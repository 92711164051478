import {
  SkylightBackendDict,
  createSkylightForm,
} from '@/data/simulation/models/enclosure/Skylight';
import { BaseValidator } from './base.validator';
import { ModelErrors, StateErrors } from '../state/shared/base.state';

export class SkylightValidator extends BaseValidator {
  static validateForm(
    skylight: SkylightBackendDict
  ): ModelErrors<SkylightBackendDict> {
    const form = createSkylightForm(skylight);
    return form.getFieldAndModelErros();
  }

  static validate(
    skylights: SkylightBackendDict[]
  ): StateErrors<SkylightBackendDict> {
    const errors = {};
    skylights.forEach(skylight => {
      errors[skylight.id] = this.validateForm(skylight);
    });
    return errors;
  }
}
