import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { map, switchMap, tap, first } from 'rxjs/operators';
import { NavService } from '@/data/core/services/nav.service';
import { AuthCookie } from '@/core/services/auth-cookies-handler';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { environment } from '../../../environments/environment';
import { UserService } from '@/data/core/services/user.service';
import { loadInfo } from '@/state/actions/info.actions';
import { SocketService } from '@/core/services/socket.service';
import { MessageService } from '@/data/messaging/services/message.service';

export class TokenObtainPairResponse {
  access: string;
  refresh: string;
}

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  public loginCloser$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    null
  );
  public impersonateCloser$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(null);

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private menuService: NavService,
    private messageService: MessageService,
    // private alertService: AlertService,
    private socketService: SocketService,
    private authCookie: AuthCookie,
    private router: Router,
    private store: Store<AppState>
  ) {}

  public isLoggedIn(): boolean {
    return !!this.authCookie.getAccess();
  }

  login(username, password) {
    return this.http
      .post<TokenObtainPairResponse>(`${environment.AXIS_API_V3_ROOT}/token/`, {
        username,
        password,
      })
      .pipe(
        first(),
        map(res => {
          this.authCookie.setAccess(JSON.stringify(res.access));
          this.authCookie.setRefresh(JSON.stringify(res.refresh));
          return res;
        }),
        switchMap(res => this.userService.info()),
        switchMap(res => this.menuService.list()),
        tap(res => {
          this.socketService.init();
        })
      );
  }

  /**
   * Call logout API to clear session, then remove all cookies and reset navigation
   * return Observable
   */
  logout() {
    return this.userService.logout().pipe(
      first(),
      tap(_ => {
        this.store.dispatch(loadInfo({ payload: null }));
        this.authCookie.deleteSession();
        this.authCookie.deleteAccess();
        this.authCookie.deleteRefresh();
      }),
      switchMap(_ => {
        return this.menuService.list();
      })
    );
  }
}
