import { ModelFormControl, ModelFormGroup } from '../ModelForm';
import { Validators } from '@angular/forms';
import { FieldLabels } from '../base';

// Labels
export const FIELD_LABELS: FieldLabels<SkylightTypeBackendDict> = {
  name: 'Name',
  shgc: 'SHGC',
  u_value: 'U-Value',
};

export interface SkylightTypeBackendDict {
  id: number | null;
  name: string;
  shgc: number;
  u_value: number;
}

export const DEFAULT_SKYLIGHT_TYPE_DICT: SkylightTypeBackendDict = {
  id: null,
  name: '',
  shgc: 0.0,
  u_value: 0.0,
};

export function createSkylightTypeForm(
  skylightType: SkylightTypeBackendDict
): ModelFormGroup {
  return new ModelFormGroup({
    id: new ModelFormControl(skylightType.id, Validators.required),
    name: new ModelFormControl(skylightType.name, Validators.required),
    shgc: new ModelFormControl(skylightType.shgc, Validators.required),
    u_value: new ModelFormControl(skylightType.u_value, Validators.required),
  });
}
