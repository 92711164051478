<div class="row">
  <div class="col-12">
    <div class="row mb-2">
      <div class="col-12 text-right">
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="row" *ngIf="isLoading">
          <div class="col-12">
            <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
          </div>
        </div>
        <table mat-table
               [dataSource]="rows"
               class="table"
               matSort
               [matSortActive]="storedParams.toTableSort().active"
               [matSortDirection]="storedParams.toTableSort().direction"
               (matSortChange)="onSortChange($event)">

          <ng-container matColumnDef="project_id">
            <th mat-header-cell *matHeaderCellDef>Project ID</th>
            <td mat-cell *matCellDef="let row">
              <a
                [routerLink]="[
                              '/',
                              'hi',
                              'project_registrations',
                              row.home_status_info?.customer_hirl_project_info?.registration
                            ]"
                target="_blank"
              >
                {{ row.home_status_info?.customer_hirl_project }}
              </a>
            </td>
          </ng-container>

          <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef>Address</th>
            <td mat-cell *matCellDef="let row">
              <app-home-display [home]="row?.home_status_info?.home_info" displayType="addressAsLink"></app-home-display>
            </td>
          </ng-container>

          <ng-container matColumnDef="eep_program">
            <th mat-header-cell *matHeaderCellDef>Program</th>
            <td mat-cell *matCellDef="let row">
              <a
                [routerLink]="[
                              '/',
                              'eep_program',
                              row.home_status_info?.eep_program
                            ]"
                target="_blank"
              >
                {{ row.home_status_info?.eep_program_info?.name }}
              </a>
            </td>
          </ng-container>

          <ng-container matColumnDef="h_number">
            <th mat-header-cell *matHeaderCellDef>H Number</th>
            <td mat-cell *matCellDef="let row">
              {{ row.home_status_info?.customer_hirl_project_info?.h_number }}
            </td>
          </ng-container>

          <ng-container matColumnDef="customer_hirl_project_type">
            <th mat-header-cell *matHeaderCellDef>Type</th>
            <td mat-cell *matCellDef="let row">
              <app-hirl-project-type
                [project]="row.home_status_info?.customer_hirl_project_info"
              ></app-hirl-project-type>
            </td>
          </ng-container>

          <ng-container matColumnDef="hirl_gcp_name">
            <th mat-header-cell *matHeaderCellDef>Product Group Name</th>
            <td mat-cell *matCellDef="let row">
              <app-product-display [product]="row.product_info" displayType="onlyNameAsLink" *ngIf="row.product_info"></app-product-display>

              <ng-container *ngIf="!row.product_info">
                <ng-container *ngFor="let item_info of row.items_info; let isLast = last">
                  {{ item_info.name }}
                  {{ isLast ? "" : "\n" }}
                </ng-container>
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="hirl_gcp_description">
            <th mat-header-cell *matHeaderCellDef>Practices</th>
            <td mat-cell *matCellDef="let row">
              <ng-container *ngFor="let item_info of row.items_info; let isLast = last">
                {{ item_info.description }}
                {{ isLast ? "" : "\n" }}
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="hirl_gcp_total">
            <th mat-header-cell *matHeaderCellDef>Fee</th>
            <td mat-cell *matCellDef="let row">
              <ng-container *ngIf="!row.total">N/A</ng-container>
              <ng-container *ngIf="row.total">
                {{ row.total | currency : "USD" }}
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="total">
            <th mat-header-cell *matHeaderCellDef>Fee</th>
            <td mat-cell *matCellDef="let row">
              {{ row.total | currency : "USD" }}
            </td>
          </ng-container>


          <ng-container matColumnDef="created_at">
            <th mat-header-cell *matHeaderCellDef>Created</th>
            <td mat-cell *matCellDef="let row">
              {{ row.created_at | date : "MM/dd/yyyy" }}
            </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [length]="rowsCount"
                       [pageIndex]="$any(storedParams.page) - 1"
                       [pageSize]="storedParams.page_size"
                       (page)="onPaginateChange($event)"
                       aria-label="Select page">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
