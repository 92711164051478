import { UIHelperService } from '@/shared/services/ui-helper.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import { reportAPIFailure } from '../actions/app.actions';

@Injectable()
export class AppEffects {
  constructor(
    private actions$: Actions,
    private uiHelperService: UIHelperService
  ) {}

  reportAPIFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(reportAPIFailure),
        tap(console.error),
        map(action => this.uiHelperService.handleUserRequestError(action.error))
      );
    },
    { dispatch: false }
  );
}
