import {
  RoofBackendDict,
  createRoofForm,
} from '@/data/simulation/models/enclosure/Roof';
import { BaseValidator } from './base.validator';
import { ModelErrors, StateErrors } from '../state/shared/base.state';

export class RoofValidator extends BaseValidator {
  static validateForm(roof: RoofBackendDict): ModelErrors<RoofBackendDict> {
    const roofForm = createRoofForm(roof);
    return roofForm.getFieldAndModelErros();
  }

  static validate(roofs: RoofBackendDict[]): StateErrors<RoofBackendDict> {
    const errors = {};
    roofs.forEach(roof => {
      errors[roof.id] = this.validateForm(roof);
    });
    return errors;
  }
}
