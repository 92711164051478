<div class="container" *ngIf="initialized">
    <div class="row">
        <div class="col-12">
            <nav class="navbar p-0 pt-3 mb-3" role="navigation" aria-label="main navigation" style="border-bottom: 1px solid #eee;">
                <h5 class="mt-3">
                    <button mat-raised-button color="accent" (click)="handleBack()">
                        <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
                    </button>
                    Add Client Agreement without DocuSign
                </h5>
            </nav>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <form [formGroup]="addFormGroup">
                <div class="row">
                    <div class="col-6 offset-3">
                        <mat-form-field class="w-100">
                            <mat-label>Company</mat-label>
                            <app-generic-model-autocomplete
                                    formControlName="company_info"
                                    modelClassName="company"
                                    [panelWidth]="600"
                                    [display]="companyDisplay"
                                    [optionDisplay]="companyDisplay"
                                    [filterParams]="companyRequestParams"
                            ></app-generic-model-autocomplete>
                            <mat-error *ngIf="addFormGroup.get('company_info').hasError('required')">
                                This field is required
                            </mat-error>
                            <mat-hint *ngIf="addFormGroup.get('company_info').value && !addFormGroup.get('company_info').value.active_customer_hirl_builder_agreements_count">
                                (Do not have active Client Agreement)
                            </mat-hint>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 text-center">
                        <button type="button"
                                (click)="handleCreate($event)"
                                class="btn btn-primary btn-lg mr-2">
                            Create
                        </button>
                    </div>
                </div>

            </form>
        </div>
    </div>

</div>
