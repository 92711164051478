import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { first } from 'rxjs/operators';
import { QaNoteService } from '@/data/qa/services/qa-note.service';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { toggleLoading } from '@/state/actions/app.actions';
import { CreateQANoteForMultipleQAStatuses } from '@/data/qa/models';

export interface IHirlQaNoteMultipleCreateDialogData {
  qaStatusesIds: number[];
}

@Component({
  selector: 'app-hirl-qa-note-multiple-create-dialog',
  templateUrl: './hirl-qa-note-multiple-create-dialog.component.html',
  styleUrls: ['./hirl-qa-note-multiple-create-dialog.component.scss'],
})
export class HirlQaNoteMultipleCreateDialogComponent implements OnInit {
  public form: UntypedFormGroup;
  public isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<HirlQaNoteMultipleCreateDialogComponent>,
    private fb: UntypedFormBuilder,
    public store: Store<AppState>,
    public qaNoteService: QaNoteService,
    public uiHelperService: UIHelperService,
    @Inject(MAT_DIALOG_DATA) public data: IHirlQaNoteMultipleCreateDialogData
  ) {}

  ngOnInit(): void {
    this.setupForm();
  }

  setupForm() {
    this.form = this.fb.group({
      note: [null, Validators.required],
    });
  }

  onSave($event: MouseEvent) {
    $event.preventDefault();
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    const formData = new CreateQANoteForMultipleQAStatuses();
    formData.note = this.form.value.note;
    formData.qa_statuses = this.data.qaStatusesIds;

    this.store.dispatch(toggleLoading({ payload: true }));
    this.qaNoteService
      .create_note_for_multiple_qa_statuses(formData)
      .pipe(first())
      .subscribe(
        qaNotes => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close(qaNotes);
        },
        error => this.uiHelperService.handleUserRequestError(error)
      );
  }
}
