<div class="card position-relative" appFocusTarget entityName="window" entityId="{{entityId}}">
    <app-loading-overlay *ngIf="loading"></app-loading-overlay>
    <div class="card-body pb-0">
        <app-form formName="windowFormGroup" [formGroup]="windowFormGroup" (save)="onSave($event)">
            <div>
                <app-input formControlName="name" [label]="fieldLabels.name"></app-input>
            </div>
            <div>
                <app-input formControlName="area" [label]="fieldLabels.area" unit="{{areaLabels[entity?.area_units]}}" type="expression"></app-input>
            </div>
            <div>Shading coefficient (Summer)</div>
            <div>
                <app-input formControlName="shading_factor_interior_summer" type="number"
                           [label]="fieldLabels.shading_factor_interior_summer"></app-input>
                <app-input formControlName="shading_factor_adjacent_summer" type="number"
                           [label]="fieldLabels.shading_factor_adjacent_summer"></app-input>
            </div>
            <div>Shading coefficient (Winter)</div>
            <div>
                <app-input formControlName="shading_factor_interior_winter" type="number"
                           [label]="fieldLabels.shading_factor_interior_winter"></app-input>
                <app-input formControlName="shading_factor_adjacent_winter" type="number"
                           [label]="fieldLabels.shading_factor_adjacent_winter"></app-input>
            </div>
            <div>
                <app-input formControlName="overhang_depth" type="number" [label]="fieldLabels.overhang_depth" unit="{{lengthLabels['ft']}}"></app-input>
                <app-input formControlName="overhang_depth_to_top_of_window" type="number" [label]="fieldLabels.overhang_depth_to_top_of_window" unit="{{lengthLabels['ft']}}"></app-input>
                <app-input formControlName="overhang_depth_to_bottom_of_window" type="number" [label]="fieldLabels.overhang_depth_to_bottom_of_window" unit="{{lengthLabels['ft']}}"></app-input>
            </div>
            <div>
                <app-select formControlName="orientation" [label]="fieldLabels.orientation" [items]="orientationLabels"></app-select>
                <app-input formControlName="azimuth" [label]="fieldLabels.azimuth" unit="0-360°"></app-input>
            </div>
            <div>
                <app-select [formControl]="wallControl" [items]="wallLabels" label="Wall location" [isInEditMode]="windowFormGroup.enabled"></app-select>
                <app-model-select modelName="aboveGradeWall" formControlName="above_grade_wall"
                    [hidden]="wallControl.value !== 'above_grade_wall'" label="Wall"></app-model-select>
                <app-model-select modelName="foundationWall" formControlName="foundation_wall"
                    [hidden]="wallControl.value === 'above_grade_wall'" label="Wall"></app-model-select>
            </div>
            <div>
                <app-select formControlName="performance_class" [items]="performanceClassLabels" [label]="fieldLabels.performance_class"></app-select>
            </div>
            <div>
                <app-checkbox [label]="fieldLabels.is_operable" formControlName="is_operable"></app-checkbox>
                <app-input *ngIf="windowFormGroup.controls.is_operable.value" formControlName="percent_operable" [label]="fieldLabels.percent_operable"></app-input>
            </div>
            <div>
                <app-foreign-model-field
                    [name]="windowTypeName"
                    (add)="addWindowType()"
                    (edit)="editWindowType()"
                    (remove)="removeWindowType()"
                    label="Window type"
                    [errorCount]="windowTypeErrorCount">
                </app-foreign-model-field>
            </div>
        </app-form>
    </div>
    <div class="card-footer p-0 text-right">
        <app-model-action-buttons [entityId]="entityId" modelName="window" (clone)="cloneWindow()" (delete)="removeWindow()">
        </app-model-action-buttons>
    </div>
</div>
