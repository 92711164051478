import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from '@/data/core/models/user';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  HIRLGreenEnergyBadge,
  HIRLProject,
  HIRLProjectAppealsProjectTypeMapping,
  HIRLProjectCommercialSpaceTypeLabelMapping,
  HIRLProjectRegistrationState,
  HIRLProjectRegistrationStateLabelMapping,
  HIRLProjectRegistrationType,
  HIRLProjectSampling,
  HIRLProjectSamplingMapping,
} from '@/data/customer-hirl/models';
import { forkJoin, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import {
  HIRLProjectRequestParams,
  HIRLProjectService,
} from '@/data/customer-hirl/services/hirl-projects.service';
import { HIRLProjectRegistrationService } from '@/data/customer-hirl/services/hirl-project-registration.service';
import {
  HIRLGreenEnergyBadgeRequestParams,
  HIRLGreenEnergyBadgeService,
} from '@/data/customer-hirl/services/hirl-green-energy-badge.service';
import { MatDialog } from '@angular/material/dialog';
import { GeocoderService } from '@/data/geocoder/services/geocoder.service';
import { UserService } from '@/data/core/services/user.service';
import { toggleLoading } from '@/state/actions/app.actions';
import { getInfoUser } from '@/state/selectors/info.selector';
import { distinctUntilChanged, first, map, takeUntil } from 'rxjs/operators';
import { HomeAddressConfirmedGeocodeResponse } from '@/modules/geocoder/components/home-address-to-geocode-response-dialog/home-address-to-geocode-response-dialog.component';
import {
  ServerErrorDialogComponent,
  ServerErrorDialogConfig,
} from '@/shared/components/server-error-dialog/server-error-dialog.component';
import { conditionalValidator } from '@/core/validators';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';
import { CreateMFHIRLProject } from '@/data/customer-hirl/models/hirl-project-registration';
import { CompanyRequestParams } from '@/data/company/services/company-base.service';
import { HIRLProjectAppealsProjectType } from '@/data/customer-hirl/models/hirl-project';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { NavigationService } from '@/shared/services/navigation-service.service';
import { IGeocodeMatches } from '@/data/geocoder/models';

@Component({
  selector: 'app-hirl-project-mf-form',
  templateUrl: './hirl-project-mf-form.component.html',
  styleUrls: ['./hirl-project-mf-form.component.scss'],
})
export class HIRLProjectMFFormComponent implements OnInit, OnDestroy {
  protected readonly HIRLProjectSamplingMapping = HIRLProjectSamplingMapping;
  protected readonly HIRLProjectAppealsProjectTypeMapping =
    HIRLProjectAppealsProjectTypeMapping;

  public initialized = false;
  public currentUser: User;
  public editFormGroup: UntypedFormGroup;
  public addCommercialSpaceFormGroup: UntypedFormGroup;

  public hirlProject?: HIRLProject;
  public commercialSpaceProjects?: HIRLProject[];

  public hirlGreenEnergyBadges: HIRLGreenEnergyBadge[] = [];

  public HIRLProjectRegistrationStateLabelMapping =
    HIRLProjectRegistrationStateLabelMapping;
  public HIRLProjectRegistrationType = HIRLProjectRegistrationType;
  public HIRLProjectRegistrationState = HIRLProjectRegistrationState;
  public HIRLProjectCommercialSpaceTypeLabelMapping =
    HIRLProjectCommercialSpaceTypeLabelMapping;
  public commercialSpaceTypeLabelMapping =
    HIRLProjectCommercialSpaceTypeLabelMapping;
  public showCSFromGroup = false;

  public customerHIRLSettings = CustomerHIRLSettings;

  private destroy$ = new Subject();

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<AppState>,
    private router: Router,
    private hirlProjectService: HIRLProjectService,
    private hirlProjectRegistrationService: HIRLProjectRegistrationService,
    private hirlGreenEnergyBadgeService: HIRLGreenEnergyBadgeService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private geocoderService: GeocoderService,
    private userService: UserService,
    private uiHelper: UIHelperService,
    private navigation: NavigationService
  ) {}

  public ngOnInit(): void {
    this.activatedRoute.params
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(params => {
        this.hirlProjectService
          .retrieve(params.projectId)
          .pipe(first())
          .subscribe(hirlProject => {
            this.hirlProject = hirlProject;

            const greenEnergyBadgeRequestParams =
              new HIRLGreenEnergyBadgeRequestParams();
            greenEnergyBadgeRequestParams.eep_program =
              this.hirlProject.registration_info.eep_program;

            this.store.dispatch(toggleLoading({ payload: true }));
            const sources: { [k: string]: any } = {
              currentUser: this.store.select(getInfoUser).pipe(first()),
              hirlGreenEnergyBadges: this.hirlGreenEnergyBadgeService
                .list(greenEnergyBadgeRequestParams)
                .pipe(
                  first(),
                  map(data => data.results)
                ),
              commercialSpaceProjects: this.hirlProjectService
                .list(
                  Object.assign(new CompanyRequestParams(), {
                    commercial_space_parent: params.projectId,
                  })
                )
                .pipe(
                  first(),
                  map(data => data.results)
                ),
            };

            forkJoin(sources).subscribe(
              ({
                currentUser,
                hirlGreenEnergyBadges,
                commercialSpaceProjects,
              }) => {
                this.currentUser = currentUser;
                this.hirlGreenEnergyBadges = hirlGreenEnergyBadges;
                this.commercialSpaceProjects = commercialSpaceProjects;

                this.setupEditFormGroup();
                this.hydrateForm();
                this.store.dispatch(toggleLoading({ payload: false }));
                this.initialized = true;
              }
            );
          });
      });
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public attachFieldCheckHomeWithAddressAlreadyExists(
    formGroup,
    fieldName,
    isMultiFamily
  ) {
    formGroup
      .get(fieldName)
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(confirmedGeocodeResponse => {
        if (!confirmedGeocodeResponse) {
          return;
        }
        this.store.dispatch(toggleLoading({ payload: true }));
        this.hirlProjectService
          .homeAddressIsUnique({
            project: this.hirlProject?.id,
            street_line1: confirmedGeocodeResponse.addressInfo.street_line1,
            street_line2: confirmedGeocodeResponse.addressInfo.street_line2,
            city: confirmedGeocodeResponse.addressInfo.city_info.id,
            zipcode: confirmedGeocodeResponse.addressInfo.zipcode,
            is_multi_family: isMultiFamily,
            geocode_response: confirmedGeocodeResponse.geocodeBrokeredResponse
              ? confirmedGeocodeResponse.geocodeBrokeredResponse.id
              : null,
          })
          .pipe(first())
          .subscribe(isUnique => {
            this.store.dispatch(toggleLoading({ payload: false }));
            if (isUnique) {
              formGroup
                .get(fieldName)
                .setErrors({ home_with_this_address_already_exists: null });
              formGroup.get(fieldName).updateValueAndValidity({
                emitEvent: false,
              });
            } else {
              formGroup
                .get(fieldName)
                .setErrors({ home_with_this_address_already_exists: true });
            }
          });
      });
  }

  public setupEditFormGroup() {
    this.editFormGroup = this.fb.group({
      registration_info: [null, Validators.required],
      building_number: [
        null,
        conditionalValidator(
          () => !this.hirlProject.is_accessory_structure,
          Validators.required
        ),
      ],
      edit_address: [
        null,
        [
          conditionalValidator(
            () =>
              this.hirlProject.registration_info.state ===
              HIRLProjectRegistrationState.new,
            Validators.required
          ),
        ],
      ],
      number_of_units: [
        '',
        conditionalValidator(
          () =>
            !this.hirlProject.is_accessory_structure &&
            !this.hirlProject.is_include_commercial_space,
          Validators.compose([
            Validators.required,
            Validators.min(1),
            Validators.max(1000000),
          ])
        ),
      ],
      commercial_space_type: [
        null,
        conditionalValidator(
          () => this.hirlProject.is_include_commercial_space,
          Validators.required
        ),
      ],
      story_count: [
        null,
        conditionalValidator(
          () =>
            !this.hirlProject.is_accessory_structure &&
            !this.hirlProject.is_include_commercial_space,
          Validators.compose([
            Validators.required,
            Validators.min(1),
            Validators.max(1000000),
          ])
        ),
      ],
      total_commercial_space: [
        null,
        conditionalValidator(
          () => this.hirlProject.is_include_commercial_space,
          Validators.compose([
            Validators.required,
            Validators.min(0),
            Validators.max(1000000),
          ])
        ),
      ],
      hud_disaster_case_number: [''],
      green_energy_badges: [[]],
      is_require_water_sense_certification: [false],
      is_require_rough_inspection: [true],
      is_require_wri_certification: [false],
      accessory_structure_description: [
        '',
        conditionalValidator(
          () => this.hirlProject.is_accessory_structure,
          Validators.required
        ),
      ],
      is_include_commercial_space: [false],
      appeals_project: [HIRLProjectAppealsProjectType.no],
      sampling: [HIRLProjectSampling.no_sampling],
      is_townhouse: [false],
    });

    if (
      this.hirlProject?.registration_info?.state !==
      HIRLProjectRegistrationState.new
    ) {
      this.editFormGroup.get('is_townhouse').disable();
    }

    this.addCommercialSpaceFormGroup = this.fb.group({
      // is_include_commercial_space: [false, ],
      commercial_space_type: [
        null,
        conditionalValidator(() => this.showCSFromGroup, Validators.required),
      ],
      commercial_space_street_line_2: [
        '',
        conditionalValidator(() => this.showCSFromGroup, Validators.required),
      ],
      total_commercial_space: [
        null,
        conditionalValidator(
          () => this.showCSFromGroup,
          Validators.compose([
            Validators.required,
            Validators.min(0),
            Validators.max(1000000),
          ])
        ),
      ],
    });

    this.attachFieldCheckHomeWithAddressAlreadyExists(
      this.editFormGroup,
      'edit_address',
      false
    );

    this.editFormGroup
      .get('is_townhouse')
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe({
        next: is_townhouse => {
          if (is_townhouse) {
            this.editFormGroup.get('story_count').patchValue(2);
            this.editFormGroup.get('number_of_units').patchValue(2);
            this.editFormGroup.get('story_count').disable();
            this.editFormGroup.get('number_of_units').disable();
          } else {
            this.editFormGroup.get('story_count').enable();
            this.editFormGroup.get('number_of_units').enable();
          }
        },
      });
  }

  hydrateForm() {
    this.editFormGroup.patchValue(this.hirlProject, {
      emitEvent: true,
      onlySelf: true,
    });
    this.editFormGroup.patchValue(
      {
        edit_address: new HomeAddressConfirmedGeocodeResponse({
          lotNumber: this.hirlProject.lot_number,
          geocode: this.hirlProject.home_address_geocode_info,
          geocodeBrokeredResponse:
            this.hirlProject.home_address_geocode_response_info,
        }),
      },
      { emitEvent: false, onlySelf: true }
    );
  }

  handleBack() {
    this.router.navigate([
      'hi',
      'project_registrations',
      this.hirlProject.registration_info.id,
    ]);
  }

  handleEdit(event): void {
    event.preventDefault();
    this.editFormGroup.markAllAsTouched();
    if (this.editFormGroup.invalid) {
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const formData = new HIRLProject();
    formData.is_require_water_sense_certification =
      this.editFormGroup.value.is_require_water_sense_certification;
    formData.is_require_rough_inspection =
      this.editFormGroup.value.is_require_rough_inspection;
    formData.appeals_project = this.editFormGroup.value.appeals_project;
    formData.sampling = this.editFormGroup.value.sampling;
    formData.is_townhouse = this.editFormGroup.value.is_townhouse;

    if (
      this.currentUser.company_info?.slug === CustomerHIRLSettings.companySlug
    ) {
      formData.registration = this.editFormGroup.value.registration_info.id;
    }

    formData.lot_number = this.editFormGroup.value.edit_address.lotNumber;
    formData.home_address_geocode =
      this.editFormGroup.value.edit_address.geocode.id;
    formData.home_address_geocode_response = null;

    if (this.editFormGroup.value.edit_address.geocodeBrokeredResponse) {
      formData.home_address_geocode_response =
        this.editFormGroup.value.edit_address.geocodeBrokeredResponse.id;
    }

    if (
      this.customerHIRLSettings.waterSenseProgramList.includes(
        this.hirlProject.registration_info?.eep_program_info?.slug
      )
    ) {
      formData.is_require_wri_certification =
        this.editFormGroup.value.is_require_wri_certification;
    } else {
      formData.is_require_wri_certification = false;
    }

    if (this.hirlProject.is_accessory_structure) {
      formData.accessory_structure_description =
        this.editFormGroup.value.accessory_structure_description;
    } else {
      if (this.hirlProject.is_include_commercial_space) {
        formData.is_include_commercial_space = true;
        formData.commercial_space_type =
          this.editFormGroup.value.commercial_space_type;
        formData.total_commercial_space =
          this.editFormGroup.value.total_commercial_space;
      } else {
        formData.green_energy_badges =
          this.editFormGroup.value.green_energy_badges;
        formData.story_count = this.editFormGroup.value.story_count;
        formData.number_of_units = this.editFormGroup.value.number_of_units;
      }
      formData.building_number = this.editFormGroup.value.building_number;
      formData.hud_disaster_case_number =
        this.editFormGroup.value.hud_disaster_case_number;
    }

    this.hirlProjectService
      .update(formData, this.hirlProject.id)
      .pipe(first())
      .subscribe(
        hirlProject => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.router.navigate([
            `/hi/project_registrations/${hirlProject.registration_info.id}`,
          ]);
        },
        error => this.uiHelper.handleUserRequestError(error)
      );
  }

  handleCreateCS($event: MouseEvent) {
    event.preventDefault();
    this.editFormGroup.markAllAsTouched();
    this.addCommercialSpaceFormGroup.markAllAsTouched();
    if (
      this.editFormGroup.invalid ||
      this.addCommercialSpaceFormGroup.invalid
    ) {
      return;
    }
    this.store.dispatch(toggleLoading({ payload: true }));

    this.geocoderService
      .matches({
        street_line1:
          this.editFormGroup.getRawValue().edit_address.geocode
            .raw_street_line1,
        street_line2:
          this.addCommercialSpaceFormGroup.value.commercial_space_street_line_2,
        city: this.editFormGroup.getRawValue().edit_address.geocode.raw_city,
        zipcode:
          this.editFormGroup.getRawValue().edit_address.geocode.raw_zipcode,
      } as IGeocodeMatches)
      .pipe(first())
      .subscribe(geocode => {
        const projectFormData = new CreateMFHIRLProject();
        projectFormData.is_accessory_structure = false;
        projectFormData.accessory_structure_description = '';

        projectFormData.lot_number =
          this.editFormGroup.getRawValue().edit_address.lotNumber;
        projectFormData.home_address_geocode = geocode.id;
        projectFormData.home_address_geocode_response = null;

        projectFormData.is_include_commercial_space = true;
        projectFormData.commercial_space_type =
          this.addCommercialSpaceFormGroup.value.commercial_space_type;
        projectFormData.total_commercial_space =
          this.addCommercialSpaceFormGroup.value.total_commercial_space;

        projectFormData.green_energy_badges =
          this.editFormGroup.getRawValue().green_energy_badges;
        projectFormData.building_number =
          this.editFormGroup.getRawValue().building_number;
        projectFormData.commercial_space_parent = this.hirlProject.id;

        this.hirlProjectRegistrationService
          .hirl_projects_create_multi_family(this.hirlProject.registration, {
            projects: [projectFormData],
          })
          .pipe(first())
          .subscribe(
            _ => {
              this.hirlProjectService
                .list(
                  Object.assign(new HIRLProjectRequestParams(), {
                    commercial_space_parent: this.hirlProject.id,
                  })
                )
                .pipe(
                  first(),
                  map(data => data.results)
                )
                .subscribe(commercialSpaceProjects => {
                  this.commercialSpaceProjects = commercialSpaceProjects;
                  this.store.dispatch(toggleLoading({ payload: false }));
                  this.showCSFromGroup = false;
                });
            },
            error => {
              this.store.dispatch(toggleLoading({ payload: false }));
              this.dialog.open(ServerErrorDialogComponent, {
                width: '400px',
                data: new ServerErrorDialogConfig({
                  title: 'Error:',
                  data: error,
                }),
              });
            }
          );
      });
  }

  onAddCSBuilding($event: MouseEvent) {
    this.showCSFromGroup = true;
    this.addCommercialSpaceFormGroup.reset();
  }

  isWRIProgramSelected() {
    return this.customerHIRLSettings.wriProgramList.includes(
      this.hirlProject?.registration_info.eep_program_info?.slug
    );
  }
}
