import {
  CoolingEfficiencyUnitLabels,
  FuelTypeLabels,
  HeatingCoolingCapacityUnitLabels,
} from '@/data/simulation/enumerations';
import { DetailedMechanicalEquipmentBackendDict } from '@/data/simulation/models/mechanicals/MechanicalEquipment';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-equipment-air-conditioner',
  templateUrl: './equipment-air-conditioner.component.html',
  styleUrls: ['./equipment-air-conditioner.component.scss'],
})
export class EquipmentAirConditionerComponent implements OnInit {
  @Input() equipment: DetailedMechanicalEquipmentBackendDict;

  FuelLabels = FuelTypeLabels;
  CapacityUnitLabels = HeatingCoolingCapacityUnitLabels;
  CoolingEfficiencyUnitLabels = CoolingEfficiencyUnitLabels;

  ngOnInit(): void {}
}
