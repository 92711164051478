<div class="container" *ngIf="initialized">
    <div class="row mt-3">
        <div class="col-12">
            <h5>
                <button mat-raised-button color="accent" (click)="handleBack()">
                    <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
                </button>
                Create Client Agreement without Client AXIS Account
            </h5>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <form [formGroup]="formGroup">

                <div class="row">
                    <div class="col-6 offset-3">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Select Company</mat-label>
                            <input matInput placeholder="Type to search" formControlName="company_info"
                                   [matAutocomplete]="autocompleteCompany">
                            <mat-error *ngIf="formGroup.controls['company_info'].hasError('required')">
                                {{ companyDisplayName }} is required - Must select option from list
                            </mat-error>
                            <mat-error *ngIf="formGroup.controls['company_info'].hasError('invalidObject')">
                                Must select option from list
                            </mat-error>
                            <mat-autocomplete #autocompleteCompany="matAutocomplete" autoActiveFirstOption [displayWith]="displayCompanyMenu" (optionSelected)="onCompanySelected($event)">
                                <mat-option
                                        *ngFor="let company of (filteredCompanies$ | async)?.results"
                                        [value]="company">
                                    <span>{{ company.name }} </span>
                                    <small>[{{ company.street_line1 }}, {{ company.city_info?.name }} {{ company.city_info?.county_info?.state }} {{ company.zipcode}}]</small>
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row" *ngIf="formGroup.value.company_info?.id">
                    <div class="col-6 offset-3">
                        <p>
                            The information provided below will be used to auto-populate the DocuSign agreement delivered to the client for signing.
                            <b>Note that the NGBS Client Agreement MUST be signed by an officer of the client’s company.</b>
                        </p>
                    </div>
                </div>


                <div class="row" *ngIf="formGroup.value.company_info?.id">
                    <div class="col-6 offset-3">
                        <mat-form-field appearance="outline" class="mb-2 w-100">
                            <mat-label>Email</mat-label>
                            <input matInput type="text" placeholder="Email" formControlName="signer_email">
                            <mat-error *ngIf="formGroup.controls['signer_email'].hasError('required')">
                                Email is required
                            </mat-error>
                            <mat-hint>
                                This email will receive the CA for electronic signature via DocuSign
                            </mat-hint>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row" *ngIf="formGroup.value.company_info?.id">
                    <div class="col-6 offset-3">
                        <mat-form-field appearance="outline" class="mb-2 w-100">
                            <mat-label>Full Name</mat-label>
                            <input matInput type="text" placeholder="Full Name" formControlName="signer_name">
                            <mat-error *ngIf="formGroup.controls['signer_name'].hasError('required')">
                                Full Name is required
                            </mat-error>
                            <mat-hint>
                                This name will be auto-populated into the DocuSign agreement as the DEFAULT signer.
                                The email recipient may forward the DocuSign email to another person for signing using a different name if necessary.
                            </mat-hint>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row" *ngIf="formGroup.value.company_info?.id">
                    <div class="col-6 offset-3">
                        <mat-form-field appearance="outline" class="w-100 mb-2">
                            <mat-label>Mailing Address</mat-label>
                            <app-address-input placeholder="Address" [formControl]="formGroup.controls['mailing_address']" required [show-clear-btn]="false"></app-address-input>
                            <mat-error *ngIf="formGroup.get('mailing_address').hasError('required')">
                                This field is required
                            </mat-error>
                            <mat-hint>
                                This address will be auto-populated into the DocuSign agreement as
                                the CLIENT company address and <b>may not</b> be changed by the email recipient.
                            </mat-hint>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row mt-2" *ngIf="formGroup.value.company_info?.id">
                    <div class="col-12 text-center">
                        <button type="button"
                                (click)="onCreate($event)"
                                class="btn btn-primary btn-lg mr-2">
                            Send Agreement for Client Signature
                        </button>
                    </div>
                </div>

            </form>
        </div>
    </div>

</div>
