import { createReducer, on } from '@ngrx/store';
import * as AirConditionerActions from './actions';
import { AirConditionerBackendDict } from '@/data/simulation/models/mechanicals/AirConditioner';
import { BaseState } from '../shared/base.state';
import { addStateErrorCount } from '../helper';

export type AirConditionerState = BaseState<AirConditionerBackendDict>;

export const initialState: AirConditionerState = {
  entities: {},
  loading: {},
  errors: {},
};

export const reducer = createReducer(
  initialState,
  on(
    AirConditionerActions.loadAirConditionersSuccess,
    (state, { airConditioners, errors }) => {
      const entities = airConditioners.reduce(
        (acc, airConditioner) => ({
          ...acc,
          [airConditioner.id]: airConditioner,
        }),
        {}
      );
      const loading = airConditioners.reduce(
        (acc, airConditioner) => ({ ...acc, [airConditioner.id]: false }),
        {}
      );

      return {
        entities: {
          ...state.entities,
          ...entities,
        },
        loading: {
          ...state.loading,
          ...loading,
        },
        errors: {
          ...state.errors,
          ...addStateErrorCount(errors),
        },
      };
    }
  ),

  on(
    AirConditionerActions.updateAirConditioner,
    (state, { airConditionerChanges }) => ({
      ...state,
      loading: {
        ...state.loading,
        [airConditionerChanges.id]: true,
      },
    })
  ),

  on(AirConditionerActions.updateAirConditionerFailure, (state, { id }) => ({
    ...state,
    loading: {
      ...state.loading,
      [id]: false,
    },
  }))
);
