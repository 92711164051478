<div fxLayout="row" fxLayoutGap="20px" class="content-container" *ngIf="entity">
    <mat-card-content fxFlex>
        <h3>Consumption (MMBtu)</h3>
        <mat-list>
            <mat-list-item>Total Heating Consumption: {{ entity.consumption.total_heating_consumption | number: '1.3-3'
                }}</mat-list-item>
            <mat-list-item>Total Cooling Consumption: {{ entity.consumption.total_cooling_consumption | number: '1.3-3'
                }}</mat-list-item>
            <mat-list-item>Total Hot Water Consumption: {{ entity.consumption.total_hot_water_consumption
                | number: '1.3-3' }}</mat-list-item>
            <mat-list-item>Total Light and Appliances Consumption: {{
                entity.consumption.total_light_and_appliances_consumption | number: '1.3-3' }}</mat-list-item>
            <mat-list-item>Total Onsite Generation: {{ entity.consumption?.total_onsite_generation | number: '1.3-3'
                }}</mat-list-item>
            <mat-list-item>Total Energy Consumption: {{ entity.consumption?.total_energy_consumption | number: '1.3-3'
                }}</mat-list-item>
        </mat-list>
    </mat-card-content>
    <mat-card-content fxFlex>
        <h3>Costs</h3>
        <mat-list>
            <mat-list-item>Annual Heating Cost: {{ entity.costs.annual_heating_cost | currency }}</mat-list-item>
            <mat-list-item>Annual Cooling Cost: {{ entity.costs.annual_cooling_cost | currency }}</mat-list-item>
            <mat-list-item>Annual Hot Water Cost: {{ entity.costs.annual_hot_water_cost | currency }}</mat-list-item>
            <mat-list-item>Annual Light and Appliances Cost: {{ entity.costs.annual_light_and_appliances_cost | currency
                }}</mat-list-item>
            <mat-list-item>Annual Generation: {{ entity.costs.annual_generation | currency }}</mat-list-item>
            <mat-list-item>Total Annual Costs: {{ entity.costs.total_annual_costs | currency }}</mat-list-item>
            <mat-list-item>Total Annual Costs with Generation: {{ entity.costs.total_annual_costs_with_generation |
                currency }}</mat-list-item>
        </mat-list>
    </mat-card-content>
</div>
