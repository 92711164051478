import { createAction, props } from '@ngrx/store';
import { ClientAgreement } from '@/data/customer-hirl/models';
import { CustomerDocument } from '@/data/filehandling/models';
import { Annotation } from '@/data/annotation/models';

export const resetClientAgreement = createAction('[HIRLClientAgreement] Reset');

export const loadClientAgreement = createAction(
  '[HIRLClientAgreement] Detail',
  props<{ payload: ClientAgreement }>()
);

// CustomerDocuments

export const loadClientAgreementDocuments = createAction(
  '[HIRLClientAgreement Documents] Documents',
  props<{ payload: CustomerDocument[] }>()
);

export const loadClientAgreementDocumentsCount = createAction(
  '[HIRLClientAgreement Documents] Documents Count',
  props<{ payload: number }>()
);

export const updateClientAgreementDocument = createAction(
  '[HIRLClientAgreement Documents] Update Document',
  props<{ payload: CustomerDocument }>()
);

export const deleteClientAgreementDocument = createAction(
  '[HIRLClientAgreement Documents] Delete Document',
  props<{ payload: CustomerDocument }>()
);

// Notes

export const loadClientAgreementNotes = createAction(
  '[HIRLClientAgreement Notes] Notes',
  props<{ payload: Annotation[] }>()
);

export const loadClientAgreementNotesCount = createAction(
  '[HIRLClientAgreement Notes] Notes Count',
  props<{ payload: number }>()
);

export const updateClientAgreementNote = createAction(
  '[HIRLClientAgreement Notes] Update Note',
  props<{ payload: Annotation }>()
);

export const deleteClientAgreementNote = createAction(
  '[HIRLClientAgreement Notes] Delete Note',
  props<{ payload: Annotation }>()
);
