import { createReducer, on } from '@ngrx/store';
import * as FrameFloorTypeActions from './actions';
import { FrameFloorTypeBackendDict } from '@/data/simulation/models/enclosure/FrameFloorType';
import { BaseState } from '../shared/base.state';
import { addStateErrorCount } from '../helper';

export type FrameFloorTypeState = BaseState<FrameFloorTypeBackendDict>;

export const initialState: FrameFloorTypeState = {
  entities: {},
  loading: {},
  errors: {},
};

export const reducer = createReducer(
  initialState,
  on(
    FrameFloorTypeActions.loadFrameFloorTypesSuccess,
    (state, { floorTypes, errors }) => {
      const entities = floorTypes.reduce(
        (acc, floorType) => ({ ...acc, [floorType.id]: floorType }),
        {}
      );
      const loading = floorTypes.reduce(
        (acc, floorType) => ({ ...acc, [floorType.id]: false }),
        {}
      );
      return {
        entities: { ...state.entities, ...entities },
        loading: { ...state.loading, ...loading },
        errors: { ...state.errors, ...addStateErrorCount(errors) },
      };
    }
  ),

  on(
    FrameFloorTypeActions.updateFrameFloorType,
    (state, { floorTypeChanges }) => ({
      ...state,
      loading: { ...state.loading, [floorTypeChanges.id]: true },
    })
  ),

  on(FrameFloorTypeActions.updateFrameFloorTypeFailure, (state, { id }) => ({
    ...state,
    loading: { ...state.loading, [id]: false },
  })),

  on(FrameFloorTypeActions.removeFrameFloorTypeSuccess, (state, { id }) => {
    const { [id]: removed, ...entities } = state.entities;
    const { [id]: removedLoading, ...loading } = state.loading;
    const { [id]: removedErrors, ...errors } = state.errors;
    return { entities, loading, errors };
  })
);
