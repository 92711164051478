import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InspectionGradeListComponent } from './pages/inspection-grade-list/inspection-grade-list.component';
import { SharedModule } from '@/shared/shared.module';
import { InspectionGradeDisplayComponent } from './components/inspection-grade-display/inspection-grade-display.component';
import { AccreditationsTabComponent } from './components/accreditations-tab/accreditations-tab.component';
import { FilehandlingModule } from '@/modules/filehandling/filehandling.module';
import { AccreditationChangeDialogComponent } from './components/accreditation-change-dialog/accreditation-change-dialog.component';
import { InspectionGradeTabComponent } from './components/inspection-grade-tab/inspection-grade-tab.component';
import { InspectionGradeChangeDialogComponent } from './components/inspection-grade-change-dialog/inspection-grade-change-dialog.component';
import { TrainingTabComponent } from './components/training-tab/training-tab.component';
import { TrainingChangeDialogComponent } from './components/training-change-dialog/training-change-dialog.component';

@NgModule({
  declarations: [
    InspectionGradeListComponent,
    InspectionGradeDisplayComponent,
    AccreditationsTabComponent,
    AccreditationChangeDialogComponent,
    InspectionGradeTabComponent,
    InspectionGradeChangeDialogComponent,
    TrainingTabComponent,
    TrainingChangeDialogComponent,
  ],
  exports: [
    InspectionGradeListComponent,
    AccreditationsTabComponent,
    InspectionGradeTabComponent,
    TrainingTabComponent,
  ],
  imports: [CommonModule, SharedModule, FilehandlingModule],
})
export class UserManagementModule {}
