import { createAction, props } from '@ngrx/store';
import { CustomerDocument } from '@/data/filehandling/models';
import { Annotation } from '@/data/annotation/models';
import { HIRLProductAgreement } from '@/data/customer-hirl/models';

export const resetHIRLProductAgreement = createAction(
  '[HIRLProductAgreement] Reset'
);

export const loadHIRLProductAgreement = createAction(
  '[HIRLProductAgreement] Detail',
  props<{ payload: HIRLProductAgreement }>()
);

// CustomerDocuments

export const loadHIRLProductAgreementDocuments = createAction(
  '[HIRLProductAgreement Documents] Documents',
  props<{ payload: CustomerDocument[] }>()
);

export const loadHIRLProductAgreementDocumentsCount = createAction(
  '[HIRLProductAgreement Documents] Documents Count',
  props<{ payload: number }>()
);

export const updateHIRLProductAgreementDocument = createAction(
  '[HIRLProductAgreement Documents] Update Document',
  props<{ payload: CustomerDocument }>()
);

export const deleteHIRLProductAgreementDocument = createAction(
  '[HIRLProductAgreement Documents] Delete Document',
  props<{ payload: CustomerDocument }>()
);

// Notes

export const loadHIRLProductAgreementNotes = createAction(
  '[HIRLProductAgreement Notes] Notes',
  props<{ payload: Annotation[] }>()
);

export const loadHIRLProductAgreementNotesCount = createAction(
  '[HIRLProductAgreement Notes] Notes Count',
  props<{ payload: number }>()
);

export const updateHIRLProductAgreementNote = createAction(
  '[HIRLProductAgreement Notes] Update Note',
  props<{ payload: Annotation }>()
);

export const deleteHIRLProductAgreementNote = createAction(
  '[HIRLProductAgreement Notes] Delete Note',
  props<{ payload: Annotation }>()
);
