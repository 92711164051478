<div class="container-fluid" *ngIf="initialized">
  <div class="row">
    <div class="col-12">
      <div class="row mb-2">
        <div class="col-6">
          <h3>
            <span *ngIf="!tableComponent?.entitiesIsLoading">{{ tableComponent?.entitiesCount | number }}</span>
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" *ngIf="tableComponent?.entitiesIsLoading"></fa-icon>
            Project Statuses Ready For Fast Track Submission
          </h3>
        </div>
        <div class="col-6 text-right">
          <ng-container *ngIf="!tableComponent?.selectedEntities?.length || tableComponent?.entitiesIsLoading">
            <button mat-raised-button color="accent" class="mr-2" [disabled]="true" matTooltip="Select at least one row">Submit</button>
          </ng-container>
          <ng-container *ngIf="tableComponent.selectedEntities?.length">
            <button mat-raised-button color="accent" (click)="onCertify($event)" class="mr-2">Submit</button>
          </ng-container>
          <button mat-raised-button color="accent" (click)="downloadReport($event)"
                  [disabled]="!canExportPermission?.hasPermission" [matTooltip]="canExportPermission?.message">
            Export
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <hr>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-12">
          <ng-container *ngIf="!eepProgramsReadyForFTSubmitIsLoading">
            <button mat-stroked-button (click)="onQuickFilterClicked($event, eepProgramReadyForFTSubmit)"
                    *ngFor="let eepProgramReadyForFTSubmit of eepProgramsReadyForFTSubmit"
                    class="mr-2 mb-1"
                    matBadgeColor="accent"
                    [matBadge]="eepProgramReadyForFTSubmit.count"
                    matBadgePosition="after"
                    [disabled]="quickFilterIsLoading">
              {{ eepProgramReadyForFTSubmit.name }}
            </button>
          </ng-container>
          <ng-container *ngIf="eepProgramsReadyForFTSubmitIsLoading">
            <div class="skeleton block">
            </div>
          </ng-container>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <app-psr-filter-form (formChange)="onFilterFormChanged($event)" (isReady)="onFilterFormReady()" [defaultParams]="this.defaultParams" [storedParams]="this.storedParams"></app-psr-filter-form>
        </div>
        <div class="col-9">
          <app-generic-table [dataSource]="getDataSource" [storedParams]="storedParams" [displayedColumns]="displayedColumns" [routerParamsToQueryString]="true" (listChangeStarted)="onListChangeStarted()" (listChanged)="onListChanged()" #tableComponent>
            <ng-container matSort>
              <ng-container matColumnDef="home__street_line1">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Address</th>
                <td mat-cell *matCellDef="let row">
                  <app-home-display [home]="row.home_info" displayType="addressAsLink"></app-home-display>
                </td>
              </ng-container>

              <ng-container matColumnDef="home__subdivision__name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Subdivision/MF Development</th>
                <td mat-cell *matCellDef="let row">
                  <app-subdivision-display [subdivision]="row?.home_info?.subdivision_info" displayType="onlyNameAsLink"></app-subdivision-display>
                </td>
              </ng-container>

              <ng-container matColumnDef="floorplan__name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Floorplan</th>
                <td mat-cell *matCellDef="let row">
                  <a [routerLink]="['/', 'floorplans', row.floorplan]" target="_blank" *ngIf="currentUser.company_info?.company_type !== CompanyType.builder">
                    {{ row.floorplan_name }}
                  </a>
                  <ng-container *ngIf="currentUser.company_info?.company_type === CompanyType.builder">
                    {{ row.floorplan_name }}
                  </ng-container>

                </td>
              </ng-container>

              <ng-container matColumnDef="eep_program__name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Program</th>
                <td mat-cell *matCellDef="let row">
                  <a [routerLink]="['/', 'eep_program', row.eep_program]" target="_blank">
                    {{ row.eep_program_name }}
                  </a>
                </td>
              </ng-container>

              <ng-container matColumnDef="state">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Project Status</th>
                <td mat-cell *matCellDef="let row">
                  <a [routerLink]="['/', 'home', row.home]" target="_blank">
                    {{ tableStateLabelMapping[row.state] }}
                  </a>
                </td>
              </ng-container>
            </ng-container>
          </app-generic-table>
        </div>
      </div>
    </div>
  </div>
</div>
