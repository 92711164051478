import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin, iif, of, Subject } from 'rxjs';
import { User } from '@/data/core/models/user';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { UserService } from '@/data/core/services/user.service';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, switchMap, takeUntil } from 'rxjs/operators';
import { toggleLoading } from '@/state/actions/app.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { ProductService } from '@/data/product/services/product.service';
import { ProductPracticeItemService } from '@/data/customer-hirl/services/product-practice-item.service';
import { ProductPracticeItem } from '@/data/customer-hirl/models';
import {
  CustomerDocument,
  CustomerDocumentInfo,
} from '@/data/filehandling/models';
import { CustomerDocumentService } from '@/data/filehandling/services/customer-document.service';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';

export interface ProductPracticeItemChangeDialogData {
  product_practice_item: ProductPracticeItem;
  commit?: boolean;
}

@Component({
  selector: 'app-product-practice-item-change-dialog',
  templateUrl: './product-practice-item-change-dialog.component.html',
  styleUrls: ['./product-practice-item-change-dialog.component.scss'],
})
export class ProductPracticeItemChangeDialogComponent
  implements OnInit, OnDestroy
{
  public initialized: boolean;
  public isEditForm = false;
  public entity?: ProductPracticeItem;
  public commit = true;
  public retrieve = true;
  public form: FormGroup;
  public canSeeField = {};
  public currentUserIsCustomerOrSuper = false;

  public currentUser: User;

  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<ProductPracticeItemChangeDialogComponent>,
    private fb: FormBuilder,
    private userService: UserService,
    private productService: ProductService,
    private productPracticeItemService: ProductPracticeItemService,
    private customerDocumentService: CustomerDocumentService,
    private dialog: MatDialog,
    private uiHelperService: UIHelperService,
    @Inject(MAT_DIALOG_DATA)
    public data: ProductPracticeItemChangeDialogData
  ) {
    if (data?.product_practice_item) {
      this.entity = Object.assign({}, data?.product_practice_item);
    }
    this.commit = data?.commit || true;
    if (this.entity?.id) {
      this.isEditForm = true;
    }
  }

  ngOnInit() {
    const sources = {
      currentUser: this.store.select(getInfoUser).pipe(first()),
      practiceItemProduct: of(this.entity),
    };

    this.store.dispatch(toggleLoading({ payload: true }));

    if (this.isEditForm && this.retrieve) {
      sources.practiceItemProduct = this.productPracticeItemService
        .retrieve(this.entity.id, { ignoreStore: true })
        .pipe(takeUntil(this.componentDestroyed$), first());
    }

    forkJoin(sources)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: ({ currentUser, practiceItemProduct }) => {
          this.store.dispatch(toggleLoading({ payload: false }));

          this.currentUser = currentUser;
          this.currentUserIsCustomerOrSuper =
            this.currentUser.company_info?.slug ===
              CustomerHIRLSettings.companySlug || this.currentUser.is_superuser;

          this.entity = practiceItemProduct;
          this.setupForm();

          this.initialized = true;
          if (this.isEditForm) {
            this.hydrateForm();
          }
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  private setupForm() {
    this.form = this.fb.group({
      standard_info: [null, Validators.required],
      chapter_info: [null, Validators.required],
      section_info: [null, Validators.required],
      practice_info: [null, Validators.required],
      item_info: [null, Validators.required],
      points: [null],
      used_to_earn_points: [null],
      product_features: [null],
      rc_comments: [null],
      is_active: [true],
      document: [null, [Validators.required]],
    });

    this.canSeeField = {
      points: true,
      used_to_earn_points: this.currentUserIsCustomerOrSuper,
      product_features: true,
      is_active: true,
      rc_comments: this.currentUserIsCustomerOrSuper,
      is_featured: this.currentUserIsCustomerOrSuper,
    };

    this.form
      .get('standard_info')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe(value => {
        this.form.get('chapter_info').reset();
        this.form.get('section_info').reset();
        this.form.get('practice_info').reset();
        this.form.get('item_info').reset();
        this.form.get('points').reset();
        this.form.get('document').reset();
      });

    this.form
      .get('chapter_info')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe(value => {
        this.form.get('section_info').reset();
        this.form.get('practice_info').reset();
        this.form.get('item_info').reset();
        this.form.get('points').reset();
        this.form.get('document').reset();
      });

    this.form
      .get('section_info')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe(value => {
        this.form.get('practice_info').reset();
        this.form.get('item_info').reset();
        this.form.get('points').reset();
        this.form.get('document').reset();
      });

    this.form
      .get('practice_info')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe(value => {
        this.form.get('item_info').reset();
        this.form.get('points').reset();
        this.form.get('document').reset();
      });

    this.form
      .get('item_info')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe(value => {
        this.form.get('points').reset();
        this.form.get('document').reset();
      });
  }

  hydrateForm() {
    this.form
      .get('standard_info')
      .patchValue(
        this.entity?.item_info?.practice_info?.section_info?.chapter_info
          ?.standard_info,
        {
          emitEvent: true,
          onlySelf: true,
        }
      );
    this.form
      .get('chapter_info')
      .patchValue(
        this.entity?.item_info?.practice_info?.section_info?.chapter_info,
        {
          emitEvent: true,
          onlySelf: true,
        }
      );
    this.form
      .get('section_info')
      .patchValue(this.entity?.item_info?.practice_info?.section_info, {
        emitEvent: true,
        onlySelf: true,
      });

    this.form
      .get('practice_info')
      .patchValue(this.entity?.item_info?.practice_info, {
        emitEvent: true,
        onlySelf: true,
      });

    this.form.get('item_info').patchValue(this.entity?.item_info, {
      emitEvent: true,
      onlySelf: true,
    });

    this.form.get('points').patchValue(this.entity?.points, {
      emitEvent: true,
      onlySelf: true,
    });

    this.form
      .get('used_to_earn_points')
      .patchValue(this.entity?.used_to_earn_points, {
        emitEvent: true,
        onlySelf: true,
      });

    this.form.get('rc_comments').patchValue(this.entity?.rc_comments, {
      emitEvent: true,
      onlySelf: true,
    });

    this.form
      .get('product_features')
      .patchValue(this.entity?.product_features, {
        emitEvent: true,
        onlySelf: true,
      });
    this.form.get('is_active').patchValue(this.entity?.is_active, {
      emitEvent: true,
      onlySelf: true,
    });
  }

  save($event: MouseEvent) {
    $event.preventDefault();
    if (this.isEditForm) {
      this.edit();
    } else {
      this.create();
    }
  }

  private create() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    if (!this.commit) {
      this.dialogRef.close(this.entity);
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const data: CustomerDocument = new CustomerDocument();
    data.login_required = false;
    data.is_public = true;

    this.userService
      .documentsCreate(this.currentUser.id, data)
      .pipe(
        first(),
        // Conditionally update file fields if document exists
        switchMap((customerDocument: CustomerDocument) =>
          iif(
            () => !!this.form.value.document?.name,
            of(customerDocument).pipe(
              switchMap(() => {
                const formData = new FormData();
                formData.append('document', this.form.value.document);
                return this.customerDocumentService.update(
                  customerDocument.id,
                  formData
                );
              })
            ),
            of(customerDocument)
          )
        )
      )
      .pipe(
        first(),
        switchMap((customerDocument: CustomerDocument) => {
          const productPracticeItemData = new ProductPracticeItem();

          productPracticeItemData.item = this.form.value.item_info?.id;
          productPracticeItemData.points = this.form.value.points;
          productPracticeItemData.customer_document = customerDocument?.id;
          productPracticeItemData.product_features =
            this.form.value.product_features;
          productPracticeItemData.rc_comments = this.form.value.rc_comments;
          productPracticeItemData.used_to_earn_points =
            this.form.value.used_to_earn_points;
          productPracticeItemData.is_active = this.form.value.is_active;

          return this.productService.product_practice_itemsCreate(
            this.entity.product,
            productPracticeItemData
          );
        })
      )
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: (productPracticeItem: ProductPracticeItem) => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close(productPracticeItem);
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  private edit() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    if (!this.commit) {
      this.dialogRef.close(this.entity);
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const data: CustomerDocument = new CustomerDocument();
    data.login_required = false;
    data.is_public = true;

    of(this.entity)
      .pipe(
        first(),
        switchMap((productPracticeItem: ProductPracticeItem) => {
          if (this.form.value.document?.name) {
            return this.userService
              .documentsCreate(this.currentUser.id, data)
              .pipe(
                first(),
                // update file fields
                switchMap((customerDocument: CustomerDocument) => {
                  const formData = new FormData();
                  formData.append('document', this.form.value.document);
                  return this.customerDocumentService.update(
                    customerDocument.id,
                    formData
                  );
                })
              );
          }

          return of(productPracticeItem.customer_document_info);
        })
      )

      .pipe(
        first(),
        switchMap(
          (customerDocument: CustomerDocument | CustomerDocumentInfo) => {
            const productPracticeItemData = new ProductPracticeItem();
            productPracticeItemData.item = this.form.value.item_info?.id;
            productPracticeItemData.points = this.form.value.points;
            productPracticeItemData.customer_document = customerDocument?.id;
            productPracticeItemData.product_features =
              this.form.value.product_features;
            productPracticeItemData.rc_comments = this.form.value.rc_comments;
            productPracticeItemData.used_to_earn_points =
              this.form.value.used_to_earn_points;
            productPracticeItemData.is_active = this.form.value.is_active;

            return this.productPracticeItemService.update(
              productPracticeItemData,
              this.entity.id
            );
          }
        )
      )
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: (entity: ProductPracticeItem) => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close(entity);
        },
        error: (error: HttpErrorResponse) =>
          this.uiHelperService.handleUserRequestError(error),
      });
  }
}
