export const ERRORS = {
  required: 'This field must not be empty',
  nonNull: 'This fields cannot be none',
  invalidMathExpression: 'Invalid math expression',
  minItemRequired: 'Please add at least {{min}} {{name}}',
  missingFrameFloorsInLocations:
    'Missing frame-floors/slabs in locations: {{locations}}',
  min: 'Expected minimum value to be {{min}}, found {{actual}}',
  max: 'Expected maximum value to be {{max}}, found {{actual}}',
  largerThanOne: 'Value cannot be larger than 1',
  perimeterGreaterThanArea: 'Perimeter cannot be greater than area',
  enumInvalid: 'Invalid choice "{{value}}" for the field ',
  minlength:
    'Minimum length of {{requiredLength}} characters required, found {{actualLength}}.',
  maxlength:
    'Maximum length of {{requiredLength}} characters required, found {{actualLength}}.',
  autoSizeCapacityError: 'Auto size capacity not allowed',

  // U & R value.
  uValueOutOfRange: 'U-value should be between 0.01 and 1, found {{actual}}',
  rValueOutOfRange: 'R-value should be between 0.01 and 100, found {{actual}}',
  framingFactorRequired: 'Framing factor is required',
  materialsThicknessGraterThanStudDepth:
    'Sum of materials depth ({{materialsThickness}}) should not be greater than ' +
    'stud depth ({{studDepth}})',
  flangeThicknessRequiredForSteelStud:
    'Flange thickness is required for a steel stud.',
  studDetailsRequired: 'Please provide details for {{invalidFields}}',

  // Simulation
  fenestrationAreaGreaterThanAboveGradeWallArea:
    'Sum total of doors and windows area attached to above grade walls ({{wallNames}}) is larger than the wall area.',
  fenestrationAreaGreaterThanFoundationWallArea:
    'Sum total of doors and windows area attached to foundation walls ({{wallNames}}) is larger than the wall area.',
  frameFloorsAttachedToFoundationWalls:
    'Foundation wall ({{foundationWallNames}}) borders {{locations}} but there is no frame floor attached to that location',
  frameFloorsAreaGreaterThanConditionedFloorArea:
    'Sum of frame floors area {{frameFloorsArea}} is greater than conditioned floor area {{conditionedFloorArea}}',
  unsupportedRoofCount:
    'Only 1 roof is supported at the moment for single family home',
  atleastAFrameFloorOrSlabRequired:
    'At least one frame floor or a slab is required',
  heatingPercentServed:
    'Heating equipment must sum to serve 100%, found {{actualPercentServed}}%',
  coolingPercentServed:
    'Cooling equipment must sum to serve 100%, found {{actualPercentServed}}%',
  waterHeaterPercentServed:
    'Water heater equipment must sum to serve 100%, found {{actualPercentServed}}%',
  dehumidifierPercentServed:
    'Dehumidifier equipment must sum to serve 100%, found {{actualPercentServed}}%',
  conditionedAreaLessThanMin:
    'Conditioned area {{conditionedArea}} is less than 160',
  conditionedVolumeLessThanMin:
    'Conditioned volume {{conditionedVolume}} is less than 1280',
  invalidDuctForHeater: 'Invalid duct assigned to DUCTLESS heater',
  shouldContainEitherRoofOrFrameFloorAsCeiling:
    'Should contain either a roof or a frame floor as ceiling',
  requiredDepthBelowGrade:
    'Please add value for Depth Below grade on slab {{name}}',
  requiredWaterHeater: 'You must provide a water heating equipment',
  WaterHeaterPercentageServedError:
    'Water Heater ({{waterheater}}) should have some water heating percent served',
  crawlspaceSlabNoFoundationWall:
    'Simulation has a {{slabName}}(slab) at {{location}} but ' +
    'does not have a corresponding foundation wall at that location.',
  invalidZerhCalculationChoice:
    'For the "{{residence}}" residence type, select only the {{type}} ZERH calculation',
  invalidEnergyStarCalculationChoice:
    'For the "{{residence}}" residence type, select only the {{type}} Energy Star calculation',
  invalidCoolingDistributionSystem:
    'Missing required distribution system on {{coolingSystemName}}',
  invalidMechanicalEfficiency:
    '{{type}} efficiency may not be 0 if {{percentType}} is not 0 on {{equipmentName}} equipment',
  invalidMechanicalCapacity:
    '{{type}} capacity may not be 0 if {{percentType}} is not 0 on {{equipmentName}} equipment',
  invalidMotorType:
    'Mini-Split Heat Pumps require a variable speed motor on {{equipmentName}}. This will be set to variable speed',
  invalidSystemForConditionedSpace:
    'The home has conditioned area, but there is no heating or cooling equipment defined',
  invalidCeilingCount:
    'Invalid ceiling count for Single Family Home. Expected 1, found 0 roof',
  sealedAtticWithinInfiltrationVolume:
    'Sealed attics for the home must all flagged as within the infiltration volume or not.',
  invalidSkylightArea:
    'Total skylight area {{totalSkylightArea}} is outside the legal range 0-300',
  missingGarageSlabs:
    'Missing slab on explicitly defined garage. {{locations}}',
  missingGarageAboveGradeWalls:
    'Missing above grade wall on explicitly defined garage. {{locations}}',
  missingGarageRoofs:
    'Missing roofs or floors on explicitly defined garage. {{locations}}',
  unsupportedHvacCount:
    'This simulation has too many HVAC systems for Home Energy Score only 2 HVAC system is supported at the moment',

  // Window
  overhangDepthBottomGreaterThanTop:
    'Overhang depth to bottom of Window must be greater than the distance to the top of Window',

  // Project
  noCompanyFound: 'Unable to find company with name "{{name}}"',

  // Above grade wall

  // Water Heater
  invalidEfficiencyHeatPump:
    'Efficiency for heat pump water heater should be between 1 and 5',
  invalidEfficiency: 'Invalid efficiency value {{efficiency}}',
  tankSizeTooSmall: 'Tank size should be greater than 5 found ({{tankSize}})',
  invalidTankUnits: 'Cannot handle tanks in units of {{tankUnits}}',

  // Roof
  ceilingAreaExceedsRoofArea:
    'Ceiling area ({{ceilingArea}}) should not be greater than roof area ({{roofArea}})',

  // Rim Joist
  invaliRimJoistUValue: 'Invalid Rim Joist U-Value',

  // Door
  mustBeAtachedTowall: '{{name}} must be attached to a wall',
  atachedToMultiplewall: '{{name}} cannot be attached to multiple walls',

  // Mechanical Equipment
  invalidEfficiencyUnit: 'Unexpected efficiency unit {{efficiencyUnit}}',
  efficiencyBelowRange:
    'Efficiency {{efficiency}} {{efficiencyUnit}} is below the allowed range of {{range}}',
  invalidDehumidifierPercent:
    '{{equipment}} cannot have a dehumidifier percent served of {{dehumidifierPercentServed}}',
  dehumidifierPercent:
    '{{equipment}} must have some dehumidifier percent served',
  invalidCoolingPercent:
    '{{equipment}} cannot have a cooling percent served of {{coolingPercentServed}}',
  coolingPercent: '{{equipment}} must have some cooling percent served',
  invalidHeatingPercent:
    '{{equipment}} cannot have a heating percent served of {{heatingPercentServed}}',
  heatingPercent: '{{equipment}} must have some heating percent served',
  invalidWaterHeaterPercent:
    '{{equipment}} cannot have a water heater percent served of {{waterHeaterPercentServed}}',

  // Air Conditioner
  invalidAirConditionerCapacity:
    'Invalid air conditioner capacity {{capacity}}',
  efficiencyOutOfRange:
    'Efficiency {{efficiency}} {{efficiencyUnit}} is outside the allowed range of {{below}}-{{above}}',

  // Ground Source Geat Pump
  invalidGshCoolingEfficiency:
    'Cooling efficiency {{coolingEfficiency}} is outside the allowed range of 10-60',
  invalidHeatingEfficiencyUnit:
    'Heating efficiency unit on must be one of HSPF/HSPF2/COP',
  invalidBackupHeatingEfficiencyUnit:
    'Backup Heating efficiency unit must be one of AFUE / Percent Efficiency',
  invalidSwitchOverTemperatureUnit:
    'Heat pump switch over temperature must be Fahrenheit',

  // Appliances
  clothesDryerEfficiency:
    "Clothes dryer has a location and fuel you must indicate it's efficiency",
  isMoistureSensingDryer:
    'Clothes dryer has efficiency defined you must indicate whether its moisture sensing or not',
  noClothesDryerFuel:
    "Clothes dryer has efficiency defined you must indicate it's fuel",
  noClothesDryerLocation:
    'Clothes dryer has efficiency defined you must indicate it location',
  invalidCeilingFanConsumption: 'Ceiling Fan consumption is not set',
  invalidCeilingFanConsumptionUnit:
    'Ceiling Fan consumption unit must be CFM/Watt',

  // Distribution System
  fieldTestTotalLeakageRequired:
    'Field test total leakage is required for {{testType}}',
  invalidFieldTestTotalLeakage:
    'Field test total leakage should be between 0 and 100, found {{fieldTestTotalLeakage}}',
  areaServedForForcedAir:
    'Area served for system type forced air cant be less than 1',

  // Utility Rates
  invalidConsumption:
    'Minimum consumption must be less than maximum consumption',
  invalidDay: 'Start day must be less than end day',
  invalidHour: 'Start hour must be less than end hour',
  invalidMinute: 'Start minute must be less than end minute',
  invalidBlockRates: 'At least one block rate is required',

  // Light
  totalLightExceeds100OnLocations:
    'Total light percentage (CFL + Florescent + LED) exceeds 100% on {{locations}}',

  // Thermostat
  missingHeatingSetpoint: 'Missing heating setpoint for thermostat',
  missingCoolingSetpoint: 'Missing cooling setpoint for thermostat',

  // Infiltartion
  invalidInfiltrationUnit:
    'HES requires infiltration be measured in ACH50 or CFM50',

  // Mechanical Ventilation
  oseriRequiredASRE: 'Please Set Adjusted Sensible Recovery Efficiency(ASRE)',
  oseriAsreNotRequired:
    'Mechanical Ventilation {{type}} normally does not have Adjusted Sensible Recovery Efficiency(ASRE)',
  oseriRequiredATRE: 'Please Set Adjusted Total Recovery Efficiency(ATRE)',
  oseriAtreNotRequired:
    'Mechanical Ventilation {{type}} normally does not have Adjusted Total Recovery Efficiency(ATRE)',
  requiredUseType: 'You need to set a use type',

  // Location
  elevationOutOfRange: 'Invalid altitude of {{elevation}}',
  invalidLatitude: 'Invalid latitude of must be in the range of -90 to 90',
};

export const WARNINGS = {
  // General
  requiredAsWarning: 'Please consider providing a value',
  aboveWarningRange: '{{actual}} seems above warning range ({{max}})',
  belowWarningRange: '{{actual}} seems below warning range ({{min}})',
  autoSizeCapacityWarn: 'Auto size capacity not allowed',

  // Simulation
  fenestrationAreaMoreThanExpectedAboveGradeWallArea:
    'Sum total of doors and windows area attached to above grade walls ({{wallNames}}) is larger than ' +
    '85% of the wall area.',
  fenestrationAreaMoreThanExpectedFoundationWallArea:
    'Sum total of doors and windows area attached to foundation walls ({{wallNames}}) is larger than ' +
    '85% of the wall area.',
  windowToFloorAreaRatio:
    'Window to floor area ratio should be between 7 and 35, found {{ratio}}',
  washerConsumptionNotWithinLimit:
    'Clothes washer electric consumption for {{bedroomCount}} bedrooms should be between ' +
    '{{lowerLimit}} and {{upperLimit}}, found {{consumptionPerYear}}',
  bedroomCountExceedsConditionedArea:
    'Conditioned area of "{{conditionedArea}}" sqft. cannot support "{{bedroomCount}}" bedroom home',
  bedroomCountExceedsConditionedVolume:
    'Conditioned volume {{conditionedVolume}} cuft. cannot support "{{bedroomCount}}" bedroom home',
  missingUtilityRates:
    'Utility rates are missing for the following fuels: {{fuels}}',
  WaterHeaterPercentageServedWarning:
    'Water Heater ({{waterheater}}) should have some water heating percent served',
  supplyAreaLessthanTarget:
    'Distribution Supply Area {{supplySurfaceArea}} is < 80% of recommended value of {{target}} on {{distributionName}}',
  supplyAreaMorethanTarget:
    'Distribution Supply Area {{supplySurfaceArea}} is > 120% of recommended ASHRAE value of {{target}} on {{distributionName}}',
  returnAreaLessthanTarget:
    'Distribution Return Area {{returnSurfaceArea}} is < 80% of recommended value of {{target}} on {{distributionName}}',
  returnAreaMorethanTarget:
    'Distribution Return Area {{returnSurfaceArea}} is > 120% of recommended ASHRAE value of {{target}} on {{distributionName}}',
  garageSlabAreaLargeThanCeilingArea:
    'Garage Slab area ({{slabArea}} sqft) does not account for all garage roof and floor area ({{ceilingArea}} sqft) above it',
  garageCeilingAreaLargeThanSlabArea:
    'Garage roof and floor area ({{ceilingArea}} sqft) above garage slab does not account for all garage slab area ({{slabArea}} sqft) under it',
  accountedWallAreaLessThanMinArea:
    'Only {{accountedWallArea}} sqft of enclosure surfaces are attached to the garage. ' +
    'You should increase the enclosure surface area between garage and the attached components by ' +
    'approximately {{missing}}-{{maxArea}} sqft.',
  accountedWallAreaMoreThanMaxArea:
    'You have {{accountedWallArea}} sqft walls attached to the garage.  This seems very high. ' +
    'You should decrease the enclosure surface area between garage and attached ' +
    'components by approximately {{overMax}}-{{overMin}} sqft.',
  checkCrawlspaceDepthBelowGrade:
    '{{slabName}} depth delow grade({{slabDepth}}) does not ' +
    'match associated {{foundationWallName}} foundation wall depth below grade({{foundationWallDepth}})',
  checkCrawlSpacePerimeter:
    'Perimeter of {{slabName}}({{slabPerimeter}}) does not match ' +
    'associated {{foundationWallName}} foundation wall perimeter({{foundationWallPerimeter}})',
  checkCrawlspaceSlabArea:
    '{{slabName}} attached to {{location}} has an area of {{slabArea}} ' +
    'sqft. which does not match the associated frame floor area of {{frameFloorArea}} sqft.',
  multipleWaterHeaters:
    'Only one water heater is supported at the moment we found {{count}} water heaters',
  invalidHeatingDistributionSystem:
    '{{heater}} requires exactly 1 distribution system attached - found {{count}}',
  invalidDuctHeatingSystem:
    'No ducts found on ducted system for heater {{distributionName}}',
  invalidGrossWallArea:
    'Invalid gross wall area of {{grossWallArea}} sqft Above grade wall.',
  invalidExposedFloorArea:
    'Total exterior floor {{exposedFloorArea}} sqft. area found greater than conditioned area',
  invalidAtticFloorArea:
    'Total exterior floor {{exposedFloorArea}} sqft. area found less than attic area',

  // U & R value.
  unexpectedFramingFactor:
    'Framing factor ({{framingFactor}}) is {{percent}}% {{range}} than the ' +
    'ASHRAE recommended {{recommended}}%',

  // Foundation wall
  largeBasementWallHeight:
    'Unconditioned basement wall height ({{height}}) should be less than 20 feet',
  invalidBasementFramingFactor:
    'Basement framing factor ({{actual}}) should be between {{min}} and ' +
    '{{max}}',
  logicalInteriorLocation:
    'Depth Below Grade {{depthBelowGrade}} implies this is attached to a ' +
    "basement but it's assigned as a conditioned space. Please confirm inputs.",
  largeWallHeight:
    'Invalid foundation Wall height ({{height}}) should be between 0 and 20 feet',

  // Framefloor
  warningFloorCavityInsulationRValue:
    'Cavity insulation R-value ({{actual}}) seems outside the recommended ' +
    'range ({{min}} - {{max}})',
  invalidFloorCavityInsulationRValue:
    'Cavity insulation R-value ({{actual}}) should be between {{min}} and ' +
    '{{max}}',

  // Window
  overhangDepthDistance: 'Window is unrealistically narrow ({{distance}} ft.)',

  // Mechanical Equipment
  efficiencyLowWarning:
    'Efficiency {{efficiency}} {{efficiencyUnit}} is usually low',
  efficiencyHighWarning:
    'Efficiency {{efficiency}} {{efficiencyUnit}} is usually high',

  // Air Conditioner
  warningAirConditionerCapacity: 'Capacity {{capacity}} really close to edge',

  // Ground Source Geat Pump
  warningGshCoolingEfficiency: 'EFF value close to the edge',

  // Distribution System
  warningIfDSESystem:
    'A simplified DSE model is provided for flexibility, but it is strongly recommended ' +
    'to use a real detailed distribution system types for better accuracy.',
  heatingOrCoolingSystemRequired:
    'Either heating or cooling system is required for distribution system',

  // Light
  totalLightBelow50OnLocations:
    'Incandescent lights attributes above 50% on {{locations}}',

  // Slab
  invalidCrawlspaceSlabThickness:
    '{{slabName}} attached to crawlspace typically has a thickness of 0.0 you have {{slabThickness}}',

  // Mechanical Ventilation
  highWattsPerCFM:
    '{{type}} fan consumption of {{actual}} exceeds the allowed limit of {{wattLimit}} watts per CFM',
  requiredASRE: 'Adjusted Sensible Recovery Efficiency(ASRE) is not set',
  asreNotRequired: 'Mechanical Ventilation normally does not set ASRE.',
  asreTooLow:
    'Mechanical Ventilation ASRE is < {{percentage}} of recommended nominal minimum of {{nominalMin}}',
  asreTooHigh:
    'Mechanical Ventilation ASRE is > 125% of recommended nominal max of {{nominalMax}}',
  requiredATRE: 'Adjusted Total Recovery Efficiency(ATRE) is not set',
  atreNotRequired: 'Mechanical Ventilation normally does not set ATRE.',
  atreTooLow:
    'Mechanical Ventilation ATRE is < {{percentage}} of recommended nominal minimum of {{nominalMin}}',
  atreTooHigh:
    'Mechanical Ventilation ATRE is > 125% of recommended nominal max of {{nominalMax}}',

  // Location
  elevationWarning: 'Altitude is really close to the edge',
};

export const FLAGS = {
  // fenestrationAreaMoreThanExpectedAboveGradeWallArea: 'os_eri_strict', // Error or warning for general
  // fenestrationAreaMoreThanExpectedFoundationWallArea: 'os_eri_strict', // Error or warning for general
  // exteriorColorRequired: ['os_eri', 'home_energy_score'], // Warning only for flags
  logicalInteriorLocation: 'os_eri_strict', // Error or warning for general
  missingHeatingSetpoint: 'os_eri_strict', // Error or warning for general
  missingCoolingSetpoint: 'os_eri_strict', // Error or warning for general,
  // invalidTankUnits: ['os_eri', 'home_energy_score'], // Error for flags
  // invalidCeilingFanConsumption: 'os_eri', // Error
  // invalidCeilingFanConsumptionUnit: 'os_eri', // Error
  invalidInfiltrationUnit: 'home_energy_score', // Error
  oseriRequiredASRE: 'os_eri_strict', // Error or warning for general
  oseriAsreNotRequired: 'os_eri_strict', // Error or warning for general,
  oseriRequiredATRE: 'os_eri_strict', // Error or warning for general
  oseriAtreNotRequired: 'os_eri_strict', // Error or warning for general
  requiredUseType: 'os_eri_strict', // Error or warning for general
  autoSizeCapacityError: 'os_eri_strict', // Error or warning for general
  invalidSkylightArea: 'home_energy_score', // Error
  // invalidEfficiencyUnit: 'os_eri', // Error
  // multipleWaterHeaters: 'os_eri', // Error
  // invalidDuctCoolingSystem: 'os_eri', // Error
  // sealedAtticWithinInfiltrationVolume: 'os_eri', // Error
  unsupportedHvacCount: 'home_energy_score', // Error
};
