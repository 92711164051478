import {
  LightsBackendDict,
  createLightsForm,
} from '@/data/simulation/models/lights/Lights';
import { updateLights } from '@/modules/simulation/state/lights/actions';
import { selectLightsViewSet } from '@/modules/simulation/state/lights/selectors';
import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-lights',
  templateUrl: './lights.component.html',
  styleUrls: ['./lights.component.scss'],
})
export class LightsComponent {
  @Input() entityId: number;

  form: FormGroup;
  loading: boolean;
  componentDestroyed$ = new Subject();

  lightsCoverge: { [key: string]: number } = {};

  constructor(public store: Store) {}

  calculateCoverage() {
    const places = ['interior', 'exterior', 'garage'];
    const typeofLights = ['cfl', 'florescent', 'led'];

    this.lightsCoverge = {
      interior: 0,
      exterior: 0,
      garage: 0,
    };
    places.forEach(place => {
      typeofLights.forEach(light => {
        const lightPercentage = this.form.get(
          `${place}_${light}_percent`
        ).value;
        this.lightsCoverge[place] += Number(lightPercentage);
      });
    });
  }

  ngOnInit(): void {
    this.store
      .select(selectLightsViewSet(this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(({ data, loading }) => {
        this.loading = loading;
        if (loading) return;

        if (!this.form) {
          this.form = createLightsForm(data);
        } else {
          this.form.patchValue(data);
        }

        this.calculateCoverage();
        this.form.valueChanges.subscribe(() => {
          this.calculateCoverage();
        });
      });
  }

  onSave(lightsChanges: Partial<LightsBackendDict>) {
    this.loading = true;
    this.store.dispatch(updateLights({ id: this.entityId, lightsChanges }));
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
