import { createSelector } from '@ngrx/store';
import { SimulationState } from '../reducers';
import { selectSimulationFeature } from '../simulation.selectors';

export const selectThermostat = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.thermostat.entities[id]
  );

const selectThermostatLoading = (id: number) =>
  createSelector(
    selectSimulationFeature,
    state => state.thermostat.loading[id]
  );

export const selectThermostatViewSet = (id: number) =>
  createSelector(
    selectThermostat(id),
    selectThermostatLoading(id),
    (data, loading) => ({ data, loading })
  );

export const selectThermostatErrorsCount = (id: number) =>
  createSelector(selectSimulationFeature, (state: SimulationState) => ({
    errorCount: state.thermostat.errors[id].errorCount,
    warningCount: state.thermostat.errors[id].warningCount,
  }));
