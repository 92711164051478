// error-message.service.ts
import { Injectable } from '@angular/core';
import { ERRORS, WARNINGS } from '../errors-template';

@Injectable({
  providedIn: 'root',
})
export class ErrorMessageService {
  convertToReadableValue(value: any): string | string[] {
    if (Array.isArray(value)) {
      return value.map(data => this.convertToReadableValue(data)).join(', ');
    }

    if (typeof value === 'number') {
      if (!Number.isInteger(value)) {
        return value.toFixed(2);
      }
    }
    return value;
  }

  getMessage(errorId: string, data: { [key: string]: any }): string {
    const template = this.isWarning(errorId)
      ? WARNINGS[errorId]
      : ERRORS[errorId];
    if (!template || !data) {
      return template;
    }

    let errorMessage = template;
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        errorMessage = errorMessage.replace(
          `{{${key}}}`,
          this.convertToReadableValue(data[key])
        );
      }
    }
    return errorMessage;
  }

  isWarning(errorId: string): boolean {
    return WARNINGS.hasOwnProperty(errorId);
  }
}
