import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import {
  ZendeskCreateRequestResponse,
  ZendeskCreateRequest,
} from '@/data/core/models';

@Injectable({
  providedIn: 'root',
})
export class ZendeskService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/zendesk/`;

  constructor(private http: HttpClient, private store: Store<AppState>) {}

  request_create(
    zendeskRequest: ZendeskCreateRequest
  ): Observable<ZendeskCreateRequestResponse> {
    const payload = Object.assign(new ZendeskCreateRequest(), zendeskRequest);
    return this.http.post<ZendeskCreateRequestResponse>(
      `${this.baseUrl}request_create/`,
      payload
    );
  }
}
