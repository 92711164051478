import { createReducer, on } from '@ngrx/store';
import * as SimulationConfigActions from './actions';
import { SimulationConfig } from '@/data/simulation/models/SimulationConfig';
import { BaseState } from '../shared/base.state';
import { addStateErrorCount } from '../helper';

export type SimulationConfigState = BaseState<SimulationConfig>;

export const initialState: SimulationConfigState = {
  entities: {},
  loading: {},
  errors: {},
};

export const reducer = createReducer(
  initialState,
  on(
    SimulationConfigActions.loadSimulationConfigSuccess,
    (state, { simulationConfig, errors }) => ({
      entities: {
        ...state.entities,
        [simulationConfig.id]: simulationConfig,
      },
      loading: {
        ...state.loading,
        [simulationConfig.id]: false,
      },
      errors: {
        ...state.errors,
        ...addStateErrorCount(errors),
      },
    })
  ),

  on(SimulationConfigActions.updateSimulationConfig, state => {
    const id = Object.values(state.entities)[0].id;
    return {
      ...state,
      loading: {
        ...state.loading,
        [id]: true,
      },
    };
  }),

  on(SimulationConfigActions.updateSimulationConfigFailure, state => {
    const id = Object.values(state.entities)[0].id;
    return {
      ...state,
      loading: {
        ...state.loading,
        [id]: false,
      },
    };
  })
);
