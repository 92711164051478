import { WaterSystemBackendDict } from '@/data/simulation/models/systems/WaterSystem';
import { createAction, props } from '@ngrx/store';
import { ModelErrors, StateErrors } from '../shared/base.state';

export const loadWaterSystems = createAction(
  '[Simulation API] Load WaterSystem',
  props<{ waterSystems: WaterSystemBackendDict[] }>()
);

export const loadWaterSystemsSuccess = createAction(
  '[WaterSystem API] Load WaterSystems Success',
  props<{
    waterSystems: WaterSystemBackendDict[];
    errors: StateErrors<WaterSystemBackendDict>;
  }>()
);

export const updateWaterSystem = createAction(
  '[WaterSystem UI] Update WaterSystem',
  props<{
    id: number;
    waterSystemChanges: Partial<WaterSystemBackendDict>;
  }>()
);

export const updateWaterSystemSuccess = createAction(
  '[WaterSystem API] Update WaterSystem Success',
  props<{
    waterSystem: WaterSystemBackendDict;
    errors: ModelErrors<WaterSystemBackendDict>;
  }>()
);

export const updateWaterSystemFailure = createAction(
  '[WaterSystem API] Update WaterSystem Failure',
  props<{ id: number }>()
);
