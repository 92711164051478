<div class="card position-relative">
    <app-loading-overlay *ngIf="loading"></app-loading-overlay>
    <div class="card-body pb-0">
        <app-form formName="photovoltaicForm" [formGroup]="form" (save)="onSave($event)">
            <div>
                <app-input formControlName="name" [label]="fieldLabels.name"></app-input>
                <app-input formControlName="area" type="expression" [label]="fieldLabels.area" unit="{{areaLabels['sq_ft']}}"></app-input>
            </div>
            <div>
                <app-input formControlName="capacity" type="number" [label]="fieldLabels.capacity"></app-input>
                <app-select formControlName="capacity_units" [items]="capacityUnitLabels" [label]="fieldLabels.capacity_units">
                </app-select>
            </div>
            <div>
                <app-input formControlName="derate_factor" type="number" [label]="fieldLabels.derate_factor"></app-input>
                <app-input formControlName="tilt" type="number" [label]="fieldLabels.tilt" unit="&deg;"></app-input>
            </div>
            <div>
                <app-select formControlName="location" [items]="locationLabels" [label]="fieldLabels.location"></app-select>
                <app-select formControlName="track_mode" [items]="trackModeLabels" [label]="fieldLabels.track_mode"></app-select>
            </div>
            <div>
                <app-input formControlName="azimuth" type="number" [label]="fieldLabels.azimuth"  unit="0-360°"></app-input>
                <app-input formControlName="percent_inverter_efficiency" type="number" [label]="fieldLabels.percent_inverter_efficiency" unit="%"></app-input>
            </div>
        </app-form>
    </div>
    <div class="card-footer p-0 text-right">
        <app-model-action-buttons [entityId]="entityId" modelName="photovoltaic" (clone)="clone()" (delete)="remove()">
        </app-model-action-buttons>
    </div>
</div>
