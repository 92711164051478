<div class="file-input-container">
  <ngx-dropzone (change)="onSelect($event)" [multiple]="false" [accept]="accept" [maxFileSize]="maxFileSize" #dropzone>
        <span *ngIf="!fileControl.value?.name" class="dropzone-label">
            {{ placeholder }}
        </span>
    <span *ngIf="fileControl.value?.name">
            {{ this.fileControl.value.name }}
        </span>
  </ngx-dropzone>
  <mat-spinner matSuffix diameter="20" *ngIf="isLoading"></mat-spinner>
  <button mat-button *ngIf="fileControl.value?.name && !isLoading"
          matSuffix mat-icon-button
          aria-label="Clear" class="clear-btn">
    <mat-icon>close</mat-icon>
  </button>
</div>
