import {
  AnalysisBackendDict,
  AnalysisEngine,
  AnalysisEngineLabels,
  AnalysisListItem,
  AnalysisTypeLabels,
} from '@/data/simulation/models/reports/Analysis';
import { Component, Input } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.scss'],
})
export class AnalysisComponent {
  filesToDownload: {
    name: string;
    fileType: string;
    urlKey: keyof AnalysisListItem['urls'];
    requiresBigLadder: boolean;
  }[] = [
    {
      name: 'Fuel usage report',
      fileType: 'pdf',
      urlKey: 'fuel_summary_report',
      requiresBigLadder: false,
    },
    {
      name: 'incoming-hpxml',
      fileType: 'xml',
      urlKey: 'incoming_xml',
      requiresBigLadder: false,
    },
    {
      name: 'os-eri-hpxml',
      fileType: 'xml',
      urlKey: 'open_studio_hpxml',
      requiresBigLadder: true,
    },
    {
      name: 'energy-plus-raw',
      fileType: 'html',
      urlKey: 'html_results_document',
      requiresBigLadder: false,
    },
    {
      name: 'hourly_usage_report',
      fileType: 'csv',
      urlKey: 'open_studio_hourly_results',
      requiresBigLadder: false,
    },
    {
      name: 'hers_diagnostic_report',
      fileType: 'json',
      urlKey: 'diagnostic_output_results',
      requiresBigLadder: true,
    },
  ];
  @Input() entity: AnalysisListItem;
  @Input() change: number;
  @Input() simulationId: number;
  @Input() bigLadderFilesAllowed: boolean;

  expanded = false;
  statusIcon: string;
  detailedAnalysis: AnalysisBackendDict;
  eplusEngine = AnalysisEngine.EPLUS;
  analysisTypeLabels = AnalysisTypeLabels;
  analysisEngineLabels = AnalysisEngineLabels;
  fileCount = 0;

  ngOnInit() {
    switch (this.entity.status) {
      case 'failed':
        this.statusIcon = 'error';
        break;
      case 'complete':
        this.statusIcon = 'check_circle';
        break;
      case 'pending':
        this.statusIcon = 'pending';
        break;
      case 'started':
        this.statusIcon = 'pending';
        break;
      default:
        this.statusIcon = null;
        break;
    }
    this.detailedAnalysis = this.entity;

    this.filesToDownload.forEach(file => {
      if (file.requiresBigLadder && !this.bigLadderFilesAllowed) return;

      if (this.entity.urls[file.urlKey]) {
        this.fileCount++;
      }
    });
  }

  getURL(urlKey) {
    return `${environment.AXIS_LEGACY_ROOT}${this.entity.urls[urlKey]}`;
  }
  downloadFile(urlKey: keyof AnalysisListItem['urls']) {
    try {
      const url = this.getURL(urlKey);
      const link = document.createElement('a');
      link.href = url;
      link.download = url.split('/').pop();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  }
}
