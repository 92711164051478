import {
  Directive,
  ElementRef,
  Input,
  HostListener,
  Renderer2,
} from '@angular/core';
import { SimulationModelsState } from '../state/reducers';

@Directive({
  selector: '[appFocusTarget]',
})
export class FocusTargetDirective {
  @Input() entityName!: keyof SimulationModelsState;
  @Input() entityId!: string;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    const generatedId = `${this.entityName}${this.entityId}`;
    this.el.nativeElement.id = generatedId;

    if (!this.el.nativeElement.hasAttribute('tabindex')) {
      this.renderer.setAttribute(this.el.nativeElement, 'tabindex', '0');
    }
  }

  @HostListener('focus')
  onFocus() {
    this.renderer.setStyle(
      this.el.nativeElement,
      'border',
      '2px solid #bf0d25'
    );
    this.renderer.setStyle(
      this.el.nativeElement,
      'box-shadow',
      '0 20px 30px rgba(191,13, 37,.1)'
    );
  }

  @HostListener('blur') onBlur() {
    this.renderer.removeStyle(this.el.nativeElement, 'border');
    this.renderer.removeStyle(this.el.nativeElement, 'box-shadow');
  }
}
