import { selectSkylightErrorViewSet } from '@/modules/simulation/state/skylight/selectors';
import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-skylight-errors',
  templateUrl: './skylight-errors.component.html',
  styleUrls: ['./skylight-errors.component.scss'],
})
export class SkylightErrorsComponent {
  @Input() entityId: number;
  componentDestroyed$: Subject<boolean> = new Subject();
  name: string;
  skylightTypeId: number;

  totalErrorCount = 0;
  totalWarningCount = 0;
  skylightTypeErrorCount = 0;
  skylightTypeWarningCount = 0;

  constructor(public store: Store) {}

  ngOnInit(): void {
    this.store
      .select(selectSkylightErrorViewSet(this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.name = data.name;
        this.skylightTypeId = data.skylightTypeId;

        const nestedModelsErrorCount = data.nestedModelsErrorCount;
        this.skylightTypeErrorCount =
          nestedModelsErrorCount.skylightType.errorCount;
        this.skylightTypeWarningCount =
          nestedModelsErrorCount.skylightType.warningCount;

        this.totalErrorCount = 0;
        this.totalErrorCount += this.skylightTypeErrorCount;
        this.totalErrorCount += nestedModelsErrorCount.skylight.errorCount;

        this.totalWarningCount = 0;
        this.totalWarningCount += this.skylightTypeWarningCount;
        this.totalWarningCount += nestedModelsErrorCount.skylight.warningCount;
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
