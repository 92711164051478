<div mat-dialog-title class="d-flex justify-content-between align-items-center">
    <h2 mat-dialog-title>Shelter Class</h2>
    <button mat-icon-button aria-label="Close Dialog" mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <p>
        The Shelter Class indicates the impact of a home's surroundings on the wind pressures experienced by the home.
    </p>
    <table style="width: 100%; border-collapse: separate; border-spacing: 0 10px;">
        <thead>
            <tr>
                <th>Class</th>
                <th style="text-align: center;">Description</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td><strong>1</strong></td>
                <td>No obstructions or local shielding.</td>
            </tr>
            <tr>
                <td><strong>2</strong></td>
                <td>Light local shielding with few obstructions; typical of an isolated rural house.</td>
            </tr>
            <tr>
                <td><strong>3</strong></td>
                <td>Moderate local shielding, such as that provided by other buildings across the street.</td>
            </tr>
            <tr>
                <td><strong>4</strong></td>
                <td>Heavy shielding with wind obstructions around most of the perimeter; typical of suburban homes.</td>
            </tr>
            <tr>
                <td><strong>5</strong></td>
                <td>Very heavy shielding around the house perimeter, provided by closely spaced homes, mature trees,
                    bushes, etc.</td>
            </tr>
        </tbody>
    </table>
</mat-dialog-content>