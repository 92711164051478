<div class="row">
  <div class="col-12">
    <div class="row mb-2">
      <div class="col-9">
      </div>
      <div class="col-3 text-right">
        <app-permission-mat-button [permissionResponse]="createPermissionResponse" (action)="create($event)">
          Add New
        </app-permission-mat-button>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <app-table-filters [formGroup]="filterFormGroup" [params]="this.storedParams"
                           [overwritePropertyValues]="overwritePropertyValues"
                           [overwritePropertyLabels]="overwritePropertyLabels"
                           [excludedProperties]="excludedProperties">
          <mat-accordion multi>
            <mat-expansion-panel [expanded]="true">
              <div class="row">
                <div class="col-12">
                  <mat-form-field class="w-100">
                    <mat-label>Search</mat-label>
                    <app-search-input formControlName="search"
                                      placeholder="Type to search something"></app-search-input>
                  </mat-form-field>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </app-table-filters>
      </div>
      <div class="col-9">
        <div class="row" *ngIf="isLoading">
          <div class="col-12">
            <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
          </div>
        </div>
        <table mat-table
               [dataSource]="rows"
               class="table"
               matSort
               [matSortActive]="storedParams.toTableSort().active"
               [matSortDirection]="storedParams.toTableSort().direction"
               (matSortChange)="onSortChange($event)">

          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>ID</th>
            <td mat-cell *matCellDef="let row">
              {{ row.id }}
            </td>
          </ng-container>

          <ng-container matColumnDef="task_type">
            <th mat-header-cell *matHeaderCellDef>Type</th>
            <td mat-cell *matCellDef="let row">
              {{ row.task_type_info?.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="assignees">
            <th mat-header-cell *matHeaderCellDef>Assignee(s)</th>
            <td mat-cell *matCellDef="let row">
              <ng-container *ngFor="let userInfo of row.assignees_info; let isLast=last">
                <app-user-display [user]="userInfo" displayType="onlyNameAsLink"></app-user-display>{{ isLast ? '' : ', '}}
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="datetime">
            <th mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let row">
              {{ row.datetime | date: 'MM/dd/yyyy HH:MM'  }}
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let row">
              {{ TaskStatusLabelMapping[row.status] }}
            </td>
          </ng-container>

          <ng-container matColumnDef="approval_state">
            <th mat-header-cell *matHeaderCellDef>Approval</th>
            <td mat-cell *matCellDef="let row">
              {{ TaskApprovalStateLabelMapping[row.approval_state] }}
            </td>
          </ng-container>

          <ng-container matColumnDef="note">
            <th mat-header-cell *matHeaderCellDef>Note</th>
            <td mat-cell *matCellDef="let row">
              {{ row.note }}
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let row">
              <app-permission-mat-button [permissionResponse]="permissions[row.id]['canEdit']" displayType="asLink" (action)="edit($event, row);" class="mr-2">
                <fa-icon [icon]="['fas', 'edit']" matTooltip="Edit"></fa-icon>
              </app-permission-mat-button>
              <app-permission-mat-button [permissionResponse]="permissions[row.id]['canDelete']" displayType="asLink" (action)="delete($event, row);" class="mr-2">
                <fa-icon [icon]="['fas', 'trash']" matTooltip="Delete"></fa-icon>
              </app-permission-mat-button>
              <app-history-mat-button displayType="asLink" [objectId]="row.id" [modelClassName]="'scheduling_task'">
                <fa-icon [icon]="['fas', 'list']" matTooltip="History"></fa-icon>
              </app-history-mat-button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [length]="rowsCount"
                       [pageIndex]="$any(storedParams.page) - 1"
                       [pageSize]="storedParams.page_size"
                       (page)="onPaginateChange($event)"
                       aria-label="Select page">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
