<div *ngIf="initialized;else loading;">
    <div mat-dialog-title>
        <div class="row">
            <div class="col-6">
                <h3>
                    <span *ngIf="!isLoading">{{ rowsCount | number }}</span>
                    <fa-icon [icon]="['fas', 'spinner']" [spin]="true" *ngIf="isLoading"></fa-icon>
                    QA Notes
                </h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <mat-divider></mat-divider>
            </div>
        </div>
    </div>
    <div mat-dialog-content>
        <div class="row">
            <div class="col-3">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <form [formGroup]="filterFromGroup">
                                <h5 class="card-header">Filters <a href="#" class="small"
                                                                   (click)="resetFilters($event);">Reset
                                    filters</a></h5>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Search</mat-label>
                                                <app-search-input formControlName="search"
                                                                  placeholder="Type to search something"></app-search-input>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-9">
                <ngx-datatable
                        class="material striped"
                        [rows]="rows"
                        columnMode="force"
                        headerHeight="40"
                        rowHeight="auto"
                        footerHeight="50"
                        [limit]="storedParams.page_size"
                        [externalSorting]="true"
                        (sort)="onSort($event)"
                        [loadingIndicator]="isLoading">

                    <ngx-datatable-column name="Last Update" [sortable]="false">
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                            {{ row.last_update | date: 'mm/dd/yyy HH:MM' }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="User" [sortable]="false">
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                            {{ row.user_name }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Observations" [sortable]="false">
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                            {{ row.observation_names }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Note" [sortable]="false">
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                            {{ row.note }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-footer>
                        <ng-template
                                ngx-datatable-footer-template>
                            <div class="page-count col-3 text-center" *ngIf="rowsCount">
                                Showing {{ storedParams.page }}
                                to {{ rowsCount | divide: storedParams.page_size }}
                                of {{ rowsCount }}
                            </div>
                            <div class="col-9 text-right">
                                <datatable-pager
                                        [pagerLeftArrowIcon]="'datatable-icon-left'"
                                        [pagerRightArrowIcon]="'datatable-icon-right'"
                                        [pagerPreviousIcon]="'datatable-icon-prev'"
                                        [pagerNextIcon]="'datatable-icon-skip'"
                                        [page]="storedParams.page"
                                        [size]="storedParams.page_size"
                                        [count]="rowsCount"
                                        (change)="onFooterPage($event)"
                                >
                                </datatable-pager>
                            </div>
                        </ng-template>
                    </ngx-datatable-footer>
                </ngx-datatable>
            </div>
        </div>
    </div>
    <mat-dialog-actions align="end">
        <button mat-raised-button (click)="cancelClick()">
            Close
        </button>
    </mat-dialog-actions>
</div>
<ng-template #loading>
    <div mat-dialog-content>
        <div class="row">
            <div class="col-12">
                <div class="d-flex justify-content-center align-items-center" style="height: 320px;">
                    <mat-spinner [diameter]="50"></mat-spinner>
                </div>
            </div>
        </div>
    </div>
</ng-template>