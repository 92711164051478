import { ProjectBackendDict } from '@/data/simulation/models/Project';
import { createAction, props } from '@ngrx/store';
import { StateErrors } from '../shared/base.state';

export const loadProjects = createAction(
  '[Project] Load Projects',
  props<{ projects: ProjectBackendDict[] }>()
);

export const loadProjectsSuccess = createAction(
  '[Project] Load Projects Success',
  props<{
    projects: ProjectBackendDict[];
    errors: StateErrors<ProjectBackendDict>;
  }>()
);

export const loadProjectsFailure = createAction(
  '[Project] Load Projects Failure',
  props<{ error: any }>()
);

export const updateProject = createAction(
  '[Project] Update Project',
  props<{ projectChanges: Partial<ProjectBackendDict> }>()
);

export const updateProjectFailure = createAction(
  '[Project API] Update Project Failure',
  props<{ id: number }>()
);
