import { createSelector } from '@ngrx/store';
import { SimulationState } from '../reducers';
import { selectSimulationFeature } from '../simulation.selectors';

export const selectUtilityRate = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.utilityRate.entities[id]
  );
const selectUtilityRateLoadingStatus = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.utilityRate.loading[id]
  );

export const selectUtilityRateViewSet = (id: number) =>
  createSelector(
    selectUtilityRate(id),
    selectUtilityRateLoadingStatus(id),
    (utilityRate, loadingStatus) => ({
      data: utilityRate,
      loading: loadingStatus,
    })
  );

export const selectUtilityRates = () =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.utilityRate.entities
  );

export const selectUtilityRateErrorViewSet = (id: number) =>
  createSelector(selectSimulationFeature, (state: SimulationState) => ({
    errorCount: state.utilityRate.errors[id].errorCount,
    warningCount: state.utilityRate.errors[id].warningCount,
    name: state.utilityRate.entities[id].name,
  }));
