<div class="month-mat-picker-container">
    <mat-form-field appearance="none" class="month-mat-picker-field">
        <input matInput [matDatepicker]="dp" [placeholder]="placeholder" [formControl]="control" [min]="minDate">
        <mat-datepicker #dp
                        startView="multi-year"
                        (yearSelected)="chosenYearHandler($event)"
                        (monthSelected)="chosenMonthHandler($event, dp)"
                        >
        </mat-datepicker>
        <button mat-button mat-icon-button matSuffix [disabled]="disabled" *ngIf="value">
            <mat-icon>close</mat-icon>
        </button>
<!--        <button mat-button mat-icon-button matSuffix [disabled]="disabled">-->
<!--            <mat-icon>calendar_today</mat-icon>-->
<!--        </button>-->
    </mat-form-field>
</div>
