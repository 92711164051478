import { Component, OnDestroy, OnInit } from '@angular/core';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { MatDialog } from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import * as moment from 'moment';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import {
  CustomerHIRLProjectCycleTimeMetricsRequestParams,
  HIRLProjectService,
} from '@/data/customer-hirl/services/hirl-projects.service';
import { HIRLProjectRegistrationTypeLabelMapping } from '@/data/customer-hirl/models';

interface IChartResult {
  name?: number;
  value: number[];
}

@Component({
  selector: 'app-customer-hirl-project-cycle-time-metrics-chart',
  templateUrl:
    './customer-hirl-project-cycle-time-metrics-chart.component.html',
  styleUrls: [
    './customer-hirl-project-cycle-time-metrics-chart.component.scss',
  ],
})
export class CustomerHirlProjectCycleTimeMetricsChartComponent
  implements OnInit, OnDestroy
{
  public isLoading = true;
  public metricsSubscription: Subscription;
  public filterFormGroup: UntypedFormGroup;
  public storedParams = new CustomerHIRLProjectCycleTimeMetricsRequestParams();
  public defaultParams = new CustomerHIRLProjectCycleTimeMetricsRequestParams();
  public results: IChartResult[] = [];

  public yearChoices: string[] = [];
  public projectRegistrationTypeLabelMapping =
    HIRLProjectRegistrationTypeLabelMapping;

  private componentDestroyed$ = new Subject();

  constructor(
    public store: Store<AppState>,
    public dialog: MatDialog,
    public fb: UntypedFormBuilder,
    public hirlProjectService: HIRLProjectService,
    public uiHelper: UIHelperService
  ) {
    for (let i = 2008; i <= moment.utc().year(); i++) {
      this.yearChoices.push(i.toString());
    }
  }

  ngOnInit(): void {
    forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
    }).subscribe(({ currentUser }) => {
      this.setupFilterFormGroup();

      // select current year by default
      this.filterFormGroup
        .get('years')
        .patchValue(moment().utc().year().toString());
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  setupFilterFormGroup() {
    this.filterFormGroup = this.fb.group({
      years: [[]],
      registration__project_type: [''],
    });

    this.filterFormGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(value => {
        const params = Object.assign(this.defaultParams, value);

        this.storedParams = Object.assign(this.storedParams, params);
        this.getResults();
      });
  }

  getResults() {
    this.isLoading = true;
    if (this.metricsSubscription) {
      this.metricsSubscription.unsubscribe();
    }

    this.metricsSubscription = this.hirlProjectService
      .project_cycle_time_metrics(this.storedParams)
      .pipe(first(), takeUntil(this.componentDestroyed$))
      .subscribe(responseData => {
        this.results = _(responseData)
          .groupBy(item => {
            return item.cycle_days;
          })
          .map((items, cycle_days) => {
            return {
              // Group by weeks
              name: Math.ceil(parseInt(cycle_days, null) / 7),
              value: _.map(items, 'projects_count'),
            };
          })
          .value();
        this.isLoading = false;
      });
  }
}
