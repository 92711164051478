import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import {
  AreaUnitLabels,
  LengthUnitLabels,
  LocationLabels,
} from '@/data/simulation/enumerations';
import { DIALOG_WIDTH } from '@/modules/simulation/constants';
import { selectFrameFloorViewSet } from '@/modules/simulation/state/frame-floor/selectors';
import {
  removeFrameFloor,
  updateFrameFloor,
} from '@/modules/simulation/state/frame-floor/actions';
import {
  FrameFloorBackendDict,
  ExteriorLocation,
  InteriorLocation,
  createFrameFloorForm,
  FIELD_LABELS,
} from '@/data/simulation/models/enclosure/FrameFloor';
import { removeFrameFloorType } from '@/modules/simulation/state/frame-floor-type/actions';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { CREATE_MODE, EDIT_MODE } from '@/core/constants';
import { FormGroup } from '@angular/forms';
import {
  FrameFloorTypeChangeDialogComponent,
  FrameFloorTypeChangeDialogData,
} from '../frame-floor-type-change-dialog/frame-floor-type-change-dialog.component';
import { selectFrameFloorTypeNameAndError } from '@/modules/simulation/state/frame-floor-type/selectors';
import { cloneFrameFloor } from '@/modules/simulation/state/simulation/actions';

@Component({
  selector: 'app-frame-floor',
  templateUrl: './frame-floor.component.html',
  styleUrls: ['./frame-floor.component.scss'],
})
export class FrameFloorComponent implements OnDestroy, OnInit {
  @Input() entityId: number;
  fieldLabels = FIELD_LABELS;

  lengthLabels = LengthUnitLabels;
  areaLabels = AreaUnitLabels;

  entity: FrameFloorBackendDict = null;
  loading = false;

  private componentDestroyed$ = new Subject();
  typeViewSubscription$ = null;
  public exteriorLocationLabelMapping = {};
  public interiorLocationLabelMapping = {};

  public frameFloorTypeName: string;
  public frameFloorTypeErrorCount = 0;

  public frameFloorFormGroup: FormGroup;
  constructor(public dialog: MatDialog, public store: Store) {
    Object.values(InteriorLocation).forEach(value => {
      this.interiorLocationLabelMapping[value] = LocationLabels[value];
    });
    Object.values(ExteriorLocation).forEach(value => {
      this.exteriorLocationLabelMapping[value] = LocationLabels[value];
    });
  }

  updateTypeDetails(typeId: number) {
    if (this.typeViewSubscription$) {
      this.typeViewSubscription$.unsubscribe();
    }
    if (!typeId) {
      this.frameFloorTypeName = null;
      this.frameFloorTypeErrorCount = 1;
      return;
    }

    this.store
      .select(selectFrameFloorTypeNameAndError(typeId))
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(({ name, errorCount }) => {
        this.frameFloorTypeName = name;
        this.frameFloorTypeErrorCount = errorCount;
      });
  }

  ngOnInit(): void {
    this.store
      .select(selectFrameFloorViewSet(this.entityId))
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(val => val !== null)
      )
      .subscribe(({ data, loading }) => {
        this.entity = data;
        this.loading = loading;
        if (!loading) {
          this.frameFloorFormGroup = createFrameFloorForm(data);
          this.updateTypeDetails(data.type);
        }
      });
  }

  openEditFloorTypeChangeDialog(data: FrameFloorTypeChangeDialogData) {
    return this.dialog.open(FrameFloorTypeChangeDialogComponent, {
      width: DIALOG_WIDTH,
      disableClose: true,
      data,
    });
  }

  removeFrameFloor() {
    let floorName = this.entity.name;
    if (floorName === null || floorName === '') {
      floorName = 'Untitled';
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '50%',
      data: {
        title: `Do you want to delete "${floorName}" floor ?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (result) {
          this.store.dispatch(removeFrameFloor({ frameFloor: this.entity }));
        }
      });
  }

  clone() {
    this.store.dispatch(cloneFrameFloor({ id: this.entityId }));
  }

  editFloorType() {
    const updatedFloorType = this.openEditFloorTypeChangeDialog({
      commit: true,
      mode: EDIT_MODE,
      entityId: this.entity.type,
    });

    updatedFloorType.afterClosed().subscribe(() => {
      this.updateTypeDetails(this.entity.type);
    });
  }

  addFloorType() {
    const newFloorDialog = this.openEditFloorTypeChangeDialog({
      commit: true,
      mode: CREATE_MODE,
      parentId: this.entityId,
    });

    newFloorDialog.afterClosed().subscribe(floorType => {
      if (!floorType) {
        return;
      }

      this.frameFloorFormGroup.patchValue({ type: floorType.id });
      this.updateTypeDetails(floorType.id);
    });
  }

  removeFloorType() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '50%',
      data: {
        title: `Do you want to delete "${this.frameFloorTypeName}" floor type ?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (result) {
          this.store.dispatch(
            removeFrameFloorType({
              floorId: this.entityId,
              floorTypeId: this.entity.type,
            })
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  onSave(frameFloorChanges: Partial<FrameFloorBackendDict>): void {
    this.store.dispatch(
      updateFrameFloor({
        frameFloorChanges: frameFloorChanges,
      })
    );
  }
}
