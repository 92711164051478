import {
  AreaUnitLabels,
  LengthUnitLabels,
  StudTypeLabels,
  WallCavityInsulationTypeLabels,
} from '@/data/simulation/enumerations';
import { INSULATION_GRADE_LABELS } from '@/data/simulation/models/enclosure/Material';
import {
  RoofTypeBackendDict,
  FIELD_LABELS,
  RoofTypeFormGroup,
} from '@/data/simulation/models/enclosure/RoofType';
import { updateRoofType } from '@/modules/simulation/state/roof-type/actions';
import { selectRoofTypeViewSet } from '@/modules/simulation/state/roof-type/selectors';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-roof-type',
  templateUrl: './roof-type.component.html',
  styleUrls: ['./roof-type.component.scss'],
})
export class RoofTypeComponent implements OnInit {
  @Input() entityId: number;
  fieldLabels = FIELD_LABELS;

  lengthLabels = LengthUnitLabels;
  areaLabels = AreaUnitLabels;
  insulationGrade = INSULATION_GRADE_LABELS;

  private componentDestroyed$ = new Subject();

  loading = false;
  entity: RoofTypeBackendDict = null;
  roofTypeFormGroup: FormGroup;
  studTypeLabels: { [key: string]: string } = StudTypeLabels;
  wallCavityInsulationTypeLabels: { [key: string]: string } =
    WallCavityInsulationTypeLabels;

  constructor(public store: Store) {}

  ngOnInit() {
    this.store
      .select(selectRoofTypeViewSet(this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(({ data, loading }) => {
        this.loading = loading;

        if (loading) return;

        if (this.roofTypeFormGroup) {
          this.roofTypeFormGroup.patchValue(data);
        } else {
          this.roofTypeFormGroup = new RoofTypeFormGroup(data);

          const controls = this.roofTypeFormGroup.controls;
          controls['user_defined_assembly_u_value'].valueChanges.subscribe(
            value => {
              if (value) {
                controls.assembly_u_value.enable({ emitEvent: false });
              } else {
                controls.assembly_u_value.disable({ emitEvent: false });
              }
            }
          );
        }
        this.entity = data;
      });
  }

  handleSystemUValue($event: any) {
    if (!this.roofTypeFormGroup.controls.user_defined_assembly_u_value.value) {
      this.roofTypeFormGroup.controls.assembly_u_value.patchValue($event, {
        emitEvent: false,
      });
    }
  }

  saveRoofType(changedValues: Partial<RoofTypeBackendDict>) {
    const newValues = this.roofTypeFormGroup.value;
    const changes = {};
    Object.entries(this.entity).forEach(([key, val]) => {
      if (!this.entity.hasOwnProperty(key)) return;
      if (val !== newValues[key]) changes[key] = newValues[key];
    });
    this.store.dispatch(
      updateRoofType({
        id: this.entityId,
        roofTypeChanges: changes,
      })
    );
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
