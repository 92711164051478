import { createSelector } from '@ngrx/store';
import { SimulationState } from '../reducers';
import { selectSimulationFeature } from '../simulation.selectors';
import { RimJoistBackendDict } from '@/data/simulation/models/enclosure/RimJoist';

export const selectRimJoist = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.rimJoist.entities[id]
  );
const selectRimJoistLoadingStatus = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.rimJoist.loading[id]
  );

export const selectRimJoistTypeId = (id: number) =>
  createSelector(selectRimJoist(id), rimJoist => rimJoist.type);

const selectRimJoistErrors = (id: number) =>
  createSelector(selectSimulationFeature, (state: SimulationState) => ({
    errorCount: state.rimJoist.errors[id].errorCount,
    warningCount: state.rimJoist.errors[id].warningCount,
  }));

export const selectRimJoistViewSet = (id: number) =>
  createSelector(
    selectRimJoist(id),
    selectRimJoistLoadingStatus(id),
    (rimJoist, loadingStatus) => ({
      data: rimJoist,
      loading: loadingStatus,
    })
  );

export const selectRimJoistName = (id: number) =>
  createSelector(
    selectRimJoist(id),
    (rimJoist: RimJoistBackendDict) => rimJoist.name
  );

export const selectRimJoistNestedErrorCount = (id: number) =>
  createSelector(
    selectSimulationFeature,
    selectRimJoistErrors(id),
    selectRimJoistTypeId(id),
    (state, errorCount, rimJoistId) => {
      const rimJoistTypeErrorCount = {
        errorCount: 0,
        warningCount: 0,
      };

      if (rimJoistId !== null) {
        rimJoistTypeErrorCount.errorCount =
          state.rimJoistType.errors[rimJoistId].errorCount;
        rimJoistTypeErrorCount.warningCount =
          state.rimJoistType.errors[rimJoistId].warningCount;
      }

      return {
        rimJoist: errorCount,
        rimJoistType: rimJoistTypeErrorCount,
      };
    }
  );

export const selectRimJoistErrorViewSet = (id: number) =>
  createSelector(
    selectRimJoistNestedErrorCount(id),
    selectRimJoistTypeId(id),
    selectRimJoistName(id),
    (nestedModelsErrorCount, rimJoistTypeId, name) => ({
      nestedModelsErrorCount,
      rimJoistTypeId,
      name,
    })
  );
