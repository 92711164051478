<div class="row">
  <div class="col-3">
    <div class="row">
      <div class="col-12">
        <form [formGroup]="filterFromGroup" class="card">
          <h5 class="card-header">Filters</h5>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <mat-form-field class="w-100">
                  <mat-label>Search</mat-label>
                  <app-search-input formControlName="search" placeholder="Type to search something"></app-search-input>
                </mat-form-field>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="col-9">
    <div class="row mb-2">
      <div class="col-12 text-right">
        <app-permission-mat-button [permissionResponse]="createPermissionResponse" (action)="create($event);">
          Add New Product Group
        </app-permission-mat-button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="row" *ngIf="isLoading">
          <div class="col-12">
            <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
          </div>
        </div>
        <table mat-table
               [dataSource]="rows"
               class="table"
               matSort
               [matSortActive]="storedParams.toTableSort().active"
               [matSortDirection]="storedParams.toTableSort().direction"
               (matSortChange)="onSortChange($event)">

          <ng-container matColumnDef="thumbnail">
            <th mat-header-cell *matHeaderCellDef>Image(s)</th>
            <td mat-cell *matCellDef="let row">
              <img src="{{ row.product_info?.thumbnail_info?.customer_document_info?.document || 'https://placehold.co/64x64?text=No+Thumbnail' }}" width="64" height="64">
            </td>
          </ng-container>

          <ng-container matColumnDef="product__title">
            <th mat-header-cell *matHeaderCellDef>Product Group Name</th>
            <td mat-cell *matCellDef="let row">
              <app-product-display [product]="row.product_info" displayType="onlyNameAsLink"></app-product-display>
            </td>
          </ng-container>

          <ng-container matColumnDef="product__practice_item_product_count">
            <th mat-header-cell *matHeaderCellDef>NGBS Practice Count</th>
            <td mat-cell *matCellDef="let row">
              {{ row.practice_item_product_count }}
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Web Status</th>
            <td mat-cell *matCellDef="let row">
              <span class="badge ml-2 {{ ProductStatusLabelMapping[row.product_info?.status]?.badgeClass }}">{{ ProductStatusLabelMapping[row.product_info?.status]?.label }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let row">
              <app-permission-mat-button [permissionResponse]="permissions[row.id]['canDelete']" displayType="asLink" (action)="delete($event, row);" class="mr-2">
                <fa-icon [icon]="['fas', 'trash']" matTooltip="Delete"></fa-icon>
              </app-permission-mat-button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [length]="rowsCount"
                       [pageIndex]="$any(storedParams.page) - 1"
                       [pageSize]="storedParams.page_size"
                       (page)="onPaginateChange($event)"
                       aria-label="Select page">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
