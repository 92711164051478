import { User } from '@/data/core/models/user';
import { Action, createReducer, on } from '@ngrx/store';
import {
  resetUser,
  loadUser,
  loadUserCompanyAccessesAction,
  loadUserCompanyAccessCountAction,
  loadUserRaterRolesAction,
  loadUserRaterRolesCountAction,
  loadUserAccreditationsAction,
  loadUserAccreditationsCountAction,
  loadUserCustomerHIRLInspectionGradesAction,
  loadUserCustomerHIRLGradesCountAction,
  loadUserEEPProgramHomeStatusCertificationMetricsAction,
  loadUserEEPProgramHomeStatusCertificationMetricsCountAction,
  loadUserTasksAction,
  loadUserTasksCountAction,
  loadUserTrainingsAction,
  loadUserTrainingsCountAction,
} from '@/state/actions/user/user.actions';
import { CompanyAccess } from '@/data/company/models';
import { loadEntityArray, loadEntityCount } from '@/state/reducers/utils';
import { UserCertificationMetric, UserRaterRole } from '@/data/core/models';
import {
  Accreditation,
  InspectionGrade,
  Training,
} from '@/data/user-management/models';
import { Task } from '@/data/scheduling/models';

export const userStateKey = 'user';

export interface UserState {
  detail: User;
  userCompanyAccesses: CompanyAccess[];
  userCompanyAccessesCount: number;
  raterRoles: UserRaterRole[];
  raterRolesCount: number;
  accreditations: Accreditation[];
  accreditationsCount: number;
  customerHIRLInspectionGrades: InspectionGrade[];
  customerHIRLInspectionGradesCount: number;
  eepProgramHomeStatusCertificationMetrics: UserCertificationMetric[];
  eepProgramHomeStatusCertificationMetricsCount: number;
  tasks: Task[];
  tasksCount: number;
  trainings: Training[];
  trainingsCount: number;
}

export const userInitialState: UserState = {
  detail: null,
  userCompanyAccesses: [],
  userCompanyAccessesCount: -1,
  raterRoles: [],
  raterRolesCount: -1,
  accreditations: [],
  accreditationsCount: -1,
  customerHIRLInspectionGrades: [],
  customerHIRLInspectionGradesCount: -1,
  eepProgramHomeStatusCertificationMetrics: [],
  eepProgramHomeStatusCertificationMetricsCount: -1,
  tasks: [],
  tasksCount: -1,
  trainings: [],
  trainingsCount: -1,
};

const userReducer = createReducer(
  userInitialState,
  on(resetUser, (state, action) => ({ ...userInitialState })),
  on(loadUser, (state, { payload }) => ({ ...state, detail: payload })),
  // Company Access
  on(loadUserCompanyAccessesAction, (state, { payload }) =>
    loadEntityArray(state, 'userCompanyAccesses', payload)
  ),
  on(loadUserCompanyAccessCountAction, (state, { payload }) =>
    loadEntityCount(state, 'userCompanyAccessesCount', payload)
  ),
  // Rater Role
  on(loadUserRaterRolesAction, (state, { payload }) =>
    loadEntityArray(state, 'raterRoles', payload)
  ),
  on(loadUserRaterRolesCountAction, (state, { payload }) =>
    loadEntityCount(state, 'raterRolesCount', payload)
  ),
  // Accreditations
  on(loadUserAccreditationsAction, (state, { payload }) =>
    loadEntityArray(state, 'accreditations', payload)
  ),
  on(loadUserAccreditationsCountAction, (state, { payload }) =>
    loadEntityCount(state, 'accreditationsCount', payload)
  ),
  // Inspection Grades
  on(loadUserCustomerHIRLInspectionGradesAction, (state, { payload }) =>
    loadEntityArray(state, 'customerHIRLInspectionGrades', payload)
  ),
  on(loadUserCustomerHIRLGradesCountAction, (state, { payload }) =>
    loadEntityCount(state, 'customerHIRLInspectionGradesCount', payload)
  ),
  // Trainings
  on(loadUserTrainingsAction, (state, { payload }) =>
    loadEntityArray(state, 'trainings', payload)
  ),
  on(loadUserTrainingsCountAction, (state, { payload }) =>
    loadEntityCount(state, 'trainingsCount', payload)
  ),
  // Tasks
  on(loadUserTasksAction, (state, { payload }) =>
    loadEntityArray(state, 'tasks', payload)
  ),
  on(loadUserTasksCountAction, (state, { payload }) =>
    loadEntityCount(state, 'tasksCount', payload)
  ),
  // EEP Program Home Statuses
  on(
    loadUserEEPProgramHomeStatusCertificationMetricsAction,
    (state, { payload }) =>
      loadEntityArray(
        state,
        'eepProgramHomeStatusCertificationMetrics',
        payload
      )
  ),
  on(
    loadUserEEPProgramHomeStatusCertificationMetricsCountAction,
    (state, { payload }) =>
      loadEntityCount(
        state,
        'eepProgramHomeStatusCertificationMetricsCount',
        payload
      )
  )
);

export function reducer(state: UserState | undefined, action: Action) {
  return userReducer(state, action);
}
