<div *ngIf="initialized; else loading;">
    <mat-chip-list aria-label="Fish selection">
        <mat-chip *ngFor="let altName of altNames">
            {{ altName.name }}
            <mat-icon matChipRemove (click)="onDelete($event, altName)">cancel</mat-icon>
        </mat-chip>
        <a href="#"
                matTooltip="Add New Alternative Name"
                (click)="onCreate($event);"
                *ngIf="(currentUser?.company == company.id && currentUser?.is_company_admin) || currentUser?.is_superuser"
        >
            <fa-icon [icon]="['fas', 'plus']"></fa-icon>
        </a>
    </mat-chip-list>
</div>

<ng-template #loading>
    <div class="row">
        <div class="col-12">
            <div class="d-flex justify-content-center">
                <mat-spinner [diameter]="30"></mat-spinner>
            </div>
        </div>
    </div>
</ng-template>
