import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { SimulationRightsService } from '../services/simulation-rights.service';

@Directive({
  selector: '[appRequiresEditPermission]',
})
export class RequiresEditPermissionDirective {
  private permissionSubscription: Subscription;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionService: SimulationRightsService
  ) {}

  ngOnInit(): void {
    this.permissionSubscription = this.permissionService.canEdit.subscribe(
      canEdit => {
        this.viewContainer.clear();
        if (canEdit) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.permissionSubscription.unsubscribe();
  }
}
