<div class="row" *ngIf="initialized">
    <div class="col-12" style="margin-left: 20px">
        <mat-expansion-panel [expanded]="panelExpanded" (opened)="panelExpanded = true" (closed)="panelExpanded = false" hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Support
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
                <div class="col-12">
                    {{ currentUser.first_name }} {{ currentUser.last_name }}<br>
                    <small>{{ currentUser.email }}</small>
                </div>
            </div>
            <div class="row" *ngIf="!isCreated">
                <div class="col-12">
                    <form [formGroup]="zendeskFormGroup">
                        <div class="row">
                            <div class="col-12">
                                <mat-form-field appearance="outline" class="w-100 mb-2">
                                    <mat-label>Subject</mat-label>
                                    <input matInput placeholder="Subject" formControlName="subject" [readonly]="isLoading">
                                    <mat-error *ngIf="zendeskFormGroup.get('subject').hasError('required')">
                                        Subject is required
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <mat-form-field appearance="outline" class="w-100 mb-2">
                                    <mat-label>Description</mat-label>
                                    <textarea matInput placeholder="Description" formControlName="description" [readonly]="isLoading"></textarea>
                                    <mat-error *ngIf="zendeskFormGroup.get('description').hasError('required')">
                                        Description is required
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <mat-form-field appearance="outline" class="w-100 mb-2">
                                    <mat-label>Type</mat-label>
                                    <mat-select aria-label="Select Ticket Type" formControlName="type">
                                        <mat-option *ngFor="let item of ticketTypeLabelMapping | keyvalue " [value]="item.key">
                                            {{ item.value }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="zendeskFormGroup.get('type').hasError('required')">
                                        Type is required
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field appearance="outline" class="w-100 mb-2">
                                    <mat-label>Priority</mat-label>
                                    <mat-select aria-label="Select Ticket Priority" formControlName="priority">
                                        <mat-option *ngFor="let item of ticketPriorityLabelMapping | keyvalue " [value]="item.key">
                                            {{ item.value }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="zendeskFormGroup.get('priority').hasError('required')">
                                        Priority is required
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row" *ngIf="!isLoading">
                            <div class="col-12 text-right">
                                <button class="btn btn-primary btn-lg mr-2"
                                        (click)="handleSend($event)">Send</button>
                                <button (click)="handleCancel($event)" class="btn btn-lg mr-2">Cancel</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row" *ngIf="isLoading">
                <div class="col-12">
                    <div class="d-flex justify-content-center">
                        <mat-spinner [diameter]="50"></mat-spinner>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="isCreated">
                <div class="col-12">
                    <h2>Success</h2>
                    <p>
                        To follow up on the issue you just submitted, you can go to
                        <a href="{{ zendeskCreateTicketResponse.url }}" target="_blank">
                            {{ zendeskCreateTicketResponse.url }}
                        </a>
                    </p>
                    <button class="btn btn-primary btn-lg mr-2"
                            (click)="resetForm()">Send again</button>
                </div>
            </div>
        </mat-expansion-panel>
    </div>
</div>
