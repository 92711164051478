import { CustomerDocumentInfo } from '@/data/filehandling/models';

export enum ProductImageType {
  thumbnail = 'thumbnail',
  main = 'main',
}

export const ProductImageTypeLabelMapping: Record<ProductImageType, any> = {
  [ProductImageType.thumbnail]: 'Gallery Image',
  [ProductImageType.main]: 'Product Detail Image',
};

export class ProductImage {
  id?: number;
  product?: number;
  customer_document?: number;
  customer_document_info?: CustomerDocumentInfo;
  image_type?: ProductImageType;
  order?: number;
}

export class ProductImageInfo {
  id?: number;
  product?: number;
  customer_document?: number;
  customer_document_info?: CustomerDocumentInfo;
  image_type?: ProductImageType;
  order?: number;
}
