import { Injectable } from '@angular/core';
import { HIRLProjectRegistrationPermissionService } from '@/modules/customer-hirl/services/hirl-project-registration-permission-service';
import { forkJoin, Observable, of } from 'rxjs';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { first, map, mergeMap, switchMap } from 'rxjs/operators';
import { HIRLProjectService } from '@/data/customer-hirl/services/hirl-projects.service';
import {
  QaStatusRequestParams,
  QaStatusService,
} from '@/data/qa/services/qa-status.service';
import {
  HIRLProject,
  HIRLProjectAppealsProjectType,
  HIRLProjectRegistration,
  HIRLProjectRegistrationType,
} from '@/data/customer-hirl/models';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';
import { Store } from '@ngrx/store';
import { getInfoUser } from '@/state/selectors/info.selector';
import { AppState } from '@/state/reducers';
import { EEPProgramRequestParams } from '@/data/eep-program/services/eep-program.service';
import { EEPProgramHomeStatusState } from '@/data/home/models/eep_program_home_status';
import { HIRLProjectRegistrationService } from '@/data/customer-hirl/services/hirl-project-registration.service';
import { EEPProgramHomeStatusService } from '@/data/home/services/eep_program_home_status.service';

@Injectable({
  providedIn: 'root',
})
export class HIRLProjectPermissionService {
  constructor(
    public store: Store<AppState>,
    public hirlProjectService: HIRLProjectService,
    public qaStatusService: QaStatusService,
    public hirlProjectRegistrationService: HIRLProjectRegistrationService,
    public hirlProjectRegistrationPermissionService: HIRLProjectRegistrationPermissionService,
    public eepProgramHomeStatusService: EEPProgramHomeStatusService
  ) {}

  canEdit(
    hirlProject: string | HIRLProject,
    hirlProjectRegistration: string | HIRLProjectRegistration,
    qaCount?: number,
    certifiedProjectsCount?: number
  ): Observable<ObjectPermissionResponse> {
    let hirlProject$: Observable<HIRLProject>;
    if (typeof hirlProject === 'string') {
      hirlProject$ = this.hirlProjectService.retrieve(hirlProject, {
        ignoreStore: true,
      });
    } else {
      hirlProject$ = of(hirlProject);
    }

    let hirlProjectRegistration$: Observable<HIRLProjectRegistration>;
    let hirlProjectRegistrationId: string;

    if (typeof hirlProjectRegistration === 'string') {
      hirlProjectRegistration$ = this.hirlProjectRegistrationService.retrieve(
        hirlProjectRegistration,
        { ignoreStore: true }
      );
      hirlProjectRegistrationId = hirlProjectRegistration;
    } else {
      hirlProjectRegistration$ = of(hirlProjectRegistration);
      hirlProjectRegistrationId = hirlProjectRegistration?.id;
    }

    let qaCount$: Observable<number>;
    if (typeof qaCount === 'number') {
      qaCount$ = of(qaCount);
    } else {
      qaCount$ = this.qaStatusService
        .list(
          Object.assign(new QaStatusRequestParams(), {
            home_status__customer_hirl_project__registration:
              hirlProjectRegistrationId,
          })
        )
        .pipe(
          first(),
          map(response => response.count)
        );
    }

    let certifiedProjectsCount$: Observable<number>;
    if (typeof certifiedProjectsCount === 'number') {
      certifiedProjectsCount$ = of(certifiedProjectsCount);
    } else {
      certifiedProjectsCount$ = this.eepProgramHomeStatusService
        .list(
          Object.assign(new EEPProgramRequestParams(), {
            customer_hirl_project__registration: hirlProjectRegistrationId,
            state: EEPProgramHomeStatusState.complete,
          })
        )
        .pipe(
          first(),
          map(response => response.count)
        );
    }

    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: hirlProject$,
      certifiedProjectsCount: certifiedProjectsCount$,
      qaCount: qaCount$,
      hirlProjectRegistration: hirlProjectRegistration$,
    })
      .pipe(
        mergeMap(
          ({
            currentUser,
            entity,
            hirlProjectRegistration,
            certifiedProjectsCount,
            qaCount,
          }) => {
            return this.hirlProjectRegistrationPermissionService
              .canEdit(hirlProjectRegistration, qaCount, certifiedProjectsCount)
              .pipe(
                map(canEditRegistration => {
                  return { canEditRegistration, qaCount, entity, currentUser };
                })
              );
          }
        )
      )
      .pipe(
        switchMap(({ canEditRegistration, qaCount, entity, currentUser }) => {
          if (
            entity.home_status_info?.state ===
            EEPProgramHomeStatusState.complete
          ) {
            return of(
              new ObjectPermissionResponse(false, 'Project has been certified.')
            );
          }
          if (
            currentUser.is_superuser ||
            (currentUser.is_company_admin &&
              currentUser.company_info?.slug ===
                CustomerHIRLSettings.companySlug)
          ) {
            return of(new ObjectPermissionResponse(true));
          }
          if (
            entity.appeals_project ===
              HIRLProjectAppealsProjectType.yesFullAppeal ||
            entity.appeals_project ==
              HIRLProjectAppealsProjectType.yesAdministrativeAppeal
          ) {
            if (entity.home_status) {
              return of(
                new ObjectPermissionResponse(
                  false,
                  'Appeals projects cannot be edit after approval'
                )
              );
            }
          }
          if (
            entity.registration_info.project_type ===
            HIRLProjectRegistrationType.multiFamily
          ) {
            if (qaCount) {
              return of(
                new ObjectPermissionResponse(
                  false,
                  'Project in this Registration received Verification Report.'
                )
              );
            }
          }
          return of(canEditRegistration);
        })
      );
  }

  canDelete(
    hirlProject: HIRLProject | string,
    hirlProjectRegistration: HIRLProjectRegistration | string
  ) {
    let hirlProject$: Observable<HIRLProject>;
    if (typeof hirlProject === 'string') {
      hirlProject$ = this.hirlProjectService.retrieve(hirlProject, {
        ignoreStore: true,
      });
    } else {
      hirlProject$ = of(hirlProject);
    }

    let hirlProjectRegistration$: Observable<HIRLProjectRegistration>;

    if (typeof hirlProjectRegistration === 'string') {
      hirlProjectRegistration$ = this.hirlProjectRegistrationService.retrieve(
        hirlProjectRegistration,
        { ignoreStore: true }
      );
    } else {
      hirlProjectRegistration$ = of(hirlProjectRegistration);
    }

    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: hirlProject$,
      registration: hirlProjectRegistration$,
    }).pipe(
      switchMap(({ currentUser, entity, registration }) => {
        if (
          currentUser.is_company_admin &&
          currentUser.company_info?.slug === CustomerHIRLSettings.companySlug &&
          entity?.home_status_info?.state !== EEPProgramHomeStatusState.complete
        ) {
          return of(new ObjectPermissionResponse(true));
        }
        return this.hirlProjectRegistrationPermissionService.canDelete(
          registration
        );
      })
    );
  }
}
