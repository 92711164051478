<div class="container" #tableTopScroll>
    <div class="row">
        <div class="col-12">
            <ngx-datatable
                    class="material hirl-project-list"
                    [rows]="projects"
                    columnMode="force"
                    [headerHeight]="0"
                    [rowHeight]="'auto'"
                    [loadingIndicator]="isLoading"
                    [footerHeight]="50"
                    [limit]="storedParams.page_size"
                    (sort)="onSort($event)"
                    [sorts]="[{prop: storedParams.ordering.replace('-', ''), dir: (storedParams.ordering.startsWith('-')) ? 'desc' : 'asc'}]"

            >

                <ngx-datatable-column>
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                        <app-hirl-project-card [project]="row" [editPermissionResponse]="permissions[row.id]?.canEdit" [deletePermissionResponse]="permissions[row.id]?.canDelete"></app-hirl-project-card>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-footer>
                    <ng-template ngx-datatable-footer-template>
                        <div class="col-12 text-center">
                            <datatable-pager
                                    [pagerLeftArrowIcon]="'datatable-icon-left'"
                                    [pagerRightArrowIcon]="'datatable-icon-right'"
                                    [pagerPreviousIcon]="'datatable-icon-prev'"
                                    [pagerNextIcon]="'datatable-icon-skip'"
                                    [page]="storedParams.page"
                                    [size]="storedParams.page_size"
                                    [count]="projectsCount"
                                    (change)="onChangePage($event)"
                            >
                            </datatable-pager>
                        </div>
                    </ng-template>
                </ngx-datatable-footer>
            </ngx-datatable>
        </div>
    </div>
</div>
