import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { County } from '@/data/geographic/models';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import { ServerResponse } from '@/core/schemes/server-response';
import {
  AltName,
  ChangeCompany,
  Company,
  CompanyAccess,
  CompanyAggregatedCountyByState,
  CompanyAutocompleteList,
  CompanyFlatList,
  CompanyType,
  CopyResourcesToOtherCompany,
  CustomerHIRLGCPFullYearExpirationNotification,
  SponsoringPreferences,
} from '@/data/company/models';
import {
  CompanyUpdateCountiesByState,
  CopiedResourcesToOtherCompanyResponse,
} from '@/data/company/models/company';
import { CustomerDocument } from '@/data/filehandling/models';
import { CustomerDocumentRequestParams } from '@/data/filehandling/services/customer-document.service';
import { tap } from 'rxjs/operators';
import {
  createCompanyAltName,
  loadCompany,
  loadCompanyAffiliationPreferences,
  loadCompanyAffiliationPreferencesCount,
  loadCompanyAltNames,
  loadCompanyAltNamesCount,
  loadCompanyCOIDocuments,
  loadCompanyCOIDocumentsCount,
  loadCompanyCompanyAccessAction,
  loadCompanyCompanyAccessCountAction,
  loadCompanyContactCards,
  loadCompanyContactCardsCount,
  loadCompanyDocuments,
  loadCompanyDocumentsCount,
  loadCompanyEquipments,
  loadCompanyEquipmentsCount,
  loadCompanyRemRateUsers,
  loadCompanyRemRateUsersCount,
  loadCompanySponsoringPreferences,
  loadCompanySponsoringPreferencesCount,
} from '@/state/actions/company/company.actions';
import { AltNameRequestParams } from '@/data/company/services/alt-name.service';
import { AffiliationPreferences } from '@/data/company/models/sponsoring';
import { ListRequestParams } from '@/core/schemes/request-params';
import { Relationship } from '@/data/relationship/models';
import { environment } from '../../../../environments/environment';
import { COIDocumentParams } from '@/data/customer-hirl/services/coi-documents.service';
import { COIDocument } from '@/data/customer-hirl/models';
import { CompanyAccessRequestParams } from '@/data/company/services/company-access.service';
import { ContactCardRequestParams } from '@/data/core/services/contact_card';
import { ContactCard } from '@/data/core/models';
import { EquipmentRequestParams } from '@/data/equipment/services/equipment.service';
import { Equipment } from '@/data/equipment/models';
import { RemRateUserRequestParams } from '@/data/remrate/services/remrateuser.service';
import { RemRateUser } from '@/data/remrate/models';

export class CompanyRequestParams extends ListRequestParams {
  is_attached?: string;
  company_type?: CompanyType | CompanyType[];
  city__county__state?: string | string[];
  city__country?: string | string[];
  active_customer_hirl_builder_agreements_count?: number;

  home_status_related?: boolean;

  gcp_certificate_expiration_date__lte?: string;
  gcp_certificate_expiration_date__gte?: string;
  customer_hirl_has_active_products?: boolean;
}

export class AffiliationPreferencesRequestParams extends ListRequestParams {}

export class SponsoringPreferencesRequestParams extends ListRequestParams {}

@Injectable({
  providedIn: 'root',
})
export class CompanyService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/companies/`;

  list(params?: CompanyRequestParams) {
    return this.http.get<ServerResponse<Company>>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }

  flat_list(params?: CompanyRequestParams) {
    return this.http.get<ServerResponse<CompanyFlatList>>(
      `${this.baseUrl}flat_list/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  autocomplete_list(params?: CompanyRequestParams) {
    return this.http.get<ServerResponse<CompanyAutocompleteList>>(
      `${this.baseUrl}autocomplete_list/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  copy_resources_to_other_company(
    formData: CopyResourcesToOtherCompany | FormData
  ): Observable<CopiedResourcesToOtherCompanyResponse> {
    return this.http.post<CopiedResourcesToOtherCompanyResponse>(
      `${this.baseUrl}copy_resources_to_other_company/`,
      formData
    );
  }

  customer_hirl_clients_export() {
    return this.http.get(`${this.baseUrl}customer_hirl_clients_export/`, {
      responseType: 'blob',
    });
  }

  create(company: Company) {
    return this.http.post<Company>(`${this.baseUrl}`, company);
  }

  retrieve(
    companyId: number,
    options?: IDataServiceMethodOptions
  ): Observable<Company> {
    const ignoreStore = options?.ignoreStore;
    let chain = this.http.get<Company>(`${this.baseUrl}${companyId}`, {});
    if (!ignoreStore) {
      chain = chain.pipe(
        tap(newCompany =>
          this.store.dispatch(loadCompany({ payload: newCompany }))
        )
      );
    }
    return chain;
  }

  update(companyId: number, company: Company | FormData): Observable<Company> {
    return this.http
      .patch<Company>(`${this.baseUrl}${companyId}/`, company)
      .pipe(
        tap(newCompany =>
          this.store.dispatch(loadCompany({ payload: newCompany }))
        )
      );
  }

  delete(companyId: number) {
    return this.http.delete(`${this.baseUrl}${companyId}/`);
  }

  counties(companyId: number): Observable<County[]> {
    return this.http.get<County[]>(`${this.baseUrl}${companyId}/counties`, {});
  }

  change_company(changeCompany: ChangeCompany): Observable<Company> {
    return this.http.post<Company>(
      `${this.baseUrl}change_company/`,
      changeCompany
    );
  }

  aggregated_counties_by_state(
    companyId: number
  ): Observable<CompanyAggregatedCountyByState[]> {
    return this.http.get<CompanyAggregatedCountyByState[]>(
      `${this.baseUrl}${companyId}/aggregated_counties_by_state`,
      {}
    );
  }

  update_counties_for_state(
    companyId: number,
    companyUpdateCountiesByState: CompanyUpdateCountiesByState
  ): Observable<County[]> {
    return this.http.patch<County[]>(
      `${this.baseUrl}${companyId}/update_counties_for_state/`,
      companyUpdateCountiesByState
    );
  }

  documents(companyId: number, params?: CustomerDocumentRequestParams) {
    return this.http
      .get<ServerResponse<CustomerDocument>>(
        `${this.baseUrl}${companyId}/documents/`,
        {
          params: this.rollParams(params),
        }
      )
      .pipe(
        tap(serverResponse =>
          this.store.dispatch(
            loadCompanyDocuments({ payload: serverResponse.results })
          )
        ),
        tap(serverResponse =>
          this.store.dispatch(
            loadCompanyDocumentsCount({ payload: serverResponse.count })
          )
        )
      );
  }

  documentsCreate(
    companyId: number,
    customerDocument: CustomerDocument | FormData
  ): Observable<CustomerDocument> {
    return this.http.post<CustomerDocument>(
      `${this.baseUrl}${companyId}/documents/`,
      customerDocument
    );
  }

  alt_names(companyId: number, params?: AltNameRequestParams) {
    return this.http
      .get<ServerResponse<AltName>>(`${this.baseUrl}${companyId}/alt_names/`, {
        params: this.rollParams(params),
      })
      .pipe(
        tap(serverResponse =>
          this.store.dispatch(
            loadCompanyAltNames({ payload: serverResponse.results })
          )
        ),
        tap(serverResponse =>
          this.store.dispatch(
            loadCompanyAltNamesCount({ payload: serverResponse.count })
          )
        )
      );
  }

  alt_namesCreate(companyId: number, altName: AltName) {
    return this.http
      .post<AltName>(`${this.baseUrl}${companyId}/alt_names/`, altName)
      .pipe(
        tap(createdAltName =>
          this.store.dispatch(createCompanyAltName({ payload: createdAltName }))
        )
      );
  }

  affiliations(
    companyId: number,
    params?: AffiliationPreferencesRequestParams
  ) {
    return this.http
      .get<ServerResponse<AffiliationPreferences>>(
        `${this.baseUrl}${companyId}/affiliations/`,
        {
          params: this.rollParams(params),
        }
      )
      .pipe(
        tap(serverResponse =>
          this.store.dispatch(
            loadCompanyAffiliationPreferences({
              payload: serverResponse.results,
            })
          )
        ),
        tap(serverResponse =>
          this.store.dispatch(
            loadCompanyAffiliationPreferencesCount({
              payload: serverResponse.count,
            })
          )
        )
      );
  }

  sponsoring(companyId: number, params?: SponsoringPreferencesRequestParams) {
    return this.http
      .get<ServerResponse<SponsoringPreferences>>(
        `${this.baseUrl}${companyId}/sponsoring/`,
        {
          params: this.rollParams(params),
        }
      )
      .pipe(
        tap(serverResponse =>
          this.store.dispatch(
            loadCompanySponsoringPreferences({
              payload: serverResponse.results,
            })
          )
        ),
        tap(serverResponse =>
          this.store.dispatch(
            loadCompanySponsoringPreferencesCount({
              payload: serverResponse.count,
            })
          )
        )
      );
  }

  relationshipsCreate(companyId: number, relationship: Relationship) {
    return this.http.post<Relationship>(
      `${this.baseUrl}${companyId}/relationships/`,
      relationship
    );
  }

  relationshipsRemove(companyId: number) {
    return this.http.delete(
      `${this.baseUrl}${companyId}/relationships/remove/`
    );
  }

  customer_hirl_verifiers_export() {
    return this.http.get(`${this.baseUrl}customer_hirl_verifiers_export/`, {
      responseType: 'blob',
    });
  }

  customer_hirl_gcp_send_full_year_expiration_notification(
    data: CustomerHIRLGCPFullYearExpirationNotification
  ): Observable<Company> {
    return this.http.post<Company>(
      `${this.baseUrl}customer_hirl_gcp_send_full_year_expiration_notification/`,
      data
    );
  }

  coi_documents(companyId: number, params?: COIDocumentParams) {
    return this.http
      .get<ServerResponse<COIDocument>>(
        `${this.baseUrl}${companyId}/coi_documents/`,
        {
          params: this.rollParams(params),
        }
      )
      .pipe(
        tap(serverResponse =>
          this.store.dispatch(
            loadCompanyCOIDocuments({ payload: serverResponse.results })
          )
        ),
        tap(serverResponse =>
          this.store.dispatch(
            loadCompanyCOIDocumentsCount({ payload: serverResponse.count })
          )
        )
      );
  }

  coi_documentsCreate(companyId: number, coiDocument: COIDocument | FormData) {
    return this.http.post<COIDocument>(
      `${this.baseUrl}${companyId}/coi_documents/`,
      coiDocument
    );
  }

  contact_cards(companyId: number, params?: ContactCardRequestParams) {
    return this.http
      .get<ServerResponse<ContactCard>>(
        `${this.baseUrl}${companyId}/contact_cards/`,
        {
          params: this.rollParams(params),
        }
      )
      .pipe(
        tap(serverResponse =>
          this.store.dispatch(
            loadCompanyContactCards({ payload: serverResponse.results })
          )
        ),
        tap(serverResponse =>
          this.store.dispatch(
            loadCompanyContactCardsCount({ payload: serverResponse.count })
          )
        )
      );
  }

  equipments(companyId: number, params?: EquipmentRequestParams) {
    return this.http
      .get<ServerResponse<Equipment>>(
        `${this.baseUrl}${companyId}/equipments/`,
        {
          params: this.rollParams(params),
        }
      )
      .pipe(
        tap(serverResponse =>
          this.store.dispatch(
            loadCompanyEquipments({ payload: serverResponse.results })
          )
        ),
        tap(serverResponse =>
          this.store.dispatch(
            loadCompanyEquipmentsCount({ payload: serverResponse.count })
          )
        )
      );
  }

  customer_eto_rater_list(params?: CompanyRequestParams) {
    return this.http.get<ServerResponse<Company>>(
      `${this.baseUrl}customer_eto_rater_list`
    );
  }

  company_accesses(
    companyId: number,
    params?: CompanyAccessRequestParams
  ): Observable<ServerResponse<CompanyAccess>> {
    return this.http
      .get<ServerResponse<CompanyAccess>>(
        `${this.baseUrl}${companyId}/company_accesses/`,
        {
          params: this.rollParams(params),
        }
      )
      .pipe(
        tap(serverResponse =>
          this.store.dispatch(
            loadCompanyCompanyAccessAction({ payload: serverResponse.results })
          )
        ),
        tap(serverResponse =>
          this.store.dispatch(
            loadCompanyCompanyAccessCountAction({
              payload: serverResponse.count,
            })
          )
        )
      );
  }

  remrate_users(
    companyId: number,
    params?: RemRateUserRequestParams
  ): Observable<ServerResponse<RemRateUser>> {
    return this.http
      .get<ServerResponse<RemRateUser>>(
        `${this.baseUrl}${companyId}/remrate_users/`,
        {
          params: this.rollParams(params),
        }
      )
      .pipe(
        tap(serverResponse =>
          this.store.dispatch(
            loadCompanyRemRateUsers({ payload: serverResponse.results })
          )
        ),
        tap(serverResponse =>
          this.store.dispatch(
            loadCompanyRemRateUsersCount({ payload: serverResponse.count })
          )
        )
      );
  }

  remrate_usersCreate(
    companyId: number,
    remRateUser: RemRateUser | FormData
  ): Observable<RemRateUser> {
    return this.http.post<RemRateUser>(
      `${this.baseUrl}${companyId}/remrate_users/`,
      remRateUser
    );
  }
}
