<div class="position-relative">
    <app-loading-overlay diameter="50" *ngIf="loading"></app-loading-overlay>
    <app-form formName="utilityRateForm" [formGroup]="utilityRateForm" (save)="saveUtilityRate($event)">
        <div>
            <app-input formControlName="name" type="text" label="Name"></app-input>
            <app-select formControlName="fuel" label="Fuel" [items]="fuelLabels"></app-select>
        </div>
    </app-form>
</div>

<div>
    <h4 class="text-center">Seasonal rates</h4>
    <app-seasonal-rates [entityIds]="entity.seasonal_rates"></app-seasonal-rates>
</div>