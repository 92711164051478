import { Component, OnDestroy, OnInit } from '@angular/core';
import { Invoice, InvoiceType } from '@/data/invoicing/models';
import { forkJoin, Observable, Subject } from 'rxjs';
import { User } from '@/data/core/models/user';
import { InvoiceService } from '@/data/invoicing/services/invoice.service';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, first, map, takeUntil } from 'rxjs/operators';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { toggleLoading } from '@/state/actions/app.actions';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { CompanyType } from '@/data/company/models';
import { NavigationService } from '@/shared/services/navigation-service.service';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { InvoicePermissionService } from '../../services/invoice-permission.service';
import {
  HirlInvoiceShareDialogComponent,
  HirlInvoiceShareDialogData,
} from '@/modules/customer-hirl/components/hirl-invoice-share-dialog/hirl-invoice-share-dialog.component';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Location } from '@angular/common';
import { resetInvoice } from '@/state/actions/invoicing/invoice.actions';
import {
  getInvoice,
  getInvoiceNotesCount,
} from '@/state/selectors/invoicing/invoice.selector';
import { getInfoUser } from '@/state/selectors/info.selector';
import {
  HirlGcpInvoiceChangeDialogComponent,
  HirlGcpInvoiceChangeDialogData,
} from '@/modules/customer-hirl/components/hirl-gcp-invoice-change-dialog/hirl-gcp-invoice-change-dialog.component';

@Component({
  selector: 'app-hirl-invoice-detail',
  templateUrl: './hirl-invoice-detail.component.html',
  styleUrls: ['./hirl-invoice-detail.component.scss'],
})
export class HIRLInvoiceDetailComponent implements OnInit, OnDestroy {
  protected readonly InvoiceType = InvoiceType;
  protected readonly companyType = CompanyType;

  public initialized = false;

  public invoice: Invoice;

  public selectedIndex = 0;
  public invoiceNotesCount = 0;

  public currentUser: User;

  public autoPayPermissionResponse: Observable<ObjectPermissionResponse>;
  public deletePermissionResponse: Observable<ObjectPermissionResponse>;
  public editPermissionResponse: Observable<ObjectPermissionResponse>;

  private componentDestroyed$ = new Subject();
  public fallbackUrl = '';

  constructor(
    public store: Store<AppState>,
    public invoiceService: InvoiceService,
    public activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public router: Router,
    public uiHelperService: UIHelperService,
    private invoicePermissionService: InvoicePermissionService,
    private location: Location,
    private navigation: NavigationService
  ) {
    this.store
      .select(getInvoice)
      .pipe(
        filter(val => val !== null),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe({
        next: (invoice: Invoice) => {
          this.invoice = invoice;

          if (invoice.invoice_type === InvoiceType.hirlProject) {
            this.fallbackUrl = '/hi/invoices';
          } else if (
            invoice.invoice_type === InvoiceType.hirlGCP ||
            invoice.invoice_type === InvoiceType.hirlGCPInitial ||
            invoice.invoice_type === InvoiceType.hirlGCPTopShelf
          ) {
            this.fallbackUrl = '/hi/invoices/gcp/list';
          }
          this.store.dispatch(toggleLoading({ payload: false }));
          this.deletePermissionResponse =
            this.invoicePermissionService.canDelete(this.invoice);
          this.autoPayPermissionResponse =
            this.invoicePermissionService.canAutoPay(this.invoice);
          this.editPermissionResponse = this.invoicePermissionService.canEdit(
            this.invoice
          );
        },
        error: error => {
          this.uiHelperService.handleMainRequestError(error);
        },
      });

    this.store
      .select(getInvoiceNotesCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(count => {
        this.invoiceNotesCount = count;
      });
  }

  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(params => {
        this.store.dispatch(toggleLoading({ payload: true }));
        forkJoin({
          invoice: this.invoiceService.retrieve(params.invoiceId).pipe(first()),
          currentUser: this.store.select(getInfoUser).pipe(first()),
        }).subscribe(
          ({ invoice, currentUser }) => {
            this.invoice = invoice;

            this.currentUser = currentUser;
            this.store.dispatch(toggleLoading({ payload: false }));
            this.initialized = true;
          },
          error => this.uiHelperService.handleMainRequestError(error)
        );
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
    this.store.dispatch(resetInvoice());
  }

  onDownloadInvoice(event, invoiceId: string) {
    this.store.dispatch(toggleLoading({ payload: true }));
    this.invoiceService
      .customer_hirl_pdf_invoice_report(invoiceId)
      .pipe(
        takeUntil(this.componentDestroyed$),
        map(response => new Blob([response], { type: 'application/pdf' }))
      )
      .subscribe(
        data => {
          this.store.dispatch(toggleLoading({ payload: false }));
          const downloadURL = window.URL.createObjectURL(data);
          const link = document.createElement('a');
          link.href = downloadURL;
          link.download = `${this.invoice.id}.pdf`;
          link.click();
        },
        error => {
          this.store.dispatch(toggleLoading({ payload: false }));
        }
      );
  }

  onEditInvoice($event: MouseEvent, invoice: Invoice) {
    $event.preventDefault();

    if (
      invoice.invoice_type === InvoiceType.hirlGCP ||
      invoice.invoice_type === InvoiceType.hirlGCPInitial ||
      invoice.invoice_type === InvoiceType.hirlGCPTopShelf
    ) {
      const dialogRef = this.dialog.open(HirlGcpInvoiceChangeDialogComponent, {
        width: '50%',
        data: {
          invoice: invoice,
        } as HirlGcpInvoiceChangeDialogData,
      });

      dialogRef
        .afterClosed()
        .pipe(first())
        .subscribe(result => {
          if (!result) {
            return;
          }
          this.store.dispatch(toggleLoading({ payload: true }));
          this.invoiceService
            .auto_pay(this.invoice.prefixed_id)
            .pipe(first())
            .subscribe({
              next: (_: Invoice) => {
                this.store.dispatch(toggleLoading({ payload: false }));
                this.uiHelperService.openSnackBar('Success');
              },
              error: error =>
                this.uiHelperService.handleUserRequestError(error),
            });
        });
    }
  }

  onDeleteInvoice($event: MouseEvent, invoiceId: string) {
    $event.preventDefault();

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '50%',
      data: {
        title: 'Do you want to permanently delete this Invoice?',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (!result) {
          return;
        }
        this.store.dispatch(toggleLoading({ payload: true }));
        this.invoiceService
          .delete(this.invoice.prefixed_id)
          .pipe(first())
          .subscribe(
            _ => {
              this.store.dispatch(toggleLoading({ payload: false }));
              this.router.navigate(['/', 'hi', 'invoices']);
            },
            error => this.uiHelperService.handleUserRequestError(error)
          );
      });
  }

  onCancelInvoice($event: MouseEvent, invoiceId: string) {
    $event.preventDefault();

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '50%',
      data: {
        title: 'Do you want to Cancel this Invoice?',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (!result) {
          return;
        }
        this.store.dispatch(toggleLoading({ payload: true }));
        this.invoiceService
          .cancel(this.invoice.prefixed_id)
          .pipe(first())
          .subscribe({
            next: (_: Invoice) => {
              this.store.dispatch(toggleLoading({ payload: false }));
              this.uiHelperService.openSnackBar('Invoice Cancelled');
            },
            error: error => this.uiHelperService.handleUserRequestError(error),
          });
      });
  }

  onAutoPayInvoice($event: MouseEvent, invoiceId: string) {
    $event.preventDefault();

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '50%',
      data: {
        title: 'Do you want to mark this invoice as PAID?',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (!result) {
          return;
        }
        this.store.dispatch(toggleLoading({ payload: true }));
        this.invoiceService
          .auto_pay(this.invoice.prefixed_id)
          .pipe(first())
          .subscribe({
            next: (_: Invoice) => {
              this.store.dispatch(toggleLoading({ payload: false }));
              this.uiHelperService.openSnackBar('Invoice Paid');
            },
            error: error => this.uiHelperService.handleUserRequestError(error),
          });
      });
  }

  shareHIRLInvoice($event: MouseEvent, id: string) {
    $event.preventDefault();

    const dialogRef = this.dialog.open(HirlInvoiceShareDialogComponent, {
      width: '50%',
      data: {
        invoiceId: this.invoice.prefixed_id,
      } as HirlInvoiceShareDialogData,
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe({
        next: invoice => {
          if (invoice) {
            this.uiHelperService.openSnackBar('Invoice copy successfully sent');
          }
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  onTabChange(event: MatTabChangeEvent) {
    const url = this.router
      .createUrlTree([], {
        relativeTo: this.activatedRoute,
        queryParams: { tab: event.index },
      })
      .toString();
    this.location.replaceState(url);
  }
}
