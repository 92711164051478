<div class="position-relative main" appFocusTarget entityName="mechanicalEquipment" [entityId]="entityId">
    <app-loading-overlay *ngIf="loading" [diameter]="30"></app-loading-overlay>
    <app-form formName="equipmentForm" [formGroup]="equipmentForm" (save)="onSave($event)">
        <div class="container w-100  m-0">
            <div class="row w-100">
                <div class="col-3">
                    <app-input formControlName="name"></app-input>
                </div>
                <div class="col-3">
                    <app-select formControlName="location" [items]="locationLabels"></app-select>
                </div>
                <div class="col-1">
                    <app-input formControlName="heating_percent_served" type="number"></app-input>
                </div>
                <div class="col-1">
                    <app-input formControlName="cooling_percent_served" type="number"></app-input>
                </div>
                <div class="col-1">
                    <app-input formControlName="water_heater_percent_served" type="number"></app-input>
                </div>
                <div class="col-1">
                    <app-input formControlName="dehumidifier_percent_served" type="number"></app-input>
                </div>
                <div class="col-2">
                    <app-foreign-model-field
                        class="layer-box"
                        (edit)="editEquipmentDetails()"
                        [name]="equipmentTypeLabels[attachedEquipmentType]"
                        [errorCount]="equipmentErrorCount">
                    </app-foreign-model-field>
                </div>
            </div>
        </div>
    </app-form>
    <button *appRequiresEditPermission mat-icon-button class="delete-button">
        <mat-icon inline="true" (click)="removeEquipment()">delete</mat-icon>
    </button>
</div>
