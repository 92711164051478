<h2 mat-dialog-title>
  <ng-container *ngIf="!isEditForm">
    Add New RamRate User
  </ng-container>
  <ng-container *ngIf="isEditForm">
    Edit RamRate User
  </ng-container>
</h2>
<mat-dialog-content *ngIf="initialized">
  <form [formGroup]="form" class="mb-2">
    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100 mb-2">
          <mat-label>Username</mat-label>
          <input matInput type="text" placeholder="Username" formControlName="username">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100 mb-2">
          <mat-label>Password</mat-label>
          <input matInput type="text" placeholder="Password" formControlName="password">
        </mat-form-field>
      </div>
    </div>
    <div class="row" *ngIf="form.value.password !== entity.password">
      <div class="col-12">
        <mat-form-field class="w-100 mb-2">
          <mat-label>Confirm Password</mat-label>
          <input matInput type="text" placeholder="Confirm New Password" formControlName="confirm_password">
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>
<div class="row">
  <div class="col-12">
    <hr>
  </div>
</div>
<mat-dialog-actions align="center">
  <button mat-button color="accent" mat-raised-button (click)="save($event)">Save</button>
  <button mat-button [mat-dialog-close]="null" color="primary" mat-raised-button>Cancel</button>
</mat-dialog-actions>
