<div class="row">
  <div class="col-12">
    <div class="row mb-2">
      <div class="col-9">
      </div>
      <div class="col-3 text-right">
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <app-table-filters [formGroup]="filterFormGroup" [params]="this.storedParams"
                           [overwritePropertyValues]="overwritePropertyValues"
                           [overwritePropertyLabels]="overwritePropertyLabels"
                           [excludedProperties]="excludedProperties">
          <mat-accordion multi>
            <mat-expansion-panel [expanded]="true">
              <div class="row">
                <div class="col-12">
                  <mat-form-field class="w-100">
                    <mat-label>Search</mat-label>
                    <app-search-input formControlName="search"
                                      placeholder="Type to search something"></app-search-input>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <mat-form-field class="w-100">
                    <mat-label>Program</mat-label>
                    <app-generic-model-chips formControlName="eep_program_info" modelClassName="eep_program" [panelWidth]="640" [initialValueIds]="storedParams.eep_program" [filterParams]="{home_status_related: true}"></app-generic-model-chips>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <mat-form-field class="w-100">
                    <mat-label>Role</mat-label>
                    <mat-select formControlName="role">
                      <mat-option [value]="''">
                        Any
                      </mat-option>
                      <mat-option [value]="'rater_of_record'">
                        Rater Of Record
                      </mat-option>
                      <mat-option [value]="'energy_modeler'">
                        Energy Modeler
                      </mat-option>
                      <mat-option [value]="'field_inspector'">
                        Field Inspector
                      </mat-option>
                      <mat-option [value]="'customer_hirl_rough_verifier'">
                        Rough Verifier
                      </mat-option>
                      <mat-option [value]="'customer_hirl_final_verifier'">
                        Final Verifier
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </app-table-filters>
      </div>
      <div class="col-9">
        <app-generic-table [dataSource]="getDataSource" [storedParams]="storedParams" [displayedColumns]="displayedColumns" [routerParamsToQueryString]="false" #tableComponent>
          <ng-container matSort>
            <ng-container matColumnDef="eep_program__name">
              <th mat-header-cell *matHeaderCellDef>Program Name</th>
              <td mat-cell *matCellDef="let row">
                <app-eep-program-display [eepProgram]="row.eep_program_info"></app-eep-program-display>
              </td>
            </ng-container>

            <ng-container matColumnDef="role">
              <th mat-header-cell *matHeaderCellDef>Role</th>
              <td mat-cell *matCellDef="let row">
                <ng-container *ngIf="row.rater_of_record === user.id">
                  <p>Rater Of Record</p>
                </ng-container>

                <ng-container *ngIf="row.energy_modeler === user.id">
                  <p>Energy Modeler</p>
                </ng-container>

                <ng-container *ngIf="row.field_inspectors?.includes(user.id)">
                  <p>Field Inspector</p>
                </ng-container>

                <ng-container *ngIf="row.customer_hirl_rough_verifier === user.id">
                  <p>Rough Verifier</p>
                </ng-container>

                <ng-container *ngIf="row.customer_hirl_final_verifier === user.id">
                  <p>Final Verifier</p>
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="home">
              <th mat-header-cell *matHeaderCellDef>Project</th>
              <td mat-cell *matCellDef="let row">
                <app-home-display [home]="row.home_info" displayType="addressAsLink"></app-home-display>
              </td>
            </ng-container>

            <ng-container matColumnDef="certification_date">
              <th mat-header-cell *matHeaderCellDef>
                Certification Date
              </th>
              <td mat-cell *matCellDef="let row">
                {{ row.certification_date | date: 'MM/dd/yyyy' }}
              </td>
            </ng-container>
          </ng-container>
        </app-generic-table>
      </div>
    </div>
  </div>
</div>
