import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { AltName, Company } from '@/data/company/models';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { filter, first, takeUntil } from 'rxjs/operators';
import {
  getCompany,
  getCompanyAltNames,
} from '@/state/selectors/company/company.selector';
import { deleteCompanyAltName } from '@/state/actions/company/company.actions';
import { MatDialog } from '@angular/material/dialog';
import {
  CompanyAlternateNameCreateDialogComponent,
  CompanyAlternateNameCreateDialogData,
} from '@/modules/company/components/company-alternate-name-list/company-alternate-name-create-dialog/company-alternate-name-create-dialog.component';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { toggleLoading } from '@/state/actions/app.actions';
import { AltNameService } from '@/data/company/services/alt-name.service';
import { ComponentCompanyType } from '@/modules/company/constants';
import { Subject } from 'rxjs';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { getInfoUser } from '@/state/selectors/info.selector';
import { User } from '@/data/core/models/user';
import { CompanyService } from '@/data/company/services/company-base.service';

@Component({
  selector: 'app-company-alternate-name-list',
  templateUrl: './company-alternate-name-list.component.html',
  styleUrls: ['./company-alternate-name-list.component.scss'],
})
export class CompanyAlternateNameListComponent implements OnInit, OnDestroy {
  @Input() componentCompanyType: ComponentCompanyType;
  initialized = false;

  currentUser: User;

  company: Company;
  companyDisplayName: string;

  altNames: AltName[];
  altNamesCount: number;

  private componentDestroyed$ = new Subject();

  constructor(
    public store: Store<AppState>,
    public injector: Injector,
    public dialog: MatDialog,
    public altNameService: AltNameService,
    private companyService: CompanyService,
    public uiHelperService: UIHelperService
  ) {}

  ngOnInit(): void {
    this.store
      .select(getCompany)
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(val => val !== null)
      )
      .subscribe(company => {
        this.company = company;

        this.companyService
          .alt_names(company.id)
          .pipe(first())
          .subscribe(_ => {
            this.componentIsReady();
          });
      });

    this.store
      .select(getCompanyAltNames)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(altNames => {
        this.altNames = altNames;
      });

    this.store
      .select(getInfoUser)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(currentUser => {
        this.currentUser = currentUser;
        this.componentIsReady();
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  componentIsReady() {
    if (this.company && this.currentUser) {
      this.initialized = true;
    }
    return this.initialized;
  }

  onCreate($event: MouseEvent) {
    $event.preventDefault();

    const data = new CompanyAlternateNameCreateDialogData();
    data.company = this.company;
    data.companyService = this.companyService;

    const dialogRef = this.dialog.open(
      CompanyAlternateNameCreateDialogComponent,
      {
        width: '45%',
        data,
      }
    );

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(altName => {
        if (!altName) {
          return;
        }
      });
  }

  onDelete($event: MouseEvent, altName: AltName) {
    $event.preventDefault();

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '45%',
      data: {
        title: 'Delete Alternative Name ?',
        content: `Do you want to delete Alternative Name: ${altName.name}`,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      this.store.dispatch(toggleLoading({ payload: true }));
      this.altNameService
        .delete(altName.id)
        .pipe(first())
        .subscribe(
          _ => {
            this.store.dispatch(deleteCompanyAltName({ payload: altName }));
            this.store.dispatch(toggleLoading({ payload: false }));
          },
          error => this.uiHelperService.handleUserRequestError(error)
        );
    });
  }
}
