import { UserInfo } from '@/data/core/models/user';

export enum MessageLevel {
  debug = 'debug',
  info = 'info',
  success = 'success',
  warning = 'warning',
  error = 'error',
  system = 'system',
}

export const MessageLevelLabelMapping: Record<MessageLevel, any> = {
  [MessageLevel.debug]: {
    label: 'Debug',
    icon: 'cog',
    color: '#212529',
    backgroundColor: '#FFFFFF',
  },
  [MessageLevel.info]: {
    label: 'Info',
    icon: 'info-circle',
    color: '#31708f',
    backgroundColor: '#d9edf7',
  },
  [MessageLevel.success]: {
    label: 'Success',
    icon: 'check-circle',
    color: '#3c763d',
    backgroundColor: '#dff0d8',
  },
  [MessageLevel.warning]: {
    label: 'Warning',
    icon: 'exclamation-triangle',
    color: '#8a6d3b',
    backgroundColor: '#fcf8e3',
  },
  [MessageLevel.error]: {
    label: 'Error',
    icon: 'exclamation-circle',
    color: '#a94442',
    backgroundColor: '#f2dede',
  },
  [MessageLevel.system]: {
    label: 'System',
    icon: 'cog',
    color: '#212529',
    backgroundColor: '#FFFFFF',
  },
};

export class ModernMessage {
  id: number;
  alert_read: boolean;
  category: string;
  content: string;
  date_alerted: string;
  date_created: string;
  date_sent: string;
  email_read: string;
  level: MessageLevel;
  sender: string;
  sticky_alert: boolean;
  title: string;
  url: string;
  alert_seen_at?: string;
  email_seen_at?: string;
  user?: number;
  user_info?: UserInfo;
}
