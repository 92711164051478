import { createAction, props } from '@ngrx/store';
import {
  AltName,
  Company,
  CompanyAccess,
  SponsoringPreferences,
} from '@/data/company/models';
import { CustomerDocument } from '@/data/filehandling/models';
import { AffiliationPreferences } from '@/data/company/models/sponsoring';
import { COIDocument } from '@/data/customer-hirl/models';
import { ContactCard } from '@/data/core/models';
import { Equipment } from '@/data/equipment/models';
import { RemRateUser } from '@/data/remrate/models';
import { Annotation } from '@/data/annotation/models';

export const resetCompany = createAction('[Company] Reset');

export const loadCompany = createAction(
  '[Company] Detail',
  props<{ payload: Company }>()
);

// Company Access

export const loadCompanyCompanyAccessAction = createAction(
  '[Company CompanyAccess] List',
  props<{ payload: CompanyAccess[] }>()
);

export const loadCompanyCompanyAccessCountAction = createAction(
  '[Company CompanyAccess] Count',
  props<{ payload: number }>()
);

export const updateCompanyCompanyAccessAction = createAction(
  '[Company CompanyAccess] Update User',
  props<{ payload: CompanyAccess }>()
);

// CustomerDocuments

export const loadCompanyDocuments = createAction(
  '[Company Documents] Documents',
  props<{ payload: CustomerDocument[] }>()
);

export const loadCompanyDocumentsCount = createAction(
  '[Company Documents] Documents Count',
  props<{ payload: number }>()
);

export const updateCompanyDocument = createAction(
  '[Company Documents] Update Document',
  props<{ payload: CustomerDocument }>()
);

export const deleteCompanyDocument = createAction(
  '[Company Documents] Delete Document',
  props<{ payload: CustomerDocument }>()
);

// AltNames

export const loadCompanyAltNames = createAction(
  '[Company AltNames] AltNames',
  props<{ payload: AltName[] }>()
);

export const loadCompanyAltNamesCount = createAction(
  '[Company AltNames] AltNames Count',
  props<{ payload: number }>()
);

export const createCompanyAltName = createAction(
  '[Company AltNames] Create AltName',
  props<{ payload: AltName }>()
);

export const updateCompanyAltName = createAction(
  '[Company AltNames] Update AltName',
  props<{ payload: AltName }>()
);

export const deleteCompanyAltName = createAction(
  '[Company AltNames] Delete AltName',
  props<{ payload: AltName }>()
);

// AffiliationPreferences

export const loadCompanyAffiliationPreferences = createAction(
  '[Company AffiliationPreferences] AffiliationPreferences',
  props<{ payload: AffiliationPreferences[] }>()
);

export const loadCompanyAffiliationPreferencesCount = createAction(
  '[Company AffiliationPreferences] AffiliationPreferences Count',
  props<{ payload: number }>()
);

// Sponsoring

export const loadCompanySponsoringPreferences = createAction(
  '[Company SponsoringPreferences] SponsoringPreferences',
  props<{ payload: SponsoringPreferences[] }>()
);

export const loadCompanySponsoringPreferencesCount = createAction(
  '[Company SponsoringPreferences] SponsoringPreferences Count',
  props<{ payload: number }>()
);

// CustomerDocuments

export const loadCompanyCOIDocuments = createAction(
  '[Company COI Documents] COI Documents',
  props<{ payload: COIDocument[] }>()
);

export const loadCompanyCOIDocumentsCount = createAction(
  '[Company COI Documents] COI Documents Count',
  props<{ payload: number }>()
);

export const updateCompanyCOIDocument = createAction(
  '[Company COI Documents] Update COI Document',
  props<{ payload: COIDocument }>()
);

// ContactCards

export const loadCompanyContactCards = createAction(
  '[Company Contact Cards] Contact Cards',
  props<{ payload: ContactCard[] }>()
);

export const loadCompanyContactCardsCount = createAction(
  '[Company Contact Cards] Contact Cards Count',
  props<{ payload: number }>()
);

export const updateCompanyContactCard = createAction(
  '[Company Contact Cards] Update Contact Card',
  props<{ payload: ContactCard }>()
);

// Equipments
export const loadCompanyEquipments = createAction(
  '[Company Equipments] COI Documents',
  props<{ payload: Equipment[] }>()
);

export const loadCompanyEquipmentsCount = createAction(
  '[Company Equipments] COI Documents Count',
  props<{ payload: number }>()
);

export const updateCompanyEquipment = createAction(
  '[Company Equipments] Update COI Document',
  props<{ payload: Equipment }>()
);

// RemRate Users
export const loadCompanyRemRateUsers = createAction(
  '[Company RemRate Users] RemRate Users List',
  props<{ payload: RemRateUser[] }>()
);

export const loadCompanyRemRateUsersCount = createAction(
  '[Company RemRate Users] RemRate Users Count',
  props<{ payload: number }>()
);

export const updateCompanyRemRateUser = createAction(
  '[Company RemRate Users] Update RemRate Users',
  props<{ payload: RemRateUser }>()
);

// Notes

export const loadCompanyNotes = createAction(
  '[Company Notes] Notes',
  props<{ payload: Annotation[] }>()
);

export const loadCompanyNotesCount = createAction(
  '[Company Notes] Notes Count',
  props<{ payload: number }>()
);
