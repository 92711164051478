import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BaseDataService } from '@/core/services/base-data-service';
import { Observable } from 'rxjs';
import { LocationBackendDict } from '../models/Location';
import { ListRequestParams } from '@/core/schemes/request-params';
import { ServerResponse } from '@/core/schemes/server-response';

@Injectable({ providedIn: 'root' })
export class LocationService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/location/`;

  list(
    params: ListRequestParams
  ): Observable<ServerResponse<LocationBackendDict>> {
    return this.http.get<ServerResponse<LocationBackendDict>>(
      `${this.baseUrl}`,
      {
        params: this.rollParams(params),
      }
    );
  }

  update(
    locationChanges: Partial<LocationBackendDict>
  ): Observable<LocationBackendDict> {
    return this.http.patch<LocationBackendDict>(
      `${this.baseUrl}${locationChanges.id}/`,
      locationChanges
    );
  }

  get_local_weathers(params) {
    return this.http.get(
      `${environment.AXIS_API_V3_ROOT}/simulations/${params.simId}/location/${params.locationId}/local_weather/`
    );
  }
}
