import {
  DetailedSimulation,
  Simulation,
  SimulationBackendDict,
  SimulationResidenceType,
  SimulationResidenceTypeLabelMapping,
  createSimulationForm,
} from '@/data/simulation/models/simulation';
import {
  BaseValidator,
  connectWithState,
  ValidationErrors,
  ValidationResult,
} from './base.validator';
import {
  ExteriorLocation,
  isLocationAssociatedWithBasement,
} from '@/data/simulation/models/enclosure/FrameFloor';
import { isLocationGarage, isLocationConditioned } from './helper';
import { ModelErrors, StateErrors } from '../state/shared/base.state';
import { getEquipmentType } from '@/data/simulation/models/mechanicals/MechanicalEquipment';
import { InteriorLocation } from '@/data/simulation/models/enclosure/Roof';
import { DistributionSystemType } from '@/data/simulation/models/mechanicals/DistributionSystem';
import {
  FuelType,
  FuelTypeLabels,
  LocationLabels,
  Location,
  MechanicalMotorType,
} from '@/data/simulation/enumerations';
import { isCrawlSpaceLocation } from '@/data/simulation/models/enclosure/Slab';
import { HeatingSystemType } from '@/data/simulation/models/mechanicals/Heater';
import { CoolingSystemType } from '@/data/simulation/models/mechanicals/AirConditioner';
import { OpenStudioAnalysisSourceName } from '@/data/simulation/models/reports/Analysis';
import { StateModelName } from '../state.registry';

function hasExplicitlyDefinedGarage(simulation) {
  const garageLocation = Location.GARAGE;
  const slabs = simulation.slabs_info || [];
  const walls = simulation.above_grade_walls_info || [];
  const roofs = simulation.roofs_info || [];

  // Check if any of the slabs, walls, or roofs have the interior_location set to GARAGE
  return (
    slabs.some(slab => slab.interior_location === garageLocation) ||
    walls.some(wall => wall.interior_location === garageLocation) ||
    roofs.some(roof => roof.interior_location === garageLocation)
  );
}

function getExplicitlyDefinedGarageLocations(simulation: DetailedSimulation) {
  const garageLocation = Location.GARAGE;
  const locations = [];
  const slabs = simulation.slabs_info || [];
  const walls = simulation.above_grade_walls_info || [];
  const roofs = simulation.roofs_info || [];

  // Helper function to process and pluralize location data
  const processLocation = (items, typeSingular, typePlural) => {
    const filteredItems = items.filter(
      item => item.interior_location === garageLocation
    );
    if (filteredItems.length) {
      locations.push(
        `${filteredItems.length > 1 ? filteredItems.length : 'a'} ${
          filteredItems.length > 1 ? typePlural : typeSingular
        }`
      );
    }
  };

  processLocation(slabs, 'slab', 'slabs');
  processLocation(walls, 'above grade wall', 'above grade walls');
  processLocation(roofs, 'roof', 'roofs');

  return locations.length
    ? `Simulation has ${locations.join(
        ' and '
      )} where the interior location is defined as a garage.`
    : 'Simulation has no explicitly defined garage locations.';
}

function filterByGarageLocation(items) {
  return (items || []).filter(
    item => item.interior_location === Location.GARAGE
  );
}
//
export class SimulationValidator extends BaseValidator {
  @connectWithState(StateModelName.frameFloor, ['_'])
  @connectWithState(StateModelName.foundationWall, [
    'interior_location',
    'name',
  ])
  static validateFrameFloorsAttachedToFoundationWalls(
    simulation: DetailedSimulation
  ) {
    const foundationWalls = simulation.foundation_walls_info || [];
    const result = {
      id: 'frameFloorsAttachedToFoundationWalls',
      validationSuccess: true,
      data: {
        foundationWallNames: [],
        locations: [],
      },
    };

    foundationWalls.forEach(foundationWall => {
      if (
        simulation.frame_floors.length === 0 &&
        !isLocationConditioned(foundationWall.interior_location)
      ) {
        result.validationSuccess = false;
        result.data.foundationWallNames.push(foundationWall.name);
        result.data.locations.push(foundationWall.interior_location);
      }
    });

    return [result];
  }

  @connectWithState(StateModelName.appliances, [
    'clothes_washer_label_electric_consumption',
  ])
  static validateWasherConsumption(simulation: DetailedSimulation) {
    const appliances = simulation.appliances_info;
    const consumptionPerYear =
      appliances.clothes_washer_label_electric_consumption;
    const bedroomCount = simulation.bedroom_count;
    const lower = 1.92 * bedroomCount + 6.77;
    const upper = 21 * bedroomCount + 73;

    const result = {
      id: 'washerConsumptionNotWithinLimit',
      validationSuccess: true,
      data: {
        lowerLimit: lower,
        upperLimit: upper,
        bedroomCount,
        consumptionPerYear,
      },
    };

    if (
      consumptionPerYear !== null &&
      (consumptionPerYear < lower || consumptionPerYear > upper)
    ) {
      result.validationSuccess = false;
    }

    return [result];
  }

  @connectWithState(StateModelName.window, ['area'])
  @connectWithState(StateModelName.frameFloor, ['area'])
  static validateWindowToFloorAreaRatio(simulation: DetailedSimulation) {
    const floors = simulation.frame_floors_info || [];
    const windows = simulation.windows_info || [];

    let floorArea = 0;
    let windowArea = 0;

    const result = {
      id: 'windowToFloorAreaRatio',
      validationSuccess: true,
      data: {
        ratio: '',
      },
    };

    floors.forEach(floor => (floorArea += floor.area));
    windows.forEach(window => (windowArea += window.area));

    if (floorArea === 0 || windowArea === 0) return [result];

    const ratio = (windowArea / floorArea) * 100;

    if (ratio < 7 || ratio > 35) {
      result.validationSuccess = false;
      result.data.ratio = ratio.toFixed(2);
    }
    return [result];
  }

  static validateHvacCount(simulation: DetailedSimulation): ValidationResult[] {
    const mechanicalEquipments = simulation.mechanical_equipment_info || [];

    let heatPumpsCount = 0;
    let heaterCount = 0;
    let airConditionerCount = 0;
    let nonHeatPumpsCount = 0;

    mechanicalEquipments.forEach(mechanicalEquipment => {
      if (
        mechanicalEquipment.air_source_heat_pump ||
        mechanicalEquipment.ground_source_heat_pump
      ) {
        heatPumpsCount++;
      } else if (mechanicalEquipment.heater) {
        heaterCount++;
      } else if (mechanicalEquipment.air_conditioner) {
        airConditionerCount++;
      }
    });

    nonHeatPumpsCount = Math.max(heaterCount, airConditionerCount);

    const result = {
      id: 'unsupportedHvacCount',
      validationSuccess: true,
      data: {
        nonHeatPumpsCount,
        heatPumpsCount,
      },
    };

    if (nonHeatPumpsCount + heatPumpsCount > 2) {
      result.validationSuccess = false;
    }

    return [result];
  }

  @connectWithState(StateModelName.frameFloor, ['area'])
  static validateFloorsAreaAgainstConditionedArea(
    simulation: DetailedSimulation
  ) {
    const frameFloors = simulation.frame_floors_info || [];

    const result = {
      id: 'frameFloorsAreaGreaterThanConditionedFloorArea',
      validationSuccess: true,
      data: {
        frameFloorsArea: 0,
        conditionedFloorArea: simulation.conditioned_area,
      },
    };

    frameFloors.forEach(frameFloor => {
      if (!frameFloor.represents_ceiling) {
        result.data.frameFloorsArea += frameFloor.area;
      }
    });

    if (result.data.frameFloorsArea > simulation.conditioned_area) {
      result.validationSuccess = false;
    }
    return [result];
  }

  @connectWithState(StateModelName.aboveGradeWall, ['area'])
  @connectWithState(StateModelName.window, ['area', 'above_grade_wall'])
  @connectWithState(StateModelName.door, ['area', 'above_grade_wall'])
  static validateFenestrationAreaAgainstAboveGradeWall(
    simulation: DetailedSimulation
  ) {
    const aboveGradeWalls = simulation.above_grade_walls_info || [];
    const windows = simulation.windows_info || [];
    const doors = simulation.doors_info || [];

    const largeFenestrationAreaResult = {
      id: 'fenestrationAreaGreaterThanAboveGradeWallArea',
      validationSuccess: true,
      data: {
        wallNames: [],
      },
    };

    const fenestrationAreaMoreThanExpectedWallArea = {
      id: 'fenestrationAreaMoreThanExpectedAboveGradeWallArea',
      validationSuccess: true,
      data: {
        wallNames: [],
      },
    };

    aboveGradeWalls.forEach(aboveGradeWall => {
      let fenestrationArea = 0;
      const wallArea = aboveGradeWall.area;

      windows.forEach(window => {
        if (window.above_grade_wall === aboveGradeWall.id) {
          fenestrationArea += window.area;
        }
      });

      doors.forEach(door => {
        if (door.above_grade_wall === aboveGradeWall.id) {
          fenestrationArea += door.area;
        }
      });

      if (fenestrationArea >= wallArea) {
        largeFenestrationAreaResult.validationSuccess = false;
        largeFenestrationAreaResult.data.wallNames.push(aboveGradeWall.name);
      }

      if (fenestrationArea > wallArea * 0.85) {
        fenestrationAreaMoreThanExpectedWallArea.validationSuccess = false;
        fenestrationAreaMoreThanExpectedWallArea.data.wallNames.push(
          aboveGradeWall.name
        );
      }
    });

    return [
      largeFenestrationAreaResult,
      fenestrationAreaMoreThanExpectedWallArea,
    ];
  }

  @connectWithState(StateModelName.foundationWall, [
    'perimeter_length',
    'height_above_grade',
    'depth_below_grade',
  ])
  @connectWithState(StateModelName.window, ['area', 'foundation_wall'])
  @connectWithState(StateModelName.door, ['area', 'foundation_wall'])
  static validateFenestrationAreaAgainstFoundationWall(
    simulation: DetailedSimulation
  ) {
    const foundationWalls = simulation.foundation_walls_info || [];
    const windows = simulation.windows_info || [];
    const doors = simulation.doors_info || [];

    const largeFenestrationAreaResult = {
      id: 'fenestrationAreaGreaterThanFoundationWallArea',
      validationSuccess: true,
      data: {
        wallNames: [],
      },
    };

    const fenestrationAreaMoreThanExpectedWallArea: ValidationResult = {
      id: 'fenestrationAreaMoreThanExpectedFoundationWallArea',
      validationSuccess: true,
      data: {
        wallNames: [],
      },
    };

    foundationWalls.forEach(foundationWall => {
      let fenestrationArea = 0;
      const wallArea =
        foundationWall.perimeter_length *
        (foundationWall.height_above_grade + foundationWall.depth_below_grade);

      windows.forEach(window => {
        if (window.foundation_wall === foundationWall.id) {
          fenestrationArea += window.area;
        }
      });

      doors.forEach(door => {
        if (door.foundation_wall === foundationWall.id) {
          fenestrationArea += door.area;
        }
      });

      if (fenestrationArea >= wallArea) {
        largeFenestrationAreaResult.validationSuccess = false;
        largeFenestrationAreaResult.data.wallNames.push(foundationWall.name);
      }
      if (fenestrationArea > wallArea * 0.85) {
        fenestrationAreaMoreThanExpectedWallArea.validationSuccess = false;
        fenestrationAreaMoreThanExpectedWallArea.data.wallNames.push(
          foundationWall.name
        );
      }
    });

    return [
      largeFenestrationAreaResult,
      fenestrationAreaMoreThanExpectedWallArea,
    ];
  }

  @connectWithState(StateModelName.frameFloor, ['exterior_location'])
  @connectWithState(StateModelName.foundationWall, ['interior_location'])
  static validateUnconditionedFoundationsHaveFrameFloors(
    simulation: DetailedSimulation
  ) {
    const frameFloors = simulation.frame_floors_info || [];
    const foundationWalls = simulation.foundation_walls_info || [];

    const result = {
      id: 'missingFrameFloorsInLocations',
      validationSuccess: true,
      data: {
        locations: '',
      },
    };

    const missingFrameFloorLocations = [];
    const frameFloorLocations = {};

    frameFloors.forEach(frameFloor => {
      const location = frameFloor.exterior_location;
      if (!isLocationAssociatedWithBasement(location)) {
        frameFloorLocations[location] = true;
      }
    });

    foundationWalls.forEach(foundationWall => {
      const location = foundationWall.interior_location;
      if (isLocationConditioned(location) || isLocationGarage(location)) {
        return;
      }

      if (!frameFloorLocations.hasOwnProperty(location)) {
        missingFrameFloorLocations.push(location);
      }
    });

    if (missingFrameFloorLocations.length > 0) {
      result.validationSuccess = false;
      result.data.locations = missingFrameFloorLocations.join(',');
    }
    return [result];
  }

  @connectWithState(StateModelName.appliances, [
    'oven_fuel',
    'clothes_dryer_fuel',
  ])
  @connectWithState(StateModelName.mechanicalEquipment, ['*'])
  @connectWithState(StateModelName.utilityRate, ['fuel'])
  static validateUtilityRateForRequiredFuels(simulation: DetailedSimulation) {
    const appliances = simulation.appliances_info;
    const utilisedFuelTypes = new Set<FuelType>();

    const result = {
      id: 'missingUtilityRates',
      validationSuccess: true,
      data: {
        fuels: '',
      },
    };

    if (appliances.oven_fuel) utilisedFuelTypes.add(appliances.oven_fuel);
    if (appliances.clothes_dryer_fuel)
      utilisedFuelTypes.add(appliances.clothes_dryer_fuel);

    (simulation.mechanical_equipment_info || []).forEach(
      mechanicalEquipment => {
        const equipmentType = getEquipmentType(mechanicalEquipment);
        if (equipmentType)
          utilisedFuelTypes.add(
            mechanicalEquipment[`${equipmentType}_info`].fuel as FuelType
          );
      }
    );

    (simulation.utility_rates_info || []).forEach(utilityRate => {
      utilisedFuelTypes.delete(utilityRate.fuel as FuelType);
    });

    if (utilisedFuelTypes.size > 0) {
      result.validationSuccess = false;
      result.data.fuels = Array.from(utilisedFuelTypes)
        .map(fuel => FuelTypeLabels[fuel])
        .join(', ');
    }
    return [result];
  }

  @connectWithState(StateModelName.mechanicalEquipment, [
    'water_heater',
    'water_heater_percent_served',
  ])
  static validateWaterHeaterPercentageServed(simulation: DetailedSimulation) {
    const mechanicalEquipment = simulation.mechanical_equipment_info || [];

    const warningResult = {
      id: 'WaterHeaterPercentageServedWarning',
      validationSuccess: true,
      data: {
        waterheater: '',
      },
    };

    const errorResult = {
      id: 'WaterHeaterPercentageServedError',
      validationSuccess: true,
      data: {
        waterheater: '',
      },
    };

    const waterHeater = mechanicalEquipment.filter(
      equipment => equipment.water_heater !== null
    );

    if (waterHeater.length === 0) {
      return [warningResult, errorResult];
    }

    waterHeater.forEach(equipment => {
      if (equipment.water_heater_percent_served === 0) {
        if (waterHeater.length > 1) {
          warningResult.validationSuccess = false;
          warningResult.data.waterheater = equipment.name;
        } else {
          errorResult.validationSuccess = false;
          errorResult.data.waterheater = equipment.name;
        }
      }
    });
    return [warningResult, errorResult];
  }

  @connectWithState(StateModelName.distributionSystem, [
    'name',
    'ducts',
    'qty_return_grills',
    'area_served',
    'supply_surface_area',
    'return_surface_area',
  ])
  static validateDistributionSurfaceArea(simulation: DetailedSimulation) {
    const supplyAreaLessthanTarget = {
      id: 'supplyAreaLessthanTarget',
      validationSuccess: true,
      data: {
        supplySurfaceArea: 0,
        target: '',
        distributionName: '',
      },
    };

    const supplyAreaMorethanTarget = {
      id: 'supplyAreaMorethanTarget',
      validationSuccess: true,
      data: {
        supplySurfaceArea: 0,
        target: '',
        distributionName: '',
      },
    };

    const returnAreaLessthanTarget = {
      id: 'returnAreaLessthanTarget',
      validationSuccess: true,
      data: {
        returnSurfaceArea: 0,
        target: '',
        distributionName: '',
      },
    };

    const returnAreaMorethanTarget = {
      id: 'returnAreaMorethanTarget',
      validationSuccess: true,
      data: {
        returnSurfaceArea: 0,
        target: '',
        distributionName: '',
      },
    };

    const floorsOnOrAboveGrade = simulation.floors_on_or_above_grade;
    if (!floorsOnOrAboveGrade) {
      return [
        supplyAreaLessthanTarget,
        supplyAreaMorethanTarget,
        returnAreaLessthanTarget,
        returnAreaMorethanTarget,
      ];
    }
    const f_out = floorsOnOrAboveGrade === 1 ? 1.0 : 0.75;

    const distributionSystems = simulation.hvac_distribution_systems_info || [];

    distributionSystems.forEach(distribution => {
      // Skip if no ducts available
      if (!distribution.ducts || distribution.ducts.length === 0) {
        return [
          supplyAreaLessthanTarget,
          supplyAreaMorethanTarget,
          returnAreaLessthanTarget,
          returnAreaMorethanTarget,
        ];
      }

      // Check for qty_return_grills
      const qtyReturnGrills = distribution.qty_return_grills;
      if (!qtyReturnGrills) {
        return [
          supplyAreaLessthanTarget,
          supplyAreaMorethanTarget,
          returnAreaLessthanTarget,
          returnAreaMorethanTarget,
        ];
      }

      // Set return grills ratio (br)
      const br =
        {
          1: 0.05,
          2: 0.1,
          3: 0.15,
          4: 0.2,
        }[qtyReturnGrills] || 0.25;

      // Area served
      const a_floor = distribution.area_served || simulation.conditioned_area;
      if (!a_floor) {
        return [
          supplyAreaLessthanTarget,
          supplyAreaMorethanTarget,
          returnAreaLessthanTarget,
          returnAreaMorethanTarget,
        ];
      }

      // Validate supply surface area
      if (distribution.supply_surface_area) {
        const supplyTarget = 0.27 * f_out * a_floor;
        if (distribution.supply_surface_area < supplyTarget * 0.8) {
          supplyAreaLessthanTarget.validationSuccess = false;
          supplyAreaLessthanTarget.data.supplySurfaceArea =
            distribution.supply_surface_area;
          supplyAreaLessthanTarget.data.target = supplyTarget.toFixed(2);
          supplyAreaLessthanTarget.data.distributionName = distribution.name;
        }
        if (distribution.supply_surface_area > supplyTarget * 1.2) {
          supplyAreaMorethanTarget.validationSuccess = false;
          supplyAreaMorethanTarget.data.supplySurfaceArea =
            distribution.supply_surface_area;
          supplyAreaMorethanTarget.data.target = supplyTarget.toFixed(2);
          supplyAreaMorethanTarget.data.distributionName = distribution.name;
        }
      }

      // Validate return surface area
      if (distribution.return_surface_area) {
        const returnTarget = br * f_out * a_floor * 0.8;
        if (distribution.return_surface_area < returnTarget * 0.8) {
          returnAreaLessthanTarget.validationSuccess = false;
          returnAreaLessthanTarget.data.returnSurfaceArea =
            distribution.return_surface_area;
          returnAreaLessthanTarget.data.target = returnTarget.toFixed(2);
          returnAreaLessthanTarget.data.distributionName = distribution.name;
        }
        if (distribution.return_surface_area > returnTarget * 1.2) {
          returnAreaMorethanTarget.validationSuccess = false;
          returnAreaMorethanTarget.data.returnSurfaceArea =
            distribution.return_surface_area;
          returnAreaMorethanTarget.data.target = returnTarget.toFixed(2);
          returnAreaMorethanTarget.data.distributionName = distribution.name;
        }
      }
    });
    return [
      supplyAreaLessthanTarget,
      supplyAreaMorethanTarget,
      returnAreaLessthanTarget,
      returnAreaMorethanTarget,
    ];
  }

  @connectWithState(StateModelName.mechanicalEquipment, [
    'heating_percent_served',
    'cooling_percent_served',
    'water_heater_percent_served',
    'dehumidifier_percent_served',
  ])
  static validateMechanicalEquipmentPercentageServed(
    simulation: DetailedSimulation
  ) {
    const mechanicalEquipment = simulation.mechanical_equipment_info || [];

    const heatingPercentServedResult = {
      id: 'heatingPercentServed',
      validationSuccess: true,
      data: {
        actualPercentServed: 0,
      },
    };
    const coolingPercentServedResult = {
      id: 'coolingPercentServed',
      validationSuccess: true,
      data: {
        actualPercentServed: 0,
      },
    };
    const waterHeaterPercentServedResult = {
      id: 'waterHeaterPercentServed',
      validationSuccess: true,
      data: {
        actualPercentServed: 0,
      },
    };
    const dehumidifierPercentServedResult = {
      id: 'dehumidifierPercentServed',
      validationSuccess: true,
      data: {
        actualPercentServed: 0,
      },
    };

    let heatingPercentServed = 0;
    let coolingPercentServed = 0;
    let waterHeaterPercentServed = 0;
    let dehumidifierPercentServed = 0;

    mechanicalEquipment.forEach(equipment => {
      heatingPercentServed += equipment.heating_percent_served;
      coolingPercentServed += equipment.cooling_percent_served;
      waterHeaterPercentServed += equipment.water_heater_percent_served;
      dehumidifierPercentServed += equipment.dehumidifier_percent_served;
    });

    // Round the totals before validation
    heatingPercentServed = Math.round(heatingPercentServed);
    coolingPercentServed = Math.round(coolingPercentServed);
    waterHeaterPercentServed = Math.round(waterHeaterPercentServed);
    dehumidifierPercentServed = Math.round(dehumidifierPercentServed);

    if (heatingPercentServed !== 0 && heatingPercentServed !== 100) {
      heatingPercentServedResult.validationSuccess = false;
      heatingPercentServedResult.data.actualPercentServed =
        heatingPercentServed;
    }
    if (coolingPercentServed !== 0 && coolingPercentServed !== 100) {
      coolingPercentServedResult.validationSuccess = false;
      coolingPercentServedResult.data.actualPercentServed =
        coolingPercentServed;
    }
    if (waterHeaterPercentServed !== 0 && waterHeaterPercentServed !== 100) {
      waterHeaterPercentServedResult.validationSuccess = false;
      waterHeaterPercentServedResult.data.actualPercentServed =
        waterHeaterPercentServed;
    }
    if (dehumidifierPercentServed !== 0 && dehumidifierPercentServed !== 100) {
      dehumidifierPercentServedResult.validationSuccess = false;
      dehumidifierPercentServedResult.data.actualPercentServed =
        dehumidifierPercentServed;
    }
    return [
      heatingPercentServedResult,
      coolingPercentServedResult,
      waterHeaterPercentServedResult,
      dehumidifierPercentServedResult,
    ];
  }

  @connectWithState(StateModelName.mechanicalEquipment, ['heater'])
  @connectWithState(StateModelName.distributionSystem, ['system_type', 'ducts'])
  static validateDuctSystem(simulation: DetailedSimulation) {
    const result = {
      id: 'invalidDuctForHeater',
      validationSuccess: true,
      data: {},
    };

    const heaterIds = (simulation.mechanical_equipment_info || [])
      .filter(equipment => equipment.heater !== null)
      .map(equipment => equipment.id);
    const distributionSystem = simulation.hvac_distribution_systems_info;

    (distributionSystem || []).forEach(distribution => {
      if (
        distribution.system_type === DistributionSystemType.DUCTLESS &&
        (distribution.ducts || []).length !== 0 &&
        heaterIds.includes(distribution.heating_system)
      ) {
        result.validationSuccess = false;
      }
    });
    return [result];
  }

  @connectWithState(StateModelName.frameFloor, ['represents_ceiling'])
  static validateContainsCeling(simulation: DetailedSimulation) {
    const conditionArea = simulation.conditioned_area;

    const result = {
      id: 'shouldContainEitherRoofOrFrameFloorAsCeiling',
      validationSuccess: true,
      data: {},
    };

    if (simulation.roofs.length > 0) {
      return [result];
    }

    const frameFloorAsCeiling = (simulation.frame_floors_info || []).filter(
      frameFloor => frameFloor.represents_ceiling
    );

    if (frameFloorAsCeiling.length === 0) {
      result.validationSuccess = false;
    }

    return [result];
  }

  @connectWithState(StateModelName.frameFloor, ['represents_ceiling', 'area'])
  static validateFloorAreaAgainstConditionedArea(
    simulation: DetailedSimulation
  ) {
    const conditionedFloorArea = simulation.conditioned_area;
    const floorsWithoutCeiling = (simulation.frame_floors_info || []).filter(
      floor => !floor.represents_ceiling
    );

    let floorArea = 0;

    floorsWithoutCeiling.forEach(floor => {
      floorArea += floor.area;
    });

    const result = {
      id: 'frameFloorsAreaGreaterThanConditionedFloorArea',
      validationSuccess: true,
      data: {
        frameFloorsArea: 0,
        conditionedFloorArea,
      },
    };

    if (floorArea > conditionedFloorArea) {
      result.validationSuccess = false;
      result.data.frameFloorsArea = floorArea;
    }
    return [result];
  }

  // If you explicitly define a slab it's area should match the frame floor above it
  @connectWithState(StateModelName.slab, ['interior_location', 'area', 'name'])
  @connectWithState(StateModelName.frameFloor, [
    'interior_location',
    'exterior_location',
    'area',
  ])
  static validateCrawlspaceSlabArea(simulation: DetailedSimulation) {
    const slabs = simulation.slabs_info || [];
    const frameFloors = simulation.frame_floors_info || [];

    const result = {
      id: 'checkCrawlspaceSlabArea',
      validationSuccess: true,
      data: {
        slabName: '',
        location: '',
        slabArea: 0,
        frameFloorArea: 0,
      },
    };

    // Filter slabs that are in crawlspace locations
    const crawlSpaceSlabs = slabs.filter(slab =>
      isCrawlSpaceLocation(slab.interior_location)
    );

    crawlSpaceSlabs.forEach(slab => {
      const slabArea = slab.area;
      const frameFloorArea = 0;
      // Find corresponding frame floors
      const matchingFrameFloors = frameFloors.filter(
        fw =>
          fw.interior_location?.toString() ===
            slab.interior_location.toString() ||
          fw.exterior_location?.toString() === slab.interior_location.toString()
      );
      // Sum up the areas of matching frame floors
      const totalFrameFloorArea = matchingFrameFloors.reduce(
        (sum, fw) => sum + fw.area,
        0
      );

      // Check if slab area matches the total frame floor area
      if (Math.abs(slabArea - totalFrameFloorArea) > 1.0) {
        result.validationSuccess = false;
        result.data.slabName = slab.name;
        result.data.location = LocationLabels[slab.interior_location];
        result.data.slabArea = slabArea;
        result.data.frameFloorArea = totalFrameFloorArea;
      }
    });
    return [result];
  }

  // If you explicitly define a slab the depth below should match a corresponding foundation wall
  @connectWithState(StateModelName.slab, [
    'interior_location',
    'depth_below_grade',
    'name',
  ])
  @connectWithState(StateModelName.foundationWall, [
    'interior_location',
    'exterior_location',
    'depth_below_grade',
    'name',
  ])
  static validateCrawlspaceDepthBelowGrade(simulation: DetailedSimulation) {
    const slabs = simulation.slabs_info || [];
    const foundationWalls = simulation.foundation_walls_info || [];
    const noFoundationWall: ValidationResult = {
      id: 'crawlspaceSlabNoFoundationWall',
      validationSuccess: true,
      data: {
        slabName: '',
        location: '',
      },
    };

    const invalidDepthBelowGrade = {
      id: 'checkCrawlspaceDepthBelowGrade',
      validationSuccess: true,
      data: {
        slabName: '',
        slabDepth: 0,
        foundationWallName: '',
        foundationWallDepth: 0,
      },
    };
    // Filter slabs that are in crawlspace locations
    const crawlSpaceSlabs = slabs.filter(slab =>
      isCrawlSpaceLocation(slab.interior_location)
    );

    crawlSpaceSlabs.forEach(slab => {
      // Find the corresponding foundation wall
      const foundationWall = foundationWalls.find(
        fw =>
          fw.interior_location === slab.interior_location ||
          fw.exterior_location?.toString() === slab.interior_location.toString()
      );

      // Add error if no foundation wall corresponds to the slab
      if (!foundationWall) {
        noFoundationWall.validationSuccess = false;
        noFoundationWall.data.slabName = slab.name;
        noFoundationWall.data.location = LocationLabels[slab.interior_location];
      }

      if (foundationWall) {
        const fwdDepthBelowGrade = foundationWall.depth_below_grade;
        const slabDepthBelowGrade = slab.depth_below_grade;
        if (Math.abs(slabDepthBelowGrade - fwdDepthBelowGrade) > 0.5) {
          invalidDepthBelowGrade.validationSuccess = false;
          invalidDepthBelowGrade.data.slabName = slab.name;
          invalidDepthBelowGrade.data.slabDepth = slabDepthBelowGrade;
          invalidDepthBelowGrade.data.foundationWallName = foundationWall.name;
          invalidDepthBelowGrade.data.foundationWallDepth = fwdDepthBelowGrade;
        }
      }
    });
    return [invalidDepthBelowGrade, noFoundationWall];
  }

  // Verify the exposed perimeter aligns with the associated foundation walls
  @connectWithState(StateModelName.slab, [
    'interior_location',
    'exposed_perimeter',
    'name',
  ])
  @connectWithState(StateModelName.foundationWall, [
    'interior_location',
    'exterior_location',
    'perimeter_length',
    'name',
  ])
  static validateCrawlSpacePerimeter(simulation: DetailedSimulation) {
    const slabs = simulation.slabs_info || [];
    const foundationWalls = simulation.foundation_walls_info || [];

    const result = {
      id: 'checkCrawlSpacePerimeter',
      validationSuccess: true,
      data: {
        slabName: '',
        slabPerimeter: 0,
        foundationWallName: '',
        foundationWallPerimeter: 0,
      },
    };
    // Filter slabs that are in crawlspace locations
    const crawlSpaceSlabs = slabs.filter(slab =>
      isCrawlSpaceLocation(slab.interior_location)
    );

    crawlSpaceSlabs.forEach(slab => {
      // Find the corresponding foundation wall
      const foundationWall = foundationWalls.find(
        fw =>
          fw.interior_location === slab.interior_location ||
          fw.exterior_location?.toString() === slab.interior_location.toString()
      );
      if (foundationWall) {
        const fwdPerimeter = foundationWall.perimeter_length;
        const slabPerimeter = slab.exposed_perimeter;
        if (Math.abs(slabPerimeter - fwdPerimeter) > 0.5) {
          result.validationSuccess = false;
          result.data.slabName = slab.name;
          result.data.slabPerimeter = slabPerimeter;
          result.data.foundationWallName = foundationWall.name;
          result.data.foundationWallPerimeter = fwdPerimeter;
        }
      }
    });
    return [result];
  }

  @connectWithState(StateModelName.mechanicalEquipment, ['water_heater'])
  static validateWaterHeater(simulation: DetailedSimulation) {
    const mechanicalEquipment = simulation.mechanical_equipment_info || [];

    const result = {
      id: 'requiredWaterHeater',
      validationSuccess: true,
      data: {},
    };

    const waterHeater = mechanicalEquipment.filter(
      equipment => equipment.water_heater !== null
    );

    if (waterHeater.length === 0) {
      result.validationSuccess = false;
    }
    return [result];
  }

  @connectWithState(StateModelName.slab, ['interior_location'])
  @connectWithState(StateModelName.aboveGradeWall, ['interior_location'])
  @connectWithState(StateModelName.roof, ['interior_location'])
  static validateGarageMissingSlabs(simulation: DetailedSimulation) {
    const result = {
      id: 'missingGarageSlabs',
      validationSuccess: true,
      data: {
        locations: '',
      },
    };
    const garageSlabs = filterByGarageLocation(simulation.slabs_info);
    if (hasExplicitlyDefinedGarage(simulation) && garageSlabs.length === 0) {
      result.validationSuccess = false;
      result.data.locations = getExplicitlyDefinedGarageLocations(simulation);
    }
    return [result];
  }

  @connectWithState(StateModelName.slab, ['interior_location'])
  @connectWithState(StateModelName.aboveGradeWall, ['interior_location'])
  @connectWithState(StateModelName.roof, ['interior_location'])
  static validateGarageMissingAboveGradeWalls(simulation: DetailedSimulation) {
    const result = {
      id: 'missingGarageAboveGradeWalls',
      validationSuccess: true,
      data: {
        locations: '',
      },
    };
    const garageAboveGradeWalls = filterByGarageLocation(
      simulation.above_grade_walls_info
    );
    if (
      hasExplicitlyDefinedGarage(simulation) &&
      garageAboveGradeWalls.length === 0
    ) {
      result.validationSuccess = false;
      result.data.locations = getExplicitlyDefinedGarageLocations(simulation);
    }
    return [result];
  }

  @connectWithState(StateModelName.slab, ['interior_location'])
  @connectWithState(StateModelName.aboveGradeWall, ['interior_location'])
  @connectWithState(StateModelName.roof, ['interior_location'])
  @connectWithState(StateModelName.frameFloor, [
    'exterior_location',
    'interior_location',
  ])
  static validateGarageMissingRoofs(simulation: DetailedSimulation) {
    const result = {
      id: 'missingGarageRoofs',
      validationSuccess: true,
      data: {
        locations: '',
      },
    };
    const garageRoofs = filterByGarageLocation(simulation.roofs_info);
    const garageFloors = (simulation.frame_floors_info || []).filter(
      fw =>
        fw.exterior_location?.toString() === Location.GARAGE ||
        fw.interior_location?.toString() === Location.GARAGE
    );

    if (
      hasExplicitlyDefinedGarage(simulation) &&
      garageRoofs.length + garageFloors.length === 0
    ) {
      result.validationSuccess = false;
      result.data.locations = getExplicitlyDefinedGarageLocations(simulation);
    }
    return [result];
  }

  // This ensures that the area for the floor matches the ceiling / roof area
  @connectWithState(StateModelName.slab, ['interior_location', 'area'])
  @connectWithState(StateModelName.roof, ['interior_location', 'ceiling_area'])
  @connectWithState(StateModelName.aboveGradeWall, ['interior_location'])
  @connectWithState(StateModelName.frameFloor, [
    'exterior_location',
    'interior_location',
    'area',
  ])
  static validateGarageSlabFloorCeilingArea(simulation: DetailedSimulation) {
    const garageSlabArea = {
      id: 'garageSlabAreaLargeThanCeilingArea',
      validationSuccess: true,
      data: {
        slabArea: 0,
        ceilingArea: 0,
      },
    };
    const garageCeilingArea = {
      id: 'garageCeilingAreaLargeThanSlabArea',
      validationSuccess: true,
      data: {
        slabArea: 0,
        ceilingArea: 0,
      },
    };

    if (!hasExplicitlyDefinedGarage(simulation)) {
      return [];
    }

    const missingSlabs = this.validateGarageMissingSlabs(simulation);
    const missingRoofs = this.validateGarageMissingRoofs(simulation);

    if (
      !missingSlabs[0].validationSuccess ||
      !missingRoofs[0].validationSuccess
    ) {
      // Return if there are missing slabs or roofs since they are prerequisites
      return [];
    }

    // Calculate slab area
    const slabArea = (simulation.slabs_info || [])
      .filter(slab => slab.interior_location?.toString() === Location.GARAGE)
      .reduce((total, slab) => total + slab.area, 0);

    // Calculate ceiling area (roof + frame floors)
    const roofArea = (simulation.roofs_info || [])
      .filter(roof => roof.interior_location?.toString() === Location.GARAGE)
      .reduce((total, roof) => total + roof.ceiling_area, 0);

    const floorArea = (simulation.frame_floors_info || [])
      .filter(
        fw =>
          fw.exterior_location?.toString() === Location.GARAGE ||
          fw.interior_location?.toString() === Location.GARAGE
      )
      .reduce((total, floor) => total + floor.area, 0);

    const ceilingArea = roofArea + floorArea;

    // Allowable tolerance
    const tolerance = 1.0;
    if (Math.abs(slabArea - ceilingArea) > tolerance) {
      if (slabArea < ceilingArea) {
        garageCeilingArea.validationSuccess = false;
        garageCeilingArea.data.slabArea = slabArea;
        garageCeilingArea.data.ceilingArea = ceilingArea;
      } else {
        garageSlabArea.validationSuccess = false;
        garageSlabArea.data.slabArea = slabArea;
        garageSlabArea.data.ceilingArea = ceilingArea;
      }
    }
    return [garageSlabArea, garageCeilingArea];
  }

  // This will verify that the wall area makes sense for the garage floor
  @connectWithState(StateModelName.slab, ['interior_location', 'area'])
  @connectWithState(StateModelName.roof, ['interior_location'])
  @connectWithState(StateModelName.aboveGradeWall, [
    'interior_location',
    'exterior_location',
    'area',
  ])
  @connectWithState(StateModelName.foundationWall, [
    'exterior_location',
    'perimeter_length',
    'height_above_grade',
  ])
  @connectWithState(StateModelName.rimJoist, [
    'exterior_location',
    'interior_location',
    'area',
  ])
  static validateGarageWallArea(simulation: DetailedSimulation) {
    const lessWallArea = {
      id: 'accountedWallAreaLessThanMinArea',
      validationSuccess: true,
      data: {
        accountedWallArea: 0,
        missing: 0,
        maxArea: 0,
      },
    };

    const moreWallArea = {
      id: 'accountedWallAreaMoreThanMaxArea',
      validationSuccess: true,
      data: {
        accountedWallArea: 0,
        overMax: 0,
        overMin: 0,
      },
    };

    if (!hasExplicitlyDefinedGarage(simulation)) {
      return [];
    }

    const missingSlabs = this.validateGarageMissingSlabs(simulation);
    const missingRoofs = this.validateGarageMissingRoofs(simulation);
    const missingSlabFloorCeilingArea =
      this.validateGarageSlabFloorCeilingArea(simulation);

    // Return early if any of the previous validations failed
    if (
      !missingSlabs[0].validationSuccess ||
      !missingRoofs[0].validationSuccess ||
      !missingSlabFloorCeilingArea[0].validationSuccess
    ) {
      return [];
    }

    // Slab area calculation (assume already in square feet)
    const slabArea = (simulation.slabs_info || [])
      .filter(slab => slab.interior_location?.toString() === Location.GARAGE)
      .reduce((total, slab) => total + slab.area, 0);

    // Foundation wall area calculation
    const foundationWalls = simulation.foundation_walls_info || [];
    const garagefoundationWalls = foundationWalls.filter(
      fw => fw.exterior_location?.toString() === Location.GARAGE
    );
    const foundationPerimeter = garagefoundationWalls.reduce(
      (total, fw) => total + fw.perimeter_length,
      0
    );
    const foundationHeight = garagefoundationWalls.reduce(
      (total, fw) => total + fw.height_above_grade,
      0
    );
    const foundationArea = foundationPerimeter * foundationHeight;

    // Joist area calculation
    const joists = (simulation.rim_joists_info || []).filter(
      fw =>
        fw.exterior_location?.toString() === Location.GARAGE ||
        fw.interior_location?.toString() === Location.GARAGE
    );
    const joistArea = joists.reduce((total, joist) => total + joist.area, 0);

    // Above-grade wall area calculation
    const aboveGradeWalls = (simulation.above_grade_walls_info || []).filter(
      fw =>
        fw.exterior_location?.toString() === Location.GARAGE ||
        fw.interior_location?.toString() === Location.GARAGE
    );
    const agArea = aboveGradeWalls.reduce(
      (total, wall) => total + wall.area,
      0
    );

    // Total accounted wall area
    const accountedWallArea = foundationArea + joistArea + agArea;

    // Minimum and maximum allowed area based on slab area
    const minArea = Math.sqrt(slabArea) * 4 * 7.0;
    const maxArea = Math.sqrt(slabArea) * 4 * 20.0;

    // Checking for minimum wall area
    if (accountedWallArea < minArea) {
      const missing = minArea - accountedWallArea;
      lessWallArea.validationSuccess = false;
      lessWallArea.data.accountedWallArea = accountedWallArea;
      lessWallArea.data.missing = missing;
      lessWallArea.data.maxArea = maxArea - 1;
    }

    // Checking for maximum wall area
    if (accountedWallArea > maxArea) {
      const overMax = accountedWallArea - maxArea - 1;
      const overMin = minArea - accountedWallArea + 1;
      moreWallArea.validationSuccess = false;
      moreWallArea.data.accountedWallArea = accountedWallArea;
      moreWallArea.data.overMax = overMax;
      moreWallArea.data.overMin = overMin;
    }
    return [lessWallArea, moreWallArea];
  }

  @connectWithState(StateModelName.mechanicalEquipment, ['water_heater'])
  static validateMultipleWaterHeaters(simulation: DetailedSimulation) {
    const mechanicalEquipment = simulation.mechanical_equipment_info || [];

    const waterHeaters = mechanicalEquipment.filter(
      equipment => equipment.water_heater !== null
    );

    const result = {
      id: 'multipleWaterHeaters',
      validationSuccess: true,
      data: {
        count: waterHeaters.length,
      },
    };

    if (waterHeaters.length > 1) {
      result.validationSuccess = false;
    }
    return [result];
  }

  @connectWithState(StateModelName.mechanicalEquipment, ['heater'])
  @connectWithState(StateModelName.heater, ['system_type', 'name'])
  @connectWithState(StateModelName.distributionSystem, ['heating_system'])
  static validateHeatingDistributionSystem(simulation: DetailedSimulation) {
    const result = {
      id: 'invalidHeatingDistributionSystem',
      validationSuccess: true,
      data: {
        heater: '',
        count: 0,
      },
    };

    // Get all heaters mechanical equipment
    const heaters = (simulation.mechanical_equipment_info || []).filter(
      equipment => equipment.heater_info
    );

    // Filter only heaters with system type as furnace
    const furnaceHeatingSystem = heaters.filter(
      equipment =>
        equipment.heater_info.system_type === HeatingSystemType.FURNACE
    );

    // Furnace Heating System should have only attached to one distribution system
    (furnaceHeatingSystem || []).forEach(furnace => {
      const hvacDistribution = simulation.hvac_distribution_systems_info.filter(
        distribution => distribution.heating_system === furnace.id
      );
      if (hvacDistribution.length > 1) {
        result.validationSuccess = false;
        result.data.heater = furnace.name;
        result.data.count = hvacDistribution.length;
      }
    });
    return [result];
  }

  @connectWithState(StateModelName.mechanicalEquipment, ['heater'])
  @connectWithState(StateModelName.distributionSystem, [
    'heating_system',
    'system_type',
    'name',
    'ducts',
  ])
  static validateDuctHeatingSystem(simulation: DetailedSimulation) {
    const result = {
      id: 'invalidDuctHeatingSystem',
      validationSuccess: true,
      data: {
        distributionName: '',
      },
    };

    const heaterIds = (simulation.mechanical_equipment_info || [])
      .filter(equipment => equipment.heater !== null)
      .map(equipment => equipment.id);
    const distributionSystem = simulation.hvac_distribution_systems_info;

    (distributionSystem || []).forEach(distribution => {
      if (
        distribution.system_type !== DistributionSystemType.DUCTLESS &&
        (distribution.ducts || []).length === 0 &&
        heaterIds.includes(distribution.heating_system)
      ) {
        result.validationSuccess = false;
        result.data.distributionName = distribution.name;
      }
    });
    return [result];
  }

  @connectWithState(StateModelName.mechanicalEquipment, [
    'air_conditioner',
    'name',
  ])
  @connectWithState(StateModelName.airConditioner, ['system_type'])
  @connectWithState(StateModelName.distributionSystem, ['cooling_system'])
  static validateCoolingDistributionSystem(simulation: DetailedSimulation) {
    const result = {
      id: 'invalidCoolingDistributionSystem',
      validationSuccess: true,
      data: {
        coolingSystemName: '',
      },
    };

    // Get all air conditioners mechanical equipments
    const coolingEquipments = (
      simulation.mechanical_equipment_info || []
    ).filter(
      equipment =>
        equipment.air_conditioner !== null &&
        equipment.air_conditioner_info.system_type ===
          CoolingSystemType.AIR_CONDITIONER
    );

    const distributionSystems = simulation.hvac_distribution_systems_info;

    (coolingEquipments || []).forEach(cooling => {
      const relatedDistributions = distributionSystems.filter(
        distribution => distribution.cooling_system === cooling.id
      );

      let hasError = true;

      // Check if any related distribution system is valid (not ductless)
      if (
        relatedDistributions.some(
          dist => dist.system_type !== DistributionSystemType.DUCTLESS
        )
      ) {
        hasError = false;
      }

      if (hasError) {
        result.validationSuccess = false;
        result.data.coolingSystemName = cooling.name;
      }
    });
    return [result];
  }

  static validateMechanicalEfficiencyAndCapacity(
    simulation: DetailedSimulation,
    type: 'air_source' | 'ground_source'
  ) {
    const efficiency = {
      id: 'invalidMechanicalEfficiency',
      validationSuccess: true,
      data: {
        type: '',
        percentType: '',
        equipmentName: '',
      },
    };
    const capacity = {
      id: 'invalidMechanicalCapacity',
      validationSuccess: true,
      data: {
        type: '',
        percentType: '',
        equipmentName: '',
      },
    };

    // Determine the filter key, info key, and capacity field based on type
    const filterKey =
      type === 'air_source'
        ? 'air_source_heat_pump'
        : 'ground_source_heat_pump';
    const infoKey =
      type === 'air_source'
        ? 'air_source_heat_pump_info'
        : 'ground_source_heat_pump_info';
    const heatingCapacityField =
      type === 'air_source' ? 'heating_capacity_47f' : 'heating_capacity';

    // Filter the relevant equipment
    const mechanicalEquipments = (
      simulation.mechanical_equipment_info || []
    ).filter(equipment => equipment[filterKey] !== null);

    // Perform validation
    mechanicalEquipments.forEach(equipment => {
      const info = equipment[infoKey];

      if (equipment.heating_percent_served > 0) {
        if (info[heatingCapacityField] === 0) {
          capacity.validationSuccess = false;
          capacity.data.type = 'Heating';
          capacity.data.percentType = 'Heating Percent Served';
          capacity.data.equipmentName = equipment.name;
        }
        if (info.heating_efficiency === 0) {
          efficiency.validationSuccess = false;
          efficiency.data.type = 'Heating';
          efficiency.data.percentType = 'Heating Percent Served';
          efficiency.data.equipmentName = equipment.name;
        }
      }

      if (equipment.cooling_percent_served > 0) {
        if (info.cooling_capacity === 0) {
          capacity.validationSuccess = false;
          capacity.data.type = 'Cooling';
          capacity.data.percentType = 'Cooling Percent Served';
          capacity.data.equipmentName = equipment.name;
        }
        if (info.cooling_efficiency === 0) {
          efficiency.validationSuccess = false;
          efficiency.data.type = 'Cooling';
          efficiency.data.percentType = 'Cooling Percent Served';
          efficiency.data.equipmentName = equipment.name;
        }
      }
    });

    return [efficiency, capacity];
  }

  // Wrapper functions for specific heat pump types
  @connectWithState(StateModelName.mechanicalEquipment, [
    'air_source_heat_pump',
    'name',
    'heating_percent_served',
    'cooling_percent_served',
  ])
  @connectWithState(StateModelName.airSourceHeatPump, [
    'heating_capacity_47f',
    'cooling_capacity',
    'heating_efficiency',
    'cooling_efficiency',
  ])
  static validateMechanicalEfficiencyAndCapacityAirSource(
    simulation: DetailedSimulation
  ) {
    return this.validateMechanicalEfficiencyAndCapacity(
      simulation,
      'air_source'
    );
  }

  @connectWithState(StateModelName.mechanicalEquipment, [
    'ground_source_heat_pump',
    'name',
    'heating_percent_served',
    'cooling_percent_served',
  ])
  @connectWithState(StateModelName.groundSourceHeatPump, [
    'heating_capacity',
    'cooling_capacity',
    'heating_efficiency',
    'cooling_efficiency',
  ])
  static validateMechanicalEfficiencyAndCapacityGroundSource(
    simulation: DetailedSimulation
  ) {
    return this.validateMechanicalEfficiencyAndCapacity(
      simulation,
      'ground_source'
    );
  }

  @connectWithState(StateModelName.mechanicalEquipment, [
    'air_source_heat_pump',
    'name',
  ])
  @connectWithState(StateModelName.distributionSystem, [
    'cooling_system',
    'heating_system',
    'system_type',
    'ducts',
  ])
  @connectWithState(StateModelName.airSourceHeatPump, ['motor_type'])
  static validateMotorTypeVariableSpeed(simulation: DetailedSimulation) {
    const result = {
      id: 'invalidMotorType',
      validationSuccess: true,
      data: {
        equipmentName: '',
      },
    };

    const airSourceHeatPumpsEquipment = (
      simulation.mechanical_equipment_info || []
    ).filter(equipment => equipment.air_source_heat_pump !== null);

    const distributionSystems = simulation.hvac_distribution_systems_info || [];

    airSourceHeatPumpsEquipment.forEach(equipment => {
      const heatPump = equipment.air_source_heat_pump_info;
      const relatedDistributions = distributionSystems.filter(
        distribution =>
          distribution.heating_system === equipment.id ||
          distribution.cooling_system === equipment.id
      );

      // Check if any related distribution system has ducts or an invalid type
      const hasDucts = relatedDistributions.some(
        distribution => (distribution.ducts || []).length > 0
      );
      const hasInvalidDistributionSystems = relatedDistributions.some(
        distribution =>
          [
            DistributionSystemType.DSE,
            DistributionSystemType.FORCED_AIR,
          ].includes(distribution.system_type)
      );

      if (hasDucts || hasInvalidDistributionSystems) {
        return; // Skip further validation for this equipment
      }

      if (
        heatPump.motor_type &&
        heatPump.motor_type !== MechanicalMotorType.VARIABLE
      ) {
        result.validationSuccess = false;
        result.data.equipmentName = equipment.name;
      }
    });
    return [result];
  }

  // A conditioned room must have either a cooling or heating system assigned to it
  @connectWithState(StateModelName.mechanicalEquipment, [
    'air_conditioner',
    'heater',
    'air_source_heat_pump',
    'ground_source_heat_pump',
  ])
  static validateSystemForConditionedSpace(simulation: DetailedSimulation) {
    const result = {
      id: 'invalidSystemForConditionedSpace',
      validationSuccess: true,
      data: {},
    };

    const mechanicalEquipments = simulation.mechanical_equipment_info || [];
    let conditioned = false;
    mechanicalEquipments.forEach(equipment => {
      if (
        equipment.heater ||
        equipment.air_conditioner ||
        equipment.air_source_heat_pump ||
        equipment.ground_source_heat_pump
      ) {
        conditioned = true;
      }
    });

    if (simulation.conditioned_area > 0 && !conditioned) {
      result.validationSuccess = false;
    }
    return [result];
  }

  // Gross wall area must be within limits as per following calculation: 27 <= (EGWA / (CFA*NCS)^0.5) <= 105
  @connectWithState(StateModelName.aboveGradeWall, ['area'])
  static validateGrossWallArea(simulation: DetailedSimulation) {
    const result = {
      id: 'invalidGrossWallArea',
      validationSuccess: true,
      data: {
        grossWallArea: 0,
      },
    };

    const aboveGradeWalls = simulation.above_grade_walls_info || [];

    let grossWallArea = 0;
    aboveGradeWalls.forEach(wall => {
      grossWallArea += wall.area;
    });

    const checkValue = grossWallArea / Math.sqrt(simulation.conditioned_area);

    // Only flag this if we have something we have other checks to make sure
    // We have something.
    if (grossWallArea > 0 && (checkValue < 27 || checkValue > 105)) {
      result.validationSuccess = false;
      result.data.grossWallArea = grossWallArea;
    }
    return [result];
  }

  @connectWithState(StateModelName.frameFloor, [
    'area',
    'represents_ceiling',
    'exterior_location',
  ])
  static validateExposedFloorArea(simulation: DetailedSimulation) {
    const expoArea: ValidationResult = {
      id: 'invalidExposedFloorArea',
      validationSuccess: true,
      data: {
        exposedFloorArea: 0,
      },
    };

    const atticArea: ValidationResult = {
      id: 'invalidAtticFloorArea',
      validationSuccess: true,
      data: {
        exposedFloorArea: 0,
      },
    };

    const frameFloors = simulation.frame_floors_info || [];

    // Calculate exposed floor area (excluding ceilings)
    const exposedFloorArea = frameFloors
      .filter(floor => !floor.represents_ceiling)
      .reduce((sum, floor) => sum + floor.area, 0);

    // Calculate attic floor area (SEALED_ATTIC location only)
    const atticFloorArea = frameFloors
      .filter(
        floor => floor.exterior_location === ExteriorLocation.SEALED_ATTIC
      )
      .reduce((sum, floor) => sum + floor.area, 0);

    // Update validation result based on conditions
    if (exposedFloorArea && exposedFloorArea > simulation.conditioned_area) {
      expoArea.validationSuccess = false;
      expoArea.data.exposedFloorArea = exposedFloorArea;
    }

    if (
      atticFloorArea &&
      exposedFloorArea &&
      atticFloorArea > exposedFloorArea
    ) {
      atticArea.validationSuccess = false;
      atticArea.data.exposedFloorArea = exposedFloorArea;
    }
    return [expoArea, atticArea];
  }

  static validateCeilingCount(simulation: DetailedSimulation) {
    const result = {
      id: 'invalidCeilingCount',
      validationSuccess: true,
      data: {},
    };

    const isMultiFamily =
      simulation.residence_type !== SimulationResidenceType.singleDetached;
    const roofs = simulation.roofs_info || [];

    // SK - This is a duplicate check see shouldContainEitherRoofOrFrameFloorAsCeiling
    // if (!isMultiFamily && roofs.length < 1) {
    //   result.validationSuccess = false;
    // }

    return [result];
  }

  @connectWithState(StateModelName.roof, [
    'attic_within_infiltration_volume',
    'interior_location',
  ])
  static validateSealedAtticInfiltationVolume(simulation: DetailedSimulation) {
    const result = {
      id: 'sealedAtticWithinInfiltrationVolume',
      validationSuccess: true,
      data: {},
    };

    const sealedAttics = (simulation.roofs_info || []).filter(
      roof => roof.interior_location === InteriorLocation.SEALED_ATTIC
    );

    const atticWithinInfiltrationVolume = new Set(
      sealedAttics.map(attic => attic.attic_within_infiltration_volume)
    );

    if (sealedAttics.length > 0 && atticWithinInfiltrationVolume.size !== 1) {
      result.validationSuccess = false;
    }

    return [result];
  }

  @connectWithState(StateModelName.skylight, ['area'])
  static validateSyslightAreaForHes(simulation: DetailedSimulation) {
    const result = {
      id: 'invalidSkylightArea',
      validationSuccess: true,
      data: {
        totalSkylightArea: 0,
        minArea: 0,
        maxArea: 300,
      },
    };

    const skylights = simulation.skylights_info || [];
    const totalSkylightArea = skylights.reduce(
      (sum, skylight) => sum + skylight.area,
      0
    );

    result.data.totalSkylightArea = totalSkylightArea;

    if (
      totalSkylightArea < result.data.minArea ||
      totalSkylightArea > result.data.maxArea
    ) {
      result.validationSuccess = false;
    }

    return [result];
  }

  @connectWithState(StateModelName.simulationConfig, [
    'analysis_types',
    'suite',
  ])
  static validateZerhCalculationChoice(simulation: DetailedSimulation) {
    const result = {
      id: 'invalidZerhCalculationChoice',
      validationSuccess: true,
      data: {
        residence: '',
        type: '',
      },
    };
    const analysisTypes = simulation.config_info.analysis_types || [];

    // Filter only analysis related to ZERH calculations
    const zerhAnalysis = analysisTypes.filter(
      analysis =>
        analysis.suite_info?.calculation_type ===
        OpenStudioAnalysisSourceName.ZERH_CALCULATION
    );

    const zerhSingleFamilyChoice = ['doe_zerh_2p0', 'doe_zerh_1p0'];

    zerhAnalysis.forEach(analysis => {
      let isInvalid = false;
      if (simulation.residence_type == SimulationResidenceType.singleDetached) {
        isInvalid = !zerhSingleFamilyChoice.includes(analysis.suite_info?.slug);
        result.data.residence =
          SimulationResidenceTypeLabelMapping[simulation.residence_type];
        result.data.type = 'single-family';
      } else {
        isInvalid = zerhSingleFamilyChoice.includes(analysis.suite_info?.slug);
        result.data.residence =
          SimulationResidenceTypeLabelMapping[simulation.residence_type];
        result.data.type = 'multi-family';
      }

      if (isInvalid) {
        result.validationSuccess = false;
      }
    });
    return [result];
  }

  @connectWithState(StateModelName.simulationConfig, [
    'analysis_types',
    'suite',
  ])
  static validateEnergyStarCalculationChoice(simulation: DetailedSimulation) {
    const result = {
      id: 'invalidEnergyStarCalculationChoice',
      validationSuccess: true,
      data: {
        residence: '',
        type: '',
      },
    };
    const analysisTypes = simulation.config_info.analysis_types || [];

    // Filter only analysis related to Energy Star calculations
    const energyStarAnalysis = analysisTypes.filter(
      analysis =>
        analysis.suite_info?.calculation_type ===
        OpenStudioAnalysisSourceName.ESTAR_CALCULATION
    );

    const energyStarSingleFamilyChoice = [
      'estar_v3',
      'estar_v3_pac',
      'estar_v3p1_fl',
      'estar_v3p1',
      'estar_v3p2',
      'estar_v3p2_orwa',
    ];

    energyStarAnalysis.forEach(analysis => {
      let isInvalid = false;
      if (simulation.residence_type == SimulationResidenceType.singleDetached) {
        isInvalid = !energyStarSingleFamilyChoice.includes(
          analysis.suite_info?.slug
        );
        result.data.residence =
          SimulationResidenceTypeLabelMapping[simulation.residence_type];
        result.data.type = 'single-family';
      } else {
        isInvalid = energyStarSingleFamilyChoice.includes(
          analysis.suite_info?.slug
        );
        result.data.residence =
          SimulationResidenceTypeLabelMapping[simulation.residence_type];
        result.data.type = 'multi-family';
      }

      if (isInvalid) {
        result.validationSuccess = false;
      }
    });
    return [result];
  }

  static validateForm(
    simulation: SimulationBackendDict
  ): ModelErrors<Simulation> {
    const simulationForm = createSimulationForm(simulation);
    return simulationForm.getFieldAndModelErros();
  }

  static validate(simulations: DetailedSimulation[]): StateErrors<Simulation> {
    const errors = {};
    simulations.forEach((simulation: DetailedSimulation) => {
      const validationErrors = new ValidationErrors<Simulation>(
        this.validateForm(simulation)
      );

      validationErrors.addValidationResults(
        this.validateUnconditionedFoundationsHaveFrameFloors(simulation)
      );
      validationErrors.addValidationResults(
        this.validateFrameFloorsAttachedToFoundationWalls(simulation)
      );
      validationErrors.addValidationResults(
        this.validateWasherConsumption(simulation)
      );
      validationErrors.addValidationResults(
        this.validateWindowToFloorAreaRatio(simulation)
      );
      validationErrors.addValidationResults(this.validateHvacCount(simulation));
      validationErrors.addValidationResults(
        this.validateFloorsAreaAgainstConditionedArea(simulation)
      );
      validationErrors.addValidationResults(
        this.validateFenestrationAreaAgainstAboveGradeWall(simulation)
      );
      validationErrors.addValidationResults(
        this.validateFenestrationAreaAgainstFoundationWall(simulation)
      );
      validationErrors.addValidationResults(
        this.validateUtilityRateForRequiredFuels(simulation)
      );
      validationErrors.addValidationResults(
        this.validateMechanicalEquipmentPercentageServed(simulation)
      );
      validationErrors.addValidationResults(
        this.validateWaterHeaterPercentageServed(simulation)
      );
      validationErrors.addValidationResults(
        this.validateDuctSystem(simulation)
      );
      validationErrors.addValidationResults(
        this.validateDistributionSurfaceArea(simulation)
      );
      validationErrors.addValidationResults(
        this.validateContainsCeling(simulation)
      );
      validationErrors.addValidationResults(
        this.validateWaterHeater(simulation)
      );
      validationErrors.addValidationResults(
        this.validateCrawlspaceDepthBelowGrade(simulation)
      );
      validationErrors.addValidationResults(
        this.validateCrawlSpacePerimeter(simulation)
      );
      validationErrors.addValidationResults(
        this.validateCrawlspaceSlabArea(simulation)
      );
      validationErrors.addValidationResults(
        this.validateGarageMissingSlabs(simulation)
      );
      validationErrors.addValidationResults(
        this.validateGarageMissingAboveGradeWalls(simulation)
      );
      validationErrors.addValidationResults(
        this.validateGarageMissingRoofs(simulation)
      );
      validationErrors.addValidationResults(
        this.validateGarageSlabFloorCeilingArea(simulation)
      );
      validationErrors.addValidationResults(
        this.validateGarageWallArea(simulation)
      );
      validationErrors.addValidationResults(
        this.validateMultipleWaterHeaters(simulation)
      );
      validationErrors.addValidationResults(
        this.validateHeatingDistributionSystem(simulation)
      );
      validationErrors.addValidationResults(
        this.validateDuctHeatingSystem(simulation)
      );
      validationErrors.addValidationResults(
        this.validateCoolingDistributionSystem(simulation)
      );
      validationErrors.addValidationResults(
        this.validateMechanicalEfficiencyAndCapacityAirSource(simulation)
      );
      validationErrors.addValidationResults(
        this.validateMechanicalEfficiencyAndCapacityGroundSource(simulation)
      );
      validationErrors.addValidationResults(
        this.validateMotorTypeVariableSpeed(simulation)
      );
      validationErrors.addValidationResults(
        this.validateSystemForConditionedSpace(simulation)
      );
      validationErrors.addValidationResults(
        this.validateGrossWallArea(simulation)
      );
      validationErrors.addValidationResults(
        this.validateCeilingCount(simulation)
      );
      validationErrors.addValidationResults(
        this.validateSealedAtticInfiltationVolume(simulation)
      );
      validationErrors.addValidationResults(
        this.validateSyslightAreaForHes(simulation)
      );
      validationErrors.addValidationResults(
        this.validateZerhCalculationChoice(simulation)
      );
      validationErrors.addValidationResults(
        this.validateEnergyStarCalculationChoice(simulation)
      );
      errors[simulation.id] = validationErrors.errors;
    });
    return errors;
  }
}
