import {
  BuildingStatusLabels,
  createProjectForm,
  FIELD_LABELS,
  ProjectBackendDict,
  RatingTypeLabels,
} from '@/data/simulation/models/Project';
import { selectProjectViewSet } from '@/modules/simulation/state/project/selectors';
import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CompanySelectDialogComponent } from '../../company-select-dialog/company-select-dialog.component';
import { updateProject } from '@/modules/simulation/state/project/actions';
import { UserSelectDialogComponent } from '../../user-select-dialog/user-select-dialog.component';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
})
export class ProjectComponent {
  @Input() entityId: number;
  fieldLabels = FIELD_LABELS;

  componentDestroyed$ = new Subject();
  entity: ProjectBackendDict;
  form: FormGroup;
  loading = false;
  builderName: string;
  raterOrgnizationName: string;
  raterOfRecordName: string;
  providerOrganizationName: string;
  buildingStatusLabels = BuildingStatusLabels;
  ratingTypeLabels = RatingTypeLabels;
  yearChoices = {};

  constructor(public dialog: MatDialog, public store: Store) {
    this.yearChoices = {};
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 10;
    Array.from(
      { length: currentYear - startYear + 1 },
      (_, i) => currentYear - i
    ).forEach(year => {
      this.yearChoices[`${year}`] = `${year}`;
    });
  }

  updateNames() {
    this.builderName = null;
    this.raterOrgnizationName = null;
    this.raterOfRecordName = null;
    this.providerOrganizationName = null;

    if (this.entity.builder && this.entity.builder_info) {
      this.builderName = this.entity.builder_info.name;
    }
    if (this.entity.rater_organization && this.entity.rater_organization_info) {
      this.raterOrgnizationName = this.entity.rater_organization_info.name;
    }

    if (this.entity.rater_of_record) {
      this.raterOfRecordName =
        this.entity.rater_of_record_info.first_name +
        ' ' +
        this.entity.rater_of_record_info.last_name;
    }

    if (
      this.entity.provider_organization &&
      this.entity.provider_organization_info
    ) {
      this.providerOrganizationName =
        this.entity.provider_organization_info.name;
    }
  }

  ngOnInit(): void {
    this.store
      .select(selectProjectViewSet(this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(({ data, loading }) => {
        this.loading = loading;
        this.entity = { ...data };

        if (loading) return;

        if (!this.form) {
          this.form = createProjectForm(data);
        } else {
          this.form.patchValue(data);
        }

        this.updateNames();
      });
  }

  editCompany(controllerName: string, companyType: string) {
    const dialogRef = this.dialog.open(CompanySelectDialogComponent, {
      disableClose: true,
      width: '600px',
      data: {
        companyType: companyType,
        initialValueId: this.entity[controllerName],
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) return;

      this.store.dispatch(
        updateProject({
          projectChanges: {
            id: this.entityId,
            [controllerName]: result.id,
          },
        })
      );
    });
  }

  removeCompany(controllerName: string) {
    this.store.dispatch(
      updateProject({
        projectChanges: {
          id: this.entityId,
          [controllerName]: null,
        },
      })
    );
  }

  removeRaterOrgnisation() {
    this.store.dispatch(
      updateProject({
        projectChanges: {
          id: this.entityId,
          rater_organization: null,
          rater_of_record: null,
        },
      })
    );
  }

  replaceRaterUser(controllerName: string) {
    const dialogRef = this.dialog.open(UserSelectDialogComponent, {
      disableClose: true,
      width: '600px',
      data: {
        initialValueId: this.entity[controllerName] || null,
        companyId: this.entity.rater_organization,
        label: 'Rater of Record',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) return;

      this.store.dispatch(
        updateProject({
          projectChanges: {
            id: this.entityId,
            [controllerName]: result.id,
          },
        })
      );
    });
  }

  removeRaterUser(controllerName: string) {
    this.store.dispatch(
      updateProject({
        projectChanges: {
          id: this.entityId,
          [controllerName]: null,
        },
      })
    );
  }

  onSave(projectChanges): void {
    this.store.dispatch(updateProject({ projectChanges }));
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
