<ng-container *ngIf="initialized">
    <div class="back-button">
        <button mat-icon-button [routerLink]="['/', 'simulations']"> <mat-icon>arrow_back</mat-icon></button>
    </div>
    <div class="main-container">
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
            <mat-tab label="Report">
                <app-analysis-type-config [configId]="configId" [indicateDefaults]="false"></app-analysis-type-config>
            </mat-tab>

            <mat-tab label="Library">
                <app-company-library-elements></app-company-library-elements>
            </mat-tab>

            <mat-tab label="Permission"><i class="text-center">Under construction</i></mat-tab>
        </mat-tab-group>
    </div>
</ng-container>