<div class="container" [hidden]="!initialized">
  <div class="row">
    <div class="col-12">
      <div class="d-flex">
        <div class="mb-0 align-self-end">
          <button mat-raised-button color="accent" appBackButton [fallbackUrl]="'/home/apex/request/utility-list'">
            <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
          </button>
        </div>
        <img
          class="ml-2"
          src="assets/apex_logo.svg"
          width="220"
        />
        <h3 class="ml-2 mb-0 align-self-end h3" style="line-height: 1;">
          Simulation
        </h3>
      </div>
    </div>
  </div>
  <div class="mt-3">
    <div class="row">
      <div class="col-12">
        <app-analysis-type-selector
          [weatherTypeControl]="formGroup.controls.weather_type"
          [analysisSuitesControl]="formGroup.controls.suites"
          (onReady)="onViewReady()">
        </app-analysis-type-selector>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <ngx-dropzone (change)="onSelect($event)" [accept]="'.blg, .xml, .hpxml'">
          <ngx-dropzone-label>
            Drag & Drop <b>HPXML</b>, <b>REM XML</b>, or <b>REM/Rate&trade;</b> <b>.blg</b> File(s) Here
          </ngx-dropzone-label>
          <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
            <ngx-dropzone-label>{{ f.name | slice :0: 20 }} ({{ f.type | slice :0: 20 }})</ngx-dropzone-label>
          </ngx-dropzone-preview>
        </ngx-dropzone>
      </div>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-12 text-center">
      <div><i class="error-message" [hidden]="formGroup.valid">Please select at least one item from the list above.</i></div>
      <button mat-raised-button color="accent" (click)="onSubmit($event)" [disabled]="!formGroup.valid">
        Submit
      </button>
    </div>
  </div>
</div>
