import { City } from '@/data/geographic/models/city';
import { GeocodeBrokeredResponse } from '@/data/geocoder/models';
import { Metro } from '@/data/geographic/models';

export class CommunityInfo {
  id?: number;
  name?: string;
  slug?: string;
  website?: string;
  city?: number;
  city_info?: City;
  metro?: number;
  metro_info?: Metro;
  cross_roads?: string;
  geocode_response?: number;
  geocode_response_info?: GeocodeBrokeredResponse;
  place?: string;
  climate_zone?: string;
  confirmed_address: boolean;
  address_override: string;
  state?: number;
  is_active: boolean;
  is_multi_family: boolean;
}

export class CommunityFlatList {
  id?: number;
  name?: string;
  cross_roads?: string;
  city_metro?: string;
}

export class Community {
  id?: number;
  name?: string;
  slug?: string;
  website?: string;
  city?: number;
  city_info?: City;
  metro?: number;
  metro_info?: Metro;
  cross_roads?: string;
  geocode_response?: number;
  geocode_response_info?: GeocodeBrokeredResponse;
  place?: string;
  climate_zone?: string;
  confirmed_address: boolean;
  address_override: string;
  state?: number;
  is_active: boolean;
  is_multi_family: boolean;
}
