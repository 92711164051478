import { ModelFormControl, ModelFormGroup } from './ModelForm';
import { Validators } from '@angular/forms';
import { FieldLabels } from './base';
import { Geocode, GeocodeBrokeredResponse } from '@/data/geocoder/models';
import { HomeAddressConfirmedGeocodeResponse } from '@/modules/geocoder/components/home-address-to-geocode-response-dialog/home-address-to-geocode-response-dialog.component';

export const FIELD_LABELS: FieldLabels<LocationBackendDict> = {
  lot_number: 'Lot Number',
  weather: 'Weather',
  weather_station_name: 'Weather Station',
  elevation: 'Elevation',
  geocode: '',
  geocode_response: '',
  is_multi_family: 'Is Multi Family',

  weather_info: '',
  geocode_info: '',
  geocode_response_info: '',
};

export interface WeatherBackendDict {
  id: number;
  name: string;
  latitude: number;
  longitude: number;
  pretty_label: string;
}

export interface LocationBackendDict {
  id: number;
  lot_number: string;

  weather: string;
  weather_info: WeatherBackendDict;
  weather_station_name: string;
  elevation: number;
  is_multi_family: boolean;

  geocode?: number;
  geocode_info?: Geocode;
  geocode_response?: number;
  geocode_response_info?: GeocodeBrokeredResponse;
}

export function createLocationForm(data: LocationBackendDict): ModelFormGroup {
  const address = new HomeAddressConfirmedGeocodeResponse({
    lotNumber: data.lot_number,
    geocode: data.geocode_info,
    geocodeBrokeredResponse: data.geocode_response_info,
  });

  return new ModelFormGroup(
    {
      id: new ModelFormControl(data.id),
      lot_number: new ModelFormControl(data.lot_number),
      address: new ModelFormControl(address, Validators.required),
      weather_station_name: new ModelFormControl(data.weather_station_name),
      weather: new ModelFormControl(data.weather, Validators.required),
      elevation: new ModelFormControl(data.elevation),
      geocode: new ModelFormControl(data.geocode_info),
    },
    {
      validators: [validateElevation, validateGeocode],
    }
  );
}

function validateElevation(controls: ModelFormGroup) {
  const elevation = controls.get('elevation').value;

  if (elevation) {
    if (elevation < -120 || elevation > 15000) {
      return { elevationOutOfRange: { elevation } };
    } else if (elevation < -115 || elevation > 14995) {
      return { elevationWarning: true };
    }
  }
}

function validateGeocode(controls: ModelFormGroup) {
  const geocode = controls.get('geocode').value;

  if (geocode && geocode.raw_latitude && Math.abs(geocode.raw_latitude) > 90) {
    return { invalidLatitude: true };
  }
}
