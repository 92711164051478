
<mat-sidenav-container class="side-nav-container h-100">
    <mat-sidenav #leftDrawer mode="side" opened class="drawr-test" fixedTopGap="10">
        <div>
            <!-- <div class="spacer"></div> -->
            <div class="tabs-container">
                <div class="tab" *ngFor="let tabInfo of tabsInfo" [ngClass]="{'active-tab': tabInfo.link === activeTabLink}">
                    <a class="tab-item" [routerLink]="'.'" [queryParams]="{ tab: tabInfo.link}"
                        (click)="setActiveTabLink(tabInfo.link)">
                        <fa-icon *ngIf="tabInfo?.iconType === 'fa' || tabInfo?.iconType === 'fas'" [icon]="tabInfo.icon" size="2x"></fa-icon>
                        <mat-icon *ngIf="tabInfo?.iconType === 'mat'" size="2x">{{tabInfo.icon}} [inline]="true"</mat-icon>
                        <div class="mat-line font-weight-bold fs-lg display-6" *ngIf="isExpanded">{{ tabInfo.name }}</div>
                    </a>
                    <div class="sub-tabs-container">
                        <div *ngFor="let subTab of tabInfo.subTabs">
                            <a class="sub-tab-item" [routerLink]="'.'" [queryParams]="{ tab: tabInfo.link, subTab: subTab.link}"
                                [ngClass]="{'active-color': tabInfo.link === activeTabLink}"
                                (click)="setActiveTabLink(tabInfo.link, subTab.link)">
                                <div matLine *ngIf="isExpanded">{{ subTab.name }}</div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-sidenav>
    <mat-sidenav-content [ngClass]="{'nav-drawer-unexpanded': !isExpanded, 'nav-drawer-right-unexpanded': !isLeftDrawerOpen}" class="sidebar-content">
        <ng-content></ng-content>
    </mat-sidenav-content>
    <mat-sidenav mode="side" opened position="end" fixedTopGap="10" #rightDrawer>
        <!-- <div class="spacer"></div> -->
        <div class="drawer-container" *ngIf="isLeftDrawerOpen">
            <div class="sidenav-top-bar">
                <ng-container *ngFor="let tab of sideNavTabs">
                    <button mat-button (click)="setSidenavActiveTabLink(tab.link)" id="side-nav-{{tab.link}}" [ngClass]="{'active-tab': tab.link === sideNavActiveTab}" class="side-nav-tab">
                        <span >{{tab.name}}</span>
                    </button>
                </ng-container>
                <button mat-icon-button (click)="toggleRightDrawer()">
                    <mat-icon>
                        {{ isLeftDrawerOpen ? "keyboard_double_arrow_right" : "keyboard_double_arrow_left"}}
                    </mat-icon>
                </button>
            </div>
            <div>
                <ng-container *ngIf="sideNavActiveTab === sideNavTabs[0].link; else otherContent">
                    <app-simulation-errors-and-warnings></app-simulation-errors-and-warnings>
                </ng-container>
                <ng-template #otherContent>
                    <ng-container *ngIf="sideNavActiveTab === sideNavTabs[1].link; else notesContent">
                        <app-simulation-summary></app-simulation-summary>
                    </ng-container>
                </ng-template>
                <ng-template #notesContent>
                    <ng-container *ngIf="sideNavActiveTab === sideNavTabs[2].link">
                        <app-notes [forSimulation]="simulationId"></app-notes>
                    </ng-container>
                </ng-template>
            </div>
        </div>
        <div *ngIf="!isLeftDrawerOpen">
            <button mat-icon-button (click)="toggleRightDrawer()">
                <mat-icon>
                    {{ isLeftDrawerOpen ? "keyboard_double_arrow_right" : "keyboard_double_arrow_left"}}
                </mat-icon>
            </button>
        </div>
        <div *ngIf="!isLeftDrawerOpen" class="side-nav-collapsed-tabs">
            <button mat-button *ngFor="let tab of sideNavTabs" class="vertical-button" (click)="setRightSideNavActiveTabIndex(tab.link)">
                <ng-container *ngIf="tab.name === 'Errors'">
                    <strong [matBadge]="errorCount" matBadgeSize="small" matBadgeColor="accent" id="side-nav-{{tab.link}}">{{tab.name}}</strong>
                </ng-container>
                <ng-container *ngIf="tab.name !== 'Errors'">

                    <strong id="side-nav-{{tab.link}}">{{tab.name}}</strong>
                </ng-container>
            </button>
        </div>
    </mat-sidenav>

</mat-sidenav-container>
