import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { forkJoin, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

import { Country, USState } from '@/data/geographic/models';
import { HIRLFindGreenVerifier } from '@/data/core/models/user';
import {
  HIRLFindVerifierRequestParams,
  UserService,
} from '@/data/core/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  NgbsFindVerifierDetailDialogComponent,
  NgbsFindVerifierDetailDialogData,
} from '@/modules/customer-hirl/components/ngbs-find-verifier-detail-dialog/ngbs-find-verifier-detail-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {
  AccreditationInfo,
  AccreditationName,
} from '@/data/user-management/models/accreditation';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-ngbs-find-green-verifier',
  templateUrl: './ngbs-find-green-verifier.component.html',
  styleUrls: ['./ngbs-find-green-verifier.component.scss'],
})
export class NgbsFindGreenVerifierComponent implements OnInit, OnDestroy {
  @ViewChild('tableTopScroll') tableTopScroll: ElementRef;

  public initialized = false;
  public isLoading = true;
  public usStates: USState[] = [];
  public verifiers: HIRLFindGreenVerifier[] = [];
  public verifiersCount = 0;
  public filterFromGroup: FormGroup;

  public defaultParams: HIRLFindVerifierRequestParams =
    new HIRLFindVerifierRequestParams(1, '', '-total_remodel_projects', 25);
  public storedParams: HIRLFindVerifierRequestParams =
    new HIRLFindVerifierRequestParams(1, '', '-total_remodel_projects', 25);

  public accreditationNameLabelMapping = {
    [AccreditationName.NGBS2020]: 'NGBS 2020',
    [AccreditationName.NGBS2015]: 'NGBS 2015',
    [AccreditationName.NGBSMasterVerifier]: 'NGBS Master Verifier',
    [AccreditationName.NGBSWRIVerifier]: 'NGBS WRI Verifier',
    [AccreditationName.NGBSGreenFieldRep]: 'NGBS Green Field Rep',
  };

  private componentDestroyed$ = new Subject();

  constructor(
    public router: Router,
    public dialog: MatDialog,
    public store: Store<AppState>,
    public userService: UserService,
    private fb: FormBuilder,
    public activatedRoute: ActivatedRoute,
    public location: Location
  ) {}

  ngOnInit(): void {
    forkJoin({
      queryParams: this.activatedRoute.queryParams.pipe(first()),
    }).subscribe(({ queryParams }) => {
      if (queryParams.us_states && queryParams.us_states.length) {
        this.storedParams.ordering =
          queryParams.ordering || 'main_state_ordering';
      } else {
        this.storedParams.ordering =
          queryParams.ordering || '-total_remodel_projects';
      }

      this.storedParams.assignQueryParams(queryParams, []);

      this.initialized = true;

      this.setupFilterForm();
      this.hydrateForm();
      this.list(false);
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  setupFilterForm() {
    this.filterFromGroup = this.fb.group({
      search: [null],
      us_states: [null],
      company__city__country: [null],
      accreditations__name: [],
    });

    this.filterFromGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        let params = Object.assign(this.defaultParams, values);

        params = Object.assign(params, {
          company__city__country_info: params.home__city__country_info?.map(
            (country: Country) => country.id
          ),
        });
        this.storedParams.assignFilterFormValues({
          formValues: params,
          excludedProperties: ['ordering'],
        });
        if (this.storedParams.us_states && this.storedParams.us_states.length) {
          this.storedParams.ordering = 'main_state_ordering';
        } else {
          this.storedParams.ordering = '-total_remodel_projects';
        }
        this.list();
      });
  }

  hydrateForm() {
    this.filterFromGroup.patchValue(this.storedParams, {
      emitEvent: false,
      onlySelf: true,
    });
  }

  list(scrollToTop = true) {
    this.isLoading = true;
    // populate params to query string
    const url = this.router
      .createUrlTree([], {
        relativeTo: this.activatedRoute,
        queryParams: this.storedParams,
      })
      .toString();

    this.location.go(url);

    if (scrollToTop) {
      this.tableTopScroll.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
    this.userService
      .customer_hirl_find_green_verifier_list(this.storedParams)
      .pipe(first())
      .subscribe(data => {
        this.verifiers = data.results;
        this.verifiersCount = data.count;
        this.isLoading = false;
      });
  }

  onSort({ sorts }) {
    this.storedParams.ordering = `${sorts[0].dir === 'desc' ? '-' : ''}${
      sorts[0].prop
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  onFooterPage(event) {
    this.storedParams.page = event.page;
    this.list();
  }

  findAccreditation(name: string, accreditationsInfo: AccreditationInfo[]) {
    const accreditations = accreditationsInfo.filter(accreditationInfo => {
      return accreditationInfo.name === name;
    });

    if (accreditations) {
      return accreditations[0];
    }
    return null;
  }

  onShowVerifier($event: MouseEvent, verifier: HIRLFindGreenVerifier) {
    $event.preventDefault();
    this.dialog.open(NgbsFindVerifierDetailDialogComponent, {
      width: '600px',
      data: {
        verifier,
      } as NgbsFindVerifierDetailDialogData,
    });
  }

  resetFilters($event: MouseEvent) {
    $event.preventDefault();
    this.filterFromGroup.reset();
  }
}
