import { Location } from '@/data/simulation/enumerations';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function isLocationConditioned(location): boolean {
  return [
    Location.CONDITIONED_ATTIC,
    Location.CONDITIONED_SPACE,
    Location.CONDITIONED_BASEMENT,
    Location.MF_UNRATED_CONDITIONED,
    Location.CONDITIONED_CRAWL_SPACE,
    Location.CONDITIONED_SEALED_ATTIC,
    Location.SEALED_ATTIC,
    // This location refers to the interior side of a vaulted roof, which is, by definition,
    // in the home's living space, so it should be considered conditioned.
    Location.VAULTED_ROOF,
  ].includes(location);
}

export function isLocationGarage(location): boolean {
  return [Location.GARAGE, Location.UNCONDITIONED_GARAGE].includes(location);
}

export function enumValidator(enumObj: any, allowNull = false): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (allowNull && control.value === null) return null;

    const values = Object.values(enumObj);
    if (values.includes(control.value)) {
      return null;
    }

    if (control.value === '' || control.value === null) {
      return { required: true };
    }

    return { enumInvalid: { value: control.value } };
  };
}

export function belowWarningRangeValidator(
  min: number,
  errorNamespace = 'belowWarningRange'
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value < min) {
      return { [errorNamespace]: { min, actual: control.value } };
    }

    return null;
  };
}

export function aboveWarningRangeValidator(
  max: number,
  errorNamespace = 'aboveWarningRange'
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value > max) {
      return { [errorNamespace]: { max, actual: control.value } };
    }

    return null;
  };
}

export function withinAshraeFramingFactorRangeValidator(
  ashraeTarget: number
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const framingFactor = control.value;
    if (!framingFactor) {
      return { framingFactorRequired: true };
    }

    const result = {
      unexpectedFramingFactor: {
        framingFactor,
        percent: 0,
        range: '',
        recommended: ashraeTarget * 100,
      },
    };
    if (framingFactor > 1) {
      return { largerThanOne: true };
    }

    if (framingFactor < ashraeTarget * 0.5) {
      result.unexpectedFramingFactor.range = 'lower';
      result.unexpectedFramingFactor.percent = 50;
    } else if (framingFactor < ashraeTarget * 0.75) {
      result.unexpectedFramingFactor.range = 'lower';
      result.unexpectedFramingFactor.percent = 25;
    } else if (framingFactor > ashraeTarget * 1.5) {
      result.unexpectedFramingFactor.range = 'higher';
      result.unexpectedFramingFactor.percent = 50;
    } else {
      return null;
    }

    return result;
  };
}

export function withinUvalueRange(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value < 0.01 || control.value > 1) {
      return { uValueOutOfRange: { actual: control.value } };
    }

    return null;
  };
}

export function withinRValueRange(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value < 0.01 || control.value > 150) {
      return { rValueOutOfRange: { actual: control.value } };
    }

    return null;
  };
}

export function shouldHaveAtleastOneItem(fieldName): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value.length === 0) {
      return { minItemRequired: { min: 'one', name: fieldName } };
    }

    return null;
  };
}

export function requiredAsWarning(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return { requiredAsWarning: true };
    }
    return null;
  };
}
