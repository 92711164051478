import { ListRequestParams } from '@/core/schemes/request-params';
import { Injectable } from '@angular/core';
import { BaseDataService } from '@/core/services/base-data-service';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { ServerResponse } from '@/core/schemes/server-response';
import { AxisFlatPage } from '@/data/core/models';
import { User } from '@/data/core/models/user';

export class AxisFlatPageRequestParams extends ListRequestParams {}

@Injectable({
  providedIn: 'root',
})
export class AxisFlatPageService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/flatpages/`;

  news(
    params?: AxisFlatPageRequestParams
  ): Observable<ServerResponse<AxisFlatPage>> {
    return this.http.get<ServerResponse<AxisFlatPage>>(`${this.baseUrl}news/`, {
      params: this.rollParams(params),
    });
  }

  retrieve(flatpageId: number) {
    return this.http.get<User>(`${this.baseUrl}${flatpageId}`, {});
  }

  update(
    formData: AxisFlatPage | FormData,
    flatpageId: number,
    partial = true
  ): Observable<AxisFlatPage> {
    let method = this.http.patch;
    if (!partial) {
      method = this.http.put;
    }
    return this.http.patch<AxisFlatPage>(
      `${this.baseUrl}${flatpageId}/`,
      formData
    );
  }

  verifier_resources_page() {
    return this.http.get<AxisFlatPage>(
      `${this.baseUrl}verifier_resources_page/`,
      {}
    );
  }

  verifier_archives_page(year) {
    return this.http.get<AxisFlatPage>(
      `${this.baseUrl}verifier_resources_page/${year}/`,
      {}
    );
  }

  builder_central_page() {
    return this.http.get<AxisFlatPage>(
      `${this.baseUrl}builder_central_page/`,
      {}
    );
  }

  customer_hirl_pm_central_page() {
    return this.http.get<AxisFlatPage>(
      `${this.baseUrl}customer_hirl_pm_central_page/`,
      {}
    );
  }
}
