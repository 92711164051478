import { createReducer, on } from '@ngrx/store';
import * as WaterSystemActions from './actions';
import { WaterSystemBackendDict } from '@/data/simulation/models/systems/WaterSystem';
import { BaseState, sharedInitialState } from '../shared/base.state';
import { addErrorCount, addStateErrorCount } from '../helper';

export type WaterSystemState = BaseState<WaterSystemBackendDict>;

export const initialState: WaterSystemState = sharedInitialState;

export const reducer = createReducer(
  initialState,
  on(WaterSystemActions.loadWaterSystemsSuccess, (currentState, payload) => {
    const entities = payload.waterSystems.reduce(
      (acc, watersystem) => ({
        ...acc,
        [watersystem.id]: watersystem,
      }),
      {}
    );

    const loadingStatus = payload.waterSystems.reduce(
      (acc, watersystem) => ({
        ...acc,
        [watersystem.id]: false,
      }),
      {}
    );

    return {
      entities: { ...currentState.entities, ...entities },
      loading: { ...currentState.loading, ...loadingStatus },
      errors: {
        ...currentState.errors,
        ...addStateErrorCount(payload.errors),
      },
    };
  }),

  on(WaterSystemActions.updateWaterSystemFailure, (state, { id }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [id]: false,
      },
    };
  }),

  on(
    WaterSystemActions.updateWaterSystemSuccess,
    (state, { waterSystem, errors }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          [waterSystem.id]: waterSystem,
        },
        loading: {
          ...state.loading,
          [waterSystem.id]: false,
        },
        errors: {
          ...state.errors,
          [waterSystem.id]: addErrorCount(errors),
        },
      };
    }
  )
);
