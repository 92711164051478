export const HIRLProjectRegistrationCategories = {
  singleFamily: [
    'ngbs-sf-whole-house-remodel-2012-new',
    'ngbs-sf-whole-house-remodel-2015-new',
    'ngbs-sf-whole-house-remodel-2020-new',
    'ngbs-green-building-renovations-with-additions-75',
    'ngbs-green-remodel-renovations-with-additions-75',
    'ngbs-renovations-with-addition-75',
    'ngbs-single-family-additions-75-1',
    'ngbs-single-family-additions-75-2',
    'ngbs-single-family-green-building-renovation',
    'ngbs-single-family-green-remodel-renovation',
    'ngbs-basement-remodel-2012',
    'ngbs-bathroom-remodel-2012',
    'ngbs-kitchen-remodel-2012',
    'ngbs-sf-whole-house-remodel-2012',
    'ngbs-small-addition-2012',
    'ngbs-sf-kitchen-remodel-2015',
    'ngbs-sf-whole-house-remodel-2015',
    'ngbs-sf-whole-house-remodel-2020',
    'ngbs-sf-certified-2020-new',
    'ngbs-sf-certified-2020',
    'ngbs-sf-new-construction-2020-new',
    'ngbs-sf-new-construction-2015-new',
    'ngbs-sf-new-construction-2012-new',
    'ngbs-sf-new-construction-2020',
    'ngbs-sf-new-construction-2015',
    'ngbs-sf-new-construction-2012',
    'ngbs-single-family-new-construction',
  ],
  singleFamilyNC: [
    'ngbs-sf-new-construction-2020-new',
    'ngbs-sf-new-construction-2015-new',
    'ngbs-sf-new-construction-2012-new',
    // legacy
    'ngbs-sf-new-construction-2020',
    'ngbs-sf-new-construction-2015',
    'ngbs-sf-new-construction-2012',
    'ngbs-single-family-new-construction',
  ],
  singleFamilyEB: [
    'ngbs-sf-whole-house-remodel-2012-new',
    'ngbs-sf-whole-house-remodel-2015-new',
    'ngbs-sf-whole-house-remodel-2020-new',
    'ngbs-green-building-renovations-with-additions-75',
    'ngbs-green-remodel-renovations-with-additions-75',
    'ngbs-renovations-with-addition-75',
    'ngbs-single-family-additions-75-1',
    'ngbs-single-family-additions-75-2',
    'ngbs-single-family-green-building-renovation',
    'ngbs-single-family-green-remodel-renovation',
    'ngbs-basement-remodel-2012',
    'ngbs-bathroom-remodel-2012',
    'ngbs-kitchen-remodel-2012',
    'ngbs-sf-whole-house-remodel-2012',
    'ngbs-small-addition-2012',
    'ngbs-sf-kitchen-remodel-2015',
    'ngbs-sf-whole-house-remodel-2015',
    'ngbs-sf-whole-house-remodel-2020',
  ],
  multiFamily: [
    'ngbs-mf-whole-house-remodel-2012-new',
    'ngbs-mf-whole-house-remodel-2015-new',
    'ngbs-mf-whole-house-remodel-2020-new',
    'ngbs-multi-unit-green-building-renovation',
    'ngbs-multi-unit-green-remodel-renovation',
    'ngbs-mf-remodel-building-2012',
    'ngbs-mf-remodel-building-2015',
    'ngbs-mf-remodel-building-2020',
    'ngbs-mf-new-construction-2012-new',
    'ngbs-mf-new-construction-2015-new',
    'ngbs-mf-new-construction-2020-new',
    'ngbs-multi-unit-new-construction',
    'ngbs-mf-new-construction-2012',
    'ngbs-mf-new-construction-2015',
    'ngbs-mf-new-construction-2020',
  ],
  multiFamilyEB: [
    'ngbs-mf-whole-house-remodel-2012-new',
    'ngbs-mf-whole-house-remodel-2015-new',
    'ngbs-mf-whole-house-remodel-2020-new',
    'ngbs-multi-unit-green-building-renovation',
    'ngbs-multi-unit-green-remodel-renovation',
    'ngbs-mf-remodel-building-2012',
    'ngbs-mf-remodel-building-2015',
    'ngbs-mf-remodel-building-2020',
  ],
  multiFamilyNC: [
    'ngbs-mf-new-construction-2012-new',
    'ngbs-mf-new-construction-2015-new',
    'ngbs-mf-new-construction-2020-new',
    'ngbs-multi-unit-new-construction',
    'ngbs-mf-new-construction-2012',
    'ngbs-mf-new-construction-2015',
    'ngbs-mf-new-construction-2020',
  ],
  landDevelopment: [
    'ngbs-green-subdivision',
    'ngbs-green-subdivision-2012',
    'ngbs-green-subdivision-2020',
  ],
};

export enum HIRLProjectType {
  singleFamily = 1,
  singleFamilyNC = 2,
  singleFamilyEB = 3,
  singleFamilyRTO = 4,
  multiFamily = 5,
  multiFamilyNC = 6,
  multiFamilyEB = 7,
  landDevelopment = 8,
}

export const HIRLProjectTypeLabelMapping: Record<HIRLProjectType, string> = {
  [HIRLProjectType.singleFamily]: 'Single Family (All)',
  [HIRLProjectType.singleFamilyNC]: 'Single Family (NC)',
  [HIRLProjectType.singleFamilyEB]: 'Single Family (Remodel)',
  [HIRLProjectType.singleFamilyRTO]: 'Single Family (BTR)',
  [HIRLProjectType.multiFamily]: 'Multi-Family (All)',
  [HIRLProjectType.multiFamilyNC]: 'Multi-Family (NC)',
  [HIRLProjectType.multiFamilyEB]: 'Multi-Family (Remodel)',
  [HIRLProjectType.landDevelopment]: 'Land Development',
};
