import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  AsynchronousProcessedDocument,
  CeleryTaskState,
} from '@/data/filehandling/models';
import { FormBuilder } from '@angular/forms';
import { forkJoin, of, Subject } from 'rxjs';
import { User } from '@/data/core/models/user';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, takeUntil } from 'rxjs/operators';
import { AsynchronousProcessedDocumentService } from '@/data/filehandling/services/asynchronous-processed-document.service';

export class AsynchronousProcessedDocumentStatusDialogData {
  title = 'Processing document';
  entity: AsynchronousProcessedDocument;
}

@Component({
  selector: 'app-asynchronous-processed-document-status-dialog',
  templateUrl: './asynchronous-processed-document-status-dialog.component.html',
  styleUrls: ['./asynchronous-processed-document-status-dialog.component.scss'],
})
export class AsynchronousProcessedDocumentStatusDialogComponent
  implements OnInit, OnDestroy
{
  public initialized: boolean;

  public entity: AsynchronousProcessedDocument;

  public taskStateColors = {
    [CeleryTaskState.SUCCESS]: 'green',
    [CeleryTaskState.FAILURE]: 'red',
    [CeleryTaskState.REVOKED]: 'magenta',
    [CeleryTaskState.STARTED]: 'black',
    [CeleryTaskState.RETRY]: 'orange',
    [CeleryTaskState.RECEIVED]: 'blue',
    [CeleryTaskState.PENDING]: 'black',
    [CeleryTaskState.UNACKNOWLEDGED]: 'grey',
  };

  public isCompleted = false;
  public processingProgressData: { [key: string]: any } | null = null;
  public writingProgressData: { [key: string]: any } | null = null;

  private componentDestroyed$ = new Subject();
  private currentUser: User;

  private timeoutIntervalId$: any;

  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<AsynchronousProcessedDocumentStatusDialogComponent>,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private asynchronousProcessedDocumentService: AsynchronousProcessedDocumentService,
    private uiHelperService: UIHelperService,
    @Inject(MAT_DIALOG_DATA)
    public data: AsynchronousProcessedDocumentStatusDialogData
  ) {
    this.entity = data.entity;
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.retrieve();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();

    if (this.timeoutIntervalId$) {
      clearTimeout(this.timeoutIntervalId$);
    }
  }

  retrieve() {
    if (this.timeoutIntervalId$) {
      clearTimeout(this.timeoutIntervalId$);
    }

    const sources = {
      currentUser: this.store.select(getInfoUser).pipe(first()),
      asynchronousProcessedDocument: of(this.entity),
    };

    sources.asynchronousProcessedDocument =
      this.asynchronousProcessedDocumentService
        .retrieve(this.entity.id, { ignoreStore: true })
        .pipe(takeUntil(this.componentDestroyed$), first());

    forkJoin(sources)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: ({ currentUser, asynchronousProcessedDocument }) => {
          this.currentUser = currentUser;
          this.entity = asynchronousProcessedDocument;
          this.initialized = true;
          if (
            [CeleryTaskState.SUCCESS, CeleryTaskState.FAILURE].includes(
              this.entity.state
            )
          ) {
            this.isCompleted = true;
          } else {
            if (this.entity.result && typeof this.entity.result !== 'string') {
              const processing = this.entity.result['processing'];
              if (processing) {
                this.processingProgressData = {
                  value: (processing['current'] / processing['total']) * 100,
                  mode: 'determinate',
                };
              }

              const writing = this.entity.result['writing'];
              if (
                writing &&
                (!this.processingProgressData ||
                  this.processingProgressData['value'] === 100)
              ) {
                this.processingProgressData = null;
                this.writingProgressData = {
                  value: (writing['current'] / writing['total']) * 100,
                  mode: 'determinate',
                };
              }
            }

            this.timeoutIntervalId$ = setInterval(() => {
              this.retrieve();
            }, 1000);
          }
        },
      });
  }
}
