export class HIRLGreenEnergyBadge {
  id?: number;
  name: string;
  slug: string;
}

export class HIRLGreenEnergyBadgeInfo {
  id?: number;
  name: string;
  slug: string;
}
