import { HomeInfo } from '@/data/home/models/home';
import { CompanyInfo } from '@/data/company/models';

export class ProviderDashboardList {
  id: number;
  company: number;
  company_info: CompanyInfo;
  home: number;
  home_info: HomeInfo;
  floorplan: number;
  floorplan_name: string;
  eep_program: number;
  eep_program_name: string;
  state: string;
}

export class ProviderDashboardProgramsReadyForCertification {
  id: number;
  name: string;
  slug: string;
  count: number;
}

export class ProviderDashboardProgramsReadyForFTSubmit {
  id: number;
  name: string;
  slug: string;
  count: number;
}
