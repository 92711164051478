import {
  BlockRateBackendDict,
  createBlockRateForm,
} from '@/data/simulation/models/utility/BlockRate';
import { BaseValidator } from './base.validator';
import { ModelErrors, StateErrors } from '../state/shared/base.state';

export class BlockRateValidator extends BaseValidator {
  static validateForm(
    blockRate: BlockRateBackendDict
  ): ModelErrors<BlockRateBackendDict> {
    const blockrateForm = createBlockRateForm(blockRate);
    const errors = {};
    Object.keys(blockRate).forEach(key => {
      if (!blockrateForm.get(key)) return;
      errors[key] = blockrateForm.get(key).errors;
    });
    return {
      field: errors,
      model: blockrateForm.errors || {},
    };
  }

  static validate(
    blockRates: BlockRateBackendDict[]
  ): StateErrors<BlockRateBackendDict> {
    const errors = {};
    blockRates.forEach(blockRate => {
      errors[blockRate.id] = this.validateForm(blockRate);
    });
    return errors;
  }
}
