import { createReducer, on } from '@ngrx/store';
import * as WaterHeaterActions from './actions';
import { WaterHeaterBackendDict } from '@/data/simulation/models/mechanicals/WaterHeater';
import { BaseState } from '../shared/base.state';
import { addStateErrorCount } from '../helper';

export type WaterHeaterState = BaseState<WaterHeaterBackendDict>;

export const initialState: WaterHeaterState = {
  entities: {},
  loading: {},
  errors: {},
};

export const reducer = createReducer(
  initialState,
  on(
    WaterHeaterActions.loadWaterHeatersSuccess,
    (state, { waterHeaters, errors }) => {
      const entities = waterHeaters.reduce(
        (acc, waterHeater) => ({ ...acc, [waterHeater.id]: waterHeater }),
        {}
      );
      const loading = waterHeaters.reduce(
        (acc, waterHeater) => ({ ...acc, [waterHeater.id]: false }),
        {}
      );

      return {
        entities: {
          ...state.entities,
          ...entities,
        },
        loading: {
          ...state.loading,
          ...loading,
        },
        errors: {
          ...state.errors,
          ...addStateErrorCount(errors),
        },
      };
    }
  ),

  on(WaterHeaterActions.updateWaterHeater, (state, { waterHeaterChanges }) => ({
    ...state,
    loading: {
      ...state.loading,
      [waterHeaterChanges.id]: true,
    },
  })),

  on(WaterHeaterActions.updateWaterHeaterFailure, (state, { id }) => ({
    ...state,
    loading: {
      ...state.loading,
      [id]: false,
    },
  }))
);
