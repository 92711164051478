<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
    <mat-form-field *ngIf="data.inputType == inputDialogInputTypeEnum.input" class="w-100" #input>
        <input matInput [(ngModel)]="data.text" cdkFocusInitial [required]="data.inputIsRequired">
    </mat-form-field>
    <mat-form-field *ngIf="data.inputType == inputDialogInputTypeEnum.textArea" class="w-100" #textArea>
        <textarea matInput [(ngModel)]="data.text" cdkFocusInitial [required]="data.inputIsRequired"></textarea>
    </mat-form-field>
</div>
<mat-dialog-actions align="end">
    <button mat-raised-button color="accent" class="mr-2" (click)="onConfirm($event)">
        {{ data.confirmBtnText }}
    </button>
    <button mat-raised-button (click)="cancelClick()">
        {{ data.cancelBtnText }}
    </button>
</mat-dialog-actions>
