export enum AnalysisStatus {
  complete = 'complete',
  pending = 'pending',
  started = 'started',
  failed = 'failed',
  retry = 'retry',
}

export const AnalysisStatusLabelMapping: Record<AnalysisStatus, string> = {
  [AnalysisStatus.complete]: 'Complete',
  [AnalysisStatus.pending]: 'Pending',
  [AnalysisStatus.started]: 'Started',
  [AnalysisStatus.failed]: 'Failed',
  [AnalysisStatus.retry]: 'Retry',
};

export class Analysis {
  id?: number;
  type?: string;
  source_name?: string;
  source_qualifier?: string;
  eri_score?: number;
  eri_score_no_pv?: number;
  type_display?: string;
}

export class AnalysisInfo {
  id?: number;
  type?: string;
  source_name?: string;
  source_qualifier?: string;
  eri_score?: number;
  eri_score_no_pv?: number;
  status: string;
}
