import { ServerResponse } from '@/core/schemes/server-response';
import {
  Company,
  CompanyAccess,
  CompanyAutocompleteList,
  CompanyRole,
  HirlMarketingRole,
} from '@/data/company/models';
import {
  CompanyRequestParams,
  CompanyService,
} from '@/data/company/services/company-base.service';
import { componentCompanyTypeDataMap } from '@/modules/company/constants';
import { RaterRole, UserRaterRole } from '@/data/core/models';
import { User } from '@/data/core/models/user';
import {
  UserRequestParams,
  UserService,
} from '@/data/core/services/user.service';
import {
  City,
  Country,
  County,
  Metro,
  USState,
} from '@/data/geographic/models';
import {
  CityRequestParams,
  CityService,
} from '@/data/geographic/services/city.service';
import {
  RaterRoleRequestParams,
  RaterRoleService,
} from '@/data/core/services/rater-role.service';
import { EEPProgram } from '@/data/eep-program/models';
import {
  EEPProgramRequestParams,
  EEPProgramService,
} from '@/data/eep-program/services/eep-program.service';
import {
  CompanyRoleRequestParams,
  CompanyRoleService,
} from '@/data/company/services/company-role.service';
import {
  HIRLMarketingRoleRequestParams,
  HirlMarketingRoleService,
} from '@/data/company/services/hirl-marketing-role.service';
import {
  CountyRequestParams,
  CountyService,
} from '@/data/geographic/services/county.service';
import {
  MetroRequestParams,
  MetroService,
} from '@/data/geographic/services/metro.service';
import {
  CountryRequestParams,
  CountryService,
} from '@/data/geographic/services/country.service';
import { Subdivision } from '@/data/subdivision/models';
import {
  SubdivisionRequestParams,
  SubdivisionService,
} from '@/data/subdivision/services/subdivision.service';
import {
  USStateRequestParams,
  USStateService,
} from '@/data/geographic/services/us-state.service';
import {
  CompanyAccessRequestParams,
  CompanyAccessService,
} from '@/data/company/services/company-access.service';
import {
  ClientAgreement,
  COIDocument,
  COIDocumentRequestParams,
  HIRLGreenEnergyBadge,
  HIRLProductAgreement,
  HIRLProject,
  HIRLProjectRegistration,
  PaymentRequest,
  Practice,
  PracticeChapter,
  PracticeItem,
  PracticeSection,
  ProductPracticeItem,
} from '@/data/customer-hirl/models';
import {
  HIRLGreenEnergyBadgeRequestParams,
  HIRLGreenEnergyBadgeService,
} from '@/data/customer-hirl/services/hirl-green-energy-badge.service';
import { SchedulingTaskType, Task } from '@/data/scheduling/models';
import {
  SchedulingTaskTypeRequestParams,
  SchedulingTaskTypeService,
  TaskService,
} from '@/data/scheduling/services';
import { Observation, ObservationType } from '@/data/qa/models';
import {
  ObservationRequestParams,
  ObservationService,
} from '@/data/qa/services/observation.service';
import {
  ObservationTypeRequestParams,
  ObservationTypeService,
} from '@/data/qa/services/observation-type.service';
import {
  HIRLProjectRegistrationRequestParams,
  HIRLProjectRegistrationService,
} from '@/data/customer-hirl/services/hirl-project-registration.service';
import {
  AboveGradeWallTypeParams,
  AboveGradeWallTypeService,
} from '@/data/simulation/services/above-grade-wall-type.service';
import { AboveGradeWallTypeBackendDict } from '@/data/simulation/models/enclosure/AboveGradeWallType';
import {
  FoundationWallTypeParams,
  FoundationWallTypeService,
} from '@/data/simulation/services/foundation-wall-type.service';
import { FoundationWallTypeBackendDict } from '@/data/simulation/models/enclosure/FoundationWallType';
import {
  RoofTypeParams,
  RoofTypeService,
} from '@/data/simulation/services/roof-type.service';
import { RoofTypeBackendDict } from '@/data/simulation/models/enclosure/RoofType';
import {
  FrameFloorTypeParams,
  FrameFloorTypeService,
} from '@/data/simulation/services/frame-floor-type.service';
import { FrameFloorTypeBackendDict } from '@/data/simulation/models/enclosure/FrameFloorType';
import {
  SlabTypeParams,
  SlabTypeService,
} from '@/data/simulation/services/slab-type.service';
import { SlabTypeBackendDict } from '@/data/simulation/models/enclosure/SlabType';
import {
  SkylightTypeParams,
  SkylightTypeService,
} from '@/data/simulation/services/skylight-type.service';
import { SkylightTypeBackendDict } from '@/data/simulation/models/enclosure/SkylightType';
import {
  RimJoistTypeParams,
  RimJoistTypeService,
} from '@/data/simulation/services/rim-joist-type.service';
import { RimJoistTypeBackendDict } from '@/data/simulation/models/enclosure/RimJoistType';
import {
  DoorTypeParams,
  DoorTypeService,
} from '@/data/simulation/services/door-type.service';
import { DoorTypeBackendDict } from '@/data/simulation/models/enclosure/DoorType';
import {
  WindowTypeParams,
  WindowTypeService,
} from '@/data/simulation/services/window-type.service';
import { WindowTypeBackendDict } from '@/data/simulation/models/enclosure/WindowType';
import { AirConditionerService } from '@/data/simulation/services/air-conditioner.service';
import { ListRequestParams } from '@/core/schemes/request-params';
import { AirConditionerBackendDict } from '@/data/simulation/models/mechanicals/AirConditioner';
import { AirSourceHeatPumpService } from '@/data/simulation/services/air-source-heat-pump.service';
import { AirSourceHeatPumpBackendDict } from '@/data/simulation/models/mechanicals/AirSourceHeatPump';
import { DehumidifierService } from '@/data/simulation/services/dehumidifier.service';
import { DehumidifierBackendDict } from '@/data/simulation/models/mechanicals/Dehumidifier';
import { GroundSourceHeatPumpService } from '@/data/simulation/services/ground-source-heat-pump.service';
import { GroundSourceHeatPumpBackendDict } from '@/data/simulation/models/mechanicals/GroundSourceHeatPump';
import { HeaterService } from '@/data/simulation/services/heater.service';
import { HeaterBackendDict } from '@/data/simulation/models/mechanicals/Heater';
import { WaterHeaterService } from '@/data/simulation/services/water-heater.service';
import { WaterHeaterBackendDict } from '@/data/simulation/models/mechanicals/WaterHeater';
import { SimulationVersions, UDRHModel } from '@/data/simulation/models';
import {
  SimulationRequestParams,
  SimulationService,
} from '@/data/simulation/services/simulation.service';
import {
  UDRHModelRequestParams,
  UDRHModelService,
} from '@/data/simulation/services/udrh-model.service';
import { UtilityRateService } from '@/data/simulation/services/utility-rate.service';
import { UtilityRateBackendDict } from '@/data/simulation/models/utility/UtilityRate';
import { LocationService } from '@/data/simulation/services/location.service';
import {
  LocationBackendDict,
  WeatherBackendDict,
} from '@/data/simulation/models/Location';
import {
  BrandRequestParams,
  BrandService,
} from '@/data/product/services/brand.service';
import {
  Brand,
  Category,
  CompanyBrand,
  ProductList,
} from '@/data/product/models';
import {
  CategoryRequestParams,
  CategoryService,
} from '@/data/product/services/category.service';
import {
  ProductRequestParams,
  ProductService,
} from '@/data/product/services/product.service';
import { PracticeStandard } from '@/data/customer-hirl/models/practice-standard';
import {
  PracticeStandardRequestParams,
  PracticeStandardService,
} from '@/data/customer-hirl/services/practice-standard.service';
import {
  PracticeChapterRequestParams,
  PracticeChapterService,
} from '@/data/customer-hirl/services/practice-chapter.service';
import {
  PracticeSectionRequestParams,
  PracticeSectionService,
} from '@/data/customer-hirl/services/practice-section.service';
import {
  PracticeRequestParams,
  PracticeService,
} from '@/data/customer-hirl/services/practice.service';
import {
  PracticeItemRequestParams,
  PracticeItemService,
} from '@/data/customer-hirl/services/practice-item.service';
import {
  HIRLProjectRequestParams,
  HIRLProjectService,
} from '@/data/customer-hirl/services/hirl-projects.service';
import {
  PaymentRequestRequestParams,
  PaymentRequestService,
} from '@/data/customer-hirl/services/payment-request.service';
import {
  HIRLProductAgreementRequestParams,
  HIRLProductAgreementService,
} from '@/data/customer-hirl/services/product-agreement.service';
import {
  InvoiceRequestParams,
  InvoiceService,
} from '@/data/invoicing/services/invoice.service';
import { Invoice } from '@/data/invoicing/models';
import { CustomerDocument } from '@/data/filehandling/models';
import {
  CustomerDocumentRequestParams,
  CustomerDocumentService,
} from '@/data/filehandling/services/customer-document.service';
import {
  ClientAgreementParams,
  ClientAgreementService,
} from '@/data/customer-hirl/services/client-agreement.service';
import { COIDocumentService } from '@/data/customer-hirl/services/coi-documents.service';
import {
  CompanyBrandRequestParams,
  CompanyBrandService,
} from '@/data/product/services/company-brand.service';
import {
  ProductPracticeItemRequestParams,
  ProductPracticeItemService,
} from '@/data/customer-hirl/services/product-practice-item.service';
import { AboveGradeWallService } from '@/data/simulation/services/above-grade-wall.service';
import { AboveGradeWallBackendDict } from '@/data/simulation/models/enclosure/AboveGradeWall';
import { FoundationWallService } from '@/data/simulation/services/foundation-wall.service';
import { FoundationWallBackendDict } from '@/data/simulation/models/enclosure/FoundationWall';
import { RoofService } from '@/data/simulation/services/roof.service';
import { RoofBackendDict } from '@/data/simulation/models/enclosure/Roof';
import { FrameFloorService } from '@/data/simulation/services/frame-floor.service';
import { FrameFloorBackendDict } from '@/data/simulation/models/enclosure/FrameFloor';
import { SlabService } from '@/data/simulation/services/slab.service';
import { SlabBackendDict } from '@/data/simulation/models/enclosure/Slab';
import { SkylightService } from '@/data/simulation/services/skylight.service';
import { SkylightBackendDict } from '@/data/simulation/models/enclosure/Skylight';
import { RimJoistBackendDict } from '@/data/simulation/models/enclosure/RimJoist';
import { RimJoistService } from '@/data/simulation/services/rim-joist.service';
import { DoorService } from '@/data/simulation/services/door.service';
import { DoorBackendDict } from '@/data/simulation/models/enclosure/Door';
import { WindowService } from '@/data/simulation/services/window.service';
import { WindowBackendDict } from '@/data/simulation/models/enclosure/Window';
import {
  UserRaterRoleRequestParams,
  UserRaterRoleService,
} from '@/data/core/services/user-rater-role.service';
import { SimulationBackendDict } from '@/data/simulation/models/simulation';
import {
  Accreditation,
  AccreditationNameLabelMapping,
  InspectionGrade,
  Training,
} from '@/data/user-management/models';
import {
  AccreditationRequestParams,
  AccreditationService,
} from '@/data/user-management/services/accreditation.service';
import {
  InspectionGradeRequestParams,
  InspectionGradeService,
} from '@/data/user-management/services/inspection-grade.service';
import { TaskRequestParams } from '@/data/scheduling/services/task.service';
import {
  TrainingRequestParams,
  TrainingService,
} from '@/data/user-management/services/training.service';
import { ProjectService } from '@/data/simulation/services/project.service';
import { ProjectBackendDict } from '@/data/simulation/models/Project';

export interface ModelMapItem<T> {
  modelCls: T | string;
  serviceCls: any; // Assuming any service class here
  filterParamsCls: any; // Assuming any filterParams class here
  method: string; // method to call in serviceCls
  display: (value: T) => string;
  optionDisplay: (value: T) => string;
  optionDisabled: (value: any) => boolean; // result of process response
  idAttributeName: string;
  processResponse: (response: ServerResponse<T> | T[] | T) => any; // Adjust any if there's a specific type
}

export interface ModelsMap {
  [key: string]: ModelMapItem<any>;
}

export const axisModelMap: ModelsMap = {
  company: {
    modelCls: Company,
    serviceCls: CompanyService,
    filterParamsCls: CompanyRequestParams,
    method: 'autocomplete_list',
    display: (value: CompanyAutocompleteList) => `${value.name}`,
    optionDisplay: (value: CompanyAutocompleteList) =>
      `${value.name} [${
        componentCompanyTypeDataMap[value.company_type].displayName
      }]`,
    idAttributeName: 'id',
    processResponse: (response: ServerResponse<CompanyAutocompleteList>) =>
      response.results,
    optionDisabled: value => false,
  },
  user: {
    modelCls: User,
    serviceCls: UserService,
    filterParamsCls: UserRequestParams,
    method: 'list',
    display: (value: User) => `${value.first_name} ${value.last_name}`,
    optionDisplay: (value: User) => {
      let str = `${value.first_name} ${value.last_name} [${value.company_info?.name}]`;

      if (!value.is_active) {
        str += ' (Inactive)';
      }
      return str;
    },
    idAttributeName: 'id',
    processResponse: (response: ServerResponse<RaterRole>) => response.results,
    optionDisabled: value => false,
  },
  city: {
    modelCls: City,
    serviceCls: CityService,
    filterParamsCls: CityRequestParams,
    method: 'list',
    display: (value: City) => {
      if (value.country === 'US') {
        return `${value?.name}, ${value.county_info?.name}, ${value.county_info?.state}`;
      }
      return `${value?.name}, ${value.country_info?.name}`;
    },
    optionDisplay: (value: City) => {
      if (value.country === 'US') {
        return `${value?.name}, ${value.county_info?.name}, ${value.county_info?.state}`;
      }
      return `${value?.name}, ${value.country_info?.name}`;
    },
    optionDisabled: value => false,
    processResponse: (response: ServerResponse<RaterRole>) => response.results,
    idAttributeName: 'id',
  },
  accreditation: {
    modelCls: Accreditation,
    serviceCls: AccreditationService,
    filterParamsCls: AccreditationRequestParams,
    method: 'list',
    display: (value: Accreditation) => `${value.name}`,
    optionDisplay: (value: Accreditation) =>
      `${AccreditationNameLabelMapping[value.name]}`,
    optionDisabled: value => false,
    idAttributeName: 'id',
    processResponse: (response: ServerResponse<Accreditation>) =>
      response.results,
  },
  training: {
    modelCls: Training,
    serviceCls: TrainingService,
    filterParamsCls: TrainingRequestParams,
    method: 'list',
    display: (value: Training) => `${value.id}`,
    optionDisplay: (value: Training) => `${value.id}`,
    optionDisabled: value => false,
    idAttributeName: 'id',
    processResponse: (response: ServerResponse<Training>) => response.results,
  },
  inspection_grade: {
    modelCls: InspectionGrade,
    serviceCls: InspectionGradeService,
    filterParamsCls: InspectionGradeRequestParams,
    method: 'list',
    display: (value: InspectionGrade) => `${value.letter_grade}`,
    optionDisplay: (value: InspectionGrade) => `${value.letter_grade}`,
    optionDisabled: value => false,
    idAttributeName: 'id',
    processResponse: (response: ServerResponse<InspectionGrade>) =>
      response.results,
  },
  rater_role: {
    modelCls: RaterRole,
    serviceCls: RaterRoleService,
    filterParamsCls: RaterRoleRequestParams,
    method: 'list',
    display: (value: RaterRole) => `${value.title}`,
    optionDisplay: (value: RaterRole) => `${value.title}`,
    optionDisabled: value => false,
    idAttributeName: 'id',
    processResponse: (response: ServerResponse<RaterRole>) => response.results,
  },
  user_rater_role: {
    modelCls: UserRaterRole,
    serviceCls: UserRaterRoleService,
    filterParamsCls: UserRaterRoleRequestParams,
    method: 'list',
    display: (value: UserRaterRole) => `${value.raterrole_info?.title}`,
    optionDisplay: (value: UserRaterRole) => `${value.raterrole_info?.title}`,
    optionDisabled: value => false,
    idAttributeName: 'id',
    processResponse: (response: ServerResponse<UserRaterRole>) =>
      response.results,
  },
  eep_program: {
    modelCls: EEPProgram,
    serviceCls: EEPProgramService,
    filterParamsCls: EEPProgramRequestParams,
    method: 'list',
    display: (value: EEPProgram) => `${value.name}`,
    optionDisplay: (value: EEPProgram) => `${value.name}`,
    optionDisabled: value => false,
    idAttributeName: 'id',
    processResponse: (response: ServerResponse<EEPProgram>) => response.results,
  },
  company_role: {
    modelCls: CompanyRole,
    serviceCls: CompanyRoleService,
    filterParamsCls: CompanyRoleRequestParams,
    method: 'list',
    display: (value: CompanyRole) => `${value.name}`,
    optionDisplay: (value: CompanyRole) => `${value.name}`,
    optionDisabled: value => false,
    idAttributeName: 'id',
    processResponse: (response: ServerResponse<CompanyRole>) =>
      response.results,
  },
  hirl_marketing_role: {
    modelCls: HirlMarketingRole,
    serviceCls: HirlMarketingRoleService,
    filterParamsCls: HIRLMarketingRoleRequestParams,
    method: 'list',
    display: (value: HirlMarketingRole) => `${value.name}`,
    optionDisplay: (value: HirlMarketingRole) => `${value.name}`,
    optionDisabled: value => false,
    idAttributeName: 'id',
    processResponse: (response: ServerResponse<HirlMarketingRole>) =>
      response.results,
  },
  simulation_versions: {
    modelCls: SimulationVersions,
    serviceCls: SimulationService,
    method: 'versions',
    idAttributeName: 'id',
    filterParamsCls: SimulationRequestParams,
    display: (value: { [key: string]: string }) => `${value.name}`,
    optionDisplay: (value: { [key: string]: string }) => `${value.name}`,
    processResponse: (response: SimulationVersions) => {
      return response.versions.map((str, index) => {
        return {
          name: str,
          id: str,
        };
      });
    },
    optionDisabled: (value: { [key: string]: string }) => false,
  },
  udrh_model: {
    modelCls: UDRHModel,
    serviceCls: UDRHModelService,
    filterParamsCls: UDRHModelRequestParams,
    method: 'list',
    idAttributeName: 'id',
    display: (value: UDRHModel) => `${value.short_name}`,
    optionDisplay: (value: UDRHModel) => `${value.short_name}`,
    processResponse: (response: ServerResponse<UDRHModel>) => response.results,
    optionDisabled: (value: UDRHModel) => false,
  },
  county: {
    modelCls: County,
    serviceCls: CountyService,
    filterParamsCls: CountyRequestParams,
    method: 'list',
    display: (value: County) => `${value.name}`,
    optionDisplay: (value: County) => `${value.name} (${value.state})`,
    optionDisabled: value => false,
    idAttributeName: 'id',
    processResponse: (response: ServerResponse<County>) => response.results,
  },
  metro: {
    modelCls: Metro,
    serviceCls: MetroService,
    filterParamsCls: MetroRequestParams,
    method: 'list',
    display: (value: Metro) => `${value.name}`,
    optionDisplay: (value: Metro) => `${value.name}`,
    optionDisabled: value => false,
    idAttributeName: 'id',
    processResponse: (response: ServerResponse<Metro>) => response.results,
  },
  country: {
    modelCls: Country,
    serviceCls: CountryService,
    filterParamsCls: CountryRequestParams,
    method: 'list',
    display: (value: Country) => `${value.name}, (${value.abbr})`,
    optionDisplay: (value: Country) => `${value.name} (${value.abbr})`,
    optionDisabled: value => false,
    idAttributeName: 'id',
    processResponse: (response: ServerResponse<Country>) => response.results,
  },
  subdivision: {
    modelCls: Subdivision,
    serviceCls: SubdivisionService,
    filterParamsCls: SubdivisionRequestParams,
    method: 'list',
    display: (value: Subdivision) =>
      `${value.name} (${value.builder_org_info?.name})`,
    optionDisplay: (value: Subdivision) =>
      `${value.name} (${value.builder_org_info?.name})`,
    optionDisabled: value => false,
    idAttributeName: 'id',
    processResponse: (response: ServerResponse<Subdivision>) =>
      response.results,
  },
  us_state: {
    modelCls: USState,
    serviceCls: USStateService,
    filterParamsCls: USStateRequestParams,
    method: 'list',
    display: (value: USState) => `${value.name} (${value.abbr})`,
    optionDisplay: (value: USState) => `${value.name} (${value.abbr})`,
    optionDisabled: value => false,
    processResponse: (response: USState[]) => {
      return response;
    },
    idAttributeName: 'abbr',
  },
  company_access: {
    modelCls: CompanyAccess,
    serviceCls: CompanyAccessService,
    filterParamsCls: CompanyAccessRequestParams,
    method: 'list',
    display: (value: CompanyAccess) =>
      `${value.company_info.name} (${value.company_info?.city_info?.name}, ${value.company_info?.state})`,
    optionDisplay: (value: CompanyAccess) =>
      `${value.company_info.name} (${value.company_info?.city_info?.name}, ${value.company_info?.state})`,
    optionDisabled: value => false,
    idAttributeName: 'id',
    processResponse: (response: ServerResponse<CompanyAccess>) =>
      response.results,
  },
  hirl_green_energy_badge: {
    modelCls: HIRLGreenEnergyBadge,
    serviceCls: HIRLGreenEnergyBadgeService,
    filterParamsCls: HIRLGreenEnergyBadgeRequestParams,
    method: 'list',
    display: (value: HIRLGreenEnergyBadge) => `${value.name}`,
    optionDisplay: (value: HIRLGreenEnergyBadge) => `${value.name}`,
    optionDisabled: value => false,
    idAttributeName: 'id',
    processResponse: (response: ServerResponse<HIRLGreenEnergyBadge>) =>
      response.results,
  },
  scheduling_task: {
    modelCls: Task,
    serviceCls: TaskService,
    filterParamsCls: TaskRequestParams,
    method: 'list',
    display: (value: Task) => `${value.id}`,
    optionDisplay: (value: Task) => `${value.id}`,
    optionDisabled: value => false,
    idAttributeName: 'id',
    processResponse: (response: ServerResponse<Task>) => response.results,
  },
  scheduling_task_type: {
    modelCls: SchedulingTaskType,
    serviceCls: SchedulingTaskTypeService,
    filterParamsCls: SchedulingTaskTypeRequestParams,
    method: 'list',
    display: (value: SchedulingTaskType) => `${value.name}`,
    optionDisplay: (value: SchedulingTaskType) => `${value.name}`,
    optionDisabled: value => false,
    idAttributeName: 'id',
    processResponse: (response: ServerResponse<SchedulingTaskType>) =>
      response.results,
  },
  observation: {
    modelCls: Observation,
    serviceCls: ObservationService,
    filterParamsCls: ObservationRequestParams,
    method: 'list',
    display: (value: Observation) => `${value.observation_type_info?.name}`,
    optionDisplay: (value: Observation) =>
      `${value?.observation_type_info.name}`,
    optionDisabled: value => false,
    idAttributeName: 'id',
    processResponse: (response: ServerResponse<Observation>) =>
      response.results,
  },
  observation_type: {
    modelCls: ObservationType,
    serviceCls: ObservationTypeService,
    filterParamsCls: ObservationTypeRequestParams,
    method: 'list',
    display: (value: ObservationType) => `${value.name}`,
    optionDisplay: (value: ObservationType) => `${value.name}`,
    optionDisabled: value => false,
    idAttributeName: 'id',
    processResponse: (response: ServerResponse<ObservationType>) =>
      response.results,
  },
  hirl_project_registration: {
    modelCls: HIRLProjectRegistration,
    serviceCls: HIRLProjectRegistrationService,
    filterParamsCls: HIRLProjectRegistrationRequestParams,
    method: 'list',
    idAttributeName: 'id',
    display: (value: HIRLProjectRegistration) => `${value.id}`,
    optionDisplay: (value: HIRLProjectRegistration) => `${value.id}`,
    optionDisabled: (value: HIRLProjectRegistration) => false,
    processResponse: (response: ServerResponse<HIRLProjectRegistration>) =>
      response.results,
  },
  hirl_project: {
    modelCls: HIRLProject,
    serviceCls: HIRLProjectService,
    filterParamsCls: HIRLProjectRequestParams,
    method: 'list',
    idAttributeName: 'id',
    display: (value: HIRLProject) => `${value.id}`,
    optionDisplay: (value: HIRLProject) => `${value.id}`,
    optionDisabled: (value: HIRLProject) => false,
    processResponse: (response: ServerResponse<HIRLProject>) =>
      response.results,
  },
  customer_document: {
    modelCls: CustomerDocument,
    serviceCls: CustomerDocumentService,
    filterParamsCls: CustomerDocumentRequestParams,
    method: 'list',
    idAttributeName: 'id',
    display: (value: CustomerDocument) => `${value.id}`,
    optionDisplay: (value: CustomerDocument) => `${value.id}`,
    optionDisabled: (value: CustomerDocument) => false,
    processResponse: (response: ServerResponse<CustomerDocument>) =>
      response.results,
  },
  client_agreement: {
    modelCls: ClientAgreement,
    serviceCls: ClientAgreementService,
    filterParamsCls: ClientAgreementParams,
    method: 'list',
    idAttributeName: 'id',
    display: (value: ClientAgreement) => `${value.id}`,
    optionDisplay: (value: ClientAgreement) => `${value.id}`,
    optionDisabled: (value: ClientAgreement) => false,
    processResponse: (response: ServerResponse<ClientAgreement>) =>
      response.results,
  },
  simulation: {
    method: 'list',
    modelCls: 'SimulationBackendDict',
    serviceCls: SimulationService,
    filterParamsCls: ListRequestParams,
    display: value => `${value.name}`,
    optionDisplay: value => `${value.name}`,
    optionDisabled: value => false,
    idAttributeName: 'id',
    processResponse: (response: ServerResponse<SimulationBackendDict>) =>
      response.results,
  },
  above_grade_wall: {
    method: 'list',
    modelCls: 'AboveGradeWallBackendDict',
    serviceCls: AboveGradeWallService,
    filterParamsCls: ListRequestParams,
    display: value => `${value.name}`,
    optionDisplay: value => `${value.name}`,
    optionDisabled: value => false,
    idAttributeName: 'id',
    processResponse: (response: ServerResponse<AboveGradeWallBackendDict>) =>
      response.results,
  },
  above_grade_wall_type: {
    method: 'list',
    modelCls: 'AboveGradeWallTypeBackendDict',
    serviceCls: AboveGradeWallTypeService,
    filterParamsCls: AboveGradeWallTypeParams,
    display: value => `${value.name}`,
    optionDisplay: value => `${value.name}`,
    optionDisabled: value => false,
    idAttributeName: 'id',
    processResponse: (
      response: ServerResponse<AboveGradeWallTypeBackendDict>
    ) => response.results,
  },
  foundation_wall: {
    method: 'list',
    modelCls: 'FoundationWallBackendDict',
    serviceCls: FoundationWallService,
    filterParamsCls: ListRequestParams,
    idAttributeName: 'id',
    display: (value: FoundationWallBackendDict) => `${value.name}`,
    optionDisplay: (value: FoundationWallBackendDict) => `${value.name}`,
    optionDisabled: (value: FoundationWallBackendDict) => false,
    processResponse: (
      response: ServerResponse<FoundationWallTypeBackendDict>
    ) => response.results,
  },
  foundation_wall_type: {
    method: 'list',
    modelCls: 'FoundationWallTypeBackendDict',
    serviceCls: FoundationWallTypeService,
    filterParamsCls: FoundationWallTypeParams,
    idAttributeName: 'id',
    display: (value: FoundationWallTypeBackendDict) => `${value.name}`,
    optionDisplay: (value: FoundationWallTypeBackendDict) => `${value.name}`,
    optionDisabled: (value: FoundationWallTypeBackendDict) => false,
    processResponse: (
      response: ServerResponse<FoundationWallTypeBackendDict>
    ) => response.results,
  },
  roof: {
    method: 'list',
    modelCls: 'RoofBackendDict',
    serviceCls: RoofService,
    filterParamsCls: ListRequestParams,
    idAttributeName: 'id',
    display: (value: RoofBackendDict) => `${value.name}`,
    optionDisplay: (value: RoofBackendDict) => `${value.name}`,
    optionDisabled: (value: RoofBackendDict) => false,
    processResponse: (response: ServerResponse<RoofTypeBackendDict>) =>
      response.results,
  },
  roof_type: {
    method: 'list',
    modelCls: 'RoofTypeBackendDict',
    serviceCls: RoofTypeService,
    filterParamsCls: RoofTypeParams,
    idAttributeName: 'id',
    display: (value: RoofTypeBackendDict) => `${value.name}`,
    optionDisplay: (value: RoofTypeBackendDict) => `${value.name}`,
    optionDisabled: (value: RoofTypeBackendDict) => false,
    processResponse: (response: ServerResponse<RoofTypeBackendDict>) =>
      response.results,
  },
  frame_floor: {
    method: 'list',
    modelCls: 'FrameFloorBackendDict',
    serviceCls: FrameFloorService,
    filterParamsCls: ListRequestParams,
    idAttributeName: 'id',
    display: (value: FrameFloorBackendDict) => `${value.name}`,
    optionDisplay: (value: FrameFloorBackendDict) => `${value.name}`,
    optionDisabled: (value: FrameFloorBackendDict) => false,
    processResponse: (response: ServerResponse<RoofTypeBackendDict>) =>
      response.results,
  },
  frame_floor_type: {
    method: 'list',
    modelCls: 'FrameFloorTypeBackendDict',
    serviceCls: FrameFloorTypeService,
    filterParamsCls: FrameFloorTypeParams,
    idAttributeName: 'id',
    display: (value: FrameFloorTypeBackendDict) => `${value.name}`,
    optionDisplay: (value: FrameFloorTypeBackendDict) => `${value.name}`,
    optionDisabled: (value: FrameFloorTypeBackendDict) => false,
    processResponse: (response: ServerResponse<RoofTypeBackendDict>) =>
      response.results,
  },
  slab: {
    method: 'list',
    modelCls: 'SlabBackendDict',
    serviceCls: SlabService,
    filterParamsCls: ListRequestParams,
    idAttributeName: 'id',
    display: (value: SlabBackendDict) => `${value.name}`,
    optionDisplay: (value: SlabBackendDict) => `${value.name}`,
    optionDisabled: (value: SlabBackendDict) => false,
    processResponse: (response: ServerResponse<SlabTypeBackendDict>) =>
      response.results,
  },
  slab_type: {
    method: 'list',
    modelCls: 'SlabTypeBackendDict',
    serviceCls: SlabTypeService,
    filterParamsCls: SlabTypeParams,
    idAttributeName: 'id',
    display: (value: SlabTypeBackendDict) => `${value.name}`,
    optionDisplay: (value: SlabTypeBackendDict) => `${value.name}`,
    optionDisabled: (value: SlabTypeBackendDict) => false,
    processResponse: (response: ServerResponse<SlabTypeBackendDict>) =>
      response.results,
  },
  skylight: {
    method: 'list',
    modelCls: 'SkylightBackendDict',
    serviceCls: SkylightService,
    filterParamsCls: ListRequestParams,
    idAttributeName: 'id',
    display: (value: SkylightBackendDict) => `${value.name}`,
    optionDisplay: (value: SkylightBackendDict) => `${value.name}`,
    optionDisabled: (value: SkylightBackendDict) => false,
    processResponse: (response: ServerResponse<SkylightTypeBackendDict>) =>
      response.results,
  },
  skylight_type: {
    method: 'list',
    modelCls: 'SkylightTypeBackendDict',
    serviceCls: SkylightTypeService,
    filterParamsCls: SkylightTypeParams,
    idAttributeName: 'id',
    display: (value: SkylightTypeBackendDict) => `${value.name}`,
    optionDisplay: (value: SkylightTypeBackendDict) => `${value.name}`,
    optionDisabled: (value: SkylightTypeBackendDict) => false,
    processResponse: (response: ServerResponse<SkylightTypeBackendDict>) =>
      response.results,
  },
  rim_joist: {
    method: 'list',
    modelCls: 'RimJoistBackendDict',
    serviceCls: RimJoistService,
    filterParamsCls: ListRequestParams,
    idAttributeName: 'id',
    display: (value: RimJoistBackendDict) => `${value.name}`,
    optionDisplay: (value: RimJoistBackendDict) => `${value.name}`,
    optionDisabled: (value: RimJoistBackendDict) => false,
    processResponse: (response: ServerResponse<RimJoistBackendDict>) =>
      response.results,
  },
  rim_joist_type: {
    method: 'list',
    modelCls: 'RimJoistTypeBackendDict',
    serviceCls: RimJoistTypeService,
    filterParamsCls: RimJoistTypeParams,
    idAttributeName: 'id',
    display: (value: RimJoistTypeBackendDict) => `${value.name}`,
    optionDisplay: (value: RimJoistTypeBackendDict) => `${value.name}`,
    optionDisabled: (value: RimJoistTypeBackendDict) => false,
    processResponse: (response: ServerResponse<RimJoistTypeBackendDict>) =>
      response.results,
  },
  door: {
    method: 'list',
    modelCls: 'DoorBackendDict',
    serviceCls: DoorService,
    filterParamsCls: ListRequestParams,
    idAttributeName: 'id',
    display: (value: DoorBackendDict) => `${value.name}`,
    optionDisplay: (value: DoorBackendDict) => `${value.name}`,
    optionDisabled: (value: DoorBackendDict) => false,
    processResponse: (response: ServerResponse<DoorBackendDict>) =>
      response.results,
  },
  door_type: {
    method: 'list',
    modelCls: 'DoorTypeBackendDict',
    serviceCls: DoorTypeService,
    filterParamsCls: DoorTypeParams,
    idAttributeName: 'id',
    display: (value: DoorTypeBackendDict) => `${value.name}`,
    optionDisplay: (value: DoorTypeBackendDict) => `${value.name}`,
    optionDisabled: (value: DoorTypeBackendDict) => false,
    processResponse: (response: ServerResponse<DoorTypeBackendDict>) =>
      response.results,
  },
  window: {
    method: 'list',
    modelCls: 'WindowBackendDict',
    serviceCls: WindowService,
    filterParamsCls: ListRequestParams,
    idAttributeName: 'id',
    display: (value: WindowBackendDict) => `${value.name}`,
    optionDisplay: (value: WindowBackendDict) => `${value.name}`,
    optionDisabled: (value: WindowBackendDict) => false,
    processResponse: (response: ServerResponse<WindowBackendDict>) =>
      response.results,
  },
  window_type: {
    method: 'list',
    modelCls: 'WindowTypeBackendDict',
    serviceCls: WindowTypeService,
    filterParamsCls: WindowTypeParams,
    idAttributeName: 'id',
    display: (value: WindowTypeBackendDict) => `${value.name}`,
    optionDisplay: (value: WindowTypeBackendDict) => `${value.name}`,
    optionDisabled: (value: WindowTypeBackendDict) => false,
    processResponse: (response: ServerResponse<WindowTypeBackendDict>) =>
      response.results,
  },

  air_conditioner: {
    method: 'list',
    modelCls: 'DoorTypeBackendDict',
    serviceCls: AirConditionerService,
    filterParamsCls: ListRequestParams,
    idAttributeName: 'id',
    display: (value: AirConditionerBackendDict) => `${value.name}`,
    optionDisplay: (value: AirConditionerBackendDict) => `${value.name}`,
    optionDisabled: (value: AirConditionerBackendDict) => false,
    processResponse: (response: ServerResponse<AirConditionerBackendDict>) =>
      response.results,
  },
  air_source_heat_pump: {
    method: 'list',
    modelCls: 'DoorTypeBackendDict',
    serviceCls: AirSourceHeatPumpService,
    filterParamsCls: ListRequestParams,
    idAttributeName: 'id',
    display: (value: AirSourceHeatPumpBackendDict) => `${value.name}`,
    optionDisplay: (value: AirSourceHeatPumpBackendDict) => `${value.name}`,
    optionDisabled: (value: AirSourceHeatPumpBackendDict) => false,
    processResponse: (response: ServerResponse<AirSourceHeatPumpBackendDict>) =>
      response.results,
  },
  dehumidifier: {
    method: 'list',
    modelCls: 'DehumidifierBackendDict',
    idAttributeName: 'id',
    serviceCls: DehumidifierService,
    filterParamsCls: ListRequestParams,
    display: (value: DehumidifierBackendDict) => `${value.name}`,
    optionDisplay: (value: DehumidifierBackendDict) => `${value.name}`,
    optionDisabled: (value: DehumidifierBackendDict) => false,
    processResponse: (response: ServerResponse<DehumidifierBackendDict>) =>
      response.results,
  },

  ground_source_heat_pump: {
    method: 'list',
    modelCls: 'GroundSourceHeatPumpBackendDict',
    idAttributeName: 'id',
    serviceCls: GroundSourceHeatPumpService,
    filterParamsCls: ListRequestParams,
    display: (value: GroundSourceHeatPumpBackendDict) => `${value.name}`,
    optionDisplay: (value: GroundSourceHeatPumpBackendDict) => `${value.name}`,
    optionDisabled: (value: GroundSourceHeatPumpBackendDict) => false,
    processResponse: (
      response: ServerResponse<GroundSourceHeatPumpBackendDict>
    ) => response.results,
  },
  heater: {
    method: 'list',
    modelCls: 'HeaterBackendDict',
    idAttributeName: 'id',
    serviceCls: HeaterService,
    filterParamsCls: ListRequestParams,
    display: (value: HeaterBackendDict) => `${value.name}`,
    optionDisplay: (value: HeaterBackendDict) => `${value.name}`,
    optionDisabled: (value: HeaterBackendDict) => false,
    processResponse: (response: ServerResponse<HeaterBackendDict>) =>
      response.results,
  },
  water_heater: {
    method: 'list',
    modelCls: 'WaterHeaterService',
    idAttributeName: 'id',
    serviceCls: WaterHeaterService,
    filterParamsCls: ListRequestParams,
    display: (value: WaterHeaterBackendDict) => `${value.name}`,
    optionDisplay: (value: WaterHeaterBackendDict) => `${value.name}`,
    optionDisabled: (value: WaterHeaterBackendDict) => false,
    processResponse: (response: ServerResponse<WaterHeaterBackendDict>) =>
      response.results,
  },
  utility_rate: {
    method: 'list',
    serviceCls: UtilityRateService,
    modelCls: 'UtilityRateBackendDict',
    idAttributeName: 'id',
    filterParamsCls: ListRequestParams,
    display: (value: UtilityRateBackendDict) => `${value.name}`,
    optionDisplay: (value: UtilityRateBackendDict) => `${value.name}`,
    optionDisabled: (value: UtilityRateBackendDict) => false,
    processResponse: (response: ServerResponse<WaterHeaterBackendDict>) =>
      response.results,
  },
  project: {
    method: 'list',
    serviceCls: ProjectService,
    modelCls: 'ProjectBackendDict',
    idAttributeName: 'id',
    filterParamsCls: ListRequestParams,
    display: (value: ProjectBackendDict) => `${value.model_name}`,
    optionDisplay: (value: ProjectBackendDict) => `${value.model_name}`,
    optionDisabled: (value: ProjectBackendDict) => false,
    processResponse: (response: ServerResponse<ProjectBackendDict>) =>
      response.results,
  },
  location: {
    method: 'list',
    serviceCls: LocationService,
    modelCls: 'LocationBackendDict',
    idAttributeName: 'id',
    filterParamsCls: ListRequestParams,
    display: (value: LocationBackendDict) => `${value.id}`,
    optionDisplay: (value: LocationBackendDict) => `${value.id}`,
    optionDisabled: (value: LocationBackendDict) => false,
    processResponse: (response: ServerResponse<LocationBackendDict>) =>
      response.results,
  },
  weather: {
    method: 'get_local_weathers',
    serviceCls: LocationService,
    modelCls: 'WeatherBackendDict',
    idAttributeName: 'id',
    filterParamsCls: ListRequestParams,
    display: (value: WeatherBackendDict) => `${value.pretty_label}`,
    optionDisplay: (value: WeatherBackendDict) => `${value.pretty_label}`,
    optionDisabled: (value: WeatherBackendDict) => false,
    processResponse: (response: ServerResponse<WaterHeaterBackendDict>) =>
      response.results,
  },
  brand: {
    method: 'list',
    serviceCls: BrandService,
    modelCls: Brand,
    idAttributeName: 'id',
    filterParamsCls: BrandRequestParams,
    display: (value: Brand) => `${value.title}`,
    optionDisplay: (value: Brand) => `${value.title}`,
    optionDisabled: (value: Brand) => false,
    processResponse: (response: ServerResponse<Brand>) => response.results,
  },
  company_brand: {
    method: 'list',
    serviceCls: CompanyBrandService,
    modelCls: CompanyBrand,
    idAttributeName: 'id',
    filterParamsCls: CompanyBrandRequestParams,
    display: (value: CompanyBrand) => `${value.title}`,
    optionDisplay: (value: CompanyBrand) => `${value.title}`,
    optionDisabled: (value: CompanyBrand) => false,
    processResponse: (response: ServerResponse<CompanyBrand>) =>
      response.results,
  },
  category: {
    method: 'list',
    serviceCls: CategoryService,
    modelCls: Category,
    idAttributeName: 'id',
    filterParamsCls: CategoryRequestParams,
    display: (value: Category) => `${value.title}`,
    optionDisplay: (value: Category) => `${value.title}`,
    optionDisabled: (value: Category) => false,
    processResponse: (response: ServerResponse<Category>) => response.results,
  },
  product: {
    method: 'list',
    serviceCls: ProductService,
    modelCls: ProductList,
    idAttributeName: 'id',
    filterParamsCls: ProductRequestParams,
    display: (value: ProductList) => `${value.title}`,
    optionDisplay: (value: ProductList) => `${value.title}`,
    optionDisabled: (value: ProductList) => false,
    processResponse: (response: ServerResponse<ProductList>) =>
      response.results,
  },
  practice_standard: {
    method: 'list',
    serviceCls: PracticeStandardService,
    modelCls: PracticeStandard,
    idAttributeName: 'id',
    filterParamsCls: PracticeStandardRequestParams,
    display: (value: PracticeStandard) => `${value?.title}`,
    optionDisplay: (value: PracticeStandard) => `${value?.title}`,
    optionDisabled: (value: PracticeStandard) => false,
    processResponse: (response: ServerResponse<PracticeStandard>) =>
      response.results,
  },
  practice_chapter: {
    method: 'list',
    serviceCls: PracticeChapterService,
    modelCls: PracticeChapter,
    idAttributeName: 'id',
    filterParamsCls: PracticeChapterRequestParams,
    display: (value: PracticeChapter) => `${value?.title}`,
    optionDisplay: (value: PracticeChapter) => `${value?.title}`,
    optionDisabled: (value: PracticeChapter) => false,
    processResponse: (response: ServerResponse<PracticeChapter>) =>
      response.results,
  },
  practice_section: {
    method: 'list',
    serviceCls: PracticeSectionService,
    modelCls: PracticeSection,
    idAttributeName: 'id',
    filterParamsCls: PracticeSectionRequestParams,
    display: (value: PracticeSection) => `${value?.title}`,
    optionDisplay: (value: PracticeSection) => `${value?.title}`,
    optionDisabled: (value: PracticeSection) => false,
    processResponse: (response: ServerResponse<PracticeSection>) =>
      response.results,
  },
  practice: {
    method: 'list',
    serviceCls: PracticeService,
    modelCls: Practice,
    idAttributeName: 'id',
    filterParamsCls: PracticeRequestParams,
    display: (value: Practice) => `${value?.title}`,
    optionDisplay: (value: Practice) => `${value?.title}`,
    optionDisabled: (value: Practice) => false,
    processResponse: (response: ServerResponse<Practice>) => response.results,
  },
  practice_item: {
    method: 'list',
    serviceCls: PracticeItemService,
    modelCls: PracticeItem,
    idAttributeName: 'id',
    filterParamsCls: PracticeItemRequestParams,
    display: (value: PracticeItem) => `${value?.item_number} - ${value?.text}`,
    optionDisplay: (value: PracticeItem) =>
      `${value?.item_number} - ${value?.text}`,
    optionDisabled: (value: PracticeItem) => false,
    processResponse: (response: ServerResponse<PracticeItem>) =>
      response.results,
  },
  product_practice_item: {
    method: 'list',
    serviceCls: ProductPracticeItemService,
    modelCls: ProductPracticeItem,
    idAttributeName: 'id',
    filterParamsCls: ProductPracticeItemRequestParams,
    display: (value: ProductPracticeItem) => `${value?.item_info.text}`,
    optionDisplay: (value: ProductPracticeItem) => `${value?.item_info.text}`,
    optionDisabled: (value: ProductPracticeItem) => false,
    processResponse: (response: ServerResponse<ProductPracticeItem>) =>
      response.results,
  },
  gcp_payment_request: {
    method: 'list',
    serviceCls: PaymentRequestService,
    modelCls: PaymentRequest,
    idAttributeName: 'id',
    filterParamsCls: PaymentRequestRequestParams,
    display: (value: PaymentRequest) => `Payment Request ${value?.id}`,
    optionDisplay: (value: PaymentRequest) => `Payment Request ${value?.id}`,
    optionDisabled: (value: PaymentRequest) => false,
    processResponse: (response: ServerResponse<PaymentRequest>) =>
      response.results,
  },
  hirl_product_agreement: {
    method: 'list',
    serviceCls: HIRLProductAgreementService,
    modelCls: HIRLProductAgreement,
    idAttributeName: 'id',
    filterParamsCls: HIRLProductAgreementRequestParams,
    display: (value: HIRLProductAgreement) => `Product Agreement ${value?.id}`,
    optionDisplay: (value: HIRLProductAgreement) =>
      `Product Agreement ${value?.id}`,
    optionDisabled: (value: HIRLProductAgreement) => false,
    processResponse: (response: ServerResponse<HIRLProductAgreement>) =>
      response.results,
  },
  invoice: {
    method: 'list',
    serviceCls: InvoiceService,
    modelCls: Invoice,
    idAttributeName: 'prefixed_id',
    filterParamsCls: InvoiceRequestParams,
    display: (value: Invoice) => `Invoice ${value?.prefixed_id}`,
    optionDisplay: (value: Invoice) => `Invoice ${value?.prefixed_id}`,
    optionDisabled: (value: Invoice) => false,
    processResponse: (response: ServerResponse<Invoice>) => response.results,
  },
  coi_document: {
    method: 'list',
    serviceCls: COIDocumentService,
    modelCls: COIDocument,
    idAttributeName: 'id',
    filterParamsCls: COIDocumentRequestParams,
    display: (value: COIDocument) => `COIDocument ${value?.id}`,
    optionDisplay: (value: COIDocument) => `COIDocument ${value?.id}`,
    optionDisabled: (value: COIDocument) => false,
    processResponse: (response: ServerResponse<COIDocument>) =>
      response.results,
  },
};
