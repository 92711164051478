import { SimulationConfig } from '@/data/simulation/models/SimulationConfig';
import { createAction, props } from '@ngrx/store';
import { StateErrors } from '../shared/base.state';

export const loadSimulationConfig = createAction(
  '[SimulationConfig] Load SimulationConfig',
  props<{ simulationConfig: SimulationConfig }>()
);

export const loadSimulationConfigSuccess = createAction(
  '[SimulationConfig] Load SimulationConfig Success',
  props<{
    simulationConfig: SimulationConfig;
    errors: StateErrors<SimulationConfig>;
  }>()
);

export const loadSimulationConfigFailure = createAction(
  '[SimulationConfig] Load SimulationConfig Failure',
  props<{ error: any }>()
);

export const updateSimulationConfig = createAction(
  '[SimulationConfig] Update SimulationConfig',
  props<{ id: number; simulationConfigChanges: Partial<SimulationConfig> }>()
);

export const updateSimulationConfigFailure = createAction(
  '[SimulationConfig API] Update SimulationConfig Failure',
  props<{ id: number }>()
);
