<div class="container" *ngIf="initialized">
  <div class="row">
    <div class="col-12">
      <h3>
        <button mat-raised-button color="accent" appBackButton [fallbackUrl]="'/home/apex/request/resnet-qa-list'">
          <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
        </button>
        APEX RESNET QA Request
      </h3>
    </div>
  </div>
  <form [formGroup]="formGroup">
    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Section</mat-label>
          <mat-select formControlName="resnet_qa_type">
            <mat-option [value]="null">Undefined</mat-option>
            <mat-option *ngFor="let item of RESENTQATypeLabelMapping | defaultOrderKeyValue"
                        [value]="item.key">{{ item.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <ngx-dropzone (change)="onSelect($event)" [accept]="'.xml,'">
          <ngx-dropzone-label>
            Drag & Drop XML Here
          </ngx-dropzone-label>
          <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
            <ngx-dropzone-label>{{ f.name | slice :0: 20 }} ({{ f.type | slice :0: 20 }})</ngx-dropzone-label>
          </ngx-dropzone-preview>
        </ngx-dropzone>
      </div>
    </div>
  </form>
  <div class="row mt-2">
    <div class="col-12 text-center">
      <button mat-raised-button color="accent" (click)="onSubmit($event)">
        Submit
      </button>
    </div>
  </div>
</div>
