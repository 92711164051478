import { AuthGuard } from '@/core/guards';
import { ProjectTrackerListComponentComponent } from './components/project-tracker-list-component/project-tracker-list-component.component';

export const CustomerETORouting = [
  {
    path: 'project_tracker_submissions',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ProjectTrackerListComponentComponent,
        resolve: {},
      },
    ],
  },
];
