import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '@/data/core/models/user';
import { forkJoin, of, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { CompanyService } from '@/data/company/services/company-base.service';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { getInfoUser } from '@/state/selectors/info.selector';
import { defaultIfEmpty, first, switchMap, takeUntil } from 'rxjs/operators';
import { toggleLoading } from '@/state/actions/app.actions';
import { formatDate } from '@angular/common';
import { SeedCreateBackendDict } from '@/data/simulation/models';
import { SeedService } from '@/data/simulation/services/seed.service';
import { CustomerDocument } from '@/data/filehandling/models';
import { CustomerDocumentService } from '@/data/filehandling/services/customer-document.service';
import {
  SimulationBackendDict,
  SimulationSourceType,
} from '@/data/simulation/models/simulation';
import { conditionalValidator } from '@/core/validators';
import { SimulationService } from '@/data/simulation/services/simulation.service';

export class SeedCreateDialogData {
  seed?: Partial<SeedCreateBackendDict>;
}

@Component({
  selector: 'app-seed-create-dialog',
  templateUrl: './seed-create-dialog.component.html',
  styleUrls: ['./seed-create-dialog.component.scss'],
})
export class SeedCreateDialogComponent implements OnInit, OnDestroy {
  protected readonly SimulationSourceType = SimulationSourceType;
  protected readonly sourceTypeLabelMapping = {
    [SimulationSourceType.axis]: 'APEX®',
    [SimulationSourceType.remrateXML]: 'REM/Rate XML',
    [SimulationSourceType.remrateBLG]: 'REM/Rate BLG',
    [SimulationSourceType.hpxml]: 'HPXML',
  };

  public initialized: boolean;
  public entity?: Partial<SeedCreateBackendDict>;
  public retrieve = true;
  public form: FormGroup;
  public currentUser: User;

  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<SeedCreateDialogData>,
    private fb: FormBuilder,
    private companyService: CompanyService,
    private seedService: SeedService,
    private simulationService: SimulationService,
    private customerDocumentService: CustomerDocumentService,
    private dialog: MatDialog,
    private uiHelperService: UIHelperService,
    @Inject(MAT_DIALOG_DATA) public data: SeedCreateDialogData
  ) {
    if (data?.seed) {
      this.entity = Object.assign({}, data?.seed);
    }
  }

  ngOnInit() {
    const sources = {
      currentUser: this.store.select(getInfoUser).pipe(first()),
      seed: of(this.entity),
    };

    this.store.dispatch(toggleLoading({ payload: true }));

    forkJoin(sources)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: ({ currentUser, entity }) => {
          this.store.dispatch(toggleLoading({ payload: false }));

          this.currentUser = currentUser;
          this.entity = entity;
          this.setupForm();
          this.initialized = true;
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  setupForm() {
    this.form = this.fb.group({
      document: [
        null,
        [
          conditionalValidator(
            () =>
              this.form.get('source_type').value &&
              this.form.get('source_type').value !== SimulationSourceType.axis,
            Validators.required
          ),
        ],
      ],
      name: [
        null,
        [
          conditionalValidator(
            () =>
              this.form.get('source_type').value &&
              this.form.get('source_type').value === SimulationSourceType.axis,
            Validators.required
          ),
        ],
      ],
      source_type: [null, Validators.required],
      source_date: [
        null,
        [
          conditionalValidator(
            () =>
              this.form.get('source_type').value &&
              this.form.get('source_type').value ===
                SimulationSourceType.ekotrope,
            Validators.required
          ),
        ],
      ],
    });

    this.form
      .get('source_type')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe(value => {
        this.form.get('name').patchValue(null);
        this.form.get('name').updateValueAndValidity();
        this.form.get('source_date').patchValue(null);
        this.form.get('source_date').updateValueAndValidity();
      });

    this.form.patchValue(Object.assign({}, this.entity), {
      emitEvent: false,
      onlySelf: true,
    });
  }

  save($event: MouseEvent) {
    $event.preventDefault();
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    if (this.form.value.source_type === SimulationSourceType.axis) {
      const simulation = new SimulationBackendDict();
      simulation.source_type = this.form.value.source_type;
      simulation.name = this.form.value.name;
      this.simulationService
        .create(simulation)
        .pipe(takeUntil(this.componentDestroyed$), first())
        .subscribe({
          next: simulation => {
            this.store.dispatch(toggleLoading({ payload: false }));
            this.dialogRef.close(simulation);
          },
          error: error => this.uiHelperService.handleUserRequestError(error),
        });
    } else {
      const customerDocumentData = new CustomerDocument();

      this.companyService
        .documentsCreate(this.currentUser.company, customerDocumentData)
        .pipe(
          takeUntil(this.componentDestroyed$),
          first(),
          // update file fields
          switchMap(customerDocument => {
            const formData = new FormData();
            formData.append('document', this.form.value.document);
            return this.customerDocumentService.update(
              customerDocument.id,
              formData
            );
          }),
          defaultIfEmpty(null),
          switchMap(customerDocument => {
            const sourceDate = `${formatDate(
              this.form.value.source_date || new Date(),
              'yyyy-MM-dd',
              'en-US'
            )}`;
            return this.seedService.create({
              source_type: this.form.value.source_type,
              source_date: sourceDate,
              document: customerDocument.id,
            });
          })
        )
        .subscribe({
          next: seed => {
            this.store.dispatch(toggleLoading({ payload: false }));
            this.dialogRef.close(seed);
          },
          error: error => this.uiHelperService.handleUserRequestError(error),
        });
    }
  }
}
