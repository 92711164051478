import { AuthGuard } from '@/core/guards';
import { SchedulingListComponent } from '@/modules/scheduling/pages/scheduling-list/scheduling-list.component';

export const SchedulingRouting = [
  {
    path: 'scheduling',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'tasks',
        component: SchedulingListComponent,
      },
    ],
  },
];
