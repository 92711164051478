import {
  CoolingEfficiencyUnitLabels,
  FuelTypeLabels,
  HeatingCoolingCapacityUnitLabels,
  HeatingEfficiencyUnitLabels,
  MechanicalMotorTypeLabels,
} from '@/data/simulation/enumerations';
import { DetailedMechanicalEquipmentBackendDict } from '@/data/simulation/models/mechanicals/MechanicalEquipment';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-equipment-ground-source-heat-pump',
  templateUrl: './equipment-ground-source-heat-pump.component.html',
  styleUrls: ['./equipment-ground-source-heat-pump.component.scss'],
})
export class EquipmentGroundSourceHeatPumpComponent implements OnInit {
  @Input() equipment: DetailedMechanicalEquipmentBackendDict;

  FuelTypeLabels = FuelTypeLabels;
  CapacityUnitLabels = HeatingCoolingCapacityUnitLabels;

  CoolingEfficiencyUnitLabels = CoolingEfficiencyUnitLabels;
  HeatingEfficiencyUnitLabels = HeatingEfficiencyUnitLabels;
  MechanicalMotorTypeLabels = MechanicalMotorTypeLabels;

  ngOnInit(): void {}
}
