import { Component } from '@angular/core';
import {
  ProjectCertification,
  ProviderDashboardList,
  ProviderDashboardProgramsReadyForCertification,
} from '@/data/home/models';
import { PsrListComponent } from '@/modules/home/pages/psr-list/psr-list.component';
import {
  EEPProgramHomeStatusRequestParams,
  EEPProgramHomeStatusService,
} from '@/data/home/services/eep_program_home_status.service';
import { Subscription } from 'rxjs';
import { first, take, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { NavigationService } from '@/shared/services/navigation-service.service';
import { EEPProgramService } from '@/data/eep-program/services/eep-program.service';
import { EEPProgram } from '@/data/eep-program/models';
import { EEPProgramHomeStatusState } from '@/data/home/models/eep_program_home_status';
import { toggleLoading } from '@/state/actions/app.actions';

import { AsynchronousProcessedDocument } from '@/data/filehandling/models';
import {
  AsynchronousProcessedDocumentStatusDialogComponent,
  AsynchronousProcessedDocumentStatusDialogData,
} from '@/modules/filehandling/components/asynchronous-processed-document-status-dialog/asynchronous-processed-document-status-dialog.component';
import {
  CertifyDialogComponent,
  CertifyDialogData,
} from '@/modules/home/components/certify-dialog/certify-dialog.component';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-provider-certification-dashboard-list',
  templateUrl: './provider-certification-dashboard-list.component.html',
  styleUrls: ['./provider-certification-dashboard-list.component.scss'],
})
export class ProviderCertificationDashboardListComponent extends PsrListComponent<ProviderDashboardList> {
  public displayedColumns = [
    'select',
    'home__street_line1',
    'home__subdivision__name',
    'floorplan__name',
    'eep_program__name',
    'state',
  ];

  public getDataSource = (params: EEPProgramHomeStatusRequestParams) =>
    this.eepProgramHomeStatusService.provider_dashboard_certification_list(
      params
    );

  public eepProgramsReadyForCertificationIsLoading = true;
  public eepProgramsReadyForCertification: ProviderDashboardProgramsReadyForCertification[];
  public eepProgramsReadyForCertificationSubscription$: Subscription;

  public quickFilterIsLoading = false;

  constructor(
    public store: Store<AppState>,
    public eepProgramHomeStatusService: EEPProgramHomeStatusService,
    public router: Router,
    public dialog: MatDialog,
    public activatedRoute: ActivatedRoute,
    public uiHelperService: UIHelperService,
    public eepProgramService: EEPProgramService,
    public navigationService: NavigationService
  ) {
    super(
      store,
      eepProgramHomeStatusService,
      router,
      dialog,
      activatedRoute,
      uiHelperService,
      navigationService
    );

    this.defaultParams = new EEPProgramHomeStatusRequestParams(
      1,
      '',
      '-created_date',
      25
    );
    this.defaultParams.state = [EEPProgramHomeStatusState.certificationPending];
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();

    if (this.eepProgramsReadyForCertificationSubscription$) {
      this.eepProgramsReadyForCertificationSubscription$.unsubscribe();
    }
  }

  onListChangeStarted() {
    if (this.eepProgramsReadyForCertificationSubscription$) {
      this.eepProgramsReadyForCertificationSubscription$.unsubscribe();
    }

    const params = new EEPProgramHomeStatusRequestParams();
    params.company_access = this.storedParams?.company_access;
    this.eepProgramsReadyForCertificationSubscription$ =
      this.eepProgramHomeStatusService
        .provider_dashboard_programs_ready_for_certification(params)
        .pipe(takeUntil(this.componentDestroyed$), take(1))
        .subscribe(eepProgramsReadyForCertification => {
          this.eepProgramsReadyForCertification =
            eepProgramsReadyForCertification;
          this.eepProgramsReadyForCertificationIsLoading = false;
        });
  }

  public setupInitialFilters() {
    this.filterComponent.fieldPermissions.state = false;

    this.storedParams.state = [EEPProgramHomeStatusState.certificationPending];
    this.filterComponent.filterFromGroup.patchValue(this.storedParams, {
      emitEvent: true,
      onlySelf: true,
    });
  }

  onCertify($event: MouseEvent) {
    $event.preventDefault();

    const certifyDialogRef = this.dialog.open(CertifyDialogComponent, {
      width: '50%',
      data: {
        statuses: this.tableComponent.selectedEntities?.toArray(),
        selectAll: this.tableComponent.selectAllEntities,
      } as CertifyDialogData,
    });

    certifyDialogRef
      .afterClosed()
      .subscribe((projectCertification?: ProjectCertification) => {
        if (!projectCertification) {
          return;
        }
        this.store.dispatch(toggleLoading({ payload: true }));

        this.eepProgramHomeStatusService
          .certify(projectCertification, this.storedParams)
          .pipe(first())
          .subscribe(
            (asynchronousProcessedDocument: AsynchronousProcessedDocument) => {
              this.store.dispatch(toggleLoading({ payload: false }));

              const asynchronousProcessedDocumentDialogRef = this.dialog.open(
                AsynchronousProcessedDocumentStatusDialogComponent,
                {
                  width: '50%',
                  data: {
                    title: 'Certifying Project(s)',
                    entity: asynchronousProcessedDocument,
                  } as AsynchronousProcessedDocumentStatusDialogData,
                }
              );
              asynchronousProcessedDocumentDialogRef
                .afterClosed()
                .subscribe(_ => {
                  this.tableComponent?.clearSelection();
                  this.tableComponent?.list();
                });
            },
            error => this.uiHelperService.handleUserRequestError(error)
          );
      });
  }

  onQuickFilterClicked(
    $event: MouseEvent,
    eepProgramReadyForCertification: ProviderDashboardProgramsReadyForCertification
  ) {
    $event.preventDefault();
    this.quickFilterIsLoading = true;
    this.eepProgramService
      .retrieve(eepProgramReadyForCertification.id)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe((eepProgram: EEPProgram) => {
        this.quickFilterIsLoading = false;
        const companyAccesses =
          this.filterComponent.filterFromGroup.value.company_access_info;

        this.filterComponent.filterFromGroup.reset();
        this.filterComponent.filterFromGroup.patchValue({
          company_access_info: companyAccesses,
          eep_program_info: [eepProgram],
          state: [EEPProgramHomeStatusState.certificationPending],
        });
      });
  }

  onFormReset($event: FormGroup) {
    this.setupInitialFilters();
  }
}
