import * as moment from 'moment';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  AttendanceTypeLabelMapping,
  Training,
  TrainingTypeLabelMapping,
} from '@/data/user-management/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '@/data/core/models/user';
import { forkJoin, of, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { AppFieldValidatorsService } from '@/core/services/field-validators.service';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, switchMap, takeUntil } from 'rxjs/operators';
import { toggleLoading } from '@/state/actions/app.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { TrainingService } from '@/data/user-management/services/training.service';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';

export class TrainingChangeDialogData {
  entity?: Training | number;
  commit = true;
  retrieve = true;
}

@Component({
  selector: 'app-training-change-dialog',
  templateUrl: './training-change-dialog.component.html',
  styleUrls: ['./training-change-dialog.component.scss'],
})
export class TrainingChangeDialogComponent implements OnInit, OnDestroy {
  protected readonly TrainingTypeLabelMapping = TrainingTypeLabelMapping;
  protected readonly AttendanceTypeLabelMapping = AttendanceTypeLabelMapping;

  public initialized: boolean;
  public isEditForm = false;
  public entity?: Training;
  private entityId?: number;

  public commit = true;
  public retrieve = true;
  public form: FormGroup;
  public currentUser: User;

  private componentDestroyed$: Subject<boolean> = new Subject();

  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<TrainingChangeDialogComponent>,
    private fb: FormBuilder,
    private trainingService: TrainingService,
    private dialog: MatDialog,
    private appFieldValidatorsService: AppFieldValidatorsService,
    private uiHelperService: UIHelperService,
    @Inject(MAT_DIALOG_DATA) public data: TrainingChangeDialogData
  ) {
    this.commit = data?.commit || true;

    if (data?.entity) {
      if (typeof data.entity === 'number') {
        this.commit = true;
        this.isEditForm = true;
        this.entityId = data.entity;
      } else {
        this.entity = Object.assign({}, data?.entity);
        this.entityId = data.entity.id;
        if (this.entity?.id) {
          this.isEditForm = true;
        }
      }
    }
  }

  ngOnInit() {
    const sources = {
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: of(this.entity),
    };

    this.store.dispatch(toggleLoading({ payload: true }));

    if (this.isEditForm && this.retrieve) {
      sources.entity = this.trainingService
        .retrieve(this.entityId, { ignoreStore: true })
        .pipe(takeUntil(this.componentDestroyed$), first());
    }

    forkJoin(sources)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: ({
          currentUser,
          entity,
        }: {
          currentUser: User;
          entity: Training;
        }) => {
          this.store.dispatch(toggleLoading({ payload: false }));

          this.currentUser = currentUser;

          this.entity = entity;
          this.setupForm();
          this.initialized = true;
          if (this.isEditForm) {
            this.hydrateForm();
          } else {
            this.initialValues();
          }
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  private setupForm() {
    this.form = this.fb.group({
      name: [null, Validators.required],
      address: [null, Validators.required],
      training_date: [null, Validators.required],
      training_type: [null, Validators.required],
      attendance_type: [null, Validators.required],
      credit_hours: [null, Validators.required],
      certificate: [null],
      notes: [''],
    });
  }

  hydrateForm() {
    this.form.patchValue(Object.assign({}, this.entity), {
      emitEvent: false,
      onlySelf: true,
    });
    this.form.patchValue(
      {
        training_type: this.entity?.training_type?.toString(),
        attendance_type: this.entity?.attendance_type?.toString(),
        training_date: this.form.value.training_date
          ? moment(this.form.value.training_date, 'YYYY-MM-DD')
          : null,
      },
      {
        emitEvent: false,
        onlySelf: true,
      }
    );
  }
  private initialValues() {}

  save($event: MouseEvent) {
    $event.preventDefault();
    if (this.isEditForm) {
      this.edit();
    } else {
      this.create();
    }
  }

  private getFormData(): Training {
    const data: Training = new Training();

    data.training_date = this.form.value.training_date
      ? this.form.value.training_date.format('YYYY-MM-DD')
      : null;
    data.trainee = this.entity?.trainee;
    data.name = this.form.value.name;
    data.address = this.form.value.address;
    data.training_type = this.form.value.training_type;
    data.attendance_type = this.form.value.attendance_type;
    data.credit_hours = this.form.value.credit_hours;
    data.notes = this.form.value.notes;

    return data;
  }

  private create() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    if (!this.commit) {
      this.dialogRef.close(this.entity);
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const data: Training = this.getFormData();
    this.trainingService
      .create(data)
      .pipe(
        takeUntil(this.componentDestroyed$),
        first(),
        // update file fields
        switchMap((training: Training) => {
          const formData = new FormData();
          formData.append('certificate', this.form.value.certificate);
          return this.trainingService.update(formData, training.id);
        })
      )
      .subscribe({
        next: (training: Training) => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close(training);
        },
        error: (error: HttpErrorResponse) =>
          this.uiHelperService.handleUserRequestError(error),
      });
  }

  private edit() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    if (!this.commit) {
      this.dialogRef.close(this.entity);
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const data: Training = this.getFormData();

    this.trainingService
      .update(data, this.entity.id)
      .pipe(
        takeUntil(this.componentDestroyed$),
        first(),
        // update file fields
        switchMap((training: Training) => {
          if (this.form.value.certificate?.name) {
            const formData = new FormData();
            formData.append('certificate', this.form.value.certificate);
            return this.trainingService.update(formData, this.entity.id);
          }
          return of(training);
        })
      )
      .subscribe({
        next: (training: Training) => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close(training);
        },
        error: (error: HttpErrorResponse) =>
          this.uiHelperService.handleUserRequestError(error),
      });
  }

  protected readonly customerHIRLSettings = CustomerHIRLSettings;
}
