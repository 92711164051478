import { ModelFormControl, ModelFormGroup } from '../ModelForm';
import { Validators } from '@angular/forms';
import { FieldLabels } from '../base';

// Labels
export const FIELD_LABELS: FieldLabels<LightsBackendDict> = {
  interior_cfl_percent: 'Interior CFL Percent',
  interior_florescent_percent: 'Interior Florescent Percent',
  interior_led_percent: 'Interior LED Percent',
  garage_cfl_percent: 'Garage CFL Percent',
  garage_florescent_percent: 'Garage Florescent Percent',
  garage_led_percent: 'Garage LED Percent',
  exterior_cfl_percent: 'Exterior CFL Percent',
  exterior_florescent_percent: 'Exterior Florescent Percent',
  exterior_led_percent: 'Exterior LED Percent',
};

export interface LightsBackendDict {
  id: number;
  interior_cfl_percent: number;
  interior_florescent_percent: number;
  interior_led_percent: number;
  garage_cfl_percent: number;
  garage_florescent_percent: number;
  garage_led_percent: number;
  exterior_cfl_percent: number;
  exterior_florescent_percent: number;
  exterior_led_percent: number;
}

export function createLightsForm(data: LightsBackendDict): ModelFormGroup {
  return new ModelFormGroup(
    {
      id: new ModelFormControl(data.id),
      interior_cfl_percent: new ModelFormControl(data.interior_cfl_percent, [
        Validators.required,
        Validators.min(0),
        Validators.max(100),
      ]),
      interior_florescent_percent: new ModelFormControl(
        data.interior_florescent_percent,
        [Validators.required, Validators.min(0), Validators.max(100)]
      ),
      interior_led_percent: new ModelFormControl(data.interior_led_percent, [
        Validators.required,
        Validators.min(0),
        Validators.max(100),
      ]),
      exterior_cfl_percent: new ModelFormControl(data.exterior_cfl_percent, [
        Validators.required,
        Validators.min(0),
        Validators.max(100),
      ]),
      exterior_florescent_percent: new ModelFormControl(
        data.exterior_florescent_percent,
        [Validators.required, Validators.min(0), Validators.max(100)]
      ),
      exterior_led_percent: new ModelFormControl(data.exterior_led_percent, [
        Validators.required,
        Validators.min(0),
        Validators.max(100),
      ]),
      garage_cfl_percent: new ModelFormControl(data.garage_cfl_percent, [
        Validators.required,
        Validators.min(0),
        Validators.max(100),
      ]),
      garage_florescent_percent: new ModelFormControl(
        data.garage_florescent_percent,
        [Validators.required, Validators.min(0), Validators.max(100)]
      ),
      garage_led_percent: new ModelFormControl(data.garage_led_percent, [
        Validators.required,
        Validators.min(0),
        Validators.max(100),
      ]),
    },
    {
      validators: [
        (controls: ModelFormGroup) => {
          const locations = ['interior', 'exterior', 'garage'];
          const total = {};

          const totalExceeds100 = new Set([]);
          for (const location of locations) {
            for (const lightType of ['cfl', 'florescent', 'led']) {
              const percent = controls.get(
                `${location}_${lightType}_percent`
              ).value;
              total[location] = (total[location] || 0) + percent;

              if (total[location] > 100) {
                totalExceeds100.add(location);
              }
            }
          }

          if (totalExceeds100.size) {
            return {
              totalLightExceeds100OnLocations: {
                locations: Array.from(totalExceeds100),
              },
            };
          }
        },

        (controls: ModelFormGroup) => {
          const locations = ['interior', 'exterior', 'garage'];
          const total = {};

          const totalBelow50 = new Set(locations);

          for (const location of locations) {
            for (const lightType of ['cfl', 'florescent', 'led']) {
              const percent = controls.get(
                `${location}_${lightType}_percent`
              ).value;
              total[location] = (total[location] || 0) + percent;

              if (total[location] > 50) {
                totalBelow50.delete(location);
              }
            }
          }

          if (totalBelow50.size) {
            return {
              totalLightBelow50OnLocations: {
                locations: Array.from(totalBelow50),
              },
            };
          }
        },
      ],
    }
  );
}
