import { ListRequestParams } from '@/core/schemes/request-params';
import { environment } from '../../../../environments/environment';
import {
  Accreditation,
  AccreditationName,
  AccreditationRole,
  AccreditationState,
} from '@/data/user-management/models/accreditation';
import { Injectable } from '@angular/core';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import { Observable } from 'rxjs';

export class AccreditationRequestParams extends ListRequestParams {
  name?: AccreditationName;
  state?: AccreditationState | AccreditationState[];
  role?: AccreditationRole | AccreditationRole[];
  is_expired?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AccreditationService extends BaseDataService<Accreditation> {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/accreditations/`;

  create(
    entity: Accreditation | FormData,
    options?: IDataServiceMethodOptions
  ): Observable<Accreditation> {
    const ignoreStore: boolean = options?.ignoreStore;
    const chain: Observable<Accreditation> = this.http.post<Accreditation>(
      `${this.baseUrl}`,
      entity
    );
    return chain;
  }

  update(
    accreditation: Accreditation | FormData,
    accreditationId: number,
    options?: IDataServiceMethodOptions
  ): Observable<Accreditation> {
    const ignoreStore = options?.ignoreStore;
    const chain: Observable<Accreditation> = this.http.patch<Accreditation>(
      `${this.baseUrl}${accreditationId}/`,
      accreditation
    );
    return chain;
  }

  delete(
    entityId: any,
    options?: IDataServiceMethodOptions
  ): Observable<Accreditation> {
    return this.http.delete<Accreditation>(`${this.baseUrl}${entityId}/`);
  }

  customer_hirl_certificate(accreditationId: number) {
    return this.http.get(
      `${this.baseUrl}${accreditationId}/generate_certificate/`,
      {
        responseType: 'blob',
      }
    );
  }
}
