<div class="row">
  <div class="col-3">
    <div class="row">
      <div class="col-12">
        <form [formGroup]="filterFromGroup" class="card">
          <h5 class="card-header">Filters</h5>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <mat-form-field class="w-100">
                  <mat-label>Search</mat-label>
                  <app-search-input formControlName="search" placeholder="Type to search something"></app-search-input>
                </mat-form-field>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="col-9">
    <div class="row">
      <div class="col-12">
        <div class="row" *ngIf="isLoading">
          <div class="col-12">
            <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
          </div>
        </div>
        <table mat-table
               [dataSource]="rows"
               class="table"
               matSort
               [matSortActive]="storedParams.toTableSort().active"
               [matSortDirection]="storedParams.toTableSort().direction"
               (matSortChange)="onSortChange($event)">


          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Request Number</th>
            <td mat-cell *matCellDef="let row">
              <a [routerLink]="['/', 'hi', 'payment', 'request', 'detail', row.payment_request_info?.id]">{{ row.payment_request_info.id }}</a>
            </td>
          </ng-container>

          <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef>State</th>
            <td mat-cell *matCellDef="let row">
              {{ PaymentRequestStateLabelMapping[row.payment_request_info.state]?.label }}
            </td>
          </ng-container>

          <ng-container matColumnDef="invoice">
            <th mat-header-cell *matHeaderCellDef>Invoice</th>
            <td mat-cell *matCellDef="let row">
              <app-invoice-display [invoice]="row.payment_request_info?.invoice_info"></app-invoice-display>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [length]="rowsCount"
                       [pageIndex]="$any(storedParams.page) - 1"
                       [pageSize]="storedParams.page_size"
                       (page)="onPaginateChange($event)"
                       aria-label="Select page">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
