import { AppState } from '@/state/reducers';
import { createSelector } from '@ngrx/store';
import { hirlProductAgreementStateKey } from '@/state/reducers/customer-hirl/hirl-product-agreement.reducer';

export const selectHIRLProductAgreementState = (state: AppState) =>
  state[hirlProductAgreementStateKey];

export const getHIRLProductAgreement = createSelector(
  selectHIRLProductAgreementState,
  store => store.detail
);

// HIRLProductAgreement Documents
export const getHIRLProductAgreementDocuments = createSelector(
  selectHIRLProductAgreementState,
  store => store.documents
);

export const getHIRLProductAgreementDocumentsCount = createSelector(
  selectHIRLProductAgreementState,
  store => store.documentsCount
);

// HIRLProductAgreement Notes
export const getHIRLProductAgreementNotes = createSelector(
  selectHIRLProductAgreementState,
  store => store.notes
);

export const getHIRLProductAgreementNotesCount = createSelector(
  selectHIRLProductAgreementState,
  store => store.notesCount
);
