import {
  Component,
  DoCheck,
  ElementRef,
  HostBinding,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NgControl,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { FocusMonitor } from '@angular/cdk/a11y';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Moment } from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import * as moment from 'moment';

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MONTH_MAT_PICKER_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-month-mat-picker',
  templateUrl: './month-mat-picker.component.html',
  styleUrls: ['./month-mat-picker.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: MonthMatPickerComponent,
    },
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MONTH_MAT_PICKER_FORMATS },
  ],
})
export class MonthMatPickerComponent
  implements
    ControlValueAccessor,
    MatFormFieldControl<any>,
    OnInit,
    OnDestroy,
    DoCheck
{
  @ViewChild('dp') dp;
  @Input() minDate?: Date;

  static nextId = 0;
  private placeholder$: string;
  private required$ = false;
  private disabled$ = false;

  @HostBinding('[id]')
  public id = `app-month-mat-picker-${MonthMatPickerComponent.nextId++}`;

  // component logic
  public control = new UntypedFormControl();

  private componentDestroyed$ = new Subject();

  // angular mat input
  public focused = false;
  public touched = false;
  public stateChanges = new Subject<void>();
  public describedBy = '';
  public onChange = (_: any) => {};
  public onTouched = () => {};

  get empty() {
    return !this.control.value;
  }

  @HostBinding('[class.app-month-mat-picker-floating]')
  get shouldLabelFloat() {
    return true;
  }

  get errorState() {
    return this.ngControl.errors !== null && this.touched;
  }

  @Input()
  get placeholder(): string {
    return this.placeholder$;
  }
  set placeholder(value: string) {
    this.placeholder$ = value;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this.required$;
  }
  set required(value: boolean) {
    this.required$ = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this.disabled$;
  }
  set disabled(value: boolean) {
    this.disabled$ = coerceBooleanProperty(value);
    this.disabled$ ? this.control.disable() : this.control.enable();
    this.stateChanges.next();
  }

  @Input()
  get value(): Date | null {
    return this.control.value;
  }
  set value(value: Date | null) {
    this.control.setValue(value);
    this.onChange(value);
    this.stateChanges.next();
  }

  constructor(
    private focusMonitorService: FocusMonitor,
    private injector: Injector,
    private elementRef$: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl
  ) {
    focusMonitorService.monitor(elementRef$, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();

    this.stateChanges.complete();
    this.focusMonitorService.stopMonitoring(this.elementRef$);
  }

  ngDoCheck(): void {
    if (this.touched !== this.ngControl.control.touched) {
      this.touched = this.ngControl.pristine;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  // angular mat input methods
  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (
      !this.elementRef$.nativeElement.contains(event.relatedTarget as Element)
    ) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick($event: MouseEvent) {
    $event.stopPropagation();
    if (this.disabled) {
      return;
    }
    this.onTouched();
    if (($event.target as Element).textContent === 'close') {
      this.clear();
    } else {
      this.handleInput();
    }
  }

  writeValue(value?: Date): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private clear(): void {
    this.writeValue(null);
  }

  private handleInput() {
    this.dp.open();
  }

  // component methods

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = moment(this.control.value || new Date());
    ctrlValue.year(normalizedYear.year());
    this.writeValue(ctrlValue.startOf('month').toDate());
  }

  chosenMonthHandler(
    normalizedMonth: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const ctrlValue = moment(this.control.value || new Date());
    ctrlValue.month(normalizedMonth.month());
    this.writeValue(ctrlValue.startOf('month').toDate());
    datepicker.close();
  }
}
