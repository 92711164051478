import { ListRequestParams } from '@/core/schemes/request-params';
import { Injectable } from '@angular/core';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { Equipment } from '@/data/equipment/models';

export class EquipmentRequestParams extends ListRequestParams {}

@Injectable({
  providedIn: 'root',
})
export class EquipmentService extends BaseDataService {
  baseUrl = `${environment.AXIS_API_V3_ROOT}/equipment/`;

  retrieve(
    equipmentId: number,
    options?: IDataServiceMethodOptions
  ): Observable<Equipment> {
    return this.http.get<Equipment>(`${this.baseUrl}${equipmentId}`);
  }

  create(equipment: Equipment | FormData): Observable<Equipment> {
    return this.http.post<Equipment>(`${this.baseUrl}`, equipment);
  }

  update(
    equipmentId: number,
    equipment: Equipment | FormData
  ): Observable<Equipment> {
    return this.http.patch<Equipment>(
      `${this.baseUrl}${equipmentId}/`,
      equipment
    );
  }

  delete(equipmentId: number) {
    return this.http.delete(`${this.baseUrl}${equipmentId}`);
  }

  copy_expired_equipment(equipmentId: number): Observable<Equipment> {
    return this.http.post<Equipment>(
      `${this.baseUrl}${equipmentId}/copy_expired_equipment/`,
      {}
    );
  }
}
