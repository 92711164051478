import {
  ComponentCompanyType,
  componentCompanyTypeDataMap,
} from '@/modules/company/constants';

export const CustomerHIRLSettings = {
  companySlug: 'provider-home-innovation-research-labs',
  waterSenseProgramList: [
    'ngbs-sf-new-construction-2020-new',
    'ngbs-mf-new-construction-2020-new',
    'ngbs-sf-whole-house-remodel-2020-new',
    'ngbs-mf-whole-house-remodel-2020-new',
    'ngbs-sf-certified-2020-new',
    'ngbs-sf-wri-2021',
    'ngbs-mf-wri-2021',
  ],
  requireRoughInspectionProgramList: [
    'ngbs-sf-whole-house-remodel-2020-new',
    'ngbs-mf-whole-house-remodel-2020-new',
    'ngbs-sf-whole-house-remodel-2015-new',
    'ngbs-mf-whole-house-remodel-2015-new',
  ],
  wriSeekingProgramList: [
    'ngbs-sf-new-construction-2020-new',
    'ngbs-mf-new-construction-2020-new',
    'ngbs-sf-certified-2020-new',
  ],
  wriProgramList: ['ngbs-sf-wri-2021', 'ngbs-mf-wri-2021'],
  appealsRegistrationWarningLimit: 3,

  paymentRequestExpirationWarningDays: 35,
};

export const EEPProgramHomeStatusSlugSettings = {
  singleFamilyRemodelSlugList: [
    'ngbs-sf-whole-house-remodel-2012-new',
    'ngbs-sf-whole-house-remodel-2015-new',
    'ngbs-sf-whole-house-remodel-2020-new',
    'ngbs-green-building-renovations-with-additions-75',
    'ngbs-green-remodel-renovations-with-additions-75',
    'ngbs-renovations-with-addition-75',
    'ngbs-single-family-additions-75-1',
    'ngbs-single-family-additions-75-2',
    'ngbs-single-family-green-building-renovation',
    'ngbs-single-family-green-remodel-renovation',
    'ngbs-basement-remodel-2012',
    'ngbs-bathroom-remodel-2012',
    'ngbs-kitchen-remodel-2012',
    'ngbs-sf-whole-house-remodel-2012',
    'ngbs-small-addition-2012',
    'ngbs-sf-kitchen-remodel-2015',
    'ngbs-sf-whole-house-remodel-2015',
    'ngbs-sf-whole-house-remodel-2020',
  ],
  singleFamilyCertifiedSlugList: [
    'ngbs-sf-certified-2020-new',
    'ngbs-sf-certified-2020',
  ],
  singleFamilyNewConstructionSlugList: [
    'ngbs-sf-new-construction-2020-new',
    'ngbs-sf-new-construction-2015-new',
    'ngbs-sf-new-construction-2012-new',
    // legacy
    'ngbs-sf-new-construction-2020',
    'ngbs-sf-new-construction-2015',
    'ngbs-sf-new-construction-2012',
    'ngbs-single-family-new-construction',
  ],
  multiFamilyRemodelSlugList: [
    'ngbs-mf-whole-house-remodel-2012-new',
    'ngbs-mf-whole-house-remodel-2015-new',
    'ngbs-mf-whole-house-remodel-2020-new',
    'ngbs-multi-unit-green-building-renovation',
    'ngbs-multi-unit-green-remodel-renovation',
    'ngbs-mf-remodel-building-2012',
    'ngbs-mf-remodel-building-2015',
    'ngbs-mf-remodel-building-2020',
  ],
  multiFamilyNewConstructionSlugList: [
    'ngbs-mf-new-construction-2012-new',
    'ngbs-mf-new-construction-2015-new',
    'ngbs-mf-new-construction-2020-new',
    'ngbs-multi-unit-new-construction',
    'ngbs-mf-new-construction-2012',
    'ngbs-mf-new-construction-2015',
    'ngbs-mf-new-construction-2020',
  ],
  landDevelopmentSlugList: [
    'ngbs-green-subdivision',
    'ngbs-green-subdivision-2012',
    'ngbs-green-subdivision-2020',
  ],
};

export const ClientAgreementCompanyTypeDataMap = {
  [ComponentCompanyType.builder]:
    componentCompanyTypeDataMap[ComponentCompanyType.builder],
  [ComponentCompanyType.architect]:
    componentCompanyTypeDataMap[ComponentCompanyType.architect],
  [ComponentCompanyType.developer]:
    componentCompanyTypeDataMap[ComponentCompanyType.developer],
  [ComponentCompanyType.communityOwner]:
    componentCompanyTypeDataMap[ComponentCompanyType.communityOwner],
};
