export enum CeleryTaskState {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  REVOKED = 'REVOKED',
  STARTED = 'STARTED',
  RETRY = 'RETRY',
  RECEIVED = 'RECEIVED',
  PENDING = 'PENDING',
  UNACKNOWLEDGED = 'UNACKNOWLEDGED',
}

export class AsynchronousProcessedDocument {
  id?: number;
  company?: number;
  task_name?: string;
  document?: string;
  final_status?: string;
  result?: string | object;
  state?: CeleryTaskState;
  download?: boolean;
  modified_date?: string;
  created_date?: string;
}
