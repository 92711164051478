import { createSelector } from '@ngrx/store';
import { SimulationState } from '../reducers';
import { selectSimulationFeature } from '../simulation.selectors';

export const selectInfiltration = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.infiltration.entities[id]
  );

const selectInfiltrationLoading = (id: number) =>
  createSelector(
    selectSimulationFeature,
    state => state.infiltration.loading[id]
  );

export const selectInfiltrationViewSet = (id: number) =>
  createSelector(
    selectInfiltration(id),
    selectInfiltrationLoading(id),
    (data, loading) => ({ data, loading })
  );

export const selectInfiltrationErrorsCount = (id: number) =>
  createSelector(selectSimulationFeature, (state: SimulationState) => ({
    errorCount: state.infiltration.errors[id].errorCount,
    warningCount: state.infiltration.errors[id].warningCount,
  }));
