import { Geocode } from '@/data/geocoder/models';
import { Simulation } from '@/data/simulation/models';
import { SimulationSourceTypeLabelMapping } from '@/data/simulation/models/simulation';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { resetSimulation } from '@/state/actions/floorplan/simulation.actions';
import { AppState } from '@/state/reducers';
import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { forkJoin, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { SimulationService } from '@/data/simulation/services/simulation.service';
import { SimulationResidenceTypeLabelMapping } from '@/data/simulation/models/simulation';
import { getInfoUser } from '@/state/selectors/info.selector';
import { User } from '@/data/core/models/user';
@Component({
  selector: 'app-simulation-detail-tab',
  templateUrl: './simulation-detail-tab.component.html',
  styleUrls: ['./simulation-detail-tab.component.scss'],
})
export class SimulationDetailTabComponent implements OnInit, OnDestroy {
  @Input() simulationId: number;

  private componentDestroyed$ = new Subject();

  public enclosureCount = 0;
  public equipmentCount = 0;
  public photovoltaicsCount = 0;
  public initialized = false;

  public simulation: Simulation;
  public simulationSourceTypeLabelMap = SimulationSourceTypeLabelMapping;
  public simulationResidenceTypeLabelMap = SimulationResidenceTypeLabelMapping;
  public simulationGeocode: Geocode;
  public currentUser: User;

  constructor(
    public store: Store<AppState>,
    private simulationService: SimulationService,
    public dialog: MatDialog,
    public injector: Injector,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public uiHelper: UIHelperService
  ) {}

  ngOnInit(): void {
    if (this.simulationId) {
      forkJoin({
        currentUser: this.store.select(getInfoUser).pipe(first()),
        simulation: this.simulationService
          .oldRetrieve(this.simulationId)
          .pipe(first()),
      })
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(({ currentUser, simulation }) => {
          this.currentUser = currentUser;
          this.simulation = simulation;
          this.enclosureCount =
            simulation.above_grade_walls.length +
            simulation.foundation_walls.length +
            simulation.frame_floors.length +
            simulation.roofs.length +
            simulation.slabs.length +
            simulation.rim_joists.length +
            simulation.skylights.length +
            simulation.windows.length +
            simulation.doors.length;
          this.equipmentCount = simulation.mechanical_equipment.length;
          this.photovoltaicsCount = simulation.photovoltaics.length;
          // Handling Simulation
          if (simulation) {
            // this.simulationGeocode = new Geocode();
            // this.simulationGeocode.raw_street_line1 =
            //   simulation.location_info?.street_line1;
            // this.simulationGeocode.raw_street_line2 =
            //   simulation.location_info?.street_line2;
            // this.simulationGeocode.raw_city = simulation.location_info?.city;
            // this.simulationGeocode.raw_city_info =
            //   simulation.location_info?.city_info;
            // this.simulationGeocode.raw_zipcode =
            //   simulation.location_info?.zipcode;

            this.initialized = true;
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
    this.store.dispatch(resetSimulation());
  }
}
