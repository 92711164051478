import { createReducer, on } from '@ngrx/store';
import * as AboveGradeWallActions from './actions';
import { AboveGradeWallBackendDict } from '@/data/simulation/models/enclosure/AboveGradeWall';
import { BaseState, sharedInitialState } from '../shared/base.state';
import { addErrorCount, addStateErrorCount } from '../helper';

export type AboveGradeWallState = BaseState<AboveGradeWallBackendDict>;

export const initialState: AboveGradeWallState = sharedInitialState;

export const reducer = createReducer(
  initialState,
  on(
    AboveGradeWallActions.loadAboveGradeWallsSuccess,
    (currentState, payload) => {
      const entities = payload.aboveGradeWalls.reduce(
        (acc, abovegradewall) => ({
          ...acc,
          [abovegradewall.id]: abovegradewall,
        }),
        {}
      );

      const loadingStatus = payload.aboveGradeWalls.reduce(
        (acc, abovegradewall) => ({ ...acc, [abovegradewall.id]: false }),
        {}
      );

      return {
        entities: { ...currentState.entities, ...entities },
        loading: { ...currentState.loading, ...loadingStatus },
        errors: {
          ...currentState.errors,
          ...addStateErrorCount(payload.errors),
        },
      };
    }
  ),

  on(
    AboveGradeWallActions.removeAboveGradeWallSuccess,
    (currentState, { id }) => {
      const { [id]: removed, ...entities } = currentState.entities;
      const { [id]: removedLoading, ...loading } = currentState.loading;
      const { [id]: removedErrors, ...errors } = currentState.errors;

      return {
        entities,
        loading,
        errors,
      };
    }
  ),

  on(
    AboveGradeWallActions.updateAboveGradeWall,
    (state, { aboveGradeWallChanges }) => {
      return {
        ...state,
        loading: {
          ...state.loading,
          [aboveGradeWallChanges.id]: true,
        },
      };
    }
  ),

  on(
    AboveGradeWallActions.removeAboveGradeWall,
    (state, { aboveGradeWall }) => {
      return {
        ...state,
        loading: {
          ...state.loading,
          [aboveGradeWall.id]: true,
        },
      };
    }
  ),

  on(
    AboveGradeWallActions.updateAboveGradeWallFailure,
    AboveGradeWallActions.removeAboveGradeWallFailure,
    (state, { id }) => {
      return {
        ...state,
        loading: {
          ...state.loading,
          [id]: false,
        },
      };
    }
  ),

  on(
    AboveGradeWallActions.updateAboveGradeWallErrors,
    (state, { id, errors }) => {
      return {
        ...state,
        errors: {
          ...state.errors,
          [id]: errors,
        },
      };
    }
  ),

  on(
    AboveGradeWallActions.updateAboveGradeWallSuccess,
    (state, { aboveGradeWall, errors }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          [aboveGradeWall.id]: aboveGradeWall,
        },
        loading: {
          ...state.loading,
          [aboveGradeWall.id]: false,
        },
        errors: {
          ...state.errors,
          [aboveGradeWall.id]: addErrorCount(errors),
        },
      };
    }
  ),

  on(
    AboveGradeWallActions.setAboveGradeWallType,
    (state, { wallId, wallTypeId }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          [wallId]: {
            ...state.entities[wallId],
            type: wallTypeId,
          },
        },
      };
    }
  )
);
