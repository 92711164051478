import { City } from '@/data/geographic/models';

/* eslint-disable @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match */
export class HomeInfo {
  id: number;

  // virtual fields
  address: string;
}

export class Home {
  id: number;
  lot_number?: string;
  street_line1: string;
  street_line2?: string;
  city: number;
  zipcode: string;
  geocode_response: number;

  // virtual fields
  address: string;
}

export class HomeAddressIsUniqueRequestData {
  home?: number;
  street_line1: string;
  street_line2?: string;
  is_multi_family: boolean;
  city: number;
  city_info: City;
  zipcode: string;
  geocode_response: number;
}
/* eslint-enable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
