import {
  WindowTypeBackendDict,
  createWindowTypeForm,
  FIELD_LABELS,
} from '@/data/simulation/models/enclosure/WindowType';
import { updateWindowType } from '@/modules/simulation/state/window-type/actions';
import { selectWindowViewSet } from '@/modules/simulation/state/window-type/selectors';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-window-type',
  templateUrl: './window-type.component.html',
  styleUrls: ['./window-type.component.scss'],
})
export class WindowTypeComponent implements OnDestroy, OnInit {
  @Input() entityId: number;
  fieldLabels = FIELD_LABELS;

  entity: WindowTypeBackendDict = null;
  windowTypeFormGroup: FormGroup;

  componentDestroyed$ = new Subject();
  loading = false;

  constructor(public store: Store) {}

  ngOnInit(): void {
    this.store
      .select(selectWindowViewSet(this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(({ data, loading }) => {
        this.entity = data;
        this.loading = loading;
        if (loading) return;

        if (!this.windowTypeFormGroup) {
          this.windowTypeFormGroup = createWindowTypeForm(data);
        } else {
          this.windowTypeFormGroup.patchValue(data);
        }
      });
  }

  onSave() {
    this.store.dispatch(
      updateWindowType({
        windowTypeChanges: this.windowTypeFormGroup.value,
      })
    );
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
