import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { getInfoUser } from '@/state/selectors/info.selector';
import { AppState } from '@/state/reducers';
import { filter, map } from 'rxjs/operators';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';

@Injectable({ providedIn: 'root' })
export class CustomerHIRLCompanyMemberGuard implements CanActivate {
  constructor(public router: Router, public store: Store<AppState>) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.select(getInfoUser).pipe(
      filter(currentUser => !!currentUser),
      map(currentUser => {
        if (
          currentUser.company &&
          currentUser.company_info.slug === CustomerHIRLSettings.companySlug
        ) {
          return true;
        }
        this.router.navigate(['403'], {
          queryParams: { returnUrl: state.url },
        });
        return false;
      })
    );
  }
}
