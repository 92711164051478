import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { Task, TaskStatusLabelMapping } from '@/data/scheduling/models';
import { TaskService } from '@/data/scheduling/services';
import { first, takeUntil } from 'rxjs/operators';
import { forkJoin, of, Subject } from 'rxjs';
import { User, UserInfo } from '@/data/core/models/user';
import { getInfoUser } from '@/state/selectors/info.selector';
import { toggleLoading } from '@/state/actions/app.actions';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { HttpErrorResponse } from '@angular/common/http';
import { ApexRequestType } from '@/data/home/models';

export class TaskChangeDialogData {
  entity?: Task | number;
  commit = true;
  retrieve = true;
}

@Component({
  selector: 'app-change-task-dialog',
  templateUrl: './change-task-dialog.component.html',
  styleUrls: ['./change-task-dialog.component.scss'],
})
export class ChangeTaskDialogComponent implements OnInit, OnDestroy {
  public initialized: boolean;
  public isEditForm = false;
  public entity?: Task;
  private entityId?: number;

  public commit = true;
  public retrieve = true;
  public form: FormGroup;
  public currentUser: User;

  public statusLabelMapping = TaskStatusLabelMapping;

  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    public dialogRef: MatDialogRef<ChangeTaskDialogComponent>,
    public fb: FormBuilder,
    public taskService: TaskService,
    public dialog: MatDialog,
    public uiHelperService: UIHelperService,
    @Inject(MAT_DIALOG_DATA) public data: TaskChangeDialogData
  ) {
    this.commit = data?.commit || true;

    if (data?.entity) {
      if (typeof data.entity === 'number') {
        this.commit = true;
        this.isEditForm = true;
        this.entityId = data.entity;
      } else {
        this.entity = data?.entity;
        this.entityId = data?.entity?.id;
        if (this.entityId) {
          this.isEditForm = true;
        }
      }
    }
  }

  ngOnInit() {
    const sources = {
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: of(this.entity),
    };

    this.store.dispatch(toggleLoading({ payload: true }));

    if (this.isEditForm && this.retrieve) {
      sources.entity = this.taskService
        .retrieve(this.entityId, { ignoreStore: true })
        .pipe(takeUntil(this.componentDestroyed$), first());
    }

    forkJoin(sources)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: ({
          currentUser,
          entity,
        }: {
          currentUser: User;
          entity: Task;
        }) => {
          this.store.dispatch(toggleLoading({ payload: false }));

          this.currentUser = currentUser;

          this.entity = entity;
          this.setupForm();
          this.initialized = true;
          if (this.isEditForm) {
            this.hydrateForm();
          } else {
            this.initialValues();
          }
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  setupForm() {
    this.form = this.fb.group({
      assignees_info: [null, Validators.required],
      task_type: [null, Validators.required],
      status: [null, Validators.required],
      date: [null, Validators.required],
      time: [null, Validators.required],
      note: [],
    });
  }

  hydrateForm() {
    this.form.patchValue(this.entity, { emitEvent: false, onlySelf: true });
    if (this.entity?.datetime) {
      const datetime = new Date(this.entity.datetime);
      const date: string = datetime.toISOString().split('T')[0];
      const time = `${datetime.getHours()}:${datetime.getMinutes()}`;

      this.form.patchValue(
        {
          date: new Date(date),
          time: time,
        },
        { emitEvent: false, onlySelf: true }
      );
    }
  }

  private initialValues() {}

  save($event: MouseEvent) {
    $event.preventDefault();
    if (this.isEditForm) {
      this.edit();
    } else {
      this.create();
    }
  }

  private edit() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }
    if (!this.commit) {
      this.dialogRef.close(this.entity);
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const data: Task = this.getFormData();
    this.taskService
      .update(data, this.entity.id)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: (task: Task) => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close(task);
        },
        error: (error: HttpErrorResponse) =>
          this.uiHelperService.handleUserRequestError(error),
      });
  }

  private create() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    if (!this.commit) {
      this.dialogRef.close(this.entity);
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const data: Task = this.getFormData();
    this.taskService
      .create(data)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: (task: Task) => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close(task);
        },
        error: (error: HttpErrorResponse) =>
          this.uiHelperService.handleUserRequestError(error),
      });
  }

  getFormData(): Task {
    const task = new Task();
    task.assignees = this.form.value.assignees_info?.map(
      (assigneesInfo: UserInfo) => assigneesInfo.id
    );
    task.task_type = this.form.value.task_type;
    task.status = this.form.value.status;
    task.note = this.form.value.note;

    const date: Date = this.form.value.date;
    const time: string = this.form.value.time;

    if (date && time) {
      const [hours, minutes] = time.split(':').map(Number);

      const datetime = new Date(date);
      datetime.setHours(hours, minutes, 0, 0);

      task.datetime = datetime.toISOString();
    }

    return task;
  }

  protected readonly ApexRequestType = ApexRequestType;
  protected readonly TaskStatusLabelMapping = TaskStatusLabelMapping;
}
