import {
  DoorBackendDict,
  DetailedDoorBackendDict,
} from '@/data/simulation/models/enclosure/Door';
import { createAction, props } from '@ngrx/store';
import { ModelErrors, StateErrors } from '../shared/base.state';

export const loadDetailedDoors = createAction(
  '[Simulation API] Load Detailed Doors',
  props<{ detailedDoors: DetailedDoorBackendDict[] }>()
);

export const loadDoorsSuccess = createAction(
  '[Door API] Load Doors Success',
  props<{
    doors: DoorBackendDict[];
    errors: StateErrors<DoorBackendDict>;
  }>()
);

export const updateDoor = createAction(
  '[Door UI] Update Door',
  props<{ doorChanges: Partial<DoorBackendDict> }>()
);

export const updateDoorSuccess = createAction(
  '[Door API] Update Door Success',
  props<{
    door: DoorBackendDict;
    errors: ModelErrors<DoorBackendDict>;
  }>()
);

export const updateDoorFailure = createAction(
  '[Door API] Update Door Failure',
  props<{ id: number }>()
);

export const removeDoor = createAction(
  '[Door UI] Remove Door',
  props<{ door: DoorBackendDict }>()
);

export const removeDoorSuccess = createAction(
  '[Door API] Remove Door Success',
  props<{ id: number }>()
);

export const removeDoorFailure = createAction(
  '[Door API] Remove Door Failure',
  props<{ id: number }>()
);

export const updateDoorErrors = createAction(
  '[Door UI] Update Door Errors',
  props<{ id: number; errors: any }>()
);

export const setDoorType = createAction(
  '[DoorType API] Set DoorType',
  props<{ doorId: number; doorTypeId: number | null }>()
);

export const setDoorTypeSuccess = createAction(
  '[DoorType API] Set DoorType null',
  props<{ doorId: number; doorTypeId: number | null }>()
);
