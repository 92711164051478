import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  ContactCard,
  ContactCardEmail,
  ContactCardPhone,
} from '@/data/core/models';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactCardService } from '@/data/core/services/contact_card';
import {
  ServerErrorDialogComponent,
  ServerErrorDialogConfig,
} from '@/shared/components/server-error-dialog/server-error-dialog.component';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, takeUntil } from 'rxjs/operators';
import { forkJoin, Subject } from 'rxjs';
import { toggleLoading } from '@/state/actions/app.actions';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { User } from '@/data/core/models/user';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { conditionalValidator } from '@/core/validators';

export enum ContactCardDialogDisplayType {
  default,
  customerHIRLManufacturer,
}

export class ContactCardDialogData {
  contactCard?: ContactCard;
  displayType?: ContactCardDialogDisplayType =
    ContactCardDialogDisplayType.default;
}

@Component({
  selector: 'app-contact-card-edit-dialog',
  templateUrl: './contact-card-edit-dialog.component.html',
  styleUrls: ['./contact-card-edit-dialog.component.scss'],
})
export class ContactCardEditDialogComponent implements OnInit, OnDestroy {
  protected readonly ContactCardDialogDisplayType =
    ContactCardDialogDisplayType;

  public initialized: boolean;
  public currentUser: User;

  customerHIRLManufacturerDepartments: string[] = [
    'Primary',
    'Marketing',
    'Accounting',
  ];

  editForm = false;
  contactCard?: ContactCard;
  form: FormGroup;
  isLoading = false;

  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<ContactCardEditDialogComponent>,
    private fb: FormBuilder,
    private contactCardService: ContactCardService,
    private dialog: MatDialog,
    private uiHelperService: UIHelperService,
    @Inject(MAT_DIALOG_DATA) public data: ContactCardDialogData
  ) {
    this.contactCard = data.contactCard;

    if (this.contactCard?.id) {
      this.editForm = true;
    }
  }

  ngOnInit() {
    const sources = {
      currentUser: this.store.select(getInfoUser).pipe(first()),
    };

    this.store.dispatch(toggleLoading({ payload: true }));

    forkJoin(sources)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: ({ currentUser, customerDocument }) => {
          this.store.dispatch(toggleLoading({ payload: false }));

          this.currentUser = currentUser;
          this.setupForm();
          this.initialized = true;
          if (this.editForm) {
            this.hydrateForm();
          }
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  setupForm() {
    this.form = this.fb.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      title: [
        '',
        [
          conditionalValidator(
            () =>
              this.data.displayType ===
              ContactCardDialogDisplayType.customerHIRLManufacturer,
            Validators.required
          ),
        ],
      ],
      emails: this.fb.array([]),
      phones: this.fb.array([]),
    });
  }

  hydrateForm() {
    this.contactCard.phones.forEach(email => {
      this.addPhone();
    });
    this.contactCard.emails.forEach(email => {
      this.addEmail();
    });
    this.form.patchValue(this.contactCard, {
      emitEvent: false,
      onlySelf: true,
    });
  }

  public addPhone(): void {
    const control = this.form.controls.phones as FormArray;
    const group = this.fb.group({
      phone: [null, Validators.required],
      description: [''],
    });

    control.push(group);
  }

  handleRemovePhone(event, index: number) {
    event.preventDefault();
    const control = this.form.controls.phones as FormArray;
    control.removeAt(index);
  }

  public addEmail(): void {
    const control = this.form.controls.emails as FormArray;
    const group = this.fb.group({
      email: [
        null,
        Validators.compose([Validators.required, Validators.email]),
      ],
      description: [''],
    });

    control.push(group);
  }

  handleRemoveEmail(event, index: number) {
    event.preventDefault();
    const control = this.form.controls.emails as FormArray;
    control.removeAt(index);
  }

  getPhonesControls() {
    return (this.form.get('phones') as FormArray).controls;
  }

  getEmailsControls() {
    return (this.form.get('emails') as FormArray).controls;
  }

  onSave(event: any) {
    if (this.editForm) {
      this.onEdit(event);
    } else {
      this.onCreate(event);
    }
  }

  onEdit(event: any) {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    const formData = new ContactCard();

    formData.first_name = this.form.value.first_name;
    formData.last_name = this.form.value.last_name;
    formData.title = this.form.value.title;

    const phones = [];

    for (const phoneData of this.form.controls.phones.value) {
      const phoneFormData = new ContactCardPhone();
      phoneFormData.phone = phoneData.phone;
      phoneFormData.description = phoneData.description;

      phones.push(phoneFormData);
    }

    formData.phones = phones;

    const emails = [];

    for (const emailData of this.form.controls.emails.value) {
      const emailFormData = new ContactCardEmail();
      emailFormData.email = emailData.email;
      emailFormData.description = emailData.description;
      emails.push(emailFormData);
    }

    formData.emails = emails;

    this.isLoading = true;
    this.contactCardService.update(formData, this.contactCard.id).subscribe(
      contactCard => {
        this.isLoading = false;
        this.dialogRef.close(contactCard);
      },
      error => {
        this.isLoading = false;
        this.dialog.open(ServerErrorDialogComponent, {
          width: '400px',
          data: new ServerErrorDialogConfig({
            title: 'Error:',
            data: error,
          }),
        });
      }
    );
  }

  onCreate(event: any) {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    const formData = Object.assign(new ContactCard(), this.contactCard);

    formData.first_name = this.form.value.first_name;
    formData.last_name = this.form.value.last_name;
    formData.title = this.form.value.title;

    const phones = [];

    for (const phoneData of this.form.controls.phones.value) {
      const phoneFormData = new ContactCardPhone();
      phoneFormData.phone = phoneData.phone;

      phones.push(phoneFormData);
    }

    if (phones) {
      formData.phones = phones;
    }

    const emails = [];

    for (const emailData of this.form.controls.emails.value) {
      const emailFormData = new ContactCardEmail();
      emailFormData.email = emailData.email;
      emails.push(emailFormData);
    }

    if (emails) {
      formData.emails = emails;
    }

    this.isLoading = true;
    this.contactCardService.create(formData).subscribe(
      contactCard => {
        this.isLoading = false;
        this.dialogRef.close(contactCard);
      },
      error => {
        this.isLoading = false;
        this.dialog.open(ServerErrorDialogComponent, {
          width: '400px',
          data: new ServerErrorDialogConfig({
            title: 'Error:',
            data: error,
          }),
        });
      }
    );
  }
}
