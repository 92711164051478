import { createReducer, on } from '@ngrx/store';
import * as DoorTypeActions from './actions';
import { DoorTypeBackendDict } from '@/data/simulation/models/enclosure/DoorType';
import { BaseState } from '../shared/base.state';
import { addStateErrorCount } from '../helper';

export type DoorTypeState = BaseState<DoorTypeBackendDict>;

export const initialState: DoorTypeState = {
  entities: {},
  loading: {},
  errors: {},
};

export const reducer = createReducer(
  initialState,
  on(DoorTypeActions.loadDoorTypesSuccess, (state, { doorTypes, errors }) => {
    const entities = doorTypes.reduce(
      (acc, doorType) => ({ ...acc, [doorType.id]: doorType }),
      {}
    );
    const loading = doorTypes.reduce(
      (acc, doorType) => ({ ...acc, [doorType.id]: false }),
      {}
    );
    return {
      entities: { ...state.entities, ...entities },
      loading: { ...state.loading, ...loading },
      errors: { ...state.errors, ...addStateErrorCount(errors) },
    };
  }),

  on(DoorTypeActions.updateDoorType, (state, { doorTypeChanges }) => ({
    ...state,
    loading: { ...state.loading, [doorTypeChanges.id]: true },
  })),

  on(DoorTypeActions.updateDoorTypeFailure, (state, { id }) => ({
    ...state,
    loading: { ...state.loading, [id]: false },
  })),

  on(DoorTypeActions.removeDoorTypeSuccess, (state, { id }) => {
    const { [id]: removed, ...entities } = state.entities;
    const { [id]: removedLoading, ...loading } = state.loading;
    const { [id]: removedErrors, ...errors } = state.errors;
    return { entities, loading, errors };
  })
);
