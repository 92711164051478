import { ListRequestParams } from '@/core/schemes/request-params';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Relationship } from '@/data/relationship/models';
import { BaseDataService } from '@/core/services/base-data-service';
import { environment } from '../../../../environments/environment';
import { ServerResponse } from '@/core/schemes/server-response';

export class RelationshipRequestParams extends ListRequestParams {}

@Injectable({
  providedIn: 'root',
})
export class RelationshipService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/relationships/`;

  list(params?: RelationshipRequestParams) {
    return this.http.get<ServerResponse<Relationship>>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }

  retrieve(relationshipId: number): Observable<Relationship> {
    return this.http.get<Relationship>(`${this.baseUrl}${relationshipId}`, {});
  }

  delete(relationshipId: number) {
    return this.http.delete(`${this.baseUrl}${relationshipId}`);
  }
}
