import { Component, Input, OnInit } from '@angular/core';
import { PaymentRequest } from '@/data/customer-hirl/models';
import { PaymentRequestStateLabelMapping } from '@/data/customer-hirl/models';

export enum PaymentRequestDisplayComponentType {
  onlyIdAsLink = 'onlyIdAsLink',
  onlyName = 'onlyName',
  onlyNameAsLink = 'onlyNameAsLink',
  nameAsLinkAndStatus = 'nameAsLinkAndStatus',
}

@Component({
  selector: 'app-payment-request-display',
  templateUrl: './payment-request-display.component.html',
  styleUrls: ['./payment-request-display.component.scss'],
})
export class PaymentRequestDisplayComponent implements OnInit {
  protected readonly PaymentRequestStateLabelMapping =
    PaymentRequestStateLabelMapping;

  @Input() displayType:
    | PaymentRequestDisplayComponentType
    | keyof typeof PaymentRequestDisplayComponentType =
    PaymentRequestDisplayComponentType.onlyName;
  @Input() paymentRequest?: PaymentRequest;

  public PaymentRequestDisplayComponentType =
    PaymentRequestDisplayComponentType;

  ngOnInit(): void {}
}
