import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { ListRequestParams } from '@/core/schemes/request-params';
import { environment } from '../../../../environments/environment';
import { FloorPlan } from '@/data/floorplan/models/floorplan';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import { ServerResponse } from '@/core/schemes/server-response';
import {
  loadFloorplan,
  loadFloorplanDocuments,
  loadFloorplanDocumentsCount,
  loadFloorplanHomeStatusesCount,
} from '@/state/actions/floorplan/floorplan.actions';
import { CustomerDocument } from '@/data/filehandling/models';
import { CustomerDocumentRequestParams } from '@/data/filehandling/services/customer-document.service';
import { EEPAndHomeInfo } from '@/data/home/models/eep_program_home_status';
import { Observable } from 'rxjs';

export class FloorPlanRequestParams extends ListRequestParams {
  air_conditioner?: boolean;
  ashp?: boolean;
  gshp?: boolean;
  dehumidifier?: boolean;
  heater_fuel_type?: string;
  water_heater_type?: string;
  simulation?: number;
  simulation_source?: string;
  simulation__version?: string;
  attached_to_home?: string;
  has_basement?: string;
  crawl_space?: string;
  has_slabs?: boolean;
  attic_type?: string;
  vaulted_ceilings?: boolean;
  num_stories?: number;
  has_photovoltaics?: boolean;
  residence_type?: string;
}

@Injectable({ providedIn: 'root' })
export class FloorPlanService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/floorplans/`;

  list(params?: FloorPlanRequestParams) {
    return this.http.get<ServerResponse<FloorPlan>>(
      `${this.baseUrl}flat_list/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  retrieve(
    floorPlanId: number,
    options?: IDataServiceMethodOptions
  ): Observable<FloorPlan> {
    const ignoreStore = options?.ignoreStore;
    let chain = this.http.get<FloorPlan>(`${this.baseUrl}${floorPlanId}/`);
    if (!ignoreStore) {
      chain = chain.pipe(
        tap(floorPlan =>
          this.store.dispatch(loadFloorplan({ payload: floorPlan }))
        )
      );
    }
    return chain;
  }

  update(floorPlan: FloorPlan | FormData, floorPlanId: number) {
    return this.http
      .patch<FloorPlan>(`${this.baseUrl}${floorPlanId}/`, floorPlan)
      .pipe(
        tap(newFloorPlan =>
          this.store.dispatch(loadFloorplan({ payload: newFloorPlan }))
        )
      );
  }

  create(floorPlan: FloorPlan | FormData) {
    return this.http
      .post<FloorPlan>(`${this.baseUrl}`, floorPlan)
      .pipe(
        tap(newFloorPlan =>
          this.store.dispatch(loadFloorplan({ payload: newFloorPlan }))
        )
      );
  }

  delete(floorPlanId: number) {
    return this.http.delete(`${this.baseUrl}${floorPlanId}/`);
  }

  documents(floorPlanId: number, params?: CustomerDocumentRequestParams) {
    return this.http
      .get<ServerResponse<CustomerDocument>>(
        `${this.baseUrl}${floorPlanId}/documents/`,
        {
          params: this.rollParams(params),
        }
      )
      .pipe(
        tap(serverResponse =>
          this.store.dispatch(
            loadFloorplanDocuments({ payload: serverResponse.results })
          )
        ),
        tap(serverResponse =>
          this.store.dispatch(
            loadFloorplanDocumentsCount({ payload: serverResponse.count })
          )
        )
      );
  }

  documentsCreate(
    floorPlanId: number,
    customerDocument: CustomerDocument | FormData
  ) {
    return this.http.post<CustomerDocument>(
      `${this.baseUrl}${floorPlanId}/documents/`,
      customerDocument
    );
  }

  home_statuses(floorPlanId: number, params?: FloorPlanRequestParams) {
    return this.http
      .get<ServerResponse<EEPAndHomeInfo>>(
        `${this.baseUrl}${floorPlanId}/home_statuses/`,
        {
          params: this.rollParams(params),
        }
      )
      .pipe(
        tap(serverResponse =>
          this.store.dispatch(
            loadFloorplanHomeStatusesCount({ payload: serverResponse.count })
          )
        )
      );
  }

  create_from_blg(formData: FormData) {
    return this.http
      .post<FloorPlan>(`${this.baseUrl}create_from_blg/`, formData)
      .pipe(
        tap(newFloorPlan =>
          this.store.dispatch(loadFloorplan({ payload: newFloorPlan }))
        )
      );
  }
}
