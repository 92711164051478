/*
 * Using in permission services to detect whether we can Edit/Delete
 * something and based on response we can disable UI parts
 * */
export class ObjectPermissionResponse {
  hasPermission: boolean;
  message?: string;

  constructor(hasPermission: boolean, message?: string) {
    this.hasPermission = hasPermission;
    this.message = message;
  }
}
