import { Injectable } from '@angular/core';
import { ListRequestParams } from '@/core/schemes/request-params';
import { BaseDataService } from '@/core/services/base-data-service';
import { environment } from '../../../../environments/environment';
import { AltName } from '@/data/company/models';
import { Observable } from 'rxjs';

export class AltNameRequestParams extends ListRequestParams {}

@Injectable({
  providedIn: 'root',
})
export class AltNameService extends BaseDataService {
  baseUrl = `${environment.AXIS_API_V3_ROOT}/alt_names/`;

  retrieve(altNameId: number): Observable<AltName> {
    return this.http.get<AltName>(`${this.baseUrl}${altNameId}`, {});
  }

  update(altName: AltName, altNameId: number) {
    return this.http.patch<AltName>(`${this.baseUrl}${altNameId}/`, altName);
  }

  delete(altNameId: number) {
    return this.http.delete(`${this.baseUrl}${altNameId}`);
  }
}
