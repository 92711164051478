import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { first, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getInfoUser } from '@/state/selectors/info.selector';
import { AppState } from '@/state/reducers';
import { BasePermissionService } from '@/core/services/base-permission-service';
import { AccreditationService } from '@/data/user-management/services/accreditation.service';
import {
  Accreditation,
  AccreditationState,
} from '@/data/user-management/models';
import { User } from '@/data/core/models/user';

@Injectable({
  providedIn: 'root',
})
export class AccreditationPermissionService extends BasePermissionService {
  applicableCompanySlugs: string[] = [
    'provider-washington-state-university-extension-ene',
    'provider-home-innovation-research-labs',
  ];

  constructor(
    public store: Store<AppState>,
    private accreditationService: AccreditationService
  ) {
    super(store);
    this.serviceCls = this.accreditationService;
  }

  canCreate(): Observable<ObjectPermissionResponse> {
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
    }).pipe(
      map(({ currentUser }: { currentUser: User }) => {
        if (currentUser.is_superuser) {
          return new ObjectPermissionResponse(true);
        }
        if (
          this.applicableCompanySlugs.includes(currentUser.company_info?.slug)
        ) {
          return new ObjectPermissionResponse(true);
        }
        return new ObjectPermissionResponse(false);
      })
    );
  }

  canEdit(
    accreditation: Accreditation | number
  ): Observable<ObjectPermissionResponse> {
    const accreditation$: Observable<Accreditation> =
      this.resolveEntity<Accreditation>(accreditation);
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: accreditation$,
    }).pipe(
      map(
        ({
          currentUser,
          entity,
        }: {
          currentUser: User;
          entity: Accreditation;
        }) => {
          if (currentUser.is_superuser) {
            return new ObjectPermissionResponse(true);
          }
          if (
            this.applicableCompanySlugs.includes(currentUser.company_info?.slug)
          ) {
            return new ObjectPermissionResponse(true);
          }
          return new ObjectPermissionResponse(false);
        }
      )
    );
  }

  canDelete(
    accreditation: Accreditation | number
  ): Observable<ObjectPermissionResponse> {
    const accreditation$: Observable<Accreditation> =
      this.resolveEntity<Accreditation>(accreditation);

    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: accreditation$,
    }).pipe(
      map(
        ({
          currentUser,
          entity,
        }: {
          currentUser: User;
          entity: Accreditation;
        }) => {
          if (currentUser.is_superuser) {
            return new ObjectPermissionResponse(true);
          }
          if (
            this.applicableCompanySlugs.includes(currentUser.company_info?.slug)
          ) {
            return new ObjectPermissionResponse(true);
          }
          return new ObjectPermissionResponse(false);
        }
      )
    );
  }

  canGenerateCertificate(
    accreditation: Accreditation | number
  ): Observable<ObjectPermissionResponse> {
    const accreditation$: Observable<Accreditation> =
      this.resolveEntity<Accreditation>(accreditation);

    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: accreditation$,
    }).pipe(
      map(
        ({
          currentUser,
          entity,
        }: {
          currentUser: User;
          entity: Accreditation;
        }) => {
          if (!entity.expiration_date) {
            return new ObjectPermissionResponse(
              false,
              'Expiration date is not set'
            );
          }

          if (entity.is_expired) {
            return new ObjectPermissionResponse(false, 'Accreditation expired');
          }

          if (
            ![
              AccreditationState.Active,
              AccreditationState.ProbationNew,
              AccreditationState.ProbationDisciplinary,
            ].includes(entity.state)
          ) {
            return new ObjectPermissionResponse(false);
          }

          if (currentUser.is_superuser) {
            return new ObjectPermissionResponse(true);
          }
          if (
            this.applicableCompanySlugs.includes(currentUser.company_info?.slug)
          ) {
            return new ObjectPermissionResponse(true);
          }

          if (
            currentUser.id === entity.trainee ||
            currentUser.id === entity.approver
          ) {
            return new ObjectPermissionResponse(true);
          }

          return new ObjectPermissionResponse(false);
        }
      )
    );
  }
}
