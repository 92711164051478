<mat-expansion-panel [expanded]="expanded">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <strong>Infiltration</strong>
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
        <div class="card position-relative" appFocusTarget entityName="infiltration" entityId="{{entityId}}">
            <app-loading-overlay *ngIf="loading"></app-loading-overlay>
            <div class="card-body pb-0">
                <app-form formName="inflitrationForm" [formGroup]="form" (save)="onSave($event)">
                    <div>
                        <app-select formControlName="measurement_type" [label]="fieldLabels.measurement_type" [items]="measurementTypeLabels"></app-select>
                    </div>
                    <div>
                        <app-input type="number" formControlName="infiltration_value" [label]="fieldLabels.infiltration_value"></app-input>
                        <app-select [label]="fieldLabels.infiltration_unit" formControlName="infiltration_unit" [items]="infiltrationUnitLabels"></app-select>
                    </div>
                    <div>
                        <app-select type="number" [label]="fieldLabels.shelter_class" formControlName="shelter_class" [items]="classLabels"></app-select>
                        <app-select [label]="fieldLabels.verification_type" formControlName="verification_type" [items]="verificationTypeLabels"></app-select>
                    </div>
                    <div *ngIf="this.form.controls.verification_type.value === 'tested'">
                        <app-input type="number" [label]="fieldLabels.tested_infiltration_value" formControlName="tested_infiltration_value"></app-input>
                        <app-select [label]="fieldLabels.tested_infiltration_unit" formControlName="tested_infiltration_unit" [items]="infiltrationUnitLabels"></app-select>
                    </div>
                </app-form>
            </div>
        </div>
    </ng-template>
</mat-expansion-panel>