import { selectAboveGradeWallErrorViewSet } from '@/modules/simulation/state/above-grade-wall/selectors';
import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-above-grade-wall-errors',
  templateUrl: './above-grade-wall-errors.component.html',
  styleUrls: ['./above-grade-wall-errors.component.scss'],
})
export class AboveGradeWallErrorsComponent {
  @Input() entityId: number;
  componentDestroyed$: Subject<boolean> = new Subject();
  enable = false;
  name: string;
  totalErrorCount = 0;
  totalWarningCount = 0;
  wallTypeId: number;
  wallTypeErrorCount = 0;
  wallTypeWarningCount = 0;
  constructor(public store: Store) {}

  ngOnInit(): void {
    this.enable = true;
    this.store
      .select(selectAboveGradeWallErrorViewSet(this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.name = data.name;
        this.wallTypeId = data.wallTypeId;

        const nestedModelsErrorCount = data.nestedModelsErrorCount;
        this.wallTypeErrorCount =
          nestedModelsErrorCount.aboveGradeWallType.errorCount;
        this.wallTypeWarningCount =
          nestedModelsErrorCount.aboveGradeWallType.warningCount;

        this.totalErrorCount = 0;
        this.totalErrorCount += this.wallTypeErrorCount;
        this.totalErrorCount +=
          nestedModelsErrorCount.aboveGradeWall.errorCount;

        this.totalWarningCount = 0;
        this.totalWarningCount += this.wallTypeWarningCount;
        this.totalWarningCount +=
          nestedModelsErrorCount.aboveGradeWall.warningCount;
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
