import { createAction, props } from '@ngrx/store';
import { User } from '@/data/core/models/user';
import { CompanyAccess } from '@/data/company/models';
import { UserCertificationMetric, UserRaterRole } from '@/data/core/models';
import {
  Accreditation,
  CustomerHIRLInspectionGradeList,
} from '@/data/user-management/models';
import { Task } from '@/data/scheduling/models';

export const resetUser = createAction('[User] Reset');

export const loadUser = createAction(
  '[User] Detail',
  props<{ payload: User }>()
);

// Company Access
export const loadUserCompanyAccessesAction = createAction(
  '[User] CompanyAccess',
  props<{ payload: CompanyAccess[] }>()
);

export const loadUserCompanyAccessCountAction = createAction(
  '[User] CompanyAccess Count',
  props<{ payload: number }>()
);

export const deleteUserCompanyAccessAction = createAction(
  '[User] Delete CompanyAccess',
  props<{ payload: CompanyAccess }>()
);

// Rater Role
export const loadUserRaterRolesAction = createAction(
  '[User] Rater Roles',
  props<{ payload: UserRaterRole[] }>()
);

export const loadUserRaterRolesCountAction = createAction(
  '[User] Rater Roles Count',
  props<{ payload: number }>()
);

// Accreditations
export const loadUserAccreditationsAction = createAction(
  '[User] Accreditations',
  props<{ payload: Accreditation[] }>()
);

export const loadUserAccreditationsCountAction = createAction(
  '[User] Accreditations Count',
  props<{ payload: number }>()
);

// Inspection Grades
export const loadUserCustomerHIRLInspectionGradesAction = createAction(
  '[User] Customer HIRL Inspection Grades',
  props<{ payload: CustomerHIRLInspectionGradeList[] }>()
);

export const loadUserCustomerHIRLGradesCountAction = createAction(
  '[User] Customer HIRL Inspection Grades Count',
  props<{ payload: number }>()
);

// Scheduling Tasks
export const loadUserTasksAction = createAction(
  '[User] Tasks',
  props<{ payload: Task[] }>()
);

export const loadUserTasksCountAction = createAction(
  '[User] Tasks Count',
  props<{ payload: number }>()
);

// Trainings
export const loadUserTrainingsAction = createAction(
  '[User] Trainings',
  props<{ payload: Task[] }>()
);

export const loadUserTrainingsCountAction = createAction(
  '[User] Trainings Count',
  props<{ payload: number }>()
);

// EEP Program Home Statuses
export const loadUserEEPProgramHomeStatusCertificationMetricsAction =
  createAction(
    '[User] EEPProgramHomeStatus Certification Metrics',
    props<{ payload: UserCertificationMetric[] }>()
  );

export const loadUserEEPProgramHomeStatusCertificationMetricsCountAction =
  createAction(
    '[User] EEPProgramHomeStatus Certification Metrics Count',
    props<{ payload: number }>()
  );
