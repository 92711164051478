import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { environment } from '../../../../environments/environment';
import { Geocode, IGeocodeMatches } from '@/data/geocoder/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GeocoderService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/geocoder/`;

  constructor(private http: HttpClient, private store: Store<AppState>) {}

  matches(data: IGeocodeMatches): Observable<Geocode> {
    return this.http.post<Geocode>(`${this.baseUrl}matches/`, data);
  }
}
