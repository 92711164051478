import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd, UrlTree } from '@angular/router';

/*
    NavigationService must be Injected in every page for correct back button work
 */

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private history: string[] = [];
  private maxLength = 50; // Define the max length for the history list

  constructor(private router: Router, private location: Location) {
    if (this.history.length === 0) {
      this.history.push(location.path());
    }
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
        if (this.history.length > this.maxLength) {
          this.history.shift(); // Remove the oldest entry if max length is exceeded
        }
      }
    });
  }

  back(fallbackUrl: string | UrlTree = '/'): void {
    this.history.pop();
    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl(fallbackUrl);
    }
  }
}
