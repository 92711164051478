import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import * as RoofActions from './actions';
import * as RoofTypeActions from '../roof-type/actions';
import * as SimulationActions from '../simulation/actions';
import * as SharedActions from '../shared/shared.actions';
import { RoofService } from '@/data/simulation/services/roof.service';
import { RoofTypeService } from '@/data/simulation/services/roof-type.service';
import { RoofTypeBackendDict } from '@/data/simulation/models/enclosure/RoofType';
import { RoofBackendDict } from '@/data/simulation/models/enclosure/Roof';
import { ModelGraphService } from '../../services/model-graph.service';
import { RoofValidator } from '../../validators/roof.validator';
import { StateModelName } from '../../state.registry';

@Injectable()
export class RoofEffects {
  loadDetailedRoofs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RoofActions.loadDetailedRoofs),
      mergeMap(action => {
        const detailedRoofTypes: RoofTypeBackendDict[] = [];
        const roofs: RoofBackendDict[] = [];
        action.detailedRoofs.forEach(detailedRoof => {
          const { type_info: detailedRoofType, ...roof } = detailedRoof;
          if (detailedRoofType) {
            detailedRoofTypes.push(detailedRoofType);
          }
          this.modelGraphService.attachModel(
            'roof',
            detailedRoof.id,
            'roofType',
            [detailedRoof.type]
          );
          roofs.push(roof);
        });

        const errors = RoofValidator.validate(action.detailedRoofs);

        return of(
          RoofActions.loadRoofsSuccess({
            roofs: roofs,
            errors: errors,
          }),
          RoofTypeActions.loadDetailedRoofTypes({
            roofTypes: detailedRoofTypes,
          })
        );
      })
    );
  });

  updateRoof$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RoofActions.updateRoof),
      mergeMap(action =>
        this.roofService.update(action.roofChanges).pipe(
          mergeMap(updatedRoof => {
            const errors = RoofValidator.validate([updatedRoof]);
            return of(
              RoofActions.updateRoofSuccess({
                roof: updatedRoof,
                errors: errors[updatedRoof.id],
              }),
              SharedActions.updateCrossModelErrors({
                modelName: StateModelName.roof,
                changedFields: Object.keys(action.roofChanges),
              })
            );
          }),
          catchError(error =>
            of(
              RoofActions.updateRoofFailure({
                id: action.roofChanges.id,
              }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        )
      )
    );
  });

  removeRoof$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RoofActions.removeRoof),
      mergeMap(action =>
        this.roofService.delete(action.roof.id).pipe(
          mergeMap(() =>
            of(
              RoofActions.removeRoofSuccess({ id: action.roof.id }),
              SimulationActions.removeItemFromList({
                fieldName: 'roofs',
                id: action.roof.id,
              })
            )
          ),
          catchError(error =>
            of(
              RoofActions.removeRoofFailure({ id: action.roof.id }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        )
      )
    );
  });

  removeRoofType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RoofTypeActions.removeRoofType),
      mergeMap(action =>
        this.roofTypeService.delete(action.roofTypeId).pipe(
          mergeMap(() => [
            RoofActions.setRoofType({
              roofId: action.roofId,
              roofTypeId: null,
            }),
            RoofTypeActions.removeRoofTypeSuccess({ id: action.roofTypeId }),
          ]),
          catchError(error => of(SharedActions.reportAPIFailure({ error })))
        )
      )
    );
  });

  addRoofType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RoofTypeActions.addRoofType),
      mergeMap(action =>
        this.roofTypeService.create(action.roofId, action.roofType).pipe(
          mergeMap(roofType =>
            of(
              RoofTypeActions.loadDetailedRoofTypes({ roofTypes: [roofType] }),
              RoofActions.setRoofType({
                roofId: action.roofId,
                roofTypeId: roofType.id,
              })
            )
          ),
          catchError(error => of(SharedActions.reportAPIFailure({ error })))
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private modelGraphService: ModelGraphService,
    private roofService: RoofService,
    private roofTypeService: RoofTypeService
  ) {}
}
