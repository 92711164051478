<div class="main">
    <div class="tabs-container">
        <div class="d-flex flex-column ml-3 mt-2">
            <div *ngFor="let tab of TABS" class="d-flex flex-column mt-1">
                <span><strong>{{tab}}</strong></span>
                <div *ngFor="let subTab of entitiesHavingLibrary[tab]" [ngClass]="{ 'active-sub-tab': (subTab === activeItem) }" class="border-right">
                    <div class="pl-4 pr-2 sub-tab-link" (click)="onClick(subTab)">
                        {{stateModelNameLabels[subTab]}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="active-content-container position-relative mb-5">
        <app-loading-overlay *ngIf="loading" diameter="150"></app-loading-overlay>
        <div class="result-header">
            <h6 class="m-0">{{rowsCount}} {{stateModelNameLabels[activeItem]}} found</h6>
            <button mat-raised-button (click)="onAdd()" [disabled]="addingNewItemInProgress" color="accent">
                <ng-container *ngIf="addingNewItemInProgress">
                    <fa-icon [icon]="['fas', 'spinner']" [spin]="true"></fa-icon> Adding
                </ng-container>
                <ng-container *ngIf="!addingNewItemInProgress">
                    <mat-icon>add</mat-icon> Add
                </ng-container>
            </button>
        </div>
        <mat-list class="d-flex flex-column w-100" *ngIf="!loading">
            <mat-list-item *ngFor="let item of items; let i = index" class="item-container">
                <div class="item w-100">
                    <div class="d-flex align-items-center">
                        <span>{{i + 1 + (storedParams.page_size * (storedParams.page - 1))}}.  </span>
                        <button mat-button (click)="edit(item)" [disabled]="activeItemIndex === i">{{item.name | truncate : 50 }}</button>
                    </div>
                    <div class="d-flex">
                        <button mat-icon-button (click)="onDelete(item, i)" [disabled]="activeItemIndex === i">
                            <ng-container *ngIf="activeItemIndex === i">
                                <fa-icon [icon]="['fas', 'spinner']" [spin]="true"></fa-icon>
                            </ng-container>
                            <ng-container *ngIf="activeItemIndex !== i">
                                <mat-icon mat-button [inline]="true">delete</mat-icon>
                            </ng-container>
                        </button>
                    </div>
                </div>
            </mat-list-item>
        </mat-list>
    </div>
    <div class="paginator-container">
        <mat-paginator
            [length]="rowsCount"
            [pageIndex]="$any(storedParams.page) - 1"
            [pageSize]="storedParams.page_size"
            [hidePageSize]="true"
            (page)="onPaginateChange($event)" aria-label="Select page">
        </mat-paginator>
    </div>

</div>