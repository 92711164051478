<div class="row" [formGroup]="filterFormGroup">
  <div class="col-4">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Years</mat-label>
      <mat-select formControlName="years" [multiple]="true">
        <mat-option *ngFor="let year of yearChoices" [value]="year">{{ year }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-4">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Project Type</mat-label>
      <mat-select formControlName="project_type">
        <mat-option [value]="''">
          Any
        </mat-option>
        <mat-option *ngFor="let item of typeLabelMapping | keyvalue " [value]="item.key">
          {{ item.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div *ngIf="!isLoading else isLoadingContainer">
  <div class="row">
    <div class="col-12">
      <div>Totals:</div>
      <span *ngFor="let item of totalResultsPerYear"><b>{{ item.name }}</b>: {{ item.value | number:'1.0-0' }} </span>
      <div>Average:</div>
      <span *ngFor="let item of avgResultsPerYear"><b>{{ item.name }}</b>: {{ item.value | number:'1.2-2' }} </span>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="metrics-chart-wrapper">
            <ngx-charts-line-chart
              [legendTitle]="'Years'"
              [legend]="true"
              [showXAxisLabel]="true"
              [showYAxisLabel]="true"
              [xAxis]="true"
              [yAxis]="true"
              [xAxisLabel]="'Month'"
              [yAxisLabel]="'Registrations'"
              [results]="results"
            >
            </ngx-charts-line-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #isLoadingContainer>
  <div class="row">
    <div class="col-12 text-center">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-center">
            <div class="d-flex flex-column justify-content-center metrics-chart-wrapper">
              <mat-spinner [diameter]="50"></mat-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
