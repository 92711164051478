import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@/shared/shared.module';
import { EquipmentChangeDialogComponent } from './components/equipment-change-dialog/equipment-change-dialog.component';
import { FilehandlingModule } from '@/modules/filehandling/filehandling.module';

@NgModule({
  declarations: [EquipmentChangeDialogComponent],
  imports: [CommonModule, SharedModule, FilehandlingModule],
})
export class EquipmentModule {}
