import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DehumidifierService } from '@/data/simulation/services/dehumidifier.service';
import { catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as DehumidifierActions from './actions';
import * as SharedActions from '../shared/shared.actions';
import { DehumidifierValidator } from '../../validators/dehumidifier.validator';

@Injectable()
export class DehumidifierEffects {
  loadDehumidifiers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DehumidifierActions.loadDehumidifiers),
      mergeMap(action => {
        const errors = DehumidifierValidator.validate(action.dehumidifiers);
        return of(
          DehumidifierActions.loadDehumidifiersSuccess({
            dehumidifiers: action.dehumidifiers,
            errors,
          })
        );
      })
    );
  });

  updateDehumidifier$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DehumidifierActions.updateDehumidifier),
      mergeMap(action => {
        return this.dehumidifierService.update(action.dehumidifierChanges).pipe(
          mergeMap(dehumidifier => {
            const errors = DehumidifierValidator.validate([dehumidifier]);
            return of(
              DehumidifierActions.loadDehumidifiersSuccess({
                dehumidifiers: [dehumidifier],
                errors,
              })
            );
          }),
          catchError(error =>
            of(
              DehumidifierActions.updateDehumidifierFailure({
                id: action.dehumidifierChanges.id,
              }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private dehumidifierService: DehumidifierService
  ) {}
}
