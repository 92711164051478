<div class="container-lg" *ngIf="initialized">
  <div class="row">
    <div class="col-12">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#" [routerLink]="['/', ]">Home</a></li>
          <li class="breadcrumb-item"><a href="#" [routerLink]="['/', 'home', 'apex', 'request', 'list']">APEX Requests</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            APEX Simulation Request
          </li>
        </ol>
      </nav>
    </div>
  </div>
  <div class="d-flex-column mb-2">
    <div class="d-flex flex-row">
      <div class="flex-fill align-self-end">
        <div class="d-flex">
          <div class="mb-0 align-self-end">
            <button mat-raised-button color="accent" appBackButton [fallbackUrl]="'/home/apex/request/list'">
              <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
            </button>
          </div>
          <img
            class="ml-2"
            src="assets/apex_logo.svg"
            width="220"
          />
          <h3 class="ml-2 mb-0 align-self-end" style="line-height: 1;">
            Simulation Request #{{ apexRequest.id }}
          </h3>
        </div>
      </div>
      <div class="text-right mb-0 align-self-end">
        <app-permission-mat-button [permissionResponse]="downloadSummaryResultPermissionResponse" (action)="onDownloadSummary($event)">Download All Results</app-permission-mat-button>
      </div>
    </div>
    <mat-divider class="mt-1 mb-2"></mat-divider>
    <div class="d-flex flex-row">
      <div class="d-flex-column flex-fill">
        <div class="d-flex flex-row">
          <div class="apex-request-attr-name">
            Builder Organization:
          </div>
          <div class="flex-fill">
            <app-company-display displayType="onlyNameAsLink" [company]="apexRequest.builder_organization_info"></app-company-display>
          </div>
        </div>
        <div class="d-flex flex-row">
          <div class="apex-request-attr-name">
            Subdivision:
          </div>
          <div class="flex-fill">
            <app-subdivision-display displayType="nameAndBuilderAsLink" [subdivision]="apexRequest.subdivision_info"></app-subdivision-display>
          </div>
        </div>
        <div class="d-flex flex-row">
          <div class="apex-request-attr-name">
            Analysis Type:
          </div>
          <div class="flex-fill">
            {{ ApexRequestTypeLabelMapping[apexRequest.request_type] }}
          </div>
        </div>
        <div class="d-flex flex-row">
          <div class="apex-request-attr-name">
            Energy Model Type:
          </div>
          <div class="flex-fill">
            {{ ApexRequestEnergyModelTypeLabelMapping[apexRequest.energy_model_type] }}
          </div>
        </div>
        <div class="d-flex flex-row">
          <div class="apex-request-attr-name">
            Created by:
          </div>
          <div class="flex-fill">
            <app-user-display [user]="apexRequest.user_info" displayType="onlyNameAsLink"></app-user-display>
          </div>
        </div>
        <div class="d-flex flex-row mb-5">
          <div class="apex-request-attr-name">
            Created at:
          </div>
          <div class="flex-fill">
            {{ apexRequest.created_at | date : "MM/dd/yyyy" }}
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <mat-tab-group color="accent" style="min-height: 450px;" disableRipple="true" animationDuration="0ms"
                     dynamicHeight (selectedTabChange)="onTabChange($event)" [selectedIndex]="selectedIndex">
        <mat-tab label="Users">
          <ng-template mat-tab-label>
            Documents
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" class="ml-1"
                     *ngIf="apexRequestDocumentsCount === -1"></fa-icon>
            <span class="badge badge-primary ml-1" *ngIf="apexRequestDocumentsCount !== -1">
                            {{ apexRequestDocumentsCount }}
                        </span>
          </ng-template>
          <div class="mt-2 mb-5">
            <app-apex-request-document-list></app-apex-request-document-list>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
