import { createSelector } from '@ngrx/store';
import { SimulationState } from '../reducers';
import { selectSimulationFeature } from '../simulation.selectors';
import { WindowBackendDict } from '@/data/simulation/models/enclosure/Window';

export const selectWindow = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.window.entities[id]
  );
const selectWindowLoadingStatus = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.window.loading[id]
  );

export const selectWindowTypeId = (id: number) =>
  createSelector(selectWindow(id), window => window.type);

const selectWindowErrorCount = (id: number) =>
  createSelector(selectSimulationFeature, (state: SimulationState) => ({
    errorCount: state.window.errors[id].errorCount,
    warningCount: state.window.errors[id].warningCount,
  }));

export const selectWindowViewSet = (id: number) =>
  createSelector(
    selectWindow(id),
    selectWindowLoadingStatus(id),
    (window, loadingStatus) => ({
      data: window,
      loading: loadingStatus,
    })
  );

export const selectWindowName = (id: number) =>
  createSelector(selectWindow(id), (window: WindowBackendDict) => window.name);

export const selectWindowNestedErrorCount = (id: number) =>
  createSelector(
    selectSimulationFeature,
    selectWindowErrorCount(id),
    selectWindowTypeId(id),
    (state, errorCount, windowTypeId) => {
      const windowTypeErrorCount = {
        errorCount: 0,
        warningCount: 0,
      };
      if (windowTypeId !== null) {
        windowTypeErrorCount.errorCount =
          state.windowType.errors[windowTypeId].errorCount;
        windowTypeErrorCount.warningCount =
          state.windowType.errors[windowTypeId].warningCount;
      }

      return {
        window: errorCount,
        windowType: windowTypeErrorCount,
      };
    }
  );

export const selectWindowErrorViewSet = (id: number) =>
  createSelector(
    selectWindowNestedErrorCount(id),
    selectWindowTypeId(id),
    selectWindowName(id),
    (nestedModelsErrorCount, windowTypeId, name) => ({
      nestedModelsErrorCount,
      windowTypeId,
      name,
    })
  );
