import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from '@/data/core/models/user';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  HIRLGreenEnergyBadge,
  HIRLProject,
  HIRLProjectAppealsProjectTypeMapping,
  HIRLProjectRegistrationState,
  HIRLProjectSampling,
  HIRLProjectSamplingMapping,
} from '@/data/customer-hirl/models';
import { forkJoin, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import {
  HIRLGreenEnergyBadgeRequestParams,
  HIRLGreenEnergyBadgeService,
} from '@/data/customer-hirl/services/hirl-green-energy-badge.service';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '@/data/core/services/user.service';
import { toggleLoading } from '@/state/actions/app.actions';
import { getInfoUser } from '@/state/selectors/info.selector';
import { distinctUntilChanged, first, map, takeUntil } from 'rxjs/operators';
import { HIRLProjectService } from '@/data/customer-hirl/services/hirl-projects.service';
import { conditionalValidator } from '@/core/validators';
import { HomeAddressConfirmedGeocodeResponse } from '@/modules/geocoder/components/home-address-to-geocode-response-dialog/home-address-to-geocode-response-dialog.component';
import {
  ServerErrorDialogComponent,
  ServerErrorDialogConfig,
} from '@/shared/components/server-error-dialog/server-error-dialog.component';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';
import { HIRLProjectAppealsProjectType } from '@/data/customer-hirl/models/hirl-project';

@Component({
  selector: 'app-hirl-project-sf-form',
  templateUrl: './hirl-project-sf-form.component.html',
  styleUrls: ['./hirl-project-sf-form.component.scss'],
})
export class HIRLProjectSFFormComponent implements OnInit, OnDestroy {
  protected readonly HIRLProjectSamplingMapping = HIRLProjectSamplingMapping;
  protected readonly HIRLProjectAppealsProjectTypeMapping =
    HIRLProjectAppealsProjectTypeMapping;

  public initialized = false;
  public currentUser: User;
  public projectFormGroup: UntypedFormGroup;

  public hirlProject?: HIRLProject;

  public hirlGreenEnergyBadges: HIRLGreenEnergyBadge[] = [];

  public HIRLProjectRegistrationState = HIRLProjectRegistrationState;

  public customerHIRLSettings = CustomerHIRLSettings;

  private destroy$ = new Subject();

  constructor(
    public fb: UntypedFormBuilder,
    public store: Store<AppState>,
    public router: Router,
    public hirlProjectService: HIRLProjectService,
    public hirlGreenEnergyBadgeService: HIRLGreenEnergyBadgeService,
    public dialog: MatDialog,
    public activatedRoute: ActivatedRoute,
    public userService: UserService
  ) {}

  public ngOnInit(): void {
    this.store.dispatch(toggleLoading({ payload: true }));

    this.hirlProjectService
      .retrieve(this.activatedRoute.snapshot.params.projectId)
      .pipe(takeUntil(this.destroy$), first())
      .subscribe(hirlProject => {
        this.hirlProject = hirlProject;

        const greenEnergyBadgeRequestParams =
          new HIRLGreenEnergyBadgeRequestParams();
        greenEnergyBadgeRequestParams.eep_program =
          this.hirlProject.registration_info.eep_program;
        const sources: { [k: string]: any } = {
          currentUser: this.store.select(getInfoUser).pipe(first()),
          hirlGreenEnergyBadges: this.hirlGreenEnergyBadgeService
            .list(greenEnergyBadgeRequestParams)
            .pipe(
              first(),
              map(data => data.results)
            ),
        };

        forkJoin(sources).subscribe(
          ({ currentUser, hirlGreenEnergyBadges }) => {
            this.currentUser = currentUser;
            this.hirlGreenEnergyBadges = hirlGreenEnergyBadges;

            this.hydrateForm();
            this.store.dispatch(toggleLoading({ payload: false }));
            this.initialized = true;
          }
        );
      });

    this.setupProjectFormGroup();
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public attachFieldCheckHomeWithAddressAlreadyExists(
    formGroup,
    fieldName,
    isMultiFamily
  ) {
    formGroup
      .get(fieldName)
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(confirmedGeocodeResponse => {
        if (!confirmedGeocodeResponse) {
          return;
        }
        this.store.dispatch(toggleLoading({ payload: true }));
        this.hirlProjectService
          .homeAddressIsUnique({
            project: this.hirlProject?.id,
            street_line1: confirmedGeocodeResponse.addressInfo.street_line1,
            street_line2: confirmedGeocodeResponse.addressInfo.street_line2,
            city: confirmedGeocodeResponse.addressInfo.city_info.id,
            zipcode: confirmedGeocodeResponse.addressInfo.zipcode,
            is_multi_family: isMultiFamily,
            geocode_response: confirmedGeocodeResponse.geocodeBrokeredResponse
              ? confirmedGeocodeResponse.geocodeBrokeredResponse.id
              : null,
          })
          .pipe(first())
          .subscribe(isUnique => {
            this.store.dispatch(toggleLoading({ payload: false }));
            if (isUnique) {
              formGroup
                .get(fieldName)
                .setErrors({ home_with_this_address_already_exists: null });
              formGroup.get(fieldName).updateValueAndValidity({
                emitEvent: false,
              });
            } else {
              formGroup
                .get(fieldName)
                .setErrors({ home_with_this_address_already_exists: true });
            }
          });
      });
  }

  public setupProjectFormGroup() {
    this.projectFormGroup = this.fb.group({
      registration_info: [null, Validators.required],
      edit_address: [null, [Validators.required]],
      accessory_structure_description: [
        '',
        conditionalValidator(
          () => this.hirlProject.is_accessory_structure,
          Validators.required
        ),
      ],
      accessory_dwelling_unit_description: [
        '',
        conditionalValidator(
          () => this.hirlProject.is_accessory_dwelling_unit,
          Validators.required
        ),
      ],
      green_energy_badges: [[]],
      hud_disaster_case_number: [''],
      is_require_water_sense_certification: [false],
      is_require_rough_inspection: [true],
      is_require_wri_certification: [false],
      appeals_project: [HIRLProjectAppealsProjectType.no],
      sampling: [
        HIRLProjectSampling.no_sampling,
        conditionalValidator(
          () => this.hirlProject.registration_info.is_build_to_rent,
          Validators.required
        ),
      ],
    });

    this.attachFieldCheckHomeWithAddressAlreadyExists(
      this.projectFormGroup,
      'edit_address',
      false
    );
  }

  hydrateForm() {
    this.projectFormGroup.patchValue(this.hirlProject, {
      emitEvent: false,
      onlySelf: true,
    });
    this.projectFormGroup.patchValue(
      {
        edit_address: new HomeAddressConfirmedGeocodeResponse({
          lotNumber: this.hirlProject.lot_number,
          geocode: this.hirlProject.home_address_geocode_info,
          geocodeBrokeredResponse:
            this.hirlProject.home_address_geocode_response_info,
        }),
      },
      { emitEvent: false, onlySelf: true }
    );
  }

  handleEdit(event): void {
    event.preventDefault();
    this.projectFormGroup.markAllAsTouched();

    if (this.projectFormGroup.invalid) {
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const formData = new HIRLProject();
    formData.lot_number = this.projectFormGroup.value.edit_address.lotNumber;
    formData.home_address_geocode =
      this.projectFormGroup.value.edit_address.geocode.id;
    formData.is_require_water_sense_certification =
      this.projectFormGroup.value.is_require_water_sense_certification;
    formData.appeals_project = this.projectFormGroup.value.appeals_project;
    formData.sampling = this.projectFormGroup.value.sampling;

    if (
      this.currentUser.company_info?.slug === CustomerHIRLSettings.companySlug
    ) {
      formData.registration = this.projectFormGroup.value.registration_info.id;
    }

    formData.home_address_geocode_response = null;

    if (this.projectFormGroup.value.edit_address.geocodeBrokeredResponse) {
      formData.home_address_geocode_response =
        this.projectFormGroup.value.edit_address.geocodeBrokeredResponse.id;
    }
    formData.hud_disaster_case_number =
      this.projectFormGroup.value.hud_disaster_case_number;

    if (
      this.customerHIRLSettings.waterSenseProgramList.includes(
        this.hirlProject.registration_info?.eep_program_info?.slug
      )
    ) {
      formData.is_require_wri_certification =
        this.projectFormGroup.value.is_require_wri_certification;
    } else {
      formData.is_require_wri_certification = false;
    }

    if (
      this.projectFormGroup.value.edit_address.geocode?.raw_city_info
        ?.country_info?.abbr === 'PR' ||
      this.projectFormGroup.value.edit_address.geocode?.raw_city_info
        ?.country_info?.abbr === 'VI'
    ) {
      formData.is_require_rough_inspection = false;
    } else {
      formData.is_require_rough_inspection =
        this.projectFormGroup.value.is_require_rough_inspection;
    }

    if (this.hirlProject.is_accessory_structure) {
      formData.accessory_structure_description =
        this.projectFormGroup.value.accessory_structure_description;
    } else if (this.hirlProject.is_accessory_dwelling_unit) {
      formData.accessory_dwelling_unit_description =
        this.projectFormGroup.value.accessory_dwelling_unit_description;
    } else {
      formData.green_energy_badges =
        this.projectFormGroup.value.green_energy_badges;
    }

    this.hirlProjectService.update(formData, this.hirlProject.id).subscribe(
      hirlProject => {
        this.store.dispatch(toggleLoading({ payload: false }));
        this.router.navigate([
          `/hi/project_registrations/${hirlProject.registration_info.id}`,
        ]);
      },
      error => {
        this.store.dispatch(toggleLoading({ payload: false }));
        this.dialog.open(ServerErrorDialogComponent, {
          width: '400px',
          data: {
            title: 'Error:',
            data: error,
          } as ServerErrorDialogConfig,
        });
      }
    );
  }
}
