import { ListRequestParams } from '@/core/schemes/request-params';
import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import { ServerResponse } from '@/core/schemes/server-response';
import {
  CustomerHIRLInspectionGradeList,
  InspectionGrade,
  InspectionGradeAggregateByLetterGrade,
} from '@/data/user-management/models';
import { AsynchronousProcessedDocument } from '@/data/filehandling/models';
import { Observable } from 'rxjs';

export class InspectionGradeRequestParams extends ListRequestParams {
  graded_date__lte?: string;
  graded_date__gte?: string;
  letter_grade?: string;
  user?: number | number[];
  approver?: number | number[];

  qa_status__requirement__type?: string;
  qa_status__home_status__eep_program?: number | number[];
}

@Injectable({
  providedIn: 'root',
})
export class InspectionGradeService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/inspection_grades/`;

  list(params?: InspectionGradeRequestParams) {
    return this.http.get<ServerResponse<InspectionGrade>>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }

  update(
    inspectionGrade: InspectionGrade | FormData,
    inspectionGradeId: number,
    options?: IDataServiceMethodOptions
  ): Observable<InspectionGrade> {
    const ignoreStore = options?.ignoreStore;
    const chain: Observable<InspectionGrade> = this.http.patch<InspectionGrade>(
      `${this.baseUrl}${inspectionGradeId}/`,
      inspectionGrade
    );
    return chain;
  }

  delete(
    entityId: any,
    options?: IDataServiceMethodOptions
  ): Observable<InspectionGrade> {
    return this.http.delete<InspectionGrade>(`${this.baseUrl}${entityId}/`);
  }

  customer_hirl_list(params?: InspectionGradeRequestParams) {
    return this.http.get<ServerResponse<CustomerHIRLInspectionGradeList>>(
      `${this.baseUrl}customer_hirl_list/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  create_approver_report(params?: InspectionGradeRequestParams) {
    return this.http.get<AsynchronousProcessedDocument>(
      `${this.baseUrl}create_approver_report/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  aggregate_by_letter_grade(params?: InspectionGradeRequestParams) {
    return this.http.get<InspectionGradeAggregateByLetterGrade>(
      `${this.baseUrl}aggregate_by_letter_grade/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  customer_hirl_inspection_grade_quarter_report() {
    return this.http.get<string>(
      `${this.baseUrl}customer_hirl_inspection_grade_quarter_report/`
    );
  }
}
