import {
  PhotovoltaicBackendDict,
  createPhotovoltaicForm,
} from '@/data/simulation/models/generators/Photovoltaic';
import { BaseValidator } from './base.validator';
import { ModelErrors, StateErrors } from '../state/shared/base.state';

export class PhotovoltaicValidator extends BaseValidator {
  static validateForm(
    photovoltaic: PhotovoltaicBackendDict
  ): ModelErrors<PhotovoltaicBackendDict> {
    const photovoltaicForm = createPhotovoltaicForm(photovoltaic);
    return photovoltaicForm.getFieldAndModelErros();
  }

  static validate(
    photovoltaics: PhotovoltaicBackendDict[]
  ): StateErrors<PhotovoltaicBackendDict> {
    const errors = {};
    photovoltaics.forEach(photovoltaic => {
      errors[photovoltaic.id] = this.validateForm(photovoltaic);
    });
    return errors;
  }
}
