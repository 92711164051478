<div mat-dialog-title class="d-flex justify-content-between align-items-center">
    <h2>{{ editorIsVisible ? 'Window type' : 'Select the window' }}</h2>
    <button mat-icon-button aria-label="close dialog" mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <div *ngIf="!editorIsVisible">
        <form [formGroup]="typeSelectForm">
            <mat-form-field class="w-100">
                <mat-label>Type</mat-label>
                <app-generic-model-autocomplete modelClassName="window_type" formControlName="type" [panelWidth]="380"
                    [filterParams]="{library_element: true}">
                </app-generic-model-autocomplete>
            </mat-form-field>
        </form>
    </div>

    <div *ngIf="editorIsVisible">
        <app-window-type [entityId]="windowTypeId"></app-window-type>
    </div>
</mat-dialog-content>