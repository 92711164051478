import { ModelFormControl, ModelFormGroup } from '../ModelForm';
import { Validators } from '@angular/forms';
import { FieldLabels } from '../base';

export enum DhwRecoveryType {
  HOT = 'hot',
  COLD = 'cold',
  BOTH = 'both',
}

export const DhwRecoveryTypeLabels = {
  [DhwRecoveryType.HOT]: 'Hot Water Side',
  [DhwRecoveryType.COLD]: 'Cold Water Side',
  [DhwRecoveryType.BOTH]: 'Both Hot/Cold',
};

export enum RecirculationType {
  TIMER = 'timer',
  TEMPERATURE = 'temp',
  DEMAND = 'presence',
  MANUAL = 'manual',
}

export const RecirculationTypeLabels = {
  [RecirculationType.TIMER]: 'Timer',
  [RecirculationType.TEMPERATURE]: 'Temperature',
  [RecirculationType.DEMAND]: 'Demand (Presence)',
  [RecirculationType.MANUAL]: 'Manual',
};

// Labels
export const FIELD_LABELS: FieldLabels<WaterSystemBackendDict> = {
  low_flow_fixtures_used: 'Uses Low Flow Fixtures',
  pipes_fully_insulated: 'Pipes Fully Insulated',
  max_hot_water_pipe_length: 'Max Hot Water Pipe Length',
  qty_shower_heads: 'Qty Shower Heads',
  has_drain_water_heat_recovery: 'Has Drain Water Heat Recovery',
  efficiency: 'Efficiency',
  recovery_type: 'Recovery Type',
  fixture_factor: 'Fixture Factor',
  has_recirculation_system: 'Has Recirculation System',
  recirculation_type: 'Recirculation Type',
  pipe_loop_length: 'Pipe Loop Length',
  hot_water_branch_length: 'Hot Water Branch Length',
  pump_energy: 'Pump Energy',
  pump_energy_unit: 'Pump Energy Unit',
  pipe_length_unit: 'Pipe Length Unit',
  qty_recirculation_shower_heads: 'Qty of Recirculation Shower Heads',
};

export interface WaterSystemBackendDict {
  id: number;
  low_flow_fixtures_used: boolean;
  pipes_fully_insulated: boolean;
  max_hot_water_pipe_length: number;
  qty_shower_heads: number;
  has_drain_water_heat_recovery: boolean;
  efficiency: number;
  recovery_type: DhwRecoveryType;
  fixture_factor: number;
  has_recirculation_system: boolean;
  recirculation_type: RecirculationType;
  pipe_loop_length: number;
  hot_water_branch_length: number;
  pump_energy: number;
  pump_energy_unit: string;
  pipe_length_unit: string;
  qty_recirculation_shower_heads: number;
}

export function createWaterSystemForm(
  data: WaterSystemBackendDict
): ModelFormGroup {
  return new ModelFormGroup({
    id: new ModelFormControl(data.id),
    low_flow_fixtures_used: new ModelFormControl(
      data.low_flow_fixtures_used,
      Validators.required
    ),
    pipes_fully_insulated: new ModelFormControl(
      data.pipes_fully_insulated,
      Validators.required
    ),
    max_hot_water_pipe_length: new ModelFormControl(
      data.max_hot_water_pipe_length,
      Validators.required
    ),
    qty_shower_heads: new ModelFormControl(data.qty_shower_heads),
    has_drain_water_heat_recovery: new ModelFormControl(
      data.has_drain_water_heat_recovery
    ),
    efficiency: new ModelFormControl(data.efficiency, Validators.required),
    recovery_type: new ModelFormControl(data.recovery_type),
    fixture_factor: new ModelFormControl(
      data.fixture_factor,
      Validators.required
    ),
    has_recirculation_system: new ModelFormControl(
      data.has_recirculation_system
    ),
    recirculation_type: new ModelFormControl(data.recirculation_type),
    pipe_loop_length: new ModelFormControl(
      data.pipe_loop_length,
      Validators.required
    ),
    hot_water_branch_length: new ModelFormControl(
      data.hot_water_branch_length,
      Validators.required
    ),
    pump_energy: new ModelFormControl(data.pump_energy),
    pump_energy_unit: new ModelFormControl(
      data.pump_energy_unit,
      Validators.required
    ),
    pipe_length_unit: new ModelFormControl(
      data.pipe_length_unit,
      Validators.required
    ),
    qty_recirculation_shower_heads: new ModelFormControl(
      data.qty_recirculation_shower_heads
    ),
  });
}
