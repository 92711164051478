<div mat-dialog-title>
    <span *ngIf="data.geocode.valid_responses.length >  1" class="text-center">
        We found multiple addresses
    </span>
    <span *ngIf="data.geocode.valid_responses.length == 1" class="text-center">
        Please confirm Home address
    </span>
    <span *ngIf="data.geocode.valid_responses.length == 0" class="text-center">
        We can't find any valid address on Map. Do you want to continue ?
    </span>
</div>
<div mat-dialog-actions>
    <div class="text-center w-100 mb-1">
        <div>
            <button *ngFor="let geocodeResponse of data.geocode.valid_responses; let idx=index;"
                    class="col-12 btn btn-outline-primary mt-2"
                    (click)="handleConfirmAddress(geocodeResponse)"
                    cdkFocusInitial>

                <span>{{geocodeResponse.street_line1}} {{geocodeResponse.street_line2}}, {{geocodeResponse.city_info.name}}, {{geocodeResponse.city_info.county_info.name}}, {{geocodeResponse.city_info.county_info.state}} {{geocodeResponse.zipcode}}</span>
            </button>
        </div>
        <button class="col-12 btn btn-outline-primary mt-2"
                (click)="handleUseAddressAsEntered()">
            <span *ngIf="data.geocode.valid_responses.length > 1">
                This addresses is not correct, I want to use address <b>As Entered</b>: "{{data.displayGeocodeAddress}}"
            </span>
            <span *ngIf="data.geocode.valid_responses.length == 1">
                This address is not correct. I want to use address <b>As Entered</b>: "{{data.displayGeocodeAddress}}"
            </span>
            <span *ngIf="data.geocode.valid_responses.length == 0">
                Yes, I want to use address <b>As Entered</b>: "{{data.displayGeocodeAddress}}"
            </span>
        </button>
        <button class="btn btn-outline-dark col-12 mt-2"
                (click)="handleCancel()">Cancel</button>
    </div>
</div>