import { LightsBackendDict } from '@/data/simulation/models/lights/Lights';
import { createAction, props } from '@ngrx/store';
import { StateErrors } from '../shared/base.state';

export const loadLights = createAction(
  '[Lights] Load Lights',
  props<{ lights: LightsBackendDict }>()
);

export const loadLightsSuccess = createAction(
  '[Lights] Load Lights Success',
  props<{
    lights: LightsBackendDict;
    errors: StateErrors<LightsBackendDict>;
  }>()
);

export const loadLightsFailure = createAction(
  '[Lights] Load Lights Failure',
  props<{ error: any }>()
);

export const updateLights = createAction(
  '[Lights] Update Lights',
  props<{ id: number; lightsChanges: Partial<LightsBackendDict> }>()
);

export const updateLightsFailure = createAction(
  '[Lights API] Update Lights Failure',
  props<{ id: number }>()
);
