import { createSelector } from '@ngrx/store';
import { SimulationState } from '../reducers';
import { selectSimulationFeature } from '../simulation.selectors';

export const selectAppliances = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.appliances.entities[id]
  );
const selectAppliancesLoadingStatus = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.appliances.loading[id]
  );

export const selectAppliancesViewSet = (id: number) =>
  createSelector(
    selectAppliances(id),
    selectAppliancesLoadingStatus(id),
    (appliances, loadingStatus) => ({
      data: appliances,
      loading: loadingStatus,
    })
  );

export const selectAppliancesErrorsCount = (id: number) =>
  createSelector(selectSimulationFeature, (state: SimulationState) => ({
    errorCount: state.appliances.errors[id].errorCount,
    warningCount: state.appliances.errors[id].warningCount,
  }));
