import { Component, OnDestroy, OnInit } from '@angular/core';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { FormBuilder, FormGroup } from '@angular/forms';
import { User } from '@/data/core/models/user';
import { ProductList, ProductStatusLabelMapping } from '@/data/product/models';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { first, take, takeUntil } from 'rxjs/operators';
import { getInfoUser } from '@/state/selectors/info.selector';
import { Company, CompanyInfo, CompanyType } from '@/data/company/models';
import { ServerResponse } from '@/core/schemes/server-response';
import { HttpErrorResponse } from '@angular/common/http';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { OkDialogComponent } from '@/shared/components/ok-dialog/ok-dialog.component';
import {
  PaymentRequestList,
  PaymentRequestStateLabelMapping,
  PaymentRequestTypeLabelMapping,
} from '@/data/customer-hirl/models';
import {
  PaymentRequestRequestParams,
  PaymentRequestService,
} from '@/data/customer-hirl/services/payment-request.service';
import { PaymentRequestPermissionService } from '@/modules/customer-hirl/services/payment-request-permission.service';
import {
  PaymentRequestChangeDialogComponent,
  PaymentRequestChangeDialogData,
} from '@/modules/customer-hirl/components/payment-request-change-dialog/payment-request-change-dialog.component';
import { HirlSetupInvoicePreferencesDialogComponent } from '@/modules/customer-hirl/components/hirl-setup-invoice-preferences-dialog/hirl-setup-invoice-preferences-dialog.component';
import { NavigationService } from '@/shared/services/navigation-service.service';

@Component({
  selector: 'app-payment-request-list',
  templateUrl: './payment-request-list.component.html',
  styleUrls: ['./payment-request-list.component.scss'],
})
export class PaymentRequestListComponent implements OnInit, OnDestroy {
  protected readonly PaymentRequestStateLabelMapping =
    PaymentRequestStateLabelMapping;
  protected readonly PaymentRequestTypeLabelMapping =
    PaymentRequestTypeLabelMapping;
  protected readonly CompanyType = CompanyType;

  public displayedColumns: string[] = [
    'id',
    'manufacturer__name',
    'request_type',
    'state',
    'created_at',
  ];

  public initialized = false;

  public createPermissionResponse: Observable<ObjectPermissionResponse>;

  public filterFromGroup: FormGroup;

  public currentUser: User;

  public entities: PaymentRequestList[];
  public entitiesCount: number;
  public entitiesIsLoading = true;

  public defaultParams: PaymentRequestRequestParams =
    new PaymentRequestRequestParams(1, '', '-created_at', 25);
  public storedParams: PaymentRequestRequestParams =
    new PaymentRequestRequestParams(1, '', '-created_at', 25);

  private listSubscription$: Subscription;
  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private paymentRequestService: PaymentRequestService,
    private paymentRequestPermissionService: PaymentRequestPermissionService,
    private router: Router,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private uiHelperService: UIHelperService,
    private navigationService: NavigationService
  ) {}

  ngOnInit() {
    forkJoin({
      queryParams: this.activatedRoute.queryParams.pipe(first()),
      currentUser: this.store.select(getInfoUser).pipe(first()),
    }).subscribe(({ queryParams, currentUser }) => {
      this.currentUser = currentUser;
      this.storedParams.assignQueryParams(queryParams, ['state']);
      this.createPermissionResponse =
        this.paymentRequestPermissionService.canCreate();

      this.initialized = true;

      this.setupInvoicePreferences();
      this.setupFilterForm();
      this.hydrateForm();
      this.list();
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }
  }

  setupInvoicePreferences() {
    if (!this.currentUser.company_info?.gcp_fee_calculation_type) {
      const dialogRef = this.dialog.open(
        HirlSetupInvoicePreferencesDialogComponent,
        {
          width: '55%',
          disableClose: true,
          autoFocus: false,
          data: {
            company: this.currentUser.company_info,
          },
        }
      );

      dialogRef.afterClosed().subscribe(() => {});
    }
  }

  setupFilterForm() {
    this.filterFromGroup = this.fb.group({
      search: [null],
      manufacturer_info: [null],
      state: [null],
    });

    this.filterFromGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        let params = Object.assign(this.defaultParams, values);

        params = Object.assign(params, {
          manufacturer: params.manufacturer_info?.map(
            (manufacturer: CompanyInfo) => manufacturer.id
          ),
        });
        this.storedParams.assignFilterFormValues({
          formValues: params,
          excludedProperties: ['ordering', 'manufacturer_info'],
        });
        this.list();
      });
  }

  hydrateForm() {
    this.filterFromGroup.patchValue(this.storedParams, {
      emitEvent: false,
      onlySelf: true,
    });
  }

  list() {
    this.entitiesIsLoading = true;

    // populate params to query string
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.storedParams.toQueryParams(),
      replaceUrl: true,
    });

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }

    this.listSubscription$ = this.paymentRequestService
      .list(this.storedParams)
      .pipe(takeUntil(this.componentDestroyed$), take(1))
      .subscribe({
        next: (data: ServerResponse<ProductList>) => {
          this.entities = data.results;
          this.entitiesCount = data.count;
          this.entitiesIsLoading = false;
        },
        error: (error: HttpErrorResponse) => {
          if (error.status === 400) {
            // to avoid cycle loop, reload list only if invalid param in query detected
            let invalidQueryParam = false;
            for (const key of Object.keys(error.error)) {
              if (this.storedParams.hasOwnProperty(key)) {
                invalidQueryParam = true;
                delete this.storedParams[key];
              }
            }

            if (invalidQueryParam) {
              this.list();
            }
          }
        },
      });
  }

  onSort($event: Sort) {
    this.storedParams.ordering = `${$event.direction === 'desc' ? '-' : ''}${
      $event.active
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  onPaginateChange($event: PageEvent) {
    this.uiHelperService.getUISettings().rowsPerPage = $event.pageSize;
    this.storedParams.page_size = $event.pageSize;
    this.storedParams.page = $event.pageIndex + 1;
    this.list();
  }

  resetFilters($event: MouseEvent) {
    $event.preventDefault();
    this.filterFromGroup.reset();
  }

  showAppliedFiltersDialog($event: MouseEvent) {
    $event.preventDefault();

    let content = '';
    for (const [key, value] of Object.entries(this.getAppliedFilters())) {
      content += `<b>${key}</b>: ${value}<br>`;
    }

    this.dialog.open(OkDialogComponent, {
      data: {
        title: 'Current Applied Filters',
        content: content,
      },
    });
  }

  getAppliedFilters(): {
    [key: string]: string;
  } {
    return this.storedParams.toAppliedFilters({
      overwritePropertyValues: {},
      overwritePropertyLabels: {},
    });
  }

  create($event: MouseEvent) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(PaymentRequestChangeDialogComponent, {
      width: '55%',
      disableClose: true,
      autoFocus: false,
      data: {} as PaymentRequestChangeDialogData,
    });

    dialogRef.afterClosed().subscribe((result?: PaymentRequest) => {
      if (!result) {
        return;
      }
      this.router.navigate([
        '/',
        'hi',
        'payment',
        'request',
        'detail',
        result.id,
      ]);
      this.uiHelperService.openSnackBar(`Payment request successfully created`);
    });
  }

  companyFilterOptionDisplay(company: Company): string {
    return `${company.name}`;
  }

  protected readonly ProductStatusLabelMapping = ProductStatusLabelMapping;
}
