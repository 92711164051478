import { Emissions } from '@/data/simulation/models/reports/Analysis';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-emissions',
  templateUrl: './emissions.component.html',
  styleUrls: ['./emissions.component.scss'],
})
export class EmissionsComponent {
  @Input() entity: Emissions;

  tableData = [
    { name: 'Heating' },
    { name: 'Cooling' },
    { name: 'Lighting and Appliances' },
    { name: 'Water Heating' },
  ];

  totlaData = {
    co2: 0,
    so2: 0,
    nox: 0,
  };

  displayedColumns: string[] = ['name', 'co2', 'so2', 'nox'];

  extractItemKey(key: string) {
    return key.split('_').pop();
  }

  ngOnInit() {
    Object.keys(this.entity).forEach(key => {
      let index = -1;
      if (key.startsWith('heating_')) {
        index = 0;
      } else if (key.startsWith('cooling_')) {
        index = 1;
      } else if (key.startsWith('lighting_and_appliances_')) {
        index = 2;
      } else if (key.startsWith('water_heating_')) {
        index = 3;
      } else if (key.startsWith('total_')) {
        this.totlaData[this.extractItemKey(key)] = this.entity[key];
      }

      if (index === -1) return;
      this.tableData[index][this.extractItemKey(key)] = this.entity[key];
    });
  }
}
