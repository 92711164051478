<h2 mat-dialog-title>
  <ng-container *ngIf="!isEditForm">
    Create New <b>Equipment</b>
  </ng-container>
  <ng-container *ngIf="isEditForm">
    Edit <b>Equipment</b>
  </ng-container>
</h2>
<mat-dialog-content *ngIf="initialized">
  <form [formGroup]="form" class="mb-2">
    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="outline" class="w-100 mb-2">
          <mat-label>Type</mat-label>
          <mat-select aria-label="Equipment Type" formControlName="equipment_type">
            <mat-option *ngFor="let item of equipmentTypeLabelMapping | defaultOrderKeyValue" [value]="item.key">
              {{ item.value }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('equipment_type').hasError('required')">
            Equipment Type is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="outline" class="mb-2 w-100">
          <mat-label>Brand</mat-label>
          <input matInput type="text" placeholder="Brand" formControlName="brand" required>
          <mat-error *ngIf="form.get('brand').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="outline" class="mb-2 w-100">
          <mat-label>Model</mat-label>
          <input matInput type="text" placeholder="Model" formControlName="equipment_model" required>
          <mat-error *ngIf="form.get('brand').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="outline" class="mb-2 w-100">
          <mat-label>Serial</mat-label>
          <input matInput type="text" placeholder="Serial" formControlName="serial" required>
          <mat-error *ngIf="form.get('serial').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="outline" class="mb-2 w-100">
          <mat-label>Description</mat-label>
          <textarea matInput type="text" placeholder="Enter Description" formControlName="description"></textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="outline" class="w-100 mb-2">
          <mat-label>Calibration Date</mat-label>
          <input matInput type="text" placeholder="Expiration Date" formControlName="calibration_date" [matDatepicker]="calibrationDatePicker">
          <mat-datepicker-toggle matSuffix [for]="calibrationDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #calibrationDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="outline" class="w-100 mb-2">
          <mat-label>Calibration Cycle</mat-label>
          <mat-select aria-label="Calibration Cycle" formControlName="calibration_cycle">
            <mat-option *ngFor="let item of equipmentCalibrationCycleLabelMapping | defaultOrderKeyValue" [value]="item.key">
              {{ item.value }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('calibration_cycle').hasError('required')">
            Calibration Cycle is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Calibration Company</mat-label>
          <input matInput type="text" placeholder="Calibration Company" formControlName="calibration_company" required>
          <mat-error *ngIf="form.get('calibration_company').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Calibration documentation</mat-label>
          <app-file-input [formControl]="form.controls['calibration_documentation']"></app-file-input>
          <mat-hint *ngIf="entity.calibration_documentation">
            Current file: <a href="{{ entity.calibration_documentation }}" target="_blank">{{ entity.calibration_documentation.split('/').pop() }}</a>
          </mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Assignees</mat-label>
          <app-generic-model-chips placeholder="Select Users" modelClassName="user" formControlName="assignees_info" [panelWidth]="640" [initialValueIds]="entity?.assignees" [filterParams]="{company: currentUser.company, is_active: true}"></app-generic-model-chips>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Notes</mat-label>
          <textarea matInput type="text" placeholder="Notes" formControlName="notes"></textarea>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button color="accent" mat-raised-button (click)="save($event)">Save</button>
  <button mat-button [mat-dialog-close]="null" color="primary" mat-raised-button>Cancel</button>
</mat-dialog-actions>
