import {
  PhotovoltaicBackendDict,
  PvCapacityLabels,
  PvTrackingLabels,
  createPhotovoltaicForm,
  FIELD_LABELS,
  pvLocationLabels,
} from '@/data/simulation/models/generators/Photovoltaic';
import {
  removePhotovoltaic,
  updatePhotovoltaic,
} from '@/modules/simulation/state/photovoltaic/actions';
import { selectPhotovoltaicViewSet } from '@/modules/simulation/state/photovoltaic/selectors';
import { clonePhotovoltaic } from '@/modules/simulation/state/simulation/actions';
import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AreaUnitLabels } from '@/data/simulation/enumerations';

@Component({
  selector: 'app-photovoltaic',
  templateUrl: './photovoltaic.component.html',
  styleUrls: ['./photovoltaic.component.scss'],
})
export class PhotovoltaicComponent {
  @Input() entityId: number;
  fieldLabels = FIELD_LABELS;

  areaLabels = AreaUnitLabels;

  form: FormGroup;
  loading: boolean;
  componentDestroyed$: Subject<boolean> = new Subject();
  trackModeLabels = PvTrackingLabels;
  locationLabels = pvLocationLabels;
  capacityUnitLabels = PvCapacityLabels;

  constructor(public store: Store) {}

  ngOnInit(): void {
    this.store
      .select(selectPhotovoltaicViewSet(this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(({ data, loading }) => {
        this.loading = loading;
        if (loading) return;

        if (this.form) {
          this.form.patchValue(data);
          return;
        }
        this.form = createPhotovoltaicForm(data);
      });
  }

  onSave(photovoltaicChanges: Partial<PhotovoltaicBackendDict>) {
    this.store.dispatch(
      updatePhotovoltaic({ id: this.entityId, photovoltaicChanges })
    );
  }

  remove() {
    this.store.dispatch(removePhotovoltaic({ id: this.entityId }));
  }

  clone() {
    this.store.dispatch(clonePhotovoltaic({ id: this.entityId }));
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
