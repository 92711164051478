<div mat-dialog-title class="d-flex justify-content-between align-items-center">
    <h2>{{ editorIsVisible ? 'Fuel rate' : 'Select the Fuel' }}</h2>
    <button mat-icon-button aria-label="close dialog" mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <div *ngIf="!editorIsVisible">
        <mat-form-field class="w-100">
            <mat-label>Type</mat-label>
            <app-generic-model-autocomplete modelClassName="utility_rate" [formControl]="utilitySelectControl"
                [panelWidth]="380" [filterParams]="{library_element: true}" (optionDisabled)="excludeUtilityRateWithFuel">
            </app-generic-model-autocomplete>
        </mat-form-field>
    </div>

    <div *ngIf="editorIsVisible">
        <app-utility-rate [entityId]="entityId"></app-utility-rate>
    </div>
</mat-dialog-content>