import {
  Component,
  EventEmitter,
  Input,
  Output,
  forwardRef,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { FormControl } from '@angular/forms';

export class DateRangeMatSelectChoice {
  label: string;
  value: DateRangeMatSelectValue;

  constructor(label: string, startDate: Date, endDate: Date) {
    this.label = label;
    this.value = new DateRangeMatSelectValue(startDate, endDate);
  }
}

export class DateRangeMatSelectValue {
  startDate: Date;
  endDate: Date;

  constructor(startDate: Date | string, endDate: Date | string) {
    this.startDate = this.parseDate(startDate);
    this.endDate = this.parseDate(endDate);
  }

  private parseDate(date: Date | string): Date | null {
    if (typeof date === 'string') {
      const parsedDate = new Date(date);
      return isNaN(parsedDate.getTime()) ? null : parsedDate;
    }
    return date instanceof Date && !isNaN(date.getTime()) ? date : null;
  }

  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-based, so we add 1
    const day = date.getDate().toString().padStart(2, '0'); // Day with leading zeros

    return `${year}-${month}-${day}`;
  }

  toQueryString(): string[] {
    if (!this.startDate || !this.endDate) {
      return [null, null];
    }
    return [this.formatDate(this.startDate), this.formatDate(this.endDate)];
  }

  equals(other: DateRangeMatSelectValue | null): boolean {
    if (other === null) {
      return this.startDate === null && this.endDate === null;
    }

    return (
      other instanceof DateRangeMatSelectValue &&
      this.compareDates(this.startDate, other.startDate) &&
      this.compareDates(this.endDate, other.endDate)
    );
  }

  private compareDates(date1: Date | null, date2: Date | null): boolean {
    if (date1 === null && date2 === null) {
      return true;
    }
    if (date1 === null || date2 === null) {
      return false;
    }

    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }
}

@Component({
  selector: 'app-date-range-mat-select',
  templateUrl: './date-range-mat-select.component.html',
  styleUrls: ['./date-range-mat-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateRangeMatSelectComponent),
      multi: true,
    },
    {
      provide: MatFormFieldControl,
      useExisting: DateRangeMatSelectComponent,
    },
  ],
})
export class DateRangeMatSelectComponent
  implements ControlValueAccessor, MatFormFieldControl<any>, OnInit, OnDestroy
{
  @Input() label: string;
  @Input() dateRanges: DateRangeMatSelectChoice[] = [];
  @Output() filterChanged: EventEmitter<any> = new EventEmitter();

  // selectedRange now holds the actual date range object
  selectedRange: FormControl = new FormControl(null);
  stateChanges = new EventEmitter<void>();
  focused = false;
  empty = false;
  id = 'date-range-mat-select'; // ID for the form field
  placeholder = 'Select a range'; // Placeholder text

  ngControl = null; // Set the ngControl property for the form field
  required = false; // Handle the required property
  disabled = false; // Handle the disabled property

  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};

  constructor() {}

  ngOnInit(): void {
    this.selectedRange.valueChanges.subscribe(
      (range: Partial<DateRangeMatSelectValue> | null) => {
        if (range === null) {
          this.filterChanged.emit({ startDate: null, endDate: null });
        } else {
          this.filterChanged.emit({
            startDate: range.startDate,
            endDate: range.endDate,
          });
        }
        this.onChange(this.selectedRange.value);
      }
    );
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
  }

  // Implement ControlValueAccessor methods

  writeValue(value: DateRangeMatSelectValue | null): void {
    const selectedRange: DateRangeMatSelectChoice = this.dateRanges.find(
      (dateRangeChoice: DateRangeMatSelectChoice) => {
        return dateRangeChoice.value.equals(value);
      }
    );
    this.selectedRange.setValue(selectedRange?.value || null, {
      emitEvent: false,
    });
    this.value = selectedRange.value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (isDisabled) {
      this.selectedRange.disable();
    } else {
      this.selectedRange.enable();
    }
  }

  // Implement MatFormFieldControl methods

  get value(): DateRangeMatSelectValue | null {
    return this.selectedRange.value as DateRangeMatSelectValue;
  }

  set value(value: DateRangeMatSelectValue | null) {
    this.selectedRange.setValue(value, { emitEvent: false });
  }

  get errorState(): boolean {
    return (
      this.selectedRange.invalid &&
      (this.selectedRange.dirty || this.selectedRange.touched)
    );
  }

  get shouldLabelFloat(): boolean {
    return this.focused || !!this.selectedRange.value;
  }

  onFocus(): void {
    this.focused = true;
    this.stateChanges.emit();
  }

  onBlur(): void {
    this.focused = false;
    this.stateChanges.emit();
    this.onTouched(); // Ensure touched callback is triggered
  }

  setDescribedByIds(ids: string[]): void {
    // This method links the form control with error messages
  }

  onContainerClick(): void {
    // Handle container click events (for accessibility or focus handling)
  }
}
