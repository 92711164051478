import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { selectUtilityRateViewSet } from '@/modules/simulation/state/utility-rate/selectors';
import {
  UtilityRateBackendDict,
  createUtilityRateForm,
} from '@/data/simulation/models/utility/UtilityRate';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FuelTypeLabels } from '@/data/simulation/enumerations';
import { updateUtilityRate } from '@/modules/simulation/state/utility-rate/actions';

@Component({
  selector: 'app-utility-rate',
  templateUrl: './utility-rate.component.html',
  styleUrls: ['./utility-rate.component.scss'],
})
export class UtilityRateComponent {
  @Input() entityId: number;
  fuelLabels = FuelTypeLabels;

  componentDestroyed$ = new Subject();

  entity: UtilityRateBackendDict;
  loading: boolean;
  utilityRateForm: FormGroup;

  constructor(public store: Store) {}

  ngOnInit() {
    if (this.entityId === null) {
      return;
    }
    this.store
      .select(selectUtilityRateViewSet(this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(({ data, loading }) => {
        this.loading = loading;

        if (loading) return;

        this.entity = data;
        if (this.utilityRateForm) {
          this.utilityRateForm.patchValue(data);
        } else {
          this.utilityRateForm = createUtilityRateForm(data);
        }
      });
  }

  saveUtilityRate(utilityRateChangedValues: Partial<UtilityRateBackendDict>) {
    this.store.dispatch(
      updateUtilityRate({
        utilityRateChanges: utilityRateChangedValues,
      })
    );
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
