import { ModelFormControl, createConditionalValidator } from '../ModelForm';
import { Validators } from '@angular/forms';
import { InsulationGrade, StudType } from '../../enumerations';
import {
  enumValidator,
  withinAshraeFramingFactorRangeValidator,
  withinRValueRange,
} from '@/modules/simulation/validators/helper';
import { FieldLabels } from '../base';
import { BaseModelWithAssemblyFormGroup } from './AssemblyCalculator';
import {
  LayerBackendDict,
  LayerFrameType,
  createNewLayer,
} from './AssemblyLayer';
import { MaterialItem, getMaterial } from './Material';

export enum Material {
  SOLID_CONCRETE = 'concrete',
  CONCRETE_MASONRY_UNIT_UNINSULATED = 'cmu_uninsulated',
  CONCRETE_MASONRY_UNIT_FOAM_INSULATED = 'cmu_foam',
  CONCRETE_MASONRY_UNIT_VERMICULITE_INSULATED = 'cmu_vermiculite',
  WOOD_STUD = 'wood_stud',
  DOUBLE_BRICK = 'double_brick',
  MH_WOOD = 'mh_wood',
  MH_VINYL = 'mh_vinyl',
  MH_ALUMINUM = 'mh_aluminium',
  OTHER = 'other',
}

export const MaterialLabels: Record<Material, string> = {
  [Material.SOLID_CONCRETE]: 'Solid Concrete',
  [Material.CONCRETE_MASONRY_UNIT_UNINSULATED]:
    'Concrete Masonry Unit - Uninsulated',
  [Material.CONCRETE_MASONRY_UNIT_FOAM_INSULATED]:
    'Concrete Masonry Unit - Foam',
  [Material.CONCRETE_MASONRY_UNIT_VERMICULITE_INSULATED]:
    'Concrete Masonry Unit - Vermiculite',
  [Material.WOOD_STUD]: 'Wood',
  [Material.DOUBLE_BRICK]: 'Double Brick',
  [Material.MH_WOOD]: 'Mobile-home vinyl skirting',
  [Material.MH_VINYL]: 'Mobile-home vinyl skirting',
  [Material.MH_ALUMINUM]: 'Mobile-home aluminium skirting',
  [Material.OTHER]: 'Other',
};

// Labels
export const FIELD_LABELS: FieldLabels<FoundationWallTypeBackendDict> = {
  name: 'Name',
  material: 'Material',
  stud_type: 'Stud Type',
  frame_width: 'Frame Width',
  frame_depth: 'Frame Depth',
  frame_spacing: 'Frame Spacing',
  framing_factor: 'Framing Factor',
  use_default_framing_factor: 'Use default framing factor',
  thickness: 'Thickness',
  cavity_insulation_r_value: 'Cavity Insulation R-Value',
  units: 'Units',
  assembly_u_value: 'Assembly U-Value',
  user_defined_assembly_u_value: 'Contains Assembly Details',
  cavity_insulation_thickness: 'Cavity Insulation Thickness',
  cavity_insulation_grade: 'Cavity Insulation Grade',
  continuous_insulation_r_value: 'Continuous Insulation R-Value',
  gypsum_thickness: 'Gypsum Thickness',
  exterior_continuous_insulation_r_value:
    'Exterior Continuous Insulation R-Value',
  exterior_insulation_from_top: 'Exterior Insulation From Top',
  exterior_insulation_from_bottom: 'Exterior Insulation From Bottom',
  interior_insulation_from_top: 'Interior Insulation From Top',
  interior_insulation_from_bottom: 'Interior Insulation From Bottom',
  layers: 'Layers',
};

export interface FoundationWallTypeBackendDict {
  id: number | null;
  name: string;

  material: Material;
  stud_type: StudType;
  frame_width: number;
  frame_depth: number;
  frame_spacing: number;
  framing_factor: number;
  use_default_framing_factor: boolean;
  thickness: number;
  cavity_insulation_r_value: number;
  assembly_u_value: number;
  user_defined_assembly_u_value: boolean;
  cavity_insulation_thickness: number;
  cavity_insulation_grade: InsulationGrade;
  continuous_insulation_r_value: number;
  gypsum_thickness: number;
  exterior_continuous_insulation_r_value: number | null;
  exterior_insulation_from_top: number | null;
  exterior_insulation_from_bottom: number | null;
  interior_insulation_from_top: number | null;
  interior_insulation_from_bottom: number | null;

  layers: number[];
  units: number;
}

export class FoundationWallTypeFormGroup extends BaseModelWithAssemblyFormGroup {
  frameType = LayerFrameType.FOUNDATION_WALL;
  getClassicCoreMaterial(instance): LayerBackendDict {
    let reference = MaterialItem.CONSTRUCTION_CONCRETE_POURED;
    if (instance.material === Material.CONCRETE_MASONRY_UNIT_UNINSULATED) {
      if (instance.thickness >= 12) {
        reference = MaterialItem.CONSTRUCTION_CONCRETE_BLOCK_12P0;
      } else if (instance.thickness >= 8) {
        reference = MaterialItem.CONSTRUCTION_CONCRETE_BLOCK_8P0;
      } else {
        reference = MaterialItem.CONSTRUCTION_CONCRETE_BLOCK_4P0;
      }
    } else if (
      instance.material === Material.CONCRETE_MASONRY_UNIT_FOAM_INSULATED
    ) {
      if (instance.thickness >= 12) {
        reference = MaterialItem.CONCRETE_BLOCK_12P0_PERLITE;
      } else if (instance.thickness >= 8) {
        reference = MaterialItem.CONCRETE_BLOCK_8P0_PERLITE;
      } else {
        reference = MaterialItem.CONCRETE_BLOCK_6P0_PERLITE;
      }
    } else if (
      instance.material === Material.CONCRETE_MASONRY_UNIT_VERMICULITE_INSULATED
    ) {
      if (instance.thickness >= 12) {
        reference = MaterialItem.CONCRETE_BLOCK_12P0_PERLITE;
      } else if (instance.thickness >= 8) {
        reference = MaterialItem.CONCRETE_BLOCK_8P0_PERLITE;
      } else {
        reference = MaterialItem.CONCRETE_BLOCK_6P0_PERLITE;
      }
    } else if (instance.material === Material.DOUBLE_BRICK) {
      if (instance.thickness >= 10) {
        reference = MaterialItem.CONSTRUCTION_DOUBLE_BRICK_10P0;
      } else if (instance.thickness >= 8) {
        reference = MaterialItem.CONSTRUCTION_DOUBLE_BRICK_8P0;
      } else {
        reference = MaterialItem.CONSTRUCTION_DOUBLE_BRICK_6P0;
      }
    } else if (instance.material === Material.WOOD_STUD) {
      reference = MaterialItem.CONSTRUCTION_SOFT_WOOD;
    }
    const referenceMaterial = getMaterial(reference);
    const thickness = instance.thickness || 4;
    return {
      ...createNewLayer(),
      materials: [
        {
          ...referenceMaterial,
          r_value:
            referenceMaterial.r_value ||
            referenceMaterial.r_value_per_inch * instance.thickness,
          thickness: thickness,
        },
      ],
    };
  }

  convertClassicFormToLayer() {
    const layers: LayerBackendDict[] = [];
    const instance = this.value;
    if (instance.exterior_continuous_insulation_r_value) {
      const layer = {
        ...createNewLayer(),
        materials: [
          {
            ...getMaterial(MaterialItem.INSULATION_EXTERIOR_GENERIC_CONTINUOUS),
            r_value: instance.exterior_continuous_insulation_r_value,
            thickness: 2.0, // Not needed or used.
          },
        ],
      };
      layers.push(layer);
    }

    if (instance.material) {
      const core_material = this.getClassicCoreMaterial(instance);
      if (core_material) {
        layers.push(core_material);
      }
    }

    // TODO: Remove and condition
    if (instance.stud_type) {
      layers.push(this.getStudWall(instance));
    }

    if (instance.continuous_insulation_r_value) {
      layers.push(this.getContinuousInsulationLayer(instance));
    }

    if (instance.gypsum_thickness) {
      layers.push(this.getGypsumLayer(instance));
    }

    return layers;
  }

  constructor(foundationwalltype) {
    const validateOnlyIfClassicForm = createConditionalValidator(
      parentControls =>
        parentControls.layers.value.length === 0 &&
        !parentControls.user_defined_assembly_u_value.value,
      ['layers', 'user_defined_assembly_u_value']
    );
    const validateOnlyIfClassicStud = createConditionalValidator(
      parentControls =>
        parentControls.layers.value.length === 0 &&
        parentControls.stud_type.value !== null,
      ['layers', 'stud_type']
    );

    const validateOnlyIfClassicStudWithoutDefaultFraming =
      createConditionalValidator(
        parentControls =>
          parentControls.layers.value.length === 0 &&
          parentControls.stud_type.value !== null &&
          !parentControls.use_default_framing_factor.value,
        ['stud_type', 'use_default_framing_factor']
      );

    super({
      name: new ModelFormControl(foundationwalltype.name, Validators.required),
      material: new ModelFormControl(
        foundationwalltype.material,
        ...validateOnlyIfClassicForm([enumValidator(Material, true)])
      ),
      thickness: new ModelFormControl(
        foundationwalltype.thickness,
        ...validateOnlyIfClassicForm([Validators.required, Validators.min(0)])
      ),
      stud_type: new ModelFormControl(
        foundationwalltype.stud_type,
        ...validateOnlyIfClassicForm([enumValidator(StudType, true)])
      ),
      frame_width: new ModelFormControl(
        foundationwalltype.frame_width,
        ...validateOnlyIfClassicStud([Validators.required, Validators.min(0)])
      ),
      frame_depth: new ModelFormControl(
        foundationwalltype.frame_depth,
        ...validateOnlyIfClassicStud([Validators.required, Validators.min(0)])
      ),
      frame_spacing: new ModelFormControl(
        foundationwalltype.frame_spacing,
        ...validateOnlyIfClassicStud([Validators.required, Validators.min(0)])
      ),
      framing_factor: new ModelFormControl(
        foundationwalltype.framing_factor,
        ...validateOnlyIfClassicStudWithoutDefaultFraming([
          withinAshraeFramingFactorRangeValidator(0.25),
        ])
      ),
      use_default_framing_factor: new ModelFormControl(
        foundationwalltype.use_default_framing_factor
      ),
      cavity_insulation_r_value: new ModelFormControl(
        foundationwalltype.cavity_insulation_r_value,
        ...validateOnlyIfClassicStud([Validators.required, withinRValueRange()])
      ),
      assembly_u_value: new ModelFormControl(
        foundationwalltype.assembly_u_value,
        [Validators.min(0.0)]
      ),
      layers: new ModelFormControl(foundationwalltype.layers),
      user_defined_assembly_u_value: new ModelFormControl(
        foundationwalltype.user_defined_assembly_u_value
      ),
      cavity_insulation_thickness: new ModelFormControl(
        foundationwalltype.cavity_insulation_thickness
      ),
      cavity_insulation_grade: new ModelFormControl(
        foundationwalltype.cavity_insulation_grade,
        ...validateOnlyIfClassicStud([enumValidator(InsulationGrade)])
      ),
      continuous_insulation_r_value: new ModelFormControl(
        foundationwalltype.continuous_insulation_r_value,
        ...validateOnlyIfClassicForm([Validators.required])
      ),
      gypsum_thickness: new ModelFormControl(
        foundationwalltype.gypsum_thickness
      ),
      exterior_continuous_insulation_r_value: new ModelFormControl(
        foundationwalltype.exterior_continuous_insulation_r_value,
        ...validateOnlyIfClassicForm([Validators.required])
      ),
      exterior_insulation_from_top: new ModelFormControl(
        foundationwalltype.exterior_insulation_from_top,
        ...validateOnlyIfClassicForm([Validators.required, Validators.min(0)])
      ),
      exterior_insulation_from_bottom: new ModelFormControl(
        foundationwalltype.exterior_insulation_from_bottom,
        ...validateOnlyIfClassicForm([Validators.required, Validators.min(0)])
      ),
      interior_insulation_from_top: new ModelFormControl(
        foundationwalltype.interior_insulation_from_top,
        ...validateOnlyIfClassicForm([Validators.required, Validators.min(0)])
      ),
      interior_insulation_from_bottom: new ModelFormControl(
        foundationwalltype.interior_insulation_from_bottom,
        ...validateOnlyIfClassicForm([Validators.required, Validators.min(0)])
      ),
    });
  }
}
