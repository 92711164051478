import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { toggleLoading } from '@/state/actions/app.actions';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import {
  ServerErrorDialogComponent,
  ServerErrorDialogConfig,
} from '@/shared/components/server-error-dialog/server-error-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import {
  MatSnackBar,
  MatSnackBarRef,
  TextOnlySnackBar,
} from '@angular/material/snack-bar';
import * as XLSX from 'xlsx';
import { EEPProgramHomeStatusProjectReportExportField } from '@/data/home/models';

const EXCEL_EXTENSION = '.xlsx';
const CSV_EXTENSION = '.csv';
const CSV_TYPE = 'text/plain;charset=utf-8';

export interface IExcelJson {
  data: Array<any>;
  header?: Array<string>;
  skipHeader?: boolean;
  origin?: string | number;
}

interface IUISettings {
  rowsPerPage: number;
  psrExportFields: EEPProgramHomeStatusProjectReportExportField[];
}

export class UISettings implements IUISettings {
  static localStorageKey = 'uiSettings';
  private rowsPerPage$ = 25;
  private psrExportFields$: EEPProgramHomeStatusProjectReportExportField[] = [];
  get rowsPerPage(): number {
    return this.rowsPerPage$;
  }
  set rowsPerPage(value: number) {
    this.rowsPerPage$ = value;
    this.save();
  }
  get psrExportFields(): EEPProgramHomeStatusProjectReportExportField[] {
    return this.psrExportFields$;
  }
  set psrExportFields(value: EEPProgramHomeStatusProjectReportExportField[]) {
    this.psrExportFields$ = value;
    this.save();
  }

  private save(): void {
    try {
      localStorage.setItem(
        UISettings.localStorageKey,
        JSON.stringify({
          rowsPerPage: this.rowsPerPage,
          psrExportFields: this.psrExportFields,
        })
      );
    } catch (e) {
      console.error('Error saving to localStorage', e);
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class UIHelperService {
  constructor(
    public store: Store<AppState>,
    public router: Router,
    public dialog: MatDialog,
    public snackBar: MatSnackBar
  ) {}

  getUISettings(): UISettings {
    try {
      const data = JSON.parse(localStorage.getItem(UISettings.localStorageKey));
      return Object.assign(new UISettings(), data);
    } catch (e) {
      console.error('Error getting data from localStorage', e);
      return new UISettings();
    }
  }

  /**
   * Helping to handle Page errors where main object requests
   * return errors, and we are not able to load anything
   * @error
   */
  handleMainRequestError(error: HttpErrorResponse) {
    this.store.dispatch(toggleLoading({ payload: false }));
    switch (error.status) {
      case 403:
        this.router.navigate(['/', '403']);
        break;
      case 404:
        this.router.navigate(['/', '404']);
        break;
      default:
        this.router.navigate(['/', '500']);
    }
  }

  /**
   * Helping to handle User action request errors.
   * For example user click on button and request
   * returned error we can inform him nicely with a dialog box
   * @serverErrorDialogConfig
   */
  handleUserRequestError(
    error?: HttpErrorResponse,
    title = 'Error',
    showErrorKey = true
  ) {
    this.store.dispatch(toggleLoading({ payload: false }));

    const data = new ServerErrorDialogConfig({
      title,
      showErrorKey,
      data: error,
    });

    this.dialog.open(ServerErrorDialogComponent, {
      width: '50%',
      data,
    });
  }

  /**
   * Create browser dialog to save data from server as file
   * @param response Data from server
   * @param type Type of server data. Most common types: text/calendar, application/pdf
   * @param filename Name displayed for user
   */
  downloadServerResponse(response: any, type: string, filename: string) {
    const blobData = new Blob([response], { type });
    const downloadURL = window.URL.createObjectURL(blobData);
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = filename;
    link.click();
  }

  openSnackBar(
    message: string,
    action = 'Close'
  ): MatSnackBarRef<TextOnlySnackBar> {
    return this.snackBar.open(message, action);
  }

  /**
   * Creates XLSX option from the Json data. Use this to customize the sheet by adding arbitrary rows and columns.
   *
   * @param json Json data to create xlsx.
   * @param fileName filename to save as.
   */
  public exportJsonToExcel(json: IExcelJson[], fileName: string): void {
    const workbook: XLSX.WorkBook = {
      Sheets: {},
      SheetNames: [],
    };

    for (let i = 0, length = json.length; i < length; i++) {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
        json[i].data,
        this.getOptions(json[i])
      );

      if (!json[i].skipHeader) {
        const range = XLSX.utils.decode_range(worksheet['!ref']);

        for (let col = range.s.c; col <= range.e.c; col++) {
          const cell_address = XLSX.utils.encode_cell({ c: col, r: 0 }); // '0' assuming header is on the first row
          if (!worksheet[cell_address]) continue; // in case cell doesn't exist
          worksheet[cell_address].s = {
            font: { bold: true }, // Bold font
            alignment: { horizontal: 'center' }, // Center alignment
          };
        }
      }

      const sheetName = `Sheet${i + 1}`;
      workbook.SheetNames.push(sheetName);
      workbook.Sheets[sheetName] = worksheet;
    }

    // save to file
    XLSX.writeFile(workbook, `${fileName}.xlsx`, {
      cellStyles: true,
      bookSST: true,
    });
  }

  private getOptions(json: IExcelJson): any {
    const options = {
      skipHeader: true,
      header: [],
      cellStyles: null,
    };

    options.skipHeader = json.skipHeader ? json.skipHeader : false;

    if (!options.skipHeader && json.header && json.header.length) {
      options.header = json.header;
      options.cellStyles = {
        font: { bold: true }, // Bold font
        alignment: { horizontal: 'center' }, // Center alignment
      };
    }

    return options;
  }

  /**
   * Creates an array of data to CSV. It will automatically generate a title row based on object keys.
   *
   * @param rows array of data to be converted to CSV.
   * @param fileName filename to save as.
   * @param columns array of object properties to convert to CSV. If skipped, then all object properties will be used for CSV.
   */
  public exportToCsv(
    rows: object[],
    fileName: string,
    columns?: string[]
  ): string {
    if (!rows || !rows.length) {
      return;
    }
    const separator = ',';
    const keys = Object.keys(rows[0]).filter(k => {
      if (columns?.length) {
        return columns.includes(k);
      } else {
        return true;
      }
    });
    const csvContent =
      keys.join(separator) +
      '\n' +
      rows
        .map(row => {
          return keys
            .map(k => {
              let cell = row[k] === null || row[k] === undefined ? '' : row[k];
              cell =
                cell instanceof Date
                  ? cell.toLocaleString()
                  : cell.toString().replace(/"/g, '""');
              if (cell.search(/("|,|\n)/g) >= 0) {
                cell = `"${cell}"`;
              }
              return cell;
            })
            .join(separator);
        })
        .join('\n');
    this.downloadServerResponse(
      csvContent,
      CSV_TYPE,
      `${fileName}${CSV_EXTENSION}`
    );
  }
}
