import { createAction, props } from '@ngrx/store';

export const createForm = createAction(
  '[Form] Create Form',
  props<{ formId: string }>()
);
export const activateForm = createAction(
  '[Form] Activate Form',
  props<{ formId: string }>()
);
export const deactivateForm = createAction(
  '[Form] Deactivate Form',
  props<{ formId: string }>()
);
export const removeForm = createAction(
  '[Form] Remove Form',
  props<{ formId: string }>()
);
