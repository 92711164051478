<div class="position-relative main">
    <app-loading-overlay *ngIf="loading" diameter="50"></app-loading-overlay>
    <app-form formName="thermostatForm" [formGroup]="thermostatForm" (save)="onSave($event)">
<!--        <div class="container w-100">-->
            <div class="row w-100">
                <div class="col-5">
                    <app-input formControlName="brand" class="layer-box" label="Brand"></app-input>
                </div>
                <div class="col-3">
                    <app-input formControlName="cooling_setpoint" class="layer-box" type="number" label="Cooling setpoint" unit="&deg;F"></app-input>
                </div>
                <div class="col-3">
                    <app-input formControlName="heating_setpoint" type="number" class="layer-box text-center" label="Heating setpoint" unit="&deg;F"></app-input>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <app-checkbox class="layer-box d-flex justify-content-left" formControlName="has_programmable_thermostat" label="Programmable"></app-checkbox>
                </div>
                <div class="col-5">
                    <app-checkbox class="layer-box d-flex justify-content-right" formControlName="has_smart_thermostat" label="Smart"></app-checkbox>
                </div>
            </div>
<!--        </div>-->
    </app-form>
    <button *appRequiresEditPermission mat-icon-button class="delete-button">
        <mat-icon inline="true" (click)="removeThermostat()">delete</mat-icon>
    </button>
</div>
