<h2 mat-dialog-title>
  Share Invoice
</h2>
<mat-dialog-content *ngIf="initialized">
  <form [formGroup]="form" class="mb-2">
    <mat-form-field class="w-100">
      <mat-label>Emails</mat-label>
      <mat-chip-list #chipList aria-label="Fruit selection" formControlName="emails" >
        <mat-chip *ngFor="let email of emails" [selectable]="false"
                  [removable]="true" (removed)="remove(email)">
          {{ email }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input placeholder="New email address..."
               [matChipInputFor]="chipList"
               [matChipInputSeparatorKeyCodes]="emailsSeparatorKeysCodes"
               [matChipInputAddOnBlur]="true"
               (matChipInputTokenEnd)="add($event)">
      </mat-chip-list>
      <mat-hint>
        <span *ngIf="entity.invoice_copy_sent_at">Last email was sent {{ entity.invoice_copy_sent_at | date: 'MM/dd/yyyy'}}. </span>You can not send email more often than 1 per hour
      </mat-hint>
      <mat-error *ngIf="form.get('emails').hasError('required')">
        Set at least one email address
      </mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button color="accent" mat-raised-button (click)="save($event)">Share</button>
  <button mat-button [mat-dialog-close]="null" color="primary" mat-raised-button>Cancel</button>
</mat-dialog-actions>
