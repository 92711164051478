import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { MatDialog } from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-customer-hirl-registration-metric-tab',
  templateUrl: './customer-hirl-registration-metric-tab.component.html',
  styleUrls: ['./customer-hirl-registration-metric-tab.component.scss'],
})
export class CustomerHirlRegistrationMetricTabComponent
  implements OnInit, OnDestroy
{
  private componentDestroyed$ = new Subject();

  constructor(
    public store: Store<AppState>,
    public dialog: MatDialog,
    public uiHelper: UIHelperService
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
