import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavService } from '@/data/core/services/nav.service';
import { filter, first, map, skip, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { toggleBooting } from '@/state/actions/app.actions';
import { getInfoUser } from '@/state/selectors/info.selector';
import { forkJoin, Subject } from 'rxjs';
import {
  getRecentMessageAdded,
  getRecentMessages,
} from '@/state/selectors/messaging/message.selector';
import { ModernMessage } from '@/data/messaging/models';
import { MessagesSnackbarService } from '@/modules/messaging/services/messages-snackbar.service';

@Component({
  selector: 'app-container',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss'],
})
export class ContentLayoutComponent implements OnInit, OnDestroy {
  private componentDestroyed$ = new Subject();

  constructor(
    private menuService: NavService,
    private messagesSnackbarService: MessagesSnackbarService,
    private store: Store<AppState>
  ) {
    this.store
      .select(getRecentMessages)
      .pipe(
        skip(1),
        first(),
        takeUntil(this.componentDestroyed$),
        map((messages: ModernMessage[]) =>
          messages.filter(message => !message.alert_seen_at)
        )
      )
      .subscribe({
        next: (messages: ModernMessage[]) => {
          messages.forEach((message: ModernMessage) => {
            this.messagesSnackbarService.show(message);
          });
        },
      });

    this.store
      .select(getRecentMessageAdded)
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(
          (message: ModernMessage) => message !== null && !message.alert_seen_at
        )
      )
      .subscribe({
        next: (message: ModernMessage) => {
          this.messagesSnackbarService.show(message);
        },
      });
  }

  ngOnInit() {
    forkJoin([
      this.store.select(getInfoUser).pipe(first()),
      this.menuService.list().pipe(first()),
    ]).subscribe(result => {
      this.store.dispatch(toggleBooting({ payload: false }));
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
