import { Component, OnDestroy, OnInit } from '@angular/core';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';
import { User } from '@/data/core/models/user';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { filter, first, takeUntil } from 'rxjs/operators';
import {
  Accreditation,
  CustomerHIRLInspectionGradeList,
  InspectionGradeAggregateByLetterGrade,
} from '@/data/user-management/models';
import {
  InspectionGradeRequestParams,
  InspectionGradeService,
} from '@/data/user-management/services/inspection-grade.service';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import {
  UserRequestParams,
  UserService,
} from '@/data/core/services/user.service';
import {
  LetterGrade,
  LetterGradeLabelMapping,
} from '@/data/user-management/models/inspection-grade';
import {
  QARequirementType,
  QARequirementTypeLabelMapping,
} from '@/data/qa/models';
import { InspectionGradePermissionService } from '@/modules/user-management/services/inspection-grade-permission-service';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { HIRLProjectRequestParams } from '@/data/customer-hirl/services/hirl-projects.service';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import {
  getUser,
  getUserCustomerHIRLInspectionGrades,
  getUserCustomerHIRLInspectionGradesCount,
} from '@/state/selectors/user/user.selector';
import { ServerResponse } from '@/core/schemes/server-response';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { toggleLoading } from '@/state/actions/app.actions';
import { HttpErrorResponse } from '@angular/common/http';

import {
  InspectionGradeChangeChangeDialogData,
  InspectionGradeChangeDialogComponent,
} from '@/modules/user-management/components/inspection-grade-change-dialog/inspection-grade-change-dialog.component';

@Component({
  selector: 'app-inspection-grade-tab',
  templateUrl: './inspection-grade-tab.component.html',
  styleUrls: ['./inspection-grade-tab.component.scss'],
})
export class InspectionGradeTabComponent implements OnInit, OnDestroy {
  public displayedColumns = [
    'graded_date',
    'grade',
    'home_address',
    'qa_type',
    'eep_program_name',
    'notes',
    'actions',
  ];

  public user: User;
  public currentUser: User;

  public entities: CustomerHIRLInspectionGradeList[];
  public entitiesCount: number;

  public isLoading = true;
  public customerHIRLSettings = CustomerHIRLSettings;
  public initialized = false;

  public userRequestParams: UserRequestParams;

  public defaultParams: HIRLProjectRequestParams =
    new InspectionGradeRequestParams(1, '', '-id', 25);
  public storedParams: InspectionGradeRequestParams = Object.assign(
    new InspectionGradeRequestParams(),
    this.defaultParams
  );

  public letterGradeLabelMapping = LetterGradeLabelMapping;

  public filterFormGroup: FormGroup;
  public overwritePropertyValues = {};
  public overwritePropertyLabels = {};
  public excludedProperties: string[];

  public inspectionGradeAggregateByLetterGrade: InspectionGradeAggregateByLetterGrade;
  public qaRequirementTypeLabelMapping = QARequirementTypeLabelMapping;
  public qaRequirementTypeChoices = {
    [QARequirementType.roughInspection]: 'Rough Inspection',
    [QARequirementType.finalInspection]: 'Final Inspection',
    [QARequirementType.desktopAudit]: 'Desktop Audit',
    [QARequirementType.roughInspectionVirtualAudit]: 'Rough Virtual Audit',
    [QARequirementType.finalInspectionVirtualAudit]: 'Final Virtual Audit',
  };

  public permissions: {
    [key: string]: {
      canEdit: Observable<ObjectPermissionResponse>;
      canDelete: Observable<ObjectPermissionResponse>;
    };
  } = {};

  private componentDestroyed$ = new Subject();

  public aggregateByLetterGradeIsLoading = true;
  public averageGrade: string;

  constructor(
    public store: Store<AppState>,
    public inspectionGradeService: InspectionGradeService,
    public router: Router,
    public dialog: MatDialog,
    public inspectionGradePermissionService: InspectionGradePermissionService,
    public userService: UserService,
    public activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public uiHelperService: UIHelperService
  ) {
    this.userRequestParams = new UserRequestParams();
    this.userRequestParams.company__slug =
      this.customerHIRLSettings.companySlug;
    this.userRequestParams.hirluserprofile__is_qa_designee = true;
  }

  ngOnInit() {
    this.store
      .select(getUser)
      .pipe(
        filter(val => val !== null),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((entity: User) => {
        this.user = entity;
        this.list();
      });

    this.store
      .select(getUserCustomerHIRLInspectionGrades)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((inspectionGrades: CustomerHIRLInspectionGradeList[]) => {
        this.entities = inspectionGrades;
      });

    this.store
      .select(getUserCustomerHIRLInspectionGradesCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((count: number) => {
        this.entitiesCount = count;
      });

    this.setupFilterForm();
    this.list();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  setupFilterForm() {
    this.filterFormGroup = this.fb.group({
      search: [null],
      qa_status__home_status__eep_program_info: [null],
      letter_grade: [null],
      qa_status__requirement__type: [null],
      graded_date__gte: [null],
      graded_date__lte: [null],
      approver_info: [null],
    });

    this.filterFormGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        let params = Object.assign(this.defaultParams, values);

        params = Object.assign(params, {
          approver: params.approver_info?.id || null,
          qa_status__home_status__eep_program:
            params.qa_status__home_status__eep_program_info?.map(
              eepProgramInfo => eepProgramInfo.id
            ) || null,
        });

        this.storedParams.assignFilterFormValues({
          formValues: params,
          excludedProperties: [
            'ordering',
            'approver_info',
            'user_info',
            'qa_status__home_status__eep_program_info',
          ],
        });

        this.list();
      });
  }

  list() {
    this.isLoading = true;
    this.aggregateByLetterGradeIsLoading = true;

    this.isLoading = true;
    this.userService
      .inspection_grades_customer_hirl_list(this.user.id, this.storedParams)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data: ServerResponse<CustomerHIRLInspectionGradeList>) => {
        this.entities = data.results;
        this.entitiesCount = data.count;
        this.entities.forEach((row: CustomerHIRLInspectionGradeList) => {
          this.permissions[row.id] = {
            canEdit: this.inspectionGradePermissionService.canEdit(row),
            canDelete: this.inspectionGradePermissionService.canDelete(row),
          };
        });
        this.isLoading = false;
      });

    this.userService
      .inspection_grades_aggregate_by_letter_grade(
        this.user.id,
        this.storedParams
      )
      .pipe(first())
      .subscribe(
        (
          inspectionGradeAggregateByLetterGrade: InspectionGradeAggregateByLetterGrade
        ) => {
          this.inspectionGradeAggregateByLetterGrade =
            inspectionGradeAggregateByLetterGrade;
          const total =
            this.inspectionGradeAggregateByLetterGrade.a_grade +
            this.inspectionGradeAggregateByLetterGrade.b_grade +
            this.inspectionGradeAggregateByLetterGrade.c_grade +
            this.inspectionGradeAggregateByLetterGrade.d_grade +
            this.inspectionGradeAggregateByLetterGrade.f_grade;
          const grades =
            LetterGrade.A * this.inspectionGradeAggregateByLetterGrade.a_grade +
            LetterGrade.B * this.inspectionGradeAggregateByLetterGrade.b_grade +
            LetterGrade.C * this.inspectionGradeAggregateByLetterGrade.c_grade +
            LetterGrade.D * this.inspectionGradeAggregateByLetterGrade.d_grade +
            LetterGrade.F * this.inspectionGradeAggregateByLetterGrade.f_grade;
          const average = Math.round(grades / total);
          this.averageGrade = LetterGradeLabelMapping[average];
          this.aggregateByLetterGradeIsLoading = false;
        }
      );
  }

  onSort($event: Sort) {
    this.storedParams.ordering = `${$event.direction === 'desc' ? '-' : ''}${
      $event.active
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  onPaginateChange($event: PageEvent) {
    this.uiHelperService.getUISettings().rowsPerPage = $event.pageSize;
    this.storedParams.page_size = $event.pageSize;
    this.storedParams.page = $event.pageIndex + 1;
    this.list();
  }

  edit($event: MouseEvent, inspectionGrade: CustomerHIRLInspectionGradeList) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(InspectionGradeChangeDialogComponent, {
      width: '55%',
      disableClose: true,
      autoFocus: false,
      data: {
        entity: inspectionGrade.id,
      } as InspectionGradeChangeChangeDialogData,
    });
    dialogRef.afterClosed().subscribe((result?: Accreditation) => {
      if (!result) return;
      this.list();
      this.uiHelperService.openSnackBar(`Accreditation successfully updated`);
    });
  }

  delete($event: MouseEvent, inspectionGrade: CustomerHIRLInspectionGradeList) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '45%',
      data: {
        title: 'Delete Inspection Grade ?',
        content: `Do you want to delete inspection grade ?`,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) return;
      this.store.dispatch(toggleLoading({ payload: true }));
      this.inspectionGradeService
        .delete(inspectionGrade.id)
        .pipe(first())
        .subscribe({
          next: () => {
            this.list();
            this.store.dispatch(toggleLoading({ payload: false }));
            this.uiHelperService.openSnackBar(
              `Inspection Grade successfully deleted`
            );
          },
          error: (error: HttpErrorResponse) =>
            this.uiHelperService.handleUserRequestError(error),
        });
    });
  }
}
