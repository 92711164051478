import { MechanicalVentilationTypeLabels } from '@/data/simulation/models/systems/MechanicalVentilation';
import { selectMechanicalVentilationErrorsCount } from '@/modules/simulation/state/mechanical-ventilation/selectors';
import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-mechanical-ventilation-errors',
  templateUrl: './mechanical-ventilation-errors.component.html',
  styleUrls: ['./mechanical-ventilation-errors.component.scss'],
})
export class MechanicalVentilationErrorsComponent implements OnInit {
  @Input() entityId: number;
  componentDestroyed$: Subject<boolean> = new Subject();
  totalErrorCount = 0;
  totalWarningCount = 0;
  type: string;

  constructor(public store: Store) {}

  ngOnInit(): void {
    this.store
      .select(selectMechanicalVentilationErrorsCount(this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.totalErrorCount = data.errorCount;
        this.totalWarningCount = data.warningCount;
        this.type = MechanicalVentilationTypeLabels[data.type];
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
