<div class="card position-relative">
    <app-loading-overlay *ngIf="loading"></app-loading-overlay>
    <div class="card-header">
        <div class="d-flex justify-content-between">
            <span>Dishwasher</span>
            <mat-icon [inline]="true">dishwasher_gen</mat-icon>
        </div>
    </div>
    <div class="card-body pb-0">
        <app-form formName="dishWasherForm" [formGroup]="form" (save)="onSave($event)">
            <div>
                <app-select formControlName="dishwasher_location" [label]="fieldLabels.dishwasher_location" [items]="locationLabels"></app-select>
            </div>
            <div>
                <app-input
                    formControlName="dishwasher_consumption"
                    type="number"
                    [label]="fieldLabels.dishwasher_consumption"
                    [unit]="auxEnergyUnitLabels[entity?.dishwasher_consumption_unit]">
                </app-input>
                <app-input
                    formControlName="dishwasher_efficiency"
                    type="number"
                    [label]="fieldLabels.dishwasher_efficiency"
                    [unit]="dishwasherEfficiencyLabels[entity?.dishwasher_efficiency_unit]"></app-input>
            </div>
            <div>
                <app-select [items]="dishwasherSizeUnitLabels" formControlName="dishwasher_size" [label]="fieldLabels.dishwasher_size" type="number"></app-select>
                <app-input formControlName="dishwasher_loads_per_week" type="number" [label]="fieldLabels.dishwasher_loads_per_week"></app-input>
            </div>
            <div>
                <app-select formControlName="dishwasher_preset" [label]="fieldLabels.dishwasher_preset"
                    [items]="dishwasherPresetLabels"></app-select>
            </div>
            <div class="mx-auto mb-3">&#9733;&#9733;<strong>Energy Star</strong>&#9733;&#9733;</div>
            <div>
                <app-input
                    formControlName="dishwasher_label_energy_rating"
                    type="number"
                    label="Energy Rating"
                    [unit]="auxEnergyUnitLabels[entity?.dishwasher_label_energy_rating_unit]">
                </app-input>
                <app-input
                    formControlName="dishwasher_label_gas_annual_cost"
                    type="number"
                    label="Annual Gas cost"
                    [unit]="costUnitLabels[entity?.dishwasher_label_gas_annual_cost_unit]">
                </app-input>
            </div>
            <div>
                <app-input
                    formControlName="dishwasher_label_electric_rate"
                    type="number"
                    label="Electric Rate"
                    [unit]="applianceCostRateUnitLabels[entity?.dishwasher_label_electric_rate_unit]">
                </app-input>
                <app-input
                    formControlName="dishwasher_label_gas_rate"
                    type="number"
                    label="Gas rate"
                    [unit]="applianceCostRateUnitLabels[entity?.dishwasher_gas_rate_unit]">
                </app-input>
            </div>
        </app-form>
    </div>
</div>
