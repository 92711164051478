import { createSelector } from '@ngrx/store';
import { SimulationState } from '../reducers';
import { selectSimulationFeature } from '../simulation.selectors';

export const selectMechanicalVentilation = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.mechanicalVentilation.entities[id]
  );

const selectMechanicalVentilationLoading = (id: number) =>
  createSelector(
    selectSimulationFeature,
    state => state.mechanicalVentilation.loading[id]
  );

export const selectMechanicalVentilationViewSet = (id: number) =>
  createSelector(
    selectMechanicalVentilation(id),
    selectMechanicalVentilationLoading(id),
    (data, loading) => ({ data, loading })
  );

export const selectMechanicalVentilationErrorsCount = (id: number) =>
  createSelector(selectSimulationFeature, (state: SimulationState) => ({
    type: state.mechanicalVentilation.entities[id].type,
    errorCount: state.mechanicalVentilation.errors[id].errorCount,
    warningCount: state.mechanicalVentilation.errors[id].warningCount,
  }));
