import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  Company,
  GCPFeeCalculationTypeLabelMapping,
  InspectionGradeTypeLabelMapping,
} from '@/data/company/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin, of, Subject } from 'rxjs';
import { User } from '@/data/core/models/user';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { CompanyService } from '@/data/company/services/company-base.service';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, switchMap, takeUntil } from 'rxjs/operators';
import { toggleLoading } from '@/state/actions/app.actions';
import { ConfirmedGeocodeResponse } from '@/modules/geocoder/components/confirm-geocode-response-dialog/confirm-geocode-response';
import { CustomerHIRLSettings } from '../../../customer-hirl/constants';

export class CompanyEditMiscDialogData {
  company: Company;
  commit = true;
  retrieve = true;
}

@Component({
  selector: 'app-company-edit-misc-dialog',
  templateUrl: './company-edit-misc-dialog.component.html',
  styleUrls: ['./company-edit-misc-dialog.component.scss'],
})
export class CompanyEditMiscDialogComponent implements OnInit, OnDestroy {
  protected readonly CustomerHIRLSettings = CustomerHIRLSettings;
  protected readonly GCPFeeCalculationTypeLabelMapping =
    GCPFeeCalculationTypeLabelMapping;

  public initialized: boolean;
  public entity?: Company;
  public commit = true;
  public form: FormGroup;

  private componentDestroyed$ = new Subject();
  public currentUser: User;
  public retrieve = true;
  public inspectionGradeTypeLabelMapping = InspectionGradeTypeLabelMapping;

  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<CompanyEditMiscDialogComponent>,
    private fb: FormBuilder,
    private companyService: CompanyService,
    private dialog: MatDialog,
    private uiHelperService: UIHelperService,
    @Inject(MAT_DIALOG_DATA) public data: CompanyEditMiscDialogData
  ) {
    this.entity = Object.assign({}, data.company);
    this.commit = data?.commit || true;
    this.retrieve = data?.retrieve || true;
  }

  ngOnInit() {
    const sources = {
      currentUser: this.store.select(getInfoUser).pipe(first()),
      company: of(this.entity),
    };

    this.store.dispatch(toggleLoading({ payload: true }));

    if (this.retrieve) {
      sources.company = this.companyService
        .retrieve(this.entity.id, { ignoreStore: true })
        .pipe(takeUntil(this.componentDestroyed$), first());
    }

    forkJoin(sources)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: ({ currentUser, company }) => {
          this.store.dispatch(toggleLoading({ payload: false }));

          this.currentUser = currentUser;
          this.entity = company;
          this.setupForm();
          this.initialized = true;
          this.hydrateForm();
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  private setupForm() {
    this.form = this.fb.group({
      description: [null, []],
      auto_add_direct_relationships: [true, []],
      display_raw_addresses: [false, []],
      inspection_grade_type: [null, [Validators.required]],
      edit_shipping_address: [null],
      logo: [null],
      hirl_marketing_roles: [null],
      gcp_fee_calculation_type: [null],
    });
  }

  hydrateForm() {
    this.form.patchValue(this.entity, { emitEvent: false, onlySelf: true });

    // mat-select expecting string
    this.form.patchValue({
      inspection_grade_type: this.entity.inspection_grade_type.toString(),
    });

    this.form.patchValue(
      {
        edit_shipping_address: new ConfirmedGeocodeResponse({
          geocode: this.entity.shipping_geocode_info,
          geocodeBrokeredResponse: this.entity.shipping_geocode_response_info,
        }),
      },
      { emitEvent: false, onlySelf: true }
    );
  }

  save($event: MouseEvent) {
    $event.preventDefault();
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    if (!this.commit) {
      this.dialogRef.close(this.entity);
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const data = new Company();
    data.description = this.form.value.description || '';
    data.auto_add_direct_relationships =
      this.form.value.auto_add_direct_relationships;
    data.display_raw_addresses = this.form.value.display_raw_addresses;
    data.inspection_grade_type = this.form.value.inspection_grade_type;

    if (
      ((this.currentUser.is_company_admin &&
        this.currentUser.company === this.entity.id) ||
        this.currentUser.company_info.slug ===
          CustomerHIRLSettings.companySlug) &&
      (this.entity?.sponsor_slugs?.includes(CustomerHIRLSettings.companySlug) ||
        this.entity?.slug === CustomerHIRLSettings.companySlug)
    ) {
      data.hirl_marketing_roles = this.form.value.hirl_marketing_roles?.map(
        value => value.id
      );
      data.gcp_fee_calculation_type = this.form.value.gcp_fee_calculation_type;
    }
    data.shipping_geocode = this.form.value.edit_shipping_address?.geocode?.id;
    data.shipping_geocode_response = null;

    if (this.form.value.edit_shipping_address?.geocodeBrokeredResponse) {
      data.shipping_geocode_response =
        this.form.value.edit_shipping_address?.geocodeBrokeredResponse?.id;
    }

    this.companyService
      .update(this.entity.id, data)
      .pipe(
        first(),
        // update file fields
        switchMap(_ => {
          const formData = new FormData();
          formData.append('logo', this.form.value.logo);
          return this.companyService.update(this.entity.id, formData);
        })
      )
      .subscribe({
        next: company => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close(company);
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }
}
