import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { EEPProgramHomeStatusService } from '@/data/home/services/eep_program_home_status.service';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { environment } from '../../../../../environments/environment';
import { HIRLProjectRegistrationCertificateLineChoices } from '@/data/customer-hirl/models';

@Component({
  selector: 'app-hirl-certificate-preview',
  templateUrl: './hirl-certificate-preview.component.html',
  styleUrls: ['./hirl-certificate-preview.component.scss'],
})
export class HirlCertificatePreviewComponent
  implements OnInit, OnChanges, OnDestroy
{
  private baseUrl = `${environment.AXIS_API_V3_ROOT}/home_project_statuses/customer_hirl_certificate_preview/?`;
  public downloadURL: string = this.baseUrl;

  @Input() eep_program_slug?: string = 'ngbs-sf-new-construction-2020-new';
  @Input() certification_level?: string = 'emerald';

  @Input() certificate_line1?: HIRLProjectRegistrationCertificateLineChoices =
    HIRLProjectRegistrationCertificateLineChoices.emptyLine;
  @Input() certificate_line1_custom_text?: string = '';

  @Input() certificate_line2?: HIRLProjectRegistrationCertificateLineChoices =
    HIRLProjectRegistrationCertificateLineChoices.emptyLine;
  @Input() certificate_line2_custom_text?: string = '';

  @Input() certificate_line3?: HIRLProjectRegistrationCertificateLineChoices =
    HIRLProjectRegistrationCertificateLineChoices.emptyLine;
  @Input() certificate_line3_custom_text?: string = '';

  @Input() certificate_line4?: HIRLProjectRegistrationCertificateLineChoices =
    HIRLProjectRegistrationCertificateLineChoices.emptyLine;
  @Input() certificate_line4_custom_text?: string = '';

  @Input() certificate_line5?: HIRLProjectRegistrationCertificateLineChoices =
    HIRLProjectRegistrationCertificateLineChoices.emptyLine;
  @Input() certificate_line5_custom_text?: string = '';

  @Input() certificate_line6?: HIRLProjectRegistrationCertificateLineChoices =
    HIRLProjectRegistrationCertificateLineChoices.emptyLine;
  @Input() certificate_line6_custom_text?: string = '';

  @Input() certificate_line7?: HIRLProjectRegistrationCertificateLineChoices =
    HIRLProjectRegistrationCertificateLineChoices.emptyLine;
  @Input() certificate_line7_custom_text?: string = '';

  @Input() certificate_line8?: HIRLProjectRegistrationCertificateLineChoices =
    HIRLProjectRegistrationCertificateLineChoices.emptyLine;
  @Input() certificate_line8_custom_text?: string = '';

  @Input() certificate_line9?: HIRLProjectRegistrationCertificateLineChoices =
    HIRLProjectRegistrationCertificateLineChoices.emptyLine;
  @Input() certificate_line9_custom_text?: string = '';

  private componentDestroyed$ = new Subject();
  constructor(
    private store: Store<AppState>,
    private eepProgramHomeStatusService: EEPProgramHomeStatusService,
    private uiHelperService: UIHelperService
  ) {}

  ngOnInit(): void {
    this.updatePreview();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updatePreview();
  }

  updatePreview() {
    const params = this.eepProgramHomeStatusService.rollParams({
      eep_program_slug: this.eep_program_slug,
      certification_level: this.certification_level,
      certificate_line1: this.certificate_line1,
      certificate_line1_custom_text: this.certificate_line1_custom_text,
      certificate_line2: this.certificate_line2,
      certificate_line2_custom_text: this.certificate_line2_custom_text,
      certificate_line3: this.certificate_line3,
      certificate_line3_custom_text: this.certificate_line3_custom_text,
      certificate_line4: this.certificate_line4,
      certificate_line4_custom_text: this.certificate_line4_custom_text,
      certificate_line5: this.certificate_line5,
      certificate_line5_custom_text: this.certificate_line5_custom_text,
      certificate_line6: this.certificate_line6,
      certificate_line6_custom_text: this.certificate_line6_custom_text,
      certificate_line7: this.certificate_line7,
      certificate_line7_custom_text: this.certificate_line7_custom_text,
      certificate_line8: this.certificate_line8,
      certificate_line8_custom_text: this.certificate_line8_custom_text,
      certificate_line9: this.certificate_line9,
      certificate_line9_custom_text: this.certificate_line9_custom_text,
    });
    // hide nav panels by default
    this.downloadURL =
      this.baseUrl + params + '#toolbar=0&navpanes=0#page=1&zoom=70';
  }
}
