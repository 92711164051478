<div class="row" *ngIf="initialized else loading">
    <div class="col-12">
        <div class="list-group">
            <a class="list-group-item list-group-item-action" *ngFor="let row of rows" style="cursor: pointer;" (click)="handleOpenFlatpage($event, row)">
                <div class="badge badge-secondary float-left mr-2" style="width: 60px; pointer-events: none;">
                    <h5>
                        {{ row.created_at | date:"d"}}<br>
                        {{ row.created_at | date:"MMM"}}
                    </h5>
                </div>
                <a href="#" style="pointer-events: none;">{{ row.title }}</a>
            </a>
        </div>
<!--        <div class="row">-->
<!--            <div class="col-12 text-right">-->
<!--                <a href="#"><small>View all</small></a>-->
<!--            </div>-->
<!--        </div>-->
    </div>
</div>
<ng-template #loading>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-center">
                        <div class="d-flex flex-column justify-content-center" style="height: 320px;">
                            <mat-spinner [diameter]="50"></mat-spinner>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>