import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { User } from '@/data/core/models/user';
import { AxisFlatPage } from '@/data/core/models';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';
import { forkJoin, Subject } from 'rxjs';
import { UntypedFormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '@/data/core/services/user.service';
import { AxisFlatPageService } from '@/data/core/services/axis_flat_page';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { toggleLoading } from '@/state/actions/app.actions';
import { first, takeUntil } from 'rxjs/operators';
import { getInfoUser } from '@/state/selectors/info.selector';

@Component({
  selector: 'app-hirl-pm-central',
  templateUrl: './hirl-pm-central.component.html',
  styleUrls: ['./hirl-pm-central.component.scss'],
})
export class HirlPmCentralComponent implements OnInit, OnDestroy {
  public initialized = false;
  public currentUser: User;
  public page: AxisFlatPage;
  public customerHIRLSettings = CustomerHIRLSettings;

  public params$ = new Subject();
  private componentDestroyed$ = new Subject();

  constructor(
    public fb: UntypedFormBuilder,
    public store: Store<AppState>,
    public injector: Injector,
    public router: Router,
    public dialog: MatDialog,
    public activatedRoute: ActivatedRoute,
    public userService: UserService,
    public axisFlatPageService: AxisFlatPageService,
    public uiHelperService: UIHelperService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(toggleLoading({ payload: true }));

    this.params$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(({ year }) => {
        const sources: { [k: string]: any } = {
          page: this.axisFlatPageService.customer_hirl_pm_central_page(),
        };

        forkJoin(sources).subscribe(
          ({ page }) => {
            this.page = page;

            this.initialized = true;
            this.store.dispatch(toggleLoading({ payload: false }));
          },
          error => this.uiHelperService.handleMainRequestError(error)
        );
      });

    this.store
      .select(getInfoUser)
      .pipe(first())
      .subscribe(currentUser => {
        this.currentUser = currentUser;
        this.activatedRoute.params
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(params => {
            this.params$.next(params);
          });
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
