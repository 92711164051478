import { SimulationSourceType } from '@/data/simulation/models/simulation';

export class SeedBackendDict {
  id: number;
  source_type: SimulationSourceType;
  simulation: number;
}

export class SeedCreateBackendDict {
  source_type: SimulationSourceType;
  source_date: string;
  document: number;
}
