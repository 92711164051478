import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { selectBlockRateViewSet } from '@/modules/simulation/state/block-rate/selectors';
import {
  BlockRateBackendDict,
  createBlockRateForm,
} from '@/data/simulation/models/utility/BlockRate';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-block-rate',
  templateUrl: './block-rate.component.html',
  styleUrls: ['./block-rate.component.scss'],
})
export class BlockRateComponent {
  @Input() entityId: number;

  componentDestroyed$ = new Subject();

  entity: BlockRateBackendDict;
  loading: boolean;
  blockRateForm: FormGroup;

  constructor(public store: Store) {}

  ngOnInit() {
    if (this.entityId === null) {
      return;
    }
    this.store
      .select(selectBlockRateViewSet(this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(({ data, loading }) => {
        this.loading = loading;

        if (loading) return;

        this.entity = data;
        if (this.blockRateForm) {
          this.blockRateForm.patchValue(data);
        } else {
          this.blockRateForm = createBlockRateForm(data);
        }
      });
  }

  saveBlockRate(blockRateChangedValues: Partial<BlockRateBackendDict>) {
    // if (this.entityId === null) {
    //   this.save.emit(this.blockRateForm.value);
    //   return;
    // }
    // this.store.dispatch(
    //   updateBlockRate({
    //     blockRateChanges: blockRateChangedValues,
    //   })
    // );
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
