import {
  AppliancesBackendDict,
  getAppliancesForms,
} from '@/data/simulation/models/Appliances';
import { BaseValidator } from './base.validator';
import { ModelErrors, StateErrors } from '../state/shared/base.state';

export class AppliancesValidator extends BaseValidator {
  static validateForm(
    appliances: AppliancesBackendDict
  ): ModelErrors<AppliancesBackendDict> {
    const appliancesForms = getAppliancesForms(appliances);
    const fieldErrors = {};
    let modelErrors = {};
    for (const form of appliancesForms) {
      Object.entries(form.controls).forEach(([fieldName, control]) => {
        if (control.errors) {
          fieldErrors[fieldName] = control.errors;
        }
      });

      modelErrors = {
        ...modelErrors,
        ...form.errors,
      };
    }
    return {
      field: fieldErrors,
      model: modelErrors,
    };
  }

  static validate(
    appliancess: AppliancesBackendDict[]
  ): StateErrors<AppliancesBackendDict> {
    const errors = {};
    appliancess.forEach(appliances => {
      errors[appliances.id] = this.validateForm(appliances);
    });
    return errors;
  }
}
