import { ListRequestParams } from '@/core/schemes/request-params';
import { Injectable } from '@angular/core';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { ServerResponse } from '@/core/schemes/server-response';
import { tap } from 'rxjs/operators';
import { CustomerDocumentRequestParams } from '@/data/filehandling/services/customer-document.service';
import { CustomerDocument } from '@/data/filehandling/models';
import { HIRLProductAgreement } from '@/data/customer-hirl/models';
import {
  loadHIRLProductAgreement,
  loadHIRLProductAgreementDocuments,
  loadHIRLProductAgreementDocumentsCount,
} from '@/state/actions/customer-hirl/hirl-product-agreement.actions';

export class HIRLProductAgreementRequestParams extends ListRequestParams {}

@Injectable({
  providedIn: 'root',
})
export class HIRLProductAgreementService extends BaseDataService {
  baseUrl = `${environment.AXIS_API_V3_ROOT}/hirl_product_agreements/`;

  list(
    params?: HIRLProductAgreementRequestParams
  ): Observable<ServerResponse<HIRLProductAgreement>> {
    return this.http.get<ServerResponse<HIRLProductAgreement>>(
      `${this.baseUrl}`,
      {
        params: this.rollParams(params),
      }
    );
  }

  retrieve(
    hirlProductAgreementId: number,
    options?: IDataServiceMethodOptions
  ): Observable<HIRLProductAgreement> {
    const ignoreStore = options?.ignoreStore;
    let chain = this.http.get<HIRLProductAgreement>(
      `${this.baseUrl}${hirlProductAgreementId}`,
      {}
    );
    if (!ignoreStore) {
      chain = chain.pipe(
        tap(hirlProductAgreement =>
          this.store.dispatch(
            loadHIRLProductAgreement({ payload: hirlProductAgreement })
          )
        )
      );
    }
    return chain;
  }

  create(
    hirlProductAgreement: HIRLProductAgreement,
    options?: IDataServiceMethodOptions
  ): Observable<HIRLProductAgreement> {
    const ignoreStore: boolean = options?.ignoreStore;
    let chain: Observable<HIRLProductAgreement> =
      this.http.post<HIRLProductAgreement>(
        `${this.baseUrl}`,
        hirlProductAgreement
      );
    if (!ignoreStore) {
      chain = chain.pipe(
        tap((newHIRLProductAgreement: HIRLProductAgreement) =>
          this.store.dispatch(
            loadHIRLProductAgreement({ payload: newHIRLProductAgreement })
          )
        )
      );
    }
    return chain;
  }

  update(
    hirlProductAgreement: HIRLProductAgreement,
    hirlProductAgreementId: number,
    options?: IDataServiceMethodOptions
  ): Observable<HIRLProductAgreement> {
    const ignoreStore = options?.ignoreStore;
    let chain: Observable<HIRLProductAgreement> =
      this.http.patch<HIRLProductAgreement>(
        `${this.baseUrl}${hirlProductAgreementId}/`,
        hirlProductAgreement
      );
    if (!ignoreStore) {
      chain = chain.pipe(
        tap((newHIRLProductAgreement: HIRLProductAgreement) =>
          this.store.dispatch(
            loadHIRLProductAgreement({ payload: newHIRLProductAgreement })
          )
        )
      );
    }
    return chain;
  }

  delete(hirlProductAgreementId: number) {
    return this.http.delete(`${this.baseUrl}${hirlProductAgreementId}`);
  }

  approve(
    hirlProductAgreementId: number,
    options?: IDataServiceMethodOptions
  ): Observable<HIRLProductAgreement> {
    const ignoreStore = options?.ignoreStore;
    let chain = this.http.post<HIRLProductAgreement>(
      `${this.baseUrl}${hirlProductAgreementId}/approve/`,
      {}
    );
    if (!ignoreStore) {
      chain = chain.pipe(
        tap((newHIRLProductAgreement: HIRLProductAgreement) =>
          this.store.dispatch(
            loadHIRLProductAgreement({ payload: newHIRLProductAgreement })
          )
        )
      );
    }
    return chain;
  }

  verify(
    hirlProductAgreementId: number,
    options?: IDataServiceMethodOptions
  ): Observable<HIRLProductAgreement> {
    const ignoreStore = options?.ignoreStore;
    let chain = this.http.post<HIRLProductAgreement>(
      `${this.baseUrl}${hirlProductAgreementId}/verify/`,
      {}
    );
    if (!ignoreStore) {
      chain = chain.pipe(
        tap((newHIRLProductAgreement: HIRLProductAgreement) =>
          this.store.dispatch(
            loadHIRLProductAgreement({ payload: newHIRLProductAgreement })
          )
        )
      );
    }
    return chain;
  }

  resend_docusign_email(clientAgreementId: number): Observable<boolean> {
    return this.http.post<boolean>(
      `${this.baseUrl}${clientAgreementId}/resend_docusign_email/`,
      {}
    );
  }

  update_docusign_status(
    hirlProductAgreementId: number,
    options?: IDataServiceMethodOptions
  ): Observable<HIRLProductAgreement> {
    const ignoreStore = options?.ignoreStore;
    let chain: Observable<HIRLProductAgreement> =
      this.http.post<HIRLProductAgreement>(
        `${this.baseUrl}${hirlProductAgreementId}/update_docusign_status/`,
        {}
      );
    if (!ignoreStore) {
      chain = chain.pipe(
        tap((newHIRLProductAgreement: HIRLProductAgreement) =>
          this.store.dispatch(
            loadHIRLProductAgreement({
              payload: newHIRLProductAgreement,
            })
          )
        )
      );
    }
    return chain;
  }

  documents(
    objectId: number | string,
    params?: CustomerDocumentRequestParams
  ): Observable<ServerResponse<CustomerDocument>> {
    return super.documents(objectId, params).pipe(
      tap(serverResponse =>
        this.store.dispatch(
          loadHIRLProductAgreementDocuments({ payload: serverResponse.results })
        )
      ),
      tap(serverResponse =>
        this.store.dispatch(
          loadHIRLProductAgreementDocumentsCount({
            payload: serverResponse.count,
          })
        )
      )
    );
  }
}
