import { Component, OnDestroy, OnInit } from '@angular/core';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';
import { User } from '@/data/core/models/user';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { filter, first, takeUntil } from 'rxjs/operators';
import {
  AttendanceTypeLabelMapping,
  Training,
  TrainingStatusStatusLabelMapping,
  TrainingTypeLabelMapping,
} from '@/data/user-management/models';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserService } from '@/data/core/services/user.service';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import {
  getUser,
  getUserTrainings,
  getUserTrainingsCount,
} from '@/state/selectors/user/user.selector';
import { ServerResponse } from '@/core/schemes/server-response';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { toggleLoading } from '@/state/actions/app.actions';
import { HttpErrorResponse } from '@angular/common/http';
import {
  TrainingRequestParams,
  TrainingService,
} from '@/data/user-management/services/training.service';
import { TrainingPermissionService } from '@/modules/user-management/services/training-permission.service';
import {
  TrainingChangeDialogComponent,
  TrainingChangeDialogData,
} from '@/modules/user-management/components/training-change-dialog/training-change-dialog.component';

@Component({
  selector: 'app-training-tab',
  templateUrl: './training-tab.component.html',
  styleUrls: ['./training-tab.component.scss'],
})
export class TrainingTabComponent implements OnInit, OnDestroy {
  protected readonly TrainingTypeLabelMapping = TrainingTypeLabelMapping;
  protected readonly AttendanceTypeLabelMapping = AttendanceTypeLabelMapping;

  public displayedColumns = [
    'name',
    'status',
    'training_date',
    'credit_hours',
    'training_type',
    'attendance_type',
    'address',
    'notes',
    'actions',
  ];

  public user: User;
  public currentUser: User;

  public entities: Training[];
  public entitiesCount: number;

  public isLoading = true;
  public customerHIRLSettings = CustomerHIRLSettings;
  public initialized = false;

  public defaultParams: TrainingRequestParams = new TrainingRequestParams(
    1,
    '',
    '-id',
    25
  );
  public storedParams: TrainingRequestParams = Object.assign(
    new TrainingRequestParams(),
    this.defaultParams
  );

  public filterFormGroup: FormGroup;
  public overwritePropertyValues = {};
  public overwritePropertyLabels = {};
  public excludedProperties: string[];

  public createPermissionResponse: Observable<ObjectPermissionResponse>;
  public permissions: {
    [key: string]: {
      canEdit: Observable<ObjectPermissionResponse>;
      canDelete: Observable<ObjectPermissionResponse>;
      canDownloadCertificate: Observable<ObjectPermissionResponse>;
    };
  } = {};

  private componentDestroyed$ = new Subject();

  constructor(
    public store: Store<AppState>,
    public trainingService: TrainingService,
    public router: Router,
    public dialog: MatDialog,
    public trainingPermissionService: TrainingPermissionService,
    public userService: UserService,
    public activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    public uiHelperService: UIHelperService
  ) {}

  ngOnInit() {
    this.store
      .select(getUser)
      .pipe(
        filter(val => val !== null),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((entity: User) => {
        this.user = entity;
        this.createPermissionResponse =
          this.trainingPermissionService.canCreate();
        this.list();
      });

    this.store
      .select(getUserTrainings)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((trainings: Training[]) => {
        this.entities = trainings;
      });

    this.store
      .select(getUserTrainingsCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((count: number) => {
        this.entitiesCount = count;
      });

    this.setupFilterForm();
    this.list();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  setupFilterForm() {
    this.filterFormGroup = this.fb.group({
      search: [null],
    });

    this.filterFormGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        const params = Object.assign(this.defaultParams, values);

        this.storedParams.assignFilterFormValues({
          formValues: params,
          excludedProperties: ['ordering'],
        });

        this.list();
      });
  }

  list() {
    this.isLoading = true;
    this.userService
      .trainings(this.user.id, this.storedParams)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data: ServerResponse<Training>) => {
        this.entities = data.results;
        this.entitiesCount = data.count;
        this.entities.forEach((row: Training) => {
          this.permissions[row.id] = {
            canEdit: this.trainingPermissionService.canEdit(row),
            canDelete: this.trainingPermissionService.canDelete(row),
            canDownloadCertificate:
              this.trainingPermissionService.canDownloadCertificate(row),
          };
        });
        this.isLoading = false;
      });
  }

  onSortChange($event: Sort) {
    this.storedParams.ordering = `${$event.direction === 'desc' ? '-' : ''}${
      $event.active
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  onPaginateChange($event: PageEvent) {
    this.uiHelperService.getUISettings().rowsPerPage = $event.pageSize;
    this.storedParams.page_size = $event.pageSize;
    this.storedParams.page = $event.pageIndex + 1;
    this.list();
  }

  create($event: MouseEvent) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(TrainingChangeDialogComponent, {
      width: '55%',
      disableClose: true,
      autoFocus: false,
      data: {
        entity: {
          trainee: this.user.id,
        },
      } as TrainingChangeDialogData,
    });
    dialogRef.afterClosed().subscribe((result?: Training) => {
      if (!result) return;
      this.list();
      this.uiHelperService.openSnackBar(`Training successfully created`);
    });
  }

  edit($event: MouseEvent, training: Training) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(TrainingChangeDialogComponent, {
      width: '55%',
      disableClose: true,
      autoFocus: false,
      data: {
        entity: training.id,
      } as TrainingChangeDialogData,
    });
    dialogRef.afterClosed().subscribe((result?: Training) => {
      if (!result) return;
      this.list();
      this.uiHelperService.openSnackBar(`Training successfully updated`);
    });
  }

  delete($event: MouseEvent, training: Training) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '45%',
      data: {
        title: 'Delete Training ?',
        content: `Do you want to delete Training ?`,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) return;
      this.store.dispatch(toggleLoading({ payload: true }));
      this.trainingService
        .delete(training.id)
        .pipe(first())
        .subscribe({
          next: () => {
            this.list();
            this.store.dispatch(toggleLoading({ payload: false }));
            this.uiHelperService.openSnackBar(`Training successfully deleted`);
          },
          error: (error: HttpErrorResponse) =>
            this.uiHelperService.handleUserRequestError(error),
        });
    });
  }

  downloadCertificate($event: MouseEvent, training: Training) {
    const link = document.createElement('a');
    link.href = training.certificate;
    link.download = `certificate_${training.id}.pdf`;
    link.click();
  }

  protected readonly TrainingStatusStatusLabelMapping =
    TrainingStatusStatusLabelMapping;
}
