import { Component, Input, OnInit } from '@angular/core';
import { Home, HomeInfo } from '@/data/home/models';

export enum HomeDisplayComponentType {
  address = 'address',
  addressAsLink = 'addressAsLink',
}

@Component({
  selector: 'app-home-display',
  templateUrl: './home-display.component.html',
  styleUrls: ['./home-display.component.scss'],
})
export class HomeDisplayComponent implements OnInit {
  @Input() home: Home | HomeInfo;
  @Input() displayType:
    | HomeDisplayComponentType
    | keyof typeof HomeDisplayComponentType = HomeDisplayComponentType.address;

  public HomeDisplayComponentType = HomeDisplayComponentType;

  ngOnInit(): void {}
}
