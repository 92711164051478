<form [formGroup]="formGroup" class="card">
  <h5 class="card-header">
    Filters
    <a href="#" matTooltip="Preview applied filters" (click)="showAppliedFiltersDialog($event);">
      <fa-icon [icon]="['fas', 'filter']"></fa-icon>
    </a>
    <a href="#" class="small float-right" (click)="resetFilters($event);">Reset filters</a>
  </h5>
  <div class="card-body filter-card-body">
    <ng-content></ng-content>
  </div>
</form>
