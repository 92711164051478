import { Component, Input, OnInit } from '@angular/core';

export enum AdvancedLinkTarget {
  blank = '_blank',
}

@Component({
  selector: 'app-advanced-link',
  templateUrl: './advanced-link.component.html',
  styleUrls: ['./advanced-link.component.scss'],
})
export class AdvancedLinkComponent implements OnInit {
  @Input() internalLink: any[] | string | null | undefined;
  @Input() tooltipText = '';
  @Input() tooltipTextNewWindow = 'Open in New Window';
  @Input() target: AdvancedLinkTarget = AdvancedLinkTarget.blank;

  ngOnInit(): void {}
}
