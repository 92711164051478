<h2 mat-dialog-title>
  <ng-container *ngIf="!isEditForm">
    Create New <b>Image</b>
  </ng-container>
  <ng-container *ngIf="isEditForm">
    Edit <b>Image</b>
  </ng-container>
</h2>
<mat-dialog-content *ngIf="initialized">
  <form [formGroup]="form" class="mb-2">
    <div class="row">
      <div class="col-12">
        <mat-form-field class="mb-2 w-100">
          <mat-label>Image Type</mat-label>
          <mat-select aria-label="Image Type" formControlName="image_type">
            <mat-option *ngFor="let item of ProductImageTypeLabelMapping | keyvalue"
                        [value]="item.key">{{item.value}}</mat-option>
          </mat-select>
          <mat-error
            *ngIf="form.get('image_type').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row" *ngIf="form.get('image_type').value === ProductImageType.thumbnail">
      <div class="col-12">
        <div class="alert alert-warning">
          <p>
            <b>Product Gallery Image Specs:</b>
          </p>
          <p>
            <b>Requirements:</b> Limited to 1 image.
          </p>
          <p>
            <b>Content/Subject:</b> From an aesthetic standpoint, images where the product is isolated on a white background is ideal, but we understand that this may not always be possible.
          </p>
          <p>
            <b>Dimensions:</b> 280px x 280px (height can be shorter if needed)
          </p>
          <p>
            <b>Format:</b> All images should be saved as a JPG at a resolution of 72dpi. Color format must be RGB (not CMYK).
          </p>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="form.get('image_type').value === ProductImageType.main">
      <div class="col-12">
        <div class="alert alert-warning">
          <p>
            <b>Product Detail Image Specs:</b>
          </p>
          <p>
            <b>Requirements:</b> Up to 5 images allowed.
          </p>
          <p>
            <b>Content/Subject:</b> This image should show the product in context within a home or residential setting; it does not have to be an isolated image like the Gallery image.
          </p>
          <p>
            <b>Dimensions:</b> 280px x 280px (height can be shorter if needed)
          </p>
          <p>
            <b>Format:</b> All images should be saved as a JPG at a resolution of 72dpi. Color format must be RGB (not CYMK).
          </p>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="form.get('image_type').value">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Document</mat-label>
          <app-file-input formControlName="document" maxFileSize="3000000" accept="image/jpeg,image/jpg,image/png"></app-file-input>
          <mat-error
            *ngIf="form.get('document').hasError('required')">
            This field is required
          </mat-error>
          <mat-hint *ngIf="entity?.customer_document_info?.document">
            Current file: <app-customer-document-display [customerDocument]="entity?.customer_document_info" displayType="filenameAsLinkAndSharedLink"></app-customer-document-display>
          </mat-hint>
        </mat-form-field>
      </div>
    </div>

    <div class="row" *ngIf="form.get('image_type').value">
      <div class="col-12">
        <mat-form-field class="mb-2 w-100">
          <mat-label>Order</mat-label>
          <input matInput type="number" placeholder="Order"
                 formControlName="order">
          <mat-error
            *ngIf="form.get('order').hasError('required')">
            This field is required
          </mat-error>
          <mat-error *ngIf="form.get('order').hasError('min')">
            Value must be greater than 0
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button color="accent" mat-raised-button (click)="save($event)">Save</button>
  <button mat-button [mat-dialog-close]="null" color="primary" mat-raised-button>Cancel</button>
</mat-dialog-actions>
