import { AuthGuard } from '@/core/guards/auth.guard';
import { UserDetailPageComponent } from '@/modules/user/pages/user-detail-page/user-detail-page.component';
import { MailingListPageComponent } from '@/modules/user/pages/user-list-page/user-list-page.component';
import { ContactCardListComponent } from '@/modules/user/pages/contact-card-list/contact-card-list.component';

export const UserRouting = [
  {
    path: 'users',
    canActivate: [AuthGuard],
    children: [
      {
        path: ':userId',
        component: UserDetailPageComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'hi',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'mailing_list',
        children: [
          {
            path: '',
            component: MailingListPageComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
    ],
  },
  {
    path: 'contact_cards',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ContactCardListComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
];
