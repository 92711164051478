import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { environment } from '../../../environments/environment';

const LEGACY_REDIRECT_URL_MAP = {
  '/message-center/unread': '/messaging',
  '/message-center/read': '/messaging',
};

@Injectable({
  providedIn: 'root',
})
export class LegacyRedirectGuard implements CanActivate {
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (environment.APP_INSIDE_DJANGO) {
      window.location.href = `${environment.AXIS_LEGACY_ROOT}${
        LEGACY_REDIRECT_URL_MAP[state.url] || state.url
      }`;
    } else {
      console.log(
        `Redirecting to Legacy url(${state.url}) disabled with APP_NOT_INSIDE_DJANGO=false configuration`
      );
    }
    return false;
  }
}
