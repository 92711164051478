import { FuelTypeLabels } from '@/data/simulation/enumerations';
import { FuelUsage } from '@/data/simulation/models/reports/Analysis';
import { Component, Input } from '@angular/core';

enum COLUMN_LABELS {
  fuel = 'Fuel',
  cost = 'Cost',
  heating_consumption = 'Heating Consumption',
  cooling_consumption = 'Cooling Consumption',
  water_heating_consumption = 'Water Heating Consumption',
  lighting_and_appliances_consumption = 'Lighting and Appliances Consumption',
  total_consumption = 'Total Consumption',
}

@Component({
  selector: 'app-fuel-usage',
  templateUrl: './fuel-usage.component.html',
  styleUrls: ['./fuel-usage.component.scss'],
})
export class FuelUsageComponent {
  @Input() fuelUsages: FuelUsage[] = [];

  displayedColumns = Object.keys(COLUMN_LABELS);
  columnLabels = COLUMN_LABELS;
  fuelTypeLabel = FuelTypeLabels;

  ngOnInit(): void {}
}
