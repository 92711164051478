import { createSelector } from '@ngrx/store';
import { SimulationState } from '../reducers';
import { selectSimulationFeature } from '../simulation.selectors';
import { RoofBackendDict } from '@/data/simulation/models/enclosure/Roof';

export const selectRoof = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.roof.entities[id]
  );
const selectRoofLoadingStatus = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.roof.loading[id]
  );

export const selectRoofTypeId = (id: number) =>
  createSelector(selectRoof(id), roof => roof.type);

export const selectRoofViewSet = (id: number) =>
  createSelector(
    selectRoof(id),
    selectRoofLoadingStatus(id),
    (roof, loadingStatus) => ({
      data: roof,
      loading: loadingStatus,
    })
  );

export const selectRoofName = (id: number) =>
  createSelector(selectRoof(id), (roof: RoofBackendDict) => roof.name);

export const selectRoofChildModels = (id: number) =>
  createSelector(selectSimulationFeature, state => {
    const roof = state.roof.entities[id];
    return {
      roofType: roof.type,
    };
  });

export const selectRoofNestedErrorCount = (id: number) =>
  createSelector(
    selectSimulationFeature,
    selectRoofChildModels(id),
    (state, childModels) => {
      const roofTypeErrors = {
        errorCount: 0,
        warningCount: 0,
      };
      if (childModels.roofType !== null) {
        roofTypeErrors.errorCount =
          state.roofType.errors[childModels.roofType].errorCount;
        roofTypeErrors.warningCount =
          state.roofType.errors[childModels.roofType].warningCount;
      }
      return {
        roof: {
          errorCount: state.roof.errors[id].errorCount,
          warningCount: state.roof.errors[id].warningCount,
        },
        roofType: roofTypeErrors,
      };
    }
  );

export const selectRoofErrorViewSet = (id: number) =>
  createSelector(
    selectRoofNestedErrorCount(id),
    selectRoofTypeId(id),
    selectRoofName(id),
    (nestedModelsErrorCount, roofTypeId, name) => {
      return {
        nestedModelsErrorCount,
        roofTypeId,
        name,
      };
    }
  );
