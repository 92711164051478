import { AnalysisSuiteService } from '@/data/simulation/services/analysis-suite.service';
import { SimulationConfigService } from '@/data/simulation/services/simulation-config.service';
import { updateSimulationConfig } from '@/modules/simulation/state/simulation-config/actions';
import { selectSimulationConfigViewSet } from '@/modules/simulation/state/simulation-config/selectors';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-analysis-type-config',
  templateUrl: './analysis-type-config.component.html',
  styleUrls: ['./analysis-type-config.component.scss'],
})
export class AnalysisTypeConfigComponent implements OnInit {
  @Input() configId: number;
  @Input() indicateDefaults = true;

  componentDestroyed$ = new Subject();
  loading = true;
  selectorReady = false;
  saveAllowed = false;
  weatherTypeControl: FormControl = new FormControl();
  analysisSuitesControl: FormControl = new FormControl([]);
  originalSuitesValue = [];
  originalWeatherTypeValue = '';
  usingLatestVersion: boolean;

  constructor(
    public simulationConfigService: SimulationConfigService,
    public analysisSuiteService: AnalysisSuiteService,
    public store: Store
  ) {}

  ngOnInit(): void {
    this.analysisSuitesControl.valueChanges.subscribe(value => {
      if (value != this.originalSuitesValue) this.saveAllowed = true;
    });

    this.weatherTypeControl.valueChanges.subscribe(value => {
      if (this.originalWeatherTypeValue !== value) this.saveAllowed = true;
    });

    this.store
      .select(selectSimulationConfigViewSet())
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        simulationConfigViewSet => {
          this.loading = simulationConfigViewSet.loading;
          if (this.loading) return;

          this.originalSuitesValue =
            simulationConfigViewSet.data.analysis_types.map(item => item.suite);
          this.originalWeatherTypeValue =
            simulationConfigViewSet.data.weather_type;
          this.saveAllowed = false;
          this.analysisSuitesControl.patchValue(this.originalSuitesValue);
          this.weatherTypeControl.patchValue(this.originalWeatherTypeValue);
        },
        error => {
          // Handle any errors from either of the requests
          console.error('Error in one of the requests:', error);
          this.loading = false;
        }
      );
  }

  saveAnalysisType() {
    const analysisType = [];
    for (const value of this.analysisSuitesControl.value) {
      if (value) {
        analysisType.push({ suite: value });
      }
    }
    this.store.dispatch(
      updateSimulationConfig({
        id: this.configId,
        simulationConfigChanges: {
          analysis_types: analysisType,
          weather_type: this.weatherTypeControl.value,
        },
      })
    );
  }

  onReady($event: any) {
    this.selectorReady = true;
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
