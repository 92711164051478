import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { first, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getInfoUser } from '@/state/selectors/info.selector';
import { AppState } from '@/state/reducers';
import { ProductPracticeItemService } from '@/data/customer-hirl/services/product-practice-item.service';
import { ProductPracticeItem } from '@/data/customer-hirl/models';
import { User } from '@/data/core/models/user';
import { Product, ProductStatus } from '@/data/product/models';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';
import { ProductService } from '@/data/product/services/product.service';

@Injectable({
  providedIn: 'root',
})
export class ProductPracticeItemPermissionService {
  constructor(
    private store: Store<AppState>,
    private productService: ProductService,
    private productPracticeItemService: ProductPracticeItemService
  ) {}

  canCreate(product: Product): Observable<ObjectPermissionResponse> {
    let product$: Observable<ProductPracticeItem>;
    if (typeof product === 'number') {
      product$ = this.productService.retrieve(product, {
        ignoreStore: true,
      });
    } else {
      product$ = of(product);
    }

    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      product: product$,
    }).pipe(
      map(
        ({ currentUser, product }: { currentUser: User; product: Product }) => {
          if (currentUser.is_superuser) {
            return new ObjectPermissionResponse(true);
          }
          if (currentUser.is_superuser) {
            return new ObjectPermissionResponse(true);
          }
          if (
            currentUser.company_info?.slug === CustomerHIRLSettings.companySlug
          ) {
            return new ObjectPermissionResponse(true);
          }
          if (product.status != ProductStatus.draft) {
            return new ObjectPermissionResponse(false);
          }
          return new ObjectPermissionResponse(true);
        }
      )
    );
  }

  canEdit(
    productPracticeItem: ProductPracticeItem | number
  ): Observable<ObjectPermissionResponse> {
    let productGroupPracticeItem$: Observable<ProductPracticeItem>;
    if (typeof productPracticeItem === 'number') {
      productGroupPracticeItem$ = this.productPracticeItemService.retrieve(
        productPracticeItem,
        {
          ignoreStore: true,
        }
      );
    } else {
      productGroupPracticeItem$ = of(productPracticeItem);
    }
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: productGroupPracticeItem$,
    }).pipe(
      map(
        ({
          currentUser,
          entity,
        }: {
          currentUser: User;
          entity: ProductPracticeItem;
        }) => {
          if (currentUser.is_superuser) {
            return new ObjectPermissionResponse(true);
          }
          if (currentUser.is_superuser) {
            return new ObjectPermissionResponse(true);
          }
          if (
            currentUser.company_info?.slug === CustomerHIRLSettings.companySlug
          ) {
            return new ObjectPermissionResponse(true);
          }
          if (entity.product_info?.status != ProductStatus.draft) {
            return new ObjectPermissionResponse(false);
          }
          return new ObjectPermissionResponse(true);
        }
      )
    );
  }

  canDelete(
    productPracticeItem: ProductPracticeItem | number
  ): Observable<ObjectPermissionResponse> {
    return this.canEdit(productPracticeItem);
  }
}
