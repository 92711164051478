import { SkylightTypeBackendDict } from '@/data/simulation/models/enclosure/SkylightType';
import { createAction, props } from '@ngrx/store';
import { StateErrors } from '../shared/base.state';

export const loadSkylightTypes = createAction(
  '[SkylightType] Load Foundation Skylight Types',
  props<{ skylightTypes: SkylightTypeBackendDict[] }>()
);

export const loadSkylightTypesSuccess = createAction(
  '[SkylightType] Load SkylightTypes Success',
  props<{
    skylightTypes: SkylightTypeBackendDict[];
    errors: StateErrors<SkylightTypeBackendDict>;
  }>()
);

export const addSkylightType = createAction(
  '[SkylightType UI] Add SkylightType',
  props<{ skylightId: number; skylightType: SkylightTypeBackendDict }>()
);

export const removeSkylightType = createAction(
  '[Skylight UI] Remove SkylightType',
  props<{ skylightId: number; skylightTypeId: number }>()
);

export const removeSkylightTypeSuccess = createAction(
  '[SkylightType API] Remove SkylightType Success',
  props<{ id: number }>()
);

export const updateSkylightType = createAction(
  '[SkylightType UI] Update SkylightType',
  props<{ skylightTypeChanges: Partial<SkylightTypeBackendDict> }>()
);

export const updateSkylightTypeFailure = createAction(
  '[SkylightType API] Update SkylightType Failure',
  props<{ id: number }>()
);
