import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectDuctViewSet } from '@/modules/simulation/state/duct/selectors';
import {
  DuctBackendDict,
  DuctForm,
  DuctLocationLabels,
  DuctTypeLabels,
} from '@/data/simulation/models/mechanicals/Duct';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { updateDuct } from '@/modules/simulation/state/duct/actions';

@Component({
  selector: 'app-duct',
  templateUrl: './duct.component.html',
  styleUrls: ['./duct.component.scss'],
})
export class DuctComponent {
  @Input() entityId: number;
  @Input() showLabels: boolean;

  componentDestroyed$ = new Subject();
  ductTypeLabels = DuctTypeLabels;
  ductLocationLabelMapping = DuctLocationLabels;

  entity: DuctBackendDict;
  loading: boolean;
  ductForm: DuctForm;

  constructor(public store: Store) {}

  ngOnInit() {
    this.store
      .select(selectDuctViewSet(this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(({ data, loading }) => {
        this.loading = loading;
        this.entity = data;

        if (loading) return;

        if (this.ductForm) {
          this.ductForm.patchValue(data);
        } else {
          this.ductForm = new DuctForm(data);
        }
      });
  }

  saveDuct(ductChangedValues: Partial<DuctBackendDict>) {
    this.store.dispatch(
      updateDuct({
        ductChanges: ductChangedValues,
      })
    );
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
