import { DehumidifierBackendDict } from '@/data/simulation/models/mechanicals/Dehumidifier';
import { createAction, props } from '@ngrx/store';
import { StateErrors } from '../shared/base.state';

export const loadDehumidifiers = createAction(
  '[Dehumidifier] Load Dehumidifiers',
  props<{ dehumidifiers: DehumidifierBackendDict[] }>()
);

export const loadDehumidifiersSuccess = createAction(
  '[Dehumidifier] Load Dehumidifiers Success',
  props<{
    dehumidifiers: DehumidifierBackendDict[];
    errors: StateErrors<DehumidifierBackendDict>;
  }>()
);

export const loadDehumidifiersFailure = createAction(
  '[Dehumidifier] Load Dehumidifiers Failure',
  props<{ error: any }>()
);

export const updateDehumidifier = createAction(
  '[Dehumidifier] Update Dehumidifier',
  props<{ dehumidifierChanges: Partial<DehumidifierBackendDict> }>()
);

export const updateDehumidifierFailure = createAction(
  '[Dehumidifier API] Update Dehumidifier Failure',
  props<{ id: number }>()
);
