import {
  AboveGradeWallBackendDict,
  DetailedAboveGradeWallBackendDict,
} from '@/data/simulation/models/enclosure/AboveGradeWall';
import { createAction, props } from '@ngrx/store';
import { ModelErrors, StateErrors } from '../shared/base.state';

export const loadDetailedAboveGradeWalls = createAction(
  '[Simulation API] Load Detailed AboveGradeWalls',
  props<{ detailedAboveGradeWalls: DetailedAboveGradeWallBackendDict[] }>()
);

export const loadAboveGradeWallsSuccess = createAction(
  '[AboveGradeWall API] Load AboveGradeWalls Success',
  props<{
    aboveGradeWalls: AboveGradeWallBackendDict[];
    errors: StateErrors<AboveGradeWallBackendDict>;
  }>()
);

export const updateAboveGradeWall = createAction(
  '[AboveGradeWall UI] Update AboveGradeWall',
  props<{ aboveGradeWallChanges: Partial<AboveGradeWallBackendDict> }>()
);

export const updateAboveGradeWallSuccess = createAction(
  '[AboveGradeWall API] Update AboveGradeWall Success',
  props<{
    aboveGradeWall: AboveGradeWallBackendDict;
    errors: ModelErrors<AboveGradeWallBackendDict>;
  }>()
);

export const updateAboveGradeWallFailure = createAction(
  '[AboveGradeWall API] Update AboveGradeWall Failure',
  props<{ id: number }>()
);

export const removeAboveGradeWall = createAction(
  '[AboveGradeWall UI] Remove AboveGradeWall',
  props<{ aboveGradeWall: AboveGradeWallBackendDict }>()
);

export const removeAboveGradeWallSuccess = createAction(
  '[AboveGradeWall API] Remove AboveGradeWall Success',
  props<{ id: number }>()
);

export const removeAboveGradeWallFailure = createAction(
  '[AboveGradeWall API] Remove AboveGradeWall Failure',
  props<{ id: number }>()
);

export const updateAboveGradeWallErrors = createAction(
  '[AboveGradeWall UI] Update AboveGradeWall Errors',
  props<{ id: number; errors: any }>()
);

export const setAboveGradeWallType = createAction(
  '[AboveGradeWallType API] Set AboveGradeWallType',
  props<{ wallId: number; wallTypeId: number | null }>()
);
