import { Component, Input } from '@angular/core';
import { BaseAppControlValueAccessor } from '../base-control-value-accessor.directive';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
})
export class DateComponent extends BaseAppControlValueAccessor {
  @Input() label = '';
  @Input() placeholder = '';
  @Input() presentErrorsInline = true;
}
