import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BaseDataService } from '@/core/services/base-data-service';
import { Observable } from 'rxjs';
import { ServerResponse } from '@/core/schemes/server-response';
import {
  AnalysisBackendDict,
  AnalysisListItem,
} from '../models/reports/Analysis';

@Injectable({ providedIn: 'root' })
export class AnalysisService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/simulations`;

  list(
    simulationId: number,
    params = {}
  ): Observable<ServerResponse<AnalysisListItem>> {
    return this.http.get<ServerResponse<AnalysisListItem>>(
      `${this.baseUrl}/${simulationId}/analysis/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  get(analysisId: number): Observable<AnalysisBackendDict> {
    return this.http.get<AnalysisBackendDict>(
      `${environment.AXIS_API_V3_ROOT}/analysis/${analysisId}/`
    );
  }

  downloadFile(
    simulationId: number,
    analysisId: number,
    fileType: string
  ): Observable<any> {
    return this.http.get(
      `${this.baseUrl}/${simulationId}/analysis/${analysisId}/${fileType}/`
    );
  }
}
