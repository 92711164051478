import { BaseDataService } from '@/core/services/base-data-service';
import { ListRequestParams } from '@/core/schemes/request-params';
import { Injectable } from '@angular/core';
import { ServerResponse } from '@/core/schemes/server-response';
import { FastTrackSubmission } from '@/data/customer-eto/models';
import { environment } from '../../../../environments/environment';

export class ProjectTrackerRequestParams extends ListRequestParams {}

@Injectable({ providedIn: 'root' })
export class ProjectTrackerService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/project-tracker-submission/`;

  list(params?: ProjectTrackerRequestParams) {
    return this.http.get<ServerResponse<FastTrackSubmission>>(
      `${this.baseUrl}`,
      {
        params: this.rollParams(params),
      }
    );
  }
}
