import {
  ApplianceCostRateUnitLabels,
  AppliancesBackendDict,
  ClothesWasherPresetLabels,
  ClothesWasherPresets,
  CostUnitLabels,
  createClothesWasherForm,
  DEFAULT_WASHER_VALUES,
  FIELD_LABELS,
  WasherCapacityUnitLabels,
  WasherEfficiencyLabels,
} from '@/data/simulation/models/Appliances';
import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseAppliancesDirective } from '../base-appliances.directive';
import { AuxEnergyUnitLabels } from '@/data/simulation/enumerations';

@Component({
  selector: 'app-clothes-washer',
  templateUrl: './clothes-washer.component.html',
  styleUrls: ['./clothes-washer.component.scss'],
})
export class ClothesWasherComponent extends BaseAppliancesDirective {
  createForm: (data: AppliancesBackendDict) => FormGroup<any> =
    createClothesWasherForm;
  washerPresetsLabels = ClothesWasherPresetLabels;
  fieldLabels = FIELD_LABELS;
  applianceCostRateUnitLabels = ApplianceCostRateUnitLabels;
  costUnitLabels = CostUnitLabels;
  washerCapacityUnitLabels = WasherCapacityUnitLabels;
  washerEfficiencyLabels = WasherEfficiencyLabels;

  ngOnInit(): void {
    super.ngOnInit();

    if (!this.form) return;

    this.form.controls.clothes_washer_preset.valueChanges.subscribe(value => {
      if (DEFAULT_WASHER_VALUES.hasOwnProperty(value)) {
        this.form.patchValue(DEFAULT_WASHER_VALUES[value]);
      }

      if (value !== ClothesWasherPresets.LABEL) {
        this.form.controls.clothes_washer_energy_star_label_electric_rate.disable();
        this.form.controls.clothes_washer_label_electric_consumption.disable();
        this.form.controls.clothes_washer_energy_star_label_gas_rate.disable();
        this.form.controls.clothes_washer_energy_star_label_gas_annual_cost.disable();
      } else {
        this.form.controls.clothes_washer_energy_star_label_electric_rate.enable();
        this.form.controls.clothes_washer_label_electric_consumption.enable();
        this.form.controls.clothes_washer_energy_star_label_gas_rate.enable();
        this.form.controls.clothes_washer_energy_star_label_gas_annual_cost.enable();
      }
    });
  }

  protected readonly auxEnergyUnitLabels = AuxEnergyUnitLabels;
}
