import { Component, Input, OnInit } from '@angular/core';
import { User, UserInfo } from '@/data/core/models/user';

export enum UserDisplayComponentType {
  onlyName = 'onlyName',
  onlyNameAsLink = 'onlyNameAsLink',
}

@Component({
  selector: 'app-user-display',
  templateUrl: './user-display.component.html',
  styleUrls: ['./user-display.component.scss'],
})
export class UserDisplayComponent implements OnInit {
  @Input() displayType:
    | UserDisplayComponentType
    | keyof typeof UserDisplayComponentType = UserDisplayComponentType.onlyName;
  @Input() user?: User | UserInfo;

  userDisplayComponentType = UserDisplayComponentType;

  ngOnInit(): void {}
}
