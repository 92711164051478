import {
  DoorTypeBackendDict,
  createDoorTypeForm,
} from '@/data/simulation/models/enclosure/DoorType';
import { BaseValidator } from './base.validator';
import { ModelErrors, StateErrors } from '../state/shared/base.state';

export class DoorTypeValidator extends BaseValidator {
  static validateForm(
    doortype: DoorTypeBackendDict
  ): ModelErrors<DoorTypeBackendDict> {
    const doortypeForm = createDoorTypeForm(doortype);
    const errors = {};
    Object.keys(doortype).forEach(key => {
      if (!doortypeForm.get(key)) return;
      errors[key] = doortypeForm.get(key).errors;
    });
    return {
      field: errors,
      model: doortypeForm.errors || {},
    };
  }

  static validate(
    doortypes: DoorTypeBackendDict[]
  ): StateErrors<DoorTypeBackendDict> {
    const errors = {};
    doortypes.forEach(doortype => {
      errors[doortype.id] = this.validateForm(doortype);
    });
    return errors;
  }
}
