import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { COIDocumentService } from '@/data/customer-hirl/services/coi-documents.service';
import {
  COIDocument,
  COIDocumentInsuranceTypeMapping,
} from '@/data/customer-hirl/models';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { forkJoin, of, Subject } from 'rxjs';
import { User } from '@/data/core/models/user';
import { Store } from '@ngrx/store';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, switchMap, takeUntil } from 'rxjs/operators';
import { toggleLoading } from '@/state/actions/app.actions';
import { AppState } from '@/state/reducers';
import { CompanyService } from '@/data/company/services/company-base.service';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';
import * as moment from 'moment/moment';

export class COIDocumentChangeDialogData {
  coiDocument?: COIDocument;
  commit = true;
  retrieve = true;
}

@Component({
  selector: 'app-coi-document-change-dialog',
  templateUrl: './coi-document-change-dialog.component.html',
  styleUrls: ['./coi-document-change-dialog.component.scss'],
})
export class CoiDocumentChangeDialogComponent implements OnInit, OnDestroy {
  protected readonly COIDocumentInsuranceTypeMapping =
    COIDocumentInsuranceTypeMapping;
  public initialized: boolean;
  public isEditForm = false;
  public entity?: COIDocument;
  public commit = true;
  public retrieve = true;
  public form: FormGroup;
  public currentUser: User;

  public customerHIRLSettings = CustomerHIRLSettings;

  public get showInsuranceTypeField(): boolean {
    return (
      this.currentUser?.is_superuser ||
      this.currentUser?.company_info.slug === CustomerHIRLSettings.companySlug
    );
  }

  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<CoiDocumentChangeDialogComponent>,
    private fb: FormBuilder,
    private companyService: CompanyService,
    private coiDocumentService: COIDocumentService,
    private dialog: MatDialog,
    private uiHelperService: UIHelperService,
    @Inject(MAT_DIALOG_DATA) public data: COIDocumentChangeDialogData
  ) {
    if (data?.coiDocument) {
      this.entity = Object.assign({}, data?.coiDocument);
    }
    this.commit = data?.commit || true;

    if (this.entity?.id) {
      this.isEditForm = true;
    }
  }

  ngOnInit() {
    const sources = {
      currentUser: this.store.select(getInfoUser).pipe(first()),
      customerDocument: of(this.entity),
    };

    this.store.dispatch(toggleLoading({ payload: true }));

    if (this.isEditForm && this.retrieve) {
      sources.customerDocument = this.coiDocumentService
        .retrieve(this.entity.id, { ignoreStore: true })
        .pipe(takeUntil(this.componentDestroyed$), first());
    }

    forkJoin(sources)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: ({ currentUser, customerDocument }) => {
          this.store.dispatch(toggleLoading({ payload: false }));

          this.currentUser = currentUser;
          this.entity = customerDocument;
          this.setupForm();
          this.initialized = true;
          if (this.isEditForm) {
            this.hydrateForm();
          }
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  setupForm() {
    this.form = this.fb.group({
      document: [null, Validators.required],
      insurance_type: [null],
      description: [''],
      policy_number: [''],
      start_date: [null],
      expiration_date: [null],
    });
  }

  hydrateForm() {
    this.form.patchValue(Object.assign({}, this.entity), {
      emitEvent: false,
      onlySelf: true,
    });
    this.form.patchValue(
      {
        start_date: this.form.value.start_date
          ? moment(this.form.value.start_date, 'YYYY-MM-DD')
          : null,
        expiration_date: this.form.value.expiration_date
          ? moment(this.form.value.expiration_date, 'YYYY-MM-DD')
          : null,
      },
      {
        emitEvent: false,
        onlySelf: true,
      }
    );
  }

  save($event: MouseEvent) {
    $event.preventDefault();
    if (this.isEditForm) {
      this.edit();
    } else {
      this.create();
    }
  }

  getFormData(): COIDocument {
    const data = new COIDocument();
    data.description = this.form.value.description;
    data.insurance_type = this.form.value.insurance_type;
    data.policy_number = this.form.value.policy_number;

    data.start_date = this.form.value.start_date
      ? this.form.value.start_date.format('YYYY-MM-DD')
      : null;

    data.expiration_date = this.form.value.expiration_date
      ? this.form.value.expiration_date.format('YYYY-MM-DD')
      : null;

    return data;
  }

  private edit() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    if (!this.commit) {
      this.dialogRef.close(this.entity);
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const data = this.getFormData();

    this.coiDocumentService
      .update(data, this.entity.id)
      .pipe(
        first(),
        // update file fields
        switchMap(coiDocument => {
          if (
            this.currentUser?.company_info?.slug ===
              this.customerHIRLSettings.companySlug ||
            this.currentUser.is_superuser ||
            !this.isEditForm
          ) {
            const formData = new FormData();
            formData.append('document', this.form.value.document);
            return this.coiDocumentService.update(formData, coiDocument.id);
          } else {
            return of(coiDocument);
          }
        })
      )
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: coiDocument => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close(coiDocument);
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  private create() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    if (!this.commit) {
      this.dialogRef.close(this.entity);
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const data = this.getFormData();

    this.companyService
      .coi_documentsCreate(this.entity.company, data)
      .pipe(
        first(),
        // update file fields
        switchMap(coi => {
          const formData = new FormData();
          formData.append('document', this.form.value.document);
          return this.coiDocumentService.update(formData, coi.id);
        })
      )
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: customerDocument => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close(customerDocument);
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }
}
