import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BaseDataService } from '@/core/services/base-data-service';
import { Observable } from 'rxjs';
import { LightsBackendDict } from '../models/lights/Lights';

@Injectable({ providedIn: 'root' })
export class LightsService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/lights/`;

  update(
    lightsTypeChanges: Partial<LightsBackendDict>
  ): Observable<LightsBackendDict> {
    return this.http.patch<LightsBackendDict>(
      `${this.baseUrl}${lightsTypeChanges.id}/`,
      lightsTypeChanges
    );
  }

  delete(id: number) {
    return this.http.delete(`${this.baseUrl}${id}/`);
  }
}
