<div class="container-fluid" *ngIf="initialized">
  <div class="row">
    <div class="col-12">
      <div class="row mb-2">
        <div class="col-6">
          <h3>
            <span *ngIf="!entitiesIsLoading">{{ entitiesCount | number }}</span>
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" *ngIf="entitiesIsLoading"></fa-icon>
            NGBS Certificate Download
          </h3>
        </div>
        <div class="col-6 text-right">
          <ng-container>
            <ng-container *ngIf="!this.selectedEntities?.length || entitiesIsLoading">
              <button mat-raised-button color="accent" class="mr-2" [disabled]="true" matTooltip="Select at least one Project">
                Download Certificate
              </button>
            </ng-container>
            <ng-container *ngIf="this.selectedEntities?.length">
              <button mat-raised-button color="accent" (click)="certificateDownload($event)" class="mr-2">
                Download Certificate
              </button>
            </ng-container>
          </ng-container>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <hr>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <div class="row">
            <div class="col-12">
              <form [formGroup]="filterFromGroup" class="card">
                <h5 class="card-header">
                  Filters
                  <a href="#" matTooltip="Preview applied filters" (click)="showAppliedFiltersDialog($event);">
                    <fa-icon [icon]="['fas', 'filter']"></fa-icon>
                  </a>
                  <a href="#" class="small float-right" (click)="resetFilters($event);">Reset filters</a>
                </h5>
                <div class="card-body filter-card-body">
                  <mat-accordion class="filter-accordion" multi>
                    <mat-expansion-panel [expanded]="true">
                      <div class="row">
                        <div class="col-12">
                          <mat-form-field class="w-100">
                            <mat-label>Search</mat-label>
                            <app-search-input formControlName="search"
                                              placeholder="Type to search something"></app-search-input>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row" *ngIf="!currentUser.is_superuser">
                        <div class="col-12">
                          <mat-form-field class="w-100">
                            <mat-label>Included Companies</mat-label>
                            <app-generic-model-chips formControlName="company_access_info" modelClassName="company_access" [panelWidth]="640" [initialValueIds]="storedParams.company_access" [filterParams]="{'user': currentUser.id}"></app-generic-model-chips>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <mat-form-field class="w-100" floatLabel="always">
                            <mat-label>Certification Date Start</mat-label>
                            <input matInput [matDatepicker]="certification_date__gte" formControlName="certification_date__gte" (click)="certification_date__gte.open()">
                            <mat-datepicker-toggle matSuffix (click)="filterFromGroup.get('certification_date__gte').setValue(null)">
                              <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker-toggle matSuffix [for]="certification_date__gte"></mat-datepicker-toggle>
                            <mat-datepicker #certification_date__gte></mat-datepicker>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <mat-form-field class="w-100" floatLabel="always">
                            <mat-label>Certification Date End</mat-label>
                            <input matInput [matDatepicker]="datepicker_certification_date__lte" formControlName="certification_date__lte" (click)="certification_date__gte.open()">
                            <mat-datepicker-toggle matSuffix (click)="filterFromGroup.get('certification_date__lte').setValue(null)">
                              <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker-toggle matSuffix [for]="datepicker_certification_date__lte"></mat-datepicker-toggle>
                            <mat-datepicker #datepicker_certification_date__lte></mat-datepicker>
                          </mat-form-field>
                        </div>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-9">
          <div class="row">
            <div class="col-12">
              <ng-container *ngIf="selectAllEntities">Selected <b>{{ entitiesCount }}</b> Projects</ng-container>
              <ng-container *ngIf="!selectAllEntities && selectedEntities?.length">Selected <b>{{ selectedEntities.length }}</b> Projects</ng-container>
            </div>
          </div>
          <div class="row" *ngIf="entitiesIsLoading">
            <div class="col-12">
              <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
            </div>
          </div>
          <table mat-table
                 [dataSource]="entities"
                 matSort
                 [matSortActive]="storedParams.toTableSort().active"
                 [matSortDirection]="storedParams.toTableSort().direction"
                 (matSortChange)="onSort($event)"
                 class="w-100"
          >

            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox matTooltip="Select All Projects" [(ngModel)]="selectAllEntities" (change)="onSelectAll()" [disabled]="entitiesIsLoading"></mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox (change)="onEntitySelect($event, row)"
                              [checked]="isEntityChecked(row)"
                              [disabled]="entitiesIsLoading"
                ></mat-checkbox>
              </td>
              <ng-container matColumnDef="customer_hirl_project__id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Project ID</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.customer_hirl_project }}
                </td>
              </ng-container>
              <ng-container matColumnDef="home__subdivision__name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>MF Development/ Subdivision</th>
                <td mat-cell *matCellDef="let row">
                  <app-subdivision-display [subdivision]="row?.subdivision_info" displayType="onlyNameAsLink"></app-subdivision-display>
                </td>
              </ng-container>
              <ng-container matColumnDef="home__street_line1">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Address</th>
                <td mat-cell *matCellDef="let row">
                  <app-home-status-display [home_status]="row" displayType="addressAsLinkNewWindow"></app-home-status-display>
                </td>
              </ng-container>
              <ng-container matColumnDef="eep_program__name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Program</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.eep_program_info?.name }}
                </td>
              </ng-container>
              <ng-container matColumnDef="certification_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Certification Date</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.certification_date | date: 'MM/dd/yyyy' }}
                </td>
              </ng-container>
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell *matCellDef="let row">
                  <a mat-icon-button color="accent" [routerLink]="['/', 'home', 'report', 'customer_hi', 'scoring_path', row.id]"
                     matTooltip="View Certificate" target="_blank">
                    <fa-icon [icon]="['fas', 'external-link-alt']"></fa-icon>
                  </a>
                  <app-copy-to-clipboard-button [value]="row.certification_link" matTooltip="Copy shareable link">
                    <fa-icon [icon]="['fas', 'link']"></fa-icon>
                  </app-copy-to-clipboard-button>
                </td>
              </ng-container>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <div class="d-flex flex-row">
            <div class="align-self-center">
            </div>
            <div class="d-flex flex-fill justify-content-end">
              <mat-paginator [length]="entitiesCount"
                             [pageIndex]="$any(storedParams.page) - 1"
                             [pageSize]="storedParams.page_size"
                             (page)="onPaginateChange($event)"
                             aria-label="Select page">
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
















