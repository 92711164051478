import { QARequirementType, QAState } from '@/data/qa/models';
import { HomeInfo } from '@/data/home/models/home';
import { CompanyInfo } from '@/data/company/models';

export class PSRReportListQAState {
  duration?: number;
  state?: QAState;
  requirement_type?: QARequirementType;
}

export class PSRReportList {
  id: number;
  company?: number;
  company_info?: CompanyInfo;
  home: number;
  home_info: HomeInfo;
  floorplan: number;
  floorplan_name: string;
  eep_program: number;
  eep_program_name: string;
  state: string;
  qa_states?: PSRReportListQAState[];
}
