<h2 mat-dialog-title>
  <ng-container *ngIf="!isEditForm">
    Create New <b>Task</b>
  </ng-container>
  <ng-container *ngIf="isEditForm">
    Edit <b>Task</b>
  </ng-container>
</h2>

<mat-dialog-content *ngIf="initialized">
  <form [formGroup]="form" class="mb-2">
    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Users</mat-label>
          <app-generic-model-chips modelClassName="user" formControlName="assignees_info" [initialValueIds]="entity?.assignees"></app-generic-model-chips>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Task Type</mat-label>
          <app-generic-mat-select modelClassName="scheduling_task_type" formControlName="task_type"></app-generic-mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100 mb-2">
          <mat-label>Date</mat-label>
          <input matInput type="text" placeholder="Date" formControlName="date"
                 [matDatepicker]="datePicker">
          <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
          <mat-datepicker #datePicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100 mb-2">
          <mat-label>Time</mat-label>
          <input
            matInput
            [ngxMatTimepicker]="timepicker"
            formControlName="time"
            format="24"
            readonly
          />
          <ngx-mat-timepicker
            #timepicker
            [enableKeyboardInput]="true"
          ></ngx-mat-timepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Completion Status</mat-label>
          <mat-select formControlName="status">
            <mat-option *ngFor="let item of TaskStatusLabelMapping | defaultOrderKeyValue" [value]="item.key">
              {{ item.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Note</mat-label>
          <textarea matInput type="text" placeholder="Note" formControlName="note"></textarea>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button color="accent" mat-raised-button (click)="save($event)">
    <ng-container *ngIf="isEditForm">Save</ng-container>
    <ng-container *ngIf="!isEditForm">Create</ng-container>
  </button>
  <button mat-button [mat-dialog-close]="null" color="primary" mat-raised-button>Cancel</button>
</mat-dialog-actions>
