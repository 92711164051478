<h2 mat-dialog-title>
  <ng-container *ngIf="!isEditForm">
    Associate New <b>NGBS Practice</b>
  </ng-container>
  <ng-container *ngIf="isEditForm">
    Edit <b>NGBS Practice</b>
  </ng-container>
</h2>
<mat-dialog-content *ngIf="initialized">
  <form [formGroup]="form" class="mb-2">
    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Standard</mat-label>
          <app-generic-model-autocomplete modelClassName="practice_standard" formControlName="standard_info" [filterParams]="{is_active: true}"></app-generic-model-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <div class="row" *ngIf="form.get('standard_info').value">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Chapter</mat-label>
          <app-generic-model-autocomplete modelClassName="practice_chapter" formControlName="chapter_info" [filterParams]="{standard: form.get('standard_info').value?.id}"></app-generic-model-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <div class="row" *ngIf="form.get('chapter_info').value">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Section</mat-label>
          <app-generic-model-autocomplete modelClassName="practice_section" formControlName="section_info" [filterParams]="{chapter: form.get('chapter_info').value?.id}"></app-generic-model-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <div class="row" *ngIf="form.get('section_info').value">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Practice</mat-label>
          <app-generic-model-autocomplete modelClassName="practice" formControlName="practice_info" [filterParams]="{section: form.get('section_info').value?.id}"></app-generic-model-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <div class="row" *ngIf="form.get('practice_info').value">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Item</mat-label>
          <app-generic-model-autocomplete modelClassName="practice_item" formControlName="item_info" [filterParams]="{practice: form.get('practice_info').value?.id}"></app-generic-model-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <div class="row" *ngIf="form.get('item_info').value && canSeeField['points']">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <mat-form-field class="mb-2 w-100">
              <mat-label>Points</mat-label>
              <input matInput type="text" placeholder="Points" formControlName="points">
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="form.get('item_info').value && canSeeField['product_features']">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <mat-form-field class="mb-2 w-100">
              <mat-label>Product Features</mat-label>
              <textarea matInput type="text" placeholder="Product Features" formControlName="product_features"></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="form.get('item_info').value && canSeeField['used_to_earn_points']">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <mat-form-field class="mb-2 w-100">
              <mat-label>Used To Earn Points</mat-label>
              <textarea matInput type="text" placeholder="Used To Earn Points" formControlName="used_to_earn_points"></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="form.get('item_info').value && canSeeField['rc_comments']">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <mat-form-field class="mb-2 w-100">
              <mat-label>Comments</mat-label>
              <textarea matInput type="text" placeholder="Comments" formControlName="rc_comments"></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="form.get('item_info').value && canSeeField['is_active']">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <mat-form-field class="mb-2 w-100">
              <mat-label>Is Active</mat-label>
              <mat-select formControlName="is_active">
                <mat-option [value]="true">
                  Yes
                </mat-option>
                <mat-option [value]="false">
                  No
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="form.get('item_info').value">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Document</mat-label>
          <app-file-input formControlName="document"></app-file-input>
          <mat-error
            *ngIf="form.get('document').hasError('required')">
            This field is required
          </mat-error>
          <mat-hint *ngIf="entity?.customer_document_info?.document">
            Current file: <app-customer-document-display [customerDocument]="entity?.customer_document_info" displayType="filenameAsLinkAndSharedLink"></app-customer-document-display>
          </mat-hint>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button color="accent" mat-raised-button (click)="save($event)">Save</button>
  <button mat-button [mat-dialog-close]="null" color="primary" mat-raised-button>Cancel</button>
</mat-dialog-actions>
