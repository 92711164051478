import { createReducer, on } from '@ngrx/store';
import * as DistributionSystemActions from './actions';
import { DistributionSystemBackendDict } from '@/data/simulation/models/mechanicals/DistributionSystem';
import { BaseState, sharedInitialState } from '../shared/base.state';
import { addErrorCount, addStateErrorCount } from '../helper';

export type DistributionSystemState = BaseState<DistributionSystemBackendDict>;

export const initialState: DistributionSystemState = sharedInitialState;

export const reducer = createReducer(
  initialState,
  on(
    DistributionSystemActions.loadDistributionSystemsSuccess,
    (currentState, payload) => {
      const entities = payload.distributionSystems.reduce(
        (acc, distributionsystem) => ({
          ...acc,
          [distributionsystem.id]: distributionsystem,
        }),
        {}
      );

      const loadingStatus = payload.distributionSystems.reduce(
        (acc, distributionsystem) => ({
          ...acc,
          [distributionsystem.id]: false,
        }),
        {}
      );

      return {
        entities: { ...currentState.entities, ...entities },
        loading: { ...currentState.loading, ...loadingStatus },
        errors: {
          ...currentState.errors,
          ...addStateErrorCount(payload.errors),
        },
      };
    }
  ),

  on(
    DistributionSystemActions.removeDistributionSystemSuccess,
    (currentState, { id }) => {
      const { [id]: removed, ...entities } = currentState.entities;
      const { [id]: removedLoading, ...loading } = currentState.loading;
      const { [id]: removedErrors, ...errors } = currentState.errors;

      return {
        entities,
        loading,
        errors,
      };
    }
  ),

  on(
    DistributionSystemActions.updateDistributionSystem,
    (state, { distributionSystemChanges }) => {
      return {
        ...state,
        loading: {
          ...state.loading,
          [distributionSystemChanges.id]: true,
        },
      };
    }
  ),

  on(
    DistributionSystemActions.removeDistributionSystem,
    (state, { distributionSystem }) => {
      return {
        ...state,
        loading: {
          ...state.loading,
          [distributionSystem.id]: true,
        },
      };
    }
  ),

  on(
    DistributionSystemActions.updateDistributionSystemFailure,
    DistributionSystemActions.removeDistributionSystemFailure,
    (state, { id }) => {
      return {
        ...state,
        loading: {
          ...state.loading,
          [id]: false,
        },
      };
    }
  ),

  on(
    DistributionSystemActions.updateDistributionSystemErrors,
    (state, { id, errors }) => {
      return {
        ...state,
        errors: {
          ...state.errors,
          [id]: errors,
        },
      };
    }
  ),

  on(
    DistributionSystemActions.updateDistributionSystemSuccess,
    (state, { distributionSystem, errors }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          [distributionSystem.id]: distributionSystem,
        },
        loading: {
          ...state.loading,
          [distributionSystem.id]: false,
        },
        errors: {
          ...state.errors,
          [distributionSystem.id]: addErrorCount(errors),
        },
      };
    }
  ),

  on(
    DistributionSystemActions.addDuctSuccess,
    (state, { distributionSystemId, ductId }) => {
      const distributionSystem = state.entities[distributionSystemId];
      const ductIds = distributionSystem.ducts;
      return {
        ...state,
        entities: {
          ...state.entities,
          [distributionSystemId]: {
            ...distributionSystem,
            ducts: [...ductIds, ductId],
          },
        },
      };
    }
  ),

  on(
    DistributionSystemActions.removeDuctSuccess,
    (state, { distributionSystemId, ductId }) => {
      const distributionSystem = state.entities[distributionSystemId];
      const ductIds = distributionSystem.ducts;
      return {
        ...state,
        entities: {
          ...state.entities,
          [distributionSystemId]: {
            ...distributionSystem,
            ducts: ductIds.filter(id => id !== ductId),
          },
        },
      };
    }
  )
);
