import { ModelFormControl, ModelFormGroup } from '../ModelForm';
import { Validators } from '@angular/forms';
import { FieldLabels } from '../base';

export const FIELD_LABELS: FieldLabels<BlockRateBackendDict> = {
  min_consumption: 'Min Consumption',
  max_consumption: 'Max Consumption',
  cost: 'Cost',
};

export interface BlockRateBackendDict {
  id: number;
  min_consumption: number;
  max_consumption: number;
  cost: number;
}

export function createBlockRateForm(
  data: BlockRateBackendDict
): ModelFormGroup {
  return new ModelFormGroup(
    {
      id: new ModelFormControl(data.id, Validators.required),
      min_consumption: new ModelFormControl(data.min_consumption, [
        Validators.required,
        Validators.min(0),
      ]),
      max_consumption: new ModelFormControl(data.max_consumption, [
        Validators.required,
        Validators.min(1),
      ]),
      cost: new ModelFormControl(data.cost, Validators.required),
    },
    {
      validators: [
        (controls: ModelFormGroup) => {
          const minConsumption = controls.get('min_consumption').value;
          const maxConsumption = controls.get('max_consumption').value;
          if (minConsumption >= maxConsumption) {
            return { invalidConsumption: true };
          }
          return null;
        },
      ],
    }
  );
}
