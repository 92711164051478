<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="row">
                <div class="col-6">
                    <h3>
                        <span *ngIf="!projectsIsLoading">{{ projectsCount | number }}</span>
                        <fa-icon [icon]="['fas', 'spinner']" [spin]="true" *ngIf="projectsIsLoading"></fa-icon>
                        NGBS MF Developments
                    </h3>
                </div>
                <div class="col-6 text-right">
                    <button [matMenuTriggerFor]="addProjectMenu"
                            class="btn btn-primary">Add New Project</button>
                    <mat-menu #addProjectMenu="matMenu">
                        <button mat-menu-item [routerLink]="['add', 'single-family']">Single Family</button>
                        <button mat-menu-item [routerLink]="['add', 'multi-family']">Multifamily</button>
                        <button mat-menu-item [routerLink]="['add', 'land-development']">Land Development</button>
                    </mat-menu>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <hr>
                </div>
            </div>

            <div class="row">
                <div class="col-3">
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <h5 class="card-header">Filters</h5>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Search</mat-label>
                                                <input matInput
                                                       type="text"
                                                       placeholder="Type something..."
                                                       [(ngModel)]="storedParams.search"
                                                       [ngModelOptions]="{standalone: true}"
                                                       (keyup)="applySearch($event.target?.value)">
                                                <button mat-button *ngIf="storedParams.search" matSuffix mat-icon-button aria-label="Clear" (click)="storedParams.search = ''; applySearch('')">
                                                    <mat-icon>close</mat-icon>
                                                </button>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Status</mat-label>
                                                <mat-select [(ngModel)]="storedParams.state" [ngModelOptions]="{standalone: true}" (ngModelChange)="list()">
                                                    <mat-option [value]="''">
                                                        Any
                                                    </mat-option>
                                                    <mat-option *ngFor="let item of stateLabelMapping | keyvalue " [value]="item.key">
                                                        {{ item.value }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-9">
                    <ngx-datatable
                            class="material"
                            [rows]="projects"
                            columnMode="force"
                            [headerHeight]="headerHeight"
                            [rowHeight]="'auto'"
                            [footerHeight]="50"
                            [limit]="storedParams.page_size"
                            [externalSorting]="true"
                            (sort)="onSort($event)"
                            [sorts]="[{prop: storedParams.ordering.replace('-', ''), dir: (storedParams.ordering.startsWith('-')) ? 'desc' : 'asc'}]"
                            [loadingIndicator]="projectsIsLoading">
                        <ngx-datatable-column name="Development Name" prop="project_name" [width]="200">
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                <span *ngIf="row.project_name && !row.subdivision">
                                    {{ row.project_name }}
                                </span>
                                <a *ngIf="row.project_name && row.subdivision" [routerLink]="['/', 'subdivision', row.subdivision]" target="_blank">
                                    {{ row.project_name }}
                                </a>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Program" prop="eep_program__name">
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                <a [routerLink]="['/', 'eep_program', row.eep_program]" target="_blank">
                                    {{ row.eep_program_info.name }}
                                </a>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Building Count" [sortable]="false" [cellClass]="'text-center'">
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                {{ row.projects_count }}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Status" prop="state" [cellClass]="'text-center'">
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                <a [routerLink]="['/', 'hi', 'project_registrations', row.id]" target="_blank">
                                    <app-hirl-project-registration-state [projectRegistration]="row"></app-hirl-project-registration-state>
                                </a>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Registered by" [sortable]="false">
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                              <app-user-display [user]="row?.registration_user_info" displayType="onlyNameAsLink"></app-user-display>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-footer>
                            <ng-template
                                    ngx-datatable-footer-template>
                                <div class="page-count col-2 text-center">
                                    <!--                            Showing {{storedParams.page}} to {{projectsCount | divide: 25 }} of {{projectsCount}}-->
                                </div>
                                <div class="col-5 text-right pr-0">
                                    <datatable-pager
                                            [pagerLeftArrowIcon]="'datatable-icon-left'"
                                            [pagerRightArrowIcon]="'datatable-icon-right'"
                                            [pagerPreviousIcon]="'datatable-icon-prev'"
                                            [pagerNextIcon]="'datatable-icon-skip'"
                                            [page]="storedParams.page"
                                            [size]="storedParams.page_size"
                                            [count]="projectsCount"
                                            (change)="onFooterPage($event)"
                                    >
                                    </datatable-pager>
                                </div>
                            </ng-template>
                        </ngx-datatable-footer>
                    </ngx-datatable>
                </div>
            </div>
        </div>
    </div>
</div>
