import { createReducer, on } from '@ngrx/store';
import * as LocationActions from './actions';
import { LocationBackendDict } from '@/data/simulation/models/Location';
import { BaseState } from '../shared/base.state';
import { addStateErrorCount } from '../helper';

export type LocationState = BaseState<LocationBackendDict>;

export const initialState: LocationState = {
  entities: {},
  loading: {},
  errors: {},
};

export const reducer = createReducer(
  initialState,
  on(LocationActions.loadLocationsSuccess, (state, { locations, errors }) => {
    const entities = locations.reduce(
      (acc, location) => ({ ...acc, [location.id]: location }),
      {}
    );
    const loading = locations.reduce(
      (acc, location) => ({ ...acc, [location.id]: false }),
      {}
    );

    return {
      entities: {
        ...state.entities,
        ...entities,
      },
      loading: {
        ...state.loading,
        ...loading,
      },
      errors: {
        ...state.errors,
        ...addStateErrorCount(errors),
      },
    };
  }),

  on(LocationActions.updateLocation, (state, { locationChanges }) => ({
    ...state,
    loading: {
      ...state.loading,
      [locationChanges.id]: true,
    },
  })),

  on(LocationActions.updateLocationFailure, (state, { id }) => ({
    ...state,
    loading: {
      ...state.loading,
      [id]: false,
    },
  }))
);
