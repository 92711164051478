import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  QaStatusRequestParams,
  QaStatusService,
} from '@/data/qa/services/qa-status.service';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, takeUntil } from 'rxjs/operators';
import {
  HIRLPriorityLabelMapping,
  HIRLQAStatusList,
  HIRLQAStatusUserFilterBadgesCount,
  QARequirementType,
  QAState,
  QAStatusStateLabelMapping,
} from '@/data/qa/models';
import { HIRLEEPProgramHomeStatusStateLabelMapping } from '@/data/home/models/eep_program_home_status';
import { NavigationService } from '@/shared/services/navigation-service.service';
import {
  HirlQaNotesDialogComponent,
  IHirlQANotesDialogData,
} from '@/modules/customer-hirl/components/hirl-qa-notes-dialog/hirl-qa-notes-dialog.component';
import {
  HirlQaNoteMultipleCreateDialogComponent,
  IHirlQaNoteMultipleCreateDialogData,
} from '@/modules/customer-hirl/components/hirl-qa-note-multiple-create-dialog/hirl-qa-note-multiple-create-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from '@/data/core/models/user';
import { CompanyType } from '@/data/company/models';
import { City, Country, USState } from '@/data/geographic/models';
import { ServerResponse } from '@/core/schemes/server-response';
import { ESGReportList } from '@/data/home/models';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-hirl-qa-dashboard',
  templateUrl: './hirl-qa-dashboard.component.html',
  styleUrls: ['./hirl-qa-dashboard.component.scss'],
})
export class HirlQaDashboardComponent implements OnInit, OnDestroy {
  protected readonly HIRLPriorityLabelMapping = HIRLPriorityLabelMapping;

  public initialized = false;
  public currentUser: User;
  public filterFromGroup: UntypedFormGroup;
  public qaState = QAState;
  public companyType = CompanyType;
  public qaStatusStateLabelMapping = QAStatusStateLabelMapping;
  public homeStatusStateLabelMapping =
    HIRLEEPProgramHomeStatusStateLabelMapping;
  public requirementTypeLabelMapping = {
    [QARequirementType.roughInspection]: 'Rough Inspection',
    [QARequirementType.finalInspection]: 'Final Inspection',
    [QARequirementType.desktopAudit]: 'Desktop Audit',
    [QARequirementType.roughInspectionVirtualAudit]: 'Rough Virtual Audit',
    [QARequirementType.finalInspectionVirtualAudit]: 'Final Virtual Audit',
  };
  public userFilterBadges: HIRLQAStatusUserFilterBadgesCount =
    new HIRLQAStatusUserFilterBadgesCount();

  public defaultParams: QaStatusRequestParams = new QaStatusRequestParams(
    1,
    '',
    '-id',
    25
  );
  public storedParams: QaStatusRequestParams;

  public entitiesIsLoading = true;
  public entities: HIRLQAStatusList[] = [];
  public entitiesCount = 0;
  public selectedRows: HIRLQAStatusList[] = [];

  private listSubscription$: Subscription;
  private componentDestroyed$ = new Subject();

  constructor(
    public store: Store<AppState>,
    public qaStatusService: QaStatusService,
    public router: Router,
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private navigation: NavigationService,
    private uiHelperService: UIHelperService
  ) {}

  ngOnInit() {
    this.storedParams = Object.assign(
      new QaStatusRequestParams(),
      this.defaultParams
    );

    forkJoin({
      queryParams: this.activatedRoute.queryParams.pipe(first()),
      currentUser: this.store.select(getInfoUser).pipe(first()),
    }).subscribe(({ queryParams, currentUser }) => {
      this.initialized = true;
      this.currentUser = currentUser;
      this.storedParams.assignQueryParams(queryParams, [
        'home_status__home__city',
        'home_status__home__state',
        'home_status__home__city__country',
        'hirl_priority',
      ]);
      this.setupFilterForm();
      this.hydrateForm();
      this.list();
    });

    this.qaStatusService
      .customer_hirl_user_filter_badges_count()
      .pipe(first())
      .subscribe(
        userFilterBadges => (this.userFilterBadges = userFilterBadges)
      );
  }

  setupFilterForm() {
    this.filterFromGroup = this.fb.group({
      search: [null],
      state: [''],
      requirement__type: [''],
      qa_designee_info: [null],
      home_status__state: [''],
      home_status__customer_hirl_project__registration__builder_organization_info:
        [null],
      home_status__customer_hirl_project__registration__registration_user__company_info:
        [null],
      home_status__home__city_info: [null],
      home_status__home__state_info: [null],
      home_status__home__city__country_info: [null],
      hirl_priority: [null],
    });

    this.filterFromGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        let params = Object.assign(this.defaultParams, values);
        params = Object.assign(params, {
          qa_designee: params.qa_designee_info?.map(
            (qa_designee: User) => qa_designee.id
          ),
          home_status__customer_hirl_project__registration__builder_organization:
            params
              .home_status__customer_hirl_project__registration__builder_organization_info
              ?.id,
          home_status__customer_hirl_project__registration__registration_user__company:
            params
              .home_status__customer_hirl_project__registration__registration_user__company_info
              ?.id,
          home_status__home__city: params.home_status__home__city_info?.map(
            (city: City) => city.id
          ),
          home_status__home__state: params.home_status__home__state_info?.map(
            (state: USState) => state.abbr
          ),
          home_status__home__city__country:
            params.home_status__home__city__country_info?.map(
              (country: Country) => country.id
            ),
        });
        this.storedParams.assignFilterFormValues({
          formValues: params,
          excludedProperties: [
            'ordering',
            'qa_designee_info',
            'home_status__customer_hirl_project__registration__builder_organization_info',
            'home_status__customer_hirl_project__registration__registration_user__company_info',
            'home_status__home__city_info',
            'home_status__home__state_info',
            'home_status__home__city__country_info',
          ],
        });
        this.list();
      });
  }

  hydrateForm() {
    this.filterFromGroup.patchValue(this.storedParams, {
      emitEvent: false,
      onlySelf: true,
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  list() {
    this.entitiesIsLoading = true;

    // populate params to query string
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.storedParams.toQueryParams(),
      replaceUrl: true,
    });

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }

    this.listSubscription$ = this.qaStatusService
      .customer_hirl_list(this.storedParams)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: (data: ServerResponse<ESGReportList>) => {
          this.entities = data.results;
          this.entitiesCount = data.count;
          this.entitiesIsLoading = false;
        },
        error: (error: HttpErrorResponse) => {
          if (error.status === 400) {
            // to avoid cycle loop, reload list only if invalid param in query detected
            let invalidQueryParam = false;
            for (const key of Object.keys(error.error)) {
              if (this.storedParams.hasOwnProperty(key)) {
                invalidQueryParam = true;
                delete this.storedParams[key];
              }
            }

            if (invalidQueryParam) {
              this.list();
            }
          }
        },
      });
  }

  getId(row) {
    return row.id;
  }

  onSort({ sorts }) {
    this.storedParams.ordering = `${sorts[0].dir === 'desc' ? '-' : ''}${
      sorts[0].prop
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  onFooterPage(event) {
    this.storedParams.page = event.page;
    this.list();
  }

  onSelect({ selected }) {
    this.selectedRows.splice(0, this.selectedRows.length);
    this.selectedRows = [].concat(selected);
  }

  resetFilters($event: MouseEvent) {
    $event.preventDefault();
    this.filterFromGroup.reset();
  }

  removeAllSelectedRows($event: MouseEvent) {
    $event.preventDefault();
    this.selectedRows = [];
  }

  onShowQANotesList($event: MouseEvent, qaStatusId: number) {
    $event.preventDefault();

    const dialogData = {
      qaStatusId,
    } as IHirlQANotesDialogData;

    const dialogRef = this.dialog.open(HirlQaNotesDialogComponent, {
      width: '60%',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe(_ => {});
  }

  onCreateQANote($event: MouseEvent, qaStatusesIds: number[]) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(
      HirlQaNoteMultipleCreateDialogComponent,
      {
        width: '45%',
        data: {
          qaStatusesIds,
        } as IHirlQaNoteMultipleCreateDialogData,
      }
    );

    dialogRef.afterClosed().subscribe(qaNotes => {
      if (qaNotes) {
        this.snackBar.open('QA Note successfully created', 'Close', {
          duration: 5000,
        });
      }
    });
  }

  onCreateQANoteForSelectedRows($event: MouseEvent) {
    $event.preventDefault();
    const qaStatusesIds = this.selectedRows.map(qa_status => qa_status.id);
    const dialogRef = this.dialog.open(
      HirlQaNoteMultipleCreateDialogComponent,
      {
        width: '45%',
        data: {
          qaStatusesIds,
        } as IHirlQaNoteMultipleCreateDialogData,
      }
    );

    dialogRef.afterClosed().subscribe(qaNotes => {
      if (qaNotes) {
        this.snackBar.open('QA Notes successfully created', 'Close', {
          duration: 5000,
        });
        this.selectedRows = [];
      }
    });
  }

  onQuickFilterAllProjects($event: MouseEvent) {
    this.filterFromGroup.reset();
  }

  onQuickFilterMyProjects($event: MouseEvent) {
    this.filterFromGroup.reset({ emitEvent: false });
    const newFilterData = {};
    if (this.currentUser.hirl_user_profile.is_qa_designee) {
      this.filterFromGroup.patchValue({ qa_designee_info: [this.currentUser] });
    }

    this.filterFromGroup.patchValue(newFilterData);
  }

  onQuickFilterMyRoughQAProjects($event: MouseEvent) {
    this.filterFromGroup.reset({ emitEvent: false });
    const newFilterData = {};
    if (this.currentUser.hirl_user_profile.is_qa_designee) {
      newFilterData['qa_designee_info'] = [this.currentUser];
    }
    newFilterData['requirement__type'] = QARequirementType.roughInspection;

    this.filterFromGroup.patchValue(newFilterData);
  }

  onQuickFilterMyFinalQAProjects($event: MouseEvent) {
    this.filterFromGroup.reset({ emitEvent: false });
    const newFilterData = {};
    if (this.currentUser.hirl_user_profile.is_qa_designee) {
      newFilterData['qa_designee_info'] = [this.currentUser];
    }
    newFilterData['requirement__type'] = QARequirementType.finalInspection;

    this.filterFromGroup.patchValue(newFilterData);
  }

  onQuickFilterMyDesktopAuditQAProjects($event: MouseEvent) {
    this.filterFromGroup.reset({ emitEvent: false });
    const newFilterData = {};
    if (this.currentUser.hirl_user_profile.is_qa_designee) {
      newFilterData['qa_designee_info'] = [this.currentUser];
    }
    newFilterData['requirement__type'] = QARequirementType.desktopAudit;

    this.filterFromGroup.patchValue(newFilterData);
  }

  onQuickFilterMyCorrectionReceivedQAProjects($event: MouseEvent) {
    this.filterFromGroup.reset({ emitEvent: false });
    const newFilterData = {};
    if (this.currentUser.hirl_user_profile.is_qa_designee) {
      newFilterData['qa_designee_info'] = [this.currentUser];
    }
    newFilterData['state'] = QAState.correction_received;

    this.filterFromGroup.patchValue(newFilterData);
  }

  onQuickFilterMyCorrectionRequiredQAProjects($event: MouseEvent) {
    this.filterFromGroup.reset({ emitEvent: false });
    const newFilterData = {};
    if (this.currentUser.hirl_user_profile.is_qa_designee) {
      newFilterData['qa_designee_info'] = [this.currentUser];
    }
    newFilterData['state'] = QAState.correction_required;

    this.filterFromGroup.patchValue(newFilterData);
  }
}
