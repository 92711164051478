import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { forkJoin, of, Subject } from 'rxjs';
import { User } from '@/data/core/models/user';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, takeUntil } from 'rxjs/operators';
import { toggleLoading } from '@/state/actions/app.actions';
import { AppFieldValidatorsService } from '@/core/services/field-validators.service';
import { InspectionGrade } from '@/data/user-management/models';
import { HttpErrorResponse } from '@angular/common/http';
import { InspectionGradeService } from '@/data/user-management/services/inspection-grade.service';

export class InspectionGradeChangeChangeDialogData {
  entity?: InspectionGrade | number;
  commit = true;
  retrieve = true;
}

@Component({
  selector: 'app-inspection-grade-change-dialog',
  templateUrl: './inspection-grade-change-dialog.component.html',
  styleUrls: ['./inspection-grade-change-dialog.component.scss'],
})
export class InspectionGradeChangeDialogComponent implements OnInit, OnDestroy {
  public initialized: boolean;
  public isEditForm = false;
  public entity?: InspectionGrade;
  private entityId?: number;

  public commit = true;
  public retrieve = true;
  public form: FormGroup;
  public currentUser: User;

  private componentDestroyed$: Subject<boolean> = new Subject();

  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<InspectionGradeChangeDialogComponent>,
    private fb: FormBuilder,
    private inspectionGradeService: InspectionGradeService,
    private dialog: MatDialog,
    private appFieldValidatorsService: AppFieldValidatorsService,
    private uiHelperService: UIHelperService,
    @Inject(MAT_DIALOG_DATA) public data: InspectionGradeChangeChangeDialogData
  ) {
    this.commit = data?.commit || true;

    if (data?.entity) {
      if (typeof data.entity === 'number') {
        this.commit = true;
        this.isEditForm = true;
        this.entityId = data.entity;
      } else {
        this.entity = Object.assign({}, data?.entity);
        this.entityId = data.entity.id;
        if (this.entity?.id) {
          this.isEditForm = true;
        }
      }
    }
  }

  ngOnInit() {
    const sources = {
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: of(this.entity),
    };

    this.store.dispatch(toggleLoading({ payload: true }));

    if (this.isEditForm && this.retrieve) {
      sources.entity = this.inspectionGradeService
        .retrieve(this.entityId, { ignoreStore: true })
        .pipe(takeUntil(this.componentDestroyed$), first());
    }

    forkJoin(sources)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: ({
          currentUser,
          entity,
        }: {
          currentUser: User;
          entity: InspectionGrade;
        }) => {
          this.store.dispatch(toggleLoading({ payload: false }));

          this.currentUser = currentUser;

          this.entity = entity;
          this.setupForm();
          this.initialized = true;
          if (this.isEditForm) {
            this.hydrateForm();
          } else {
            this.initialValues();
          }
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  private setupForm() {
    this.form = this.fb.group({
      notes: [''],
    });
  }

  hydrateForm() {
    this.form.patchValue(Object.assign({}, this.entity), {
      emitEvent: false,
      onlySelf: true,
    });
  }
  private initialValues() {}

  save($event: MouseEvent) {
    $event.preventDefault();
    if (this.isEditForm) {
      this.edit();
    } else {
      this.create();
    }
  }

  private getFormData(): InspectionGrade {
    const data: InspectionGrade = new InspectionGrade();
    data.notes = this.form.value.notes;

    return data;
  }

  private create() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    if (!this.commit) {
      this.dialogRef.close(this.entity);
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));
  }

  private edit() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    if (!this.commit) {
      this.dialogRef.close(this.entity);
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const data: InspectionGrade = this.getFormData();

    this.inspectionGradeService
      .update(data, this.entity.id)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: (inspectionGrade: InspectionGrade) => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close(inspectionGrade);
        },
        error: (error: HttpErrorResponse) =>
          this.uiHelperService.handleUserRequestError(error),
      });
  }
}
