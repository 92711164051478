import { Component, Injector, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { Store } from '@ngrx/store';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first } from 'rxjs/operators';
import { User } from '@/data/core/models/user';
import { AppState } from '@/state/reducers';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import {
  AxisFlatPageRequestParams,
  AxisFlatPageService,
} from '@/data/core/services/axis_flat_page';
import { OkDialogComponent } from '@/shared/components/ok-dialog/ok-dialog.component';
import { AxisFlatPage } from '@/data/core/models';

@Component({
  selector: 'app-news-column',
  templateUrl: './news-column.component.html',
  styleUrls: ['./news-column.component.scss'],
})
export class NewsColumnComponent implements OnInit {
  public initialized = false;
  public currentUser: User;

  public rows = [];
  public rowsCount = 0;

  constructor(
    public store: Store<AppState>,
    public router: Router,
    public injector: Injector,
    public dialog: MatDialog,
    public axisFlatPageService: AxisFlatPageService,
    public uiHelper: UIHelperService
  ) {}

  ngOnInit(): void {
    forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      newsResponse: this.axisFlatPageService.news(
        Object.assign(new AxisFlatPageRequestParams(), { page_size: 3 })
      ),
    }).subscribe(({ currentUser, newsResponse }) => {
      this.currentUser = currentUser;
      this.rows = newsResponse.results;
      this.rowsCount = newsResponse.count;
      this.initialized = true;
    });
  }

  handleOpenFlatpage($event: MouseEvent, flatpage: AxisFlatPage) {
    $event.preventDefault();
    this.dialog.open(OkDialogComponent, {
      data: {
        title: flatpage.title,
        content: flatpage.content,
      },
    });
  }
}
