import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BaseDataService } from '@/core/services/base-data-service';
import { Observable } from 'rxjs';
import { AirConditionerBackendDict } from '../models/mechanicals/AirConditioner';
import { ServerResponse } from '@/core/schemes/server-response';
import { ListRequestParams } from '@/core/schemes/request-params';

@Injectable({ providedIn: 'root' })
export class AirConditionerService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/air_conditioner/`;

  list(
    params: ListRequestParams
  ): Observable<ServerResponse<AirConditionerBackendDict>> {
    return this.http.get<ServerResponse<AirConditionerBackendDict>>(
      `${this.baseUrl}`,
      {
        params: this.rollParams(params),
      }
    );
  }

  update(
    heater: Partial<AirConditionerBackendDict>
  ): Observable<AirConditionerBackendDict> {
    return this.http.patch<AirConditionerBackendDict>(
      `${this.baseUrl}${heater.id}/`,
      heater
    );
  }
}
