import { AuthGuard } from '@/core/guards';

import { FloorplanListPageComponent } from './pages/floorplan-list-page/floorplan-list-page.component';
import { FloorplanDetailPageComponent } from './pages/floorplan-detail-page/floorplan-detail-page.component';

export const FloorPlanRouting = [
  {
    path: 'floorplans',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: FloorplanListPageComponent,
        resolve: {},
      },
      {
        path: ':floorplanId',
        component: FloorplanDetailPageComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
];
