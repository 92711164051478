import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AirConditionerService } from '@/data/simulation/services/air-conditioner.service';
import { catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as AirConditionerActions from './actions';
import * as SharedActions from '../shared/shared.actions';
import { AirConditionerValidator } from '../../validators/air-conditioner.validator';
import { StateModelName } from '../../state.registry';

@Injectable()
export class AirConditionerEffects {
  loadAirConditioners$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AirConditionerActions.loadAirConditioners),
      mergeMap(action => {
        const errors = AirConditionerValidator.validate(action.airConditioners);
        return of(
          AirConditionerActions.loadAirConditionersSuccess({
            airConditioners: action.airConditioners,
            errors,
          })
        );
      })
    );
  });

  updateAirConditioner$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AirConditionerActions.updateAirConditioner),
      mergeMap(action => {
        return this.airConditionerService
          .update(action.airConditionerChanges)
          .pipe(
            mergeMap(airConditioner => {
              const errors = AirConditionerValidator.validate([airConditioner]);
              return of(
                AirConditionerActions.loadAirConditionersSuccess({
                  airConditioners: [airConditioner],
                  errors,
                }),
                SharedActions.updateCrossModelErrors({
                  modelName: StateModelName.airConditioner,
                  changedFields: Object.keys(action.airConditionerChanges),
                })
              );
            }),
            catchError(error =>
              of(
                AirConditionerActions.updateAirConditionerFailure({
                  id: action.airConditionerChanges.id,
                }),
                SharedActions.reportAPIFailure({ error })
              )
            )
          );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private airConditionerService: AirConditionerService
  ) {}
}
