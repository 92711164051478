import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import * as DoorActions from './actions';
import * as DoorTypeActions from '../door-type/actions';
import * as SimulationActions from '../simulation/actions';
import * as SharedActions from '../shared/shared.actions';
import { DoorService } from '@/data/simulation/services/door.service';
import { DoorTypeService } from '@/data/simulation/services/door-type.service';
import { DoorTypeBackendDict } from '@/data/simulation/models/enclosure/DoorType';
import { DoorBackendDict } from '@/data/simulation/models/enclosure/Door';
import { ModelGraphService } from '../../services/model-graph.service';
import { DoorValidator } from '../../validators/door.validator';
import { StateModelName } from '../../state.registry';

@Injectable()
export class DoorEffects {
  loadDetailedDoors$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DoorActions.loadDetailedDoors),
      mergeMap(action => {
        const doorTypes: DoorTypeBackendDict[] = [];
        const doors: DoorBackendDict[] = [];
        action.detailedDoors.forEach(detailedDoor => {
          const { type_info: detailedDoorType, ...door } = detailedDoor;
          if (detailedDoorType) {
            doorTypes.push(detailedDoorType);
          }
          this.modelGraphService.attachModel(
            'door',
            detailedDoor.id,
            'doorType',
            [detailedDoor.type]
          );
          doors.push(door);
        });

        const errors = DoorValidator.validate(action.detailedDoors);

        return of(
          DoorActions.loadDoorsSuccess({
            doors: doors,
            errors: errors,
          }),
          DoorTypeActions.loadDetailedDoorTypes({
            doorTypes: doorTypes,
          })
        );
      })
    );
  });

  updateDoor$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DoorActions.updateDoor),
      mergeMap(action =>
        this.doorService.update(action.doorChanges).pipe(
          mergeMap(updatedDoor => {
            const errors = DoorValidator.validate([updatedDoor]);
            return of(
              DoorActions.updateDoorSuccess({
                door: updatedDoor,
                errors: errors[updatedDoor.id],
              }),
              SharedActions.updateCrossModelErrors({
                modelName: StateModelName.door,
                changedFields: Object.keys(action.doorChanges),
              })
            );
          }),
          catchError(error =>
            of(
              DoorActions.updateDoorFailure({
                id: action.doorChanges.id,
              }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        )
      )
    );
  });

  removeDoor$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DoorActions.removeDoor),
      mergeMap(action =>
        this.doorService.delete(action.door.id).pipe(
          mergeMap(() =>
            of(
              DoorActions.removeDoorSuccess({
                id: action.door.id,
              }),
              SimulationActions.removeItemFromList({
                fieldName: 'doors',
                id: action.door.id,
              })
            )
          ),
          catchError(error =>
            of(
              DoorActions.removeDoorFailure({
                id: action.door.id,
              }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        )
      )
    );
  });

  removeDoorType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DoorTypeActions.removeDoorType),
      mergeMap(action =>
        this.doorTypeService.delete(action.doorTypeId).pipe(
          mergeMap(() => [
            DoorActions.setDoorType({
              doorId: action.doorId,
              doorTypeId: null,
            }),
            DoorTypeActions.removeDoorTypeSuccess({
              id: action.doorTypeId,
            }),
          ]),
          catchError(error => of(SharedActions.reportAPIFailure({ error })))
        )
      )
    );
  });

  addDoorType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DoorTypeActions.addDoorType),
      mergeMap(action =>
        this.doorTypeService.create(action.doorId, action.doorType).pipe(
          mergeMap(doorType =>
            of(
              DoorTypeActions.loadDetailedDoorTypes({
                doorTypes: [doorType],
              }),
              DoorActions.setDoorType({
                doorId: action.doorId,
                doorTypeId: doorType.id,
              })
            )
          ),
          catchError(error => of(SharedActions.reportAPIFailure({ error })))
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private modelGraphService: ModelGraphService,
    private doorService: DoorService,
    private doorTypeService: DoorTypeService
  ) {}
}
