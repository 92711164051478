import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '@/data/core/models/user';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';
import { forkJoin, of, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, switchMap, takeUntil } from 'rxjs/operators';
import { toggleLoading } from '@/state/actions/app.actions';
import { Invoice } from '@/data/invoicing/models';
import { InvoiceService } from '@/data/invoicing/services/invoice.service';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';

export class HirlInvoiceShareDialogData {
  invoiceId: string;
}

@Component({
  selector: 'app-hirl-invoice-share-dialog',
  templateUrl: './hirl-invoice-share-dialog.component.html',
  styleUrls: ['./hirl-invoice-share-dialog.component.scss'],
})
export class HirlInvoiceShareDialogComponent implements OnInit, OnDestroy {
  emailsSeparatorKeysCodes: number[] = [ENTER, COMMA, SPACE];
  emails: string[] = [];

  @ViewChild('emailsInput') emailsInput: ElementRef<HTMLInputElement>;

  public initialized: boolean;
  public entity?: Invoice;
  public commit = true;
  public retrieve = true;
  public form: FormGroup;
  public currentUser: User;

  public customerHIRLSettings = CustomerHIRLSettings;

  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<HirlInvoiceShareDialogComponent>,
    private fb: FormBuilder,
    private invoiceService: InvoiceService,
    private dialog: MatDialog,
    private uiHelperService: UIHelperService,
    @Inject(MAT_DIALOG_DATA) public data: HirlInvoiceShareDialogData
  ) {}

  ngOnInit() {
    const sources = {
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: this.invoiceService
        .retrieve(this.data.invoiceId, { ignoreStore: true })
        .pipe(takeUntil(this.componentDestroyed$), first()),
    };

    this.store.dispatch(toggleLoading({ payload: true }));

    forkJoin(sources)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: ({ currentUser, entity }) => {
          this.store.dispatch(toggleLoading({ payload: false }));

          this.currentUser = currentUser;
          this.entity = entity;
          this.emails = this.entity.emails?.split(',').filter(n => n) || [];
          this.setupForm();
          this.initialized = true;
          this.hydrateForm();
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  setupForm() {
    this.form = this.fb.group({
      emails: [this.entity.emails || '', Validators.required],
    });
  }

  hydrateForm() {
    this.form.patchValue(Object.assign({}, this.entity), {
      emitEvent: false,
      onlySelf: true,
    });
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.emails.push(value);
      this.form.get('emails').setValue(this.emails);
      this.form.get('emails').updateValueAndValidity();
    }

    // Clear the input value
    if (event.chipInput) {
      event.chipInput.clear();
    }
  }

  remove(email: string): void {
    const index = this.emails.indexOf(email);

    if (index >= 0) {
      this.emails.splice(index, 1);
      this.form.get('emails').setValue(this.emails);
      this.form.get('emails').updateValueAndValidity();
    }
  }

  save($event: MouseEvent) {
    $event.preventDefault();
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    if (!this.commit) {
      this.dialogRef.close(this.entity);
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const data = new Invoice();
    data.emails = this.form.get('emails').value.toString();

    this.invoiceService
      .update(this.entity.prefixed_id, data)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .pipe(
        switchMap(invoice => {
          if (invoice.emails) {
            return this.invoiceService.customer_hirl_send_invoice_copy_for_additional_emails(
              invoice.prefixed_id
            );
          } else {
            return of(invoice);
          }
        })
      )
      .subscribe({
        next: invoice => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close(invoice);
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }
}
