import {
  Component,
  Input,
  ViewEncapsulation,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { BaseAppControlValueAccessor } from '../base-control-value-accessor.directive';
import { LabelWithDescription } from '@/data/simulation/models/base';
import { evaluateSimpleExpression } from '@/data/simulation/math-eval';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class InputComponent
  extends BaseAppControlValueAccessor
  implements OnInit, OnDestroy
{
  @Input() type: 'text' | 'number' | 'expression' = 'text';
  @Input() label: string | LabelWithDescription;
  @Input() placeholder = '';
  @Input() presentErrorsInline = true;
  @Input() unit: string = null;
  @Input() disabled = false;

  private valueChangesSubscription: Subscription;

  onCommit() {
    const value = this.control.value;

    // Manually setting the value to null if it's an empty string.
    if (this.type === 'number' && value === '') {
      this.control.setValue(null, { emitEvent: false });
    }

    if (this.type !== 'expression' || !value) return;

    if (typeof value === 'number') {
      this.control.setErrors(null);
      return;
    }

    try {
      const result = evaluateSimpleExpression(value);

      if (result !== null && !isNaN(result)) {
        this.control.setValue(result, { emitEvent: false });
        this.control.setErrors(null);
      } else {
        this.control.setErrors({ invalidExpression: true });
      }
    } catch (error) {
      this.control.setErrors({ invalidExpression: true });
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.valueChangesSubscription?.unsubscribe();
  }
}
