import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { QaStatus } from '@/data/qa/models';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getInfoUser } from '@/state/selectors/info.selector';
import { AppState } from '@/state/reducers';
import { QaStatusService } from '@/data/qa/services/qa-status.service';
import { InvoiceService } from '@/data/invoicing/services/invoice.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { QaNoteRequestParams } from '@/data/qa/services/qa-note.service';
import { QANoteList } from '@/data/qa/models/qa-status';

export interface IHirlQANotesDialogData {
  qaStatusId: number;
}

@Component({
  selector: 'app-hirl-qa-notes-dialog',
  templateUrl: './hirl-qa-notes-dialog.component.html',
  styleUrls: ['./hirl-qa-notes-dialog.component.scss'],
})
export class HirlQaNotesDialogComponent implements OnInit {
  public initialized = false;
  public qaStatus: QaStatus;
  public isLoading = true;
  public filterFromGroup: UntypedFormGroup;

  public defaultParams: QaNoteRequestParams = new QaNoteRequestParams(
    1,
    '',
    '-id',
    25
  );
  public storedParams: QaNoteRequestParams;

  public rows: QANoteList[] = [];
  public rowsCount = 0;

  private listSubscription$: Subscription;
  private componentDestroyed$ = new Subject();

  constructor(
    public dialogRef: MatDialogRef<HirlQaNotesDialogComponent>,
    public dialog: MatDialog,
    public uiHelperService: UIHelperService,
    public store: Store<AppState>,
    public qaStatusService: QaStatusService,
    public invoiceService: InvoiceService,
    public router: Router,
    private fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: IHirlQANotesDialogData
  ) {
    this.storedParams = Object.assign(
      new QaNoteRequestParams(),
      this.defaultParams
    );

    forkJoin({
      qaStatus: this.qaStatusService.retrieve(data.qaStatusId),
      currentUser: this.store.select(getInfoUser).pipe(first()),
    }).subscribe(({ qaStatus, currentUser }) => {
      this.qaStatus = qaStatus;
      this.initialized = true;
      this.list();
      this.setupFilterForm();
    });
  }

  ngOnInit(): void {}

  list() {
    this.isLoading = true;

    // populate params to query string
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.storedParams.toQueryParams(),
      replaceUrl: true,
    });

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }

    this.listSubscription$ = this.qaStatusService
      .qa_notes_flat_list(this.qaStatus.id, this.storedParams)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(data => {
        this.rows = data.results;
        this.rowsCount = data.count;
        this.isLoading = false;
      });
  }

  setupFilterForm() {
    this.filterFromGroup = this.fb.group({
      search: [null],
    });

    this.filterFromGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        const params = Object.assign(this.defaultParams, values);
        delete params.ordering;

        this.storedParams = Object.assign(this.storedParams, params);
        this.list();
      });
  }

  resetFilters($event: MouseEvent) {
    $event.preventDefault();
    this.filterFromGroup.reset();
  }

  onSort({ sorts }) {
    this.storedParams.ordering = `${sorts[0].dir === 'desc' ? '-' : ''}${
      sorts[0].prop
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  onFooterPage(event) {
    this.storedParams.page = event.page;
    this.list();
  }

  cancelClick(): void {
    this.dialogRef.close();
  }
}
