import { SimulationModelsState } from './state/reducers';
import {
  AboveGradeWallBackendDict,
  FIELD_LABELS as AboveGradeWallLabels,
} from '@/data/simulation/models/enclosure/AboveGradeWall';
import {
  AboveGradeWallTypeBackendDict,
  FIELD_LABELS as AboveGradeWallTypeLabels,
} from '@/data/simulation/models/enclosure/AboveGradeWallType';
import {
  DoorBackendDict,
  FIELD_LABELS as DoorLabels,
} from '@/data/simulation/models/enclosure/Door';
import {
  DoorTypeBackendDict,
  FIELD_LABELS as DoorTypeLabels,
} from '@/data/simulation/models/enclosure/DoorType';
import {
  FoundationWallBackendDict,
  FIELD_LABELS as FoundationWallLabels,
} from '@/data/simulation/models/enclosure/FoundationWall';
import {
  FoundationWallTypeBackendDict,
  FIELD_LABELS as FoundationWallTypeLabels,
} from '@/data/simulation/models/enclosure/FoundationWallType';
import {
  FrameFloorBackendDict,
  FIELD_LABELS as FrameFloorLabels,
} from '@/data/simulation/models/enclosure/FrameFloor';
import {
  FrameFloorTypeBackendDict,
  FIELD_LABELS as FrameFloorTypeLabels,
} from '@/data/simulation/models/enclosure/FrameFloorType';
import {
  EquipmentType,
  MechanicalEquipmentBackendDict,
  FIELD_LABELS as MechanicalEquipmentLabels,
} from '@/data/simulation/models/mechanicals/MechanicalEquipment';
import {
  AirConditionerBackendDict,
  FIELD_LABELS as AirConditionerLabels,
} from '@/data/simulation/models/mechanicals/AirConditioner';
import {
  AirSourceHeatPumpBackendDict,
  FIELD_LABELS as AirSourceHeatPumpLabels,
} from '@/data/simulation/models/mechanicals/AirSourceHeatPump';
import {
  DehumidifierBackendDict,
  FIELD_LABELS as DehumidifierLabels,
} from '@/data/simulation/models/mechanicals/Dehumidifier';
import {
  GroundSourceHeatPumpBackendDict,
  FIELD_LABELS as GroundSourceHeatPumpLabels,
} from '@/data/simulation/models/mechanicals/GroundSourceHeatPump';
import {
  HeaterBackendDict,
  FIELD_LABELS as HeaterLabels,
} from '@/data/simulation/models/mechanicals/Heater';
import {
  WaterHeaterBackendDict,
  FIELD_LABELS as WaterHeaterLabels,
} from '@/data/simulation/models/mechanicals/WaterHeater';
import {
  LightsBackendDict,
  FIELD_LABELS as LightsLabels,
} from '@/data/simulation/models/lights/Lights';
import {
  AppliancesBackendDict,
  FIELD_LABELS as AppliancesLabels,
} from '@/data/simulation/models/Appliances';
import {
  DistributionSystemBackendDict,
  FIELD_LABELS as DistributionSystemLabels,
} from '@/data/simulation/models/mechanicals/DistributionSystem';
import {
  DuctBackendDict,
  FIELD_LABELS as DuctLabels,
} from '@/data/simulation/models/mechanicals/Duct';
import {
  ThermostatBackendDict,
  FIELD_LABELS as ThermostatLabels,
} from '@/data/simulation/models/mechanicals/Thermostat';
import {
  PhotovoltaicBackendDict,
  FIELD_LABELS as PhotovoltaicLabels,
} from '@/data/simulation/models/generators/Photovoltaic';
import {
  RimJoistBackendDict,
  FIELD_LABELS as RimJoistLabels,
} from '@/data/simulation/models/enclosure/RimJoist';
import {
  RimJoistTypeBackendDict,
  FIELD_LABELS as RimJoistTypeLabels,
} from '@/data/simulation/models/enclosure/RimJoistType';
import {
  RoofBackendDict,
  FIELD_LABELS as RoofLabels,
} from '@/data/simulation/models/enclosure/Roof';
import {
  RoofTypeBackendDict,
  FIELD_LABELS as RoofTypeLabels,
} from '@/data/simulation/models/enclosure/RoofType';
import {
  SimulationBackendDict,
  FIELD_LABELS as SimulationLabels,
} from '@/data/simulation/models/simulation';
import {
  SlabBackendDict,
  FIELD_LABELS as SlabLabels,
} from '@/data/simulation/models/enclosure/Slab';
import {
  SlabTypeBackendDict,
  FIELD_LABELS as SlabTypeLabels,
} from '@/data/simulation/models/enclosure/SlabType';
import {
  SkylightBackendDict,
  FIELD_LABELS as SkylightLabels,
} from '@/data/simulation/models/enclosure/Skylight';
import {
  SkylightTypeBackendDict,
  FIELD_LABELS as SkylightTypeLabels,
} from '@/data/simulation/models/enclosure/SkylightType';
import {
  WaterSystemBackendDict,
  FIELD_LABELS as WaterSystemLabels,
} from '@/data/simulation/models/systems/WaterSystem';
import {
  WindowBackendDict,
  FIELD_LABELS as WindowLabels,
} from '@/data/simulation/models/enclosure/Window';
import {
  WindowTypeBackendDict,
  FIELD_LABELS as WindowTypeLabels,
} from '@/data/simulation/models/enclosure/WindowType';
import {
  FIELD_LABELS as LocationLabels,
  LocationBackendDict,
} from '@/data/simulation/models/Location';
import {
  ProjectBackendDict,
  FIELD_LABELS as ProjectLabels,
} from '@/data/simulation/models/Project';
import {
  UtilityRateBackendDict,
  FIELD_LABELS as UtilityLabels,
} from '@/data/simulation/models/utility/UtilityRate';
import {
  SeasonalRateBackendDict,
  FIELD_LABELS as SeasonalRateLabels,
} from '@/data/simulation/models/utility/SeasonalRate';
import {
  BlockRateBackendDict,
  FIELD_LABELS as BlockRateLabels,
} from '@/data/simulation/models/utility/BlockRate';
import {
  InfiltrationBackendDict,
  FIELD_LABELS as InfiltrationLabels,
} from '@/data/simulation/models/enclosure/Infiltration';
import {
  NaturalVentilationBackendDict,
  FIELD_LABELS as NaturalVentilationLabels,
} from '@/data/simulation/models/systems/NaturalVentilation';
import { FieldLabels } from '@/data/simulation/models/base';
import {
  MechanicalVentilationBackendDict,
  FIELD_LABELS as MechanicalVentilationLabels,
} from '@/data/simulation/models/systems/MechanicalVentilation';

export const StateNameToFieldLabels: Record<
  keyof SimulationModelsState,
  FieldLabels<any>
> = {
  aboveGradeWall: AboveGradeWallLabels,
  aboveGradeWallType: AboveGradeWallTypeLabels,
  door: DoorLabels,
  doorType: DoorTypeLabels,
  foundationWall: FoundationWallLabels,
  foundationWallType: FoundationWallTypeLabels,
  frameFloor: FrameFloorLabels,
  frameFloorType: FrameFloorTypeLabels,
  mechanicalEquipment: MechanicalEquipmentLabels,
  airConditioner: AirConditionerLabels,
  airSourceHeatPump: AirSourceHeatPumpLabels,
  dehumidifier: DehumidifierLabels,
  groundSourceHeatPump: GroundSourceHeatPumpLabels,
  heater: HeaterLabels,
  waterHeater: WaterHeaterLabels,
  lights: LightsLabels,
  appliances: AppliancesLabels,
  distributionSystem: DistributionSystemLabels,
  duct: DuctLabels,
  thermostat: ThermostatLabels,
  photovoltaic: PhotovoltaicLabels,
  rimJoist: RimJoistLabels,
  rimJoistType: RimJoistTypeLabels,
  infiltration: InfiltrationLabels,
  roof: RoofLabels,
  roofType: RoofTypeLabels,
  slab: SlabLabels,
  slabType: SlabTypeLabels,
  skylight: SkylightLabels,
  skylightType: SkylightTypeLabels,
  simulation: SimulationLabels,
  waterSystem: WaterSystemLabels,
  window: WindowLabels,
  windowType: WindowTypeLabels,
  location: LocationLabels,
  project: ProjectLabels,
  utilityRate: UtilityLabels,
  seasonalRate: SeasonalRateLabels,
  blockRate: BlockRateLabels,
  naturalVentilation: NaturalVentilationLabels,
  mechanicalVentilation: MechanicalVentilationLabels,
  simulationConfig: {},
};

export interface StateNameToModel {
  aboveGradeWall: AboveGradeWallBackendDict;
  aboveGradeWallType: AboveGradeWallTypeBackendDict;
  door: DoorBackendDict;
  doorType: DoorTypeBackendDict;
  foundationWall: FoundationWallBackendDict;
  foundationWallType: FoundationWallTypeBackendDict;
  frameFloor: FrameFloorBackendDict;
  frameFloorType: FrameFloorTypeBackendDict;
  mechanicalEquipment: MechanicalEquipmentBackendDict;
  airConditioner: AirConditionerBackendDict;
  airSourceHeatPump: AirSourceHeatPumpBackendDict;
  dehumidifier: DehumidifierBackendDict;
  groundSourceHeatPump: GroundSourceHeatPumpBackendDict;
  heater: HeaterBackendDict;
  waterHeater: WaterHeaterBackendDict;
  lights: LightsBackendDict;
  appliances: AppliancesBackendDict;
  distributionSystem: DistributionSystemBackendDict;
  duct: DuctBackendDict;
  thermostat: ThermostatBackendDict;
  photovoltaic: PhotovoltaicBackendDict;
  rimJoist: RimJoistBackendDict;
  rimJoistType: RimJoistTypeBackendDict;
  infiltration: InfiltrationBackendDict;
  roof: RoofBackendDict;
  roofType: RoofTypeBackendDict;
  slab: SlabBackendDict;
  slabType: SlabTypeBackendDict;
  skylight: SkylightBackendDict;
  skylightType: SkylightTypeBackendDict;
  simulation: SimulationBackendDict;
  waterSystem: WaterSystemBackendDict;
  window: WindowBackendDict;
  windowType: WindowTypeBackendDict;
  location: LocationBackendDict;
  project: ProjectBackendDict;
  utilityRate: UtilityRateBackendDict;
  seasonalRate: SeasonalRateBackendDict;
  blockRate: BlockRateBackendDict;
  naturalVentilation: NaturalVentilationBackendDict;
  mechanicalVentilation: MechanicalVentilationBackendDict;
}

export const STATE_BACKEND_NAME: Record<
  Partial<keyof SimulationModelsState>,
  string
> = {
  aboveGradeWall: 'above_grade_wall',
  aboveGradeWallType: 'above_grade_wall_type',
  simulation: 'simulation',
  foundationWall: 'foundation_wall',
  foundationWallType: 'foundation_wall_type',
  roof: 'roof',
  roofType: 'roof_type',
  frameFloor: 'frame_floor',
  frameFloorType: 'frame_floor_type',
  rimJoist: 'rim_joist',
  rimJoistType: 'rim_joist_type',
  infiltration: 'infiltration',
  slab: 'slab',
  slabType: 'slab_type',
  skylight: 'skylight',
  skylightType: 'skylight_type',
  door: 'door',
  doorType: 'door_type',
  window: 'window',
  windowType: 'window_type',
  mechanicalEquipment: 'mechanical_equipment',
  airConditioner: 'air_conditioner',
  heater: 'heater',
  waterHeater: 'water_heater',
  airSourceHeatPump: 'air_source_heat_pump',
  dehumidifier: 'dehumidifier',
  groundSourceHeatPump: 'ground_source_heat_pump',
  appliances: 'appliances',
  lights: 'lights',
  distributionSystem: 'distribution_system',
  duct: 'duct',
  thermostat: 'thermostat',
  photovoltaic: 'photovoltaic',
  waterSystem: 'water_system',
  location: 'location',
  project: 'project',
  utilityRate: 'utility_rate',
  seasonalRate: 'seasonal_rate',
  blockRate: 'block_rate',
  naturalVentilation: 'natural_ventilation',
  mechanicalVentilation: 'mechanical_ventilation',
  simulationConfig: 'simulation_config',
};

export enum StateModelName {
  aboveGradeWall = 'aboveGradeWall',
  aboveGradeWallType = 'aboveGradeWallType',
  simulation = 'simulation',
  foundationWall = 'foundationWall',
  foundationWallType = 'foundationWallType',
  roof = 'roof',
  roofType = 'roofType',
  frameFloor = 'frameFloor',
  frameFloorType = 'frameFloorType',
  rimJoist = 'rimJoist',
  rimJoistType = 'rimJoistType',
  infiltration = 'infiltration',
  slab = 'slab',
  slabType = 'slabType',
  skylight = 'skylight',
  skylightType = 'skylightType',
  door = 'door',
  doorType = 'doorType',
  window = 'window',
  windowType = 'windowType',
  mechanicalEquipment = 'mechanicalEquipment',
  airConditioner = 'airConditioner',
  heater = 'heater',
  waterHeater = 'waterHeater',
  airSourceHeatPump = 'airSourceHeatPump',
  dehumidifier = 'dehumidifier',
  groundSourceHeatPump = 'groundSourceHeatPump',
  appliances = 'appliances',
  lights = 'lights',
  distributionSystem = 'distributionSystem',
  duct = 'duct',
  thermostat = 'thermostat',
  photovoltaic = 'photovoltaic',
  waterSystem = 'waterSystem',
  location = 'location',
  project = 'project',
  utilityRate = 'utilityRate',
  seasonalRate = 'seasonalRate',
  blockRate = 'blockRate',
  naturalVentilation = 'naturalVentilation',
  mechanicalVentilation = 'mechanicalVentilation',
  simulationConfig = 'simulationConfig',
}

export const StateModelNameLabels: Record<StateModelName, string> = {
  aboveGradeWall: 'Above Grade Wall',
  aboveGradeWallType: 'Above Grade Wall Type',
  simulation: 'Simulation',
  foundationWall: 'Foundation Wall',
  foundationWallType: 'Foundation Wall Type',
  roof: 'Roof',
  roofType: 'Roof Type',
  frameFloor: 'Frame Floor',
  frameFloorType: 'Frame Floor Type',
  rimJoist: 'Rim Joist',
  rimJoistType: 'Rim Joist Type',
  infiltration: 'Infiltration',
  slab: 'Slab',
  slabType: 'Slab Type',
  skylight: 'Skylight',
  skylightType: 'Skylight Type',
  door: 'Door',
  doorType: 'Door Type',
  window: 'Window',
  windowType: 'Window Type',
  mechanicalEquipment: 'Mechanical Equipment',
  airConditioner: 'Air Conditioner',
  heater: 'Heater',
  waterHeater: 'Water Heater',
  airSourceHeatPump: 'Air Source Heat Pump',
  dehumidifier: 'Dehumidifier',
  groundSourceHeatPump: 'Ground Source Heat Pump',
  appliances: 'Appliances',
  lights: 'Lights',
  distributionSystem: 'Distribution System',
  duct: 'Duct',
  thermostat: 'Thermostat',
  photovoltaic: 'Photovoltaic',
  waterSystem: 'Water System',
  location: 'Location',
  project: 'Project',
  utilityRate: 'Utility Rate',
  seasonalRate: 'Seasonal Rate',
  blockRate: 'Block Rate',
  naturalVentilation: 'Natural Ventilation',
  mechanicalVentilation: 'Mechanical Ventilation',
  simulationConfig: 'Simulation Config',
};

export const STATE_NAME_TO_EQUIPMENT_TYPE: Record<string, EquipmentType> = {
  [StateModelName.airConditioner]: EquipmentType.AIR_CONDITIONER,
  [StateModelName.airSourceHeatPump]: EquipmentType.AIR_SOURCE_HEAT_PUMP,
  [StateModelName.dehumidifier]: EquipmentType.DEHUMIDIFIER,
  [StateModelName.groundSourceHeatPump]: EquipmentType.GROUND_SOURCE_HEAT_PUMP,
  [StateModelName.heater]: EquipmentType.HEATER,
  [StateModelName.waterHeater]: EquipmentType.WATER_HEATER,
};
