import { createSelector } from '@ngrx/store';
import { SimulationState } from '../reducers';
import { selectSimulationFeature } from '../simulation.selectors';
import { AboveGradeWallBackendDict } from '@/data/simulation/models/enclosure/AboveGradeWall';

export const selectAboveGradeWall = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.aboveGradeWall.entities[id]
  );
const selectAboveGradeWallLoadingStatus = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.aboveGradeWall.loading[id]
  );

export const selectAboveGradeWallTypeId = (id: number) =>
  createSelector(
    selectAboveGradeWall(id),
    aboveGradeWall => aboveGradeWall.type
  );

export const selectAboveGradeWallViewSet = (id: number) =>
  createSelector(
    selectAboveGradeWall(id),
    selectAboveGradeWallLoadingStatus(id),
    (aboveGradeWall, loadingStatus) => ({
      data: aboveGradeWall,
      loading: loadingStatus,
    })
  );

export const selectAboveGradeWallName = (id: number) =>
  createSelector(
    selectAboveGradeWall(id),
    (aboveGradeWall: AboveGradeWallBackendDict) => aboveGradeWall.name
  );

export const selectAboveGradeWallChildModels = (id: number) =>
  createSelector(selectSimulationFeature, state => {
    const aboveGradeWall = state.aboveGradeWall.entities[id];
    return {
      aboveGradeWallType: aboveGradeWall.type,
    };
  });

export const selectAboveGradeWallNestedErrorCount = (id: number) =>
  createSelector(
    selectSimulationFeature,
    selectAboveGradeWallChildModels(id),
    (state, childModels) => {
      let wallTypeErrors = {
        errorCount: 0,
        warningCount: 0,
      };

      if (childModels.aboveGradeWallType !== null) {
        wallTypeErrors = {
          errorCount:
            state.aboveGradeWallType.errors[childModels.aboveGradeWallType]
              .errorCount,
          warningCount:
            state.aboveGradeWallType.errors[childModels.aboveGradeWallType]
              .warningCount,
        };
      }
      return {
        aboveGradeWall: {
          errorCount: state.aboveGradeWall.errors[id].errorCount,
          warningCount: state.aboveGradeWall.errors[id].warningCount,
        },
        aboveGradeWallType: wallTypeErrors,
      };
    }
  );

export const selectAboveGradeWallErrorViewSet = (id: number) =>
  createSelector(
    selectAboveGradeWallNestedErrorCount(id),
    selectAboveGradeWallTypeId(id),
    selectAboveGradeWallName(id),
    (nestedModelsErrorCount, wallTypeId, name) => {
      return {
        nestedModelsErrorCount,
        wallTypeId,
        name,
      };
    }
  );
