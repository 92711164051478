<div class="container">
    <button mat-icon-button matTooltip="Notes" (click)="openNotes()" *ngIf="requiredButtons.clone">
        <mat-icon inline="true">notes</mat-icon>
    </button>
    <div *appRequiresEditPermission class="d-flex right-shift">
        <button mat-icon-button (click)="delete.emit()" *ngIf="requiredButtons.delete">
            <mat-icon inline="true">delete</mat-icon>
        </button>
        <button mat-icon-button matTooltip="Make a copy" (click)="clone.emit()" *ngIf="requiredButtons.clone">
            <mat-icon inline="true">content_copy</mat-icon>
        </button>
        <app-history-mat-button displayType="iconButton" [objectId]="entityId" [modelClassName]="backendModelName">
            <mat-icon inline="true">history</mat-icon>
        </app-history-mat-button>
    </div>
</div>