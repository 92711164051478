<h2 mat-dialog-title>
  <ng-container *ngIf="!isEditForm">
    Create New <b>Client Agreement</b>
  </ng-container>
  <ng-container *ngIf="isEditForm">
    Edit <b>Client Agreement</b>
  </ng-container>
</h2>
<mat-dialog-content *ngIf="initialized">
  <form [formGroup]="form" class="mb-2">
    <ng-container *ngIf="isEditForm && (currentUser.company === entity.owner || currentUser.is_superuser)">
      <div class="row">
        <div class="col-6">
          <mat-form-field class="w-100">
            <mat-label>Start Date</mat-label>
            <input matInput type="text" placeholder="Start Date" formControlName="agreement_start_date" [matDatepicker]="startDatePicker">
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field class="w-100">
            <mat-label>Expiration Date</mat-label>
            <input matInput type="text" placeholder="Expiration Date" formControlName="agreement_expiration_date" [matDatepicker]="expirationDatePicker">
            <mat-datepicker-toggle matSuffix [for]="expirationDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #expirationDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12">
          <mat-divider></mat-divider>
        </div>
      </div>
    </ng-container>

    <div class="row">
      <div class="col-12">
        <h4>Mailing Information:</h4>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Mailing Address</mat-label>
          <app-address-input placeholder="Mailing Address" formControlName="mailing_address_geocode" [show-clear-btn]="false" required></app-address-input>
          <mat-error *ngIf="form.get('mailing_address_geocode').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Shipping/Physical Address</mat-label>
          <app-address-input placeholder="Shipping/Physical Address" formControlName="shipping_address_geocode" [show-clear-btn]="true"></app-address-input>
        </mat-form-field>
      </div>
    </div>


    <div class="row">
      <div class="col-12">
        <h4>Primary Contact:</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <mat-form-field class="w-100">
          <mat-label>First Name</mat-label>
          <input matInput type="text" placeholder="First Name" formControlName="primary_contact_first_name">
          <mat-error *ngIf="form.get('primary_contact_first_name').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="w-100">
          <mat-label>Last Name</mat-label>
          <input matInput type="text" placeholder="Last Name" formControlName="primary_contact_last_name">
          <mat-error *ngIf="form.get('primary_contact_last_name').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <mat-form-field class="w-100">
          <mat-label>Email Address</mat-label>
          <input matInput type="text" placeholder="Email Address" formControlName="primary_contact_email_address">
          <mat-error *ngIf="form.get('primary_contact_email_address').hasError('required')">
            This field is required
          </mat-error>
          <mat-error *ngIf="form.get('primary_contact_email_address').hasError('email')">
            Email Address is invalid
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="w-100">
          <mat-label>Phone Number</mat-label>
          <input matInput type="text" placeholder="Phone Number" formControlName="primary_contact_phone_number" mask="000-000-0000" [showMaskTyped]="true">
          <mat-error *ngIf="form.get('primary_contact_phone_number').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <mat-form-field class="w-100">
          <mat-label>Title</mat-label>
          <input matInput type="text" placeholder="Title" formControlName="primary_contact_title">
          <mat-error *ngIf="form.get('primary_contact_title').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="w-100">
          <mat-label>Cell Number</mat-label>
          <input matInput type="text" placeholder="Cell Number" formControlName="primary_contact_cell_number">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <mat-form-field class="w-100">
          <mat-label>Website:</mat-label>
          <input matInput type="text" placeholder="Website" formControlName="website">
          <mat-error *ngIf="form.get('website').hasError('websiteURL')">
            Enter valid website URL
          </mat-error>
        </mat-form-field>
      </div>
    </div>


    <div class="row">
      <div class="col-12">
        <h4>Secondary Contact:</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <mat-form-field class="w-100">
          <mat-label>First Name</mat-label>
          <input matInput type="text" placeholder="First Name" formControlName="secondary_contact_first_name">
          <mat-error *ngIf="form.get('secondary_contact_first_name').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="w-100">
          <mat-label>Last Name</mat-label>
          <input matInput type="text" placeholder="Last Name" formControlName="secondary_contact_last_name">
          <mat-error *ngIf="form.get('secondary_contact_last_name').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <mat-form-field class="w-100">
          <mat-label>Email Address</mat-label>
          <input matInput type="text" placeholder="Email Address" formControlName="secondary_contact_email_address">
          <mat-error *ngIf="form.get('secondary_contact_email_address').hasError('required')">
            This field is required
          </mat-error>
          <mat-error *ngIf="form.get('secondary_contact_email_address').hasError('email')">
            Email Address is invalid
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="w-100">
          <mat-label>Phone Number</mat-label>
          <input matInput type="text" placeholder="Phone Number" formControlName="secondary_contact_phone_number" mask="000-000-0000" [showMaskTyped]="true">
          <mat-error *ngIf="form.get('secondary_contact_phone_number').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <mat-form-field class="w-100">
          <mat-label>Title</mat-label>
          <input matInput type="text" placeholder="Title" formControlName="secondary_contact_title">
          <mat-error *ngIf="form.get('secondary_contact_title').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="w-100">
          <mat-label>Cell Number</mat-label>
          <input matInput type="text" placeholder="Cell Number" formControlName="secondary_contact_cell_number">
        </mat-form-field>
      </div>
    </div>


    <div class="row">
      <div class="col-12">
        <h4>Person Responsible for Payment Contact:</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <mat-form-field class="w-100">
          <mat-label>First Name</mat-label>
          <input matInput type="text" placeholder="First Name" formControlName="payment_contact_first_name">
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="w-100">
          <mat-label>Last Name</mat-label>
          <input matInput type="text" placeholder="Last Name" formControlName="payment_contact_last_name">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <mat-form-field class="w-100">
          <mat-label>Email Address</mat-label>
          <input matInput type="text" placeholder="Email Address" formControlName="payment_contact_email_address">
          <mat-error *ngIf="form.get('payment_contact_email_address').hasError('email')">
            Email Address is invalid
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="w-100">
          <mat-label>Phone Number</mat-label>
          <input matInput type="text" placeholder="Phone Number" formControlName="payment_contact_phone_number" mask="000-000-0000" [showMaskTyped]="true">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <mat-form-field class="w-100">
          <mat-label>Title</mat-label>
          <input matInput type="text" placeholder="Title" formControlName="payment_contact_title">
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="w-100">
          <mat-label>Cell Number</mat-label>
          <input matInput type="text" placeholder="Cell Number" formControlName="payment_contact_cell_number">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-checkbox formControlName="use_payment_contact_in_ngbs_green_projects">
          Should this person be contact for payment on future NGBS Green Projects?
        </mat-checkbox>
      </div>
    </div>


    <div class="row">
      <div class="col-12">
        <h4>Marketing Contact:</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <mat-form-field class="w-100">
          <mat-label>First Name</mat-label>
          <input matInput type="text" placeholder="First Name" formControlName="marketing_contact_first_name">
          <mat-error *ngIf="form.get('marketing_contact_first_name').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="w-100">
          <mat-label>Last Name</mat-label>
          <input matInput type="text" placeholder="Last Name" formControlName="marketing_contact_last_name">
          <mat-error *ngIf="form.get('marketing_contact_last_name').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <mat-form-field class="w-100">
          <mat-label>Email Address</mat-label>
          <input matInput type="text" placeholder="Email Address" formControlName="marketing_contact_email_address">
          <mat-error *ngIf="form.get('marketing_contact_email_address').hasError('required')">
            This field is required
          </mat-error>
          <mat-error *ngIf="form.get('marketing_contact_email_address').hasError('email')">
            Email Address is invalid
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="w-100">
          <mat-label>Phone Number</mat-label>
          <input matInput type="text" placeholder="Phone Number" formControlName="marketing_contact_phone_number" mask="000-000-0000" [showMaskTyped]="true">
          <mat-error *ngIf="form.get('marketing_contact_phone_number').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <mat-form-field class="w-100">
          <mat-label>Title</mat-label>
          <input matInput type="text" placeholder="Title" formControlName="marketing_contact_title">
          <mat-error *ngIf="form.get('marketing_contact_title').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="w-100">
          <mat-label>Cell Number</mat-label>
          <input matInput type="text" placeholder="Cell Number" formControlName="marketing_contact_cell_number">
        </mat-form-field>
      </div>
    </div>


    <div class="row">
      <div class="col-12">
        <h4>Website Contact:</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <mat-form-field class="w-100">
          <mat-label>First Name</mat-label>
          <input matInput type="text" placeholder="First Name" formControlName="website_contact_first_name">
          <mat-error *ngIf="form.get('website_contact_first_name').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="w-100">
          <mat-label>Last Name</mat-label>
          <input matInput type="text" placeholder="Last Name" formControlName="website_contact_last_name">
          <mat-error *ngIf="form.get('website_contact_last_name').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <mat-form-field class="w-100">
          <mat-label>Email Address</mat-label>
          <input matInput type="text" placeholder="Email Address" formControlName="website_contact_email_address">
          <mat-error *ngIf="form.get('website_contact_email_address').hasError('required')">
            This field is required
          </mat-error>
          <mat-error *ngIf="form.get('website_contact_email_address').hasError('email')">
            Email Address is invalid
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="w-100">
          <mat-label>Phone Number</mat-label>
          <input matInput type="text" placeholder="Phone Number" formControlName="website_contact_phone_number" mask="000-000-0000" [showMaskTyped]="true">
          <mat-error *ngIf="form.get('website_contact_phone_number').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <mat-form-field class="w-100">
          <mat-label>Title</mat-label>
          <input matInput type="text" placeholder="Title" formControlName="website_contact_title">
          <mat-error *ngIf="form.get('website_contact_title').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="w-100">
          <mat-label>Cell Number</mat-label>
          <input matInput type="text" placeholder="Cell Number" formControlName="website_contact_cell_number">
        </mat-form-field>
      </div>
    </div>
  </form>
  <div class="row">
    <div class="col-12">
      <h3>Preview of Legal Agreement (electronic signature will be required via DocuSign):</h3>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <iframe style="width: 100%; height: 25rem"
              src="assets/pages/hirl-client-agreement-detail/builder_embedded_agreement_v4.pdf"></iframe>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      By clicking "Submit for Review and Approval by NGBS" you are acknowledging that your
      company agrees to the legal terms and conditions as stated above. Once you click
      “Submit for Review and Approval by NGBS”, a legal agreement will be emailed to the
      Primary Contact specified above for signature. This legal agreement must be signed,
      dated, and returned prior to participation in an NGBS program. If you do not agree to
      the terms and conditions as stated above, or if you have any questions prior to
      proceeding,
      you may contact the NGBS Program Staff by emailing
      <a href="mailto:verificationreport@homeinnovation.com">verificationreport@homeinnovation.com</a>.
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <a href="assets/pages/hirl-client-agreement-detail/builder_embedded_agreement_v4.pdf">Click here to download the
        sample PDF.</a>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button color="accent" mat-raised-button (click)="save($event)">
    <ng-container *ngIf="isEditForm">Save</ng-container>
    <ng-container *ngIf="!isEditForm">Submit for Review and Approval by NGBS</ng-container>
  </button>
  <button mat-button [mat-dialog-close]="null" color="primary" mat-raised-button>Cancel</button>
</mat-dialog-actions>
