import { ListRequestParams } from '@/core/schemes/request-params';
import { Injectable } from '@angular/core';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { ServerResponse } from '@/core/schemes/server-response';
import { HirlMarketingRole } from '@/data/company/models/';

export class HIRLMarketingRoleRequestParams extends ListRequestParams {}

@Injectable({
  providedIn: 'root',
})
export class HirlMarketingRoleService extends BaseDataService {
  baseUrl = `${environment.AXIS_API_V3_ROOT}/hirl_marketing_roles/`;

  list(
    params?: HIRLMarketingRoleRequestParams
  ): Observable<ServerResponse<HirlMarketingRole>> {
    return this.http.get<ServerResponse<HirlMarketingRole>>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }

  retrieve(
    hirlMarketingRoleId: number,
    options?: IDataServiceMethodOptions
  ): Observable<HirlMarketingRole> {
    const ignoreStore = options?.ignoreStore;
    const chain = this.http.get<HirlMarketingRole>(
      `${this.baseUrl}${hirlMarketingRoleId}`
    );
    return chain;
  }
}
