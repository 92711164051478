import {
  AirConditionerBackendDict,
  AirConditionerForm,
} from '@/data/simulation/models/mechanicals/AirConditioner';
import { BaseValidator } from './base.validator';
import { ModelErrors, StateErrors } from '../state/shared/base.state';

export class AirConditionerValidator extends BaseValidator {
  static validateForm(
    airConditioner: AirConditionerBackendDict
  ): ModelErrors<AirConditionerBackendDict> {
    const airConditionerForm = new AirConditionerForm(airConditioner);
    return airConditionerForm.getFieldAndModelErros();
  }

  static validate(
    airConditioners: AirConditionerBackendDict[]
  ): StateErrors<AirConditionerBackendDict> {
    const errors = {};
    airConditioners.forEach(airConditioner => {
      errors[airConditioner.id] = this.validateForm(airConditioner);
    });
    return errors;
  }
}
