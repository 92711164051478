import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { filter, first, takeUntil } from 'rxjs/operators';
import { toggleLoading } from '@/state/actions/app.actions';
import { MatTabChangeEvent } from '@angular/material/tabs';
import {
  ApexRequest,
  ApexRequestDocument,
  ApexRequestEnergyModelTypeLabelMapping,
  ApexRequestTypeLabelMapping,
} from '@/data/home/models';
import { ApexRequestService } from '@/data/home/services/apex_request.service';
import { resetApexRequest } from '@/state/actions/home/apex-request.actions';
import {
  getApexRequest,
  getApexRequestDocuments,
  getApexRequestDocumentsCount,
} from '@/state/selectors/home/apex-request.selector';

@Component({
  selector: 'app-apex-request-detail',
  templateUrl: './apex-request-detail.component.html',
  styleUrls: ['./apex-request-detail.component.scss'],
})
export class ApexRequestDetailComponent implements OnInit, OnDestroy {
  protected readonly ApexRequestEnergyModelTypeLabelMapping =
    ApexRequestEnergyModelTypeLabelMapping;
  protected readonly ApexRequestTypeLabelMapping = ApexRequestTypeLabelMapping;

  initialized = false;

  selectedIndex = 0;
  apexRequestDocumentsCount = -1; // means that is not loading yet
  apexRequest: ApexRequest;

  public editPermissionResponse: Observable<ObjectPermissionResponse>;
  public deletePermissionResponse: Observable<ObjectPermissionResponse>;
  public downloadSummaryResultPermissionResponse: Observable<ObjectPermissionResponse> =
    of(
      new ObjectPermissionResponse(
        false,
        `Wait for all results to be completed`
      )
    );

  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    public injector: Injector,
    public dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private apexRequestService: ApexRequestService,
    public uiHelperService: UIHelperService
  ) {
    this.store
      .select(getApexRequest)
      .pipe(
        filter(val => val !== null),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(apexRequest => {
        this.apexRequest = apexRequest;
      });

    this.store
      .select(getApexRequestDocuments)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((apexRequestDocuments: ApexRequestDocument[]) => {
        if (apexRequestDocuments.length) {
          const allFinished = apexRequestDocuments.every(
            apexRequestDocument => {
              return (
                apexRequestDocument?.asynchronous_process_document_info
                  ?.final_status === 'SUCCESS' ||
                apexRequestDocument?.asynchronous_process_document_info
                  ?.final_status === 'FAILURE'
              );
            }
          );
          if (allFinished) {
            this.downloadSummaryResultPermissionResponse = of(
              new ObjectPermissionResponse(true)
            );
          }
        }
      });

    this.store
      .select(getApexRequestDocumentsCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(count => {
        this.apexRequestDocumentsCount = count;
      });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(params => {
        if (params.tab) {
          this.selectedIndex = Number(params.tab) || 0;
        }
      });

    this.activatedRoute.params
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(params => {
        this.store.dispatch(toggleLoading({ payload: true }));
        this.apexRequestService
          .retrieve(params.apexRequestId)
          .pipe(takeUntil(this.componentDestroyed$), first())
          .subscribe(
            apexRequest => {
              this.apexRequest = apexRequest;
              this.store.dispatch(toggleLoading({ payload: false }));
              this.initialized = true;
            },
            error => this.uiHelperService.handleMainRequestError(error)
          );
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
    this.store.dispatch(resetApexRequest());
  }

  onTabChange(event: MatTabChangeEvent) {
    const url = this.router
      .createUrlTree([], {
        relativeTo: this.activatedRoute,
        queryParams: { tab: event.index },
      })
      .toString();
    this.location.replaceState(url);
  }

  onDownloadSummary($event: MouseEvent) {
    $event.preventDefault();
    this.store.dispatch(toggleLoading({ payload: true }));
    this.apexRequestService
      .summary_result(this.apexRequest.id)
      .pipe(first())
      .subscribe(
        data => {
          this.store.dispatch(toggleLoading({ payload: false }));
          const downloadURL = window.URL.createObjectURL(data);
          const link = document.createElement('a');
          link.href = downloadURL;
          link.download = `apex_request_result_${this.apexRequest.id}.xlsx`;
          link.click();
        },
        error => this.uiHelperService.handleUserRequestError(error)
      );
  }
}
