import { GroundSourceHeatPumpBackendDict } from '@/data/simulation/models/mechanicals/GroundSourceHeatPump';
import { createAction, props } from '@ngrx/store';
import { StateErrors } from '../shared/base.state';

export const loadGroundSourceHeatPumps = createAction(
  '[GroundSourceHeatPump] Load GroundSourceHeatPumps',
  props<{ groundSourceHeatPumps: GroundSourceHeatPumpBackendDict[] }>()
);

export const loadGroundSourceHeatPumpsSuccess = createAction(
  '[GroundSourceHeatPump] Load GroundSourceHeatPumps Success',
  props<{
    groundSourceHeatPumps: GroundSourceHeatPumpBackendDict[];
    errors: StateErrors<GroundSourceHeatPumpBackendDict>;
  }>()
);

export const loadGroundSourceHeatPumpsFailure = createAction(
  '[GroundSourceHeatPump] Load GroundSourceHeatPumps Failure',
  props<{ error: any }>()
);

export const updateGroundSourceHeatPump = createAction(
  '[GroundSourceHeatPump] Update GroundSourceHeatPump',
  props<{
    groundSourceHeatPumpChanges: Partial<GroundSourceHeatPumpBackendDict>;
  }>()
);

export const updateGroundSourceHeatPumpFailure = createAction(
  '[GroundSourceHeatPump API] Update GroundSourceHeatPump Failure',
  props<{ id: number }>()
);
