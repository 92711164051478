import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { first, take, takeUntil } from 'rxjs/operators';

import {
  AggregateESGReport,
  EEPProgramHomeStatusProjectReportDownload,
  EEPProgramHomeStatusProjectReportExportField,
  ESGReportList,
} from '@/data/home/models';
import { toggleLoading } from '@/state/actions/app.actions';

import { PsrListComponent } from '@/modules/home/pages/psr-list/psr-list.component';
import { EEPProgramHomeStatusRequestParams } from '@/data/home/services/eep_program_home_status.service';

@Component({
  selector: 'app-esg-report',
  templateUrl: './esg-report.component.html',
  styleUrls: ['./esg-report.component.scss'],
})
export class EsgReportComponent extends PsrListComponent<ESGReportList> {
  public displayedColumns = [
    'home__street_line1',
    'home__subdivision__name',
    'floorplan__name',
    'eep_program__name',
    'state',
    'eri_score',
    'co2',
    'co2_savings',
  ];

  public getDataSource = (params: EEPProgramHomeStatusRequestParams) =>
    this.eepProgramHomeStatusService.esg_report_list(params);

  public aggregateESGReportIsLoading = true;
  public aggregateESGReport: AggregateESGReport;
  public aggregateSubscription$: Subscription;

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();

    if (this.aggregateSubscription$) {
      this.aggregateSubscription$.unsubscribe();
    }
  }

  onListChangeStarted() {
    if (this.aggregateSubscription$) {
      this.aggregateSubscription$.unsubscribe();
    }

    this.aggregateSubscription$ = this.eepProgramHomeStatusService
      .aggregate_esg_report(this.storedParams)
      .pipe(takeUntil(this.componentDestroyed$), take(1))
      .subscribe(aggregateESGReport => {
        this.aggregateESGReport = aggregateESGReport;
        this.aggregateESGReportIsLoading = false;
      });
  }

  downloadReport($event: MouseEvent) {
    $event.preventDefault();
    this.store.dispatch(toggleLoading({ payload: true }));

    const projectReportDownload =
      new EEPProgramHomeStatusProjectReportDownload();
    projectReportDownload.export_fields = [
      EEPProgramHomeStatusProjectReportExportField.home,
      EEPProgramHomeStatusProjectReportExportField.subdivision,
      EEPProgramHomeStatusProjectReportExportField.eep_program,
      EEPProgramHomeStatusProjectReportExportField.simulation_basic,
      EEPProgramHomeStatusProjectReportExportField.simulation_advanced,
      EEPProgramHomeStatusProjectReportExportField.retain_empty,
    ];
    projectReportDownload.export_filters =
      this.filterComponent.getAppliedFilters();

    const newTab = window.open('', '_blank');
    this.eepProgramHomeStatusService
      .esg_project_report_download(projectReportDownload, this.storedParams)
      .pipe(first())
      .subscribe(
        asynchronousProcessedDocument => {
          newTab.location.href = `/file-operation/document/${asynchronousProcessedDocument.id}`;
          newTab.focus();
          this.store.dispatch(toggleLoading({ payload: false }));
        },
        error => {
          newTab.close();
          this.store.dispatch(toggleLoading({ payload: false }));
          this.uiHelperService.handleUserRequestError(error);
        }
      );
  }
}
