<div class="container" *ngIf="initialized">
  <div class="row">
    <div class="col-12">
      <h5>
        <button mat-raised-button appBackButton color="accent">
          <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
        </button>
        <span *ngIf="!editForm">
          New Land Development Project Registration
        </span>
        <span *ngIf="editForm">
          Edit Land Development
          <b>Project Registration {{ hirlProjectRegistration.id }}</b>
        </span>
      </h5>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <mat-vertical-stepper
        [linear]="!editForm"
        (selectionChange)="onStepChange($event)"
        #stepper
      >
        <mat-step [stepControl]="projectInformationFormGroup">
          <form [formGroup]="projectInformationFormGroup">
            <ng-template matStepLabel>Project Information</ng-template>
            <div class="row mt-5">
              <div class="col-12">
                <mat-form-field appearance="outline" class="w-100 mb-2">
                  <mat-label>NGBS Standard Version and Scoring Path</mat-label>
                  <app-generic-mat-select
                    formControlName="eep_program"
                    modelClassName="eep_program"
                    [filterParams]="{ set: 'customer_hirl_land_development' }"
                    required
                  ></app-generic-mat-select>
                  <mat-error
                    *ngIf="
                      projectInformationFormGroup.controls[
                        'eep_program'
                      ].hasError('required')
                    "
                  >
                    NGBS Standard Version and Scoring Path is required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <mat-form-field appearance="outline" class="w-100 mb-2">
                  <mat-label>Land Development Name</mat-label>
                  <input
                    matInput
                    placeholder="Land Development Name"
                    formControlName="project_name"
                    required
                  />
                  <mat-error
                    *ngIf="
                      projectInformationFormGroup.controls[
                        'project_name'
                      ].hasError('required')
                    "
                  >
                    This field is required
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-form-field appearance="outline">
                  <mat-label>Estimated Completion Date</mat-label>
                  <app-month-mat-picker
                    formControlName="estimated_completion_date"
                    [minDate]="estimatedCompletionDateMinDate"
                    required
                  ></app-month-mat-picker>
                  <mat-error
                    *ngIf="
                      projectInformationFormGroup.controls[
                        'estimated_completion_date'
                      ].hasError('required')
                    "
                  >
                    This field is required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <mat-form-field appearance="outline" class="w-100 mb-2">
                  <mat-label>Description</mat-label>
                  <textarea
                    matInput
                    placeholder="Description"
                    formControlName="project_description"
                  ></textarea>
                  <mat-error
                    *ngIf="
                      projectInformationFormGroup.controls[
                        'project_description'
                      ].hasError('required')
                    "
                  >
                    This field is required
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-form-field appearance="outline" class="w-100 mb-2">
                  <mat-label>Website URL</mat-label>
                  <input
                    matInput
                    placeholder="Website URL"
                    formControlName="project_website_url"
                    required
                  />
                  <mat-error
                    *ngIf="
                      projectInformationFormGroup.controls[
                        'project_website_url'
                      ].hasError('required')
                    "
                  >
                    This field is required
                  </mat-error>
                  <mat-error
                    *ngIf="
                      projectInformationFormGroup.controls[
                        'project_website_url'
                      ].hasError('websiteURL')
                    "
                  >
                    Incorrect url format. Example: www.example.com or
                    https://www.example.com
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-12 text-center">
                <button matStepperNext mat-raised-button color="accent">
                  Next
                </button>
              </div>
            </div>
          </form>
        </mat-step>
        <mat-step
          [stepControl]="projectTypeFormGroup"
          [optional]="false"
          *ngIf="!editForm"
        >
          <form [formGroup]="projectTypeFormGroup">
            <ng-template matStepLabel>Project Type</ng-template>
            <div class="row mt-5">
              <div class="col-6">
                <mat-form-field appearance="outline" class="w-100 mb-2">
                  <mat-label>Project Type</mat-label>
                  <mat-select
                    aria-label="Project Type"
                    formControlName="ld_workflow"
                    required
                  >
                    <mat-option
                      *ngFor="
                        let item of hirlProjectLDWorkflowTypeMapping
                          | defaultOrderKeyValue
                      "
                      [value]="item.key"
                      >{{ item.value }}</mat-option
                    >
                  </mat-select>
                  <mat-error
                    *ngIf="
                      projectTypeFormGroup.controls['ld_workflow'].hasError(
                        'required'
                      )
                    "
                  >
                    Client is required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-12">
                <div>
                  <p>
                    <mat-label>Will this development be phased?</mat-label>
                  </p>
                  <mat-radio-group
                    aria-label="Will this development be phased?"
                    formControlName="is_phased_development"
                  >
                    <mat-radio-button [value]="true" class="mr-5">
                      Yes
                    </mat-radio-button>
                    <mat-radio-button [value]="false"> No </mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
            </div>
            <div
              class="row"
              *ngIf="projectTypeFormGroup.get('is_phased_development').value"
            >
              <div class="col-12">
                <p>
                  <b>Note:</b> All phases must be registered at the same time as
                  part of this registration.
                </p>
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-12 text-center">
                <button matStepperPrevious mat-raised-button class="mr-2">
                  Back
                </button>
                <button mat-raised-button color="accent" matStepperNext>
                  Next
                </button>
              </div>
            </div>
          </form>
        </mat-step>

        <mat-step
          [stepControl]="buildingFormGroup"
          [optional]="false"
          *ngIf="!editForm"
        >
          <form [formGroup]="buildingFormGroup">
            <ng-template matStepLabel> Land Development </ng-template>

            <div
              class="row mt-2"
              #letterFieldsContainer
              *ngIf="
                projectTypeFormGroup.get('ld_workflow').value ===
                projectRegistrationLDWorkflowType.letterOfApprovalAndFullCertification
              "
            >
              <div class="col-12">
                <div class="card">
                  <div class="card-header">
                    Letter Of Approval
                    <fa-icon
                      [icon]="['fas', 'question-circle']"
                      matTooltip="You see this because you selected 'Letter of Approval and Full Certification'"
                    ></fa-icon>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12">
                        <mat-form-field appearance="outline" class="w-100 mb-2">
                          <mat-label>Address</mat-label>
                          <app-home-address-input
                            placeholder="Address"
                            formControlName="letter_address"
                            required
                            [show-clear-btn]="true"
                          ></app-home-address-input>
                          <mat-error
                            *ngIf="
                              buildingFormGroup
                                .get('letter_address')
                                .hasError('required')
                            "
                          >
                            This field is required
                          </mat-error>
                          <mat-error
                            *ngIf="
                              buildingFormGroup
                                .get('letter_address')
                                .hasError('hirlProjectAlreadyExists')
                            "
                          >
                            Project with this address already exists
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-12">
                <div class="card">
                  <div class="card-header">
                    {{
                      projectTypeFormGroup.get("is_phased_development").value
                        ? "Phase #1"
                        : "Land Development"
                    }}
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12">
                        <mat-form-field appearance="outline" class="w-100 mb-2">
                          <mat-label>Address</mat-label>
                          <app-home-address-input
                            placeholder="Address"
                            formControlName="address"
                            [show-clear-btn]="true"
                            required
                          ></app-home-address-input>
                          <mat-error
                            *ngIf="
                              buildingFormGroup
                                .get('address')
                                .hasError('required')
                            "
                          >
                            This field is required
                          </mat-error>
                          <mat-error
                            *ngIf="
                              buildingFormGroup
                                .get('address')
                                .hasError('hirlProjectAlreadyExists')
                            "
                          >
                            Project with this address already exists
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <mat-form-field appearance="outline" class="w-100 mb-2">
                          <mat-label>Number of Lots</mat-label>
                          <input
                            matInput
                            type="number"
                            placeholder="Number of Lots"
                            formControlName="number_of_lots"
                          />
                          <mat-error
                            *ngIf="
                              buildingFormGroup
                                .get('number_of_lots')
                                .hasError('required')
                            "
                          >
                            This field is required
                          </mat-error>
                          <mat-error
                            *ngIf="
                              buildingFormGroup
                                .get('number_of_lots')
                                .hasError('min') ||
                              buildingFormGroup
                                .get('number_of_lots')
                                .hasError('max')
                            "
                          >
                            Value must be between 0 and 1000000
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>

                    <div class="row mt-1">
                      <div class="col-12">
                        <div>
                          <p>
                            <mat-label
                              >Are 100% of eligible buildings in the development
                              seeking NGBS Green Certification?</mat-label
                            >
                          </p>
                          <mat-radio-group
                            aria-label="Are 100% of eligible buildings in the development seeking NGBS Green Certification?"
                            formControlName="are_all_homes_in_ld_seeking_certification"
                          >
                            <mat-radio-button [value]="true" class="mr-5">
                              Yes
                            </mat-radio-button>
                            <mat-radio-button [value]="false">
                              No
                            </mat-radio-button>
                          </mat-radio-group>
                        </div>
                      </div>
                    </div>
                    <div
                      class="row"
                      *ngIf="
                        buildingFormGroup.get(
                          'are_all_homes_in_ld_seeking_certification'
                        ).value
                      "
                    >
                      <div class="col-12">
                        <p>
                          <b>Note:</b> Deed covenant or other substantiating
                          evidence must be uploaded with verification report
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="row mt-2"
              *ngIf="projectTypeFormGroup.get('is_phased_development').value"
            >
              <div class="col-12">
                <div formArrayName="phases">
                  <div
                    class="card mb-2"
                    *ngFor="
                      let phaseControl of getPhasesControls();
                      let i = index
                    "
                  >
                    <div class="card-header">
                      <div class="row">
                        <div class="col-11">
                          <!-- Our first phase is defined above, that's why we have +2 to display-->
                          Phase #{{ i + 2 }}
                        </div>
                        <div class="col-1 text-right">
                          <button
                            mat-button
                            mat-icon-button
                            (click)="onRemovePhase($event, i)"
                          >
                            <mat-icon>close</mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="card-body" [formGroupName]="i">
                      <div class="row">
                        <div class="col-12">
                          <mat-form-field
                            appearance="outline"
                            class="w-100 mb-2"
                          >
                            <mat-label>Address</mat-label>
                            <app-home-address-input
                              placeholder="Address"
                              formControlName="address"
                              required
                              [show-clear-btn]="true"
                            ></app-home-address-input>
                            <mat-error
                              *ngIf="
                                phaseControl.get('address').hasError('required')
                              "
                            >
                              This field is required
                            </mat-error>
                            <mat-error
                              *ngIf="
                                phaseControl
                                  .get('address')
                                  .hasError('hirlProjectAlreadyExists')
                              "
                            >
                              Project with this address already exists
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <mat-form-field
                            appearance="outline"
                            class="w-100 mb-2"
                          >
                            <mat-label>Number of Lots</mat-label>
                            <input
                              matInput
                              type="number"
                              placeholder="Number of Lots"
                              formControlName="number_of_lots"
                              required
                            />
                            <mat-error
                              *ngIf="
                                phaseControl
                                  .get('number_of_lots')
                                  .hasError('required')
                              "
                            >
                              This field is required
                            </mat-error>
                            <mat-error
                              *ngIf="
                                phaseControl
                                  .get('number_of_lots')
                                  .hasError('min') ||
                                phaseControl
                                  .get('number_of_lots')
                                  .hasError('max')
                              "
                            >
                              Value must be between 0 and 1000000
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row mt-1">
                        <div class="col-12">
                          <div>
                            <p>
                              <mat-label
                                >Are 100% of eligible buildings in the
                                development seeking NGBS Green
                                Certification?</mat-label
                              >
                            </p>
                            <mat-radio-group
                              aria-label="Are 100% of eligible buildings in the development seeking NGBS Green Certification?"
                              formControlName="are_all_homes_in_ld_seeking_certification"
                            >
                              <mat-radio-button [value]="true" class="mr-5">
                                Yes
                              </mat-radio-button>
                              <mat-radio-button [value]="false">
                                No
                              </mat-radio-button>
                            </mat-radio-group>
                          </div>
                        </div>
                      </div>
                      <div
                        class="row"
                        *ngIf="
                          phaseControl.get(
                            'are_all_homes_in_ld_seeking_certification'
                          ).value
                        "
                      >
                        <div class="col-12">
                          <p>
                            <b>Note:</b> Deed covenant or other substantiating
                            evidence must be uploaded with verification report
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12"></div>
                </div>
                <div class="row">
                  <div class="col-12 text-center">
                    <button mat-raised-button (click)="addPhase()">
                      Add Phase
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="row"
              *ngIf="
                buildingFormGroup.hasError('uniqueConfirmedGeocodeResponse')
              "
            >
              <div class="col-12 text-center">
                <mat-error>
                  Provided addresses for Projects is not unique
                </mat-error>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-12 text-center">
                <button matStepperPrevious mat-raised-button class="mr-2">
                  Back
                </button>
                <button
                  matStepperNext
                  mat-raised-button
                  color="accent"
                  [disabled]="buildingFormGroup.pending"
                >
                  Next
                  <fa-icon
                    [icon]="['fas', 'spinner']"
                    [spin]="true"
                    *ngIf="buildingFormGroup.pending"
                  ></fa-icon>
                </button>
              </div>
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="developerFormGroup">
          <form [formGroup]="developerFormGroup">
            <ng-template matStepLabel>Project Client</ng-template>
            <div class="row">
              <div class="col-12">
                <mat-form-field appearance="outline" class="w-100 mb-2">
                  <mat-label>Client Company</mat-label>
                  <app-generic-model-autocomplete
                    modelClassName="company"
                    [initialValueId]="
                      hirlProjectRegistration?.builder_organization
                    "
                    [filterParams]="{ company_type: 'builder' }"
                    [optionDisplay]="clientCompanyDisplay"
                    formControlName="builder_organization_info"
                    required
                  ></app-generic-model-autocomplete>
                  <mat-error
                    *ngIf="
                      developerFormGroup
                        .get('builder_organization_info')
                        .hasError('required')
                    "
                  >
                    This field is required
                  </mat-error>
                </mat-form-field>
                <div class="row mb-3" *ngIf="developerFormGroup.get('builder_organization_info').value && developerFormGroup.get('builder_organization_info').value.active_customer_hirl_builder_agreements_count === 0">
                  <div class="col-12">
                    <b>
                      Client company does not have an active Client Agreement.
                      <a href="#" [routerLink]="['/', 'hi', 'client_agreements', 'builder', 'create_without_user']" target="_blank">
                        Click here to initiate a new Client Agreement for {{ developerFormGroup.get('builder_organization_info').value.name }}
                      </a>
                    </b>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row"
              *ngIf="developerFormGroup.get('builder_organization_info').value"
            >
              <div class="col-12">
                <mat-form-field appearance="outline" class="w-100 mb-2">
                  <mat-label>Contact</mat-label>
                  <app-contact-card-select
                    formControlName="builder_organization_contact"
                    [filterParams]="{
                      company_and_related_users: developerFormGroup.get(
                        'builder_organization_info'
                      ).value.id
                    }"
                    [contactCardInitialData]="{
                      company: developerFormGroup.get(
                        'builder_organization_info'
                      ).value.id
                    }"
                  ></app-contact-card-select>
                  <mat-error
                    *ngIf="
                      developerFormGroup
                        .get('builder_organization_contact')
                        .hasError('required')
                    "
                  >
                    This field is required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-12 text-center">
                <button matStepperPrevious mat-raised-button class="mr-2">
                  Back
                </button>
                <button matStepperNext mat-raised-button color="accent">
                  Next
                </button>
              </div>
            </div>
          </form>
        </mat-step>

        <mat-step [stepControl]="certificateNamesFormGroup">
          <form [formGroup]="certificateNamesFormGroup">
            <ng-template matStepLabel
              >Certificate Customization Options</ng-template
            >
            <div class="row mt-5">
              <div class="col-3">
                <div
                  class="row"
                  *ngFor="let index of [1, 2, 3, 4, 5, 6, 7, 8, 9]"
                >
                  <div
                    [ngClass]="
                      certificateNamesFormGroup.get('certificate_line' + index)
                        .value === certificateLineChoices.customText || certificateNamesFormGroup.get('certificate_line' + index)
                        .value === certificateLineChoices.customTextGreen
                        ? 'col-4'
                        : 'col-12'
                    "
                  >
                    <mat-form-field class="w-100">
                      <mat-label>Line {{ index }}</mat-label>
                      <mat-select
                        [formControlName]="'certificate_line' + index"
                        panelClass="certificate-line-panel-override"
                      >
                        <mat-option
                          *ngFor="
                            let item of certificateLineChoicesMapping
                              | defaultOrderKeyValue
                          "
                          [value]="item.key"
                          >{{ item.value }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div
                    class="col-8"
                    *ngIf="
                      certificateNamesFormGroup.get('certificate_line' + index)
                        .value === certificateLineChoices.customText ||
                        certificateNamesFormGroup.get('certificate_line' + index)
                        .value === certificateLineChoices.customTextGreen
                    "
                  >
                    <mat-form-field class="w-100 mb-2">
                      <mat-label>Custom Text for Line {{ index }}</mat-label>
                      <input
                        matInput
                        placeholder="Custom Text for Line {{ index }}"
                        [formControlName]="
                          'certificate_line' + index + '_custom_text'
                        "
                      />
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="col-9 text-center">
                <app-hirl-certificate-preview
                  [eep_program_slug]="'ngbs-land-development-2020-new'"
                  [certification_level]="'four_stars'"
                  [certificate_line1]="
                    certificateNamesFormGroup.get('certificate_line1').value
                  "
                  [certificate_line1_custom_text]="
                    certificateNamesFormGroup.get(
                      'certificate_line1_custom_text'
                    ).value
                  "
                  [certificate_line2]="
                    certificateNamesFormGroup.get('certificate_line2').value
                  "
                  [certificate_line2_custom_text]="
                    certificateNamesFormGroup.get(
                      'certificate_line2_custom_text'
                    ).value
                  "
                  [certificate_line3]="
                    certificateNamesFormGroup.get('certificate_line3').value
                  "
                  [certificate_line3_custom_text]="
                    certificateNamesFormGroup.get(
                      'certificate_line3_custom_text'
                    ).value
                  "
                  [certificate_line4]="
                    certificateNamesFormGroup.get('certificate_line4').value
                  "
                  [certificate_line4_custom_text]="
                    certificateNamesFormGroup.get(
                      'certificate_line4_custom_text'
                    ).value
                  "
                  [certificate_line5]="
                    certificateNamesFormGroup.get('certificate_line5').value
                  "
                  [certificate_line5_custom_text]="
                    certificateNamesFormGroup.get(
                      'certificate_line5_custom_text'
                    ).value
                  "
                  [certificate_line6]="
                    certificateNamesFormGroup.get('certificate_line6').value
                  "
                  [certificate_line6_custom_text]="
                    certificateNamesFormGroup.get(
                      'certificate_line6_custom_text'
                    ).value
                  "
                  [certificate_line7]="
                    certificateNamesFormGroup.get('certificate_line7').value
                  "
                  [certificate_line7_custom_text]="
                    certificateNamesFormGroup.get(
                      'certificate_line7_custom_text'
                    ).value
                  "
                  [certificate_line8]="
                    certificateNamesFormGroup.get('certificate_line8').value
                  "
                  [certificate_line8_custom_text]="
                    certificateNamesFormGroup.get(
                      'certificate_line8_custom_text'
                    ).value
                  "
                  [certificate_line9]="
                    certificateNamesFormGroup.get('certificate_line9').value
                  "
                  [certificate_line9_custom_text]="
                    certificateNamesFormGroup.get(
                      'certificate_line9_custom_text'
                    ).value
                  "
                ></app-hirl-certificate-preview>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-12 text-center">
                <mat-error *ngIf="certificateNamesFormGroup.hasError('addressRequired')">
                  Address must be included in the NGBS Certificate Customization Options section of this registration.  Please review the fields above and ensure that “Address” is selected for one of the line items.
                </mat-error>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-12 text-center">
                <button matStepperPrevious class="btn btn-lg mr-2">Back</button>
                <button matStepperNext class="btn btn-primary btn-lg mr-2">
                  Next
                </button>
              </div>
            </div>
          </form>
        </mat-step>

        <mat-step *ngIf="!editForm">
          <ng-template matStepLabel>Confirm</ng-template>
          <p>
            Before clicking the Submit button, please be sure that the
            information is correct as clicking Submit will forward the project
            information to NGBS staff for review and approval.
          </p>
          <div>
            <button matStepperPrevious mat-raised-button class="mr-2">
              Back
            </button>
            <button
              matStepperNext
              mat-raised-button
              color="accent"
              (click)="handleCreate($event)"
              *ngIf="!editForm"
            >
              Submit
            </button>
          </div>
        </mat-step>
      </mat-vertical-stepper>
    </div>
  </div>

  <div class="row" *ngIf="editForm">
    <div class="col-12 text-center">
      <button appBackButton mat-raised-button class="mr-2">Back</button>
      <button mat-raised-button color="accent" (click)="handleEdit($event)">
        Save
      </button>
    </div>
  </div>

  <router-outlet></router-outlet>
</div>
