<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="row">
                <div class="col-6">
                    <h4>
                        <span *ngIf="!floorPlanLoading">{{ floorPlanCount | number }}</span>
                        <fa-icon [icon]="['fas', 'spinner']" [spin]="true" *ngIf="floorPlanLoading"></fa-icon>
                        Floor Plans
                    </h4>
                </div>
                <div class="col-6 text-right">
                    <mat-menu #createMenu="matMenu">
                        <button mat-menu-item (click)="addFloorplan($event)">
                            Add New
                        </button>
                        <button mat-menu-item (click)="addFloorplanFromBLG($event)">
                            Add New from BLG
                        </button>
                        <button mat-menu-item (click)="addFloorplanFromSimulation($event)">
                            Add New from Simulation
                        </button>
                    </mat-menu>
                    <button mat-button [matMenuTriggerFor]="createMenu">
                        <app-permission-mat-button [permissionResponse]="createPermissionResponse">
                            Add New
                        </app-permission-mat-button>
                    </button>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <hr>
                </div>
            </div>

            <div class="row">
                <div class="col-3">
                    <div class="row">
                        <div class="col-12">
                            <form [formGroup]="filterForm" class="card">
                                <h5 class="card-header">
                                    Filters
                                    <div style="float: right;">
                                        <a href="#" class="small" (click)="resetFilters($event);">Reset Filters</a>
                                    </div>
                                </h5>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Search</mat-label>
                                                <app-search-input formControlName="search"
                                                    placeholder="Type to search something"></app-search-input>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-12">
                                            <mat-form-field class="w-100">
                                                <mat-label>Attached to Home</mat-label>
                                                <mat-select formControlName="attached_to_home">
                                                    <mat-option [value]="">Any</mat-option>
                                                    <mat-option [value]="true">Yes</mat-option>
                                                    <mat-option [value]="false">No</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-12">
                                            <mat-form-field class="w-100">
                                                <mat-label>Residence Type</mat-label>
                                                <mat-select formControlName="residence_type">
                                                    <mat-option [value]="">Any</mat-option>
                                                    <mat-option *ngFor="let item of simulationResidenceTypeLabelMapping  | keyvalue " [value]="item.key">
                                                        {{ item.value }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-12">
                                            <mat-form-field class="w-100">
                                                <mat-label>Simulation Source</mat-label>
                                                <mat-select formControlName="simulation_source">
                                                    <mat-option [value]="">Any</mat-option>
                                                    <mat-option [value]="'remrate_sql'">REM/Rate SQL Export</mat-option>
                                                    <mat-option [value]="'remrate_xml'">REM/Rate XML</mat-option>
                                                    <mat-option [value]="'remrate_blg'">REM/Rate BLG</mat-option>
                                                    <mat-option [value]="'ekotrope'">Ekotrope</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-12">
                                            <mat-form-field class="w-100">
                                                <mat-label>Simulation Version</mat-label>
                                              <app-generic-mat-select formControlName="simulation__version" modelClassName="simulation_versions" hasEmptyChoice="true" emptyChoiceLabel="Any" [emptyChoiceValue]="''"></app-generic-mat-select>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-12">
                                            <mat-form-field class="w-100">
                                                <mat-label>Heater Type</mat-label>
                                                <mat-select formControlName="heater_fuel_type">
                                                    <mat-option [value]="">None</mat-option>
                                                    <mat-option [value]="'electric'">Electric Heater</mat-option>
                                                    <mat-option [value]="'natural_gas'">Gas Heater</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-12">
                                            <mat-form-field class="w-100">
                                                <mat-label>Water Heater Type</mat-label>
                                                <mat-select formControlName="water_heater_type">
                                                    <mat-option [value]="">None</mat-option>
                                                    <mat-option [value]="'electric_tankless'">Electric Tankless
                                                    </mat-option>
                                                    <mat-option [value]="'electric_conventional'">Electric Conventional
                                                    </mat-option>
                                                    <mat-option [value]="'gas_tankless'">Gas Tankless</mat-option>
                                                    <mat-option [value]="'gas_conventional'">Gas Conventional
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-12">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Air Conditioner</mat-label>
                                                <mat-select formControlName="air_conditioner">
                                                    <mat-option value="">Any</mat-option>
                                                    <mat-option value="true">Yes</mat-option>
                                                    <mat-option value="false">No</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-12">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>ASHP</mat-label>
                                                <mat-select formControlName="ashp">
                                                    <mat-option value="">Any</mat-option>
                                                    <mat-option value="false">Yes</mat-option>
                                                    <mat-option value="true">No</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-12">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>GSHP</mat-label>
                                                <mat-select formControlName="gshp">
                                                    <mat-option value="">Any</mat-option>
                                                    <mat-option value="false">Yes</mat-option>
                                                    <mat-option value="true">No</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-12">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Dehumidifier</mat-label>
                                                <mat-select formControlName="dehumidifier">
                                                    <mat-option value="">Any</mat-option>
                                                    <mat-option value="false">Yes</mat-option>
                                                    <mat-option value="true">No</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-12">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Has Photovoltaics</mat-label>
                                                <mat-select formControlName="has_photovoltaics">
                                                    <mat-option value="">Any</mat-option>
                                                    <mat-option value="false">Yes</mat-option>
                                                    <mat-option value="true">No</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-12">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Has Basement</mat-label>
                                                <mat-select formControlName="has_basement">
                                                    <mat-option value="">Any</mat-option>
                                                    <mat-option value="true">Yes</mat-option>
                                                    <mat-option value="false">No</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-12">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Crawlspace</mat-label>
                                                <mat-select formControlName="crawl_space">
                                                    <mat-option value="">Any</mat-option>
                                                    <mat-option value="vented_crawl">Vented</mat-option>
                                                    <mat-option value="unvented_crawl">Un-Vented</mat-option>
                                                    <mat-option value="na">No</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-12">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Has Slabs</mat-label>
                                                <mat-select formControlName="has_slabs">
                                                    <mat-option value="">Any</mat-option>
                                                    <mat-option value="false">Yes</mat-option>
                                                    <mat-option value="true">No</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-12">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Attic Type</mat-label>
                                                <mat-select formControlName="attic_type">
                                                    <mat-option value="">Any</mat-option>
                                                    <mat-option value="vented">Vented</mat-option>
                                                    <mat-option value="unvented">Un-Vented</mat-option>
                                                    <mat-option value="na">No</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-12">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Vaulted Ceilings</mat-label>
                                                <mat-select formControlName="vaulted_ceilings">
                                                    <mat-option value="">Any</mat-option>
                                                    <mat-option value="true">Yes</mat-option>
                                                    <mat-option value="false">No</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-12">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Number of Stories</mat-label>
                                                <mat-select formControlName="num_stories">
                                                    <mat-option value=1>1</mat-option>
                                                    <mat-option value=2>2</mat-option>
                                                    <mat-option value=3>3+</mat-option>
                                                    <mat-option value="">Any</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class=" col-9">
                    <ngx-datatable class="material" [rows]="floorplans" columnMode="force" headerHeight="40"
                        rowHeight="auto" footerHeight="50" limit="25" [loadingIndicator]="floorPlanLoading"
                        externalSorting="true" (sort)="onSort($event)">

                        <ngx-datatable-column name="Name" prop="name">
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                <app-floorplan-display
                                    [floorplan]="row"
                                    displayType="onlyNameAsLink"
                                ></app-floorplan-display>
                                <span display='inline-block' *ngIf="row.homes_count > 0">
                                    <mat-icon class="icon-display">lock</mat-icon>
                                </span>
                            </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-column name="Homes Count" prop="homes_count">
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                <div style="margin-left: 50px;">{{ row.homes_count }}</div>
                            </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-column name="Simulation Source" prop="remrate_target">
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                <div *ngIf="row.remrate_target || row.ekotrope_houseplan || row.simulation_info?.source_type === 'remrate_blg'; else remdash">
                                    <a *ngIf="row.remrate_target"
                                        [routerLink]="['/', 'floorplan', 'input', 'remrate', row.remrate_target]">
                                        <div *ngIf="row.simulation_info">
                                            {{ row.simulation_info.name }}
                                        </div>
                                    </a>
                                    <a *ngIf="row.ekotrope_houseplan"
                                        [routerLink]="['/', 'floorplan', 'input', 'ekotrope', row.ekotrope_houseplan]">
                                        <div *ngIf="row.simulation_info">
                                            {{ row.simulation_info.name }}
                                        </div>
                                    </a>
                                    <a *ngIf="row.simulation_info?.source_type === 'remrate_blg'" 
                                        href="{{ row.remrate_data_file_url }}" target="_blank">
                                            <fa-icon [icon]="['fas', 'download']" matTooltip="Download BLG File"></fa-icon>
                                            {{ row.remrate_data_file_name }}
                                    </a>
                                </div>
                                <ng-template #remdash ngx-datatable-cell-template>-
                                </ng-template>
                            </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-column name="SourceType / Version" prop="simulator_version">
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                <div *ngIf="row.simulation_info">    
                                    <ng-container *ngIf="row.simulation_info?.source_type !== 'ekotrope'; else remrateTypeContainer">
                                        {{ simulationSourceTypeLabelMap[row.simulation_info?.source_type] }}
                                    </ng-container>
                                    <ng-template #remrateTypeContainer>
                                        Ekotrope Data Type
                                    </ng-template>
                                    / {{row.simulation_info.version }}
                                </div>
                            </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-column name="Created Date" prop="created_date">
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                <div style="margin-left: 30px;">{{ row.created_date | date: 'medium' }}</div>
                            </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-footer>
                            <ng-template ngx-datatable-footer-template>
                                <div class="col-12 text-center">
                                    <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                        [pagerRightArrowIcon]="'datatable-icon-right'"
                                        [pagerPreviousIcon]="'datatable-icon-prev'"
                                        [pagerNextIcon]="'datatable-icon-skip'" [size]="25" [count]="floorPlanCount"
                                        (change)="onFooterPage($event)">
                                    </datatable-pager>
                                </div>
                            </ng-template>
                        </ngx-datatable-footer>
                    </ngx-datatable>
                </div>
            </div>
        </div>
    </div>
</div>
