import { Injectable } from '@angular/core';
import { ListRequestParams } from '@/core/schemes/request-params';
import { environment } from '../../../../environments/environment';
import { BaseDataService } from '@/core/services/base-data-service';
import { Observable } from 'rxjs';
import { UtilityRateBackendDict } from '../models/utility/UtilityRate';
import { ServerResponse } from '@/core/schemes/server-response';

export class UtilityRateParams extends ListRequestParams {}

@Injectable({ providedIn: 'root' })
export class UtilityRateService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/utility_rate/`;
  public parentUrl = `${environment.AXIS_API_V3_ROOT}/simulation/`;

  list(
    params: ListRequestParams
  ): Observable<ServerResponse<UtilityRateBackendDict>> {
    return this.http.get<ServerResponse<UtilityRateBackendDict>>(
      `${this.baseUrl}`,
      {
        params: this.rollParams(params),
      }
    );
  }

  create(
    simulationId: number,
    data: Partial<UtilityRateBackendDict>
  ): Observable<UtilityRateBackendDict> {
    return this.http.post<UtilityRateBackendDict>(
      `${this.parentUrl}${simulationId}/utility_rates/`,
      data
    );
  }

  update(
    utilityRateChanges: Partial<UtilityRateBackendDict>
  ): Observable<UtilityRateBackendDict> {
    return this.http.patch<UtilityRateBackendDict>(
      `${this.baseUrl}${utilityRateChanges.id}/`,
      utilityRateChanges
    );
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}${id}/`);
  }
}
