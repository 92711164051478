import { Action, createReducer, on } from '@ngrx/store';
import { Task } from '@/data/scheduling/models';
import {
  loadSchedulingTask,
  resetSchedulingTask,
} from '@/state/actions/scheduling/task.actions';

export const schedulingTaskStateKey = 'schedulingTask';

export interface SchedulingTaskState {
  detail: Task;
  projects: Task[];
  projectsCount: number;
}

export const SchedulingTaskInitialState: SchedulingTaskState = {
  detail: null,
  projects: [],
  projectsCount: 0,
};

const SchedulingTaskReducer = createReducer(
  SchedulingTaskInitialState,
  on(resetSchedulingTask, (state, action) => ({
    ...SchedulingTaskInitialState,
  })),
  on(loadSchedulingTask, (state, { payload }) => ({
    ...state,
    detail: payload,
  }))
);

export function reducer(
  state: SchedulingTaskState | undefined,
  action: Action
) {
  return SchedulingTaskReducer(state, action);
}
