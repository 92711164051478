import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User, UserInfo } from '@/data/core/models/user';
import { forkJoin, of, Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, takeUntil } from 'rxjs/operators';
import { SimulationBackendDict } from '@/data/simulation/models/simulation';
import { selectLocationViewSet } from '@/modules/simulation/state/location/selectors';
import { LocationBackendDict } from '@/data/simulation/models/Location';
import { selectProject } from '@/modules/simulation/state/project/selectors';
import {
  ProjectBackendDict,
  RatingTypeLabels,
} from '@/data/simulation/models/Project';
import {
  SubmitToAxisRequestBackendDict,
  SubmitToAxisRequestEEPProgramType,
  SubmitToAxisRequestEEPProgramTypeLabels,
  SubmitToAxisRequestSubmitType,
  SubmitToAxisRequestSubmitTypeLabels,
  SubmitToAxisResponseBackendDict,
} from '@/data/simulation/models';
import { HttpErrorResponse } from '@angular/common/http';
import { SimulationService } from '@/data/simulation/services/simulation.service';
import {
  FloorPlanRequestParams,
  FloorPlanService,
} from '@/data/floorplan/services/floorplan.service';
import { FloorPlan } from '@/data/floorplan/models';
import { formatDate } from '@angular/common';
import { HomeAddressConfirmedGeocodeResponse } from '@/modules/geocoder/components/home-address-to-geocode-response-dialog/home-address-to-geocode-response-dialog.component';
import { findProgram } from '@/data/simulation/models/submit-to-axis';

export interface CreateHomeFromSimulationDialogData {
  simulation?: SimulationBackendDict;
}

@Component({
  selector: 'app-create-home-from-simulation-dialog',
  templateUrl: './create-home-from-simulation-dialog.component.html',
  styleUrls: ['./create-home-from-simulation-dialog.component.scss'],
})
export class CreateHomeFromSimulationDialogComponent
  implements OnInit, OnDestroy
{
  protected readonly RatingTypeLabels = RatingTypeLabels;
  protected readonly SubmitToAxisRequestEEPProgramTypeLabels =
    SubmitToAxisRequestEEPProgramTypeLabels;
  protected readonly SubmitToAxisRequestEEPProgramType =
    SubmitToAxisRequestEEPProgramType;

  SubmitToAxisRequestSubmitType = SubmitToAxisRequestSubmitType;

  protected readonly SubmitToAxisRequestSubmitTypeLabels =
    SubmitToAxisRequestSubmitTypeLabels;
  initialized = false;
  entity?: SimulationBackendDict;
  location: LocationBackendDict;
  project: ProjectBackendDict;
  floorplan?: FloorPlan;
  confirmedGeocodeResponse?: HomeAddressConfirmedGeocodeResponse;
  form: FormGroup;
  currentUser: User;
  loading = false;

  private componentDestroyed$ = new Subject();
  constructor(
    private dialogRef: MatDialogRef<CreateHomeFromSimulationDialogComponent>,
    private fb: FormBuilder,
    private simulationService: SimulationService,
    private floorplanService: FloorPlanService,
    private store: Store<AppState>,
    private uiHelperService: UIHelperService,
    @Inject(MAT_DIALOG_DATA) public data: CreateHomeFromSimulationDialogData
  ) {
    if (data?.simulation) {
      this.entity = Object.assign({}, data?.simulation);
    }
  }

  ngOnInit() {
    const floorplanParams = new FloorPlanRequestParams();
    floorplanParams.simulation = this.entity.id;

    const sources = {
      currentUser: this.store.select(getInfoUser).pipe(first()),
      simulation: of(this.entity),
      locationData: this.store
        .select(selectLocationViewSet(this.entity.location))
        .pipe(first()),
      projectData: this.store
        .select(selectProject(this.entity.project))
        .pipe(first()),
    };

    forkJoin(sources)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe({
        next: ({
          currentUser,
          simulation,
          locationData,
          projectData,
        }: {
          currentUser: UserInfo;
          simulation: SimulationBackendDict;
          locationData: { data: LocationBackendDict; loading: boolean };
          projectData: ProjectBackendDict;
        }) => {
          this.currentUser = currentUser;
          this.entity = simulation;
          this.location = locationData.data;
          this.project = projectData;

          this.confirmedGeocodeResponse =
            new HomeAddressConfirmedGeocodeResponse({
              lotNumber: this.location.lot_number,
              geocode: this.location.geocode_info,
              geocodeBrokeredResponse: this.location.geocode_response_info,
            });
          this.form = this.fb.group({
            eep_program_type: [''],
            permit_date: [this.project.permit_date, [Validators.required]],
            address: [this.confirmedGeocodeResponse, [Validators.required]],
            builder_organization_info: [null, Validators.required],
            rater_organization_info: [null, Validators.required],
            rater_of_record_info: [null, Validators.required],
          });

          this.form.controls.address.valueChanges.subscribe(value => {
            findProgram(
              value?.geocode?.id,
              this.form.controls.permit_date.value
            );
          });
          this.initialized = true;
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  save($event: MouseEvent) {
    $event.preventDefault();
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    const data: SubmitToAxisRequestBackendDict = {
      submit_type: 'home',
      eep_program_type: this.form.get('eep_program_type').value,
      permit_date: `${formatDate(
        this.form.get('permit_date').value,
        'yyyy-MM-dd',
        'en-US'
      )}`,
      builder_organization: this.form.value.builder_organization_info?.id,
      rater_organization: this.form.value.rater_organization_info?.id,
      rater_of_record: this.form.value.rater_of_record_info?.id,
      energy_modeler: this.currentUser.id,
      lot_number: this.form.get('address').value?.lotNumber,
      geocode: this.form.get('address').value?.geocode?.id,
      geocode_response: this.form.get('address').value?.geocode_response?.id,
      simulation: this.entity.id,
    } as SubmitToAxisRequestBackendDict;

    this.loading = true;
    this.simulationService
      .createHomeFromSimulation(data)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(
        (response: SubmitToAxisResponseBackendDict) => {
          this.dialogRef.close(response);
          this.loading = false;
        },
        (error?: HttpErrorResponse) => {
          this.uiHelperService.handleUserRequestError(error);
          this.loading = false;
        }
      );
  }
}
