import { ModelFormGroup } from '@/data/simulation/models/ModelForm';
import {
  AuxEnergyUnitLabels,
  createMechanicalVentilationForm,
  FIELD_LABELS,
  MechanicalVentilationBackendDict,
  MechanicalVentilationTypeLabels,
  MechanicalVentilationUseCaseLabels,
  VentilationRateUnitLabels,
} from '@/data/simulation/models/systems/MechanicalVentilation';
import {
  removeMechanicalVentilation,
  updateMechanicalVentilation,
} from '@/modules/simulation/state/mechanical-ventilation/actions';
import { selectMechanicalVentilationViewSet } from '@/modules/simulation/state/mechanical-ventilation/selectors';
import { cloneMechanicalVentilation } from '@/modules/simulation/state/simulation/actions';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { LengthUnitLabels } from '@/data/simulation/enumerations';

@Component({
  selector: 'app-mechanical-ventilation',
  templateUrl: './mechanical-ventilation.component.html',
  styleUrls: ['./mechanical-ventilation.component.scss'],
})
export class MechanicalVentilationComponent implements OnInit {
  @Input() entityId: number;

  mechanicalVentilationForm: ModelFormGroup;
  entity: MechanicalVentilationBackendDict;
  loading: boolean;
  fieldLabels = FIELD_LABELS;
  typeLabels = MechanicalVentilationTypeLabels;
  useCaseLabels = MechanicalVentilationUseCaseLabels;
  ventilationRateLabels = VentilationRateUnitLabels;
  auxEnergyUnitLabels = AuxEnergyUnitLabels;

  private componentDestroyed$ = new Subject();

  constructor(public dialog: MatDialog, public store: Store) {}

  ngOnInit(): void {
    this.store
      .select(selectMechanicalVentilationViewSet(this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(({ data, loading }) => {
        this.entity = data;
        this.loading = loading;
        if (loading) return;

        if (this.mechanicalVentilationForm) {
          this.mechanicalVentilationForm.patchValue(data);
        } else {
          this.mechanicalVentilationForm =
            createMechanicalVentilationForm(data);
        }
      });
  }

  onSave(
    mechanicalVentilationChanges: Partial<MechanicalVentilationBackendDict>
  ): void {
    this.store.dispatch(
      updateMechanicalVentilation({
        mechanicalVentilationChanges,
      })
    );
  }

  removeMechanicalVentilation() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '50%',
      data: {
        title: `Do you want to delete the MechanicalVentilation?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (result) {
          this.store.dispatch(
            removeMechanicalVentilation({
              mechanicalVentilation: this.entity,
            })
          );
        }
      });
  }

  cloneMechanicalVentilation() {
    this.store.dispatch(cloneMechanicalVentilation({ id: this.entityId }));
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  protected readonly lengthLabels = LengthUnitLabels;
}
