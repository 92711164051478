import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin, of, Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { toggleLoading } from '@/state/actions/app.actions';
import {
  defaultIfEmpty,
  first,
  map,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { UDRHModel } from '@/data/simulation/models';
import { UDRHModelService } from '@/data/simulation/services/udrh-model.service';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { forEach } from 'lodash';
import { CustomerDocument } from '@/data/filehandling/models';
import { CompanyService } from '@/data/company/services/company-base.service';
import { CustomerDocumentService } from '@/data/filehandling/services/customer-document.service';
import { getInfoUser } from '@/state/selectors/info.selector';
import { User } from '@/data/core/models/user';

export class ChangeUDRHModelDialogData {
  udrhModel?: UDRHModel;
  commit = true;
  retrieve = true;
}

@Component({
  selector: 'app-change-udrh-dialog',
  templateUrl: './change-udrh-dialog.component.html',
  styleUrls: ['./change-udrh-dialog.component.scss'],
})
export class ChangeUdrhDialogComponent implements OnInit, OnDestroy {
  public initialized = false;
  public isEditForm = false;
  public entity?: UDRHModel;
  public commit = true;
  public retrieve = true;
  public form: FormGroup;
  public currentUser: User;

  public files: File[] = [];

  private componentDestroyed$ = new Subject();
  constructor(
    private dialogRef: MatDialogRef<ChangeUdrhDialogComponent>,
    private fb: FormBuilder,
    private store: Store<AppState>,
    private udrhModelService: UDRHModelService,
    private companyService: CompanyService,
    private customerDocumentService: CustomerDocumentService,
    private uiHelperService: UIHelperService,
    @Inject(MAT_DIALOG_DATA) public data: ChangeUDRHModelDialogData
  ) {
    if (data?.udrhModel) {
      this.entity = Object.assign({}, data?.udrhModel);
    }
    this.commit = data?.commit || true;

    if (this.entity?.id) {
      this.isEditForm = true;
    }
  }

  ngOnInit() {
    const sources = {
      currentUser: this.store.select(getInfoUser).pipe(first()),
      customerDocument: of(this.entity),
    };

    this.store.dispatch(toggleLoading({ payload: true }));

    if (this.isEditForm && this.retrieve) {
      sources.customerDocument = this.udrhModelService
        .retrieve(this.entity.id, { ignoreStore: true })
        .pipe(takeUntil(this.componentDestroyed$), first());
    }

    forkJoin(sources)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: ({ currentUser, customerDocument }) => {
          this.store.dispatch(toggleLoading({ payload: false }));

          this.currentUser = currentUser;
          this.entity = customerDocument;
          this.setupForm();
          this.initialized = true;
          if (this.isEditForm) {
            this.hydrateForm();
          }
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  setupForm() {
    this.form = this.fb.group({
      short_name: ['', Validators.required],
      description: [''],
    });
  }

  hydrateForm() {}

  save($event: MouseEvent) {
    $event.preventDefault();
    if (this.isEditForm) {
      this.edit();
    } else {
      this.create();
    }
  }

  private create() {
    this.form.markAllAsTouched();

    if (this.form.invalid || !this.files.length) {
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const udrhModel: UDRHModel = new UDRHModel();

    udrhModel.short_name = this.form.value.short_name;
    udrhModel.description = this.form.value.description;

    const customerDocumentsChain$ = [];

    forEach(this.files, f => {
      const customerDocumentData = new CustomerDocument();

      const customerDocumentCreate$ = this.companyService
        .documentsCreate(this.currentUser.company, customerDocumentData)
        .pipe(
          first(),
          // update file fields
          switchMap(customerDocument => {
            const formData = new FormData();
            formData.append('document', f);
            return this.customerDocumentService.update(
              customerDocument.id,
              formData
            );
          })
        )
        .pipe(takeUntil(this.componentDestroyed$), first());

      customerDocumentsChain$.push(customerDocumentCreate$);
    });

    forkJoin(customerDocumentsChain$)
      .pipe(
        takeUntil(this.componentDestroyed$),
        defaultIfEmpty(null),
        map(customerDocuments =>
          customerDocuments.map(customerDocument => customerDocument.id)
        ),
        switchMap(customerDocumentsIds => {
          udrhModel.uploaded_file = customerDocumentsIds[0];

          return this.udrhModelService
            .create_from_file(udrhModel)
            .pipe(first(), takeUntil(this.componentDestroyed$));
        })
      )
      .subscribe({
        next: udrhModel => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close(udrhModel);
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  private edit() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    if (!this.commit) {
      this.dialogRef.close(this.entity);
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    // const data = new UDRHModel();
    //
    // this.udrhModelService
    //   .update(this.entity!.id, data)
    //   .pipe(first())
    //   .subscribe({
    //     next: udrhModel => {
    //       this.store.dispatch(toggleLoading({ payload: false }));
    //       this.dialogRef.close(udrhModel);
    //     },
    //     error: error => this.uiHelperService.handleUserRequestError(error),
    //   });
  }

  onSelect($event: NgxDropzoneChangeEvent) {
    this.files.push(...$event.addedFiles);
  }

  onRemove(f: File) {
    this.files.splice(this.files.indexOf(f), 1);
  }
}
