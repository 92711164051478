import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { addRoofType } from '@/modules/simulation/state/roof-type/actions';
import { CREATE_MODE, EDIT_MODE } from '@/core/constants';

export class RoofTypeChangeDialogData {
  entityId?: number;
  parentId?: number;
  mode: typeof EDIT_MODE | typeof CREATE_MODE;
  commit?: boolean = false;
  retrieve?: boolean = false;
}

@Component({
  selector: 'app-roof-type-change-dialog',
  templateUrl: './roof-type-change-dialog.component.html',
  styleUrls: ['./roof-type-change-dialog.component.scss'],
})
export class RoofTypeChangeDialogComponent implements OnInit {
  typeSelectForm: FormGroup;
  public editorIsVisible = false;
  public editMode: boolean;
  public roofTypeId: number;
  public initalRoofTypeId: number;

  componentDestroyed$ = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: RoofTypeChangeDialogData,
    public dialogRef: MatDialogRef<RoofTypeChangeDialogComponent>,
    public store: Store
  ) {
    this.editMode = this.data.mode === EDIT_MODE;
    if (this.data.mode === CREATE_MODE && this.data.entityId) {
      throw new Error(
        'Cannot create a new roof type with an existing roof type id or ' +
          'roof type'
      );
    }

    if (this.data.mode === EDIT_MODE) {
      this.roofTypeId = this.data.entityId;
    }
  }

  initTypeSelectorForm() {
    this.editorIsVisible = false;
    this.typeSelectForm = new FormGroup({
      type: new FormControl(null),
    });

    this.typeSelectForm
      .get('type')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe(roofType => {
        this.store.dispatch(
          addRoofType({ roofId: this.data.parentId, roofType: roofType })
        );

        this.close();
      });
  }

  initRoofTypeForm(entityId: number) {
    this.roofTypeId = entityId;
    this.editorIsVisible = true;
  }

  ngOnInit(): void {
    if (this.data.mode === CREATE_MODE) {
      this.initTypeSelectorForm();
    } else {
      this.roofTypeId = this.data.entityId;
      this.editorIsVisible = true;
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
