import {
  FoundationWallTypeBackendDict,
  FoundationWallTypeFormGroup,
} from '@/data/simulation/models/enclosure/FoundationWallType';
import { BaseValidator } from './base.validator';
import { ModelErrors, StateErrors } from '../state/shared/base.state';

export class FoundationWallTypeValidator extends BaseValidator {
  static validateForm(
    foundationwalltype: FoundationWallTypeBackendDict
  ): ModelErrors<FoundationWallTypeBackendDict> {
    const form = new FoundationWallTypeFormGroup(foundationwalltype);
    return form.getFieldAndModelErros();
  }

  static validate(
    foundationwalltypes: FoundationWallTypeBackendDict[]
  ): StateErrors<FoundationWallTypeBackendDict> {
    const errors = {};
    foundationwalltypes.forEach(foundationwalltype => {
      errors[foundationwalltype.id] = this.validateForm(foundationwalltype);
    });
    return errors;
  }
}
