<h2 mat-dialog-title>
    Set Registration to Abandon state
</h2>
<hr>
<mat-dialog-content>
    <form [formGroup]="formGroup">
    <div class="row">
        <div class="col-12">
            <p class="lead">
                All projects related to this this registration will be abandoned
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">

                <p>
                    Set Billing state for Projects in this Registration (except Projects with Invoice)
                </p>
                <mat-radio-group aria-label="Set Billing state for Projects(except Projects with Invoice)" formControlName="billing_state">
                    <div *ngFor="let billingState of billingStates | keyvalue">
                        <mat-radio-button value="{{ billingState.key }}">
                            {{ billingState.value }}
                        </mat-radio-button>
                    </div>
                </mat-radio-group>

        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <mat-form-field appearance="outline" class="w-100 mb-2">
                <mat-label>Reason</mat-label>
                <textarea matInput placeholder="Reason"
                          formControlName="reason"></textarea>
            </mat-form-field>
        </div>
    </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="col-12 text-right">
        <button mat-raised-button class="mr-2" color="accent" (click)="onConfirm()">
            Confirm
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>
            Cancel
        </button>
    </div>
</mat-dialog-actions>