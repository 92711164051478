import { Component, Injector, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getInfoUser } from '@/state/selectors/info.selector';
import { User } from '@/data/core/models/user';
import { AppState } from '@/state/reducers';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';
import { environment } from '../../../../../environments/environment';
import { toggleLoading } from '@/state/actions/app.actions';

@Component({
  selector: 'app-main-dashboard-page',
  templateUrl: './main-dashboard-page.component.html',
  styleUrls: ['./main-dashboard-page.component.scss'],
})
export class MainDashboardPageComponent implements OnInit {
  public initialized = false;
  public currentUser: User;

  public customerHIRLSettings = CustomerHIRLSettings;

  constructor(
    public store: Store<AppState>,
    public router: Router,
    public injector: Injector,
    public dialog: MatDialog,
    public uiHelper: UIHelperService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(toggleLoading({ payload: true }));

    forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
    }).subscribe(({ currentUser }) => {
      this.currentUser = currentUser;
      if (
        !currentUser.company ||
        currentUser.company_info.slug !== CustomerHIRLSettings.companySlug
      ) {
        if (environment.APP_INSIDE_DJANGO) {
          window.location.href = `${environment.AXIS_LEGACY_ROOT}`;
        } else {
          console.log(
            `Redirecting to Legacy url(/) disabled with APP_NOT_INSIDE_DJANGO=false configuration`
          );
          this.initialized = false;
          this.store.dispatch(toggleLoading({ payload: false }));
        }
      } else {
        this.initialized = true;
        this.store.dispatch(toggleLoading({ payload: false }));
      }
    });
  }
}
