import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, mergeMap, tap } from 'rxjs/operators';
import * as SharedActions from './shared.actions';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { StateValidatorService } from '../../services/state-validator.service';
import { of } from 'rxjs';
import { getErrorActions } from '../helper';

@Injectable()
export class SharedEffects {
  reportAPIFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SharedActions.reportAPIFailure),
        tap(console.error),
        map(action => this.uiHelperService.handleUserRequestError(action.error))
      );
    },
    { dispatch: false }
  );

  updateCrossModelErrors$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SharedActions.updateCrossModelErrors),
      tap(console.error),
      mergeMap(action => {
        const connectedErrors =
          this.stateValidatorService.validateAgainstChanges(
            action.modelName,
            action.changedFields
          );
        return of(...getErrorActions(connectedErrors));
      })
    );
  });

  constructor(
    private actions$: Actions,
    private uiHelperService: UIHelperService,
    private stateValidatorService: StateValidatorService
  ) {}
}
