import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { first, flatMap } from 'rxjs/operators';
import { getInfo } from '@/state/selectors/info.selector';
import { TokenService } from '@/core/services/token.service';
import { ImpersonationUser } from '@/data/core/models/impersonation_user';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private tokenService: TokenService,
    private store: Store<AppState>
  ) {}

  isRefreshingToken: false;

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<
    | HttpSentEvent
    | HttpHeaderResponse
    | HttpProgressEvent
    | HttpResponse<any>
    | HttpUserEvent<any>
    | any
  > {
    if (request.headers.get('skipJWT')) {
      return next.handle(request);
    }
    return this.store.select(getInfo).pipe(
      first(),
      flatMap((info: ImpersonationUser) =>
        next.handle(
          this.tokenService.decorateRequest(
            request,
            info,
            this.tokenService.getAccessToken()
          )
        )
      )
    );
  }
}
