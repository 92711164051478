import { map, take } from 'rxjs/operators';
import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { HIRLProjectService } from '@/data/customer-hirl/services/hirl-projects.service';

export function hirlProjectAddressExistsValidator(
  projectService: HIRLProjectService,
  projectId?: string,
  isMultifamily = false
): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    const confirmedGeocodeResponse = control.value;
    if (!confirmedGeocodeResponse || !confirmedGeocodeResponse.addressInfo) {
      return of(null);
    }
    return projectService
      .homeAddressIsUnique({
        project: projectId,
        street_line1: confirmedGeocodeResponse.addressInfo.street_line1,
        street_line2: confirmedGeocodeResponse.addressInfo.street_line2,
        city: confirmedGeocodeResponse.addressInfo.city_info.id,
        zipcode: confirmedGeocodeResponse.addressInfo.zipcode,
        is_multi_family: isMultifamily,
        geocode_response: confirmedGeocodeResponse.geocodeBrokeredResponse
          ? confirmedGeocodeResponse.geocodeBrokeredResponse.id
          : null,
      })
      .pipe(
        take(1),
        map((result: boolean) => {
          return result ? null : { hirlProjectAlreadyExists: true };
        })
      );
  };
}
