<ng-container *ngIf="community">
  <ng-container [ngSwitch]="displayType">
    <ng-container *ngSwitchCase="communityDisplayComponentType.onlyName">
      {{ community?.name }}
    </ng-container>
    <ng-container *ngSwitchCase="communityDisplayComponentType.nameAndAddress">
      {{ community?.name }}
      <small>[{{ community?.cross_roads }}</small>
    </ng-container>
    <a
      [routerLink]="[
        '/',
        'community',
        community?.id
      ]"
      *ngSwitchCase="communityDisplayComponentType.onlyNameAsLink"
    >
      {{ community?.name }}
    </a>
    <ng-container *ngSwitchCase="communityDisplayComponentType.nameAndType">
      {{ community?.name }} 
    </ng-container>
    <a
      [routerLink]="[
        '/',
        'community',
        community?.id
      ]"
      *ngSwitchCase="communityDisplayComponentType.nameAndTypeAsLink"
    >
      {{ community?.name }}
    </a>
  </ng-container>
</ng-container>
