import { createAction, props } from '@ngrx/store';
import {
  HIRLProject,
  HIRLProjectRegistration,
} from '@/data/customer-hirl/models';

export const resetHIRLProjectRegistration = createAction(
  '[HIRLProjectRegistration] Reset'
);

export const loadHIRLProjectRegistration = createAction(
  '[HIRLProjectRegistration] Detail',
  props<{ payload: HIRLProjectRegistration }>()
);

// projects

export const loadHIRLProjectRegistrationProjects = createAction(
  '[HIRLProjectRegistration Projects] List',
  props<{ payload: HIRLProject[] }>()
);

export const loadHIRLProjectRegistrationProjectsCount = createAction(
  '[HIRLProjectRegistration Projects] Count',
  props<{ payload: number }>()
);

export const deleteHIRLProjectRegistrationProject = createAction(
  '[HIRLProjectRegistration Projects] Delete',
  props<{ payload: HIRLProject }>()
);
