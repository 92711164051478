import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BaseDataService } from '@/core/services/base-data-service';
import { Observable } from 'rxjs';
import { SlabBackendDict } from '../models/enclosure/Slab';

@Injectable({ providedIn: 'root' })
export class SlabService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/slab/`;

  update(
    id: number,
    slabTypeChanges: Partial<SlabBackendDict>
  ): Observable<SlabBackendDict> {
    return this.http.put<SlabBackendDict>(
      `${this.baseUrl}${id}/`,
      slabTypeChanges
    );
  }

  delete(id: number) {
    return this.http.delete(`${this.baseUrl}${id}/`);
  }
}
