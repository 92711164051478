import { CREATE_MODE, EDIT_MODE } from '@/core/constants';
import {
  EquipmentType,
  EquipmentTypeLabels,
} from '@/data/simulation/models/mechanicals/MechanicalEquipment';
import { addNewEquipment } from '@/modules/simulation/state/simulation/actions';
import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface EquipmentDetailsChangeDialogData {
  equipmentType: EquipmentType;
  entityId: number;
  mode: typeof EDIT_MODE | typeof CREATE_MODE;
}

@Component({
  selector: 'app-equipment-details-change-dialog',
  templateUrl: './equipment-details-change-dialog.component.html',
  styleUrls: ['./equipment-details-change-dialog.component.scss'],
})
export class EquipmentDetailsChangeDialogComponent {
  public equipmentType: EquipmentType = EquipmentType.AIR_CONDITIONER;
  public entityId: number;
  public editMode: boolean;
  editorIsVisible = true;
  equipmentTypes = EquipmentType;
  equipmentSelectControl = new FormControl({ id: null, name: '' });
  equipmentTypeControl = new FormControl(EquipmentType.AIR_CONDITIONER);
  private componentDestroyed$ = new Subject();

  equipmentTypeLabels = EquipmentTypeLabels;
  showSelector = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EquipmentDetailsChangeDialogData,
    public dialogRef: MatDialogRef<EquipmentDetailsChangeDialogData>,
    public store: Store
  ) {
    this.editMode = this.data.mode === EDIT_MODE;
    if (this.editMode) {
      this.equipmentType = this.data.equipmentType;
      this.entityId = this.data.entityId;
    }
  }

  ngOnInit(): void {
    this.equipmentTypeControl.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((value: EquipmentType) => {
        this.equipmentType = value;

        this.showSelector = false;

        setTimeout(() => {
          this.showSelector = true;
        }, 100);
      });

    this.equipmentSelectControl.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(equipment => {
        equipment.id = null;
        this.store.dispatch(
          addNewEquipment({
            detailedMechanicalEquipment: {
              id: null,
              name: equipment.name,
              qty_installed: 1,
              location: null,
              [this.equipmentType]: equipment,
            },
          })
        );
        this.dialogRef.close();
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
