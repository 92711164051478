import { AppState } from '@/state/reducers';
import { createSelector } from '@ngrx/store';
import { apexRequestStateKey } from '@/state/reducers/home/apex-request.reducer';

export const selectApexRequestState = (state: AppState) =>
  state[apexRequestStateKey];

export const getApexRequest = createSelector(
  selectApexRequestState,
  store => store.detail
);
// Apex Request Documents
export const getApexRequestDocuments = createSelector(
  selectApexRequestState,
  store => store.apexRequestDocuments
);

export const getApexRequestDocumentsCount = createSelector(
  selectApexRequestState,
  store => store.apexRequestDocumentsCount
);
