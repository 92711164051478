import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { MatDialog } from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { filter, first, takeUntil } from 'rxjs/operators';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import * as _ from 'lodash';
import { ProductPracticeItem } from '@/data/customer-hirl/models';

import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { ProductPracticeItemRequestParams } from '@/data/customer-hirl/services/product-practice-item.service';

import { ProductService } from '@/data/product/services/product.service';
import { Product } from '@/data/product/models';
import {
  getProduct,
  getProductPracticeItems,
  getProductPracticeItemsCount,
} from '@/state/selectors/product/product.selector';
import { ProductPracticeItemService } from '@/data/customer-hirl/services/product-practice-item.service';
import { ProductPracticeItemPermissionService } from '@/modules/customer-hirl/services/product-practice-item-permission.service';
import {
  ProductPracticeItemChangeDialogComponent,
  ProductPracticeItemChangeDialogData,
} from '@/modules/customer-hirl/components/product-practice-item-change-dialog/product-practice-item-change-dialog.component';
import { toggleLoading } from '@/state/actions/app.actions';
import {
  deleteProductPracticeItemAction,
  updateProductProductPracticeItemAction,
} from '@/state/actions/product/product.actions';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-product-practice-items-tab',
  templateUrl: './product-practice-items-tab.component.html',
  styleUrls: ['./product-practice-items-tab.component.scss'],
})
export class ProductPracticeItemsTabComponent implements OnInit, OnDestroy {
  public product: Product;
  public displayedColumns = [
    'standard',
    'chapter',
    'section',
    'practice',
    'item',
    'points',
    'used_to_earn_points',
    'product_features',
    'customer_document',
    'is_active',
    'actions',
  ];
  public filterFromGroup: FormGroup;
  public isLoading = false;
  public rows: ProductPracticeItem[] = [];
  public permissions: {
    [key: string]: {
      canEdit: Observable<ObjectPermissionResponse>;
      canDelete: Observable<ObjectPermissionResponse>;
    };
  } = {};
  public rowsCount: number;
  public createPermissionResponse: Observable<ObjectPermissionResponse>;

  public defaultParams: ProductPracticeItemRequestParams =
    new ProductPracticeItemRequestParams(1, '', '-created_at');

  public storedParams: ProductPracticeItemRequestParams = Object.assign(
    new ProductPracticeItemRequestParams(),
    this.defaultParams
  );

  private listSubscription$: Subscription;
  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private injector: Injector,
    private dialog: MatDialog,
    private productService: ProductService,
    private productPracticeItemService: ProductPracticeItemService,
    private productPracticeItemPermissionService: ProductPracticeItemPermissionService,
    private fb: FormBuilder,
    private uiHelperService: UIHelperService
  ) {}

  ngOnInit(): void {
    this.storedParams = _.cloneDeep(this.defaultParams);

    this.store
      .select(getProduct)
      .pipe(
        filter(val => val !== null),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((entity: Product) => {
        this.product = entity;
        this.createPermissionResponse =
          this.productPracticeItemPermissionService.canCreate(this.product);
        this.list();
      });

    this.store
      .select(getProductPracticeItems)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((productPracticeItems: ProductPracticeItem[]) => {
        this.rows = productPracticeItems;
      });

    this.store
      .select(getProductPracticeItemsCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(productPracticeItemsCount => {
        this.rowsCount = productPracticeItemsCount;
      });

    this.setupFilterForm();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  setupFilterForm() {
    this.filterFromGroup = this.fb.group({
      search: [null],
    });

    this.filterFromGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        const params = _.merge({}, this.defaultParams, values);
        delete params.ordering;

        this.storedParams = _.merge(this.storedParams, params);
        this.list();
      });
  }

  list() {
    this.isLoading = true;

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }

    this.listSubscription$ = this.productService
      .product_practice_items(this.product.id, this.storedParams)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.rows = data.results;
        this.rowsCount = data.count;
        this.isLoading = false;
        this.rows.forEach((row: ProductPracticeItem) => {
          this.permissions[row.id] = {
            canEdit: this.productPracticeItemPermissionService.canEdit(row),
            canDelete: this.productPracticeItemPermissionService.canDelete(row),
          };
        });
      });
  }

  onPaginateChange($event: PageEvent) {
    this.uiHelperService.getUISettings().rowsPerPage = $event.pageSize;
    this.storedParams.page_size = $event.pageSize;
    this.storedParams.page = $event.pageIndex + 1;
    this.list();
  }

  onSortChange($event: Sort) {
    this.storedParams.ordering = `${$event.direction === 'desc' ? '-' : ''}${
      $event.active
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  create($event: MouseEvent) {
    $event.preventDefault();

    const dialogRef = this.dialog.open(
      ProductPracticeItemChangeDialogComponent,
      {
        width: '45%',
        data: {
          product_practice_item: {
            product: this.product.id,
          },
          commit: true,
        } as ProductPracticeItemChangeDialogData,
        disableClose: true,
        autoFocus: false,
      }
    );

    dialogRef.afterClosed().subscribe(practiceItemProduct => {
      if (!practiceItemProduct) {
        return;
      }
      this.list();
      this.uiHelperService.openSnackBar(`Practice Item Successfully Added`);
    });
  }

  edit($event: MouseEvent, productPracticeItem: ProductPracticeItem) {
    $event.preventDefault();

    const dialogRef = this.dialog.open(
      ProductPracticeItemChangeDialogComponent,
      {
        width: '45%',
        data: {
          product_practice_item: productPracticeItem,
          commit: true,
        } as ProductPracticeItemChangeDialogData,
        disableClose: true,
      }
    );

    dialogRef
      .afterClosed()
      .subscribe((changedProductPracticeItem?: ProductPracticeItem) => {
        if (!changedProductPracticeItem) {
          return;
        }
        this.store.dispatch(
          updateProductProductPracticeItemAction({
            payload: changedProductPracticeItem,
          })
        );
        this.uiHelperService.openSnackBar(`Item successfully updated`);
      });
  }

  delete($event: MouseEvent, productPracticeItem: ProductPracticeItem) {
    $event.preventDefault();

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '45%',
      data: {
        title: 'Delete item ?',
        content: `Do you want to delete item: ${productPracticeItem?.item_info.text}`,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      this.store.dispatch(toggleLoading({ payload: true }));
      this.productPracticeItemService
        .delete(productPracticeItem.id)
        .pipe(takeUntil(this.componentDestroyed$), first())
        .subscribe({
          next: () => {
            this.store.dispatch(
              deleteProductPracticeItemAction({ payload: productPracticeItem })
            );
            this.store.dispatch(toggleLoading({ payload: false }));
            this.uiHelperService.openSnackBar(`Item successfully deleted`);
          },
          error: (error: HttpErrorResponse) =>
            this.uiHelperService.handleUserRequestError(error),
        });
    });
  }
}
