<div class="row" *ngIf="initialized; else initialization">
  <div class="col-12">
    <app-table-filters [formGroup]="filterFromGroup" [params]="storedParams"
                       [overwritePropertyValues]="overwritePropertyValues"
                       [overwritePropertyLabels]="overwritePropertyLabels"
                       [excludedProperties]="excludedProperties" (formReset)="onFormReset($event)">

      <mat-accordion multi>
        <mat-expansion-panel [expanded]="true">
          <div class="row" *ngIf="fieldPermissions.search">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>Search</mat-label>
                <app-search-input formControlName="search"
                                  placeholder="Type to search something"></app-search-input>
              </mat-form-field>
            </div>
          </div>
          <div class="row" *ngIf="fieldPermissions.company_access_info">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>Included Companies</mat-label>
                <app-generic-model-chips formControlName="company_access_info"
                                         modelClassName="company_access" [panelWidth]="640"
                                         [initialValueIds]="storedParams.company_access"
                                         [filterParams]="{'user': currentUser.id}"></app-generic-model-chips>
              </mat-form-field>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              General
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="row" *ngIf="fieldPermissions.state">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>Project Status</mat-label>
                <mat-select formControlName="state" [multiple]="true">
                  <mat-option
                    *ngFor="
                                    let item of EEPProgramHomeStatusStateLabelMapping
                                      | defaultOrderKeyValue
                                  "
                    [value]="item.key"
                  >
                    {{ item.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row" *ngIf="fieldPermissions.eep_program_info">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>Program Name</mat-label>
                <app-generic-model-chips formControlName="eep_program_info" modelClassName="eep_program"
                                         [filterParams]="{home_status_related: homeStatusRelated, is_qa_program: false, ordering: 'name'}"
                                         [panelWidth]="640"
                                         [initialValueIds]="storedParams.eep_program"></app-generic-model-chips>
              </mat-form-field>
            </div>
          </div>
          <div class="row" *ngIf="fieldPermissions.subdivision_info">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>Subdivision / MF Development Name</mat-label>
                <app-generic-model-chips formControlName="subdivision_info" modelClassName="subdivision"
                                         [panelWidth]="640"
                                         [filterParams]="{home_status_related: homeStatusRelated, company_access: this.storedParams.company_access, name__isnull: false, ordering: 'name'}"
                                         [initialValueIds]="storedParams.home__subdivision"></app-generic-model-chips>
              </mat-form-field>
            </div>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Companies
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row" *ngIf="fieldPermissions.builder_organization_info">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>Builder / Client Company</mat-label>
                <app-generic-model-chips formControlName="builder_organization_info"
                                         modelClassName="company"
                                         [filterParams]="{company_type: CompanyType.builder, home_status_related: homeStatusRelated, ordering: 'name'}"
                                         [optionDisplay]="companyFilterOptionDisplay" [panelWidth]="640"
                                         [initialValueIds]="storedParams.builder_organization"></app-generic-model-chips>
              </mat-form-field>
            </div>
          </div>
          <div class="row" *ngIf="fieldPermissions.rater_organization_info">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>Rater / Verification Company</mat-label>
                <app-generic-model-chips formControlName="rater_organization_info" modelClassName="company"
                                         [filterParams]="{company_type: CompanyType.rater, home_status_related: homeStatusRelated, ordering: 'name'}"
                                         [optionDisplay]="companyFilterOptionDisplay" [panelWidth]="640"
                                         [initialValueIds]="storedParams.rater_organization"></app-generic-model-chips>
              </mat-form-field>
            </div>
          </div>
          <div class="row" *ngIf="fieldPermissions.provider_organization_info">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>Certification / Provider Organization</mat-label>
                <app-generic-model-chips formControlName="provider_organization_info"
                                         modelClassName="company"
                                         [filterParams]="{company_type: CompanyType.provider, home_status_related: homeStatusRelated, ordering: 'name'}"
                                         [optionDisplay]="companyFilterOptionDisplay" [panelWidth]="640"
                                         [initialValueIds]="storedParams.provider_organization"></app-generic-model-chips>
              </mat-form-field>
            </div>
          </div>
          <div class="row" *ngIf="fieldPermissions.eep_organization_info">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>Program Sponsor / EEP Organization</mat-label>
                <app-generic-model-chips formControlName="eep_organization_info" modelClassName="company"
                                         [filterParams]="{company_type: CompanyType.eep, home_status_related: homeStatusRelated, ordering: 'name'}"
                                         [optionDisplay]="companyFilterOptionDisplay" [panelWidth]="640"
                                         [initialValueIds]="storedParams.eep_organization"></app-generic-model-chips>
              </mat-form-field>
            </div>
          </div>
          <div class="row" *ngIf="fieldPermissions.utility_organization_info">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>Utility Company</mat-label>
                <app-generic-model-chips formControlName="utility_organization_info"
                                         modelClassName="company"
                                         [filterParams]="{company_type: CompanyType.utility, home_status_related: homeStatusRelated, ordering: 'name'}"
                                         [optionDisplay]="companyFilterOptionDisplay" [panelWidth]="640"
                                         [initialValueIds]="storedParams.utility_organization"></app-generic-model-chips>
              </mat-form-field>
            </div>
          </div>
          <div class="row" *ngIf="fieldPermissions.hvac_organization_info">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>HVAC Contractor</mat-label>
                <app-generic-model-chips formControlName="hvac_organization_info" modelClassName="company"
                                         [filterParams]="{company_type: CompanyType.hvac, home_status_related: homeStatusRelated, ordering: 'name'}"
                                         [optionDisplay]="companyFilterOptionDisplay" [panelWidth]="640"
                                         [initialValueIds]="storedParams.hvac_organization"></app-generic-model-chips>
              </mat-form-field>
            </div>
          </div>
          <div class="row" *ngIf="fieldPermissions.qa_organization_info">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>QA/QC Company</mat-label>
                <app-generic-model-chips formControlName="qa_organization_info" modelClassName="company"
                                         [filterParams]="{company_type: CompanyType.qa, home_status_related: homeStatusRelated, ordering: 'name'}"
                                         [optionDisplay]="companyFilterOptionDisplay" [panelWidth]="640"
                                         [initialValueIds]="storedParams.qa_organization"></app-generic-model-chips>
              </mat-form-field>
            </div>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Individuals
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="row" *ngIf="fieldPermissions.rater_of_record_info">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>Rater Of Record</mat-label>
                <app-generic-model-chips formControlName="rater_of_record_info" modelClassName="user"
                                         hasEmptyChoice="true" emptyChoiceLabel="Unassigned"
                                         [emptyChoiceValue]="'unassigned'" [panelWidth]="640"
                                         [filterParams]="{home_status_related_as_rater_of_record: homeStatusRelated, ordering: 'first_name'}"
                                         [initialValueIds]="storedParams.rater_of_record"></app-generic-model-chips>
              </mat-form-field>
            </div>
          </div>

          <div class="row" *ngIf="fieldPermissions.energy_modeler_info">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>Energy Modeler</mat-label>
                <app-generic-model-chips formControlName="energy_modeler_info" modelClassName="user"
                                         hasEmptyChoice="true" emptyChoiceLabel="Unassigned"
                                         [emptyChoiceValue]="'unassigned'" [panelWidth]="640"
                                         [filterParams]="{home_status_related_as_energy_modeler: homeStatusRelated, ordering: 'first_name'}"
                                         [initialValueIds]="storedParams.energy_modeler"></app-generic-model-chips>
              </mat-form-field>
            </div>
          </div>

          <div class="row" *ngIf="fieldPermissions.field_inspector_info">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>Field Inspector</mat-label>
                <app-generic-model-chips formControlName="field_inspector_info" modelClassName="user"
                                         hasEmptyChoice="true" emptyChoiceLabel="Unassigned"
                                         [emptyChoiceValue]="'unassigned'" [panelWidth]="640"
                                         [filterParams]="{home_status_related_as_field_inspector: homeStatusRelated, ordering: 'first_name'}"
                                         [initialValueIds]="storedParams.field_inspector"></app-generic-model-chips>
              </mat-form-field>
            </div>
          </div>

          <div class="row" *ngIf="fieldPermissions.customer_hirl_rough_verifier_info">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>Rough Verifier</mat-label>
                <app-generic-model-chips formControlName="customer_hirl_rough_verifier_info"
                                         modelClassName="user" [panelWidth]="640"
                                         [filterParams]="{company_type: CompanyType.rater, home_status_related_as_customer_hirl_rough_verifier: homeStatusRelated, ordering: 'first_name'}"
                                         [initialValueIds]="storedParams.customer_hirl_rough_verifier"></app-generic-model-chips>
              </mat-form-field>
            </div>
          </div>

          <div class="row" *ngIf="fieldPermissions.customer_hirl_final_verifier_info">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>Final Verifier</mat-label>
                <app-generic-model-chips formControlName="customer_hirl_final_verifier_info"
                                         modelClassName="user" [panelWidth]="640"
                                         [filterParams]="{company_type: CompanyType.rater, home_status_related_as_customer_hirl_final_verifier: homeStatusRelated, ordering: 'first_name'}"
                                         [initialValueIds]="storedParams.customer_hirl_final_verifier"></app-generic-model-chips>
              </mat-form-field>
            </div>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Location
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="row" *ngIf="fieldPermissions.home__city_info">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>City</mat-label>
                <app-generic-model-chips formControlName="home__city_info" modelClassName="city"
                                         [filterParams]="{ordering: 'name', home_status_related: homeStatusRelated}"
                                         [panelWidth]="640"
                                         [initialValueIds]="storedParams.home__city"></app-generic-model-chips>
              </mat-form-field>
            </div>
          </div>
          <div class="row" *ngIf="fieldPermissions.home__city__county_info">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>County</mat-label>
                <app-generic-model-chips formControlName="home__city__county_info" modelClassName="county"
                                         [filterParams]="{ordering: 'name', home_status_related: homeStatusRelated}"
                                         [panelWidth]="640"
                                         [initialValueIds]="storedParams.home__city__county"></app-generic-model-chips>
              </mat-form-field>
            </div>
          </div>
          <div class="row" *ngIf="fieldPermissions.home__metro_info">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>Metro</mat-label>
                <app-generic-model-chips formControlName="home__metro_info" modelClassName="metro"
                                         [filterParams]="{home_status_related: homeStatusRelated, ordering: 'name'}"
                                         [panelWidth]="640"
                                         [initialValueIds]="storedParams.home__metro"></app-generic-model-chips>
              </mat-form-field>
            </div>
          </div>

          <div class="row" *ngIf="fieldPermissions.home__state_info">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>US State</mat-label>
                <app-generic-model-chips formControlName="home__state_info" modelClassName="us_state"
                                         [filterParams]="{home_status_related: homeStatusRelated, ordering: 'name'}"
                                         [panelWidth]="640"
                                         [initialValueIds]="storedParams.home__state"></app-generic-model-chips>
              </mat-form-field>
            </div>
          </div>

          <div class="row" *ngIf="fieldPermissions.home__city__country_info">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>Country</mat-label>
                <app-generic-model-chips formControlName="home__city__country_info" modelClassName="country"
                                         [filterParams]="{ordering: 'name', home_status_related: homeStatusRelated}"
                                         [panelWidth]="640"
                                         [initialValueIds]="storedParams.home__city__country"></app-generic-model-chips>
              </mat-form-field>
            </div>
          </div>

          <div class="row" *ngIf="fieldPermissions.customer_eto_region">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>ETO Region</mat-label>
                <mat-select formControlName="customer_eto_region" [multiple]="true">
                  <mat-option
                    *ngFor="
                                    let item of ETORegionLabelMapping
                                      | defaultOrderKeyValue
                                  "
                    [value]="item.key"
                  >
                    {{ item.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="true"
                             *ngIf="fieldPermissions.qa_status__state ||
                             fieldPermissions.qa_status__qa_designee_info ||
                             fieldPermissions.qastatus__requirement__type ||
                             fieldPermissions.qastatus__hirl_badges_awarded_info ||
                             fieldPermissions.qastatus__observation__observation_type_info ||
                             fieldPermissions.home__task__assignees_info ||
                             fieldPermissions.home__task__task_type_info ||
                             fieldPermissions.qastatus__hirl_water_sense_confirmed ||
                             fieldPermissions.qastatus__hirl_commercial_space_confirmed">
          <mat-expansion-panel-header>
            <mat-panel-title>
              QA Options
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row" *ngIf="fieldPermissions.qa_status__state">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>QA Status</mat-label>
                <mat-select formControlName="qa_status__state" [multiple]="true">
                  <mat-option
                    *ngFor="
                                    let item of QAStatusStateLabelMapping
                                      | defaultOrderKeyValue
                                  "
                    [value]="item.key"
                  >
                    {{ item.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row" *ngIf="fieldPermissions.qa_status__qa_designee_info">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>QA Designee</mat-label>
                <app-generic-model-chips formControlName="qa_status__qa_designee_info" modelClassName="user"
                                         [panelWidth]="640"
                                         [filterParams]="qaStatusQADesigneeFilterParams"
                                         [initialValueIds]="storedParams.qa_status__qa_designee"
                                         [optionDisplay]="qaStatusQADesigneeOptionDisplay"></app-generic-model-chips>
              </mat-form-field>
            </div>
          </div>

          <div class="row" *ngIf="fieldPermissions.qastatus__requirement__type">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>QA Type</mat-label>
                <mat-select formControlName="qastatus__requirement__type" [multiple]="true">
                  <mat-option
                    *ngFor="
                                    let item of qaRequirementTypeChoicesLabelMapping
                                      | defaultOrderKeyValue
                                  "
                    [value]="item.key"
                  >
                    {{ item.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row" *ngIf="fieldPermissions.qastatus__hirl_badges_awarded_info">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>QA Green+ Badges Awarded</mat-label>
                <app-generic-model-chips formControlName="qastatus__hirl_badges_awarded_info"
                                         modelClassName="hirl_green_energy_badge"
                                         [panelWidth]="640"
                                         [initialValueIds]="storedParams.qastatus__hirl_badges_awarded"></app-generic-model-chips>
              </mat-form-field>
            </div>
          </div>

          <div class="row" *ngIf="fieldPermissions.qastatus__observation__observation_type_info">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>QA Observations</mat-label>
                <app-generic-model-chips formControlName="qastatus__observation__observation_type_info"
                                         modelClassName="observation_type"
                                         [filterParams]="{ordering: 'name'}" [panelWidth]="640"
                                         [initialValueIds]="storedParams.qastatus__observation__observation_type"></app-generic-model-chips>
              </mat-form-field>
            </div>
          </div>

          <div class="row" *ngIf="fieldPermissions.home__task__assignees_info">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>Task Assignee</mat-label>
                <app-generic-model-chips formControlName="home__task__assignees_info" modelClassName="user"
                                         [panelWidth]="640" [filterParams]="{company: currentUser.company}"
                                         [initialValueIds]="storedParams.home__task__assignees"></app-generic-model-chips>
              </mat-form-field>
            </div>
          </div>

          <div class="row" *ngIf="fieldPermissions.home__task__task_type_info">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>Task Type</mat-label>
                <app-generic-model-chips formControlName="home__task__task_type_info"
                                         modelClassName="scheduling_task_type" [panelWidth]="640"
                                         [initialValueIds]="storedParams.home__task__task_type"></app-generic-model-chips>
              </mat-form-field>
            </div>
          </div>

          <div class="row" *ngIf="fieldPermissions.qastatus__hirl_water_sense_confirmed">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>QA WaterSense Confirmed</mat-label>
                <mat-select
                  formControlName="qastatus__hirl_water_sense_confirmed"
                >
                  <mat-option [value]="null">Any</mat-option>
                  <mat-option [value]="'true'">Yes</mat-option>
                  <mat-option [value]="'false'">No</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row" *ngIf="fieldPermissions.qastatus__hirl_commercial_space_confirmed">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>QA Commercial Space Confirmed</mat-label>
                <mat-select
                  formControlName="qastatus__hirl_commercial_space_confirmed"
                >
                  <mat-option [value]="null">Any</mat-option>
                  <mat-option [value]="'true'">Yes</mat-option>
                  <mat-option [value]="'false'">No</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="true" *ngIf="fieldPermissions.customer_eto_fasttracksubmission__submit_status">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Project Tracker Options
            </mat-panel-title>
          </mat-expansion-panel-header>
            <div class="row" *ngIf="fieldPermissions.customer_eto_fasttracksubmission__submit_status">
              <div class="col-12">
                <mat-form-field class="w-100">
                  <mat-label>FastTrack Submit Status</mat-label>
                  <mat-select formControlName="customer_eto_fasttracksubmission__submit_status" [multiple]="true">
                    <mat-option
                      *ngFor="
                                    let item of EEPProgramHomeStatusRFastTrackSubmitStatusLabelMapping
                                      | defaultOrderKeyValue
                                  "
                      [value]="item.key"
                    >
                      {{ item.value }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="true"
                             *ngIf="fieldPermissions.customer_hirl_project__certification_level ||
                             fieldPermissions.customer_hirl_project__is_hud_project ||
                             fieldPermissions.customer_hirl_project__registration__is_build_to_rent ||
                             fieldPermissions.customer_hirl_project__appeals_project ||
                             fieldPermissions.customer_hirl_project__is_require_wri_certification ||
                             fieldPermissions.customer_hirl_project__green_energy_badges_info ||
                             fieldPermissions.customer_hirl_project__commercial_space_type ||
                             fieldPermissions.customer_hirl_project__sampling">
          <mat-expansion-panel-header>
            <mat-panel-title>
              NGBS-Specific Options
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row" *ngIf="fieldPermissions.customer_hirl_project__certification_level">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>Certification Level</mat-label>
                <mat-select formControlName="customer_hirl_project__certification_level" [multiple]="true">
                  <mat-option
                    *ngFor="
                                    let item of QAHIRLCertificationLevelAwardedLabelMapping
                                      | defaultOrderKeyValue
                                  "
                    [value]="item.key"
                  >
                    {{ item.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row" *ngIf="fieldPermissions.customer_hirl_project__is_hud_project">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>Is HUD Project?</mat-label>
                <mat-select
                  formControlName="customer_hirl_project__is_hud_project"
                >
                  <mat-option [value]="null">Any</mat-option>
                  <mat-option [value]="'true'">Yes</mat-option>
                  <mat-option [value]="'false'">No</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row" *ngIf="fieldPermissions.customer_hirl_project__registration__is_build_to_rent">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>Is Build-To-Rent?</mat-label>
                <mat-select
                  formControlName="customer_hirl_project__registration__is_build_to_rent"
                >
                  <mat-option [value]="null">Any</mat-option>
                  <mat-option [value]="'true'">Yes</mat-option>
                  <mat-option [value]="'false'">No</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row" *ngIf="fieldPermissions.customer_hirl_project__appeals_project">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>Is Appeals Project?</mat-label>
                <mat-select
                  formControlName="customer_hirl_project__appeals_project" [multiple]="true"
                >
                  <mat-option
                    *ngFor="
                                    let item of HIRLProjectAppealsProjectTypeMapping
                                      | defaultOrderKeyValue
                                  "
                    [value]="item.key"
                  >
                    {{ item.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row" *ngIf="fieldPermissions.customer_hirl_project__is_require_wri_certification">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>Includes WRI Certification?</mat-label>
                <mat-select
                  formControlName="customer_hirl_project__is_require_wri_certification"
                >
                  <mat-option [value]="null">Any</mat-option>
                  <mat-option [value]="'true'">Yes</mat-option>
                  <mat-option [value]="'false'">No</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row" *ngIf="fieldPermissions.customer_hirl_project__is_require_water_sense_certification">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>Is the project seeking WaterSense Certification?</mat-label>
                <mat-select
                  formControlName="customer_hirl_project__is_require_water_sense_certification"
                >
                  <mat-option [value]="null">Any</mat-option>
                  <mat-option [value]="'true'">Yes</mat-option>
                  <mat-option [value]="'false'">No</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row" *ngIf="fieldPermissions.customer_hirl_project__green_energy_badges_info">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>Green Energy Badges</mat-label>
                <app-generic-model-chips formControlName="customer_hirl_project__green_energy_badges_info"
                                         modelClassName="hirl_green_energy_badge" [panelWidth]="640"
                                         [initialValueIds]="storedParams.customer_hirl_project__green_energy_badges"></app-generic-model-chips>
              </mat-form-field>
            </div>
          </div>

          <div class="row" *ngIf="fieldPermissions.customer_hirl_project__commercial_space_type">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>Commercial Space Type</mat-label>
                <mat-select formControlName="customer_hirl_project__commercial_space_type">
                  <mat-option [value]="''"> Any</mat-option>
                  <mat-option
                    *ngFor="
                                    let item of HIRLProjectCommercialSpaceTypeLabelMapping
                                      | defaultOrderKeyValue
                                  "
                    [value]="item.key"
                  >
                    {{ item.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row" *ngIf="fieldPermissions.customer_hirl_project__sampling">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>Sampling</mat-label>
                <mat-select formControlName="customer_hirl_project__sampling" [multiple]="true">
                  <mat-option
                    *ngFor="
                                    let item of HIRLProjectSamplingMapping
                                      | defaultOrderKeyValue
                                  "
                    [value]="item.key"
                  >
                    {{ item.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>Special Financing Type</mat-label>
                <mat-select formControlName="customer_hirl_project__registration__financing_type"
                            [multiple]="true">
                  <mat-option
                    *ngFor="
                                    let item of CustomerHirlProjectRegistrationFinanceTypeLabelMapping
                                      | defaultOrderKeyValue
                                  "
                    [value]="item.key"
                  >
                    {{ item.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>Energy Path</mat-label>
                <mat-select
                  formControlName="customer_hirl_annotations__energy_path"
                  [multiple]="true"
                >
                  <mat-option [value]="null">Any</mat-option>
                  <mat-option [value]="'Alt. Bronze or Silver'">Alt. Bronze or Silver</mat-option>
                  <mat-option [value]="'Alt Bronze, Silver, Gold'">Alt Bronze, Silver, Gold</mat-option>
                  <mat-option [value]="'Alt Gold for Tropical'">Alt Gold for Tropical</mat-option>
                  <mat-option [value]="'Performance Path'">Performance Path</mat-option>
                  <mat-option [value]="'Prescriptive Path'">Prescriptive Path</mat-option>
                  <mat-option [value]="'ERI Target'">ERI Target</mat-option>
                  <mat-option [value]="'HERS Index Target'">HERS Index Target</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <mat-form-field class="w-100">
                <mat-label>Story Count From</mat-label>
                <input matInput type="number" placeholder="From"
                       formControlName="customer_hirl_project__story_count__gte">
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field class="w-100">
                <mat-label>Story Count To</mat-label>
                <input matInput type="number" placeholder="To"
                       formControlName="customer_hirl_project__story_count__lte">
              </mat-form-field>
            </div>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Date
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="row">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>Project Created Date</mat-label>
                <mat-date-range-input [rangePicker]="home__created_date_picker">
                  <input matStartDate formControlName="home__created_date__gte" placeholder="Start Date">
                  <input matEndDate formControlName="home__created_date__lte" placeholder="End Date">
                </mat-date-range-input>
                <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix
                                       (click)="filterFromGroup.get('home__created_date__gte').setValue(null); filterFromGroup.get('home__created_date__lte').setValue(null);">
                  <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker-toggle matSuffix [for]="home__created_date_picker"></mat-datepicker-toggle>
                <mat-date-range-picker #home__created_date_picker></mat-date-range-picker>

                <mat-error
                  *ngIf="filterFromGroup.controls.home__created_date__gte.hasError('matStartDateInvalid')">
                  Invalid start date
                </mat-error>
                <mat-error
                  *ngIf="filterFromGroup.controls.home__created_date__lte.hasError('matEndDateInvalid')">
                  Invalid end date
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>Program Added Date</mat-label>
                <mat-date-range-input [rangePicker]="created_date_picker">
                  <input matStartDate formControlName="created_date__gte" placeholder="Start Date">
                  <input matEndDate formControlName="created_date__lte" placeholder="End Date">
                </mat-date-range-input>
                <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix
                                       (click)="filterFromGroup.get('created_date__gte').setValue(null); filterFromGroup.get('created_date__lte').setValue(null);">
                  <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker-toggle matSuffix [for]="created_date_picker"></mat-datepicker-toggle>
                <mat-date-range-picker #created_date_picker></mat-date-range-picker>

                <mat-error
                  *ngIf="filterFromGroup.controls.created_date__gte.hasError('matStartDateInvalid')">Invalid
                  start date
                </mat-error>
                <mat-error *ngIf="filterFromGroup.controls.created_date__lte.hasError('matEndDateInvalid')">
                  Invalid end date
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label>Program Certification Date</mat-label>
                <mat-date-range-input [rangePicker]="certification_date_picker">
                  <input matStartDate formControlName="certification_date__gte" placeholder="Start Date">
                  <input matEndDate formControlName="certification_date__lte" placeholder="End Date">
                </mat-date-range-input>
                <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix
                                       (click)="filterFromGroup.get('certification_date__gte').setValue(null); filterFromGroup.get('certification_date__lte').setValue(null);">
                  <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker-toggle matSuffix [for]="certification_date_picker"></mat-datepicker-toggle>
                <mat-date-range-picker #certification_date_picker></mat-date-range-picker>

                <mat-error
                  *ngIf="filterFromGroup.controls.certification_date__gte.hasError('matStartDateInvalid')">
                  Invalid start date
                </mat-error>
                <mat-error
                  *ngIf="filterFromGroup.controls.certification_date__lte.hasError('matEndDateInvalid')">
                  Invalid end date
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </app-table-filters>
  </div>
</div>
<ng-template #initialization>
  <div class="skeleton block"></div>
</ng-template>
