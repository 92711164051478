export class PracticeStandard {
  id?: number;
  title?: string;
  is_active?: boolean;
}

export class PracticeStandardInfo {
  id?: number;
  title?: string;
  is_active?: boolean;
}
