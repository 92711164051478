import { ListRequestParams } from '@/core/schemes/request-params';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { AsynchronousProcessedDocument } from '@/data/filehandling/models';
import { Injectable } from '@angular/core';
import { ServerResponse } from '@/core/schemes/server-response';

export class AsynchronousProcessedDocumentRequestParams extends ListRequestParams {
  download?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AsynchronousProcessedDocumentService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/asynchronous_processed_document/`;

  list(
    params?: AsynchronousProcessedDocumentRequestParams
  ): Observable<ServerResponse<AsynchronousProcessedDocument>> {
    return this.http.get<ServerResponse<AsynchronousProcessedDocument>>(
      `${this.baseUrl}`,
      {
        params: this.rollParams(params),
      }
    );
  }

  retrieve(
    asynchronousProcessedDocumentId: number,
    options?: IDataServiceMethodOptions
  ): Observable<AsynchronousProcessedDocument> {
    const chain = this.http.get<AsynchronousProcessedDocument>(
      `${this.baseUrl}${asynchronousProcessedDocumentId}`,
      {}
    );
    return chain;
  }
}
