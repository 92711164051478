import { addNewSkylight } from '@/modules/simulation/state/simulation/actions';
import { selectSkylightIds } from '@/modules/simulation/state/simulation/selectors';
import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-skylights',
  templateUrl: './skylights.component.html',
  styleUrls: ['./skylights.component.scss'],
})
export class SkylightsComponent {
  @Input() expanded = false;
  componentDestroyed$ = new Subject();
  skylightIds: number[] = [];

  constructor(public store: Store) {
    this.store
      .select(selectSkylightIds())
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(ids => {
        this.skylightIds = ids;
      });
  }

  addSkylight() {
    this.store.dispatch(addNewSkylight());
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
