import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { first, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getInfoUser } from '@/state/selectors/info.selector';
import { AppState } from '@/state/reducers';
import { CompanyService } from '@/data/company/services/company-base.service';
import { Company, CompanyType } from '@/data/company/models';

@Injectable({
  providedIn: 'root',
})
export class CompanyPermissionService {
  constructor(
    public store: Store<AppState>,
    public companyService: CompanyService
  ) {}

  canCreate(companyType: CompanyType): Observable<ObjectPermissionResponse> {
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
    }).pipe(
      map(({ currentUser }) => {
        if (!currentUser.is_company_admin) {
          return new ObjectPermissionResponse(
            false,
            'Only Company Admin users can Create new companies'
          );
        }
        return new ObjectPermissionResponse(true);
      })
    );
  }

  canEdit(company: Company | number): Observable<ObjectPermissionResponse> {
    let company$: Observable<Company>;
    if (typeof company === 'number') {
      company$ = this.companyService.retrieve(company, { ignoreStore: true });
    } else {
      company$ = of(company);
    }
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: company$,
    }).pipe(
      map(({ currentUser, entity }) => {
        if (currentUser.is_superuser) {
          return new ObjectPermissionResponse(true);
        }
        if (currentUser.company === entity.id) {
          return new ObjectPermissionResponse(true);
        }
        if (entity.sponsor_slugs.includes(currentUser.company_info.slug)) {
          return new ObjectPermissionResponse(true);
        }
        if (entity.total_users === 0) {
          return new ObjectPermissionResponse(true);
        }
        return new ObjectPermissionResponse(
          false,
          'You do not have permissions to edit this Company'
        );
      })
    );
  }

  canDelete(company: Company | number): Observable<ObjectPermissionResponse> {
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
    }).pipe(
      map(({ currentUser }) => {
        if (!currentUser.is_superuser) {
          return new ObjectPermissionResponse(
            false,
            'Only AXIS Administrators can delete Companies'
          );
        }
        return new ObjectPermissionResponse(true);
      })
    );
  }
}
