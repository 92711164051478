import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HeaterService } from '@/data/simulation/services/heater.service';
import { catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as HeaterActions from './actions';
import * as SharedActions from '../shared/shared.actions';
import { HeaterValidator } from '../../validators/heater.validator';
import { StateModelName } from '../../state.registry';

@Injectable()
export class HeaterEffects {
  loadHeaters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(HeaterActions.loadHeaters),
      mergeMap(action => {
        const errors = HeaterValidator.validate(action.heaters);
        return of(
          HeaterActions.loadHeatersSuccess({
            heaters: action.heaters,
            errors,
          })
        );
      })
    );
  });

  updateHeater$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(HeaterActions.updateHeater),
      mergeMap(action => {
        return this.heaterService.update(action.heaterChanges).pipe(
          mergeMap(heater => {
            const errors = HeaterValidator.validate([heater]);
            return of(
              HeaterActions.loadHeatersSuccess({
                heaters: [heater],
                errors,
              }),
              SharedActions.updateCrossModelErrors({
                modelName: StateModelName.heater,
                changedFields: Object.keys(action.heaterChanges),
              })
            );
          }),
          catchError(error =>
            of(
              HeaterActions.updateHeaterFailure({
                id: action.heaterChanges.id,
              }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private heaterService: HeaterService
  ) {}
}
