import {
  SlabBackendDict,
  DetailedSlabBackendDict,
} from '@/data/simulation/models/enclosure/Slab';
import { createAction, props } from '@ngrx/store';
import { ModelErrors, StateErrors } from '../shared/base.state';

export const loadDetailedSlabs = createAction(
  '[Simulation API] Load Detailed Slabs',
  props<{ detailedSlabs: DetailedSlabBackendDict[] }>()
);

export const loadSlabsSuccess = createAction(
  '[Slab API] Load Slabs Success',
  props<{
    slabs: SlabBackendDict[];
    errors: StateErrors<SlabBackendDict>;
  }>()
);

export const updateSlab = createAction(
  '[Slab UI] Update Slab',
  props<{ id: number; slabChanges: Partial<SlabBackendDict> }>()
);

export const updateSlabSuccess = createAction(
  '[Slab API] Update Slab Success',
  props<{
    slab: SlabBackendDict;
    errors: ModelErrors<SlabBackendDict>;
  }>()
);

export const updateSlabFailure = createAction(
  '[Slab API] Update Slab Failure',
  props<{ id: number }>()
);

export const removeSlab = createAction(
  '[Slab UI] Remove Slab',
  props<{ slab: SlabBackendDict }>()
);

export const removeSlabSuccess = createAction(
  '[Slab API] Remove Slab Success',
  props<{ id: number }>()
);

export const removeSlabFailure = createAction(
  '[Slab API] Remove Slab Failure',
  props<{ id: number }>()
);

export const updateSlabErrors = createAction(
  '[Slab UI] Update Slab Errors',
  props<{ id: number; errors: any }>()
);

export const setSlabType = createAction(
  '[SlabType API] Set SlabType',
  props<{ slabId: number; slabTypeId: number | null }>()
);

export const setSlabTypeSuccess = createAction(
  '[SlabType API] Set SlabType null',
  props<{ slabId: number; slabTypeId: number | null }>()
);
