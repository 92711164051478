<div class="row">
  <div class="col-12">
    <div class="row mb-2">
      <div class="col-9">
        <form [formGroup]="filterFromGroup" class="inline-form">
          <mat-form-field>
            <mat-label>Search</mat-label>
            <app-search-input formControlName="search" placeholder="Type to search something"></app-search-input>
          </mat-form-field>
        </form>
      </div>
      <div class="col-3 text-right">
        <app-permission-mat-button [permissionResponse]="createPermissionResponse" (action)="create($event)">Add New</app-permission-mat-button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="row" *ngIf="isLoading">
          <div class="col-12">
            <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
          </div>
        </div>
        <table mat-table
               [dataSource]="rows"
               class="table"
               matSort
               [matSortActive]="storedParams.toTableSort().active"
               [matSortDirection]="storedParams.toTableSort().direction"
               (matSortChange)="onSortChange($event)">

          <ng-container matColumnDef="item">
            <th mat-header-cell *matHeaderCellDef>Item</th>
            <td mat-cell *matCellDef="let row">
              <b>{{ row.item_info?.item_number }}</b>
              <div [innerHTML]="row.item_info?.text | safe: 'text'"></div>

              <div *ngIf="row.item_info?.badges_info?.length">
                <div class="mt-2">
                  <b>Badges:</b>
                </div>
                <ng-container *ngFor="let badge of row.item_info?.badges_info; let isLast=last">
                  {{ badge.name}}{{isLast ? '' : ', '}}
                </ng-container>
              </div>

              <div *ngIf="row.item_info?.gresb_indicators_info?.length">
                <div class="mt-2">
                  <b>GRESB Indicators:</b>
                </div>
                <ng-container *ngFor="let gresb_indicators of row.item_info?.gresb_indicators_info; let isLast=last">
                  {{ gresb_indicators.name}}{{isLast ? '' : ', '}}
                </ng-container>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="practice">
            <th mat-header-cell *matHeaderCellDef>Practice</th>
            <td mat-cell *matCellDef="let row">
              {{ row.item_info?.practice_info?.title }}
            </td>
          </ng-container>

          <ng-container matColumnDef="section">
            <th mat-header-cell *matHeaderCellDef>Section</th>
            <td mat-cell *matCellDef="let row">
              {{ row.item_info?.practice_info?.section_info?.title }}
            </td>
          </ng-container>

          <ng-container matColumnDef="chapter">
            <th mat-header-cell *matHeaderCellDef>Chapter</th>
            <td mat-cell *matCellDef="let row">
              {{ row.item_info?.practice_info?.section_info?.chapter_info?.title }}
            </td>
          </ng-container>

          <ng-container matColumnDef="standard">
            <th mat-header-cell *matHeaderCellDef>Standard</th>
            <td mat-cell *matCellDef="let row">
              {{ row.item_info?.practice_info?.section_info?.chapter_info?.standard_info?.title }}
            </td>
          </ng-container>

          <ng-container matColumnDef="points">
            <th mat-header-cell *matHeaderCellDef>Points</th>
            <td mat-cell *matCellDef="let row">
              {{ row.points }}
            </td>
          </ng-container>

          <ng-container matColumnDef="used_to_earn_points">
            <th mat-header-cell *matHeaderCellDef>Used To Earn Points</th>
            <td mat-cell *matCellDef="let row">
              {{ row.used_to_earn_points }}
            </td>
          </ng-container>

          <ng-container matColumnDef="product_features">
            <th mat-header-cell *matHeaderCellDef>Product Features</th>
            <td mat-cell *matCellDef="let row">
              {{ row.product_features }}
            </td>
          </ng-container>

          <ng-container matColumnDef="customer_document">
            <th mat-header-cell *matHeaderCellDef>Document</th>
            <td mat-cell *matCellDef="let row">
              <app-customer-document-display [customerDocument]="row.customer_document_info" displayType="filenameAsLinkAndSharedLink"></app-customer-document-display>
            </td>
          </ng-container>

          <ng-container matColumnDef="is_active">
            <th mat-header-cell *matHeaderCellDef>Is Active</th>
            <td mat-cell *matCellDef="let row">
              {{ row.is_active | yesNo }}
            </td>
          </ng-container>


          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let row">
              <app-permission-mat-button [permissionResponse]="permissions[row.id]['canEdit']" displayType="asLink" (action)="edit($event, row);" class="mr-2">
                <fa-icon [icon]="['fas', 'edit']" matTooltip="Edit"></fa-icon>
              </app-permission-mat-button>
              <app-permission-mat-button [permissionResponse]="permissions[row.id]['canDelete']" displayType="asLink" (action)="delete($event, row);" class="mr-2">
                <fa-icon [icon]="['fas', 'trash']" matTooltip="Delete"></fa-icon>
              </app-permission-mat-button>
              <app-history-mat-button displayType="asLink" [objectId]="row.id" [modelClassName]="'product_practice_item'">
                <fa-icon [icon]="['fas', 'list']" matTooltip="History"></fa-icon>
              </app-history-mat-button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [length]="rowsCount"
                       [pageIndex]="$any(storedParams.page) - 1"
                       [pageSize]="storedParams.page_size"
                       (page)="onPaginateChange($event)"
                       aria-label="Select page">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
