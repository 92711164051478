import { createSelector } from '@ngrx/store';
import { SimulationState } from '../reducers';
import { selectSimulationFeature } from '../simulation.selectors';

export const selectSkylightType = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.skylightType.entities[id]
  );

const selectSkylightTypeLoadingStatus = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.skylightType.loading[id]
  );

export const selectSkylightViewSet = (id: number) =>
  createSelector(
    selectSkylightType(id),
    selectSkylightTypeLoadingStatus(id),
    (skylightType, loadingStatus) => ({
      data: skylightType,
      loading: loadingStatus,
    })
  );

const selectSkylightTypeName = (id: number) =>
  createSelector(selectSkylightType(id), skylightType => skylightType.name);

const selectSkylightTypeErrorCount = (id: number) =>
  createSelector(selectSimulationFeature, (state: SimulationState) => {
    return state.skylightType.errors[id].errorCount;
  });

export const selectSkylightTypeNameAndError = (id: number) =>
  createSelector(
    selectSkylightTypeName(id),
    selectSkylightTypeErrorCount(id),
    (name, errorCount) => {
      return {
        name,
        errorCount,
      };
    }
  );
