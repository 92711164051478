import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-run-simulation-status',
  templateUrl: './run-simulation-status.component.html',
  styleUrls: ['./run-simulation-status.component.scss'],
})
export class RunSimulationStatusComponent implements OnInit {
  steps = [];

  statusIcon = ['remove', 'pending', 'check', 'cancel'];

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<RunSimulationStatusComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data
  ) {}

  ngOnInit(): void {
    this.steps = this.data.steps;
  }
}
