import { ModelFormControl, createConditionalValidator } from '../ModelForm';
import { Validators } from '@angular/forms';
import {
  LengthUnit,
  StudType,
  WallCavityInsulationType,
} from '../../enumerations';

import { FieldLabels } from '../base';
import {
  aboveWarningRangeValidator,
  belowWarningRangeValidator,
  enumValidator,
  withinAshraeFramingFactorRangeValidator,
} from '@/modules/simulation/validators/helper';
import {
  LayerBackendDict,
  LayerFrameType,
  createNewLayer,
} from './AssemblyLayer';
import { MaterialItem, getMaterial } from './Material';
import { BaseModelWithAssemblyFormGroup } from './AssemblyCalculator';

// Labels
export const FIELD_LABELS: FieldLabels<AboveGradeWallTypeBackendDict> = {
  name: 'Name',
  stud_type: 'Stud Type',
  layers: 'Layers',
  frame_width: 'Frame Width',
  frame_depth: 'Frame Depth',
  frame_spacing: 'Frame Spacing',
  framing_factor: 'Framing Factor',
  use_default_framing_factor: 'Use default framing factor',
  cavity_insulation_material: 'Cavity Insulation Material',
  cavity_insulation_r_value: 'Cavity Insulation R-Value',
  cavity_insulation_thickness: 'Cavity Insulation Thickness',
  cavity_insulation_grade: 'Cavity Insulation Grade',
  continuous_insulation_r_value: 'Continuous Insulation R-Value',
  gypsum_thickness: 'Gypsum Thickness',
  units: 'Units',
  block_insulation_r_value: 'Block Insulation R-Value',
  assembly_u_value: 'Assembly U-Value',
  user_defined_assembly_u_value: 'Contains Assembly Details',
};

export interface AboveGradeWallTypeBackendDict {
  id: number | null;
  name: string;
  stud_type: StudType;
  layers: number[];
  frame_width: number;
  frame_depth: number;
  frame_spacing: number;
  framing_factor: number;
  use_default_framing_factor: boolean;
  cavity_insulation_material: WallCavityInsulationType;
  cavity_insulation_r_value: number;
  cavity_insulation_thickness: number;
  cavity_insulation_grade: number;
  continuous_insulation_r_value: number;
  gypsum_thickness: number;
  units: LengthUnit;
  block_insulation_r_value: number;
  assembly_u_value: number;
  user_defined_assembly_u_value: boolean;
}

export const DEFAULT_ABOVE_GRADE_WALL_TYPE_DICT: AboveGradeWallTypeBackendDict =
  {
    id: null,
    name: '',
    stud_type: null,
    layers: [],
    frame_width: 0,
    frame_depth: 0,
    frame_spacing: 0,
    framing_factor: 0,
    use_default_framing_factor: false,
    cavity_insulation_material: null,
    cavity_insulation_r_value: 0,
    cavity_insulation_thickness: 0,
    cavity_insulation_grade: 0,
    continuous_insulation_r_value: 0,
    gypsum_thickness: 0,
    units: LengthUnit.INCH,
    block_insulation_r_value: 0,
    assembly_u_value: 0,
    user_defined_assembly_u_value: false,
  };

export class AboveGradeWallTypeFormGroup extends BaseModelWithAssemblyFormGroup {
  classicAssemblyFields = [
    'stud_type',
    'frame_width',
    'frame_depth',
    'frame_spacing',
    'framing_factor',
    'cavity_insulation_material',
    'cavity_insulation_r_value',
    'cavity_insulation_thickness',
    'cavity_insulation_grade',
    'continuous_insulation_r_value',
    'gypsum_thickness',
    'block_insulation_r_value',
  ];

  frameType = LayerFrameType.ABOVE_GRADE_WALL;

  convertClassicFormToLayer() {
    const layers: LayerBackendDict[] = [
      {
        ...createNewLayer(),
        materials: [getMaterial(MaterialItem.SIDING_PLYWOOD_0P75)],
      },
    ];
    const instance: AboveGradeWallTypeBackendDict = this.value;

    if (instance.continuous_insulation_r_value) {
      layers.push(this.getContinuousInsulationLayer(instance));
    }

    if (instance.stud_type) {
      layers.push(this.getStudWall(instance));
    }

    if (instance.gypsum_thickness) {
      layers.push(this.getGypsumLayer(instance));
    }

    return layers;
  }

  constructor(aboveGradeWallType) {
    const validateOnlyIfClassicForm = createConditionalValidator(
      parentControls =>
        parentControls.layers.value.length === 0 &&
        !parentControls.user_defined_assembly_u_value.value,
      ['layers', 'user_defined_assembly_u_value']
    );
    const validateOnlyIfClassicStud = createConditionalValidator(
      parentControls =>
        parentControls.layers.value.length === 0 &&
        parentControls.stud_type.value !== null,
      ['stud_type']
    );

    const validateOnlyIfClassicStudWithoutDefaultFraming =
      createConditionalValidator(
        parentControls =>
          parentControls.layers.value.length === 0 &&
          parentControls.stud_type.value !== null &&
          !parentControls.use_default_framing_factor.value,
        ['stud_type', 'use_default_framing_factor']
      );

    super({
      id: new ModelFormControl(aboveGradeWallType.id),
      name: new ModelFormControl(aboveGradeWallType.name, Validators.required),
      assembly_u_value: new ModelFormControl(
        aboveGradeWallType.assembly_u_value,
        [Validators.required, Validators.min(0.0)]
      ),
      user_defined_assembly_u_value: new ModelFormControl(
        aboveGradeWallType.user_defined_assembly_u_value
      ),
      layers: new ModelFormControl(aboveGradeWallType.layers),
      stud_type: new ModelFormControl(
        aboveGradeWallType.stud_type,
        ...validateOnlyIfClassicForm([enumValidator(StudType, true)])
      ),

      frame_width: new ModelFormControl(
        aboveGradeWallType.frame_width,
        ...validateOnlyIfClassicStud([Validators.required])
      ),
      frame_depth: new ModelFormControl(
        aboveGradeWallType.frame_depth,
        ...validateOnlyIfClassicStud([Validators.required])
      ),
      frame_spacing: new ModelFormControl(
        aboveGradeWallType.frame_spacing,
        ...validateOnlyIfClassicStud([Validators.required])
      ),
      framing_factor: new ModelFormControl(
        aboveGradeWallType.framing_factor,
        ...validateOnlyIfClassicStudWithoutDefaultFraming([
          Validators.required,
          withinAshraeFramingFactorRangeValidator(0.25),
        ])
      ),

      use_default_framing_factor: new ModelFormControl(
        aboveGradeWallType.use_default_framing_factor
      ),
      cavity_insulation_material: new ModelFormControl(
        aboveGradeWallType.cavity_insulation_material,
        ...validateOnlyIfClassicStud([
          enumValidator(WallCavityInsulationType, true),
        ])
      ),
      cavity_insulation_r_value: new ModelFormControl(
        aboveGradeWallType.cavity_insulation_r_value,
        ...validateOnlyIfClassicStud([
          Validators.required,
          Validators.min(0),
          Validators.max(100),
          belowWarningRangeValidator(5),
          aboveWarningRangeValidator(95),
        ])
      ),
      cavity_insulation_thickness: new ModelFormControl(
        aboveGradeWallType.cavity_insulation_thickness
      ),
      cavity_insulation_grade: new ModelFormControl(
        aboveGradeWallType.cavity_insulation_grade,
        ...validateOnlyIfClassicStud([Validators.required])
      ),
      continuous_insulation_r_value: new ModelFormControl(
        aboveGradeWallType.continuous_insulation_r_value,
        ...validateOnlyIfClassicForm([Validators.required])
      ),
      gypsum_thickness: new ModelFormControl(
        aboveGradeWallType.gypsum_thickness
      ),
      block_insulation_r_value: new ModelFormControl(
        aboveGradeWallType.block_insulation_r_value
      ),
    });
  }
}
