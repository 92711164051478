import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  CreateSFHIRLProject,
  CreateSFHIRLProjectRegistration,
  HIRLGreenEnergyBadge,
  HIRLProjectRegistration,
  HIRLProjectRegistrationState,
  HIRLProjectAppealsProjectTypeMapping,
  HIRLProjectSamplingMapping,
  HIRLProjectSampling,
} from '@/data/customer-hirl/models';
import { EMPTY, forkJoin, Observable, of, Subject } from 'rxjs';
import { EEPProgram } from '@/data/eep-program/models';
import { ServerResponse } from '@/core/schemes/server-response';
import { User } from '@/data/core/models/user';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import {
  EEPProgramRequestParams,
  EEPProgramService,
} from '@/data/eep-program/services/eep-program.service';
import {
  HIRLGreenEnergyBadgeRequestParams,
  HIRLGreenEnergyBadgeService,
} from '@/data/customer-hirl/services/hirl-green-energy-badge.service';
import { UserService } from '@/data/core/services/user.service';
import { toggleLoading } from '@/state/actions/app.actions';
import { getInfoUser } from '@/state/selectors/info.selector';
import {
  catchError,
  debounceTime,
  defaultIfEmpty,
  distinctUntilChanged,
  first,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { GeocoderService } from '@/data/geocoder/services/geocoder.service';
import { conditionalValidator, objectValidator } from '@/core/validators';
import {
  ServerErrorDialogComponent,
  ServerErrorDialogConfig,
} from '@/shared/components/server-error-dialog/server-error-dialog.component';
import { HIRLProjectRegistrationService } from '@/data/customer-hirl/services/hirl-project-registration.service';
import { HIRLProjectService } from '@/data/customer-hirl/services/hirl-projects.service';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { ContactCard } from '@/data/core/models';
import { MatStepper } from '@angular/material/stepper';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';
import { SubdivisionSelectOrCreateDialogComponent } from '@/modules/subdivision/components/subdivision-select-or-create-dialog/subdivision-select-or-create-dialog.component';
import { Subdivision } from '@/data/subdivision/models';
import { SubdivisionService } from '@/data/subdivision/services/subdivision.service';
import {
  CompanyRequestParams,
  CompanyService,
} from '@/data/company/services/company-base.service';
import {
  Company,
  CompanyAutocompleteList,
  CompanyType,
} from '@/data/company/models';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { HIRLProjectAppealsProjectType } from '@/data/customer-hirl/models/hirl-project';
import { HIRLProjectSFRegistrationCertificateLineChoices } from '@/data/customer-hirl/models';
import { IGeocodeMatches } from '@/data/geocoder/models';
@Component({
  selector: 'app-hirl-project-registration-sf-form',
  templateUrl: './hirl-project-registration-sf-form.component.html',
  styleUrls: ['./hirl-project-registration-sf-form.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
  ],
})
export class HIRLProjectRegistrationSFFormComponent
  implements OnInit, OnDestroy
{
  protected readonly HIRLProjectSamplingMapping = HIRLProjectSamplingMapping;
  protected readonly HIRLProjectAppealsProjectTypeMapping =
    HIRLProjectAppealsProjectTypeMapping;

  @ViewChild('stepper') private stepper: MatStepper;

  public initialized = false;
  public editForm = false;
  public currentUser: User;

  public projectInformationFormGroup: UntypedFormGroup;
  public builderFormGroup: UntypedFormGroup;
  public projectFormGroup: UntypedFormGroup;
  public financialFormGroup: UntypedFormGroup;
  public certificateNamesFormGroup: UntypedFormGroup;

  public hirlProjectRegistration?: HIRLProjectRegistration;
  public selectedSubdivision: Subdivision;

  public hirlGreenEnergyBadges: HIRLGreenEnergyBadge[] = [];
  public eepPrograms: EEPProgram[];
  public selectedEEPProgram: EEPProgram;

  public bannedEEPProgramSlugs: string[] = [];

  public filteredBuilderOrganizations$: Observable<ServerResponse<Company>>;
  public HIRLProjectRegistrationState = HIRLProjectRegistrationState;

  // this key is using to save incomplete form data on create and restore it for next visit
  public localStorageFormDataSaveKey = 'project-registration-sf-form';

  public showDeveloperOrganizationContactCards = false;
  public developerOrganizationContactCards: ContactCard[] = [];

  public customerHIRLSettings = CustomerHIRLSettings;

  public certificateLineChoices =
    HIRLProjectSFRegistrationCertificateLineChoices;
  public certificateLineChoicesMapping = {
    [HIRLProjectSFRegistrationCertificateLineChoices.emptyLine]: 'Empty Line',
    [HIRLProjectSFRegistrationCertificateLineChoices.developmentName]:
      'Subdivision/Development Name',
    [HIRLProjectSFRegistrationCertificateLineChoices.addressLineDouble]:
      'Address (2 lines)',
    [HIRLProjectSFRegistrationCertificateLineChoices.clientCompanyNameWithCity]:
      'Client Company (2 lines)',
    [HIRLProjectSFRegistrationCertificateLineChoices.hudDisasterCaseNumber]:
      'HUD Disaster Case Number',
    [HIRLProjectSFRegistrationCertificateLineChoices.customText]:
      'Custom Text (black font)',
    [HIRLProjectSFRegistrationCertificateLineChoices.customTextGreen]:
      'Custom Text (green font)',
  };

  private destroy$ = new Subject();

  constructor(
    public fb: UntypedFormBuilder,
    public store: Store<AppState>,
    public router: Router,
    public subdivisionService: SubdivisionService,
    public hirlProjectService: HIRLProjectService,
    public hirlProjectRegistrationService: HIRLProjectRegistrationService,
    public builderOrganizationService: CompanyService,
    public eepProgramService: EEPProgramService,
    public hirlGreenEnergyBadgeService: HIRLGreenEnergyBadgeService,
    public dialog: MatDialog,
    public activatedRoute: ActivatedRoute,
    public geocoderService: GeocoderService,
    public userService: UserService,
    public uiHelperService: UIHelperService
  ) {
    if (this.activatedRoute.snapshot.params.projectRegistrationId) {
      this.editForm = true;
    }
  }

  public ngOnInit(): void {
    this.store.dispatch(toggleLoading({ payload: true }));

    const eepProgramRequestParams = new EEPProgramRequestParams();
    eepProgramRequestParams.ordering = 'name';
    eepProgramRequestParams.set = 'hirl_project_sf_programs';
    eepProgramRequestParams.includes_older_versions = false;
    const sources: { [k: string]: any } = {
      currentUser: this.store.select(getInfoUser).pipe(first()),
      eepPrograms: this.eepProgramService
        .hirl_project_programs(eepProgramRequestParams)
        .pipe(first()),
    };

    if (this.editForm) {
      sources.hirlProjectRegistration = this.hirlProjectRegistrationService
        .retrieve(this.activatedRoute.snapshot.params.projectRegistrationId)
        .pipe(first());
    }

    if (this.editForm) {
      if (
        (sources.currentUser.is_company_admin &&
          sources.currentUser.company_info?.slug ===
            CustomerHIRLSettings.companySlug) ||
        sources.currentUser.is_superuser
      ) {
        eepProgramRequestParams.includes_older_versions = true;
        sources.eepPrograms = this.eepProgramService
          .hirl_project_programs(eepProgramRequestParams)
          .pipe(first());
      }
    }

    forkJoin(sources).subscribe(
      ({ currentUser, eepPrograms, hirlProjectRegistration }) => {
        this.currentUser = currentUser;
        this.hirlProjectRegistration = hirlProjectRegistration;

        this.eepPrograms = eepPrograms;
        // remove 2012-2015 from list to prevent create NEW projects with this programs
        this.eepPrograms = this.eepPrograms.filter(eepProgram => {
          if (this.editForm) {
            return (
              !this.bannedEEPProgramSlugs.includes(eepProgram.slug) ||
              this.hirlProjectRegistration?.eep_program_info?.slug ===
                eepProgram.slug
            );
          }
          return !this.bannedEEPProgramSlugs.includes(eepProgram.slug);
        });

        const registrationSources: { [k: string]: any } = {};
        if (this.hirlProjectRegistration?.subdivision) {
          registrationSources.subdivision = this.subdivisionService
            .retrieve(this.hirlProjectRegistration.subdivision)
            .pipe(first());
        }
        forkJoin(registrationSources)
          .pipe(defaultIfEmpty({ subdivision: new Subdivision() }))
          .subscribe(({ subdivision }) => {
            this.selectedSubdivision = subdivision;
            if (this.editForm) {
              this.hydrateForm();
              this.store.dispatch(toggleLoading({ payload: false }));
              this.initialized = true;
            } else {
              this.store.dispatch(toggleLoading({ payload: false }));
              this.initialized = true;
              this.loadPreviousFormData();
            }
          });
      }
    );

    if (this.editForm) {
      this.setupProjectInformationFormGroup();
      this.setupFinancialFormGroup();
      this.setupBuilderFormGroup();
      this.setupCertificateNamesFormGroup();
      this.setupProjectFormGroup();
    } else {
      this.setupProjectInformationFormGroup();
      this.setupFinancialFormGroup();
      this.setupBuilderFormGroup();
      this.setupCertificateNamesFormGroup();
      this.setupProjectFormGroup();

      this.addHomeAddress();
    }
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public setupFinancialFormGroup() {
    this.financialFormGroup = this.fb.group({
      building_will_include_non_residential_space: [false],
      seeking_hud_mortgage_insurance_premium_reduction: [false],
      seeking_hud_green_and_resilient_retrofit_program: [false],
      seeking_fannie_mae_green_financing: [false],
      seeking_freddie_mac_green_financing: [false],
      intended_to_be_affordable_housing: [false],
    });
  }

  public setupProjectInformationFormGroup() {
    this.projectInformationFormGroup = this.fb.group({
      eep_program: [null, Validators.required],
      subdivision: [null],
      is_build_to_rent: [false, Validators.required],
    });

    // skip first reset if this is edit form
    let skipFirstReset = this.editForm;

    this.projectInformationFormGroup
      .get('eep_program')
      .valueChanges.subscribe(eepProgramId => {
        // filter and reset Green Energy Badges
        this.store.dispatch(toggleLoading({ payload: true }));
        this.hirlGreenEnergyBadgeService
          .list(
            Object.assign(new HIRLGreenEnergyBadgeRequestParams(), {
              eep_program: eepProgramId,
            })
          )
          .pipe(first())
          .subscribe(data => {
            this.hirlGreenEnergyBadges = data.results;
            this.hirlGreenEnergyBadges = data.results;
            this.selectedEEPProgram = this.eepPrograms.filter(
              eepProgram => eepProgram.id === eepProgramId
            )[0];

            // reset green_energy_badges after update
            if (skipFirstReset) {
              skipFirstReset = false;
            } else {
              if (!this.editForm) {
                const formArray = this.projectFormGroup.controls
                  .home_addresses as UntypedFormArray;
                for (let i = 0; i < formArray.length; i++) {
                  formArray.controls[i]
                    .get('green_energy_badges')
                    .patchValue([]);
                  formArray.controls[i]
                    .get('is_require_rough_inspection')
                    .patchValue(true);
                  formArray.controls[i]
                    .get('is_require_water_sense_certification')
                    .patchValue(false);
                }
              }
            }
            this.store.dispatch(toggleLoading({ payload: false }));
          });
      });
  }

  public setupBuilderFormGroup() {
    this.builderFormGroup = this.fb.group({
      builder_organization_info: [
        '',
        [
          Validators.required,
          objectValidator(),
          this.builderMustBeTheSameAsSubdivisionBuilderValidator(),
        ],
      ],
      builder_organization_contact: [null, Validators.required],
      is_coi_held_by_different_company: [false],
      coi_held_by_different_company: [
        null,
        conditionalValidator(
          () =>
            this.builderFormGroup.get('is_coi_held_by_different_company').value,
          Validators.required
        ),
      ],
      billing_email: [null, Validators.email],
    });

    this.builderFormGroup
      .get('is_coi_held_by_different_company')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        if (!value) {
          this.builderFormGroup
            .get('coi_held_by_different_company')
            .patchValue('', { emitEvent: true, onlySelf: true });
        }
      });

    // builder organization autocomplete
    this.filteredBuilderOrganizations$ = this.builderFormGroup
      .get('builder_organization_info')
      .valueChanges.pipe(
        takeUntil(this.destroy$),
        startWith(''),
        debounceTime(100),
        distinctUntilChanged(),
        switchMap(value =>
          this.builderOrganizationService
            .list(
              Object.assign(new CompanyRequestParams(), {
                search: value,
                is_attached: 'attached',
                page_size: 300,
                company_type: CompanyType.builder,
              })
            )
            .pipe(catchError(_ => EMPTY))
        )
      );
  }

  public setupCertificateNamesFormGroup() {
    this.certificateNamesFormGroup = this.fb.group(
      {
        certificate_line1: [
          HIRLProjectSFRegistrationCertificateLineChoices.emptyLine,
        ],
        certificate_line1_custom_text: [''],
        certificate_line2: [
          HIRLProjectSFRegistrationCertificateLineChoices.addressLineDouble,
        ],
        certificate_line2_custom_text: [''],
        certificate_line3: [
          HIRLProjectSFRegistrationCertificateLineChoices.emptyLine,
        ],
        certificate_line3_custom_text: [''],
        certificate_line4: [
          HIRLProjectSFRegistrationCertificateLineChoices.hudDisasterCaseNumber,
        ],
        certificate_line4_custom_text: [''],
        certificate_line5: [
          HIRLProjectSFRegistrationCertificateLineChoices.customText,
        ],
        certificate_line5_custom_text: ['BUILT BY'],
        certificate_line6: [
          HIRLProjectSFRegistrationCertificateLineChoices.clientCompanyNameWithCity,
        ],
        certificate_line6_custom_text: [''],
        certificate_line7: [
          HIRLProjectSFRegistrationCertificateLineChoices.emptyLine,
        ],
        certificate_line7_custom_text: [''],
        certificate_line8: [
          HIRLProjectSFRegistrationCertificateLineChoices.emptyLine,
        ],
        certificate_line8_custom_text: [''],
        certificate_line9: [
          HIRLProjectSFRegistrationCertificateLineChoices.emptyLine,
        ],
        certificate_line9_custom_text: [''],
      },
      { validator: this.addressClientNameValidator }
    );
  }

  addressClientNameValidator(
    group: UntypedFormGroup
  ): { [key: string]: any } | null {
    const fields = [
      'certificate_line1',
      'certificate_line2',
      'certificate_line3',
      'certificate_line4',
      'certificate_line5',
      'certificate_line6',
      'certificate_line7',
      'certificate_line8',
      'certificate_line9',
    ];

    const customTextFields = [
      'certificate_line1_custom_text',
      'certificate_line2_custom_text',
      'certificate_line3_custom_text',
      'certificate_line4_custom_text',
      'certificate_line5_custom_text',
      'certificate_line6_custom_text',
      'certificate_line7_custom_text',
      'certificate_line8_custom_text',
      'certificate_line9_custom_text',
    ];

    const hasAddress = fields.some((fieldName, index) => {
      const field = group.get(fieldName);
      const customTextField = group.get(customTextFields[index]);
      return (
        (field &&
          field.value ===
            HIRLProjectSFRegistrationCertificateLineChoices.addressLineDouble) ||
        (customTextField &&
          customTextField.value.toLowerCase().includes('address'))
      );
    });

    const hasClientName = fields.some((fieldName, index) => {
      const field = group.get(fieldName);
      const customTextField = group.get(customTextFields[index]);
      return (
        (field &&
          field.value ===
            HIRLProjectSFRegistrationCertificateLineChoices.clientCompanyNameWithCity) ||
        (customTextField &&
          customTextField.value.toLowerCase().includes('client company name'))
      );
    });

    return hasAddress && hasClientName
      ? null
      : { addressAndClientNameRequired: true };
  }

  public setupProjectFormGroup() {
    this.projectFormGroup = this.fb.group({
      home_addresses: this.fb.array([]),
    });
  }

  public attachFieldCheckHomeWithAddressAlreadyExists(
    formGroup,
    fieldName,
    isMultiFamily
  ) {
    formGroup
      .get(fieldName)
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(confirmedGeocodeResponse => {
        if (!confirmedGeocodeResponse) {
          return;
        }
        this.store.dispatch(toggleLoading({ payload: true }));
        this.hirlProjectService
          .homeAddressIsUnique({
            project: null,
            street_line1: confirmedGeocodeResponse.addressInfo.street_line1,
            street_line2: confirmedGeocodeResponse.addressInfo.street_line2,
            city: confirmedGeocodeResponse.addressInfo.city_info.id,
            zipcode: confirmedGeocodeResponse.addressInfo.zipcode,
            is_multi_family: isMultiFamily,
            geocode_response: confirmedGeocodeResponse.geocodeBrokeredResponse
              ? confirmedGeocodeResponse.geocodeBrokeredResponse.id
              : null,
          })
          .pipe(first())
          .subscribe(isUnique => {
            this.store.dispatch(toggleLoading({ payload: false }));
            if (isUnique) {
              formGroup
                .get(fieldName)
                .setErrors({ home_with_this_address_already_exists: null });
              formGroup.get(fieldName).updateValueAndValidity({
                emitEvent: false,
              });
            } else {
              formGroup
                .get(fieldName)
                .setErrors({ home_with_this_address_already_exists: true });
            }
          });
      });
  }

  public builderMustBeTheSameAsSubdivisionBuilderValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let isValid = true;

      if (this.selectedSubdivision?.builder_org_info?.id && control.value?.id) {
        if (
          control.value?.id !== this.selectedSubdivision?.builder_org_info?.id
        ) {
          isValid = false;
        }
      }

      if (!isValid) {
        return {
          builderMustBeTheSameAsSubdivisionBuilder: true,
        };
      }

      return null;
    };
  }

  public uniqueAddressValidator(fieldName, index): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let isValid = true;
      const formArray =
        control.parent && control.parent.parent
          ? (control.parent.parent as UntypedFormArray)
          : null;

      if (formArray) {
        formArray.controls.forEach((existingControl, i) => {
          if (index !== i) {
            if (
              existingControl.value &&
              existingControl.value[fieldName] &&
              control.value
            ) {
              if (
                existingControl.value[fieldName].addressInfo.isEqual(
                  control.value.addressInfo
                )
              ) {
                isValid = false;
                return;
              }
            }
          }
        });
      }
      if (!isValid) {
        return {
          home_with_this_address_already_exists: true,
        };
      }

      return null;
    };
  }

  public getAddressFromLastHomeAddressForm() {
    if (
      !this.projectFormGroup.value ||
      !this.projectFormGroup.value.home_addresses ||
      this.projectFormGroup.value.home_addresses.length === 0
    ) {
      return null;
    }
    const formValue =
      this.projectFormGroup.value.home_addresses[
        this.projectFormGroup.value.home_addresses.length - 1
      ];
    return formValue.address;
  }

  public addHomeAddress(): void {
    const control = this.projectFormGroup.controls
      .home_addresses as UntypedFormArray;
    const group = this.fb.group({
      address: [
        this.getAddressFromLastHomeAddressForm(),
        [
          Validators.required,
          this.uniqueAddressValidator('address', control.length),
        ],
      ],
      green_energy_badges: [[]],
      hud_disaster_case_number: [''],
      is_accessory_structure: [false, Validators.required],
      accessory_structure_description: [
        '',
        conditionalValidator(
          () => group.get('is_accessory_structure').value,
          Validators.required
        ),
      ],
      accessory_structure_street_line_2: [
        null,
        conditionalValidator(
          () => group.get('is_accessory_structure').value,
          Validators.required
        ),
      ],
      is_accessory_dwelling_unit: [false, Validators.required],
      accessory_dwelling_unit_description: [
        '',
        conditionalValidator(
          () => group.get('is_accessory_dwelling_unit').value,
          Validators.required
        ),
      ],
      accessory_dwelling_unit_street_line_2: [
        null,
        conditionalValidator(
          () => group.get('is_accessory_dwelling_unit').value,
          Validators.required
        ),
      ],
      is_require_water_sense_certification: [false],
      is_require_rough_inspection: [true],
      is_require_wri_certification: [false],
      appeals_project: [HIRLProjectAppealsProjectType.no],
      sampling: [HIRLProjectSampling.no_sampling, Validators.required],
    });

    group
      .get('is_accessory_structure')
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(value => {
        group.patchValue(
          {
            accessory_structure_description: '',
            accessory_structure_street_line_2: '',
          },
          { emitEvent: false }
        );

        group.get('accessory_structure_description').reset();
        group.get('accessory_structure_street_line_2').reset();

        group.get('accessory_structure_description').setErrors(null);
        group.get('accessory_structure_street_line_2').setErrors(null);
      });

    group
      .get('is_accessory_dwelling_unit')
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(value => {
        group.patchValue(
          {
            accessory_dwelling_unit_description: '',
            accessory_dwelling_unit_street_line_2: '',
          },
          { emitEvent: false }
        );

        group.get('accessory_dwelling_unit_description').reset();
        group.get('accessory_dwelling_unit_street_line_2').reset();

        group.get('accessory_dwelling_unit_description').setErrors(null);
        group.get('accessory_dwelling_unit_street_line_2').setErrors(null);
      });

    this.attachFieldCheckHomeWithAddressAlreadyExists(group, 'address', false);

    control.push(group);
  }

  public handleRemoveHomeAddress(event, index: number): void {
    event.preventDefault();
    const control = this.projectFormGroup.controls
      .home_addresses as UntypedFormArray;
    control.removeAt(index);
  }

  accessoryStructureFormData(geocode, homeAddressData): CreateSFHIRLProject {
    const projectFormData = new CreateSFHIRLProject();
    projectFormData.is_accessory_structure = true;
    projectFormData.accessory_structure_description =
      homeAddressData.accessory_structure_description;

    projectFormData.lot_number = homeAddressData.address.lotNumber;
    projectFormData.home_address_geocode = geocode.id;
    projectFormData.home_address_geocode_response = null;
    projectFormData.is_require_water_sense_certification =
      homeAddressData.is_require_water_sense_certification;
    projectFormData.is_require_rough_inspection =
      homeAddressData.is_require_rough_inspection;

    return projectFormData;
  }

  accessoryDwellingUnitFormData(geocode, homeAddressData): CreateSFHIRLProject {
    const projectFormData = new CreateSFHIRLProject();
    projectFormData.is_accessory_dwelling_unit = true;
    projectFormData.accessory_dwelling_unit_description =
      homeAddressData.accessory_dwelling_unit_description;

    projectFormData.lot_number = homeAddressData.address.lotNumber;
    projectFormData.home_address_geocode = geocode.id;
    projectFormData.home_address_geocode_response = null;
    projectFormData.is_require_water_sense_certification =
      homeAddressData.is_require_water_sense_certification;
    projectFormData.is_require_rough_inspection =
      homeAddressData.is_require_rough_inspection;

    return projectFormData;
  }

  handleCreate(event) {
    event.preventDefault();

    this.projectInformationFormGroup.markAllAsTouched();
    this.financialFormGroup.markAllAsTouched();
    this.builderFormGroup.markAllAsTouched();

    if (
      this.projectInformationFormGroup.invalid ||
      this.builderFormGroup.invalid ||
      this.financialFormGroup.invalid
    ) {
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));
    const accessoryStructureGeocodes = [];

    const formData = new CreateSFHIRLProjectRegistration();
    formData.eep_program = this.projectInformationFormGroup.value.eep_program;
    formData.builder_organization =
      this.builderFormGroup.value.builder_organization_info.id;
    formData.builder_organization_contact =
      this.builderFormGroup.value.builder_organization_contact;
    formData.billing_email = this.builderFormGroup.value.billing_email;
    formData.subdivision = this.selectedSubdivision?.id;
    formData.is_build_to_rent =
      this.projectInformationFormGroup.value.is_build_to_rent;

    formData.coi_held_by_different_company =
      this.builderFormGroup.value.coi_held_by_different_company || '';

    formData.building_will_include_non_residential_space =
      this.financialFormGroup.value.building_will_include_non_residential_space;
    formData.seeking_hud_mortgage_insurance_premium_reduction =
      this.financialFormGroup.value.seeking_hud_mortgage_insurance_premium_reduction;
    formData.seeking_hud_green_and_resilient_retrofit_program =
      this.financialFormGroup.value.seeking_hud_green_and_resilient_retrofit_program;
    formData.seeking_fannie_mae_green_financing =
      this.financialFormGroup.value.seeking_fannie_mae_green_financing;
    formData.seeking_freddie_mac_green_financing =
      this.financialFormGroup.value.seeking_freddie_mac_green_financing;
    formData.intended_to_be_affordable_housing =
      this.financialFormGroup.value.intended_to_be_affordable_housing;

    for (const homeAddressData of this.projectFormGroup.controls.home_addresses
      .value) {
      const projectFormData = new CreateSFHIRLProject();
      projectFormData.is_accessory_structure = false;
      projectFormData.accessory_structure_description = '';
      projectFormData.green_energy_badges = homeAddressData.green_energy_badges;
      projectFormData.hud_disaster_case_number =
        homeAddressData.hud_disaster_case_number;
      projectFormData.is_require_water_sense_certification =
        homeAddressData.is_require_water_sense_certification;
      projectFormData.appeals_project = homeAddressData.appeals_project;
      projectFormData.sampling = homeAddressData.sampling;

      if (
        this.customerHIRLSettings.waterSenseProgramList.includes(
          this.selectedEEPProgram?.slug
        )
      ) {
        projectFormData.is_require_wri_certification =
          homeAddressData.is_require_wri_certification;
      } else {
        projectFormData.is_require_wri_certification = false;
      }

      projectFormData.lot_number = homeAddressData.address.lotNumber;
      projectFormData.home_address_geocode = homeAddressData.address.geocode.id;
      projectFormData.home_address_geocode_response = null;

      if (
        homeAddressData.address.geocode?.raw_city_info?.country_info?.abbr ===
          'PR' ||
        homeAddressData.address.geocode?.raw_city_info?.country_info?.abbr ===
          'VI'
      ) {
        projectFormData.is_require_rough_inspection = false;
      } else if (
        this.customerHIRLSettings.wriProgramList.includes(
          this.selectedEEPProgram?.slug
        )
      ) {
        // skip rough inspection for WRI programs
        projectFormData.is_require_rough_inspection = false;
        projectFormData.is_accessory_structure = false;
      } else {
        projectFormData.is_require_rough_inspection =
          homeAddressData.is_require_rough_inspection;
      }

      if (homeAddressData.address.geocodeBrokeredResponse) {
        projectFormData.home_address_geocode_response =
          homeAddressData.address.geocodeBrokeredResponse.id;
      }

      if (
        homeAddressData.is_accessory_structure &&
        !this.isWRIProgramSelected()
      ) {
        // to keep order add our data via tap
        accessoryStructureGeocodes.push(
          this.geocoderService
            .matches({
              street_line1: homeAddressData.address.geocode.raw_street_line1,
              street_line2: homeAddressData.accessory_structure_street_line_2,
              city: homeAddressData.address.geocode.raw_city,
              zipcode: homeAddressData.address.geocode.raw_zipcode,
            } as IGeocodeMatches)
            .pipe(
              first(),
              tap(geocode =>
                formData.projects.push(
                  this.accessoryStructureFormData(geocode, homeAddressData)
                )
              )
            )
        );
      }
      if (
        homeAddressData.is_accessory_dwelling_unit &&
        !this.isWRIProgramSelected()
      ) {
        // to keep order add our data via tap
        accessoryStructureGeocodes.push(
          this.geocoderService
            .matches({
              street_line1: homeAddressData.address.geocode.raw_street_line1,
              street_line2:
                homeAddressData.accessory_dwelling_unit_street_line_2,
              city: homeAddressData.address.geocode.raw_city,
              zipcode: homeAddressData.address.geocode.raw_zipcode,
            } as IGeocodeMatches)
            .pipe(
              first(),
              tap(geocode =>
                formData.projects.push(
                  this.accessoryDwellingUnitFormData(geocode, homeAddressData)
                )
              )
            )
        );
      }

      accessoryStructureGeocodes.push(
        of(formData.projects.push(projectFormData))
      );
    }

    forkJoin(accessoryStructureGeocodes)
      .pipe(defaultIfEmpty(null))
      .subscribe(_ => {
        this.hirlProjectRegistrationService
          .create_single_family(formData)
          .subscribe(
            project => {
              this.store.dispatch(toggleLoading({ payload: false }));
              // remove saved un complete data, because our form was successfully saved
              localStorage.removeItem(this.localStorageFormDataSaveKey);

              this.router.navigate([`/hi/projects/`]);
            },
            error => this.uiHelperService.handleUserRequestError(error)
          );
      });
  }

  handleEdit(event): void {
    event.preventDefault();
    this.store.dispatch(toggleLoading({ payload: true }));

    const formData = new HIRLProjectRegistration();

    formData.eep_program = this.projectInformationFormGroup.value.eep_program;
    formData.builder_organization =
      this.builderFormGroup.value.builder_organization_info.id;
    formData.builder_organization_contact =
      this.builderFormGroup.value.builder_organization_contact;
    formData.billing_email = this.builderFormGroup.value.billing_email;
    formData.subdivision = this.selectedSubdivision?.id;
    formData.is_build_to_rent =
      this.projectInformationFormGroup.value.is_build_to_rent;

    formData.coi_held_by_different_company =
      this.builderFormGroup.value.coi_held_by_different_company || '';

    formData.building_will_include_non_residential_space =
      this.financialFormGroup.value.building_will_include_non_residential_space;
    formData.seeking_hud_mortgage_insurance_premium_reduction =
      this.financialFormGroup.value.seeking_hud_mortgage_insurance_premium_reduction;
    formData.seeking_hud_green_and_resilient_retrofit_program =
      this.financialFormGroup.value.seeking_hud_green_and_resilient_retrofit_program;
    formData.seeking_fannie_mae_green_financing =
      this.financialFormGroup.value.seeking_fannie_mae_green_financing;
    formData.seeking_freddie_mac_green_financing =
      this.financialFormGroup.value.seeking_freddie_mac_green_financing;
    formData.intended_to_be_affordable_housing =
      this.financialFormGroup.value.intended_to_be_affordable_housing;

    this.hirlProjectRegistrationService
      .update(formData, this.hirlProjectRegistration.id)
      .subscribe(
        hirlProjectRegistration => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.router.navigate([
            `/hi/project_registrations/${hirlProjectRegistration.id}`,
          ]);
        },
        error => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialog.open(ServerErrorDialogComponent, {
            width: '400px',
            data: {
              title: 'Error:',
              data: error,
            } as ServerErrorDialogConfig,
          });
        }
      );
  }

  hydrateForm() {
    const payload = Object.assign({}, this.hirlProjectRegistration);
    this.projectInformationFormGroup.patchValue(payload, {
      emitEvent: false,
      onlySelf: true,
    });
    this.projectInformationFormGroup.patchValue(
      {
        subdivision: this.hirlProjectRegistration?.subdivision_info?.name,
      },
      { emitEvent: false, onlySelf: true }
    );
    this.builderFormGroup.patchValue(payload, {
      emitEvent: false,
      onlySelf: true,
    });
    this.financialFormGroup.patchValue(payload, {
      emitEvent: false,
      onlySelf: true,
    });

    if (this.hirlProjectRegistration.coi_held_by_different_company) {
      this.builderFormGroup
        .get('is_coi_held_by_different_company')
        .patchValue(true, { emitEvent: false, onlySelf: true });
    }

    if (this.hirlProjectRegistration.builder_organization_info) {
      this.builderFormGroup.patchValue(
        {
          builder_organization_info:
            this.hirlProjectRegistration.builder_organization_info,
        },
        {
          emitEvent: true,
          onlySelf: true,
        }
      );
    }
  }

  handleBack() {
    if (this.editForm) {
      this.router.navigate([
        'hi',
        'project_registrations',
        this.hirlProjectRegistration.id,
      ]);
    } else {
      this.router.navigate(['hi', 'projects']);
    }
  }

  getHomeAddressesControls() {
    return (this.projectFormGroup.get('home_addresses') as UntypedFormArray)
      .controls;
  }

  displayBuilderOrganizationMenu(builderOrganization) {
    if (builderOrganization) {
      return `${builderOrganization.name}`;
    }
  }

  loadPreviousFormData() {
    let formData;
    try {
      formData = JSON.parse(
        localStorage.getItem(this.localStorageFormDataSaveKey)
      );
    } catch (e) {
      return;
    }

    if (!formData) {
      return;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      disableClose: true,
      data: {
        title: 'Welcome Back !',
        content:
          'Looks like you did not complete the form last time. ' +
          "Click 'Confirm' to start where you left off or click 'Cancel' to start again",
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      this.projectInformationFormGroup.patchValue(formData, {
        emitEvent: true,
        onlySelf: true,
      });
      this.financialFormGroup.patchValue(formData, {
        emitEvent: true,
        onlySelf: true,
      });
      this.builderFormGroup.patchValue(formData, {
        emitEvent: true,
        onlySelf: true,
      });
    });
  }

  onStepChange(event: any) {
    if (this.editForm) {
      return;
    }
    const formData = {
      ...{
        eep_program: this.projectInformationFormGroup.value.eep_program,
        is_build_to_rent:
          this.projectInformationFormGroup.value.is_build_to_rent,
      },
      ...this.builderFormGroup.value,
      ...this.financialFormGroup.value,
      ...this.projectFormGroup.value,
    };

    localStorage.setItem(
      this.localStorageFormDataSaveKey,
      JSON.stringify(formData)
    );
  }

  handleChangeSubdivision($event: MouseEvent | FocusEvent) {
    $event.preventDefault();

    const dialogRef = this.dialog.open(
      SubdivisionSelectOrCreateDialogComponent,
      {
        width: '45%',
        data: {
          subdivision: this.selectedSubdivision,
        },
        autoFocus: false,
      }
    );

    dialogRef.afterClosed().subscribe(subdivisionSelectOrCreateDialogResult => {
      this.projectInformationFormGroup.patchValue({
        subdivision: subdivisionSelectOrCreateDialogResult.subdivision?.name,
      });
      this.selectedSubdivision =
        subdivisionSelectOrCreateDialogResult.subdivision;
      if (subdivisionSelectOrCreateDialogResult.subdivision) {
        if (
          subdivisionSelectOrCreateDialogResult.subdivision?.builder_org_info
            ?.id
        ) {
          this.builderFormGroup.patchValue(
            {
              builder_organization_info:
                subdivisionSelectOrCreateDialogResult.subdivision
                  ?.builder_org_info,
            },
            {
              emitEvent: true,
              onlySelf: true,
            }
          );
        }
      }
    });
  }

  isWRIProgramSelected() {
    return this.customerHIRLSettings.wriProgramList.includes(
      this.selectedEEPProgram?.slug
    );
  }

  buildToRentDeveloperOptionDisplay(value: CompanyAutocompleteList): string {
    return `${value.name} [${value.city_name}, ${value.state}]`;
  }
}
