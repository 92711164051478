<div class="container-lg" *ngIf="initialized">
  <div class="row">
    <div class="col-12">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#" [routerLink]="['/', ]">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            <app-user-display [user]="entity"></app-user-display>
          </li>
        </ol>
      </nav>
    </div>
  </div>
  <div class="d-flex-column mb-2">
    <div class="d-flex flex-row">
      <div class="flex-fill align-self-end">
        <h3 class="mb-0">
          <app-user-display [user]="entity"></app-user-display>
        </h3>
      </div>
      <div class="d-flex text-right">

        <app-permission-mat-button [permissionResponse]="changePasswordPermissionResponse" [routerLink]="['/', 'password_change']" class="mr-1" [hideOnFalse]="true">
          Change password
        </app-permission-mat-button>
        <app-permission-mat-button [permissionResponse]="editPermissionResponse" (click)="onEdit($event)" class="mr-1">
          Edit
        </app-permission-mat-button>
        <app-history-mat-button [objectId]="entity.id" [modelClassName]="'user'">
          History
        </app-history-mat-button>
      </div>
    </div>
    <mat-divider class="mt-1 mb-2"></mat-divider>
    <div class="d-flex flex-row">
      <div class="d-flex-column flex-fill">
        <div class="d-flex flex-row">
          <div class="entity-attr-name">AXIS ID:</div>
          <div class="flex-fill">{{ entity.id }}</div>
        </div>
        <div class="mt-2"></div>
        <div class="d-flex flex-row">
          <div class="entity-attr-name">Full Name:</div>
          <div class="flex-fill">{{ entity.first_name }} {{ entity.last_name }}</div>
        </div>
        <div class="mt-2"></div>
        <div class="d-flex flex-row">
          <div class="entity-attr-name">Title:</div>
          <div class="flex-fill">{{ entity.title || '-' }}</div>
        </div>
        <div class="mt-2"></div>

        <div class="d-flex flex-row">
          <div class="entity-attr-name">Email:</div>
          <div class="flex-fill">
            <ng-container *ngIf="entity.email">
              <a href="mailto:{{ entity.email }}" class="mr-1">{{ entity.email }}</a>
              <app-copy-to-clipboard-button [value]="entity.email" matTooltip="Copy Email Address">
                <fa-icon [icon]="['fas', 'copy']"></fa-icon>
              </app-copy-to-clipboard-button>
            </ng-container>
            <ng-container *ngIf="!entity.email">
              -
            </ng-container>
          </div>
        </div>
        <div class="mt-2"></div>

        <div class="d-flex flex-row">
          <div class="entity-attr-name">Department:</div>
          <div class="flex-fill">{{ entity.department || '-' }}</div>
        </div>
        <div class="mt-2"></div>

        <div class="d-flex flex-row">
          <div class="entity-attr-name">Companies:</div>
        </div>
        <div class="d-flex flex-row">
          <div class="flex-fill">
            <ul>
              <li *ngFor="let companyAccess of companyAccesses;">
                <app-company-display [company]="companyAccess.company_info" displayType="onlyNameAsLink"></app-company-display> -
                <ng-container *ngIf="companyAccess.roles_info.length; else noRoles;">
                                <span
                                  *ngFor="let role of companyAccess.roles_info; let isLast=last">{{ role.name }}{{isLast ? '' : ', '}}</span>
                </ng-container>
                <ng-template #noRoles>
                  <span>General User</span>
                </ng-template>
              </li>
            </ul>
          </div>
        </div>
        <div class="d-flex flex-row">
          <div class="entity-attr-name"><fa-icon [icon]="['fas', 'phone']"></fa-icon> Work:</div>
          <div class="flex-fill">
            <ng-container *ngIf="entity.work_phone">
              <a href="tel:{{ entity.work_phone }}" class="mr-1">{{ entity.work_phone }}</a>
              <app-copy-to-clipboard-button [value]="entity.work_phone" matTooltip="Copy Work Phone Number">
                <fa-icon [icon]="['fas', 'copy']"></fa-icon>
              </app-copy-to-clipboard-button>
            </ng-container>
            <ng-container *ngIf="!entity.work_phone">
              -
            </ng-container>
          </div>
        </div>
        <div class="mt-2"></div>

        <div class="d-flex flex-row" *ngIf="entity.cell_phone">
          <div class="entity-attr-name"><fa-icon [icon]="['fas', 'phone']"></fa-icon> Cell:</div>
          <div class="flex-fill">
            <ng-container *ngIf="entity.cell_phone">
              <a href="tel:{{ entity.cell_phone }}" class="mr-1">{{ entity.cell_phone }}</a>
              <app-copy-to-clipboard-button [value]="entity.cell_phone" matTooltip="Copy Cell Phone Number">
                <fa-icon [icon]="['fas', 'copy']"></fa-icon>
              </app-copy-to-clipboard-button>
            </ng-container>
            <ng-container *ngIf="!entity.cell_phone">
              -
            </ng-container>
          </div>
        </div>
        <div class="mt-2"></div>

        <div class="d-flex flex-row" *ngIf="entity.fax_number">
          <div class="entity-attr-name"><fa-icon [icon]="['fas', 'fax']"></fa-icon> Fax:</div>
          <div class="flex-fill">
            <ng-container *ngIf="entity.fax_number">
              <a href="tel:{{ entity.fax_number }}" class="mr-1">{{ entity.fax_number }}</a>
              <app-copy-to-clipboard-button [value]="entity.fax_number" matTooltip="Copy Fax Number">
                <fa-icon [icon]="['fas', 'copy']"></fa-icon>
              </app-copy-to-clipboard-button>
            </ng-container>
            <ng-container *ngIf="!entity.fax_number">
              -
            </ng-container>
          </div>
        </div>
        <div class="mt-2"></div>

        <div class="d-flex flex-row" *ngIf="entity.alt_phone">
          <div class="entity-attr-name"><fa-icon [icon]="['fas', 'phone']"></fa-icon> Alt:</div>
          <div class="flex-fill">
            <ng-container *ngIf="entity.alt_phone">
              <a href="tel:{{ entity.alt_phone }}" class="mr-1">{{ entity.alt_phone }}</a>
              <app-copy-to-clipboard-button [value]="entity.alt_phone" matTooltip="Copy Alt Phone Number">
                <fa-icon [icon]="['fas', 'copy']"></fa-icon>
              </app-copy-to-clipboard-button>
            </ng-container>
            <ng-container *ngIf="!entity.alt_phone">
              -
            </ng-container>
          </div>
        </div>

        <div class="d-flex flex-row mt-2" *ngIf="entity.mailing_geocode_info">
          <div class="entity-attr-name">Mailing Address:</div>
          <div class="flex-fill">
            <ng-container *ngIf="entity.mailing_geocode_info">
              <app-address-display [geocode]="entity.mailing_geocode_info"
                                   [geocodeBrokeredResponse]="entity.mailing_geocode_response_info"></app-address-display>
            </ng-container>
            <ng-container *ngIf="!entity.mailing_geocode_info">
              -
            </ng-container>
          </div>
        </div>
        <div class="d-flex flex-row mt-2" *ngIf="entity.shipping_geocode_info">
          <div class="entity-attr-name">Shipping Address:</div>
          <div class="flex-fill">
            <ng-container *ngIf="entity.shipping_geocode_info">
              <app-address-display [geocode]="entity.shipping_geocode_info"
                                   [geocodeBrokeredResponse]="entity.shipping_geocode_response_info"></app-address-display>
            </ng-container>
            <ng-container *ngIf="!entity.shipping_geocode_info">
              -
            </ng-container>
          </div>
        </div>

        <div class="d-flex flex-row mt-2" *ngIf="companyConfirmedGeocodeResponse">
          <div class="entity-attr-name">Company Mailing Address:</div>
          <div class="flex-fill">
            <ng-container *ngIf="companyConfirmedGeocodeResponse.geocode">
              <app-address-display [geocode]="companyConfirmedGeocodeResponse.geocode"
                                   [geocodeBrokeredResponse]="companyConfirmedGeocodeResponse.geocodeBrokeredResponse"></app-address-display>
            </ng-container>
            <ng-container *ngIf="!companyConfirmedGeocodeResponse.geocode">
              -
            </ng-container>
          </div>
        </div>
        <div class="d-flex flex-row mt-2" *ngIf="entity.company_info?.shipping_geocode_info">
          <div class="entity-attr-name">Company Shipping Address:</div>
          <div class="flex-fill">
            <ng-container *ngIf="entity.company_info?.shipping_geocode_info">
              <app-address-display [geocode]="entity.company_info?.shipping_geocode_info"
                                   [geocodeBrokeredResponse]="entity.company_info?.shipping_geocode_response_info"></app-address-display>
            </ng-container>
            <ng-container *ngIf="!entity.company_info?.shipping_geocode_info">
              -
            </ng-container>
          </div>
        </div>

        <div class="d-flex flex-row mt-2">
          <div class="entity-attr-name">Timezone preferences:</div>
          <div class="flex-fill">{{ entity.timezone_preference || '-' }}</div>
        </div>
        <div class="mt-2"></div>
      </div>
      <div class="d-flex-row">

      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <mat-tab-group color="accent" style="min-height: 450px;" disableRipple="true" animationDuration="0ms"
                     dynamicHeight [selectedIndex]="selectedIndex">

        <mat-tab>
          <ng-template mat-tab-label>
            Additional Information
          </ng-template>
          <div class="mt-2 mb-5">
            <div class="d-flex flex-row">
              <div class="mb-2 w-50 pr-1">
                <div class="card">
                  <div class="card-header">
                    Rater Information
                  </div>
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                      <b>
                        Roles:
                      </b>
                      <app-mtm-display [list]="raterRoles" modelClassName="user_rater_role"></app-mtm-display>
                    </li>
                    <li class="list-group-item">
                      <b>
                        RESNET RTIN:
                      </b>
                      {{ entity.rater_id }}
                    </li>
                    <li class="list-group-item">
                      <b>
                        Signature:
                      </b>
                      <span *ngIf="entity.signature_image">
                            <a href="{{ entity.signature_image }}" target="_blank">View</a>
                        </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="mt-2"></div>

            <div class="d-flex flex-row">
              <div class="mb-2 w-50 pr-1">
                <div class="card mb-2 w-100">
                  <div class="card-header">
                    NGBS Settings
                  </div>
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                      <b>
                        Internal User ID:
                      </b>
                      {{ entity.hirlrateruser_info?.hirl_id || '-' }}
                    </li>
                    <li class="list-group-item">
                      <b>
                        Is QA Designee:
                      </b>
                      {{ entity.hirl_user_profile?.is_qa_designee | yesNo }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="mb-2 w-50 pl-1">
                <div class="card mb-2 w-100">
                  <div class="card-header">
                    Provider Information
                  </div>
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                      <b>
                        RESNET Username:
                      </b>
                      {{ entity.resnet_username }}
                    </li>
                    <li class="list-group-item">
                      <b>
                        RESNET Password:
                      </b>
                      <app-sensitive-info>
                        {{ entity.resnet_password || '-' }}
                      </app-sensitive-info>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            Accreditations
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" class="ml-1"
                     *ngIf="accreditationsCount === -1"></fa-icon>
            <span class="badge badge-primary ml-1" *ngIf="accreditationsCount !== -1">
                {{ accreditationsCount }}
            </span>
          </ng-template>
          <div class="mt-2 mb-5">
            <app-accreditations-tab></app-accreditations-tab>
          </div>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            Training
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" class="ml-1"
                     *ngIf="trainingsCount === -1"></fa-icon>
            <span class="badge badge-primary ml-1" *ngIf="trainingsCount !== -1">
                {{ trainingsCount }}
            </span>
          </ng-template>
          <div class="mt-2 mb-5">
            <app-training-tab></app-training-tab>
          </div>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            Grading
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" class="ml-1"
                     *ngIf="inspectionGradesCount === -1"></fa-icon>
            <span class="badge badge-primary ml-1" *ngIf="inspectionGradesCount !== -1">
                {{ inspectionGradesCount }}
            </span>
          </ng-template>
          <div class="mt-2 mb-5">
            <app-inspection-grade-tab></app-inspection-grade-tab>
          </div>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            Metrics
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" class="ml-1"
                     *ngIf="eepProgramHomeStatusesCertificationMetricsCount === -1"></fa-icon>
            <span class="badge badge-primary ml-1" *ngIf="eepProgramHomeStatusesCertificationMetricsCount !== -1">
                {{ eepProgramHomeStatusesCertificationMetricsCount }}
            </span>
          </ng-template>
          <div class="mt-2 mb-5">
            <app-user-metrics-tab></app-user-metrics-tab>
          </div>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            Scheduling
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" class="ml-1"
                     *ngIf="tasksCount === -1"></fa-icon>
            <span class="badge badge-primary ml-1" *ngIf="tasksCount !== -1">
                {{ tasksCount }}
            </span>
          </ng-template>
          <div class="mt-2 mb-5">
            <app-user-scheduling-tab></app-user-scheduling-tab>
          </div>
        </mat-tab>

      </mat-tab-group>
    </div>
  </div>
</div>
