import { Injectable } from '@angular/core';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import { environment } from '../../../../environments/environment';
import {
  ApexRequestCreate,
  ApexRequestDocument,
  ApexRequestFlatList,
  ApexRequestType,
} from '@/data/home/models';
import { ListRequestParams } from '@/core/schemes/request-params';
import { ServerResponse } from '@/core/schemes/server-response';
import { Observable } from 'rxjs';
import { ApexRequest } from '@/data/home/models/apex-request';
import { tap } from 'rxjs/operators';
import {
  loadApexRequest,
  loadApexRequestDocuments,
  loadApexRequestDocumentsCount,
} from '@/state/actions/home/apex-request.actions';
import { ApexRequestDocumentRequestParams } from '@/data/home/services/apex-request-document.service';

export class ApexRequestRequestParams extends ListRequestParams {
  id?: string | number;
  request_type?: ApexRequestType | ApexRequestType[];
}

@Injectable({
  providedIn: 'root',
})
export class ApexRequestService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/apex_requests/`;

  list(params?: ApexRequestRequestParams) {
    return this.http.get<ServerResponse<ApexRequest>>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }

  flat_list(
    params?: ApexRequestRequestParams
  ): Observable<ServerResponse<ApexRequestFlatList>> {
    return this.http.get<ServerResponse<ApexRequestFlatList>>(
      `${this.baseUrl}flat_list/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  create(apexRequest: ApexRequestCreate): Observable<ApexRequest> {
    return this.http.post<ApexRequest>(`${this.baseUrl}`, apexRequest);
  }

  retrieve(apexRequestId: number, options?: IDataServiceMethodOptions) {
    const ignoreStore = options?.ignoreStore;
    let chain = this.http.get<ApexRequest>(
      `${this.baseUrl}${apexRequestId}/`,
      {}
    );
    if (!ignoreStore) {
      chain = chain.pipe(
        tap(apexRequest =>
          this.store.dispatch(loadApexRequest({ payload: apexRequest }))
        )
      );
    }
    return chain;
  }

  summary_result(apexRequestId: number, options?: IDataServiceMethodOptions) {
    return this.http.get(`${this.baseUrl}${apexRequestId}/summary_result/`, {
      responseType: 'blob',
    });
  }

  apexRequestDocuments(
    objectId: number | string,
    params?: ApexRequestDocumentRequestParams,
    options?: IDataServiceMethodOptions
  ) {
    const ignoreStore = options?.ignoreStore;
    let chain = this.http.get<ServerResponse<ApexRequestDocument>>(
      `${this.baseUrl}${objectId}/apex_request_documents/`,
      {
        params: this.rollParams(params),
      }
    );
    if (!ignoreStore) {
      chain = chain.pipe(
        tap(apexRequestDocumentsResponse =>
          this.store.dispatch(
            loadApexRequestDocuments({
              payload: apexRequestDocumentsResponse.results,
            })
          )
        ),
        tap(apexRequestDocumentsResponse =>
          this.store.dispatch(
            loadApexRequestDocumentsCount({
              payload: apexRequestDocumentsResponse.count,
            })
          )
        )
      );
    }
    return chain;
  }
}
