import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

import { Company } from '@/data/company/models';
import {
  CompanyRequestParams,
  CompanyService,
} from '@/data/company/services/company-base.service';
import { EEPProgram } from '@/data/eep-program/models';
import {
  EEPProgramRequestParams,
  EEPProgramService,
} from '@/data/eep-program/services/eep-program.service';
import { AppState } from '@/state/reducers';
import {
  FastTrackSubmission,
  FastTrackSubmissionSubmitStatusMapping,
} from './../../../../data/customer-eto/models';
import {
  ProjectTrackerRequestParams,
  ProjectTrackerService,
} from './../../../../data/customer-eto/services/project-tracker.service';

@Component({
  selector: 'app-project-tracker-list-component',
  templateUrl: './project-tracker-list-component.component.html',
  styleUrls: ['./project-tracker-list-component.component.scss'],
})
export class ProjectTrackerListComponentComponent implements OnInit {
  public submissions: FastTrackSubmission[];
  public submissionStatusMapping = FastTrackSubmissionSubmitStatusMapping;
  public submissionsCount: number;
  public componentLoading = true;

  public filterForm: FormGroup;
  private componentDestroyed$ = new Subject();

  public defaultParams: ProjectTrackerRequestParams =
    new ProjectTrackerRequestParams(1, '', '-id', 25);
  public storedParams: ProjectTrackerRequestParams = Object.assign(
    new ProjectTrackerRequestParams(),
    this.defaultParams
  );
  public eep_programs: EEPProgram[];
  public raters: Company[];

  constructor(
    public store: Store<AppState>,
    public router: Router,
    public injector: Injector,
    private activatedRoute: ActivatedRoute,
    private projectTrackerService: ProjectTrackerService,
    private eepProgramService: EEPProgramService,
    private companyService: CompanyService,
    public formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.pipe(first()).subscribe(params => {
      this.storedParams.assignQueryParams(params);
    });

    this.loadEEPPrograms();
    this.loadRaters();
    this.setupFilterForm();
    this.hydrateForm();
    this.list();
  }

  list() {
    this.componentLoading = true;

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.storedParams,
      replaceUrl: true,
    });

    this.projectTrackerService
      .list(this.storedParams)
      .pipe(first())
      .subscribe(data => {
        this.submissions = data.results;
        this.submissionsCount = data.count;
        this.componentLoading = false;
      });
  }

  onFooterPage(event) {
    this.storedParams.page = event.page;
    this.list();
  }

  onSort({ sorts }) {
    this.storedParams.ordering = `${sorts[0].dir === 'desc' ? '-' : ''}${
      sorts[0].prop
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  setupFilterForm() {
    this.filterForm = this.formBuilder.group({
      submit_status: [null],
      program: [null],
      rater_slug: [null],
      search: [null],
    });

    this.filterForm.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        const params = Object.assign(this.defaultParams, values);

        this.storedParams = Object.assign(this.storedParams, params);
        this.list();
      });
  }

  loadEEPPrograms() {
    const eepProgramRequestParams = new EEPProgramRequestParams();
    eepProgramRequestParams.ordering = 'name';
    eepProgramRequestParams.owner__slug = 'eto';
    eepProgramRequestParams.is_qa_program = false;
    this.eepProgramService
      .list(eepProgramRequestParams)
      .pipe(first())
      .subscribe(data => {
        this.eep_programs = data['results'];
      });
  }

  loadRaters() {
    const companyRequestParams = new CompanyRequestParams();
    this.companyService
      .customer_eto_rater_list(companyRequestParams)
      .pipe(first())
      .subscribe(data => {
        this.raters = data['results'];
      });
  }

  resetFilters($event: MouseEvent) {
    $event.preventDefault();
    this.filterForm.reset();
  }

  reLoad($event: MouseEvent) {
    $event.preventDefault();
    this.list();
  }

  hydrateForm() {
    this.filterForm.patchValue(this.storedParams, {
      emitEvent: false,
      onlySelf: true,
    });
  }
}
