import { ListRequestParams } from '@/core/schemes/request-params';
import { Injectable } from '@angular/core';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { ServerResponse } from '@/core/schemes/server-response';
import { PracticeSection } from '@/data/customer-hirl/models';

export class PracticeSectionRequestParams extends ListRequestParams {
  chapter?: number;
}

@Injectable({
  providedIn: 'root',
})
export class PracticeSectionService extends BaseDataService {
  baseUrl = `${environment.AXIS_API_V3_ROOT}/practice_sections/`;

  list(params?: PracticeSectionRequestParams) {
    return this.http.get<ServerResponse<PracticeSection>>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }

  retrieve(
    practiceSectionId: number,
    options?: IDataServiceMethodOptions
  ): Observable<PracticeSection> {
    return this.http.get<PracticeSection>(
      `${this.baseUrl}${practiceSectionId}`,
      {}
    );
  }

  update(practiceSection: PracticeSection, practiceSectionId: number) {
    return this.http.patch<PracticeSection>(
      `${this.baseUrl}${practiceSectionId}/`,
      practiceSection
    );
  }

  delete(practiceSectionId: number) {
    return this.http.delete(`${this.baseUrl}${practiceSectionId}`);
  }
}
