import { Component } from '@angular/core';
import { PsrListComponent } from '@/modules/home/pages/psr-list/psr-list.component';
import {
  ProviderDashboardList,
  ProviderDashboardProgramsReadyForFTSubmit,
} from '@/data/home/models';
import {
  EEPProgramHomeStatusRequestParams,
  EEPProgramHomeStatusRFastTrackSubmitStatus,
  EEPProgramHomeStatusService,
} from '@/data/home/services/eep_program_home_status.service';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { EEPProgramService } from '@/data/eep-program/services/eep-program.service';
import { NavigationService } from '@/shared/services/navigation-service.service';
import { first, take, takeUntil } from 'rxjs/operators';
import { EEPProgram } from '@/data/eep-program/models';

@Component({
  selector: 'app-provider-fasttracksubmit-dashboard-list',
  templateUrl: './provider-fasttracksubmit-dashboard-list.component.html',
  styleUrls: ['./provider-fasttracksubmit-dashboard-list.component.scss'],
})
export class ProviderFasttracksubmitDashboardListComponent extends PsrListComponent<ProviderDashboardList> {
  public displayedColumns = [
    'select',
    'home__street_line1',
    'home__subdivision__name',
    'floorplan__name',
    'eep_program__name',
    'state',
  ];

  public getDataSource = (params: EEPProgramHomeStatusRequestParams) =>
    this.eepProgramHomeStatusService.provider_dashboard_certification_list(
      params
    );

  public eepProgramsReadyForFTSubmitIsLoading = true;
  public eepProgramsReadyForFTSubmit: ProviderDashboardProgramsReadyForFTSubmit[];
  public eepProgramsReadyForFTSubmitSubscription$: Subscription;

  public quickFilterIsLoading = false;

  constructor(
    public store: Store<AppState>,
    public eepProgramHomeStatusService: EEPProgramHomeStatusService,
    public router: Router,
    public dialog: MatDialog,
    public activatedRoute: ActivatedRoute,
    public uiHelperService: UIHelperService,
    public eepProgramService: EEPProgramService,
    public navigationService: NavigationService
  ) {
    super(
      store,
      eepProgramHomeStatusService,
      router,
      dialog,
      activatedRoute,
      uiHelperService,
      navigationService
    );
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();

    if (this.eepProgramsReadyForFTSubmitSubscription$) {
      this.eepProgramsReadyForFTSubmitSubscription$.unsubscribe();
    }
  }

  onListChangeStarted() {
    if (this.eepProgramsReadyForFTSubmitSubscription$) {
      this.eepProgramsReadyForFTSubmitSubscription$.unsubscribe();
    }

    const params = new EEPProgramHomeStatusRequestParams();
    params.company_access = this.storedParams?.company_access;
    this.eepProgramsReadyForFTSubmitSubscription$ =
      this.eepProgramHomeStatusService
        .provider_dashboard_programs_ready_for_ft_submit(params)
        .pipe(takeUntil(this.componentDestroyed$), take(1))
        .subscribe(eepProgramsReadyForFTSubmit => {
          this.eepProgramsReadyForFTSubmit = eepProgramsReadyForFTSubmit;
          this.eepProgramsReadyForFTSubmitIsLoading = false;
        });
  }

  public setupInitialFilters() {
    this.filterComponent.fieldPermissions.state = false;
    this.filterComponent.fieldPermissions.customer_eto_fasttracksubmission__submit_status =
      false;
    this.filterComponent.fieldPermissions.certification_date__gte = false;
    this.filterComponent.fieldPermissions.certification_date__lte = false;

    this.storedParams.customer_eto_fasttracksubmission__submit_status = [
      EEPProgramHomeStatusRFastTrackSubmitStatus.readyForSubmit,
    ];
    this.filterComponent.filterFromGroup.patchValue(this.storedParams, {
      emitEvent: false,
      onlySelf: true,
    });
  }

  onQuickFilterClicked(
    $event: MouseEvent,
    eepProgramReadyForFTSubmit: ProviderDashboardProgramsReadyForFTSubmit
  ) {
    $event.preventDefault();
    this.quickFilterIsLoading = true;
    this.eepProgramService
      .retrieve(eepProgramReadyForFTSubmit.id)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe((eepProgram: EEPProgram) => {
        this.quickFilterIsLoading = false;
        const companyAccesses =
          this.filterComponent.filterFromGroup.value.company_access_info;

        this.filterComponent.filterFromGroup.reset();
        this.filterComponent.filterFromGroup.patchValue({
          company_access_info: companyAccesses,
          eep_program_info: [eepProgram],
          customer_eto_fasttracksubmission__submit_status: [
            EEPProgramHomeStatusRFastTrackSubmitStatus.readyForSubmit,
          ],
        });
      });
  }
}
