import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@/shared/shared.module';
import { ProductProductImagesTabComponent } from './components/product-product-images-tab/product-product-images-tab.component';
import { FilehandlingModule } from '@/modules/filehandling/filehandling.module';
import { ProductImageChangeDialogComponent } from './components/product-image-change-dialog/product-image-change-dialog.component';
import { ProductDisplayComponent } from './components/product-display/product-display.component';
import { CompanyBrandChangeDialogComponent } from './components/company-brand-change-dialog/company-brand-change-dialog.component';

@NgModule({
  declarations: [
    ProductProductImagesTabComponent,
    ProductImageChangeDialogComponent,
    ProductDisplayComponent,
    CompanyBrandChangeDialogComponent,
  ],
  imports: [CommonModule, SharedModule, FilehandlingModule],
  exports: [ProductDisplayComponent, ProductProductImagesTabComponent],
})
export class ProductModule {}
