import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';

interface Status {
  analysis_ids: number[];
  id: number;
  status: string;
  task_id: string;
}

@Injectable({ providedIn: 'root' })
export class OpenStudioEriService {
  constructor(public http: HttpClient) {}

  _getUrl(simulationId) {
    return `${environment.AXIS_API_V3_ROOT}/simulations/${simulationId}/open-studio-eri/`;
  }

  get(simulationId: number): Observable<Status> {
    return this.http.get<Status>(this._getUrl(simulationId));
  }

  post(simulationId: number) {
    return this.http.post(this._getUrl(simulationId), {});
  }
}
