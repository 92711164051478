import { AirSourceHeatPumpBackendDict } from '@/data/simulation/models/mechanicals/AirSourceHeatPump';
import { createAction, props } from '@ngrx/store';
import { StateErrors } from '../shared/base.state';

export const loadAirSourceHeatPumps = createAction(
  '[AirSourceHeatPump] Load AirSourceHeatPumps',
  props<{ airSourceHeatPumps: AirSourceHeatPumpBackendDict[] }>()
);

export const loadAirSourceHeatPumpsSuccess = createAction(
  '[AirSourceHeatPump] Load AirSourceHeatPumps Success',
  props<{
    airSourceHeatPumps: AirSourceHeatPumpBackendDict[];
    errors: StateErrors<AirSourceHeatPumpBackendDict>;
  }>()
);

export const loadAirSourceHeatPumpsFailure = createAction(
  '[AirSourceHeatPump] Load AirSourceHeatPumps Failure',
  props<{ error: any }>()
);

export const updateAirSourceHeatPump = createAction(
  '[AirSourceHeatPump] Update AirSourceHeatPump',
  props<{ airSourceHeatPumpChanges: Partial<AirSourceHeatPumpBackendDict> }>()
);

export const updateAirSourceHeatPumpFailure = createAction(
  '[AirSourceHeatPump API] Update AirSourceHeatPump Failure',
  props<{ id: number }>()
);
