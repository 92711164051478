<ng-container>
  <div class="row" *ngIf="entitiesIsLoading">
    <div class="col-12">
      <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <ng-container *ngIf="selectAllEntities">Selected <b>{{ entitiesCount }}</b> Rows</ng-container>
      <ng-container *ngIf="!selectAllEntities && selectedEntities?.length">Selected <b>{{ selectedEntities.length }}</b> Rows</ng-container>
    </div>
  </div>


  <table mat-table
         [dataSource]="entities"
         class="w-100"
  >
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox matTooltip="Select All" [(ngModel)]="selectAllEntities" (change)="onSelectAll()"
                      [disabled]="entitiesIsLoading"></mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (change)="onEntitySelect($event, row)"
                      [checked]="isEntityChecked(row)"
                      [disabled]="entitiesIsLoading"
        ></mat-checkbox>
      </td>
    </ng-container>
    <ng-content></ng-content>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <div class="d-flex flex-row">
    <div class="align-self-center">
      <ng-content select="[table-footer-left]"></ng-content>
    </div>
    <div class="d-flex flex-fill justify-content-end">
      <mat-paginator [length]="entitiesCount"
                     [pageIndex]="$any(storedParams.page) - 1"
                     [pageSize]="storedParams.page_size"
                     (page)="onPaginateChange($event)"
                     aria-label="Select page">
      </mat-paginator>
    </div>
  </div>
</ng-container>
