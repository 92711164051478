import { BlockRateBackendDict } from '@/data/simulation/models/utility/BlockRate';
import { createAction, props } from '@ngrx/store';
import { ModelErrors, StateErrors } from '../shared/base.state';

export const loadBlockRates = createAction(
  '[Simulation API] Load Detailed BlockRates',
  props<{ blockRates: BlockRateBackendDict[] }>()
);

export const loadBlockRatesSuccess = createAction(
  '[BlockRate API] Load BlockRates Success',
  props<{
    blockRates: BlockRateBackendDict[];
    errors: StateErrors<BlockRateBackendDict>;
  }>()
);

export const updateBlockRate = createAction(
  '[BlockRate UI] Update BlockRate',
  props<{ blockRateChanges: Partial<BlockRateBackendDict> }>()
);

export const updateBlockRateSuccess = createAction(
  '[BlockRate API] Update BlockRate Success',
  props<{
    blockRate: BlockRateBackendDict;
    errors: ModelErrors<BlockRateBackendDict>;
  }>()
);

export const updateBlockRateFailure = createAction(
  '[BlockRate API] Update BlockRate Failure',
  props<{ id: number }>()
);

export const removeBlockRate = createAction(
  '[BlockRate UI] Remove BlockRate',
  props<{ blockRate: BlockRateBackendDict }>()
);

export const removeBlockRateSuccess = createAction(
  '[BlockRate API] Remove BlockRate Success',
  props<{ id: number }>()
);

export const removeBlockRateFailure = createAction(
  '[BlockRate API] Remove BlockRate Failure',
  props<{ id: number }>()
);

export const updateBlockRateErrors = createAction(
  '[BlockRate UI] Update BlockRate Errors',
  props<{ id: number; errors: any }>()
);
