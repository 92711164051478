<div>
    <div class="card">
        <div class="card-header">
            Associated Companies
        </div>
        <div class="row" *ngIf="initialized else loading">
            <div class="col-12">
              <div class="p-2">
                <div class="d-flex justify-content-between align-items-start" *ngFor="let item of groupedRelationships | defaultOrderKeyValue; let i=index">
                  <div class="ms-2 me-auto">
                    <b>{{ companyTypesMap[item.key] || item.key }}</b>
                    <div *ngFor="let relationship of item.value;" class="d-flex">
                      <app-company-display [company]="relationship.company_info" displayType="onlyNameAsLink"></app-company-display>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <ng-template #loading>
            <div class="row">
                <div class="col-12">
                    <div class="d-flex justify-content-center" style="height: 320px;">
                      <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</div>
