import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { User } from '@/data/core/models/user';
import { forkJoin, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, takeUntil } from 'rxjs/operators';
import { toggleLoading } from '@/state/actions/app.actions';
import { EEPProgramHomeStatusProjectReportExportField } from '@/data/home/models';
import { EEPProgramHomeStatusProjectReportExportFieldLabelMapping } from '@/data/home/models/eep_program_home_status_project_report_download';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';
import { CustomerETOSettings } from '@/modules/customer-eto/constants';
import { CustomerAPSSettings } from '@/modules/customer-aps/constants';
import { CustomerNEEASettings } from '@/modules/customer-neea/constants';
import { MatSelectionListChange } from '@angular/material/list';

export class PsrExportFieldsDialogData {
  export_fields?: EEPProgramHomeStatusProjectReportExportField[];
}

@Component({
  selector: 'app-psr-export-fields-dialog',
  templateUrl: './psr-export-fields-dialog.component.html',
  styleUrls: ['./psr-export-fields-dialog.component.scss'],
})
export class PsrExportFieldsDialogComponent implements OnInit, OnDestroy {
  public exportFieldLabelMapping = Object.assign(
    EEPProgramHomeStatusProjectReportExportFieldLabelMapping,
    {
      [EEPProgramHomeStatusProjectReportExportField.home]:
        'Project Data (Home / Program)',
    }
  );

  public initialized: boolean;
  public form: FormGroup;
  public currentUser: User;

  public showCustomerETO = false;
  public showCustomerNEEA = false;
  public showCustomerAPS = false;
  public showCustomerNGBS = false;

  public formPermissions: { [key: string]: boolean } = {};

  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<PsrExportFieldsDialogComponent>,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private uiHelperService: UIHelperService,
    @Inject(MAT_DIALOG_DATA) public data: PsrExportFieldsDialogData
  ) {}

  ngOnInit() {
    const sources = {
      currentUser: this.store.select(getInfoUser).pipe(first()),
    };

    this.store.dispatch(toggleLoading({ payload: true }));

    forkJoin(sources)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: ({ currentUser }) => {
          this.store.dispatch(toggleLoading({ payload: false }));

          this.currentUser = currentUser;

          this.showCustomerETO =
            this.currentUser?.company_info?.sponsor_slugs?.includes(
              CustomerETOSettings.companySlug
            ) ||
            this.currentUser?.company_info?.slug ===
              CustomerETOSettings.companySlug ||
            this.currentUser.is_superuser;

          this.showCustomerAPS =
            this.currentUser?.company_info?.sponsor_slugs?.includes(
              CustomerAPSSettings.companySlug
            ) ||
            this.currentUser?.company_info?.slug ===
              CustomerAPSSettings.companySlug ||
            this.currentUser.is_superuser;

          this.showCustomerNEEA =
            this.currentUser?.company_info?.sponsor_slugs?.includes(
              CustomerNEEASettings.companySlug
            ) ||
            this.currentUser?.company_info?.slug ===
              CustomerNEEASettings.companySlug ||
            this.currentUser.is_superuser;

          this.showCustomerNGBS =
            this.currentUser?.company_info?.sponsor_slugs?.includes(
              CustomerHIRLSettings.companySlug
            ) ||
            this.currentUser?.company_info?.slug ===
              CustomerHIRLSettings.companySlug ||
            this.currentUser.is_superuser;

          this.formPermissions = {
            home: true,
            status: false, // hidden, but always set to true as home
            subdivision: true,
            community: true,
            relationships: true,
            eep_program: true,
            ipp:
              this.showCustomerETO ||
              this.showCustomerAPS ||
              this.showCustomerNEEA,
            floorplan:
              this.showCustomerETO ||
              this.showCustomerAPS ||
              this.showCustomerNEEA,
            invoicing: this.showCustomerNGBS,
            simulation_basic:
              this.showCustomerETO ||
              this.showCustomerAPS ||
              this.showCustomerNEEA,
            simulation_advanced:
              this.showCustomerETO ||
              this.showCustomerAPS ||
              this.showCustomerNEEA,
            annotations: true,
            checklist_answers:
              this.showCustomerETO ||
              this.showCustomerAPS ||
              this.showCustomerNEEA,
            qa: true,
            customer_aps: this.showCustomerAPS,
            customer_eto: this.showCustomerETO,
            ngbs_data: this.showCustomerNGBS,
            hes_data: this.showCustomerETO || this.showCustomerNEEA,
            retain_empty: true,
          };

          this.setupForm();

          if (!this.data?.export_fields || !this.data.export_fields.length) {
            this.reset(new MouseEvent('click'));
          }
          this.initialized = true;
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  setupForm() {
    this.form = this.fb.group({
      home: [false],
      status: [false],
      eep_program: [false],
      subdivision: [false],
      community: [false],
      relationships: [false],
      ipp: [false],
      invoicing: [false],
      floorplan: [false],
      simulation_basic: [false],
      simulation_advanced: [false],
      annotations: [false],
      checklist_answers: [false],
      qa: [false],
      customer_aps: [false],
      customer_eto: [false],
      ngbs_data: [false],
      hes_data: [false],
      retain_empty: [false],
    });

    if (this.data?.export_fields) {
      this.data?.export_fields.forEach(value => {
        if (value) {
          this.form.get(value).patchValue(true);
        }
      });
    }

    this.form
      .get('simulation_basic')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe(value => {
        if (value) {
          this.form
            .get('simulation_advanced')
            .patchValue(!value, { emitEvent: false, onlySelf: true });
        }
      });

    this.form
      .get('simulation_advanced')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe(value => {
        if (value) {
          this.form
            .get('simulation_basic')
            .patchValue(!value, { emitEvent: false, onlySelf: true });
        }
      });

    this.form
      .get('home')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe(value => {
        this.form
          .get('status')
          .patchValue(value, { emitEvent: false, onlySelf: true });
      });
  }

  confirm($event: MouseEvent) {
    $event.preventDefault();
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }
    const selectedFields: EEPProgramHomeStatusProjectReportExportField[] =
      Object.keys(this.form.value)
        .filter(key => this.form.value[key])
        .map(key => EEPProgramHomeStatusProjectReportExportField[key]);

    this.uiHelperService.getUISettings().psrExportFields = selectedFields;

    this.dialogRef.close(selectedFields);
  }

  reset($event: MouseEvent) {
    $event.preventDefault();

    Object.keys(this.form.controls).forEach(controlName => {
      this.form.get(controlName).setValue(false);
    });

    const exportFields = this.getDefaults();
    exportFields.forEach(value => {
      if (value) {
        if (this.formPermissions[value]) {
          this.form.get(value).patchValue(true);
        }
      }
    });
  }

  getDefaults(): EEPProgramHomeStatusProjectReportExportField[] {
    if (
      this.currentUser.company_info?.sponsor_slugs?.includes(
        CustomerHIRLSettings.companySlug
      ) ||
      this.currentUser.company_info?.slug === CustomerHIRLSettings.companySlug
    ) {
      return [
        EEPProgramHomeStatusProjectReportExportField.home,
        EEPProgramHomeStatusProjectReportExportField.status,
        EEPProgramHomeStatusProjectReportExportField.qa,
        EEPProgramHomeStatusProjectReportExportField.ngbs_data,
        EEPProgramHomeStatusProjectReportExportField.retain_empty,
      ];
    } else if (
      this.currentUser?.company_info?.sponsor_slugs?.includes(
        CustomerETOSettings.companySlug
      ) ||
      this.currentUser?.company_info?.slug === CustomerETOSettings.companySlug
    ) {
      return [
        EEPProgramHomeStatusProjectReportExportField.home,
        EEPProgramHomeStatusProjectReportExportField.status,
        EEPProgramHomeStatusProjectReportExportField.subdivision,
        EEPProgramHomeStatusProjectReportExportField.community,
        EEPProgramHomeStatusProjectReportExportField.relationships,
        EEPProgramHomeStatusProjectReportExportField.simulation_basic,
        EEPProgramHomeStatusProjectReportExportField.customer_eto,
        EEPProgramHomeStatusProjectReportExportField.retain_empty,
      ];
    } else {
      return [
        EEPProgramHomeStatusProjectReportExportField.home,
        EEPProgramHomeStatusProjectReportExportField.status,
        EEPProgramHomeStatusProjectReportExportField.subdivision,
        EEPProgramHomeStatusProjectReportExportField.community,
        EEPProgramHomeStatusProjectReportExportField.relationships,
        EEPProgramHomeStatusProjectReportExportField.simulation_basic,
        EEPProgramHomeStatusProjectReportExportField.retain_empty,
      ];
    }
  }

  updateFormControlValue($event: MatSelectionListChange) {
    const selectedValues = $event.source.selectedOptions.selected.map(
      option => option.value
    );
    Object.keys(this.form.controls).forEach(controlName => {
      this.form.get(controlName).setValue(false);
    });

    selectedValues.forEach(selected => {
      this.form.get(selected).setValue(true);
    });
  }
}
