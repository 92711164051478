import {
  WaterHeaterBackendDict,
  createWaterHeaterForm,
  FIELD_LABELS,
  WaterHeaterStyleLabels,
  HotWaterEfficiencyUnitLabels,
  WaterHeaterLiquidVolumeLabels,
} from '@/data/simulation/models/mechanicals/WaterHeater';
import { updateWaterHeater } from '@/modules/simulation/state/water-heater/actions';
import { selectWaterHeaterViewSet } from '@/modules/simulation/state/water-heater/selectors';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  FuelTypeLabels,
  HeatingCoolingCapacityUnitLabels,
} from '@/data/simulation/enumerations';

@Component({
  selector: 'app-water-heater',
  templateUrl: './water-heater.component.html',
  styleUrls: ['./water-heater.component.scss'],
})
export class WaterHeaterComponent implements OnInit {
  @Input() entityId: number;
  fieldLabels = FIELD_LABELS;

  private componentDestroyed$ = new Subject();

  loading = false;
  entity: WaterHeaterBackendDict = null;
  waterHeaterFormGroup: FormGroup;
  fuelTypeLabels = FuelTypeLabels;
  waterHeaterLiquidVolumeLabels = WaterHeaterLiquidVolumeLabels;
  waterHeaterStyleLabels = WaterHeaterStyleLabels;
  hotWaterEfficiencyUnitLabels = HotWaterEfficiencyUnitLabels;
  hotWaterCapacityUnitLabels = HeatingCoolingCapacityUnitLabels;

  constructor(public store: Store) {}

  ngOnInit() {
    this.store
      .select(selectWaterHeaterViewSet(this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(({ data, loading }) => {
        this.entity = data;
        this.loading = loading;
        if (loading) return;

        if (this.waterHeaterFormGroup) {
          this.waterHeaterFormGroup.patchValue(data);
        } else {
          this.waterHeaterFormGroup = createWaterHeaterForm(data);
        }
      });
  }

  onSave(changedValues: Partial<WaterHeaterBackendDict>) {
    this.store.dispatch(
      updateWaterHeater({
        waterHeaterChanges: changedValues,
      })
    );
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
