<div class="position-relative main w-100">
    <app-form formName="materialForm" [formGroup]="entityForm" [dedicatedSaveButton]="false">
        <div class="container w-100">
            <div class="row  w-100">
                <div class="col-4 position-relative w-100">
                    <app-foreign-model-field
                        *appRequiresEditPermission
                        class="position-absolute"
                        [matMenuTriggerFor]="mainMenu"
                        [label]="showLabel ? 'Material' : null"
                        [options]="[]"
                        [name]="materialLabels[entityForm.controls.name.value]">
                    </app-foreign-model-field>
                </div>
                <div class="col-3  w-100">
                    <app-select
                        formControlName="insulation_grade"
                        [label]="showLabel ? 'Grade' : null"
                        [items]="insulationGrade">
                    </app-select>
                </div>
                <div class="col-3  w-100">
                    <app-input
                        formControlName="thickness"
                        type="number"
                        [label]="showLabel ? 'Thickness' : null">
                    </app-input>
                </div>
                <div class="col-2  w-100">
                    <app-input
                        formControlName="r_value"
                        type="number"
                        [label]="showLabel ? 'R-value' : null"
                        type="expression">
                    </app-input>
                </div>
            </div>
        </div>
    </app-form>
</div>

<mat-menu #mainMenu="matMenu">
    <ng-container *ngFor="let materialChoicesOption of materialChoicesOptions | keyvalue">
        <ng-container *ngIf="materialChoicesOption.value && materialChoicesOption.value.length > 0; else singleItem">
            <button mat-menu-item [matMenuTriggerFor]="subMenu">{{ materialLabels[materialChoicesOption.key] }}</button>
            <mat-menu #subMenu="matMenu">
                <button mat-menu-item *ngFor="let subItem of materialChoicesOption.value" (click)="updateMaterial(materialChoicesOption.key, subItem)">{{ materialItems[subItem] }}</button>
            </mat-menu>
        </ng-container>
        <ng-template #singleItem>
            <button mat-menu-item (click)="updateMaterial(materialChoicesOption.key, null)">{{ materialLabels[materialChoicesOption.key] }}</button>
        </ng-template>
    </ng-container>
</mat-menu>