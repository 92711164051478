import {
  Component,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { User } from '@/data/core/models/user';
import { first, takeUntil } from 'rxjs/operators';
import { DefaultProjectorFn, MemoizedSelector, Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ListRequestParams } from '@/core/schemes/request-params';
import { History, HistoryTypeLabelMapping } from '@/data/core/models';
import { axisModelMap } from '@/shared/constants/select-widget-model-map';

@Component({
  selector: 'app-history-table',
  templateUrl: './history-table.component.html',
  styleUrls: ['./history-table.component.scss'],
})
export class HistoryTableComponent implements OnInit, OnDestroy {
  protected readonly modelsMap = axisModelMap;

  @ViewChild('historyTable') table: any;

  @Input() objectId: string | number;
  @Input() modelClassName: string;
  @Input() selector?: MemoizedSelector<AppState, any, DefaultProjectorFn<any>>;

  public rows: History[];
  public count: number;
  public isLoading = true;
  public currentUser: User;
  public storedParams: ListRequestParams = new ListRequestParams(
    1,
    '',
    '-history_id'
  );

  public historyTypeLabelMapping = HistoryTypeLabelMapping;

  public componentDestroyed$ = new Subject();
  public title: string;

  // component logic
  private modelService$: any;

  constructor(public store: Store<AppState>, private injector: Injector) {}

  ngOnInit(): void {
    this.modelService$ = this.injector.get(
      this.modelsMap[this.modelClassName].serviceCls
    );
    this.list();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  list() {
    this.isLoading = true;

    this.modelService$
      .history(this.objectId, this.storedParams)
      .pipe(first(), takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.rows = data.results;
        this.count = data.count;
        this.isLoading = false;
      });
  }

  onSort({ sorts }) {
    this.storedParams.ordering = `${sorts[0].dir === 'desc' ? '-' : ''}${
      sorts[0].prop
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  onFooterPage(event) {
    this.storedParams.page = event.page;
    this.list();
  }
}
