import { SimulationConfig } from '@/data/simulation/models/SimulationConfig';
import { BaseValidator } from './base.validator';
import { ModelErrors, StateErrors } from '../state/shared/base.state';

export class SimulationConfigValidator extends BaseValidator {
  static validateForm(
    simulationconfig: SimulationConfig
  ): ModelErrors<SimulationConfig> {
    // const simulationconfigForm = createSimulationConfigForm(simulationconfig);
    // const errors = {};
    // Object.keys(simulationconfig).forEach(key => {
    //   if (!simulationconfigForm.get(key)) return;
    //   errors[key] = simulationconfigForm.get(key).errors;
    // });
    return {
      field: {},
      model: {},
    };
  }

  static validate(
    simulationconfigs: SimulationConfig[]
  ): StateErrors<SimulationConfig> {
    const errors = {};
    simulationconfigs.forEach(simulationconfig => {
      errors[simulationconfig.id] = this.validateForm(simulationconfig);
    });
    return errors;
  }
}
