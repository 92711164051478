import { ListRequestParams } from '@/core/schemes/request-params';
import { Injectable } from '@angular/core';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { ServerResponse } from '@/core/schemes/server-response';
import { CompanyRole } from '@/data/company/models/company-role';

export class CompanyRoleRequestParams extends ListRequestParams {}

@Injectable({
  providedIn: 'root',
})
export class CompanyRoleService extends BaseDataService {
  baseUrl = `${environment.AXIS_API_V3_ROOT}/company_roles/`;

  list(
    params?: CompanyRoleRequestParams
  ): Observable<ServerResponse<CompanyRole>> {
    return this.http.get<ServerResponse<CompanyRole>>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }

  retrieve(
    companyRoleId: number,
    options?: IDataServiceMethodOptions
  ): Observable<CompanyRole> {
    const ignoreStore = options?.ignoreStore;
    const chain = this.http.get<CompanyRole>(`${this.baseUrl}${companyRoleId}`);
    return chain;
  }
}
