<div class="container">
    <div class="row justify-content-center">
        <div class="col-md-6">
            <div class="card">
                <div class="card-header">Sign In</div>
                <div class="card-body">
                    <form [formGroup]="loginFormGroup">
                        <div class="row">
                            <div class="col-12">
                                <mat-form-field appearance="outline" class="w-100 mb-2">
                                    <mat-label>Email Address / Username</mat-label>
                                    <input matInput #usernameInput="matInput" placeholder="Email Address" formControlName="username" required>
                                    <mat-error *ngIf="loginFormGroup.get('username').hasError('required')">
                                        Email Address or Username is required
                                    </mat-error>
                                    <mat-error *ngIf="loginFormGroup.get('username').hasError('email')">
                                        Email Address or Username is invalid
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <mat-form-field appearance="outline" class="w-100 mb-2">
                                    <mat-label>Password</mat-label>
                                    <input matInput type="password" placeholder="Password" formControlName="password" required>
                                    <mat-error *ngIf="loginFormGroup.get('password').hasError('required')">
                                        Password is required
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12 text-center">
                                <button type="submit" class="btn btn-primary" (click)="login()">
                                    Sign In
                                </button>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12 text-center">
                                <a [routerLink]="['/', 'password_reset']" class="btn btn-link">
                                    Forgot Your Password?
                                </a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
