import { StudTypeLabels } from '@/data/simulation/enumerations';
import { LayerBackendDict } from '@/data/simulation/models/enclosure/AssemblyLayer';
import {
  MaterialItem,
  Materials,
  StudTypeChoicesOptions,
} from '@/data/simulation/models/enclosure/Material';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-assembly-layer-stud',
  templateUrl: './assembly-layer-stud.component.html',
  styleUrls: ['./assembly-layer-stud.component.scss'],
})
export class AssemblyLayerStudComponent implements OnInit {
  @Input() entityForm: FormGroup;
  @Output() onChange = new EventEmitter<Partial<LayerBackendDict>>();
  @Input() defaultThickness = 0.0;

  studTypesLabels = StudTypeLabels;
  studTypeChoicesOptions = StudTypeChoicesOptions;
  materialItems = {};

  updateStudType(
    item: keyof typeof StudTypeChoicesOptions,
    option: MaterialItem
  ) {
    const newValue = {
      stud_type: item,
      stud_depth: this.defaultThickness,
      stud_width: 0.0,
      stud_spacing: 0.0,
      stud_r_value: 0.0,
    };

    if (option) {
      newValue.stud_r_value = Materials[option].r_value;

      newValue.stud_depth = Materials[option].thickness;
    }
    this.entityForm.patchValue(newValue);
  }

  ngOnInit(): void {
    Object.entries(Materials).forEach(([key, value]) => {
      this.materialItems[key] = value.name;
    });

    this.entityForm.controls.user_defined_framing_fraction.valueChanges.subscribe(
      value => {
        if (value) {
          this.entityForm.controls.framing_fraction.enable({
            emitEvent: false,
          });
        } else {
          this.entityForm.controls.framing_fraction.disable({
            emitEvent: false,
          });
        }
      }
    );
  }
}
