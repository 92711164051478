<h2 mat-dialog-title>
  Current Applied Filters
</h2>
<mat-dialog-content>
  <p *ngFor="let item of data.filters | defaultOrderKeyValue;">
    <b>{{ item.key }}</b>: {{ item.value }}
  </p>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="col-12 text-right">
    <button mat-raised-button color="accent" [mat-dialog-close]="false">
      OK
    </button>
  </div>
</mat-dialog-actions>
