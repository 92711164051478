import { createSelector } from '@ngrx/store';
import { SimulationState } from '../reducers';
import { selectSimulationFeature } from '../simulation.selectors';

export const selectRimJoistType = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.rimJoistType.entities[id]
  );

const selectRimJoistTypeLoadingStatus = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.rimJoistType.loading[id]
  );

export const selectRimJoistViewSet = (id: number) =>
  createSelector(
    selectRimJoistType(id),
    selectRimJoistTypeLoadingStatus(id),
    (rimJoistType, loadingStatus) => ({
      data: rimJoistType,
      loading: loadingStatus,
    })
  );

const selectRimJoistTypeName = (id: number) =>
  createSelector(selectRimJoistType(id), rimJoistType => rimJoistType.name);

const selectRimJoistTypeErrorCount = (id: number) =>
  createSelector(selectSimulationFeature, (state: SimulationState) => {
    return state.rimJoistType.errors[id].errorCount;
  });

export const selectRimJoistTypeNameAndError = (id: number) =>
  createSelector(
    selectRimJoistTypeName(id),
    selectRimJoistTypeErrorCount(id),
    (name, errorCount) => {
      return {
        name,
        errorCount,
      };
    }
  );
