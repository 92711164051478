import { WindowTypeBackendDict } from '@/data/simulation/models/enclosure/WindowType';
import { createAction, props } from '@ngrx/store';
import { StateErrors } from '../shared/base.state';

export const loadWindowTypes = createAction(
  '[WindowType] Load WindowTypes',
  props<{ windowTypes: WindowTypeBackendDict[] }>()
);

export const loadWindowTypesSuccess = createAction(
  '[WindowType] Load WindowTypes Success',
  props<{
    windowTypes: WindowTypeBackendDict[];
    errors: StateErrors<WindowTypeBackendDict>;
  }>()
);

export const addWindowType = createAction(
  '[WindowType UI] Add WindowType',
  props<{ windowId: number; windowType: WindowTypeBackendDict }>()
);

export const removeWindowType = createAction(
  '[Window UI] Remove WindowType',
  props<{ windowId: number; windowTypeId: number }>()
);

export const removeWindowTypeSuccess = createAction(
  '[WindowType API] Remove WindowType Success',
  props<{ id: number }>()
);

export const updateWindowType = createAction(
  '[WindowType UI] Update WindowType',
  props<{ windowTypeChanges: Partial<WindowTypeBackendDict> }>()
);

export const updateWindowTypeFailure = createAction(
  '[WindowType API] Update WindowType Failure',
  props<{ id: number }>()
);
