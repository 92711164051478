import { createAction, props } from '@ngrx/store';

import { FloorPlan } from '@/data/floorplan/models/floorplan';
import { CustomerDocument } from '@/data/filehandling/models/customer-document';

export const resetFloorplan = createAction('[FloorPlan] Reset');

export const loadFloorplan = createAction(
  '[FloorPlan] Detail',
  props<{ payload: FloorPlan }>()
);

export const loadFloorplanDocuments = createAction(
  '[Floorplan Documents] Documents',
  props<{ payload: CustomerDocument[] }>()
);

export const loadFloorplanDocumentsCount = createAction(
  '[Floorplan Documents] Documents Count',
  props<{ payload: number }>()
);

export const loadFloorplanHomeStatusesCount = createAction(
  '[Floorplan Homes] Homes Count',
  props<{ payload: number }>()
);

export const updateFloorplanDocument = createAction(
  '[Floorplan Documents] Update Document',
  props<{ payload: CustomerDocument }>()
);

export const deleteFloorplanDocument = createAction(
  '[Floorplan Documents] Delete Document',
  props<{ payload: CustomerDocument }>()
);
