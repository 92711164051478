<div class="card position-relative" appFocusTarget entityName="mechanicalVentilation" [entityId]="entityId">
    <app-loading-overlay *ngIf="loading"></app-loading-overlay>
    <div class="card-body pb-0">
        <app-form formName="mechanicalVentilationFormGroup" [formGroup]="mechanicalVentilationForm" (save)="onSave($event)">
            <div>
                <app-select formControlName="type" [label]="fieldLabels.type" [items]="typeLabels"></app-select>
                <app-select formControlName="use_type" [label]="fieldLabels.use_type" [items]="useCaseLabels"></app-select>
            </div>
            <div>
                <app-input formControlName="adjusted_sensible_recovery_efficiency" [label]="fieldLabels.adjusted_sensible_recovery_efficiency" type="number"></app-input>
            </div>
            <div>
                <app-input formControlName="adjusted_total_recovery_efficiency" [label]="fieldLabels.adjusted_total_recovery_efficiency" type="number"></app-input>
            </div>
            <div>
                <app-input
                    formControlName="outside_air_percent"
                    [label]="fieldLabels.outside_air_percent"
                    type="number">
                </app-input>
                <app-input
                    formControlName="recirculated_air_percent"
                    [label]="fieldLabels.recirculated_air_percent"
                    type="number">
                </app-input>
            </div>
            <div>
                <app-input formControlName="hour_per_day" [label]="fieldLabels.hour_per_day" type="number" unit="hrs/day"></app-input>
                <app-checkbox
                    formControlName="ecm_motor"
                    [label]="fieldLabels.ecm_motor">
                </app-checkbox>
            </div>
            <div>
                <app-input *ngIf="!mechanicalVentilationForm.controls.ventilation_rate_unmeasured.value"
                    formControlName="ventilation_rate" [label]="fieldLabels.ventilation_rate" type="number" unit="{{ventilationRateLabels[entity?.ventilation_rate_unit]}}">
                </app-input>
                <app-checkbox formControlName="ventilation_rate_unmeasured" [label]="fieldLabels.ventilation_rate_unmeasured">
                </app-checkbox>
            </div>
            <div>
                <app-input *ngIf="!mechanicalVentilationForm.controls.use_default_consumption.value" formControlName="consumption"
                    [label]="fieldLabels.consumption" type="number" unit="{{auxEnergyUnitLabels[entity?.consumption_unit]}}">
                </app-input>
                <app-checkbox formControlName="use_default_consumption" label="Use Default Consumption"></app-checkbox>
            </div>
        </app-form>
    </div>
    <div class="card-footer p-0 text-right">
        <app-model-action-buttons [entityId]="entityId" modelName="mechanicalVentilation" (clone)="cloneMechanicalVentilation()" (delete)="removeMechanicalVentilation()">
        </app-model-action-buttons>
    </div>
</div>