import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { first, catchError } from 'rxjs/operators';

import { of } from 'rxjs';
import { AuthenticationService } from '@/core/services/authentication.service';

@Component({
  selector: 'app-nav-login',
  templateUrl: 'nav-login.component.html',
})
export class NavLoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  loading = false;
  error = false;
  submitted = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  login() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    } // stop here if form is invalid

    this.loading = true;
    this.authenticationService
      .login(String(this.f.username.value), String(this.f.password.value))
      .pipe(
        first(),
        catchError(err => {
          this.loading = false;
          this.error = true;
          return of(false);
        })
      )
      .subscribe();
  }
}
