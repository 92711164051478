import { Annotation } from '@/data/annotation/models';
import { createAction, props } from '@ngrx/store';

export const loadAnnotations = createAction(
  '[Annotation] Load Annotations',
  props<{ simulationId: number }>()
);

export const loadAnnotationsSuccess = createAction(
  '[Annotation] Load Annotations Success',
  props<{ annotations: Annotation[] }>()
);

export const loadAnnotationsFailure = createAction(
  '[Annotation] Load Annotations Failure',
  props<{ error: any }>()
);

export const updateAnnotation = createAction(
  '[Annotation] Update Annotation',
  props<{ contentType: string; objectId: number; annotation: Annotation }>()
);

export const updateAnnotationSuccess = createAction(
  '[Annotation] Update Annotation Success',
  props<{ annotation: Annotation }>()
);

export const updateAnnotationFailure = createAction(
  '[Annotation API] Update Annotation Failure',
  props<{ id: number }>()
);

export const deleteAnnotation = createAction(
  '[Annotation UI] Delete Annotation',
  props<{ content_type: string; object_id: number; annotationId: number }>()
);

export const deleteAnnotationSuccess = createAction(
  '[Annotation API] Delete Annotation Success',
  props<{ content_type: string; object_id: number; annotationId: number }>()
);

export const addAnnotation = createAction(
  '[Annotation UI] Add Annotation',
  props<{ content_type: string; object_id: number; annotation: Annotation }>()
);
