import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AbandonHIRLProjectRegistration,
  CreateLandDevelopmentHIRLProjectRegistration,
  CreateSFHIRLProjectRegistration,
  HIRLProject,
  HIRLProjectAddSF,
  HIRLProjectRegistration,
  HIRLProjectRegistrationList,
} from '@/data/customer-hirl/models';
import { ServerResponse } from '@/core/schemes/server-response';
import { environment } from '../../../../environments/environment';
import { ListRequestParams } from '@/core/schemes/request-params';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import { HIRLProjectRequestParams } from '@/data/customer-hirl/services/hirl-projects.service';
import {
  CreateMFHIRLProjectRegistration,
  HIRLProjectAddMF,
  HIRLProjectRegistrationActivityMetricsByMonth,
  HIRLProjectRegistrationActivityMetricsUnitsByMonth,
} from '@/data/customer-hirl/models/hirl-project-registration';
import { tap } from 'rxjs/operators';
import {
  loadHIRLProjectRegistration,
  loadHIRLProjectRegistrationProjects,
  loadHIRLProjectRegistrationProjectsCount,
} from '@/state/actions/customer-hirl/hirl-project-registration.actions';

export class HIRLProjectRegistrationRequestParams extends ListRequestParams {
  project_type?: string;
  without_projects?: boolean;
  state?: number;
  eep_program?: number | number[];
  eep_program__slug?: string | string[];
}

export class HIRLProjectRegistrationActivityMetricsByMonthRequestParams extends ListRequestParams {
  years?: string[];
  eep_program__slug?: string | string[];
  is_build_to_rent?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class HIRLProjectRegistrationService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/hirl_project_registrations/`;

  list(params?: HIRLProjectRegistrationRequestParams) {
    return this.http.get<ServerResponse<HIRLProjectRegistrationList>>(
      `${this.baseUrl}`,
      {
        params: this.rollParams(params),
      }
    );
  }

  retrieve(
    projectRegistrationId: string,
    options?: IDataServiceMethodOptions
  ): Observable<HIRLProjectRegistration> {
    const ignoreStore = options?.ignoreStore;
    let chain = this.http.get<HIRLProjectRegistration>(
      `${this.baseUrl}${projectRegistrationId}`
    );
    if (!ignoreStore) {
      chain = chain.pipe(
        tap(registration =>
          this.store.dispatch(
            loadHIRLProjectRegistration({ payload: registration })
          )
        )
      );
    }
    return chain;
  }

  create_single_family(
    body: CreateSFHIRLProjectRegistration
  ): Observable<HIRLProjectRegistration> {
    return this.http.post<HIRLProjectRegistration>(
      `${this.baseUrl}create_single_family/`,
      body
    );
  }

  create_multi_family(
    body: CreateMFHIRLProjectRegistration
  ): Observable<HIRLProjectRegistration> {
    return this.http.post<HIRLProjectRegistration>(
      `${this.baseUrl}create_multi_family/`,
      body
    );
  }

  create_land_development(
    body: CreateLandDevelopmentHIRLProjectRegistration
  ): Observable<HIRLProjectRegistration> {
    return this.http.post<HIRLProjectRegistration>(
      `${this.baseUrl}create_land_development/`,
      body
    );
  }

  update(
    hirlProjectRegistration: HIRLProjectRegistration,
    projectRegistrationId: string,
    partial = true
  ) {
    let method = this.http.patch;
    if (!partial) {
      method = this.http.put;
    }
    return this.http.patch<HIRLProjectRegistration>(
      `${this.baseUrl}${projectRegistrationId}/`,
      hirlProjectRegistration
    );
  }

  delete(
    projectRegistration: HIRLProjectRegistration,
    options?: IDataServiceMethodOptions
  ) {
    const ignoreStore = options?.ignoreStore;
    const chain = this.http.delete(`${this.baseUrl}${projectRegistration.id}/`);
    if (!ignoreStore) {
      // chain = chain.pipe(
      //     tap(registration => this.store.dispatch(deleteHIRLProjectRegistration({payload: registration})))
      // );
    }
    return chain;
  }

  abandon(
    projectRegistrationId: string,
    data: AbandonHIRLProjectRegistration | FormData,
    options?: IDataServiceMethodOptions
  ): Observable<HIRLProjectRegistration> {
    const ignoreStore = options?.ignoreStore;
    let chain = this.http.post<HIRLProjectRegistration>(
      `${this.baseUrl}${projectRegistrationId}/abandon/`,
      data
    );
    if (!ignoreStore) {
      chain = chain.pipe(
        tap(registration =>
          this.store.dispatch(
            loadHIRLProjectRegistration({ payload: registration })
          )
        )
      );
    }
    return chain;
  }

  appeals_registrations_for_client(
    projectRegistrationId: string
  ): Observable<ServerResponse<HIRLProjectRegistration>> {
    return this.http.get<ServerResponse<HIRLProjectRegistration>>(
      `${this.baseUrl}${projectRegistrationId}/appeals_registrations_for_client`
    );
  }

  hirl_projects(
    projectRegistrationId: string,
    params?: HIRLProjectRequestParams
  ): Observable<ServerResponse<HIRLProject>> {
    return this.http
      .get<ServerResponse<HIRLProject>>(
        `${this.baseUrl}${projectRegistrationId}/hirl_projects`,
        {
          params: this.rollParams(params),
        }
      )
      .pipe(
        tap(serverResponse =>
          this.store.dispatch(
            loadHIRLProjectRegistrationProjects({
              payload: serverResponse.results,
            })
          )
        ),
        tap(serverResponse =>
          this.store.dispatch(
            loadHIRLProjectRegistrationProjectsCount({
              payload: serverResponse.count,
            })
          )
        )
      );
  }

  hirl_projects_create_single_family(
    projectRegistrationId: string,
    body: HIRLProjectAddSF
  ): Observable<ServerResponse<HIRLProject[]>> {
    return this.http.post<ServerResponse<HIRLProject[]>>(
      `${this.baseUrl}${projectRegistrationId}/hirl_projects/create_single_family/`,
      body
    );
  }

  hirl_projects_create_multi_family(
    projectRegistrationId: string,
    body: HIRLProjectAddMF
  ): Observable<ServerResponse<HIRLProject[]>> {
    return this.http.post<ServerResponse<HIRLProject[]>>(
      `${this.baseUrl}${projectRegistrationId}/hirl_projects/create_multi_family/`,
      body
    );
  }

  approve(projectRegistrationId: string): Observable<HIRLProjectRegistration> {
    return this.http
      .post<HIRLProjectRegistration>(
        `${this.baseUrl}${projectRegistrationId}/approve/`,
        {}
      )
      .pipe(
        tap(registration =>
          this.store.dispatch(
            loadHIRLProjectRegistration({ payload: registration })
          )
        )
      );
  }

  reject(
    projectRegistrationId: string,
    reason = ''
  ): Observable<HIRLProjectRegistration> {
    return this.http
      .post<HIRLProjectRegistration>(
        `${this.baseUrl}${projectRegistrationId}/reject/`,
        {
          reason,
        }
      )
      .pipe(
        tap(registration =>
          this.store.dispatch(
            loadHIRLProjectRegistration({ payload: registration })
          )
        )
      );
  }

  registration_activity_metrics_by_month(
    params?: HIRLProjectRegistrationActivityMetricsByMonthRequestParams
  ): Observable<HIRLProjectRegistrationActivityMetricsByMonth[]> {
    return this.http.get<HIRLProjectRegistrationActivityMetricsByMonth[]>(
      `${this.baseUrl}registration_activity_metrics_by_month/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  registration_activity_metrics_units_by_month(
    params?: HIRLProjectRegistrationActivityMetricsByMonthRequestParams
  ): Observable<HIRLProjectRegistrationActivityMetricsUnitsByMonth[]> {
    return this.http.get<HIRLProjectRegistrationActivityMetricsUnitsByMonth[]>(
      `${this.baseUrl}registration_activity_metrics_units_by_month/`,
      {
        params: this.rollParams(params),
      }
    );
  }
}
