import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as AnnotationActions from '@/state/actions/annotation/annotation.actions';
import { AnnotationService } from '@/data/annotation/services/annotation.service';
import { reportAPIFailure } from '@/state/actions/app.actions';

@Injectable()
export class AnnotationEffects {
  loadAnnotations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AnnotationActions.loadAnnotations),
      mergeMap(action => {
        return this.annotationService
          .listSimulationRelatedAnnotations(action.simulationId)
          .pipe(
            mergeMap(annotations => {
              return of(
                AnnotationActions.loadAnnotationsSuccess({
                  annotations: annotations,
                })
              );
            }),
            catchError(error =>
              of(
                AnnotationActions.loadAnnotationsFailure({ error }),
                reportAPIFailure({ error })
              )
            )
          );
      })
    );
  });

  addAnnotation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AnnotationActions.addAnnotation),
      mergeMap(action => {
        return this.annotationService
          .createSimulationRelatedAnnotation(
            action.content_type,
            action.object_id,
            action.annotation
          )
          .pipe(
            mergeMap(response => {
              return of(
                AnnotationActions.loadAnnotationsSuccess({
                  annotations: [{ ...response }],
                })
              );
            }),
            catchError(error =>
              of(
                AnnotationActions.loadAnnotationsFailure({ error }),
                reportAPIFailure({ error })
              )
            )
          );
      })
    );
  });

  updateAnnotation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AnnotationActions.updateAnnotation),
      mergeMap(action => {
        return this.annotationService
          .updateSimulationRelatedAnnotation(
            action.contentType,
            action.objectId,
            action.annotation.id,
            action.annotation
          )
          .pipe(
            mergeMap(response => {
              return of(
                AnnotationActions.loadAnnotationsSuccess({
                  annotations: [{ ...response }],
                })
              );
            }),
            catchError(error =>
              of(
                AnnotationActions.updateAnnotationFailure({
                  id: action.annotation.id,
                }),
                reportAPIFailure({ error })
              )
            )
          );
      })
    );
  });

  deleteAnnotation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AnnotationActions.deleteAnnotation),
      mergeMap(action => {
        return this.annotationService
          .deleteSimulationRelatedAnnotation(
            action.content_type,
            action.object_id,
            action.annotationId
          )
          .pipe(
            mergeMap(() => {
              return of(
                AnnotationActions.deleteAnnotationSuccess({
                  content_type: action.content_type,
                  object_id: action.object_id,
                  annotationId: action.annotationId,
                })
              );
            }),
            catchError(error =>
              of(
                AnnotationActions.loadAnnotationsFailure({ error }),
                reportAPIFailure({ error })
              )
            )
          );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private annotationService: AnnotationService
  ) {}
}
