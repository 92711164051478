import { Action, createReducer, on } from '@ngrx/store';
import {
  HIRLProject,
  HIRLProjectRegistration,
} from '@/data/customer-hirl/models';
import {
  deleteHIRLProjectRegistrationProject,
  loadHIRLProjectRegistration,
  loadHIRLProjectRegistrationProjects,
  loadHIRLProjectRegistrationProjectsCount,
  resetHIRLProjectRegistration,
} from '@/state/actions/customer-hirl/hirl-project-registration.actions';

export const hirlProjectRegistrationStateKey = 'hirlProjectRegistration';

export interface HIRLProjectRegistrationState {
  detail: HIRLProjectRegistration;
  projects: HIRLProject[];
  projectsCount: number;
}

export const HIRLProjectRegistrationInitialState: HIRLProjectRegistrationState =
  {
    detail: null,
    projects: [],
    projectsCount: 0,
  };

const HIRLProjectRegistrationReducer = createReducer(
  HIRLProjectRegistrationInitialState,
  on(resetHIRLProjectRegistration, (state, action) => ({
    ...HIRLProjectRegistrationInitialState,
  })),
  on(loadHIRLProjectRegistration, (state, { payload }) => ({
    ...state,
    detail: payload,
  })),
  // projects
  on(loadHIRLProjectRegistrationProjects, (state, { payload }) => ({
    ...state,
    projects: payload,
  })),
  on(loadHIRLProjectRegistrationProjectsCount, (state, { payload }) => ({
    ...state,
    projectsCount: payload,
  })),
  on(deleteHIRLProjectRegistrationProject, (state, { payload }) => {
    const projectIndex = state.projects.findIndex(
      project => project.id === payload.id
    );
    const newProjects = Object.assign([], state.projects);
    newProjects.splice(projectIndex, 1);
    return {
      ...state,
      projects: newProjects,
      projectsCount: state.projectsCount - 1,
    };
  })
);

export function reducer(
  state: HIRLProjectRegistrationState | undefined,
  action: Action
) {
  return HIRLProjectRegistrationReducer(state, action);
}
