import { createAction, props } from '@ngrx/store';
import {
  PaymentRequest,
  PaymentRequestProduct,
} from '@/data/customer-hirl/models';

export const resetPaymentRequest = createAction('[HIRLPaymentRequest] Reset');

export const loadPaymentRequest = createAction(
  '[HIRLPaymentRequest] Detail',
  props<{ payload: PaymentRequest }>()
);

// PaymentRequestProducts

export const loadPaymentRequestProductsAction = createAction(
  '[HIRLPaymentRequest PaymentRequestProducts] Products',
  props<{ payload: PaymentRequestProduct[] }>()
);

export const loadPaymentRequestProductsCountAction = createAction(
  '[HIRLPaymentRequest PaymentRequestProducts] Products Count',
  props<{ payload: number }>()
);

export const deletePaymentRequestProductAction = createAction(
  '[HIRLPaymentRequest PaymentRequestProducts] Delete Product',
  props<{ payload: PaymentRequestProduct }>()
);
