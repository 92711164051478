import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { User } from '@/data/core/models/user';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { HIRLProjectService } from '@/data/customer-hirl/services/hirl-projects.service';
import { HIRLProjectRegistrationService } from '@/data/customer-hirl/services/hirl-project-registration.service';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '@/data/core/services/user.service';
import { toggleLoading } from '@/state/actions/app.actions';
import { forkJoin, Subject } from 'rxjs';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, takeUntil } from 'rxjs/operators';
import {
  CreateLandDevelopmentHIRLProject,
  CreateLandDevelopmentHIRLProjectRegistration,
  HIRLProjectRegistration,
  HIRLProjectRegistrationLDWorkflowType,
  HIRLProjectRegistrationLDWorkflowTypeMapping,
  HIRLProjectRegistrationProjectClientType,
  LandDevelopmentProjectType,
} from '@/data/customer-hirl/models';
import { conditionalValidator } from '@/core/validators';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { NavigationService } from '@/shared/services/navigation-service.service';
import { formatDate } from '@angular/common';
import { hirlProjectAddressExistsValidator } from '@/modules/customer-hirl/field-validators';
import { AppFieldValidatorsService } from '@/core/services/field-validators.service';
import { CompanyAutocompleteList } from '@/data/company/models';
import * as moment from 'moment';
import { HIRLProjectLDRegistrationCertificateLineChoices } from '@/data/customer-hirl/models';

@Component({
  selector: 'app-hirl-project-registration-land-development-form',
  templateUrl:
    './hirl-project-registration-land-development-form.component.html',
  styleUrls: [
    './hirl-project-registration-land-development-form.component.scss',
  ],
})
export class HirlProjectRegistrationLandDevelopmentFormComponent
  implements OnInit
{
  @ViewChild('stepper') private stepper: MatStepper;

  @ViewChild('letterFieldsContainer') set letterFieldsContainer(element) {
    // validators are not updating correctly with *ngIf and stepper
    // https://angular.io/errors/NG0100
    // manually resolve this
    this.cdRef.detectChanges();

    this.buildingFormGroup
      ?.get('letter_address')
      .updateValueAndValidity({ emitEvent: true });
    this.buildingFormGroup
      ?.get('phases')
      .updateValueAndValidity({ emitEvent: true });
  }

  public initialized = false;
  public editForm = false;
  public currentUser: User;

  public projectRegistrationLDWorkflowType =
    HIRLProjectRegistrationLDWorkflowType;
  public hirlProjectLDWorkflowTypeMapping =
    HIRLProjectRegistrationLDWorkflowTypeMapping;

  public projectInformationFormGroup: UntypedFormGroup;
  public projectTypeFormGroup: UntypedFormGroup;
  public buildingFormGroup: UntypedFormGroup;
  public developerFormGroup: UntypedFormGroup;
  public certificateNamesFormGroup: UntypedFormGroup;
  public estimatedCompletionDateMinDate?: Date;
  public hirlProjectRegistration?: HIRLProjectRegistration;

  // this key is using to save incomplete form data on create and restore it for next visit
  public localStorageFormDataSaveKey =
    'project-registration-land-development-form';

  public certificateLineChoices =
    HIRLProjectLDRegistrationCertificateLineChoices;
  public certificateLineChoicesMapping = {
    [HIRLProjectLDRegistrationCertificateLineChoices.emptyLine]: 'Empty Line',
    [HIRLProjectLDRegistrationCertificateLineChoices.developmentName]:
      'Subdivision/Development Name',
    [HIRLProjectLDRegistrationCertificateLineChoices.addressLineDouble]:
      'Address (2 lines)',
    [HIRLProjectLDRegistrationCertificateLineChoices.clientCompanyNameWithCity]:
      'Client Company (2 lines)',
    [HIRLProjectLDRegistrationCertificateLineChoices.customText]:
      'Custom Text (black font)',
    [HIRLProjectLDRegistrationCertificateLineChoices.customTextGreen]:
      'Custom Text (green font)',
  };

  private componentDestroyed$ = new Subject();

  constructor(
    public fb: UntypedFormBuilder,
    public store: Store<AppState>,
    public router: Router,
    private cdRef: ChangeDetectorRef,
    public hirlProjectService: HIRLProjectService,
    public hirlProjectRegistrationService: HIRLProjectRegistrationService,
    public dialog: MatDialog,
    public activatedRoute: ActivatedRoute,
    public userService: UserService,
    public uiHelperService: UIHelperService,
    public appFieldValidatorsService: AppFieldValidatorsService,
    private navigation: NavigationService
  ) {
    this.estimatedCompletionDateMinDate = moment().toDate();
    if (this.activatedRoute.snapshot.params.projectRegistrationId) {
      this.editForm = true;
    }
  }

  ngOnInit(): void {
    this.store.dispatch(toggleLoading({ payload: true }));

    const sources: { [k: string]: any } = {
      currentUser: this.store.select(getInfoUser).pipe(first()),
    };

    if (this.editForm) {
      sources.hirlProjectRegistration = this.hirlProjectRegistrationService
        .retrieve(this.activatedRoute.snapshot.params.projectRegistrationId)
        .pipe(first());
    }

    forkJoin(sources).subscribe(({ currentUser, hirlProjectRegistration }) => {
      this.currentUser = currentUser;
      this.hirlProjectRegistration = hirlProjectRegistration;

      this.store.dispatch(toggleLoading({ payload: false }));
      this.initialized = true;

      if (this.editForm) {
        this.hydrateForm();
      } else {
        this.loadPreviousFormData();
      }
    });

    if (this.editForm) {
      this.setupProjectInformationFormGroup();
      this.setupDeveloperFormGroup();
      this.setupCertificateNamesFormGroup();
    } else {
      this.setupProjectInformationFormGroup();
      this.setupProjectTypeFormGroup();
      this.setupBuildingFormGroup();
      this.setupDeveloperFormGroup();
      this.setupCertificateNamesFormGroup();
    }
  }

  hydrateForm() {
    const payload = Object.assign({}, this.hirlProjectRegistration);
    this.projectInformationFormGroup.patchValue(payload, {
      emitEvent: false,
      onlySelf: true,
    });
    this.developerFormGroup.patchValue(payload, {
      emitEvent: false,
      onlySelf: true,
    });
  }

  loadPreviousFormData() {
    let formData;
    try {
      formData = JSON.parse(
        localStorage.getItem(this.localStorageFormDataSaveKey)
      );
    } catch (e) {
      return;
    }

    if (!formData) {
      return;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      disableClose: true,
      data: {
        title: 'Welcome Back !',
        content:
          'Looks like you did not complete the form last time. ' +
          "Click 'Confirm' to start where you left off or click 'Cancel' to start again",
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      this.projectInformationFormGroup.patchValue(formData, {
        emitEvent: true,
        onlySelf: true,
      });
      this.projectTypeFormGroup.patchValue(formData, {
        emitEvent: true,
        onlySelf: true,
      });
      this.developerFormGroup.patchValue(formData, {
        emitEvent: true,
        onlySelf: true,
      });
    });
  }

  public setupProjectInformationFormGroup() {
    this.projectInformationFormGroup = this.fb.group({
      eep_program: [null, Validators.required],
      project_name: [null, Validators.required],
      project_client: [
        HIRLProjectRegistrationProjectClientType.builder,
        Validators.required,
      ],
      estimated_completion_date: [null, Validators.required],
      project_description: ['', Validators.required],
      project_website_url: [
        '',
        Validators.compose([
          Validators.required,
          this.appFieldValidatorsService.websiteURLValidator(),
        ]),
      ],
    });
  }

  public setupCertificateNamesFormGroup() {
    this.certificateNamesFormGroup = this.fb.group(
      {
        certificate_line1: [
          HIRLProjectLDRegistrationCertificateLineChoices.emptyLine,
        ],
        certificate_line1_custom_text: [''],
        certificate_line2: [
          HIRLProjectLDRegistrationCertificateLineChoices.developmentName,
        ],
        certificate_line2_custom_text: [''],
        certificate_line3: [
          HIRLProjectLDRegistrationCertificateLineChoices.addressLineDouble,
        ],
        certificate_line3_custom_text: [''],
        certificate_line4: [
          HIRLProjectLDRegistrationCertificateLineChoices.emptyLine,
        ],
        certificate_line4_custom_text: [''],
        certificate_line5: [
          HIRLProjectLDRegistrationCertificateLineChoices.emptyLine,
        ],
        certificate_line5_custom_text: [''],
        certificate_line6: [
          HIRLProjectLDRegistrationCertificateLineChoices.customText,
        ],
        certificate_line6_custom_text: ['DEVELOPED BY:'],
        certificate_line7: [
          HIRLProjectLDRegistrationCertificateLineChoices.clientCompanyNameWithCity,
        ],
        certificate_line7_custom_text: [''],
        certificate_line8: [
          HIRLProjectLDRegistrationCertificateLineChoices.emptyLine,
        ],
        certificate_line8_custom_text: [''],
        certificate_line9: [
          HIRLProjectLDRegistrationCertificateLineChoices.emptyLine,
        ],
        certificate_line9_custom_text: [''],
      },
      { validator: this.addressValidator }
    );
  }

  addressValidator(group: UntypedFormGroup): { [key: string]: any } | null {
    const fields = [
      'certificate_line1',
      'certificate_line2',
      'certificate_line3',
      'certificate_line4',
      'certificate_line5',
      'certificate_line6',
      'certificate_line7',
      'certificate_line8',
      'certificate_line9',
    ];

    const customTextFields = [
      'certificate_line1_custom_text',
      'certificate_line2_custom_text',
      'certificate_line3_custom_text',
      'certificate_line4_custom_text',
      'certificate_line5_custom_text',
      'certificate_line6_custom_text',
      'certificate_line7_custom_text',
      'certificate_line8_custom_text',
      'certificate_line9_custom_text',
    ];

    const hasAddress = fields.some((fieldName, index) => {
      const field = group.get(fieldName);
      const customTextField = group.get(customTextFields[index]);
      return (
        (field &&
          field.value ===
            HIRLProjectLDRegistrationCertificateLineChoices.addressLineDouble) ||
        (customTextField &&
          customTextField.value.toLowerCase().includes('address'))
      );
    });

    return hasAddress ? null : { addressRequired: true };
  }

  public setupProjectTypeFormGroup() {
    this.projectTypeFormGroup = this.fb.group({
      ld_workflow: [null],
      is_phased_development: [false],
    });

    this.projectTypeFormGroup
      .get('is_phased_development')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe(value => {
        if (!value) {
          const controls = this.buildingFormGroup.controls
            .phases as UntypedFormArray;
          for (let i = 0; i < controls.length; i++) {
            controls.removeAt(i);
          }
        }
      });
  }

  public setupBuildingFormGroup() {
    this.buildingFormGroup = this.fb.group(
      {
        address: [
          null,
          [Validators.required],
          [
            hirlProjectAddressExistsValidator(
              this.hirlProjectService,
              null,
              false
            ),
          ],
        ],
        letter_address: [
          null,
          conditionalValidator(
            () =>
              this.projectTypeFormGroup.value.ld_workflow ===
              HIRLProjectRegistrationLDWorkflowType.letterOfApprovalAndFullCertification,
            Validators.required
          ),
          [
            hirlProjectAddressExistsValidator(
              this.hirlProjectService,
              null,
              false
            ),
          ],
        ],
        number_of_lots: [null, Validators.required],
        are_all_homes_in_ld_seeking_certification: [false],
        phases: this.fb.array([]),
      },
      {
        validators: [
          this.appFieldValidatorsService.uniqueConfirmedGeocodeResponseValidator(
            ['address', 'letter_address', 'phases->address']
          ),
        ],
      }
    );
  }

  public setupDeveloperFormGroup() {
    this.developerFormGroup = this.fb.group({
      builder_organization_info: [null, Validators.required],
      builder_organization_contact: [null, Validators.required],
    });
  }

  handleCreate($event: MouseEvent) {
    $event.preventDefault();
    this.store.dispatch(toggleLoading({ payload: true }));

    const formData = new CreateLandDevelopmentHIRLProjectRegistration();

    formData.eep_program = this.projectInformationFormGroup.value.eep_program;
    formData.project_name = this.projectInformationFormGroup.value.project_name;
    formData.project_description =
      this.projectInformationFormGroup.value.project_description;
    formData.project_website_url =
      this.projectInformationFormGroup.value.project_website_url;
    formData.estimated_completion_date = `${formatDate(
      this.projectInformationFormGroup.value.estimated_completion_date,
      'yyyy-MM-dd',
      'en-US'
    )}`;
    formData.builder_organization =
      this.developerFormGroup.value.builder_organization_info.id;
    formData.builder_organization_contact =
      this.developerFormGroup.value.builder_organization_contact;
    formData.ld_workflow = this.projectTypeFormGroup.value.ld_workflow;

    // main project
    let projectFormData = new CreateLandDevelopmentHIRLProject();
    let phaseNumber = 1;
    projectFormData.number_of_lots =
      this.buildingFormGroup.value.number_of_lots;

    projectFormData.lot_number = this.buildingFormGroup.value.address.lotNumber;
    projectFormData.home_address_geocode =
      this.buildingFormGroup.value.address.geocode.id;
    projectFormData.home_address_geocode_response = null;
    projectFormData.are_all_homes_in_ld_seeking_certification =
      this.buildingFormGroup.value.are_all_homes_in_ld_seeking_certification;
    projectFormData.land_development_project_type =
      LandDevelopmentProjectType.phase;
    projectFormData.land_development_phase_number = phaseNumber;

    if (this.buildingFormGroup.value.address.geocodeBrokeredResponse) {
      projectFormData.home_address_geocode_response =
        this.buildingFormGroup.value.address.geocodeBrokeredResponse.id;
    }

    formData.projects.push(projectFormData);

    // phase projects
    for (const phaseData of this.buildingFormGroup.controls.phases.value) {
      phaseNumber += 1;
      projectFormData = new CreateLandDevelopmentHIRLProject();

      projectFormData.lot_number = phaseData.address.lotNumber;
      projectFormData.are_all_homes_in_ld_seeking_certification =
        phaseData.are_all_homes_in_ld_seeking_certification;
      projectFormData.land_development_project_type =
        LandDevelopmentProjectType.phase;
      projectFormData.land_development_phase_number = phaseNumber;
      projectFormData.number_of_lots = phaseData.number_of_lots;

      projectFormData.home_address_geocode = phaseData.address.geocode.id;
      projectFormData.home_address_geocode_response = null;

      if (phaseData.address.geocodeBrokeredResponse) {
        projectFormData.home_address_geocode_response =
          phaseData.address.geocodeBrokeredResponse.id;
      }

      formData.projects.push(projectFormData);
    }

    projectFormData = new CreateLandDevelopmentHIRLProject();
    if (
      this.projectTypeFormGroup.value.ld_workflow ===
      HIRLProjectRegistrationLDWorkflowType.letterOfApprovalAndFullCertification
    ) {
      // letter project
      projectFormData.lot_number =
        this.buildingFormGroup.value.address.lotNumber;
      projectFormData.home_address_geocode =
        this.buildingFormGroup.value.letter_address.geocode.id;
      projectFormData.home_address_geocode_response = null;
      projectFormData.land_development_project_type =
        LandDevelopmentProjectType.letter;

      if (this.buildingFormGroup.value.letter_address.geocodeBrokeredResponse) {
        projectFormData.home_address_geocode_response =
          this.buildingFormGroup.value.letter_address.geocodeBrokeredResponse.id;
      }

      formData.projects.push(projectFormData);
    }

    this.hirlProjectRegistrationService
      .create_land_development(formData)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(
        project => {
          this.store.dispatch(toggleLoading({ payload: false }));
          // remove saved un complete data, because our form was successfully saved
          localStorage.removeItem(this.localStorageFormDataSaveKey);

          this.router.navigate([`/hi/projects/`]);
        },
        error => this.uiHelperService.handleUserRequestError(error)
      );
  }

  public handleEdit($event: MouseEvent) {
    $event.preventDefault();
    this.projectInformationFormGroup.markAllAsTouched();
    this.developerFormGroup.markAllAsTouched();

    if (
      this.projectInformationFormGroup.invalid ||
      this.developerFormGroup.invalid
    ) {
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const formData = new HIRLProjectRegistration();
    formData.eep_program = this.projectInformationFormGroup.value.eep_program;
    formData.project_name = this.projectInformationFormGroup.value.project_name;
    formData.estimated_completion_date = `${formatDate(
      this.projectInformationFormGroup.value.estimated_completion_date,
      'yyyy-MM-dd',
      'en-US'
    )}`;
    formData.builder_organization =
      this.developerFormGroup.value.builder_organization_info.id;
    formData.builder_organization_contact =
      this.developerFormGroup.value.builder_organization_contact;

    this.hirlProjectRegistrationService
      .update(formData, this.hirlProjectRegistration.id)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(
        hirlProjectRegistration => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.router.navigate([
            `/hi/project_registrations/${hirlProjectRegistration.id}`,
          ]);
        },
        error => this.uiHelperService.handleUserRequestError(error)
      );
  }

  public onStepChange($event: StepperSelectionEvent) {
    if (this.editForm) {
      return;
    }
    const formData = {
      ...this.projectInformationFormGroup.value,
      ...this.projectTypeFormGroup.value,
      ...this.buildingFormGroup.value,
      ...this.developerFormGroup.value,
    };

    localStorage.setItem(
      this.localStorageFormDataSaveKey,
      JSON.stringify(formData)
    );
  }

  public addPhase(): void {
    const control = this.buildingFormGroup.controls.phases as UntypedFormArray;
    const group = this.fb.group({
      address: [
        null,
        [Validators.required],
        [
          hirlProjectAddressExistsValidator(
            this.hirlProjectService,
            null,
            false
          ),
        ],
      ],
      number_of_lots: [null, Validators.required],
      are_all_homes_in_ld_seeking_certification: [false],
    });

    control.push(group);
  }

  public getPhasesControls() {
    return (this.buildingFormGroup.get('phases') as UntypedFormArray).controls;
  }

  public onRemovePhase($event: MouseEvent, index: number): void {
    $event.preventDefault();
    const control = this.buildingFormGroup.controls.phases as UntypedFormArray;
    control.removeAt(index);
  }

  public clientCompanyDisplay(company: CompanyAutocompleteList): string {
    let name = `${company.name} [${company.address}]`;
    if (
      !company.active_customer_hirl_builder_agreements_count &&
      !company.active_coi_document_count
    ) {
      name +=
        ' (Do not have active Client Agreement and Certificate Of Insurance)';
    } else if (!company.active_customer_hirl_builder_agreements_count) {
      name += ' (Do not have active Client Agreement)';
    } else if (!company.active_coi_document_count) {
      name += ' (Do not have active Certificate Of Insurance)';
    }
    return name;
  }
}
