import { MechanicalVentilationBackendDict } from '@/data/simulation/models/systems/MechanicalVentilation';
import { createAction, props } from '@ngrx/store';
import { StateErrors } from '../shared/base.state';

export const loadMechanicalVentilations = createAction(
  '[MechanicalVentilation] Load MechanicalVentilations',
  props<{ mechanicalVentilations: MechanicalVentilationBackendDict[] }>()
);

export const loadMechanicalVentilationsSuccess = createAction(
  '[MechanicalVentilation] Load MechanicalVentilations Success',
  props<{
    mechanicalVentilations: MechanicalVentilationBackendDict[];
    errors: StateErrors<MechanicalVentilationBackendDict>;
  }>()
);

export const loadMechanicalVentilationsFailure = createAction(
  '[MechanicalVentilation] Load MechanicalVentilations Failure',
  props<{ error: any }>()
);

export const updateMechanicalVentilation = createAction(
  '[MechanicalVentilation] Update MechanicalVentilation',
  props<{
    mechanicalVentilationChanges: Partial<MechanicalVentilationBackendDict>;
  }>()
);

export const updateMechanicalVentilationFailure = createAction(
  '[MechanicalVentilation API] Update MechanicalVentilation Failure',
  props<{ id: number }>()
);

export const removeMechanicalVentilation = createAction(
  '[MechanicalVentilation] Remove MechanicalVentilation',
  props<{ mechanicalVentilation: MechanicalVentilationBackendDict }>()
);

export const removeMechanicalVentilationSuccess = createAction(
  '[MechanicalVentilation API] Remove MechanicalVentilation Success',
  props<{ id: number }>()
);

export const removeMechanicalVentilationFailure = createAction(
  '[MechanicalVentilation API] Remove MechanicalVentilation Failure',
  props<{ id: number }>()
);
