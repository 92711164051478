import { Injectable } from '@angular/core';
import { BaseDataService } from '@/core/services/base-data-service';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { SponsorPreferences } from '@/data/company/models/sponsoring';

@Injectable({
  providedIn: 'root',
})
export class SponsorPreferencesService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/sponsoring_preferences/`;

  retrieve(sponsorPreferencesId: string): Observable<SponsorPreferences> {
    return this.http.get<SponsorPreferences>(
      `${this.baseUrl}${sponsorPreferencesId}`
    );
  }

  create(sponsorPreferences: SponsorPreferences) {
    return this.http.post<SponsorPreferences>(
      `${this.baseUrl}`,
      sponsorPreferences
    );
  }

  update(sponsorPreferences: SponsorPreferences, sponsorPreferencesId: number) {
    return this.http.patch<SponsorPreferences>(
      `${this.baseUrl}${sponsorPreferencesId}/`,
      sponsorPreferences
    );
  }
}
