import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LocationService } from '@/data/simulation/services/location.service';
import { catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as LocationActions from './actions';
import * as SharedActions from '../shared/shared.actions';
import { LocationValidator } from '../../validators/location.validator';

@Injectable()
export class LocationEffects {
  loadLocations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LocationActions.loadLocations),
      mergeMap(action => {
        const errors = LocationValidator.validate(action.locations);
        return of(
          LocationActions.loadLocationsSuccess({
            locations: action.locations,
            errors,
          })
        );
      })
    );
  });

  updateLocation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LocationActions.updateLocation),
      mergeMap(action => {
        return this.locationService.update(action.locationChanges).pipe(
          mergeMap(location => {
            const errors = LocationValidator.validate([location]);
            return of(
              LocationActions.loadLocationsSuccess({
                locations: [location],
                errors,
              })
            );
          }),
          catchError(error =>
            of(
              LocationActions.updateLocationFailure({
                id: action.locationChanges.id,
              }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private locationService: LocationService
  ) {}
}
