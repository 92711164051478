import { createSelector } from '@ngrx/store';
import { SimulationState } from '../reducers';
import { selectSimulationFeature } from '../simulation.selectors';

export const selectAirConditioner = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.airConditioner.entities[id]
  );

const selectAirConditionerLoading = (id: number) =>
  createSelector(
    selectSimulationFeature,
    state => state.airConditioner.loading[id]
  );

export const selectAirConditionerViewSet = (id: number) =>
  createSelector(
    selectAirConditioner(id),
    selectAirConditionerLoading(id),
    (data, loading) => ({ data, loading })
  );

export const selectAirConditionerErrors = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.airConditioner.errors[id]
  );
