import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Community } from '@/data/community/models';
import { MatDialog } from '@angular/material/dialog';
import { CommunityService } from '@/data/community/services/community.service';

@Component({
  selector: 'app-community-subdivision-tab',
  templateUrl: './community-subdivision-tab.component.html',
  styleUrls: ['./community-subdivision-tab.component.scss'],
})
export class CommunitySubdivisionTabComponent implements OnInit, OnDestroy {
  community: Community;
  communityDisplayName: string;

  // rater community type specific attrs
  workerCompensationInsurance: boolean;

  private componentDestroyed$ = new Subject();

  constructor(
    public store: Store<AppState>,
    public injector: Injector,
    public dialog: MatDialog,
    private communityService: CommunityService
  ) {}

  ngOnInit(): void {
    this.store
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(community => {
        this.community = Object.assign(new Community(), community);
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
