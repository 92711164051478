import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { getInfoUser } from '@/state/selectors/info.selector';
import { AppState } from '@/state/reducers';
import { filter, map } from 'rxjs/operators';
import { AuthenticationService } from '@/core/services/authentication.service';

@Injectable({ providedIn: 'root' })
export class SuperuserGuard implements CanActivate {
  constructor(
    public router: Router,
    public store: Store<AppState>,
    public authenticationService: AuthenticationService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.authenticationService.isLoggedIn()) {
      // authorized so return true
      return false;
    }
    return this.store.select(getInfoUser).pipe(
      filter(currentUser => !!currentUser),
      map(currentUser => {
        if (currentUser.is_superuser) {
          return true;
        }
        this.router.navigate(['403'], {
          queryParams: { returnUrl: state.url },
        });
        return false;
      })
    );
  }
}
