import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { CityService } from '@/data/geographic/services/city.service';
import { objectValidator } from '@/core/validators';
import { GeocoderService } from '@/data/geocoder/services/geocoder.service';
import {
  Geocode,
  GeocodeBrokeredResponse,
  IGeocodeMatches,
} from '@/data/geocoder/models';
import {
  ConfirmedGeocodeResponse,
  GeocodeAddressInfo,
} from '@/modules/geocoder/components/confirm-geocode-response-dialog/confirm-geocode-response';
import { UIHelperService } from '@/shared/services/ui-helper.service';

export class AddressToGeocodeResponseDialogData {
  geocodeAddressInfo?: GeocodeAddressInfo;

  constructor(geocodeAddressInfo?: GeocodeAddressInfo) {
    this.geocodeAddressInfo = geocodeAddressInfo;
  }
}

@Component({
  selector: 'app-address-to-geocode-response-dialog',
  templateUrl: './address-to-geocode-response-dialog.component.html',
  styleUrls: ['./address-to-geocode-response-dialog.component.scss'],
})
export class AddressToGeocodeResponseDialogComponent implements OnInit {
  public addressForm: UntypedFormGroup;
  public geocode?: Geocode;
  public rawAddress?: string;
  public matchesLoading = false;

  constructor(
    public fb: UntypedFormBuilder,
    public cityService: CityService,
    public geocoderService: GeocoderService,
    public uiHelperService: UIHelperService,
    public dialogRef: MatDialogRef<AddressToGeocodeResponseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddressToGeocodeResponseDialogData
  ) {}

  ngOnInit() {
    this.prepareForm();
    if (this.data && this.data.geocodeAddressInfo) {
      this.addressForm.patchValue(
        {
          street_line1: this.data.geocodeAddressInfo.street_line1,
          street_line2: this.data.geocodeAddressInfo.street_line2,
          city_info: this.data.geocodeAddressInfo.city_info,
          zipcode: this.data.geocodeAddressInfo.zipcode,
        },
        { emitEvent: false, onlySelf: true }
      );
    }
  }

  prepareForm() {
    const controlsConfig = {
      street_line1: ['', Validators.required],
      street_line2: [''],
      city_info: ['', [Validators.required, objectValidator()]],
      zipcode: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(5)]),
      ],
    };
    this.addressForm = this.fb.group(controlsConfig);
  }

  confirmGeocodeAddress(geocode: Geocode) {
    this.geocode = geocode;
    this.rawAddress = `${this.addressForm.get('street_line1').value}`;
    if (this.addressForm.get('street_line2').value) {
      this.rawAddress += ` ${this.addressForm.get('street_line2').value}`;
    }
    this.rawAddress += `, ${this.addressForm.get('city_info').value.name}`;
    if (this.addressForm.get('city_info').value.country_info?.abbr === 'US') {
      this.rawAddress += `, ${
        this.addressForm.get('city_info').value.county_info.name
      }`;
      this.rawAddress += `, ${
        this.addressForm.get('city_info').value.county_info.state
      }`;
    } else {
      this.rawAddress += `, ${
        this.addressForm.get('city_info').value.country_info?.name
      }`;
    }
    this.rawAddress += `, ${this.addressForm.get('zipcode').value}`;
  }

  handleAdd() {
    this.addressForm.markAllAsTouched();
    if (!this.addressForm.valid) {
      return;
    }

    this.matchesLoading = true;
    this.geocoderService
      .matches({
        street_line1: this.addressForm.get('street_line1').value,
        street_line2: this.addressForm.get('street_line2').value || '',
        city: this.addressForm.get('city_info').value.id,
        zipcode: this.addressForm.get('zipcode').value,
      } as IGeocodeMatches)
      .subscribe(
        geocode => {
          this.matchesLoading = false;
          this.confirmGeocodeAddress(geocode);
        },
        error => {
          this.uiHelperService.handleUserRequestError(error);
          this.matchesLoading = false;
        }
      );
  }

  handleConfirmAddress(geocodeBrokeredResponse: GeocodeBrokeredResponse) {
    const dialogResult = new ConfirmedGeocodeResponse({
      geocode: this.geocode,
      geocodeBrokeredResponse: geocodeBrokeredResponse,
    });
    this.dialogRef.close(dialogResult);
  }

  handleUseAddressAsEntered() {
    const dialogResult = new ConfirmedGeocodeResponse({
      geocode: this.geocode,
      geocodeBrokeredResponse: null,
    });
    this.dialogRef.close(dialogResult);
  }

  /**
   * When user decided to re enter address after geocoding
   */
  handleCancel() {
    this.geocode = null;
    this.rawAddress = null;
  }

  /**
   * Close dialog and return empty result
   */
  handleClose() {
    this.dialogRef.close(null);
  }
}
