import { Component, Input } from '@angular/core';
import { selectAppliancesId } from '@/modules/simulation/state/simulation/selectors';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-laundry-appliances',
  templateUrl: './laundry-appliances.component.html',
  styleUrls: ['./laundry-appliances.component.scss'],
})
export class LaundryAppliancesComponent {
  @Input() expanded = false;
  entityId: number;
  componentDestroyed$ = new Subject();

  constructor(public store: Store) {}

  ngOnInit(): void {
    this.store
      .select(selectAppliancesId())
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(id => {
        this.entityId = id;
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
