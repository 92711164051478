<div class="row">
  <div class="col-3">
    <div class="row">
      <div class="col-12">
        <form [formGroup]="filterFromGroup" class="card">
          <h5 class="card-header">Filters</h5>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <mat-form-field class="w-100">
                  <mat-label>Search</mat-label>
                  <app-search-input formControlName="search" placeholder="Type to search something"></app-search-input>
                </mat-form-field>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="col-9">
    <div class="row">
      <div class="col-12">
        <div class="row" *ngIf="isLoading">
          <div class="col-12">
            <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
          </div>
        </div>
        <table mat-table
               [dataSource]="rows"
               class="table"
               matSort
               [matSortActive]="storedParams.toTableSort().active"
               [matSortDirection]="storedParams.toTableSort().direction"
               (matSortChange)="onSortChange($event)">

          <ng-container matColumnDef="customer_document">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Uploaded File</th>
            <td mat-cell *matCellDef="let row" class="text-center">
              <app-customer-document-display [customerDocument]="row.customer_document_info" displayType="filenameAsLinkAndSharedLink"></app-customer-document-display>
            </td>
          </ng-container>

          <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef>
              Workflow Status
              <fa-icon [icon]="['fas', 'info-circle']" [matTooltip]="'Status of full workflow based on analysis type'"></fa-icon>
            </th>
            <td mat-cell *matCellDef="let row" class="text-center">
              {{ ApexRequestDocumentStateLabelMapping[row.state] }}
            </td>
          </ng-container>

          <ng-container matColumnDef="simulation__status">
            <th mat-header-cell *matHeaderCellDef>
              Simulation Status
              <fa-icon [icon]="['fas', 'info-circle']" [matTooltip]="'Status of simulation result'"></fa-icon>
            </th>
            <td mat-cell *matCellDef="let row" class="text-center">
              {{ AnalysisStatusLabelMapping[row.analysis_info?.status] || "-" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="result">
            <th mat-header-cell *matHeaderCellDef>Result</th>
            <td mat-cell *matCellDef="let row" class="text-center">
              <a href="{{ row.asynchronous_process_document_info?.document }}" *ngIf="row.asynchronous_process_document_info?.document">
                Document
              </a>
            </td>
          </ng-container>

          <ng-container matColumnDef="home_status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Address</th>
            <td mat-cell *matCellDef="let row" class="text-center">
              <app-home-status-display [home_status]="row.home_status_info" displayType="addressAsLinkNewWindow" *ngIf="row.home_status_info"></app-home-status-display>
            </td>
          </ng-container>

          <ng-container matColumnDef="floorplan">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Floorplan</th>
            <td mat-cell *matCellDef="let row" class="text-center">
              <app-floorplan-display displayType="onlyNameAsLinkNewWindow" [floorplan]="row.floorplan_info"></app-floorplan-display>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let row" class="text-center">
              <a [routerLink]="['/', 'file-operation', 'document', row.asynchronous_process_document_info?.id]" target="_blank" [matTooltip]="'View detailed log in new window'" *ngIf="row.asynchronous_process_document_info">
                <fa-icon [icon]="['fas', 'external-link-alt']"></fa-icon>
              </a>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [length]="rowsCount"
                       [pageIndex]="$any(storedParams.page) - 1"
                       [pageSize]="storedParams.page_size"
                       (page)="onPaginateChange($event)"
                       aria-label="Select page">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
