import { createSelector } from '@ngrx/store';

import { AppState } from '@/state/reducers';
import { floorplanStateKey } from '../../reducers/floorplan/floorplan.reducers';

export const selectFloorplanState = (state: AppState) =>
  state[floorplanStateKey];

export const getFloorplan = createSelector(
  selectFloorplanState,
  store => store.detail
);

export const getFloorplanDocuments = createSelector(
  selectFloorplanState,
  store => store.documents
);

export const getFloorplanDocumentsCount = createSelector(
  selectFloorplanState,
  store => store.documentsCount
);

export const getFloorplanHomeStatusesCount = createSelector(
  selectFloorplanState,
  store => store.homeStatusesCount
);
