<div class="container-fluid" *ngIf="initialized">
  <div class="row">
    <div class="col-12">
      <div class="row mb-2">
        <div class="col-6">
          <img class="ml-2 mb-2" src="assets/apex_logo.svg" width="220" />
          <h4>
            <span *ngIf="!isLoading">{{ rowsCount | number }}</span>
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" *ngIf="isLoading"></fa-icon>
            Simulations
          </h4>
        </div>
        <div class="col-6 text-right">
          <button mat-icon-button [routerLink]="['/', 'simulations', 'config']" matTooltip="Config">
            <mat-icon>tune</mat-icon>
          </button>
          <app-permission-mat-button [permissionResponse]="createPermissionResponse" (click)="create($event);">
            Create New
          </app-permission-mat-button>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <hr>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <div class="row">
            <div class="col-12">
              <form [formGroup]="filterFromGroup" class="card">
                <h5 class="card-header">
                  Filters
                  <a href="#" class="small" (click)="resetFilters($event);">Reset filters</a>
                </h5>
                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>Search</mat-label>
                        <app-search-input formControlName="search"></app-search-input>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>Source Type</mat-label>
                        <mat-select formControlName="source_type">
                          <mat-option [value]="''">
                            Any
                          </mat-option>
                          <mat-option *ngFor="let item of SimulationSourceTypeLabelMapping | defaultOrderKeyValue " [value]="item.key">
                            {{ item.value }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>Company</mat-label>
                        <app-generic-model-autocomplete modelClassName="company" formControlName="company_info" [panelWidth]="640"></app-generic-model-autocomplete>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row" *ngIf="currentUser.is_superuser">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>Status</mat-label>
                        <mat-select formControlName="status">
                          <mat-option [value]="''">
                            Any
                          </mat-option>
                          <mat-option *ngFor="let item of SimulationStatusTypeLabelMapping | defaultOrderKeyValue" [value]="item.key">
                            {{ item.value }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>Simulation Version</mat-label>
                        <app-generic-mat-select formControlName="version" modelClassName="simulation_versions" hasEmptyChoice="true" emptyChoiceLabel="Any" [emptyChoiceValue]="''"></app-generic-mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>Attached to Home</mat-label>
                        <mat-select formControlName="attached_to_home">
                          <mat-option [value]="">Any</mat-option>
                          <mat-option [value]="true">Yes</mat-option>
                          <mat-option [value]="false">No</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>Residence Type</mat-label>
                        <mat-select formControlName="residence_type">
                          <mat-option [value]="">Any</mat-option>
                          <mat-option *ngFor="let item of simulationResidenceTypeLabelMapping  | keyvalue " [value]="item.key">
                            {{ item.value }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>Heater Type</mat-label>
                        <mat-select formControlName="heater_fuel_type">
                          <mat-option [value]="">None</mat-option>
                          <mat-option [value]="'electric'">Electric Heater</mat-option>
                          <mat-option [value]="'natural_gas'">Gas Heater</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>Water Heater Type</mat-label>
                        <mat-select formControlName="water_heater_type">
                          <mat-option [value]="">None</mat-option>
                          <mat-option [value]="'electric_tankless'">Electric Tankless
                          </mat-option>
                          <mat-option [value]="'electric_conventional'">Electric Conventional
                          </mat-option>
                          <mat-option [value]="'gas_tankless'">Gas Tankless</mat-option>
                          <mat-option [value]="'gas_conventional'">Gas Conventional
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>Air Conditioner</mat-label>
                        <mat-select formControlName="air_conditioner">
                          <mat-option value="">Any</mat-option>
                          <mat-option value="true">Yes</mat-option>
                          <mat-option value="false">No</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>ASHP</mat-label>
                        <mat-select formControlName="ashp">
                          <mat-option value="">Any</mat-option>
                          <mat-option value="false">Yes</mat-option>
                          <mat-option value="true">No</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>GSHP</mat-label>
                        <mat-select formControlName="gshp">
                          <mat-option value="">Any</mat-option>
                          <mat-option value="false">Yes</mat-option>
                          <mat-option value="true">No</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>Dehumidifier</mat-label>
                        <mat-select formControlName="dehumidifier">
                          <mat-option value="">Any</mat-option>
                          <mat-option value="false">Yes</mat-option>
                          <mat-option value="true">No</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>Has Photovoltaics</mat-label>
                        <mat-select formControlName="has_photovoltaics">
                          <mat-option value="">Any</mat-option>
                          <mat-option value="false">Yes</mat-option>
                          <mat-option value="true">No</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>Has Basement</mat-label>
                        <mat-select formControlName="has_basement">
                          <mat-option value="">Any</mat-option>
                          <mat-option value="true">Yes</mat-option>
                          <mat-option value="false">No</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>Crawlspace</mat-label>
                        <mat-select formControlName="crawl_space">
                          <mat-option value="">Any</mat-option>
                          <mat-option value="vented_crawl">Vented</mat-option>
                          <mat-option value="unvented_crawl">Un-Vented</mat-option>
                          <mat-option value="na">No</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>Has Slabs</mat-label>
                        <mat-select formControlName="has_slabs">
                          <mat-option value="">Any</mat-option>
                          <mat-option value="false">Yes</mat-option>
                          <mat-option value="true">No</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>Attic Type</mat-label>
                        <mat-select formControlName="attic_type">
                          <mat-option value="">Any</mat-option>
                          <mat-option value="vented">Vented</mat-option>
                          <mat-option value="unvented">Un-Vented</mat-option>
                          <mat-option value="na">No</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>Vaulted Ceilings</mat-label>
                        <mat-select formControlName="vaulted_ceilings">
                          <mat-option value="">Any</mat-option>
                          <mat-option value="true">Yes</mat-option>
                          <mat-option value="false">No</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>Number of Stories</mat-label>
                        <mat-select formControlName="num_stories">
                          <mat-option value=1>1</mat-option>
                          <mat-option value=2>2</mat-option>
                          <mat-option value=3>3+</mat-option>
                          <mat-option value="">Any</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-9">
          <div class="row" *ngIf="isLoading">
            <div class="col-12">
              <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
            </div>
          </div>
          <table mat-table
                 [dataSource]="rows"
                 class="table"
                 matSort
                 [matSortActive]="storedParams.toTableSort().active"
                 [matSortDirection]="storedParams.toTableSort().direction"
                 (matSortChange)="onSortChange($event)">

            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
              <td mat-cell *matCellDef="let row">
                <a [routerLink]="['/', 'simulations', 'edit', row.id]">
                  {{ row.name }}
                </a>
              </td>
            </ng-container>

            <ng-container matColumnDef="company__name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Company</th>
              <td mat-cell *matCellDef="let row">
                <a [routerLink]="['/', 'company', row.company__type, 'detail', row.company]">
                  {{ row.company__name }}
                </a>
              </td>
            </ng-container>

            <ng-container matColumnDef="bedroom_count">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Bedroom Count</th>
              <td mat-cell *matCellDef="let row">
                {{ row.bedroom_count }}
              </td>
            </ng-container>

            <ng-container matColumnDef="location__geocode__raw_street_line1">
              <th mat-header-cell *matHeaderCellDef>Address</th>
              <td mat-cell *matCellDef="let row">
                <app-address-display
                    [geocode]="row.location_geocode_info"
                    [geocodeBrokeredResponse]="row.location_geocode_response_info"
                    [display]="'geocodingIndicator'"
                ></app-address-display>
              </td>
            </ng-container>

            <ng-container matColumnDef="source_type">
              <th mat-header-cell *matHeaderCellDef>Source Type</th>
              <td mat-cell *matCellDef="let row">
                {{ SimulationSourceTypeLabelMapping[row.source_type] }}
              </td>
            </ng-container>

            <ng-container matColumnDef="created_date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Created At</th>
              <td mat-cell *matCellDef="let row">
                {{ row.created_date | date: 'MM/dd/yyyy HH:MM' || '-' }}
              </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>Actions</th>
              <td mat-cell *matCellDef="let row">
                <a [routerLink]="['/', 'simulations', 'edit', row.id]"  matTooltip="View in new window" class="mr-2">
                  <fa-icon [icon]="['fas', 'external-link-alt']"></fa-icon>
                </a>

                <app-permission-mat-button [permissionResponse]="permissions[row.id]['canDelete']" displayType="asLink" resolveType="onClick" (action)="delete($event, row);" matTooltip="Delete" class="mr-2">
                  <fa-icon [icon]="['fas', 'trash']"></fa-icon>
                </app-permission-mat-button>

                <app-permission-mat-button [permissionResponse]="permissions[row.id]['canDelete']" displayType="asLink"
                  resolveType="onClick" (action)="clone($event, row);" matTooltip="Clone" class="mr-2">
                  <fa-icon [icon]="['fas', 'copy']" matTooltip="Clone"></fa-icon>
                </app-permission-mat-button>

                <app-history-mat-button displayType="asLink" [objectId]="row.id" [modelClassName]="'simulation'">
                  <fa-icon [icon]="['fas', 'list']" matTooltip="History"></fa-icon>
                </app-history-mat-button>
              </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>

          <div class="d-flex flex-row">
            <div class="align-self-center">

            </div>
            <div class="d-flex flex-fill justify-content-end">
              <mat-paginator [length]="rowsCount"
                             [pageIndex]="$any(storedParams.page) - 1"
                             [pageSize]="storedParams.page_size"
                             (page)="onPaginateChange($event)"
                             aria-label="Select page">
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
