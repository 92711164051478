<mat-expansion-panel [expanded]="expanded">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <strong>{{title}}&nbsp;({{itemsCount}})</strong>
        </mat-panel-title>
        <mat-panel-description>
            <app-error-count [errorCount]="errorCount"></app-error-count>
        </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
        <div class="items-container pt-2">
            <ng-container *ngIf="itemsCount > 0">
                <ng-content></ng-content>
                <button *appRequiresEditPermission mat-button (click)="addItem()" [disabled]="addingNewItem">
                    <mat-icon *ngIf="!addingNewItem">add_circle</mat-icon>
                    <mat-spinner [diameter]="18" *ngIf="addingNewItem"></mat-spinner>
                </button>
            </ng-container>
            <ng-container *ngIf="itemsCount === 0">
                <div class="text-center w-100 p-5">
                    <p>No {{title}} found!</p>
                    <button *appRequiresEditPermission mat-button (click)="addItem()" [disabled]="addingNewItem">
                        <mat-icon  *ngIf="!addingNewItem">add_circle</mat-icon>
                        <mat-spinner [diameter]="18" *ngIf="addingNewItem"></mat-spinner>
                    </button>
                </div>
            </ng-container>
        </div>
    </ng-template>
</mat-expansion-panel>
