<h2 mat-dialog-title>
    Change Status
</h2>
<mat-dialog-content class="mat-typography">
    <form [formGroup]="formGroup" class="mb-2">
        <div class="row">
            <div class="col-12">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Status</mat-label>
                    <mat-select formControlName="new_status">
                        <mat-option *ngFor="let item of statusLabelMapping | keyvalue " [value]="item.key">
                            {{ item.value }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="formGroup.get('new_status').hasError('required')">
                        This field is required
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <mat-form-field appearance="outline" class="w-100 mb-2">
                    <mat-label>Annotation</mat-label>
                    <textarea matInput type="text" placeholder="Annotation" formControlName="status_annotation"></textarea>
                </mat-form-field>
            </div>
        </div>
    </form>
</mat-dialog-content>
<div class="row">
    <div class="col-12">
        <hr>
    </div>
</div>
<div class="row" *ngIf="!isLoading">
    <div class="col-12 text-center">
        <button mat-raised-button class="mr-2" color="accent" (click)="onConfirm($event)">
            Confirm
        </button>
        <button mat-raised-button (click)="onCancel($event)" cdkFocusInitial>
            Cancel
        </button>
    </div>
</div>
<div class="row" *ngIf="isLoading">
    <div class="col-12">
        <div class="d-flex justify-content-center">
            <mat-spinner [diameter]="50"></mat-spinner>
        </div>
    </div>
</div>