import { HeaterBackendDict } from '@/data/simulation/models/mechanicals/Heater';
import { createAction, props } from '@ngrx/store';
import { StateErrors } from '../shared/base.state';

export const loadHeaters = createAction(
  '[Heater] Load Heaters',
  props<{ heaters: HeaterBackendDict[] }>()
);

export const loadHeatersSuccess = createAction(
  '[Heater] Load Heaters Success',
  props<{
    heaters: HeaterBackendDict[];
    errors: StateErrors<HeaterBackendDict>;
  }>()
);

export const loadHeatersFailure = createAction(
  '[Heater] Load Heaters Failure',
  props<{ error: any }>()
);

export const updateHeater = createAction(
  '[Heater] Update Heater',
  props<{ heaterChanges: Partial<HeaterBackendDict> }>()
);

export const updateHeaterFailure = createAction(
  '[Heater API] Update Heater Failure',
  props<{ id: number }>()
);
