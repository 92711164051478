import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BaseDataService } from '@/core/services/base-data-service';
import { Observable } from 'rxjs';
import { WaterSystemBackendDict } from '../models/systems/WaterSystem';

@Injectable({ providedIn: 'root' })
export class WaterSystemService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/water_system/`;

  update(
    waterSystem: Partial<WaterSystemBackendDict>
  ): Observable<WaterSystemBackendDict> {
    return this.http.patch<WaterSystemBackendDict>(
      `${this.baseUrl}${waterSystem.id}/`,
      waterSystem
    );
  }
}
