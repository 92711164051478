import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { first, map, mergeMap, takeUntil } from 'rxjs/operators';
import {
  SimulationRequestParams,
  SimulationService,
} from '@/data/simulation/services/simulation.service';
import { SeedBackendDict, SimulationList } from '@/data/simulation/models';
import {
  SimulationBackendDict,
  SimulationResidenceTypeLabelMapping,
  SimulationSourceTypeLabelMapping,
  SimulationStatusTypeLabelMapping,
} from '@/data/simulation/models/simulation';
import { FormBuilder, FormGroup } from '@angular/forms';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { getInfoUser } from '@/state/selectors/info.selector';
import { User } from '@/data/core/models/user';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import * as _ from 'lodash';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { SimulationPermissionService } from '@/modules/simulation/services/simulation-permission-service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SeedCreateDialogComponent } from '@/modules/floorplan/components/seed-create-dialog/seed-create-dialog.component';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { toggleLoading } from '@/state/actions/app.actions';

@Component({
  selector: 'app-simulation-list-page',
  templateUrl: './simulation-list-page.component.html',
  styleUrls: ['./simulation-list-page.component.scss'],
})
export class SimulationListPageComponent implements OnInit, OnDestroy {
  protected readonly SimulationSourceTypeLabelMapping =
    SimulationSourceTypeLabelMapping;
  protected readonly SimulationStatusTypeLabelMapping =
    SimulationStatusTypeLabelMapping;
  protected readonly simulationResidenceTypeLabelMapping =
    SimulationResidenceTypeLabelMapping;

  public displayedColumns: string[] = [
    'name',
    'company__name',
    'bedroom_count',
    'location__geocode__raw_street_line1',
    'source_type',
    'created_date',
    'actions',
  ];

  public initialized = false;
  public rows: SimulationList[];
  public rowsCount: number;
  public permissions: {
    [key: string]: {
      canEdit: Observable<ObjectPermissionResponse>;
      canDelete: Observable<ObjectPermissionResponse>;
    };
  } = {};
  public createPermissionResponse: Observable<ObjectPermissionResponse>;

  public isLoading = true;

  public currentUser: User;
  public filterFromGroup: FormGroup;

  public defaultParams: SimulationRequestParams = new SimulationRequestParams(
    1,
    '',
    '-created_date'
  );
  public storedParams: SimulationRequestParams = Object.assign(
    new SimulationRequestParams(),
    this.defaultParams
  );

  private listSubscription$: Subscription;
  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private dialog: MatDialog,
    private simulationService: SimulationService,
    private simulationPermissionService: SimulationPermissionService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private uiHelperService: UIHelperService
  ) {}

  ngOnInit() {
    this.setupFilterForm();

    this.activatedRoute.params
      .pipe(
        takeUntil(this.componentDestroyed$),
        mergeMap(params => {
          this.rows = [];
          this.rowsCount = 0;

          return forkJoin({
            queryParams: this.activatedRoute.queryParams.pipe(first()),
            currentUser: this.store.select(getInfoUser).pipe(first()),
          }).pipe(
            map(({ queryParams, currentUser }, index) => {
              return { params, queryParams, currentUser };
            })
          );
        })
      )
      .subscribe(({ params, queryParams, currentUser }) => {
        this.storedParams.page_size =
          this.uiHelperService.getUISettings().rowsPerPage;
        this.storedParams.assignQueryParams(queryParams);
        this.currentUser = currentUser;

        this.initialized = true;

        this.createPermissionResponse =
          this.simulationPermissionService.canCreate();

        this.hydrateForm();
        this.list();
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
  setupFilterForm() {
    this.filterFromGroup = this.fb.group({
      search: [null],
      source_type: [null],
      status: [null],
      company_info: [null],
      version: [null],
      residence_type: [null],
      heater_fuel_type: [null],
      water_heater_type: [null],
      air_conditioner: [null],
      ashp: [null],
      gshp: [null],
      dehumidifier: [null],
      has_basement: [null],
      crawl_space: [null],
      has_slabs: [null],
      attic_type: [null],
      vaulted_ceilings: [null],
      num_stories: [null],
      has_photovoltaics: [null],
      attached_to_home: [null],
    });

    this.filterFromGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        const params = _.merge({}, this.defaultParams, values);
        delete params.page_size;
        delete params.ordering;

        if (params.company_info) {
          params.company = params.company_info.id;
        } else {
          params.company = null;
        }
        delete params.company_info;

        this.storedParams = Object.assign(this.storedParams, params);
        this.list();
      });
  }

  list() {
    this.isLoading = true;

    // populate params to query string
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.storedParams.toQueryParams(),
      replaceUrl: true,
    });

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }

    this.listSubscription$ = this.simulationService
      .list(this.storedParams)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(data => {
        this.rows = data.results;
        this.rowsCount = data.count;

        this.rows.forEach(row => {
          this.permissions[row.id] = {
            canEdit: this.simulationPermissionService.canEdit({
              simulation: row,
            }),
            canDelete: this.simulationPermissionService.canDelete({
              simulation: row,
            }),
          };
        });

        this.isLoading = false;
      });
  }

  hydrateForm() {
    this.filterFromGroup.patchValue(this.storedParams, {
      emitEvent: false,
      onlySelf: true,
    });
  }

  resetFilters($event: MouseEvent) {
    $event.preventDefault();
    this.filterFromGroup.reset();
  }

  onPaginateChange($event: PageEvent) {
    this.uiHelperService.getUISettings().rowsPerPage = $event.pageSize;
    this.storedParams.page_size = $event.pageSize;
    this.storedParams.page = $event.pageIndex + 1;
    this.list();
  }

  onSortChange($event: Sort) {
    this.storedParams.ordering = `${$event.direction === 'desc' ? '-' : ''}${
      $event.active
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  create($event: MouseEvent) {
    $event.preventDefault();

    const dialogRef: MatDialogRef<
      SeedCreateDialogComponent,
      SeedBackendDict | SimulationBackendDict
    > = this.dialog.open(SeedCreateDialogComponent, {
      width: '40%',
      data: {},
    });
    dialogRef
      .afterClosed()
      .subscribe((instance: SeedBackendDict | SimulationBackendDict) => {
        if (!instance) {
          return;
        }
        if ((instance as SeedBackendDict).simulation) {
          this.router.navigate([
            '/',
            'simulations',
            'edit',
            (instance as SeedBackendDict).simulation,
          ]);
        } else {
          this.router.navigate(['/', 'simulations', 'edit', instance.id]);
        }
      });
  }

  delete($event: MouseEvent, simulation: SimulationList) {
    $event.preventDefault();

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '45%',
      data: {
        title: `Delete simulation ?`,
        content: `Do you want to delete simulation "${simulation.name}"?`,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      this.store.dispatch(toggleLoading({ payload: true }));
      this.simulationService
        .delete(simulation.id)
        .pipe(takeUntil(this.componentDestroyed$), first())
        .subscribe(() => {
          this.list();
          this.store.dispatch(toggleLoading({ payload: false }));
          this.uiHelperService.openSnackBar('Record Successfully Deleted');
        });
    });
  }

  clone($event: MouseEvent, simulation: SimulationList) {
    $event.preventDefault();
    this.uiHelperService.openSnackBar('Cloning simulation...');
    this.store.dispatch(toggleLoading({ payload: true }));
    const simulationId = simulation.id;
    this.simulationService
      .clone(simulationId, simulation.name + ' - copy')
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(
        simulation => {
          this.uiHelperService.openSnackBar('Cloned successfully');
          this.storedParams.page_size =
            this.uiHelperService.getUISettings().rowsPerPage;
          this.storedParams.page = 1;
          this.list();

          this.store.dispatch(toggleLoading({ payload: false }));
        },
        error => {
          this.uiHelperService.openSnackBar('Error cloning simulation');
          this.store.dispatch(toggleLoading({ payload: false }));
        }
      );
  }
}
