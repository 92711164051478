import { createSelector } from '@ngrx/store';
import { SimulationState } from '../reducers';
import { selectSimulationFeature } from '../simulation.selectors';
import { PhotovoltaicBackendDict } from '@/data/simulation/models/generators/Photovoltaic';

export const selectPhotovoltaic = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.photovoltaic.entities[id]
  );
const selectPhotovoltaicLoadingStatus = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.photovoltaic.loading[id]
  );

export const selectPhotovoltaicViewSet = (id: number) =>
  createSelector(
    selectPhotovoltaic(id),
    selectPhotovoltaicLoadingStatus(id),
    (photovoltaic, loadingStatus) => ({
      data: photovoltaic,
      loading: loadingStatus,
    })
  );

export const selectPhotovoltaicName = (id: number) =>
  createSelector(
    selectPhotovoltaic(id),
    (photovoltaic: PhotovoltaicBackendDict) => photovoltaic.name
  );

export const selectPhotovoltaicErrorsCount = (id: number) =>
  createSelector(selectSimulationFeature, (state: SimulationState) => ({
    errorCount: state.photovoltaic.errors[id].errorCount,
    warningCount: state.photovoltaic.errors[id].warningCount,
  }));
