import { Action, createReducer, on } from '@ngrx/store';
import { Invoice, InvoiceItemGroup } from '@/data/invoicing/models';
import {
  deleteInvoiceInvoiceItemGroupAction,
  deleteInvoiceNote,
  loadInvoice,
  loadInvoiceInvoiceItemGroupsAction,
  loadInvoiceInvoiceItemGroupsCountAction,
  loadInvoiceNotes,
  loadInvoiceNotesCount,
  resetInvoice,
  updateInvoiceNote,
} from '@/state/actions/invoicing/invoice.actions';
import { Annotation } from '@/data/annotation/models';

export const invoiceStateKey = 'invoice';

export interface InvoiceState {
  detail: Invoice;
  invoiceItemGroups: InvoiceItemGroup[];
  invoiceItemGroupsCount: number;
  notes: Annotation[];
  notesCount: number;
}

export const InvoiceInitialState: InvoiceState = {
  detail: null,
  invoiceItemGroups: [],
  invoiceItemGroupsCount: -1,
  notes: [],
  notesCount: 0,
};

const InvoiceReducer = createReducer(
  InvoiceInitialState,
  on(resetInvoice, (state, action) => ({
    ...InvoiceInitialState,
  })),
  on(loadInvoice, (state, { payload }) => ({
    ...state,
    detail: payload,
  })),
  // Products
  on(loadInvoiceInvoiceItemGroupsAction, (state, { payload }) => ({
    ...state,
    newInvoiceItemGroups: payload,
  })),
  on(loadInvoiceInvoiceItemGroupsCountAction, (state, { payload }) => ({
    ...state,
    invoiceItemGroupsCount: payload,
  })),
  on(deleteInvoiceInvoiceItemGroupAction, (state, { payload }) => {
    const invoiceItemGroupIndex = state.invoiceItemGroups.findIndex(
      (invoiceItemGroup: InvoiceItemGroup) => invoiceItemGroup.id === payload.id
    );
    const newInvoiceItemGroups = Object.assign([], state.invoiceItemGroups);
    newInvoiceItemGroups.splice(invoiceItemGroupIndex, 1);
    return {
      ...state,
      newInvoiceItemGroups: newInvoiceItemGroups,
      invoiceItemGroupsCount: state.invoiceItemGroupsCount - 1,
    };
  }),

  // Notes
  on(loadInvoiceNotes, (state, { payload }) => ({
    ...state,
    notes: payload,
  })),
  on(loadInvoiceNotesCount, (state, { payload }) => ({
    ...state,
    notesCount: payload,
  })),
  on(updateInvoiceNote, (state, { payload }) => {
    const noteIndex = state.notes.findIndex(note => note.id === payload.id);
    const newNotes = Object.assign([], state.notes, { [noteIndex]: payload });
    return { ...state, notes: newNotes };
  }),
  on(deleteInvoiceNote, (state, { payload }) => {
    const noteIndex = state.notes.findIndex(note => note.id === payload.id);
    const newNotes = Object.assign([], state.notes);
    newNotes.splice(noteIndex, 1);
    return { ...state, notes: newNotes, notesCount: state.notesCount - 1 };
  })
);

export function reducer(state: InvoiceState | undefined, action: Action) {
  return InvoiceReducer(state, action);
}
