import { PracticeItemInfo } from '@/data/customer-hirl/models/practice-item';
import { ProductInfo } from '@/data/product/models';
import { CustomerDocumentInfo } from '@/data/filehandling/models';

export class ProductPracticeItem {
  id?: number;
  item?: number;
  item_info?: PracticeItemInfo;
  product?: number;
  product_info?: ProductInfo;
  points?: string;
  customer_document?: number;
  customer_document_info?: CustomerDocumentInfo;
  used_to_earn_points?: string;
  product_features?: string;
  excel_designer_report?: string;
  display_on_certificate?: string;
  is_active?: boolean;
  rc_comments?: string;
}
