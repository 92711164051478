import { DatePipe } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  HIRLProjectRegistration,
  HIRLProjectRegistrationInfo,
  HIRLProjectRegistrationState,
  HIRLProjectRegistrationStateLabelMapping,
} from '@/data/customer-hirl/models';

export enum HIRLProjectRegistrationStateDisplay {
  badge = 'badge',
  flat = 'flat',
}

@Component({
  selector: 'app-hirl-project-registration-state',
  templateUrl: './hirl-project-registration-state.component.html',
  styleUrls: ['./hirl-project-registration-state.component.scss'],
})
export class HIRLProjectRegistrationStateComponent implements OnInit {
  @Input() projectRegistration:
    | HIRLProjectRegistration
    | HIRLProjectRegistrationInfo;
  @Input() display: HIRLProjectRegistrationStateDisplay =
    HIRLProjectRegistrationStateDisplay.flat;

  @ViewChild('stateDescription') public stateDescriptionRef: TemplateRef<any>;
  public stateLabelMapping = HIRLProjectRegistrationStateLabelMapping;
  public registrationState = HIRLProjectRegistrationState;
  public stateDisplay = HIRLProjectRegistrationStateDisplay;
  public stateDescriptionMap;

  constructor(private datePipe: DatePipe) {}

  ngOnInit(): void {
    this.stateDescriptionMap = {
      new: 'Waiting for approval by Home Innovation Research Labs. Verifier can modify/delete project before approval',
      pending: `Project was approved by Home Innovation Research Labs on ${this.datePipe.transform(
        this.projectRegistration.state_changed_at,
        'MM/dd/yyyy'
      )} and now waiting for more details`,
      active: `Project was approved by Home Innovation Research Labs on ${this.datePipe.transform(
        this.projectRegistration.state_changed_at,
        'MM/dd/yyyy'
      )}`,
      rejected: `Project was approved by Home Innovation Research Labs on ${this.datePipe.transform(
        this.projectRegistration.state_changed_at,
        'MM/dd/yyyy'
      )}. Reason: ${this.projectRegistration.state_change_reason}`,
      abandon: `Project was abandoned by Home Innovation Research Labs on ${this.datePipe.transform(
        this.projectRegistration.state_changed_at,
        'MM/dd/yyyy'
      )}. Reason: ${this.projectRegistration.state_change_reason}`,
    };
  }
}
