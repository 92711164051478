import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { HIRLProjectService } from '@/data/customer-hirl/services/hirl-projects.service';
import { QaStatusService } from '@/data/qa/services/qa-status.service';
import { HIRLProjectRegistrationPermissionService } from '@/modules/customer-hirl/services/hirl-project-registration-permission-service';
import { Observable, of } from 'rxjs';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';

@Injectable({
  providedIn: 'root',
})
export class HIRLRPCUpdaterRequestServicePermissionService {
  constructor(
    public store: Store<AppState>,
    public hirlProjectService: HIRLProjectService,
    public qaStatusService: QaStatusService,
    public hirlProjectRegistrationService: HIRLProjectRegistrationPermissionService
  ) {}

  canCreate(): Observable<ObjectPermissionResponse> {
    return of(new ObjectPermissionResponse(true));
  }
}
