import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ServerResponse } from '@/core/schemes/server-response';
import { City } from '@/data/geographic/models/city';
import { ListRequestParams } from '@/core/schemes/request-params';
import { BaseDataService } from '@/core/services/base-data-service';
import { Observable } from 'rxjs';

export class CityRequestParams extends ListRequestParams {
  is_attached?: string;
}

@Injectable({
  providedIn: 'root',
})
export class CityService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/cities/`;

  list(params?: CityRequestParams) {
    return this.http.get<ServerResponse<City>>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }

  retrieve(cityId: string): Observable<City> {
    return this.http.get<City>(`${this.baseUrl}${cityId}`);
  }
}
