import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZendeskFeedbackComponent } from './components/zendesk-feedback/zendesk-feedback.component';
import { SharedModule } from '@/shared/shared.module';

@NgModule({
  declarations: [ZendeskFeedbackComponent],
  exports: [ZendeskFeedbackComponent],
  imports: [CommonModule, SharedModule],
})
export class ZendeskModule {}
