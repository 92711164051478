import { UserInfo } from '@/data/core/models/user';
import { RaterRoleInfo } from '@/data/core/models/rater-role';

export class UserRaterRole {
  id?: number;
  user?: number;
  user_info: UserInfo;
  raterrole: number;
  raterrole_info: RaterRoleInfo;
}
