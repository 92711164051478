import { addMechanicalVentilation } from '@/modules/simulation/state/simulation/actions';
import { selectMechanicalVentilationIds } from '@/modules/simulation/state/simulation/selectors';
import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-mechanical-ventilations',
  templateUrl: './mechanical-ventilations.component.html',
  styleUrls: ['./mechanical-ventilations.component.scss'],
})
export class MechanicalVentilationsComponent implements OnInit {
  @Input() expanded = false;
  componentDestroyed$ = new Subject();
  mechanicalVentilationIds: number[] = [];

  constructor(public store: Store) {}

  ngOnInit(): void {
    this.store
      .select(selectMechanicalVentilationIds())
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(ids => {
        this.mechanicalVentilationIds = ids;
      });
  }

  addMechanicalVentilation() {
    this.store.dispatch(addMechanicalVentilation());
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
