<div class="container-fluid" *ngIf="initialized">
  <div class="row">
    <div class="col-12">
      <div class="row mb-2">
        <div class="col-6">
          <h3>
            <span *ngIf="!tableComponent?.entitiesIsLoading">{{ tableComponent?.entitiesCount | number }}</span>
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" *ngIf="tableComponent?.entitiesIsLoading"></fa-icon>
            Sustainability Records
          </h3>
        </div>
        <div class="col-6 text-right">
          <button mat-raised-button color="accent" (click)="downloadReport($event)"
                  [disabled]="!canExportPermission?.hasPermission" [matTooltip]="canExportPermission?.message">
            Export
          </button>
        </div>
      </div>

      <div class="row" *ngIf="!aggregateESGReportIsLoading else aggregateESGReportLoading">
        <div class="col-12 text-right">
          <b>Average ERI:</b> {{ aggregateESGReport.avg_eri_score | number: '1.0-0' }}
          <span class="text-muted"> | </span>
          <b>Total CO2:</b> {{ aggregateESGReport.total_co2 | lbsToTonnes | number: '1.2-2' }}
          <span class="text-muted"> | </span>
          <b>Total CO2 Savings:</b> {{ aggregateESGReport.total_co2_savings | lbsToTonnes | number: '1.2-2' }}
        </div>
      </div>

      <ng-template #aggregateESGReportLoading>
        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-left">
              <mat-spinner [diameter]="20"></mat-spinner>
            </div>
          </div>
        </div>
      </ng-template>

      <div class="row">
        <div class="col-12">
          <hr>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <app-psr-filter-form (formChange)="onFilterFormChanged($event)" (isReady)="onFilterFormReady()" [defaultParams]="this.defaultParams" [storedParams]="this.storedParams"></app-psr-filter-form>
        </div>
        <div class="col-9">
          <app-generic-table [dataSource]="getDataSource" [storedParams]="storedParams" [displayedColumns]="displayedColumns" [routerParamsToQueryString]="true" (listChanged)="onListChanged()" (listChangeStarted)="onListChangeStarted()" #tableComponent>
            <ng-container matSort>
              <ng-container matColumnDef="home__street_line1">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Address</th>
                <td mat-cell *matCellDef="let row">
                  <app-home-display [home]="row.home_info" displayType="addressAsLink"></app-home-display>
                </td>
              </ng-container>

              <ng-container matColumnDef="home__subdivision__name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Subdivision/MF Development</th>
                <td mat-cell *matCellDef="let row">
                  <app-subdivision-display [subdivision]="row?.home_info?.subdivision_info" displayType="onlyNameAsLink"></app-subdivision-display>
                </td>
              </ng-container>

              <ng-container matColumnDef="floorplan__name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Floorplan</th>
                <td mat-cell *matCellDef="let row">
                  <a [routerLink]="['/', 'floorplans', row.floorplan]" target="_blank" *ngIf="currentUser.company_info?.company_type !== CompanyType.builder">
                    {{ row.floorplan_name }}
                  </a>
                  <ng-container *ngIf="currentUser.company_info?.company_type === CompanyType.builder">
                    {{ row.floorplan_name }}
                  </ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="eep_program__name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Program</th>
                <td mat-cell *matCellDef="let row">
                  <a [routerLink]="['/', 'eep_program', row.eep_program]" target="_blank">
                    {{ row.eep_program_name }}
                  </a>
                </td>
              </ng-container>

              <ng-container matColumnDef="state">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Project Status</th>
                <td mat-cell *matCellDef="let row">
                  <a [routerLink]="['/', 'home', row.home]" target="_blank">
                    {{ tableStateLabelMapping[row.state] }}
                  </a>
                </td>
              </ng-container>

              <ng-container matColumnDef="eri_score">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>ERI</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.eri_score | number: '1.0-0' }}
                </td>
              </ng-container>

              <ng-container matColumnDef="co2">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>CO2 (mt/yr)</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.co2 | lbsToTonnes | number: '1.3-3' }}
                </td>
              </ng-container>

              <ng-container matColumnDef="co2_savings">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>CO2 Savings (mt/yr)</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.co2_savings | lbsToTonnes | number: '1.3-3' }}
                </td>
              </ng-container>
            </ng-container>
          </app-generic-table>
        </div>
      </div>
    </div>
  </div>
</div>
