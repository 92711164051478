import { EEPProgramHomeStatusInfo, HomeInfo } from '@/data/home/models';
import { EEPProgramInfo } from '@/data/eep-program/models';
import { AnnotationInfo } from '@/data/annotation/models';

export enum QARequirementType {
  fileQA = 'file',
  fieldQA = 'field',
  programReview = 'program_review',
  roughInspection = 'rough_inspection_qa',
  roughInspectionVirtualAudit = 'rough_inspection_virtual_audit',
  finalInspection = 'final_inspection_qa',
  desktopAudit = 'desktop_audit',
  finalInspectionVirtualAudit = 'final_inspection_virtual_audit',
}

export const QARequirementTypeLabelMapping: Record<QARequirementType, string> =
  {
    [QARequirementType.fileQA]: 'File QA',
    [QARequirementType.fieldQA]: 'Field QA',
    [QARequirementType.programReview]: 'Program Review',
    [QARequirementType.roughInspection]: 'Rough Inspection',
    [QARequirementType.roughInspectionVirtualAudit]:
      'Rough Inspection Virtual Audit',
    [QARequirementType.finalInspection]: 'Final Inspection',
    [QARequirementType.desktopAudit]: 'Desktop Audit',
    [QARequirementType.finalInspectionVirtualAudit]: 'Virtual Audit',
  };

export enum HIRLPriority {
  normal = 'normal',
  high = 'high',
}

export const HIRLPriorityLabelMapping: Record<HIRLPriority, string> = {
  [HIRLPriority.normal]: 'Normal',
  [HIRLPriority.high]: 'High',
};

export enum QAState {
  received = 'received',
  in_progress = 'in_progress',
  correction_required = 'correction_required',
  correction_received = 'correction_received',
  complete = 'complete',
}

export const QAStatusStateLabelMapping: Record<QAState, string> = {
  [QAState.received]: 'Received',
  [QAState.in_progress]: 'In Progress',
  [QAState.correction_required]: 'Correction Required',
  [QAState.correction_received]: 'Correction Received',
  [QAState.complete]: 'Complete',
};

export enum QAHIRLCertificationLevelAwarded {
  emerald = 'emerald',
  gold = 'gold',
  silver = 'silver',
  bronze = 'bronze',
  certified = 'certified',
  conforming = 'conforming',
  one_star = 'one_star',
  two_stars = 'two_stars',
  three_stars = 'three_stars',
  four_stars = 'four_stars',
}

export const QAHIRLCertificationLevelAwardedLabelMapping: Record<
  QAHIRLCertificationLevelAwarded,
  string
> = {
  [QAHIRLCertificationLevelAwarded.emerald]: 'Emerald',
  [QAHIRLCertificationLevelAwarded.gold]: 'Gold',
  [QAHIRLCertificationLevelAwarded.silver]: 'Silver',
  [QAHIRLCertificationLevelAwarded.bronze]: 'Bronze',
  [QAHIRLCertificationLevelAwarded.certified]: 'Certified',
  [QAHIRLCertificationLevelAwarded.conforming]: 'Conforming',
  [QAHIRLCertificationLevelAwarded.one_star]: 'One Star',
  [QAHIRLCertificationLevelAwarded.two_stars]: 'Two Stars',
  [QAHIRLCertificationLevelAwarded.three_stars]: 'Three Stars',
  [QAHIRLCertificationLevelAwarded.four_stars]: 'Four Stars',
};

export class QARequirementInfo {
  id?: number;
  type?: QARequirementType;
}

export class QaStatusInfo {
  id?: number;

  home_status?: number;
  home_status_info?: EEPProgramHomeStatusInfo;
  requirement?: number;
  requirement_info?: QARequirementInfo;
}

export class QaStatus {
  id?: number;

  home_status?: number;
  home_status_info?: EEPProgramHomeStatusInfo;

  requirement?: number;
  requirement_info?: QARequirementInfo;
}

export class QANote {
  id?: number;
  qa_status: number;
  qa_status_info?: number;
  user?: number;
  user_info?: number;

  note?: string;

  last_update?: string;
  created_on?: string;
}

export class QANoteList {
  id?: number;
  user_name: string;
  observation_names?: string[];
  note?: string;
  last_update?: string;
}

export class HIRLQAStatusListEEPProgramHomeStatusInfo {
  id: number;
  state: string;
  eep_program: number;
  eep_program_info: EEPProgramInfo;
  home: number;
  home_info: HomeInfo;
}

export class HIRLQAStatusList {
  id?: number;
  state?: string;
  home_status?: number;
  home_status_info?: HIRLQAStatusListEEPProgramHomeStatusInfo;
  requirement?: number;
  requirement_info?: QARequirementInfo;
  verifier_id?: number;
  verifier_name?: string;
  state_cycle_time_total_duration?: number;
  hirl_priority?: HIRLPriority;
  home_status_notes?: AnnotationInfo[];
}

export class CreateQANoteForMultipleQAStatuses {
  qa_statuses: number[];
  note: string;
}

export class HIRLQAStatusUserFilterBadgesCount {
  all_projects: number;
  my_projects: number;
  rough_qa_projects: number;
  final_qa_projects: number;
  desktop_audit_projects: number;
  qa_correction_received_projects: number;
  qa_correction_required_projects: number;
}
