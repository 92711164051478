<div class="container" *ngIf="entityForm">
    <div class="row">
        <div class="col-12">
            <app-assembly-layer-materials
                [materialsForm]="entityForm.controls.materials">
            </app-assembly-layer-materials>
        </div>
     </div>
     <div class="row mt-2">
        <div class="col-12">
            <div class="d-flex justify-content-between">
                <h6>Stud/Cavity Info</h6>
                <mat-slide-toggle
                    [checked]="entityForm.controls.stud_type.value"
                    (change)="onStudRequiredChange($event)"></mat-slide-toggle>
            </div>
           <app-assembly-layer-stud [defaultThickness]="materialsThickness" [entityForm]="entityForm" *ngIf="entityForm.controls.stud_type.value"></app-assembly-layer-stud>
        </div>
    </div>
</div>
