import { PracticeChapterInfo } from '@/data/customer-hirl/models/practice-chapter';

export class PracticeSection {
  id?: number;
  title?: string;
  chapter?: number;
  chapter_info?: PracticeChapterInfo;
}

export class PracticeSectionInfo {
  id?: number;
  title?: string;
  chapter?: number;
  chapter_info?: PracticeChapterInfo;
}
