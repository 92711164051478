import { ModelFormGroup } from '@/data/simulation/models/ModelForm';
import {
  createNaturalVentilationForm,
  FIELD_LABELS,
  NaturalVentilationBackendDict,
  WindowCoolingMethodLabels,
} from '@/data/simulation/models/systems/NaturalVentilation';
import { updateNaturalVentilation } from '@/modules/simulation/state/natural-ventilation/actions';
import { selectNaturalVentilationViewSet } from '@/modules/simulation/state/natural-ventilation/selectors';
import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  AuxEnergyUnitLabels,
  VentilationRateUnitLabels,
} from '@/data/simulation/models/systems/MechanicalVentilation';

@Component({
  selector: 'app-natural-ventilation',
  templateUrl: './natural-ventilation.component.html',
  styleUrls: ['./natural-ventilation.component.scss'],
})
export class NaturalVentilationComponent implements OnInit {
  @Input() expanded = false;
  @Input() entityId: number;
  fieldLabels = FIELD_LABELS;

  ventilationRateLabels = VentilationRateUnitLabels;
  auxEnergyUnitLabels = AuxEnergyUnitLabels;

  form: ModelFormGroup;
  loading: boolean;
  entity: NaturalVentilationBackendDict;
  componentDestroyed$: Subject<boolean> = new Subject();
  typeLabels = WindowCoolingMethodLabels;

  constructor(public store: Store) {}

  ngOnInit(): void {
    this.store
      .select(selectNaturalVentilationViewSet(this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(({ data, loading }) => {
        this.entity = data;
        this.loading = loading;
        if (loading) return;

        if (this.form) {
          this.form.patchValue(data);
          return;
        }
        this.form = createNaturalVentilationForm(data);
      });
  }

  onSave(naturalVentilationChanges: Partial<NaturalVentilationBackendDict>) {
    this.store.dispatch(
      updateNaturalVentilation({ id: this.entityId, naturalVentilationChanges })
    );
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
