import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomerHIRLCertificateLookupList } from '@/data/home/models/eep_program_home_status';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { first, takeUntil } from 'rxjs/operators';
import {
  EEPProgramHomeStatusRequestParams,
  EEPProgramHomeStatusService,
} from '@/data/home/services/eep_program_home_status.service';
import { QAHIRLCertificationLevelAwardedLabelMapping } from '@/data/qa/models';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import * as _ from 'lodash';

@Component({
  selector: 'app-eep-program-certificate-lookup-result',
  templateUrl: './eep-program-certificate-lookup-result.component.html',
  styleUrls: ['./eep-program-certificate-lookup-result.component.scss'],
})
export class EEPProgramCertificateLookupResultComponent
  implements OnInit, OnDestroy
{
  protected readonly QAHIRLCertificationLevelAwardedLabelMapping =
    QAHIRLCertificationLevelAwardedLabelMapping;
  public displayedColumns: string[] = [
    'home__home__street_line1',
    'home__subdivision__name',
    'customer_hirl_project__registration__builder_organization__name',
    'certification_date',
    'certification_level',
    'certification_path',
    'certification_number',
    'actions',
  ];

  constructor(
    public store: Store<AppState>,
    public eepProgramHomeStatusService: EEPProgramHomeStatusService,
    public router: Router,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private uiHelperService: UIHelperService
  ) {}

  public defaultParams: EEPProgramHomeStatusRequestParams =
    new EEPProgramHomeStatusRequestParams(1, '', '-id');

  public filterFromGroup: FormGroup;

  public storedParams: EEPProgramHomeStatusRequestParams;

  public isLoading = true;
  public rows: CustomerHIRLCertificateLookupList[] = [];
  public rowsCount = 0;

  private listSubscription$: Subscription;
  private componentDestroyed$ = new Subject();

  ngOnInit() {
    this.setupFilterForm();
    this.storedParams = Object.assign(
      new EEPProgramHomeStatusRequestParams(),
      this.defaultParams
    );

    forkJoin({
      queryParams: this.activatedRoute.queryParams.pipe(first()),
    }).subscribe(({ queryParams }) => {
      this.storedParams = _.cloneDeep(this.defaultParams);
      this.storedParams.page_size =
        this.uiHelperService.getUISettings().rowsPerPage;
      this.storedParams.assignQueryParams(queryParams);
      this.hydrateForm();
      this.list();
    });
  }

  setupFilterForm() {
    this.filterFromGroup = this.fb.group({
      search: [null, [Validators.required, Validators.minLength(3)]],
    });

    this.filterFromGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        const params = _.merge({}, this.defaultParams, values);
        delete params.page_size;
        delete params.ordering;

        this.storedParams = _.merge(this.storedParams, params);
        this.list();
      });
  }

  hydrateForm() {
    this.filterFromGroup.patchValue(this.storedParams, {
      emitEvent: false,
      onlySelf: true,
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }
  }

  list() {
    if (!this.storedParams.search || this.storedParams.search.length < 3) {
      return;
    }

    this.isLoading = true;

    // populate params to query string
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.storedParams.toQueryParams(),
      replaceUrl: true,
    });

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }

    this.listSubscription$ = this.eepProgramHomeStatusService
      .customer_hirl_certificate_lookup_list(this.storedParams)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(data => {
        this.rows = data.results;
        this.rowsCount = data.count;
        this.isLoading = false;
      });
  }

  resetFilters($event: MouseEvent) {
    $event.preventDefault();
    this.filterFromGroup.reset();
  }

  onSort({ sorts }) {
    this.storedParams.ordering = `${sorts[0].dir === 'desc' ? '-' : ''}${
      sorts[0].prop
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  onPaginateChange($event: PageEvent) {
    this.uiHelperService.getUISettings().rowsPerPage = $event.pageSize;
    this.storedParams.page_size = $event.pageSize;
    this.storedParams.page = $event.pageIndex + 1;
    this.list();
  }

  onSortChange($event: Sort) {
    this.storedParams.ordering = `${$event.direction === 'desc' ? '-' : ''}${
      $event.active
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  onFooterPage(event) {
    this.storedParams.page = event.page;
    this.list();
  }
}
