import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BaseDataService } from '@/core/services/base-data-service';
import { Observable } from 'rxjs';
import { STATE_BACKEND_NAME } from '@/modules/simulation/state.registry';
import { ListRequestParams } from '@/core/schemes/request-params';
import { ServerResponse } from '@/core/schemes/server-response';

@Injectable({ providedIn: 'root' })
export class LibraryService extends BaseDataService {
  list(stateName, params: ListRequestParams): Observable<ServerResponse<any>> {
    const backendName = STATE_BACKEND_NAME[stateName];
    return this.http.get<ServerResponse<any>>(
      `${environment.AXIS_API_V3_ROOT}/${backendName}/`,
      {
        params: this.rollParams({ ...params, library_element: true }),
      }
    );
  }

  delete(stateName, id: number): Observable<any> {
    const backendName = STATE_BACKEND_NAME[stateName];
    return this.http.delete(
      `${environment.AXIS_API_V3_ROOT}/${backendName}/${id}/`
    );
  }

  create(stateName, name): Observable<any> {
    const backendName = STATE_BACKEND_NAME[stateName];
    return this.http.post(`${environment.AXIS_API_V3_ROOT}/${backendName}/`, {
      library_element: true,
      name,
    });
  }
}
