import { KeyValueDiffers, Pipe, PipeTransform } from '@angular/core';
import { KeyValuePipe } from '@angular/common';

const unordered = (a, b) => 0;

@Pipe({
  name: 'defaultOrderKeyValue',
})
export class DefaultOrderKeyValuePipe implements PipeTransform {
  constructor(public differs: KeyValueDiffers) {}

  public transform(value, compareFn = unordered) {
    const pipe = new KeyValuePipe(this.differs);
    return pipe.transform(value, compareFn);
  }
}
