<div class="row">
  <div class="col-3">
    <div class="row">
      <div class="col-12">
        <form [formGroup]="filterFromGroup" class="card">
          <h5 class="card-header">Filters</h5>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Search</mat-label>
                  <app-search-input formControlName="search" placeholder="Type to search something"></app-search-input>
                </mat-form-field>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="col-9">
        <div class="row mb-2">
          <div class="col-12 text-right">
            <button mat-raised-button color="accent" (click)="create($event)">
              Create New
            </button>
          </div>
        </div>
    <div class="row">
      <div class="col-12">
        <div class="row" *ngIf="isLoading">
          <div class="col-12">
            <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
          </div>
        </div>
        <table mat-table
               [dataSource]="rows"
               class="table"
               matSort
               [matSortActive]="storedParams.toTableSort().active"
               [matSortDirection]="storedParams.toTableSort().direction"
               (matSortChange)="onSortChange($event)">

          <ng-container matColumnDef="content">
            <th mat-header-cell *matHeaderCellDef>Note</th>
            <td mat-cell *matCellDef="let row">
              <quill-view [content]="row.content" theme="snow" sanitize="true"></quill-view>
            </td>
          </ng-container>

          <ng-container matColumnDef="user__first_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let row">
              <app-user-display [user]="row.user_info" displayType="onlyNameAsLink"></app-user-display>
            </td>
          </ng-container>

          <ng-container matColumnDef="is_public">
            <th mat-header-cell *matHeaderCellDef>Is Public</th>
            <td mat-cell *matCellDef="let row">
              {{ row.is_public | yesNo }}
            </td>
          </ng-container>

          <ng-container matColumnDef="created_on">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Created At</th>
            <td mat-cell *matCellDef="let row">
              {{ row.created_on | date : "MM/dd/yyyy HH:mm" }}
            </td>
          </ng-container>


          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let row">
              <app-permission-mat-button [permissionResponse]="permissions[row.id]['canEdit']" displayType="asLink" (action)="edit($event, row);" class="mr-2">
                <fa-icon [icon]="['fas', 'edit']" matTooltip="Edit"></fa-icon>
              </app-permission-mat-button>
              <app-permission-mat-button [permissionResponse]="permissions[row.id]['canDelete']" displayType="asLink" (action)="delete($event, row);" class="mr-2">
                <fa-icon [icon]="['fas', 'trash']" matTooltip="Delete"></fa-icon>
              </app-permission-mat-button>
              <app-history-mat-button displayType="asLink" [objectId]="row.id" [modelClassName]="'annotation'">
                <fa-icon [icon]="['fas', 'list']" matTooltip="History"></fa-icon>
              </app-history-mat-button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [length]="rowsCount"
                       [pageIndex]="$any(storedParams.page) - 1"
                       [pageSize]="storedParams.page_size"
                       (page)="onPaginateChange($event)"
                       aria-label="Select page">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
