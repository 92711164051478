import {
  RimJoistTypeBackendDict,
  createRimJoistTypeForm,
} from '@/data/simulation/models/enclosure/RimJoistType';
import { BaseValidator } from './base.validator';
import { ModelErrors, StateErrors } from '../state/shared/base.state';

export class RimJoistTypeValidator extends BaseValidator {
  static validateForm(
    rimjoisttype: RimJoistTypeBackendDict
  ): ModelErrors<RimJoistTypeBackendDict> {
    const rimjoisttypeForm = createRimJoistTypeForm(rimjoisttype);
    return rimjoisttypeForm.getFieldAndModelErros();
  }

  static validate(
    rimjoisttypes: RimJoistTypeBackendDict[]
  ): StateErrors<RimJoistTypeBackendDict> {
    const errors = {};
    rimjoisttypes.forEach(rimjoisttype => {
      errors[rimjoisttype.id] = this.validateForm(rimjoisttype);
    });
    return errors;
  }
}
