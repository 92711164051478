<div class="row h-100" *ngIf="initialized">
    <div class="col-12">
        <div class="container">
            <div class="row">
                <div class="col-6">
                    <h3>
                        Your Update Requests
                    </h3>
                </div>
                <div class="col-6 text-right">
                    <app-permission-mat-button [permissionResponse]="createPermissionResponse" [hideOnFalse]="true" class="mr-1" [routerLink]="['create', ]">
                        Create New Request
                    </app-permission-mat-button>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <hr>
                </div>
            </div>

            <div class="row">
                <div class="col-3">
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <form [formGroup]="filterFromGroup">
                                    <h5 class="card-header">Filters <a href="#" class="small"
                                                                       (click)="resetFilters($event);">Reset
                                        filters</a></h5>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-12">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label>Search</mat-label>
                                                    <app-search-input formControlName="search"
                                                                      placeholder="Type to search something"></app-search-input>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-9">
                    <div class="row">
                        <div class="col-10">
                            <i>Average time for update takes 8-10 minutes</i>
                        </div>
                        <div class="col-2">
                            <app-rows-per-page (onRowsPerPageChanged)="onRowsPerPageChanged($event)"></app-rows-per-page>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <ngx-datatable
                                    class="material striped"
                                    [rows]="rows"
                                    columnMode="force"
                                    headerHeight="48"
                                    rowHeight="auto"
                                    footerHeight="50"
                                    [rowIdentity]="getId"
                                    [limit]="storedParams.page_size"
                                    [externalSorting]="true"
                                    (sort)="onSort($event)"
                                    [loadingIndicator]="isLoading">
                                <ngx-datatable-column name="Request ID" prop="id" [sortable]="true">
                                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                        <a [routerLink]="['/', 'hi', 'hirl_rpc_updater_request_list', 'detail', row?.id]">
                                            {{ row.id }}
                                        </a>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="State" prop="state" [sortable]="true">
                                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                        {{ row.state }}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Document" [sortable]="false" [cellClass]="'text-center'">
                                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                        <a href="{{ row.document }}" target="_blank">
                                            <fa-icon [icon]="['fas', 'download']"></fa-icon>
                                        </a>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Result" [sortable]="false" [cellClass]="'text-center'">
                                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                        <a href="{{ row.result_document }}" target="_blank" *ngIf="row.result_document">
                                            <fa-icon [icon]="['fas', 'download']"></fa-icon>
                                        </a>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Created" [sortable]="false" [cellClass]="'text-center'">
                                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                        {{ row.created_at | date: 'MM/dd/yyyy h:mm a' }}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-footer>
                                    <ng-template
                                            ngx-datatable-footer-template>
                                        <div class="col-12 text-center">
                                            <datatable-pager
                                                    [pagerLeftArrowIcon]="'datatable-icon-left'"
                                                    [pagerRightArrowIcon]="'datatable-icon-right'"
                                                    [pagerPreviousIcon]="'datatable-icon-prev'"
                                                    [pagerNextIcon]="'datatable-icon-skip'"
                                                    [page]="storedParams.page"
                                                    [size]="storedParams.page_size"
                                                    [count]="rowsCount"
                                                    (change)="onFooterPage($event)"
                                            >
                                            </datatable-pager>
                                        </div>
                                    </ng-template>
                                </ngx-datatable-footer>
                            </ngx-datatable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
