import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { User } from '@/data/core/models/user';
import {
  CustomerHIRLListInvoice,
  InvoiceAggregateByState,
  InvoiceState,
  InvoiceType,
  MultipleInvoiceImport,
} from '@/data/invoicing/models';
import {
  InvoiceRequestParams,
  InvoiceService,
} from '@/data/invoicing/services/invoice.service';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {
  CompanyAccessRequestParams,
  CompanyAccessService,
} from '@/data/company/services/company-access.service';
import { NavigationService } from '@/shared/services/navigation-service.service';
import {
  IExcelJson,
  UIHelperService,
} from '@/shared/services/ui-helper.service';
import { CurrencyPipe, formatDate } from '@angular/common';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, map, switchMap, takeUntil } from 'rxjs/operators';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { toggleLoading } from '@/state/actions/app.actions';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-hirl-gcp-invoices-list',
  templateUrl: './hirl-gcp-invoices-list.component.html',
  styleUrls: ['./hirl-gcp-invoices-list.component.scss'],
})
export class HirlGcpInvoicesListComponent implements OnInit, OnDestroy {
  protected readonly InvoiceState = InvoiceState;

  public initialized = false;
  public filterFromGroup: FormGroup;

  readonly headerHeight = 40;
  public displayedColumns = [
    'select',
    'invoiceNumber',
    'client_invoicee',
    'total',
    'total_paid',
    'current_balance',
    'created_at',
  ];

  public currentUser$: Observable<User>;
  public currentUser: User;
  public invoices: CustomerHIRLListInvoice[];
  public invoicesCount: number;
  public invoicesIsLoading = true;
  public aggregateByState = new InvoiceAggregateByState();

  public selectedInvoiceItems: CustomerHIRLListInvoice[] = [];

  public defaultParams: InvoiceRequestParams = new InvoiceRequestParams(
    1,
    '',
    '-id',
    25
  );
  public storedParams: InvoiceRequestParams;

  public partiallyPaidLabelMapping = {
    '': 'Any',
    is_partially_paid: 'Is Partially Paid',
    is_not_partially_paid: 'Is Not Partially Paid',
  };

  public containsFeesByNameLabelMapping = {
    appeals_fees: 'Appeals Fees',
  };

  private listSubscription$: Subscription;
  private listSubscriptionAggregate$: Subscription;
  private componentDestroyed$ = new Subject();

  constructor(
    public store: Store<AppState>,
    public invoiceService: InvoiceService,
    public router: Router,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private companyAccessService: CompanyAccessService,
    private navigation: NavigationService,
    private uiHelperService: UIHelperService,
    private currencyPipe: CurrencyPipe
  ) {
    this.currentUser$ = this.store.select(getInfoUser);
  }

  ngOnInit() {
    this.defaultParams.state = InvoiceState.new;
    this.storedParams = Object.assign(
      new InvoiceRequestParams(),
      this.defaultParams
    );

    forkJoin({
      queryParams: this.activatedRoute.queryParams.pipe(first()),
      currentUser: this.store.select(getInfoUser).pipe(first()),
    })
      .pipe(
        takeUntil(this.componentDestroyed$),
        switchMap(({ queryParams, currentUser }) => {
          const params = new CompanyAccessRequestParams();
          params.company = currentUser.company;
          return this.companyAccessService.list(params).pipe(
            first(),
            map(companyAccessesResponse => {
              const companyAccesses = companyAccessesResponse.results;
              let companyAccess = null;
              if (companyAccesses.length) {
                companyAccess = companyAccesses[0];
              }
              return {
                queryParams: queryParams,
                currentUser: currentUser,
                companyAccess: companyAccess,
              };
            })
          );
        })
      )
      .subscribe(({ queryParams, currentUser, companyAccess }) => {
        this.currentUser = currentUser;
        this.storedParams.assignQueryParams(queryParams, [
          'contains_fees_by_name',
        ]);
        if (currentUser.is_superuser) {
          this.storedParams.company_access = null;
        } else {
          if (!this.storedParams.company_access) {
            this.storedParams.company_access = [companyAccess.id];
          }
        }
        this.initialized = true;

        this.setupFilterForm();
        this.hydrateForm();
        this.list();
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }

    if (this.listSubscriptionAggregate$) {
      this.listSubscriptionAggregate$.unsubscribe();
    }
  }

  list() {
    this.invoicesIsLoading = true;

    this.storedParams.invoice_type = [
      InvoiceType.hirlGCP,
      InvoiceType.hirlGCPTopShelf,
      InvoiceType.hirlGCPInitial,
    ];

    // populate params to query string
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.storedParams.toQueryParams(),
      replaceUrl: true,
    });

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }
    if (this.listSubscriptionAggregate$) {
      this.listSubscriptionAggregate$.unsubscribe();
    }

    const aggregateRequestParams = new InvoiceRequestParams();
    aggregateRequestParams.company_access = this.storedParams.company_access;
    aggregateRequestParams.invoice_type = [
      InvoiceType.hirlGCP,
      InvoiceType.hirlGCPInitial,
      InvoiceType.hirlGCPTopShelf,
    ];
    this.listSubscriptionAggregate$ = this.invoiceService
      .aggregate_by_state(aggregateRequestParams)
      .pipe(first())
      .subscribe(aggregateByState => {
        this.aggregateByState = aggregateByState;
      });
    this.listSubscription$ = this.invoiceService
      .customer_hirl_list(this.storedParams)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(data => {
        this.invoices = data.results;
        this.invoicesCount = data.count;
        this.invoicesIsLoading = false;
      });
  }

  onSort($event: Sort) {
    this.storedParams.ordering = `${$event.direction === 'desc' ? '-' : ''}${
      $event.active
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  onPaginateChange($event: PageEvent) {
    this.uiHelperService.getUISettings().rowsPerPage = $event.pageSize;
    this.storedParams.page_size = $event.pageSize;
    this.storedParams.page = $event.pageIndex + 1;
    this.list();
  }

  onFooterPage(event) {
    this.storedParams.page = event.page;
    this.list();
  }

  onChangeInvoiceState(event, newState) {
    this.storedParams.state = newState;
    this.list();
  }

  public setupFilterForm() {
    this.filterFromGroup = this.fb.group({
      search: [null],
      partially_paid: [''],
      contains_fees_by_name: [null],
      company_access_info: [null],
    });

    this.filterFromGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        const params = Object.assign(this.defaultParams, values);
        delete params.ordering;

        if (params.company_access_info) {
          params.company_access = params.company_access_info.map(
            companyAccess => companyAccess.id
          );
        } else {
          params.company_access = null;
        }
        delete params.company_access_info;

        this.storedParams = Object.assign(this.storedParams, params);
        this.list();
      });
  }

  public hydrateForm() {
    this.filterFromGroup.patchValue(this.storedParams, {
      emitEvent: false,
      onlySelf: true,
    });
  }

  resetFilters($event: MouseEvent) {
    $event.preventDefault();
    this.filterFromGroup.reset();
  }

  calculateTotal(): number {
    let total = 0;
    for (const invoiceItem of this.selectedInvoiceItems) {
      total += invoiceItem.total;
    }
    return total;
  }

  downloadSelectedInvoiceItems($event: MouseEvent) {
    this.store.dispatch(toggleLoading({ payload: true }));

    const newTab = window.open('', '_blank');
    this.invoiceService
      .customer_hirl_pdf_invoice_report_multiple_download({
        invoices: this.selectedInvoiceItems?.map(invoice => invoice.id),
        select_all: false,
      } as MultipleInvoiceImport)
      .subscribe(
        asynchronousProcessedDocument => {
          newTab.location.href = `/file-operation/document/${asynchronousProcessedDocument.id}`;
          newTab.focus();
          window.location.reload();
        },
        error => {
          // Close the blank tab if we encountered an error
          newTab.close();
          this.uiHelperService.handleUserRequestError(error);
        }
      );
  }

  onSelect(event: MatCheckboxChange, row: CustomerHIRLListInvoice): void {
    if (event.checked) {
      // Add row to selectedInvoiceItemGroups array
      this.selectedInvoiceItems = [...this.selectedInvoiceItems, row];
      this.selectedInvoiceItems = this.selectedInvoiceItems.slice();
    } else {
      // Remove row from selectedInvoiceItemGroups array
      const index = this.selectedInvoiceItems.indexOf(row);
      if (index > -1) {
        this.selectedInvoiceItems.splice(index, 1);
        this.selectedInvoiceItems = this.selectedInvoiceItems.slice();
      }
    }
  }

  onSelectAll(event: MatCheckboxChange): void {
    if (event.checked) {
      this.selectedInvoiceItems = [...this.invoices];
    } else {
      this.selectedInvoiceItems = [];
    }
  }

  formatCurrency(value: number): string {
    return this.currencyPipe.transform(value, 'USD', 'symbol', '1.2-2') || '';
  }

  exportToExcel($event: MouseEvent) {
    $event.preventDefault();
    const excelData: Array<IExcelJson> = [];
    const udt: IExcelJson = {
      data: [
        {
          A: 'Invoice Number',
          B: 'Subdivision/MF Development',
          C: 'Client/Invoicee',
          D: 'Total',
          E: 'Total Paid',
          F: 'Balance',
          G: 'H Numbers',
          H: 'Created',
        }, // table header
      ],
      skipHeader: true,
    };
    this.invoices.forEach(invoiceItem => {
      const formattedDate = formatDate(
        invoiceItem.created_at,
        'MM/dd/yyyy',
        'en-US'
      );
      const formattedNumbers: string = invoiceItem.h_numbers.join(', ');
      udt.data.push({
        A: invoiceItem.id,
        B: invoiceItem.subdivision_name,
        C:
          invoiceItem.customer_name +
          '/' +
          invoiceItem.erfp_before_company_consolidation_name,
        D: this.formatCurrency(invoiceItem?.total),
        E: this.formatCurrency(invoiceItem.total_paid),
        F: this.formatCurrency(invoiceItem.current_balance),
        G: formattedNumbers,
        H: formattedDate,
      });
    });
    excelData.push(udt);

    this.uiHelperService.exportJsonToExcel(excelData, 'invoices');
  }

  exportToCSV($event: MouseEvent) {
    $event.preventDefault();
    const data = [];
    this.invoices.forEach(invoiceItem => {
      const formattedDate = formatDate(
        invoiceItem.created_at,
        'MM/dd/yyyy',
        'en-US'
      );
      const formattedNumbers: string = invoiceItem.h_numbers.join(', ');
      data.push({
        'Invoice Number': invoiceItem.id,
        'Subdivision/MF Developmnet': invoiceItem.subdivision_name,
        'Client/Invoicee':
          invoiceItem.customer_name +
          '/' +
          invoiceItem.erfp_before_company_consolidation_name,
        Total: this.formatCurrency(invoiceItem?.total),
        'Total Paid': this.formatCurrency(invoiceItem.total_paid),
        Balance: this.formatCurrency(invoiceItem.current_balance),
        'H Numbers': formattedNumbers,
        Created: formattedDate,
      });
    });
    this.uiHelperService.exportToCsv(data, 'invoices', [
      'Invoice Number',
      'Subdivision/MF Developmnet',
      'Client/Invoicee',
      'Total',
      'Total Paid',
      'Balance',
      'H Numbers',
      'Created',
    ]);
  }
}
