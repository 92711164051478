<ngx-datatable class="material" [rows]="analytics" columnMode="force" [headerHeight]="40"
    rowHeight="auto" [loadingIndicator]="isLoading" [footerHeight]="50">
    <ngx-datatable-column name="Type" [sortable]="false" width="160">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{ row.analysis_type_info.name }}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="ERI Score" [sortable]="false">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{ row.eri_score }}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Fuel Usages" [sortable]="false">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <div style="padding-left: 2px;" class="d-inline-flex" *ngFor="let fuel_usage of row.fuel_usages">
                <button type="button" class="btn btn-outline-secondary" (click)="onShowFuelUsage($event, fuel_usage)">
                    {{fuel_usage.fuel_type_display}}
                </button>
            </div>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Consumption" [sortable]="false" cellClass="d-flex align-items-center">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <div style="padding-left: 2px;" class="d-inline-flex ">
                <button type="button" class="btn btn-outline-secondary" (click)="onShowConsumption($event, row.summary)">
                    Details
                </button>
            </div>
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-footer>
        <ng-template ngx-datatable-footer-template>
            <div class="col-6">
                Showing {{storedParams.page}} to {{count | divide: 25 }} of {{count}}
            </div>
            <div class="col-6">
                <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                    [pagerRightArrowIcon]="'datatable-icon-right'"
                    [pagerPreviousIcon]="'datatable-icon-prev'"
                    [pagerNextIcon]="'datatable-icon-skip'" [page]="storedParams.page" [size]="25"
                    [count]="count" (change)="onFooterPage($event)">
                </datatable-pager>
            </div>
        </ng-template>
    </ngx-datatable-footer>
</ngx-datatable>