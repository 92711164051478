import {
  LightsBackendDict,
  createLightsForm,
} from '@/data/simulation/models/lights/Lights';
import { BaseValidator } from './base.validator';
import { ModelErrors, StateErrors } from '../state/shared/base.state';

export class LightsValidator extends BaseValidator {
  static validateForm(
    lights: LightsBackendDict
  ): ModelErrors<LightsBackendDict> {
    const lightsForm = createLightsForm(lights);
    return lightsForm.getFieldAndModelErros();
  }

  static validate(lights: LightsBackendDict): StateErrors<LightsBackendDict> {
    const errors = {};
    errors[lights.id] = this.validateForm(lights);
    return errors;
  }
}
