import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerDocumentEditDialogComponent } from './components/customer-document-edit-dialog/customer-document-edit-dialog.component';
import { SharedModule } from '@/shared/shared.module';
import { CustomerDocumentDisplayComponent } from './components/customer-document-display/customer-document-display.component';
import { AsynchronousProcessedDocumentStatusDialogComponent } from './components/asynchronous-processed-document-status-dialog/asynchronous-processed-document-status-dialog.component';
import { CustomerDocumentTabComponent } from './components/customer-document-tab/customer-document-tab.component';

@NgModule({
  declarations: [
    CustomerDocumentEditDialogComponent,
    CustomerDocumentDisplayComponent,
    AsynchronousProcessedDocumentStatusDialogComponent,
    CustomerDocumentTabComponent,
  ],
  imports: [CommonModule, SharedModule],
  exports: [
    CustomerDocumentEditDialogComponent,
    CustomerDocumentDisplayComponent,
    CustomerDocumentTabComponent,
  ],
})
export class FilehandlingModule {}
