<div class="container" *ngIf="initialized">
  <div class="row">
    <div class="col-12">
      <div class="d-flex">
        <div class="mb-0 align-self-end">
          <button mat-raised-button color="accent" appBackButton [fallbackUrl]="'/home/apex/request/list'">
            <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
          </button>
        </div>
        <img
          class="ml-2"
          src="assets/apex_logo.svg"
          width="220"
        />
        <h3 class="ml-2 mb-0 align-self-end h3" style="line-height: 1;">
          Simulation
        </h3>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <hr>
    </div>
  </div>
  <form [formGroup]="formGroup">
    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Simulation/Analysis Options</mat-label>
          <mat-select formControlName="request_type">
            <mat-option *ngFor="let item of ApexRequestTypeLabelMapping | defaultOrderKeyValue" [value]="item.key" [disabled]="item.key === ApexRequestType.simulate_and_create_fp">{{ item.value }}</mat-option>
          </mat-select>
          <mat-error *ngIf="formGroup.get('request_type').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row" *ngIf="formGroup.get('request_type').value === ApexRequestType.simulate_and_create_home || formGroup.get('request_type').value === ApexRequestType.simulate_and_create_fp">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Builder Company</mat-label>
          <app-generic-model-autocomplete modelClassName="company" formControlName="builder_organization_info" [panelWidth]="640" [filterParams]="{company_type: 'builder'}" [display]="builderCompanyDisplay" [optionDisplay]="builderCompanyDisplay"></app-generic-model-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <div class="row" *ngIf="formGroup.get('request_type').value === ApexRequestType.simulate_and_create_home">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Subdivision</mat-label>
          <app-generic-model-autocomplete modelClassName="subdivision" formControlName="subdivision_info" [panelWidth]="640"></app-generic-model-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <div class="row" *ngIf="formGroup.get('request_type').value === ApexRequestType.simulate_and_create_home || formGroup.get('request_type').value === ApexRequestType.simulate_and_create_fp">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Energy Model Type</mat-label>
          <mat-select formControlName="energy_model_type">
            <mat-option *ngFor="let item of ApexRequestEnergyModelTypeLabelMapping | defaultOrderKeyValue" [value]="item.key">{{ item.value }}</mat-option>
          </mat-select>
          <mat-error *ngIf="formGroup.get('energy_model_type').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <ngx-dropzone (change)="onSelect($event)" [accept]="'.blg, .xml, .hpxml'">
          <ngx-dropzone-label>
            Drag & Drop <b>HPXML</b>, <b>REM XML</b>, or <b>REM/Rate&trade;</b> <b>.blg</b> File(s) Here
          </ngx-dropzone-label>
          <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
            <ngx-dropzone-label>{{ f.name | slice :0: 20 }} ({{ f.type | slice :0: 20 }})</ngx-dropzone-label>
          </ngx-dropzone-preview>
        </ngx-dropzone>
      </div>
    </div>
  </form>
  <div class="row mt-2">
    <div class="col-12 text-center">
      <button mat-raised-button color="accent" (click)="onSubmit($event)">
        Submit
      </button>
    </div>
  </div>
</div>
