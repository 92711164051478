<div class="row">
    <mat-card class="card-container position-relative">
        <app-loading-overlay *ngIf="loading"></app-loading-overlay>
        <mat-card-content>
            <app-form formName="foundationWallTypeFormGroup" (save)="onSave()" [formGroup]="foundationWallTypeFormGroup" (save)="onSave()" *ngIf="!calculatorView">
                <div>
                    <app-input formControlName="name" [label]="fieldLabels.name" cdkFocusInitial></app-input>
                </div>
                <div>
                    <app-input formControlName="assembly_u_value" [label]="fieldLabels.assembly_u_value" type="expression"></app-input>
                    <app-checkbox formControlName="user_defined_assembly_u_value" label="Directly defined U-value"></app-checkbox>
                </div>
            </app-form>
            <app-assembly-editor 
                    (save)="onSave()" 
                    [form]="foundationWallTypeFormGroup" 
                    (systemUValue)="handleSystemUValue($event)"
                    *ngIf="!foundationWallTypeFormGroup.controls.user_defined_assembly_u_value.value">
                <app-form formName="foundationWallTypeFormGroup" (save)="onSave()" [formGroup]="foundationWallTypeFormGroup">
                    <div>
                        <app-select formControlName="material" [label]="fieldLabels.material" [items]="materialLabels"></app-select>
                        <app-input formControlName="thickness" type="number" unit="{{lengthLabels[entity?.units]}}" [label]="fieldLabels.thickness"></app-input>
                    </div>
                    <br>
                    <ng-container *ngIf="foundationWallTypeFormGroup.controls.stud_type.value">
                        <div><h5>Cavity Insulation</h5></div>
                        <div>
                            <app-input formControlName="cavity_insulation_thickness" type="number" label="Thickness" unit="{{lengthLabels[entity?.units]}}"></app-input>
                            <app-select formControlName="cavity_insulation_grade" type="number" [items]="gradeLabels" label="Insulation Grade"></app-select>
                            <app-input formControlName="cavity_insulation_r_value" type="expression" label="R-value"></app-input>
                        </div>
                    </ng-container>
                    <br>
                    <div><h5>Exterior Insulation</h5></div>
                    <div>
                        <app-input formControlName="exterior_insulation_from_top" type="number" label="From top" unit="{{lengthLabels[entity?.units]}}"></app-input>
                        <app-input formControlName="exterior_insulation_from_bottom" type="number" label="From bottom" unit="{{lengthLabels[entity?.units]}}"></app-input>
                        <app-input formControlName="exterior_continuous_insulation_r_value" type="expression" label="R-value (Continuous)"></app-input>
                    </div>
                    <br>
                    <div><h5>Interior Insulation</h5></div>
                    <div>
                        <app-input formControlName="interior_insulation_from_top" type="number" label="From top" unit="{{lengthLabels[entity?.units]}}"></app-input>
                        <app-input formControlName="interior_insulation_from_bottom" type="number" label="From bottom" unit="{{lengthLabels[entity?.units]}}"></app-input>
                        <app-input formControlName="continuous_insulation_r_value" type="expression" label="R-value (Continuous)"></app-input>
                    </div>
                    <br>
                    <div>
                        <app-select formControlName="stud_type" [label]="fieldLabels.stud_type" [items]="studTypeLabels" [allowClearingValue]="true"></app-select>
                    </div>
                    <br />
                    <ng-container *ngIf="foundationWallTypeFormGroup.controls.stud_type.value">
                        <div>
                            <app-input formControlName="gypsum_thickness" type="number" [label]="fieldLabels.gypsum_thickness" unit="{{lengthLabels[entity?.units]}}"></app-input>
                        </div>
                        <div>
                            <h5>Framing</h5>
                        </div>
                        <div>
                            <app-input formControlName="frame_width" type="number" label="Width" unit="{{lengthLabels[entity?.units]}}"></app-input>
                            <app-input formControlName="frame_depth" type="number" label="Depth" unit="{{lengthLabels[entity?.units]}}"></app-input>
                            <app-input formControlName="frame_spacing" type="number" label="Spacing" unit="{{lengthLabels[entity?.units]}}"></app-input>
                        </div>
                        <div>
                            <app-input formControlName="framing_factor" type="number" [label]="fieldLabels.framing_factor"></app-input>
                            <app-checkbox formControlName="use_default_framing_factor"
                                [label]="fieldLabels.use_default_framing_factor"></app-checkbox>
                        </div>
                    </ng-container>
                </app-form>
            </app-assembly-editor>
        </mat-card-content>
    </mat-card>
</div>
