<mat-expansion-panel>
    <mat-expansion-panel-header>
        <mat-panel-title>
            <strong>Emissions</strong>
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
        <table mat-table [dataSource]="tableData" class="mat-elevation-z8 demo-table">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                <td mat-footer-cell *matFooterCellDef> Total </td>
            </ng-container>

            <ng-container matColumnDef="{{item}}" *ngFor="let item of displayedColumns.slice(1)">
                <th mat-header-cell *matHeaderCellDef> {{item}} </th>
                <td mat-cell *matCellDef="let element" class="data">
                    <ng-container *ngIf="element[item]">{{element[item] | number: '1.2-2'}}</ng-container>
                    <ng-container *ngIf="!element[item]"><i>--</i></ng-container>
                </td>
                <td mat-footer-cell *matFooterCellDef class="data">
                    <ng-container *ngIf="totlaData[item]">{{totlaData[item] | number: '1.2-2'}}</ng-container>
                    <ng-container *ngIf="!totlaData[item]"><i>--</i></ng-container>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
        </table>
    </ng-template>
</mat-expansion-panel>
