import { ThermostatBackendDict } from '@/data/simulation/models/mechanicals/Thermostat';
import { createAction, props } from '@ngrx/store';
import { StateErrors } from '../shared/base.state';

export const loadThermostats = createAction(
  '[Thermostat] Load Thermostats',
  props<{ thermostats: ThermostatBackendDict[] }>()
);

export const loadThermostatsSuccess = createAction(
  '[Thermostat] Load Thermostats Success',
  props<{
    thermostats: ThermostatBackendDict[];
    errors: StateErrors<ThermostatBackendDict>;
  }>()
);

export const loadThermostatsFailure = createAction(
  '[Thermostat] Load Thermostats Failure',
  props<{ error: any }>()
);

export const updateThermostat = createAction(
  '[Thermostat] Update Thermostat',
  props<{ thermostatChanges: Partial<ThermostatBackendDict> }>()
);

export const updateThermostatFailure = createAction(
  '[Thermostat API] Update Thermostat Failure',
  props<{ id: number }>()
);

export const removeThermostat = createAction(
  '[Thermostat] Remove Thermostat',
  props<{ thermostat: ThermostatBackendDict }>()
);

export const removeThermostatSuccess = createAction(
  '[Thermostat API] Remove Thermostat Success',
  props<{ id: number }>()
);

export const removeThermostatFailure = createAction(
  '[Thermostat API] Remove Thermostat Failure',
  props<{ id: number }>()
);
