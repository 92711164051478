import { ProductInfo } from '@/data/product/models';
import { PaymentRequestInfo } from '@/data/customer-hirl/models/payment-request';

export class PaymentRequestProduct {
  id?: number;
  product?: number;
  product_info?: ProductInfo;
  payment_request?: number;
  payment_request_info?: PaymentRequestInfo;
  practice_item_product_count?: number;
}
