import { createReducer, on } from '@ngrx/store';
import * as WindowTypeActions from './actions';
import { WindowTypeBackendDict } from '@/data/simulation/models/enclosure/WindowType';
import { BaseState } from '../shared/base.state';
import { addStateErrorCount } from '../helper';

export type WindowTypeState = BaseState<WindowTypeBackendDict>;

export const initialState: WindowTypeState = {
  entities: {},
  loading: {},
  errors: {},
};

export const reducer = createReducer(
  initialState,
  on(
    WindowTypeActions.loadWindowTypesSuccess,
    (state, { windowTypes, errors }) => {
      const entities = windowTypes.reduce(
        (acc, windowType) => ({ ...acc, [windowType.id]: windowType }),
        {}
      );
      const loading = windowTypes.reduce(
        (acc, windowType) => ({ ...acc, [windowType.id]: false }),
        {}
      );
      return {
        entities: { ...state.entities, ...entities },
        loading: { ...state.loading, ...loading },
        errors: { ...state.errors, ...addStateErrorCount(errors) },
      };
    }
  ),

  on(WindowTypeActions.updateWindowType, (state, { windowTypeChanges }) => ({
    ...state,
    loading: { ...state.loading, [windowTypeChanges.id]: true },
  })),

  on(WindowTypeActions.updateWindowTypeFailure, (state, { id }) => ({
    ...state,
    loading: { ...state.loading, [id]: false },
  })),

  on(WindowTypeActions.removeWindowTypeSuccess, (state, { id }) => {
    const { [id]: removed, ...entities } = state.entities;
    const { [id]: removedLoading, ...loading } = state.loading;
    const { [id]: removedErrors, ...errors } = state.errors;
    return { entities, loading, errors };
  })
);
