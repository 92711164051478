import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { selectSeasonalRateViewSet } from '@/modules/simulation/state/seasonal-rate/selectors';
import {
  SeasonalRateBackendDict,
  createSeasonalRateForm,
} from '@/data/simulation/models/utility/SeasonalRate';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

const MONTHS = [
  { name: 'January', value: 1, days: 31 },
  { name: 'February', value: 2, days: 28 },
  { name: 'March', value: 3, days: 31 },
  { name: 'April', value: 4, days: 30 },
  { name: 'May', value: 5, days: 31 },
  { name: 'June', value: 6, days: 30 },
  { name: 'July', value: 7, days: 31 },
  { name: 'August', value: 8, days: 31 },
  { name: 'September', value: 9, days: 30 },
  { name: 'October', value: 10, days: 31 },
  { name: 'November', value: 11, days: 30 },
  { name: 'December', value: 12, days: 31 },
];

@Component({
  selector: 'app-seasonal-rate',
  templateUrl: './seasonal-rate.component.html',
  styleUrls: ['./seasonal-rate.component.scss'],
})
export class SeasonalRateComponent {
  @Input() entityId: number;

  componentDestroyed$ = new Subject();

  entity: SeasonalRateBackendDict;
  loading: boolean;
  seasonalRateForm: FormGroup;

  monthLabel: { [key: number]: string } = {};
  dateLabel: { value: number; label: string }[] = [];
  startDateLabel: { [key: number]: string } = {};
  endDateLabel: { [key: number]: string } = {};

  constructor(public store: Store) {
    MONTHS.forEach(month => {
      this.monthLabel[month.value] = month.name;
    });

    for (let i = 1; i <= 31; i++) {
      const label = i.toString();
      if (label.endsWith('1')) {
        this.dateLabel.push({ value: i, label: label + 'st' });
      } else if (label.endsWith('2')) {
        this.dateLabel.push({ value: i, label: label + 'nd' });
      } else if (label.endsWith('3')) {
        this.dateLabel.push({ value: i, label: label + 'rd' });
      } else {
        this.dateLabel.push({ value: i, label: label + 'th' });
      }
    }
  }

  updateDateLabel() {
    const startMonth = this.seasonalRateForm.get('start_month').value;
    const endMonth = this.seasonalRateForm.get('end_month').value;
    this.startDateLabel = {};
    this.endDateLabel = {};
    const startMonthLastDate = MONTHS.find(
      month => month.value === startMonth
    ).days;
    const endMonthLastDate = MONTHS.find(
      month => month.value === endMonth
    ).days;

    this.dateLabel.slice(0, startMonthLastDate).forEach((date, index) => {
      this.startDateLabel[date.value] = date.label;
    });

    this.dateLabel.slice(0, endMonthLastDate).forEach((date, index) => {
      this.endDateLabel[date.value] = date.label;
    });
  }

  ngOnInit() {
    if (this.entityId === null) {
      return;
    }
    this.store
      .select(selectSeasonalRateViewSet(this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(({ data, loading }) => {
        this.loading = loading;

        if (loading) return;

        this.entity = data;
        if (this.seasonalRateForm) {
          this.seasonalRateForm.patchValue(data);
        } else {
          this.seasonalRateForm = createSeasonalRateForm(data);
        }

        this.updateDateLabel();
      });

    // this.seasonalRateForm.valueChanges
    //   .pipe(takeUntil(this.componentDestroyed$))
    //   .subscribe(() => {
    //     this.updateDateLabel();
    //   }
    // );
  }

  saveSeasonalRate(
    seasonalRateChangedValues: Partial<SeasonalRateBackendDict>
  ) {
    // if (this.entityId === null) {
    //   this.save.emit(this.seasonalRateForm.value);
    //   return;
    // }
    // this.store.dispatch(
    //   updateSeasonalRate({
    //     seasonalRateChanges: seasonalRateChangedValues,
    //   })
    // );
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
