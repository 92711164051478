import {
  selectPhotovoltaicName,
  selectPhotovoltaicErrorsCount,
} from '@/modules/simulation/state/photovoltaic/selectors';
import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-photovoltaic-errors',
  templateUrl: './photovoltaic-errors.component.html',
  styleUrls: ['./photovoltaic-errors.component.scss'],
})
export class PhotovoltaicErrorsComponent {
  @Input() entityId: number;
  componentDestroyed$: Subject<boolean> = new Subject();
  name: string;
  totalErrorCount = 0;
  totalWarningCount = 0;

  constructor(public store: Store) {}

  ngOnInit(): void {
    this.store
      .select(selectPhotovoltaicErrorsCount(this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(count => {
        this.totalErrorCount = count.errorCount;
        this.totalWarningCount = count.warningCount;
      });

    this.store
      .select(selectPhotovoltaicName(this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(name => {
        this.name = name;
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
