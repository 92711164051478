import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ClientAgreementService } from '@/data/customer-hirl/services/client-agreement.service';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { filter, first, takeUntil } from 'rxjs/operators';
import { forkJoin, Observable, Subject } from 'rxjs';
import { resetClientAgreement } from '@/state/actions/customer-hirl/client-agreement.actions';
import {
  getHIRLClientAgreement,
  getHIRLClientAgreementDocumentsCount,
  getHIRLClientAgreementNotesCount,
} from '@/state/selectors/customer-hirl/client-agreement.selector';
import {
  ClientAgreement,
  ClientAgreementExtensionRequestState,
  ClientAgreementState,
  ClientAgreementStateLabelMapping,
} from '@/data/customer-hirl/models';
import { toggleLoading } from '@/state/actions/app.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Location } from '@angular/common';
import { getInfoUser } from '@/state/selectors/info.selector';
import { User } from '@/data/core/models/user';
import { ConfirmedGeocodeResponse } from '@/modules/geocoder/components/confirm-geocode-response-dialog/confirm-geocode-response';
import { HIRLClientAgreementPermissionService } from '@/modules/customer-hirl/services/client-agreement-permission.service';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { Company } from '@/data/company/models';
import {
  ClientAgreementChangeDialogData,
  HirlClientAgreementChangeDialogComponent,
} from '@/modules/customer-hirl/components/hirl-client-agreement-change-dialog/hirl-client-agreement-change-dialog.component';
import {
  HirlClientAgreementForceStateChangeDialogComponent,
  HirlClientAgreementForceStateChangeDialogData,
} from '@/modules/customer-hirl/components/hirl-client-agreement-force-state-change-dialog/hirl-client-agreement-force-state-change-dialog.component';
import {
  InputDialogComponent,
  InputDialogData,
  InputDialogInputTypeEnum,
} from '@/shared/components/input-dialog/input-dialog.component';

@Component({
  selector: 'app-hirl-client-agreement-detail',
  templateUrl: './hirl-client-agreement-detail.component.html',
  styleUrls: ['./hirl-client-agreement-detail.component.scss'],
})
export class HirlClientAgreementDetailComponent implements OnInit, OnDestroy {
  protected readonly ClientAgreementState = ClientAgreementState;
  protected readonly ClientAgreementExtensionRequestState =
    ClientAgreementExtensionRequestState;

  public initialized = false;
  public clientAgreement: ClientAgreement;
  public clientAgreementDocumentsCount = 0;
  public clientAgreementNotesCount = 0;

  public editPermissionResponse: Observable<ObjectPermissionResponse>;
  public deletePermissionResponse: Observable<ObjectPermissionResponse>;
  public reSendEmailPermissionResponse: Observable<ObjectPermissionResponse>;
  public updateDocusignStatusPermissionResponse: Observable<ObjectPermissionResponse>;
  public counterSignPermissionResponse: Observable<ObjectPermissionResponse>;
  public initiateExtensionRequestPermissionResponse: Observable<ObjectPermissionResponse>;

  public currentUser: User;

  public clientAgreementStateLabelMapping = ClientAgreementStateLabelMapping;

  public confirmedGeocodeResponse: ConfirmedGeocodeResponse;
  public selectedIndex = 0;
  public get initiateNewClientAgreementUrl(): string[] {
    if (this.currentUser.company == this.clientAgreement.company) {
      return ['/', 'hi', 'client_agreements', 'create'];
    }
    return [
      '/',
      'hi',
      'client_agreements',
      this.clientAgreement.company_info.company_type,
      'create_without_user',
    ];
  }

  private componentDestroyed$ = new Subject();
  constructor(
    private store: Store<AppState>,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private clientAgreementService: ClientAgreementService,
    private clientAgreementPermissionService: HIRLClientAgreementPermissionService,
    private uiHelperService: UIHelperService
  ) {
    this.store
      .select(getHIRLClientAgreement)
      .pipe(
        filter(val => val !== null),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(clientAgreement => {
        this.clientAgreement = clientAgreement;
        this.clientAgreementSetup();
      });

    this.store
      .select(getHIRLClientAgreementDocumentsCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(count => {
        this.clientAgreementDocumentsCount = count;
      });

    this.store
      .select(getHIRLClientAgreementNotesCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(count => {
        this.clientAgreementNotesCount = count;
      });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(params => {
        if (params.tab) {
          this.selectedIndex = Number(params.tab) || 0;
        }
      });

    this.activatedRoute.params
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(params => {
        this.store.dispatch(toggleLoading({ payload: true }));
        forkJoin({
          clientAgreement: this.clientAgreementService
            .retrieve(params.clientAgreementId)
            .pipe(first()),
          currentUser: this.store.select(getInfoUser).pipe(first()),
        }).subscribe(
          ({ clientAgreement, currentUser }) => {
            this.clientAgreement = clientAgreement;

            this.currentUser = currentUser;
            this.clientAgreementSetup();
            this.store.dispatch(toggleLoading({ payload: false }));
            this.initialized = true;
          },
          error => this.uiHelperService.handleMainRequestError(error)
        );
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
    this.store.dispatch(resetClientAgreement());
  }

  clientAgreementSetup() {
    this.editPermissionResponse = this.clientAgreementPermissionService.canEdit(
      this.clientAgreement
    );
    this.deletePermissionResponse =
      this.clientAgreementPermissionService.canDelete(this.clientAgreement);
    this.reSendEmailPermissionResponse =
      this.clientAgreementPermissionService.canReSendEmail(
        this.clientAgreement
      );
    this.counterSignPermissionResponse =
      this.clientAgreementPermissionService.canCounterSign(
        this.clientAgreement
      );
    this.updateDocusignStatusPermissionResponse =
      this.clientAgreementPermissionService.canUpdateDocusignStatus(
        this.clientAgreement
      );
    this.initiateExtensionRequestPermissionResponse =
      this.clientAgreementPermissionService.canInitiateExtensionRequest(
        this.clientAgreement
      );
  }

  onTabChange($event: MatTabChangeEvent) {
    const url = this.router
      .createUrlTree([], {
        relativeTo: this.activatedRoute,
        queryParams: { tab: $event.index },
      })
      .toString();
    this.location.replaceState(url);
  }

  reSendEmail($event: MouseEvent) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '40%',
      data: {
        title: 'Are you sure you want to re-send Docusign email ?',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (!result) {
          return;
        }
        this.store.dispatch(toggleLoading({ payload: true }));
        this.clientAgreementService
          .resend_docusign_email(this.clientAgreement.id)
          .pipe(takeUntil(this.componentDestroyed$), first())
          .subscribe(
            _ => {
              this.store.dispatch(toggleLoading({ payload: false }));
              this.uiHelperService.openSnackBar(`Email Successfully Sent`);
            },
            error => this.uiHelperService.handleUserRequestError(error)
          );
      });
  }

  delete($event: MouseEvent) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '40%',
      data: {
        title: 'Are you sure you want delete Client Agreement ?',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (!result) {
          return;
        }
        this.store.dispatch(toggleLoading({ payload: true }));
        this.clientAgreementService
          .delete(this.clientAgreement.id)
          .pipe(takeUntil(this.componentDestroyed$), first())
          .subscribe(
            _ => {
              this.store.dispatch(toggleLoading({ payload: false }));
              this.router.navigate(['/', 'hi', 'builder-agreements']);
            },
            error => this.uiHelperService.handleUserRequestError(error)
          );
      });
  }

  edit($event: MouseEvent) {
    $event.preventDefault();

    const dialogRef = this.dialog.open(
      HirlClientAgreementChangeDialogComponent,
      {
        width: '55%',
        disableClose: true,
        autoFocus: false,
        data: {
          clientAgreement: this.clientAgreement,
        } as ClientAgreementChangeDialogData,
      }
    );

    dialogRef.afterClosed().subscribe((result?: Company) => {
      if (!result) {
        return;
      }
      this.uiHelperService.openSnackBar('Record Successfully Updated');
    });
  }

  approve($event: MouseEvent) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '40%',
      data: {
        title: 'Are you sure you want to Approve Client Agreement?',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (!result) {
          return;
        }
        this.store.dispatch(toggleLoading({ payload: true }));
        this.clientAgreementService
          .approve(this.clientAgreement.id)
          .pipe(takeUntil(this.componentDestroyed$), first())
          .subscribe(
            _ => {
              this.store.dispatch(toggleLoading({ payload: false }));
              this.uiHelperService.openSnackBar(
                `Client Agreement successfully Approved`
              );
            },
            error => this.uiHelperService.handleUserRequestError(error)
          );
      });
  }

  counterSign($event: MouseEvent) {
    $event.preventDefault();
    this.store.dispatch(toggleLoading({ payload: true }));
    this.clientAgreementService
      .verify(this.clientAgreement.id)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(
        _ => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.uiHelperService.openSnackBar(
            `Client Agreement successfully updated`
          );
        },
        error => this.uiHelperService.handleUserRequestError(error)
      );
  }

  updateDocusignStatus($event: MouseEvent) {
    $event.preventDefault();
    this.store.dispatch(toggleLoading({ payload: true }));
    this.clientAgreementService
      .update_docusign_status(this.clientAgreement.id)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(
        _ => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.uiHelperService.openSnackBar(
            `Client Agreement status successfully updated`
          );
        },
        error => this.uiHelperService.handleUserRequestError(error)
      );
  }

  forceState($event: MouseEvent) {
    $event.preventDefault();

    const dialogRef = this.dialog.open(
      HirlClientAgreementForceStateChangeDialogComponent,
      {
        width: '40%',
        disableClose: true,
        autoFocus: false,
        data: {
          clientAgreement: this.clientAgreement,
        } as HirlClientAgreementForceStateChangeDialogData,
      }
    );

    dialogRef.afterClosed().subscribe((result?: Company) => {
      if (!result) {
        return;
      }
      this.uiHelperService.openSnackBar('Record Successfully Updated');
    });
  }

  initiateExtensionRequest($event: MouseEvent) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '40%',
      data: {
        title:
          'Please confirm your intention to request a two-year CA extension',
        content:
          'Note that only one extension is allowed for an active Client Agreement. ' +
          'Alternatively, you may initiate a new four-year Client Agreement by clicking “Cancel” ' +
          'and selecting the “Initiate New Client Agreement” button.',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (!result) {
          return;
        }
        this.store.dispatch(toggleLoading({ payload: true }));
        this.clientAgreementService
          .initiate_extension_request(this.clientAgreement.id)
          .pipe(takeUntil(this.componentDestroyed$), first())
          .subscribe(
            _ => {
              this.store.dispatch(toggleLoading({ payload: false }));
              this.uiHelperService.openSnackBar(
                `Confirmation request to approve Extension Request successfully sent`
              );
            },
            error => this.uiHelperService.handleUserRequestError(error)
          );
      });
  }

  extensionRequestApprove($event: MouseEvent) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '40%',
      data: {
        title: 'Are you sure you want to Approve extension request?',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (!result) {
          return;
        }
        this.store.dispatch(toggleLoading({ payload: true }));
        this.clientAgreementService
          .approve_extension_request(this.clientAgreement.id)
          .pipe(takeUntil(this.componentDestroyed$), first())
          .subscribe(
            _ => {
              this.store.dispatch(toggleLoading({ payload: false }));
              this.uiHelperService.openSnackBar(
                `Extension Request successfully approved`
              );
            },
            error => this.uiHelperService.handleUserRequestError(error)
          );
      });
  }

  extensionRequestReject($event: MouseEvent) {
    $event.preventDefault();

    const dialogData: InputDialogData = new InputDialogData({
      title: 'Reject Reason',
      inputIsRequired: true,
      inputType: InputDialogInputTypeEnum.textArea,
    });
    const dialogRef: MatDialogRef<InputDialogComponent> = this.dialog.open(
      InputDialogComponent,
      {
        width: '40%',
        data: dialogData,
      }
    );

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(rejectReason => {
        if (!rejectReason) {
          return;
        }
        this.store.dispatch(toggleLoading({ payload: true }));

        this.clientAgreementService
          .reject_extension_request(this.clientAgreement.id, rejectReason)
          .pipe(takeUntil(this.componentDestroyed$), first())
          .subscribe(
            _ => {
              this.store.dispatch(toggleLoading({ payload: false }));
              this.uiHelperService.openSnackBar(
                `Extension Request successfully approved`
              );
            },
            error => this.uiHelperService.handleUserRequestError(error)
          );
      });
  }
}
