import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@/shared/shared.module';
import { CommunityListComponent } from './pages/community-list-page/community-list-page.component';
import { CommunityDetailPageComponent } from './pages/community-detail-page/community-detail-page.component';
import { CommunitySubdivisionTabComponent } from './components/community-subdivision-tab/community-subdivision-tab.component';
import { CommunitySubdivisionListComponent } from './components/community-subdivision-tab/community-subdivision-list/community-subdivision-list.component';
import { CommunityChangeDialogComponent } from './components/community-change-dialog/community-change-dialog.component';

@NgModule({
  declarations: [
    CommunityListComponent,
    CommunityDetailPageComponent,
    CommunitySubdivisionTabComponent,
    CommunitySubdivisionListComponent,
    CommunityChangeDialogComponent,
  ],
  imports: [CommonModule, SharedModule],
})
export class CommunityModule {}
