import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { first, takeUntil } from 'rxjs/operators';

import { FloorPlan } from '@/data/floorplan/models/floorplan';
import {
  FloorPlanRequestParams,
  FloorPlanService,
} from '@/data/floorplan/services/floorplan.service';
import { SimulationService } from '@/data/simulation/services/simulation.service';
import { AppState } from '@/state/reducers';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, Subject, Subscription } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import {
  FloorplanChangeDialogComponent,
  FloorplanChangeDialogData,
} from '@/modules/floorplan/components/floorplan-change-dialog/floorplan-change-dialog.component';
import { FloorplanPermissionService } from '@/modules/floorplan/services/floorplan-permission-service';
import { FloorplanFromBLGDialogComponent } from '../../components/floorplan-from-blg-dialog/floorplan-from-blg-dialog.component';
import { FloorplanFromSimulationDialogComponent } from '../../components/floorplan-from-simulation-dialog/floorplan-from-simulation-dialog.component';
import { SimulationSourceType } from '@/data/simulation/models/simulation';
import {
  SimulationSourceTypeLabelMapping,
  SimulationResidenceTypeLabelMapping,
} from '@/data/simulation/models/simulation';
@Component({
  selector: 'app-floorplan-list-page',
  templateUrl: './floorplan-list-page.component.html',
  styleUrls: ['./floorplan-list-page.component.scss'],
})
export class FloorplanListPageComponent implements OnInit, OnDestroy {
  public floorplans: FloorPlan[];
  public floorPlanCount: number;
  public floorPlanLoading = true;

  private componentDestroyed$ = new Subject();
  public filterForm: FormGroup;
  private subscription: Subscription;
  public simulationSourceTypeLabelMap = SimulationSourceTypeLabelMapping;
  public simulationResidenceTypeLabelMapping =
    SimulationResidenceTypeLabelMapping;

  public defaultParams: FloorPlanRequestParams = new FloorPlanRequestParams(
    1,
    '',
    '-id',
    25
  );
  public storedParams: FloorPlanRequestParams = Object.assign(
    new FloorPlanRequestParams(),
    this.defaultParams
  );
  public createPermissionResponse: Observable<ObjectPermissionResponse>;
  public simulationSourceType: SimulationSourceType;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private injector: Injector,
    private activatedRoute: ActivatedRoute,
    private floorplanService: FloorPlanService,
    private floorplanPermissionService: FloorplanPermissionService,
    private simulationService: SimulationService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(params => {
        this.storedParams.assignQueryParams(params);

        if (params.dialog === 'true' && params.simulationId) {
          this.openFloorplanFromSimulationDialog(params.simulationId);
        }
      });
    this.createPermissionResponse = this.floorplanPermissionService.canCreate();
    this.setupFilterForm();
    this.hydrateForm();
    this.list();
  }

  openFloorplanFromSimulationDialog(simulationId: string) {
    const dialogRef: MatDialogRef<FloorplanFromSimulationDialogComponent> =
      this.dialog.open(FloorplanFromSimulationDialogComponent, {
        width: '45%',
        data: { simulationId: simulationId }, // Pass simulation ID here
      });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      this.list();
    });
  }

  setupFilterForm() {
    this.filterForm = this.formBuilder.group({
      simulation_source: [null],
      residence_type: [null],
      simulation__version: [null],
      heater_fuel_type: [null],
      water_heater_type: [null],
      air_conditioner: [null],
      ashp: [null],
      gshp: [null],
      dehumidifier: [null],
      has_basement: [null],
      crawl_space: [null],
      has_slabs: [null],
      attic_type: [null],
      vaulted_ceilings: [null],
      num_stories: [null],
      has_photovoltaics: [null],
      search: [null],
      attached_to_home: [null],
    });

    this.filterForm.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        const params = Object.assign(this.defaultParams, values);

        this.storedParams = Object.assign(this.storedParams, params);
        this.list();
      });
  }

  resetFilters($event: MouseEvent) {
    $event.preventDefault();
    this.filterForm.reset();
  }

  list() {
    this.floorPlanLoading = true;

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.storedParams,
      replaceUrl: true,
    });

    this.subscription = this.floorplanService
      .list(this.storedParams)
      .pipe(first())
      .subscribe(data => {
        this.floorplans = data.results;
        this.floorPlanCount = data.count;
        this.floorPlanLoading = false;
      });
  }

  onFooterPage(event) {
    this.storedParams.page = event.page;
    this.list();
  }

  onSort({ sorts }) {
    this.storedParams.ordering = `${sorts[0].dir === 'desc' ? '-' : ''}${
      sorts[0].prop
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
    this.subscription.unsubscribe();
  }

  hydrateForm() {
    this.filterForm.patchValue(this.storedParams, {
      emitEvent: false,
      onlySelf: true,
    });
  }

  addFloorplan(event): void {
    const dialogData: FloorplanChangeDialogData =
      new FloorplanChangeDialogData();
    const dialogRef: MatDialogRef<FloorplanChangeDialogComponent> =
      this.dialog.open(FloorplanChangeDialogComponent, {
        width: '45%',
        data: dialogData,
      });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      this.list();
    });
  }

  addFloorplanFromBLG(event): void {
    const dialogRef: MatDialogRef<FloorplanFromBLGDialogComponent> =
      this.dialog.open(FloorplanFromBLGDialogComponent, {
        width: '45%',
      });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      this.list();
    });
  }

  addFloorplanFromSimulation(event): void {
    const dialogRef: MatDialogRef<FloorplanFromSimulationDialogComponent> =
      this.dialog.open(FloorplanFromSimulationDialogComponent, {
        width: '45%',
      });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      this.list();
    });
  }
}
