import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TaskStatusLabelMapping } from '@/data/scheduling/models';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { TaskChangeDialogData } from '@/modules/scheduling/components/change-task-dialog/change-task-dialog.component';

@Component({
  selector: 'app-change-status-dialog',
  templateUrl: './change-status-dialog.component.html',
  styleUrls: ['./change-status-dialog.component.scss'],
})
export class ChangeStatusDialogComponent implements OnInit, OnDestroy {
  public formGroup: UntypedFormGroup;
  public isLoading = false;
  public statusLabelMapping = TaskStatusLabelMapping;

  private componentDestroyed$ = new Subject();

  constructor(
    public dialogRef: MatDialogRef<ChangeStatusDialogComponent>,
    public fb: UntypedFormBuilder,
    public dialog: MatDialog,
    public uiHelperService: UIHelperService,
    @Inject(MAT_DIALOG_DATA) public data: TaskChangeDialogData
  ) {}

  ngOnInit() {
    this.setupForm();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  setupForm() {
    this.formGroup = this.fb.group({
      new_status: [null, Validators.required],
      status_annotation: [''],
    });
  }

  onConfirm($event: MouseEvent) {
    $event.preventDefault();
    this.formGroup.markAllAsTouched();
    if (this.formGroup.invalid) {
      return;
    }

    this.dialogRef.close(this.formGroup.value);
  }

  onCancel($event: MouseEvent) {
    $event.preventDefault();
    this.dialogRef.close(null);
  }
}
