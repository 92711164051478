import { ListRequestParams } from '@/core/schemes/request-params';
import { Injectable } from '@angular/core';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { AnnotationType } from '@/data/annotation/models';
import { ServerResponse } from '@/core/schemes/server-response';

export class AnnotationTypeRequestParams extends ListRequestParams {
  name?: string;
  slug?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AnnotationTypeService extends BaseDataService {
  baseUrl = `${environment.AXIS_API_V3_ROOT}/annotation_types/`;

  list(
    params: AnnotationTypeRequestParams
  ): Observable<ServerResponse<AnnotationType>> {
    return this.http.get<ServerResponse<AnnotationType>>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }

  retrieve(
    userId: number,
    options?: IDataServiceMethodOptions
  ): Observable<AnnotationType> {
    const ignoreStore: boolean = options?.ignoreStore;
    const chain: Observable<AnnotationType> = this.http.get<AnnotationType>(
      `${this.baseUrl}${userId}`,
      {}
    );
    return chain;
  }
}
