import { ListRequestParams } from '@/core/schemes/request-params';
import { Injectable } from '@angular/core';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { COIDocument } from '@/data/customer-hirl/models';

export class COIDocumentParams extends ListRequestParams {
  is_active?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class COIDocumentService extends BaseDataService {
  baseUrl = `${environment.AXIS_API_V3_ROOT}/coi_documents/`;

  retrieve(
    coiDocumentId: number,
    options?: IDataServiceMethodOptions
  ): Observable<COIDocument> {
    return this.http.get<COIDocument>(`${this.baseUrl}${coiDocumentId}`, {});
  }

  update(coiDocument: COIDocument | FormData, coiDocumentId: number) {
    return this.http.patch<COIDocument>(
      `${this.baseUrl}${coiDocumentId}/`,
      coiDocument
    );
  }

  delete(coiDocumentId: number) {
    return this.http.delete(`${this.baseUrl}${coiDocumentId}`);
  }
}
