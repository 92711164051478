<div>
    <ul>
        <li><strong>Conditioned Area</strong>: {{ simulationData?.conditioned_area }} sqft.</li>
        <li><strong>Conditioned Volume</strong>: {{ simulationData?.conditioned_volume }} cuft.</li>
        <li><strong>Bedroom Count</strong>: {{ simulationData?.bedroom_count }}</li>
        <li><strong>Floors on or Above Grade</strong>: {{ simulationData?.floors_on_or_above_grade }}</li>

        <br>
        <li><strong>Above Grade Walls</strong>
            <ul>
                <li><strong>Count:</strong> {{ simulationData?.above_grade_walls.length }}</li>
                <li><strong>Total area:</strong> {{ summaryData.totalAboveGradeWallArea }} sqft.</li>
            </ul>
        </li>
        <br>

        <li><strong>Foundation Walls Count</strong>: {{ simulationData?.foundation_walls.length }}</li>

        <br>
        <li><strong>Roofs</strong>
            <ul>
                <li><strong>Count:</strong> {{ simulationData?.roofs.length }}</li>
                <li><strong>Total area:</strong> {{ summaryData.totalRoofArea }} sqft.</li>
            </ul>
        </li>
        <br>

        <br>
        <li><strong>Frame Floors</strong>
            <ul>
                <li><strong>Count:</strong> {{ simulationData?.frame_floors.length }}</li>
                <li><strong>Total area:</strong> {{ summaryData.totalFrameFloorArea }} sqft.</li>
            </ul>
        </li>
        <br>

        <li><strong>Rim Joists</strong>
            <ul>
                <li><strong>Count:</strong> {{ simulationData?.rim_joists.length }}</li>
                <li><strong>Total area:</strong> {{ summaryData.totalRimJoistArea }} sqft.</li>
            </ul>
        </li>
        <br>

        <li><strong>Slabs</strong>
            <ul>
                <li><strong>Count:</strong> {{ simulationData?.slabs.length }}</li>
                <li><strong>Total area:</strong> {{ summaryData.totalSlabArea }} sqft.</li>
            </ul>
        </li>
        <br>

        <li><strong>Skylights</strong>
            <ul>
                <li><strong>Count:</strong> {{ simulationData?.skylights.length }}</li>
                <li><strong>Total area:</strong> {{ summaryData.totalSkylightArea }} sqft.</li>
            </ul>
        </li>
        <br>

        <li><strong>Doors Count</strong>: {{ simulationData?.doors.length }}</li>
        <li><strong>Windows Count</strong>: {{ simulationData?.windows.length }}</li>
        <li><strong>Mechanical Equipment Count</strong>: {{ simulationData?.mechanical_equipment.length }}</li>
        <li><strong>HVAC Distribution Systems Count</strong>: {{ simulationData?.hvac_distribution_systems.length }}</li>
        <li><strong>Photovoltaics Count</strong>: {{ simulationData?.photovoltaics.length }}</li>
        <li><strong>Thermostats Count</strong>: {{ simulationData?.thermostats.length }}</li>

        <br>
        <li><strong>Interior Locations</strong>:
            <ul>
                <li *ngFor="let location of summaryData.interiorLocations">{{ locationLabels[location] }}</li>
            </ul>
        </li>
        <br>

        <li><strong>Exterior Locations</strong>:
            <ul>
                <li *ngFor="let location of summaryData.exteriorLocations">{{ locationLabels[location] || location }}</li>
            </ul>
        </li>
    </ul>
</div>


