<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="row">
                <div class="col-6">
                    <h3>JAMIS Dashboard</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <hr>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-12">
                    <button mat-raised-button color="accent" (click)="exportClientOrganizations()" class="mr-2">
                        Export Client Organizations
                    </button>
                    <button mat-raised-button color="accent" (click)="onExportVerifiersClicked($event)" class="mr-2">
                        Export Verifiers
                    </button>
                    <button mat-raised-button color="accent" [matMenuTriggerFor]="exportAllProjectsMenu">Export All Projects</button>
                    <mat-menu #exportAllProjectsMenu="matMenu">
                        <button mat-menu-item (click)="onExportNGBSProjectsClicked($event)">NGBS Programs</button>
                        <button mat-menu-item  (click)="onExportWRIProjectsClicked($event)">WRI Programs</button>
                    </mat-menu>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card mb-2">
                        <div class="card-header">
                            JAMIS Export
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-5">
                                    <button mat-raised-button color="accent" [matMenuTriggerFor]="exportBillingRulesFileMenu" class="mr-2">Export Billing Rules File</button>
                                    <mat-menu #exportBillingRulesFileMenu="matMenu">
                                        <button mat-menu-item (click)="onExportNGBSProjectsBillingRuleClicked($event)">NGBS Programs</button>
                                        <button mat-menu-item (click)="onExportWRIProjectsBillingRuleClicked($event)">WRI Programs</button>
                                    </mat-menu>
                                    <button mat-raised-button color="accent" [matMenuTriggerFor]="exportMilestoneFileMenu">Export Milestone File</button>
                                    <mat-menu #exportMilestoneFileMenu="matMenu">
                                        <button mat-menu-item (click)="onExportNGBSProjectsMilestoneClicked($event)">NGBS Programs</button>
                                        <button mat-menu-item (click)="onExportWRIProjectsMilestoneClicked($event)">WRI Programs</button>
                                    </mat-menu>
                                </div>
                                <div class="col-6">
                                    <form [formGroup]="exportBillingRuleFileForm">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Export range</mat-label>
                                            <mat-date-range-input formGroupName="date_range" [rangePicker]="exportBillingRuleRangePicker" required>
                                                <input matStartDate formControlName="start" placeholder="Start date">
                                                <input matEndDate formControlName="end" placeholder="End date">
                                            </mat-date-range-input>
                                            <mat-datepicker-toggle matSuffix [for]="exportBillingRuleRangePicker"></mat-datepicker-toggle>
                                            <mat-date-range-picker #exportBillingRuleRangePicker></mat-date-range-picker>

                                            <mat-error *ngIf="exportBillingRuleFileForm.get('date_range').get('start').hasError('required') || exportBillingRuleFileForm.get('date_range').get('end').hasError('required')">
                                                Please select export date range
                                            </mat-error>
                                            <mat-error *ngIf="exportBillingRuleFileForm.get('date_range').get('start').hasError('matStartDateInvalid')">Invalid start date</mat-error>
                                            <mat-error *ngIf="exportBillingRuleFileForm.get('date_range').get('end').hasError('matEndDateInvalid')">Invalid end date</mat-error>
                                        </mat-form-field>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card mb-2">
                        <div class="card-header">
                            Green Payments Import
                        </div>
                        <div class="card-body">
                            <form [formGroup]="jamisForm" (ngSubmit)="onGreenPaymentsFormSubmit($event)" novalidate>
                                <div class="row">
                                    <div class="col-8">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <app-file-input formControlName="jamis_file" placeholder="JAMIS Import File" required></app-file-input>
                                            <mat-error *ngIf="jamisForm.get('jamis_file').hasError('required')">
                                                Please select a file
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-4">
                                        <button type="submit" class="btn btn-primary btn-block" [disabled]="jamisForm.get('jamis_file').errors">
                                            Upload
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="card mb-2">
                        <div class="card-header">
                            Project Billing Import
                        </div>
                        <div class="card-body">
                            <form [formGroup]="projectBillingForm" (ngSubmit)="onProjectBillingFormSubmit($event)" novalidate>
                                <div class="row">
                                    <div class="col-8">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <app-file-input formControlName="jamis_file" placeholder="Project Billing File" required></app-file-input>
                                            <mat-error *ngIf="projectBillingForm.get('jamis_file').hasError('required')">
                                                Please select a file
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-4">
                                        <button type="submit" class="btn btn-primary btn-block" [disabled]="projectBillingForm.get('jamis_file').errors">
                                            Upload
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
