import { CompanyTypeLabelMapping } from '@/data/company/models';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-company-select-dialog',
  templateUrl: './company-select-dialog.component.html',
  styleUrls: ['./company-select-dialog.component.scss'],
})
export class CompanySelectDialogComponent implements OnInit {
  filters = {};

  control = new FormControl();
  companyTypeLabel: any;

  companyDisplay(value) {
    return value.name;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<CompanySelectDialogComponent>
  ) {}

  ngOnInit(): void {
    this.companyTypeLabel = CompanyTypeLabelMapping[this.data.companyType];

    this.filters = {
      company_type: this.data.companyType || null,
    };

    this.control.valueChanges.subscribe(value => {
      this.dialogRef.close(value);
    });
  }
}
