import {
  DistributionSystemBackendDict,
  DetailedDistributionSystemBackendDict,
} from '@/data/simulation/models/mechanicals/DistributionSystem';
import { createAction, props } from '@ngrx/store';
import { ModelErrors, StateErrors } from '../shared/base.state';

export const loadDetailedDistributionSystems = createAction(
  '[Simulation API] Load Detailed DistributionSystems',
  props<{
    detailedDistributionSystems: DetailedDistributionSystemBackendDict[];
  }>()
);

export const loadDistributionSystemsSuccess = createAction(
  '[DistributionSystem API] Load DistributionSystems Success',
  props<{
    distributionSystems: DistributionSystemBackendDict[];
    errors: StateErrors<DistributionSystemBackendDict>;
  }>()
);

export const updateDistributionSystem = createAction(
  '[DistributionSystem UI] Update DistributionSystem',
  props<{
    id: number;
    distributionSystemChanges: Partial<DistributionSystemBackendDict>;
  }>()
);

export const updateDistributionSystemSuccess = createAction(
  '[DistributionSystem API] Update DistributionSystem Success',
  props<{
    distributionSystem: DistributionSystemBackendDict;
    errors: ModelErrors<DistributionSystemBackendDict>;
  }>()
);

export const updateDistributionSystemFailure = createAction(
  '[DistributionSystem API] Update DistributionSystem Failure',
  props<{ id: number }>()
);

export const removeDistributionSystem = createAction(
  '[DistributionSystem UI] Remove DistributionSystem',
  props<{ distributionSystem: DistributionSystemBackendDict }>()
);

export const removeDistributionSystemSuccess = createAction(
  '[DistributionSystem API] Remove DistributionSystem Success',
  props<{ id: number }>()
);

export const removeDistributionSystemFailure = createAction(
  '[DistributionSystem API] Remove DistributionSystem Failure',
  props<{ id: number }>()
);

export const updateDistributionSystemErrors = createAction(
  '[DistributionSystem UI] Update DistributionSystem Errors',
  props<{ id: number; errors: any }>()
);

export const addDuctSuccess = createAction(
  '[DistributionSystem UI] Add Duct success',
  props<{
    distributionSystemId: number;
    ductId: number;
  }>()
);

export const removeDuctSuccess = createAction(
  '[DistributionSystem UI] Remove Duct success',
  props<{
    distributionSystemId: number;
    ductId: number;
  }>()
);
