import { ModelFormControl, ModelFormGroup } from '../ModelForm';
import { Validators } from '@angular/forms';
import { DetailedSeasonalRateBackendDict } from './SeasonalRate';
import { FieldLabels } from '../base';
import { SimulationModelsState } from '@/modules/simulation/state/reducers';

export const FIELD_LABELS: FieldLabels<UtilityRateBackendDict> = {
  name: 'Name',
  fuel: 'Fuel',
  consumption_units: 'Consumption Units',
  cost_units: 'Cost Units',
  seasonal_rates: 'Seasonal Rates',
};

export interface UtilityRateBackendDict {
  id: number;
  name: string;
  fuel: string;
  consumption_units: string;
  cost_units: string;
  seasonal_rates: number[];
}

export interface DetailedUtilityRateBackendDict extends UtilityRateBackendDict {
  seasonal_rates_info: DetailedSeasonalRateBackendDict[];
}

export function createUtilityRateForm(
  data: UtilityRateBackendDict
): ModelFormGroup {
  return new ModelFormGroup({
    id: new ModelFormControl(data.id, Validators.required),
    name: new ModelFormControl(data.name, Validators.required),
    fuel: new ModelFormControl(data.fuel, Validators.required),
    consumption_units: new ModelFormControl(
      data.consumption_units,
      Validators.required
    ),
    cost_units: new ModelFormControl(data.cost_units, Validators.required),
    seasonal_rates: new ModelFormControl(
      data.seasonal_rates,
      Validators.required
    ),
  });
}

export function denormalizeUtilityrates(
  state: SimulationModelsState
): DetailedUtilityRateBackendDict[] {
  return Object.values(state.utilityRate.entities).map(utilityRate => ({
    ...utilityRate,
    seasonal_rates_info: utilityRate.seasonal_rates.map(
      id => state.seasonalRate[id]
    ),
  }));
}
