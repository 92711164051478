import { Injectable } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormGroup,
  ValidatorFn,
} from '@angular/forms';
import { ConfirmedGeocodeResponse } from '@/modules/geocoder/components/confirm-geocode-response-dialog/confirm-geocode-response';

@Injectable({
  providedIn: 'root',
})
export class AppFieldValidatorsService {
  websiteURLValidator(errorNamespace = 'websiteURL'): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const regex = new RegExp(
        '^(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?$'
      );
      const valid = regex.test(control.value);
      return valid ? null : { [errorNamespace]: true };
    };
  }

  /**
   * Using to check if at least one field in form have value. Usefully for checkboxes list
   *
   * Usage example:
   * this.formGroup = this.fb.group({
   *    field1: [null, ],
   *    field2: [null, ],
   * }, {
   *    validators: atLeastOneHasValueValidator(['field1', 'field2'])
   * });
   */
  atLeastOneHasValueValidator(
    fields: Array<string>,
    errorNamespace = 'atLeastOneHasValue'
  ): ValidatorFn {
    return (group: UntypedFormGroup) => {
      for (const fieldName of fields) {
        if (group.get(fieldName).value) {
          return null;
        }
      }
      return { [errorNamespace]: true };
    };
  }

  /**
   * this.fb.group({
   * }, { validators: [
   *   this.appValidationService.uniqueConfirmedGeocodeResponseValidator(['address', 'letter_address', 'phases->address']),
   * ]}
   *         )
   * @param fields - list of fields. Support FormArray with `->` separator
   * @param errorNamespace - error key
   */
  uniqueConfirmedGeocodeResponseValidator(
    fields: string[],
    errorNamespace = 'uniqueConfirmedGeocodeResponse'
  ): ValidatorFn {
    return (group: UntypedFormGroup) => {
      let responses: ConfirmedGeocodeResponse[] = [];

      for (const fieldNameStr of fields) {
        const [fieldName, formGroupFieldName] = fieldNameStr.split('->');

        if (group.get(fieldName) instanceof UntypedFormArray) {
          const formArray = group.get(fieldName) as UntypedFormArray;
          for (const control of formArray.controls) {
            responses.push(control.value[formGroupFieldName]);
          }
        } else {
          if (group.get(fieldName).value) {
            responses.push(group.get(fieldName).value);
          }
        }
      }
      responses = responses.filter(response => response != null);
      if (responses.length < 2) {
        return null;
      }

      const hasDuplicates = responses.some(response => {
        return (
          responses.filter(response2 =>
            response.addressInfo.isEqual(response2.addressInfo)
          ).length > 1
        );
      });

      return hasDuplicates ? { [errorNamespace]: true } : null;
    };
  }
}
