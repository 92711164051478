import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@/shared/shared.module';
import { MessageDropdownComponent } from '@/modules/messaging/components/message-dropdown/message-dropdown.component';
import { MessageSnackbarComponent } from './components/message-snackbar/message-snackbar.component';

@NgModule({
  declarations: [MessageDropdownComponent, MessageSnackbarComponent],
  exports: [MessageDropdownComponent],
  imports: [CommonModule, SharedModule],
})
export class MessagingModule {}
