import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyListPageComponent } from './pages/company-list-page/company-list-page.component';
import { CompanyDetailPageComponent } from './pages/company-detail-page/company-detail-page.component';
import { SharedModule } from '@/shared/shared.module';
import { CompanyMiscTabComponent } from './components/company-misc-tab/company-misc-tab.component';
import { CompanyUsersTabComponent } from './components/company-users-tab/company-users-tab.component';
import { CompanyCountiesTabComponent } from './components/company-counties-tab/company-counties-tab.component';
import { FilehandlingModule } from '@/modules/filehandling/filehandling.module';
import { CompanyAlternateNameListComponent } from './components/company-alternate-name-list/company-alternate-name-list.component';
import { CompanyAlternateNameCreateDialogComponent } from './components/company-alternate-name-list/company-alternate-name-create-dialog/company-alternate-name-create-dialog.component';
import { CompanyAffiliationsListComponent } from './components/company-affiliations-list/company-affiliations-list.component';
import { CompanySponsoringListTabComponent } from './components/company-sponsoring-list-tab/company-sponsoring-list-tab.component';
import { CompanyCoiDocumentTabComponent } from './components/company-coi-document-tab/company-coi-document-tab.component';
import { CoiHelpDialogComponent } from './components/coi-help-dialog/coi-help-dialog.component';
import { CompanyCoiDocumentListComponent } from './components/company-coi-document-tab/company-coi-list/company-coi-document-list.component';
import { GeocoderModule } from '@/modules/geocoder/geocoder.module';
import { CompanyContactCardTabComponent } from './components/company-contact-card-tab/company-contact-card-tab.component';
import { CustomerHIRLModule } from '@/modules/customer-hirl/customer-hirl.module';
import { SponsorPreferencesCreatePageComponent } from './pages/sponsor-preferences-create-page/sponsor-preferences-create-page.component';
import { ChangeCompanyAccessDialogComponent } from './components/change-company-access-dialog/change-company-access-dialog.component';
import { UserModule } from '@/modules/user/user.module';
import { CompanyChangeDialogComponent } from './components/company-change-dialog/company-change-dialog.component';
import { CompanyEditMiscDialogComponent } from './components/company-edit-misc-dialog/company-edit-misc-dialog.component';
import { CompanyEquipmentTabComponent } from './components/company-equipment-tab/company-equipment-tab.component';
import { CompanyRemrateUsersTabComponent } from './components/company-remrate-users-tab/company-remrate-users-tab.component';
import { AnnotationModule } from '@/modules/annotation/annotation.module';
import { ProductModule } from '@/modules/product/product.module';

@NgModule({
  declarations: [
    CompanyListPageComponent,
    CompanyDetailPageComponent,
    CompanyMiscTabComponent,
    CompanyUsersTabComponent,
    CompanyCountiesTabComponent,
    CompanyAlternateNameListComponent,
    CompanyAlternateNameCreateDialogComponent,
    CompanyAffiliationsListComponent,
    CompanySponsoringListTabComponent,
    CompanyCoiDocumentTabComponent,
    CoiHelpDialogComponent,
    CompanyCoiDocumentListComponent,
    CompanyContactCardTabComponent,
    SponsorPreferencesCreatePageComponent,
    ChangeCompanyAccessDialogComponent,
    CompanyChangeDialogComponent,
    CompanyEditMiscDialogComponent,
    CompanyEquipmentTabComponent,
    CompanyRemrateUsersTabComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FilehandlingModule,
    GeocoderModule,
    CustomerHIRLModule,
    UserModule,
    AnnotationModule,
    ProductModule,
  ],
  exports: [],
})
export class CompanyModule {}
