import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { environment } from '../../../../environments/environment';
import { FrontendLog } from '@/data/core/models/frontend_log';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FrontendLoggerService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/frontend_logger/`;

  constructor(private http: HttpClient, private store: Store<AppState>) {}

  create(frontendLog: FrontendLog): Observable<FrontendLog> {
    const payload = Object.assign(new FrontendLog(), frontendLog);
    return this.http.post<FrontendLog>(`${this.baseUrl}`, payload);
  }
}
