import { createReducer, on } from '@ngrx/store';
import * as SkylightTypeActions from './actions';
import { SkylightTypeBackendDict } from '@/data/simulation/models/enclosure/SkylightType';
import { BaseState } from '../shared/base.state';
import { addStateErrorCount } from '../helper';

export type SkylightTypeState = BaseState<SkylightTypeBackendDict>;

export const initialState: SkylightTypeState = {
  entities: {},
  loading: {},
  errors: {},
};

export const reducer = createReducer(
  initialState,
  on(
    SkylightTypeActions.loadSkylightTypesSuccess,
    (state, { skylightTypes, errors }) => {
      const entities = skylightTypes.reduce(
        (acc, skylightType) => ({ ...acc, [skylightType.id]: skylightType }),
        {}
      );
      const loading = skylightTypes.reduce(
        (acc, skylightType) => ({ ...acc, [skylightType.id]: false }),
        {}
      );
      return {
        entities: { ...state.entities, ...entities },
        loading: { ...state.loading, ...loading },
        errors: { ...state.errors, ...addStateErrorCount(errors) },
      };
    }
  ),

  on(
    SkylightTypeActions.updateSkylightType,
    (state, { skylightTypeChanges }) => ({
      ...state,
      loading: { ...state.loading, [skylightTypeChanges.id]: true },
    })
  ),

  on(SkylightTypeActions.updateSkylightTypeFailure, (state, { id }) => ({
    ...state,
    loading: { ...state.loading, [id]: false },
  })),

  on(SkylightTypeActions.removeSkylightTypeSuccess, (state, { id }) => {
    const { [id]: removed, ...entities } = state.entities;
    const { [id]: removedLoading, ...loading } = state.loading;
    const { [id]: removedErrors, ...errors } = state.errors;
    return { entities, loading, errors };
  })
);
