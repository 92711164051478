import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { catchError, first, map } from 'rxjs/operators';
import { UserService } from '@/data/core/services/user.service';
import { User } from '@/data/core/models/user';
import { ImpersonationUser } from '@/data/core/models/impersonation_user';

@Injectable({
  providedIn: 'root',
})
export class UserPermissionService {
  constructor(public router: Router, public userService: UserService) {}

  canEdit(user: User | number): Observable<ObjectPermissionResponse> {
    let user$: Observable<User>;
    if (typeof user === 'number') {
      user$ = this.userService.retrieve(user, {
        ignoreStore: true,
      });
    } else {
      user$ = of(user);
    }

    return forkJoin({
      impersonationUser: this.userService.info().pipe(first()),
      user: user$,
    }).pipe(
      map(({ impersonationUser, user }) => {
        if (
          user.id === impersonationUser.user.id ||
          impersonationUser.user.is_superuser
        ) {
          return new ObjectPermissionResponse(true);
        }

        if (user.company === impersonationUser.user.company) {
          if (impersonationUser.user.is_company_admin) {
            return new ObjectPermissionResponse(true);
          }
        }

        return new ObjectPermissionResponse(false);
      }),
      catchError(err => of(new ObjectPermissionResponse(false)))
    );
  }

  canChangePassword(user: User | number): Observable<ObjectPermissionResponse> {
    let user$: Observable<User>;
    if (typeof user === 'number') {
      user$ = this.userService.retrieve(user, {
        ignoreStore: true,
      });
    } else {
      user$ = of(user);
    }

    return forkJoin({
      impersonationUser: this.userService.info().pipe(first()),
      user: user$,
    }).pipe(
      map(
        ({
          impersonationUser,
          user,
        }: {
          impersonationUser: ImpersonationUser;
          user: User;
        }) => {
          if (user.id === impersonationUser.user.id) {
            return new ObjectPermissionResponse(true);
          }

          return new ObjectPermissionResponse(false);
        }
      )
    );
  }
}
