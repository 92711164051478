import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BaseDataService } from '@/core/services/base-data-service';
import { Observable } from 'rxjs';
import { AppliancesBackendDict } from '../models/Appliances';

@Injectable({ providedIn: 'root' })
export class AppliancesService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/appliances/`;

  update(
    id: number,
    appliancesTypeChanges: Partial<AppliancesBackendDict>
  ): Observable<AppliancesBackendDict> {
    return this.http.put<AppliancesBackendDict>(
      `${this.baseUrl}${id}/`,
      appliancesTypeChanges
    );
  }

  delete(id: number) {
    return this.http.delete(`${this.baseUrl}${id}/`);
  }
}
