import { ListRequestParams } from '@/core/schemes/request-params';
import { Injectable } from '@angular/core';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import {
  ClientAgreement,
  CreateClientAgreementWithoutDocuSign,
  CreateClientAgreementWithoutUser,
} from '@/data/customer-hirl/models';
import { ServerResponse } from '@/core/schemes/server-response';
import { tap } from 'rxjs/operators';
import {
  loadClientAgreement,
  loadClientAgreementDocuments,
  loadClientAgreementDocumentsCount,
} from '@/state/actions/customer-hirl/client-agreement.actions';
import { CustomerDocumentRequestParams } from '@/data/filehandling/services/customer-document.service';
import { CustomerDocument } from '@/data/filehandling/models';

export class ClientAgreementParams extends ListRequestParams {}

@Injectable({
  providedIn: 'root',
})
export class ClientAgreementService extends BaseDataService {
  baseUrl = `${environment.AXIS_API_V3_ROOT}/client_agreements/`;

  list(
    params?: ClientAgreementParams
  ): Observable<ServerResponse<ClientAgreement>> {
    return this.http.get<ServerResponse<ClientAgreement>>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }

  retrieve(
    clientAgreementId: number,
    options?: IDataServiceMethodOptions
  ): Observable<ClientAgreement> {
    const ignoreStore = options?.ignoreStore;
    let chain = this.http.get<ClientAgreement>(
      `${this.baseUrl}${clientAgreementId}`,
      {}
    );
    if (!ignoreStore) {
      chain = chain.pipe(
        tap(clientAgreement =>
          this.store.dispatch(loadClientAgreement({ payload: clientAgreement }))
        )
      );
    }
    return chain;
  }

  create(
    clientAgreement: ClientAgreement | FormData,
    options?: IDataServiceMethodOptions
  ): Observable<ClientAgreement> {
    const ignoreStore: boolean = options?.ignoreStore;
    let chain: Observable<ClientAgreement> = this.http.post<ClientAgreement>(
      `${this.baseUrl}`,
      clientAgreement
    );
    if (!ignoreStore) {
      chain = chain.pipe(
        tap((newClientAgreement: ClientAgreement) =>
          this.store.dispatch(
            loadClientAgreement({ payload: newClientAgreement })
          )
        )
      );
    }
    return chain;
  }

  update(
    clientAgreement: ClientAgreement | FormData,
    clientAgreementId: number,
    options?: IDataServiceMethodOptions
  ): Observable<ClientAgreement> {
    const ignoreStore = options?.ignoreStore;
    let chain: Observable<ClientAgreement> = this.http.patch<ClientAgreement>(
      `${this.baseUrl}${clientAgreementId}/`,
      clientAgreement
    );
    if (!ignoreStore) {
      chain = chain.pipe(
        tap((newClientAgreement: ClientAgreement) =>
          this.store.dispatch(
            loadClientAgreement({ payload: newClientAgreement })
          )
        )
      );
    }
    return chain;
  }

  delete(clientAgreementId: number) {
    return this.http.delete(`${this.baseUrl}${clientAgreementId}`);
  }

  approve(
    clientAgreementId: number,
    options?: IDataServiceMethodOptions
  ): Observable<ClientAgreement> {
    const ignoreStore = options?.ignoreStore;
    let chain = this.http.post<ClientAgreement>(
      `${this.baseUrl}${clientAgreementId}/approve/`,
      {}
    );
    if (!ignoreStore) {
      chain = chain.pipe(
        tap(clientAgreement =>
          this.store.dispatch(loadClientAgreement({ payload: clientAgreement }))
        )
      );
    }
    return chain;
  }

  verify(
    clientAgreementId: number,
    options?: IDataServiceMethodOptions
  ): Observable<ClientAgreement> {
    const ignoreStore = options?.ignoreStore;
    let chain = this.http.post<ClientAgreement>(
      `${this.baseUrl}${clientAgreementId}/verify/`,
      {}
    );
    if (!ignoreStore) {
      chain = chain.pipe(
        tap(clientAgreement =>
          this.store.dispatch(loadClientAgreement({ payload: clientAgreement }))
        )
      );
    }
    return chain;
  }

  create_without_docusign(
    formData: CreateClientAgreementWithoutDocuSign | FormData
  ): Observable<ClientAgreement> {
    return this.http.post<ClientAgreement>(
      `${this.baseUrl}create_without_docusign/`,
      formData
    );
  }

  create_without_user(
    formData: CreateClientAgreementWithoutUser | FormData
  ): Observable<ClientAgreement> {
    return this.http.post<ClientAgreement>(
      `${this.baseUrl}create_without_user/`,
      formData
    );
  }

  resend_docusign_email(clientAgreementId: number): Observable<boolean> {
    return this.http.post<boolean>(
      `${this.baseUrl}${clientAgreementId}/resend_docusign_email/`,
      {}
    );
  }

  update_docusign_status(
    clientAgreementId: number,
    options?: IDataServiceMethodOptions
  ): Observable<ClientAgreement> {
    const ignoreStore = options?.ignoreStore;
    let chain: Observable<ClientAgreement> = this.http.post<ClientAgreement>(
      `${this.baseUrl}${clientAgreementId}/update_docusign_status/`,
      {}
    );
    if (!ignoreStore) {
      chain = chain.pipe(
        tap(clientAgreement =>
          this.store.dispatch(loadClientAgreement({ payload: clientAgreement }))
        )
      );
    }
    return chain;
  }

  documents(
    objectId: number | string,
    params?: CustomerDocumentRequestParams
  ): Observable<ServerResponse<CustomerDocument>> {
    return super.documents(objectId, params).pipe(
      tap(serverResponse =>
        this.store.dispatch(
          loadClientAgreementDocuments({ payload: serverResponse.results })
        )
      ),
      tap(serverResponse =>
        this.store.dispatch(
          loadClientAgreementDocumentsCount({ payload: serverResponse.count })
        )
      )
    );
  }

  force_state(
    clientAgreement: ClientAgreement | FormData,
    clientAgreementId: number,
    options?: IDataServiceMethodOptions
  ): Observable<ClientAgreement> {
    const ignoreStore = options?.ignoreStore;
    let chain: Observable<ClientAgreement> = this.http.patch<ClientAgreement>(
      `${this.baseUrl}${clientAgreementId}/force_state/`,
      clientAgreement
    );
    if (!ignoreStore) {
      chain = chain.pipe(
        tap((newClientAgreement: ClientAgreement) =>
          this.store.dispatch(
            loadClientAgreement({ payload: newClientAgreement })
          )
        )
      );
    }
    return chain;
  }

  initiate_extension_request(
    clientAgreementId: number,
    options?: IDataServiceMethodOptions
  ): Observable<ClientAgreement> {
    const ignoreStore = options?.ignoreStore;
    let chain = this.http.post<ClientAgreement>(
      `${this.baseUrl}${clientAgreementId}/initiate_extension_request/`,
      {}
    );
    if (!ignoreStore) {
      chain = chain.pipe(
        tap(clientAgreement =>
          this.store.dispatch(loadClientAgreement({ payload: clientAgreement }))
        )
      );
    }
    return chain;
  }

  approve_extension_request(
    clientAgreementId: number,
    options?: IDataServiceMethodOptions
  ): Observable<ClientAgreement> {
    const ignoreStore = options?.ignoreStore;
    let chain = this.http.post<ClientAgreement>(
      `${this.baseUrl}${clientAgreementId}/approve_extension_request/`,
      {}
    );
    if (!ignoreStore) {
      chain = chain.pipe(
        tap(clientAgreement =>
          this.store.dispatch(loadClientAgreement({ payload: clientAgreement }))
        )
      );
    }
    return chain;
  }

  reject_extension_request(
    clientAgreementId: number,
    rejectReason: string,
    options?: IDataServiceMethodOptions
  ): Observable<ClientAgreement> {
    const ignoreStore = options?.ignoreStore;
    let chain = this.http.post<ClientAgreement>(
      `${this.baseUrl}${clientAgreementId}/reject_extension_request/`,
      {
        reason: rejectReason,
      }
    );
    if (!ignoreStore) {
      chain = chain.pipe(
        tap(clientAgreement =>
          this.store.dispatch(loadClientAgreement({ payload: clientAgreement }))
        )
      );
    }
    return chain;
  }
}
