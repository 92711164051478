import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BaseDataService } from '@/core/services/base-data-service';
import { Observable } from 'rxjs';
import { MechanicalVentilationBackendDict } from '../models/systems/MechanicalVentilation';

@Injectable({ providedIn: 'root' })
export class MechanicalVentilationService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/mechanical_ventilation/`;

  update(
    mechanicalVentilation: Partial<MechanicalVentilationBackendDict>
  ): Observable<MechanicalVentilationBackendDict> {
    return this.http.patch<MechanicalVentilationBackendDict>(
      `${this.baseUrl}${mechanicalVentilation.id}/`,
      mechanicalVentilation
    );
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}${id}/`);
  }
}
