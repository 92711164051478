<h2 mat-dialog-title>
  <ng-container *ngIf="!isEditForm">
    Create New <b>Product Group</b>
  </ng-container>
  <ng-container *ngIf="isEditForm">
    Edit <b>Product Group</b>
  </ng-container>
</h2>
<mat-dialog-content *ngIf="initialized">
  <form [formGroup]="form" class="mb-2">
    <div class="row" *ngIf="canSeeField['manufacturer_info']">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Product Manufacturer</mat-label>
          <app-generic-model-autocomplete modelClassName="company" formControlName="manufacturer_info"  [filterParams]="{company_type: 'manufacturer'}"></app-generic-model-autocomplete>
          <mat-error *ngIf="form.get('manufacturer_info').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <ng-container *ngIf="this.isEditForm || !this.isEditForm && form.get('manufacturer_info').value">
      <div class="row" *ngIf="canSeeField['title']">
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>Product Name</mat-label>
            <input matInput type="text" placeholder="Product Name" formControlName="title">
            <mat-error *ngIf="form.get('title').hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="pl-2 text-muted">
            Description:
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12">
          <quill-editor formControlName="description" style="display: block;" (onContentChanged)="onDescriptionChanged($event)" [modules]="quillModules"></quill-editor>
          <mat-error *ngIf="form.get('description').hasError('maxlength') && form.get('description').touched" class="error">
            Maximum length of 500 characters exceeded!
          </mat-error>
          <p>Characters left: {{ this.descriptionRemainingCharacters || 0 }}</p>
        </div>
      </div>
      <div class="row" *ngIf="canSeeField['categories_info']">
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>Product Category</mat-label>
            <app-generic-mat-select modelClassName="category" formControlName="categories_info"></app-generic-mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row" *ngIf="canSeeField['company_brands_info']">
        <div class="col-11">
          <mat-form-field class="w-100">
            <mat-label>Brand(s)</mat-label>
            <app-generic-model-chips modelClassName="company_brand" formControlName="company_brands_info" [filterParams]="{'company': entity?.manufacturer || form.get('manufacturer_info')?.value?.id || currentUser.company }" [initialValueIds]="entity?.company_brands"></app-generic-model-chips>
          </mat-form-field>
        </div>
        <div class="col-1 text-center">
          <button mat-button [color]="'accent'" (click)="createCompanyBrand($event);" class="mt-2" matTooltip="Create New Brand" style="min-width: auto; padding: 0;">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mat-form-field class="mb-2 w-100">
            <mat-label>Manufacturer’s Website</mat-label>
            <input matInput type="text" placeholder="Manufacturer’s Website" formControlName="website_url">
            <mat-hint>
              For example: www.example.com or https://www.example.com
            </mat-hint>
            <mat-error *ngIf="!(form.controls['website_url'].hasError('websiteURLValidator'))">
              Incorrect url format. Example: www.example.com or https://www.example.com
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mat-form-field class="mb-2 w-100">
            <mat-label>Product Specifications</mat-label>
            <input matInput type="text" placeholder="Product Specifications" formControlName="specifications_url">
            <mat-hint>
              Link to product specifications. For example: www.example.com or https://www.example.com
            </mat-hint>
            <mat-error *ngIf="!(form.controls['specifications_url'].hasError('websiteURLValidator'))">
              Incorrect url format. Example: www.example.com or https://www.example.com
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row" *ngIf="canSeeField['is_featured']">
        <div class="col-12">
          <mat-form-field class="mb-2 w-100">
            <mat-label>Featured Product</mat-label>
            <mat-select
              formControlName="is_featured"
              placeholder="Featured Product"
            >
              <mat-option [value]="true">Yes</mat-option>
              <mat-option [value]="false">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </ng-container>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button color="accent" mat-raised-button (click)="save($event)">Save</button>
  <button mat-button [mat-dialog-close]="null" color="primary" mat-raised-button>Cancel</button>
</mat-dialog-actions>
