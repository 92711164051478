import { createReducer, on } from '@ngrx/store';
import * as AnnotationActions from '@/state/actions/annotation/annotation.actions';
import { Annotation } from '@/data/annotation/models';

export interface AnnotationState {
  entities: { [id: number]: { [id: number]: Annotation } };
  loading: { [id: number]: { [id: number]: boolean } };
  errors: { [id: number]: any };
  initialLoading: boolean;
}

export const initialState: AnnotationState = {
  entities: {},
  loading: {},
  errors: {},
  initialLoading: false,
};

export const annotationStateKey = 'annotation';

export const reducer = createReducer(
  initialState,
  on(AnnotationActions.loadAnnotations, (state, { simulationId }) => {
    const key = 'simulation' + simulationId;
    return {
      ...state,
      initialLoading: true,
    };
  }),

  on(AnnotationActions.loadAnnotationsSuccess, (state, { annotations }) => {
    const entities = {};
    const loading = {};

    Object.entries(state.entities).forEach(([key, value]) => {
      entities[key] = { ...value };
    });

    Object.entries(state.loading).forEach(([key, value]) => {
      loading[key] = { ...value };
    });

    annotations.forEach(annotation => {
      const key = annotation.content_type + annotation.object_id;
      if (entities[key]) {
        entities[key][annotation.id] = annotation;
        loading[key][annotation.id] = false;
      } else {
        entities[key] = {
          [annotation.id]: annotation,
        };
        loading[key] = {
          [annotation.id]: false,
        };
      }
    });

    return {
      entities: {
        ...state.entities,
        ...entities,
      },
      loading: {
        ...state.loading,
        ...loading,
      },
      errors: {},
      initialLoading: false,
    };
  }),

  on(AnnotationActions.updateAnnotation, (state, { annotation }) => ({
    ...state,
    loading: {
      ...state.loading,
      [annotation.content_type + annotation.object_id]: {
        ...state.loading[annotation.content_type + annotation.object_id],
        [annotation.id]: true,
      },
    },
  })),

  on(AnnotationActions.updateAnnotationSuccess, (state, { annotation }) => {
    const entities = {};
    const loading = {};

    Object.entries(state.entities).forEach(([key, value]) => {
      entities[key] = { ...value };
    });

    Object.entries(state.loading).forEach(([key, value]) => {
      loading[key] = { ...value };
    });

    entities[annotation.content_type + annotation.object_id][annotation.id] =
      annotation;
    loading[annotation.content_type + annotation.object_id][annotation.id] =
      false;

    return {
      entities: {
        ...state.entities,
        ...entities,
      },
      loading: {
        ...state.loading,
        ...loading,
      },
      errors: {},
      initialLoading: false,
    };
  }),

  on(AnnotationActions.updateAnnotationFailure, (state, { id }) => ({
    ...state,
    loading: {
      ...state.loading,
    },
  })),

  on(
    AnnotationActions.deleteAnnotation,
    (state, { content_type, object_id, annotationId }) => ({
      ...state,
      loading: {
        ...state.loading,
        [content_type + object_id]: {
          ...state.loading[content_type + object_id],
          [annotationId]: true,
        },
      },
    })
  ),

  on(
    AnnotationActions.deleteAnnotationSuccess,
    (state, { content_type, object_id, annotationId }) => {
      const entities = {};
      const loading = {};
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      content_type = content_type.replaceAll('_', '');

      Object.entries(state.entities).forEach(([key, value]) => {
        entities[key] = { ...value };
      });

      Object.entries(state.loading).forEach(([key, value]) => {
        loading[key] = { ...value };
      });

      delete entities[content_type + object_id][annotationId];
      delete loading[content_type + object_id][annotationId];

      return {
        entities: {
          ...state.entities,
          ...entities,
        },
        loading: {
          ...state.loading,
          ...loading,
        },
        errors: {},
        initialLoading: false,
      };
    }
  )
);
