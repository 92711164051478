import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SimulationConfig } from '../models/SimulationConfig';

@Injectable({ providedIn: 'root' })
export class SimulationConfigService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/simulation_config/`;
  public companySimulationConfigUrl = `${environment.AXIS_API_V3_ROOT}/companies/`;

  constructor(public http: HttpClient) {}

  update(
    id: number,
    simulationConfig: Partial<SimulationConfig>
  ): Observable<SimulationConfig> {
    return this.http.patch<any>(`${this.baseUrl}${id}/`, simulationConfig);
  }

  getSimulationConfig(id: number): Observable<SimulationConfig> {
    return this.http.get<any>(`${this.baseUrl}${id}/`);
  }

  getCompanySimulationConfig(companyId): Observable<SimulationConfig> {
    return this.http.get<SimulationConfig>(
      `${this.companySimulationConfigUrl}${companyId}/simulation_config/`
    );
  }
}
