import {
  Component,
  ContentChildren,
  QueryList,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormGroup, ControlContainer } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectFormStatus } from '@/modules/simulation/state/form/selectors';
import {
  activateForm,
  createForm,
  deactivateForm,
  removeForm,
} from '@/modules/simulation/state/form/actions';
import { Subject } from 'rxjs';
import { ForeignModelFieldComponent } from '../../fields/foreign-model-field/foreign-model-field.component';
import { ModelFormGroup } from '@/data/simulation/models/ModelForm';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent {
  @ContentChildren(ForeignModelFieldComponent, { descendants: true })
  foreignModelFields!: QueryList<ForeignModelFieldComponent>;

  @Input() formName = 'unknown';
  @Input() editingDisabled = false;
  @Output() save = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Input() dedicatedSaveButton = true;

  formGroup: ModelFormGroup;
  isInEditMode = false;
  memento = {};
  componentDestroyed$ = new Subject();

  formId: string;
  actionButtonOnTop: any;
  saveInProgress = false;
  constructor(public controlContainer: ControlContainer, public store: Store) {}

  ngOnInit() {
    this.formGroup = this.controlContainer.control as ModelFormGroup;
    let entityId = null;
    if (this.formGroup.get('id')) {
      entityId = this.formGroup.get('id').value as number;
    } else {
      entityId = this.generateId();
    }

    this.formId = `${this.formName}.${entityId}`;

    this.store.dispatch(createForm({ formId: this.formId }));
    this.store
      .select(selectFormStatus(this.formId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(status => {
        if (!status) return;
        // TODO: Find a better way to identify wether to call autosave or not.
        if (!status.active && this.isInEditMode && !this.saveInProgress) {
          this.autoSaveChanges();
        }

        this.isInEditMode = status.active;
        this.toggleElements(status.active);
        this.saveInProgress = false;
      });
  }

  toggleElements(status: boolean) {
    if (status) {
      this.formGroup.markEditable();
    } else {
      this.formGroup.markUneditable();
    }

    if (this.foreignModelFields) {
      this.foreignModelFields.forEach(field => {
        field.isInEditMode = status;
      });
    }
  }

  enableEditMode(event: MouseEvent) {
    this.store.dispatch(activateForm({ formId: this.formId }));
    this.memento = this.formGroup.value;

    setTimeout(() => {
      const elBeneath = document.elementFromPoint(
        event.clientX,
        event.clientY
      ) as HTMLElement;
      if (elBeneath) {
        elBeneath.click();
      }
    });
  }

  _getChanges() {
    const newValues = this.formGroup.value;
    const changes = {};
    Object.entries(this.memento).forEach(([key, val]) => {
      if (val !== newValues[key]) changes[key] = newValues[key];
    });
    return changes;
  }

  autoSaveChanges() {
    const changes = this._getChanges();

    if (Object.keys(changes).length > 0) {
      this.save.emit({ id: this.formId.split('.')[1], ...changes });
    }
  }

  saveChanges(e: Event) {
    e.stopPropagation();
    const changes = this._getChanges();
    if (changes) {
      this.save.emit({ id: this.formId.split('.')[1], ...changes });
    }
    this.saveInProgress = true;
    this.store.dispatch(deactivateForm({ formId: this.formId }));
  }

  cancelChanges(e: Event) {
    // Reset the form values.
    this.formGroup.markUneditable();
    e.stopPropagation();
    this.store.dispatch(deactivateForm({ formId: this.formId }));
    (<FormGroup>this.controlContainer.control).patchValue(this.memento);
    this.cancel.emit();
  }

  generateId() {
    return Math.floor(Math.random() * 9000000000) + 1000000000;
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
    this.store.dispatch(removeForm({ formId: this.formId }));
  }
}
