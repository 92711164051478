<div class="container-lg" *ngIf="initialized">
  <div class="row">
    <div class="col-12">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#" [routerLink]="['/', ]">Home</a></li>
          <li class="breadcrumb-item"><a href="#" [routerLink]="['/', 'home', 'apex', 'request', 'resnet-qa-list']">APEX RESNET QA Requests</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            APEX RESNET QA Request
          </li>
        </ol>
      </nav>
    </div>
  </div>
  <div class="d-flex-column mb-2">
    <div class="d-flex flex-row">
      <div class="flex-fill align-self-end">
        <h3 class="mb-0">
          APEX RESNET QA Request #{{ apexRequest.id }}
        </h3>
      </div>
    </div>
    <mat-divider class="mt-1 mb-2"></mat-divider>
    <div class="d-flex flex-row">
      <div class="d-flex-column flex-fill">
        <div class="d-flex flex-row">
          <div class="apex-request-attr-name">
            Analysis Type:
          </div>
          <div class="flex-fill">
            {{ ApexRequestTypeLabelMapping[apexRequest.request_type] }}
          </div>
        </div>
        <div class="d-flex flex-row">
          <div class="apex-request-attr-name">
            Section:
          </div>
          <div class="flex-fill">
            {{ RESENTQATypeLabelMapping[apexRequest.resnet_qa_type] }}
          </div>
        </div>
        <div class="d-flex flex-row">
          <div class="apex-request-attr-name">
            Created by:
          </div>
          <div class="flex-fill">
            <app-user-display [user]="apexRequest.user_info" displayType="onlyNameAsLink"></app-user-display>
          </div>
        </div>
        <div class="d-flex flex-row mb-5">
          <div class="apex-request-attr-name">
            Created at:
          </div>
          <div class="flex-fill">
            {{ apexRequest.created_at | date : "MM/dd/yyyy" }}
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <mat-tab-group color="accent" style="min-height: 450px;" disableRipple="true" animationDuration="0ms"
                     dynamicHeight (selectedTabChange)="onTabChange($event)" [selectedIndex]="selectedIndex">
        <mat-tab label="Users">
          <ng-template mat-tab-label>
            Documents
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" class="ml-1"
                     *ngIf="apexRequestDocumentsCount === -1"></fa-icon>
            <span class="badge badge-primary ml-1" *ngIf="apexRequestDocumentsCount !== -1">
                            {{ apexRequestDocumentsCount }}
                        </span>
          </ng-template>
          <div class="mt-2 mb-5">
            <app-apex-request-document-list></app-apex-request-document-list>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
