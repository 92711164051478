import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { HIRLFindVerifier } from '@/data/core/models/user';
import { AccreditationInfo } from '@/data/user-management/models';

export interface NgbsFindVerifierDetailDialogData {
  verifier: HIRLFindVerifier;
}

@Component({
  selector: 'app-ngbs-find-verifier-detail-dialog',
  templateUrl: './ngbs-find-verifier-detail-dialog.component.html',
  styleUrls: ['./ngbs-find-verifier-detail-dialog.component.scss'],
})
export class NgbsFindVerifierDetailDialogComponent implements OnInit {
  verifier: HIRLFindVerifier;

  constructor(
    public dialogRef: MatDialogRef<NgbsFindVerifierDetailDialogComponent>,
    public store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) public data: NgbsFindVerifierDetailDialogData
  ) {
    this.verifier = data.verifier;
  }

  ngOnInit(): void {}

  findAccreditation(name: string, accreditationsInfo?: AccreditationInfo[]) {
    const accreditations = accreditationsInfo?.filter(accreditationInfo => {
      return accreditationInfo.name === name;
    });

    if (accreditations) {
      return accreditations[0];
    }
    return null;
  }

  closeClick(): void {
    this.dialogRef.close();
  }
}
