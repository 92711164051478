import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { first, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getInfoUser } from '@/state/selectors/info.selector';
import { AppState } from '@/state/reducers';
import { CompanyType } from '@/data/company/models';
import { SimulationService } from '@/data/simulation/services/simulation.service';
import { Simulation, SimulationList } from '@/data/simulation/models';
import {
  EEPProgramHomeStatus,
  EEPProgramHomeStatusState,
} from '@/data/home/models/eep_program_home_status';

@Injectable({
  providedIn: 'root',
})
export class SimulationPermissionService {
  constructor(
    public store: Store<AppState>,
    public simulationService: SimulationService
  ) {}

  canCreate(): Observable<ObjectPermissionResponse> {
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
    }).pipe(
      map(({ currentUser }) => {
        if (currentUser.is_superuser) {
          return new ObjectPermissionResponse(true);
        }
        if (!currentUser.is_company_admin) {
          return new ObjectPermissionResponse(
            false,
            'Only Company Admin users can Create new Simulations'
          );
        }
        if (currentUser.company_info.company_type === CompanyType.rater) {
          return new ObjectPermissionResponse(true);
        }
        if (currentUser.company_info.company_type === CompanyType.provider) {
          return new ObjectPermissionResponse(true);
        }
        return new ObjectPermissionResponse(
          false,
          'You do not have permissions to create new Simulation'
        );
      })
    );
  }

  canEdit({
    simulation,
    certifiedHomeStatusExists,
  }: {
    simulation: Simulation | SimulationList | number;
    certifiedHomeStatusExists?: boolean;
  }): Observable<ObjectPermissionResponse> {
    let simulation$: Observable<Simulation | SimulationList>;
    let simulationId;
    if (typeof simulation === 'number') {
      simulationId = simulation;
      simulation$ = this.simulationService.oldRetrieve(simulation, {
        ignoreStore: true,
      });
    } else {
      simulationId = simulation.id;
      simulation$ = of(simulation);
    }

    let certifiedHomeStatusExists$: Observable<boolean>;
    if (typeof certifiedHomeStatusExists === 'undefined') {
      certifiedHomeStatusExists$ = this.simulationService
        .getObject(simulationId, 'home_project_status')
        .pipe(
          map(response =>
            response['results'].find(
              (home_status: EEPProgramHomeStatus) =>
                home_status['state'] === EEPProgramHomeStatusState.complete
            )
          )
        );
    } else {
      certifiedHomeStatusExists$ = of(certifiedHomeStatusExists);
    }

    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: simulation$,
      certifiedHomeStatusExists: certifiedHomeStatusExists$,
    }).pipe(
      map(({ currentUser, entity, certifiedHomeStatusExists }) => {
        if (certifiedHomeStatusExists) {
          return new ObjectPermissionResponse(
            false,
            'Simulation attached to certified Home'
          );
        }

        if (
          currentUser.is_superuser !== true &&
          entity?.company !== currentUser?.company_info?.id
        ) {
          return new ObjectPermissionResponse(
            false,
            'Your are not owner of this simulation'
          );
        }
        return new ObjectPermissionResponse(true);
      })
    );
  }

  canDelete({
    simulation,
    certifiedHomeStatusExists,
  }: {
    simulation: Simulation | SimulationList | number;
    certifiedHomeStatusExists?: boolean;
  }): Observable<ObjectPermissionResponse> {
    return this.canEdit({
      simulation: simulation,
      certifiedHomeStatusExists: certifiedHomeStatusExists,
    });
  }
}
