<div class="container-lg" *ngIf="initialized">
  <div class="row">
    <div class="col-12">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#" [routerLink]="['/', ]">Home</a></li>
          <li class="breadcrumb-item"><a href="#" [routerLink]="['/', 'hi', 'payment', 'request', 'list']">Payment Requests</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Payment Request #{{ entity.id }}
          </li>
        </ol>
      </nav>
    </div>
  </div>
  <div class="d-flex-column mb-2">
    <div class="d-flex flex-row">
      <div class="flex-fill align-self-end">
        <h3 class="mb-0">
          Payment Request #{{ entity.id }}
          <span
            class="badge ml-2 {{ PaymentRequestStateLabelMapping[entity.state]?.badgeClass }}">{{ PaymentRequestStateLabelMapping[entity.state]?.label }}</span>
        </h3>
      </div>
      <div class="d-flex text-right">
        <app-permission-mat-button
          [permissionResponse]="approvePermissionResponse"
          (action)="onApprove($event)"
          [hideOnFalse]="true"
          class="mr-1">
          Approve
        </app-permission-mat-button>
        <app-permission-mat-button
          [permissionResponse]="rejectPermissionResponse"
          (action)="onReject($event)"
          [hideOnFalse]="true"
          class="mr-1">
          Reject
        </app-permission-mat-button>

        <app-history-mat-button [objectId]="entity.id" [modelClassName]="'gcp_payment_request'" class="mr-1">
          History
        </app-history-mat-button>
        <app-permission-mat-button [permissionResponse]="editPermissionResponse" (action)="onEdit($event)">
          Edit
        </app-permission-mat-button>
      </div>
    </div>
    <mat-divider class="mt-1 mb-2"></mat-divider>
    <div class="d-flex flex-row">
      <div class="d-flex-column flex-fill">
        <div class="d-flex flex-row">
          <div class="payment-request-attr-name">AXIS ID:</div>
          <div class="flex-fill">{{ entity.id }}</div>
        </div>
        <div class="mt-2"></div>
        <div class="d-flex flex-row">
          <div class="payment-request-attr-name">Request Type:</div>
          <div class="flex-fill">{{ PaymentRequestTypeLabelMapping[entity.request_type] }}</div>
        </div>
        <div class="mt-2"></div>
        <div class="d-flex flex-row">
          <div class="payment-request-attr-name">Total Price:</div>
          <div class="flex-fill">
            {{ entity.total | currency }}
          </div>
        </div>
        <div class="mt-2"></div>
        <div class="d-flex flex-row">
          <div class="payment-request-attr-name">Manufacturer:</div>
          <div class="flex-fill">
            <app-company-display [company]="entity.manufacturer_info"
                                 displayType="onlyNameAsLink"></app-company-display>
          </div>
        </div>
        <div class="mt-2"></div>
        <div class="d-flex flex-row">
          <div class="payment-request-attr-name">Invoice:</div>
          <div class="flex-fill">
            <a [routerLink]="['/', 'hi', 'invoices', entity.invoice_info?.prefixed_id]">{{ entity.invoice_info?.prefixed_id }}</a>
          </div>
        </div>
      </div>
      <div class="d-flex-row">

      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <mat-tab-group color="accent" style="min-height: 450px;" disableRipple="true" animationDuration="0ms"
                     dynamicHeight (selectedTabChange)="onTabChange($event)" [selectedIndex]="selectedIndex">
        <mat-tab label="Products" *ngIf="entity.request_type === PaymentRequestType.fullYearSubscription || entity.request_type === PaymentRequestType.insertNewProduct">
          <ng-template mat-tab-label>
            Product Groups
            <span class="badge badge-primary ml-1" *ngIf="paymentRequestProductsCount !== -1">
                {{ paymentRequestProductsCount }}
            </span>
          </ng-template>
          <div class="mt-2 mb-5">
            <app-payment-request-products-tab (onCreated)="onChangePaymentRequestProduct()" (onDeleted)="onChangePaymentRequestProduct()"></app-payment-request-products-tab>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
