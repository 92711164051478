<div class="container-lg" *ngIf="initialized">
  <div class="row">
    <div class="col-12">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="#" [routerLink]="['/', ]">Home</a>
          </li>
          <li class="breadcrumb-item">
            <a href="#" [routerLink]="['/', 'hi', 'product', 'list']">Product Groups</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Product Group: {{ entity.title }}
          </li>
        </ol>
      </nav>
    </div>
  </div>
  <div class="d-flex-column mb-2">
    <div class="d-flex flex-row">
      <div class="flex-fill align-self-end">
        <h3 class="mb-0">
          <button mat-raised-button appBackButton color="accent">
            <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
          </button>
          Product Group: {{ (entity.title.length > 50) ? (entity.title | slice:0:50) + '...' : (entity.title) }}
          <span
            class="badge ml-2 {{ ProductStatusLabelMapping[entity.status]?.badgeClass }}">{{ ProductStatusLabelMapping[entity.status]?.label }}</span>
        </h3>
      </div>
      <div class="d-flex text-right">
        <app-permission-mat-button
          [permissionResponse]="publishPermissionResponse"
          (action)="onPublish($event)"
          [hideOnFalse]="true"
          class="mr-1">
          Certify
        </app-permission-mat-button>
        <app-permission-mat-button
          [permissionResponse]="archivePermissionResponse"
          (action)="onArchive($event)"
          [hideOnFalse]="true"
          class="mr-1">
          Archive
        </app-permission-mat-button>
        <app-history-mat-button [objectId]="entity.id" [modelClassName]="'product'" class="mr-1">
          History
        </app-history-mat-button>
        <app-permission-mat-button [permissionResponse]="editPermissionResponse" (action)="onEdit($event)">
          Edit
        </app-permission-mat-button>
      </div>
    </div>
    <mat-divider class="mt-1 mb-2"></mat-divider>
    <div class="d-flex flex-row">
      <div class="d-flex-column flex-fill">
        <div class="d-flex flex-row">
          <div class="product-attr-name">AXIS ID:</div>
          <div class="flex-fill">{{ entity.id }}</div>
        </div>
        <div class="mt-2"></div>
        <div class="d-flex flex-row">
          <div class="product-attr-name">Name:</div>
          <div class="flex-fill">{{ entity.title }}</div>
        </div>
        <div class="mt-2"></div>
        <div class="d-flex flex-row">
          <div class="product-attr-name">Brand(s):</div>
          <div class="flex-fill">
            <ng-container
              *ngFor="
                  let companyBrand of entity.company_brands_info;
                  let isLast = last
                "
            >
              {{ companyBrand.title }}
              {{ isLast ? "" : ", " }}
            </ng-container>
          </div>
        </div>
        <div class="mt-2"></div>
        <div class="d-flex flex-row">
          <div class="product-attr-name">Category:</div>
          <div class="flex-fill">
            <ng-container
              *ngFor="
                  let category of entity.categories_info;
                  let isLast = last
                "
            >
              {{ category.title }}
              {{ isLast ? "" : ", " }}
            </ng-container>
          </div>
        </div>
        <div class="mt-2"></div>
        <div class="d-flex flex-row">
          <div class="product-attr-name">Description:</div>
          <div class="flex-fill" [innerHTML]="entity?.description | safe: 'text'">
          </div>
        </div>
        <div class="mt-2"></div>
        <div class="d-flex flex-row">
          <div class="product-attr-name">Manufacturer:</div>
          <div class="flex-fill">
            <app-company-display [company]="entity.manufacturer_info"
                                 displayType="onlyNameAsLink"></app-company-display>
          </div>
        </div>
        <div class="mt-2"></div>
        <div class="d-flex flex-row">
          <div class="product-attr-name">Manufacturer’s Website:</div>
          <div class="flex-fill">
            <a href="{{ entity?.website_url }}" target="_blank">{{ (entity.website_url?.length > 50) ? (entity.website_url | slice:0:50) + '...' : (entity.website_url) }}</a>
          </div>
        </div>
        <div class="mt-2"></div>
        <div class="d-flex flex-row">
          <div class="product-attr-name">Product Specifications:</div>
          <div class="flex-fill">
            <a href="{{ entity?.specifications_url }}" target="_blank">{{ (entity.specifications_url?.length > 50) ? (entity.specifications_url | slice:0:50) + '...' : (entity.specifications_url) }}</a>
          </div>
        </div>
        <div class="mt-2"></div>
        <div class="d-flex flex-row">
          <div class="product-attr-name">Featured Product:</div>
          <div class="flex-fill">
            {{ entity?.is_featured | yesNo }}
          </div>
        </div>
        <div class="mt-2"></div>
        <div class="d-flex flex-row">
          <div class="product-attr-name">Certification Date:</div>
          <div class="flex-fill">
            {{ entity.hirl_certificate_approval_date | date: 'MM/dd/yyyy' }}
          </div>
        </div>
        <div class="mt-2"></div>
        <div class="d-flex flex-row">
          <div class="product-attr-name">Expiration Date:</div>
          <div class="flex-fill">
            {{ entity.hirl_certificate_expiration_date | date: 'MM/dd/yyyy' }}
          </div>
        </div>
        <div class="mt-2"></div>
        <div class="d-flex flex-row">
          <div class="product-attr-name">Certificate Number:</div>
          <div class="flex-fill">
            {{ entity.hirl_certificate_number || '-' }}
            <a href="{{ entity.customer_hirl_gcp_certificate_link }}" *ngIf="entity.customer_hirl_gcp_certificate_link" target="_blank" class="mr-1">
              Download
            </a>
            <app-copy-to-clipboard-button [value]="entity.customer_hirl_gcp_certificate_link" matTooltip="Copy shareable link" *ngIf="entity.customer_hirl_gcp_certificate_link">
              <fa-icon [icon]="['fas', 'link']"></fa-icon>
            </app-copy-to-clipboard-button>
          </div>
        </div>
      </div>
      <div class="d-flex-row">
        <div style="width: 320px;">
          <owl-carousel-o [options]="carouselCustomOptions" style="height: 220px;">
            <ng-template carouselSlide *ngFor="let slide of carouselSlides">
              <img [src]="slide.img" alt="Image">
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <mat-tab-group color="accent" style="min-height: 450px;" disableRipple="true" animationDuration="0ms"
                     dynamicHeight (selectedTabChange)="onTabChange($event)" [selectedIndex]="selectedIndex">
        <mat-tab label="Associated NGBS Practices">
          <ng-template mat-tab-label>
            Associated NGBS Practices
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" class="ml-1"
                     *ngIf="productPracticeItemCount === -1"></fa-icon>
            <span class="badge badge-primary ml-1" *ngIf="productPracticeItemCount !== -1">{{ productPracticeItemCount }}</span>
          </ng-template>
          <div class="mt-2 mb-5">
            <app-product-practice-items-tab></app-product-practice-items-tab>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            Images
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" class="ml-1"
                     *ngIf="productImageCount === -1"></fa-icon>
            <span class="badge badge-primary ml-1" *ngIf="productImageCount !== -1">{{ productImageCount }}</span>
          </ng-template>
          <div class="mt-2 mb-5">
            <app-product-product-images-tab></app-product-product-images-tab>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            Payment Requests
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" class="ml-1"
                     *ngIf="entityPaymentRequestsCount === -1"></fa-icon>
            <span class="badge badge-primary ml-1" *ngIf="entityPaymentRequestsCount !== -1">{{ entityPaymentRequestsCount }}</span>
          </ng-template>
          <div class="mt-2 mb-5">
            <app-product-payment-requests-tab></app-product-payment-requests-tab>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            Documents
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" class="ml-1"
                     *ngIf="entityDocumentsCount === -1"></fa-icon>
            <span class="badge badge-primary ml-1" *ngIf="entityDocumentsCount !== -1">
                            {{ entityDocumentsCount }}
                        </span>
          </ng-template>
          <div class="mt-2 mb-5">
            <app-customer-document-tab [modelClassName]="'product'" [objectId]="entity.id"></app-customer-document-tab>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            Notes
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" class="ml-1"
                     *ngIf="entityNotesCount === -1"></fa-icon>
            <span class="badge badge-primary ml-1" *ngIf="entityNotesCount !== -1">
                            {{ entityNotesCount }}
                        </span>
          </ng-template>
          <div class="mt-2 mb-5">
            <app-annotation-tab [annotationTypeSlug]="'note'" [modelClassName]="'product'" [objectId]="entity.id"></app-annotation-tab>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
