import { SimulationBackendDict } from '@/data/simulation/models/simulation';
import { LocationLabels } from '@/data/simulation/enumerations';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { selectSimulationFeature } from '../../state/simulation.selectors';
import { SimulationModelsState } from '../../state/reducers';

@Component({
  selector: 'app-simulation-summary',
  templateUrl: './simulation-summary.component.html',
  styleUrls: ['./simulation-summary.component.scss'],
})
export class SimulationSummaryComponent implements OnInit {
  simulationData: SimulationBackendDict;
  componentDestroyed$ = new Subject();

  locationLabels = LocationLabels;

  summaryData: {
    totalAboveGradeWallArea: number;
    totalAboveGradeWallRValue: number;
    totalAboveGradeWallUValue: number;
    totalAboveGradeWallCost: number;
    interiorLocations: Set<string>;
    exteriorLocations: Set<string>;
    exteriorColors: Set<string>;
    totalRoofArea: number;
    totalFrameFloorArea: number;
    totalSlabArea: number;
    totalRimJoistArea: number;
    totalSkylightArea: number;
  };

  constructor(public store: Store) {
    this.initSummaryData();
  }

  initSummaryData() {
    this.summaryData = {
      totalAboveGradeWallArea: 0,
      totalAboveGradeWallRValue: 0,
      totalAboveGradeWallUValue: 0,
      totalAboveGradeWallCost: 0,
      interiorLocations: new Set<string>(),
      exteriorLocations: new Set<string>(),
      exteriorColors: new Set<string>(),
      totalRoofArea: 0,
      totalFrameFloorArea: 0,
      totalSlabArea: 0,
      totalRimJoistArea: 0,
      totalSkylightArea: 0,
    };
  }

  updateSummaryData(state: SimulationModelsState) {
    this.initSummaryData();

    for (const wall of Object.values(state.aboveGradeWall.entities)) {
      this.summaryData['totalAboveGradeWallArea'] += wall.area;
      wall.interior_location &&
        this.summaryData.interiorLocations.add(
          wall.interior_location as unknown as string
        );
      wall.exterior_location &&
        this.summaryData.exteriorLocations.add(
          wall.exterior_location as unknown as string
        );
      this.summaryData.exteriorColors.add(
        wall.exterior_color as unknown as string
      );
    }

    for (const wall of Object.values(state.foundationWall.entities)) {
      wall.interior_location &&
        this.summaryData.interiorLocations.add(
          wall.interior_location as unknown as string
        );
      wall.exterior_location &&
        this.summaryData.exteriorLocations.add(
          wall.exterior_location as unknown as string
        );
    }

    for (const roof of Object.values(state.roof.entities)) {
      this.summaryData.totalRoofArea += roof.roof_area || 0;
      roof.interior_location &&
        this.summaryData.interiorLocations.add(
          roof.interior_location as unknown as string
        );
      roof.exterior_location &&
        this.summaryData.exteriorLocations.add(
          roof.exterior_location as unknown as string
        );
    }

    for (const floor of Object.values(state.frameFloor.entities)) {
      this.summaryData.totalFrameFloorArea += floor.area;
      floor.interior_location &&
        this.summaryData.interiorLocations.add(
          floor.interior_location as unknown as string
        );
      floor.exterior_location &&
        this.summaryData.exteriorLocations.add(
          floor.exterior_location as unknown as string
        );
    }

    for (const slab of Object.values(state.slab.entities)) {
      this.summaryData.totalSlabArea += slab.area;
      slab.interior_location &&
        this.summaryData.interiorLocations.add(
          slab.interior_location as unknown as string
        );
    }

    for (const rimJoist of Object.values(state.rimJoist.entities)) {
      this.summaryData.totalRimJoistArea += rimJoist.area;
      rimJoist.interior_location &&
        this.summaryData.interiorLocations.add(
          rimJoist.interior_location as unknown as string
        );
      rimJoist.exterior_location &&
        this.summaryData.exteriorLocations.add(
          rimJoist.exterior_location as unknown as string
        );
    }

    for (const skylight of Object.values(state.skylight.entities)) {
      this.summaryData.totalSkylightArea += skylight.area;
    }
  }

  ngOnInit(): void {
    this.store
      .select(selectSimulationFeature)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(state => {
        this.simulationData =
          state.simulation.entities[state.simulation.simulationId];
        this.updateSummaryData(state);
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
