import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppState } from '@/state/reducers';
import { Store } from '@ngrx/store';
import { AuthCookie } from '@/core/services/auth-cookies-handler';
import { getMenu } from '@/state/selectors/menu.selector';
import { Menu } from '@/data/core/models/menu';
import { Observable } from 'rxjs';
import { AuthenticationService } from '@/core/services/authentication.service';
import { ImpersonationUser } from '@/data/core/models/impersonation_user';
import { getInfo, getInfoUser } from '@/state/selectors/info.selector';
import { User } from '@/data/core/models/user';
import { DomService } from '@/core/services/dom.service';
import { first } from 'rxjs/operators';
import { UserService } from '@/data/core/services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { SuperuserCompanyAccessDialogComponent } from '@/modules/auth/components/superuser-company-access-dialog/superuser-company-access-dialog.component';
import { UserChangeCompanyDialogComponent } from '@/modules/auth/components/company-change-dialog/user-change-company-dialog.component';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
  public menu$: Observable<Menu[]>;
  public authData$: Observable<ImpersonationUser>;
  public activeMenuItem: string;

  public searchInputWidth = 90;

  public currentUser$: Observable<User>;

  public hostname = window.location.hostname;

  constructor(
    public store: Store<AppState>,
    public domService: DomService,
    private dialog: MatDialog,
    public router: Router,
    public authenticationService: AuthenticationService,
    public userService: UserService,
    public authCookie: AuthCookie
  ) {
    this.currentUser$ = this.store.select(getInfoUser);
    this.menu$ = this.store.select(getMenu);
    this.authData$ = this.store.select(getInfo);
  }

  ngOnInit() {}

  search(value) {
    this.router.navigate(['/core/search/'], { queryParams: { q: value } });
  }

  onLogout(event) {
    event.preventDefault();

    this.authenticationService
      .logout()
      .pipe(first())
      .subscribe(_ => {
        this.router.navigate(['/']);
      });
  }

  onToggleBeta($event: MouseEvent) {
    $event.preventDefault();

    this.currentUser$.pipe(first()).subscribe(currentUser => {
      this.userService
        .update({ show_beta: !currentUser.show_beta }, currentUser.id)
        .pipe(first())
        .subscribe(_ => {
          window.location.href = '/';
        });
    });
  }

  showChangeCompanySuperuserDialog($event: MouseEvent) {
    $event.preventDefault();
    this.dialog.open(SuperuserCompanyAccessDialogComponent, {
      width: '45%',
      autoFocus: false,
      data: {},
    });
  }

  showChangeCompanyDialog($event: MouseEvent) {
    $event.preventDefault();
    this.dialog.open(UserChangeCompanyDialogComponent, {
      width: '45%',
      autoFocus: false,
      data: {},
    });
  }
}
