import { CustomerDocumentInfo } from '@/data/filehandling/models';
import { CompanyInfo } from '@/data/company/models';
import { BrandInfo } from '@/data/product/models/brand';
import { CategoryInfo } from '@/data/product/models/category';
import { ProductImageInfo } from '@/data/product/models/product-image';
import { CompanyBrandInfo } from '@/data/product/models/company-brand';
import {
  COIDocument,
  HIRLProductAgreementInfo,
  PaymentRequest,
} from '@/data/customer-hirl/models';

export enum ProductStatus {
  draft = 'draft',
  active = 'active',
  archived = 'archived',
  expired = 'expired',
}

export const ProductStatusLabelMapping: Record<ProductStatus, any> = {
  [ProductStatus.draft]: {
    label: 'Draft',
    badgeClass: 'badge-warning',
  },
  [ProductStatus.active]: {
    label: 'Active',
    badgeClass: 'badge-success',
  },
  [ProductStatus.archived]: {
    label: 'Archived',
    badgeClass: 'badge-danger',
  },
  [ProductStatus.expired]: {
    label: 'Expired',
    badgeClass: 'badge-danger',
  },
};

export enum ProductType {
  hirlProduct = 'hirl_product',
  default = 'default',
}

export const ProductTypeMapping: Record<ProductType, any> = {
  [ProductType.hirlProduct]: 'HIRL Product',
  [ProductType.default]: 'Default',
};

export class Product {
  id?: number;
  title?: string;
  product_type?: ProductType;
  thumbnail_info?: CustomerDocumentInfo;
  description?: string;
  manufacturer?: number;
  manufacturer_info?: CompanyInfo;
  categories?: number[];
  categories_info?: CategoryInfo[];
  brands?: number[];
  brands_info?: BrandInfo[];
  company_brands?: number[];
  company_brands_info?: CompanyBrandInfo[];
  status?: ProductStatus;

  is_featured?: boolean;
  website_url?: string;
  specifications_url?: string;

  hirl_certificate_approval_date?: string;
  hirl_certificate_expiration_date?: string;
  hirl_certificate_number?: string;

  practice_item_product_count?: number;
  product_fee_payment_request?: PaymentRequest;
  paid_non_expired_payment_request?: PaymentRequest;
  payment_request_initial_fee?: PaymentRequest;
  paid_non_expired_initial_fee?: PaymentRequest;
  product_agreement?: HIRLProductAgreementInfo;
  countersigned_agreement?: HIRLProductAgreementInfo;
  manufacturer_general_liability_coi?: COIDocument;
  not_expired_manufacturer_general_liability_coi?: COIDocument;
  customer_hirl_gcp_certificate_link?: string;
}

export class ProductInfo {
  id?: number;
  title?: string;
  thumbnail_info?: CustomerDocumentInfo;
  description?: string;
  manufacturer?: number;
  manufacturer_info?: CompanyInfo;
  categories?: number[];
  categories_info?: CategoryInfo[];
  brands?: number[];
  brands_info?: BrandInfo[];
  company_brands?: number[];
  company_brands_info?: CompanyBrandInfo[];
  status?: ProductStatus;
}

export class ProductList {
  id?: number;
  title?: string;
  product_type?: ProductType;
  thumbnail_info?: ProductImageInfo;
  status?: ProductStatus;
}

export class CustomerHIRLProductList {
  id?: number;
  title?: string;
  product_type?: ProductType;
  thumbnail_info?: ProductImageInfo;
  status?: ProductStatus;

  hirl_certificate_expiration_date?: string;

  practice_item_product_count?: number;
  product_fee_payment_request?: PaymentRequest;
  paid_non_expired_payment_request?: PaymentRequest;
  payment_request_initial_fee?: PaymentRequest;
  paid_non_expired_initial_fee?: PaymentRequest;
  product_agreement?: HIRLProductAgreementInfo;
  countersigned_agreement?: HIRLProductAgreementInfo;

  manufacturer_general_liability_coi?: COIDocument;
  not_expired_manufacturer_general_liability_coi?: COIDocument;

  practices_number?: string[];
}

export class ProductBulkPublish {
  select_all?: boolean;
  products?: number[];
}

export class CustomerHIRLGCPProductReportDownload {}

export class GCPProductCertificateMultipleDownload {
  products?: number[];
  select_all?: boolean;
}
