import { Component, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { toggleLoading } from '@/state/actions/app.actions';
import { first, map, takeUntil } from 'rxjs/operators';
import { forkJoin, Subject } from 'rxjs';
import { User } from '@/data/core/models/user';
import { UntypedFormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '@/data/core/services/user.service';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { Accreditation } from '@/data/user-management/models/accreditation';
import { getInfoUser } from '@/state/selectors/info.selector';
import { AxisFlatPageService } from '@/data/core/services/axis_flat_page';
import { AxisFlatPage } from '@/data/core/models';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';
import { AccreditationRequestParams } from '@/data/user-management/services/accreditation.service';
import { CompanyService } from '@/data/company/services/company-base.service';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-hirl-verifier-resources-page',
  templateUrl: './hirl-verifier-resources-page.component.html',
  styleUrls: ['./hirl-verifier-resources-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HirlVerifierResourcesPageComponent implements OnInit {
  public initialized = false;
  public currentUser: User;
  public verifier: User;
  public activeAccreditations: Accreditation[] = [];
  public allAccreditations: Accreditation[] = [];
  public verifierResourcesPage: AxisFlatPage;
  public customerHIRLSettings = CustomerHIRLSettings;

  public params$ = new Subject();

  private componentDestroyed$ = new Subject();

  constructor(
    public fb: UntypedFormBuilder,
    public store: Store<AppState>,
    public injector: Injector,
    public router: Router,
    public companyService: CompanyService,
    public dialog: MatDialog,
    public activatedRoute: ActivatedRoute,
    public userService: UserService,
    public axisFlatPageService: AxisFlatPageService,
    public uiHelperService: UIHelperService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(toggleLoading({ payload: true }));

    this.params$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(({ userId, year }) => {
        userId = userId ? userId : this.currentUser.id;
        year = year ? year : null;
        const sources: { [k: string]: any } = {
          verifier: this.userService.retrieve(userId),
          allAccreditations: this.userService
            .accreditations(userId)
            .pipe(map(response => response.results)),
          activeAccreditations: this.userService
            .accreditations(
              userId,
              Object.assign(new AccreditationRequestParams(), {
                is_expired: false,
              })
            )
            .pipe(map(response => response.results)),
          verifierResourcesPage: year
            ? this.axisFlatPageService.verifier_archives_page(year)
            : this.axisFlatPageService.verifier_resources_page(),
        };

        forkJoin(sources).subscribe(
          ({
            verifier,
            activeAccreditations,
            allAccreditations,
            verifierResourcesPage,
          }) => {
            this.verifier = verifier;
            this.allAccreditations = allAccreditations;
            this.activeAccreditations = activeAccreditations;
            this.verifierResourcesPage = verifierResourcesPage;

            this.initialized = true;
            setTimeout(() => {
              // remove accreditation blocks from our content
              const availableAccreditationNames = this.activeAccreditations.map(
                accreditation => {
                  return accreditation.name as string;
                }
              );

              document
                .querySelectorAll('[data-accreditation]')
                .forEach(block => {
                  const accreditationName =
                    block.getAttribute('data-accreditation');
                  if (
                    !availableAccreditationNames.includes(accreditationName)
                  ) {
                    block.remove();
                  }
                });

              // remove service logos
              if (this.allAccreditations.length) {
                const mostRecentAccreditation = _.sortBy(
                  this.allAccreditations,
                  accreditation => {
                    return new Date(accreditation.date_initial);
                  }
                );

                const dateInitial = moment(
                  mostRecentAccreditation[0].date_initial
                );
                const today = moment();
                const yearsOfService = today.diff(dateInitial, 'years');
                const yearsOfServiceMap = {
                  1: {
                    start: 10,
                    end: 15,
                  },
                  2: {
                    start: 15,
                    end: 100,
                  },
                };

                document
                  .querySelectorAll('[data-years-of-service-key]')
                  .forEach(block => {
                    const yearsOfServiceKey = block.getAttribute(
                      'data-years-of-service-key'
                    );
                    const requiredYearsOfService =
                      yearsOfServiceMap[yearsOfServiceKey];
                    if (
                      !(requiredYearsOfService.start <= yearsOfService) ||
                      !(yearsOfService < requiredYearsOfService.end)
                    ) {
                      block.remove();
                    }
                  });
              } else {
                document
                  .querySelectorAll('[data-years-of-service-key]')
                  .forEach(block => {
                    block.remove();
                  });
              }
              this.store.dispatch(toggleLoading({ payload: false }));
            }, 1);
          },
          error => this.uiHelperService.handleMainRequestError(error)
        );
      });

    this.store
      .select(getInfoUser)
      .pipe(first())
      .subscribe(currentUser => {
        this.currentUser = currentUser;
        this.activatedRoute.params
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(params => {
            this.params$.next(params);
          });
      });
  }
}
