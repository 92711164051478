import {
  NaturalVentilationBackendDict,
  createNaturalVentilationForm,
} from '@/data/simulation/models/systems/NaturalVentilation';
import { BaseValidator } from './base.validator';
import { ModelErrors, StateErrors } from '../state/shared/base.state';

export class NaturalVentilationValidator extends BaseValidator {
  static validateForm(
    naturalVentilation: NaturalVentilationBackendDict
  ): ModelErrors<NaturalVentilationBackendDict> {
    const form = createNaturalVentilationForm(naturalVentilation);
    return form.getFieldAndModelErros();
  }

  static validate(
    naturalVentilations: NaturalVentilationBackendDict[]
  ): StateErrors<NaturalVentilationBackendDict> {
    const errors = {};
    naturalVentilations.forEach(naturalVentilation => {
      errors[naturalVentilation.id] = this.validateForm(naturalVentilation);
    });
    return errors;
  }
}
