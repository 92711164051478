import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  EEPProgramHomeStatus,
  EEPProgramHomeStatusInfo,
} from '@/data/home/models';
import {
  EEPProgramHomeStatusState,
  EEPProgramHomeStatusStateLabelMapping,
  HIRLEEPProgramHomeStatusStateLabelMapping,
} from '@/data/home/models/eep_program_home_status';
import { HIRLProjectEEPProgramHomeStatusInfo } from '@/data/customer-hirl/models';

export enum EepProgramHomeStatusStateDisplayType {
  badge = 'badge',
  flat = 'flat',
}

@Component({
  selector: 'app-eep-program-home-status-state',
  templateUrl: './eep-program-home-status-state.component.html',
  styleUrls: ['./eep-program-home-status-state.component.scss'],
})
export class EepProgramHomeStatusStateComponent implements OnInit {
  @Input() homeStatus:
    | EEPProgramHomeStatus
    | EEPProgramHomeStatusInfo
    | HIRLProjectEEPProgramHomeStatusInfo;
  @Input() display: EepProgramHomeStatusStateDisplayType =
    EepProgramHomeStatusStateDisplayType.flat;

  @ViewChild('stateDescription') public stateDescriptionRef: TemplateRef<any>;
  public stateLabelMapping = Object.assign(
    EEPProgramHomeStatusStateLabelMapping,
    HIRLEEPProgramHomeStatusStateLabelMapping
  );
  public homeStatusState = EEPProgramHomeStatusState;
  public stateDisplay = EepProgramHomeStatusStateDisplayType;

  ngOnInit(): void {}
}
