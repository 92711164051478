export class ETOAccountInfo {
  id?: number;
  company?: number;
  account_number?: number;
  ccb_number?: number;
}

export class ETOAccount {
  id?: number;
  company?: number;
  account_number?: number;
  ccb_number?: number;
}
