import { AirConditionerBackendDict } from '@/data/simulation/models/mechanicals/AirConditioner';
import { createAction, props } from '@ngrx/store';
import { StateErrors } from '../shared/base.state';

export const loadAirConditioners = createAction(
  '[AirConditioner] Load AirConditioners',
  props<{ airConditioners: AirConditionerBackendDict[] }>()
);

export const loadAirConditionersSuccess = createAction(
  '[AirConditioner] Load AirConditioners Success',
  props<{
    airConditioners: AirConditionerBackendDict[];
    errors: StateErrors<AirConditionerBackendDict>;
  }>()
);

export const loadAirConditionersFailure = createAction(
  '[AirConditioner] Load AirConditioners Failure',
  props<{ error: any }>()
);

export const updateAirConditioner = createAction(
  '[AirConditioner] Update AirConditioner',
  props<{ airConditionerChanges: Partial<AirConditionerBackendDict> }>()
);

export const updateAirConditionerFailure = createAction(
  '[AirConditioner API] Update AirConditioner Failure',
  props<{ id: number }>()
);
