import { ListRequestParams } from '@/core/schemes/request-params';
import { Injectable } from '@angular/core';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import { ServerResponse } from '@/core/schemes/server-response';
import { UserRaterRole } from '@/data/core/models';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';

export class UserRaterRoleRequestParams extends ListRequestParams {
  user?: number;
}

@Injectable({ providedIn: 'root' })
export class UserRaterRoleService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/user_rater_roles/`;

  list(params: UserRaterRoleRequestParams) {
    return this.http.get<ServerResponse<UserRaterRole>>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }

  retrieve(
    userId: number,
    options?: IDataServiceMethodOptions
  ): Observable<UserRaterRole> {
    const ignoreStore = options?.ignoreStore;
    return this.http.get<UserRaterRole>(`${this.baseUrl}${userId}`, {});
  }
}
