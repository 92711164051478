<app-loading-overlay *ngIf="loading"></app-loading-overlay>
<app-form formName="heaterFormGroup" [formGroup]="heaterFormGroup" (save)="onSave($event)">
    <div>
        <app-input formControlName="name" [label]="fieldLabels.name"></app-input>
    </div>
    <div>
        <app-select formControlName="system_type" [label]="fieldLabels.system_type" [items]="heatingSystemTypeLabels"></app-select>
        <app-select formControlName="fuel" [label]="fieldLabels.fuel" [items]="fuelTypeLabels"></app-select>
    </div>

    <div>
        <app-input *ngIf="!heaterFormGroup?.controls.auto_size_capacity.value" formControlName="capacity" type="number" [label]="fieldLabels.capacity"></app-input>
        <app-select *ngIf="!heaterFormGroup?.controls.auto_size_capacity.value" formControlName="capacity_unit" [label]="fieldLabels.capacity_unit" [items]="capacityUnitLabels"></app-select>
        <app-checkbox formControlName="auto_size_capacity" [label]="fieldLabels.auto_size_capacity"></app-checkbox>
    </div>

    <div>
        <app-input formControlName="efficiency" type="number" [label]="fieldLabels.efficiency"></app-input>
        <app-select formControlName="efficiency_unit" [label]="fieldLabels.efficiency_unit" [items]="heatingEfficiencyUnitLabels"></app-select>
    </div>
    <div>
        <app-select formControlName="motor_type" [label]="fieldLabels.motor_type" [items]="mototTypeLabels"></app-select>
        <app-input formControlName="fan_power" type="number" [label]="fieldLabels.fan_power"></app-input>
        <app-input formControlName="air_flow_defect_ratio" type="number" [label]="fieldLabels.air_flow_defect_ratio"></app-input>
    </div>
    <div>
        <app-input formControlName="auxiliary_electric_capacity" type="number" [unit]="AuxEnergyUnitLabels[entity?.auxiliary_electric_unit]"
                   *ngIf="!heaterFormGroup?.controls.auxiliary_electric_defaults.value" label="Use auxiliary electric as default">
        </app-input>
        <app-checkbox formControlName="auxiliary_electric_defaults"
                  [label]="fieldLabels.auxiliary_electric_defaults"></app-checkbox>
    </div>
    <div>
        <app-input formControlName="ahri_reference_number" [label]="fieldLabels.ahri_reference_number"></app-input>
    </div>
</app-form>
