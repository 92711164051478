import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BaseDataService } from '@/core/services/base-data-service';
import { Observable } from 'rxjs';
import { FrameFloorBackendDict } from '../models/enclosure/FrameFloor';

@Injectable({ providedIn: 'root' })
export class FrameFloorService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/frame_floor/`;

  update(
    frameFloorType: Partial<FrameFloorBackendDict>
  ): Observable<FrameFloorBackendDict> {
    return this.http.put<FrameFloorBackendDict>(
      `${this.baseUrl}${frameFloorType.id}/`,
      frameFloorType
    );
  }

  delete(id: number) {
    return this.http.delete(`${this.baseUrl}${id}/`);
  }
}
