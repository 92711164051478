import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DIALOG_WIDTH } from '@/modules/simulation/constants';
import {
  SkylightTypeChangeDialogComponent,
  SkylightTypeChangeDialogData,
} from '../skylight-type-change-dialog/skylight-type-change-dialog.component';
import {
  SkylightBackendDict,
  createSkylightForm,
  FIELD_LABELS,
} from '@/data/simulation/models/enclosure/Skylight';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectSkylightViewSet } from '@/modules/simulation/state/skylight/selectors';
import { filter, first, takeUntil } from 'rxjs/operators';
import { selectSkylightTypeNameAndError } from '@/modules/simulation/state/skylight-type/selectors';
import {
  removeSkylight,
  updateSkylight,
} from '@/modules/simulation/state/skylight/actions';
import { CREATE_MODE, EDIT_MODE } from '@/core/constants';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { removeSkylightType } from '@/modules/simulation/state/skylight-type/actions';
import { OrientationLabels } from '@/data/simulation/enumerations';
import { cloneSkylight } from '@/modules/simulation/state/simulation/actions';

@Component({
  selector: 'app-skylight',
  templateUrl: './skylight.component.html',
  styleUrls: ['./skylight.component.scss'],
})
export class SkylightComponent implements OnDestroy, OnInit {
  @Input() entityId: number;
  fieldLabels = FIELD_LABELS;

  entity: SkylightBackendDict = null;
  loading = false;

  private componentDestroyed$ = new Subject();

  public skylightTypeName: string;
  public skylightTypeErrorCount = 0;
  public orientationLabels = OrientationLabels;

  public skylightFormGroup: FormGroup;
  constructor(public dialog: MatDialog, public store: Store) {}

  updateTypeDetails(typeId: number) {
    if (!typeId) {
      this.skylightTypeName = null;
      this.skylightTypeErrorCount = 1;
      return;
    }

    this.store
      .select(selectSkylightTypeNameAndError(typeId))
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(({ name, errorCount }) => {
        this.skylightTypeName = name;
        this.skylightTypeErrorCount = errorCount;
      });
  }

  ngOnInit(): void {
    this.store
      .select(selectSkylightViewSet(this.entityId))
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(val => val !== null)
      )
      .subscribe(({ data, loading }) => {
        this.entity = data;
        this.loading = loading;
        if (loading) return;
        if (!this.skylightFormGroup) {
          this.skylightFormGroup = createSkylightForm(data);
        }

        this.updateTypeDetails(data.type);
      });
  }

  openEditSkylightTypeChangeDialog(data: SkylightTypeChangeDialogData) {
    return this.dialog.open(SkylightTypeChangeDialogComponent, {
      width: DIALOG_WIDTH,
      disableClose: true,
      data,
    });
  }

  removeSkylight() {
    let skylightName = this.entity.name;
    if (skylightName === null || skylightName === '') {
      skylightName = 'Untitled';
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '50%',
      data: {
        title: `Do you want to delete "${skylightName}" skylight ?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (result) {
          this.store.dispatch(removeSkylight({ skylight: this.entity }));
        }
      });
  }

  editSkylightType() {
    const updatedSkylightType = this.openEditSkylightTypeChangeDialog({
      commit: true,
      mode: EDIT_MODE,
      entityId: this.entity.type,
    });

    updatedSkylightType.afterClosed().subscribe(() => {
      this.updateTypeDetails(this.entity.type);
    });
  }

  addSkylightType() {
    const newSkylightDialog = this.openEditSkylightTypeChangeDialog({
      commit: true,
      mode: CREATE_MODE,
      parentId: this.entityId,
    });

    newSkylightDialog.afterClosed().subscribe(skylightType => {
      if (!skylightType) {
        return;
      }

      this.skylightFormGroup.patchValue({ type: skylightType.id });
      this.updateTypeDetails(skylightType.id);
    });
  }

  removeSkylightType() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '50%',
      data: {
        title: `Do you want to delete "${this.skylightTypeName}" skylight type ?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (result) {
          this.store.dispatch(
            removeSkylightType({
              skylightId: this.entityId,
              skylightTypeId: this.entity.type,
            })
          );
        }
      });
  }

  clone() {
    this.store.dispatch(cloneSkylight({ id: this.entityId }));
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  onSave(skylightChanges: Partial<SkylightBackendDict>): void {
    this.store.dispatch(
      updateSkylight({
        id: this.entityId,
        skylightChanges: skylightChanges,
      })
    );
  }
}
