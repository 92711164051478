import {
  Component,
  DoCheck,
  ElementRef,
  HostBinding,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NgControl,
} from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { FocusMonitor } from '@angular/cdk/a11y';
import { NgxDropzoneComponent } from 'ngx-dropzone';
import { first, takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: FileInputComponent,
    },
  ],
})
export class FileInputComponent
  implements
    ControlValueAccessor,
    MatFormFieldControl<any>,
    OnInit,
    OnDestroy,
    DoCheck
{
  static nextId = 0;
  private placeholder$: string;
  private required$ = false;
  private disabled$ = false;

  @Input() initialValue: string;
  @Input() accept = '*';
  @Input() maxFileSize: number;

  @ViewChild('dropzone') dropzoneRef?: NgxDropzoneComponent;

  @HostBinding('[id]')
  public id = `app-file-input-${FileInputComponent.nextId++}`;

  public isLoading = false;

  public fileControl = new UntypedFormControl();
  private componentDestroyed$ = new Subject();

  // angular mat input
  public focused = false;
  public touched = false;
  public stateChanges = new Subject<void>();
  public describedBy = '';
  public onChange = (_: any) => {};
  public onTouched = () => {};

  get empty() {
    return !this.fileControl.value;
  }

  @HostBinding('[class.app-file-input-floating]')
  get shouldLabelFloat() {
    return true;
  }

  get errorState() {
    return this.ngControl.errors !== null && this.touched;
  }

  @Input()
  get placeholder(): string {
    return this.placeholder$;
  }
  set placeholder(value: string) {
    this.placeholder$ = value;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this.required$;
  }
  set required(value: boolean) {
    this.required$ = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this.disabled$;
  }
  set disabled(value: boolean) {
    this.disabled$ = coerceBooleanProperty(value);
    this.disabled$
      ? (this.dropzoneRef.disabled = true)
      : (this.dropzoneRef.disabled = false);
    this.stateChanges.next();
  }

  @Input()
  get value(): File | null {
    return this.fileControl.value;
  }
  set value(value: File | string | null) {
    if (!this.fileControl) {
      return;
    }
    if (typeof value === 'string') {
      this.isLoading = true;
      this.httpClient
        .get(value, { headers: { skipJWT: 'true' }, responseType: 'blob' })
        .pipe(takeUntil(this.componentDestroyed$), first())
        .subscribe(
          file => {
            const filename = new URL(value).pathname.split('/').pop();
            this.writeValue(new File([file], filename));
          },
          _ => this.writeValue(new File([], ''))
        );
    } else if (value === null) {
      // empty File means that user delete image
      this.writeValue(new File([], ''));
    } else {
      this.fileControl.setValue(value);
      this.isLoading = false;
      this.stateChanges.next();
      this.onChange(value);
    }
  }

  constructor(
    private focusMonitorService: FocusMonitor,
    private injector: Injector,
    private elementRef$: ElementRef<HTMLElement>,
    private httpClient: HttpClient,
    @Optional() @Self() public ngControl: NgControl
  ) {
    focusMonitorService.monitor(elementRef$, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    if (typeof this.placeholder === 'undefined') {
      this.placeholder = 'Click to select file or drag-and-drop file here';
    }
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  ngDoCheck(): void {
    if (this.touched !== this.ngControl.control.touched) {
      this.touched = this.ngControl.touched;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  // angular mat input methods
  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (
      !this.elementRef$.nativeElement.contains(event.relatedTarget as Element)
    ) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick($event: MouseEvent) {
    $event.stopPropagation();
    if (this.disabled) {
      return;
    }
    this.onTouched();
    if (($event.target as Element).tagName.toLowerCase() === 'mat-icon') {
      this.clear();
    } else if (
      ($event.target as Element).tagName.toLowerCase() === 'input' ||
      ($event.target as Element).tagName.toLowerCase() === 'ngx-dropzone' ||
      ($event.target as Element).classList.contains('dropzone-label')
    ) {
      // do nothing with ngx-dropzone events
    } else {
      this.dropzoneRef._onClick();
    }
  }

  writeValue(value: File | string | null): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
    // update our null or string value after registering control
    this.writeValue(this.value);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private clear(): void {
    this.writeValue(new File([], ''));
  }

  // component methods

  onSelect(event) {
    this.writeValue([...event.addedFiles][0]);
  }
}
