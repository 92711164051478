import { Metro } from '@/data/geographic/models/metro';
import { ClimateZone } from '@/data/simulation/models/simulation';

export class County {
  id: number;
  name: string;
  state: string;
  county_fips: string;
  county_type: string;
  legal_statistical_area_description: string;
  ansi_code: string;
  land_area_meters: number;
  water_area_meters: number;
  latitude: number;
  longitude: number;
  metro: number;
  metro_info: Metro;
  climate_zone: number;
  climate_zone_info: ClimateZone;
}

export interface CountyBackendDict {
  id: number;
  name: string;
  state: string;
  county_fips: string;
  county_type: string;
  legal_statistical_area_description: string;
  ansi_code: string;
  land_area_meters: number;
  water_area_meters: number;
  latitude: number;
  longitude: number;
  metro: number;
  metro_info: Metro;
  climate_zone: number;
  climate_zone_info: ClimateZone;
}
