<div class="card position-relative">
    <app-loading-overlay *ngIf="loading"></app-loading-overlay>
    <div class="card-header">
        <span>Oven and Range</span>
    </div>
    <div class="card-body pb-0">
        <app-form formName="cookingRangeForm" [formGroup]="form" (save)="onSave($event)">
            <div>
                <app-select formControlName="oven_location" label="Location" [items]="locationLabels"></app-select>
                <app-select formControlName="oven_fuel" [items]="fuleTypeLabels" label="Fuel"></app-select>
            </div>
            <div>
                <app-checkbox formControlName="is_induction_range"
                    [label]="fieldLabels.is_induction_range"></app-checkbox>
                <app-checkbox formControlName="is_convection_oven"
                    [label]="fieldLabels.is_convection_oven"></app-checkbox>
            </div>
        </app-form>
    </div>
</div>
