import { ListRequestParams } from '@/core/schemes/request-params';
import { Injectable } from '@angular/core';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { ServerResponse } from '@/core/schemes/server-response';
import {
  PaymentRequest,
  PaymentRequestCalculatePriceRequest,
  PaymentRequestCalculatePriceResponse,
  PaymentRequestCreate,
  PaymentRequestList,
  PaymentRequestProduct,
  PaymentRequestState,
  PaymentRequestType,
} from '@/data/customer-hirl/models';
import { tap } from 'rxjs/operators';
import {
  loadPaymentRequest,
  loadPaymentRequestProductsAction,
  loadPaymentRequestProductsCountAction,
} from '@/state/actions/customer-hirl/payment-request';
import { PaymentRequestProductRequestParams } from '@/data/customer-hirl/services/payment-request-product.service';

export class PaymentRequestRequestParams extends ListRequestParams {
  manufacturer?: number | number[];
  state?: PaymentRequestState | PaymentRequestState[];
  request_type?: PaymentRequestType | PaymentRequestType[];
}

@Injectable({
  providedIn: 'root',
})
export class PaymentRequestService extends BaseDataService {
  baseUrl = `${environment.AXIS_API_V3_ROOT}/gcp_payment_requests/`;

  list(
    params?: PaymentRequestRequestParams
  ): Observable<ServerResponse<PaymentRequestList>> {
    return this.http.get<ServerResponse<PaymentRequestList>>(
      `${this.baseUrl}`,
      {
        params: this.rollParams(params),
      }
    );
  }

  create(paymentRequest: PaymentRequestCreate): Observable<PaymentRequest> {
    return this.http.post<PaymentRequest>(`${this.baseUrl}`, paymentRequest);
  }

  retrieve(
    paymentRequestId: number,
    options?: IDataServiceMethodOptions
  ): Observable<PaymentRequest> {
    const ignoreStore = options?.ignoreStore;
    let chain = this.http.get<PaymentRequest>(
      `${this.baseUrl}${paymentRequestId}`,
      {}
    );
    if (!ignoreStore) {
      chain = chain.pipe(
        tap(paymentRequest =>
          this.store.dispatch(loadPaymentRequest({ payload: paymentRequest }))
        )
      );
    }
    return chain;
  }

  update(
    paymentRequest: PaymentRequest,
    paymentRequestId: number,
    options?: IDataServiceMethodOptions
  ): Observable<PaymentRequest> {
    const ignoreStore = options?.ignoreStore;

    let chain = this.http.patch<PaymentRequest>(
      `${this.baseUrl}${paymentRequestId}/`,
      paymentRequest
    );

    if (!ignoreStore) {
      chain = chain.pipe(
        tap(paymentRequest =>
          this.store.dispatch(loadPaymentRequest({ payload: paymentRequest }))
        )
      );
    }
    return chain;
  }

  delete(paymentRequestId: number) {
    return this.http.delete(`${this.baseUrl}${paymentRequestId}`);
  }

  calculate_price(
    data: PaymentRequestCalculatePriceRequest
  ): Observable<PaymentRequestCalculatePriceResponse> {
    return this.http.post<PaymentRequestCalculatePriceResponse>(
      `${this.baseUrl}calculate_price/`,
      data
    );
  }

  approve(paymentRequestId: number): Observable<PaymentRequest> {
    return this.http
      .post<PaymentRequest>(`${this.baseUrl}${paymentRequestId}/approve/`, {})
      .pipe(
        tap((entity: PaymentRequest) =>
          this.store.dispatch(loadPaymentRequest({ payload: entity }))
        )
      );
  }

  reject(paymentRequestId: number): Observable<PaymentRequest> {
    return this.http
      .post<PaymentRequest>(`${this.baseUrl}${paymentRequestId}/reject/`, {})
      .pipe(
        tap((entity: PaymentRequest) =>
          this.store.dispatch(loadPaymentRequest({ payload: entity }))
        )
      );
  }

  payment_request_products(
    paymentRequestId: number,
    params?: PaymentRequestProductRequestParams
  ) {
    return this.http
      .get<ServerResponse<PaymentRequestProduct>>(
        `${this.baseUrl}${paymentRequestId}/products/`,
        {
          params: this.rollParams(params),
        }
      )
      .pipe(
        tap(serverResponse =>
          this.store.dispatch(
            loadPaymentRequestProductsAction({
              payload: serverResponse.results,
            })
          )
        ),
        tap(serverResponse =>
          this.store.dispatch(
            loadPaymentRequestProductsCountAction({
              payload: serverResponse.count,
            })
          )
        )
      );
  }

  payment_request_productsCreate(
    paymentRequestId: number,
    paymentRequestProduct: PaymentRequestProduct
  ): Observable<PaymentRequestProduct> {
    return this.http.post<PaymentRequestProduct>(
      `${this.baseUrl}${paymentRequestId}/products/`,
      paymentRequestProduct
    );
  }
}
