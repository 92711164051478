import {
  Component,
  EventEmitter,
  Injector,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ProductStatusLabelMapping } from '@/data/product/models';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, Subject, Subscription } from 'rxjs';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { MatDialog } from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import * as _ from 'lodash';
import { filter, first, takeUntil } from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import {
  PaymentRequest,
  PaymentRequestProduct,
} from '@/data/customer-hirl/models';
import {
  getHIRLPaymentRequest,
  getHIRLPaymentRequestProducts,
  getHIRLPaymentRequestProductsCount,
} from '@/state/selectors/customer-hirl/payment-request';
import { PaymentRequestService } from '@/data/customer-hirl/services/payment-request.service';
import {
  PaymentRequestProductRequestParams,
  PaymentRequestProductService,
} from '@/data/customer-hirl/services/payment-request-product.service';
import { PaymentRequestProductPermissionService } from '@/modules/customer-hirl/services/payment-request-product-permission.service';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { toggleLoading } from '@/state/actions/app.actions';
import {
  PaymentRequestProductChangeDialogComponent,
  PaymentRequestProductChangeDialogData,
} from '@/modules/customer-hirl/components/payment-request-product-change-dialog/payment-request-product-change-dialog.component';

@Component({
  selector: 'app-payment-request-products-tab',
  templateUrl: './payment-request-products-tab.component.html',
  styleUrls: ['./payment-request-products-tab.component.scss'],
})
export class PaymentRequestProductsTabComponent implements OnInit, OnDestroy {
  protected readonly ProductStatusLabelMapping = ProductStatusLabelMapping;

  public paymentRequest: PaymentRequest;
  public displayedColumns = [
    'product__title',
    'product__practice_item_product_count',
    'thumbnail',
    'status',
    'actions',
  ];
  public filterFromGroup: FormGroup;
  public isLoading = false;
  public rows: PaymentRequestProduct[] = [];

  public permissions: {
    [key: string]: {
      canEdit: Observable<ObjectPermissionResponse>;
      canDelete: Observable<ObjectPermissionResponse>;
    };
  } = {};
  public rowsCount: number;
  public createPermissionResponse: Observable<ObjectPermissionResponse>;

  public defaultParams: PaymentRequestProductRequestParams =
    new PaymentRequestProductRequestParams(1, '', '-id');

  public storedParams: PaymentRequestProductRequestParams = Object.assign(
    new PaymentRequestProductRequestParams(),
    this.defaultParams
  );

  private listSubscription$: Subscription;
  private componentDestroyed$ = new Subject();
  @Output() onCreated = new EventEmitter<PaymentRequestProduct>();
  @Output() onDeleted = new EventEmitter<PaymentRequestProduct>();

  constructor(
    private store: Store<AppState>,
    private injector: Injector,
    private dialog: MatDialog,
    private paymentRequestService: PaymentRequestService,
    private paymentRequestProductService: PaymentRequestProductService,
    private paymentRequestProductPermissionService: PaymentRequestProductPermissionService,
    private fb: FormBuilder,
    private uiHelperService: UIHelperService
  ) {}

  ngOnInit(): void {
    this.storedParams = _.cloneDeep(this.defaultParams);

    this.store
      .select(getHIRLPaymentRequest)
      .pipe(
        filter(val => val !== null),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((entity: PaymentRequest) => {
        this.paymentRequest = entity;
        this.createPermissionResponse =
          this.paymentRequestProductPermissionService.canCreate({
            paymentRequest: entity,
          });
        this.list();
      });

    this.store
      .select(getHIRLPaymentRequestProducts)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((paymentRequestProductGroups: PaymentRequestProduct[]) => {
        this.rows = paymentRequestProductGroups;
      });

    this.store
      .select(getHIRLPaymentRequestProductsCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(paymentRequestProductGroupsCount => {
        this.rowsCount = paymentRequestProductGroupsCount;
      });

    this.setupFilterForm();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  setupFilterForm() {
    this.filterFromGroup = this.fb.group({
      search: [null],
    });

    this.filterFromGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        const params = _.merge({}, this.defaultParams, values);
        delete params.ordering;

        this.storedParams = _.merge(this.storedParams, params);
        this.list();
      });
  }

  list() {
    this.isLoading = true;

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }

    this.listSubscription$ = this.paymentRequestService
      .payment_request_products(this.paymentRequest.id, this.storedParams)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.rows = data.results;
        this.rowsCount = data.count;
        this.isLoading = false;
        this.rows.forEach((row: PaymentRequestProduct) => {
          this.permissions[row.id] = {
            canEdit: this.paymentRequestProductPermissionService.canEdit(row),
            canDelete:
              this.paymentRequestProductPermissionService.canDelete(row),
          };
        });
      });
  }

  onPaginateChange($event: PageEvent) {
    this.uiHelperService.getUISettings().rowsPerPage = $event.pageSize;
    this.storedParams.page_size = $event.pageSize;
    this.storedParams.page = $event.pageIndex + 1;
    this.list();
  }

  onSortChange($event: Sort) {
    this.storedParams.ordering = `${$event.direction === 'desc' ? '-' : ''}${
      $event.active
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  delete($event: MouseEvent, paymentRequestProduct: PaymentRequestProduct) {
    $event.preventDefault();

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '45%',
      data: {
        title: 'Delete document ?',
        content: `Do you want to remove Product Group: ${paymentRequestProduct?.product_info?.title}`,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      this.store.dispatch(toggleLoading({ payload: true }));
      this.paymentRequestProductService
        .delete(paymentRequestProduct)
        .pipe(takeUntil(this.componentDestroyed$), first())
        .subscribe(() => {
          this.onDeleted?.emit(paymentRequestProduct);
          this.store.dispatch(toggleLoading({ payload: false }));
          this.uiHelperService.openSnackBar(
            `Product Group successfully removed`
          );
        });
    });
  }

  create($event: MouseEvent) {
    $event.preventDefault();

    const dialogRef = this.dialog.open(
      PaymentRequestProductChangeDialogComponent,
      {
        width: '45%',
        data: {
          paymentRequestProduct: {
            payment_request: this.paymentRequest.id,
          },
          commit: true,
        } as PaymentRequestProductChangeDialogData,
        disableClose: true,
      }
    );

    dialogRef.afterClosed().subscribe(paymentRequestProduct => {
      if (!paymentRequestProduct) {
        return;
      }
      this.onCreated?.emit(paymentRequestProduct);
      this.list();
      this.uiHelperService.openSnackBar(`Product Group Successfully Added`);
    });
  }
}
