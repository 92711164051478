import { Component, Injector, OnInit } from '@angular/core';
import { Company } from '@/data/company/models';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, Subject, Subscription } from 'rxjs';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute } from '@angular/router';
import { CompanyService } from '@/data/company/services/company-base.service';
import { CompanyPermissionService } from '@/modules/company/services/company-permission-service';
import { MatDialog } from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import * as _ from 'lodash';
import {
  getCompany,
  selectCompanyEquipments,
  selectCompanyEquipmentsCount,
} from '@/state/selectors/company/company.selector';
import { filter, first, takeUntil } from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import {
  Equipment,
  EquipmentCalibrationCycleLabelMapping,
  EquipmentTypeLabelMapping,
} from '@/data/equipment/models';
import {
  EquipmentRequestParams,
  EquipmentService,
} from '@/data/equipment/services/equipment.service';
import {
  EquipmentChangeDialogComponent,
  EquipmentChangeDialogData,
} from '@/modules/equipment/components/equipment-change-dialog/equipment-change-dialog.component';
import { toggleLoading } from '@/state/actions/app.actions';
import { EquipmentPermissionService } from '@/modules/equipment/services/equipment-permission.service';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-company-equipment-tab',
  templateUrl: './company-equipment-tab.component.html',
  styleUrls: ['./company-equipment-tab.component.scss'],
})
export class CompanyEquipmentTabComponent implements OnInit {
  company: Company;
  companyDisplayName: string;
  displayedColumns = [
    'brand',
    'type',
    'description',
    'calibration_date',
    'assignees',
    'notes',
    'status',
    'actions',
  ];

  equipmentTypeLabelMapping = EquipmentTypeLabelMapping;
  equipmentCalibrationCycleLabelMapping = EquipmentCalibrationCycleLabelMapping;
  filterFromGroup: FormGroup;
  isLoading = false;
  rows: Equipment[] = [];
  rowsCount: number;
  permissions: {
    [key: string]: {
      canEdit: Observable<ObjectPermissionResponse>;
      canDelete: Observable<ObjectPermissionResponse>;
    };
  } = {};

  public createPermissionResponse: Observable<ObjectPermissionResponse>;

  public defaultParams: EquipmentRequestParams = new EquipmentRequestParams(
    1,
    '',
    '-id',
    25
  );
  public storedParams: EquipmentRequestParams = Object.assign(
    new EquipmentRequestParams(),
    this.defaultParams
  );

  private listSubscription$: Subscription;
  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private injector: Injector,
    private activatedRoute: ActivatedRoute,
    private equipmentService: EquipmentService,
    private companyService: CompanyService,
    private fb: FormBuilder,
    private companyPermissionService: CompanyPermissionService,
    private equipmentPermissionService: EquipmentPermissionService,
    private dialog: MatDialog,
    private uiHelperService: UIHelperService
  ) {}

  ngOnInit(): void {
    this.storedParams = _.cloneDeep(this.defaultParams);
    this.storedParams.page_size =
      this.uiHelperService.getUISettings().rowsPerPage;

    this.store
      .select(getCompany)
      .pipe(takeUntil(this.componentDestroyed$))
      .pipe(filter(val => val !== null))
      .subscribe(company => {
        this.company = company;
        this.createPermissionResponse =
          this.equipmentPermissionService.canCreate(this.company.id);
        this.list();
      });

    this.store
      .select(selectCompanyEquipments)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((equipments: Equipment[]) => {
        this.rows = equipments;
      });

    this.store
      .select(selectCompanyEquipmentsCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(count => {
        this.rowsCount = count;
      });

    this.setupFilterForm();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  setupFilterForm() {
    this.filterFromGroup = this.fb.group({
      search: [null],
    });

    this.filterFromGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        const params = _.merge({}, this.defaultParams, values);
        delete params.ordering;

        this.storedParams = _.merge(this.storedParams, params);
        this.list();
      });
  }

  list() {
    this.isLoading = true;

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }

    this.listSubscription$ = this.companyService
      .equipments(this.company.id, this.storedParams)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.rows = data.results;
        this.rowsCount = data.count;
        this.isLoading = false;
        this.rows.forEach(row => {
          this.permissions[row.id] = {
            canEdit: this.equipmentPermissionService.canEdit(row),
            canDelete: this.equipmentPermissionService.canDelete(row),
          };
        });
      });
  }

  edit($event: MouseEvent, equipment: Equipment) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(EquipmentChangeDialogComponent, {
      width: '45%',
      disableClose: true,
      data: {
        equipment,
      } as EquipmentChangeDialogData,
    });

    dialogRef.afterClosed().subscribe((result?: Equipment) => {
      if (!result) {
        return;
      }
      this.list();
      this.uiHelperService.openSnackBar('Record Successfully Changed');
    });
  }

  onPaginateChange($event: PageEvent) {
    this.uiHelperService.getUISettings().rowsPerPage = $event.pageSize;
    this.storedParams.page_size = $event.pageSize;
    this.storedParams.page = $event.pageIndex + 1;
    this.list();
  }

  onSortChange($event: Sort) {
    this.storedParams.ordering = `${$event.direction === 'desc' ? '-' : ''}${
      $event.active
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  create($event: MouseEvent) {
    $event.preventDefault();

    const dialogRef = this.dialog.open(EquipmentChangeDialogComponent, {
      width: '45%',
      disableClose: true,
      data: {
        equipment: {
          owner_company: this.company.id,
        } as Equipment,
      } as EquipmentChangeDialogData,
    });

    dialogRef.afterClosed().subscribe((result?: Equipment) => {
      if (!result) {
        return;
      }
      this.list();
      this.uiHelperService.openSnackBar('Record Successfully Created');
    });
  }

  copy($event: MouseEvent, equipment: Equipment) {
    $event.preventDefault();

    this.store.dispatch(toggleLoading({ payload: true }));
    this.equipmentService
      .copy_expired_equipment(equipment.id)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: newEquipment => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.list();
          this.uiHelperService.openSnackBar('Record Successfully Copied');
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  delete($event: MouseEvent, equipment: Equipment) {
    $event.preventDefault();

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '45%',
      data: {
        title: 'Delete equipment ?',
        content: `Do you want to delete equipment?`,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      this.store.dispatch(toggleLoading({ payload: true }));
      this.equipmentService
        .delete(equipment.id)
        .pipe(takeUntil(this.componentDestroyed$), first())
        .subscribe(() => {
          this.list();
          this.store.dispatch(toggleLoading({ payload: false }));
        });
    });
  }
}
