import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BaseDataService } from '@/core/services/base-data-service';
import { Observable } from 'rxjs';
import { FoundationWallTypeBackendDict } from '../models/enclosure/FoundationWallType';
import { ListRequestParams } from '@/core/schemes/request-params';
import { ServerResponse } from '@/core/schemes/server-response';

export class FoundationWallTypeParams extends ListRequestParams {}

@Injectable({ providedIn: 'root' })
export class FoundationWallTypeService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/foundation_wall_type/`;
  public parentUrl = `${environment.AXIS_API_V3_ROOT}/foundation_wall/`;

  list(
    params: FoundationWallTypeParams
  ): Observable<ServerResponse<FoundationWallTypeBackendDict>> {
    return this.http.get<ServerResponse<FoundationWallTypeBackendDict>>(
      `${this.baseUrl}`,
      {
        params: this.rollParams(params),
      }
    );
  }

  create(
    wallId: number,
    wallType: FoundationWallTypeBackendDict
  ): Observable<FoundationWallTypeBackendDict> {
    return this.http.post<FoundationWallTypeBackendDict>(
      `${this.parentUrl}${wallId}/foundation_wall_type/`,
      wallType
    );
  }

  update(
    foundationWallTypeChanges: Partial<FoundationWallTypeBackendDict>
  ): Observable<FoundationWallTypeBackendDict> {
    return this.http.patch<FoundationWallTypeBackendDict>(
      `${this.baseUrl}${foundationWallTypeChanges.id}/`,
      foundationWallTypeChanges
    );
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}${id}/`);
  }
}
