import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Annotation } from '@/data/annotation/models';
import { FormBuilder, FormGroup } from '@angular/forms';
import { forkJoin, of, Subject } from 'rxjs';
import { User } from '@/data/core/models/user';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, takeUntil } from 'rxjs/operators';
import { toggleLoading } from '@/state/actions/app.actions';
import { AnnotationService } from '@/data/annotation/services/annotation.service';

export class AnnotationEditDialogData {
  title = 'Annotation';
  annotation?: Annotation;
  commit = true;
  retrieve = true;
}

@Component({
  selector: 'app-annotation-edit-dialog',
  templateUrl: './annotation-edit-dialog.component.html',
  styleUrls: ['./annotation-edit-dialog.component.scss'],
})
export class AnnotationEditDialogComponent implements OnInit, OnDestroy {
  public initialized: boolean;
  public isEditForm = false;
  public entity?: Annotation;
  public retrieve = true;
  public commit = true;
  public form: FormGroup;

  private componentDestroyed$ = new Subject();
  private currentUser: User;

  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<AnnotationEditDialogComponent>,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private annotationService: AnnotationService,
    private uiHelperService: UIHelperService,
    @Inject(MAT_DIALOG_DATA) public data: AnnotationEditDialogData
  ) {
    if (data?.annotation) {
      this.entity = Object.assign({}, data?.annotation);
    }
    if (data.hasOwnProperty('commit')) {
      this.commit = data?.commit;
    }
    if (data.hasOwnProperty('retrieve')) {
      this.retrieve = data?.retrieve;
    }

    if (this.entity?.id) {
      this.isEditForm = true;
    }
  }

  ngOnInit(): void {
    const sources = {
      currentUser: this.store.select(getInfoUser).pipe(first()),
      annotation: of(this.entity),
    };

    this.store.dispatch(toggleLoading({ payload: true }));

    if (this.isEditForm && this.retrieve) {
      sources.annotation = this.annotationService
        .retrieve(this.entity.id, { ignoreStore: true })
        .pipe(takeUntil(this.componentDestroyed$), first());
    }

    forkJoin(sources)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: ({ currentUser, annotation }) => {
          this.store.dispatch(toggleLoading({ payload: false }));

          this.currentUser = currentUser;
          this.entity = annotation;
          this.setupForm();
          this.initialized = true;
          if (this.isEditForm) {
            this.hydrateForm();
          }
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  hydrateForm() {
    this.form.patchValue(Object.assign({}, this.entity), {
      emitEvent: false,
      onlySelf: true,
    });
  }

  setupForm() {
    this.form = this.fb.group({
      is_public: [true],
      content: [''],
    });
  }

  save($event: MouseEvent) {
    $event.preventDefault();
    if (this.isEditForm) {
      this.edit();
    } else {
      this.create();
    }
  }

  private getFormData(): Annotation {
    const data: Annotation = new Annotation();
    data.content = this.form.value.content;
    data.is_public = this.form.value.is_public;
    data.type = this.entity.type;
    return data;
  }

  private create() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    const data: Annotation = this.getFormData();

    this.dialogRef.close(data);
  }

  private edit() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    const data: Annotation = this.getFormData();
    if (!this.commit) {
      this.dialogRef.close(data);
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    this.annotationService
      .update(this.entity.id, data)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: annotation => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close(annotation);
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }
}
