import { createSelector } from '@ngrx/store';
import { SimulationState } from '../reducers';
import { selectSimulationFeature } from '../simulation.selectors';

export const selectWaterHeater = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.waterHeater.entities[id]
  );

const selectWaterHeaterLoading = (id: number) =>
  createSelector(
    selectSimulationFeature,
    state => state.waterHeater.loading[id]
  );

export const selectWaterHeaterViewSet = (id: number) =>
  createSelector(
    selectWaterHeater(id),
    selectWaterHeaterLoading(id),
    (data, loading) => ({ data, loading })
  );

export const selectWaterHeaterErrors = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.waterHeater.errors[id]
  );
