import {
  RoofTypeBackendDict,
  RoofTypeFormGroup,
} from '@/data/simulation/models/enclosure/RoofType';
import { BaseValidator } from './base.validator';
import { ModelErrors, StateErrors } from '../state/shared/base.state';

export class RoofTypeValidator extends BaseValidator {
  static validateForm(
    rooftype: RoofTypeBackendDict
  ): ModelErrors<RoofTypeBackendDict> {
    const form = new RoofTypeFormGroup(rooftype);
    return form.getFieldAndModelErros();
  }

  static validate(
    rooftypes: RoofTypeBackendDict[]
  ): StateErrors<RoofTypeBackendDict> {
    const errors = {};
    rooftypes.forEach(rooftype => {
      errors[rooftype.id] = this.validateForm(rooftype);
    });
    return errors;
  }
}
