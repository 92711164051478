import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { map, switchMap } from 'rxjs/operators';
import { HIRLProjectPermissionService } from '@/modules/customer-hirl/services/hirl-project-permission-service';
import { toggleLoading } from '@/state/actions/app.actions';
import { HIRLProjectService } from '@/data/customer-hirl/services/hirl-projects.service';

@Injectable({ providedIn: 'root' })
export class CustomerHIRLEditHIRLProjectGuard implements CanActivate {
  constructor(
    public router: Router,
    public store: Store<AppState>,
    public hirlProjectService: HIRLProjectService,
    public hirlProjectPermissionService: HIRLProjectPermissionService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.store.dispatch(toggleLoading({ payload: true }));
    return this.hirlProjectService
      .retrieve(route.params.projectId, { ignoreStore: true })
      .pipe(
        switchMap(project => {
          return this.hirlProjectPermissionService
            .canEdit(project, project.registration)
            .pipe(
              map(objectPermissionResponse => {
                this.store.dispatch(toggleLoading({ payload: false }));
                if (objectPermissionResponse.hasPermission) {
                  return true;
                }
                this.router.navigate(['403'], {
                  queryParams: { returnUrl: state.url.split('?')[0] },
                });
                return false;
              })
            );
        })
      );
  }
}
