import { Component, Input, OnInit } from '@angular/core';
import {
  CustomerDocument,
  CustomerDocumentInfo,
} from '@/data/filehandling/models';

export enum CustomerDocumentDisplayComponentType {
  onlyFilename = 'onlyFilename',
  onlyFilenameAsLink = 'onlyFilenameAsLink',
  filenameAsLinkAndSharedLink = 'filenameAsLinkAndSharedLink',
}

@Component({
  selector: 'app-customer-document-display',
  templateUrl: './customer-document-display.component.html',
  styleUrls: ['./customer-document-display.component.scss'],
})
export class CustomerDocumentDisplayComponent implements OnInit {
  @Input() displayType:
    | CustomerDocumentDisplayComponentType
    | keyof typeof CustomerDocumentDisplayComponentType =
    CustomerDocumentDisplayComponentType.onlyFilename;
  @Input() customerDocument?: CustomerDocument | CustomerDocumentInfo;
  customerDocumentDisplayComponentType = CustomerDocumentDisplayComponentType;

  ngOnInit(): void {}
}
