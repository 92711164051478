<div class="container-lg lookup-padding">
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-6">
          <h3>
            <span *ngIf="!isLoading">{{ rowsCount | number }}</span>
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" *ngIf="isLoading"></fa-icon>
            Search Results
          </h3>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <hr>
        </div>
      </div>

      <div class="row">
        <div class="col-12 mb-2">
          <form [formGroup]="filterFromGroup" class="w-100">
            <mat-form-field class="w-100">
              <mat-label>Certification Search</mat-label>
              <app-search-input
                  formControlName="search"
                  placeholder="Address, city, state, zip code, or subdivision/MF development name"
              ></app-search-input>
              <mat-error *ngIf="filterFromGroup.get('search').hasError('minlength')">
                Minimum length for search is 3 characters.
              </mat-error>
            </mat-form-field>
          </form>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="row" *ngIf="isLoading">
            <div class="col-12">
              <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <table
                  mat-table
                  [dataSource]="rows"
                  class="table"
                  matSort
                  [matSortActive]="storedParams.toTableSort().active"
                  [matSortDirection]="storedParams.toTableSort().direction"
                  (matSortChange)="onSortChange($event)"
              >
                <ng-container matColumnDef="home__home__street_line1">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="centered-cell">
                    Address
                  </th>
                  <td mat-cell *matCellDef="let row" class="centered-cell">
                    {{ row.address }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="home__subdivision__name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="centered-cell">
                    Subdivision/MF Development
                  </th>
                  <td mat-cell *matCellDef="let row" class="centered-cell">
                    {{ row.subdivision_name }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="customer_hirl_project__registration__builder_organization__name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="centered-cell">
                    Client Name
                  </th>
                  <td mat-cell *matCellDef="let row" class="centered-cell">
                    {{ row.client_name }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="certification_date">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="centered-cell">
                    Certification Date
                  </th>
                  <td mat-cell *matCellDef="let row" class="centered-cell">
                    {{ row.certification_date | date: 'MM/dd/yyyy' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="certification_level">
                  <th mat-header-cell *matHeaderCellDef class="centered-cell">
                    Certification Level
                  </th>
                  <td mat-cell *matCellDef="let row" class="centered-cell">
                    {{ QAHIRLCertificationLevelAwardedLabelMapping[row.certification_level] }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="certification_path">
                  <th mat-header-cell *matHeaderCellDef class="centered-cell">
                    Certification Path
                  </th>
                  <td mat-cell *matCellDef="let row" class="centered-cell">
                    {{ row.certification_path }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="certification_number">
                  <th mat-header-cell *matHeaderCellDef class="centered-cell">
                    Certification Number
                  </th>
                  <td mat-cell *matCellDef="let row" class="centered-cell">
                    {{ row.certification_number }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef class="centered-cell">
                    Actions
                  </th>
                  <td mat-cell *matCellDef="let row" class="centered-cell">
                    <a
                        [href]="row.certification_link"
                        class="mr-2"
                        target="_blank"
                        matTooltip="Open certificate in new window"
                    >
                      <fa-icon [icon]="['fas', 'external-link-alt']"></fa-icon>
                    </a>
                    <app-copy-to-clipboard-button [value]="row.certification_link" matTooltip="Copy shareable link">
                      <fa-icon [icon]="['fas', 'link']"></fa-icon>
                    </app-copy-to-clipboard-button>
                  </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              </table>
            </div>
          </div>
          <div class="d-flex flex-row">
            <div class="d-flex flex-fill justify-content-end">
              <mat-paginator
                  [length]="rowsCount"
                  [pageIndex]="$any(storedParams.page) - 1"
                  [pageSize]="storedParams.page_size"
                  (page)="onPaginateChange($event)"
                  aria-label="Select page"
              >
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
