import { createSelector } from '@ngrx/store';
import { SimulationState } from '../reducers';
import { selectSimulationFeature } from '../simulation.selectors';
import { SlabBackendDict } from '@/data/simulation/models/enclosure/Slab';

export const selectSlab = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.slab.entities[id]
  );
const selectSlabLoadingStatus = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.slab.loading[id]
  );

export const selectSlabTypeId = (id: number) =>
  createSelector(selectSlab(id), slab => slab.type);

const selectSlabErrors = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.slab.errors[id]
  );

export const selectSlabViewSet = (id: number) =>
  createSelector(
    selectSlab(id),
    selectSlabLoadingStatus(id),
    (slab, loadingStatus) => ({
      data: slab,
      loading: loadingStatus,
    })
  );

export const selectSlabName = (id: number) =>
  createSelector(selectSlab(id), (slab: SlabBackendDict) => slab.name);

export const selectSlabNestedErrorCount = (id: number) =>
  createSelector(
    selectSimulationFeature,
    selectSlabErrors(id),
    selectSlabTypeId(id),
    (state, slabErrors, slabTypeId) => {
      const slabTypeErrorCount = {
        errorCount: 0,
        warningCount: 0,
      };
      if (slabTypeId !== null) {
        slabTypeErrorCount.errorCount =
          state.slabType.errors[slabTypeId].errorCount;
        slabTypeErrorCount.warningCount =
          state.slabType.errors[slabTypeId].warningCount;
      }
      return {
        slab: {
          errorCount: slabErrors.errorCount,
          warningCount: slabErrors.warningCount,
        },
        slabType: slabTypeErrorCount,
      };
    }
  );

export const selectSlabErrorViewSet = (id: number) =>
  createSelector(
    selectSlabNestedErrorCount(id),
    selectSlabTypeId(id),
    selectSlabName(id),
    (nestedModelsErrorCount, slabTypeId, name) => ({
      nestedModelsErrorCount,
      slabTypeId,
      name,
    })
  );
