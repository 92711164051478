import { Component, OnDestroy, OnInit } from '@angular/core';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';
import { User } from '@/data/core/models/user';
import { forkJoin, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { first, takeUntil } from 'rxjs/operators';
import { getInfoUser } from '@/state/selectors/info.selector';
import {
  CustomerHIRLInspectionGradeList,
  InspectionGradeAggregateByLetterGrade,
} from '@/data/user-management/models';
import {
  InspectionGradeRequestParams,
  InspectionGradeService,
} from '@/data/user-management/services/inspection-grade.service';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { toggleLoading } from '@/state/actions/app.actions';
import {
  UserRequestParams,
  UserService,
} from '@/data/core/services/user.service';
import {
  LetterGrade,
  LetterGradeLabelMapping,
} from '@/data/user-management/models/inspection-grade';
import {
  QARequirementType,
  QARequirementTypeLabelMapping,
} from '@/data/qa/models';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { InspectionGradePermissionService } from '@/modules/user-management/services/inspection-grade-permission-service';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-inspection-grade-list',
  templateUrl: './inspection-grade-list.component.html',
  styleUrls: ['./inspection-grade-list.component.scss'],
})
export class InspectionGradeListComponent implements OnInit, OnDestroy {
  public displayedColumns = [
    'user__full_name',
    'company__name',
    'graded_date',
    'grade',
    'home_address',
    'qa_type',
    'eep_program_name',
    'notes',
  ];
  public currentUser: User;
  public entities: CustomerHIRLInspectionGradeList[];
  public count: number;

  public isLoading = true;
  public customerHIRLSettings = CustomerHIRLSettings;
  public initialized = false;

  public userRequestParams: UserRequestParams;

  public defaultParams: InspectionGradeRequestParams =
    new InspectionGradeRequestParams(1, '', '-id', 25);
  public storedParams: InspectionGradeRequestParams = Object.assign(
    new InspectionGradeRequestParams(),
    this.defaultParams
  );

  public letterGradeLabelMapping = LetterGradeLabelMapping;

  public filterFormGroup: FormGroup;
  public overwritePropertyValues = {};
  public overwritePropertyLabels = {};
  public excludedProperties: string[];

  public inspectionGradeAggregateByLetterGrade: InspectionGradeAggregateByLetterGrade;
  public qaRequirementTypeLabelMapping = QARequirementTypeLabelMapping;
  public qaRequirementTypeChoices = {
    [QARequirementType.roughInspection]: 'Rough Inspection',
    [QARequirementType.finalInspection]: 'Final Inspection',
    [QARequirementType.desktopAudit]: 'Desktop Audit',
    [QARequirementType.roughInspectionVirtualAudit]: 'Rough Virtual Audit',
    [QARequirementType.finalInspectionVirtualAudit]: 'Final Virtual Audit',
  };

  private componentDestroyed$ = new Subject();

  public aggregateByLetterGradeIsLoading = true;
  public averageGrade: string;
  public canPerformCustomerHIRLInspectionGradeQuarterReport =
    new ObjectPermissionResponse(false);

  constructor(
    public store: Store<AppState>,
    public inspectionGradeService: InspectionGradeService,
    public router: Router,
    public dialog: MatDialog,
    public inspectionGradePermissionService: InspectionGradePermissionService,
    public userService: UserService,
    public activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public uiHelperService: UIHelperService
  ) {
    this.userRequestParams = new UserRequestParams();
    this.userRequestParams.company__slug =
      this.customerHIRLSettings.companySlug;
    this.userRequestParams.hirluserprofile__is_qa_designee = true;
  }

  ngOnInit() {
    forkJoin({
      queryParams: this.activatedRoute.queryParams.pipe(first()),
      currentUser: this.store.select(getInfoUser).pipe(first()),
      canPerformCustomerHIRLInspectionGradeQuarterReport:
        this.inspectionGradePermissionService.canPerformCustomerHIRLInspectionGradeQuarterReport(),
    }).subscribe(
      ({
        queryParams,
        currentUser,
        canPerformCustomerHIRLInspectionGradeQuarterReport,
      }) => {
        this.storedParams.assignQueryParams(queryParams);
        this.currentUser = currentUser;

        this.canPerformCustomerHIRLInspectionGradeQuarterReport =
          canPerformCustomerHIRLInspectionGradeQuarterReport;

        this.setupFilterForm();
        this.hydrateForm();
        this.initialized = true;
        this.list();
      }
    );
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  setupFilterForm() {
    this.filterFormGroup = this.fb.group({
      search: [null],
      qa_status__home_status__eep_program_info: [null],
      letter_grade: [null],
      qa_status__requirement__type: [null],
      graded_date__gte: [null],
      graded_date__lte: [null],
      approver_info: [null],
      user_info: [null],
    });

    this.filterFormGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        let params = Object.assign(this.defaultParams, values);

        params = Object.assign(params, {
          approver: params.approver_info?.id || null,
          user: params.user_info?.id || null,
          qa_status__home_status__eep_program:
            params.qa_status__home_status__eep_program_info?.map(
              eepProgramInfo => eepProgramInfo.id
            ) || null,
        });

        this.storedParams.assignFilterFormValues({
          formValues: params,
          excludedProperties: [
            'ordering',
            'approver_info',
            'user_info',
            'qa_status__home_status__eep_program_info',
          ],
        });

        this.list();
      });
  }

  hydrateForm() {
    this.filterFormGroup.patchValue(this.storedParams, {
      emitEvent: false,
      onlySelf: true,
    });
  }

  list() {
    this.isLoading = true;
    this.aggregateByLetterGradeIsLoading = true;

    // populate params to query string
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.storedParams.toQueryParams(),
      replaceUrl: true,
    });
    this.inspectionGradeService
      .customer_hirl_list(this.storedParams)
      .pipe(first())
      .subscribe(
        data => {
          this.entities = data.results;
          this.count = data.count;
          this.isLoading = false;
        },
        error => this.uiHelperService.handleMainRequestError(error)
      );

    this.inspectionGradeService
      .aggregate_by_letter_grade(this.storedParams)
      .pipe(first())
      .subscribe(inspectionGradeAggregateByLetterGrade => {
        this.inspectionGradeAggregateByLetterGrade =
          inspectionGradeAggregateByLetterGrade;
        const total =
          this.inspectionGradeAggregateByLetterGrade.a_grade +
          this.inspectionGradeAggregateByLetterGrade.b_grade +
          this.inspectionGradeAggregateByLetterGrade.c_grade +
          this.inspectionGradeAggregateByLetterGrade.d_grade +
          this.inspectionGradeAggregateByLetterGrade.f_grade;
        const grades =
          LetterGrade.A * this.inspectionGradeAggregateByLetterGrade.a_grade +
          LetterGrade.B * this.inspectionGradeAggregateByLetterGrade.b_grade +
          LetterGrade.C * this.inspectionGradeAggregateByLetterGrade.c_grade +
          LetterGrade.D * this.inspectionGradeAggregateByLetterGrade.d_grade +
          LetterGrade.F * this.inspectionGradeAggregateByLetterGrade.f_grade;
        const average = Math.round(grades / total);
        this.averageGrade = LetterGradeLabelMapping[average];
        this.aggregateByLetterGradeIsLoading = false;
      });
  }

  onSortChange($event: Sort) {
    this.storedParams.ordering = `${$event.direction === 'desc' ? '-' : ''}${
      $event.active
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  onPaginateChange($event: PageEvent) {
    this.uiHelperService.getUISettings().rowsPerPage = $event.pageSize;
    this.storedParams.page_size = $event.pageSize;
    this.storedParams.page = $event.pageIndex + 1;
    this.list();
  }

  onCreateReport($event: MouseEvent) {
    $event.preventDefault();

    this.store.dispatch(toggleLoading({ payload: true }));
    this.inspectionGradeService
      .create_approver_report(this.storedParams)
      .subscribe(
        asynchronousProcessedDocument => {
          const _ = this.router.navigate([
            'file-operation',
            'document',
            asynchronousProcessedDocument.id,
          ]);
        },
        error => this.uiHelperService.handleUserRequestError(error)
      );
  }

  onCreateVerifierQuarterReport($event: MouseEvent) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '45%',
      data: {
        title: 'Are you sure you want to continue ?',
        content: `By confirming this action you will send email report for ALL verifiers`,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      this.store.dispatch(toggleLoading({ payload: true }));
      this.inspectionGradeService
        .customer_hirl_inspection_grade_quarter_report()
        .pipe(first())
        .subscribe(
          _ => {
            this.store.dispatch(toggleLoading({ payload: false }));
          },
          error => this.uiHelperService.handleUserRequestError(error)
        );
    });
  }
}
