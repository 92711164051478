import { Component, OnDestroy, OnInit } from '@angular/core';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { User } from '@/data/core/models/user';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { MatDialog } from '@angular/material/dialog';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, takeUntil, map, mergeMap } from 'rxjs/operators';
import {
  CommunityService,
  CommunitySubdivisionParams,
} from '@/data/community/services/community.service';
import { Subdivision } from '@/data/subdivision/models/subdivision';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import * as _ from 'lodash';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-community-subdivision-list',
  templateUrl: './community-subdivision-list.component.html',
  styleUrls: ['./community-subdivision-list.component.scss'],
})
export class CommunitySubdivisionListComponent implements OnInit, OnDestroy {
  communityId: number;

  filterFromGroup: FormGroup;
  rows: Subdivision[];
  rowsCount: number;

  public isLoading = true;
  public currentUser: User;

  public defaultParams: CommunitySubdivisionParams =
    new CommunitySubdivisionParams(1, '', '-id');
  public storedParams: CommunitySubdivisionParams;

  private listSubscription$: Subscription;
  private componentDestroyed$ = new Subject();
  public displayedColumns = [
    'subdivision',
    'builder',
    'crossroads',
    'coordinates',
    'number_of_homes',
  ];

  constructor(
    private store: Store<AppState>,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private communityService: CommunityService,
    private uiHelperService: UIHelperService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.setupFilterForm();
    this.activatedRoute.params
      .pipe(
        takeUntil(this.componentDestroyed$),
        mergeMap(params => {
          this.rows = [];
          this.rowsCount = 0;

          return forkJoin({
            queryParams: this.activatedRoute.queryParams.pipe(first()),
            currentUser: this.store.select(getInfoUser).pipe(first()),
          }).pipe(
            map(({ queryParams, currentUser }, index) => {
              return { params, queryParams, currentUser };
            })
          );
        })
      )
      .subscribe(({ params, queryParams, currentUser }) => {
        this.storedParams = _.cloneDeep(this.defaultParams);
        this.storedParams.page_size =
          this.uiHelperService.getUISettings().rowsPerPage;
        this.storedParams.assignQueryParams(queryParams);
        this.hydrateForm();
        this.communityId = params.communityId;
        this.list();
      });
  }

  hydrateForm() {
    this.filterFromGroup.patchValue(this.storedParams, {
      emitEvent: false,
      onlySelf: true,
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  setupFilterForm() {
    this.filterFromGroup = this.fb.group({
      search: [null],
    });

    this.filterFromGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        const params = _.merge({}, this.defaultParams, values);
        delete params.ordering;

        this.storedParams = _.merge(this.storedParams, params);
        this.list();
      });
  }

  list() {
    this.isLoading = true;

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }

    this.listSubscription$ = this.communityService
      .subdivisions_list(this.communityId, this.storedParams)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.rows = data.results;
        this.rowsCount = data.count;
        this.isLoading = false;
      });
  }

  onAddNew($event: MouseEvent) {
    $event.preventDefault();
  }

  onPaginateChange($event: PageEvent) {
    this.uiHelperService.getUISettings().rowsPerPage = $event.pageSize;
    this.storedParams.page_size = $event.pageSize;
    this.storedParams.page = $event.pageIndex + 1;
    this.list();
  }

  onSortChange($event: Sort) {
    this.storedParams.ordering = `${$event.direction === 'desc' ? '-' : ''}${
      $event.active
    }`;
    this.storedParams.page = 1;
    this.list();
  }
}
