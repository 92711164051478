import { Component, Input } from '@angular/core';
import { BaseAppControlValueAccessor } from '../base-control-value-accessor.directive';
import { FormGroupDirective, NgControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import {
  selectEntity,
  selectModelEntitiesLabels,
} from '@/modules/simulation/state/simulation.selectors';
import { first, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-model-select',
  templateUrl: './model-select.component.html',
  styleUrls: ['./model-select.component.scss'],
})
export class ModelSelectComponent extends BaseAppControlValueAccessor {
  @Input() modelName: string;
  @Input() label: string;
  @Input() excluder = _ => false;
  @Input() optionLabel = model => model.name;

  items: { [id: string]: string };

  controlValueSubscription: Subscription;

  constructor(
    public ngControl: NgControl,
    private fgd: FormGroupDirective,
    private store: Store
  ) {
    super(ngControl, fgd);
  }

  updateSubsriptions() {
    if (this.controlValueSubscription) {
      this.controlValueSubscription.unsubscribe();
    }
    if (!this.control.value) return;

    this.controlValueSubscription = this.store
      .select(selectEntity(this.modelName, this.control.value))
      .subscribe(entity => {
        if (!entity) {
          this.items[this.control.value] = null;
        }
        this.items[this.control.value] = this.optionLabel(entity);
      });
  }

  updateItemsForSelection(opened: boolean) {
    if (!opened) return;

    this.items = {};
    this.store
      .select(
        selectModelEntitiesLabels(
          this.modelName,
          this.excluder,
          this.optionLabel
        )
      )
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(labels => {
        this.items = labels;
      });
  }

  ngOnInit() {
    super.ngOnInit();
    this.updateItemsForSelection(true);
    this.updateSubsriptions();
    this.control.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        this.updateItemsForSelection(true);
        this.updateSubsriptions();
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
