import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from '@/data/core/models/user';
import { forkJoin, Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute } from '@angular/router';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { filter, first, takeUntil } from 'rxjs/operators';
import { UserService } from '@/data/core/services/user.service';
import {
  getUser,
  getUserAccreditationsCount,
  getUserCompanyAccesses,
  getUserCustomerHIRLInspectionGradesCount,
  getUserEEPProgramHomeStatusesCertificationMetricsCount,
  getUserRaterRoles,
  getUserTasksCount,
  getUserTrainingsCount,
} from '@/state/selectors/user/user.selector';
import { toggleLoading } from '@/state/actions/app.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { NavigationService } from '@/shared/services/navigation-service.service';
import { UserPermissionService } from '@/modules/user/services/user-permission-service';
import { CompanyAccess } from '@/data/company/models';
import { ServerResponse } from '@/core/schemes/server-response';
import { MatDialog } from '@angular/material/dialog';
import {
  IUserChangeDialogData,
  UserChangeDialogComponent,
} from '@/modules/user/components/user-change-dialog/user-change-dialog.component';
import { UserRaterRole } from '@/data/core/models';
import { Geocode } from '@/data/geocoder/models';
import { ConfirmedGeocodeResponse } from '@/modules/geocoder/components/confirm-geocode-response-dialog/confirm-geocode-response';
import { resetUser } from '@/state/actions/user/user.actions';

@Component({
  selector: 'app-user-detail-page',
  templateUrl: './user-detail-page.component.html',
  styleUrls: ['./user-detail-page.component.scss'],
})
export class UserDetailPageComponent implements OnInit, OnDestroy {
  initialized = false;

  selectedIndex = 0;

  currentUser: User;
  entity: User;
  companyAccesses: CompanyAccess[];
  raterRoles: UserRaterRole[];

  accreditationsCount?: number = -1;
  inspectionGradesCount?: number = -1;
  tasksCount?: number = -1;
  trainingsCount?: number = -1;
  eepProgramHomeStatusesCertificationMetricsCount?: number = -1;

  companyConfirmedGeocodeResponse: ConfirmedGeocodeResponse;

  public changePasswordPermissionResponse: Observable<ObjectPermissionResponse>;
  public editPermissionResponse: Observable<ObjectPermissionResponse>;

  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private userService: UserService,
    private userPermissionService: UserPermissionService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private uiHelperService: UIHelperService,
    private navigationService: NavigationService
  ) {
    this.store
      .select(getUser)
      .pipe(
        filter(val => val !== null),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((entity: User) => {
        this.entity = entity;
        this.entitySetup();
      });

    this.store
      .select(getUserCompanyAccesses)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((companyAccesses: CompanyAccess[]) => {
        this.companyAccesses = companyAccesses;
      });

    this.store
      .select(getUserRaterRoles)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((userRaterRoles: UserRaterRole[]) => {
        this.raterRoles = userRaterRoles;
      });

    this.store
      .select(getUserAccreditationsCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((count: number) => {
        this.accreditationsCount = count;
      });

    this.store
      .select(getUserCustomerHIRLInspectionGradesCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((count: number) => {
        this.inspectionGradesCount = count;
      });

    this.store
      .select(getUserEEPProgramHomeStatusesCertificationMetricsCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((count: number) => {
        this.eepProgramHomeStatusesCertificationMetricsCount = count;
      });

    this.store
      .select(getUserTasksCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((count: number) => {
        this.tasksCount = count;
      });

    this.store
      .select(getUserTrainingsCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((count: number) => {
        this.trainingsCount = count;
      });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(params => {
        if (params.tab) {
          this.selectedIndex = Number(params.tab) || 0;
        }
      });

    this.activatedRoute.params
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(params => {
        this.loadEntity(params.userId);
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();

    this.store.dispatch(resetUser());
  }

  loadEntity(userId: number) {
    this.store.dispatch(toggleLoading({ payload: true }));

    const sources: { [k: string]: any } = {
      entity: this.userService.retrieve(userId).pipe(first()),
      companyAccessesServerResponse: this.userService
        .company_access(userId)
        .pipe(first()),
      raterRolesServerResponse: this.userService.rater_roles(userId),
    };

    forkJoin(sources).subscribe({
      next: ({
        entity,
        companyAccessesServerResponse,
        raterRolesServerResponse,
      }: {
        entity: User;
        companyAccessesServerResponse: ServerResponse<CompanyAccess>;
        raterRolesServerResponse: ServerResponse<UserRaterRole>;
      }) => {
        this.entity = entity;
        this.companyAccesses = companyAccessesServerResponse.results;
        this.raterRoles = raterRolesServerResponse.results;

        const geocode = new Geocode();
        geocode.raw_street_line1 = this.entity.company_info?.street_line1;
        geocode.raw_street_line2 = this.entity.company_info?.street_line2;
        geocode.raw_city = this.entity.company_info?.city;
        geocode.raw_city_info = this.entity.company_info?.city_info;
        geocode.raw_zipcode = this.entity.company_info?.zipcode;
        this.companyConfirmedGeocodeResponse = new ConfirmedGeocodeResponse({
          geocode: geocode,
        });

        this.entitySetup();
        this.store.dispatch(toggleLoading({ payload: false }));
        this.initialized = true;
      },
      error: (error: HttpErrorResponse) =>
        this.uiHelperService.handleMainRequestError(error),
    });
  }

  entitySetup() {
    this.changePasswordPermissionResponse =
      this.userPermissionService.canChangePassword(this.entity);
    this.editPermissionResponse = this.userPermissionService.canEdit(
      this.entity
    );
  }

  onEdit($event: MouseEvent) {
    $event.preventDefault();

    const dialogRef = this.dialog.open(UserChangeDialogComponent, {
      width: '90%',
      disableClose: true,
      autoFocus: false,
      data: {
        entity: this.entity,
      } as IUserChangeDialogData,
    });

    dialogRef.afterClosed().subscribe((entity?: PaymentRequest) => {
      if (!entity) {
        return;
      }
      this.uiHelperService.openSnackBar('Record Successfully Updated');
    });
  }
}
