import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, map } from 'rxjs/operators';
import { AppState } from '@/state/reducers';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import {
  FlatListTask,
  Task,
  TaskApprovalState,
} from '@/data/scheduling/models';
import { TaskService } from '@/data/scheduling/services';
import { User, UserInfo } from '@/data/core/models/user';
import { BasePermissionService } from '@/core/services/base-permission-service';

@Injectable({
  providedIn: 'root',
})
export class TaskPermissionService extends BasePermissionService {
  constructor(
    public store: Store<AppState>,
    private taskService: TaskService,
    private userService: TaskService
  ) {
    super(store);
  }

  canCreate(
    user: number | User | UserInfo
  ): Observable<ObjectPermissionResponse> {
    const currentUser = this.store.select(getInfoUser).pipe(first());
    const user$ = this.resolveEntity<User | UserInfo>(user);

    return forkJoin([currentUser, user$]).pipe(
      map(([currentUser, user]) => {
        return new ObjectPermissionResponse(true);
      })
    );
  }

  canEdit(
    task: number | Task | FlatListTask
  ): Observable<ObjectPermissionResponse> {
    const currentUser = this.store.select(getInfoUser).pipe(first());
    const entity$ = this.resolveEntity<Task | FlatListTask>(task);

    return forkJoin([currentUser, entity$]).pipe(
      map(([currentUser, entity]) => {
        if (
          entity.assignees.indexOf(currentUser.id) !== -1 ||
          entity.assigner === currentUser.id ||
          currentUser.is_superuser
        ) {
          return new ObjectPermissionResponse(true);
        }
        return new ObjectPermissionResponse(false);
      })
    );
  }

  canDelete(task: number | Task | FlatListTask) {
    return this.canEdit(task);
  }

  canChangeState(
    task: number | Task | FlatListTask
  ): Observable<ObjectPermissionResponse> {
    const currentUser = this.store.select(getInfoUser).pipe(first());
    let instance = of(task);

    if (typeof task === 'number') {
      instance = this.taskService
        .retrieve(task, { ignoreStore: true })
        .pipe(first());
    }

    return forkJoin([currentUser, instance]).pipe(
      map(([currentUser, instance]) => {
        instance = instance as Task;
        if (
          instance.approval_state === TaskApprovalState.approved ||
          instance.approval_state === TaskApprovalState.rejected
        ) {
          return new ObjectPermissionResponse(false);
        }
        if (
          instance.assignees.indexOf(currentUser.id) !== -1 ||
          currentUser.is_superuser
        ) {
          return new ObjectPermissionResponse(true);
        }
        return new ObjectPermissionResponse(false);
      })
    );
  }
}
