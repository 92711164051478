<div class="container" *ngIf="initialized">
    <div class="row mt-3">
        <div class="col-12">
            <h5>
                <button mat-raised-button appBackButton color="accent">
                    <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
                </button>
                Copy/Move Client Agreement/COI
            </h5>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <mat-divider></mat-divider>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <mat-stepper orientation="vertical" [linear]="true" #stepper>
                <mat-step [stepControl]="companyFromFormGroup">
                    <form [formGroup]="companyFromFormGroup">
                        <ng-template matStepLabel>Source Company</ng-template>

                        <div class="row">
                            <div class="col-6">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Company Type</mat-label>
                                    <mat-select formControlName="company_type_from">
                                        <mat-option *ngFor="let item of componentCompanyTypeDataMap | keyvalue"
                                                    [value]="item.key">
                                            {{ item.value.displayName }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error
                                            *ngIf="companyFromFormGroup.controls['company_type_from'].hasError('required')">
                                        This field is required
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row" *ngIf="companyFromFormGroup.get('company_type_from').value">
                            <div class="col-6">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>
                                        From {{ componentCompanyTypeDataMap[companyFromFormGroup.get('company_type_from').value].displayName }}</mat-label>
                                    <input matInput placeholder="Type to search" formControlName="company_from"
                                           #companyFromTrigger="matAutocompleteTrigger"
                                           [matAutocomplete]="autocompleteCompanyFrom">
                                    <mat-error
                                            *ngIf="companyFromFormGroup.controls['company_from'].hasError('required')">
                                        {{ componentCompanyTypeDataMap[companyFromFormGroup.get('company_type_from').value].displayName }}
                                        is required - Must select option from list
                                    </mat-error>
                                    <mat-error
                                            *ngIf="companyFromFormGroup.controls['company_from'].hasError('invalidObject')">
                                        Must select option from list
                                    </mat-error>
                                    <mat-autocomplete #autocompleteCompanyFrom="matAutocomplete" autoActiveFirstOption
                                                      [displayWith]="displayCompanyMenu">
                                        <mat-option
                                                *ngFor="let company of filteredCompaniesFrom"
                                                [value]="company">
                                            <app-company-display [company]="company"
                                                                 [displayType]="'nameAndAddress'"></app-company-display>
                                        </mat-option>
                                    </mat-autocomplete>
                                    <mat-spinner matSuffix diameter="20" *ngIf="companyFromIsLoading"></mat-spinner>
                                    <button mat-button
                                            *ngIf="companyFromFormGroup.get('company_from')?.value && !companyFromIsLoading"
                                            matSuffix mat-icon-button
                                            aria-label="Clear"
                                            (click)="onClearFromCompany($event, companyFromTrigger);">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                </mat-form-field>
                            </div>
                        </div>

                        <div>
                            <button mat-raised-button color="accent" matStepperNext>Next</button>
                        </div>
                    </form>
                </mat-step>
                <mat-step [stepControl]="companyToFormGroup">
                    <form [formGroup]="companyToFormGroup">
                        <ng-template matStepLabel>Destination Company(s)</ng-template>
                        <div formArrayName="companies_to">
                            <div class="card mb-2" *ngFor="let companyToControl of getCompanyToControls(); let i=index">
                                <div class="card-header">
                                    <div class="row">
                                        <div class="col-11 align-self-center">
                                            Company #{{ i + 1 }}
                                        </div>
                                        <div class="col-1 text-right">
                                            <button mat-button mat-icon-button
                                                    (click)="handleRemoveCompanyTo($event, i)" [disabled]="i === 0">
                                                <mat-icon>close</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body" [formGroupName]="i">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label>Company Type</mat-label>
                                                        <mat-select formControlName="company_type_to">
                                                            <mat-option
                                                                    *ngFor="let item of componentCompanyTypeDataMap | keyvalue"
                                                                    [value]="item.key">
                                                                {{ item.value.displayName }}
                                                            </mat-option>
                                                        </mat-select>
                                                        <mat-error
                                                                *ngIf="companyToControl.get('company_type_to').hasError('required')">
                                                            This field is required
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="companyToControl.get('company_type_to').value">
                                                <div class="col-6">
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label>
                                                            To {{ componentCompanyTypeDataMap[companyToControl.get('company_type_to').value].displayName }}</mat-label>
                                                        <input matInput placeholder="Type to search"
                                                               formControlName="company_to"
                                                               #companyFromTrigger="matAutocompleteTrigger"
                                                               [matAutocomplete]="autocompleteCompanyTo">
                                                        <mat-error
                                                                *ngIf="companyToControl.get('company_to').hasError('required')">
                                                            {{ componentCompanyTypeDataMap[companyToControl.get('company_type_to').value].displayName }}
                                                            is required - Must select option from list
                                                        </mat-error>
                                                        <mat-error
                                                                *ngIf="companyToControl.get('company_to').hasError('invalidObject')">
                                                            Must select option from list
                                                        </mat-error>
                                                        <mat-autocomplete #autocompleteCompanyTo="matAutocomplete"
                                                                          autoActiveFirstOption
                                                                          [displayWith]="displayCompanyMenu">
                                                            <mat-option
                                                                    *ngFor="let company of filteredCompaniesTo[i]"
                                                                    [value]="company">
                                                                <app-company-display [company]="company"
                                                                                     [displayType]="'nameAndAddress'"></app-company-display>
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                        <mat-spinner matSuffix diameter="20"
                                                                     *ngIf="companyToIsLoading[i]"></mat-spinner>
                                                        <button mat-button
                                                                *ngIf="companyToControl.get('company_to').value && !companyToIsLoading[i]"
                                                                matSuffix mat-icon-button
                                                                aria-label="Clear"
                                                                (click)="onClearToCompany($event, companyFromTrigger);">
                                                            <mat-icon>close</mat-icon>
                                                        </button>
                                                        <mat-hint>
                                                            <div *ngIf="companyToControl.get('company_to').value?.active_customer_hirl_builder_agreements_count !== undefined">
                                                                Active Client Agreements: {{ companyToControl.get('company_to').value?.active_customer_hirl_builder_agreements_count }}
                                                            </div>
                                                            <div *ngIf="companyToControl.get('company_to').value?.active_coi_document_count !== undefined">
                                                                Active Certificates Of Insurance:{{ companyToControl.get('company_to').value?.active_coi_document_count }}
                                                            </div>
                                                        </mat-hint>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 text-center">
                                <button mat-raised-button (click)="addCompanyTo()">Add Company</button>
                            </div>
                        </div>
                        <div>
                            <button mat-raised-button matStepperPrevious class="mr-2">Back</button>
                            <button mat-raised-button color="accent" matStepperNext>Next</button>
                        </div>
                    </form>
                </mat-step>
                <mat-step [stepControl]="optionsFormGroup">
                    <form [formGroup]="optionsFormGroup">
                        <ng-template matStepLabel>Data Selection</ng-template>
                        <div class="row">
                            <div class="col-12">
                                <mat-checkbox formControlName="copy_client_agreement">
                                    Copy Client Agreement (“Active” CA for destination company(s) will be set to “Expired” and CA from source company will be copied to destination company(s))
                                </mat-checkbox>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <mat-checkbox formControlName="copy_coi">
                                    Copy Certificate of Insurance (All COI’s for destination company(s) will be deleted and all COI(s) from source company will be copied to destination company(s))
                                </mat-checkbox>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <mat-checkbox formControlName="move_client_agreement">
                                    Move Client Agreement (CA from source company will be moved to destination company(s); source company will no longer have a CA)
                                </mat-checkbox>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <mat-checkbox formControlName="move_coi">
                                    Move Certificate of Insurance (All COI’s from source company will be moved to destination company(s); source company will no longer have any COI’s)
                                </mat-checkbox>
                            </div>
                        </div>
                        <div>
                            <button mat-raised-button matStepperPrevious class="mr-2">Back</button>
                            <button mat-raised-button color="accent" (click)="onCopyAction($event)">Next</button>
                        </div>
                    </form>
                </mat-step>
                <mat-step>
                    <ng-template matStepLabel>Done</ng-template>
                    <p>The following items have been created:</p>
                    <ul *ngIf="copiedResourcesToOtherCompanyResponse">
                        <li>
                            Client Agreements:
                        </li>
                        <li>
                            <ul *ngFor="let clientAgreementId of copiedResourcesToOtherCompanyResponse.client_agreements;">
                                <li>
                                    <a [routerLink]="['/', 'hi', 'builder-agreements', clientAgreementId]" target="_blank">
                                        Client Agreement {{ clientAgreementId }}
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            Certificate Of Insurances:
                            <ul>
                                <li *ngFor="let companyToControl of getCompanyToControls();">
                                    <a [routerLink]="['/', companyToControl.get('company_to').value.company_type, companyToControl.get('company_to').value.id]" target="_blank">
                                        {{ companyToControl.get('company_to').value.name }}
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <div>
                        <button mat-raised-button color="accent" (click)="reset()">Reset</button>
                    </div>
                </mat-step>
            </mat-stepper>
        </div>
    </div>
</div>