import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lbsToTonnes',
})
export class LbsToTonnesPipe implements PipeTransform {
  /**
   * This pipe converts pounds (lbs/yr) to metric tonnes (tonnes).
   *
   * @param value The value in pounds to be converted.
   * @returns The converted value in metric tonnes with two decimal places.
   */
  transform(value: number | null): number | null {
    if (value === null) {
      return null;
    }

    // Convert pounds to metric tonnes
    const tonnes = value / 2204.62;

    // Round to two decimal places
    return Math.round(tonnes * 100) / 100;
  }
}
