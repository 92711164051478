<h2 mat-dialog-title>
    Create Alternative Name
</h2>
<mat-dialog-content>
    <form [formGroup]="formGroup">
        <div class="row">
            <div class="col-12">
                <mat-form-field appearance="outline" class="mb-2 w-100">
                    <mat-label>Name</mat-label>
                    <input matInput type="text" placeholder="Enter Alternative Name" formControlName="name">
                </mat-form-field>
            </div>
        </div>
    </form>
</mat-dialog-content>
<div class="row">
    <div class="col-12">
        <hr>
    </div>
</div>
<div class="row">
    <div class="col-12 text-right">
        <button mat-raised-button color="accent" class="mr-2" (click)="onConfirm($event)">Confirm</button>
        <button mat-button color="accent" [mat-dialog-close]="null" cdkFocusInitial>Cancel</button>
    </div>
</div>
