import {
  AirSourceHeatPumpBackendDict,
  createAirSourceHeatPumpForm,
} from '@/data/simulation/models/mechanicals/AirSourceHeatPump';
import { BaseValidator } from './base.validator';
import { ModelErrors, StateErrors } from '../state/shared/base.state';

export class AirSourceHeatPumpValidator extends BaseValidator {
  static validateForm(
    airSourceHeatPump: AirSourceHeatPumpBackendDict
  ): ModelErrors<AirSourceHeatPumpBackendDict> {
    const airSourceHeatPumpForm =
      createAirSourceHeatPumpForm(airSourceHeatPump);
    return airSourceHeatPumpForm.getFieldAndModelErros();
  }

  static validate(
    airSourceHeatPumps: AirSourceHeatPumpBackendDict[]
  ): StateErrors<AirSourceHeatPumpBackendDict> {
    const errors = {};
    airSourceHeatPumps.forEach(airSourceHeatPump => {
      errors[airSourceHeatPump.id] = this.validateForm(airSourceHeatPump);
    });
    return errors;
  }
}
