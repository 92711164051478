import { Component, Input } from '@angular/core';
import { EditorNavigationService } from '../../services/editor-navigation.service';
import { StateModelName, StateModelNameLabels } from '../../state.registry';

@Component({
  selector: 'app-model-link',
  templateUrl: './model-link.component.html',
  styleUrls: ['./model-link.component.scss'],
})
export class ModelLinkComponent {
  @Input() entityName: StateModelName;
  @Input() entityId: string;
  @Input() title: string;

  stateModelNameLabels = StateModelNameLabels;

  constructor(public editorNavigationService: EditorNavigationService) {}

  handleClick(e) {
    this.editorNavigationService.focusElementOfTab(
      this.entityName,
      this.entityId
    );

    e.preventDefault();
    e.stopPropagation();
  }
}
