import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { axisModelMap } from '@/shared/constants/select-widget-model-map';

@Component({
  selector: 'app-mtm-display',
  templateUrl: './mtm-display.component.html',
  styleUrls: ['./mtm-display.component.scss'],
})
export class MtmDisplayComponent implements OnInit, OnDestroy {
  protected readonly modelsMap = axisModelMap;

  @Input() modelClassName: string;
  @Input() optionDisplay: (value: any) => string;

  @Input() list: any[];

  private componentDestroyed$ = new Subject();

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  onOptionDisplay(value: any) {
    if (value) {
      if (this.optionDisplay) {
        return this.optionDisplay.bind(this)(value);
      }
      return this.modelsMap[this.modelClassName].optionDisplay.bind(this)(
        value
      );
    }
    return null;
  }
}
