import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@/shared/shared.module';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FlatpageEditPageFormComponent } from './pages/flatpage-change-form/flatpage-edit-page-form.component';

@NgModule({
  declarations: [FlatpageEditPageFormComponent],
  imports: [CommonModule, SharedModule, CKEditorModule],
})
export class FlatpageModule {}
