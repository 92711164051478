import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  Geocode,
  GeocodeBrokeredResponse,
  IGeocodeMatches,
} from '@/data/geocoder/models';
import { GeocoderService } from '@/data/geocoder/services/geocoder.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ConfirmedGeocodeResponse,
  GeocodeAddressDisplayFormat,
  GeocodeAddressInfo,
} from '@/modules/geocoder/components/confirm-geocode-response-dialog/confirm-geocode-response';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { first, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { conditionalValidator } from '@/core/validators';

export class HomeAddressConfirmedGeocodeResponse extends ConfirmedGeocodeResponse {
  lotNumber?: string;

  constructor(
    {
      lotNumber,
      geocode,
      geocodeBrokeredResponse,
      displayAddressFormat,
    }: {
      lotNumber?: string;
      geocode?: Geocode;
      geocodeBrokeredResponse?: GeocodeBrokeredResponse;
      displayAddressFormat?: GeocodeAddressDisplayFormat;
    } = { displayAddressFormat: GeocodeAddressDisplayFormat.default }
  ) {
    super({ geocode, geocodeBrokeredResponse, displayAddressFormat });
    this.lotNumber = lotNumber;
  }

  get displayAddress(): string {
    let address = super.displayAddress;
    if (address && this.lotNumber) {
      address = `${address} Lot Number: ${this.lotNumber}`;
    }
    if (!address && this.lotNumber) {
      address = `${this.lotNumber}`;
    }
    return address;
  }

  isEqual(
    homeAddressConfirmedGeocodeResponse: HomeAddressConfirmedGeocodeResponse | null
  ): boolean {
    const addressInfo = homeAddressConfirmedGeocodeResponse
      ? homeAddressConfirmedGeocodeResponse.addressInfo
      : null;
    return (
      this.addressInfo?.isEqual(addressInfo) &&
      this.lotNumber === homeAddressConfirmedGeocodeResponse?.lotNumber
    );
  }
}

export enum HomeAddressToGeocodeResponseDialogStep {
  initial = 'initial',
  confirmAddress = 'confirm_address',
  // makes street_line1 as optional field and allows user to select location on map
  approximateAddress = 'approximate_address',
}

export class HomeAddressToGeocodeResponseDialogData {
  lotNumber?: string;
  geocodeAddressInfo?: GeocodeAddressInfo;
  approximateAddress?: boolean;

  constructor({
    lotNumber,
    geocodeAddressInfo,
    approximateAddress,
  }: {
    lotNumber?: string;
    geocodeAddressInfo?: GeocodeAddressInfo;
    approximateAddress?: boolean;
  } = {}) {
    this.lotNumber = lotNumber;
    this.geocodeAddressInfo = geocodeAddressInfo;
    this.approximateAddress =
      approximateAddress !== undefined ? approximateAddress : false;
  }
}

@Component({
  selector: 'app-home-address-to-geocode-response-dialog',
  templateUrl: './home-address-to-geocode-response-dialog.component.html',
  styleUrls: ['./home-address-to-geocode-response-dialog.component.scss'],
})
export class HomeAddressToGeocodeResponseDialogComponent
  implements OnInit, OnDestroy
{
  protected HomeAddressToGeocodeResponseDialogStep =
    HomeAddressToGeocodeResponseDialogStep;
  public step: HomeAddressToGeocodeResponseDialogStep;

  public form: FormGroup;
  public geocode?: Geocode;
  public rawAddress?: string;
  public isLoading = false;

  @ViewChild(GoogleMap, { static: false }) googleMapsMap: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) googleMapsInfo: MapInfoWindow;
  public googleMapsCenter: google.maps.LatLngLiteral;
  public googleMapsOptions: google.maps.MapOptions = {
    disableDoubleClickZoom: true,
  };
  public googleMapsMarkers = [];
  public googleMapsInfoContent = '';

  private componentDestroyed$ = new Subject();

  constructor(
    public fb: FormBuilder,
    public geocoderService: GeocoderService,
    public dialogRef: MatDialogRef<HomeAddressToGeocodeResponseDialogComponent>,
    public uiHelperService: UIHelperService,
    @Inject(MAT_DIALOG_DATA) public data: HomeAddressToGeocodeResponseDialogData
  ) {}

  ngOnInit() {
    this.toInitialStep();
    this.prepareForm();
    if (this.data?.geocodeAddressInfo) {
      this.form.patchValue(
        {
          lot_number: this.data.lotNumber,
          street_line1: this.data.geocodeAddressInfo.street_line1,
          street_line2: this.data.geocodeAddressInfo.street_line2 || '',
          city_info: this.data.geocodeAddressInfo.city_info,
          zipcode: this.data.geocodeAddressInfo.zipcode,
          lat: this.data.geocodeAddressInfo.lat,
          lng: this.data.geocodeAddressInfo.lng,
        },
        { emitEvent: false, onlySelf: true }
      );
    }
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  prepareForm() {
    this.form = this.fb.group({
      lot_number: ['', Validators.maxLength(30)],
      street_line1: [
        '',
        [
          conditionalValidator(
            () => this.data?.approximateAddress !== true,
            Validators.required
          ),
        ],
      ],
      street_line2: [''],
      city_info: ['', [Validators.required]],
      lat: [null],
      lng: [null],
      zipcode: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(5)]),
      ],
    });

    this.form
      .get('city_info')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe(value => {
        if (
          this.step ===
          HomeAddressToGeocodeResponseDialogStep.approximateAddress
        ) {
          this.form.patchValue(
            {
              lat: this.form.value.city_info?.latitude,
              lng: this.form.value.city_info?.longitude,
            },
            { emitEvent: false }
          );
          this.googleMapsUpdateMarker(
            this.form.value.city_info?.latitude,
            this.form.value.city_info?.longitude
          );
        }
      });

    this.form
      .get('lat')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe(value => {
        this.googleMapsUpdateMarker(value, this.form.value.lng);
      });

    this.form
      .get('lng')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe(value => {
        this.googleMapsUpdateMarker(this.form.value.lat, value);
      });
  }

  confirmGeocodeAddress(geocode: Geocode) {
    this.geocode = geocode;
    const confirmedGeocodeResponse = new ConfirmedGeocodeResponse({ geocode });
    this.rawAddress = confirmedGeocodeResponse.displayAddress;
  }

  handleAdd() {
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      return;
    }

    if (this.form.value.street_line1) {
      this.isLoading = true;
      this.form.patchValue({
        lat: null,
        lng: null,
      });
      this.findMatches().subscribe(
        geocode => {
          this.isLoading = false;
          this.step = HomeAddressToGeocodeResponseDialogStep.confirmAddress;
          this.confirmGeocodeAddress(geocode);
        },
        error => {
          this.uiHelperService.handleUserRequestError(error);
          this.isLoading = false;
        }
      );
    } else {
      this.toApproximateAddressStep();
    }
  }

  findMatches() {
    return this.geocoderService
      .matches({
        street_line1: this.form.get('street_line1').value,
        street_line2: this.form.get('street_line2').value,
        city: this.form.get('city_info').value.id,
        zipcode: this.form.get('zipcode').value,
        lat: this.form.get('lat').value,
        lng: this.form.get('lng').value,
      } as IGeocodeMatches)
      .pipe(takeUntil(this.componentDestroyed$), first());
  }

  approximateAddressConfirm() {
    this.isLoading = true;
    this.findMatches().subscribe(
      geocode => {
        const dialogResult = new HomeAddressConfirmedGeocodeResponse({
          lotNumber: this.form.get('lot_number').value,
          geocode: geocode,
          geocodeBrokeredResponse: null,
        });

        this.dialogRef.close(dialogResult);
      },
      error => {
        this.uiHelperService.handleUserRequestError(error);
        this.isLoading = false;
      }
    );
  }

  handleConfirmAddress(geocodeBrokeredResponse: GeocodeBrokeredResponse) {
    const dialogResult = new HomeAddressConfirmedGeocodeResponse({
      lotNumber: this.form.get('lot_number').value,
      geocode: this.geocode,
      geocodeBrokeredResponse: geocodeBrokeredResponse,
    });
    this.dialogRef.close(dialogResult);
  }

  handleUseAddressAsEntered() {
    const dialogResult = new HomeAddressConfirmedGeocodeResponse({
      lotNumber: this.form.get('lot_number').value,
      geocode: this.geocode,
      geocodeBrokeredResponse: null,
    });
    this.dialogRef.close(dialogResult);
  }

  toInitialStep($event?: MouseEvent) {
    $event?.preventDefault();
    this.geocode = null;
    this.rawAddress = null;

    this.step = HomeAddressToGeocodeResponseDialogStep.initial;
    this.dialogRef.updateSize('40%');
  }

  toApproximateAddressStep($event?: MouseEvent) {
    $event?.preventDefault();
    this.dialogRef.updateSize('70%');
    this.step = HomeAddressToGeocodeResponseDialogStep.approximateAddress;

    const lat = this.form.value.lat || this.form.value.city_info?.latitude;
    const lng = this.form.value.lng || this.form.value.city_info?.longitude;

    this.form.patchValue(
      {
        lat,
        lng,
      },
      { emitEvent: false }
    );
    this.googleMapsUpdateMarker(lat, lng);
  }

  /**
   * Close dialog and return empty result
   */
  handleClose() {
    this.dialogRef.close(null);
  }

  googleMapsUpdateMarker(lat?: number, lng?: number) {
    if (!lat || !lng) {
      return;
    }

    this.googleMapsCenter = {
      lat: lat,
      lng: lng,
    };

    const marker = {
      position: {
        lat: lat,
        lng: lng,
      },
      label: {
        color: 'black',
        text: `Move marker on position`,
      },
      title: `Marker`,
      info: `Lat: ${lat}\n Lng: ${lng}`,
      options: {
        draggable: true,
        animation: google.maps.Animation.DROP,
      },
    };

    this.googleMapsMarkers = [marker];
  }

  googleMapsOpenInfo(marker: MapMarker, content: string) {
    this.googleMapsInfoContent = content;
    this.googleMapsInfo.open(marker);
  }

  onDragend(marker: MapMarker, $event: any) {
    const lat = $event.latLng.lat();
    const lng = $event.latLng.lng();
    this.googleMapsInfoContent = `Lat: ${lat}\n Lng: ${lng}`;
    this.form.patchValue(
      {
        lat: lat,
        lng: lng,
      },
      { emitEvent: false }
    );
  }
}
