<nav class="navbar navbar-expand">
  <div
    style="
      margin-left: auto;
      margin-right: auto;
      min-width: 960px;
      max-width: 1170px;
    "
  >
    <div class="navbar-collapse">
      <a
        class="navbar-brand"
        [routerLink]="['/']"
        (click)="activeMenuItem = ''"
        ngSwitch="{{ hostname }}"
      >
        <img
          src="assets/axis_logo.png"
          height="40"
          width="100"
          *ngSwitchDefault
        />
        <img
          src="assets/home_innovations_square.svg"
          height="48"
          width="48"
          *ngSwitchCase="'homeinnovation.pivotalenergy.net'"
        />
      </a>
      <ul class="navbar-nav mr-auto">
        <li
          class="nav-item"
          *ngFor="let link of menu$ | async"
          routerLinkActive="active"
        >
          <a
            class="nav-link"
            *ngIf="link.children.length == 0"
            [routerLink]="[link.url]"
            (click)="activeMenuItem = link.title"
          >
            {{ link.title }}
          </a>

          <a
            class="nav-link dropdown-toggle"
            role="button"
            [matMenuTriggerFor]="navMenu"
            *ngIf="link.children.length > 0"
            (click)="activeMenuItem = link.title"
          >
            {{ link.title }}
          </a>
          <mat-menu #navMenu="matMenu" class="menu">
            <ng-container *ngFor="let child of link.children">
              <mat-divider *ngIf="child.add_divider"></mat-divider>
              <button mat-menu-item [disabled]="true" *ngIf="child.add_header">
                {{ child.add_header }}
              </button>
              <button
                mat-menu-item
                *ngIf="!child.attrs?.target"
                [routerLink]="[child.url]"
                routerLinkActive="active-link"
              >
                <span *ngIf="child.add_indent">&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <span [innerHTML]="child.title"></span>
              </button>
              <a
                [target]="child.attrs?.target"
                *ngIf="!child.add_header && child.attrs?.target"
                [href]="child.url"
                class="text-decoration-none"
              >
                <button mat-menu-item>
                  <span *ngIf="child.add_indent">&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  <span [innerHTML]="child.title"></span>
                </button>
              </a>
            </ng-container>
          </mat-menu>
        </li>
      </ul>
      <div *ngIf="!(authData$ | async)" class="form-inline my-2 my-lg-0">
        <div class="dropdown">
          <button
            class="btn btn-sm btn-light text-dark my-2 my-sm-0 ml-1"
            [routerLink]="['/', 'auth', 'login']"
          >
            <fa-icon [icon]="['fas', 'sign-in-alt']"></fa-icon>
            <span class="align-middle ml-1">Sign In</span>
          </button>
          <button
            class="btn btn-sm btn-light text-dark my-2 my-sm-0 ml-1"
            [routerLink]="['/', 'auth', 'register', 'ex']"
          >
            <fa-icon [icon]="['fas', 'user-plus']"></fa-icon>
            <span class="align-middle ml-1">Register</span>
          </button>
        </div>
      </div>
      <div *ngIf="currentUser$ | async as currentUser">
        <div
          *ngIf="authData$ | async as authData"
          class="form-inline my-2 my-lg-0"
        >
          <input
            type="text"
            class="form-control pull-right mr-3"
            placeholder="Search"
            value=""
            (keyup.enter)="search($event.target.value)"
            (focus)="searchInputWidth = 160"
            (focusout)="searchInputWidth = 90"
            [style.width.px]="searchInputWidth"
          />

          <app-message-dropdown></app-message-dropdown>
          <app-nav-impersonate
            *ngIf="
              currentUser.is_superuser || authData.impersonated;
              else logoutButton
            "
          ></app-nav-impersonate>
          <ng-template #logoutButton>
            <a
              href="#"
              style="color: #ffffff"
              (click)="onLogout($event)"
              matTooltip="Logout"
            >
              <fa-icon [icon]="['fas', 'sign-out-alt']"></fa-icon>
            </a>
          </ng-template>
          <div class="beta ml-3" *ngIf="currentUser.is_superuser">
            <a
              href="#"
              (click)="onToggleBeta($event)"
              matTooltip="Turn on Beta to see improved pages"
            >
              <fa-icon
                [icon]="['fas', 'power-off']"
                [ngClass]="{
                  'text-danger': !currentUser.show_beta,
                  'text-success': currentUser.show_beta
                }"
              ></fa-icon>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
<div class="navbar-extras" *ngIf="currentUser$ | async as currentUser">
  <div class="container">
    <div class="row">
      <div class="col-6">
        <span class="current-company">
          <app-company-display
            [company]="currentUser.company_info"
            displayType="nameAndType"
          ></app-company-display>
          <ng-container *ngIf="currentUser.is_superuser">
            (
            <a
              href="#"
              (click)="showChangeCompanySuperuserDialog($event)"
              class="change-company-link"
              >Change</a
            >
            )
          </ng-container>
          <ng-container *ngIf="!currentUser.is_superuser">
            (
            <a
              href="#"
              (click)="showChangeCompanyDialog($event)"
              class="change-company-link"
              >Change</a
            >
            )
          </ng-container>
        </span>
      </div>
    </div>
  </div>
</div>
