<h2 mat-dialog-title>
  {{ this.config.title }}
</h2>
<mat-dialog-content>
  <div class="d-flex flex-column">
    <div class="d-flex-column flex-fill">
      <div class="flex-fill">
        <h5>
          1. Manufacturer must have Not Expired, Countersigned Agreement
          <fa-icon
            [icon]="entity.countersigned_agreement?.id ? ['fas', 'check'] : ['fas', 'minus'] "
            [ngClass]="{'text-success': entity.countersigned_agreement?.id, 'text-danger': !entity.countersigned_agreement?.id}"
          ></fa-icon>
        </h5>
      </div>
      <div class="flex-fill pl-4">
        <ng-container *ngIf="!entity.product_agreement?.id">Not created</ng-container>
        <app-hirl-product-agreement-display [hirlProductAgreement]="entity.product_agreement" displayType="nameAsLinkAndStatus" *ngIf="entity.product_agreement?.id"></app-hirl-product-agreement-display>
        <div *ngIf="entity.countersigned_agreement?.id && (entity.product_agreement?.id !== entity.countersigned_agreement?.id)">
          <app-hirl-product-agreement-display [hirlProductAgreement]="entity.countersigned_agreement" displayType="nameAsLinkAndStatus" *ngIf="entity.countersigned_agreement?.id"></app-hirl-product-agreement-display>
        </div>
      </div>
    </div>

    <div class="d-flex-column flex-fill">
      <div class="flex-fill">
        <h5>
          2. Not Expired Certificate Of Insurance
          <fa-icon
            [icon]="entity.not_expired_manufacturer_general_liability_coi?.id ? ['fas', 'check'] : ['fas', 'minus'] "
            [ngClass]="{'text-success': entity.not_expired_manufacturer_general_liability_coi?.id, 'text-danger': !entity.not_expired_manufacturer_general_liability_coi?.id}"
          ></fa-icon>
        </h5>
      </div>
      <div class="flex-fill pl-4">
        <ng-container *ngIf="!entity.manufacturer_general_liability_coi?.id">Not created</ng-container>
        <app-coi-document-display [coiDocument]="entity.manufacturer_general_liability_coi" displayType="nameAsLinkAndDates" *ngIf="entity.manufacturer_general_liability_coi?.id"></app-coi-document-display>
        <ng-container *ngIf="entity.manufacturer_general_liability_coi?.id && (entity.manufacturer_general_liability_coi?.id !== entity.not_expired_manufacturer_general_liability_coi?.id)">
          <hr>
          <app-coi-document-display [coiDocument]="entity.not_expired_manufacturer_general_liability_coi" displayType="nameAsLinkAndDates" *ngIf="entity.not_expired_manufacturer_general_liability_coi?.id"></app-coi-document-display>
        </ng-container>
      </div>
    </div>

    <div class="d-flex-column flex-fill">
      <div class="flex-fill">
        <h5>
          3. Paid Initial Fee
          <fa-icon
            [icon]="entity.paid_non_expired_initial_fee?.id ? ['fas', 'check'] : ['fas', 'minus'] "
            [ngClass]="{'text-success': entity.paid_non_expired_initial_fee?.id, 'text-danger': !entity.paid_non_expired_initial_fee?.id}"
          ></fa-icon>
        </h5>
      </div>
      <div class="flex-fill pl-4">
        <ng-container *ngIf="!entity.payment_request_initial_fee?.id">Not created</ng-container>
        <app-payment-request-display [paymentRequest]="entity.payment_request_initial_fee" displayType="nameAsLinkAndStatus" *ngIf="entity.payment_request_initial_fee?.id"></app-payment-request-display>
        <app-invoice-display [invoice]="entity?.payment_request_initial_fee?.invoice_info" displayType="nameAsLinkAndStatus" *ngIf="entity?.payment_request_initial_fee?.invoice_info"></app-invoice-display>
        <div *ngIf="entity.paid_non_expired_initial_fee?.id && (entity.payment_request_initial_fee?.id !== entity.paid_non_expired_initial_fee?.id)">
          <app-payment-request-display [paymentRequest]="entity.paid_non_expired_initial_fee" displayType="nameAsLinkAndStatus" *ngIf="entity.paid_non_expired_initial_fee?.id"></app-payment-request-display>
          <app-invoice-display [invoice]="entity?.paid_non_expired_initial_fee?.invoice_info" displayType="nameAsLinkAndStatus" *ngIf="entity?.paid_non_expired_initial_fee?.invoice_info"></app-invoice-display>
        </div>
      </div>
    </div>

    <div class="d-flex-column flex-fill">
      <div class="flex-fill">
        <h5>
          4. Paid Not Expired Product Fee
          <fa-icon
            [icon]="entity.paid_non_expired_payment_request?.id ? ['fas', 'check'] : ['fas', 'minus'] "
            [ngClass]="{'text-success': entity.paid_non_expired_payment_request?.id, 'text-danger': !entity.paid_non_expired_payment_request?.id}"
          ></fa-icon>
        </h5>
      </div>
      <div class="flex-fill pl-4">
        <ng-container *ngIf="!entity.product_fee_payment_request?.id">Not created</ng-container>
        <app-payment-request-display [paymentRequest]="entity.product_fee_payment_request" displayType="nameAsLinkAndStatus" *ngIf="entity.product_fee_payment_request?.id"></app-payment-request-display>
        <div>
          <app-invoice-display [invoice]="entity?.product_fee_payment_request?.invoice_info" displayType="nameAsLinkAndStatus" *ngIf="entity?.product_fee_payment_request?.invoice_info"></app-invoice-display>
        </div>
        <ng-container *ngIf="entity.paid_non_expired_payment_request?.id && (entity.product_fee_payment_request?.id !== entity.paid_non_expired_payment_request?.id)">
          <hr>
          <app-payment-request-display [paymentRequest]="entity.paid_non_expired_payment_request" displayType="nameAsLinkAndStatus" *ngIf="entity.paid_non_expired_payment_request?.id"></app-payment-request-display>
          <div>
            <app-invoice-display [invoice]="entity?.paid_non_expired_payment_request?.invoice_info" displayType="nameAsLinkAndStatus" *ngIf="entity?.paid_non_expired_payment_request?.invoice_info"></app-invoice-display>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <app-permission-mat-button [permissionResponse]="certifyPermissionResponse" (action)="onPublish($event)" class="mr-1">
    Certify
  </app-permission-mat-button>
  <button mat-button mat-raised-button color="primary" [mat-dialog-close]="true">
    Close
  </button>
</mat-dialog-actions>
