<div class="container" *ngIf="initialized">
  <div class="row">
    <div class="col-12">
      <div class="row mb-2">
        <div class="col-6">
          <h4>
            <span *ngIf="!isLoading">{{ rowsCount | number }}</span>
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" *ngIf="isLoading"></fa-icon>
            COI Dashboard
          </h4>
        </div>
        <div class="col-6 text-right">
          <button mat-raised-button color="accent" (click)="exportAll($event);">Export All</button>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <hr>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <div class="row">
            <div class="col-12">
              <form [formGroup]="filterFromGroup" class="card">
                <h5 class="card-header">
                  Filters
                  <a href="#" class="small" (click)="resetFilters($event);">Reset filters</a>
                </h5>
                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>Search</mat-label>
                        <app-search-input formControlName="search"></app-search-input>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Requirements Met</mat-label>
                        <mat-select formControlName="coi_requirements_met">
                          <mat-option value="">
                            All
                          </mat-option>
                          <mat-option value="met">
                            Met
                          </mat-option>
                          <mat-option value="not_met">
                            Not Met
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-9">
          <div class="row" *ngIf="isLoading">
            <div class="col-12">
              <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
            </div>
          </div>
          <table mat-table
                 [dataSource]="rows"
                 class="table"
                 matSort
                 [matSortActive]="storedParams.toTableSort().active"
                 [matSortDirection]="storedParams.toTableSort().direction"
                 (matSortChange)="onSortChange($event)">

            <ng-container matColumnDef="first_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Verifier Name</th>
              <td mat-cell *matCellDef="let row">
                <app-user-display [user]="row" displayType="onlyNameAsLink"></app-user-display>
              </td>
            </ng-container>

            <ng-container matColumnDef="company__name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Company Name</th>
              <td mat-cell *matCellDef="let row">
                <app-company-display [company]="row.company_info" displayType="onlyNameAsLink"></app-company-display>
              </td>
            </ng-container>

            <ng-container matColumnDef="general_liability">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>General Liability</th>
              <td mat-cell *matCellDef="let row">
                {{ row.general_liability | date: "MM/dd/yyyy" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="professional_liability">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Professional Liability</th>
              <td mat-cell *matCellDef="let row">
                {{ row.professional_liability | date: "MM/dd/yyyy" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="automobile_liability">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Auto</th>
              <td mat-cell *matCellDef="let row">
                {{ row.automobile_liability | date: "MM/dd/yyyy" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="umbrella_liability">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Umbrella</th>
              <td mat-cell *matCellDef="let row">
                {{ row.umbrella_liability | date: "MM/dd/yyyy" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="workers_compensation">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Workers Comp</th>
              <td mat-cell *matCellDef="let row">
                {{ row.workers_compensation | date: "MM/dd/yyyy" }}
              </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>

          <div class="d-flex flex-row">
            <div class="align-self-center">
              <button
                  mat-raised-button
                  (click)="exportToCSV($event)"
                  [disabled]="isLoading"
                  class="mr-2"
                  matTooltip="Export Data to CSV file"
              >
                CSV
              </button>
              <button
                  mat-raised-button
                  (click)="exportToExcel($event)"
                  [disabled]="isLoading"
                  matTooltip="Export Data to Excel file"
              >
                Excel
              </button>
            </div>
            <div class="d-flex flex-fill justify-content-end">
              <mat-paginator [length]="rowsCount"
                             [pageIndex]="$any(storedParams.page) - 1"
                             [pageSize]="storedParams.page_size"
                             (page)="onPaginateChange($event)"
                             aria-label="Select page">
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
