<div class="row">
  <div class="col-12">
    <div class="row mb-2">
      <div class="col-9">
      </div>
      <div class="col-3 text-right">
        <app-permission-mat-button [permissionResponse]="createPermissionResponse" (action)="create($event)">
          Add New
        </app-permission-mat-button>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <app-table-filters [formGroup]="filterFormGroup" [params]="this.storedParams"
                           [overwritePropertyValues]="overwritePropertyValues"
                           [overwritePropertyLabels]="overwritePropertyLabels"
                           [excludedProperties]="excludedProperties">
          <mat-accordion multi>
            <mat-expansion-panel [expanded]="true">
              <div class="row">
                <div class="col-12">
                  <mat-form-field class="w-100">
                    <mat-label>Search</mat-label>
                    <app-search-input formControlName="search"
                                      placeholder="Type to search something"></app-search-input>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <mat-form-field class="w-100">
                    <mat-label>Status</mat-label>
                    <mat-select formControlName="state" [multiple]="true">
                      <mat-option *ngFor="let item of AccreditationStateLabelMapping | keyvalue " [value]="item.key">
                        {{ item.value }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <mat-form-field class="w-100">
                    <mat-label>Role</mat-label>
                    <mat-select formControlName="role" [multiple]="true">
                      <mat-option *ngFor="let item of AccreditationRoleLabelMapping | keyvalue " [value]="item.key">
                        {{ item.value }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </app-table-filters>
      </div>
      <div class="col-9">
        <div class="row" *ngIf="isLoading">
          <div class="col-12">
            <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
          </div>
        </div>
        <table mat-table
               [dataSource]="rows"
               class="table"
               matSort
               [matSortActive]="storedParams.toTableSort().active"
               [matSortDirection]="storedParams.toTableSort().direction"
               (matSortChange)="onSortChange($event)">

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Program Name</th>
            <td mat-cell *matCellDef="let row">
              {{ AccreditationNameLabelMapping[row.name] || row.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="accreditation_id">
            <th mat-header-cell *matHeaderCellDef>Accreditation ID</th>
            <td mat-cell *matCellDef="let row">
              {{ row.accreditation_id }}
            </td>
          </ng-container>

          <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let row">
              {{ AccreditationStateLabelMapping[row.state] }}
            </td>
          </ng-container>

          <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef>Role</th>
            <td mat-cell *matCellDef="let row">
              {{ AccreditationRoleLabelMapping[row.role] }}
            </td>
          </ng-container>

          <ng-container matColumnDef="date_initial">
            <th mat-header-cell *matHeaderCellDef>Start Date</th>
            <td mat-cell *matCellDef="let row">
              {{ row.date_initial | utcDate  | date : "MM/dd/yyyy" :'UTC' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="expiration_date">
            <th mat-header-cell *matHeaderCellDef>Expiration Date</th>
            <td mat-cell *matCellDef="let row">
              {{ row.expiration_date | utcDate  | date : "MM/dd/yyyy" :'UTC' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="accreditation_cycle">
            <th mat-header-cell *matHeaderCellDef>Cycle</th>
            <td mat-cell *matCellDef="let row">
              {{ AccreditationCycleLabelMapping[row.accreditation_cycle] }}
            </td>
          </ng-container>

          <ng-container matColumnDef="notes">
            <th mat-header-cell *matHeaderCellDef>Notes</th>
            <td mat-cell *matCellDef="let row">
              {{ row.notes }}
            </td>
          </ng-container>


          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let row">
              <app-permission-mat-button [permissionResponse]="permissions[row.id]['canGenerateCertificate']" displayType="asLink" (action)="generateCertificate($event, row);" class="mr-2">
                <fa-icon [icon]="['fas', 'file']"></fa-icon>
              </app-permission-mat-button>
              <app-permission-mat-button [permissionResponse]="permissions[row.id]['canEdit']" displayType="asLink" (action)="edit($event, row);" class="mr-2">
                <fa-icon [icon]="['fas', 'edit']" matTooltip="Edit"></fa-icon>
              </app-permission-mat-button>
              <app-permission-mat-button [permissionResponse]="permissions[row.id]['canDelete']" displayType="asLink" (action)="delete($event, row);" class="mr-2">
                <fa-icon [icon]="['fas', 'trash']" matTooltip="Delete"></fa-icon>
              </app-permission-mat-button>
              <app-history-mat-button displayType="asLink" [objectId]="row.id" [modelClassName]="'accreditation'">
                <fa-icon [icon]="['fas', 'list']" matTooltip="History"></fa-icon>
              </app-history-mat-button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [length]="rowsCount"
                       [pageIndex]="$any(storedParams.page) - 1"
                       [pageSize]="storedParams.page_size"
                       (page)="onPaginateChange($event)"
                       aria-label="Select page">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
