import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { first, map } from 'rxjs/operators';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';
import { Store } from '@ngrx/store';
import { getInfoUser } from '@/state/selectors/info.selector';
import { AppState } from '@/state/reducers';
import { User } from '@/data/core/models/user';
import { InspectionGrade } from '@/data/user-management/models';
import { BasePermissionService } from '@/core/services/base-permission-service';
import { InspectionGradeService } from '@/data/user-management/services/inspection-grade.service';

@Injectable({
  providedIn: 'root',
})
export class InspectionGradePermissionService extends BasePermissionService {
  applicableCompanySlugs: string[] = [
    'provider-washington-state-university-extension-ene',
    'provider-home-innovation-research-labs',
  ];

  constructor(
    public store: Store<AppState>,
    private accreditationService: InspectionGradeService
  ) {
    super(store);
  }

  canEdit(
    inspectionGrade: InspectionGrade | number
  ): Observable<ObjectPermissionResponse> {
    const inspectionGrade$: Observable<InspectionGrade> =
      this.resolveEntity<InspectionGrade>(inspectionGrade);
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: inspectionGrade$,
    }).pipe(
      map(
        ({
          currentUser,
          entity,
        }: {
          currentUser: User;
          entity: InspectionGrade;
        }) => {
          if (currentUser.is_superuser) {
            return new ObjectPermissionResponse(true);
          }
          if (
            this.applicableCompanySlugs.includes(currentUser.company_info?.slug)
          ) {
            return new ObjectPermissionResponse(true);
          }
          return new ObjectPermissionResponse(false);
        }
      )
    );
  }

  canDelete(
    inspectionGrade: InspectionGrade | number
  ): Observable<ObjectPermissionResponse> {
    const inspectionGrade$: Observable<InspectionGrade> =
      this.resolveEntity<InspectionGrade>(inspectionGrade);

    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: inspectionGrade$,
    }).pipe(
      map(
        ({
          currentUser,
          entity,
        }: {
          currentUser: User;
          entity: InspectionGrade;
        }) => {
          if (currentUser.is_superuser) {
            return new ObjectPermissionResponse(true);
          }
          if (
            this.applicableCompanySlugs.includes(currentUser.company_info?.slug)
          ) {
            return new ObjectPermissionResponse(true);
          }
          return new ObjectPermissionResponse(false);
        }
      )
    );
  }

  canPerformCustomerHIRLInspectionGradeQuarterReport(): Observable<ObjectPermissionResponse> {
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
    }).pipe(
      map(({ currentUser }) => {
        if (
          (currentUser.company_info?.slug ===
            CustomerHIRLSettings.companySlug &&
            currentUser.is_company_admin) ||
          currentUser.is_superuser
        ) {
          return new ObjectPermissionResponse(true);
        }
        return new ObjectPermissionResponse(false);
      })
    );
  }
}
