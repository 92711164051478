import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent,
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '@/core/services/authentication.service';
import { TokenService } from '@/core/services/token.service';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { AuthCookie } from '@/core/services/auth-cookies-handler';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  // https://www.intertech.com/Blog/angular-4-tutorial-handling-refresh-token-with-new-httpinterceptor/

  constructor(
    private authService: AuthenticationService,
    private tokenService: TokenService,
    private store: Store<AppState>,
    private authCookie: AuthCookie
  ) {}

  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<
    | HttpSentEvent
    | HttpHeaderResponse
    | HttpProgressEvent
    | HttpResponse<any>
    | HttpUserEvent<any>
    | any
  > {
    return next.handle(request).pipe(
      catchError(err => {
        // if ((err as HttpErrorResponse).status === 401) {
        //     return this.handle401Error(request, next);
        // }
        return throwError(err);
      })
    );
  }

  // private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
  //
  //     if (request.urlWithParams.indexOf('refresh') !== -1) {
  //
  //         this.authService.logout();
  //
  //     } else if (!this.isRefreshingToken) {
  //
  //         this.isRefreshingToken = true;
  //
  //         // Reset here so that the following requests wait until the token
  //         // comes back from the refreshToken call.
  //         this.tokenSubject.next(null);
  //
  //         return this.tokenService.refreshToken().pipe(
  //             switchMap((tokenObtainPairResponse: TokenObtainPairResponse) => {
  //
  //                 if (tokenObtainPairResponse) {
  //                     this.tokenSubject.next(tokenObtainPairResponse.access);
  //                     this.authCookie.setAccess(JSON.stringify(tokenObtainPairResponse.access));
  //                     return next.handle(this.tokenService.decorateRequest(request, null, tokenObtainPairResponse.access));
  //                 }
  //
  //                 return this.authService.logout();
  //             }),
  //             catchError((error) => {
  //                 return this.authService.logout();
  //             }),
  //             finalize(() => {
  //                 this.isRefreshingToken = false;
  //             })
  //         );
  //
  //     } else {
  //
  //         return this.tokenSubject.pipe(
  //             filter(token => token != null),
  //             take(1),
  //             switchMap(token => next.handle(this.tokenService.decorateRequest(request, null, token)))
  //         );
  //     }
  // }
}
