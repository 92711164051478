import { WaterHeaterBackendDict } from '@/data/simulation/models/mechanicals/WaterHeater';
import { createAction, props } from '@ngrx/store';
import { StateErrors } from '../shared/base.state';

export const loadWaterHeaters = createAction(
  '[WaterHeater] Load WaterHeaters',
  props<{ waterHeaters: WaterHeaterBackendDict[] }>()
);

export const loadWaterHeatersSuccess = createAction(
  '[WaterHeater] Load WaterHeaters Success',
  props<{
    waterHeaters: WaterHeaterBackendDict[];
    errors: StateErrors<WaterHeaterBackendDict>;
  }>()
);

export const loadWaterHeatersFailure = createAction(
  '[WaterHeater] Load WaterHeaters Failure',
  props<{ error: any }>()
);

export const updateWaterHeater = createAction(
  '[WaterHeater] Update WaterHeater',
  props<{ waterHeaterChanges: Partial<WaterHeaterBackendDict> }>()
);

export const updateWaterHeaterFailure = createAction(
  '[WaterHeater API] Update WaterHeater Failure',
  props<{ id: number }>()
);
