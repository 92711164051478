<h2 mat-dialog-title>
  <ng-container *ngIf="!isEditForm">
    Add New <b>Product Group</b>
  </ng-container>
  <ng-container *ngIf="isEditForm">
    Edit <b>Product Group</b>
  </ng-container>
</h2>
<mat-dialog-content *ngIf="initialized">
  <form [formGroup]="form" class="mb-2">
    <ng-container>
      <div class="row">
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>Product Group</mat-label>
            <app-generic-model-autocomplete modelClassName="product" formControlName="product_info"></app-generic-model-autocomplete>
          </mat-form-field>
        </div>
      </div>
    </ng-container>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button color="accent" mat-raised-button (click)="save($event)">Save</button>
  <button mat-button [mat-dialog-close]="null" color="primary" mat-raised-button>Cancel</button>
</mat-dialog-actions>
