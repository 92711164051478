import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServerResponse } from '@/core/schemes/server-response';
import { environment } from '../../../../environments/environment';
import { ListRequestParams } from '@/core/schemes/request-params';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import {
  Community,
  CommunityFlatList,
} from '@/data/community/models/community';
import { Relationship } from '@/data/relationship/models/relationship';
import { Subdivision } from '@/data/subdivision/models/subdivision';
import { tap } from 'rxjs/operators';
import { loadCommunity } from '@/state/actions/community/community.actions';

export class CommunitySubdivisionParams extends ListRequestParams {
  is_active?: boolean;
}

export class CommunityHistoryParams extends ListRequestParams {
  is_active?: boolean;
}

export class CommunityRequestParams extends ListRequestParams {
  is_attached?: string;
  city_metro?: string | string[];
  cross_roads?: string | string[];
  city__name?: string | string[];
  city__county__state?: string | string[];
  city__country?: string | string[];
}

@Injectable({
  providedIn: 'root',
})
export class CommunityService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/communities/`;

  list(params?: CommunityRequestParams) {
    return this.http.get<ServerResponse<Community>>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }

  flat_list(params?: CommunityRequestParams) {
    return this.http.get<ServerResponse<CommunityFlatList>>(
      `${this.baseUrl}flat_list/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  all_list(params?: CommunityRequestParams) {
    return this.http.get<ServerResponse<CommunityFlatList>>(
      `${this.baseUrl}all_list/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  relationships_list(communityId: number) {
    return this.http.get<ServerResponse<Relationship>>(
      `${this.baseUrl}${communityId}/relationships/`
    );
  }

  relationships_create(communityId: number, relationship: Relationship) {
    return this.http.post(
      `${this.baseUrl}${communityId}/relationships/`,
      relationship
    );
  }

  relationships_remove(communityId: number) {
    return this.http.delete(
      `${this.baseUrl}${communityId}/relationships/remove/`
    );
  }

  create(community: Community) {
    return this.http.post<Community>(`${this.baseUrl}`, community);
  }

  retrieve(
    communityId: number,
    options?: IDataServiceMethodOptions
  ): Observable<Community> {
    const ignoreStore = options?.ignoreStore;
    let chain = this.http.get<Community>(`${this.baseUrl}${communityId}`, {});
    if (!ignoreStore) {
      chain = chain.pipe(
        tap(newCommunity =>
          this.store.dispatch(loadCommunity({ payload: newCommunity }))
        )
      );
    }
    return chain;
  }

  subdivisions_list(communityId: number, params?: CommunitySubdivisionParams) {
    return this.http.get<ServerResponse<Subdivision>>(
      `${this.baseUrl}${communityId}/subdivisions/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  update(
    communityId: number,
    community: Community | FormData
  ): Observable<Community> {
    return this.http
      .patch<Community>(`${this.baseUrl}${communityId}/`, community)
      .pipe(
        tap(newCommunity =>
          this.store.dispatch(loadCommunity({ payload: newCommunity }))
        )
      );
  }

  delete(communityId: number) {
    return this.http.delete(`${this.baseUrl}${communityId}/`);
  }
}
