import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { User } from '@/data/core/models/user';
import { Observable, Subject } from 'rxjs';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '@/data/core/services/user.service';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import {
  getUser,
  getUserTasks,
  getUserTasksCount,
} from '@/state/selectors/user/user.selector';
import { filter, first, takeUntil } from 'rxjs/operators';
import { ServerResponse } from '@/core/schemes/server-response';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { toggleLoading } from '@/state/actions/app.actions';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import {
  TaskRequestParams,
  TaskService,
} from '@/data/scheduling/services/task.service';
import { TaskPermissionService } from '@/modules/scheduling/services/task-permission.service';
import {
  Task,
  TaskApprovalStateLabelMapping,
  TaskStatusLabelMapping,
} from '@/data/scheduling/models';
import {
  ChangeTaskDialogComponent,
  TaskChangeDialogData,
} from '@/modules/scheduling/components/change-task-dialog/change-task-dialog.component';

@Component({
  selector: 'app-user-scheduling-tab',
  templateUrl: './user-scheduling-tab.component.html',
  styleUrls: ['./user-scheduling-tab.component.scss'],
})
export class UserSchedulingTabComponent implements OnInit, OnDestroy {
  protected readonly TaskApprovalStateLabelMapping =
    TaskApprovalStateLabelMapping;
  protected readonly TaskStatusLabelMapping = TaskStatusLabelMapping;

  public displayedColumns = [
    'id',
    'task_type',
    'approval_state',
    'status',
    'assignees',
    'datetime',
    'note',
    'actions',
  ];
  public filterFormGroup: FormGroup;
  public isLoading = false;
  public rows: Task[] = [];
  public rowsCount: number;

  public user: User;
  public currentUser: User;

  public defaultParams: TaskRequestParams = new TaskRequestParams(
    1,
    '',
    '-created_date',
    25
  );
  public storedParams: TaskRequestParams = new TaskRequestParams(
    1,
    '',
    '-created_date',
    25
  );

  public overwritePropertyValues = {};
  public overwritePropertyLabels = {};
  public excludedProperties: string[];

  public createPermissionResponse: Observable<ObjectPermissionResponse>;
  public permissions: {
    [key: string]: {
      canEdit: Observable<ObjectPermissionResponse>;
      canDelete: Observable<ObjectPermissionResponse>;
    };
  } = {};

  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private dialog: MatDialog,
    private userService: UserService,
    private taskService: TaskService,
    private taskPermissionService: TaskPermissionService,
    private fb: FormBuilder,
    private uiHelperService: UIHelperService
  ) {}

  ngOnInit(): void {
    this.store
      .select(getUser)
      .pipe(
        filter(val => val !== null),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((entity: User) => {
        this.user = entity;
        this.createPermissionResponse = this.taskPermissionService.canCreate(
          this.user
        );
        this.list();
      });

    this.store
      .select(getUserTasks)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((tasks: Task[]) => {
        this.rows = tasks;
      });

    this.store
      .select(getUserTasksCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(count => {
        this.rowsCount = count;
      });

    this.setupFilterForm();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  setupFilterForm() {
    this.filterFormGroup = this.fb.group({
      search: [null],
    });
    this.filterFormGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        const params = Object.assign(this.defaultParams, values);

        this.storedParams.assignFilterFormValues({
          formValues: params,
          excludedProperties: ['ordering'],
        });
        this.list();
      });
  }

  list() {
    if (!this.user) {
      return;
    }

    this.storedParams.assignees = [this.user.id];

    this.isLoading = true;
    this.userService
      .tasks(this.user.id, this.storedParams)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data: ServerResponse<Task>) => {
        this.rows = data.results;
        this.rowsCount = data.count;
        this.rows.forEach(row => {
          this.permissions[row.id] = {
            canEdit: this.taskPermissionService.canEdit(row),
            canDelete: this.taskPermissionService.canDelete(row),
          };
        });
        this.isLoading = false;
      });
  }

  onSortChange($event: Sort) {
    this.storedParams.ordering = `${$event.direction === 'desc' ? '-' : ''}${
      $event.active
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  onPaginateChange($event: PageEvent) {
    this.uiHelperService.getUISettings().rowsPerPage = $event.pageSize;
    this.storedParams.page_size = $event.pageSize;
    this.storedParams.page = $event.pageIndex + 1;
    this.list();
  }

  create($event: MouseEvent) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(ChangeTaskDialogComponent, {
      width: '55%',
      disableClose: true,
      autoFocus: false,
      data: {
        entity: {
          assignees: [this.user.id],
        },
      } as TaskChangeDialogData,
    });
    dialogRef.afterClosed().subscribe((result?: Task) => {
      if (!result) return;
      this.list();
      this.uiHelperService.openSnackBar(`Scheduling Task successfully created`);
    });
  }

  edit($event: MouseEvent, task: Task) {
    $event.preventDefault();
    const dialogRef: MatDialogRef<TaskChangeDialogData> = this.dialog.open(
      ChangeTaskDialogComponent,
      {
        width: '55%',
        disableClose: true,
        autoFocus: false,
        data: {
          entity: task,
        } as TaskChangeDialogData,
      }
    );
    dialogRef.afterClosed().subscribe((result?: Task) => {
      if (!result) return;
      this.list();
      this.uiHelperService.openSnackBar(`Scheduling Task successfully updated`);
    });
  }

  delete($event: MouseEvent, task: Task) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '45%',
      data: {
        title: 'Delete Accreditation?',
        content: `Do you want to delete Scheduling Task ?`,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) return;
      this.store.dispatch(toggleLoading({ payload: true }));
      this.taskService
        .delete(task.id)
        .pipe(first())
        .subscribe({
          next: () => {
            this.list();
            this.store.dispatch(toggleLoading({ payload: false }));
            this.uiHelperService.openSnackBar(
              `Scheduling Task successfully deleted`
            );
          },
          error: (error: HttpErrorResponse) =>
            this.uiHelperService.handleUserRequestError(error),
        });
    });
  }
}
