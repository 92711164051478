import { CompanyInfo } from '@/data/company/models/company';
import { PaymentRequestInfo } from '@/data/customer-hirl/models';
import { SubdivisionInfo } from '@/data/subdivision/models';

export enum InvoiceType {
  default = 'default',
  hirlProject = 'hirl_project',
  hirlGCP = 'hirl_gcp',
  hirlGCPTopShelf = 'hirl_gcp_top_shelf',
  hirlGCPInitial = 'hirl_gcp_initial',
}

export const InvoiceTypeLabelMapping: Record<InvoiceType, any> = {
  [InvoiceType.default]: 'Default',
  [InvoiceType.hirlProject]: 'NGBS Project',
  [InvoiceType.hirlGCP]: 'NGBS Green Certified Product',
  [InvoiceType.hirlGCPTopShelf]: 'NGBS Green Certified Product',
  [InvoiceType.hirlGCPInitial]: 'NGBS Green Certified Product Initial',
};

export enum InvoiceState {
  new = 'new',
  paid = 'paid',
  cancelled = 'cancelled',
}

export const InvoiceStateLabelMapping: Record<InvoiceState, any> = {
  [InvoiceState.new]: {
    label: 'New',
    badgeClass: 'badge-secondary',
  },
  [InvoiceState.paid]: {
    label: 'Paid',
    badgeClass: 'badge-success',
  },
  [InvoiceState.cancelled]: {
    label: 'Cancelled',
    badgeClass: 'badge-danger',
  },
};

export class Invoice {
  id?: string;

  prefixed_id?: string;

  invoice_type: InvoiceType;
  state: InvoiceState;
  total?: number;
  total_paid?: number;
  current_balance?: number;

  issuer: number;
  issuer_info: CompanyInfo;
  customer: number;
  customer_info: CompanyInfo;

  emails?: string;
  invoice_copy_sent_at?: string;
  gcp_special_instructions?: string;

  due_date?: string;
  updated_at: string;
  created_at: string;

  subdivisions_info?: SubdivisionInfo[];

  payment_request_info?: PaymentRequestInfo;

  client_ngbs_id?: number;

  client_before_company_consolidation?: number;
  client_before_company_consolidation_info?: CompanyInfo;
  client_ngbs_id_before_company_consolidation?: number;

  erfp_before_company_consolidation_info?: CompanyInfo;
  erfp_before_company_consolidation?: number;
  erfp_ngbs_id_before_company_consolidation?: number;
}

export class InvoiceInfo {
  id?: string;
  prefixed_id?: string;

  state: InvoiceState;
  total?: number;
  total_paid?: number;
  current_balance?: number;

  issuer: number;
  issuer_info: CompanyInfo;
  customer: number;
  customer_info: CompanyInfo;

  emails?: string;
  invoice_copy_sent_at?: string;
}

export class InvoiceAggregateByState {
  new: number;
  paid: number;
  cancelled: number;
}

export class CustomerHIRLListInvoice {
  id: string;
  prefixed_id?: string;
  customer_id: string;
  customer_type: string;
  customer_name: string;
  erfp_before_company_consolidation_id: string;
  erfp_before_company_consolidation_type: string;
  erfp_before_company_consolidation_name: string;
  total: number;
  total_paid: number;
  h_numbers: string[];
  current_balance: number;
  created_at: string;
  subdivision_name?: string;
  subdivision_url?: string;
}

export class InvoiceItem {
  id?: string;
  name?: string;
  group: number;
}

export class InvoiceItemInfo {
  id?: string;
  name?: string;
  group: number;
}

export class MultipleInvoiceImport {
  invoices?: string[];
  select_all?: boolean;
}
