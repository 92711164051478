import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin, of, Subject, Subscription } from 'rxjs';
import { User } from '@/data/core/models/user';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, take, takeUntil } from 'rxjs/operators';
import { toggleLoading } from '@/state/actions/app.actions';
import { HttpErrorResponse } from '@angular/common/http';
import {
  HIRLProjectAppealsProjectTypeMapping,
  PaymentRequest,
  PaymentRequestCalculatePriceRequest,
  PaymentRequestCalculatePriceResponse,
  PaymentRequestCalculatePriceResponseDetail,
  PaymentRequestCreate,
  PaymentRequestList,
  PaymentRequestProduct,
  PaymentRequestState,
  PaymentRequestStateLabelMapping,
  PaymentRequestType,
} from '@/data/customer-hirl/models';
import {
  PaymentRequestRequestParams,
  PaymentRequestService,
} from '@/data/customer-hirl/services/payment-request.service';
import { conditionalValidator } from '@/core/validators';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';
import {
  CustomerHIRLProductList,
  ProductList,
  ProductStatus,
  ProductStatusLabelMapping,
} from '@/data/product/models';
import { ServerResponse } from '@/core/schemes/server-response';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { UniqueSet } from '@/core/utils';
import {
  CompanyInfo,
  GCPFeeCalculationTypeLabelMapping,
} from '@/data/company/models';
import {
  ProductRequestParams,
  ProductService,
} from '@/data/product/services/product.service';

export interface PaymentRequestChangeDialogData {
  paymentRequest: PaymentRequest;
  commit?: boolean;
}

@Component({
  selector: 'app-payment-request-change-dialog',
  templateUrl: './payment-request-change-dialog.component.html',
  styleUrls: ['./payment-request-change-dialog.component.scss'],
})
export class PaymentRequestChangeDialogComponent implements OnInit, OnDestroy {
  protected readonly CustomerHIRLSettings = CustomerHIRLSettings;
  protected readonly PaymentRequestType = PaymentRequestType;
  protected readonly HIRLProjectAppealsProjectTypeMapping =
    HIRLProjectAppealsProjectTypeMapping;
  protected readonly PaymentRequestStateLabelMapping =
    PaymentRequestStateLabelMapping;
  protected readonly ProductStatusLabelMapping = ProductStatusLabelMapping;
  protected readonly GCPFeeCalculationTypeLabelMapping =
    GCPFeeCalculationTypeLabelMapping;

  public initialized: boolean;
  public isEditForm = false;
  public entity?: PaymentRequest;
  public commit = true;
  public retrieve = true;
  public form: FormGroup;
  public currentUser: User;

  public paymentRequestTypes = {};
  public initialCertificationFee = false;
  private paymentRequestTypesSubscription$: Subscription;

  public totalPrice = 0;
  public priceErrors: { [p: string]: string }[] = [];
  public priceDetails: PaymentRequestCalculatePriceResponseDetail[] = [];
  public priceIsLoading = false;
  private calculatePriceSubscription$: Subscription;

  public productsIsLoading = true;
  public products: CustomerHIRLProductList[] = [];
  public productsCount = 0;
  public selectedProducts: UniqueSet<CustomerHIRLProductList> =
    new UniqueSet<CustomerHIRLProductList>(
      (item: CustomerHIRLProductList) => item.id
    );
  public productsStoredParams: ProductRequestParams = new ProductRequestParams(
    1,
    '',
    '-id'
  );
  public productsDisplayedColumns = [
    'select',
    'title',
    'status',
    'hirl_certificate_approval_date',
    'practices_number',
  ];

  private productsListSubscription$: Subscription;
  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<PaymentRequestChangeDialogComponent>,
    private fb: FormBuilder,
    private paymentRequestService: PaymentRequestService,
    private productService: ProductService,
    private uiHelperService: UIHelperService,
    @Inject(MAT_DIALOG_DATA) public data: PaymentRequestChangeDialogData
  ) {
    if (data?.paymentRequest) {
      this.entity = Object.assign({}, data?.paymentRequest);
    }
    this.commit = data?.commit || true;
    if (this.entity?.id) {
      this.isEditForm = true;
    }
  }
  ngOnInit() {
    const sources = {
      currentUser: this.store.select(getInfoUser).pipe(first()),
      paymentRequest: of(this.entity),
    };

    this.store.dispatch(toggleLoading({ payload: true }));

    if (this.isEditForm && this.retrieve) {
      sources.paymentRequest = this.paymentRequestService
        .retrieve(this.entity.id, { ignoreStore: true })
        .pipe(takeUntil(this.componentDestroyed$), first());
    }

    forkJoin(sources)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: ({ currentUser, paymentRequest }) => {
          this.store.dispatch(toggleLoading({ payload: false }));

          this.currentUser = currentUser;
          this.entity = paymentRequest;
          this.setupForm();
          this.initialized = true;
          this.hydrateForm();
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();

    if (this.calculatePriceSubscription$) {
      this.calculatePriceSubscription$.unsubscribe();
    }
    if (this.paymentRequestTypesSubscription$) {
      this.paymentRequestTypesSubscription$.unsubscribe();
    }
  }

  private setupForm() {
    this.form = this.fb.group({
      request_type: [null, [Validators.required]],
      products_info_select_all: [false],
      manufacturer_info: [
        null,
        [
          conditionalValidator(
            () =>
              this.currentUser.company_info?.slug ===
                CustomerHIRLSettings.companySlug ||
              this.currentUser.is_superuser,
            Validators.required
          ),
        ],
      ],
    });

    this.form
      .get('manufacturer_info')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe((manufacturer_info: CompanyInfo) => {
        this.form.get('request_type').reset();
        this.form.get('products_info_select_all').reset();
        this.selectedProducts.clear();
        this.buildRequestTypeChoices(manufacturer_info);
      });

    this.form
      .get('products_info_select_all')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe(value => {
        if (value) {
          this.products.forEach((row: CustomerHIRLProductList) => {
            if (row.practice_item_product_count > 0) {
              this.selectedProducts.add(row);
            }
          });
        } else {
          this.selectedProducts.clear();
        }
        this.calculatePrice();
      });

    this.form
      .get('request_type')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe(request_type => {
        this.form.get('products_info_select_all').reset();
        this.selectedProducts.clear();
        this.products = [];
        if (
          request_type === PaymentRequestType.fullYearSubscription ||
          request_type === PaymentRequestType.insertNewProduct
        ) {
          this.hirlProductGroupsList();
        }
        this.calculatePrice();
      });
  }

  hydrateForm() {
    if (this.isEditForm) {
      this.form.patchValue(Object.assign({}, this.entity), {
        emitEvent: false,
        onlySelf: true,
      });
    } else {
      let manufacturer = null;
      if (
        this.currentUser.company_info?.slug !==
          CustomerHIRLSettings.companySlug &&
        !this.currentUser.is_superuser
      ) {
        manufacturer = this.currentUser.company_info;
      }
      this.form.get('manufacturer_info').patchValue(manufacturer);
    }
  }

  save($event: MouseEvent) {
    $event.preventDefault();
    if (this.isEditForm) {
      this.edit();
    } else {
      this.create();
    }
  }

  private create() {
    this.form.markAllAsTouched();

    if (this.form.invalid || this.priceErrors?.length) {
      return;
    }

    if (!this.commit) {
      this.dialogRef.close(this.entity);
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const data = new PaymentRequestCreate();

    data.request_type = this.form.value.request_type;

    let productsSelectAll = this.form.get('products_info_select_all').value;
    if (
      productsSelectAll &&
      this.selectedProducts.toArray().length === this.productsCount
    ) {
      productsSelectAll = false;
    }

    data.products_select_all = productsSelectAll;
    if (!data.products_select_all) {
      data.products = this.selectedProducts
        .toArray()
        .map((productGroup: ProductList) => {
          const paymentRequestProduct = new PaymentRequestProduct();
          paymentRequestProduct.product = productGroup.id;
          return paymentRequestProduct;
        });
    }
    data.manufacturer = this.form.value.manufacturer_info?.id;

    this.paymentRequestService
      .create(data)
      .pipe(first())
      .subscribe({
        next: (entity: PaymentRequest) => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close(entity);
        },
        error: (error: HttpErrorResponse) =>
          this.uiHelperService.handleUserRequestError(error),
      });
  }

  private edit() {
    this.form.markAllAsTouched();

    if (this.form.invalid || this.priceErrors?.length) {
      return;
    }

    if (!this.commit) {
      this.dialogRef.close(this.entity);
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const data = new PaymentRequest();

    data.request_type = this.form.value.title;
    data.manufacturer = this.form.value.manufacturer_info?.id;

    this.paymentRequestService
      .update(data, this.entity.id)
      .pipe(first())
      .subscribe({
        next: (entity: PaymentRequest) => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close(entity);
        },
        error: (error: HttpErrorResponse) =>
          this.uiHelperService.handleUserRequestError(error),
      });
  }

  calculatePrice() {
    const data = new PaymentRequestCalculatePriceRequest();

    let productsSelectAll = this.form.get('products_info_select_all').value;
    if (
      productsSelectAll &&
      this.selectedProducts.toArray().length === this.productsCount
    ) {
      productsSelectAll = false;
    }

    data.products_select_all = productsSelectAll;
    data.products = this.selectedProducts?.toArray().map(item => item.id);
    data.request_type = this.form.get('request_type').value;

    if (
      this.currentUser.company_info?.slug ===
        CustomerHIRLSettings.companySlug ||
      this.currentUser.is_superuser
    ) {
      data.manufacturer = this.form.get('manufacturer_info').value?.id;
    } else {
      data.manufacturer = this.currentUser.company;
    }

    this.priceIsLoading = true;
    if (this.calculatePriceSubscription$) {
      this.calculatePriceSubscription$.unsubscribe();
    }
    this.calculatePriceSubscription$ = this.paymentRequestService
      .calculate_price(data)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: (response: PaymentRequestCalculatePriceResponse) => {
          this.priceIsLoading = false;
          this.totalPrice = response.total_price;
          this.priceErrors = response.errors;
          this.priceDetails = response.details;
        },
        error: (error: HttpErrorResponse) =>
          this.uiHelperService.handleUserRequestError(error),
      });
  }

  hirlProductGroupsList() {
    this.productsIsLoading = true;

    this.productsStoredParams = new ProductRequestParams(1, '', '-id');

    if (
      this.currentUser.company_info?.slug ===
        CustomerHIRLSettings.companySlug ||
      this.currentUser.is_superuser
    ) {
      this.productsStoredParams.manufacturer =
        this.form.get('manufacturer_info').value?.id;
    } else {
      this.productsStoredParams.manufacturer = this.currentUser.company;
    }

    if (
      this.form.get('request_type').value ===
      PaymentRequestType.insertNewProduct
    ) {
      this.productsStoredParams.status = [ProductStatus.draft];
    }

    if (this.productsListSubscription$) {
      this.productsListSubscription$.unsubscribe();
    }

    this.productsListSubscription$ = this.productService
      .customer_hirl_list(this.productsStoredParams)
      .pipe(takeUntil(this.componentDestroyed$), take(1))
      .subscribe({
        next: (data: ServerResponse<CustomerHIRLProductList>) => {
          this.products = data.results;
          this.productsCount = data.count;
          this.productsIsLoading = false;
          if (this.form.get('products_info_select_all').value) {
            this.products.forEach((row: ProductList) => {
              this.selectedProducts.add(row);
            });
          }
        },
        error: (error: HttpErrorResponse) =>
          this.uiHelperService.handleUserRequestError(error),
      });
  }

  onHIRLProductGroupsPaginateChange($event: PageEvent) {
    this.uiHelperService.getUISettings().rowsPerPage = $event.pageSize;
    this.productsStoredParams.page_size = $event.pageSize;
    this.productsStoredParams.page = $event.pageIndex + 1;
    this.hirlProductGroupsList();
  }

  onHIRLProductGroupsSortChange($event: Sort) {
    this.productsStoredParams.ordering = `${
      $event.direction === 'desc' ? '-' : ''
    }${$event.active}`;
    this.productsStoredParams.page = 1;
    this.hirlProductGroupsList();
  }

  onHIRLProductGroupsSelect(event: MatCheckboxChange, row: ProductList): void {
    if (event.checked) {
      this.selectedProducts.add(row);
    } else {
      this.selectedProducts.delete(row);
    }

    if (
      this.selectedProducts.length &&
      this.selectedProducts.length === this.productsCount
    ) {
      this.form
        .get('products_info_select_all')
        .patchValue(true, { emitEvent: false, onlySelf: true });
    } else {
      this.form
        .get('products_info_select_all')
        .patchValue(false, { emitEvent: false, onlySelf: true });
    }

    this.calculatePrice();
  }

  isHIRLProductGroupChecked(row: ProductList): boolean {
    return this.selectedProducts.has(row);
  }

  buildRequestTypeChoices(manufacturer_info: CompanyInfo) {
    const initialFeeParams = new PaymentRequestRequestParams();
    initialFeeParams.manufacturer = manufacturer_info?.id;
    initialFeeParams.request_type = PaymentRequestType.initialFee;

    const fullYearFeeParams = new PaymentRequestRequestParams();
    fullYearFeeParams.manufacturer = manufacturer_info?.id;
    fullYearFeeParams.request_type = PaymentRequestType.fullYearSubscription;

    const sources = {
      initialFeePaymentRequests:
        this.paymentRequestService.list(initialFeeParams),
      fullYearPaymentRequests:
        this.paymentRequestService.list(fullYearFeeParams),
    };

    if (this.paymentRequestTypesSubscription$) {
      this.paymentRequestTypesSubscription$.unsubscribe();
    }
    this.store.dispatch(toggleLoading({ payload: true }));

    this.initialCertificationFee = false;

    this.paymentRequestTypesSubscription$ = forkJoin(sources)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: ({
          initialFeePaymentRequests,
          fullYearPaymentRequests,
        }: {
          initialFeePaymentRequests: ServerResponse<PaymentRequestList>;
          fullYearPaymentRequests: ServerResponse<PaymentRequestList>;
        }) => {
          this.store.dispatch(toggleLoading({ payload: false }));

          let initialFeePaymentRequest: PaymentRequest = null;
          if (initialFeePaymentRequests.results.length) {
            initialFeePaymentRequest = initialFeePaymentRequests.results[0];
          }

          this.paymentRequestTypes = {};

          if (!initialFeePaymentRequest) {
            this.paymentRequestTypes[PaymentRequestType.initialFee] = {
              name: 'Application Fee',
              disabled: false,
            };
            this.paymentRequestTypes[PaymentRequestType.fullYearSubscription] =
              {
                name: 'Initial Certification Fee',
                disabled: true,
              };
            this.paymentRequestTypes[PaymentRequestType.insertNewProduct] = {
              name: 'Mid-Cycle Certification Fee',
              disabled: true,
            };
          } else {
            if (initialFeePaymentRequest.state !== PaymentRequestState.paid) {
              this.paymentRequestTypes[PaymentRequestType.initialFee] = {
                name: 'Application Fee (Already exists and awaiting for Application Fee payment)',
                disabled: true,
              };
              this.paymentRequestTypes[
                PaymentRequestType.fullYearSubscription
              ] = {
                name: 'Initial Certification Fee (Awaiting for Application Fee payment)',
                disabled: true,
              };
              this.paymentRequestTypes[PaymentRequestType.insertNewProduct] = {
                name: 'Mid-Cycle Certification Fee (Awaiting for Application Fee payment)',
                disabled: true,
              };
            } else {
              this.paymentRequestTypes[PaymentRequestType.initialFee] = {
                name: 'Application Fee (Already Exists)',
                disabled: true,
              };
              if (!fullYearPaymentRequests.results.length) {
                this.initialCertificationFee = true;
                this.paymentRequestTypes[
                  PaymentRequestType.fullYearSubscription
                ] = {
                  name: 'Initial Certification Fee',
                  disabled: false,
                };
                this.paymentRequestTypes[PaymentRequestType.insertNewProduct] =
                  {
                    name: 'Mid-Cycle Certification Fee (Certification Fee not paid yet)',
                    disabled: true,
                  };
              } else {
                const renewalDateIsValid = this.isDateInNextNDays(
                  manufacturer_info?.gcp_certificate_expiration_date,
                  35
                );

                if (!renewalDateIsValid) {
                  this.paymentRequestTypes[
                    PaymentRequestType.fullYearSubscription
                  ] = {
                    name: `Renewal Certification Fee (Will be available in ${CustomerHIRLSettings.paymentRequestExpirationWarningDays} days prior to expiration)`,
                    disabled: true,
                  };

                  this.paymentRequestTypes[
                    PaymentRequestType.insertNewProduct
                  ] = {
                    name: 'Mid-Cycle Certification Fee',
                    disabled: false,
                  };
                } else {
                  this.paymentRequestTypes[
                    PaymentRequestType.fullYearSubscription
                  ] = {
                    name: 'Renewal Certification Fee',
                    disabled: false,
                  };
                }
              }
            }
          }
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  isDateInNextNDays(dateStr: string, numberOfDays: number): boolean {
    const today = new Date();
    const date = new Date(dateStr);

    const sixtyDaysFromToday = new Date();
    sixtyDaysFromToday.setDate(today.getDate() + numberOfDays);
    return date >= today && date <= sixtyDaysFromToday;
  }
}
