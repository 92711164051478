import { FuelTypeLabels } from '@/data/simulation/enumerations';
import { selectUtilityRates } from '@/modules/simulation/state/utility-rate/selectors';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import {
  UtilityRateChangeDialogData,
  UtilityRateDialogComponent,
} from '../utility-rate-dialog/utility-rate-dialog.component';
import { DIALOG_WIDTH } from '@/modules/simulation/constants';
import { CREATE_MODE, EDIT_MODE } from '@/core/constants';
import { removeUtilityRate } from '@/modules/simulation/state/utility-rate/actions';

@Component({
  selector: 'app-utilities',
  templateUrl: './utilities.component.html',
  styleUrls: ['./utilities.component.scss'],
})
export class UtilitiesComponent implements OnInit {
  componentDestroyed$: Subject<boolean> = new Subject();
  utilityRates = [];

  fuelTypeLabels = FuelTypeLabels;

  constructor(public store: Store, public dialog: MatDialog) {}

  ngOnInit(): void {
    this.store
      .select(selectUtilityRates())
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.utilityRates = Object.values(data);
      });
  }

  openEditUtilityRateChangeDialog(data: UtilityRateChangeDialogData) {
    return this.dialog.open(UtilityRateDialogComponent, {
      width: DIALOG_WIDTH,
      disableClose: true,
      data,
    });
  }

  editUtilityRate(entityId: number) {
    const updatedUtilityRate = this.openEditUtilityRateChangeDialog({
      commit: true,
      mode: EDIT_MODE,
      entityId: entityId,
      excludedFuels: this.utilityRates.map(rate => rate.fuel),
    });

    updatedUtilityRate.afterClosed().subscribe(utilityRate => {
      if (!utilityRate) {
        return;
      }
    });
  }

  addUtilityRate() {
    const newUtilityRate = this.openEditUtilityRateChangeDialog({
      commit: true,
      mode: CREATE_MODE,
    });

    newUtilityRate.afterClosed().subscribe(utilityRate => {
      if (!utilityRate) {
        return;
      }
    });
  }

  removeUtilityRate(index: number) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '50%',
      data: {
        title: `Do you want to delete "
          ${this.fuelTypeLabels[this.utilityRates[index].fuel]}
          " fuel rate info?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (result) {
          this.store.dispatch(
            removeUtilityRate({ utilityRate: this.utilityRates[index] })
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
