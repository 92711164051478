import { createReducer, on } from '@ngrx/store';
import * as DehumidifierActions from './actions';
import { DehumidifierBackendDict } from '@/data/simulation/models/mechanicals/Dehumidifier';
import { BaseState } from '../shared/base.state';
import { addStateErrorCount } from '../helper';

export type DehumidifierState = BaseState<DehumidifierBackendDict>;

export const initialState: DehumidifierState = {
  entities: {},
  loading: {},
  errors: {},
};

export const reducer = createReducer(
  initialState,
  on(
    DehumidifierActions.loadDehumidifiersSuccess,
    (state, { dehumidifiers, errors }) => {
      const entities = dehumidifiers.reduce(
        (acc, dehumidifier) => ({ ...acc, [dehumidifier.id]: dehumidifier }),
        {}
      );
      const loading = dehumidifiers.reduce(
        (acc, dehumidifier) => ({ ...acc, [dehumidifier.id]: false }),
        {}
      );

      return {
        entities: {
          ...state.entities,
          ...entities,
        },
        loading: {
          ...state.loading,
          ...loading,
        },
        errors: {
          ...state.errors,
          ...addStateErrorCount(errors),
        },
      };
    }
  ),

  on(
    DehumidifierActions.updateDehumidifier,
    (state, { dehumidifierChanges }) => ({
      ...state,
      loading: {
        ...state.loading,
        [dehumidifierChanges.id]: true,
      },
    })
  ),

  on(DehumidifierActions.updateDehumidifierFailure, (state, { id }) => ({
    ...state,
    loading: {
      ...state.loading,
      [id]: false,
    },
  }))
);
