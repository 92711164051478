import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { forkJoin, Subject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment/moment';
import { EEPProgramHomeStatus, ProjectCertification } from '@/data/home/models';
import { conditionalValidator } from '@/core/validators';
import { User } from '@/data/core/models/user';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';

export interface CertifyDialogData {
  company?: number;
  certificationDate?: Date;
  statuses?: EEPProgramHomeStatus[];
  selectAll?: boolean;
}

@Component({
  selector: 'app-certify-dialog',
  templateUrl: './certify-dialog.component.html',
  styleUrls: ['./certify-dialog.component.scss'],
})
export class CertifyDialogComponent implements OnInit, OnDestroy {
  public initialized = false;
  public form: FormGroup;
  public currentUser: User;

  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CertifyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CertifyDialogData
  ) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
    }).subscribe(({ currentUser }: { currentUser: User }) => {
      this.currentUser = currentUser;
      this.initialized = true;
      this.setupForm();
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  setupForm() {
    const certificationDate = this.data.certificationDate
      ? moment(this.data.certificationDate, 'YYYY-MM-DD')
      : moment();

    this.form = this.fb.group({
      certification_date: [certificationDate, [Validators.required]],
      company_info: [
        null,
        [
          conditionalValidator(
            () => this.currentUser.is_superuser,
            Validators.required
          ),
        ],
      ],
    });
  }

  save($event: MouseEvent) {
    $event.preventDefault();

    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    const projectCertification = new ProjectCertification();
    projectCertification.certification_datetime =
      this.form.value.certification_date.format('YYYY-MM-DD');
    projectCertification.statuses = this.data.statuses?.map(
      (homeStatus: EEPProgramHomeStatus) => homeStatus.id
    );
    projectCertification.select_all = this.data.selectAll;
    projectCertification.company = this.form.value.company_info?.id;

    this.dialogRef.close(projectCertification);
  }
}
