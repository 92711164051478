<div class="container" *ngIf="initialized">
    <div class="row mt-3">
        <div class="col-12">
            <h5>
                <button mat-raised-button appBackButton color="accent">
                    <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
                </button>
                Create Affiliation for Company
            </h5>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <mat-divider></mat-divider>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <mat-stepper orientation="vertical" [linear]="true" #stepper>
                <mat-step [stepControl]="companyToFormGroup">
                    <form [formGroup]="companyToFormGroup">
                        <ng-template matStepLabel>Select Sponsored Company</ng-template>
                        <div class="row">
                            <div class="col-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Sponsored Company</mat-label>
                                    <app-generic-model-autocomplete
                                            formControlName="company_to_info"
                                            modelClassName="company"
                                            [initialValueId]="storedParams.company_to"
                                            [panelWidth]="480"
                                    ></app-generic-model-autocomplete>
                                    <mat-error
                                            *ngIf="companyToFormGroup.controls['company_to_info'].hasError('required')">
                                        This field is required
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div>
                            <button mat-raised-button matStepperPrevious class="mr-2">Back</button>
                            <button mat-raised-button color="accent" matStepperNext>Next</button>
                        </div>
                    </form>
                </mat-step>
                <mat-step [stepControl]="settingsFormGroup">
                    <form [formGroup]="settingsFormGroup">
                        <ng-template matStepLabel>Configure Settings</ng-template>
                        <div class="row">
                            <div class="col-12">
                                <mat-checkbox formControlName="can_edit_profile">
                                    Can Edit Profile
                                </mat-checkbox>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <mat-checkbox formControlName="can_edit_identity_fields">
                                    Can Edit Identity Fields
                                </mat-checkbox>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <mat-checkbox formControlName="notify_sponsor_on_update">
                                    Notify Sponsor On Update
                                </mat-checkbox>
                            </div>
                        </div>
                        <div>
                            <button mat-raised-button matStepperPrevious class="mr-2">Back</button>
                            <button mat-raised-button color="accent" (click)="onSave($event)">Save</button>
                        </div>
                    </form>
                </mat-step>
                <mat-step>
                    <ng-template matStepLabel>Done</ng-template>
                    <p class="lead">
                        Sponsor Preferences successfully created
                    </p>
                </mat-step>
            </mat-stepper>
        </div>
    </div>
</div>
