import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { getCompany } from '@/state/selectors/company/company.selector';
import { filter, takeUntil } from 'rxjs/operators';
import {
  Company,
  CompanyType,
  GCPFeeCalculationTypeLabelMapping,
  InspectionGradeTypeLabelMapping,
} from '@/data/company/models';
import {
  EtoAccountChangeDialogComponent,
  EtoAccountChangeDialogData,
} from '@/modules/customer-eto/components/eto-account-change-dialog/eto-account-change-dialog.component';
import { ETOAccount } from '@/data/customer-eto/models';
import { MatDialog } from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import {
  CompanyEditMiscDialogComponent,
  CompanyEditMiscDialogData,
} from '@/modules/company/components/company-edit-misc-dialog/company-edit-misc-dialog.component';
import { CompanyPermissionService } from '@/modules/company/services/company-permission-service';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { CustomerETOSettings } from '@/modules/customer-eto/constants';
import { getInfoUser } from '@/state/selectors/info.selector';
import { User } from '@/data/core/models/user';
import { HIRLCompanyClient } from '@/data/customer-hirl/models';
import { CustomerHIRLSettings } from '../../../customer-hirl/constants';

@Component({
  selector: 'app-company-misc-tab',
  templateUrl: './company-misc-tab.component.html',
  styleUrls: ['./company-misc-tab.component.scss'],
})
export class CompanyMiscTabComponent implements OnInit, OnDestroy {
  protected readonly CustomerHIRLSettings = CustomerHIRLSettings;
  public company: Company;
  public ngbsClientId: number;
  public associatedClientIds: number[] = [];

  public inspectionGradeTypeLabelMapping = InspectionGradeTypeLabelMapping;

  public currentUser: User;
  public editPermissionResponse: Observable<ObjectPermissionResponse>;
  private componentDestroyed$ = new Subject();

  constructor(
    public store: Store<AppState>,
    public injector: Injector,
    private dialog: MatDialog,
    private companyPermissionService: CompanyPermissionService,
    private uiHelperService: UIHelperService
  ) {}

  ngOnInit(): void {
    this.store
      .select(getInfoUser)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(currentUser => {
        this.currentUser = currentUser;
      });

    this.store
      .select(getCompany)
      .pipe(
        filter(val => val !== null),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(company => {
        this.company = company;
        this.ngbsClientId = company.hirlcompanyclient_set[0]?.id;
        const associatedClients: HIRLCompanyClient[] = [
          ...company.hirlcompanyclient_set,
        ];
        associatedClients.shift();
        this.associatedClientIds = associatedClients.map(
          hirlCompanyClient => hirlCompanyClient.id
        );
        this.editPermissionResponse =
          this.companyPermissionService.canEdit(company);
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  editETOAccount($event: MouseEvent) {
    $event.preventDefault();

    const dialogRef = this.dialog.open(EtoAccountChangeDialogComponent, {
      width: '55%',
      disableClose: true,
      autoFocus: false,
      data: {
        etoAccount: { id: this.company.eto_account, company: this.company.id },
      } as EtoAccountChangeDialogData,
    });

    dialogRef.afterClosed().subscribe((result?: ETOAccount) => {
      if (!result) {
        return;
      }
      this.uiHelperService.openSnackBar('Record Successfully Updated');
    });
  }

  editMiscCompanyData($event: MouseEvent) {
    $event.preventDefault();

    const dialogRef = this.dialog.open(CompanyEditMiscDialogComponent, {
      width: '55%',
      disableClose: true,
      autoFocus: false,
      data: {
        company: this.company,
      } as CompanyEditMiscDialogData,
    });

    dialogRef.afterClosed().subscribe((result?: Company) => {
      if (!result) {
        return;
      }
      this.uiHelperService.openSnackBar('Record Successfully Updated');
    });
  }

  get showETO() {
    const companySlugs = [CustomerETOSettings.companySlug, 'peci'];
    const companyTypes = [CompanyType.builder, CompanyType.rater];
    return (
      companyTypes.includes(this.company.company_type) &&
      (companySlugs.includes(this.currentUser?.company_info?.slug) ||
        this.currentUser?.is_superuser)
    );
  }

  protected readonly GCPFeeCalculationTypeLabelMapping =
    GCPFeeCalculationTypeLabelMapping;
}
