import { Component, Input, OnInit } from '@angular/core';
import { FloorPlan } from '@/data/floorplan/models/floorplan';
import { SimulationSourceTypeLabelMapping } from '@/data/simulation/models/simulation';

export enum FloorplanDisplayComponentType {
  onlyName = 'onlyName',
  onlyNameAsLink = 'onlyNameAsLink',
  onlyNameAsLinkNewWindow = 'onlyNameAsLinkNewWindow',
  nameAndType = 'nameAndType',
  nameAndTypeAsLink = 'nameAndTypeAsLink',
}

@Component({
  selector: 'app-floorplan-display',
  templateUrl: './floorplan-display.component.html',
  styleUrls: ['./floorplan-display.component.scss'],
})
export class FloorplanDisplayComponent implements OnInit {
  @Input() displayType:
    | FloorplanDisplayComponentType
    | keyof typeof FloorplanDisplayComponentType =
    FloorplanDisplayComponentType.onlyName;
  @Input() floorplan?: FloorPlan;

  public SimulationSourceTypeLabelMapping = SimulationSourceTypeLabelMapping;
  public floorplanDisplayComponentType = FloorplanDisplayComponentType;

  ngOnInit(): void {}
}
