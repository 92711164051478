import { Component, OnDestroy, OnInit } from '@angular/core';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { MatDialog } from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import * as moment from 'moment';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import {
  HIRLProjectRegistrationActivityMetricsByMonthRequestParams,
  HIRLProjectRegistrationService,
} from '@/data/customer-hirl/services/hirl-project-registration.service';

import {
  HIRLProjectRegistrationCategories,
  HIRLProjectTypeLabelMapping,
  HIRLProjectType,
} from '@/modules/misc/constants';
import { HIRLProjectRegistrationActivityMetricsByMonth } from '@/data/customer-hirl/models/hirl-project-registration';
interface IChartResultSeriesData {
  name: string;
  value: number;
}

interface IChartResult {
  name: string;
  series: IChartResultSeriesData[];
}

@Component({
  selector:
    'app-customer-hirl-registrations-per-month-metrics-chart-by-projects',
  templateUrl:
    './customer-hirl-registrations-per-month-metrics-chart-by-projects.component.html',
  styleUrls: [
    './customer-hirl-registrations-per-month-metrics-chart-by-projects.component.scss',
  ],
})
export class CustomerHirlRegistrationsPerMonthMetricsChartByProjectsComponent
  implements OnInit, OnDestroy
{
  public isLoading = true;
  public metricsSubscription: Subscription;
  public filterFormGroup: UntypedFormGroup;
  public storedParams =
    new HIRLProjectRegistrationActivityMetricsByMonthRequestParams();
  public results: IChartResult[] = [];

  public yearChoices: string[] = [];
  public totalResultsPerYear: { name: string; value: number }[] = [];
  public avgResultsPerYear: { name: string; value: number }[] = [];
  public typeLabelMapping = HIRLProjectTypeLabelMapping;

  private componentDestroyed$ = new Subject();

  constructor(
    public store: Store<AppState>,
    public dialog: MatDialog,
    public fb: UntypedFormBuilder,
    public hirlProjectRegistrationService: HIRLProjectRegistrationService,
    public uiHelper: UIHelperService
  ) {
    for (let i = 2008; i <= moment.utc().year(); i++) {
      this.yearChoices.push(i.toString());
    }
  }

  ngOnInit(): void {
    forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
    }).subscribe(({ currentUser }) => {
      this.setupFilterFormGroup();

      // select current year by default
      this.filterFormGroup
        .get('years')
        .patchValue([moment().utc().year().toString()]);
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  setupFilterFormGroup() {
    this.filterFormGroup = this.fb.group({
      years: [[]],
      project_type: [''],
      is_build_to_rent: [false],
    });

    this.filterFormGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(value => {
        this.storedParams =
          new HIRLProjectRegistrationActivityMetricsByMonthRequestParams();
        this.storedParams.years = this.filterFormGroup.value.years;
        this.storedParams.eep_program__slug = [];

        if (
          this.filterFormGroup.value.project_type ==
          HIRLProjectType.singleFamily
        ) {
          this.storedParams.eep_program__slug =
            HIRLProjectRegistrationCategories.singleFamily;
        }

        if (
          this.filterFormGroup.value.project_type ==
          HIRLProjectType.singleFamilyNC
        ) {
          this.storedParams.eep_program__slug =
            HIRLProjectRegistrationCategories.singleFamilyNC;
        }

        if (
          this.filterFormGroup.value.project_type ==
          HIRLProjectType.singleFamilyEB
        ) {
          this.storedParams.eep_program__slug =
            HIRLProjectRegistrationCategories.singleFamilyEB;
        }

        if (
          this.filterFormGroup.value.project_type ==
          HIRLProjectType.singleFamilyRTO
        ) {
          this.storedParams.is_build_to_rent = true;
        }

        if (
          this.filterFormGroup.value.project_type == HIRLProjectType.multiFamily
        ) {
          this.storedParams.eep_program__slug =
            HIRLProjectRegistrationCategories.multiFamily;
        }

        if (
          this.filterFormGroup.value.project_type ==
          HIRLProjectType.multiFamilyNC
        ) {
          this.storedParams.eep_program__slug =
            HIRLProjectRegistrationCategories.multiFamilyNC;
        }

        if (
          this.filterFormGroup.value.project_type ==
          HIRLProjectType.multiFamilyEB
        ) {
          this.storedParams.eep_program__slug =
            HIRLProjectRegistrationCategories.multiFamilyEB;
        }

        if (
          this.filterFormGroup.value.project_type ==
          HIRLProjectType.landDevelopment
        ) {
          this.storedParams.eep_program__slug =
            HIRLProjectRegistrationCategories.landDevelopment;
        }
        this.getResults();
      });
  }

  getResults() {
    this.isLoading = true;
    if (this.metricsSubscription) {
      this.metricsSubscription.unsubscribe();
    }

    this.metricsSubscription = this.hirlProjectRegistrationService
      .registration_activity_metrics_by_month(this.storedParams)
      .pipe(first(), takeUntil(this.componentDestroyed$))
      .subscribe(responseData => {
        this.totalResultsPerYear = _(responseData)
          .groupBy(item => item.month.substring(0, 4))
          .map(arr => {
            const { month, registrations_count } = arr[0];

            return {
              name: month.substring(0, 4),
              value: _(arr).sumBy('registrations_count'),
            };
          })
          .value();

        this.avgResultsPerYear = _(responseData)
          .groupBy(item => item.month.substring(0, 4))
          .map(arr => {
            const { month, registrations_count } = arr[0];
            let avg = 0;
            const activeMonthsCount = _(arr)
              .filter(
                (value: HIRLProjectRegistrationActivityMetricsByMonth) =>
                  value['registrations_count'] > 0
              )
              .size();

            if (activeMonthsCount > 0) {
              avg = _(arr).sumBy('registrations_count') / activeMonthsCount;
            }

            return {
              name: month.substring(0, 4),
              value: avg,
            };
          })
          .value();

        this.results = _(responseData)
          .groupBy(item => {
            return item.month.substring(0, 4);
          })
          .values()
          .map(value => {
            const monthSeries = _(value)
              .map(series => {
                return {
                  name: moment.utc(series.month).format('MMM'),
                  value: series.registrations_count,
                };
              })
              .chain()
              .keyBy('name')
              .mapValues('value')
              .value();

            return {
              name: `${value[0].month.substring(0, 4)}`,
              series: [
                { name: 'Jan', value: monthSeries.Jan || 0 },
                { name: 'Feb', value: monthSeries.Feb || 0 },
                { name: 'Mar', value: monthSeries.Mar || 0 },
                { name: 'Apr', value: monthSeries.Apr || 0 },
                { name: 'May', value: monthSeries.May || 0 },
                { name: 'Jun', value: monthSeries.Jun || 0 },
                { name: 'Jul', value: monthSeries.Jul || 0 },
                { name: 'Aug', value: monthSeries.Aug || 0 },
                { name: 'Sep', value: monthSeries.Sep || 0 },
                { name: 'Oct', value: monthSeries.Oct || 0 },
                { name: 'Nov', value: monthSeries.Nov || 0 },
                { name: 'Dec', value: monthSeries.Dec || 0 },
              ],
            };
          })
          .value();
        this.isLoading = false;
      });
  }
}
