import {
  AltName,
  Company,
  CompanyAccess,
  SponsoringPreferences,
} from '@/data/company/models';
import { Action, createReducer, on } from '@ngrx/store';
import {
  loadCompany,
  loadCompanyDocuments,
  loadCompanyDocumentsCount,
  updateCompanyDocument,
  deleteCompanyDocument,
  loadCompanyAltNames,
  loadCompanyAltNamesCount,
  updateCompanyAltName,
  deleteCompanyAltName,
  createCompanyAltName,
  loadCompanyAffiliationPreferences,
  loadCompanyAffiliationPreferencesCount,
  resetCompany,
  loadCompanySponsoringPreferences,
  loadCompanySponsoringPreferencesCount,
  loadCompanyCOIDocuments,
  loadCompanyCOIDocumentsCount,
  updateCompanyCOIDocument,
  loadCompanyCompanyAccessAction,
  loadCompanyCompanyAccessCountAction,
  updateCompanyCompanyAccessAction,
  loadCompanyContactCards,
  loadCompanyContactCardsCount,
  updateCompanyContactCard,
  loadCompanyEquipments,
  loadCompanyEquipmentsCount,
  updateCompanyEquipment,
  loadCompanyRemRateUsers,
  loadCompanyRemRateUsersCount,
  updateCompanyRemRateUser,
  loadCompanyNotes,
  loadCompanyNotesCount,
} from '@/state/actions/company/company.actions';
import { CustomerDocument } from '@/data/filehandling/models';
import { AffiliationPreferences } from '@/data/company/models/sponsoring';
import { COIDocument } from '@/data/customer-hirl/models';
import { ContactCard } from '@/data/core/models';
import { Equipment } from '@/data/equipment/models';
import { RemRateUser } from '@/data/remrate/models';
import { Annotation } from '@/data/annotation/models';

export const companyStateKey = 'company';

export interface CompanyState {
  detail: Company;
  documents: CustomerDocument[];
  documentsCount: number;
  altNames: AltName[];
  altNamesCount: number;
  affiliationPreferences: AffiliationPreferences[];
  affiliationPreferencesCount: number;
  sponsoringPreferences: SponsoringPreferences[];
  sponsoringPreferencesCount: number;
  coiDocuments: COIDocument[];
  coiDocumentsCount: number;
  companyAccesses: CompanyAccess[];
  companyAccessesCount: number;
  contactCards: ContactCard[];
  contactCardsCount: number;
  equipments: Equipment[];
  equipmentsCount: number;
  remRateUsers: RemRateUser[];
  remRateUsersCount: number;
  notes: Annotation[];
  notesCount: number;
}

export const companyInitialState: CompanyState = {
  detail: null,
  documents: [],
  documentsCount: 0,
  altNames: [],
  altNamesCount: 0,
  affiliationPreferences: [],
  affiliationPreferencesCount: 0,
  sponsoringPreferences: [],
  sponsoringPreferencesCount: 0,
  coiDocuments: [],
  coiDocumentsCount: 0,
  companyAccesses: [],
  companyAccessesCount: 0,
  contactCards: [],
  contactCardsCount: 0,
  equipments: [],
  equipmentsCount: 0,
  remRateUsers: [],
  remRateUsersCount: 0,
  notes: [],
  notesCount: 0,
};

const companyReducer = createReducer(
  companyInitialState,
  on(resetCompany, (state, action) => ({ ...companyInitialState })),
  on(loadCompany, (state, { payload }) => ({ ...state, detail: payload })),
  // Documents
  on(loadCompanyDocuments, (state, { payload }) => ({
    ...state,
    documents: payload,
  })),
  on(loadCompanyDocumentsCount, (state, { payload }) => ({
    ...state,
    documentsCount: payload,
  })),
  on(updateCompanyDocument, (state, { payload }) => {
    const documentIndex = state.documents.findIndex(
      document => document.id === payload.id
    );
    const newDocuments = Object.assign([], state.documents, {
      [documentIndex]: payload,
    });
    return { ...state, documents: newDocuments };
  }),
  on(deleteCompanyDocument, (state, { payload }) => {
    const documentIndex = state.documents.findIndex(
      document => document.id === payload.id
    );
    const newDocuments = Object.assign([], state.documents);
    newDocuments.splice(documentIndex, 1);
    return {
      ...state,
      documents: newDocuments,
      documentsCount: state.documentsCount - 1,
    };
  }),
  on(loadCompanyAltNames, (state, { payload }) => ({
    ...state,
    altNames: payload,
  })),
  on(loadCompanyAltNamesCount, (state, { payload }) => ({
    ...state,
    altNamesCount: payload,
  })),
  on(createCompanyAltName, (state, { payload }) => {
    const newDocuments = [payload].concat(state.altNames);
    return { ...state, altNames: newDocuments };
  }),
  on(updateCompanyAltName, (state, { payload }) => {
    const altNameIndex = state.altNames.findIndex(
      document => document.id === payload.id
    );
    const newDocuments = Object.assign([], state.altNames, {
      [altNameIndex]: payload,
    });
    return { ...state, altNames: newDocuments };
  }),
  on(deleteCompanyAltName, (state, { payload }) => {
    const altNameIndex = state.altNames.findIndex(
      document => document.id === payload.id
    );
    const newDocuments = Object.assign([], state.altNames);
    newDocuments.splice(altNameIndex, 1);
    return {
      ...state,
      altNames: newDocuments,
      altNamesCount: state.altNamesCount - 1,
    };
  }),
  // AffiliationPreferences
  on(loadCompanyAffiliationPreferences, (state, { payload }) => ({
    ...state,
    affiliationPreferences: payload,
  })),
  on(loadCompanyAffiliationPreferencesCount, (state, { payload }) => ({
    ...state,
    affiliationPreferencesCount: payload,
  })),
  // SponsoringPreferences
  on(loadCompanySponsoringPreferences, (state, { payload }) => ({
    ...state,
    sponsoringPreferences: payload,
  })),
  on(loadCompanySponsoringPreferencesCount, (state, { payload }) => ({
    ...state,
    sponsoringPreferencesCount: payload,
  })),
  // CompanyAccesses
  on(loadCompanyCompanyAccessAction, (state, { payload }) => ({
    ...state,
    companyAccesses: payload,
  })),
  on(loadCompanyCompanyAccessCountAction, (state, { payload }) => ({
    ...state,
    companyAccessesCount: payload,
  })),
  on(updateCompanyCompanyAccessAction, (state, { payload }) => {
    const companyAccessIndex = state.companyAccesses.findIndex(
      companyAccess => companyAccess.id === payload.id
    );
    const newCompanyAccesses = Object.assign([], state.companyAccesses, {
      [companyAccessIndex]: payload,
    });
    return { ...state, companyAccesses: newCompanyAccesses };
  }),
  // COI Documents
  on(loadCompanyCOIDocuments, (state, { payload }) => ({
    ...state,
    coiDocuments: payload,
  })),
  on(loadCompanyCOIDocumentsCount, (state, { payload }) => ({
    ...state,
    coiDocumentsCount: payload,
  })),
  on(updateCompanyCOIDocument, (state, { payload }) => {
    const coiDocumentIndex = state.coiDocuments.findIndex(
      coiDocument => coiDocument.id === payload.id
    );
    const newCOIDocuments = Object.assign([], state.coiDocuments, {
      [coiDocumentIndex]: payload,
    });
    return { ...state, coiDocuments: newCOIDocuments };
  }),
  // Contact Cards
  on(loadCompanyContactCards, (state, { payload }) => ({
    ...state,
    contactCards: payload,
  })),
  on(loadCompanyContactCardsCount, (state, { payload }) => ({
    ...state,
    contactCardsCount: payload,
  })),
  on(updateCompanyContactCard, (state, { payload }) => {
    const contactCardIndex = state.contactCards.findIndex(
      contactCard => contactCard.id === payload.id
    );
    const newContactCards = Object.assign([], state.contactCards, {
      [contactCardIndex]: payload,
    });
    return { ...state, contactCards: newContactCards };
  }),
  // Equipments
  on(loadCompanyEquipments, (state, { payload }) => ({
    ...state,
    equipments: payload,
  })),
  on(loadCompanyEquipmentsCount, (state, { payload }) => ({
    ...state,
    equipmentsCount: payload,
  })),
  on(updateCompanyEquipment, (state, { payload }) => {
    const equipmentIndex = state.equipments.findIndex(
      equipment => equipment.id === payload.id
    );
    const newEquipments = Object.assign([], state.equipments, {
      [equipmentIndex]: payload,
    });
    return { ...state, equipments: newEquipments };
  }),
  // RemRate Users
  on(loadCompanyRemRateUsers, (state, { payload }) => ({
    ...state,
    remRateUsers: payload,
  })),
  on(loadCompanyRemRateUsersCount, (state, { payload }) => ({
    ...state,
    remRateUsersCount: payload,
  })),
  on(updateCompanyRemRateUser, (state, { payload }) => {
    const remRateUserIndex = state.remRateUsers.findIndex(
      remrateUser => remrateUser.id === payload.id
    );
    const newRemrateUsers = Object.assign([], state.remRateUsers, {
      [remRateUserIndex]: payload,
    });
    return { ...state, remrateUsers: newRemrateUsers };
  }),
  // Notes
  on(loadCompanyNotes, (state, { payload }) => ({
    ...state,
    notes: payload,
  })),
  on(loadCompanyNotesCount, (state, { payload }) => ({
    ...state,
    notesCount: payload,
  }))
);

export function reducer(state: CompanyState | undefined, action: Action) {
  return companyReducer(state, action);
}
