<div class="row mt-2">
    <div class="col-12">
        <p class="mat-headline">Monthly Registrations by Project/Buildings</p>
    </div>
</div>

<app-customer-hirl-registrations-per-month-metrics-chart-by-projects></app-customer-hirl-registrations-per-month-metrics-chart-by-projects>

<div class="row mt-2">
    <div class="col-12">
        <p class="mat-headline">Monthly Registrations by Units</p>
    </div>
</div>

<app-customer-hirl-registrations-per-month-metrics-chart-by-units></app-customer-hirl-registrations-per-month-metrics-chart-by-units>


<div class="row mt-2">
    <div class="col-12">
        <p class="mat-headline">Monthly Certifications by Project/Buildings</p>
    </div>
</div>

<app-customer-hirl-home-status-certification-metric-chart-by-projects></app-customer-hirl-home-status-certification-metric-chart-by-projects>

<div class="row mt-2">
    <div class="col-12">
        <p class="mat-headline">Monthly Certifications by Units</p>
    </div>
</div>

<app-customer-hirl-home-status-certification-metric-chart-by-units></app-customer-hirl-home-status-certification-metric-chart-by-units>

<div class="row mt-2">
    <div class="col-12">
        <p class="mat-headline">Project Cycle Time</p>
    </div>
</div>

<app-customer-hirl-project-cycle-time-metrics-chart></app-customer-hirl-project-cycle-time-metrics-chart>
