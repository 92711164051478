import { PracticeSectionInfo } from '@/data/customer-hirl/models/practice-section';

export class Practice {
  id?: number;
  title?: string;
  description?: string;
  section?: number;
  section_info?: PracticeSectionInfo;
}

export class PracticeInfo {
  id?: number;
  title?: string;
  description?: string;
  section?: number;
  section_info?: PracticeSectionInfo;
}
