import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServerResponse } from '@/core/schemes/server-response';
import { environment } from '../../../../environments/environment';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import { ListRequestParams } from '@/core/schemes/request-params';
import {
  CustomerHIRLGCPProductReportDownload,
  CustomerHIRLProductList,
  GCPProductCertificateMultipleDownload,
  Product,
  ProductBulkPublish,
  ProductImage,
  ProductList,
  ProductStatus,
} from '@/data/product/models';
import { tap } from 'rxjs/operators';
import {
  loadProduct,
  loadProductPaymentRequestsAction,
  loadProductPaymentRequestsCountAction,
  loadProductProductImagesAction,
  loadProductProductImagesCountAction,
  loadProductProductPracticeItemsAction,
  loadProductProductPracticeItemsCountAction,
} from '@/state/actions/product/product.actions';
import { ProductImageRequestParams } from '@/data/product/services/product-image.service';
import {
  PaymentRequestProduct,
  ProductPracticeItem,
} from '@/data/customer-hirl/models';
import { ProductPracticeItemRequestParams } from '@/data/customer-hirl/services/product-practice-item.service';
import { PaymentRequestProductRequestParams } from '@/data/customer-hirl/services/payment-request-product.service';
import { AsynchronousProcessedDocument } from '@/data/filehandling/models';

export class ProductRequestParams extends ListRequestParams {
  manufacturer?: number | number[];
  brand?: number | number[];
  categories?: number | number[];
  status?: ProductStatus | ProductStatus[];
  company_brands?: number | number[];
  hirl_certificate_expiration_date__lte?: string;
  hirl_certificate_expiration_date__gte?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ProductService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/products/`;

  list(params?: ProductRequestParams): Observable<ServerResponse<ProductList>> {
    return this.http.get<ServerResponse<ProductList>>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }

  customer_hirl_list(
    params?: ProductRequestParams
  ): Observable<ServerResponse<CustomerHIRLProductList>> {
    return this.http.get<ServerResponse<CustomerHIRLProductList>>(
      `${this.baseUrl}customer_hirl_list/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  create(product: Product) {
    return this.http.post<Product>(`${this.baseUrl}`, product);
  }

  retrieve(
    productId: number,
    options?: IDataServiceMethodOptions
  ): Observable<Product> {
    const ignoreStore = options?.ignoreStore;
    let chain = this.http.get<Product>(`${this.baseUrl}${productId}`);
    if (!ignoreStore) {
      chain = chain.pipe(
        tap((newEntity: Product) =>
          this.store.dispatch(loadProduct({ payload: newEntity }))
        )
      );
    }
    return chain;
  }

  update(
    productId: number,
    product: Product,
    options?: IDataServiceMethodOptions
  ): Observable<Product> {
    return this.http
      .patch<Product>(`${this.baseUrl}${productId}/`, product)
      .pipe(
        tap(newEntity =>
          this.store.dispatch(loadProduct({ payload: newEntity }))
        )
      );
  }

  product_images(productId: number, params?: ProductImageRequestParams) {
    return this.http
      .get<ServerResponse<ProductImage>>(
        `${this.baseUrl}${productId}/product_images/`,
        {
          params: this.rollParams(params),
        }
      )
      .pipe(
        tap(serverResponse =>
          this.store.dispatch(
            loadProductProductImagesAction({ payload: serverResponse.results })
          )
        ),
        tap(serverResponse =>
          this.store.dispatch(
            loadProductProductImagesCountAction({
              payload: serverResponse.count,
            })
          )
        )
      );
  }

  product_imagesCreate(productId: number, productImage: ProductImage) {
    return this.http.post<ProductImage>(
      `${this.baseUrl}${productId}/product_images/`,
      productImage
    );
  }

  product_practice_items(
    productId: number,
    params?: ProductPracticeItemRequestParams
  ) {
    return this.http
      .get<ServerResponse<ProductPracticeItem>>(
        `${this.baseUrl}${productId}/product_practice_items/`,
        {
          params: this.rollParams(params),
        }
      )
      .pipe(
        tap(serverResponse =>
          this.store.dispatch(
            loadProductProductPracticeItemsAction({
              payload: serverResponse.results,
            })
          )
        ),
        tap(serverResponse =>
          this.store.dispatch(
            loadProductProductPracticeItemsCountAction({
              payload: serverResponse.count,
            })
          )
        )
      );
  }

  product_practice_itemsCreate(
    productId: number,
    practiceItemProduct: ProductPracticeItem
  ) {
    return this.http.post<ProductPracticeItem>(
      `${this.baseUrl}${productId}/product_practice_items/`,
      practiceItemProduct
    );
  }

  publish(productId: number): Observable<Product> {
    return this.http
      .post<Product>(`${this.baseUrl}${productId}/publish/`, {})
      .pipe(
        tap((entity: Product) =>
          this.store.dispatch(loadProduct({ payload: entity }))
        )
      );
  }

  bulk_publish(
    productBulkPublish: ProductBulkPublish,
    params?: ProductRequestParams
  ): Observable<ServerResponse<CustomerHIRLProductList>> {
    return this.http.post<ServerResponse<CustomerHIRLProductList>>(
      `${this.baseUrl}bulk_publish/`,
      productBulkPublish,
      {
        params: this.rollParams(params),
      }
    );
  }

  archive(productId: number): Observable<Product> {
    return this.http
      .post<Product>(`${this.baseUrl}${productId}/archive/`, {})
      .pipe(
        tap((entity: Product) =>
          this.store.dispatch(loadProduct({ payload: entity }))
        )
      );
  }

  payment_requests(
    productId: number,
    params?: PaymentRequestProductRequestParams
  ) {
    return this.http
      .get<ServerResponse<PaymentRequestProduct>>(
        `${this.baseUrl}${productId}/payment_requests/`,
        {
          params: this.rollParams(params),
        }
      )
      .pipe(
        tap(serverResponse =>
          this.store.dispatch(
            loadProductPaymentRequestsAction({
              payload: serverResponse.results,
            })
          )
        ),
        tap(serverResponse =>
          this.store.dispatch(
            loadProductPaymentRequestsCountAction({
              payload: serverResponse.count,
            })
          )
        )
      );
  }

  customer_hirl_gcp_products_report(
    data: CustomerHIRLGCPProductReportDownload,
    params?: ProductRequestParams
  ): Observable<AsynchronousProcessedDocument> {
    return this.http.post<AsynchronousProcessedDocument>(
      `${this.baseUrl}customer_hirl_gcp_products_report/`,
      data,
      {
        params: this.rollParams(params),
      }
    );
  }

  customer_hirl_gcp_certificate_multiple_download(
    data: GCPProductCertificateMultipleDownload,
    params?: ProductRequestParams
  ): Observable<AsynchronousProcessedDocument> {
    return this.http.post<AsynchronousProcessedDocument>(
      `${this.baseUrl}customer_hirl_gcp_certificate_multiple_download/`,
      data,
      {
        params: this.rollParams(params),
      }
    );
  }
}
