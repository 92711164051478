<h1 mat-dialog-title>
  {{ verifier.first_name }} {{ verifier.last_name }}
</h1>
<div mat-dialog-content>
  <div class="row">
    <div class="col-12">
      <b>Company: </b> {{ verifier.company_info?.name }}
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <b>Home Office: </b> {{ verifier.company_info?.city_info?.name }}, {{ verifier.company_info?.state }}
    </div>
  </div>
  <div class="row" *ngIf="verifier.work_phone">
    <div class="col-12">
      <b>Work Phone: </b> <a href="tel:{{ verifier.work_phone }}">{{ verifier.work_phone }}</a>
    </div>
  </div>
  <div class="row" *ngIf="verifier.cell_phone">
    <div class="col-12">
      <b>Cell Phone: </b> <a href="tel:{{ verifier.cell_phone }}">{{ verifier.cell_phone }}</a>
    </div>
  </div>
  <div class="row" *ngIf="verifier.company_info?.home_page">
    <div class="col-12">
      <b>Company Website: </b> <a href="{{ verifier.company_info?.home_page }}"
                                  target="_blank">{{ verifier.company_info?.home_page }}</a>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <b>Additional Services Provided: </b>
      <ng-container *ngIf="verifier.customer_hirl_enrolled_verifier_agreements_info?.length">
        <ng-container
          *ngFor="let providedService of verifier.customer_hirl_enrolled_verifier_agreements_info[0].provided_services; let isLast=last">
          {{ providedService.name }}{{ isLast ? '' : ', ' }}
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <b>Accreditations: </b>
      <div *ngIf="findAccreditation('ngbs-2012', verifier.accreditations_info)">
        2012 NGBS
      </div>
      <div *ngIf="findAccreditation('ngbs-2015', verifier.accreditations_info)">
        2015 NGBS
      </div>
      <div *ngIf="findAccreditation('ngbs-2020', verifier.accreditations_info)">
        2020 NGBS
      </div>
      <div *ngIf="findAccreditation('ngbs-green-field-rep', verifier.accreditations_info)">
        Green Field Rep
      </div>
    </div>
  </div>
</div>
<mat-dialog-actions>
  <button class="btn btn-primary mr-2" (click)="closeClick()">
    Close
  </button>
</mat-dialog-actions>
