import {
  Component,
  DoCheck,
  ElementRef,
  HostBinding,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import { ContactCard } from '@/data/core/models';
import { ContactCardEditDialogComponent } from '@/modules/user/components/contact-card-edit-dialog/contact-card-edit-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NgControl,
} from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject, Subscription } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { FocusMonitor } from '@angular/cdk/a11y';
import { first, takeUntil, tap } from 'rxjs/operators';
import { ContactCardService } from '@/data/core/services/contact_card';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-contact-card-select',
  templateUrl: './contact-card-select.component.html',
  styleUrls: ['./contact-card-select.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: ContactCardSelectComponent,
    },
  ],
})
export class ContactCardSelectComponent
  implements
    ControlValueAccessor,
    MatFormFieldControl<any>,
    OnInit,
    OnDestroy,
    DoCheck
{
  static nextId = 0;
  private placeholder$: string;
  private required$ = false;
  private disabled$ = false;

  private filterParams$: any = {};
  @Input() set filterParams(value: any) {
    this.filterParams$ = value;
    this.touched = true;
    this.list();
  }

  get filterParams(): any {
    return this.filterParams$;
  }

  @Input() contactCardInitialData: ContactCard | any = new ContactCard();
  /**
   * Select first option by default
   */
  @Input() selectFirst = true;

  @HostBinding('[id]')
  public id = `app-contact-card-select-${ContactCardSelectComponent.nextId++}`;

  public control = new UntypedFormControl();
  public isLoading = false;
  public results: ContactCard[];
  private listSubscription$: Subscription;
  public selectedContactCard: ContactCard;

  private componentDestroyed$ = new Subject();

  // angular mat input
  public focused = false;
  public touched = false;
  public stateChanges = new Subject<void>();
  public describedBy = '';
  public onChange = (_: any) => {};
  public onTouched = () => {};

  get empty() {
    return !this.control.value;
  }

  @HostBinding('[class.app-contact-card-select-floating]')
  get shouldLabelFloat() {
    return true;
  }

  get errorState() {
    return this.ngControl.errors !== null && this.touched;
  }

  @Input()
  get placeholder(): string {
    return this.placeholder$;
  }

  set placeholder(value: string) {
    this.placeholder$ = value;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this.required$;
  }

  set required(value: boolean) {
    this.required$ = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this.disabled$;
  }

  set disabled(value: boolean) {
    this.disabled$ = coerceBooleanProperty(value);
    this.disabled$ ? this.control.disable() : this.control.enable();
    this.stateChanges.next();
  }

  @Input()
  get value(): any {
    return this.control.value;
  }

  set value(value: any) {
    this.control.setValue(value);
    this.onChange(value);
    this.stateChanges.next();
  }

  constructor(
    private focusMonitorService: FocusMonitor,
    private injector: Injector,
    private dialog: MatDialog,
    private contactCardService: ContactCardService,
    private elementRef$: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl
  ) {
    focusMonitorService.monitor(elementRef$, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  ngDoCheck(): void {
    if (this.touched !== this.ngControl.control.touched) {
      this.touched = this.ngControl.pristine;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  list() {
    this.isLoading = true;
    this.results = [];

    const params = Object.assign(this.filterParams, { page_size: 100 });
    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }
    this.listSubscription$ = this.contactCardService
      .list(params)
      .pipe(
        takeUntil(this.componentDestroyed$),
        first(),
        tap(response => (this.results = response.results))
      )
      .subscribe(_ => {
        this.isLoading = false;

        const previousSelected = this.results.find(
          contactCard => contactCard.id === this.value
        );
        if (previousSelected) {
          this.selectedContactCard = previousSelected;
          this.writeValue(previousSelected.id);
        } else {
          if (this.selectFirst && this.results.length > 0) {
            this.selectedContactCard = this.results[0];
            this.writeValue(this.results[0].id);
          } else {
            this.selectedContactCard = null;
            this.writeValue(null);
          }
        }
      });
  }

  // angular mat input methods
  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (
      !this.elementRef$.nativeElement.contains(event.relatedTarget as Element)
    ) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick($event: MouseEvent) {
    $event.stopPropagation();
    if (this.disabled) {
      return;
    }
    this.onTouched();
    if (($event.target as Element).tagName.toLowerCase() === 'mat-icon') {
      this.clear();
    } else {
      this.handleInput();
    }
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private clear(): void {
    this.value = null;
  }

  private handleInput() {}

  onSelectionChanged($event: MatSelectChange) {
    this.value = $event.source.value;
    this.selectedContactCard = this.results.find(
      contactCard => contactCard.id === this.value
    );
  }

  onCreate(event: any) {
    const dialogRef = this.dialog.open(ContactCardEditDialogComponent, {
      width: '45%',
      data: {
        contactCard: this.contactCardInitialData,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      this.list();
    });
  }

  onEdit(event: any, contactCard: ContactCard) {
    const dialogRef = this.dialog.open(ContactCardEditDialogComponent, {
      width: '45%',
      data: {
        contactCard,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      this.list();
    });
  }
}
