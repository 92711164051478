import {
  MechanicalVentilationBackendDict,
  createMechanicalVentilationForm,
} from '@/data/simulation/models/systems/MechanicalVentilation';
import { BaseValidator } from './base.validator';
import { ModelErrors, StateErrors } from '../state/shared/base.state';

export class MechanicalVentilationValidator extends BaseValidator {
  static validateForm(
    mechanicalVentilation: MechanicalVentilationBackendDict
  ): ModelErrors<MechanicalVentilationBackendDict> {
    const form = createMechanicalVentilationForm(mechanicalVentilation);
    return form.getFieldAndModelErros();
  }

  static validate(
    mechanicalVentilations: MechanicalVentilationBackendDict[]
  ): StateErrors<MechanicalVentilationBackendDict> {
    const errors = {};
    mechanicalVentilations.forEach(mechanicalVentilation => {
      errors[mechanicalVentilation.id] = this.validateForm(
        mechanicalVentilation
      );
    });
    return errors;
  }
}
