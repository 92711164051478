import { ModelFormGroup } from '@/data/simulation/models/ModelForm';
import { Directive, Input } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  FormControlName,
  FormGroupDirective,
  NgControl,
  ValidationErrors,
} from '@angular/forms';
import { Subject } from 'rxjs';

@Directive()
export class BaseAppControlValueAccessor implements ControlValueAccessor {
  @Input() info: string | null = null;
  // Make sure to use isInEditMode when using this component without a formGroup!
  @Input() isInEditMode = false;
  control: FormControl;
  errors: ValidationErrors | null = null;

  onChange: any = () => {};
  onTouched: any = () => {};
  componentDestroyed$ = new Subject();
  required = false;

  constructor(public ngControl: NgControl, private fg: FormGroupDirective) {
    // Replace the provider from above with this.
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    if (this.ngControl instanceof FormControlName) {
      this.control = this.fg.getControl(this.ngControl);
    } else if (this.ngControl instanceof FormControlDirective) {
      // Make sure to use isInEditMode when using this component without a formGroup!
      this.control = this.ngControl.form;
    } else {
      throw new Error('FormControlName or FormControl is required');
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.control._rawValidators?.forEach(validator => {
      if (validator.name === 'required') {
        this.required = true;
      }
    });
    if (this.control.parent) {
      this.isInEditMode = (
        this.control.parent as ModelFormGroup
      ).getEditableState();
      (this.control.parent as ModelFormGroup).enable$.subscribe(status => {
        this.isInEditMode = status;
      });
    }
  }

  setDisabledState(isDisabled: boolean) {}

  writeValue(val: any): void {}

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
