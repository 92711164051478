export function evaluateSimpleExpression(expression: string): number | null {
  // Remove whitespace
  const sanitizedExpr = expression.replace(/\s+/g, '');

  // Validate expression (only numbers and basic operators)
  if (!/^[\d+\-*/().]+$/.test(sanitizedExpr)) {
    console.error('Invalid characters in expression');
    return null;
  }

  try {
    // Tokenize the expression
    const tokens = sanitizedExpr.match(/(\d+\.?\d*|\+|-|\*|\/|\(|\))/g);
    if (!tokens) return null;

    // Evaluate using a stack-based approach
    const result = evaluateTokens(tokens);
    return result;
  } catch (error) {
    console.error('Error evaluating expression:', error);
    return null;
  }
}

function evaluateTokens(tokens: string[]): number {
  const ops: string[] = [];
  const values: number[] = [];

  const precedence: Record<string, number> = { '+': 1, '-': 1, '*': 2, '/': 2 };

  const applyOperator = () => {
    const op = ops.pop();
    const b = values.pop()!;
    const a = values.pop()!;
    switch (op) {
      case '+':
        values.push(a + b);
        break;
      case '-':
        values.push(a - b);
        break;
      case '*':
        values.push(a * b);
        break;
      case '/':
        values.push(a / b);
        break;
    }
  };

  for (const token of tokens) {
    if (!isNaN(Number(token))) {
      values.push(parseFloat(token));
    } else if (token === '(') {
      ops.push(token);
    } else if (token === ')') {
      while (ops[ops.length - 1] !== '(') applyOperator();
      ops.pop();
    } else if (['+', '-', '*', '/'].includes(token)) {
      while (
        ops.length &&
        precedence[ops[ops.length - 1]] >= precedence[token]
      ) {
        applyOperator();
      }
      ops.push(token);
    }
  }

  while (ops.length) applyOperator();

  return values[0];
}
