import { AuthGuard } from '@/core/guards/auth.guard';
import { CommunityListComponent } from '@/modules/community/pages/community-list-page/community-list-page.component';

export const CommunityRouting = [
  {
    path: 'community',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: CommunityListComponent,
        canActivate: [AuthGuard],
      },
      // {
      //   path: "detail/:communityId",
      //   component: CommunityDetailPageComponent,
      //   canActivate: [AuthGuard],
      // },
    ],
  },
];
