import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { User } from '@/data/core/models/user';
import { AxisFlatPage } from '@/data/core/models';
import { forkJoin, Subject } from 'rxjs';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '@/data/core/services/user.service';
import { AxisFlatPageService } from '@/data/core/services/axis_flat_page';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { toggleLoading } from '@/state/actions/app.actions';
import { first, takeUntil } from 'rxjs/operators';
import { getInfoUser } from '@/state/selectors/info.selector';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as CustomEditor from 'assets/ckeditor/ckeditor';

@Component({
  selector: 'app-flatpage-edit-page-form',
  templateUrl: './flatpage-edit-page-form.component.html',
  styleUrls: ['./flatpage-edit-page-form.component.scss'],
})
export class FlatpageEditPageFormComponent implements OnInit, OnDestroy {
  public editor = CustomEditor;
  public editorConfig = {
    toolbar: [
      'heading',
      '|',
      'fontfamily',
      'fontsize',
      '|',
      'alignment',
      '|',
      'fontColor',
      'fontBackgroundColor',
      '|',
      'bold',
      'italic',
      'strikethrough',
      'underline',
      'subscript',
      'superscript',
      '|',
      'link',
      '|',
      'outdent',
      'indent',
      '|',
      'bulletedList',
      'numberedList',
      'todoList',
      '|',
      'code',
      'codeBlock',
      '|',
      'insertTable',
      '|',
      'blockQuote',
      '|',
      'undo',
      'redo',
      '|',
      'sourceEditing',
    ],
    allowedContent: true,
  };
  public initialized = false;
  public currentUser: User;
  public flatPage: AxisFlatPage;
  public formGroup: UntypedFormGroup;
  public isLoading = false;

  public flatPageId$ = new Subject<number>();

  private componentDestroyed$ = new Subject();

  constructor(
    public fb: UntypedFormBuilder,
    public store: Store<AppState>,
    public injector: Injector,
    public router: Router,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public activatedRoute: ActivatedRoute,
    public userService: UserService,
    public axisFlatPageService: AxisFlatPageService,
    public uiHelperService: UIHelperService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(toggleLoading({ payload: true }));

    this.flatPageId$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(flatPageId => {
        const sources: { [k: string]: any } = {
          currentUser: this.store.select(getInfoUser).pipe(first()),
          flatPage: this.axisFlatPageService.retrieve(flatPageId),
        };

        forkJoin(sources).subscribe(({ currentUser, flatPage }) => {
          this.currentUser = currentUser;
          this.flatPage = flatPage;

          this.store.dispatch(toggleLoading({ payload: false }));
          this.initialized = true;
          this.hydrateForm();
        });
      });

    this.activatedRoute.params
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(params => {
        this.flatPageId$.next(params.flatPageId);
      });

    this.setupForm();
  }

  public ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  public setupForm() {
    this.formGroup = this.fb.group({
      title: [null, [Validators.required]],
      content: [null, []],
    });
  }

  hydrateForm() {
    this.formGroup.patchValue(this.flatPage, {
      emitEvent: false,
      onlySelf: true,
    });
  }

  handleBack() {
    if (this.flatPage.url === '/verifier/resources/') {
      return this.router.navigate([
        '/',
        'hi',
        'verifier',
        this.currentUser.id,
        'resources',
      ]);
    }
    return this.router.navigate(['/']);
  }

  onSave($event) {
    $event.preventDefault();
    this.formGroup.markAllAsTouched();

    if (this.formGroup.invalid) {
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const formData = new AxisFlatPage();
    formData.title = this.formGroup.value.title;
    formData.content = this.formGroup.value.content;

    this.axisFlatPageService.update(formData, this.flatPage.id).subscribe(
      flatPage => {
        this.store.dispatch(toggleLoading({ payload: false }));
        this.flatPage = flatPage;
        this.hydrateForm();
        this.snackBar.open('Page successfully saved', 'Close', {
          duration: 5000,
        });
      },
      error => this.uiHelperService.handleUserRequestError(error)
    );
  }
}
