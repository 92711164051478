import { Injectable } from '@angular/core';
import { ListRequestParams } from './../../../core/schemes/request-params';
import { ServerResponse } from './../../../core/schemes/server-response';
import { BaseDataService } from './../../../core/services/base-data-service';

import { environment } from '../../../../environments/environment';
import { FastTrackSubmission } from './../models/';

export class ProjectTrackerRequestParams extends ListRequestParams {}

@Injectable({ providedIn: 'root' })
export class ProjectTrackerService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/project-tracker-list/`;

  list(params?: ProjectTrackerRequestParams) {
    return this.http.get<ServerResponse<FastTrackSubmission>>(
      `${this.baseUrl}`,
      {
        params: this.rollParams(params),
      }
    );
  }
}
