export class Country {
  id?: number;
  abbr: string;
  name: string;
}

export interface CountryBackendDict {
  id: number;
  abbr: string;
  name: string;
}
