import { County, CountyBackendDict } from './county';
import { Country, CountryBackendDict } from '@/data/geographic/models/country';

export class City {
  id: number;
  county: number;
  county_info: County;
  country: string;
  country_info: Country;
  name: string;
  place_fips: string;
  legal_statistical_area_description: string;
  ansi_code: string;
  land_area_meters: number;
  water_area_meters: number;
  latitude: number;
  longitude: number;
}

export interface CityBackendDict {
  id: number;
  county: number;
  country: string;
  name: string;
  place_fips: string;
  legal_statistical_area_description: string;
  ansi_code: string;
  land_area_meters: number;
  water_area_meters: number;
  latitude: number;
  longitude: number;
}

export interface DetailedCityBackendDict extends CityBackendDict {
  county_info: CountyBackendDict;
  country_info: CountryBackendDict;
}
