import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { toggleLoading } from '@/state/actions/app.actions';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AppState } from '@/state/reducers';
import { User } from '@/data/core/models/user';
import { SponsorPreferencesService } from '@/data/company/services/sponsor-preferences.service';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { SponsorPreferences } from '@/data/company/models/sponsoring';
import { ActivatedRoute } from '@angular/router';

class SponsorPreferencesCreateParams {
  company_to?: number;
}

@Component({
  selector: 'app-sponsor-preferences-create-page',
  templateUrl: './sponsor-preferences-create-page.component.html',
  styleUrls: ['./sponsor-preferences-create-page.component.scss'],
})
export class SponsorPreferencesCreatePageComponent implements OnInit {
  @ViewChild('stepper') stepper;
  public initialized = false;
  public currentUser: User;
  public storedParams: SponsorPreferencesCreateParams;

  public companyToFormGroup: UntypedFormGroup;
  public settingsFormGroup: UntypedFormGroup;

  constructor(
    public fb: UntypedFormBuilder,
    public store: Store<AppState>,
    private activatedRoute: ActivatedRoute,
    public sponsorPreferencesService: SponsorPreferencesService,
    public uiHelperService: UIHelperService
  ) {}

  ngOnInit(): void {
    const sources: { [k: string]: any } = {
      queryParams: this.activatedRoute.queryParams.pipe(first()),
      currentUser: this.store.select(getInfoUser).pipe(first()),
    };

    forkJoin(sources).subscribe(({ currentUser, queryParams }) => {
      this.storedParams = queryParams;
      this.currentUser = currentUser;

      this.store.dispatch(toggleLoading({ payload: false }));
      this.initialized = true;
    });

    this.setupCompanyToFormGroup();
    this.setupSettingsForm();
  }

  setupCompanyToFormGroup() {
    this.companyToFormGroup = this.fb.group({
      company_to_info: [null, Validators.required],
    });
  }

  setupSettingsForm() {
    this.settingsFormGroup = this.fb.group({
      can_edit_profile: [true],
      can_edit_identity_fields: [false],
      notify_sponsor_on_update: [true],
    });
  }

  reset() {
    this.stepper.reset();
  }

  onSave($event: MouseEvent) {
    $event.stopPropagation();
    this.companyToFormGroup.markAllAsTouched();
    this.settingsFormGroup.markAllAsTouched();

    if (this.companyToFormGroup.invalid || this.settingsFormGroup.invalid) {
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const sponsorPreferences = new SponsorPreferences();
    sponsorPreferences.sponsor = this.currentUser.company;
    sponsorPreferences.sponsored_company =
      this.companyToFormGroup.value.company_to_info.id;

    sponsorPreferences.can_edit_profile =
      this.settingsFormGroup.value.can_edit_profile;
    sponsorPreferences.can_edit_identity_fields =
      this.settingsFormGroup.value.can_edit_identity_fields;
    sponsorPreferences.notify_sponsor_on_update =
      this.settingsFormGroup.value.notify_sponsor_on_update;

    this.sponsorPreferencesService
      .create(sponsorPreferences)
      .pipe(first())
      .subscribe(
        _ => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.stepper.selectedIndex = this.stepper.selectedIndex + 1;
        },
        error => this.uiHelperService.handleUserRequestError(error)
      );
  }
}
