import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { first, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getInfoUser } from '@/state/selectors/info.selector';
import { AppState } from '@/state/reducers';
import { CustomerDocumentService } from '@/data/filehandling/services/customer-document.service';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';
import { CustomerDocument } from '@/data/filehandling/models';

@Injectable({
  providedIn: 'root',
})
export class CustomerDocumentPermissionService {
  constructor(
    public store: Store<AppState>,
    public customerDocumentService: CustomerDocumentService
  ) {}
  canEdit(
    customerDocument: CustomerDocument | number
  ): Observable<ObjectPermissionResponse> {
    let customerDocument$: Observable<CustomerDocument>;
    if (typeof customerDocument === 'number') {
      customerDocument$ = this.customerDocumentService.retrieve(
        customerDocument,
        { ignoreStore: true }
      );
    } else {
      customerDocument$ = of(customerDocument);
    }
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: customerDocument$,
    }).pipe(
      map(({ currentUser, entity }) => {
        if (currentUser.is_superuser) {
          return new ObjectPermissionResponse(true);
        }
        const matches = [
          'Home via Bulk Upload',
          'Home via Single Home Upload',
          'Scoring upload',
        ];

        if (matches.some(s => entity.description?.includes(s))) {
          return new ObjectPermissionResponse(
            false,
            `You do not have permissions to edit documents from Bulk Upload or Scoring Upload`
          );
        }

        if (
          currentUser.company_info?.slug === CustomerHIRLSettings.companySlug &&
          currentUser.is_company_admin
        ) {
          if (
            entity.company_info?.sponsor_slugs.includes(
              CustomerHIRLSettings.companySlug
            )
          ) {
            return new ObjectPermissionResponse(true);
          }
        }
        if (currentUser.company === entity.company) {
          return new ObjectPermissionResponse(true);
        }
        return new ObjectPermissionResponse(
          false,
          'You do not have permissions to edit this Document'
        );
      })
    );
  }

  canDelete(
    customerDocument: CustomerDocument | number
  ): Observable<ObjectPermissionResponse> {
    return this.canEdit(customerDocument);
  }
}
