import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { first, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getInfoUser } from '@/state/selectors/info.selector';
import { AppState } from '@/state/reducers';
import { ProductImage } from '@/data/product/models';
import { ProductImageService } from '@/data/product/services/product-image.service';

@Injectable({
  providedIn: 'root',
})
export class ProductImagePermissionService {
  constructor(
    public store: Store<AppState>,
    public productImageService: ProductImageService
  ) {}

  canCreate(): Observable<ObjectPermissionResponse> {
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
    }).pipe(
      map(({ currentUser }) => {
        if (!currentUser.is_company_admin) {
          return new ObjectPermissionResponse(
            false,
            'Only Company Admin users can Create new products'
          );
        }
        return new ObjectPermissionResponse(true);
      })
    );
  }

  canEdit(
    productImage: ProductImage | number
  ): Observable<ObjectPermissionResponse> {
    let productImage$: Observable<ProductImage>;
    if (typeof productImage === 'number') {
      productImage$ = this.productImageService.retrieve(productImage, {
        ignoreStore: true,
      });
    } else {
      productImage$ = of(productImage);
    }
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: productImage$,
    }).pipe(
      map(({ currentUser, entity }) => {
        return new ObjectPermissionResponse(true);
      })
    );
  }

  canDelete(
    productImage: ProductImage | number
  ): Observable<ObjectPermissionResponse> {
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
    }).pipe(
      map(({ currentUser }) => {
        return new ObjectPermissionResponse(true);
      })
    );
  }
}
