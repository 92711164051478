<div class="container-lg" *ngIf="initialized">
  <div class="row">
    <div class="col-12">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#" [routerLink]="['/', ]">Home</a></li>
          <li class="breadcrumb-item">
            <a href="#" [routerLink]="['/', 'hi', 'builder-agreements']">NGBS Client Agreements</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Client Agreement for
            <app-company-display [company]="clientAgreement.company_info"></app-company-display>
          </li>
        </ol>
      </nav>
    </div>
  </div>

  <ng-container *ngIf="clientAgreement.extension_request_state === ClientAgreementExtensionRequestState.notSent; else extensionAgreementStateAlert">
    <ng-container *ngIf="clientAgreement.owner === currentUser.company || currentUser.is_superuser; else userStateAlert">
      <ng-container [ngSwitch]="clientAgreement.state">
        <ng-container *ngSwitchCase="ClientAgreementState.new">
          <div class="row">
            <div class="col-12">
              <i>
                Please review the enrollment information provided and contact the client directly to
                resolve any issues. Once reviewed, approve the enrollment by clicking "Approve
                client
                Enrollment".
              </i>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12 text-center">
              <button mat-raised-button color="accent" (click)="approve($event)">
                Approve Client Enrollment
              </button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="ClientAgreementState.approved">
          <div class="row">
            <div class="col-12">
              <i>
                If the legal agreement has been
                signed by
                the client, initiate counter-signing of the legal agreement by clicking "Route
                Agreement
                for Counter-Signing".
              </i>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12 text-center">
              <app-permission-mat-button [permissionResponse]="counterSignPermissionResponse" (action)="counterSign($event)">
                Route Agreement for Counter-Signing
              </app-permission-mat-button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="ClientAgreementState.verified">
          <div class="row">
            <div class="col-12">
              <i>
                Legal agreement has been routed internally for counter-signature. Please update the
                agreement and certificate of insurance start and expiration dates based on the
                documents
                provided by the client.
              </i>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="ClientAgreementState.countersigned">
          <div class="row">
            <div class="col-12">
              <i>
                Client enrollment has been completed. Legal agreement has been signed by both parties.
              </i>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12 text-center">
              <app-permission-mat-button class="mr-2" [permissionResponse]="initiateExtensionRequestPermissionResponse" [hideOnFalse]="true" (action)="initiateExtensionRequest($event)">
                Extend Client Agreement
              </app-permission-mat-button>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-template #userStateAlert>
      <ng-container [ngSwitch]="clientAgreement.state">
        <ng-container *ngSwitchCase="ClientAgreementState.new">
          <div class="row">
            <div class="col-12">
              <i>
                Your enrollment has been forwarded to NGBS Program Administration Staff for review
                and
                approval. The signing of the legal agreement
                is
                completed using DocuSign. Please check your email inbox for correspondence regarding
                the
                legal agreement.
              </i>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="ClientAgreementState.approved">
          <div class="row">
            <div class="col-12">
              <i>
                Your enrollment has been forwarded to NGBS Program Administration Staff for review
                and
                approval. Note that enrollment will only be completed once both signed legal
                agreement. The signing of the legal agreement
                is
                completed using DocuSign. Please check your email inbox for correspondence regarding
                the
                legal agreement. The certificate of insurance may be submitted to NGBS from this
                page
                (see instructions below).
              </i>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="ClientAgreementState.verified">
          <div class="row">
            <div class="col-12">
              <i>
                The NGBS Program Administration Staff has approved your enrollment. The legal
                agreement
                is being counter-signed and a copy of the fully executed legal agreement will be
                emailed
                to you and will also be available to you on the "Documents" tab below.
              </i>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="ClientAgreementState.countersigned">
          <div class="row">
            <div class="col-12">
              <i>
                Your enrollment has been completed. Copies of fully executed legal agreement are available on the
                "Documents" tab below. If
                you have
                any questions regarding your enrollment status, please contact NGBS <a
                  href="mailto:verificationreport@homeinnovation.com">verificationreport@homeinnovation.com</a>.
              </i>
            </div>
          </div>
          <div class="row mb-2" *ngIf="clientAgreement.state === ClientAgreementState.countersigned">
            <div class="col-12 text-center">
              <app-permission-mat-button class="mr-2" [permissionResponse]="initiateExtensionRequestPermissionResponse" [hideOnFalse]="true" (action)="initiateExtensionRequest($event)">
                Extend Client Agreement
              </app-permission-mat-button>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-template>
  </ng-container>
  <ng-template #extensionAgreementStateAlert>
    <ng-container *ngIf="clientAgreement.owner === currentUser.company || currentUser.is_superuser; else userExtensionRequestStateAlert">
      <ng-container [ngSwitch]="clientAgreement.extension_request_state">
        <ng-container *ngSwitchCase="ClientAgreementExtensionRequestState.initiated">
          <div class="row">
            <div class="col-12">
              <i>
               Waiting NGBS Approval
              </i>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12 text-center">
              <app-permission-mat-button [permissionResponse]="updateDocusignStatusPermissionResponse" (click)="extensionRequestApprove($event)" class="mr-2">
                Approve CA Extension
              </app-permission-mat-button>
              <app-permission-mat-button [permissionResponse]="updateDocusignStatusPermissionResponse" (click)="extensionRequestReject($event)">
                Reject CA Extension
              </app-permission-mat-button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="ClientAgreementExtensionRequestState.rejected">
          <div class="row">
            <div class="col-12">
              <i>
                Extension request rejected. Reason: <b>{{ clientAgreement.extension_request_reject_reason }}</b>
              </i>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="ClientAgreementExtensionRequestState.sentToClient">
          <div class="row">
            <div class="col-12">
              <i>
                Extension request agreement sent to client
              </i>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="ClientAgreementExtensionRequestState.sentForCounterSign">
          <div class="row">
            <div class="col-12">
              <i>
                Extension request agreement has been
                sent to NGBS
                for Counter-Signing
              </i>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="ClientAgreementExtensionRequestState.countersigned">
          <div class="row">
            <div class="col-12">
              <i>
                Extension request agreement has been completed and extended for 2 years
              </i>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-template #userExtensionRequestStateAlert>
      <ng-container [ngSwitch]="clientAgreement.extension_request_state">
        <ng-container *ngSwitchCase="ClientAgreementExtensionRequestState.initiated">
          <div class="row">
            <div class="col-12">
              <i>
                Extension waiting for NGBS Approval
              </i>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="ClientAgreementExtensionRequestState.rejected">
          <div class="row">
            <div class="col-12">
              <i>
                Extension request rejected. Reason: <b>{{ clientAgreement.extension_request_reject_reason }}</b>
              </i>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="ClientAgreementExtensionRequestState.sentToClient">
          <div class="row">
            <div class="col-12">
              <div class="alert alert-success" role="alert">
                Extension request agreement sent to client
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="ClientAgreementExtensionRequestState.sentForCounterSign">
          <div class="row">
            <div class="col-12">
              <i>
                Extension request agreement has been
                sent to NGBS
                for Counter-Signing
              </i>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="ClientAgreementExtensionRequestState.countersigned">
          <div class="row">
            <div class="col-12">
              <i>
                Extension request agreement has been completed and extended for 2 years
              </i>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-template>
  </ng-template>

  <div class="row mb-2 mt-2">
    <div class="col-12">
      <mat-divider></mat-divider>
    </div>
  </div>

  <div class="row">
    <div class="col-12 text-right">
      <button color="accent" mat-raised-button [routerLink]="initiateNewClientAgreementUrl" class="mr-2">
        Initiate New Client Agreement
      </button>
      <app-permission-mat-button matTooltip="Force State" class="mr-2" [permissionResponse]="updateDocusignStatusPermissionResponse" [hideOnFalse]="true" (action)="forceState($event)">
        Force State
      </app-permission-mat-button>
      <app-permission-mat-button matTooltip="Update Docusign Status" class="mr-2" [permissionResponse]="updateDocusignStatusPermissionResponse" [hideOnFalse]="true" (action)="updateDocusignStatus($event)">
        Update Status
      </app-permission-mat-button>
      <app-permission-mat-button matTooltip="Re-send email for current step recipient" class="mr-2" [permissionResponse]="reSendEmailPermissionResponse" [hideOnFalse]="true" (action)="reSendEmail($event)">
        Re-send email
      </app-permission-mat-button>
      <app-history-mat-button [objectId]="clientAgreement.id" modelClassName="client_agreement" class="mr-2">
        History
      </app-history-mat-button>
      <app-permission-mat-button matTooltip="Completely delete this agreement and all related information about it" class="mr-2" [permissionResponse]="deletePermissionResponse" [hideOnFalse]="true" (action)="delete($event)">
        Delete
      </app-permission-mat-button>
      <app-permission-mat-button [permissionResponse]="editPermissionResponse" [hideOnFalse]="true" (action)="edit($event)">
        Edit
      </app-permission-mat-button>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-12">
      <mat-divider></mat-divider>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-6">
      <div class="d-flex-column flex-fill">
        <div class="d-flex flex-row">
          <div class="ca-attr-name">
            Company Name:
          </div>
          <div class="flex-fill">
            <app-company-display [company]="clientAgreement.company_info" displayType="onlyNameAsLink"></app-company-display>
          </div>
        </div>
        <div class="d-flex flex-row" *ngIf="clientAgreement.signed_agreement && (clientAgreement.state === ClientAgreementState.countersigned || clientAgreement.state === ClientAgreementState.expired)">
          <div class="ca-attr-name">
            Signed Agreement:
          </div>
          <div class="flex-fill">
            <app-customer-document-display [customerDocument]="clientAgreement.signed_agreement_info" displayType="filenameAsLinkAndSharedLink"></app-customer-document-display>
          </div>
        </div>
        <div class="d-flex flex-row" *ngIf="clientAgreement.extension_request_signed_agreement && clientAgreement.extension_request_state === ClientAgreementExtensionRequestState.countersigned">
          <div class="ca-attr-name">
            Extension Request:
          </div>
          <div class="flex-fill">
            <app-customer-document-display [customerDocument]="clientAgreement.extension_request_signed_agreement_info" displayType="filenameAsLinkAndSharedLink"></app-customer-document-display>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="d-flex-column flex-fill">
        <div class="d-flex flex-row">
          <div class="ca-attr-name">
            Enrollment Status:
          </div>
          <div class="flex-fill">
            {{ clientAgreementStateLabelMapping[clientAgreement.state] }} <span *ngIf="clientAgreement.extension_request_certifying_document && clientAgreement.extension_request_state === ClientAgreementExtensionRequestState.countersigned">(Extended)</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-2 mb-2">
    <div class="col-12">
      <mat-divider></mat-divider>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="d-flex-column flex-fill">
        <h4>Agreement: </h4>
        <div class="d-flex flex-row">
          <div class="ca-attr-name">
            Start Date:
          </div>
          <div class="flex-fill">
            {{ clientAgreement.agreement_start_date | date: 'MM/dd/yyyy' }}
          </div>
        </div>
        <div class="d-flex flex-row">
          <div class="ca-attr-name">
            Expiration Date:
          </div>
          <div class="flex-fill">
            {{ clientAgreement.agreement_expiration_date | date: 'MM/dd/yyyy' }}
          </div>
        </div>
        <div class="d-flex flex-row">
          <div class="ca-attr-name">
            Version:
          </div>
          <div class="flex-fill">
            {{ clientAgreement.ca_version_to_sign }}
          </div>
        </div>
        <div class="d-flex flex-row">
          <div class="ca-attr-name">
            Initiator:
          </div>
          <div class="flex-fill">
            <ng-container [ngTemplateOutlet]="clientAgreement.initiator ? initiator_info : clientAgreement.created_by ? created_by_info : company_info"></ng-container>
            <ng-template #initiator_info>
              <app-user-display [user]="clientAgreement.initiator_info" displayType="onlyNameAsLink"></app-user-display>
            </ng-template>
            <ng-template #created_by_info>
              <app-user-display [user]="clientAgreement.created_by_info" displayType="onlyNameAsLink"></app-user-display>
            </ng-template>
            <ng-template #company_info>
              <app-company-display [company]="clientAgreement.company_info" *ngIf="clientAgreement.company" displayType="onlyNameAsLink"></app-company-display>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <h4>Certificate Of Insurance:</h4>
      <app-hirl-client-agreement-coi-list></app-hirl-client-agreement-coi-list>
    </div>
  </div>

  <mat-tab-group color="accent" style="min-height: 450px;" disableRipple="true" animationDuration="0ms"
                 dynamicHeight (selectedTabChange)="onTabChange($event)" [selectedIndex]="selectedIndex" class="mt-4">
    <mat-tab>
      <ng-template mat-tab-label>
        Contact Information
      </ng-template>
      <div class="mt-2 mb-5">
        <div class="row">
          <div class="col-12">
            <h3 class="contact-info-main-title">Mailing Information:</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="d-flex-column flex-fill">
              <h4 class="contact-info-sub-title">Mailing Address:</h4>
              <div class="d-flex flex-row">
                <div class="d-flex flex-row flex-fill">
                  <app-address-display [geocode]="clientAgreement.mailing_geocode_info"
                                       [geocodeBrokeredResponse]="clientAgreement.mailing_geocode_response_info"></app-address-display>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="d-flex-column flex-fill">
              <h4 class="contact-info-sub-title">Shipping/Physical Address:</h4>
              <div class="d-flex flex-row">
                <div class="d-flex flex-row flex-fill">
                  <app-address-display [geocode]="clientAgreement.shipping_geocode_info"
                                       [geocodeBrokeredResponse]="clientAgreement.shipping_geocode_response_info"
                                       *ngIf="clientAgreement.shipping_geocode_info"></app-address-display>
                  <app-address-display [geocode]="clientAgreement.mailing_geocode_info"
                                       [geocodeBrokeredResponse]="clientAgreement.mailing_geocode_response_info"
                                       *ngIf="!clientAgreement.shipping_geocode_info"></app-address-display>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3 mb-3">
          <div class="col-12">
            <mat-divider></mat-divider>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <h3 class="contact-info-main-title">Contact Information:</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="d-flex-column flex-fill">
              <h4 class="contact-info-sub-title">Primary Contact:</h4>
              <div class="d-flex flex-row">
                <div class="ca-attr-name">
                  First Name:
                </div>
                <div class="flex-fill">
                  {{ clientAgreement.primary_contact_first_name || '-' }}
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="ca-attr-name">
                  Last Name:
                </div>
                <div class="flex-fill">
                  {{ clientAgreement.primary_contact_last_name || '-' }}
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="ca-attr-name">
                  Title:
                </div>
                <div class="flex-fill">
                  {{ clientAgreement.primary_contact_title || '-' }}
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="ca-attr-name">
                  Email Address:
                </div>
                <div class="flex-fill">
                  {{ clientAgreement.primary_contact_email_address || '-' }}
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="ca-attr-name">
                  Phone Number:
                </div>
                <div class="flex-fill">
                  {{ clientAgreement.primary_contact_phone_number || '-' }}
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="ca-attr-name">
                  Cell Number:
                </div>
                <div class="flex-fill">
                  {{ clientAgreement.primary_contact_cell_number || '-' }}
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="ca-attr-name">
                  Website:
                </div>
                <div class="flex-fill">
                  {{ clientAgreement.website || '-' }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="d-flex-column flex-fill">
              <h4 class="contact-info-sub-title">Secondary Contact:</h4>
              <div class="d-flex flex-row">
                <div class="ca-attr-name">
                  First Name:
                </div>
                <div class="flex-fill">
                  {{ clientAgreement.secondary_contact_first_name || '-' }}
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="ca-attr-name">
                  Last Name:
                </div>
                <div class="flex-fill">
                  {{ clientAgreement.secondary_contact_last_name || '-' }}
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="ca-attr-name">
                  Title:
                </div>
                <div class="flex-fill">
                  {{ clientAgreement.secondary_contact_title || '-' }}
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="ca-attr-name">
                  Email Address:
                </div>
                <div class="flex-fill">
                  {{ clientAgreement.secondary_contact_email_address || '-' }}
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="ca-attr-name">
                  Phone Number:
                </div>
                <div class="flex-fill">
                  {{ clientAgreement.secondary_contact_phone_number || '-' }}
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="ca-attr-name">
                  Cell Number:
                </div>
                <div class="flex-fill">
                  {{ clientAgreement.secondary_contact_cell_number || '-' }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3 mb-3">
          <div class="col-12">
            <mat-divider></mat-divider>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="d-flex flex-fill">
              <h4 class="contact-info-sub-title">Person Responsible for Payment Contact:</h4>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="d-flex-column flex-fill">
              <div class="d-flex flex-row">
                <div class="ca-attr-name">
                  First Name:
                </div>
                <div class="flex-fill">
                  {{ clientAgreement.payment_contact_first_name || '-' }}
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="ca-attr-name">
                  Last Name:
                </div>
                <div class="flex-fill">
                  {{ clientAgreement.payment_contact_last_name || '-' }}
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="ca-attr-name">
                  Title:
                </div>
                <div class="flex-fill">
                  {{ clientAgreement.payment_contact_title || '-' }}
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="ca-attr-name">
                  Email Address:
                </div>
                <div class="flex-fill">
                  {{ clientAgreement.payment_contact_email_address || '-' }}
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="ca-attr-name">
                  Phone Number:
                </div>
                <div class="flex-fill">
                  {{ clientAgreement.payment_contact_phone_number || '-' }}
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="ca-attr-name">
                  Cell Number:
                </div>
                <div class="flex-fill">
                  {{ clientAgreement.payment_contact_cell_number || '-' }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="d-flex-column flex-fill">
              <div class="d-flex flex-row">
                <div class="d-flex flex-row">
                  <div class="ca-payment-contact-attr-name">
                    Use Payment Contact for future NGBS Green projects?:
                  </div>
                  <div class="flex-fill">
                    {{ clientAgreement.use_payment_contact_in_ngbs_green_projects | yesNo }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-2 mb-2">
          <div class="col-12">
            <mat-divider></mat-divider>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="d-flex-column flex-fill">
              <h4 class="contact-info-sub-title">Marketing Contact:</h4>
              <div class="d-flex flex-row">
                <div class="ca-attr-name">
                  First Name:
                </div>
                <div class="flex-fill">
                  {{ clientAgreement.marketing_contact_first_name || '-' }}
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="ca-attr-name">
                  Last Name:
                </div>
                <div class="flex-fill">
                  {{ clientAgreement.marketing_contact_last_name || '-' }}
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="ca-attr-name">
                  Title:
                </div>
                <div class="flex-fill">
                  {{ clientAgreement.marketing_contact_title || '-' }}
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="ca-attr-name">
                  Email Address:
                </div>
                <div class="flex-fill">
                  {{ clientAgreement.marketing_contact_email_address || '-' }}
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="ca-attr-name">
                  Phone Number:
                </div>
                <div class="flex-fill">
                  {{ clientAgreement.marketing_contact_phone_number || '-' }}
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="ca-attr-name">
                  Cell Number:
                </div>
                <div class="flex-fill">
                  {{ clientAgreement.marketing_contact_cell_number || '-' }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="d-flex-column flex-fill">
              <h4 class="contact-info-sub-title">Website Contact:</h4>
              <div class="d-flex flex-row">
                <div class="ca-attr-name">
                  First Name:
                </div>
                <div class="flex-fill">
                  {{ clientAgreement.website_contact_first_name || '-' }}
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="ca-attr-name">
                  Last Name:
                </div>
                <div class="flex-fill">
                  {{ clientAgreement.website_contact_last_name || '-' }}
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="ca-attr-name">
                  Title:
                </div>
                <div class="flex-fill">
                  {{ clientAgreement.website_contact_title || '-' }}
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="ca-attr-name">
                  Email Address:
                </div>
                <div class="flex-fill">
                  {{ clientAgreement.website_contact_email_address || '-' }}
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="ca-attr-name">
                  Phone Number:
                </div>
                <div class="flex-fill">
                  {{ clientAgreement.website_contact_phone_number || '-' }}
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="ca-attr-name">
                  Cell Number:
                </div>
                <div class="flex-fill">
                  {{ clientAgreement.website_contact_cell_number || '-' }}
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        Documents
        <fa-icon [icon]="['fas', 'spinner']" [spin]="true" class="ml-1"
                 *ngIf="clientAgreementDocumentsCount === -1"></fa-icon>
        <span class="badge badge-primary ml-1" *ngIf="clientAgreementDocumentsCount !== -1">
                            {{ clientAgreementDocumentsCount }}
                        </span>
      </ng-template>
      <div class="mt-2 mb-5">
        <app-customer-document-tab [modelClassName]="'client_agreement'" [objectId]="clientAgreement.id" [canCreate]="false"></app-customer-document-tab>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        Notes
        <fa-icon [icon]="['fas', 'spinner']" [spin]="true" class="ml-1"
                 *ngIf="clientAgreementNotesCount === -1"></fa-icon>
        <span class="badge badge-primary ml-1" *ngIf="clientAgreementNotesCount !== -1">
                            {{ clientAgreementNotesCount }}
                        </span>
      </ng-template>
      <div class="mt-2 mb-5">
        <app-annotation-tab [annotationTypeSlug]="'note'" [modelClassName]="'client_agreement'" [objectId]="clientAgreement.id"></app-annotation-tab>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        Docusign Information
      </ng-template>
      <div class="mt-2 mb-5">
        <div class="row">
          <div class="col-12">
            <div class="d-flex flex-row">
              <div class="ca-attr-name">
                Envelope ID:
              </div>
              <div class="flex-fill">
                {{ clientAgreement.docusign_data?.envelope_id || '-' }}
              </div>
            </div>
            <div class="d-flex flex-row">
              <div class="ca-attr-name">
                Status:
              </div>
              <div class="flex-fill">
                {{ clientAgreement.docusign_data?.status || '-' }}
              </div>
            </div>
            <div class="d-flex flex-row">
              <div class="ca-attr-name">
                Status Message:
              </div>
              <div class="flex-fill">
                {{ clientAgreement.docusign_data?.status_message || '-' }}
              </div>
            </div>
            <div class="d-flex flex-row">
              <div class="ca-attr-name">
                Recipient Email:
              </div>
              <div class="flex-fill">
                {{ clientAgreement.docusign_data?.recipient_email || '-' }}
              </div>
            </div>
            <div class="d-flex flex-row">
              <div class="ca-attr-name">
                Delivered Time:
              </div>
              <div class="flex-fill">
                {{ clientAgreement.docusign_data?.delivered_time | date: 'MM/dd/yyyy HH:MM' || '-' }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>

  <div class="row">
    <div class="col-12">
      <h3>Preview of Legal Agreement (electronic signature will be required via DocuSign):</h3>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12" [ngSwitch]="clientAgreement.ca_version_to_sign">
      <iframe style="width: 100%; height: 25rem"
              src="assets/pages/hirl-client-agreement-detail/builder_embedded_agreement_v4.pdf" *ngSwitchDefault></iframe>
      <iframe style="width: 100%; height: 25rem"
              src="assets/pages/hirl-client-agreement-detail/builder_embedded_agreement_v3.pdf" *ngSwitchCase="3"></iframe>
      <iframe style="width: 100%; height: 25rem"
              src="assets/pages/hirl-client-agreement-detail/builder_embedded_agreement_v2.pdf" *ngSwitchCase="2"></iframe>
      <iframe style="width: 100%; height: 25rem"
              src="assets/pages/hirl-client-agreement-detail/builder_embedded_agreement.pdf"
              *ngSwitchCase="1"></iframe>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      By clicking "Submit for Review and Approval by NGBS" you are acknowledging that your
      company agrees to the legal terms and conditions as stated above. Once you click
      “Submit for Review and Approval by NGBS”, a legal agreement will be emailed to the
      Primary Contact specified above for signature. This legal agreement must be signed,
      dated, and returned prior to participation in an NGBS program. If you do not agree to
      the terms and conditions as stated above, or if you have any questions prior to
      proceeding,
      you may contact the NGBS Program Staff by emailing
      <a href="mailto:verificationreport@homeinnovation.com">verificationreport@homeinnovation.com</a>.
    </div>
  </div>
  <div class="row">
    <div class="col-md-12" [ngSwitch]="clientAgreement.ca_version_to_sign">
      <a href="assets/pages/hirl-client-agreement-detail/builder_embedded_agreement.pdf" *ngSwitchCase="1">Click here to download the
        sample PDF.</a>
      <a href="assets/pages/hirl-client-agreement-detail/builder_embedded_agreement_v2.pdf" *ngSwitchCase="2">Click here to download the
        sample PDF.</a>
      <a href="assets/pages/hirl-client-agreement-detail/builder_embedded_agreement_v3.pdf" *ngSwitchCase="3">Click here to download the
        sample PDF.</a>
      <a href="assets/pages/hirl-client-agreement-detail/builder_embedded_agreement_v4.pdf" *ngSwitchDefault>Click here to download the
        sample PDF.</a>
    </div>
  </div>
</div>
