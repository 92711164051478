import { ListRequestParams } from '@/core/schemes/request-params';
import { Injectable } from '@angular/core';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { Annotation } from '@/data/annotation/models';

export class AnnotationRequestParams extends ListRequestParams {
  type__slug?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AnnotationService extends BaseDataService {
  baseUrl = `${environment.AXIS_API_V3_ROOT}/annotations/`;

  retrieve(
    annotationId: number,
    options?: IDataServiceMethodOptions
  ): Observable<Annotation> {
    const ignoreStore: boolean = options?.ignoreStore;
    const chain: Observable<Annotation> = this.http.get<Annotation>(
      `${this.baseUrl}${annotationId}`,
      {}
    );
    return chain;
  }

  update(
    annotationId: number,
    annotation: Annotation | FormData,
    options?: IDataServiceMethodOptions
  ): Observable<Annotation> {
    const ignoreStore = options?.ignoreStore;
    const chain = this.http.patch<Annotation>(
      `${this.baseUrl}${annotationId}/`,
      annotation
    );

    return chain;
  }

  delete(annotationId: number) {
    return this.http.delete(`${this.baseUrl}${annotationId}`);
  }

  createSimulationRelatedAnnotation(
    modelBackendName: string,
    modelId: number,
    annotation: Partial<Annotation>
  ) {
    if (modelBackendName === 'simulation') {
      modelBackendName = 'simulations';
    }
    return this.http.post<Annotation>(
      `${environment.AXIS_API_V3_ROOT}/${modelBackendName}/${modelId}/annotations/`,
      annotation
    );
  }

  listSimulationRelatedAnnotations(simulationId: number) {
    return this.http.get<Annotation[]>(
      `${environment.AXIS_API_V3_ROOT}/simulations/${simulationId}/list_annotations/`
    );
  }

  updateSimulationRelatedAnnotation(
    modelBackendName: string,
    modelId: number,
    annotationId: number,
    annotation: Partial<Annotation>
  ) {
    if (modelBackendName === 'simulation') {
      modelBackendName = 'simulations';
    }
    return this.http.patch<Annotation>(
      `${environment.AXIS_API_V3_ROOT}/${modelBackendName}/${modelId}/annotations/${annotationId}/`,
      annotation
    );
  }

  deleteSimulationRelatedAnnotation(
    modelBackendName: string,
    modelId: number,
    annotationId: number
  ) {
    if (modelBackendName === 'simulation') {
      modelBackendName = 'simulations';
    }
    return this.http.delete(
      `${environment.AXIS_API_V3_ROOT}/${modelBackendName}/${modelId}/annotations/${annotationId}/`
    );
  }
}
