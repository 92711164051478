<ng-container *ngIf="floorplan">
    <ng-container [ngSwitch]="displayType">
        <ng-container *ngSwitchCase="floorplanDisplayComponentType.onlyName">
            <span *ngIf="floorplan.name; else dash">
                {{ floorplan?.name }}
            </span>
            <ng-template #dash ngx-datatable-cell-template>
                <a [routerLink]="['/', 'floorplan', floorplan.id]"><strong>--</strong></a>
            </ng-template>
        </ng-container>
        <a [routerLink]="['/', 'floorplans', floorplan?.id]" *ngSwitchCase="floorplanDisplayComponentType.onlyNameAsLink">
            <span *ngIf="floorplan.name; else dash">
                {{ floorplan?.name }}
            </span>
            <ng-template #dash ngx-datatable-cell-template>
                <a [routerLink]="['/', 'floorplan', floorplan.id]"><strong>--</strong></a>
            </ng-template>
        </a>
        <a [routerLink]="['/', 'floorplans', floorplan?.id]" target="_blank" *ngSwitchCase="floorplanDisplayComponentType.onlyNameAsLinkNewWindow">
            <span *ngIf="floorplan.name; else dash">
                {{ floorplan?.name }}
            </span>
            <ng-template #dash ngx-datatable-cell-template>
                <a [routerLink]="['/', 'floorplan', floorplan.id]"><strong>--</strong></a>
            </ng-template>
        </a>
        <ng-container *ngSwitchCase="floorplanDisplayComponentType.nameAndType">{{ floorplan?.name }} [{{ SimulationSourceTypeLabelMapping[floorplan?.simulation_info?.source_type] }}]</ng-container>
        <a [routerLink]="['/', 'floorplans', 'detail', floorplan?.id]" *ngSwitchCase="floorplanDisplayComponentType.nameAndTypeAsLink">
            {{ floorplan?.name }} [{{ SimulationSourceTypeLabelMapping[floorplan?.simulation_info?.source_type] }}]
        </a>
    </ng-container>
</ng-container>
