import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ClientAgreementChangeDialogData,
  HirlClientAgreementChangeDialogComponent,
} from '@/modules/customer-hirl/components/hirl-client-agreement-change-dialog/hirl-client-agreement-change-dialog.component';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ClientAgreementService } from '@/data/customer-hirl/services/client-agreement.service';
import { HIRLClientAgreementPermissionService } from '@/modules/customer-hirl/services/client-agreement-permission.service';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { forkJoin, Subject } from 'rxjs';
import { resetClientAgreement } from '@/state/actions/customer-hirl/client-agreement.actions';
import { first } from 'rxjs/operators';
import { getInfoUser } from '@/state/selectors/info.selector';
import { toggleLoading } from '@/state/actions/app.actions';
import { ClientAgreement } from '@/data/customer-hirl/models';

@Component({
  selector: 'app-hirl-client-agreement-create',
  templateUrl: './hirl-client-agreement-create.component.html',
  styleUrls: ['./hirl-client-agreement-create.component.scss'],
})
export class HirlClientAgreementCreateComponent implements OnInit, OnDestroy {
  private componentDestroyed$ = new Subject();
  constructor(
    private store: Store<AppState>,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private clientAgreementService: ClientAgreementService,
    private clientAgreementPermissionService: HIRLClientAgreementPermissionService,
    private uiHelperService: UIHelperService
  ) {}

  ngOnInit(): void {
    forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
    }).subscribe(
      ({ currentUser }) => {
        this.store.dispatch(toggleLoading({ payload: false }));
        const dialogRef = this.dialog.open(
          HirlClientAgreementChangeDialogComponent,
          {
            width: '55%',
            disableClose: true,
            autoFocus: false,
            data: {
              clientAgreement: {
                company: currentUser.company,
              },
            } as ClientAgreementChangeDialogData,
          }
        );

        dialogRef.afterClosed().subscribe((result?: ClientAgreement) => {
          if (!result) {
            this.router.navigate(['/', 'hi', 'builder-agreements']);
            return;
          } else {
            this.router.navigate([
              '/',
              'hi',
              'client_agreements',
              'detail',
              result.id,
            ]);
          }
        });
      },
      error => this.uiHelperService.handleMainRequestError(error)
    );
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
    this.store.dispatch(resetClientAgreement());
  }
}
