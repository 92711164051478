import { selectSlabErrorViewSet } from '@/modules/simulation/state/slab/selectors';
import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-slab-errors',
  templateUrl: './slab-errors.component.html',
  styleUrls: ['./slab-errors.component.scss'],
})
export class SlabErrorsComponent {
  @Input() entityId: number;
  componentDestroyed$: Subject<boolean> = new Subject();
  enable = false;
  name: string;
  slabTypeId: number;
  totalErrorCount = 0;
  totalWarningCount = 0;
  slabTypeErrorCount = 0;
  slabTypeWarningCount = 0;

  constructor(public store: Store) {}

  ngOnInit(): void {
    this.enable = true;

    this.store
      .select(selectSlabErrorViewSet(this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.name = data.name;
        this.slabTypeId = data.slabTypeId;

        const nestedModelsErrorCount = data.nestedModelsErrorCount;
        this.slabTypeErrorCount = nestedModelsErrorCount.slabType.errorCount;
        this.slabTypeWarningCount =
          nestedModelsErrorCount.slabType.warningCount;

        this.totalErrorCount = 0;
        this.totalErrorCount += this.slabTypeErrorCount;
        this.totalErrorCount += nestedModelsErrorCount.slab.errorCount;

        this.totalWarningCount = 0;
        this.totalWarningCount += this.slabTypeWarningCount;
        this.totalWarningCount += nestedModelsErrorCount.slab.warningCount;
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
