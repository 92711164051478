import { Component } from '@angular/core';
import { first } from 'rxjs/operators';
import {
  EEPProgramHomeStatusProjectReportDownload,
  EEPProgramHomeStatusProjectReportExportField,
  ProgramEligibilityList,
} from '@/data/home/models';
import { toggleLoading } from '@/state/actions/app.actions';
import { PsrListComponent } from '@/modules/home/pages/psr-list/psr-list.component';
import { EEPProgramHomeStatusRequestParams } from '@/data/home/services/eep_program_home_status.service';

@Component({
  selector: 'app-program-eligibility-dashboard',
  templateUrl: './program-eligibility-dashboard.component.html',
  styleUrls: ['./program-eligibility-dashboard.component.scss'],
})
export class ProgramEligibilityDashboardComponent extends PsrListComponent<ProgramEligibilityList> {
  public displayedColumns = [
    'home__street_line1',
    'home__subdivision__name',
    'floorplan__name',
    'eep_program__name',
    'state',
    'energy_star_32',
    'doe_zerh',
    'mbs',
  ];

  public getDataSource = (params: EEPProgramHomeStatusRequestParams) =>
    this.eepProgramHomeStatusService.program_eligibility_list(params);

  downloadReport($event: MouseEvent) {
    $event.preventDefault();
    this.store.dispatch(toggleLoading({ payload: true }));

    const projectReportDownload =
      new EEPProgramHomeStatusProjectReportDownload();
    projectReportDownload.export_fields = [
      EEPProgramHomeStatusProjectReportExportField.home,
      EEPProgramHomeStatusProjectReportExportField.subdivision,
      EEPProgramHomeStatusProjectReportExportField.eep_program,
      EEPProgramHomeStatusProjectReportExportField.simulation_basic,
      EEPProgramHomeStatusProjectReportExportField.simulation_advanced,
      EEPProgramHomeStatusProjectReportExportField.retain_empty,
    ];
    projectReportDownload.export_filters =
      this.filterComponent.getAppliedFilters();
    const newTab = window.open('', '_blank');
    this.eepProgramHomeStatusService
      .program_eligibility_report_download(
        projectReportDownload,
        this.storedParams
      )
      .pipe(first())
      .subscribe(
        asynchronousProcessedDocument => {
          newTab.location.href = `/file-operation/document/${asynchronousProcessedDocument.id}`;
          newTab.focus();
          this.store.dispatch(toggleLoading({ payload: false }));
        },
        error => {
          newTab.close();
          this.store.dispatch(toggleLoading({ payload: false }));
          this.uiHelperService.handleUserRequestError(error);
        }
      );
  }
}
