<div class="d-flex flex-column">
    <h6>Materials info</h6>
    <div class="d-flex flex-column">
        <div *ngFor="let materialForm of materialsForm.controls; let i=index; let f=first" class="items-container">
            <div class="content">
                <app-assembly-layer-material
                    [entityForm]="materialForm"
                    [showLabel]="f">
                </app-assembly-layer-material>
            </div>
            <div class="action" *ngIf="materialsForm.length > 1">
                <button mat-icon-button *appRequiresEditPermission (click)="removeMaterial(i)">
                    <mat-icon [inline]="true">close</mat-icon>
                </button>
            </div>
        </div>
        <button *appRequiresEditPermission mat-button (click)="addMaterial()">
            Add material<mat-icon>add_circle</mat-icon>
        </button>
    </div>
</div>
