import { Component, OnInit } from '@angular/core';
import {
  STATE_NAME_TO_EQUIPMENT_TYPE,
  StateModelName,
  StateModelNameLabels,
} from '../../state.registry';
import { LibraryService } from '@/data/simulation/services/library.service';
import { FoundationWallTypeChangeDialogComponent } from '../enclosure/foundation-wall-type-change-dialog/foundation-wall-type-change-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { EDIT_MODE } from '@/core/constants';
import { loadFoundationWallTypes } from '../../state/foundation-wall-type/actions';
import { Store } from '@ngrx/store';
import { DIALOG_WIDTH } from '../../constants';
import { first, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { PageEvent } from '@angular/material/paginator';
import { ListRequestParams } from '@/core/schemes/request-params';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { AboveGradeWallTypeChangeDialogComponent } from '../enclosure/above-grade-wall-type-change-dialog/above-grade-wall-type-change-dialog.component';
import { RoofTypeChangeDialogComponent } from '../enclosure/roof-type-change-dialog/roof-type-change-dialog.component';
import { FrameFloorTypeChangeDialogComponent } from '../enclosure/frame-floor-type-change-dialog/frame-floor-type-change-dialog.component';
import { SlabTypeChangeDialogComponent } from '../enclosure/slab-type-change-dialog/slab-type-change-dialog.component';
import { RimJoistTypeChangeDialogComponent } from '../enclosure/rim-joist-type-change-dialog/rim-joist-type-change-dialog.component';
import { DoorTypeChangeDialogComponent } from '../enclosure/door-type-change-dialog/door-type-change-dialog.component';
import { WindowTypeChangeDialogComponent } from '../enclosure/window-type-change-dialog/window-type-change-dialog.component';
import { SkylightTypeChangeDialogComponent } from '../enclosure/skylight-type-change-dialog/skylight-type-change-dialog.component';
import { loadDetailedAboveGradeWallTypes } from '../../state/above-grade-wall-type/actions';
import { loadDetailedRoofTypes } from '../../state/roof-type/actions';
import { loadDetailedFrameFloorTypes } from '../../state/frame-floor-type/actions';
import { loadSlabTypes } from '../../state/slab-type/actions';
import { loadRimJoistTypes } from '../../state/rim-joist-type/actions';
import { loadDetailedDoorTypes } from '../../state/door-type/actions';
import { loadWindowTypes } from '../../state/window-type/actions';
import { loadSkylightTypes } from '../../state/skylight-type/actions';
import { EquipmentDetailsChangeDialogComponent } from '../mechanicals/equipment-details-change-dialog/equipment-details-change-dialog.component';
import { loadAirConditioners } from '../../state/air-conditioner/actions';
import { loadAirSourceHeatPumps } from '../../state/air-source-heat-pump/actions';
import { loadDehumidifiers } from '../../state/dehumidifier/actions';
import { loadGroundSourceHeatPumps } from '../../state/ground-source-heat-pump/actions';
import { loadHeaters } from '../../state/heater/actions';
import { loadWaterHeaters } from '../../state/water-heater/actions';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { SimulationRightsService } from '../../services/simulation-rights.service';

@Component({
  selector: 'app-company-library-elements',
  templateUrl: './company-library-elements.component.html',
  styleUrls: ['./company-library-elements.component.scss'],
})
export class CompanyLibraryElementsComponent implements OnInit {
  private componentDestroyed$ = new Subject();

  statenameToComponent = {
    [StateModelName.aboveGradeWallType]:
      AboveGradeWallTypeChangeDialogComponent,
    [StateModelName.foundationWallType]:
      FoundationWallTypeChangeDialogComponent,
    [StateModelName.roofType]: RoofTypeChangeDialogComponent,
    [StateModelName.frameFloorType]: FrameFloorTypeChangeDialogComponent,
    [StateModelName.slabType]: SlabTypeChangeDialogComponent,
    [StateModelName.rimJoistType]: RimJoistTypeChangeDialogComponent,

    [StateModelName.doorType]: DoorTypeChangeDialogComponent,
    [StateModelName.windowType]: WindowTypeChangeDialogComponent,
    [StateModelName.skylightType]: SkylightTypeChangeDialogComponent,

    [StateModelName.airConditioner]: EquipmentDetailsChangeDialogComponent,
    [StateModelName.airSourceHeatPump]: EquipmentDetailsChangeDialogComponent,
    [StateModelName.dehumidifier]: EquipmentDetailsChangeDialogComponent,
    [StateModelName.groundSourceHeatPump]:
      EquipmentDetailsChangeDialogComponent,
    [StateModelName.heater]: EquipmentDetailsChangeDialogComponent,
    [StateModelName.waterHeater]: EquipmentDetailsChangeDialogComponent,
  };

  stateNameToPopulateReducer = {
    [StateModelName.aboveGradeWallType]: [
      loadDetailedAboveGradeWallTypes,
      'wallTypes',
    ],
    [StateModelName.foundationWallType]: [loadFoundationWallTypes, 'wallTypes'],
    [StateModelName.roofType]: [loadDetailedRoofTypes, 'roofTypes'],
    [StateModelName.frameFloorType]: [
      loadDetailedFrameFloorTypes,
      'floorTypes',
    ],
    [StateModelName.slabType]: [loadSlabTypes, 'slabTypes'],
    [StateModelName.rimJoistType]: [loadRimJoistTypes, 'rimJoistTypes'],

    [StateModelName.doorType]: [loadDetailedDoorTypes, 'doorTypes'],
    [StateModelName.windowType]: [loadWindowTypes, 'windowTypes'],
    [StateModelName.skylightType]: [loadSkylightTypes, 'skylightTypes'],

    [StateModelName.airConditioner]: [loadAirConditioners, 'airConditioners'],
    [StateModelName.airSourceHeatPump]: [
      loadAirSourceHeatPumps,
      'airSourceHeatPumps',
    ],
    [StateModelName.dehumidifier]: [loadDehumidifiers, 'dehumidifiers'],
    [StateModelName.groundSourceHeatPump]: [
      loadGroundSourceHeatPumps,
      'groundSourceHeatPumps',
    ],
    [StateModelName.heater]: [loadHeaters, 'heaters'],
    [StateModelName.waterHeater]: [loadWaterHeaters, 'waterHeaters'],
  };
  public storedParams: ListRequestParams = Object.assign(
    new ListRequestParams(),
    { page_size: 15, page: 1 }
  );
  stateModelNameLabels = StateModelNameLabels;
  items = [];
  TABS = ['Envelope', 'Fenestration', 'Equipments'];
  entitiesHavingLibrary: Record<string, StateModelName[]> = {
    Envelope: [
      StateModelName.aboveGradeWallType,
      StateModelName.foundationWallType,
      StateModelName.roofType,
      StateModelName.frameFloorType,
      StateModelName.slabType,
      StateModelName.rimJoistType,
    ],
    Fenestration: [
      StateModelName.doorType,
      StateModelName.windowType,
      StateModelName.skylightType,
    ],
    Equipments: [
      StateModelName.airConditioner,
      StateModelName.airSourceHeatPump,
      StateModelName.dehumidifier,
      StateModelName.groundSourceHeatPump,
      StateModelName.heater,
      StateModelName.waterHeater,
    ],
  };
  activeItem: StateModelName;
  rowsCount: number;
  loading: boolean;
  listSubscription$: any;
  addingNewItemInProgress = false;
  activeItemIndex = -1;

  constructor(
    private libraryService: LibraryService,
    private dialog: MatDialog,
    private store: Store,
    private uiHelperService: UIHelperService,
    private simulationRightsService: SimulationRightsService
  ) {}

  ngOnInit(): void {
    this.activeItem = StateModelName.foundationWallType;
    this.onClick(this.activeItem);
    this.simulationRightsService.setEditingAllowed(true);
  }

  edit(data: any) {
    const action = this.stateNameToPopulateReducer[this.activeItem][0];
    const datakey = this.stateNameToPopulateReducer[this.activeItem][1];
    this.store.dispatch(action({ [datakey]: [data] }));

    const component = this.statenameToComponent[this.activeItem];
    const dialogRef = this.dialog.open(component, {
      width: DIALOG_WIDTH,
      data: {
        mode: EDIT_MODE,
        entityId: data.id,
        equipmentType: STATE_NAME_TO_EQUIPMENT_TYPE[this.activeItem],
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.onClick(this.activeItem);
    });
  }

  onClick(item: StateModelName) {
    this.activeItem = item;

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }

    this.listSubscription$ = this.libraryService
      .list(item, this.storedParams)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(data => {
        this.items = data.results;
        this.rowsCount = data.count;
        this.activeItemIndex = -1;
      });
  }

  onAdd() {
    this.addingNewItemInProgress = true;
    const name = 'New ' + this.stateModelNameLabels[this.activeItem];
    this.libraryService.create(this.activeItem, name).subscribe(
      data => {
        this.onClick(this.activeItem);
        this.edit(data);
        this.addingNewItemInProgress = false;
      },
      error => {
        this.addingNewItemInProgress = false;
        this.uiHelperService.handleUserRequestError(error);
      }
    );
  }

  onDelete(item, activeItemIndex) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: DIALOG_WIDTH,
      data: {
        title: 'Confirmation required',
        content: `Do you want to delete this "${item.name}"?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (result) {
          this.activeItemIndex = activeItemIndex;
          this.libraryService.delete(this.activeItem, item.id).subscribe(
            () => {
              this.onClick(this.activeItem);
            },
            error => {
              this.uiHelperService.handleUserRequestError(error);
            }
          );
        }
      });
  }

  onPaginateChange($event: PageEvent) {
    this.storedParams.page_size = 15;
    this.storedParams.page = $event.pageIndex + 1;
    this.onClick(this.activeItem);
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
