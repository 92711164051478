import {
  EquipmentType,
  EquipmentTypeLabels,
} from '@/data/simulation/models/mechanicals/MechanicalEquipment';
import {
  EquipmentFieldNameToStateName,
  selectMechanicalEquipmentErrorViewSet,
} from '@/modules/simulation/state/mechanical-equipment/selectors';
import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-mechanical-equipment-errors',
  templateUrl: './mechanical-equipment-errors.component.html',
  styleUrls: ['./mechanical-equipment-errors.component.scss'],
})
export class MechanicalEquipmentErrorsComponent {
  @Input() entityId: number;
  componentDestroyed$: Subject<boolean> = new Subject();
  enable = false;
  name: string;
  equipmentName: string;
  mechanicalequipmentTypeId: number;

  totalErrorCount = 0;
  totalWarningCount = 0;
  attachedEquipmnentErrorCount = 0;
  attachedEquipmnentWarningCount = 0;

  attachedEquipmentDetails: { name: EquipmentType; id: number };
  attachedEquipmentStateName: string;

  constructor(public store: Store) {}

  ngOnInit(): void {
    this.enable = true;

    this.store
      .select(selectMechanicalEquipmentErrorViewSet(this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.name = data.name;
        this.mechanicalequipmentTypeId = data.attachedEquipmentDetails.id;

        this.attachedEquipmnentErrorCount =
          data.attachedEquipmentErrorCount.errorCount;
        this.attachedEquipmnentWarningCount =
          data.attachedEquipmentErrorCount.warningCount;

        this.totalErrorCount =
          data.mechanicalEquipmentError.errorCount +
          this.attachedEquipmnentErrorCount;
        this.totalWarningCount =
          data.mechanicalEquipmentError.warningCount +
          this.attachedEquipmnentWarningCount;

        this.attachedEquipmentDetails = data.attachedEquipmentDetails;
        this.equipmentName =
          EquipmentTypeLabels[data.attachedEquipmentDetails.name];
        this.attachedEquipmentStateName =
          EquipmentFieldNameToStateName[data.attachedEquipmentDetails.name];
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
