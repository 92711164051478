import { Component, Inject, OnInit } from '@angular/core';
import {
  Equipment,
  EquipmentCalibrationCycleLabelMapping,
  EquipmentTypeLabelMapping,
} from '@/data/equipment/models';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { forkJoin, of, Subject } from 'rxjs';
import { User } from '@/data/core/models/user';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, switchMap, takeUntil } from 'rxjs/operators';
import { toggleLoading } from '@/state/actions/app.actions';
import { EquipmentService } from '@/data/equipment/services/equipment.service';
import { formatDate } from '@angular/common';

export class EquipmentChangeDialogData {
  equipment: Equipment;
  commit = true;
  retrieve = true;
}

@Component({
  selector: 'app-equipment-change-dialog',
  templateUrl: './equipment-change-dialog.component.html',
  styleUrls: ['./equipment-change-dialog.component.scss'],
})
export class EquipmentChangeDialogComponent implements OnInit {
  public initialized: boolean;
  public entity?: Equipment;
  public commit = true;
  public form: FormGroup;

  public equipmentTypeLabelMapping = EquipmentTypeLabelMapping;
  public equipmentCalibrationCycleLabelMapping =
    EquipmentCalibrationCycleLabelMapping;

  private componentDestroyed$ = new Subject();
  public currentUser: User;
  public retrieve = true;
  public isEditForm = false;

  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<EquipmentChangeDialogComponent>,
    private fb: FormBuilder,
    private equipmentService: EquipmentService,
    private dialog: MatDialog,
    private uiHelperService: UIHelperService,
    @Inject(MAT_DIALOG_DATA) public data: EquipmentChangeDialogData
  ) {
    this.entity = Object.assign({}, data.equipment);
    this.commit = data?.commit || true;
    this.retrieve = data?.retrieve || true;

    if (this.entity?.id) {
      this.isEditForm = true;
    } else {
      this.retrieve = false;
    }

    if (!this.entity?.owner_company) {
      throw Error('Provide entity.owner_company for this dialog');
    }
  }

  ngOnInit() {
    const sources = {
      currentUser: this.store.select(getInfoUser).pipe(first()),
      equipment: of(this.entity),
    };

    this.store.dispatch(toggleLoading({ payload: true }));

    if (this.retrieve) {
      sources.equipment = this.equipmentService
        .retrieve(this.entity.id, { ignoreStore: true })
        .pipe(takeUntil(this.componentDestroyed$), first());
    }

    forkJoin(sources)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: ({ currentUser, equipment }) => {
          this.store.dispatch(toggleLoading({ payload: false }));

          this.currentUser = currentUser;
          this.entity = equipment;
          this.setupForm();
          this.initialized = true;
          this.hydrateForm();
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  private setupForm() {
    this.form = this.fb.group({
      brand: [null, Validators.required],
      equipment_model: [null, Validators.required],
      serial: [null, Validators.required],
      description: [null, []],
      calibration_date: [null, []],
      equipment_type: [null, Validators.required],
      calibration_cycle: [null, Validators.required],
      calibration_company: [null, Validators.required],
      assignees_info: [[]],
      calibration_documentation: [null, Validators.required],
      notes: [],
    });
  }

  hydrateForm() {
    this.form.patchValue(this.entity, { emitEvent: false, onlySelf: true });
    this.form.patchValue(
      { calibration_cycle: this.entity.calibration_cycle?.toString() },
      { emitEvent: false, onlySelf: true }
    );
    this.form.patchValue(
      { equipment_type: this.entity.equipment_type?.toString() },
      { emitEvent: false, onlySelf: true }
    );
  }

  save($event: MouseEvent) {
    $event.preventDefault();
    if (this.isEditForm) {
      this.edit();
    } else {
      this.create();
    }
  }

  getFormData(): Equipment {
    const data = new Equipment();
    data.owner_company = this.entity.owner_company;
    data.equipment_type = this.form.value.equipment_type;
    data.brand = this.form.value.brand;
    data.serial = this.form.value.serial;
    data.brand = this.form.value.brand;
    data.equipment_model = this.form.value.equipment_model;
    data.description = this.form.value.description || '';
    data.calibration_date = `${formatDate(
      this.form.value.calibration_date,
      'yyyy-MM-dd',
      'en-US'
    )}`;
    data.calibration_cycle = this.form.value.calibration_cycle;
    data.calibration_company = this.form.value.calibration_company;
    data.assignees = this.form.value.assignees_info?.map(
      assignee => assignee.id
    );
    data.notes = this.form.value.notes || '';

    return data;
  }

  private edit() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    if (!this.commit) {
      this.dialogRef.close(this.entity);
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const data = this.getFormData();

    this.equipmentService
      .update(this.entity.id, data)
      .pipe(
        takeUntil(this.componentDestroyed$),
        first(),
        // update file fields
        switchMap(_ => {
          const formData = new FormData();
          formData.append(
            'calibration_documentation',
            this.form.value.calibration_documentation
          );
          return this.equipmentService.update(this.entity.id, formData);
        })
      )
      .subscribe({
        next: equipment => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close(equipment);
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  private create() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    if (!this.commit) {
      this.dialogRef.close(this.entity);
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const data = this.getFormData();

    this.equipmentService
      .create(data)
      .pipe(
        takeUntil(this.componentDestroyed$),
        first(),
        // update file fields
        switchMap(equipment => {
          const formData = new FormData();
          formData.append(
            'calibration_documentation',
            this.form.value.calibration_documentation
          );
          return this.equipmentService.update(equipment.id, formData);
        })
      )
      .subscribe({
        next: equipment => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close(equipment);
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }
}
