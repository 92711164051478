import { createSelector } from '@ngrx/store';
import { SimulationState } from '../reducers';
import { selectSimulationFeature } from '../simulation.selectors';

export const selectDehumidifier = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.dehumidifier.entities[id]
  );

const selectDehumidifierLoading = (id: number) =>
  createSelector(
    selectSimulationFeature,
    state => state.dehumidifier.loading[id]
  );

export const selectDehumidifierViewSet = (id: number) =>
  createSelector(
    selectDehumidifier(id),
    selectDehumidifierLoading(id),
    (data, loading) => ({ data, loading })
  );

export const selectDehumidifierErrors = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.dehumidifier.errors[id]
  );
