import {
  ThermostatBackendDict,
  createThermostatForm,
} from '@/data/simulation/models/mechanicals/Thermostat';
import { BaseValidator } from './base.validator';
import { ModelErrors, StateErrors } from '../state/shared/base.state';

export class ThermostatValidator extends BaseValidator {
  static validateForm(
    thermostat: ThermostatBackendDict
  ): ModelErrors<ThermostatBackendDict> {
    const form = createThermostatForm(thermostat);
    return form.getFieldAndModelErros();
  }

  static validate(
    thermostat: ThermostatBackendDict[]
  ): StateErrors<ThermostatBackendDict> {
    const errors = {};
    thermostat.forEach(thermostat => {
      errors[thermostat.id] = this.validateForm(thermostat);
    });
    return errors;
  }
}
