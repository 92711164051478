import { enumValidator } from '@/modules/simulation/validators/helper';
import { Orientation } from '../../enumerations';
import { SkylightTypeBackendDict } from './SkylightType';
import { ModelFormControl, ModelFormGroup } from '../ModelForm';
import { Validators } from '@angular/forms';
import { FieldLabels } from '../base';
import { SimulationModelsState } from '@/modules/simulation/state/reducers';

export const DEFAULT_SKYLIGHT: SkylightBackendDict = {
  id: null,
  name: '',
  type: null,
  orientation: Orientation.NORTH,
  area: 0,
  shading_factor_interior_summer: 0,
  shading_factor_interior_winter: 0,
  shading_factor_adjacent_summer: 0,
  shading_factor_adjacent_winter: 0,
  pitch: 0,
  roof: null,
};

// Labels
export const FIELD_LABELS: FieldLabels<SkylightBackendDict> = {
  name: 'Name',
  type: 'Type',
  orientation: 'Orientation',
  area: 'Area',
  shading_factor_interior_summer: {
    label: 'Interior',
    description: `
      A value between 0.0 and 1.0 that represents the fraction of solar heat that enters the home through the window during the
      summer months. A value of 1.0 means there are no interior window coverings (transparent). This value depends on both the window glass
      and the interior shades.`,
  },
  shading_factor_interior_winter: {
    label: 'Interior',
    description: `
      A value between 0.0 and 1.0 that represents the fraction of solar heat that enters the home through the window during the
      summer months. A value of 1.0 means there are no interior window coverings (transparent). This value depends on both the window glass
      and the interior shades.`,
  },
  shading_factor_adjacent_summer: {
    // Label Adjacent change to Exterior as requested by Steve.
    label: 'Exterior',
    description: `
      A value between 0.0 and 1.0 to indicate how much the windows are shaded by external objects, which might be attached to the
      building or not during the summer months. Examples include wing walls (part of the building's structure), trees and shrubs
      (which may lose or gain foliage seasonally), and nearby buildings and landforms. If your building has no overhangs or
      significant obstructions, you should select 1.0 for the winter and summer shading factors.`,
  },
  shading_factor_adjacent_winter: {
    label: 'Exterior',
    description: `
      A value between 0.0 and 1.0 to indicate how much the windows are shaded by external objects, which might be attached to the
      building or not during the winter months. Examples include wing walls (part of the building's structure), trees and shrubs
      (which may lose or gain foliage seasonally), and nearby buildings and landforms. If your building has no overhangs or
      significant obstructions, you should select 1.0 for the winter and summer shading factors.`,
  },
  pitch: {
    label: 'Pitch',
    description: `
      Pitch is stored as X/12, such that e.g. pitch=4 (the most common US roof pitch) means a
      slope of 4 inches of drop for every 12 inches of horizontal travel.  Please enter the exterior roof surface
      pitch (not the interior side). You may leave this blank if you have the roof pitch assigned.`,
  },
  roof: 'Roof',
};

export interface SkylightBackendDict {
  id: number;
  type: number;
  name: string;
  orientation: Orientation;
  area: number;
  shading_factor_interior_summer: number;
  shading_factor_interior_winter: number;
  shading_factor_adjacent_summer: number;
  shading_factor_adjacent_winter: number;
  pitch: number;
  roof: number;
}

export interface DetailedSkylightBackendDict extends SkylightBackendDict {
  type_info: SkylightTypeBackendDict;
}

export function createSkylightForm(skylight: SkylightBackendDict) {
  return new ModelFormGroup({
    id: new ModelFormControl(skylight.id, Validators.required),
    name: new ModelFormControl(skylight.name, [
      Validators.required,
      Validators.maxLength(128),
    ]),
    type: new ModelFormControl(skylight.type, Validators.required),
    orientation: new ModelFormControl(skylight.orientation, [
      enumValidator(Orientation),
    ]),
    area: new ModelFormControl(skylight.area, [
      Validators.required,
      Validators.min(0),
      Validators.max(3000),
    ]),
    shading_factor_interior_summer: new ModelFormControl(
      skylight.shading_factor_interior_summer,
      [Validators.required, Validators.min(0), Validators.max(1)]
    ),
    shading_factor_interior_winter: new ModelFormControl(
      skylight.shading_factor_interior_winter,
      [Validators.required, Validators.min(0), Validators.max(1)]
    ),
    shading_factor_adjacent_summer: new ModelFormControl(
      skylight.shading_factor_adjacent_summer,
      [Validators.required, Validators.min(0), Validators.max(1)]
    ),
    shading_factor_adjacent_winter: new ModelFormControl(
      skylight.shading_factor_adjacent_winter,
      [Validators.required, Validators.min(0), Validators.max(1)]
    ),
    pitch: new ModelFormControl(skylight.pitch, [
      Validators.min(1),
      Validators.max(12),
    ]),
    roof: new ModelFormControl(skylight.roof, Validators.required),
  });
}

export function denormalizeSkylights(
  state: SimulationModelsState
): DetailedSkylightBackendDict[] {
  const denormalizedSkylight: DetailedSkylightBackendDict[] = [];
  for (const skylight of Object.values(state.skylight.entities)) {
    denormalizedSkylight.push({
      ...skylight,
      type_info: state.skylightType[skylight.type],
    });
  }
  return denormalizedSkylight;
}
