<div>
    <div *ngFor="let error of fieldErrors | keyvalue">
        <div class="field" *ngFor="let errorMessage of error.value">
            <strong class="qa-flag-border" [style.borderColor]="errorMessage[0]">{{labels[error.key]}}:</strong>&nbsp;<p class="errors">{{errorMessage[1]}}</p>
        </div>
    </div>
    <div *ngFor="let error of fieldWarnings | keyvalue">
        <div class="field" *ngFor="let warningMessage of error.value">
            <strong class="qa-flag-border" [style.borderColor]="warningMessage[0]">{{labels[error.key]}}:</strong>&nbsp;<p class="warnings">{{warningMessage[1]}}</p>
        </div>
    </div>
    <ul class="model-error-list">
        <li *ngFor="let errorMessage of modelErrors" class="errors">
            <p class="qa-flag-border" [style.borderColor]="errorMessage[0]">{{errorMessage[1]}}</p>
        </li>
        <li *ngFor="let message of modelWarnings" class="warnings">
            <p class="qa-flag-border" [style.borderColor]="message[0]">{{message[1]}}</p>
        </li>
    </ul>
</div>
