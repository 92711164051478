<div class="row">
    <div class="col-4">
        <h3>Modern Message Notifications</h3>
        <hr>
        <button class="btn btn-info btn-block" (click)="createMessage('info');">
            Create Info Toast Service message
        </button>
        <button class="btn btn-light btn-block" (click)="createMessage('system');">
            Create System Toast Service message
        </button>
        <button class="btn btn-success btn-block" (click)="createMessage('success');">
            Create Success Toast Service message
        </button>
        <button class="btn btn-warning btn-block" (click)="createMessage('warning');">
            Create Warning Toast Service message
        </button>
        <button class="btn btn-danger btn-block" (click)="createMessage('error');">
            Create Error Toast Service message
        </button>
    </div>
    <div class="col-4">
        <h3>Global</h3>
        <hr>
        <button class="btn btn-primary btn-block" (click)="raiseUnhandledException()">
            Raise Unhandled Exception
        </button>
        <button class="btn btn-info btn-block" (click)="turnOnGlobalLoading(10)">
            Turn On Global Loading for 10 sec.
        </button>
    </div>
    <div class="col-4">
        <h3>Maps</h3>
        <google-map
                height="350px"
                width="100%"
                [zoom]="12"
                [center]="googleMapsEx1Center"
                [options]="googleMapsEx1Options"
        >
            <map-marker
                #markerElem="mapMarker"
                *ngFor="let marker of googleMapsEx1Markers"
                [position]="marker.position"
                [label]="marker.label"
                [title]="marker.title"
                [options]="marker.options"
                (mapClick)="googleMapsEx1OpenInfo(markerElem, marker.info)"
            >
            </map-marker>
            <map-info-window>{{ googleMapsEx1InfoContent }}</map-info-window>
        </google-map>
        <button mat-raised-button (click)="this.googleMapsEx1addMarker()">Add marker(only after geolocation)</button>
    </div>
</div>

<div class="row">
    <div class="col-4">
        <h3>Address Inputs</h3>
        <hr>
        <form [formGroup]="differentFieldsForm">
            <mat-form-field class="w-100">
                <mat-label>Home Address Input</mat-label>
                <app-home-address-input placeholder="Home Address Input" formControlName="home_address" required [show-clear-btn]="true"></app-home-address-input>
                <mat-error *ngIf="differentFieldsForm.get('home_address').hasError('required')">
                    This field is required
                </mat-error>
            </mat-form-field>
            <mat-form-field class="w-100">
                <mat-label>Address Input</mat-label>
                <app-address-input placeholder="Address Input" formControlName="address" required [show-clear-btn]="true"></app-address-input>
                <mat-error *ngIf="differentFieldsForm.get('address').hasError('required')">
                    This field is required
                </mat-error>
            </mat-form-field>
            <h3>Select</h3>
            <p>
                Generic select
            </p>
            <p>
                Initial value for this select applying synchronously
            </p>
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>EEP Programs</mat-label>
                <app-generic-mat-select
                        formControlName="eep_program"
                        modelClassName="eep_program"
                        [display]="eepProgramSelectCustomDisplayOption"
                        [filterParams]="eepProgramRequestParams"
                ></app-generic-mat-select>
                <mat-error *ngIf="differentFieldsForm.get('eep_program').hasError('required')">
                    This field is required
                </mat-error>
            </mat-form-field>
            <pre>Selected EEP Program: {{ differentFieldsForm.get('eep_program').value }}</pre>
            <p>
                Multiple selection
            </p>
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>EEP Programs</mat-label>
                <app-generic-mat-select
                        formControlName="eep_program_multiple"
                        modelClassName="eep_program"
                        [multiple]="true"
                ></app-generic-mat-select>
                <mat-error *ngIf="differentFieldsForm.get('eep_program_multiple').hasError('required')">
                    This field is required
                </mat-error>
            </mat-form-field>
            <pre>Selected EEP Program: {{ differentFieldsForm.get('eep_program_multiple').value }}</pre>

            <h3>Autocomplete</h3>
            <p>
                Filter company list by company type (only builders and raters) with initial value
            </p>
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Builder Autocomplete</mat-label>
                <app-generic-model-autocomplete
                        formControlName="builder_autocomplete"
                        modelClassName="company"
                        [initialValueId]="1"
                        [display]="companyAutocompleteCustomDisplayCompany"
                        [filterParams]="builderRequestParams"
                ></app-generic-model-autocomplete>
                <mat-error *ngIf="differentFieldsForm.get('builder_autocomplete').hasError('required')">
                    This field is required
                </mat-error>
            </mat-form-field>
            <pre>Selected company: {{ differentFieldsForm.get('builder_autocomplete').value?.id }} - {{ differentFieldsForm.get('builder_autocomplete').value?.name }}</pre>
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Select Multiple Users</mat-label>
                <app-generic-model-chips placeholder="Add New User..." formControlName="service_chips" modelClassName="user" [initialValueIds]="[1, 5, 10]"></app-generic-model-chips>
                <mat-error *ngIf="differentFieldsForm.get('service_chips').hasError('required')">
                    This field is required
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Upload file</mat-label>
                <app-file-input formControlName="file_upload"></app-file-input>
                <mat-error *ngIf="differentFieldsForm.get('file_upload').hasError('required')">
                    This field is required
                </mat-error>
            </mat-form-field>
            Selected users:
            <pre *ngFor="let user of differentFieldsForm.get('service_chips').value">{{ user?.id }} - {{ user?.first_name }} {{ user?.last_name }}</pre>
            <h3>Month/Year picker</h3>
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Month/Year picker</mat-label>
                <app-month-mat-picker formControlName="month_year"></app-month-mat-picker>
                <mat-error *ngIf="differentFieldsForm.get('month_year').hasError('required')">
                    This field is required
                </mat-error>
            </mat-form-field>
            <h3>Search field</h3>
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Search</mat-label>
                <app-search-input formControlName="search_field"></app-search-input>
            </mat-form-field>
            <h3>CK Editor(deprecated) Use ngx-quill</h3>
            <ckeditor
                    formControlName="content"
                    [editor]="editor"
                    [config]="editorConfig">
            </ckeditor>
            <h3>Date Range Select Filter</h3>


          <mat-form-field class="w-100">
            <mat-label>Certificate Expiration</mat-label>
            <app-date-range-mat-select
              formControlName="date_range_select_filter"
              [dateRanges]="dateRanges"
            ></app-date-range-mat-select>
          </mat-form-field>
        </form>
    </div>

    <div class="col-4">
        <h3>Dialogs</h3>
        <hr>
        <button mat-raised-button color="accent" class="btn-block" (click)="showOkDialog()">
            OK Dialog
        </button>
        <button mat-raised-button color="accent" class="btn-block" (click)="showConfirmDialog()">
            Confirm Dialog
        </button>
        <button mat-raised-button color="accent" class="btn-block" (click)="showInputDialog()">
            Input Dialog
        </button>
        <button mat-raised-button color="accent" class="btn-block" (click)="showServerErrorDialog()">
            Server Error Dialog
        </button>
        <button mat-raised-button color="accent" class="btn-block" (click)="showDropZoneDialog()">
            Dropzone Dialog
        </button>
        <p class="mt-3">
            Copy button:<br>

            <app-copy-to-clipboard-button [value]="'Value'">
                Copy "Value" text to clipboard
            </app-copy-to-clipboard-button>
        </p>
    </div>
    <div class="col-4">
        <h3>History</h3>
        <hr>
        <b>app-history-mat-button</b>
        <p>Change Builder via Autocomplete select to view new history</p>
        <app-history-mat-button [objectId]="differentFieldsForm.get('builder_autocomplete').value.id" [modelClassName]="'company'" *ngIf="differentFieldsForm.get('builder_autocomplete').value">
            View History for {{ differentFieldsForm.get('builder_autocomplete').value?.name }}
        </app-history-mat-button>
        <br>
        <b>app-history-table</b>
        <div *ngIf="builderOrganization?.id">
            <div style="max-height: 300px; overflow-y: auto;">
                <app-history-table [modelClassName]="'company'" [objectId]="builderOrganization.id"></app-history-table>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-4">
        <h3>Typography</h3>
        <hr>
        <app-advanced-link [internalLink]="['/', 'internal', 'widget', 'test']">Advanced link</app-advanced-link>
        <p>
            <b>Company display component:</b> <br>
            <app-company-display [company]="currentUser?.company_info" displayType="nameAndAddress"></app-company-display>
        </p>
        <p>
            <b>User display component:</b> <br>
            <app-user-display [user]="currentUser" displayType="onlyNameAsLink"></app-user-display>
        </p>

        <p>
          <b>Address display component(Select address via Home address input):</b> <br>
          <app-address-display [display]="'geocodingIndicator'" [lotNumber]="differentFieldsForm.get('home_address')?.value?.lotNumber" [geocode]="differentFieldsForm.get('home_address')?.value?.geocode" [geocodeBrokeredResponse]="differentFieldsForm.get('home_address')?.value?.geocodeBrokeredResponse"></app-address-display>
        </p>

    </div>
    <div class="col-4">
        <h3>Pipes</h3>
        <hr>
        <div>
            Yes/No pipe <br>
            Input: <b>true</b>
            Output: <b>{{ true | yesNo }}</b>
        </div>
        <hr>
        <div>
            Divide pipe <br>
            Input: <b>25/5</b>
            Output: <b>{{ 25 | divide: 5 }}</b>
        </div>
        <hr>
        <div>
            Sum Array of Objects pipe <br>
            Input: <b>{{ sumArrayPipeList | json }}</b>
            Output: <b>{{ sumArrayPipeList | sumArrayOfObjectsPipe: 'total' }}</b>
        </div>
        <hr>
        <div>
            Safe pipe <br>
            Input: <b>{{ safePipeHTML }}</b>
            Output: <b><span [innerHTML]="safePipeHTML"></span></b>
        </div>
        <div>
            Default Order Key Value pipe <br>
            Input: <b>{{ {'a': 1, 'b': 1} | json }}</b>
            <br>
            Output: <div *ngFor="let item of {'a': 1, 'b': 2} | defaultOrderKeyValue">{{ item.key }}: {{ item.value }}</div>
        </div>
    </div>
</div>
