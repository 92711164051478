<div class="container" *ngIf="initialized">
  <div class="row">
    <div class="col-12">
      <h3>
        <button mat-raised-button color="accent" appBackButton [fallbackUrl]="'/hi/project_registrations/'">
          <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
        </button>
        Add New Entire Building Commercial Space
      </h3>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <hr>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <form [formGroup]="formGroup">
        <div class="row">
          <div class="col-12">
            <mat-form-field class="w-100 mb-2">
              <mat-label>Address</mat-label>
              <app-home-address-input placeholder="Address" formControlName="address" required [show-clear-btn]="true"></app-home-address-input>
              <mat-error
                *ngIf="formGroup.get('address').hasError('required')">
                This field is required
              </mat-error>
              <mat-error
                *ngIf="formGroup.get('address').hasError('home_with_this_address_already_exists')">
                Home with this address already exists
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <mat-form-field class="mb-2 w-100">
              <mat-label>Type</mat-label>
              <mat-select aria-label="Commercial Space Type" formControlName="commercial_space_type">
                <mat-option *ngFor="let item of HIRLProjectCommercialSpaceTypeLabelMapping | keyvalue"
                            [value]="item.key">{{item.value}}</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.get('commercial_space_type').hasError('required')">
                Commercial Space Type is required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field class="mb-2 w-100">
              <mat-label>Total Commercial Space</mat-label>
              <input matInput type="number" placeholder="Total Commercial Space" formControlName="total_commercial_space">
              <span matSuffix>
                                                sq. ft
                                            </span>
              <mat-error *ngIf="formGroup.get('total_commercial_space').hasError('required')">
                This field is required
              </mat-error>
              <mat-error *ngIf="formGroup.get('total_commercial_space').hasError('min') || formGroup.get('total_commercial_space').hasError('max')">
                Value must be between 0 and 1000000 sq. ft
              </mat-error>
            </mat-form-field>
          </div>
        </div>

      </form>

      <div class="row">
        <div class="col-12 text-center">
          <button type="button" mat-raised-button color="accent"
                  (click)="handleCreate($event)" [disabled]="formGroup.pending">
            Create
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" *ngIf="formGroup.pending"></fa-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
