import { Geocode, GeocodeBrokeredResponse } from '@/data/geocoder/models';
import { EEPProgramHomeStatusInfo, HomeInfo } from '@/data/home/models';
import { HIRLGreenEnergyBadgeInfo } from '@/data/customer-hirl/models/hirl-green-energy-badge';
import {
  HIRLProjectRegistrationInfo,
  HIRLProjectRegistrationState,
} from '@/data/customer-hirl/models/hirl-project-registration';

export const HIRLProjectRegistrationStateLabelMapping: Record<
  HIRLProjectRegistrationState,
  any
> = {
  [HIRLProjectRegistrationState.new]: 'New',
  [HIRLProjectRegistrationState.pending]: 'Pending',
  [HIRLProjectRegistrationState.active]: 'Active',
  [HIRLProjectRegistrationState.rejected]: 'Rejected',
  [HIRLProjectRegistrationState.abandon]: 'Abandoned',
};

export enum HIRLProjectSampling {
  no_sampling = 'no_sampling',
  testing_and_practices_only = 'testing_and_practices_only',
  rough_testing_and_practices_only = 'rough_testing_and_practices_only',
  final_testing_and_practices_only = 'final_testing_and_practices_only',
  rough_all = 'rough_all',
  final_all = 'final_all',
  all = 'all',
}

export const HIRLProjectSamplingMapping: Record<HIRLProjectSampling, string> = {
  [HIRLProjectSampling.no_sampling]: 'No Sampling',
  [HIRLProjectSampling.testing_and_practices_only]:
    'For Energy efficiency testing practices only (both Rough and Final)',
  [HIRLProjectSampling.rough_testing_and_practices_only]:
    'For Energy efficiency testing practices only (Rough only)',
  [HIRLProjectSampling.final_testing_and_practices_only]:
    'For Energy efficiency testing practices only (Final only)',
  [HIRLProjectSampling.all]:
    'For All or most NGBS practices (both Rough and Final)',
  [HIRLProjectSampling.rough_all]:
    'For All or most NGBS practices (Rough only)',
  [HIRLProjectSampling.final_all]:
    'For All or most NGBS practices (Final only)',
};

export enum HIRLProjectCommercialSpaceType {
  coreShell = 'core&shell',
  full = 'full',
}

export enum HIRLProjectAppealsProjectType {
  yesFullAppeal = 'yes_full_appeal',
  yesAdministrativeAppeal = 'yes_administrative_appeal',
  no = 'no',
}

export const HIRLProjectAppealsProjectTypeMapping: Record<
  HIRLProjectAppealsProjectType,
  string
> = {
  [HIRLProjectAppealsProjectType.yesFullAppeal]: 'Yes – Full Appeal',
  [HIRLProjectAppealsProjectType.yesAdministrativeAppeal]:
    'Yes – Administrative Appeal',
  [HIRLProjectAppealsProjectType.no]: 'No',
};

export const HIRLProjectCommercialSpaceTypeLabelMapping: Record<
  HIRLProjectCommercialSpaceType,
  string
> = {
  [HIRLProjectCommercialSpaceType.coreShell]: 'Core & Shell',
  [HIRLProjectCommercialSpaceType.full]: 'Fully Fitted',
};

export enum HIRLProjectBillingState {
  automatically = '',
  new = 'new',
  newQueued = 'new_queued',
  newNotified = 'new_notified',
  noticeSent = 'notice_sent',
  completed = 'completed',
  // manual billing states
  complimentary = 'complimentary',
  not_pursuing = 'not_pursuing',
  test = 'test',
  void = 'void',
  special_4300 = '4300',
}

export enum LandDevelopmentProjectType {
  letter = 'letter',
  phase = 'phase',
}

export const LandDevelopmentProjectTypeLabelMapping: Record<
  LandDevelopmentProjectType,
  string
> = {
  [LandDevelopmentProjectType.letter]: 'Letter',
  [LandDevelopmentProjectType.phase]: 'Phase',
};

export const HIRLProjectBillingStateLabelMapping: Record<
  HIRLProjectBillingState,
  string
> = {
  [HIRLProjectBillingState.automatically]: 'Automatically',
  [HIRLProjectBillingState.new]: 'New',
  [HIRLProjectBillingState.newQueued]: 'New - Queued',
  [HIRLProjectBillingState.newNotified]: 'New - Notified',
  [HIRLProjectBillingState.noticeSent]: 'Notice Sent',
  [HIRLProjectBillingState.completed]: 'Completed',
  [HIRLProjectBillingState.complimentary]: 'Complimentary',
  [HIRLProjectBillingState.not_pursuing]: 'Not pursuing',
  [HIRLProjectBillingState.test]: 'Test',
  [HIRLProjectBillingState.void]: 'Void',
  [HIRLProjectBillingState.special_4300]: '4300',
};

/* eslint-disable @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match */
export class HIRLProject {
  id?: string;
  is_accessory_structure?: boolean;
  accessory_structure_description?: string;
  is_accessory_dwelling_unit?: boolean;
  accessory_dwelling_unit_description?: string;
  green_energy_badges: Array<number>;
  green_energy_badges_info: HIRLGreenEnergyBadgeInfo[];

  lot_number?: string;
  home_address_geocode?: number;
  home_address_geocode_info?: Geocode;
  home_address_geocode_response?: number;
  home_address_geocode_response_info?: GeocodeBrokeredResponse;

  home_status?: number;
  home_status_info?: HIRLProjectEEPProgramHomeStatusInfo;

  h_number?: string;

  hud_disaster_case_number?: string;
  number_of_units?: number;
  story_count?: number;

  building_number?: string;
  is_include_commercial_space?: boolean;

  commercial_space_type?: HIRLProjectCommercialSpaceType;
  total_commercial_space?: number;

  registration?: string;
  registration_info?: HIRLProjectRegistrationInfo;

  is_require_water_sense_certification?: boolean;
  is_require_rough_inspection?: boolean;
  is_require_wri_certification?: boolean;

  billing_state?: HIRLProjectBillingState;
  manual_billing_state?: HIRLProjectBillingState;

  land_development_project_type: LandDevelopmentProjectType;
  number_of_lots?: number;
  are_all_homes_in_ld_seeking_certification?: boolean;
  land_development_phase_number?: number;
  appeals_project: HIRLProjectAppealsProjectType;

  client_ngbs_id?: number;

  sampling?: HIRLProjectSampling;
  is_townhouse?: boolean;
  commercial_space_parent?: string;
}

export class HIRLProjectBatchSubmission {
  id?: string;

  registration?: string;
  registration_info?: HIRLProjectRegistrationInfo;

  home_status?: string;
  home_status_info?: EEPProgramHomeStatusInfo;

  billing_state?: HIRLProjectBillingState;
  manual_billing_state?: HIRLProjectBillingState;

  home_status_address_builder?: string;

  vr_batch_submission_parent_rough_home?: HomeStatusBatchSubmission;
  vr_batch_submission_parent_final_home?: HomeStatusBatchSubmission;
  vr_batch_submission_parent_childrens_rough?: HomeStatusBatchSubmission[];
  vr_batch_submission_parent_childrens_final?: HomeStatusBatchSubmission[];
  vr_batch_submission_rough_childrens?: HomeStatusBatchSubmission[];
  vr_batch_submission_final_childrens?: HomeStatusBatchSubmission[];

  subdivision_name?: string;
}

export class HomeStatusBatchSubmission {
  id?: string;
  home_status_id?: string;
  home_status_url?: string;
  home_status_address?: string;
}

export class HIRLProjectInfo {
  id: string;
  home_status: number;
  home_status_info?: HIRLProjectEEPProgramHomeStatusInfo;

  home_address_geocode?: number;
  home_address_geocode_info?: Geocode;
  home_address_geocode_response?: number;
  home_address_geocode_response_info?: GeocodeBrokeredResponse;

  is_accessory_structure?: boolean;

  number_of_units?: number;
  story_count?: number;

  client_ngbs_id?: number;

  h_number?: string;
  created_at: string;
}

export class HIRLProjectEEPProgramHomeStatusInfo {
  id: number;
  home: number;
  state: string;
  home_info: HomeInfo;
}

export class ProjectCycleTimeMetrics {
  cycle_days: number;
  projects_count: number;
}

/* eslint-enable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
