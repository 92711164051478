import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BaseDataService } from '@/core/services/base-data-service';
import { Observable } from 'rxjs';
import { PhotovoltaicBackendDict } from '../models/generators/Photovoltaic';
import { ListRequestParams } from '@/core/schemes/request-params';
import { ServerResponse } from '@/core/schemes/server-response';

@Injectable({ providedIn: 'root' })
export class PhotovoltaicService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/photovoltaic/`;

  list(
    params: ListRequestParams
  ): Observable<ServerResponse<PhotovoltaicBackendDict>> {
    return this.http.get<ServerResponse<PhotovoltaicBackendDict>>(
      `${this.baseUrl}`,
      {
        params: this.rollParams(params),
      }
    );
  }

  update(
    photovoltaic: Partial<PhotovoltaicBackendDict>
  ): Observable<PhotovoltaicBackendDict> {
    return this.http.patch<PhotovoltaicBackendDict>(
      `${this.baseUrl}${photovoltaic.id}/`,
      photovoltaic
    );
  }

  delete(photovoltaicId: number) {
    return this.http.delete<PhotovoltaicBackendDict>(
      `${this.baseUrl}${photovoltaicId}/`
    );
  }
}
