import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from '@/data/core/models/user';
import { Subject } from 'rxjs';
import { toggleLoading } from '@/state/actions/app.actions';
import { takeUntil } from 'rxjs/operators';
import { getInfoUser } from '@/state/selectors/info.selector';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '@/data/core/services/user.service';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { NavigationService } from '@/shared/services/navigation-service.service';

@Component({
  selector: 'app-hirl-manufacturer-dashboard',
  templateUrl: './hirl-manufacturer-dashboard.component.html',
  styleUrls: ['./hirl-manufacturer-dashboard.component.scss'],
})
export class HirlManufacturerDashboardComponent implements OnInit, OnDestroy {
  public initialized = false;
  public currentUser: User;

  private componentDestroyed$ = new Subject();

  constructor(
    public store: Store<AppState>,
    public router: Router,
    public dialog: MatDialog,
    public activatedRoute: ActivatedRoute,
    public userService: UserService,
    public uiHelperService: UIHelperService,
    private navigation: NavigationService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(toggleLoading({ payload: true }));

    this.store
      .select(getInfoUser)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(currentUser => {
        this.currentUser = currentUser;

        this.initialized = true;
        this.store.dispatch(toggleLoading({ payload: false }));
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
