import { Directive, HostListener, Input } from '@angular/core';
import { NavigationService } from '@/shared/services/navigation-service.service';
import { UrlTree } from '@angular/router';

@Directive({
  selector: '[appBackButton]',
})
export class BackButtonDirective {
  @Input() fallbackUrl: string | UrlTree = '/';

  constructor(private navigation: NavigationService) {}

  @HostListener('click')
  onClick(): void {
    this.navigation.back(this.fallbackUrl);
  }
}
