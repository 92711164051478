import { AboveGradeWallTypeBackendDict } from '@/data/simulation/models/enclosure/AboveGradeWallType';
import { createAction, props } from '@ngrx/store';
import { StateErrors } from '../shared/base.state';

export const loadDetailedAboveGradeWallTypes = createAction(
  '[AboveGradeWallType] Load Detailed AboveGradeWallTypes',
  props<{ wallTypes: AboveGradeWallTypeBackendDict[] }>()
);

export const loadAboveGradeWallTypesSuccess = createAction(
  '[AboveGradeWallType] Load AboveGradeWallTypes Success',
  props<{
    wallTypes: AboveGradeWallTypeBackendDict[];
    errors: StateErrors<AboveGradeWallTypeBackendDict>;
  }>()
);

export const addAboveGradeWallType = createAction(
  '[AboveGradeWallType UI] Add AboveGradeWallType',
  props<{ wallId: number; wallType: AboveGradeWallTypeBackendDict }>()
);

export const removeAboveGradeWallType = createAction(
  '[AboveGradeWall UI] Remove AboveGradeWallType',
  props<{ wallId: number; wallTypeId: number }>()
);

export const removeAboveGradeWallTypeSuccess = createAction(
  '[AboveGradeWallType API] Remove AboveGradeWallType Success',
  props<{ id: number }>()
);

export const updateAboveGradeWallType = createAction(
  '[AboveGradeWallType UI] Update AboveGradeWallType',
  props<{ wallTypeChanges: Partial<AboveGradeWallTypeBackendDict> }>()
);

export const updateAboveGradeWallTypeFailure = createAction(
  '[AboveGradeWallType API] Update AboveGradeWallType Failure',
  props<{ id: number }>()
);
