import { CompanyInfo } from '@/data/company/models';

export enum TrainingStatusStatus {
  new = 'new',
  approved = 'approved',
  rejected = 'rejected',
  expired = 'expired',
}

export const TrainingStatusStatusLabelMapping: Record<
  TrainingStatusStatus,
  string
> = {
  [TrainingStatusStatus.new]: 'New (Unapproved)',
  [TrainingStatusStatus.approved]: 'Approved',
  [TrainingStatusStatus.rejected]: 'Rejected',
  [TrainingStatusStatus.expired]: 'Expired',
};

export class TrainingStatus {
  id?: number;
  company?: number;
  company_info?: CompanyInfo;
  approver?: number;
  approver_info?: number;

  state: TrainingStatusStatus;

  state_changed_at?: string;
  state_notes?: string;
  updated_at?: string;
  created_at?: string;
}
