import { ModelFormControl, ModelFormGroup } from '../ModelForm';
import { Validators } from '@angular/forms';
import { FieldLabels } from '../base';
import { enumValidator } from '@/modules/simulation/validators/helper';

// Labels
export const FIELD_LABELS: FieldLabels<DehumidifierBackendDict> = {
  name: 'Name',
  type: 'Type',
  fuel: 'Fuel',
  capacity: 'Capacity',
  capacity_unit: 'Capacity Unit',
  efficiency: 'Efficiency',
  efficiency_unit: 'Efficiency Unit',
  ahri_reference_number: 'AHRI Reference Number',
};

export enum DehumidifierType {
  PORTABLE = 'portable',
  WHOLE_HOME = 'whole-home',
}

export const DehumidifierTypeLabels: Record<DehumidifierType, string> = {
  [DehumidifierType.PORTABLE]: 'Portable',
  [DehumidifierType.WHOLE_HOME]: 'Whole Home',
};

export enum DehumidifierCapacityUnit {
  PINTS_DAY = 'pints_per_day',
}

export const DehumidifierCapacityUnitLabels: Record<
  DehumidifierCapacityUnit,
  string
> = {
  [DehumidifierCapacityUnit.PINTS_DAY]: 'Pints/Day',
};

export enum DehumidifierEfficiencyUnit {
  IEF = 'ief',
  EF = 'ef',
}

export const DehumidifierEfficiencyUnitLabels: Record<
  DehumidifierEfficiencyUnit,
  string
> = {
  [DehumidifierEfficiencyUnit.IEF]: 'IEF (liters/kWh)',
  [DehumidifierEfficiencyUnit.EF]: 'EF (liters/kWh)',
};

export interface DehumidifierBackendDict {
  id: number;
  name: string;
  type: DehumidifierType;
  fuel: string;
  capacity: number;
  capacity_unit: DehumidifierCapacityUnit;
  efficiency: number;
  efficiency_unit: DehumidifierEfficiencyUnit;
  ahri_reference_number: string;
}

export function createDehumidifierForm(
  data: DehumidifierBackendDict
): ModelFormGroup {
  return new ModelFormGroup(
    {
      id: new ModelFormControl(data.id),
      name: new ModelFormControl(data.name, [
        Validators.required,
        Validators.maxLength(128),
      ]),
      type: new ModelFormControl(data.type, enumValidator(DehumidifierType)),
      fuel: new ModelFormControl(data.fuel, Validators.required),
      capacity: new ModelFormControl(data.capacity, [
        Validators.required,
        Validators.min(0),
      ]),
      capacity_unit: new ModelFormControl(
        data.capacity_unit,
        enumValidator(DehumidifierCapacityUnit)
      ),
      efficiency: new ModelFormControl(data.efficiency, [
        Validators.required,
        Validators.min(0),
      ]),
      efficiency_unit: new ModelFormControl(
        data.efficiency_unit,
        enumValidator(DehumidifierEfficiencyUnit)
      ),
      ahri_reference_number: new ModelFormControl(data.ahri_reference_number),
    },
    {
      validators: [validateEfficiencyUnit],
    }
  );
}

function validateEfficiencyUnit(controls: ModelFormGroup) {
  // If the efficiency unit is not IEF, then the efficiency unit is invalid
  const efficiencyUnit = controls.get('efficiency_unit').value;
  if (efficiencyUnit !== DehumidifierEfficiencyUnit.IEF) {
    return {
      invalidEfficiencyUnit: { efficiencyUnit: efficiencyUnit },
    };
  }
}
