import { createReducer, on } from '@ngrx/store';
import * as InfiltrationActions from './actions';
import { InfiltrationBackendDict } from '@/data/simulation/models/enclosure/Infiltration';
import { BaseState } from '../shared/base.state';
import { addStateErrorCount } from '../helper';

export type InfiltrationState = BaseState<InfiltrationBackendDict>;

export const initialState: InfiltrationState = {
  entities: {},
  loading: {},
  errors: {},
};

export const reducer = createReducer(
  initialState,
  on(
    InfiltrationActions.loadInfiltrationSuccess,
    (state, { infiltration, errors }) => ({
      entities: {
        ...state.entities,
        [infiltration.id]: infiltration,
      },
      loading: {
        ...state.loading,
        [infiltration.id]: false,
      },
      errors: {
        ...state.errors,
        ...addStateErrorCount(errors),
      },
    })
  ),

  on(
    InfiltrationActions.updateInfiltration,
    (state, { infiltrationChanges }) => ({
      ...state,
      loading: {
        ...state.loading,
        [infiltrationChanges.id]: true,
      },
    })
  ),

  on(InfiltrationActions.updateInfiltrationFailure, (state, { id }) => ({
    ...state,
    loading: {
      ...state.loading,
      [id]: false,
    },
  }))
);
