import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServerResponse } from '@/core/schemes/server-response';
import { environment } from '../../../../environments/environment';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import { ListRequestParams } from '@/core/schemes/request-params';
import { Brand } from '@/data/product/models';

export class BrandRequestParams extends ListRequestParams {}

@Injectable({
  providedIn: 'root',
})
export class BrandService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/brand/`;

  list(params?: BrandRequestParams) {
    return this.http.get<ServerResponse<Brand>>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }

  retrieve(
    brandId: number,
    options?: IDataServiceMethodOptions
  ): Observable<Brand> {
    const ignoreStore = options?.ignoreStore;
    const chain = this.http.get<Brand>(`${this.baseUrl}${brandId}`);
    return chain;
  }
}
