import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavLoginComponent } from './components/nav-login/nav-login.component';
import { SharedModule } from '@/shared/shared.module';
import { NavImpersonateComponent } from '@/modules/auth/components/nav-impersonate/impersonate.component';
import { LoginComponent } from './pages/login/login.component';
import { SuperuserCompanyAccessDialogComponent } from './components/superuser-company-access-dialog/superuser-company-access-dialog.component';
import { UserChangeCompanyDialogComponent } from './components/company-change-dialog/user-change-company-dialog.component';
import { UserModule } from '@/modules/user/user.module';

@NgModule({
  declarations: [
    NavLoginComponent,
    NavImpersonateComponent,
    LoginComponent,
    SuperuserCompanyAccessDialogComponent,
    UserChangeCompanyDialogComponent,
  ],
  exports: [NavLoginComponent, NavImpersonateComponent],
  imports: [CommonModule, SharedModule, UserModule],
})
export class AuthModule {}
