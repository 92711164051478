export enum FastTrackSubmissionSubmitStatus {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  RETRY = 'RETRY',
}

export const FastTrackSubmissionSubmitStatusMapping: Record<
  FastTrackSubmissionSubmitStatus,
  string
> = {
  [FastTrackSubmissionSubmitStatus.PENDING]: 'Pending',
  [FastTrackSubmissionSubmitStatus.SUCCESS]: 'Success',
  [FastTrackSubmissionSubmitStatus.FAILURE]: 'Failed',
  [FastTrackSubmissionSubmitStatus.RETRY]: 'Retry',
};

export class FastTrackSubmission {
  id: number;
  address: string;
  project_id?: string;
  solar_project_id?: string;
  submit_user?: string;
  submit_status?: string;
  solar_submit_status?: string;
  created_date?: string;
  modified_date?: string;
  submission_count?: number;
  overall_submission_status?: string;
}
