<h2 mat-dialog-title>Validation failed with following errors</h2>
<mat-dialog-content>
    <ul>
        <li *ngFor="let item of errors">
            <p>{{item}}</p>
        </li>
    </ul>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>