import { DuctBackendDict } from '@/data/simulation/models/mechanicals/Duct';
import { createAction, props } from '@ngrx/store';
import { StateErrors } from '../shared/base.state';

export const loadDucts = createAction(
  '[Duct] Load Ducts',
  props<{ ducts: DuctBackendDict[] }>()
);

export const loadDuctsSuccess = createAction(
  '[Duct] Load Ducts Success',
  props<{
    ducts: DuctBackendDict[];
    errors: StateErrors<DuctBackendDict>;
  }>()
);

export const addDuct = createAction(
  '[Duct UI] Add Duct',
  props<{ duct: Partial<DuctBackendDict>; distributionSystemId: number }>()
);

export const removeDuct = createAction(
  '[Ducts UI] Remove Duct',
  props<{ ductId: number; distributionSystemId: number }>()
);

export const removeDuctSuccess = createAction(
  '[Duct API] Remove Duct Success',
  props<{ id: number }>()
);

export const updateDuct = createAction(
  '[Duct UI] Update Duct',
  props<{ ductChanges: Partial<DuctBackendDict> }>()
);

export const updateDuctFailure = createAction(
  '[Duct API] Update Duct Failure',
  props<{ id: number }>()
);
