import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BaseDataService } from '@/core/services/base-data-service';
import { Observable } from 'rxjs';
import { AboveGradeWallBackendDict } from '../models/enclosure/AboveGradeWall';

@Injectable({ providedIn: 'root' })
export class AboveGradeWallService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/above_grade_wall/`;

  update(
    aboveGradeWallTypeChanges: Partial<AboveGradeWallBackendDict>
  ): Observable<AboveGradeWallBackendDict> {
    return this.http.patch<AboveGradeWallBackendDict>(
      `${this.baseUrl}${aboveGradeWallTypeChanges.id}/`,
      aboveGradeWallTypeChanges
    );
  }

  delete(id: number) {
    return this.http.delete(`${this.baseUrl}${id}/`);
  }
}
