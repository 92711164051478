import { Component, Input, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';

export interface FuelUsageDialogData {
  title: string;
  fuelUsage: Record<string, unknown>;
}

@Component({
  selector: 'app-analytics-fuel-usage',
  templateUrl: './analytics-fuel-usage.component.html',
  styleUrls: ['./analytics-fuel-usage.component.scss'],
})
export class AnalyticsFuelUsageComponent implements OnInit {
  @Input() fuelUsage;

  public initialized: false;
  public title: string;

  public componentDestroyed$ = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FuelUsageDialogData,
    private dialogRef: MatDialogRef<AnalyticsFuelUsageComponent>
  ) {
    this.fuelUsage = this.data.fuelUsage;
    this.title = this.data.title;
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  onClose() {
    this.dialogRef.close();
  }
}
