import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BaseDataService } from '@/core/services/base-data-service';
import { Observable } from 'rxjs';
import { RimJoistBackendDict } from '../models/enclosure/RimJoist';

@Injectable({ providedIn: 'root' })
export class RimJoistService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/rim_joist/`;

  update(
    id: number,
    rimJoistChanges: Partial<RimJoistBackendDict>
  ): Observable<RimJoistBackendDict> {
    return this.http.patch<RimJoistBackendDict>(
      `${this.baseUrl}${id}/`,
      rimJoistChanges
    );
  }

  delete(id: number) {
    return this.http.delete(`${this.baseUrl}${id}/`);
  }
}
