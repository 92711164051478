import { EEPProgramInfo } from '@/data/eep-program/models';
import { UserInfo } from '@/data/core/models/user';
import { CompanyInfo } from '@/data/company/models/company';
import {
  HIRLProjectAppealsProjectType,
  HIRLProjectBillingState,
  HIRLProjectCommercialSpaceType,
  HIRLProjectSampling,
  LandDevelopmentProjectType,
} from '@/data/customer-hirl/models/hirl-project';
import { SubdivisionInfo } from '@/data/subdivision/models';
import { ContactCard } from '@/data/core/models';

export enum HIRLProjectRegistrationType {
  singleFamily = 1,
  multiFamily = 2,
  landDevelopment = 3,
}

export const HIRLProjectRegistrationTypeLabelMapping: Record<
  HIRLProjectRegistrationType,
  string
> = {
  [HIRLProjectRegistrationType.singleFamily]: 'Single Family',
  [HIRLProjectRegistrationType.multiFamily]: 'Multi-Family',
  [HIRLProjectRegistrationType.landDevelopment]: 'Land Development',
};

export enum HIRLProjectRegistrationProjectClientType {
  architect = 'architect',
  builder = 'builder',
  developer = 'developer',
  owner = 'owner',
}

export enum HIRLProjectRegistrationAppeals {
  yesFullAppeal = 'yes_full_appeal',
  yesAdministrativeAppeal = 'yes_administrative_appeal',
  no = 'no',
}

export const HIRLProjectRegistrationAppealsLabelMapping: Record<
  HIRLProjectRegistrationAppeals,
  string
> = {
  [HIRLProjectRegistrationAppeals.no]: 'No',
  [HIRLProjectRegistrationAppeals.yesFullAppeal]: 'Yes - Full Appeal',
  [HIRLProjectRegistrationAppeals.yesAdministrativeAppeal]:
    'Yes - Administrative Appeal',
};

export const HIRLProjectRegistrationProjectClientTypeLabelMapping: Record<
  HIRLProjectRegistrationProjectClientType,
  string
> = {
  [HIRLProjectRegistrationProjectClientType.architect]: 'Architect',
  [HIRLProjectRegistrationProjectClientType.builder]: 'Builder',
  [HIRLProjectRegistrationProjectClientType.developer]: 'Developer',
  [HIRLProjectRegistrationProjectClientType.owner]: 'Owner',
};

export enum HIRLResponsibleNameType {
  builder = 'builder',
  architect = 'architect',
  communityOwner = 'community_owner',
  developer = 'developer',
  verifier = 'verifier',
}

export const HIRLResponsibleNameTypeLabelMapping: Record<
  HIRLResponsibleNameType,
  string
> = {
  [HIRLResponsibleNameType.builder]: 'Builder',
  [HIRLResponsibleNameType.architect]: 'Architect',
  [HIRLResponsibleNameType.communityOwner]: 'Community Owner',
  [HIRLResponsibleNameType.developer]: 'Developer',
  [HIRLResponsibleNameType.verifier]: 'Verifier',
};

export enum HIRLProjectRegistrationCertificateLineChoices {
  emptyLine = '',
  customText = 'custom_text',
  customTextGreen = 'custom_text_green',
  addressLineDouble = 'address_line_double',
  hudDisasterCaseNumber = 'hud_disaster_case_number',
  clientCompanyName = 'client_company_name',
  clientCompanyNameWithCity = 'client_company_name_with_city',
  developmentName = 'development_name',
}

export const HIRLProjectRegistrationCertificateLineChoicesMapping: Record<
  HIRLProjectRegistrationCertificateLineChoices,
  string
> = {
  [HIRLProjectRegistrationCertificateLineChoices.emptyLine]: 'Empty Line',
  [HIRLProjectRegistrationCertificateLineChoices.developmentName]:
    'Subdivision/Development Name',
  [HIRLProjectRegistrationCertificateLineChoices.addressLineDouble]:
    'Address (2 lines)',
  [HIRLProjectRegistrationCertificateLineChoices.clientCompanyName]:
    'Client Company Name',
  [HIRLProjectRegistrationCertificateLineChoices.hudDisasterCaseNumber]:
    'HUD Disaster Case Number',
  [HIRLProjectRegistrationCertificateLineChoices.clientCompanyNameWithCity]:
    'Client Company Name with City',
  [HIRLProjectRegistrationCertificateLineChoices.customText]:
    'Custom Text(black font)',
  [HIRLProjectRegistrationCertificateLineChoices.customTextGreen]:
    'Custom Text(green font)',
};

export enum HIRLProjectLDRegistrationCertificateLineChoices {
  emptyLine = '',
  customText = 'custom_text',
  customTextGreen = 'custom_text_green',
  addressLineDouble = 'address_line_double',
  clientCompanyNameWithCity = 'client_company_name_with_city',
  developmentName = 'development_name',
}

export const HIRLProjectLDRegistrationCertificateLineChoicesMapping: Record<
  HIRLProjectLDRegistrationCertificateLineChoices,
  string
> = {
  [HIRLProjectLDRegistrationCertificateLineChoices.emptyLine]: 'Empty Line',
  [HIRLProjectLDRegistrationCertificateLineChoices.developmentName]:
    'Subdivision/Development Name',
  [HIRLProjectLDRegistrationCertificateLineChoices.addressLineDouble]:
    'Address (2 lines)',
  [HIRLProjectLDRegistrationCertificateLineChoices.clientCompanyNameWithCity]:
    'Client Company Name with City',
  [HIRLProjectLDRegistrationCertificateLineChoices.customText]:
    'Custom Text(black font)',
  [HIRLProjectLDRegistrationCertificateLineChoices.customTextGreen]:
    'Custom Text(green font)',
};

export enum HIRLProjectSFRegistrationCertificateLineChoices {
  emptyLine = '',
  customText = 'custom_text',
  customTextGreen = 'custom_text_green',
  addressLineDouble = 'address_line_double',
  hudDisasterCaseNumber = 'hud_disaster_case_number',
  clientCompanyNameWithCity = 'client_company_name_with_city',
  developmentName = 'development_name',
}

export const HIRLProjectSFRegistrationCertificateLineChoicesMapping: Record<
  HIRLProjectSFRegistrationCertificateLineChoices,
  string
> = {
  [HIRLProjectSFRegistrationCertificateLineChoices.emptyLine]: 'Empty Line',
  [HIRLProjectSFRegistrationCertificateLineChoices.developmentName]:
    'Subdivision/Development Name',
  [HIRLProjectSFRegistrationCertificateLineChoices.addressLineDouble]:
    'Address (2 lines)',
  [HIRLProjectSFRegistrationCertificateLineChoices.clientCompanyNameWithCity]:
    'Client Company (2 lines)',
  [HIRLProjectSFRegistrationCertificateLineChoices.hudDisasterCaseNumber]:
    'HUD Disaster Case Number',
  [HIRLProjectSFRegistrationCertificateLineChoices.customText]:
    'Custom Text(black font)',
  [HIRLProjectSFRegistrationCertificateLineChoices.customTextGreen]:
    'Custom Text(green font)',
};

export enum ApplicationPacketCompletionType {
  builder = 'builder',
  architect = 'architect',
  communityOwner = 'community_owner',
  developer = 'developer',
}

export const HIRLApplicationPacketCompletionLabelMapping: Record<
  ApplicationPacketCompletionType,
  string
> = {
  [HIRLResponsibleNameType.builder]: 'Builder',
  [HIRLResponsibleNameType.architect]: 'Architect',
  [HIRLResponsibleNameType.communityOwner]: 'Community Owner',
  [HIRLResponsibleNameType.developer]: 'Developer',
};

export enum PartyNamedOnCertificateType {
  builder = 'builder',
  architect = 'architect',
  communityOwner = 'community_owner',
  developer = 'developer',
}

export const HIRLPartyNamedOnCertificateLabelMapping: Record<
  PartyNamedOnCertificateType,
  string
> = {
  [HIRLResponsibleNameType.builder]: 'Builder',
  [HIRLResponsibleNameType.architect]: 'Architect',
  [HIRLResponsibleNameType.communityOwner]: 'Community Owner',
  [HIRLResponsibleNameType.developer]: 'Developer',
};

export enum HIRLProjectRegistrationState {
  new = 'new',
  pending = 'pending',
  active = 'active',
  rejected = 'rejected',
  abandon = 'abandoned',
}

export class HIRLProjectRegistration {
  id?: string;
  project_type: HIRLProjectRegistrationType;

  projects_count: number;

  eep_program: number;
  eep_program_info: EEPProgramInfo;

  registration_user: number;
  registration_user_info: UserInfo;

  state: HIRLProjectRegistrationState;
  state_change_reason: string;
  state_changed_at?: Date;

  builder_organization: number;
  builder_organization_info: CompanyInfo;
  builder_organization_contact: number;
  builder_organization_contact_info: ContactCard;

  // single family specific fields
  is_build_to_rent?: boolean;
  // multi family specific fields
  subdivision?: number;
  subdivision_info?: SubdivisionInfo;

  is_include_commercial_space: boolean;
  commercial_space_type: HIRLProjectCommercialSpaceType;
  project_client: HIRLProjectRegistrationProjectClientType;
  project_name: string;
  estimated_completion_date?: string;
  total_commercial_space: number;
  number_of_units: number;

  project_contact_first_name: string;
  project_contact_last_name: string;
  project_contact_email: string;
  project_contact_phone_number: string;

  application_packet_completion: HIRLResponsibleNameType;
  party_named_on_certificate: HIRLResponsibleNameType;
  community_named_on_certificate: boolean;

  certificate_line1?: HIRLProjectRegistrationCertificateLineChoices;
  certificate_line1_custom_text?: string;
  certificate_line2?: HIRLProjectRegistrationCertificateLineChoices;
  certificate_line2_custom_text?: string;
  certificate_line3?: HIRLProjectRegistrationCertificateLineChoices;
  certificate_line3_custom_text?: string;
  certificate_line4?: HIRLProjectRegistrationCertificateLineChoices;
  certificate_line4_custom_text?: string;
  certificate_line5?: HIRLProjectRegistrationCertificateLineChoices;
  certificate_line5_custom_text?: string;
  certificate_line6?: HIRLProjectRegistrationCertificateLineChoices;
  certificate_line6_custom_text?: string;
  certificate_line7?: HIRLProjectRegistrationCertificateLineChoices;
  certificate_line7_custom_text?: string;
  certificate_line8?: HIRLProjectRegistrationCertificateLineChoices;
  certificate_line8_custom_text?: string;
  certificate_line9?: HIRLProjectRegistrationCertificateLineChoices;
  certificate_line9_custom_text?: string;

  project_description: string;
  project_website_url?: string;

  building_will_include_non_residential_space: boolean;
  seeking_hud_mortgage_insurance_premium_reduction: boolean;
  seeking_hud_green_and_resilient_retrofit_program: boolean;
  seeking_fannie_mae_green_financing: boolean;
  seeking_freddie_mac_green_financing: boolean;
  intended_to_be_affordable_housing: boolean;

  legacy_builder_organization?: number;
  legacy_builder_organization_info?: CompanyInfo;
  legacy_builder_organization_contact: number;
  legacy_builder_organization_contact_info: ContactCard;

  architect_organization: number;
  architect_organization_info: CompanyInfo;
  architect_organization_contact: number;
  architect_organization_contact_info: ContactCard;

  developer_organization: number;
  developer_organization_info: CompanyInfo;
  developer_organization_contact: number;
  developer_organization_contact_info: ContactCard;

  community_owner_organization?: number;
  community_owner_organization_info?: CompanyInfo;
  community_owner_organization_contact: number;
  community_owner_organization_contact_info: ContactCard;

  sales_phone?: string;
  sales_email?: string;
  sales_website_url?: string;

  entity_responsible_for_payment?: HIRLResponsibleNameType;
  billing_first_name?: string;
  billing_last_name?: string;
  billing_email?: string;
  billing_phone?: string;

  marketing_first_name?: string;
  marketing_last_name?: string;
  marketing_email?: string;
  marketing_phone?: string;

  coi_held_by_different_company?: string;

  // land development specific
  are_all_homes_in_ld_seeking_certification?: boolean;

  client_ngbs_id?: string;
  client_ngbs_id_before_company_consolidation?: string;
}

export class HIRLProjectRegistrationList {
  id?: string;
  project_type: HIRLProjectRegistrationType;
  state: HIRLProjectRegistrationState;
  state_change_reason: string;
  state_changed_at?: Date;

  registration_user: number;
  registration_user_info: UserInfo;
  eep_program: number;
  eep_program_info: EEPProgramInfo;

  subdivision?: number;
  subdivision_info?: SubdivisionInfo;

  project_client: HIRLProjectRegistrationProjectClientType;
  project_name: string;

  projects_count: number;
}

export class HIRLProjectRegistrationInfo {
  id?: string;
  project_type: HIRLProjectRegistrationType;
  eep_program: number;
  eep_program_info: EEPProgramInfo;
  registration_user: number;
  registration_user_info: UserInfo;
  state: HIRLProjectRegistrationState;
  state_change_reason: string;
  state_changed_at?: Date;
  is_build_to_rent: boolean;

  project_name?: string;
  subdivision?: number;
  builder_organization: number;
  builder_organization_info: CompanyInfo;
  created_at?: Date;
}

export class CreateSFHIRLProjectRegistration {
  eep_program: number;
  builder_organization: number;
  builder_organization_contact: number;
  billing_email?: string;
  subdivision?: number;
  is_build_to_rent?: boolean;
  projects: Array<CreateSFHIRLProject> = [];

  building_will_include_non_residential_space: boolean;
  seeking_hud_mortgage_insurance_premium_reduction: boolean;
  seeking_hud_green_and_resilient_retrofit_program: boolean;
  seeking_fannie_mae_green_financing: boolean;
  seeking_freddie_mac_green_financing: boolean;
  intended_to_be_affordable_housing: boolean;

  coi_held_by_different_company = '';
}

export class CreateSFHIRLProject {
  lot_number: string;
  is_accessory_structure: boolean;
  accessory_structure_description: string;

  is_accessory_dwelling_unit: boolean;
  accessory_dwelling_unit_description: string;
  green_energy_badges: Array<number>;
  home_address_geocode: number;
  home_address_geocode_response?: number;
  hud_disaster_case_number?: string;
  is_require_water_sense_certification?: boolean;
  is_require_rough_inspection?: boolean;
  is_require_wri_certification?: boolean;
  appeals_project: HIRLProjectAppealsProjectType;
  sampling: HIRLProjectSampling;
}

export enum HIRLProjectRegistrationLDWorkflowType {
  fullCertification = 'full_certification',
  letterOfApprovalAndFullCertification = 'letter_of_approval_and_full_certification',
}

export const HIRLProjectRegistrationLDWorkflowTypeMapping: Record<
  HIRLProjectRegistrationLDWorkflowType,
  string
> = {
  [HIRLProjectRegistrationLDWorkflowType.letterOfApprovalAndFullCertification]:
    'Letter of Approval and Full Certification',
  [HIRLProjectRegistrationLDWorkflowType.fullCertification]:
    'Full Certification Only',
};

export class CreateLandDevelopmentHIRLProjectRegistration {
  eep_program: number;
  builder_organization: number;
  builder_organization_contact: number;
  project_description: string;
  project_website_url: string;
  project_name: string;
  estimated_completion_date: string;
  ld_workflow: HIRLProjectRegistrationLDWorkflowType;
  projects: CreateLandDevelopmentHIRLProject[] = [];
}

export class CreateLandDevelopmentHIRLProject {
  lot_number: string;
  home_address_geocode: number;
  home_address_geocode_response?: number;
  number_of_lots: number;
  are_all_homes_in_ld_seeking_certification: boolean;
  land_development_project_type: LandDevelopmentProjectType;
  land_development_phase_number: number;
}

export class CreateMFHIRLProjectRegistration {
  eep_program: number;

  is_include_commercial_space: boolean;
  commercial_space_type: HIRLProjectCommercialSpaceType;
  project_client: HIRLProjectRegistrationProjectClientType;
  project_name: string;
  total_commercial_space: number;
  number_of_units: number;

  project_contact_first_name: string;
  project_contact_last_name: string;
  project_contact_email: string;
  project_contact_phone_number: string;

  estimated_completion_date?: string;

  building_will_include_non_residential_space: boolean;
  seeking_hud_mortgage_insurance_premium_reduction: boolean;
  seeking_hud_green_and_resilient_retrofit_program: boolean;
  seeking_fannie_mae_green_financing: boolean;
  seeking_freddie_mac_green_financing: boolean;
  intended_to_be_affordable_housing: boolean;

  builder_organization: number;
  builder_organization_contact: number;

  architect_organization: number;
  architect_organization_contact: number;

  developer_organization: number;
  developer_organization_contact: number;

  community_owner_organization: number;
  community_owner_organization_contact: number;

  legacy_builder_organization: number;
  legacy_builder_organization_contact: number;

  sales_phone: string;
  sales_email: string;
  sales_website_url: string;

  entity_responsible_for_payment: HIRLResponsibleNameType;
  billing_first_name?: string;
  billing_last_name?: string;
  billing_email?: string;
  billing_phone?: string;

  coi_held_by_different_company = '';

  marketing_first_name?: string;
  marketing_last_name?: string;
  marketing_email?: string;
  marketing_phone?: string;

  project_description?: string;
  project_website_url?: string;

  certificate_line1?: HIRLProjectRegistrationCertificateLineChoices;
  certificate_line1_custom_text?: string;
  certificate_line2?: HIRLProjectRegistrationCertificateLineChoices;
  certificate_line2_custom_text?: string;
  certificate_line3?: HIRLProjectRegistrationCertificateLineChoices;
  certificate_line3_custom_text?: string;
  certificate_line4?: HIRLProjectRegistrationCertificateLineChoices;
  certificate_line4_custom_text?: string;
  certificate_line5?: HIRLProjectRegistrationCertificateLineChoices;
  certificate_line5_custom_text?: string;
  certificate_line6?: HIRLProjectRegistrationCertificateLineChoices;
  certificate_line6_custom_text?: string;
  certificate_line7?: HIRLProjectRegistrationCertificateLineChoices;
  certificate_line7_custom_text?: string;
  certificate_line8?: HIRLProjectRegistrationCertificateLineChoices;
  certificate_line8_custom_text?: string;
  certificate_line9?: HIRLProjectRegistrationCertificateLineChoices;
  certificate_line9_custom_text?: string;

  projects: Array<CreateMFHIRLProject> = [];
}

export class CreateMFHIRLProject {
  lot_number: string;
  is_accessory_structure: boolean;
  accessory_structure_description: string;
  is_accessory_dwelling_unit: boolean;
  accessory_dwelling_unit_description: string;
  green_energy_badges: Array<number>;
  home_address_geocode: number;
  home_address_geocode_response?: number;
  hud_disaster_case_number?: string;
  is_include_commercial_space?: boolean;
  commercial_space_type?: HIRLProjectCommercialSpaceType;
  total_commercial_space?: number;
  building_number?: string;
  story_count?: number;
  number_of_units?: number;
  is_require_water_sense_certification?: boolean;
  is_require_rough_inspection?: boolean;
  is_require_wri_certification?: boolean;
  commercial_space_parent?: string;
  appeals_project: HIRLProjectAppealsProjectType;
  commercial_spaces: CreateMFHIRLProject[] = [];
  sampling: HIRLProjectSampling;
  is_townhouse: boolean;
}

export class HIRLProjectAddMF {
  projects: CreateMFHIRLProject[] = [];
}

export class HIRLProjectAddSF {
  projects: CreateSFHIRLProject[] = [];
}

export class AbandonHIRLProjectRegistration {
  reason: string;
  billing_state?: HIRLProjectBillingState;
}

export class HIRLProjectRegistrationActivityMetricsByMonth {
  month?: string;
  registrations_count?: number;
}

export class HIRLProjectRegistrationActivityMetricsUnitsByMonth {
  month?: string;
  units_count?: number;
}
