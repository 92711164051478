import { createSelector } from '@ngrx/store';
import { SimulationState } from '../reducers';
import { selectSimulationFeature } from '../simulation.selectors';

export const selectFrameFloorType = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.frameFloorType.entities[id]
  );

const selectFrameFloorTypeLoadingStatus = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.frameFloorType.loading[id]
  );

export const selectFrameFloorTypeViewSet = (id: number) =>
  createSelector(
    selectFrameFloorType(id),
    selectFrameFloorTypeLoadingStatus(id),
    (frameFloorType, loadingStatus) => ({
      data: frameFloorType,
      loading: loadingStatus,
    })
  );

const selectFrameFloorTypeName = (id: number) =>
  createSelector(
    selectFrameFloorType(id),
    frameFloorType => frameFloorType.name
  );

const selectFrameFloorTypeErrorCount = (id: number) =>
  createSelector(selectSimulationFeature, (state: SimulationState) => {
    return state.frameFloorType.errors[id].errorCount;
  });

export const selectFrameFloorTypeNameAndError = (id: number) =>
  createSelector(
    selectFrameFloorTypeName(id),
    selectFrameFloorTypeErrorCount(id),
    (name, errorCount) => {
      return {
        name,
        errorCount,
      };
    }
  );
