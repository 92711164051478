import { Action, createReducer, on } from '@ngrx/store';
import { loadMenu } from '../actions/menu.actions';
import { Menu } from '@/data/core/models/menu';

export const menuFeatureKey = 'menu';

export interface MenuState {
  menu: Menu[];
}

export const initialState: MenuState = {
  menu: [],
};
const menuReducer = createReducer(
  initialState,
  on(loadMenu, (state, { payload }) => ({ ...state, menu: payload }))
);

export function reducer(state: MenuState | undefined, action: Action) {
  return menuReducer(state, action);
}
