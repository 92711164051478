import { FuelTypeLabels } from '@/data/simulation/enumerations';
import { DehumidifierCapacityUnitLabels } from '@/data/simulation/models/mechanicals/Dehumidifier';
import { DetailedMechanicalEquipmentBackendDict } from '@/data/simulation/models/mechanicals/MechanicalEquipment';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-equipment-dehumidifier',
  templateUrl: './equipment-dehumidifier.component.html',
  styleUrls: ['./equipment-dehumidifier.component.scss'],
})
export class EquipmentDehumidifierComponent implements OnInit {
  @Input() equipment: DetailedMechanicalEquipmentBackendDict;

  FuelTypeLabels = FuelTypeLabels;
  DehumidifierCapacityUnitLabels = DehumidifierCapacityUnitLabels;
  ngOnInit(): void {}
}
