import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { HIRLProductAgreement } from '@/data/customer-hirl/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '@/data/core/models/user';
import { forkJoin, of, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { GeocoderService } from '@/data/geocoder/services/geocoder.service';
import { AppFieldValidatorsService } from '@/core/services/field-validators.service';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, takeUntil } from 'rxjs/operators';
import { toggleLoading } from '@/state/actions/app.actions';
import { HIRLProductAgreementService } from '@/data/customer-hirl/services/product-agreement.service';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';
import { resetHIRLProductAgreement } from '@/state/actions/customer-hirl/hirl-product-agreement.actions';
import { ConfirmedGeocodeResponse } from '@/modules/geocoder/components/confirm-geocode-response-dialog/confirm-geocode-response';
import { IGeocodeMatches } from '@/data/geocoder/models';
import { conditionalValidator } from '@/core/validators';
import { formatDate } from '@angular/common';
import { CompanyInfo, CompanyType } from '@/data/company/models';
import {
  UserRequestParams,
  UserService,
} from '@/data/core/services/user.service';

export class HirlProductAgreementChangeDialogData {
  productAgreement?: HIRLProductAgreement;
  commit = true;
  retrieve = true;
}

@Component({
  selector: 'app-hirl-product-agreement-change-dialog',
  templateUrl: './hirl-product-agreement-change-dialog.component.html',
  styleUrls: ['./hirl-product-agreement-change-dialog.component.scss'],
})
export class HirlProductAgreementChangeDialogComponent
  implements OnInit, OnDestroy
{
  protected readonly CustomerHIRLSettings = CustomerHIRLSettings;

  public initialized: boolean;
  public isEditForm = false;
  public entity?: HIRLProductAgreement;
  public commit = true;
  public retrieve = true;
  public form: FormGroup;
  public currentUser: User;

  private componentDestroyed$: Subject<boolean> = new Subject();

  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<HirlProductAgreementChangeDialogComponent>,
    private fb: FormBuilder,
    private hirlProductAgreementService: HIRLProductAgreementService,
    private geocoderService: GeocoderService,
    private userService: UserService,
    private dialog: MatDialog,
    private appFieldValidatorsService: AppFieldValidatorsService,
    private uiHelperService: UIHelperService,
    @Inject(MAT_DIALOG_DATA) public data: HirlProductAgreementChangeDialogData
  ) {
    if (data?.productAgreement) {
      this.entity = Object.assign({}, data?.productAgreement);
    }
    this.commit = data?.commit || true;

    if (this.entity?.id) {
      this.isEditForm = true;
    }
  }

  ngOnInit() {
    const sources = {
      currentUser: this.store.select(getInfoUser).pipe(first()),
      productAgreement: of(this.entity),
    };

    this.store.dispatch(toggleLoading({ payload: true }));

    if (this.isEditForm && this.retrieve) {
      sources.productAgreement = this.hirlProductAgreementService
        .retrieve(this.entity.id, { ignoreStore: true })
        .pipe(takeUntil(this.componentDestroyed$), first());
    }

    forkJoin(sources)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: ({
          currentUser,
          productAgreement,
        }: {
          currentUser: User;
          productAgreement: HIRLProductAgreement;
        }) => {
          this.store.dispatch(toggleLoading({ payload: false }));

          this.currentUser = currentUser;
          this.entity = productAgreement;
          this.setupForm();
          this.initialized = true;
          this.hydrateForm();
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
    this.store.dispatch(resetHIRLProductAgreement());
  }

  private setupForm() {
    this.form = this.fb.group({
      start_date: [null],
      expiration_date: [null],

      primary_contact_first_name: [
        null,
        [conditionalValidator(() => !this.isEditForm, Validators.required)],
      ],
      primary_contact_last_name: [
        null,
        [conditionalValidator(() => !this.isEditForm, Validators.required)],
      ],
      primary_contact_email_address: [
        null,
        [
          Validators.email,
          conditionalValidator(() => !this.isEditForm, Validators.required),
        ],
      ],
      primary_contact_phone_number: [
        null,
        [conditionalValidator(() => !this.isEditForm, Validators.required)],
      ],
      primary_contact_title: [
        null,
        [conditionalValidator(() => !this.isEditForm, Validators.required)],
      ],
      primary_contact_cell_number: [''],

      mailing_address_geocode: [null, Validators.required],
      company_info: [
        null,
        conditionalValidator(() => !this.isEditForm, Validators.required),
      ],
    });

    this.form
      .get('company_info')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe((company: CompanyInfo) => {
        if (company) {
          if (
            this.currentUser.company_info?.slug ===
              CustomerHIRLSettings.companySlug ||
            this.currentUser.is_superuser
          ) {
            this.store.dispatch(toggleLoading({ payload: true }));
            const userRequestParams = new UserRequestParams();
            userRequestParams.company = company.id;
            this.userService
              .list(userRequestParams)
              .pipe(takeUntil(this.componentDestroyed$), first())
              .subscribe(usersResponse => {
                if (usersResponse.results.length) {
                  const user = usersResponse.results[0];
                  this.form.patchValue(
                    {
                      primary_contact_first_name: user.first_name,
                      primary_contact_last_name: user.last_name,
                      primary_contact_email_address: user.email,
                      primary_contact_phone_number: user.work_phone,
                      primary_contact_cell_number: user.cell_phone || '',
                      primary_contact_title: user.title,
                    },
                    {
                      emitEvent: false,
                      onlySelf: true,
                    }
                  );
                }
                this.store.dispatch(toggleLoading({ payload: false }));
                this.initialValues();
              });
          } else {
            this.form.patchValue(
              {
                primary_contact_first_name: this.currentUser.first_name,
                primary_contact_last_name: this.currentUser.last_name,
                primary_contact_email_address: this.currentUser.email,
                primary_contact_phone_number: this.currentUser.work_phone,
                primary_contact_cell_number: this.currentUser.cell_phone || '',
                primary_contact_title: this.currentUser.title,
              },
              {
                emitEvent: false,
                onlySelf: true,
              }
            );
            this.initialValues();
          }
        } else {
          this.initialValues();
        }
      });
  }

  hydrateForm() {
    if (this.isEditForm) {
      this.form.patchValue(Object.assign({}, this.entity), {
        emitEvent: false,
        onlySelf: true,
      });

      this.form.patchValue(
        {
          mailing_address_geocode: new ConfirmedGeocodeResponse({
            geocode: this.entity.mailing_geocode_info,
            geocodeBrokeredResponse: this.entity.mailing_geocode_response_info,
          }),
        },
        { emitEvent: false, onlySelf: true }
      );
    } else {
      let manufacturer = null;
      if (
        this.currentUser.company_info?.slug !==
          CustomerHIRLSettings.companySlug &&
        !this.currentUser.is_superuser
      ) {
        manufacturer = this.currentUser.company_info;
      }
      this.form.get('company_info').patchValue(manufacturer);
    }
  }
  private initialValues() {
    // Company address do not have geocode field. We create it manually
    if (this.form.get('company_info').value) {
      this.geocoderService
        .matches({
          street_line1: this.form.get('company_info').value?.street_line1,
          street_line2: this.form.get('company_info').value?.street_line2 || '',
          city: this.form.get('company_info').value?.city,
          zipcode: this.form.get('company_info').value?.zipcode,
        } as IGeocodeMatches)
        .pipe(takeUntil(this.componentDestroyed$), first())
        .subscribe(mailingGeocode => {
          const mailingConfirmedGeocodeResponse: ConfirmedGeocodeResponse =
            new ConfirmedGeocodeResponse({
              geocode: mailingGeocode,
              geocodeBrokeredResponse: null,
            });
          this.form.patchValue({
            mailing_address_geocode: mailingConfirmedGeocodeResponse,
          });
        });
    }
  }

  save($event: MouseEvent) {
    $event.preventDefault();
    if (this.isEditForm) {
      this.edit();
    } else {
      this.create();
    }
  }

  private getFormData(): HIRLProductAgreement {
    const data: HIRLProductAgreement = new HIRLProductAgreement();

    if (this.form.value.start_date) {
      data.start_date = `${formatDate(
        this.form.value.start_date,
        'yyyy-MM-dd',
        'en-US'
      )}`;
    } else {
      data.start_date = null;
    }

    if (this.form.value.expiration_date) {
      data.expiration_date = `${formatDate(
        this.form.value.expiration_date,
        'yyyy-MM-dd',
        'en-US'
      )}`;
    } else {
      data.expiration_date = null;
    }

    data.primary_contact_first_name =
      this.form.value.primary_contact_first_name;
    data.primary_contact_last_name = this.form.value.primary_contact_last_name;
    data.primary_contact_title = this.form.value.primary_contact_title;
    data.primary_contact_email_address =
      this.form.value.primary_contact_email_address;
    data.primary_contact_phone_number =
      this.form.value.primary_contact_phone_number;
    data.primary_contact_cell_number =
      this.form.value.primary_contact_cell_number;

    data.mailing_geocode = this.form.value.mailing_address_geocode?.geocode?.id;
    data.mailing_geocode_response = null;
    data.company = this.form.value.company_info?.id;

    if (this.form.value.mailing_address_geocode?.geocodeBrokeredResponse) {
      data.mailing_geocode_response =
        this.form.value.mailing_address_geocode?.geocodeBrokeredResponse?.id;
    }

    return data;
  }

  private create() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    if (!this.commit) {
      this.dialogRef.close(this.entity);
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const data: HIRLProductAgreement = this.getFormData();
    this.hirlProductAgreementService
      .create(data)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: productAgreement => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close(productAgreement);
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  private edit() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    if (!this.commit) {
      this.dialogRef.close(this.entity);
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const data: HIRLProductAgreement = this.getFormData();

    this.hirlProductAgreementService
      .update(data, this.entity.id)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: clientAgreement => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close(clientAgreement);
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  protected readonly CompanyType = CompanyType;
}
