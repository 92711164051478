<h2 mat-dialog-title>
  Send reminder notification
</h2>
<mat-dialog-content *ngIf="initialized">
  <form [formGroup]="form" class="mb-2">
    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Product Manufacturer</mat-label>
          <app-generic-model-autocomplete modelClassName="company" formControlName="company_info"  [filterParams]="{company_type: 'manufacturer'}"></app-generic-model-autocomplete>
          <mat-error *ngIf="form.get('company_info').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <ng-container *ngIf="form.get('company_info').value">
      <div class="row">
        <div class="col-12">
          <b>Latest reminder notification sent at:</b> {{ form.get('company_info').value?.gcp_full_year_expiration_last_notified_at | date: 'MM/dd/yyyy HH:MM' || '-' }}
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <b>Expiration Date:</b> {{ form.get('company_info').value?.gcp_certificate_expiration_date | date: 'MM/dd/yyyy' || '-' }}
        </div>
      </div>
    </ng-container>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button color="accent" mat-raised-button (click)="save($event)">Send Reminder</button>
  <button mat-button [mat-dialog-close]="null" color="primary" mat-raised-button>Cancel</button>
</mat-dialog-actions>
