import { Simulation } from '@/data/simulation/models';
import { resetSimulation } from '@/state/actions/floorplan/simulation.actions';
import { AppState } from '@/state/reducers';
import { getSimulation } from '@/state/selectors/floorplan/simulation.selector';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-enclosure-floors',
  templateUrl: './enclosure-floors.component.html',
  styleUrls: ['./enclosure-floors.component.scss'],
})
export class EnclosureFloorsComponent implements OnInit, OnDestroy {
  public initialized = false;
  public invalidResponse = false;
  public simulation: Simulation;
  public count: number;
  public floors: object[];

  private componentDestroyed$ = new Subject();

  constructor(public store: Store<AppState>) {}

  ngOnInit(): void {
    this.store
      .select(getSimulation)
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(val => val != null)
      )
      .subscribe(
        simulation => {
          this.floors = simulation.frame_floors_info;
          this.count = this.floors ? this.floors.length : 0;
          this.initialized = true;
        },
        error => {
          this.invalidResponse = true;
          this.initialized = true;
        }
      );
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
    this.store.dispatch(resetSimulation());
  }
}
