import { AppState } from '@/state/reducers';
import { createSelector } from '@ngrx/store';
import { hirlProjectRegistrationStateKey } from '@/state/reducers/customer-hirl/hirl-project-registration.reducer';

export const selectHIRLProjectRegistrationState = (state: AppState) =>
  state[hirlProjectRegistrationStateKey];

export const getHIRLProjectRegistration = createSelector(
  selectHIRLProjectRegistrationState,
  store => store.detail
);
// HIRLProjectRegistration Projects
export const getHIRLProjectRegistrationProjects = createSelector(
  selectHIRLProjectRegistrationState,
  store => store.projects
);

export const getHIRLProjectRegistrationProjectsCount = createSelector(
  selectHIRLProjectRegistrationState,
  store => store.projectsCount
);
