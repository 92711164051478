import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { first, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getInfoUser } from '@/state/selectors/info.selector';
import { AppState } from '@/state/reducers';
import { Invoice, InvoiceState, InvoiceType } from '@/data/invoicing/models';
import { InvoiceService } from '@/data/invoicing/services/invoice.service';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';

@Injectable({
  providedIn: 'root',
})
export class InvoicePermissionService {
  constructor(
    public store: Store<AppState>,
    public invoiceService: InvoiceService
  ) {}

  canEdit(invoice: string | Invoice): Observable<ObjectPermissionResponse> {
    let invoice$: Observable<Invoice>;
    if (typeof invoice === 'string') {
      invoice$ = this.invoiceService.retrieve(invoice);
    } else {
      invoice$ = of(invoice);
    }
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: invoice$,
    }).pipe(
      map(({ currentUser, entity }) => {
        if (
          entity.invoice_type === InvoiceType.hirlGCPTopShelf ||
          entity.invoice_type === InvoiceType.hirlGCPInitial ||
          entity.invoice_type === InvoiceType.hirlGCP
        ) {
          if (
            currentUser.is_superuser ||
            currentUser.company_info?.slug === CustomerHIRLSettings.companySlug
          ) {
            return new ObjectPermissionResponse(true);
          }

          if (entity.state === InvoiceState.paid) {
            return new ObjectPermissionResponse(false, 'Already PAID');
          }
          return new ObjectPermissionResponse(true);
        }
        return new ObjectPermissionResponse(
          false,
          'You do not have permission to perform this action'
        );
      })
    );
  }

  canDelete(invoice: string | Invoice): Observable<ObjectPermissionResponse> {
    let invoice$: Observable<Invoice>;
    if (typeof invoice === 'string') {
      invoice$ = this.invoiceService.retrieve(invoice);
    } else {
      invoice$ = of(invoice);
    }
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: invoice$,
    }).pipe(
      map(({ currentUser, entity }) => {
        if (currentUser.is_superuser && entity.state === 'new') {
          return new ObjectPermissionResponse(true);
        }
        if (
          currentUser.is_company_admin &&
          currentUser.company_info?.slug === CustomerHIRLSettings.companySlug &&
          entity.state === 'new'
        ) {
          return new ObjectPermissionResponse(true);
        }
        return new ObjectPermissionResponse(
          false,
          'You do not have permission to perform this action'
        );
      })
    );
  }

  canAutoPay(invoice: string | Invoice): Observable<ObjectPermissionResponse> {
    let invoice$: Observable<Invoice>;
    if (typeof invoice === 'string') {
      invoice$ = this.invoiceService.retrieve(invoice);
    } else {
      invoice$ = of(invoice);
    }
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: invoice$,
    }).pipe(
      map(({ currentUser, entity }) => {
        if (
          currentUser.is_superuser ||
          currentUser.company_info?.slug === CustomerHIRLSettings.companySlug
        ) {
          if (
            entity.total - entity.total_paid === 0 ||
            entity.state === InvoiceState.paid
          ) {
            return new ObjectPermissionResponse(false, 'Already PAID');
          }
          return new ObjectPermissionResponse(true);
        }
        return new ObjectPermissionResponse(
          false,
          'You do not have permission to perform this action'
        );
      })
    );
  }
}
