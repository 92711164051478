import { AuthGuard } from '@/core/guards';
import { CompanyListPageComponent } from '@/modules/company/pages/company-list-page/company-list-page.component';
import { CompanyDetailPageComponent } from '@/modules/company/pages/company-detail-page/company-detail-page.component';
import { componentCompanyTypeDataMap } from '@/modules/company/constants';
import { SponsorPreferencesCreatePageComponent } from '@/modules/company/pages/sponsor-preferences-create-page/sponsor-preferences-create-page.component';

const companyTypeRoutes = [];

Object.entries(componentCompanyTypeDataMap).forEach(
  ([componentType, typeMap]) => {
    companyTypeRoutes.push({
      path: `:companyType`,
      children: [
        {
          path: '',
          component: CompanyListPageComponent,
        },
        {
          path: 'detail/:companyId',
          component: CompanyDetailPageComponent,
        },
        ...typeMap.additionalRoutes,
      ],
    });
  }
);

export const CompanyRouting = [
  {
    path: 'company',
    canActivate: [AuthGuard],
    children: companyTypeRoutes,
  },
  {
    path: 'sponsor_preferences',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'create',
        component: SponsorPreferencesCreatePageComponent,
      },
    ],
  },
];
