import { ModelFormControl, ModelFormGroup } from '../ModelForm';
import { FloorCovering, LengthUnit } from '../../enumerations';
import { FieldLabels } from '../base';
import { Validators } from '@angular/forms';
import { enumValidator } from '@/modules/simulation/validators/helper';

// Labels
export const FIELD_LABELS: FieldLabels<SlabTypeBackendDict> = {
  name: 'Name',
  perimeter_insulation_r_value: 'Perimeter Insulation R-Value',
  perimeter_insulation_depth: 'Perimeter Insulation Depth',
  under_slab_insulation_r_value: 'Under Slab Insulation R-Value',
  under_slab_insulation_width: 'Under Slab Insulation Width',
  floor_covering: 'Floor Covering',
  insulation_grade: 'Insulation Grade',
  units: 'Units',
  is_radiant_heated: 'Is Radiant Heated',
  assembly_u_value: 'Assembly U-Value',
  thickness: 'Thickness',
};

export interface SlabTypeBackendDict {
  id: number | null;
  name: string;
  perimeter_insulation_r_value: number;
  perimeter_insulation_depth: number;
  under_slab_insulation_r_value: number;
  under_slab_insulation_width: number;
  floor_covering: FloorCovering;
  insulation_grade: number;
  units: LengthUnit.FT;
  is_radiant_heated: boolean;
  assembly_u_value: number;
  thickness: number;
}

export function createSlabTypeForm(
  slabType: SlabTypeBackendDict
): ModelFormGroup {
  return new ModelFormGroup({
    id: new ModelFormControl(slabType.id),
    name: new ModelFormControl(slabType.name, [
      Validators.required,
      Validators.maxLength(128),
    ]),
    perimeter_insulation_r_value: new ModelFormControl(
      slabType.perimeter_insulation_r_value,
      [Validators.required, Validators.min(0)]
    ),
    perimeter_insulation_depth: new ModelFormControl(
      slabType.perimeter_insulation_depth,
      [Validators.required, Validators.min(0)]
    ),
    under_slab_insulation_r_value: new ModelFormControl(
      slabType.under_slab_insulation_r_value,
      [Validators.required, Validators.min(0)]
    ),
    under_slab_insulation_width: new ModelFormControl(
      slabType.under_slab_insulation_width,
      [Validators.required, Validators.min(0)]
    ),
    floor_covering: new ModelFormControl(
      slabType.floor_covering,
      enumValidator(FloorCovering, true)
    ),
    insulation_grade: new ModelFormControl(slabType.insulation_grade),
    units: new ModelFormControl(slabType.units),
    is_radiant_heated: new ModelFormControl(slabType.is_radiant_heated),
    assembly_u_value: new ModelFormControl(slabType.assembly_u_value, [
      Validators.required,
      Validators.min(0),
    ]),
    thickness: new ModelFormControl(slabType.thickness, [
      Validators.required,
      Validators.min(0),
    ]),
  });
}
