import {
  DehumidifierBackendDict,
  createDehumidifierForm,
  FIELD_LABELS,
  DehumidifierTypeLabels,
  DehumidifierCapacityUnitLabels,
  DehumidifierEfficiencyUnitLabels,
} from '@/data/simulation/models/mechanicals/Dehumidifier';
import { updateDehumidifier } from '@/modules/simulation/state/dehumidifier/actions';
import { selectDehumidifierViewSet } from '@/modules/simulation/state/dehumidifier/selectors';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuelTypeLabels } from '@/data/simulation/enumerations';

@Component({
  selector: 'app-dehumidifier',
  templateUrl: './dehumidifier.component.html',
  styleUrls: ['./dehumidifier.component.scss'],
})
export class DehumidifierComponent implements OnInit {
  @Input() entityId: number;
  fieldLabels = FIELD_LABELS;

  private componentDestroyed$ = new Subject();

  loading = false;
  entity: DehumidifierBackendDict = null;
  dehumidifierFormGroup: FormGroup;
  dehumidifierFuel = FuelTypeLabels;
  dehumidifierTypeLabels = DehumidifierTypeLabels;
  dehumidifierCapacityUnitLabels = DehumidifierCapacityUnitLabels;
  dehumidifierEfficiencyUnitLabels = DehumidifierEfficiencyUnitLabels;

  constructor(public store: Store) {}

  ngOnInit() {
    this.store
      .select(selectDehumidifierViewSet(this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(({ data, loading }) => {
        this.entity = data;
        this.loading = loading;
        if (loading) return;

        if (this.dehumidifierFormGroup) {
          this.dehumidifierFormGroup.patchValue(data);
        } else {
          this.dehumidifierFormGroup = createDehumidifierForm(data);
        }
      });
  }

  onSave(changedValues: Partial<DehumidifierBackendDict>) {
    this.store.dispatch(
      updateDehumidifier({
        dehumidifierChanges: changedValues,
      })
    );
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
