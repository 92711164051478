import { BaseValidator } from './base.validator';
import { ModelErrors, StateErrors } from '../state/shared/base.state';
import {
  LayerBackendDict,
  createAssemblyLayerForm,
} from '@/data/simulation/models/enclosure/AssemblyLayer';
import { FormArray } from '@angular/forms';

export class LayerValidator extends BaseValidator {
  static validateForm(layer: LayerBackendDict): ModelErrors<LayerBackendDict> {
    const layerForm = createAssemblyLayerForm(layer);
    const errors = {};
    Object.keys(layer).forEach(key => {
      if (!layerForm.get(key)) return;
      errors[key] = layerForm.get(key).errors;
    });
    layer.materials.forEach((material, index) => {
      const materialForm = (layerForm.get('materials') as FormArray).at(index);
      Object.keys(material).forEach(key => {
        if (!materialForm.get(key)) return;
        if (materialForm.get(key).errors !== null) {
          errors['materials'] = materialForm.get(key).errors;
        }
      });
    });

    return {
      field: errors,
      model: layerForm.errors || {},
    };
  }

  static validate(layers: LayerBackendDict[]): StateErrors<LayerBackendDict> {
    const errors = [];
    layers.forEach(layer => {
      errors.push(this.validateForm(layer));
    });
    return errors;
  }
}
