import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Company, CompanyType } from '@/data/company/models';
import { Observable, Subject, Subscription } from 'rxjs';
import { ContactCard } from '@/data/core/models';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute } from '@angular/router';
import { CompanyService } from '@/data/company/services/company-base.service';
import { MatDialog } from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import {
  getCompany,
  selectCompanyContactCards,
  selectCompanyContactCardsCount,
} from '@/state/selectors/company/company.selector';
import { filter, takeUntil } from 'rxjs/operators';
import {
  ContactCardRequestParams,
  ContactCardService,
} from '@/data/core/services/contact_card';
import {
  ContactCardDialogData,
  ContactCardDialogDisplayType,
  ContactCardEditDialogComponent,
} from '@/modules/user/components/contact-card-edit-dialog/contact-card-edit-dialog.component';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import * as _ from 'lodash';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { CompanyPermissionService } from '@/modules/company/services/company-permission-service';

@Component({
  selector: 'app-company-contact-card-tab',
  templateUrl: './company-contact-card-tab.component.html',
  styleUrls: ['./company-contact-card-tab.component.scss'],
})
export class CompanyContactCardTabComponent implements OnInit, OnDestroy {
  company: Company;
  companyDisplayName: string;
  displayedColumns = ['first_name', 'title', 'phones', 'emails', 'actions'];

  filterFromGroup: FormGroup;
  isLoading = false;
  rows: ContactCard[] = [];
  rowsCount: number;

  public createPermissionResponse: Observable<ObjectPermissionResponse>;

  public defaultParams: ContactCardRequestParams = new ContactCardRequestParams(
    1,
    '',
    'first_name',
    25
  );
  public storedParams: ContactCardRequestParams = Object.assign(
    new ContactCardRequestParams(),
    this.defaultParams
  );

  private listSubscription$: Subscription;
  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private injector: Injector,
    private activatedRoute: ActivatedRoute,
    private contactCardService: ContactCardService,
    private companyService: CompanyService,
    private fb: FormBuilder,
    private companyPermissionService: CompanyPermissionService,
    private dialog: MatDialog,
    private uiHelperService: UIHelperService
  ) {}

  ngOnInit(): void {
    this.storedParams = _.cloneDeep(this.defaultParams);
    this.storedParams.page_size =
      this.uiHelperService.getUISettings().rowsPerPage;

    this.store
      .select(getCompany)
      .pipe(takeUntil(this.componentDestroyed$))
      .pipe(filter(val => val !== null))
      .subscribe(company => {
        this.company = company;
        this.createPermissionResponse =
          this.companyPermissionService.canEdit(company);
        this.list();
      });

    this.store
      .select(selectCompanyContactCards)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(contactCards => {
        this.rows = contactCards;
      });

    this.store
      .select(selectCompanyContactCardsCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(count => {
        this.rowsCount = count;
      });

    this.setupFilterForm();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  setupFilterForm() {
    this.filterFromGroup = this.fb.group({
      search: [null],
    });

    this.filterFromGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        const params = _.merge({}, this.defaultParams, values);
        delete params.ordering;

        this.storedParams = _.merge(this.storedParams, params);
        this.list();
      });
  }

  list() {
    this.isLoading = true;

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }

    this.listSubscription$ = this.companyService
      .contact_cards(this.company.id, this.storedParams)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.rows = data.results;
        this.rowsCount = data.count;
        this.isLoading = false;
      });
  }

  edit($event: MouseEvent, contactCard: ContactCard) {
    $event.preventDefault();
    let displayType = ContactCardDialogDisplayType.default;
    if (this.company.company_type === CompanyType.manufacturer) {
      displayType = ContactCardDialogDisplayType.customerHIRLManufacturer;
    }
    const dialogRef = this.dialog.open(ContactCardEditDialogComponent, {
      width: '45%',
      disableClose: true,
      data: {
        contactCard,
        displayType,
      } as ContactCardDialogData,
    });

    dialogRef.afterClosed().subscribe((result?: ContactCard) => {
      if (!result) {
        return;
      }
      this.list();
      this.uiHelperService.openSnackBar('Record Successfully Changed');
    });
  }

  onPaginateChange($event: PageEvent) {
    this.uiHelperService.getUISettings().rowsPerPage = $event.pageSize;
    this.storedParams.page_size = $event.pageSize;
    this.storedParams.page = $event.pageIndex + 1;
    this.list();
  }

  onSortChange($event: Sort) {
    this.storedParams.ordering = `${$event.direction === 'desc' ? '-' : ''}${
      $event.active
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  create($event: MouseEvent) {
    $event.preventDefault();

    let displayType = ContactCardDialogDisplayType.default;
    if (this.company.company_type === CompanyType.manufacturer) {
      displayType = ContactCardDialogDisplayType.customerHIRLManufacturer;
    }

    const dialogRef = this.dialog.open(ContactCardEditDialogComponent, {
      width: '45%',
      disableClose: true,
      data: {
        contactCard: {
          company: this.company.id,
        } as ContactCard,
        displayType,
      } as ContactCardDialogData,
    });

    dialogRef.afterClosed().subscribe((result?: ContactCard) => {
      if (!result) {
        return;
      }
      this.list();
      this.uiHelperService.openSnackBar('Record Successfully Created');
    });
  }
}
