import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { loadMenu } from '@/state/actions/menu.actions';
import { Menu } from '@/data/core/models/menu';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NavService {
  constructor(private http: HttpClient, private store: Store<AppState>) {}

  list() {
    return this.http
      .get<{ menu: Menu }>(`${environment.AXIS_API_V3_ROOT}/menu/`)
      .pipe(
        tap(loaded => {
          if (loaded) {
            this.store.dispatch(loadMenu({ payload: loaded.menu }));
          }
        })
      );
  }
}
