import { ListRequestParams } from '@/core/schemes/request-params';
import { Injectable } from '@angular/core';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { ServerResponse } from '@/core/schemes/server-response';
import { Practice } from '@/data/customer-hirl/models';

export class PracticeRequestParams extends ListRequestParams {
  section?: number;
}

@Injectable({
  providedIn: 'root',
})
export class PracticeService extends BaseDataService {
  baseUrl = `${environment.AXIS_API_V3_ROOT}/practices/`;

  list(params?: PracticeRequestParams) {
    return this.http.get<ServerResponse<Practice>>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }

  retrieve(
    practiceId: number,
    options?: IDataServiceMethodOptions
  ): Observable<Practice> {
    return this.http.get<Practice>(`${this.baseUrl}${practiceId}`, {});
  }

  update(practice: Practice, practiceId: number) {
    return this.http.patch<Practice>(`${this.baseUrl}${practiceId}/`, practice);
  }

  delete(practiceId: number) {
    return this.http.delete(`${this.baseUrl}${practiceId}`);
  }
}
