import {
  Component,
  Injectable,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { User } from '@/data/core/models/user';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  UntypedFormArray,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  HIRLGreenEnergyBadge,
  HIRLProjectAppealsProjectTypeMapping,
  HIRLProjectCommercialSpaceTypeLabelMapping,
  HIRLProjectRegistration,
  HIRLProjectRegistrationCertificateLineChoices,
  HIRLProjectRegistrationState,
  HIRLProjectSampling,
} from '@/data/customer-hirl/models';
import { EEPProgram } from '@/data/eep-program/models';
import { EMPTY, forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { ServerResponse } from '@/core/schemes/server-response';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { HIRLProjectService } from '@/data/customer-hirl/services/hirl-projects.service';
import { HIRLProjectRegistrationService } from '@/data/customer-hirl/services/hirl-project-registration.service';
import {
  EEPProgramRequestParams,
  EEPProgramService,
} from '@/data/eep-program/services/eep-program.service';
import { HIRLGreenEnergyBadgeService } from '@/data/customer-hirl/services/hirl-green-energy-badge.service';
import { MatDialog } from '@angular/material/dialog';
import { GeocoderService } from '@/data/geocoder/services/geocoder.service';
import { UserService } from '@/data/core/services/user.service';
import { toggleLoading } from '@/state/actions/app.actions';
import { getInfoUser } from '@/state/selectors/info.selector';
import {
  catchError,
  debounceTime,
  defaultIfEmpty,
  distinctUntilChanged,
  filter,
  first,
  map,
  pairwise,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { conditionalValidator, objectValidator } from '@/core/validators';
import {
  ServerErrorDialogComponent,
  ServerErrorDialogConfig,
} from '@/shared/components/server-error-dialog/server-error-dialog.component';
import { formatDate } from '@angular/common';
import {
  CreateMFHIRLProject,
  CreateMFHIRLProjectRegistration,
} from '@/data/customer-hirl/models/hirl-project-registration';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatStepper, MatStepperIntl } from '@angular/material/stepper';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';
import {
  QaStatusRequestParams,
  QaStatusService,
} from '@/data/qa/services/qa-status.service';
import {
  CompanyRequestParams,
  CompanyService,
} from '@/data/company/services/company-base.service';
import { Company, CompanyType } from '@/data/company/models';
import { AppFieldValidatorsService } from '@/core/services/field-validators.service';
import * as moment from 'moment';
import { HIRLProjectAppealsProjectType } from '@/data/customer-hirl/models/hirl-project';
import { IGeocodeMatches } from '@/data/geocoder/models';

@Injectable()
export class HIRLProjectRegistrationMFStepper extends MatStepperIntl {
  optionalLabel = '';
}

@Component({
  selector: 'app-hirl-project-registration-mf-form',
  templateUrl: './hirl-project-registration-mf-form.component.html',
  styleUrls: ['./hirl-project-registration-mf-form.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {
        showError: true,
      },
    },
    { provide: MatStepperIntl, useClass: HIRLProjectRegistrationMFStepper },
  ],
})
export class HIRLProjectRegistrationMFFormComponent
  implements OnInit, OnDestroy
{
  protected readonly HIRLProjectAppealsProjectTypeMapping =
    HIRLProjectAppealsProjectTypeMapping;

  @ViewChild('stepper') private stepper: MatStepper;

  public initialized = false;
  public editForm = false;
  public currentUser: User;

  public estimatedCompletionDateMinDate?: Date;

  public projectInformationFormGroup: FormGroup;
  public financialFormGroup: FormGroup;
  public certificateNamesFormGroup: FormGroup;
  public builderFormGroup: FormGroup;
  public developerFormGroup: FormGroup;
  public architectFormGroup: FormGroup;
  public communityOwnerFormGroup: FormGroup;
  public generalContractorFormGroup: FormGroup;
  public marketingFormGroup: FormGroup;
  public accessoryStructureFormGroup: FormGroup;
  public standAloneCommercialSpaceFormGroup: FormGroup;
  public buildingFormGroup: FormGroup;
  public buildingFormControlSubscriptions: Map<
    AbstractControl,
    Subscription[]
  > = new Map();

  public hirlProjectRegistration?: HIRLProjectRegistration;

  public hirlGreenEnergyBadges: HIRLGreenEnergyBadge[] = [];
  public eepPrograms: EEPProgram[];
  public selectedEEPProgram: EEPProgram;
  public qaCount: number;

  public bannedEEPProgramSlugs: string[] = [];

  public filteredBuilderOrganizations$: Observable<ServerResponse<Company>>;
  public filteredArchitectOrganizations$: Observable<ServerResponse<Company>>;
  public filteredDeveloperOrganizations$: Observable<ServerResponse<Company>>;
  public filteredGeneralContractorOrganizations$: Observable<
    ServerResponse<Company>
  >;
  public filteredCommunityOwnerOrganizations$: Observable<
    ServerResponse<Company>
  >;

  public HIRLProjectRegistrationState = HIRLProjectRegistrationState;
  public projectCommercialSpaceTypeLabelMapping =
    HIRLProjectCommercialSpaceTypeLabelMapping;
  public samplingMapping = {
    [HIRLProjectSampling.no_sampling]: 'No Sampling',
    [HIRLProjectSampling.testing_and_practices_only]:
      'For Energy efficiency testing practices only (both Rough and Final)',
    [HIRLProjectSampling.rough_testing_and_practices_only]:
      'For Energy efficiency testing practices only (Rough only)',
    [HIRLProjectSampling.final_testing_and_practices_only]:
      'For Energy efficiency testing practices only (Final only)',
    [HIRLProjectSampling.all]:
      'For All or most NGBS practices (both Rough and Final – available only for NGBS Green Master Verifiers)',
    [HIRLProjectSampling.rough_all]:
      'For All or most NGBS practices (Rough only – available only for NGBS Green Master Verifiers)',
    [HIRLProjectSampling.final_all]:
      'For All or most NGBS practices (Final only – available only for NGBS Green Master Verifiers)',
  };

  public certificateLineChoices = HIRLProjectRegistrationCertificateLineChoices;
  public certificateLineChoicesMapping = {
    [HIRLProjectRegistrationCertificateLineChoices.emptyLine]: 'Empty Line',
    [HIRLProjectRegistrationCertificateLineChoices.developmentName]:
      'Subdivision/Development Name',
    [HIRLProjectRegistrationCertificateLineChoices.addressLineDouble]:
      'Address (2 lines)',
    [HIRLProjectRegistrationCertificateLineChoices.clientCompanyName]:
      'Client Company Name',
    [HIRLProjectRegistrationCertificateLineChoices.hudDisasterCaseNumber]:
      'HUD Disaster Case Number',
    [HIRLProjectRegistrationCertificateLineChoices.customText]:
      'Custom Text (black font)',
    [HIRLProjectRegistrationCertificateLineChoices.customTextGreen]:
      'Custom Text (green font)',
  };

  // this key is using to save incomplete form data on create and restore it for next visit
  public localStorageFormDataSaveKey = 'project-registration-mf-form';

  public customerHIRLSettings = CustomerHIRLSettings;

  private destroy$ = new Subject();
  private editFormData: HIRLProjectRegistration = new HIRLProjectRegistration();

  constructor(
    public fb: FormBuilder,
    public store: Store<AppState>,
    public router: Router,
    public hirlProjectService: HIRLProjectService,
    public hirlProjectRegistrationService: HIRLProjectRegistrationService,
    public companyService: CompanyService,
    public eepProgramService: EEPProgramService,
    public hirlGreenEnergyBadgeService: HIRLGreenEnergyBadgeService,
    public dialog: MatDialog,
    public activatedRoute: ActivatedRoute,
    public geocoderService: GeocoderService,
    public userService: UserService,
    public qaStatusService: QaStatusService,
    public appFieldValidatorsService: AppFieldValidatorsService
  ) {
    this.estimatedCompletionDateMinDate = moment().toDate();
    if (this.activatedRoute.snapshot.params.projectRegistrationId) {
      this.editForm = true;
    }
  }

  public ngOnInit(): void {
    this.store.dispatch(toggleLoading({ payload: true }));

    this.store
      .select(getInfoUser)
      .pipe(first())
      .subscribe(currentUser => {
        this.currentUser = currentUser;
        const eepProgramRequestParams = new EEPProgramRequestParams();
        eepProgramRequestParams.ordering = 'name';
        eepProgramRequestParams.set = 'hirl_project_mf_programs';
        eepProgramRequestParams.includes_older_versions = false;
        const sources: { [k: string]: any } = {
          eepPrograms: this.eepProgramService
            .hirl_project_programs(eepProgramRequestParams)
            .pipe(first()),
        };

        if (this.editForm) {
          sources.hirlProjectRegistration = this.hirlProjectRegistrationService
            .retrieve(this.activatedRoute.snapshot.params.projectRegistrationId)
            .pipe(first());
          sources.qaCount = this.qaStatusService
            .list(
              Object.assign(new QaStatusRequestParams(), {
                home_status__customer_hirl_project__registration:
                  this.activatedRoute.snapshot.params.projectRegistrationId,
              })
            )
            .pipe(
              first(),
              map(response => response.count)
            );
        }

        if (this.editForm) {
          if (
            (currentUser.is_company_admin &&
              currentUser.company_info?.slug ===
                CustomerHIRLSettings.companySlug) ||
            currentUser.is_superuser
          ) {
            eepProgramRequestParams.includes_older_versions = true;
            sources.eepPrograms = this.eepProgramService
              .hirl_project_programs(eepProgramRequestParams)
              .pipe(first());
          }
        }

        forkJoin(sources).subscribe(
          ({ eepPrograms, hirlProjectRegistration, qaCount }) => {
            this.eepPrograms = eepPrograms;
            this.hirlProjectRegistration = hirlProjectRegistration;
            this.qaCount = qaCount;

            // remove 2012-2015 from list to prevent create NEW projects with this programs
            this.eepPrograms = this.eepPrograms.filter(eepProgram => {
              if (this.editForm) {
                return (
                  !this.bannedEEPProgramSlugs.includes(eepProgram.slug) ||
                  this.hirlProjectRegistration?.eep_program_info?.slug ===
                    eepProgram.slug
                );
              }
              return !this.bannedEEPProgramSlugs.includes(eepProgram.slug);
            });

            if (this.editForm) {
              this.hydrateForm();
              this.store.dispatch(toggleLoading({ payload: false }));
              this.initialized = true;
            } else {
              this.store.dispatch(toggleLoading({ payload: false }));
              this.initialized = true;
              this.loadPreviousFormData();
            }
          }
        );
      });

    if (this.editForm) {
      this.setupProjectInformationFormGroup();
      this.setupFinancialFormGroup();
      this.setupCertificateNamesFormGroup();
      this.setupBuilderFormGroup();
      this.setupMarketingFormGroup();
      this.setupDeveloperFormGroup();
      this.setupArchitectFormGroup();
      this.setupCommunityOwnerFormGroup();
      this.setupGeneralContractorFormGroup();
    } else {
      this.setupProjectInformationFormGroup();
      this.setupFinancialFormGroup();
      this.setupCertificateNamesFormGroup();
      this.setupBuilderFormGroup();
      this.setupMarketingFormGroup();
      this.setupAccessoryStructureFormGroup();
      this.setupStandAloneCommercialSpaceFormGroup();
      this.setupBuildingFormGroup();
      this.setupDeveloperFormGroup();
      this.setupArchitectFormGroup();
      this.setupCommunityOwnerFormGroup();
      this.setupGeneralContractorFormGroup();
    }
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadPreviousFormData() {
    let formData;
    try {
      formData = JSON.parse(
        localStorage.getItem(this.localStorageFormDataSaveKey)
      );
    } catch (e) {
      return;
    }

    if (!formData) {
      return;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      disableClose: true,
      data: {
        title: 'Welcome Back !',
        content:
          'Looks like you did not complete the form last time. ' +
          "Click 'Confirm' to start where you left off or click 'Cancel' to start again",
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      this.projectInformationFormGroup.patchValue(formData, {
        emitEvent: true,
        onlySelf: true,
      });
      this.financialFormGroup.patchValue(formData, {
        emitEvent: true,
        onlySelf: true,
      });
      this.certificateNamesFormGroup.patchValue(formData, {
        emitEvent: true,
        onlySelf: true,
      });
      this.builderFormGroup.patchValue(formData, {
        emitEvent: true,
        onlySelf: true,
      });
      this.developerFormGroup.patchValue(formData, {
        emitEvent: true,
        onlySelf: true,
      });
      this.architectFormGroup.patchValue(formData, {
        emitEvent: true,
        onlySelf: true,
      });
      this.communityOwnerFormGroup.patchValue(formData, {
        emitEvent: true,
        onlySelf: true,
      });
      this.generalContractorFormGroup.patchValue(formData, {
        emitEvent: true,
        onlySelf: true,
      });
      this.accessoryStructureFormGroup.patchValue(formData, {
        emitEvent: true,
        onlySelf: true,
      });
    });
  }

  onStepChange(event: any) {
    if (this.editForm) {
      return;
    }
    const formData = {
      ...this.projectInformationFormGroup.value,
      ...this.financialFormGroup.value,
      ...this.certificateNamesFormGroup.value,
      ...this.builderFormGroup.value,
      ...this.developerFormGroup.value,
      ...this.architectFormGroup.value,
      ...this.communityOwnerFormGroup.value,
      ...this.generalContractorFormGroup.value,
      ...this.accessoryStructureFormGroup.value,
      ...this.buildingFormGroup.value,
    };

    localStorage.setItem(
      this.localStorageFormDataSaveKey,
      JSON.stringify(formData)
    );
  }

  public setupProjectInformationFormGroup() {
    this.projectInformationFormGroup = this.fb.group({
      eep_program: [null, Validators.required],
      project_name: ['', Validators.required],
      estimated_completion_date: [null, Validators.required],
    });

    // skip first reset if this is edit form
    let skipFirstReset = this.editForm;

    this.projectInformationFormGroup
      .get('eep_program')
      .valueChanges.subscribe(eepProgramId => {
        // filter and reset Green Energy Badges
        this.store.dispatch(toggleLoading({ payload: true }));
        this.hirlGreenEnergyBadgeService
          .list(
            Object.assign(new CompanyRequestParams(), {
              eep_program: eepProgramId,
            })
          )
          .pipe(first())
          .subscribe(data => {
            this.hirlGreenEnergyBadges = data.results;

            this.selectedEEPProgram = this.eepPrograms.filter(
              eepProgram => eepProgram.id === eepProgramId
            )[0];

            // reset green_energy_badges after update
            if (skipFirstReset) {
              skipFirstReset = false;
            } else {
              if (!this.editForm) {
                const formArray = this.buildingFormGroup.controls
                  .buildings as UntypedFormArray;
                for (let i = 0; i < formArray.length; i++) {
                  formArray.controls[i]
                    .get('green_energy_badges')
                    .patchValue([]);
                  formArray.controls[i]
                    .get('is_require_rough_inspection')
                    .patchValue(true);
                  formArray.controls[i]
                    .get('is_require_water_sense_certification')
                    .patchValue(false);
                }
              }
            }
            this.store.dispatch(toggleLoading({ payload: false }));
          });
      });
  }

  public setupBuilderFormGroup() {
    this.builderFormGroup = this.fb.group({
      builder_organization_info: [
        null,
        Validators.compose([Validators.required, objectValidator()]),
      ],
      builder_organization_contact: [null, [Validators.required]],
      billing_email: [null, Validators.email],
      is_coi_held_by_different_company: [false],
      coi_held_by_different_company: [
        null,
        conditionalValidator(
          () =>
            this.builderFormGroup.get('is_coi_held_by_different_company').value,
          Validators.required
        ),
      ],
    });

    this.builderFormGroup
      .get('is_coi_held_by_different_company')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        if (!value) {
          this.builderFormGroup
            .get('coi_held_by_different_company')
            .patchValue('', { emitEvent: true, onlySelf: true });
        }
      });

    // builder organization autocomplete
    this.filteredBuilderOrganizations$ = this.builderFormGroup
      .get('builder_organization_info')
      .valueChanges.pipe(
        startWith(''),
        debounceTime(100),
        distinctUntilChanged(),
        switchMap(value =>
          this.companyService
            .list(
              Object.assign(new CompanyRequestParams(), {
                search: value,
                is_attached: 'attached',
                page_size: 300,
                company_type: CompanyType.builder,
              })
            )
            .pipe(catchError(err => EMPTY))
        )
      );
  }

  public setupGeneralContractorFormGroup() {
    this.generalContractorFormGroup = this.fb.group({
      legacy_builder_organization_info: [null],
      legacy_builder_organization_contact: [null],
    });
    // developer organization autocomplete
    this.filteredGeneralContractorOrganizations$ =
      this.generalContractorFormGroup
        .get('legacy_builder_organization_info')
        .valueChanges.pipe(
          startWith(''),
          debounceTime(100),
          distinctUntilChanged(),
          filter(x => typeof x === 'string'),
          switchMap(value => {
            return this.companyService.list(
              Object.assign(new CompanyRequestParams(), {
                search: value,
                is_attached: 'attached',
                page_size: 300,
                company_type: CompanyType.builder,
              })
            );
          })
        );
  }

  public setupDeveloperFormGroup() {
    this.developerFormGroup = this.fb.group({
      developer_organization_info: [null],
      developer_organization_contact: [null],
    });
    // developer organization autocomplete
    this.filteredDeveloperOrganizations$ = this.developerFormGroup
      .get('developer_organization_info')
      .valueChanges.pipe(
        startWith(''),
        debounceTime(100),
        distinctUntilChanged(),
        filter(x => typeof x === 'string'),
        switchMap(value => {
          return this.companyService.list(
            Object.assign(new CompanyRequestParams(), {
              search: value,
              is_attached: 'attached',
              page_size: 300,
              company_type: CompanyType.builder,
            })
          );
        })
      );
  }

  public setupArchitectFormGroup() {
    this.architectFormGroup = this.fb.group({
      architect_organization_info: [null],
      architect_organization_contact: [null],
    });
    // architect organization autocomplete
    this.filteredArchitectOrganizations$ = this.architectFormGroup
      .get('architect_organization_info')
      .valueChanges.pipe(
        startWith(''),
        debounceTime(100),
        distinctUntilChanged(),
        filter(x => typeof x === 'string'),
        switchMap(value => {
          return this.companyService.list(
            Object.assign(new CompanyRequestParams(), {
              search: value,
              is_attached: 'attached',
              page_size: 300,
              company_type: CompanyType.builder,
            })
          );
        })
      );
  }

  public setupCommunityOwnerFormGroup() {
    this.communityOwnerFormGroup = this.fb.group({
      community_owner_organization_info: [null],
      community_owner_organization_contact: [null],
    });
    // community owner organization autocomplete
    this.filteredCommunityOwnerOrganizations$ = this.communityOwnerFormGroup
      .get('community_owner_organization_info')
      .valueChanges.pipe(
        startWith(''),
        debounceTime(100),
        distinctUntilChanged(),
        filter(x => typeof x === 'string'),
        switchMap(value => {
          return this.companyService.list(
            Object.assign(new CompanyRequestParams(), {
              search: value,
              is_attached: 'attached',
              page_size: 300,
              company_type: CompanyType.builder,
            })
          );
        })
      );
  }

  public setupBuildingFormGroup() {
    if (!this.editForm) {
      this.buildingFormGroup = this.fb.group({
        buildings: this.fb.array([]),
      });
    }
  }

  public setupFinancialFormGroup() {
    this.financialFormGroup = this.fb.group({
      building_will_include_non_residential_space: [false],
      seeking_hud_mortgage_insurance_premium_reduction: [false],
      seeking_hud_green_and_resilient_retrofit_program: [false],
      seeking_fannie_mae_green_financing: [false],
      seeking_freddie_mac_green_financing: [false],
      intended_to_be_affordable_housing: [false],
    });
  }

  public setupCertificateNamesFormGroup() {
    this.certificateNamesFormGroup = this.fb.group(
      {
        certificate_line1: [
          HIRLProjectRegistrationCertificateLineChoices.emptyLine,
        ],
        certificate_line1_custom_text: [''],
        certificate_line2: [
          HIRLProjectRegistrationCertificateLineChoices.developmentName,
        ],
        certificate_line2_custom_text: [''],
        certificate_line3: [
          HIRLProjectRegistrationCertificateLineChoices.addressLineDouble,
        ],
        certificate_line3_custom_text: [''],
        certificate_line4: [
          HIRLProjectRegistrationCertificateLineChoices.emptyLine,
        ],
        certificate_line4_custom_text: [''],
        certificate_line5: [
          HIRLProjectRegistrationCertificateLineChoices.hudDisasterCaseNumber,
        ],
        certificate_line5_custom_text: [''],
        certificate_line6: [
          HIRLProjectRegistrationCertificateLineChoices.clientCompanyName,
        ],
        certificate_line6_custom_text: [''],
        certificate_line7: [
          HIRLProjectRegistrationCertificateLineChoices.emptyLine,
        ],
        certificate_line7_custom_text: [''],
        certificate_line8: [
          HIRLProjectRegistrationCertificateLineChoices.emptyLine,
        ],
        certificate_line8_custom_text: [''],
        certificate_line9: [
          HIRLProjectRegistrationCertificateLineChoices.emptyLine,
        ],
        certificate_line9_custom_text: [''],
      },
      { validator: this.addressValidator }
    );
  }

  addressValidator(group: UntypedFormGroup): { [key: string]: any } | null {
    const fields = [
      'certificate_line1',
      'certificate_line2',
      'certificate_line3',
      'certificate_line4',
      'certificate_line5',
      'certificate_line6',
      'certificate_line7',
      'certificate_line8',
      'certificate_line9',
    ];

    const customTextFields = [
      'certificate_line1_custom_text',
      'certificate_line2_custom_text',
      'certificate_line3_custom_text',
      'certificate_line4_custom_text',
      'certificate_line5_custom_text',
      'certificate_line6_custom_text',
      'certificate_line7_custom_text',
      'certificate_line8_custom_text',
      'certificate_line9_custom_text',
    ];

    const hasAddress = fields.some((fieldName, index) => {
      const field = group.get(fieldName);
      const customTextField = group.get(customTextFields[index]);
      return (
        (field &&
          field.value ===
            HIRLProjectRegistrationCertificateLineChoices.addressLineDouble) ||
        (customTextField &&
          customTextField.value.toLowerCase().includes('address'))
      );
    });

    return hasAddress ? null : { addressRequired: true };
  }

  public setupMarketingFormGroup() {
    this.marketingFormGroup = this.fb.group({
      marketing_first_name: [''],
      marketing_last_name: [''],
      marketing_email: ['', Validators.email],
      marketing_phone: [''],
      project_description: [''],
      project_website_url: [
        '',
        this.appFieldValidatorsService.websiteURLValidator(),
      ],
    });
  }

  public setupAccessoryStructureFormGroup() {
    if (!this.editForm) {
      this.accessoryStructureFormGroup = this.fb.group({
        buildings: this.fb.array([]),
      });
    }
  }

  public addAccessoryStructure(): void {
    const control = this.accessoryStructureFormGroup.controls
      .buildings as FormArray;

    const group = this.fb.group({
      accessory_structure_address: [null, Validators.required],
      accessory_structure_description: ['', Validators.required],
    });

    this.attachFieldCheckHomeWithAddressAlreadyExists(
      group,
      'accessory_structure_address',
      true
    );
    control.push(group);
  }

  public handleRemoveAccessoryStructure(event, index: number): void {
    event.preventDefault();
    const control = this.accessoryStructureFormGroup.controls
      .buildings as UntypedFormArray;
    control.removeAt(index);
  }

  public setupStandAloneCommercialSpaceFormGroup() {
    if (!this.editForm) {
      this.standAloneCommercialSpaceFormGroup = this.fb.group({
        buildings: this.fb.array([]),
      });
    }
  }

  public onAddStandAloneCommercialSpace($event: MouseEvent): void {
    $event.preventDefault();
    const control = this.standAloneCommercialSpaceFormGroup.controls
      .buildings as FormArray;

    const group = this.fb.group({
      stand_alone_commercial_space_address: [null, Validators.required],
      commercial_space_type: [null, Validators.required],
      total_commercial_space: [null, Validators.required],
    });

    this.attachFieldCheckHomeWithAddressAlreadyExists(
      group,
      'stand_alone_commercial_space_address',
      true
    );
    control.push(group);
  }

  public onRemoveStandAloneCommercialSpace(
    $event: MouseEvent,
    index: number
  ): void {
    $event.preventDefault();
    const control = this.standAloneCommercialSpaceFormGroup.controls
      .buildings as FormArray;
    control.removeAt(index);
  }

  public getAddressFromLastBuildingAddressForm() {
    if (
      !this.buildingFormGroup.value ||
      !this.buildingFormGroup.value.buildings ||
      this.buildingFormGroup.value.buildings.length === 0
    ) {
      return null;
    }
    const formValue =
      this.buildingFormGroup.value.buildings[
        this.buildingFormGroup.value.buildings.length - 1
      ];
    return formValue.address;
  }

  public addBuilding(): void {
    const control = this.buildingFormGroup.controls.buildings as FormArray;
    const group = this.fb.group({
      building_number: [null, Validators.required],
      address: [
        this.getAddressFromLastBuildingAddressForm(),
        [
          Validators.required,
          this.uniqueAddressValidator('address', control.length),
        ],
      ],
      number_of_units: [
        null as number | null,
        Validators.compose([
          Validators.required,
          Validators.min(2),
          Validators.max(1000000),
        ]),
      ],
      story_count: [
        null as number | null,
        [Validators.required, Validators.min(1), Validators.max(1000000)],
      ],
      commercial_spaces: this.fb.array([]),
      hud_disaster_case_number: [''],
      green_energy_badges: [[]],
      appeals_project: [HIRLProjectAppealsProjectType.no, Validators.required],
      is_require_water_sense_certification: [false],
      is_require_rough_inspection: [true],
      is_require_wri_certification: [false],
      sampling: [HIRLProjectSampling.no_sampling],
      is_townhouse: [false],
    });

    const subscriptions: Subscription[] = [];
    let subscription: Subscription =
      this.attachFieldCheckHomeWithAddressAlreadyExists(group, 'address', true);
    // Store the subscription in the map using the control as the key
    subscriptions.push(subscription);

    subscription = group
      .get('is_townhouse')
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe({
        next: is_townhouse => {
          if (is_townhouse) {
            group.get('story_count').patchValue(4);
            group.get('number_of_units').patchValue(2);
            group.get('story_count').disable();
            group.get('number_of_units').disable();
          } else {
            group.get('story_count').enable();
            group.get('number_of_units').enable();
          }
        },
      });
    subscriptions.push(subscription);
    // Store the subscription in the map using the control as the key
    this.buildingFormControlSubscriptions.set(group, subscriptions);
    control.push(group);
  }

  public handleRemoveBuilding(event, index: number): void {
    event.preventDefault();
    const buildings = this.buildingFormGroup.controls
      .buildings as UntypedFormArray;

    const formGroup = buildings.controls[index];

    // Unsubscribe from the removed control's valueChanges
    const removedSubscriptions: Subscription[] =
      this.buildingFormControlSubscriptions.get(formGroup);
    if (removedSubscriptions) {
      removedSubscriptions.forEach(subscription => subscription.unsubscribe());
      this.buildingFormControlSubscriptions.delete(formGroup);
    }

    buildings.removeAt(index);
  }

  public addCommercialSpace(index: number): void {
    const buildingControl = this.buildingFormGroup.controls
      .buildings as UntypedFormArray;
    const control = (buildingControl.at(index) as UntypedFormGroup).controls
      .commercial_spaces as UntypedFormArray;

    const group = this.fb.group({
      commercial_space_type: [null, Validators.required],
      commercial_space_street_line_2: ['', Validators.required],
      total_commercial_space: [null, Validators.required],
    });

    control.push(group);
  }

  public handleRemoveCommercialSpace(
    event,
    buildingIndex: number,
    controlIndex: number
  ): void {
    event.preventDefault();
    const buildingControl = this.buildingFormGroup.controls
      .buildings as UntypedFormArray;
    const control = (buildingControl.at(buildingIndex) as UntypedFormGroup)
      .controls.commercial_spaces as UntypedFormArray;
    control.removeAt(controlIndex);
  }

  public getCommercialSpaceControls(buildingControl: AbstractControl) {
    return (buildingControl.get('commercial_spaces') as UntypedFormArray)
      .controls;
  }

  public attachFieldCheckHomeWithAddressAlreadyExists(
    formGroup,
    fieldName,
    isMultiFamily
  ): Subscription {
    return formGroup
      .get(fieldName)
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(confirmedGeocodeResponse => {
        if (!confirmedGeocodeResponse) {
          return;
        }
        this.store.dispatch(toggleLoading({ payload: true }));
        this.hirlProjectService
          .homeAddressIsUnique({
            project: null,
            street_line1: confirmedGeocodeResponse.addressInfo.street_line1,
            street_line2: confirmedGeocodeResponse.addressInfo.street_line2,
            city: confirmedGeocodeResponse.addressInfo.city_info.id,
            zipcode: confirmedGeocodeResponse.addressInfo.zipcode,
            is_multi_family: isMultiFamily,
            geocode_response: confirmedGeocodeResponse.geocodeBrokeredResponse
              ? confirmedGeocodeResponse.geocodeBrokeredResponse.id
              : null,
          })
          .pipe(first())
          .subscribe(isUnique => {
            this.store.dispatch(toggleLoading({ payload: false }));
            if (isUnique) {
              formGroup
                .get(fieldName)
                .setErrors({ home_with_this_address_already_exists: null });
              formGroup.get(fieldName).updateValueAndValidity({
                emitEvent: false,
              });
            } else {
              formGroup
                .get(fieldName)
                .setErrors({ home_with_this_address_already_exists: true });
            }
          });
      });
  }

  public uniqueAddressValidator(fieldName, index): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let isValid = true;
      const formArray =
        control.parent && control.parent.parent
          ? (control.parent.parent as UntypedFormArray)
          : null;

      if (formArray) {
        formArray.controls.forEach((existingControl, i) => {
          if (index !== i) {
            if (
              existingControl.value &&
              existingControl.value[fieldName] &&
              control.value
            ) {
              if (
                existingControl.value[fieldName].addressInfo.isEqual(
                  control.value.addressInfo
                )
              ) {
                isValid = false;
                return;
              }
            }
          }
        });
      }
      if (!isValid) {
        return {
          home_with_this_address_already_exists: true,
        };
      }

      return null;
    };
  }

  commercialSpaceFormData(
    geocode,
    buildingData,
    commercialSpaceData
  ): CreateMFHIRLProject {
    const projectFormData = new CreateMFHIRLProject();
    projectFormData.is_accessory_structure = false;
    projectFormData.accessory_structure_description = '';

    projectFormData.lot_number = buildingData.address.lotNumber;
    projectFormData.home_address_geocode = geocode.id;
    projectFormData.home_address_geocode_response = null;

    projectFormData.is_include_commercial_space = true;
    projectFormData.commercial_space_type =
      commercialSpaceData.commercial_space_type;
    projectFormData.total_commercial_space =
      commercialSpaceData.total_commercial_space;
    projectFormData.story_count = 1;
    projectFormData.number_of_units = 1;
    projectFormData.green_energy_badges = [];
    projectFormData.building_number = buildingData.building_number;
    projectFormData.is_require_water_sense_certification =
      buildingData.is_require_water_sense_certification;
    projectFormData.is_require_rough_inspection =
      buildingData.is_require_rough_inspection;

    return projectFormData;
  }

  handleCreate(event) {
    event.preventDefault();
    // Select invalid step for User and mark stepper step as red by selecting current form
    this.stepper.steps.forEach((step, index) => {
      step.completed = true;
      if (step.stepControl && step.stepControl.invalid) {
        this.stepper.selectedIndex = index;
      }
    });

    this.projectInformationFormGroup.markAllAsTouched();
    this.financialFormGroup.markAllAsTouched();
    this.certificateNamesFormGroup.markAllAsTouched();
    this.builderFormGroup.markAllAsTouched();
    this.marketingFormGroup.markAllAsTouched();
    this.accessoryStructureFormGroup.markAllAsTouched();
    this.buildingFormGroup.markAllAsTouched();

    if (
      this.projectInformationFormGroup.invalid ||
      this.financialFormGroup.invalid ||
      this.certificateNamesFormGroup.invalid ||
      this.builderFormGroup.invalid ||
      this.marketingFormGroup.invalid ||
      this.accessoryStructureFormGroup.invalid ||
      this.buildingFormGroup.invalid
    ) {
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const commercialSpaceGeocodes = [];

    const formData: CreateMFHIRLProjectRegistration =
      new CreateMFHIRLProjectRegistration();

    formData.eep_program = this.projectInformationFormGroup.value.eep_program;
    formData.project_name = this.projectInformationFormGroup.value.project_name;
    formData.estimated_completion_date = `${formatDate(
      this.projectInformationFormGroup.value.estimated_completion_date,
      'yyyy-MM-dd',
      'en-US'
    )}`;

    formData.building_will_include_non_residential_space =
      this.financialFormGroup.value.building_will_include_non_residential_space;
    formData.seeking_hud_mortgage_insurance_premium_reduction =
      this.financialFormGroup.value.seeking_hud_mortgage_insurance_premium_reduction;
    formData.seeking_hud_green_and_resilient_retrofit_program =
      this.financialFormGroup.value.seeking_hud_green_and_resilient_retrofit_program;
    formData.seeking_fannie_mae_green_financing =
      this.financialFormGroup.value.seeking_fannie_mae_green_financing;
    formData.seeking_freddie_mac_green_financing =
      this.financialFormGroup.value.seeking_freddie_mac_green_financing;
    formData.intended_to_be_affordable_housing =
      this.financialFormGroup.value.intended_to_be_affordable_housing;

    formData.builder_organization =
      this.builderFormGroup.value.builder_organization_info?.id;
    formData.builder_organization_contact =
      this.builderFormGroup.value.builder_organization_contact;
    formData.billing_email = this.builderFormGroup.value.billing_email;
    formData.coi_held_by_different_company =
      this.builderFormGroup.value.coi_held_by_different_company || '';

    formData.marketing_first_name =
      this.marketingFormGroup.value.marketing_first_name;
    formData.marketing_last_name =
      this.marketingFormGroup.value.marketing_last_name;
    formData.marketing_email = this.marketingFormGroup.value.marketing_email;
    formData.marketing_phone = this.marketingFormGroup.value.marketing_phone;
    formData.project_description =
      this.marketingFormGroup.value.project_description;
    formData.project_website_url =
      this.marketingFormGroup.value.project_website_url;

    formData.architect_organization =
      this.architectFormGroup.value.architect_organization_info?.id;
    formData.architect_organization_contact =
      this.architectFormGroup.value.architect_organization_contact;

    formData.developer_organization =
      this.developerFormGroup.value.developer_organization_info?.id;
    formData.developer_organization_contact =
      this.developerFormGroup.value.developer_organization_contact;

    formData.community_owner_organization =
      this.communityOwnerFormGroup.value.community_owner_organization_info?.id;
    formData.community_owner_organization_contact =
      this.communityOwnerFormGroup.value.community_owner_organization_contact;

    formData.legacy_builder_organization =
      this.generalContractorFormGroup.value.legacy_builder_organization_info?.id;
    formData.legacy_builder_organization_contact =
      this.generalContractorFormGroup.value.legacy_builder_organization_contact;

    formData.certificate_line1 =
      this.certificateNamesFormGroup.value.certificate_line1;
    formData.certificate_line1_custom_text =
      this.certificateNamesFormGroup.value.certificate_line1_custom_text;
    formData.certificate_line2 =
      this.certificateNamesFormGroup.value.certificate_line2;
    formData.certificate_line2_custom_text =
      this.certificateNamesFormGroup.value.certificate_line2_custom_text;
    formData.certificate_line3 =
      this.certificateNamesFormGroup.value.certificate_line3;
    formData.certificate_line3_custom_text =
      this.certificateNamesFormGroup.value.certificate_line3_custom_text;
    formData.certificate_line4 =
      this.certificateNamesFormGroup.value.certificate_line4;
    formData.certificate_line4_custom_text =
      this.certificateNamesFormGroup.value.certificate_line4_custom_text;
    formData.certificate_line5 =
      this.certificateNamesFormGroup.value.certificate_line5;
    formData.certificate_line5_custom_text =
      this.certificateNamesFormGroup.value.certificate_line5_custom_text;
    formData.certificate_line6 =
      this.certificateNamesFormGroup.value.certificate_line6;
    formData.certificate_line6_custom_text =
      this.certificateNamesFormGroup.value.certificate_line6_custom_text;
    formData.certificate_line7 =
      this.certificateNamesFormGroup.value.certificate_line7;
    formData.certificate_line7_custom_text =
      this.certificateNamesFormGroup.value.certificate_line7_custom_text;
    formData.certificate_line8 =
      this.certificateNamesFormGroup.value.certificate_line8;
    formData.certificate_line8_custom_text =
      this.certificateNamesFormGroup.value.certificate_line8_custom_text;
    formData.certificate_line9 =
      this.certificateNamesFormGroup.value.certificate_line9;
    formData.certificate_line9_custom_text =
      this.certificateNamesFormGroup.value.certificate_line9_custom_text;

    for (const buildingData of this.buildingFormGroup.controls.buildings
      .value) {
      const projectFormData = new CreateMFHIRLProject();

      projectFormData.is_accessory_structure = false;
      projectFormData.accessory_structure_description = '';
      projectFormData.green_energy_badges = buildingData.green_energy_badges;
      projectFormData.building_number = buildingData.building_number;
      projectFormData.story_count = buildingData.story_count;
      projectFormData.number_of_units = buildingData.number_of_units;
      projectFormData.building_number = buildingData.building_number;
      projectFormData.hud_disaster_case_number =
        buildingData.hud_disaster_case_number;
      projectFormData.is_require_water_sense_certification =
        buildingData.is_require_water_sense_certification;
      projectFormData.is_require_rough_inspection =
        buildingData.is_require_rough_inspection;
      projectFormData.appeals_project = buildingData.appeals_project;
      projectFormData.sampling = buildingData.sampling;
      projectFormData.is_townhouse = buildingData.is_townhouse;

      if (
        this.customerHIRLSettings.waterSenseProgramList.includes(
          this.selectedEEPProgram?.slug
        )
      ) {
        projectFormData.is_require_wri_certification =
          buildingData.is_require_wri_certification;
      } else {
        projectFormData.is_require_wri_certification = false;
      }

      if (
        buildingData.address.geocode?.raw_city_info?.country_info?.abbr ===
          'PR' ||
        buildingData.address.geocode?.raw_city_info?.country_info?.abbr === 'VI'
      ) {
        projectFormData.is_require_rough_inspection = false;
      } else if (this.isWRIProgramSelected()) {
        // skip rough inspection for WRI programs
        projectFormData.is_require_rough_inspection = false;
        projectFormData.is_accessory_structure = false;
      } else {
        projectFormData.is_require_rough_inspection =
          buildingData.is_require_rough_inspection;
      }

      projectFormData.lot_number = buildingData.address.lotNumber;
      projectFormData.home_address_geocode = buildingData.address.geocode.id;
      projectFormData.home_address_geocode_response = null;

      if (buildingData.address.geocodeBrokeredResponse) {
        projectFormData.home_address_geocode_response =
          buildingData.address.geocodeBrokeredResponse.id;
      }

      if (
        buildingData.commercial_spaces.length &&
        !this.isWRIProgramSelected()
      ) {
        for (const commercialSpaceData of buildingData.commercial_spaces) {
          // to keep order add our data via tap
          commercialSpaceGeocodes.push(
            this.geocoderService
              .matches({
                street_line1: buildingData.address.geocode.raw_street_line1,
                street_line2:
                  commercialSpaceData.commercial_space_street_line_2,
                city: buildingData.address.geocode.raw_city,
                zipcode: buildingData.address.geocode.raw_zipcode,
              } as IGeocodeMatches)
              .pipe(
                first(),
                tap(geocode =>
                  projectFormData.commercial_spaces.push(
                    this.commercialSpaceFormData(
                      geocode,
                      buildingData,
                      commercialSpaceData
                    )
                  )
                )
              )
          );
        }
      }

      formData.projects.push(projectFormData);
    }

    if (!this.isWRIProgramSelected()) {
      for (const accessoryStructureData of this.accessoryStructureFormGroup
        .controls.buildings.value) {
        const projectFormData = new CreateMFHIRLProject();

        projectFormData.is_accessory_structure = true;
        projectFormData.accessory_structure_description =
          accessoryStructureData.accessory_structure_description;

        projectFormData.lot_number =
          accessoryStructureData.accessory_structure_address.lotNumber;
        projectFormData.home_address_geocode =
          accessoryStructureData.accessory_structure_address.geocode.id;
        projectFormData.home_address_geocode_response = null;

        if (
          accessoryStructureData.accessory_structure_address
            .geocodeBrokeredResponse
        ) {
          projectFormData.home_address_geocode_response =
            accessoryStructureData.accessory_structure_address.geocodeBrokeredResponse.id;
        }

        projectFormData.story_count = 1;
        projectFormData.number_of_units = 1;
        projectFormData.green_energy_badges = [];

        formData.projects.push(projectFormData);
      }
    }

    for (const standAloneCommercialSpaceData of this
      .standAloneCommercialSpaceFormGroup.controls.buildings.value) {
      const projectFormData = new CreateMFHIRLProject();

      projectFormData.lot_number =
        standAloneCommercialSpaceData.stand_alone_commercial_space_address.lotNumber;
      projectFormData.home_address_geocode =
        standAloneCommercialSpaceData.stand_alone_commercial_space_address.geocode.id;
      projectFormData.home_address_geocode_response = null;

      if (
        standAloneCommercialSpaceData.stand_alone_commercial_space_address
          .geocodeBrokeredResponse
      ) {
        projectFormData.home_address_geocode_response =
          standAloneCommercialSpaceData.stand_alone_commercial_space_address.geocodeBrokeredResponse.id;
      }

      projectFormData.story_count = 1;
      projectFormData.number_of_units = 1;
      projectFormData.green_energy_badges = [];

      projectFormData.is_include_commercial_space = true;
      projectFormData.commercial_space_type =
        standAloneCommercialSpaceData.commercial_space_type;
      projectFormData.total_commercial_space =
        standAloneCommercialSpaceData.total_commercial_space;

      formData.projects.push(projectFormData);
    }

    forkJoin(commercialSpaceGeocodes)
      .pipe(defaultIfEmpty(null))
      .subscribe(_ => {
        this.hirlProjectRegistrationService
          .create_multi_family(formData)
          .subscribe(
            registration => {
              this.store.dispatch(toggleLoading({ payload: false }));
              // remove saved un complete data, because our form was successfully saved
              localStorage.removeItem(this.localStorageFormDataSaveKey);
              this.router.navigate([`/hi/project_registrations/`]);
            },
            error => {
              this.store.dispatch(toggleLoading({ payload: false }));
              this.dialog.open(ServerErrorDialogComponent, {
                width: '400px',
                data: new ServerErrorDialogConfig({
                  title: 'Error:',
                  data: error,
                }),
              });
            }
          );
      });
  }

  private watchEditFormUpdates(): void {
    const forms = [
      this.projectInformationFormGroup,
      this.financialFormGroup,
      this.certificateNamesFormGroup,
      this.builderFormGroup,
      this.marketingFormGroup,
    ];
    forms.forEach(form => {
      form.valueChanges
        .pipe(
          startWith(form.value),
          pairwise(),
          takeUntil(this.destroy$),
          map(([prev, curr]) => {
            return Object.keys(curr).find(key => prev[key] !== curr[key]);
          }),
          filter(val => val !== undefined)
        )
        .subscribe(key => {
          let serverKey;
          let value;
          switch (key) {
            case 'builder_organization_info':
              value = form.controls[key].value?.id || null;
              serverKey = 'builder_organization';
              break;
            case 'developer_organization_info':
              value = form.controls[key].value?.id || null;
              serverKey = 'developer_organization';
              break;
            case 'architect_organization_info':
              value = form.controls[key].value?.id || null;
              serverKey = 'architect_organization';
              break;
            case 'legacy_builder_organization_info':
              value = form.controls[key].value?.id || null;
              serverKey = 'legacy_builder_organization';
              break;
            case 'community_owner_organization_info':
              value = form.controls[key].value?.id || null;
              serverKey = 'community_owner_organization';
              break;
            case 'estimated_completion_date':
              value = `${formatDate(
                form.controls[key].value,
                'yyyy-MM-dd',
                'en-US'
              )}`;
              serverKey = 'estimated_completion_date';
              break;
            default:
              value = form.controls[key].value;
              serverKey = key;
          }

          if (form.get(key).valid) {
            this.editFormData[serverKey] = value;
          }
        });
    });
  }

  handleEdit(event): void {
    event.preventDefault();

    // Select invalid step for User and mark stepper step as red by selecting current form
    this.stepper.steps.forEach((step, index) => {
      step.completed = true;
      if (step.stepControl && step.stepControl.invalid) {
        this.stepper.selectedIndex = index;
      }
    });

    this.projectInformationFormGroup.markAllAsTouched();
    this.financialFormGroup.markAllAsTouched();
    this.certificateNamesFormGroup.markAllAsTouched();
    this.builderFormGroup.markAllAsTouched();
    this.marketingFormGroup.markAllAsTouched();

    if (
      this.projectInformationFormGroup.invalid ||
      this.financialFormGroup.invalid ||
      this.certificateNamesFormGroup.invalid ||
      this.builderFormGroup.invalid ||
      this.marketingFormGroup.invalid
    ) {
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const formData: HIRLProjectRegistration = new HIRLProjectRegistration();

    formData.eep_program = this.projectInformationFormGroup.value.eep_program;
    formData.project_client =
      this.projectInformationFormGroup.value.project_client;
    formData.project_name = this.projectInformationFormGroup.value.project_name;
    formData.estimated_completion_date = `${formatDate(
      this.projectInformationFormGroup.value.estimated_completion_date,
      'yyyy-MM-dd',
      'en-US'
    )}`;

    formData.building_will_include_non_residential_space =
      this.financialFormGroup.value.building_will_include_non_residential_space;
    formData.seeking_hud_mortgage_insurance_premium_reduction =
      this.financialFormGroup.value.seeking_hud_mortgage_insurance_premium_reduction;
    formData.seeking_hud_green_and_resilient_retrofit_program =
      this.financialFormGroup.value.seeking_hud_green_and_resilient_retrofit_program;
    formData.seeking_fannie_mae_green_financing =
      this.financialFormGroup.value.seeking_fannie_mae_green_financing;
    formData.seeking_freddie_mac_green_financing =
      this.financialFormGroup.value.seeking_freddie_mac_green_financing;
    formData.intended_to_be_affordable_housing =
      this.financialFormGroup.value.intended_to_be_affordable_housing;

    formData.architect_organization =
      this.architectFormGroup.value.architect_organization_info?.id;
    formData.architect_organization_contact =
      this.architectFormGroup.value.architect_organization_contact;

    formData.developer_organization =
      this.developerFormGroup.value.developer_organization_info?.id;
    formData.developer_organization_contact =
      this.developerFormGroup.value.developer_organization_contact;

    formData.community_owner_organization =
      this.communityOwnerFormGroup.value.community_owner_organization_info?.id;
    formData.community_owner_organization_contact =
      this.communityOwnerFormGroup.value.community_owner_organization_contact;

    formData.legacy_builder_organization =
      this.generalContractorFormGroup.value.legacy_builder_organization_info?.id;
    formData.legacy_builder_organization_contact =
      this.generalContractorFormGroup.value.legacy_builder_organization_contact;

    formData.certificate_line1 =
      this.certificateNamesFormGroup.value.certificate_line1;
    formData.certificate_line1_custom_text =
      this.certificateNamesFormGroup.value.certificate_line1_custom_text;
    formData.certificate_line2 =
      this.certificateNamesFormGroup.value.certificate_line2;
    formData.certificate_line2_custom_text =
      this.certificateNamesFormGroup.value.certificate_line2_custom_text;
    formData.certificate_line3 =
      this.certificateNamesFormGroup.value.certificate_line3;
    formData.certificate_line3_custom_text =
      this.certificateNamesFormGroup.value.certificate_line3_custom_text;
    formData.certificate_line4 =
      this.certificateNamesFormGroup.value.certificate_line4;
    formData.certificate_line4_custom_text =
      this.certificateNamesFormGroup.value.certificate_line4_custom_text;
    formData.certificate_line5 =
      this.certificateNamesFormGroup.value.certificate_line5;
    formData.certificate_line5_custom_text =
      this.certificateNamesFormGroup.value.certificate_line5_custom_text;
    formData.certificate_line6 =
      this.certificateNamesFormGroup.value.certificate_line6;
    formData.certificate_line6_custom_text =
      this.certificateNamesFormGroup.value.certificate_line6_custom_text;
    formData.certificate_line7 =
      this.certificateNamesFormGroup.value.certificate_line7;
    formData.certificate_line7_custom_text =
      this.certificateNamesFormGroup.value.certificate_line7_custom_text;
    formData.certificate_line8 =
      this.certificateNamesFormGroup.value.certificate_line8;
    formData.certificate_line8_custom_text =
      this.certificateNamesFormGroup.value.certificate_line8_custom_text;
    formData.certificate_line9 =
      this.certificateNamesFormGroup.value.certificate_line9;
    formData.certificate_line9_custom_text =
      this.certificateNamesFormGroup.value.certificate_line9_custom_text;

    formData.builder_organization =
      this.builderFormGroup.value.builder_organization_info?.id;
    formData.builder_organization_contact =
      this.builderFormGroup.value.builder_organization_contact;
    formData.billing_email = this.builderFormGroup.value.billing_email;

    formData.coi_held_by_different_company =
      this.builderFormGroup.value.coi_held_by_different_company || '';

    formData.marketing_first_name =
      this.marketingFormGroup.value.marketing_first_name;
    formData.marketing_last_name =
      this.marketingFormGroup.value.marketing_last_name;
    formData.marketing_email = this.marketingFormGroup.value.marketing_email;
    formData.marketing_phone = this.marketingFormGroup.value.marketing_phone;
    formData.project_description =
      this.marketingFormGroup.value.project_description;
    formData.project_website_url =
      this.marketingFormGroup.value.project_website_url;

    this.hirlProjectRegistrationService
      .update(formData, this.hirlProjectRegistration.id)
      .subscribe(
        hirlProjectRegistration => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.router.navigate([
            `/hi/project_registrations/${hirlProjectRegistration.id}`,
          ]);
        },
        error => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialog.open(ServerErrorDialogComponent, {
            width: '400px',
            data: {
              title: 'Error:',
              data: error,
            } as ServerErrorDialogConfig,
          });
        }
      );
  }

  hydrateForm() {
    const payload = Object.assign({}, this.hirlProjectRegistration);

    this.projectInformationFormGroup.patchValue(payload, {
      emitEvent: false,
      onlySelf: true,
    });
    this.selectedEEPProgram = payload?.eep_program_info;

    this.financialFormGroup.patchValue(payload, {
      emitEvent: false,
      onlySelf: true,
    });
    this.certificateNamesFormGroup.patchValue(payload, {
      emitEvent: false,
      onlySelf: true,
    });
    this.builderFormGroup.patchValue(payload, {
      emitEvent: false,
      onlySelf: true,
    });
    this.developerFormGroup.patchValue(payload, {
      emitEvent: false,
      onlySelf: true,
    });
    this.architectFormGroup.patchValue(payload, {
      emitEvent: false,
      onlySelf: true,
    });
    this.communityOwnerFormGroup.patchValue(payload, {
      emitEvent: false,
      onlySelf: true,
    });
    this.generalContractorFormGroup.patchValue(payload, {
      emitEvent: false,
      onlySelf: true,
    });
    this.marketingFormGroup.patchValue(payload, {
      emitEvent: false,
      onlySelf: true,
    });

    if (this.hirlProjectRegistration.coi_held_by_different_company) {
      this.builderFormGroup
        .get('is_coi_held_by_different_company')
        .patchValue(true, { emitEvent: false, onlySelf: true });
    }

    if (this.hirlProjectRegistration.builder_organization_info) {
      this.builderFormGroup.patchValue(
        this.hirlProjectRegistration.builder_organization_info,
        {
          emitEvent: true,
          onlySelf: true,
        }
      );
    }

    if (this.hirlProjectRegistration.developer_organization_info) {
      this.builderFormGroup.patchValue(
        this.hirlProjectRegistration.developer_organization_info,
        {
          emitEvent: true,
          onlySelf: true,
        }
      );
    }

    if (this.hirlProjectRegistration.community_owner_organization_info) {
      this.builderFormGroup.patchValue(
        this.hirlProjectRegistration.community_owner_organization_info,
        {
          emitEvent: true,
          onlySelf: true,
        }
      );
    }

    if (this.hirlProjectRegistration.architect_organization_info) {
      this.builderFormGroup.patchValue(
        this.hirlProjectRegistration.architect_organization_info,
        {
          emitEvent: true,
          onlySelf: true,
        }
      );
    }

    if (this.hirlProjectRegistration.legacy_builder_organization_info) {
      this.builderFormGroup.patchValue(
        this.hirlProjectRegistration.legacy_builder_organization_info,
        {
          emitEvent: true,
          onlySelf: true,
        }
      );
    }

    this.watchEditFormUpdates();

    if (
      this.qaCount !== 0 &&
      this.currentUser.company_info?.slug !==
        this.customerHIRLSettings.companySlug
    ) {
      this.projectInformationFormGroup.disable();
      this.financialFormGroup.disable();
      this.certificateNamesFormGroup.disable();

      if (this.hirlProjectRegistration.builder_organization) {
        this.builderFormGroup.disable();
      }
      if (this.hirlProjectRegistration.marketing_first_name) {
        this.marketingFormGroup.controls.marketing_first_name.disable();
      }
      if (this.hirlProjectRegistration.marketing_last_name) {
        this.marketingFormGroup.controls.marketing_last_name.disable();
      }
      if (this.hirlProjectRegistration.marketing_email) {
        this.marketingFormGroup.controls.marketing_email.disable();
      }
      if (this.hirlProjectRegistration.marketing_phone) {
        this.marketingFormGroup.controls.marketing_phone.disable();
      }
      if (this.hirlProjectRegistration.project_website_url) {
        this.marketingFormGroup.controls.project_website_url.disable();
      }
      if (this.hirlProjectRegistration.project_description) {
        this.marketingFormGroup.controls.project_description.disable();
      }
    }
  }

  handleBack() {
    if (this.editForm) {
      this.router.navigate([
        'hi',
        'project_registrations',
        this.hirlProjectRegistration.id,
      ]);
    } else {
      this.router.navigate(['hi', 'projects']);
    }
  }

  displayBuilderOrganizationMenu(builderOrganization) {
    if (builderOrganization) {
      return `${builderOrganization.name}`;
    }
  }

  displayArchitectOrganizationMenu(architectOrganization) {
    if (architectOrganization) {
      return `${architectOrganization.name}`;
    }
  }

  displayDeveloperOrganizationMenu(developerOrganization) {
    if (developerOrganization) {
      return `${developerOrganization.name}`;
    }
  }

  displayCommunityOwnerOrganizationMenu(communityOwnerOrganization) {
    if (communityOwnerOrganization) {
      return `${communityOwnerOrganization.name}`;
    }
  }

  displayGeneralContractorOrganizationMenu(builderOrganization) {
    if (builderOrganization) {
      return `${builderOrganization.name}`;
    }
  }

  getBuildingsControls() {
    return (this.buildingFormGroup.get('buildings') as UntypedFormArray)
      .controls;
  }

  getAccessoryStructureControls() {
    return (this.accessoryStructureFormGroup.get('buildings') as FormArray)
      .controls;
  }

  getStandAloneCommercialSpaceControls() {
    return (
      this.standAloneCommercialSpaceFormGroup.get('buildings') as FormArray
    ).controls;
  }

  isWRIProgramSelected() {
    return this.customerHIRLSettings.wriProgramList.includes(
      this.selectedEEPProgram?.slug
    );
  }
}
