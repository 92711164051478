import { Simulation } from '@/data/simulation/models';
import { AppliancesBackendDict } from '@/data/simulation/models/Appliances';
import { resetSimulation } from '@/state/actions/floorplan/simulation.actions';
import { AppState } from '@/state/reducers';
import { getSimulation } from '@/state/selectors/floorplan/simulation.selector';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-simulation-appliances',
  templateUrl: './simulation-appliances.component.html',
  styleUrls: ['./simulation-appliances.component.scss'],
})
export class SimulationAppliancesComponent implements OnInit {
  public initialized = false;
  public invalidResponse = false;
  public simulation: Simulation;
  public count: number;
  public appliances: AppliancesBackendDict;
  public hidden = true;

  private componentDestroyed$ = new Subject();

  constructor(public store: Store<AppState>) {}

  ngOnInit(): void {
    this.store
      .select(getSimulation)
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(val => val != null)
      )
      .subscribe(
        simulation => {
          this, (this.appliances = simulation.appliances_info);
          this.initialized = true;
          if (this.appliances) {
            this.hidden = false;
          }
        },
        error => {
          this.invalidResponse = true;
          this.initialized = true;
        }
      );
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
    this.store.dispatch(resetSimulation());
  }
}
