import { selectFrameFloorErrorViewSet } from '@/modules/simulation/state/frame-floor/selectors';
import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-frame-floor-errors',
  templateUrl: './frame-floor-errors.component.html',
  styleUrls: ['./frame-floor-errors.component.scss'],
})
export class FrameFloorErrorsComponent {
  @Input() entityId: number;
  componentDestroyed$: Subject<boolean> = new Subject();
  enable = false;
  name: string;
  totalErrorCount = 0;
  totalWarningCount = 0;
  floorTypeId: number;
  floorTypeErrorCount = 0;
  floorTypeWarningCount = 0;
  constructor(public store: Store) {}

  ngOnInit(): void {
    this.enable = true;
    this.store
      .select(selectFrameFloorErrorViewSet(this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.name = data.name;
        this.floorTypeId = data.frameFloorTypeId;

        const nestedModelsErrorCount = data.nestedModelsErrorCount;
        this.floorTypeErrorCount =
          nestedModelsErrorCount.frameFloorType.errorCount;
        this.floorTypeWarningCount =
          nestedModelsErrorCount.frameFloorType.warningCount;

        this.totalErrorCount = 0;
        this.totalErrorCount += this.floorTypeErrorCount;
        this.totalErrorCount += nestedModelsErrorCount.frameFloor.errorCount;

        this.totalWarningCount = 0;
        this.totalWarningCount += this.floorTypeWarningCount;
        this.totalWarningCount +=
          nestedModelsErrorCount.frameFloor.warningCount;
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
