import {
  AboveGradeWallBackendDict,
  createAboveGradeWallForm,
} from '@/data/simulation/models/enclosure/AboveGradeWall';
import { BaseValidator } from './base.validator';
import { ModelErrors, StateErrors } from '../state/shared/base.state';

export class AboveGradeWallValidator extends BaseValidator {
  static validateForm(
    abovegradewall: AboveGradeWallBackendDict
  ): ModelErrors<AboveGradeWallBackendDict> {
    const abovegradewallForm = createAboveGradeWallForm(abovegradewall);
    const errors = {};
    Object.keys(abovegradewall).forEach(key => {
      if (!abovegradewallForm.get(key)) return;
      errors[key] = abovegradewallForm.get(key).errors;
    });
    return {
      field: errors,
      model: abovegradewallForm.errors || {},
    };
  }

  static validate(
    abovegradewalls: AboveGradeWallBackendDict[]
  ): StateErrors<AboveGradeWallBackendDict> {
    const errors = {};
    abovegradewalls.forEach(abovegradewall => {
      errors[abovegradewall.id] = this.validateForm(abovegradewall);
    });
    return errors;
  }
}
