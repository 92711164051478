import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnnotationEditDialogComponent } from './components/annotation-edit-dialog/annotation-edit-dialog.component';
import { SharedModule } from '@/shared/shared.module';
import { AnnotationTabComponent } from '@/modules/annotation/components/annotation-tab/annotation-tab.component';

@NgModule({
  declarations: [AnnotationEditDialogComponent, AnnotationTabComponent],
  imports: [CommonModule, SharedModule],
  exports: [AnnotationEditDialogComponent, AnnotationTabComponent],
})
export class AnnotationModule {}
