import {
  CoolingEfficiencyUnitLabels,
  FuelTypeLabels,
  HeatingCoolingCapacityUnitLabels,
  MechanicalMotorTypeLabels,
} from '@/data/simulation/enumerations';
import {
  AirConditionerBackendDict,
  AirConditionerForm,
  CoolingSystemTypeLabels,
  FIELD_LABELS,
} from '@/data/simulation/models/mechanicals/AirConditioner';
import { updateAirConditioner } from '@/modules/simulation/state/air-conditioner/actions';
import { selectAirConditionerViewSet } from '@/modules/simulation/state/air-conditioner/selectors';
import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-air-conditioner',
  templateUrl: './air-conditioner.component.html',
  styleUrls: ['./air-conditioner.component.scss'],
})
export class AirConditionerComponent implements OnInit {
  @Input() entityId: number;
  fieldLabels = FIELD_LABELS;

  private componentDestroyed$ = new Subject();

  loading = false;
  entity: AirConditionerBackendDict = null;
  airConditionerFormGroup: AirConditionerForm;
  fuelTypeLabels = FuelTypeLabels;
  coolingSystemTypeLabels = CoolingSystemTypeLabels;
  mototTypeLabels = MechanicalMotorTypeLabels;

  coolingEfficiencyUnitLabels = CoolingEfficiencyUnitLabels;
  capacityUnitLabels = HeatingCoolingCapacityUnitLabels;

  constructor(public store: Store) {}

  ngOnInit() {
    this.store
      .select(selectAirConditionerViewSet(this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(({ data, loading }) => {
        this.entity = data;
        this.loading = loading;
        if (loading) return;

        if (this.airConditionerFormGroup) {
          this.airConditionerFormGroup.patchValue(data);
        } else {
          this.airConditionerFormGroup = new AirConditionerForm(data);
        }
      });
  }

  onSave(changedValues: Partial<AirConditionerBackendDict>) {
    this.store.dispatch(
      updateAirConditioner({
        airConditionerChanges: changedValues,
      })
    );
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
