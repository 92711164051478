<mat-expansion-panel *ngIf="(totalErrorCount > 0 || totalWarningCount > 0)">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <div class="title-container">
                <app-model-link entityName="distributionSystem" [entityId]="entityId" [title]="name">
                </app-model-link>
                <div class="d-flex align-items-center">
                    <app-error-count [errorCount]="totalErrorCount" [warningCount]="totalWarningCount">
                    </app-error-count>
                </div>
            </div>
        </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-accordion>
        <app-model-errors-tree [entityId]="entityId" entityName="distributionSystem"></app-model-errors-tree>
        <ng-container *ngFor="let ductInfo of ductsInfo">
            <mat-expansion-panel *ngIf="ductInfo && (ductInfo.errorCount > 0 || ductInfo.warningCount > 0)">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <div class="title-container">
                            Duct ({{ ductInfo.type }})
                            <app-error-count [errorCount]="ductInfo.errorCount" [warningCount]="ductInfo.warningCount">
                            </app-error-count>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <app-model-errors-tree [entityId]="ductInfo.id" entityName="duct">
                    </app-model-errors-tree>
                </ng-template>
            </mat-expansion-panel>
        </ng-container>
    </mat-accordion>
</mat-expansion-panel>