import { CompanyInfo } from '@/data/company/models';
import { InvoiceInfo } from '@/data/invoicing/models';
import { PaymentRequestProduct } from '@/data/customer-hirl/models/payment-request-product';

export enum PaymentRequestType {
  initialFee = 'initial_fee',
  fullYearSubscription = 'full_year_subscription',
  insertNewProduct = 'insert_product_to_current_subscription',
}

export const PaymentRequestTypeLabelMapping: Record<PaymentRequestType, any> = {
  [PaymentRequestType.initialFee]: 'Application Fee',
  [PaymentRequestType.fullYearSubscription]:
    'Initial/Renewal Certification Fee',
  [PaymentRequestType.insertNewProduct]: 'Mid-Cycle Certification Fee',
};

export enum PaymentRequestState {
  new = 'new',
  pending = 'pending',
  paid = 'paid',
  rejected = 'rejected',
}

export const PaymentRequestStateLabelMapping: Record<PaymentRequestState, any> =
  {
    [PaymentRequestState.new]: {
      label: 'New',
      badgeClass: 'badge-info',
    },
    [PaymentRequestState.pending]: {
      label: 'Pending',
      badgeClass: 'badge-warning',
    },
    [PaymentRequestState.paid]: {
      label: 'Paid',
      badgeClass: 'badge-success',
    },
    [PaymentRequestState.rejected]: {
      label: 'Rejected',
      badgeClass: 'badge-danger',
    },
  };

export class PaymentRequest {
  id?: number;
  request_type?: PaymentRequestType;
  state?: PaymentRequestState;
  manufacturer?: number;
  manufacturer_info?: CompanyInfo;
  products?: PaymentRequestProduct[];
  invoice?: number;
  invoice_info?: InvoiceInfo;
  total?: number;
  updated_at?: string;
  created_at?: string;
}

export class PaymentRequestCreate {
  request_type?: PaymentRequestType;
  manufacturer?: number;
  products_select_all?: boolean;
  products?: PaymentRequestProduct[];
}

export class PaymentRequestList {
  id?: number;
  request_type?: PaymentRequestType;
  state?: PaymentRequestState;
  manufacturer?: number;
  manufacturer_info?: CompanyInfo;
  total?: number;
  updated_at?: string;
  created_at?: string;
}

export class PaymentRequestInfo {
  id?: number;
  request_type?: PaymentRequestType;
  state?: PaymentRequestState;
  manufacturer?: number;
  manufacturer_info?: CompanyInfo;
  invoice?: number;
  invoice_info?: InvoiceInfo;
}

export class PaymentRequestCalculatePriceRequest {
  products_select_all?: boolean;
  products?: number[];
  request_type?: PaymentRequestType;
  manufacturer?: number;
  payment_request?: number;
}

export class PaymentRequestCalculatePriceResponseDetail {
  title: string;
  description: string;
  total: number;
}

export class PaymentRequestCalculatePriceResponse {
  total_price: number;
  errors?: { [key: string]: string }[];
  details?: PaymentRequestCalculatePriceResponseDetail[];
}
