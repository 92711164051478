import { Component, Input, OnInit } from '@angular/core';
import { Geocode, GeocodeBrokeredResponse } from '@/data/geocoder/models';
import { HomeAddressConfirmedGeocodeResponse } from '@/modules/geocoder/components/home-address-to-geocode-response-dialog/home-address-to-geocode-response-dialog.component';
import { GeocodeAddressDisplayFormat } from '@/modules/geocoder/components/confirm-geocode-response-dialog/confirm-geocode-response';

export enum DisplayType {
  default = 'default',
  geocodingIndicator = 'geocodingIndicator',
  contactAddress = 'contactAddress',
}

@Component({
  selector: 'app-address-display',
  templateUrl: './address-display.component.html',
  styleUrls: ['./address-display.component.scss'],
})
export class AddressDisplayComponent implements OnInit {
  private _lotNumber: string;

  get lotNumber(): string {
    return this._lotNumber;
  }

  @Input() set lotNumber(value: string) {
    this._lotNumber = value;
    this.setAddress();
  }

  private _geocode: Geocode;

  @Input() set geocode(value: Geocode) {
    this._geocode = value;
    this.setAddress();
  }

  get geocode(): Geocode {
    return this._geocode;
  }

  private _geocodeBrokeredResponse: GeocodeBrokeredResponse;

  @Input() set geocodeBrokeredResponse(value: GeocodeBrokeredResponse) {
    this._geocodeBrokeredResponse = value;
    this.setAddress();
  }

  get geocodeBrokeredResponse(): GeocodeBrokeredResponse {
    return this._geocodeBrokeredResponse;
  }

  @Input() display: DisplayType = DisplayType.default;
  displayType = DisplayType;

  confirmedGeocodeResponse: HomeAddressConfirmedGeocodeResponse;

  constructor() {}

  ngOnInit(): void {}

  setAddress() {
    let displayAddressFormat = GeocodeAddressDisplayFormat.default;
    if (this.display === DisplayType.contactAddress) {
      displayAddressFormat = GeocodeAddressDisplayFormat.contactAddress;
    }

    this.confirmedGeocodeResponse = new HomeAddressConfirmedGeocodeResponse({
      lotNumber: this.lotNumber,
      geocode: this.geocode,
      geocodeBrokeredResponse: this.geocodeBrokeredResponse,
      displayAddressFormat: displayAddressFormat,
    });
  }
}
