import { createReducer, on } from '@ngrx/store';
import * as MechanicalEquipmentActions from './actions';
import { MechanicalEquipmentBackendDict } from '@/data/simulation/models/mechanicals/MechanicalEquipment';
import { BaseState, sharedInitialState } from '../shared/base.state';
import { addErrorCount, addStateErrorCount } from '../helper';

export type MechanicalEquipmentState =
  BaseState<MechanicalEquipmentBackendDict>;

export const initialState: MechanicalEquipmentState = sharedInitialState;

export const reducer = createReducer(
  initialState,
  on(
    MechanicalEquipmentActions.loadMechanicalEquipmentsSuccess,
    (currentState, payload) => {
      const entities = payload.mechanicalEquipments.reduce(
        (acc, mechanicalequipment) => ({
          ...acc,
          [mechanicalequipment.id]: mechanicalequipment,
        }),
        {}
      );

      const loadingStatus = payload.mechanicalEquipments.reduce(
        (acc, mechanicalequipment) => ({
          ...acc,
          [mechanicalequipment.id]: false,
        }),
        {}
      );

      return {
        entities: { ...currentState.entities, ...entities },
        loading: { ...currentState.loading, ...loadingStatus },
        errors: {
          ...currentState.errors,
          ...addStateErrorCount(payload.errors),
        },
      };
    }
  ),

  on(
    MechanicalEquipmentActions.removeMechanicalEquipmentSuccess,
    (currentState, { id }) => {
      const { [id]: removed, ...entities } = currentState.entities;
      const { [id]: removedLoading, ...loading } = currentState.loading;
      const { [id]: removedErrors, ...errors } = currentState.errors;

      return {
        entities,
        loading,
        errors,
      };
    }
  ),

  on(MechanicalEquipmentActions.updateMechanicalEquipment, (state, { id }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [id]: true,
      },
    };
  }),

  on(
    MechanicalEquipmentActions.removeMechanicalEquipment,
    (state, { mechanicalEquipment }) => {
      return {
        ...state,
        loading: {
          ...state.loading,
          [mechanicalEquipment.id]: true,
        },
      };
    }
  ),

  on(
    MechanicalEquipmentActions.updateMechanicalEquipmentFailure,
    MechanicalEquipmentActions.removeMechanicalEquipmentFailure,
    (state, { id }) => {
      return {
        ...state,
        loading: {
          ...state.loading,
          [id]: false,
        },
      };
    }
  ),

  on(
    MechanicalEquipmentActions.updateMechanicalEquipmentSuccess,
    (state, { mechanicalEquipment, errors }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          [mechanicalEquipment.id]: mechanicalEquipment,
        },
        loading: {
          ...state.loading,
          [mechanicalEquipment.id]: false,
        },
        errors: {
          ...state.errors,
          [mechanicalEquipment.id]: addErrorCount(errors),
        },
      };
    }
  )
);
