import { createSelector } from '@ngrx/store';
import { SimulationState } from '../reducers';
import { selectSimulationFeature } from '../simulation.selectors';
import { DistributionSystemBackendDict } from '@/data/simulation/models/mechanicals/DistributionSystem';

export const selectDistributionSystem = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.distributionSystem.entities[id]
  );
const selectDistributionSystemLoadingStatus = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.distributionSystem.loading[id]
  );

export const selectDistributionSystemViewSet = (id: number) =>
  createSelector(
    selectDistributionSystem(id),
    selectDistributionSystemLoadingStatus(id),
    (distributionSystem, loadingStatus) => ({
      data: distributionSystem,
      loading: loadingStatus,
    })
  );

export const selectDistributionSystemName = (id: number) =>
  createSelector(
    selectDistributionSystem(id),
    (distributionSystem: DistributionSystemBackendDict) =>
      distributionSystem.name
  );

export const selectDistributionSystemDucts = (id: number) =>
  createSelector(
    selectDistributionSystem(id),
    (distributionSystem: DistributionSystemBackendDict) =>
      distributionSystem.ducts
  );

export const selectDistributionSystemErrorViewSet = (id: number) =>
  createSelector(
    selectSimulationFeature,
    selectDistributionSystemDucts(id),
    (state: SimulationState, ductIds: number[]) => {
      let errorCount = 0;
      let warningCount = 0;
      const ductsInfo = [];
      for (const ductId of ductIds) {
        if (state.duct.errors[ductId]) {
          errorCount += state.duct.errors[ductId].errorCount;
          warningCount += state.duct.errors[ductId].warningCount;

          ductsInfo.push({
            errorCount: state.duct.errors[ductId].errorCount,
            warningCount: state.duct.errors[ductId].warningCount,
            type: state.duct.entities[ductId].type,
            id: ductId,
          });
        }
      }
      errorCount += state.distributionSystem.errors[id].errorCount;
      warningCount += state.distributionSystem.errors[id].warningCount;
      return {
        errorCount,
        warningCount,
        ductsInfo,
        name: state.distributionSystem.entities[id].name,
      };
    }
  );
