<div class="container" *ngIf="invoice">
  <div class="row">
    <div class="col-5">
      <h3>
        <button mat-raised-button color="accent" appBackButton [fallbackUrl]="fallbackUrl">
          <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
        </button>
        <app-invoice-display [invoice]="invoice" displayType="nameAndStatus"></app-invoice-display>
      </h3>
    </div>
    <div class="col-7 text-right">
      <button mat-button mat-raised-button color="accent" (click)="onDownloadInvoice($event, invoice.prefixed_id)"
              class="mr-1">
        Download Invoice
      </button>
      <button mat-button mat-raised-button color="accent" (click)="shareHIRLInvoice($event, invoice.prefixed_id)"
              class="mr-1">
        Share
      </button>
      <app-permission-mat-button [permissionResponse]="autoPayPermissionResponse"
                                 (action)="onAutoPayInvoice($event, invoice.prefixed_id)" [hideOnFalse]="true"
                                 class="mr-1">
        Pay
      </app-permission-mat-button>
      <app-permission-mat-button [permissionResponse]="editPermissionResponse" (action)="onEditInvoice($event, invoice)"
                                 [hideOnFalse]="true" class="mr-1">
        Edit
      </app-permission-mat-button>
      <app-permission-mat-button [permissionResponse]="deletePermissionResponse"
                                 (action)="onCancelInvoice($event, invoice.prefixed_id)" [hideOnFalse]="true"
                                 class="mr-1">
        Cancel
      </app-permission-mat-button>
      <app-permission-mat-button [permissionResponse]="deletePermissionResponse"
                                 (action)="onDeleteInvoice($event, invoice.prefixed_id)" [hideOnFalse]="true"
                                 class="mr-1">
        Delete
      </app-permission-mat-button>
      <app-history-mat-button [objectId]="invoice.id" [modelClassName]="'invoice'" class="mr-1">
        History
      </app-history-mat-button>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <hr>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card mb-2">
        <div class="card-header">
          General Information
        </div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <b>Invoice ID:</b>&nbsp;
            <app-invoice-display [invoice]="invoice" displayType="onlyId"></app-invoice-display>
          </li>
          <li class="list-group-item">
            <b>Invoice Date:</b>&nbsp;{{ invoice.created_at | date: 'MM/dd/yyyy' }}
          </li>
          <li class="list-group-item">
            <b>Client:</b>
            <app-company-display
              [company]="invoice.customer_info"
              displayType="onlyNameAsLink"
            ></app-company-display>
            <ng-container *ngIf="invoice.client_ngbs_id">(NGBS ID: {{ invoice.client_ngbs_id }})</ng-container>
          </li>
          <li class="list-group-item" *ngIf="invoice.client_ngbs_id_before_company_consolidation">
            <b>Legacy NGBS Client:</b>
            <app-company-display
              [company]="invoice.client_before_company_consolidation_info"
              displayType="onlyNameAsLink"
            ></app-company-display>
            (NGBS ID: {{ invoice.client_ngbs_id_before_company_consolidation }})
          </li>
          <li class="list-group-item" *ngIf="invoice.erfp_ngbs_id_before_company_consolidation">
            <b>Legacy Invoicee:</b>
            <app-company-display
              [company]="invoice.erfp_before_company_consolidation_info"
              displayType="onlyNameAsLink"
            ></app-company-display>
            (NGBS ID: {{ invoice.erfp_ngbs_id_before_company_consolidation }})
          </li>
          <li class="list-group-item" *ngIf="invoice.invoice_type === InvoiceType.hirlProject">
            <b>Subdivision:</b>&nbsp;
            <ng-container *ngIf="invoice.subdivisions_info">
              <ng-container class="list-inline-item" *ngFor="let subdivision_info of invoice.subdivisions_info; let isLast=last">
                <app-subdivision-display [subdivision]="subdivision_info" displayType="onlyNameAsLink"></app-subdivision-display>
                {{ isLast ? '' : ', '}}
              </ng-container>
              <ng-container *ngIf="!invoice.subdivisions_info">
                N/A
              </ng-container>
            </ng-container>
          </li>
          <li class="list-group-item">
            <b>Total:</b>&nbsp;{{ invoice.total | currency: 'USD' }}
          </li>
          <li class="list-group-item">
            <b>Total Paid:</b>&nbsp;{{ invoice.total_paid | currency: 'USD' }}
          </li>
          <li class="list-group-item">
            <b>Current Balance:</b>&nbsp;{{ invoice.current_balance | currency: 'USD' }}
          </li>
          <li class="list-group-item" *ngIf="invoice.invoice_type === InvoiceType.hirlGCP ||
            invoice.invoice_type === InvoiceType.hirlGCPInitial ||
            invoice.invoice_type === InvoiceType.hirlGCPTopShelf">
            <b>Due Date:</b>&nbsp;{{ invoice.due_date | date: 'MM/dd/yyyy' || "-" }}
          </li>
          <li class="list-group-item" *ngIf="invoice.invoice_type === InvoiceType.hirlGCP ||
            invoice.invoice_type === InvoiceType.hirlGCPInitial ||
            invoice.invoice_type === InvoiceType.hirlGCPTopShelf">
            <b>Payment Request:</b>&nbsp;<app-payment-request-display [paymentRequest]="invoice.payment_request_info"
                                                                      displayType="onlyIdAsLink"></app-payment-request-display>
          </li>
          <li class="list-group-item" *ngIf="invoice.invoice_type === InvoiceType.hirlGCP ||
            invoice.invoice_type === InvoiceType.hirlGCPInitial ||
            invoice.invoice_type === InvoiceType.hirlGCPTopShelf">
            <b>Special Instructions:</b>&nbsp;{{ invoice.gcp_special_instructions || "-" }}
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <mat-tab-group color="accent" style="min-height: 450px;" disableRipple="true" animationDuration="0ms"
                     dynamicHeight (selectedTabChange)="onTabChange($event)" [selectedIndex]="selectedIndex">
        <mat-tab label="Fees">
          <ng-template mat-tab-label>
            Fees
          </ng-template>
          <div class="mt-2 mb-5">
            <app-hirl-invoice-invoice-item-group-tab></app-hirl-invoice-invoice-item-group-tab>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            Notes
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" class="ml-1"
                     *ngIf="invoiceNotesCount === -1"></fa-icon>
            <span class="badge badge-primary ml-1" *ngIf="invoiceNotesCount !== -1">
                            {{ invoiceNotesCount }}
                        </span>
          </ng-template>
          <div class="mt-2 mb-5">
            <app-annotation-tab [annotationTypeSlug]="'note'" [modelClassName]="'invoice'"
                                [objectId]="invoice.id"></app-annotation-tab>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
