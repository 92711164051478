import { Component, Input, OnInit } from '@angular/core';
import { InspectionGrade } from '@/data/user-management/models';
import { LetterGradeLabelMapping } from '@/data/user-management/models/inspection-grade';

@Component({
  selector: 'app-inspection-grade-display',
  templateUrl: './inspection-grade-display.component.html',
  styleUrls: ['./inspection-grade-display.component.scss'],
})
export class InspectionGradeDisplayComponent implements OnInit {
  @Input() inspectionGrade: InspectionGrade;

  letterGradeLabelMapping = LetterGradeLabelMapping;

  ngOnInit(): void {}
}
