<div *ngIf="initialized; else loading;">

    <mat-expansion-panel *ngIf="count > 0">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Slabs
            </mat-panel-title>
            <div matBadge="{{ count }}" matBadgeSize="medium" class="countBadge"></div>
        </mat-expansion-panel-header>
        <ng-container *ngTemplateOutlet="slabsView; context: {slabs: slabs}"></ng-container>
    </mat-expansion-panel>
</div>

<ng-template #slabsView let-slabs="slabs">
    <div class="row mt-2">
        <ng-container *ngFor="let slab of slabs; let index=index">
            <div class="col-6 mt-2">
                <div class="card">
                    <div class="card-content">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item header"
                                style="background-color:rgba(0, 0, 0, 0.03); align-items: center;">
                                <b>
                                    {{ slab.name }}
                                </b>
                            </li>
                            <li class="list-group-item">
                                <b>Area:</b>
                                {{ slab.area }} {{slab.area_units_display}}
                            </li>
                            <li class="list-group-item">
                                <b>Perimeter:</b>
                                {{ slab.perimeter }} {{slab.units_display}}
                            </li>
                            <li class="list-group-item">
                                <b>U-Value:</b>
                                {{slab.type_info.assembly_u_value}}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="w-100" *ngIf="(index+1) % 2 === 0"></div>
        </ng-container>
    </div>
</ng-template>

<ng-template #loading>
    <div class="row">
        <div class="col-12">
            <div class="d-flex justify-content-center" style="height: 320px;">
                <mat-spinner [diameter]="50"></mat-spinner>
            </div>
        </div>
    </div>
</ng-template>