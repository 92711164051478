import { Action, createReducer, on } from '@ngrx/store';
import { ApexRequest, ApexRequestDocument } from '@/data/home/models';
import {
  loadApexRequest,
  loadApexRequestDocuments,
  loadApexRequestDocumentsCount,
  resetApexRequest,
} from '@/state/actions/home/apex-request.actions';

export const apexRequestStateKey = 'apexRequest';

export interface ApexRequestState {
  detail: ApexRequest;
  apexRequestDocuments: ApexRequestDocument[];
  apexRequestDocumentsCount: number;
}

export const apexRequestInitialState: ApexRequestState = {
  detail: null,
  apexRequestDocuments: [],
  apexRequestDocumentsCount: 0,
};

const apexRequestReducer = createReducer(
  apexRequestInitialState,
  on(resetApexRequest, (state, action) => ({ ...apexRequestInitialState })),
  on(loadApexRequest, (state, { payload }) => ({ ...state, detail: payload })),
  // Documents
  on(loadApexRequestDocuments, (state, { payload }) => ({
    ...state,
    apexRequestDocuments: payload,
  })),
  on(loadApexRequestDocumentsCount, (state, { payload }) => ({
    ...state,
    apexRequestDocumentsCount: payload,
  }))
);

export function reducer(state: ApexRequestState | undefined, action: Action) {
  return apexRequestReducer(state, action);
}
