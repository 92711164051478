import { Component, Input, OnInit } from '@angular/core';
import { UIHelperService } from '@/shared/services/ui-helper.service';

@Component({
  selector: 'app-sensitive-info',
  templateUrl: './sensitive-info.component.html',
  styleUrls: ['./sensitive-info.component.scss'],
})
export class SensitiveInfoComponent implements OnInit {
  @Input() tooltipTextHide = 'Hide';
  @Input() tooltipTextShow = 'Show';

  @Input() show = false;
  constructor(private uiHelperService: UIHelperService) {}

  ngOnInit(): void {}

  toggle($event: MouseEvent): void {
    $event.preventDefault();
    this.show = !this.show;
  }
}
