import { Component, Input, OnInit } from '@angular/core';
import { DetailedMechanicalEquipmentBackendDict } from '@/data/simulation/models/mechanicals/MechanicalEquipment';
import {
  FuelTypeLabels,
  HeatingCoolingCapacityUnitLabels,
  HeatingEfficiencyUnitLabels,
} from '@/data/simulation/enumerations';

@Component({
  selector: 'app-equipment-heater',
  templateUrl: './equipment-heater.component.html',
  styleUrls: ['./equipment-heater.component.scss'],
})
export class EquipmentHeaterComponent implements OnInit {
  @Input() equipment: DetailedMechanicalEquipmentBackendDict;

  FuelTypeLabels = FuelTypeLabels;
  CapacityUnitLabels = HeatingCoolingCapacityUnitLabels;

  HeatingEfficiencyUnitLabels = HeatingEfficiencyUnitLabels;

  ngOnInit(): void {}
}
