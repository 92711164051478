import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EditorNavigationService } from '../../services/editor-navigation.service';
import { STATE_BACKEND_NAME } from '../../state.registry';
import { first } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import {
  AnnotationEditDialogComponent,
  AnnotationEditDialogData,
} from '@/modules/annotation/components/annotation-edit-dialog/annotation-edit-dialog.component';
import { Annotation } from '@/data/annotation/models';
import { Store } from '@ngrx/store';
import { addAnnotation } from '@/state/actions/annotation/annotation.actions';

@Component({
  selector: 'app-model-action-buttons',
  templateUrl: './model-action-buttons.component.html',
  styleUrls: ['./model-action-buttons.component.scss'],
})
export class ModelActionButtonsComponent implements OnInit {
  @Input() entityId;
  @Input() modelName;
  @Input() options = ['delete', 'clone', 'history', 'notes'];
  @Output() delete = new EventEmitter();
  @Output() clone = new EventEmitter();
  @Output() history = new EventEmitter();

  backendModelName: string;

  constructor(
    public editorNavigation: EditorNavigationService,
    public dialog: MatDialog,
    public store: Store
  ) {}

  requiredButtons = {
    delete: false,
    clone: false,
    history: false,
    notes: false,
  };

  ngOnInit() {
    this.requiredButtons = this.options.reduce((acc, option) => {
      acc[option] = true;
      return acc;
    }, this.requiredButtons);

    this.backendModelName = STATE_BACKEND_NAME[this.modelName];
  }

  openNotes() {
    this.editorNavigation
      .focusNotes(this.modelName, this.entityId)
      .pipe(first())
      .subscribe(found => {
        if (found) return;

        const dialogRef = this.dialog.open(AnnotationEditDialogComponent, {
          width: '45%',
          disableClose: true,
          autoFocus: false,
          data: {
            title: 'Note',
            annotation: {
              type: 61,
            },
          } as AnnotationEditDialogData,
        });

        dialogRef.afterClosed().subscribe((result?: Annotation) => {
          if (!result) {
            return;
          }

          this.store.dispatch(
            addAnnotation({
              annotation: {
                ...result,
              },
              content_type: STATE_BACKEND_NAME[this.modelName],
              object_id: this.entityId,
            })
          );
          this.editorNavigation.focusNotes(this.modelName, this.entityId);
        });
      });
  }
}
