import {
  DistributionSystemBackendDict,
  createDistributionSystemForm,
} from '@/data/simulation/models/mechanicals/DistributionSystem';
import { BaseValidator } from './base.validator';
import { ModelErrors, StateErrors } from '../state/shared/base.state';

export class DistributionSystemValidator extends BaseValidator {
  static validateForm(
    distributionSystem: DistributionSystemBackendDict
  ): ModelErrors<DistributionSystemBackendDict> {
    const distributionsystemForm =
      createDistributionSystemForm(distributionSystem);
    return distributionsystemForm.getFieldAndModelErros();
  }

  static validate(
    distributionSystems: DistributionSystemBackendDict[]
  ): StateErrors<DistributionSystemBackendDict> {
    const errors = {};
    distributionSystems.forEach(distributionSystem => {
      errors[distributionSystem.id] = this.validateForm(distributionSystem);
    });
    return errors;
  }
}
