import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BaseDataService } from '@/core/services/base-data-service';
import { Observable } from 'rxjs';
import { SkylightBackendDict } from '../models/enclosure/Skylight';

@Injectable({ providedIn: 'root' })
export class SkylightService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/skylight/`;

  update(
    id: number,
    skylightTypeChanges: Partial<SkylightBackendDict>
  ): Observable<SkylightBackendDict> {
    return this.http.put<SkylightBackendDict>(
      `${this.baseUrl}${id}/`,
      skylightTypeChanges
    );
  }

  delete(id: number) {
    return this.http.delete(`${this.baseUrl}${id}/`);
  }
}
