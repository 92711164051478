<app-company-display [company]="builderOrganization" [displayType]="'nameAndAddress'"></app-company-display>

<span *ngIf="!builderOrganization?.active_customer_hirl_builder_agreements_count && !builderOrganization?.active_coi_document_count; else separatePrefixes">
    (Do not have active Client Agreement and Certificate Of Insurance)
</span>
<ng-template #separatePrefixes>
    <span *ngIf="!builderOrganization?.active_customer_hirl_builder_agreements_count">
    (Do not have active Client Agreement)
    </span>
        <span *ngIf="!builderOrganization?.active_coi_document_count">
    (Do not have active Certificate Of Insurance)
    </span>
</ng-template>