import {
  DEFAULT_MATERIAL,
  MaterialBackendDict,
  createMaterialForm,
} from '@/data/simulation/models/enclosure/Material';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray } from '@angular/forms';

@Component({
  selector: 'app-assembly-layer-materials',
  templateUrl: './assembly-layer-materials.component.html',
  styleUrls: ['./assembly-layer-materials.component.scss'],
})
export class AssemblyLayerMaterialsComponent {
  @Input() materialsForm: FormArray;
  @Output() onChange = new EventEmitter<MaterialBackendDict[]>();

  removeMaterial(index: number) {
    this.materialsForm.removeAt(index);
  }

  addMaterial() {
    this.materialsForm.push(createMaterialForm(DEFAULT_MATERIAL));
  }
}
