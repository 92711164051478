import { CompanyInfo } from '@/data/company/models/company';
import { CompanyRole } from '@/data/company/models/company-role';
import { UserInfo } from '@/data/core/models/user';

export class CompanyAccess {
  id: number;
  company: number;
  company_info: CompanyInfo;
  user: number;
  user_info: UserInfo;
  roles: number[];
  roles_info: CompanyRole[];
}
