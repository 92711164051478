import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BaseDataService } from '@/core/services/base-data-service';
import { Observable } from 'rxjs';
import { NaturalVentilationBackendDict } from '../models/systems/NaturalVentilation';

@Injectable({ providedIn: 'root' })
export class NaturalVentilationService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/natural_ventilation/`;

  update(
    naturalVentilation: Partial<NaturalVentilationBackendDict>
  ): Observable<NaturalVentilationBackendDict> {
    return this.http.patch<NaturalVentilationBackendDict>(
      `${this.baseUrl}${naturalVentilation.id}/`,
      naturalVentilation
    );
  }
}
