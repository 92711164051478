import { Pipe, PipeTransform } from '@angular/core';

/*
Angular’s date pipe treats date-only
strings ("YYYY-MM-DD") as local time,
and when converting to UTC, it may shift
the date backward depending on your timezone
 */
@Pipe({ name: 'utcDate' })
export class UtcDatePipe implements PipeTransform {
  transform(value: string): Date | null {
    return value ? new Date(value + 'T00:00:00Z') : null;
  }
}
