import { createAction, props } from '@ngrx/store';
import { ModernMessage } from '@/data/messaging/models/modernMessage';

export const resetRecentMessagesAction = createAction(
  '[ModernMessage] Reset Recent Messages'
);

export const loadRecentMessagesAction = createAction(
  '[ModernMessage] Load Recent Messages',
  props<{ payload: ModernMessage[] }>()
);
export const loadRecentMessagesCountAction = createAction(
  '[ModernMessage] Load Recent Messages Count',
  props<{ payload: number }>()
);
export const addRecentMessageAction = createAction(
  '[ModernMessage] Add Recent Message',
  props<{ payload: ModernMessage }>()
);
export const updateRecentMessageAction = createAction(
  '[ModernMessage] Update Recent Message',
  props<{ payload: ModernMessage }>()
);
export const deleteRecentMessageAction = createAction(
  '[ModernMessage] Delete Recent Message',
  props<{ payload: ModernMessage }>()
);
