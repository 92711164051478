import { MatDialog } from '@angular/material/dialog';
import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { first, map } from 'rxjs/operators';
import { forkJoin, Subject } from 'rxjs';

import { AppState } from '@/state/reducers';
import { FloorPlanService } from '@/data/floorplan/services/floorplan.service';
import { FloorPlan } from '@/data/floorplan/models/floorplan';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { Company, CompanyType } from '@/data/company/models';
import { RelationshipService } from '@/data/relationship/services/relationship';
import { getInfoUser } from '@/state/selectors/info.selector';
import { User } from '@/data/core/models/user';
import { Relationship } from '@/data/relationship/models';
import { CompanyService } from '@/data/company/services/company-base.service';
import * as _ from 'lodash';

export interface IRelationshipAssociatedCompaniesEvent {
  action: 'create' | 'update' | 'delete';
  company: Company;
}

@Component({
  selector: 'app-associated-companies',
  templateUrl: './associated-companies.component.html',
  styleUrls: ['./associated-companies.component.scss'],
})
export class AssociatedCompaniesComponent implements OnInit, OnDestroy {
  public initialized: boolean;

  public currentUser: User;
  public relationships: Relationship[];
  public groupedRelationships: { [key: string]: Relationship[] };
  public companyTypesMap = {
    [CompanyType.builder]: 'Builder',
    [CompanyType.qa]: 'QA/QC Company',
    [CompanyType.hvac]: 'HVAC Contractor',
    [CompanyType.utility]: 'Utility Company',
    [CompanyType.rater]: 'Rating/Verification Company',
    [CompanyType.provider]: 'Certification Organization',
    [CompanyType.eep]: 'Program Sponsor',
  };

  @Input() objectId: number | string;
  @Input() modelClassName: string;
  @Output() onChange = new EventEmitter();

  public modelsMap = {
    floorplan: {
      modelCls: FloorPlan,
      serviceCls: FloorPlanService,
    },
  };

  private modelService$: FloorPlanService | CompanyService;
  private componentDestroyed$ = new Subject();

  constructor(
    public store: Store<AppState>,
    public injector: Injector,
    public dialog: MatDialog,
    public uiHelper: UIHelperService,
    public relationshipService: RelationshipService
  ) {}

  ngOnInit(): void {
    this.modelService$ = this.injector.get(
      this.modelsMap[this.modelClassName].serviceCls
    );
    const sources: { [k: string]: any } = {
      currentUser: this.store.select(getInfoUser).pipe(first()),
      relationships: this.modelService$.relationships(this.objectId).pipe(
        first(),
        map(response => response.results)
      ),
    };

    forkJoin(sources).subscribe(({ currentUser, relationships }) => {
      this.currentUser = currentUser;
      this.relationships = relationships;

      this.groupedRelationships = _(relationships)
        .groupBy(relationship => relationship?.company_info.company_type)
        .value();
      this.initialized = true;
    });
  }

  // onRemoveAssociation(event, relationId) {
  //     const dialogRef = this.dialog.open(ConfirmDialogComponent, {
  //         width: "50%",
  //         data: {
  //             title: "Do you want to remove this association?",
  //         },
  //     });
  //
  //     dialogRef
  //         .afterClosed()
  //         .pipe(first())
  //         .subscribe((result) => {
  //             if (!result) {
  //                 return;
  //             }
  //
  //             this.associationChangedEvent.emit();
  //             this.store.dispatch(toggleLoading({ payload: true }));
  //             this.relationshipService
  //                 .delete(relationId)
  //                 .pipe(first())
  //                 .subscribe(
  //                     (_) => {
  //                         this.initialized = false;
  //                         this.getAssociatedCompanies(this.objectId);
  //                         this.store.dispatch(toggleLoading({ payload: false }));
  //                     },
  //                     (error) => this.uiHelper.handleUserRequestError(error)
  //                 );
  //         });
  // }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
