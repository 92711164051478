import { createReducer, on } from '@ngrx/store';
import * as AppliancesActions from './actions';
import { AppliancesBackendDict } from '@/data/simulation/models/Appliances';
import { BaseState, sharedInitialState } from '../shared/base.state';
import { addErrorCount, addStateErrorCount } from '../helper';

export type AppliancesState = BaseState<AppliancesBackendDict>;

export const initialState: AppliancesState = sharedInitialState;

export const reducer = createReducer(
  initialState,
  on(AppliancesActions.loadAppliancesSuccess, (currentState, payload) => {
    return {
      entities: {
        ...currentState.entities,
        [payload.appliances.id]: payload.appliances,
      },
      loading: {
        ...currentState.loading,
        [payload.appliances.id]: false,
      },
      errors: { ...currentState.errors, ...addStateErrorCount(payload.errors) },
    };
  }),

  on(AppliancesActions.updateAppliances, (state, { id }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [id]: true,
      },
    };
  }),

  on(
    AppliancesActions.updateAppliancesSuccess,
    (state, { appliances, errors }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          [appliances.id]: appliances,
        },
        loading: {
          ...state.loading,
          [appliances.id]: false,
        },
        errors: {
          ...state.errors,
          [appliances.id]: addErrorCount(errors),
        },
      };
    }
  ),

  on(AppliancesActions.updateAppliancesFailure, (state, { id }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [id]: false,
      },
    };
  })
);
