<div class="main d-flex flex-column" [ngClass]="{'active': active}">
    <div class="studs" [ngClass]="{'studs-background': !active}">
        <div class="stud" *ngIf="layer.stud_type">
            <div [ngClass]="layer.stud_type"></div>
            <div [ngClass]="layer.stud_type"></div>
            <div [ngClass]="layer.stud_type"></div>
            <div [ngClass]="layer.stud_type"></div>
            <div [ngClass]="layer.stud_type === 'wood_stud' ? 'wood_stud' : 'half-steel_frame'"></div>
        </div>
    </div>
    <div *ngIf="airCavityInStud" [ngStyle]="{'height.%': (100/layerItemsCount)}"></div>
    <ng-container *ngFor="let material of layer.materials">
        <div class="material {{materialToStyleClassname[material.name]}}"
             [ngStyle]="{'height.%': (100/layerItemsCount)}"
             [matTooltip]="materialLabels[material.name]">
        </div>
    </ng-container>
</div>