import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { User } from '@/data/core/models/user';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import {
  CompanyAccessRequestParams,
  CompanyAccessService,
} from '@/data/company/services/company-access.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { first, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { getInfoUser } from '@/state/selectors/info.selector';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import {
  CompanyInfo,
  CompanyTypeLabelMapping,
  HirlMarketingRole,
} from '@/data/company/models';
import { OkDialogComponent } from '@/shared/components/ok-dialog/ok-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ServerResponse } from '@/core/schemes/server-response';
import {
  ContactCardRequestParams,
  ContactCardRequestParamsAttachedToLabelMapping,
  ContactCardRequestParamsHIRLProjectClientTypeLabelMapping,
  ContactCardService,
} from '@/data/core/services/contact_card';
import { ContactCard, ContactCardReportDownload } from '@/data/core/models';
import { Geocode } from '@/data/geocoder/models';
import { toggleLoading } from '@/state/actions/app.actions';
import { AsynchronousProcessedDocumentStatusDialogComponent } from '@/modules/filehandling/components/asynchronous-processed-document-status-dialog/asynchronous-processed-document-status-dialog.component';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';

@Component({
  selector: 'app-contact-card-list',
  templateUrl: './contact-card-list.component.html',
  styleUrls: ['./contact-card-list.component.scss'],
})
export class ContactCardListComponent implements OnInit, OnDestroy {
  protected readonly ContactCardRequestParamsHIRLProjectClientTypeLabelMapping =
    ContactCardRequestParamsHIRLProjectClientTypeLabelMapping;
  protected readonly ContactCardRequestParamsAttachedToLabelMapping =
    ContactCardRequestParamsAttachedToLabelMapping;
  protected readonly CompanyTypeLabelMapping = CompanyTypeLabelMapping;

  public initialized = false;

  public displayedColumns = [
    'company__name',
    'user__first_name',
    'company__street_line1',
    'phones',
    'emails',
    'title',
  ];

  public customerHIRLManufacturerDepartmentsLabelMapping = {
    Primary: 'Primary',
    Marketing: 'Marketing',
    Accounting: 'Accounting',
  };

  public filterFromGroup: FormGroup;

  public currentUser: User;
  public showCustomerHIRLFilters = false;

  public entities: ContactCard[];
  public entitiesCount: number;
  public entitiesIsLoading = true;

  public defaultParams: ContactCardRequestParams = new ContactCardRequestParams(
    1,
    '',
    'company__name',
    25
  );
  public storedParams: ContactCardRequestParams = new ContactCardRequestParams(
    1,
    '',
    'company__name',
    25
  );

  private listSubscription$: Subscription;
  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private contactCardService: ContactCardService,
    private companyAccessService: CompanyAccessService,
    private router: Router,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private uiHelperService: UIHelperService
  ) {}

  ngOnInit() {
    forkJoin({
      queryParams: this.activatedRoute.queryParams.pipe(first()),
      currentUser: this.store.select(getInfoUser).pipe(first()),
    })
      .pipe(
        takeUntil(this.componentDestroyed$),
        switchMap(({ queryParams, currentUser }) => {
          const params = new CompanyAccessRequestParams();
          params.company = currentUser.company;
          return this.companyAccessService.list(params).pipe(
            first(),
            map(companyAccessesResponse => {
              const companyAccesses = companyAccessesResponse.results;
              let companyAccess = null;
              if (companyAccesses.length) {
                companyAccess = companyAccesses[0];
              }
              return {
                queryParams: queryParams,
                currentUser: currentUser,
                companyAccess: companyAccess,
              };
            })
          );
        })
      )
      .subscribe(({ queryParams, currentUser, companyAccess }) => {
        this.currentUser = currentUser;
        this.storedParams.assignQueryParams(queryParams, [
          'attached_to',
          'company_type',
          'marketing_role',
          'hirl_project_client_type',
        ]);

        this.showCustomerHIRLFilters =
          this.currentUser?.company_info?.sponsor_slugs?.includes(
            CustomerHIRLSettings.companySlug
          ) ||
          this.currentUser?.company_info?.slug ===
            CustomerHIRLSettings.companySlug ||
          this.currentUser.is_superuser;

        if (this.showCustomerHIRLFilters) {
          this.displayedColumns.push('marketing_role');
        }

        this.initialized = true;

        this.setupFilterForm();
        this.hydrateForm();
        this.list();
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }
  }

  setupFilterForm() {
    this.filterFromGroup = this.fb.group({
      search: [null],
      title: [null],
      attached_to: [null],
      company_type: [null],
      hirl_marketing_role_info: [null],
      hirl_project_client_type: [null],
    });

    this.filterFromGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        let params = Object.assign(this.defaultParams, values);

        params = Object.assign(params, {
          hirl_marketing_role: params.hirl_marketing_role_info?.map(
            (hirl_marketing_role: HirlMarketingRole) => hirl_marketing_role.id
          ),
        });
        this.storedParams.assignFilterFormValues({
          formValues: params,
          excludedProperties: ['ordering', 'hirl_marketing_role_info'],
        });
        this.list();
      });
  }

  hydrateForm() {
    this.filterFromGroup.patchValue(this.storedParams, {
      emitEvent: false,
      onlySelf: true,
    });
  }

  list() {
    this.entitiesIsLoading = true;

    // populate params to query string
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.storedParams.toQueryParams(),
      replaceUrl: true,
    });

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }

    this.listSubscription$ = this.contactCardService
      .list(this.storedParams)
      .pipe(takeUntil(this.componentDestroyed$), take(1))
      .subscribe({
        next: (data: ServerResponse<ContactCard>) => {
          this.entities = data.results;
          this.entitiesCount = data.count;
          this.entitiesIsLoading = false;
        },
        error: (error: HttpErrorResponse) => {
          if (error.status === 400) {
            // to avoid cycle loop, reload list only if invalid param in query detected
            let invalidQueryParam = false;
            for (const key of Object.keys(error.error)) {
              if (this.storedParams.hasOwnProperty(key)) {
                invalidQueryParam = true;
                delete this.storedParams[key];
              }
            }

            if (invalidQueryParam) {
              this.list();
            }
          }
        },
      });
  }

  onSort($event: Sort) {
    this.storedParams.ordering = `${$event.direction === 'desc' ? '-' : ''}${
      $event.active
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  onPaginateChange($event: PageEvent) {
    this.uiHelperService.getUISettings().rowsPerPage = $event.pageSize;
    this.storedParams.page_size = $event.pageSize;
    this.storedParams.page = $event.pageIndex + 1;
    this.list();
  }

  resetFilters($event: MouseEvent) {
    $event.preventDefault();
    this.filterFromGroup.reset();
  }

  downloadReport($event: MouseEvent) {
    $event.preventDefault();

    this.store.dispatch(toggleLoading({ payload: true }));
    const reportDownload = new ContactCardReportDownload();
    reportDownload.export_filters = this.getAppliedFilters();

    this.contactCardService
      .report_download(reportDownload, this.storedParams)
      .pipe(first())
      .subscribe(
        asynchronousProcessedDocument => {
          this.store.dispatch(toggleLoading({ payload: false }));

          const asynchronousProcessedDocumentDialogRef = this.dialog.open(
            AsynchronousProcessedDocumentStatusDialogComponent,
            {
              width: '50%',
              data: {
                entity: asynchronousProcessedDocument,
                retrieve: true,
              },
            }
          );
          asynchronousProcessedDocumentDialogRef
            .afterClosed()
            .subscribe(result => {
              if (!result) {
                return;
              }
            });
        },
        error => this.uiHelperService.handleUserRequestError(error)
      );
  }

  get canExport(): ObjectPermissionResponse {
    if (this.entitiesIsLoading) {
      return new ObjectPermissionResponse(false, 'Loading... Please wait');
    }
    return new ObjectPermissionResponse(true);
  }

  showAppliedFiltersDialog($event: MouseEvent) {
    $event.preventDefault();

    let content = '';
    for (const [key, value] of Object.entries(this.getAppliedFilters())) {
      content += `<b>${key}</b>: ${value}<br>`;
    }

    this.dialog.open(OkDialogComponent, {
      data: {
        title: 'Current Applied Filters',
        content: content,
      },
    });
  }

  getAppliedFilters(): {
    [key: string]: string;
  } {
    return this.storedParams.toAppliedFilters({
      overwritePropertyValues: {},
      overwritePropertyLabels: {},
    });
  }

  // Company still do not support geocode/geocodeBrokeredResponse pattern
  companyAddressToConfirmedGeocodeResponse(
    company?: CompanyInfo
  ): Geocode | null {
    if (!company) {
      return null;
    }

    const geocode = new Geocode();
    geocode.raw_street_line1 = company.street_line1;
    geocode.raw_street_line2 = company.street_line2;
    geocode.raw_city = company.city;
    geocode.raw_city_info = company.city_info;
    geocode.raw_zipcode = company.zipcode;
    return geocode;
  }
}
