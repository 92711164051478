import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BaseDataService } from '@/core/services/base-data-service';
import { Observable } from 'rxjs';
import { FoundationWallBackendDict } from '../models/enclosure/FoundationWall';

@Injectable({ providedIn: 'root' })
export class FoundationWallService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/foundation_wall/`;

  update(
    id: number,
    foundationWallTypeChanges: Partial<FoundationWallBackendDict>
  ): Observable<FoundationWallBackendDict> {
    return this.http.put<FoundationWallBackendDict>(
      `${this.baseUrl}${id}/`,
      foundationWallTypeChanges
    );
  }

  delete(id: number) {
    return this.http.delete(`${this.baseUrl}${id}/`);
  }
}
