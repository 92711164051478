import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@/shared/shared.module';
import { HomeModule } from '@/modules/home/home.module';

@NgModule({
  declarations: [],
  exports: [],
  imports: [CommonModule, SharedModule, HomeModule],
})
export class RPCModule {}
