<h2 mat-dialog-title>
  <ng-container *ngIf="!isEditForm">
    Add New <b>{{ data.title }}</b>
  </ng-container>
  <ng-container *ngIf="isEditForm">
    Edit <b>{{ data.title }}</b>
  </ng-container>
</h2>
<mat-dialog-content *ngIf="initialized">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-12">
        <mat-checkbox formControlName="is_public"
                      matTooltip="If not public, only users from your company can see this note">
          Is Public
        </mat-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="col-12">

        <div class="row" style="height: 300px;">
          <div class="col-12">
            <quill-editor formControlName="content"></quill-editor>
          </div>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button color="accent" mat-raised-button (click)="save($event)">Save</button>
  <button mat-button [mat-dialog-close]="null" color="primary" mat-raised-button>Cancel</button>
</mat-dialog-actions>


