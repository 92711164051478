import { Injectable, Injector } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ModernMessage } from '@/data/messaging/models';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { MessageSnackbarComponent } from '@/modules/messaging/components/message-snackbar/message-snackbar.component';

@Injectable({
  providedIn: 'root',
})
export class MessagesSnackbarService {
  private overlayRefs: OverlayRef[] = []; // Track active snackbars
  private messageQueue: ModernMessage[] = []; // Queue for additional snackbars
  private maxSnackbars = 5; // Max snackbars visible at once

  private initialTopMargin = 65; // Initial margin from the top
  private snackbarSpacing = 25; // Fixed space between each snackbar

  constructor(private overlay: Overlay, private injector: Injector) {}

  show(message: ModernMessage, duration = 10000) {
    if (this.overlayRefs.length >= this.maxSnackbars) {
      this.messageQueue.push(message);
      return;
    }

    const overlayRef = this.overlay.create({
      positionStrategy: this.overlay
        .position()
        .global()
        .top('0px') // This will be updated later
        .right('20px'),
      panelClass: 'messages-snackbar-panel',
      hasBackdrop: false,
      scrollStrategy: this.overlay.scrollStrategies.noop(),
    });

    const snackbarPortal = new ComponentPortal(
      MessageSnackbarComponent,
      null,
      this.createInjector(message, overlayRef)
    );
    const componentRef = overlayRef.attach(snackbarPortal);

    // Add the new overlayRef at the beginning of the array
    this.overlayRefs.unshift(overlayRef);

    // Set OverlayRef in the snackbar component
    const snackbarInstance = componentRef.instance;
    snackbarInstance.setOverlayRef(overlayRef);

    // Subscribe to the snackbarClosed event
    snackbarInstance.snackbarClosed.subscribe(() => {
      overlayRef.dispose(); // Dispose of the overlay
      this.overlayRefs = this.overlayRefs.filter(ref => ref !== overlayRef);
      this.updateOffsets();
      this.displayNextFromQueue(); // Show the next snackbar from the queue
    });

    // Calculate height after a short delay to ensure it's rendered
    setTimeout(() => {
      this.updateOffsets(); // Update offsets after the snackbar has rendered

      setTimeout(() => {
        overlayRef.dispose();
        this.overlayRefs = this.overlayRefs.filter(ref => ref !== overlayRef);
        this.updateOffsets();
        this.displayNextFromQueue();
      }, duration);
    }, 200); // Delay to ensure the snackbar is fully rendered
  }

  private displayNextFromQueue() {
    if (this.messageQueue.length > 0) {
      const nextMessage = this.messageQueue.shift();
      if (nextMessage) {
        this.show(nextMessage);
      }
    }
  }

  private updateOffsets() {
    let offset = this.initialTopMargin; // Start from the initial top margin

    this.overlayRefs.forEach(overlayRef => {
      // Get the height of the snackbar
      const snackbarHeight = overlayRef.overlayElement.clientHeight;

      // Update the position of the snackbar
      const positionStrategy = this.overlay
        .position()
        .global()
        .top(`${offset}px`)
        .right('20px');
      overlayRef.updatePositionStrategy(positionStrategy);

      // Increase offset for the next snackbar
      offset += snackbarHeight + this.snackbarSpacing; // Add the height and spacing
    });
  }

  private createInjector(data: ModernMessage, overlayRef: OverlayRef) {
    return Injector.create({
      providers: [
        { provide: MAT_SNACK_BAR_DATA, useValue: data },
        { provide: OverlayRef, useValue: overlayRef },
      ],
      parent: this.injector,
    });
  }
}
