<mat-expansion-panel *ngIf="(totalErrorCount > 0 || totalWarningCount > 0)">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <div class="title-container d-flex justify-content-between w-100">
                <span>Water System</span>
                <app-error-count
                    [errorCount]="totalErrorCount"
                    [warningCount]="totalWarningCount">
                </app-error-count>
            </div>
        </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-accordion>
        <app-model-errors-tree [entityId]="entityId" entityName="waterSystem"></app-model-errors-tree>
    </mat-accordion>
</mat-expansion-panel>