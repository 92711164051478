import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@/app.module';
import { environment } from './environments/environment';

if (environment.STATE === 'production') {
  enableProdMode();
  environment.AXIS_LEGACY_ROOT = `${window.location.protocol}//${window.location.hostname}`;
  environment.AXIS_SOCKET_URL = `${environment.AXIS_LEGACY_ROOT}/messages`;
  environment.AXIS_API_V2_ROOT = `${environment.AXIS_LEGACY_ROOT}/api/v2`;
  environment.AXIS_API_V3_ROOT = `${environment.AXIS_LEGACY_ROOT}/api/v3`;
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
