import { createSelector } from '@ngrx/store';
import { SimulationState } from '../reducers';
import { selectSimulationFeature } from '../simulation.selectors';

export const selectAirSourceHeatPump = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.airSourceHeatPump.entities[id]
  );

const selectAirSourceHeatPumpLoading = (id: number) =>
  createSelector(
    selectSimulationFeature,
    state => state.airSourceHeatPump.loading[id]
  );

export const selectAirSourceHeatPumpViewSet = (id: number) =>
  createSelector(
    selectAirSourceHeatPump(id),
    selectAirSourceHeatPumpLoading(id),
    (data, loading) => ({ data, loading })
  );

export const selectAirSourceHeatPumpErrors = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.airSourceHeatPump.errors[id]
  );
