import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { HIRLProjectBillingState } from '@/data/customer-hirl/models/hirl-project';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { AbandonHIRLProjectRegistration } from '@/data/customer-hirl/models';

@Component({
  selector: 'app-hirl-registration-abandon-confirm-dialog',
  templateUrl: './hirl-registration-abandon-confirm-dialog.component.html',
  styleUrls: ['./hirl-registration-abandon-confirm-dialog.component.scss'],
})
export class HirlRegistrationAbandonConfirmDialogComponent
  implements OnInit, OnDestroy
{
  public formGroup: UntypedFormGroup;

  public billingStates = {
    [HIRLProjectBillingState.void]: 'Void',
    [HIRLProjectBillingState.not_pursuing]: 'Not pursuing',
  };

  private destroy$ = new Subject();

  constructor(
    public dialogRef: MatDialogRef<HirlRegistrationAbandonConfirmDialogComponent>,
    public fb: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.setupFormGroup();
  }

  public setupFormGroup() {
    this.formGroup = this.fb.group({
      billing_state: [HIRLProjectBillingState.void, Validators.required],
      reason: [''],
    });
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onConfirm() {
    this.formGroup.markAllAsTouched();

    if (this.formGroup.invalid) {
      return;
    }

    const formData = new AbandonHIRLProjectRegistration();
    formData.billing_state = this.formGroup.value.billing_state;
    formData.reason = this.formGroup.value.reason;

    this.dialogRef.close(formData);
  }
}
