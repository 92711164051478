import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import {
  getCompany,
  getCompanyAffiliationPreferences,
} from '@/state/selectors/company/company.selector';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { MatDialog } from '@angular/material/dialog';
import { filter, first, takeUntil } from 'rxjs/operators';
import { AffiliationPreferences } from '@/data/company/models/sponsoring';
import { ComponentCompanyType } from '@/modules/company/constants';
import { Subject } from 'rxjs';
import { Company } from '@/data/company/models';
import { CompanyService } from '@/data/company/services/company-base.service';

@Component({
  selector: 'app-company-affiliations-list',
  templateUrl: './company-affiliations-list.component.html',
  styleUrls: ['./company-affiliations-list.component.scss'],
})
export class CompanyAffiliationsListComponent implements OnInit, OnDestroy {
  @Input() componentCompanyType: ComponentCompanyType;

  initialized = false;

  company: Company;
  companyDisplayName: string;

  affiliations: AffiliationPreferences[];

  private componentDestroyed$ = new Subject();

  constructor(
    public store: Store<AppState>,
    public injector: Injector,
    public dialog: MatDialog,
    private companyService: CompanyService
  ) {}

  ngOnInit(): void {
    this.store
      .select(getCompany)
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(val => val !== null)
      )
      .subscribe(company => {
        this.company = company;

        this.companyService
          .affiliations(company.id)
          .pipe(first())
          .subscribe(_ => {
            this.initialized = true;
          });
      });

    this.store
      .select(getCompanyAffiliationPreferences)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(affiliations => {
        this.affiliations = affiliations;
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
