import { createSelector } from '@ngrx/store';
import { SimulationState } from '../reducers';
import { selectSimulationFeature } from '../simulation.selectors';

export const selectBlockRate = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.blockRate.entities[id]
  );
const selectBlockRateLoadingStatus = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.blockRate.loading[id]
  );

export const selectBlockRateViewSet = (id: number) =>
  createSelector(
    selectBlockRate(id),
    selectBlockRateLoadingStatus(id),
    (blockRate, loadingStatus) => ({
      data: blockRate,
      loading: loadingStatus,
    })
  );
