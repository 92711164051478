<ng-container *ngIf="show; else hiddenContainer;">
  <ng-content></ng-content>
  <a href="#" (click)="toggle($event)">
    <fa-icon [icon]="['fas', 'eye-slash']" [matTooltip]="tooltipTextHide"></fa-icon>
  </a>
</ng-container>

<ng-template #hiddenContainer>
  ******
  <a href="#" (click)="toggle($event)">
    <fa-icon [icon]="['fas', 'eye']" [matTooltip]="tooltipTextShow"></fa-icon>
  </a>
</ng-template>
