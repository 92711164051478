<div class="autocomplete-container">
  <mat-form-field appearance="none" class="autocomplete-field"
                  (focusin)="onFocusIn($event)"
                  (focusout)="onFocusOut($event)">
    <input matInput placeholder="Type to search"
           #autocompleteTrigger="matAutocompleteTrigger"
           #autocompleteInput
           [matAutocomplete]="autocomplete"
           [formControl]="autocompleteControl">
    <mat-autocomplete
      #autocomplete="matAutocomplete"
      autoActiveFirstOption
      [displayWith]="onDisplay.bind(this)"
      (optionSelected)="onOptionSelected($event)"
      [panelWidth]="panelWidth"
    >
      <mat-option
        *ngFor="let result of results"
        [value]="result" [disabled]="onOptionDisabled(result)">
        <div [innerHTML]="onOptionDisplay(result)"></div>
      </mat-option>
      <mat-option [disabled]="true" *ngIf="results?.length === pageSize">
        Too many results to display. Continue typing to see more...
      </mat-option>
    </mat-autocomplete>
    <mat-spinner matSuffix diameter="20" *ngIf="isLoading"></mat-spinner>
    <button mat-button *ngIf="autocompleteControl.value && !isLoading"
            matSuffix mat-icon-button
            aria-label="Clear"
            (click)="onClear();">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
</div>
