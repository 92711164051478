import { createSelector } from '@ngrx/store';
import { SimulationState } from '../reducers';
import { selectSimulationFeature } from '../simulation.selectors';

export const selectSlabType = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.slabType.entities[id]
  );

const selectSlabTypeLoadingStatus = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.slabType.loading[id]
  );

export const selectSlabViewSet = (id: number) =>
  createSelector(
    selectSlabType(id),
    selectSlabTypeLoadingStatus(id),
    (slabType, loadingStatus) => ({
      data: slabType,
      loading: loadingStatus,
    })
  );

const selectSlabTypeName = (id: number) =>
  createSelector(selectSlabType(id), slabType => slabType.name);

const selectSlabTypeErrorCount = (id: number) =>
  createSelector(selectSimulationFeature, (state: SimulationState) => {
    return state.slabType.errors[id].errorCount;
  });

export const selectSlabTypeNameAndError = (id: number) =>
  createSelector(
    selectSlabTypeName(id),
    selectSlabTypeErrorCount(id),
    (name, errorCount) => {
      return {
        name,
        errorCount,
      };
    }
  );
