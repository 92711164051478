<div class="row h-100" *ngIf="initialized">
  <div class="col-12">
    <mat-sidenav-container class="h-100" [hasBackdrop]="false">
      <mat-sidenav #selectedSideNav mode="side" [opened]="!!selectedInvoiceItems.length" position="end"
                   fixedInViewport="true" [fixedTopGap]="50">
        <div class="container padding-top-bottom">
          <div class="row mt-2">
            <div class="col-12">
              <h3>Selected Invoice Items</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <ul class="list-group list-group-flush">
                <li class="list-group-item" *ngFor="let invoice of selectedInvoiceItems">
                  <a [routerLink]="['/', 'hi', 'invoices', invoice.prefixed_id]">{{ invoice.prefixed_id }}</a> - {{ invoice.customer_name }}
                </li>
              </ul>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <hr>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button class="btn btn-primary btn-block" [disabled]="!selectedInvoiceItems.length"
                      (click)="downloadSelectedInvoiceItems($event)">
                Download Invoice
              </button>
            </div>
          </div>
        </div>
      </mat-sidenav>
      <mat-sidenav-content>
        <div class="container-fluid">
          <div class="row">
            <div class="col-6">
              <h3>NGBS Green Products Invoices</h3>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <hr>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-4">
              <button class="btn btn-block" (click)="onChangeInvoiceState($event, InvoiceState.new)" [ngClass]="{'btn-danger': storedParams.state === InvoiceState.new}">
                New ({{ aggregateByState.new }})
              </button>
            </div>
            <div class="col-4">
              <button class="btn btn-block" (click)="onChangeInvoiceState($event, InvoiceState.paid)" [ngClass]="{'btn-danger': storedParams.state === InvoiceState.paid}">
                Paid ({{ aggregateByState.paid }})
              </button>
            </div>
            <div class="col-4">
              <button class="btn btn-block" (click)="onChangeInvoiceState($event, InvoiceState.cancelled)" [ngClass]="{'btn-danger': storedParams.state === InvoiceState.cancelled}">
                Cancelled ({{ aggregateByState.cancelled }})
              </button>
            </div>
          </div>


          <div class="row">
            <div class="col-3">
              <div class="row">
                <div class="col-12">
                  <form [formGroup]="filterFromGroup" class="card">
                    <h5 class="card-header">Filters <a href="#" class="small"
                                                       (click)="resetFilters($event);">Reset
                      filters</a></h5>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12">
                          <mat-form-field class="w-100">
                            <mat-label>Search</mat-label>
                            <app-search-input formControlName="search"
                                              placeholder="Type to search something"></app-search-input>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row" *ngIf="!currentUser.is_superuser">
                        <div class="col-12">
                          <mat-form-field class="w-100">
                            <mat-label>Included Companies</mat-label>
                            <app-generic-model-chips formControlName="company_access_info" modelClassName="company_access" [panelWidth]="640" [initialValueIds]="storedParams.company_access" [filterParams]="{'user': currentUser.id}"></app-generic-model-chips>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-9">
              <section>
                <div class="row" *ngIf="invoicesIsLoading">
                  <div class="col-12">
                    <mat-progress-bar
                      mode="indeterminate"
                      color="accent"
                    ></mat-progress-bar>
                  </div>
                </div>
                <table mat-table
                       [dataSource]="invoices"
                       class="table"
                       matSort
                       [matSortActive]="storedParams.toTableSort().active"
                       [matSortDirection]="storedParams.toTableSort().direction"
                       (matSortChange)="onSort($event)"
                >
                  <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                      <mat-checkbox (change)="onSelectAll($event)"></mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <mat-checkbox (change)="onSelect($event, row)" [checked]="selectedInvoiceItems.includes(row)"></mat-checkbox>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="invoiceNumber">
                    <th mat-header-cell *matHeaderCellDef>Invoice Number</th>
                    <td mat-cell *matCellDef="let row">
                      <app-invoice-display [invoice]="row" displayType="onlyIdAsLink"></app-invoice-display>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="client_invoicee">
                    <th mat-header-cell *matHeaderCellDef>Client/Invoicee</th>
                    <td mat-cell *matCellDef="let row">
                      <div style="display: flex; flex-direction: column;">
                        <a [routerLink]="['/', 'company', row.customer_type, row.customer_id]"
                           target="_blank" matTooltip="View Client">
                          {{ row.customer_name }}
                        </a>

                        <a [routerLink]="['/company', row.customer_type, row.customer_id]"
                           target="_blank" matTooltip="View Invoicee">
                          {{ row.erfp_before_company_consolidation_name }}
                        </a>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="total">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Total</th>
                    <td mat-cell *matCellDef="let row">
                      <div style="display: flex; flex-direction: column;">
                        <p class="has-text-grey">{{ row.total | currency: 'USD' }}</p>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="total_paid">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Paid</th>
                    <td mat-cell *matCellDef="let row">
                      <div style="display: flex; flex-direction: column;">
                        <p class="has-text-grey">{{ row.total_paid | currency: 'USD' }}</p>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="current_balance">
                    <th mat-header-cell *matHeaderCellDef>Balance</th>
                    <td mat-cell *matCellDef="let row">
                      <div style="display: flex; flex-direction: column;">
                        <p class="has-text-grey">{{ row.current_balance | currency: 'USD' }}</p>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="created_at">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
                    <td mat-cell *matCellDef="let row">
                      <div style="display: flex; flex-direction: column;">
                        {{ row.created_at | date: 'MM/dd/yyyy' }}
                      </div>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <ng-container matColumnDef="rowShading" class="row-shading"></ng-container>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns; let even = even; let odd = odd"
                      [ngClass]="{ 'row-shading': odd }"></tr>
                </table>
                <div class="d-flex flex-row">
                  <div class="align-self-center">
                    <button
                      mat-raised-button
                      (click)="exportToCSV($event)"
                      [disabled]="invoicesIsLoading"
                      class="mr-2"
                      matTooltip="Export Data to CSV file"
                    >
                      CSV
                    </button>
                    <button
                      mat-raised-button
                      (click)="exportToExcel($event)"
                      [disabled]="invoicesIsLoading"
                      matTooltip="Export Data to Excel file"
                    >
                      Excel
                    </button>
                  </div>
                  <div class="d-flex flex-fill justify-content-end align-self-center">
                    <mat-paginator
                      [length]="invoicesCount"
                      [pageIndex]="$any(storedParams.page) - 1"
                      [pageSize]="storedParams.page_size"
                      (page)="onPaginateChange($event)"
                      aria-label="Select page"
                    >
                    </mat-paginator>
                  </div>
                </div>

              </section>
            </div>
          </div>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>
