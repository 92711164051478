<h2 mat-dialog-title>
  <ng-container> Edit <b>Company</b></ng-container>
</h2>
<mat-dialog-content *ngIf="initialized">
  <form [formGroup]="form" class="mb-2">
    <div class="row">
      <div class="col-12">
        <mat-form-field class="mb-2 w-100">
          <mat-label>Description</mat-label>
          <textarea
              matInput
              type="text"
              placeholder="Enter Company Description"
              formControlName="description"
          ></textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field class="mb-2 w-100">
          <mat-label>Auto add direct relationships</mat-label>
          <mat-select
              formControlName="auto_add_direct_relationships"
              placeholder="Auto add direct relationships"
          >
            <mat-option [value]="true">Yes</mat-option>
            <mat-option [value]="false">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field class="mb-2 w-100">
          <mat-label>Show addresses as-entered</mat-label>
          <mat-select
              formControlName="display_raw_addresses"
              placeholder="Show addresses as-entered"
          >
            <mat-option [value]="true">Yes</mat-option>
            <mat-option [value]="false">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field class="mb-2 w-100">
          <mat-label>Verification Grade Type</mat-label>
          <mat-select
              aria-label="Select Inspection Grade Type"
              formControlName="inspection_grade_type"
              placeholder="Verification Grade Type"
              required
          >
            <mat-option
                *ngFor="let item of inspectionGradeTypeLabelMapping | keyvalue"
                [value]="item.key"
            >
              {{ item.value }}
            </mat-option>
          </mat-select>
          <mat-error
              *ngIf="form.controls['inspection_grade_type'].hasError('required')"
          >
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100 mb-2">
          <mat-label>Shipping Address</mat-label>
          <app-address-input
              placeholder="Shipping Address"
              formControlName="edit_shipping_address"
              required
              [show-clear-btn]="true"
          ></app-address-input>
          <mat-error
              *ngIf="form.get('edit_shipping_address').hasError('required')"
          >
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Company Logo</mat-label>
          <app-file-input
              [formControl]="form.controls['logo']"
              [accept]="'.png, .jpg, .jpeg'"
          ></app-file-input>
          <mat-hint *ngIf="entity.logo">
            Current file:
            <a href="{{ entity.logo }}" target="_blank">{{
                entity.logo.split("/").pop()
              }}</a>
          </mat-hint>
        </mat-form-field>
      </div>
    </div>

    <div class="row" *ngIf="((currentUser.is_company_admin && currentUser.company === entity.id) ||
        currentUser.company_info.slug ===
          CustomerHIRLSettings.companySlug) &&
          (entity?.sponsor_slugs?.includes(CustomerHIRLSettings.companySlug) ||
          entity?.slug === CustomerHIRLSettings.companySlug)">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Marketing Roles</mat-label>
          <app-generic-model-chips
              modelClassName="hirl_marketing_role"
              formControlName="hirl_marketing_roles"
              [initialValueIds]="entity?.hirl_marketing_roles"
          ></app-generic-model-chips>
        </mat-form-field>
      </div>
    </div>

    <div class="row" *ngIf="((currentUser.is_company_admin && currentUser.company === entity.id) ||
        currentUser.company_info.slug ===
          CustomerHIRLSettings.companySlug) &&
          (entity?.sponsor_slugs?.includes(CustomerHIRLSettings.companySlug) ||
          entity?.slug === CustomerHIRLSettings.companySlug)">
      <div class="col-12">
        <mat-form-field class="w-100 mb-2">
          <mat-label>Invoice Preferences</mat-label>
          <mat-select aria-label="Invoice Preferences Type" formControlName="gcp_fee_calculation_type">
            <mat-option *ngFor="let item of GCPFeeCalculationTypeLabelMapping | defaultOrderKeyValue" [value]="item.key">
              {{ item.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button color="accent" mat-raised-button (click)="save($event)">
    Save
  </button>
  <button
      mat-button
      [mat-dialog-close]="null"
      color="primary"
      mat-raised-button
  >
    Cancel
  </button>
</mat-dialog-actions>
