import { CompanyInfo } from '@/data/company/models';

export enum COIDocumentInsuranceType {
  professionalLiability = 'professional_liability',
  workersCompensation = 'workers_compensation',
  umbrellaLiability = 'umbrella_liability',
  automobileInsurance = 'automobile_insurance',
  generalLiability = 'general_liability',
  other = 'other',
}

export const COIDocumentInsuranceTypeMapping: Record<
  COIDocumentInsuranceType,
  string
> = {
  [COIDocumentInsuranceType.professionalLiability]: 'Professional Liability',
  [COIDocumentInsuranceType.workersCompensation]: 'Workers Compensation',
  [COIDocumentInsuranceType.umbrellaLiability]: 'Umbrella Liability',
  [COIDocumentInsuranceType.automobileInsurance]: 'Automobile Insurance',
  [COIDocumentInsuranceType.generalLiability]: 'General Liability',
  [COIDocumentInsuranceType.other]: 'Other',
};

export class COIDocument {
  id?: number;
  document?: string;
  company?: number;
  company_info?: CompanyInfo;
  description?: string;
  policy_number?: string;
  start_date?: string;
  expiration_date?: string;
  insurance_type?: COIDocumentInsuranceType;

  last_update?: string;
  created_at?: string;
}
