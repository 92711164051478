<h2 mat-dialog-title>
  <ng-container *ngIf="company.company_type == CompanyType.builder || company.company_type == CompanyType.manufacturer">
    NGBS Green Program Insurance Requirements
  </ng-container>
  <ng-container *ngIf="company.company_type == CompanyType.rater">
    NGBS Green Verifier Insurance Requirements
  </ng-container>
</h2>
<mat-dialog-content>
  <ng-container *ngIf="company.company_type == CompanyType.builder || company.company_type == CompanyType.manufacturer">
    <div class="row">
      <div class="col-12">
        <p>
          Home Innovation requires NGBS Green participants to maintain, at participant’s expense,
          General Liability insurance as shown below.
          Obtaining insurance certificates is typically a routine matter
          for your insurance agent/broker and the information below should
          help expedite the process. Please ask your insurance agent
          to provide a current Certificate of Insurance (COI) –
          including the additional insured verbiage below – and upload
          to your AXIS record at your earliest convenience.
        </p>

        <p>
          The chart below shows the MINIMUM required coverage per occurrence:

        </p>
        <table class="table table-bordered">
          <tbody>
          <tr>
            <td>GENERAL LIABILITY</td>
            <td>$1,000,000</td>
          </tr>
          </tbody>
        </table>

        <p>
          Please note: the insurance policy <b>must</b> name
          <b>Home Innovation Research Labs</b> and its officers,
          directors, agents, and employees, as <b>additional insureds</b>
          for liability with respect to or arising out of the
          actual or alleged acts or omissions of Manufacturer.
        </p>
        <p>
          Your insurance certificate must:
        </p>
        <ul>
          <li>
            List “Certificate holder” as:
            <ul>
              <li>
                <i>
                  Home Innovation Research Labs, 400 Prince George’s Boulevard, Upper Marlboro, MD 20774-8731, and
                </i>
              </li>
            </ul>
          </li>
          <li>
            Include the following paragraph to meet the additional insured requirements:
            <ul style="">
              <li>
                <i>
                  "Home Innovation Research Labs and its officers, directors, agents, affiliates,
                  and employees are included as additional where required by
                  written contract in accordance with the policy provisions of
                  the General Liability policy."
                </i>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="company.company_type == CompanyType.rater">
    <div class="row">
      <div class="col-12">
        <p>
          Home Innovation requires that you maintain specific levels of insurance
          coverage
          while serving as an NGBS Green Verifier. Obtaining insurance certificates is
          typically a routine matter for your insurance agent/broker – the information
          below
          should help expedite the processing. Please have your insurance agent send a
          Certificate of Insurance showing the required coverages for the current
          policy
          period to:
        </p>

        <p>
          <i>Lynda Marchman Mosteller</i><br>
          Certification Program Coordinator<br>
          Home Innovation Research Labs<br>
          400 Prince George’s Boulevard<br>
          Upper Marlboro, MD 20774-8731<br>
          Phone: <a href="tel:301.430.6237">301.430.6237</a> | Fax: <a
          href="tel:301.430.6184">301.430.6184</a><br>
          <a href="mailto:lmosteller@homeinnovation.com">lmosteller@homeinnovation.com</a>
        </p>
        <p>
          MINIMUM required coverage per occurrence:
        </p>
        <table class="table table-bordered">
          <tbody>
          <tr>
            <td>GENERAL LIABILITY</td>
            <td>$1,000,000</td>
          </tr>
          <tr>
            <td>
              AUTOMOBILE LIABILITY
            </td>
            <td>
              $500,000
            </td>
          </tr>
          <tr>
            <td>
              WORKERS COMPENSATION
            </td>
            <td>
              As Required by Law
            </td>
          </tr>
          <tr>
            <td>
              EMPLOYER’S LIABILITY
              <i>typically part of Workers Compensation $500,000</i>
            </td>
            <td>
              $500,000
            </td>
          </tr>
          <tr>
            <td>
              PROFESSIONAL LIABILITY*
              <i>Covers professionals for negligence and errors and omissions that
                injure clients</i>
            </td>
            <td>
              $500,000
            </td>
          </tr>
          </tbody>
        </table>

        <p>
          <i>*Professionals are expected to have extensive technical knowledge or
            training
            in their particular area of expertise. They are also expected to perform
            the
            services for which they were hired, according to the standards of
            conduct in
            their
            profession. If they fail to use the degree of skill expected of them,
            they
            can
            be
            held responsible in a court of law for any harm they cause to another
            person
            or
            business.
          </i>
        </p>
        <p>
          <i>
            *Professionals that operate their own businesses need professional
            liability
            insurance in addition to an in-home business or business owner’s policy.
            This
            protects them against financial losses from lawsuits filed against them
            by
            their
            clients.
          </i>
        </p>
        <p>
          *When liability is limited to acts of negligence, professional liability
          insurance
          may be called “errors and omissions” liability.
        </p>
        <p>
          Your insurance certificate must list, as the certificate holder, Home
          Innovation
          Research Labs, 400 Prince George’s Boulevard, Upper Marlboro, MD 20774-8731.
          Your
          general and automobile liability insurance certificate must also name the
          following
          as additional insured:
        </p>
        <p>
          Home Innovation Research Labs and its officers, directors, agents,
          affiliates,
          and
          employees as additional insureds for liability arising out of the work of
          the
          Verifier.
        </p>
      </div>
    </div>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="btn btn-primary" [mat-dialog-close]="true" cdkFocusInitial>
    Close
  </button>
</mat-dialog-actions>
