import { CREATE_MODE, EDIT_MODE } from '@/core/constants';
import { UtilityRateBackendDict } from '@/data/simulation/models/utility/UtilityRate';
import { addUtilityRate } from '@/modules/simulation/state/simulation/actions';
import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export class UtilityRateChangeDialogData {
  entityId?: number;
  parentId?: number;
  mode: typeof EDIT_MODE | typeof CREATE_MODE;
  commit?: boolean = false;
  retrieve?: boolean = false;
  excludedFuels?: string[];
}

@Component({
  selector: 'app-utility-rate-dialog',
  templateUrl: './utility-rate-dialog.component.html',
  styleUrls: ['./utility-rate-dialog.component.scss'],
})
export class UtilityRateDialogComponent {
  public editorIsVisible = false;
  public editMode: boolean;
  public entityId: number;

  componentDestroyed$ = new Subject();
  utilitySelectControl: any;
  excludeUtilityRates: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UtilityRateChangeDialogData,
    public dialogRef: MatDialogRef<UtilityRateDialogComponent>,
    public store: Store
  ) {}

  initUtilitySelector() {
    this.editorIsVisible = false;
    this.utilitySelectControl = new FormControl(null);

    this.utilitySelectControl.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(entity => {
        this.store.dispatch(addUtilityRate({ entity }));

        this.dialogRef.close();
      });
  }

  excludeUtilityRateWithFuel(rate: UtilityRateBackendDict) {
    return !this.excludeUtilityRates.includes(rate.fuel);
  }

  ngOnInit(): void {
    if (this.data.mode === CREATE_MODE && this.data.entityId) {
      throw new Error(
        'Cannot create a new wall type with an existing wall type id or ' +
          'wall type'
      );
    }

    if (this.data.mode === EDIT_MODE) {
      this.editorIsVisible = this.data.mode === EDIT_MODE;
      this.entityId = this.data.entityId;
    } else {
      this.excludeUtilityRates = this.data.excludedFuels;
      this.initUtilitySelector();
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
