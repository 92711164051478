<mat-expansion-panel [expanded]="expanded">
    <mat-expansion-panel-header class="headers-align">
        <mat-panel-title>
            <strong>
                [{{analysisEngineLabels[entity.engine]}}]&nbsp; {{analysisTypeLabels[entity.type]}}
            </strong>
        </mat-panel-title>
        <mat-panel-description>
            <div class="d-flex justify-content-between w-100">
                <div>
                    {{entity.simulation_date | date:'medium'}}

                </div>
                <div class="result-changes" >
                    <ng-container *ngIf="entity.status === 'complete'">
                        <span class="font-italic text-primary ml-2">{{fileCount}} file{{fileCount > 1 ? 's' : ''}}</span>
                        <span class="font-weight-bold" *ngIf="entity.eri_score">ERI Score: {{entity.eri_score | number:'1.2-2'}}</span>
                        <span class="badge" *ngIf="change" [ngClass]="{'badge-success': change > 0, 'badge-danger': change < 0}">{{ change > 0 ? '+' : ''}}{{change | number: '1.2-2'}}%</span>
                    </ng-container>
                    <mat-icon [class]="statusIcon + '-icon'">{{statusIcon}}</mat-icon>
                </div>
            </div>
        </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>

        <mat-card *ngIf="entity">
            <mat-card-content>
                <div class="report-container" *ngIf="entity.status === 'complete'">
                    <app-analysis-summary [entity]="entity"></app-analysis-summary>
                    <app-emissions [entity]="detailedAnalysis.emissions" *ngIf="detailedAnalysis.emissions"></app-emissions>
                    <app-component-loads [entity]="detailedAnalysis.component_loads"
                        *ngIf="detailedAnalysis.component_loads"></app-component-loads>
                    <app-fuel-usage [fuelUsages]="detailedAnalysis.fuel_usages"
                        *ngIf="detailedAnalysis.fuel_usages"></app-fuel-usage>
                </div>

                <div class="pending-report-container" *ngIf="entity.status === 'pending' || entity.status === 'started'">
                    <i *ngIf="entity.status === 'pending'" class="queue">Is in queue!</i>
                    <strong *ngIf="entity.status === 'started'">Analysis Running!</strong>
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div>

                <div *ngIf="entity.status === 'failed'">
                    <p>Analysis failed!</p>
                    <pre><code>{{entity.result}}</code></pre>
                </div>

            </mat-card-content>
            <mat-divider></mat-divider>
            <mat-card-actions class="ml-2" *ngIf="entity.status === 'complete'">
                <h5>Files for download</h5>
                <div class="files-container mt-2">
                    <ng-container *ngFor="let item of filesToDownload; let i = index">
                        <div class="d-flex flex-column button-container" *ngIf="(!item.requiresBigLadder || (item.requiresBigLadder && bigLadderFilesAllowed)) && entity.urls[item.urlKey]">
                            <div class="position-relative w-100 h-100"> 
                                <button mat-icon-button class="w-100 h-100">
                                    <fa-icon [icon]="['far', item.fileType === 'pdf' ? 'file-pdf' : 'file']" size="4x"></fa-icon>
                                </button>
                                <a mat-icon-button class="download-icon" [href]="getURL(item.urlKey)" [matTooltip]="item.name + '.' + item.fileType" target="_blank">
                                    <fa-icon [icon]="['fas', 'download']" size="2x"></fa-icon>
                                </a>
                            </div>
                            <span>{{ item.name | truncate : 10}}.{{item.fileType}}</span>
                        </div>
                    </ng-container>
                </div>
            </mat-card-actions>
        </mat-card>
    </ng-template>
</mat-expansion-panel>
