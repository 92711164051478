export interface Errors {
  [id: string]: any;
}
export type FieldErrors<Model> = {
  [K in keyof Partial<Model>]: Errors;
};

export type ModelErrors<Model> = {
  field: FieldErrors<Model>;
  model: Errors;
};

export interface ModelErrorsWithCount<Model> extends ModelErrors<Model> {
  errorCount: number;
  warningCount: number;
}

export type StateErrors<Model> = {
  [id: number]: ModelErrorsWithCount<Model>;
};

export interface BaseState<Model> {
  entities: { [id: number]: Model };
  loading: { [id: number]: boolean };
  errors: StateErrors<Model>;
}

export const sharedInitialState = {
  entities: {},
  loading: {},
  errors: {},
};
