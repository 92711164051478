import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { loadInfo, loadImpersonations } from '../actions/info.actions';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers';

@Injectable()
export class InfoEffects {
  constructor(private actions$: Actions, private store: Store<AppState>) {}

  storeImpersonation$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(loadInfo),
        tap(info => {
          const impersonated = info.payload && info.payload.impersonated;

          if (impersonated) {
            let list =
              JSON.parse(localStorage.getItem('impersonationList')) || [];

            if (!Array.isArray(list)) {
              list = [];
            } // reset in random case that bad data is previously stored

            if (list.length > 10) {
              list.pop();
            } // keep this list pruned

            const idx = list.findIndex(user => user.id === impersonated.id);

            if (idx === -1) {
              list.unshift(impersonated);
            }

            localStorage.setItem('impersonationList', JSON.stringify(list));

            this.store.dispatch(loadImpersonations({ payload: list }));
          }
        })
      );
    },
    { dispatch: false }
  );
}
