import { Location, Color, AreaUnit } from '@/data/simulation/enumerations';
import { RoofTypeBackendDict } from './RoofType';
import { ModelFormControl, ModelFormGroup } from '../ModelForm';
import { Validators } from '@angular/forms';
import {
  belowWarningRangeValidator,
  enumValidator,
  requiredAsWarning,
} from '@/modules/simulation/validators/helper';
import { FieldLabels } from '../base';
import { SimulationModelsState } from '@/modules/simulation/state/reducers';

export enum InteriorLocation {
  VAULTED_ROOF = Location.VAULTED_ROOF,
  ATTIC_VENTED = Location.ATTIC_VENTED,
  SEALED_ATTIC = Location.SEALED_ATTIC,
  GARAGE = Location.GARAGE,
  CONDITIONED_SPACE = Location.CONDITIONED_SPACE,
}

export enum ExteriorLocation {
  AMBIENT = Location.AMBIENT,
}

export enum ExteriorColor {
  LIGHT = Color.LIGHT,
  MEDIUM = Color.MEDIUM,
  MEDIUM_DARK = Color.MEDIUM_DARK,
  DARK = Color.DARK,
  REFLECTIVE = Color.REFLECTIVE,
  // STD140 = Color.STD140,
  // STD140_LOWABS = Color.STD140_LOWABS,
  // WHITE_COMPOSITE = Color.WHITE_COMPOSITE,
  // WHITE_TILE = Color.WHITE_TILE,
  // WHITE_METAL = Color.WHITE_METAL,
}

export const FIELD_LABELS: FieldLabels<RoofBackendDict> = {
  name: 'Name',
  type: 'Type',
  interior_location: 'Interior Location',
  exterior_location: 'Exterior Location',
  exterior_color: 'Exterior Color',
  ceiling_area: {
    label: 'Ceiling Area',
    description: `
    Enter the total finished ceiling area, including skylights, that is exposed to either an attic or outdoor conditions.
    If there is an unfinished attic, use the finished ceiling area rather than the roof area.`,
  },
  roof_area: {
    label: 'Roof Area',
    description: `Use this field to enter the estimated area of roof surfaces exposed to outdoor conditions.`,
  },
  area_units: 'Area Units',
  pitch: {
    label: 'Pitch',
    description: `
      Pitch is stored as X/12, such that e.g. pitch=4 (the most common US roof pitch) means a
      slope of 4 inches of drop for every 12 inches of horizontal travel.  Please enter the exterior roof surface
      pitch (not the interior side). You may leave this blank if you have the roof pitch assigned.`,
  },
  solar_absorptance: {
    label: 'Solar Absorptance',
    description: `
      The fraction of solar energy absorbed by the roof. This value is between 0 and 1. A higher value means the roof
      absorbs more solar energy.`,
  },
  emittance: {
    label: 'Emittance',
    description: `
      The fraction of long-wave radiation emitted by the roof. This value is between 0 and 1. A higher value means the roof
      emits more long-wave radiation.`,
  },
  attic_ventilation_rate: {
    label: 'Attic Ventilation Rate',
    description: `
      The rate of ventilation in the attic. This value is in air changes per hour (ACH).`,
  },
  attic_within_infiltration_volume: 'Attic Within Infiltration Volume',
};

export interface RoofBackendDict {
  id: number;
  type: number;
  name: string;
  interior_location: InteriorLocation;
  exterior_location: ExteriorLocation;
  exterior_color: ExteriorColor;
  ceiling_area: number;
  roof_area: number;
  area_units: AreaUnit.SQ_FT;
  pitch: number;
  solar_absorptance: number;
  emittance: number;
  attic_ventilation_rate: number | null;
  attic_within_infiltration_volume: boolean;
}

export interface DetailedRoofBackendDict extends RoofBackendDict {
  type_info: RoofTypeBackendDict;
}

export function createRoofForm(roof: RoofBackendDict): ModelFormGroup {
  return new ModelFormGroup(
    {
      id: new ModelFormControl(roof.id, Validators.required),
      name: new ModelFormControl(roof.name, [
        Validators.required,
        Validators.maxLength(128),
      ]),
      type: new ModelFormControl(roof.type, Validators.required),
      interior_location: new ModelFormControl(roof.interior_location, [
        enumValidator(InteriorLocation),
      ]),
      exterior_location: new ModelFormControl(roof.exterior_location, [
        enumValidator(ExteriorLocation),
      ]),
      exterior_color: new ModelFormControl(roof.exterior_color, [
        enumValidator(ExteriorColor, true),
        requiredAsWarning(),
      ]),
      ceiling_area: new ModelFormControl(roof.ceiling_area, [
        Validators.required,
        Validators.min(0),
        belowWarningRangeValidator(5),
      ]),
      roof_area: new ModelFormControl(roof.roof_area, [
        Validators.required,
        Validators.min(0),
      ]),
      pitch: new ModelFormControl(roof.pitch),
      solar_absorptance: new ModelFormControl(roof.solar_absorptance, [
        Validators.min(0),
        Validators.max(1),
      ]),
      emittance: new ModelFormControl(roof.emittance, [
        Validators.min(0),
        Validators.max(1),
      ]),
      attic_ventilation_rate: new ModelFormControl(roof.attic_ventilation_rate),
      attic_within_infiltration_volume: new ModelFormControl(
        roof.attic_within_infiltration_volume
      ),
    },
    {
      validators: [validateCeilingArea],
    }
  );
}

function validateCeilingArea(controls: ModelFormGroup) {
  // Ceiling area should not be greater than roof area
  const ceilingArea = controls.get('ceiling_area').value;
  const roofArea = controls.get('roof_area').value;

  if (ceilingArea > roofArea) {
    return {
      ceilingAreaExceedsRoofArea: {
        ceilingArea,
        roofArea,
      },
    };
  }
}

export function denormalizeRoofs(
  state: SimulationModelsState
): DetailedRoofBackendDict[] {
  const denormalizedRoof: DetailedRoofBackendDict[] = [];
  for (const roof of Object.values(state.roof.entities)) {
    denormalizedRoof.push({
      ...roof,
      type_info: state.roofType[roof.type],
    });
  }
  return denormalizedRoof;
}
