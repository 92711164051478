import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-foreign-model-field',
  templateUrl: './foreign-model-field.component.html',
  styleUrls: ['./foreign-model-field.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class ForeignModelFieldComponent {
  @Input() label = '';
  @Input() name = '';
  @Input() errorCount = 0;
  @Input() options: string[] = ['add', 'edit', 'remove'];
  @Input() subLabel: string;
  @Input() subValue: string | number;
  @Input() errors: ValidationErrors = {};

  @Output() add = new EventEmitter<any>();
  @Output() remove = new EventEmitter<any>();
  @Output() edit = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();
  @Output() replace = new EventEmitter<any>();

  value: any;
  isInEditMode = false;

  handleEnter($event) {
    $event.srcElement.click();
    $event.stopPropagation();
    $event.preventDefault();
  }
}
