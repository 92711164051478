<ng-container *ngIf="subdivision">
  <ng-container [ngSwitch]="displayType">
    <ng-container *ngSwitchCase="SubdivisionDisplayComponentType.onlyName">{{ subdivision?.name }}</ng-container>
    <ng-container *ngSwitchCase="SubdivisionDisplayComponentType.nameAndBuilder">
      {{ subdivision?.name }}
      <ng-container *ngIf="subdivision.builder_org_info">({{ subdivision.builder_org_info?.name }})</ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="SubdivisionDisplayComponentType.onlyNameAsLink">
      <a [routerLink]="['/', 'subdivision', subdivision.id]">
        {{ subdivision?.name }}
      </a>
    </ng-container>
    <ng-container *ngSwitchCase="SubdivisionDisplayComponentType.nameAndBuilderAsLink">
      <a [routerLink]="['/', 'subdivision', subdivision.id]">
        {{ subdivision?.name }}
        <ng-container *ngIf="subdivision.builder_org_info">({{ subdivision.builder_org_info?.name }})</ng-container>
      </a>
    </ng-container>
  </ng-container>
</ng-container>
