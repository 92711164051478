import { Injectable } from '@angular/core';

import { ListRequestParams } from '@/core/schemes/request-params';
import { environment } from '../../../../environments/environment';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import { Observable } from 'rxjs';
import {
  SeedBackendDict,
  SeedCreateBackendDict,
} from '@/data/simulation/models';
import { ServerResponse } from '@/core/schemes/server-response';

export class SeedRequestParams extends ListRequestParams {}

@Injectable({ providedIn: 'root' })
export class SeedService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/simulation-seeds/`;

  list(params?: SeedRequestParams) {
    return this.http.get<ServerResponse<SeedBackendDict>>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }

  retrieve(
    seedId: number,
    options?: IDataServiceMethodOptions
  ): Observable<SeedBackendDict> {
    return this.http.get<SeedBackendDict>(`${this.baseUrl}${seedId}`, {});
  }

  create(seed: SeedCreateBackendDict): Observable<SeedBackendDict> {
    return this.http.post<SeedBackendDict>(`${this.baseUrl}`, seed);
  }
}
