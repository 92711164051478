<div class="main-wrapper-container">
  <div id="main-container">


    <div id="header" class="clearfix">

      <div class="interior clearfix">

        <a id="logo" href="https://www.homeinnovation.com/"><img
          src="assets/pages/ngbs-find-verifier/home-innovation-logo.png"
          alt="Home Innovation Research Labs - Formerly NAHB Research Center"
          width="324" height="73"></a>
      </div>

      <!--ZOOMSTOP-->
      <div id="breadcrumb-container" class="clearfix">
        <ul class="breadcrumbs clearfix">
          <li class="home"><a href="https://www.homeinnovation.com/">Home</a></li>
          <li><a href="https://www.homeinnovation.com/services">Services</a></li>
          <li><a href="https://www.homeinnovation.com/services/certification">Certification</a></li>
          <li><a href="https://www.homeinnovation.com/services/certification/green_homes">Green Homes </a></li>
          <li><a href="https://www.homeinnovation.com/services/certification/green_homes/resources">NGBS Certification
            Resources</a></li>
          <li class="on"><a [routerLink]="['/', 'find_verifier']">Find a
            Verifier</a></li>
        </ul>

        <img src="assets/pages/ngbs-find-verifier/help-button.png" width="217" height="60"
             alt="Learn How We Can Help (800) 638-8556" class="help-button">
      </div>
      <!--ZOOMRESTART-->


    </div>

    <div id="main_photo_container" class="certification" #tableTopScroll>
      <div class="main-banner"><img id="ctl01_imgBanner" src="assets/pages/ngbs-find-verifier/banner_37.jpeg">
      </div>
      <div class="title">Certification</div>
      <div class="sub-title">Find a Verifier</div>
      <div id="h1-container"></div>
      <h2>NGBS Green Verifiers with Existing Building Experience</h2>
    </div><!-- / #main_photo_container -->

    <div id="content-container">
      <div id="content" class="clearfix">
        <div class="center-col reports results detail" style="width: 100%;">
          <p>
            NGBS Green Verifiers are authorized to verify new construction, existing buildings, and land development
            projects. However, some Verifiers develop specialty with certain project types. These Verifiers have
            demonstrated experience with projects earning the NGBS Green <a
            href="https://www.homeinnovation.com/services/certification/green_homes/existing_building_certification"
            target="_blank">Existing Building Certification.</a>
          </p>
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-3">
                    <div class="row">
                      <div class="col-12">
                        <div class="card">
                          <h5 class="card-header">
                            Filters
                            <a href="#" class="reset-filters-link" (click)="resetFilters($event);">Reset filters</a>
                          </h5>
                          <form [formGroup]="filterFromGroup" class="card-body">
                            <div class="row">
                              <div class="col-12">
                                <mat-form-field class="w-100">
                                  <mat-label>Search</mat-label>
                                  <app-search-input formControlName="search"
                                                    placeholder="Type to search something"></app-search-input>
                                </mat-form-field>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
                                <mat-form-field class="w-100 mb-2">
                                  <mat-label>Type</mat-label>
                                  <mat-select aria-label="Type" formControlName="accreditations__name">
                                    <mat-option [value]="''">
                                      Any
                                    </mat-option>
                                    <mat-option
                                      *ngFor="let item of accreditationNameLabelMapping | defaultOrderKeyValue"
                                      [value]="item.key">
                                      {{ item.value }}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
                                <mat-form-field class="w-100">
                                  <mat-label>US State</mat-label>
                                  <app-generic-mat-select modelClassName="us_state"
                                                          formControlName="us_states"
                                                          [emptyChoiceValue]="''"
                                                          [emptyChoiceLabel]="'All'"
                                                          [hasEmptyChoice]="true"
                                                          [pageSize]="300" multiple="false"
                                                          parentKey="abbr"></app-generic-mat-select>
                                </mat-form-field>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
                                <mat-form-field class="w-100">
                                  <mat-label>Country</mat-label>
                                  <app-generic-mat-select formControlName="company__city__country"
                                                          modelClassName="country"
                                                          [filterParams]="{ordering: 'name', abbr: ['US', 'PR', 'VI']}"
                                                          multiple="false"
                                                          [emptyChoiceValue]="''"
                                                          [emptyChoiceLabel]="'All'"
                                                          [hasEmptyChoice]="true"
                                                          [panelWidth]="640"></app-generic-mat-select>
                                </mat-form-field>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-9">
                    <ngx-datatable
                      class="material ngx-datatable-sticky-header"
                      [rows]="verifiers"
                      columnMode="force"
                      [headerHeight]="70"
                      [rowHeight]="'auto'"
                      [footerHeight]="50"
                      [limit]="storedParams.page_size"
                      [reorderable]="false"
                      [draggable]="false"
                      [externalSorting]="true"
                      (sort)="onSort($event)"
                      [sorts]="[{prop: storedParams.ordering.replace('-', ''), dir: (storedParams.ordering.startsWith('-')) ? 'desc' : 'asc'}]"
                      [loadingIndicator]="isLoading">

                      <ngx-datatable-column [width]="210" prop="first_name" name="Verifier Name">
                        <ng-template let-row="row" let-value="value"
                                     ngx-datatable-cell-template>
                          <a href="#" (click)="onShowVerifier($event, row)" style="text-decoration: underline;">
                            {{ row.first_name }} {{ row.last_name }}
                          </a>
                          <br>
                          {{ row.company_info?.name }}
                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column prop="company__city__name" name="Office Location" [width]="210">
                        <ng-template let-row="row" let-value="value"
                                     ngx-datatable-cell-template>
                          {{ row.company_info?.city_info?.name }},
                          <span *ngIf="row.company_info?.city_info?.country_info?.abbr !== 'US'">
                                                          {{ row.company_info?.city_info?.country }}
                                                      </span>
                          <span *ngIf="row.company_info?.city_info?.country_info?.abbr === 'US'">
                                                          {{ row.company_info?.state }}
                                                      </span>
                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column prop="sf_remodel_projects" name="Single-Family<br>Homes" [sortable]="true"
                                            cellClass="text-center">
                        <ng-template let-row="row" let-value="value"
                                     ngx-datatable-cell-template>
                                                    <span>
                                                        {{ row.sf_remodel_projects }}
                                                    </span>
                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column prop="mf_remodel_projects" name="Multifamily<br>Units" [sortable]="true"
                                            cellClass="text-center">
                        <ng-template let-row="row" let-value="value"
                                     ngx-datatable-cell-template>
                                                    <span>
                                                        {{ row.mf_remodel_projects }}
                                                    </span>
                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-footer>
                        <ng-template ngx-datatable-footer-template>
                          <div class="col-12 text-center">
                            <datatable-pager
                              [pagerLeftArrowIcon]="'datatable-icon-left'"
                              [pagerRightArrowIcon]="'datatable-icon-right'"
                              [pagerPreviousIcon]="'datatable-icon-prev'"
                              [pagerNextIcon]="'datatable-icon-skip'"
                              [page]="storedParams.page"
                              [size]="storedParams.page_size"
                              [count]="verifiersCount"
                              (change)="onFooterPage($event)"
                            >
                            </datatable-pager>
                          </div>
                        </ng-template>
                      </ngx-datatable-footer>
                    </ngx-datatable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div><!-- / #content -->
    </div><!-- / #content-container -->
  </div>
</div>
