<div class="main-container">
    <div class="d-flex mt-3 position-relative justify-content-center">
        <ng-container
            *ngTemplateOutlet="analysisType; context: { sourceName: openStudioAnalysisSourceName.ERI_CALCULATION }">
        </ng-container>
        <ng-container
            *ngTemplateOutlet="analysisType; context: { sourceName: openStudioAnalysisSourceName.ESTAR_CALCULATION }">
        </ng-container>
        <div class="d-flex flex-column">
            <div class="d-flex">
                <ng-container
                    *ngTemplateOutlet="analysisType; context: { sourceName: openStudioAnalysisSourceName.CO2_CALCULATION }">
                </ng-container>
                <ng-container
                    *ngTemplateOutlet="analysisType; context: { sourceName: openStudioAnalysisSourceName.IECC_CALCULATION }">
                </ng-container>
                <ng-container
                    *ngTemplateOutlet="analysisType; context: { sourceName: openStudioAnalysisSourceName.ZERH_CALCULATION }">
                </ng-container>
            </div>
            <div class="d-flex">
                <ng-container
                    *ngTemplateOutlet="analysisType; context: { sourceName: openStudioAnalysisSourceName.UDRH_CALCULATION }">
                </ng-container>
            </div>
            <div class="d-flex">
                <div class="d-flex flex-column">
                    <label><strong>Weather model</strong></label>
                    <mat-form-field appearance="fill">
                        <mat-select [formControl]="weatherTypeControl">
                            <mat-option *ngFor="let item of analysisWeatherTypeLabel | defaultOrderKeyValue"
                                [value]="item.key">
                                {{ item.value }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <div class="text-center">
        <span><i class="asterisk" *ngIf="indicateDefaults">* Marked items are the company's default settings for running simulations.</i></span>
    </div>
    <ng-content></ng-content>
</div>

<ng-template #analysisType let-sourceName="sourceName">
    <div class="mr-4" *ngIf="analysisSuites[sourceName]">
        <label class="d-flex align-items-center">
            <strong>{{openStudioAnalysisSourceNameLabels[sourceName]}}</strong>
            <div class="udrh-button-container" *ngIf="sourceName === openStudioAnalysisSourceName.UDRH_CALCULATION">
                <button mat-button (click)="createUDRH($event)" color="accent">Add new +</button>
            </div>
        </label>
        <div class="type-item" *ngFor="let item of analysisSuites[sourceName]">
            <mat-checkbox [ngModel]="this.checked[item.id]" [value]="item.id" [id]="item.id"
                (ngModelChange)="toggleAnalysisType(item.id)">
                {{item.name}} <i class="asterisk" *ngIf="indicateDefaults">{{ defaults[item.id] ? '*' : '' }}</i>
            </mat-checkbox>
        </div>
        <div class="text-center" *ngIf="loadingNewItem && sourceName === openStudioAnalysisSourceName.UDRH_CALCULATION">
            <mat-spinner [diameter]="20"></mat-spinner>
        </div>
    </div>
</ng-template>