import { createSelector } from '@ngrx/store';
import { SimulationState } from '../reducers';
import { selectSimulationFeature } from '../simulation.selectors';

export const selectHeater = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.heater.entities[id]
  );

const selectHeaterLoading = (id: number) =>
  createSelector(selectSimulationFeature, state => state.heater.loading[id]);

export const selectHeaterViewSet = (id: number) =>
  createSelector(
    selectHeater(id),
    selectHeaterLoading(id),
    (data, loading) => ({ data, loading })
  );

export const selectHeaterErrors = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.heater.errors[id]
  );
