import {
  ModelFormControl,
  ModelFormGroup,
  createConditionalValidator,
} from '../ModelForm';
import { Validators } from '@angular/forms';
import { DuctBackendDict } from './Duct';
import { FieldLabels } from '../base';
import { enumValidator } from '@/modules/simulation/validators/helper';
import { SimulationModelsState } from '@/modules/simulation/state/reducers';

export enum DistributionSystemType {
  FORCED_AIR = 'forced_air',
  DUCTLESS = 'ductless',
  // RADIANT = 'radiant',
  HYDRONIC = 'hydronic',
  DSE = 'dse',
}

export const DistributionSystemTypeLabels: Record<
  DistributionSystemType,
  string
> = {
  [DistributionSystemType.FORCED_AIR]: 'Forced-air',
  [DistributionSystemType.DUCTLESS]: 'Forced-air Ductless',
  // [DistributionSystemType.RADIANT]: 'Radiant',
  [DistributionSystemType.HYDRONIC]: 'Hydronic',
  [DistributionSystemType.DSE]: 'Distribution System Estimate',
};

export enum DistributionSystemLeakageUnit {
  ACH50 = 'ach50',
  CFM25 = 'cfm25',
  CFM50 = 'cfm50',
  CFM25FAN = 'cfm25fan',
  CFM25CFA = 'cfm25cfa',
  EFF_LEAKAGE = 'eff_leakage',
  SPECIFIC_LEAKAGE = 'specific_leakage',
  ELA100 = 'ela100',
  CFM50SHELL = 'cfm50shell',
  THERMAL_EFF = 'therm_eff',
  UNKNOWN = 'unknown',
  CFMSTD152 = 'cfmstd152',
}

export const DistributionSystemLeakageUnitLabels: Record<
  DistributionSystemLeakageUnit,
  string
> = {
  [DistributionSystemLeakageUnit.ACH50]: 'ACH @ 50 Pascals',
  [DistributionSystemLeakageUnit.CFM25]: 'CFM @ 25 Pascals',
  [DistributionSystemLeakageUnit.CFM50]: 'CFM @ 50 Pascals',
  [DistributionSystemLeakageUnit.CFM25FAN]: 'CFM25 / CFMfan',
  [DistributionSystemLeakageUnit.CFM25CFA]: 'CFM25 / CFA',
  [DistributionSystemLeakageUnit.EFF_LEAKAGE]: 'Effective Leakage Area (in²)',
  [DistributionSystemLeakageUnit.SPECIFIC_LEAKAGE]: 'Specific Leakage Area',
  [DistributionSystemLeakageUnit.ELA100]: 'ELA/100 sq ft shell',
  [DistributionSystemLeakageUnit.CFM50SHELL]: 'CFM 50 / sq ft shell',
  [DistributionSystemLeakageUnit.THERMAL_EFF]: 'Thermal Efficiency (%)',
  [DistributionSystemLeakageUnit.UNKNOWN]: 'Unknown',
  [DistributionSystemLeakageUnit.CFMSTD152]: 'CFM per Std 152',
};

export enum DistributionSystemTestType {
  THRESHOLD = 'threshold',
  MEASURED = 'measured',
  UNTESTED = 'untested',
  UNKNOWN = 'unknown',
}

export const DistributionSystemTestTypeLabels: Record<
  DistributionSystemTestType,
  string
> = {
  [DistributionSystemTestType.THRESHOLD]: 'Threshold / Sampled',
  [DistributionSystemTestType.MEASURED]: 'Measured',
  [DistributionSystemTestType.UNTESTED]: 'Untested',
  [DistributionSystemTestType.UNKNOWN]: 'Unknown',
};

export enum HydronicDistributionType {
  RADIATOR = 'radiator',
  BASEBOARD = 'baseboard',
  RADIANT_FLOOR = 'radiant_floor',
  RADIANT_CEILING = 'radiant_ceiling',
  WATER_LOOP = 'water_loop',
}

export const HydronicDistributionTypeLabels: Record<
  HydronicDistributionType,
  string
> = {
  [HydronicDistributionType.RADIATOR]: 'Radiator',
  [HydronicDistributionType.BASEBOARD]: 'Baseboard',
  [HydronicDistributionType.RADIANT_FLOOR]: 'Radiant Floor',
  [HydronicDistributionType.RADIANT_CEILING]: 'Radiant Ceiling',
  [HydronicDistributionType.WATER_LOOP]: 'Water Loop',
};

export enum TotalLeakageTestCondition {
  POST_CONSTRUCTION = 'post',
  ROUGH_IN_HANDLER = 'rough_w_hdlr',
  ROUGH_IN_NOHANDLER = 'rough_wo_hdlr',
  NOT_TESTED = 'untested',
}

export const TotalLeakageTestConditionLabels: Record<
  TotalLeakageTestCondition,
  string
> = {
  [TotalLeakageTestCondition.POST_CONSTRUCTION]: 'Post Construction',
  [TotalLeakageTestCondition.ROUGH_IN_HANDLER]: 'Rough-in w/ Air Handler',
  [TotalLeakageTestCondition.ROUGH_IN_NOHANDLER]: 'Rough-in w/o Air Handler',
  [TotalLeakageTestCondition.NOT_TESTED]: 'Not Tested',
};

// Labels
export const FIELD_LABELS: FieldLabels<DistributionSystemBackendDict> = {
  name: 'Name',
  ducts: 'Ducts',
  system_type: 'System Type',
  hydronic_distribution_type: 'Hydronic Distribution Type',
  test_type: 'Test Type',
  area_served: 'Area Served',
  supply_surface_area: 'Supply Surface Area',
  return_surface_area: 'Return Surface Area',
  area_units: 'Area Units',
  qty_return_grills: 'Qty Return Grills',
  leakage_to_outside_tested: 'Leakage To Outside Tested',
  leakage_to_outside: 'Leakage To Outside',
  total_leakage_condition: 'Total Leakage Condition',
  total_leakage: 'Total Leakage',
  field_test_leakage_to_outside: 'Field Test Leakage To Outside',
  field_test_total_leakage: 'Field Test Total Leakage',
  leakage_unit: 'Leakage Unit',
  iecc_test_exemption: 'IECC Test Exemption',
  eri_test_exemption: 'ERI Test Exemption',
  energy_star_leakage_to_outside_exemption:
    'Energy Star Leakage To Outside Exemption',
  system_in_conditioned_space: 'System In Conditioned Space',
  estimated_system_efficiency: 'Estimated System Efficiency',
  dse_annual_heating_system_efficiency: 'Heating System Efficiency',
  dse_annual_cooling_system_efficiency: 'Cooling System Efficiency',
  heating_system: 'Heating System',
  cooling_system: 'Cooling System',
};

export const DEFAULT_DICT: DistributionSystemBackendDict = {
  id: 0,
  name: '',
  ducts: [],
  system_type: DistributionSystemType.FORCED_AIR,
  hydronic_distribution_type: HydronicDistributionType.RADIATOR,
  test_type: DistributionSystemTestType.UNTESTED,
  area_served: 0,
  supply_surface_area: 0,
  return_surface_area: 0,
  area_units: '',
  qty_return_grills: 0,
  leakage_to_outside_tested: false,
  leakage_to_outside: 0,
  total_leakage_condition: TotalLeakageTestCondition.NOT_TESTED,
  total_leakage: 0,
  field_test_leakage_to_outside: 0,
  field_test_total_leakage: 0,
  leakage_unit: DistributionSystemLeakageUnit.CFM25,
  iecc_test_exemption: false,
  eri_test_exemption: false,
  energy_star_leakage_to_outside_exemption: false,
  system_in_conditioned_space: false,
  estimated_system_efficiency: 0,
  dse_annual_heating_system_efficiency: 0,
  dse_annual_cooling_system_efficiency: 0,
  heating_system: 0,
  cooling_system: 0,
};

export interface DistributionSystemBackendDict {
  id: number;
  name: string;
  ducts: number[];
  system_type: DistributionSystemType;
  hydronic_distribution_type: HydronicDistributionType;
  test_type: DistributionSystemTestType;
  area_served: number;
  supply_surface_area: number;
  return_surface_area: number;
  area_units: string;
  qty_return_grills: number;
  leakage_to_outside_tested: boolean;
  leakage_to_outside: number;
  total_leakage_condition: TotalLeakageTestCondition;
  total_leakage: number;
  field_test_leakage_to_outside: number;
  field_test_total_leakage: number;
  leakage_unit: DistributionSystemLeakageUnit;
  iecc_test_exemption: boolean;
  eri_test_exemption: boolean;
  energy_star_leakage_to_outside_exemption: boolean;
  system_in_conditioned_space: boolean;
  estimated_system_efficiency: number;
  dse_annual_heating_system_efficiency: number;
  dse_annual_cooling_system_efficiency: number;

  heating_system: number;
  cooling_system: number;
}

export interface DetailedDistributionSystemBackendDict
  extends DistributionSystemBackendDict {
  ducts_info: DuctBackendDict[];
}

export function createDistributionSystemForm(
  data: DistributionSystemBackendDict
): ModelFormGroup {
  const validateOnlyIfNotDSESystem = createConditionalValidator(
    parentControls =>
      parentControls.system_type.value !== DistributionSystemType.DSE,
    ['system_type']
  );

  const validateOnlyIfDSESystem = createConditionalValidator(
    parentControls =>
      parentControls.system_type.value === DistributionSystemType.DSE,
    ['system_type']
  );

  const validateOnlyIfDuctlessSystem = createConditionalValidator(
    parentControls =>
      parentControls.system_type.value === DistributionSystemType.DUCTLESS,
    ['system_type']
  );

  const validateOnlyIfHydronicSystem = createConditionalValidator(
    parentControls =>
      parentControls.system_type.value === DistributionSystemType.HYDRONIC,
    ['system_type']
  );

  return new ModelFormGroup(
    {
      id: new ModelFormControl(data.id),
      name: new ModelFormControl(data.name, [
        Validators.required,
        Validators.maxLength(128),
      ]),
      system_type: new ModelFormControl(
        data.system_type,
        enumValidator(DistributionSystemType)
      ),
      hydronic_distribution_type: new ModelFormControl(
        data.hydronic_distribution_type,
        ...validateOnlyIfHydronicSystem([
          enumValidator(HydronicDistributionType, true),
        ])
      ),
      test_type: new ModelFormControl(
        data.test_type,
        ...validateOnlyIfNotDSESystem([
          enumValidator(DistributionSystemTestType),
        ])
      ),
      area_served: new ModelFormControl(data.area_served, [
        Validators.required,
        Validators.min(0),
      ]),
      supply_surface_area: new ModelFormControl(
        data.supply_surface_area,
        ...validateOnlyIfNotDSESystem([Validators.required, Validators.min(0)])
      ),
      return_surface_area: new ModelFormControl(
        data.return_surface_area,
        ...validateOnlyIfNotDSESystem([Validators.required, Validators.min(0)])
      ),
      qty_return_grills: new ModelFormControl(data.qty_return_grills, [
        Validators.required,
        Validators.min(0),
      ]),
      leakage_to_outside_tested: new ModelFormControl(
        data.leakage_to_outside_tested,
        Validators.required
      ),
      leakage_to_outside: new ModelFormControl(
        data.leakage_to_outside,
        Validators.required
      ),
      total_leakage_condition: new ModelFormControl(
        data.total_leakage_condition,
        Validators.required
      ),
      total_leakage: new ModelFormControl(
        data.total_leakage,
        Validators.required
      ),
      field_test_leakage_to_outside: new ModelFormControl(
        data.field_test_leakage_to_outside,
        Validators.required
      ),
      field_test_total_leakage: new ModelFormControl(
        data.field_test_total_leakage,
        Validators.required
      ),
      leakage_unit: new ModelFormControl(
        data.leakage_unit,
        enumValidator(DistributionSystemLeakageUnit)
      ),
      iecc_test_exemption: new ModelFormControl(
        data.iecc_test_exemption,
        Validators.required
      ),
      eri_test_exemption: new ModelFormControl(
        data.eri_test_exemption,
        Validators.required
      ),
      energy_star_leakage_to_outside_exemption: new ModelFormControl(
        data.energy_star_leakage_to_outside_exemption,
        Validators.required
      ),
      system_in_conditioned_space: new ModelFormControl(
        data.system_in_conditioned_space,
        Validators.required
      ),
      estimated_system_efficiency: new ModelFormControl(
        data.estimated_system_efficiency,
        ...validateOnlyIfDSESystem([Validators.required])
      ),

      dse_annual_heating_system_efficiency: new ModelFormControl(
        data.dse_annual_heating_system_efficiency,
        ...validateOnlyIfDSESystem([
          Validators.nullValidator,
          Validators.min(0),
          Validators.max(1),
        ])
      ),
      dse_annual_cooling_system_efficiency: new ModelFormControl(
        data.dse_annual_cooling_system_efficiency,
        ...validateOnlyIfDSESystem([
          Validators.nullValidator,
          Validators.min(0),
          Validators.max(1),
        ])
      ),

      heating_system: new ModelFormControl(data.heating_system),
      cooling_system: new ModelFormControl(data.cooling_system),
    },
    {
      validators: [
        validateSystemType,
        validateTestType,
        validateFieldTestTotalLeakage,
        validateHeatingAndCoolingSystem,
        validateAreaServed,
      ],
    }
  );
}

function validateSystemType(controls: ModelFormGroup) {
  const systemType = controls.get('system_type').value;

  if (systemType === DistributionSystemType.DSE) {
    return { warningIfDSESystem: true };
  }
}

function validateTestType(controls: ModelFormControl) {
  const testType = controls.get('test_type').value;
  const fieldTestTotalLeakage = controls.get('field_test_total_leakage').value;
  const allowedTestTypes = [
    DistributionSystemTestType.THRESHOLD,
    DistributionSystemTestType.MEASURED,
  ];

  if (allowedTestTypes.includes(testType) && !fieldTestTotalLeakage) {
    return { fieldTestTotalLeakageRequired: { testType } };
  }
}

function validateFieldTestTotalLeakage(controls: ModelFormGroup) {
  const fieldTestTotalLeakage = controls.get('field_test_total_leakage').value;
  const leakageUnit = controls.get('leakage_unit').value;

  if (
    (leakageUnit === DistributionSystemLeakageUnit.CFM25 ||
      leakageUnit === DistributionSystemLeakageUnit.CFM50) &&
    (fieldTestTotalLeakage < 0 || fieldTestTotalLeakage > 100)
  ) {
    return { invalidFieldTestTotalLeakage: { fieldTestTotalLeakage } };
  }
}

function validateHeatingAndCoolingSystem(controls: ModelFormGroup) {
  const heatingSystem = controls.get('heating_system').value;
  const coolingSystem = controls.get('cooling_system').value;

  if (!heatingSystem && !coolingSystem) {
    return { heatingOrCoolingSystemRequired: true };
  }
}

function validateAreaServed(controls: ModelFormGroup) {
  const systemType = controls.get('system_type').value;
  const areaServed = controls.get('area_served').value;

  if (systemType === DistributionSystemType.FORCED_AIR && areaServed < 1) {
    return { areaServedForForcedAir: true };
  }
}

export function denormalizeDistributionSystems(
  state: SimulationModelsState
): DetailedDistributionSystemBackendDict[] {
  const denormalizedDistributionSystems: DetailedDistributionSystemBackendDict[] =
    [];
  for (const distributionSystem of Object.values(
    state.distributionSystem.entities
  )) {
    denormalizedDistributionSystems.push({
      ...distributionSystem,
      ducts_info: distributionSystem.ducts.map(id => state.duct.entities[id]),
    });
  }
  return denormalizedDistributionSystems;
}
