<router-outlet *ngIf="initialized"></router-outlet>


<div *ngIf="initialized && isLoading">

    <div style="position: fixed;
                left: 0;
                right: 0;
                text-align: center;
                top: 0;
                background: #888888;
                opacity: .4;
                z-index: 2000;
                bottom: 0;">
    </div>

    <div style="position: fixed;
                left: 0;
                right: 0;
                text-align: center;
                z-index: 2000;
                top: 400px;">
        <fa-icon [icon]="['fas', 'spinner']" [spin]="true" size="5x"></fa-icon>
    </div>
</div>

<div *ngIf="!initialized">
    <div style="position: fixed;
                left: 0;
                right: 0;
                text-align: center;
                z-index: 2000;
                top: 400px;">
        <img src="assets/axis_logo.png" height="40" width="100">
    </div>
</div>


