import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AuthenticationService } from '@/core/services/authentication.service';
import { first, mergeMap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { toggleLoading } from '@/state/actions/app.actions';
import {
  ServerErrorDialogComponent,
  ServerErrorDialogConfig,
} from '@/shared/components/server-error-dialog/server-error-dialog.component';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { MatDialog } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewInit {
  @ViewChild('usernameInput') usernameInput: MatInput;
  public loginFormGroup: UntypedFormGroup;

  constructor(
    public fb: UntypedFormBuilder,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public store: Store<AppState>,
    public dialog: MatDialog,
    public authenticationService: AuthenticationService,
    public cd: ChangeDetectorRef
  ) {
    this.loginFormGroup = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', Validators.required],
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.usernameInput.focus();
    this.cd.detectChanges();
  }

  login() {
    this.loginFormGroup.markAllAsTouched();

    if (this.loginFormGroup.invalid) {
      return;
    }
    this.store.dispatch(toggleLoading({ payload: true }));

    this.authenticationService
      .login(
        this.loginFormGroup.value.username,
        this.loginFormGroup.value.password
      )
      .pipe(
        first(),
        mergeMap(data => this.activatedRoute.queryParams.pipe(first()))
      )
      .subscribe(
        params => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.router.navigateByUrl(params.returnUrl || '/');
        },
        error => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialog.open(ServerErrorDialogComponent, {
            width: '400px',
            data: new ServerErrorDialogConfig({
              title: 'Error:',
              data: error,
            }),
          });
        }
      );
  }
}
