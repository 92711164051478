<div class="container" *ngIf="initialized">
    <div class="row">
        <div class="col-9">
            <div class="row">
                <div class="col-12">
                    <mat-tab-group color="accent" class="dashboard-tab-group" disableRipple="true" animationDuration="0ms" dynamicHeight>
                        <mat-tab label="Certification Activity" *ngIf="currentUser?.company_info?.slug === customerHIRLSettings.companySlug">
                            <ng-template matTabContent>
                                <div class="mt-2 mb-5">
                                    <app-customer-hirl-home-status-metric-table></app-customer-hirl-home-status-metric-table>
                                </div>
                            </ng-template>
                        </mat-tab>
                        <mat-tab label="Trend Data" *ngIf="currentUser?.company_info?.slug === customerHIRLSettings.companySlug">
                            <ng-template matTabContent>
                                <div class="mt-2 mb-5">
                                    <app-customer-hirl-registration-metric-tab></app-customer-hirl-registration-metric-tab>
                                </div>
                            </ng-template>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
        </div>
        <div class="col-3">
            <div class="row">
                <div class="col-12">
                    <h3>Latest site news:</h3>
                </div>
            </div>
            <app-news-column></app-news-column>
        </div>
    </div>
</div>
