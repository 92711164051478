import { CompanyInfo } from '@/data/company/models';
import { UserInfo } from '@/data/core/models/user';
import { Geocode, GeocodeBrokeredResponse } from '@/data/geocoder/models';
import { CustomerDocumentInfo } from '@/data/filehandling/models';

export enum ClientAgreementState {
  new = 'new',
  approved = 'approved',
  verified = 'verified',
  countersigned = 'countersigned',
  expired = 'expired',
}

export const ClientAgreementStateLabelMapping: Record<
  ClientAgreementState,
  string
> = {
  [ClientAgreementState.new]: 'New',
  [ClientAgreementState.approved]: 'Approved',
  [ClientAgreementState.verified]: 'Routed for Counter-Signing',
  [ClientAgreementState.countersigned]: 'Countersigned',
  [ClientAgreementState.expired]: 'Expired',
};

export enum ClientAgreementExtensionRequestState {
  notSent = 'not_sent',
  initiated = 'initiated',
  sentToClient = 'sent_to_client',
  sentForCounterSign = 'sent_for_countersigned',
  countersigned = 'countersigned',
  rejected = 'rejected',
}

export const ClientAgreementExtensionRequestStateLabelMapping: Record<
  ClientAgreementExtensionRequestState,
  string
> = {
  [ClientAgreementExtensionRequestState.notSent]: 'Not sent',
  [ClientAgreementExtensionRequestState.initiated]: 'Initiated',
  [ClientAgreementExtensionRequestState.sentToClient]: 'Sent to Client',
  [ClientAgreementExtensionRequestState.sentForCounterSign]:
    'Sent for Counter-Signing',
  [ClientAgreementExtensionRequestState.countersigned]: 'Countersigned',
  [ClientAgreementExtensionRequestState.rejected]: 'Rejected',
};

export class ClientAgreementDocusignInfo {
  envelope_id: string;
  status: string;
  status_message: string;
  recipient_email: string;
  delivered_time: string;
}

/*
 * Original name on backend CustomerHIRL -> BuilderAgreement
 * */
export class ClientAgreement {
  id?: number;
  company?: number;
  company_info?: CompanyInfo;

  owner?: number;
  owner_info?: CompanyInfo;

  initiator?: number;
  initiator_info?: UserInfo;

  created_by?: number;
  created_by_info?: UserInfo;

  state?: ClientAgreementState;

  agreement_start_date?: string;
  agreement_expiration_date?: string;

  mailing_geocode?: number;
  mailing_geocode_info?: Geocode;
  mailing_geocode_response?: number;
  mailing_geocode_response_info?: GeocodeBrokeredResponse;

  shipping_geocode?: number;
  shipping_geocode_info?: Geocode;
  shipping_geocode_response?: number;
  shipping_geocode_response_info?: GeocodeBrokeredResponse;

  primary_contact_first_name?: string;
  primary_contact_last_name?: string;
  primary_contact_title?: string;
  primary_contact_email_address?: string;
  primary_contact_phone_number?: string;
  primary_contact_cell_number?: string;

  secondary_contact_first_name?: string;
  secondary_contact_last_name?: string;
  secondary_contact_title?: string;
  secondary_contact_email_address?: string;
  secondary_contact_phone_number?: string;
  secondary_contact_cell_number?: string;

  marketing_contact_first_name?: string;
  marketing_contact_last_name?: string;
  marketing_contact_title?: string;
  marketing_contact_phone_number?: string;
  marketing_contact_cell_number?: string;
  marketing_contact_email_address?: string;

  website_contact_first_name?: string;
  website_contact_last_name?: string;
  website_contact_title?: string;
  website_contact_phone_number?: string;
  website_contact_cell_number?: string;
  website_contact_email_address?: string;

  payment_contact_first_name?: string;
  payment_contact_last_name?: string;
  payment_contact_title?: string;
  payment_contact_phone_number?: string;
  payment_contact_cell_number?: string;
  payment_contact_email_address?: string;

  use_payment_contact_in_ngbs_green_projects?: boolean;

  ca_version_to_sign?: number;

  date_modified?: string;
  date_created?: string;

  signed_agreement?: number;
  signed_agreement_info: CustomerDocumentInfo;

  certifying_document?: number;
  certifying_document_info: CustomerDocumentInfo;

  extension_request_state: ClientAgreementExtensionRequestState;

  extension_request_signed_agreement?: number;
  extension_request_signed_agreement_info: CustomerDocumentInfo;

  extension_request_certifying_document?: number;
  extension_request_certifying_document_info: CustomerDocumentInfo;

  extension_request_reject_reason?: string;

  website?: string;

  docusign_data?: ClientAgreementDocusignInfo;
}

export class CreateClientAgreementWithoutDocuSign {
  company: number;
}

export class CreateClientAgreementWithoutUser {
  company: number;
  signer_name: string;
  signer_email: string;
  mailing_geocode: number;
}
