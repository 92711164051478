import { ModelFormControl, createConditionalValidator } from '../ModelForm';
import { StudType, WallCavityInsulationType } from '../../enumerations';
import { FieldLabels } from '../base';
import {
  LayerBackendDict,
  LayerFrameType,
  createNewLayer,
} from './AssemblyLayer';
import { BaseModelWithAssemblyFormGroup } from './AssemblyCalculator';
import { MaterialItem, getMaterial } from './Material';
import {
  aboveWarningRangeValidator,
  belowWarningRangeValidator,
  enumValidator,
  withinAshraeFramingFactorRangeValidator,
} from '@/modules/simulation/validators/helper';
import { Validators } from '@angular/forms';

// Labels
export const FIELD_LABELS: FieldLabels<RoofTypeBackendDict> = {
  name: 'Name',
  layers: 'Layers',
  stud_type: 'Stud Type',
  frame_width: 'Frame Width',
  frame_depth: 'Frame Depth',
  frame_spacing: 'Frame Spacing',
  framing_factor: 'Framing Factor',
  use_default_framing_factor: 'Use default framing factor',
  cavity_insulation_r_value: 'Cavity Insulation R-Value',
  cavity_insulation_material: 'Cavity Insulation Material',
  cavity_insulation_thickness: 'Cavity Insulation Thickness',
  cavity_insulation_grade: 'Cavity Insulation Grade',
  continuous_insulation_r_value: 'Continuous Insulation R-Value',
  gypsum_thickness: 'Gypsum Thickness',
  units: 'Units',
  assembly_u_value: 'Assembly U-Value',
  user_defined_assembly_u_value: 'Contains Assembly Details',
  has_radiant_barrier: 'Has Radiant Barrier',
  radiant_barrier_grade: 'Radiant Barrier Grade',
  has_sub_tile_ventilation: 'Has Sub Tile Ventilation',
  has_clay_or_concrete_tiles: 'Has Clay or Concrete Tiles',
};

export const DEFAULT_ROOF_TYPE: RoofTypeBackendDict = {
  id: null,
  name: 'Random Name',
  stud_type: StudType.WOOD_STUD,
  frame_width: 0.0,
  frame_depth: 0.0,
  frame_spacing: 0.0,
  framing_factor: 0.0,
  cavity_insulation_r_value: 0.0,
  cavity_insulation_material: WallCavityInsulationType.BATT,
  cavity_insulation_thickness: 0.0,
  cavity_insulation_grade: 0.0,
  continuous_insulation_r_value: 0.0,
  gypsum_thickness: 0.0,
  units: 'Random Units',
  assembly_u_value: 0.0,
  layers: [],
  use_default_framing_factor: false,
  user_defined_assembly_u_value: false,
  has_radiant_barrier: false,
  radiant_barrier_grade: 0.0,
  has_sub_tile_ventilation: false,
  has_clay_or_concrete_tiles: false,
};

export interface RoofTypeBackendDict {
  id: number;
  name: string;
  layers: LayerBackendDict[];
  stud_type: StudType;
  frame_width: number;
  frame_depth: number;
  frame_spacing: number;
  framing_factor: number;
  use_default_framing_factor: boolean;
  cavity_insulation_r_value: number;
  cavity_insulation_material: WallCavityInsulationType;
  cavity_insulation_thickness: number;
  cavity_insulation_grade: number;
  continuous_insulation_r_value: number;
  gypsum_thickness: number;
  units: string;
  assembly_u_value: number;
  user_defined_assembly_u_value: boolean;
  has_radiant_barrier: boolean;
  radiant_barrier_grade: number;
  has_sub_tile_ventilation: boolean;
  has_clay_or_concrete_tiles: boolean;
}

export class RoofTypeFormGroup extends BaseModelWithAssemblyFormGroup {
  frameType = LayerFrameType.ROOF;

  convertClassicFormToLayer() {
    const layers: LayerBackendDict[] = [];
    const instance = this.value;

    let shinglesLayer = {
      ...createNewLayer(),
      materials: [{ ...getMaterial(MaterialItem.ROOF_ASPHALT_SHINGLES) }],
    };
    if (instance.has_clay_or_concrete_tiles) {
      shinglesLayer = {
        ...createNewLayer(),
        materials: [{ ...getMaterial(MaterialItem.ROOF_CLAY_TILE_SHINGLES) }],
      };
    }
    layers.push(shinglesLayer);

    // Roof Membrane
    layers.push({
      ...createNewLayer(),
      materials: [{ ...getMaterial(MaterialItem.ROOF_MEMBRANE) }],
    });

    // Sheathing
    layers.push({
      ...createNewLayer(),
      materials: [
        {
          ...getMaterial(MaterialItem.SHEATHING_PLYWOOD_0P625),
        },
      ],
    });

    if (instance.continuous_insulation_r_value) {
      layers.push(this.getContinuousInsulationLayer(instance));
    }

    if (instance.stud_type) {
      layers.push(this.getStudWall(instance));
    }

    if (instance.gypsum_thickness) {
      layers.push(this.getGypsumLayer(instance));
    }

    return layers;
  }

  constructor(roofType) {
    const validateOnlyIfClassicForm = createConditionalValidator(
      parentControls =>
        parentControls.layers.value.length === 0 &&
        !parentControls.user_defined_assembly_u_value.value,
      ['layers', 'user_defined_assembly_u_value']
    );

    const validateOnlyIfClassicStud = createConditionalValidator(
      parentControls =>
        parentControls.layers.value.length === 0 &&
        parentControls.stud_type.value !== null,
      ['stud_type']
    );

    const validateOnlyIfRaddiantBarrier = createConditionalValidator(
      parentControls => parentControls.has_radiant_barrier.value,
      ['has_radiant_barrier']
    );

    const validateOnlyIfClassicStudWithoutDefaultFraming =
      createConditionalValidator(
        parentControls =>
          parentControls.layers.value.length === 0 &&
          parentControls.stud_type.value !== null &&
          !parentControls.use_default_framing_factor.value,
        ['stud_type', 'use_default_framing_factor']
      );

    super({
      id: new ModelFormControl(roofType.id),
      name: new ModelFormControl(roofType.name),
      layers: new ModelFormControl(roofType.layers),

      stud_type: new ModelFormControl(
        roofType.stud_type,
        ...validateOnlyIfClassicForm([enumValidator(StudType, true)])
      ),
      frame_width: new ModelFormControl(
        roofType.frame_width,
        ...validateOnlyIfClassicStud([Validators.required, Validators.min(0)])
      ),
      frame_depth: new ModelFormControl(
        roofType.frame_depth,
        ...validateOnlyIfClassicStud([Validators.required, Validators.min(0)])
      ),
      frame_spacing: new ModelFormControl(
        roofType.frame_spacing,
        ...validateOnlyIfClassicStud([Validators.required, Validators.min(0)])
      ),
      framing_factor: new ModelFormControl(
        roofType.framing_factor,
        ...validateOnlyIfClassicStudWithoutDefaultFraming([
          withinAshraeFramingFactorRangeValidator(0.07),
        ])
      ),
      use_default_framing_factor: new ModelFormControl(
        roofType.use_default_framing_factor
      ),
      cavity_insulation_r_value: new ModelFormControl(
        roofType.cavity_insulation_r_value,
        ...validateOnlyIfClassicStud([
          Validators.required,
          Validators.min(0),
          Validators.max(100),
          belowWarningRangeValidator(5),
          aboveWarningRangeValidator(95),
        ])
      ),
      cavity_insulation_material: new ModelFormControl(
        roofType.cavity_insulation_material,
        ...validateOnlyIfClassicStud([
          enumValidator(WallCavityInsulationType, true),
        ])
      ),
      cavity_insulation_thickness: new ModelFormControl(
        roofType.cavity_insulation_thickness
      ),
      cavity_insulation_grade: new ModelFormControl(
        roofType.cavity_insulation_grade,
        ...validateOnlyIfClassicStud([Validators.required])
      ),
      continuous_insulation_r_value: new ModelFormControl(
        roofType.continuous_insulation_r_value
      ),
      gypsum_thickness: new ModelFormControl(roofType.gypsum_thickness),
      assembly_u_value: new ModelFormControl(roofType.assembly_u_value, [
        Validators.required,
        Validators.min(0),
      ]),
      user_defined_assembly_u_value: new ModelFormControl(
        roofType.user_defined_assembly_u_value
      ),
      has_radiant_barrier: new ModelFormControl(roofType.has_radiant_barrier),
      radiant_barrier_grade: new ModelFormControl(
        roofType.radiant_barrier_grade,
        ...validateOnlyIfRaddiantBarrier([Validators.required])
      ),
      has_sub_tile_ventilation: new ModelFormControl(
        roofType.has_sub_tile_ventilation
      ),
      has_clay_or_concrete_tiles: new ModelFormControl(
        roofType.has_clay_or_concrete_tiles
      ),
    });
  }
}
