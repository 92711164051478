import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubdivisionSelectOrCreateDialogComponent } from './components/subdivision-select-or-create-dialog/subdivision-select-or-create-dialog.component';
import { SharedModule } from '@/shared/shared.module';
import { CustomerHIRLModule } from '@/modules/customer-hirl/customer-hirl.module';

@NgModule({
  declarations: [SubdivisionSelectOrCreateDialogComponent],
  imports: [CommonModule, SharedModule, CustomerHIRLModule],
  exports: [],
})
export class SubdivisionModule {}
