<div class="row">
    <div class="col-3">
        <div class="row">
            <div class="col-12">
                <form [formGroup]="filterFromGroup" class="card">
                    <h5 class="card-header">Filters</h5>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Search</mat-label>
                                    <app-search-input formControlName="search" placeholder="Type to search something"></app-search-input>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="col-9">
        <div class="row mb-2">
            <div class="col-12 text-right">
                <app-permission-mat-button [permissionResponse]="createPermissionResponse" (click)="create($event);">
                    Create New
                </app-permission-mat-button>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="row" *ngIf="isLoading">
                    <div class="col-12">
                        <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
                    </div>
                </div>
                <table mat-table
                       [dataSource]="rows"
                       class="table"
                       matSort
                       [matSortActive]="storedParams.toTableSort().active"
                       [matSortDirection]="storedParams.toTableSort().direction"
                       (matSortChange)="onSortChange($event)">

                    <ng-container matColumnDef="first_name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                        <td mat-cell *matCellDef="let row">{{ row.first_name }} {{ row.last_name }}</td>
                    </ng-container>

                    <ng-container matColumnDef="title">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Title/Role</th>
                        <td mat-cell *matCellDef="let row">{{ row.title }}</td>
                    </ng-container>

                    <ng-container matColumnDef="phones">
                        <th mat-header-cell *matHeaderCellDef>Phones</th>
                        <td mat-cell *matCellDef="let row">
                            <ul class="list-unstyled">
                                <li *ngFor="let phoneContact of row?.phones">
                                    {{ phoneContact.phone | mask: '000-000-0000' }}
                                    <span *ngIf="phoneContact.description">- {{ phoneContact.description }}</span>
                                </li>
                            </ul>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="emails">
                        <th mat-header-cell *matHeaderCellDef>Emails</th>
                        <td mat-cell *matCellDef="let row">
                            <ul class="list-unstyled">
                                <li *ngFor="let emailContact of row?.emails">
                                    {{ emailContact.email }}
                                    <span *ngIf="emailContact.description">- {{ emailContact.description }}</span>
                                </li>
                            </ul>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef>Actions</th>
                        <td mat-cell *matCellDef="let row">
                            <a href="#" (click)="edit($event, row);">
                                <fa-icon [icon]="['fas', 'edit']"></fa-icon>
                            </a>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

                <mat-paginator [length]="rowsCount"
                               [pageIndex]="$any(storedParams.page) - 1"
                               [pageSize]="storedParams.page_size"
                               (page)="onPaginateChange($event)"
                               aria-label="Select page">
                </mat-paginator>
            </div>
        </div>
    </div>
</div>
