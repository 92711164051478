export enum ZendeskTicketType {
  question = 'question',
  incident = 'incident',
  problem = 'problem',
  task = 'task',
}

export const ZendeskTicketTypeLabelMapping: Record<ZendeskTicketType, string> =
  {
    [ZendeskTicketType.question]: 'Question',
    [ZendeskTicketType.incident]: 'Incident',
    [ZendeskTicketType.problem]: 'Problem',
    [ZendeskTicketType.task]: 'Task',
  };

export enum ZendeskTicketPriority {
  low = 'low',
  normal = 'normal',
  high = 'high',
  urgent = 'urgent',
}

export const ZendeskTicketPriorityLabelMapping: Record<
  ZendeskTicketPriority,
  string
> = {
  [ZendeskTicketPriority.low]: 'Low',
  [ZendeskTicketPriority.normal]: 'Normal',
  [ZendeskTicketPriority.high]: 'High',
  [ZendeskTicketPriority.urgent]: 'Urgent',
};

export class ZendeskTicket {
  subject?: string;
  description?: string;
  ticket_type?: ZendeskTicketType;
  priority?: ZendeskTicketPriority;
}

export class ZendeskCreateTicketComment {
  body?: string;
}

export class ZendeskCreateRequest {
  subject?: string;
  description?: string;
  current_page?: string;
  other_pages?: string;
  ticket_type?: ZendeskTicketType;
  priority?: ZendeskTicketPriority;
  comment?: ZendeskCreateTicketComment;
}

export class ZendeskCreateRequestResponse {
  api_url: string;
  url: string;
}
