import { createSelector } from '@ngrx/store';
import { SimulationState } from '../reducers';
import { selectSimulationFeature } from '../simulation.selectors';

export const selectGroundSourceHeatPump = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.groundSourceHeatPump.entities[id]
  );

const selectGroundSourceHeatPumpLoading = (id: number) =>
  createSelector(
    selectSimulationFeature,
    state => state.groundSourceHeatPump.loading[id]
  );

export const selectGroundSourceHeatPumpViewSet = (id: number) =>
  createSelector(
    selectGroundSourceHeatPump(id),
    selectGroundSourceHeatPumpLoading(id),
    (data, loading) => ({ data, loading })
  );

export const selectGroundSourceHeatPumpErrors = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.groundSourceHeatPump.errors[id]
  );
