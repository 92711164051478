import {
  createConditionalValidator,
  ModelFormControl,
  ModelFormGroup,
} from '../ModelForm';
import { Validators } from '@angular/forms';
import {
  AuxEnergyUnit,
  CoolingEfficiencyUnit,
  FuelType,
  HeatingCoolingCapacityUnit,
  HeatingEfficiencyUnit,
  HeatingEfficiencyUnitLabels,
  MechanicalMotorType,
  TemperatureUnit,
} from '../../enumerations';
import { FieldLabels } from '../base';
import { enumValidator } from '@/modules/simulation/validators/helper';
import {
  validateBackupHeatingEfficiencyUnit,
  validateHeatingEfficiencyUnit,
  validateSwitchOverTemperatureUnit,
  validateAutoSizeCapacity,
} from './AirSourceHeatPump';

enum GroundSourceHeatPumpHeatingEfficiencyUnit {
  COP = HeatingEfficiencyUnit.COP,
}

export const GroundSourceHeatPumpHeatingEfficiencyUnitLabels = {
  [HeatingEfficiencyUnit.COP]:
    HeatingEfficiencyUnitLabels[HeatingEfficiencyUnit.COP],
};

// Labels
export const FIELD_LABELS: FieldLabels<GroundSourceHeatPumpBackendDict> = {
  name: 'Name',
  fuel: 'Fuel',
  auto_size_capacity: {
    label: 'Auto Size Capacity',
    description:
      'Auto sizing can be used to autosize the equipment for research purposes or to run tests.',
  },
  heating_capacity: 'Heating Capacity',
  heating_efficiency: 'Heating Efficiency',
  heating_efficiency_unit: 'Heating Efficiency Unit',
  cooling_capacity: 'Cooling Capacity',
  capacity_unit: 'Capacity Unit',
  cooling_efficiency: 'Cooling Efficiency',
  cooling_efficiency_unit: 'Cooling Efficiency Unit',
  motor_type: 'Motor Type',
  sensible_heat_fraction: 'Sensible Heat Fraction',
  has_desuperheater: 'Has Desuperheater',
  backup_heating_fuel: 'Backup Heating Fuel',
  backup_heating_capacity: 'Backup Heating Capacity',
  backup_heating_capacity_unit: 'Backup Heating Capacity Unit',
  backup_heating_efficiency: 'Backup Heating Efficiency',
  backup_heating_efficiency_unit: 'Backup Heating Efficiency Unit',
  switch_over_temperature: 'Switch Over Temperature',
  switch_over_temperature_unit: 'Switch Over Temperature Unit',
  fan_power: 'Fan Power',
  fan_unit: 'Fan Power Unit',
  air_flow_defect_ratio: 'Air Flow defect ratio',
  charge_defect_ratio: 'Charge defect ratio',
  ahri_reference_number: 'AHRI Reference Number',
  pump_energy: 'Pump Energy',
  pump_energy_unit: 'Pump Energy Unit',
};

export interface GroundSourceHeatPumpBackendDict {
  id: number;
  name: string;
  fuel: FuelType;
  heating_efficiency: number;
  heating_efficiency_unit: GroundSourceHeatPumpHeatingEfficiencyUnit;
  auto_size_capacity: boolean;
  heating_capacity: number;
  cooling_efficiency: number;
  cooling_efficiency_unit: CoolingEfficiencyUnit;
  cooling_capacity: number;
  capacity_unit: HeatingCoolingCapacityUnit;
  motor_type: MechanicalMotorType;
  sensible_heat_fraction: number;
  has_desuperheater: boolean;
  backup_heating_fuel: FuelType;
  backup_heating_efficiency: number;
  backup_heating_efficiency_unit: HeatingEfficiencyUnit;
  backup_heating_capacity: number;
  backup_heating_capacity_unit: HeatingCoolingCapacityUnit;
  switch_over_temperature: number;
  switch_over_temperature_unit: TemperatureUnit;
  fan_power: number;
  fan_unit: AuxEnergyUnit;
  air_flow_defect_ratio: number;
  charge_defect_ratio: number;
  pump_energy: number;
  pump_energy_unit: AuxEnergyUnit;
  ahri_reference_number: string;
}

export function createGroundSourceHeatPumpForm(
  data: GroundSourceHeatPumpBackendDict
): ModelFormGroup {
  const validateOnlyIfAutoSizeDisabled = createConditionalValidator(
    parentControls => !parentControls.auto_size_capacity.value,
    ['auto_size_capacity']
  );

  return new ModelFormGroup(
    {
      id: new ModelFormControl(data.id),
      name: new ModelFormControl(data.name, [
        Validators.required,
        Validators.maxLength(128),
      ]),
      fuel: new ModelFormControl(data.fuel, enumValidator(FuelType)),
      heating_efficiency: new ModelFormControl(data.heating_efficiency, [
        Validators.required,
        Validators.min(0),
      ]),
      heating_efficiency_unit: new ModelFormControl(
        data.heating_efficiency_unit,
        enumValidator(HeatingEfficiencyUnit)
      ),
      auto_size_capacity: new ModelFormControl(data.auto_size_capacity),
      heating_capacity: new ModelFormControl(
        data.heating_capacity,
        ...validateOnlyIfAutoSizeDisabled([
          Validators.required,
          Validators.min(0),
        ])
      ),
      cooling_efficiency: new ModelFormControl(data.cooling_efficiency, [
        Validators.required,
        Validators.min(0),
      ]),
      cooling_efficiency_unit: new ModelFormControl(
        data.cooling_efficiency_unit,
        enumValidator(CoolingEfficiencyUnit)
      ),
      cooling_capacity: new ModelFormControl(
        data.cooling_capacity,
        ...validateOnlyIfAutoSizeDisabled([
          Validators.required,
          Validators.min(0),
        ])
      ),
      capacity_unit: new ModelFormControl(
        data.capacity_unit,
        ...validateOnlyIfAutoSizeDisabled([
          enumValidator(HeatingCoolingCapacityUnit),
        ])
      ),
      motor_type: new ModelFormControl(
        data.motor_type,
        enumValidator(MechanicalMotorType, true)
      ),
      sensible_heat_fraction: new ModelFormControl(
        data.sensible_heat_fraction,
        [Validators.nullValidator, Validators.min(0.5), Validators.max(1.0)]
      ),
      has_desuperheater: new ModelFormControl(data.has_desuperheater),
      backup_heating_fuel: new ModelFormControl(
        data.backup_heating_fuel,
        enumValidator(FuelType)
      ),
      backup_heating_efficiency: new ModelFormControl(
        data.backup_heating_efficiency,
        [Validators.nullValidator, Validators.min(0)]
      ),
      backup_heating_efficiency_unit: new ModelFormControl(
        data.backup_heating_efficiency_unit,
        enumValidator(HeatingEfficiencyUnit, true)
      ),
      backup_heating_capacity: new ModelFormControl(
        data.backup_heating_capacity,
        [Validators.nullValidator, Validators.min(0)]
      ),
      backup_heating_capacity_unit: new ModelFormControl(
        data.backup_heating_capacity_unit,
        enumValidator(HeatingCoolingCapacityUnit)
      ),
      switch_over_temperature: new ModelFormControl(
        data.switch_over_temperature,
        [Validators.nullValidator, Validators.min(0)]
      ),
      switch_over_temperature_unit: new ModelFormControl(
        data.switch_over_temperature_unit,
        enumValidator(TemperatureUnit)
      ),
      pump_energy: new ModelFormControl(data.fan_power, [
        Validators.nullValidator,
        Validators.min(0),
      ]),
      pump_energy_unit: new ModelFormControl(
        data.pump_energy_unit,
        enumValidator(AuxEnergyUnit, true)
      ),

      fan_power: new ModelFormControl(data.fan_power, [
        Validators.nullValidator,
        Validators.min(0),
      ]),
      fan_unit: new ModelFormControl(
        data.fan_unit,
        enumValidator(AuxEnergyUnit, true)
      ),
      air_flow_defect_ratio: new ModelFormControl(data.air_flow_defect_ratio, [
        Validators.nullValidator,
        Validators.min(-0.9),
        Validators.max(9.0),
      ]),
      charge_defect_ratio: new ModelFormControl(data.charge_defect_ratio, [
        Validators.nullValidator,
        Validators.min(-0.25),
        Validators.max(0.25),
      ]),
      ahri_reference_number: new ModelFormControl(data.ahri_reference_number),
    },
    {
      validators: [
        validateCoolingEfficiency,
        validateAutoSizeCapacity,
        validateHeatingEfficiencyUnit,
        validateBackupHeatingEfficiencyUnit,
        validateSwitchOverTemperatureUnit,
      ],
    }
  );
}

function validateCoolingEfficiency(controls: ModelFormGroup) {
  const coolingEfficiencyUnit = controls.get('cooling_efficiency_unit').value;
  const coolingEfficiency = controls.get('cooling_efficiency').value;

  if (
    coolingEfficiencyUnit === CoolingEfficiencyUnit.PCT_EFF ||
    coolingEfficiencyUnit === CoolingEfficiencyUnit.ADJUSTED_EFFICIENCY
  ) {
    if (coolingEfficiency < 10 || coolingEfficiency > 60) {
      return {
        invalidGshCoolingEfficiency: {
          coolingEfficiency: coolingEfficiency,
        },
      };
    } else if (coolingEfficiency < 15 || coolingEfficiency > 55) {
      return {
        warningGshCoolingEfficiency: {
          coolingEfficiency: coolingEfficiency,
        },
      };
    }
  }
}
