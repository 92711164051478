<h2 mat-dialog-title>
  Certify Project(s)
</h2>
<mat-dialog-content *ngIf="initialized">
  <form [formGroup]="form" class="mb-2">
    <div class="row" *ngIf="currentUser.is_superuser">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Company</mat-label>
          <app-generic-model-autocomplete modelClassName="company" formControlName="company_info" [initialValueId]="data.company"></app-generic-model-autocomplete>
          <mat-error *ngIf="form.get('company_info').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100 mb-2">
          <mat-label>Certification Date</mat-label>
          <input matInput type="text" placeholder="Certification Date" formControlName="certification_date" [matDatepicker]="certificationDatePicker">
          <mat-datepicker-toggle matSuffix [for]="certificationDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #certificationDatePicker></mat-datepicker>
          <mat-error *ngIf="form.get('certification_date').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button color="accent" mat-raised-button (click)="save($event)">Certify</button>
  <button mat-button [mat-dialog-close]="null" color="primary" mat-raised-button>Cancel</button>
</mat-dialog-actions>
