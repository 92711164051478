<div class="container-fluid" *ngIf="initialized else loading">
  <div class="d-flex justify-content-center align-self-center mx-auto" *ngIf="!floorplan">
    <div class="d-flex flex-column align-items-center">
      <span class="text-center h4"><i>No Floorplan is associated with the simulation.</i></span>
      <div>
        <button mat-raised-button color="accent" (click)="createFloorplanFromSimulation($event)" class="ml-2"
          *ngIf="!floorplan">
          Attach to a new Floorplan
        </button>
      </div>
    </div>
  </div>
  <div class="row mb-2" *ngIf="floorplan">
    <div class="col-9">
      <div class="h4">
        <h4>
          <span *ngIf="!entitiesIsLoading">{{ entitiesCount | number }}</span>
          <fa-icon [icon]="['fas', 'spinner']" [spin]="true" *ngIf="entitiesIsLoading"></fa-icon>
          Homes/Projects Associated with Floorplan <app-floorplan-display [floorplan]="floorplan"
            displayType="onlyNameAsLinkNewWindow"></app-floorplan-display>
        </h4>
      </div>
    </div>
    <div class="col-3 text-right">
      <button mat-raised-button color="accent" (click)="createHomeFromSimulation($event)" class="ml-2">
        + Add Home/Project
      </button>
    </div>
  </div>
  <div class="row"  *ngIf="floorplan">
    <div class="col-12">
      <div class="row">
        <div class="col-3">
          <div class="row">
            <div class="col-12">
              <form [formGroup]="filterFromGroup" class="card">
                <h5 class="card-header">
                  Filters
                  <a href="#" matTooltip="Preview applied filters" (click)="showAppliedFiltersDialog($event);">
                    <fa-icon [icon]="['fas', 'filter']"></fa-icon>
                  </a>
                  <a href="#" class="small float-right" (click)="resetFilters($event);">Reset filters</a>
                </h5>
                <div class="card-body filter-card-body">
                  <div class="row">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>Search</mat-label>
                        <app-search-input formControlName="search"
                                          placeholder="Type to search something"></app-search-input>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>Project Status</mat-label>
                        <mat-select formControlName="state" [multiple]="true">
                          <mat-option
                            *ngFor="
                                    let item of EEPProgramHomeStatusStateLabelMapping
                                      | defaultOrderKeyValue
                                  "
                            [value]="item.key"
                          >
                            {{ item.value }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>QA Status</mat-label>
                        <mat-select formControlName="qa_status__state" [multiple]="true">
                          <mat-option
                            *ngFor="
                                    let item of QAStatusStateLabelMapping
                                      | defaultOrderKeyValue
                                  "
                            [value]="item.key"
                          >
                            {{ item.value }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-9">
          <div class="row" *ngIf="entitiesIsLoading">
            <div class="col-12">
              <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
            </div>
          </div>
          <table mat-table
                 [dataSource]="entities"
                 matSort
                 [matSortActive]="storedParams.toTableSort().active"
                 [matSortDirection]="storedParams.toTableSort().direction"
                 (matSortChange)="onSort($event)"
                 class="w-100"
          >
            <ng-container matColumnDef="home__street_line1">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Address</th>
              <td mat-cell *matCellDef="let row">
                <a [routerLink]="['/', 'home', row.home]" target="_blank">
                  {{ row.address }}
                </a>
              </td>
            </ng-container>

            <ng-container matColumnDef="home__subdivision__name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Subdivision/MF Development</th>
              <td mat-cell *matCellDef="let row">
                <a [routerLink]="['/', 'subdivision', row.subdivision_id]" target="_blank">
                  {{ row.subdivision_name }}
                </a>
              </td>
            </ng-container>

            <ng-container matColumnDef="eep_program__name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Program</th>
              <td mat-cell *matCellDef="let row">
                <a [routerLink]="['/', 'eep_program', row.eep_program]" target="_blank">
                  {{ row.eep_program_name }}
                </a>
              </td>
            </ng-container>

            <ng-container matColumnDef="state">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Project Status</th>
              <td mat-cell *matCellDef="let row">
                {{ tableStateLabelMapping[row.state] }}
              </td>
            </ng-container>

            <ng-container matColumnDef="qa_status">
              <th mat-header-cell *matHeaderCellDef>QA Status</th>
              <td mat-cell *matCellDef="let row">
                <div *ngFor="let qa_state of row.qa_states; let lastItem = last">
                  <div>
                    {{ QARequirementTypeLabelMapping[qa_state.requirement_type] }}
                  </div>
                  <div>
                    {{ QAStatusStateLabelMapping[qa_state.state] }}
                  </div>
                  <div *ngIf="qa_state.duration" matTooltip="Duration in days">
                    {{ qa_state.duration }}
                  </div>
                  <hr *ngIf="!lastItem" class="qa-divider">
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <div class="d-flex flex-row">
            <div class="align-self-center">
            </div>
            <div class="d-flex flex-fill justify-content-end">
              <mat-paginator [length]="entitiesCount"
                             [pageIndex]="$any(storedParams.page) - 1"
                             [pageSize]="storedParams.page_size"
                             (page)="onPaginateChange($event)"
                             aria-label="Select page">
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #loading>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="d-flex justify-content-center" style="height: 320px;">
          <mat-spinner [diameter]="100"></mat-spinner>
        </div>
      </div>
    </div>
  </div>
</ng-template>

