import { createSelector } from '@ngrx/store';
import { SimulationState } from '../reducers';
import { selectSimulationFeature } from '../simulation.selectors';
import { DoorBackendDict } from '@/data/simulation/models/enclosure/Door';

export const selectDoor = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.door.entities[id]
  );
const selectDoorLoadingStatus = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.door.loading[id]
  );

export const selectDoorTypeId = (id: number) =>
  createSelector(selectDoor(id), door => door.type);

const selectDoorErrorCount = (id: number) =>
  createSelector(selectSimulationFeature, (state: SimulationState) => ({
    errorCount: state.door.errors[id].errorCount,
    warningCount: state.door.errors[id].warningCount,
  }));

export const selectDoorViewSet = (id: number) =>
  createSelector(
    selectDoor(id),
    selectDoorLoadingStatus(id),
    (door, loadingStatus) => ({
      data: door,
      loading: loadingStatus,
    })
  );

export const selectDoorName = (id: number) =>
  createSelector(selectDoor(id), (door: DoorBackendDict) => door.name);

export const selectDoorNestedErrorCount = (id: number) =>
  createSelector(
    selectSimulationFeature,
    selectDoorErrorCount(id),
    selectDoorTypeId(id),
    (state, errorCount, doorTypeId) => {
      const doorTypeErrorCount = {
        errorCount: 0,
        warningCount: 0,
      };

      if (doorTypeId !== null) {
        doorTypeErrorCount.errorCount =
          state.doorType.errors[doorTypeId].errorCount;
        doorTypeErrorCount.warningCount =
          state.doorType.errors[doorTypeId].warningCount;
      }

      return {
        door: errorCount,
        doorType: doorTypeErrorCount,
      };
    }
  );

export const selectDoorErrorViewSet = (id: number) =>
  createSelector(
    selectDoorNestedErrorCount(id),
    selectDoorTypeId(id),
    selectDoorName(id),
    (nestedModelsErrorCount, doorTypeId, name) => ({
      nestedModelsErrorCount,
      doorTypeId,
      name,
    })
  );
