import {
  MechanicalEquipmentBackendDict,
  DetailedMechanicalEquipmentBackendDict,
} from '@/data/simulation/models/mechanicals/MechanicalEquipment';
import { createAction, props } from '@ngrx/store';
import { ModelErrors, StateErrors } from '../shared/base.state';

export const loadDetailedMechanicalEquipment = createAction(
  '[Simulation API] Load Detailed MechanicalEquipments',
  props<{
    detailedMechanicalEquipment: DetailedMechanicalEquipmentBackendDict[];
  }>()
);

export const loadMechanicalEquipmentsSuccess = createAction(
  '[MechanicalEquipment API] Load MechanicalEquipments Success',
  props<{
    mechanicalEquipments: MechanicalEquipmentBackendDict[];
    errors: StateErrors<MechanicalEquipmentBackendDict>;
  }>()
);

export const updateMechanicalEquipment = createAction(
  '[MechanicalEquipment UI] Update MechanicalEquipment',
  props<{
    id: number;
    mechanicalEquipmentChanges: Partial<MechanicalEquipmentBackendDict>;
  }>()
);

export const updateMechanicalEquipmentSuccess = createAction(
  '[MechanicalEquipment API] Update MechanicalEquipment Success',
  props<{
    mechanicalEquipment: MechanicalEquipmentBackendDict;
    errors: ModelErrors<MechanicalEquipmentBackendDict>;
  }>()
);

export const updateMechanicalEquipmentFailure = createAction(
  '[MechanicalEquipment API] Update MechanicalEquipment Failure',
  props<{ id: number }>()
);

export const removeMechanicalEquipment = createAction(
  '[MechanicalEquipment UI] Remove MechanicalEquipment',
  props<{ mechanicalEquipment: MechanicalEquipmentBackendDict }>()
);

export const removeMechanicalEquipmentSuccess = createAction(
  '[MechanicalEquipment API] Remove MechanicalEquipment Success',
  props<{ id: number }>()
);

export const removeMechanicalEquipmentFailure = createAction(
  '[MechanicalEquipment API] Remove MechanicalEquipment Failure',
  props<{ id: number }>()
);
