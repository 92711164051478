<h2 mat-dialog-title>
    History
</h2>
<mat-dialog-content>
    <app-history-table [objectId]="config.objectId" [modelClassName]="config.modelClassName"></app-history-table>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="col-12 text-right">
        <button mat-raised-button color="accent" [mat-dialog-close]="false" cdkFocusInitial>
            Close
        </button>
    </div>
</mat-dialog-actions>