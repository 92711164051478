<div class="row">
  <div class="col-12">
    <div class="row mb-2">
      <div class="col-9">
      </div>
      <div class="col-3 text-right">
        <app-permission-mat-button [permissionResponse]="createPermissionResponse" (action)="create($event)">
          Add New
        </app-permission-mat-button>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <app-table-filters [formGroup]="filterFormGroup" [params]="this.storedParams"
                           [overwritePropertyValues]="overwritePropertyValues"
                           [overwritePropertyLabels]="overwritePropertyLabels"
                           [excludedProperties]="excludedProperties">
          <mat-accordion multi>
            <mat-expansion-panel [expanded]="true">
              <div class="row">
                <div class="col-12">
                  <mat-form-field class="w-100">
                    <mat-label>Search</mat-label>
                    <app-search-input formControlName="search"
                                      placeholder="Type to search something"></app-search-input>
                  </mat-form-field>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </app-table-filters>
      </div>
      <div class="col-9">
        <div class="row" *ngIf="isLoading">
          <div class="col-12">
            <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
          </div>
        </div>
        <table mat-table
               [dataSource]="entities"
               class="table"
               matSort
               [matSortActive]="storedParams.toTableSort().active"
               [matSortDirection]="storedParams.toTableSort().direction"
               (matSortChange)="onSortChange($event)">

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let row">
              {{ row.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let row">
              <ul *ngFor="let trainingStatus of row.statuses_info">
                <li>
                  {{ TrainingStatusStatusLabelMapping[trainingStatus.state] }} - <app-company-display [company]="trainingStatus.company_info"></app-company-display>
                </li>
              </ul>
            </td>
          </ng-container>

          <ng-container matColumnDef="training_date">
            <th mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let row">
              {{ row.training_date | utcDate | date: 'MM/dd/yyyy' :'UTC' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="credit_hours">
            <th mat-header-cell *matHeaderCellDef>Credit Hours</th>
            <td mat-cell *matCellDef="let row">
              {{ row.credit_hours }}
            </td>
          </ng-container>

          <ng-container matColumnDef="training_type">
            <th mat-header-cell *matHeaderCellDef>Type</th>
            <td mat-cell *matCellDef="let row">
              {{ TrainingTypeLabelMapping[row.training_type] }}
            </td>
          </ng-container>

          <ng-container matColumnDef="attendance_type">
            <th mat-header-cell *matHeaderCellDef>Attendance</th>
            <td mat-cell *matCellDef="let row">
              {{ AttendanceTypeLabelMapping[row.attendance_type] }}
            </td>
          </ng-container>

          <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef>Training Company/Conference</th>
            <td mat-cell *matCellDef="let row">
              {{ row.address }}
            </td>
          </ng-container>

          <ng-container matColumnDef="notes">
            <th mat-header-cell *matHeaderCellDef>Notes</th>
            <td mat-cell *matCellDef="let row">
              {{ row.notes }}
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let row">
              <app-permission-mat-button [permissionResponse]="permissions[row.id]['canDownloadCertificate']" displayType="asLink" (action)="downloadCertificate($event, row)" [hideOnFalse]="true" class="mr-2">
                <fa-icon [icon]="['fas', 'file']" matTooltip="Download Certificate"></fa-icon>
              </app-permission-mat-button>
              <app-permission-mat-button [permissionResponse]="permissions[row.id]['canEdit']" displayType="asLink" (action)="edit($event, row);" class="mr-2">
                <fa-icon [icon]="['fas', 'edit']" matTooltip="Edit"></fa-icon>
              </app-permission-mat-button>
              <app-permission-mat-button [permissionResponse]="permissions[row.id]['canDelete']" displayType="asLink" (action)="delete($event, row);" class="mr-2">
                <fa-icon [icon]="['fas', 'trash']" matTooltip="Delete"></fa-icon>
              </app-permission-mat-button>
              <app-history-mat-button displayType="asLink" [objectId]="row.id" [modelClassName]="'training'">
                <fa-icon [icon]="['fas', 'list']" matTooltip="History"></fa-icon>
              </app-history-mat-button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [length]="entitiesCount"
                       [pageIndex]="$any(storedParams.page) - 1"
                       [pageSize]="storedParams.page_size"
                       (page)="onPaginateChange($event)"
                       aria-label="Select page">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
