<h2 mat-dialog-title>
    Change Company
</h2>
<ng-container *ngIf="initialized else loading">
    <mat-dialog-content>
        <form [formGroup]="form">
            <div class="row">
                <div class="col-12">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>I want to be User from Company</mat-label>
                        <app-generic-model-autocomplete modelClassName="company" formControlName="company"></app-generic-model-autocomplete>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>With Roles</mat-label>
                        <app-generic-model-chips modelClassName="company_role" formControlName="roles"></app-generic-model-chips>
                    </mat-form-field>
                </div>
            </div>
        </form>
        <div class="row recent-company-access-container">
            <div class="col-12">
                <h5>Recent Company Roles:</h5>
                <mat-selection-list [multiple]="false" class="list-group">
                    <mat-list-option *ngFor="let companyAccess of recentCompanyAccess" [value]="companyAccess.id" class="list-group-item" (click)="selectRecentCompanyAccess($event, companyAccess);">
                        <p mat-line>
                            <app-company-display [company]="companyAccess.company_info" displayType="nameAndType"></app-company-display>
                        </p>
                        <div mat-line>
                            <span class="text-muted">Roles: </span>
                            <ng-container *ngIf="companyAccess.roles_info.length; else noRoles">
                                <span *ngFor="let role of companyAccess.roles_info" class="text-muted">{{ role.name }}</span>
                            </ng-container>
                            <ng-template #noRoles>
                                <span class="text-muted">General User</span>
                            </ng-template>
                        </div>
                    </mat-list-option>
                </mat-selection-list>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
        <button mat-raised-button color="accent" (click)="save($event)">
            Confirm
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false">
            Cancel
        </button>
    </mat-dialog-actions>
</ng-container>
<ng-template #loading>
    <mat-dialog-content>
        <div class="row">
            <div class="col-12">
                <div class="d-flex justify-content-center" style="height: 320px;">
                    <mat-spinner [diameter]="100"></mat-spinner>
                </div>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>
