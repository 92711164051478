import { EEPProgramInfo } from '@/data/eep-program/models';
import { HomeInfo } from '@/data/home/models';

export class UserCertificationMetric {
  id: number;
  eep_program: number;
  eep_program_info?: EEPProgramInfo;
  home: number;
  home_info: HomeInfo;
  rater_of_record?: number;
  energy_modeler: number;
  field_inspectors?: number[];
  customer_hirl_rough_verifier: number;
  customer_hirl_final_verifier: number;
  certification_date: string;
}
