import { CompanyInfo } from '@/data/company/models';

export enum CustomerDocumentType {
  image = 'image',
  document = 'document',
}

export class CustomerDocument {
  id?: number;
  document?: string;
  type?: CustomerDocumentType;
  description?: string;
  is_public?: boolean;
  is_active?: boolean;
  created_on?: string;
  filesize?: string;
  filetype?: string;
  filename?: string;
  login_required?: boolean;
  preview_link?: string;
  company?: number;
  company_info?: CompanyInfo;
  objectId: number;
}

export class CustomerDocumentInfo {
  id?: number;
  document?: string;
  type?: CustomerDocumentType;
  description?: string;
  is_public?: boolean;
  is_active?: boolean;
  created_on?: string;
  filesize?: string;
  filetype?: string;
  filename?: string;
  login_required?: boolean;
  preview_link?: string;
  company?: number;
  company_info?: CompanyInfo;
}
