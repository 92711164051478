import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { selectDoorTypeId } from '@/modules/simulation/state/door/selectors';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { addDoorType } from '@/modules/simulation/state/door-type/actions';
import { CREATE_MODE, EDIT_MODE } from '@/core/constants';

export class DoorTypeChangeDialogData {
  entityId?: number;
  parentId?: number;
  mode: typeof EDIT_MODE | typeof CREATE_MODE;
  commit?: boolean = false;
  retrieve?: boolean = false;
}

@Component({
  selector: 'app-door-type-change-dialog',
  templateUrl: './door-type-change-dialog.component.html',
  styleUrls: ['./door-type-change-dialog.component.scss'],
})
export class DoorTypeChangeDialogComponent implements OnInit {
  typeSelectForm: FormGroup;
  public editorIsVisible = false;
  public editMode: boolean;
  public doorTypeId: number;
  public initalDoorTypeId: number;

  componentDestroyed$ = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DoorTypeChangeDialogData,
    public dialogRef: MatDialogRef<DoorTypeChangeDialogComponent>,
    public store: Store
  ) {
    this.editMode = this.data.mode === EDIT_MODE;
    if (this.data.mode === CREATE_MODE && this.data.entityId) {
      throw new Error(
        'Cannot create a new door type with an existing door type id or ' +
          'door type'
      );
    }

    if (this.data.mode === EDIT_MODE) {
      this.doorTypeId = this.data.entityId;
    }

    this.store
      .select(selectDoorTypeId(this.data.parentId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(doorTypeId => {
        if (doorTypeId) {
          this.doorTypeId = doorTypeId;
          this.editorIsVisible = true;
        }
      });
  }

  initTypeSelectorForm() {
    this.editorIsVisible = false;
    this.typeSelectForm = new FormGroup({
      type: new FormControl(null),
    });

    this.typeSelectForm
      .get('type')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe(doorType => {
        this.store.dispatch(
          addDoorType({ doorId: this.data.parentId, doorType: doorType })
        );
        this.close();
      });
  }

  initDoorTypeForm(entityId: number) {
    this.doorTypeId = entityId;
    this.editorIsVisible = true;
  }

  ngOnInit(): void {
    if (this.data.mode === CREATE_MODE) {
      this.initTypeSelectorForm();
    } else {
      this.doorTypeId = this.data.entityId;
      this.editorIsVisible = true;
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
