import { Component, Input, OnInit } from '@angular/core';
import {
  HIRLProductAgreement,
  HIRLProductAgreementInfo,
  HIRLProductAgreementStateLabelMapping,
} from '@/data/customer-hirl/models';

export enum HirlProductAgreementDisplayComponentType {
  onlyName = 'onlyName',
  onlyNameAsLink = 'onlyNameAsLink',
  nameAsLinkAndStatus = 'nameAsLinkAndStatus',
}

@Component({
  selector: 'app-hirl-product-agreement-display',
  templateUrl: './hirl-product-agreement-display.component.html',
  styleUrls: ['./hirl-product-agreement-display.component.scss'],
})
export class HirlProductAgreementDisplayComponent implements OnInit {
  @Input() displayType:
    | HirlProductAgreementDisplayComponentType
    | keyof typeof HirlProductAgreementDisplayComponentType =
    HirlProductAgreementDisplayComponentType.onlyName;
  @Input() hirlProductAgreement?:
    | HIRLProductAgreement
    | HIRLProductAgreementInfo;

  public HirlProductAgreementDisplayComponentType =
    HirlProductAgreementDisplayComponentType;

  constructor() {}

  ngOnInit(): void {}

  protected readonly HIRLProductAgreementStateLabelMapping =
    HIRLProductAgreementStateLabelMapping;
}
