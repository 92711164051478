import { SubmitToAxisResponseBackendDict } from '@/data/simulation/models';
import { RatingTypeLabels } from '@/data/simulation/models/Project';
import { SimulationBackendDict } from '@/data/simulation/models/simulation';
import { createFloorplanFromSimulationForm } from '@/data/simulation/models/submit-to-axis';
import { SimulationService } from '@/data/simulation/services/simulation.service';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { selectProject } from '../../state/project/selectors';

export interface CreateFloorplanFromSimulationDialogData {
  simulation?: SimulationBackendDict;
}

@Component({
  selector: 'app-create-floorplan-from-simulation-dialog',
  templateUrl: './create-floorplan-from-simulation-dialog.component.html',
  styleUrls: ['./create-floorplan-from-simulation-dialog.component.scss'],
})
export class CreateFloorplanFromSimulationDialogComponent
  implements OnInit, OnDestroy
{
  protected readonly RatingTypeLabels = RatingTypeLabels;
  entity?: SimulationBackendDict;
  form: FormGroup;

  private componentDestroyed$ = new Subject();
  constructor(
    private dialogRef: MatDialogRef<CreateFloorplanFromSimulationDialogComponent>,
    private simulationService: SimulationService,
    private uiHelperService: UIHelperService,
    private store: Store,
    @Inject(MAT_DIALOG_DATA)
    public data: CreateFloorplanFromSimulationDialogData
  ) {
    if (data?.simulation) {
      this.entity = Object.assign({}, data?.simulation);
    }
  }

  ngOnInit() {
    this.store
      .select(selectProject(this.entity?.project))
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(project => {
        this.form = createFloorplanFromSimulationForm(
          this.entity.name,
          project?.rating_type
        );
      });
  }

  save($event: MouseEvent) {
    this.simulationService
      .createFloorplanFromSimulation(
        this.entity.id,
        this.form.get('name').value,
        this.form.get('rating_type').value
      )
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: (response: SubmitToAxisResponseBackendDict) => {
          this.dialogRef.close(response);
        },
        error: (error?: HttpErrorResponse) =>
          this.uiHelperService.handleUserRequestError(error),
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
