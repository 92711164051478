import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { toggleLoading } from '@/state/actions/app.actions';
import { first } from 'rxjs/operators';
import {
  ServerErrorDialogComponent,
  ServerErrorDialogConfig,
} from '@/shared/components/server-error-dialog/server-error-dialog.component';
import { AltName, Company } from '@/data/company/models';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { Subject } from 'rxjs';
import { CompanyService } from '@/data/company/services/company-base.service';

export class CompanyAlternateNameCreateDialogData {
  company: Company;
  companyService: CompanyService;
}

@Component({
  selector: 'app-company-alternate-name-create-dialog',
  templateUrl: './company-alternate-name-create-dialog.component.html',
  styleUrls: ['./company-alternate-name-create-dialog.component.scss'],
})
export class CompanyAlternateNameCreateDialogComponent
  implements OnInit, OnDestroy
{
  formGroup: UntypedFormGroup;
  private componentDestroyed$ = new Subject();

  constructor(
    public dialogRef: MatDialogRef<File[]>,
    public store: Store<AppState>,
    public fb: UntypedFormBuilder,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: CompanyAlternateNameCreateDialogData
  ) {}

  ngOnInit() {
    this.setupForm();
  }

  setupForm() {
    this.formGroup = this.fb.group({
      name: ['', [Validators.required]],
    });
  }

  onConfirm($event: MouseEvent) {
    $event.preventDefault();
    this.formGroup.markAllAsTouched();
    if (this.formGroup.invalid) {
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const formData = new AltName();
    formData.name = this.formGroup.value.name;

    this.data.companyService
      .alt_namesCreate(this.data.company.id, formData)
      .pipe(first())
      .subscribe(
        _ => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close();
        },
        error => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialog.open(ServerErrorDialogComponent, {
            width: '400px',
            data: {
              title: 'Error:',
              data: error,
            } as ServerErrorDialogConfig,
          });
        }
      );
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
