<h2 mat-dialog-title>
  <ng-container *ngIf="!isEditForm">
    Create New <b>Brand</b>
  </ng-container>
  <ng-container *ngIf="isEditForm">
    Edit <b>Brand</b>
  </ng-container>
</h2>
<mat-dialog-content>
  <form [formGroup]="form" class="mb-2">
    <div class="row">
      <div class="col-12">
        <mat-form-field class="mb-2 w-100">
          <mat-label>Brand Name</mat-label>
          <input
            matInput
            type="text"
            placeholder="Brand Name"
            formControlName="title"
            autocomplete="off">
          <mat-error *ngIf="form.get('title').hasError('required')">
            Name is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button color="accent" mat-raised-button (click)="save($event)">Save</button>
  <button mat-button [mat-dialog-close]="null" color="primary" mat-raised-button>Cancel</button>
</mat-dialog-actions>
