import { requiredAsWarning } from '@/modules/simulation/validators/helper';
import { ModelFormControl, ModelFormGroup } from '../ModelForm';
import { FieldLabels } from '../base';

// Labels
export const FIELD_LABELS: FieldLabels<ThermostatBackendDict> = {
  brand: 'Brand',
  cooling_setpoint: 'Cooling Setpoint',
  heating_setpoint: 'Heating Setpoint',
  has_programmable_thermostat: 'Has Programmable Thermostat',
  has_smart_thermostat: 'Has Smart Thermostat',
};

export interface ThermostatBackendDict {
  id: number;
  brand: string;
  cooling_setpoint: number;
  heating_setpoint: number;
  has_programmable_thermostat: boolean;
  has_smart_thermostat: boolean;
}

export function createThermostatForm(
  thermostat: ThermostatBackendDict
): ModelFormGroup {
  return new ModelFormGroup(
    {
      id: new ModelFormControl(thermostat.id),
      brand: new ModelFormControl(thermostat.brand, requiredAsWarning()),
      cooling_setpoint: new ModelFormControl(
        thermostat.cooling_setpoint,
        requiredAsWarning()
      ),
      heating_setpoint: new ModelFormControl(
        thermostat.heating_setpoint,
        requiredAsWarning()
      ),
      has_programmable_thermostat: new ModelFormControl(
        thermostat.has_programmable_thermostat
      ),
      has_smart_thermostat: new ModelFormControl(
        thermostat.has_smart_thermostat
      ),
    },
    {
      validators: [
        (controls: ModelFormGroup) => {
          const heatingSetpoint = controls.get('heating_setpoint').value;
          if (heatingSetpoint === null) {
            return { missingHeatingSetpoint: true };
          }
        },
        (controls: ModelFormGroup) => {
          const coolingSetpoint = controls.get('cooling_setpoint').value;
          if (coolingSetpoint === null) {
            return { missingCoolingSetpoint: true };
          }
        },
      ],
    }
  );
}
