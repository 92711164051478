import { Component, OnDestroy, OnInit } from '@angular/core';

import { FormBuilder, FormGroup } from '@angular/forms';
import { User } from '@/data/core/models/user';

import { forkJoin, Observable, of, Subject, Subscription } from 'rxjs';

import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';

import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { first, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { getInfoUser } from '@/state/selectors/info.selector';

import { ServerResponse } from '@/core/schemes/server-response';
import { HttpErrorResponse } from '@angular/common/http';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';

import { OkDialogComponent } from '@/shared/components/ok-dialog/ok-dialog.component';
import {
  ProductRequestParams,
  ProductService,
} from '@/data/product/services/product.service';
import {
  BrandInfo,
  CategoryInfo,
  CompanyBrand,
  CustomerHIRLGCPProductReportDownload,
  CustomerHIRLProductList,
  GCPProductCertificateMultipleDownload,
  Product,
  ProductBulkPublish,
  ProductList,
  ProductStatus,
  ProductStatusLabelMapping,
} from '@/data/product/models';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { ProductPermissionService } from '@/modules/product/services/product-permission-service';
import { Company, CompanyInfo, CompanyType } from '@/data/company/models';
import {
  HIRLProductChangeDialogComponent,
  HIRLProductChangeDialogData,
} from '@/modules/customer-hirl/components/hirl-product-change-dialog/hirl-product-change-dialog.component';
import { NavigationService } from '@/shared/services/navigation-service.service';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { UniqueSet } from '@/core/utils';
import { toggleLoading } from '@/state/actions/app.actions';
import { AsynchronousProcessedDocumentStatusDialogComponent } from '@/modules/filehandling/components/asynchronous-processed-document-status-dialog/asynchronous-processed-document-status-dialog.component';
import { AsynchronousProcessedDocument } from '@/data/filehandling/models';
import {
  DateRangeMatSelectChoice,
  DateRangeMatSelectValue,
} from '@/shared/components/date-range-mat-select/date-range-mat-select.component';
import { HirlTriggerRenewalNotificationDialogComponent } from '@/modules/customer-hirl/components/hirl-trigger-renewal-notification-dialog/hirl-trigger-renewal-notification-dialog.component';

@Component({
  selector: 'app-hirl-product-list',
  templateUrl: './hirl-product-list.component.html',
  styleUrls: ['./hirl-product-list.component.scss'],
})
export class HIRLProductListComponent implements OnInit, OnDestroy {
  protected readonly ProductStatus = ProductStatus;
  protected readonly CompanyType = CompanyType;
  protected readonly ProductStatusLabelMapping = ProductStatusLabelMapping;
  protected readonly CustomerHIRLSettings = CustomerHIRLSettings;

  public selectAllEntities = false;

  public displayedColumns = [];

  public initialized = false;

  public createPermissionResponse: Observable<ObjectPermissionResponse>;

  public filterFromGroup: FormGroup;

  public currentUser: User;

  public entities: CustomerHIRLProductList[];
  public entitiesCount: number;
  public entitiesIsLoading = true;

  public selectedEntities: UniqueSet<CustomerHIRLProductList> =
    new UniqueSet<CustomerHIRLProductList>(
      (item: CustomerHIRLProductList) => item.id
    );

  public productRelated = true;

  public defaultParams: ProductRequestParams = new ProductRequestParams(
    1,
    '',
    '-created_date',
    25
  );
  public storedParams: ProductRequestParams = new ProductRequestParams(
    1,
    '',
    '-created_date',
    25
  );

  public hirlCertificateExpirationDateRanges: DateRangeMatSelectChoice[] = [
    new DateRangeMatSelectChoice('Any', null, null),
    new DateRangeMatSelectChoice(
      '60 days',
      new Date(new Date().setDate(new Date().getDate() - 60)),
      new Date(new Date().setDate(new Date().getDate() + 30))
    ),
    new DateRangeMatSelectChoice(
      '30 days',
      new Date(new Date().setDate(new Date().getDate() - 30)),
      new Date(new Date().setDate(new Date().getDate() + 30))
    ),
    new DateRangeMatSelectChoice(
      '15 days',
      new Date(new Date().setDate(new Date().getDate() - 15)),
      new Date(new Date().setDate(new Date().getDate() + 30))
    ),
    new DateRangeMatSelectChoice(
      '0 days',
      new Date(),
      new Date(new Date().setDate(new Date().getDate() + 30))
    ),
  ];
  private listSubscription$: Subscription;
  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private productService: ProductService,
    private productPermissionService: ProductPermissionService,
    private router: Router,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private uiHelperService: UIHelperService,
    private navigationService: NavigationService
  ) {}

  ngOnInit() {
    forkJoin({
      queryParams: this.activatedRoute.queryParams.pipe(first()),
      currentUser: this.store.select(getInfoUser).pipe(first()),
    }).subscribe(({ queryParams, currentUser }) => {
      this.currentUser = currentUser;
      this.storedParams.assignQueryParams(queryParams, [
        'status',
        'manufacturer',
        'categories',
        'company_brands',
        'hirl_certificate_expiration_date__range',
      ]);
      this.createPermissionResponse = this.productPermissionService.canCreate();

      if (
        this.currentUser.is_superuser ||
        this.currentUser.company_info?.slug === CustomerHIRLSettings.companySlug
      ) {
        this.displayedColumns = [
          'select',
          'manufacturer__name',
          'title',
          'thumbnail',
          'status',
          'hirl_certificate_number',
          'hirl_certificate_expiration_date',
          'paid_non_expired_initial_fee',
          'paid_non_expired_payment_request',
          'countersigned_agreement',
          'coi',
          'actions',
        ];
      } else {
        this.displayedColumns = [
          'title',
          'thumbnail',
          'status',
          'hirl_certificate_number',
          'hirl_certificate_expiration_date',
          'paid_non_expired_initial_fee',
          'paid_non_expired_payment_request',
          'countersigned_agreement',
          'coi',
          'actions',
        ];
      }

      this.initialized = true;

      this.setupFilterForm();
      this.hydrateForm();
      this.list();
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }
  }

  setupFilterForm() {
    this.filterFromGroup = this.fb.group({
      search: [null],
      manufacturer_info: [null],
      category_info: [null],
      company_brands_info: [null],
      status: [null],
      hirl_certificate_expiration_date__range_info: [null],
    });

    this.filterFromGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        let params = Object.assign(this.defaultParams, values);
        this.selectAllEntities = false;
        this.selectedEntities.clear();

        params = Object.assign(params, {
          manufacturer: params.manufacturer_info?.map(
            (manufacturer: CompanyInfo) => manufacturer.id
          ),
          brand: params.brand_info?.map((brand: BrandInfo) => brand.id),
          categories: params.category_info?.map(
            (category: CategoryInfo) => category.id
          ),
          company_brands: params.company_brands_info?.map(
            (company_brand: CompanyBrand) => company_brand.id
          ),
          hirl_certificate_expiration_date__gte: (
            params.hirl_certificate_expiration_date__range_info as DateRangeMatSelectValue
          )?.toQueryString()[0],
          hirl_certificate_expiration_date__lte: (
            params.hirl_certificate_expiration_date__range_info as DateRangeMatSelectValue
          )?.toQueryString()[1],
        });
        this.storedParams.assignFilterFormValues({
          formValues: params,
          excludedProperties: [
            'ordering',
            'category_info',
            'manufacturer_info',
            'company_brands_info',
            'hirl_certificate_expiration_date__range_info',
          ],
        });
        this.list();
      });
  }

  hydrateForm() {
    this.filterFromGroup.patchValue(this.storedParams, {
      emitEvent: false,
      onlySelf: true,
    });

    this.filterFromGroup
      .get('hirl_certificate_expiration_date__range_info')
      .patchValue(
        new DateRangeMatSelectValue(
          this.storedParams.hirl_certificate_expiration_date__gte,
          this.storedParams.hirl_certificate_expiration_date__lte
        ),
        { emitEvent: true, onlySelf: true }
      );
  }

  list() {
    this.entitiesIsLoading = true;

    // populate params to query string
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.storedParams.toQueryParams(),
      replaceUrl: true,
    });

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }

    this.listSubscription$ = this.productService
      .customer_hirl_list(this.storedParams)
      .pipe(takeUntil(this.componentDestroyed$), take(1))
      .subscribe({
        next: (data: ServerResponse<CustomerHIRLProductList>) => {
          this.entities = data.results;
          this.entitiesCount = data.count;
          this.entitiesIsLoading = false;
          if (this.selectAllEntities) {
            this.entities.forEach((row: ProductList) => {
              this.selectedEntities.add(row);
            });
          }
        },
        error: (error: HttpErrorResponse) => {
          if (error.status === 400) {
            // to avoid cycle loop, reload list only if invalid param in query detected
            let invalidQueryParam = false;
            for (const key of Object.keys(error.error)) {
              if (this.storedParams.hasOwnProperty(key)) {
                invalidQueryParam = true;
                delete this.storedParams[key];
              }
            }

            if (invalidQueryParam) {
              this.list();
            }
          }
        },
      });
  }

  onSort($event: Sort) {
    this.storedParams.ordering = `${$event.direction === 'desc' ? '-' : ''}${
      $event.active
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  onPaginateChange($event: PageEvent) {
    this.uiHelperService.getUISettings().rowsPerPage = $event.pageSize;
    this.storedParams.page_size = $event.pageSize;
    this.storedParams.page = $event.pageIndex + 1;
    this.list();
  }

  resetFilters($event: MouseEvent) {
    $event.preventDefault();
    this.filterFromGroup.reset();
  }

  showAppliedFiltersDialog($event: MouseEvent) {
    $event.preventDefault();

    let content = '';
    for (const [key, value] of Object.entries(this.getAppliedFilters())) {
      content += `<b>${key}</b>: ${value}<br>`;
    }

    this.dialog.open(OkDialogComponent, {
      data: {
        title: 'Current Applied Filters',
        content: content,
      },
    });
  }

  getAppliedFilters(): {
    [key: string]: string;
  } {
    return this.storedParams.toAppliedFilters({
      overwritePropertyValues: {},
      overwritePropertyLabels: {},
    });
  }

  create($event: MouseEvent) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(HIRLProductChangeDialogComponent, {
      width: '55%',
      disableClose: true,
      autoFocus: false,
      data: {} as HIRLProductChangeDialogData,
    });

    dialogRef.afterClosed().subscribe((result?: Product) => {
      if (!result) {
        return;
      }
      this.router.navigate(['/', 'hi', 'product', 'detail', result.id]);
      this.uiHelperService.openSnackBar(`Product Group successfully created`);
    });
  }

  companyFilterOptionDisplay(company: Company): string {
    return `${company.name}`;
  }

  onSelectAll() {
    if (this.selectAllEntities) {
      this.entities.forEach((row: ProductList) => {
        this.selectedEntities.add(row);
      });
    } else {
      this.selectedEntities.clear();
    }
  }

  onEntitySelect(event: MatCheckboxChange, row: ProductList): void {
    if (event.checked) {
      this.selectedEntities.add(row);
    } else {
      this.selectedEntities.delete(row);
    }

    this.selectAllEntities =
      this.selectedEntities.length &&
      this.selectedEntities.length === this.entitiesCount;
  }

  isEntityChecked(row: ProductList): boolean {
    return this.selectedEntities.has(row);
  }

  onPublish($event: MouseEvent) {
    $event.preventDefault();

    const data = new ProductBulkPublish();
    data.select_all = this.selectAllEntities;
    data.products = this.selectedEntities?.toArray().map(product => product.id);
    this.store.dispatch(toggleLoading({ payload: true }));
    this.productService
      .bulk_publish(data, this.storedParams)
      .pipe(
        takeUntil(this.componentDestroyed$),
        first(),
        switchMap(_ => {
          this.list();
          return of(this.listSubscription$);
        }),
        tap(_ => {
          this.store.dispatch(toggleLoading({ payload: false }));
        })
      )
      .subscribe({
        error: (error: HttpErrorResponse) =>
          this.uiHelperService.handleUserRequestError(error),
      });
  }

  downloadReport($event: MouseEvent) {
    $event.preventDefault();

    this.store.dispatch(toggleLoading({ payload: true }));

    const productReportDownload = new CustomerHIRLGCPProductReportDownload();

    this.productService
      .customer_hirl_gcp_products_report(
        productReportDownload,
        this.storedParams
      )
      .pipe(first())
      .subscribe(
        (asynchronousProcessedDocument: AsynchronousProcessedDocument) => {
          this.store.dispatch(toggleLoading({ payload: false }));

          const asynchronousProcessedDocumentDialogRef = this.dialog.open(
            AsynchronousProcessedDocumentStatusDialogComponent,
            {
              width: '50%',
              data: {
                entity: asynchronousProcessedDocument,
                retrieve: true,
              },
            }
          );
          asynchronousProcessedDocumentDialogRef.afterClosed().subscribe();
        },
        error => this.uiHelperService.handleUserRequestError(error)
      );
  }

  certificateDownload($event: MouseEvent) {
    $event.preventDefault();

    this.store.dispatch(toggleLoading({ payload: true }));

    const certificateDownloadData = new GCPProductCertificateMultipleDownload();
    certificateDownloadData.products = this.selectedEntities
      ?.toArray()
      .filter(
        (product: CustomerHIRLProductList) =>
          product.status === ProductStatus.active
      )
      .map((product: CustomerHIRLProductList) => product.id);
    certificateDownloadData.select_all = this.selectAllEntities;

    this.productService
      .customer_hirl_gcp_certificate_multiple_download(
        certificateDownloadData,
        this.storedParams
      )
      .pipe(first())
      .subscribe(
        (asynchronousProcessedDocument: AsynchronousProcessedDocument) => {
          this.store.dispatch(toggleLoading({ payload: false }));

          const asynchronousProcessedDocumentDialogRef = this.dialog.open(
            AsynchronousProcessedDocumentStatusDialogComponent,
            {
              width: '50%',
              data: {
                entity: asynchronousProcessedDocument,
                retrieve: true,
              },
            }
          );
          asynchronousProcessedDocumentDialogRef.afterClosed().subscribe();
        },
        error => this.uiHelperService.handleUserRequestError(error)
      );
  }

  openRenewalReminderDialog($event: MouseEvent) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(
      HirlTriggerRenewalNotificationDialogComponent,
      {
        width: '45%',
        disableClose: true,
        autoFocus: false,
        data: {},
      }
    );

    dialogRef.afterClosed().subscribe((company?: CompanyInfo) => {
      if (!company) {
        return;
      }
      this.uiHelperService.openSnackBar(`Renewal successfully sent`);
    });
  }
}
