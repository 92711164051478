import { Component, Input, OnInit } from '@angular/core';
import { CompanyDisplayComponentType } from '@/shared/components/company-display/company-display.component';
import { Subdivision, SubdivisionInfo } from '@/data/subdivision/models';

export enum SubdivisionDisplayComponentType {
  onlyName = 'onlyName',
  nameAndBuilder = 'nameAndBuilder',
  onlyNameAsLink = 'onlyNameAsLink',
  nameAndBuilderAsLink = 'nameAndBuilderAsLink',
}

@Component({
  selector: 'app-subdivision-display',
  templateUrl: './subdivision-display.component.html',
  styleUrls: ['./subdivision-display.component.scss'],
})
export class SubdivisionDisplayComponent implements OnInit {
  protected readonly SubdivisionDisplayComponentType =
    SubdivisionDisplayComponentType;

  @Input() displayType:
    | CompanyDisplayComponentType
    | keyof typeof CompanyDisplayComponentType =
    CompanyDisplayComponentType.onlyName;
  @Input() subdivision?: Subdivision | SubdivisionInfo;

  ngOnInit(): void {}
}
