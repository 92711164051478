import { Action, createReducer, on } from '@ngrx/store';
import { HIRLProductAgreement } from '@/data/customer-hirl/models';
import {
  deleteHIRLProductAgreementDocument,
  deleteHIRLProductAgreementNote,
  loadHIRLProductAgreement,
  loadHIRLProductAgreementDocuments,
  loadHIRLProductAgreementDocumentsCount,
  loadHIRLProductAgreementNotes,
  loadHIRLProductAgreementNotesCount,
  resetHIRLProductAgreement,
  updateHIRLProductAgreementDocument,
  updateHIRLProductAgreementNote,
} from '@/state/actions/customer-hirl/hirl-product-agreement.actions';
import { CustomerDocument } from '@/data/filehandling/models';
import { Annotation } from '@/data/annotation/models';

export const hirlProductAgreementStateKey = 'hirlProductAgreement';

export interface HIRLProductAgreementState {
  detail: HIRLProductAgreement;
  documents: CustomerDocument[];
  documentsCount: number;
  notes: Annotation[];
  notesCount: number;
}

export const HIRLProductAgreementInitialState: HIRLProductAgreementState = {
  detail: null,
  documents: [],
  documentsCount: 0,
  notes: [],
  notesCount: 0,
};

const HIRLProductAgreementReducer = createReducer(
  HIRLProductAgreementInitialState,
  on(resetHIRLProductAgreement, (state, action) => ({
    ...HIRLProductAgreementInitialState,
  })),
  on(loadHIRLProductAgreement, (state, { payload }) => ({
    ...state,
    detail: payload,
  })),
  // Documents
  on(loadHIRLProductAgreementDocuments, (state, { payload }) => ({
    ...state,
    documents: payload,
  })),
  on(loadHIRLProductAgreementDocumentsCount, (state, { payload }) => ({
    ...state,
    documentsCount: payload,
  })),
  on(updateHIRLProductAgreementDocument, (state, { payload }) => {
    const documentIndex = state.documents.findIndex(
      document => document.id === payload.id
    );
    const newDocuments = Object.assign([], state.documents, {
      [documentIndex]: payload,
    });
    return { ...state, documents: newDocuments };
  }),
  on(deleteHIRLProductAgreementDocument, (state, { payload }) => {
    const documentIndex = state.documents.findIndex(
      document => document.id === payload.id
    );
    const newDocuments = Object.assign([], state.documents);
    newDocuments.splice(documentIndex, 1);
    return {
      ...state,
      documents: newDocuments,
      documentsCount: state.documentsCount - 1,
    };
  }),
  // Notes
  on(loadHIRLProductAgreementNotes, (state, { payload }) => ({
    ...state,
    notes: payload,
  })),
  on(loadHIRLProductAgreementNotesCount, (state, { payload }) => ({
    ...state,
    notesCount: payload,
  })),
  on(updateHIRLProductAgreementNote, (state, { payload }) => {
    const noteIndex = state.notes.findIndex(note => note.id === payload.id);
    const newNotes = Object.assign([], state.notes, { [noteIndex]: payload });
    return { ...state, notes: newNotes };
  }),
  on(deleteHIRLProductAgreementNote, (state, { payload }) => {
    const noteIndex = state.notes.findIndex(note => note.id === payload.id);
    const newNotes = Object.assign([], state.notes);
    newNotes.splice(noteIndex, 1);
    return { ...state, notes: newNotes, notesCount: state.notesCount - 1 };
  })
);

export function reducer(
  state: HIRLProductAgreementState | undefined,
  action: Action
) {
  return HIRLProductAgreementReducer(state, action);
}
