import { Component, OnDestroy, OnInit } from '@angular/core';
import { HIRLProductAgreement } from '@/data/customer-hirl/models';
import { forkJoin, Observable, Subject } from 'rxjs';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { User } from '@/data/core/models/user';
import { ConfirmedGeocodeResponse } from '@/modules/geocoder/components/confirm-geocode-response-dialog/confirm-geocode-response';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { filter, first, takeUntil } from 'rxjs/operators';
import { toggleLoading } from '@/state/actions/app.actions';
import { getInfoUser } from '@/state/selectors/info.selector';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { Company } from '@/data/company/models';
import {
  HIRLProductAgreementState,
  HIRLProductAgreementStateLabelMapping,
} from '@/data/customer-hirl/models/product-agreement';
import {
  getHIRLProductAgreement,
  getHIRLProductAgreementDocumentsCount,
  getHIRLProductAgreementNotesCount,
} from '@/state/selectors/customer-hirl/hirl-product-agreement.selector';
import { HIRLProductAgreementService } from '@/data/customer-hirl/services/product-agreement.service';
import { HIRLProductAgreementPermissionService } from '@/modules/customer-hirl/services/hirl-product-agreement-permission.service';
import {
  HirlProductAgreementChangeDialogComponent,
  HirlProductAgreementChangeDialogData,
} from '@/modules/customer-hirl/components/hirl-product-agreement-change-dialog/hirl-product-agreement-change-dialog.component';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';
import { HttpErrorResponse } from '@angular/common/http';
import { NavigationService } from '@/shared/services/navigation-service.service';
import { resetHIRLProductAgreement } from '@/state/actions/customer-hirl/hirl-product-agreement.actions';

@Component({
  selector: 'app-hirl-product-agreement-detail',
  templateUrl: './hirl-product-agreement-detail.component.html',
  styleUrls: ['./hirl-product-agreement-detail.component.scss'],
})
export class HirlProductAgreementDetailComponent implements OnInit, OnDestroy {
  protected readonly CustomerHIRLSettings = CustomerHIRLSettings;
  protected readonly HIRLProductAgreementState = HIRLProductAgreementState;
  protected readonly HIRLProductAgreementStateLabelMapping =
    HIRLProductAgreementStateLabelMapping;

  public initialized = false;
  public productAgreement: HIRLProductAgreement;
  public productAgreementDocumentsCount = 0;
  public productAgreementNotesCount = 0;

  public editPermissionResponse: Observable<ObjectPermissionResponse>;
  public deletePermissionResponse: Observable<ObjectPermissionResponse>;
  public reSendEmailPermissionResponse: Observable<ObjectPermissionResponse>;
  public updateDocusignStatusPermissionResponse: Observable<ObjectPermissionResponse>;
  public counterSignPermissionResponse: Observable<ObjectPermissionResponse>;

  public currentUser: User;

  public confirmedGeocodeResponse: ConfirmedGeocodeResponse;
  public selectedIndex = 0;

  private componentDestroyed$ = new Subject();
  constructor(
    private store: Store<AppState>,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private productAgreementService: HIRLProductAgreementService,
    private productAgreementPermissionService: HIRLProductAgreementPermissionService,
    private uiHelperService: UIHelperService,
    private navigationService: NavigationService
  ) {
    this.store
      .select(getHIRLProductAgreement)
      .pipe(
        filter(val => val !== null),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((productAgreement: HIRLProductAgreement) => {
        this.productAgreement = productAgreement;
        this.productAgreementSetup();
      });

    this.store
      .select(getHIRLProductAgreementDocumentsCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(count => {
        this.productAgreementDocumentsCount = count;
      });

    this.store
      .select(getHIRLProductAgreementNotesCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(count => {
        this.productAgreementNotesCount = count;
      });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(params => {
        if (params.tab) {
          this.selectedIndex = Number(params.tab) || 0;
        }
      });

    this.activatedRoute.params
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(params => {
        this.store.dispatch(toggleLoading({ payload: true }));
        forkJoin({
          productAgreement: this.productAgreementService
            .retrieve(params.productAgreementId)
            .pipe(first()),
          currentUser: this.store.select(getInfoUser).pipe(first()),
        }).subscribe(
          ({
            productAgreement,
            currentUser,
          }: {
            productAgreement: HIRLProductAgreement;
            currentUser: User;
          }) => {
            this.productAgreement = productAgreement;

            this.currentUser = currentUser;
            this.productAgreementSetup();
            this.store.dispatch(toggleLoading({ payload: false }));
            this.initialized = true;
          },
          error => this.uiHelperService.handleMainRequestError(error)
        );
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
    this.store.dispatch(resetHIRLProductAgreement());
  }

  productAgreementSetup() {
    this.editPermissionResponse =
      this.productAgreementPermissionService.canEdit(this.productAgreement);
    this.deletePermissionResponse =
      this.productAgreementPermissionService.canDelete(this.productAgreement);
    this.reSendEmailPermissionResponse =
      this.productAgreementPermissionService.canReSendEmail(
        this.productAgreement
      );
    this.counterSignPermissionResponse =
      this.productAgreementPermissionService.canCounterSign(
        this.productAgreement
      );
    this.updateDocusignStatusPermissionResponse =
      this.productAgreementPermissionService.canUpdateDocusignStatus(
        this.productAgreement
      );
  }

  onTabChange($event: MatTabChangeEvent) {
    const url = this.router
      .createUrlTree([], {
        relativeTo: this.activatedRoute,
        queryParams: { tab: $event.index },
      })
      .toString();
    this.location.replaceState(url);
  }

  reSendEmail($event: MouseEvent) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '40%',
      data: {
        title: 'Are you sure you want to re-send Docusign email ?',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (!result) {
          return;
        }
        this.store.dispatch(toggleLoading({ payload: true }));
        this.productAgreementService
          .resend_docusign_email(this.productAgreement.id)
          .pipe(takeUntil(this.componentDestroyed$), first())
          .subscribe(
            _ => {
              this.store.dispatch(toggleLoading({ payload: false }));
              this.uiHelperService.openSnackBar(`Email Successfully Sent`);
            },
            error => this.uiHelperService.handleUserRequestError(error)
          );
      });
  }

  delete($event: MouseEvent) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '40%',
      data: {
        title: 'Are you sure you want delete Product Agreement ?',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (!result) {
          return;
        }
        this.store.dispatch(toggleLoading({ payload: true }));
        this.productAgreementService
          .delete(this.productAgreement.id)
          .pipe(takeUntil(this.componentDestroyed$), first())
          .subscribe(
            _ => {
              this.store.dispatch(toggleLoading({ payload: false }));
              this.router.navigate(['/', 'hi', 'builder-agreements']);
            },
            error => this.uiHelperService.handleUserRequestError(error)
          );
      });
  }

  edit($event: MouseEvent) {
    $event.preventDefault();

    const dialogRef = this.dialog.open(
      HirlProductAgreementChangeDialogComponent,
      {
        width: '55%',
        disableClose: true,
        autoFocus: false,
        data: {
          productAgreement: this.productAgreement,
        } as HirlProductAgreementChangeDialogData,
      }
    );

    dialogRef.afterClosed().subscribe((result?: Company) => {
      if (!result) {
        return;
      }
      this.uiHelperService.openSnackBar('Record Successfully Updated');
    });
  }

  approve($event: MouseEvent) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '40%',
      data: {
        title: 'Are you sure you want to Approve Manufacturer Agreement?',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (!result) {
          return;
        }
        this.store.dispatch(toggleLoading({ payload: true }));
        this.productAgreementService
          .approve(this.productAgreement.id)
          .pipe(takeUntil(this.componentDestroyed$), first())
          .subscribe(
            _ => {
              this.store.dispatch(toggleLoading({ payload: false }));
              this.uiHelperService.openSnackBar(
                `Manufacturer Agreement successfully Approved`
              );
            },
            error => this.uiHelperService.handleUserRequestError(error)
          );
      });
  }

  counterSign($event: MouseEvent) {
    $event.preventDefault();
    this.store.dispatch(toggleLoading({ payload: true }));
    this.productAgreementService
      .verify(this.productAgreement.id)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: _ => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.uiHelperService.openSnackBar(
            `Manufacturer Agreement successfully updated`
          );
        },
        error: (error: HttpErrorResponse) =>
          this.uiHelperService.handleUserRequestError(error),
      });
  }

  updateDocusignStatus($event: MouseEvent) {
    $event.preventDefault();
    this.store.dispatch(toggleLoading({ payload: true }));
    this.productAgreementService
      .update_docusign_status(this.productAgreement.id)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(
        _ => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.uiHelperService.openSnackBar(
            `Manufacturer Agreement status successfully updated`
          );
        },
        error => this.uiHelperService.handleUserRequestError(error)
      );
  }

  forceState($event: MouseEvent) {
    $event.preventDefault();

    // const dialogRef = this.dialog.open(
    //   HirlClientAgreementForceStateChangeDialogComponent,
    //   {
    //     width: '40%',
    //     disableClose: true,
    //     autoFocus: false,
    //     data: {
    //       clientAgreement: this.clientAgreement,
    //     } as HirlClientAgreementForceStateChangeDialogData,
    //   }
    // );
    //
    // dialogRef.afterClosed().subscribe((result?: Company) => {
    //   if (!result) {
    //     return;
    //   }
    //   this.uiHelperService.openSnackBar('Record Successfully Updated');
    // });
  }
}
