import { Action, createReducer, on } from '@ngrx/store';
import { toggleLoading, toggleBooting } from '@/state/actions/app.actions';

export const appFeatureKey = 'application';

export interface ApplicationState {
  loading: boolean;
  booting: boolean;
}
export const initialState: ApplicationState = {
  loading: false,
  booting: true,
};
const applicationReducer = createReducer(
  initialState,
  on(toggleLoading, (state, { payload }) => ({ ...state, loading: payload })),
  on(toggleBooting, (state, { payload }) => ({ ...state, booting: payload }))
);
export function reducer(state: ApplicationState | undefined, action: Action) {
  return applicationReducer(state, action);
}
