import { Location } from '@/data/simulation/enumerations';
import { FrameFloorTypeBackendDict } from './FrameFloorType';
import { ModelFormControl, ModelFormGroup } from '../ModelForm';
import { Validators } from '@angular/forms';
import { enumValidator } from '@/modules/simulation/validators/helper';
import { FieldLabels } from '../base';
import { SimulationModelsState } from '@/modules/simulation/state/reducers';

export const DEFAULT_FRAME_FLOOR: FrameFloorBackendDict = {
  id: null,
  name: '',
  type: null,
  interior_location: null,
  exterior_location: null,
  area: 0,
  represents_ceiling: false,
};

export enum CRAWL_SPACE_LOCATIONS {
  CRAWL_SPACE_UNVENTED_INSULATED = Location.CRAWL_SPACE_UNVENTED_INSULATED,
  CRAWL_SPACE_UNVENTED_UNINSULATED = Location.CRAWL_SPACE_UNVENTED_UNINSULATED,
  CRAWL_SPACE_VENTED_UNINSULATED = Location.CRAWL_SPACE_VENTED_UNINSULATED,
  CRAWL_SPACE_VENTED_INSULATED = Location.CRAWL_SPACE_VENTED_INSULATED,
  CRAWL_SPACE_VENTED_INSULATED_FLOOR = Location.CRAWL_SPACE_VENTED_INSULATED_FLOOR,
  CRAWL_SPACE_UNVENTED_INSULATED_FLOOR = Location.CRAWL_SPACE_UNVENTED_INSULATED_FLOOR,
  ENCLOSED_CRAWL = Location.ENCLOSED_CRAWL,
  CONDITIONED_CRAWL_SPACE = Location.CONDITIONED_CRAWL_SPACE,
  OPEN_CRAWL_SPACE = Location.OPEN_CRAWL_SPACE,
}

export enum InteriorLocation {
  CONDITIONED_SPACE = Location.CONDITIONED_SPACE,
  ATTIC_VENTED = Location.ATTIC_VENTED,
  SEALED_ATTIC = Location.SEALED_ATTIC,
  CONDITIONED_BASEMENT = Location.CONDITIONED_BASEMENT,
  UNCONDITIONED_BASEMENT = Location.UNCONDITIONED_BASEMENT,
  CRAWL_SPACE_VENTED = Location.CRAWL_SPACE_VENTED,
  CRAWL_SPACE_UNVENTED = Location.CRAWL_SPACE_UNVENTED,
  GARAGE = Location.GARAGE,
}

export enum ExteriorLocation {
  NONE = Location.NONE,

  ATTIC_VENTED = Location.ATTIC_VENTED,
  SEALED_ATTIC = Location.SEALED_ATTIC,
  CONDITIONED_BASEMENT = Location.CONDITIONED_BASEMENT,
  UNCONDITIONED_BASEMENT = Location.UNCONDITIONED_BASEMENT,
  UNCONDITIONED_INSULATED_BASEMENT = Location.UNCONDITIONED_INSULATED_BASEMENT,
  CRAWL_SPACE_VENTED = Location.CRAWL_SPACE_VENTED,
  CRAWL_SPACE_UNVENTED = Location.CRAWL_SPACE_UNVENTED,
  ENCLOSED_CRAWL = Location.ENCLOSED_CRAWL,
  CONDITIONED_CRAWL_SPACE = Location.CONDITIONED_CRAWL_SPACE,
  GARAGE = Location.GARAGE,
  MF_UNRATED_HEATED = Location.MF_UNRATED_HEATED,
  MF_BUFFER = Location.MF_BUFFER,
  MF_NONFREEZING = Location.MF_NONFREEZING,
  MF_UNRATED_CONDITIONED = Location.MF_UNRATED_CONDITIONED,
  CONDITIONED_SPACE = Location.CONDITIONED_SPACE,
  UNCONDITIONED_GARAGE = Location.UNCONDITIONED_GARAGE,
  AMBIENT = Location.AMBIENT,
}

// Labels
export const FIELD_LABELS: FieldLabels<FrameFloorBackendDict> = {
  name: 'Name',
  type: 'Type',
  interior_location: 'Interior Location',
  exterior_location: 'Exterior Location',
  area: 'Area',
  represents_ceiling: 'Represents Ceiling',
};

export interface FrameFloorBackendDict {
  id: number;
  type: number;
  name: string;
  interior_location: InteriorLocation;
  exterior_location: ExteriorLocation;
  area: number;
  represents_ceiling: boolean;
}

export interface DetailedFrameFloorBackendDict extends FrameFloorBackendDict {
  type_info: FrameFloorTypeBackendDict;
}

export function createFrameFloorForm(
  frameFloor: FrameFloorBackendDict = DEFAULT_FRAME_FLOOR
) {
  return new ModelFormGroup({
    id: new ModelFormControl(frameFloor.id, Validators.required),
    name: new ModelFormControl(frameFloor.name, [
      Validators.required,
      Validators.maxLength(128),
    ]),
    type: new ModelFormControl(frameFloor.type, Validators.required),
    interior_location: new ModelFormControl(frameFloor.interior_location, [
      enumValidator(InteriorLocation),
    ]),
    exterior_location: new ModelFormControl(frameFloor.exterior_location, [
      enumValidator(ExteriorLocation),
    ]),
    area: new ModelFormControl(frameFloor.area, [
      Validators.required,
      Validators.min(0),
    ]),
    represents_ceiling: new ModelFormControl(frameFloor.represents_ceiling),
  });
}

export function isLocationAssociatedWithBasement(location: ExteriorLocation) {
  return (
    location in
    [
      Location.UNKNOWN,
      Location.NONE,
      Location.ADIABATIC,
      Location.AMBIENT,
      Location.GARAGE,
      Location.UNCONDITIONED_GARAGE,
    ]
  );
}

export function denormalizeFrameFloors(state: SimulationModelsState) {
  const denormalizedFrameFloors: DetailedFrameFloorBackendDict[] = [];
  for (const frameFloor of Object.values(state.frameFloor.entities)) {
    denormalizedFrameFloors.push({
      ...frameFloor,
      type_info: state.frameFloorType.entities[frameFloor.type],
    });
  }
  return denormalizedFrameFloors;
}
