import { Component } from '@angular/core';
import { BaseAppliancesDirective } from '../base-appliances.directive';
import {
  AppliancesBackendDict,
  CeilingFanConsumptionUnitLabels,
  createFansForm,
  FIELD_LABELS,
} from '@/data/simulation/models/Appliances';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-fans',
  templateUrl: './fans.component.html',
  styleUrls: ['./fans.component.scss'],
})
export class FansComponent extends BaseAppliancesDirective {
  createForm: (data: AppliancesBackendDict) => FormGroup<any> = createFansForm;
  fieldLabels = FIELD_LABELS;
  ceilingFanConsumptionUnitLabels = CeilingFanConsumptionUnitLabels;
}
