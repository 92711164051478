<div class="container" *ngIf="initialized">
    <div class="row">
        <div class="col-11">
            <h3>
                <button mat-raised-button color="accent" appBackButton [fallbackUrl]="'/hi/hirl_rpc_updater_request_list'">
                    <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
                </button>
                NGBS Update Request {{ hirlRPCUpdaterRequest.id }}
            </h3>
        </div>
        <div class="col-1 text-right">
            <div class="d-flex justify-content-center" *ngIf="isRefreshing">
                <mat-spinner [diameter]="20"></mat-spinner>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <hr>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                </div>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                        <div class="row">
                            <div class="col-12">
                                <b>Current status:</b> {{ hirlRPCUpdaterRequestStateLabelMapping[hirlRPCUpdaterRequest.state] }}
                                <span *ngIf="hirlRPCUpdaterRequest.state === hirlRPCUpdaterRequestState.inProgress">
                                    <fa-icon [icon]="['fas', 'spinner']" [spin]="true"></fa-icon>
                                    (<i>Average time for update takes 8-10 minutes</i>)
                                </span>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item">
                        <div class="row">
                            <div class="col-12">
                                <b>Project Type:</b>
                                {{ rpcUpdaterProjectTypeLabelMapping[hirlRPCUpdaterRequest.project_type] }}
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item">
                        <div class="row">
                            <div class="col-12">
                                <b>Scoring Path:</b>
                                {{ rpcUpdaterScoringPathLabelMapping[hirlRPCUpdaterRequest.scoring_path] }}
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item">
                        <div class="row">
                            <div class="col-12">
                                <b>Input Document:</b>
                                <a href="{{ hirlRPCUpdaterRequest.document }}" target="_blank" *ngIf="hirlRPCUpdaterRequest.document">
                                    <fa-icon [icon]="['fas', 'download']"></fa-icon>
                                </a>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item">
                        <div class="row">
                            <div class="col-12">
                                <b>Output Document:</b>
                                <a href="{{ hirlRPCUpdaterRequest.result_document }}" target="_blank" *ngIf="hirlRPCUpdaterRequest.result_document">
                                    <fa-icon [icon]="['fas', 'download']"></fa-icon>
                                </a>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item">
                        <div class="row">
                            <div class="col-12">
                                <b>Creation Date:</b>
                                {{ hirlRPCUpdaterRequest.created_at | date: 'MM/dd/yyyy h:mm a' }}
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
