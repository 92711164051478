import { Component, Input, OnInit, OnDestroy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';

export interface ConsumptionDialogData {
  title: string;
  consumption: Record<string, unknown>;
}

@Component({
  selector: 'app-analytics-consumption',
  templateUrl: './analytics-consumption.component.html',
  styleUrls: ['./analytics-consumption.component.scss'],
})
export class AnalyticsConsumptionComponent implements OnInit, OnDestroy {
  @Input() consumption;

  public initialized: false;
  public title: string;

  public componentDestroyed$ = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConsumptionDialogData,
    private dialogRef: MatDialogRef<AnalyticsConsumptionComponent>
  ) {
    this.consumption = this.data.consumption;
    this.title = this.data.title;
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  onClose() {
    this.dialogRef.close();
  }
}
