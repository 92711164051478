import { selectDistributionSystemErrorViewSet } from '@/modules/simulation/state/distribution-system/selectors';
import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-distribution-system-errors',
  templateUrl: './distribution-system-errors.component.html',
  styleUrls: ['./distribution-system-errors.component.scss'],
})
export class DistributionSystemErrorsComponent implements OnInit {
  @Input() entityId: number;
  componentDestroyed$: Subject<boolean> = new Subject();
  name: string;
  totalErrorCount = 0;
  totalWarningCount = 0;
  ductsInfo = [];

  constructor(public store: Store) {}

  ngOnInit(): void {
    this.store
      .select(selectDistributionSystemErrorViewSet(this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.totalErrorCount = data.errorCount;
        this.totalWarningCount = data.warningCount;
        this.name = data.name;
        this.ductsInfo = data.ductsInfo;
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
