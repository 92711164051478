import {
  UtilityRateBackendDict,
  createUtilityRateForm,
} from '@/data/simulation/models/utility/UtilityRate';
import { BaseValidator } from './base.validator';
import { ModelErrors, StateErrors } from '../state/shared/base.state';

export class UtilityRateValidator extends BaseValidator {
  static validateForm(
    utilityRate: UtilityRateBackendDict
  ): ModelErrors<UtilityRateBackendDict> {
    const form = createUtilityRateForm(utilityRate);
    return form.getFieldAndModelErros();
  }

  static validate(
    utilityRates: UtilityRateBackendDict[]
  ): StateErrors<UtilityRateBackendDict> {
    const errors = {};
    utilityRates.forEach(utilityRate => {
      errors[utilityRate.id] = this.validateForm(utilityRate);
    });
    return errors;
  }
}
