// @fileoverview ModelGraphService is a service that keeps track of all the models in the simulation.
// The attachModel() is used to attach a model to its parent model.
// The getParentId() is used to get the parent id of a model.
// This service is used to find the parent model of a given model. For example,
// we can use this service to find the parent surface model of a given heat-path model.

import { Injectable } from '@angular/core';

interface Node {
  modelName: string;
  modelId: number;
  children: Node[];
  parentModelName: string;
  parentModelId: number;
}

const MAX_GRAPH_DEPTH = 8;

@Injectable({
  providedIn: 'root',
})
export class ModelGraphService {
  public nodes: { [key: string]: Node } = {};

  private getKey(modelName: string, modelId: number): string {
    return `${modelName}_${modelId}`;
  }

  attachModel(
    modelName: string,
    modelId: number,
    childModelName: string,
    childrenIds: number[]
  ): void {
    const parentNodeKey = this.getKey(modelName, modelId);
    if (!this.nodes[parentNodeKey]) {
      this.nodes[parentNodeKey] = {
        modelName: modelName,
        modelId: modelId,
        children: [],
        parentModelName: null,
        parentModelId: null,
      };
    }

    for (const childId of childrenIds) {
      const childNodeKey = this.getKey(childModelName, childId);
      if (!this.nodes[childNodeKey]) {
        this.nodes[childNodeKey] = {
          modelName: childModelName,
          modelId: childId,
          children: [],
          parentModelName: modelName,
          parentModelId: modelId,
        };
      }
      this.nodes[parentNodeKey].children.push(this.nodes[childNodeKey]);
    }
  }

  getParentId(
    modelName: string,
    modelId: number,
    parentModelName: string
  ): number | null {
    const node = this.nodes[this.getKey(modelName, modelId)];

    if (!node) return null;

    if (node.parentModelName === parentModelName) {
      return node.parentModelId;
    }

    return this.getParentId(
      node.parentModelName,
      node.parentModelId,
      parentModelName
    );
  }
}
