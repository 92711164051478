import {
  FoundationWallBackendDict,
  DetailedFoundationWallBackendDict,
} from '@/data/simulation/models/enclosure/FoundationWall';
import { createAction, props } from '@ngrx/store';
import { ModelErrors, StateErrors } from '../shared/base.state';

export const loadDetailedFoundationWalls = createAction(
  '[Simulation API] Load Detailed FoundationWalls',
  props<{ detailedFoundationWalls: DetailedFoundationWallBackendDict[] }>()
);

export const loadFoundationWallsSuccess = createAction(
  '[FoundationWall API] Load FoundationWalls Success',
  props<{
    foundationWalls: FoundationWallBackendDict[];
    errors: StateErrors<FoundationWallBackendDict>;
  }>()
);

export const updateFoundationWall = createAction(
  '[FoundationWall UI] Update FoundationWall',
  props<{
    id: number;
    foundationWallChanges: Partial<FoundationWallBackendDict>;
  }>()
);

export const updateFoundationWallSuccess = createAction(
  '[FoundationWall API] Update FoundationWall Success',
  props<{
    foundationWall: FoundationWallBackendDict;
    errors: ModelErrors<FoundationWallBackendDict>;
  }>()
);

export const updateFoundationWallFailure = createAction(
  '[FoundationWall API] Update FoundationWall Failure',
  props<{ id: number }>()
);

export const removeFoundationWall = createAction(
  '[FoundationWall UI] Remove FoundationWall',
  props<{ foundationWall: FoundationWallBackendDict }>()
);

export const removeFoundationWallSuccess = createAction(
  '[FoundationWall API] Remove FoundationWall Success',
  props<{ id: number }>()
);

export const removeFoundationWallFailure = createAction(
  '[FoundationWall API] Remove FoundationWall Failure',
  props<{ id: number }>()
);

export const updateFoundationWallErrors = createAction(
  '[FoundationWall UI] Update FoundationWall Errors',
  props<{ id: number; errors: any }>()
);

export const setFoundationWallType = createAction(
  '[FoundationWallType API] Set FoundationWallType',
  props<{ wallId: number; wallTypeId: number | null }>()
);

export const setFoundationWallTypeSuccess = createAction(
  '[FoundationWallType API] Set FoundationWallType null',
  props<{ wallId: number; wallTypeId: number | null }>()
);
