import { createAction, props } from '@ngrx/store';
import { Community } from '@/data/community/models';
import { CustomerDocument } from '@/data/filehandling/models';
import { COIDocument } from '@/data/customer-hirl/models';
import { ContactCard } from '@/data/core/models';
import { Equipment } from '@/data/equipment/models';

export const resetCommunity = createAction('[Community] Reset');

export const loadCommunity = createAction(
  '[Community] Detail',
  props<{ payload: Community }>()
);

export const loadCommunityCommunityAccessCountAction = createAction(
  '[Community CommunityAccess] Count',
  props<{ payload: number }>()
);

export const loadCommunityDocuments = createAction(
  '[Community Documents] Documents',
  props<{ payload: CustomerDocument[] }>()
);

export const loadCommunityDocumentsCount = createAction(
  '[Community Documents] Documents Count',
  props<{ payload: number }>()
);

export const updateCommunityDocument = createAction(
  '[Community Documents] Update Document',
  props<{ payload: CustomerDocument }>()
);

export const deleteCommunityDocument = createAction(
  '[Community Documents] Delete Document',
  props<{ payload: CustomerDocument }>()
);

export const loadCommunityAltNamesCount = createAction(
  '[Community AltNames] AltNames Count',
  props<{ payload: number }>()
);

export const loadCommunityAffiliationPreferencesCount = createAction(
  '[Community AffiliationPreferences] AffiliationPreferences Count',
  props<{ payload: number }>()
);

export const loadCommunitySponsoringPreferencesCount = createAction(
  '[Community SponsoringPreferences] SponsoringPreferences Count',
  props<{ payload: number }>()
);

// CustomerDocuments

export const loadCommunityCOIDocuments = createAction(
  '[Community COI Documents] COI Documents',
  props<{ payload: COIDocument[] }>()
);

export const loadCommunityCOIDocumentsCount = createAction(
  '[Community COI Documents] COI Documents Count',
  props<{ payload: number }>()
);

export const updateCommunityCOIDocument = createAction(
  '[Community COI Documents] Update COI Document',
  props<{ payload: COIDocument }>()
);

// ContactCards

export const loadCommunityContactCards = createAction(
  '[Community Contact Cards] Contact Cards',
  props<{ payload: ContactCard[] }>()
);

export const loadCommunityContactCardsCount = createAction(
  '[Community Contact Cards] Contact Cards Count',
  props<{ payload: number }>()
);

export const updateCommunityContactCard = createAction(
  '[Community Contact Cards] Update Contact Card',
  props<{ payload: ContactCard }>()
);

// Equipments
export const loadCommunityEquipments = createAction(
  '[Community Equipments] COI Documents',
  props<{ payload: Equipment[] }>()
);

export const loadCommunityEquipmentsCount = createAction(
  '[Community Equipments] COI Documents Count',
  props<{ payload: number }>()
);

export const updateCommunityEquipment = createAction(
  '[Community Equipments] Update COI Document',
  props<{ payload: Equipment }>()
);
