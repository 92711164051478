<ng-container *ngIf="hirlProductAgreement">
  <ng-container [ngSwitch]="displayType">
    <ng-container *ngSwitchCase="HirlProductAgreementDisplayComponentType.onlyName">
      Manufacturer Agreement #{{ hirlProductAgreement.id }}
    </ng-container>
    <ng-container *ngSwitchCase="HirlProductAgreementDisplayComponentType.onlyNameAsLink">
      <a
        [routerLink]="[
        '/',
        'hi',
        'product',
        'agreements',
        'detail',
        hirlProductAgreement?.id
      ]">
        Manufacturer Agreement #{{ hirlProductAgreement.id }}
      </a>
    </ng-container>
    <ng-container *ngSwitchCase="HirlProductAgreementDisplayComponentType.nameAsLinkAndStatus">
      <a
        [routerLink]="[
        '/',
        'hi',
        'product',
        'agreements',
        'detail',
        hirlProductAgreement?.id
      ]">
        Manufacturer Agreement #{{ hirlProductAgreement.id }}
      </a>
      <span
        class="badge ml-2 {{ HIRLProductAgreementStateLabelMapping[hirlProductAgreement.state]?.badgeClass }}">{{ HIRLProductAgreementStateLabelMapping[hirlProductAgreement.state]?.label }}</span>
    </ng-container>
  </ng-container>
</ng-container>
