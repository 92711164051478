import { AppState } from '@/state/reducers';
import { createSelector } from '@ngrx/store';
import { companyStateKey } from '@/state/reducers/company/company.reducer';

export const selectCompanyState = (state: AppState) => state[companyStateKey];

export const getCompany = createSelector(
  selectCompanyState,
  store => store.detail
);
// Company Documents
export const getCompanyDocuments = createSelector(
  selectCompanyState,
  store => store.documents
);

export const getCompanyDocumentsCount = createSelector(
  selectCompanyState,
  store => store.documentsCount
);

// Alternate Names
export const getCompanyAltNames = createSelector(
  selectCompanyState,
  store => store.altNames
);

// Company AffiliationPreferences
export const getCompanyAffiliationPreferences = createSelector(
  selectCompanyState,
  store => store.affiliationPreferences
);

// Company SponsoringPreferences
export const getCompanySponsoringPreferences = createSelector(
  selectCompanyState,
  store => store.sponsoringPreferences
);

export const getCompanySponsoringPreferencesCount = createSelector(
  selectCompanyState,
  store => store.sponsoringPreferencesCount
);

// Company CompanyAccess
export const selectCompanyCompanyAccesses = createSelector(
  selectCompanyState,
  store => store.companyAccesses
);

export const selectCompanyCompanyAccessesCount = createSelector(
  selectCompanyState,
  store => store.companyAccessesCount
);

// COI Documents
export const getCompanyCOIDocuments = createSelector(
  selectCompanyState,
  store => store.coiDocuments
);

export const getCompanyCOIDocumentsCount = createSelector(
  selectCompanyState,
  store => store.coiDocumentsCount
);

// Contact Cards
export const selectCompanyContactCards = createSelector(
  selectCompanyState,
  store => store.contactCards
);

export const selectCompanyContactCardsCount = createSelector(
  selectCompanyState,
  store => store.contactCardsCount
);

// Equipment
export const selectCompanyEquipments = createSelector(
  selectCompanyState,
  store => store.equipments
);

export const selectCompanyEquipmentsCount = createSelector(
  selectCompanyState,
  store => store.equipmentsCount
);

// RemRate Users
export const selectCompanyRemRateUsers = createSelector(
  selectCompanyState,
  store => store.remRateUsers
);

export const selectCompanyRemRateUsersCount = createSelector(
  selectCompanyState,
  store => store.remRateUsersCount
);

// Notes
export const selectCompanyNotes = createSelector(
  selectCompanyState,
  store => store.notes
);

export const selectCompanyNotesCount = createSelector(
  selectCompanyState,
  store => store.notesCount
);
