export class HirlMarketingRole {
  id: number;
  slug: string;
  name: string;
}

export class HirlMarketingRoleInfo {
  id: number;
  slug: string;
  name: string;
}
