import {
  RimJoistBackendDict,
  createRimJoistForm,
} from '@/data/simulation/models/enclosure/RimJoist';
import { BaseValidator } from './base.validator';
import { ModelErrors, StateErrors } from '../state/shared/base.state';

export class RimJoistValidator extends BaseValidator {
  static validateForm(
    rimjoist: RimJoistBackendDict
  ): ModelErrors<RimJoistBackendDict> {
    const rimjoistForm = createRimJoistForm(rimjoist);
    return rimjoistForm.getFieldAndModelErros();
  }

  static validate(
    rimjoists: RimJoistBackendDict[]
  ): StateErrors<RimJoistBackendDict> {
    const errors = {};
    rimjoists.forEach(rimjoist => {
      errors[rimjoist.id] = this.validateForm(rimjoist);
    });
    return errors;
  }
}
