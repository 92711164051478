<div *ngIf="initialized; else loading;">

    <mat-expansion-panel *ngIf="count > 0">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Windows
            </mat-panel-title>
            <div matBadge="{{ count }}" matBadgeSize="medium" class="countBadge"></div>
        </mat-expansion-panel-header>
        <ng-container *ngTemplateOutlet="windowsView; context: {windows: windows}"></ng-container>
    </mat-expansion-panel>
</div>

<ng-template #windowsView let-windows="windows">
    <div class="row mt-2">
        <ng-container *ngFor="let window of windows; let index=index">
            <div class="col-6 mt-2">
                <div class="card">
                    <div class="card-content">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item header"
                                style="background-color:rgba(0, 0, 0, 0.03); align-items: center;">
                                <b>
                                    {{ window.name }}
                                </b>
                            </li>
                            <li class="list-group-item">
                                <b>Area:</b>
                                {{ window.area }} {{window.area_units_display}}
                            </li>
                            <li class="list-group-item">
                                <b>Orientation:</b>
                                {{ window.orientation_display }}
                            </li>
                            <li class="list-group-item">
                                <b>SHGC:</b>
                                {{window.type_info.shgc}}
                            </li>
                            <li class="list-group-item">
                                <b>U-Value:</b>
                                {{ window.type_info.u_value }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="w-100" *ngIf="(index+1) % 2 === 0"></div>
        </ng-container>
    </div>
</ng-template>

<ng-template #loading>
    <div class="row">
        <div class="col-12">
            <div class="d-flex justify-content-center" style="height: 320px;">
                <mat-spinner [diameter]="50"></mat-spinner>
            </div>
        </div>
    </div>
</ng-template>