import { Action, createReducer, on } from '@ngrx/store';
import { ClientAgreement } from '@/data/customer-hirl/models';
import {
  deleteClientAgreementDocument,
  deleteClientAgreementNote,
  loadClientAgreement,
  loadClientAgreementDocuments,
  loadClientAgreementDocumentsCount,
  loadClientAgreementNotes,
  loadClientAgreementNotesCount,
  resetClientAgreement,
  updateClientAgreementDocument,
  updateClientAgreementNote,
} from '@/state/actions/customer-hirl/client-agreement.actions';
import { CustomerDocument } from '@/data/filehandling/models';
import { Annotation } from '@/data/annotation/models';

export const hirlClientAgreementStateKey = 'hirlClientAgreement';

export interface HIRLClientAgreementState {
  detail: ClientAgreement;
  documents: CustomerDocument[];
  documentsCount: number;
  notes: Annotation[];
  notesCount: number;
}

export const HIRLClientAgreementInitialState: HIRLClientAgreementState = {
  detail: null,
  documents: [],
  documentsCount: 0,
  notes: [],
  notesCount: 0,
};

const HIRLClientAgreementReducer = createReducer(
  HIRLClientAgreementInitialState,
  on(resetClientAgreement, (state, action) => ({
    ...HIRLClientAgreementInitialState,
  })),
  on(loadClientAgreement, (state, { payload }) => ({
    ...state,
    detail: payload,
  })),
  // Documents
  on(loadClientAgreementDocuments, (state, { payload }) => ({
    ...state,
    documents: payload,
  })),
  on(loadClientAgreementDocumentsCount, (state, { payload }) => ({
    ...state,
    documentsCount: payload,
  })),
  on(updateClientAgreementDocument, (state, { payload }) => {
    const documentIndex = state.documents.findIndex(
      document => document.id === payload.id
    );
    const newDocuments = Object.assign([], state.documents, {
      [documentIndex]: payload,
    });
    return { ...state, documents: newDocuments };
  }),
  on(deleteClientAgreementDocument, (state, { payload }) => {
    const documentIndex = state.documents.findIndex(
      document => document.id === payload.id
    );
    const newDocuments = Object.assign([], state.documents);
    newDocuments.splice(documentIndex, 1);
    return {
      ...state,
      documents: newDocuments,
      documentsCount: state.documentsCount - 1,
    };
  }),
  // Notes
  on(loadClientAgreementNotes, (state, { payload }) => ({
    ...state,
    notes: payload,
  })),
  on(loadClientAgreementNotesCount, (state, { payload }) => ({
    ...state,
    notesCount: payload,
  })),
  on(updateClientAgreementNote, (state, { payload }) => {
    const noteIndex = state.notes.findIndex(note => note.id === payload.id);
    const newNotes = Object.assign([], state.notes, { [noteIndex]: payload });
    return { ...state, notes: newNotes };
  }),
  on(deleteClientAgreementNote, (state, { payload }) => {
    const noteIndex = state.notes.findIndex(note => note.id === payload.id);
    const newNotes = Object.assign([], state.notes);
    newNotes.splice(noteIndex, 1);
    return { ...state, notes: newNotes, notesCount: state.notesCount - 1 };
  })
);

export function reducer(
  state: HIRLClientAgreementState | undefined,
  action: Action
) {
  return HIRLClientAgreementReducer(state, action);
}
