import {
  Component,
  DoCheck,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NgControl,
} from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { FocusMonitor } from '@angular/cdk/a11y';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SearchInputComponent,
    },
  ],
})
export class SearchInputComponent
  implements
    ControlValueAccessor,
    MatFormFieldControl<string>,
    OnInit,
    OnDestroy,
    DoCheck
{
  static nextId = 0;
  private placeholder$ = 'Type to search something';
  private required$ = false;
  private disabled$ = false;

  @ViewChild('searchInput') searchInput?: ElementRef;
  @Input() debounceTime = 1000;

  @HostBinding('[id]')
  public id = `app-search-input-${SearchInputComponent.nextId++}`;

  // component logic
  public searchControl = new UntypedFormControl();
  public searchTerm$: Subject<string> = new Subject<string>();

  private componentDestroyed$ = new Subject();

  // angular mat input
  public focused = false;
  public touched = false;
  public stateChanges = new Subject<void>();
  public describedBy = '';
  public onChange = (_: any) => {};
  public onTouched = () => {};

  get empty() {
    return !this.searchControl.value;
  }

  @HostBinding('[class.app-search-input-floating]')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get errorState() {
    return this.ngControl.errors !== null && this.touched;
  }

  @Input()
  get placeholder(): string {
    if (this.shouldLabelFloat) {
      return this.placeholder$;
    }
    return '';
  }

  set placeholder(value: string) {
    this.placeholder$ = value;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this.required$;
  }

  set required(value: boolean) {
    this.required$ = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this.disabled$;
  }

  set disabled(value: boolean) {
    this.disabled$ = coerceBooleanProperty(value);
    this.disabled$ ? this.searchControl.disable() : this.searchControl.enable();
    this.stateChanges.next();
  }

  @Input()
  get value(): string | null {
    return this.searchControl.value;
  }

  set value(value: string | null) {
    this.searchControl.setValue(value);
    this.onChange(value);
    this.stateChanges.next();
  }

  constructor(
    private focusMonitorService: FocusMonitor,
    private elementRef$: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl
  ) {
    focusMonitorService.monitor(elementRef$, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.searchTerm$
      .pipe(
        takeUntil(this.componentDestroyed$),
        debounceTime(this.debounceTime),
        distinctUntilChanged()
      )
      .subscribe(value => {
        this.value = value;
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  ngDoCheck(): void {
    if (
      this.ngControl.control &&
      this.touched !== this.ngControl.control.touched
    ) {
      this.touched = this.ngControl.touched;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  // angular mat input methods
  onFocusIn(_: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (
      !this.elementRef$.nativeElement.contains(event.relatedTarget as Element)
    ) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick($event: MouseEvent) {
    $event.stopPropagation();
    if (this.disabled) {
      return;
    }
    this.onTouched();
    if (($event.target as Element).classList.contains('clear-btn')) {
      this.clear();
    } else {
      this.handleInput();
    }
  }

  writeValue(value: string | null): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private clear(): void {
    this.value = null;
  }

  private handleInput() {
    this.searchInput?.nativeElement.focus();
  }

  // component methods

  applySearch(value: string) {
    this.searchTerm$.next(value);
  }
}
