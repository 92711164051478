import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-error-count',
  templateUrl: './error-count.component.html',
  styleUrls: ['./error-count.component.scss'],
})
export class ErrorCountComponent {
  @Input() errorCount: number = null;
  @Input() warningCount: number = null;
}
