import { createReducer, on } from '@ngrx/store';
import * as SkylightActions from './actions';
import { SkylightBackendDict } from '@/data/simulation/models/enclosure/Skylight';
import { BaseState, sharedInitialState } from '../shared/base.state';
import { addErrorCount, addStateErrorCount } from '../helper';

export type SkylightState = BaseState<SkylightBackendDict>;

export const initialState: SkylightState = sharedInitialState;

export const reducer = createReducer(
  initialState,
  on(SkylightActions.loadSkylightsSuccess, (currentState, payload) => {
    const entities = payload.skylights.reduce(
      (acc, skylight) => ({ ...acc, [skylight.id]: skylight }),
      {}
    );
    const loadingStatus = payload.skylights.reduce(
      (acc, skylight) => ({ ...acc, [skylight.id]: false }),
      {}
    );

    return {
      entities: { ...currentState.entities, ...entities },
      loading: { ...currentState.loading, ...loadingStatus },
      errors: { ...currentState.errors, ...addStateErrorCount(payload.errors) },
    };
  }),

  on(SkylightActions.removeSkylightSuccess, (currentState, { id }) => {
    const { [id]: removed, ...entities } = currentState.entities;
    const { [id]: removedLoading, ...loading } = currentState.loading;
    const { [id]: removedErrors, ...errors } = currentState.errors;

    return {
      entities,
      loading,
      errors,
    };
  }),

  on(SkylightActions.updateSkylight, (state, { id }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [id]: true,
      },
    };
  }),

  on(SkylightActions.removeSkylight, (state, { skylight }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [skylight.id]: true,
      },
    };
  }),

  on(
    SkylightActions.updateSkylightFailure,
    SkylightActions.removeSkylightFailure,
    (state, { id }) => {
      return {
        ...state,
        loading: {
          ...state.loading,
          [id]: false,
        },
      };
    }
  ),

  on(SkylightActions.updateSkylightErrors, (state, { id, errors }) => {
    return {
      ...state,
      errors: {
        ...state.errors,
        [id]: errors,
      },
    };
  }),

  on(SkylightActions.updateSkylightSuccess, (state, { skylight, errors }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        [skylight.id]: skylight,
      },
      loading: {
        ...state.loading,
        [skylight.id]: false,
      },
      errors: {
        ...state.errors,
        [skylight.id]: addErrorCount(errors),
      },
    };
  }),

  on(
    SkylightActions.setSkylightType,
    (state, { skylightId, skylightTypeId }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          [skylightId]: {
            ...state.entities[skylightId],
            type: skylightTypeId,
          },
        },
      };
    }
  )
);
