import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { User } from '@/data/core/models/user';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { UserService } from '@/data/core/services/user.service';
import { forkJoin, of, Subject } from 'rxjs';
import { HIRLUserProfile } from '@/data/customer-hirl/models';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, switchMap, takeUntil } from 'rxjs/operators';
import { toggleLoading } from '@/state/actions/app.actions';
import { ServerResponse } from '@/core/schemes/server-response';
import { CompanyAccess } from '@/data/company/models';
import { RaterRole } from '@/data/core/models';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfirmedGeocodeResponse } from '@/modules/geocoder/components/confirm-geocode-response-dialog/confirm-geocode-response';

export interface IUserChangeDialogData {
  entity: User;
  commit?: boolean;
}

@Component({
  selector: 'app-user-change-dialog',
  templateUrl: './user-change-dialog.component.html',
  styleUrls: ['./user-change-dialog.component.scss'],
})
export class UserChangeDialogComponent implements OnInit, OnDestroy {
  public selectedIndex = 0;

  public mainInformationFormGroup: FormGroup;
  public contactFormGroup: FormGroup;
  public hesFormGroup: FormGroup;
  public raterFormGroup: FormGroup;
  public ngbsFormGroup: FormGroup;
  public providerFormGroup: FormGroup;

  public initialized: boolean;
  public isEditForm = false;
  public entity?: User;
  public commit = true;
  public retrieve = true;
  public currentUser: User;

  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<UserChangeDialogComponent>,
    private fb: FormBuilder,
    private userService: UserService,
    private uiHelperService: UIHelperService,
    @Inject(MAT_DIALOG_DATA) public data: IUserChangeDialogData
  ) {
    if (data?.entity) {
      this.entity = Object.assign({}, data?.entity);
    }
    this.commit = data?.commit || true;
    if (this.entity?.id) {
      this.isEditForm = true;
    }
  }

  ngOnInit() {
    const sources = {
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: of(this.entity),
    };

    this.store.dispatch(toggleLoading({ payload: true }));

    if (this.isEditForm && this.retrieve) {
      sources.entity = this.userService
        .retrieve(this.entity.id, { ignoreStore: true })
        .pipe(takeUntil(this.componentDestroyed$), first());
    }

    forkJoin(sources)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: ({
          currentUser,
          entity,
        }: {
          currentUser: User;
          entity: User;
        }) => {
          this.store.dispatch(toggleLoading({ payload: false }));

          this.currentUser = currentUser;
          this.entity = entity;

          this.setupMainInformationFormGroup();
          this.setupContactFormGroup();
          this.setupHESFormGroup();
          this.setupRaterFormGroup();
          this.setupNGBSFormData();
          this.setupProviderFormData();
          this.initialized = true;
          this.hydrateForm();
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  setupMainInformationFormGroup() {
    this.mainInformationFormGroup = this.fb.group({
      first_name: [null, Validators.required],
      last_name: [null, Validators.required],
      title: [null],
      department: [null],
    });
  }

  setupContactFormGroup() {
    this.contactFormGroup = this.fb.group({
      email: [
        null,
        Validators.compose([Validators.required, Validators.email]),
      ],
      work_phone: [null],
      cell_phone: [null],
      mailing_address_geocode: [null],
      shipping_address_geocode: [null],
    });
  }

  setupHESFormGroup() {
    this.hesFormGroup = this.fb.group({
      username: [null],
      password: [null],
    });
  }

  setupRaterFormGroup() {
    this.raterFormGroup = this.fb.group({
      rater_roles: [null],
      resnet: [null],
      signature_image: [null],
    });
  }

  setupNGBSFormData() {
    this.ngbsFormGroup = this.fb.group({
      is_qa_designee: [false],
    });
  }

  setupProviderFormData() {
    this.providerFormGroup = this.fb.group({
      resnet_username: [null],
      resnet_password: [null],
    });
  }

  hydrateForm() {
    const payload = Object.assign({}, this.entity);

    this.mainInformationFormGroup.patchValue(payload, {
      emitEvent: false,
      onlySelf: true,
    });
    this.contactFormGroup.patchValue(payload, {
      emitEvent: false,
      onlySelf: true,
    });
    this.raterFormGroup.patchValue(payload, {
      emitEvent: false,
      onlySelf: true,
    });
    this.hesFormGroup.patchValue(payload, { emitEvent: false, onlySelf: true });
    this.providerFormGroup.patchValue(payload, {
      emitEvent: false,
      onlySelf: true,
    });

    this.ngbsFormGroup.patchValue(payload.hirl_user_profile, {
      emitEvent: false,
      onlySelf: true,
    });

    this.contactFormGroup.patchValue({
      mailing_address_geocode: new ConfirmedGeocodeResponse({
        geocode: this.entity.mailing_geocode_info,
        geocodeBrokeredResponse: this.entity.mailing_geocode_response_info,
      }),
      shipping_address_geocode: new ConfirmedGeocodeResponse({
        geocode: this.entity.shipping_geocode_info,
        geocodeBrokeredResponse: this.entity.shipping_geocode_response_info,
      }),
    });
  }

  getAllFormGroups(): FormGroup[] {
    return [
      this.mainInformationFormGroup,
      this.contactFormGroup,
      this.raterFormGroup,
      this.hesFormGroup,
      this.ngbsFormGroup,
      this.providerFormGroup,
    ];
  }

  save($event: MouseEvent) {
    $event.preventDefault();
    if (this.isEditForm) {
      this.edit();
    } else {
      this.create();
    }
  }

  private create() {}

  private edit(): void {
    this.getAllFormGroups().forEach(form => {
      form.markAllAsTouched();
    });

    if (this.getAllFormGroups().some(form => form.invalid)) {
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const imageData = new FormData();
    imageData.append(
      'signature_image',
      this.raterFormGroup.value.signature_image
    );

    // ngbs data
    const hirlUserProfileData = new HIRLUserProfile();
    hirlUserProfileData.is_qa_designee =
      this.ngbsFormGroup.value.is_qa_designee;

    // send other data as separate request
    const userData = new User();
    userData.rater_roles = this.raterFormGroup.value.rater_roles?.map(
      rater_role => rater_role.id
    );
    userData.hirl_user_profile = hirlUserProfileData;
    userData.first_name = this.mainInformationFormGroup.value.first_name;
    userData.last_name = this.mainInformationFormGroup.value.last_name;
    userData.title = this.mainInformationFormGroup.value.title;
    userData.department = this.mainInformationFormGroup.value.department;

    userData.email = this.contactFormGroup.value.email;
    userData.work_phone = this.contactFormGroup.value.work_phone;
    userData.cell_phone = this.contactFormGroup.value.cell_phone;

    userData.mailing_geocode =
      this.contactFormGroup.value.mailing_address_geocode?.geocode?.id;
    userData.mailing_geocode_response = null;

    if (
      this.contactFormGroup.value.mailing_address_geocode
        ?.geocodeBrokeredResponse
    ) {
      userData.mailing_geocode_response =
        this.contactFormGroup.value.mailing_address_geocode?.geocodeBrokeredResponse?.id;
    }

    userData.shipping_geocode =
      this.contactFormGroup.value.shipping_address_geocode?.geocode?.id;
    userData.shipping_geocode_response = null;

    if (
      this.contactFormGroup.value.shipping_address_geocode
        ?.geocodeBrokeredResponse
    ) {
      userData.shipping_geocode_response =
        this.contactFormGroup.value.shipping_address_geocode?.geocodeBrokeredResponse?.id;
    }

    userData.resnet_username = this.providerFormGroup.value.resnet_username;
    userData.resnet_password = this.providerFormGroup.value.resnet_password;

    this.userService
      .update(userData, this.entity.id)
      .pipe(
        first(),
        switchMap((user: User) => this.userService.update(imageData, user.id))
      )
      .pipe(
        switchMap((user: User) => {
          const sources: { [k: string]: any } = {
            entity: of(user),
            companyAccessesServerResponse: this.userService
              .company_access(this.entity.id)
              .pipe(first()),
            raterRolesServerResponse: this.userService.rater_roles(
              this.entity.id
            ),
          };
          return forkJoin(sources);
        })
      )
      .subscribe({
        next: ({
          entity,
          companyAccessesServerResponse,
          raterRolesServerResponse,
        }: {
          entity: User;
          companyAccessesServerResponse: ServerResponse<CompanyAccess>;
          raterRolesServerResponse: ServerResponse<RaterRole>;
        }) => {
          this.entity = entity;
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close(entity);
        },
        error: (error: HttpErrorResponse) =>
          this.uiHelperService.handleMainRequestError(error),
      });
  }
}
