import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FloorplanListPageComponent } from './pages/floorplan-list-page/floorplan-list-page.component';
import { SharedModule } from '@/shared/shared.module';
import { FloorplanDetailPageComponent } from './pages/floorplan-detail-page/floorplan-detail-page.component';
import { SimulationCompareComponent } from './components/simulation-compare/simulation-compare-component';
import { SimulationDetailTabComponent } from './components/simulation-detail-tab/simulation-detail-tab.component';
import { GeocoderModule } from '@/modules/geocoder/geocoder.module';
import { FloorplanHomesTabComponent } from './components/floorplan-homes-tab/floorplan-homes-tab.component';
import { SimulationMechanicalsComponent } from './components/simulation-detail-tab/simulation-detail/simulation-mechanicals/simulation-mechanicals.component';
import { EquipmentHeaterComponent } from './components/simulation-detail-tab/simulation-detail/simulation-mechanicals/equipment-heater/equipment-heater.component';
import { EquipmentAirConditionerComponent } from './components/simulation-detail-tab/simulation-detail/simulation-mechanicals/equipment-air-conditioner/equipment-air-conditioner.component';
import { EquipmentWaterHeaterComponent } from './components/simulation-detail-tab/simulation-detail/simulation-mechanicals/equipment-water-heater/equipment-water-heater.component';
import { EquipmentAirSourceHeatPumpComponent } from './components/simulation-detail-tab/simulation-detail/simulation-mechanicals/equipment-air-source-heat-pump/equipment-air-source-heat-pump.component';
import { EquipmentGroundSourceHeatPumpComponent } from './components/simulation-detail-tab/simulation-detail/simulation-mechanicals/equipment-ground-source-heat-pump/equipment-ground-source-heat-pump.component';
import { EquipmentDehumidifierComponent } from './components/simulation-detail-tab/simulation-detail/simulation-mechanicals/equipment-dehumidifier/equipment-dehumidifier.component';
import { EnclosureRoofsComponent } from './components/simulation-detail-tab/simulation-detail/simulation-enclosures/enclosure-roofs/enclosure-roofs.component';
import { EnclosureFloorsComponent } from './components/simulation-detail-tab/simulation-detail/simulation-enclosures/enclosure-floors/enclosure-floors.component';
import { EnclosureFoundationWallsComponent } from './components/simulation-detail-tab/simulation-detail/simulation-enclosures/enclosure-foundation-walls/enclosure-foundation-walls.component';
import { EnclosureAbovegradeWallsComponent } from './components/simulation-detail-tab/simulation-detail/simulation-enclosures/enclosure-abovegrade-walls/enclosure-abovegrade-walls.component';
import { EnclosureWindowsComponent } from './components/simulation-detail-tab/simulation-detail/simulation-enclosures/enclosure-windows/enclosure-windows.component';
import { EnclosureDoorsComponent } from './components/simulation-detail-tab/simulation-detail/simulation-enclosures/enclosure-doors/enclosure-doors.component';
import { EnclosureSlabsComponent } from './components/simulation-detail-tab/simulation-detail/simulation-enclosures/enclosure-slabs/enclosure-slabs.component';
import { EnclosureSkylightsComponent } from './components/simulation-detail-tab/simulation-detail/simulation-enclosures/enclosure-skylights/enclosure-skylights.component';
import { EnclosureRimjoistsComponent } from './components/simulation-detail-tab/simulation-detail/simulation-enclosures/enclosure-rimjoists/enclosure-rimjoists.component';
import { SimulationPhotovoltaicsComponent } from './components/simulation-detail-tab/simulation-detail/simulation-photovoltaics/simulation-photovoltaics.component';
import { FloorplanAnalyticsTabComponent } from './components/floorplan-analytics-tab/floorplan-analytics-tab.component';
import { AnalyticsConsumptionComponent } from './components/floorplan-analytics-tab/analytics-consumption/analytics-consumption.component';
import { AnalyticsFuelUsageComponent } from './components/floorplan-analytics-tab/analytics-fuel-usage/analytics-fuel-usage.component';
import { SimulationInfiltrationComponent } from './components/simulation-detail-tab/simulation-detail/simulation-infiltration/simulation-infiltration.component';
import { SimulationLightsComponent } from './components/simulation-detail-tab/simulation-detail/simulation-lights/simulation-lights.component';
import { SimulationAppliancesComponent } from './components/simulation-detail-tab/simulation-detail/simulation-appliances/simulation-appliances.component';
import { AssociatedCompaniesComponent } from '@/shared/components/associated-companies/associated-companies.component';
import { FloorplanChangeDialogComponent } from './components/floorplan-change-dialog/floorplan-change-dialog.component';
import { FilehandlingModule } from '@/modules/filehandling/filehandling.module';
import { FloorplanFromBLGDialogComponent } from './components/floorplan-from-blg-dialog/floorplan-from-blg-dialog.component';
import { FloorplanFromSimulationDialogComponent } from './components/floorplan-from-simulation-dialog/floorplan-from-simulation-dialog.component';
import { SeedCreateDialogComponent } from './components/seed-create-dialog/seed-create-dialog.component';
@NgModule({
  declarations: [
    FloorplanListPageComponent,
    FloorplanDetailPageComponent,
    SimulationCompareComponent,
    SimulationDetailTabComponent,
    SimulationMechanicalsComponent,
    EquipmentHeaterComponent,
    EquipmentAirConditionerComponent,
    EquipmentWaterHeaterComponent,
    EquipmentAirSourceHeatPumpComponent,
    EquipmentGroundSourceHeatPumpComponent,
    EquipmentDehumidifierComponent,
    EnclosureRoofsComponent,
    EnclosureFloorsComponent,
    EnclosureFoundationWallsComponent,
    EnclosureAbovegradeWallsComponent,
    FloorplanHomesTabComponent,
    EnclosureWindowsComponent,
    EnclosureDoorsComponent,
    EnclosureSlabsComponent,
    EnclosureSkylightsComponent,
    EnclosureRimjoistsComponent,
    SimulationPhotovoltaicsComponent,
    FloorplanAnalyticsTabComponent,
    AnalyticsConsumptionComponent,
    AnalyticsFuelUsageComponent,
    SimulationInfiltrationComponent,
    SimulationLightsComponent,
    SimulationAppliancesComponent,
    AssociatedCompaniesComponent,
    FloorplanChangeDialogComponent,
    FloorplanFromBLGDialogComponent,
    FloorplanFromSimulationDialogComponent,
    SeedCreateDialogComponent,
  ],
  imports: [CommonModule, SharedModule, GeocoderModule, FilehandlingModule],
  exports: [],
})
export class FloorPlanModule {}
