<mat-select
  [formControl]="selectedRange"
  (focus)="onFocus()"
  (blur)="onBlur()"
  [placeholder]="placeholder"
  [id]="id"
  aria-describedby="{{ id }}-error"
>
  <mat-option *ngFor="let range of dateRanges" [value]="range.value">
    {{ range.label }}
  </mat-option>
</mat-select>
