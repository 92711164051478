import { createReducer, on } from '@ngrx/store';
import * as ProjectActions from './actions';
import { ProjectBackendDict } from '@/data/simulation/models/Project';
import { BaseState } from '../shared/base.state';
import { addStateErrorCount } from '../helper';

export type ProjectState = BaseState<ProjectBackendDict>;

export const initialState: ProjectState = {
  entities: {},
  loading: {},
  errors: {},
};

export const reducer = createReducer(
  initialState,
  on(ProjectActions.loadProjectsSuccess, (state, { projects, errors }) => {
    const entities = projects.reduce(
      (acc, project) => ({ ...acc, [project.id]: project }),
      {}
    );
    const loading = projects.reduce(
      (acc, project) => ({ ...acc, [project.id]: false }),
      {}
    );

    return {
      entities: {
        ...state.entities,
        ...entities,
      },
      loading: {
        ...state.loading,
        ...loading,
      },
      errors: {
        ...state.errors,
        ...addStateErrorCount(errors),
      },
    };
  }),

  on(ProjectActions.updateProject, (state, { projectChanges }) => ({
    ...state,
    loading: {
      ...state.loading,
      [projectChanges.id]: true,
    },
  })),

  on(ProjectActions.updateProjectFailure, (state, { id }) => ({
    ...state,
    loading: {
      ...state.loading,
      [id]: false,
    },
  }))
);
