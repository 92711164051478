<h2 mat-dialog-title>
    {{ title }}
</h2>

<div class="row mt-2">
    <div class="col-12 mt-2">
        <div class="card">
            <div class="card-content">
                <ul class="list-group list-group-flush">
                    <ng-container>
                        <li class="list-group-item">
                            <b>Cooling Consumption:</b>
                            {{ fuelUsage.cooling_consumption | number: '1.2-2' }} {{
                            fuelUsage.consumption_units_display }}
                        </li>
                        <li class="list-group-item">
                            <b>Heating Consumption:</b>
                            {{ fuelUsage.heating_consumption | number: '1.2-2' }} {{
                            fuelUsage.consumption_units_display }}
                        </li>
                        <li class="list-group-item">
                            <b>Lights and Appliances Consumption:</b>
                            {{ fuelUsage.lighting_and_appliances_consumption | number: '1.2-2' }} {{
                            fuelUsage.consumption_units_display }}
                        </li>
                        <li class="list-group-item">
                            <b>Total Consumption:</b>
                            {{ fuelUsage.total_consumption | number: '1.2-2' }} {{
                            fuelUsage.consumption_units_display }}
                        </li>
                        <li class="list-group-item">
                            <b>Total Cost:</b>
                             {{ fuelUsage.cost_units_display }}{{ fuelUsage.cost | number: '1.2-2' }}
                        </li>
                    </ng-container>
                    <button mat-raised-button color="accent" (click)="onClose()">Close</button>
                </ul>
            </div>
        </div>
    </div>
</div>