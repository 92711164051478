import { ReportsService } from '@/data/simulation/services/reports.services';
import {
  Component,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { selectSimulation } from '../../state/simulation/selectors';
import {
  catchError,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { SimulationRightsService } from '../../services/simulation-rights.service';
import { SimulationService } from '@/data/simulation/services/simulation.service';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import {
  EditorNavigationService,
  Tab,
} from '../../services/editor-navigation.service';
import { BackendValidationResponseDialogComponent } from '../reports/backend-validation-response-dialog/backend-validation-response-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { selectErrorAndWarningCount } from '../../state/simulation.selectors';
import { getFlatResults } from '@/data/simulation/models/reports/ValidationResult';
import { of, Subject, throwError } from 'rxjs';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { RunSimulationStatusComponent } from '../run-simulation-status/run-simulation-status.component';
import { OpenStudioEriService } from '@/data/simulation/services/open-studio-eri.services';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';

@Component({
  selector: 'app-run-simulation-button',
  templateUrl: './run-simulation-button.component.html',
  styleUrls: ['./run-simulation-button.component.scss'],
})
export class RunSimulationButtonComponent {
  alreadyRunningSimulation = false;
  simulationId: number;
  buttonTitle = 'Validation';
  editingAllowed = false;
  validationSuccess = false;
  steps = [
    {
      name: 'No visible errors',
      status: 0,
    },
    {
      name: 'Validated data on server',
      status: 0,
    },
    {
      name: 'Run simulation',
      status: 0,
    },
  ];

  $componentDestroyed = new Subject();
  statusSheet;
  configId: number = null;

  private overlayRef: OverlayRef | null = null;

  @ViewChild('overlayTemplate') overlayTemplate: TemplateRef<any>;

  constructor(
    private reportsService: ReportsService,
    public openStudioEriService: OpenStudioEriService,
    public store: Store,
    public snackBar: MatSnackBar,
    public simulationRightsService: SimulationRightsService,
    public simulationService: SimulationService,
    public uiHelperService: UIHelperService,
    public editorNavigationService: EditorNavigationService,
    public dialog: MatDialog,
    private _bottomSheet: MatBottomSheet,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef
  ) {}

  openBottomSheet() {
    return this._bottomSheet.open(RunSimulationStatusComponent, {
      disableClose: true,
      data: { steps: this.steps },
    });
  }

  ngOnInit() {
    this.store
      .select(selectSimulation())
      .pipe(take(1), takeUntil(this.$componentDestroyed))
      .subscribe(simulation => {
        this.simulationId = simulation.id;
        this.configId = simulation.config;
      });

    this.simulationRightsService.canEdit.subscribe(canEdit => {
      this.editingAllowed = canEdit;
    });
  }

  handleRunSimulation() {
    this.statusSheet = this.openBottomSheet();
    this.checkLocalValidation()
      .pipe(
        switchMap(() => this.runBackendValidation()),
        switchMap(() => this.runOpenStudioSimulation()),
        catchError(error => {
          return of(null);
        })
      )
      .subscribe(result => {
        this.statusSheet.disableClose = false;

        setTimeout(() => {
          this.statusSheet.dismiss();
          this.steps.forEach(step => {
            step.status = 0;
          });
        }, 2000);
      });
  }

  checkLocalValidation() {
    this.steps[0].status = 1;
    return this.store.select(selectErrorAndWarningCount).pipe(
      take(1),
      map(({ errorCount, warningCount }) => {
        if (errorCount < 0) {
          setTimeout(() => {
            this.snackBar.open(
              `Please fix all (${errorCount}) errors before running the simulation`,
              'Dismiss',
              {
                duration: 10000,
                panelClass: 'snackbar-error',
                horizontalPosition: 'right',
                verticalPosition: 'bottom',
              }
            );
            const element = document.getElementById('errors-tab');
            if (element) {
              element.click();
            }
          }, 100);
          this.steps[0].status = 3;
          throw new Error('Local validation failed with errors');
        }
        this.steps[0].status = 2;
        return true;
      }),
      catchError(err => throwError(err))
    );
  }

  runBackendValidation() {
    this.steps[1].status = 1;
    return this.simulationService.validate(this.simulationId).pipe(
      tap(response => {
        const flatResults = getFlatResults(response);

        // Set status based on validation results
        if (Object.keys(flatResults).length > 0) {
          this.steps[1].status = 3; // Mark as failed
          this.dialog.open(BackendValidationResponseDialogComponent, {
            data: { flatResults },
          });
          throw new Error('Backend validation failed with errors');
        }

        this.steps[1].status = 2; // Mark as success if no errors
      }),
      catchError(err => {
        console.error('Error during backend validation:', err);
        return throwError(err); // Propagate the error if needed
      })
    );
  }

  runOpenStudioSimulation() {
    this.steps[2].status = 1;

    return this.openStudioEriService.post(this.simulationId).pipe(
      map(response => {
        this.steps[2].status = 2;
        this.editorNavigationService.setActiveTabLink(Tab.REPORTS);

        this.reportsService.makeRepeatedGetCalls(this.simulationId).subscribe();
      }),
      catchError(err => {
        this.uiHelperService.handleUserRequestError(err);
        return throwError(err); // Propagate the error if needed
      })
    );
  }

  openOverlay(event: MouseEvent): void {
    if (!this.overlayRef) {
      const positionStrategy = this.overlay
        .position()
        .flexibleConnectedTo(event.target as HTMLElement)
        .withPositions([
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
          },
        ]);

      this.overlayRef = this.overlay.create({
        positionStrategy,
        hasBackdrop: true,
        backdropClass: 'cdk-overlay-transparent-backdrop', // Click outside to close
      });

      // Listen for backdrop click to close the overlay
      this.overlayRef.backdropClick().subscribe(() => this.closeOverlay());

      // Attach the form to the overlay
      const portal = new TemplatePortal(
        this.overlayTemplate,
        this.viewContainerRef
      );
      this.overlayRef.attach(portal);
    }
  }

  closeOverlay(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  ngOnDestroy() {
    this.$componentDestroyed.next();
    this.$componentDestroyed.complete();
  }
}
