import { AppState } from '@/state/reducers';
import { createSelector } from '@ngrx/store';
import { simulationStateKey } from '@/state/reducers/floorplan/simulation.reducer';

export const selectSimulationState = (state: AppState) =>
  state[simulationStateKey];

export const getSimulation = createSelector(
  selectSimulationState,
  store => store.detail
);
