import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, Subject, Subscription } from 'rxjs';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import * as _ from 'lodash';
import { filter, takeUntil } from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import {
  getApexRequest,
  getApexRequestDocuments,
  getApexRequestDocumentsCount,
} from '@/state/selectors/home/apex-request.selector';
import {
  ApexRequest,
  ApexRequestDocument,
  ApexRequestDocumentStateLabelMapping,
  ApexRequestType,
} from '@/data/home/models';
import { ApexRequestService } from '@/data/home/services/apex_request.service';
import { ApexRequestDocumentRequestParams } from '@/data/home/services/apex-request-document.service';
import { AnalysisStatusLabelMapping } from '@/data/simulation/models';

@Component({
  selector: 'app-apex-request-document-list',
  templateUrl: './apex-request-document-list.component.html',
  styleUrls: ['./apex-request-document-list.component.scss'],
})
export class ApexRequestDocumentListComponent implements OnInit {
  protected readonly ApexRequestType = ApexRequestType;
  protected readonly AnalysisStatusLabelMapping = AnalysisStatusLabelMapping;
  protected readonly ApexRequestDocumentStateLabelMapping =
    ApexRequestDocumentStateLabelMapping;

  public displayedColumns = [
    'customer_document',
    'state',
    'simulation__status',
    'result',
    'home_status',
    'floorplan',
    'actions',
  ];
  public filterFromGroup: FormGroup;
  public isLoading = false;

  apexRequest: ApexRequest;
  public rows: ApexRequestDocument[] = [];
  public permissions: {
    [key: string]: {
      canEdit: Observable<ObjectPermissionResponse>;
      canDelete: Observable<ObjectPermissionResponse>;
    };
  } = {};
  public rowsCount: number;
  public createPermissionResponse: Observable<ObjectPermissionResponse>;

  public defaultParams: ApexRequestDocumentRequestParams =
    new ApexRequestDocumentRequestParams(1, '', '-id');

  public storedParams: ApexRequestDocumentRequestParams = Object.assign(
    new ApexRequestDocumentRequestParams(),
    this.defaultParams
  );

  private listSubscription$: Subscription;
  private timeoutIntervalId$: any;
  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private apexRequestService: ApexRequestService,
    private fb: FormBuilder,
    private uiHelperService: UIHelperService
  ) {}

  ngOnInit(): void {
    this.storedParams = _.cloneDeep(this.defaultParams);

    this.store
      .select(getApexRequest)
      .pipe(
        filter(val => val !== null),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(apexRequest => {
        this.apexRequest = apexRequest;
        if (this.apexRequest.request_type == ApexRequestType.simulate) {
          this.displayedColumns = [
            'customer_document',
            'state',
            'simulation__status',
            'result',
            'actions',
          ];
        } else if (this.apexRequest.request_type == ApexRequestType.resnet_qa) {
          this.displayedColumns = [
            'customer_document',
            'state',
            'result',
            'actions',
          ];
        }
        this.list();
      });

    this.store
      .select(getApexRequestDocuments)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(documents => {
        this.rows = documents;
      });

    this.store
      .select(getApexRequestDocumentsCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(documentsCount => {
        this.rowsCount = documentsCount;
      });

    this.setupFilterForm();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();

    if (this.timeoutIntervalId$) {
      clearTimeout(this.timeoutIntervalId$);
    }

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }
  }

  setupFilterForm() {
    this.filterFromGroup = this.fb.group({
      search: [null],
    });

    this.filterFromGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        const params = _.merge({}, this.defaultParams, values);
        delete params.ordering;

        this.storedParams = _.merge(this.storedParams, params);
        this.list();
      });
  }

  list() {
    this.isLoading = true;

    if (this.timeoutIntervalId$) {
      clearTimeout(this.timeoutIntervalId$);
    }

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }

    this.listSubscription$ = this.apexRequestService
      .apexRequestDocuments(this.apexRequest.id, this.storedParams)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.rows = data.results;
        this.rowsCount = data.count;
        this.isLoading = false;

        const inProgressResults = this.rows.filter(
          apexRequestDocument =>
            apexRequestDocument.asynchronous_process_document_info === null ||
            (apexRequestDocument.asynchronous_process_document_info
              ?.final_status !== 'SUCCESS' &&
              apexRequestDocument.asynchronous_process_document_info
                ?.final_status !== 'FAILURE')
        );
        if (inProgressResults.length) {
          this.timeoutIntervalId$ = setInterval(() => {
            this.list();
          }, 5000);
        }
      });
  }

  onPaginateChange($event: PageEvent) {
    this.uiHelperService.getUISettings().rowsPerPage = $event.pageSize;
    this.storedParams.page_size = $event.pageSize;
    this.storedParams.page = $event.pageIndex + 1;
    this.list();
  }

  onSortChange($event: Sort) {
    this.storedParams.ordering = `${$event.direction === 'desc' ? '-' : ''}${
      $event.active
    }`;
    this.storedParams.page = 1;
    this.list();
  }
}
