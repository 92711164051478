import { StateModelName } from '../state.registry';
import { ModelErrors } from '../state/shared/base.state';

export interface ValidationResult {
  id: string;
  validationSuccess: boolean;
  data: any;
}

export class BaseValidator {
  static configurable: any;
}

export function connectWithState<K extends StateModelName>(
  modelName: K,
  fields: string[]
) {
  return function (
    target: any,
    propertyKey: string,
    descriptor: PropertyDescriptor
  ) {
    target.configurable = target.configurable || {};

    target.configurable[modelName] = {
      ...target.configurable[modelName],
      [propertyKey]: fields,
    };

    return descriptor;
  };
}

export class ValidationErrors<Model> {
  errors: ModelErrors<Model>;

  constructor(errors: ModelErrors<Model>) {
    this.errors = errors;
  }

  addValidationResults(results: ValidationResult[]) {
    results.forEach(result => {
      if (result.validationSuccess) return;
      this.errors.model[result.id] = result.data;
    });
  }
}
