import { AuthGuard } from '@/core/guards/auth.guard';
import { MainDashboardPageComponent } from '@/modules/misc/pages/main-dashboard-page/main-dashboard-page.component';

export const MiscRouting = [
  {
    path: 'misc',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        component: MainDashboardPageComponent,
      },
    ],
  },
];
