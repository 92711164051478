import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ServerResponse } from '@/core/schemes/server-response';
import { ListRequestParams } from '@/core/schemes/request-params';
import { BaseDataService } from '@/core/services/base-data-service';
import { Subdivision } from '@/data/subdivision/models';

export class SubdivisionRequestParams extends ListRequestParams {
  company_access?: number | number[];
  home_status_related?: boolean;
  name__isnull?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class SubdivisionService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/subdivisions/`;

  list(params?: SubdivisionRequestParams) {
    return this.http.get<ServerResponse<Subdivision>>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }

  retrieve(subdivisionId: number) {
    return this.http.get<Subdivision>(`${this.baseUrl}${subdivisionId}`);
  }

  create(subdivision: Subdivision | FormData) {
    return this.http.post<Subdivision>(`${this.baseUrl}`, subdivision);
  }
}
