<h2 mat-dialog-title>
  <ng-container *ngIf="!isEditForm">
    Create New <b>UDRH</b>
  </ng-container>
  <ng-container *ngIf="isEditForm">
    Edit <b>UDRH</b>
  </ng-container>
</h2>
<mat-dialog-content>
  <form [formGroup]="form" class="mb-2">
    <div class="row">
      <div class="col-12">
        <mat-form-field class="mb-2 w-100">
          <mat-label>Name</mat-label>
          <input
              matInput
              type="text"
              placeholder="Name"
              formControlName="short_name"
              autocomplete="off">
          <mat-error *ngIf="form.get('short_name').hasError('required')">
            Name is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field class="mb-2 w-100">
          <mat-label>Description</mat-label>
          <textarea
              matInput
              type="text"
              placeholder="Description"
              formControlName="description"
          ></textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <ngx-dropzone (change)="onSelect($event)" [accept]="'.udr, .tsv'" multiple="false">
          <ngx-dropzone-label>Drag & Drop REM/Rate™ ".udr" or ".tsv" File Here</ngx-dropzone-label>
          <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
            <ngx-dropzone-label>{{ f.name | slice :0: 20 }} ({{ f.type | slice :0: 20 }})</ngx-dropzone-label>
          </ngx-dropzone-preview>
        </ngx-dropzone>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button color="accent" mat-raised-button (click)="save($event)">Save</button>
  <button mat-button [mat-dialog-close]="null" color="primary" mat-raised-button>Cancel</button>
</mat-dialog-actions>
