<div class="card position-relative" appFocusTarget entityName="skylight" entityId="{{entityId}}">
    <app-loading-overlay *ngIf="loading"></app-loading-overlay>
    <div class="card-body pb-0">
        <app-form formName="skylightFormGroup" [formGroup]="skylightFormGroup" (save)="onSave($event)">
            <div>
                <app-input formControlName="name" [label]="fieldLabels.name"></app-input>
            </div>
            <div>
                <app-model-select modelName="roof" formControlName="roof" label="Roof"></app-model-select>
                <app-select formControlName="orientation" [label]="fieldLabels.orientation" [items]="orientationLabels"></app-select>
            </div>
            <div>
                <app-input formControlName="area" [label]="fieldLabels.area" type="expression"></app-input>
                <app-input formControlName="pitch" [label]="fieldLabels.pitch" type="number" unit="/12"></app-input>
            </div>
            <div>Shading coefficient (Summer)</div>
            <div>
                <app-input formControlName="shading_factor_interior_summer" type="number" [label]="fieldLabels.shading_factor_interior_summer"></app-input>
                <app-input formControlName="shading_factor_adjacent_summer" type="number" [label]="fieldLabels.shading_factor_adjacent_summer"></app-input>
            </div>
            <div>Shading coefficient (Winter)</div>
            <div>
                <app-input formControlName="shading_factor_interior_winter" type="number"
                           [label]="fieldLabels.shading_factor_interior_winter"></app-input>
                <app-input formControlName="shading_factor_adjacent_winter" type="number" [label]="fieldLabels.shading_factor_adjacent_winter"></app-input>
            </div>
            <div>
                <app-foreign-model-field
                    [name]="skylightTypeName"
                    [errorCount]="skylightTypeErrorCount"
                    (add)="addSkylightType()"
                    (edit)="editSkylightType()"
                    (remove)="removeSkylightType()"
                    label="Skylight type">
                </app-foreign-model-field>
            </div>
        </app-form>
    </div>
    <div class="card-footer p-0 text-right">
        <app-model-action-buttons [entityId]="entityId" modelName="skylight" (clone)="clone()" (delete)="removeSkylight()">
        </app-model-action-buttons>
    </div>
</div>
