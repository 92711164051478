import { selectRimJoistErrorViewSet } from '@/modules/simulation/state/rim-joist/selectors';
import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-rim-joist-errors',
  templateUrl: './rim-joist-errors.component.html',
  styleUrls: ['./rim-joist-errors.component.scss'],
})
export class RimJoistErrorsComponent {
  @Input() entityId: number;
  componentDestroyed$: Subject<boolean> = new Subject();
  enable = false;
  name: string;
  rimJoistTypeId: number;

  totalErrorCount = 0;
  totalWarningCount = 0;
  rimJoistTypeErrorCount = 0;
  rimJoistTypeWarningCount = 0;

  constructor(public store: Store) {}

  ngOnInit(): void {
    this.enable = true;

    this.store
      .select(selectRimJoistErrorViewSet(this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.name = data.name;
        this.rimJoistTypeId = data.rimJoistTypeId;

        const nestedModelsErrorCount = data.nestedModelsErrorCount;
        this.rimJoistTypeErrorCount =
          nestedModelsErrorCount.rimJoistType.errorCount;
        this.rimJoistTypeWarningCount =
          nestedModelsErrorCount.rimJoistType.warningCount;

        this.totalErrorCount = 0;
        this.totalErrorCount += this.rimJoistTypeErrorCount;
        this.totalErrorCount += nestedModelsErrorCount.rimJoist.errorCount;

        this.totalWarningCount = 0;
        this.totalWarningCount += this.rimJoistTypeWarningCount;
        this.totalWarningCount += nestedModelsErrorCount.rimJoist.warningCount;
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
