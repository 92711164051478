import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Company } from '@/data/company/models';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, Subject, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { CompanyService } from '@/data/company/services/company-base.service';
import { MatDialog } from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import * as _ from 'lodash';
import { getCompany } from '@/state/selectors/company/company.selector';
import { filter, first, takeUntil } from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { RemRateUser } from '@/data/remrate/models';
import {
  RemRateUserRequestParams,
  RemRateUserService,
} from '@/data/remrate/services/remrateuser.service';
import {
  RemrateUserChangeDialogComponent,
  RemrateUserChangeDialogData,
} from '@/modules/remrate/components/remrate-user-change-dialog/remrate-user-change-dialog.component';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { RemRateUserPermissionService } from '@/modules/remrate/services/remrate-user-permission.service';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { toggleLoading } from '@/state/actions/app.actions';

@Component({
  selector: 'app-company-remrate-users-tab',
  templateUrl: './company-remrate-users-tab.component.html',
  styleUrls: ['./company-remrate-users-tab.component.scss'],
})
export class CompanyRemrateUsersTabComponent implements OnInit, OnDestroy {
  public company: Company;

  public filterFormGroup: FormGroup;
  public isLoading = false;
  public rows: RemRateUser[] = [];
  public rowsCount: number;

  public permissions: {
    [key: string]: {
      canEdit: Observable<ObjectPermissionResponse>;
      canDelete: Observable<ObjectPermissionResponse>;
    };
  } = {};
  public createPermissionResponse: Observable<ObjectPermissionResponse>;

  public displayedColumns = ['username', 'password', 'last_used', 'actions'];

  public defaultParams: RemRateUserRequestParams = new RemRateUserRequestParams(
    1,
    '',
    '-user__first_name',
    25
  );
  public storedParams: RemRateUserRequestParams = Object.assign(
    new RemRateUserRequestParams(),
    this.defaultParams
  );

  private listSubscription$: Subscription;
  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private injector: Injector,
    private remRateUserService: RemRateUserService,
    private companyService: CompanyService,
    private remRateUserPermissionService: RemRateUserPermissionService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private uiHelperService: UIHelperService
  ) {}

  ngOnInit(): void {
    this.storedParams = _.cloneDeep(this.defaultParams);
    this.storedParams.page_size =
      this.uiHelperService.getUISettings().rowsPerPage;

    this.store
      .select(getCompany)
      .pipe(takeUntil(this.componentDestroyed$))
      .pipe(filter(val => val !== null))
      .subscribe(company => {
        this.company = company;
        this.createPermissionResponse =
          this.remRateUserPermissionService.canCreate(this.company);
        this.list();
      });

    this.setupFilterForm();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  setupFilterForm() {
    this.filterFormGroup = this.fb.group({
      search: [null],
    });

    this.filterFormGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        const params = _.merge({}, this.defaultParams, values);
        delete params.ordering;

        this.storedParams = _.merge(this.storedParams, params);
        this.list();
      });
  }
  list() {
    this.isLoading = true;

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }

    this.listSubscription$ = this.companyService
      .remrate_users(this.company.id, this.storedParams)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.rows = data.results;
        this.rowsCount = data.count;
        this.rows.forEach(row => {
          this.permissions[row.id] = {
            canEdit: this.remRateUserPermissionService.canEdit(row),
            canDelete: this.remRateUserPermissionService.canDelete(row),
          };
        });
        this.isLoading = false;
      });
  }
  edit($event: MouseEvent, remRateUser: RemRateUser) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(RemrateUserChangeDialogComponent, {
      width: '45%',
      disableClose: true,
      data: {
        remRateUser,
      } as RemrateUserChangeDialogData,
    });

    dialogRef.afterClosed().subscribe((result?: RemRateUser) => {
      if (!result) {
        return;
      }
      this.list();
      this.uiHelperService.openSnackBar('Record Successfully Added');
    });
  }

  onPaginateChange($event: PageEvent) {
    this.uiHelperService.getUISettings().rowsPerPage = $event.pageSize;
    this.storedParams.page_size = $event.pageSize;
    this.storedParams.page = $event.pageIndex + 1;
    this.list();
  }

  onSortChange($event: Sort) {
    this.storedParams.ordering = `${$event.direction === 'desc' ? '-' : ''}${
      $event.active
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  onAddNew($event: MouseEvent) {
    $event.preventDefault();

    const dialogRef = this.dialog.open(RemrateUserChangeDialogComponent, {
      width: '45%',
      data: {
        remRateUser: {
          company: this.company.id,
        },
      } as RemrateUserChangeDialogData,
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(coiDocument => {
        if (!coiDocument) {
          return;
        }
        this.list();
        this.uiHelperService.openSnackBar('Record Successfully Created');
      });
  }

  delete($event: MouseEvent, remRateUser: RemRateUser) {
    $event.preventDefault();

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '45%',
      data: {
        title: 'Delete Rem/Rate User ?',
        content: `Do you want to delete: ${remRateUser.username}`,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      this.store.dispatch(toggleLoading({ payload: true }));
      this.remRateUserService
        .delete(remRateUser.id)
        .pipe(takeUntil(this.componentDestroyed$), first())
        .subscribe(() => {
          this.list();
          this.store.dispatch(toggleLoading({ payload: false }));
        });
    });
  }
}
