import { createSelector } from '@ngrx/store';
import { SimulationState } from '../reducers';
import { selectSimulationFeature } from '../simulation.selectors';
import { SkylightBackendDict } from '@/data/simulation/models/enclosure/Skylight';

export const selectSkylight = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.skylight.entities[id]
  );
const selectSkylightLoadingStatus = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.skylight.loading[id]
  );

export const selectSkylightTypeId = (id: number) =>
  createSelector(selectSkylight(id), skylight => skylight.type);

const selectSkylightErrorCount = (id: number) =>
  createSelector(selectSimulationFeature, (state: SimulationState) => ({
    errorCount: state.skylight.errors[id].errorCount,
    warningCount: state.skylight.errors[id].warningCount,
  }));

export const selectSkylightViewSet = (id: number) =>
  createSelector(
    selectSkylight(id),
    selectSkylightLoadingStatus(id),
    (skylight, loadingStatus) => ({
      data: skylight,
      loading: loadingStatus,
    })
  );

export const selectSkylightName = (id: number) =>
  createSelector(
    selectSkylight(id),
    (skylight: SkylightBackendDict) => skylight.name
  );

export const selectSkylightNestedErrorCount = (id: number) =>
  createSelector(
    selectSimulationFeature,
    selectSkylightErrorCount(id),
    selectSkylightTypeId(id),
    (state, errorCount, skylightTypeId) => {
      const skylightTypeErrorCount = {
        errorCount: 0,
        warningCount: 0,
      };
      if (skylightTypeId !== null) {
        skylightTypeErrorCount.errorCount =
          state.skylightType.errors[skylightTypeId].errorCount;
        skylightTypeErrorCount.warningCount =
          state.skylightType.errors[skylightTypeId].warningCount;
      }
      return {
        skylight: errorCount,
        skylightType: skylightTypeErrorCount,
      };
    }
  );

export const selectSkylightErrorViewSet = (id: number) =>
  createSelector(
    selectSkylightNestedErrorCount(id),
    selectSkylightTypeId(id),
    selectSkylightName(id),
    (nestedModelsErrorCount, skylightTypeId, name) => ({
      nestedModelsErrorCount,
      skylightTypeId,
      name,
    })
  );
