import { addNewSlab } from '@/modules/simulation/state/simulation/actions';
import { selectSlabIds } from '@/modules/simulation/state/simulation/selectors';
import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-slabs',
  templateUrl: './slabs.component.html',
  styleUrls: ['./slabs.component.scss'],
})
export class SlabsComponent {
  componentDestroyed$ = new Subject();
  slabIds: number[] = [];
  @Input() expanded = false;

  constructor(public store: Store) {
    this.store
      .select(selectSlabIds())
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(ids => {
        this.slabIds = ids;
      });
  }

  addSlab() {
    this.store.dispatch(addNewSlab());
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
