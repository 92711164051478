import { ListRequestParams } from '@/core/schemes/request-params';
import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import { ServerResponse } from '@/core/schemes/server-response';
import { Training } from '@/data/user-management/models';
import { Observable } from 'rxjs';

export class TrainingRequestParams extends ListRequestParams {}

@Injectable({
  providedIn: 'root',
})
export class TrainingService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/trainings/`;

  list(params?: TrainingRequestParams): Observable<ServerResponse<Training>> {
    return this.http.get<ServerResponse<Training>>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }

  create(
    entity: Training | FormData,
    options?: IDataServiceMethodOptions
  ): Observable<Training> {
    const ignoreStore: boolean = options?.ignoreStore;

    const chain: Observable<Training> = this.http.post<Training>(
      `${this.baseUrl}`,
      entity
    );
    return chain;
  }

  update(
    entity: Training | FormData,
    entityId: number,
    options?: IDataServiceMethodOptions
  ): Observable<Training> {
    const ignoreStore = options?.ignoreStore;
    const chain: Observable<Training> = this.http.patch<Training>(
      `${this.baseUrl}${entityId}/`,
      entity
    );
    return chain;
  }

  delete(
    entityId: number,
    options?: IDataServiceMethodOptions
  ): Observable<Training> {
    return this.http.delete<Training>(`${this.baseUrl}${entityId}/`);
  }
}
