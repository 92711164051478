import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { getInfoUser } from '@/state/selectors/info.selector';
import { filter, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SponsoredByCompanyGuard implements CanActivate {
  constructor(public router: Router, public store: Store<AppState>) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.select(getInfoUser).pipe(
      filter(currentUser => !!currentUser),
      map(currentUser => {
        if (currentUser.is_superuser) {
          return true;
        }
        const sponsorSlugs = route.data.sponsorSlugs as Array<string>;
        if (currentUser.company_info) {
          const sponsored = currentUser.company_info.sponsor_slugs.some(r =>
            sponsorSlugs.includes(r)
          );
          const isSponsor = sponsorSlugs.includes(
            currentUser.company_info.slug
          );
          if (sponsored || isSponsor) {
            return true;
          }
        }
        this.router.navigate(['403'], {
          queryParams: { returnUrl: state.url.split('?')[0] },
        });
        return false;
      })
    );
  }
}
