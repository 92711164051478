import { selectWindowErrorViewSet } from '@/modules/simulation/state/window/selectors';
import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-window-errors',
  templateUrl: './window-errors.component.html',
  styleUrls: ['./window-errors.component.scss'],
})
export class WindowErrorsComponent {
  @Input() entityId: number;
  componentDestroyed$: Subject<boolean> = new Subject();
  name: string;
  windowTypeId: number;

  totalErrorCount = 0;
  totalWarningCount = 0;
  windowTypeErrorCount = 0;
  windowTypeWarningCount = 0;

  constructor(public store: Store) {}

  ngOnInit(): void {
    this.store
      .select(selectWindowErrorViewSet(this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.name = data.name;
        this.windowTypeId = data.windowTypeId;

        const nestedModelsErrorCount = data.nestedModelsErrorCount;
        this.windowTypeErrorCount =
          nestedModelsErrorCount.windowType.errorCount;
        this.windowTypeWarningCount =
          nestedModelsErrorCount.windowType.warningCount;

        this.totalErrorCount = 0;
        this.totalErrorCount += this.windowTypeErrorCount;
        this.totalErrorCount += nestedModelsErrorCount.window.errorCount;

        this.totalWarningCount = 0;
        this.totalWarningCount += this.windowTypeWarningCount;
        this.totalWarningCount += nestedModelsErrorCount.window.warningCount;
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
