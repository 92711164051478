<div>
    <div class="app-input-container">
        <app-label [data]="label" [presentAstrix]="false"></app-label>
        <div class="d-flex justify-content-between input-view position-relative">
            <div *ngIf="name" class="field-name">
                <div>
                    {{name}}
                </div>
                <div class="text-muted" *ngIf="subLabel && subValue">
                    [ {{subLabel}}: {{subValue}} ]
                </div>
            </div>
            <div *ngIf="!name"><i>None</i></div>
            <div class="edit-icon-container">
                <app-error-count *ngIf="errorCount > 0" [errorCount]="errorCount"></app-error-count>
                <mat-icon *appRequiresEditPermission matSuffix inline="true">{{ name ? 'mode_edit' : 'add' }}</mat-icon>
            </div>
            <ng-container *ngIf="isInEditMode && options.length">
                <div class="position-absolute w-100 h-100 main-container"
                    *appRequiresEditPermission
                    #menuTrigger="matMenuTrigger"
                    [matMenuTriggerFor]="menu"
                    (keydown.enter)="handleEnter($event)"
                    tabindex="0">
                </div>
            </ng-container>
        </div>
        <app-field-errors [errors]="errors"></app-field-errors>
    </div>
</div>

<mat-menu #menu="matMenu">
    <button *ngIf="!name && options.includes('add')" mat-menu-item (click)="add.emit()" tabindex="1">Add</button>
    <button *ngIf="name && options.includes('edit')" mat-menu-item (click)="edit.emit()" tabindex="0">Edit</button>
    <button *ngIf="name && options.includes('replace')" mat-menu-item (click)="replace.emit()" tabindex="2">Replace</button>
    <button *ngIf="name && options.includes('delete')" mat-menu-item (click)="delete.emit()" tabindex="2">Delete</button>
    <button *ngIf="name && options.includes('remove')" mat-menu-item (click)="remove.emit()" tabindex="2">Remove</button>
    <ng-content></ng-content>
</mat-menu>