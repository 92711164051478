import { createSelector } from '@ngrx/store';
import { SimulationState } from '../reducers';
import { selectSimulationFeature } from '../simulation.selectors';

export const selectWindowType = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.windowType.entities[id]
  );

const selectWindowTypeLoadingStatus = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.windowType.loading[id]
  );

export const selectWindowViewSet = (id: number) =>
  createSelector(
    selectWindowType(id),
    selectWindowTypeLoadingStatus(id),
    (windowType, loadingStatus) => ({
      data: windowType,
      loading: loadingStatus,
    })
  );

const selectWindowTypeName = (id: number) =>
  createSelector(selectWindowType(id), windowType => windowType.name);

const selectWindowTypeErrorCount = (id: number) =>
  createSelector(selectSimulationFeature, (state: SimulationState) => {
    return state.windowType.errors[id].errorCount;
  });

export const selectWindowTypeNameAndError = (id: number) =>
  createSelector(
    selectWindowTypeName(id),
    selectWindowTypeErrorCount(id),
    (name, errorCount) => {
      return {
        name,
        errorCount,
      };
    }
  );
