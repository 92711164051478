import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import {
  AppliedFiltersDialogComponent,
  IAppliedFiltersDialog,
} from '@/shared/components/applied-filters-dialog/applied-filters-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { RequestParams } from '@/core/schemes/request-params';

@Component({
  selector: 'app-table-filters',
  templateUrl: './table-filters.component.html',
  styleUrls: ['./table-filters.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TableFiltersComponent implements OnInit, OnDestroy {
  @Output() formReset = new EventEmitter<FormGroup>();
  @Input() formGroup?: FormGroup;
  @Input() params?: RequestParams;
  @Input() overwritePropertyValues?: { [key: string]: string };
  @Input() overwritePropertyLabels?: { [key: string]: string };
  @Input() excludedProperties?: string[] = ['page', 'page_size'];

  private componentDestroyed$ = new Subject();

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  showAppliedFiltersDialog($event: MouseEvent) {
    $event.preventDefault();

    const data: IAppliedFiltersDialog = {
      filters: this.params.toAppliedFilters({
        overwritePropertyValues: this.overwritePropertyValues,
        overwritePropertyLabels: this.overwritePropertyLabels,
        excludedProperties: this.excludedProperties,
      }),
    };

    this.dialog.open(AppliedFiltersDialogComponent, {
      width: '45%',
      data,
    });
  }

  resetFilters($event: MouseEvent) {
    $event.preventDefault();
    this.formGroup.reset();
    this.formReset?.emit(this.formGroup);
  }
}
