import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { first, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getInfoUser } from '@/state/selectors/info.selector';
import { AppState } from '@/state/reducers';
import {
  PaymentRequest,
  PaymentRequestProduct,
  PaymentRequestState,
} from '@/data/customer-hirl/models';
import { PaymentRequestProductService } from '@/data/customer-hirl/services/payment-request-product.service';
import { PaymentRequestService } from '@/data/customer-hirl/services/payment-request.service';
import { User } from '@/data/core/models/user';
@Injectable({
  providedIn: 'root',
})
export class PaymentRequestProductPermissionService {
  constructor(
    private store: Store<AppState>,
    private paymentRequestService: PaymentRequestService,
    private paymentRequestProductGroupService: PaymentRequestProductService
  ) {}

  canCreate({
    paymentRequest,
  }: {
    paymentRequest: PaymentRequest | number;
  }): Observable<ObjectPermissionResponse> {
    let paymentRequest$: Observable<PaymentRequest>;
    if (typeof paymentRequest === 'number') {
      paymentRequest$ = this.paymentRequestService.retrieve(paymentRequest, {
        ignoreStore: true,
      });
    } else {
      paymentRequest$ = of(paymentRequest);
    }

    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      paymentRequest: paymentRequest$,
    }).pipe(
      map(
        ({
          currentUser,
          paymentRequest,
        }: {
          currentUser: User;
          paymentRequest: PaymentRequest;
        }) => {
          if (paymentRequest.state !== PaymentRequestState.new) {
            return new ObjectPermissionResponse(false);
          }
          return new ObjectPermissionResponse(true);
        }
      )
    );
  }

  canEdit(
    paymentRequestProductGroup: PaymentRequestProduct | number
  ): Observable<ObjectPermissionResponse> {
    let paymentRequestProductGroup$: Observable<PaymentRequestProduct>;
    if (typeof paymentRequestProductGroup === 'number') {
      paymentRequestProductGroup$ =
        this.paymentRequestProductGroupService.retrieve(
          paymentRequestProductGroup,
          {
            ignoreStore: true,
          }
        );
    } else {
      paymentRequestProductGroup$ = of(paymentRequestProductGroup);
    }
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: paymentRequestProductGroup$,
    }).pipe(
      map(
        ({
          currentUser,
          entity,
        }: {
          currentUser: User;
          entity: PaymentRequestProduct;
        }) => {
          return new ObjectPermissionResponse(false);
        }
      )
    );
  }

  canDelete(
    paymentRequestProductGroup: PaymentRequestProduct | number
  ): Observable<ObjectPermissionResponse> {
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
    }).pipe(
      map(({ currentUser }) => {
        return new ObjectPermissionResponse(true);
      })
    );
  }
}
