import { Injectable } from '@angular/core';
import { BaseDataService } from '@/core/services/base-data-service';
import { environment } from '../../../../environments/environment';
import { ServerResponse } from '@/core/schemes/server-response';
import { Observation } from '@/data/qa/models';
import { ListRequestParams } from '@/core/schemes/request-params';
import { Observable } from 'rxjs';

export class ObservationRequestParams extends ListRequestParams {}

@Injectable({
  providedIn: 'root',
})
export class ObservationService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/observations/`;

  list(
    params?: ObservationRequestParams
  ): Observable<ServerResponse<Observation>> {
    return this.http.get<ServerResponse<Observation>>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }

  retrieve(observationId: number): Observable<Observation> {
    return this.http.get<Observation>(`${this.baseUrl}${observationId}`);
  }
}
