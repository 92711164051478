<div *ngIf="!simulation; else simDetail" class="container" style="padding-top:15px;">
    <div *ngIf="initialized">
        <p>Simulation data does not exists</p>
    </div>
</div>

<ng-template #simDetail>
    <div *ngIf="initialized; else loading;">

        <div class="row">
            <div class="col-8">
                <b>Viewing {{ simulationSourceTypeLabelMap[simulation.source_type] }}</b>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-7">
                <div class="card">
                    <div class="card-header">
                        General Information
                    </div>
                    <div class="card-content">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item" *ngIf="currentUser.is_superuser">
                                <b>Simulation ID:</b>
                                {{ simulation.id }}
                            </li>
                            <li class="list-group-item">
                                <b>Address:</b>
                                <app-address-display [geocode]="simulationGeocode"></app-address-display>
                            </li>
                            <li class="list-group-item">
                                <b>Subdivision:</b>
                                <a [routerLink]="['/', 'subdivision', simulation.location_info?.subdivision]"
                                    target="_blank" *ngIf="simulation.location_info?.subdivision">
                                    {{ simulation.location_info?.subdivision_info?.name }}
                                </a>
                            </li>
                            <li class="list-group-item">
                                <b>Residence Type:</b>
                                {{ simulationResidenceTypeLabelMap[simulation?.residence_type] }}
                            </li>
                            <li class="list-group-item">
                                <b>Conditioned Area:</b>
                                {{ simulation?.conditioned_area }}
                            </li>
                            <li class="list-group-item">
                                <b>Volume:</b>
                                {{ simulation?.conditioned_volume }}
                            </li>
                            <li class="list-group-item">
                                <b>Bedrooms:</b>
                                {{ simulation?.bedroom_count }}
                            </li>
                            <li class="list-group-item">
                                <b>Floors on or above Grade:</b>
                                {{ simulation?.floors_on_or_above_grade }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-5">
                <div class="card">
                    <div class="card-header">
                        Company Information
                    </div>
                    <div class="card-content">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">
                                <b>Builder Organization:</b>
                                <a [routerLink]="['/', simulation.project_info?.builder_info?.company_type, simulation.project_info?.builder_info?.id]"
                                    target="_blank" *ngIf="simulation.project_info?.builder_info?.name">
                                    {{ simulation.project_info?.builder_info?.name }}
                                </a>
                                <span *ngIf="!simulation.project_info?.builder_info?.name">
                                    {{ simulation.project_info?.builder_name }}
                                </span>
                            </li>
                            <li class="list-group-item">
                                <b>Rater Organization:</b>
                                <a [routerLink]="['/', simulation.project_info?.rater_organization?.company_type, simulation.project_info?.rater_organization?.id]"
                                    target="_blank" *ngIf="simulation.project_info?.rater_organization?.name">
                                    {{ simulation.project_info?.rater_organization?.name }}
                                </a>
                                <span *ngIf="!simulation.project_info?.rater_organization?.name">
                                    {{ simulation.project_info?.rater_organization_name }}
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="card mt-2">
                    <div class="card-header">
                        Misc
                    </div>
                    <div class="card-content">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">
                                <b>Rater Of Record:</b>
                                <a [routerLink]="['/', 'user', simulation.project_info?.rater_of_record_info?.id]"
                                    target="_blank"
                                    *ngIf="simulation.project_info?.rater_of_record_info?.id">
                                    {{ simulation.project_info?.rater_of_record_info?.first_name }} {{
                                    simulation.project_info?.rater_of_record_info?.last_name }}
                                </a>
                                <span *ngIf="!simulation.project_info?.rater_of_record_info?.id">
                                    {{ simulation.project_info?.source_extra?.missing_rater_of_record?.rater_name }}
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <hr>

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Mechanical Equipment
                </mat-panel-title>
                <div matBadge="{{ equipmentCount }}" matBadgeSize="medium" class="countBadge"></div>
            </mat-expansion-panel-header>
            <app-simulation-mechanicals></app-simulation-mechanicals>
        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Enclosures
                </mat-panel-title>
                <div matBadge="{{ enclosureCount }}" matBadgeSize="medium" class="countBadge">
                </div>
            </mat-expansion-panel-header>
            <app-enclosure-roofs></app-enclosure-roofs>
            <app-enclosure-floors></app-enclosure-floors>
            <app-enclosure-foundation-walls></app-enclosure-foundation-walls>
            <app-enclosure-abovegrade-walls></app-enclosure-abovegrade-walls>
            <app-enclosure-windows></app-enclosure-windows>
            <app-enclosure-doors></app-enclosure-doors>
            <app-enclosure-slabs></app-enclosure-slabs>
            <app-enclosure-skylights></app-enclosure-skylights>
            <app-enclosure-rimjoists></app-enclosure-rimjoists>
        </mat-expansion-panel>

        <mat-expansion-panel [hidden]="simulation.photovoltaics.length === 0">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Photovoltaics
                </mat-panel-title>
                <div matBadge="{{ photovoltaicsCount }}" matBadgeSize="medium" class="countBadge"></div>
            </mat-expansion-panel-header>
            <app-simulation-photovoltaics #pvComponent></app-simulation-photovoltaics>
        </mat-expansion-panel>

        <mat-expansion-panel [hidden]="!simulation.infiltration">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Infiltration
                </mat-panel-title>
            </mat-expansion-panel-header>
            <app-simulation-infiltration #infiltrationComponent></app-simulation-infiltration>
        </mat-expansion-panel>

        <mat-expansion-panel [hidden]="!simulation.lights">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Lights
                </mat-panel-title>
            </mat-expansion-panel-header>
            <app-simulation-lights #lightsComponent></app-simulation-lights>
        </mat-expansion-panel>

        <mat-expansion-panel [hidden]="!simulation.appliances">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Appliances
                </mat-panel-title>
            </mat-expansion-panel-header>
            <app-simulation-appliances #appliancesComponent></app-simulation-appliances>
        </mat-expansion-panel>

    </div>
</ng-template>

<ng-template #loading>
    <div class="row">
        <div class="col-12">
            <div class="d-flex justify-content-center" style="height: 320px;">
                <mat-spinner [diameter]="50"></mat-spinner>
            </div>
        </div>
    </div>
</ng-template>