import { AppState } from '@/state/reducers';
import { userStateKey } from '@/state/reducers/user/user.reducer';
import { createSelector } from '@ngrx/store';

export const selectUserState = (state: AppState) => state[userStateKey];

export const getUser = createSelector(selectUserState, store => store.detail);

// User Company Access
export const getUserCompanyAccesses = createSelector(
  selectUserState,
  store => store.userCompanyAccesses
);

export const getUserCompanyAccessesCount = createSelector(
  selectUserState,
  store => store.userCompanyAccessesCount
);

// User Rater Roles
export const getUserRaterRoles = createSelector(
  selectUserState,
  store => store.raterRoles
);

export const getUserRaterRolesCount = createSelector(
  selectUserState,
  store => store.raterRolesCount
);

// User Accreditations
export const getUserAccreditations = createSelector(
  selectUserState,
  store => store.accreditations
);

export const getUserAccreditationsCount = createSelector(
  selectUserState,
  store => store.accreditationsCount
);

// User Inspection Grades
export const getUserCustomerHIRLInspectionGrades = createSelector(
  selectUserState,
  store => store.customerHIRLInspectionGrades
);

export const getUserCustomerHIRLInspectionGradesCount = createSelector(
  selectUserState,
  store => store.customerHIRLInspectionGradesCount
);

// User Inspection Grades
export const getUserTasks = createSelector(
  selectUserState,
  store => store.tasks
);

export const getUserTasksCount = createSelector(
  selectUserState,
  store => store.tasksCount
);

// Training
export const getUserTrainings = createSelector(
  selectUserState,
  store => store.trainings
);

export const getUserTrainingsCount = createSelector(
  selectUserState,
  store => store.trainingsCount
);

// User EEP Program Home Statuses
export const getUserEEPProgramHomeStatusCertificationMetrics = createSelector(
  selectUserState,
  store => store.eepProgramHomeStatusCertificationMetrics
);

export const getUserEEPProgramHomeStatusesCertificationMetricsCount =
  createSelector(
    selectUserState,
    store => store.eepProgramHomeStatusCertificationMetricsCount
  );
