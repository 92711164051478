<div class="mt-3" *ngIf="entityForm.controls.stud_type.value">
    <app-form formName="studForm" [formGroup]="entityForm" [dedicatedSaveButton]="false">
        <div>
            <app-foreign-model-field
                *appRequiresEditPermission
                class="position-relative"
                label="Stud Type"
                [matMenuTriggerFor]="mainMenu"
                [options]="[]"
                [name]="studTypesLabels[entityForm.controls.stud_type.value]">
            </app-foreign-model-field>
            <app-input label="R value" formControlName="stud_r_value" type="expression"></app-input>
        </div>
        <div>
            <app-input label="Depth" formControlName="stud_depth" type="number"></app-input>
            <app-input label="Width" formControlName="stud_width" type="number"></app-input>
            <app-input label="Spacing" formControlName="stud_spacing" type="number"></app-input>
        </div>
        <div>
            <app-input label="Framing fraction" formControlName="framing_fraction" type="number"></app-input>
            <app-checkbox label="Override Framing fraction" formControlName="user_defined_framing_fraction" type="number"></app-checkbox>
        </div>
    </app-form>
</div>


<mat-menu #mainMenu="matMenu">
    <ng-container *ngFor="let studTypeChoicesOption of studTypeChoicesOptions | keyvalue">
        <ng-container *ngIf="studTypeChoicesOption.value && studTypeChoicesOption.value.length > 0; else singleItem">
            <button mat-menu-item [matMenuTriggerFor]="subMenu">{{ studTypesLabels[studTypeChoicesOption.key] }}</button>
            <mat-menu #subMenu="matMenu">
                <button mat-menu-item *ngFor="let subItem of studTypeChoicesOption.value"
                    (click)="updateStudType(studTypeChoicesOption.key, subItem)">{{ materialItems[subItem] }}</button>
            </mat-menu>
        </ng-container>
        <ng-template #singleItem>
            <button mat-menu-item (click)="updateStudType(studTypeChoicesOption.key, null)">{{
                studTypesLabels[studTypeChoicesOption.key] }}</button>
        </ng-template>
    </ng-container>
</mat-menu>