import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { User } from '@/data/core/models/user';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { MatDialog } from '@angular/material/dialog';
import {
  getCompany,
  getCompanyCOIDocuments,
  getCompanyCOIDocumentsCount,
} from '@/state/selectors/company/company.selector';
import { filter, first, takeUntil } from 'rxjs/operators';
import {
  COIDocumentParams,
  COIDocumentService,
} from '@/data/customer-hirl/services/coi-documents.service';
import {
  COIDocument,
  COIDocumentInsuranceTypeMapping,
} from '@/data/customer-hirl/models';
import {
  CoiDocumentChangeDialogComponent,
  COIDocumentChangeDialogData,
} from '@/modules/customer-hirl/components/coi-document-change-dialog/coi-document-change-dialog.component';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { updateCompanyCOIDocument } from '@/state/actions/company/company.actions';
import { Company } from '@/data/company/models';
import { CompanyService } from '@/data/company/services/company-base.service';
import * as _ from 'lodash';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-company-coi-list',
  templateUrl: './company-coi-document-list.component.html',
  styleUrls: ['./company-coi-document-list.component.scss'],
})
export class CompanyCoiDocumentListComponent implements OnInit, OnDestroy {
  company: Company;

  filterFromGroup: FormGroup;
  rows: COIDocument[];
  rowsCount: number;

  protected readonly COIDocumentInsuranceTypeMapping =
    COIDocumentInsuranceTypeMapping;

  public isLoading = true;
  public currentUser: User;

  public defaultParams: COIDocumentParams = new COIDocumentParams(1, '', '-id');
  public storedParams: COIDocumentParams;

  private listSubscription$: Subscription;
  private componentDestroyed$ = new Subject();
  public displayedColumns = [
    'description',
    'insurance_type',
    'policy_number',
    'start_date',
    'expiration_date',
    'actions',
  ];

  constructor(
    private store: Store<AppState>,
    private dialog: MatDialog,
    private coiDocumentService: COIDocumentService,
    private fb: FormBuilder,
    private companyService: CompanyService,
    private uiHelperService: UIHelperService
  ) {}

  ngOnInit(): void {
    this.storedParams = _.cloneDeep(this.defaultParams);
    this.storedParams.page_size =
      this.uiHelperService.getUISettings().rowsPerPage;

    this.store
      .select(getCompany)
      .pipe(
        filter(val => val !== null),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(company => {
        this.company = company;
        this.list();
      });

    this.store
      .select(getCompanyCOIDocuments)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(documents => {
        this.rows = documents;
      });

    this.store
      .select(getCompanyCOIDocumentsCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(documentsCount => {
        this.rowsCount = documentsCount;
      });

    this.setupFilterForm();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  setupFilterForm() {
    this.filterFromGroup = this.fb.group({
      search: [null],
      is_active: [null],
    });

    this.filterFromGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        const params = _.merge({}, this.defaultParams, values);
        delete params.ordering;

        this.storedParams = _.merge(this.storedParams, params);
        this.list();
      });
  }

  list() {
    this.isLoading = true;

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }

    this.listSubscription$ = this.companyService
      .coi_documents(this.company.id, this.storedParams)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.rows = data.results;
        this.rowsCount = data.count;
        this.isLoading = false;
      });
  }

  onAddNew($event: MouseEvent) {
    $event.preventDefault();

    const dialogRef = this.dialog.open(CoiDocumentChangeDialogComponent, {
      width: '45%',
      data: {
        coiDocument: {
          company: this.company.id,
        },
      } as COIDocumentChangeDialogData,
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(coiDocument => {
        if (!coiDocument) {
          return;
        }
        this.list();
        this.uiHelperService.openSnackBar('Record Successfully Created');
      });
  }

  onEditCOIDocument($event: MouseEvent, coiDocument: COIDocument) {
    $event.preventDefault();

    const dialogRef = this.dialog.open(CoiDocumentChangeDialogComponent, {
      width: '45%',
      data: {
        coiDocument,
      } as COIDocumentChangeDialogData,
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(changedCOIDocument => {
        if (!changedCOIDocument) {
          return;
        }
        this.store.dispatch(
          updateCompanyCOIDocument({ payload: changedCOIDocument })
        );
        this.uiHelperService.openSnackBar('Record Successfully Changed');
      });
  }

  onPaginateChange($event: PageEvent) {
    this.uiHelperService.getUISettings().rowsPerPage = $event.pageSize;
    this.storedParams.page_size = $event.pageSize;
    this.storedParams.page = $event.pageIndex + 1;
    this.list();
  }

  onSortChange($event: Sort) {
    this.storedParams.ordering = `${$event.direction === 'desc' ? '-' : ''}${
      $event.active
    }`;
    this.storedParams.page = 1;
    this.list();
  }
}
