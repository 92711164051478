<mat-expansion-panel [expanded]="expanded">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <strong>Water system</strong>
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
        <div class="card position-relative">
            <app-loading-overlay *ngIf="loading"></app-loading-overlay>
            <div class="card-body pb-0">
                <app-form formName="waterSystemForm" [formGroup]="form" (save)="onSave($event)">
                    <div>
                        <app-checkbox formControlName="low_flow_fixtures_used" [label]="fieldLabels.low_flow_fixtures_used"></app-checkbox>
                        <app-checkbox formControlName="pipes_fully_insulated" [label]="fieldLabels.pipes_fully_insulated"></app-checkbox>
                    </div>
                    <div>
                        <app-input formControlName="max_hot_water_pipe_length" type="number"
                            [label]="fieldLabels.max_hot_water_pipe_length" [unit]="entity?.pipe_length_unit"></app-input>
                        <app-input formControlName="qty_shower_heads" type="number" [label]="fieldLabels.qty_shower_heads"></app-input>
                    </div>
                    <div>
                        <app-checkbox formControlName="has_drain_water_heat_recovery"
                            [label]="fieldLabels.has_drain_water_heat_recovery"></app-checkbox>
                    </div>
                    <div [hidden]="!form.controls.has_drain_water_heat_recovery.value">
                        <app-input formControlName="efficiency" type="number" [label]="fieldLabels.efficiency"></app-input>
                        <app-select formControlName="recovery_type" [items]="recoveryTypeLabels" [label]="fieldLabels.recovery_type"></app-select>
                        <app-input formControlName="fixture_factor" type="number" [label]="fieldLabels.fixture_factor"></app-input>
                    </div>
                    <div>
                        <app-checkbox formControlName="has_recirculation_system" [label]="fieldLabels.has_recirculation_system"></app-checkbox>
                    </div>
                    <div [hidden]="!form.controls.has_recirculation_system.value">
                        <app-select formControlName="recirculation_type" [items]="recirculationTypeLabels"
                            [label]="fieldLabels.recirculation_type"></app-select>
                        <app-input formControlName="pipe_loop_length" type="number" [label]="fieldLabels.pipe_loop_length" [unit]="entity?.pipe_length_unit"></app-input>
                        <app-input formControlName="hot_water_branch_length" type="number" [label]="fieldLabels.hot_water_branch_length" [unit]="entity?.pipe_length_unit"></app-input>
                    </div>
                    <div [hidden]="!form.controls.has_recirculation_system.value">
                        <app-input formControlName="pump_energy" type="number" [label]="fieldLabels.pump_energy" [unit]="energyLabels[entity.pump_energy_unit]"></app-input>
                        <app-input formControlName="qty_recirculation_shower_heads" type="number"
                            [label]="fieldLabels.qty_recirculation_shower_heads"></app-input>
                    </div>
                </app-form>
            </div>
        </div>
    </ng-template>
</mat-expansion-panel>

