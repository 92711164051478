<div class="d-flex">
    <div class="w-50 d-flex flex-column">
      <app-simulation id="{{subTabs.PROJECT}}" class="model-instance"></app-simulation>
    </div>
    <div class="w-50 d-flex flex-column">
      <app-location [entityId]="locationId" [simulationId]="simulationId" class="model-instance"></app-location>
      <div id="{{subTabs.FUELS}}" class="model-instance">
        <app-utilities></app-utilities>
      </div>
    </div>
</div>