<form [formGroup]="filterForm">
  <div class="row">
    <div class="col-4">
      <mat-form-field class="w-100">
        <mat-label>NGBS Standard Version and Scoring Path</mat-label>
        <app-generic-mat-select formControlName="eep_program__slug" modelClassName="eep_program" multiple="true"
                                parentKey="slug" [panelWidth]="640"
                                [filterParams]="{owner__slug: this.customerHIRLSettings.companySlug}"></app-generic-mat-select>
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field class="w-100">
        <mat-label>Program Created Date</mat-label>
        <mat-date-range-input [rangePicker]="created_date_picker">
          <input matStartDate formControlName="created_date__gte" placeholder="Start Date">
          <input matEndDate formControlName="created_date__lte" placeholder="End Date">
        </mat-date-range-input>
        <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix
                               (click)="filterForm.get('created_date__gte').setValue(null); filterForm.get('created_date__lte').setValue(null);">
          <mat-icon matDatepickerToggleIcon>clear</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker-toggle matSuffix [for]="created_date_picker"></mat-datepicker-toggle>
        <mat-date-range-picker #created_date_picker></mat-date-range-picker>

        <mat-error *ngIf="filterForm.controls.created_date__gte.hasError('matStartDateInvalid')">Invalid start date
        </mat-error>
        <mat-error *ngIf="filterForm.controls.created_date__lte.hasError('matEndDateInvalid')">Invalid end date
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field class="w-100">
        <mat-label>Program Certification Date</mat-label>
        <mat-date-range-input [rangePicker]="certification_date_picker">
          <input matStartDate formControlName="certification_date__gte" placeholder="Start Date">
          <input matEndDate formControlName="certification_date__lte" placeholder="End Date">
        </mat-date-range-input>
        <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix
                               (click)="filterForm.get('certification_date__gte').setValue(null); filterForm.get('certification_date__lte').setValue(null);">
          <mat-icon matDatepickerToggleIcon>clear</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker-toggle matSuffix [for]="certification_date_picker"></mat-datepicker-toggle>
        <mat-date-range-picker #certification_date_picker></mat-date-range-picker>

        <mat-error *ngIf="filterForm.controls.certification_date__gte.hasError('matStartDateInvalid')">Invalid start
          date
        </mat-error>
        <mat-error *ngIf="filterForm.controls.certification_date__lte.hasError('matEndDateInvalid')">Invalid end date
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-4">
      <mat-form-field class="w-100">
        <mat-label>US State</mat-label>
        <app-generic-mat-select modelClassName="us_state" formControlName="home__city__county__state" [pageSize]="300"
                                multiple="true" parentKey="abbr" [filterParams]="{exclude_non_us_states: true}"></app-generic-mat-select>
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field class="w-100">
        <mat-label>Country</mat-label>
        <app-generic-model-chips placeholder="Add country..." modelClassName="country" formControlName="home__city__country_info" [filterParams]="{home_status_related: true}" [pageSize]="300"></app-generic-model-chips>
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field class="w-100">
        <mat-label>Billing State</mat-label>
        <mat-select formControlName="customer_hirl_project__billing_state" [multiple]="true">
          <mat-option *ngFor="let item of hirlProjectBillingStateLabelMapping | defaultOrderKeyValue"
                      [value]="item.key">
            {{ item.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="row mb-2">
    <div class="col-12">
      <div class="row mb-2">
        <div class="col-2 align-self-center">
          <label class="font-weight-bold">Quick Filters:</label>
        </div>
        <div class="col-10 text-right">
          <button mat-raised-button color="accent"
                  (click)="resetQuickFilters()">
            Reset Filters
          </button>
        </div>
      </div>
      <div class="mb-2 row">
        <div class="col-6">
          <div class="row mb-2">
            <div class="col-12">
              <form [formGroup]="projectTypeFormGroup">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-6">
                        <label class="font-weight-bold">Certification Path</label>
                      </div>
                      <div class="col-6">
                        <p>(Select one or multiple)</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <mat-checkbox formControlName="single_family_remodel">
                          Single Family Remodel
                        </mat-checkbox>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <mat-checkbox formControlName="single_family_new_construction">
                          Single Family New Construction
                        </mat-checkbox>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <mat-checkbox
                            formControlName="single_family_certified">
                          Single Family Certified
                        </mat-checkbox>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <mat-checkbox
                            formControlName="multi_family_remodel">
                          Multi-Family Remodel
                        </mat-checkbox>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <mat-checkbox
                            formControlName="multi_family_new_construction">
                          Multi-Family New Construction
                        </mat-checkbox>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <mat-checkbox
                            formControlName="land_development">
                          Land Development
                        </mat-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row mb-2">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-6">
                      <label class="font-weight-bold">Project Status</label>
                    </div>
                    <div class="col-6">
                      <p>(Select one or multiple)</p>
                    </div>
                  </div>
                  <form [formGroup]="projectStatusFormGroup">
                    <div class="row" *ngFor="let item of eepProgramHomeStatusStateLabelMapping | defaultOrderKeyValue">
                      <div class="col-12">
                        <mat-checkbox
                            [formControlName]="item.key">
                          {{ item.value }}
                        </mat-checkbox>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <mat-checkbox
                            formControlName="in_progress_including_abandoned">
                          In Progress(including Abandoned)
                        </mat-checkbox>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <mat-checkbox
                            formControlName="in_progress_excluding_abandoned">
                          In Progress(excluding Abandoned)
                        </mat-checkbox>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col-6">
      <div class="row mb-2">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <label class="font-weight-bold">Certification Level</label>
                </div>
                <div class="col-6">
                  <p>(Select one or multiple)</p>
                </div>
              </div>
              <form [formGroup]="certificationLevelFormGroup">
                <div class="row" *ngFor="let item of QAHIRLCertificationLevelAwardedLabelMapping | defaultOrderKeyValue">
                  <div class="col-12">
                    <mat-checkbox
                        [formControlName]="item.key">
                      {{ item.value }}
                    </mat-checkbox>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <form [formGroup]="standardVersionFormGroup">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <label class="font-weight-bold">NGBS Standard Version</label>
              </div>
              <div class="col-6">
                <p>(Select one or multiple)</p>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox
                    formControlName="version_2020">
                  2020
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox
                    formControlName="version_2015">
                  2015
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox
                    formControlName="version_2012">
                  2012
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox formControlName="version_2008">
                  2008
                </mat-checkbox>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col-12">
      <div class="row mb-2">
        <div class="col-2 align-self-center">
          <label class="font-weight-bold">Additional Filters:</label>
        </div>
        <div class="col-10 text-right">
          <button mat-raised-button color="accent"
                  (click)="resetAdditionalFilters()">
            Reset Filters
          </button>
        </div>
      </div>
      <div class="mb-2 row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">

              <div class="row">
                <div class="col-6">
                  <label class="font-weight-bold">Project Type</label>
                </div>
                <div class="col-6">
                  <p>(Select one or multiple)</p>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <mat-checkbox
                      name="green_energy_badges"
                      formControlName="green_energy_badges">
                    Green+ Badge(s) Earned
                  </mat-checkbox>
                </div>
                <div class="col-6">
                  <mat-checkbox
                      name="building_will_include_non_residential_space"
                      formControlName="building_will_include_non_residential_space">
                    Building(s) will include non-residential space (retail/commercial)?
                  </mat-checkbox>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <mat-checkbox
                      name="hud_disaster_case_number"
                      formControlName="hud_disaster_case_number">
                    HUD Project
                  </mat-checkbox>
                </div>
                <div class="col-6">
                  <mat-checkbox
                      name="seeking_hud_mortgage_insurance_premium_reduction"
                      formControlName="seeking_hud_mortgage_insurance_premium_reduction">
                    Seeking HUD Mortgage Insurance Premium Reduction?
                  </mat-checkbox>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <mat-checkbox
                      name="sampling"
                      formControlName="sampling">
                    Utilizes Sampling
                  </mat-checkbox>
                </div>
                <div class="col-6">
                  <mat-checkbox
                      name="seeking_hud_green_and_resilient_retrofit_program"
                      formControlName="seeking_hud_green_and_resilient_retrofit_program">
                    Seeking HUD Green and Resilient Retrofit Program?
                  </mat-checkbox>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <mat-checkbox
                      name="is_appeals_project"
                      formControlName="is_appeals_project">
                    Appeals Project
                  </mat-checkbox>
                </div>
                <div class="col-6">
                  <mat-checkbox
                      name="seeking_fannie_mae_green_financing"
                      formControlName="seeking_fannie_mae_green_financing">
                    Seeking Fannie Mae Green financing?
                  </mat-checkbox>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <mat-checkbox
                      name="is_require_wri_certification"
                      formControlName="is_require_wri_certification">
                    WRI Project
                  </mat-checkbox>
                </div>
                <div class="col-6">
                  <mat-checkbox
                      name="seeking_freddie_mac_green_financing"
                      formControlName="seeking_freddie_mac_green_financing">
                    Seeking Freddie Mac Green financing?
                  </mat-checkbox>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <mat-checkbox
                      name="is_build_to_rent"
                      formControlName="is_build_to_rent">
                    BTR Project
                  </mat-checkbox>
                </div>
                <div class="col-6">
                  <mat-checkbox
                      name="intended_to_be_affordable_housing"
                      formControlName="intended_to_be_affordable_housing">
                    Intended to be affordable housing?
                  </mat-checkbox>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <mat-checkbox
                      name="is_included_core_commercial_space"
                      formControlName="is_include_core_commercial_space">
                    Includes Core & Shell Commercial Space
                  </mat-checkbox>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <mat-checkbox
                      name="is_included_fully_fitted_commercial_space"
                      formControlName="is_included_fully_fitted_commercial_space">
                    Includes Fully Fitted Commercial Space
                  </mat-checkbox>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
<div class="row">
  <div class="col-12">
    <ngx-datatable
        class="material"
        [rows]="rows"
        columnMode="force"
        headerHeight="40"
        rowHeight="auto"
        footerHeight="50"
        [loadingIndicator]="isLoading">
      <ngx-datatable-column name="Key" [sortable]="false">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          {{ row.key }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Value" [sortable]="false">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          {{ row.value }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-footer>
        <ng-template ngx-datatable-footer-template>
        </ng-template>
      </ngx-datatable-footer>
    </ngx-datatable>
  </div>
</div>

<div class="mb-2">
  <div class="row">
    <div class="col-6">
      <h5 class="text-center">Top 12 SF States by Project/Building Count</h5>
    </div>
    <div class="col-6">
      <h5 class="text-center">Top 12 MF States by Unit Count</h5>
    </div>
  </div>
  <div class="row" *ngIf="!topStatsIsLoading else topStatsLoading">
    <div class="col-6">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div style="min-height: 320px;">
                <ngx-charts-bar-horizontal
                    [results]="topSingleFamilyStates"
                    [yAxis]="true"
                    [showDataLabel]="true" [legendPosition]="'below'">
                </ngx-charts-bar-horizontal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div style="min-height: 320px;">
                <ngx-charts-bar-horizontal
                    [results]="topMultiFamilyStates"
                    [yAxis]="true"
                    [showDataLabel]="true" [legendPosition]="'below'">
                </ngx-charts-bar-horizontal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #topStatsLoading>
    <div class="row">
      <div class="col-12 text-center">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center">
              <div class="d-flex flex-column justify-content-center" style="height: 320px;">
                <mat-spinner [diameter]="50"></mat-spinner>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>


<div class="mb-2">
  <div class="row">
    <div class="col-6">
      <h5 class="text-center">Top 12 SF Clients by Project/Building Count</h5>
    </div>
    <div class="col-6">
      <h5 class="text-center">Top 12 MF Clients by Unit Count</h5>
    </div>
  </div>
  <div class="row" *ngIf="!topClientsIsLoading else topClientsLoading">
    <div class="col-6">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div style="min-height: 320px;">
                <ngx-charts-bar-horizontal
                    [results]="topSingleFamilyClients"
                    [yAxis]="true"
                    [showDataLabel]="true"
                    [legendPosition]="'below'">
                </ngx-charts-bar-horizontal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div style="min-height: 320px;">
                <ngx-charts-bar-horizontal
                    [results]="topMultiFamilyClients"
                    [yAxis]="true"
                    [showDataLabel]="true" [legendPosition]="'below'">
                </ngx-charts-bar-horizontal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #topClientsLoading>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center">
              <div class="d-flex flex-column justify-content-center" style="height: 320px;">
                <mat-spinner [diameter]="50"></mat-spinner>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<div class="mb-2">
  <div class="row">
    <div class="col-6">
      <h5 class="text-center">Top 12 Verifiers (individuals) by Project/Building Count</h5>
    </div>
    <div class="col-6">
      <h5 class="text-center">Top 12 Verifiers (individuals) by Unit Count</h5>
    </div>
  </div>
  <div class="row" *ngIf="!topVerifiersIsLoading else topVerifiersLoading">
    <div class="col-6">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div style="min-height: 320px;">
                <ngx-charts-bar-horizontal
                    [results]="topVerifiersByProject"
                    [yAxis]="true"
                    [showDataLabel]="true"
                    [legendPosition]="'below'">
                </ngx-charts-bar-horizontal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-6">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div style="min-height: 320px;">
                <ngx-charts-bar-horizontal
                    [results]="topVerifiersByUnit"
                    [yAxis]="true"
                    [showDataLabel]="true"
                    [legendPosition]="'below'">
                </ngx-charts-bar-horizontal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #topVerifiersLoading>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center">
              <div class="d-flex flex-column justify-content-center" style="height: 320px;">
                <mat-spinner [diameter]="50"></mat-spinner>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<div>
  <div class="row">
    <div class="col-6">
      <h5 class="text-center">Top 12 Verification Companies by Project/Building Count</h5>
    </div>
    <div class="col-6">
      <h5 class="text-center">Top 12 Verification Companies by Unit Count</h5>
    </div>
  </div>
  <div class="row" *ngIf="!topCompaniesIsLoading else topCompaniesLoading">
    <div class="col-6">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div style="min-height: 320px;">
                <ngx-charts-bar-horizontal
                    [results]="topCompaniesByProject"
                    [yAxis]="true"
                    [showDataLabel]="true"
                    [legendPosition]="'below'">
                </ngx-charts-bar-horizontal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div style="min-height: 320px;">
                <ngx-charts-bar-horizontal
                    [results]="topCompaniesByUnit"
                    [yAxis]="true"
                    [showDataLabel]="true"
                    [legendPosition]="'below'">
                </ngx-charts-bar-horizontal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #topCompaniesLoading>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center">
              <div class="d-flex flex-column justify-content-center" style="height: 320px;">
                <mat-spinner [diameter]="50"></mat-spinner>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
