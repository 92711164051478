import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { BaseDataService } from '@/core/services/base-data-service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BasePermissionService {
  serviceCls: BaseDataService;

  constructor(public store: Store<AppState>) {}

  resolveEntity<M>(entity: any): Observable<M> {
    let entity$: Observable<M>;
    if (typeof entity === 'number') {
      entity$ = this.serviceCls?.retrieve(entity, {
        ignoreStore: true,
      });
    } else {
      entity$ = of(entity);
    }

    return entity$;
  }
}
