import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from './state/reducers';
import { forkJoin, of } from 'rxjs';
import { AuthenticationService } from '@/core/services/authentication.service';
import { UserService } from '@/data/core/services/user.service';
import { getLoading } from '@/state/selectors/app.selector';
import { SocketService } from '@/core/services/socket.service';
import { MessageService } from '@/data/messaging/services/message.service';
import { catchError, first, map } from 'rxjs/operators';
import { transition, trigger, useAnimation } from '@angular/animations';
import {
  fadeInOutEnterAnimation,
  fadeInOutLeaveAnimation,
} from '@/core/animations/claims.animations';
import { getInfoUser } from '@/state/selectors/info.selector';
import { User } from '@/data/core/models/user';
import { NavService } from '@/data/core/services/nav.service';
import { DomService } from '@/core/services/dom.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [useAnimation(fadeInOutEnterAnimation)]),
      transition(':leave', [useAnimation(fadeInOutLeaveAnimation)]),
    ]),
  ],
})
export class AppComponent implements OnInit {
  public initialized = false;
  public isLoading = false;
  public currentUser: User;

  @HostListener('document:click', ['$event'])
  documentClick(event: any): void {
    this.domService.documentClickedTarget.next(event.target);
  }

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private messagesService: MessageService,
    private navService: NavService,
    private domService: DomService,
    private socketService: SocketService,
    private store: Store<AppState>,
    private httpClient: HttpClient,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.store.select(getLoading).subscribe(isLoading => {
      this.isLoading = isLoading;
      this.cdRef.detectChanges();
    });

    const googleMapsApi = this.httpClient
      .jsonp(
        'https://maps.googleapis.com/maps/api/js?client=gme-pivotalenergysolutions',
        'callback'
      )
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );

    if (this.authenticationService.isLoggedIn()) {
      this.userService
        .info()
        .pipe(first())
        .subscribe(result => {
          const userId = result.impersonated
            ? result.impersonated.id
            : result.user.id;
          forkJoin({
            currentUser: this.store.select(getInfoUser).pipe(first()),
            menu: this.navService.list().pipe(first()),
            googleMaps: googleMapsApi,
          }).subscribe(({ currentUser, menu, googleMaps }) => {
            this.currentUser = currentUser;
            this.socketService.init();
            this.initialized = true;
          });
        });
    } else {
      forkJoin({
        currentUser: this.store.select(getInfoUser).pipe(first()),
        menu: this.navService.list().pipe(first()),
        googleMaps: googleMapsApi,
      }).subscribe(({ currentUser, menu, googleMaps }) => {
        this.currentUser = currentUser;
        this.initialized = true;
      });
    }
  }
}
