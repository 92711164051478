import { Component, OnInit } from '@angular/core';
import { User } from '@/data/core/models/user';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { getInfoUser } from '@/state/selectors/info.selector';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { UAParser } from 'ua-parser-js';
import {
  ZendeskCreateRequest,
  ZendeskCreateRequestResponse,
  ZendeskTicketPriority,
  ZendeskTicketPriorityLabelMapping,
  ZendeskTicketType,
  ZendeskTicketTypeLabelMapping,
} from '@/data/core/models';
import { ZendeskService } from '@/data/core/services/zendesk.service';
import {
  ServerErrorDialogComponent,
  ServerErrorDialogConfig,
} from '@/shared/components/server-error-dialog/server-error-dialog.component';

@Component({
  selector: 'app-zendesk-feedback',
  templateUrl: './zendesk-feedback.component.html',
  styleUrls: ['./zendesk-feedback.component.scss'],
})
export class ZendeskFeedbackComponent implements OnInit {
  public initialized = false;
  public currentUser: User;
  public zendeskFormGroup: UntypedFormGroup;
  public ticketTypeLabelMapping = ZendeskTicketTypeLabelMapping;
  public ticketPriorityLabelMapping = ZendeskTicketPriorityLabelMapping;
  public panelExpanded = false;
  public isLoading = false;
  public isCreated = false;
  public zendeskCreateTicketResponse: ZendeskCreateRequestResponse;

  constructor(
    public store: Store<AppState>,
    public router: Router,
    public dialog: MatDialog,
    public fb: UntypedFormBuilder,
    public zendeskService: ZendeskService
  ) {}

  ngOnInit(): void {
    this.store.select(getInfoUser).subscribe(currentUser => {
      this.currentUser = currentUser;
      this.initialized = !!currentUser;
      this.setupZendeskFormGroup();
      this.resetForm();
      this.panelExpanded = false;
    });
  }

  public setupZendeskFormGroup() {
    this.zendeskFormGroup = this.fb.group({
      subject: ['', Validators.required],
      description: ['', Validators.required],
      type: [ZendeskTicketType.question, Validators.required],
      priority: [ZendeskTicketPriority.low, Validators.required],
    });
  }

  handleCancel($event: MouseEvent) {
    $event.preventDefault();
    this.panelExpanded = !this.panelExpanded;
  }

  handleSend($event: MouseEvent) {
    $event.preventDefault();

    this.zendeskFormGroup.markAllAsTouched();

    if (this.zendeskFormGroup.invalid) {
      return;
    }

    this.isLoading = true;

    const formData = new ZendeskCreateRequest();

    formData.subject = this.zendeskFormGroup.value.subject;
    formData.description = this.zendeskFormGroup.value.description;
    formData.ticket_type = this.zendeskFormGroup.value.type;
    formData.priority = this.zendeskFormGroup.value.priority;
    formData.current_page = window.location.href;
    formData.other_pages = this.getOtherPages();

    const ua = new UAParser(navigator.userAgent).getResult();

    formData.comment = {
      body: `${this.zendeskFormGroup.value.description}
                    ---
                    Current Page:
                    ${window.location.href}
                    ---
                    Other Pages:
                    ${this.getOtherPages() || 'None'}
                    ---
                    Browser:
                    ${ua.browser.name} - ${ua.browser.version}
                    ---
                    OS:
                    ${ua.os.name} - ${ua.os.version}
                    `,
    };

    this.zendeskService.request_create(formData).subscribe(
      zendeskCreateTicketResponse => {
        this.isLoading = false;
        this.isCreated = true;
        this.zendeskCreateTicketResponse = zendeskCreateTicketResponse;
      },
      error => {
        this.isLoading = false;
        this.dialog.open(ServerErrorDialogComponent, {
          width: '400px',
          data: new ServerErrorDialogConfig({
            title: 'Error:',
            data: error,
          }),
        });
      }
    );
  }

  resetForm() {
    this.zendeskFormGroup.get('subject').reset();
    this.zendeskFormGroup.get('description').reset();
    this.zendeskFormGroup.get('type').patchValue(ZendeskTicketType.question);
    this.zendeskFormGroup.get('priority').patchValue(ZendeskTicketPriority.low);
    this.isCreated = false;
    this.isLoading = false;
  }

  getOtherPages(): string {
    const otherPages =
      JSON.parse(localStorage.getItem('zendesk_open_pages')) || [];
    otherPages.splice(otherPages.indexOf(window.location.href), 1);

    return otherPages.join(', ');
  }
}
