<h2 mat-dialog-title>
    Select Subdivision
</h2>
<div style="position: relative;">
    <div class="overlay-content" *ngIf="isLoading"
         style="position: absolute; width: 100%; height: 100%; top:0; left: 0; background: #FFFFFF; z-index: 10000;">
        <div class="d-flex justify-content-center h-100">
            <div class="d-flex flex-column justify-content-center">
                <mat-spinner [diameter]="75"></mat-spinner>
            </div>
        </div>
    </div>
    <mat-dialog-content [class]="{'loading-content': isLoading}">
        <form [formGroup]="form" class="mb-2">
            <div class="row">
                <div class="col-12">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Subdivision Name</mat-label>
                        <input matInput placeholder="Type to search"
                               [matAutocomplete]="nameAutocomplete" formControlName="name">
                        <mat-autocomplete #nameAutocomplete="matAutocomplete"
                                          [displayWith]="displaySubdivisionAutocomplete"
                                          (optionSelected)='onSubdivisionSelected($event)'>
                            <mat-option
                                    *ngFor="let subdivision of (existingSubdivisions$ | async)?.results"
                                    [value]="subdivision">
                                {{ subdivision.name }}
                            </mat-option>
                            <mat-option [value]="newSubdivisionName">
                                <b>Create New ...</b>
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error
                                *ngIf="form.get('name').hasError('required')">
                            This field is required
                        </mat-error>
                        <mat-error
                                *ngIf="form.get('name').hasError('invalidObject')">
                            Select existing Subdivision or select <b>Create New</b> option from the list
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="isNew">
                <div class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline" class="w-100 mb-2">
                            <mat-label>City/County/State</mat-label>
                            <app-generic-model-autocomplete formControlName="city_info" modelClassName="city" [filterParams]="{ordering: 'name'}"></app-generic-model-autocomplete>
                            <mat-error *ngIf="form.controls.city_info.hasError('required')">City is required -
                                Must select option from list
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Builder Organization</mat-label>
                            <input matInput placeholder="Type to search" formControlName="builder_organization_info"
                                   [matAutocomplete]="auto_builder_organization">
                            <mat-error *ngIf="form.controls['builder_organization_info'].hasError('required')">
                                Builder Organization is required - Must select option from list
                            </mat-error>
                            <mat-error *ngIf="form.controls['builder_organization_info'].hasError('invalidObject')">
                                Must select option from list
                            </mat-error>
                            <mat-autocomplete #auto_builder_organization="matAutocomplete" autoActiveFirstOption [displayWith]="displayBuilderOrganizationMenu">
                                <mat-option
                                        *ngFor="let builderOrganization of (filteredBuilderOrganizations$ | async)?.results"
                                        [value]="builderOrganization">
                                    <app-hirl-builder-organization-display-prefix [builderOrganization]="builderOrganization"></app-hirl-builder-organization-display-prefix>
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                        <small class="mat-hint">
                            List of all available
                            <a [routerLink]="['', 'company', 'builder']" target="_blank" matTooltip="Open Builder Organizations page in new window">
                                Builder Organizations <fa-icon [icon]="['fas', 'external-link-alt']"></fa-icon>
                            </a>
                        </small>
                    </div>
                </div>
            </div>
        </form>
        <div *ngIf="subdivision.id">
            <ul class="list-group">
                <li class="list-group-item">
                    <b>Location</b>:
                    {{ subdivision.city_info?.name }},
                    <span *ngIf="subdivision.city_info?.country_info.abbr === 'US'">
                        {{ subdivision.city_info?.county_info?.name }},
                        {{ subdivision.city_info?.county_info?.state }}
                    </span>
                    <span *ngIf="subdivision.city_info?.country_info.abbr !== 'US'">
                        {{ subdivision.city_info?.country_info?.name }}
                    </span>
                </li>
                <li class="list-group-item">
                    <b>Builder Organization</b>: <a [routerLink]="['/', 'builders', subdivision.builder_org]" target="_blank">{{ subdivision.builder_org_info?.name }}</a>
                </li>
            </ul>
        </div>
    </mat-dialog-content>
    <div class="row">
        <div class="col-12">
            <hr>
        </div>
    </div>
    <div class="row">
        <div class="col-12 text-center">
            <button class="btn btn-primary mr-2" (click)="onSelectSubdivision($event)" [disabled]="isLoading">
                Confirm
            </button>
            <button class="btn btn-primary" (click)="onClose($event)" cdkFocusInitial [disabled]="isLoading">
                Cancel
            </button>
        </div>
    </div>


</div>
