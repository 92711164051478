<h2 mat-dialog-title>
  <ng-container *ngIf="!isEditForm">
    Add New <b>Document</b>
  </ng-container>
  <ng-container *ngIf="isEditForm">
    Edit <b>Document</b>
  </ng-container>
</h2>
<mat-dialog-content *ngIf="initialized">
  <div class="row">
    <div class="col-12">
      <form [formGroup]="form">
        <div class="row">
          <div class="col-12">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Document</mat-label>
              <app-file-input [formControl]="form.controls['document']"></app-file-input>
              <mat-hint *ngIf="entity.document">
                Current file: <app-customer-document-display [customerDocument]="entity" displayType="filenameAsLinkAndSharedLink"></app-customer-document-display>
              </mat-hint>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <mat-form-field appearance="outline" class="mb-2 w-100">
              <mat-label>Description</mat-label>
              <textarea matInput type="text" placeholder="Enter Description"
                        formControlName="description"></textarea>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Is Public</mat-label>
              <mat-select formControlName="doc_access_level">
                <mat-option value="private">Private (visible only to users with AXIS accounts associated with your company)
                </mat-option>
                <mat-option value="public_logged_in">
                  Public – AXIS Visibility (visible to all users with AXIS accounts)
                </mat-option>
                <mat-option value="public_global">
                  Public – Global Visibility (visible to all users with AXIS accounts and referenceable publicly outside of AXIS)
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-button color="accent" mat-raised-button (click)="save($event)">Save</button>
    <button mat-button [mat-dialog-close]="null" color="primary" mat-raised-button>Cancel</button>
</mat-dialog-actions>


