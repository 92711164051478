import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { first } from 'rxjs/operators';
import { ContentTypeInfo } from '@/data/core/models';
import { QaStatusService } from '@/data/qa/services/qa-status.service';
import { QaStatus } from '@/data/qa/models';

@Component({
  selector: 'app-content-object-display',
  templateUrl: './content-object-display.component.html',
  styleUrls: ['./content-object-display.component.scss'],
})
export class ContentObjectDisplayComponent implements OnInit, OnChanges {
  @Input() objectId: number | string;
  @Input() contentTypeInfo: ContentTypeInfo;

  public isLoading = true;
  public contentObject?: QaStatus;

  constructor(public qaStatusService: QaStatusService) {}

  ngOnInit(): void {
    this.isLoading = true;
    if (this.objectId && this.contentTypeInfo) {
      switch (this.contentTypeInfo.model) {
        case 'qastatus':
          this.qaStatusService
            .retrieve(Number(this.objectId))
            .pipe(first())
            .subscribe(qaStatus => {
              this.contentObject = qaStatus;
              this.isLoading = false;
            });
          break;
        default:
          this.isLoading = false;
      }
    } else {
      this.isLoading = false;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.ngOnInit();
  }
}
