import { AppliancesBackendDict } from '@/data/simulation/models/Appliances';
import { createAction, props } from '@ngrx/store';
import { ModelErrors, StateErrors } from '../shared/base.state';

export const loadAppliances = createAction(
  '[Simulation API] Load Appliancess',
  props<{ appliances: AppliancesBackendDict }>()
);

export const loadAppliancesSuccess = createAction(
  '[Appliances API] Load Appliancess Success',
  props<{
    appliances: AppliancesBackendDict;
    errors: StateErrors<AppliancesBackendDict>;
  }>()
);

export const updateAppliances = createAction(
  '[Appliances UI] Update Appliances',
  props<{ id: number; appliancesChanges: Partial<AppliancesBackendDict> }>()
);

export const updateAppliancesSuccess = createAction(
  '[Appliances API] Update Appliances Success',
  props<{
    appliances: AppliancesBackendDict;
    errors: ModelErrors<AppliancesBackendDict>;
  }>()
);

export const updateAppliancesFailure = createAction(
  '[Appliances API] Update Appliances Failure',
  props<{ id: number }>()
);
