<ng-container *ngIf="eepProgram">
  <ng-container [ngSwitch]="displayType">
    <ng-container *ngSwitchCase="EEEPProgramDisplayComponentType.nameAsLink">
      <a
        [routerLink]="[
        '/',
        'eep_program',
        eepProgram?.id
      ]">
        {{ eepProgram?.name }}
      </a>
    </ng-container>
  </ng-container>
</ng-container>
