import { createAction, props } from '@ngrx/store';
import { Product, ProductImage } from '@/data/product/models';
import { ProductPracticeItem } from '@/data/customer-hirl/models';
import { Annotation } from '@/data/annotation/models';
import { CustomerDocument } from '@/data/filehandling/models';

export const resetProduct = createAction('[Product] Reset');

export const loadProduct = createAction(
  '[Product] Detail',
  props<{ payload: Product }>()
);

// Product Images

export const loadProductProductImagesAction = createAction(
  '[Product ProductImage] List',
  props<{ payload: ProductImage[] }>()
);

export const loadProductProductImagesCountAction = createAction(
  '[Product ProductImage] Count',
  props<{ payload: number }>()
);

export const updateProductProductImageAction = createAction(
  '[Product ProductImage] Update',
  props<{ payload: ProductImage }>()
);

export const deleteProductProductImageAction = createAction(
  '[Product ProductImage] Delete',
  props<{ payload: ProductImage }>()
);

// HIRL Practice Item Product
export const loadProductProductPracticeItemsAction = createAction(
  '[Product ProductPracticeItem] List',
  props<{ payload: ProductPracticeItem[] }>()
);

export const loadProductProductPracticeItemsCountAction = createAction(
  '[Product ProductPracticeItem] Count',
  props<{ payload: number }>()
);

export const updateProductProductPracticeItemAction = createAction(
  '[Product ProductPracticeItem] Update',
  props<{ payload: ProductPracticeItem }>()
);

export const deleteProductPracticeItemAction = createAction(
  '[Product ProductPracticeItem] Delete',
  props<{ payload: ProductPracticeItem }>()
);

// Payment Requests Product

export const loadProductPaymentRequestsAction = createAction(
  '[Product PaymentRequestProduct] List',
  props<{ payload: ProductImage[] }>()
);

export const loadProductPaymentRequestsCountAction = createAction(
  '[Product PaymentRequestProduct] Count',
  props<{ payload: number }>()
);

// CustomerDocuments

export const loadProductDocuments = createAction(
  '[Product Documents] Documents',
  props<{ payload: CustomerDocument[] }>()
);

export const loadProductDocumentsCount = createAction(
  '[Product Documents] Documents Count',
  props<{ payload: number }>()
);

export const updateProductDocument = createAction(
  '[Product Documents] Update Document',
  props<{ payload: CustomerDocument }>()
);

export const deleteProductDocument = createAction(
  '[Product Documents] Delete Document',
  props<{ payload: CustomerDocument }>()
);

// Notes

export const loadProductNotes = createAction(
  '[Product Notes] Notes',
  props<{ payload: Annotation[] }>()
);

export const loadProductNotesCount = createAction(
  '[Product Notes] Notes Count',
  props<{ payload: number }>()
);

export const updateProductNote = createAction(
  '[Product Notes] Update Note',
  props<{ payload: Annotation }>()
);

export const deleteProductNote = createAction(
  '[Product Notes] Delete Note',
  props<{ payload: Annotation }>()
);
