import { UserInfo } from '@/data/core/models/user';

export enum HIRLRPCUpdaterRequestState {
  inProgress = 'in_progress',
  success = 'success',
  error = 'error',
}

export const HIRLRPCUpdaterRequestStateLabelMapping: Record<
  HIRLRPCUpdaterRequestState,
  string
> = {
  [HIRLRPCUpdaterRequestState.inProgress]: 'In progress',
  [HIRLRPCUpdaterRequestState.success]: 'Success',
  [HIRLRPCUpdaterRequestState.error]: 'Error',
};

export enum HIRLRPCUpdaterScoringPath {
  nc2020 = '2020_ngbs',
  remodel2020 = '2020_ngbs_remodel',
  nc2015 = '2015_ngbs',
}

export const HIRLRPCUpdaterScoringPathLabelMapping: Record<
  HIRLRPCUpdaterScoringPath,
  string
> = {
  [HIRLRPCUpdaterScoringPath.nc2020]: '2020 New Construction',
  [HIRLRPCUpdaterScoringPath.remodel2020]: '2020 Remodel',
  [HIRLRPCUpdaterScoringPath.nc2015]: '2015 New Construction',
};

export enum HIRLRPCUpdaterProjectType {
  singleFamily = 'single_family',
  multiFamily = 'multifamily',
}

export const HIRLRPCUpdaterProjectTypeLabelMapping: Record<
  HIRLRPCUpdaterProjectType,
  string
> = {
  [HIRLRPCUpdaterProjectType.singleFamily]: 'Single Family',
  [HIRLRPCUpdaterProjectType.multiFamily]: 'Multi Family',
};

export class HIRLRPCUpdaterRequest {
  id?: number;
  state: HIRLRPCUpdaterRequestState;

  document: string;

  scoring_path: HIRLRPCUpdaterScoringPath;

  project_type: HIRLRPCUpdaterProjectType;
  result_document: string;

  rpc_session: number;
  task_id: string;

  user: number;
  user_info: UserInfo;
  created_at: string;
}
