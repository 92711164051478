import {
  SlabTypeBackendDict,
  createSlabTypeForm,
} from '@/data/simulation/models/enclosure/SlabType';
import { BaseValidator } from './base.validator';
import { ModelErrors, StateErrors } from '../state/shared/base.state';

export class SlabTypeValidator extends BaseValidator {
  static validateForm(
    slabtype: SlabTypeBackendDict
  ): ModelErrors<SlabTypeBackendDict> {
    const form = createSlabTypeForm(slabtype);
    return form.getFieldAndModelErros();
  }

  static validate(
    slabtypes: SlabTypeBackendDict[]
  ): StateErrors<SlabTypeBackendDict> {
    const errors = {};
    slabtypes.forEach(slabtype => {
      errors[slabtype.id] = this.validateForm(slabtype);
    });
    return errors;
  }
}
