import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface IAppliedFiltersDialog {
  filters: {
    [key: string]: string;
  };
}

@Component({
  selector: 'app-applied-filters-dialog',
  templateUrl: './applied-filters-dialog.component.html',
  styleUrls: ['./applied-filters-dialog.component.scss'],
})
export class AppliedFiltersDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<AppliedFiltersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IAppliedFiltersDialog
  ) {}

  ngOnInit(): void {}
}
