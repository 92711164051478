<ng-container [ngSwitch]="display">
    <ng-container *ngSwitchCase="stateDisplay.flat">
        {{ stateLabelMapping[projectRegistration.state] }}
        <fa-icon [icon]="['fas', 'info-circle']"  [matTooltip]="stateDescriptionMap[this.projectRegistration.state]"></fa-icon>
    </ng-container>

    <ng-container *ngSwitchCase="stateDisplay.badge">
        <ng-container [ngSwitch]="projectRegistration.state">
            <ng-container *ngSwitchCase="registrationState.new">
                <span class="badge badge-secondary" [matTooltip]="stateDescriptionMap[this.projectRegistration.state]">
                    {{ stateLabelMapping[projectRegistration.state] }}
                </span>
            </ng-container>
            <ng-container *ngSwitchCase="registrationState.pending">
                <span class="badge badge-secondary" [matTooltip]="stateDescriptionMap[this.projectRegistration.state]">
                    {{ stateLabelMapping[projectRegistration.state] }}
                </span>
            </ng-container>
            <ng-container *ngSwitchCase="registrationState.active">
                <span class="badge badge-success" [matTooltip]="stateDescriptionMap[this.projectRegistration.state]">
                    {{ stateLabelMapping[projectRegistration.state] }}
                </span>
            </ng-container>
            <ng-container *ngSwitchCase="registrationState.rejected">
                <span class="badge badge-danger" [matTooltip]="stateDescriptionMap[this.projectRegistration.state]">
                    {{ stateLabelMapping[projectRegistration.state] }}
                </span>
            </ng-container>
            <ng-container *ngSwitchCase="registrationState.abandon">
                <span class="badge badge-danger" [matTooltip]="stateDescriptionMap[this.projectRegistration.state]">
                    {{ stateLabelMapping[projectRegistration.state] }}
                </span>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-container>
