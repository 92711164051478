import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { User } from '@/data/core/models/user';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientAgreementService } from '@/data/customer-hirl/services/client-agreement.service';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '@/data/core/services/user.service';
import { toggleLoading } from '@/state/actions/app.actions';
import { getInfoUser } from '@/state/selectors/info.selector';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  first,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { EMPTY, forkJoin, Observable, Subject } from 'rxjs';
import { objectValidator } from '@/core/validators';
import {
  ComponentCompanyType,
  componentCompanyTypeDataMap,
} from '@/modules/company/constants';
import { ServerResponse } from '@/core/schemes/server-response';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { CreateClientAgreementWithoutUser } from '@/data/customer-hirl/models';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { ClientAgreementCompanyTypeDataMap } from '@/modules/customer-hirl/constants';
import {
  CompanyRequestParams,
  CompanyService,
} from '@/data/company/services/company-base.service';
import { GeocoderService } from '@/data/geocoder/services/geocoder.service';
import { ConfirmedGeocodeResponse } from '@/modules/geocoder/components/confirm-geocode-response-dialog/confirm-geocode-response';
import { Company } from '@/data/company/models';
import { NavigationService } from '@/shared/services/navigation-service.service';
import { IGeocodeMatches } from '@/data/geocoder/models';

@Component({
  selector: 'app-hirl-create-client-agreement-without-user',
  templateUrl: './hirl-create-client-agreement-without-user.component.html',
  styleUrls: ['./hirl-create-client-agreement-without-user.component.scss'],
})
export class HirlCreateClientAgreementWithoutUserComponent
  implements OnInit, OnDestroy
{
  public initialized = false;
  public currentUser: User;
  public formGroup: UntypedFormGroup;

  componentCompanyType$ = new Subject<string>();
  companyDisplayName: string;
  companyType: string;
  componentCompanyTypeDataMap = ClientAgreementCompanyTypeDataMap;

  filteredCompanies$: Observable<ServerResponse<Company>>;

  private componentDestroyed$ = new Subject();

  constructor(
    public fb: UntypedFormBuilder,
    public store: Store<AppState>,
    public injector: Injector,
    public router: Router,
    public clientAgreementService: ClientAgreementService,
    public dialog: MatDialog,
    public activatedRoute: ActivatedRoute,
    public userService: UserService,
    private companyService: CompanyService,
    private geocoderService: GeocoderService,
    public uiHelperService: UIHelperService,
    private navigation: NavigationService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(toggleLoading({ payload: true }));

    this.componentCompanyType$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(componentType => {
        this.companyType = componentType;
        this.companyDisplayName =
          componentCompanyTypeDataMap[componentType].displayName;

        const sources: { [k: string]: any } = {
          currentUser: this.store.select(getInfoUser).pipe(first()),
        };

        forkJoin(sources).subscribe(({ currentUser }) => {
          this.currentUser = currentUser;

          this.store.dispatch(toggleLoading({ payload: false }));
          this.initialized = true;
        });

        this.setupFormGroup();
      });

    this.activatedRoute.params
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(params => {
        this.componentCompanyType$.next(params.componentCompanyType);
      });
  }

  public ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();

    this.componentCompanyType$.complete();
  }

  public setupFormGroup() {
    this.formGroup = this.fb.group({
      company_info: ['', [Validators.required, objectValidator()]],
      signer_name: ['', [Validators.required]],
      signer_email: ['', [Validators.required, Validators.email]],
      mailing_address: [null, [Validators.required]],
    });

    this.filteredCompanies$ = this.formGroup
      .get('company_info')
      .valueChanges.pipe(
        takeUntil(this.componentCompanyType$),
        startWith(''),
        debounceTime(100),
        distinctUntilChanged(),
        switchMap(value =>
          this.companyService
            .list(
              Object.assign(new CompanyRequestParams(), {
                search: value,
                is_attached: 'attached',
                page_size: 300,
                company_type: ComponentCompanyType.builder,
              })
            )
            .pipe(catchError(err => EMPTY))
        )
      );
  }

  handleBack() {
    this.router.navigate(['hi', 'builder-agreements']);
  }

  displayCompanyMenu(builderOrganization) {
    if (builderOrganization) {
      return `${builderOrganization.name}`;
    }
  }

  onCompanySelected($event: MatAutocompleteSelectedEvent) {
    this.store.dispatch(toggleLoading({ payload: true }));
    this.formGroup
      .get('signer_email')
      .patchValue($event.option.value.default_email);
    this.geocoderService
      .matches({
        street_line1: $event.option.value.street_line1,
        street_line2: $event.option.value.street_line2 || '',
        city: $event.option.value.city,
        zipcode: $event.option.value.zipcode,
      } as IGeocodeMatches)
      .pipe(first())
      .subscribe(
        geocode => {
          const confirmedGeocodeResponse = new ConfirmedGeocodeResponse({
            geocode: geocode,
            geocodeBrokeredResponse: null,
          });
          this.formGroup
            .get('mailing_address')
            .patchValue(confirmedGeocodeResponse);
          this.store.dispatch(toggleLoading({ payload: false }));
        },
        _ => {
          // Ignore error in case company address is empty or not valid
          this.formGroup.get('mailing_address').patchValue(null);
          this.store.dispatch(toggleLoading({ payload: false }));
        }
      );
  }

  onCreate($event: MouseEvent) {
    $event.preventDefault();
    this.formGroup.markAllAsTouched();

    if (this.formGroup.invalid) {
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const formData = new CreateClientAgreementWithoutUser();
    formData.company = this.formGroup.value.company_info.id;
    formData.signer_email = this.formGroup.value.signer_email;
    formData.signer_name = this.formGroup.value.signer_name;
    formData.mailing_geocode = this.formGroup.value.mailing_address.geocode.id;

    this.clientAgreementService
      .create_without_user(formData)
      .pipe(first())
      .subscribe(
        clientAgreement => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.router.navigate([
            '/',
            'hi',
            'client_agreements',
            'detail',
            clientAgreement.id,
          ]);
        },
        error => this.uiHelperService.handleUserRequestError(error)
      );
  }
}
