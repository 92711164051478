  <div class="card mb-2 position-relative">
  <app-loading-overlay *ngIf="loading"></app-loading-overlay>
  <div class="card-header">
    <div class="row">
      <div class="col-12">
        <strong>Home location</strong>
      </div>
    </div>
  </div>
  <div class="card-body pb-0">
    <div class="d-flex flex-column">
      <div class="d-flex flex-column">
        <ng-container
            *ngIf="this.locationConfirmedGeocodeResponse?.addressInfo?.lat && this.locationConfirmedGeocodeResponse?.addressInfo?.lng; else mapAddressNotValid;">
          <div class="card w-100 h-100">
            <div class="card-content w-100 h-100">
              <google-map
                  height="350px"
                  width="100%"
                  [zoom]="16"
                  [center]="mapCenter"
                  [options]="mapOptions"
              >
                <map-marker
                    #markerElem="mapMarker"
                    *ngFor="let marker of mapMarkers"
                    [position]="marker.position"
                    [label]="marker.label"
                    [title]="marker.title"
                    [options]="marker.options"
                    (mapClick)="mapOpenInfo(markerElem, marker.info)"
                >
                </map-marker>
                <map-info-window>{{ mapInfoContent }}</map-info-window>
              </google-map>
            </div>
          </div>
          <div class="d-flex flex-row mt-3">
            <div class="attr-name">Metropolitan:</div>
            <div
                class="flex-fill">{{ this.locationConfirmedGeocodeResponse?.addressInfo?.city_info?.county_info?.metro_info?.name }}
            </div>
          </div>
          <div class="d-flex flex-row">
            <div class="attr-name">County:</div>
            <div
                class="flex-fill">{{ this.locationConfirmedGeocodeResponse?.addressInfo?.city_info?.county_info?.name }}
            </div>
          </div>
          <div class="d-flex flex-row">
            <div class="attr-name">Climate Zone:</div>
            <div
                class="flex-fill">{{ this.locationConfirmedGeocodeResponse?.addressInfo?.city_info?.county_info?.climate_zone_info?.label }}
            </div>
          </div>
          <div class="d-flex flex-row mb-3">
            <div class="attr-name">Coordinates:</div>
            <div class="flex-fill">
              {{ this.locationConfirmedGeocodeResponse?.addressInfo?.lat }} {{ this.locationConfirmedGeocodeResponse?.addressInfo?.lng }}
              <app-copy-to-clipboard-button
                  [value]="this.locationConfirmedGeocodeResponse?.addressInfo?.lat + ' ' + this.locationConfirmedGeocodeResponse?.addressInfo?.lng">
                <fa-icon [icon]="['far', 'copy']"></fa-icon>
              </app-copy-to-clipboard-button>
            </div>
          </div>
        </ng-container>

        <ng-template #mapAddressNotValid>
          <div class="d-flex flex-column flex-fill justify-content-center not-confirmed-address-container">
            <div class="p-3 text-muted text-center">
              Axis was unable to confirm and map this address.
              <fa-icon [icon]="['fas', 'map-marker']"></fa-icon>
            </div>
          </div>
        </ng-template>
      </div>
      <app-form formName="locationForm" [formGroup]="form" (save)="onSave($event)">
        <mat-form-field class="w-100">
          <mat-label>Address</mat-label>
          <app-home-address-input formControlName="address" required [show-clear-btn]="true"
                                  [approximate-address]="true"></app-home-address-input>
        </mat-form-field>

        <app-foreign-model-field
          [label]="fieldLabels.weather"
          [name]="weatherName"
          [options]="['add', 'replace']"
          (add)="replaceWeather()"
          (replace)="replaceWeather()">
        </app-foreign-model-field>
      </app-form>
    </div>

  </div>
  <div class="card-footer p-0">
    <app-model-action-buttons
      [entityId]="entityId"
      [options]="['history']"
      modelName="location">
    </app-model-action-buttons>
  </div>
</div>
