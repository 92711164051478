import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { filter, first, takeUntil } from 'rxjs/operators';
import { toggleLoading } from '@/state/actions/app.actions';
import { MatTabChangeEvent } from '@angular/material/tabs';
import {
  Product,
  ProductImage,
  ProductImageType,
  ProductStatusLabelMapping,
} from '@/data/product/models';
import { ProductService } from '@/data/product/services/product.service';
import { resetProduct } from '@/state/actions/product/product.actions';
import {
  getProduct,
  getProductDocumentsCount,
  getProductImages,
  getProductImagesCount,
  getProductNotesCount,
  getProductPaymentRequestsCount,
  getProductPracticeItemsCount,
} from '@/state/selectors/product/product.selector';
import { ProductPermissionService } from '@/modules/product/services/product-permission-service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import { NavigationService } from '@/shared/services/navigation-service.service';
import {
  HIRLProductChangeDialogComponent,
  HIRLProductChangeDialogData,
} from '@/modules/customer-hirl/components/hirl-product-change-dialog/hirl-product-change-dialog.component';
import {
  HirlProductPublishRequirementsDialogComponent,
  HirlProductPublishRequirementsDialogConfig,
} from '@/modules/customer-hirl/components/hirl-product-publish-requirements-dialog/hirl-product-publish-requirements-dialog.component';

@Component({
  selector: 'app-hirl-product-detail-page',
  templateUrl: './hirl-product-detail-page.component.html',
  styleUrls: ['./hirl-product-detail-page.component.scss'],
})
export class HIRLProductDetailPageComponent implements OnInit, OnDestroy {
  protected readonly ProductStatusLabelMapping = ProductStatusLabelMapping;

  carouselCustomOptions: OwlOptions = {
    items: 1,
    loop: true,
    dots: true,
    nav: true,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    responsive: {
      0: { items: 1 },
      400: { items: 1 },
      740: { items: 1 },
      940: { items: 1 },
    },
  };
  carouselSlides = [
    {
      img: 'https://placehold.co/600x400?text=No+Image',
    },
  ];

  initialized = false;

  selectedIndex = 0;
  productImageCount = -1; // means that is not loading yet

  productPracticeItemCount = -1;

  entityNotesCount = -1;
  entityDocumentsCount = -1;

  entityPaymentRequestsCount = -1;

  entity: Product;

  public editPermissionResponse: Observable<ObjectPermissionResponse>;
  public publishPermissionResponse: Observable<ObjectPermissionResponse>;
  public archivePermissionResponse: Observable<ObjectPermissionResponse>;
  public deletePermissionResponse: Observable<ObjectPermissionResponse>;

  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private productService: ProductService,
    private productPermissionService: ProductPermissionService,
    private uiHelperService: UIHelperService,
    private navigationService: NavigationService
  ) {
    this.store
      .select(getProduct)
      .pipe(
        filter(val => val !== null),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((entity: Product) => {
        this.entity = entity;
        this.entitySetup();
      });

    this.store
      .select(getProductImages)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((productImages: ProductImage[]) => {
        this.carouselSlides = productImages
          .filter(
            productImage => productImage.image_type === ProductImageType.main
          )
          .map((productImage: ProductImage) => {
            return {
              img: productImage.customer_document_info?.document,
            };
          });
      });

    this.store
      .select(getProductImagesCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((count: number) => {
        this.productImageCount = count;
      });

    this.store
      .select(getProductPracticeItemsCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((count: number) => {
        this.productPracticeItemCount = count;
      });

    this.store
      .select(getProductNotesCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(count => {
        this.entityNotesCount = count;
      });

    this.store
      .select(getProductDocumentsCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(count => {
        this.entityDocumentsCount = count;
      });

    this.store
      .select(getProductPaymentRequestsCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(count => {
        this.entityPaymentRequestsCount = count;
      });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(params => {
        if (params.tab) {
          this.selectedIndex = Number(params.tab) || 0;
        }
      });

    this.activatedRoute.params
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(params => {
        this.store.dispatch(toggleLoading({ payload: true }));
        this.productService
          .retrieve(params.productId)
          .pipe(takeUntil(this.componentDestroyed$), first())
          .subscribe({
            next: (entity: Product) => {
              this.entity = entity;
              this.entitySetup();
              this.store.dispatch(toggleLoading({ payload: false }));
              this.initialized = true;
            },
            error: error => this.uiHelperService.handleMainRequestError(error),
          });
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
    this.store.dispatch(resetProduct());
  }

  entitySetup() {
    this.editPermissionResponse = this.productPermissionService.canEdit(
      this.entity
    );
    this.publishPermissionResponse = this.productPermissionService.canPublish(
      this.entity
    );
    this.archivePermissionResponse = this.productPermissionService.canArchive(
      this.entity
    );
    this.deletePermissionResponse = this.productPermissionService.canDelete(
      this.entity
    );
  }

  onTabChange(event: MatTabChangeEvent) {
    const url = this.router
      .createUrlTree([], {
        relativeTo: this.activatedRoute,
        queryParams: { tab: event.index },
      })
      .toString();
    this.location.replaceState(url);
  }

  onEdit($event: MouseEvent) {
    $event.preventDefault();

    const dialogRef = this.dialog.open(HIRLProductChangeDialogComponent, {
      width: '55%',
      disableClose: true,
      autoFocus: false,
      data: {
        product: this.entity,
      } as HIRLProductChangeDialogData,
    });

    dialogRef.afterClosed().subscribe((entity?: Product) => {
      if (!entity) {
        return;
      }
      this.uiHelperService.openSnackBar('Record Successfully Updated');
    });
  }

  onPublish($event: MouseEvent) {
    $event.preventDefault();
    const data = new HirlProductPublishRequirementsDialogConfig({});

    const dialogRef = this.dialog.open(
      HirlProductPublishRequirementsDialogComponent,
      {
        data,
      }
    );
    dialogRef.afterClosed().subscribe();
  }

  onArchive($event: MouseEvent) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Do you want to archive product?',
      },
    });
    dialogRef.afterClosed().subscribe((entity?: Product) => {
      if (!entity) {
        return;
      }

      this.store.dispatch(toggleLoading({ payload: true }));
      this.productService
        .archive(this.entity.id)
        .pipe(takeUntil(this.componentDestroyed$), first())
        .subscribe({
          next: (_: Product) => {
            this.store.dispatch(toggleLoading({ payload: false }));
            this.uiHelperService.openSnackBar('Product successfully archived');
          },
          error: (error: HttpErrorResponse) =>
            this.uiHelperService.handleUserRequestError(error),
        });
    });
  }
}
