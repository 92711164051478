import {
  FuelTypeLabels,
  HeatingCoolingCapacityUnitLabels,
  HeatingEfficiencyUnitLabels,
  MechanicalMotorTypeLabels,
} from '@/data/simulation/enumerations';
import {
  HeaterBackendDict,
  createHeaterForm,
  FIELD_LABELS,
  HeatingSystemTypeLabels,
  AuxEnergyUnitLabels,
} from '@/data/simulation/models/mechanicals/Heater';
import { updateHeater } from '@/modules/simulation/state/heater/actions';
import { selectHeaterViewSet } from '@/modules/simulation/state/heater/selectors';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-heater',
  templateUrl: './heater.component.html',
  styleUrls: ['./heater.component.scss'],
})
export class HeaterComponent implements OnInit {
  @Input() entityId: number;
  fieldLabels = FIELD_LABELS;

  private componentDestroyed$ = new Subject();

  loading = false;
  entity: HeaterBackendDict = null;
  heaterFormGroup: FormGroup;

  heatingSystemTypeLabels = HeatingSystemTypeLabels;
  fuelTypeLabels = FuelTypeLabels;
  capacityUnitLabels = HeatingCoolingCapacityUnitLabels;
  heatingEfficiencyUnitLabels = HeatingEfficiencyUnitLabels;

  mototTypeLabels = MechanicalMotorTypeLabels;
  auxEnergyUnitLabels = AuxEnergyUnitLabels;

  constructor(public store: Store) {}

  ngOnInit() {
    this.store
      .select(selectHeaterViewSet(this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(({ data, loading }) => {
        this.entity = data;
        this.loading = loading;
        if (loading) return;

        if (this.heaterFormGroup) {
          this.heaterFormGroup.patchValue(data);
        } else {
          this.heaterFormGroup = createHeaterForm(data);
        }
      });
  }

  onSave(changedValues: Partial<HeaterBackendDict>) {
    this.store.dispatch(
      updateHeater({
        heaterChanges: { id: this.entityId, ...changedValues },
      })
    );
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  protected readonly AuxEnergyUnitLabels = AuxEnergyUnitLabels;
}
