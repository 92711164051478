import { Geocode, GeocodeBrokeredResponse } from '@/data/geocoder/models';
import { City } from '@/data/geographic/models/city';

export enum GeocodeAddressDisplayFormat {
  default = 'default',
  // Showing address without County
  contactAddress = 'contactAddress',
}

/**
 * Return short address representation based on GeocodeResponse or As Entered address
 */
export class GeocodeAddressInfo {
  street_line1: string;
  street_line2: string;
  zipcode: string;
  city_info?: City;
  lat?: number;
  lng?: number;

  displayAddressFormat = GeocodeAddressDisplayFormat.default;

  constructor(
    street_line1: string,
    street_line2: string,
    zipcode: string,
    city_info?: City,
    lat?: number,
    lng?: number
  ) {
    this.street_line1 = street_line1;
    this.street_line2 = street_line2;
    this.zipcode = zipcode;
    this.city_info = city_info;
    this.lat = lat;
    this.lng = lng;
  }

  get toString(): string {
    if (
      this.displayAddressFormat === GeocodeAddressDisplayFormat.contactAddress
    ) {
      let street = this.street_line1;
      if (this.street_line2) {
        street += ` ${this.street_line2}`;
      }
      let city = '';
      if (this.city_info?.country === 'US') {
        city += `${this.city_info?.name}, ${this.city_info?.county_info?.state}`;
      } else if (this.city_info?.country_info?.name) {
        city += `${this.city_info?.country_info?.name}`;
      }

      let address = '';
      if (street && city) {
        address = `${street}, ${city}`;
      } else if (street) {
        address = `${street}`;
      } else if (city) {
        address = `${city}`;
      }
      if (this.zipcode) {
        address += `, ${this.zipcode}`;
      }
      return address;
    }

    let street = this.street_line1;
    if (this.street_line2) {
      street += ` ${this.street_line2}`;
    }

    let city = '';
    if (this.city_info?.country === 'US') {
      city += `${this.city_info?.name}, ${this.city_info?.county_info?.name} ${this.city_info?.county_info?.state}`;
    } else if (this.city_info?.country_info?.name) {
      city += `${this.city_info?.country_info?.name}`;
    }

    let address = '';
    if (street && city) {
      address = `${street}, ${city}`;
    } else if (street) {
      address = `${street}`;
    } else if (city) {
      address = `${city}`;
    }
    if (this.zipcode) {
      address += `, ${this.zipcode}`;
    }
    return address;
  }

  isEqual(geocodeAddressInfo: GeocodeAddressInfo | null): boolean {
    if (!geocodeAddressInfo) {
      return false;
    }
    return (
      this.street_line1 === geocodeAddressInfo.street_line1 &&
      this.street_line2 === geocodeAddressInfo.street_line2 &&
      this.city_info.id === geocodeAddressInfo.city_info.id &&
      this.zipcode === geocodeAddressInfo.zipcode &&
      this.lat === geocodeAddressInfo.lat &&
      this.lng === geocodeAddressInfo.lng
    );
  }
}

export class ConfirmedGeocodeResponse {
  geocode?: Geocode;
  geocodeBrokeredResponse?: GeocodeBrokeredResponse;
  displayAddressFormat = GeocodeAddressDisplayFormat.default;

  constructor(
    {
      geocode,
      geocodeBrokeredResponse,
      displayAddressFormat,
    }: {
      geocode?: Geocode;
      geocodeBrokeredResponse?: GeocodeBrokeredResponse;
      displayAddressFormat?: GeocodeAddressDisplayFormat;
    } = { displayAddressFormat: GeocodeAddressDisplayFormat.default }
  ) {
    this.geocode = geocode;
    this.geocodeBrokeredResponse = geocodeBrokeredResponse;
    this.displayAddressFormat = displayAddressFormat;
  }

  get displayAddress(): string {
    if (this.geocodeBrokeredResponseAddressInfo) {
      return this.geocodeBrokeredResponseAddressInfo.toString;
    }
    return this.geocodeAddressInfo?.toString;
  }

  get geocodeAddressInfo(): GeocodeAddressInfo | null {
    if (!this.geocode) {
      return null;
    }
    const geocodeAddressInfo = new GeocodeAddressInfo(
      this.geocode.raw_street_line1,
      this.geocode.raw_street_line2,
      this.geocode.raw_zipcode,
      this.geocode.raw_city_info,
      this.geocode.raw_latitude,
      this.geocode.raw_longitude
    );
    geocodeAddressInfo.displayAddressFormat = this.displayAddressFormat;
    return geocodeAddressInfo;
  }

  get geocodeBrokeredResponseAddressInfo(): GeocodeAddressInfo | null {
    if (!this.geocodeBrokeredResponse) {
      return null;
    }
    const geocodeAddressInfo = new GeocodeAddressInfo(
      this.geocodeBrokeredResponse.street_line1,
      this.geocodeBrokeredResponse.street_line2,
      this.geocodeBrokeredResponse.zipcode,
      this.geocodeBrokeredResponse.city_info,
      this.geocodeBrokeredResponse.latitude,
      this.geocodeBrokeredResponse.longitude
    );
    geocodeAddressInfo.displayAddressFormat = this.displayAddressFormat;
    return geocodeAddressInfo;
  }

  get addressInfo(): GeocodeAddressInfo {
    if (this.geocodeBrokeredResponseAddressInfo) {
      return this.geocodeBrokeredResponseAddressInfo;
    }
    return this.geocodeAddressInfo;
  }

  isEqual(confirmedGeocodeResponse: ConfirmedGeocodeResponse | null): boolean {
    const addressInfo = confirmedGeocodeResponse
      ? confirmedGeocodeResponse.addressInfo
      : null;
    return this.addressInfo?.isEqual(addressInfo) || false;
  }
}
