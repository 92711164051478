<h2 mat-dialog-title>
    Add QA Notes
</h2>
<mat-dialog-content>
    <form [formGroup]="form" class="mb-2">
        <div class="row">
            <div class="col-12">
                <mat-form-field appearance="outline" class="w-100 mb-2">
                    <mat-label>Note</mat-label>
                    <textarea matInput type="text" placeholder="Note" formControlName="note"></textarea>
                    <mat-error *ngIf="form.controls['note'].hasError('required')">
                        This field is required
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>
</mat-dialog-content>
<div class="row">
    <div class="col-12">
        <hr>
    </div>
</div>
<div class="row" *ngIf="!isLoading">
    <div class="col-12 text-center">
        <button mat-raised-button color="accent" class="mr-2" (click)="onSave($event)">
            Save
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="null" cdkFocusInitial>
            Cancel
        </button>
    </div>
</div>
<div class="row" *ngIf="isLoading">
    <div class="col-12">
        <div class="d-flex justify-content-center">
            <mat-spinner [diameter]="50"></mat-spinner>
        </div>
    </div>
</div>