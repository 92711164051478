import { createSelector } from '@ngrx/store';
import { SimulationState } from '../reducers';
import { selectSimulationFeature } from '../simulation.selectors';

export const selectProject = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.project.entities[id]
  );

const selectProjectLoading = (id: number) =>
  createSelector(selectSimulationFeature, state => state.project.loading[id]);

export const selectProjectViewSet = (id: number) =>
  createSelector(
    selectProject(id),
    selectProjectLoading(id),
    (data, loading) => ({ data, loading })
  );

export const selectProjectErrorsCount = (id: number) =>
  createSelector(selectSimulationFeature, (state: SimulationState) => ({
    errorCount: state.project.errors[id].errorCount,
    warningCount: state.project.errors[id].warningCount,
  }));
