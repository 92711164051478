import { AnalysisListItem } from '@/data/simulation/models/reports/Analysis';
import { AnalysisService } from '@/data/simulation/services/analysis.service';
import { ReportsService } from '@/data/simulation/services/reports.services';
import { getInfoUser } from '@/state/selectors/info.selector';
import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from 'environments/environment';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-reports-tab',
  templateUrl: './reports-tab.component.html',
  styleUrls: ['./reports-tab.component.scss'],
})
export class ReportsTabComponent {
  @Input() simulationId: number;
  loading = true;
  runningSimulation = false;
  analyses: AnalysisListItem[];
  bigLadderCompany = false;
  superUser = false;

  constructor(
    private reportsService: ReportsService,
    private analysisService: AnalysisService,
    private store: Store
  ) {}

  changes = {};

  loadData() {
    this.loading = true;
    this.analysisService.list(this.simulationId).subscribe(data => {
      const analyses = data.results;
      if (analyses === null) return;

      this.loading = false;
      this.analyses = analyses.filter(
        analysis => !analysis.type.includes('reference')
      );

      const analysesPairs: {
        [engine: string]: {
          [baseType: string]: {
            design?: AnalysisListItem;
            reference?: AnalysisListItem;
          };
        };
      } = {};

      analyses.forEach(analysis => {
        const match = analysis.type.match(/(.+)(_design|_reference)$/);
        if (match) {
          const baseType = match[1];
          const suffix = match[2];

          if (!analysesPairs[analysis.engine]) {
            analysesPairs[analysis.engine] = {};
          }
          if (!analysesPairs[analysis.engine][baseType]) {
            analysesPairs[analysis.engine][baseType] = {};
          }

          if (suffix === '_design') {
            analysesPairs[analysis.engine][baseType].design = analysis;
          } else if (suffix === '_reference') {
            analysesPairs[analysis.engine][baseType].reference = analysis;
          }
        }
      });

      Object.keys(analysesPairs).forEach(engine => {
        Object.keys(analysesPairs[engine] || []).forEach(baseType => {
          const pair = analysesPairs[engine][baseType];
          const designScore =
            pair.design?.consumption?.total_energy_consumption || null;
          const referenceScore =
            pair.reference?.consumption?.total_energy_consumption || null;
          const analysisId = pair.design?.id || null;

          if (
            designScore !== null &&
            referenceScore !== null &&
            analysisId !== null
          ) {
            this.changes[analysisId] =
              ((designScore - referenceScore) / referenceScore) * 100;
          }
        });
      });
    });
  }

  ngOnInit() {
    this.runningSimulation = false;
    this.loadData();

    this.reportsService.refetchAnalyses$().subscribe(runningSimulation => {
      if (runningSimulation) {
        this.runningSimulation = true;
        return;
      }

      this.runningSimulation = false;
      this.loadData();
    });

    this.store
      .select(getInfoUser)
      .pipe(first())
      .subscribe(userInfo => {
        this.bigLadderCompany =
          userInfo.company_info.slug === 'general-big-ladder-software';
        this.superUser = userInfo.is_superuser;
      });
  }

  getURL(item) {
    return `${environment.AXIS_API_V3_ROOT}/simulations/${this.simulationId}/${item}/`;
  }
}
