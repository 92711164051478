import { ErrorMessageService } from '@/modules/simulation/services/error-message.service';
import { Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-field-errors',
  templateUrl: './field-errors.component.html',
  styleUrls: ['./field-errors.component.scss'],
})
export class FieldErrorsComponent {
  @Input() errors: ValidationErrors | null = null;
  errorCount = 0;
  warningCount = 0;
  errorMessages: string[] = [];
  warningMessages: string[] = [];

  constructor(public errorMessageService: ErrorMessageService) {}

  updateErrorMessages() {
    this.errorMessages = [];
    this.warningMessages = [];
    this.errorCount = 0;
    this.warningCount = 0;

    if (!this.errors) return;

    for (const [errorId, data] of Object.entries(this.errors)) {
      const message = this.errorMessageService.getMessage(errorId, data);
      if (this.errorMessageService.isWarning(errorId)) {
        this.warningMessages.push(message);
        this.warningCount = this.warningMessages.length;
      } else {
        this.errorMessages.push(message);
        this.errorCount = this.errorMessages.length;
      }
    }
  }

  ngOnChanges(changes) {
    this.updateErrorMessages();
  }
}
