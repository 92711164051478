import {
  FIELD_LABELS,
  InfiltrationMeasurementTypeLabels,
  InfiltrationUnitLabels,
  InfiltrationVerificationTypeLabels,
} from '@/data/simulation/models/enclosure/Infiltration';
import {
  InfiltrationBackendDict,
  createInfiltrationForm,
} from '@/data/simulation/models/enclosure/Infiltration';
import { updateInfiltration } from '@/modules/simulation/state/infiltration/actions';
import { selectInfiltrationViewSet } from '@/modules/simulation/state/infiltration/selectors';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-infiltration',
  templateUrl: './infiltration.component.html',
  styleUrls: ['./infiltration.component.scss'],
})
export class InfiltrationComponent implements OnInit {
  @Input() expanded = false;
  @Input() entityId: number;

  componentDestroyed$ = new Subject();
  form: FormGroup;
  loading: boolean;
  fieldLabels = FIELD_LABELS;
  measurementTypeLabels = InfiltrationMeasurementTypeLabels;
  infiltrationUnitLabels = InfiltrationUnitLabels;
  verificationTypeLabels = InfiltrationVerificationTypeLabels;
  classLabels = {};

  constructor(public store: Store) {
    Array.from({ length: 5 }, (_, i) => 1 + i).forEach(klass => {
      this.classLabels[klass] = `${klass}`;
    });
  }

  ngOnInit(): void {
    this.store
      .select(selectInfiltrationViewSet(this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(({ data, loading }) => {
        this.loading = loading;
        if (loading) return;

        if (this.form) {
          this.form.patchValue(data);
          return;
        }
        this.form = createInfiltrationForm(data);
      });
  }

  onSave(infiltrationChanges: Partial<InfiltrationBackendDict>) {
    this.loading = true;
    this.store.dispatch(
      updateInfiltration({
        id: this.entityId,
        infiltrationChanges,
      })
    );
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
