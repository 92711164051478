<div class="row">
    <mat-card class="card-container position-relative">
        <app-loading-overlay *ngIf="loading"></app-loading-overlay>
        <mat-card-content>
            <app-form formName="roofTypeFormGroup" [formGroup]="roofTypeFormGroup" (save)="saveRoofType($event)">
                <div>
                    <app-input formControlName="name" [label]="fieldLabels.name"></app-input>
                </div>
                <div>
                    <app-input formControlName="assembly_u_value" [label]="fieldLabels.assembly_u_value" type="expression"></app-input>
                    <app-checkbox formControlName="user_defined_assembly_u_value" label="Directly defined U-value"></app-checkbox>
                </div>
            </app-form>
            <app-assembly-editor 
                    [form]="roofTypeFormGroup" 
                    (save)="saveRoofType($event)" 
                    (systemUValue)="handleSystemUValue($event)"
                    *ngIf="!roofTypeFormGroup.controls.user_defined_assembly_u_value.value">
                <app-form formName="roofTypeFormGroup" (save)="saveRoofType($event)" [formGroup]="roofTypeFormGroup">
                    <div>
                        <app-input formControlName="continuous_insulation_r_value" type="expression"
                            [label]="fieldLabels.continuous_insulation_r_value"></app-input>
                    </div>
                    <div>
                        <app-select formControlName="stud_type" [label]="fieldLabels.stud_type" [items]="studTypeLabels" [allowClearingValue]="true"></app-select>
                    </div>
                    <ng-container *ngIf="roofTypeFormGroup.controls.stud_type.value">
                        <div>
                            <app-input formControlName="gypsum_thickness" type="number" [label]="fieldLabels.gypsum_thickness" unit="{{lengthLabels[entity?.units]}}"></app-input>
                        </div>
                        <div><h5>Cavity insulation</h5></div>
                        <div>
                            <app-select formControlName="cavity_insulation_material" label="Material"
                                [items]="wallCavityInsulationTypeLabels"></app-select>
                            <app-input formControlName="cavity_insulation_r_value" type="expression" label="R-value"></app-input>
                        </div>
                        <div>
                            <app-input formControlName="cavity_insulation_thickness" type="number" label="Thickness" unit="{{lengthLabels[entity?.units]}}"></app-input>
                            <app-select formControlName="cavity_insulation_grade" label="Grade" [items]="insulationGrade"></app-select>
                        </div>
                        <br>
                        <div>
                            <h5>Framing</h5>
                        </div>
                        <div>
                            <app-input formControlName="frame_width" type="number" label="Width" unit="{{lengthLabels[entity?.units]}}"></app-input>
                            <app-input formControlName="frame_depth" type="number" label="Depth" unit="{{lengthLabels[entity?.units]}}"></app-input>
                            <app-input formControlName="frame_spacing" type="number" label="Spacing" unit="{{lengthLabels[entity?.units]}}"></app-input>
                        </div>
                        <div>
                            <app-input formControlName="framing_factor" type="number" [label]="fieldLabels.framing_factor"></app-input>
                            <app-checkbox formControlName="use_default_framing_factor"
                                [label]="fieldLabels.use_default_framing_factor"></app-checkbox>
                        </div>
                    </ng-container>
                    <br>
                    <div>
                        <app-checkbox formControlName="has_radiant_barrier" label="Has Radiant Barrier"></app-checkbox>
                        <app-input formControlName="radiant_barrier_grade" type="number" [label]="fieldLabels.radiant_barrier_grade"
                            *ngIf="roofTypeFormGroup.controls.has_radiant_barrier.value"></app-input>
                    </div>
                    <div>
                        <app-checkbox formControlName="has_sub_tile_ventilation" label="Has Sub Tile Ventilation"></app-checkbox>
                        <app-checkbox formControlName="has_clay_or_concrete_tiles" label="Has Clay or Concrete Tiles"></app-checkbox>
                    </div>
                </app-form>
            </app-assembly-editor>
        </mat-card-content>
    </mat-card>
</div>
