import { UserInfo } from '@/data/core/models/user';

export enum HistoryType {
  created = '+',
  changed = '~',
  deleted = '-',
}

export const HistoryTypeLabelMapping: Record<HistoryType, string> = {
  [HistoryType.created]: 'Created',
  [HistoryType.changed]: 'Changed',
  [HistoryType.deleted]: 'Deleted',
};

export class HistoryChangesDiff {
  field_name?: string;
  old?: string;
  new?: string;
}

export class History {
  history_id?: number;
  history_datetime?: Date;
  history_type?: HistoryType;
  history_user?: number;
  history_user_info?: UserInfo;
  changes?: HistoryChangesDiff[];
}
