import { City } from '@/data/geographic/models';
import { CommunityInfo } from '@/data/community/models';
import { CompanyInfo } from '@/data/company/models';

export class SubdivisionInfo {
  id: number;
  name: string;

  builder_org?: number;
  builder_org_info?: CompanyInfo;
}

export class Subdivision {
  id?: number;
  name?: string;
  city_info?: City;
  city?: number;
  builder_org?: number;
  builder_org_info?: CompanyInfo;
  community?: null;
  community_info?: CommunityInfo;
}
