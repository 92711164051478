import { Component, Input, OnInit } from '@angular/core';
import { EEPProgram, EEPProgramInfo } from '@/data/eep-program/models';

export enum EEEPProgramDisplayComponentType {
  nameAsLink = 'nameAsLink',
}

@Component({
  selector: 'app-eep-program-display',
  templateUrl: './eep-program-display.component.html',
  styleUrls: ['./eep-program-display.component.scss'],
})
export class EEPProgramDisplayComponent implements OnInit {
  @Input() displayType:
    | EEEPProgramDisplayComponentType
    | keyof typeof EEEPProgramDisplayComponentType =
    EEEPProgramDisplayComponentType.nameAsLink;
  @Input() eepProgram?: EEPProgram | EEPProgramInfo;

  public EEEPProgramDisplayComponentType = EEEPProgramDisplayComponentType;

  ngOnInit(): void {}
}
