import { addNewFoundationWall } from '@/modules/simulation/state/simulation/actions';
import { selectFoundationWallIds } from '@/modules/simulation/state/simulation/selectors';
import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-foundation-walls-form',
  templateUrl: './foundation-walls-form.component.html',
  styleUrls: ['./foundation-walls-form.component.scss'],
})
export class FoundationWallsFormComponent {
  @Input() expanded = false;
  componentDestroyed$ = new Subject();
  foundationWallIds: number[] = [];

  constructor(public store: Store) {
    this.store
      .select(selectFoundationWallIds())
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(ids => {
        this.foundationWallIds = ids;
      });
  }

  addFoundationWall() {
    this.store.dispatch(addNewFoundationWall());
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
