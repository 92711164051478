import {
  BaseModelWithAssemblyFormGroup,
  RoofAssemblyCalculator,
  WallAssemblyCalculator,
} from '@/data/simulation/models/enclosure/AssemblyCalculator';
import {
  Layer,
  LayerBackendDict,
  LayerFrameType,
} from '@/data/simulation/models/enclosure/AssemblyLayer';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-assembly-editor',
  templateUrl: './assembly-editor.component.html',
  styleUrls: ['./assembly-editor.component.scss'],
})
export class AssemblyEditorComponent implements OnInit {
  @Input() form: BaseModelWithAssemblyFormGroup;
  @Output() systemUValue = new EventEmitter<number>();

  assemblyDataFormat = new FormControl('classic');
  switchingInProgress = false;
  unableToCalculateRValue = false;
  componentDestroyed$ = new EventEmitter();

  @Output() save = new EventEmitter();

  constructor() {}

  onSave(e: Event) {
    this.save.emit();
  }

  calculateRvalue(value: LayerBackendDict[]) {
    const calc =
      this.form.frameType === LayerFrameType.ROOF
        ? new RoofAssemblyCalculator(value)
        : new WallAssemblyCalculator(value);
    calc.calculate();
    if (calc.assembly_u_value) {
      this.systemUValue.emit(calc.assembly_u_value);
      this.unableToCalculateRValue = false;
    } else {
      this.unableToCalculateRValue = true;
    }
  }

  classicFormUpdate(value) {
    if (value.layers.length === 0) {
      const layers = this.form.convertClassicFormToLayer();
      this.calculateRvalue(layers);

      if (value.use_default_framing_factor) {
        let ff = null;
        if (this.form.frameType === LayerFrameType.FRAME_FLOOR) {
          ff = Layer.getDefaultFramingFraction(
            this.form.frameType,
            value.joist_type,
            value.joist_width,
            value.joist_spacing
          );
        } else {
          ff = Layer.getDefaultFramingFraction(
            this.form.frameType,
            value.stud_type,
            value.frame_width,
            value.frame_spacing
          );
        }
        this.form.controls.framing_factor.patchValue(ff, {
          emitEvent: false,
        });
        this.form.controls.framing_factor.disable({
          emitEvent: false,
        });
      } else {
        this.form.controls.framing_factor.enable({
          emitEvent: false,
        });
      }
    }
  }

  ngOnInit(): void {
    this.assemblyDataFormat.setValue(
      (this.form.controls.layers.value as LayerBackendDict[]).length === 0
        ? 'classic'
        : 'layers'
    );

    this.assemblyDataFormat.valueChanges.subscribe(value => {
      this.assemblyDataFormat.disable({ emitEvent: false });
      this.switchingInProgress = true;
    });

    this.classicFormUpdate(this.form.value);

    this.form.controls.layers.valueChanges
      .pipe(takeUntil(this.componentDestroyed$), debounceTime(30))
      .subscribe(value => this.calculateRvalue(value));

    this.form.valueChanges
      .pipe(takeUntil(this.componentDestroyed$), debounceTime(30))
      .subscribe(value => {
        if (this.assemblyDataFormat.value === 'classic') {
          this.classicFormUpdate(this.form.value);
        }
      });
  }

  switchToFormat() {
    if (this.assemblyDataFormat.value === 'layers') {
      this.form.controls.layers.patchValue(
        this.form.convertClassicFormToLayer(),
        { emitEvent: false }
      );
    } else {
      this.form.controls.layers.patchValue([], { emitEvent: false });
    }
    this.assemblyDataFormat.enable({ emitEvent: false });
    this.switchingInProgress = false;
    this.save.emit();
  }

  undoSwitch() {
    this.assemblyDataFormat.setValue(
      this.assemblyDataFormat.value === 'classic' ? 'layers' : 'classic'
    );
    this.assemblyDataFormat.enable({ emitEvent: false });
    this.switchingInProgress = false;
  }

  ngOnDestroy() {
    this.componentDestroyed$.complete();
  }
}
