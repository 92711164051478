import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin, of, Subject } from 'rxjs';
import { User } from '@/data/core/models/user';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, takeUntil } from 'rxjs/operators';
import { toggleLoading } from '@/state/actions/app.actions';
import { ConfirmedGeocodeResponse } from '@/modules/geocoder/components/confirm-geocode-response-dialog/confirm-geocode-response';
import { ClientAgreement } from '@/data/customer-hirl/models';
import { ClientAgreementService } from '@/data/customer-hirl/services/client-agreement.service';
import { CustomerHIRLSettings } from '../../constants';
import { formatDate } from '@angular/common';
import { GeocoderService } from '@/data/geocoder/services/geocoder.service';
import { AppFieldValidatorsService } from '@/core/services/field-validators.service';
import { conditionalValidator } from '@/core/validators';
import { IGeocodeMatches } from '@/data/geocoder/models';

export class ClientAgreementChangeDialogData {
  clientAgreement?: ClientAgreement;
  commit = true;
  retrieve = true;
}

@Component({
  selector: 'app-hirl-client-agreement-change-dialog',
  templateUrl: './hirl-client-agreement-change-dialog.component.html',
  styleUrls: ['./hirl-client-agreement-change-dialog.component.scss'],
})
export class HirlClientAgreementChangeDialogComponent
  implements OnInit, OnDestroy
{
  protected readonly CustomerHIRLSettings = CustomerHIRLSettings;

  public initialized: boolean;
  public isEditForm = false;
  public entity?: ClientAgreement;
  public commit = true;
  public retrieve = true;
  public form: FormGroup;
  public currentUser: User;

  private componentDestroyed$: Subject<boolean> = new Subject();

  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<HirlClientAgreementChangeDialogComponent>,
    private fb: FormBuilder,
    private clientAgreementService: ClientAgreementService,
    private geocoderService: GeocoderService,
    private dialog: MatDialog,
    private appFieldValidatorsService: AppFieldValidatorsService,
    private uiHelperService: UIHelperService,
    @Inject(MAT_DIALOG_DATA) public data: ClientAgreementChangeDialogData
  ) {
    if (data?.clientAgreement) {
      this.entity = Object.assign({}, data?.clientAgreement);
    }
    this.commit = data?.commit || true;

    if (this.entity?.id) {
      this.isEditForm = true;
    }
  }

  ngOnInit() {
    const sources = {
      currentUser: this.store.select(getInfoUser).pipe(first()),
      clientAgreement: of(this.entity),
    };

    this.store.dispatch(toggleLoading({ payload: true }));

    if (this.isEditForm && this.retrieve) {
      sources.clientAgreement = this.clientAgreementService
        .retrieve(this.entity.id, { ignoreStore: true })
        .pipe(takeUntil(this.componentDestroyed$), first());
    }

    forkJoin(sources)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: ({ currentUser, clientAgreement }) => {
          this.store.dispatch(toggleLoading({ payload: false }));

          this.currentUser = currentUser;
          this.entity = clientAgreement;
          this.setupForm();
          this.initialized = true;
          if (this.isEditForm) {
            this.hydrateForm();
          } else {
            this.initialValues();
          }
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  private setupForm() {
    this.form = this.fb.group({
      agreement_start_date: [null],
      agreement_expiration_date: [null],

      mailing_address_geocode: [null, Validators.required],
      shipping_address_geocode: [null],

      use_payment_contact_in_ngbs_green_projects: [false],
      website: [
        '',
        Validators.compose([
          this.appFieldValidatorsService.websiteURLValidator(),
        ]),
      ],

      primary_contact_first_name: [
        null,
        [conditionalValidator(() => !this.isEditForm, Validators.required)],
      ],
      primary_contact_last_name: [
        null,
        [conditionalValidator(() => !this.isEditForm, Validators.required)],
      ],
      primary_contact_email_address: [
        null,
        [
          Validators.email,
          conditionalValidator(() => !this.isEditForm, Validators.required),
        ],
      ],
      primary_contact_phone_number: [
        null,
        [conditionalValidator(() => !this.isEditForm, Validators.required)],
      ],
      primary_contact_title: [
        null,
        [conditionalValidator(() => !this.isEditForm, Validators.required)],
      ],
      primary_contact_cell_number: [''],

      secondary_contact_first_name: [''],
      secondary_contact_last_name: [''],
      secondary_contact_email_address: ['', [Validators.email]],
      secondary_contact_phone_number: [''],
      secondary_contact_title: [''],
      secondary_contact_cell_number: [''],

      payment_contact_first_name: [''],
      payment_contact_last_name: [''],
      payment_contact_email_address: ['', [Validators.email]],
      payment_contact_phone_number: [''],
      payment_contact_title: [''],
      payment_contact_cell_number: [''],

      marketing_contact_first_name: [
        null,
        [conditionalValidator(() => !this.isEditForm, Validators.required)],
      ],
      marketing_contact_last_name: [
        null,
        [conditionalValidator(() => !this.isEditForm, Validators.required)],
      ],
      marketing_contact_email_address: [
        null,
        [
          Validators.email,
          conditionalValidator(() => !this.isEditForm, Validators.required),
        ],
      ],
      marketing_contact_phone_number: [
        null,
        [conditionalValidator(() => !this.isEditForm, Validators.required)],
      ],
      marketing_contact_title: [
        null,
        [conditionalValidator(() => !this.isEditForm, Validators.required)],
      ],
      marketing_contact_cell_number: [''],

      website_contact_first_name: [
        null,
        [conditionalValidator(() => !this.isEditForm, Validators.required)],
      ],
      website_contact_last_name: [
        null,
        [conditionalValidator(() => !this.isEditForm, Validators.required)],
      ],
      website_contact_email_address: [
        null,
        [
          Validators.email,
          conditionalValidator(() => !this.isEditForm, Validators.required),
        ],
      ],
      website_contact_phone_number: [
        null,
        [conditionalValidator(() => !this.isEditForm, Validators.required)],
      ],
      website_contact_title: [
        null,
        [conditionalValidator(() => !this.isEditForm, Validators.required)],
      ],
      website_contact_cell_number: [''],
    });

    this.form
      .get('mailing_address_geocode')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe(value => {
        if (!this.form.value.shipping_geocode) {
          this.form.patchValue({
            shipping_address_geocode: value,
          });
        }
      });
  }

  hydrateForm() {
    this.form.patchValue(Object.assign({}, this.entity), {
      emitEvent: false,
      onlySelf: true,
    });

    this.form.patchValue(
      {
        mailing_address_geocode: new ConfirmedGeocodeResponse({
          geocode: this.entity.mailing_geocode_info,
          geocodeBrokeredResponse: this.entity.mailing_geocode_response_info,
        }),
      },
      { emitEvent: false, onlySelf: true }
    );

    this.form.patchValue(
      {
        shipping_address_geocode: new ConfirmedGeocodeResponse({
          geocode: this.entity.shipping_geocode_info,
          geocodeBrokeredResponse: this.entity.shipping_geocode_response_info,
        }),
      },
      { emitEvent: false, onlySelf: true }
    );
  }
  private initialValues() {
    // Company address do not have geocode field. We create it manually
    this.form.patchValue(
      {
        primary_contact_first_name: this.currentUser.first_name,
        primary_contact_last_name: this.currentUser.last_name,
        primary_contact_email_address: this.currentUser.email,
        primary_contact_phone_number: this.currentUser.work_phone,
        primary_contact_cell_number: this.currentUser.cell_phone || '',
        primary_contact_title: this.currentUser.title,
      },
      {
        emitEvent: false,
        onlySelf: true,
      }
    );

    this.geocoderService
      .matches({
        street_line1: this.currentUser.company_info.street_line1,
        street_line2: this.currentUser.company_info.street_line2 || '',
        city: this.currentUser.company_info.city,
        zipcode: this.currentUser.company_info.zipcode,
      } as IGeocodeMatches)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(mailingGeocode => {
        const mailingConfirmedGeocodeResponse: ConfirmedGeocodeResponse =
          new ConfirmedGeocodeResponse({
            geocode: mailingGeocode,
            geocodeBrokeredResponse: null,
          });
        this.form.patchValue({
          mailing_address_geocode: mailingConfirmedGeocodeResponse,
        });

        if (this.currentUser.company_info?.shipping_geocode) {
          this.form.patchValue({
            shipping_address_geocode: new ConfirmedGeocodeResponse({
              geocode: this.currentUser.company_info?.shipping_geocode_info,
              geocodeBrokeredResponse:
                this.currentUser.company_info?.shipping_geocode_response_info,
            }),
          });
        } else {
          this.form.patchValue({
            shipping_address_geocode: mailingConfirmedGeocodeResponse,
          });
        }
      });
  }

  save($event: MouseEvent) {
    $event.preventDefault();
    if (this.isEditForm) {
      this.edit();
    } else {
      this.create();
    }
  }

  private getFormData(): ClientAgreement {
    const data: ClientAgreement = new ClientAgreement();

    if (this.form.value.agreement_start_date) {
      data.agreement_start_date = `${formatDate(
        this.form.value.agreement_start_date,
        'yyyy-MM-dd',
        'en-US'
      )}`;
    } else {
      data.agreement_start_date = null;
    }

    if (this.form.value.agreement_expiration_date) {
      data.agreement_expiration_date = `${formatDate(
        this.form.value.agreement_expiration_date,
        'yyyy-MM-dd',
        'en-US'
      )}`;
    } else {
      data.agreement_expiration_date = null;
    }

    data.primary_contact_first_name =
      this.form.value.primary_contact_first_name;
    data.primary_contact_last_name = this.form.value.primary_contact_last_name;
    data.primary_contact_title = this.form.value.primary_contact_title;
    data.primary_contact_email_address =
      this.form.value.primary_contact_email_address;
    data.primary_contact_phone_number =
      this.form.value.primary_contact_phone_number;
    data.primary_contact_cell_number =
      this.form.value.primary_contact_cell_number;

    data.secondary_contact_first_name =
      this.form.value.secondary_contact_first_name;
    data.secondary_contact_last_name =
      this.form.value.secondary_contact_last_name;
    data.secondary_contact_title = this.form.value.secondary_contact_title;
    data.secondary_contact_email_address =
      this.form.value.secondary_contact_email_address;
    data.secondary_contact_phone_number =
      this.form.value.secondary_contact_phone_number;
    data.secondary_contact_cell_number =
      this.form.value.secondary_contact_cell_number;

    data.payment_contact_first_name =
      this.form.value.payment_contact_first_name;
    data.payment_contact_last_name = this.form.value.payment_contact_last_name;
    data.payment_contact_title = this.form.value.payment_contact_title;
    data.payment_contact_phone_number =
      this.form.value.payment_contact_phone_number;
    data.payment_contact_cell_number =
      this.form.value.payment_contact_cell_number;
    data.payment_contact_email_address =
      this.form.value.payment_contact_email_address;
    data.use_payment_contact_in_ngbs_green_projects =
      this.form.value.use_payment_contact_in_ngbs_green_projects;

    data.marketing_contact_first_name =
      this.form.value.marketing_contact_first_name;
    data.marketing_contact_last_name =
      this.form.value.marketing_contact_last_name;
    data.marketing_contact_title = this.form.value.marketing_contact_title;
    data.marketing_contact_phone_number =
      this.form.value.marketing_contact_phone_number;
    data.marketing_contact_cell_number =
      this.form.value.marketing_contact_cell_number;
    data.marketing_contact_email_address =
      this.form.value.marketing_contact_email_address;

    data.website_contact_first_name =
      this.form.value.website_contact_first_name;
    data.website_contact_last_name = this.form.value.website_contact_last_name;
    data.website_contact_title = this.form.value.website_contact_title;
    data.website_contact_phone_number =
      this.form.value.website_contact_phone_number;
    data.website_contact_cell_number =
      this.form.value.website_contact_cell_number;
    data.website_contact_email_address =
      this.form.value.website_contact_email_address;

    data.mailing_geocode = this.form.value.mailing_address_geocode?.geocode?.id;
    data.mailing_geocode_response = null;

    if (this.form.value.mailing_address_geocode?.geocodeBrokeredResponse) {
      data.mailing_geocode_response =
        this.form.value.mailing_address_geocode?.geocodeBrokeredResponse?.id;
    }

    data.shipping_geocode =
      this.form.value.shipping_address_geocode?.geocode?.id;
    data.shipping_geocode_response = null;

    if (this.form.value.shipping_address_geocode?.geocodeBrokeredResponse) {
      data.shipping_geocode_response =
        this.form.value.shipping_address_geocode?.geocodeBrokeredResponse?.id;
    }

    return data;
  }

  private create() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    if (!this.commit) {
      this.dialogRef.close(this.entity);
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const data: ClientAgreement = this.getFormData();
    this.clientAgreementService
      .create(data)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: clientAgreement => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close(clientAgreement);
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  private edit() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    if (!this.commit) {
      this.dialogRef.close(this.entity);
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const data: ClientAgreement = this.getFormData();

    this.clientAgreementService
      .update(data, this.entity.id)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: clientAgreement => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close(clientAgreement);
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }
}
