<h2 mat-dialog-title>
  <ng-container *ngIf="!isEditForm">
    Create New <b>Company</b>
  </ng-container>
  <ng-container *ngIf="isEditForm">
    Edit <b>Company</b>
  </ng-container>
</h2>
<mat-dialog-content *ngIf="initialized">
  <form [formGroup]="form" class="mb-2">
    <ng-container *ngIf="checkedForExistingCompany; else existingCompanySelection;">
      <div class="row">
        <div class="col-12">
          <mat-form-field appearance="outline" class="mb-2 w-100">
            <mat-label>Company Name</mat-label>
            <input matInput type="text" placeholder="Name" formControlName="name" required>
            <mat-error *ngIf="form.get('name').hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mat-form-field appearance="outline" class="w-100 mb-2">
            <mat-label>Address</mat-label>
            <app-address-input placeholder="Address" formControlName="address" required
                               [show-clear-btn]="true"></app-address-input>
            <mat-error
                *ngIf="form.get('address').hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <mat-form-field appearance="outline" class="w-100 mb-2">
            <mat-label>Phone</mat-label>
            <input matInput placeholder="Phone"
                   formControlName="office_phone"
                   required mask="000-000-0000" [showMaskTyped]="true">
            <mat-error *ngIf="form.get('office_phone').hasError('required')">
              Phone is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="outline" class="w-100 mb-2">
            <mat-label>Email</mat-label>
            <input matInput placeholder="Email"
                   formControlName="default_email"
                   required>
            <mat-error *ngIf="form.get('default_email').hasError('required')">
              Email is required
            </mat-error>
            <mat-error *ngIf="form.get('default_email').hasError('email')">
              Email Address is invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </ng-container>
    <ng-template #existingCompanySelection>
      <div class="p-2">
        <div class="row">
          <div class="col-12">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Company Name</mat-label>
              <input matInput placeholder="Type New Company Name or Select existing" [formControl]="existingCompanyControl"
                     [matAutocomplete]="existingCompanyAutocomplete">
              <mat-autocomplete #existingCompanyAutocomplete="matAutocomplete" autoActiveFirstOption [displayWith]="displayExistingCompanyAutocomplete" (optionSelected)='onCompanySelected($event)'>
                <mat-option
                    *ngFor="let company of (existingCompanies$ | async)?.results"
                    [value]="company">
                  <app-company-display [company]="company" displayType="nameAndAddress"></app-company-display>
                </mat-option>
                <mat-option [value]="newCompanyName">
                  <b>Create New ...</b>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
      </div>
    </ng-template>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button color="accent" mat-raised-button (click)="save($event)">Save</button>
  <button mat-button [mat-dialog-close]="null" color="primary" mat-raised-button>Cancel</button>
</mat-dialog-actions>
