import { AppState } from '../reducers';
import { createSelector } from '@ngrx/store';

export const selectInfoState = (state: AppState) => state.info;
export const getInfo = createSelector(selectInfoState, store => store.info);
export const getInfoUser = createSelector(
  selectInfoState,
  store =>
    (store.info && store.info.impersonated) || (store.info && store.info.user)
);
export const getInfoImpersonator = createSelector(selectInfoState, store => {
  if (store.info && store.info.impersonated && store.info.user) {
    return store.info.user;
  }
  return null;
});
export const getImpersonations = createSelector(
  selectInfoState,
  store => store.previousImpersonations
);
