export class GRESBIndicator {
  id?: number;
  name: string;
  slug: string;
}

export class GRESBIndicatorInfo {
  id?: number;
  name: string;
  slug: string;
}
