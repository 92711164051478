import { ListRequestParams } from '@/core/schemes/request-params';
import { Injectable } from '@angular/core';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { ServerResponse } from '@/core/schemes/server-response';
import { CompanyAccess } from '@/data/company/models/';

export class CompanyAccessRequestParams extends ListRequestParams {
  user?: number;
  roles?: number[];
  company?: number | number[];
  user__is_active?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CompanyAccessService extends BaseDataService {
  baseUrl = `${environment.AXIS_API_V3_ROOT}/company_accesses/`;

  list(
    params?: CompanyAccessRequestParams
  ): Observable<ServerResponse<CompanyAccess>> {
    return this.http.get<ServerResponse<CompanyAccess>>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }

  retrieve(
    companyAccessId: number,
    options?: IDataServiceMethodOptions
  ): Observable<CompanyAccess> {
    const ignoreStore = options?.ignoreStore;
    const chain = this.http.get<CompanyAccess>(
      `${this.baseUrl}${companyAccessId}`
    );
    return chain;
  }

  create(companyAccess: CompanyAccess): Observable<CompanyAccess> {
    return this.http.post<CompanyAccess>(`${this.baseUrl}`, companyAccess);
  }

  update(
    companyAccessId: number,
    companyAccess: CompanyAccess
  ): Observable<CompanyAccess> {
    return this.http.patch<CompanyAccess>(
      `${this.baseUrl}${companyAccessId}/`,
      companyAccess
    );
  }
}
