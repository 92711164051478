<mat-expansion-panel *ngIf="enable && (totalErrorCount > 0 || totalWarningCount > 0)">
    <mat-expansion-panel-header class="example-headers-align">
        <mat-panel-title>
            <div class="title-container">
                <app-model-link entityName="roof" [entityId]="entityId" [title]="name">
                </app-model-link>
                <div class="d-flex align-items-center">
                    <app-error-count
                        [errorCount]="totalErrorCount"
                        [warningCount]="totalWarningCount">
                    </app-error-count>
                </div>
            </div>
        </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-accordion class="example-headers-align">
        <app-model-errors-tree [entityId]="entityId" entityName="roof"></app-model-errors-tree>
        <mat-expansion-panel *ngIf="roofTypeId && (roofTypeErrorCount > 0 || roofTypeWarningCount > 0)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="title-container">
                        Roof type
                        <app-error-count
                            [errorCount]="roofTypeErrorCount"
                            [warningCount]="roofTypeWarningCount">
                        </app-error-count>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-model-errors-tree [entityId]="roofTypeId" entityName="roofType">
                </app-model-errors-tree>
            </ng-template>
        </mat-expansion-panel>
    </mat-accordion>
</mat-expansion-panel>