import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { CompanyAccess } from '@/data/company/models';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { CompanyAccessService } from '@/data/company/services/company-access.service';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { first } from 'rxjs/operators';
import { toggleLoading } from '@/state/actions/app.actions';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';

export class ChangeCompanyAccessDialogData {
  companyAccess?: CompanyAccess;
  commit = true;
}

@Component({
  selector: 'app-change-company-access-dialog',
  templateUrl: './change-company-access-dialog.component.html',
  styleUrls: ['./change-company-access-dialog.component.scss'],
})
export class ChangeCompanyAccessDialogComponent implements OnInit, OnDestroy {
  public isEditForm = false;
  public entity?: CompanyAccess;
  public commit = true;
  public form: FormGroup;

  private componentDestroyed$ = new Subject();
  constructor(
    private dialogRef: MatDialogRef<ChangeCompanyAccessDialogComponent>,
    private fb: FormBuilder,
    private store: Store<AppState>,
    private companyAccessService: CompanyAccessService,
    private dialog: MatDialog,
    private uiHelperService: UIHelperService,
    @Inject(MAT_DIALOG_DATA) public data: ChangeCompanyAccessDialogData
  ) {
    if (data?.companyAccess) {
      this.entity = Object.assign({}, data?.companyAccess);
    }
    this.commit = data?.commit || true;

    if (this.entity?.id) {
      this.isEditForm = true;
    }

    this.form = this.fb.group({
      roles: [null],
    });
  }

  ngOnInit() {
    if (this.isEditForm) {
      this.hydrateForm();
    }
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  hydrateForm() {
    this.form.get('roles').patchValue(this.entity.roles_info, {
      emitEvent: false,
      onlySelf: true,
    });
  }

  save($event: MouseEvent) {
    $event.preventDefault();
    if (this.isEditForm) {
      this.edit();
    } else {
      this.create();
    }
  }

  private create() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    if (!this.commit) {
      this.dialogRef.close(this.entity);
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const data = {
      roles: this.form.value.roles?.map(role => role.id) || [],
    } as CompanyAccess;

    this.companyAccessService
      .create(data)
      .pipe(first())
      .subscribe({
        next: companyAccess => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close(companyAccess);
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  private edit() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    if (!this.commit) {
      this.dialogRef.close(this.entity);
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const data = {
      roles: this.form.value.roles?.map(role => role.id) || [],
    } as CompanyAccess;

    this.companyAccessService
      .update(this.entity.id, data)
      .pipe(first())
      .subscribe({
        next: companyAccess => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close(companyAccess);
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }
}
