import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import {
  PaymentRequestProduct,
  PaymentRequestStateLabelMapping,
} from '@/data/customer-hirl/models';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, Subject, Subscription } from 'rxjs';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import {
  PaymentRequestProductRequestParams,
  PaymentRequestProductService,
} from '@/data/customer-hirl/services/payment-request-product.service';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { MatDialog } from '@angular/material/dialog';
import { PaymentRequestProductPermissionService } from '@/modules/customer-hirl/services/payment-request-product-permission.service';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { cloneDeep, merge } from 'lodash';
import { filter, takeUntil } from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Product } from '@/data/product/models';
import {
  getProduct,
  getProductPaymentRequests,
  getProductPaymentRequestsCount,
} from '@/state/selectors/product/product.selector';
import { ProductService } from '@/data/product/services/product.service';

@Component({
  selector: 'app-product-payment-requests-tab',
  templateUrl: './product-payment-requests-tab.component.html',
  styleUrls: ['./product-payment-requests-tab.component.scss'],
})
export class ProductPaymentRequestsTabComponent implements OnInit, OnDestroy {
  public product: Product;
  public displayedColumns = ['id', 'state', 'invoice'];
  public filterFromGroup: FormGroup;
  public isLoading = false;
  public rows: PaymentRequestProduct[] = [];

  public permissions: {
    [key: string]: {
      canEdit: Observable<ObjectPermissionResponse>;
      canDelete: Observable<ObjectPermissionResponse>;
    };
  } = {};
  public rowsCount: number;

  public defaultParams: PaymentRequestProductRequestParams =
    new PaymentRequestProductRequestParams(1, '', '-id');

  public storedParams: PaymentRequestProductRequestParams = Object.assign(
    new PaymentRequestProductRequestParams(),
    this.defaultParams
  );

  private listSubscription$: Subscription;
  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private injector: Injector,
    private dialog: MatDialog,
    private productService: ProductService,
    private paymentRequestProductService: PaymentRequestProductService,
    private paymentRequestProductPermissionService: PaymentRequestProductPermissionService,
    private fb: FormBuilder,
    private uiHelperService: UIHelperService
  ) {}

  ngOnInit(): void {
    this.storedParams = cloneDeep(this.defaultParams);

    this.store
      .select(getProduct)
      .pipe(
        filter(val => val !== null),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((entity: Product) => {
        this.product = entity;
        this.list();
      });

    this.store
      .select(getProductPaymentRequests)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((paymentRequestProducts: PaymentRequestProduct[]) => {
        this.rows = paymentRequestProducts;
      });

    this.store
      .select(getProductPaymentRequestsCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((paymentRequestProductCount: number) => {
        this.rowsCount = paymentRequestProductCount;
      });

    this.setupFilterForm();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  setupFilterForm() {
    this.filterFromGroup = this.fb.group({
      search: [null],
    });

    this.filterFromGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        const params = merge({}, this.defaultParams, values);
        delete params.ordering;

        this.storedParams = merge(this.storedParams, params);
        this.list();
      });
  }

  list() {
    this.isLoading = true;

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }

    this.listSubscription$ = this.productService
      .payment_requests(this.product.id, this.storedParams)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.rows = data.results;
        this.rowsCount = data.count;
        this.isLoading = false;
        this.rows.forEach((row: PaymentRequestProduct) => {
          this.permissions[row.id] = {
            canEdit: this.paymentRequestProductPermissionService.canEdit(row),
            canDelete:
              this.paymentRequestProductPermissionService.canDelete(row),
          };
        });
      });
  }

  onPaginateChange($event: PageEvent) {
    this.uiHelperService.getUISettings().rowsPerPage = $event.pageSize;
    this.storedParams.page_size = $event.pageSize;
    this.storedParams.page = $event.pageIndex + 1;
    this.list();
  }

  onSortChange($event: Sort) {
    this.storedParams.ordering = `${$event.direction === 'desc' ? '-' : ''}${
      $event.active
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  protected readonly PaymentRequestStateLabelMapping =
    PaymentRequestStateLabelMapping;
}
