<div *ngIf="initialized; else loading" class="container" style="padding-top:15px;">
    <div *ngIf="inValidSimulation; else simTree">
        <p>Simulation data does not exists</p>
    </div>

    <ng-template #simTree>
        <div class="card mb-3">
            <div class="card-header" style="font-weight: bold;">{{ compare.summary }}</div>
        </div>
        <div *ngIf="compare.error" class="card text-white bg-danger mb-3">
            <div class="card-body">
                <div class="alert alert-danger">
                    Cannot show diff: {{ compare.error }}
                </div>
            </div>
        </div>
        <div *ngIf="compare.errors.length" class="card text-white bg-danger mb-3">
            <div class="alert alert-danger">
                <div class="card-header">RemXML Differences (Errors)</div>
                <div class="card-body">
                    <ul>
                        <li *ngFor="let data of compare.errors">{{ data}}</li>
                    </ul>
                </div>
            </div>
        </div>
        <div *ngIf="compare.warnings.length" class="card bg-warning mb-3">
            <div class="card-content">
                <div class="alert alert-warning">
                    <div class="card-header">RemXML Differences (Warnings <i>text differences</i>)</div>
                    <div class="card-body">
                    <ul>
                        <li *ngFor="let data of compare.warnings">{{ data }}</li>
                    </ul>
                </div>
                </div>
            </div>
        </div>
        <div *ngIf="compare.ignored.length" class="card bg-info mb-3">
            <div class="card-content">
                <div class="alert alert-info">
                    <div class="card-header">RemXML Differences (Ignored <i>text differences</i>)</div>
                    <div class="card-body">
                    <ul>
                        <li *ngFor="let data of compare.ignored">{{ data }}</li>
                    </ul>
                </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>

<ng-template #loading>
    <div class="row">
        <div class="col-12">
            <div class="d-flex justify-content-center" style="height: 320px;">
                <mat-spinner [diameter]="50"></mat-spinner>
            </div>
        </div>
    </div>
</ng-template>