import {
  Component,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { filter, first, takeUntil } from 'rxjs/operators';

import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import {
  getCompany,
  getCompanyCOIDocumentsCount,
  getCompanyDocumentsCount,
  selectCompanyEquipmentsCount,
  selectCompanyCompanyAccessesCount,
  selectCompanyContactCardsCount,
  selectCompanyRemRateUsersCount,
  selectCompanyNotesCount,
} from '@/state/selectors/company/company.selector';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import {
  ComponentCompanyType,
  componentCompanyTypeDataMap,
} from '@/modules/company/constants';
import { Observable, Subject } from 'rxjs';
import { CompanyService } from '@/data/company/services/company-base.service';
import { Company, CompanyType } from '@/data/company/models';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { toggleLoading } from '@/state/actions/app.actions';
import { Geocode } from '@/data/geocoder/models';
import { ConfirmedGeocodeResponse } from '@/modules/geocoder/components/confirm-geocode-response-dialog/confirm-geocode-response';
import { MatDialog } from '@angular/material/dialog';
import {
  CompanyChangeDialogComponent,
  CompanyChangeDialogData,
} from '@/modules/company/components/company-change-dialog/company-change-dialog.component';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { resetCompany } from '@/state/actions/company/company.actions';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { CompanyPermissionService } from '@/modules/company/services/company-permission-service';

@Component({
  selector: 'app-company-detail-page',
  templateUrl: './company-detail-page.component.html',
  styleUrls: ['./company-detail-page.component.scss'],
})
export class CompanyDetailPageComponent implements OnInit, OnDestroy {
  protected readonly CompanyType = CompanyType;

  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) mapInfoWindow: MapInfoWindow;
  initialized = false;

  selectedIndex = 0;
  companyDocumentsCount = -1; // means that is not loading yet
  companyContactsCount = -1;
  companyAccessesCount = -1;
  companyCOICount = -1;
  companyEquipmentCount = -1;
  companyNotesCount = -1;
  companyRemrateUsersCount = -1;

  companyType = CompanyType;
  componentCompanyType: ComponentCompanyType;
  company: Company;
  companyDisplayName: string;

  public editPermissionResponse: Observable<ObjectPermissionResponse>;
  public deletePermissionResponse: Observable<ObjectPermissionResponse>;

  // utility company type specific attrs
  electricityProvider: boolean;
  gasProvider: boolean;
  waterProvider: boolean;

  confirmedGeocodeResponse: ConfirmedGeocodeResponse;
  showAdditionalLocationStatistics = false;

  public mapCenter: google.maps.LatLngLiteral;
  public mapOptions: google.maps.MapOptions = {
    disableDoubleClickZoom: true,
  };
  public mapMarkers = [];
  public mapInfoContent = '';

  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    public injector: Injector,
    public dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private companyService: CompanyService,
    private companyPermissionService: CompanyPermissionService,
    public uiHelperService: UIHelperService
  ) {
    this.store
      .select(getCompany)
      .pipe(
        filter(val => val !== null),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(company => {
        this.company = company;
        this.companySetup();
      });

    this.store
      .select(getCompanyDocumentsCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(count => {
        this.companyDocumentsCount = count;
      });

    this.store
      .select(selectCompanyContactCardsCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(count => {
        this.companyContactsCount = count;
      });

    this.store
      .select(selectCompanyCompanyAccessesCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(count => {
        this.companyAccessesCount = count;
      });

    this.store
      .select(getCompanyCOIDocumentsCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(count => {
        this.companyCOICount = count;
      });

    this.store
      .select(selectCompanyEquipmentsCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(count => {
        this.companyEquipmentCount = count;
      });

    this.store
      .select(selectCompanyRemRateUsersCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(count => {
        this.companyRemrateUsersCount = count;
      });

    this.store
      .select(selectCompanyNotesCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(count => {
        this.companyNotesCount = count;
      });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(params => {
        if (params.tab) {
          this.selectedIndex = Number(params.tab) || 0;
        }
      });

    this.activatedRoute.params
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(params => {
        this.store.dispatch(toggleLoading({ payload: true }));
        this.companyService
          .retrieve(params.companyId)
          .pipe(takeUntil(this.componentDestroyed$), first())
          .subscribe(
            company => {
              this.company = company;
              this.companySetup();
              this.store.dispatch(toggleLoading({ payload: false }));
              this.initialized = true;
            },
            error => this.uiHelperService.handleMainRequestError(error)
          );
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
    this.store.dispatch(resetCompany());
  }

  companySetup() {
    this.companyDisplayName =
      componentCompanyTypeDataMap[this.company.company_type].displayName;

    const geocode = new Geocode();
    geocode.raw_street_line1 = this.company.street_line1;
    geocode.raw_street_line2 = this.company.street_line2;
    geocode.raw_city = this.company.city;
    geocode.raw_city_info = this.company.city_info;
    geocode.raw_zipcode = this.company.zipcode;
    this.confirmedGeocodeResponse = new ConfirmedGeocodeResponse({
      geocode: geocode,
      geocodeBrokeredResponse: this.company.geocode_response_info,
    });

    if (this.company.company_type === CompanyType.utility) {
      this.electricityProvider = this.company.electricity_provider;
      this.waterProvider = this.company.water_provider;
      this.gasProvider = this.company.gas_provider;
    } else {
      this.electricityProvider = false;
      this.waterProvider = false;
      this.gasProvider = false;
    }

    // map
    if (this.company.latitude && this.company.longitude) {
      this.mapCenter = {
        lat: this.company.latitude,
        lng: this.company.longitude,
      };
      this.mapMarkers.push({
        position: this.mapCenter,
        title: 'Geocoded Address',
        info: this.confirmedGeocodeResponse.displayAddress,
      });
    } else {
      this.mapCenter = null;
      this.mapMarkers = [];
    }

    this.editPermissionResponse = this.companyPermissionService.canEdit(
      this.company
    );
    this.deletePermissionResponse = this.companyPermissionService.canDelete(
      this.company
    );
  }

  onTabChange(event: MatTabChangeEvent) {
    const url = this.router
      .createUrlTree([], {
        relativeTo: this.activatedRoute,
        queryParams: { tab: event.index },
      })
      .toString();
    this.location.replaceState(url);
  }

  onDelete($event: MouseEvent) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '50%',
      data: {
        title: 'Do you want to delete this company ?',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (!result) {
          return;
        }
        this.store.dispatch(toggleLoading({ payload: true }));
        this.companyService
          .delete(this.company.id)
          .pipe(first())
          .subscribe(
            _ => {
              this.store.dispatch(toggleLoading({ payload: false }));
              this.router.navigate(['/', 'company', 'builder']);
            },
            error => this.uiHelperService.handleUserRequestError(error)
          );
      });
  }

  onEdit($event: MouseEvent) {
    $event.preventDefault();

    const dialogRef = this.dialog.open(CompanyChangeDialogComponent, {
      width: '55%',
      disableClose: true,
      autoFocus: false,
      data: {
        company: this.company,
      } as CompanyChangeDialogData,
    });

    dialogRef.afterClosed().subscribe((result?: Company) => {
      if (!result) {
        return;
      }
      this.uiHelperService.openSnackBar('Record Successfully Updated');
    });
  }

  onToggleAdditionalLocationStatistics($event: MouseEvent) {
    $event.preventDefault();
    this.showAdditionalLocationStatistics =
      !this.showAdditionalLocationStatistics;
  }

  mapOpenInfo(marker: MapMarker, content) {
    this.mapInfoContent = content;
    this.mapInfoWindow.open(marker);
  }
}
