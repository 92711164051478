<div class="container" *ngIf="initialized">
    <div class="row">
        <div class="col-12">
            <h5>
                <button mat-raised-button color="accent" appBackButton>
                    <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
                </button>
                Edit
                Land Development
                <b>Project {{ hirlProject.id }}</b>
            </h5>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <hr>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <form [formGroup]="editFormGroup">
                <div class="row" *ngIf="this.currentUser.company_info?.slug === customerHIRLSettings.companySlug">
                    <div class="col-12">
                        <mat-form-field appearance="outline" class="w-100 mb-2">
                            <mat-label>Registration</mat-label>
                            <app-generic-model-autocomplete modelClassName="hirl_project_registration" formControlName="registration_info" [panelWidth]="640" [initialValueId]="hirlProject.registration"></app-generic-model-autocomplete>
                            <mat-error
                                    *ngIf="editFormGroup.get('registration_info').hasError('required')">
                                This field is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline" class="w-100 mb-2" aria-readonly="true">
                            <mat-label>Address</mat-label>
                            <app-home-address-input placeholder="Address" formControlName="edit_address" required
                                                    [show-clear-btn]="true"></app-home-address-input>
                            <mat-error
                                    *ngIf="editFormGroup.controls['edit_address'].hasError('required')">
                                This field is required
                            </mat-error>
                            <mat-error
                                    *ngIf="editFormGroup.controls['edit_address'].hasError('hirlProjectAlreadyExists')">
                                Project with this address already exists
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="row">
        <div class="col-12 text-center">
            <button mat-raised-button color="accent"
                    (click)="handleEdit($event)"
                    [disabled]="editFormGroup.pending">
                Save <fa-icon [icon]="['fas', 'spinner']" [spin]="true" *ngIf="editFormGroup.pending"></fa-icon>
            </button>
        </div>
    </div>
</div>
