import { createReducer, on } from '@ngrx/store';
import * as ThermostatActions from './actions';
import { ThermostatBackendDict } from '@/data/simulation/models/mechanicals/Thermostat';
import { BaseState } from '../shared/base.state';
import { addStateErrorCount } from '../helper';

export type ThermostatState = BaseState<ThermostatBackendDict>;

export const initialState: ThermostatState = {
  entities: {},
  loading: {},
  errors: {},
};

export const reducer = createReducer(
  initialState,
  on(
    ThermostatActions.loadThermostatsSuccess,
    (state, { thermostats, errors }) => {
      const entities = thermostats.reduce(
        (acc, thermostat) => ({
          ...acc,
          [thermostat.id]: thermostat,
        }),
        {}
      );
      const loading = thermostats.reduce(
        (acc, thermostat) => ({ ...acc, [thermostat.id]: false }),
        {}
      );

      return {
        entities: {
          ...state.entities,
          ...entities,
        },
        loading: {
          ...state.loading,
          ...loading,
        },
        errors: {
          ...state.errors,
          ...addStateErrorCount(errors),
        },
      };
    }
  ),

  on(ThermostatActions.updateThermostat, (state, { thermostatChanges }) => ({
    ...state,
    loading: {
      ...state.loading,
      [thermostatChanges.id]: true,
    },
  })),

  on(ThermostatActions.updateThermostatFailure, (state, { id }) => ({
    ...state,
    loading: {
      ...state.loading,
      [id]: false,
    },
  })),

  on(ThermostatActions.removeThermostat, (state, { thermostat }) => ({
    ...state,
    loading: {
      ...state.loading,
      [thermostat.id]: true,
    },
  })),

  on(ThermostatActions.removeThermostatSuccess, (state, { id }) => {
    const entities = { ...state.entities };
    delete entities[id];

    return {
      entities,
      loading: {
        ...state.loading,
        [id]: false,
      },
      errors: {
        ...state.errors,
        [id]: undefined,
      },
    };
  }),

  on(ThermostatActions.removeThermostatFailure, (state, { id }) => ({
    ...state,
    loading: {
      ...state.loading,
      [id]: false,
    },
  }))
);
