import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import * as FrameFloorActions from './actions';
import * as FrameFloorTypeActions from '../frame-floor-type/actions';
import * as SimulationActions from '../simulation/actions';
import * as SharedActions from '../shared/shared.actions';
import { FrameFloorService } from '@/data/simulation/services/frame-floor.service';
import { FrameFloorTypeService } from '@/data/simulation/services/frame-floor-type.service';
import { FrameFloorTypeBackendDict } from '@/data/simulation/models/enclosure/FrameFloorType';
import { FrameFloorBackendDict } from '@/data/simulation/models/enclosure/FrameFloor';
import { ModelGraphService } from '../../services/model-graph.service';
import { StateValidatorService } from '../../services/state-validator.service';
import { StateModelName } from '../../state.registry';
import { FrameFloorValidator } from '../../validators/frame-floor.validator';

@Injectable()
export class FrameFloorEffects {
  loadDetailedFrameFloors$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FrameFloorActions.loadDetailedFrameFloors),
      mergeMap(action => {
        const detailedFloorTypes: FrameFloorTypeBackendDict[] = [];
        const frameFloors: FrameFloorBackendDict[] = [];
        action.detailedFrameFloors.forEach(detailedFloor => {
          const { type_info: detailedFloorType, ...floor } = detailedFloor;
          if (detailedFloorType) {
            detailedFloorTypes.push(detailedFloorType);
          }
          this.modelGraphService.attachModel(
            'frameFloor',
            detailedFloor.id,
            'frameFloorType',
            [detailedFloor.type]
          );
          frameFloors.push(floor);
        });

        const errors = FrameFloorValidator.validate(action.detailedFrameFloors);

        return of(
          FrameFloorActions.loadFrameFloorsSuccess({
            frameFloors: frameFloors,
            errors: errors,
          }),
          FrameFloorTypeActions.loadDetailedFrameFloorTypes({
            floorTypes: detailedFloorTypes,
          })
        );
      })
    );
  });

  updateFrameFloor$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FrameFloorActions.updateFrameFloor),
      mergeMap(action =>
        this.framefloorService.update(action.frameFloorChanges).pipe(
          mergeMap(updatedFloor => {
            const errors = FrameFloorValidator.validate([updatedFloor]);
            const connectedErrors =
              this.stateValidatorService.validateAgainstChanges(
                StateModelName.frameFloor,
                Object.keys(action.frameFloorChanges)
              );

            return of(
              FrameFloorActions.updateFrameFloorSuccess({
                frameFloor: updatedFloor,
                errors: errors[updatedFloor.id],
              }),
              SharedActions.updateCrossModelErrors({
                modelName: StateModelName.frameFloor,
                changedFields: Object.keys(action.frameFloorChanges),
              })
            );
          }),
          catchError(error =>
            of(
              FrameFloorActions.updateFrameFloorFailure({
                id: action.frameFloorChanges.id,
              }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        )
      )
    );
  });

  removeFrameFloor$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FrameFloorActions.removeFrameFloor),
      mergeMap(action =>
        this.framefloorService.delete(action.frameFloor.id).pipe(
          mergeMap(() =>
            of(
              FrameFloorActions.removeFrameFloorSuccess({
                id: action.frameFloor.id,
              }),
              SimulationActions.removeItemFromList({
                fieldName: 'frame_floors',
                id: action.frameFloor.id,
              })
            )
          ),
          catchError(error =>
            of(
              FrameFloorActions.removeFrameFloorFailure({
                id: action.frameFloor.id,
              }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        )
      )
    );
  });

  removeFrameFloorType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FrameFloorTypeActions.removeFrameFloorType),
      mergeMap(action =>
        this.framefloorTypeService.delete(action.floorTypeId).pipe(
          mergeMap(() => [
            FrameFloorActions.setFrameFloorType({
              floorId: action.floorId,
              floorTypeId: null,
            }),
            FrameFloorTypeActions.removeFrameFloorTypeSuccess({
              id: action.floorTypeId,
            }),
          ]),
          catchError(error => of(SharedActions.reportAPIFailure({ error })))
        )
      )
    );
  });

  addFrameFloorType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FrameFloorTypeActions.addFrameFloorType),
      mergeMap(action =>
        this.framefloorTypeService
          .create(action.floorId, action.floorType)
          .pipe(
            mergeMap(floorType =>
              of(
                FrameFloorTypeActions.loadDetailedFrameFloorTypes({
                  floorTypes: [floorType],
                }),
                FrameFloorActions.setFrameFloorType({
                  floorId: action.floorId,
                  floorTypeId: floorType.id,
                })
              )
            ),
            catchError(error => of(SharedActions.reportAPIFailure({ error })))
          )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private modelGraphService: ModelGraphService,
    private framefloorService: FrameFloorService,
    private framefloorTypeService: FrameFloorTypeService,
    private stateValidatorService: StateValidatorService
  ) {}
}
