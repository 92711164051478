import { Component, Input, ViewEncapsulation } from '@angular/core';
import { BaseAppControlValueAccessor } from '../base-control-value-accessor.directive';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class SelectComponent extends BaseAppControlValueAccessor {
  public ordering = {
    asc: (a, b) => a.key.localeCompare(b.key),
    desc: (a, b) => b.key.localeCompare(a.key),
    unorder: (a, b) => 0,
  };

  @Input() items: Record<string, string> = {};
  @Input() order: keyof typeof this.ordering = 'unorder';
  @Input() label = '';
  @Input() allowClearingValue = false;

  onClear($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    this.control.setValue(null);
  }
}
