import { ListRequestParams } from '@/core/schemes/request-params';
import { Injectable } from '@angular/core';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import { environment } from '../../../../environments/environment';
import { ServerResponse } from '@/core/schemes/server-response';
import { ETOAccount } from '@/data/customer-eto/models';
import { Observable } from 'rxjs';

export class ETOAccountRequestParams extends ListRequestParams {}

@Injectable({ providedIn: 'root' })
export class ETOAccountService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/eto-account/`;

  list(
    params?: ETOAccountRequestParams
  ): Observable<ServerResponse<ETOAccount>> {
    return this.http.get<ServerResponse<ETOAccount>>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }

  retrieve(
    etoAccountId: number,
    options?: IDataServiceMethodOptions
  ): Observable<ETOAccount> {
    const ignoreStore = options?.ignoreStore;
    const chain = this.http.get<ETOAccount>(`${this.baseUrl}${etoAccountId}`);
    return chain;
  }

  create(
    etoAccount: ETOAccount,
    options?: IDataServiceMethodOptions
  ): Observable<ETOAccount> {
    return this.http.post<ETOAccount>(`${this.baseUrl}`, etoAccount);
  }

  update(
    etoAccountId: number,
    etoAccount: ETOAccount | FormData,
    options?: IDataServiceMethodOptions
  ): Observable<ETOAccount> {
    const chain = this.http.patch<ETOAccount>(
      `${this.baseUrl}${etoAccountId}/`,
      etoAccount
    );
    return chain;
  }

  delete(etoAccountId: number) {
    return this.http.delete(`${this.baseUrl}${etoAccountId}/`);
  }
}
