<ng-container *ngIf="paymentRequest">
  <ng-container [ngSwitch]="displayType">
    <ng-container *ngSwitchCase="PaymentRequestDisplayComponentType.onlyName">
      Payment Request #{{ paymentRequest.id }}
    </ng-container>
    <ng-container *ngSwitchCase="PaymentRequestDisplayComponentType.onlyIdAsLink">
      <a
        [routerLink]="[
        '/',
        'hi',
        'payment',
        'request',
        'detail',
        paymentRequest?.id
      ]">
        #{{ paymentRequest.id }}
      </a>
    </ng-container>
    <ng-container *ngSwitchCase="PaymentRequestDisplayComponentType.onlyNameAsLink">
      <a
        [routerLink]="[
        '/',
        'hi',
        'payment',
        'request',
        'detail',
        paymentRequest?.id
      ]">
        Payment Request #{{ paymentRequest.id }}
      </a>
    </ng-container>
    <ng-container *ngSwitchCase="PaymentRequestDisplayComponentType.nameAsLinkAndStatus">
      <a
        [routerLink]="[
        '/',
        'hi',
        'payment',
        'request',
        'detail',
        paymentRequest?.id
      ]">
        Payment Request #{{ paymentRequest.id }}
      </a>
      <span
        class="badge ml-2 {{ PaymentRequestStateLabelMapping[paymentRequest.state]?.badgeClass }}">{{ PaymentRequestStateLabelMapping[paymentRequest.state]?.label }}</span>
    </ng-container>
  </ng-container>
</ng-container>
