import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, Subject, Subscription } from 'rxjs';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';

import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { MatDialog } from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import * as _ from 'lodash';

import { filter, first, takeUntil } from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { toggleLoading } from '@/state/actions/app.actions';

import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import {
  Product,
  ProductImage,
  ProductImageTypeLabelMapping,
  ProductType,
} from '@/data/product/models';
import {
  getProduct,
  getProductImages,
  getProductImagesCount,
} from '@/state/selectors/product/product.selector';
import {
  ProductImageRequestParams,
  ProductImageService,
} from '@/data/product/services/product-image.service';
import { ProductService } from '@/data/product/services/product.service';
import { ProductImagePermissionService } from '@/modules/product/services/product-image-permission-service';
import {
  ProductImageChangeDialogComponent,
  ProductImageChangeDialogData,
} from '@/modules/product/components/product-image-change-dialog/product-image-change-dialog.component';
import {
  deleteProductProductImageAction,
  updateProductProductImageAction,
} from '@/state/actions/product/product.actions';

@Component({
  selector: 'app-product-product-images-tab',
  templateUrl: './product-product-images-tab.component.html',
  styleUrls: ['./product-product-images-tab.component.scss'],
})
export class ProductProductImagesTabComponent implements OnInit, OnDestroy {
  protected readonly ProductType = ProductType;
  protected readonly ProductImageTypeLabelMapping =
    ProductImageTypeLabelMapping;

  public product: Product;
  public displayedColumns = ['document', 'image_type', 'order', 'actions'];
  public filterFromGroup: FormGroup;
  public isLoading = false;
  public rows: ProductImage[] = [];
  public permissions: {
    [key: string]: {
      canEdit: Observable<ObjectPermissionResponse>;
      canDelete: Observable<ObjectPermissionResponse>;
    };
  } = {};
  public rowsCount: number;
  public createPermissionResponse: Observable<ObjectPermissionResponse>;

  public defaultParams: ProductImageRequestParams =
    new ProductImageRequestParams(1, '', 'order');

  public storedParams: ProductImageRequestParams = Object.assign(
    new ProductImageRequestParams(),
    this.defaultParams
  );

  private listSubscription$: Subscription;
  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private injector: Injector,
    private dialog: MatDialog,
    private productService: ProductService,
    private productImageService: ProductImageService,
    private productImagePermissionService: ProductImagePermissionService,
    private fb: FormBuilder,
    private uiHelperService: UIHelperService
  ) {}

  ngOnInit(): void {
    this.storedParams = _.cloneDeep(this.defaultParams);

    this.store
      .select(getProduct)
      .pipe(
        filter(val => val !== null),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((entity: Product) => {
        this.product = entity;
        this.list();
      });

    this.store
      .select(getProductImages)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((productImages: ProductImage[]) => {
        this.rows = productImages;
      });

    this.store
      .select(getProductImagesCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(productImagesCount => {
        this.rowsCount = productImagesCount;
      });

    this.setupFilterForm();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  setupFilterForm() {
    this.filterFromGroup = this.fb.group({
      search: [null],
    });

    this.filterFromGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        const params = _.merge({}, this.defaultParams, values);
        delete params.ordering;

        this.storedParams = _.merge(this.storedParams, params);
        this.list();
      });
  }

  list() {
    this.isLoading = true;

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }

    this.listSubscription$ = this.productService
      .product_images(this.product.id, this.storedParams)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.rows = data.results;
        this.rowsCount = data.count;
        this.isLoading = false;
        this.rows.forEach((row: ProductImage) => {
          this.permissions[row.id] = {
            canEdit: this.productImagePermissionService.canEdit(row),
            canDelete: this.productImagePermissionService.canDelete(row),
          };
        });
      });
  }

  onPaginateChange($event: PageEvent) {
    this.uiHelperService.getUISettings().rowsPerPage = $event.pageSize;
    this.storedParams.page_size = $event.pageSize;
    this.storedParams.page = $event.pageIndex + 1;
    this.list();
  }

  onSortChange($event: Sort) {
    this.storedParams.ordering = `${$event.direction === 'desc' ? '-' : ''}${
      $event.active
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  edit($event: MouseEvent, productImage: ProductImage) {
    $event.preventDefault();

    const dialogRef = this.dialog.open(ProductImageChangeDialogComponent, {
      width: '45%',
      data: {
        product_image: productImage,
        commit: true,
      } as ProductImageChangeDialogData,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(changedProductImage => {
      if (!changedProductImage) {
        return;
      }
      this.store.dispatch(
        updateProductProductImageAction({ payload: changedProductImage })
      );
      this.uiHelperService.openSnackBar(`Product Image successfully updated`);
    });
  }

  delete($event: MouseEvent, productImage: ProductImage) {
    $event.preventDefault();

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '45%',
      data: {
        title: 'Delete document ?',
        content: `Do you want to delete image: ${productImage?.customer_document_info.filename}`,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      this.store.dispatch(toggleLoading({ payload: true }));
      this.productImageService
        .delete(productImage.id)
        .pipe(takeUntil(this.componentDestroyed$), first())
        .subscribe(() => {
          this.store.dispatch(
            deleteProductProductImageAction({ payload: productImage })
          );
          this.store.dispatch(toggleLoading({ payload: false }));
          this.uiHelperService.openSnackBar(`Image successfully updated`);
        });
    });
  }

  create($event: MouseEvent) {
    $event.preventDefault();

    const dialogRef = this.dialog.open(ProductImageChangeDialogComponent, {
      width: '45%',
      data: {
        product_image: {
          product: this.product.id,
        },
        commit: true,
      } as ProductImageChangeDialogData,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(changedProductImage => {
      if (!changedProductImage) {
        return;
      }
      this.list();
      this.uiHelperService.openSnackBar(`Product Image Successfully Created`);
    });
  }
}
