import { Component, ViewChild } from '@angular/core';
import {
  EditorNavigationService,
  SIDE_NAV_TABS,
  SideNavTab,
  SubTab,
  TABS,
  Tab,
} from '../../services/editor-navigation.service';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { Store } from '@ngrx/store';
import { selectSimulationId } from '../../state/simulation/selectors';
import { selectErrorAndWarningCount } from '../../state/simulation.selectors';

@Component({
  selector: 'app-side-nav-bar',
  templateUrl: './side-nav-bar.component.html',
  styleUrls: ['./side-nav-bar.component.scss'],
})
export class SideNavBarComponent {
  tabsInfo = TABS;
  isExpanded = true;
  isLeftDrawerOpen = true;
  activeTabLink: Tab;
  activeSubTabLink: SubTab;
  sideNavActiveTabLink: SideNavTab;
  componentDestroyed$ = new Subject();
  simulationId: number = null;
  sideNavTabs = SIDE_NAV_TABS;
  sideNavActiveTab = SIDE_NAV_TABS[0].link;
  errorCount = 0;
  warningCount = 0;

  @ViewChild('leftDrawer') leftDrawer: MatSidenav;
  @ViewChild('rightDrawer') rightDrawer: MatSidenav;

  public toggleDrawer(): void {
    this.isExpanded = !this.isExpanded;
    this.leftDrawer.toggle();
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    public editorNavigationService: EditorNavigationService,
    public store: Store
  ) {}

  ngOnInit() {
    this.editorNavigationService
      .getActiveTabLinkObservable()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(tab => {
        this.activeTabLink = tab;
      });

    this.editorNavigationService
      .getActiveSubTabLinkObservable()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(subTab => {
        this.activeSubTabLink = subTab;
      });

    this.editorNavigationService
      .getSideNavActiveTabLink()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(sideNavActiveTabLink => {
        if (sideNavActiveTabLink) {
          this.sideNavActiveTab = sideNavActiveTabLink;
        }
      });

    this.activatedRoute.queryParams
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(params => {
        this.editorNavigationService.setActiveTabLink(
          params.tab,
          params.subTab
        );
      });

    this.store
      .select(selectSimulationId())
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(simulationId => {
        this.simulationId = simulationId;
      });

    this.store
      .select(selectErrorAndWarningCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(count => {
        this.errorCount = count.errorCount;
        this.warningCount = count.warningCount;
      });
  }

  setActiveTabLink(tab: Tab, subTab?: SubTab) {
    this.editorNavigationService.setActiveTabLink(tab, subTab);
  }

  toggleRightDrawer() {
    this.isLeftDrawerOpen = !this.isLeftDrawerOpen;
    this.rightDrawer.toggle();
  }

  setRightSideNavActiveTabIndex(arg0: any) {
    this.toggleRightDrawer();
    this.editorNavigationService.setSideNavActiveTabLink(arg0);
  }

  setSidenavActiveTabLink(link) {
    this.editorNavigationService.setSideNavActiveTabLink(link);
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
