import { Injectable } from '@angular/core';
import { BaseDataService } from '@/core/services/base-data-service';
import { environment } from '../../../../environments/environment';
import { ServerResponse } from '@/core/schemes/server-response';
import {
  HIRLPriority,
  HIRLQAStatusList,
  HIRLQAStatusUserFilterBadgesCount,
  QANote,
  QARequirementType,
  QaStatus,
} from '@/data/qa/models';
import { ListRequestParams } from '@/core/schemes/request-params';
import { Observable } from 'rxjs';
import { QaNoteRequestParams } from '@/data/qa/services/qa-note.service';
import { QANoteList } from '@/data/qa/models/qa-status';
import { EEPProgramHomeStatusState } from '@/data/home/models/eep_program_home_status';

export class QaStatusRequestParams extends ListRequestParams {
  requirement__type?: QARequirementType;
  home_status__customer_hirl_project__registration?: string;
  home_status__customer_hirl_project?: string;
  qa_designee?: number | number[];
  home_status__state?: EEPProgramHomeStatusState;
  home_status__customer_hirl_project__registration__builder_organization?: string;
  home_status__customer_hirl_project__registration__architect_organization?: string;
  home_status__customer_hirl_project__registration__developer_organization?: string;
  home_status__customer_hirl_project__registration__community_owner_organization?: string;
  home_status__customer_hirl_project__registration__registration_user__company?: string;
  home_status__home__city?: number | number[];
  home_status__home__state?: number | number[];
  home_status__home__city__country?: number | number[];
  hirl_priority: HIRLPriority | HIRLPriority[];
}

@Injectable({
  providedIn: 'root',
})
export class QaStatusService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/qa_statuses/`;

  list(params?: QaStatusRequestParams): Observable<ServerResponse<QaStatus>> {
    return this.http.get<ServerResponse<QaStatus>>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }

  retrieve(qaStatusId: number): Observable<QaStatus> {
    return this.http.get<QaStatus>(`${this.baseUrl}${qaStatusId}`);
  }

  customer_hirl_list(
    params?: QaStatusRequestParams
  ): Observable<ServerResponse<HIRLQAStatusList>> {
    return this.http.get<ServerResponse<HIRLQAStatusList>>(
      `${this.baseUrl}customer_hirl_list/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  customer_hirl_user_filter_badges_count(): Observable<HIRLQAStatusUserFilterBadgesCount> {
    return this.http.get<HIRLQAStatusUserFilterBadgesCount>(
      `${this.baseUrl}customer_hirl_user_filter_badges_count/`
    );
  }

  qa_notes(
    qaStatusId: number,
    params?: QaNoteRequestParams
  ): Observable<ServerResponse<QANote>> {
    return this.http.get<ServerResponse<QANote>>(
      `${this.baseUrl}${qaStatusId}/qa_notes/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  qa_notes_flat_list(
    qaStatusId: number,
    params?: QaNoteRequestParams
  ): Observable<ServerResponse<QANoteList>> {
    return this.http.get<ServerResponse<QANoteList>>(
      `${this.baseUrl}${qaStatusId}/qa_notes/flat_list/`,
      {
        params: this.rollParams(params),
      }
    );
  }
}
