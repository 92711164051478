import { Action, createReducer, on } from '@ngrx/store';
import {
  addEntityArray,
  deleteEntityArray,
  loadEntityArray,
  loadEntityCount,
  updateEntityArray,
} from '@/state/reducers/utils';
import { ModernMessage } from '@/data/messaging/models/modernMessage';
import {
  addRecentMessageAction,
  deleteRecentMessageAction,
  loadRecentMessagesAction,
  loadRecentMessagesCountAction,
  resetRecentMessagesAction,
  updateRecentMessageAction,
} from '@/state/actions/messaging/message.actions';

export const messageStateKey = 'message';

export interface MessageState {
  recentMessages: ModernMessage[];
  recentMessagesCount: number;
  recentMessageAdded?: ModernMessage;
}

export const messageInitialState: MessageState = {
  recentMessages: [],
  recentMessagesCount: 0,
  recentMessageAdded: null,
};

const productReducer = createReducer(
  messageInitialState,
  on(resetRecentMessagesAction, state => ({
    ...state,
    recentMessages: [],
    recentMessagesCount: 0,
  })),
  on(loadRecentMessagesAction, (state, { payload }) =>
    loadEntityArray(state, 'recentMessages', payload)
  ),
  on(loadRecentMessagesCountAction, (state, { payload }) =>
    loadEntityCount(state, 'recentMessagesCount', payload)
  ),

  on(addRecentMessageAction, (state, { payload }) => {
    if (payload.alert_read) {
      return state; // Ignore messages with alert_read = true
    }
    state = addEntityArray(
      state,
      'recentMessages',
      payload,
      'recentMessagesCount'
    );
    return {
      ...state,
      recentMessageAdded: payload,
    };
  }),

  on(updateRecentMessageAction, (state, { payload }) => {
    if (payload.alert_read) {
      // Remove message if updated to alert_read = true

      const messageExists = state.recentMessages.some(
        message => message.id === payload.id
      );
      return {
        ...state,
        recentMessages: state.recentMessages.filter(
          msg => msg.id !== payload.id
        ),
        recentMessagesCount: messageExists
          ? state.recentMessagesCount - 1
          : state.recentMessagesCount,
      };
    } else {
      return updateEntityArray(state, 'recentMessages', payload);
    }
  }),
  on(deleteRecentMessageAction, (state, { payload }) =>
    deleteEntityArray(state, 'recentMessages', payload, 'recentMessagesCount')
  )
);

export function reducer(state: MessageState | undefined, action: Action) {
  return productReducer(state, action);
}
