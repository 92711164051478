import {
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import {
  MessageLevelLabelMapping,
  ModernMessage,
} from '@/data/messaging/models';
import { first, takeUntil } from 'rxjs/operators';
import { MessageService } from '@/data/messaging/services/message.service';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { Subject } from 'rxjs';
import { OverlayRef } from '@angular/cdk/overlay';

@Component({
  selector: 'app-message-snackbar',
  templateUrl: './message-snackbar.component.html',
  styleUrls: ['./message-snackbar.component.scss'],
  animations: [
    trigger('snackbarAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(-100%)' }), // Start position (off screen to the left)
        animate(
          '200ms ease-out',
          style({ opacity: 1, transform: 'translateX(0)' })
        ), // End position (on screen)
      ]),
      transition(':leave', [
        animate(
          '200ms ease-in',
          style({ opacity: 0, transform: 'translateX(100%)' })
        ), // Exit position (off screen to the right)
      ]),
    ]),
  ],
})
export class MessageSnackbarComponent implements OnInit, OnDestroy {
  protected readonly MessageLevelLabelMapping = MessageLevelLabelMapping;

  @Output() snackbarClosed = new EventEmitter<void>();

  private componentDestroyed$ = new Subject();

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public entity: ModernMessage,
    private messageService: MessageService,
    private overlayRef: OverlayRef,
    private uiHelperService: UIHelperService
  ) {}

  ngOnInit(): void {
    if (this.entity.id) {
      this.messageService
        .seen(this.entity.id)
        .pipe(first(), takeUntil(this.componentDestroyed$))
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  onRead($event: MouseEvent, message: ModernMessage) {
    $event.preventDefault();
    this.close();
    this.messageService
      .read(message.id)
      .pipe(first(), takeUntil(this.componentDestroyed$))
      .subscribe();
  }

  onClose($event: MouseEvent, message: ModernMessage) {
    $event.preventDefault();
    this.close();
  }

  setOverlayRef(ref: OverlayRef) {
    this.overlayRef = ref;
  }

  close() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.snackbarClosed.emit(); // Emit event when closing
    }
  }
}
