import { FrameFloorTypeBackendDict } from '@/data/simulation/models/enclosure/FrameFloorType';
import { createAction, props } from '@ngrx/store';
import { StateErrors } from '../shared/base.state';

export const loadDetailedFrameFloorTypes = createAction(
  '[FrameFloorType] Load Detailed FrameFloorTypes',
  props<{ floorTypes: FrameFloorTypeBackendDict[] }>()
);

export const loadFrameFloorTypesSuccess = createAction(
  '[FrameFloorType] Load FrameFloorTypes Success',
  props<{
    floorTypes: FrameFloorTypeBackendDict[];
    errors: StateErrors<FrameFloorTypeBackendDict>;
  }>()
);

export const addFrameFloorType = createAction(
  '[FrameFloorType UI] Add FrameFloorType',
  props<{ floorId: number; floorType: FrameFloorTypeBackendDict }>()
);

export const removeFrameFloorType = createAction(
  '[FrameFloor UI] Remove FrameFloorType',
  props<{ floorId: number; floorTypeId: number }>()
);

export const removeFrameFloorTypeSuccess = createAction(
  '[FrameFloorType API] Remove FrameFloorType Success',
  props<{ id: number }>()
);

export const updateFrameFloorType = createAction(
  '[FrameFloorType UI] Update FrameFloorType',
  props<{ floorTypeChanges: Partial<FrameFloorTypeBackendDict> }>()
);

export const updateFrameFloorTypeFailure = createAction(
  '[FrameFloorType API] Update FrameFloorType Failure',
  props<{ id: number }>()
);
