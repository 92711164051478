import { NaturalVentilationBackendDict } from '@/data/simulation/models/systems/NaturalVentilation';
import { createAction, props } from '@ngrx/store';
import { ModelErrors, StateErrors } from '../shared/base.state';

export const loadNaturalVentilations = createAction(
  '[Simulation API] Load NaturalVentilation',
  props<{ naturalVentilations: NaturalVentilationBackendDict[] }>()
);

export const loadNaturalVentilationsSuccess = createAction(
  '[NaturalVentilation API] Load NaturalVentilations Success',
  props<{
    naturalVentilations: NaturalVentilationBackendDict[];
    errors: StateErrors<NaturalVentilationBackendDict>;
  }>()
);

export const updateNaturalVentilation = createAction(
  '[NaturalVentilation UI] Update NaturalVentilation',
  props<{
    id: number;
    naturalVentilationChanges: Partial<NaturalVentilationBackendDict>;
  }>()
);

export const updateNaturalVentilationSuccess = createAction(
  '[NaturalVentilation API] Update NaturalVentilation Success',
  props<{
    naturalVentilation: NaturalVentilationBackendDict;
    errors: ModelErrors<NaturalVentilationBackendDict>;
  }>()
);

export const updateNaturalVentilationFailure = createAction(
  '[NaturalVentilation API] Update NaturalVentilation Failure',
  props<{ id: number }>()
);
