<div class="dropdown" *ngIf="(authData$ | async) as auth" style="position: relative;">
    <a
        id="impersonateDropdown"
        (click)="handleOpen()"
        [attr.aria-expanded]="!isCollapsed"
        style="cursor: pointer;">

<!--        <i class="fa fa-shield-alt fa-lg navbar-icon"-->
<!--            style="color: #888888;"-->
<!--            [ngStyle]="{'color': !!auth.impersonated ? '#bf0d25' : '#888888' }"-->
<!--            id="shield" ></i>-->
        <fa-icon [icon]="['fas', 'shield-alt']" [ngStyle]="{'color': !!auth.impersonated ? '#bf0d25' : '#888888' }"></fa-icon>
    </a>

    <div *ngIf="!!auth.impersonated"
        style="position: absolute;
                bottom: -44px;
                height: 30px;
                right: 0;
                background: #212529;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                color: #fff;
                font-size: 12px;
                overflow: hidden;">
        <div *ngIf="(userData$ | async) as preview" style="white-space: nowrap; padding: 7px;">
            <span>{{preview.first_name}} {{preview.last_name}} ({{preview.username}})</span>
<!--            <span *ngIf="preview.company_info"> | {{preview.company_info.name}} ({{preview.company_info.company_type}})</span>-->
        </div>
    </div>

    <div class="dropdown-menu dropdown-menu-right p-3 mt-3"
         [ngClass]="{'show': !isCollapsed}"
         #impersonateDropdown
         style="width: 400px;
                min-height: 100px;
                padding-bottom: 0px;">

        <div class="col-sm-12 form-group" *ngIf="(userData$ | async) as profile">
            <h4>
                <span class="text-muted">[{{profile.id}}]</span>
                {{profile.first_name}} {{profile.last_name}} <i *ngIf="!!auth.impersonated" class="fa fa-fw fa-user-secret"></i>
                - <small>({{profile.username}})</small>
            </h4>
            <h5 *ngIf="profile.company_info">
                <span class="text-muted">[{{profile.company_info.id}}]</span>
                <span>{{profile.company_info.name}} - <small>{{profile.company_info.company_type}}</small></span>
            </h5>
        </div>

        <div *ngIf="(previousImpersonations$ | async).length > 0 as impList" class="col-sm-12 my-3">
            <h5 style="font-size: 15px;">Previous Impersonations</h5>
            <div class="card">
                <ul class="list-group list-group-flush" *ngFor="let user of (previousImpersonations$ | async) | slice:0:5">
                    <li class="list-group-item previous-impersonation"
                        (click)="handlePreviousClick( user.id )"
                        style="font-size:14px;">
                        <span>{{user.first_name}} {{user.last_name}} ({{user.username}})</span>
                        <span *ngIf="user.company_info"> | {{user.company_info.name}} ({{user.company_info.company_type}})</span>
                    </li>
                </ul>
            </div>
        </div>

        <div class="col-sm-12 form-group">
            <form [formGroup]="impersonateForm" style="width:100%">
                <mat-form-field appearance="outline" class="column" class="w-100">
                    <input matInput placeholder="" formControlName="txtQuery" [matAutocomplete]="auto">
                </mat-form-field>
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayImpersonationMenuFn" (opened)="impersonateAutocompleteOpened()" (closed)="impersonateAutocompleteClosed()">
                    <mat-option *ngIf="isLoading" class="is-loading"><mat-spinner diameter="50"></mat-spinner></mat-option>
                    <ng-container *ngIf="!isLoading">
                        <mat-option *ngFor="let user of (filteredImpersonations$ | async)?.results" [value]="user">
                            <div style="height: 25px;">
                                <span>{{ user.first_name }} {{ user.last_name }} </span>
                                <span style="font-style: 11px">({{user.username}})</span>
                            </div>
                            <div>
                                <span style="font-weight: 800" *ngIf="user.company_info">{{ user.company_info.name }} </span>
                                <span *ngIf="user.company_info">{{ user.company_info.company_type }}</span>
                            </div>
                        </mat-option>
                    </ng-container>
                </mat-autocomplete>
            </form>
        </div>
        <div class="col-sm-12 form-group mt-3">
            <button type="submit"
                    class="btn btn-primary mr-2"
                    (click)="engageImpersonate()"
                    [disabled]="shouldDisable()">
                <!-- <i class="fa fa-spin fa-spinner ng-hide" ng-show="impersonate.processing"></i>  -->
                Impersonate
            </button>
            <button class="btn btn-primary mr-2"
                    type="button"
                    (click)="endImpersonate()"
                    [disabled]="!auth.impersonated">Stop</button>
            <button class="btn btn-primary"
                    type="button"
                    (click)="onLogout($event)">Logout</button>
        </div>
    </div>
</div>
