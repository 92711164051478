import { AppState } from '@/state/reducers';
import { createSelector } from '@ngrx/store';
import { hirlClientAgreementStateKey } from '@/state/reducers/customer-hirl/client-agreement.reducer';

export const selectHIRLClientAgreementState = (state: AppState) =>
  state[hirlClientAgreementStateKey];

export const getHIRLClientAgreement = createSelector(
  selectHIRLClientAgreementState,
  store => store.detail
);

// Client Agreement Documents
export const getHIRLClientAgreementDocuments = createSelector(
  selectHIRLClientAgreementState,
  store => store.documents
);

export const getHIRLClientAgreementDocumentsCount = createSelector(
  selectHIRLClientAgreementState,
  store => store.documentsCount
);

// Client Agreement Notes
export const getHIRLClientAgreementNotes = createSelector(
  selectHIRLClientAgreementState,
  store => store.notes
);

export const getHIRLClientAgreementNotesCount = createSelector(
  selectHIRLClientAgreementState,
  store => store.notesCount
);
