<div *ngIf="initialized; else loading;">
    <ng-container *ngTemplateOutlet="lightsView; context: {data: lights}"></ng-container>
</div>

<ng-template #lightsView let-lights="data">
    <div class="row mt-2">
        <ng-container>
            <div class="col-6 mt-2">
                <div class="card">
                    <div class="card-content">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item header"
                                style="background-color:rgba(0, 0, 0, 0.03); align-items: center;">
                                <b *ngIf="lights.efficient_percent">
                                    Efficient Lighting
                                    {{ lights.efficient_percent |number: '1.2-2' }}
                                </b>
                                <b *ngIf="lights.led_percent">
                                    LED Lighting
                                    {{ lights.led_percent | number: '1.2-2' }}
                                </b>
                            </li>
                            <li class="list-group-item">
                                <b>Efficiency Percentages:</b>
                                {{ lights.interior_efficient_percent }}
                                {{ lights.garage_efficient_percent}}
                                {{ lights.exterior_efficient_percent }}
                            </li>
                            <li class="list-group-item">
                                <b>LED Efficiency Percentages:</b>
                                {{ lights.interior_led_percent }}
                                {{ lights.garage_led_percent }}
                                {{ lights.exterior_led_percent }}
                            </li>
                            <li class="list-group-item">
                                <b>CFL Efficiency Percentages:</b>
                                {{ lights.interior_cfl_percent }}
                                {{ lights.garage_cfl_percent }}
                                {{ lights.exterior_cfl_percent }}
                            </li>
                            <li class="list-group-item">
                                <b>Florescent Efficiency Percentages:</b>
                                {{ lights.interior_florescent_percent }}
                                {{ lights.garage_florescent_percent }}
                                {{ lights.exterior_florescent_percent }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>

<ng-template #loading>
    <div class="row">
        <div class="col-12">
            <div class="d-flex justify-content-center" style="height: 320px;">
                <mat-spinner [diameter]="50"></mat-spinner>
            </div>
        </div>
    </div>
</ng-template>