import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import { USState } from '@/data/geographic/models';
import { ListRequestParams } from '@/core/schemes/request-params';
import { Observable } from 'rxjs';

export class USStateRequestParams extends ListRequestParams {
  home_status_related?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class USStateService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/us_states/`;

  list(params: USStateRequestParams): Observable<USState[]> {
    return this.http.get<USState[]>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }

  retrieve(
    abbr: string,
    options?: IDataServiceMethodOptions
  ): Observable<USState> {
    const ignoreStore = options?.ignoreStore;
    const chain = this.http.get<USState>(`${this.baseUrl}${abbr}`);
    return chain;
  }
}
