import { createReducer, on } from '@ngrx/store';
import * as FormActions from './actions';

export interface FormStatus {
  active: boolean;
}

export interface FormState {
  entities: { [id: string]: FormStatus };
  currentActiveFormId: string | null;
}

export const initialState: FormState = {
  entities: {},
  currentActiveFormId: null,
};

export const reducer = createReducer(
  initialState,

  on(FormActions.createForm, (state, { formId }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        [formId]: { active: false }, // Initial state of form is not active
      },
    };
  }),

  on(FormActions.activateForm, (state, { formId }) => {
    const lastActiveFormId = state.currentActiveFormId;
    const newState = {
      entities: { ...state.entities },
      currentActiveFormId: formId,
    };

    if (lastActiveFormId !== null) {
      newState.entities[lastActiveFormId] = {
        active: false,
      };
    }

    newState.entities[formId] = {
      active: true,
    };
    return newState;
  }),

  on(FormActions.deactivateForm, (state, { formId }) => {
    // If form doesn't exist, return state unchanged
    if (!state.entities[formId]) return state;

    return {
      currentActiveFormId: null,
      entities: {
        ...state.entities,
        [formId]: {
          active: false,
        },
      },
    };
  }),

  on(FormActions.removeForm, (state, { formId }) => {
    const { [formId]: deleted, ...entities } = state.entities;
    return { ...state, entities };
  })
);
