<div class="container" *ngIf="initialized">
  <div class="row">
    <div class="col-12">
      <nav
        class="navbar p-0 pt-3 mb-3"
        role="navigation"
        aria-label="main navigation"
        style="border-bottom: 1px solid #eee"
      >
        <h5 class="mt-3">
          <button mat-raised-button color="accent" (click)="handleBack()">
            <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
          </button>
          <span *ngIf="!editForm">
            New Multifamily Family Project Registration
          </span>
          <span *ngIf="editForm">
            Edit Multifamily Family
            <b>Project Registration {{ hirlProjectRegistration.id }}</b>
          </span>
        </h5>
      </nav>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <mat-vertical-stepper
        [linear]="!editForm"
        #stepper
        (selectionChange)="onStepChange($event)"
      >
        <mat-step [stepControl]="projectInformationFormGroup">
          <form [formGroup]="projectInformationFormGroup">
            <ng-template matStepLabel>Project Information</ng-template>
            <div class="row mt-5">
              <div class="col-12">
                <mat-form-field class="w-100 mb-2">
                  <mat-label>NGBS Standard Version and Scoring Path</mat-label>
                  <mat-select
                    aria-label="Select Standard Version and Scoring Path"
                    formControlName="eep_program"
                    required
                    panelClass="eep-program-panel-override"
                  >
                    <mat-option
                      *ngFor="let eepProgram of eepPrograms"
                      [value]="eepProgram.id"
                      [disabled]="
                        bannedEEPProgramSlugs.includes(eepProgram.slug)
                      "
                    >
                      {{ eepProgram.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      projectInformationFormGroup.controls[
                        'eep_program'
                      ].hasError('required')
                    "
                  >
                    NGBS Standard Version and Scoring Path is required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <mat-form-field appearance="outline" class="w-100 mb-2">
                  <mat-label>MF Development Name</mat-label>
                  <input
                    matInput
                    placeholder="MF Development Name"
                    formControlName="project_name"
                    required
                  />
                  <mat-error
                    *ngIf="
                      projectInformationFormGroup.controls[
                        'project_name'
                      ].hasError('required')
                    "
                  >
                    This field is required
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-form-field appearance="outline">
                  <mat-label>Estimated Completion Date</mat-label>
                  <app-month-mat-picker
                    formControlName="estimated_completion_date"
                    [minDate]="estimatedCompletionDateMinDate"
                    required
                  ></app-month-mat-picker>
                  <mat-error
                    *ngIf="
                      projectInformationFormGroup.controls[
                        'estimated_completion_date'
                      ].hasError('required')
                    "
                  >
                    This field is required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-12 text-center">
                <button matStepperNext mat-raised-button color="accent">
                  Next
                </button>
              </div>
            </div>
          </form>
        </mat-step>

        <mat-step [stepControl]="builderFormGroup">
          <form [formGroup]="builderFormGroup">
            <ng-template matStepLabel>Client Information</ng-template>
            <div class="row mt-5">
              <div class="col-12">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Client Company</mat-label>
                  <input
                    matInput
                    placeholder="Type to search"
                    formControlName="builder_organization_info"
                    [matAutocomplete]="auto_builder_organization"
                    required
                  />
                  <mat-error
                    *ngIf="
                      builderFormGroup.controls[
                        'builder_organization_info'
                      ].hasError('required')
                    "
                  >
                    Client is required - Must select option from list
                  </mat-error>
                  <mat-error
                    *ngIf="
                      builderFormGroup.controls[
                        'builder_organization_info'
                      ].hasError('invalidObject')
                    "
                  >
                    Must select option from list
                  </mat-error>
                  <mat-autocomplete
                    #auto_builder_organization="matAutocomplete"
                    autoActiveFirstOption
                    [displayWith]="displayBuilderOrganizationMenu"
                  >
                    <mat-option
                      *ngFor="
                        let builderOrganization of (
                          filteredBuilderOrganizations$ | async
                        )?.results
                      "
                      [value]="builderOrganization"
                    >
                      <app-hirl-builder-organization-display-prefix
                        [builderOrganization]="builderOrganization"
                      ></app-hirl-builder-organization-display-prefix>
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <small class="mat-hint" *ngIf="!builderFormGroup.get('builder_organization_info').value">
                  List of all available
                  <a
                    [routerLink]="['', 'company', 'builder']"
                    target="_blank"
                    matTooltip="Open Clients page in new window"
                  >
                    Clients
                    <fa-icon [icon]="['fas', 'external-link-alt']"></fa-icon>
                  </a>
                </small>
                <div class="row mb-3"
                     *ngIf="builderFormGroup.get('builder_organization_info').value && builderFormGroup.get('builder_organization_info').value.active_customer_hirl_builder_agreements_count === 0">
                  <div class="col-12">
                    <b>
                      Client company does not have an active Client Agreement.
                      <a href="#" [routerLink]="['/', 'hi', 'client_agreements', 'builder', 'create_without_user']"
                         target="_blank">
                        Click here to initiate a new Client Agreement
                        for {{ builderFormGroup.get('builder_organization_info').value.name }}
                      </a>
                    </b>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="row"
              *ngIf="builderFormGroup.get('builder_organization_info').value"
            >
              <div class="col-12">
                <mat-form-field appearance="outline" class="w-100 mb-2">
                  <mat-label>Contact</mat-label>
                  <app-contact-card-select
                    formControlName="builder_organization_contact"
                    [filterParams]="{
                      company_and_related_users: builderFormGroup.get(
                        'builder_organization_info'
                      ).value.id
                    }"
                    [contactCardInitialData]="{
                      company: builderFormGroup.get('builder_organization_info')
                        .value.id
                    }"
                    required
                  ></app-contact-card-select>
                  <mat-error
                    *ngIf="
                      builderFormGroup
                        .get('builder_organization_contact')
                        .hasError('required')
                    "
                  >
                    This field is required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <mat-form-field class="w-100 mb-2">
                  <mat-label
                  >Email address of additional invoice recipient
                  </mat-label
                  >
                  <input
                    matInput
                    placeholder="Email address of additional invoice recipient"
                    name="billing_email"
                    formControlName="billing_email"
                  />
                  <mat-error
                    *ngIf="
                      builderFormGroup.controls['billing_email'].hasError(
                        'email'
                      )
                    "
                  >
                    Enter correct email address
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <mat-checkbox formControlName="is_coi_held_by_different_company">
                  Is COI held by a company different than the Builder/Client?
                </mat-checkbox>
              </div>
            </div>

            <div class="row" *ngIf="builderFormGroup.get('is_coi_held_by_different_company').value">
              <div class="col-12">
                <mat-form-field class="mb-2 mt-2 w-100">
                  <mat-label>Company Name</mat-label>
                  <textarea matInput type="text" placeholder="Company Name"
                            formControlName="coi_held_by_different_company"></textarea>
                  <mat-error
                    *ngIf="
                      builderFormGroup.get(
                        'coi_held_by_different_company'
                      ).hasError('required')
                    "
                  >
                    Company Name is required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-12 text-center">
                <button matStepperPrevious mat-raised-button color="primary" class="mr-2">Back</button>
                <button matStepperNext mat-raised-button color="accent">
                  Next
                </button>
              </div>
            </div>
          </form>
        </mat-step>

        <mat-step [stepControl]="financialFormGroup">
          <form [formGroup]="financialFormGroup">
            <ng-template matStepLabel
            >Project Type & Financing Information
            </ng-template
            >
            <div class="row mt-5">
              <div class="col-12">
                <mat-checkbox
                  name="building_will_include_non_residential_space"
                  formControlName="building_will_include_non_residential_space"
                >
                  Building(s) will include non-residential space
                  (retail/commercial) ?
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox
                  name="seeking_hud_mortgage_insurance_premium_reduction"
                  formControlName="seeking_hud_mortgage_insurance_premium_reduction"
                >
                  Seeking HUD Mortgage Insurance Premium Reduction?
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox
                  name="seeking_hud_green_and_resilient_retrofit_program"
                  formControlName="seeking_hud_green_and_resilient_retrofit_program"
                >
                  Seeking HUD Green and Resilient Retrofit Program?
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox
                  name="seeking_fannie_mae_green_financing"
                  formControlName="seeking_fannie_mae_green_financing"
                >
                  Seeking Fannie Mae Green financing?
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox
                  name="seeking_freddie_mac_green_financing"
                  formControlName="seeking_freddie_mac_green_financing"
                >
                  Seeking Freddie Mac Green financing?
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox
                  name="intended_to_be_affordable_housing"
                  formControlName="intended_to_be_affordable_housing"
                >
                  Intended to be affordable housing?
                </mat-checkbox>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-12 text-center">
                <button matStepperPrevious mat-raised-button color="primary" class="mr-2">Back</button>
                <button matStepperNext mat-raised-button color="accent">
                  Next
                </button>
              </div>
            </div>
          </form>
        </mat-step>

        <mat-step [stepControl]="certificateNamesFormGroup">
          <form [formGroup]="certificateNamesFormGroup">
            <ng-template matStepLabel
            >Certificate Customization Options
            </ng-template
            >
            <div class="row mt-5">
              <div class="col-3">
                <div
                  class="row"
                  *ngFor="let index of [1, 2, 3, 4, 5, 6, 7, 8, 9]"
                >
                  <div
                    [ngClass]="
                      certificateNamesFormGroup.get('certificate_line' + index)
                        .value === certificateLineChoices.customText || certificateNamesFormGroup.get('certificate_line' + index)
                        .value === certificateLineChoices.customTextGreen
                        ? 'col-4'
                        : 'col-12'
                    "
                  >
                    <mat-form-field class="w-100">
                      <mat-label>Line {{ index }}</mat-label>
                      <mat-select
                        [formControlName]="'certificate_line' + index"
                        panelClass="certificate-line-panel-override"
                      >
                        <mat-option
                          *ngFor="
                            let item of certificateLineChoicesMapping
                              | defaultOrderKeyValue
                          "
                          [value]="item.key"
                        >{{ item.value }}
                        </mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div
                    class="col-8"
                    *ngIf="
                      certificateNamesFormGroup.get('certificate_line' + index)
                        .value === certificateLineChoices.customText ||
                        certificateNamesFormGroup.get('certificate_line' + index)
                        .value === certificateLineChoices.customTextGreen
                    "
                  >
                    <mat-form-field class="w-100 mb-2">
                      <mat-label>Custom Text for Line {{ index }}</mat-label>
                      <input
                        matInput
                        placeholder="Custom Text for Line {{ index }}"
                        [formControlName]="
                          'certificate_line' + index + '_custom_text'
                        "
                      />
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="col-9 text-center">
                <app-hirl-certificate-preview
                  [eep_program_slug]="selectedEEPProgram?.slug"
                  [certificate_line1]="
                    certificateNamesFormGroup.get('certificate_line1').value
                  "
                  [certificate_line1_custom_text]="
                    certificateNamesFormGroup.get(
                      'certificate_line1_custom_text'
                    ).value
                  "
                  [certificate_line2]="
                    certificateNamesFormGroup.get('certificate_line2').value
                  "
                  [certificate_line2_custom_text]="
                    certificateNamesFormGroup.get(
                      'certificate_line2_custom_text'
                    ).value
                  "
                  [certificate_line3]="
                    certificateNamesFormGroup.get('certificate_line3').value
                  "
                  [certificate_line3_custom_text]="
                    certificateNamesFormGroup.get(
                      'certificate_line3_custom_text'
                    ).value
                  "
                  [certificate_line4]="
                    certificateNamesFormGroup.get('certificate_line4').value
                  "
                  [certificate_line4_custom_text]="
                    certificateNamesFormGroup.get(
                      'certificate_line4_custom_text'
                    ).value
                  "
                  [certificate_line5]="
                    certificateNamesFormGroup.get('certificate_line5').value
                  "
                  [certificate_line5_custom_text]="
                    certificateNamesFormGroup.get(
                      'certificate_line5_custom_text'
                    ).value
                  "
                  [certificate_line6]="
                    certificateNamesFormGroup.get('certificate_line6').value
                  "
                  [certificate_line6_custom_text]="
                    certificateNamesFormGroup.get(
                      'certificate_line6_custom_text'
                    ).value
                  "
                  [certificate_line7]="
                    certificateNamesFormGroup.get('certificate_line7').value
                  "
                  [certificate_line7_custom_text]="
                    certificateNamesFormGroup.get(
                      'certificate_line7_custom_text'
                    ).value
                  "
                  [certificate_line8]="
                    certificateNamesFormGroup.get('certificate_line8').value
                  "
                  [certificate_line8_custom_text]="
                    certificateNamesFormGroup.get(
                      'certificate_line8_custom_text'
                    ).value
                  "
                  [certificate_line9]="
                    certificateNamesFormGroup.get('certificate_line9').value
                  "
                  [certificate_line9_custom_text]="
                    certificateNamesFormGroup.get(
                      'certificate_line9_custom_text'
                    ).value
                  "
                ></app-hirl-certificate-preview>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-12 text-center">
                <mat-error *ngIf="certificateNamesFormGroup.hasError('addressRequired')">
                  Address must be included in the NGBS Certificate Customization Options section of this registration.
                  Please review the fields above and ensure that “Address” is selected for one of the line items.
                </mat-error>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-12 text-center">
                <button matStepperPrevious mat-raised-button color="primary" class="mr-2">Back</button>
                <button matStepperNext mat-raised-button color="accent">
                  Next
                </button>
              </div>
            </div>
          </form>
        </mat-step>

        <mat-step
          [stepControl]="accessoryStructureFormGroup"
          [optional]="true"
          *ngIf="!editForm && !isWRIProgramSelected()"
        >
          <form [formGroup]="accessoryStructureFormGroup">
            <ng-template matStepLabel>
              Accessory Structures
              <div class="mat-step-optional ng-star-inserted">Optional for project registration; Required for project
                certification
              </div>
            </ng-template>
            <div class="mt-5">
              <div formArrayName="buildings">
                <div
                  class="card mb-2"
                  *ngFor="
                    let buildingControl of getAccessoryStructureControls();
                    let i = index
                  "
                >
                  <div class="card-header">
                    <div class="row">
                      <div class="col-11">Accessory Structure #{{ i + 1 }}</div>
                      <div class="col-1 text-right">
                        <button
                          mat-button
                          mat-icon-button
                          (click)="handleRemoveAccessoryStructure($event, i)"
                        >
                          <mat-icon>close</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="card-body" [formGroupName]="i">
                    <div class="row mt-5">
                      <div class="col-12">
                        <mat-form-field appearance="outline" class="w-100 mb-2">
                          <mat-label>Accessory Structure Address</mat-label>
                          <app-home-address-input
                            placeholder="Accessory Structure Address"
                            formControlName="accessory_structure_address"
                            required
                            [show-clear-btn]="true"
                          ></app-home-address-input>
                          <mat-error
                            *ngIf="
                              buildingControl
                                .get('accessory_structure_address')
                                .hasError('required')
                            "
                          >
                            This field is required
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <mat-form-field appearance="outline" class="w-100 mb-2">
                          <mat-label>Accessory Structure Description</mat-label>
                          <textarea
                            matInput
                            placeholder="Accessory Structure Description"
                            formControlName="accessory_structure_description"
                            required
                          ></textarea>
                          <mat-error
                            *ngIf="
                              buildingControl
                                .get('accessory_structure_description')
                                .hasError('required')
                            "
                          >
                            Accessory Structure Description is required
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 text-center">
                  <button
                    class="btn btn-outline-dark"
                    (click)="addAccessoryStructure()"
                  >
                    Add Accessory Structure
                  </button>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-12 text-center">
                  <button matStepperPrevious mat-raised-button color="primary" class="mr-2">Back</button>
                  <button matStepperNext mat-raised-button color="accent">
                    Next
                  </button>
                </div>
              </div>
            </div>
          </form>
        </mat-step>


        <mat-step
          [stepControl]="standAloneCommercialSpaceFormGroup"
          [optional]="true"
          *ngIf="!editForm"
        >
          <form [formGroup]="standAloneCommercialSpaceFormGroup">
            <ng-template matStepLabel>
              Entire Building Commercial Spaces
              <div class="mat-step-optional ng-star-inserted">Optional for project registration; Required for project
                certification
              </div>
            </ng-template>
            <div class="mt-5">
              <div formArrayName="buildings">
                <div
                  class="card mb-2"
                  *ngFor="
                    let buildingControl of getStandAloneCommercialSpaceControls();
                    let i = index
                  "
                >
                  <div class="card-header">
                    <div class="row">
                      <div class="col-11">Entire Building Commercial Space #{{ i + 1 }}</div>
                      <div class="col-1 text-right">
                        <button
                          mat-button
                          mat-icon-button
                          (click)="onRemoveStandAloneCommercialSpace($event, i)"
                        >
                          <mat-icon>close</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="card-body" [formGroupName]="i">
                    <div class="row mt-5">
                      <div class="col-12">
                        <mat-form-field class="w-100 mb-2">
                          <mat-label>Entire Building Commercial Space Address</mat-label>
                          <app-home-address-input
                            placeholder="Entire Building Commercial Space Address"
                            formControlName="stand_alone_commercial_space_address"
                            required
                            [show-clear-btn]="true"
                          ></app-home-address-input>
                          <mat-error
                            *ngIf="
                              buildingControl
                                .get('stand_alone_commercial_space_address')
                                .hasError('required')
                            "
                          >
                            This field is required
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <mat-form-field
                          class="w-100 mb-2"
                        >
                          <mat-label>Type</mat-label>
                          <mat-select
                            aria-label="Commercial Space Type"
                            formControlName="commercial_space_type"
                          >
                            <mat-option
                              *ngFor="
                                        let item of projectCommercialSpaceTypeLabelMapping
                                          | keyvalue
                                      "
                              [value]="item.key"
                            >{{ item.value }}
                            </mat-option
                            >
                          </mat-select>
                          <mat-error
                            *ngIf="
                                      buildingControl
                                        .get('commercial_space_type')
                                        .hasError('required')
                                    "
                          >
                            Commercial Space Type is required
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-6">
                        <mat-form-field
                          class="w-100 mb-2"
                        >
                          <mat-label>Total Commercial Space</mat-label>
                          <input
                            matInput
                            type="number"
                            placeholder="Total Commercial Space"
                            formControlName="total_commercial_space"
                          />
                          <span matSuffix> sq. ft </span>
                          <mat-error
                            *ngIf="
                                      buildingControl
                                        .get('total_commercial_space')
                                        .hasError('required')
                                    "
                          >
                            This field is required
                          </mat-error>
                          <mat-error
                            *ngIf="
                                      buildingControl
                                        .get('total_commercial_space')
                                        .hasError('min') ||
                                      buildingControl
                                        .get('total_commercial_space')
                                        .hasError('max')
                                    "
                          >
                            Value must be between 0 and 1000000 sq. ft
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 text-center">
                  <button
                    class="btn btn-outline-dark"
                    (click)="onAddStandAloneCommercialSpace($event)"
                  >
                    Add Entire Building Commercial Space
                  </button>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-12 text-center">
                  <button matStepperPrevious mat-raised-button color="primary" class="mr-2">Back</button>
                  <button matStepperNext mat-raised-button color="accent">
                    Next
                  </button>
                </div>
              </div>
            </div>
          </form>
        </mat-step>


        <mat-step
          [stepControl]="buildingFormGroup"
          [optional]="true"
          *ngIf="!editForm"
        >
          <form [formGroup]="buildingFormGroup">
            <ng-template matStepLabel>
              Building Information
              <div class="mat-step-optional ng-star-inserted">Optional for project registration; Required for project
                certification
              </div>
            </ng-template>
            <div class="mt-5">
              <div formArrayName="buildings">
                <div
                  class="card mb-2"
                  *ngFor="
                    let buildingControl of getBuildingsControls();
                    let i = index
                  "
                >
                  <div class="card-header">
                    <div class="row">
                      <div class="col-11">Building #{{ i + 1 }}</div>
                      <div class="col-1 text-right">
                        <button
                          mat-button
                          mat-icon-button
                          (click)="handleRemoveBuilding($event, i)"
                        >
                          <mat-icon>close</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="card-body" [formGroupName]="i">
                    <div class="row">
                      <div class="col-12">
                        <mat-form-field
                          style="width: 100%"
                          appearance="outline"
                          class="mb-2"
                        >
                          <mat-label>Building Number</mat-label>
                          <input
                            matInput
                            type="text"
                            placeholder="Building Number"
                            formControlName="building_number"
                            required
                          />
                          <mat-error
                            *ngIf="
                              buildingControl
                                .get('building_number')
                                .hasError('required')
                            "
                          >
                            This field is required
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <mat-form-field
                          class="mb-2 w-100"
                        >
                          <mat-label>Address</mat-label>
                          <app-home-address-input
                            placeholder="Address"
                            formControlName="address"
                            required
                            [show-clear-btn]="true"
                          ></app-home-address-input>
                          <mat-error
                            *ngIf="
                              buildingControl
                                .get('address')
                                .hasError('required')
                            "
                          >
                            This field is required
                          </mat-error>
                          <mat-error
                            *ngIf="
                              buildingControl
                                .get('address')
                                .hasError(
                                  'home_with_this_address_already_exists'
                                )
                            "
                          >
                            Home with this address already exists
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <mat-form-field class="mb-2 w-100">
                          <mat-label>Number of Units</mat-label>
                          <input
                            matInput
                            type="number"
                            placeholder="Number of Units"
                            formControlName="number_of_units"
                            required
                          />
                          <mat-error
                            *ngIf="
                              buildingControl
                                .get('number_of_units')
                                .hasError('required')
                            "
                          >
                            This field is required
                          </mat-error>
                          <mat-error
                            *ngIf="
                              buildingControl
                                .get('number_of_units')
                                .hasError('min') ||
                              buildingControl
                                .get('number_of_units')
                                .hasError('max')
                            "
                          >
                            Value must be between 2 and 1000000
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12">
                        <mat-form-field class="mb-2 w-100">
                          <mat-label>Story Count</mat-label>
                          <input
                            matInput
                            type="number"
                            placeholder="Story Count"
                            formControlName="story_count"
                            required
                          />
                          <mat-error
                            *ngIf="
                              buildingControl
                                .get('story_count')
                                .hasError('required')
                            "
                          >
                            This field is required
                          </mat-error>
                          <mat-error
                            *ngIf="
                              buildingControl
                                .get('story_count')
                                .hasError('min') ||
                              buildingControl.get('story_count').hasError('max')
                            "
                          >
                            Value must be between 1 and 1000000 sq. ft
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12">
                        <mat-form-field appearance="outline" class="w-100 mb-2">
                          <mat-label>HUD Disaster Case Number</mat-label>
                          <input
                            matInput
                            type="text"
                            placeholder="HUD Disaster Case Number"
                            formControlName="hud_disaster_case_number"
                          />
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row" *ngIf="hirlGreenEnergyBadges.length">
                      <div class="col-12">
                        <mat-hint>
                          Identify any NGBS Green+ badges that you intend to
                          pursue. Note: with the exception of Zero Water, all
                          badges are available for both New Construction and
                          Renovation. See NGBS Green+ Compliance Handbook for
                          more details.
                        </mat-hint>
                        <mat-form-field appearance="outline" class="w-100 mb-2">
                          <mat-label>NGBS Green+ Badges:</mat-label>
                          <mat-select
                            aria-label="Select Green Energy Badges"
                            formControlName="green_energy_badges"
                            multiple
                            panelClass="green-energy-badges-panel-override"
                          >
                            <mat-option
                              *ngFor="
                                let hirlGreenBadge of hirlGreenEnergyBadges
                              "
                              [value]="hirlGreenBadge.id"
                            >{{ hirlGreenBadge.name }}
                            </mat-option
                            >
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>

                    <div class="row mt-1">
                      <div class="col-12">
                        <div>
                          <mat-form-field class="mb-2 w-100">
                            <mat-label>Is this an appeals project?</mat-label>
                            <mat-select aria-label="Is this an appeals project?" formControlName="appeals_project">
                              <mat-option
                                *ngFor="let appealsProjectType of HIRLProjectAppealsProjectTypeMapping | defaultOrderKeyValue"
                                [value]="appealsProjectType.key">{{ appealsProjectType.value }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>

                    <div class="row mt-1">
                      <div class="col-12">
                        <div>
                          <mat-form-field class="mb-2 w-100">
                            <mat-label>Is this building a two-over-two /maisonette/stacked townhouse?</mat-label>
                            <mat-select aria-label="Is this building a two-over-two /maisonette/stacked townhouse"
                                        formControlName="is_townhouse">
                              <mat-option [value]="false">No</mat-option>
                              <mat-option [value]="true">Yes</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>

                    <div class="row mt-2" *ngIf="!isWRIProgramSelected()">
                      <div class="col-12">
                        <mat-form-field class="w-100 mb-2">
                          <mat-label
                          >Do you intend to employ the NGBS Green Alternative
                            Multifamily Verification Protocol (Sampling) ?
                          </mat-label
                          >
                          <mat-select
                            formControlName="sampling"
                            panelClass="sampling-panel-override"
                          >
                            <mat-option
                              *ngFor="
                        let item of samplingMapping
                          | defaultOrderKeyValue
                      "
                              [value]="item.key"
                            >
                              {{ item.value }}
                            </mat-option>
                          </mat-select>
                          <mat-error
                            *ngIf="
                      buildingControl.get('sampling').hasError('required')
                    "
                          >
                            This field is required
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>

                    <div
                      class="row mt-2"
                      *ngIf="
                        customerHIRLSettings.requireRoughInspectionProgramList.includes(
                          this.selectedEEPProgram?.slug
                        )
                      "
                    >
                      <div class="col-12">
                        <div>
                          <p>
                            <mat-label
                            >Rough inspections are typically not required if
                              there will be no renovation behind drywall. Will
                              this remodel project require a rough
                              inspection?
                            </mat-label
                            >
                          </p>
                          <mat-radio-group
                            aria-label="Will this remodel project require a rough inspection?"
                            formControlName="is_require_rough_inspection"
                          >
                            <mat-radio-button [value]="true" class="mr-5">
                              Yes
                            </mat-radio-button>
                            <mat-radio-button [value]="false">
                              No
                            </mat-radio-button>
                          </mat-radio-group>
                        </div>
                      </div>
                    </div>

                    <div
                      class="row mt-2"
                      *ngIf="
                        customerHIRLSettings.wriSeekingProgramList.includes(
                          this.selectedEEPProgram?.slug
                        )
                      "
                    >
                      <div class="col-12">
                        <div>
                          <p>
                            <mat-label
                            >Is this project seeking a WRI?
                            </mat-label
                            >
                          </p>
                          <mat-radio-group
                            aria-label="Is this project seeking a WRI?"
                            formControlName="is_require_wri_certification"
                          >
                            <mat-radio-button [value]="true" class="mr-5">
                              Yes
                            </mat-radio-button>
                            <mat-radio-button [value]="false">
                              No
                            </mat-radio-button>
                          </mat-radio-group>
                        </div>
                      </div>
                    </div>

                    <div
                      class="row mt-2"
                      *ngIf="
                        customerHIRLSettings.waterSenseProgramList.includes(
                          this.selectedEEPProgram?.slug
                        )
                      "
                    >
                      <div class="col-12">
                        <div>
                          <p>
                            <b>WaterSense Certification:</b>
                            The WaterSense label is available for home seeking
                            NGBS Green Certification under the 2020 NGBS
                            version. Do you intend to pursue WaterSense
                            certification for this building? Note: WRI Verifier
                            training must be completed prior to final
                            inspection.
                          </p>
                          <p>
                            <mat-label
                            >Is the project seeking WaterSense
                              Certification?
                            </mat-label
                            >
                          </p>
                          <mat-radio-group
                            aria-label="Is the project seeking WaterSense Certification?"
                            formControlName="is_require_water_sense_certification"
                          >
                            <mat-radio-button [value]="true" class="mr-5">
                              Yes
                            </mat-radio-button>
                            <mat-radio-button [value]="false">
                              No
                            </mat-radio-button>
                          </mat-radio-group>
                        </div>
                      </div>
                    </div>

                    <div class="row" formArrayName="commercial_spaces">
                      <div class="col-12">
                        <div
                          class="card mb-2"
                          *ngFor="
                            let commercialSpaceControl of getCommercialSpaceControls(
                              buildingControl
                            );
                            let j = index
                          "
                          [formGroupName]="j"
                        >
                          <div class="card-header">
                            <div class="row">
                              <div class="col-11">
                                Commercial Space #{{ j + 1 }}
                              </div>
                              <div class="col-1 text-right">
                                <button
                                  mat-button
                                  mat-icon-button
                                  (click)="
                                    handleRemoveCommercialSpace($event, i, j)
                                  "
                                >
                                  <mat-icon>close</mat-icon>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="card-body">
                            <div class="row">
                              <div class="col-12">
                                <mat-form-field
                                  appearance="outline"
                                  class="w-100 mb-2"
                                >
                                  <mat-label
                                  >Commercial Space Street Line 2
                                  </mat-label
                                  >
                                  <textarea
                                    matInput
                                    placeholder="Commercial Space Street Line 2"
                                    formControlName="commercial_space_street_line_2"
                                  ></textarea>
                                  <mat-error
                                    *ngIf="
                                      commercialSpaceControl
                                        .get('commercial_space_street_line_2')
                                        .hasError('required')
                                    "
                                  >
                                    Commercial Space Street Line 2 is required
                                  </mat-error>
                                </mat-form-field>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-6">
                                <mat-form-field
                                  class="w-100 mb-2"
                                >
                                  <mat-label>Type</mat-label>
                                  <mat-select
                                    aria-label="Commercial Space Type"
                                    formControlName="commercial_space_type"
                                  >
                                    <mat-option
                                      *ngFor="
                                        let item of projectCommercialSpaceTypeLabelMapping
                                          | keyvalue
                                      "
                                      [value]="item.key"
                                    >{{ item.value }}
                                    </mat-option
                                    >
                                  </mat-select>
                                  <mat-error
                                    *ngIf="
                                      commercialSpaceControl
                                        .get('commercial_space_type')
                                        .hasError('required')
                                    "
                                  >
                                    Commercial Space Type is required
                                  </mat-error>
                                </mat-form-field>
                              </div>
                              <div class="col-6">
                                <mat-form-field
                                  class="w-100 mb-2"
                                >
                                  <mat-label>Total Commercial Space</mat-label>
                                  <input
                                    matInput
                                    type="number"
                                    placeholder="Total Commercial Space"
                                    formControlName="total_commercial_space"
                                  />
                                  <span matSuffix> sq. ft </span>
                                  <mat-error
                                    *ngIf="
                                      commercialSpaceControl
                                        .get('total_commercial_space')
                                        .hasError('required')
                                    "
                                  >
                                    This field is required
                                  </mat-error>
                                  <mat-error
                                    *ngIf="
                                      commercialSpaceControl
                                        .get('total_commercial_space')
                                        .hasError('min') ||
                                      commercialSpaceControl
                                        .get('total_commercial_space')
                                        .hasError('max')
                                    "
                                  >
                                    Value must be between 0 and 1000000 sq. ft
                                  </mat-error>
                                </mat-form-field>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 text-center">
                        <button
                          class="btn btn-outline-dark"
                          (click)="addCommercialSpace(i)"
                          *ngIf="!isWRIProgramSelected()"
                        >
                          Add Commercial Space (if applicable)
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12"></div>
              </div>
              <div class="row">
                <div class="col-12 text-center">
                  <button class="btn btn-outline-dark" (click)="addBuilding()">
                    Add Building
                  </button>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-12 text-center">
                  <button matStepperPrevious mat-raised-button color="primary" class="mr-2">Back</button>
                  <button matStepperNext mat-raised-button color="accent">
                    Next
                  </button>
                </div>
              </div>
            </div>
          </form>
        </mat-step>

        <mat-step [stepControl]="generalContractorFormGroup" [optional]="true">
          <form [formGroup]="generalContractorFormGroup">
            <ng-template matStepLabel>
              Builder/General Contractor
              <div class="mat-step-optional ng-star-inserted">Optional – informational only</div>
            </ng-template>
            <div class="row mt-5">
              <div class="col-12">
                <mat-form-field class="w-100">
                  <mat-label>Builder/General Contractor Organization</mat-label>
                  <input matInput placeholder="Type to search" formControlName="legacy_builder_organization_info"
                         [matAutocomplete]="auto_general_contractor_organization"
                         [required]="generalContractorFormGroup.controls['legacy_builder_organization_info'].errors !== null && generalContractorFormGroup.controls['legacy_builder_organization_info'].errors.required">
                  <mat-error
                    *ngIf="generalContractorFormGroup.controls['legacy_builder_organization_info'].hasError('required')">
                    Developer Organization is required - Must select option from list
                  </mat-error>
                  <mat-error
                    *ngIf="generalContractorFormGroup.controls['legacy_builder_organization_info'].hasError('invalidObject')">
                    Must select option from list
                  </mat-error>
                  <mat-autocomplete #auto_general_contractor_organization="matAutocomplete" autoActiveFirstOption
                                    [displayWith]="displayGeneralContractorOrganizationMenu">
                    <mat-option
                      *ngFor="let generalContractorOrganization of (filteredGeneralContractorOrganizations$ | async)?.results"
                      [value]="generalContractorOrganization">
                      <app-company-display [company]="generalContractorOrganization"
                                           [displayType]="'nameAndAddress'"></app-company-display>
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </div>
            <div class="row" *ngIf="generalContractorFormGroup.get('legacy_builder_organization_info').value">
              <div class="col-12">
                <mat-form-field class="w-100 mb-2">
                  <mat-label>Contact</mat-label>
                  <app-contact-card-select formControlName="legacy_builder_organization_contact"
                                           [filterParams]="{company_and_related_users: generalContractorFormGroup.get('legacy_builder_organization_info').value.id}"
                                           [contactCardInitialData]="{company: generalContractorFormGroup.get('legacy_builder_organization_info').value.id}"
                                           required></app-contact-card-select>
                  <mat-error
                    *ngIf="generalContractorFormGroup.get('legacy_builder_organization_contact').hasError('required')">
                    This field is required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-12 text-center">
                <button matStepperPrevious mat-raised-button color="primary" class="mr-2">Back</button>
                <button matStepperNext mat-raised-button color="accent">
                  Next
                </button>
              </div>
            </div>
          </form>
        </mat-step>

        <mat-step [stepControl]="developerFormGroup" [optional]="true">
          <form [formGroup]="developerFormGroup">
            <ng-template matStepLabel>
              Developer Information
              <div class="mat-step-optional ng-star-inserted">Optional – informational only</div>
            </ng-template>
            <div class="row mt-5">
              <div class="col-12">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Developer Organization</mat-label>
                  <input matInput placeholder="Type to search" formControlName="developer_organization_info"
                         [matAutocomplete]="auto_developer_organization"
                         [required]="developerFormGroup.controls['developer_organization_info'].errors !== null && developerFormGroup.controls['developer_organization_info'].errors.required">
                  <mat-error *ngIf="developerFormGroup.controls['developer_organization_info'].hasError('required')">
                    Developer Organization is required - Must select option from list
                  </mat-error>
                  <mat-error
                    *ngIf="developerFormGroup.controls['developer_organization_info'].hasError('invalidObject')">
                    Must select option from list
                  </mat-error>
                  <mat-autocomplete #auto_developer_organization="matAutocomplete" autoActiveFirstOption
                                    [displayWith]="displayDeveloperOrganizationMenu">
                    <mat-option *ngFor="let developerOrganization of (filteredDeveloperOrganizations$ | async)?.results"
                                [value]="developerOrganization">
                      <app-company-display [company]="developerOrganization"
                                           [displayType]="'nameAndAddress'"></app-company-display>
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <small class="mat-hint">
                  List of all available
                  <a [routerLink]="['', 'company', 'developer']" target="_blank"
                     matTooltip="Open Developer Organizations page in new window">
                    Developer Organizations
                    <fa-icon [icon]="['fas', 'external-link-alt']"></fa-icon>
                  </a>
                </small>
              </div>
            </div>
            <div class="row" *ngIf="developerFormGroup.get('developer_organization_info').value">
              <div class="col-12">
                <mat-form-field appearance="outline" class="w-100 mb-2">
                  <mat-label>Contact</mat-label>
                  <app-contact-card-select formControlName="developer_organization_contact"
                                           [filterParams]="{company_and_related_users: developerFormGroup.get('developer_organization_info').value.id}"
                                           [contactCardInitialData]="{company: developerFormGroup.get('developer_organization_info').value.id}"
                                           required></app-contact-card-select>
                  <mat-error
                    *ngIf="developerFormGroup.get('developer_organization_contact').hasError('required')">
                    This field is required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-12 text-center">
                <button matStepperPrevious mat-raised-button color="primary" class="mr-2">Back</button>
                <button matStepperNext mat-raised-button color="accent">
                  Next
                </button>
              </div>
            </div>
          </form>
        </mat-step>

        <mat-step [stepControl]="communityOwnerFormGroup" [optional]="true">
          <form [formGroup]="communityOwnerFormGroup">
            <ng-template matStepLabel>
              Owner Information
              <div class="mat-step-optional ng-star-inserted">Optional – informational only</div>
            </ng-template>
            <div class="row mt-5">
              <div class="col-12">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Owner Organization</mat-label>
                  <input matInput placeholder="Type to search" formControlName="community_owner_organization_info"
                         [matAutocomplete]="auto_community_organization"
                         [required]="communityOwnerFormGroup.controls['community_owner_organization_info'].errors !== null && communityOwnerFormGroup.controls['community_owner_organization_info'].errors.required">
                  <mat-error
                    *ngIf="communityOwnerFormGroup.controls['community_owner_organization_info'].hasError('required')">
                    Owner Organization is required - Must select option from list
                  </mat-error>
                  <mat-error
                    *ngIf="communityOwnerFormGroup.controls['community_owner_organization_info'].hasError('invalidObject')">
                    Must select option from list
                  </mat-error>
                  <mat-autocomplete #auto_community_organization="matAutocomplete" autoActiveFirstOption
                                    [displayWith]="displayCommunityOwnerOrganizationMenu">
                    <mat-option
                      *ngFor="let communityOwnerOrganization of (filteredCommunityOwnerOrganizations$ | async)?.results"
                      [value]="communityOwnerOrganization">
                      <app-company-display [company]="communityOwnerOrganization"
                                           [displayType]="'nameAndAddress'"></app-company-display>
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>

                <small class="mat-hint">
                  List of all available
                  <a [routerLink]="['', 'company', 'communityowner']" target="_blank"
                     matTooltip="Open Community Owner Organizations page in new window">
                    Community Owner Organizations
                    <fa-icon [icon]="['fas', 'external-link-alt']"></fa-icon>
                  </a>
                </small>
              </div>
            </div>
            <div class="row" *ngIf="communityOwnerFormGroup.get('community_owner_organization_info').value">
              <div class="col-12">
                <mat-form-field appearance="outline" class="w-100 mb-2">
                  <mat-label>Contact</mat-label>
                  <app-contact-card-select formControlName="community_owner_organization_contact"
                                           [filterParams]="{company_and_related_users: communityOwnerFormGroup.get('community_owner_organization_info').value.id}"
                                           [contactCardInitialData]="{company: communityOwnerFormGroup.get('community_owner_organization_info').value.id}"
                                           required></app-contact-card-select>
                  <mat-error
                    *ngIf="communityOwnerFormGroup.get('community_owner_organization_contact').hasError('required')">
                    This field is required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-12 text-center">
                <button matStepperPrevious mat-raised-button color="primary" class="mr-2">Back</button>
                <button matStepperNext mat-raised-button color="accent">
                  Next
                </button>
              </div>
            </div>
          </form>
        </mat-step>

        <mat-step [stepControl]="architectFormGroup" [optional]="true">
          <form [formGroup]="architectFormGroup">
            <ng-template matStepLabel>
              Architect Information
              <div class="mat-step-optional ng-star-inserted">Optional – informational only</div>
            </ng-template>
            <div class="row mt-5">
              <div class="col-12">
                <mat-form-field class="mb-2 w-100">
                  <mat-label>Architect Organization</mat-label>
                  <input matInput placeholder="Type to search" formControlName="architect_organization_info"
                         [matAutocomplete]="auto_architect_organization"
                         [required]="architectFormGroup.controls['architect_organization_info'].errors !== null && architectFormGroup.controls['architect_organization_info'].errors.required">
                  <mat-error *ngIf="architectFormGroup.controls['architect_organization_info'].hasError('required')">
                    Architect Organization is required - Must select option from list
                  </mat-error>
                  <mat-error
                    *ngIf="architectFormGroup.controls['architect_organization_info'].hasError('invalidObject')">
                    Must select option from list
                  </mat-error>
                  <mat-autocomplete #auto_architect_organization="matAutocomplete" autoActiveFirstOption
                                    [displayWith]="displayArchitectOrganizationMenu">
                    <mat-option *ngFor="let architectOrganization of (filteredArchitectOrganizations$ | async)?.results"
                                [value]="architectOrganization">
                      <app-company-display [company]="architectOrganization"
                                           [displayType]="'nameAndAddress'"></app-company-display>
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </div>

            <div class="row" *ngIf="architectFormGroup.get('architect_organization_info').value">
              <div class="col-12">
                <mat-form-field appearance="outline" class="w-100 mb-2">
                  <mat-label>Contact</mat-label>
                  <app-contact-card-select formControlName="architect_organization_contact"
                                           [filterParams]="{company_and_related_users: architectFormGroup.get('architect_organization_info').value.id}"
                                           [contactCardInitialData]="{company: architectFormGroup.get('architect_organization_info').value.id}"
                                           required></app-contact-card-select>
                  <mat-error
                    *ngIf="architectFormGroup.get('architect_organization_contact').hasError('required')">
                    This field is required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-12 text-center">
                <button matStepperPrevious mat-raised-button color="primary" class="mr-2">Back</button>
                <button matStepperNext mat-raised-button color="accent">
                  Next
                </button>
              </div>
            </div>
          </form>
        </mat-step>

        <mat-step [stepControl]="marketingFormGroup" [optional]="true">
          <form [formGroup]="marketingFormGroup">
            <ng-template matStepLabel>
              Marketing Information
              <div class="mat-step-optional ng-star-inserted">Optional – informational only</div>
            </ng-template>
            <div class="row mt-5">
              <div class="col-6">
                <mat-form-field
                  style="width: 100%"
                  appearance="outline"
                  class="mb-2"
                >
                  <mat-label>First Name</mat-label>
                  <input
                    matInput
                    placeholder="First Name"
                    name="marketing_first_name"
                    formControlName="marketing_first_name"
                  />
                  <mat-error
                    *ngIf="
                      marketingFormGroup.controls[
                        'marketing_first_name'
                      ].hasError('required')
                    "
                  >
                    First Name is required
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-form-field
                  style="width: 100%"
                  appearance="outline"
                  class="mb-2"
                >
                  <mat-label>Last Name</mat-label>
                  <input
                    matInput
                    placeholder="Last Name"
                    name="marketing_last_name"
                    formControlName="marketing_last_name"
                  />
                  <mat-error
                    *ngIf="
                      marketingFormGroup.controls[
                        'marketing_last_name'
                      ].hasError('required')
                    "
                  >
                    Last Name is required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <mat-form-field
                  style="width: 100%"
                  appearance="outline"
                  class="mb-2"
                >
                  <mat-label>Email Address</mat-label>
                  <input
                    matInput
                    placeholder="Email Address"
                    name="marketing_email"
                    formControlName="marketing_email"
                  />
                  <mat-error
                    *ngIf="
                      marketingFormGroup.controls['marketing_email'].hasError(
                        'required'
                      )
                    "
                  >
                    Email Address is required
                  </mat-error>
                  <mat-error
                    *ngIf="
                      marketingFormGroup.controls['marketing_email'].hasError(
                        'email'
                      )
                    "
                  >
                    Email Address is invalid
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-form-field
                  style="width: 100%"
                  appearance="outline"
                  class="mb-2"
                >
                  <mat-label>Phone Number</mat-label>
                  <input
                    matInput
                    placeholder="Phone Number"
                    name="marketing_phone"
                    formControlName="marketing_phone"
                    mask="000-000-0000"
                    [showMaskTyped]="true"
                  />
                  <mat-error
                    *ngIf="
                      marketingFormGroup.controls['marketing_phone'].hasError(
                        'required'
                      )
                    "
                  >
                    Phone Number is required
                  </mat-error>
                  <mat-error
                    *ngIf="
                      marketingFormGroup.controls['marketing_phone'].hasError(
                        'pattern'
                      )
                    "
                  >
                    Phone Number is invalid
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <mat-form-field
                  style="width: 100%"
                  appearance="outline"
                  class="mb-2"
                >
                  <mat-label>Project Description</mat-label>
                  <textarea
                    matInput
                    placeholder="Project Description"
                    formControlName="project_description"
                  ></textarea>
                  <mat-error
                    *ngIf="
                      marketingFormGroup.controls[
                        'project_description'
                      ].hasError('required')
                    "
                  >
                    This field is required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <mat-form-field appearance="outline" class="w-100 mb-2">
                  <mat-label>Website URL</mat-label>
                  <input
                    matInput
                    placeholder="Website URL"
                    formControlName="project_website_url"
                  />
                  <mat-error
                    *ngIf="
                      marketingFormGroup.controls[
                        'project_website_url'
                      ].hasError('required')
                    "
                  >
                    This field is required
                  </mat-error>
                  <mat-error
                    *ngIf="
                      marketingFormGroup.controls[
                        'project_website_url'
                      ].hasError('websiteURLValidator')
                    "
                  >
                    Incorrect url format. Example: www.example.com or
                    https://www.example.com
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-12 text-center">
                <button matStepperPrevious mat-raised-button color="primary" class="mr-2">Back</button>
                <button matStepperNext mat-raised-button color="accent" *ngIf="!editForm">
                  Next
                </button>
              </div>
            </div>
          </form>
        </mat-step>

        <mat-step *ngIf="!editForm">
          <ng-template matStepLabel>Confirm</ng-template>
          <p>
            Before clicking the Submit button, please be sure that the
            information is correct as clicking Submit will forward the project
            information to NGBS staff for review and approval.
          </p>
          <div>
            <button matStepperPrevious mat-raised-button color="primary" class="mr-2">Back</button>
            <button
              matStepperNext mat-raised-button color="accent"
              (click)="handleCreate($event)"
            >
              Submit
            </button>
          </div>
        </mat-step>
      </mat-vertical-stepper>
    </div>
  </div>

  <div *ngIf="editForm" class="row">
    <div class="col-12 text-center">
      <button
        type="button"
        (click)="handleEdit($event)"
        class="btn btn-primary btn-lg mr-2"
        style="background-color: #bf0d25; border-color: #a70b20"
      >
        Save
      </button>
    </div>
  </div>

  <router-outlet></router-outlet>
</div>
