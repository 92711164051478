import { Component } from '@angular/core';
import { BaseAppliancesDirective } from '../base-appliances.directive';
import {
  AppliancesBackendDict,
  createRefrigeratorForm,
  FIELD_LABELS,
} from '@/data/simulation/models/Appliances';
import { FormGroup } from '@angular/forms';
import { AuxEnergyUnitLabels } from '@/data/simulation/enumerations';

@Component({
  selector: 'app-refrigerator',
  templateUrl: './refrigerator.component.html',
  styleUrls: ['./refrigerator.component.scss'],
})
export class RefrigeratorComponent extends BaseAppliancesDirective {
  createForm: (data: AppliancesBackendDict) => FormGroup<any> =
    createRefrigeratorForm;
  fieldLabels = FIELD_LABELS;
  auxEnergyUnitLabels = AuxEnergyUnitLabels;
}
