import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import {
  AreaUnitLabels,
  ColorLabels,
  LengthUnitLabels,
  LocationLabels,
} from '@/data/simulation/enumerations';
import { FormGroup } from '@angular/forms';
import {
  AboveGradeWallTypeChangeDialogComponent,
  AboveGradeWallTypeChangeDialogData,
} from '../above-grade-wall-type-change-dialog/above-grade-wall-type-change-dialog.component';
import { DIALOG_WIDTH } from '@/modules/simulation/constants';
import { selectAboveGradeWallViewSet } from '@/modules/simulation/state/above-grade-wall/selectors';
import { selectAboveGradeWallTypeNameAndError } from '@/modules/simulation/state/above-grade-wall-type/selectors';
import {
  removeAboveGradeWall,
  updateAboveGradeWall,
} from '@/modules/simulation/state/above-grade-wall/actions';
import {
  AboveGradeWallBackendDict,
  ExteriorColor,
  ExteriorLocation,
  FIELD_LABELS,
  InteriorLocation,
  createAboveGradeWallForm,
} from '@/data/simulation/models/enclosure/AboveGradeWall';
import { removeAboveGradeWallType } from '@/modules/simulation/state/above-grade-wall-type/actions';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { CREATE_MODE, EDIT_MODE } from '@/core/constants';
import { cloneAboveGradeWall } from '@/modules/simulation/state/simulation/actions';

@Component({
  selector: 'app-above-grade-wall',
  templateUrl: './above-grade-wall.component.html',
  styleUrls: ['./above-grade-wall.component.scss'],
})
export class AboveGradeWallComponent implements OnDestroy, OnInit {
  @Input() entityId: number;
  fieldLabels = FIELD_LABELS;

  lengthLabels = LengthUnitLabels;
  areaLabels = AreaUnitLabels;

  entity: AboveGradeWallBackendDict = null;
  loading = false;

  private componentDestroyed$ = new Subject();
  typeViewSubscription$ = null;
  public exteriorLocationLabelMapping = {};
  public interiorLocationLabelMapping = {};

  public aboveGradeWallTypeName: string;
  public aboveGradeWallTypeErrorCount = 0;
  aboveGradeWallTypeRValue: number;

  public aboveGradeWallFormGroup: FormGroup;
  colorLabels = {};

  constructor(public dialog: MatDialog, public store: Store) {
    Object.values(InteriorLocation).forEach(value => {
      this.interiorLocationLabelMapping[value] = LocationLabels[value];
    });
    Object.values(ExteriorLocation).forEach(value => {
      this.exteriorLocationLabelMapping[value] = LocationLabels[value];
    });
    Object.values(ExteriorColor).forEach(value => {
      this.colorLabels[value] = ColorLabels[value];
    });
  }

  updateTypeDetails(typeId: number) {
    if (this.typeViewSubscription$) {
      this.typeViewSubscription$.unsubscribe();
    }
    if (!typeId) {
      this.aboveGradeWallTypeName = null;
      this.aboveGradeWallTypeRValue = null;
      this.aboveGradeWallTypeErrorCount = 1;
      return;
    }

    this.store
      .select(selectAboveGradeWallTypeNameAndError(typeId))
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(({ name, surfaceRValue, errorCount }) => {
        this.aboveGradeWallTypeName = name;
        this.aboveGradeWallTypeErrorCount = errorCount;
        this.aboveGradeWallTypeRValue = surfaceRValue;
      });
  }

  ngOnInit(): void {
    this.store
      .select(selectAboveGradeWallViewSet(this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(({ data, loading }) => {
        this.entity = data;
        this.loading = loading;
        if (loading) return;

        if (!this.aboveGradeWallFormGroup) {
          this.aboveGradeWallFormGroup = createAboveGradeWallForm(data);
        } else {
          this.aboveGradeWallFormGroup.patchValue(data);
        }

        this.updateTypeDetails(data.type);
      });
  }

  openEditWallTypeChangeDialog(data: AboveGradeWallTypeChangeDialogData) {
    return this.dialog.open(AboveGradeWallTypeChangeDialogComponent, {
      width: DIALOG_WIDTH,
      disableClose: true,
      data,
    });
  }

  removeAboveGradeWall() {
    let wallName = this.entity.name;
    if (wallName === null || wallName === '') {
      wallName = 'Untitled';
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '50%',
      data: {
        title: `Do you want to delete "${wallName}" wall ?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (result) {
          this.store.dispatch(
            removeAboveGradeWall({ aboveGradeWall: this.entity })
          );
        }
      });
  }

  editWallType() {
    const updatedWallType = this.openEditWallTypeChangeDialog({
      commit: true,
      mode: EDIT_MODE,
      entityId: this.entity.type,
    });

    updatedWallType.afterClosed().subscribe(() => {
      this.updateTypeDetails(this.entity.type);
    });
  }

  addWallType() {
    this.onSave(this.aboveGradeWallFormGroup.value);
    const newWallDialog = this.openEditWallTypeChangeDialog({
      commit: true,
      mode: CREATE_MODE,
      parentId: this.entityId,
    });

    newWallDialog.afterClosed().subscribe(wallType => {
      if (!wallType) {
        return;
      }

      this.aboveGradeWallFormGroup.patchValue({ type: wallType.id });
      this.updateTypeDetails(wallType.id);
    });
  }

  removeWallType() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '50%',
      data: {
        title: `Do you want to delete "${this.aboveGradeWallTypeName}" wall type ?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (result) {
          this.store.dispatch(
            removeAboveGradeWallType({
              wallId: this.entityId,
              wallTypeId: this.entity.type,
            })
          );
        }
      });
  }

  onSave(aboveGradeWallChanges: Partial<AboveGradeWallBackendDict>): void {
    if (Object.keys(aboveGradeWallChanges).length === 0) return;

    this.store.dispatch(
      updateAboveGradeWall({
        aboveGradeWallChanges: aboveGradeWallChanges,
      })
    );
  }

  cloneWall() {
    this.store.dispatch(cloneAboveGradeWall({ id: this.entityId }));
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
