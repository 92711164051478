import { ShelterClassInfoDialogComponent } from '@/modules/simulation/components/enclosure/shelter-class-info-dialog/shelter-class-info-dialog.component';
import { FieldLabels } from '../base';
import {
  ModelFormControl,
  ModelFormGroup,
  createConditionalValidator,
} from '../ModelForm';
import { Validators } from '@angular/forms';
import { requiredAsWarning } from '@/modules/simulation/validators/helper';

export const FIELD_LABELS: FieldLabels<InfiltrationBackendDict> = {
  measurement_type: 'Measurement Type',
  infiltration_value: 'Value',
  infiltration_unit: 'Unit',
  shelter_class: {
    label: 'Shelter Class',
    infoDialog: ShelterClassInfoDialogComponent,
  },
  verification_type: 'Verification Type',
  tested_infiltration_value: 'Tested Value',
  tested_infiltration_unit: 'Tested Unit',
};

export interface InfiltrationBackendDict {
  id: number;
  measurement_type: InfiltrationMeasurementType;
  infiltration_value: number;
  infiltration_unit: InfiltrationUnit;
  shelter_class: number;
  verification_type: InfiltrationVerificationType;
  tested_infiltration_value: number;
  tested_infiltration_unit: InfiltrationUnit;
}

export enum InfiltrationMeasurementType {
  THRESHOLD = 'threshold',
  USER_ESTIMATE = 'estimate',
  CODE_DEFAULT = 'code_default',
  TRACER_GAS = 'tracer_gas',
  BLOWER_DOOR = 'blower_door',
  NOT_TESTED = 'untested',
  TO_BE_BLOWER_DOOR = 'tbd_blower_door',
}

export enum InfiltrationUnit {
  NATURAL = 'natural',
  ACH50 = 'ach50',
  CFM50 = 'cfm50',
  CFM25 = 'cfm25',
  ELA100 = 'ela100',
  CFM25FAN = 'cfm25fan',
  CFM25CFA = 'cfm25cfa',
  EFF_LEAKAGE = 'eff_leakage',
  SPECIFIC_LEAKAGE = 'specific_leakage',
  CFM50SHELL = 'cfm50shell',
  THERMAL_EFF = 'therm_eff',
  CFMSTD152 = 'cfmstd152',
}

export enum InfiltrationVerificationType {
  TESTED = 'tested',
  VISUAL = 'visual_ins',
}

export const InfiltrationMeasurementTypeLabels = {
  [InfiltrationMeasurementType.THRESHOLD]: 'Threshold / Sampled',
  [InfiltrationMeasurementType.USER_ESTIMATE]: 'User Estimate',
  [InfiltrationMeasurementType.CODE_DEFAULT]: 'Code Default',
  [InfiltrationMeasurementType.TRACER_GAS]: 'Tracer Gas',
  [InfiltrationMeasurementType.BLOWER_DOOR]: 'Blower Door',
  [InfiltrationMeasurementType.NOT_TESTED]: 'Untested',
  [InfiltrationMeasurementType.TO_BE_BLOWER_DOOR]: 'Blower Door (TBD)',
};

export const InfiltrationUnitLabels = {
  [InfiltrationUnit.NATURAL]: 'Natural ACH',
  [InfiltrationUnit.ACH50]: 'ACH @ 50 Pascals',
  [InfiltrationUnit.CFM50]: 'CFM @ 50 Pascals',
  [InfiltrationUnit.CFM25]: 'CFM @ 25 Pascals',
  [InfiltrationUnit.ELA100]: 'ELA/100 sq ft shell',
  [InfiltrationUnit.CFM25FAN]: 'CFM25 / CFMfan',
  [InfiltrationUnit.CFM25CFA]: 'CFM25 / CFA',
  [InfiltrationUnit.EFF_LEAKAGE]: 'Effective Leakage Area (in²)',
  [InfiltrationUnit.SPECIFIC_LEAKAGE]: 'Specific Leakage Area',
  [InfiltrationUnit.CFM50SHELL]: 'CFM 50 / sq ft shell',
  [InfiltrationUnit.THERMAL_EFF]: 'Thermal Efficiency (%)',
  [InfiltrationUnit.CFMSTD152]: 'CFM per Std 152',
};

export const InfiltrationVerificationTypeLabels = {
  [InfiltrationVerificationType.TESTED]: 'Tested',
  [InfiltrationVerificationType.VISUAL]: 'Visually Inspected',
};

export function createInfiltrationForm(
  infiltration: InfiltrationBackendDict
): ModelFormGroup {
  const validateOnlyIfTested = createConditionalValidator(
    controls =>
      controls.verification_type.value === InfiltrationVerificationType.TESTED,
    ['verification_type']
  );
  return new ModelFormGroup(
    {
      id: new ModelFormControl(infiltration.id),
      measurement_type: new ModelFormControl(
        infiltration.measurement_type,
        Validators.required
      ),
      infiltration_value: new ModelFormControl(
        infiltration.infiltration_value,
        Validators.required
      ),
      infiltration_unit: new ModelFormControl(
        infiltration.infiltration_unit,
        Validators.required
      ),
      shelter_class: new ModelFormControl(infiltration.shelter_class, [
        requiredAsWarning(),
        Validators.min(1),
        Validators.max(5),
      ]),
      verification_type: new ModelFormControl(infiltration.verification_type),
      tested_infiltration_value: new ModelFormControl(
        infiltration.tested_infiltration_value,
        ...validateOnlyIfTested([Validators.required])
      ),
      tested_infiltration_unit: new ModelFormControl(
        infiltration.tested_infiltration_unit
      ),
    },
    {
      validators: [validateInfiltrationUnit],
    }
  );
}

function validateInfiltrationUnit(controls: ModelFormGroup) {
  const infiltrationUnit = controls.get('infiltration_unit').value;
  const validUnit = [InfiltrationUnit.ACH50, InfiltrationUnit.CFM50];
  if (!infiltrationUnit.includes(validUnit)) {
    return { invalidInfiltrationUnit: true };
  }
}
