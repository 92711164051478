import { WidgetsTestComponent } from '@/modules/internal/pages/widgets-test/widgets-test.component';
import { SuperuserGuard } from '@/modules/user/guards/superuser.guard';

export const InternalRouting = [
  {
    path: 'internal',
    canActivate: [SuperuserGuard],
    children: [
      {
        path: 'widget/test',
        component: WidgetsTestComponent,
        resolve: {},
      },
    ],
  },
];
