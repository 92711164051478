import { ActionReducer } from '@ngrx/store';
import { FLAGS, WARNINGS } from '../errors-template';
import {
  ModelErrors,
  ModelErrorsWithCount,
  StateErrors,
} from './shared/base.state';
import * as SharedActions from './shared/shared.actions';
import * as SimulationActions from './simulation/actions';

export function addStateErrorCount(
  stateErrors: StateErrors<any>
): StateErrors<any> {
  const errorsWithCount = {};
  Object.entries(stateErrors).forEach(([id, error]) => {
    errorsWithCount[id] = addErrorCount(error);
  });

  return errorsWithCount;
}

export function addErrorCount(
  modelErrors: ModelErrors<any>
): ModelErrorsWithCount<any> {
  let count = 0,
    warningCount = 0;
  Object.keys(modelErrors.field).forEach(key => {
    if (!modelErrors.field[key]) return;

    for (const errorKey in modelErrors.field[key]) {
      if (WARNINGS.hasOwnProperty(errorKey)) {
        warningCount += 1;
      } else {
        count += 1;
      }
    }
  });

  for (const errorKey in modelErrors.model) {
    if (WARNINGS.hasOwnProperty(errorKey)) {
      warningCount += 1;
    } else {
      count += 1;
    }
  }

  return {
    ...modelErrors,
    errorCount: count,
    warningCount: warningCount,
  };
}

export function addErrorCountWithFlags(
  modelErrors: ModelErrors<any>,
  flags: string[]
): ModelErrorsWithCount<any> {
  let count = 0,
    warningCount = 0;
  Object.keys(modelErrors.field).forEach(key => {
    if (!modelErrors.field[key]) return;

    for (const errorKey in modelErrors.field[key]) {
      const applicableFlag = FLAGS[errorKey] ?? null;
      if (applicableFlag && !flags.includes(applicableFlag)) {
        continue;
      }

      if (WARNINGS.hasOwnProperty(errorKey)) {
        warningCount += 1;
      } else {
        count += 1;
      }
    }
  });

  for (const errorKey in modelErrors.model) {
    const applicableFlag = FLAGS[errorKey] ?? null;
    if (applicableFlag && !flags.includes(applicableFlag)) {
      continue;
    }
    if (WARNINGS.hasOwnProperty(errorKey)) {
      warningCount += 1;
    } else {
      count += 1;
    }
  }

  return {
    ...modelErrors,
    errorCount: count,
    warningCount: warningCount,
  };
}

export function addStateErrorCountWithFalgs(
  stateErrors: StateErrors<any>,
  flags: string[]
): StateErrors<any> {
  const errorsWithCount = {};
  Object.entries(stateErrors).forEach(([id, error]) => {
    errorsWithCount[id] = addErrorCountWithFlags(error, flags);
  });

  return errorsWithCount;
}

export function errorCountMetaReducer<T>(
  reducer: ActionReducer<T>
): ActionReducer<T> {
  return (state, action) => {
    // Run the original reducer first
    const nextState = reducer(state, action);

    // Check if the action matches the shared action
    if (action.type === SharedActions.updateErrorCountOnFlagChanges.type) {
      const newState = {};
      const { flags } = action as ReturnType<
        typeof SharedActions.updateErrorCountOnFlagChanges
      >;

      Object.entries(state).forEach(([key, value]) => {
        newState[key as string] = { ...value };

        if (!newState[key as string].hasOwnProperty('errors')) return;

        newState[key as string].errors = addStateErrorCountWithFalgs(
          newState[key as string].errors,
          flags
        );
      });

      return newState as T;
    }

    return nextState;
  };
}

export function getErrorActions(errors) {
  const actions = [];

  for (const modelName of Object.keys(errors)) {
    if (modelName === 'simulation') {
      const error = errors[modelName];
      actions.push(SimulationActions.updateCrossModelErrors({ errors: error }));
    }
  }

  return actions;
}
