<div *ngIf="initialized; else loading;">
    <ng-container *ngTemplateOutlet="appliancesView; context: {data: appliances}"></ng-container>
</div>

<ng-template #appliancesView let-appliances="data">
    <div class="row mt-2">
        <ng-container>
            <div class="col-6 mt-2">
                <div class="card">
                    <div class="card-content">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item header"
                                style="background-color:rgba(0, 0, 0, 0.03); align-items: center;">
                                <b>
                                    {{ appliances.dishwasher_display }}
                                </b>
                            </li>
                            <li class="list-group-item">
                                <b>Dishwasher Consumption:</b>
                                {{ appliances.dishwasher_consumption | number: '1.2-2' }}
                                {{ appliances.dishwasher_consumption_unit_display }}
                            </li>
                            <li class="list-group-item">
                                <b>Dishwasher Efficiency:</b>
                                {{ appliances.dishwasher_efficiency | number: '1.2-2' }}
                                {{ appliances.dishwasher_efficiency_unit_display }}
                            </li>
                            <li class="list-group-item">
                                <b>Dishwasher Location:</b>
                                {{ appliances.dishwasher_location_display }}
                            </li>
                            <li *ngIf="appliances.dishwasher_label_energy_rating" class="list-group-item">
                                <b>Dishwasher Label Energy Rating:</b>
                                {{ appliances.dishwasher_label_energy_rating }}
                                {{ appliances.dishwasher_label_energy_rating_unit_display }}
                            </li>
                            <li *ngIf="appliances.dishwasher_label_gas_annual_cost" class="list-group-item">
                                <b>Dishwasher Label Annual Gas Cost:</b>
                                {{ appliances.dishwasher_label_gas_annual_cost }}
                                {{ appliances.dishwasher_label_gas_annual_cost_unit_display }}
                            </li>
                            <li *ngIf="appliances.dishwasher_label_electric_rate" class="list-group-item">
                                <b>Dishwasher Label Electric Rate:</b>
                                {{ appliances.dishwasher_label_electric_rate }}
                                {{ appliances.dishwasher_label_electric_rate_unit_display }}
                            </li>
                            <li *ngIf="appliances.dishwasher_label_gas_rate" class="list-group-item">
                                <b>Dishwasher Label Gas Rate:</b>
                                {{ appliances.dishwasher_label_gas_rate }}
                                {{ appliances.dishwasher_gas_rate_unit_display }}
                            </li>
                            <li *ngIf="appliances.dishwasher_loads_per_week" class="list-group-item">
                                <b>Dishwasher Loads Per Week:</b>
                                {{ appliances.dishwasher_loads_per_week }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container>
            <div class="col-6 mt-2">
                <div class="card">
                    <div class="card-content">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item header"
                                style="background-color:rgba(0, 0, 0, 0.03); align-items: center;">
                                <b>
                                    {{ appliances.clothes_washer_display }}
                                </b>
                            </li>
                            <li class="list-group-item">
                                <b>Clothes Washer Capacity:</b>
                                {{ appliances.clothes_washer_capacity }}
                                {{ appliances.clothes_washer_capacity_units_display }}
                            </li>
                            <li class="list-group-item">
                                <b>Clothes Washer Efficiency:</b>
                                {{ appliances.clothes_washer_efficiency }}
                                {{ appliances.clothes_washer_efficiency_unit_display }}
                            </li>
                            <li class="list-group-item">
                                <b>Is Front Load:</b>
                                {{ appliances.is_front_loading_clothes_washer ? 'Yes' : 'No' }}
                            </li>
                            <li class="list-group-item">
                                <b>Location:</b>
                                {{ appliances.clothes_washer_location_display }}
                            </li>
                            <li *ngIf="appliances.clothes_washer_label_electric_consumption" class="list-group-item">
                                <b>Clothes Label Electric Consumption:</b>
                                {{ appliances.clothes_washer_label_electric_consumption }}
                                {{ appliances.clothes_washer_label_electric_consumption_unit_display }}
                            </li>
                            <li *ngIf="appliances.clothes_washer_energy_star_label_electric_rate"
                                class="list-group-item">
                                <b>Clothes Label Electric Rate:</b>
                                {{ appliances.clothes_washer_energy_star_label_electric_rate }}
                                {{ appliances.clothes_washer_energy_star_label_electric_rate_unit_display }}
                            </li>
                            <li *ngIf="appliances.clothes_washer_energy_star_label_gas_rate" class="list-group-item">
                                <b>Clothes Label Gas Rate:</b>
                                {{ appliances.clothes_washer_energy_star_label_gas_rate }}
                                {{ appliances.clothes_washer_energy_star_label_gas_rate_unit_display }}
                            </li>
                            <li *ngIf="appliances.clothes_washer_energy_star_label_gas_annual_cost"
                                class="list-group-item">
                                <b>Clothes Star Gas Annual Cost:</b>
                                {{ appliances.clothes_washer_energy_star_label_gas_annual_cost }}
                                {{ appliances.clothes_washer_energy_star_label_gas_annual_cost_unit_display }}
                            </li>
                            <li *ngIf="appliances.clothes_washer_integrated_water_factor" class="list-group-item">
                                <b>Clothes Intergrated Water Factor:</b>
                                {{ appliances.clothes_washer_integrated_water_factor }}
                            </li>
                            <li *ngIf="appliances.clothes_washer_loads_per_week" class="list-group-item">
                                <b>Clothes Loads Per Week:</b>
                                {{ appliances.clothes_washer_loads_per_week }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="w-100"></div>
        </ng-container>

        <ng-container>
            <div class="col-6 mt-2">
                <div class="card">
                    <div class="card-content">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item header"
                                style="background-color:rgba(0, 0, 0, 0.03); align-items: center;">
                                <b>
                                    {{ appliances.clothes_dryer_display }}
                                </b>
                            </li>
                            <li class="list-group-item">
                                <b>Clothes Dryer Fuel:</b>
                                {{ appliances.clothes_dryer_fuel_type_display }}
                            </li>
                            <li *ngIf="appliances.clothes_dryer_efficiency" class="list-group-item">
                                <b>Clothes Dryer Efficiency:</b>
                                {{ appliances.clothes_dryer_efficiency }}
                                {{ appliances.clothes_dryer_efficiency_unit_display }}
                            </li>
                            <li class="list-group-item">
                                <b>Clothes Dryer Location:</b>
                                {{ appliances.clothes_dryer_location_display }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container>
            <div class="col-6 mt-2">
                <div class="card">
                    <div class="card-content">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item header"
                                style="background-color:rgba(0, 0, 0, 0.03); align-items: center;">
                                <b>
                                    {{ appliances.refrigerator_display }}
                                </b>
                            </li>
                            <li class="list-group-item">
                                <b>Refrigerator Consumption</b>
                                {{ appliances.refrigerator_consumption }}
                                {{ appliances.refrigerator_consumption_unit_display }}
                            </li>
                            <li class="list-group-item">
                                <b>Refrigerator Location:</b>
                                {{ appliances.refrigerator_location_display }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="w-100"></div>
        </ng-container>

        <ng-container>
            <div class="col-6 mt-2">
                <div class="card">
                    <div class="card-content">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item header"
                                style="background-color:rgba(0, 0, 0, 0.03); align-items: center;">
                                <b>
                                    {{ appliances.oven_display }}
                                </b>
                            </li>
                            <li class="list-group-item">
                                <b>Oven Fuel:</b>
                                {{ appliances.oven_fuel_type_display }}
                            </li>
                            <li *ngIf="appliances.clothes_dryer_efficiency" class="list-group-item">
                                <b>Is Induction:</b>
                                {{ appliances.is_induction_range ? 'Yes': 'No' }}
                            </li>
                            <li class="list-group-item">
                                <b>Is Convection:</b>
                                {{ appliances.is_convection_oven ? 'Yes': 'No' }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container>
            <div class="col-6 mt-2">
                <div class="card">
                    <div class="card-content">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item header"
                                style="background-color:rgba(0, 0, 0, 0.03); align-items: center;">
                                <b>
                                    {{ appliances.ceiling_fan_display }}
                                </b>
                            </li>
                            <li class="list-group-item">
                                <b>Ceiling Fan Consumption:</b>
                                {{ appliances.ceiling_fan_consumption }}
                                {{ appliances.ceiling_fan_consumption_unit_display }}
                            </li>
                            <li *ngIf="appliances.clothes_dryer_efficiency" class="list-group-item">
                                <b>Count:</b>
                                {{ appliances.ceiling_fan_count }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </ng-container>

    </div>
</ng-template>

<ng-template #loading>
    <div class="row">
        <div class="col-12">
            <div class="d-flex justify-content-center" style="height: 320px;">
                <mat-spinner [diameter]="50"></mat-spinner>
            </div>
        </div>
    </div>
</ng-template>