export enum CeleryTaskState {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  REVOKED = 'REVOKED',
  STARTED = 'STARTED',
  RETRY = 'RETRY',
  RECEIVED = 'RECEIVED',
  PENDING = 'PENDING',
  UNACKNOWLEDGED = 'UNACKNOWLEDGED',
}

export class AsynchronousProcessedDocumentLog {
  debug?: string[];
  info?: string[];
  warnings?: string[];
  errors?: string[];
  critical?: string[];
  chronological?: string[];
}

export class AsynchronousProcessedDocument {
  id?: number;
  company?: number;
  task_name?: string;
  document?: string;
  final_status?: string;
  result?: string | object;
  state?: CeleryTaskState;
  download?: boolean;
  log: AsynchronousProcessedDocumentLog;
  modified_date?: string;
  created_date?: string;
}
