import {
  AboveGradeWallTypeBackendDict,
  AboveGradeWallTypeFormGroup,
} from '@/data/simulation/models/enclosure/AboveGradeWallType';
import { BaseValidator } from './base.validator';
import { ModelErrors, StateErrors } from '../state/shared/base.state';

export class AboveGradeWallTypeValidator extends BaseValidator {
  static validateForm(
    abovegradewalltype: AboveGradeWallTypeBackendDict
  ): ModelErrors<AboveGradeWallTypeBackendDict> {
    const form = new AboveGradeWallTypeFormGroup(abovegradewalltype);
    return form.getFieldAndModelErros();
  }

  static validate(
    abovegradewalltypes: AboveGradeWallTypeBackendDict[]
  ): StateErrors<AboveGradeWallTypeBackendDict> {
    const errors = {};
    abovegradewalltypes.forEach(abovegradewalltype => {
      errors[abovegradewalltype.id] = this.validateForm(abovegradewalltype);
    });
    return errors;
  }
}
