import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { addAboveGradeWallType } from '@/modules/simulation/state/above-grade-wall-type/actions';
import { CREATE_MODE, EDIT_MODE } from '@/core/constants';

export class AboveGradeWallTypeChangeDialogData {
  entityId?: number;
  parentId?: number;
  mode: typeof EDIT_MODE | typeof CREATE_MODE;
  commit?: boolean = false;
  retrieve?: boolean = false;
}

@Component({
  selector: 'app-above-grade-wall-type-change-dialog',
  templateUrl: './above-grade-wall-type-change-dialog.component.html',
  styleUrls: ['./above-grade-wall-type-change-dialog.component.scss'],
})
export class AboveGradeWallTypeChangeDialogComponent implements OnInit {
  typeSelectForm: FormGroup;
  public editorIsVisible = false;
  public editMode: boolean;
  public wallTypeId: number;
  public initalWallTypeId: number;

  componentDestroyed$ = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AboveGradeWallTypeChangeDialogData,
    public dialogRef: MatDialogRef<AboveGradeWallTypeChangeDialogComponent>,
    public uiHelperService: UIHelperService,
    public store: Store
  ) {
    this.editMode = this.data.mode === EDIT_MODE;
    if (this.data.mode === CREATE_MODE && this.data.entityId) {
      throw new Error(
        'Cannot create a new wall type with an existing wall type id or ' +
          'wall type'
      );
    }

    if (this.data.mode === EDIT_MODE) {
      this.wallTypeId = this.data.entityId;
    }
  }

  initTypeSelectorForm() {
    this.editorIsVisible = false;
    this.typeSelectForm = new FormGroup({
      type: new FormControl(null),
    });

    this.typeSelectForm
      .get('type')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe(wallType => {
        this.store.dispatch(
          addAboveGradeWallType({
            wallId: this.data.parentId,
            wallType: wallType,
          })
        );

        this.close();
      });
  }

  initWallTypeForm(entityId: number) {
    this.wallTypeId = entityId;
    this.editorIsVisible = true;
  }

  ngOnInit(): void {
    if (this.data.mode === CREATE_MODE) {
      this.initTypeSelectorForm();
    } else {
      this.wallTypeId = this.data.entityId;
      this.editorIsVisible = true;
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
