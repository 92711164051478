<h2 mat-dialog-title>
  <ng-container *ngIf="!isEditForm">
    Create New <b>Manufacturer Agreement</b>
  </ng-container>
  <ng-container *ngIf="isEditForm">
    Edit <b>Manufacturer Agreement</b>
  </ng-container>
</h2>
<mat-dialog-content *ngIf="initialized">
  <form [formGroup]="form" class="mb-2">
    <ng-container *ngIf="!isEditForm && (currentUser.company_info?.slug === CustomerHIRLSettings.companySlug || currentUser.is_superuser)">
      <div class="row">
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>Company</mat-label>
            <app-generic-model-autocomplete modelClassName="company" formControlName="company_info" [filterParams]="{company_type: CompanyType.manufacturer}"></app-generic-model-autocomplete>
            <mat-error *ngIf="form.get('company_info').hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="isEditForm && (currentUser.company_info?.slug === CustomerHIRLSettings.companySlug || currentUser.is_superuser)">
      <div class="row">
        <div class="col-6">
          <mat-form-field class="w-100">
            <mat-label>Start Date</mat-label>
            <input matInput type="text" placeholder="Start Date" formControlName="start_date" [matDatepicker]="startDatePicker">
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field class="w-100">
            <mat-label>Expiration Date</mat-label>
            <input matInput type="text" placeholder="Expiration Date" formControlName="expiration_date" [matDatepicker]="expirationDatePicker">
            <mat-datepicker-toggle matSuffix [for]="expirationDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #expirationDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12">
          <mat-divider></mat-divider>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="form.get('company_info').value">
      <div class="row">
        <div class="col-12">
          <h4>Mailing Information:</h4>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>Mailing Address</mat-label>
            <app-address-input placeholder="Mailing Address" formControlName="mailing_address_geocode" [show-clear-btn]="false" required></app-address-input>
            <mat-error *ngIf="form.get('mailing_address_geocode').hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <h4>Primary Contact:</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <mat-form-field class="w-100">
            <mat-label>First Name</mat-label>
            <input matInput type="text" placeholder="First Name" formControlName="primary_contact_first_name">
            <mat-error *ngIf="form.get('primary_contact_first_name').hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field class="w-100">
            <mat-label>Last Name</mat-label>
            <input matInput type="text" placeholder="Last Name" formControlName="primary_contact_last_name">
            <mat-error *ngIf="form.get('primary_contact_last_name').hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <mat-form-field class="w-100">
            <mat-label>Email Address</mat-label>
            <input matInput type="text" placeholder="Email Address" formControlName="primary_contact_email_address">
            <mat-error *ngIf="form.get('primary_contact_email_address').hasError('required')">
              This field is required
            </mat-error>
            <mat-error *ngIf="form.get('primary_contact_email_address').hasError('email')">
              Email Address is invalid
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field class="w-100">
            <mat-label>Phone Number</mat-label>
            <input matInput type="text" placeholder="Phone Number" formControlName="primary_contact_phone_number" mask="000-000-0000" [showMaskTyped]="true">
            <mat-error *ngIf="form.get('primary_contact_phone_number').hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <mat-form-field class="w-100">
            <mat-label>Title</mat-label>
            <input matInput type="text" placeholder="Title" formControlName="primary_contact_title">
            <mat-error *ngIf="form.get('primary_contact_title').hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field class="w-100">
            <mat-label>Cell Number</mat-label>
            <input matInput type="text" placeholder="Cell Number" formControlName="primary_contact_cell_number">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h4>Preview of Manufacturer Agreement (electronic signature will be required via DocuSign):</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <iframe style="width: 100%; height: 25rem"
                  src="assets/pages/hirl-product-agreement-detail/product_embedded_agreement_v1.pdf"></iframe>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          By clicking "Submit for Review and Approval by Home Innovation," you are acknowledging that
          your company agrees to the legal terms and conditions as stated above. Once you click,
          a legal agreement will be emailed to the Primary Contact specified above for signature.
          The legal agreement must be signed, dated, and returned in order
          for Home Innovation to initiate review of the submitted product(s).
          If you do not agree to the terms or if you have any questions prior to proceeding,
          you may contact the NGBS Green team at
          <a href="mailto:greenproducts@homeinnovation.com">greenproducts@homeinnovation.com</a>.
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <a href="assets/pages/hirl-product-agreement-detail/product_embedded_agreement_v1.pdf">Click here to download the
            sample PDF.</a>
        </div>
      </div>
    </ng-container>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button color="accent" mat-raised-button (click)="save($event)">
    <ng-container *ngIf="isEditForm">Save</ng-container>
    <ng-container *ngIf="!isEditForm">Submit for Review and Approval by NGBS</ng-container>
  </button>
  <button mat-button [mat-dialog-close]="null" color="primary" mat-raised-button>Cancel</button>
</mat-dialog-actions>
