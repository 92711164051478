import {
  DuctBackendDict,
  DuctForm,
} from '@/data/simulation/models/mechanicals/Duct';
import { BaseValidator } from './base.validator';
import { ModelErrors, StateErrors } from '../state/shared/base.state';

export class DuctValidator extends BaseValidator {
  static validateForm(duct: DuctBackendDict): ModelErrors<DuctBackendDict> {
    const ductForm = new DuctForm(duct);
    return ductForm.getFieldAndModelErros();
  }

  static validate(ducts: DuctBackendDict[]): StateErrors<DuctBackendDict> {
    const errors = {};
    ducts.forEach(duct => {
      errors[duct.id] = this.validateForm(duct);
    });
    return errors;
  }
}
