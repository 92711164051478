import {
  RoofBackendDict,
  DetailedRoofBackendDict,
} from '@/data/simulation/models/enclosure/Roof';
import { createAction, props } from '@ngrx/store';
import { ModelErrors, StateErrors } from '../shared/base.state';

export const loadDetailedRoofs = createAction(
  '[Simulation API] Load Detailed Roofs',
  props<{ detailedRoofs: DetailedRoofBackendDict[] }>()
);

export const loadRoofsSuccess = createAction(
  '[Roof API] Load Roofs Success',
  props<{
    roofs: RoofBackendDict[];
    errors: StateErrors<RoofBackendDict>;
  }>()
);

export const updateRoof = createAction(
  '[Roof UI] Update Roof',
  props<{ roofChanges: Partial<RoofBackendDict> }>()
);

export const updateRoofSuccess = createAction(
  '[Roof API] Update Roof Success',
  props<{
    roof: RoofBackendDict;
    errors: ModelErrors<RoofBackendDict>;
  }>()
);

export const updateRoofFailure = createAction(
  '[Roof API] Update Roof Failure',
  props<{ id: number }>()
);

export const removeRoof = createAction(
  '[Roof UI] Remove Roof',
  props<{ roof: RoofBackendDict }>()
);

export const removeRoofSuccess = createAction(
  '[Roof API] Remove Roof Success',
  props<{ id: number }>()
);

export const removeRoofFailure = createAction(
  '[Roof API] Remove Roof Failure',
  props<{ id: number }>()
);

export const updateRoofErrors = createAction(
  '[Roof UI] Update Roof Errors',
  props<{ id: number; errors: any }>()
);

export const setRoofType = createAction(
  '[RoofType API] Set RoofType',
  props<{ roofId: number; roofTypeId: number | null }>()
);

export const setRoofTypeSuccess = createAction(
  '[RoofType API] Set RoofType null',
  props<{ roofId: number; roofTypeId: number | null }>()
);
