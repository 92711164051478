import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { User } from '@/data/core/models/user';
import { FormBuilder, FormGroup } from '@angular/forms';
import { forkJoin, of, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, takeUntil } from 'rxjs/operators';
import { toggleLoading } from '@/state/actions/app.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { Invoice } from '@/data/invoicing/models';
import { InvoiceService } from '@/data/invoicing/services/invoice.service';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';

export interface HirlGcpInvoiceChangeDialogData {
  invoice: Invoice;
  commit?: boolean;
}

@Component({
  selector: 'app-hirl-gcp-invoice-change-dialog',
  templateUrl: './hirl-gcp-invoice-change-dialog.component.html',
  styleUrls: ['./hirl-gcp-invoice-change-dialog.component.scss'],
})
export class HirlGcpInvoiceChangeDialogComponent implements OnInit, OnDestroy {
  protected readonly CustomerHIRLSettings = CustomerHIRLSettings;

  public initialized: boolean;
  public currentUser: User;

  public currentUserIsCustomerOrSuper = false;

  public isEditForm = false;
  public entity?: Invoice;
  public commit = true;
  public retrieve = true;
  public form: FormGroup;

  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<HirlGcpInvoiceChangeDialogComponent>,
    private fb: FormBuilder,
    private invoiceService: InvoiceService,
    private dialog: MatDialog,
    private uiHelperService: UIHelperService,
    @Inject(MAT_DIALOG_DATA) public data: HirlGcpInvoiceChangeDialogData
  ) {
    if (data?.invoice) {
      this.entity = Object.assign({}, data?.invoice);
    }
    this.commit = data?.commit || true;
    if (this.entity?.prefixed_id) {
      this.isEditForm = true;
    }
  }
  ngOnInit() {
    const sources = {
      currentUser: this.store.select(getInfoUser).pipe(first()),
      invoice: of(this.entity),
    };

    this.store.dispatch(toggleLoading({ payload: true }));

    if (this.isEditForm && this.retrieve) {
      sources.invoice = this.invoiceService
        .retrieve(this.entity.prefixed_id, { ignoreStore: true })
        .pipe(takeUntil(this.componentDestroyed$), first());
    }

    forkJoin(sources)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: ({ currentUser, invoice }) => {
          this.store.dispatch(toggleLoading({ payload: false }));

          this.currentUser = currentUser;
          this.currentUserIsCustomerOrSuper =
            this.currentUser.company_info?.slug ===
              CustomerHIRLSettings.companySlug || this.currentUser.is_superuser;
          this.entity = invoice;
          this.setupForm();
          this.initialized = true;
          this.hydrateForm();
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  private setupForm() {
    this.form = this.fb.group({
      gcp_special_instructions: [''],
    });
  }

  hydrateForm() {
    if (this.isEditForm) {
      this.form.patchValue(Object.assign({}, this.entity), {
        emitEvent: false,
        onlySelf: true,
      });
    }
  }

  save($event: MouseEvent) {
    $event.preventDefault();
    if (this.isEditForm) {
      this.edit();
    }
  }

  private edit() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    if (!this.commit) {
      this.dialogRef.close(this.entity);
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const data = new Invoice();

    data.gcp_special_instructions = this.form.value.gcp_special_instructions;

    this.invoiceService
      .update(this.entity.prefixed_id, data)
      .pipe(first())
      .subscribe({
        next: (entity: Invoice) => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close(entity);
        },
        error: (error: HttpErrorResponse) =>
          this.uiHelperService.handleUserRequestError(error),
      });
  }
}
