import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  EEPProgramHomeStatusStateLabelMapping,
  HIRLEEPProgramHomeStatusStateLabelMapping,
} from '@/data/home/models/eep_program_home_status';
import { FormBuilder, FormGroup } from '@angular/forms';
import { User } from '@/data/core/models/user';
import { PSRReportList } from '@/data/home/models';
import { forkJoin, Subject, Subscription } from 'rxjs';
import {
  CustomerHirlProjectRegistrationFinanceTypeLabelMapping,
  EEPProgramHomeStatusRequestParams,
  EEPProgramHomeStatusService,
} from '@/data/home/services/eep_program_home_status.service';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import {
  CompanyAccessRequestParams,
  CompanyAccessService,
} from '@/data/company/services/company-access.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { first, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { getInfoUser } from '@/state/selectors/info.selector';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import {
  QAHIRLCertificationLevelAwardedLabelMapping,
  QARequirementTypeLabelMapping,
  QAStatusStateLabelMapping,
} from '@/data/qa/models';
import { CompanyType } from '@/data/company/models';

import {
  HIRLProjectAppealsProjectTypeMapping,
  HIRLProjectCommercialSpaceTypeLabelMapping,
  HIRLProjectSamplingMapping,
} from '@/data/customer-hirl/models';
import { ETORegionLabelMapping } from '@/data/customer-eto/models';
import { OkDialogComponent } from '@/shared/components/ok-dialog/ok-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ServerResponse } from '@/core/schemes/server-response';
import {
  CreateHomeFromSimulationDialogComponent,
  CreateHomeFromSimulationDialogData,
} from '@/modules/simulation/components/create-home-from-simulation-dialog/create-home-from-simulation-dialog.component';
import { SubmitToAxisResponseBackendDict } from '@/data/simulation/models';
import { selectSimulation } from '@/modules/simulation/state/simulation/selectors';
import { SimulationBackendDict } from '@/data/simulation/models/simulation';
import {
  FloorPlanRequestParams,
  FloorPlanService,
} from '@/data/floorplan/services/floorplan.service';
import { FloorPlan } from '@/data/floorplan/models';
import {
  CreateFloorplanFromSimulationDialogComponent,
  CreateFloorplanFromSimulationDialogData,
} from '../create-floorplan-from-simulation-dialog/create-floorplan-from-simulation-dialog.component';

@Component({
  selector: 'app-axis-tab',
  templateUrl: './axis-tab.component.html',
  styleUrls: ['./axis-tab.component.scss'],
})
export class AxisTabComponent implements OnInit, OnDestroy {
  protected readonly QARequirementTypeLabelMapping =
    QARequirementTypeLabelMapping;
  protected readonly QAHIRLCertificationLevelAwardedLabelMapping =
    QAHIRLCertificationLevelAwardedLabelMapping;
  protected readonly HIRLProjectCommercialSpaceTypeLabelMapping =
    HIRLProjectCommercialSpaceTypeLabelMapping;
  protected readonly HIRLProjectSamplingMapping = HIRLProjectSamplingMapping;
  protected readonly CustomerHirlProjectRegistrationFinanceTypeLabelMapping =
    CustomerHirlProjectRegistrationFinanceTypeLabelMapping;
  protected readonly HIRLProjectAppealsProjectTypeMapping =
    HIRLProjectAppealsProjectTypeMapping;
  protected readonly ETORegionLabelMapping = ETORegionLabelMapping;
  protected readonly CompanyType = CompanyType;
  public MAX_EXPORT_RESULTS = 2000;
  public initialized = false;

  public qaRequirementTypeChoicesLabelMapping = {};

  public tableStateLabelMapping = {
    ...EEPProgramHomeStatusStateLabelMapping,
    ...HIRLEEPProgramHomeStatusStateLabelMapping,
  };

  public EEPProgramHomeStatusStateLabelMapping = {
    not_certified: 'Not Certified',
    not_certified_and_not_abandoned: 'Not Certified (exclude Abandoned)',
    ...EEPProgramHomeStatusStateLabelMapping,
    ...HIRLEEPProgramHomeStatusStateLabelMapping,
  };

  public QAStatusStateLabelMapping = {
    not_in_progress: 'Not In Progress',
    addable: 'QA Addable',
    does_not_exist: 'Does Not Exist',
    not_complete: 'Not Complete',
    ...QAStatusStateLabelMapping,
  };

  public displayedColumns = [
    'home__street_line1',
    'home__subdivision__name',
    'eep_program__name',
    'state',
    'qa_status',
  ];

  public filterFromGroup: FormGroup;

  public currentUser: User;

  public homeStatusRelated = true;

  public entities: PSRReportList[];
  public entitiesCount: number;
  public entitiesIsLoading = true;

  public floorplan: FloorPlan;
  public aggregateSubscription$: Subscription;

  public simulation: SimulationBackendDict;

  public defaultParams: EEPProgramHomeStatusRequestParams =
    new EEPProgramHomeStatusRequestParams(1, '', '-created_date', 25);
  public storedParams: EEPProgramHomeStatusRequestParams =
    new EEPProgramHomeStatusRequestParams(1, '', '-created_date', 25);

  private listSubscription$: Subscription;
  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private eepProgramHomeStatusService: EEPProgramHomeStatusService,
    private companyAccessService: CompanyAccessService,
    private floorplanService: FloorPlanService,
    private router: Router,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private uiHelperService: UIHelperService
  ) {}

  ngOnInit() {
    this.store
      .select(selectSimulation())
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(simulation => {
        this.simulation = simulation;
      });

    forkJoin({
      queryParams: this.activatedRoute.queryParams.pipe(first()),
      currentUser: this.store.select(getInfoUser).pipe(first()),
      simulation: this.store.select(selectSimulation()).pipe(first()),
    })
      .pipe(
        takeUntil(this.componentDestroyed$),
        switchMap(({ queryParams, currentUser, simulation }) => {
          const params = new CompanyAccessRequestParams();
          params.company = currentUser.company;
          return this.companyAccessService.list(params).pipe(
            first(),
            map(companyAccessesResponse => {
              const companyAccesses = companyAccessesResponse.results;
              let companyAccess = null;
              if (companyAccesses.length) {
                companyAccess = companyAccesses[0];
              }
              return {
                queryParams: queryParams,
                currentUser: currentUser,
                simulation: simulation,
                companyAccess: companyAccess,
              };
            })
          );
        })
      )
      .subscribe(({ queryParams, currentUser, simulation, companyAccess }) => {
        this.currentUser = currentUser;
        this.storedParams.assignQueryParams(queryParams, [
          'state',
          'qa_status__state',
        ]);

        if (currentUser.is_superuser) {
          this.storedParams.company_access = null;
        } else {
          if (!this.storedParams.company_access) {
            this.storedParams.company_access = [companyAccess.id];
          }
        }

        if (currentUser.is_superuser) {
          this.homeStatusRelated = false;
        }

        this.initialized = true;

        this.setupFilterForm();
        this.hydrateForm();
        this.list();
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }

    if (this.aggregateSubscription$) {
      this.aggregateSubscription$.unsubscribe();
    }
  }

  setupFilterForm() {
    this.filterFromGroup = this.fb.group({
      search: [null],
      company_access_info: [null],
      state: [null],
      subdivision_info: [null],
      builder_organization_info: [null],
      rater_organization_info: [null],
      utility_organization_info: [null],
      eep_organization_info: [null],
      hvac_organization_info: [null],
      qa_organization_info: [null],
      provider_organization_info: [null],
      home__city_info: [null],
      home__city__country_info: [null],
      home__metro_info: [null],
      home__city__county_info: [null],
      home__task__assignees_info: [null],
      home__task__task_type_info: [null],
      eep_program_info: [null],
      created_date__gte: [null],
      created_date__lte: [null],
      certification_date__gte: [null],
      certification_date__lte: [null],
      home__created_date__gte: [null],
      home__created_date__lte: [null],
      home__state_info: [null],
      qa_status__state: [null],
      qa_status__qa_designee_info: [null],
      qastatus__requirement__type: [null],
      qastatus__observation__observation_type_info: [null],

      rater_of_record_info: [null],
      energy_modeler_info: [null],
      field_inspector_info: [null],

      customer_hirl_project__certification_level: [null],
      customer_hirl_project__is_hud_project: [null],
      customer_hirl_project__registration__is_build_to_rent: [null],
      customer_hirl_project__green_energy_badges_info: [null],
      customer_hirl_project__appeals_project: [null],
      customer_hirl_project__is_require_wri_certification: [null],
      customer_hirl_project__commercial_space_type: [null],
      customer_hirl_project__sampling: [null],
      customer_hirl_project__registration__financing_type: [null],
      customer_hirl_rough_verifier_info: [null],
      customer_hirl_final_verifier_info: [null],

      customer_hirl_annotations__energy_path: [null],

      customer_eto_region: [null],
    });

    this.filterFromGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        let params = Object.assign(this.defaultParams, values);

        params = Object.assign(params, {});
        this.storedParams.assignFilterFormValues({
          formValues: params,
          excludedProperties: ['ordering', 'tab'],
        });
        this.list();
      });
  }

  hydrateForm() {
    this.filterFromGroup.patchValue(this.storedParams, {
      emitEvent: false,
      onlySelf: true,
    });
  }

  list() {
    this.entitiesIsLoading = true;

    this.storedParams.floorplan__simulation = this.simulation.id;

    // populate params to query string
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.storedParams.toQueryParams(),
      replaceUrl: true,
    });

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }

    this.listSubscription$ = this.eepProgramHomeStatusService
      .psr_report_list(this.storedParams)
      .pipe(takeUntil(this.componentDestroyed$), take(1))
      .subscribe({
        next: (data: ServerResponse<PSRReportList>) => {
          this.entities = data.results;
          this.entitiesCount = data.count;
          this.entitiesIsLoading = false;
        },
        error: (error: HttpErrorResponse) => {
          if (error.status === 400) {
            // to avoid cycle loop, reload list only if invalid param in query detected
            let invalidQueryParam = false;
            for (const key of Object.keys(error.error)) {
              if (this.storedParams.hasOwnProperty(key)) {
                invalidQueryParam = true;
                delete this.storedParams[key];
              }
            }

            if (invalidQueryParam) {
              this.list();
            }
          }
        },
      });

    const floorplanParams = new FloorPlanRequestParams();
    floorplanParams.simulation = this.simulation.id;

    this.aggregateSubscription$ = this.floorplanService
      .list(floorplanParams)
      .pipe(takeUntil(this.componentDestroyed$), take(1))
      .subscribe({
        next: (data: ServerResponse<FloorPlan>) => {
          if (data.results.length) {
            this.floorplan = data.results[0];
          }
        },
        error: (error: HttpErrorResponse) =>
          this.uiHelperService.handleUserRequestError(error),
      });
  }

  onSort($event: Sort) {
    this.storedParams.ordering = `${$event.direction === 'desc' ? '-' : ''}${
      $event.active
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  onPaginateChange($event: PageEvent) {
    this.uiHelperService.getUISettings().rowsPerPage = $event.pageSize;
    this.storedParams.page_size = $event.pageSize;
    this.storedParams.page = $event.pageIndex + 1;
    this.list();
  }

  resetFilters($event: MouseEvent) {
    $event.preventDefault();
    this.filterFromGroup.reset();
  }

  showAppliedFiltersDialog($event: MouseEvent) {
    $event.preventDefault();

    let content = '';
    for (const [key, value] of Object.entries(this.getAppliedFilters())) {
      content += `<b>${key}</b>: ${value}<br>`;
    }

    this.dialog.open(OkDialogComponent, {
      data: {
        title: 'Current Applied Filters',
        content: content,
      },
    });
  }

  getAppliedFilters(): {
    [key: string]: string;
  } {
    return this.storedParams.toAppliedFilters({
      overwritePropertyValues: {},
      overwritePropertyLabels: {
        eep_program: 'EEP Program',
        company_access: 'Included Companies',
      },
      excludedProperties: ['tab', 'page', 'page_size'],
    });
  }

  createHomeFromSimulation($event: MouseEvent) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(
      CreateHomeFromSimulationDialogComponent,
      {
        width: '45%',
        disableClose: true,
        data: {
          simulation: this.simulation,
        } as CreateHomeFromSimulationDialogData,
      }
    );

    dialogRef
      .afterClosed()
      .subscribe((result?: SubmitToAxisResponseBackendDict) => {
        if (!result) {
          return;
        }

        this.list();
      });
  }

  createFloorplanFromSimulation($event: MouseEvent) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(
      CreateFloorplanFromSimulationDialogComponent,
      {
        width: '45%',
        disableClose: true,
        data: {
          simulation: this.simulation,
        } as CreateFloorplanFromSimulationDialogData,
      }
    );

    dialogRef
      .afterClosed()
      .subscribe((result?: SubmitToAxisResponseBackendDict) => {
        if (!result) {
          return;
        }

        this.list();
      });
  }
}
