import { Injectable } from '@angular/core';
import { ListRequestParams } from '@/core/schemes/request-params';
import { environment } from '../../../../environments/environment';
import { BaseDataService } from '@/core/services/base-data-service';
import { Observable } from 'rxjs';
import { SeasonalRateBackendDict } from '../models/utility/SeasonalRate';

export class SeasonalRateParams extends ListRequestParams {}

@Injectable({ providedIn: 'root' })
export class SeasonalRateService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/seasonal_rate/`;
  public parentUrl = `${environment.AXIS_API_V3_ROOT}/simulation/`;

  create(
    simulationId: number,
    data: Partial<SeasonalRateBackendDict>
  ): Observable<SeasonalRateBackendDict> {
    return this.http.post<SeasonalRateBackendDict>(
      `${this.parentUrl}${simulationId}/seasonal_rates/`,
      data
    );
  }

  update(
    seasonalRateChanges: Partial<SeasonalRateBackendDict>
  ): Observable<SeasonalRateBackendDict> {
    return this.http.patch<SeasonalRateBackendDict>(
      `${this.baseUrl}${seasonalRateChanges.id}/`,
      seasonalRateChanges
    );
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}${id}/`);
  }
}
