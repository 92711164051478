<div class="row">
  <div class="col-3">
    <div class="row">
      <div class="col-12">
        <form [formGroup]="filterFromGroup" class="card">
          <h5 class="card-header">Filters</h5>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Search</mat-label>
                  <app-search-input formControlName="search" placeholder="Type to search something"></app-search-input>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-form-field class="w-100">
                  <mat-label>Is Active</mat-label>
                  <mat-select formControlName="is_active">
                    <mat-option [value]="''">Any</mat-option>
                    <mat-option [value]="true">
                      Yes
                    </mat-option>
                    <mat-option [value]="false">
                      No
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="col-9">
    <div class="row mb-2">
      <div class="col-12 text-right">
        <button mat-raised-button color="accent" (click)="onAddNew($event)">Add New</button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="row" *ngIf="isLoading">
          <div class="col-12">
            <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
          </div>
        </div>
        <table mat-table
               [dataSource]="rows"
               class="table"
               matSort
               [matSortActive]="storedParams.toTableSort().active"
               [matSortDirection]="storedParams.toTableSort().direction"
               (matSortChange)="onSortChange($event)">

          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>Description</th>
            <td mat-cell *matCellDef="let row">{{ row.description }}</td>
          </ng-container>

          <ng-container matColumnDef="insurance_type">
            <th mat-header-cell *matHeaderCellDef>Insurance Type</th>
            <td mat-cell *matCellDef="let row">{{ COIDocumentInsuranceTypeMapping[row.insurance_type] }}</td>
          </ng-container>

          <ng-container matColumnDef="policy_number">
            <th mat-header-cell *matHeaderCellDef >Policy Number</th>
            <td mat-cell *matCellDef="let row">{{ row.policy_number }}</td>
          </ng-container>

          <ng-container matColumnDef="start_date">
            <th mat-header-cell *matHeaderCellDef>Start Date</th>
            <td mat-cell *matCellDef="let row">{{ row.start_date }}</td>
          </ng-container>

          <ng-container matColumnDef="expiration_date">
            <th mat-header-cell *matHeaderCellDef>Expiration Date</th>
            <td mat-cell *matCellDef="let row">{{ row.expiration_date }}</td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let row">
              <a href="{{ row.document }}" target="_blank" class="mr-2">
                <fa-icon [icon]="['fas', 'file']" matTooltip="View Document"></fa-icon>
              </a>
              <a href="#" (click)="onEditCOIDocument($event, row);" class="mr-2">
                <fa-icon [icon]="['fas', 'edit']" matTooltip="Edit COI Document"></fa-icon>
              </a>
              <app-history-mat-button [objectId]="row.id" [modelClassName]="'coi_document'"
                                      matTooltip="View Document History" displayType="asLink">
                <fa-icon [icon]="['fas', 'list']"></fa-icon>
              </app-history-mat-button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [length]="rowsCount"
                       [pageIndex]="$any(storedParams.page) - 1"
                       [pageSize]="storedParams.page_size"
                       (page)="onPaginateChange($event)"
                       aria-label="Select page">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>

