<div class="card mb-2 position-relative">
    <div class="card-header">
        <div class="row">
            <div class="col-12">
                <strong>Fuels</strong>
            </div>
        </div>
    </div>
    <div class="card-body pb-0">
        <div class="row">
            <div class="col-12">
                <div *ngFor="let utilityRate of utilityRates; index as i">
                    <div class="item">
                        <strong>
                            {{ fuelTypeLabels[utilityRate.fuel] }} ({{ utilityRate.name }})
                        </strong>
                        <div class="d-flex justify-content-between align-items-center">
                            <button type="button" class="edit-button" (click)="editUtilityRate(utilityRate.id)">Edit rate</button>
                            <button mat-icon-button class="delete-button">
                                <mat-icon inline="true" (click)="removeUtilityRate(i)">delete</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center mt-3">
                    <button class="btn btn-primary" mat-button (click)="addUtilityRate()">
                        <strong>Add +</strong>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>