import {
  enumValidator,
  requiredAsWarning,
} from '@/modules/simulation/validators/helper';
import { Validators } from '@angular/forms';
import { FieldLabels } from '../base';
import { ModelFormControl, ModelFormGroup } from '../ModelForm';

export enum MechanicalVentilationType {
  NONE = 'none',
  BALANCED = 'balanced',
  EXHAUST_ONLY = 'exhaust',
  SUPPLY_ONLY = 'supply',
  AIR_CYCLER = 'air_cycler',
  HRV = 'hrv',
  ERV = 'erv',
}

export const MechanicalVentilationTypeLabels: Record<
  MechanicalVentilationType,
  string
> = {
  [MechanicalVentilationType.NONE]: 'None',
  [MechanicalVentilationType.BALANCED]: 'Balanced',
  [MechanicalVentilationType.EXHAUST_ONLY]: 'Exhaust Only',
  [MechanicalVentilationType.SUPPLY_ONLY]: 'Supply Only',
  [MechanicalVentilationType.AIR_CYCLER]: 'Air Cycler (CFIS)',
  [MechanicalVentilationType.HRV]: 'HRV',
  [MechanicalVentilationType.ERV]: 'ERV',
};

export enum MechanicalVentilationUseCase {
  WHOLE_HOME = 'whole_home',
  LOCAL_VENTILATION = 'local_ventilation',
  SEASONAL_COOLING = 'seasonal_cooling',
  GARAGE_VENTILATION = 'garage_ventilation',
}

export const MechanicalVentilationUseCaseLabels: Record<
  MechanicalVentilationUseCase,
  string
> = {
  [MechanicalVentilationUseCase.WHOLE_HOME]: 'Whole Home',
  [MechanicalVentilationUseCase.LOCAL_VENTILATION]: 'Local Ventilation',
  [MechanicalVentilationUseCase.SEASONAL_COOLING]: 'Seasonal Cooling',
  [MechanicalVentilationUseCase.GARAGE_VENTILATION]: 'Garage Ventilation',
};

export enum VentilationRateUnit {
  CFM = 'cfm',
}
export const VentilationRateUnitLabels: Record<VentilationRateUnit, string> = {
  [VentilationRateUnit.CFM]: 'CFM',
};

export enum AuxEnergyUnit {
  WATT = 'watt',
  KWH_YEAR = 'kwh_yr',
  EAE = 'eae',
}
export const AuxEnergyUnitLabels: Record<AuxEnergyUnit, string> = {
  [AuxEnergyUnit.WATT]: 'Watt',
  [AuxEnergyUnit.KWH_YEAR]: 'kWh/yr',
  [AuxEnergyUnit.EAE]: 'Eae',
};

export interface MechanicalVentilationBackendDict {
  id: number;
  type: MechanicalVentilationType;
  use_type: MechanicalVentilationUseCase;
  adjusted_sensible_recovery_efficiency: number;
  adjusted_total_recovery_efficiency: number;
  hour_per_day: number;
  ventilation_rate: number;
  ventilation_rate_unit: string;
  ventilation_rate_unmeasured: boolean;
  consumption: number;
  consumption_unit: string;
  use_default_consumption: boolean;
  ecm_motor: boolean;
  outside_air_percent: number;
  recirculated_air_percent: number;
}

export const FIELD_LABELS: FieldLabels<MechanicalVentilationBackendDict> = {
  type: 'Type',
  use_type: 'Use Type',
  adjusted_sensible_recovery_efficiency:
    'Adjusted Sensible Recovery Efficiency',
  adjusted_total_recovery_efficiency: 'Adjusted Total Recovery Efficiency',
  hour_per_day: 'Hour per Day',
  ventilation_rate: 'Ventilation Rate',
  ventilation_rate_unit: 'Ventilation Rate Unit',
  ventilation_rate_unmeasured: 'Ventilation Rate Unmeasured',
  consumption: 'Consumption',
  consumption_unit: 'Consumption Unit',
  use_default_consumption: 'Use Default Consumption',
  ecm_motor: 'ECM Motor',
  outside_air_percent: 'Outside Air Percent',
  recirculated_air_percent: 'Recirculated Air Percent',
};

export function createMechanicalVentilationForm(
  data: MechanicalVentilationBackendDict
): ModelFormGroup {
  return new ModelFormGroup(
    {
      id: new ModelFormControl(data.id),
      type: new ModelFormControl(
        data.type,
        enumValidator(MechanicalVentilationType)
      ),
      use_type: new ModelFormControl(data.use_type, [
        requiredAsWarning(),
        enumValidator(MechanicalVentilationUseCase, true),
      ]),
      adjusted_sensible_recovery_efficiency: new ModelFormControl(
        data.adjusted_sensible_recovery_efficiency,
        [Validators.min(0), Validators.max(100)]
      ),
      adjusted_total_recovery_efficiency: new ModelFormControl(
        data.adjusted_total_recovery_efficiency,
        [Validators.min(0), Validators.max(100)]
      ),
      hour_per_day: new ModelFormControl(data.hour_per_day, [
        Validators.min(0),
        Validators.max(24),
      ]),
      ventilation_rate: new ModelFormControl(data.ventilation_rate, [
        Validators.min(0),
      ]),
      ventilation_rate_unmeasured: new ModelFormControl(
        data.ventilation_rate_unmeasured
      ),
      consumption: new ModelFormControl(data.consumption, [Validators.min(0)]),
      use_default_consumption: new ModelFormControl(
        data.use_default_consumption
      ),
      ecm_motor: new ModelFormControl(data.ecm_motor),
      outside_air_percent: new ModelFormControl(data.outside_air_percent, [
        Validators.min(0),
        Validators.max(100),
      ]),
      recirculated_air_percent: new ModelFormControl(
        data.recirculated_air_percent,
        [Validators.min(0), Validators.max(100)]
      ),
    },
    {
      validators: [
        validateWattsPerCFM,
        validateAdjustedSensibleRecoveryEfficiency,
        validateUseType,
        validateAdjustedTotalRecoveryEfficiency,
      ],
    }
  );
}

function validateUseType(controls: ModelFormGroup) {
  const useType = controls.get('use_type')?.value;

  if (!useType) {
    return { requiredUseType: true };
  }
}

function validateWattsPerCFM(controls: ModelFormGroup) {
  const type = controls.get('type')?.value;
  const ventilationRate = controls.get('ventilation_rate')?.value;
  const consumption = controls.get('consumption')?.value;

  if (!ventilationRate || !consumption) {
    return null;
  }

  const fanTypeLimits = {
    [MechanicalVentilationType.EXHAUST_ONLY]: 0.12,
    [MechanicalVentilationType.SUPPLY_ONLY]: 0.12,
    [MechanicalVentilationType.BALANCED]: 0.24,
    [MechanicalVentilationType.ERV]: 0.48,
    [MechanicalVentilationType.AIR_CYCLER]: 0.36,
  };

  const wattLimit = fanTypeLimits[type];
  if (wattLimit !== undefined) {
    const wattsPerCfm = ventilationRate / consumption;
    if (wattsPerCfm > wattLimit) {
      return {
        highWattsPerCFM: {
          type: MechanicalVentilationTypeLabels[type],
          wattLimit,
          actual: wattsPerCfm,
        },
      };
    }
  }
}

function validateAdjustedSensibleRecoveryEfficiency(controls: ModelFormGroup) {
  const type = controls.get('type')?.value;
  const asre = controls.get('adjusted_sensible_recovery_efficiency')?.value;

  const nominalMin = 55;
  const nominalMax = 75;
  const allowedTypes = [
    MechanicalVentilationType.BALANCED,
    MechanicalVentilationType.SUPPLY_ONLY,
    MechanicalVentilationType.EXHAUST_ONLY,
    MechanicalVentilationType.AIR_CYCLER,
  ];

  // Check if ASRE is required but missing
  if (!asre && !allowedTypes.includes(type)) {
    return { requiredASRE: true, oseriRequiredASRE: true };
  }

  // Check if ASRE is not required
  if (allowedTypes.includes(type) && asre) {
    return { asreNotRequired: true, oseriAsreNotRequired: { type } };
  }

  // Validate ASRE against thresholds
  const lowerBound50 = nominalMin * 0.5;
  const lowerBound75 = nominalMin * 0.75;
  const upperBound125 = nominalMax * 1.25;

  if (asre && asre < lowerBound50) {
    return { asreTooLow: { percentage: '50%', nominalMin } };
  }

  if (asre && asre < lowerBound75) {
    return { asreTooLow: { percentage: '75%', nominalMin } };
  }

  if (asre && asre > upperBound125) {
    return { asreTooHigh: { nominalMax } };
  }
}

function validateAdjustedTotalRecoveryEfficiency(controls: ModelFormGroup) {
  const type = controls.get('type')?.value;
  const atre = controls.get('adjusted_total_recovery_efficiency')?.value;

  const nominalMin = 45;
  const nominalMax = 75;
  const allowedTypes = [
    MechanicalVentilationType.BALANCED,
    MechanicalVentilationType.SUPPLY_ONLY,
    MechanicalVentilationType.EXHAUST_ONLY,
    MechanicalVentilationType.AIR_CYCLER,
    MechanicalVentilationType.HRV,
  ];

  // Check if ATRE is required but not set or zero
  if ((!atre || atre === 0) && !allowedTypes.includes(type)) {
    return { requiredATRE: true, oseriRequiredATRE: true };
  }

  // Check if ATRE is set for types where it's normally not required
  if (allowedTypes.includes(type) && atre) {
    return { atreNotRequired: true, oseriAtreNotRequired: { type } };
  }

  // Validate ATRE against thresholds
  const lowerBound50 = nominalMin * 0.5;
  const lowerBound75 = nominalMin * 0.75;
  const upperBound125 = nominalMax * 1.25;

  if (atre && atre < lowerBound50) {
    return { atreTooLow: { percentage: '50%', nominalMin } };
  }

  if (atre && atre < lowerBound75) {
    return { atreTooLow: { percentage: '75%', nominalMin } };
  }

  if (atre && atre > upperBound125) {
    return { atreTooHigh: { nominalMax } };
  }
}

export const DEFAULT_MECHANICAL_VENTILATION: MechanicalVentilationBackendDict =
  {
    id: null,
    type: MechanicalVentilationType.BALANCED,
    use_type: MechanicalVentilationUseCase.WHOLE_HOME,
    adjusted_sensible_recovery_efficiency: 0,
    adjusted_total_recovery_efficiency: 0,
    hour_per_day: 0,
    ventilation_rate: 0,
    ventilation_rate_unit: 'cfm',
    ventilation_rate_unmeasured: false,
    consumption: 0,
    consumption_unit: 'watt',
    use_default_consumption: false,
    ecm_motor: false,
    outside_air_percent: 0,
    recirculated_air_percent: 0,
  };
