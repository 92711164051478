export const addEntityArray = (state, key, payload, countKey) => ({
  ...state,
  [key]: [...state[key], payload],
  ...(countKey && { [countKey]: state[countKey] + 1 }),
});

export const updateEntityArray = (state, key, payload) => ({
  ...state,
  [key]: state[key].map(item => (item.id === payload.id ? payload : item)),
});

export const deleteEntityArray = (state, key, payload, countKey) => ({
  ...state,
  [key]: state[key].filter(item => item.id !== payload.id),
  ...(countKey && { [countKey]: state[countKey] - 1 }),
});

export const loadEntityArray = (state, key, payload) => ({
  ...state,
  [key]: payload,
});

export const loadEntityCount = (state, countKey, payload) => ({
  ...state,
  [countKey]: payload,
});
