<ng-container>
    <li class="list-group-item header" style="background-color:rgba(0, 0, 0, 0.03); align-items: center;">
        <b>
            Water Heater
        </b>
    </li>
    <li class="list-group-item">
        <b>Name:</b>
        {{ equipment.water_heater_info.name }}
    </li>
    <li class="list-group-item">
        <b>Type:</b>
        {{ WaterHeaterStyleLabels[equipment.water_heater_info.style] }}
        <span style="float:right;">
            <b>Is Heat Pump:</b>
            {{ equipment.water_heater_info.is_heat_pump && 'Yes' || 'No' }}
        </span>
    </li>
    <li class="list-group-item">
        <b>Fuel Type:</b>
    </li>

    <li class="list-group-item">
        <b>Capacity:</b>
        {{ equipment.water_heater_info.tank_size }} /
        {{ WaterHeaterLiquidVolumeLabels[equipment.water_heater_info.tank_units] }}
    </li>
    <li class="list-group-item">
        <b>Efficiency:</b>
        {{ equipment.water_heater_info.efficiency }} /
        {{ HeatingEfficiencyUnitLabels[equipment.water_heater_info.efficiency_unit] }}
    </li>
    <li class="list-group-item">
        <b>Extra tank insulation:</b>
        {{ equipment.water_heater_info.extra_tank_insulation_r_value }}
    </li>
</ng-container>