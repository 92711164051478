import { selectFoundationWallErrorViewSet } from '@/modules/simulation/state/foundation-wall/selectors';
import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-foundation-wall-errors',
  templateUrl: './foundation-wall-errors.component.html',
  styleUrls: ['./foundation-wall-errors.component.scss'],
})
export class FoundationWallErrorsComponent {
  @Input() entityId: number;
  componentDestroyed$: Subject<boolean> = new Subject();
  enable = false;
  name: string;
  totalErrorCount = 0;
  totalWarningCount = 0;
  wallTypeId: number;
  wallTypeErrorCount = 0;
  wallTypeWarningCount = 0;

  constructor(public store: Store) {}

  ngOnInit(): void {
    this.enable = true;

    this.store
      .select(selectFoundationWallErrorViewSet(this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.name = data.name;
        this.wallTypeId = data.wallTypeId;

        const nestedModelsErrorCount = data.nestedModelsErrorCount;
        this.wallTypeErrorCount =
          nestedModelsErrorCount.foundationWallType.errorCount;
        this.wallTypeWarningCount =
          nestedModelsErrorCount.foundationWallType.warningCount;

        this.totalErrorCount = 0;
        this.totalErrorCount += this.wallTypeErrorCount;
        this.totalErrorCount +=
          nestedModelsErrorCount.foundationWall.errorCount;

        this.totalWarningCount = 0;
        this.totalWarningCount += this.wallTypeWarningCount;
        this.totalWarningCount +=
          nestedModelsErrorCount.foundationWall.warningCount;
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
