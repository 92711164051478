import { createReducer, on } from '@ngrx/store';
import * as BlockRateActions from './actions';
import { BlockRateBackendDict } from '@/data/simulation/models/utility/BlockRate';
import { BaseState, sharedInitialState } from '../shared/base.state';
import { addErrorCount, addStateErrorCount } from '../helper';

export type BlockRateState = BaseState<BlockRateBackendDict>;

export const initialState: BlockRateState = sharedInitialState;

export const reducer = createReducer(
  initialState,
  on(BlockRateActions.loadBlockRatesSuccess, (currentState, payload) => {
    const entities = payload.blockRates.reduce(
      (acc, blockrate) => ({ ...acc, [blockrate.id]: blockrate }),
      {}
    );

    const loadingStatus = payload.blockRates.reduce(
      (acc, blockrate) => ({ ...acc, [blockrate.id]: false }),
      {}
    );

    return {
      entities: { ...currentState.entities, ...entities },
      loading: { ...currentState.loading, ...loadingStatus },
      errors: { ...currentState.errors, ...addStateErrorCount(payload.errors) },
    };
  }),

  on(BlockRateActions.removeBlockRateSuccess, (currentState, { id }) => {
    const { [id]: removed, ...entities } = currentState.entities;
    const { [id]: removedLoading, ...loading } = currentState.loading;
    const { [id]: removedErrors, ...errors } = currentState.errors;

    return {
      entities,
      loading,
      errors,
    };
  }),

  on(BlockRateActions.updateBlockRate, (state, { blockRateChanges }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [blockRateChanges.id]: true,
      },
    };
  }),

  on(BlockRateActions.removeBlockRate, (state, { blockRate }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [blockRate.id]: true,
      },
    };
  }),

  on(
    BlockRateActions.updateBlockRateFailure,
    BlockRateActions.removeBlockRateFailure,
    (state, { id }) => {
      return {
        ...state,
        loading: {
          ...state.loading,
          [id]: false,
        },
      };
    }
  ),

  on(BlockRateActions.updateBlockRateErrors, (state, { id, errors }) => {
    return {
      ...state,
      errors: {
        ...state.errors,
        [id]: errors,
      },
    };
  }),

  on(
    BlockRateActions.updateBlockRateSuccess,
    (state, { blockRate, errors }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          [blockRate.id]: blockRate,
        },
        loading: {
          ...state.loading,
          [blockRate.id]: false,
        },
        errors: {
          ...state.errors,
          [blockRate.id]: addErrorCount(errors),
        },
      };
    }
  )
);
