<h2 mat-dialog-title>
  <ng-container *ngIf="!isEditForm">
    Create New <b>ETO Account</b>
  </ng-container>
  <ng-container *ngIf="isEditForm">
    Edit <b>ETO Account</b>
  </ng-container>
</h2>
<mat-dialog-content>
  <form [formGroup]="form" class="mb-2">
    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Account Number</mat-label>
          <input matInput type="text" placeholder="Comment" formControlName="account_number">
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button color="primary" mat-raised-button (click)="save($event)">Save</button>
  <button mat-button [mat-dialog-close]="null" color="primary" mat-raised-button>Cancel</button>
</mat-dialog-actions>
