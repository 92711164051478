<div class="d-flex justify-content-center">
    <button mat-button class="small" [color]="showCalculationTable ? 'accent' : 'primary'" (click)="showCalculationTable = !showCalculationTable">{{showCalculationTable ? 'Hide' : 'Show'}} Calculation</button>
</div>

<div *ngIf="!showCalculationTable">
    <div *ngIf="layersForm.length === 0" class="layers text-center mb-3">
        <br>
        <br>

        <button *appRequiresEditPermission mat-icon-button (click)="append()">
            <mat-icon>add</mat-icon> Add layer
        </button>
    </div>

    <div *ngIf="layersForm.length > 0">
        <div class="layers text-center mb-3">
            <button *appRequiresEditPermission mat-icon-button (click)="append()" class="mt-3">
                <mat-icon>add</mat-icon>
            </button>
            <div *ngFor="let layerForm of layersForm.controls;let i=index" class="layer">
                <h6 class="layer-index" [ngClass]="{'active-index': (i === activeLayerIndex), 'invalid-status': layerValidations[i]}">({{i + 1}})</h6>
                <app-assembly-layer [layer]="layerForm.value" (click)="onActiveLayer(i)"
                    [active]="activeLayerIndex===i"></app-assembly-layer>
                <mat-icon inline="true" (click)="removeLayer(i)">delete</mat-icon>
            </div>
            <button *appRequiresEditPermission mat-icon-button (click)="push()">
                <mat-icon>add</mat-icon>
            </button>
        </div>
        <div class="d-flex justify-content-center" *ngIf="activeLayerIndex >= 0">
            <h5>
                Editing layer ({{activeLayerIndex + 1}})
            </h5>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <app-field-errors [errors]="activeLayerErrors"></app-field-errors>
                </div>
            </div>
        </div>
        <app-assembly-layer-editor *ngIf="activeLayerIndex >= 0"
            [entityForm]="layersForm.controls[activeLayerIndex]"></app-assembly-layer-editor>

        <div class="form-buttons-container" *ngIf="changesExist">
            <button type="button" class="save" [ngClass]="{'disabled-save': foundErrorsInLayers, 'save': !foundErrorsInLayers}" (click)="onSave()" [disabled]="foundErrorsInLayers">Save</button>
        </div>
        <div *ngIf="foundErrorsInLayers" class="d-flex flex-row-reverse">
            <div>
                Please fix the errors in layers to save the data.
            </div>
        </div>
    </div>
</div>

<div *ngIf="showCalculationTable">
    <div class="d-flex justify-content-center mb-2">
        <strong>Using {{methodUsedForCalculation}}</strong>
    </div>
    <table class="table">
        <thead>
            <tr>
                <th scope="col" *ngFor="let item of tableHeaders">{{item}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let rowData of tableData; let i = index" [ngClass]="{ 'important-strong': (i > tableData.length - 3) }">
                <td *ngFor="let data of rowData">{{data}}</td>
            </tr>
        </tbody>
    </table>
</div>