<div class="generic-mat-select-container">
  <mat-form-field appearance="none" class="generic-mat-select-field">
    <mat-select
      [formControl]="control"
      [multiple]="false"
      (selectionChange)="onSelectionChanged($event)"
      #select
    >
      <mat-select-trigger *ngIf="selectedContactCard">
        <div class="row mb-5">
          <div class="col-12">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">
                <fa-icon
                  [icon]="['fas', 'user']"
                  *ngIf="selectedContactCard.user"
                ></fa-icon>
                <fa-icon
                  [icon]="['fas', 'building']"
                  *ngIf="selectedContactCard.company"
                ></fa-icon>
                {{ selectedContactCard.name }}
              </h5>
            </div>
            <div *ngIf="selectedContactCard.title">
              {{ selectedContactCard.title }}
            </div>
            <div class="mb-1">
              <div *ngFor="let contactCardPhone of selectedContactCard.phones">
                Phone<span *ngIf="contactCardPhone.description">
                  ({{ contactCardPhone.description }} )</span
                >: {{ contactCardPhone.phone }}
              </div>
              <div *ngFor="let contactCardEmail of selectedContactCard.emails">
                Email
                <span *ngIf="contactCardEmail.description">
                  ({{ contactCardEmail.description }} )</span
                >: {{ contactCardEmail.email }}
              </div>
            </div>
          </div>
        </div>
      </mat-select-trigger>
      <div class="custom-panel">
        <mat-option
          *ngIf="isLoading && !results.length"
          class="mat-option-loading"
        >
          <div class="row">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <mat-spinner [diameter]="50"></mat-spinner>
              </div>
            </div>
          </div>
        </mat-option>
        <mat-option
          *ngIf="!isLoading && !results.length"
          class="mat-option-loading text-center"
        >
          You do not have any Contact Cards
        </mat-option>
        <mat-option
          *ngFor="let contactCard of results"
          [value]="contactCard.id"
          class="mat-option-contact"
        >
          <div class="row">
            <div class="col-9">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">
                  <fa-icon
                    [icon]="['fas', 'user']"
                    *ngIf="contactCard.user"
                  ></fa-icon>
                  <fa-icon
                    [icon]="['fas', 'building']"
                    *ngIf="contactCard.company"
                  ></fa-icon>
                  {{ contactCard.name }}
                </h5>
              </div>
              <div *ngIf="contactCard.title">
                {{ contactCard.title }}
              </div>
              <div class="mb-1">
                <div *ngFor="let contactCardPhone of contactCard.phones">
                  Phone<span *ngIf="contactCardPhone.description">
                    ({{ contactCardPhone.description }} )</span
                  >: {{ contactCardPhone.phone }}
                </div>
                <div *ngFor="let contactCardEmail of contactCard.emails">
                  Email
                  <span *ngIf="contactCardEmail.description">
                    ({{ contactCardEmail.description }} )</span
                  >: {{ contactCardEmail.email }}
                </div>
              </div>
            </div>
            <div class="col-3 text-right">
              <button
                mat-raised-button
                (click)="$event.stopPropagation(); onEdit($event, contactCard)"
                *ngIf="!contactCard.protected"
              >
                Edit
              </button>
            </div>
          </div>
        </mat-option>
      </div>
      <footer class="custom-panel-footer">
        <button mat-raised-button color="accent" (click)="onCreate($event)">
          Add New Contact
        </button>
      </footer>
    </mat-select>
    <mat-spinner matSuffix diameter="20" *ngIf="isLoading"></mat-spinner>
  </mat-form-field>
</div>
