import {
  DetailedSeasonalRateBackendDict,
  SeasonalRateBackendDict,
  createSeasonalRateForm,
} from '@/data/simulation/models/utility/SeasonalRate';
import { BaseValidator } from './base.validator';
import { ModelErrors, StateErrors } from '../state/shared/base.state';

export class SeasonalRateValidator extends BaseValidator {
  static validateForm(
    seasonalRate: SeasonalRateBackendDict
  ): ModelErrors<SeasonalRateBackendDict> {
    const seasonalrateForm = createSeasonalRateForm(seasonalRate);
    return seasonalrateForm.getFieldAndModelErros();
  }

  static validate(
    seasonalRates: DetailedSeasonalRateBackendDict[]
  ): StateErrors<SeasonalRateBackendDict> {
    const errors = {};
    seasonalRates.forEach(seasonalRate => {
      errors[seasonalRate.id] = this.validateForm(seasonalRate);
    });
    return errors;
  }
}
