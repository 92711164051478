import { Action, createReducer, on } from '@ngrx/store';
import {
  PaymentRequest,
  PaymentRequestProduct,
} from '@/data/customer-hirl/models';
import {
  deletePaymentRequestProductAction,
  loadPaymentRequest,
  loadPaymentRequestProductsAction,
  loadPaymentRequestProductsCountAction,
  resetPaymentRequest,
} from '@/state/actions/customer-hirl/payment-request';

export const hirlPaymentRequestStateKey = 'hirlPaymentRequest';

export interface HIRLPaymentRequestState {
  detail: PaymentRequest;
  paymentRequestProducts: PaymentRequestProduct[];
  paymentRequestProductsCount: number;
}

export const HIRLPaymentRequestInitialState: HIRLPaymentRequestState = {
  detail: null,
  paymentRequestProducts: [],
  paymentRequestProductsCount: -1,
};

const HIRLPaymentRequestReducer = createReducer(
  HIRLPaymentRequestInitialState,
  on(resetPaymentRequest, (state, action) => ({
    ...HIRLPaymentRequestInitialState,
  })),
  on(loadPaymentRequest, (state, { payload }) => ({
    ...state,
    detail: payload,
  })),
  // Products
  on(loadPaymentRequestProductsAction, (state, { payload }) => ({
    ...state,
    paymentRequestProducts: payload,
  })),
  on(loadPaymentRequestProductsCountAction, (state, { payload }) => ({
    ...state,
    paymentRequestProductsCount: payload,
  })),
  on(deletePaymentRequestProductAction, (state, { payload }) => {
    const paymentRequestProductIndex = state.paymentRequestProducts.findIndex(
      (paymentRequestProduct: PaymentRequestProduct) =>
        paymentRequestProduct.id === payload.id
    );
    const newPaymentRequestProducts = Object.assign(
      [],
      state.paymentRequestProducts
    );
    newPaymentRequestProducts.splice(paymentRequestProductIndex, 1);
    return {
      ...state,
      paymentRequestProducts: newPaymentRequestProducts,
      paymentRequestProductsCount: state.paymentRequestProductsCount - 1,
    };
  })
);

export function reducer(
  state: HIRLPaymentRequestState | undefined,
  action: Action
) {
  return HIRLPaymentRequestReducer(state, action);
}
