import { Injectable } from '@angular/core';
import { ListRequestParams } from '@/core/schemes/request-params';
import { environment } from '../../../../environments/environment';
import { BaseDataService } from '@/core/services/base-data-service';
import { Observable } from 'rxjs';
import { AboveGradeWallTypeBackendDict } from '../models/enclosure/AboveGradeWallType';
import { ServerResponse } from '@/core/schemes/server-response';

export class AboveGradeWallTypeParams extends ListRequestParams {}

@Injectable({ providedIn: 'root' })
export class AboveGradeWallTypeService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/above_grade_wall_type/`;
  public parentUrl = `${environment.AXIS_API_V3_ROOT}/above_grade_wall/`;

  list(
    params: AboveGradeWallTypeParams
  ): Observable<ServerResponse<AboveGradeWallTypeBackendDict>> {
    return this.http.get<ServerResponse<AboveGradeWallTypeBackendDict>>(
      `${this.baseUrl}`,
      {
        params: this.rollParams(params),
      }
    );
  }

  create(
    wallId: number,
    detailedWallType: AboveGradeWallTypeBackendDict
  ): Observable<AboveGradeWallTypeBackendDict> {
    return this.http.post<AboveGradeWallTypeBackendDict>(
      `${this.parentUrl}${wallId}/above_grade_wall_type/`,
      detailedWallType
    );
  }

  update(
    aboveGradeWallTypeChanges: Partial<AboveGradeWallTypeBackendDict>
  ): Observable<AboveGradeWallTypeBackendDict> {
    return this.http.patch<AboveGradeWallTypeBackendDict>(
      `${this.baseUrl}${aboveGradeWallTypeChanges.id}/`,
      aboveGradeWallTypeChanges
    );
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}${id}/`);
  }
}
