import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'divide',
})
export class DividePipe implements PipeTransform {
  transform(numerator: number, denominator: number): number {
    return Math.round(numerator / denominator) || 1;
  }
}
