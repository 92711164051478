import {
  INSULATION_GRADE_LABELS,
  InsulationGrade,
  MATERIAL_LABELS,
  MaterialBackendDict,
  MaterialChoicesOptions,
  MaterialItem,
  Materials,
} from '@/data/simulation/models/enclosure/Material';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-assembly-layer-material',
  templateUrl: './assembly-layer-material.component.html',
  styleUrls: ['./assembly-layer-material.component.scss'],
})
export class AssemblyLayerMaterialComponent implements OnInit {
  @Output() onChange = new EventEmitter<MaterialBackendDict>();
  @Input() entityForm: FormGroup;
  @Input() showLabel = false;

  materialChoicesOptions = MaterialChoicesOptions;

  materialItems = {};
  insulationGrade = INSULATION_GRADE_LABELS;
  materialLabels = MATERIAL_LABELS;

  ngOnInit(): void {
    Object.entries(Materials).forEach(([key, value]) => {
      this.materialItems[key] = value.name;
    });
  }

  updateMaterial(
    item: keyof typeof MaterialChoicesOptions,
    option: MaterialItem
  ) {
    const newValue = {
      name: item,
      reference: option,
      insulation_grade: InsulationGrade.GradeI,
      r_value: 0.0,
      thickness: 1,
      r_value_per_inch: null,
    };

    if (option) {
      newValue.r_value = Materials[option].r_value;
      newValue.thickness = Materials[option].thickness;
      newValue.r_value_per_inch = Materials[option].r_value_per_inch;
    }
    this.entityForm.patchValue(newValue);
  }
}
