<h2 mat-dialog-title>
  <ng-container *ngIf="!isEditForm">
    Create New <b>Payment Request</b>
  </ng-container>
  <ng-container *ngIf="isEditForm">
    Edit <b>Payment Request</b>
  </ng-container>
</h2>
<mat-dialog-content *ngIf="initialized">
  <form [formGroup]="form" class="mb-2">
    <div class="row"
         *ngIf="!isEditForm && currentUser.company_info?.slug === CustomerHIRLSettings.companySlug || currentUser.is_superuser">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Manufacturer</mat-label>
          <app-generic-model-autocomplete modelClassName="company" formControlName="manufacturer_info"
                                          [filterParams]="{company_type: 'manufacturer'}"></app-generic-model-autocomplete>
        </mat-form-field>
      </div>
    </div>

    <div class="row" *ngIf="this.form.get('manufacturer_info').value">
      <div class="col-12">
        <div>
          <mat-form-field class="mb-2 w-100">
            <mat-label>Request Type</mat-label>
            <mat-select aria-label="Request Type" formControlName="request_type">
              <mat-option *ngFor="let requestTypeChoice of paymentRequestTypes | defaultOrderKeyValue"
                          [value]="requestTypeChoice.key" [disabled]="requestTypeChoice.value.disabled">{{ requestTypeChoice.value.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <ng-container *ngIf="!isEditForm && form.get('request_type').value === PaymentRequestType.fullYearSubscription || form.get('request_type').value === PaymentRequestType.insertNewProduct">
      <div class="row" *ngIf="!isEditForm && form.get('request_type').value === PaymentRequestType.fullYearSubscription && !initialCertificationFee">
         <div class="col-12">
           <h3 class="text-center">
             RENEWAL REQUIREMENTS
           </h3>
           <p>
             Manufacturer Name: <b>{{ form.get('manufacturer_info').value?.name }}</b>
           </p>
           <p>
             Renewal Period: <b>{{ GCPFeeCalculationTypeLabelMapping[this.form.get('manufacturer_info').value?.gcp_fee_calculation_type] }}</b>
           </p>
           <p>
             <i>
               Per the NGBS Green Certified Product Manufacturer’s Agreement, at the end of your certification term you must certify
               that nothing about the product(s) has changed that affects its eligibility to be listed
               as eligible for points in the National Green Building Standard.
             </i>
           </p>
           <p>
             <i>
               If you wish to continue the NGBS Green Certified listing and licensed use of the NGBS Green Certified mark for the product(s)
               and practice(s) listed below, please review the products/practices listed below.
               <b>
                 By selecting a product(s) listed below, you agree that there have been no changes in
                 the product design, specification, raw materials, or manufacturing processes since the
                 initial application that would make the product(s) ineligible for an NGBS Green Certified listing.
               </b>
             </i>
           </p>
         </div>
      </div>

      <div class="row" style="max-height: 320px; overflow-y: auto;">
        <div class="col-12">
          <div class="row" *ngIf="productsIsLoading">
            <div class="col-12">
              <mat-progress-bar
                mode="indeterminate"
                color="accent"
              ></mat-progress-bar>
            </div>
          </div>
          <table
            mat-table
            [dataSource]="products"
            class="table"
            matSort
            [matSortActive]="productsStoredParams.toTableSort().active"
            [matSortDirection]="productsStoredParams.toTableSort().direction"
            (matSortChange)="onHIRLProductGroupsSortChange($event)"
          >
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox matTooltip="Select All Product Groups" formControlName="products_info_select_all"></mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <ng-container *ngIf="row.practice_item_product_count">
                  <mat-checkbox [disabled]="!row.practice_item_product_count"
                                (change)="onHIRLProductGroupsSelect($event, row)"
                                [checked]="isHIRLProductGroupChecked(row)"
                  ></mat-checkbox>
                </ng-container>
                <ng-container *ngIf="!row.practice_item_product_count">
                  <mat-checkbox [disabled]="true"
                                [matTooltip]="'Cannot select Product Group without Practices'"
                  ></mat-checkbox>
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
              <td mat-cell *matCellDef="let row">
                <app-product-display displayType="onlyNameAsLink" [product]="row"></app-product-display>
              </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>Status</th>
              <td mat-cell *matCellDef="let row">
                <span class="badge ml-2 {{ ProductStatusLabelMapping[row.status]?.badgeClass }}">{{ ProductStatusLabelMapping[row.status]?.label }}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="hirl_certificate_approval_date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Initial Approval Date</th>
              <td mat-cell *matCellDef="let row">
                {{ row.hirl_certificate_approval_date | date: 'MM/dd/yyyy' }}
              </td>
            </ng-container>

            <ng-container matColumnDef="practices_number">
              <th mat-header-cell *matHeaderCellDef>NGBS practice(s)</th>
              <td mat-cell *matCellDef="let row">
                {{ row.practices_number?.join(', ') }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="productsDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: productsDisplayedColumns;"></tr>
          </table>
          <div class="d-flex flex-row">
            <div class="d-flex flex-fill justify-content-end">
              <mat-paginator
                [length]="productsCount"
                [pageIndex]="$any(productsStoredParams.page) - 1"
                [pageSize]="productsStoredParams.page_size"
                (page)="onHIRLProductGroupsPaginateChange($event)"
                aria-label="Select page"
              >
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>

    </ng-container>

    <div class="row" *ngIf="form.get('request_type').value">
      <div class="col-12">
        <p class="text-right">
          <b>Total:</b>
          <ng-container *ngIf="!priceIsLoading">{{ totalPrice | currency }}</ng-container>
          <fa-icon [icon]="['fas', 'spinner']" [spin]="true" *ngIf="priceIsLoading"></fa-icon>
        </p>
        <ng-container *ngFor="let priceError of priceErrors">
          <mat-error *ngFor="let kv of priceError | defaultOrderKeyValue">
            {{ kv.value }}
          </mat-error>
        </ng-container>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button color="accent" mat-raised-button (click)="save($event)">Submit to Home Innovation Research Labs</button>
  <button mat-button [mat-dialog-close]="null" color="primary" mat-raised-button>Cancel</button>
</mat-dialog-actions>
