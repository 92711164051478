<h1 mat-dialog-title>Enter reason</h1>
<div mat-dialog-content>
    <mat-form-field>
        <textarea matInput [(ngModel)]="data.reason" cdkFocusInitial></textarea>
    </mat-form-field>
</div>
<mat-dialog-actions align="end">
    <button class="btn btn-primary mr-2" [mat-dialog-close]="data.reason">
        Confirm
    </button>
    <button class="btn btn-outline-dark" (click)="cancelClick()">
        Cancel
    </button>
</mat-dialog-actions>