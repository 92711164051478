import { CompanyInfo } from '@/data/company/models';
import { Community } from '@/data/community/models';
import { ContentTypeInfo } from '@/data/core/models';

export class Relationship {
  id?: number;
  is_attached?: boolean;
  is_viewable?: boolean;
  is_owned?: boolean;
  is_reportable?: boolean;
  company?: number;
  company_info: CompanyInfo;
  content_type?: number;
  community?: number;
  community_info: Community;
  content_type_info?: ContentTypeInfo;
  content_object: string;
}
