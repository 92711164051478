<div class="card position-relative">
    <app-loading-overlay *ngIf="loading"></app-loading-overlay>
    <div class="card-header">
        <div class="d-flex justify-content-between">
            <span>Refrigerator</span>
            <mat-icon [inline]="true">kitchen</mat-icon>
        </div>
    </div>
    <div class="card-body pb-0">
        <app-form formName="refrigeratorForm" [formGroup]="form" (save)="onSave($event)">
            <div>
                <app-select
                    formControlName="refrigerator_location"
                    [label]="fieldLabels.refrigerator_location"
                    [items]="locationLabels">
                </app-select>
                <app-input
                    formControlName="refrigerator_consumption"
                    type="number"
                    [unit]="auxEnergyUnitLabels[entity?.refrigerator_consumption_unit]"
                    [label]="fieldLabels.refrigerator_consumption">
                </app-input>
            </div>
        </app-form>
    </div>
</div>
