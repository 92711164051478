<div class="card">
    <div class="card-header">
        <div class="row">
            <div class="col-7 align-self-center">
                <b>Type:</b>
                <app-hirl-project-type [project]="project"></app-hirl-project-type>
                <app-eep-program-home-status-state *ngIf="project.home_status_info" [homeStatus]="project.home_status_info" [display]="'badge'"></app-eep-program-home-status-state>
            </div>
            <div class="col-5 text-right">
                <app-history-mat-button [objectId]="project.id" [modelClassName]="'hirl_project'" class="mr-1">History</app-history-mat-button>
                <app-permission-mat-button 
                        [permissionResponse]="deletePermissionResponse" 
                        (action)="onDeleteProject()"
                        class="mr-1"
                        [hideOnFalse]="true">
                    Delete
                </app-permission-mat-button>
                <app-permission-mat-button
                        [permissionResponse]="editPermissionResponse"
                        (action)="onEditProject()"
                        class="mr-1">
                    Edit
                </app-permission-mat-button>
                <button mat-raised-button color="accent" [routerLink]="['/', 'home', project.home_status_info.home]" *ngIf="project.home_status_info && project.home_status_info.home">
                    View
                </button>
            </div>
        </div>
    </div>
    <ul class="list-group list-group-flush">
        <li class="list-group-item">
            <b>Project ID:</b>
            {{ project.id }}
        </li>
        <li class="list-group-item">
            <b>Address:</b>
            <span *ngIf="!project.home_status">
                <app-address-display [geocode]="project.home_address_geocode_info" [geocodeBrokeredResponse]="project.home_address_geocode_response_info"></app-address-display> <span *ngIf="project.lot_number">Lot #: {{  project.lot_number }}</span>
            </span>
            <a *ngIf="project.home_status" [routerLink]="['/', 'home', project.home_status_info.home]" matTooltip="View project">
                <app-address-display [geocode]="project.home_address_geocode_info" [geocodeBrokeredResponse]="project.home_address_geocode_response_info"></app-address-display> <span *ngIf="project.lot_number">Lot #: {{  project.lot_number }}</span>
            </a>
        </li>
        <li class="list-group-item" *ngIf="project.is_accessory_structure">
            <b>Accessory Structure Description:</b>
            {{ project.accessory_structure_description }}
        </li>
        <li class="list-group-item" *ngIf="project.is_accessory_dwelling_unit">
            <b>Accessory Dwelling Unit Description:</b>
            {{ project.accessory_dwelling_unit_description }}
        </li>
        <li class="list-group-item">
            <b>H-Number:</b>
            {{ project.h_number }}
        </li>
        <li class="list-group-item" *ngIf="project.is_include_commercial_space">
            <b>Compliance Option:</b>
            {{ commercialSpaceTypeLabelMapping[project.commercial_space_type] }}
        </li>
        <li class="list-group-item" *ngIf="project.is_include_commercial_space">
            <b>Total Commercial Sq. Footage:</b>
            {{ project.total_commercial_space }}
        </li>
        <li class="list-group-item" *ngIf="project.registration_info?.project_type !== hirlProjectRegistrationType.landDevelopment && !project.is_accessory_structure && !project.is_accessory_dwelling_unit">
            <b>HUD Disaster Case Number:</b>
            {{ project.hud_disaster_case_number || 'None entered' }}
        </li>
        <li class="list-group-item" *ngIf="!project.is_accessory_structure && !project.is_accessory_dwelling_unit && project.registration_info.project_type === hirlProjectRegistrationType.multiFamily">
            <b>Building Number:</b>
            {{ project.building_number }}
        </li>
        <li class="list-group-item" *ngIf="!project.is_accessory_structure && !project.is_accessory_dwelling_unit && !project.is_include_commercial_space && project.registration_info.project_type === hirlProjectRegistrationType.multiFamily">
            <b>Story Count:</b>
            {{ project.story_count }}
        </li>
        <li class="list-group-item" *ngIf="!project.is_accessory_structure && !project.is_accessory_dwelling_unit && !project.is_include_commercial_space && project.registration_info.project_type === hirlProjectRegistrationType.multiFamily">
            <b>Number Of Units:</b>
            {{ project.number_of_units }}
        </li>
        <li class="list-group-item" *ngIf="project.registration_info.project_type === hirlProjectRegistrationType.singleFamily || project.registration_info.project_type === hirlProjectRegistrationType.multiFamily">
            <b>Is this an appeals project:</b> {{ HIRLProjectAppealsProjectTypeMapping[project.appeals_project] }}
        </li>
        <li class="list-group-item" *ngIf="project.registration_info.project_type === hirlProjectRegistrationType.singleFamily && project.registration_info.is_build_to_rent || project.registration_info.project_type === hirlProjectRegistrationType.multiFamily">
            <b
            >Will the NGBS Sampling Protocol be utilized for this
                project ?:</b
            >
            {{
            HIRLProjectSamplingMapping[
                project.sampling
                ] || "No Sampling"
            }}
        </li>
        <li class="list-group-item" *ngIf="project.registration_info.project_type === hirlProjectRegistrationType.multiFamily">
            <b>Is this building a two-over-two /maisonette/stacked townhouse ?:</b>
            {{ project.is_townhouse | yesNo }}
        </li>
        <li class="list-group-item" *ngIf="project.registration_info?.project_type !== hirlProjectRegistrationType.landDevelopment && !project.is_accessory_structure && !project.is_accessory_dwelling_unit && !project.is_include_commercial_space">
            <b>NGBS Green+ Badges:</b>&nbsp;
            <span *ngIf="project.green_energy_badges_info.length != 0; else hirlGreenEnergyEmpty;">
                                    <span *ngFor="let greenEnergyBadge of project.green_energy_badges_info; let isLast=last">
                                        {{greenEnergyBadge.name}}{{isLast ? '' : ', '}}
                                    </span>
                                </span>
            <ng-template #hirlGreenEnergyEmpty>None selected</ng-template>
        </li>
        <li class="list-group-item" *ngIf="customerHIRLSettings.waterSenseProgramList.includes(project.registration_info?.eep_program_info?.slug)">
            <b>Is the project seeking WaterSense Certification?:</b>&nbsp;
            {{ project.is_require_water_sense_certification | yesNo }}
        </li>
        <li class="list-group-item" *ngIf="customerHIRLSettings.requireRoughInspectionProgramList.includes(project.registration_info?.eep_program_info?.slug)">
            <b>Will this remodel project require a rough inspection?:</b>&nbsp;
            {{ project.is_require_rough_inspection | yesNo }}
        </li>
        <li class="list-group-item" *ngIf="customerHIRLSettings.wriSeekingProgramList.includes(project.registration_info?.eep_program_info?.slug)">
            <b>Is this project seeking a WRI?:</b>&nbsp;
            {{ project.is_require_wri_certification | yesNo }}
        </li>
        <li class="list-group-item" *ngIf="project.registration_info?.project_type === hirlProjectRegistrationType.landDevelopment && project.land_development_project_type === landDevelopmentProjectType.phase">
            <b>Are all homes in the development seeking NGBS certification?:</b>&nbsp;
            {{ project.are_all_homes_in_ld_seeking_certification | yesNo }}
        </li>
        <li class="list-group-item" *ngIf="project.registration_info?.project_type === hirlProjectRegistrationType.landDevelopment && project.land_development_project_type === landDevelopmentProjectType.phase">
            <b>Number Of Lots:</b>&nbsp;
            {{ project.number_of_lots }}
        </li>
    </ul>
</div>
