import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export enum InputDialogInputTypeEnum {
  input = 'input',
  textArea = 'textarea',
}

export class InputDialogData {
  text: string;
  title: string;
  inputType: InputDialogInputTypeEnum = InputDialogInputTypeEnum.input;
  inputIsRequired = false;
  confirmBtnText = 'Confirm';
  cancelBtnText = 'Cancel';

  constructor(data: Partial<InputDialogData>) {
    Object.assign(this, data);
  }
}

@Component({
  selector: 'app-input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.scss'],
})
export class InputDialogComponent implements OnInit {
  public inputDialogInputTypeEnum = InputDialogInputTypeEnum;

  constructor(
    public dialogRef: MatDialogRef<InputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InputDialogData
  ) {}

  ngOnInit() {}

  cancelClick(): void {
    this.dialogRef.close();
  }

  onConfirm($event: MouseEvent) {
    if (this.data.inputIsRequired) {
      if (this.data.text) {
        this.dialogRef.close(this.data.text);
      }
    } else {
      this.dialogRef.close(this.data.text);
    }
  }
}
