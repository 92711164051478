import { createReducer, on } from '@ngrx/store';
import * as DuctActions from './actions';
import { DuctBackendDict } from '@/data/simulation/models/mechanicals/Duct';
import { BaseState } from '../shared/base.state';
import { addStateErrorCount } from '../helper';

export type DuctState = BaseState<DuctBackendDict>;

export const initialState: DuctState = {
  entities: {},
  loading: {},
  errors: {},
};

export const reducer = createReducer(
  initialState,
  on(DuctActions.loadDuctsSuccess, (state, { ducts, errors }) => {
    const entities = ducts.reduce(
      (acc, duct) => ({ ...acc, [duct.id]: duct }),
      {}
    );
    const loading = ducts.reduce(
      (acc, duct) => ({ ...acc, [duct.id]: false }),
      {}
    );
    return {
      entities: { ...state.entities, ...entities },
      loading: { ...state.loading, ...loading },
      errors: { ...state.errors, ...addStateErrorCount(errors) },
    };
  }),

  on(DuctActions.updateDuct, (state, { ductChanges }) => ({
    ...state,
    loading: { ...state.loading, [ductChanges.id]: true },
  })),

  on(DuctActions.updateDuctFailure, (state, { id }) => ({
    ...state,
    loading: { ...state.loading, [id]: false },
  })),

  on(DuctActions.removeDuctSuccess, (state, { id }) => {
    const { [id]: removed, ...entities } = state.entities;
    const { [id]: removedLoading, ...loading } = state.loading;
    const { [id]: removedErrors, ...errors } = state.errors;
    return { entities, loading, errors };
  })
);
