<div class="container" *ngIf="initialized">
    <div class="row mt-3">
        <div class="col-12">
            <h5>
                <button mat-raised-button appBackButton color="accent">
                    <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
                </button>
                Edit page {{ flatPage?.url }}
            </h5>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <form [formGroup]="formGroup">
                <div class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline" class="mb-2 w-100">
                            <mat-label>Title</mat-label>
                            <input matInput type="text" placeholder="Page title"
                                   formControlName="title">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <ckeditor
                                formControlName="content"
                                [editor]="editor"
                                [config]="editorConfig">
                        </ckeditor>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="row mt-2" *ngIf="!isLoading">
        <div class="col-12 text-center">
            <button mat-raised-button color="accent" (click)="onSave($event)">
                <span>Save</span>
            </button>
        </div>
    </div>
    <div class="row" *ngIf="isLoading">
        <div class="col-12">
            <div class="d-flex justify-content-center">
                <mat-spinner [diameter]="50"></mat-spinner>
            </div>
        </div>
    </div>

</div>