export enum Location {
  CONDITIONED_SPACE = 'conditioned_space',
  UNCONDITIONED_SPACE = 'unconditioned_space',
  CONDITIONED_CRAWL_SPACE = 'conditioned_crawl',
  UNCONDITIONED_BASEMENT = 'unconditioned_basement',
  UNCONDITIONED_INSULATED_BASEMENT = 'unconditioned_insulated_basement',
  CONDITIONED_BASEMENT = 'conditioned_basement',
  ENCLOSED_CRAWL = 'enclosed_crawl',
  CRAWL_SPACE_UNVENTED = 'unvented_crawl',
  CRAWL_SPACE_UNVENTED_INSULATED = 'crawl_unvented_ins',
  CRAWL_SPACE_UNVENTED_UNINSULATED = 'crawl_unvented_un_ins',
  CRAWL_SPACE_UNVENTED_INSULATED_FLOOR = 'crawl_unvented_ins_floor',
  CRAWL_SPACE_VENTED = 'vented_crawl',
  CRAWL_SPACE_VENTED_INSULATED = 'crawl_vented_ins',
  CRAWL_SPACE_VENTED_UNINSULATED = 'crawl_vented_un_ins',
  CRAWL_SPACE_VENTED_INSULATED_FLOOR = 'crawl_vented_ins_floor',
  CONDITIONED_ATTIC = 'conditioned_attic',
  SEALED_ATTIC = 'sealed_attic',
  GARAGE = 'garage',
  GARAGE_WALL = 'garage_wall',
  GARAGE_CEILING = 'garage_ceiling',
  UNKNOWN = 'unknown',
  ATTIC = 'attic',
  VENTED_ATTIC = 'vented_attic',
  ATTIC_POOR_VENTED = 'attic_vented_poor',
  ATTIC_VENTED_RADIANT = 'attic_vented_radiant',
  ATTIC_POOR_VENTED_RADIANT = 'attic_vented_radiant_poor',
  ATTIC_UNDER_INS_VENTED = 'attic_under_ins_vented',
  ATTIC_UNDER_INS_POOR_VENTED = 'attic_under_ins_vented_poor',
  ATTIC_UNDER_INS_VENTED_RAIANT = 'attic_under_ins_vented_radiant',
  ATTIC_UNDER_INS_POOR_VENTED_RADIANT = 'attic_under_ins_vented_radiant_poor',
  UNCONDITIONED_GARAGE = 'unconditioned_garage',
  AMBIENT = 'ambient',
  ADIABATIC = 'adiabatic',
  CONDITIONED_SEALED_ATTIC = 'conditioned_sealed_attic',
  OPEN_CRAWL_SPACE = 'open_crawl',
  VAULTED_ROOF = 'vaulted_roof',
  BASEMENT_UNINSULATED = 'basement_un_ins',
  BASEMENT_INSULATED_WALLS = 'basement_ins_walls',
  BASEMENT_INSULATED_CEILING = 'basement_ins_ceiling',
  UNDER_SLAB = 'under_slab',
  MOBILE_HOME_BELLY = 'mobile',
  EXTERIOR_WALL = 'ext_wall',
  EXPOSED_EXTERIOR_WALL = 'ext_wall_exposed',
  ROOF_DECK = 'roof_deck',
  OTHER_INTERIOR = 'other_int',
  OTHER_EXTERIOR = 'other_ext',
  ATTIC_VENTED = 'attic_vented',
  MF_UNRATED_CONDITIONED = 'mf_unrated_conditioned',
  MF_UNRATED_ATTIC = 'mf_unrated_attic',
  MF_UNRATED_HEATED = 'mf_unrated_heated',
  MF_BUFFER = 'mf_buffer',
  MF_NONFREEZING = 'mf_nonfreezing',
  NONE = 'none',
}

export const LocationLabels: Record<Location, string> = {
  [Location.CONDITIONED_SPACE]: 'Conditioned Space',
  [Location.UNCONDITIONED_SPACE]: 'Unconditioned Space',
  [Location.CONDITIONED_CRAWL_SPACE]: 'Conditioned Crawl Space',
  [Location.UNCONDITIONED_BASEMENT]: 'Unconditioned Basement',
  [Location.UNCONDITIONED_INSULATED_BASEMENT]:
    'Unconditioned Insulated Basement',
  [Location.CONDITIONED_BASEMENT]: 'Conditioned Basement',
  [Location.ENCLOSED_CRAWL]: 'Enclosed Crawl Space',
  [Location.CRAWL_SPACE_UNVENTED]: 'Unvented Crawl Space',
  [Location.CRAWL_SPACE_UNVENTED_INSULATED]: 'Insulated unvented Crawlspace',
  [Location.CRAWL_SPACE_UNVENTED_UNINSULATED]:
    'Uninsulated unvented Crawlspace',
  [Location.CRAWL_SPACE_UNVENTED_INSULATED_FLOOR]:
    'Insulated floor-only unvented Crawlspace',
  [Location.CRAWL_SPACE_VENTED]: 'Vented (Open) Crawl Space',
  [Location.CRAWL_SPACE_VENTED_INSULATED]: 'Insulated vented Crawlspace',
  [Location.CRAWL_SPACE_VENTED_UNINSULATED]: 'Uninsulated vented Crawlspace',
  [Location.CRAWL_SPACE_VENTED_INSULATED_FLOOR]:
    'Insulated floor-only vented Crawlspace',
  [Location.CONDITIONED_ATTIC]: 'Conditioned Attic',
  [Location.SEALED_ATTIC]: 'Sealed Attic',
  [Location.GARAGE]: 'Garage',
  [Location.GARAGE_WALL]: 'Garage Wall',
  [Location.GARAGE_CEILING]: 'Garage Ceiling',
  [Location.UNKNOWN]: 'Unknown',
  [Location.ATTIC]: 'Attic',
  [Location.VENTED_ATTIC]: 'Vented Attic',
  [Location.ATTIC_POOR_VENTED]: 'Attic Poor-Vented',
  [Location.ATTIC_VENTED_RADIANT]: 'Attic Well-Vented w/ Radiant Barrier',
  [Location.ATTIC_POOR_VENTED_RADIANT]: 'Attic Poor-Vented w/ Radiant Barrier',
  [Location.ATTIC_UNDER_INS_VENTED]: 'Attic under insulation Well-Vented',
  [Location.ATTIC_UNDER_INS_POOR_VENTED]: 'Attic under insulation Poor-Vented',
  [Location.ATTIC_UNDER_INS_VENTED_RAIANT]:
    'Attic under insulation Well-Vented w/ Radiant Barrier',
  [Location.ATTIC_UNDER_INS_POOR_VENTED_RADIANT]:
    'Attic under insulation Poor-Vented w/ Radiant Barrier',
  [Location.UNCONDITIONED_GARAGE]: 'Unconditioned Garage / Open Crawlspace',
  [Location.AMBIENT]: 'Ambient',
  [Location.ADIABATIC]: 'Adiabatic',
  [Location.CONDITIONED_SEALED_ATTIC]: 'Conditioned / Sealed Attic',
  [Location.OPEN_CRAWL_SPACE]: 'Open Crawl Space',
  [Location.VAULTED_ROOF]: 'Vaulted Roof',
  [Location.BASEMENT_UNINSULATED]: 'Uninsulated Basement',
  [Location.BASEMENT_INSULATED_WALLS]: 'Insulated wall Basement',
  [Location.BASEMENT_INSULATED_CEILING]: 'Insulated ceiling Basement',
  [Location.UNDER_SLAB]: 'Under-Slab',
  [Location.MOBILE_HOME_BELLY]: 'Mobile home belly',
  [Location.EXTERIOR_WALL]: 'Exterior Wall',
  [Location.EXPOSED_EXTERIOR_WALL]: 'Exposed exterior Wall',
  [Location.ROOF_DECK]: 'Roof Deck',
  [Location.OTHER_INTERIOR]: 'Other interior',
  [Location.OTHER_EXTERIOR]: 'Other exterior',
  [Location.ATTIC_VENTED]: 'Attic Well-Vented',
  [Location.MF_UNRATED_CONDITIONED]: 'Multi-Family Unrated Conditioned Space',
  [Location.MF_UNRATED_ATTIC]: 'Multi-Family Unrated Attic',
  [Location.MF_UNRATED_HEATED]: 'Multi-Family Unrated Heated Space',
  [Location.MF_BUFFER]: 'Multi-Family Buffer Space',
  [Location.MF_NONFREEZING]: 'Multi-Family Nonfreezing Space',
  [Location.NONE]: 'None',
};

export enum BasementLocations {
  BASEMENT_UNINSULATED = Location.BASEMENT_UNINSULATED,
  BASEMENT_INSULATED_CEILING = Location.BASEMENT_INSULATED_CEILING,
  BASEMENT_INSULATED_WALLS = Location.BASEMENT_INSULATED_WALLS,
  CONDITIONED_BASEMENT = Location.CONDITIONED_BASEMENT,
  UNCONDITIONED_INSULATED_BASEMENT = Location.UNCONDITIONED_INSULATED_BASEMENT,
  UNCONDITIONED_BASEMENT = Location.UNCONDITIONED_BASEMENT,
}

export enum Color {
  LIGHT = 'light',
  MEDIUM = 'medium',
  MEDIUM_DARK = 'med_dark',
  DARK = 'dark',
  REFLECTIVE = 'reflective',
  STD140 = 'std140',
  STD140_LOWABS = 'std140-lowabs',
  WHITE_COMPOSITE = 'white_comp',
  WHITE_TILE = 'white_tile',
  WHITE_METAL = 'white_metal',
  UNKNOWN = 'unknown',
}
export const ColorLabels: Record<Color, string> = {
  [Color.LIGHT]: 'Light',
  [Color.MEDIUM]: 'Medium',
  [Color.MEDIUM_DARK]: 'Medium Dark (Default)',
  [Color.DARK]: 'Dark',
  [Color.REFLECTIVE]: 'Reflective',
  [Color.STD140]: 'Std 140',
  [Color.STD140_LOWABS]: 'Std 140 Low Abs',
  [Color.WHITE_COMPOSITE]: 'White Composite Shingles',
  [Color.WHITE_TILE]: 'White Tile/Concrete Shingles',
  [Color.WHITE_METAL]: 'White Metal/TPO',
  [Color.UNKNOWN]: 'Unknown',
};

export enum LengthUnit {
  FT = 'ft',
  METER = 'm',
  YARD = 'yd',
  INCH = 'inch',
  CM = 'cm',
  MIL = 'mil',
  MM = 'mm',
}

export const LengthUnitLabels: Record<LengthUnit, string> = {
  [LengthUnit.FT]: 'ft',
  [LengthUnit.METER]: 'm',
  [LengthUnit.YARD]: 'yd',
  [LengthUnit.INCH]: 'in',
  [LengthUnit.CM]: 'cm',
  [LengthUnit.MIL]: 'mil',
  [LengthUnit.MM]: 'mm',
};

export enum AreaUnit {
  SQ_FT = 'sq_ft',
  SQ_M = 'sq_m',
  SQ_Y = 'sq_y',
}
export const AreaUnitLabels: Record<AreaUnit, string> = {
  [AreaUnit.SQ_FT]: 'ft²',
  [AreaUnit.SQ_M]: 'm²',
  [AreaUnit.SQ_Y]: 'y²',
};

export enum VolumeUnit {
  CU_FT = 'cu_ft',
  CU_YD = 'cu_yd',
  CU_M = 'cu_m',
}

export const VolumeUnitLabels: Record<VolumeUnit, string> = {
  [VolumeUnit.CU_FT]: 'ft³',
  [VolumeUnit.CU_YD]: 'yd³',
  [VolumeUnit.CU_M]: 'm³',
};

export enum StudType {
  WOOD_STUD = 'wood_stud',
  STEEL_FRAME = 'steel_frame',
  SOLID_CONCRETE = 'concrete',
}
export const StudTypeLabels: Record<StudType, string> = {
  [StudType.WOOD_STUD]: 'Wood Stud',
  [StudType.STEEL_FRAME]: 'Steel Frame',
  [StudType.SOLID_CONCRETE]: 'Solid Concrete',
};

export enum FloorCovering {
  CARPET = 'carpet',
  TILE = 'tile',
  HARDWOOD = 'hardwood',
  VINYL = 'vinyl',
  NONE = 'none',
  RIGID_R3_INSULATION = 'rigid_r3',
  CODE_CARPET = 'code_carpet',
}

export const FloorCoveringLabels: Record<FloorCovering, string> = {
  [FloorCovering.CARPET]: 'Carpet',
  [FloorCovering.TILE]: 'Tile',
  [FloorCovering.HARDWOOD]: 'Hardwood',
  [FloorCovering.VINYL]: 'Vinyl',
  [FloorCovering.NONE]: 'None',
  [FloorCovering.RIGID_R3_INSULATION]: 'Rigid R-3 Insulation',
  [FloorCovering.CODE_CARPET]: 'Code Reference Carpet',
};

export enum WallCavityInsulationType {
  XPS = 'xps',
  EPS = 'eps',
  SPRAY_FOAM = 'foam',
  CELLULOSE = 'cellulose',
  BATT = 'fiberglass_batt',
  WOOL = 'wool',
  POLY = 'polyiso',
  VERMICULITE = 'vermiculite',
  OTHER = 'other',
}

export const WallCavityInsulationTypeLabels: Record<
  WallCavityInsulationType,
  string
> = {
  [WallCavityInsulationType.XPS]: 'XPS',
  [WallCavityInsulationType.EPS]: 'EPS',
  [WallCavityInsulationType.SPRAY_FOAM]: 'Spray Foam',
  [WallCavityInsulationType.CELLULOSE]: 'Cellulose',
  [WallCavityInsulationType.BATT]: 'Fiberglass Batt',
  [WallCavityInsulationType.WOOL]: 'Wool',
  [WallCavityInsulationType.POLY]: 'Polyisocyanurate',
  [WallCavityInsulationType.VERMICULITE]: 'Vermiculite',
  [WallCavityInsulationType.OTHER]: 'Other',
};

export enum Orientation {
  NORTH = 'north',
  NORTHEAST = 'northeast',
  EAST = 'east',
  SOUTHEAST = 'southeast',
  SOUTH = 'south',
  SOUTHWEST = 'southwest',
  WEST = 'west',
  NORTHWEST = 'northwest',
  UNKNOWN = 'unknown',
}

export const OrientationLabels: Record<Orientation, string> = {
  [Orientation.NORTH]: 'North',
  [Orientation.NORTHEAST]: 'Northeast',
  [Orientation.EAST]: 'East',
  [Orientation.SOUTHEAST]: 'Southeast',
  [Orientation.SOUTH]: 'South',
  [Orientation.SOUTHWEST]: 'Southwest',
  [Orientation.WEST]: 'West',
  [Orientation.NORTHWEST]: 'Northwest',
  [Orientation.UNKNOWN]: 'Unknown',
};

export const MatIconDirectionLabels: Record<Orientation, string> = {
  [Orientation.NORTH]: 'north',
  [Orientation.NORTHEAST]: 'north_east',
  [Orientation.EAST]: 'east',
  [Orientation.SOUTHEAST]: 'south_east',
  [Orientation.SOUTH]: 'south',
  [Orientation.SOUTHWEST]: 'south_west',
  [Orientation.WEST]: 'west',
  [Orientation.NORTHWEST]: 'north_west',
  [Orientation.UNKNOWN]: 'help_outline',
};

export const OrientationToAzimuth: Record<Orientation, number> = {
  [Orientation.NORTH]: 0,
  [Orientation.NORTHEAST]: 45,
  [Orientation.EAST]: 90,
  [Orientation.SOUTHEAST]: 135,
  [Orientation.SOUTH]: 180,
  [Orientation.SOUTHWEST]: 225,
  [Orientation.WEST]: 270,
  [Orientation.NORTHWEST]: 315,
  [Orientation.UNKNOWN]: -1,
};

export enum FuelType {
  NATURAL_GAS = 'natural_gas',
  ELECTRIC = 'electric',
  OIL = 'oil',
  KEROSENE = 'kerosene',
  PROPANE = 'propane',
  WOOD = 'wood',
}

export const FuelTypeLabels: Record<FuelType, string> = {
  [FuelType.NATURAL_GAS]: 'Natural Gas',
  [FuelType.ELECTRIC]: 'Electric',
  [FuelType.OIL]: 'Oil',
  [FuelType.KEROSENE]: 'Kerosene',
  [FuelType.PROPANE]: 'Propane',
  [FuelType.WOOD]: 'Wood',
};

export enum HeatingEfficiencyUnit {
  AFUE = 'afue',
  COP = 'cop',
  HSPF = 'hspf',
  HSPF2 = 'hspf2',
  PCT_EFF = 'pct_eff',
  ADJUSTED_EFFICIENCY = 'adj_eff',
}

export const HeatingEfficiencyUnitLabels = {
  [HeatingEfficiencyUnit.AFUE]: 'AFUE',
  [HeatingEfficiencyUnit.HSPF]: 'HSPF',
  [HeatingEfficiencyUnit.HSPF2]: 'HSPF2',
  [HeatingEfficiencyUnit.COP]: 'COP',
  [HeatingEfficiencyUnit.PCT_EFF]: '% Eff',
  [HeatingEfficiencyUnit.ADJUSTED_EFFICIENCY]: 'Adjusted Eff',
};

export enum CoolingEfficiencyUnit {
  COP = 'cop',
  PCT_EFF = 'pct_eff',
  ADJUSTED_EFFICIENCY = 'adj_eff',
  SEER = 'seer',
  SEER2 = 'seer2',
  EER = 'eer',
  EER2 = 'eer2',
}

export const CoolingEfficiencyUnitLabels: Record<
  CoolingEfficiencyUnit,
  string
> = {
  [CoolingEfficiencyUnit.SEER]: 'SEER',
  [CoolingEfficiencyUnit.SEER2]: 'SEER2',
  [CoolingEfficiencyUnit.COP]: 'COP',
  [CoolingEfficiencyUnit.EER]: 'EER',
  [CoolingEfficiencyUnit.EER2]: 'EER2',
  [CoolingEfficiencyUnit.PCT_EFF]: '% Eff',
  [CoolingEfficiencyUnit.ADJUSTED_EFFICIENCY]: 'Adjusted Eff',
};

export enum CostUnit {
  USC = 'usc',
  USD = 'usd',
  EURO = 'euro',
}

export const CostUnitLabels: Record<CostUnit, string> = {
  [CostUnit.USC]: '¢',
  [CostUnit.USD]: '$',
  [CostUnit.EURO]: '€',
};

export enum EnergyUnit {
  BTU = 'btu',
  KBTU = 'kbtu',
  MBTU = 'mbtu',
  JOULE = 'j',
  KILOJOULE = 'kj',
  MEGAJOULE = 'mj',
  KWH = 'kwh',
  THERM = 'therm',
  PROPANE_GALLON = 'propane_gallon',
  KEROSENE_GALLON = 'kerosene_gallon',
  CCF = 'ccf',
  MCF = 'mcf',
  CORD = 'cord',
}

export const EnergyUnitLabels: { [key in EnergyUnit]: string } = {
  [EnergyUnit.BTU]: 'Btu',
  [EnergyUnit.KBTU]: 'kBtu',
  [EnergyUnit.MBTU]: 'MMBtu',
  [EnergyUnit.JOULE]: 'Joules',
  [EnergyUnit.KILOJOULE]: 'KiloJoules',
  [EnergyUnit.MEGAJOULE]: 'MegaJoules',
  [EnergyUnit.KWH]: 'kWh',
  [EnergyUnit.THERM]: 'Therms',
  [EnergyUnit.PROPANE_GALLON]: 'Gallons (Propane)',
  [EnergyUnit.KEROSENE_GALLON]: 'Gallons (Kerosene)',
  [EnergyUnit.CCF]: 'Natural Gas CCF',
  [EnergyUnit.MCF]: 'Natural Gas MCF',
  [EnergyUnit.CORD]: 'Wood Cord',
};

export enum AuxEnergyUnit {
  KWH_YEAR = 'kwh_yr',
  EAE = 'eae',
  WATT = 'watt',
}

export const AuxEnergyUnitLabels: Record<AuxEnergyUnit, string> = {
  [AuxEnergyUnit.KWH_YEAR]: 'kWh/yr',
  [AuxEnergyUnit.EAE]: 'Eae',
  [AuxEnergyUnit.WATT]: 'Watt',
};

export enum ApplianceCostRateUnit {
  USD_KWH = 'usd_kwh',
  USD_THERM = 'usd_therm',
}

export const ApplianceCostRateUnitLabels: Record<
  ApplianceCostRateUnit,
  string
> = {
  [ApplianceCostRateUnit.USD_KWH]: '$/kWh',
  [ApplianceCostRateUnit.USD_THERM]: '$/therm',
};

export enum HeatingCoolingCapacityUnit {
  KW = 'kw',
  KBTUH = 'kbtuh',
}

export const HeatingCoolingCapacityUnitLabels: Record<
  HeatingCoolingCapacityUnit,
  string
> = {
  [HeatingCoolingCapacityUnit.KW]: 'kW',
  [HeatingCoolingCapacityUnit.KBTUH]: 'kBtu/h',
};

export enum ERICalculationChoices {
  ERI2014 = '2014',
  ERI2014A = '2014A',
  ERI2014AE = '2014AE',
  ERI2014AEG = '2014AEG',
  ERI2019 = '2019',
  ERI2019A = '2019A',
  ERI2019AB = '2019AB',
  LATEST = 'latest',
}

export const ERICalculationChoicesLabelMapping: Record<
  ERICalculationChoices,
  string
> = {
  [ERICalculationChoices.ERI2014]: '2014',
  [ERICalculationChoices.ERI2014A]: '2014A',
  [ERICalculationChoices.ERI2014AE]: '2014AE',
  [ERICalculationChoices.ERI2014AEG]: '2014AEG',
  [ERICalculationChoices.ERI2019]: '2019',
  [ERICalculationChoices.ERI2019A]: '2019A',
  [ERICalculationChoices.ERI2019AB]: '2019AB',
  [ERICalculationChoices.LATEST]: 'Latest',
};

export enum ESTARCalculationChoices {
  ESTARSFNAT3p2 = 'SF_National_3.2',
  ESTARSFNAT3p1 = 'SF_National_3.1',
  ESTARSFNAT3p0 = 'SF_National_3.0',
  ESTARSFPAC3p0 = 'SF_Pacific_3.0',
  ESTARSFFL3p1 = 'SF_Florida_3.1',
  ESTARSFORWA3p2 = 'SF_OregonWashington_3.2',
  ESTARMFNAT1p2 = 'MF_National_1.2',
  ESTARMFNAT1p1 = 'MF_National_1.1',
  ESTARMFNAT1p0 = 'MF_National_1.0',
  ESTARMFORWA1p0 = 'MF_OregonWashington_1.2',
}

export const ESTARCalculationChoicesLabelMapping: Record<
  ESTARCalculationChoices,
  string
> = {
  [ESTARCalculationChoices.ESTARSFNAT3p2]: 'Single Family National 3.2',
  [ESTARCalculationChoices.ESTARSFNAT3p1]: 'Single Family National 3.1',
  [ESTARCalculationChoices.ESTARSFNAT3p0]: 'Single Family National 3.0',
  [ESTARCalculationChoices.ESTARSFPAC3p0]: 'Single Family Pacific 3.0',
  [ESTARCalculationChoices.ESTARSFFL3p1]: 'Single Family Florida 3.1',
  [ESTARCalculationChoices.ESTARSFORWA3p2]: 'SF OR/WA 3.2',
  [ESTARCalculationChoices.ESTARMFNAT1p2]: 'Multi-Family National 1.2',
  [ESTARCalculationChoices.ESTARMFNAT1p1]: 'Multi-Family National 1.1',
  [ESTARCalculationChoices.ESTARMFNAT1p0]: 'Multi-Family National 1.0',
  [ESTARCalculationChoices.ESTARMFORWA1p0]: 'Multi-Family OR/WA 1.2',
};

export enum CO2CalculationChoices {
  CO2019ABCD = '2019ABCD',
  LATEST = 'latest',
}

export const CO2CalculationChoicesLabelMapping: Record<
  CO2CalculationChoices,
  string
> = {
  [CO2CalculationChoices.CO2019ABCD]: '2019ABCD',
  [CO2CalculationChoices.LATEST]: 'Latest',
};

export enum IECCCalculationChoices {
  IECC2015 = '2015',
  IECC2018 = '2018',
  IECC2021 = '2021',
  IECC2024 = '2024',
}

export const IECCCalculationChoicesLabelMapping: Record<
  IECCCalculationChoices,
  string
> = {
  [IECCCalculationChoices.IECC2015]: '2015',
  [IECCCalculationChoices.IECC2018]: '2018',
  [IECCCalculationChoices.IECC2021]: '2021',
  [IECCCalculationChoices.IECC2024]: '2024',
};

export enum ZERHCalculationChoices {
  MF2p0 = 'MF_2.0',
  SF2p0 = 'SF_2.0',
  SF1p0 = '1.0',
}

export const ZERHCalculationChoicesLabelMapping: Record<
  ZERHCalculationChoices,
  string
> = {
  [ZERHCalculationChoices.MF2p0]: 'Multi Family 2.0',
  [ZERHCalculationChoices.SF2p0]: 'Single Family 2.0',
  [ZERHCalculationChoices.SF1p0]: 'Single Family 1.0',
};

export enum InsulationGrade {
  Grade1 = '1',
  Grade2 = '2',
  Grade3 = '3',
}

export const InsulationGradeLabelMapping: Record<InsulationGrade, string> = {
  [InsulationGrade.Grade1]: 'I',
  [InsulationGrade.Grade2]: 'II',
  [InsulationGrade.Grade3]: 'III',
};

export enum TemperatureUnit {
  F = 'F',
  C = 'C',
}

export const TemperatureUnitLabels: Record<TemperatureUnit, string> = {
  [TemperatureUnit.F]: '°F',
  [TemperatureUnit.C]: '°C',
};

export enum MechanicalMotorType {
  SINGLE = 'single',
  DUAL = 'two',
  VARIABLE = 'variable',
}

export const MechanicalMotorTypeLabels: Record<MechanicalMotorType, string> = {
  [MechanicalMotorType.SINGLE]: 'Single Stage',
  [MechanicalMotorType.DUAL]: 'Two Stage',
  [MechanicalMotorType.VARIABLE]: 'Variable Speed (ECM)',
};
