import { createReducer, on } from '@ngrx/store';
import * as SlabActions from './actions';
import { SlabBackendDict } from '@/data/simulation/models/enclosure/Slab';
import { BaseState, sharedInitialState } from '../shared/base.state';
import { addErrorCount, addStateErrorCount } from '../helper';

export type SlabState = BaseState<SlabBackendDict>;

export const initialState: SlabState = sharedInitialState;

export const reducer = createReducer(
  initialState,
  on(SlabActions.loadSlabsSuccess, (currentState, payload) => {
    const entities = payload.slabs.reduce(
      (acc, slab) => ({ ...acc, [slab.id]: slab }),
      {}
    );
    const loadingStatus = payload.slabs.reduce(
      (acc, slab) => ({ ...acc, [slab.id]: false }),
      {}
    );

    return {
      entities: { ...currentState.entities, ...entities },
      loading: { ...currentState.loading, ...loadingStatus },
      errors: { ...currentState.errors, ...addStateErrorCount(payload.errors) },
    };
  }),

  on(SlabActions.removeSlabSuccess, (currentState, { id }) => {
    const { [id]: removed, ...entities } = currentState.entities;
    const { [id]: removedLoading, ...loading } = currentState.loading;
    const { [id]: removedErrors, ...errors } = currentState.errors;

    return {
      entities,
      loading,
      errors,
    };
  }),

  on(SlabActions.updateSlab, (state, { id }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [id]: true,
      },
    };
  }),

  on(SlabActions.removeSlab, (state, { slab }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [slab.id]: true,
      },
    };
  }),

  on(
    SlabActions.updateSlabFailure,
    SlabActions.removeSlabFailure,
    (state, { id }) => {
      return {
        ...state,
        loading: {
          ...state.loading,
          [id]: false,
        },
      };
    }
  ),

  on(SlabActions.updateSlabErrors, (state, { id, errors }) => {
    return {
      ...state,
      errors: {
        ...state.errors,
        [id]: errors,
      },
    };
  }),

  on(SlabActions.updateSlabSuccess, (state, { slab, errors }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        [slab.id]: slab,
      },
      loading: {
        ...state.loading,
        [slab.id]: false,
      },
      errors: {
        ...state.errors,
        [slab.id]: addErrorCount(errors),
      },
    };
  }),

  on(SlabActions.setSlabType, (state, { slabId, slabTypeId }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        [slabId]: {
          ...state.entities[slabId],
          type: slabTypeId,
        },
      },
    };
  })
);
