import { Component, Input, OnInit } from '@angular/core';
import {
  Invoice,
  InvoiceInfo,
  InvoiceStateLabelMapping,
} from '@/data/invoicing/models';

export enum InvoiceDisplayComponentType {
  onlyId = 'onlyId',
  onlyIdAsLink = 'onlyIdAsLink',
  nameAsLinkAndStatus = 'nameAsLinkAndStatus',
  nameAndStatus = 'nameAndStatus',
}
@Component({
  selector: 'app-invoice-display',
  templateUrl: './invoice-display.component.html',
  styleUrls: ['./invoice-display.component.scss'],
})
export class InvoiceDisplayComponent implements OnInit {
  protected readonly InvoiceStateLabelMapping = InvoiceStateLabelMapping;

  @Input() displayType:
    | InvoiceDisplayComponentType
    | keyof typeof InvoiceDisplayComponentType =
    InvoiceDisplayComponentType.onlyIdAsLink;
  @Input() invoice?: Invoice | InvoiceInfo;

  public InvoiceDisplayComponentType = InvoiceDisplayComponentType;

  ngOnInit(): void {}
}
