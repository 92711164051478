<label *ngIf="data">
    {{ label }} &nbsp;
    <mat-icon
        [inline]="true"
        class="tooltip-style"
        *ngIf="infoDialog"
        (click)="openDialog()">
        help
    </mat-icon>
    <mat-icon
        [inline]="true"
        class="tooltip-style"
        *ngIf="description"
        matTooltip="{{description}}">
        info
    </mat-icon>
    <span class="required" *ngIf="presentAstrix">*</span>
</label>
