import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { first, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getInfoUser } from '@/state/selectors/info.selector';
import { AppState } from '@/state/reducers';
import { FloorPlanService } from '@/data/floorplan/services/floorplan.service';
import { CompanyType } from '@/data/company/models';
import { FloorPlan } from '@/data/floorplan/models/floorplan';

@Injectable({
  providedIn: 'root',
})
export class FloorplanPermissionService {
  constructor(
    public store: Store<AppState>,
    public floorplanService: FloorPlanService
  ) {}

  canCreate(): Observable<ObjectPermissionResponse> {
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
    }).pipe(
      map(({ currentUser }) => {
        if (currentUser.is_superuser) {
          return new ObjectPermissionResponse(true);
        }
        if (!currentUser.is_company_admin) {
          return new ObjectPermissionResponse(
            false,
            'Only Company Admin users can Create new Floorplans'
          );
        }
        if (currentUser.company_info.company_type === CompanyType.rater) {
          return new ObjectPermissionResponse(true);
        }
        if (currentUser.company_info.company_type === CompanyType.provider) {
          return new ObjectPermissionResponse(true);
        }
        return new ObjectPermissionResponse(false);
      })
    );
  }

  canEdit(floorplan: FloorPlan | number): Observable<ObjectPermissionResponse> {
    let floorplan$: Observable<FloorPlan>;
    if (typeof floorplan === 'number') {
      floorplan$ = this.floorplanService.retrieve(floorplan, {
        ignoreStore: true,
      });
    } else {
      floorplan$ = of(floorplan);
    }
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: floorplan$,
    }).pipe(
      map(({ currentUser, entity }) => {
        if (currentUser.is_superuser) {
          return new ObjectPermissionResponse(true);
        }

        if (entity.is_restricted == 1) {
          return new ObjectPermissionResponse(false, 'Floorplan is restricted');
        }

        if (currentUser.company === entity.owner) {
          return new ObjectPermissionResponse(true);
        }

        return new ObjectPermissionResponse(
          false,
          'You do not have permissions to edit this Floorplan'
        );
      })
    );
  }

  canDelete(
    floorplan: FloorPlan | number
  ): Observable<ObjectPermissionResponse> {
    let floorplan$: Observable<FloorPlan>;
    if (typeof floorplan === 'number') {
      floorplan$ = this.floorplanService.retrieve(floorplan, {
        ignoreStore: true,
      });
    } else {
      floorplan$ = of(floorplan);
    }
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: floorplan$,
    }).pipe(
      map(({ currentUser, entity }) => {
        if (!currentUser.is_superuser) {
          return new ObjectPermissionResponse(
            false,
            'Only AXIS Administrators can delete Floorplans'
          );
        }
        if (entity?.homes_count > 0) {
          return new ObjectPermissionResponse(
            false,
            'Floorplan is bounded to a home'
          );
        }
        return new ObjectPermissionResponse(true);
      })
    );
  }
}
