import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ModelGraphService } from '../../services/model-graph.service';
import { selectSimulation } from '../../state/simulation/selectors';
import { StateModelName } from '../../state.registry';
import { selectInitialLoading } from '@/state/selectors/annotation/annotation.selector';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
})
export class NotesComponent implements OnInit {
  @Input() forSimulation;
  componentDestroyed$ = new Subject();
  modelNameToModelIds = [];
  entityIds: string[] = [];
  loading = true;
  constructor(
    public store: Store,
    public modelGraphService: ModelGraphService
  ) {}

  ngOnInit(): void {
    this.store
      .select(selectInitialLoading())
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(loading => {
        this.loading = loading;
      });

    this.store
      .select(selectSimulation())
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(simulation => {
        if (!simulation) return;

        this.modelNameToModelIds = [
          [StateModelName.simulation, [simulation.id]],
          [StateModelName.aboveGradeWall, simulation.above_grade_walls],
          [StateModelName.foundationWall, simulation.foundation_walls],
          [StateModelName.roof, simulation.roofs],
          [StateModelName.frameFloor, simulation.frame_floors],
          [StateModelName.slab, simulation.slabs],
          [StateModelName.rimJoist, simulation.rim_joists],
          [StateModelName.infiltration, [simulation.infiltration]],

          [StateModelName.door, simulation.doors],
          [StateModelName.window, simulation.windows],
          [StateModelName.skylight, simulation.skylights],

          [StateModelName.mechanicalEquipment, simulation.mechanical_equipment],

          [
            StateModelName.distributionSystem,
            simulation.hvac_distribution_systems,
          ],

          [StateModelName.appliances, [simulation.appliances]],
          [StateModelName.lights, [simulation.lights]],

          [StateModelName.waterSystem, [simulation.water_system]],

          [StateModelName.photovoltaic, simulation.photovoltaics],

          [StateModelName.thermostat, simulation.thermostats],

          [
            StateModelName.mechanicalVentilation,
            simulation.mechanical_ventilation_systems,
          ],

          [StateModelName.naturalVentilation, [simulation.natural_ventilation]],
        ];
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
