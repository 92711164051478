<ng-container *ngIf="product">
  <ng-container [ngSwitch]="displayType">
    <ng-container *ngSwitchCase="ProductDisplayComponentType.onlyName">
      {{ product?.title }}
    </ng-container>
    <ng-container *ngSwitchCase="ProductDisplayComponentType.onlyNameAsLink">
      <a
        [routerLink]="[
        '/',
        'product',
        'detail',
        product?.id
      ]" *ngIf="product.product_type === ProductType.default"
      >
        {{ product?.title }}
      </a>
      <a
        [routerLink]="[
        '/',
        'hi',
        'product',
        'detail',
        product?.id
      ]" *ngIf="product.product_type === ProductType.hirlProduct"
      >
        {{ product?.title }}
      </a>
    </ng-container>
  </ng-container>
</ng-container>
