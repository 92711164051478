import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { first, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getInfoUser } from '@/state/selectors/info.selector';
import { AppState } from '@/state/reducers';
import { CommunityService } from '@/data/community/services/community.service';
import { Community } from '@/data/community/models';

@Injectable({
  providedIn: 'root',
})
export class CommunityPermissionService {
  constructor(
    public store: Store<AppState>,
    public communityService: CommunityService
  ) {}

  canCreate(): Observable<ObjectPermissionResponse> {
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
    }).pipe(
      map(({ currentUser }) => {
        if (!currentUser.is_company_admin) {
          return new ObjectPermissionResponse(
            false,
            'Only Company Admin users can Create new communities'
          );
        }
        return new ObjectPermissionResponse(true);
      })
    );
  }

  canEdit(community: Community | number): Observable<ObjectPermissionResponse> {
    let community$: Observable<Community>;
    if (typeof community === 'number') {
      community$ = this.communityService.retrieve(community, {
        ignoreStore: true,
      });
    } else {
      community$ = of(community);
    }
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: community$,
    }).pipe(
      map(({ currentUser }) => {
        if (currentUser.is_superuser) {
          return new ObjectPermissionResponse(true);
        }
        return new ObjectPermissionResponse(
          false,
          'You do not have permissions to edit this Community'
        );
      })
    );
  }

  canDelete(
    community: Community | number
  ): Observable<ObjectPermissionResponse> {
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
    }).pipe(
      map(({ currentUser }) => {
        if (!currentUser.is_superuser) {
          return new ObjectPermissionResponse(
            false,
            'Only AXIS Admin can delete this Community'
          );
        }
        return new ObjectPermissionResponse(true);
      })
    );
  }
  canRemoveAssociation(
    community: Community | number
  ): Observable<ObjectPermissionResponse> {
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
    }).pipe(
      map(({ currentUser }) => {
        if (!currentUser.is_company_admin) {
          return new ObjectPermissionResponse(
            false,
            'Only Company Admin can remove Association'
          );
        }
        return new ObjectPermissionResponse(true);
      })
    );
  }
}
