<div class="row position-relative" appFocusTarget entityName="project" [entityId]="entityId">
    <app-loading-overlay *ngIf="loading"></app-loading-overlay>
    <div class="col-12">
        <app-form formName="projectForm" [formGroup]="form" (save)="onSave($event)">
            <div>
                <app-foreign-model-field [name]="builderName"
                    (add)="editCompany('builder', 'builder')"
                    (replace)="editCompany('builder', 'builder')"
                    (remove)="removeCompany('builder')"
                    [options]="['add', 'replace', 'remove']"
                    [errors]="form?.controls.builder.errors"
                    label="Builder">
                </app-foreign-model-field>
                <app-select formControlName="building_status" [label]="fieldLabels.building_status" [items]="buildingStatusLabels"></app-select>
            </div>
            <div>
                <app-foreign-model-field [name]="raterOrgnizationName"
                    (add)="editCompany('rater_organization', 'rater')"
                    (replace)="editCompany('rater_organization', 'rater')"
                    (remove)="removeRaterOrgnisation()"
                    [options]="['add', 'replace', 'remove']"
                    [errors]="form?.controls.rater_organization.errors"
                    label="Rater Organization">
                </app-foreign-model-field>
                <app-foreign-model-field
                    *ngIf="entity.rater_organization"
                    [name]="raterOfRecordName"
                    (add)="replaceRaterUser('rater_of_record')"
                    (replace)="replaceRaterUser('rater_of_record')"
                    (remove)="removeRaterUser('rater_of_record')"
                    [options]="['add', 'replace', 'remove']"
                    [errors]="form?.controls.rater_of_record.errors"
                    label="Rater of record">
                </app-foreign-model-field>
            </div>
            <div>
                <app-foreign-model-field [name]="providerOrganizationName"
                    (add)="editCompany('provider_organization', 'provider')"
                    (replace)="editCompany('provider_organization', 'provider')"
                    (remove)="removeCompany('provider_organization')"
                    [options]="['add', 'replace', 'remove']"
                    [errors]="form?.controls.provider_organization.errors"
                    [label]="fieldLabels.provider_organization">
                </app-foreign-model-field>
                <app-select formControlName="construction_year" [label]="fieldLabels.construction_year" [items]="yearChoices" order="desc"></app-select>

            </div>
            <div>
                <app-input formControlName="model_name" [label]="fieldLabels.model_name"></app-input>
                <app-input formControlName="permit_identification" [label]="fieldLabels.permit_identification"></app-input>
            </div>
            <div>
                <app-date formControlName="permit_date" [label]="fieldLabels.permit_date"></app-date>
                <app-date formControlName="final_inspection_date" [label]="fieldLabels.final_inspection_date"></app-date>

            </div>
            <div>
                <app-select formControlName="rating_type" [label]="fieldLabels.rating_type" [items]="ratingTypeLabels"></app-select>
                <app-input formControlName="sampleset_identification" [label]="fieldLabels.sampleset_identification"></app-input>
            </div>
        </app-form>

        <ul>
            <li *ngFor="let key of [
                'resnet_registry_identification',
                'resnet_registry_datetime',
                'eto_reference_home_type']">
                <strong>{{fieldLabels[key]}}:</strong>&nbsp;{{entity[key] || 'N/A'}}
            </li>
        </ul>
    </div>
</div>
