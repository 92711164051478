<h2 mat-dialog-title>
    <ng-container *ngIf="!isEditForm">
        Create New <b>Company Access</b>
    </ng-container>
    <ng-container *ngIf="isEditForm">
        Edit <b>Company Access</b> for <b>{{ entity.user_info.first_name }} {{entity.user_info.last_name }}</b>
    </ng-container>
</h2>
<mat-dialog-content>
    <form [formGroup]="form" class="mb-2">
        <div class="row">
            <div class="col-12">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>With Roles</mat-label>
                    <app-generic-model-chips modelClassName="company_role" formControlName="roles" [initialValueIds]="entity?.roles"></app-generic-model-chips>
                </mat-form-field>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-button color="accent" mat-raised-button (click)="save($event)">Save</button>
    <button mat-button [mat-dialog-close]="null" color="primary" mat-raised-button>Cancel</button>
</mat-dialog-actions>
