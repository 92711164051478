import { Directive, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectAppliancesViewSet } from '../../state/appliances/selectors';
import { FormGroup } from '@angular/forms';
import {
  AppliancesBackendDict,
  AppliancesLocationLabels,
} from '@/data/simulation/models/Appliances';
import { updateAppliances } from '../../state/appliances/actions';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Directive({
  selector: '[appBaseAppliances]',
})
export class BaseAppliancesDirective {
  @Input() entityId: number;
  locationLabels = AppliancesLocationLabels;
  componentDestroyed$ = new Subject();

  form: FormGroup;
  entity: AppliancesBackendDict;
  createForm: (data: AppliancesBackendDict) => FormGroup;
  loading: boolean;

  constructor(public store: Store) {}

  updateForm() {}

  ngOnInit(): void {
    this.store
      .select(selectAppliancesViewSet(this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(({ data, loading }) => {
        this.entity = data;
        if (loading) return;

        this.loading = false;

        if (this.form) {
          this.form.patchValue(data);
          return;
        }
        this.form = this.createForm(data);

        this.updateForm();
      });
  }

  onSave(appliancesChanges: Partial<AppliancesBackendDict>) {
    this.loading = true;
    this.store.dispatch(
      updateAppliances({ id: this.entityId, appliancesChanges })
    );
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
