import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import * as MechanicalEquipmentActions from './actions';
import * as AirConditionerActions from '../air-conditioner/actions';
import * as HeaterActions from '../heater/actions';
import * as WaterHeaterActions from '../water-heater/actions';
import * as AirSourceHeatPumpActions from '../air-source-heat-pump/actions';
import * as GroundSourceHeatPumpActions from '../ground-source-heat-pump/actions';
import * as DehumidifierActions from '../dehumidifier/actions';
import * as SimulationActions from '../simulation/actions';
import * as SharedActions from '../shared/shared.actions';
import { MechanicalEquipmentBackendDict } from '@/data/simulation/models/mechanicals/MechanicalEquipment';
import { ModelGraphService } from '../../services/model-graph.service';
import { MechanicalEquipmentValidator } from '../../validators/mechanical-equipment.validator';
import { HeaterBackendDict } from '@/data/simulation/models/mechanicals/Heater';
import { AirConditionerBackendDict } from '@/data/simulation/models/mechanicals/AirConditioner';
import { WaterHeaterBackendDict } from '@/data/simulation/models/mechanicals/WaterHeater';
import { MechanicalEquipmentService } from '@/data/simulation/services/mechanical-equipment.service';
import { AirSourceHeatPumpBackendDict } from '@/data/simulation/models/mechanicals/AirSourceHeatPump';
import { GroundSourceHeatPumpBackendDict } from '@/data/simulation/models/mechanicals/GroundSourceHeatPump';
import { DehumidifierBackendDict } from '@/data/simulation/models/mechanicals/Dehumidifier';
import { StateModelName } from '../../state.registry';

@Injectable()
export class MechanicalEquipmentEffects {
  loadDetailedMechanicalEquipments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MechanicalEquipmentActions.loadDetailedMechanicalEquipment),
      mergeMap(action => {
        const mechanicalEquipments: MechanicalEquipmentBackendDict[] = [];
        const airConditioners: AirConditionerBackendDict[] = [];
        const heaters: HeaterBackendDict[] = [];
        const waterHeaters: WaterHeaterBackendDict[] = [];
        const airSourceHeatPump: AirSourceHeatPumpBackendDict[] = [];
        const groundSourceHeatPump: GroundSourceHeatPumpBackendDict[] = [];
        const dehumidifiers: DehumidifierBackendDict[] = [];

        action.detailedMechanicalEquipment.forEach(detailedEquipment => {
          const {
            water_heater_info: water_heater,
            heater_info: heater,
            air_conditioner_info: air_conditioner,
            air_source_heat_pump_info: air_source_heat_pump,
            ground_source_heat_pump_info: ground_source_heat_pump,
            dehumidifier_info: dehumidifier,
            ...equipment
          } = detailedEquipment;

          if (air_conditioner) {
            this.modelGraphService.attachModel(
              'mechanicalEquipment',
              detailedEquipment.id,
              'airConditioner',
              [detailedEquipment.air_conditioner]
            );
            airConditioners.push(air_conditioner);
          } else if (heater) {
            this.modelGraphService.attachModel(
              'mechanicalEquipment',
              detailedEquipment.id,
              'heater',
              [detailedEquipment.heater]
            );
            heaters.push(heater);
          } else if (water_heater) {
            this.modelGraphService.attachModel(
              'mechanicalEquipment',
              detailedEquipment.id,
              'waterHeater',
              [detailedEquipment.water_heater]
            );
            waterHeaters.push(water_heater);
          } else if (air_source_heat_pump) {
            this.modelGraphService.attachModel(
              'mechanicalEquipment',
              detailedEquipment.id,
              'airSourceHeatPump',
              [detailedEquipment.air_source_heat_pump]
            );
            airSourceHeatPump.push(air_source_heat_pump);
          } else if (ground_source_heat_pump) {
            this.modelGraphService.attachModel(
              'mechanicalEquipment',
              detailedEquipment.id,
              'groundSourceHeatPump',
              [detailedEquipment.ground_source_heat_pump]
            );
            groundSourceHeatPump.push(ground_source_heat_pump);
          } else if (dehumidifier) {
            this.modelGraphService.attachModel(
              'mechanicalEquipment',
              detailedEquipment.id,
              'dehumidifier',
              [detailedEquipment.dehumidifier]
            );
            dehumidifiers.push(dehumidifier);
          }

          mechanicalEquipments.push(equipment);
        });

        const errors = MechanicalEquipmentValidator.validate(
          action.detailedMechanicalEquipment
        );

        return of(
          AirConditionerActions.loadAirConditioners({
            airConditioners: airConditioners,
          }),
          HeaterActions.loadHeaters({
            heaters: heaters,
          }),
          WaterHeaterActions.loadWaterHeaters({
            waterHeaters: waterHeaters,
          }),
          AirSourceHeatPumpActions.loadAirSourceHeatPumps({
            airSourceHeatPumps: airSourceHeatPump,
          }),
          GroundSourceHeatPumpActions.loadGroundSourceHeatPumps({
            groundSourceHeatPumps: groundSourceHeatPump,
          }),
          DehumidifierActions.loadDehumidifiers({
            dehumidifiers: dehumidifiers,
          }),
          MechanicalEquipmentActions.loadMechanicalEquipmentsSuccess({
            mechanicalEquipments: mechanicalEquipments,
            errors: errors,
          })
        );
      })
    );
  });

  updateMechanicalEquipment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MechanicalEquipmentActions.updateMechanicalEquipment),
      mergeMap(action =>
        this.mechanicalEquipmentService
          .update(action.id, action.mechanicalEquipmentChanges)
          .pipe(
            mergeMap(updatedmechanicalEquipment => {
              const errors = MechanicalEquipmentValidator.validate([
                updatedmechanicalEquipment,
              ]);
              return of(
                MechanicalEquipmentActions.loadMechanicalEquipmentsSuccess({
                  mechanicalEquipments: [updatedmechanicalEquipment],
                  errors,
                }),
                SharedActions.updateCrossModelErrors({
                  modelName: StateModelName.mechanicalEquipment,
                  changedFields: Object.keys(action.mechanicalEquipmentChanges),
                })
              );
            }),
            catchError(error =>
              of(
                MechanicalEquipmentActions.updateMechanicalEquipmentFailure({
                  id: action.id,
                }),
                SharedActions.reportAPIFailure({ error })
              )
            )
          )
      )
    );
  });

  removeMechanicalEquipment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MechanicalEquipmentActions.removeMechanicalEquipment),
      mergeMap(action =>
        this.mechanicalEquipmentService
          .remove(action.mechanicalEquipment.id)
          .pipe(
            mergeMap(() =>
              of(
                MechanicalEquipmentActions.removeMechanicalEquipmentSuccess({
                  id: action.mechanicalEquipment.id,
                }),
                SimulationActions.removeItemFromList({
                  fieldName: 'mechanical_equipment',
                  id: action.mechanicalEquipment.id,
                })
              )
            ),
            catchError(error =>
              of(
                MechanicalEquipmentActions.removeMechanicalEquipmentFailure({
                  id: action.mechanicalEquipment.id,
                }),
                SharedActions.reportAPIFailure({ error })
              )
            )
          )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private modelGraphService: ModelGraphService,
    private mechanicalEquipmentService: MechanicalEquipmentService
  ) {}
}
