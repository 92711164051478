<div class="container-fluid" *ngIf="initialized">
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-6">
          <h4>
            <span *ngIf="!isLoading">{{ count | number }}</span>
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" *ngIf="isLoading"></fa-icon>
            Inspection Grades
          </h4>
        </div>
        <div class="col-6 text-right">
        </div>
      </div>

      <div class="row" *ngIf="!aggregateByLetterGradeIsLoading else letterGradeLoading">
        <div class="col-6">
          <span>Totals: </span>
          <span class="badge badge-secondary mr-2">A: {{ inspectionGradeAggregateByLetterGrade.a_grade }}</span>
          <span class="badge badge-secondary mr-2">B: {{ inspectionGradeAggregateByLetterGrade.b_grade }}</span>
          <span class="badge badge-secondary mr-2">C: {{ inspectionGradeAggregateByLetterGrade.c_grade }}</span>
          <span class="badge badge-secondary mr-2">D: {{ inspectionGradeAggregateByLetterGrade.d_grade }}</span>
          <span class="badge badge-secondary mr-2">F: {{ inspectionGradeAggregateByLetterGrade.f_grade }}</span>
          <span>Average: </span>
          <span class="badge badge-secondary mr-2"> {{ averageGrade || '-' }}</span>
        </div>
      </div>
      <ng-template #letterGradeLoading>
        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-center">
              <mat-spinner [diameter]="20"></mat-spinner>
            </div>
          </div>
        </div>
      </ng-template>

      <div class="row">
        <div class="col-12">
          <hr>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <app-table-filters [formGroup]="filterFormGroup" [params]="this.storedParams"
                             [overwritePropertyValues]="overwritePropertyValues"
                             [overwritePropertyLabels]="overwritePropertyLabels"
                             [excludedProperties]="excludedProperties">
            <mat-accordion multi>
              <mat-expansion-panel [expanded]="true">
                <div class="row">
                  <div class="col-12">
                    <mat-form-field class="w-100">
                      <mat-label>Search</mat-label>
                      <app-search-input formControlName="search"
                                        placeholder="Type to search something"></app-search-input>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <mat-form-field class="w-100 mb-2">
                      <mat-label>Program</mat-label>
                      <app-generic-model-chips
                        placeholder="Filter by Program..."
                        formControlName="qa_status__home_status__eep_program_info"
                        modelClassName="eep_program" [panelWidth]="640"
                        [initialValueIds]="storedParams.qa_status__home_status__eep_program">
                      </app-generic-model-chips>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <mat-form-field class="w-100">
                      <mat-label>Grade</mat-label>
                      <mat-select formControlName="letter_grade">
                        <mat-option [value]="''">
                          Any
                        </mat-option>
                        <mat-option *ngFor="let item of letterGradeLabelMapping | keyvalue " [value]="item.key">
                          {{ item.value }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <mat-form-field class="w-100">
                      <mat-label>User</mat-label>
                      <app-generic-model-autocomplete modelClassName="user" formControlName="user_info"
                                                      [panelWidth]="480"
                                                      [initialValueId]="storedParams.user"></app-generic-model-autocomplete>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <mat-form-field class="w-100">
                      <mat-label>NGBS Reviewer</mat-label>
                      <app-generic-model-autocomplete modelClassName="user" formControlName="approver_info"
                                                      [filterParams]="userRequestParams" [panelWidth]="480"
                                                      [initialValueId]="storedParams.approver"></app-generic-model-autocomplete>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <mat-form-field class="w-100">
                      <mat-label>QA Type</mat-label>
                      <mat-select formControlName="qa_status__requirement__type">
                        <mat-option [value]="''">
                          Any
                        </mat-option>
                        <mat-option *ngFor="let item of qaRequirementTypeChoices | defaultOrderKeyValue"
                                    [value]="item.key">
                          {{ item.value }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <mat-form-field class="w-100">
                      <mat-label>Date Graded Start</mat-label>
                      <input matInput [matDatepicker]="datepicker_date_graded__gte" formControlName="graded_date__gte">
                      <mat-datepicker-toggle matSuffix (click)="filterFormGroup.get('graded_date__gte').setValue(null)">
                        <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                      </mat-datepicker-toggle>
                      <mat-datepicker-toggle matSuffix [for]="datepicker_date_graded__gte"></mat-datepicker-toggle>
                      <mat-datepicker #datepicker_date_graded__gte></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <mat-form-field class="w-100">
                      <mat-label>Date Graded End</mat-label>
                      <input matInput [matDatepicker]="datepicker_date_graded__lte" formControlName="graded_date__lte">
                      <mat-datepicker-toggle matSuffix (click)="filterFormGroup.get('graded_date__lte').setValue(null)">
                        <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                      </mat-datepicker-toggle>
                      <mat-datepicker-toggle matSuffix [for]="datepicker_date_graded__lte"></mat-datepicker-toggle>
                      <mat-datepicker #datepicker_date_graded__lte></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </app-table-filters>
          <div class="row">
            <div class="col-12">
              <button mat-raised-button color="accent" (click)="onCreateReport($event)" class="w-100">
                Create report
              </button>
            </div>
          </div>
          <div class="row mt-2" *ngIf="canPerformCustomerHIRLInspectionGradeQuarterReport.hasPermission">
            <div class="col-12">
              <button mat-raised-button color="accent" (click)="onCreateVerifierQuarterReport($event)" class="w-100">
                Email Verifier Report Cards
              </button>
            </div>
          </div>
        </div>
        <div class="col-9">
          <div class="row" *ngIf="isLoading">
            <div class="col-12">
              <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
            </div>
          </div>
          <table mat-table
                 [dataSource]="entities"
                 matSort
                 [matSortActive]="storedParams.toTableSort().active"
                 [matSortDirection]="storedParams.toTableSort().direction"
                 (matSortChange)="onSortChange($event)"
                 class="w-100"
          >

            <ng-container matColumnDef="user__full_name">
              <th mat-header-cell *matHeaderCellDef>Name</th>
              <td mat-cell *matCellDef="let row">
                <a [routerLink]="['/', 'profile', row.user]">
                  {{ row.user_fullname }}
                </a>
              </td>
            </ng-container>

            <ng-container matColumnDef="company__name">
              <th mat-header-cell *matHeaderCellDef>Company</th>
              <td mat-cell *matCellDef="let row">
                <a [routerLink]="['/', 'company', row.user_company_type, row.user_company]">
                  {{ row.user_company_name }}
                </a>
              </td>
            </ng-container>

            <ng-container matColumnDef="graded_date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Grading</th>
              <td mat-cell *matCellDef="let row">
                {{ row.graded_date | date: 'MM/dd/yyyy' }}
              </td>
            </ng-container>

            <ng-container matColumnDef="grade">
              <th mat-header-cell *matHeaderCellDef>Grade</th>
              <td mat-cell *matCellDef="let row">
                <app-inspection-grade-display [inspectionGrade]="row"></app-inspection-grade-display>
              </td>
            </ng-container>

            <ng-container matColumnDef="home_address">
              <th mat-header-cell *matHeaderCellDef>Address</th>
              <td mat-cell *matCellDef="let row">
                <a [routerLink]="['/', 'home', row.home_id]" *ngIf="row.home_id">
                  {{ row.home_address }}
                </a>
              </td>
            </ng-container>

            <ng-container matColumnDef="qa_type">
              <th mat-header-cell *matHeaderCellDef>QA Type</th>
              <td mat-cell *matCellDef="let row">
                {{ qaRequirementTypeLabelMapping[row.qa_type] }}
              </td>
            </ng-container>

            <ng-container matColumnDef="eep_program_name">
              <th mat-header-cell *matHeaderCellDef>Program</th>
              <td mat-cell *matCellDef="let row">
                {{ row.eep_program_name }}
              </td>
            </ng-container>

            <ng-container matColumnDef="notes">
              <th mat-header-cell *matHeaderCellDef>Reviewer Notes</th>
              <td mat-cell *matCellDef="let row">
                {{ row.notes }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>

          <div class="d-flex flex-row">
            <div class="d-flex flex-fill justify-content-end">
              <mat-paginator [length]="count"
                             [pageIndex]="$any(storedParams.page) - 1"
                             [pageSize]="25"
                             (page)="onPaginateChange($event)"
                             aria-label="Select page">
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
