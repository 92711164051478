import { createSelector } from '@ngrx/store';
import { AppState } from '@/state/reducers';

export const selectAnnotationState = (state: AppState) => state.annotation;

export const selectAnnotations = () =>
  createSelector(selectAnnotationState, state => state.entities);

export const selectAnnotation = (entityName: string, entityId: number) =>
  createSelector(
    selectAnnotationState,
    state => state.entities[entityName + entityId]
  );

const selectAnnotationLoading = (entityName, entityId) =>
  createSelector(
    selectAnnotationState,
    state => state.loading[entityName + entityId]
  );

export const selectAnnotationViewSet = (entityName: string, entityId: number) =>
  createSelector(
    selectAnnotation(entityName, entityId),
    selectAnnotationLoading(entityName, entityId),
    (data, loading) => ({
      data,
      loading,
    })
  );

export const selectInitialLoading = () =>
  createSelector(selectAnnotationState, state => state.initialLoading);
