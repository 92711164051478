import { createReducer, on } from '@ngrx/store';
import * as NaturalVentilationActions from './actions';
import { NaturalVentilationBackendDict } from '@/data/simulation/models/systems/NaturalVentilation';
import { BaseState, sharedInitialState } from '../shared/base.state';
import { addErrorCount, addStateErrorCount } from '../helper';

export type NaturalVentilationState = BaseState<NaturalVentilationBackendDict>;

export const initialState: NaturalVentilationState = sharedInitialState;

export const reducer = createReducer(
  initialState,
  on(
    NaturalVentilationActions.loadNaturalVentilationsSuccess,
    (currentState, payload) => {
      const entities = payload.naturalVentilations.reduce(
        (acc, naturalventilation) => ({
          ...acc,
          [naturalventilation.id]: naturalventilation,
        }),
        {}
      );

      const loadingStatus = payload.naturalVentilations.reduce(
        (acc, naturalventilation) => ({
          ...acc,
          [naturalventilation.id]: false,
        }),
        {}
      );

      return {
        entities: { ...currentState.entities, ...entities },
        loading: { ...currentState.loading, ...loadingStatus },
        errors: {
          ...currentState.errors,
          ...addStateErrorCount(payload.errors),
        },
      };
    }
  ),

  on(NaturalVentilationActions.updateNaturalVentilation, (state, { id }) => ({
    ...state,
    loading: {
      ...state.loading,
      [id]: true,
    },
  })),

  on(
    NaturalVentilationActions.updateNaturalVentilationFailure,
    (state, { id }) => {
      return {
        ...state,
        loading: {
          ...state.loading,
          [id]: false,
        },
      };
    }
  ),

  on(
    NaturalVentilationActions.updateNaturalVentilationSuccess,
    (state, { naturalVentilation, errors }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          [naturalVentilation.id]: naturalVentilation,
        },
        loading: {
          ...state.loading,
          [naturalVentilation.id]: false,
        },
        errors: {
          ...state.errors,
          [naturalVentilation.id]: addErrorCount(errors),
        },
      };
    }
  )
);
