import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from '@/core/interceptors/jwt.interceptor';
import { ErrorInterceptor } from '@/core/interceptors/error.interceptor';
import { throwIfAlreadyLoaded } from '@/core/guards/module-import.guard';
import { AuthCookie } from '@/core/services/auth-cookies-handler';
import { AuthGuard } from '@/core/guards/auth.guard';
import { LegacyRedirectGuard } from '@/core/guards/redirector.guard';
import { GlobalErrorHandlerService } from '@/core/services/global-error-handler.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    AuthCookie,
    AuthGuard,
    LegacyRedirectGuard,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
