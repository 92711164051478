<div class="container-fluid" *ngIf="initialized">
  <div class="row">
    <div class="col-12">
      <div class="row mb-2">
        <div class="col-6">
          <h3>
            <span *ngIf="!entitiesIsLoading">{{ entitiesCount | number }}</span>
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" *ngIf="entitiesIsLoading"></fa-icon>
            {{ companyDisplayNamePlural }}
          </h3>
        </div>
        <div class="col-6 text-right">
          <app-permission-mat-button [permissionResponse]="createPermissionResponse" (click)="create($event);">
            Create New
          </app-permission-mat-button>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <hr>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <div class="row">
            <div class="col-12">
              <app-table-filters [formGroup]="filterFromGroup" [params]="this.storedParams"
                                 [overwritePropertyValues]="overwritePropertyValues"
                                 [overwritePropertyLabels]="overwritePropertyLabels"
                                 [excludedProperties]="excludedProperties">
                  <mat-accordion multi>
                    <mat-expansion-panel [expanded]="true">
                      <div class="row">
                        <div class="col-12">
                          <mat-form-field class="w-100">
                            <mat-label>Search</mat-label>
                            <app-search-input formControlName="search"
                                              placeholder="Type to search something"></app-search-input>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <mat-form-field class="w-100">
                            <mat-label>Country</mat-label>
                            <app-generic-model-chips placeholder="Add country..." modelClassName="country"
                                                     formControlName="city__country_info" [pageSize]="300"
                                                     [initialValueIds]="storedParams.city__country"></app-generic-model-chips>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <mat-form-field class="w-100">
                            <mat-label>US State</mat-label>
                            <app-generic-mat-select modelClassName="us_state" formControlName="city__county__state_info"
                                                    [pageSize]="300" multiple="true"
                                                    parentKey="abbr"></app-generic-mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row" *ngIf="showGCPCertificateExpirationDateColumn">
                        <div class="col-12">
                          <mat-form-field class="w-100">
                            <mat-label>Has Active Products</mat-label>
                            <mat-select formControlName="customer_hirl_has_active_products">
                              <mat-option [value]="''">Any</mat-option>
                              <mat-option [value]="'true'">Yes</mat-option>
                              <mat-option [value]="'false'">No</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
              </app-table-filters>
            </div>
          </div>
        </div>
        <div class="col-9">
          <div class="row">
            <div class="col-12">
              <ng-container *ngIf="selectAllEntities">Selected <b>{{ entitiesCount }}</b> Product Groups</ng-container>
              <ng-container *ngIf="!selectAllEntities && selectedEntities?.length">Selected <b>{{ selectedEntities.length }}</b> Product Groups</ng-container>
            </div>
          </div>
          <div class="row" *ngIf="entitiesIsLoading">
            <div class="col-12">
              <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
            </div>
          </div>
          <table mat-table
                 [dataSource]="entities"
                 matSort
                 [matSortActive]="storedParams.toTableSort().active"
                 [matSortDirection]="storedParams.toTableSort().direction"
                 (matSortChange)="onSort($event)"
                 class="w-100"
          >
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
              <td mat-cell *matCellDef="let row">
                <app-company-display displayType="onlyNameAsLink" [company]="row"></app-company-display>
              </td>
            </ng-container>

            <ng-container matColumnDef="street_line1">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Address</th>
              <td mat-cell *matCellDef="let row">
                {{ row.address }}
              </td>
            </ng-container>

            <ng-container matColumnDef="state">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>State</th>
              <td mat-cell *matCellDef="let row">
                {{ row.state }}
              </td>
            </ng-container>

            <ng-container matColumnDef="zipcode">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Zip Code</th>
              <td mat-cell *matCellDef="let row">
                {{ row.zipcode }}
              </td>
            </ng-container>


            <ng-container matColumnDef="office_phone">
              <th mat-header-cell *matHeaderCellDef>Phone</th>
              <td mat-cell *matCellDef="let row">
                {{ row.office_phone }}
              </td>
            </ng-container>

            <ng-container matColumnDef="eto_account">
              <th mat-header-cell *matHeaderCellDef>ETO Account</th>
              <td mat-cell *matCellDef="let row">
                {{ row.eto_account }}
              </td>
            </ng-container>

            <ng-container matColumnDef="gcp_certificate_expiration_date">
              <th mat-header-cell *matHeaderCellDef>Active Products</th>
              <td mat-cell *matCellDef="let row">
                {{ row.hasActiveProducts }}
              </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>Actions</th>
              <td mat-cell *matCellDef="let row">
                <a href="#" [matTooltip]="'Remove Association for company '+ row.name" (click)="onRemoveAssociation($event, row);">
                  <fa-icon [icon]="['fas', 'unlink']"></fa-icon>
                </a>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <div class="d-flex flex-row">
            <div class="align-self-center">
                <button mat-raised-button (click)="exportToCSV($event)" [disabled]="entitiesIsLoading" class="mr-2" matTooltip="Export Data to CSV file">CSV</button>
                <button mat-raised-button (click)="exportToExcel($event)" [disabled]="entitiesIsLoading" matTooltip="Export Data to Excel file">Excel</button>
            </div>
            <div class="d-flex flex-fill justify-content-end">
              <mat-paginator [length]="entitiesCount"
                             [pageIndex]="$any(storedParams.page) - 1"
                             [pageSize]="storedParams.page_size"
                             (page)="onPaginateChange($event)"
                             aria-label="Select page">
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
