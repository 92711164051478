
<div class="row mt-2">
    <app-qa-flags [flags]="qaFlags" (flagChange)="handleQAFlagChange($event)"></app-qa-flags>
    <div class="col-12">
        <!-- General info -->
        <app-model-errors-tree [entityId]="simulation.id" entityName="simulation"></app-model-errors-tree>
        <app-project-errors [entityId]="simulation.project"></app-project-errors>
        <app-location-errors [entityId]="simulation.location"></app-location-errors>
        <ng-container *ngFor="let utilityRateId of simulation.utility_rates">
            <app-utility-rate-errors [entityId]="utilityRateId"></app-utility-rate-errors>
        </ng-container>

        <!-- Envelope errors -->
        <ng-container *ngFor="let wallId of simulation.above_grade_walls">
            <app-above-grade-wall-errors [entityId]="wallId"></app-above-grade-wall-errors>
        </ng-container>

        <ng-container *ngFor="let foundationWallId of simulation.foundation_walls">
            <app-foundation-wall-errors [entityId]="foundationWallId"></app-foundation-wall-errors>
        </ng-container>

        <ng-container *ngFor="let roofId of simulation.roofs">
            <app-roof-errors [entityId]="roofId"></app-roof-errors>
        </ng-container>

        <ng-container *ngFor="let floorId of simulation.frame_floors">
            <app-frame-floor-errors [entityId]="floorId"></app-frame-floor-errors>
        </ng-container>

        <ng-container *ngFor="let slabId of simulation.slabs">
            <app-slab-errors [entityId]="slabId"></app-slab-errors>
        </ng-container>

        <ng-container *ngFor="let rimJoistId of simulation.rim_joists">
            <app-rim-joist-errors [entityId]="rimJoistId"></app-rim-joist-errors>
        </ng-container>

        <app-infiltration-errors [entityId]="simulation.infiltration"></app-infiltration-errors>

        <!-- Fenestration errors -->
        <ng-container *ngFor="let doorId of simulation.doors">
            <app-door-errors [entityId]="doorId"></app-door-errors>
        </ng-container>

        <ng-container *ngFor="let windowId of simulation.windows">
            <app-window-errors [entityId]="windowId"></app-window-errors>
        </ng-container>

        <ng-container *ngFor="let skylightId of simulation.skylights">
            <app-skylight-errors [entityId]="skylightId"></app-skylight-errors>
        </ng-container>

        <!-- Mechanical errors -->
        <ng-container *ngFor="let mechnicalEquipmentId of simulation.mechanical_equipment">
            <app-mechanical-equipment-errors [entityId]="mechnicalEquipmentId"></app-mechanical-equipment-errors>
        </ng-container>

        <ng-container *ngFor="let distributionSystemId of simulation.hvac_distribution_systems">
            <app-distribution-system-errors [entityId]="distributionSystemId"></app-distribution-system-errors>
        </ng-container>

        <ng-container *ngFor="let thermostatId of simulation.thermostats">
            <app-thermostat-errors [entityId]="thermostatId"></app-thermostat-errors>
        </ng-container>

        <!-- Appliances errors -->
        <app-lights-errors [entityId]="simulation.lights"></app-lights-errors>
        <app-appliances-errors [entityId]="simulation.appliances"></app-appliances-errors>

        <!-- Systems errors -->
        <ng-container *ngFor="let mechanicalVentilationId of simulation.mechanical_ventilation_systems">
            <app-mechanical-ventilation-errors [entityId]="mechanicalVentilationId"></app-mechanical-ventilation-errors>
        </ng-container>
        <app-natural-ventilation-errors [entityId]="simulation.natural_ventilation"></app-natural-ventilation-errors>
        <app-water-system-errors [entityId]="simulation.water_system"></app-water-system-errors>

        <!-- Generator errors -->
        <ng-container *ngFor="let photovoltaicId of simulation.photovoltaics">
            <app-photovoltaic-errors [entityId]="photovoltaicId"></app-photovoltaic-errors>
        </ng-container>
    </div>
</div>
