import {
  InfiltrationBackendDict,
  createInfiltrationForm,
} from '@/data/simulation/models/enclosure/Infiltration';
import { BaseValidator } from './base.validator';
import { ModelErrors, StateErrors } from '../state/shared/base.state';

export class InfiltrationValidator extends BaseValidator {
  static validateForm(
    infiltration: InfiltrationBackendDict
  ): ModelErrors<InfiltrationBackendDict> {
    const infiltrationForm = createInfiltrationForm(infiltration);
    const errors = {};
    Object.keys(infiltration).forEach(key => {
      if (!infiltrationForm.get(key)) return;
      errors[key] = infiltrationForm.get(key).errors;
    });
    return {
      field: errors,
      model: infiltrationForm.errors || {},
    };
  }

  static validate(
    infiltration: InfiltrationBackendDict
  ): StateErrors<InfiltrationBackendDict> {
    const errors = {};
    errors[infiltration.id] = this.validateForm(infiltration);
    return errors;
  }
}
