import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  HIRLGreenEnergyBadge,
  HIRLProject,
  HIRLProjectBatchSubmission,
  HIRLProjectAddressIsUniqueRequestData,
  HIRLProjectCommercialSpaceType,
  HIRLProjectRegistrationState,
  HIRLProjectRegistrationType,
  HIRLProjectRegistrationAppeals,
  ProjectCycleTimeMetrics,
} from '@/data/customer-hirl/models';
import { ServerResponse } from '@/core/schemes/server-response';
import { environment } from '../../../../environments/environment';
import {
  ListRequestParams,
  RequestParams,
} from '@/core/schemes/request-params';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import { AsynchronousProcessedDocument } from '@/data/filehandling/models';
import { tap } from 'rxjs/operators';
import { deleteHIRLProjectRegistrationProject } from '@/state/actions/customer-hirl/hirl-project-registration.actions';

export class HIRLProjectRequestParams extends ListRequestParams {
  registration__registration_user?: number;
  registration__project_type?: HIRLProjectRegistrationType;
  appeals_project?: HIRLProjectRegistrationAppeals;
  registration__state?: HIRLProjectRegistrationState;
  is_accessory_structure?: boolean;
  is_accessory_dwelling_unit?: boolean;
  commercial_space_type?: HIRLProjectCommercialSpaceType;
  registration__is_build_to_rent?: boolean;
  commercial_space_parent?: string;
  created_at__lte?: Date;
  created_at__gte?: Date;
  registration__epp_program__slug?: string | string[];
}

export class BillingRuleExportParams extends RequestParams {
  start_date?: string;
  end_date?: string;
  program_slugs?: string[];
}

export class MilestoneExportParams extends RequestParams {
  start_date?: string;
  end_date?: string;
  program_slugs?: string[];
}

export class CustomerHIRLProjectCycleTimeMetricsRequestParams extends RequestParams {
  years?: string[];
  registration__project_type?: HIRLProjectRegistrationType;
}

@Injectable({
  providedIn: 'root',
})
export class HIRLProjectService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/hirl_projects/`;

  list(params?: HIRLProjectRequestParams) {
    return this.http.get<ServerResponse<HIRLProject>>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }

  batch_submission_list(params?: HIRLProjectRequestParams) {
    return this.http.get<ServerResponse<HIRLProjectBatchSubmission>>(
      `${this.baseUrl}batch_submission_list/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  retrieve(
    projectId: string,
    options?: IDataServiceMethodOptions
  ): Observable<HIRLProject> {
    return this.http.get<HIRLProject>(`${this.baseUrl}${projectId}`);
  }

  create(hirlProject: HIRLProject): Observable<HIRLProject> {
    const payload = Object.assign(new HIRLProject(), hirlProject);

    return this.http.post<HIRLProject>(`${this.baseUrl}`, payload);
  }

  createBulk(
    hirlProjects: HIRLProject[]
  ): Observable<ServerResponse<HIRLProject>> {
    return this.http.post<ServerResponse<HIRLProject>>(
      `${this.baseUrl}create_bulk/`,
      hirlProjects
    );
  }

  delete(project: HIRLProject, options?: IDataServiceMethodOptions) {
    const ignoreStore = options?.ignoreStore;
    let chain = this.http.delete(`${this.baseUrl}${project.id}/`);
    if (!ignoreStore) {
      chain = chain.pipe(
        tap(_ =>
          this.store.dispatch(
            deleteHIRLProjectRegistrationProject({ payload: project })
          )
        )
      );
    }
    return chain;
  }

  update(hirlProject: HIRLProject, projectId: string) {
    const payload = Object.assign(new HIRLProject(), hirlProject);

    return this.http.patch<HIRLProject>(
      `${this.baseUrl}${projectId}/`,
      payload
    );
  }

  green_energy_badges(projectId: string): Observable<HIRLGreenEnergyBadge[]> {
    return this.http.get<HIRLGreenEnergyBadge[]>(
      `${this.baseUrl}${projectId}/green_energy_badges/`,
      {}
    );
  }

  homeAddressIsUnique(
    hirlProjectAddressIsUniqueRequestData: HIRLProjectAddressIsUniqueRequestData
  ): Observable<boolean> {
    return this.http.post<boolean>(
      `${this.baseUrl}project_address_is_unique/`,
      hirlProjectAddressIsUniqueRequestData
    );
  }

  billing_rule_export(params?: BillingRuleExportParams) {
    return this.http.get<AsynchronousProcessedDocument>(
      `${this.baseUrl}billing_rule_export/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  milestone_export(params?: MilestoneExportParams) {
    return this.http.get<AsynchronousProcessedDocument>(
      `${this.baseUrl}milestone_export/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  green_payments_import(formData): Observable<AsynchronousProcessedDocument> {
    return this.http.post<AsynchronousProcessedDocument>(
      `${this.baseUrl}green_payments_import/`,
      formData
    );
  }

  project_billing_import(formData): Observable<AsynchronousProcessedDocument> {
    return this.http.post<AsynchronousProcessedDocument>(
      `${this.baseUrl}project_billing_import/`,
      formData
    );
  }

  all_projects_report(
    params?: HIRLProjectRequestParams
  ): Observable<AsynchronousProcessedDocument> {
    return this.http.get<AsynchronousProcessedDocument>(
      `${this.baseUrl}all_projects_report/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  project_cycle_time_metrics(
    params?: CustomerHIRLProjectCycleTimeMetricsRequestParams
  ): Observable<ProjectCycleTimeMetrics[]> {
    return this.http.get<ProjectCycleTimeMetrics[]>(
      `${this.baseUrl}project_cycle_time_metrics/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  verification_report_upload(
    formData: FormData
  ): Observable<AsynchronousProcessedDocument> {
    return this.http.post<AsynchronousProcessedDocument>(
      `${this.baseUrl}verification_report_upload/`,
      formData
    );
  }
}
