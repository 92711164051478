<div class="row">
    <div class="col-3">
        <div class="row">
            <div class="col-12">
                <form [formGroup]="filterFromGroup" class="card">
                    <h5 class="card-header">Filters</h5>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Search</mat-label>
                                    <app-search-input formControlName="search" placeholder="Type to search something"></app-search-input>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Roles</mat-label>
                                    <app-generic-mat-select modelClassName="company_role" formControlName="roles" multiple="true" parentKey="slug" [processResponse]="processCompanyRoleResponse"></app-generic-mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Is Active</mat-label>
                                    <mat-select formControlName="user__is_active">
                                        <mat-option [value]="''">Any</mat-option>
                                        <mat-option [value]="true">
                                            Yes
                                        </mat-option>
                                        <mat-option [value]="false">
                                            No
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="col-9">
        <div class="row">
            <div class="col-12">
                <div class="row" *ngIf="isLoading">
                    <div class="col-12">
                        <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
                    </div>
                </div>
                <table mat-table
                       [dataSource]="rows"
                       class="table"
                       matSort
                       [matSortActive]="storedParams.toTableSort().active"
                       [matSortDirection]="storedParams.toTableSort().direction"
                       (matSortChange)="onSortChange($event)">

                    <ng-container matColumnDef="user__first_name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                        <td mat-cell *matCellDef="let row">
                            <app-user-display [user]="row.user_info" displayType="onlyNameAsLink"></app-user-display>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="roles__name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Roles</th>
                        <td mat-cell *matCellDef="let row">
                            <ng-container *ngIf="row.roles_info.length; else noRoles;">
                                <span
                                    *ngFor="let role of row.roles_info; let isLast=last">{{ role.name }}{{isLast ? '' : ', '}}</span>
                            </ng-container>
                            <ng-template #noRoles>
                                <span>General User</span>
                            </ng-template>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef>Change Role</th>
                        <td mat-cell *matCellDef="let row">
                            <a href="#" (click)="edit($event, row);">
                                <fa-icon [icon]="['fas', 'edit']"></fa-icon>
                            </a>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

                <mat-paginator [length]="rowsCount"
                               [pageIndex]="$any(storedParams.page) - 1"
                               [pageSize]="storedParams.page_size"
                               (page)="onPaginateChange($event)"
                               aria-label="Select page">
                </mat-paginator>
            </div>
        </div>
    </div>
</div>
