import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RemrateUserChangeDialogComponent } from './components/remrate-user-change-dialog/remrate-user-change-dialog.component';
import { SharedModule } from '@/shared/shared.module';

@NgModule({
  declarations: [RemrateUserChangeDialogComponent],
  imports: [CommonModule, SharedModule],
})
export class RemrateModule {}
