import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServerResponse } from '@/core/schemes/server-response';
import { environment } from '../../../../environments/environment';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import { ListRequestParams } from '@/core/schemes/request-params';
import { Category } from '@/data/product/models';

export class CategoryRequestParams extends ListRequestParams {}

@Injectable({
  providedIn: 'root',
})
export class CategoryService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/category/`;

  list(params?: CategoryRequestParams) {
    return this.http.get<ServerResponse<Category>>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }

  retrieve(
    categoryId: number,
    options?: IDataServiceMethodOptions
  ): Observable<Category> {
    const ignoreStore = options?.ignoreStore;
    const chain = this.http.get<Category>(`${this.baseUrl}${categoryId}`);
    return chain;
  }
}
