import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import {
  ClientAgreement,
  HIRLProductAgreement,
} from '@/data/customer-hirl/models';
import { FormBuilder, FormGroup } from '@angular/forms';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { MatDialog } from '@angular/material/dialog';
import { ClientAgreementService } from '@/data/customer-hirl/services/client-agreement.service';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import * as _ from 'lodash';
import { first, takeUntil, tap } from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Annotation, AnnotationType } from '@/data/annotation/models';
import {
  AnnotationRequestParams,
  AnnotationService,
} from '@/data/annotation/services/annotation.service';
import { User } from '@/data/core/models/user';
import { getInfoUser } from '@/state/selectors/info.selector';
import {
  AnnotationTypeRequestParams,
  AnnotationTypeService,
} from '@/data/annotation/services/annotation-type.service';
import { toggleLoading } from '@/state/actions/app.actions';
import {
  AnnotationEditDialogComponent,
  AnnotationEditDialogData,
} from '@/modules/annotation/components/annotation-edit-dialog/annotation-edit-dialog.component';
import { AnnotationPermissionService } from '@/modules/annotation/services/annotation-permission-service';
import {
  loadClientAgreementNotes,
  loadClientAgreementNotesCount,
} from '@/state/actions/customer-hirl/client-agreement.actions';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { Company } from '@/data/company/models';
import { CompanyService } from '@/data/company/services/company-base.service';
import { ServerResponse } from '@/core/schemes/server-response';
import {
  loadCompanyNotes,
  loadCompanyNotesCount,
} from '@/state/actions/company/company.actions';
import {
  loadInvoiceNotes,
  loadInvoiceNotesCount,
} from '@/state/actions/invoicing/invoice.actions';
import { Invoice } from '@/data/invoicing/models';
import { InvoiceService } from '@/data/invoicing/services/invoice.service';
import { HIRLProductAgreementService } from '@/data/customer-hirl/services/product-agreement.service';
import {
  loadHIRLProductAgreementNotes,
  loadHIRLProductAgreementNotesCount,
} from '@/state/actions/customer-hirl/hirl-product-agreement.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { Product } from '@/data/product/models';
import { ProductService } from '@/data/product/services/product.service';
import {
  loadProductNotes,
  loadProductNotesCount,
} from '@/state/actions/product/product.actions';

@Component({
  selector: 'app-annotation-tab',
  templateUrl: './annotation-tab.component.html',
  styleUrls: ['./annotation-tab.component.scss'],
})
export class AnnotationTabComponent implements OnInit, OnDestroy {
  public currentUser: User;
  public displayedColumns: string[] = [
    'content',
    'user__first_name',
    'created_on',
    'is_public',
    'actions',
  ];
  public filterFromGroup: FormGroup;
  public isLoading = false;
  public rows: Annotation[] = [];
  public permissions: {
    [key: string]: {
      canEdit: Observable<ObjectPermissionResponse>;
      canDelete: Observable<ObjectPermissionResponse>;
    };
  } = {};
  public rowsCount: number;
  public createPermissionResponse: Observable<ObjectPermissionResponse>;

  public defaultParams: AnnotationRequestParams = new AnnotationRequestParams(
    1,
    '',
    '-id'
  );

  public storedParams: AnnotationRequestParams = Object.assign(
    new AnnotationRequestParams(),
    this.defaultParams
  );

  private listSubscription$: Subscription;
  private componentDestroyed$ = new Subject();
  private annotationType: AnnotationType;
  private modelService$: any;

  @Input() objectId: number | string;
  @Input() annotationTypeSlug: string;
  @Input() modelClassName: string;

  public modelsMap = {
    client_agreement: {
      modelCls: ClientAgreement,
      serviceCls: ClientAgreementService,
      method: 'annotations',
      filterParamsCls: AnnotationRequestParams,
      listSelector: loadClientAgreementNotes,
      countSelector: loadClientAgreementNotesCount,
    },
    company: {
      modelCls: Company,
      serviceCls: CompanyService,
      method: 'annotations',
      filterParamsCls: AnnotationRequestParams,
      listSelector: loadCompanyNotes,
      countSelector: loadCompanyNotesCount,
    },
    invoice: {
      modelCls: Invoice,
      serviceCls: InvoiceService,
      method: 'annotations',
      filterParamsCls: AnnotationRequestParams,
      listSelector: loadInvoiceNotes,
      countSelector: loadInvoiceNotesCount,
    },
    hirl_product_agreement: {
      modelCls: HIRLProductAgreement,
      serviceCls: HIRLProductAgreementService,
      method: 'annotations',
      filterParamsCls: AnnotationRequestParams,
      listSelector: loadHIRLProductAgreementNotes,
      countSelector: loadHIRLProductAgreementNotesCount,
    },
    product: {
      modelCls: Product,
      serviceCls: ProductService,
      method: 'annotations',
      filterParamsCls: AnnotationRequestParams,
      listSelector: loadProductNotes,
      countSelector: loadProductNotesCount,
    },
  };

  constructor(
    private store: Store<AppState>,
    private injector: Injector,
    private dialog: MatDialog,
    private annotationService: AnnotationService,
    private annotationPermissionService: AnnotationPermissionService,
    private fb: FormBuilder,
    private annotationTypeService: AnnotationTypeService,
    private uiHelperService: UIHelperService
  ) {}

  ngOnInit(): void {
    this.storedParams = _.cloneDeep(this.defaultParams);
    this.storedParams.type__slug = this.annotationTypeSlug;

    this.modelService$ = this.injector.get(
      this.modelsMap[this.modelClassName].serviceCls
    );

    const sources = {
      currentUser: this.store.select(getInfoUser).pipe(first()),
      annotationTypesResponse: this.annotationTypeService
        .list({
          slug: this.annotationTypeSlug,
        } as AnnotationTypeRequestParams)
        .pipe(first()),
    };

    forkJoin(sources)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: ({
          currentUser,
          annotationTypesResponse,
        }: {
          currentUser: any;
          annotationTypesResponse: ServerResponse<AnnotationType>;
        }) => {
          this.currentUser = currentUser;

          if (annotationTypesResponse.results.length > 0) {
            this.annotationType = annotationTypesResponse.results[0];
          }

          this.list();
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });

    this.setupFilterForm();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  setupFilterForm() {
    this.filterFromGroup = this.fb.group({
      search: [null],
    });

    this.filterFromGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        const params = _.merge({}, this.defaultParams, values);
        delete params.ordering;

        this.storedParams = _.merge(this.storedParams, params);
        this.list();
      });
  }

  list() {
    this.isLoading = true;

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }

    this.listSubscription$ = this.modelService$[
      this.modelsMap[this.modelClassName].method
    ](this.objectId, this.storedParams)
      .pipe(takeUntil(this.componentDestroyed$))
      .pipe(
        tap((serverResponse: ServerResponse<Annotation>) => {
          if (this.modelsMap[this.modelClassName].listSelector) {
            this.store.dispatch(
              this.modelsMap[this.modelClassName].listSelector({
                payload: serverResponse.results,
              })
            );
          }
          if (this.modelsMap[this.modelClassName].countSelector) {
            this.store.dispatch(
              this.modelsMap[this.modelClassName].countSelector({
                payload: serverResponse.count,
              })
            );
          }
        })
      )
      .subscribe(data => {
        this.rows = data.results;
        this.rowsCount = data.count;
        this.isLoading = false;
        this.rows.forEach(row => {
          this.permissions[row.id] = {
            canEdit: this.annotationPermissionService.canEdit(row),
            canDelete: this.annotationPermissionService.canDelete(row),
          };
        });
      });
  }

  onPaginateChange($event: PageEvent) {
    this.uiHelperService.getUISettings().rowsPerPage = $event.pageSize;
    this.storedParams.page_size = $event.pageSize;
    this.storedParams.page = $event.pageIndex + 1;
    this.list();
  }

  onSortChange($event: Sort) {
    this.storedParams.ordering = `${$event.direction === 'desc' ? '-' : ''}${
      $event.active
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  edit($event: MouseEvent, annotation: Annotation) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(AnnotationEditDialogComponent, {
      width: '45%',
      disableClose: true,
      autoFocus: false,
      data: {
        title: 'Note',
        annotation: annotation,
      } as AnnotationEditDialogData,
    });

    dialogRef.afterClosed().subscribe(changedAnnotation => {
      if (!changedAnnotation) {
        return;
      }
      this.list();
      this.uiHelperService.openSnackBar(`Note Successfully updated`);
    });
  }

  delete($event: MouseEvent, annotation: Annotation) {
    $event.preventDefault();

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '45%',
      data: {
        title: 'Delete Note ?',
        content: `Do you want to delete note`,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      this.store.dispatch(toggleLoading({ payload: true }));
      this.annotationService
        .delete(annotation.id)
        .pipe(takeUntil(this.componentDestroyed$), first())
        .subscribe(() => {
          this.list();
          this.store.dispatch(toggleLoading({ payload: false }));
        });
    });
  }

  create($event: MouseEvent) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(AnnotationEditDialogComponent, {
      width: '45%',
      disableClose: true,
      autoFocus: false,
      data: {
        title: 'Note',
        annotation: {
          user: this.currentUser.id,
          type: this.annotationType.id,
        },
      } as AnnotationEditDialogData,
    });

    dialogRef.afterClosed().subscribe((result?: Annotation) => {
      if (!result) {
        return;
      }
      this.store.dispatch(toggleLoading({ payload: true }));
      this.modelService$
        .annotationsCreate(this.objectId, result)
        .pipe(takeUntil(this.componentDestroyed$), first())
        .subscribe({
          next: (_: Annotation) => {
            this.list();
            this.store.dispatch(toggleLoading({ payload: false }));
            this.uiHelperService.openSnackBar(`Note Successfully Created`);
          },
          error: (error: HttpErrorResponse) =>
            this.uiHelperService.handleUserRequestError(error),
        });
    });
  }
}
