import {
  LocationBackendDict,
  createLocationForm,
} from '@/data/simulation/models/Location';
import { BaseValidator } from './base.validator';
import { ModelErrors, StateErrors } from '../state/shared/base.state';

export class LocationValidator extends BaseValidator {
  static validateForm(
    location: LocationBackendDict
  ): ModelErrors<LocationBackendDict> {
    const LocationForm = createLocationForm(location);
    return LocationForm.getFieldAndModelErros();
  }

  static validate(
    locations: LocationBackendDict[]
  ): StateErrors<LocationBackendDict> {
    const errors = {};
    locations.forEach(location => {
      errors[location.id] = this.validateForm(location);
    });
    return errors;
  }
}
