<ng-container [ngSwitch]="displayType">
    <ng-container *ngSwitchCase="historyMatButtonDisplayType.asLink">
        <a href="#" (click)="showHistoryDialog($event);">
            <ng-container [ngTemplateOutlet]="content"></ng-container>
        </a>
    </ng-container>
    <ng-container *ngSwitchCase="historyMatButtonDisplayType.raisedButton">
        <button mat-raised-button matTooltip="History" color="accent" (click)="showHistoryDialog($event);">
            <ng-container [ngTemplateOutlet]="content"></ng-container>
        </button>
    </ng-container>
    <ng-container *ngSwitchCase="historyMatButtonDisplayType.iconButton">
        <button mat-icon-button matTooltip="History" (click)="showHistoryDialog($event);">
            <ng-container [ngTemplateOutlet]="content"></ng-container>
        </button>
    </ng-container>
</ng-container>

<ng-template #content>
    <ng-content></ng-content>
</ng-template>
