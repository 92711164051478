import {
  SeasonalRateBackendDict,
  DetailedSeasonalRateBackendDict,
} from '@/data/simulation/models/utility/SeasonalRate';
import { createAction, props } from '@ngrx/store';
import { ModelErrors, StateErrors } from '../shared/base.state';

export const loadDetailedSeasonalRates = createAction(
  '[Simulation API] Load Detailed SeasonalRates',
  props<{ detailedSeasonalRates: DetailedSeasonalRateBackendDict[] }>()
);

export const loadSeasonalRatesSuccess = createAction(
  '[SeasonalRate API] Load SeasonalRates Success',
  props<{
    seasonalRates: SeasonalRateBackendDict[];
    errors: StateErrors<SeasonalRateBackendDict>;
  }>()
);

export const updateSeasonalRate = createAction(
  '[SeasonalRate UI] Update SeasonalRate',
  props<{ seasonalRateChanges: Partial<SeasonalRateBackendDict> }>()
);

export const updateSeasonalRateSuccess = createAction(
  '[SeasonalRate API] Update SeasonalRate Success',
  props<{
    seasonalRate: SeasonalRateBackendDict;
    errors: ModelErrors<SeasonalRateBackendDict>;
  }>()
);

export const updateSeasonalRateFailure = createAction(
  '[SeasonalRate API] Update SeasonalRate Failure',
  props<{ id: number }>()
);

export const removeSeasonalRate = createAction(
  '[SeasonalRate UI] Remove SeasonalRate',
  props<{ seasonalRate: SeasonalRateBackendDict }>()
);

export const removeSeasonalRateSuccess = createAction(
  '[SeasonalRate API] Remove SeasonalRate Success',
  props<{ id: number }>()
);

export const removeSeasonalRateFailure = createAction(
  '[SeasonalRate API] Remove SeasonalRate Failure',
  props<{ id: number }>()
);

export const updateSeasonalRateErrors = createAction(
  '[SeasonalRate UI] Update SeasonalRate Errors',
  props<{ id: number; errors: any }>()
);

export const removeBlockRateSuccess = createAction(
  '[SeasonalRate UI] Remove BlockRate Success',
  props<{ id: number }>()
);
