<div [@snackbarAnimation]>
  <div class="row modern-message-container"
       [ngStyle]="{'background': MessageLevelLabelMapping[entity.level]?.backgroundColor, 'color': MessageLevelLabelMapping[entity.level]?.color}">
    <div class="col-2 message-icon-col">
      <fa-icon [icon]="['fas', MessageLevelLabelMapping[entity.level]?.icon]"
               *ngIf="MessageLevelLabelMapping[entity.level]?.icon" size="2x"></fa-icon>
    </div>
    <div class="col-8 message-body-col">
      <h5 class="title">{{ entity.title }}</h5>
      <div [innerHTML]="entity.content"></div>
      <div class="row">
        <div class="col-12">
          <div class="small text-muted">
            <span> {{ entity.date_created | date: 'MM/dd/yyy HH:MM' }}</span>
            <span> • </span>
            <span> {{ MessageLevelLabelMapping[entity.level].label }}</span>
            <span> • </span>
            <span class="badge badge-secondary">{{ entity.category }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-2 message-actions-col">
      <div>
        <a (click)="onClose($event, entity)" [matTooltip]="'Close'" matTooltipPosition="left" class="action">
          <fa-icon [icon]="['fas', 'times']"></fa-icon>
        </a>
      </div>
      <div *ngIf="entity.id">
        <a (click)="onRead($event, entity)" [matTooltip]="'Mark as Read'" matTooltipPosition="left" class="action">
          <fa-icon [icon]="['fas', 'check']"></fa-icon>
        </a>
      </div>
      <div>
        <a [routerLink]="entity.url" target="_blank" [matTooltip]="'Go to page'" matTooltipPosition="left" *ngIf="entity.url" class="action">
          <fa-icon [icon]="['fas', 'external-link-alt']"></fa-icon>
        </a>
      </div>
    </div>
  </div>
</div>
