import {
  FoundationWallBackendDict,
  createFoundationWallForm,
} from '@/data/simulation/models/enclosure/FoundationWall';
import { BaseValidator } from './base.validator';
import { ModelErrors, StateErrors } from '../state/shared/base.state';
import { FoundationWallTypeBackendDict } from '@/data/simulation/models/enclosure/FoundationWallType';
import { BasementLocations } from '@/data/simulation/enumerations';

export class FoundationWallValidator extends BaseValidator {
  static validateBasementFramingFactor(
    foundationWall: FoundationWallBackendDict,
    type: FoundationWallTypeBackendDict
  ) {
    const result = {
      id: 'invalidBasementFramingFactor',
      validationSuccess: true,
      data: {},
    };

    if (BasementLocations.hasOwnProperty(foundationWall.interior_location)) {
      const framingFactor = type.framing_factor;

      if (framingFactor <= 0 || framingFactor < 0.99) {
        const data = {
          min: 0,
          max: 1,
          actual: framingFactor,
        };
        result.data = data;
        result.validationSuccess = false;
      }
    }
    return result;
  }

  static validateForm(
    foundationwall: FoundationWallBackendDict
  ): ModelErrors<FoundationWallBackendDict> {
    const foundationwallForm = createFoundationWallForm(foundationwall);
    const errors = {};
    Object.keys(foundationwall).forEach(key => {
      if (!foundationwallForm.get(key)) return;
      errors[key] = foundationwallForm.get(key).errors;
    });
    return {
      field: errors,
      model: foundationwallForm.errors || {},
    };
  }

  static validate(
    foundationwalls: FoundationWallBackendDict[]
  ): StateErrors<FoundationWallBackendDict> {
    const errors = {};
    foundationwalls.forEach(foundationwall => {
      errors[foundationwall.id] = this.validateForm(foundationwall);
    });
    return errors;
  }
}
