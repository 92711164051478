import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { User } from '@/data/core/models/user';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { MatDialog } from '@angular/material/dialog';
import { filter, takeUntil } from 'rxjs/operators';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserService } from '@/data/core/services/user.service';
import { EEPProgramHomeStatusRequestParams } from '@/data/home/services/eep_program_home_status.service';
import { UserCertificationMetric } from '@/data/core/models';
import { getUser } from '@/state/selectors/user/user.selector';

import { EEPProgram } from '@/data/eep-program/models';
import { GenericTableComponent } from '@/shared/components/generic-table/generic-table.component';

@Component({
  selector: 'app-user-metrics-tab',
  templateUrl: './user-metrics-tab.component.html',
  styleUrls: ['./user-metrics-tab.component.scss'],
})
export class UserMetricsTabComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild(GenericTableComponent)
  tableComponent!: GenericTableComponent<UserCertificationMetric>;

  public getDataSource = (params: EEPProgramHomeStatusRequestParams) =>
    this.list(params);

  public displayedColumns = [
    'eep_program__name',
    'home',
    'role',
    'certification_date',
  ];
  public filterFormGroup: FormGroup;

  public user: User;
  public currentUser: User;

  public defaultParams: EEPProgramHomeStatusRequestParams =
    new EEPProgramHomeStatusRequestParams(1, '', '-created_date', 25);
  public storedParams: EEPProgramHomeStatusRequestParams =
    new EEPProgramHomeStatusRequestParams(1, '', '-created_date', 25);

  public overwritePropertyValues = {};
  public overwritePropertyLabels: { [key: string]: string } = {
    eep_program: 'Program Name(s)',
  };
  public excludedProperties: string[];

  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private dialog: MatDialog,
    private userService: UserService,
    private fb: FormBuilder,
    private uiHelperService: UIHelperService
  ) {}

  ngOnInit(): void {
    this.store
      .select(getUser)
      .pipe(
        filter(val => val !== null),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((entity: User) => {
        this.user = entity;
        this.tableComponent?.list();
      });

    this.setupFilterForm();
  }

  ngAfterViewInit() {
    this.tableComponent?.list();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  setupFilterForm() {
    this.filterFormGroup = this.fb.group({
      search: [null],
      eep_program_info: [null],
      role: [null],
    });
    this.filterFormGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        let params = Object.assign(this.defaultParams, values);

        params = Object.assign(params, {
          eep_program: params.eep_program_info?.map(
            (eepProgram: EEPProgram) => eepProgram.id
          ),
        });

        params['rater_of_record'] = null;
        params['energy_modeler'] = null;
        params['field_inspector'] = null;
        params['customer_hirl_rough_verifier'] = null;
        params['customer_hirl_final_verifier'] = null;

        if (params.role) {
          params[params.role] = this.user.id;
        }

        this.storedParams.assignFilterFormValues({
          formValues: params,
          excludedProperties: ['ordering', 'eep_program_info', 'role'],
        });

        this.overwritePropertyValues = {
          eep_program: this.filterFormGroup.value.eep_program_info?.map(
            (eepProgram: EEPProgram) => eepProgram.name
          ),
        };
        this.tableComponent?.list();
      });
  }

  list(params: EEPProgramHomeStatusRequestParams) {
    return this.userService.eep_program_home_statuses_certification_metrics(
      this.user.id,
      params
    );
  }
}
