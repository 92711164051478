import { addNewDoor } from '@/modules/simulation/state/simulation/actions';
import { selectDoorIds } from '@/modules/simulation/state/simulation/selectors';
import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-doors',
  templateUrl: './doors.component.html',
  styleUrls: ['./doors.component.scss'],
})
export class DoorsComponent {
  componentDestroyed$ = new Subject();
  doorIds: number[] = [];
  @Input() expanded = true;

  constructor(public store: Store) {
    this.store
      .select(selectDoorIds())
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(ids => {
        this.doorIds = ids;
      });
  }

  addDoor() {
    this.store.dispatch(addNewDoor());
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
