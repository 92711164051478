import {
  AppliancesBackendDict,
  DishwasherPresetLabels,
  createDishwasherForm,
  FIELD_LABELS,
  DishwasherSizeUnitLabels,
  DEFAULT_DISHWASHER_VALUES,
  DishwasherPresets,
  DishwasherEfficiencyLabels,
  ApplianceCostRateUnitLabels,
  CostUnitLabels,
} from '@/data/simulation/models/Appliances';
import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseAppliancesDirective } from '../base-appliances.directive';
import { AuxEnergyUnitLabels } from '@/data/simulation/enumerations';

@Component({
  selector: 'app-dishwasher',
  templateUrl: './dishwasher.component.html',
  styleUrls: ['./dishwasher.component.scss'],
})
export class DishwasherComponent extends BaseAppliancesDirective {
  createForm: (data: AppliancesBackendDict) => FormGroup = createDishwasherForm;
  dishwasherPresetLabels = DishwasherPresetLabels;
  fieldLabels = FIELD_LABELS;
  dishwasherSizeUnitLabels = DishwasherSizeUnitLabels;
  dishwasherEfficiencyLabels = DishwasherEfficiencyLabels;
  auxEnergyUnitLabels = AuxEnergyUnitLabels;
  applianceCostRateUnitLabels = ApplianceCostRateUnitLabels;
  costUnitLabels = CostUnitLabels;

  updateFormAsPerPreset(value: DishwasherPresets, updateValues = true) {
    if (DEFAULT_DISHWASHER_VALUES.hasOwnProperty(value) && updateValues) {
      this.form.patchValue(DEFAULT_DISHWASHER_VALUES[value]);
    }

    if (value !== DishwasherPresets.LABEL) {
      this.form.controls.dishwasher_label_energy_rating.disable();
      this.form.controls.dishwasher_label_gas_annual_cost.disable();
      this.form.controls.dishwasher_label_electric_rate.disable();
      this.form.controls.dishwasher_label_gas_rate.disable();
    } else {
      this.form.controls.dishwasher_label_energy_rating.enable();
      this.form.controls.dishwasher_label_gas_annual_cost.enable();
      this.form.controls.dishwasher_label_electric_rate.enable();
      this.form.controls.dishwasher_label_gas_rate.enable();
    }
  }

  updateForm(): void {
    this.updateFormAsPerPreset(
      this.form.controls.dishwasher_preset.value,
      false
    );
  }
  ngOnInit(): void {
    super.ngOnInit();

    this.form.controls.dishwasher_preset.valueChanges.subscribe(
      this.updateFormAsPerPreset.bind(this)
    );
  }
}
