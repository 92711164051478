import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';
import { PaymentRequestProduct } from '@/data/customer-hirl/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '@/data/core/models/user';
import { forkJoin, of, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, takeUntil } from 'rxjs/operators';
import { toggleLoading } from '@/state/actions/app.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { PaymentRequestProductService } from '@/data/customer-hirl/services/payment-request-product.service';
import { PaymentRequestService } from '@/data/customer-hirl/services/payment-request.service';

export interface PaymentRequestProductChangeDialogData {
  paymentRequestProduct: PaymentRequestProduct;
  commit?: boolean;
}

@Component({
  selector: 'app-payment-request-product-change-dialog',
  templateUrl: './payment-request-product-change-dialog.component.html',
  styleUrls: ['./payment-request-product-change-dialog.component.scss'],
})
export class PaymentRequestProductChangeDialogComponent
  implements OnInit, OnDestroy
{
  public initialized: boolean;
  public customerHIRLSettings = CustomerHIRLSettings;
  public isEditForm = false;
  public entity?: PaymentRequestProduct;
  public commit = true;
  public retrieve = true;
  public form: FormGroup;
  public currentUser: User;

  public displayedColumns = ['thumbnail', 'title', 'practice_items_count'];

  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<PaymentRequestProductChangeDialogComponent>,
    private fb: FormBuilder,
    private paymentRequestService: PaymentRequestService,
    private paymentRequestProductGroupService: PaymentRequestProductService,
    private dialog: MatDialog,
    private uiHelperService: UIHelperService,
    @Inject(MAT_DIALOG_DATA)
    public data: PaymentRequestProductChangeDialogData
  ) {
    if (data?.paymentRequestProduct) {
      this.entity = Object.assign({}, data?.paymentRequestProduct);
    }
    this.commit = data?.commit || true;
    if (this.entity?.id) {
      this.isEditForm = true;
    }
  }
  ngOnInit() {
    const sources = {
      currentUser: this.store.select(getInfoUser).pipe(first()),
      paymentRequest: of(this.entity),
    };

    this.store.dispatch(toggleLoading({ payload: true }));

    if (this.isEditForm && this.retrieve) {
      sources.paymentRequest = this.paymentRequestProductGroupService
        .retrieve(this.entity.id, { ignoreStore: true })
        .pipe(takeUntil(this.componentDestroyed$), first());
    }

    forkJoin(sources)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: ({ currentUser, paymentRequest }) => {
          this.store.dispatch(toggleLoading({ payload: false }));

          this.currentUser = currentUser;
          this.entity = paymentRequest;
          this.setupForm();
          this.initialized = true;
          if (this.isEditForm) {
            this.hydrateForm();
          }
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  private setupForm() {
    this.form = this.fb.group({
      product_info: [null, [Validators.required]],
    });
  }

  hydrateForm() {
    this.form.patchValue(Object.assign({}, this.entity), {
      emitEvent: false,
      onlySelf: true,
    });
  }

  save($event: MouseEvent) {
    $event.preventDefault();
    if (this.isEditForm) {
      this.edit();
    } else {
      this.create();
    }
  }

  private create() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    if (!this.commit) {
      this.dialogRef.close(this.entity);
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const data = new PaymentRequestProduct();
    data.product = this.form.get('product_info').value?.id;

    this.paymentRequestService
      .payment_request_productsCreate(this.entity.payment_request, data)
      .pipe(first())
      .subscribe({
        next: (entity: PaymentRequestProduct) => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close(entity);
        },
        error: (error: HttpErrorResponse) =>
          this.uiHelperService.handleUserRequestError(error),
      });
  }

  private edit() {}
}
