import { Injectable } from '@angular/core';
import { BaseDataService } from '@/core/services/base-data-service';
import { environment } from '../../../../environments/environment';
import { ServerResponse } from '@/core/schemes/server-response';
import { ObservationType } from '@/data/qa/models';
import { ListRequestParams } from '@/core/schemes/request-params';
import { Observable } from 'rxjs';

export class ObservationTypeRequestParams extends ListRequestParams {}

@Injectable({
  providedIn: 'root',
})
export class ObservationTypeService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/observation_types/`;

  list(
    params?: ObservationTypeRequestParams
  ): Observable<ServerResponse<ObservationType>> {
    return this.http.get<ServerResponse<ObservationType>>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }

  retrieve(observationTypeId: number): Observable<ObservationType> {
    return this.http.get<ObservationType>(
      `${this.baseUrl}${observationTypeId}`
    );
  }
}
