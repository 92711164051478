<div class="container-fluid" *ngIf="initialized">
  <div class="row">
    <div class="col-12">
      <div class="row mb-2">
        <div class="col-6">
          <h3>
            Welcome <app-user-display [user]="currentUser"></app-user-display>
          </h3>
        </div>
        <div class="col-6 text-right">
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <hr>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <div class="card mb-2">
            <div class="card-header">
              <h5>Finish Setup your Account</h5>
            </div>
            <div class="card-body">
              <ul class="list-group list-group-flush">
                <li class="list-group-item">
<!--                  <a href="#">Complete Client Agreement</a>-->
                   <s><span class="text-muted mr-2">Complete Client Agreement</span></s> <fa-icon [icon]="['fas', 'check']" class="text-success"></fa-icon>
                </li>
                <li class="list-group-item">
                  <a href="#">Create Products</a>
                </li>
                <li class="list-group-item">
                  <a href="#">Initiate New Payment Request</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="card mb-2">
            <div class="card-header">
              <h5>Quick Actions</h5>
            </div>
            <div class="card-body">
              <ul class="list-group list-group-flush">
                <li class="list-group-item">
                  <a href="#">Manage Products / Product Groups</a>
                </li>
                <li class="list-group-item">
                  <a href="#">Manage Payments</a>
                </li>
                <li class="list-group-item">
                  <a href="#">View / Download Invoices</a>
                </li>
                <li class="list-group-item">
                  <a href="#">Manage Company Profile</a>
                </li>
                <li class="list-group-item">
                  <a href="#">Manage Manufacturer Agreement</a>
                </li>
                <li class="list-group-item">
                  <a href="#">Add / Manage Certificate Of Insurance</a>
                </li>
                <li class="list-group-item">
                  <a href="#">Download Product Certificates</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="card mb-2">
            <div class="card-header">
              <h5>Unread Messages</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
