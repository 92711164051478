export class ProvidedService {
  id?: number;
  slug?: string;
  name?: string;
  order?: number;
  last_update?: string;
  created_at?: string;
}

export class ProvidedServiceInfo {
  id?: number;
  slug?: string;
  name?: string;
  order?: number;
  last_update?: string;
  created_at?: string;
}
