import {
  AfterContentInit,
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { UniqueSet } from '@/core/utils';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { ListRequestParams } from '@/core/schemes/request-params';
import { Observable, Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ServerResponse } from '@/core/schemes/server-response';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MatColumnDef,
  MatHeaderRowDef,
  MatNoDataRow,
  MatRowDef,
  MatTable,
} from '@angular/material/table';

@Component({
  selector: 'app-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.scss'],
})
export class GenericTableComponent<T>
  implements OnInit, OnDestroy, AfterContentInit
{
  @Output() listChanged = new EventEmitter<void>();
  @Output() listChangeStarted = new EventEmitter<void>();

  @Output() selectionChanged = new EventEmitter<void>();

  @ContentChildren(MatSort) matSorts!: QueryList<MatSort>;
  @ContentChildren(MatHeaderRowDef) headerRowDefs!: QueryList<MatHeaderRowDef>;
  @ContentChildren(MatRowDef) rowDefs!: QueryList<MatRowDef<T>>;
  @ContentChildren(MatColumnDef) columnDefs!: QueryList<MatColumnDef>;
  @ContentChild(MatNoDataRow) noDataRow!: MatNoDataRow;
  @ViewChild(MatTable, { static: true }) table!: MatTable<T>;

  @Input() displayedColumns: string[] = [];
  @Input() dataSource!: (
    params: ListRequestParams
  ) => Observable<ServerResponse<T>>;
  @Input() routerParamsToQueryString = false;
  @Input() storedParams!: ListRequestParams;
  @Input() idKey: keyof T = 'id' as keyof T;

  public entities: T[] = [];
  public entitiesCount = 0;

  public sort!: MatSort;
  public selectAllEntities = false;
  public selectedEntities = new UniqueSet<T>((item: T) => item[this.idKey]);
  public entitiesIsLoading = true;
  public initialized = false;

  private sortInitialized = false;
  private listSubscription$?: Subscription;
  private componentDestroyed$ = new Subject<void>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private uiHelperService: UIHelperService
  ) {}

  ngOnInit(): void {}

  ngAfterContentInit(): void {
    this.columnDefs.forEach(columnDef => this.table.addColumnDef(columnDef));
    this.rowDefs.forEach(rowDef => this.table.addRowDef(rowDef));
    this.headerRowDefs.forEach(headerRowDef =>
      this.table.addHeaderRowDef(headerRowDef)
    );
    this.table.setNoDataRow(this.noDataRow);
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
    this.listSubscription$?.unsubscribe();
  }

  list(): void {
    this.entitiesIsLoading = true;
    if (this.routerParamsToQueryString) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: this.storedParams.toQueryParams(),
        replaceUrl: true,
      });
    }

    if (!this.sortInitialized) {
      this.sort = this.matSorts.first;
      this.sortInitialized = true;
      if (this.sort) {
        this.sort.sort({
          id: this.storedParams.toTableSort().active,
          start: this.storedParams.toTableSort().direction,
        } as MatSortable);
        this.sort.sortChange
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe((sort: Sort) => this.onSort(sort));
      }
    }

    this.listSubscription$?.unsubscribe();
    this.listChangeStarted.emit();

    this.listSubscription$ = this.dataSource(this.storedParams)
      .pipe(takeUntil(this.componentDestroyed$), take(1))
      .subscribe({
        next: (data: ServerResponse<T>) => {
          this.entities = data.results;
          this.entitiesCount = data.count;
          this.entitiesIsLoading = false;

          if (this.selectAllEntities) {
            this.entities.forEach(row => this.selectedEntities.add(row));
          }

          this.initialized = true;
          this.listChanged.emit();
        },
        error: (error: HttpErrorResponse) => {
          if (error.status === 400) {
            let invalidQueryParam = false;
            Object.keys(error.error).forEach(key => {
              if (this.storedParams.hasOwnProperty(key)) {
                invalidQueryParam = true;
                delete this.storedParams[key];
              }
            });

            if (invalidQueryParam) {
              this.list();
            }
          }
        },
      });
  }

  clearSelection(): void {
    this.selectAllEntities = false;
    this.selectedEntities.clear();

    this.selectionChanged?.emit();
  }

  onSelectAll(): void {
    this.selectAllEntities
      ? this.entities.forEach(row => this.selectedEntities.add(row))
      : this.selectedEntities.clear();

    this.selectionChanged?.emit();
  }

  onEntitySelect(event: MatCheckboxChange, row: T): void {
    event.checked
      ? this.selectedEntities.add(row)
      : this.selectedEntities.delete(row);
    this.selectAllEntities =
      this.selectedEntities.length === this.entitiesCount;
    this.selectionChanged?.emit();
  }

  isEntityChecked(row: T): boolean {
    return this.selectedEntities.has(row);
  }

  onSort(event: Sort): void {
    this.storedParams.ordering = `${event.direction === 'desc' ? '-' : ''}${
      event.active
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  onPaginateChange(event: PageEvent): void {
    this.uiHelperService.getUISettings().rowsPerPage = event.pageSize;
    this.storedParams.page_size = event.pageSize;
    this.storedParams.page = event.pageIndex + 1;
    this.list();
  }
}
