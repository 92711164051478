import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FloorPlan } from '@/data/floorplan/models/floorplan';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { Router } from '@angular/router';
import { EMPTY, forkJoin, Observable, Subject } from 'rxjs';
import { User } from '@/data/core/models/user';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { getInfoUser } from '@/state/selectors/info.selector';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  first,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { toggleLoading } from '@/state/actions/app.actions';
import { FloorPlanService } from '@/data/floorplan/services/floorplan.service';
import { SimulationService } from '@/data/simulation/services/simulation.service';
import { SimulationList } from '@/data/simulation/models';
import { ServerResponse } from '@/core/schemes/server-response';
import { SimulationRequestParams } from '@/data/simulation/services/simulation.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-floorplan-from-simulation-dialog',
  templateUrl: './floorplan-from-simulation-dialog.component.html',
  styleUrls: ['./floorplan-from-simulation-dialog.component.scss'],
})
export class FloorplanFromSimulationDialogComponent
  implements OnInit, OnDestroy
{
  public initialized: boolean;
  public entity?: FloorPlan;
  public currentUser: User;

  public checkedForExistingSimulation = false;
  public existingSimulationControl = new FormControl();
  public existingSimulations$: Observable<ServerResponse<SimulationList>>;
  public simulationName = '';
  public selectedCommunity?: SimulationList;
  private componentDestroyed$ = new Subject();
  public initialCompanyIds: number[] = [];

  public form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<FloorplanFromSimulationDialogComponent>,
    private floorplanService: FloorPlanService,
    private simulationService: SimulationService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    public router: Router,
    private uiHelperService: UIHelperService
  ) {}

  ngOnInit() {
    const sources = {
      currentUser: this.store.select(getInfoUser).pipe(first()),
    };

    this.store.dispatch(toggleLoading({ payload: true }));

    forkJoin(sources).subscribe({
      next: ({ currentUser, floorplan }) => {
        this.store.dispatch(toggleLoading({ payload: false }));

        this.currentUser = currentUser;
        this.existingSimulations$ =
          this.existingSimulationControl.valueChanges.pipe(
            takeUntil(this.componentDestroyed$),
            startWith(''),
            debounceTime(100),
            distinctUntilChanged(),
            tap(value => (this.simulationName = value)),
            tap(_ => this.store.dispatch(toggleLoading({ payload: true }))),
            switchMap(value => {
              const simulationRequestParams = new SimulationRequestParams();
              simulationRequestParams.search = value;
              simulationRequestParams.page_size = 50;
              simulationRequestParams.floorplan__isnull = true;
              return this.simulationService
                .list(simulationRequestParams)
                .pipe(catchError(err => EMPTY));
            }),
            tap(_ => this.store.dispatch(toggleLoading({ payload: false })))
          );
        this.initialized = true;
        this.setupForm();
      },
      error: error => this.uiHelperService.handleUserRequestError(error),
    });

    if (this.data && this.data.simulationId) {
      this.existingSimulations$.pipe(first()).subscribe(response => {
        const foundSimulation = response.results.find(
          sim => String(sim.id) === String(this.data.simulationId)
        );

        if (foundSimulation) {
          this.selectedCommunity = foundSimulation;
          this.simulationName = foundSimulation.name;
          this.form.patchValue({
            name: this.selectedCommunity.name,
            // number: this.selectedCommunity.floorplan_number,
            // square_footage: this.selectedCommunity.square_footage,
          });
          this.existingSimulationControl.setValue(foundSimulation);
          this.existingSimulationControl.updateValueAndValidity();
        }
      });
    }
  }

  public onCreateFromSimulation(event) {
    this.store.dispatch(toggleLoading({ payload: true }));
    this.form.markAllAsTouched();

    this.simulationService
      .createFloorplanFromSimulation(
        this.selectedCommunity.id,
        this.form.get('name').value
      )
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: (response: FloorPlan) => {
          this.store.dispatch(toggleLoading({ payload: false }));
          if (response.id) {
            const _ = this.router.navigate(['/', 'floorplans', response.id]);
          }
          this.dialogRef.close(response.id);
        },
        error: (error?: HttpErrorResponse) =>
          this.uiHelperService.handleUserRequestError(error),
      });
  }

  private setupForm() {
    this.form = this.fb.group({
      name: [null, [Validators.required]],
      number: [null],
      square_footage: [null],
      comment: [''],
    });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  displayExistingSimulationAutocomplete(simulation: SimulationList) {
    if (simulation) {
      return `${simulation.name}`;
    }
  }

  save($event: MouseEvent) {
    $event.preventDefault();
  }

  onSimulationSelected($event: MatAutocompleteSelectedEvent) {
    if (typeof $event.option.value === 'string') {
      this.checkedForExistingSimulation = true;
    } else {
      this.selectedCommunity = $event.option.value;
      this.form.patchValue({
        name: this.selectedCommunity.name,
        // number: this.selectedCommunity.floorplan_number,
        // square_footage: this.selectedCommunity.square_footage,
      });
    }
  }
}
