<h2 mat-dialog-title>
  <ng-container *ngIf="!isEditForm">
    Create New <b>Training</b>
  </ng-container>
  <ng-container *ngIf="isEditForm">
    Edit <b>Training</b>
  </ng-container>
</h2>
<mat-dialog-content *ngIf="initialized">
  <form [formGroup]="form" class="mb-2">
    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Training Name</mat-label>
          <input matInput type="text" placeholder="Training Name" formControlName="name">
          <mat-error *ngIf="form.get('name').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Training Name</mat-label>
          <input matInput type="text" placeholder="Training Company or Conference" formControlName="address">
          <mat-hint>
            Name of company delivering training or conference where training credits were earned
          </mat-hint>
          <mat-error *ngIf="form.get('name').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Training Date</mat-label>
          <input matInput type="text" placeholder="Training Date" formControlName="training_date" [matDatepicker]="trainingDatePicker">
          <mat-datepicker-toggle matSuffix [for]="trainingDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #trainingDatePicker></mat-datepicker>
          <mat-error *ngIf="form.get('training_date').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Training Type</mat-label>
          <mat-select formControlName="training_type">
            <mat-option *ngFor="let item of TrainingTypeLabelMapping | defaultOrderKeyValue" [value]="item.key">{{ item.value }}</mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('training_type').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Attendance</mat-label>
          <mat-select formControlName="attendance_type">
            <mat-option *ngFor="let item of AttendanceTypeLabelMapping | defaultOrderKeyValue" [value]="item.key">{{ item.value }}</mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('attendance_type').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Certificate</mat-label>
          <app-file-input [formControl]="form.controls['certificate']"></app-file-input>
          <mat-hint *ngIf="entity.certificate">
            Current file: <a href="{{ entity.certificate }}" target="_blank">{{ entity.certificate.split('/').pop() }}</a>
          </mat-hint>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Credit Hours</mat-label>
          <input matInput type="number" placeholder="Credit Hours" formControlName="credit_hours">
          <mat-error *ngIf="form.get('credit_hours').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Notes</mat-label>
          <textarea matInput type="text" placeholder="Notes" formControlName="notes"></textarea>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button color="accent" mat-raised-button (click)="save($event)">
    <ng-container *ngIf="isEditForm">Save</ng-container>
    <ng-container *ngIf="!isEditForm">Create</ng-container>
  </button>
  <button mat-button [mat-dialog-close]="null" color="primary" mat-raised-button>Cancel</button>
</mat-dialog-actions>
