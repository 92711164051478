import { createSelector } from '@ngrx/store';
import { SimulationState } from '../reducers';
import { selectSimulationFeature } from '../simulation.selectors';

export const selectLights = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.lights.entities[id]
  );

const selectLightsLoading = (id: number) =>
  createSelector(selectSimulationFeature, state => state.lights.loading[id]);

export const selectLightsViewSet = (id: number) =>
  createSelector(
    selectLights(id),
    selectLightsLoading(id),
    (data, loading) => ({ data, loading })
  );

export const selectLightsErrorsCount = (id: number) =>
  createSelector(selectSimulationFeature, (state: SimulationState) => ({
    errorCount: state.lights.errors[id].errorCount,
    warningCount: state.lights.errors[id].warningCount,
  }));
