import { Component, OnDestroy, OnInit } from '@angular/core';
import { FloorPlan } from '@/data/floorplan/models/floorplan';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { forkJoin, Subject } from 'rxjs';
import { User } from '@/data/core/models/user';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first } from 'rxjs/operators';
import { toggleLoading } from '@/state/actions/app.actions';
import { FloorPlanService } from '@/data/floorplan/services/floorplan.service';

@Component({
  selector: 'app-floorplan-from-blg-dialog',
  templateUrl: './floorplan-from-blg-dialog.component.html',
  styleUrls: ['./floorplan-from-blg-dialog.component.scss'],
})
export class FloorplanFromBLGDialogComponent implements OnInit, OnDestroy {
  public initialized: boolean;
  public entity?: FloorPlan;
  public form: FormGroup;
  public currentUser: User;

  private componentDestroyed$ = new Subject();
  public initialCompanyIds: number[] = [];

  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<FloorplanFromBLGDialogComponent>,
    private fb: FormBuilder,
    private floorplanService: FloorPlanService,
    private dialog: MatDialog,
    public router: Router,
    private uiHelperService: UIHelperService
  ) {}

  ngOnInit() {
    const sources = {
      currentUser: this.store.select(getInfoUser).pipe(first()),
    };

    this.store.dispatch(toggleLoading({ payload: true }));

    forkJoin(sources).subscribe({
      next: ({ currentUser, floorplan }) => {
        this.store.dispatch(toggleLoading({ payload: false }));

        this.currentUser = currentUser;
        this.setupForm();
        this.initialized = true;
      },
      error: error => this.uiHelperService.handleUserRequestError(error),
    });
  }

  public onCreateFromBLG(event) {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const data = new FormData();
    data.append('file', this.form.value.remrate_data_file);

    this.floorplanService
      .create_from_blg(data)
      .pipe(first())
      .subscribe({
        next: floorPlan => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close(floorPlan);
          const _ = this.router.navigate(['/', 'floorplans', floorPlan.id]);
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  private setupForm() {
    this.form = this.fb.group({
      remrate_data_file: [null, Validators.required],
    });
  }

  hydrateForm() {
    this.form.patchValue(Object.assign({}, this.entity), {
      emitEvent: false,
      onlySelf: true,
    });
  }

  save($event: MouseEvent) {
    $event.preventDefault();
  }
}
