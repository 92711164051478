import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { first, map } from 'rxjs/operators';
import {
  ClientAgreement,
  ClientAgreementExtensionRequestState,
  ClientAgreementState,
} from '@/data/customer-hirl/models';
import { Store } from '@ngrx/store';
import { getInfoUser } from '@/state/selectors/info.selector';
import { AppState } from '@/state/reducers';
import { ClientAgreementService } from '@/data/customer-hirl/services/client-agreement.service';
import { CompanyType } from '@/data/company/models';

@Injectable({
  providedIn: 'root',
})
export class HIRLClientAgreementPermissionService {
  constructor(
    public store: Store<AppState>,
    public clientAgreementService: ClientAgreementService
  ) {}

  canEdit(
    clientAgreement: number | ClientAgreement
  ): Observable<ObjectPermissionResponse> {
    const clientAgreement$ = this.getClientAgreement(clientAgreement);
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: clientAgreement$,
    }).pipe(
      map(({ currentUser, entity }) => {
        if (currentUser.is_superuser) {
          return new ObjectPermissionResponse(true);
        }
        if (entity.owner === currentUser.company) {
          return new ObjectPermissionResponse(true);
        }
        if (
          [
            ClientAgreementState.expired,
            ClientAgreementState.countersigned,
          ].includes(entity.state)
        ) {
          return new ObjectPermissionResponse(
            false,
            `Client Agreement already ${entity.state}`
          );
        }
        if (entity.company === currentUser.company) {
          return new ObjectPermissionResponse(true);
        }
        if (
          entity.initiator_info?.company === currentUser.company &&
          !entity.certifying_document
        ) {
          return new ObjectPermissionResponse(true);
        }
        return new ObjectPermissionResponse(
          false,
          'You do not have permission to perform this action'
        );
      })
    );
  }

  canDelete(clientAgreement: number | ClientAgreement) {
    const clientAgreement$ = this.getClientAgreement(clientAgreement);
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: clientAgreement$,
    }).pipe(
      map(({ currentUser, entity }) => {
        if (currentUser.is_superuser) {
          return new ObjectPermissionResponse(true);
        }
        if (entity.owner === currentUser.company) {
          return new ObjectPermissionResponse(true);
        }
        if (
          [
            ClientAgreementState.expired,
            ClientAgreementState.countersigned,
          ].includes(entity.state)
        ) {
          return new ObjectPermissionResponse(
            false,
            `Client Agreement already ${entity.state}`
          );
        }
        if (
          entity.initiator_info?.company === currentUser.company &&
          !entity.certifying_document
        ) {
          return new ObjectPermissionResponse(true);
        }
        return new ObjectPermissionResponse(
          false,
          'You do not have permission to perform this action'
        );
      })
    );
  }

  canReSendEmail(clientAgreement: number | ClientAgreement) {
    const clientAgreement$ = this.getClientAgreement(clientAgreement);
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: clientAgreement$,
    }).pipe(
      map(({ currentUser, entity }) => {
        if (
          [
            ClientAgreementState.expired,
            ClientAgreementState.countersigned,
          ].includes(entity.state)
        ) {
          return new ObjectPermissionResponse(
            false,
            `Client Agreement already ${entity.state}`
          );
        }
        if (currentUser.is_superuser) {
          return new ObjectPermissionResponse(true);
        }
        if (entity.owner === currentUser.company) {
          return new ObjectPermissionResponse(true);
        }
        if (
          entity.initiator_info?.company === currentUser.company &&
          !entity.certifying_document
        ) {
          return new ObjectPermissionResponse(true);
        }
        return new ObjectPermissionResponse(false);
      })
    );
  }

  canCounterSign(
    clientAgreement: number | ClientAgreement
  ): Observable<ObjectPermissionResponse> {
    const clientAgreement$ = this.getClientAgreement(clientAgreement);
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: clientAgreement$,
    }).pipe(
      map(({ currentUser, entity }) => {
        if (entity.state != ClientAgreementState.approved) {
          return new ObjectPermissionResponse(
            false,
            'Client Agreement is not in Approved state'
          );
        }
        if (!entity.agreement_start_date) {
          return new ObjectPermissionResponse(
            false,
            'Agreement start date not set'
          );
        }
        if (!entity.agreement_expiration_date) {
          return new ObjectPermissionResponse(
            false,
            'Agreement expiration date not set'
          );
        }
        if (!entity.certifying_document) {
          return new ObjectPermissionResponse(
            false,
            'Client did not sign agreement yet'
          );
        }
        return new ObjectPermissionResponse(true);
      })
    );
  }

  canInitiateExtensionRequest(
    clientAgreement: number | ClientAgreement
  ): Observable<ObjectPermissionResponse> {
    const clientAgreement$ = this.getClientAgreement(clientAgreement);
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: clientAgreement$,
    }).pipe(
      map(({ currentUser, entity }) => {
        const expirationDate = new Date(entity.agreement_expiration_date);
        const currentDate = new Date();
        const sixtyDaysBefore = new Date(
          expirationDate.setDate(expirationDate.getDate() - 60)
        );
        if (currentDate < sixtyDaysBefore) {
          return new ObjectPermissionResponse(
            false,
            'It is available from 60 days before the Expiration Date'
          );
        }
        if (entity.state != ClientAgreementState.countersigned) {
          return new ObjectPermissionResponse(
            false,
            'Client Agreement is not in Countersigned state'
          );
        }
        if (
          entity.extension_request_state !=
          ClientAgreementExtensionRequestState.notSent
        ) {
          return new ObjectPermissionResponse(false, 'Request already sent');
        }
        if (entity.ca_version_to_sign === 1) {
          return new ObjectPermissionResponse(false, 'This is Legacy CA');
        }
        if (currentUser.is_superuser) {
          return new ObjectPermissionResponse(true);
        }
        if (
          currentUser.company !== entity.company &&
          currentUser.company !== entity.owner &&
          currentUser?.id !== entity.initiator &&
          currentUser?.id !== entity.created_by &&
          currentUser.company_info?.company_type !== CompanyType.rater
        ) {
          return new ObjectPermissionResponse(
            false,
            'You do not have permission to perform this action'
          );
        }
        return new ObjectPermissionResponse(true);
      })
    );
  }

  canUpdateDocusignStatus(
    clientAgreement: number | ClientAgreement
  ): Observable<ObjectPermissionResponse> {
    const clientAgreement$ = this.getClientAgreement(clientAgreement);
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: clientAgreement$,
    }).pipe(
      map(({ currentUser, entity }) => {
        if (currentUser.is_superuser || currentUser.company == entity.owner) {
          return new ObjectPermissionResponse(true);
        }
        return new ObjectPermissionResponse(false);
      })
    );
  }

  private getClientAgreement(
    clientAgreement: number | ClientAgreement
  ): Observable<ClientAgreement> {
    let clientAgreement$: Observable<ClientAgreement>;
    if (typeof clientAgreement === 'number') {
      clientAgreement$ = this.clientAgreementService.retrieve(clientAgreement, {
        ignoreStore: true,
      });
    } else {
      clientAgreement$ = of(clientAgreement);
    }
    return clientAgreement$;
  }
}
