import {
  WindowBackendDict,
  createWindowForm,
} from '@/data/simulation/models/enclosure/Window';
import { BaseValidator } from './base.validator';
import { ModelErrors, StateErrors } from '../state/shared/base.state';

export class WindowValidator extends BaseValidator {
  static validateForm(
    window: WindowBackendDict
  ): ModelErrors<WindowBackendDict> {
    const form = createWindowForm(window);
    return form.getFieldAndModelErros();
  }

  static validate(
    windows: WindowBackendDict[]
  ): StateErrors<WindowBackendDict> {
    const errors = {};
    windows.forEach(window => {
      errors[window.id] = this.validateForm(window);
    });
    return errors;
  }
}
