import { CompanyInfo } from '@/data/company/models';
import { UserInfo } from '@/data/core/models/user';
import { Geocode, GeocodeBrokeredResponse } from '@/data/geocoder/models';
import { CustomerDocumentInfo } from '@/data/filehandling/models';

export enum HIRLProductAgreementState {
  new = 'new',
  approved = 'approved',
  verified = 'verified',
  countersigned = 'countersigned',
  expired = 'expired',
}

export const HIRLProductAgreementStateLabelMapping: Record<
  HIRLProductAgreementState,
  any
> = {
  [HIRLProductAgreementState.new]: {
    label: 'New',
    badgeClass: 'badge-info',
  },
  [HIRLProductAgreementState.approved]: {
    label: 'Approved',
    badgeClass: 'badge-warning',
  },
  [HIRLProductAgreementState.verified]: {
    label: 'Routed for Counter-Signing',
    badgeClass: 'badge-warning',
  },
  [HIRLProductAgreementState.countersigned]: {
    label: 'Countersigned',
    badgeClass: 'badge-success',
  },
  [HIRLProductAgreementState.expired]: {
    label: 'Expired',
    badgeClass: 'badge-danger',
  },
};

export class HIRLProductAgreementDocusignInfo {
  envelope_id: string;
  status: string;
  status_message: string;
  recipient_email: string;
  delivered_time: string;
}

export class HIRLProductAgreement {
  id?: number;
  company?: number;
  company_info?: CompanyInfo;

  created_by?: number;
  created_by_info?: UserInfo;

  state?: HIRLProductAgreementState;

  mailing_geocode?: number;
  mailing_geocode_info?: Geocode;
  mailing_geocode_response?: number;
  mailing_geocode_response_info?: GeocodeBrokeredResponse;

  primary_contact_first_name?: string;
  primary_contact_last_name?: string;
  primary_contact_title?: string;
  primary_contact_email_address?: string;
  primary_contact_phone_number?: string;
  primary_contact_cell_number?: string;

  start_date?: string;
  expiration_date?: string;

  version_to_sign?: number;

  signed_agreement?: number;
  signed_agreement_info: CustomerDocumentInfo;

  certifying_document?: number;
  certifying_document_info: CustomerDocumentInfo;

  docusign_data?: HIRLProductAgreementDocusignInfo;
}

export class HIRLProductAgreementInfo {
  id?: number;

  state?: HIRLProductAgreementState;

  start_date?: string;
  expiration_date?: string;

  version_to_sign?: number;
}
