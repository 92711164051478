<mat-expansion-panel [expanded]="expanded">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <strong>Mechanical Equipment&nbsp;({{equipmentIds.length}})</strong>
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
        <div class="d-flex flex-column justify-content-center">
            <div>
                <div class="container labels-container m-0">
                    <div class="row">
                        <div class="col-3"><label>Name</label></div>
                        <div class="col-3"><label for="Location">Location</label></div>
                        <div class="col-4">
                            <div class="container w-100">
                                <div class="row d-flex justify-content-center">
                                    <label for="Percenrage served">Percentage served</label>
                                </div>
                                <div class="row">
                                    <div class="col-3 percent-label"><label for="Heating">Heating</label></div>
                                    <div class="col-3 percent-label"><label for="Cooling">Cooling</label></div>
                                    <div class="col-3 percent-label"><label for="Water heater">Water heater</label></div>
                                    <div class="col-3 percent-label"><label for="Dehumidifier">Dehumidifier</label></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-2"><label for="Equipment type">Equipment type</label></div>
                    </div>
                </div>
                <div class="items-container mt-2">
                    <ng-container *ngFor="let equipmentId of equipmentIds; index as i">
                        <app-mechanical-equipment [entityId]="equipmentId"></app-mechanical-equipment>
                    </ng-container>
                </div>
            </div>
            <button *appRequiresEditPermission mat-button (click)="addEquipmentDialog()">
                <mat-icon>add_circle</mat-icon>
            </button>
        </div>
    </ng-template>
</mat-expansion-panel>