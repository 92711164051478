import { CompanyInfo } from '@/data/company/models';
import { EquipmentSponsorStatus } from '@/data/equipment/models/equipment_sponsor_status';

export enum EquipmentType {
  blowerDoorFan = 1,
  laserTapeMeasure = 2,
  manometer = 3,
}

export const EquipmentTypeLabelMapping: Record<EquipmentType, string> = {
  [EquipmentType.manometer]: 'Manometer',
  [EquipmentType.blowerDoorFan]: 'Blower Door Fan',
  [EquipmentType.laserTapeMeasure]: 'Laser Tape Measure',
};

export enum EquipmentCalibrationCycle {
  annual = 3,
  everyTwoYears = 1,
  everyThreeYears = 2,
}

export const EquipmentCalibrationCycleLabelMapping: Record<
  EquipmentCalibrationCycle,
  string
> = {
  [EquipmentCalibrationCycle.annual]: 'Annual',
  [EquipmentCalibrationCycle.everyTwoYears]: 'Every 2 years',
  [EquipmentCalibrationCycle.everyThreeYears]: 'Every 3 years',
};

export class Equipment {
  id?: number;
  owner_company?: number;
  owner_company_info?: CompanyInfo;
  equipment_type?: EquipmentType;
  calibration_cycle?: EquipmentCalibrationCycle;
  brand?: string;
  equipment_model?: string;
  serial?: string;
  description?: string;
  calibration_date?: string;
  calibration_company?: string;
  notes?: string;
  assignees?: number[];
  assignees_info?: number[];
  equipment_sponsor_statuses_info?: EquipmentSponsorStatus[];
  calibration_documentation?: string;
}
