import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';

export interface IDropzoneDialogData {
  title: string;
  description?: string;
  label: string;
  multiple: boolean;
}

export class DropzoneDialogData implements IDropzoneDialogData {
  title = 'Upload Files';
  description = null;
  label = 'Drop documents here';
  multiple = true;
}

@Component({
  selector: 'app-dropzone-dialog',
  templateUrl: './dropzone-dialog.component.html',
  styleUrls: ['./dropzone-dialog.component.scss'],
})
export class DropzoneDialogComponent implements OnInit {
  files: File[] = [];

  constructor(
    public dialogRef: MatDialogRef<File[]>,
    public fb: UntypedFormBuilder,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DropzoneDialogData
  ) {}

  ngOnInit() {}

  onConfirm() {
    this.dialogRef.close(this.files);
  }

  onSelect($event: NgxDropzoneChangeEvent) {
    this.files.push(...$event.addedFiles);
  }

  onRemove(f: File) {
    this.files.splice(this.files.indexOf(f), 1);
  }
}
