import { ListRequestParams } from '@/core/schemes/request-params';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import { environment } from '../../../../environments/environment';
import { forkJoin, Observable } from 'rxjs';
import { CustomerDocument } from '@/data/filehandling/models';
import { Injectable } from '@angular/core';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, map } from 'rxjs/operators';

export class CustomerDocumentRequestParams extends ListRequestParams {
  is_public?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CustomerDocumentService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/customer_document/`;

  canCreate(): Observable<ObjectPermissionResponse> {
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
    }).pipe(
      map(({ currentUser }) => {
        return new ObjectPermissionResponse(true);
      })
    );
  }

  retrieve(
    customerDocumentId: number,
    options?: IDataServiceMethodOptions
  ): Observable<CustomerDocument> {
    const ignoreStore = options?.ignoreStore;
    const chain = this.http.get<CustomerDocument>(
      `${this.baseUrl}${customerDocumentId}`
    );

    return chain;
  }

  create(
    customerDocument: CustomerDocument,
    options?: IDataServiceMethodOptions
  ): Observable<CustomerDocument> {
    const ignoreStore = options?.ignoreStore;
    const payload = Object.assign(new CustomerDocument(), customerDocument);
    const chain = this.http.post<CustomerDocument>(`${this.baseUrl}`, payload);

    return chain;
  }

  update(
    customerDocumentId: number,
    customerDocument: CustomerDocument | FormData,
    options?: IDataServiceMethodOptions
  ) {
    const ignoreStore = options?.ignoreStore;
    const chain = this.http.patch<CustomerDocument>(
      `${this.baseUrl}${customerDocumentId}/`,
      customerDocument
    );

    return chain;
  }

  delete(customerDocumentId: number) {
    return this.http.delete(`${this.baseUrl}${customerDocumentId}/`);
  }
}
