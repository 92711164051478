import { ListRequestParams } from '@/core/schemes/request-params';
import { Injectable } from '@angular/core';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import { environment } from '../../../../environments/environment';
import { ServerResponse } from '@/core/schemes/server-response';
import { Observable } from 'rxjs';
import { SchedulingTaskType } from '@/data/scheduling/models';

export class SchedulingTaskTypeRequestParams extends ListRequestParams {}

@Injectable({
  providedIn: 'root',
})
export class SchedulingTaskTypeService extends BaseDataService {
  baseUrl = `${environment.AXIS_API_V3_ROOT}/scheduling_task_types/`;

  list(params?: SchedulingTaskTypeRequestParams) {
    return this.http.get<ServerResponse<SchedulingTaskType>>(
      `${this.baseUrl}`,
      {
        params: this.rollParams(params),
      }
    );
  }

  retrieve(
    taskTypeId: number,
    options?: IDataServiceMethodOptions
  ): Observable<SchedulingTaskType> {
    return this.http.get<SchedulingTaskType>(`${this.baseUrl}${taskTypeId}`);
  }
}
