import { Component, Input, OnInit } from '@angular/core';
import { Company, CompanyInfo } from '@/data/company/models';
import { componentCompanyTypeDataMap } from '@/modules/company/constants';

export enum CompanyDisplayComponentType {
  onlyName = 'onlyName',
  nameAndAddress = 'nameAndAddress',
  onlyNameAsLink = 'onlyNameAsLink',
  nameAndType = 'nameAndType',
  nameAndTypeAsLink = 'nameAndTypeAsLink',
}

@Component({
  selector: 'app-company-display',
  templateUrl: './company-display.component.html',
  styleUrls: ['./company-display.component.scss'],
})
export class CompanyDisplayComponent implements OnInit {
  @Input() displayType:
    | CompanyDisplayComponentType
    | keyof typeof CompanyDisplayComponentType =
    CompanyDisplayComponentType.onlyName;
  @Input() company?: Company | CompanyInfo;

  public componentCompanyTypeDataMap = componentCompanyTypeDataMap;
  public companyDisplayComponentType = CompanyDisplayComponentType;

  ngOnInit(): void {}
}
