<h2 mat-dialog-title>
  Create Home/Project using Simulation & Floorplan
</h2>
<ng-container *ngIf="initialized">
  <mat-dialog-content>
    <app-loading-overlay *ngIf="loading"></app-loading-overlay>
    <form [formGroup]="form" class="mb-2" *ngIf="form">
      <div class="row">
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>Energy Efficiency Program</mat-label>
            <mat-select
              formControlName="eep_program_type">
              <mat-option
                *ngFor="let item of SubmitToAxisRequestEEPProgramTypeLabels | defaultOrderKeyValue"
                [value]="item.key">
                {{ item.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row"
            *ngIf="form.get('eep_program_type').value === SubmitToAxisRequestEEPProgramType.energy_star_new_homes">
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>Permit Date</mat-label>
            <input matInput [matDatepicker]="permit_date_picker" formControlName="permit_date">
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix
                                    (click)="form.get('permit_date').setValue(null);">
              <mat-icon matDatepickerToggleIcon>clear</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker-toggle matSuffix [for]="permit_date_picker"></mat-datepicker-toggle>
            <mat-datepicker #permit_date_picker></mat-datepicker>

            <mat-error
              *ngIf="form.get('permit_date').hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row"
            *ngIf="form.get('eep_program_type').value === SubmitToAxisRequestEEPProgramType.energy_star_new_homes">
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>Address</mat-label>
            <app-home-address-input placeholder="Address" formControlName="address"
                                    [show-clear-btn]="true"></app-home-address-input>
            <mat-error
              *ngIf="form.get('address').hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row"
            *ngIf="form.get('eep_program_type').value === SubmitToAxisRequestEEPProgramType.energy_star_new_homes">
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>Client/Builder</mat-label>
            <app-generic-model-autocomplete formControlName="builder_organization_info" modelClassName="company"
                                            [filterParams]="{company_type: 'builder'}" [panelWidth]="640"
                                            [initialValueId]="project?.builder"></app-generic-model-autocomplete>
            <mat-error
              *ngIf="form.get('builder_organization_info').hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row"
            *ngIf="form.get('eep_program_type').value === SubmitToAxisRequestEEPProgramType.energy_star_new_homes">
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>Rater Organization</mat-label>
            <app-generic-model-autocomplete formControlName="rater_organization_info" modelClassName="company"
                                            [filterParams]="{company_type: 'rater'}" [panelWidth]="640"
                                            [initialValueId]="project?.rater_organization"></app-generic-model-autocomplete>
            <mat-error
              *ngIf="form.get('rater_organization_info').hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row"
            *ngIf="form.get('eep_program_type').value === SubmitToAxisRequestEEPProgramType.energy_star_new_homes">
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>Rater Of Record</mat-label>
            <app-generic-model-autocomplete formControlName="rater_of_record_info" modelClassName="user"
                                            [filterParams]="{company__company_type: 'rater'}" [panelWidth]="640"
                                            [initialValueId]="project.rater_of_record"></app-generic-model-autocomplete>
            <mat-error
              *ngIf="form.get('rater_of_record_info').hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <ng-container *ngIf="floorplan">
        <b>Floorplan Already Attached:</b>
        <app-floorplan-display [floorplan]="floorplan" displayType="onlyNameAsLink"></app-floorplan-display>
      </ng-container>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <div class="text-center">
      <i></i>
    </div>
    <div class="text-center">
      <button mat-button color="accent" mat-raised-button (click)="save($event)">Create</button>
      <button mat-button [mat-dialog-close]="null" color="primary" mat-raised-button>Cancel</button>
    </div>
  </mat-dialog-actions>
</ng-container>
