import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { first, takeUntil } from 'rxjs/operators';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { OkDialogComponent } from '@/shared/components/ok-dialog/ok-dialog.component';
import { MatDialog } from '@angular/material/dialog';

export enum PermissionMatButtonDisplayType {
  asButton = 'asButton',
  asLink = 'asLink',
}

export enum PermissionMatButtonResolveType {
  onInit = 'onInit',
  onClick = 'onClick',
}

@Component({
  selector: 'app-permission-mat-button',
  templateUrl: './permission-mat-button.component.html',
  styleUrls: ['./permission-mat-button.component.scss'],
})
export class PermissionMatButtonComponent implements OnInit, OnDestroy {
  @ContentChild('template') template: ElementRef;
  @HostBinding('style.display') public display = '';
  @Input() routerLink: string[] | string = [];
  @Input() displayType:
    | PermissionMatButtonDisplayType
    | keyof typeof PermissionMatButtonDisplayType =
    PermissionMatButtonDisplayType.asButton;

  @Input() resolveType:
    | PermissionMatButtonResolveType
    | keyof typeof PermissionMatButtonResolveType =
    PermissionMatButtonResolveType.onInit;

  @Input() hideOnFalse = false;
  @Output() action?: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  private permissionResponse$: Observable<ObjectPermissionResponse> = of(
    new ObjectPermissionResponse(false)
  );

  public permissionMatButtonDisplayType = PermissionMatButtonDisplayType;

  public editPermissionIsLoading: boolean;
  public editPermissionResponse: ObjectPermissionResponse;

  private subscription$: Subscription;

  @Input() set permissionResponse(value: Observable<ObjectPermissionResponse>) {
    if (value) {
      this.permissionResponse$ = value;
      if (this.resolveType === PermissionMatButtonResolveType.onInit) {
        this.resolveOnInit();
      }
    }
  }

  get permissionResponse(): Observable<ObjectPermissionResponse> {
    return this.permissionResponse$;
  }

  private componentDestroyed$ = new Subject();

  constructor(private dialog: MatDialog) {}

  ngOnInit() {
    if (this.resolveType === PermissionMatButtonResolveType.onInit) {
      this.editPermissionResponse = new ObjectPermissionResponse(false);
      this.resolveOnInit();
    } else if (this.resolveType === PermissionMatButtonResolveType.onClick) {
      this.editPermissionResponse = new ObjectPermissionResponse(true);
    }
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  resolvePermission() {
    return this.permissionResponse$.pipe(
      takeUntil(this.componentDestroyed$),
      first()
    );
  }

  resolveOnInit() {
    if (this.subscription$) {
      this.subscription$.unsubscribe();
    }

    this.editPermissionIsLoading = true;
    this.subscription$ = this.resolvePermission().subscribe(
      permissionResponse => {
        this.editPermissionIsLoading = false;
        this.editPermissionResponse = permissionResponse;
        setTimeout(() => {
          if (!permissionResponse.hasPermission && this.hideOnFalse) {
            this.display = 'none';
          } else {
            this.display = 'inline-block';
          }
        }, 0);
      }
    );
  }

  resolveOnClick($event: MouseEvent) {
    if (this.subscription$) {
      this.subscription$.unsubscribe();
    }

    this.editPermissionIsLoading = true;
    this.subscription$ = this.resolvePermission().subscribe(
      permissionResponse => {
        this.editPermissionIsLoading = false;
        this.editPermissionResponse = permissionResponse;

        if (this.editPermissionResponse.hasPermission) {
          if (this.action) {
            this.action.emit($event);
          }
        } else {
          const dialogRef = this.dialog.open(OkDialogComponent, {
            data: {
              title: 'You do not have permission',
              content: this.editPermissionResponse.message,
            },
          });

          dialogRef.afterClosed().subscribe(_ => {
            // restore state back
            this.editPermissionResponse = new ObjectPermissionResponse(true);
          });
        }
      }
    );
  }

  @HostListener('click', ['$event'])
  handleClick($event: MouseEvent): void {
    if (this.resolveType === PermissionMatButtonResolveType.onClick) {
      this.resolveOnClick($event);
    } else if (this.resolveType === PermissionMatButtonResolveType.onInit) {
      if (this.action && this.editPermissionResponse.hasPermission) {
        this.action.emit($event);
      }
    }
  }
}
