<div class="row">
  <div class="col-3">
    <div class="row">
      <div class="col-12">
        <form [formGroup]="filterFromGroup" class="card">
          <h5 class="card-header">Filters</h5>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Search</mat-label>
                  <app-search-input formControlName="search" placeholder="Type to search something"></app-search-input>
                </mat-form-field>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="col-9">
    <div class="row mb-2">
      <div class="col-12 text-right">
        <app-permission-mat-button [permissionResponse]="createPermissionResponse" (action)="create($event);">
          Create New
        </app-permission-mat-button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="row" *ngIf="isLoading">
          <div class="col-12">
            <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
          </div>
        </div>
        <table mat-table
               [dataSource]="rows"
               class="table"
               matSort
               [matSortActive]="storedParams.toTableSort().active"
               [matSortDirection]="storedParams.toTableSort().direction"
               (matSortChange)="onSortChange($event)">

          <ng-container matColumnDef="brand">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Brand/<br>Model/<br>Serial</th>
            <td mat-cell *matCellDef="let row">
              {{ row.brand }}<br>
              {{ row.equipment_model }}<br>
              {{ row.serial }}
            </td>
          </ng-container>

          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
            <td mat-cell *matCellDef="let row">{{ equipmentTypeLabelMapping[row.equipment_type] }}</td>
          </ng-container>

          <ng-container matColumnDef="assignees">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Assignees</th>
            <td mat-cell *matCellDef="let row">
              <ng-container *ngFor="let userInfo of row.assignees_info; let isLast=last">
                    <app-user-display [user]="userInfo" displayType="onlyNameAsLink"></app-user-display><br>
                </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="notes">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Notes</th>
            <td mat-cell *matCellDef="let row">
              {{ row.notes }}
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
            <td mat-cell *matCellDef="let row">
              <span *ngFor="let sponsorStatus of row.equipment_sponsor_statuses_info; let isLast=last">
                <span class="badge badge-primary" [matTooltip]="sponsorStatus?.company_info?.name">{{ sponsorStatus.state }}</span>{{ isLast ? '' : ' '}}
                <a href="#" (click)="copy($event, row);" matTooltip="Copy Equipment" *ngIf="sponsorStatus.state === 'expired'">
                  <fa-icon [icon]="['fas', 'copy']"></fa-icon>
                </a>
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="calibration_date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Calibration Date</th>
            <td mat-cell *matCellDef="let row">
              {{ row.calibration_date | date: 'MM/dd/yyyy' }}<br>
              <span matTooltip="Calibration Cycle">({{ equipmentCalibrationCycleLabelMapping[row.calibration_cycle] }})</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
            <td mat-cell *matCellDef="let row">{{ row.description }}</td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let row">
              <app-permission-mat-button [permissionResponse]="permissions[row.id]['canEdit']" displayType="asLink" (action)="edit($event, row);" class="mr-2">
                <fa-icon [icon]="['fas', 'edit']"></fa-icon>
              </app-permission-mat-button>
              <app-permission-mat-button [permissionResponse]="permissions[row.id]['canDelete']" displayType="asLink" (action)="delete($event, row);" class="mr-2">
                <fa-icon [icon]="['fas', 'trash']"></fa-icon>
              </app-permission-mat-button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [length]="rowsCount"
                       [pageIndex]="$any(storedParams.page) - 1"
                       [pageSize]="storedParams.page_size"
                       (page)="onPaginateChange($event)"
                       aria-label="Select page">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
