import { createReducer, on } from '@ngrx/store';
import * as RoofActions from './actions';
import { RoofBackendDict } from '@/data/simulation/models/enclosure/Roof';
import { BaseState, sharedInitialState } from '../shared/base.state';
import { addErrorCount, addStateErrorCount } from '../helper';

export type RoofState = BaseState<RoofBackendDict>;

export const initialState: RoofState = sharedInitialState;

export const reducer = createReducer(
  initialState,
  on(RoofActions.loadRoofsSuccess, (currentState, payload) => {
    const entities = payload.roofs.reduce(
      (acc, roof) => ({ ...acc, [roof.id]: roof }),
      {}
    );

    const loadingStatus = payload.roofs.reduce(
      (acc, roof) => ({ ...acc, [roof.id]: false }),
      {}
    );

    return {
      entities: { ...currentState.entities, ...entities },
      loading: { ...currentState.loading, ...loadingStatus },
      errors: { ...currentState.errors, ...addStateErrorCount(payload.errors) },
    };
  }),

  on(RoofActions.removeRoofSuccess, (currentState, { id }) => {
    const { [id]: removed, ...entities } = currentState.entities;
    const { [id]: removedLoading, ...loading } = currentState.loading;
    const { [id]: removedErrors, ...errors } = currentState.errors;

    return {
      entities,
      loading,
      errors,
    };
  }),

  on(RoofActions.updateRoof, (state, { roofChanges }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [roofChanges.id]: true,
      },
    };
  }),

  on(RoofActions.removeRoof, (state, { roof }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [roof.id]: true,
      },
    };
  }),

  on(
    RoofActions.updateRoofFailure,
    RoofActions.removeRoofFailure,
    (state, { id }) => {
      return {
        ...state,
        loading: {
          ...state.loading,
          [id]: false,
        },
      };
    }
  ),

  on(RoofActions.updateRoofErrors, (state, { id, errors }) => {
    return {
      ...state,
      errors: {
        ...state.errors,
        [id]: errors,
      },
    };
  }),

  on(RoofActions.updateRoofSuccess, (state, { roof, errors }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        [roof.id]: roof,
      },
      loading: {
        ...state.loading,
        [roof.id]: false,
      },
      errors: {
        ...state.errors,
        [roof.id]: addErrorCount(errors),
      },
    };
  }),

  on(RoofActions.setRoofType, (state, { roofId, roofTypeId }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        [roofId]: {
          ...state.entities[roofId],
          type: roofTypeId,
        },
      },
    };
  })
);
