import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@/shared/shared.module';

import { ProjectTrackerListComponentComponent } from './components/project-tracker-list-component/project-tracker-list-component.component';
import { EtoAccountChangeDialogComponent } from './components/eto-account-change-dialog/eto-account-change-dialog.component';

@NgModule({
  declarations: [
    ProjectTrackerListComponentComponent,
    EtoAccountChangeDialogComponent,
  ],
  imports: [CommonModule, SharedModule],
  exports: [],
})
export class CustomerETOModule {}
