import { ValidationResult } from '@/data/simulation/models/reports/ValidationResult';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-backend-validation-response-dialog',
  templateUrl: './backend-validation-response-dialog.component.html',
  styleUrls: ['./backend-validation-response-dialog.component.scss'],
})
export class BackendValidationResponseDialogComponent implements OnInit {
  errors = [];
  warnings = [];

  constructor(
    public dialogRef: MatDialogRef<BackendValidationResponseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public result: ValidationResult
  ) {}

  ngOnInit(): void {
    this.errors = Object.values(this.result.flatResults);
  }
}
