import { ListRequestParams } from '@/core/schemes/request-params';
import { Injectable } from '@angular/core';
import { BaseDataService } from '@/core/services/base-data-service';
import { ServerResponse } from '@/core/schemes/server-response';
import {
  CreateQANoteForMultipleQAStatuses,
  QANote,
} from '@/data/qa/models/qa-status';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

export class QaNoteRequestParams extends ListRequestParams {}

@Injectable({
  providedIn: 'root',
})
export class QaNoteService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/qa_notes/`;

  create_note_for_multiple_qa_statuses(
    data: CreateQANoteForMultipleQAStatuses | FormData
  ): Observable<ServerResponse<QANote>> {
    return this.http.post<ServerResponse<QANote>>(
      `${this.baseUrl}create_note_for_multiple_qa_statuses/`,
      data
    );
  }
}
