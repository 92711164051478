import {
  FrameFloorBackendDict,
  DetailedFrameFloorBackendDict,
} from '@/data/simulation/models/enclosure/FrameFloor';
import { createAction, props } from '@ngrx/store';
import { ModelErrors, StateErrors } from '../shared/base.state';

export const loadDetailedFrameFloors = createAction(
  '[Simulation API] Load Detailed FrameFloors',
  props<{ detailedFrameFloors: DetailedFrameFloorBackendDict[] }>()
);

export const loadFrameFloorsSuccess = createAction(
  '[FrameFloor API] Load FrameFloors Success',
  props<{
    frameFloors: FrameFloorBackendDict[];
    errors: StateErrors<FrameFloorBackendDict>;
  }>()
);

export const updateFrameFloor = createAction(
  '[FrameFloor UI] Update FrameFloor',
  props<{ frameFloorChanges: Partial<FrameFloorBackendDict> }>()
);

export const updateFrameFloorSuccess = createAction(
  '[FrameFloor API] Update FrameFloor Success',
  props<{
    frameFloor: FrameFloorBackendDict;
    errors: ModelErrors<FrameFloorBackendDict>;
  }>()
);

export const updateFrameFloorFailure = createAction(
  '[FrameFloor API] Update FrameFloor Failure',
  props<{ id: number }>()
);

export const removeFrameFloor = createAction(
  '[FrameFloor UI] Remove FrameFloor',
  props<{ frameFloor: FrameFloorBackendDict }>()
);

export const removeFrameFloorSuccess = createAction(
  '[FrameFloor API] Remove FrameFloor Success',
  props<{ id: number }>()
);

export const removeFrameFloorFailure = createAction(
  '[FrameFloor API] Remove FrameFloor Failure',
  props<{ id: number }>()
);

export const updateFrameFloorErrors = createAction(
  '[FrameFloor UI] Update FrameFloor Errors',
  props<{ id: number; errors: any }>()
);

export const setFrameFloorType = createAction(
  '[FrameFloorType API] Set FrameFloorType',
  props<{ floorId: number; floorTypeId: number | null }>()
);

export const setFrameFloorTypeSuccess = createAction(
  '[FrameFloorType API] Set FrameFloorType null',
  props<{ floorId: number; floorTypeId: number | null }>()
);
