import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { selectAnnotationViewSet } from '@/state/selectors/annotation/annotation.selector';
import { Annotation } from '@/data/annotation/models';
import { STATE_BACKEND_NAME } from '../../state.registry';
import { MatDialog } from '@angular/material/dialog';
import {
  AnnotationEditDialogComponent,
  AnnotationEditDialogData,
} from '@/modules/annotation/components/annotation-edit-dialog/annotation-edit-dialog.component';
import {
  addAnnotation,
  deleteAnnotation,
  updateAnnotation,
} from '@/state/actions/annotation/annotation.actions';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { selectEntityName } from '../../state/simulation.selectors';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss'],
})
export class NoteComponent implements OnInit {
  @Input() entityId: number;
  @Input() entityName: string;

  componentDestroyed$ = new Subject();
  notes: { key: Annotation };
  loading: boolean;
  control: FormControl = new FormControl();
  edit = false;
  notesLength = -1;
  title = '';

  constructor(public store: Store, public dialog: MatDialog) {}

  ngOnInit(): void {
    const id = STATE_BACKEND_NAME[this.entityName].replaceAll('_', '');

    this.store
      .select(selectAnnotationViewSet(id, this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(({ data, loading }) => {
        this.notes = data;
        this.notesLength = Object.keys(this.notes || {}).length;
      });
    this.store
      .select(selectEntityName(this.entityName, this.entityId))
      .subscribe(name => {
        this.title = name;
      });
  }

  cancel(e) {
    this.edit = false;
    e.preventDefault();
    e.stopPropagation();
  }

  addNote($event: MouseEvent) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(AnnotationEditDialogComponent, {
      width: '45%',
      disableClose: true,
      autoFocus: false,
      data: {
        title: 'Note',
        annotation: {
          type: 61,
        },
      } as AnnotationEditDialogData,
    });

    dialogRef.afterClosed().subscribe((result?: Annotation) => {
      if (!result) {
        return;
      }

      this.store.dispatch(
        addAnnotation({
          annotation: {
            ...result,
          },
          content_type: STATE_BACKEND_NAME[this.entityName],
          object_id: this.entityId,
        })
      );
    });
  }

  deleteNote(annotationId: number) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '50%',
      data: {
        title: `Do you want to delete the note?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (result) {
          this.store.dispatch(
            deleteAnnotation({
              content_type: STATE_BACKEND_NAME[this.entityName],
              object_id: this.entityId,
              annotationId,
            })
          );
        }
      });
  }

  editNote(annotation: Annotation) {
    const dialogRef = this.dialog.open(AnnotationEditDialogComponent, {
      width: '45%',
      disableClose: true,
      autoFocus: false,
      data: {
        title: 'Note',
        retrieve: false,
        commit: false,
        isEditForm: true,
        annotation: annotation,
      } as AnnotationEditDialogData,
    });

    dialogRef.afterClosed().subscribe((result?: Annotation) => {
      if (!result) {
        return;
      }

      this.store.dispatch(
        updateAnnotation({
          annotation: {
            id: annotation.id,
            ...result,
          },
          contentType: STATE_BACKEND_NAME[this.entityName],
          objectId: this.entityId,
        })
      );
    });
  }

  viewNoteDetail($event, arg0: any) {
    $event.preventDefault();
    $event.stopPropagation();
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
