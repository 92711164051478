import { Directive, Input } from '@angular/core';
import { MatMenuPanel, MatMenuTrigger } from '@angular/material/menu';

/*
 * Allow disabling mat menu for disabled buttons
 * */
@Directive({
  selector: `[appMenuTriggerFor]`,
  exportAs: 'appMenuTrigger',
})
export class appMenuTriggerFor extends MatMenuTrigger {
  _handleClick(event: MouseEvent): void {
    const parentElement = (event.target as Element).parentElement;
    if (parentElement) {
      if (
        parentElement.tagName === 'BUTTON' &&
        parentElement.classList.contains('mat-button-disabled')
      ) {
        return;
      }
    }
    this.openMenu();
  }

  @Input('appMenuTriggerFor')
  get menu() {
    return super.menu;
  }
  set menu(menu: MatMenuPanel) {
    super.menu = menu;
  }
}
