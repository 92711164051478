import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import {
  AggregateESGReport,
  CustomerHIRLAggregateDashboard,
  CustomerHIRLBulkCertificateEEPProgramHomeStatusDownload,
  CustomerHIRLBulkCertificateEEPProgramHomeStatusList,
  CustomerHIRLCertificateLookupList,
  CustomerHIRLCertificationMetric,
  CustomerHIRLCertifiedProjectsByMonthMetrics,
  CustomerHIRLCertifiedUnitsByMonthMetrics,
  CustomerHIRLTopClientStats,
  CustomerHIRLTopCompanyStats,
  CustomerHIRLTopStatesStats,
  CustomerHIRLTopVerifierStats,
  EEPProgramHomeStatus,
  EEPProgramHomeStatusProjectReportDownload,
  ESGReportList,
  ProgramEligibilityList,
  ProjectCertification,
  ProjectTrackerBulkSubmit,
  ProviderDashboardList,
  ProviderDashboardProgramsReadyForCertification,
  ProviderDashboardProgramsReadyForFTSubmit,
  PSRReportList,
} from '@/data/home/models';
import { BaseDataService } from '@/core/services/base-data-service';
import {
  ListRequestParams,
  RequestParams,
} from '@/core/schemes/request-params';
import { ServerResponse } from '@/core/schemes/server-response';
import { Observable } from 'rxjs';
import { AsynchronousProcessedDocument } from '@/data/filehandling/models';
import {
  ETORegion,
  FastTrackSubmissionSubmitStatus,
} from '@/data/customer-eto/models';
import { HIRLProjectAppealsProjectType } from '@/data/customer-hirl/models';

export enum CustomerHirlProjectRegistrationFinanceType {
  building_will_include_non_residential_space = 'building_will_include_non_residential_space',
  seeking_hud_mortgage_insurance_premium_reduction = 'seeking_hud_mortgage_insurance_premium_reduction',
  seeking_hud_green_and_resilient_retrofit_program = 'seeking_hud_green_and_resilient_retrofit_program',
  seeking_fannie_mae_green_financing = 'seeking_fannie_mae_green_financing',
  intended_to_be_affordable_housing = 'intended_to_be_affordable_housing',
}

export const CustomerHirlProjectRegistrationFinanceTypeLabelMapping: Record<
  CustomerHirlProjectRegistrationFinanceType,
  string
> = {
  building_will_include_non_residential_space:
    'Building(s) will include non-residential space (retail/commercial) ?',
  seeking_hud_mortgage_insurance_premium_reduction:
    'Seeking HUD Mortgage Insurance Premium Reduction?',
  seeking_hud_green_and_resilient_retrofit_program:
    'Seeking HUD Green and Resilient Retrofit Program?',
  seeking_fannie_mae_green_financing: 'Seeking Fannie Mae Green financing?',
  intended_to_be_affordable_housing: 'Intended to be affordable housing?',
};

export enum EEPProgramHomeStatusRFastTrackSubmitStatus {
  readyForSubmit = 'ready_for_submit',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  RETRY = 'RETRY',
}

export const EEPProgramHomeStatusRFastTrackSubmitStatusLabelMapping: Record<
  EEPProgramHomeStatusRFastTrackSubmitStatus,
  string
> = {
  [EEPProgramHomeStatusRFastTrackSubmitStatus.readyForSubmit]:
    'Ready For Submit',
  [FastTrackSubmissionSubmitStatus.PENDING]: 'Pending',
  [FastTrackSubmissionSubmitStatus.SUCCESS]: 'Success',
  [FastTrackSubmissionSubmitStatus.FAILURE]: 'Failed',
  [FastTrackSubmissionSubmitStatus.RETRY]: 'Retry',
};

export class EEPProgramHomeStatusRequestParams extends ListRequestParams {
  id?: string | number;
  company_access?: number | number[];
  eep_program?: number | number[];
  eep_program__slug?: string | string[];
  customer_hirl_project__registration?: string;
  customer_hirl_project__billing_state?: string | string[];
  customer_hirl_project__registration__is_build_to_rent?: boolean;
  customer_hirl_project__is_require_wri_certification?: boolean;
  customer_hirl_project__is_require_water_sense_certification?: boolean;
  customer_hirl_project__is_hud_project?: boolean;
  customer_hirl_project__appeals_project?:
    | HIRLProjectAppealsProjectType
    | HIRLProjectAppealsProjectType[];
  customer_hirl_project__sampling?: string | string[];
  customer_hirl_rough_verifier?: number | number[];
  customer_hirl_final_verifier?: number | number[];
  customer_hirl_project__registration__financing_type?: string | string[];
  customer_hirl_annotations__energy_path?: string | string[];
  customer_hirl_project__green_energy_badges?: number | number[];
  customer_hirl_story_count_gte?: number;
  customer_hirl_story_count_lte?: number;

  rater_of_record?: number | number[];
  energy_modeler?: number | number[];
  field_inspector?: number | number[];

  state?: string | string[];
  created_date__gte?: string;
  created_date__lte?: string;
  certification_date__gte?: string;
  certification_date__lte?: string;

  home__created_date__gte?: string;
  home__created_date__lte?: string;

  builder_organization?: number | number[];
  rater_organization?: number | number[];
  utility_organization?: number | number[];
  eep_organization?: number | number[];
  hvac_organization?: number | number[];
  qa_organization?: number | number[];
  provider_organization?: number | number[];

  home__subdivision?: number | number[];
  home__city?: number | number[];
  home__state?: string | string[];
  home__city__country?: number | number[];
  home__city__county?: number | number[];
  home__metro?: number | number[];

  home__task__task_type?: number | number[];
  home__task__assignees?: number | number[];

  qa_status__state?: string | string[];
  qastatus__observation__observation_type?: number | number[];
  qastatus__requirement__type?: string | string[];
  qa_status__qa_designee?: number | number[];
  qastatus__hirl_badges_awarded?: number | number[];

  customer_eto_region?: ETORegion | ETORegion[];
  customer_eto_fasttracksubmission__submit_status?:
    | EEPProgramHomeStatusRFastTrackSubmitStatus
    | EEPProgramHomeStatusRFastTrackSubmitStatus[];

  floorplan__simulation?: number | number[];
}

export class HIRLProgramStatusRequestParams extends RequestParams {
  eep_program__slug?: string | string[];
  customer_hirl_project__billing_state?: string | string[];
  state?: string | string[];
  home__city__county__state?: string | string[];
  created_date__gte?: string;
  created_date__lte?: string;
  certification_date__gte?: string;
  certification_date__lte?: string;
  certification_level?: string | string[];
  hirl_additional_filter?: string | string[];
}

export class HIRLTopStatesStatsRequestParams extends RequestParams {
  eep_program__slug?: string | string[];
  customer_hirl_project__billing_state?: string | string[];
  state?: string | string[];
  home__city__county__state?: string | string[];
  created_date__gte?: string;
  created_date__lte?: string;
  certification_date__gte?: string;
  certification_date__lte?: string;
}

export class CustomerHIRLCertifiedProjectsByMonthMetricsRequestParams extends RequestParams {
  years?: string[];
  eep_program__slug?: string[];
  customer_hirl_project__registration__is_build_to_rent?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class EEPProgramHomeStatusService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/home_project_statuses/`;

  list(
    params?: EEPProgramHomeStatusRequestParams
  ): Observable<ServerResponse<EEPProgramHomeStatus>> {
    return this.http.get<ServerResponse<EEPProgramHomeStatus>>(
      `${this.baseUrl}`,
      {
        params: this.rollParams(params),
      }
    );
  }

  hirl_program_stats(params?: HIRLProgramStatusRequestParams) {
    return this.http.get<CustomerHIRLCertificationMetric>(
      `${this.baseUrl}hirl_program_stats/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  provider_dashboard_certification_list(
    params?: EEPProgramHomeStatusRequestParams
  ): Observable<ServerResponse<ProviderDashboardList>> {
    return this.http.get<ServerResponse<ProviderDashboardList>>(
      `${this.baseUrl}provider_dashboard_certification_list/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  provider_dashboard_programs_ready_for_certification(
    params?: EEPProgramHomeStatusRequestParams
  ): Observable<ProviderDashboardProgramsReadyForCertification[]> {
    return this.http.get<ProviderDashboardProgramsReadyForCertification[]>(
      `${this.baseUrl}provider_dashboard_programs_ready_for_certification/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  provider_dashboard_programs_ready_for_ft_submit(
    params?: EEPProgramHomeStatusRequestParams
  ): Observable<ProviderDashboardProgramsReadyForFTSubmit[]> {
    return this.http.get<ProviderDashboardProgramsReadyForFTSubmit[]>(
      `${this.baseUrl}provider_dashboard_programs_ready_for_ft_submit/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  esg_report_list(params?: EEPProgramHomeStatusRequestParams) {
    return this.http.get<ServerResponse<ESGReportList>>(
      `${this.baseUrl}esg_report_list/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  aggregate_esg_report(params?: EEPProgramHomeStatusRequestParams) {
    return this.http.get<AggregateESGReport>(
      `${this.baseUrl}aggregate_esg_report/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  program_eligibility_list(params?: EEPProgramHomeStatusRequestParams) {
    return this.http.get<ServerResponse<ProgramEligibilityList>>(
      `${this.baseUrl}program_eligibility_list/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  esg_project_report_download(
    data: EEPProgramHomeStatusProjectReportDownload,
    params?: EEPProgramHomeStatusRequestParams
  ): Observable<AsynchronousProcessedDocument> {
    return this.http.post<AsynchronousProcessedDocument>(
      `${this.baseUrl}esg_project_report_download/`,
      data,
      {
        params: this.rollParams(params),
      }
    );
  }

  program_eligibility_report_download(
    data: EEPProgramHomeStatusProjectReportDownload,
    params?: EEPProgramHomeStatusRequestParams
  ): Observable<AsynchronousProcessedDocument> {
    return this.http.post<AsynchronousProcessedDocument>(
      `${this.baseUrl}program_eligibility_report_download/`,
      data,
      {
        params: this.rollParams(params),
      }
    );
  }

  psr_project_report_download(
    data: EEPProgramHomeStatusProjectReportDownload,
    params?: EEPProgramHomeStatusRequestParams
  ): Observable<AsynchronousProcessedDocument> {
    return this.http.post<AsynchronousProcessedDocument>(
      `${this.baseUrl}psr_project_report_download/`,
      data,
      {
        params: this.rollParams(params),
      }
    );
  }

  psr_report_list(params?: EEPProgramHomeStatusRequestParams) {
    return this.http.get<ServerResponse<PSRReportList>>(
      `${this.baseUrl}psr_report_list/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  hirl_aggregate_dashboard_stats(params?: HIRLProgramStatusRequestParams) {
    return this.http.get<CustomerHIRLAggregateDashboard>(
      `${this.baseUrl}hirl_aggregate_dashboard_stats/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  customer_hirl_top_states_stats(params?: HIRLTopStatesStatsRequestParams) {
    return this.http.get<CustomerHIRLTopStatesStats>(
      `${this.baseUrl}customer_hirl_top_states_stats/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  customer_hirl_top_client_stats(params?: HIRLTopStatesStatsRequestParams) {
    return this.http.get<CustomerHIRLTopClientStats>(
      `${this.baseUrl}customer_hirl_top_client_stats/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  customer_hirl_top_verifier_stats(params?: HIRLTopStatesStatsRequestParams) {
    return this.http.get<CustomerHIRLTopVerifierStats>(
      `${this.baseUrl}customer_hirl_top_verifier_stats/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  customer_hirl_top_company_stats(params?: HIRLTopStatesStatsRequestParams) {
    return this.http.get<CustomerHIRLTopCompanyStats>(
      `${this.baseUrl}customer_hirl_top_company_stats/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  customer_hirl_certified_projects_by_month_metrics(
    params?: CustomerHIRLCertifiedProjectsByMonthMetricsRequestParams
  ): Observable<CustomerHIRLCertifiedProjectsByMonthMetrics[]> {
    return this.http.get<CustomerHIRLCertifiedProjectsByMonthMetrics[]>(
      `${this.baseUrl}customer_hirl_certified_projects_by_month_metrics/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  customer_hirl_certified_units_by_month_metrics(
    params?: CustomerHIRLCertifiedProjectsByMonthMetricsRequestParams
  ): Observable<CustomerHIRLCertifiedUnitsByMonthMetrics[]> {
    return this.http.get<CustomerHIRLCertifiedUnitsByMonthMetrics[]>(
      `${this.baseUrl}customer_hirl_certified_units_by_month_metrics/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  customer_hirl_bulk_certificate_list(
    params?: EEPProgramHomeStatusRequestParams
  ): Observable<
    ServerResponse<CustomerHIRLBulkCertificateEEPProgramHomeStatusList>
  > {
    return this.http.get<
      ServerResponse<CustomerHIRLBulkCertificateEEPProgramHomeStatusList>
    >(`${this.baseUrl}customer_hirl_bulk_certificate_list/`, {
      params: this.rollParams(params),
    });
  }

  customer_hirl_certificate_lookup_list(
    params?: EEPProgramHomeStatusRequestParams
  ) {
    return this.http.get<ServerResponse<CustomerHIRLCertificateLookupList>>(
      `${this.baseUrl}customer_hirl_certificate_lookup_list`,
      {
        params: this.rollParams(params),
      }
    );
  }

  customer_hirl_certificate_multiple_download(
    data: CustomerHIRLBulkCertificateEEPProgramHomeStatusDownload,
    params?: EEPProgramHomeStatusRequestParams
  ): Observable<AsynchronousProcessedDocument> {
    return this.http.post<AsynchronousProcessedDocument>(
      `${this.baseUrl}customer_hirl_certificate_multiple_download/`,
      data,
      {
        params: this.rollParams(params),
      }
    );
  }

  certify(
    data: ProjectCertification,
    params?: EEPProgramHomeStatusRequestParams
  ): Observable<AsynchronousProcessedDocument> {
    return this.http.post<AsynchronousProcessedDocument>(
      `${this.baseUrl}certify/`,
      data,
      {
        params: this.rollParams(params),
      }
    );
  }

  project_tracker_submit(
    data: ProjectTrackerBulkSubmit,
    params?: EEPProgramHomeStatusRequestParams
  ): Observable<AsynchronousProcessedDocument> {
    return this.http.post<AsynchronousProcessedDocument>(
      `${this.baseUrl}project_tracker_submit/`,
      data,
      {
        params: this.rollParams(params),
      }
    );
  }
}
