<div class="container" *ngIf="initialized">
    <div class="row">
        <div class="col-12">
            <h3>
                <button mat-raised-button color="accent" appBackButton [fallbackUrl]="'/hi/project_registrations/'">
                    <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
                </button>
                Add New Accessory Dwelling Unit Structure
            </h3>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <hr>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <form [formGroup]="formGroup">
                <div class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline" class="w-100 mb-2">
                            <mat-label>Address</mat-label>
                            <app-home-address-input placeholder="Address" formControlName="address" required [show-clear-btn]="true"></app-home-address-input>
                            <mat-error
                                    *ngIf="formGroup.get('address').hasError('required')">
                                This field is required
                            </mat-error>
                            <mat-error
                                    *ngIf="formGroup.get('address').hasError('home_with_this_address_already_exists')">
                                Home with this address already exists
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline" class="w-100 mb-2">
                            <mat-label>Accessory Dwelling Unit Structure Description</mat-label>
                            <textarea matInput placeholder="Accessory Dwelling Unit Structure Description"
                                      formControlName="accessory_dwelling_unit_description"></textarea>
                            <mat-error *ngIf="formGroup.get('accessory_dwelling_unit_description').hasError('required')">
                                Accessory Dwelling Unit Structure Description is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </form>

            <div class="row">
                <div class="col-12 text-center">
                    <button type="button" mat-raised-button color="accent"
                            (click)="handleCreate($event)" [disabled]="formGroup.pending">
                        Create
                        <fa-icon [icon]="['fas', 'spinner']" [spin]="true" *ngIf="formGroup.pending"></fa-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
