import { selectLightsErrorsCount } from '@/modules/simulation/state/lights/selectors';
import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-lights-errors',
  templateUrl: './lights-errors.component.html',
  styleUrls: ['./lights-errors.component.scss'],
})
export class LightsErrorsComponent {
  @Input() entityId: number;
  componentDestroyed$: Subject<boolean> = new Subject();
  enable = false;
  totalErrorCount = 0;
  totalWarningCount = 0;

  constructor(public store: Store) {}

  ngOnInit(): void {
    this.store
      .select(selectLightsErrorsCount(this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(count => {
        this.totalErrorCount = count.errorCount;
        this.totalWarningCount = count.warningCount;
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
