<h2 mat-dialog-title>
    {{ data.title }}
</h2>
<mat-dialog-content>
    <div [innerHTML]="data.content"></div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-raised-button color="accent" [mat-dialog-close]="true" cdkFocusInitial>
    OK
  </button>
</mat-dialog-actions>
