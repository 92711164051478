<mat-expansion-panel [expanded]="expanded">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <strong>Ambience (Lights & fans)</strong>
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
        <div class="row">
            <div class="col-8">
                <app-lights [entityId]="lightsId"></app-lights>
            </div>
            <div class="col-4">
                <app-fans [entityId]="appliancesId"></app-fans>
            </div>
        </div>
    </ng-template>
</mat-expansion-panel>