import { RoofTypeBackendDict } from '@/data/simulation/models/enclosure/RoofType';
import { createAction, props } from '@ngrx/store';
import { StateErrors } from '../shared/base.state';

export const loadDetailedRoofTypes = createAction(
  '[RoofType] Load Detailed RoofTypes',
  props<{ roofTypes: RoofTypeBackendDict[] }>()
);

export const loadRoofTypesSuccess = createAction(
  '[RoofType] Load RoofTypes Success',
  props<{
    roofTypes: RoofTypeBackendDict[];
    errors: StateErrors<RoofTypeBackendDict>;
  }>()
);

export const addRoofType = createAction(
  '[RoofType UI] Add RoofType',
  props<{ roofId: number; roofType: RoofTypeBackendDict }>()
);

export const removeRoofType = createAction(
  '[Roof UI] Remove RoofType',
  props<{ roofId: number; roofTypeId: number }>()
);

export const removeRoofTypeSuccess = createAction(
  '[RoofType API] Remove RoofType Success',
  props<{ id: number }>()
);

export const updateRoofType = createAction(
  '[RoofType UI] Update RoofType',
  props<{ id: number; roofTypeChanges: Partial<RoofTypeBackendDict> }>()
);

export const updateRoofTypeFailure = createAction(
  '[RoofType API] Update RoofType Failure',
  props<{ id: number }>()
);
