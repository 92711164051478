import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  first,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import {
  CommunityRequestParams,
  CommunityService,
} from '@/data/community/services/community.service';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { Community, CommunityFlatList } from '@/data/community/models';
import { EMPTY, forkJoin, Observable, of, Subject } from 'rxjs';
import { toggleLoading } from '@/state/actions/app.actions';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { Relationship } from '@/data/relationship/models';
import { ServerResponse } from '@/core/schemes/server-response';
import { getInfoUser } from '@/state/selectors/info.selector';
import { User } from '@/data/core/models/user';
import { AppFieldValidatorsService } from '@/core/services/field-validators.service';
import { CityService } from '@/data/geographic/services/city.service';
import { objectValidator } from '@/core/validators';

export class CommunityChangeDialogData {
  community?: Community;
  commit = true;
  retrieve = true;
}

@Component({
  selector: 'app-community-change-dialog',
  templateUrl: './community-change-dialog.component.html',
  styleUrls: ['./community-change-dialog.component.scss'],
})
export class CommunityChangeDialogComponent implements OnInit, OnDestroy {
  public initialized: boolean;
  public isEditForm = false;
  public entity?: Community;
  public commit = true;
  public retrieve = true;
  public form: FormGroup;
  // only for Create mode
  public checkedForExistingCommunity = false;
  public newCommunityName = '';
  public existingCommunityControl = new FormControl();
  public existingCommunities$: Observable<ServerResponse<CommunityFlatList>>;

  private componentDestroyed$ = new Subject();
  private currentUser: User;
  selectedCity: any;

  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<CommunityChangeDialogComponent>,
    private fb: FormBuilder,
    public cityService: CityService,
    private communityService: CommunityService,
    private dialog: MatDialog,
    private uiHelperService: UIHelperService,
    public appFieldValidatorsService: AppFieldValidatorsService,
    @Inject(MAT_DIALOG_DATA) public data: CommunityChangeDialogData
  ) {
    if (data?.community) {
      this.entity = Object.assign({}, data?.community);
    }
    this.commit = data?.commit || true;

    if (this.entity?.id) {
      this.isEditForm = true;
      this.checkedForExistingCommunity = true;
    }
  }

  ngOnInit() {
    const sources = {
      currentUser: this.store.select(getInfoUser).pipe(first()),
      community: of(this.entity),
    };

    this.store.dispatch(toggleLoading({ payload: true }));

    if (this.isEditForm && this.retrieve) {
      sources.community = this.communityService
        .retrieve(this.entity.id, { ignoreStore: true })
        .pipe(takeUntil(this.componentDestroyed$), first());
    }

    forkJoin(sources)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: ({ currentUser, community }) => {
          this.store.dispatch(toggleLoading({ payload: false }));

          this.currentUser = currentUser;
          this.entity = community;
          this.setupForm();
          this.initialized = true;
          if (this.isEditForm) {
            this.hydrateForm();
          }
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  private setupForm() {
    this.form = this.fb.group({
      name: [null, [Validators.required]],
      city_info: ['', [Validators.required, objectValidator()]],
      website_url: ['', this.appFieldValidatorsService.websiteURLValidator()],
      cross_roads: [null],
    });

    if (!this.isEditForm) {
      this.existingCommunities$ =
        this.existingCommunityControl.valueChanges.pipe(
          takeUntil(this.componentDestroyed$),
          startWith(''),
          debounceTime(100),
          distinctUntilChanged(),
          tap(value => (this.newCommunityName = value)),
          tap(_ => this.store.dispatch(toggleLoading({ payload: true }))),
          switchMap(value =>
            this.communityService
              .all_list(
                Object.assign(new CommunityRequestParams(), {
                  search: value,
                  page_size: 50,
                })
              )
              .pipe(catchError(err => EMPTY))
          ),
          tap(_ => this.store.dispatch(toggleLoading({ payload: false })))
        );
    }
  }

  hydrateForm() {
    this.form.patchValue(Object.assign({}, this.entity), {
      emitEvent: false,
      onlySelf: true,
    });
  }

  save($event: MouseEvent) {
    $event.preventDefault();
    if (this.isEditForm) {
      this.edit();
    } else {
      this.create();
    }
  }

  private create() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    if (!this.commit) {
      this.dialogRef.close(this.entity);
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const data = new Community();

    data.name = this.form.value.name;
    data.city = this.form.value.city_info.id;
    data.city_info = this.form.value.city_info;
    data.cross_roads = this.form.value.cross_roads;
    data.website = this.form.value.website_url;

    this.communityService
      .create(data)
      .pipe(first())
      .subscribe({
        next: community => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close(community);
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  private edit() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    if (!this.commit) {
      this.dialogRef.close(this.entity);
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const data = new Community();

    data.name = this.form.value.name;
    data.city = this.form.value.city_info.id;
    data.city_info = this.form.value.city_info;
    data.cross_roads = this.form.value.cross_roads;
    data.website = this.form.value.website_url;

    this.communityService
      .update(this.entity.id, data)
      .pipe(first())
      .subscribe({
        next: community => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close(community);
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  onCommunitySelected($event: MatAutocompleteSelectedEvent) {
    if (typeof $event.option.value === 'string') {
      this.checkedForExistingCommunity = true;
      this.form.patchValue({ name: this.newCommunityName });
    } else {
      const selectedCommunity = $event.option.value;

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '400px',
        data: {
          title: 'Community with this name already exists',
          content: `Community with name <b>${selectedCommunity.name}</b> already exists. We will create an association with existing community instead`,
        },
      });

      dialogRef
        .afterClosed()
        .pipe(first())
        .subscribe(result => {
          if (!result) {
            return;
          }
          this.store.dispatch(toggleLoading({ payload: true }));
          const relationship = new Relationship();
          relationship.company = this.currentUser.company_info.id;
          relationship.community = selectedCommunity.id;
          relationship.is_attached = true;
          relationship.is_viewable = true;
          relationship.is_owned = true;
          relationship.is_reportable = true;

          this.communityService
            .relationships_create(selectedCommunity.id, relationship)
            .pipe(first())
            .subscribe(
              _ => {
                this.store.dispatch(toggleLoading({ payload: false }));
                this.dialogRef.close(selectedCommunity);
              },
              error => this.uiHelperService.handleUserRequestError(error)
            );
        });
    }
  }

  displayExistingCommunityAutocomplete(community: Community) {
    if (community) {
      return `${community.name}`;
    }
  }
}
