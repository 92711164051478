<div class="card">
  <div class="card-header">
    <div class="row">
      <div class="col-8">
        <h4>Description/Misc:</h4>
      </div>
      <div class="col-4 text-right">
        <app-permission-mat-button
          [permissionResponse]="editPermissionResponse"
          (action)="editMiscCompanyData($event)"
        >
          Edit
        </app-permission-mat-button>
      </div>
    </div>
  </div>
  <div class="card-content">
    <div class="row">
      <div class="col-12">
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <b>Description:</b>
            {{ company.description || "-" }}
          </li>
          <li class="list-group-item">
            <b>Auto add direct relationships:</b>
            {{ company.auto_add_direct_relationships | yesNo }}
          </li>
          <li class="list-group-item">
            <b>Show addresses as-entered:</b>
            {{ company.display_raw_addresses | yesNo }}
          </li>
          <li class="list-group-item">
            <b>Verification Grade Type:</b>
            {{ inspectionGradeTypeLabelMapping[company.inspection_grade_type] }}
          </li>
          <li class="list-group-item">
            <b>Shipping Address:</b>
            <app-address-display
              [geocode]="company.shipping_geocode_info"
              [geocodeBrokeredResponse]="company.shipping_geocode_response_info"
            ></app-address-display>
          </li>
          <li class="list-group-item">
            <b>Logo:</b>
            <img src="{{ company.logo }}" class="img-fluid" />
          </li>
          <li class="list-group-item" *ngIf="company?.sponsor_slugs?.includes(CustomerHIRLSettings.companySlug) || company?.slug === CustomerHIRLSettings.companySlug">
            <b>Marketing Roles:</b>
            <ul class="list-inline mb-0">
              <li
                class="list-inline-item"
                *ngFor="
                  let hirl_marketing_role of company.hirl_marketing_roles_info;
                  let isLast = last
                "
              >
                {{ hirl_marketing_role.name }}
                {{ isLast ? "" : ", " }}
              </li>
            </ul>
          </li>
          <li class="list-group-item" *ngIf="company?.sponsor_slugs?.includes(CustomerHIRLSettings.companySlug) || company?.slug === CustomerHIRLSettings.companySlug">
            <b>NGBS Green Products Invoice Preference: </b>{{ GCPFeeCalculationTypeLabelMapping[company.gcp_fee_calculation_type] }}
          </li>
          <li class="list-group-item" *ngIf="company?.sponsor_slugs?.includes(CustomerHIRLSettings.companySlug) || company?.slug === CustomerHIRLSettings.companySlug">
            <b>NGBS Green Products Expiration Date: </b>
            <ng-container *ngIf="company.gcp_certificate_expiration_date">
              {{ company.gcp_certificate_expiration_date | date: 'MM/dd/yyyy' }}
            </ng-container>
            <ng-container *ngIf="!company.gcp_certificate_expiration_date">-</ng-container>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="row mt-2" *ngIf="showETO">
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-8">
            <h4>ETO Account</h4>
          </div>
          <div class="col-4 text-right">
            <app-permission-mat-button
              [permissionResponse]="editPermissionResponse"
              (action)="editETOAccount($event)"
            >
              Edit
            </app-permission-mat-button>
          </div>
        </div>
      </div>
      <div class="card-content">
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <b>Account Number:</b>
            {{ company.eto_account_info?.account_number || "-" }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="row mt-2">
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-8">
            <h4>NGBS Client ID</h4>
          </div>
        </div>
      </div>
      <div class="card-content">
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <b>Internal Client ID:</b>
            <span>
              {{ company.hirlcompanyclient_set[0]?.id || "-" }}
            </span>
          </li>
          <li class="list-group-item" *ngIf="associatedClientIds.length">
            <b>Associated Client ID’s:</b>
            <span
              *ngFor="
                let hirlCompanyClientId of associatedClientIds;
                let isLast = last
              "
            >
              {{ hirlCompanyClientId || "-" }}{{ isLast ? "" : ", " }}
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
