<h2 mat-dialog-title>
    <ng-container>
      Create New <b>FloorPlan</b> from BLG
    </ng-container>
</h2>
<mat-dialog-content *ngIf="initialized">
    <form [formGroup]="form" class="mb-2">
        <div class="row">
            <div class="col-12 mr-2">
                <mat-form-field appearance="outline" class="mb-2 w-100">
                <mat-label>Blg File</mat-label>
                <app-file-input formControlName="remrate_data_file" placeholder="Import Remrate Data File" [accept]="'.blg, .xml'" required></app-file-input>
                </mat-form-field>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-button color="accent" mat-raised-button (click)="onCreateFromBLG($event)">Create Floorplan from BLG</button>
    <button mat-button [mat-dialog-close]="null" color="primary" mat-raised-button>Cancel</button>
</mat-dialog-actions>
  