<ng-container *ngIf="customerDocument">
  <ng-container [ngSwitch]="displayType">
    <ng-container *ngSwitchCase="customerDocumentDisplayComponentType.onlyFilename">{{ customerDocument?.filename }}</ng-container>
    <ng-container *ngSwitchCase="customerDocumentDisplayComponentType.onlyFilenameAsLink">
      <a href="{{ customerDocument.preview_link }}" target="_blank">
        {{ customerDocument?.filename }}
      </a>
    </ng-container>
    <ng-container *ngSwitchCase="customerDocumentDisplayComponentType.filenameAsLinkAndSharedLink">
      <a href="{{ customerDocument.preview_link }}" target="_blank">
        {{ customerDocument?.filename }}
      </a>

      <app-copy-to-clipboard-button [value]="customerDocument.preview_link" *ngIf="customerDocument?.filename" matTooltip="Copy link">
        <fa-icon [icon]="['fas', 'link']"></fa-icon>
      </app-copy-to-clipboard-button>
    </ng-container>
  </ng-container>
</ng-container>
