export function arraysHaveSameElements<T>(array1: T[], array2: T[]): boolean {
  // Check if both arrays have the same length
  if (array1.length !== array2.length) {
    return false;
  }

  // Convert arrays to sets and compare the sets
  const set1 = new Set(array1);
  const set2 = new Set(array2);

  // Check if both sets have the same size
  if (set1.size !== set2.size) {
    return false;
  }

  // Check if all elements in set1 are in set2
  for (const element of set1) {
    if (!set2.has(element)) {
      return false;
    }
  }

  // If all checks pass, the arrays have the same elements
  return true;
}
