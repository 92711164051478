import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-user-select-dialog',
  templateUrl: './user-select-dialog.component.html',
  styleUrls: ['./user-select-dialog.component.scss'],
})
export class UserSelectDialogComponent implements OnInit {
  initialValueId: number;
  filters = {};

  control = new FormControl();
  label: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<UserSelectDialogComponent>
  ) {}

  ngOnInit(): void {
    this.initialValueId = this.data.initialValueId;
    this.label = this.data.label;
    this.filters = {
      company: this.data.companyId,
    };

    this.control.setValue(this.initialValueId);

    this.control.valueChanges.subscribe(value => {
      if (value.id === this.initialValueId) return;

      this.dialogRef.close(value);
    });
  }
}
