import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { ImpersonationUser } from '@/data/core/models/impersonation_user';
import { AuthCookie } from '@/core/services/auth-cookies-handler';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor(private http: HttpClient, private authCookie: AuthCookie) {}

  public decorateRequest(
    request: HttpRequest<any>,
    info: ImpersonationUser,
    token: string
  ): HttpRequest<any> {
    const csrf =
      info && info.csrftoken ? { 'X-CSRFToken': info.csrftoken } : {};
    const auth = token ? { Authorization: `Bearer ${token}` } : {};

    return request.clone({ setHeaders: Object.assign(auth, csrf) });
  }

  public getAccessToken(): string {
    return (
      (this.authCookie.getAccess() &&
        JSON.parse(this.authCookie.getAccess())) ||
      ''
    );
  }

  public getRefreshToken(): string {
    return (
      (this.authCookie.getRefresh() &&
        JSON.parse(this.authCookie.getRefresh())) ||
      ''
    );
  }
}
