import { LayerBackendDict } from '@/data/simulation/models/enclosure/AssemblyLayer';
import {
  MaterialChoices,
  Materials,
} from '@/data/simulation/models/enclosure/Material';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-assembly-layer',
  templateUrl: './assembly-layer.component.html',
  styleUrls: ['./assembly-layer.component.scss'],
})
export class AssemblyLayerComponent implements OnInit {
  @Input() layer: LayerBackendDict;
  @Input() active = false;

  materialLabels = {};
  airCavityInStud = false;
  materialToStyleClassname = MaterialToStyleClassname;

  layerItemsCount: any;

  updateAirCavityInStud() {
    Object.entries(Materials).forEach(([key, value]) => {
      this.materialLabels[key] = value.name;
    });

    const materialsThickness = this.layer.materials.reduce(
      (acc, material) => acc + material.thickness,
      0
    );

    this.airCavityInStud = materialsThickness < this.layer.stud_depth;

    this.layerItemsCount = this.layer.materials.length;
    if (this.airCavityInStud) {
      this.layerItemsCount += 1;
    }
  }

  ngOnInit(): void {
    this.updateAirCavityInStud();
  }

  ngOnChanges() {
    this.updateAirCavityInStud();
  }
}

const MaterialToStyleClassname: Record<MaterialChoices, string> = {
  [MaterialChoices.FIBERGLASS_BATTS]: 'fiberglass-wool',
  [MaterialChoices.MINERAL_WOOL_BATTS]: 'rock-wool',
  [MaterialChoices.CELLULOSE_DENSE_PACK]: 'blown-cellulose',
  [MaterialChoices.CELLULOSE_SPRAY]: 'blown-cellulose',
  [MaterialChoices.CONCRETE_STUCCO_CEMENT]: 'concrete-block',
  [MaterialChoices.PLYWOOD_OSB]: 'sheathing-plywood',
  [MaterialChoices.INSULATED_SHEATHING_1IN]: 'seathing',
  [MaterialChoices.AIR_GAP]: 'polka',
  [MaterialChoices.BRICK_VENEER]: 'brick',
  [MaterialChoices.BRICK_STRUCTURAL]: 'brick',
  [MaterialChoices.GYPSUM_BOARD]: 'gypsum-board',
  [MaterialChoices.CUSTOM]: 'diagonal',
  [MaterialChoices.FIBERBOARD]: 'fiberboard',
  [MaterialChoices.HOUSEWRAP]: 'cross',
  [MaterialChoices.FINISH_WOOD]: 'wood',
  [MaterialChoices.SPRAY_FOAM_HD_POLYURETHANE]: 'foam',
  [MaterialChoices.SPRAY_FOAM_LD_POLYURETHANE]: 'foam',

  [MaterialChoices.CARPET]: 'cross',
  [MaterialChoices.EPS]: 'cross',
  [MaterialChoices.FIBERGLASS_DENSE_PACK]: 'lines',
  [MaterialChoices.PARTICLE_BOARD]: 'wavy',
  [MaterialChoices.POLYISOCYANURATE_FOIL]: 'moon',
  [MaterialChoices.SHINGLES]: 'triangle',
  [MaterialChoices.SIDING_VINYL]: 'diagonal',
  [MaterialChoices.SIDING_WOOD]: 'rombus',
  [MaterialChoices.VERMICULITE_LOOSE_FILL]: 'wavy',
  [MaterialChoices.XPS]: 'moon',
  [MaterialChoices.FLOOR_COVERS]: 'moon',
};
