import {
  UtilityRateBackendDict,
  DetailedUtilityRateBackendDict,
} from '@/data/simulation/models/utility/UtilityRate';
import { createAction, props } from '@ngrx/store';
import { ModelErrors, StateErrors } from '../shared/base.state';

export const loadDetailedUtilityRates = createAction(
  '[Simulation API] Load Detailed UtilityRates',
  props<{ detailedUtilityRates: DetailedUtilityRateBackendDict[] }>()
);

export const loadUtilityRatesSuccess = createAction(
  '[UtilityRate API] Load UtilityRates Success',
  props<{
    utilityRates: UtilityRateBackendDict[];
    errors: StateErrors<UtilityRateBackendDict>;
  }>()
);

export const updateUtilityRate = createAction(
  '[UtilityRate UI] Update UtilityRate',
  props<{ utilityRateChanges: Partial<UtilityRateBackendDict> }>()
);

export const updateUtilityRateSuccess = createAction(
  '[UtilityRate API] Update UtilityRate Success',
  props<{
    utilityRate: UtilityRateBackendDict;
    errors: ModelErrors<UtilityRateBackendDict>;
  }>()
);

export const updateUtilityRateFailure = createAction(
  '[UtilityRate API] Update UtilityRate Failure',
  props<{ id: number }>()
);

export const removeUtilityRate = createAction(
  '[UtilityRate UI] Remove UtilityRate',
  props<{ utilityRate: UtilityRateBackendDict }>()
);

export const removeUtilityRateSuccess = createAction(
  '[UtilityRate API] Remove UtilityRate Success',
  props<{ id: number }>()
);

export const removeUtilityRateFailure = createAction(
  '[UtilityRate API] Remove UtilityRate Failure',
  props<{ id: number }>()
);

export const updateUtilityRateErrors = createAction(
  '[UtilityRate UI] Update UtilityRate Errors',
  props<{ id: number; errors: any }>()
);
