import { ListRequestParams } from '@/core/schemes/request-params';
import { Injectable } from '@angular/core';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import { environment } from '../../../../environments/environment';
import { ServerResponse } from '@/core/schemes/server-response';
import { Observable } from 'rxjs';
import {
  FlatListTask,
  Task,
  TaskApprovalState,
  TaskExportToCal,
  TaskStatus,
  TaskChangeStatus,
  TaskChangeState,
} from '../models';

export class TaskRequestParams extends ListRequestParams {
  assigner?: number;
  approver?: number;
  assignees?: number[];
  approval_state?: TaskApprovalState;
  status?: TaskStatus;
  created_at__gte?: string;
  created_at__lte?: string;
}

@Injectable({
  providedIn: 'root',
})
export class TaskService extends BaseDataService {
  baseUrl = `${environment.AXIS_API_V3_ROOT}/scheduling_tasks/`;

  list(params?: TaskRequestParams): Observable<ServerResponse<Task>> {
    return this.http.get<ServerResponse<Task>>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }

  flat_list(params?: TaskRequestParams) {
    return this.http.get<ServerResponse<FlatListTask>>(
      `${this.baseUrl}flat_list/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  create(
    task: Task | FormData,
    options?: IDataServiceMethodOptions
  ): Observable<Task> {
    return this.http.post<Task>(`${this.baseUrl}`, task);
  }

  retrieve(
    taskId: number,
    options?: IDataServiceMethodOptions
  ): Observable<Task> {
    return this.http.get<Task>(`${this.baseUrl}${taskId}`);
  }

  update(task: Task, taskId: number, partial = true): Observable<Task> {
    let method = this.http.patch;
    if (!partial) {
      method = this.http.put;
    }
    return this.http.patch<Task>(`${this.baseUrl}${taskId}/`, task);
  }

  delete(taskId: number) {
    return this.http.delete(`${this.baseUrl}${taskId}`);
  }

  change_state(data: TaskChangeState): Observable<TaskChangeState> {
    return this.http.patch<TaskChangeState>(
      `${this.baseUrl}change_state/`,
      data
    );
  }

  change_status(data: TaskChangeStatus): Observable<TaskChangeStatus> {
    return this.http.patch<TaskChangeStatus>(
      `${this.baseUrl}change_status/`,
      data
    );
  }

  export_to_cal(data: TaskExportToCal): Observable<any> {
    return this.http.post(`${this.baseUrl}export_to_cal/`, data, {
      responseType: 'blob' as const,
    });
  }
}
