<div class="row">
    <div class="col-3">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <h5 class="card-header">Filters</h5>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Search</mat-label>
                                    <input matInput
                                           type="text"
                                           placeholder="Type something..."
                                           [(ngModel)]="storedParams.search"
                                           [ngModelOptions]="{standalone: true}"
                                           (keyup)="onSearch($event)">
                                    <button mat-button *ngIf="storedParams.search" matSuffix mat-icon-button aria-label="Clear" (click)="onClearSearch($event);">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-9">
        <ngx-datatable
                class="material"
                [rows]="rows"
                columnMode="force"
                [headerHeight]="40"
                rowHeight="40"
                [loadingIndicator]="isLoading"
                [footerHeight]="50"
                (sort)="onSort($event)"
                [sorts]="[{prop: storedParams.ordering.replace('-', ''), dir: (storedParams.ordering.startsWith('-')) ? 'desc' : 'asc'}]">
            <ngx-datatable-column name="Company Name">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <a href="#">
                        {{ row.sponsored_company_info.name }}
                    </a>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Allow Profile Modifications">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    {{ row.sponsored_company_info.can_edit_profile | yesNo }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Unlock name, address, phone, and email fields">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    {{ row.sponsored_company_info.can_edit_identity_fields | yesNo }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Subscribe to profile update notifications">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    {{ row.sponsored_company_info.notify_sponsor_on_update | yesNo }}
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer *ngIf="rowsCount">
                <ng-template
                        ngx-datatable-footer-template>
                    <div class="col-3 text-center">
                        Showing {{ storedParams.page }} to {{ rowsCount | divide: 25 }}
                        of {{ rowsCount }}
                    </div>
                    <div class="col-9 text-right">
                        <datatable-pager
                                [pagerLeftArrowIcon]="'datatable-icon-left'"
                                [pagerRightArrowIcon]="'datatable-icon-right'"
                                [pagerPreviousIcon]="'datatable-icon-prev'"
                                [pagerNextIcon]="'datatable-icon-skip'"
                                [page]="storedParams.page"
                                [size]="25"
                                [count]="rowsCount"
                                (change)="onChangePage($event)"
                        >
                        </datatable-pager>
                    </div>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
</div>
