import { Component, Input, OnInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { UIHelperService } from '@/shared/services/ui-helper.service';

@Component({
  selector: 'app-copy-to-clipboard-button',
  templateUrl: './copy-to-clipboard-button.component.html',
  styleUrls: ['./copy-to-clipboard-button.component.scss'],
})
export class CopyToClipboardButtonComponent implements OnInit {
  @Input() value: string;
  constructor(
    private clipboard: Clipboard,
    private uiHelperService: UIHelperService
  ) {}

  ngOnInit(): void {}

  copyToClipboard($event: MouseEvent) {
    $event.preventDefault();
    this.clipboard.copy(this.value);
    this.uiHelperService.openSnackBar('Copied To Clipboard');
  }
}
