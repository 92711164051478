import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Location } from '@angular/common';

import { FrontendLoggerService } from '@/data/core/services/frontend-logger.service';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandlerService implements ErrorHandler {
  private previousError: any;

  // Error handling is important and needs to be loaded first.
  // Because of this we should manually inject the services with Injector.
  constructor(private injector: Injector, private location: Location) {}

  handleError(error: any) {
    console.log(error);
    if (JSON.stringify(this.previousError) === JSON.stringify(error)) {
      return;
    }
    this.previousError = error;
    const frontendLoggerService = this.injector.get(FrontendLoggerService);

    frontendLoggerService
      .create({
        level: 'ERROR',
        msg: error.message,
        extra: {
          stack: error.stack,
          url: this.location.path(),
        },
      })
      .pipe(first())
      .subscribe();

    console.error(error);
  }
}
