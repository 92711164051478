<div class="container" *ngIf="initialized">
    <div class="row">
        <div class="col-12">
            <nav class="navbar p-0 pt-3 mb-3" role="navigation" aria-label="main navigation" style="border-bottom: 1px solid #eee;">
                <h5 class="mt-3">
                    <button mat-raised-button color="accent" (click)="handleBack()">
                        <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
                    </button>
                    Add
                    <span *ngIf="isAccessoryStructure">Accessory Structure </span>
                    <span *ngIf="!isAccessoryStructure">Building </span>
                </h5>
            </nav>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <form [formGroup]="addFormGroup">
                <div class="row" *ngIf="!isAccessoryStructure">
                    <div class="col-12">
                        <mat-form-field style="width: 100%;" appearance="outline" class="mb-2">
                            <mat-label>Building Number</mat-label>
                            <input matInput type="text" placeholder="Building Number"
                                   formControlName="building_number" required>
                            <mat-error
                                    *ngIf="addFormGroup.get('building_number').hasError('required')">
                                This field is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline" class="w-100 mb-2">
                            <mat-label>Address</mat-label>
                            <app-home-address-input placeholder="Address" formControlName="address" required [show-clear-btn]="true"></app-home-address-input>
                            <mat-error
                                    *ngIf="addFormGroup.get('address').hasError('required')">
                                This field is required
                            </mat-error>
                            <mat-error
                                    *ngIf="addFormGroup.get('address').hasError('home_with_this_address_already_exists')">
                                Home with this address already exists
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div *ngIf="!isAccessoryStructure">
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field style="width: 100%;" appearance="outline" class="mb-2">
                                <mat-label>Number of Units</mat-label>
                                <input matInput type="number" placeholder="Number of Units"
                                       formControlName="number_of_units" required>
                                <mat-error
                                        *ngIf="addFormGroup.get('number_of_units').hasError('required')">
                                    This field is required
                                </mat-error>
                                <mat-error
                                        *ngIf="addFormGroup.get('number_of_units').hasError('min') || addFormGroup.get('number_of_units').hasError('max')">
                                    Value must be between 0 and 1000000
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field style="width: 100%;" appearance="outline" class="mb-2">
                                <mat-label>Story Count</mat-label>
                                <input matInput type="number" placeholder="Story Count" formControlName="story_count" required>
                                <mat-error *ngIf="addFormGroup.get('story_count').hasError('required')">
                                    This field is required
                                </mat-error>
                                <mat-error *ngIf="addFormGroup.get('story_count').hasError('min') || addFormGroup.get('story_count').hasError('max')">
                                    Value must be between 0 and 1000000 sq. ft
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <mat-form-field class="mb-2 w-100">
                                <mat-label>HUD Disaster Case Number</mat-label>
                                <input matInput type="text" placeholder="HUD Disaster Case Number"
                                       formControlName="hud_disaster_case_number">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row" *ngIf="hirlGreenEnergyBadges.length">
                        <div class="col-12">
                            <mat-hint>
                                Identify any NGBS Green+ badges that you intend to pursue. Note: with the exception of Zero Water, all badges are available for both
                                New Construction and Renovation. See NGBS Green+ Compliance Handbook for more details.
                            </mat-hint>
                            <mat-form-field appearance="outline" class="mb-2 w-100">
                                <mat-label>NGBS Green+ Badges: </mat-label>
                                <mat-select aria-label="Select Green Energy Badges" formControlName="green_energy_badges" multiple panelClass="green-energy-badges-panel-override">
                                    <mat-option *ngFor="let hirlGreenBadge of hirlGreenEnergyBadges"
                                                [value]="hirlGreenBadge.id">{{hirlGreenBadge.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row mt-1">
                        <div class="col-12">
                            <div>
                                <mat-form-field class="mb-2 w-100">
                                    <mat-label>Is this an appeals project?</mat-label>
                                    <mat-select aria-label="Select Green Energy Badges" formControlName="appeals_project">
                                        <mat-option *ngFor="let appealsProjectType of HIRLProjectAppealsProjectTypeMapping | defaultOrderKeyValue"
                                                    [value]="appealsProjectType.key">{{ appealsProjectType.value }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-1">
                        <div class="col-12">
                            <div>
                                <mat-form-field class="mb-2 w-100">
                                    <mat-label>Is this building a two-over-two /maisonette/stacked townhouse?</mat-label>
                                    <mat-select aria-label="Is this building a two-over-two /maisonette/stacked townhouse" formControlName="is_townhouse">
                                        <mat-option [value]="false">No</mat-option>
                                        <mat-option [value]="true">Yes</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-2" *ngIf="!isWRIProgramSelected()">
                        <div class="col-12">
                            <mat-form-field class="w-100 mb-2">
                                <mat-label
                                >Do you intend to employ the NGBS Green Alternative
                                    Multifamily Verification Protocol (Sampling) ?</mat-label
                                >
                                <mat-select
                                    formControlName="sampling"
                                    panelClass="sampling-panel-override"
                                >
                                    <mat-option
                                        *ngFor="
                        let item of HIRLProjectSamplingMapping
                          | defaultOrderKeyValue
                      "
                                        [value]="item.key"
                                    >
                                        {{ item.value }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="addFormGroup.get('sampling').hasError('required')">
                                    This field is required
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row mt-2" *ngIf="customerHIRLSettings.requireRoughInspectionProgramList.includes(this.hirlProjectRegistration.eep_program_info?.slug)">
                        <div class="col-12">
                            <div>
                                <p>
                                    <mat-label>Rough inspections are typically not required if there will be no renovation behind drywall.
                                        Will this remodel project require a rough inspection?</mat-label>
                                </p>
                                <mat-radio-group
                                        aria-label="Will this remodel project require a rough inspection?"
                                        formControlName="is_require_rough_inspection">
                                    <mat-radio-button [value]="true" class="mr-5">
                                        Yes
                                    </mat-radio-button>
                                    <mat-radio-button [value]="false">
                                        No
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-2" *ngIf="customerHIRLSettings.wriSeekingProgramList.includes(this.hirlProjectRegistration.eep_program_info?.slug)">
                        <div class="col-12">
                            <div>
                                <p>
                                    <mat-label>Is this project seeking a WRI?</mat-label>
                                </p>
                                <mat-radio-group
                                        aria-label="Is this project seeking a WRI?"
                                        formControlName="is_require_wri_certification">
                                    <mat-radio-button [value]="true" class="mr-5">
                                        Yes
                                    </mat-radio-button>
                                    <mat-radio-button [value]="false">
                                        No
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-2" *ngIf="customerHIRLSettings.waterSenseProgramList.includes(this.hirlProjectRegistration.eep_program_info?.slug)">
                        <div class="col-12">
                            <div>
                                <p>
                                    <b>WaterSense Certification:</b>
                                    The WaterSense label is available for home seeking NGBS Green Certification under the 2020 NGBS version. Do you intend to pursue WaterSense certification for this building? Note: WRI Verifier training must be completed prior to final inspection.
                                </p>
                                <p>
                                    <mat-label>Is the project seeking WaterSense Certification?</mat-label>
                                </p>
                                <mat-radio-group
                                        aria-label="Is the project seeking WaterSense Certification?"
                                        formControlName="is_require_water_sense_certification">
                                    <mat-radio-button [value]="true" class="mr-5">
                                        Yes
                                    </mat-radio-button>
                                    <mat-radio-button [value]="false">
                                        No
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>


                    <div *ngIf="!isWRIProgramSelected()">
                        <div class="row">
                            <div class="col-12">
                                Will this building include Commercial Space?
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <mat-radio-group aria-label="Will this project include Commercial Space?" name="is_include_commercial_space" formControlName="is_include_commercial_space">
                                    <mat-radio-button [value]="true" class="mr-5">
                                        Yes
                                    </mat-radio-button>
                                    <mat-radio-button [value]="false">
                                        No
                                    </mat-radio-button>
                                </mat-radio-group>
                                <mat-error *ngIf="addFormGroup.get('is_include_commercial_space').hasError('required') && (addFormGroup.get('is_include_commercial_space').dirty || addFormGroup.get('is_include_commercial_space').touched)">
                                    This field is required
                                </mat-error>
                            </div>
                        </div>
                        <div class="row" *ngIf="addFormGroup.get('is_include_commercial_space').value">
                            <div class="col-12">
                                <mat-form-field appearance="outline" class="w-100 mb-2">
                                    <mat-label>Commercial Space Street Line 2</mat-label>
                                    <textarea matInput placeholder="Commercial Space Street Line 2"
                                              formControlName="commercial_space_street_line_2"></textarea>
                                    <mat-error *ngIf="addFormGroup.get('commercial_space_street_line_2').hasError('required')">
                                        Accessory Structure Description is required
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row" *ngIf="addFormGroup.get('is_include_commercial_space').value">
                            <div class="col-6">
                                <mat-form-field appearance="outline" class="w-100 mb-2">
                                    <mat-label>Type</mat-label>
                                    <mat-select aria-label="Commercial Space Type" formControlName="commercial_space_type">
                                        <mat-option *ngFor="let item of commercialSpaceTypeLabelMapping | defaultOrderKeyValue" [value]="item.key">
                                            {{ item.value }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="addFormGroup.get('commercial_space_type').hasError('required')">
                                        Commercial Space Type is required
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field style="width: 100%;" appearance="outline" class="mb-2">
                                    <mat-label>Total Commercial Space</mat-label>
                                    <input matInput type="number" placeholder="Total Commercial Space" formControlName="total_commercial_space">
                                    <span matSuffix>
                                                sq. ft
                                            </span>
                                    <mat-error *ngIf="addFormGroup.get('total_commercial_space').hasError('required')">
                                        This field is required
                                    </mat-error>
                                    <mat-error *ngIf="addFormGroup.get('total_commercial_space').hasError('min') || addFormGroup.get('total_commercial_space').hasError('max')">
                                        Value must be between 0 and 1000000 sq. ft
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                </div>

                <div *ngIf="isAccessoryStructure">
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field appearance="outline" class="w-100 mb-2">
                                <mat-label>Accessory Structure Description</mat-label>
                                <textarea matInput placeholder="Accessory Structure Description"
                                          formControlName="accessory_structure_description"></textarea>
                                <mat-error *ngIf="addFormGroup.get('accessory_structure_description').hasError('required')">
                                    Accessory Structure Description is required
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 text-center">
                        <button type="button"
                                (click)="handleCreate($event)"
                                class="btn btn-primary btn-lg mr-2">
                            Create
                        </button>
                    </div>
                </div>

            </form>
        </div>
    </div>

</div>
