import { createSelector } from '@ngrx/store';
import { SimulationState } from '../reducers';
import { selectSimulationFeature } from '../simulation.selectors';

export const selectFoundationWallType = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.foundationWallType.entities[id]
  );

const selectFoundationWallTypeLoadingStatus = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.foundationWallType.loading[id]
  );

export const selectFoundationWallViewSet = (id: number) =>
  createSelector(
    selectFoundationWallType(id),
    selectFoundationWallTypeLoadingStatus(id),
    (foundationWallType, loadingStatus) => ({
      data: foundationWallType,
      loading: loadingStatus,
    })
  );

const selectFoundationWallTypeName = (id: number) =>
  createSelector(
    selectFoundationWallType(id),
    foundationWallType => foundationWallType.name
  );
const selectFoundationWallTypeRValue = (id: number) =>
  createSelector(
    selectFoundationWallType(id),
    foundationWallType => 1 / foundationWallType.assembly_u_value
  );

const selectFoundationWallTypeErrorCount = (id: number) =>
  createSelector(selectSimulationFeature, (state: SimulationState) => {
    return state.foundationWallType.errors[id].errorCount;
  });

export const selectFoundationWallTypeNameAndError = (id: number) =>
  createSelector(
    selectFoundationWallTypeName(id),
    selectFoundationWallTypeRValue(id),
    selectFoundationWallTypeErrorCount(id),
    (name, rValue, errorCount) => {
      return {
        name,
        rValue,
        errorCount,
      };
    }
  );
