<div *ngIf="!form.controls.user_defined_assembly_u_value.value">
    <div class="assembly-editor-header">
        <h4>Assembly Editor</h4>
        <mat-button-toggle-group appearance="legacy" [formControl]="assemblyDataFormat" class="custom-toggle-group">
            <mat-button-toggle value="classic">Classic form</mat-button-toggle>
            <mat-button-toggle value="layers">Layers editor</mat-button-toggle>
        </mat-button-toggle-group>
        <i class="text-danger" *ngIf="unableToCalculateRValue">Invalid data, unable to caclulate the assembly U value!</i>
    </div>
    <ng-container *ngIf="!switchingInProgress">
        <ng-container *ngIf="assemblyDataFormat.value === 'classic'">
            <ng-content></ng-content>
        </ng-container>
        <form [formGroup]="form">
            <app-assembly-layers [formControl]="form.controls.layers"
                *ngIf="assemblyDataFormat.value === 'layers'"
                (onChange)="onSave($event)"
                [assembledOn]="form.frameType"></app-assembly-layers>
        </form>
    </ng-container>

    <ng-container *ngIf="switchingInProgress">
        <div class="d-flex flex-column justify-content-center mt-5 align-items-center">
            <h4>Are you sure you want to switch to "{{assemblyDataFormat.value}}" editor?</h4>
            <div class="d-flex flex-column justify-content-center">
                <p *ngIf="assemblyDataFormat.value==='classic'"><em><strong>Note:</strong> All the layers data will be lost if you switch to the classic editor.</em></p>
                <p *ngIf="assemblyDataFormat.value==='layers'"><em><strong>Note:</strong> The classic assembly editor data will be convert to layers.</em></p>
            </div>

            <div class="d-flex justify-content-center">
                <button mat-raised-button color="primary" class="mr-2" (click)="undoSwitch()">Cancel</button>
                <button mat-raised-button color="primary" (click)="switchToFormat()">Switch</button>
            </div>
        </div>
    </ng-container>
</div>