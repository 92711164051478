import { createReducer, on } from '@ngrx/store';
import * as FoundationWallActions from './actions';
import { FoundationWallBackendDict } from '@/data/simulation/models/enclosure/FoundationWall';
import { BaseState, sharedInitialState } from '../shared/base.state';
import { addErrorCount, addStateErrorCount } from '../helper';

export type FoundationWallState = BaseState<FoundationWallBackendDict>;

export const initialState: FoundationWallState = sharedInitialState;

export const reducer = createReducer(
  initialState,
  on(
    FoundationWallActions.loadFoundationWallsSuccess,
    (currentState, payload) => {
      const entities = payload.foundationWalls.reduce(
        (acc, foundationwall) => ({
          ...acc,
          [foundationwall.id]: foundationwall,
        }),
        {}
      );
      const loadingStatus = payload.foundationWalls.reduce(
        (acc, foundationwall) => ({ ...acc, [foundationwall.id]: false }),
        {}
      );

      return {
        entities: { ...currentState.entities, ...entities },
        loading: { ...currentState.loading, ...loadingStatus },
        errors: {
          ...currentState.errors,
          ...addStateErrorCount(payload.errors),
        },
      };
    }
  ),

  on(
    FoundationWallActions.removeFoundationWallSuccess,
    (currentState, { id }) => {
      const { [id]: removed, ...entities } = currentState.entities;
      const { [id]: removedLoading, ...loading } = currentState.loading;
      const { [id]: removedErrors, ...errors } = currentState.errors;

      return {
        entities,
        loading,
        errors,
      };
    }
  ),

  on(FoundationWallActions.updateFoundationWall, (state, { id }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [id]: true,
      },
    };
  }),

  on(
    FoundationWallActions.removeFoundationWall,
    (state, { foundationWall }) => {
      return {
        ...state,
        loading: {
          ...state.loading,
          [foundationWall.id]: true,
        },
      };
    }
  ),

  on(
    FoundationWallActions.updateFoundationWallFailure,
    FoundationWallActions.removeFoundationWallFailure,
    (state, { id }) => {
      return {
        ...state,
        loading: {
          ...state.loading,
          [id]: false,
        },
      };
    }
  ),

  on(
    FoundationWallActions.updateFoundationWallErrors,
    (state, { id, errors }) => {
      return {
        ...state,
        errors: {
          ...state.errors,
          [id]: errors,
        },
      };
    }
  ),

  on(
    FoundationWallActions.updateFoundationWallSuccess,
    (state, { foundationWall, errors }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          [foundationWall.id]: foundationWall,
        },
        loading: {
          ...state.loading,
          [foundationWall.id]: false,
        },
        errors: {
          ...state.errors,
          [foundationWall.id]: addErrorCount(errors),
        },
      };
    }
  ),

  on(
    FoundationWallActions.setFoundationWallType,
    (state, { wallId, wallTypeId }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          [wallId]: {
            ...state.entities[wallId],
            type: wallTypeId,
          },
        },
      };
    }
  )
);
