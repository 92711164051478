import { PracticeInfo } from '@/data/customer-hirl/models/practice';
import { HIRLGreenEnergyBadge } from '@/data/customer-hirl/models/hirl-green-energy-badge';
import { GRESBIndicatorInfo } from '@/data/customer-hirl/models/gresb-indicator';

export class PracticeItem {
  id?: number;
  item_number?: string;
  text?: string;
  practice?: number;
  practice_info?: PracticeInfo;
  badges?: number[];
  badges_info?: HIRLGreenEnergyBadge[];
  gresb_indicators?: number[];
  gresb_indicators_info?: GRESBIndicatorInfo[];
}

export class PracticeItemInfo {
  id?: number;
  item_number?: string;
  text?: string;
  practice?: number;
  practice_info?: PracticeInfo;
  badges?: number[];
  badges_info?: HIRLGreenEnergyBadge[];
  gresb_indicators?: number[];
  gresb_indicators_info?: GRESBIndicatorInfo[];
}
