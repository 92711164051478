<div class="container-lg">
  <div class="row">
    <div class="col-12">
      <div class="alert alert-warning">
        This page is being replaced with a new report page found <a [routerLink]="['/', 'contact_cards']">here</a>.
        This page will be unavailable starting <b>May 1, 2024</b> and only the new report page will be available.
        Please contact <a href="mailto:support@pivotalenergysoltuions.com">support@pivotalenergysoltuions.com</a> if you have any questions or concerns.
      </div>
    </div>
  </div>
    <div class="row">
        <div class="col-12">
            <div class="row">
                <div class="col-6">
                    <h4>
                        <span *ngIf="!isLoading">{{ rowsCount | number }}</span>
                        <fa-icon [icon]="['fas', 'spinner']" [spin]="true" *ngIf="isLoading"></fa-icon>
                        Users
                    </h4>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <hr>
                </div>
            </div>

            <div class="row">
                <div class="col-3">
                    <div class="row mb-4">
                        <div class="col-12 mr-4">
                            <form [formGroup]="filterFromGroup" class="card">
                                <h5 class="card-header">
                                    Filters
                                    <a href="#" class="small" (click)="resetFilters($event);">Reset filters</a>
                                </h5>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12">
                                            <mat-form-field class="w-100">
                                                <mat-label>Search</mat-label>
                                                <app-search-input formControlName="search" placeholder="Type to search something"></app-search-input>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <mat-form-field class="w-100">
                                                <mat-label>Marketing Role</mat-label>
                                                <app-generic-mat-select modelClassName="hirl_marketing_role" formControlName="company__hirl_marketing_roles__slug" multiple="true" parentKey="slug" [hasEmptyChoice]="true"></app-generic-mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <button class="btn btn-primary mr-2 btn-block" (click)="exportMailingList($event)" matTooltip="Export Data to Excel file">Export Data</button>
                        </div>
                    </div>
                </div>
                <div class="col-9">
                    <div class="row" *ngIf="isLoading">
                        <div class="col-12">
                            <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
                        </div>
                    </div>
                    <table mat-table
                           [dataSource]="rows"
                           class="table"
                           matSort
                           [matSortActive]="storedParams.toTableSort().active"
                           [matSortDirection]="storedParams.toTableSort().direction"
                           (matSortChange)="onSortChange($event)">

                        <ng-container matColumnDef="first_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                            <td mat-cell *matCellDef="let row">
                                <app-user-display [user]="row" displayType="onlyNameAsLink"></app-user-display>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="email">
                            <th mat-header-cell *matHeaderCellDef>Email</th>
                            <td mat-cell *matCellDef="let row">
                                {{ row.email }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="phone_number">
                            <th mat-header-cell *matHeaderCellDef>Phone Number</th>
                            <td mat-cell *matCellDef="let row">
                                {{ row.work_phone }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="company__name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Company Name</th>
                            <td mat-cell *matCellDef="let row">
                                <app-company-display [company]="row.company_info" displayType="onlyNameAsLink"></app-company-display>
                            </td>
                        </ng-container>


                        <ng-container matColumnDef="marketing_role">
                            <th mat-header-cell *matHeaderCellDef>Marketing Role</th>
                            <td mat-cell *matCellDef="let row">
                                {{ getMarketingRoleNames(row.company_info.hirl_marketing_roles_info) }}
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>                        
                    <div class="d-flex flex-row">
                        <div class="align-self-center">
                            <button
                              mat-raised-button
                              (click)="exportToCSV($event)"
                              [disabled]="isLoading"
                              class="mr-2"
                              matTooltip="Export Data to CSV file"
                            >
                              CSV
                            </button>
                            <button
                              mat-raised-button
                              (click)="exportToExcel($event)"
                              [disabled]="isLoading"
                              matTooltip="Export Data to Excel file"
                            >
                              Excel
                            </button>
                          </div>
                        <div class="d-flex flex-fill justify-content-end">
                            <mat-paginator [length]="rowsCount"
                                           [pageIndex]="$any(storedParams.page) - 1"
                                           [pageSize]="storedParams.page_size"
                                           (page)="onPaginateChange($event)"
                                           aria-label="Select page">
                            </mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
