<ng-container>
    <div class="app-input-container">
        <app-label [data]="label" [presentAstrix]="required && isInEditMode"></app-label>
        <mat-form-field *ngIf="isInEditMode" class="custom-input" appearance="fill">
            <input (blur)="onCommit()" matInput [formControl]="control">
            <span *ngIf="unit" matSuffix>{{unit}}</span>
        </mat-form-field>
        <ng-container *ngIf="!isInEditMode">
            <span class="input-view input-view-border">
                <div class="d-flex justify-content-between">
                    <span>{{control.value }}</span>
                    <span *ngIf="unit" matSuffix>{{unit}}</span>
                </div>
            </span>
        </ng-container>
        <app-field-errors *ngIf="presentErrorsInline" [errors]="control.errors"></app-field-errors>
    </div>
</ng-container>
