import { createReducer, on } from '@ngrx/store';
import * as UtilityRateActions from './actions';
import { UtilityRateBackendDict } from '@/data/simulation/models/utility/UtilityRate';
import { BaseState, sharedInitialState } from '../shared/base.state';
import { addErrorCount, addStateErrorCount } from '../helper';

export type UtilityRateState = BaseState<UtilityRateBackendDict>;

export const initialState: UtilityRateState = sharedInitialState;

export const reducer = createReducer(
  initialState,
  on(UtilityRateActions.loadUtilityRatesSuccess, (currentState, payload) => {
    const entities = payload.utilityRates.reduce(
      (acc, utilityrate) => ({ ...acc, [utilityrate.id]: utilityrate }),
      {}
    );

    const loadingStatus = payload.utilityRates.reduce(
      (acc, utilityrate) => ({ ...acc, [utilityrate.id]: false }),
      {}
    );

    return {
      entities: { ...currentState.entities, ...entities },
      loading: { ...currentState.loading, ...loadingStatus },
      errors: { ...currentState.errors, ...addStateErrorCount(payload.errors) },
    };
  }),

  on(UtilityRateActions.removeUtilityRateSuccess, (currentState, { id }) => {
    const { [id]: removed, ...entities } = currentState.entities;
    const { [id]: removedLoading, ...loading } = currentState.loading;
    const { [id]: removedErrors, ...errors } = currentState.errors;

    return {
      entities,
      loading,
      errors,
    };
  }),

  on(UtilityRateActions.updateUtilityRate, (state, { utilityRateChanges }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [utilityRateChanges.id]: true,
      },
    };
  }),

  on(UtilityRateActions.removeUtilityRate, (state, { utilityRate }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [utilityRate.id]: true,
      },
    };
  }),

  on(
    UtilityRateActions.updateUtilityRateFailure,
    UtilityRateActions.removeUtilityRateFailure,
    (state, { id }) => {
      return {
        ...state,
        loading: {
          ...state.loading,
          [id]: false,
        },
      };
    }
  ),

  on(UtilityRateActions.updateUtilityRateErrors, (state, { id, errors }) => {
    return {
      ...state,
      errors: {
        ...state.errors,
        [id]: errors,
      },
    };
  }),

  on(
    UtilityRateActions.updateUtilityRateSuccess,
    (state, { utilityRate, errors }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          [utilityRate.id]: utilityRate,
        },
        loading: {
          ...state.loading,
          [utilityRate.id]: false,
        },
        errors: {
          ...state.errors,
          [utilityRate.id]: addErrorCount(errors),
        },
      };
    }
  )
);
