import { addNewWindow } from '@/modules/simulation/state/simulation/actions';
import { selectWindowIds } from '@/modules/simulation/state/simulation/selectors';
import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-windows',
  templateUrl: './windows.component.html',
  styleUrls: ['./windows.component.scss'],
})
export class WindowsComponent {
  @Input() expanded = false;
  componentDestroyed$ = new Subject();
  windowIds: number[] = [];

  constructor(public store: Store) {
    this.store
      .select(selectWindowIds())
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(ids => {
        this.windowIds = ids;
      });
  }

  addWindow() {
    this.store.dispatch(addNewWindow());
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
