export const CompanySettings = {};

export enum ComponentCompanyType {
  builder = 'builder',
  rater = 'rater',
  developer = 'developer',
  architect = 'architect',
  communityOwner = 'communityowner',
  eep = 'eep',
  hvac = 'hvac',
  provider = 'provider',
  qa = 'qa',
  utility = 'utility',
  manufacturer = 'manufacturer',
  general = 'general',
}

export interface ICompanyTypeMap {
  displayName: string;
  displayNamePlural: string;
  additionalRoutes: [];
}

export const componentCompanyTypeDataMap: { [key: string]: ICompanyTypeMap } = {
  [ComponentCompanyType.builder]: {
    displayName: 'Builder',
    displayNamePlural: 'Builder Organizations',
    additionalRoutes: [],
  },
  [ComponentCompanyType.rater]: {
    displayName: 'Rating/Verification',
    displayNamePlural: 'Rating/Verification Organizations',
    additionalRoutes: [],
  },
  [ComponentCompanyType.architect]: {
    displayName: 'Architect',
    displayNamePlural: 'Architects',
    additionalRoutes: [],
  },
  [ComponentCompanyType.developer]: {
    displayName: 'Developer',
    displayNamePlural: 'Developer Organizations',
    additionalRoutes: [],
  },
  [ComponentCompanyType.communityOwner]: {
    displayName: 'Community Owner',
    displayNamePlural: 'Community Owner Organizations',
    additionalRoutes: [],
  },
  [ComponentCompanyType.eep]: {
    displayName: 'Program Sponsor',
    displayNamePlural: 'Program Sponsor Organizations',
    additionalRoutes: [],
  },
  [ComponentCompanyType.hvac]: {
    displayName: 'HVAC Organization',
    displayNamePlural: 'HVAC Organizations',
    additionalRoutes: [],
  },
  [ComponentCompanyType.provider]: {
    displayName: 'Certification Organization',
    displayNamePlural: 'Certification Organizations',
    additionalRoutes: [],
  },
  [ComponentCompanyType.qa]: {
    displayName: 'QA Organization',
    displayNamePlural: 'QA Organizations',
    additionalRoutes: [],
  },
  [ComponentCompanyType.utility]: {
    displayName: 'Utility Organization',
    displayNamePlural: 'Utility Organizations',
    additionalRoutes: [],
  },
  [ComponentCompanyType.manufacturer]: {
    displayName: 'Product Manufacturer',
    displayNamePlural: 'Product Manufacturers',
    additionalRoutes: [],
  },
  [ComponentCompanyType.general]: {
    displayName: 'General Organization',
    displayNamePlural: 'General Organizations',
    additionalRoutes: [],
  },
};
