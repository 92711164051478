import { createReducer, on } from '@ngrx/store';
import * as AirSourceHeatPumpActions from './actions';
import { AirSourceHeatPumpBackendDict } from '@/data/simulation/models/mechanicals/AirSourceHeatPump';
import { BaseState } from '../shared/base.state';
import { addStateErrorCount } from '../helper';

export type AirSourceHeatPumpState = BaseState<AirSourceHeatPumpBackendDict>;

export const initialState: AirSourceHeatPumpState = {
  entities: {},
  loading: {},
  errors: {},
};

export const reducer = createReducer(
  initialState,
  on(
    AirSourceHeatPumpActions.loadAirSourceHeatPumpsSuccess,
    (state, { airSourceHeatPumps, errors }) => {
      const entities = airSourceHeatPumps.reduce(
        (acc, airSourceHeatPump) => ({
          ...acc,
          [airSourceHeatPump.id]: airSourceHeatPump,
        }),
        {}
      );
      const loading = airSourceHeatPumps.reduce(
        (acc, airSourceHeatPump) => ({ ...acc, [airSourceHeatPump.id]: false }),
        {}
      );

      return {
        entities: {
          ...state.entities,
          ...entities,
        },
        loading: {
          ...state.loading,
          ...loading,
        },
        errors: {
          ...state.errors,
          ...addStateErrorCount(errors),
        },
      };
    }
  ),

  on(
    AirSourceHeatPumpActions.updateAirSourceHeatPump,
    (state, { airSourceHeatPumpChanges }) => ({
      ...state,
      loading: {
        ...state.loading,
        [airSourceHeatPumpChanges.id]: true,
      },
    })
  ),

  on(
    AirSourceHeatPumpActions.updateAirSourceHeatPumpFailure,
    (state, { id }) => ({
      ...state,
      loading: {
        ...state.loading,
        [id]: false,
      },
    })
  )
);
