import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from '@/data/core/models/user';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { HIRLProject } from '@/data/customer-hirl/models';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';
import { forkJoin, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { HIRLProjectService } from '@/data/customer-hirl/services/hirl-projects.service';
import { HIRLProjectRegistrationService } from '@/data/customer-hirl/services/hirl-project-registration.service';
import { MatDialog } from '@angular/material/dialog';
import { GeocoderService } from '@/data/geocoder/services/geocoder.service';
import { UserService } from '@/data/core/services/user.service';
import { distinctUntilChanged, first, takeUntil } from 'rxjs/operators';
import { toggleLoading } from '@/state/actions/app.actions';
import { getInfoUser } from '@/state/selectors/info.selector';
import { HomeAddressConfirmedGeocodeResponse } from '@/modules/geocoder/components/home-address-to-geocode-response-dialog/home-address-to-geocode-response-dialog.component';
import { NavigationService } from '@/shared/services/navigation-service.service';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { hirlProjectAddressExistsValidator } from '@/modules/customer-hirl/field-validators';

@Component({
  selector: 'app-hirl-project-ld-form',
  templateUrl: './hirl-project-ld-form.component.html',
  styleUrls: ['./hirl-project-ld-form.component.scss'],
})
export class HirlProjectLdFormComponent implements OnInit, OnDestroy {
  public initialized = false;
  public currentUser: User;
  public editFormGroup: UntypedFormGroup;

  public hirlProject?: HIRLProject;

  public customerHIRLSettings = CustomerHIRLSettings;

  private destroy$ = new Subject();

  constructor(
    public fb: UntypedFormBuilder,
    public store: Store<AppState>,
    public router: Router,
    public hirlProjectService: HIRLProjectService,
    public hirlProjectRegistrationService: HIRLProjectRegistrationService,
    public dialog: MatDialog,
    public activatedRoute: ActivatedRoute,
    public geocoderService: GeocoderService,
    public userService: UserService,
    private uiHelperService: UIHelperService,
    private navigation: NavigationService
  ) {}

  public ngOnInit(): void {
    this.activatedRoute.params
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(params => {
        this.hirlProjectService
          .retrieve(params.projectId)
          .pipe(first())
          .subscribe(hirlProject => {
            this.hirlProject = hirlProject;

            this.store.dispatch(toggleLoading({ payload: true }));
            const sources: { [k: string]: any } = {
              currentUser: this.store.select(getInfoUser).pipe(first()),
            };

            forkJoin(sources).subscribe(({ currentUser }) => {
              this.currentUser = currentUser;

              this.hydrateForm();
              this.store.dispatch(toggleLoading({ payload: false }));
              this.initialized = true;
            });
          });
      });

    this.setupEditFormGroup();
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public setupEditFormGroup() {
    this.editFormGroup = this.fb.group({
      registration_info: [null, Validators.required],
      edit_address: [
        null,
        Validators.required,
        [
          hirlProjectAddressExistsValidator(
            this.hirlProjectService,
            null,
            false
          ),
        ],
      ],
    });
  }

  hydrateForm() {
    this.editFormGroup.patchValue(this.hirlProject, {
      emitEvent: false,
      onlySelf: true,
    });
    this.editFormGroup.patchValue(
      {
        edit_address: new HomeAddressConfirmedGeocodeResponse({
          lotNumber: this.hirlProject.lot_number,
          geocode: this.hirlProject.home_address_geocode_info,
          geocodeBrokeredResponse:
            this.hirlProject.home_address_geocode_response_info,
        }),
      },
      { emitEvent: false, onlySelf: true }
    );
  }

  handleEdit(event): void {
    event.preventDefault();
    this.editFormGroup.markAllAsTouched();
    if (this.editFormGroup.invalid) {
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const formData = new HIRLProject();
    if (
      this.currentUser.company_info?.slug === CustomerHIRLSettings.companySlug
    ) {
      formData.registration = this.editFormGroup.value.registration_info.id;
    }

    formData.lot_number = this.editFormGroup.value.edit_address.lotNumber;
    formData.home_address_geocode =
      this.editFormGroup.value.edit_address.geocode.id;
    formData.home_address_geocode_response = null;

    if (this.editFormGroup.value.edit_address.geocodeBrokeredResponse) {
      formData.home_address_geocode_response =
        this.editFormGroup.value.edit_address.geocodeBrokeredResponse.id;
    }

    this.hirlProjectService
      .update(formData, this.hirlProject.id)
      .pipe(first())
      .subscribe(
        hirlProject => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.router.navigate([
            `/hi/project_registrations/${hirlProject.registration_info.id}`,
          ]);
        },
        error => this.uiHelperService.handleUserRequestError(error)
      );
  }
}
