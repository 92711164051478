<div *ngIf="initialized else loading">
    <div class="row">
        <div class="col-3">
            <div class="row">
                <div class="col-12">
                    <form [formGroup]="filterForm" class="card">
                        <h5 class="card-header">Filters</h5>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>Search</mat-label>
                                        <app-search-input formControlName="search"
                                            placeholder="Type to search something"></app-search-input>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-9">
            <div class="row">
                <div class="col-12">
                    <ngx-datatable class="material" [rows]="eepHomeInfo" columnMode="force" [headerHeight]="40"
                        rowHeight="auto" [loadingIndicator]="isLoading" [footerHeight]="50" (sort)="onSort($event)"
                        [sorts]="[{prop: storedParams.ordering.replace('-', ''), dir: (storedParams.ordering.startsWith('-')) ? 'desc' : 'asc'}]">
                        <ngx-datatable-column name="Address" prop="home_id">
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                <a [routerLink]="['/', 'home', row.home_info.id ]">
                                    {{ row.home_info.home_address }}
                                </a>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Subdivision" prop="subdivision">
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                <a *ngIf="row.home_info.subdivision_info"
                                    [routerLink]="['/', 'subdivision', row.home_info.subdivision_info.id ]">
                                    {{ row.home_info.subdivision_info.name }} ({{
                                    row.home_info.subdivision_info.builder_name }})
                                </a>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Program" prop="home__homestatuses__eep_program">
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                <ul>
                                    <li *ngFor="let program of (row.home_info.eep_programs)">
                                        {{ program.eep_program.name }}
                                    </li>
                                </ul>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Program Status" prop="home__homestatuses__state">
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                <ul>
                                    <li *ngFor="let program of (row.home_info.eep_programs)">
                                        {{ eepProgramHomeStatusStateLabelMapping[getState(program)] }}
                                    </li>        
                                </ul>
                            </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-footer>
                            <ng-template ngx-datatable-footer-template>
                                <div class="col-6">
                                    Showing {{storedParams.page}} to {{count | divide: 25 }} of {{count}}
                                </div>
                                <div class="col-6">
                                    <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                        [pagerRightArrowIcon]="'datatable-icon-right'"
                                        [pagerPreviousIcon]="'datatable-icon-prev'"
                                        [pagerNextIcon]="'datatable-icon-skip'" [page]="storedParams.page" [size]="25"
                                        [count]="count" (change)="onFooterPage($event)">
                                    </datatable-pager>
                                </div>
                            </ng-template>
                        </ngx-datatable-footer>
                    </ngx-datatable>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #loading>
    <div class="row">
        <div class="col-12">
            <div class="d-flex justify-content-center" style="height: 320px;">
                <mat-spinner [diameter]="50"></mat-spinner>
            </div>
        </div>
    </div>
</ng-template>