import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { forkJoin, Subject, Subscription } from 'rxjs';
import {
  MessageLevelLabelMapping,
  ModernMessage,
} from '@/data/messaging/models/modernMessage';
import { DomService } from '@/core/services/dom.service';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { MessageService } from '@/data/messaging/services/message.service';
import { UserService } from '@/data/core/services/user.service';
import { filter, first, skip, takeUntil } from 'rxjs/operators';
import { User } from '@/data/core/models/user';
import { getInfoUser } from '@/state/selectors/info.selector';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { ServerResponse } from '@/core/schemes/server-response';
import { toggleLoading } from '@/state/actions/app.actions';
import {
  getRecentMessages,
  getRecentMessagesCount,
} from '@/state/selectors/messaging/message.selector';

@Component({
  selector: 'app-message-dropdown',
  templateUrl: './message-dropdown.component.html',
  styleUrls: ['./message-dropdown.component.scss'],
})
export class MessageDropdownComponent implements OnInit, OnDestroy {
  protected readonly MessageLevelLabelMapping = MessageLevelLabelMapping;
  public initialized = false;
  public isLoading = true;

  public currentUser: User;

  public entities: ModernMessage[] = [];
  public entitiesCount = 0;

  @ViewChild('messageDropdown', { read: ElementRef, static: false })
  messageDropdown: ElementRef;

  public isCollapsed = true;
  public outsideClickSubscription$: Subscription;

  private componentDestroyed$ = new Subject();

  constructor(
    private messageService: MessageService,
    private domService: DomService,
    private userService: UserService,
    private store: Store<AppState>,
    private uiHelperService: UIHelperService
  ) {}

  ngOnInit() {
    this.store
      .select(getInfoUser)
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(user => user !== null)
      )
      .subscribe((user: User) => {
        this.currentUser = user;
        this.list();
      });

    this.store
      .select(getRecentMessages)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe({
        next: (messages: ModernMessage[]) => {
          this.entities = messages;
        },
      });

    this.store
      .select(getRecentMessagesCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe({
        next: (messagesCount: number) => {
          this.entitiesCount = messagesCount;
        },
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();

    this.outsideClickSubscription$?.unsubscribe();
  }

  documentClickListener(target: any): void {
    if (!this.messageDropdown.nativeElement.contains(target)) {
      this.close();
    }
  }

  onCollapse($event: MouseEvent) {
    $event.preventDefault();
    if (this.isCollapsed) {
      this.open();
    } else {
      this.close();
    }
  }

  open() {
    this.outsideClickSubscription$?.unsubscribe();
    this.isCollapsed = false;
    this.outsideClickSubscription$ = this.domService.documentClickedTarget
      .pipe(skip(1))
      .subscribe(target => this.documentClickListener(target));
  }

  close() {
    this.isCollapsed = true;
    this.outsideClickSubscription$?.unsubscribe();
  }

  list() {
    this.isLoading = true;

    forkJoin({
      recentMessages: this.userService
        .messagesPull(this.currentUser.id)
        .pipe(first()),
    }).subscribe({
      next: ({
        recentMessages,
      }: {
        recentMessages: ServerResponse<ModernMessage>;
      }) => {
        this.entities = recentMessages.results;
        this.entitiesCount = recentMessages.count;
        this.initialized = true;
        this.isLoading = false;
      },
      error: error => this.uiHelperService.handleUserRequestError(error),
    });
  }

  onMarkAllAsRead($event: MouseEvent) {
    $event.preventDefault();
    this.store.dispatch(toggleLoading({ payload: true }));
    this.userService
      .messagesMarkAllAsRead(this.currentUser.id)
      .pipe(first(), takeUntil(this.componentDestroyed$))
      .subscribe({
        next: _ => {
          this.store.dispatch(toggleLoading({ payload: false }));
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  onRead($event: MouseEvent, message: ModernMessage) {
    $event.preventDefault();
    this.isLoading = true;

    this.messageService
      .read(message.id)
      .pipe(first(), takeUntil(this.componentDestroyed$))
      .subscribe({
        next: (_: ModernMessage) => {
          this.isLoading = false;
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }
}
