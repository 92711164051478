<ng-container *ngIf="company">
    <ng-container [ngSwitch]="displayType">
        <ng-container *ngSwitchCase="companyDisplayComponentType.onlyName">{{ company?.name }}</ng-container>
        <ng-container *ngSwitchCase="companyDisplayComponentType.nameAndAddress">
            {{ company?.name }}
            <small>[{{ company?.street_line1 }}
                , {{ company?.city_info?.name }} {{ company?.city_info?.county_info?.state }} {{ company?.zipcode}}
                ]</small>
        </ng-container>
        <a [routerLink]="['/', 'company', company?.company_type, 'detail', company?.id]" *ngSwitchCase="companyDisplayComponentType.onlyNameAsLink">
            {{ company?.name }}
        </a>
        <ng-container *ngSwitchCase="companyDisplayComponentType.nameAndType">{{ company?.name }} [{{ componentCompanyTypeDataMap[company?.company_type].displayName }}]</ng-container>
        <a [routerLink]="['/', 'company', company?.company_type, 'detail', company?.id]" *ngSwitchCase="companyDisplayComponentType.nameAndTypeAsLink">
            {{ company?.name }} [{{ componentCompanyTypeDataMap[company?.company_type].displayName }}]
        </a>
    </ng-container>
</ng-container>
