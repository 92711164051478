import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UIHelperService,
  UISettings,
} from '@/shared/services/ui-helper.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-rows-per-page',
  templateUrl: './rows-per-page.component.html',
  styleUrls: ['./rows-per-page.component.scss'],
})
export class RowsPerPageComponent implements OnInit, OnDestroy {
  @Input() pageSizeParamKey = 'page_size';
  @Output() onRowsPerPageChanged = new EventEmitter<number>();
  public uiSettings: UISettings;
  public fomGroup: FormGroup;

  public rowsPerPageLabelMapping = {
    '25': 25,
    '50': 50,
    '100': 100,
    '250': 250,
  };

  private componentDestroyed$ = new Subject();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private uiHelperService: UIHelperService
  ) {
    this.uiSettings = this.uiHelperService.getUISettings();
  }

  ngOnInit(): void {
    this.setupForm();
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  setupForm() {
    this.fomGroup = this.fb.group({
      rowsPerPage: [null],
    });

    this.fomGroup
      .get('rowsPerPage')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe(value => {
        this.uiSettings.rowsPerPage = value;
        this.onRowsPerPageChanged.emit(value);
      });

    this.activatedRoute.params
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(params => {
        this.onRowsPerPageChanged.emit(this.uiSettings.rowsPerPage);
      });

    this.fomGroup.patchValue(
      { rowsPerPage: this.uiSettings.rowsPerPage.toString() },
      { emitEvent: true, onlySelf: true }
    );
  }
}
