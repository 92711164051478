import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BaseDataService } from '@/core/services/base-data-service';
import { Observable } from 'rxjs';
import { WindowBackendDict } from '../models/enclosure/Window';

@Injectable({ providedIn: 'root' })
export class WindowService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/window/`;

  update(
    id: number,
    windowTypeChanges: Partial<WindowBackendDict>
  ): Observable<WindowBackendDict> {
    return this.http.put<WindowBackendDict>(
      `${this.baseUrl}${id}/`,
      windowTypeChanges
    );
  }

  delete(id: number) {
    return this.http.delete(`${this.baseUrl}${id}/`);
  }
}
