<mat-expansion-panel *ngIf="(totalErrorCount > 0 || totalWarningCount > 0)">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <div class="title-container">
                <app-model-link entityName="utilityRate" [entityId]="entityId" [title]="name">
                </app-model-link>
                <div class="d-flex align-items-center">
                    <app-error-count [errorCount]="totalErrorCount" [warningCount]="totalWarningCount">
                    </app-error-count>
                </div>
            </div>
        </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-accordion>
        <app-model-errors-tree [entityId]="entityId" entityName="utilityRate"></app-model-errors-tree>
    </mat-accordion>
</mat-expansion-panel>