import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-model-list',
  templateUrl: './model-list.component.html',
  styleUrls: ['./model-list.component.scss'],
})
export class ModelListComponent {
  @Input() title: string;
  @Input() errorCount: number;
  @Input() itemsCount = 0;
  @Input() expanded = false;
  @Output() add = new EventEmitter<void>();

  addingNewItem = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('itemsCount')) {
      this.addingNewItem = false;
    }
  }

  addItem() {
    this.addingNewItem = true;
    this.add.emit();
  }
}
