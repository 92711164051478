import { createAction, props } from '@ngrx/store';
import { ApexRequest, ApexRequestDocument } from '@/data/home/models';

export const resetApexRequest = createAction('[ApexRequest] Reset');

export const loadApexRequest = createAction(
  '[ApexRequest] Detail',
  props<{ payload: ApexRequest }>()
);

// CustomerDocuments

export const loadApexRequestDocuments = createAction(
  '[ApexRequest Documents] Documents',
  props<{ payload: ApexRequestDocument[] }>()
);

export const loadApexRequestDocumentsCount = createAction(
  '[ApexRequest Documents] Documents Count',
  props<{ payload: number }>()
);
