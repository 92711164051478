import { Component, Input, OnInit } from '@angular/core';
import { ConfirmedGeocodeResponse } from '@/modules/geocoder/components/confirm-geocode-response-dialog/confirm-geocode-response';

export enum AddressTypeComponentDisplay {
  default = 'default',
}

/**
 Using to show address Geocode / Manual override indicator
 */
@Component({
  selector: 'app-address-type',
  templateUrl: './address-type.component.html',
  styleUrls: ['./address-type.component.scss'],
})
export class AddressTypeComponent implements OnInit {
  @Input() address: ConfirmedGeocodeResponse;
  @Input() display: AddressTypeComponentDisplay =
    AddressTypeComponentDisplay.default;

  stateDisplay = AddressTypeComponentDisplay;

  ngOnInit(): void {}
}
