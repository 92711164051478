import { createSelector } from '@ngrx/store';
import { SimulationState } from '../reducers';
import { selectSimulationFeature } from '../simulation.selectors';

export const doesFormIdExist = (formId: string) =>
  createSelector(selectSimulationFeature, (state: SimulationState) =>
    state.form.entities.hasOwnProperty(formId)
  );

export const selectFormStatus = (formId: string) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.form.entities[formId]
  );
