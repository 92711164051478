<ng-container>
    <li class="list-group-item header" style="background-color:rgba(0, 0, 0, 0.03); align-items: center;">
        <b>
            Heater
        </b>
    </li>
    <li class="list-group-item">
        <b>Name:</b>
        {{ equipment.heater_info.name }}
    </li>
    <li class="list-group-item">
        <b>Fuel Type:</b>
        {{ FuelTypeLabels[equipment.heater_info.fuel] }}
    </li>

    <li class="list-group-item">
        <b>Capacity:</b>
        {{ equipment.heater_info.capacity }}
        {{ CapacityUnitLabels[equipment.heater_info.capacity_unit] }}
    </li>
    <li class="list-group-item">
        <b>Efficiency:</b>
        {{ equipment.heater_info.efficiency }}
        {{ HeatingEfficiencyUnitLabels[equipment.heater_info.efficiency_unit] }}
    </li>
    <li class="list-group-item" *ngIf="equipment.heater_info.motor_type">
        <b>Motor Type:</b>
        {{ equipment.heater_info.motor_type }}
    </li>
</ng-container>