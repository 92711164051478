import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomerDocument } from '@/data/filehandling/models';
import { CustomerDocumentService } from '@/data/filehandling/services/customer-document.service';
import { first, switchMap, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getInfoUser } from '@/state/selectors/info.selector';
import { forkJoin, of, Subject } from 'rxjs';
import { toggleLoading } from '@/state/actions/app.actions';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { User } from '@/data/core/models/user';
import { AppState } from '@/state/reducers';
import { BaseDataService } from '@/core/services/base-data-service';

export class CustomerDocumentEditDialogData {
  serviceCls: any;
  customerDocument?: CustomerDocument;
  commit = true;
  retrieve = true;
}

@Component({
  selector: 'app-customer-document-edit-dialog',
  templateUrl: './customer-document-edit-dialog.component.html',
  styleUrls: ['./customer-document-edit-dialog.component.scss'],
})
export class CustomerDocumentEditDialogComponent implements OnInit, OnDestroy {
  public initialized: boolean;
  public isEditForm = false;
  public entity?: CustomerDocument;
  public commit = true;
  public retrieve = true;
  public form: FormGroup;

  private componentDestroyed$ = new Subject();
  private currentUser: User;

  private modelService$: BaseDataService;

  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<CustomerDocument>,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private injector: Injector,
    private customerDocumentService: CustomerDocumentService,
    private uiHelperService: UIHelperService,
    @Inject(MAT_DIALOG_DATA) public data: CustomerDocumentEditDialogData
  ) {}

  ngOnInit(): void {
    this.modelService$ = this.injector.get(this.data.serviceCls);

    if (this.data?.customerDocument) {
      this.entity = Object.assign({}, this.data?.customerDocument);
    }
    this.commit = this.data?.commit || true;

    if (this.entity?.id) {
      this.isEditForm = true;
    }

    const sources = {
      currentUser: this.store.select(getInfoUser).pipe(first()),
      customerDocument: of(this.entity),
    };

    this.store.dispatch(toggleLoading({ payload: true }));

    if (this.isEditForm && this.retrieve) {
      sources.customerDocument = this.customerDocumentService
        .retrieve(this.entity.id, { ignoreStore: true })
        .pipe(takeUntil(this.componentDestroyed$), first());
    }

    forkJoin(sources)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: ({ currentUser, customerDocument }) => {
          this.store.dispatch(toggleLoading({ payload: false }));

          this.currentUser = currentUser;
          this.entity = customerDocument;
          this.setupForm();
          this.initialized = true;
          if (this.isEditForm) {
            this.hydrateForm();
          }
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  hydrateForm() {
    this.form.patchValue(Object.assign({}, this.entity), {
      emitEvent: false,
      onlySelf: true,
    });

    let doc_access_level = 'private';

    if (this.entity?.is_public && this.entity?.login_required) {
      doc_access_level = 'public_logged_in';
    }

    if (this.entity?.is_public && !this.entity?.login_required) {
      doc_access_level = 'public_global';
    }

    this.form.patchValue(
      {
        doc_access_level,
      },
      {
        emitEvent: false,
        onlySelf: true,
      }
    );
  }

  setupForm() {
    this.form = this.fb.group({
      document: [null],
      description: [''],
      is_public: [true],
      login_required: [true],
      doc_access_level: [null, [Validators.required]],
    });
  }

  save($event: MouseEvent) {
    $event.preventDefault();
    if (this.isEditForm) {
      this.edit();
    } else {
      this.create();
    }
  }

  private getFormData(): CustomerDocument {
    const data = new CustomerDocument();
    data.description = this.form.value.description;
    const doc_access_level = this.form.value.doc_access_level;

    switch (doc_access_level) {
      case 'public_logged_in':
        data.is_public = true;
        data.login_required = true;
        break;
      case 'public_global':
        data.is_public = true;
        data.login_required = false;
        break;
      default:
        data.is_public = false;
        data.login_required = true;
    }
    return data;
  }

  private create() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    if (!this.commit) {
      this.dialogRef.close(this.entity);
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const data = this.getFormData();

    this.modelService$
      .documentsCreate(this.entity.objectId, data)
      .pipe(
        first(),
        // update file fields
        switchMap(customerDocument => {
          const formData = new FormData();
          formData.append('document', this.form.value.document);
          return this.customerDocumentService.update(
            customerDocument.id,
            formData
          );
        })
      )
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: customerDocument => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close(customerDocument);
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  private edit() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    if (!this.commit) {
      this.dialogRef.close(this.entity);
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const data: CustomerDocument = this.getFormData();

    this.customerDocumentService
      .update(this.entity.id, data)
      .pipe(
        first(),
        // update file fields
        switchMap(customerDocument => {
          const formData = new FormData();
          formData.append('document', this.form.value.document);
          return this.customerDocumentService.update(
            customerDocument.id,
            formData
          );
        })
      )
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: customerDocument => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close(customerDocument);
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }
}
