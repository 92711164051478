import { CompanyInfo } from '@/data/company/models/company';

export class SponsorPreferences {
  id?: number;
  sponsor?: number;
  sponsor_info?: CompanyInfo;
  sponsored_company?: number;
  sponsored_company_info?: CompanyInfo;
  can_edit_profile?: boolean;
  can_edit_identity_fields?: boolean;
  notify_sponsor_on_update?: boolean;
}

export class AffiliationPreferences {
  id?: number;
  sponsor?: number;
  sponsor_info?: CompanyInfo;
}

export class SponsoringPreferences {
  id?: number;
  sponsored_company?: number;
  sponsored_company_info?: CompanyInfo;
  can_edit_profile?: boolean;
  can_edit_identity_fields?: boolean;
  notify_sponsor_on_update?: boolean;
}
