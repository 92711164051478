import { Component, Input, OnInit } from '@angular/core';
import {
  CustomerHIRLProductList,
  Product,
  ProductList,
  ProductType,
} from '@/data/product/models';

export enum ProductDisplayComponentType {
  onlyName = 'onlyName',
  onlyNameAsLink = 'onlyNameAsLink',
}

@Component({
  selector: 'app-product-display',
  templateUrl: './product-display.component.html',
  styleUrls: ['./product-display.component.scss'],
})
export class ProductDisplayComponent implements OnInit {
  @Input() displayType:
    | ProductDisplayComponentType
    | keyof typeof ProductDisplayComponentType =
    ProductDisplayComponentType.onlyName;
  @Input() product?: Product | ProductList | CustomerHIRLProductList;

  public ProductDisplayComponentType = ProductDisplayComponentType;

  ngOnInit(): void {}

  protected readonly ProductType = ProductType;
}
