import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface HIRLProjectReasonDialogData {
  reason: string;
}

@Component({
  selector: 'app-hirl-project-reason-dialog',
  templateUrl: './hirl-project-reason-dialog.component.html',
  styleUrls: ['./hirl-project-reason-dialog.component.scss'],
})
export class HIRLProjectReasonDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<HIRLProjectReasonDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: HIRLProjectReasonDialogData
  ) {}

  ngOnInit() {}

  cancelClick(): void {
    this.dialogRef.close();
  }
}
