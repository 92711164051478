<div class="d-flex flex-column">
    <div class="d-flex flex-column justify-content-between position-relative">
        <app-loading-overlay *ngIf="supplyLoading"></app-loading-overlay>
        <div class="d-flex flex-column">
            <div class="d-flex justify-content-center">
                <label class="heading-label">Supply Duct</label>
            </div>
            <div  class="d-flex flex-column">
                <div  *ngFor="let ductId of supplyDuctIds; let first = first;" class="d-flex align-items-baseline">
                    <button *appRequiresEditPermission mat-icon-button (click)="removeDuct(ductId, ductTypes.SUPPLY)" class="p-0 pl-2"><mat-icon [inline]="true">delete</mat-icon></button>
                    <app-duct [entityId]="ductId" [showLabels]="first" class="w-100"></app-duct>
                </div>
            </div>
        </div>
        <button *appRequiresEditPermission class="add-button" (click)="addNewDuct(ductTypes.SUPPLY)" mat-icon-button>
            Add <mat-icon inline="true">add_circle</mat-icon>
        </button>
    </div>
    <div class="d-flex justify-content-center">
        <div class="spacer"></div>
    </div>
    <div class="d-flex flex-column justify-content-between position-relative">
        <app-loading-overlay *ngIf="returnLoading"></app-loading-overlay>
        <div class="d-flex flex-column">
            <div class="d-flex justify-content-center mt-3">
                <label class="heading-label">Return Duct</label>
            </div>
            <div  class="d-flex flex-column">
                <div  *ngFor="let ductId of retunDuctIds; let first = first;" class="d-flex align-items-baseline">
                    <button *appRequiresEditPermission mat-icon-button (click)="removeDuct(ductId, ductTypes.RETURN)" class="p-0 pl-2"><mat-icon [inline]="true">delete</mat-icon></button>
                    <app-duct [entityId]="ductId" [showLabels]="first" class="w-100"></app-duct>
                </div>
            </div>
        </div>
        <button *appRequiresEditPermission class="add-button" (click)="addNewDuct(ductTypes.RETURN)" mat-icon-button>
            Add <mat-icon inline="true">add_circle</mat-icon>
        </button>
    </div>
</div>