import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { User } from '@/data/core/models/user';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { AppFieldValidatorsService } from '@/core/services/field-validators.service';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, takeUntil } from 'rxjs/operators';
import { toggleLoading } from '@/state/actions/app.actions';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';
import { CompanyService } from '@/data/company/services/company-base.service';
import {
  Company,
  CustomerHIRLGCPFullYearExpirationNotification,
} from '@/data/company/models';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-hirl-trigger-renewal-notification-dialog',
  templateUrl: './hirl-trigger-renewal-notification-dialog.component.html',
  styleUrls: ['./hirl-trigger-renewal-notification-dialog.component.scss'],
})
export class HirlTriggerRenewalNotificationDialogComponent
  implements OnInit, OnDestroy
{
  protected readonly CustomerHIRLSettings = CustomerHIRLSettings;

  public initialized: boolean;
  public currentUser: User;

  public currentUserIsCustomerOrSuper = false;

  public isEditForm = false;
  public form: FormGroup;

  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<HirlTriggerRenewalNotificationDialogComponent>,
    private fb: FormBuilder,
    private companyService: CompanyService,
    private dialog: MatDialog,
    private appFieldValidatorsService: AppFieldValidatorsService,
    private uiHelperService: UIHelperService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    const sources = {
      currentUser: this.store.select(getInfoUser).pipe(first()),
    };

    this.store.dispatch(toggleLoading({ payload: true }));

    forkJoin(sources)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: ({ currentUser }) => {
          this.store.dispatch(toggleLoading({ payload: false }));

          this.currentUser = currentUser;
          this.currentUserIsCustomerOrSuper =
            this.currentUser.company_info?.slug ===
              CustomerHIRLSettings.companySlug || this.currentUser.is_superuser;
          this.setupForm();
          this.initialized = true;
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  private setupForm() {
    this.form = this.fb.group({
      company_info: [null, [Validators.required]],
    });
  }

  save($event: MouseEvent) {
    $event.preventDefault();

    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const data = new CustomerHIRLGCPFullYearExpirationNotification();

    data.company = this.form.value.company_info?.id;

    this.companyService
      .customer_hirl_gcp_send_full_year_expiration_notification(data)
      .pipe(first())
      .subscribe({
        next: (entity: Company) => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close(entity);
        },
        error: (error: HttpErrorResponse) =>
          this.uiHelperService.handleUserRequestError(error),
      });
  }
}
