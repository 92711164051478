import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import * as FrameFloorTypeActions from './actions';
import * as SharedActions from '../shared/shared.actions';
import { FrameFloorTypeService } from '@/data/simulation/services/frame-floor-type.service';
import { ModelGraphService } from '../../services/model-graph.service';
import { FrameFloorTypeValidator } from '../../validators/frame-floor-type.validator';

@Injectable()
export class FrameFloorTypeEffects {
  loadDetailedFrameFloorTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FrameFloorTypeActions.loadDetailedFrameFloorTypes),
      mergeMap(action => {
        const errors = FrameFloorTypeValidator.validate(action.floorTypes);
        return of(
          FrameFloorTypeActions.loadFrameFloorTypesSuccess({
            floorTypes: action.floorTypes,
            errors,
          })
        );
      })
    );
  });

  updateFrameFloorType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FrameFloorTypeActions.updateFrameFloorType),
      mergeMap(action =>
        this.floorTypeService.update(action.floorTypeChanges).pipe(
          mergeMap(updatedFloorType => {
            const errors = FrameFloorTypeValidator.validate([updatedFloorType]);
            return of(
              FrameFloorTypeActions.loadFrameFloorTypesSuccess({
                floorTypes: [updatedFloorType],
                errors,
              })
            );
          }),
          catchError(error =>
            of(
              FrameFloorTypeActions.updateFrameFloorTypeFailure({
                id: action.floorTypeChanges.id,
              }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private modelGraphService: ModelGraphService,
    private floorTypeService: FrameFloorTypeService
  ) {}
}
