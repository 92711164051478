<mat-expansion-panel *ngIf="enable && (totalErrorCount > 0 || totalWarningCount > 0)">
    <mat-expansion-panel-header class="example-headers-align">
        <mat-panel-title>
            <div class="title-container">
                <app-model-link entityName="infiltration" title="Infiltration">
                </app-model-link>
                <div class="d-flex align-items-center">
                    <app-error-count
                        [errorCount]="totalErrorCount"
                        [warningCount]="totalWarningCount">
                    </app-error-count>
                </div>
            </div>
        </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-accordion class="example-headers-align">
        <app-model-errors-tree [entityId]="entityId" entityName="infiltration"></app-model-errors-tree>
    </mat-accordion>
</mat-expansion-panel>