<div class="container" *ngIf="initialized">
    <div class="row">
        <div class="col-12">
            <h3>
                <button mat-raised-button color="accent" appBackButton [fallbackUrl]="'/hi/hirl_rpc_updater_request_list'">
                    <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
                </button>
                NGBS Updater Tool
            </h3>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <hr>
        </div>
    </div>
    <form [formGroup]="formGroup">
        <div class="row">
            <div class="col-12">
                <p>
                    <b>Use this tool to update previous versions of an NGBS Verification Report to the latest version</b>
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Project Type</mat-label>
                    <mat-select formControlName="project_type">
                        <mat-option *ngFor="let item of rpcUpdaterProjectTypeLabelMapping | defaultOrderKeyValue" [value]="item.key">
                            {{ item.value }}
                        </mat-option>
                    </mat-select>
                    <mat-error
                            *ngIf="formGroup.controls['project_type'].hasError('required')">
                        This field is required
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Scoring Path</mat-label>
                    <mat-select formControlName="scoring_path">
                        <mat-option *ngFor="let item of rpcUpdaterScoringPathLabelMapping | defaultOrderKeyValue" [value]="item.key">
                            {{ item.value }}
                        </mat-option>
                    </mat-select>
                    <mat-error
                            *ngIf="formGroup.controls['scoring_path'].hasError('required')">
                        This field is required
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Verification Report File</mat-label>
                    <app-file-input formControlName="report_file" required></app-file-input>
                    <mat-error *ngIf="formGroup.get('report_file').hasError('required')">
                        This field is required
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>
    <div class="row">
        <div class="col-12 text-center">
            <button mat-raised-button color="accent" (click)="onSubmit($event)">
                Submit
            </button>
        </div>
    </div>
</div>
