import { createSelector } from '@ngrx/store';
import { SimulationState } from '../reducers';
import { selectSimulationFeature } from '../simulation.selectors';

export const selectDuct = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.duct.entities[id]
  );

const selectDuctLoadingStatus = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.duct.loading[id]
  );

export const selectDuctViewSet = (id: number) =>
  createSelector(
    selectDuct(id),
    selectDuctLoadingStatus(id),
    (duct, loadingStatus) => ({
      data: duct,
      loading: loadingStatus,
    })
  );

export const selectDucts = (ids: number[]) =>
  createSelector(selectSimulationFeature, (state: SimulationState) =>
    ids.map(id => state.duct.entities[id])
  );
