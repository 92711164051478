import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import * as SlabTypeActions from './actions';
import * as SharedActions from '../shared/shared.actions';
import { SlabTypeService } from '@/data/simulation/services/slab-type.service';
import { SlabTypeValidator } from '../../validators/slab-type.validator';

@Injectable()
export class SlabTypeEffects {
  loadDetailedSlabTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SlabTypeActions.loadSlabTypes),
      mergeMap(action => {
        const errors = SlabTypeValidator.validate(action.slabTypes);
        return of(
          SlabTypeActions.loadSlabTypesSuccess({
            slabTypes: action.slabTypes,
            errors,
          })
        );
      })
    );
  });

  updateSlabType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SlabTypeActions.updateSlabType),
      mergeMap(action =>
        this.slabTypeService.update(action.slabTypeChanges).pipe(
          mergeMap(updatedSlabType => {
            const errors = SlabTypeValidator.validate([updatedSlabType]);
            return of(
              SlabTypeActions.loadSlabTypesSuccess({
                slabTypes: [updatedSlabType],
                errors,
              })
            );
          }),
          catchError(error =>
            of(
              SlabTypeActions.updateSlabTypeFailure({
                id: action.slabTypeChanges.id,
              }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private slabTypeService: SlabTypeService
  ) {}
}
