<div class="generic-mat-select-container">
  <mat-form-field appearance="none" class="generic-mat-select-field">
    <mat-select [formControl]="control" [multiple]="multiple" (selectionChange)="onSelectionChanged($event)">
      <ng-container  *ngIf="!isLoading">
        <mat-option [value]="emptyChoiceValue" *ngIf="hasEmptyChoice && !isLoading">
          {{ emptyChoiceLabel }}
        </mat-option>
        <mat-option *ngFor="let result of results" [value]="result[parentKey]">
          {{ onOptionDisplay(result) }}
        </mat-option>
      </ng-container>
    </mat-select>
    <mat-spinner matSuffix diameter="20" *ngIf="isLoading"></mat-spinner>
  </mat-form-field>
</div>
