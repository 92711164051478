import { createReducer, on } from '@ngrx/store';
import * as FrameFloorActions from './actions';
import { FrameFloorBackendDict } from '@/data/simulation/models/enclosure/FrameFloor';
import { BaseState, sharedInitialState } from '../shared/base.state';
import { addErrorCount, addStateErrorCount } from '../helper';

export type FrameFloorState = BaseState<FrameFloorBackendDict>;

export const initialState: FrameFloorState = sharedInitialState;

export const reducer = createReducer(
  initialState,
  on(FrameFloorActions.loadFrameFloorsSuccess, (currentState, payload) => {
    const entities = payload.frameFloors.reduce(
      (acc, framefloor) => ({ ...acc, [framefloor.id]: framefloor }),
      {}
    );

    const loadingStatus = payload.frameFloors.reduce(
      (acc, framefloor) => ({ ...acc, [framefloor.id]: false }),
      {}
    );

    return {
      entities: { ...currentState.entities, ...entities },
      loading: { ...currentState.loading, ...loadingStatus },
      errors: { ...currentState.errors, ...addStateErrorCount(payload.errors) },
    };
  }),

  on(FrameFloorActions.removeFrameFloorSuccess, (currentState, { id }) => {
    const { [id]: removed, ...entities } = currentState.entities;
    const { [id]: removedLoading, ...loading } = currentState.loading;
    const { [id]: removedErrors, ...errors } = currentState.errors;

    return {
      entities,
      loading,
      errors,
    };
  }),

  on(FrameFloorActions.updateFrameFloor, (state, { frameFloorChanges }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [frameFloorChanges.id]: true,
      },
    };
  }),

  on(FrameFloorActions.removeFrameFloor, (state, { frameFloor }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [frameFloor.id]: true,
      },
    };
  }),

  on(
    FrameFloorActions.updateFrameFloorFailure,
    FrameFloorActions.removeFrameFloorFailure,
    (state, { id }) => {
      return {
        ...state,
        loading: {
          ...state.loading,
          [id]: false,
        },
      };
    }
  ),

  on(FrameFloorActions.updateFrameFloorErrors, (state, { id, errors }) => {
    return {
      ...state,
      errors: {
        ...state.errors,
        [id]: errors,
      },
    };
  }),

  on(
    FrameFloorActions.updateFrameFloorSuccess,
    (state, { frameFloor, errors }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          [frameFloor.id]: frameFloor,
        },
        loading: {
          ...state.loading,
          [frameFloor.id]: false,
        },
        errors: {
          ...state.errors,
          [frameFloor.id]: addErrorCount(errors),
        },
      };
    }
  ),

  on(FrameFloorActions.setFrameFloorType, (state, { floorId, floorTypeId }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        [floorId]: {
          ...state.entities[floorId],
          type: floorTypeId,
        },
      },
    };
  })
);
