import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SimulationModelsState, SimulationState } from './reducers';
import { ModelErrorsWithCount } from './shared/base.state';

export const selectSimulationFeature =
  createFeatureSelector<SimulationState>('simulation');

export const selectStateEntites = <K extends keyof SimulationModelsState>(
  stateName: K,
  entityIds: number[]
) =>
  createSelector(selectSimulationFeature, (state: SimulationState) => {
    return entityIds.map(id => state[stateName].entities[id]);
  });

export const selectErrorViewSet = (modelName: string, id: number) =>
  createSelector(
    selectSimulationFeature,
    selectErrors(modelName, id),
    (state: SimulationState, modelErrors) => {
      const flags = Object.values(
        state.simulationConfig.entities
      )[0].qa_flags.map(flag => flag.name);
      return { errors: modelErrors, flags };
    }
  );

export const selectErrors = (modelName: string, id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState): ModelErrorsWithCount<any> =>
      state[modelName].errors[id]
  );

export const selectModelEntities = (modelName: string) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state[modelName].entities
  );

export const selectModelEntitiesLabels = (
  modelName: string,
  exculder: (any) => boolean,
  labelExtractor: (any) => string
) =>
  createSelector(selectModelEntities(modelName), entities => {
    const labels = {};

    Object.keys(entities).forEach(key => {
      if (exculder(entities[key])) return;

      let label = `${entities[key].name} (#${key})`;
      if (labelExtractor) {
        label = labelExtractor(entities[key]);
      }

      labels[label] = Number(key);
    });
    return labels;
  });

export const selectEntity = (modelName: string, id: number) =>
  createSelector(selectModelEntities(modelName), entities => {
    return entities[id];
  });

export const selectEntityName = (modelName: string, id: number) =>
  createSelector(selectModelEntities(modelName), entities => {
    return entities[id].name;
  });

export const selectErrorAndWarningCount = createSelector(
  selectSimulationFeature,
  (state: SimulationState) => {
    let errorCount = 0;
    let warningCount = 0;
    Object.entries(state).forEach(([key, model]) => {
      if (!model.hasOwnProperty('errors')) return;

      const errors = Object.values(model.errors) as ModelErrorsWithCount<any>[];

      for (const error of errors) {
        errorCount += error.errorCount || 0;
      }

      warningCount += errors.reduce(
        (acc, model) => acc + (model.warningCount || 0),
        0
      );
    });

    return { errorCount, warningCount };
  }
);
