import { LoginComponent } from '@/modules/auth/pages/login/login.component';
import { NonAuthGuard } from '@/core/guards';

export const AuthRouting = [
  {
    path: 'auth',
    canActivate: [NonAuthGuard],
    children: [
      {
        path: 'login',
        component: LoginComponent,
      },
    ],
  },
];
