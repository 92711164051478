import { CustomerHIRLSettings } from '../../constants';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  ClientAgreement,
  ClientAgreementStateLabelMapping,
} from '@/data/customer-hirl/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '@/data/core/models/user';
import { forkJoin, of, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ClientAgreementService } from '@/data/customer-hirl/services/client-agreement.service';
import { GeocoderService } from '@/data/geocoder/services/geocoder.service';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, takeUntil } from 'rxjs/operators';
import { toggleLoading } from '@/state/actions/app.actions';

export class HirlClientAgreementForceStateChangeDialogData {
  clientAgreement: ClientAgreement;
  commit = true;
  retrieve = true;
}

@Component({
  selector: 'app-hirl-client-agreement-force-state-change-dialog',
  templateUrl:
    './hirl-client-agreement-force-state-change-dialog.component.html',
  styleUrls: [
    './hirl-client-agreement-force-state-change-dialog.component.scss',
  ],
})
export class HirlClientAgreementForceStateChangeDialogComponent
  implements OnInit, OnDestroy
{
  protected readonly ClientAgreementStateLabelMapping =
    ClientAgreementStateLabelMapping;
  protected readonly CustomerHIRLSettings = CustomerHIRLSettings;

  public initialized: boolean;
  public entity?: ClientAgreement;
  public commit = true;
  public retrieve = true;
  public form: FormGroup;
  public currentUser: User;

  private componentDestroyed$: Subject<boolean> = new Subject();

  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<HirlClientAgreementForceStateChangeDialogComponent>,
    private fb: FormBuilder,
    private clientAgreementService: ClientAgreementService,
    private geocoderService: GeocoderService,
    private dialog: MatDialog,
    private uiHelperService: UIHelperService,
    @Inject(MAT_DIALOG_DATA)
    public data: HirlClientAgreementForceStateChangeDialogData
  ) {
    this.entity = Object.assign({}, data?.clientAgreement);
    this.commit = data?.commit || true;
  }

  ngOnInit() {
    const sources = {
      currentUser: this.store.select(getInfoUser).pipe(first()),
      clientAgreement: of(this.entity),
    };

    this.store.dispatch(toggleLoading({ payload: true }));

    if (this.retrieve) {
      sources.clientAgreement = this.clientAgreementService
        .retrieve(this.entity.id, { ignoreStore: true })
        .pipe(takeUntil(this.componentDestroyed$), first());
    }

    forkJoin(sources)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: ({ currentUser, clientAgreement }) => {
          this.store.dispatch(toggleLoading({ payload: false }));

          this.currentUser = currentUser;
          this.entity = clientAgreement;
          this.setupForm();
          this.initialized = true;
          this.hydrateForm();
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  private setupForm() {
    this.form = this.fb.group({
      state: [null, Validators.required],
    });
  }

  hydrateForm() {
    this.form.patchValue(Object.assign({}, this.entity), {
      emitEvent: false,
      onlySelf: true,
    });
  }

  save($event: MouseEvent) {
    $event.preventDefault();

    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    if (!this.commit) {
      this.dialogRef.close(this.entity);
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const data: ClientAgreement = this.getFormData();

    this.clientAgreementService
      .force_state(data, this.entity.id)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: clientAgreement => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close(clientAgreement);
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  private getFormData(): ClientAgreement {
    const data: ClientAgreement = new ClientAgreement();
    data.state = this.form.value.state;
    return data;
  }
}
