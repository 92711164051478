import { Injectable } from '@angular/core';
import {
  CustomerHIRLInvoiceManagementList,
  InvoiceItemGroup,
} from '@/data/invoicing/models';
import { ServerResponse } from '@/core/schemes/server-response';
import { environment } from '../../../../environments/environment';
import { ListRequestParams } from '@/core/schemes/request-params';
import { EEPProgramHomeStatusState } from '@/data/home/models/eep_program_home_status';
import { BaseDataService } from '@/core/services/base-data-service';
import { ClientAgreementState } from '@/data/customer-hirl/models';
import { Observable } from 'rxjs';

export class InvoiceItemGroupRequestParams extends ListRequestParams {
  uninvoiced?: boolean;
  home_status__state?: EEPProgramHomeStatusState;
  created_at__gte?: Date;
  created_at__lte?: Date;
  current_balance_range_min?: string;
  current_balance_range_max?: string;
}

export class CustomerHIRLInvoiceItemGroupRequestParams extends ListRequestParams {
  uninvoiced?: boolean;
  home_status__state?: EEPProgramHomeStatusState;
  home_status__customer_hirl_project__registration__project_type?: string;
  customer_hirl_erfp_company?: string;
  created_at__gte?: Date;
  created_at__lte?: Date;
  current_balance_range_min?: string;
  current_balance_range_max?: string;
  customer_hirl_project_have_legacy_notified_certification?: string;
  customer_hirl_erfp_ca_status?: ClientAgreementState;
  customer_hirl_client_company?: string;
}

@Injectable({
  providedIn: 'root',
})
export class InvoiceItemGroupService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/invoice_item_groups/`;

  list(params?: InvoiceItemGroupRequestParams) {
    return this.http.get<ServerResponse<InvoiceItemGroup>>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }

  customer_hirl_invoice_management_list(
    params?: InvoiceItemGroupRequestParams
  ): Observable<ServerResponse<CustomerHIRLInvoiceManagementList>> {
    return this.http.get<ServerResponse<CustomerHIRLInvoiceManagementList>>(
      `${this.baseUrl}customer_hirl_invoice_management_list/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  update(invoiceItemGroup: InvoiceItemGroup, invoiceItemGroupId: number) {
    const payload = Object.assign(new InvoiceItemGroup(), invoiceItemGroup);

    return this.http.patch<InvoiceItemGroup>(
      `${this.baseUrl}${invoiceItemGroupId}/`,
      payload
    );
  }
}
