import {
  WindowTypeBackendDict,
  createWindowTypeForm,
} from '@/data/simulation/models/enclosure/WindowType';
import { BaseValidator } from './base.validator';
import { ModelErrors, StateErrors } from '../state/shared/base.state';

export class WindowTypeValidator extends BaseValidator {
  static validateForm(
    windowtype: WindowTypeBackendDict
  ): ModelErrors<WindowTypeBackendDict> {
    const form = createWindowTypeForm(windowtype);
    return form.getFieldAndModelErros();
  }

  static validate(
    windowtypes: WindowTypeBackendDict[]
  ): StateErrors<WindowTypeBackendDict> {
    const errors = {};
    windowtypes.forEach(windowtype => {
      errors[windowtype.id] = this.validateForm(windowtype);
    });
    return errors;
  }
}
