<ng-container>
    <ng-container *ngIf="project?.registration_info?.project_type === projectRegistrationType.singleFamily && !project.is_accessory_structure && !project.is_accessory_dwelling_unit">
        Primary Dwelling
    </ng-container>
    <ng-container *ngIf="project?.is_accessory_structure">
        Accessory Structure
    </ng-container>
    <ng-container *ngIf="project?.commercial_space_type && project?.commercial_space_parent">
        Commercial Space
    </ng-container>
    <ng-container *ngIf="project?.commercial_space_type && !project?.commercial_space_parent">
      Entire Building Commercial Space
    </ng-container>
    <ng-container *ngIf="project?.is_accessory_dwelling_unit">
        Accessory Dwelling Unit
    </ng-container>
    <ng-container *ngIf="project?.registration_info?.project_type === projectRegistrationType.multiFamily && !project.is_accessory_structure && !project.is_include_commercial_space && !project.is_accessory_dwelling_unit">
        Building
    </ng-container>
    <ng-container *ngIf="project?.registration_info?.project_type === projectRegistrationType.landDevelopment">
        Land Development
        <ng-container *ngIf="project?.land_development_project_type === landDevelopmentProjectType.letter">
            (Letter Of Approval)
        </ng-container>
    </ng-container>
</ng-container>
