<ng-container *ngIf="coiDocument">
  <ng-container [ngSwitch]="displayType">
    <ng-container *ngSwitchCase="CoiDocumentDisplayComponentType.onlyName">
      {{ COIDocumentInsuranceTypeMapping[coiDocument.insurance_type] }}
    </ng-container>
    <ng-container *ngSwitchCase="CoiDocumentDisplayComponentType.onlyNameAsLink">
      <a
        [routerLink]="['/', 'company', coiDocument.company_info?.company_type, 'detail', coiDocument.company_info?.id]">
        {{ COIDocumentInsuranceTypeMapping[coiDocument.insurance_type] }}
      </a>
    </ng-container>
    <ng-container *ngSwitchCase="CoiDocumentDisplayComponentType.nameAsLinkAndDates">
      <a
        [routerLink]="['/', 'company', coiDocument.company_info?.company_type, 'detail', coiDocument.company_info?.id]">
        {{ COIDocumentInsuranceTypeMapping[coiDocument.insurance_type] }}
      </a>
      <ng-container *ngIf="coiDocument.start_date || coiDocument.expiration_date">
        - ({{ coiDocument.start_date | date: 'MM/dd/yyyy' || '-' }} - {{ coiDocument.expiration_date | date: 'MM/dd/yyyy' || '-' }})
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
