import { Injectable } from '@angular/core';
import { StateModelName } from '../state.registry';
import { BaseValidator } from '../validators/base.validator';
import { SimulationValidator } from '../validators/simulation.validataor';
import { AboveGradeWallValidator } from '../validators/above-grade-wall.validator';
import { AboveGradeWallTypeValidator } from '../validators/above-grade-wall-type.validator';
import { DoorValidator } from '../validators/door.validator';
import { DoorTypeValidator } from '../validators/door-type.validator';
import { FoundationWallValidator } from '../validators/foundation-wall.validator';
import { FoundationWallTypeValidator } from '../validators/foundation-wall-type.validator';
import { FrameFloorValidator } from '../validators/frame-floor.validator';
import { FrameFloorTypeValidator } from '../validators/frame-floor-type.validator';
import { MechanicalEquipmentValidator } from '../validators/mechanical-equipment.validator';
import { AirConditionerValidator } from '../validators/air-conditioner.validator';
import { AirSourceHeatPumpValidator } from '../validators/air-source-heat-pump.validator';
import { DehumidifierValidator } from '../validators/dehumidifier.validator';
import { GroundSourceHeatPumpValidator } from '../validators/ground-source-heat-pump.validator';
import { HeaterValidator } from '../validators/heater.validator';
import { WaterHeaterValidator } from '../validators/water-heater.validator';
import { LightsValidator } from '../validators/lights.validator';
import { AppliancesValidator } from '../validators/appliances.validator';
import { DistributionSystemValidator } from '../validators/distribution-system.validator';
import { DuctValidator } from '../validators/duct.validator';
import { ThermostatValidator } from '../validators/thermostat.validator';
import { PhotovoltaicValidator } from '../validators/photovoltaic.validator';
import { RimJoistValidator } from '../validators/rim-joist.validator';
import { RimJoistTypeValidator } from '../validators/rim-joist-type.validator';
import { InfiltrationValidator } from '../validators/infiltration.validator';
import { RoofValidator } from '../validators/roof.validator';
import { RoofTypeValidator } from '../validators/roof-type.validator';
import { SlabValidator } from '../validators/slab.validator';
import { SlabTypeValidator } from '../validators/slab-type.validator';
import { SkylightValidator } from '../validators/skylight.validator';
import { SkylightTypeValidator } from '../validators/skylight-type.validator';
import { WaterSystemValidator } from '../validators/water-system.validator';
import { WindowValidator } from '../validators/window.validator';
import { WindowTypeValidator } from '../validators/window-type.validator';
import { LocationValidator } from '../validators/location.validator';
import { ProjectValidator } from '../validators/project.validator';
import { UtilityRateValidator } from '../validators/utility-rate.validator';
import { SeasonalRateValidator } from '../validators/seasonal-rate.validator';
import { BlockRateValidator } from '../validators/block-rate.validator';
import { NaturalVentilationValidator } from '../validators/natural-ventilation.validator';
import { MechanicalVentilationValidator } from '../validators/mechanical-ventilation.validator';
import { Store } from '@ngrx/store';
import { selectSimulationFeature } from '../state/simulation.selectors';
import { denormalizeSimulation } from '@/data/simulation/models/simulation';
import { SimulationConfigValidator } from '../validators/simulation-config.validator';
import { first } from 'rxjs/operators';

const StateValidators: Record<StateModelName, BaseValidator> = {
  aboveGradeWall: AboveGradeWallValidator,
  aboveGradeWallType: AboveGradeWallTypeValidator,
  door: DoorValidator,
  doorType: DoorTypeValidator,
  foundationWall: FoundationWallValidator,
  foundationWallType: FoundationWallTypeValidator,
  frameFloor: FrameFloorValidator,
  frameFloorType: FrameFloorTypeValidator,
  mechanicalEquipment: MechanicalEquipmentValidator,
  airConditioner: AirConditionerValidator,
  airSourceHeatPump: AirSourceHeatPumpValidator,
  dehumidifier: DehumidifierValidator,
  groundSourceHeatPump: GroundSourceHeatPumpValidator,
  heater: HeaterValidator,
  waterHeater: WaterHeaterValidator,
  lights: LightsValidator,
  appliances: AppliancesValidator,
  distributionSystem: DistributionSystemValidator,
  duct: DuctValidator,
  thermostat: ThermostatValidator,
  photovoltaic: PhotovoltaicValidator,
  rimJoist: RimJoistValidator,
  rimJoistType: RimJoistTypeValidator,
  infiltration: InfiltrationValidator,
  roof: RoofValidator,
  roofType: RoofTypeValidator,
  slab: SlabValidator,
  slabType: SlabTypeValidator,
  skylight: SkylightValidator,
  skylightType: SkylightTypeValidator,
  simulation: SimulationValidator,
  waterSystem: WaterSystemValidator,
  window: WindowValidator,
  windowType: WindowTypeValidator,
  location: LocationValidator,
  project: ProjectValidator,
  utilityRate: UtilityRateValidator,
  seasonalRate: SeasonalRateValidator,
  blockRate: BlockRateValidator,
  naturalVentilation: NaturalVentilationValidator,
  mechanicalVentilation: MechanicalVentilationValidator,
  simulationConfig: SimulationConfigValidator,
};

@Injectable({ providedIn: 'root' })
export class StateValidatorService {
  connectedValidators = {};

  constructor(private store: Store) {
    this.connectedValidators = {};
  }

  validateModel(stateName: StateModelName, data: any): any {
    const validator = StateValidators[stateName];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const errors = validator.validate(data);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    for (const [modelName, config] of Object.entries(validator.configurable)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      for (const [methodName, fields] of Object.entries(config)) {
        fields.forEach(field => {
          const validator = this.connectedValidators[modelName] || {};
          const methods = validator[field] || new Set([]);
          this.connectedValidators[modelName] = {
            ...this.connectedValidators[modelName],
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            [field]: methods.add(stateName + '/' + methodName),
          };
        });
      }
    }
    return errors;
  }

  validateAgainstChanges(
    stateName: StateModelName,
    changedFieldNames: string[]
  ): any {
    const fieldNameToValidatorName = this.connectedValidators[stateName] || {};

    const errors = {};

    changedFieldNames.forEach(fieldName => {
      const validatorNames = fieldNameToValidatorName[fieldName] || new Set();
      this.store
        .select(selectSimulationFeature)
        .pipe(first())
        .subscribe(simulationState => {
          validatorNames.forEach(validatorName => {
            const [modelName, methodName] = validatorName.split('/');
            const validator = StateValidators[modelName];

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const error = validator[methodName](
              denormalizeSimulation(simulationState)
            );

            if (error) {
              if (!errors[modelName]) {
                errors[modelName] = [];
              }
              errors[modelName].push(...error);
            }
          });
        });
    });
    return errors;
  }
}
