<div class="container-lg" *ngIf="initialized">
  <div class="row">
    <div class="col-12">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#" [routerLink]="['/', ]">Home</a></li>
          <li class="breadcrumb-item"><a href="#" [routerLink]="['/', 'company', company.company_type]">Companies</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            <app-company-display [company]="company"></app-company-display>
          </li>
        </ol>
      </nav>
    </div>
  </div>
  <div class="d-flex-column mb-2">
    <div class="d-flex flex-row">
      <div class="flex-fill align-self-end">
        <h3 class="mb-0">
          {{ companyDisplayName }}:
          <app-company-display [company]="company"></app-company-display>
        </h3>
      </div>
      <div class="text-right">
        <app-history-mat-button [objectId]="company.id" [modelClassName]="'company'" class="mr-1">
          History
        </app-history-mat-button>
        <app-permission-mat-button [permissionResponse]="deletePermissionResponse" (action)="onDelete($event)" class="mr-1" [hideOnFalse]="true">
          Delete
        </app-permission-mat-button>
        <app-permission-mat-button [permissionResponse]="editPermissionResponse" (action)="onEdit($event)">
          Edit
        </app-permission-mat-button>
      </div>
    </div>
    <mat-divider class="mt-1 mb-2"></mat-divider>
    <div class="d-flex flex-row">
      <div class="d-flex-column flex-fill">
        <div class="d-flex flex-row">
          <div class="company-attr-name">
            Street:
          </div>
          <div class="flex-fill">
            {{ company.street_line1 }}
          </div>
        </div>
        <div class="d-flex flex-row" *ngIf="company.street_line2">
          <div class="company-attr-name"></div>
          <div class="flex-fill">{{ company.street_line2 }}</div>
        </div>
        <div class="d-flex flex-row">
          <div class="company-attr-name">City:</div>
          <div class="flex-fill">{{ company.city_info?.name }}</div>
        </div>
        <div class="d-flex flex-row">
          <div class="company-attr-name">State:</div>
          <div class="flex-fill">{{ company.city_info?.county_info?.state }}</div>
        </div>
        <div class="d-flex flex-row">
          <div class="company-attr-name">ZIP Code:</div>
          <div class="flex-fill">{{ company.zipcode }}</div>
        </div>
        <div>
          <small>
            <a href="#" (click)="onToggleAdditionalLocationStatistics($event);">
              <ng-container *ngIf="!showAdditionalLocationStatistics">Show additional location statistics</ng-container>
              <ng-container *ngIf="showAdditionalLocationStatistics">Hide additional location statistics</ng-container>
            </a>
          </small>
        </div>
        <div *ngIf="showAdditionalLocationStatistics">
          <div class="d-flex flex-row">
            <div class="company-attr-name">Metropolitan:</div>
            <div class="flex-fill">{{ company?.city_info?.county_info?.metro }}</div>
          </div>
          <div class="d-flex flex-row">
            <div class="company-attr-name">County:</div>
            <div class="flex-fill">{{ company.city_info?.county_info?.name }}</div>
          </div>
          <div class="d-flex flex-row">
            <div class="company-attr-name">Coordinates:</div>
            <div class="flex-fill">{{ company.latitude }} {{ company.longitude }}</div>
          </div>
        </div>
        <div class="mt-2"></div>
        <div class="d-flex flex-row">
          <div class="company-attr-name">AXIS ID:</div>
          <div class="flex-fill">{{ company.id }}</div>
        </div>
        <div class="mt-2"></div>
        <div class="d-flex flex-row">
          <div class="company-attr-name">Home Page:</div>
          <div class="flex-fill">
            <a href="{{ company.home_page }}" target="_blank" *ngIf="company.home_page">
              {{ company.home_page }}
            </a>
            <span *ngIf="!company.home_page">-</span>
          </div>
        </div>
        <div class="d-flex flex-row">
          <div class="company-attr-name">Office Phone:</div>
          <div class="flex-fill">{{ company.office_phone || '-' }}</div>
        </div>
        <div class="d-flex flex-row">
          <div class="company-attr-name">Default Email:</div>
          <div class="flex-fill">{{ company.default_email || '-' }}</div>
        </div>
        <div class="mt-4"></div>
        <div *ngIf="company.company_type == companyType.utility" class="d-flex flex-row">
          <div class="company-attr-name">Provided Services:</div>
          <div class="flex-fill">
            <span class="badge badge-warning ml-2" *ngIf="electricityProvider">Electric</span>
            <span class="badge badge-secondary" *ngIf="gasProvider">Gas</span>
            <span class="badge badge-info" *ngIf="waterProvider">Water</span>
          </div>
        </div>
        <div class="d-flex flex-row" *ngIf="company.company_type == companyType.provider">
          <div class="company-attr-name">RESNET Provider:</div>
          <div class="flex-fill">{{ company.resnet | yesNo }}</div>
        </div>
        <div class="d-flex flex-row">
          <div class="company-attr-name align-self-center">Alternate Names:</div>
          <div class="flex-fill align-self-center">
            <app-company-alternate-name-list
              [componentCompanyType]="componentCompanyType"></app-company-alternate-name-list>
          </div>
        </div>
        <div class="d-flex flex-row">
          <div class="company-attr-name align-self-center">Affiliations:</div>
          <app-company-affiliations-list class="align-self-center"
              [componentCompanyType]="componentCompanyType"></app-company-affiliations-list>
        </div>
      </div>
      <div class="d-flex-column" style="max-width: 480px; min-width: 450px;">
          <div class="card w-100 h-100" *ngIf="company.latitude && company.longitude; else mapAddressNotValid;">
            <div class="card-content w-100 h-100">
              <google-map
                  height="350px"
                  width="100%"
                  [zoom]="16"
                  [center]="mapCenter"
                  [options]="mapOptions"
              >
                <map-marker
                    #markerElem="mapMarker"
                    *ngFor="let marker of mapMarkers"
                    [position]="marker.position"
                    [label]="marker.label"
                    [title]="marker.title"
                    [options]="marker.options"
                    (mapClick)="mapOpenInfo(markerElem, marker.info)"
                >
                </map-marker>
                <map-info-window>{{ mapInfoContent }}</map-info-window>
              </google-map>
            </div>
          </div>
          <ng-template #mapAddressNotValid>
            <div class="d-flex flex-column flex-fill justify-content-center not-confirmed-address-container">
              <div class="p-3 text-muted">
                Axis was unable to confirm and map this address. Please verify the accuracy of the address.
                If the address entered is correct, it may be too new for our mapping engines to find it.
                <fa-icon [icon]="['fas', 'map-marker']"></fa-icon>
              </div>
            </div>
          </ng-template>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <mat-tab-group color="accent" style="min-height: 450px;" disableRipple="true" animationDuration="0ms"
                     dynamicHeight (selectedTabChange)="onTabChange($event)" [selectedIndex]="selectedIndex">
        <mat-tab label="Users">
          <ng-template mat-tab-label>
            Users
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" class="ml-1"
                     *ngIf="companyAccessesCount === -1"></fa-icon>
            <span class="badge badge-primary ml-1" *ngIf="companyAccessesCount !== -1">
                            {{ companyAccessesCount }}
                        </span>
          </ng-template>
          <div class="mt-2 mb-5">
            <app-company-users-tab></app-company-users-tab>
          </div>
        </mat-tab>
        <mat-tab label="Counties">
          <div class="mt-2 mb-5">
            <app-company-counties-tab></app-company-counties-tab>
          </div>
        </mat-tab>
        <mat-tab label="Contacts">
          <ng-template mat-tab-label>
            Contacts
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" class="ml-1"
                     *ngIf="companyContactsCount === -1"></fa-icon>
            <span class="badge badge-primary ml-1" *ngIf="companyContactsCount !== -1">
                            {{ companyContactsCount }}
                        </span>
          </ng-template>
          <div class="mt-2 mb-5">
            <app-company-contact-card-tab></app-company-contact-card-tab>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            Documents
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" class="ml-1"
                     *ngIf="companyDocumentsCount === -1"></fa-icon>
            <span class="badge badge-primary ml-1" *ngIf="companyDocumentsCount !== -1">
                            {{ companyDocumentsCount }}
                        </span>
          </ng-template>
          <div class="mt-2 mb-5">
            <app-customer-document-tab [modelClassName]="'company'" [objectId]="company.id"></app-customer-document-tab>
          </div>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            Notes
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" class="ml-1"
                     *ngIf="companyNotesCount === -1"></fa-icon>
            <span class="badge badge-primary ml-1" *ngIf="companyNotesCount !== -1">
                            {{ companyNotesCount }}
                        </span>
          </ng-template>
          <div class="mt-2 mb-5">
            <app-annotation-tab [annotationTypeSlug]="'note'" [modelClassName]="'company'" [objectId]="company.id"></app-annotation-tab>
          </div>
        </mat-tab>

        <mat-tab *ngIf="company?.company_type === CompanyType.rater || company?.company_type === CompanyType.provider">
          <ng-template mat-tab-label>
            Equipment
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" class="ml-1"
                     *ngIf="companyEquipmentCount === -1"></fa-icon>
            <span class="badge badge-primary ml-1" *ngIf="companyEquipmentCount !== -1">
                {{ companyEquipmentCount }}
            </span>
          </ng-template>
          <div class="mt-2 mb-5">
            <app-company-equipment-tab></app-company-equipment-tab>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            COI
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" class="ml-1"
                     *ngIf="companyCOICount === -1"></fa-icon>
            <span class="badge badge-primary ml-1" *ngIf="companyCOICount !== -1">
                            {{ companyCOICount }}
                        </span>
          </ng-template>
          <div class="mt-2 mb-5">
            <div class="row">
              <div class="col-12">
                <app-company-coi-tab></app-company-coi-tab>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Description/Misc">
          <div class="mt-2 mb-5">
            <div class="row">
              <div class="col-8">
                <app-company-misc-tab></app-company-misc-tab>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Sponsoring">
          <div class="mt-2 mb-5">
            <div class="row">
              <div class="col-12">
                <app-company-sponsoring-list-tab></app-company-sponsoring-list-tab>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Rem/Rate Users" *ngIf="company?.company_type === CompanyType.rater || company?.company_type === CompanyType.provider">
          <ng-template mat-tab-label>
            REM/Rate Users&trade;
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" class="ml-1"
                     *ngIf="companyRemrateUsersCount === -1"></fa-icon>
            <span class="badge badge-primary ml-1" *ngIf="companyRemrateUsersCount !== -1">
                            {{ companyRemrateUsersCount }}
                        </span>
          </ng-template>
          <div class="mt-2 mb-5">
            <app-company-remrate-users-tab></app-company-remrate-users-tab>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
