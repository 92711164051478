import { UserInfo } from '@/data/core/models/user';
import { QARequirementType, QaStatusInfo } from '@/data/qa/models';
import { CompanyType } from '@/data/company/models';

export enum LetterGrade {
  A = 1,
  B = 2,
  C = 3,
  D = 4,
  F = 5,
}

export const LetterGradeLabelMapping: Record<LetterGrade, string> = {
  [LetterGrade.A]: 'A',
  [LetterGrade.B]: 'B',
  [LetterGrade.C]: 'C',
  [LetterGrade.D]: 'D',
  [LetterGrade.F]: 'F',
};

export class InspectionGrade {
  id?: number;
  user?: number;
  user_info?: UserInfo;
  approver?: number;
  approver_info?: UserInfo;

  letter_grade?: LetterGrade;
  numeric_grade?: number;

  qa_status_info?: QaStatusInfo;
  qa_status?: number;

  notes?: string;

  updated_at?: string;
  created_at?: string;
}

export class CustomerHIRLInspectionGradeList {
  id?: number;

  user?: number;
  user_fullname?: string;

  user_company: number;
  user_company_type: CompanyType;
  user_company_name: string;

  qa_type: QARequirementType;
  eep_program_name: string;

  home_id?: string;
  home_address?: string;

  letter_grade?: LetterGrade;
  numeric_grade?: number;

  updated_at?: string;
  created_at?: string;
}

export class InspectionGradeAggregateByLetterGrade {
  a_grade: number;
  b_grade: number;
  c_grade: number;
  d_grade: number;
  f_grade: number;
}
