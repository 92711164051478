import { createReducer, on } from '@ngrx/store';
import * as SlabTypeActions from './actions';
import { SlabTypeBackendDict } from '@/data/simulation/models/enclosure/SlabType';
import { BaseState } from '../shared/base.state';
import { addStateErrorCount } from '../helper';

export type SlabTypeState = BaseState<SlabTypeBackendDict>;

export const initialState: SlabTypeState = {
  entities: {},
  loading: {},
  errors: {},
};

export const reducer = createReducer(
  initialState,
  on(SlabTypeActions.loadSlabTypesSuccess, (state, { slabTypes, errors }) => {
    const entities = slabTypes.reduce(
      (acc, slabType) => ({ ...acc, [slabType.id]: slabType }),
      {}
    );
    const loading = slabTypes.reduce(
      (acc, slabType) => ({ ...acc, [slabType.id]: false }),
      {}
    );
    return {
      entities: { ...state.entities, ...entities },
      loading: { ...state.loading, ...loading },
      errors: { ...state.errors, ...addStateErrorCount(errors) },
    };
  }),

  on(SlabTypeActions.updateSlabType, (state, { slabTypeChanges }) => ({
    ...state,
    loading: { ...state.loading, [slabTypeChanges.id]: true },
  })),

  on(SlabTypeActions.updateSlabTypeFailure, (state, { id }) => ({
    ...state,
    loading: { ...state.loading, [id]: false },
  })),

  on(SlabTypeActions.removeSlabTypeSuccess, (state, { id }) => {
    const { [id]: removed, ...entities } = state.entities;
    const { [id]: removedLoading, ...loading } = state.loading;
    const { [id]: removedErrors, ...errors } = state.errors;
    return { entities, loading, errors };
  })
);
