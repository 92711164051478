import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  HIRLProject,
  HIRLProjectCommercialSpaceTypeLabelMapping,
  HIRLProjectRegistrationType,
  LandDevelopmentProjectType,
  HIRLProjectAppealsProjectTypeMapping,
  HIRLProjectSamplingMapping,
} from '@/data/customer-hirl/models';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { first } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import {
  ConfirmDialogComponent,
  IConfirmDialogData,
} from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { toggleLoading } from '@/state/actions/app.actions';
import { MatDialog } from '@angular/material/dialog';
import { HIRLProjectService } from '@/data/customer-hirl/services/hirl-projects.service';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { NavigationService } from '@/shared/services/navigation-service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hirl-project-card',
  templateUrl: './hirl-project-card.component.html',
  styleUrls: ['./hirl-project-card.component.scss'],
})
export class HirlProjectCardComponent implements OnInit, OnDestroy {
  protected readonly HIRLProjectSamplingMapping = HIRLProjectSamplingMapping;
  protected readonly HIRLProjectAppealsProjectTypeMapping =
    HIRLProjectAppealsProjectTypeMapping;

  @Input() project: HIRLProject;
  @Input() deletePermissionResponse: Observable<ObjectPermissionResponse>;
  @Input() editPermissionResponse: Observable<ObjectPermissionResponse>;

  public commercialSpaceTypeLabelMapping =
    HIRLProjectCommercialSpaceTypeLabelMapping;
  public customerHIRLSettings = CustomerHIRLSettings;
  public hirlProjectRegistrationType = HIRLProjectRegistrationType;
  public landDevelopmentProjectType = LandDevelopmentProjectType;

  private componentDestroyed$ = new Subject();

  constructor(
    public store: Store<AppState>,
    public router: Router,
    public hirlProjectService: HIRLProjectService,
    public dialog: MatDialog,
    public uiHelperService: UIHelperService,
    private navigation: NavigationService
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  onDeleteProject() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '350px',
      data: {
        title: 'Are you sure?',
        content:
          'Are you sure you want to delete this <b>Project</b>? This action cannot be undone',
      } as IConfirmDialogData,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(toggleLoading({ payload: true }));
        this.hirlProjectService
          .delete(this.project)
          .pipe(first())
          .subscribe(_ => {
            this.store.dispatch(toggleLoading({ payload: false }));
          });
      }
    });
  }

  onEditProject() {
    if (
      this.project.registration_info.project_type ===
      HIRLProjectRegistrationType.singleFamily
    ) {
      return this.router.navigate([
        '/',
        'hi',
        'projects',
        this.project.id,
        'edit-single-family',
      ]);
    }
    if (
      this.project.registration_info.project_type ===
      HIRLProjectRegistrationType.landDevelopment
    ) {
      return this.router.navigate([
        '/',
        'hi',
        'projects',
        this.project.id,
        'edit-land-development',
      ]);
    }
    return this.router.navigate([
      '/',
      'hi',
      'projects',
      this.project.id,
      'edit-multi-family',
    ]);
  }
}
