import { Injectable } from '@angular/core';
import { Cookie } from 'ng2-cookies/ng2-cookies';

@Injectable({ providedIn: 'root' })
export class AuthCookie {
  getAccess(): string {
    return Cookie.get('access');
  }

  getRefresh(): string {
    return Cookie.get('refresh');
  }

  setAccess(value: string): void {
    Cookie.set('access', value, null, '/');
  }

  setRefresh(value: string): void {
    Cookie.set('refresh', value, null, '/');
  }

  deleteAccess(): void {
    Cookie.delete('access');
  }

  deleteRefresh(): void {
    Cookie.delete('refresh');
  }

  setSession(sessionid: string): void {
    Cookie.set('sessionid', sessionid, null, '/');
  }

  deleteSession(): void {
    Cookie.delete('sessionid');
  }

  getSessionId(): string {
    return Cookie.get('sessionid');
  }
}
