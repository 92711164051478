import { Action, createReducer, on } from '@ngrx/store';
import {
  loadInfo,
  loadImpersonations,
  updateUserInfo,
} from '../actions/info.actions';
import { User } from '@/data/core/models/user';
import { ImpersonationUser } from '@/data/core/models/impersonation_user';

export const infoFeatureKey = 'info';

export interface InfoState {
  info: ImpersonationUser;
  previousImpersonations: User[];
}

export const initialState: InfoState = {
  info: null,
  previousImpersonations: [],
};
const infoReducer = createReducer(
  initialState,
  on(loadInfo, (state, { payload }) => ({ ...state, info: payload })),
  on(updateUserInfo, (state, { payload }) => {
    if (!state.info) {
      return { ...state, info: null };
    }
    const newInfo = { ...state.info };

    if (payload.id === state.info.user.id) {
      newInfo.user = payload;
    }

    if (payload.id === state.info.impersonated?.id) {
      newInfo.impersonated = payload;
    }

    return { ...state, info: newInfo };
  }),
  on(loadImpersonations, (state, { payload }) => ({
    ...state,
    previousImpersonations: payload,
  }))
);

export function reducer(state: InfoState | undefined, action: Action) {
  return infoReducer(state, action);
}
