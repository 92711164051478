import { Component, OnInit, ViewChild } from '@angular/core';
import {
  MessageLevel,
  ModernMessage,
} from '@/data/messaging/models/modernMessage';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { toggleLoading } from '@/state/actions/app.actions';
import { OkDialogComponent } from '@/shared/components/ok-dialog/ok-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import {
  InputDialogComponent,
  InputDialogData,
  InputDialogInputTypeEnum,
} from '@/shared/components/input-dialog/input-dialog.component';
import {
  ServerErrorDialogComponent,
  ServerErrorDialogConfig,
} from '@/shared/components/server-error-dialog/server-error-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import {
  DropzoneDialogComponent,
  DropzoneDialogData,
} from '@/shared/components/dropzone-dialog/dropzone-dialog.component';
import { Company, CompanyType } from '@/data/company/models';
import {
  CompanyRequestParams,
  CompanyService,
} from '@/data/company/services/company-base.service';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { User } from '@/data/core/models/user';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import * as CustomEditor from '../../../../../assets/ckeditor/ckeditor';
import { EEPProgram } from '@/data/eep-program/models';
import { EEPProgramRequestParams } from '@/data/eep-program/services/eep-program.service';
import { MessagesSnackbarService } from '@/modules/messaging/services/messages-snackbar.service';
import { DateRangeMatSelectChoice } from '@/shared/components/date-range-mat-select/date-range-mat-select.component';

@Component({
  selector: 'app-widgets-test',
  templateUrl: './widgets-test.component.html',
  styleUrls: ['./widgets-test.component.scss'],
})
export class WidgetsTestComponent implements OnInit {
  @ViewChild(GoogleMap, { static: false }) googleMapsEx1Map: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) googleMapsEx1Info: MapInfoWindow;

  public initialized = false;

  public currentUser: User;
  public builderOrganization: Company;

  public differentFieldsForm: UntypedFormGroup;
  public sumArrayPipeList: any[] = [
    { id: 1, total: 5 },
    { id: 2, total: 10 },
  ];
  public safePipeHTML = '<i>HTML text here</i>';

  public builderRequestParams: CompanyRequestParams;
  public eepProgramRequestParams: EEPProgramRequestParams;

  public googleMapsEx1Center: google.maps.LatLngLiteral;
  public googleMapsEx1Options: google.maps.MapOptions = {
    disableDoubleClickZoom: true,
  };
  public googleMapsEx1Markers = [];
  public googleMapsEx1InfoContent = '';

  public editor = CustomEditor;
  public editorConfig = {
    toolbar: [
      'heading',
      '|',
      'fontfamily',
      'fontsize',
      '|',
      'alignment',
      '|',
      'fontColor',
      'fontBackgroundColor',
      '|',
      'bold',
      'italic',
      'strikethrough',
      'underline',
      'subscript',
      'superscript',
      '|',
      'link',
      '|',
      'outdent',
      'indent',
      '|',
      'bulletedList',
      'numberedList',
      'todoList',
      '|',
      'code',
      'codeBlock',
      '|',
      'insertTable',
      '|',
      'blockQuote',
      '|',
      'undo',
      'redo',
      '|',
      'sourceEditing',
    ],
    allowedContent: true,
  };

  dateRanges: DateRangeMatSelectChoice[] = [
    new DateRangeMatSelectChoice('Any', null, null),
    new DateRangeMatSelectChoice(
      '60 days',
      new Date(new Date().setDate(new Date().getDate() - 60)),
      new Date(new Date().setDate(new Date().getDate() + 30))
    ),
    new DateRangeMatSelectChoice(
      '30 days',
      new Date(new Date().setDate(new Date().getDate() - 30)),
      new Date(new Date().setDate(new Date().getDate() + 30))
    ),
    new DateRangeMatSelectChoice(
      '15 days',
      new Date(new Date().setDate(new Date().getDate() - 15)),
      new Date(new Date().setDate(new Date().getDate() + 30))
    ),
    new DateRangeMatSelectChoice(
      '0 days',
      new Date(),
      new Date(new Date().setDate(new Date().getDate() + 30))
    ),
  ];

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<AppState>,
    private dialog: MatDialog,
    private companyService: CompanyService,
    private messagesSnackbarService: MessagesSnackbarService,
    private uiHelperService: UIHelperService
  ) {
    this.builderRequestParams = new CompanyRequestParams();
    this.builderRequestParams.company_type = [
      CompanyType.builder,
      CompanyType.rater,
    ];

    this.eepProgramRequestParams = new EEPProgramRequestParams();
  }

  ngOnInit(): void {
    this.differentFieldsForm = this.fb.group({
      home_address: [null, Validators.required],
      address: [null, Validators.required],
      builder_autocomplete: [null, Validators.required],
      service_chips: [null, Validators.required],
      file_upload: [null, Validators.required],
      content: [''],
      eep_program: [null],
      eep_program_multiple: [],
      month_year: [null, Validators.required],
      search_field: [null],
      date_range_select_filter: [null],
    });

    this.differentFieldsForm.valueChanges.subscribe(value =>
      console.log(value)
    );

    navigator.geolocation.getCurrentPosition(position => {
      this.googleMapsEx1Center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
    });

    forkJoin({
      // retrieve random builder
      builderOrganization: this.companyService.retrieve(1),
      currentUser: this.store.select(getInfoUser).pipe(first()),
    }).subscribe(
      ({ builderOrganization, currentUser }) => {
        this.currentUser = currentUser;
        this.builderOrganization = builderOrganization;

        this.initialized = true;
        this.hydrateForm();
        this.store.dispatch(toggleLoading({ payload: false }));
      },
      error => this.uiHelperService.handleUserRequestError(error)
    );
  }

  hydrateForm() {}

  createMessage(type: string) {
    const msg = new ModernMessage();
    msg.title = 'Test title';
    msg.content =
      'Test system. Test info. Test info. Test <a href="/">Link here</a>.';
    msg.sticky_alert = true;

    switch (type) {
      case 'info':
        msg.id = 1;
        msg.level = MessageLevel.info;
        break;
      case 'system':
        msg.id = 2;
        msg.level = MessageLevel.system;
        break;
      case 'warning':
        msg.id = 3;
        msg.level = MessageLevel.warning;
        break;
      case 'error':
        msg.id = 4;
        msg.level = MessageLevel.error;
        break;
      case 'success':
        msg.id = 5;
        msg.level = MessageLevel.success;
        break;
      case 'debug':
        msg.id = 5;
        msg.level = MessageLevel.debug;
        break;
      default:
        break;
    }

    this.messagesSnackbarService.show(msg);
  }

  raiseUnhandledException() {
    throw Error('Unhandled Exception');
  }

  turnOnGlobalLoading(seconds: number) {
    this.store.dispatch(toggleLoading({ payload: true }));

    setTimeout(() => {
      this.store.dispatch(toggleLoading({ payload: false }));
    }, 3000);
  }

  showOkDialog() {
    this.dialog.open(OkDialogComponent, {
      data: {
        title: 'It is title here',
        content: `Text here`,
      },
    });
  }

  showConfirmDialog() {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'It is title here',
        content: `Text here`,
      },
    });
  }

  showInputDialog() {
    const dialogData = new InputDialogData({
      title: 'Add something',
      inputType: InputDialogInputTypeEnum.textArea,
      confirmBtnText: 'Confirm',
      cancelBtnText: 'No no back',
    });

    this.dialog.open(InputDialogComponent, {
      data: dialogData,
    });
  }

  showServerErrorDialog() {
    const n = null;
    const error = new HttpErrorResponse({
      error: {
        non_field_errors: 'This user is already exists',
        'First Name': ['This field is required'],
        'Last Name': 'Last name is required',
        projects: [
          {
            building_number: [
              'Ensure this field has no more than 30 characters.',
            ],
          },
        ],
        more_complex: [
          {
            error_key: 'error_value',
          },
          [
            {
              error_key_dict: 'error_nested_value',
            },
            {
              [n]: 'error_nested_value2',
            },
          ],
          'error_string',
        ],
      },
      status: 403,
    });
    this.dialog.open(ServerErrorDialogComponent, {
      width: '400px',
      data: {
        title: 'Error:',
        showErrorKey: true,
        data: error,
      } as ServerErrorDialogConfig,
    });
  }

  showDropZoneDialog() {
    const dialogData = new DropzoneDialogData();
    dialogData.title = 'Upload Files';
    dialogData.description = 'View console to see uploaded files';

    const dialogRef = this.dialog.open(DropzoneDialogComponent, {
      width: '400px',
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe(files => {
      if (!files) {
        return;
      }
      console.log(files);
    });
  }

  companyAutocompleteCustomDisplayCompany(company: Company) {
    return `${company.name} (ID: ${company.id})`;
  }

  eepProgramSelectCustomDisplayOption(eepProgram: EEPProgram) {
    return `${eepProgram.name} (ID: ${eepProgram.id})`;
  }

  googleMapsEx1addMarker() {
    this.googleMapsEx1Markers.push({
      position: {
        lat: this.googleMapsEx1Center.lat + ((Math.random() - 0.5) * 2) / 10,
        lng: this.googleMapsEx1Center.lng + ((Math.random() - 0.5) * 2) / 10,
      },
      label: {
        color: 'red',
        text: 'Marker label ' + (this.googleMapsEx1Markers.length + 1),
      },
      title: 'Marker title ' + (this.googleMapsEx1Markers.length + 1),
      info: 'Marker info ' + (this.googleMapsEx1Markers.length + 1),
      options: {
        animation: google.maps.Animation.BOUNCE,
      },
    });
  }

  googleMapsEx1OpenInfo(marker: MapMarker, content) {
    this.googleMapsEx1InfoContent = content;
    this.googleMapsEx1Info.open(marker);
  }
}
