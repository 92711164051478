import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin, Subject } from 'rxjs';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import {
  CompanyAccessRequestParams,
  CompanyAccessService,
} from '@/data/company/services/company-access.service';
import { Store } from '@ngrx/store';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, switchMap, takeUntil, tap } from 'rxjs/operators';
import { User } from '@/data/core/models/user';
import { ChangeCompany, CompanyAccess } from '@/data/company/models';
import { AppState } from '@/state/reducers';
import { toggleLoading } from '@/state/actions/app.actions';
import { CompanyService } from '@/data/company/services/company-base.service';

@Component({
  selector: 'app-superuser-company-access-dialog',
  templateUrl: './superuser-company-access-dialog.component.html',
  styleUrls: ['./superuser-company-access-dialog.component.scss'],
})
export class SuperuserCompanyAccessDialogComponent
  implements OnInit, OnDestroy
{
  public initialized = false;
  public form: FormGroup;
  public currentUser: User;

  public recentCompanyAccess: CompanyAccess[] = [];

  private componentDestroyed$ = new Subject();
  constructor(
    private dialogRef: MatDialogRef<SuperuserCompanyAccessDialogComponent>,
    private store: Store<AppState>,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private companyAccessService: CompanyAccessService,
    private companyService: CompanyService,
    private uiHelperService: UIHelperService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.form = this.fb.group({
      company: [null, Validators.required],
      roles: [null],
    });
  }

  ngOnInit() {
    forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
    })
      .pipe(
        switchMap(({ currentUser }) => {
          this.currentUser = currentUser;
          return this.companyAccessService.list({
            user: this.currentUser.id,
            ordering: '-id',
          } as CompanyAccessRequestParams);
        }),
        tap(
          recentCompanyAccessResponse =>
            (this.recentCompanyAccess = recentCompanyAccessResponse.results)
        )
      )
      .subscribe({
        next: _ => {
          this.initialized = true;
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  save($event: MouseEvent) {
    $event.preventDefault();
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const companyAccessData = {
      company: this.form.value.company.id,
      user: this.currentUser.id,
      roles: this.form.value.roles?.map(role => role.id) || [],
    } as CompanyAccess;
    this.companyAccessService
      .create(companyAccessData)
      .pipe(
        takeUntil(this.componentDestroyed$),
        first(),
        switchMap(companyAccess =>
          this.companyService.change_company({
            company_access: companyAccess.id,
          } as ChangeCompany)
        ),
        tap(res => window.location.reload())
      )
      .subscribe({
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  selectRecentCompanyAccess($event: MouseEvent, companyAccess: CompanyAccess) {
    $event.preventDefault();
    this.store.dispatch(toggleLoading({ payload: true }));
    this.companyService
      .change_company({
        company_access: companyAccess.id,
      } as ChangeCompany)
      .pipe(
        takeUntil(this.componentDestroyed$),
        first(),
        tap(res => window.location.reload())
      )
      .subscribe({
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }
}
