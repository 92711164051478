<div class="container" *ngIf="initialized">
  <div class="row">
    <div class="col-12">
      <nav
        class="navbar p-0 pt-3 mb-3"
        role="navigation"
        aria-label="main navigation"
        style="border-bottom: 1px solid #eee"
      >
        <h5 class="mt-3">
          <button mat-raised-button color="accent" (click)="handleBack()">
            <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
          </button>
          <span *ngIf="!editForm">
            New Single Family Project Registration
          </span>
          <span *ngIf="editForm">
            Edit Single Family
            <b>Project Registration {{ hirlProjectRegistration.id }}</b>
          </span>
        </h5>
      </nav>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <mat-vertical-stepper
        [linear]="!editForm"
        (selectionChange)="onStepChange($event)"
        #stepper
      >
        <mat-step [stepControl]="projectInformationFormGroup">
          <form [formGroup]="projectInformationFormGroup">
            <ng-template matStepLabel>Project Information</ng-template>
            <div class="row mt-5">
              <div class="col-12">
                <mat-form-field appearance="outline" class="w-100 mb-2">
                  <mat-label>NGBS Standard Version and Scoring Path</mat-label>
                  <mat-select
                    aria-label="Select Standard Version and Scoring Path"
                    formControlName="eep_program"
                    required
                    panelClass="eep-program-panel-override"
                  >
                    <mat-option
                      *ngFor="let eepProgram of eepPrograms"
                      [value]="eepProgram.id"
                      [disabled]="
                        bannedEEPProgramSlugs.includes(eepProgram.slug)
                      "
                    >
                      {{ eepProgram.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      projectInformationFormGroup.controls[
                        'eep_program'
                      ].hasError('required')
                    "
                  >
                    NGBS Standard Version and Scoring Path is required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <mat-form-field
                  appearance="outline"
                  class="w-100 mb-2"
                  (click)="handleChangeSubdivision($event)"
                >
                  <mat-label>Subdivision (if applicable)</mat-label>
                  <input
                    matInput
                    type="text"
                    placeholder="Subdivision (if applicable)"
                    formControlName="subdivision"
                    [contentEditable]="false"
                    [readonly]="true"
                  />
                </mat-form-field>
              </div>
            </div>

            <div class="row mt-1">
              <div class="col-12">
                <div>
                  <p>
                    <mat-label>Is this project Build-To-Rent?</mat-label>
                  </p>
                  <mat-radio-group
                    aria-label="Is this project Build-To-Rent?"
                    formControlName="is_build_to_rent"
                  >
                    <mat-radio-button [value]="true" class="mr-5">
                      Yes
                    </mat-radio-button>
                    <mat-radio-button [value]="false"> No </mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-12 text-center">
                <button matStepperNext mat-raised-button color="accent">
                  Next
                </button>
              </div>
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="builderFormGroup">
          <form [formGroup]="builderFormGroup">
            <ng-template matStepLabel>Client Information</ng-template>
            <div class="row mt-5">
              <div class="col-12">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Client Company</mat-label>
                  <input
                    matInput
                    placeholder="Type to search"
                    formControlName="builder_organization_info"
                    [matAutocomplete]="auto_builder_organization"
                  />
                  <mat-error
                    *ngIf="
                      builderFormGroup.controls[
                        'builder_organization_info'
                      ].hasError('required')
                    "
                  >
                    Client Company is required - Must select option from list
                  </mat-error>
                  <mat-error
                    *ngIf="
                      builderFormGroup.controls[
                        'builder_organization_info'
                      ].hasError('invalidObject')
                    "
                  >
                    Must select option from list
                  </mat-error>
                  <mat-error
                    *ngIf="
                      builderFormGroup.controls[
                        'builder_organization_info'
                      ].hasError('builderMustBeTheSameAsSubdivisionBuilder')
                    "
                  >
                    Client Company must be the same as for selected Subdivision
                  </mat-error>
                  <mat-autocomplete
                    #auto_builder_organization="matAutocomplete"
                    autoActiveFirstOption
                    [displayWith]="displayBuilderOrganizationMenu"
                  >
                    <mat-option
                      *ngFor="
                        let builderOrganization of (
                          filteredBuilderOrganizations$ | async
                        )?.results
                      "
                      [value]="builderOrganization"
                    >
                      <app-hirl-builder-organization-display-prefix
                        [builderOrganization]="builderOrganization"
                      ></app-hirl-builder-organization-display-prefix>
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <small class="mat-hint" *ngIf="!builderFormGroup.get('builder_organization_info').value">
                  List of all available
                  <a
                      [routerLink]="['', 'company', 'builder']"
                      target="_blank"
                      matTooltip="Open Clients page in new window"
                  >
                    Clients
                    <fa-icon [icon]="['fas', 'external-link-alt']"></fa-icon>
                  </a>
                </small>
                <div class="row mb-3" *ngIf="builderFormGroup.get('builder_organization_info').value && builderFormGroup.get('builder_organization_info').value.active_customer_hirl_builder_agreements_count === 0">
                  <div class="col-12">
                    <b>
                      Client company does not have an active Client Agreement.
                      <a href="#" [routerLink]="['/', 'hi', 'client_agreements', 'builder', 'create_without_user']" target="_blank">
                        Click here to initiate a new Client Agreement for {{ builderFormGroup.get('builder_organization_info').value.name }}
                      </a>
                    </b>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="row"
              *ngIf="builderFormGroup.get('builder_organization_info').value"
            >
              <div class="col-12">
                <mat-form-field appearance="outline" class="w-100 mb-2">
                  <mat-label>Contact</mat-label>
                  <app-contact-card-select
                    formControlName="builder_organization_contact"
                    [filterParams]="{
                      company_and_related_users: builderFormGroup.get(
                        'builder_organization_info'
                      ).value.id
                    }"
                    [contactCardInitialData]="{
                      company: builderFormGroup.get('builder_organization_info')
                        .value.id
                    }"
                    required
                  ></app-contact-card-select>
                  <mat-error
                    *ngIf="
                      builderFormGroup
                        .get('builder_organization_contact')
                        .hasError('required')
                    "
                  >
                    This field is required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>


            <div class="row">
              <div class="col-6">
                <mat-form-field class="w-100 mb-2">
                  <mat-label
                    >Email address of additional invoice recipient</mat-label
                  >
                  <input
                    matInput
                    placeholder="Email address of additional invoice recipient"
                    name="billing_email"
                    formControlName="billing_email"
                  />
                  <mat-error
                    *ngIf="
                      builderFormGroup.controls['billing_email'].hasError(
                        'email'
                      )
                    "
                  >
                    Enter correct email address
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <mat-checkbox formControlName="is_coi_held_by_different_company">
                  Is COI held by a company different than the Builder/Client?
                </mat-checkbox>
              </div>
            </div>

            <div class="row" *ngIf="builderFormGroup.get('is_coi_held_by_different_company').value">
              <div class="col-12">
                <mat-form-field class="mb-2 mt-2 w-100">
                  <mat-label>Company Name</mat-label>
                  <textarea matInput type="text" placeholder="Company Name"
                            formControlName="coi_held_by_different_company"></textarea>
                  <mat-error
                      *ngIf="
                      builderFormGroup.get(
                        'coi_held_by_different_company'
                      ).hasError('required')
                    "
                  >
                    Company Name is required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-12 text-center">
                <button matStepperPrevious mat-raised-button>Back</button>
                <button
                  matStepperNext
                  mat-raised-button
                  color="accent"
                  class="ml-2"
                >
                  Next
                </button>
              </div>
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="certificateNamesFormGroup">
          <form [formGroup]="certificateNamesFormGroup">
            <ng-template matStepLabel
              >Certificate Customization Options</ng-template
            >
            <div class="row mt-5">
              <div class="col-3">
                <div
                  class="row"
                  *ngFor="let index of [1, 2, 3, 4, 5, 6, 7, 8, 9]"
                >
                  <div
                    [ngClass]="
                      certificateNamesFormGroup.get('certificate_line' + index)
                        .value === certificateLineChoices.customText || certificateNamesFormGroup.get('certificate_line' + index)
                        .value === certificateLineChoices.customTextGreen
                        ? 'col-4'
                        : 'col-12'
                    "
                  >
                    <mat-form-field class="w-100">
                      <mat-label>Line {{ index }}</mat-label>
                      <mat-select
                        [formControlName]="'certificate_line' + index"
                        panelClass="certificate-line-panel-override"
                      >
                        <mat-option
                          *ngFor="
                            let item of certificateLineChoicesMapping
                              | defaultOrderKeyValue
                          "
                          [value]="item.key"
                          >{{ item.value }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div
                    class="col-8"
                    *ngIf="
                      certificateNamesFormGroup.get('certificate_line' + index)
                        .value === certificateLineChoices.customText ||
                        certificateNamesFormGroup.get('certificate_line' + index)
                        .value === certificateLineChoices.customTextGreen
                    "
                  >
                    <mat-form-field class="w-100 mb-2">
                      <mat-label>Custom Text for Line {{ index }}</mat-label>
                      <input
                        matInput
                        placeholder="Custom Text for Line {{ index }}"
                        [formControlName]="
                          'certificate_line' + index + '_custom_text'
                        "
                      />
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="col-9 text-center">
                <app-hirl-certificate-preview
                  [eep_program_slug]="selectedEEPProgram?.slug"
                  [certificate_line1]="
                    certificateNamesFormGroup.get('certificate_line1').value
                  "
                  [certificate_line1_custom_text]="
                    certificateNamesFormGroup.get(
                      'certificate_line1_custom_text'
                    ).value
                  "
                  [certificate_line2]="
                    certificateNamesFormGroup.get('certificate_line2').value
                  "
                  [certificate_line2_custom_text]="
                    certificateNamesFormGroup.get(
                      'certificate_line2_custom_text'
                    ).value
                  "
                  [certificate_line3]="
                    certificateNamesFormGroup.get('certificate_line3').value
                  "
                  [certificate_line3_custom_text]="
                    certificateNamesFormGroup.get(
                      'certificate_line3_custom_text'
                    ).value
                  "
                  [certificate_line4]="
                    certificateNamesFormGroup.get('certificate_line4').value
                  "
                  [certificate_line4_custom_text]="
                    certificateNamesFormGroup.get(
                      'certificate_line4_custom_text'
                    ).value
                  "
                  [certificate_line5]="
                    certificateNamesFormGroup.get('certificate_line5').value
                  "
                  [certificate_line5_custom_text]="
                    certificateNamesFormGroup.get(
                      'certificate_line5_custom_text'
                    ).value
                  "
                  [certificate_line6]="
                    certificateNamesFormGroup.get('certificate_line6').value
                  "
                  [certificate_line6_custom_text]="
                    certificateNamesFormGroup.get(
                      'certificate_line6_custom_text'
                    ).value
                  "
                  [certificate_line7]="
                    certificateNamesFormGroup.get('certificate_line7').value
                  "
                  [certificate_line7_custom_text]="
                    certificateNamesFormGroup.get(
                      'certificate_line7_custom_text'
                    ).value
                  "
                  [certificate_line8]="
                    certificateNamesFormGroup.get('certificate_line8').value
                  "
                  [certificate_line8_custom_text]="
                    certificateNamesFormGroup.get(
                      'certificate_line8_custom_text'
                    ).value
                  "
                  [certificate_line9]="
                    certificateNamesFormGroup.get('certificate_line9').value
                  "
                  [certificate_line9_custom_text]="
                    certificateNamesFormGroup.get(
                      'certificate_line9_custom_text'
                    ).value
                  "
                ></app-hirl-certificate-preview>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-12 text-center">
                <mat-error *ngIf="certificateNamesFormGroup.hasError('addressAndClientNameRequired')">
                  Address and Client Company Name must be included in the NGBS Certificate Customization Options section of this registration.  Please review the fields above and ensure that “Address” and “Client Company Name” are selected for one of the line items.
                </mat-error>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-12 text-center">
                <button matStepperPrevious class="btn btn-lg mr-2">Back</button>
                <button matStepperNext class="btn btn-primary btn-lg mr-2">
                  Next
                </button>
              </div>
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="projectFormGroup" *ngIf="!editForm">
          <form [formGroup]="projectFormGroup">
            <ng-template matStepLabel>Home Address</ng-template>
            <div *ngIf="!editForm">
              <div formArrayName="home_addresses">
                <div
                  class="row"
                  *ngFor="
                    let homeAddressControl of getHomeAddressesControls();
                    let i = index
                  "
                >
                  <div class="col-12" [formGroupName]="i">
                    <div class="row">
                      <div class="col-11">
                        <mat-form-field appearance="outline" class="w-100 mb-2">
                          <mat-label>Address</mat-label>
                          <app-home-address-input
                            placeholder="Address"
                            formControlName="address"
                            required
                            [show-clear-btn]="false"
                          ></app-home-address-input>
                          <mat-error
                            *ngIf="
                              homeAddressControl
                                .get('address')
                                .hasError('required')
                            "
                          >
                            This field is required
                          </mat-error>
                          <mat-error
                            *ngIf="
                              homeAddressControl
                                .get('address')
                                .hasError(
                                  'home_with_this_address_already_exists'
                                )
                            "
                          >
                            Home with this address already exists
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-1 text-right">
                        <button
                          mat-button
                          mat-icon-button
                          (click)="handleRemoveHomeAddress($event, i)"
                          [disabled]="i == 0"
                        >
                          <mat-icon>close</mat-icon>
                        </button>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12">
                        <mat-form-field appearance="outline" class="w-100 mb-2">
                          <mat-label>HUD Disaster Case Number</mat-label>
                          <input
                            matInput
                            type="text"
                            placeholder="HUD Disaster Case Number"
                            formControlName="hud_disaster_case_number"
                          />
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row" *ngIf="hirlGreenEnergyBadges.length">
                      <div class="col-12">
                        <mat-hint>
                          Identify any NGBS Green+ badges that you intend to
                          pursue. Note: with the exception of Zero Water, all
                          badges are available for both New Construction and
                          Renovation. See NGBS Green+ Compliance Handbook for
                          more details.
                        </mat-hint>
                        <mat-form-field appearance="outline" class="w-100 mb-2">
                          <mat-label>NGBS Green+ Badges: </mat-label>
                          <mat-select
                            aria-label="Select Green Energy Badges"
                            formControlName="green_energy_badges"
                            multiple
                            panelClass="green-energy-badges-panel-override"
                          >
                            <mat-option
                              *ngFor="
                                let hirlGreenBadge of hirlGreenEnergyBadges
                              "
                              [value]="hirlGreenBadge.id"
                              >{{ hirlGreenBadge.name }}</mat-option
                            >
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>

                    <div class="row mt-1">
                      <div class="col-12">
                        <div>
                          <mat-form-field appearance="outline" class="mb-2 w-100">
                            <mat-label>Is this an appeals project?</mat-label>
                            <mat-select aria-label="Select Green Energy Badges" formControlName="appeals_project">
                              <mat-option *ngFor="let appealsProjectType of HIRLProjectAppealsProjectTypeMapping | defaultOrderKeyValue"
                                          [value]="appealsProjectType.key">{{ appealsProjectType.value }}</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>

                    <div class="row mt-2" *ngIf="projectInformationFormGroup.get('is_build_to_rent').value">
                      <div class="col-12">
                        <mat-form-field class="w-100 mb-2">
                          <mat-label
                          >Do you intend to employ the NGBS Green Alternative
                            Multifamily Verification Protocol (Sampling) ?</mat-label
                          >
                          <mat-select
                              formControlName="sampling"
                              panelClass="sampling-panel-override"
                          >
                            <mat-option
                                *ngFor="
                        let item of HIRLProjectSamplingMapping
                          | defaultOrderKeyValue
                      "
                                [value]="item.key"
                            >
                              {{ item.value }}
                            </mat-option>
                          </mat-select>
                          <mat-error *ngIf="homeAddressControl.get('sampling').hasError('required')">
                            This field is required
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>

                    <div *ngIf="!isWRIProgramSelected()">
                      <div class="row mt-2">
                        <div class="col-12">
                          <div>
                            <p>
                              <mat-label
                                >Is this property associated with an Accessory
                                Structure seeking certification?</mat-label
                              >
                            </p>
                            <mat-radio-group
                              aria-label="Select property associated with an Accessory Structure seeking certification"
                              formControlName="is_accessory_structure"
                            >
                              <mat-radio-button [value]="true" class="mr-5">
                                Yes
                              </mat-radio-button>
                              <mat-radio-button [value]="false">
                                No
                              </mat-radio-button>
                            </mat-radio-group>
                          </div>
                        </div>
                      </div>

                      <div
                        class="row"
                        *ngIf="
                          homeAddressControl.get('is_accessory_structure').value
                        "
                      >
                        <div class="col-12">
                          <mat-form-field
                            appearance="outline"
                            class="w-100 mb-2"
                          >
                            <mat-label>Accessory Street Line 2</mat-label>
                            <input
                              matInput
                              placeholder="Accessory Street Line 2"
                              formControlName="accessory_structure_street_line_2"
                            />
                            <mat-error
                              *ngIf="
                                homeAddressControl
                                  .get('accessory_structure_street_line_2')
                                  .hasError('required')
                              "
                            >
                              Accessory Address is required
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>

                      <div
                        class="row"
                        *ngIf="
                          homeAddressControl.get('is_accessory_structure').value
                        "
                      >
                        <div class="col-12">
                          <mat-form-field
                            appearance="outline"
                            class="w-100 mb-2"
                          >
                            <mat-label
                              >Accessory Structure Description</mat-label
                            >
                            <textarea
                              matInput
                              placeholder="Accessory Structure Description"
                              formControlName="accessory_structure_description"
                            ></textarea>
                            <mat-error
                              *ngIf="
                                homeAddressControl
                                  .get('accessory_structure_description')
                                  .hasError('required')
                              "
                            >
                              Accessory Structure Description is required
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                      <!-- ADU -->
                      <div class="row mt-2">
                        <div class="col-12">
                          <div>
                            <p>
                              <mat-label
                                >Is this property associated with an Accessory
                                Dwelling Unit (ADU) seeking
                                certification?</mat-label
                              >
                            </p>
                            <mat-radio-group
                              aria-label="Select property associated with an Accessory Dwelling Unit seeking certification"
                              formControlName="is_accessory_dwelling_unit"
                            >
                              <mat-radio-button [value]="true" class="mr-5">
                                Yes
                              </mat-radio-button>
                              <mat-radio-button [value]="false">
                                No
                              </mat-radio-button>
                            </mat-radio-group>
                          </div>
                        </div>
                      </div>

                      <div
                        class="row"
                        *ngIf="
                          homeAddressControl.get('is_accessory_dwelling_unit')
                            .value
                        "
                      >
                        <div class="col-12">
                          <mat-form-field
                            appearance="outline"
                            class="w-100 mb-2"
                          >
                            <mat-label
                              >Accessory Dwelling Unit Street Line 2</mat-label
                            >
                            <input
                              matInput
                              placeholder="Accessory Dwelling Unit Street Line 2"
                              formControlName="accessory_dwelling_unit_street_line_2"
                            />
                            <mat-error
                              *ngIf="
                                homeAddressControl
                                  .get('accessory_dwelling_unit_street_line_2')
                                  .hasError('required')
                              "
                            >
                              Accessory Dwelling Unit Address is required
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>

                      <div
                        class="row"
                        *ngIf="
                          homeAddressControl.get('is_accessory_dwelling_unit')
                            .value
                        "
                      >
                        <div class="col-12">
                          <mat-form-field
                            appearance="outline"
                            class="w-100 mb-2"
                          >
                            <mat-label
                              >Accessory Dwelling Unit Description</mat-label
                            >
                            <textarea
                              matInput
                              placeholder="Accessory Dwelling Unit Description"
                              formControlName="accessory_dwelling_unit_description"
                            ></textarea>
                            <mat-error
                              *ngIf="
                                homeAddressControl
                                  .get('accessory_dwelling_unit_description')
                                  .hasError('required')
                              "
                            >
                              Accessory Dwelling Unit Description is required
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>

                      <div
                        class="row mt-2"
                        *ngIf="
                          customerHIRLSettings.requireRoughInspectionProgramList.includes(
                            this.selectedEEPProgram?.slug
                          ) &&
                          homeAddressControl.get('address').value?.geocode
                            ?.raw_city_info?.county_info?.state !== 'PR' &&
                            homeAddressControl.get('address').value?.geocode
                              ?.raw_city_info?.county_info?.state !== 'VI'
                        "
                      >
                        <div class="col-12">
                          <div>
                            <p>
                              <mat-label>
                                Rough inspections are typically not required if
                                there will be no renovation behind drywall. Will
                                this remodel project require a rough inspection?
                              </mat-label>
                            </p>
                            <mat-radio-group
                              aria-label="Will this remodel project require a rough inspection?"
                              formControlName="is_require_rough_inspection"
                            >
                              <mat-radio-button [value]="true" class="mr-5">
                                Yes
                              </mat-radio-button>
                              <mat-radio-button [value]="false">
                                No
                              </mat-radio-button>
                            </mat-radio-group>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="row mt-2"
                      *ngIf="
                        customerHIRLSettings.wriSeekingProgramList.includes(
                          this.selectedEEPProgram?.slug
                        )
                      "
                    >
                      <div class="col-12">
                        <div>
                          <p>
                            <mat-label
                              >Is this project seeking a WRI?</mat-label
                            >
                          </p>
                          <mat-radio-group
                            aria-label="Is this project seeking a WRI?"
                            formControlName="is_require_wri_certification"
                          >
                            <mat-radio-button [value]="true" class="mr-5">
                              Yes
                            </mat-radio-button>
                            <mat-radio-button [value]="false">
                              No
                            </mat-radio-button>
                          </mat-radio-group>
                        </div>
                      </div>
                    </div>

                    <div
                      class="row mt-2"
                      *ngIf="
                        customerHIRLSettings.waterSenseProgramList.includes(
                          this.selectedEEPProgram?.slug
                        )
                      "
                    >
                      <div class="col-12">
                        <div>
                          <p>
                            <b>WaterSense Certification:</b>
                            The WaterSense label is available for home seeking
                            NGBS Green Certification under the 2020 NGBS
                            version. Do you intend to pursue WaterSense
                            certification for this building? Note: WRI Verifier
                            training must be completed prior to final
                            inspection.
                          </p>
                          <p>
                            <mat-label
                              >Is the project seeking WaterSense
                              Certification?</mat-label
                            >
                          </p>
                          <mat-radio-group
                            aria-label="Is the project seeking WaterSense Certification?"
                            formControlName="is_require_water_sense_certification"
                          >
                            <mat-radio-button [value]="true" class="mr-5">
                              Yes
                            </mat-radio-button>
                            <mat-radio-button [value]="false">
                              No
                            </mat-radio-button>
                          </mat-radio-group>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 text-center">
                  <button mat-raised-button (click)="addHomeAddress()">
                    Add Another Address
                  </button>
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-12 text-center">
                <button matStepperPrevious mat-raised-button>Back</button>
                <button
                  matStepperNext
                  mat-raised-button
                  color="accent"
                  class="ml-2"
                >
                  Next
                </button>
              </div>
            </div>
          </form>
        </mat-step>

        <mat-step [stepControl]="financialFormGroup">
          <form [formGroup]="financialFormGroup">
            <ng-template matStepLabel
              >Project Type & Financing Information</ng-template
            >
            <div class="row mt-5">
              <div class="col-12">
                <mat-checkbox
                  name="building_will_include_non_residential_space"
                  formControlName="building_will_include_non_residential_space"
                >
                  Building(s) will include non-residential space
                  (retail/commercial) ?
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox
                  name="seeking_hud_mortgage_insurance_premium_reduction"
                  formControlName="seeking_hud_mortgage_insurance_premium_reduction"
                >
                  Seeking HUD Mortgage Insurance Premium Reduction?
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox
                  name="seeking_hud_green_and_resilient_retrofit_program"
                  formControlName="seeking_hud_green_and_resilient_retrofit_program"
                >
                Seeking HUD Green and Resilient Retrofit Program?
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox
                  name="seeking_fannie_mae_green_financing"
                  formControlName="seeking_fannie_mae_green_financing"
                >
                  Seeking Fannie Mae Green financing?
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox
                  name="seeking_freddie_mac_green_financing"
                  formControlName="seeking_freddie_mac_green_financing"
                >
                  Seeking Freddie Mac Green financing?
                </mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-checkbox
                  name="intended_to_be_affordable_housing"
                  formControlName="intended_to_be_affordable_housing"
                >
                  Intended to be affordable housing?
                </mat-checkbox>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-12 text-center">
                <button matStepperPrevious mat-raised-button>Back</button>
                <button
                  matStepperNext
                  mat-raised-button
                  color="accent"
                  class="ml-2"
                  *ngIf="!editForm"
                >
                  Next
                </button>
              </div>
            </div>
          </form>
        </mat-step>

        <mat-step *ngIf="!editForm">
          <ng-template matStepLabel>Confirm</ng-template>
          <p>
            Before clicking the Submit button, please be sure that the
            information is correct as clicking Submit will forward the project
            information to NGBS staff for review and approval.
          </p>
          <div>
            <button matStepperPrevious mat-raised-button class="mr-2">
              Back
            </button>
            <button
              matStepperNext
              mat-raised-button
              color="accent"
              (click)="handleCreate($event)"
              *ngIf="!editForm"
            >
              Submit
            </button>
          </div>
        </mat-step>
      </mat-vertical-stepper>
    </div>
  </div>

  <div class="row" *ngIf="editForm">
    <div class="col-12 text-center">
      <button (click)="handleBack()" mat-raised-button class="mr-2">
        Back
      </button>
      <button mat-raised-button color="accent" (click)="handleEdit($event)">
        Save
      </button>
    </div>
  </div>

  <router-outlet></router-outlet>
</div>
