import {
  EditorNavigationService,
  SubTab,
} from '@/modules/simulation/services/editor-navigation.service';
import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-fenestration-tab',
  templateUrl: './fenestration-tab.component.html',
  styleUrls: ['./fenestration-tab.component.scss'],
})
export class FenestrationTabComponent {
  componentDestroyed$ = new Subject();
  activeSubTabLink: SubTab;
  subTabs = SubTab;

  constructor(public editorNavigationService: EditorNavigationService) {}

  ngOnInit() {
    this.editorNavigationService
      .getActiveSubTabLinkObservable()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(subTab => {
        this.activeSubTabLink = subTab;
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
