import { Simulation } from '@/data/simulation/models';
import { LightsBackendDict } from '@/data/simulation/models/lights/Lights';
import { resetSimulation } from '@/state/actions/floorplan/simulation.actions';
import { AppState } from '@/state/reducers';
import { getSimulation } from '@/state/selectors/floorplan/simulation.selector';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-simulation-lights',
  templateUrl: './simulation-lights.component.html',
  styleUrls: ['./simulation-lights.component.scss'],
})
export class SimulationLightsComponent implements OnInit {
  public initialized = false;
  public invalidResponse = false;
  public simulation: Simulation;
  public lights: LightsBackendDict;
  public hidden = true;

  private componentDestroyed$ = new Subject();

  constructor(public store: Store<AppState>) {}

  ngOnInit(): void {
    this.store
      .select(getSimulation)
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(val => val != null)
      )
      .subscribe(
        simulation => {
          this.initialized = true;
          this.lights = simulation.lights_info;
          if (this.lights) {
            this.hidden = false;
          }
        },
        error => {
          this.invalidResponse = true;
          this.initialized = true;
        }
      );
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
    this.store.dispatch(resetSimulation());
  }
}
