<div *ngIf="initialized; else loading;">

    <mat-expansion-panel *ngIf="count > 0">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Rimjoists
            </mat-panel-title>
            <div matBadge="{{ count }}" matBadgeSize="medium" class="countBadge"></div>
        </mat-expansion-panel-header>
        <ng-container *ngTemplateOutlet="rimjoistsView; context: {rimjoists: rimjoists}"></ng-container>
    </mat-expansion-panel>
</div>

<ng-template #rimjoistsView let-rimjoists="rimjoists">
    <div class="row mt-2">
        <ng-container *ngFor="let rimjoist of rimjoists; let index=index">
            <div class="col-6 mt-2">
                <div class="card">
                    <div class="card-content">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item header"
                                style="background-color:rgba(0, 0, 0, 0.03); align-items: center;">
                                <b>
                                    {{ rimjoist.name }}
                                </b>
                            </li>
                            <li class="list-group-item">
                                <b>Area:</b>
                                {{ rimjoist.area }} {{rimjoist.area_units_display}}
                            </li>
                            <li class="list-group-item">
                                <b>Location (Interior / Exterior):</b>
                                {{ rimjoist.interior_location_display }} / {{ rimjoist.exterior_location_display }}
                            </li>
                            <li class="list-group-item">
                                <b>R-Value:</b>
                                {{rimjoist.type_info.cavity_insulation_r_value}}
                            </li>
                            <li class="list-group-item">
                                <b>Insulation Thickess:</b>
                                {{ rimjoist.type_info.cavity_insulation_thickness }} {{ rimjoist.type_info.units_display }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="w-100" *ngIf="(index+1) % 2 === 0"></div>
        </ng-container>
    </div>
</ng-template>

<ng-template #loading>
    <div class="row">
        <div class="col-12">
            <div class="d-flex justify-content-center" style="height: 320px;">
                <mat-spinner [diameter]="50"></mat-spinner>
            </div>
        </div>
    </div>
</ng-template>