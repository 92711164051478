/**
 * Geocode model representation
 */
import { City } from '@/data/geographic/models/city';

export class Geocode {
  id: number;
  raw_street_line1: string;
  raw_street_line2: string;
  raw_zipcode: string;
  raw_city: number;
  raw_city_info: City;
  raw_cross_roads: string;
  raw_address: string;
  raw_latitude?: number;
  raw_longitude?: number;
  responses: GeocodeBrokeredResponse[];
  valid_responses: GeocodeBrokeredResponse[];
}

/**
 * GeocodeBrokeredResponse company-detail information
 */
export class GeocodeBrokeredResponse {
  id: number;
  street_line1: string;
  street_line2: string;
  zipcode: string;
  city: number;
  city_info: City;
  cross_roads: string;
  latitude: number;
  longitude: number;
}
