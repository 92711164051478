import { ComponentLoads } from '@/data/simulation/models/reports/Analysis';
import { Component, Input } from '@angular/core';

enum COMPONENTS_NAMES {
  roofs = 'Roofs',
  ceilings = 'Ceilings',
  above_ground_walls = 'Above Ground Walls',
  rim_joists = 'Rim Joists',
  foundation_walls = 'Foundation Walls',
  doors = 'Doors',
  windows_solar = 'Windows Solar',
  windows_conduction = 'Windows Conduction',
  windows = 'Windows',
  skylights_solar = 'Skylights Solar',
  skylights_conduction = 'Skylights Conduction',
  skylights = 'Skylights',
  frame_floors = 'Frame Floors',
  slabs = 'Slabs',
  internal_mass = 'Internal Mass',
  infiltration = 'Infiltration',
  natural_ventilation = 'Natural Ventilation',
  mechanical_ventilation = 'Mechanical Ventilation',
  whole_house_fan = 'Whole House Fan',
  ducts = 'Ducts',
  sunspace = 'Sunspace',
  internal_gains = 'Internal Gains',
  lighting = 'Lighting',
}

@Component({
  selector: 'app-component-loads',
  templateUrl: './component-loads.component.html',
  styleUrls: ['./component-loads.component.scss'],
})
export class ComponentLoadsComponent {
  @Input() entity: ComponentLoads;
  componentNames = COMPONENTS_NAMES;
  displayedColumns: string[] = ['type', ...Object.keys(COMPONENTS_NAMES)];
  dataSource: any[] = [];

  ngOnInit(): void {
    this.prepareDataSource();
  }

  private prepareDataSource(): void {
    if (!this.entity) return;

    const heatingData = {};
    const coolingData = {};

    for (const [key, value] of Object.entries(this.entity)) {
      if (key.startsWith('heating')) {
        const dataKey = key.replace('heating_', '');
        heatingData[dataKey] = value;
      } else if (key.startsWith('cooling')) {
        const dataKey = key.replace('cooling_', '');
        coolingData[dataKey] = value;
      }
    }

    this.dataSource = [
      { type: 'Heating', ...heatingData },
      { type: 'Cooling', ...coolingData },
    ];
  }
}
