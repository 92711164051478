import { ModelFormControl, ModelFormGroup } from './ModelForm';
import { Validators } from '@angular/forms';
import {
  ApplianceCostRateUnit,
  AuxEnergyUnit,
  CostUnit,
  FuelType,
  Location,
  LocationLabels,
} from '../enumerations';
import {
  enumValidator,
  requiredAsWarning,
} from '@/modules/simulation/validators/helper';
import { FieldLabels } from './base';

export enum AppliancesLocation {
  CONDITIONED_SPACE = Location.CONDITIONED_SPACE,
  UNCONDITIONED_SPACE = Location.UNCONDITIONED_SPACE,
  UNKNOWN = Location.UNKNOWN,
}

export const AppliancesLocationLabels = {
  [Location.CONDITIONED_SPACE]: LocationLabels[Location.CONDITIONED_SPACE],
  [Location.UNCONDITIONED_SPACE]: LocationLabels[Location.UNCONDITIONED_SPACE],
  [Location.UNKNOWN]: LocationLabels[Location.UNKNOWN],
};

export enum DryerEfficiencyUnit {
  DRYER_EFFICIENCY = 'dryer_ef',
  CEF = 'cef',
}

export const DryerEfficiencyUnitLabels = {
  [DryerEfficiencyUnit.DRYER_EFFICIENCY]: 'Dryer Efficiency EF',
  [DryerEfficiencyUnit.CEF]: 'CEF',
};

export enum CeilingFanConsumptionUnit {
  CFM_WATT = 'cfm_watt',
}

export const CeilingFanConsumptionUnitLabels = {
  [CeilingFanConsumptionUnit.CFM_WATT]: 'CFM / Watt',
};

export enum WasherCapacityUnit {
  CU_FT = 'cu_ft',
}

export const WasherCapacityUnitLabels = {
  [WasherCapacityUnit.CU_FT]: 'Cubic Feet',
};

export enum DishwasherPresets {
  ERI = 'eri',
  FEDERAL = 'fed',
  ESTAR_STD = 'estar',
  ESTAR_COMPACT = 'estar_comp',
  LABEL = 'label',
}

export const DishwasherPresetLabels = {
  [DishwasherPresets.ERI]: 'ERI Reference',
  [DishwasherPresets.FEDERAL]: 'Federal Minimum',
  [DishwasherPresets.ESTAR_STD]: 'ENERGY STAR (std)',
  [DishwasherPresets.ESTAR_COMPACT]: 'ENERGY STAR (comp)',
  [DishwasherPresets.LABEL]: 'EnergyGuide label',
};

export enum DishwasherSizeUnit {
  COMPACT = 'compact',
  STANDARD = 'std',
}

export const DishwasherSizeUnitLabels = {
  [DishwasherSizeUnit.COMPACT]: 'Compact',
  [DishwasherSizeUnit.STANDARD]: 'Standard',
};

export enum DishwasherEfficiencyUnit {
  UEF = 'uef',
  EF = 'ef',
}

export const DishwasherEfficiencyLabels = {
  [DishwasherEfficiencyUnit.UEF]: 'UEF',
  [DishwasherEfficiencyUnit.EF]: 'EF',
};

export const CostUnitLabels = {
  [CostUnit.USD]: '$',
  [CostUnit.EURO]: '€',
};

export enum ApplianceCostRateUnits {
  USD_KWH = 'usd_kwh',
  USD_THERM = 'usd_therm',
}

export const ApplianceCostRateUnitLabels = {
  [ApplianceCostRateUnits.USD_KWH]: '$/kWh',
  [ApplianceCostRateUnits.USD_THERM]: '$/therm',
};

export enum WasherEfficiencyUnit {
  MEF = 'mef',
  IMEF = 'imef',
}

export const WasherEfficiencyLabels = {
  [WasherEfficiencyUnit.MEF]: 'MEF',
  [WasherEfficiencyUnit.IMEF]: 'IMEF',
};

export enum ClothesWasherPresets {
  ERI_2006 = 'eri2006',
  STD_2008 = 'std',
  STD_2018 = 'std2018',
  ESTAR_2006 = 'estar2006',
  ESTAR_2018 = 'estar2018',
  CEE_TIER_2 = 'tier2',
  LABEL = 'label',
  CUSTOM = 'custom',
  HIGH_EFF = 'high_eff',
  MED_EFF = 'med_eff',
}

export const ClothesWasherPresetLabels = {
  [ClothesWasherPresets.ERI_2006]: 'ERI Ref 2006',
  [ClothesWasherPresets.STD_2008]: 'Standard 2008-2017',
  [ClothesWasherPresets.STD_2018]: 'Standard 2018',
  [ClothesWasherPresets.ESTAR_2006]: 'ENERGY STAR 2006-2017',
  [ClothesWasherPresets.ESTAR_2018]: 'ENERGY STAR 2018',
  [ClothesWasherPresets.CEE_TIER_2]: 'CEE Tier II 2018',
  [ClothesWasherPresets.LABEL]: 'EnergyGuide label',
  [ClothesWasherPresets.CUSTOM]: 'Custom',
  [ClothesWasherPresets.HIGH_EFF]: 'High Efficiency',
  [ClothesWasherPresets.MED_EFF]: 'Medium Efficiency',
};

// Labels
export const FIELD_LABELS: FieldLabels<AppliancesBackendDict> = {
  // Refrigerator
  refrigerator_consumption: 'Consumption',
  refrigerator_consumption_unit: 'Consumption Unit',
  refrigerator_location: 'Location',
  // Dishwasher
  dishwasher_preset: 'Preset',
  dishwasher_consumption: 'Consumption',
  dishwasher_consumption_unit: 'Consumption Unit',
  dishwasher_efficiency: 'Efficiency',
  dishwasher_efficiency_unit: 'Efficiency Unit',
  dishwasher_place_setting: 'Place Setting',
  dishwasher_size: 'Size',
  dishwasher_location: 'Location',
  dishwasher_label_energy_rating: 'Label Energy Rating',
  dishwasher_label_energy_rating_unit: 'Label Energy Rating Unit',
  dishwasher_label_gas_annual_cost: 'Label Gas Annual Cost',
  dishwasher_label_gas_annual_cost_unit:
    'Dishwasher Label Gas Annual Cost Unit',
  dishwasher_label_electric_rate: 'Label Electric Rate',
  dishwasher_label_electric_rate_unit: 'Dishwasher Label Electric Rate Unit',
  dishwasher_label_gas_rate: 'Label Gas Rate',
  dishwasher_gas_rate_unit: 'Dishwasher Gas Rate Unit',
  dishwasher_loads_per_week: 'Loads Per Week',
  // Cooking Range
  oven_fuel: 'Oven Fuel',
  is_induction_range: 'Is Induction Range',
  is_convection_oven: 'Is Convection Oven',
  oven_location: 'Oven Location',
  // Clothes Dryer
  clothes_dryer_fuel: 'Fuel',
  clothes_dryer_efficiency: 'Efficiency',
  clothes_dryer_efficiency_unit: 'Clothes Dryer Efficiency Unit',
  is_moisture_sensing_dryer: 'Is Moisture Sensing?',
  clothes_dryer_location: 'Location',
  // Clothes Washer
  clothes_washer_preset: 'Preset',
  clothes_washer_capacity: 'Capacity',
  clothes_washer_capacity_units: 'Clothes Washer Capacity Units',
  clothes_washer_efficiency: 'Efficiency',
  clothes_washer_efficiency_unit: 'Clothes Washer Efficiency Unit',
  clothes_washer_label_electric_consumption: 'Electric Consumption',
  clothes_washer_label_electric_consumption_unit:
    'Clothes Washer Label Electric Consumption Unit',
  is_front_loading_clothes_washer: 'Is Front Loading?',
  clothes_washer_location: 'Location',
  clothes_washer_integrated_water_factor: 'Integrated Water Factor',
  clothes_washer_loads_per_week: 'Loads Per Week',
  clothes_washer_energy_star_label_electric_rate: 'Electric Rate',
  clothes_washer_energy_star_label_electric_rate_unit: 'Electric Rate Unit',
  clothes_washer_energy_star_label_gas_rate: 'Gas Rate',
  clothes_washer_energy_star_label_gas_rate_unit: 'Gas Rate Unit',
  clothes_washer_energy_star_label_gas_annual_cost: 'Gas Annual Cost',
  clothes_washer_energy_star_label_gas_annual_cost_unit: 'Gas Annual Cost Unit',
  // Fans
  ceiling_fan_consumption: 'Consumption',
  ceiling_fan_consumption_unit: 'Ceiling Fan Consumption Unit',
  ceiling_fan_count: 'Fans Count',
};

export interface AppliancesBackendDict {
  id: number;
  // Refrigerator
  refrigerator_consumption: number;
  refrigerator_consumption_unit: string;
  refrigerator_location: AppliancesLocation;
  // Dishwasher
  dishwasher_preset: DishwasherPresets;
  dishwasher_consumption: number;
  dishwasher_consumption_unit: AuxEnergyUnit.KWH_YEAR;
  dishwasher_efficiency: number;
  dishwasher_efficiency_unit: string;
  dishwasher_place_setting: number;
  dishwasher_size: string;
  dishwasher_location: AppliancesLocation;
  dishwasher_label_energy_rating: number;
  dishwasher_label_energy_rating_unit: string;
  dishwasher_label_gas_annual_cost: number;
  dishwasher_label_gas_annual_cost_unit: string;
  dishwasher_label_electric_rate: number;
  dishwasher_label_electric_rate_unit: string;
  dishwasher_label_gas_rate: number;
  dishwasher_gas_rate_unit: string;
  dishwasher_loads_per_week: number;
  // Cooking Range
  oven_fuel: FuelType;
  is_induction_range: boolean;
  is_convection_oven: boolean;
  oven_location: AppliancesLocation;
  // Clothes Dryer
  clothes_dryer_fuel: FuelType;
  clothes_dryer_efficiency: number;
  clothes_dryer_efficiency_unit: string;
  is_moisture_sensing_dryer: boolean;
  clothes_dryer_location: AppliancesLocation;
  // Clothes Washer
  clothes_washer_preset: ClothesWasherPresets;
  clothes_washer_capacity: number;
  clothes_washer_capacity_units: WasherCapacityUnit;
  clothes_washer_efficiency: number;
  clothes_washer_efficiency_unit: string;
  clothes_washer_label_electric_consumption: number;
  clothes_washer_label_electric_consumption_unit: string;
  is_front_loading_clothes_washer: boolean;
  clothes_washer_location: AppliancesLocation;
  clothes_washer_integrated_water_factor: number;
  clothes_washer_loads_per_week: number;
  clothes_washer_energy_star_label_electric_rate: number;
  clothes_washer_energy_star_label_electric_rate_unit: string;
  clothes_washer_energy_star_label_gas_rate: number;
  clothes_washer_energy_star_label_gas_rate_unit: ApplianceCostRateUnit;
  clothes_washer_energy_star_label_gas_annual_cost: number;
  clothes_washer_energy_star_label_gas_annual_cost_unit: string;
  // Fans
  ceiling_fan_consumption: number;
  ceiling_fan_consumption_unit: string;
  ceiling_fan_count: number;
}

export const DEFAULT_DISHWASHER_VALUES = {
  [DishwasherPresets.ERI]: {
    dishwasher_size: DishwasherSizeUnit.STANDARD,
    dishwasher_label_energy_rating: 467,
    dishwasher_label_gas_annual_cost: 33.12,
    dishwasher_label_electric_rate: 0.12,
    dishwasher_label_gas_rate: 1.09,
    dishwasher_loads_per_week: 4,
  },
  [DishwasherPresets.FEDERAL]: {
    dishwasher_size: DishwasherSizeUnit.COMPACT,
    dishwasher_label_energy_rating: 307,
    dishwasher_label_gas_annual_cost: 22.32,
  },
  [DishwasherPresets.ESTAR_STD]: {
    dishwasher_size: DishwasherSizeUnit.STANDARD,
    dishwasher_label_energy_rating: 270,
    dishwasher_label_gas_annual_cost: 22.23,
  },
  [DishwasherPresets.ESTAR_COMPACT]: {
    dishwasher_size: DishwasherSizeUnit.COMPACT,
    dishwasher_label_energy_rating: 203,
    dishwasher_label_gas_annual_cost: 14.2,
  },
};

export function createDishwasherForm(appliances: AppliancesBackendDict) {
  return new ModelFormGroup({
    dishwasher_preset: new ModelFormControl(appliances.dishwasher_preset),
    dishwasher_consumption: new ModelFormControl(
      appliances.dishwasher_consumption,
      [requiredAsWarning(), Validators.min(0)]
    ),
    dishwasher_consumption_unit: new ModelFormControl(
      appliances.dishwasher_consumption_unit,
      enumValidator([AuxEnergyUnit.KWH_YEAR])
    ),
    dishwasher_efficiency: new ModelFormControl(
      appliances.dishwasher_efficiency,
      [requiredAsWarning(), Validators.min(0)]
    ),
    dishwasher_efficiency_unit: new ModelFormControl(
      appliances.dishwasher_efficiency_unit
    ),
    dishwasher_place_setting: new ModelFormControl(
      appliances.dishwasher_place_setting,
      Validators.min(0)
    ),
    dishwasher_size: new ModelFormControl(appliances.dishwasher_size),
    dishwasher_location: new ModelFormControl(
      appliances.dishwasher_location,
      Validators.required
    ),
    dishwasher_label_energy_rating: new ModelFormControl(
      appliances.dishwasher_label_energy_rating,
      [requiredAsWarning(), Validators.min(0)]
    ),
    dishwasher_label_energy_rating_unit: new ModelFormControl(
      appliances.dishwasher_label_energy_rating_unit
    ),
    dishwasher_label_gas_annual_cost: new ModelFormControl(
      appliances.dishwasher_label_gas_annual_cost
    ),
    dishwasher_label_gas_annual_cost_unit: new ModelFormControl(
      appliances.dishwasher_label_gas_annual_cost_unit,
      enumValidator([CostUnit.USD])
    ),
    dishwasher_label_electric_rate: new ModelFormControl(
      appliances.dishwasher_label_electric_rate
    ),
    dishwasher_label_electric_rate_unit: new ModelFormControl(
      appliances.dishwasher_label_electric_rate_unit,
      enumValidator([ApplianceCostRateUnit.USD_KWH])
    ),
    dishwasher_label_gas_rate: new ModelFormControl(
      appliances.dishwasher_label_gas_rate
    ),
    dishwasher_gas_rate_unit: new ModelFormControl(
      appliances.dishwasher_gas_rate_unit,
      enumValidator([ApplianceCostRateUnit.USD_THERM])
    ),
    dishwasher_loads_per_week: new ModelFormControl(
      appliances.dishwasher_loads_per_week,
      [Validators.min(0)]
    ),
  });
}

export function createRefrigeratorForm(appliances: AppliancesBackendDict) {
  return new ModelFormGroup({
    refrigerator_consumption: new ModelFormControl(
      appliances.refrigerator_consumption,
      [Validators.required, Validators.min(0)]
    ),
    refrigerator_consumption_unit: new ModelFormControl(
      appliances.refrigerator_consumption_unit,
      enumValidator([AuxEnergyUnit.KWH_YEAR])
    ),
    refrigerator_location: new ModelFormControl(
      appliances.refrigerator_location,
      Validators.required
    ),
  });
}

export function createCookingRangeForm(appliances: AppliancesBackendDict) {
  return new ModelFormGroup({
    oven_fuel: new ModelFormControl(appliances.oven_fuel),
    is_induction_range: new ModelFormControl(appliances.is_induction_range),
    is_convection_oven: new ModelFormControl(appliances.is_convection_oven),
    oven_location: new ModelFormControl(
      appliances.oven_location,
      Validators.required
    ),
  });
}

export function createClothesDryerForm(appliances: AppliancesBackendDict) {
  return new ModelFormGroup(
    {
      clothes_dryer_fuel: new ModelFormControl(appliances.clothes_dryer_fuel),
      clothes_dryer_efficiency: new ModelFormControl(
        appliances.clothes_dryer_efficiency,
        [requiredAsWarning(), Validators.min(0)]
      ),
      clothes_dryer_efficiency_unit: new ModelFormControl(
        appliances.clothes_dryer_efficiency_unit,
        enumValidator(DryerEfficiencyUnit)
      ),
      is_moisture_sensing_dryer: new ModelFormControl(
        appliances.is_moisture_sensing_dryer
      ),
      clothes_dryer_location: new ModelFormControl(
        appliances.clothes_dryer_location
      ),
    },
    {
      validators: [
        (controls: ModelFormGroup) => {
          const dryerLocation = controls.get('clothes_dryer_location').value;
          const dryerFuel = controls.get('clothes_dryer_fuel').value;
          const dryerEfficiency = controls.get(
            'clothes_dryer_efficiency'
          ).value;

          if (dryerLocation && dryerFuel) {
            if (dryerEfficiency in [null, 0.0]) {
              return { clothesDryerEfficiency: true };
            }
          }
        },

        (controls: ModelFormGroup) => {
          if (controls.get('clothes_dryer_efficiency').value > 0) {
            if (controls.get('is_moisture_sensing_dryer').value === null) {
              return {
                isMoistureSensingDryer: true,
              };
            }
          }
        },

        (controls: ModelFormGroup) => {
          if (controls.get('clothes_dryer_efficiency').value > 0) {
            if (controls.get('clothes_dryer_fuel').value === null) {
              return {
                noClothesDryerFuel: true,
              };
            }
          }
        },

        (controls: ModelFormGroup) => {
          if (controls.get('clothes_dryer_efficiency').value > 0) {
            if (controls.get('clothes_dryer_location').value === null) {
              return {
                noClothesDryerLocation: true,
              };
            }
          }
        },
      ],
    }
  );
}

export const DEFAULT_WASHER_VALUES = {
  [ClothesWasherPresets.ERI_2006]: {
    clothes_washer_capacity: 3.0,
    // labelEnergyRating: 400,
    clothes_washer_energy_star_label_gas_annual_cost: 27.0,
    clothes_washer_energy_star_label_electric_rate: 0.12,
    clothes_washer_energy_star_label_gas_rate: 1.09,
    clothes_washer_loads_per_week: 6,
    clothes_washer_efficiency: 1.0,
    clothes_washer_integrated_water_factor: 11.4,
  },
  [ClothesWasherPresets.STD_2008]: {
    clothes_washer_capacity: 3.2,
    // labelEnergyRating: 380,
    clothes_washer_efficiency: 1.21,
    clothes_washer_integrated_water_factor: 9.5,
  },
  [ClothesWasherPresets.STD_2018]: {
    clothes_washer_capacity: 4.2,
    // labelEnergyRating: 284,
    clothes_washer_energy_star_label_gas_annual_cost: 18.0,
    clothes_washer_efficiency: 1.57,
    clothes_washer_integrated_water_factor: 6.5,
  },
  [ClothesWasherPresets.ESTAR_2006]: {
    clothes_washer_capacity: 3.5,
    // labelEnergyRating: 260,
    clothes_washer_energy_star_label_gas_annual_cost: 18.0,
    clothes_washer_efficiency: 1.63,
    clothes_washer_integrated_water_factor: 5.2,
  },
  [ClothesWasherPresets.ESTAR_2018]: {
    clothes_washer_capacity: 4.2,
    // labelEnergyRating: 152,
    clothes_washer_energy_star_label_gas_annual_cost: 12.0,
    clothes_washer_efficiency: 2.06,
    clothes_washer_integrated_water_factor: 4.3,
  },
  [ClothesWasherPresets.CEE_TIER_2]: {
    clothes_washer_capacity: 5.2,
    // labelEnergyRating: 125,
    clothes_washer_energy_star_label_gas_annual_cost: 9.0,
    clothes_washer_efficiency: 2.92,
    clothes_washer_integrated_water_factor: 3.2,
  },
};

export function createClothesWasherForm(appliances: AppliancesBackendDict) {
  return new ModelFormGroup({
    clothes_washer_preset: new ModelFormControl(
      appliances.clothes_washer_preset
    ),
    clothes_washer_capacity: new ModelFormControl(
      appliances.clothes_washer_capacity,
      [Validators.required, Validators.min(0)]
    ),
    clothes_washer_capacity_units: new ModelFormControl(
      appliances.clothes_washer_capacity_units,
      enumValidator([WasherCapacityUnit.CU_FT])
    ),
    clothes_washer_efficiency: new ModelFormControl(
      appliances.clothes_washer_efficiency,
      [requiredAsWarning(), Validators.min(0)]
    ),
    clothes_washer_efficiency_unit: new ModelFormControl(
      appliances.clothes_washer_efficiency_unit
    ),
    clothes_washer_label_electric_consumption: new ModelFormControl(
      appliances.clothes_washer_label_electric_consumption,
      [Validators.required, Validators.min(0)]
    ),
    clothes_washer_label_electric_consumption_unit: new ModelFormControl(
      appliances.clothes_washer_label_electric_consumption_unit,
      enumValidator([AuxEnergyUnit.KWH_YEAR])
    ),
    is_front_loading_clothes_washer: new ModelFormControl(
      appliances.is_front_loading_clothes_washer
    ),
    clothes_washer_location: new ModelFormControl(
      appliances.clothes_washer_location,
      Validators.required
    ),
    clothes_washer_integrated_water_factor: new ModelFormControl(
      appliances.clothes_washer_integrated_water_factor,
      [Validators.min(0)]
    ),
    clothes_washer_loads_per_week: new ModelFormControl(
      appliances.clothes_washer_loads_per_week
    ),
    clothes_washer_energy_star_label_electric_rate: new ModelFormControl(
      appliances.clothes_washer_energy_star_label_electric_rate
    ),
    clothes_washer_energy_star_label_electric_rate_unit: new ModelFormControl(
      appliances.clothes_washer_energy_star_label_electric_rate_unit,
      enumValidator([ApplianceCostRateUnit.USD_KWH])
    ),
    clothes_washer_energy_star_label_gas_rate: new ModelFormControl(
      appliances.clothes_washer_energy_star_label_gas_rate
    ),
    clothes_washer_energy_star_label_gas_rate_unit: new ModelFormControl(
      appliances.clothes_washer_energy_star_label_gas_rate_unit,
      enumValidator([ApplianceCostRateUnit.USD_THERM])
    ),
    clothes_washer_energy_star_label_gas_annual_cost: new ModelFormControl(
      appliances.clothes_washer_energy_star_label_gas_annual_cost
    ),
    clothes_washer_energy_star_label_gas_annual_cost_unit: new ModelFormControl(
      appliances.clothes_washer_energy_star_label_gas_annual_cost_unit,
      enumValidator([CostUnit.USD])
    ),
  });
}

export function createFansForm(appliances: AppliancesBackendDict) {
  return new ModelFormGroup(
    {
      ceiling_fan_consumption: new ModelFormControl(
        appliances.ceiling_fan_consumption,
        [Validators.required, Validators.min(0)]
      ),
      ceiling_fan_consumption_unit: new ModelFormControl(
        appliances.ceiling_fan_consumption_unit
      ),
      ceiling_fan_count: new ModelFormControl(
        appliances.ceiling_fan_count,
        Validators.min(0)
      ),
    },
    {
      validators: [validateCeilingFanConsumption],
    }
  );
}

function validateCeilingFanConsumption(controls: ModelFormGroup) {
  const consumption = controls.get('ceiling_fan_consumption').value;
  const count = controls.get('ceiling_fan_count').value;

  if (count && !consumption) {
    return {
      invalidCeilingFanConsumption: true,
    };
  }
}

export function getAppliancesForms(appliances: AppliancesBackendDict) {
  // Call all above form function and combine them into one form group
  return [
    createDishwasherForm(appliances),
    createRefrigeratorForm(appliances),
    createCookingRangeForm(appliances),
    createClothesDryerForm(appliances),
    createClothesWasherForm(appliances),
    createFansForm(appliances),
  ];
}
