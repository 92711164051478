import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from '@/data/core/models/user';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  CreateSFHIRLProject,
  HIRLGreenEnergyBadge,
  HIRLProjectAddSF,
  HIRLProjectAppealsProjectTypeMapping,
  HIRLProjectRegistration,
  HIRLProjectSampling,
  HIRLProjectSamplingMapping,
} from '@/data/customer-hirl/models';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { HIRLProjectService } from '@/data/customer-hirl/services/hirl-projects.service';
import { HIRLProjectRegistrationService } from '@/data/customer-hirl/services/hirl-project-registration.service';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '@/data/core/services/user.service';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { toggleLoading } from '@/state/actions/app.actions';
import { filter, first, takeUntil } from 'rxjs/operators';
import { getInfoUser } from '@/state/selectors/info.selector';
import { getHIRLProjectRegistration } from '@/state/selectors/customer-hirl/hirl-project-registration.selector';
import { hirlProjectAddressExistsValidator } from '@/modules/customer-hirl/field-validators';
import { HIRLProjectAppealsProjectType } from '@/data/customer-hirl/models/hirl-project';

@Component({
  selector: 'app-hirl-project-sf-add-form',
  templateUrl: './hirl-project-sf-add-form.component.html',
  styleUrls: ['./hirl-project-sf-add-form.component.scss'],
})
export class HirlProjectSfAddFormComponent implements OnInit, OnDestroy {
  protected readonly HIRLProjectSamplingMapping = HIRLProjectSamplingMapping;
  protected readonly HIRLProjectAppealsProjectTypeMapping =
    HIRLProjectAppealsProjectTypeMapping;

  public initialized = false;
  public currentUser: User;
  public formGroup: FormGroup;

  public hirlProjectRegistration: HIRLProjectRegistration;
  public hirlGreenEnergyBadges: HIRLGreenEnergyBadge[] = [];

  public customerHIRLSettings = CustomerHIRLSettings;

  private componentDestroyed$ = new Subject();

  constructor(
    public fb: FormBuilder,
    public store: Store<AppState>,
    public router: Router,
    public hirlProjectService: HIRLProjectService,
    public hirlProjectRegistrationService: HIRLProjectRegistrationService,
    public dialog: MatDialog,
    public activatedRoute: ActivatedRoute,
    public userService: UserService,
    public uiHelperService: UIHelperService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(toggleLoading({ payload: true }));

    this.activatedRoute.params
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(params => {
        this.store.dispatch(toggleLoading({ payload: true }));
        this.hirlProjectRegistrationService
          .retrieve(params.projectRegistrationId)
          .pipe(first())
          .subscribe();
      });

    this.store
      .select(getInfoUser)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(currentUser => {
        this.currentUser = currentUser;
      });

    this.store
      .select(getHIRLProjectRegistration)
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(val => val !== null)
      )
      .subscribe(registration => {
        this.hirlProjectRegistration = registration;
        this.store.dispatch(toggleLoading({ payload: false }));
        this.initialized = true;
      });

    this.setupForm();
  }

  public ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  public setupForm() {
    this.formGroup = this.fb.group({
      address: [
        null,
        Validators.required,
        [
          hirlProjectAddressExistsValidator(
            this.hirlProjectService,
            null,
            false
          ),
        ],
      ],
      hud_disaster_case_number: [''],
      green_energy_badges: [[]],
      is_require_wri_certification: [false],
      is_require_water_sense_certification: [false],
      appeals_project: [HIRLProjectAppealsProjectType.no],
      sampling: [HIRLProjectSampling.no_sampling],
    });
  }

  handleCreate(event) {
    event.preventDefault();

    this.formGroup.markAllAsTouched();
    if (this.formGroup.invalid) {
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const formData = new HIRLProjectAddSF();

    const projectFormData = new CreateSFHIRLProject();
    projectFormData.green_energy_badges =
      this.formGroup.value.green_energy_badges;

    projectFormData.hud_disaster_case_number =
      this.formGroup.value.hud_disaster_case_number;
    projectFormData.is_require_water_sense_certification =
      this.formGroup.value.is_require_water_sense_certification;

    projectFormData.lot_number = this.formGroup.value.address.lotNumber;
    projectFormData.home_address_geocode =
      this.formGroup.value.address.geocode.id;
    projectFormData.home_address_geocode_response = null;
    projectFormData.appeals_project = this.formGroup.value.appeals_project;
    projectFormData.sampling = this.formGroup.value.sampling;

    if (
      this.customerHIRLSettings.waterSenseProgramList.includes(
        this.hirlProjectRegistration?.eep_program_info?.slug
      )
    ) {
      projectFormData.is_require_wri_certification =
        this.formGroup.value.is_require_wri_certification;
    } else {
      projectFormData.is_require_wri_certification = false;
    }

    if (
      this.formGroup.value.address.geocode?.raw_city_info?.country_info
        ?.abbr === 'PR' ||
      this.formGroup.value.address.geocode?.raw_city_info?.country_info
        ?.abbr === 'VI'
    ) {
      projectFormData.is_require_rough_inspection = false;
    } else if (
      this.customerHIRLSettings.requireRoughInspectionProgramList.includes(
        this.hirlProjectRegistration.eep_program_info?.slug
      )
    ) {
      projectFormData.is_require_rough_inspection =
        this.formGroup.value.is_require_rough_inspection;
    } else {
      projectFormData.is_require_rough_inspection = true;
    }

    if (this.formGroup.value.address.geocodeBrokeredResponse) {
      projectFormData.home_address_geocode_response =
        this.formGroup.value.address.geocodeBrokeredResponse.id;
    }

    formData.projects.push(projectFormData);

    this.hirlProjectRegistrationService
      .hirl_projects_create_single_family(
        this.hirlProjectRegistration.id,
        formData
      )
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(
        projects => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.router.navigate([
            `/hi/project_registrations/`,
            this.hirlProjectRegistration.id,
          ]);
        },
        error => this.uiHelperService.handleUserRequestError(error)
      );
  }
}
