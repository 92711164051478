<h2 mat-dialog-title>
    {{ this.config.title }}
</h2>
<mat-dialog-content class="mat-typography">
    <ng-container [ngTemplateOutlet]="errorMessage" [ngTemplateOutletContext]="{error: allErrors, isRoot: true}"></ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-raised-button color="accent" [mat-dialog-close]="true">
        Close
    </button>
</mat-dialog-actions>

<ng-template #errorMessage let-error="error" let-isRoot="isRoot">
    <ng-container [ngSwitch]="error | typeof">
        <ng-container *ngSwitchCase="'object'">
            <ng-container *ngFor="let err of error | defaultOrderKeyValue">
                <div>
                    <ng-container *ngIf="config.showErrorKey && err.key && err.key !== 'non_field_errors'">
                        <b>{{ err.key.replace('_', ' ') | titlecase }}: </b>
                    </ng-container>
                    <ng-container [ngTemplateOutlet]="errorMessage" [ngTemplateOutletContext]="{error: err.value}"></ng-container>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'array'">
            <ng-container *ngIf="error.length == 1 && (((error[0] | typeof) !== 'object' && (error[0] | typeof) !== 'array') || isRoot); else arrayMessage">
                <ng-container [ngTemplateOutlet]="errorMessage" [ngTemplateOutletContext]="{error: error[0]}"></ng-container>
            </ng-container>
            <ng-template #arrayMessage>
                <ul *ngFor="let err of error">
                    <li>
                        <ng-container [ngTemplateOutlet]="errorMessage" [ngTemplateOutletContext]="{error: err}"></ng-container>
                    </li>
                </ul>
            </ng-template>
        </ng-container>
        <ng-container *ngSwitchDefault>
            {{ error }}
        </ng-container>
    </ng-container>
</ng-template>

