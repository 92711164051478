import {
  createLocationForm,
  FIELD_LABELS,
  LocationBackendDict,
} from '@/data/simulation/models/Location';
import { updateLocation } from '@/modules/simulation/state/location/actions';
import { selectLocationViewSet } from '@/modules/simulation/state/location/selectors';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HomeAddressConfirmedGeocodeResponse } from '@/modules/geocoder/components/home-address-to-geocode-response-dialog/home-address-to-geocode-response-dialog.component';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { WeatherSelectDialogComponent } from '../../weather-select-dialog/weather-select-dialog.component';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
})
export class LocationComponent implements OnInit, OnDestroy {
  @Input() entityId: number;
  @Input() simulationId: number;
  fieldLabels = FIELD_LABELS;

  componentDestroyed$ = new Subject();
  form: FormGroup;
  loading = false;

  location: LocationBackendDict;
  weatherName = '';

  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) mapInfoWindow: MapInfoWindow;
  locationConfirmedGeocodeResponse: HomeAddressConfirmedGeocodeResponse;
  mapCenter: google.maps.LatLngLiteral;
  mapOptions: google.maps.MapOptions = {
    disableDoubleClickZoom: true,
  };
  mapMarkers = [];
  mapInfoContent = '';
  constructor(public store: Store, private _dialog: MatDialog) {}

  ngOnInit(): void {
    this.store
      .select(selectLocationViewSet(this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(({ data, loading }) => {
        this.loading = loading;
        if (loading) return;

        this.location = data;
        this.weatherName = data.weather_info?.name || data.weather_station_name;

        this.setupMap();

        if (!this.form) {
          this.form = createLocationForm(data);
        } else {
          this.form.patchValue(data);
        }
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  onSave(changes: any): void {
    if (changes.hasOwnProperty('address')) {
      changes.lot_number = changes.address?.lotNumber || '';
      changes.geocode = changes.address?.geocode?.id || null;
      changes.geocode_response =
        changes.address?.geocodeBrokeredResponse?.id || null;
      delete changes.address;
    }

    this.store.dispatch(
      updateLocation({
        locationChanges: {
          id: this.entityId,
          ...changes,
        },
      })
    );
  }

  setupMap() {
    this.locationConfirmedGeocodeResponse =
      new HomeAddressConfirmedGeocodeResponse({
        lotNumber: this.location?.lot_number,
        geocode: this.location?.geocode_info,
        geocodeBrokeredResponse: this.location?.geocode_response_info,
      });

    // map
    if (
      this.locationConfirmedGeocodeResponse?.addressInfo?.lat &&
      this.locationConfirmedGeocodeResponse?.addressInfo?.lng
    ) {
      this.mapCenter = {
        lat: this.locationConfirmedGeocodeResponse?.addressInfo?.lat,
        lng: this.locationConfirmedGeocodeResponse?.addressInfo?.lng,
      };
      this.mapMarkers.push({
        position: this.mapCenter,
        title: 'Address',
        info: this.locationConfirmedGeocodeResponse.displayAddress,
      });
    } else {
      this.mapCenter = null;
      this.mapMarkers = [];
    }
  }

  mapOpenInfo(marker: MapMarker, content: string) {
    this.mapInfoContent = content;
    this.mapInfoWindow.open(marker);
  }

  replaceWeather() {
    const dialogRef = this._dialog.open(WeatherSelectDialogComponent, {
      disableClose: true,
      width: '600px',
      data: {
        simId: this.simulationId,
        locationId: this.entityId,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(
          updateLocation({
            locationChanges: {
              id: this.entityId,
              weather: result.id,
            },
          })
        );
      }
    });
  }
}
