import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-seasonal-rates',
  templateUrl: './seasonal-rates.component.html',
  styleUrls: ['./seasonal-rates.component.scss'],
})
export class SeasonalRatesComponent {
  @Input() entityIds: number;
}
