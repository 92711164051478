import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from '@/data/core/models/user';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { forkJoin, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { Router } from '@angular/router';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { toggleLoading } from '@/state/actions/app.actions';
import {
  defaultIfEmpty,
  first,
  map,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { getInfoUser } from '@/state/selectors/info.selector';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import {
  ApexRequestCreate,
  ApexRequestType,
  RESENTQATypeLabelMapping,
} from '@/data/home/models';
import { ApexRequestService } from '@/data/home/services/apex_request.service';
import { CustomerDocumentService } from '@/data/filehandling/services/customer-document.service';
import { CustomerDocument } from '@/data/filehandling/models';
import { forEach } from 'lodash';
import { CompanyService } from '@/data/company/services/company-base.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-apex-request-resnet-qa-create',
  templateUrl: './apex-request-resnet-qa-create.component.html',
  styleUrls: ['./apex-request-resnet-qa-create.component.scss'],
})
export class ApexRequestRESNETQACreateComponent implements OnInit, OnDestroy {
  protected readonly RESENTQATypeLabelMapping = RESENTQATypeLabelMapping;
  protected readonly ApexRequestType = ApexRequestType;
  public initialized = false;

  public files: File[] = [];
  public currentUser: User;
  public formGroup: FormGroup;

  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private dialog: MatDialog,
    private customerDocumentService: CustomerDocumentService,
    private companyService: CompanyService,
    private apexRequestService: ApexRequestService,
    private fb: UntypedFormBuilder,
    private uiHelperService: UIHelperService
  ) {}

  ngOnInit(): void {
    forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
    })
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(({ currentUser }) => {
        this.currentUser = currentUser;

        this.setupForm();
        this.initialized = true;
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  public setupForm() {
    this.formGroup = this.fb.group({
      request_type: [ApexRequestType.resnet_qa, Validators.required],
      resnet_qa_type: [null],
    });
  }

  onSubmit($event: MouseEvent) {
    $event.preventDefault();
    this.formGroup.markAllAsTouched();

    if (this.formGroup.invalid || !this.files.length) {
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const apexRequest: ApexRequestCreate = new ApexRequestCreate();

    apexRequest.request_type = this.formGroup.value.request_type;
    apexRequest.resnet_qa_type = this.formGroup.value.resnet_qa_type;

    const customerDocumentsChain$ = [];

    forEach(this.files, f => {
      const customerDocumentData = new CustomerDocument();

      const customerDocumentCreate$ = this.companyService
        .documentsCreate(this.currentUser.company, customerDocumentData)
        .pipe(
          first(),
          // update file fields
          switchMap(customerDocument => {
            const formData = new FormData();
            formData.append('document', f);
            return this.customerDocumentService.update(
              customerDocument.id,
              formData
            );
          })
        )
        .pipe(takeUntil(this.componentDestroyed$), first());

      customerDocumentsChain$.push(customerDocumentCreate$);
    });

    forkJoin(customerDocumentsChain$)
      .pipe(
        takeUntil(this.componentDestroyed$),
        defaultIfEmpty(null),
        map(customerDocuments =>
          customerDocuments.map(customerDocument => customerDocument.id)
        ),
        switchMap(customerDocumentsIds => {
          apexRequest.documents = customerDocumentsIds;

          return this.apexRequestService
            .create(apexRequest)
            .pipe(first(), takeUntil(this.componentDestroyed$));
        })
      )
      .subscribe({
        next: apexRequest => {
          this.store.dispatch(toggleLoading({ payload: false }));
          const _ = this.router.navigate([
            '/',
            'home',
            'apex',
            'request',
            'resnet-qa-detail',
            apexRequest.id,
          ]);
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  onSelect($event: NgxDropzoneChangeEvent) {
    this.files.push(...$event.addedFiles);
  }

  onRemove(f: File) {
    this.files.splice(this.files.indexOf(f), 1);
  }
}
