<div mat-dialog-title class="d-flex justify-content-between align-items-center">
    <h2>Select the weather</h2>
    <button mat-icon-button aria-label="close dialog" mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <mat-form-field appearance="outline" class="w-100">
        <mat-label>Weather</mat-label>
        <app-generic-model-autocomplete
            [formControl]="control"
            modelClassName="weather"
            [filterParams]="params"
            [panelWidth]="480">
        </app-generic-model-autocomplete>
    </mat-form-field>
</mat-dialog-content>