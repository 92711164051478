import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import {
  getCompany,
  selectCompanyCompanyAccesses,
  selectCompanyCompanyAccessesCount,
} from '@/state/selectors/company/company.selector';
import { CompanyService } from '@/data/company/services/company-base.service';
import { Company, CompanyAccess, CompanyRole } from '@/data/company/models';
import {
  CompanyAccessRequestParams,
  CompanyAccessService,
} from '@/data/company/services/company-access.service';
import {
  ChangeCompanyAccessDialogComponent,
  ChangeCompanyAccessDialogData,
} from '@/modules/company/components/change-company-access-dialog/change-company-access-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import * as _ from 'lodash';
import { ServerResponse } from '@/core/schemes/server-response';

@Component({
  selector: 'app-company-users-tab',
  templateUrl: './company-users-tab.component.html',
  styleUrls: ['./company-users-tab.component.scss'],
})
export class CompanyUsersTabComponent implements OnInit, OnDestroy {
  company: Company;
  companyDisplayName: string;

  filterFromGroup: FormGroup;
  isLoading = false;
  rows: CompanyAccess[] = [];
  rowsCount: number;

  public defaultParams: CompanyAccessRequestParams =
    new CompanyAccessRequestParams(1, '', '-user__first_name', 25);
  public storedParams: CompanyAccessRequestParams = Object.assign(
    new CompanyAccessRequestParams(),
    this.defaultParams
  );

  private listSubscription$: Subscription;
  private componentDestroyed$ = new Subject();
  displayedColumns = ['user__first_name', 'roles__name', 'actions'];

  constructor(
    private store: Store<AppState>,
    private injector: Injector,
    private activatedRoute: ActivatedRoute,
    private companyAccessService: CompanyAccessService,
    private companyService: CompanyService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private uiHelperService: UIHelperService
  ) {}

  ngOnInit(): void {
    this.defaultParams.user__is_active = true;

    this.storedParams = _.cloneDeep(this.defaultParams);
    this.storedParams.page_size =
      this.uiHelperService.getUISettings().rowsPerPage;

    this.store
      .select(getCompany)
      .pipe(takeUntil(this.componentDestroyed$))
      .pipe(filter(val => val !== null))
      .subscribe(company => {
        this.company = company;
        this.list();
      });

    this.store
      .select(selectCompanyCompanyAccesses)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(companyAccesses => {
        this.rows = companyAccesses;
      });

    this.store
      .select(selectCompanyCompanyAccessesCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(count => {
        this.rowsCount = count;
      });

    this.setupFilterForm();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  setupFilterForm() {
    this.filterFromGroup = this.fb.group({
      search: [null],
      roles: [null],
      user__is_active: [true],
    });

    this.filterFromGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        const params = _.merge({}, this.defaultParams, values);
        delete params.ordering;
        delete this.storedParams.roles;

        this.storedParams = _.merge(this.storedParams, params);
        this.list();
      });
  }
  list() {
    this.isLoading = true;

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }

    this.listSubscription$ = this.companyService
      .company_accesses(this.company.id, this.storedParams)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.rows = data.results;
        this.rowsCount = data.count;
        this.isLoading = false;
        window.dispatchEvent(new Event('resize'));
      });
  }

  edit($event: MouseEvent, companyAccess: CompanyAccess) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(ChangeCompanyAccessDialogComponent, {
      width: '45%',
      disableClose: true,
      data: {
        companyAccess,
      } as ChangeCompanyAccessDialogData,
    });

    dialogRef.afterClosed().subscribe((result?: CompanyAccess) => {
      if (!result) {
        return;
      }
      this.list();
      this.uiHelperService.openSnackBar('Record Successfully Added');
    });
  }

  onPaginateChange($event: PageEvent) {
    this.uiHelperService.getUISettings().rowsPerPage = $event.pageSize;
    this.storedParams.page_size = $event.pageSize;
    this.storedParams.page = $event.pageIndex + 1;
    this.list();
  }

  onSortChange($event: Sort) {
    this.storedParams.ordering = `${$event.direction === 'desc' ? '-' : ''}${
      $event.active
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  processCompanyRoleResponse(
    response: ServerResponse<CompanyRole>
  ): CompanyRole[] {
    const results = response.results;
    results.unshift({
      id: null,
      name: 'General User',
      slug: 'general_user',
    });
    return results;
  }
}
