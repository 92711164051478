import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { User } from '@/data/core/models/user';
import { FormBuilder, FormGroup } from '@angular/forms';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import {
  ClientAgreement,
  HIRLProductAgreement,
} from '@/data/customer-hirl/models';
import { HIRLProductAgreementService } from '@/data/customer-hirl/services/product-agreement.service';
import {
  loadHIRLProductAgreementDocuments,
  loadHIRLProductAgreementDocumentsCount,
} from '@/state/actions/customer-hirl/hirl-product-agreement.actions';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { MatDialog } from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import * as _ from 'lodash';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, takeUntil, tap } from 'rxjs/operators';
import { ServerResponse } from '@/core/schemes/server-response';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { toggleLoading } from '@/state/actions/app.actions';
import {
  CustomerDocumentRequestParams,
  CustomerDocumentService,
} from '@/data/filehandling/services/customer-document.service';
import { CustomerDocumentPermissionService } from '@/modules/filehandling/services/customer-document-permission-service';
import { CustomerDocument } from '@/data/filehandling/models';
import {
  CustomerDocumentEditDialogComponent,
  CustomerDocumentEditDialogData,
} from '@/modules/filehandling/components/customer-document-edit-dialog/customer-document-edit-dialog.component';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { ClientAgreementService } from '@/data/customer-hirl/services/client-agreement.service';
import {
  loadClientAgreementDocuments,
  loadClientAgreementDocumentsCount,
} from '@/state/actions/customer-hirl/client-agreement.actions';
import { Company } from '@/data/company/models';
import { CompanyService } from '@/data/company/services/company-base.service';
import {
  loadCompanyDocuments,
  loadCompanyDocumentsCount,
} from '@/state/actions/company/company.actions';
import { FloorPlan } from '@/data/floorplan/models/floorplan';
import { FloorPlanService } from '@/data/floorplan/services/floorplan.service';
import {
  loadFloorplanDocuments,
  loadFloorplanDocumentsCount,
} from '@/state/actions/floorplan/floorplan.actions';
import { Product } from '@/data/product/models';
import { ProductService } from '@/data/product/services/product.service';
import {
  loadProductDocuments,
  loadProductDocumentsCount,
} from '@/state/actions/product/product.actions';

@Component({
  selector: 'app-customer-document-tab',
  templateUrl: './customer-document-tab.component.html',
  styleUrls: ['./customer-document-tab.component.scss'],
})
export class CustomerDocumentTabComponent implements OnInit, OnDestroy {
  public entityName = 'Document';
  public currentUser: User;
  public displayedColumns = ['document', 'description', 'is_public', 'actions'];
  public filterFromGroup: FormGroup;
  public isLoading = false;
  public rows: CustomerDocument[] = [];
  public permissions: {
    [key: string]: {
      canEdit: Observable<ObjectPermissionResponse>;
      canDelete: Observable<ObjectPermissionResponse>;
    };
  } = {};
  public rowsCount: number;
  public createPermissionResponse: Observable<ObjectPermissionResponse>;

  public defaultParams: CustomerDocumentRequestParams =
    new CustomerDocumentRequestParams(1, '', '-id');

  public storedParams: CustomerDocumentRequestParams = Object.assign(
    new CustomerDocumentRequestParams(),
    this.defaultParams
  );

  private listSubscription$: Subscription;
  private componentDestroyed$ = new Subject();
  private modelService$: any;

  @Input() objectId: number | string;
  @Input() modelClassName: string;
  @Input() canCreate = true;

  public modelsMap = {
    hirl_product_agreement: {
      modelCls: HIRLProductAgreement,
      serviceCls: HIRLProductAgreementService,
      method: 'documents',
      filterParamsCls: CustomerDocumentRequestParams,
      listSelector: loadHIRLProductAgreementDocuments,
      countSelector: loadHIRLProductAgreementDocumentsCount,
    },
    client_agreement: {
      modelCls: ClientAgreement,
      serviceCls: ClientAgreementService,
      method: 'documents',
      filterParamsCls: CustomerDocumentRequestParams,
      listSelector: loadClientAgreementDocuments,
      countSelector: loadClientAgreementDocumentsCount,
    },
    company: {
      modelCls: Company,
      serviceCls: CompanyService,
      method: 'documents',
      filterParamsCls: CustomerDocumentRequestParams,
      listSelector: loadCompanyDocuments,
      countSelector: loadCompanyDocumentsCount,
    },
    floorplan: {
      modelCls: FloorPlan,
      serviceCls: FloorPlanService,
      method: 'documents',
      filterParamsCls: CustomerDocumentRequestParams,
      listSelector: loadFloorplanDocuments,
      countSelector: loadFloorplanDocumentsCount,
    },
    product: {
      modelCls: Product,
      serviceCls: ProductService,
      method: 'documents',
      filterParamsCls: CustomerDocumentRequestParams,
      listSelector: loadProductDocuments,
      countSelector: loadProductDocumentsCount,
    },
  };

  constructor(
    private store: Store<AppState>,
    private injector: Injector,
    private dialog: MatDialog,
    private customerDocumentService: CustomerDocumentService,
    private customerDocumentPermissionService: CustomerDocumentPermissionService,
    private fb: FormBuilder,
    private uiHelperService: UIHelperService
  ) {}

  ngOnInit(): void {
    this.storedParams = _.cloneDeep(this.defaultParams);

    this.modelService$ = this.injector.get(
      this.modelsMap[this.modelClassName].serviceCls
    );

    const sources = {
      currentUser: this.store.select(getInfoUser).pipe(first()),
    };

    forkJoin(sources)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: ({ currentUser }: { currentUser: User }) => {
          this.currentUser = currentUser;
          this.createPermissionResponse =
            this.customerDocumentService.canCreate();
          this.list();
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });

    this.setupFilterForm();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  setupFilterForm() {
    this.filterFromGroup = this.fb.group({
      search: [null],
    });

    this.filterFromGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        const params = _.merge({}, this.defaultParams, values);
        delete params.ordering;

        this.storedParams = _.merge(this.storedParams, params);
        this.list();
      });
  }

  list() {
    this.isLoading = true;

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }

    this.listSubscription$ = this.modelService$[
      this.modelsMap[this.modelClassName].method
    ](this.objectId, this.storedParams)
      .pipe(takeUntil(this.componentDestroyed$))
      .pipe(
        tap((serverResponse: ServerResponse<CustomerDocument>) => {
          if (this.modelsMap[this.modelClassName].listSelector) {
            this.store.dispatch(
              this.modelsMap[this.modelClassName].listSelector({
                payload: serverResponse.results,
              })
            );
          }
          if (this.modelsMap[this.modelClassName].countSelector) {
            this.store.dispatch(
              this.modelsMap[this.modelClassName].countSelector({
                payload: serverResponse.count,
              })
            );
          }
        })
      )
      .subscribe((serverResponse: ServerResponse<CustomerDocument>) => {
        this.rows = serverResponse.results;
        this.rowsCount = serverResponse.count;
        this.isLoading = false;
        this.rows.forEach(row => {
          this.permissions[row.id] = {
            canEdit: this.customerDocumentPermissionService.canEdit(row),
            canDelete: this.customerDocumentPermissionService.canDelete(row),
          };
        });
      });
  }

  onPaginateChange($event: PageEvent) {
    this.uiHelperService.getUISettings().rowsPerPage = $event.pageSize;
    this.storedParams.page_size = $event.pageSize;
    this.storedParams.page = $event.pageIndex + 1;
    this.list();
  }

  onSortChange($event: Sort) {
    this.storedParams.ordering = `${$event.direction === 'desc' ? '-' : ''}${
      $event.active
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  edit($event: MouseEvent, customerDocument: CustomerDocument) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(CustomerDocumentEditDialogComponent, {
      width: '45%',
      disableClose: true,
      autoFocus: false,
      data: {
        serviceCls: this.modelsMap[this.modelClassName].serviceCls,
        customerDocument: customerDocument,
      } as CustomerDocumentEditDialogData,
    });

    dialogRef.afterClosed().subscribe(changedAnnotation => {
      if (!changedAnnotation) {
        return;
      }
      this.list();
      this.uiHelperService.openSnackBar(
        `${this.entityName} Successfully updated`
      );
    });
  }

  delete($event: MouseEvent, customerDocument: CustomerDocument) {
    $event.preventDefault();

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '45%',
      data: {
        title: `Delete ${this.entityName} ?`,
        content: `Do you want to delete ${this.entityName}`,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      this.store.dispatch(toggleLoading({ payload: true }));
      this.customerDocumentService
        .delete(customerDocument.id)
        .pipe(takeUntil(this.componentDestroyed$), first())
        .subscribe(() => {
          this.list();
          this.store.dispatch(toggleLoading({ payload: false }));
        });
    });
  }

  onAddNew($event: MouseEvent) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(CustomerDocumentEditDialogComponent, {
      width: '55%',
      disableClose: true,
      autoFocus: false,
      data: {
        serviceCls: this.modelsMap[this.modelClassName].serviceCls,
        customerDocument: {
          objectId: this.objectId,
        },
      } as CustomerDocumentEditDialogData,
    });

    dialogRef.afterClosed().subscribe((result?: CustomerDocument) => {
      if (!result) {
        return;
      }
      this.list();
      this.uiHelperService.openSnackBar(
        `Document ${result.filename} Successfully Created`
      );
    });
  }
}
