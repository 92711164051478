import { AbstractControl, ValidatorFn } from '@angular/forms';

/** To validate autocomplete against an array of object options,
 * the validator can leverage the fact that the control.value will only be a string
 * if a valid Object option has not been selected.
 */
export function objectValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (typeof control.value === 'string') {
      if (control.value === '') {
        return null;
      }
      return {
        invalidObject: {
          value: control.value,
        },
      };
    }
    return null;
  };
}
