import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { User } from '@/data/core/models/user';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, takeUntil } from 'rxjs/operators';
import { AppState } from '@/state/reducers';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { Country } from '@/data/geographic/models';
import {
  EEPProgramHomeStatusService,
  HIRLProgramStatusRequestParams,
} from '@/data/home/services/eep_program_home_status.service';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import {
  EEPProgramHomeStatusState,
  HIRLEEPProgramHomeStatusStateLabelMapping,
} from '@/data/home/models/eep_program_home_status';
import { HIRLProjectBillingState } from '@/data/customer-hirl/models/hirl-project';
import {
  CustomerHIRLSettings,
  EEPProgramHomeStatusSlugSettings,
} from '@/modules/customer-hirl/constants';
import { QAHIRLCertificationLevelAwardedLabelMapping } from '@/data/qa/models';

@Component({
  selector: 'app-customer-hirl-home-status-metric-table',
  templateUrl: './customer-hirl-home-status-metric-table.component.html',
  styleUrls: ['./customer-hirl-home-status-metric-table.component.scss'],
})
export class CustomerHirlHomeStatusMetricTableComponent
  implements OnInit, OnDestroy
{
  protected readonly QAHIRLCertificationLevelAwardedLabelMapping =
    QAHIRLCertificationLevelAwardedLabelMapping;

  public initialized = false;
  public currentUser: User;
  public isLoading = true;
  public listSubscription: Subscription;

  public topStatsListSubscription: Subscription;
  public topStatsIsLoading = true;
  public topSingleFamilyStates = [];
  public topMultiFamilyStates = [];

  public topClientsListSubscription: Subscription;
  public topClientsIsLoading = true;
  public topSingleFamilyClients = [];
  public topMultiFamilyClients = [];

  public topVerifiersListSubscription: Subscription;
  public topVerifiersIsLoading = true;
  public topVerifiersByProject = [];
  public topVerifiersByUnit = [];

  public topCompaniesListSubscription: Subscription;
  public topCompaniesIsLoading = true;
  public topCompaniesByProject = [];
  public topCompaniesByUnit = [];

  public hirlProjectBillingStateLabelMapping = {
    [HIRLProjectBillingState.new]: 'New',
    [HIRLProjectBillingState.newQueued]: 'New - Queued',
    [HIRLProjectBillingState.newNotified]: 'New - Notified',
    [HIRLProjectBillingState.noticeSent]: 'Notice Sent',
    [HIRLProjectBillingState.completed]: 'Completed',
    [HIRLProjectBillingState.complimentary]: 'Complimentary',
    [HIRLProjectBillingState.not_pursuing]: 'Not pursuing',
    [HIRLProjectBillingState.test]: 'Test',
    [HIRLProjectBillingState.void]: 'Void',
    [HIRLProjectBillingState.special_4300]: '4300',
  };
  public eepProgramHomeStatusStateLabelMapping =
    HIRLEEPProgramHomeStatusStateLabelMapping;
  public defaultParams: HIRLProgramStatusRequestParams;
  public storedParams: HIRLProgramStatusRequestParams;

  public customerHIRLSettings = CustomerHIRLSettings;

  public filterForm: FormGroup;
  public projectTypeFormGroup: FormGroup;
  public projectStatusFormGroup: FormGroup;
  public standardVersionFormGroup: FormGroup;
  public certificationLevelFormGroup: FormGroup;

  public rows = [];

  private componentDestroyed$ = new Subject();

  public allCertificationPathSelected(slugs: string[]): boolean {
    const selectedSlugs = this.filterForm.get('eep_program__slug').value || [];
    const filteredSlugs: string[] = this.filterEEPProgramSlugsByYears(slugs);

    if (filteredSlugs.length === 0 || selectedSlugs.length === 0) {
      return false;
    }
    return filteredSlugs.every(item => selectedSlugs.includes(item));
  }

  public get allSingleFamilyRemodelSelected(): boolean {
    return this.allCertificationPathSelected(
      EEPProgramHomeStatusSlugSettings.singleFamilyRemodelSlugList
    );
  }

  public get allSingleFamilyNewConstructionSelected(): boolean {
    return this.allCertificationPathSelected(
      EEPProgramHomeStatusSlugSettings.singleFamilyNewConstructionSlugList
    );
  }

  public get allSingleFamilyCeritifiedSelected(): boolean {
    return this.allCertificationPathSelected(
      EEPProgramHomeStatusSlugSettings.singleFamilyCertifiedSlugList
    );
  }

  public get allMultiFamilyRemodelSelected(): boolean {
    return this.allCertificationPathSelected(
      EEPProgramHomeStatusSlugSettings.multiFamilyRemodelSlugList
    );
  }

  public get allMultiFamilyNewConstructionSelected(): boolean {
    return this.allCertificationPathSelected(
      EEPProgramHomeStatusSlugSettings.multiFamilyNewConstructionSlugList
    );
  }

  public get allLandDevelopmentSelected(): boolean {
    return this.allCertificationPathSelected(
      EEPProgramHomeStatusSlugSettings.landDevelopmentSlugList
    );
  }

  constructor(
    public fb: UntypedFormBuilder,
    public store: Store<AppState>,
    public router: Router,
    public injector: Injector,
    public dialog: MatDialog,
    public eepProgramHomeStatusService: EEPProgramHomeStatusService,
    public uiHelper: UIHelperService
  ) {
    this.defaultParams = new HIRLProgramStatusRequestParams();
    this.defaultParams.customer_hirl_project__billing_state = [];

    this.storedParams = new HIRLProgramStatusRequestParams();
    this.storedParams.customer_hirl_project__billing_state = [];
  }

  ngOnInit(): void {
    forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
    }).subscribe(({ currentUser }) => {
      this.currentUser = currentUser;

      this.initialized = true;
    });
    this.setupFilterForm();
    this.setupProjectTypeFormGroup();
    this.setupProjectStatusFormGroup();
    this.setupStandardVersionFormGroup();
    this.setupCertificationLevelFormGroup();
  }

  public setupFilterForm() {
    this.filterForm = this.fb.group({
      eep_program__slug: [[]],
      state: [[]],
      home__city__country_info: [[]],
      home__city__county__state: [[]],
      customer_hirl_project__billing_state: [[]],
      customer_hirl_project__certification_level: [],
      certification_date__gte: [],
      certification_date__lte: [],
      created_date__gte: [],
      created_date__lte: [],

      green_energy_badges: [false],
      hud_disaster_case_number: [false],
      is_appeals_project: [false],
      sampling: [false],
      is_require_wri_certification: [false],
      is_build_to_rent: [false],
      building_will_include_non_residential_space: [false],
      seeking_hud_mortgage_insurance_premium_reduction: [false],
      seeking_hud_green_and_resilient_retrofit_program: [false],
      seeking_fannie_mae_green_financing: [false],
      seeking_freddie_mac_green_financing: [false],
      intended_to_be_affordable_housing: [false],
      is_include_core_commercial_space: [false],
      is_included_fully_fitted_commercial_space: [false],

      single_family_remodel: [false],
      single_family_new_construction: [false],
      single_family_certified: [false],
      multi_family_remodel: [false],
      multi_family_new_construction: [false],
      land_development: [false],

      certified: [false],
      in_progress_excluding_abandoned: [false],
      in_progress_including_abandoned: [false],
    });

    this.filterForm.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        const params = Object.assign(this.defaultParams, values);
        delete params.ordering;

        if (params.home__city__country_info) {
          params.home__city__country = params.home__city__country_info.map(
            (country: Country) => country.id
          );
        } else {
          params.home__city__country = null;
        }
        delete params.home__city__country_info;

        // order matters
        params.hirl_additional_filter = [
          params.green_energy_badges,
          params.hud_disaster_case_number,
          params.sampling,
          params.is_appeals_project,
          params.is_require_wri_certification,
          params.building_will_include_non_residential_space,
          params.seeking_hud_mortgage_insurance_premium_reduction,
          params.seeking_hud_green_and_resilient_retrofit_program,
          params.seeking_fannie_mae_green_financing,
          params.seeking_freddie_mac_green_financing,
          params.intended_to_be_affordable_housing,
          params.is_build_to_rent,
          params.is_include_core_commercial_space,
          params.is_included_fully_fitted_commercial_space,
        ];

        delete params.green_energy_badges;
        delete params.hud_disaster_case_number;
        delete params.sampling;
        delete params.is_appeals_project;
        delete params.is_require_wri_certification;
        delete params.building_will_include_non_residential_space;
        delete params.seeking_hud_mortgage_insurance_premium_reduction;
        delete params.seeking_hud_green_and_resilient_retrofit_program;
        delete params.seeking_fannie_mae_green_financing;
        delete params.seeking_freddie_mac_green_financing;
        delete params.intended_to_be_affordable_housing;
        delete params.is_build_to_rent;
        delete params.is_include_core_commercial_space;
        delete params.is_included_fully_fitted_commercial_space;

        this.projectTypeFormGroup.patchValue(
          {
            single_family_remodel: this.allSingleFamilyRemodelSelected,
            single_family_new_construction:
              this.allSingleFamilyNewConstructionSelected,
            single_family_certified: this.allSingleFamilyCeritifiedSelected,
            multi_family_remodel: this.allMultiFamilyRemodelSelected,
            multi_family_new_construction:
              this.allMultiFamilyNewConstructionSelected,
            land_development: this.allLandDevelopmentSelected,
          },
          { emitEvent: false, onlySelf: false }
        );

        this.storedParams = Object.assign(this.storedParams, params);
        this.list();
      });
  }

  public setupProjectTypeFormGroup() {
    this.projectTypeFormGroup = this.fb.group({
      single_family_remodel: [false],
      single_family_new_construction: [false],
      single_family_certified: [false],
      multi_family_remodel: [false],
      multi_family_new_construction: [false],
      land_development: [false],
    });

    this.projectTypeFormGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        let programSlugs: string[] = [];

        if (values.single_family_remodel) {
          programSlugs = programSlugs.concat(
            this.filterEEPProgramSlugsByYears(
              EEPProgramHomeStatusSlugSettings.singleFamilyRemodelSlugList
            )
          );
        }

        if (values.single_family_new_construction) {
          programSlugs = programSlugs.concat(
            this.filterEEPProgramSlugsByYears(
              EEPProgramHomeStatusSlugSettings.singleFamilyNewConstructionSlugList
            )
          );
        }

        if (values.single_family_certified) {
          programSlugs = programSlugs.concat(
            this.filterEEPProgramSlugsByYears(
              EEPProgramHomeStatusSlugSettings.singleFamilyCertifiedSlugList
            )
          );
        }

        if (values.multi_family_remodel) {
          programSlugs = programSlugs.concat(
            this.filterEEPProgramSlugsByYears(
              EEPProgramHomeStatusSlugSettings.multiFamilyRemodelSlugList
            )
          );
        }

        if (values.multi_family_new_construction) {
          programSlugs = programSlugs.concat(
            this.filterEEPProgramSlugsByYears(
              EEPProgramHomeStatusSlugSettings.multiFamilyNewConstructionSlugList
            )
          );
        }

        if (values.land_development) {
          programSlugs = programSlugs.concat(
            this.filterEEPProgramSlugsByYears(
              EEPProgramHomeStatusSlugSettings.landDevelopmentSlugList
            )
          );
        }

        this.filterForm.get('eep_program__slug').patchValue(programSlugs);
      });
  }

  public setupStandardVersionFormGroup() {
    this.standardVersionFormGroup = this.fb.group({
      version_2008: [false],
      version_2012: [false],
      version_2015: [false],
      version_2020: [false],
    });

    this.standardVersionFormGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        this.projectTypeFormGroup.patchValue({});
      });
  }

  public setupProjectStatusFormGroup() {
    const controlsConfig = {};
    Object.keys(this.eepProgramHomeStatusStateLabelMapping).forEach(
      key => (controlsConfig[key] = [false])
    );

    controlsConfig['in_progress_excluding_abandoned'] = [false];
    controlsConfig['in_progress_including_abandoned'] = [false];
    this.projectStatusFormGroup = this.fb.group(controlsConfig);

    this.projectStatusFormGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        let projectStatuses: string[] = [];

        Object.keys(values).forEach(key => {
          const value = values[key];
          if (value) {
            if (key === 'in_progress_including_abandoned') {
              projectStatuses = projectStatuses.concat([
                EEPProgramHomeStatusState.customerHIRLPendingProjectData,
                EEPProgramHomeStatusState.customerHIRLPendingRoughQA,
                EEPProgramHomeStatusState.customerHIRLPendingFinalQA,
                EEPProgramHomeStatusState.customerHIRLPendingFinalQA,
                EEPProgramHomeStatusState.abandoned,
              ]);
            } else if (key === 'in_progress_excluding_abandoned') {
              projectStatuses = projectStatuses.concat([
                EEPProgramHomeStatusState.customerHIRLPendingProjectData,
                EEPProgramHomeStatusState.customerHIRLPendingRoughQA,
                EEPProgramHomeStatusState.customerHIRLPendingFinalQA,
              ]);
            } else {
              projectStatuses = projectStatuses.concat([key]);
            }
          }
        });

        projectStatuses = Array.from(new Set(projectStatuses));
        this.filterForm.get('state').patchValue(projectStatuses);
      });
  }

  public setupCertificationLevelFormGroup() {
    const controlsConfig = {};
    Object.keys(QAHIRLCertificationLevelAwardedLabelMapping).forEach(
      key => (controlsConfig[key] = [false])
    );
    this.certificationLevelFormGroup = this.fb.group(controlsConfig);

    this.certificationLevelFormGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        let certificationLevels: string[] = [];

        Object.keys(values).forEach(key => {
          const value = values[key];
          if (value) {
            certificationLevels = certificationLevels.concat([key]);
          }
        });

        certificationLevels = Array.from(new Set(certificationLevels));
        this.filterForm
          .get('customer_hirl_project__certification_level')
          .patchValue(certificationLevels);
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  list() {
    this.isLoading = true;
    if (this.listSubscription) {
      this.listSubscription.unsubscribe();
    }
    this.listSubscription = this.eepProgramHomeStatusService
      .hirl_aggregate_dashboard_stats(this.storedParams)
      .pipe(first(), takeUntil(this.componentDestroyed$))
      .subscribe(
        hirlAggregateDashboard => {
          this.isLoading = false;
          this.rows = [
            {
              key: 'Projects/Buildings',
              value: hirlAggregateDashboard.buildings_count,
            },
            {
              key: 'Units',
              value: hirlAggregateDashboard.unit_count,
            },
            {
              key: 'Commercial Space',
              value: hirlAggregateDashboard.total_commercial_space,
            },
            {
              key: 'Average Units',
              value: hirlAggregateDashboard.average_units,
            },
            {
              key: 'Count of HERS Score projects',
              value: hirlAggregateDashboard.hers_score_is_not_null_count,
            },
            {
              key: 'Average HERS Score',
              value: hirlAggregateDashboard.average_hers_score,
            },
          ];
        },
        error => this.uiHelper.handleUserRequestError(error)
      );

    this.topStatsIsLoading = true;
    if (this.topStatsListSubscription) {
      this.topStatsListSubscription.unsubscribe();
    }
    this.topStatsListSubscription = this.eepProgramHomeStatusService
      .customer_hirl_top_states_stats(this.storedParams)
      .pipe(first(), takeUntil(this.componentDestroyed$))
      .subscribe(
        customerHIRLTopStatesStats => {
          this.topStatsIsLoading = false;
          this.topSingleFamilyStates =
            customerHIRLTopStatesStats.states_for_sf_grouped_by_count
              .map(value => {
                const stateName = value[0] ? value[0] : 'unknown';
                return { name: stateName, value: value[1] };
              })
              .slice(0, 12);
          this.topMultiFamilyStates =
            customerHIRLTopStatesStats.states_for_mf_grouped_by_unit_count
              .map(value => {
                const stateName = value[0] ? value[0] : 'unknown';
                return { name: stateName, value: value[1] };
              })
              .slice(0, 12);
        },
        error => this.uiHelper.handleUserRequestError(error)
      );

    this.topClientsIsLoading = true;
    if (this.topClientsListSubscription) {
      this.topClientsListSubscription.unsubscribe();
    }
    this.topClientsListSubscription = this.eepProgramHomeStatusService
      .customer_hirl_top_client_stats(this.storedParams)
      .pipe(first(), takeUntil(this.componentDestroyed$))
      .subscribe(
        customerHIRLTopClientsStats => {
          this.topClientsIsLoading = false;
          this.topSingleFamilyClients =
            customerHIRLTopClientsStats.clients_for_sf_grouped_by_count
              .map(value => {
                return { name: value[0], value: value[1] };
              })
              .slice(0, 12);
          this.topMultiFamilyClients =
            customerHIRLTopClientsStats.clients_for_mf_grouped_by_unit_count
              .map(value => {
                return { name: value[0], value: value[1] };
              })
              .slice(0, 12);
        },
        error => this.uiHelper.handleUserRequestError(error)
      );

    this.topVerifiersIsLoading = true;
    if (this.topVerifiersListSubscription) {
      this.topVerifiersListSubscription.unsubscribe();
    }

    this.topVerifiersListSubscription = this.eepProgramHomeStatusService
      .customer_hirl_top_verifier_stats(this.storedParams)
      .pipe(first(), takeUntil(this.componentDestroyed$))
      .subscribe(
        customerHIRLTopVerifiersStats => {
          this.topVerifiersIsLoading = false;
          this.topVerifiersByProject =
            customerHIRLTopVerifiersStats.verifiers_by_project_count
              .map(value => {
                const fullName = `${value.verifier_first_name} ${value.verifier_last_name}`;
                return { name: fullName, value: value.projects_count };
              })
              .slice(0, 12);
          this.topVerifiersByUnit =
            customerHIRLTopVerifiersStats.verifiers_by_unit_count
              .map(value => {
                const fullName = `${value.verifier_first_name} ${value.verifier_last_name}`;
                const totalUnits = value.total_units ? value.total_units : 0;
                return { name: fullName, value: totalUnits };
              })
              .slice(0, 12);
        },
        error => this.uiHelper.handleUserRequestError(error)
      );

    this.topCompaniesIsLoading = true;
    if (this.topCompaniesListSubscription) {
      this.topCompaniesListSubscription.unsubscribe();
    }

    this.topCompaniesListSubscription = this.eepProgramHomeStatusService
      .customer_hirl_top_company_stats(this.storedParams)
      .pipe(first(), takeUntil(this.componentDestroyed$))
      .subscribe(
        customerHIRLTopCompaniesStats => {
          this.topCompaniesIsLoading = false;
          this.topCompaniesByProject =
            customerHIRLTopCompaniesStats.companies_by_project_count
              .map(value => {
                const companyName = value.company_name
                  ? value.company_name
                  : 'unknown';
                return { name: companyName, value: value.projects_count };
              })
              .slice(0, 12);
          this.topCompaniesByUnit =
            customerHIRLTopCompaniesStats.companies_by_unit_count
              .map(value => {
                const companyName = value.company_name
                  ? value.company_name
                  : 'unknown';
                const totalUnits = value.total_units ? value.total_units : 0;
                return { name: companyName, value: totalUnits };
              })
              .slice(0, 12);
        },
        error => this.uiHelper.handleUserRequestError(error)
      );
  }

  resetQuickFilters() {
    let resetKeys = {};
    this.projectTypeFormGroup.patchValue({
      single_family_remodel: false,
      single_family_new_construction: false,
      single_family_certified: false,
      multi_family_remodel: false,
      multi_family_new_construction: false,
      land_development: false,
    });

    resetKeys = {
      in_progress_excluding_abandoned: false,
      in_progress_including_abandoned: false,
    };
    Object.keys(this.eepProgramHomeStatusStateLabelMapping).forEach(key => {
      resetKeys[key] = false;
    });
    this.projectStatusFormGroup.patchValue(resetKeys);

    this.standardVersionFormGroup.patchValue({
      version_2008: false,
      version_2012: false,
      version_2015: false,
      version_2020: false,
    });
    resetKeys = {};
    Object.keys(QAHIRLCertificationLevelAwardedLabelMapping).forEach(key => {
      resetKeys[key] = false;
    });
    this.certificationLevelFormGroup.patchValue(resetKeys);
  }
  resetAdditionalFilters() {
    this.filterForm.patchValue({
      green_energy_badges: false,
      hud_disaster_case_number: false,
      is_appeals_project: false,
      sampling: false,
      is_require_wri_certification: false,
      is_build_to_rent: false,
      building_will_include_non_residential_space: false,
      seeking_hud_mortgage_insurance_premium_reduction: false,
      seeking_hud_green_and_resilient_retrofit_program: false,
      seeking_fannie_mae_green_financing: false,
      seeking_freddie_mac_green_financing: false,
      intended_to_be_affordable_housing: false,
      is_include_core_commercial_space: false,
      is_included_fully_fitted_commercial_space: false,
    });
  }

  filterEEPProgramSlugsByYears(programSlugs: string[]): string[] {
    let filteredSlugs: string[] = [];

    if (
      !this.standardVersionFormGroup.get('version_2008').value &&
      !this.standardVersionFormGroup.get('version_2012').value &&
      !this.standardVersionFormGroup.get('version_2015').value &&
      !this.standardVersionFormGroup.get('version_2020').value
    ) {
      return programSlugs;
    }

    if (this.standardVersionFormGroup.get('version_2008').value) {
      filteredSlugs = filteredSlugs.concat(
        programSlugs.filter(
          item =>
            !item.includes('2012') ||
            !item.includes('2015') ||
            !item.includes('2020')
        )
      );
    }
    if (this.standardVersionFormGroup.get('version_2012').value) {
      filteredSlugs = filteredSlugs.concat(
        programSlugs.filter(item => item.includes('2012'))
      );
    }
    if (this.standardVersionFormGroup.get('version_2015').value) {
      filteredSlugs = filteredSlugs.concat(
        programSlugs.filter(item => item.includes('2015'))
      );
    }
    if (this.standardVersionFormGroup.get('version_2020').value) {
      filteredSlugs = filteredSlugs.concat(
        programSlugs.filter(item => item.includes('2020'))
      );
    }

    return filteredSlugs;
  }
}
