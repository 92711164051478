import { createReducer, on } from '@ngrx/store';
import * as MechanicalVentilationActions from './actions';
import { MechanicalVentilationBackendDict } from '@/data/simulation/models/systems/MechanicalVentilation';
import { BaseState } from '../shared/base.state';
import { addStateErrorCount } from '../helper';

export type MechanicalVentilationState =
  BaseState<MechanicalVentilationBackendDict>;

export const initialState: MechanicalVentilationState = {
  entities: {},
  loading: {},
  errors: {},
};

export const reducer = createReducer(
  initialState,
  on(
    MechanicalVentilationActions.loadMechanicalVentilationsSuccess,
    (state, { mechanicalVentilations, errors }) => {
      const entities = mechanicalVentilations.reduce(
        (acc, mechanicalventilation) => ({
          ...acc,
          [mechanicalventilation.id]: mechanicalventilation,
        }),
        {}
      );
      const loading = mechanicalVentilations.reduce(
        (acc, mechanicalventilation) => ({
          ...acc,
          [mechanicalventilation.id]: false,
        }),
        {}
      );

      return {
        entities: {
          ...state.entities,
          ...entities,
        },
        loading: {
          ...state.loading,
          ...loading,
        },
        errors: {
          ...state.errors,
          ...addStateErrorCount(errors),
        },
      };
    }
  ),

  on(
    MechanicalVentilationActions.updateMechanicalVentilation,
    (state, { mechanicalVentilationChanges }) => ({
      ...state,
      loading: {
        ...state.loading,
        [mechanicalVentilationChanges.id]: true,
      },
    })
  ),

  on(
    MechanicalVentilationActions.updateMechanicalVentilationFailure,
    (state, { id }) => ({
      ...state,
      loading: {
        ...state.loading,
        [id]: false,
      },
    })
  ),

  on(
    MechanicalVentilationActions.removeMechanicalVentilation,
    (state, { mechanicalVentilation }) => ({
      ...state,
      loading: {
        ...state.loading,
        [mechanicalVentilation.id]: true,
      },
    })
  ),

  on(
    MechanicalVentilationActions.removeMechanicalVentilationSuccess,
    (state, { id }) => {
      const entities = { ...state.entities };
      delete entities[id];

      return {
        entities,
        loading: {
          ...state.loading,
          [id]: false,
        },
        errors: {
          ...state.errors,
          [id]: undefined,
        },
      };
    }
  ),

  on(
    MechanicalVentilationActions.removeMechanicalVentilationFailure,
    (state, { id }) => ({
      ...state,
      loading: {
        ...state.loading,
        [id]: false,
      },
    })
  )
);
