<div mat-dialog-title>
    <span>
        <span *ngIf="!data?.geocodeAddressInfo">Add</span>
        <span *ngIf="data?.geocodeAddressInfo">Edit</span>
        Address <i class="fas fa-map-marker-alt"></i>
    </span>
    <button type="button" class="close" aria-label="Close address dialog" (click)="handleClose()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div mat-dialog-content>
    <div *ngIf="!geocode">
        <div class="row mt-2" [formGroup]="addressForm">
            <div class="col-12">
                <div class="row">
                    <div class="col-6">
                        <mat-form-field appearance="outline" class="w-100 mb-2">
                            <mat-label>Street Line 1</mat-label>
                            <input matInput placeholder="Street Line 1" formControlName="street_line1">
                            <mat-error *ngIf="addressForm.controls['street_line1'].hasError('required')">
                                Street Line is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field appearance="outline" class="w-100 mb-2">
                            <mat-label>Street Line 2</mat-label>
                            <input matInput placeholder="Street Line 2" formControlName="street_line2">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <mat-form-field style="width: 100%;" appearance="outline" class="mb-2">
                            <mat-label>City/County/State</mat-label>
                            <app-generic-model-autocomplete formControlName="city_info" modelClassName="city" [filterParams]="{ordering: 'name'}"></app-generic-model-autocomplete>
                            <mat-error *ngIf="addressForm.controls.city_info.hasError('required')">City is required -
                                Must select option from list
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field style="width: 100%;" appearance="outline" class="mb-2">
                            <mat-label>ZIP Code</mat-label>
                            <input matInput placeholder="Zip code" formControlName="zipcode">
                            <mat-error *ngIf="addressForm.controls['zipcode'].hasError('required')">
                                ZIP Code is required
                            </mat-error>
                            <mat-error *ngIf="addressForm.controls['zipcode'].hasError('maxlength')">
                                ZIP Code must contains 5 digits
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div *ngIf="geocode">
        <p *ngIf="geocode.valid_responses.length >  1">
            We found multiple addresses
        </p>
        <p *ngIf="geocode.valid_responses.length == 1">
            Please confirm Home address
        </p>
        <p *ngIf="geocode.valid_responses.length == 0">
            We can't find any valid address on Map.
        </p>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="row" *ngIf="!geocode">
                <div class="col-12">
                    <div class="text-center">
                        <mat-spinner [diameter]="50" *ngIf="matchesLoading"></mat-spinner>
                        <button class="btn btn-primary"
                                (click)="handleAdd()"
                                *ngIf="!matchesLoading">
                        <span *ngIf="!data?.geocodeAddressInfo">
                            Add Address
                        </span>
                            <span *ngIf="data?.geocodeAddressInfo">
                            Change Address
                        </span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="geocode">
                <div class="col-12">
                    <div class="text-center">
                        <div>
                            <button *ngFor="let geocodeResponse of geocode.valid_responses; let idx=index;"
                                    class="btn btn-outline-primary mt-2 w-100"
                                    (click)="handleConfirmAddress(geocodeResponse)"
                                    cdkFocusInitial>
                                <span>{{geocodeResponse.street_line1}}<span *ngIf="geocodeResponse.street_line2"> {{geocodeResponse.street_line2}}</span>,
                                    {{geocodeResponse.city_info.name}}, {{geocodeResponse.city_info.county_info.name}},
                                    {{geocodeResponse.city_info.county_info.state}} {{geocodeResponse.zipcode}}
                                </span>
                            </button>
                            <button class="col-12 btn btn-outline-primary mt-2"
                                    (click)="handleUseAddressAsEntered()">
                                <span *ngIf="geocode.valid_responses.length > 1">
                                    This addresses is not correct, I want to use address <b>As Entered</b>: "{{rawAddress}}"
                                </span>
                                <span *ngIf="geocode.valid_responses.length == 1">
                                    This address is not correct. I want to use address <b>As Entered</b>: "{{rawAddress}}"
                                </span>
                                <span *ngIf="geocode.valid_responses.length == 0">
                                    I want to use address <b>As Entered</b>: "{{rawAddress}}"
                                </span>
                            </button>
                            <button class="btn btn-outline-dark mt-2 w-100"
                                    (click)="handleCancel()">Back
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
