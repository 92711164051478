import { Component, OnDestroy, OnInit } from '@angular/core';
import { toggleLoading } from '@/state/actions/app.actions';
import { Store } from '@ngrx/store';
import { getInfoUser } from '@/state/selectors/info.selector';
import {
  defaultIfEmpty,
  distinctUntilChanged,
  first,
  map,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { forkJoin, Subject } from 'rxjs';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { HIRLProjectService } from '@/data/customer-hirl/services/hirl-projects.service';
import { HIRLProjectRegistrationService } from '@/data/customer-hirl/services/hirl-project-registration.service';
import {
  HIRLGreenEnergyBadgeRequestParams,
  HIRLGreenEnergyBadgeService,
} from '@/data/customer-hirl/services/hirl-green-energy-badge.service';
import { MatDialog } from '@angular/material/dialog';
import { GeocoderService } from '@/data/geocoder/services/geocoder.service';
import { UserService } from '@/data/core/services/user.service';
import { User } from '@/data/core/models/user';
import {
  HIRLGreenEnergyBadge,
  HIRLProjectAppealsProjectTypeMapping,
  HIRLProjectCommercialSpaceTypeLabelMapping,
  HIRLProjectRegistration,
  HIRLProjectSampling,
  HIRLProjectSamplingMapping,
} from '@/data/customer-hirl/models';
import {
  CreateMFHIRLProject,
  HIRLProjectAddMF,
} from '@/data/customer-hirl/models/hirl-project-registration';
import { conditionalValidator } from '@/core/validators';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { HIRLProjectAppealsProjectType } from '@/data/customer-hirl/models/hirl-project';
import { IGeocodeMatches } from '@/data/geocoder/models';

@Component({
  selector: 'app-hirl-project-mf-add-form',
  templateUrl: './hirl-project-mf-add-form.component.html',
  styleUrls: ['./hirl-project-mf-add-form.component.scss'],
})
export class HIRLProjectMFAddFormComponent implements OnInit, OnDestroy {
  protected readonly HIRLProjectSamplingMapping = HIRLProjectSamplingMapping;
  protected readonly HIRLProjectAppealsProjectTypeMapping =
    HIRLProjectAppealsProjectTypeMapping;

  public initialized = false;
  public currentUser: User;
  public addFormGroup: UntypedFormGroup;

  public isAccessoryStructure = false;

  public hirlProjectRegistration: HIRLProjectRegistration;
  public hirlGreenEnergyBadges: HIRLGreenEnergyBadge[] = [];

  public commercialSpaceTypeLabelMapping =
    HIRLProjectCommercialSpaceTypeLabelMapping;

  public customerHIRLSettings = CustomerHIRLSettings;

  private destroy$ = new Subject();

  constructor(
    public fb: UntypedFormBuilder,
    public store: Store<AppState>,
    public router: Router,
    public hirlProjectService: HIRLProjectService,
    public hirlProjectRegistrationService: HIRLProjectRegistrationService,
    public hirlGreenEnergyBadgeService: HIRLGreenEnergyBadgeService,
    public dialog: MatDialog,
    public activatedRoute: ActivatedRoute,
    public geocoderService: GeocoderService,
    public userService: UserService,
    public uiHelperService: UIHelperService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(toggleLoading({ payload: true }));
    this.isAccessoryStructure =
      this.activatedRoute.snapshot.queryParams.is_accessory_structure ===
      'true';

    this.hirlProjectRegistrationService
      .retrieve(this.activatedRoute.snapshot.params.projectRegistrationId)
      .pipe(first())
      .subscribe(hirlProjectRegistration => {
        this.hirlProjectRegistration = hirlProjectRegistration;

        const greenEnergyBadgeRequestParams =
          new HIRLGreenEnergyBadgeRequestParams();
        greenEnergyBadgeRequestParams.eep_program =
          this.hirlProjectRegistration.eep_program;

        const sources: { [k: string]: any } = {
          currentUser: this.store.select(getInfoUser).pipe(first()),
          hirlGreenEnergyBadges: this.hirlGreenEnergyBadgeService
            .list(greenEnergyBadgeRequestParams)
            .pipe(
              first(),
              map(data => data.results)
            ),
        };

        forkJoin(sources).subscribe(
          ({ currentUser, hirlGreenEnergyBadges }) => {
            this.currentUser = currentUser;
            this.hirlGreenEnergyBadges = hirlGreenEnergyBadges;

            this.store.dispatch(toggleLoading({ payload: false }));
            this.initialized = true;
          }
        );
      });

    this.setupAddFormGroup();
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  handleBack() {
    this.router.navigate([
      'hi',
      'project_registrations',
      this.hirlProjectRegistration.id,
    ]);
  }

  public setupAddFormGroup() {
    this.addFormGroup = this.fb.group({
      building_number: [
        null,
        conditionalValidator(
          () => !this.isAccessoryStructure,
          Validators.required
        ),
      ],
      address: [null, [Validators.required]],
      number_of_units: [
        '',
        conditionalValidator(
          () => !this.isAccessoryStructure,
          Validators.compose([
            Validators.required,
            Validators.min(0),
            Validators.max(1000000),
          ])
        ),
      ],
      story_count: [
        null,
        conditionalValidator(
          () => !this.isAccessoryStructure,
          Validators.compose([
            Validators.required,
            Validators.min(0),
            Validators.max(1000000),
          ])
        ),
      ],
      is_include_commercial_space: [false],
      commercial_space_type: [
        null,
        conditionalValidator(
          () => this.addFormGroup.value.is_include_commercial_space,
          Validators.required
        ),
      ],
      commercial_space_street_line_2: [
        '',
        conditionalValidator(
          () => this.addFormGroup.value.is_include_commercial_space.value,
          Validators.required
        ),
      ],
      total_commercial_space: [
        null,
        conditionalValidator(
          () => this.addFormGroup.value.is_include_commercial_space,
          Validators.compose([
            Validators.required,
            Validators.min(0),
            Validators.max(1000000),
          ])
        ),
      ],
      hud_disaster_case_number: [''],
      green_energy_badges: [[]],
      accessory_structure_description: [
        '',
        conditionalValidator(
          () => this.isAccessoryStructure,
          Validators.required
        ),
      ],
      is_require_water_sense_certification: [false],
      is_require_rough_inspection: [true],
      is_require_wri_certification: [false],
      appeals_project: [HIRLProjectAppealsProjectType.no],
      sampling: [HIRLProjectSampling.no_sampling],
      is_townhouse: [false],
    });

    this.addFormGroup
      .get('is_include_commercial_space')
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(value => {
        if (!value) {
          this.addFormGroup.get('commercial_space_street_line_2').reset();
          this.addFormGroup.get('commercial_space_type').reset();
          this.addFormGroup.get('total_commercial_space').reset();
        }
      });

    this.attachFieldCheckHomeWithAddressAlreadyExists(
      this.addFormGroup,
      'address',
      true
    );

    this.addFormGroup
      .get('is_townhouse')
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe({
        next: is_townhouse => {
          if (is_townhouse) {
            this.addFormGroup.get('story_count').patchValue(2);
            this.addFormGroup.get('number_of_units').patchValue(2);
            this.addFormGroup.get('story_count').disable();
            this.addFormGroup.get('number_of_units').disable();
          } else {
            this.addFormGroup.get('story_count').enable();
            this.addFormGroup.get('number_of_units').enable();
          }
        },
      });
  }

  accessoryStructureFormData(): CreateMFHIRLProject {
    const projectFormData = new CreateMFHIRLProject();
    projectFormData.is_accessory_structure = true;
    projectFormData.accessory_structure_description =
      this.addFormGroup.value.accessory_structure_description;

    projectFormData.lot_number = this.addFormGroup.value.address.lotNumber;
    projectFormData.home_address_geocode =
      this.addFormGroup.value.address.geocode.id;
    projectFormData.home_address_geocode_response = null;

    if (this.addFormGroup.value.address.geocodeBrokeredResponse) {
      projectFormData.home_address_geocode_response =
        this.addFormGroup.value.address.geocodeBrokeredResponse.id;
    }

    return projectFormData;
  }

  commercialSpaceFormData(geocode): CreateMFHIRLProject {
    const projectFormData = new CreateMFHIRLProject();
    projectFormData.is_accessory_structure = false;
    projectFormData.accessory_structure_description = '';

    projectFormData.lot_number = this.addFormGroup.value.address.lotNumber;
    projectFormData.home_address_geocode = geocode.id;
    projectFormData.home_address_geocode_response = null;

    projectFormData.is_include_commercial_space =
      this.addFormGroup.value.is_include_commercial_space;
    projectFormData.commercial_space_type =
      this.addFormGroup.value.commercial_space_type;
    projectFormData.total_commercial_space =
      this.addFormGroup.value.total_commercial_space;
    projectFormData.story_count = 1;
    projectFormData.number_of_units = 1;
    projectFormData.green_energy_badges =
      this.addFormGroup.value.green_energy_badges;
    projectFormData.building_number = this.addFormGroup.value.building_number;

    return projectFormData;
  }

  handleCreate(event) {
    event.preventDefault();

    this.addFormGroup.markAllAsTouched();
    if (this.addFormGroup.invalid) {
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const formData = new HIRLProjectAddMF();
    const commercialSpaceGeocodes = [];

    if (this.isAccessoryStructure) {
      formData.projects.push(this.accessoryStructureFormData());
    } else {
      const projectFormData = new CreateMFHIRLProject();
      projectFormData.is_accessory_structure = false;
      projectFormData.accessory_structure_description = '';
      projectFormData.green_energy_badges =
        this.addFormGroup.value.green_energy_badges;
      projectFormData.building_number = this.addFormGroup.value.building_number;
      projectFormData.story_count = this.addFormGroup.value.story_count;
      projectFormData.number_of_units = this.addFormGroup.value.number_of_units;
      projectFormData.building_number = this.addFormGroup.value.building_number;
      projectFormData.hud_disaster_case_number =
        this.addFormGroup.value.hud_disaster_case_number;
      projectFormData.is_require_water_sense_certification =
        this.addFormGroup.value.is_require_water_sense_certification;
      projectFormData.appeals_project = this.addFormGroup.value.appeals_project;
      projectFormData.sampling = this.addFormGroup.value.sampling;
      projectFormData.is_townhouse = this.addFormGroup.value.is_townhouse;

      projectFormData.lot_number = this.addFormGroup.value.address.lotNumber;
      projectFormData.home_address_geocode =
        this.addFormGroup.value.address.geocode.id;
      projectFormData.home_address_geocode_response = null;

      if (
        this.customerHIRLSettings.waterSenseProgramList.includes(
          this.hirlProjectRegistration?.eep_program_info?.slug
        )
      ) {
        projectFormData.is_require_wri_certification =
          this.addFormGroup.value.is_require_wri_certification;
      } else {
        projectFormData.is_require_wri_certification = false;
      }

      if (
        this.addFormGroup.value.address.geocode?.raw_city_info?.country_info
          ?.abbr === 'PR' ||
        this.addFormGroup.value.address.geocode?.raw_city_info?.country_info
          ?.abbr === 'VI'
      ) {
        projectFormData.is_require_rough_inspection = false;
      } else if (
        this.customerHIRLSettings.requireRoughInspectionProgramList.includes(
          this.hirlProjectRegistration.eep_program_info?.slug
        )
      ) {
        projectFormData.is_require_rough_inspection =
          this.addFormGroup.value.is_require_rough_inspection;
      } else {
        projectFormData.is_require_rough_inspection = true;
      }

      if (this.addFormGroup.value.address.geocodeBrokeredResponse) {
        projectFormData.home_address_geocode_response =
          this.addFormGroup.value.address.geocodeBrokeredResponse.id;
      }

      if (this.addFormGroup.value.is_include_commercial_space) {
        // to keep order add our data via tap
        commercialSpaceGeocodes.push(
          this.geocoderService
            .matches({
              street_line1:
                this.addFormGroup.value.address.geocode.raw_street_line1,
              street_line2:
                this.addFormGroup.value.commercial_space_street_line_2,
              city: this.addFormGroup.value.address.geocode.raw_city,
              zipcode: this.addFormGroup.value.address.geocode.raw_zipcode,
            } as IGeocodeMatches)
            .pipe(
              first(),
              tap(geocode =>
                projectFormData.commercial_spaces.push(
                  this.commercialSpaceFormData(geocode)
                )
              )
            )
        );
      }
      formData.projects.push(projectFormData);
    }

    forkJoin(commercialSpaceGeocodes)
      .pipe(defaultIfEmpty(null))
      .subscribe(_ => {
        this.hirlProjectRegistrationService
          .hirl_projects_create_multi_family(
            this.hirlProjectRegistration.id,
            formData
          )
          .pipe(first())
          .subscribe(
            registration => {
              this.store.dispatch(toggleLoading({ payload: false }));
              this.router.navigate([
                `/hi/project_registrations/`,
                this.hirlProjectRegistration.id,
              ]);
            },
            error => this.uiHelperService.handleUserRequestError(error)
          );
      });
  }

  public attachFieldCheckHomeWithAddressAlreadyExists(
    formGroup,
    fieldName,
    isMultiFamily
  ) {
    formGroup
      .get(fieldName)
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(confirmedGeocodeResponse => {
        if (!confirmedGeocodeResponse) {
          return;
        }
        this.store.dispatch(toggleLoading({ payload: true }));
        this.hirlProjectService
          .homeAddressIsUnique({
            project: null,
            street_line1: confirmedGeocodeResponse.addressInfo.street_line1,
            street_line2: confirmedGeocodeResponse.addressInfo.street_line2,
            city: confirmedGeocodeResponse.addressInfo.city_info.id,
            zipcode: confirmedGeocodeResponse.addressInfo.zipcode,
            is_multi_family: isMultiFamily,
            geocode_response: confirmedGeocodeResponse.geocodeBrokeredResponse
              ? confirmedGeocodeResponse.geocodeBrokeredResponse.id
              : null,
          })
          .subscribe(isUnique => {
            this.store.dispatch(toggleLoading({ payload: false }));
            if (isUnique) {
              formGroup
                .get(fieldName)
                .setErrors({ home_with_this_address_already_exists: null });
              formGroup.get(fieldName).updateValueAndValidity({
                emitEvent: false,
              });
            } else {
              formGroup
                .get(fieldName)
                .setErrors({ home_with_this_address_already_exists: true });
            }
          });
      });
  }

  isWRIProgramSelected() {
    return this.customerHIRLSettings.wriProgramList.includes(
      this.hirlProjectRegistration?.eep_program_info?.slug
    );
  }
}
