import { StudType } from '@/data/simulation/enumerations';
import {
  LayerBackendDict,
  StudTypeToRValue,
} from '@/data/simulation/models/enclosure/AssemblyLayer';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-assembly-layer-editor',
  templateUrl: './assembly-layer-editor.component.html',
  styleUrls: ['./assembly-layer-editor.component.scss'],
})
export class AssemblyLayerEditorComponent {
  @Output() onChange = new EventEmitter<LayerBackendDict>();
  @Input() entityForm: FormGroup;
  materialsThickness: number;

  errorData = {};
  onStudRequiredChange(studRequired: MatSlideToggleChange) {
    let studName = null;
    if (studRequired.checked) {
      studName = StudType.WOOD_STUD;
      this.entityForm.patchValue({
        stud_type: studName,
        stud_r_value: StudTypeToRValue[studName],
        stud_depth: 5,
        stud_width: 3.5,
        stud_spacing: 16,
      });
    } else {
      this.entityForm.patchValue({
        stud_type: null,
        stud_r_value: null,
        stud_depth: 0,
        stud_width: 0,
        stud_spacing: 0,
      });
    }
  }
}
