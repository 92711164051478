import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  EEPProgramHomeStatusState,
  EEPProgramHomeStatusStateLabelMapping,
  HIRLEEPProgramHomeStatusStateLabelMapping,
} from '@/data/home/models/eep_program_home_status';
import { FormGroup } from '@angular/forms';
import { User } from '@/data/core/models/user';
import {
  EEPProgramHomeStatusProjectReportDownload,
  EEPProgramHomeStatusProjectReportExportField,
  PSRReportList,
} from '@/data/home/models';
import { forkJoin, Subject, Subscription } from 'rxjs';
import {
  EEPProgramHomeStatusRequestParams,
  EEPProgramHomeStatusService,
} from '@/data/home/services/eep_program_home_status.service';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { first } from 'rxjs/operators';
import { getInfo, getInfoUser } from '@/state/selectors/info.selector';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import {
  QARequirementTypeLabelMapping,
  QAStatusStateLabelMapping,
} from '@/data/qa/models';
import { CompanyType } from '@/data/company/models';
import {
  PsrExportFieldsDialogComponent,
  PsrExportFieldsDialogData,
} from '@/modules/home/components/psr-export-fields-dialog/psr-export-fields-dialog.component';
import { toggleLoading } from '@/state/actions/app.actions';
import {
  HIRLProjectAppealsProjectTypeMapping,
  HIRLProjectCommercialSpaceTypeLabelMapping,
  HIRLProjectSamplingMapping,
} from '@/data/customer-hirl/models';
import { AsynchronousProcessedDocumentStatusDialogComponent } from '@/modules/filehandling/components/asynchronous-processed-document-status-dialog/asynchronous-processed-document-status-dialog.component';
import { ImpersonationUser } from '@/data/core/models/impersonation_user';
import { PsrFilterFormComponent } from '@/modules/home/components/psr-filter-form/psr-filter-form.component';
import { NavigationService } from '@/shared/services/navigation-service.service';
import { GenericTableComponent } from '@/shared/components/generic-table/generic-table.component';

@Component({
  selector: 'app-psr-list',
  templateUrl: './psr-list.component.html',
  styleUrls: ['./psr-list.component.scss'],
})
export class PsrListComponent<T extends PSRReportList = PSRReportList>
  implements OnInit, OnDestroy
{
  @ViewChild(GenericTableComponent)
  tableComponent!: GenericTableComponent<T>;
  @ViewChild(PsrFilterFormComponent) filterComponent!: PsrFilterFormComponent;

  public getDataSource = (params: EEPProgramHomeStatusRequestParams) =>
    this.eepProgramHomeStatusService.psr_report_list(params);

  protected readonly QARequirementTypeLabelMapping =
    QARequirementTypeLabelMapping;
  protected readonly HIRLProjectCommercialSpaceTypeLabelMapping =
    HIRLProjectCommercialSpaceTypeLabelMapping;
  protected readonly HIRLProjectSamplingMapping = HIRLProjectSamplingMapping;
  protected readonly HIRLProjectAppealsProjectTypeMapping =
    HIRLProjectAppealsProjectTypeMapping;
  protected readonly CompanyType = CompanyType;

  public tableStateLabelMapping = {
    ...EEPProgramHomeStatusStateLabelMapping,
    ...HIRLEEPProgramHomeStatusStateLabelMapping,
  };

  public QAStatusStateLabelMapping = {
    not_in_progress: 'Not In Progress',
    addable: 'QA Addable',
    does_not_exist: 'Does Not Exist',
    not_complete: 'Not Complete',
    ...QAStatusStateLabelMapping,
  };

  public MAX_EXPORT_RESULTS = 2000;
  public initialized = false;

  public displayedColumns = [
    'home__street_line1',
    'home__subdivision__name',
    'floorplan__name',
    'eep_program__name',
    'state',
    'qa_status',
  ];

  public filterFromGroup: FormGroup;

  public impersonationUser: ImpersonationUser;
  public currentUser: User;

  public defaultParams: EEPProgramHomeStatusRequestParams =
    new EEPProgramHomeStatusRequestParams(1, '', '-created_date', 25);
  public storedParams: EEPProgramHomeStatusRequestParams =
    new EEPProgramHomeStatusRequestParams(1, '', '-created_date', 25);

  public canExportPermission = new ObjectPermissionResponse(
    false,
    'Loading... Please wait'
  );

  public listSubscription$: Subscription;
  public componentDestroyed$ = new Subject();

  constructor(
    public store: Store<AppState>,
    public eepProgramHomeStatusService: EEPProgramHomeStatusService,
    public router: Router,
    public dialog: MatDialog,
    public activatedRoute: ActivatedRoute,
    public uiHelperService: UIHelperService,
    public navigationService: NavigationService
  ) {}

  ngOnInit() {
    forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      impersonationUser: this.store.select(getInfo).pipe(first()),
    }).subscribe(
      ({
        currentUser,
        impersonationUser,
      }: {
        currentUser: User;
        impersonationUser: ImpersonationUser;
      }) => {
        this.currentUser = currentUser;
        this.impersonationUser = impersonationUser;
        this.initialized = true;
      }
    );
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  public setupInitialFilters() {
    if (!this.storedParams.state) {
      if (this.currentUser.company_info?.company_type === CompanyType.rater) {
        this.storedParams.state = ['not_certified_and_not_abandoned'];
      } else if (
        this.currentUser.company_info?.company_type === CompanyType.provider ||
        this.currentUser.company_info?.company_type === CompanyType.eep
      ) {
        this.storedParams.state = [EEPProgramHomeStatusState.complete];
      }
    }
    this.filterComponent.filterFromGroup.patchValue(this.storedParams, {
      emitEvent: true,
      onlySelf: true,
    });
  }

  onFilterFormReady(): void {
    this.setupInitialFilters();
  }

  onFilterFormChanged(form: FormGroup): void {
    this.tableComponent?.clearSelection();
    this.tableComponent?.list();
  }

  downloadReport($event: MouseEvent) {
    $event.preventDefault();

    const dialogData = new PsrExportFieldsDialogData();

    dialogData.export_fields =
      this.uiHelperService.getUISettings().psrExportFields;

    const dialogRef = this.dialog.open(PsrExportFieldsDialogComponent, {
      width: '50%',
      maxWidth: '750px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }

      this.store.dispatch(toggleLoading({ payload: true }));
      const projectReportDownload =
        new EEPProgramHomeStatusProjectReportDownload();
      projectReportDownload.export_fields =
        result as EEPProgramHomeStatusProjectReportExportField[];
      projectReportDownload.export_filters =
        this.filterComponent.getAppliedFilters();

      this.eepProgramHomeStatusService
        .psr_project_report_download(projectReportDownload, this.storedParams)
        .pipe(first())
        .subscribe(
          asynchronousProcessedDocument => {
            this.store.dispatch(toggleLoading({ payload: false }));

            const asynchronousProcessedDocumentDialogRef = this.dialog.open(
              AsynchronousProcessedDocumentStatusDialogComponent,
              {
                width: '50%',
                data: {
                  entity: asynchronousProcessedDocument,
                  retrieve: true,
                },
              }
            );
            asynchronousProcessedDocumentDialogRef.afterClosed().subscribe();
          },
          error => this.uiHelperService.handleUserRequestError(error)
        );
    });
  }

  canExport(): ObjectPermissionResponse {
    if (this.tableComponent?.entitiesIsLoading) {
      return new ObjectPermissionResponse(false, 'Loading... Please wait');
    }
    if (this.currentUser.is_superuser) {
      return new ObjectPermissionResponse(true);
    }
    if (this.impersonationUser.impersonated) {
      return new ObjectPermissionResponse(true);
    }
    if (this.tableComponent?.entitiesCount > this.MAX_EXPORT_RESULTS) {
      return new ObjectPermissionResponse(
        false,
        `Cannot export more than ${this.MAX_EXPORT_RESULTS} at once. Filter results to reduce count`
      );
    }
    return new ObjectPermissionResponse(true);
  }

  onListChanged() {
    this.canExportPermission = this.canExport();
  }
}
