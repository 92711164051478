import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@/shared/shared.module';
import { WidgetsTestComponent } from './pages/widgets-test/widgets-test.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { UserModule } from '@/modules/user/user.module';

@NgModule({
  declarations: [WidgetsTestComponent],
  imports: [CommonModule, SharedModule, CKEditorModule, UserModule],
})
export class InternalModule {}
