import { Component, Input, OnInit } from '@angular/core';
import { Company } from '@/data/company/models';

@Component({
  selector: 'app-hirl-builder-organization-display-prefix',
  templateUrl: './hirl-builder-organization-display-prefix.component.html',
  styleUrls: ['./hirl-builder-organization-display-prefix.component.scss'],
})
export class HirlBuilderOrganizationDisplayPrefixComponent implements OnInit {
  @Input() builderOrganization?: Company;

  ngOnInit(): void {}
}
