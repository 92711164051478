import { QAFlag } from '@/data/simulation/models/SimulationConfig';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export const QAFlags = [
  {
    key: 'os_eri_strict',
    name: 'OpenStudio-ERI (strict)',
    color: '#f3722c',
  },
  {
    key: 'home_energy_score',
    name: 'Home Energy Score',
    color: '#90be6d',
  },
  {
    key: 'resnet',
    name: 'RESNET',
    color: '#277da1',
  },
];

@Component({
  selector: 'app-qa-flags',
  templateUrl: './qa-flags.component.html',
  styleUrls: ['./qa-flags.component.scss'],
})
export class QaFlagsComponent implements OnInit {
  qaFlags = QAFlags;
  activeFlags = {
    os_eri_strict: false,
    home_energy_score: false,
    resnet: false,
  };

  @Output() flagChange = new EventEmitter<QAFlag[]>();
  @Input() set flags(flags: QAFlag[]) {
    flags.forEach(flag => {
      this.activeFlags[flag.name] = true;
    });
  }

  ngOnInit(): void {}

  toggleFlag(flagkey: string) {
    this.activeFlags[flagkey] = !this.activeFlags[flagkey];
    const flags: QAFlag[] = [];
    Object.keys(this.activeFlags)
      .filter(key => this.activeFlags[key])
      .map(key => {
        flags.push({ name: key });
      });
    this.flagChange.emit(flags);
  }
}
