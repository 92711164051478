import { EEPProgramInfo } from '@/data/eep-program/models';
import { CompanyInfo } from '@/data/company/models/company';
import { HomeInfo } from '@/data/home/models/home';
import {
  HIRLProjectRegistrationState,
  HIRLProjectRegistrationType,
} from '@/data/customer-hirl/models';
import { UserInfo } from '@/data/core/models/user';
import { SubdivisionInfo } from '@/data/subdivision/models';

/* eslint-disable @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match */
export enum EEPProgramHomeStatusState {
  pendingInspection = 'pending_inspection',
  inspection = 'inspection',
  qaPending = 'qa_pending',
  certificationPending = 'certification_pending',
  complete = 'complete',
  failed = 'failed',
  abandoned = 'abandoned',
  // customer hirl states
  customerHIRLPendingProjectData = 'customer_hirl_pending_project_data',
  customerHIRLPendingRoughQA = 'customer_hirl_pending_rough_qa',
  customerHIRLPendingFinalQA = 'customer_hirl_pending_final_qa',
}

export const EEPProgramHomeStatusStateLabelMapping = {
  [EEPProgramHomeStatusState.pendingInspection]: 'Pending',
  [EEPProgramHomeStatusState.inspection]: 'Active',
  [EEPProgramHomeStatusState.qaPending]: 'Pending QA',
  [EEPProgramHomeStatusState.certificationPending]: 'Inspected',
  [EEPProgramHomeStatusState.complete]: 'Certified',
  [EEPProgramHomeStatusState.failed]: 'Failed',
  [EEPProgramHomeStatusState.abandoned]: 'Abandoned',
};

export const HIRLEEPProgramHomeStatusStateLabelMapping = {
  [EEPProgramHomeStatusState.customerHIRLPendingProjectData]:
    'Pending Project Data',
  [EEPProgramHomeStatusState.customerHIRLPendingRoughQA]: 'Pending Rough QA',
  [EEPProgramHomeStatusState.customerHIRLPendingFinalQA]: 'Pending Final QA',
  [EEPProgramHomeStatusState.certificationPending]: 'Inspected',
  [EEPProgramHomeStatusState.complete]: 'Certified',
  [EEPProgramHomeStatusState.failed]: 'Failed',
  [EEPProgramHomeStatusState.abandoned]: 'Abandoned',
};

export class EEPProgramHomeStatusHIRLProjectRegistrationInfo {
  id?: number;
  project_type: HIRLProjectRegistrationType;
  eep_program: number;
  eep_program_info: EEPProgramInfo;
  registration_user: number;
  registration_user_info: UserInfo;
  state: HIRLProjectRegistrationState;
  state_change_reason: string;
  state_changed_at?: Date;
}

export class EEPProgramHomeStatusHIRLProjectInfo {
  id: number;
  registration: number;
  registration_info: EEPProgramHomeStatusHIRLProjectRegistrationInfo;
}

export class EEPProgramHomeStatusInfo {
  id: number;
  state: string;
  eep_program: number;
  eep_program_info: EEPProgramInfo;
  company: number;
  company_info: CompanyInfo;
  home: number;
  home_info: HomeInfo;
  customer_hirl_project: number;
  customer_hirl_project_info: EEPProgramHomeStatusHIRLProjectInfo;
}

export class EEPProgramHomeStatus {
  id?: number;
  state: string;
  eep_program: number;
  home: number;
  home_info: HomeInfo;
}

export class CustomerHIRLHomeStatusMetrics {
  label: string;
  certified_buildings: number;
  certified_units: number;
  in_progress_buildings: number;
  in_progress_units: number;
  abandoned_buildings: number;
  abandoned_units: number;
}

export class CustomerHIRLCertificationMetric {
  single_family: CustomerHIRLHomeStatusMetrics;
  multi_family: CustomerHIRLHomeStatusMetrics;
  multi_family_remodel: CustomerHIRLHomeStatusMetrics;
  land_developments: CustomerHIRLHomeStatusMetrics;
  certified_commercial_space: number;
  in_progress_commercial_space: number;
  certified_average_units_count: number;
  in_progress_average_units_count: number;
  certified_hers_score_average_count: number;
  in_progress_hers_score_average_count: number;
}

export class CustomerHIRLTopStatesStats {
  states_for_mf_grouped_by_unit_count: [string, number];
  states_for_sf_grouped_by_count: [string, number];
}

export class CustomerHIRLTopClientStats {
  clients_for_mf_grouped_by_unit_count: [string, number];
  clients_for_sf_grouped_by_count: [string, number];
}

export class CustomerHIRLTopVerifierStats {
  verifiers_by_project_count: {
    projects_count: number;
    verifier_first_name: string;
    verifier_last_name: string;
  }[];
  verifiers_by_unit_count: {
    total_units: number;
    verifier_first_name: string;
    verifier_last_name: string;
  }[];
}

export class CustomerHIRLTopCompanyStats {
  companies_by_project_count: {
    projects_count: number;
    company_name: string;
  }[];
  companies_by_unit_count: {
    total_units: number;
    company_name: string;
  }[];
}

export class CustomerHIRLCertifiedProjectsByMonthMetrics {
  certification_date_month: string;
  home_status_count: number;
}

export class CustomerHIRLCertifiedUnitsByMonthMetrics {
  certification_date_month: string;
  units_count: number;
}

export class CustomerHIRLAggregateDashboard {
  buildings_count: number;
  unit_count: number;
  total_commercial_space: number;
  average_units: number;
  hers_score_is_not_null_count: number;
  average_hers_score: number;
}

export class EEPAndHomeInfo {
  eep_program_info: EEPProgramHomeStatusInfo;
  home_info: HomeInfo;
}

export class CustomerHIRLBulkCertificateEEPProgramHomeStatusList {
  id?: number;
  state: string;
  eep_program: number;
  eep_program_info: EEPProgramInfo;
  company: number;
  company_info: CompanyInfo;
  home: number;
  home_info: HomeInfo;
  customer_hirl_project: string;
  certification_date: string;
  subdivision_info?: SubdivisionInfo;
  certification_link?: string;
}

export class CustomerHIRLBulkCertificateEEPProgramHomeStatusDownload {
  home_statuses?: number[];
  select_all?: boolean;
}

export class CustomerHIRLCertificateLookupList {
  address: string;
  company: number;
  company_info: CompanyInfo;
  client_name: string;
  certification_path: string;
  certification_level: string;
  certification_date: string;
  subdivision_name?: string;
  certification_number: number;
  certification_link?: string;
}

/* eslint-enable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
