<h2 mat-dialog-title>
  Force change state for <b>Client Agreement</b>
</h2>
<mat-dialog-content *ngIf="initialized">
  <form [formGroup]="form" class="mb-2">

    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Client Agreement State</mat-label>
          <mat-select formControlName="state">
            <mat-option *ngFor="let item of ClientAgreementStateLabelMapping | defaultOrderKeyValue" [value]="item.key">
              {{ item.value }}
            </mat-option>
          </mat-select>
          <mat-hint>
            Notifications and documents will not be generated !
          </mat-hint>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button color="accent" mat-raised-button (click)="save($event)">Save</button>
  <button mat-button [mat-dialog-close]="null" color="primary" mat-raised-button>Cancel</button>
</mat-dialog-actions>
