<div class="autocomplete-container">
    <mat-form-field appearance="none" class="autocomplete-field">
        <div class="clear-all-wrapper">
            <button mat-icon-button
                    class="clear-all-btn"
                    [attr.aria-label]="'Clear All '"
                    *ngIf="selectedValues$.length > 1" (click)="clearAll($event)"
                    [matTooltip]="'Clear All'">
                <mat-icon>cancel</mat-icon>
            </button>
        </div>
        <mat-chip-list #chipList aria-label="Multiple selection">
            <mat-chip
                    *ngFor="let option of selectedValues$"
                    (removed)="remove(option)">
                {{ onDisplay(option) }}
                <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip>
            <input
                    [placeholder]="placeholder"
                    #autocompleteInput
                    #autocompleteTrigger="matAutocompleteTrigger"
                    [formControl]="autocompleteControl"
                    [matAutocomplete]="autocomplete"
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
        </mat-chip-list>
        <mat-autocomplete
            #autocomplete="matAutocomplete"
            [displayWith]="onDisplay.bind(this)"
            (optionSelected)="onOptionSelected($event)"
            [panelWidth]="panelWidth">
          <mat-option
            *ngFor="let result of results"
            [value]="result" [class.mat-active]="isOptionSelected(result)">
            <span>{{ onOptionDisplay(result) }}</span>
          </mat-option>
          <mat-option [disabled]="true" *ngIf="results?.length === pageSize">
            Too many results to display. Continue typing to see more...
          </mat-option>
        </mat-autocomplete>
        <mat-spinner matSuffix diameter="20" *ngIf="isLoading"></mat-spinner>
    </mat-form-field>
</div>
