import { enumValidator } from '@/modules/simulation/validators/helper';
import {
  createConditionalValidator,
  ModelFormControl,
  ModelFormGroup,
} from '../ModelForm';
import { Validators } from '@angular/forms';
import { FieldLabels } from '../base';

enum WindowCoolingMethod {
  NO_VENTILATION = 'none',
  NATURAL = 'natural',
  WHOLE_HOUSE_FAN = 'whole_home_fan',
}

export const WindowCoolingMethodLabels: Record<WindowCoolingMethod, string> = {
  [WindowCoolingMethod.NO_VENTILATION]: 'No Ventilation',
  [WindowCoolingMethod.NATURAL]: 'Natural Ventilation',
  [WindowCoolingMethod.WHOLE_HOUSE_FAN]: 'Whole House Fan',
};

export interface NaturalVentilationBackendDict {
  id: number;
  type: WindowCoolingMethod;
  ventilation_rate: number;
  ventilation_rate_unit: string;
  consumption: number;
  consumption_unit: string;
}

export const FIELD_LABELS: FieldLabels<NaturalVentilationBackendDict> = {
  type: 'Type',
  ventilation_rate: 'Ventilation Rate',
  ventilation_rate_unit: 'Ventilation Rate Unit',
  consumption: 'Consumption',
  consumption_unit: 'Consumption Unit',
};

export function createNaturalVentilationForm(
  data: NaturalVentilationBackendDict
): ModelFormGroup {
  const validateOnlyIfVentilation = createConditionalValidator(
    controls => controls.type.value !== WindowCoolingMethod.NO_VENTILATION,
    ['type']
  );

  return new ModelFormGroup({
    id: new ModelFormControl(data.id, Validators.required),
    type: new ModelFormControl(
      data.type,
      enumValidator(WindowCoolingMethod, true)
    ),
    ventilation_rate: new ModelFormControl(
      data.ventilation_rate,
      ...validateOnlyIfVentilation([Validators.min(0)])
    ),
    consumption: new ModelFormControl(
      data.consumption,
      ...validateOnlyIfVentilation([Validators.min(0)])
    ),
  });
}
