import {
  LabelWithDescription,
  LabelWithInfoDialog,
  LabelWithRichDescription,
} from '@/data/simulation/models/base';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
})
export class LabelComponent implements OnInit {
  @Input() data:
    | string
    | LabelWithDescription
    | LabelWithInfoDialog
    | LabelWithRichDescription
    | null = null;
  @Input() presentAstrix = false;
  @Input() info: string | null = null;
  label: string;
  description: string;
  infoDialog: any;
  descriptionHtml: any;

  constructor(public _dialog: MatDialog) {}

  openDialog() {
    if (this.infoDialog) {
      this._dialog.open(this.infoDialog, {
        width: '500px',
      });
    }
  }

  ngOnInit(): void {
    if (!this.data) {
      this.label = null;
      this.description = null;
      return;
    }

    if (typeof this.data === 'string') {
      this.label = this.data;
      this.description = null;
    } else if (isLabelWithDescription(this.data)) {
      this.label = this.data.label;
      this.description = this.data.description;
    } else if (isLabelWithRichDescription(this.data)) {
      this.label = this.data.label;
      this.descriptionHtml = (
        this.data as LabelWithRichDescription
      ).descriptionHtml;
      this.description = null;
    } else if (isLabelWithInfoDialog(this.data)) {
      this.label = this.data.label;
      this.infoDialog = this.data.infoDialog;
    } else {
      throw new Error('Invalid data type');
    }
  }
}

// Type guard for LabelWithDescription
function isLabelWithDescription(value: any): value is LabelWithDescription {
  return (
    value &&
    typeof value === 'object' &&
    'label' in value &&
    'description' in value
  );
}

// Type guard for LabelWithRichDescription
function isLabelWithRichDescription(value: any): value is LabelWithDescription {
  return (
    value &&
    typeof value === 'object' &&
    'label' in value &&
    'descriptionHtml' in value
  );
}

// Type guard for LabelWithInfoDialog
function isLabelWithInfoDialog(value: any): value is LabelWithInfoDialog {
  return (
    value &&
    typeof value === 'object' &&
    'label' in value &&
    'infoDialog' in value
  );
}
