import { Injectable } from '@angular/core';
import io from 'socket.io-client';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { Observable } from 'rxjs';
import { getInfo } from '@/state/selectors/info.selector';
import { environment } from '../../../environments/environment';
import { first } from 'rxjs/operators';
import { ImpersonationUser } from '@/data/core/models/impersonation_user';
import {
  addRecentMessageAction,
  deleteRecentMessageAction,
  resetRecentMessagesAction,
} from '@/state/actions/messaging/message.actions';
import { ModernMessage } from '@/data/messaging/models';
import { getRecentMessages } from '@/state/selectors/messaging/message.selector';
import { forEach } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  private socket;
  public baseUrl = environment.AXIS_SOCKET_URL;
  public impersonationUser$: Observable<ImpersonationUser>;

  constructor(private store: Store<AppState>) {}

  init() {
    this.impersonationUser$ = this.store.select(getInfo);
    this.impersonationUser$.pipe(first()).subscribe(impersonationUser => {
      console.log(`Connecting to ${this.baseUrl}`);
      if (!this.baseUrl) {
        return;
      }
      this.socket = io(this.baseUrl, {
        path: '/services/websocket',
        reconnectionDelay: 5000,
        transports: ['websocket'],
      })
        .on('connect', () => {
          console.log('connect');
        })
        .on('connect:ready', data => {
          console.log('connect:ready', data);
        })
        .on('connect:acknowledge', clientId => {
          console.log('connect:acknowledge');
          console.log(`Session key ${impersonationUser.session_key}`);
          this.socket.emit('connect:ready', impersonationUser.session_key);
        })
        .on('disconnect', reason => {
          console.log('Disconnect.', reason);
        })
        .on('message:push', (data: string) => {
          console.log('ModernMessage:push');
          console.log(data);
          const modernMessage = JSON.parse(data) as ModernMessage;
          if (modernMessage.user === impersonationUser.user.id) {
            this.store.dispatch(
              addRecentMessageAction({ payload: modernMessage })
            );
          }
        })
        .on('message:read', (data: string) => {
          console.log('ModernMessage:read');
          console.log(data);
          const modernMessage = JSON.parse(data) as ModernMessage;
          if (modernMessage.user === impersonationUser.user.id) {
            this.store.dispatch(
              deleteRecentMessageAction({ payload: modernMessage })
            );
          }
        })
        .on('message:read_bulk', (messageIds: string[]) => {
          console.log('ModernMessage:read_bulk');
          console.log(messageIds);
          if (messageIds.length === 0) {
            this.store.dispatch(resetRecentMessagesAction());
          } else {
            this.store
              .select(getRecentMessages)
              .pipe(first())
              .subscribe({
                next: (messages: ModernMessage[]) => {
                  forEach(messages, (message: ModernMessage) => {
                    if (messageIds.includes(message.id?.toString())) {
                      this.store.dispatch(
                        deleteRecentMessageAction({ payload: message })
                      );
                    }
                  });
                },
              });
          }
        })
        .on('error', error => {
          console.log('Error.', error);
        });
    });
  }
}
