<h2 mat-dialog-title class="d-flex justify-content-between align-items-center">
  {{ this.data.title }}
  <button mat-icon-button aria-label="Close dialog" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</h2>
<mat-dialog-content>
  <div class="row">
    <div class="col-12">
      <b>Current Status: </b>
      <span [style]="{color: taskStateColors[entity.state]}">{{ entity.state || "PENDING" }}</span>
    </div>
  </div>

  <ng-container *ngIf="initialized">
    <div class="row" *ngIf="entity.download">
      <div class="col-12">
        <b>Download: </b>
        <a href="{{ entity.document }}" *ngIf="entity.document">
          Document
        </a>
      </div>
    </div>
    <ng-container *ngIf="!isCompleted">
      <div class="row">
        <div class="col-12">
          <hr>
        </div>
      </div>
      <ng-container *ngIf="!processingProgressData && !writingProgressData">
        <div class="row">
          <div class="col-12">
            <b>Processing</b>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-progress-bar mode="query"></mat-progress-bar>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="processingProgressData">
        <div class="row">
          <div class="col-12">
            <b>Processing:</b>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-progress-bar [mode]="processingProgressData.mode" [value]="processingProgressData.value"></mat-progress-bar>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="writingProgressData">
        <div class="row">
          <div class="col-12">
            <b>Writing:</b>
          </div>
        </div>
        <div class="row" *ngIf="writingProgressData">
          <div class="col-12">
            <mat-progress-bar [mode]="writingProgressData.mode" [value]="writingProgressData.value"></mat-progress-bar>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <ng-container>
      <mat-tab-group class="mt-4">
        <mat-tab *ngIf="this.entity?.log?.chronological?.length">
          <ng-template mat-tab-label>
            Logs
            <span class="badge badge-primary ml-1" *ngIf="this.entity?.log?.chronological?.length > 0">
              {{ this.entity?.log?.chronological?.length }}
            </span>
          </ng-template>
          <div class="log-content">
            <ng-container *ngFor="let item of this.entity?.log?.chronological">
              {{ item }}
              <hr>
            </ng-container>
          </div>
        </mat-tab>
        <mat-tab *ngIf="this.entity?.log?.critical?.length">
          <ng-template mat-tab-label>
            Critical
            <span class="badge badge-primary ml-1" *ngIf="this.entity?.log?.critical?.length > 0">
              {{ this.entity?.log?.critical?.length }}
            </span>
          </ng-template>
          <div class="log-content">
            <p *ngFor="let item of this.entity?.log?.critical">{{ item }}</p>
          </div>
        </mat-tab>

        <mat-tab *ngIf="this.entity?.log?.errors?.length">
          <ng-template mat-tab-label>
            Errors
            <span class="badge badge-primary ml-1" *ngIf="this.entity?.log?.errors?.length > 0">
              {{ this.entity?.log?.errors?.length }}
            </span>
          </ng-template>
          <div class="log-content">
            <ng-container *ngFor="let item of this.entity?.log?.errors">
              {{ item }}
              <hr>
            </ng-container>
          </div>
        </mat-tab>

        <mat-tab *ngIf="this.entity?.log?.warnings?.length">
          <ng-template mat-tab-label>
            Warnings
            <span class="badge badge-primary ml-1" *ngIf="this.entity?.log?.warnings?.length > 0">
              {{ this.entity?.log?.warnings?.length }}
            </span>
          </ng-template>
          <div class="log-content">
            <ng-container *ngFor="let item of this.entity?.log?.warnings">
              {{ item }}
              <hr>
            </ng-container>
          </div>
        </mat-tab>

        <mat-tab *ngIf="this.entity?.log?.info?.length">
          <ng-template mat-tab-label>
            Info
            <span class="badge badge-primary ml-1" *ngIf="this.entity?.log?.info?.length > 0">
              {{ this.entity?.log?.info?.length }}
            </span>
          </ng-template>
          <div class="log-content">
            <ng-container *ngFor="let item of this.entity?.log?.info">
              {{ item }}
              <hr>
            </ng-container>
          </div>
        </mat-tab>

        <mat-tab *ngIf="this.entity?.log?.debug?.length">
          <ng-template mat-tab-label>
            Debug
            <span class="badge badge-primary ml-1" *ngIf="this.entity?.log?.debug?.length > 0">
              {{ this.entity?.log?.debug?.length }}
            </span>
          </ng-template>
          <div class="log-content">
            <ng-container *ngFor="let item of this.entity?.log?.debug">
              {{ item }}
              <hr>
            </ng-container>
          </div>
        </mat-tab>
      </mat-tab-group>
    </ng-container>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <a mat-button href="{{ entity.document }}" color="accent" mat-raised-button *ngIf="entity.document && isCompleted">
    Download
  </a>
  <a mat-button [routerLink]="['/', 'file-operation', 'document', entity.id]" target="_blank" color="primary" mat-raised-button cdkFocusInitial [mat-dialog-close]="null">
    Open In New Window <fa-icon [icon]="['fas', 'external-link-alt']"></fa-icon>
  </a>
</mat-dialog-actions>


