import {
  Component,
  DoCheck,
  ElementRef,
  HostBinding,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';

import { User } from '@/data/core/models/user';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NgControl,
} from '@angular/forms';
import { EMPTY, Subject } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { FocusMonitor } from '@angular/cdk/a11y';
import {
  catchError,
  debounceTime,
  filter,
  first,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { MatFormFieldControl } from '@angular/material/form-field';
import { ServerResponse } from '@/core/schemes/server-response';

import { axisModelMap } from '@/shared/constants/select-widget-model-map';

@Component({
  selector: 'app-generic-model-autocomplete',
  templateUrl: './generic-model-autocomplete.component.html',
  styleUrls: ['./generic-model-autocomplete.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: GenericModelAutocompleteComponent,
    },
  ],
})
export class GenericModelAutocompleteComponent
  implements
    ControlValueAccessor,
    MatFormFieldControl<any>,
    OnInit,
    OnDestroy,
    DoCheck
{
  static nextId = 0;
  private placeholder$: string;
  private required$ = false;
  private disabled$ = false;

  @ViewChild('autocompleteInput') autocompleteInput: ElementRef;
  @ViewChild('autocomplete') autocomplete: MatAutocomplete;
  @ViewChild('autocompleteTrigger') autocompleteTrigger: MatAutocompleteTrigger;

  @Input() modelClassName: string;
  @Input() initialValueId: number | string;
  @Input() filterParams: any = {};
  @Input() display: (value: any) => string;
  @Input() optionDisplay: (value: any) => string;
  @Input() optionDisabled: (value: any) => boolean;
  @Input() panelWidth?: string | number;
  @Input() pageSize = 100;

  @HostBinding('[id]')
  public id = `app-generic-model-autocomplete-${GenericModelAutocompleteComponent.nextId++}`;

  // component logic
  protected readonly modelsMap = axisModelMap;
  private modelService$: any;

  public autocompleteControl = new UntypedFormControl();
  public isLoading = false;
  public results: User[];

  private componentDestroyed$ = new Subject();

  // angular mat input
  public focused = false;
  public touched = false;
  public stateChanges = new Subject<void>();
  public describedBy = '';
  public onChange = (_: any) => {};
  public onTouched = () => {};

  get empty() {
    return !this.autocompleteControl.value;
  }

  @HostBinding('[class.app-generic-model-autocomplete-floating]')
  get shouldLabelFloat() {
    return true;
  }

  get errorState() {
    return this.ngControl.errors !== null && this.touched;
  }

  @Input()
  get placeholder(): string {
    return this.placeholder$;
  }
  set placeholder(value: string) {
    this.placeholder$ = value;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this.required$;
  }
  set required(value: boolean) {
    this.required$ = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this.disabled$;
  }
  set disabled(value: boolean) {
    this.disabled$ = coerceBooleanProperty(value);
    this.disabled$
      ? this.autocompleteControl.disable()
      : this.autocompleteControl.enable();
    this.stateChanges.next();
  }

  @Input()
  get value(): any {
    return this.autocompleteControl.value;
  }
  set value(value: any) {
    this.autocompleteControl.setValue(value);
    this.isLoading = false;
    this.onChange(value);
    this.stateChanges.next();
  }

  constructor(
    private focusMonitorService: FocusMonitor,
    private injector: Injector,
    private elementRef$: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl
  ) {
    focusMonitorService.monitor(elementRef$, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.modelService$ = this.injector.get(
      this.modelsMap[this.modelClassName].serviceCls
    );

    if (this.initialValueId) {
      this.modelService$
        .retrieve(this.initialValueId)
        .pipe(takeUntil(this.componentDestroyed$), first())
        .subscribe(data => {
          this.writeValue(data);
        });
    }

    // autocomplete
    this.autocompleteControl.valueChanges
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(value => typeof value === 'string' || value === null),
        startWith(''),
        debounceTime(100),
        tap(_ => (this.isLoading = true)),
        switchMap(value => {
          const params = Object.assign(this.filterParams, {
            search: value,
            page_size: this.pageSize,
          });
          return this.modelService$[this.modelsMap[this.modelClassName].method](
            params
          ).pipe(catchError(err => EMPTY));
        }),
        tap(
          response => (this.results = (response as ServerResponse<any>).results)
        ),
        tap(_ => (this.isLoading = false))
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  ngDoCheck(): void {
    if (this.touched !== this.ngControl.control.touched) {
      this.touched = this.ngControl.touched;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  // angular mat input methods
  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (
      !this.elementRef$.nativeElement.contains(event.relatedTarget as Element)
    ) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick($event: MouseEvent) {
    $event.stopPropagation();
    if (this.disabled) {
      return;
    }
    this.onTouched();
    if (($event.target as Element).tagName.toLowerCase() === 'mat-icon') {
      this.clear();
    } else {
      this.handleInput();
    }
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private clear(): void {
    this.value = null;
  }

  private handleInput() {
    this.autocompleteInput.nativeElement.focus();
    this.autocompleteTrigger.openPanel();
  }

  // component methods
  onDisplay(value) {
    if (value) {
      if (this.display) {
        return this.display.bind(this)(value);
      }
      return this.modelsMap[this.modelClassName].display.bind(this)(value);
    }
    return null;
  }

  onClear() {
    this.value = null;
    this.focused = false;
    this.onTouched();
    this.stateChanges.next();
    this.results = [];
    this.handleInput();
  }

  onOptionSelected($event: MatAutocompleteSelectedEvent) {
    this.value = $event.option.value;
  }

  onOptionDisplay(value: any) {
    if (this.optionDisplay) {
      return this.optionDisplay.bind(this)(value);
    }
    return this.modelsMap[this.modelClassName].optionDisplay.bind(this)(value);
  }

  onOptionDisabled(value: any) {
    if (this.optionDisabled) {
      return this.optionDisabled.bind(this)(value);
    }
    return this.modelsMap[this.modelClassName].optionDisabled.bind(this)(value);
  }
}
