import {
  MechanicalEquipmentBackendDict,
  createMechanicalEquipmentForm,
} from '@/data/simulation/models/mechanicals/MechanicalEquipment';
import { BaseValidator } from './base.validator';
import { ModelErrors, StateErrors } from '../state/shared/base.state';

export class MechanicalEquipmentValidator extends BaseValidator {
  static validateForm(
    mechanicalequipment: MechanicalEquipmentBackendDict
  ): ModelErrors<MechanicalEquipmentBackendDict> {
    const mechanicalequipmentForm =
      createMechanicalEquipmentForm(mechanicalequipment);
    return mechanicalequipmentForm.getFieldAndModelErros();
  }

  static validate(
    mechanicalEquipmentList: MechanicalEquipmentBackendDict[]
  ): StateErrors<MechanicalEquipmentBackendDict> {
    const errors = {};
    mechanicalEquipmentList.forEach(mechanicalEquipment => {
      errors[mechanicalEquipment.id] = this.validateForm(mechanicalEquipment);
    });
    return errors;
  }
}
