<div *ngIf="errorCount > 0 || warningCount > 0" class="error-messages-contaainer">
    <div class="d-flex">
        <small class="badge badge-primary rounded-circle" *ngIf="errorCount > 1">{{errorCount}}</small>
        <small class="badge badge-warning rounded-circle" *ngIf="warningCount > 1">{{warningCount}}</small>
    </div>
    <ng-container [ngSwitch]="errorCount">
        <ng-container *ngSwitchCase="0">
            <small class="warnings">{{warningMessages[0]}}</small>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <small class="errors">{{errorMessages[0]}}</small>
        </ng-container>
    </ng-container>
</div>
