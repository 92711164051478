import { Component, Input, OnInit } from '@angular/core';
import { Community } from '@/data/community/models';
import { CommunityFlatList } from '@/data/community/models/community';

export enum CommunityDisplayComponentType {
  onlyName = 'onlyName',
  nameAndAddress = 'nameAndAddress',
  onlyNameAsLink = 'onlyNameAsLink',
  nameAndType = 'nameAndType',
  nameAndTypeAsLink = 'nameAndTypeAsLink',
}

@Component({
  selector: 'app-community-display',
  templateUrl: './community-display.component.html',
  styleUrls: ['./community-display.component.scss'],
})
export class CommunityDisplayComponent implements OnInit {
  @Input() displayType:
    | CommunityDisplayComponentType
    | keyof typeof CommunityDisplayComponentType =
    CommunityDisplayComponentType.onlyName;
  @Input() community?: Community | CommunityFlatList;

  public communityDisplayComponentType = CommunityDisplayComponentType;

  ngOnInit(): void {}
}
