<mat-expansion-panel>
    <mat-expansion-panel-header>
        <mat-panel-title>
            <strong>Component Loads</strong>
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
        <div class="table-container">
            <mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef> Type </th>
                    <td mat-cell *matCellDef="let element"> <strong>{{element.type}}</strong></td>
                </ng-container>

                <!-- Dynamically create columns for each component -->
                <ng-container *ngFor="let column of displayedColumns.slice(1)" [matColumnDef]="column">
                    <th mat-header-cell *matHeaderCellDef> {{componentNames[column]}} </th>
                    <td mat-cell *matCellDef="let element" class="data">
                        <ng-container *ngIf="element[column]">{{element[column] | number: '1.2-2'}}</ng-container>
                        <ng-container *ngIf="!element[column]"><i>--</i></ng-container>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </mat-table>
        </div>
    </ng-template>
</mat-expansion-panel>
