import { Component, OnDestroy, OnInit } from '@angular/core';
import { transition, trigger, useAnimation } from '@angular/animations';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { SelectionType } from '@swimlane/ngx-datatable';
import { CustomerHIRLInvoiceManagementList } from '@/data/invoicing/models';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, takeUntil } from 'rxjs/operators';
import {
  fadeInOutEnterAnimation,
  fadeInOutLeaveAnimation,
} from '@/core/animations/claims.animations';
import { AppState } from '@/state/reducers';
import { User } from '@/data/core/models/user';
import {
  CustomerHIRLInvoiceItemGroupRequestParams,
  InvoiceItemGroupService,
} from '@/data/invoicing/services/invoice-item-group.service';
import { InvoiceService } from '@/data/invoicing/services/invoice.service';
import { toggleLoading } from '@/state/actions/app.actions';
import {
  ClientAgreementStateLabelMapping,
  HIRLProjectRegistrationTypeLabelMapping,
} from '@/data/customer-hirl/models';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { HIRLEEPProgramHomeStatusStateLabelMapping } from '@/data/home/models/eep_program_home_status';
import {
  IExcelJson,
  UIHelperService,
} from '@/shared/services/ui-helper.service';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { InvoiceItemGroupCategoryLabelMapping } from '@/data/invoicing/models/invoice-item-group';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CompanyType } from '../../../../data/company/models';

@Component({
  selector: 'app-customer-hirl-projects-list',
  templateUrl: './hirl-invoice-item-groups-list.component.html',
  styleUrls: ['./hirl-invoice-item-groups-list.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [useAnimation(fadeInOutEnterAnimation)]),
      transition(':leave', [useAnimation(fadeInOutLeaveAnimation)]),
    ]),
  ],
})
export class HIRLInvoiceItemGroupsListComponent implements OnInit, OnDestroy {
  public filterFromGroup: UntypedFormGroup;

  protected readonly CompanyType = CompanyType;
  protected readonly SelectionType = SelectionType;

  public selectedInvoiceItemGroups: CustomerHIRLInvoiceManagementList[] = [];
  public currentUser$: Observable<User>;

  public invoiceItemGroups: CustomerHIRLInvoiceManagementList[] = [];
  readonly headerHeight = 40;
  public displayedColumns = [];

  public invoiceItemGroupsCount = 0;
  public invoiceItemGroupsIsLoading = true;

  public currentBalanceFilterLabelMapping = {
    All: { current_balance_range_min: '', current_balance_range_max: '' },
    'Hide Paid Fees': {
      current_balance_range_min: '1',
      current_balance_range_max: '',
    },
    'Show Only Paid Fees': {
      current_balance_range_min: '',
      current_balance_range_max: '1',
    },
  };
  public projectHaveLegacyNotifiedCertificationLabelMapping = {
    '': 'All',
    notified: 'Legacy Invoice Issued',
    not_notified: 'No Legacy Invoice',
  };

  public HIRLProjectRegistrationTypeLabelMapping =
    HIRLProjectRegistrationTypeLabelMapping;
  public eepProgramHomeStatusStateLabelMapping =
    HIRLEEPProgramHomeStatusStateLabelMapping;
  public clientAgreementStateLabelMapping = ClientAgreementStateLabelMapping;
  public invoiceItemGroupCategoryLabelMapping =
    InvoiceItemGroupCategoryLabelMapping;

  public defaultParams: CustomerHIRLInvoiceItemGroupRequestParams =
    new CustomerHIRLInvoiceItemGroupRequestParams(1, '', '-id', 25);
  public storedParams: CustomerHIRLInvoiceItemGroupRequestParams;

  private listSubscription$: Subscription;
  private componentDestroyed$ = new Subject();

  constructor(
    public store: Store<AppState>,
    public invoiceItemGroupService: InvoiceItemGroupService,
    public invoiceService: InvoiceService,
    public router: Router,
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private uiHelperService: UIHelperService
  ) {
    this.currentUser$ = this.store.select(getInfoUser);
  }

  ngOnInit() {
    // set Hide Paid Fees by default
    this.defaultParams.current_balance_range_min = '1';
    this.defaultParams.current_balance_range_max = '';

    this.storedParams = Object.assign(
      new CustomerHIRLInvoiceItemGroupRequestParams(),
      this.defaultParams
    );

    this.setupFilterForm();

    forkJoin({
      queryParams: this.activatedRoute.queryParams.pipe(first()),
      currentUser: this.store.select(getInfoUser).pipe(first()),
    }).subscribe(({ queryParams, currentUser }) => {
      this.storedParams.assignQueryParams(queryParams);
      this.displayedColumns = [
        'select',
        'project_id',
        'address',
        'total',
        'client',
        'program',
        'created',
        'ca_status',
        'project_status',
      ];
      this.hydrateForm();
      this.list();
    });
  }

  setupFilterForm() {
    this.filterFromGroup = this.fb.group({
      search: [null],
      home_status__customer_hirl_project__registration__project_type: [''],
      category: [''],
      home_status__state: [''],
      customer_hirl_project_have_legacy_notified_certification: [''],
      customer_hirl_erfp_company_info: [null],
      customer_hirl_client_company_info: [null],
      current_balance_filter: ['Hide Paid Fees'],
      customer_hirl_erfp_ca_status: [''],
      created_at__gte: [null],
      created_at__lte: [null],
    });

    this.filterFromGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        const params = Object.assign(this.defaultParams, values);
        delete params.ordering;

        if (params.customer_hirl_erfp_company_info) {
          params.customer_hirl_erfp_company =
            params.customer_hirl_erfp_company_info.id;
        } else {
          params.customer_hirl_erfp_company = null;
        }
        delete params.customer_hirl_erfp_company_info;

        if (params.customer_hirl_client_company_info) {
          params.customer_hirl_client_company =
            params.customer_hirl_client_company_info.id;
        } else {
          params.customer_hirl_client_company = null;
        }
        delete params.customer_hirl_client_company_info;

        // balance filter
        const balanceFilter =
          this.currentBalanceFilterLabelMapping[
            params.current_balance_filter || 'All'
          ];

        params.current_balance_range_min =
          balanceFilter.current_balance_range_min;
        params.current_balance_range_max =
          balanceFilter.current_balance_range_max;

        delete params.current_balance_filter;
        this.storedParams = Object.assign(this.storedParams, params);
        this.list();
      });
  }

  hydrateForm() {
    this.filterFromGroup.patchValue(this.storedParams, {
      emitEvent: false,
      onlySelf: true,
    });
    const filtered = Object.entries(this.currentBalanceFilterLabelMapping)
      .filter(([k, v]) => {
        return (
          this.storedParams.current_balance_range_min ===
            v.current_balance_range_min &&
          this.storedParams.current_balance_range_max ===
            v.current_balance_range_max
        );
      })
      .map(([k, v]) => k);

    if (filtered.length > 0) {
      this.filterFromGroup.patchValue(
        { current_balance_filter: filtered[0] },
        { emitEvent: false, onlySelf: true }
      );
    }
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  list() {
    this.invoiceItemGroupsIsLoading = true;
    this.selectedInvoiceItemGroups = [];

    // work only with uninvoiced Item Groups
    this.storedParams.uninvoiced = true;

    // populate params to query string
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.storedParams.toQueryParams(),
      replaceUrl: true,
    });

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }

    this.listSubscription$ = this.invoiceItemGroupService
      .customer_hirl_invoice_management_list(this.storedParams)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(data => {
        this.invoiceItemGroups = data.results;
        this.invoiceItemGroupsCount = data.count;
        this.invoiceItemGroupsIsLoading = false;
      });
  }

  onPaginateChange($event: PageEvent) {
    this.uiHelperService.getUISettings().rowsPerPage = $event.pageSize;
    this.storedParams.page_size = $event.pageSize;
    this.storedParams.page = $event.pageIndex + 1;
    this.list();
  }

  onSortChange($event: Sort) {
    this.storedParams.ordering = `${$event.direction === 'desc' ? '-' : ''}${
      $event.active
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  onFooterPage(event) {
    this.storedParams.page = event.page;
    this.list();
  }

  exportToExcel($event: MouseEvent) {
    $event.preventDefault();
    const excelData: Array<IExcelJson> = [];
    const udt: IExcelJson = {
      data: [
        {
          A: 'Project Id',
          B: 'Address',
          C: 'Total',
          D: 'Client',
          E: 'Program',
          F: 'Created',
          G: 'CA Status',
          H: 'Project Status',
        }, // table header
      ],
      skipHeader: true,
    };
    this.invoiceItemGroups.forEach(invoiceItem => {
      // const formattedDate = formatDate(invoiceItem.created_at, 'MM/dd/yyyy', 'en-US');
      udt.data.push({
        A: invoiceItem.hirl_project_id,
        B: invoiceItem.hirl_project_address,
        C: invoiceItem.total,
        D: invoiceItem?.client.name,
        E: invoiceItem.eep_program_name,
        F: invoiceItem.created_at,
        G: invoiceItem.client_ca_status,
        H: invoiceItem.home_status_state,
      });
    });
    excelData.push(udt);

    this.uiHelperService.exportJsonToExcel(excelData, 'invoiceItemGroups');
  }

  exportToCSV($event: MouseEvent) {
    $event.preventDefault();
    const data = [];
    this.invoiceItemGroups.forEach(invoiceItem => {
      // const formattedDate = formatDate(invoiceItem.created_at, 'MM/dd/yyyy', 'en-US');
      data.push({
        'Project Id': invoiceItem.hirl_project_id,
        Address: invoiceItem.hirl_project_address,
        Total: invoiceItem.total,
        Client: invoiceItem?.client.name,
        Program: invoiceItem.eep_program_name,
        Created: invoiceItem.created_at,
        'CA Status': invoiceItem.client_ca_status,
        'Project Status': invoiceItem.home_status_state,
      });
    });
    this.uiHelperService.exportToCsv(data, 'invoiceItemGroups', [
      'Project Id',
      'Address',
      'Total',
      'Client',
      'Program',
      'Created',
      'CA Status',
      'Project Status',
    ]);
  }

  onSelectAll(event: MatCheckboxChange): void {
    if (event.checked) {
      this.selectedInvoiceItemGroups = [...this.invoiceItemGroups];
    } else {
      this.selectedInvoiceItemGroups = [];
    }
  }

  onSelect(
    event: MatCheckboxChange,
    row: CustomerHIRLInvoiceManagementList
  ): void {
    if (event.checked) {
      // Add row to selectedInvoiceItemGroups array
      this.selectedInvoiceItemGroups = [...this.selectedInvoiceItemGroups, row];
      this.selectedInvoiceItemGroups = this.selectedInvoiceItemGroups.slice();
    } else {
      // Remove row from selectedInvoiceItemGroups array
      const index = this.selectedInvoiceItemGroups.indexOf(row);
      if (index > -1) {
        this.selectedInvoiceItemGroups.splice(index, 1);
        this.selectedInvoiceItemGroups = this.selectedInvoiceItemGroups.slice();
      }
    }
  }

  createInvoice(invoiceItemGroups: CustomerHIRLInvoiceManagementList[]) {
    const ids = invoiceItemGroups.map(item => item.id);

    this.store.dispatch(toggleLoading({ payload: true }));
    this.invoiceService.hirl_create_invoice(ids).subscribe(
      invoice => {
        this.selectedInvoiceItemGroups = [];
        const newTab = window.open(`/app/hi/invoices/${invoice.id}`, '_blank');
        window.location.reload();
        newTab.focus();
      },
      error =>
        this.uiHelperService.handleUserRequestError(
          error,
          'Error - Invoice Cannot Be Generated'
        )
    );
  }

  resetFilters($event: MouseEvent) {
    $event.preventDefault();
    this.filterFromGroup.reset();
  }

  returnZero() {
    // prevent angular keyvalue sorting
    // https://stackoverflow.com/questions/54091011/disable-the-default-keyvalue-pipe-sort-in-angular
    return 0;
  }
}
