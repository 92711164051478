import { AppState } from '@/state/reducers';
import { createSelector } from '@ngrx/store';
import { invoiceStateKey } from '@/state/reducers/invoicing/invoice.reducer';

export const selectInvoiceState = (state: AppState) => state[invoiceStateKey];

export const getInvoice = createSelector(
  selectInvoiceState,
  store => store.detail
);

// InvoiceItemGroups
export const getInvoiceInvoiceItemGroups = createSelector(
  selectInvoiceState,
  store => store.invoiceItemGroups
);

export const getInvoiceInvoiceItemGroupsCount = createSelector(
  selectInvoiceState,
  store => store.invoiceItemGroupsCount
);

// Invoice Notes
export const getInvoiceNotes = createSelector(
  selectInvoiceState,
  store => store.notes
);

export const getInvoiceNotesCount = createSelector(
  selectInvoiceState,
  store => store.notesCount
);
