import { Action, createReducer, on } from '@ngrx/store';
import {
  deleteProductDocument,
  deleteProductNote,
  deleteProductPracticeItemAction,
  deleteProductProductImageAction,
  loadProduct,
  loadProductDocuments,
  loadProductDocumentsCount,
  loadProductNotes,
  loadProductNotesCount,
  loadProductPaymentRequestsAction,
  loadProductPaymentRequestsCountAction,
  loadProductProductImagesAction,
  loadProductProductImagesCountAction,
  loadProductProductPracticeItemsAction,
  loadProductProductPracticeItemsCountAction,
  resetProduct,
  updateProductDocument,
  updateProductNote,
  updateProductProductImageAction,
  updateProductProductPracticeItemAction,
} from '@/state/actions/product/product.actions';
import { Product, ProductImage } from '@/data/product/models';
import {
  PaymentRequestProduct,
  ProductPracticeItem,
} from '@/data/customer-hirl/models';
import { Annotation } from '@/data/annotation/models';
import { CustomerDocument } from '@/data/filehandling/models';
import {
  deleteEntityArray,
  loadEntityArray,
  loadEntityCount,
  updateEntityArray,
} from '@/state/reducers/utils';

export const productStateKey = 'product';

export interface ProductState {
  detail: Product;
  productImages: ProductImage[];
  productImagesCount: number;
  productPracticeItems: ProductPracticeItem[];
  productPracticeItemsCount: number;
  notes: Annotation[];
  notesCount: number;
  documents: CustomerDocument[];
  documentsCount: number;
  paymentRequestsProducts: PaymentRequestProduct[];
  paymentRequestsProductsCount: number;
}

export const productInitialState: ProductState = {
  detail: null,
  productImages: [],
  productImagesCount: 0,
  productPracticeItems: [],
  productPracticeItemsCount: 0,
  notes: [],
  notesCount: 0,
  documents: [],
  documentsCount: 0,
  paymentRequestsProducts: [],
  paymentRequestsProductsCount: 0,
};

const productReducer = createReducer(
  productInitialState,

  // Reset Product
  on(resetProduct, () => ({ ...productInitialState })),

  // Load Product Details
  on(loadProduct, (state, { payload }) => ({ ...state, detail: payload })),

  // Product Images
  on(loadProductProductImagesAction, (state, { payload }) =>
    loadEntityArray(state, 'productImages', payload)
  ),
  on(loadProductProductImagesCountAction, (state, { payload }) =>
    loadEntityCount(state, 'productImagesCount', payload)
  ),
  on(updateProductProductImageAction, (state, { payload }) =>
    updateEntityArray(state, 'productImages', payload)
  ),
  on(deleteProductProductImageAction, (state, { payload }) =>
    deleteEntityArray(state, 'productImages', payload, 'productImagesCount')
  ),

  // Payment Requests
  on(loadProductPaymentRequestsAction, (state, { payload }) =>
    loadEntityArray(state, 'paymentRequestsProducts', payload)
  ),
  on(loadProductPaymentRequestsCountAction, (state, { payload }) =>
    loadEntityCount(state, 'paymentRequestsProductsCount', payload)
  ),

  // Practice Items
  on(loadProductProductPracticeItemsAction, (state, { payload }) =>
    loadEntityArray(state, 'productPracticeItems', payload)
  ),
  on(loadProductProductPracticeItemsCountAction, (state, { payload }) =>
    loadEntityCount(state, 'productPracticeItemsCount', payload)
  ),
  on(updateProductProductPracticeItemAction, (state, { payload }) =>
    updateEntityArray(state, 'productPracticeItems', payload)
  ),
  on(deleteProductPracticeItemAction, (state, { payload }) =>
    deleteEntityArray(
      state,
      'productPracticeItems',
      payload,
      'productPracticeItemsCount'
    )
  ),

  // Documents
  on(loadProductDocuments, (state, { payload }) =>
    loadEntityArray(state, 'documents', payload)
  ),
  on(loadProductDocumentsCount, (state, { payload }) =>
    loadEntityCount(state, 'documentsCount', payload)
  ),
  on(updateProductDocument, (state, { payload }) =>
    updateEntityArray(state, 'documents', payload)
  ),
  on(deleteProductDocument, (state, { payload }) =>
    deleteEntityArray(state, 'documents', payload, 'documentsCount')
  ),

  // Notes
  on(loadProductNotes, (state, { payload }) =>
    loadEntityArray(state, 'notes', payload)
  ),
  on(loadProductNotesCount, (state, { payload }) =>
    loadEntityCount(state, 'notesCount', payload)
  ),
  on(updateProductNote, (state, { payload }) =>
    updateEntityArray(state, 'notes', payload)
  ),
  on(deleteProductNote, (state, { payload }) =>
    deleteEntityArray(state, 'notes', payload, 'notesCount')
  )
);

export function reducer(state: ProductState | undefined, action: Action) {
  return productReducer(state, action);
}
