import { Component, OnDestroy, OnInit } from '@angular/core';
import { toggleLoading } from '@/state/actions/app.actions';
import { HIRLProjectReasonDialogComponent } from '@/modules/customer-hirl/components/hirl-project-reason-dialog/hirl-project-reason-dialog.component';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { HIRLProjectService } from '@/data/customer-hirl/services/hirl-projects.service';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin, Observable, Subject } from 'rxjs';
import { getInfoUser } from '@/state/selectors/info.selector';
import { filter, first, map, mergeMap, takeUntil } from 'rxjs/operators';
import { HIRLProjectRegistrationService } from '@/data/customer-hirl/services/hirl-project-registration.service';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';
import {
  HIRLProjectRegistration,
  HIRLProjectRegistrationState,
  HIRLProjectRegistrationType,
  HIRLProjectRegistrationTypeLabelMapping,
  HIRLResponsibleNameTypeLabelMapping,
} from '@/data/customer-hirl/models';
import { User } from '@/data/core/models/user';
import { HIRLProjectRegistrationPermissionService } from '@/modules/customer-hirl/services/hirl-project-registration-permission-service';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import {
  getHIRLProjectRegistration,
  getHIRLProjectRegistrationProjectsCount,
} from '@/state/selectors/customer-hirl/hirl-project-registration.selector';
import {
  ConfirmDialogComponent,
  IConfirmDialogData,
} from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { HirlRegistrationAbandonConfirmDialogComponent } from '@/modules/customer-hirl/components/hirl-registration-abandon-confirm-dialog/hirl-registration-abandon-confirm-dialog.component';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { NavigationService } from '@/shared/services/navigation-service.service';
import {
  QaStatusRequestParams,
  QaStatusService,
} from '@/data/qa/services/qa-status.service';
import { EEPProgramRequestParams } from '@/data/eep-program/services/eep-program.service';
import { EEPProgramHomeStatusState } from '@/data/home/models/eep_program_home_status';
import { EEPProgramHomeStatusService } from '@/data/home/services/eep_program_home_status.service';

@Component({
  selector: 'app-hirl-project-registration-detail',
  templateUrl: './hirl-project-registration-detail.component.html',
  styleUrls: ['./hirl-project-registration-detail.component.scss'],
})
export class HIRLProjectRegistrationDetailComponent
  implements OnInit, OnDestroy
{
  public initialized = false;
  public currentUser: User;
  public hirlProjectRegistration: HIRLProjectRegistration;
  public projectsCount = -1;

  public HIRLProjectProjectRegistrationTypeLabelMapping =
    HIRLProjectRegistrationTypeLabelMapping;
  public HIRLProjectRegistrationType = HIRLProjectRegistrationType;
  public HIRLProjectRegistrationState = HIRLProjectRegistrationState;
  public responsibleNameTypeLabelMapping = HIRLResponsibleNameTypeLabelMapping;
  public customerHIRLSettings = CustomerHIRLSettings;
  public deletePermissionResponse: Observable<ObjectPermissionResponse>;
  public addProjectPermissionResponse: Observable<ObjectPermissionResponse>;
  public editPermissionResponse: Observable<ObjectPermissionResponse>;
  public abandonPermissionResponse: Observable<ObjectPermissionResponse>;
  public appealsProjectsCountForClient = 0;

  private componentDestroyed$ = new Subject();

  public get isAppealsProjectsCountIsMoreThanThreshold(): boolean {
    return (
      this.appealsProjectsCountForClient >=
      CustomerHIRLSettings.appealsRegistrationWarningLimit
    );
  }

  constructor(
    public store: Store<AppState>,
    public router: Router,
    public hirlProjectService: HIRLProjectService,
    public hirlProjectRegistrationService: HIRLProjectRegistrationService,
    public activatedRoute: ActivatedRoute,
    public hirlProjectRegistrationPermissionService: HIRLProjectRegistrationPermissionService,
    public dialog: MatDialog,
    public qaStatusService: QaStatusService,
    public eepProgramHomeStatusService: EEPProgramHomeStatusService,
    private navigation: NavigationService,
    public uiHelperService: UIHelperService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(toggleLoading({ payload: true }));

    forkJoin({
      hirlProjectRegistration: this.hirlProjectRegistrationService.retrieve(
        this.activatedRoute.snapshot.params.projectRegistrationId
      ),
      currentUser: this.store.select(getInfoUser).pipe(first()),
    }).subscribe(
      ({ hirlProjectRegistration, currentUser }) => {
        this.currentUser = currentUser;
      },
      error => this.uiHelperService.handleMainRequestError(error)
    );

    this.store
      .select(getHIRLProjectRegistrationProjectsCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(count => {
        this.projectsCount = count;
      });

    this.store
      .select(getHIRLProjectRegistration)
      .pipe(takeUntil(this.componentDestroyed$))
      .pipe(
        filter(val => val !== null),
        mergeMap(registration => {
          return forkJoin({
            qaCount: this.qaStatusService
              .list(
                Object.assign(new QaStatusRequestParams(), {
                  home_status__customer_hirl_project__registration:
                    registration.id,
                })
              )
              .pipe(
                first(),
                map(response => response.count)
              ),
            certifiedProjectsCount: this.eepProgramHomeStatusService
              .list(
                Object.assign(new EEPProgramRequestParams(), {
                  customer_hirl_project__registration: registration.id,
                  state: EEPProgramHomeStatusState.complete,
                })
              )
              .pipe(
                first(),
                map(response => response.count)
              ),
            appealsProjectsCountForClient: this.hirlProjectRegistrationService
              .appeals_registrations_for_client(registration.id)
              .pipe(
                first(),
                map(response => response.count)
              ),
          }).pipe(
            map(
              ({
                qaCount,
                certifiedProjectsCount,
                appealsProjectsCountForClient,
              }) => {
                return {
                  registration,
                  qaCount,
                  certifiedProjectsCount,
                  appealsProjectsCountForClient,
                };
              }
            )
          );
        })
      )
      .subscribe(
        ({
          registration,
          qaCount,
          certifiedProjectsCount,
          appealsProjectsCountForClient,
        }) => {
          this.hirlProjectRegistration = registration;

          this.addProjectPermissionResponse =
            this.hirlProjectRegistrationPermissionService.canAddProject(
              registration
            );
          this.deletePermissionResponse =
            this.hirlProjectRegistrationPermissionService.canDelete(
              registration
            );
          this.editPermissionResponse =
            this.hirlProjectRegistrationPermissionService.canEdit(
              registration,
              qaCount,
              certifiedProjectsCount
            );
          this.abandonPermissionResponse =
            this.hirlProjectRegistrationPermissionService.canAbandon(
              registration,
              certifiedProjectsCount
            );
          this.appealsProjectsCountForClient = appealsProjectsCountForClient;

          this.initialized = true;
          this.store.dispatch(toggleLoading({ payload: false }));
        }
      );
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  approve() {
    this.store.dispatch(toggleLoading({ payload: true }));
    this.hirlProjectRegistrationService
      .approve(this.hirlProjectRegistration.id)
      .pipe(first())
      .subscribe(hirlProjectRegistration => {
        this.hirlProjectRegistration = hirlProjectRegistration;
        this.store.dispatch(toggleLoading({ payload: false }));
      });
  }

  reject() {
    const dialogRef = this.dialog.open(HIRLProjectReasonDialogComponent, {
      width: '250px',
      data: {
        reason: '',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.store.dispatch(toggleLoading({ payload: true }));
        this.hirlProjectRegistrationService
          .reject(this.hirlProjectRegistration.id, result)
          .pipe(first())
          .subscribe(hirlProjectRegistration => {
            this.hirlProjectRegistration = hirlProjectRegistration;
            this.store.dispatch(toggleLoading({ payload: false }));
          });
      }
    });
  }

  onDeleteRegistration() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '350px',
      data: {
        title: 'Are you sure?',
        content:
          'Are you sure you want to delete this <b>Registration</b>? This also delete ALL Projects and related information',
      } as IConfirmDialogData,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(toggleLoading({ payload: true }));
        this.hirlProjectRegistrationService
          .delete(this.hirlProjectRegistration)
          .pipe(first())
          .subscribe(_ => {
            this.store.dispatch(toggleLoading({ payload: false }));
            this.router.navigate(['hi', 'project_registrations']);
          });
      }
    });
  }

  isWRIProgramSelected() {
    return this.customerHIRLSettings.wriProgramList.includes(
      this.hirlProjectRegistration?.eep_program_info?.slug
    );
  }

  onAbandonRegistration() {
    const dialogRef = this.dialog.open(
      HirlRegistrationAbandonConfirmDialogComponent,
      {
        width: '550px',
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(toggleLoading({ payload: true }));
        this.hirlProjectRegistrationService
          .abandon(this.hirlProjectRegistration.id, result)
          .pipe(first())
          .subscribe(
            _ => {
              this.store.dispatch(toggleLoading({ payload: false }));
            },
            error => this.uiHelperService.handleUserRequestError(error)
          );
      }
    });
  }

  onEditRegistration() {
    if (
      this.hirlProjectRegistration.project_type ===
      HIRLProjectRegistrationType.singleFamily
    ) {
      return this.router.navigate([
        'hi',
        'project_registrations',
        this.hirlProjectRegistration.id,
        'edit-single-family',
      ]);
    }
    if (
      this.hirlProjectRegistration.project_type ===
      HIRLProjectRegistrationType.landDevelopment
    ) {
      return this.router.navigate([
        'hi',
        'project_registrations',
        this.hirlProjectRegistration.id,
        'edit-land-development',
      ]);
    }
    return this.router.navigate([
      'hi',
      'project_registrations',
      this.hirlProjectRegistration.id,
      'edit-multi-family',
    ]);
  }
}
