import { HomeInfo } from '@/data/home/models/home';

export class ProgramEligibilityList {
  id: number;
  home: number;
  home_info: HomeInfo;
  floorplan: number;
  floorplan_name: string;
  eep_program: number;
  eep_program_name: string;
  state: string;
}
