import { InfiltrationBackendDict } from '@/data/simulation/models/enclosure/Infiltration';
import { createAction, props } from '@ngrx/store';
import { StateErrors } from '../shared/base.state';

export const loadInfiltration = createAction(
  '[Infiltration] Load Infiltration',
  props<{ infiltration: InfiltrationBackendDict }>()
);

export const loadInfiltrationSuccess = createAction(
  '[Infiltration] Load Infiltration Success',
  props<{
    infiltration: InfiltrationBackendDict;
    errors: StateErrors<InfiltrationBackendDict>;
  }>()
);

export const loadInfiltrationFailure = createAction(
  '[Infiltration] Load Infiltration Failure',
  props<{ error: any }>()
);

export const updateInfiltration = createAction(
  '[Infiltration] Update Infiltration',
  props<{ id: number; infiltrationChanges: Partial<InfiltrationBackendDict> }>()
);

export const updateInfiltrationFailure = createAction(
  '[Infiltration API] Update Infiltration Failure',
  props<{ id: number }>()
);
