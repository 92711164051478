import { Component } from '@angular/core';
import { BaseAppliancesDirective } from '../base-appliances.directive';
import {
  AppliancesBackendDict,
  createCookingRangeForm,
  FIELD_LABELS,
} from '@/data/simulation/models/Appliances';
import { FormGroup } from '@angular/forms';
import { FuelTypeLabels } from '@/data/simulation/enumerations';

@Component({
  selector: 'app-cooking-range',
  templateUrl: './cooking-range.component.html',
  styleUrls: ['./cooking-range.component.scss'],
})
export class CookingRangeComponent extends BaseAppliancesDirective {
  createForm: (data: AppliancesBackendDict) => FormGroup<any> =
    createCookingRangeForm;
  fuleTypeLabels = FuelTypeLabels;
  fieldLabels = FIELD_LABELS;
}
