import { ModelErrors } from '@/modules/simulation/state/shared/base.state';
import {
  AbstractControl,
  AbstractControlOptions,
  AsyncValidatorFn,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

export class ModelFormGroup extends FormGroup {
  private enableSubject = new BehaviorSubject<boolean>(false);

  enable$ = this.enableSubject.asObservable();

  constructor(
    controls,
    validatorOrOpts?:
      | ValidatorFn
      | ValidatorFn[]
      | AbstractControlOptions
      | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
  ) {
    super(controls, validatorOrOpts, asyncValidator);
    const connections = {};
    Object.keys(this.controls).forEach((name: string) => {
      if (this.controls[name] instanceof ModelFormControl) {
        (this.controls[name] as ModelFormControl).connectedToFields.forEach(
          (field: string) => {
            if (!connections[field]) connections[field] = [];
            connections[field].push(name);
          }
        );
      }
    });

    Object.keys(connections).forEach((name: string) => {
      const connectedControls = connections[name].map(
        (controlName: string) => this.controls[controlName]
      );
      this.controls[name].addValidators(control => {
        connectedControls.forEach((control: AbstractControl) => {
          control.updateValueAndValidity({ onlySelf: true, emitEvent: false });
        });
        return null;
      });
    });
  }

  markEditable() {
    this.enableSubject.next(true);
  }

  markUneditable() {
    this.enableSubject.next(false);
  }

  getEditableState(): boolean {
    return this.enableSubject.getValue();
  }

  getFieldAndModelErros(): ModelErrors<any> {
    const errors = {};

    Object.keys(this.controls).forEach(key => {
      const control = (this.controls as any)[key];
      control.setParent(this);
      control.updateValueAndValidity({ onlySelf: true, emitEvent: false });
      errors[key] = control.errors;
    });
    return {
      field: errors,
      model: this.errors || {},
    };
  }
}

export class ModelFormControl extends FormControl {
  connectedToFields: string[] = [];

  constructor(
    formState: any = null,
    validatorOrOpts?:
      | ValidatorFn
      | ValidatorFn[]
      | AbstractControlOptions
      | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
    connectedToFields: string[] = []
  ) {
    super(formState, validatorOrOpts, asyncValidator);
    this.connectedToFields = connectedToFields || [];
  }
}

export function createConditionalValidator(
  condition: (controls: { [key: string]: AbstractControl }) => boolean,
  fieldNames: string[] = []
) {
  return (validators: ValidatorFn[]): [ValidatorFn, null, string[]] => [
    validateOnlyIf(condition, validators),
    null,
    fieldNames,
  ];
}

export function validateOnlyIf(
  condition: (controls) => boolean,
  validators: ValidatorFn[]
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const parent = control.parent;
    if (!parent) {
      return null;
    }

    const verificationRequired = condition(parent.controls);

    if (!verificationRequired) return null;

    let errors = null;
    for (const validator of validators) {
      const error = validator(control);
      if (!error) continue;

      errors = Object.assign({}, errors, error);
    }
    return errors;
  };
}
