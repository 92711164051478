import { AuthGuard } from '@/core/guards';
import { InspectionGradeListComponent } from '@/modules/user-management/pages/inspection-grade-list/inspection-grade-list.component';

export const UserManagementRouting = [
  {
    path: 'user_management',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'inspection_grades',
        children: [
          {
            path: '',
            component: InspectionGradeListComponent,
          },
        ],
      },
    ],
  },
];
