<h2 mat-dialog-title>
  Setup Invoice preferences
</h2>
<mat-dialog-content *ngIf="initialized">
  <p>
    Looks like you didn't select Invoice plan yet.
  </p>
  <form [formGroup]="form" class="mb-2">
    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100 mb-2">
          <mat-label>Invoice Preferences</mat-label>
          <mat-select aria-label="Invoice Preferences Type" formControlName="gcp_fee_calculation_type">
            <mat-option *ngFor="let item of GCPFeeCalculationTypeLabelMapping | defaultOrderKeyValue" [value]="item.key">
              {{ item.value }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('gcp_fee_calculation_type').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button color="accent" mat-raised-button (click)="save($event)">Submit</button>
</mat-dialog-actions>
