export interface ValidationResult {
  [key: string]: ResultItem | ValidationResult | ValidationResult[];
}

export interface ResultItem {
  description: string;
  errors: any[];
  field: string;
  flags: string[];
  help_text: string;
  label: string;
  passing: boolean;
  warnings: any[];
}

export function getFlatResults(result: ValidationResult, keyPrefix = '') {
  let flatResults = {};

  for (const key in result) {
    const value = result[key];
    if (Array.isArray(value)) {
      for (const [i, item] of value.entries()) {
        flatResults = {
          ...flatResults,
          ...getFlatResults(item, key + i + '.'),
        };
      }
    } else if (typeof value === 'object' && value !== null) {
      if (value.hasOwnProperty('errors')) {
        if ((value as ResultItem).errors.length > 0) {
          flatResults[keyPrefix + key] = value.errors;
        }
      } else {
        flatResults = {
          ...flatResults,
          ...getFlatResults(value as ValidationResult, key + '.'),
        };
      }
    }
  }

  return flatResults;
}
