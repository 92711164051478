import { PhotovoltaicBackendDict } from '@/data/simulation/models/generators/Photovoltaic';
import { createAction, props } from '@ngrx/store';
import { ModelErrors, StateErrors } from '../shared/base.state';

export const loadPhotovoltaics = createAction(
  '[Simulation API] Load Photovoltaics',
  props<{
    photovoltaics: PhotovoltaicBackendDict[];
  }>()
);

export const loadPhotovoltaicsSuccess = createAction(
  '[Photovoltaic API] Load Photovoltaics Success',
  props<{
    photovoltaics: PhotovoltaicBackendDict[];
    errors: StateErrors<PhotovoltaicBackendDict>;
  }>()
);

export const updatePhotovoltaic = createAction(
  '[Photovoltaic UI] Update Photovoltaic',
  props<{
    id: number;
    photovoltaicChanges: Partial<PhotovoltaicBackendDict>;
  }>()
);

export const updatePhotovoltaicSuccess = createAction(
  '[Photovoltaic API] Update Photovoltaic Success',
  props<{
    photovoltaic: PhotovoltaicBackendDict;
    errors: ModelErrors<PhotovoltaicBackendDict>;
  }>()
);

export const updatePhotovoltaicFailure = createAction(
  '[Photovoltaic API] Update Photovoltaic Failure',
  props<{ id: number }>()
);

export const removePhotovoltaic = createAction(
  '[Photovoltaic UI] Remove Photovoltaic',
  props<{ id: number }>()
);

export const removePhotovoltaicSuccess = createAction(
  '[Photovoltaic API] Remove Photovoltaic Success',
  props<{ id: number }>()
);

export const removePhotovoltaicFailure = createAction(
  '[Photovoltaic API] Remove Photovoltaic Failure',
  props<{ id: number }>()
);
