<h2 mat-dialog-title>
  <ng-container *ngIf="!isEditForm">
    Create New <b>Community</b>
  </ng-container>
  <ng-container *ngIf="isEditForm">
    Edit <b>Community</b>
  </ng-container>
</h2>
<mat-dialog-content *ngIf="initialized">
  <form [formGroup]="form" class="mb-2">
    <ng-container *ngIf="checkedForExistingCommunity; else existingCommunitySelection;">
      <div class="row">
        <div class="col-12">
          <mat-form-field appearance="outline" class="mb-2 w-100">
            <mat-label>Community Name</mat-label>
            <input matInput type="text" placeholder="Name" formControlName="name" required>
            <mat-error *ngIf="form.get('name').hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mat-form-field style="width: 100%;" appearance="outline" class="mb-2">
                <mat-label>City/County/State</mat-label>
                <app-generic-model-autocomplete formControlName="city_info" modelClassName="city" [filterParams]="{ordering: 'name', is_attached: true}"></app-generic-model-autocomplete>
                <mat-error *ngIf="form.get('city_info').hasError('required')">City is required -
                  Must select option from list
              </mat-error>
            </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mat-form-field appearance="outline" class="mb-2 w-100">
            <mat-label>Website</mat-label>
            <input matInput type="text" placeholder="Website" formControlName="website_url">
            <mat-error *ngIf="!(form.controls['website_url'].hasError('websiteURLValidator'))">
              Incorrect url format. Example: www.example.com or https://www.example.com
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mat-form-field appearance="outline" class="mb-2 w-100">
            <mat-label>Crossroads</mat-label>
            <input matInput type="text" placeholder="Crossroads" formControlName="cross_roads">
          </mat-form-field>
        </div>
      </div>
    </ng-container>
    <ng-template #existingCommunitySelection>
      <div class="p-2">
        <div class="row">
          <div class="col-12">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Community Name</mat-label>
              <input matInput placeholder="Type New Community Name or Select existing" [formControl]="existingCommunityControl"
                     [matAutocomplete]="existingCommunityAutocomplete">
              <mat-autocomplete #existingCommunityAutocomplete="matAutocomplete" autoActiveFirstOption [displayWith]="displayExistingCommunityAutocomplete" (optionSelected)='onCommunitySelected($event)'>
                <mat-option
                    *ngFor="let community of (existingCommunities$ | async)?.results"
                    [value]="community">
                  <app-community-display [community]="community" displayType="onlyName"></app-community-display>
                </mat-option>
                <mat-option [value]="newCommunityName">
                  <b>Create New ...</b>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
      </div>
    </ng-template>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button color="accent" mat-raised-button (click)="save($event)">Save</button>
  <button mat-button [mat-dialog-close]="null" color="primary" mat-raised-button>Cancel</button>
</mat-dialog-actions>
