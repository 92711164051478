<mat-expansion-panel [expanded]="expanded">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <strong>Kitchen Appliances</strong>
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
        <div class="row">
            <div class="col-6">
                <app-dishwasher [entityId]="entityId"></app-dishwasher>
            </div>
            <div class="col-6">
                <div class="row">
                    <div class="col-12">
                        <app-refrigerator [entityId]="entityId"></app-refrigerator>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <app-cooking-range [entityId]="entityId"></app-cooking-range>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</mat-expansion-panel>
