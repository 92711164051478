<h2 mat-dialog-title>
    {{ this.data.title }}
</h2>
<mat-dialog-content>
    <p>
        {{ this.data.description }}
    </p>
    <ngx-dropzone (change)="onSelect($event)" [multiple]="this.data.multiple">
        <ngx-dropzone-label>{{ this.data.label }}</ngx-dropzone-label>
        <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
            <ngx-dropzone-label>{{ f.name | slice :0: 20 }} ({{ f.type | slice :0: 20 }})</ngx-dropzone-label>
        </ngx-dropzone-preview>
    </ngx-dropzone>
</mat-dialog-content>
<div class="row">
    <div class="col-12">
        <hr>
    </div>
</div>
<div class="row">
    <div class="col-12 text-right">
        <button mat-raised-button color="accent" class="mr-2" (click)="onConfirm()">Confirm</button>
        <button mat-button color="accent" [mat-dialog-close]="null" cdkFocusInitial>Cancel</button>
    </div>
</div>
