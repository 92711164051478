import { CustomerDocument } from '@/data/filehandling/models';
import { Action, createReducer, on } from '@ngrx/store';

import { FloorPlan } from '@/data/floorplan/models/floorplan';
import {
  loadFloorplan,
  resetFloorplan,
  loadFloorplanDocuments,
  loadFloorplanDocumentsCount,
  loadFloorplanHomeStatusesCount,
  updateFloorplanDocument,
  deleteFloorplanDocument,
} from '@/state/actions/floorplan/floorplan.actions';

export const floorplanStateKey = 'floorplan';

export interface FloorplanState {
  detail: FloorPlan;
  documents: CustomerDocument[];
  documentsCount: number;
  homeStatusesCount: number;
}

export const floorplanInitialState: FloorplanState = {
  detail: null,
  documents: [],
  documentsCount: 0,
  homeStatusesCount: 0,
};

const floorplanReducer = createReducer(
  floorplanInitialState,
  on(resetFloorplan, (state, action) => ({ ...floorplanInitialState })),
  on(loadFloorplan, (state, { payload }) => ({ ...state, detail: payload })),
  on(loadFloorplanDocuments, (state, { payload }) => ({
    ...state,
    documents: payload,
  })),
  on(loadFloorplanDocumentsCount, (state, { payload }) => ({
    ...state,
    documentsCount: payload,
  })),
  on(loadFloorplanHomeStatusesCount, (state, { payload }) => ({
    ...state,
    homeStatusesCount: payload,
  })),
  on(updateFloorplanDocument, (state, { payload }) => {
    const documentIndex = state.documents.findIndex(
      document => document.id === payload.id
    );
    const newDocuments = Object.assign([], state.documents, {
      [documentIndex]: payload,
    });
    return { ...state, documents: newDocuments };
  }),
  on(deleteFloorplanDocument, (state, { payload }) => {
    const documentIndex = state.documents.findIndex(
      document => document.id === payload.id
    );
    const newDocuments = Object.assign([], state.documents);
    newDocuments.splice(documentIndex, 1);
    return {
      ...state,
      documents: newDocuments,
      documentsCount: state.documentsCount - 1,
    };
  })
);

export function reducer(state: FloorplanState | undefined, action: Action) {
  return floorplanReducer(state, action);
}
