import { AboveGradeWallTypeEffects } from './above-grade-wall-type/effects';
import { SimulationEffects } from './simulation/effects';
import { AboveGradeWallEffects } from './above-grade-wall/effects';
import { FoundationWallEffects } from './foundation-wall/effects';
import { FoundationWallTypeEffects } from './foundation-wall-type/effects';
import { SharedEffects } from './shared/shared.effects';
import { FrameFloorEffects } from './frame-floor/effects';
import { FrameFloorTypeEffects } from './frame-floor-type/effects';
import { RoofEffects } from './roof/effects';
import { RoofTypeEffects } from './roof-type/effects';
import { RimJoistEffects } from './rim-joist/effects';
import { RimJoistTypeEffects } from './rim-joist-type/effects';
import { InfiltrationEffects } from './infiltration/effects';
import { SlabEffects } from './slab/effects';
import { SlabTypeEffects } from './slab-type/effects';
import { SkylightEffects } from './skylight/effects';
import { SkylightTypeEffects } from './skylight-type/effects';
import { DoorEffects } from './door/effects';
import { DoorTypeEffects } from './door-type/effects';
import { WindowEffects } from './window/effects';
import { WindowTypeEffects } from './window-type/effects';
import { MechanicalEquipmentEffects } from './mechanical-equipment/effects';
import { AirConditionerEffects } from './air-conditioner/effects';
import { HeaterEffects } from './heater/effects';
import { WaterHeaterEffects } from './water-heater/effects';
import { DehumidifierEffects } from './dehumidifier/effects';
import { GroundSourceHeatPumpEffects } from './ground-source-heat-pump/effects';
import { AirSourceHeatPumpEffects } from './air-source-heat-pump/effects';
import { AppliancesEffects } from './appliances/effects';
import { LightsEffects } from './lights/effects';
import { DistributionSystemEffects } from './distribution-system/effects';
import { DuctEffects } from './duct/effects';
import { PhotovoltaicEffects } from './photovoltaic/effects';
import { WaterSystemEffects } from './water-system/effects';
import { ProjectEffects } from './project/effects';
import { LocationEffects } from './location/effects';
import { UtilityRateEffects } from './utility-rate/effects';
import { SeasonalRateEffects } from './seasonal-rate/effects';
import { BlockRateEffects } from './block-rate/effects';
import { ThermostatEffects } from './thermostat/effects';
import { NaturalVentilationEffects } from './natural-ventilation/effects';
import { MechanicalVentilationEffects } from './mechanical-ventilation/effects';
import { SimulationConfigEffects } from './simulation-config/effects';

export const effects = [
  SimulationEffects,
  AboveGradeWallEffects,
  AboveGradeWallTypeEffects,
  RoofEffects,
  RoofTypeEffects,
  FoundationWallEffects,
  FoundationWallTypeEffects,
  FrameFloorEffects,
  FrameFloorTypeEffects,
  RimJoistEffects,
  RimJoistTypeEffects,
  InfiltrationEffects,
  SlabEffects,
  SlabTypeEffects,
  SkylightEffects,
  SkylightTypeEffects,
  DoorEffects,
  DoorTypeEffects,
  WindowEffects,
  WindowTypeEffects,
  MechanicalEquipmentEffects,
  AirConditionerEffects,
  AirSourceHeatPumpEffects,
  DehumidifierEffects,
  HeaterEffects,
  GroundSourceHeatPumpEffects,
  WaterHeaterEffects,
  AppliancesEffects,
  LightsEffects,
  DistributionSystemEffects,
  DuctEffects,
  ThermostatEffects,
  PhotovoltaicEffects,
  WaterSystemEffects,
  ProjectEffects,
  LocationEffects,
  UtilityRateEffects,
  SeasonalRateEffects,
  BlockRateEffects,
  MechanicalVentilationEffects,
  NaturalVentilationEffects,
  SimulationConfigEffects,

  SharedEffects,
];
