import { FoundationWallTypeBackendDict } from '@/data/simulation/models/enclosure/FoundationWallType';
import { createAction, props } from '@ngrx/store';
import { StateErrors } from '../shared/base.state';

export const loadFoundationWallTypes = createAction(
  '[FoundationWallType] Load Foundation Wall Types',
  props<{ wallTypes: FoundationWallTypeBackendDict[] }>()
);

export const loadFoundationWallTypesSuccess = createAction(
  '[FoundationWallType] Load FoundationWallTypes Success',
  props<{
    wallTypes: FoundationWallTypeBackendDict[];
    errors: StateErrors<FoundationWallTypeBackendDict>;
  }>()
);

export const addFoundationWallType = createAction(
  '[FoundationWallType UI] Add FoundationWallType',
  props<{ wallId: number; wallType: FoundationWallTypeBackendDict }>()
);

export const removeFoundationWallType = createAction(
  '[FoundationWall UI] Remove FoundationWallType',
  props<{ wallId: number; wallTypeId: number }>()
);

export const removeFoundationWallTypeSuccess = createAction(
  '[FoundationWallType API] Remove FoundationWallType Success',
  props<{ id: number }>()
);

export const updateFoundationWallType = createAction(
  '[FoundationWallType UI] Update FoundationWallType',
  props<{ wallTypeChanges: Partial<FoundationWallTypeBackendDict> }>()
);

export const updateFoundationWallTypeFailure = createAction(
  '[FoundationWallType API] Update FoundationWallType Failure',
  props<{ id: number }>()
);
