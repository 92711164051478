import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { first, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getInfoUser } from '@/state/selectors/info.selector';
import { AppState } from '@/state/reducers';
import { BasePermissionService } from '@/core/services/base-permission-service';
import { Training } from '@/data/user-management/models';
import { User } from '@/data/core/models/user';
import { TrainingService } from '@/data/user-management/services/training.service';

@Injectable({
  providedIn: 'root',
})
export class TrainingPermissionService extends BasePermissionService {
  applicableCompanySlugs: string[] = [
    'provider-washington-state-university-extension-ene',
    'provider-home-innovation-research-labs',
  ];

  constructor(
    public store: Store<AppState>,
    private trainingService: TrainingService
  ) {
    super(store);
  }

  canCreate(): Observable<ObjectPermissionResponse> {
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
    }).pipe(
      map(({ currentUser }: { currentUser: User }) => {
        if (currentUser.is_superuser) {
          return new ObjectPermissionResponse(true);
        }
        if (
          this.applicableCompanySlugs.includes(currentUser.company_info?.slug)
        ) {
          return new ObjectPermissionResponse(true);
        }
        return new ObjectPermissionResponse(false);
      })
    );
  }

  canEdit(training: Training | number): Observable<ObjectPermissionResponse> {
    const entity$: Observable<Training> =
      this.resolveEntity<Training>(training);
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: entity$,
    }).pipe(
      map(
        ({ currentUser, entity }: { currentUser: User; entity: Training }) => {
          if (currentUser.is_superuser) {
            return new ObjectPermissionResponse(true);
          }
          if (
            this.applicableCompanySlugs.includes(currentUser.company_info?.slug)
          ) {
            return new ObjectPermissionResponse(true);
          }
          return new ObjectPermissionResponse(false);
        }
      )
    );
  }

  canDelete(training: Training | number): Observable<ObjectPermissionResponse> {
    const entity$: Observable<Training> =
      this.resolveEntity<Training>(training);

    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: entity$,
    }).pipe(
      map(
        ({ currentUser, entity }: { currentUser: User; entity: Training }) => {
          if (currentUser.is_superuser) {
            return new ObjectPermissionResponse(true);
          }
          if (
            this.applicableCompanySlugs.includes(currentUser.company_info?.slug)
          ) {
            return new ObjectPermissionResponse(true);
          }
          return new ObjectPermissionResponse(false);
        }
      )
    );
  }

  canDownloadCertificate(
    training: Training | number
  ): Observable<ObjectPermissionResponse> {
    const entity$: Observable<Training> =
      this.resolveEntity<Training>(training);
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: entity$,
    }).pipe(
      map(
        ({ currentUser, entity }: { currentUser: User; entity: Training }) => {
          if (entity.certificate) {
            if (currentUser.is_superuser) {
              return new ObjectPermissionResponse(true);
            }
            if (
              this.applicableCompanySlugs.includes(
                currentUser.company_info?.slug
              )
            ) {
              return new ObjectPermissionResponse(true);
            }
            if (currentUser.id === entity.trainee) {
              return new ObjectPermissionResponse(true);
            }
          }

          return new ObjectPermissionResponse(false);
        }
      )
    );
  }
}
