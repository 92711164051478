import { SlabTypeBackendDict } from '@/data/simulation/models/enclosure/SlabType';
import { createAction, props } from '@ngrx/store';
import { StateErrors } from '../shared/base.state';

export const loadSlabTypes = createAction(
  '[SlabType] Load Foundation Slab Types',
  props<{ slabTypes: SlabTypeBackendDict[] }>()
);

export const loadSlabTypesSuccess = createAction(
  '[SlabType] Load SlabTypes Success',
  props<{
    slabTypes: SlabTypeBackendDict[];
    errors: StateErrors<SlabTypeBackendDict>;
  }>()
);

export const addSlabType = createAction(
  '[SlabType UI] Add SlabType',
  props<{ slabId: number; slabType: SlabTypeBackendDict }>()
);

export const removeSlabType = createAction(
  '[Slab UI] Remove SlabType',
  props<{ slabId: number; slabTypeId: number }>()
);

export const removeSlabTypeSuccess = createAction(
  '[SlabType API] Remove SlabType Success',
  props<{ id: number }>()
);

export const updateSlabType = createAction(
  '[SlabType UI] Update SlabType',
  props<{ slabTypeChanges: Partial<SlabTypeBackendDict> }>()
);

export const updateSlabTypeFailure = createAction(
  '[SlabType API] Update SlabType Failure',
  props<{ id: number }>()
);
