import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { first, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getInfoUser } from '@/state/selectors/info.selector';
import { AppState } from '@/state/reducers';
import { ProductService } from '@/data/product/services/product.service';
import { Product, ProductStatus } from '@/data/product/models';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';

@Injectable({
  providedIn: 'root',
})
export class ProductPermissionService {
  constructor(
    public store: Store<AppState>,
    public productService: ProductService
  ) {}

  canCreate(): Observable<ObjectPermissionResponse> {
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
    }).pipe(
      map(({ currentUser }) => {
        if (!currentUser.is_company_admin) {
          return new ObjectPermissionResponse(
            false,
            'Only Company Admin users can Create new products'
          );
        }
        return new ObjectPermissionResponse(true);
      })
    );
  }

  canEdit(product: Product | number): Observable<ObjectPermissionResponse> {
    let product$: Observable<Product>;
    if (typeof product === 'number') {
      product$ = this.productService.retrieve(product, { ignoreStore: true });
    } else {
      product$ = of(product);
    }
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: product$,
    }).pipe(
      map(({ currentUser, entity }) => {
        return new ObjectPermissionResponse(true);
      })
    );
  }

  canPublish(product: Product | number): Observable<ObjectPermissionResponse> {
    let product$: Observable<Product>;
    if (typeof product === 'number') {
      product$ = this.productService.retrieve(product, { ignoreStore: true });
    } else {
      product$ = of(product);
    }
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: product$,
    }).pipe(
      map(({ currentUser, entity }) => {
        if (
          !currentUser.is_superuser &&
          !(currentUser.company_info?.slug === CustomerHIRLSettings.companySlug)
        ) {
          return new ObjectPermissionResponse(false);
        }
        if (entity.status === ProductStatus.draft) {
          return new ObjectPermissionResponse(true);
        }
        return new ObjectPermissionResponse(false);
      })
    );
  }

  canArchive(product: Product | number): Observable<ObjectPermissionResponse> {
    let product$: Observable<Product>;
    if (typeof product === 'number') {
      product$ = this.productService.retrieve(product, { ignoreStore: true });
    } else {
      product$ = of(product);
    }
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: product$,
    }).pipe(
      map(({ currentUser, entity }) => {
        if (
          !currentUser.is_superuser &&
          !(currentUser.company_info?.slug === CustomerHIRLSettings.companySlug)
        ) {
          return new ObjectPermissionResponse(false);
        }
        if (entity.status !== ProductStatus.archived) {
          return new ObjectPermissionResponse(true);
        }
        return new ObjectPermissionResponse(false);
      })
    );
  }

  canDelete(product: Product | number): Observable<ObjectPermissionResponse> {
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
    }).pipe(
      map(({ currentUser }) => {
        if (!currentUser.is_superuser) {
          return new ObjectPermissionResponse(
            false,
            'Only AXIS Administrators can delete products'
          );
        }
        return new ObjectPermissionResponse(true);
      })
    );
  }
}
