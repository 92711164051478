import { createAction, props } from '@ngrx/store';
import { User } from '@/data/core/models/user';
import { ImpersonationUser } from '@/data/core/models/impersonation_user';

export const loadInfo = createAction(
  '[Info] Load Info',
  props<{ payload: ImpersonationUser }>()
);
export const loadImpersonations = createAction(
  '[Info] Load Impersonations',
  props<{ payload: User[] }>()
);
export const updateUserInfo = createAction(
  '[Info] Update User Info',
  props<{ payload: User }>()
);
