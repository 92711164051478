
<mat-expansion-panel appFocusTarget [entityName]="entityName + 'Annotations'" [entityId]="entityId" *ngIf="notesLength > 0 || entityName === 'simulation'">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <app-model-link [entityName]="entityName" [entityId]="entityId" [title]="title">
            </app-model-link>
        </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
        <ul class="p-0 pl-3">
            <li *ngFor="let note of notes | keyvalue">
                <div class="note-item-container" (click)="viewNoteDetail($event, note.value)" >
                    <quill-view [content]="note.value.content" theme="snow" sanitize="true"></quill-view>
                    <div class="action-button-container">
                        <button mat-button (click)="deleteNote(note.key)" class="action-button wrapper-container" matTooltip="Delete note">
                            <mat-icon class="action-button material-icons">delete</mat-icon>
                        </button>
                        <button mat-button (click)="editNote(note.value)" class="action-button wrapper-container" matTooltip="Edit note">
                            <mat-icon class="action-button material-icons">edit</mat-icon>
                        </button>
                    </div>
                </div>
            </li>
        </ul>
        <div *ngIf="notesLength === 0" class="text-center font-italic">
            No notes available!
        </div>
        <div class="d-flex justify-content-center mt-3">
            <button mat-button (click)="addNote($event)" matTooltip="Add a new note">
                <mat-icon>add_circle</mat-icon>
            </button>
        </div>
    </ng-template>
</mat-expansion-panel>
