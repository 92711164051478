import { LocationBackendDict } from '@/data/simulation/models/Location';
import { createAction, props } from '@ngrx/store';
import { StateErrors } from '../shared/base.state';

export const loadLocations = createAction(
  '[Location] Load Locations',
  props<{ locations: LocationBackendDict[] }>()
);

export const loadLocationsSuccess = createAction(
  '[Location] Load Locations Success',
  props<{
    locations: LocationBackendDict[];
    errors: StateErrors<LocationBackendDict>;
  }>()
);

export const loadLocationFailure = createAction(
  '[Location] Load Location Failure',
  props<{ error: any }>()
);

export const updateLocation = createAction(
  '[Location] Update Location',
  props<{ locationChanges: Partial<LocationBackendDict> }>()
);

export const updateLocationFailure = createAction(
  '[Location API] Update Location Failure',
  props<{ id: number }>()
);
