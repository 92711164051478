// Task

import { createAction, props } from '@ngrx/store';
import { Task } from '@/data/scheduling/models';

export const resetSchedulingTask = createAction('[Scheduling Tasks] Reset');

export const loadSchedulingTasks = createAction(
  '[Scheduling Tasks] Load Tasks',
  props<{ payload: Task[] }>()
);

export const loadSchedulingTasksCount = createAction(
  '[Scheduling Tasks] Tasks Count',
  props<{ payload: number }>()
);

export const loadSchedulingTask = createAction(
  '[Scheduling Tasks] Load Task',
  props<{ payload: Task }>()
);

export const updateSchedulingTask = createAction(
  '[Scheduling Tasks] Update COI Document',
  props<{ payload: Task }>()
);
