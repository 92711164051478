import { AppState } from '@/state/reducers';
import { createSelector } from '@ngrx/store';
import { hirlPaymentRequestStateKey } from '@/state/reducers/customer-hirl/payment-request';

export const selectHIRLPaymentRequestState = (state: AppState) =>
  state[hirlPaymentRequestStateKey];

export const getHIRLPaymentRequest = createSelector(
  selectHIRLPaymentRequestState,
  store => store.detail
);

// PaymentRequestProducts
export const getHIRLPaymentRequestProducts = createSelector(
  selectHIRLPaymentRequestState,
  store => store.paymentRequestProducts
);

export const getHIRLPaymentRequestProductsCount = createSelector(
  selectHIRLPaymentRequestState,
  store => store.paymentRequestProductsCount
);
