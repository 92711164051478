<app-loading-overlay *ngIf="loading"></app-loading-overlay>
<app-form formName="airConditionerFormGroup" [formGroup]="airConditionerFormGroup" (save)="onSave($event)">
    <div>
        <app-input formControlName="name" [label]="fieldLabels.name"></app-input>
    </div>
    <div>
        <app-select formControlName="fuel" [label]="fieldLabels.fuel" [items]="fuelTypeLabels"></app-select>
        <app-select formControlName="system_type" [label]="fieldLabels.system_type" [items]="coolingSystemTypeLabels"></app-select>
    </div>
    <div>
        <app-input
            *ngIf="!airConditionerFormGroup?.controls.auto_size_capacity.value"
            formControlName="capacity"
            type="number"
            [label]="fieldLabels.capacity">
        </app-input>
        <app-select
            *ngIf="!airConditionerFormGroup?.controls.auto_size_capacity.value"
            formControlName="capacity_unit"
            [label]="fieldLabels.capacity_unit"
            [items]="capacityUnitLabels">
        </app-select>
        <app-checkbox formControlName="auto_size_capacity" [label]="fieldLabels.auto_size_capacity"></app-checkbox>
    </div>
    <div>
        <app-input
            formControlName="efficiency"
            type="number"
            [label]="fieldLabels.efficiency">
        </app-input>
        <app-select formControlName="efficiency_unit" [label]="fieldLabels.efficiency_unit" [items]="coolingEfficiencyUnitLabels">
        </app-select>
    </div>
    <div>
        <app-select formControlName="motor_type" [label]="fieldLabels.motor_type" [items]="mototTypeLabels"></app-select>
        <app-input formControlName="sensible_heat_fraction" type="number" [label]="fieldLabels.sensible_heat_fraction"></app-input>
    </div>

    <div>
        <app-input
                formControlName="fan_power"
                type="number"
                unit="W/cfm"
                [label]="fieldLabels.fan_power">
        </app-input>
        <app-input
                formControlName="air_flow_defect_ratio"
                type="number"
                [label]="fieldLabels.air_flow_defect_ratio">
        </app-input>
        <app-input
                formControlName="charge_defect_ratio"
                type="number"
                [label]="fieldLabels.charge_defect_ratio">
        </app-input>
    </div>
    <div>
        <app-input formControlName="ahri_reference_number" type="number" [label]="fieldLabels.ahri_reference_number"></app-input>
    </div>
</app-form>
