<h2 mat-dialog-title>
  <ng-container *ngIf="!editForm">
    Add Contact
  </ng-container>
  <ng-container *ngIf="editForm">
    Edit Contact
  </ng-container>
</h2>
<mat-dialog-content *ngIf="initialized">
  <form [formGroup]="form" class="mb-2">
    <div class="row">
      <div class="col-4">
        <mat-form-field class="w-100 mb-2">
          <mat-label>First Name</mat-label>
          <input matInput type="text" placeholder="First Name" formControlName="first_name" required
                 [readonly]="isLoading">
          <mat-error *ngIf="form.get('first_name').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-4">
        <mat-form-field class="w-100 mb-2">
          <mat-label>Last Name</mat-label>
          <input matInput type="text" placeholder="Last Name" formControlName="last_name" required
                 [readonly]="isLoading">
          <mat-error *ngIf="form.get('last_name').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-4">
        <ng-container *ngIf="data.displayType === ContactCardDialogDisplayType.default">
          <mat-form-field class="w-100 mb-2">
            <mat-label>Title/Role</mat-label>
            <input matInput type="text" placeholder="Title/Role" formControlName="title" [readonly]="isLoading">
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="data.displayType === ContactCardDialogDisplayType.customerHIRLManufacturer">
          <mat-form-field>
            <mat-label>Title/Role</mat-label>
            <mat-select formControlName="title">
              <mat-option *ngFor="let department of customerHIRLManufacturerDepartments" [value]="department">
                {{ department }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('title').hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>
        </ng-container>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h4>Phone Numbers:</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div formArrayName="phones">
          <ng-container class="mb-2" *ngFor="let phoneControl of getPhonesControls(); let i=index">
            <ng-container [formGroupName]="i">
              <div class="row">
                <div class="col-6">
                  <mat-form-field class="w-100 mb-2">
                    <mat-label>Phone</mat-label>
                    <input matInput placeholder="Phone"
                           formControlName="phone"
                           required [readonly]="isLoading" mask="000-000-0000" [showMaskTyped]="true">
                    <mat-error *ngIf="phoneControl.get('phone').hasError('required')">
                      Phone is required
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-5">
                  <mat-form-field class="w-100 mb-2">
                    <mat-label>Description</mat-label>
                    <input matInput placeholder="For example: Work Phone"
                           formControlName="description"
                           [readonly]="isLoading">
                  </mat-form-field>
                </div>
                <div class="col-1 text-right">
                  <button mat-button mat-icon-button (click)="handleRemovePhone($event, i)" class="mt-4">
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <div class="row">
            <div class="col-12 text-center">
              <button mat-raised-button color="accent" (click)="addPhone()" [disabled]="isLoading">
                Add Phone Number
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h4>Emails:</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div formArrayName="emails">
          <ng-container class="mb-2" *ngFor="let emailControl of getEmailsControls(); let i=index">
            <ng-container [formGroupName]="i">
              <div class="row">
                <div class="col-6">
                  <mat-form-field class="w-100 mb-2">
                    <mat-label>Email</mat-label>
                    <input matInput placeholder="Email"
                           formControlName="email"
                           required [readonly]="isLoading">
                    <mat-error *ngIf="emailControl.get('email').hasError('required')">
                      Email is required
                    </mat-error>
                    <mat-error *ngIf="emailControl.get('email').hasError('email')">
                      Email Address is invalid
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-5">
                  <mat-form-field class="w-100 mb-2">
                    <mat-label>Description</mat-label>
                    <input matInput placeholder="For example: Main Email"
                           formControlName="description"
                           [readonly]="isLoading">
                  </mat-form-field>
                </div>
                <div class="col-1 text-right">
                  <button mat-button mat-icon-button (click)="handleRemoveEmail($event, i)" class="mt-4">
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <div class="row">
            <div class="col-12 text-center">
              <button mat-raised-button color="accent" (click)="addEmail()" [disabled]="isLoading">
                Add Email Address
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>
<div class="row">
  <div class="col-12">
    <hr>
  </div>
</div>
<div class="row" *ngIf="!isLoading">
  <div class="col-12 text-center">
    <button mat-raised-button color="accent" class="mr-2" (click)="onSave($event)">
      <span *ngIf="editForm">Save</span>
      <span *ngIf="!editForm">Create</span>
    </button>
    <button mat-raised-button color="primary" [mat-dialog-close]="null">
      Cancel
    </button>
  </div>
</div>
<div class="row" *ngIf="isLoading">
  <div class="col-12">
    <div class="d-flex justify-content-center">
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>
  </div>
</div>
