export enum ETORegion {
  'North Coast - 1' = 1,
  'South Coast - 2' = 2,
  'Portland Metro - 3' = 3,
  'Mid-Willamette - 4' = 4,
  'Southern Willamette - 5' = 5,
  'Southern - 6' = 6,
  'Columbia Basin - 7' = 7,
  'Central - 8' = 8,
  'Klamath Basin - 9' = 9,
  'Northeast - 10' = 10,
  'Eastern - 11' = 11,
  'Southwest Washington - 12' = 12,
}

export const ETORegionLabelMapping: Record<ETORegion, string> = {
  [ETORegion['North Coast - 1']]: 'North Coast - 1',
  [ETORegion['South Coast - 2']]: 'South Coast - 2',
  [ETORegion['Portland Metro - 3']]: 'Portland Metro - 3',
  [ETORegion['Mid-Willamette - 4']]: 'Mid-Willamette - 4',
  [ETORegion['Southern Willamette - 5']]: 'Southern Willamette - 5',
  [ETORegion['Southern - 6']]: 'Southern - 6',
  [ETORegion['Columbia Basin - 7']]: 'Columbia Basin - 7',
  [ETORegion['Central - 8']]: 'Central - 8',
  [ETORegion['Klamath Basin - 9']]: 'Klamath Basin - 9',
  [ETORegion['Northeast - 10']]: 'Northeast - 10',
  [ETORegion['Eastern - 11']]: 'Eastern - 11',
  [ETORegion['Southwest Washington - 12']]: 'Southwest Washington - 12',
};
