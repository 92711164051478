import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BaseDataService } from '@/core/services/base-data-service';
import { Observable } from 'rxjs';
import { AnalysisSuiteBackendDict } from '../models/reports/Analysis';

@Injectable({ providedIn: 'root' })
export class AnalysisSuiteService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/analysis_suites/`;

  list(params): Observable<AnalysisSuiteBackendDict[]> {
    return this.http.get<AnalysisSuiteBackendDict[]>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }
}
