import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { StateErrors } from '../../state/shared/base.state';
import { AboveGradeWallBackendDict } from '@/data/simulation/models/enclosure/AboveGradeWall';
import { selectSimulation } from '../../state/simulation/selectors';
import { SimulationBackendDict } from '@/data/simulation/models/simulation';
import { selectSimulationConfigViewSet } from '../../state/simulation-config/selectors';
import { QAFlag } from '@/data/simulation/models/SimulationConfig';
import { updateSimulationConfig } from '../../state/simulation-config/actions';

@Component({
  selector: 'app-simulation-errors-and-warnings',
  templateUrl: './simulation-errors-and-warnings.component.html',
  styleUrls: ['./simulation-errors-and-warnings.component.scss'],
})
export class SimulationErrorsAndWarningsComponent {
  componentDestroyed$ = new Subject();
  aboveGradeWallErrors: StateErrors<AboveGradeWallBackendDict> = {};
  simulation: SimulationBackendDict;
  simulationConfigId: number;
  qaFlags: QAFlag[] = [];

  constructor(public store: Store) {}

  ngOnInit(): void {
    this.store
      .select(selectSimulation())
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(simulation => {
        this.simulation = simulation;
      });

    this.store
      .select(selectSimulationConfigViewSet())
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(({ data, loading }) => {
        this.qaFlags = data.qa_flags;
        this.simulationConfigId = data.id;
      });
  }

  handleQAFlagChange($event: QAFlag[]): void {
    this.store.dispatch(
      updateSimulationConfig({
        id: this.simulationConfigId,
        simulationConfigChanges: {
          qa_flags: $event,
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
