<ng-container *ngIf="initialized; else loading;">
    <ul class="list-inline mb-0">
        <li class="list-inline-item" *ngFor="let affiliation of affiliations; let isLast=last">
            <app-company-display displayType="onlyNameAsLink" [company]="affiliation.sponsor_info"></app-company-display>
            {{ isLast ? '' : ', '}}
        </li>
    </ul>
</ng-container>
<ng-template #loading>
    <div class="row">
        <div class="col-12">
            <div class="d-flex justify-content-center">
                <mat-spinner [diameter]="30"></mat-spinner>
            </div>
        </div>
    </div>
</ng-template>
