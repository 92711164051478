import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { addSlabType } from '@/modules/simulation/state/slab-type/actions';
import { CREATE_MODE, EDIT_MODE } from '@/core/constants';

export class SlabTypeChangeDialogData {
  entityId?: number;
  parentId?: number;
  mode: typeof EDIT_MODE | typeof CREATE_MODE;
  commit?: boolean = false;
  retrieve?: boolean = false;
}

@Component({
  selector: 'app-slab-type-change-dialog',
  templateUrl: './slab-type-change-dialog.component.html',
  styleUrls: ['./slab-type-change-dialog.component.scss'],
})
export class SlabTypeChangeDialogComponent implements OnInit {
  typeSelectForm: FormGroup;
  public editorIsVisible = false;
  public editMode: boolean;
  public slabTypeId: number;
  public initalslabTypeId: number;

  componentDestroyed$ = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SlabTypeChangeDialogData,
    public dialogRef: MatDialogRef<SlabTypeChangeDialogComponent>,
    public store: Store
  ) {
    this.editMode = this.data.mode === EDIT_MODE;
    if (this.data.mode === CREATE_MODE && this.data.entityId) {
      throw new Error(
        'Cannot create a new slab type with an existing slab type id or ' +
          'slab type'
      );
    }

    if (this.data.mode === EDIT_MODE) {
      this.slabTypeId = this.data.entityId;
    }
  }

  initTypeSelectorForm() {
    this.editorIsVisible = false;
    this.typeSelectForm = new FormGroup({
      type: new FormControl(null),
    });

    this.typeSelectForm
      .get('type')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe(slabType => {
        this.store.dispatch(
          addSlabType({ slabId: this.data.parentId, slabType: slabType })
        );

        this.dialogRef.close();
      });
  }

  initslabTypeForm(entityId: number) {
    this.slabTypeId = entityId;
    this.editorIsVisible = true;
  }

  ngOnInit(): void {
    if (this.data.mode === CREATE_MODE) {
      this.initTypeSelectorForm();
    } else {
      this.slabTypeId = this.data.entityId;
      this.editorIsVisible = true;
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
