import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BaseDataService } from '@/core/services/base-data-service';
import { Observable } from 'rxjs';
import { WindowTypeBackendDict } from '../models/enclosure/WindowType';
import { ListRequestParams } from '@/core/schemes/request-params';
import { ServerResponse } from '@/core/schemes/server-response';

export class WindowTypeParams extends ListRequestParams {}

@Injectable({ providedIn: 'root' })
export class WindowTypeService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/window_type/`;
  public parentUrl = `${environment.AXIS_API_V3_ROOT}/window/`;

  list(
    params: WindowTypeParams
  ): Observable<ServerResponse<WindowTypeBackendDict>> {
    return this.http.get<ServerResponse<WindowTypeBackendDict>>(
      `${this.baseUrl}`,
      {
        params: this.rollParams(params),
      }
    );
  }

  create(
    windowId: number,
    windowType: WindowTypeBackendDict
  ): Observable<WindowTypeBackendDict> {
    return this.http.post<WindowTypeBackendDict>(
      `${this.parentUrl}${windowId}/window_type/`,
      windowType
    );
  }

  update(
    windowTypeChanges: Partial<WindowTypeBackendDict>
  ): Observable<WindowTypeBackendDict> {
    return this.http.patch<WindowTypeBackendDict>(
      `${this.baseUrl}${windowTypeChanges.id}/`,
      windowTypeChanges
    );
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}${id}/`);
  }
}
