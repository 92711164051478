import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';
import { FloorPlan } from '@/data/floorplan/models/floorplan';
import { FloorPlanService } from '@/data/floorplan/services/floorplan.service';
import { SimulationService } from '@/data/simulation/services/simulation.service';
import { resetFloorplan } from '@/state/actions/floorplan/floorplan.actions';
import { AppState } from '@/state/reducers';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { User } from '@/data/core/models/user';
import { toggleLoading } from '@/state/actions/app.actions';
import { IRelationshipAssociatedCompaniesEvent } from '@/shared/components/associated-companies/associated-companies.component';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import {
  getFloorplan,
  getFloorplanDocumentsCount,
  getFloorplanHomeStatusesCount,
} from '@/state/selectors/floorplan/floorplan.selector';
import { FloorplanPermissionService } from '@/modules/floorplan/services/floorplan-permission-service';
import {
  FloorplanChangeDialogComponent,
  FloorplanChangeDialogData,
} from '@/modules/floorplan/components/floorplan-change-dialog/floorplan-change-dialog.component';
import { FloorplanSimulationSummary } from '@/data/simulation/models';
import { UntypedFormBuilder } from '@angular/forms';
import { SimulationSourceType } from '@/data/simulation/models/simulation';
import { SimulationResidenceType } from '@/data/simulation/models/simulation';
import { FloorplanSourceTypeLabelMapping } from '@/data/floorplan/models/floorplan';
import { SimulationSourceTypeLabelMapping } from '@/data/simulation/models/simulation';
@Component({
  selector: 'app-floorplan-detail-page',
  templateUrl: './floorplan-detail-page.component.html',
  styleUrls: ['./floorplan-detail-page.component.scss'],
})
export class FloorplanDetailPageComponent implements OnInit, OnDestroy {
  public floorplan: FloorPlan;
  public initialized: boolean;
  public modelClassName = 'floorplan';
  public currentUser: User;
  public selectedIndex = 0;
  public floorplanDocumentsCount = -1;
  public floorplanHomeStatusesCount = -1;
  public floorplanAnalysisCount = -1;
  public simulationSummary?: FloorplanSimulationSummary;
  public simulationSummaryIsLoading = true;

  public editPermissionResponse: Observable<ObjectPermissionResponse>;
  public deletePermissionResponse: Observable<ObjectPermissionResponse>;
  public isEkotropeType = false;
  public is_multi_family: boolean;
  public isSingleDetached = false;
  public residenceType: string;
  public floorplanSourceTypeLabelMap = FloorplanSourceTypeLabelMapping;
  public simulationSourceTypeLabelMap = SimulationSourceTypeLabelMapping;

  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private injector: Injector,
    private activatedRoute: ActivatedRoute,
    private floorPlanService: FloorPlanService,
    private floorplanPermissionService: FloorplanPermissionService,
    private simulationService: SimulationService,
    private dialog: MatDialog,
    private uiHelperService: UIHelperService,
    private fb: UntypedFormBuilder
  ) {
    this.store
      .select(getFloorplan)
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(val => val !== null)
      )
      .subscribe(floorplan => {
        this.floorplan = floorplan;
        this.setupFloorplan();
      });

    this.store
      .select(getFloorplanDocumentsCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(documentsCount => {
        this.floorplanDocumentsCount = documentsCount;
      });

    this.store
      .select(getFloorplanHomeStatusesCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(homeStatusesCount => {
        this.floorplanHomeStatusesCount = homeStatusesCount;
      });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(params => {
        if (params.tab) {
          this.selectedIndex = Number(params.tab) || 0;
        }
      });

    this.activatedRoute.params
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(params => {
        this.store.dispatch(toggleLoading({ payload: true }));
        this.floorPlanService
          .retrieve(params.floorplanId)
          .pipe(takeUntil(this.componentDestroyed$), first())
          .subscribe(
            floorplan => {
              this.floorplan = floorplan;
              this.is_multi_family =
                floorplan?.simulation_info?.location_info?.is_multi_family;
              this.isEkotropeType =
                this.floorplan?.simulation_info?.source_type ===
                SimulationSourceType.ekotrope
                  ? true
                  : false;
              this.isSingleDetached =
                this.floorplan?.simulation_info?.residence_type ===
                SimulationResidenceType.singleDetached
                  ? true
                  : false;

              this.setupFloorplan();
              this.store.dispatch(toggleLoading({ payload: false }));
              this.initialized = true;
            },
            error => this.uiHelperService.handleMainRequestError(error)
          );
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
    this.store.dispatch(resetFloorplan());
  }

  setupFloorplan() {
    this.editPermissionResponse = this.floorplanPermissionService.canEdit(
      this.floorplan
    );
    this.deletePermissionResponse = this.floorplanPermissionService.canDelete(
      this.floorplan
    );
    if (this.floorplan.simulation) {
      this.simulationSummaryIsLoading = true;
      this.simulationService
        .summary(this.floorplan.simulation)
        .pipe(first(), takeUntil(this.componentDestroyed$))
        .subscribe(
          summary => {
            this.simulationSummary = summary;
            this.floorplanAnalysisCount =
              this.simulationSummary?.available_analyses?.length;
            this.simulationSummaryIsLoading = false;
          },
          error => this.uiHelperService.handleUserRequestError(error)
        );
    } else {
      this.floorplanAnalysisCount = 0;
      this.simulationSummaryIsLoading = false;
    }
  }

  onAssociationChange($event: IRelationshipAssociatedCompaniesEvent) {}

  onEdit($event: MouseEvent) {
    $event.preventDefault();
    const dialogData: FloorplanChangeDialogData =
      new FloorplanChangeDialogData();
    dialogData.floorplan = this.floorplan;

    const dialogRef: MatDialogRef<FloorplanChangeDialogComponent> =
      this.dialog.open(FloorplanChangeDialogComponent, {
        width: '45%',
        data: dialogData,
      });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
    });
  }

  onDownloadREMFile($event: MouseEvent) {
    this.store.dispatch(toggleLoading({ payload: true }));
    $event.preventDefault();

    const simulationId = this.floorplan.simulation;

    this.simulationService.remxml(simulationId).subscribe(
      (hpxmlBlob: Blob) => {
        const url = window.URL.createObjectURL(hpxmlBlob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${this.floorplan?.simulation_info?.name}_rem.xml`;
        a.click();
        window.URL.revokeObjectURL(url);
        window.location.reload();
      },
      error => {
        this.uiHelperService.handleUserRequestError(error);
      }
    );
  }

  onDownloadHESHPXML($event: MouseEvent) {
    this.store.dispatch(toggleLoading({ payload: true }));
    $event.preventDefault();

    const simulationId = this.floorplan.simulation;
    const hpxmlType = 'hes';

    this.simulationService.hpxml(simulationId, hpxmlType).subscribe(
      (hpxmlBlob: Blob) => {
        const url = window.URL.createObjectURL(hpxmlBlob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${simulationId}.hes.hpxml.xml`;
        a.click();
        window.URL.revokeObjectURL(url);
        window.location.reload();
      },
      error => {
        this.uiHelperService.handleUserRequestError(error);
      }
    );
  }

  onDownloadOSERIHPXML($event: MouseEvent) {
    this.store.dispatch(toggleLoading({ payload: true }));
    $event.preventDefault();

    const simulationId = this.floorplan.simulation;
    const hpxmlType = 'oseri';

    this.simulationService.hpxml(simulationId, hpxmlType).subscribe(
      (hpxmlBlob: Blob) => {
        const url = window.URL.createObjectURL(hpxmlBlob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${simulationId}.oseri.hpxml.xml`;
        a.click();
        window.URL.revokeObjectURL(url);
        window.location.reload();
      },
      error => {
        this.uiHelperService.handleUserRequestError(error);
      }
    );
  }

  onDelete($event: MouseEvent) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '50%',
      data: {
        title: 'Do you want to delete this floorplan?',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (!result) {
          return;
        }
        this.store.dispatch(toggleLoading({ payload: true }));
        this.floorPlanService
          .delete(this.floorplan.id)
          .pipe(takeUntil(this.componentDestroyed$), first())
          .subscribe(
            _ => {
              this.store.dispatch(toggleLoading({ payload: false }));
              this.router.navigate(['/', 'floorplans']);
            },
            error => this.uiHelperService.handleUserRequestError(error)
          );
      });
  }
}
