import { Injectable } from '@angular/core';
import { ListRequestParams } from '@/core/schemes/request-params';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { ServerResponse } from '@/core/schemes/server-response';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import { RemRateUser } from '@/data/remrate/models';

export class RemRateUserRequestParams extends ListRequestParams {}

@Injectable({
  providedIn: 'root',
})
export class RemRateUserService extends BaseDataService {
  baseUrl = `${environment.AXIS_API_V3_ROOT}/remrate_users/`;

  list(
    params?: RemRateUserRequestParams
  ): Observable<ServerResponse<RemRateUser>> {
    return this.http.get<ServerResponse<RemRateUser>>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }

  retrieve(
    remRateUserId: number,
    options?: IDataServiceMethodOptions
  ): Observable<RemRateUser> {
    const ignoreStore = options?.ignoreStore;
    const chain = this.http.get<RemRateUser>(`${this.baseUrl}${remRateUserId}`);
    return chain;
  }

  update(
    remRateUserId: number,
    remRateUser: RemRateUser
  ): Observable<RemRateUser> {
    return this.http.patch<RemRateUser>(
      `${this.baseUrl}${remRateUserId}/`,
      remRateUser
    );
  }

  delete(remRateUserId: number) {
    return this.http.delete(`${this.baseUrl}${remRateUserId}/`);
  }
}
