import { AppState } from '../reducers';
import { createSelector } from '@ngrx/store';

export const selectLoadingState = (state: AppState) => state.application;
export const getLoading = createSelector(
  selectLoadingState,
  store => store.loading
);
export const getBooting = createSelector(
  selectLoadingState,
  store => store.booting
);
