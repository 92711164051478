import {
  HeaterBackendDict,
  createHeaterForm,
} from '@/data/simulation/models/mechanicals/Heater';
import { BaseValidator } from './base.validator';
import { ModelErrors, StateErrors } from '../state/shared/base.state';

export class HeaterValidator extends BaseValidator {
  static validateForm(
    heater: HeaterBackendDict
  ): ModelErrors<HeaterBackendDict> {
    const form = createHeaterForm(heater);
    return form.getFieldAndModelErros();
  }

  static validate(
    heaters: HeaterBackendDict[]
  ): StateErrors<HeaterBackendDict> {
    const errors = {};
    heaters.forEach(heater => {
      errors[heater.id] = this.validateForm(heater);
    });
    return errors;
  }
}
