<div class="container" *ngIf="initialized">
    <div class="row mt-3">
        <div class="col-8">
            <h5>
                {{ verifierResourcesPage?.title }}
            </h5>
        </div>
        <div class="col-4 text-right">
            <button mat-raised-button color="accent" [routerLink]="['/', 'flatpages', verifierResourcesPage?.id, 'edit']" *ngIf="currentUser?.is_superuser || currentUser?.company_info?.slug === customerHIRLSettings.companySlug">
                Edit page
            </button>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div [innerHTML]="verifierResourcesPage?.content | safe: 'text'">
            </div>
        </div>
    </div>
</div>