import { LocationLabels } from '@/data/simulation/enumerations';
import {
  EquipmentLocation,
  EquipmentType,
  EquipmentTypeLabels,
  MechanicalEquipmentBackendDict,
  createMechanicalEquipmentForm,
} from '@/data/simulation/models/mechanicals/MechanicalEquipment';
import {
  removeMechanicalEquipment,
  updateMechanicalEquipment,
} from '@/modules/simulation/state/mechanical-equipment/actions';
import {
  selectAtahcedEquipmentNameAndError,
  selectMechanicalEquipmentViewSet,
} from '@/modules/simulation/state/mechanical-equipment/selectors';
import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import {
  EquipmentDetailsChangeDialogComponent,
  EquipmentDetailsChangeDialogData,
} from '../equipment-details-change-dialog/equipment-details-change-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DIALOG_WIDTH } from '@/modules/simulation/constants';
import { EDIT_MODE } from '@/core/constants';
import { first, takeUntil } from 'rxjs/operators';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-mechanical-equipment',
  templateUrl: './mechanical-equipment.component.html',
  styleUrls: ['./mechanical-equipment.component.scss'],
})
export class MechanicalEquipmentComponent {
  @Input() entityId: number;

  equipmentForm: FormGroup;
  entity: MechanicalEquipmentBackendDict;
  loading: boolean;

  private componentDestroyed$ = new Subject();
  attachedEquipmentType: EquipmentType;
  attachedEquipmentId: number;

  locationLabels = {};
  equipmentTypeLabels = EquipmentTypeLabels;

  equipmentModelName = '';
  equipmentErrorCount: number;

  constructor(public dialog: MatDialog, public store: Store) {
    Object.values(EquipmentLocation).forEach(value => {
      this.locationLabels[value] = LocationLabels[value];
    });
  }

  ngOnInit(): void {
    this.store
      .select(selectMechanicalEquipmentViewSet(this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(({ data, loading }) => {
        this.entity = data;
        this.loading = loading;
        if (loading) return;

        if (this.equipmentForm) {
          this.equipmentForm.patchValue(data);
        } else {
          this.equipmentForm = createMechanicalEquipmentForm(data);
        }

        this.updateAttachedEquipmentDetails();
        this.store
          .select(
            selectAtahcedEquipmentNameAndError(
              this.attachedEquipmentType,
              this.attachedEquipmentId
            )
          )
          .pipe(first())
          .subscribe(data => {
            this.equipmentModelName = data.name;
            this.equipmentErrorCount = data.errorCount;
          });
      });
  }

  openEditEquipmentDetailsChangeDialog(data: EquipmentDetailsChangeDialogData) {
    return this.dialog.open(EquipmentDetailsChangeDialogComponent, {
      width: DIALOG_WIDTH,
      disableClose: true,
      data,
    });
  }

  updateAttachedEquipmentDetails() {
    let equipment = [];
    if (this.entity.air_conditioner !== null) {
      equipment = [EquipmentType.AIR_CONDITIONER, this.entity.air_conditioner];
    } else if (this.entity.air_source_heat_pump !== null) {
      equipment = [
        EquipmentType.AIR_SOURCE_HEAT_PUMP,
        this.entity.air_source_heat_pump,
      ];
    } else if (this.entity.dehumidifier !== null) {
      equipment = [EquipmentType.DEHUMIDIFIER, this.entity.dehumidifier];
    } else if (this.entity.ground_source_heat_pump !== null) {
      equipment = [
        EquipmentType.GROUND_SOURCE_HEAT_PUMP,
        this.entity.ground_source_heat_pump,
      ];
    } else if (this.entity.heater !== null) {
      equipment = [EquipmentType.HEATER, this.entity.heater];
    } else if (this.entity.water_heater !== null) {
      equipment = [EquipmentType.WATER_HEATER, this.entity.water_heater];
    }
    this.attachedEquipmentType = equipment[0];
    this.attachedEquipmentId = equipment[1];
  }

  editEquipmentDetails() {
    const updatedEquipmentDetails = this.openEditEquipmentDetailsChangeDialog({
      equipmentType: this.attachedEquipmentType,
      mode: EDIT_MODE,
      entityId: this.attachedEquipmentId,
    });

    updatedEquipmentDetails.afterClosed().subscribe(equipmentDetails => {
      if (!equipmentDetails) {
        return;
      }
    });
  }

  onSave(
    mechanicalEquipmentChanges: Partial<MechanicalEquipmentBackendDict>
  ): void {
    this.store.dispatch(
      updateMechanicalEquipment({
        id: this.entityId,
        mechanicalEquipmentChanges: mechanicalEquipmentChanges,
      })
    );
  }

  removeEquipment() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '50%',
      data: {
        title: `Do you want to delete "${this.entity.name}" ${
          this.equipmentTypeLabels[this.attachedEquipmentType]
        }?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (result) {
          this.store.dispatch(
            removeMechanicalEquipment({
              mechanicalEquipment: this.entity,
            })
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
