import { ListRequestParams } from '@/core/schemes/request-params';
import { Injectable } from '@angular/core';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { PracticeStandard } from '@/data/customer-hirl/models/practice-standard';
import { ServerResponse } from '@/core/schemes/server-response';

export class PracticeStandardRequestParams extends ListRequestParams {
  practice_item_product_related?: boolean;
  is_active?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class PracticeStandardService extends BaseDataService {
  baseUrl = `${environment.AXIS_API_V3_ROOT}/practice_standards/`;

  list(params?: PracticeStandardRequestParams) {
    return this.http.get<ServerResponse<PracticeStandard>>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }

  retrieve(
    practiceStandardId: number,
    options?: IDataServiceMethodOptions
  ): Observable<PracticeStandard> {
    return this.http.get<PracticeStandard>(
      `${this.baseUrl}${practiceStandardId}`,
      {}
    );
  }

  update(practiceStandard: PracticeStandard, practiceStandardId: number) {
    return this.http.patch<PracticeStandard>(
      `${this.baseUrl}${practiceStandardId}/`,
      practiceStandard
    );
  }

  delete(practiceStandardId: number) {
    return this.http.delete(`${this.baseUrl}${practiceStandardId}`);
  }
}
