<div class="search-input-container">
    <mat-form-field appearance="none" class="search-input" (focusin)="onFocusIn($event)" (focusout)="onFocusOut($event)">
        <input matInput
               type="text"
               autocomplete="off"
               [placeholder]="placeholder"
               [formControl]="searchControl"
               (keyup)="applySearch(searchInput.value)"
               #searchInput>
        <a *ngIf="searchControl.value" class="clear-btn" matSuffix mat-icon-button aria-label="Clear">
            <mat-icon class="clear-btn">close</mat-icon>
        </a>
    </mat-form-field>
</div>
