import {
  EditorNavigationService,
  SubTab,
} from '@/modules/simulation/services/editor-navigation.service';
import { selectSimulation } from '@/modules/simulation/state/simulation/selectors';
import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-general-info-tab',
  templateUrl: './general-info-tab.component.html',
  styleUrls: ['./general-info-tab.component.scss'],
})
export class GeneralInfoTabComponent {
  projectId: number;
  locationId: number;
  simulationId: number;
  activeSubTabLink: string;
  subTabs = SubTab;
  componentDestroyed$ = new Subject();

  constructor(
    public store: Store,
    public editorNavigationService: EditorNavigationService
  ) {}

  ngOnInit(): void {
    this.store
      .select(selectSimulation())
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(simulation => {
        this.projectId = simulation.project;
        this.locationId = simulation.location;
        this.simulationId = simulation.id;
      });

    this.editorNavigationService
      .getActiveSubTabLinkObservable()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(subTab => {
        this.activeSubTabLink = subTab;

        if ([SubTab.PROJECT, SubTab.FUELS].includes(subTab)) {
          setTimeout(() => {
            document.getElementById(subTab)?.focus();
          }, 100);
        }
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
