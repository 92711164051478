import {
  AreaUnitLabels,
  LengthUnitLabels,
  StudTypeLabels,
  WallCavityInsulationTypeLabels,
} from '@/data/simulation/enumerations';
import {
  AboveGradeWallTypeBackendDict,
  AboveGradeWallTypeFormGroup,
  FIELD_LABELS,
} from '@/data/simulation/models/enclosure/AboveGradeWallType';
import { INSULATION_GRADE_LABELS } from '@/data/simulation/models/enclosure/Material';

import { updateAboveGradeWallType } from '@/modules/simulation/state/above-grade-wall-type/actions';
import { selectAboveGradeWallViewSet } from '@/modules/simulation/state/above-grade-wall-type/selectors';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-above-grade-wall-type-form',
  templateUrl: './above-grade-wall-type-form.component.html',
  styleUrls: ['./above-grade-wall-type-form.component.scss'],
})
export class AboveGradeWallTypeFormComponent implements OnInit {
  @Input() entityId: number;
  fieldLabels = FIELD_LABELS;

  lengthLabels = LengthUnitLabels;
  areaLabels = AreaUnitLabels;

  private componentDestroyed$ = new Subject();

  loading = false;
  entity: AboveGradeWallTypeBackendDict = null;
  aboveGradeWallTypeFormGroup: FormGroup;
  wallCavityInsulationTypeLabels = WallCavityInsulationTypeLabels;
  studTypeLabels = StudTypeLabels;
  insulationGrade = INSULATION_GRADE_LABELS;

  constructor(public store: Store) {}

  ngOnInit() {
    this.store
      .select(selectAboveGradeWallViewSet(this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(({ data, loading }) => {
        this.loading = loading;
        if (this.loading) {
          return;
        }

        if (this.aboveGradeWallTypeFormGroup) {
          this.aboveGradeWallTypeFormGroup.patchValue(data);
        } else {
          this.aboveGradeWallTypeFormGroup = new AboveGradeWallTypeFormGroup(
            data
          );

          const controls = this.aboveGradeWallTypeFormGroup.controls;
          controls['user_defined_assembly_u_value'].valueChanges.subscribe(
            value => {
              if (value) {
                controls.assembly_u_value.enable({ emitEvent: false });
              } else {
                controls.assembly_u_value.disable({ emitEvent: false });
              }
            }
          );
        }
        this.entity = data;
      });
  }

  handleSystemUValue($event: any) {
    if (
      !this.aboveGradeWallTypeFormGroup.controls.user_defined_assembly_u_value
        .value
    ) {
      this.aboveGradeWallTypeFormGroup.controls.assembly_u_value.patchValue(
        $event,
        { emitEvent: false }
      );
    }
  }

  saveAboveGradeWallType(
    changedValues: Partial<AboveGradeWallTypeBackendDict>
  ) {
    const newValues = this.aboveGradeWallTypeFormGroup.value;
    const changes = {};
    Object.entries(this.entity).forEach(([key, val]) => {
      if (!this.entity.hasOwnProperty(key)) return;
      if (val !== newValues[key]) changes[key] = newValues[key];
    });
    this.store.dispatch(
      updateAboveGradeWallType({
        wallTypeChanges: {
          ...changes,
          id: this.entityId,
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
