import {
  DetailedSlabBackendDict,
  SlabBackendDict,
  createSlabForm,
  isCrawlSpaceLocation,
} from '@/data/simulation/models/enclosure/Slab';
import {
  BaseValidator,
  ValidationErrors,
  ValidationResult,
} from './base.validator';
import { ModelErrors, StateErrors } from '../state/shared/base.state';

export class SlabValidator extends BaseValidator {
  static validateForm(slab: SlabBackendDict): ModelErrors<SlabBackendDict> {
    const form = createSlabForm(slab);
    return form.getFieldAndModelErros();
  }

  static validateCrawlspaceSlabThickness(
    slab: DetailedSlabBackendDict
  ): ValidationResult[] {
    const thickness = slab.type_info?.thickness;
    const result = {
      id: 'invalidCrawlspaceSlabThickness',
      validationSuccess: true,
      data: {
        slabName: slab.name,
        slabThickness: thickness,
      },
    };

    // Filter slabs that are in crawlspace locations
    if (isCrawlSpaceLocation(slab.interior_location) && thickness !== 0) {
      result.validationSuccess = false;
    }
    return [result];
  }

  static validate(
    slabs: (SlabBackendDict | DetailedSlabBackendDict)[]
  ): StateErrors<SlabBackendDict> {
    const errors = {};
    slabs.forEach(slab => {
      const validationErrors = new ValidationErrors(this.validateForm(slab));
      validationErrors.addValidationResults(
        this.validateCrawlspaceSlabThickness(slab as DetailedSlabBackendDict)
      );
      errors[slab.id] = validationErrors.errors;
    });
    return errors;
  }
}
