import { FloorplanInfoBackendDict } from '@/data/floorplan/models';
import { EEPProgramHomeStatusInfo } from '@/data/home/models';
import { ModelFormControl, ModelFormGroup } from './ModelForm';
import { Validators } from '@angular/forms';

export enum SubmitToAxisRequestSubmitType {
  floorplan = 'floorplan',
  home = 'home',
}

export const SubmitToAxisRequestSubmitTypeLabels: Record<
  SubmitToAxisRequestSubmitType,
  string
> = {
  [SubmitToAxisRequestSubmitType.home]: 'Home/MF Unit',
  [SubmitToAxisRequestSubmitType.floorplan]:
    'Floorplan (to be used for multiple SF homes or MF units)',
};

export enum SubmitToAxisRequestEEPProgramType {
  energy_star_new_homes = 'energy_star_new_homes',
  doe_zero_energy_ready_home = 'doe_zero_energy_ready_home',
  indoor_airplus = 'indoor_airplus',
  water_sense = 'water_sense',
  energy_code_compliance = 'energy_code_compliance',
}

export const SubmitToAxisRequestEEPProgramTypeLabels: Record<
  SubmitToAxisRequestEEPProgramType,
  string
> = {
  [SubmitToAxisRequestEEPProgramType.energy_star_new_homes]:
    'ENERGY STAR® New Homes',
  [SubmitToAxisRequestEEPProgramType.doe_zero_energy_ready_home]:
    'DOE Zero Energy Ready Homes',
  [SubmitToAxisRequestEEPProgramType.indoor_airplus]: 'Indoor airPLUS®',
  [SubmitToAxisRequestEEPProgramType.water_sense]: 'WaterSense®',
  [SubmitToAxisRequestEEPProgramType.energy_code_compliance]:
    'Energy Code Compliance',
};

export interface SubmitToAxisRequestBackendDict {
  submit_type: SubmitToAxisRequestSubmitType;
  floorplan_name?: string;
  eep_program_type?: SubmitToAxisRequestEEPProgramType;
  permit_date?: string;

  builder_organization?: number;
  rater_organization?: number;
  rater_of_record?: number;
  energy_modeler?: number;

  geocode?: number;
  geocode_response?: number;
}

export interface SubmitToAxisResponseBackendDict {
  floorplan_info?: FloorplanInfoBackendDict;
  home_status_info?: EEPProgramHomeStatusInfo;
}

export const createSubmitFloorplanForm = name => {
  return new ModelFormGroup({
    name: new ModelFormControl(name, [Validators.required]),
  });
};
export const createSubmitHomeForm = (
  raterOrgId: number,
  raterOfRecordId: number,
  builderOrgId: number,
  permitDate: Date
) => {
  return new ModelFormGroup({
    eep_program_type: new ModelFormControl(''),
    permit_date: new ModelFormControl(permitDate, [Validators.required]),
    address: new ModelFormControl(null, [Validators.required]),
    builder_organization_info: new ModelFormControl(builderOrgId, [
      Validators.required,
    ]),
    rater_organization_info: new ModelFormControl(raterOrgId, [
      Validators.required,
    ]),
    rater_of_record_info: new ModelFormControl(raterOfRecordId, [
      Validators.required,
    ]),
  });
};

export const createFloorplanFromSimulationForm = (
  name: string,
  rating_type
) => {
  return new ModelFormGroup({
    name: new ModelFormControl(name, [Validators.required]),
    rating_type: new ModelFormControl(rating_type, [Validators.required]),
  });
};

type ProgramRequirement = {
  startDate: Date;
  endDate: Date;
  program: string;
};

const programRequirements: Record<string, ProgramRequirement[]> = {
  'AL, AK, AZ, AR, CO, IN, ID, KS, KY, LA, MS, MO, NH, NC, ND, OH, OK, SC, SD, TN, WV, WI, WY':
    [
      {
        startDate: new Date(2024, 0, 1),
        endDate: new Date(2025, 0, 1),
        program: 'energy-star-sfnh-national-v31-rev-12',
      },
      {
        startDate: new Date(2025, 0, 1),
        endDate: new Date(2026, 0, 1),
        program: 'energy-star-sfnh-national-v31-rev-13',
      },
    ],
  'DC, DE, IA, MA, MI, MN, MT, NE, NV, NY, PA, RI, TX': [
    {
      startDate: new Date(2024, 0, 1),
      endDate: new Date(2025, 0, 1),
      program: 'energy-star-sfnh-national-v31-rev-12',
    },
    {
      startDate: new Date(2025, 0, 1),
      endDate: new Date(2026, 0, 1),
      program: 'energy-star-sfnh-national-v31-rev-13',
    },
  ],
  'GA, NM, UT': [
    {
      startDate: new Date(2024, 0, 1),
      endDate: new Date(2025, 0, 1),
      program: 'energy-star-sfnh-national-v31-rev-12',
    },
    {
      startDate: new Date(2025, 0, 1),
      endDate: new Date(2026, 0, 1),
      program: 'energy-star-sfnh-national-v31-rev-13',
    },
  ],
  ME: [
    {
      startDate: new Date(2024, 0, 1),
      endDate: new Date(2025, 0, 1),
      program: 'energy-star-sfnh-national-v31-rev-12',
    },
    {
      startDate: new Date(2025, 0, 1),
      endDate: new Date(2026, 0, 1),
      program: 'energy-star-sfnh-national-v31-rev-13',
    },
  ],
  FL: [
    {
      startDate: new Date(2024, 0, 1),
      endDate: new Date(2025, 0, 1),
      program: 'energy-star-sfnh-national-v31-rev-12',
    },
    {
      startDate: new Date(2025, 0, 1),
      endDate: new Date(2026, 0, 1),
      program: 'energy-star-sfnh-national-v31-rev-13',
    },
  ],
  'OR, WA': [
    {
      startDate: new Date(2022, 0, 1),
      endDate: new Date(2023, 0, 1),
      program: 'energy-star-sfnh-or-wa-v32-rev-11',
    },
    {
      startDate: new Date(2023, 0, 1),
      endDate: new Date(2024, 0, 1),
      program: 'energy-star-sfnh-or-wa-v32-rev-11',
    },
    {
      startDate: new Date(2024, 0, 1),
      endDate: new Date(2025, 0, 1),
      program: 'energy-star-sfnh-or-wa-v32-rev-12',
    },
    {
      startDate: new Date(2025, 0, 1),
      endDate: new Date(2026, 0, 1),
      program: 'energy-star-sfnh-or-wa-v32-rev-12',
    },
  ],
  'HI, GU, NMI': [
    {
      startDate: new Date(2024, 0, 1),
      endDate: new Date(2025, 0, 1),
      program: 'energy-star-sfnh-pacific-v3-rev-12',
    },
  ],
  'PR, USVI': [
    {
      startDate: new Date(2024, 0, 1),
      endDate: new Date(2025, 0, 1),
      program: 'energy-star-sfnh-caribbean-v3-rev-12',
    },
  ],
  'CT, MD, NJ, VT': [
    {
      startDate: new Date(2024, 0, 1),
      endDate: new Date(2025, 0, 1),
      program: 'energy-star-sfnh-national-v31-rev-12',
    },
    {
      startDate: new Date(2025, 0, 1),
      endDate: new Date(2026, 0, 1),
      program: 'energy-star-sfnh-national-v32-rev-13',
    },
  ],
  VA: [
    {
      startDate: new Date(2024, 0, 1),
      endDate: new Date(2025, 0, 1),
      program: 'energy-star-sfnh-national-v31-rev-12',
    },
    {
      startDate: new Date(2025, 0, 1),
      endDate: new Date(2026, 0, 1),
      program: 'energy-star-sfnh-national-v31-rev-13',
    },
    {
      startDate: new Date(2026, 0, 1),
      endDate: new Date(2027, 0, 1),
      program: 'energy-star-sfnh-national-v31-rev-13',
    },
  ],
  IL: [
    {
      startDate: new Date(2024, 0, 1),
      endDate: new Date(2025, 0, 1),
      program: 'energy-star-sfnh-national-v31-rev-12',
    },
    {
      startDate: new Date(2025, 0, 1),
      endDate: new Date(2026, 0, 1),
      program: 'energy-star-sfnh-national-v31-rev-13',
    },
    {
      startDate: new Date(2026, 0, 1),
      endDate: new Date(2027, 0, 1),
      program: 'energy-star-sfnh-national-v32-rev-13',
    },
  ],
};

export function findProgram(stateCode: string, date: Date): string | null {
  for (const [stateGroup, programs] of Object.entries(programRequirements)) {
    const states = stateGroup.split(', ').map(state => state.trim());
    if (states.includes(stateCode)) {
      for (const program of programs) {
        if (date >= program.startDate && date < program.endDate) {
          return program.program;
        }
      }
    }
  }
  return null; // Return null if no program is found
}
