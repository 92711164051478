export class COIDocumentRequestParams {
  search?: string;
  page?: number;
  ordering?: string;
  is_active?: boolean;

  constructor(page: number, search: string, ordering: string) {
    this.page = page;
    this.search = search;
    this.ordering = ordering;
  }
}

export class HIRLProjectAddressIsUniqueRequestData {
  project?: string;
  street_line1: string;
  street_line2?: string;
  is_multi_family: boolean;
  city: number;
  zipcode: string;
  geocode_response: number;
}
