<div class="position-relative">
    <app-loading-overlay diameter="50" *ngIf="loading"></app-loading-overlay>
    <app-form formName="seasonalRateForm" [formGroup]="seasonalRateForm" (save)="saveSeasonalRate($event)">
        <div>
            <app-select formControlName="start_day" label="Start day" [items]="startDateLabel"></app-select>
            <app-select formControlName="start_month" [items]="monthLabel" label="Start Month"></app-select>
        </div>
        <div>
            <app-select formControlName="end_day" label="End" [items]="endDateLabel" label="End day"></app-select>
            <app-select formControlName="end_month" [items]="monthLabel" label="End Month"></app-select>
        </div>
    </app-form>
</div>

<h4 class="text-center">Block rates</h4>
<div class="d-flex justify-content-between pr-5 pl-5">
    <strong>Min consumption</strong>
    <strong>Max consumption</strong>
    <strong>Cost</strong>
</div>
<ul>
    <li *ngFor="let blockRate of entity.block_rates">
        <app-block-rate [entityId]="blockRate"></app-block-rate>
    </li>
</ul>
