<div class="card mb-2">
    <div class="card-header">
        <div class="row">
            <div class="col-12">
                <strong>
                    Project Info: {{sourceTypeLabels[entity?.source_type]}}&nbsp;
                    <ng-container *ngIf="entity?.status !== 'ready'">
                        [{{statusTypeLabels[entity?.status]}}]
                    </ng-container>
                </strong>
            </div>
        </div>
    </div>
    <div class="card-body pb-0">
        <div class="position-relative">
            <app-loading-overlay *ngIf="loading"></app-loading-overlay>
            <div class="row">
                <div class="col-12">
                    <app-form formName="simulationForm" [formGroup]="form" (save)="onSave($event)">
                        <div>
                            <app-input formControlName="name" [label]="fieldLabels.name"></app-input>
                        </div>
                        <div>
                            <app-select formControlName="residence_type" [label]="fieldLabels.residence_type" [items]="residenceTypeLabels"></app-select>
                            <app-input [hidden]="!isResidencyTypeDuplexOrMultiFamily()"
                                        formControlName="unit_count"
                                        type="number"
                                        [label]="fieldLabels.unit_count">
                            </app-input>
                        </div>
                        <div>
                            <app-input formControlName="bedroom_count" [label]="fieldLabels.bedroom_count" type="number"></app-input>
                            <app-input formControlName="floors_on_or_above_grade" [label]="fieldLabels.floors_on_or_above_grade" type="number"></app-input>
                        </div>
                        <div>
                            <app-input formControlName="conditioned_area" [label]="fieldLabels.conditioned_area" type="expression" unit="{{areaLabels['sq_ft']}}"></app-input>
                            <app-input formControlName="conditioned_volume" [label]="fieldLabels.conditioned_volume" type="expression" unit="{{volumeLabels['cu_ft']}}"></app-input>
                        </div>
                    </app-form>
                </div>
            </div>

        </div>

        <div class="division-line"></div>

        <div class="position-relative">
            <app-project [entityId]="entity?.project"></app-project>
        </div>
    </div>
    <div class="card-footer p-0">
        <app-model-action-buttons
          [entityId]="entity?.project"
          [options]="['history']"
          modelName="project">
        </app-model-action-buttons>
    </div>
</div>
