<mat-expansion-panel>
    <mat-expansion-panel-header>
        <mat-panel-title>
            <strong>Fuel Usages</strong>
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
        <div class="table-container">
            <table mat-table [dataSource]="fuelUsages" class="mat-elevation-z8">
                <ng-container *ngFor="let cell of displayedColumns;">
                    <ng-container matColumnDef="{{cell}}">
                        <th mat-header-cell *matHeaderCellDef> {{columnLabels[cell]}} </th>
                        <td mat-cell *matCellDef="let element" class="data">
                            <ng-container [ngSwitch]="cell">
                                <ng-container *ngSwitchCase="'fuel'">
                                    {{ fuelTypeLabel[element[cell]] }}
                                </ng-container>
                                <ng-container *ngSwitchCase="'cost'">
                                    {{ element[cell] | currency: 'USD':'symbol':'1.2-2' }}
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    {{ element[cell] | number: '1.2-2' }}
                                </ng-container>
                            </ng-container>
                        </td>
                    </ng-container>
                </ng-container>
                <!-- Table Header and Row Declarations -->
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </ng-template>
</mat-expansion-panel>
