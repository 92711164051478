import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import * as BlockRateActions from './actions';
import * as SeasonalRateActions from '../seasonal-rate/actions';
import * as SharedActions from '../shared/shared.actions';
import { BlockRateService } from '@/data/simulation/services/block-rate.service';
import { BlockRateValidator } from '../../validators/block-rate.validator';

@Injectable()
export class BlockRateEffects {
  loadDetailedBlockRates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BlockRateActions.loadBlockRates),
      mergeMap(action => {
        const errors = BlockRateValidator.validate(action.blockRates);
        return of(
          BlockRateActions.loadBlockRatesSuccess({
            blockRates: action.blockRates,
            errors: errors,
          })
        );
      })
    );
  });

  updateBlockRate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BlockRateActions.updateBlockRate),
      mergeMap(action =>
        this.blockrateService.update(action.blockRateChanges).pipe(
          mergeMap(updatedFloor => {
            const errors = BlockRateValidator.validate([updatedFloor]);
            return of(
              BlockRateActions.updateBlockRateSuccess({
                blockRate: updatedFloor,
                errors: errors[updatedFloor.id],
              })
            );
          }),
          catchError(error =>
            of(
              BlockRateActions.updateBlockRateFailure({
                id: action.blockRateChanges.id,
              }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        )
      )
    );
  });

  removeBlockRate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BlockRateActions.removeBlockRate),
      mergeMap(action =>
        this.blockrateService.delete(action.blockRate.id).pipe(
          mergeMap(() =>
            of(
              BlockRateActions.removeBlockRateSuccess({
                id: action.blockRate.id,
              }),
              SeasonalRateActions.removeBlockRateSuccess({
                id: action.blockRate.id,
              })
            )
          ),
          catchError(error =>
            of(
              BlockRateActions.removeBlockRateFailure({
                id: action.blockRate.id,
              }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private blockrateService: BlockRateService
  ) {}
}
