import { FieldLabels } from '../base';
import { BlockRateBackendDict } from './BlockRate';
import { ModelFormGroup, ModelFormControl } from '../ModelForm';
import { Validators } from '@angular/forms';

export const FIELD_LABELS: FieldLabels<SeasonalRateBackendDict> = {
  start_month: 'Start Month',
  start_day: 'Start Day',
  start_hour: 'Start Hour',
  start_minute: 'Start Minute',
  end_month: 'End Month',
  end_day: 'End Day',
  end_hour: 'End Hour',
  end_minute: 'End Minute',
  service_charge: 'Service Charge',
  block_rates: 'Block Rates',
};

export interface SeasonalRateBackendDict {
  id: number;
  start_month: number;
  start_day: number;
  start_hour: number;
  start_minute: number;
  end_month: number;
  end_day: number;
  end_hour: number;
  end_minute: number;
  service_charge: number;
  block_rates: number[];
}

export interface DetailedSeasonalRateBackendDict
  extends SeasonalRateBackendDict {
  block_rates_info: BlockRateBackendDict[];
}

export function createSeasonalRateForm(
  data: SeasonalRateBackendDict
): ModelFormGroup {
  return new ModelFormGroup(
    {
      id: new ModelFormControl(data.id, Validators.required),
      start_month: new ModelFormControl(data.start_month, Validators.required),
      start_day: new ModelFormControl(data.start_day, Validators.required),
      start_hour: new ModelFormControl(data.start_hour, Validators.required),
      start_minute: new ModelFormControl(
        data.start_minute,
        Validators.required
      ),
      end_month: new ModelFormControl(data.end_month, Validators.required),
      end_day: new ModelFormControl(data.end_day, Validators.required),
      end_hour: new ModelFormControl(data.end_hour, Validators.required),
      end_minute: new ModelFormControl(data.end_minute, Validators.required),
      service_charge: new ModelFormControl(
        data.service_charge,
        Validators.required
      ),
      block_rates: new ModelFormControl(data.block_rates, Validators.required),
    },
    {
      validators: [
        (controls: ModelFormGroup) => {
          const startMonth = controls.get('start_month').value;
          const endMonth = controls.get('end_month').value;
          const startDay = controls.get('start_day').value;
          const endDay = controls.get('end_day').value;
          const startHour = controls.get('start_hour').value;
          const endHour = controls.get('end_hour').value;
          const startMinute = controls.get('start_minute').value;
          const endMinute = controls.get('end_minute').value;

          if (startMonth === endMonth) {
            if (startDay > endDay) {
              return { invalidDay: true };
            } else if (startDay === endDay) {
              if (startHour > endHour) {
                return { invalidHour: true };
              } else if (startHour === endHour) {
                if (startMinute >= endMinute) {
                  return { invalidMinute: true };
                }
              }
            }
          }
        },

        (controls: ModelFormGroup) => {
          const blockRates = controls.get('block_rates').value;
          if (blockRates.length === 0) {
            return { invalidBlockRates: true };
          }
        },
      ],
    }
  );
}
