<form [formGroup]="loginForm" (ngSubmit)="login()">
    <h4 class="mb-2">Login</h4>
    <div class="alert alert-danger"
        *ngIf="error">
        Please enter a correct username and password. Note that both fields may be case-sensitive.
    </div>
    <div class="form-group mb-2">
        <input type="text" 
                formControlName="username" 
                class="form-control w-100" 
                [ngClass]="{ 'is-invalid': submitted && f.username.errors }" 
                placeholder="Email Address or Username"/>
        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
            <div *ngIf="f.username.errors.required">Username is required</div>
        </div>
    </div>
    <div class="form-group mb-2">
        <input type="password" 
                formControlName="password" 
                class="form-control w-100" 
                [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                placeholder="Password" />
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
            <div *ngIf="f.password.errors.required">Password is required</div>
        </div>
    </div>
    <button [disabled]="loading" class="btn btn-primary mr-5" style="background-color: #bf0d25;">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        Sign in
    </button>
    <!-- <span style="color: #ccc;">Forget Password?</span> -->
</form>