import { Injectable } from '@angular/core';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import { environment } from '../../../../environments/environment';

import { ListRequestParams } from '@/core/schemes/request-params';
import { ServerResponse } from '@/core/schemes/server-response';
import { ApexRequest } from '@/data/home/models/apex-request';
import { tap } from 'rxjs/operators';
import { loadApexRequest } from '@/state/actions/home/apex-request.actions';

export class ApexRequestDocumentRequestParams extends ListRequestParams {
  id?: string | number;
}

@Injectable({
  providedIn: 'root',
})
export class ApexRequestDocumentService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/apex_request_document/`;

  list(params?: ApexRequestDocumentRequestParams) {
    return this.http.get<ServerResponse<ApexRequest>>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }

  retrieve(userId: number, options?: IDataServiceMethodOptions) {
    const ignoreStore = options?.ignoreStore;
    let chain = this.http.get<ApexRequest>(`${this.baseUrl}${userId}`, {});
    if (!ignoreStore) {
      chain = chain.pipe(
        tap(apexRequest =>
          this.store.dispatch(loadApexRequest({ payload: apexRequest }))
        )
      );
    }
    return chain;
  }
}
