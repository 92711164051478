import { createSelector } from '@ngrx/store';
import { SimulationState } from '../reducers';
import { selectSimulationFeature } from '../simulation.selectors';

export const selectWaterSystem = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.waterSystem.entities[id]
  );
const selectWaterSystemLoadingStatus = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.waterSystem.loading[id]
  );

export const selectWaterSystemViewSet = (id: number) =>
  createSelector(
    selectWaterSystem(id),
    selectWaterSystemLoadingStatus(id),
    (waterSystem, loadingStatus) => ({
      data: waterSystem,
      loading: loadingStatus,
    })
  );

export const selectWaterSystemErrorsCount = (id: number) =>
  createSelector(selectSimulationFeature, (state: SimulationState) => ({
    errorCount: state.waterSystem.errors[id].errorCount,
    warningCount: state.waterSystem.errors[id].warningCount,
  }));
