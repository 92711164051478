import { LengthUnit, Location } from '@/data/simulation/enumerations';
import { FoundationWallTypeBackendDict } from './FoundationWallType';
import { ModelFormControl, ModelFormGroup } from '../ModelForm';
import { Validators } from '@angular/forms';
import { enumValidator } from '@/modules/simulation/validators/helper';
import { FieldLabels } from '../base';
import { SimulationModelsState } from '@/modules/simulation/state/reducers';

export enum InteriorLocation {
  // Garage
  GARAGE = Location.GARAGE,
  // Vented Crawl Space
  CRAWL_SPACE_VENTED = Location.CRAWL_SPACE_VENTED,

  // Unvented Crawl Space
  CRAWL_SPACE_UNVENTED = Location.CRAWL_SPACE_UNVENTED,
  UNCONDITIONED_BASEMENT = Location.UNCONDITIONED_BASEMENT,
  CONDITIONED_BASEMENT = Location.CONDITIONED_BASEMENT,
}

export enum ExteriorLocation {
  AMBIENT = Location.AMBIENT,
  GARAGE = Location.GARAGE,
  CONDITIONED_BASEMENT = Location.CONDITIONED_BASEMENT,
  UNCONDITIONED_BASEMENT = Location.UNCONDITIONED_BASEMENT,
  // Ground
  CRAWL_SPACE_VENTED = Location.CRAWL_SPACE_VENTED,
  CRAWL_SPACE_UNVENTED = Location.CRAWL_SPACE_UNVENTED,
  OTHER_EXTERIOR = Location.OTHER_EXTERIOR,
  MF_BUFFER = Location.MF_BUFFER,
  MF_UNRATED_HEATED = Location.MF_UNRATED_HEATED,
  MF_NONFREEZING = Location.MF_NONFREEZING,
  MF_UNRATED_CONDITIONED = Location.MF_UNRATED_CONDITIONED,
}

export const DEFAULT_FOUNDATION_WALL: FoundationWallBackendDict = {
  id: null,
  name: '',
  type: null,
  interior_location: null,
  exterior_location: null,
  height_above_grade: 0.0,
  depth_below_grade: 0.0,
  perimeter_length: 0.0,
  units: LengthUnit.FT,
};

export const FIELD_LABELS: FieldLabels<FoundationWallBackendDict> = {
  name: 'Name',
  type: 'Type',
  interior_location: 'Interior Location',
  exterior_location: 'Exterior Location',
  height_above_grade: 'Height Above Grade',
  depth_below_grade: 'Depth Below Grade',
  perimeter_length: 'Perimeter Length',
  units: 'Units',
};

export interface FoundationWallBackendDict {
  id: number | null;
  name: string;
  type: number;
  interior_location: InteriorLocation;
  exterior_location: ExteriorLocation;
  height_above_grade: number;
  depth_below_grade: number;
  perimeter_length: number;
  units: LengthUnit.FT;
}

export interface DetailedFoundationWallBackendDict
  extends FoundationWallBackendDict {
  type_info: FoundationWallTypeBackendDict;
}

export function createFoundationWallForm(
  foundationwall: FoundationWallBackendDict
) {
  return new ModelFormGroup(
    {
      name: new ModelFormControl(foundationwall.name, [
        Validators.required,
        Validators.maxLength(128),
      ]),
      type: new ModelFormControl(foundationwall.type, Validators.required),
      interior_location: new ModelFormControl(
        foundationwall.interior_location,
        [enumValidator(InteriorLocation)]
      ),
      exterior_location: new ModelFormControl(
        foundationwall.exterior_location,
        [enumValidator(ExteriorLocation)]
      ),
      height_above_grade: new ModelFormControl(
        foundationwall.height_above_grade,
        [Validators.required, Validators.min(0)]
      ),
      depth_below_grade: new ModelFormControl(
        foundationwall.depth_below_grade,
        Validators.min(0)
      ),
      perimeter_length: new ModelFormControl(
        foundationwall.perimeter_length,
        Validators.min(0)
      ),
      units: new ModelFormControl(
        foundationwall.units,
        enumValidator([LengthUnit.FT])
      ),
    },
    {
      validators: [validateHeight],
    }
  );
}

function validateHeight(controls: ModelFormGroup) {
  const heightAboveGrade = controls.get('height_above_grade').value;
  const depthBelowGrade = controls.get('depth_below_grade').value;
  const height = heightAboveGrade + depthBelowGrade;
  const location = controls.get('interior_location').value;

  if (location === Location.CONDITIONED_SPACE) {
    if (depthBelowGrade > 6 && height > 8) {
      return {
        logicalInteriorLocation: true,
      };
    }
  }

  if (location === InteriorLocation.UNCONDITIONED_BASEMENT && height > 20) {
    return { largeBasementWallHeight: { height } };
  } else if (height <= 0 || height > 20) {
    return { largeWallHeight: { height } };
  }
  return null;
}

export function denormalizeFoundationWalls(state: SimulationModelsState) {
  const denormalizedFoundationWalls: DetailedFoundationWallBackendDict[] = [];
  for (const foundationWall of Object.values(state.foundationWall.entities)) {
    denormalizedFoundationWalls.push({
      ...foundationWall,
      type_info: state.foundationWallType.entities[foundationWall.type],
    });
  }
  return denormalizedFoundationWalls;
}
