<h2 mat-dialog-title>
    {{ data.title }}
</h2>
<mat-dialog-content>
    <div [innerHTML]="data.content"></div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="col-12 text-right">
        <button mat-raised-button color="accent" [mat-dialog-close]="true">
            Confirm
        </button>
        <button mat-raised-button [mat-dialog-close]="false">
            Cancel
        </button>
    </div>
</mat-dialog-actions>
