<div class="row">
    <mat-card class="card-container position-relative">
        <app-loading-overlay *ngIf="loading"></app-loading-overlay>
        <mat-card-content>
            <app-form formName="slabTypeFormGroup" [formGroup]="slabTypeFormGroup" (save)="saveSlabType($event)">
                <div>
                    <app-input formControlName="name" [label]="fieldLabels.name"></app-input>
                </div>
                <div>
                    <app-select formControlName="floor_covering" [items]="floorCoveringLabels"
                        [label]="fieldLabels.floor_covering"></app-select>
                    <app-select formControlName="insulation_grade" [label]="fieldLabels.insulation_grade"
                        [items]="insulationGradeLabelMapping"></app-select>
                    <app-input formControlName="thickness" [label]="fieldLabels.thickness" unit="{{lengthLabels[entity?.units]}}"></app-input>
                </div>
                <div>
                    <h5>Under slab insulation</h5>
                </div>
                <div>
                    <app-input formControlName="under_slab_insulation_width" type="number" label="Width" unit="{{lengthLabels[entity?.units]}}"></app-input>
                    <app-input formControlName="under_slab_insulation_r_value" type="expression" label="R-value"></app-input>
                </div>
                <div><h5>Perimeter insulation</h5></div>
                <div>
                    <app-input formControlName="perimeter_insulation_depth" type="number" label="Depth" unit="{{lengthLabels[entity?.units]}}"></app-input>
                    <app-input formControlName="perimeter_insulation_r_value" type="expression" label="R-value"></app-input>
                </div>
                <div>
                    <app-input formControlName="assembly_u_value" type="expression" [label]="fieldLabels.assembly_u_value"></app-input>
                    <app-checkbox formControlName="is_radiant_heated" [label]="fieldLabels.is_radiant_heated"></app-checkbox>
                </div>
            </app-form>
        </mat-card-content>
    </mat-card>
</div>
