<form [formGroup]="fomGroup">
    <div class="row">
        <div class="col-12">
            <mat-form-field appearance="outline" class="w-100" style="font-size: 10px;">
                <mat-label>Rows Per Page</mat-label>
                <mat-select
                        formControlName="rowsPerPage">
                    <mat-option
                            *ngFor="let item of rowsPerPageLabelMapping | defaultOrderKeyValue"
                            [value]="item.key">
                        {{ item.value }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</form>
