import {
  SkylightTypeBackendDict,
  createSkylightTypeForm,
  FIELD_LABELS,
} from '@/data/simulation/models/enclosure/SkylightType';
import { updateSkylightType } from '@/modules/simulation/state/skylight-type/actions';
import { selectSkylightViewSet } from '@/modules/simulation/state/skylight-type/selectors';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-skylight-type',
  templateUrl: './skylight-type.component.html',
  styleUrls: ['./skylight-type.component.scss'],
})
export class SkylightTypeComponent implements OnDestroy, OnInit {
  @Input() entityId: number;
  fieldLabels = FIELD_LABELS;

  entity: SkylightTypeBackendDict = null;
  skylightTypeFormGroup: FormGroup;

  componentDestroyed$ = new Subject();
  loading = false;

  constructor(public store: Store) {}

  ngOnInit(): void {
    this.store
      .select(selectSkylightViewSet(this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(({ data, loading }) => {
        this.loading = loading;
        this.entity = data;
        if (loading) return;

        if (!this.skylightTypeFormGroup) {
          this.skylightTypeFormGroup = createSkylightTypeForm(data);
        } else {
          this.skylightTypeFormGroup.patchValue(data);
        }
      });
  }

  onSave() {
    this.store.dispatch(
      updateSkylightType({
        skylightTypeChanges: this.skylightTypeFormGroup.value,
      })
    );
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
