import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { WaterHeaterService } from '@/data/simulation/services/water-heater.service';
import { catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as WaterHeaterActions from './actions';
import * as SharedActions from '../shared/shared.actions';
import { WaterHeaterValidator } from '../../validators/water-heater.validator';
import { StateModelName } from '../../state.registry';

@Injectable()
export class WaterHeaterEffects {
  loadWaterHeaters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WaterHeaterActions.loadWaterHeaters),
      mergeMap(action => {
        const errors = WaterHeaterValidator.validate(action.waterHeaters);
        return of(
          WaterHeaterActions.loadWaterHeatersSuccess({
            waterHeaters: action.waterHeaters,
            errors,
          })
        );
      })
    );
  });

  updateWaterHeater$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WaterHeaterActions.updateWaterHeater),
      mergeMap(action => {
        return this.waterHeaterService.update(action.waterHeaterChanges).pipe(
          mergeMap(waterHeater => {
            const errors = WaterHeaterValidator.validate([waterHeater]);
            return of(
              WaterHeaterActions.loadWaterHeatersSuccess({
                waterHeaters: [waterHeater],
                errors,
              }),
              SharedActions.updateCrossModelErrors({
                modelName: StateModelName.waterHeater,
                changedFields: Object.keys(action.waterHeaterChanges),
              })
            );
          }),
          catchError(error =>
            of(
              WaterHeaterActions.updateWaterHeaterFailure({
                id: action.waterHeaterChanges.id,
              }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private waterHeaterService: WaterHeaterService
  ) {}
}
