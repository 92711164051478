import { Component, Input, OnInit } from '@angular/core';
import {
  EEPProgramHomeStatus,
  EEPProgramHomeStatusInfo,
} from '@/data/home/models';
import { Geocode, GeocodeBrokeredResponse } from '@/data/geocoder/models';

export enum HomeStatusDisplayComponentType {
  address = 'address',
  addressAsLink = 'addressAsLink',
  addressAsLinkNewWindow = 'addressAsLinkNewWindow',
}

@Component({
  selector: 'app-home-status-display',
  templateUrl: './home-status-display.component.html',
  styleUrls: ['./home-status-display.component.scss'],
})
export class HomeStatusDisplayComponent implements OnInit {
  protected HomeStatusDisplayComponentType = HomeStatusDisplayComponentType;
  @Input() displayType:
    | HomeStatusDisplayComponentType
    | keyof typeof HomeStatusDisplayComponentType =
    HomeStatusDisplayComponentType.addressAsLink;

  private home_status$: EEPProgramHomeStatus | EEPProgramHomeStatusInfo;
  public geocode: Geocode;
  public geocodeBrokeredResponse?: GeocodeBrokeredResponse;

  @Input() home_status: EEPProgramHomeStatus | EEPProgramHomeStatusInfo;

  ngOnInit(): void {}
}
