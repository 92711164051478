import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import {
  HistoryDialogComponent,
  HistoryDialogConfig,
} from '@/shared/components/history-dialog/history-dialog.component';
import { MatDialog } from '@angular/material/dialog';

export enum HistoryMatButtonDisplayType {
  raisedButton = 'raisedButton',
  asLink = 'asLink',
  iconButton = 'iconButton',
}

@Component({
  selector: 'app-history-mat-button',
  templateUrl: './history-mat-button.component.html',
  styleUrls: ['./history-mat-button.component.scss'],
})
export class HistoryMatButtonComponent implements OnInit, OnDestroy {
  @Input() displayType:
    | HistoryMatButtonDisplayType
    | keyof typeof HistoryMatButtonDisplayType =
    HistoryMatButtonDisplayType.raisedButton;
  @Input() objectId: string | number;
  @Input() modelClassName: string;

  public historyMatButtonDisplayType = HistoryMatButtonDisplayType;
  private componentDestroyed$ = new Subject();

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  showHistoryDialog($event: MouseEvent) {
    $event.preventDefault();

    const data = new HistoryDialogConfig({
      objectId: this.objectId,
      modelClassName: this.modelClassName,
    });

    this.dialog.open(HistoryDialogComponent, {
      width: '80%',
      data,
    });
  }
}
