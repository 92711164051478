import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { forkJoin, Subject } from 'rxjs';
import { User } from '@/data/core/models/user';
import {
  HIRLProjectRegistrationList,
  HIRLProjectRegistrationStateLabelMapping,
  HIRLProjectRegistrationType,
  HIRLProjectRegistrationTypeLabelMapping,
} from '@/data/customer-hirl/models';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { getInfoUser } from '@/state/selectors/info.selector';
import { debounceTime, distinctUntilChanged, first } from 'rxjs/operators';
import {
  HIRLProjectRegistrationRequestParams,
  HIRLProjectRegistrationService,
} from '@/data/customer-hirl/services/hirl-project-registration.service';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';
import { NavigationService } from '@/shared/services/navigation-service.service';

@Component({
  selector: 'app-hirl-project-registration-list',
  templateUrl: './hirl-project-registration-list.component.html',
  styleUrls: ['./hirl-project-registration-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HIRLProjectRegistrationListComponent implements OnInit {
  public headerHeight = 40;

  public customerHIRLSettings = CustomerHIRLSettings;
  public stateLabelMapping = HIRLProjectRegistrationStateLabelMapping;

  public currentUser: User;
  public projects: HIRLProjectRegistrationList[];
  public projectsCount: number;
  public searchTerm$: Subject<string> = new Subject<string>();
  public projectsIsLoading = true;

  public storedParams: HIRLProjectRegistrationRequestParams =
    new HIRLProjectRegistrationRequestParams(1, '', '-id', 25);
  public ColumnMode = ColumnMode;
  public registrationTypeLabelMapping = HIRLProjectRegistrationTypeLabelMapping;

  constructor(
    public store: Store<AppState>,
    public hirlProjectRegistrationService: HIRLProjectRegistrationService,
    public router: Router,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private navigation: NavigationService
  ) {}

  ngOnInit() {
    forkJoin({
      queryParams: this.activatedRoute.queryParams.pipe(first()),
      currentUser: this.store.select(getInfoUser).pipe(first()),
    }).subscribe(({ queryParams, currentUser }) => {
      this.currentUser = currentUser;
      this.storedParams.page = Number(queryParams.page) || 1;
      this.storedParams.search = queryParams.search || '';
      this.storedParams.ordering = queryParams.ordering || '-id';
      this.storedParams.page_size = queryParams.page_size || 25;
      this.storedParams.project_type =
        queryParams.project_type || HIRLProjectRegistrationType.multiFamily;
      this.storedParams.state = queryParams.state || '';
      this.storedParams.without_projects = queryParams.without_projects || '';
      this.list();
    });

    this.searchTerm$
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe(searchTerm => {
        this.storedParams.search = searchTerm;
        this.storedParams.page = 1;
        this.list();
      });
  }

  list() {
    this.projectsIsLoading = true;

    // populate params to query string
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.storedParams,
      replaceUrl: true,
    });
    this.hirlProjectRegistrationService
      .list(this.storedParams)
      .pipe(first())
      .subscribe(
        data => {
          this.projects = data.results;

          this.projectsCount = data.count;
          this.projectsIsLoading = false;
        },
        error => {
          if (error.status === 403) {
            const _ = this.router.navigate(['/', '403']);
          }
        }
      );
  }

  applySearch(searchTerm: string) {
    this.searchTerm$.next(searchTerm);
  }

  clearSearch() {
    this.storedParams.search = '';
    this.storedParams.page = 1;
    this.list();
  }

  onSort({ sorts }) {
    this.storedParams.ordering = `${sorts[0].dir === 'desc' ? '-' : ''}${
      sorts[0].prop
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  onFooterPage(event) {
    this.storedParams.page = event.page;
    this.list();
  }
}
