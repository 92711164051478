import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ModernMessage } from '@/data/messaging/models/modernMessage';
import { environment } from '../../../../environments/environment';
import { tap } from 'rxjs/operators';
import { ListRequestParams } from '@/core/schemes/request-params';
import { BaseDataService } from '@/core/services/base-data-service';
import { updateRecentMessageAction } from '@/state/actions/messaging/message.actions';

export class MessageRequestParams extends ListRequestParams {
  search?: string;
  level?: string;
  category?: string;
  delivery_type?: string;
  date_created__gt?: string;
  date_created__lt?: string;
  date_alerted_start?: string;
  date_alerted_end?: string;
  alert_read?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class MessageService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/messages/`;

  read(messageId: number): Observable<ModernMessage> {
    return this.http
      .patch<ModernMessage>(`${this.baseUrl}${messageId}/read/`, {})
      .pipe(
        tap(message =>
          this.store.dispatch(updateRecentMessageAction({ payload: message }))
        )
      );
  }

  seen(messageId: number): Observable<ModernMessage> {
    return this.http
      .patch<ModernMessage>(`${this.baseUrl}${messageId}/seen/`, {})
      .pipe(
        tap(message =>
          this.store.dispatch(updateRecentMessageAction({ payload: message }))
        )
      );
  }
}
