import { ModelFormControl, ModelFormGroup } from '../ModelForm';
import { Validators } from '@angular/forms';
import { StudType, WallCavityInsulationType } from '../../enumerations';

export interface MaterialBackendDict {
  name: string;
  r_value: number;
  r_value_per_inch: number;
  thickness: number;
  insulation_grade: InsulationGrade;
}

export class Material {
  r_value: number;
  thickness: number;
  name?: string;
  r_value_per_inch?: number;
  insulation_grade?: number;
  material: any;

  constructor(data: MaterialBackendDict) {
    Object.assign(this, data);
  }
}

export enum MaterialChoices {
  INSULATED_SHEATHING_1IN = '1in_insulated_sheathing',
  AIR_GAP = 'air_gap',
  BRICK_STRUCTURAL = 'brick_structural',
  BRICK_VENEER = 'brick_veneer',
  CARPET = 'carpet',
  CELLULOSE_DENSE_PACK = 'cellulose_dense_pack',
  CELLULOSE_SPRAY = 'cellulose_spray',
  CONCRETE_STUCCO_CEMENT = 'concrete_stucco_cement',
  CUSTOM = 'custom',
  EPS = 'eps',
  FIBERBOARD = 'fiberboard',
  FIBERGLASS_BATTS = 'fiberglass_batts',
  FIBERGLASS_DENSE_PACK = 'fiberglass_dense_pack',
  FINISH_WOOD = 'finish_wood',
  GYPSUM_BOARD = 'gypsum_board',
  HOUSEWRAP = 'housewrap',
  MINERAL_WOOL_BATTS = 'mineral_wool_batts',
  PARTICLE_BOARD = 'particle_board',
  PLYWOOD_OSB = 'plywood_osb',
  POLYISOCYANURATE_FOIL = 'polyisocyanurate_foil_faced',
  SHINGLES = 'shingles',
  SIDING_VINYL = 'siding_vinyl',
  SIDING_WOOD = 'siding_wood',
  SPRAY_FOAM_HD_POLYURETHANE = 'spray_foam_hd_polyurethane',
  SPRAY_FOAM_LD_POLYURETHANE = 'spray_foam_ld_polyurethane',
  VERMICULITE_LOOSE_FILL = 'vermiculite_loose_fill',
  XPS = 'xps',
  FLOOR_COVERS = 'floor_covers',
}

export const MATERIAL_LABELS: Record<MaterialChoices, string> = {
  [MaterialChoices.INSULATED_SHEATHING_1IN]: '1in Insulated Sheathing',
  [MaterialChoices.AIR_GAP]: 'Air Gap',
  [MaterialChoices.BRICK_STRUCTURAL]: 'Brick (Structural)',
  [MaterialChoices.BRICK_VENEER]: 'Brick Veneer',
  [MaterialChoices.CARPET]: 'Carpet',
  [MaterialChoices.CELLULOSE_DENSE_PACK]: 'Cellulose Dense Pack',
  [MaterialChoices.CELLULOSE_SPRAY]: 'Cellulose Spray',
  [MaterialChoices.CONCRETE_STUCCO_CEMENT]: 'Concrete, Stucco, or Cement',
  [MaterialChoices.CUSTOM]: 'Custom',
  [MaterialChoices.EPS]: 'EPS',
  [MaterialChoices.FIBERBOARD]: 'Fiberboard',
  [MaterialChoices.FIBERGLASS_BATTS]: 'Fiberglass Batts',
  [MaterialChoices.FIBERGLASS_DENSE_PACK]: 'Fiberglass Dense Pack',
  [MaterialChoices.FINISH_WOOD]: 'Finish Wood',
  [MaterialChoices.GYPSUM_BOARD]: 'Gypsum Board',
  [MaterialChoices.HOUSEWRAP]: 'Housewrap',
  [MaterialChoices.MINERAL_WOOL_BATTS]: 'Mineral Wool Batts',
  [MaterialChoices.PARTICLE_BOARD]: 'Particle Board',
  [MaterialChoices.PLYWOOD_OSB]: 'Plywood/OSB',
  [MaterialChoices.POLYISOCYANURATE_FOIL]: 'Polyisocyanurate (Foil Faced)',
  [MaterialChoices.SHINGLES]: 'Shingles',
  [MaterialChoices.SIDING_VINYL]: 'Siding (Vinyl)',
  [MaterialChoices.SIDING_WOOD]: 'Siding (Wood)',
  [MaterialChoices.SPRAY_FOAM_HD_POLYURETHANE]: 'Spray Foam (HD Polyurethane)',
  [MaterialChoices.SPRAY_FOAM_LD_POLYURETHANE]: 'Spray Foam (LD Polyurethane)',
  [MaterialChoices.VERMICULITE_LOOSE_FILL]: 'Vermiculite Loose Fill',
  [MaterialChoices.XPS]: 'XPS',
  [MaterialChoices.FLOOR_COVERS]: 'Floor Covers',
};

export enum MaterialItem {
  INSULATION_FIBERGLASS_BATT = 'INSULATION_FIBERGLASS_BATT',
  INSULATION_FIBERGLASS_BATT_3P5 = 'INSULATION_FIBERGLASS_BATT_3P5',
  INSULATION_FIBERGLASS_BATT_3P625 = 'INSULATION_FIBERGLASS_BATT_3P625',
  INSULATION_FIBERGLASS_BATT_3P5HD = 'INSULATION_FIBERGLASS_BATT_3P5HD',
  INSULATION_FIBERGLASS_BATT_6P5 = 'INSULATION_FIBERGLASS_BATT_6P5',
  INSULATION_FIBERGLASS_BATT_8P0 = 'INSULATION_FIBERGLASS_BATT_8P0',
  INSULATION_FIBERGLASS_BATT_8P0HD = 'INSULATION_FIBERGLASS_BATT_8P0HD',
  INSULATION_FIBERGLASS_BATT_9P5 = 'INSULATION_FIBERGLASS_BATT_9P5',
  INSULATION_FIBERGLASS_BATT_12P0 = 'INSULATION_FIBERGLASS_BATT_12P0',
  INSULATION_FIBERGLASS_BLOWN_ATTIC = 'INSULATION_FIBERGLASS_BLOWN_ATTIC',
  INSULATION_FIBERGLASS_BLOWN_WALL = 'INSULATION_FIBERGLASS_BLOWN_WALL',
  INSULATION_ROCK_WOOL_BATT = 'INSULATION_ROCK_WOOL_BATT',
  INSULATION_ROCK_WOOL_BLOWN_ATTIC = 'INSULATION_ROCK_WOOL_BLOWN_ATTIC',
  INSULATION_ROCK_WOOL_BLOWN_WALL = 'INSULATION_ROCK_WOOL_BLOWN_WALL',
  INSULATION_CELLULOSE_BLOWN_ATTIC = 'INSULATION_CELLULOSE_BLOWN_ATTIC',
  INSULATION_CELLULOSE_BLOWN_WALL = 'INSULATION_CELLULOSE_BLOWN_WALL',
  INSULATION_VERMICULITE = 'INSULATION_VERMICULITE',
  INSULATION_AUTOCLAVED_CONCRETE = 'INSULATION_AUTOCLAVED_CONCRETE',
  INSULATION_UREA_FOAM = 'INSULATION_UREA_FOAM',
  INSULATION_RIGID_FIBERGLASS = 'INSULATION_RIGID_FIBERGLASS',
  INSULATION_EPS_BEADBOARD = 'INSULATION_EPS_BEADBOARD',
  INSULATION_XPS = 'INSULATION_XPS',
  INSULATION_POLYURETHANE = 'INSULATION_POLYURETHANE',
  INSULATION_POLYISOCYANURATE = 'INSULATION_POLYISOCYANURATE',
  INSULATION_POLYISOCYANURATE_GAS_IMPER = 'INSULATION_POLYISOCYANURATE_GAS_IMPER',
  CONSTRUCTION_CONCRETE_BLOCK_4P0 = 'CONSTRUCTION_CONCRETE_BLOCK_4P0',
  CONSTRUCTION_CONCRETE_BLOCK_8P0 = 'CONSTRUCTION_CONCRETE_BLOCK_8P0',
  CONSTRUCTION_CONCRETE_BLOCK_12P0 = 'CONSTRUCTION_CONCRETE_BLOCK_12P0',
  CONSTRUCTION_CONCRETE_ICF_8P0 = 'CONSTRUCTION_CONCRETE_ICF_8P0',
  CONSTRUCTION_BRICK_4p0 = 'CONSTRUCTION_BRICK_4p0',
  CONSTRUCTION_BRICK_FACE = 'CONSTRUCTION_BRICK_FACE',
  CONSTRUCTION_CONCRETE_POURED = 'CONSTRUCTION_CONCRETE_POURED',
  CONSTRUCTION_SOFT_WOOD = 'CONSTRUCTION_SOFT_WOOD',
  CONSTRUCTION_1P5 = 'CONSTRUCTION_1P5',
  CONSTRUCTION_2X4 = 'CONSTRUCTION_2X4',
  CONSTRUCTION_2X6 = 'CONSTRUCTION_2X6',
  CONSTRUCTION_2X8 = 'CONSTRUCTION_2X8',
  CONSTRUCTION_2X10 = 'CONSTRUCTION_2X10',
  CONSTRUCTION_2X12 = 'CONSTRUCTION_2X12',
  CONSTRUCTION_CEDAR = 'CONSTRUCTION_CEDAR',
  CONSTRUCTION_STEEL = 'CONSTRUCTION_STEEL',
  CONSTRUCTION_STEEL_1P25X2P5 = 'CONSTRUCTION_STEEL_1P25X2P5',
  CONSTRUCTION_STEEL_1P25X3P5 = 'CONSTRUCTION_STEEL_1P25X3P5',
  CONSTRUCTION_STEEL_1P25X3P625 = 'CONSTRUCTION_STEEL_1P25X3P625',
  SHEATHING_PLYWOOD_0P25 = 'SHEATHING_PLYWOOD_0P25',
  SHEATHING_PLYWOOD_0P325 = 'SHEATHING_PLYWOOD_0P325',
  SHEATHING_PLYWOOD_0P5 = 'SHEATHING_PLYWOOD_0P5',
  SHEATHING_PLYWOOD_0P625 = 'SHEATHING_PLYWOOD_0P625',
  SHEATHING_PLYWOOD_0P75 = 'SHEATHING_PLYWOOD_0P75',
  SHEATHING_FIBERBOARD_0P5 = 'SHEATHING_FIBERBOARD_0P5',
  SHEATHING_FIBERBOARD_0P78 = 'SHEATHING_FIBERBOARD_0P78',
  SHEATHING_FIBERGLASS_0P75 = 'SHEATHING_FIBERGLASS_0P75',
  SHEATHING_FIBERGLASS_1P0 = 'SHEATHING_FIBERGLASS_1P0',
  SHEATHING_FIBERGLASS_1P5 = 'SHEATHING_FIBERGLASS_1P5',
  SHEATHING_XPS_0P75 = 'SHEATHING_XPS_0P75',
  SHEATHING_XPS_1P0 = 'SHEATHING_XPS_1P0',
  SHEATHING_XPS_1P5 = 'SHEATHING_XPS_1P5',
  SHEATHING_POLYISO_FOIL_0P75 = 'SHEATHING_POLYISO_FOIL_0P75',
  SHEATHING_POLYISO_FOIL_1P0 = 'SHEATHING_POLYISO_FOIL_1P0',
  SHEATHING_POLYISO_FOIL_1P5 = 'SHEATHING_POLYISO_FOIL_1P5',
  OSB_0P44 = 'OSB_0P44',
  VAPOR_PERMEABLE_FELT = 'VAPOR_PERMEABLE_FELT',
  MINERAL_FIBERBOARD_1P0 = 'MINERAL_FIBERBOARD_1P0',
  SIDING_HARDBOARD = 'SIDING_HARDBOARD',
  SIDING_PLYWOOD_0P75 = 'SIDING_PLYWOOD_0P75',
  SIDING_WOOD_BEVEL_LAPPED = 'SIDING_WOOD_BEVEL_LAPPED',
  SIDING_STANDARD_SIDING_HOLLOW = 'SIDING_STANDARD_SIDING_HOLLOW',
  SIDING_STANDARD_SIDING_INSULATED = 'SIDING_STANDARD_SIDING_INSULATED',
  INTERIOR_FINISH_GYPSUM_0P5 = 'INTERIOR_FINISH_GYPSUM_0P5',
  INTERIOR_FINISH_GYPSUM_0P625 = 'INTERIOR_FINISH_GYPSUM_0P625',
  INTERIOR_FINISH_PANELING = 'INTERIOR_FINISH_PANELING',
  ROOF_MEMBRANE = 'ROOF_MEMBRANE',
  AIR_GAP = 'AIR_GAP',
  INSULATION_GENERIC_CAVITY = 'INSULATION_GENERIC_CAVITY',
  INSULATION_GENERIC_CONTINUOUS = 'INSULATION_GENERIC_CONTINUOUS',
  INSULATION_EXTERIOR_GENERIC_CONTINUOUS = 'INSULATION_EXTERIOR_GENERIC_CONTINUOUS',

  CONCRETE_BLOCK_6P0_PERLITE = 'CONCRETE_BLOCK_6P0_PERLITE',
  CONCRETE_BLOCK_8P0_PERLITE = 'CONCRETE_BLOCK_8P0_PERLITE',
  CONCRETE_BLOCK_12P0_PERLITE = 'CONCRETE_BLOCK_12P0_PERLITE',
  CONCRETE_BLOCK_6P0_VERMICULITE = 'CONCRETE_BLOCK_6P0_VERMICULITE',
  CONCRETE_BLOCK_8P0_VERMICULITE = 'CONCRETE_BLOCK_8P0_VERMICULITE',
  CONCRETE_BLOCK_12P0_VERMICULITE = 'CONCRETE_BLOCK_12P0_VERMICULITE',
  CONSTRUCTION_DOUBLE_BRICK_6P0 = 'CONSTRUCTION_DOUBLE_BRICK_6P0',
  CONSTRUCTION_DOUBLE_BRICK_8P0 = 'CONSTRUCTION_DOUBLE_BRICK_8P0',
  CONSTRUCTION_DOUBLE_BRICK_10P0 = 'CONSTRUCTION_DOUBLE_BRICK_10P0',

  FLOOR_COVERING_CARPET = 'FLOOR_COVERING_CARPET',
  FLOOR_COVERING_LINOLEUM = 'FLOOR_COVERING_LINOLEUM',
  FLOOR_COVERING_HARDWOOD = 'FLOOR_COVERING_HARDWOOD',
  FLOOR_COVERING_TILE = 'FLOOR_COVERING_TILE',

  ROOF_ASPHALT_SHINGLES = 'ROOF_ASPHALT_SHINGLES',
  ROOF_SLATE_SHINGLES = 'ROOF_SLATE_SHINGLES',
  ROOF_CLAY_TILE_SHINGLES = 'ROOF_CLAY_TILE_SHINGLES',
  ROOF_WOOD_SHINGLES = 'ROOF_WOOD_SHINGLES',
}

export enum InsulationGrade {
  GradeI = 1,
  GradeII = 2,
  GradeIII = 3,
}

export const INSULATION_GRADE_LABELS: Record<InsulationGrade, string> = {
  [InsulationGrade.GradeI]: 'I',
  [InsulationGrade.GradeII]: 'II',
  [InsulationGrade.GradeIII]: 'III',
};

interface MaterialInfo {
  name: string;
  thickness: number | null;
  r_value: number | null;
  source: string;
  r_value_per_inch: number | null;
}

export function createMaterialForm(
  material: MaterialBackendDict
): ModelFormGroup {
  return new ModelFormGroup({
    name: new ModelFormControl(material.name, Validators.required),
    r_value: new ModelFormControl(material.r_value, Validators.required),
    r_value_per_inch: new ModelFormControl(material.r_value_per_inch),
    thickness: new ModelFormControl(material.thickness, Validators.required),
    insulation_grade: new ModelFormControl(
      material.insulation_grade,
      Validators.required
    ),
  });
}

export const MaterialChoicesOptions: {
  [key in MaterialChoices]: MaterialItem[];
} = {
  [MaterialChoices.INSULATED_SHEATHING_1IN]: [
    MaterialItem.SHEATHING_POLYISO_FOIL_0P75,
    MaterialItem.SHEATHING_POLYISO_FOIL_1P0,
    MaterialItem.SHEATHING_POLYISO_FOIL_1P5,
  ],
  [MaterialChoices.AIR_GAP]: [MaterialItem.AIR_GAP],
  [MaterialChoices.BRICK_STRUCTURAL]: [
    MaterialItem.CONSTRUCTION_BRICK_4p0,
    MaterialItem.CONSTRUCTION_BRICK_FACE,
    MaterialItem.CONSTRUCTION_DOUBLE_BRICK_6P0,
    MaterialItem.CONSTRUCTION_DOUBLE_BRICK_8P0,
    MaterialItem.CONSTRUCTION_DOUBLE_BRICK_10P0,
  ],
  [MaterialChoices.BRICK_VENEER]: [MaterialItem.CONSTRUCTION_BRICK_FACE],
  [MaterialChoices.CARPET]: [],
  [MaterialChoices.CELLULOSE_DENSE_PACK]: [
    MaterialItem.INSULATION_CELLULOSE_BLOWN_WALL,
  ],
  [MaterialChoices.CELLULOSE_SPRAY]: [
    MaterialItem.INSULATION_CELLULOSE_BLOWN_WALL,
  ],
  [MaterialChoices.CONCRETE_STUCCO_CEMENT]: [
    MaterialItem.CONSTRUCTION_CONCRETE_POURED,
    MaterialItem.CONCRETE_BLOCK_6P0_PERLITE,
    MaterialItem.CONCRETE_BLOCK_8P0_PERLITE,
    MaterialItem.CONCRETE_BLOCK_12P0_PERLITE,
    MaterialItem.CONCRETE_BLOCK_6P0_VERMICULITE,
    MaterialItem.CONCRETE_BLOCK_8P0_VERMICULITE,
    MaterialItem.CONCRETE_BLOCK_12P0_VERMICULITE,
  ],
  [MaterialChoices.CUSTOM]: [
    MaterialItem.INSULATION_GENERIC_CAVITY,
    MaterialItem.INSULATION_GENERIC_CONTINUOUS,
    MaterialItem.INSULATION_EXTERIOR_GENERIC_CONTINUOUS,
    MaterialItem.ROOF_MEMBRANE,
  ],
  [MaterialChoices.EPS]: [MaterialItem.INSULATION_EPS_BEADBOARD],
  [MaterialChoices.FIBERBOARD]: [
    MaterialItem.SHEATHING_FIBERBOARD_0P5,
    MaterialItem.SHEATHING_FIBERBOARD_0P78,
  ],
  [MaterialChoices.FIBERGLASS_BATTS]: [
    MaterialItem.INSULATION_FIBERGLASS_BATT_3P5,
    MaterialItem.INSULATION_FIBERGLASS_BATT_3P625,
    MaterialItem.INSULATION_FIBERGLASS_BATT_3P5HD,
    MaterialItem.INSULATION_FIBERGLASS_BATT_6P5,
    MaterialItem.INSULATION_FIBERGLASS_BATT_8P0,
    MaterialItem.INSULATION_FIBERGLASS_BATT_8P0HD,
    MaterialItem.INSULATION_FIBERGLASS_BATT_9P5,
    MaterialItem.INSULATION_FIBERGLASS_BATT_12P0,
  ],
  [MaterialChoices.FIBERGLASS_DENSE_PACK]: [
    MaterialItem.INSULATION_RIGID_FIBERGLASS,
    MaterialItem.INSULATION_FIBERGLASS_BLOWN_WALL,
  ],
  [MaterialChoices.FINISH_WOOD]: [],
  [MaterialChoices.GYPSUM_BOARD]: [
    MaterialItem.INTERIOR_FINISH_GYPSUM_0P5,
    MaterialItem.INTERIOR_FINISH_GYPSUM_0P625,
  ],
  [MaterialChoices.HOUSEWRAP]: [],
  [MaterialChoices.MINERAL_WOOL_BATTS]: [
    MaterialItem.INSULATION_ROCK_WOOL_BATT,
  ],
  [MaterialChoices.PARTICLE_BOARD]: [],
  [MaterialChoices.PLYWOOD_OSB]: [
    MaterialItem.OSB_0P44,
    MaterialItem.SIDING_PLYWOOD_0P75,
    MaterialItem.SHEATHING_PLYWOOD_0P625,
  ],
  [MaterialChoices.POLYISOCYANURATE_FOIL]: [
    MaterialItem.INSULATION_POLYISOCYANURATE,
    MaterialItem.INSULATION_POLYISOCYANURATE_GAS_IMPER,
  ],
  [MaterialChoices.SHINGLES]: [
    MaterialItem.ROOF_ASPHALT_SHINGLES,
    MaterialItem.ROOF_SLATE_SHINGLES,
    MaterialItem.ROOF_CLAY_TILE_SHINGLES,
    MaterialItem.ROOF_WOOD_SHINGLES,
  ],
  [MaterialChoices.SIDING_VINYL]: [
    MaterialItem.SIDING_STANDARD_SIDING_HOLLOW,
    MaterialItem.SIDING_STANDARD_SIDING_INSULATED,
  ],
  [MaterialChoices.SIDING_WOOD]: [MaterialItem.SIDING_WOOD_BEVEL_LAPPED],
  [MaterialChoices.SPRAY_FOAM_HD_POLYURETHANE]: [
    MaterialItem.INSULATION_POLYURETHANE,
  ],
  [MaterialChoices.SPRAY_FOAM_LD_POLYURETHANE]: [
    MaterialItem.INSULATION_POLYURETHANE,
  ],
  [MaterialChoices.VERMICULITE_LOOSE_FILL]: [
    MaterialItem.INSULATION_VERMICULITE,
  ],
  [MaterialChoices.XPS]: [MaterialItem.INSULATION_XPS],

  [MaterialChoices.FLOOR_COVERS]: [
    MaterialItem.FLOOR_COVERING_CARPET,
    MaterialItem.FLOOR_COVERING_LINOLEUM,
    MaterialItem.FLOOR_COVERING_HARDWOOD,
    MaterialItem.FLOOR_COVERING_TILE,
  ],
};

export const StudTypeChoicesOptions: {
  [key in StudType]: MaterialItem[];
} = {
  [StudType.WOOD_STUD]: [
    MaterialItem.CONSTRUCTION_1P5,
    MaterialItem.CONSTRUCTION_2X4,
    MaterialItem.CONSTRUCTION_2X6,
    MaterialItem.CONSTRUCTION_2X8,
    MaterialItem.CONSTRUCTION_2X10,
    MaterialItem.CONSTRUCTION_2X12,
    MaterialItem.CONSTRUCTION_SOFT_WOOD,
  ],
  [StudType.STEEL_FRAME]: [
    MaterialItem.CONSTRUCTION_STEEL,
    MaterialItem.CONSTRUCTION_STEEL_1P25X2P5,
    MaterialItem.CONSTRUCTION_STEEL_1P25X3P5,
    MaterialItem.CONSTRUCTION_STEEL_1P25X3P625,
  ],
  [StudType.SOLID_CONCRETE]: [
    MaterialItem.CONSTRUCTION_CONCRETE_BLOCK_4P0,
    MaterialItem.CONSTRUCTION_CONCRETE_BLOCK_8P0,
    MaterialItem.CONSTRUCTION_CONCRETE_BLOCK_12P0,
    MaterialItem.CONSTRUCTION_CONCRETE_ICF_8P0,
  ],
};

export const Materials: Record<MaterialItem, MaterialInfo> = {
  INSULATION_FIBERGLASS_BATT: {
    name: 'Fiberglass Batt',
    thickness: null,
    r_value: null,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: 3.7199999999999998,
  },
  INSULATION_FIBERGLASS_BATT_3P5: {
    name: '3 1/2" Fiberglass Batt',
    thickness: 3.5,
    r_value: 11.0,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  INSULATION_FIBERGLASS_BATT_3P625: {
    name: '3 5/8" Fiberglass Batt',
    thickness: 3.625,
    r_value: 13,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  INSULATION_FIBERGLASS_BATT_3P5HD: {
    name: '3 5/8" Fiberglass Batt (High Density)',
    thickness: 3.5,
    r_value: 15,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  INSULATION_FIBERGLASS_BATT_6P5: {
    name: '6 1/2" Fiberglass Batt',
    thickness: 6.5,
    r_value: 19,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  INSULATION_FIBERGLASS_BATT_8P0: {
    name: '8" Fiberglass Batt',
    thickness: 8.0,
    r_value: 25,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  INSULATION_FIBERGLASS_BATT_8P0HD: {
    name: '8" Fiberglass Batt (High Density)',
    thickness: 8.0,
    r_value: 30,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  INSULATION_FIBERGLASS_BATT_9P5: {
    name: '9 1/2" Fiberglass Batt',
    thickness: 9.5,
    r_value: 30,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  INSULATION_FIBERGLASS_BATT_12P0: {
    name: '12" Fiberglass Batt',
    thickness: 12.0,
    r_value: 38,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  INSULATION_FIBERGLASS_BLOWN_ATTIC: {
    name: 'Fiberglass Blown (Attic)',
    thickness: null,
    r_value: null,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: 3.25,
  },
  INSULATION_FIBERGLASS_BLOWN_WALL: {
    name: 'Fiberglass Blown (Wall)',
    thickness: null,
    r_value: null,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: 4.0,
  },
  INSULATION_ROCK_WOOL_BATT: {
    name: 'Rock Wool Batt',
    thickness: null,
    r_value: null,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: 3.5700000000000003,
  },
  INSULATION_ROCK_WOOL_BLOWN_ATTIC: {
    name: 'Rock Wool Blown (Attic)',
    thickness: null,
    r_value: null,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: 3.55,
  },
  INSULATION_ROCK_WOOL_BLOWN_WALL: {
    name: 'Rock Wool Blown (Wall)',
    thickness: null,
    r_value: null,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: 3.55,
  },
  INSULATION_CELLULOSE_BLOWN_ATTIC: {
    name: 'Cellulose Blown (attic)',
    thickness: null,
    r_value: null,
    source:
      'https://www.coloradoenergy.org/procorner/stuff/ASHRAE_R-Value_Report_Truncated.pdf',
    r_value_per_inch: 3.6500000000000004,
  },
  INSULATION_CELLULOSE_BLOWN_WALL: {
    name: 'Cellulose Blown (wall)',
    thickness: null,
    r_value: null,
    source:
      'https://www.coloradoenergy.org/procorner/stuff/ASHRAE_R-Value_Report_Truncated.pdf',
    r_value_per_inch: 3.8499999999999996,
  },
  INSULATION_VERMICULITE: {
    name: 'Vermiculite',
    thickness: null,
    r_value: null,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: 2.13,
  },
  INSULATION_AUTOCLAVED_CONCRETE: {
    name: 'Autoclaved Aerated Concrete',
    thickness: null,
    r_value: null,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: 1.05,
  },
  INSULATION_UREA_FOAM: {
    name: 'Urea Terpolymer Foam',
    thickness: null,
    r_value: null,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: 4.48,
  },
  INSULATION_RIGID_FIBERGLASS: {
    name: 'Rigid Fiberglass (> 4lb/ft3)',
    thickness: null,
    r_value: null,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: 4.0,
  },
  INSULATION_EPS_BEADBOARD: {
    name: 'Expanded Polystyrene (EPS beadboard)',
    thickness: null,
    r_value: null,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: 4.0,
  },
  INSULATION_XPS: {
    name: 'Extruded Polystyrene (XPS)',
    thickness: null,
    r_value: null,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: 5.0,
  },
  INSULATION_POLYURETHANE: {
    name: 'Polyurethane (foamed-in-place)',
    thickness: null,
    r_value: null,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: 5.0,
  },
  INSULATION_POLYISOCYANURATE: {
    name: 'Polyisocyanurate (foil-faced)',
    thickness: null,
    r_value: null,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: 5.3,
  },
  INSULATION_POLYISOCYANURATE_GAS_IMPER: {
    name: 'Polyisocyanurate (cellular gas-impermeable facers)',
    thickness: null,
    r_value: null,
    source: 'ASHRAE Handbook fundamentals Chapt 27',
    r_value_per_inch: 9.4,
  },
  CONSTRUCTION_CONCRETE_BLOCK_4P0: {
    name: 'Concrete Block 4"',
    thickness: null,
    r_value: 0.8,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  CONSTRUCTION_CONCRETE_BLOCK_8P0: {
    name: 'Concrete Block 8"',
    thickness: null,
    r_value: 1.11,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  CONSTRUCTION_CONCRETE_BLOCK_12P0: {
    name: 'Concrete Block 12"',
    thickness: null,
    r_value: 1.28,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  CONSTRUCTION_CONCRETE_ICF_8P0: {
    name: 'Insulating Concrete Form (ICF) 8"',
    thickness: null,
    r_value: 1.11,
    source: 'ce_src',
    r_value_per_inch: null,
  },
  CONSTRUCTION_BRICK_4p0: {
    name: 'Brick 4" common',
    thickness: null,
    r_value: 0.8,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  CONSTRUCTION_BRICK_FACE: {
    name: 'Brick 4" face',
    thickness: null,
    r_value: 0.44,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  CONSTRUCTION_CONCRETE_POURED: {
    name: 'Poured Concrete',
    thickness: null,
    r_value: null,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: 0.08,
  },
  CONSTRUCTION_SOFT_WOOD: {
    name: 'Soft Wood Lumber',
    thickness: null,
    r_value: null,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: 1.25,
  },
  CONSTRUCTION_1P5: {
    name: '2" nominal (1 1/2")',
    thickness: 1.5,
    r_value: 1.88,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  CONSTRUCTION_2X4: {
    name: '2x4 (3 1/2")',
    thickness: 3.5,
    r_value: 4.38,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  CONSTRUCTION_2X6: {
    name: '2x6 (5 1/2")',
    thickness: 5.5,
    r_value: 6.88,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  CONSTRUCTION_2X8: {
    name: '2x8 (7-1/4")',
    thickness: 7.25,
    r_value: 1.24 * 7.25,
    source: 'ce_src',
    r_value_per_inch: null,
  },
  CONSTRUCTION_2X10: {
    name: '2x10 (9-1/4")',
    thickness: 9.25,
    r_value: 1.24 * 9.25,
    source: 'ce_src',
    r_value_per_inch: null,
  },
  CONSTRUCTION_2X12: {
    name: '2x10 (11-1/4")',
    thickness: 11.25,
    r_value: 1.24 * 11.25,
    source: 'ce_src',
    r_value_per_inch: null,
  },
  CONSTRUCTION_CEDAR: {
    name: 'Cedar Logs and Lumber',
    thickness: null,
    r_value: null,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: 1.33,
  },
  CONSTRUCTION_STEEL: {
    name: 'Steel Framing',
    thickness: null,
    r_value: null,
    source: 'ASHRAE Handbook fundamentals Chapt 27',
    r_value_per_inch: 0.003,
  },
  CONSTRUCTION_STEEL_1P25X2P5: {
    name: 'Steel Stud 1-1/4" x 2-1/2"',
    thickness: 2.5,
    r_value: null,
    source: 'ashrae_src',
    r_value_per_inch: null,
  },
  CONSTRUCTION_STEEL_1P25X3P625: {
    name: 'Steel Stud 1-1/4" x 3-5/8"',
    thickness: 3.625,
    r_value: null,
    source: 'ashrae_src',
    r_value_per_inch: null,
  },
  CONSTRUCTION_STEEL_1P25X3P5: {
    name: 'Steel Stud 1-1/4" x 3-1/2"',
    thickness: 3.5,
    r_value: null,
    source: 'ashrae_src',
    r_value_per_inch: null,
  },
  SHEATHING_PLYWOOD_0P25: {
    name: 'Plywood 1/4"',
    thickness: 0.25,
    r_value: 0.31,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  SHEATHING_PLYWOOD_0P325: {
    name: 'Plywood 3/8"',
    thickness: 0.375,
    r_value: 0.47,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  SHEATHING_PLYWOOD_0P5: {
    name: 'Plywood 1/2"',
    thickness: 0.5,
    r_value: 0.63,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  SHEATHING_PLYWOOD_0P625: {
    name: 'Plywood 5/8"',
    thickness: 0.625,
    r_value: 0.77,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  SHEATHING_PLYWOOD_0P75: {
    name: 'Plywood 3/4"',
    thickness: 0.75,
    r_value: 0.94,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  SHEATHING_FIBERBOARD_0P5: {
    name: 'Fiberboard 1/2"',
    thickness: 0.5,
    r_value: 1.32,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  SHEATHING_FIBERBOARD_0P78: {
    name: 'Fiberboard 25/32"',
    thickness: 0.78125,
    r_value: 2.06,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  SHEATHING_FIBERGLASS_0P75: {
    name: 'Fiberglass 3/4"',
    thickness: 0.75,
    r_value: 3.0,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  SHEATHING_FIBERGLASS_1P0: {
    name: 'Fiberglass 1"',
    thickness: 1.0,
    r_value: 4.0,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  SHEATHING_FIBERGLASS_1P5: {
    name: 'Fiberglass 1 1/2"',
    thickness: 1.5,
    r_value: 6.0,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  SHEATHING_XPS_0P75: {
    name: 'Extruded Polystyrene (XPS) 3/4"',
    thickness: 0.75,
    r_value: 3.75,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  SHEATHING_XPS_1P0: {
    name: 'Extruded Polystyrene (XPS) 1"',
    thickness: 1.0,
    r_value: 5.0,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  SHEATHING_XPS_1P5: {
    name: 'Extruded Polystyrene (XPS) 1 1/2"',
    thickness: 1.5,
    r_value: 7.5,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  SHEATHING_POLYISO_FOIL_0P75: {
    name: 'Foil-faced Polyisocyanurate 3/4"',
    thickness: 0.75,
    r_value: 5.5,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  SHEATHING_POLYISO_FOIL_1P0: {
    name: 'Foil-faced Polyisocyanurate 1"',
    thickness: 1.0,
    r_value: 7.2,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  SHEATHING_POLYISO_FOIL_1P5: {
    name: 'Foil-faced Polyisocyanurate 1 1/2"',
    thickness: 1.5,
    r_value: 1.8,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  OSB_0P44: {
    name: 'Oriented strand board (OSB), 7/16"',
    thickness: 0.4375,
    r_value: 0.62,
    source: 'ASHRAE Handbook fundamentals Chapt 27',
    r_value_per_inch: null,
  },
  VAPOR_PERMEABLE_FELT: {
    name: 'Vapor permeable felt',
    thickness: 0.0625,
    r_value: 0.06,
    source: 'ASHRAE Handbook fundamentals Chapt 27',
    r_value_per_inch: null,
  },
  MINERAL_FIBERBOARD_1P0: {
    name: 'Mineral fiberboard 1"',
    thickness: 1.0,
    r_value: 2.94,
    source: 'ASHRAE Handbook fundamentals Chapt 27',
    r_value_per_inch: null,
  },
  SIDING_HARDBOARD: {
    name: 'Hardboard 1/2"',
    thickness: 0.5,
    r_value: 0.34,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  SIDING_PLYWOOD_0P75: {
    name: 'Plywood 3/4"',
    thickness: 0.75,
    r_value: 0.93,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  SIDING_WOOD_BEVEL_LAPPED: {
    name: 'Wood Bevel Lapped',
    thickness: null,
    r_value: 0.8,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  SIDING_STANDARD_SIDING_HOLLOW: {
    name: 'Aluminum, Steel, Vinyl Hollow Siding',
    thickness: null,
    r_value: 0.62,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  SIDING_STANDARD_SIDING_INSULATED: {
    name: 'Aluminum, Steel, Vinyl Siding w/ 1/2" Insulating board',
    thickness: null,
    r_value: 1.8,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  INTERIOR_FINISH_GYPSUM_0P5: {
    name: 'Drywall 1/2"',
    thickness: 0.5,
    r_value: 0.45,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  INTERIOR_FINISH_GYPSUM_0P625: {
    name: 'Drywall 5/8"',
    thickness: 0.5,
    r_value: 0.56,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  INTERIOR_FINISH_PANELING: {
    name: 'Paneling 3/8"',
    thickness: 0.375,
    r_value: 0.47,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  AIR_GAP: {
    name: 'Air Gap',
    thickness: null,
    r_value: null,
    source: 'ASHRAE Handbook fundamentals Chapt 27',
    r_value_per_inch: 1.03,
  },
  INSULATION_GENERIC_CAVITY: {
    name: 'Custom canity material',
    thickness: null,
    r_value: null,
    source: null,
    r_value_per_inch: null,
  },
  INSULATION_GENERIC_CONTINUOUS: {
    name: 'Custom continuous material',
    thickness: null,
    r_value: null,
    source: null,
    r_value_per_inch: null,
  },
  INSULATION_EXTERIOR_GENERIC_CONTINUOUS: {
    name: 'Custom exterior continuous material',
    thickness: null,
    r_value: null,
    source: null,
    r_value_per_inch: null,
  },
  CONCRETE_BLOCK_6P0_PERLITE: {
    name: 'Concrete Block 6" (Perlite Filled)',
    thickness: null,
    r_value: 4.2,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  CONCRETE_BLOCK_8P0_PERLITE: {
    name: 'Concrete Block 8" (Perlite Filled)',
    thickness: null,
    r_value: 5.6,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  CONCRETE_BLOCK_12P0_PERLITE: {
    name: 'Concrete Block 12" (Perlite Filled)',
    thickness: null,
    r_value: 7.75,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  CONCRETE_BLOCK_6P0_VERMICULITE: {
    name: 'Concrete Block 6" (Vermiculite Filled)',
    thickness: null,
    r_value: 3.0,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  CONCRETE_BLOCK_8P0_VERMICULITE: {
    name: 'Concrete Block 8" (Vermiculite Filled)',
    thickness: null,
    r_value: 5.1,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  CONCRETE_BLOCK_12P0_VERMICULITE: {
    name: 'Concrete Block 12" (Vermiculite Filled)',
    thickness: null,
    r_value: 5.8,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  CONSTRUCTION_DOUBLE_BRICK_6P0: {
    name: 'Brick 2 cells deep 6"',
    thickness: null,
    r_value: 1.52,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  CONSTRUCTION_DOUBLE_BRICK_8P0: {
    name: 'Brick 2 cells deep 8"',
    thickness: null,
    r_value: 1.85,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },
  CONSTRUCTION_DOUBLE_BRICK_10P0: {
    name: 'Brick 2 cells deep 10"',
    thickness: null,
    r_value: 2.22,
    source: 'https://www.coloradoenergy.org/procorner/stuff/r-values.htm',
    r_value_per_inch: null,
  },

  FLOOR_COVERING_CARPET: {
    name: 'Pile carpet (w pad)',
    thickness: 0.5,
    r_value: 1.59,
    source: 'ASHRAE Handbook fundamentals Chapt 27',
    r_value_per_inch: null,
  },
  FLOOR_COVERING_LINOLEUM: {
    name: 'Linoleum/Cork Tile',
    thickness: 0.25,
    r_value: 0.51,
    source: 'ASHRAE Handbook fundamentals Chapt 27',
    r_value_per_inch: null,
  },
  FLOOR_COVERING_HARDWOOD: {
    name: 'Hardwood',
    thickness: 0.75,
    r_value: 0.39,
    source: 'ASHRAE Handbook fundamentals Chapt 27',
    r_value_per_inch: null,
  },
  FLOOR_COVERING_TILE: {
    name: 'Tile (w/thinset)',
    thickness: 0.625,
    r_value: 0.65,
    source:
      'https://www.heatizon.com/installation-manuals/r-values-building-flooring-subfloors',
    r_value_per_inch: null,
  },
  ROOF_ASPHALT_SHINGLES: {
    name: 'Asphalt Shingles',
    thickness: 3 / 16,
    r_value: 0.44,
    source: 'ASHRAE Handbook fundamentals Chapt 27',
    r_value_per_inch: null,
  },
  ROOF_SLATE_SHINGLES: {
    name: 'Slate Shingles',
    thickness: 0.75,
    r_value: 0.05,
    source: 'ASHRAE Handbook fundamentals Chapt 27',
    r_value_per_inch: null,
  },
  ROOF_CLAY_TILE_SHINGLES: {
    name: 'Clay / Tile Shingles',
    thickness: 0.75,
    r_value: 0.03,
    source: 'ASHRAE Handbook fundamentals Chapt 27',
    r_value_per_inch: null,
  },
  ROOF_WOOD_SHINGLES: {
    name: 'Wood Shingles',
    thickness: 1 / 2,
    r_value: 0.31,
    source: 'ASHRAE Handbook fundamentals Chapt 27',
    r_value_per_inch: null,
  },
  ROOF_MEMBRANE: {
    name: 'Roof Membrane',
    thickness: 0.375,
    r_value: 0.33,
    source: 'ASHRAE Handbook fundamentals Chapt 27',
    r_value_per_inch: 0.33 / 0.375,
  },
};

export const DEFAULT_MATERIAL: MaterialBackendDict = {
  name: MaterialChoices.FIBERGLASS_BATTS,
  r_value: Materials[MaterialItem.INSULATION_FIBERGLASS_BATT_3P5].r_value,
  r_value_per_inch:
    Materials[MaterialItem.INSULATION_FIBERGLASS_BATT_3P5].r_value_per_inch,
  thickness: Materials[MaterialItem.INSULATION_FIBERGLASS_BATT_3P5].thickness,
  insulation_grade: InsulationGrade.GradeI,
};

function getMaterialChoice(materialItem: MaterialItem): MaterialChoices {
  return Object.entries(MaterialChoicesOptions).find(([key, value]) =>
    value.includes(materialItem)
  )[0] as MaterialChoices;
}

export function getMaterial(item: MaterialItem): MaterialBackendDict {
  const material = Materials[item];
  return {
    name: getMaterialChoice(item),
    thickness: material.thickness,
    r_value: material.r_value,
    r_value_per_inch: material.r_value_per_inch,
    insulation_grade: InsulationGrade.GradeI,
  };
}

export const MaterialItemFromWallCavityMaterial: Record<
  WallCavityInsulationType,
  MaterialItem
> = {
  [WallCavityInsulationType.XPS]: MaterialItem.INSULATION_XPS,
  [WallCavityInsulationType.EPS]: MaterialItem.INSULATION_EPS_BEADBOARD,
  [WallCavityInsulationType.SPRAY_FOAM]: MaterialItem.INSULATION_POLYURETHANE,
  [WallCavityInsulationType.CELLULOSE]:
    MaterialItem.INSULATION_CELLULOSE_BLOWN_WALL,
  [WallCavityInsulationType.BATT]: MaterialItem.INSULATION_RIGID_FIBERGLASS,
  [WallCavityInsulationType.WOOL]: MaterialItem.INSULATION_ROCK_WOOL_BATT,
  [WallCavityInsulationType.POLY]: MaterialItem.INSULATION_POLYISOCYANURATE,
  [WallCavityInsulationType.VERMICULITE]: MaterialItem.INSULATION_VERMICULITE,
  [WallCavityInsulationType.OTHER]: MaterialItem.INSULATION_GENERIC_CAVITY,
};
