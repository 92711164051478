<h2 mat-dialog-title>
  Choose Categories to Export
</h2>
<mat-dialog-content *ngIf="initialized">
  <mat-selection-list [formGroup]="form" class="mb-2 mt-2" (selectionChange)="updateFormControlValue($event)">
    <ng-container *ngFor="let control of form.controls | defaultOrderKeyValue">
      <ng-container *ngIf="formPermissions[control.key]">
        <mat-list-option [value]="control.key" [selected]="form.get(control.key).value" class="export-line-item">
          {{ exportFieldLabelMapping[control.key] }}
        </mat-list-option>
      </ng-container>
    </ng-container>
  </mat-selection-list>
</mat-dialog-content>
<div class="row">
  <div class="col-12">
    <hr>
  </div>
</div>
<mat-dialog-actions>
  <a href="#" (click)="reset($event)">Reset Defaults</a>
  <span class="spacer"></span>
  <button mat-button [mat-dialog-close]="null" color="primary" mat-raised-button>Cancel</button>
  <button mat-button color="accent" mat-raised-button (click)="confirm($event)">Export</button>
</mat-dialog-actions>
