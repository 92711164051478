import { createSelector } from '@ngrx/store';
import { SimulationState } from '../reducers';
import { selectSimulationFeature } from '../simulation.selectors';

export const selectSimulationConfig = () =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) =>
      Object.values(state.simulationConfig.entities)[0]
  );

const selectSimulationConfigLoading = () =>
  createSelector(
    selectSimulationFeature,
    state => Object.values(state.simulationConfig.loading)[0]
  );

export const selectSimulationConfigViewSet = () =>
  createSelector(
    selectSimulationConfig(),
    selectSimulationConfigLoading(),
    (data, loading) => ({ data, loading })
  );
