import { createReducer, on } from '@ngrx/store';
import * as HeaterActions from './actions';
import { HeaterBackendDict } from '@/data/simulation/models/mechanicals/Heater';
import { BaseState } from '../shared/base.state';
import { addStateErrorCount } from '../helper';

export type HeaterState = BaseState<HeaterBackendDict>;

export const initialState: HeaterState = {
  entities: {},
  loading: {},
  errors: {},
};

export const reducer = createReducer(
  initialState,
  on(HeaterActions.loadHeatersSuccess, (state, { heaters, errors }) => {
    const entities = heaters.reduce(
      (acc, heater) => ({ ...acc, [heater.id]: heater }),
      {}
    );
    const loading = heaters.reduce(
      (acc, heater) => ({ ...acc, [heater.id]: false }),
      {}
    );

    return {
      entities: {
        ...state.entities,
        ...entities,
      },
      loading: {
        ...state.loading,
        ...loading,
      },
      errors: {
        ...state.errors,
        ...addStateErrorCount(errors),
      },
    };
  }),

  on(HeaterActions.updateHeater, (state, { heaterChanges }) => ({
    ...state,
    loading: {
      ...state.loading,
      [heaterChanges.id]: true,
    },
  })),

  on(HeaterActions.updateHeaterFailure, (state, { id }) => ({
    ...state,
    loading: {
      ...state.loading,
      [id]: false,
    },
  }))
);
