<mat-expansion-panel [expanded]="expanded">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <strong>Natural Ventilation</strong>
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
        <div class="card position-relative" appFocusTarget entityName="naturalVentilation" [entityId]="entityId">
            <app-loading-overlay *ngIf="loading"></app-loading-overlay>
            <div class="card-body pb-0">
                <app-form formName="naturalVentilationForm" [formGroup]="form" (save)="onSave($event)">
                    <div>
                        <app-select formControlName="type" [label]="fieldLabels.type" [items]="typeLabels"></app-select>
                    </div>
                    <div>
                        <app-input formControlName="ventilation_rate" type="number" [label]="fieldLabels.ventilation_rate" unit="{{ventilationRateLabels[entity?.ventilation_rate_unit]}}"></app-input>
                        <app-input formControlName="consumption" type="number" [label]="fieldLabels.consumption" unit="{{auxEnergyUnitLabels[entity?.consumption_unit]}}"></app-input>
                    </div>
                </app-form>
            </div>
        </div>
    </ng-template>
</mat-expansion-panel>