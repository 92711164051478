import { createSelector } from '@ngrx/store';
import { SimulationState } from '../reducers';
import { selectSimulationFeature } from '../simulation.selectors';

export const selectAboveGradeWallType = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.aboveGradeWallType.entities[id]
  );

const selectAboveGradeWallTypeLoadingStatus = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.aboveGradeWallType.loading[id]
  );

export const selectAboveGradeWallViewSet = (id: number) =>
  createSelector(
    selectAboveGradeWallType(id),
    selectAboveGradeWallTypeLoadingStatus(id),
    (aboveGradeWallType, loadingStatus) => ({
      data: aboveGradeWallType,
      loading: loadingStatus,
    })
  );

const selectAboveGradeWallTypeName = (id: number) =>
  createSelector(
    selectAboveGradeWallType(id),
    aboveGradeWallType => aboveGradeWallType.name
  );

const selectSurfaceRValue = (id: number) =>
  createSelector(selectSimulationFeature, (state: SimulationState) => {
    return 1 / state.aboveGradeWallType.entities[id].assembly_u_value;
  });

const selectAboveGradeWallTypeErrorCount = (id: number) =>
  createSelector(selectSimulationFeature, (state: SimulationState) => {
    return state.aboveGradeWallType.errors[id].errorCount;
  });

export const selectAboveGradeWallTypeNameAndError = (id: number) =>
  createSelector(
    selectAboveGradeWallTypeName(id),
    selectSurfaceRValue(id),
    selectAboveGradeWallTypeErrorCount(id),
    (name, surfaceRValue, errorCount) => {
      return {
        name,
        surfaceRValue,
        errorCount,
      };
    }
  );
