<ng-container *ngIf="home_status">
  <ng-container [ngSwitch]="displayType">
    <ng-container *ngSwitchCase="HomeStatusDisplayComponentType.address">
      <app-home-display [home]="home_status?.home_info"></app-home-display>
    </ng-container>
    <ng-container *ngSwitchCase="HomeStatusDisplayComponentType.addressAsLink">
      <a [routerLink]="['/', 'home', home_status.home_info?.id]">
        <app-home-display [home]="home_status?.home_info"></app-home-display>
      </a>
    </ng-container>
    <ng-container *ngSwitchCase="HomeStatusDisplayComponentType.addressAsLinkNewWindow">
      <a [routerLink]="['/', 'home', home_status.home_info?.id]" target="_blank">
        <app-home-display [home]="home_status?.home_info"></app-home-display>
      </a>
    </ng-container>
  </ng-container>
</ng-container>
