<div class="analysis-reports-container">
    <h4>Building Summary Reports</h4>
    <mat-divider></mat-divider>
    <div class="files-container">
        <div class="d-flex flex-column button-container">
            <div class="position-relative w-100 h-100"> 
                <button mat-icon-button class="w-100 h-100">
                    <fa-icon [icon]="['far', 'file-pdf']" size="4x"></fa-icon>
                </button>
                <a mat-icon-button class="download-icon" href="{{getURL('building_summary_report')}}" download>
                    <fa-icon [icon]="['fas', 'download']" size="1x"></fa-icon>
                </a>
            </div>
            <span>Building-summary.pdf</span>
        </div>
        <div class="d-flex flex-column button-container" *ngIf="bigLadderCompany || superUser">
            <div class="position-relative w-100 h-100"> 
                <button mat-icon-button class="w-100 h-100">
                    <fa-icon [icon]="['far', 'file']" size="4x"></fa-icon>
                </button>
                <a mat-icon-button class="download-icon" href="{{getURL('resnet_xml')}}" download>
                    <fa-icon [icon]="['fas', 'download']" size="1x"></fa-icon>
                </a>
            </div>
            <span>Resnet.xml</span>
        </div>
    </div>
</div>
<div class="mt-5"></div>
<div class="analysis-reports-container">
    <h4>Analysis Reports</h4>
    <mat-divider></mat-divider>
    <div class="items" *ngIf="loading || runningSimulation">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div class="items" *ngIf="!loading">
        <div *ngIf="analyses.length === 0 && !runningSimulation">
            <div class="no-item">
                <h3 class="fst-italic">No analysis reports available!</h3>
                <app-run-simulation-button></app-run-simulation-button>
            </div>
        </div>
        <div *ngFor="let analysis of analyses">
            <app-analysis [entity]="analysis" [change]="changes[analysis.id]" [simulationId]="simulationId" [bigLadderFilesAllowed]="bigLadderCompany || superUser"></app-analysis>
        </div>
    </div>
</div>
