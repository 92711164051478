import { AppState } from '@/state/reducers';
import { createSelector } from '@ngrx/store';
import { productStateKey } from '@/state/reducers/product/product.reducer';

export const selectProductState = (state: AppState) => state[productStateKey];

export const getProduct = createSelector(
  selectProductState,
  store => store.detail
);
// Product Images
export const getProductImages = createSelector(
  selectProductState,
  store => store.productImages
);

export const getProductImagesCount = createSelector(
  selectProductState,
  store => store.productImagesCount
);

// Practice Item Product
export const getProductPracticeItems = createSelector(
  selectProductState,
  store => store.productPracticeItems
);

export const getProductPracticeItemsCount = createSelector(
  selectProductState,
  store => store.productPracticeItemsCount
);

// Payment Requests
export const getProductPaymentRequests = createSelector(
  selectProductState,
  store => store.paymentRequestsProducts
);

export const getProductPaymentRequestsCount = createSelector(
  selectProductState,
  store => store.paymentRequestsProductsCount
);

// Product Documents
export const getProductDocuments = createSelector(
  selectProductState,
  store => store.documents
);

export const getProductDocumentsCount = createSelector(
  selectProductState,
  store => store.documentsCount
);

// Product Notes
export const getProductNotes = createSelector(
  selectProductState,
  store => store.notes
);

export const getProductNotesCount = createSelector(
  selectProductState,
  store => store.notesCount
);
