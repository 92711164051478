import { createSelector } from '@ngrx/store';
import { AppState } from '@/state/reducers';

export const selectMessageState = (state: AppState) => state.message;

export const getRecentMessages = createSelector(
  selectMessageState,
  store => store.recentMessages
);
export const getRecentMessagesCount = createSelector(
  selectMessageState,
  store => store.recentMessagesCount
);
export const getRecentMessageAdded = createSelector(
  selectMessageState,
  store => store.recentMessageAdded
);
