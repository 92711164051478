import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Product } from '@/data/product/models';

import { toggleLoading } from '@/state/actions/app.actions';
import { filter, first, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ProductService } from '@/data/product/services/product.service';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { Observable, of, Subject } from 'rxjs';
import { getProduct } from '@/state/selectors/product/product.selector';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';

export class HirlProductPublishRequirementsDialogConfig {
  title: string;

  constructor({
    title = 'Product Group Certification Requirements',
  }: {
    title?: string;
  }) {
    this.title = title;
  }
}

@Component({
  selector: 'app-hirl-product-publish-requirements-dialog',
  templateUrl: './hirl-product-publish-requirements-dialog.component.html',
  styleUrls: ['./hirl-product-publish-requirements-dialog.component.scss'],
})
export class HirlProductPublishRequirementsDialogComponent
  implements OnInit, OnDestroy
{
  entity: Product;
  certifyPermissionResponse: Observable<ObjectPermissionResponse> = of(
    new ObjectPermissionResponse(false)
  );

  private componentDestroyed$ = new Subject();

  constructor(
    public store: Store<AppState>,
    public productService: ProductService,
    public dialogRef: MatDialogRef<HirlProductPublishRequirementsDialogComponent>,
    public uiHelperService: UIHelperService,
    @Inject(MAT_DIALOG_DATA)
    public config: HirlProductPublishRequirementsDialogConfig
  ) {}

  ngOnInit(): void {
    this.dialogRef.updateSize('50%');
    this.store
      .select(getProduct)
      .pipe(
        filter(val => val !== null),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((entity: Product) => {
        this.entity = entity;

        if (
          entity.paid_non_expired_initial_fee?.id &&
          entity.paid_non_expired_payment_request?.id &&
          entity.countersigned_agreement?.id
        ) {
          this.certifyPermissionResponse = of(
            new ObjectPermissionResponse(true)
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  onPublish($event: MouseEvent) {
    this.store.dispatch(toggleLoading({ payload: true }));
    this.productService
      .publish(this.entity.id)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: (_: Product) => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close();
          this.uiHelperService.openSnackBar(
            'Product Group successfully Certified'
          );
        },
        error: (error: HttpErrorResponse) =>
          this.uiHelperService.handleUserRequestError(error),
      });
  }
}
