import { AnalysisListItem } from '@/data/simulation/models/reports/Analysis';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-analysis-summary',
  templateUrl: './analysis-summary.component.html',
  styleUrls: ['./analysis-summary.component.scss'],
})
export class AnalysisSummaryComponent {
  @Input() entity: AnalysisListItem;
}
