import { CompanyInfo } from '@/data/company/models';
import { Simulation } from '@/data/simulation/models';

export class FloorPlan {
  id?: number;
  name?: string;
  number?: string;
  square_footage?: number;
  homes_count?: number;
  is_custom_home?: boolean;
  comment?: string;
  owner?: number;
  owner_info?: CompanyInfo;
  type?: string;
  remrate_target?: number;
  remrate_data_file?: string;
  ekotrope_houseplan?: string;
  is_active?: boolean;
  modified_date?: string;
  created_date?: string;
  simulation?: number;
  simulation_info?: Simulation;
  certified_homes_count?: number;
  active_homes_count?: number;
  qa_pending_homes_count?: number;
  is_restricted?: number;

  remrate_data_file_name?: string;
  remrate_data_file_url?: string;
  ekotrope_project_url?: string;
}

export enum FloorplanSourceType {
  remrateDataType = 'remrate',
  ekotropeDataType = 'ekotrope',
  blgDataType = 'blg_data',
}

export const FloorplanSourceTypeLabelMapping: Record<
  FloorplanSourceType,
  string
> = {
  [FloorplanSourceType.remrateDataType]: 'RemRate Data Type',
  [FloorplanSourceType.ekotropeDataType]: 'Ekotrope Data Type',
  [FloorplanSourceType.blgDataType]: 'Blg Data Type',
};

export interface FloorplanInfoBackendDict {
  id?: number;
}
