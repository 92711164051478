<div class="card position-relative" appFocusTarget entityId="{{entityId}}" entityName="distributionSystem">
    <app-loading-overlay *ngIf="loading"></app-loading-overlay>
    <div class="card-body pb-0 min-width">
        <app-form formName="distributionSystemFormGroup" [formGroup]="distributionSystemFormGroup" (save)="onSave($event)">
            <div>
                <app-input formControlName="name" [label]="fieldLabels.name"></app-input>
            </div>
            <div>
                <app-select formControlName="system_type"
                            [items]="distributionSystemTypeLabels"
                            [label]="fieldLabels.system_type"></app-select>

                <app-select formControlName="hydronic_distribution_type"
                            *ngIf="distributionSystemFormGroup?.controls.system_type.value === 'hydronic'"
                            [items]="hydronicDistributionTypeLabels"
                            [label]="fieldLabels.hydronic_distribution_type">
                </app-select>
            </div>
            <div>
                <app-model-select
                        modelName="mechanicalEquipment" formControlName="heating_system"
                        [label]="fieldLabels.heating_system" [excluder]="excludeNonHeaters"
                        [optionLabel]="optionaLabel"></app-model-select>
                <app-model-select
                        modelName="mechanicalEquipment" formControlName="cooling_system"
                        [label]="fieldLabels.cooling_system" [excluder]="excludeNonCooler"
                        [optionLabel]="optionaLabel"></app-model-select>
            </div>
            <div [hidden]="distributionSystemFormGroup.controls.system_type.value !== 'dse'">
                <app-input
                        formControlName="dse_annual_heating_system_efficiency" type="number"
                        [label]="fieldLabels.dse_annual_heating_system_efficiency"></app-input>
                <app-input
                        formControlName="dse_annual_cooling_system_efficiency" type="number"
                        [label]="fieldLabels.dse_annual_cooling_system_efficiency"> </app-input>
            </div>
            <div [hidden]="distributionSystemFormGroup.controls.system_type.value !== 'dse'">
                <app-input formControlName="estimated_system_efficiency" type="number"
                    [label]="fieldLabels.estimated_system_efficiency"></app-input>
            </div>
            <ng-container *ngIf="distributionSystemFormGroup.controls.system_type.value !== 'dse'">
                <div>
                    <app-select formControlName="test_type" [items]="distributionSystemTestTypeLabels"
                        [label]="fieldLabels.test_type"></app-select>
                </div>
                <div>
                    <app-input *ngIf="distributionSystemFormGroup?.controls.system_type.value === 'forced_air'" formControlName="area_served" type="number" [label]="fieldLabels.area_served"></app-input>
                    <app-input *ngIf="distributionSystemFormGroup?.controls.system_type.value === 'forced_air'" formControlName="supply_surface_area" type="number" [label]="fieldLabels.supply_surface_area"></app-input>
                    <app-input *ngIf="distributionSystemFormGroup?.controls.system_type.value === 'forced_air'" formControlName="return_surface_area" type="number" [label]="fieldLabels.return_surface_area"></app-input>
                </div>
                <div>
                    <app-input *ngIf="distributionSystemFormGroup?.controls.system_type.value === 'forced_air'" formControlName="qty_return_grills" type="number" [label]="fieldLabels.qty_return_grills"></app-input>
                    <app-checkbox formControlName="leakage_to_outside_tested" [label]="fieldLabels.leakage_to_outside_tested"></app-checkbox>
                </div>
                <div>
                    <app-input formControlName="leakage_to_outside" type="number" [label]="fieldLabels.leakage_to_outside"></app-input>
                    <app-select formControlName="total_leakage_condition" [items]="totalLeakageTestConditionLables" [label]="fieldLabels.total_leakage_condition"></app-select>
                </div>
                <div>
                    <app-input formControlName="total_leakage" type="number" [label]="fieldLabels.total_leakage"></app-input>
                    <app-input formControlName="field_test_leakage_to_outside" type="number"
                        [label]="fieldLabels.field_test_leakage_to_outside"></app-input>
                </div>
                <div>
                    <app-input formControlName="field_test_total_leakage" type="number" [label]="fieldLabels.field_test_total_leakage"></app-input>
                    <app-select formControlName="leakage_unit" [label]="fieldLabels.leakage_unit" [items]="distributionSystemLeakageUnit"></app-select>
                </div>
                <div>
                    <app-checkbox formControlName="iecc_test_exemption" [label]="fieldLabels.iecc_test_exemption"></app-checkbox>
                    <app-checkbox formControlName="eri_test_exemption" [label]="fieldLabels.eri_test_exemption"></app-checkbox>
                </div>
                <div>
                    <app-checkbox formControlName="energy_star_leakage_to_outside_exemption"
                        label="Energy Star Leakage to Outside Exemption"></app-checkbox>
                    <app-checkbox formControlName="system_in_conditioned_space" [label]="fieldLabels.system_in_conditioned_space"></app-checkbox>
                </div>
            </ng-container>
        </app-form>
        <app-ducts [parentId]="entityId" *ngIf="distributionSystemFormGroup?.controls.system_type.value === 'forced_air'"></app-ducts>
    </div>
    <div class="card-footer p-0 text-right">
        <app-model-action-buttons [entityId]="entityId" modelName="distributionSystem" (clone)="clone()" (delete)="removeDistributionSystem()">
        </app-model-action-buttons>
    </div>
</div>
