import {
  SkylightTypeBackendDict,
  createSkylightTypeForm,
} from '@/data/simulation/models/enclosure/SkylightType';
import { BaseValidator } from './base.validator';
import { ModelErrors, StateErrors } from '../state/shared/base.state';

export class SkylightTypeValidator extends BaseValidator {
  static validateForm(
    skylighttype: SkylightTypeBackendDict
  ): ModelErrors<SkylightTypeBackendDict> {
    const form = createSkylightTypeForm(skylighttype);
    return form.getFieldAndModelErros();
  }

  static validate(
    skylighttypes: SkylightTypeBackendDict[]
  ): StateErrors<SkylightTypeBackendDict> {
    const errors = {};
    skylighttypes.forEach(skylighttype => {
      errors[skylighttype.id] = this.validateForm(skylighttype);
    });
    return errors;
  }
}
