import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { forkJoin, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { Company, CompanyAggregatedCountyByState } from '@/data/company/models';
import {
  CountyRequestParams,
  CountyService,
} from '@/data/geographic/services/county.service';
import { County } from '@/data/geographic/models';
import { filter, first, skip, switchMap, takeUntil, tap } from 'rxjs/operators';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CompanyUpdateCountiesByState } from '@/data/company/models/company';
import { toggleLoading } from '@/state/actions/app.actions';
import { MatSelectionListChange } from '@angular/material/list';

import { getCompany } from '@/state/selectors/company/company.selector';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { CompanyService } from '@/data/company/services/company-base.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-company-counties-tab',
  templateUrl: './company-counties-tab.component.html',
  styleUrls: ['./company-counties-tab.component.scss'],
})
export class CompanyCountiesTabComponent implements OnInit, OnDestroy {
  initialized = false;
  company: Company;
  companyDisplayName: string;

  formGroup: FormGroup;

  companyCounties: County[] = [];
  counties: County[] = [];
  countiesSectionIsLoading = false;

  selectedCounties = [];

  aggregatedCountiesByState: CompanyAggregatedCountyByState[];
  openedItem: CompanyAggregatedCountyByState;

  private componentDestroyed$ = new Subject();

  constructor(
    public store: Store<AppState>,
    public injector: Injector,
    public fb: FormBuilder,
    public countyService: CountyService,
    private companyService: CompanyService,
    public uiHelperService: UIHelperService
  ) {}

  ngOnInit(): void {
    this.store
      .select(getCompany)
      .pipe(takeUntil(this.componentDestroyed$))
      .pipe(filter(val => val !== null))
      .subscribe(company => {
        this.company = company;

        forkJoin({
          companyCounties: this.companyService
            .counties(this.company.id)
            .pipe(first()),
          aggregatedCountiesByState: this.companyService
            .aggregated_counties_by_state(this.company.id)
            .pipe(first()),
        }).subscribe(({ companyCounties, aggregatedCountiesByState }) => {
          this.companyCounties = companyCounties;
          this.aggregatedCountiesByState = aggregatedCountiesByState;
          this.initialized = true;
        });
      });

    this.setupFormGroup();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  public setupFormGroup() {
    this.formGroup = this.fb.group({
      countries: [null],
    });

    this.formGroup
      .get('countries')
      .valueChanges.pipe(
        takeUntil(this.componentDestroyed$),
        skip(1),
        tap(countries => this.store.dispatch(toggleLoading({ payload: true }))),
        switchMap(countries =>
          this.companyService.update(this.company.id, {
            countries: countries.map(country => country.id),
          })
        ),
        tap(countries => this.store.dispatch(toggleLoading({ payload: false })))
      )
      .subscribe();
  }

  loadCounties(aggregatedCounty: CompanyAggregatedCountyByState) {
    this.countiesSectionIsLoading = true;
    this.selectedCounties = [];
    this.counties = [];

    this.openedItem = aggregatedCounty;

    this.countyService
      .list(
        Object.assign(new CountyRequestParams(), {
          state: aggregatedCounty.state,
          page_size: 1000,
        })
      )
      .pipe(first())
      .subscribe(serverResponse => {
        this.counties = serverResponse.results;
        this.companyService
          .counties(this.company.id)
          .pipe(first())
          .subscribe(companyCounties => {
            this.companyCounties = companyCounties;

            this.selectedCounties = companyCounties;
            this.countiesSectionIsLoading = false;
          });
      });
  }

  stateSelected(
    change: MatCheckboxChange,
    item: CompanyAggregatedCountyByState
  ) {
    const updatedCounties = new CompanyUpdateCountiesByState();
    updatedCounties.state = item.state;
    updatedCounties.counties = [];
    this.store.dispatch(toggleLoading({ payload: true }));

    if (change.checked) {
      this.countyService
        .list(
          Object.assign(new CountyRequestParams(), {
            state: item.state,
            page_size: 1000,
          })
        )
        .pipe(first())
        .subscribe(response => {
          updatedCounties.counties = response.results.map(county => county.id);
          this.companyService
            .update_counties_for_state(this.company.id, updatedCounties)
            .pipe(first())
            .subscribe(
              _ => {
                item.selected_counties = item.counties;
                this.selectedCounties = response.results;
                this.store.dispatch(toggleLoading({ payload: false }));
              },
              error => this.uiHelperService.handleUserRequestError(error)
            );
        });
    } else {
      this.companyService
        .update_counties_for_state(this.company.id, updatedCounties)
        .pipe(first())
        .subscribe(
          _ => {
            item.selected_counties = 0;
            this.selectedCounties = [];
            this.store.dispatch(toggleLoading({ payload: false }));
          },
          error => this.uiHelperService.handleUserRequestError(error)
        );
    }
  }

  countySelected(_: MatSelectionListChange) {
    this.openedItem.selected_counties = this.selectedCounties.length;
    const updatedCounties = new CompanyUpdateCountiesByState();
    updatedCounties.state = this.openedItem.state;
    updatedCounties.counties = this.selectedCounties.map(county => county.id);

    this.store.dispatch(toggleLoading({ payload: true }));
    this.companyService
      .update_counties_for_state(this.company.id, updatedCounties)
      .pipe(first())
      .subscribe(
        response => {
          this.store.dispatch(toggleLoading({ payload: false }));
        },
        error => this.uiHelperService.handleUserRequestError(error)
      );
  }

  countiesCompareFunction(o1: County, o2: County) {
    return o1?.id === o2?.id;
  }
}
