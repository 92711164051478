export class RaterRole {
  id?: number;
  title?: string;
  slug?: string;
  hidden?: boolean;
}

export class RaterRoleInfo {
  id?: number;
  title?: string;
  slug?: string;
  hidden?: boolean;
}
