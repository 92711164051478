<div class="row">
    <mat-card class="card-container position-relative">
        <app-loading-overlay *ngIf="loading"></app-loading-overlay>
        <mat-card-title class="card-title">Info</mat-card-title>
        <mat-card-content>
            <app-form formName="frameFloorTypeForm" [formGroup]="frameFloorTypeFormGroup" (save)="saveFrameFloorType($event)">
                <div>
                    <app-input formControlName="name" [label]="fieldLabels.name"></app-input>
                </div>
                <div>
                    <app-input formControlName="assembly_u_value" [label]="fieldLabels.assembly_u_value" type="expression"></app-input>
                    <app-checkbox formControlName="user_defined_assembly_u_value" label="Directly defined U-value"></app-checkbox>
                </div>
            </app-form>
            <app-assembly-editor 
                    (save)="saveFrameFloorType($event)" 
                    [form]="frameFloorTypeFormGroup" 
                    (systemUValue)="handleSystemUValue($event)"
                    *ngIf="!frameFloorTypeFormGroup.controls.user_defined_assembly_u_value.value">
                <app-form formName="frameFloorTypeFormGroup" (save)="saveFrameFloorType($event)" [formGroup]="frameFloorTypeFormGroup">
                    <div>
                        <app-select formControlName="floor_covering" [label]="fieldLabels.floor_covering"
                            [items]="floorCoveringLabels"></app-select>
                        <app-input formControlName="continuous_insulation_r_value" type="expression"
                            [label]="fieldLabels.continuous_insulation_r_value"></app-input>
                    </div>
                    <div>
                        <h5>Joist</h5>
                    </div>
                    <div>
                        <app-select formControlName="joist_type" [items]="joistTypeLabels" label=""></app-select>
                    </div>
                    <ng-container *ngIf="frameFloorTypeFormGroup.controls.joist_type.value">
                        <div>
                            <app-input formControlName="joist_width" type="number" label="Width" unit="{{lengthLabels[entity?.units]}}"></app-input>
                            <app-input formControlName="joist_depth" type="number" label="Depth" unit="{{lengthLabels[entity?.units]}}"></app-input>
                            <app-input formControlName="joist_spacing" type="number" label="Spacing" unit="{{lengthLabels[entity?.units]}}"></app-input>
                        </div>
                        <div>
                            <app-input type="number" [label]="fieldLabels.framing_factor" formControlName="framing_factor"></app-input>
                            <app-checkbox formControlName="use_default_framing_factor"
                                [label]="fieldLabels.use_default_framing_factor"></app-checkbox>
                        </div>
                        <br>

                        <div><h5>Cavity Insulation</h5></div>
                        <div>
                            <app-input formControlName="cavity_insulation_thickness" type="number" label="Thickness" unit="{{lengthLabels[entity?.units]}}"></app-input>
                            <app-select formControlName="cavity_insulation_grade" type="number" [items]="gradeLabels" label="Insulation grade"></app-select>
                            <app-input formControlName="cavity_insulation_r_value" type="expression" label="R-value"></app-input>
                        </div>
                    </ng-container>
                </app-form>
            </app-assembly-editor>
        </mat-card-content>
    </mat-card>
</div>
