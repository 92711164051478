import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LightsService } from '@/data/simulation/services/lights.service';
import { catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as LightsActions from './actions';
import * as SharedActions from '../shared/shared.actions';
import { LightsValidator } from '../../validators/lights.validator';

@Injectable()
export class LightsEffects {
  loadLights$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LightsActions.loadLights),
      mergeMap(action => {
        const errors = LightsValidator.validate(action.lights);
        return of(
          LightsActions.loadLightsSuccess({
            lights: action.lights,
            errors,
          })
        );
      })
    );
  });

  updateLights$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LightsActions.updateLights),
      mergeMap(action => {
        return this.lightsService.update(action.lightsChanges).pipe(
          mergeMap(lights => {
            const errors = LightsValidator.validate(lights);
            return of(
              LightsActions.loadLightsSuccess({
                lights: lights,
                errors,
              })
            );
          }),
          catchError(error =>
            of(
              LightsActions.updateLightsFailure({
                id: action.lightsChanges.id,
              }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private lightsService: LightsService
  ) {}
}
