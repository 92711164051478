<h2 mat-dialog-title>
  <ng-container *ngIf="!isEditForm">
    Create New <b>Accreditation</b>
  </ng-container>
  <ng-container *ngIf="isEditForm">
    Edit <b>Accreditation</b>
  </ng-container>
</h2>
<mat-dialog-content *ngIf="initialized">
  <form [formGroup]="form" class="mb-2">
    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Name</mat-label>
          <mat-select formControlName="name">
            <mat-option *ngFor="let item of nameLabelMapping | defaultOrderKeyValue" [value]="item.key">{{ item.value }}</mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('name').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Accreditation ID</mat-label>
          <input matInput type="text" placeholder="Accreditation ID" formControlName="accreditation_id">
          <mat-error *ngIf="form.get('accreditation_id').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Role</mat-label>
          <mat-select formControlName="role">
            <mat-option *ngFor="let item of AccreditationRoleLabelMapping | defaultOrderKeyValue" [value]="item.key">{{ item.value }}</mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('role').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Accreditation Status</mat-label>
          <mat-select formControlName="state">
            <mat-option *ngFor="let item of AccreditationStateLabelMapping | defaultOrderKeyValue" [value]="item.key">{{ item.value }}</mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('state').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Accreditation Cycle</mat-label>
          <mat-select formControlName="accreditation_cycle">
            <mat-option *ngFor="let item of AccreditationCycleLabelMapping | defaultOrderKeyValue" [value]="item.key">{{ item.value }}</mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('accreditation_cycle').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Initial Accreditation Date</mat-label>
          <input matInput type="text" placeholder="Start Date" formControlName="date_initial" [matDatepicker]="dateInitialDatePicker">
          <mat-datepicker-toggle matSuffix [for]="dateInitialDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #dateInitialDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Most Recent Accreditation Date</mat-label>
          <input matInput type="text" placeholder="Most Recent Accreditation Date" formControlName="date_last" [matDatepicker]="dateLastDatePicker">
          <mat-datepicker-toggle matSuffix [for]="dateLastDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #dateLastDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Accreditation Expiration</mat-label>
          <input matInput type="text" placeholder="Expiration Date" formControlName="manual_expiration_date" [matDatepicker]="expirationDateDatePicker">
          <mat-datepicker-toggle matSuffix [for]="expirationDateDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #expirationDateDatePicker></mat-datepicker>
          <mat-hint>
            If not set then expiration date will be calculated automatically based on &quot;Most Recent Accreditation Date&quot; and &quot;Accreditation Cycle&quot;
          </mat-hint>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Notes</mat-label>
          <textarea matInput type="text" placeholder="Notes" formControlName="notes"></textarea>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button color="accent" mat-raised-button (click)="save($event)">
    <ng-container *ngIf="isEditForm">Save</ng-container>
    <ng-container *ngIf="!isEditForm">Create</ng-container>
  </button>
  <button mat-button [mat-dialog-close]="null" color="primary" mat-raised-button>Cancel</button>
</mat-dialog-actions>
