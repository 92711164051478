import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import * as DuctActions from './actions';
import * as SharedActions from '../shared/shared.actions';
import { DuctService } from '@/data/simulation/services/duct.service';
import { DuctValidator } from '../../validators/duct.validator';
import { StateModelName } from '../../state.registry';

@Injectable()
export class DuctEffects {
  loadDucts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DuctActions.loadDucts),
      mergeMap(action => {
        const errors = DuctValidator.validate(action.ducts);
        return of(
          DuctActions.loadDuctsSuccess({
            ducts: action.ducts,
            errors,
          })
        );
      })
    );
  });

  updateDuct$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DuctActions.updateDuct),
      mergeMap(action =>
        this.ductService.update(action.ductChanges).pipe(
          mergeMap(updatedDuct => {
            const errors = DuctValidator.validate([updatedDuct]);
            return of(
              DuctActions.loadDuctsSuccess({
                ducts: [updatedDuct],
                errors,
              }),
              SharedActions.updateCrossModelErrors({
                modelName: StateModelName.duct,
                changedFields: Object.keys(action.ductChanges),
              })
            );
          }),
          catchError(error =>
            of(
              DuctActions.updateDuctFailure({
                id: action.ductChanges.id,
              }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        )
      )
    );
  });

  constructor(private actions$: Actions, private ductService: DuctService) {}
}
