<app-loading-overlay *ngIf="loading"></app-loading-overlay>
<app-form formName="dehumidifierFormGroup" [formGroup]="dehumidifierFormGroup" (save)="onSave($event)">
    <div>
        <app-input formControlName="name" [label]="fieldLabels.name"></app-input>
    </div>
    <div>
        <app-select formControlName="type" [label]="fieldLabels.type" [items]="dehumidifierTypeLabels"></app-select>
        <app-select formControlName="fuel" [label]="fieldLabels.fuel" [items]="dehumidifierFuel"></app-select>
    </div>
    <div>
        <app-input
            formControlName="capacity"
            type="number"
            [unit]="dehumidifierCapacityUnitLabels[entity?.capacity_unit]"
            [label]="fieldLabels.capacity">
        </app-input>
        <app-select formControlName="capacity_unit" [label]="fieldLabels.capacity_unit" [items]="dehumidifierCapacityUnitLabels"></app-select>
    </div>
    <div>
        <app-input
            formControlName="efficiency"
            type="number"
            [unit]="dehumidifierEfficiencyUnitLabels[entity?.efficiency_unit]"
            [label]="fieldLabels.efficiency">
        </app-input>
        <app-select formControlName="efficiency_unit" [label]="fieldLabels.efficiency_unit" [items]="dehumidifierEfficiencyUnitLabels"></app-select>
    </div>
    <div>
        <app-input formControlName="ahri_reference_number" [label]="fieldLabels.ahri_reference_number"></app-input>
    </div>
</app-form>
