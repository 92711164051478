import { CompanyInfo } from '@/data/company/models';
import { City, County, USState } from '@/data/geographic/models';
import { SubdivisionInfo } from '@/data/subdivision/models';
import { User, UserInfo } from '@/data/core/models/user';
import { Analysis } from '@/data/simulation/models/analysis';
import {
  DetailedAboveGradeWallBackendDict,
  denormalizeAboveGradeWalls,
} from './enclosure/AboveGradeWall';
import {
  DetailedFoundationWallBackendDict,
  denormalizeFoundationWalls,
} from './enclosure/FoundationWall';
import {
  DetailedFrameFloorBackendDict,
  denormalizeFrameFloors,
} from './enclosure/FrameFloor';
import { DetailedRoofBackendDict, denormalizeRoofs } from './enclosure/Roof';
import {
  DetailedRimJoistBackendDict,
  denormalizeRimJoists,
} from './enclosure/RimJoist';
import { DetailedSlabBackendDict, denormalizeSlabs } from './enclosure/Slab';
import {
  DetailedSkylightBackendDict,
  denormalizeSkylights,
} from './enclosure/Skylight';
import { DetailedDoorBackendDict, denormalizeDoors } from './enclosure/Door';
import {
  DetailedWindowBackendDict,
  denormalizeWindows,
} from './enclosure/Window';
import {
  DetailedMechanicalEquipmentBackendDict,
  denormalizeMechanicalEquipment,
} from './mechanicals/MechanicalEquipment';
import { AppliancesBackendDict } from './Appliances';
import { LightsBackendDict } from './lights/Lights';
import {
  DetailedDistributionSystemBackendDict,
  denormalizeDistributionSystems,
} from './mechanicals/DistributionSystem';
import { PhotovoltaicBackendDict } from './generators/Photovoltaic';
import { WaterSystemBackendDict } from './systems/WaterSystem';
import { ModelFormControl, ModelFormGroup } from './ModelForm';
import { Validators } from '@angular/forms';
import { ProjectBackendDict } from './Project';
import {
  DetailedUtilityRateBackendDict,
  denormalizeUtilityrates,
} from './utility/UtilityRate';
import { LocationBackendDict } from './Location';
import { FieldLabels } from './base';
import {
  enumValidator,
  requiredAsWarning,
  shouldHaveAtleastOneItem,
} from '@/modules/simulation/validators/helper';
import { Geocode, GeocodeBrokeredResponse } from '@/data/geocoder/models';
import { ThermostatBackendDict } from './mechanicals/Thermostat';
import { InfiltrationBackendDict } from './enclosure/Infiltration';
import { SimulationModelsState } from '@/modules/simulation/state/reducers';
import { MechanicalVentilationBackendDict } from './systems/MechanicalVentilation';
import { NaturalVentilationBackendDict } from './systems/NaturalVentilation';
import { SimulationConfig } from './SimulationConfig';

export enum SimulationSourceType {
  remrateSQL = 'remrate_sql',
  remrateXML = 'remrate_xml',
  remrateBLG = 'remrate_blg',
  ekotrope = 'ekotrope',
  hpxml = 'hpxml',
  axis = 'axis',
}

export const SimulationSourceTypeLabelMapping: Record<
  SimulationSourceType,
  string
> = {
  [SimulationSourceType.axis]: 'APEX®',
  [SimulationSourceType.remrateSQL]: 'REM/Rate SQL Export',
  [SimulationSourceType.remrateXML]: 'REM/Rate XML',
  [SimulationSourceType.remrateBLG]: 'REM/Rate BLG',
  [SimulationSourceType.ekotrope]: 'Ekotrope',
  [SimulationSourceType.hpxml]: 'HPXML',
};

export enum SimulationStatusType {
  notReady = 'not-ready',
  ready = 'ready',
  inProgress = 'in-progress',
}

export const SimulationStatusTypeLabelMapping: Record<
  SimulationStatusType,
  string
> = {
  [SimulationStatusType.notReady]: 'Not Ready',
  [SimulationStatusType.ready]: 'Ready',
  [SimulationStatusType.inProgress]: 'In Progress',
};

export enum SimulationResidenceType {
  singleDetached = 'single_detached',
  singleAttached = 'single_attached',
  townhomeEnd = 'townhome_end',
  townhomeInside = 'townhome_inside',
  aptEnd = 'apt_end',
  aptInside = 'apt_inside',
  mfWhole = 'mf_whole',
  dplxEnd = 'dplx_end',
  dplxWhole = 'dplx_whole',
  mobile = 'mobile',
}

export const SimulationResidenceTypeLabelMapping: Record<
  SimulationResidenceType,
  string
> = {
  [SimulationResidenceType.singleDetached]: 'Single Family Detached',
  [SimulationResidenceType.singleAttached]: 'Single Family Attached',
  [SimulationResidenceType.townhomeEnd]: 'Townhouse, end unit',
  [SimulationResidenceType.townhomeInside]: 'Townhouse, inside unit',
  [SimulationResidenceType.aptEnd]: 'Apartment, end unit',
  [SimulationResidenceType.aptInside]: 'Apartment, inside unit',
  [SimulationResidenceType.mfWhole]: 'Multi-family, whole building',
  [SimulationResidenceType.dplxEnd]: 'Duplex Single unit',
  [SimulationResidenceType.dplxWhole]: 'Duplex Whole building',
  [SimulationResidenceType.mobile]: 'Mobile home',
};

export class RaterUserInfo {
  id?: number;
  rater_name?: string;
  user?: number;
  user_info?: UserInfo;
}

export class LocationInfo {
  id?: number;
  lot_number?: string;
  street_line1?: string;
  street_line2?: string;
  zipcode?: string;
  city_info?: City;
  city?: number;
  county_info?: County;
  county?: number;
  subdivision?: number;
  subdivision_info?: SubdivisionInfo;
  us_state_info?: USState;
  is_multi_family?: boolean;
}

export class ProjectInfo {
  id?: number;
  builder?: number;
  builder_info?: CompanyInfo;
  builder_name?: string;
  rater_organization?: CompanyInfo;
  rater_organization_name?: string;
  provider_organization?: CompanyInfo;
  rater_of_record_info?: User;
  source_extra?: any;
}

export class Simulation {
  id?: number;
  name?: string;
  source_type?: SimulationSourceType;
  status?: SimulationStatusType;
  company?: number;
  company_info?: CompanyInfo;
  bedroom_count?: number;
  project?: number;
  project_info: ProjectInfo;
  location?: number;
  location_info?: LocationInfo;
  above_grade_walls: number[];
  foundation_walls: number[];
  above_grade_walls_info?: DetailedAboveGradeWallBackendDict[];
  foundation_walls_info?: DetailedFoundationWallBackendDict[];
  roofs_info?: DetailedRoofBackendDict[];
  roofs?: number[];
  frame_floors_info?: DetailedFrameFloorBackendDict[];
  frame_floors: number[];
  rim_joists: number[];
  rim_joists_info?: DetailedRimJoistBackendDict[];
  slabs: number[];
  slabs_info?: DetailedSlabBackendDict[];
  skylights: number[];
  skylights_info?: DetailedSkylightBackendDict[];
  doors: number[];
  doors_info?: DetailedDoorBackendDict[];
  floors_on_or_above_grade?: number;
  residence_type?: SimulationResidenceType;
  conditioned_volume?: number;
  conditioned_area?: number;
  windows: number[];
  windows_info?: DetailedWindowBackendDict[];
  mechanical_equipment?: number[];
  appliances?: number;
  appliances_info?: AppliancesBackendDict;
  lights?: number;
  lights_info?: LightsBackendDict;
  mechanical_equipment_info?: DetailedMechanicalEquipmentBackendDict[];
  hvac_distribution_systems?: number[];
  hvac_distribution_systems_info?: DetailedDistributionSystemBackendDict[];
  photovoltaics?: number[];
  photovoltaics_info?: PhotovoltaicBackendDict[];
  water_system?: number;
  water_system_info?: WaterSystemBackendDict;
  infiltration?: number;
  infiltration_info?: InfiltrationBackendDict;
}

// Labels
export const FIELD_LABELS: FieldLabels<SimulationBackendDict> = {
  name: 'Name',
  residence_type: 'Residence Type',
  unit_count: 'Unit Count',
  conditioned_area: {
    label: 'Conditioned Area',
    description: `
    The floor area of the Conditioned Space Volume within a building, minus the
    floor area of attics, floor cavities, crawlspaces, and basements below air
    sealed and insulated floors as defined by ANSI/ RESNET 301.`,
  },
  area_units: 'Area Units',
  conditioned_volume: 'Conditioned Volume',
  volume_units: 'Volume Units',
  source_type: 'Source Type',
  status: 'Status',
  company: 'Company',
  bedroom_count: 'Bedroom Count',
  project: 'Project',
  location: 'Location',
  floors_on_or_above_grade: 'Floors On Or Above Grade',
  above_grade_walls: 'Above Grade Walls',
  foundation_walls: 'Foundation Walls',
  roofs: 'Roofs',
  frame_floors: 'Frame Floors',
  rim_joists: 'Rim Joists',
  slabs: 'Slabs',
  skylights: 'Skylights',
  doors: 'Doors',
  windows: 'Windows',
  mechanical_equipment: 'Mechanical Equipment',
  appliances: 'Appliances',
  lights: 'Lights',
  hvac_distribution_systems: 'HVAC Distribution Systems',
  photovoltaics: 'Photovoltaics',
  water_system: 'Water System',
  utility_rates: 'Utility Rates',
  thermostats: 'Thermostats',
  infiltration: 'Infiltration',
  mechanical_ventilation_systems: 'Mechanical Ventilations',
  natural_ventilation: 'Natural Ventilation',
  config: 'Config',
};

export class SimulationInfoBackendDict {
  id: number;
  name: string;
}

export class SimulationBackendDict {
  id: number;
  name: string;
  residence_type: SimulationResidenceType;
  unit_count: number;
  conditioned_area: number;
  area_units: string;
  conditioned_volume: number;
  volume_units: string;
  source_type: SimulationSourceType;
  status: SimulationStatusType;
  company: number;
  bedroom_count: number;
  project: number;
  location: number;
  floors_on_or_above_grade: number;
  above_grade_walls: number[];
  foundation_walls: number[];
  roofs: number[];
  frame_floors: number[];
  rim_joists: number[];
  slabs: number[];
  skylights: number[];
  doors: number[];
  windows: number[];
  mechanical_equipment: number[];
  hvac_distribution_systems: number[];
  appliances: number;
  lights: number;
  photovoltaics: number[];
  water_system: number;
  utility_rates: number[];
  thermostats: number[];
  natural_ventilation: number;
  mechanical_ventilation_systems: number[];
  infiltration: number;
  config: number;
}

export interface DetailedSimulation extends SimulationBackendDict {
  company_info: CompanyInfo;
  bedroom_count: number;
  project: number;
  project_info: ProjectBackendDict;
  location_info: LocationBackendDict;
  above_grade_walls_info: DetailedAboveGradeWallBackendDict[];
  foundation_walls_info: DetailedFoundationWallBackendDict[];
  frame_floors_info: DetailedFrameFloorBackendDict[];
  rim_joists_info: DetailedRimJoistBackendDict[];
  roofs_info: DetailedRoofBackendDict[];
  slabs_info: DetailedSlabBackendDict[];
  skylights_info: DetailedSkylightBackendDict[];
  doors_info: DetailedDoorBackendDict[];
  windows_info: DetailedWindowBackendDict[];
  mechanical_equipment_info: DetailedMechanicalEquipmentBackendDict[];
  appliances_info: AppliancesBackendDict;
  lights_info: LightsBackendDict;
  hvac_distribution_systems_info: DetailedDistributionSystemBackendDict[];
  photovoltaics_info: PhotovoltaicBackendDict[];
  water_system_info: WaterSystemBackendDict;
  utility_rates_info: DetailedUtilityRateBackendDict[];
  thermostats_info: ThermostatBackendDict[];
  natural_ventilation_info: NaturalVentilationBackendDict;
  mechanical_ventilation_systems_info: MechanicalVentilationBackendDict[];
  infiltration_info: InfiltrationBackendDict;
  config_info: SimulationConfig;
}

export class SimulationList {
  id?: number;
  name?: string;

  source_type?: SimulationSourceType;
  status?: SimulationStatusType;
  company?: number;
  company__name?: string;
  bedroom_count?: number;

  location_geocode_info?: Geocode;
  location_geocode_response_info?: GeocodeBrokeredResponse;

  modified_date?: string;
  created_date?: string;
}

export enum EquipmentTypes {
  'heater' = 'Heater',
  'water_heater' = 'Water Heater',
  'air_conditioner' = 'Air Conditioner',
  'dehumidifier' = 'Dehumidifier',
  'ground_source_heat_pump' = 'Ground Source Heat Pump',
  'air_source_heat_pump' = 'Air Source Heat Pump',
}

export class FloorplanSimulationSummarySeed {
  id: number;
  source_id: number;
  source_label: string;
  status: string;
  exception_info: string;
}

export class FloorplanSimulationSummary {
  id: number;
  source_type: string;
  status: string;
  version: string;
  flavor: string;
  created_date: string;
  modified_date: string;
  as_string: string;
  available_analyses: Analysis[];
  seed?: FloorplanSimulationSummarySeed;
  url: string;
}

export class ClimateZone {
  pk: number;
  zone: number;
  moisture_regime: string;
  doe_zone: string;
  label: string;
}

export function createSimulationForm(
  simulation: SimulationBackendDict
): ModelFormGroup {
  return new ModelFormGroup(
    {
      id: new ModelFormControl(simulation.id),
      name: new ModelFormControl(simulation.name),
      residence_type: new ModelFormControl(simulation.residence_type, [
        enumValidator(SimulationResidenceType),
      ]),
      unit_count: new ModelFormControl(simulation.unit_count),
      source_type: new ModelFormControl(simulation.source_type),
      status: new ModelFormControl(simulation.status),
      bedroom_count: new ModelFormControl(simulation.bedroom_count, [
        Validators.required,
        Validators.min(0),
      ]),
      conditioned_area: new ModelFormControl(simulation.conditioned_area, [
        Validators.required,
        Validators.min(0),
        Validators.max(40000),
      ]),
      conditioned_volume: new ModelFormControl(simulation.conditioned_volume, [
        requiredAsWarning(),
        Validators.min(800),
        Validators.max(250000),
      ]),
      floors_on_or_above_grade: new ModelFormControl(
        simulation.floors_on_or_above_grade,
        [Validators.required, Validators.min(1), Validators.max(4)]
      ),
      location: new ModelFormControl(simulation.location, [
        Validators.required,
      ]),
      windows: new ModelFormControl(simulation.windows, [
        shouldHaveAtleastOneItem('Window'),
      ]),
      roofs: new ModelFormControl(simulation.roofs),
      frame_floors: new ModelFormControl(simulation.frame_floors),
      slabs: new ModelFormControl(simulation.slabs),
      above_grade_walls: new ModelFormControl(simulation.above_grade_walls, [
        shouldHaveAtleastOneItem('Above Grade Wall'),
      ]),
      thermostats: new ModelFormControl(
        simulation.thermostats,
        shouldHaveAtleastOneItem('Thermostats')
      ),
    },
    {
      validators: [
        controls => {
          // As per RESNET(5.3.1), the conditioned area should be greater than the
          // bedroom area + bathroom carea + min living area.
          const conditionedArea = controls.get('conditioned_area').value;
          const bedroomCount = controls.get('bedroom_count').value;
          const minConditionedAreaRequired = calculateMinArea(simulation);

          if (conditionedArea < 160) {
            return {
              conditionedAreaLessThanMin: {
                conditionedArea,
              },
            };
          } else if (minConditionedAreaRequired > conditionedArea) {
            return {
              bedroomCountExceedsConditionedArea: {
                bedroomCount,
                conditionedArea,
              },
            };
          }
        },

        controls => {
          const minVolume = calculateMinArea(simulation) * 8.0;
          const conditionedVolume = controls.get('conditioned_volume').value;
          const bedroomCount = controls.get('bedroom_count').value;

          if (conditionedVolume < 160 * 8 && conditionedVolume) {
            return {
              conditionedVolumeLessThanMin: {
                conditionedVolume,
              },
            };
          } else if (minVolume > conditionedVolume && conditionedVolume) {
            return {
              bedroomCountExceedsConditionedVolume: {
                conditionedVolume,
                bedroomCount,
              },
            };
          }
        },

        controls => {
          const frameFloorsCount = controls.get('frame_floors').value.length;
          const slabsCount = controls.get('slabs').value.length;
          if (frameFloorsCount + slabsCount === 0) {
            return {
              atleastAFrameFloorOrSlabRequired: true,
            };
          }
        },

        controls => {
          const conditionedArea = controls.get('conditioned_area').value;
          const bedroomCount = controls.get('bedroom_count').value;
          const bedRoomCountLimit = (conditionedArea - 120) / 70;

          if (bedroomCount > bedRoomCountLimit) {
            return {
              bedroomCountExceedsConditionedArea: {
                bedroomCount,
                conditionedArea,
              },
            };
          }
        },
      ],
    }
  );
}

function calculateMinArea(simulation: SimulationBackendDict): number {
  const minLivingArea = 290;
  let minBedRoomArea = 70;
  const minBathroomArea = 75;

  const bedroomCount = simulation.bedroom_count;
  const bathroomCount = bedroomCount / 2 + 0.5;

  // Studio apartments may share living space with a bedroom
  if (simulation.residence_type === SimulationResidenceType.singleDetached) {
    minBedRoomArea = 0;
  }

  const totalBedroomArea = bedroomCount * minBedRoomArea;
  const totalBathroomArea = bathroomCount * minBathroomArea;

  return minLivingArea + totalBedroomArea + totalBathroomArea;
}

export function denormalizeSimulation(
  state: SimulationModelsState
): DetailedSimulation {
  return {
    ...state.simulation.entities[state.simulation.simulationId],
    company_info: null,
    project_info: Object.values(state.project.entities)[0],
    location_info: Object.values(state.location.entities)[0],
    above_grade_walls_info: denormalizeAboveGradeWalls(state),
    foundation_walls_info: denormalizeFoundationWalls(state),
    frame_floors_info: denormalizeFrameFloors(state),
    rim_joists_info: denormalizeRimJoists(state),
    roofs_info: denormalizeRoofs(state),
    slabs_info: denormalizeSlabs(state),
    skylights_info: denormalizeSkylights(state),
    doors_info: denormalizeDoors(state),
    windows_info: denormalizeWindows(state),
    mechanical_equipment_info: denormalizeMechanicalEquipment(state),
    appliances_info: Object.values(state.appliances.entities)[0],
    lights_info: Object.values(state.lights.entities)[0],
    hvac_distribution_systems_info: denormalizeDistributionSystems(state),
    photovoltaics_info: Object.values(state.photovoltaic.entities),
    water_system_info: Object.values(state.waterSystem.entities)[0],
    utility_rates_info: denormalizeUtilityrates(state),
    thermostats_info: Object.values(state.thermostat.entities),
    infiltration_info: Object.values(state.infiltration.entities)[0],
    natural_ventilation_info: Object.values(
      state.naturalVentilation.entities
    )[0],
    mechanical_ventilation_systems_info: Object.values(
      state.mechanicalVentilation.entities
    ),
    config_info: Object.values(state.simulationConfig.entities)[0],
  };
}
