<div class="card position-relative" appFocusTarget entityName="foundationWall" entityId="{{entityId}}">
  <app-loading-overlay *ngIf="loading"></app-loading-overlay>
  <div class="card-body pb-0">
    <app-form formName="foundationWallFormGroup" [formGroup]="foundationWallFormGroup" (save)="onSave($event)">
      <div>
        <app-input formControlName="name" [label]="fieldLabels.name" cdkFocusInitial></app-input>
      </div>
      <div>
        <app-select formControlName="interior_location" [label]="fieldLabels.interior_location" [items]="interiorLocationLabelMapping"></app-select>
        <app-select formControlName="exterior_location" [label]="fieldLabels.exterior_location" [items]="exteriorLocationLabelMapping"></app-select>
      </div>
      <div>
        <app-input
          formControlName="height_above_grade"
          unit="{{lengthLabels[entity?.units]}}"
          label="Height above grade">
        </app-input>
        <app-input
          formControlName="depth_below_grade"
          unit="{{lengthLabels[entity?.units]}}"
          label="Depth below grade">
        </app-input>
      </div>
      <div>
        <app-input formControlName="perimeter_length" label="Perimeter length" unit="{{lengthLabels[entity?.units]}}"></app-input>
      </div>
      <div>
        <app-foreign-model-field
          [name]="foundationWallTypeName"
          [errorCount]="foundationWallTypeErrorCount"
          subLabel="R-value"
          [subValue]="foundationWallTypeRValue | number : '1.2-2'"
          (add)="addWallType()"
          (edit)="editWallType()"
          (remove)="removeWallType()"
          label="Wall type">
        </app-foreign-model-field>
      </div>
    </app-form>
  </div>
  <div class="card-footer p-0 text-right">
    <app-model-action-buttons
      [entityId]="entityId"
      modelName="foundationWall"
      (clone)="clone()"
      (delete)="removeFoundationWall()">
    </app-model-action-buttons>
  </div>
</div>
