import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import {
  AreaUnitLabels,
  LengthUnitLabels,
  LocationLabels,
} from '@/data/simulation/enumerations';
import { FormGroup } from '@angular/forms';
import {
  RimJoistTypeChangeDialogComponent,
  RimJoistTypeChangeDialogData,
} from '../rim-joist-type-change-dialog/rim-joist-type-change-dialog.component';
import { DIALOG_WIDTH } from '@/modules/simulation/constants';
import { selectRimJoistViewSet } from '@/modules/simulation/state/rim-joist/selectors';
import { selectRimJoistTypeNameAndError } from '@/modules/simulation/state/rim-joist-type/selectors';
import {
  removeRimJoist,
  updateRimJoist,
} from '@/modules/simulation/state/rim-joist/actions';
import {
  RimJoistBackendDict,
  ExteriorLocation,
  InteriorLocation,
  createRimJoistForm,
  FIELD_LABELS,
} from '@/data/simulation/models/enclosure/RimJoist';
import { removeRimJoistType } from '@/modules/simulation/state/rim-joist-type/actions';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { CREATE_MODE, EDIT_MODE } from '@/core/constants';
import { cloneRimJoist } from '@/modules/simulation/state/simulation/actions';

@Component({
  selector: 'app-rim-joist',
  templateUrl: './rim-joist.component.html',
  styleUrls: ['./rim-joist.component.scss'],
})
export class RimJoistComponent implements OnDestroy, OnInit {
  @Input() entityId: number;
  fieldLabels = FIELD_LABELS;

  lengthLabels = LengthUnitLabels;
  areaLabels = AreaUnitLabels;

  entity: RimJoistBackendDict = null;
  loading = false;

  private componentDestroyed$ = new Subject();
  typeViewSubscription$ = null;
  public exteriorLocationLabelMapping = {};
  public interiorLocationLabelMapping = {};

  public rimJoistTypeName: string;
  public rimJoistTypeErrorCount = 0;

  public rimJoistFormGroup: FormGroup;
  constructor(public dialog: MatDialog, public store: Store) {
    Object.values(InteriorLocation).forEach(value => {
      this.interiorLocationLabelMapping[value] = LocationLabels[value];
    });
    Object.values(ExteriorLocation).forEach(value => {
      this.exteriorLocationLabelMapping[value] = LocationLabels[value];
    });
  }

  updateTypeDetails(typeId: number) {
    if (this.typeViewSubscription$) {
      this.typeViewSubscription$.unsubscribe();
    }
    if (!typeId) {
      this.rimJoistTypeName = null;
      this.rimJoistTypeErrorCount = 1;
      return;
    }

    this.store
      .select(selectRimJoistTypeNameAndError(typeId))
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(({ name, errorCount }) => {
        this.rimJoistTypeName = name;
        this.rimJoistTypeErrorCount = errorCount;
      });
  }

  ngOnInit(): void {
    this.store
      .select(selectRimJoistViewSet(this.entityId))
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(val => val !== null)
      )
      .subscribe(({ data, loading }) => {
        this.entity = data;
        this.loading = loading;
        if (loading) return;

        if (!this.rimJoistFormGroup) {
          this.rimJoistFormGroup = createRimJoistForm(data);
        }

        this.updateTypeDetails(data.type);
      });
  }

  openEditRimJoistTypeChangeDialog(data: RimJoistTypeChangeDialogData) {
    return this.dialog.open(RimJoistTypeChangeDialogComponent, {
      width: DIALOG_WIDTH,
      disableClose: true,
      data,
    });
  }

  removeRimJoist() {
    let rimJoistName = this.entity.name;
    if (rimJoistName === null || rimJoistName === '') {
      rimJoistName = 'Untitled';
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '50%',
      data: {
        title: `Do you want to delete "${rimJoistName}" rimJoist ?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (result) {
          this.store.dispatch(removeRimJoist({ rimJoist: this.entity }));
        }
      });
  }

  clone() {
    this.store.dispatch(cloneRimJoist({ id: this.entityId }));
  }

  editRimJoistType() {
    const updatedRimJoistType = this.openEditRimJoistTypeChangeDialog({
      commit: true,
      mode: EDIT_MODE,
      entityId: this.entity.type,
    });

    updatedRimJoistType.afterClosed().subscribe(() => {
      this.updateTypeDetails(this.entity.type);
    });
  }

  addRimJoistType() {
    const newRimJoistDialog = this.openEditRimJoistTypeChangeDialog({
      commit: true,
      mode: CREATE_MODE,
      parentId: this.entityId,
    });

    newRimJoistDialog.afterClosed().subscribe(rimJoistType => {
      if (!rimJoistType) {
        return;
      }

      this.rimJoistFormGroup.patchValue({ type: rimJoistType.id });
      this.updateTypeDetails(rimJoistType.id);
    });
  }

  removeRimJoistType() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '50%',
      data: {
        title: `Do you want to delete "${this.rimJoistTypeName}" rimJoist type ?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (result) {
          this.store.dispatch(
            removeRimJoistType({
              rimJoistId: this.entityId,
              rimJoistTypeId: this.entity.type,
            })
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  onSave(rimJoistChanges: Partial<RimJoistBackendDict>): void {
    this.store.dispatch(
      updateRimJoist({
        id: this.entityId,
        rimJoistChanges: rimJoistChanges,
      })
    );
  }
}
