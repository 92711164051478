import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { first, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getInfoUser } from '@/state/selectors/info.selector';
import { AppState } from '@/state/reducers';
import { EquipmentService } from '@/data/equipment/services/equipment.service';
import { EquipmentSettings } from '@/modules/equipment/constants';
import { Equipment } from '@/data/equipment/models';

@Injectable({
  providedIn: 'root',
})
export class EquipmentPermissionService {
  constructor(
    public store: Store<AppState>,
    public equipmentService: EquipmentService
  ) {}

  canCreate(ownerCompanyId: number): Observable<ObjectPermissionResponse> {
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
    }).pipe(
      map(({ currentUser }) => {
        if (currentUser.is_superuser) {
          return new ObjectPermissionResponse(true);
        }
        if (
          EquipmentSettings.applicableCompanySlugs.includes(
            currentUser.company_info.slug
          )
        ) {
          return new ObjectPermissionResponse(true);
        }
        if (currentUser.company === ownerCompanyId) {
          return new ObjectPermissionResponse(true);
        }
        return new ObjectPermissionResponse(false);
      })
    );
  }

  canEdit(equipment: Equipment | number): Observable<ObjectPermissionResponse> {
    let equipment$: Observable<Equipment>;
    if (typeof equipment === 'number') {
      equipment$ = this.equipmentService.retrieve(equipment, {
        ignoreStore: true,
      });
    } else {
      equipment$ = of(equipment);
    }
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: equipment$,
    }).pipe(
      map(({ currentUser, entity }) => {
        if (currentUser.is_superuser) {
          return new ObjectPermissionResponse(true);
        }
        if (
          EquipmentSettings.applicableCompanySlugs.includes(
            currentUser.company_info.slug
          )
        ) {
          return new ObjectPermissionResponse(true);
        }
        if (currentUser.company === entity.owner_company) {
          return new ObjectPermissionResponse(true);
        }
        return new ObjectPermissionResponse(false);
      })
    );
  }

  canDelete(
    equipment: Equipment | number
  ): Observable<ObjectPermissionResponse> {
    return this.canEdit(equipment);
  }
}
