import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@/shared/shared.module';
import { UserDetailPageComponent } from './pages/user-detail-page/user-detail-page.component';
import { MailingListPageComponent } from './pages/user-list-page/user-list-page.component';
import { ContactCardSelectComponent } from '@/modules/user/components/contact-card-select/contact-card-select.component';
import { ContactCardEditDialogComponent } from '@/modules/user/components/contact-card-edit-dialog/contact-card-edit-dialog.component';
import { ContactCardListComponent } from '@/modules/user/pages/contact-card-list/contact-card-list.component';
import { UserChangeDialogComponent } from './components/user-change-dialog/user-change-dialog.component';
import { ProductModule } from '@/modules/product/product.module';
import { UserManagementModule } from '@/modules/user-management/user-management.module';
import { UserMetricsTabComponent } from './components/user-metrics-tab/user-metrics-tab.component';
import { SchedulingModule } from '@/modules/scheduling/scheduling.module';

@NgModule({
  declarations: [
    UserDetailPageComponent,
    MailingListPageComponent,
    ContactCardListComponent,
    ContactCardSelectComponent,
    ContactCardEditDialogComponent,
    UserChangeDialogComponent,
    UserMetricsTabComponent,
  ],
  exports: [ContactCardSelectComponent],
  imports: [
    CommonModule,
    SharedModule,
    ProductModule,
    UserManagementModule,
    SchedulingModule,
  ],
})
export class UserModule {}
