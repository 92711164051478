import { ListRequestParams } from '@/core/schemes/request-params';
import { Injectable } from '@angular/core';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { ServerResponse } from '@/core/schemes/server-response';
import { PaymentRequestProduct } from '@/data/customer-hirl/models';
import { deletePaymentRequestProductAction } from '@/state/actions/customer-hirl/payment-request';
import { tap } from 'rxjs/operators';

export class PaymentRequestProductRequestParams extends ListRequestParams {
  section?: number;
}

@Injectable({
  providedIn: 'root',
})
export class PaymentRequestProductService extends BaseDataService {
  baseUrl = `${environment.AXIS_API_V3_ROOT}/gcp_payment_request_products/`;

  list(params?: PaymentRequestProductRequestParams) {
    return this.http.get<ServerResponse<PaymentRequestProduct>>(
      `${this.baseUrl}`,
      {
        params: this.rollParams(params),
      }
    );
  }

  retrieve(
    paymentRequestProductId: number,
    options?: IDataServiceMethodOptions
  ): Observable<PaymentRequestProduct> {
    return this.http.get<PaymentRequestProduct>(
      `${this.baseUrl}${paymentRequestProductId}`,
      {}
    );
  }

  update(
    paymentRequestProduct: PaymentRequestProduct,
    paymentRequestProductId: number
  ) {
    return this.http.patch<PaymentRequestProduct>(
      `${this.baseUrl}${paymentRequestProductId}/`,
      paymentRequestProduct
    );
  }

  delete(
    paymentRequestProduct: PaymentRequestProduct,
    options?: IDataServiceMethodOptions
  ) {
    const ignoreStore = options?.ignoreStore;
    let chain = this.http.delete(`${this.baseUrl}${paymentRequestProduct.id}/`);
    if (!ignoreStore) {
      chain = chain.pipe(
        tap(_ =>
          this.store.dispatch(
            deletePaymentRequestProductAction({
              payload: paymentRequestProduct,
            })
          )
        )
      );
    }
    return chain;
  }
}
