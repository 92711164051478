import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BaseDataService } from '@/core/services/base-data-service';
import { Observable } from 'rxjs';
import { MechanicalEquipmentBackendDict } from '../models/mechanicals/MechanicalEquipment';

@Injectable({ providedIn: 'root' })
export class MechanicalEquipmentService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/mechanical_equipment/`;

  update(
    id: number,
    mechanicalEquipment: Partial<MechanicalEquipmentBackendDict>
  ): Observable<MechanicalEquipmentBackendDict> {
    return this.http.patch<MechanicalEquipmentBackendDict>(
      `${this.baseUrl}${id}/`,
      mechanicalEquipment
    );
  }

  remove(id: number): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}${id}/`);
  }
}
