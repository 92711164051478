import { FuelTypeLabels } from '@/data/simulation/enumerations';
import { DetailedMechanicalEquipmentBackendDict } from '@/data/simulation/models/mechanicals/MechanicalEquipment';
import {
  HotWaterEfficiencyUnitLabels,
  WaterHeaterLiquidVolumeLabels,
  WaterHeaterStyleLabels,
} from '@/data/simulation/models/mechanicals/WaterHeater';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-equipment-water-heater',
  templateUrl: './equipment-water-heater.component.html',
  styleUrls: ['./equipment-water-heater.component.scss'],
})
export class EquipmentWaterHeaterComponent implements OnInit {
  @Input() equipment: DetailedMechanicalEquipmentBackendDict;

  FuelTypeLabels = FuelTypeLabels;
  HeatingEfficiencyUnitLabels = HotWaterEfficiencyUnitLabels;
  WaterHeaterLiquidVolumeLabels = WaterHeaterLiquidVolumeLabels;
  WaterHeaterStyleLabels = WaterHeaterStyleLabels;

  ngOnInit(): void {}
}
