import {
  AreaUnitLabels,
  FloorCoveringLabels,
  InsulationGradeLabelMapping,
  LengthUnitLabels,
  StudTypeLabels,
} from '@/data/simulation/enumerations';
import {
  FrameFloorTypeBackendDict,
  FIELD_LABELS,
  FrameFloorTypeFormGroup,
} from '@/data/simulation/models/enclosure/FrameFloorType';
import { updateFrameFloorType } from '@/modules/simulation/state/frame-floor-type/actions';
import { selectFrameFloorTypeViewSet } from '@/modules/simulation/state/frame-floor-type/selectors';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-frame-floor-type',
  templateUrl: './frame-floor-type.component.html',
  styleUrls: ['./frame-floor-type.component.scss'],
})
export class FrameFloorTypeComponent implements OnInit {
  @Input() entityId: number;
  fieldLabels = FIELD_LABELS;

  lengthLabels = LengthUnitLabels;
  areaLabels = AreaUnitLabels;

  private componentDestroyed$ = new Subject();
  floorCoveringLabels = FloorCoveringLabels;
  loading = false;
  entity: FrameFloorTypeBackendDict = null;
  frameFloorTypeFormGroup: FormGroup;
  gradeLabels = InsulationGradeLabelMapping;
  joistTypeLabels = StudTypeLabels;

  constructor(public store: Store) {}

  ngOnInit() {
    this.store
      .select(selectFrameFloorTypeViewSet(this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(({ data, loading }) => {
        this.loading = loading;
        if (loading) {
          return;
        }

        if (this.frameFloorTypeFormGroup) {
          this.frameFloorTypeFormGroup.patchValue(data);
        } else {
          this.frameFloorTypeFormGroup = new FrameFloorTypeFormGroup(data);

          const controls = this.frameFloorTypeFormGroup.controls;
          controls['user_defined_assembly_u_value'].valueChanges.subscribe(
            value => {
              if (value) {
                controls.assembly_u_value.enable({ emitEvent: false });
              } else {
                controls.assembly_u_value.disable({ emitEvent: false });
              }
            }
          );
        }
        this.entity = data;
      });
  }

  handleSystemUValue($event: any) {
    if (
      !this.frameFloorTypeFormGroup.controls.user_defined_assembly_u_value.value
    ) {
      this.frameFloorTypeFormGroup.controls.assembly_u_value.patchValue(
        $event,
        { emitEvent: false }
      );
    }
  }

  saveFrameFloorType(changedValues: Partial<FrameFloorTypeBackendDict>) {
    const newValues = this.frameFloorTypeFormGroup.value;
    const changes = {};
    Object.entries(this.entity).forEach(([key, val]) => {
      if (!this.entity.hasOwnProperty(key)) return;
      if (val !== newValues[key]) changes[key] = newValues[key];
    });

    this.store.dispatch(
      updateFrameFloorType({
        floorTypeChanges: {
          ...changes,
          id: this.entityId,
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
