<div class="container-lg">
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-6">
          <h4>
            <span *ngIf="!isLoading">{{ rowsCount | number }} Communities</span>
            <fa-icon
              [icon]="['fas', 'spinner']"
              [spin]="true"
              *ngIf="isLoading"
            ></fa-icon>
            {{ communityDisplayNamePlural }}
          </h4>
        </div>
        <div class="col-6 text-right">
          <app-permission-mat-button [permissionResponse]="createPermissionResponse" (click)="create($event);">
            Create New
          </app-permission-mat-button>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <hr />
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <div class="row">
            <div class="col-12">
              <form [formGroup]="filterFromGroup" class="card">
                <h5 class="card-header">
                  Filters
                  <a href="#" class="small" (click)="resetFilters($event)"
                    >Reset filters</a
                  >
                </h5>
                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>Search</mat-label>
                        <app-search-input
                          formControlName="search"
                          placeholder="Type to search something"
                        ></app-search-input>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-9">
          <div class="row" *ngIf="isLoading">
            <div class="col-12">
              <mat-progress-bar
                mode="indeterminate"
                color="accent"
              ></mat-progress-bar>
            </div>
          </div>
          <table
            mat-table
            [dataSource]="rows"
            class="table"
            matSort
            [matSortActive]="storedParams.toTableSort().active"
            [matSortDirection]="storedParams.toTableSort().direction"
            (matSortChange)="onSortChange($event)"
          >
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
              <td mat-cell *matCellDef="let row">
                <app-community-display displayType="onlyNameAsLink" [community]="row"></app-community-display>
              </td>
            </ng-container>

            <ng-container matColumnDef="city__name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                City (Metro)
              </th>
              <td mat-cell *matCellDef="let row">
                {{ row.city_metro }}
              </td>
            </ng-container>

            <ng-container matColumnDef="cross_roads">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Crossraods
              </th>
              <td mat-cell *matCellDef="let row">
                {{ row.cross_roads }}
              </td>
            </ng-container>

            <ng-container matColumnDef="associations" *ngIf="!(currentUser?.is_superuser)">
              <th mat-header-cell *matHeaderCellDef>Association</th>
              <td mat-cell *matCellDef="let row">
                <app-permission-mat-button [permissionResponse]="permissions[row.id]['canRemoveAssociation']" displayType="asLink" (action)="onRemoveAssociation($event, row);">
                  Remove
                </app-permission-mat-button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>

          <div class="d-flex flex-row">
            <div class="align-self-center">
              <button
                mat-raised-button
                (click)="exportToCSV($event)"
                [disabled]="isLoading"
                class="mr-2"
                matTooltip="Export Data to CSV file"
              >
                CSV
              </button>
              <button
                mat-raised-button
                (click)="exportToExcel($event)"
                [disabled]="isLoading"
                matTooltip="Export Data to Excel file"
              >
                Excel
              </button>
            </div>
            <div class="d-flex flex-fill justify-content-end">
              <mat-paginator
                [length]="rowsCount"
                [pageIndex]="$any(storedParams.page) - 1"
                [pageSize]="storedParams.page_size"
                (page)="onPaginateChange($event)"
                aria-label="Select page"
              >
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
