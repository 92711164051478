import { RimJoistTypeBackendDict } from '@/data/simulation/models/enclosure/RimJoistType';
import { createAction, props } from '@ngrx/store';
import { StateErrors } from '../shared/base.state';

export const loadRimJoistTypes = createAction(
  '[RimJoistType] Load Foundation RimJoist Types',
  props<{ rimJoistTypes: RimJoistTypeBackendDict[] }>()
);

export const loadRimJoistTypesSuccess = createAction(
  '[RimJoistType] Load RimJoistTypes Success',
  props<{
    rimJoistTypes: RimJoistTypeBackendDict[];
    errors: StateErrors<RimJoistTypeBackendDict>;
  }>()
);

export const addRimJoistType = createAction(
  '[RimJoistType UI] Add RimJoistType',
  props<{ rimJoistId: number; rimJoistType: RimJoistTypeBackendDict }>()
);

export const removeRimJoistType = createAction(
  '[RimJoist UI] Remove RimJoistType',
  props<{ rimJoistId: number; rimJoistTypeId: number }>()
);

export const removeRimJoistTypeSuccess = createAction(
  '[RimJoistType API] Remove RimJoistType Success',
  props<{ id: number }>()
);

export const updateRimJoistType = createAction(
  '[RimJoistType UI] Update RimJoistType',
  props<{ id: number; rimJoistTypeChanges: Partial<RimJoistTypeBackendDict> }>()
);

export const updateRimJoistTypeFailure = createAction(
  '[RimJoistType API] Update RimJoistType Failure',
  props<{ id: number }>()
);
