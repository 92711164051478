import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';

export class ServerErrorDialogConfig {
  title: string;
  /* Whether show Django error key near error message
   * `non_field_errors` is hidden by default
   * */
  showErrorKey: boolean;
  data: HttpErrorResponse;

  constructor({
    title = 'Please correct errors below:',
    showErrorKey = true,
    data,
  }: {
    title?: string;
    showErrorKey?: boolean;
    data: HttpErrorResponse;
  }) {
    this.title = title;
    this.showErrorKey = showErrorKey;
    this.data = data;
  }
}

/**
 * Handles base Django response errors
 */
@Component({
  selector: 'app-server-error-dialog',
  templateUrl: './server-error-dialog.component.html',
  styleUrls: ['./server-error-dialog.component.scss'],
})
export class ServerErrorDialogComponent implements OnInit {
  public allErrors = [];

  constructor(
    public dialogRef: MatDialogRef<ServerErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public config: ServerErrorDialogConfig
  ) {}

  ngOnInit() {
    if (this.config.data.status === 500) {
      this.allErrors = [
        {
          'Internal Error':
            'Internal Error Occurred. Please Contact to Administrator',
        },
      ];
    } else if (Array.isArray(this.config.data.error)) {
      this.allErrors = this.config.data.error;
    } else {
      this.allErrors = [this.config.data.error, this.config.data.statusText];
    }
  }
}
