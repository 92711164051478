import {
  SkylightBackendDict,
  DetailedSkylightBackendDict,
} from '@/data/simulation/models/enclosure/Skylight';
import { createAction, props } from '@ngrx/store';
import { ModelErrors, StateErrors } from '../shared/base.state';

export const loadDetailedSkylights = createAction(
  '[Simulation API] Load Detailed Skylights',
  props<{ detailedSkylights: DetailedSkylightBackendDict[] }>()
);

export const loadSkylightsSuccess = createAction(
  '[Skylight API] Load Skylights Success',
  props<{
    skylights: SkylightBackendDict[];
    errors: StateErrors<SkylightBackendDict>;
  }>()
);

export const updateSkylight = createAction(
  '[Skylight UI] Update Skylight',
  props<{ id: number; skylightChanges: Partial<SkylightBackendDict> }>()
);

export const updateSkylightSuccess = createAction(
  '[Skylight API] Update Skylight Success',
  props<{
    skylight: SkylightBackendDict;
    errors: ModelErrors<SkylightBackendDict>;
  }>()
);

export const updateSkylightFailure = createAction(
  '[Skylight API] Update Skylight Failure',
  props<{ id: number }>()
);

export const removeSkylight = createAction(
  '[Skylight UI] Remove Skylight',
  props<{ skylight: SkylightBackendDict }>()
);

export const removeSkylightSuccess = createAction(
  '[Skylight API] Remove Skylight Success',
  props<{ id: number }>()
);

export const removeSkylightFailure = createAction(
  '[Skylight API] Remove Skylight Failure',
  props<{ id: number }>()
);

export const updateSkylightErrors = createAction(
  '[Skylight UI] Update Skylight Errors',
  props<{ id: number; errors: any }>()
);

export const setSkylightType = createAction(
  '[SkylightType API] Set SkylightType',
  props<{ skylightId: number; skylightTypeId: number | null }>()
);

export const setSkylightTypeSuccess = createAction(
  '[SkylightType API] Set SkylightType null',
  props<{ skylightId: number; skylightTypeId: number | null }>()
);
