import {
  DoorBackendDict,
  createDoorForm,
} from '@/data/simulation/models/enclosure/Door';
import { BaseValidator } from './base.validator';
import { ModelErrors, StateErrors } from '../state/shared/base.state';

export class DoorValidator extends BaseValidator {
  static validateForm(door: DoorBackendDict): ModelErrors<DoorBackendDict> {
    const doorForm = createDoorForm(door);
    const errors = {};
    Object.keys(door).forEach(key => {
      if (!doorForm.get(key)) return;
      errors[key] = doorForm.get(key).errors;
    });
    return {
      field: errors,
      model: doorForm.errors || {},
    };
  }

  static validate(doors: DoorBackendDict[]): StateErrors<DoorBackendDict> {
    const errors = {};
    doors.forEach(door => {
      errors[door.id] = this.validateForm(door);
    });
    return errors;
  }
}
