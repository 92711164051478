<div mat-dialog-title class="d-flex justify-content-between align-items-center">
    <h2>{{ editMode ? (equipmentTypeLabels[equipmentType] + ' Details') : ('Select ' + equipmentTypeLabels[equipmentType]) }}</h2>
    <button mat-icon-button aria-label="close dialog" mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <div *ngIf="!editMode">
        <mat-form-field appearance="outline" class="mb-2 w-100">
            <mat-label>Equipment</mat-label>
            <mat-select [formControl]="equipmentTypeControl">
                <mat-option *ngFor="let equipmentType of equipmentTypeLabels | keyvalue" [value]="equipmentType.key">{{equipmentType.value}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="w-100" *ngIf="showSelector">
            <mat-label>{{equipmentTypeLabels[equipmentType]}}</mat-label>
            <app-generic-model-autocomplete [modelClassName]="equipmentType" [formControl]="equipmentSelectControl" [filterParams]="{library_element: true}">
            </app-generic-model-autocomplete>
        </mat-form-field>
    </div>

    <div *ngIf="editMode">
        <ng-container [ngSwitch]="equipmentType">
            <app-heater *ngSwitchCase="equipmentTypes.HEATER" [entityId]="entityId"></app-heater>
            <app-water-heater *ngSwitchCase="equipmentTypes.WATER_HEATER" [entityId]="entityId"></app-water-heater>
            <app-air-source-heat-pump *ngSwitchCase="equipmentTypes.AIR_SOURCE_HEAT_PUMP" [entityId]="entityId"></app-air-source-heat-pump>
            <app-ground-source-heat-pump *ngSwitchCase="equipmentTypes.GROUND_SOURCE_HEAT_PUMP" [entityId]="entityId"></app-ground-source-heat-pump>
            <app-air-conditioner *ngSwitchCase="equipmentTypes.AIR_CONDITIONER" [entityId]="entityId"></app-air-conditioner>
            <app-dehumidifier *ngSwitchCase="equipmentTypes.DEHUMIDIFIER" [entityId]="entityId"></app-dehumidifier>
        </ng-container>
    </div>
</mat-dialog-content>
