import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Geocode, GeocodeBrokeredResponse } from '@/data/geocoder/models';
import {
  ConfirmedGeocodeResponse,
  GeocodeAddressInfo,
} from '@/modules/geocoder/components/confirm-geocode-response-dialog/confirm-geocode-response';

export class ConfirmGeocodeResponseDialogData {
  geocode: Geocode;
  constructor(geocode?: Geocode) {
    this.geocode = geocode;
  }

  get displayGeocodeAddress(): string {
    if (!this.geocode.raw_city_info) {
      return '';
    }
    let address = this.geocode.raw_street_line1;
    if (this.geocode.raw_street_line2) {
      address += ` ${this.geocode.raw_street_line2}`;
    }

    address += `, ${this.geocode.raw_city_info.name}`;
    if (this.geocode.raw_city_info.country_info?.abbr === 'US') {
      address += `, ${this.geocode.raw_city_info.county_info?.name}`;
      address += `, ${this.geocode.raw_city_info.county_info?.state}`;
    } else {
      address += `, ${this.geocode.raw_city_info.country_info?.name}`;
    }
    address += ` ${this.geocode.raw_zipcode}`;

    return address;
  }

  get geocodeAddressInfo(): GeocodeAddressInfo {
    return new GeocodeAddressInfo(
      this.geocode.raw_street_line1,
      this.geocode.raw_street_line2,
      this.geocode.raw_zipcode,
      this.geocode.raw_city_info
    );
  }
}

@Component({
  selector: 'app-confirm-geocode-response-dialog',
  templateUrl: './confirm-geocode-response-dialog.component.html',
  styleUrls: ['./confirm-geocode-response-dialog.component.scss'],
})
export class ConfirmGeocodeResponseDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ConfirmGeocodeResponseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmGeocodeResponseDialogData
  ) {}

  ngOnInit() {}

  handleConfirmAddress(geocodeBrokeredResponse: GeocodeBrokeredResponse) {
    const dialogResult = new ConfirmedGeocodeResponse({
      geocode: this.data.geocode,
      geocodeBrokeredResponse,
    });
    this.dialogRef.close(dialogResult);
  }

  handleUseAddressAsEntered() {
    const dialogResult = new ConfirmedGeocodeResponse({
      geocode: this.data.geocode,
      geocodeBrokeredResponse: null,
    });
    this.dialogRef.close(dialogResult);
  }

  handleCancel() {
    this.dialogRef.close(null);
  }
}
