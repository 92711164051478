import {
  EquipmentType,
  EquipmentTypeLabels,
} from '@/data/simulation/models/mechanicals/MechanicalEquipment';
import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { EquipmentDetailsChangeDialogComponent } from '../equipment-details-change-dialog/equipment-details-change-dialog.component';
import { DIALOG_WIDTH } from '@/modules/simulation/constants';
import { MatDialog } from '@angular/material/dialog';
import { CREATE_MODE } from '@/core/constants';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { selectMechanicalEquipmentIds } from '@/modules/simulation/state/simulation/selectors';

@Component({
  selector: 'app-mechanical-equipments',
  templateUrl: './mechanical-equipments.component.html',
  styleUrls: ['./mechanical-equipments.component.scss'],
})
export class MechanicalEquipmentsComponent {
  @Input() expanded = true;

  equipments: Record<EquipmentType, number[]>;

  equipmentLabels = EquipmentTypeLabels;
  equipmentTypes = EquipmentType;
  componentDestroyed$ = new Subject();
  equipmentIds: number[] = [];

  constructor(public dialog: MatDialog, public store: Store) {}

  ngOnInit(): void {
    this.store
      .select(selectMechanicalEquipmentIds())
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(equipmentIds => {
        this.equipmentIds = equipmentIds;
      });
  }

  addEquipmentDialog() {
    this.dialog.open(EquipmentDetailsChangeDialogComponent, {
      width: DIALOG_WIDTH,
      disableClose: true,
      data: {
        equipmentType: EquipmentType.HEATER,
        mode: CREATE_MODE,
      },
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
