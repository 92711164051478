import { createSelector } from '@ngrx/store';
import { SimulationState } from '../reducers';
import { selectSimulationFeature } from '../simulation.selectors';

export const selectLocation = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.location.entities[id]
  );

const selectLocationLoading = (id: number) =>
  createSelector(selectSimulationFeature, state => state.location.loading[id]);

export const selectLocationViewSet = (id: number) =>
  createSelector(
    selectLocation(id),
    selectLocationLoading(id),
    (data, loading) => ({ data, loading })
  );

export const selectLocationErrorCount = (id: number) =>
  createSelector(selectSimulationFeature, (state: SimulationState) => ({
    errorCount: state.location.errors[id].errorCount,
    warningCount: state.location.errors[id].warningCount,
  }));
