import { ListRequestParams } from '@/core/schemes/request-params';
import { Injectable } from '@angular/core';
import { BaseDataService } from '@/core/services/base-data-service';
import { environment } from '../../../../environments/environment';
import { ServerResponse } from '@/core/schemes/server-response';
import { Observable } from 'rxjs';
import { HIRLRPCUpdaterRequest } from '@/data/rpc/models';

export class HIRLRPCUpdaterRequestParams extends ListRequestParams {}

@Injectable({
  providedIn: 'root',
})
export class HIRLRPCUpdaterRequestService extends BaseDataService {
  baseUrl = `${environment.AXIS_API_V3_ROOT}/hirl_rpc_updater_request/`;

  list(
    params?: HIRLRPCUpdaterRequestParams
  ): Observable<ServerResponse<HIRLRPCUpdaterRequest>> {
    return this.http.get<ServerResponse<HIRLRPCUpdaterRequest>>(
      `${this.baseUrl}`,
      {
        params: this.rollParams(params),
      }
    );
  }

  retrieve(hirlRPCUpdaterRequestId: number): Observable<HIRLRPCUpdaterRequest> {
    return this.http.get<HIRLRPCUpdaterRequest>(
      `${this.baseUrl}${hirlRPCUpdaterRequestId}`
    );
  }

  create(formData: FormData): Observable<HIRLRPCUpdaterRequest> {
    return this.http.post<HIRLRPCUpdaterRequest>(`${this.baseUrl}`, formData);
  }
}
