import { createReducer, on } from '@ngrx/store';
import * as GroundSourceHeatPumpActions from './actions';
import { GroundSourceHeatPumpBackendDict } from '@/data/simulation/models/mechanicals/GroundSourceHeatPump';
import { BaseState } from '../shared/base.state';
import { addStateErrorCount } from '../helper';

export type GroundSourceHeatPumpState =
  BaseState<GroundSourceHeatPumpBackendDict>;

export const initialState: GroundSourceHeatPumpState = {
  entities: {},
  loading: {},
  errors: {},
};

export const reducer = createReducer(
  initialState,
  on(
    GroundSourceHeatPumpActions.loadGroundSourceHeatPumpsSuccess,
    (state, { groundSourceHeatPumps, errors }) => {
      const entities = groundSourceHeatPumps.reduce(
        (acc, groundSourceHeatPump) => ({
          ...acc,
          [groundSourceHeatPump.id]: groundSourceHeatPump,
        }),
        {}
      );
      const loading = groundSourceHeatPumps.reduce(
        (acc, groundSourceHeatPump) => ({
          ...acc,
          [groundSourceHeatPump.id]: false,
        }),
        {}
      );

      return {
        entities: {
          ...state.entities,
          ...entities,
        },
        loading: {
          ...state.loading,
          ...loading,
        },
        errors: {
          ...state.errors,
          ...addStateErrorCount(errors),
        },
      };
    }
  ),

  on(
    GroundSourceHeatPumpActions.updateGroundSourceHeatPump,
    (state, { groundSourceHeatPumpChanges }) => ({
      ...state,
      loading: {
        ...state.loading,
        [groundSourceHeatPumpChanges.id]: true,
      },
    })
  ),

  on(
    GroundSourceHeatPumpActions.updateGroundSourceHeatPumpFailure,
    (state, { id }) => ({
      ...state,
      loading: {
        ...state.loading,
        [id]: false,
      },
    })
  )
);
