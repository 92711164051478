import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BaseDataService } from '@/core/services/base-data-service';
import { Observable } from 'rxjs';
import { SkylightTypeBackendDict } from '../models/enclosure/SkylightType';
import { ListRequestParams } from '@/core/schemes/request-params';
import { ServerResponse } from '@/core/schemes/server-response';

export class SkylightTypeParams extends ListRequestParams {}

@Injectable({ providedIn: 'root' })
export class SkylightTypeService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/skylight_type/`;
  public parentUrl = `${environment.AXIS_API_V3_ROOT}/skylight/`;

  list(
    params: SkylightTypeParams
  ): Observable<ServerResponse<SkylightTypeBackendDict>> {
    return this.http.get<ServerResponse<SkylightTypeBackendDict>>(
      `${this.baseUrl}`,
      {
        params: this.rollParams(params),
      }
    );
  }

  create(
    skylightId: number,
    skylightType: SkylightTypeBackendDict
  ): Observable<SkylightTypeBackendDict> {
    return this.http.post<SkylightTypeBackendDict>(
      `${this.parentUrl}${skylightId}/skylight_type/`,
      skylightType
    );
  }

  update(
    skylightTypeChanges: Partial<SkylightTypeBackendDict>
  ): Observable<SkylightTypeBackendDict> {
    return this.http.patch<SkylightTypeBackendDict>(
      `${this.baseUrl}${skylightTypeChanges.id}/`,
      skylightTypeChanges
    );
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}${id}/`);
  }
}
