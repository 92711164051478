export class UniqueSet<T> {
  private items: Set<T>;
  private getKey: (item: T) => any;

  constructor(getKey: (item: T) => any) {
    this.items = new Set<T>();
    this.getKey = getKey;
  }

  // Get the number of items in the set
  get length(): number {
    return Array.from(this.items).length;
  }

  // Add an item to the set
  add(item: T): void {
    if (!this.has(this.getKey(item))) {
      this.items.add(item);
    }
  }

  // Check if an item with a specific key exists in the set
  has(item: T): boolean {
    const key = this.getKey(item);
    for (const existingItem of this.items) {
      if (this.getKey(existingItem) === key) {
        return true;
      }
    }
    return false;
  }

  // Remove an item with a specific key from the set
  delete(item: T): boolean {
    const key = this.getKey(item);
    for (const existingItem of this.items) {
      if (this.getKey(existingItem) === key) {
        return this.items.delete(existingItem);
      }
    }
    return false;
  }

  // Removes all items
  clear() {
    this.items.clear();
  }

  // Get all items as an array
  toArray(): T[] {
    return Array.from(this.items);
  }
}
