<ng-template #template>
  <ng-content></ng-content>
</ng-template>
<ng-container [ngSwitch]="displayType">
  <ng-container *ngSwitchCase="permissionMatButtonDisplayType.asButton">
        <span *ngIf="editPermissionIsLoading; else btnTemplate" [matTooltip]="'Loading... Please wait'">
            <button mat-raised-button color="accent" disabled>
               <ng-container *ngTemplateOutlet="template"></ng-container> <fa-icon [icon]="['fas', 'spinner']"
                                                                                   [spin]="true"></fa-icon>
            </button>
        </span>

    <ng-template #btnTemplate>
      <button [routerLink]="routerLink" mat-raised-button color="accent" *ngIf="editPermissionResponse.hasPermission">
        <ng-container *ngTemplateOutlet="template"></ng-container>
      </button>
      <span [matTooltip]="editPermissionResponse.message" *ngIf="!editPermissionResponse.hasPermission">
            <button mat-raised-button color="accent" disabled>
                <ng-container *ngTemplateOutlet="template"></ng-container>
            </button>
        </span>
    </ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="permissionMatButtonDisplayType.asLink">
    <span *ngIf="editPermissionIsLoading; else linkTemplate" [matTooltip]="'Loading... Please wait'">
        <fa-icon [icon]="['fas', 'spinner']" [spin]="true"></fa-icon>
    </span>

    <ng-template #linkTemplate>
      <a [routerLink]="routerLink" *ngIf="editPermissionResponse.hasPermission">
        <ng-container *ngTemplateOutlet="template"></ng-container>
      </a>
      <span [matTooltip]="editPermissionResponse.message" *ngIf="!editPermissionResponse.hasPermission">
            <a disabled>
                <ng-container *ngTemplateOutlet="template"></ng-container>
            </a>
        </span>
    </ng-template>
  </ng-container>
</ng-container>
