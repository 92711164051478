import { AppState } from '@/state/reducers';
import { getFloorplan } from '@/state/selectors/floorplan/floorplan.selector';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { SimulationService } from '@/data/simulation/services/simulation.service';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { first, filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-simulation-compare',
  templateUrl: './simulation-compare-component.html',
  styleUrls: ['./simulation-compare-component.scss'],
})
export class SimulationCompareComponent implements OnInit, OnDestroy {
  public compare;
  public initialized: boolean;
  public inValidSimulation = false;
  public hideMatching = false;

  private componentDestroyed$ = new Subject();

  constructor(
    private simulationService: SimulationService,
    public store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.store
      .select(getFloorplan)
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(val => val !== null)
      )
      .subscribe(floorPlan => {
        if (floorPlan.simulation) {
          this.simulationService
            .compare(floorPlan.simulation)
            .pipe(first())
            .subscribe(
              data => {
                this.compare = data;
                this.initialized = true;
              },
              error => {
                this.inValidSimulation = true;
                this.initialized = true;
              }
            );
        } else {
          this.inValidSimulation = true;
          this.initialized = true;
        }
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
