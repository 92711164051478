import { createSelector } from '@ngrx/store';
import { SimulationState } from '../reducers';
import { selectSimulationFeature } from '../simulation.selectors';
import { SimulationBackendDict } from '@/data/simulation/models/simulation';

export const selectSimulationLoadingStatus = () =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.simulation.loading
  );

export const selectSimulation = () =>
  createSelector(
    selectSimulationFeature,
    selectSimulationId(),
    (state: SimulationState, simulationId) =>
      state.simulation.entities[simulationId]
  );

export const selectSimulationViewSet = () =>
  createSelector(
    selectSimulationFeature,
    selectSimulationId(),
    (state: SimulationState, simulationId) => ({
      data: state.simulation.entities[simulationId],
      loading: state.simulation.loading[simulationId],
    })
  );

export const selectSimulationId = () =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.simulation.simulationId
  );
export const selectAboveGradeWallIds = () =>
  createSelector(
    selectSimulation(),
    (simulation: SimulationBackendDict) => simulation.above_grade_walls
  );

export const selectAllFrameFloorIds = () =>
  createSelector(
    selectSimulation(),
    (simulation: SimulationBackendDict) => simulation.frame_floors
  );

export const selectAllRoofIds = () =>
  createSelector(
    selectSimulation(),
    (simulation: SimulationBackendDict) => simulation.roofs
  );

export const selectFoundationWallIds = () =>
  createSelector(
    selectSimulation(),
    (simulation: SimulationBackendDict) => simulation.foundation_walls
  );

export const selectSlabIds = () =>
  createSelector(
    selectSimulation(),
    (simulation: SimulationBackendDict) => simulation.slabs
  );

export const selectSkylightIds = () =>
  createSelector(
    selectSimulation(),
    (simulation: SimulationBackendDict) => simulation.skylights
  );

export const selectRimJoistIds = () =>
  createSelector(
    selectSimulation(),
    (simulation: SimulationBackendDict) => simulation.rim_joists
  );

export const selectDoorIds = () =>
  createSelector(
    selectSimulation(),
    (simulation: SimulationBackendDict) => simulation.doors
  );

export const selectWindowIds = () =>
  createSelector(
    selectSimulation(),
    (simulation: SimulationBackendDict) => simulation.windows
  );

export const selectAppliancesId = () =>
  createSelector(
    selectSimulation(),
    (simulation: SimulationBackendDict) => simulation.appliances
  );

export const selectLightsId = () =>
  createSelector(
    selectSimulation(),
    (simulation: SimulationBackendDict) => simulation.lights
  );

export const selectMechanicalEquipmentIds = () =>
  createSelector(
    selectSimulation(),
    (simulation: SimulationBackendDict) => simulation.mechanical_equipment
  );

export const selectDistributionSystemIds = () =>
  createSelector(
    selectSimulation(),
    (simulation: SimulationBackendDict) => simulation.hvac_distribution_systems
  );

export const selectPhotovoltaicIds = () =>
  createSelector(
    selectSimulation(),
    (simulation: SimulationBackendDict) => simulation.photovoltaics
  );

export const selectThermostatIds = () =>
  createSelector(
    selectSimulation(),
    (simulation: SimulationBackendDict) => simulation.thermostats
  );

export const selectInfiltrationId = () =>
  createSelector(
    selectSimulation(),
    (simulation: SimulationBackendDict) => simulation.infiltration
  );

export const selectMechanicalVentilationIds = () =>
  createSelector(
    selectSimulation(),
    (simulation: SimulationBackendDict) =>
      simulation.mechanical_ventilation_systems
  );
