<ng-container [ngSwitch]="display">
    <ng-container *ngSwitchCase="stateDisplay.flat">
        {{ stateLabelMapping[homeStatus.state] }}
    </ng-container>

    <ng-container *ngSwitchCase="stateDisplay.badge">
        <ng-container [ngSwitch]="homeStatus.state">
            <ng-container *ngSwitchDefault>
                <span class="badge badge-info">
                    {{ stateLabelMapping[homeStatus.state] }}
                </span>
            </ng-container>
            <ng-container *ngSwitchCase="homeStatusState.complete">
                <span class="badge badge-success">
                    {{ stateLabelMapping[homeStatus.state] }}
                </span>
            </ng-container>
            <ng-container *ngSwitchCase="homeStatusState.failed">
                <span class="badge badge-danger">
                    {{ stateLabelMapping[homeStatus.state] }}
                </span>
            </ng-container>
            <ng-container *ngSwitchCase="homeStatusState.abandoned">
                <span class="badge badge-danger">
                    {{ stateLabelMapping[homeStatus.state] }}
                </span>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-container>
