import {
  FrameFloorTypeBackendDict,
  FrameFloorTypeFormGroup,
} from '@/data/simulation/models/enclosure/FrameFloorType';
import { BaseValidator } from './base.validator';
import { ModelErrors, StateErrors } from '../state/shared/base.state';

export class FrameFloorTypeValidator extends BaseValidator {
  static validateForm(
    frameFloorType: FrameFloorTypeBackendDict
  ): ModelErrors<FrameFloorTypeBackendDict> {
    const form = new FrameFloorTypeFormGroup(frameFloorType);
    return form.getFieldAndModelErros();
  }

  static validate(
    frameFloorTypes: FrameFloorTypeBackendDict[]
  ): StateErrors<FrameFloorTypeBackendDict> {
    const errors = {};
    frameFloorTypes.forEach(frameFloorType => {
      errors[frameFloorType.id] = this.validateForm(frameFloorType);
    });
    return errors;
  }
}
