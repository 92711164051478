import { Component, Input, ViewEncapsulation } from '@angular/core';
import { BaseAppControlValueAccessor } from '../base-control-value-accessor.directive';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class CheckboxComponent extends BaseAppControlValueAccessor {
  @Input() label = '';
  @Input() presentErrorsInline = true;
}
