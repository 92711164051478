<h2 mat-dialog-title>
    <ng-container *ngIf="!isEditForm">
        Add New COI Document
    </ng-container>
    <ng-container *ngIf="isEditForm">
        Edit COI Document
    </ng-container>
</h2>
<mat-dialog-content *ngIf="initialized">
    <form [formGroup]="form" class="mb-2">
        <div class="row" *ngIf="currentUser?.company_info?.slug === customerHIRLSettings.companySlug || currentUser.is_superuser || !isEditForm">
            <div class="col-12">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Document</mat-label>
                    <app-file-input [formControl]="form.controls['document']"></app-file-input>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <mat-form-field class="w-100 mb-2">
                    <mat-label>Description</mat-label>
                    <textarea matInput type="text" placeholder="Description" formControlName="description"></textarea>
                </mat-form-field>
            </div>
        </div>
        <div class="row" *ngIf="showInsuranceTypeField">
          <div class="col-12">
            <mat-form-field class="w-100 mb-2">
              <mat-label>Insurance Type</mat-label>
              <mat-select aria-label="Insurance Type" formControlName="insurance_type">
                <mat-option *ngFor="let item of COIDocumentInsuranceTypeMapping | keyvalue"
                            [value]="item.key">
                  {{item.value}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row" *ngIf="currentUser?.company_info?.slug === customerHIRLSettings.companySlug || currentUser.is_superuser">
            <div class="col-12">
                <mat-form-field appearance="outline" class="w-100 mb-2">
                    <mat-label>Policy number</mat-label>
                    <input matInput type="text" placeholder="Policy number" formControlName="policy_number">
                </mat-form-field>
            </div>
        </div>
        <div class="row" *ngIf="currentUser?.company_info?.slug === customerHIRLSettings.companySlug || currentUser.is_superuser">
            <div class="col-6">
                <mat-form-field appearance="outline" class="w-100 mb-2">
                    <mat-label>Start Date</mat-label>
                    <input matInput type="text" placeholder="Start Date" formControlName="start_date" [matDatepicker]="startDatePicker">
                    <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #startDatePicker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field appearance="outline" class="w-100 mb-2">
                    <mat-label>Expiration Date</mat-label>
                    <input matInput type="text" placeholder="Expiration Date" formControlName="expiration_date" [matDatepicker]="expirationDatePicker">
                    <mat-datepicker-toggle matSuffix [for]="expirationDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #expirationDatePicker></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
    </form>
</mat-dialog-content>
<div class="row">
    <div class="col-12">
        <hr>
    </div>
</div>
<mat-dialog-actions align="center">
    <button mat-button color="accent" mat-raised-button (click)="save($event)">Save</button>
    <button mat-button [mat-dialog-close]="null" color="primary" mat-raised-button>Cancel</button>
</mat-dialog-actions>
