import { AreaUnit } from '../../enumerations';
import { DoorTypeBackendDict } from './DoorType';
import { ModelFormControl, ModelFormGroup } from '../ModelForm';
import { Validators } from '@angular/forms';
import { FieldLabels } from '../base';
import { SimulationModelsState } from '@/modules/simulation/state/reducers';

export const DEFAULT_DOOR = {
  id: null,
  name: '',
  type: null,
  above_grade_wall: null,
  foundation_wall: null,
  area: 0,
  area_units: 'sq_ft',
};

// Labels
export const FIELD_LABELS: FieldLabels<DoorBackendDict> = {
  name: 'Name',
  type: 'Type',
  above_grade_wall: 'Above Grade Wall',
  foundation_wall: 'Foundation Wall',
  area: 'Area',
  area_units: 'Area Units',
};

export interface DoorBackendDict {
  id: number;
  type: number;
  name: string;
  above_grade_wall: number;
  foundation_wall: number;
  area: number;
  area_units: AreaUnit;
}

export interface DetailedDoorBackendDict extends DoorBackendDict {
  type_info: DoorTypeBackendDict;
}

export function createDoorForm(door: DoorBackendDict) {
  return new ModelFormGroup(
    {
      id: new ModelFormControl(door.id, Validators.required),
      name: new ModelFormControl(door.name, Validators.required),
      type: new ModelFormControl(door.type, Validators.required),
      above_grade_wall: new ModelFormControl(door.above_grade_wall),
      foundation_wall: new ModelFormControl(door.foundation_wall),
      area: new ModelFormControl(door.area, [
        Validators.required,
        Validators.min(0.1),
      ]),
    },
    {
      validators: [validateAttachedWall],
    }
  );
}

function validateAttachedWall(controls: ModelFormGroup) {
  const aboveGradeWall = controls.get('above_grade_wall').value;
  const foundationWall = controls.get('foundation_wall').value;
  if (aboveGradeWall === null && foundationWall === null) {
    return { mustBeAtachedTowall: { name: 'Door' } };
  }

  if (aboveGradeWall !== null && foundationWall !== null) {
    return { atachedToMultiplewall: { name: 'Door' } };
  }
}

export function denormalizeDoors(state: SimulationModelsState) {
  const denormalizedDoors: DetailedDoorBackendDict[] = [];
  for (const door of Object.values(state.door.entities)) {
    denormalizedDoors.push({
      ...door,
      type_info: state.doorType.entities[door.type],
    });
  }
  return denormalizedDoors;
}
