import { AuthGuard } from '@/core/guards';
import { FlatpageEditPageFormComponent } from '@/modules/flatpage/pages/flatpage-change-form/flatpage-edit-page-form.component';

export const FlatpageRouting = [
  {
    path: 'flatpages',
    canActivate: [AuthGuard],
    children: [
      {
        path: ':flatPageId/edit',
        children: [
          {
            path: '',
            component: FlatpageEditPageFormComponent,
            resolve: {},
          },
        ],
      },
    ],
  },
];
