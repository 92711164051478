export class ObservationTypeInfo {
  id?: number;
  name?: string;
}

export class ObservationType {
  id?: number;
  name?: string;
}

export class Observation {
  id?: number;
  observation_type?: number;
  observation_type_info?: ObservationTypeInfo;
}
