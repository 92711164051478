import {
  ThermostatBackendDict,
  createThermostatForm,
} from '@/data/simulation/models/mechanicals/Thermostat';
import { ModelFormGroup } from '@/data/simulation/models/ModelForm';
import {
  removeThermostat,
  updateThermostat,
} from '@/modules/simulation/state/thermostat/actions';
import { selectThermostatViewSet } from '@/modules/simulation/state/thermostat/selectors';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-thermostat',
  templateUrl: './thermostat.component.html',
  styleUrls: ['./thermostat.component.scss'],
})
export class ThermostatComponent implements OnInit {
  @Input() entityId: number;

  thermostatForm: ModelFormGroup;
  entity: ThermostatBackendDict;
  loading: boolean;

  private componentDestroyed$ = new Subject();

  constructor(public dialog: MatDialog, public store: Store) {}

  ngOnInit(): void {
    this.store
      .select(selectThermostatViewSet(this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(({ data, loading }) => {
        this.entity = data;
        this.loading = loading;
        if (loading) return;

        if (this.thermostatForm) {
          this.thermostatForm.patchValue(data);
        } else {
          this.thermostatForm = createThermostatForm(data);
        }
      });
  }

  onSave(thermostatChanges: Partial<ThermostatBackendDict>): void {
    this.store.dispatch(
      updateThermostat({
        thermostatChanges: thermostatChanges,
      })
    );
  }

  removeThermostat() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '50%',
      data: {
        title: `Do you want to delete the Thermostat?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (result) {
          this.store.dispatch(
            removeThermostat({
              thermostat: this.entity,
            })
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
