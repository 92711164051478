<div class="card position-relative">
    <app-loading-overlay *ngIf="loading"></app-loading-overlay>
    <div class="card-header">
        <span>Dryer</span>
    </div>
    <div class="card-body pb-0">
        <app-form formName="clothesDryerForm" [formGroup]="form" (save)="onSave($event)">
            <div>
                <app-select formControlName="clothes_dryer_location" [label]="fieldLabels.clothes_dryer_location" [items]="locationLabels"></app-select>
                <app-select formControlName="clothes_dryer_fuel" [label]="fieldLabels.clothes_dryer_fuel" [items]="fuelTypeLabels"></app-select>
            </div>
            <div>
                <app-input formControlName="clothes_dryer_efficiency" [label]="fieldLabels.clothes_dryer_efficiency"></app-input>
                <app-select formControlName="clothes_dryer_efficiency_unit" label="Efficiency Unit" [items]="dryerEfficiencyUnitLabels"></app-select>
            </div>
            <div>
                <app-checkbox formControlName="is_moisture_sensing_dryer" [label]="fieldLabels.is_moisture_sensing_dryer"></app-checkbox>
            </div>
        </app-form>
    </div>
</div>
