import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { User } from '@/data/core/models/user';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, map, mergeMap, take, takeUntil } from 'rxjs/operators';
import { Company, CompanyFlatList, CompanyType } from '@/data/company/models';
import { USStateService } from '@/data/geographic/services/us-state.service';
import {
  ComponentCompanyType,
  componentCompanyTypeDataMap,
} from '@/modules/company/constants';
import {
  IExcelJson,
  UIHelperService,
} from '@/shared/services/ui-helper.service';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { toggleLoading } from '@/state/actions/app.actions';
import { RelationshipService } from '@/data/relationship/services/relationship';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CustomerETOSettings } from '@/modules/customer-eto/constants';
import {
  CompanyRequestParams,
  CompanyService,
} from '@/data/company/services/company-base.service';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { CompanyPermissionService } from '@/modules/company/services/company-permission-service';
import {
  CompanyChangeDialogComponent,
  CompanyChangeDialogData,
} from '@/modules/company/components/company-change-dialog/company-change-dialog.component';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import * as _ from 'lodash';
import { ServerResponse } from '@/core/schemes/server-response';
import { HttpErrorResponse } from '@angular/common/http';
import { UniqueSet } from '@/core/utils';
import { Country } from '@/data/geographic/models';

@Component({
  selector: 'app-company-list-page',
  templateUrl: './company-list-page.component.html',
  styleUrls: ['./company-list-page.component.scss'],
})
export class CompanyListPageComponent implements OnInit, OnDestroy {
  public selectAllEntities = false;
  public initialized = false;

  public displayedColumns = [];

  public entities: CompanyFlatList[];
  public entitiesCount: number;
  public selectedEntities: UniqueSet<CompanyFlatList> =
    new UniqueSet<CompanyFlatList>((item: CompanyFlatList) => item.id);

  public entitiesIsLoading = true;

  public currentUser: User;
  public filterFromGroup: FormGroup;

  public customerHIRLSettings = CustomerHIRLSettings;
  public customerETOSettings = CustomerETOSettings;

  public companyType: string;
  public componentCompanyType = ComponentCompanyType;
  public companyDisplayNamePlural: string;

  public createPermissionResponse: Observable<ObjectPermissionResponse>;

  public defaultParams: CompanyRequestParams = new CompanyRequestParams(
    1,
    '',
    '-id',
    25
  );
  public storedParams: CompanyRequestParams;

  public overwritePropertyValues = {};
  public overwritePropertyLabels = {};
  public excludedProperties: string[];

  private listSubscription$: Subscription;
  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private injector: Injector,
    private dialog: MatDialog,
    private companyService: CompanyService,
    private usStateService: USStateService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private relationshipService: RelationshipService,
    private companyPermissionService: CompanyPermissionService,
    private uiHelperService: UIHelperService
  ) {}

  ngOnInit() {
    this.setupFilterForm();

    this.activatedRoute.params
      .pipe(
        takeUntil(this.componentDestroyed$),
        mergeMap(params => {
          this.entities = [];
          this.entitiesCount = 0;

          return forkJoin({
            queryParams: this.activatedRoute.queryParams.pipe(first()),
            currentUser: this.store.select(getInfoUser).pipe(first()),
          }).pipe(
            map(({ queryParams, currentUser }, index) => {
              return { params, queryParams, currentUser };
            })
          );
        })
      )
      .subscribe(({ params, queryParams, currentUser }) => {
        const indexOfType = Object.values(CompanyType).indexOf(
          params.companyType as unknown as CompanyType
        );
        const companyType = Object.keys(CompanyType)[indexOfType];

        this.companyType = CompanyType[companyType];
        this.companyDisplayNamePlural =
          componentCompanyTypeDataMap[
            CompanyType[companyType]
          ].displayNamePlural;
        this.storedParams = _.cloneDeep(this.defaultParams);
        this.storedParams.page_size =
          this.uiHelperService.getUISettings().rowsPerPage;

        this.storedParams.assignQueryParams(queryParams, [
          'city__country',
          'city__county__state',
        ]);

        this.storedParams.is_attached = 'attached';
        this.storedParams.company_type = params.companyType;
        this.currentUser = currentUser;

        this.createPermissionResponse = this.companyPermissionService.canCreate(
          CompanyType[companyType]
        );

        this.displayedColumns = [
          'name',
          'street_line1',
          'state',
          'zipcode',
          'office_phone',
          'actions',
        ];
        if (this.showETOColumn) {
          this.displayedColumns.splice(
            this.displayedColumns.length - 1,
            0,
            ...['eto_account']
          );
        }
        if (this.showGCPCertificateExpirationDateColumn) {
          this.displayedColumns.splice(
            this.displayedColumns.length - 1,
            0,
            ...['gcp_certificate_expiration_date']
          );
        }

        this.initialized = true;

        this.hydrateForm();
        this.list();
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  setupFilterForm() {
    this.filterFromGroup = this.fb.group({
      search: [null],
      city__country_info: [null],
      city__county__state_info: [null],
      customer_hirl_has_active_products: [null],
    });

    this.filterFromGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        let params = Object.assign(this.defaultParams, values);
        this.selectAllEntities = false;
        this.selectedEntities.clear();

        params = Object.assign(params, {
          city__country: params.city__country_info?.map(
            (country: Country) => country.id
          ),
          city__county__state: params.city__county__state_info,
        });

        this.storedParams.assignFilterFormValues({
          formValues: params,
          excludedProperties: [
            'ordering',
            'city__country_info',
            'city__county__state_info',
          ],
        });
        this.list();
      });
  }

  list() {
    this.entitiesIsLoading = true;

    // populate params to query string
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.storedParams.toQueryParams(),
      replaceUrl: true,
    });

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }

    this.getAppliedFilters();

    this.listSubscription$ = this.companyService
      .flat_list(this.storedParams)
      .pipe(takeUntil(this.componentDestroyed$), take(1))
      .subscribe({
        next: (data: ServerResponse<CompanyFlatList>) => {
          this.entities = data.results;
          this.entities = data.results.map(entity => ({
            ...entity,
            hasActiveProducts: entity.gcp_certificate_expiration_date
              ? this.isActiveProduct(entity.gcp_certificate_expiration_date)
              : 'No',
          }));

          this.entitiesCount = data.count;
          this.entitiesIsLoading = false;

          if (this.selectAllEntities) {
            this.entities.forEach((row: CompanyFlatList) => {
              this.selectedEntities.add(row);
            });
          }
        },
        error: (error: HttpErrorResponse) => {
          if (error.status === 400) {
            // to avoid cycle loop, reload list only if invalid param in query detected
            let invalidQueryParam = false;
            for (const key of Object.keys(error.error)) {
              if (this.storedParams.hasOwnProperty(key)) {
                invalidQueryParam = true;
                delete this.storedParams[key];
              }
            }

            if (invalidQueryParam) {
              this.list();
            }
          }
        },
      });
  }

  hydrateForm() {
    this.filterFromGroup.patchValue(this.storedParams, {
      emitEvent: false,
      onlySelf: true,
    });

    this.filterFromGroup.patchValue(
      {
        city__country__abbr_info: this.storedParams.city__country,
      },
      { emitEvent: false, onlySelf: true }
    );

    this.filterFromGroup.patchValue(
      {
        city__county__state_info: this.storedParams.city__county__state,
      },
      { emitEvent: false, onlySelf: true }
    );
  }

  onSort($event: Sort) {
    this.storedParams.ordering = `${$event.direction === 'desc' ? '-' : ''}${
      $event.active
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  onPaginateChange($event: PageEvent) {
    this.uiHelperService.getUISettings().rowsPerPage = $event.pageSize;
    this.storedParams.page_size = $event.pageSize;
    this.storedParams.page = $event.pageIndex + 1;
    this.list();
  }

  getAppliedFilters(): {
    [key: string]: string;
  } {
    this.overwritePropertyValues = {
      city__country: this.filterFromGroup.value.city__country_info?.map(
        (country: Country) => country.name
      ),
    };
    this.overwritePropertyLabels = {
      city__country: 'Country',
      city__county__state: 'US State',
    };

    return this.storedParams.toAppliedFilters({
      overwritePropertyValues: this.overwritePropertyValues,
      overwritePropertyLabels: this.overwritePropertyLabels,
    });
  }

  get showETOColumn() {
    const companySlugs = [CustomerETOSettings.companySlug, 'peci'];
    return (
      (this.companyType === this.componentCompanyType.rater ||
        this.companyType === this.componentCompanyType.builder) &&
      (companySlugs.includes(this.currentUser.company_info?.slug) ||
        this.currentUser.is_superuser)
    );
  }

  get showGCPCertificateExpirationDateColumn() {
    const companySlugs = [CustomerHIRLSettings.companySlug];
    return (
      this.companyType === this.componentCompanyType.manufacturer &&
      (companySlugs.includes(this.currentUser.company_info?.slug) ||
        this.currentUser.is_superuser)
    );
  }

  onRemoveAssociation($event: MouseEvent, company: CompanyFlatList) {
    $event.preventDefault();

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '45%',
      data: {
        title: 'Remove Association ?',
        content: `Do you want to delete association with: ${company.name} organization ?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (!result) {
          return;
        }
        this.store.dispatch(toggleLoading({ payload: true }));
        this.companyService
          .relationshipsRemove(company.id)
          .pipe(first())
          .subscribe(() => {
            this.list();
            this.store.dispatch(toggleLoading({ payload: false }));
          });
      });
  }

  create($event: MouseEvent) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(CompanyChangeDialogComponent, {
      width: '55%',
      disableClose: true,
      autoFocus: false,
      data: {
        company: {
          company_type: this.companyType,
        },
      } as CompanyChangeDialogData,
    });

    dialogRef.afterClosed().subscribe((result?: Company) => {
      if (!result) {
        return;
      }
      this.list();
      this.uiHelperService.openSnackBar(
        `Company ${result.name} Successfully Created`
      );
    });
  }

  exportToExcel($event: MouseEvent) {
    $event.preventDefault();
    const excelData: Array<IExcelJson> = [];

    const headers: any = {
      A: 'AXIS ID',
      B: 'Name',
      C: 'Address',
      D: 'State',
      E: 'Zip Code',
      ...(this.showETOColumn && { F: 'ETO Account' }),
      [this.showETOColumn ? 'G' : 'F']: 'Office Phone',
    };

    const udt: IExcelJson = { data: [headers], skipHeader: true };

    this.entities.forEach((company: CompanyFlatList) => {
      udt.data.push({
        A: company.id,
        B: company.name,
        C: company.address,
        D: company.state,
        E: company.zipcode,
        ...(this.showETOColumn && { F: company.eto_account }),
        [this.showETOColumn ? 'G' : 'F']: company.office_phone,
      });
    });

    excelData.push(udt);
    this.uiHelperService.exportJsonToExcel(excelData, 'companies');
  }

  exportToCSV($event: MouseEvent) {
    $event.preventDefault();
    const data = [];
    this.entities.forEach((company: CompanyFlatList) => {
      data.push({
        'AXIS ID': company.id,
        'First Name': company.name,
        Address: company.address,
        State: company.state,
        'Zip Code': company.zipcode,
        'ETO Account': company.eto_account,
        'Office Phone': company.office_phone,
      });
    });

    const columns = [
      'AXIS ID',
      'Name',
      'Address',
      'State',
      'Zip Code',
      'Office Phone',
    ];
    if (this.showETOColumn) {
      columns.splice(columns.length - 1, 0, ...['ETO Account']);
    }

    this.uiHelperService.exportToCsv(data, 'companies', columns);
  }

  private isActiveProduct(expirationDate: string): string {
    const currentDate = new Date();
    const targetDate = new Date(expirationDate);
    targetDate.setDate(targetDate.getDate() + 30);

    return targetDate >= currentDate ? 'Yes' : 'No';
  }
}
