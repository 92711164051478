import {
  ChangeDetectorRef,
  Component,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Subdivision } from '@/data/subdivision/models';
import {
  SubdivisionRequestParams,
  SubdivisionService,
} from '@/data/subdivision/services/subdivision.service';
import { User } from '@/data/core/models/user';
import { EMPTY, Observable, Subject } from 'rxjs';
import { ServerResponse } from '@/core/schemes/server-response';
import { Store } from '@ngrx/store';
import { getInfoUser } from '@/state/selectors/info.selector';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  first,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { objectValidator } from '@/core/validators';
import { CityService } from '@/data/geographic/services/city.service';
import { Company, CompanyType } from '@/data/company/models';
import {
  CompanyRequestParams,
  CompanyService,
} from '@/data/company/services/company-base.service';

export class SubdivisionSelectOrCreateDialogData {
  subdivision: Subdivision;
}

export enum SubdivisionSelectOrCreateResultState {
  createdNew,
  selectExisting,
  cancelled,
}

export class SubdivisionSelectOrCreateDialogResult {
  subdivision: Subdivision;
  state: SubdivisionSelectOrCreateResultState;
}

@Component({
  selector: 'app-subdivision-select-or-create-dialog',
  templateUrl: './subdivision-select-or-create-dialog.component.html',
  styleUrls: ['./subdivision-select-or-create-dialog.component.scss'],
})
export class SubdivisionSelectOrCreateDialogComponent
  implements OnInit, OnDestroy
{
  subdivision: Subdivision;
  newSubdivisionName = '';

  form: UntypedFormGroup;
  isLoading = true;
  initialized = false;
  currentUser: User;
  isNew = false;

  existingSubdivisions$: Observable<ServerResponse<Subdivision>>;
  filteredBuilderOrganizations$: Observable<ServerResponse<Company>>;

  private destroy$ = new Subject();

  constructor(
    public dialogRef: MatDialogRef<SubdivisionSelectOrCreateDialogComponent>,
    public fb: UntypedFormBuilder,
    public subdivisionService: SubdivisionService,
    public dialog: MatDialog,
    public store: Store<AppState>,
    public injector: Injector,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public uiHelper: UIHelperService,
    public cityService: CityService,
    public companyService: CompanyService,
    private cdRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: SubdivisionSelectOrCreateDialogData
  ) {
    this.dialogRef.disableClose = true;
    this.subdivision = data.subdivision;
  }

  ngOnInit() {
    this.store
      .select(getInfoUser)
      .pipe(first())
      .subscribe(currentUser => {
        this.currentUser = currentUser;
        this.setupForm();
        this.isLoading = false;
        this.hydrateForm();
      });
  }

  setupForm() {
    this.form = this.fb.group({
      name: [null, [Validators.required, objectValidator()]],
      city_info: ['', [Validators.required, objectValidator()]],
      builder_organization_info: ['', [Validators.required, objectValidator()]],
    });

    this.existingSubdivisions$ = this.form.controls.name.valueChanges.pipe(
      takeUntil(this.destroy$),
      startWith(''),
      debounceTime(100),
      distinctUntilChanged(),
      tap(value => (this.newSubdivisionName = value)),
      switchMap(value =>
        this.subdivisionService
          .list(
            Object.assign(new SubdivisionRequestParams(), {
              search: value,
              page_size: 50,
            })
          )
          .pipe(catchError(err => EMPTY))
      )
    );

    // builder organization autocomplete
    this.filteredBuilderOrganizations$ = this.form
      .get('builder_organization_info')
      .valueChanges.pipe(
        takeUntil(this.destroy$),
        startWith(''),
        debounceTime(100),
        distinctUntilChanged(),
        switchMap(value =>
          this.companyService
            .list(
              Object.assign(new CompanyRequestParams(), {
                search: value,
                is_attached: 'attached',
                page_size: 300,
                company_type: CompanyType.builder,
              })
            )
            .pipe(catchError(err => EMPTY))
        )
      );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  hydrateForm() {
    this.form.patchValue(
      { name: { id: this.subdivision.id, name: this.subdivision.name || '' } },
      { emitEvent: false, onlySelf: true }
    );
  }

  onSelectSubdivision(event: any) {
    const result = new SubdivisionSelectOrCreateDialogResult();
    if (this.subdivision.id) {
      result.state = SubdivisionSelectOrCreateResultState.selectExisting;
      result.subdivision = this.subdivision;
      this.dialogRef.close(result);
    } else {
      this.form.markAllAsTouched();

      if (this.form.invalid) {
        return;
      }

      this.isLoading = true;

      const formData = new Subdivision();
      formData.name = this.subdivision.name;
      formData.builder_org = this.form.value.builder_organization_info.id;
      formData.city = this.form.value.city_info.id;
      formData.community = null;

      this.subdivisionService.create(formData).subscribe(
        subdivision => {
          this.isLoading = false;
          result.state = SubdivisionSelectOrCreateResultState.createdNew;
          result.subdivision = subdivision;
          this.dialogRef.close(result);
        },
        error => {
          this.uiHelper.handleUserRequestError(error, 'Error', true);
          this.isLoading = false;
        }
      );
    }
  }

  onSubdivisionSelected($event: MatAutocompleteSelectedEvent) {
    if (typeof $event.option.value === 'string') {
      this.subdivision = new Subdivision();
      this.subdivision.name = $event.option.value;
      this.isNew = true;
      this.form.patchValue({
        name: { id: null, name: this.newSubdivisionName },
        city_info: null,
      });
    } else {
      this.subdivision = $event.option.value;
      this.isNew = false;
    }
  }

  displaySubdivisionAutocomplete(subdivision: Subdivision) {
    if (subdivision) {
      return `${subdivision.name}`;
    }
  }

  displayBuilderOrganizationMenu(builderOrganization) {
    if (builderOrganization) {
      return `${builderOrganization.name}`;
    }
  }

  onClose($event: MouseEvent) {
    const result = new SubdivisionSelectOrCreateDialogResult();
    result.state = SubdivisionSelectOrCreateResultState.cancelled;
    result.subdivision = this.data.subdivision;
    this.dialogRef.close(result);
  }
}
