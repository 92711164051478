<div class="container" *ngIf="initialized">
    <div class="row">
        <div class="col-12">
            <h3>
                <button mat-raised-button color="accent" appBackButton [fallbackUrl]="'/hi/project_registrations'">
                    <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
                </button>
                Add New Home
            </h3>
            <hr>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <form [formGroup]="formGroup">

                <div class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline" class="w-100 mb-2">
                            <mat-label>Address</mat-label>
                            <app-home-address-input placeholder="Address" formControlName="address" required [show-clear-btn]="true"></app-home-address-input>
                            <mat-error
                                    *ngIf="formGroup.get('address').hasError('required')">
                                This field is required
                            </mat-error>
                            <mat-error
                                    *ngIf="formGroup.get('address').hasError('home_with_this_address_already_exists')">
                                Home with this address already exists
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline" class="w-100 mb-2">
                            <mat-label>HUD Disaster Case Number</mat-label>
                            <input matInput type="text" placeholder="HUD Disaster Case Number"
                                   formControlName="hud_disaster_case_number">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <mat-hint>
                            Identify any NGBS Green+ badges that you intend to pursue. Note: with the exception of Zero Water, all badges are available for both
                            New Construction and Renovation. See NGBS Green+ Compliance Handbook for more details.
                        </mat-hint>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>NGBS Green+ Badges</mat-label>
                            <app-generic-mat-select
                                    formControlName="green_energy_badges"
                                    modelClassName="hirl_green_energy_badge"
                                    [multiple]="true"
                            ></app-generic-mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row mt-1">
                    <div class="col-12">
                        <div>
                            <mat-form-field appearance="outline" class="mb-2 w-100">
                                <mat-label>Is this an appeals project?</mat-label>
                                <mat-select aria-label="Select Green Energy Badges" formControlName="appeals_project">
                                    <mat-option *ngFor="let appealsProjectType of HIRLProjectAppealsProjectTypeMapping | defaultOrderKeyValue"
                                                [value]="appealsProjectType.key">{{ appealsProjectType.value }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="row mt-2" *ngIf="hirlProjectRegistration.is_build_to_rent">
                    <div class="col-12">
                        <mat-form-field class="w-100 mb-2">
                            <mat-label
                            >Do you intend to employ the NGBS Green Alternative
                                Multifamily Verification Protocol (Sampling) ?</mat-label
                            >
                            <mat-select
                                formControlName="sampling"
                                panelClass="sampling-panel-override"
                            >
                                <mat-option
                                    *ngFor="
                        let item of HIRLProjectSamplingMapping
                          | defaultOrderKeyValue
                      "
                                    [value]="item.key"
                                >
                                    {{ item.value }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="formGroup.get('sampling').hasError('required')">
                                This field is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row mt-2" *ngIf="customerHIRLSettings.requireRoughInspectionProgramList.includes(this.hirlProjectRegistration.eep_program_info?.slug)">
                    <div class="col-12">
                        <div>
                            <p>
                                <mat-label>Rough inspections are typically not required if there will be no renovation behind drywall.
                                    Will this remodel project require a rough inspection?</mat-label>
                            </p>
                            <mat-radio-group
                                    aria-label="Will this remodel project require a rough inspection?"
                                    formControlName="is_require_rough_inspection">
                                <mat-radio-button [value]="true" class="mr-5">
                                    Yes
                                </mat-radio-button>
                                <mat-radio-button [value]="false">
                                    No
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                </div>

                <div class="row mt-2" *ngIf="customerHIRLSettings.wriSeekingProgramList.includes(this.hirlProjectRegistration.eep_program_info?.slug)">
                    <div class="col-12">
                        <div>
                            <p>
                                <mat-label>Is this project seeking a WRI?</mat-label>
                            </p>
                            <mat-radio-group
                                    aria-label="Is this project seeking a WRI?"
                                    formControlName="is_require_wri_certification">
                                <mat-radio-button [value]="true" class="mr-5">
                                    Yes
                                </mat-radio-button>
                                <mat-radio-button [value]="false">
                                    No
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                </div>

                <div class="row mt-2" *ngIf="customerHIRLSettings.waterSenseProgramList.includes(this.hirlProjectRegistration.eep_program_info?.slug)">
                    <div class="col-12">
                        <div>
                            <p>
                                <b>WaterSense Certification:</b>
                                The WaterSense label is available for home seeking NGBS Green Certification under the 2020 NGBS version. Do you intend to pursue WaterSense certification for this building? Note: WRI Verifier training must be completed prior to final inspection.
                            </p>
                            <p>
                                <mat-label>Is the project seeking WaterSense Certification?</mat-label>
                            </p>
                            <mat-radio-group
                                    aria-label="Is the project seeking WaterSense Certification?"
                                    formControlName="is_require_water_sense_certification">
                                <mat-radio-button [value]="true" class="mr-5">
                                    Yes
                                </mat-radio-button>
                                <mat-radio-button [value]="false">
                                    No
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 text-center">
                        <button type="button" mat-raised-button color="accent"
                                (click)="handleCreate($event)" [disabled]="formGroup.pending">
                            Create
                            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" *ngIf="formGroup.pending"></fa-icon>
                        </button>
                    </div>
                </div>

            </form>
        </div>
    </div>

</div>
