import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hirl-client-agreement-list',
  templateUrl: './hirl-client-agreement-list.component.html',
  styleUrls: ['./hirl-client-agreement-list.component.scss'],
})
export class HirlClientAgreementListComponent implements OnInit {
  ngOnInit(): void {}
}
