import { CompanyInfo } from '@/data/company/models';
import { CustomerDocumentInfo } from '@/data/filehandling/models';

export enum AnalysisWeatherType {
  TMY3 = 'tmy3',
  TMYX = 'tmyx',
  TMYX2004 = 'tmyx2004',
}

export const AnalysisWeatherTypeLabelMapping: Record<
  AnalysisWeatherType,
  string
> = {
  [AnalysisWeatherType.TMY3]: 'TYM3',
  [AnalysisWeatherType.TMYX]: 'TMYX',
  [AnalysisWeatherType.TMYX2004]: 'TMYX2004',
};

export class UDRHModelInfo {
  id: number;
  short_name?: string;
  description?: string;
  reference_analysis?: string;
  design_analysis?: string;
  company?: string;
  company_info: CompanyInfo;
  uploaded_file?: number;
  uploaded_file_info?: CustomerDocumentInfo;
  resulted_file?: number;
  resulted_file_info?: CustomerDocumentInfo;
}

export class UDRHModel {
  id: number;
  short_name?: string;
  description?: string;
  reference_analysis?: string;
  design_analysis?: string;
  company?: string;
  company_info: CompanyInfo;
  uploaded_file?: number;
  uploaded_file_info?: CustomerDocumentInfo;
  resulted_file?: number;
  resulted_file_info?: CustomerDocumentInfo;
}
