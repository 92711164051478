import { addNewDistributionSystem } from '@/modules/simulation/state/simulation/actions';
import { selectDistributionSystemIds } from '@/modules/simulation/state/simulation/selectors';
import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-distribution-systems',
  templateUrl: './distribution-systems.component.html',
  styleUrls: ['./distribution-systems.component.scss'],
})
export class DistributionSystemsComponent {
  @Input() expanded = false;

  componentDestroyed$ = new Subject();
  distributionSystemIds: number[] = [];

  constructor(public store: Store) {
    this.store
      .select(selectDistributionSystemIds())
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(ids => {
        this.distributionSystemIds = ids;
      });
  }

  addDistributionSystem() {
    this.store.dispatch(addNewDistributionSystem());
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
