<ng-container *ngIf="invoice">
  <ng-container [ngSwitch]="displayType">
    <ng-container *ngSwitchCase="InvoiceDisplayComponentType.onlyId">
      {{ invoice?.prefixed_id }}
    </ng-container>
    <ng-container *ngSwitchCase="InvoiceDisplayComponentType.onlyIdAsLink">
      <a
        [routerLink]="[
        '/',
        'hi',
        'invoices',
        invoice?.prefixed_id
      ]">
        {{ invoice?.prefixed_id }}
      </a>
    </ng-container>
    <ng-container *ngSwitchCase="InvoiceDisplayComponentType.nameAsLinkAndStatus">
      <a
        [routerLink]="[
        '/',
        'hi',
        'invoices',
        invoice?.prefixed_id
      ]">
        Invoice: {{ invoice?.prefixed_id }}
        <span class="badge {{ InvoiceStateLabelMapping[invoice.state].badgeClass }}">{{ InvoiceStateLabelMapping[invoice.state].label }}</span>
      </a>
    </ng-container>
    <ng-container *ngSwitchCase="InvoiceDisplayComponentType.nameAndStatus">
      Invoice: {{ invoice?.prefixed_id }}
      <span class="badge {{ InvoiceStateLabelMapping[invoice.state].badgeClass }}">{{ InvoiceStateLabelMapping[invoice.state].label }}</span>
    </ng-container>
  </ng-container>
</ng-container>
