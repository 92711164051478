import * as moment from 'moment';
import { Component, OnInit } from '@angular/core';
import { toggleLoading } from '@/state/actions/app.actions';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { MatDialog } from '@angular/material/dialog';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  BillingRuleExportParams,
  HIRLProjectRequestParams,
  HIRLProjectService,
  MilestoneExportParams,
} from '@/data/customer-hirl/services/hirl-projects.service';
import { formatDate } from '@angular/common';

import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { CompanyService } from '@/data/company/services/company-base.service';

@Component({
  selector: 'app-hirl-jamis-dashboard',
  templateUrl: './hirl-jamis-dashboard.component.html',
  styleUrls: ['./hirl-jamis-dashboard.component.scss'],
})
export class HIRLJamisDashboardComponent implements OnInit {
  public jamisForm: UntypedFormGroup;
  public projectBillingForm: UntypedFormGroup;
  public exportBillingRuleFileForm: UntypedFormGroup;

  /**
   * 100 MB (=100 * 2 ** 20).
   */
  readonly maxSize = 104857600;

  constructor(
    public store: Store<AppState>,
    public dialog: MatDialog,
    private companyService: CompanyService,
    private hirlProjectService: HIRLProjectService,
    private fb: UntypedFormBuilder,
    private router: Router,
    public uiHelper: UIHelperService
  ) {}

  ngOnInit(): void {
    this.jamisForm = this.fb.group({
      jamis_file: [undefined, [Validators.required]],
    });

    this.projectBillingForm = this.fb.group({
      jamis_file: [undefined, [Validators.required]],
    });

    const now = moment();
    const tenDaysAgo = moment().subtract(10, 'days');

    this.exportBillingRuleFileForm = this.fb.group({
      date_range: this.fb.group({
        start: [tenDaysAgo, Validators.required],
        end: [now, Validators.required],
      }),
    });
  }

  exportClientOrganizations() {
    this.store.dispatch(toggleLoading({ payload: true }));

    this.companyService
      .customer_hirl_clients_export()
      .pipe(first())
      .subscribe(
        data => {
          this.store.dispatch(toggleLoading({ payload: false }));
          const downloadURL = window.URL.createObjectURL(data);
          const link = document.createElement('a');
          link.href = downloadURL;
          const formattedDate = formatDate(new Date(), 'yyyyMMdd', 'en-US');
          link.download = `Builders${formattedDate}.xlsx`;
          link.click();
        },
        error => this.uiHelper.handleUserRequestError(error)
      );
  }

  onExportNGBSProjectsClicked($event) {
    this.store.dispatch(toggleLoading({ payload: true }));
    const params = new HIRLProjectRequestParams();
    params.registration__epp_program__slug = [
      'ngbs-sf-new-construction-2020-new',
      'ngbs-mf-new-construction-2020-new',
      'ngbs-sf-whole-house-remodel-2020-new',
      'ngbs-mf-whole-house-remodel-2020-new',
      'ngbs-sf-certified-2020-new',
      'ngbs-sf-new-construction-2015-new',
      'ngbs-mf-new-construction-2015-new',
      'ngbs-sf-whole-house-remodel-2015-new',
      'ngbs-mf-whole-house-remodel-2015-new',
      'ngbs-sf-new-construction-2012-new',
      'ngbs-mf-new-construction-2012-new',
      'ngbs-sf-whole-house-remodel-2012-new',
      'ngbs-mf-whole-house-remodel-2012-new',
    ];
    this.hirlProjectService.all_projects_report(params).subscribe(
      asynchronousProcessedDocument => {
        const _ = this.router.navigate([
          'file-operation',
          'document',
          asynchronousProcessedDocument.id,
        ]);
      },
      error => this.uiHelper.handleUserRequestError(error)
    );
  }

  onExportWRIProjectsClicked($event) {
    this.store.dispatch(toggleLoading({ payload: true }));
    const params = new HIRLProjectRequestParams();
    params.page = 1;
    params.registration__epp_program__slug = [
      'ngbs-sf-wri-2021',
      'ngbs-mf-wri-2021',
    ];
    this.hirlProjectService.all_projects_report(params).subscribe(
      asynchronousProcessedDocument => {
        const _ = this.router.navigate([
          'file-operation',
          'document',
          asynchronousProcessedDocument.id,
        ]);
      },
      error => this.uiHelper.handleUserRequestError(error)
    );
  }

  onExportVerifiersClicked(event) {
    this.store.dispatch(toggleLoading({ payload: true }));
    this.companyService.customer_hirl_verifiers_export().subscribe(
      data => {
        this.store.dispatch(toggleLoading({ payload: false }));
        const downloadURL = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        const formattedDate = formatDate(new Date(), 'yyyyMMdd', 'en-US');
        link.href = downloadURL;
        link.download = `Verifiers${formattedDate}.xlsx`;
        link.click();
      },
      error => this.uiHelper.handleUserRequestError(error)
    );
  }

  onExportNGBSProjectsBillingRuleClicked(event) {
    if (this.exportBillingRuleFileForm.invalid) {
      return;
    }

    let startDate = '';
    let endDate = '';
    if (this.exportBillingRuleFileForm.value.date_range.start) {
      startDate =
        this.exportBillingRuleFileForm.value.date_range.start.format(
          'YYYY-MM-DD'
        );
    }
    if (this.exportBillingRuleFileForm.value.date_range.end) {
      endDate =
        this.exportBillingRuleFileForm.value.date_range.end.format(
          'YYYY-MM-DD'
        );
    }

    const params = new BillingRuleExportParams();
    params.start_date = startDate;
    params.end_date = endDate;
    params.program_slugs = [
      'ngbs-sf-new-construction-2020-new',
      'ngbs-mf-new-construction-2020-new',
      'ngbs-sf-whole-house-remodel-2020-new',
      'ngbs-mf-whole-house-remodel-2020-new',
      'ngbs-sf-certified-2020-new',
      'ngbs-sf-new-construction-2015-new',
      'ngbs-mf-new-construction-2015-new',
      'ngbs-sf-whole-house-remodel-2015-new',
      'ngbs-mf-whole-house-remodel-2015-new',
      'ngbs-sf-new-construction-2012-new',
      'ngbs-mf-new-construction-2012-new',
      'ngbs-sf-whole-house-remodel-2012-new',
      'ngbs-mf-whole-house-remodel-2012-new',

      'ngbs-land-development-2020-new',
    ];

    this.store.dispatch(toggleLoading({ payload: true }));
    this.exportNGBSProjectsBillingRule(params);
  }

  onExportWRIProjectsBillingRuleClicked(event) {
    if (this.exportBillingRuleFileForm.invalid) {
      return;
    }

    let startDate = '';
    let endDate = '';
    if (this.exportBillingRuleFileForm.value.date_range.start) {
      startDate = `${formatDate(
        this.exportBillingRuleFileForm.value.date_range.start,
        'yyyy-MM-dd',
        'en-US'
      )}`;
    }
    if (this.exportBillingRuleFileForm.value.date_range.end) {
      endDate = `${formatDate(
        this.exportBillingRuleFileForm.value.date_range.end,
        'yyyy-MM-dd',
        'en-US'
      )}`;
    }

    const params = new BillingRuleExportParams();
    params.start_date = startDate;
    params.end_date = endDate;
    params.program_slugs = ['ngbs-sf-wri-2021', 'ngbs-mf-wri-2021'];

    this.store.dispatch(toggleLoading({ payload: true }));
    this.exportNGBSProjectsBillingRule(params);
  }

  exportNGBSProjectsBillingRule(params: BillingRuleExportParams) {
    this.store.dispatch(toggleLoading({ payload: true }));
    this.hirlProjectService
      .billing_rule_export(params)
      .pipe(first())
      .subscribe(
        asynchronousProcessedDocument => {
          const _ = this.router.navigate([
            'file-operation',
            'document',
            asynchronousProcessedDocument.id,
          ]);
        },
        error => this.uiHelper.handleUserRequestError(error)
      );
  }

  onExportNGBSProjectsMilestoneClicked(event$) {
    if (this.exportBillingRuleFileForm.invalid) {
      return;
    }

    let startDate = '';
    let endDate = '';
    if (this.exportBillingRuleFileForm.value.date_range.start) {
      startDate = `${formatDate(
        this.exportBillingRuleFileForm.value.date_range.start,
        'yyyy-MM-dd',
        'en-US'
      )}`;
    }
    if (this.exportBillingRuleFileForm.value.date_range.end) {
      endDate = `${formatDate(
        this.exportBillingRuleFileForm.value.date_range.end,
        'yyyy-MM-dd',
        'en-US'
      )}`;
    }

    const params = new MilestoneExportParams();
    params.start_date = startDate;
    params.end_date = endDate;
    params.program_slugs = [
      'ngbs-sf-new-construction-2020-new',
      'ngbs-mf-new-construction-2020-new',
      'ngbs-sf-whole-house-remodel-2020-new',
      'ngbs-mf-whole-house-remodel-2020-new',
      'ngbs-sf-certified-2020-new',
      'ngbs-sf-new-construction-2015-new',
      'ngbs-mf-new-construction-2015-new',
      'ngbs-sf-whole-house-remodel-2015-new',
      'ngbs-mf-whole-house-remodel-2015-new',
      'ngbs-sf-new-construction-2012-new',
      'ngbs-mf-new-construction-2012-new',
      'ngbs-sf-whole-house-remodel-2012-new',
      'ngbs-mf-whole-house-remodel-2012-new',

      'ngbs-land-development-2020-new',
    ];

    this.store.dispatch(toggleLoading({ payload: true }));
    this.exportMilestone(params);
  }

  onExportWRIProjectsMilestoneClicked(event$) {
    if (this.exportBillingRuleFileForm.invalid) {
      return;
    }

    let startDate = '';
    let endDate = '';
    if (this.exportBillingRuleFileForm.value.date_range.start) {
      startDate = `${formatDate(
        this.exportBillingRuleFileForm.value.date_range.start,
        'yyyy-MM-dd',
        'en-US'
      )}`;
    }
    if (this.exportBillingRuleFileForm.value.date_range.end) {
      endDate = `${formatDate(
        this.exportBillingRuleFileForm.value.date_range.end,
        'yyyy-MM-dd',
        'en-US'
      )}`;
    }

    const params = new MilestoneExportParams();
    params.start_date = startDate;
    params.end_date = endDate;
    params.program_slugs = ['ngbs-sf-wri-2021', 'ngbs-mf-wri-2021'];

    this.store.dispatch(toggleLoading({ payload: true }));
    this.exportMilestone(params);
  }

  exportMilestone(params: MilestoneExportParams) {
    this.store.dispatch(toggleLoading({ payload: true }));
    this.hirlProjectService
      .milestone_export(params)
      .pipe(first())
      .subscribe(
        asynchronousProcessedDocument => {
          const _ = this.router.navigate([
            'file-operation',
            'document',
            asynchronousProcessedDocument.id,
          ]);
        },
        error => this.uiHelper.handleUserRequestError(error)
      );
  }

  onGreenPaymentsFormSubmit(event) {
    const formData = new FormData();
    formData.append('jamis_file', this.jamisForm.get('jamis_file').value);

    this.store.dispatch(toggleLoading({ payload: true }));
    this.hirlProjectService
      .green_payments_import(formData)
      .pipe(first())
      .subscribe(
        asynchronousProcessedDocument => {
          const _ = this.router.navigate([
            'file-operation',
            'document',
            asynchronousProcessedDocument.id,
          ]);
        },
        error => this.uiHelper.handleUserRequestError(error)
      );
  }

  onProjectBillingFormSubmit(event) {
    const formData = new FormData();
    formData.append(
      'jamis_file',
      this.projectBillingForm.get('jamis_file').value
    );

    this.store.dispatch(toggleLoading({ payload: true }));
    this.hirlProjectService
      .project_billing_import(formData)
      .pipe(first())
      .subscribe(
        asynchronousProcessedDocument => {
          const _ = this.router.navigate([
            'file-operation',
            'document',
            asynchronousProcessedDocument.id,
          ]);
        },
        error => this.uiHelper.handleUserRequestError(error)
      );
  }
}
