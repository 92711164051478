import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BaseDataService } from '@/core/services/base-data-service';
import { Observable } from 'rxjs';
import { RimJoistTypeBackendDict } from '../models/enclosure/RimJoistType';
import { ListRequestParams } from '@/core/schemes/request-params';
import { ServerResponse } from '@/core/schemes/server-response';

export class RimJoistTypeParams extends ListRequestParams {}

@Injectable({ providedIn: 'root' })
export class RimJoistTypeService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/rim_joist_type/`;
  public parentUrl = `${environment.AXIS_API_V3_ROOT}/rim_joist/`;

  list(
    params: RimJoistTypeParams
  ): Observable<ServerResponse<RimJoistTypeBackendDict>> {
    return this.http.get<ServerResponse<RimJoistTypeBackendDict>>(
      `${this.baseUrl}`,
      {
        params: this.rollParams(params),
      }
    );
  }

  create(
    wallId: number,
    wallType: RimJoistTypeBackendDict
  ): Observable<RimJoistTypeBackendDict> {
    return this.http.post<RimJoistTypeBackendDict>(
      `${this.parentUrl}${wallId}/rim_joist_type/`,
      wallType
    );
  }

  update(
    id: number,
    rimJoistTypeChanges: Partial<RimJoistTypeBackendDict>
  ): Observable<RimJoistTypeBackendDict> {
    return this.http.patch<RimJoistTypeBackendDict>(
      `${this.baseUrl}${id}/`,
      rimJoistTypeChanges
    );
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}${id}/`);
  }
}
