<mat-expansion-panel [expanded]="expanded">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <strong>Thermostats&nbsp;({{thermostatIds.length}})</strong>
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
        <div class="d-flex flex-column justify-content-center">
            <div>
                <div class="items-container mt-2">
                    <ng-container *ngFor="let thermostatId of thermostatIds;">
                        <app-thermostat [entityId]="thermostatId"></app-thermostat>
                    </ng-container>
                </div>
            </div>
            <button *appRequiresEditPermission mat-button (click)="addThermostat()">
                <mat-icon>add_circle</mat-icon>
            </button>
        </div>
    </ng-template>
</mat-expansion-panel>