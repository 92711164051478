import {
  CRAWL_SPACE_LOCATIONS,
  FrameFloorBackendDict,
  createFrameFloorForm,
} from '@/data/simulation/models/enclosure/FrameFloor';
import { BaseValidator } from './base.validator';
import { ModelErrors, StateErrors } from '../state/shared/base.state';
import { FrameFloorTypeBackendDict } from '@/data/simulation/models/enclosure/FrameFloorType';

const MIN_CAVITY_INSULATION_R_VALUE = 0;
const MAX_CAVITY_INSULATION_R_VALUE = 100;
const MIN_CRAWL_CAVITY_INSULATION_R_VALUE = 0;
const MAX_CRAWL_CAVITY_INSULATION_R_VALUE = 95;

export class FrameFloorValidator extends BaseValidator {
  validateCavityInsulationRValue(
    frameFloor: FrameFloorBackendDict,
    type: FrameFloorTypeBackendDict
  ) {
    const results = [
      {
        id: 'invalidFloorCavityInsulationRValue',
        validationSuccess: true,
        data: {},
      },
      {
        id: 'warningFloorCavityInsulationRValue',
        validationSuccess: true,
        data: {},
      },
    ];

    const location = frameFloor.interior_location;

    let minRVal = MIN_CAVITY_INSULATION_R_VALUE;
    let maxRVal = MAX_CAVITY_INSULATION_R_VALUE;
    if (CRAWL_SPACE_LOCATIONS.hasOwnProperty(location)) {
      minRVal = MIN_CRAWL_CAVITY_INSULATION_R_VALUE;
      maxRVal = MAX_CRAWL_CAVITY_INSULATION_R_VALUE;
    }

    const cavityInsulationRvalue = type.cavity_insulation_r_value;
    const data = {
      min: minRVal,
      max: maxRVal,
      actual: cavityInsulationRvalue,
    };
    results[0].data = data;
    results[1].data = data;

    if (cavityInsulationRvalue < minRVal || cavityInsulationRvalue > maxRVal) {
      results[0].validationSuccess = false;
    } else if (
      cavityInsulationRvalue < minRVal + 5 ||
      cavityInsulationRvalue > maxRVal - 5
    ) {
      results[1].validationSuccess = false;
    }

    return results;
  }

  static validateForm(
    frameFloor: FrameFloorBackendDict
  ): ModelErrors<FrameFloorBackendDict> {
    const framefloorForm = createFrameFloorForm(frameFloor);
    const errors = {};
    Object.keys(frameFloor).forEach(key => {
      if (!framefloorForm.get(key)) return;
      errors[key] = framefloorForm.get(key).errors;
    });
    return {
      field: errors,
      model: framefloorForm.errors || {},
    };
  }

  static validate(
    frameFloors: FrameFloorBackendDict[]
  ): StateErrors<FrameFloorBackendDict> {
    const errors = {};
    frameFloors.forEach(frameFloor => {
      errors[frameFloor.id] = this.validateForm(frameFloor);
    });
    return errors;
  }
}
