import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import * as PhotovoltaicActions from './actions';
import * as SimulationActions from '../simulation/actions';
import * as SharedActions from '../shared/shared.actions';
import { PhotovoltaicService } from '@/data/simulation/services/photovoltaic.service';
import { PhotovoltaicValidator } from '../../validators/photovoltaic.validator';

@Injectable()
export class PhotovoltaicEffects {
  loadPhotovoltaics$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PhotovoltaicActions.loadPhotovoltaics),
      mergeMap(action => {
        const errors = PhotovoltaicValidator.validate(action.photovoltaics);

        return of(
          PhotovoltaicActions.loadPhotovoltaicsSuccess({
            photovoltaics: action.photovoltaics,
            errors: errors,
          })
        );
      })
    );
  });

  updatePhotovoltaic$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PhotovoltaicActions.updatePhotovoltaic),
      mergeMap(action =>
        this.photovoltaicService.update(action.photovoltaicChanges).pipe(
          mergeMap(updatedPhotovoltaic => {
            const errors = PhotovoltaicValidator.validate([
              updatedPhotovoltaic,
            ]);
            return of(
              PhotovoltaicActions.updatePhotovoltaicSuccess({
                photovoltaic: updatedPhotovoltaic,
                errors: errors[updatedPhotovoltaic.id],
              })
            );
          }),
          catchError(error =>
            of(
              PhotovoltaicActions.updatePhotovoltaicFailure({
                id: action.photovoltaicChanges.id,
              }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        )
      )
    );
  });

  removePhotovoltaic$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PhotovoltaicActions.removePhotovoltaic),
      mergeMap(action =>
        this.photovoltaicService.delete(action.id).pipe(
          mergeMap(() =>
            of(
              PhotovoltaicActions.removePhotovoltaicSuccess({
                id: action.id,
              }),
              SimulationActions.removeItemFromList({
                fieldName: 'photovoltaics',
                id: action.id,
              })
            )
          ),
          catchError(error =>
            of(
              PhotovoltaicActions.removePhotovoltaicFailure({
                id: action.id,
              }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private photovoltaicService: PhotovoltaicService
  ) {}
}
