import {
  selectAppliancesId,
  selectLightsId,
} from '@/modules/simulation/state/simulation/selectors';
import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-ambience',
  templateUrl: './ambience.component.html',
  styleUrls: ['./ambience.component.scss'],
})
export class AmbienceComponent implements OnInit {
  @Input() expanded = false;
  appliancesId: number;
  componentDestroyed$ = new Subject();
  lightsId: unknown;

  constructor(public store: Store) {}

  ngOnInit(): void {
    this.store
      .select(selectAppliancesId())
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(id => {
        this.appliancesId = id;
      });

    this.store
      .select(selectLightsId())
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(id => (this.lightsId = id));
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
