import {
  WaterHeaterBackendDict,
  createWaterHeaterForm,
} from '@/data/simulation/models/mechanicals/WaterHeater';
import { BaseValidator } from './base.validator';
import { ModelErrors, StateErrors } from '../state/shared/base.state';

export class WaterHeaterValidator extends BaseValidator {
  static validateForm(
    waterHeater: WaterHeaterBackendDict
  ): ModelErrors<WaterHeaterBackendDict> {
    const form = createWaterHeaterForm(waterHeater);
    return form.getFieldAndModelErros();
  }

  static validate(
    waterHeaters: WaterHeaterBackendDict[]
  ): StateErrors<WaterHeaterBackendDict> {
    const errors = {};
    waterHeaters.forEach(waterHeater => {
      errors[waterHeater.id] = this.validateForm(waterHeater);
    });
    return errors;
  }
}
