import { createReducer, on } from '@ngrx/store';
import * as PhotovoltaicActions from './actions';
import { PhotovoltaicBackendDict } from '@/data/simulation/models/generators/Photovoltaic';
import { BaseState, sharedInitialState } from '../shared/base.state';
import { addErrorCount, addStateErrorCount } from '../helper';

export type PhotovoltaicState = BaseState<PhotovoltaicBackendDict>;

export const initialState: PhotovoltaicState = sharedInitialState;

export const reducer = createReducer(
  initialState,
  on(PhotovoltaicActions.loadPhotovoltaicsSuccess, (currentState, payload) => {
    const entities = payload.photovoltaics.reduce(
      (acc, photovoltaic) => ({
        ...acc,
        [photovoltaic.id]: photovoltaic,
      }),
      {}
    );

    const loadingStatus = payload.photovoltaics.reduce(
      (acc, photovoltaic) => ({
        ...acc,
        [photovoltaic.id]: false,
      }),
      {}
    );

    return {
      entities: { ...currentState.entities, ...entities },
      loading: { ...currentState.loading, ...loadingStatus },
      errors: {
        ...currentState.errors,
        ...addStateErrorCount(payload.errors),
      },
    };
  }),

  on(PhotovoltaicActions.removePhotovoltaicSuccess, (currentState, { id }) => {
    const { [id]: removed, ...entities } = currentState.entities;
    const { [id]: removedLoading, ...loading } = currentState.loading;
    const { [id]: removedErrors, ...errors } = currentState.errors;

    return {
      entities,
      loading,
      errors,
    };
  }),

  on(
    PhotovoltaicActions.updatePhotovoltaic,
    (state, { photovoltaicChanges }) => {
      return {
        ...state,
        loading: {
          ...state.loading,
          [photovoltaicChanges.id]: true,
        },
      };
    }
  ),

  on(PhotovoltaicActions.removePhotovoltaic, (state, { id }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [id]: true,
      },
    };
  }),

  on(
    PhotovoltaicActions.updatePhotovoltaicFailure,
    PhotovoltaicActions.removePhotovoltaicFailure,
    (state, { id }) => {
      return {
        ...state,
        loading: {
          ...state.loading,
          [id]: false,
        },
      };
    }
  ),

  on(
    PhotovoltaicActions.updatePhotovoltaicSuccess,
    (state, { photovoltaic, errors }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          [photovoltaic.id]: photovoltaic,
        },
        loading: {
          ...state.loading,
          [photovoltaic.id]: false,
        },
        errors: {
          ...state.errors,
          [photovoltaic.id]: addErrorCount(errors),
        },
      };
    }
  )
);
