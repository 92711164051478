import { City } from '@/data/geographic/models/city';
import { Geocode, GeocodeBrokeredResponse } from '@/data/geocoder/models';
import { HIRLCompanyClient } from '@/data/customer-hirl/models';
import { ETOAccountInfo } from '@/data/customer-eto/models';
import { HirlMarketingRoleInfo } from '@/data/company/models/hirl-marketing-role';

export class CompanyInfo {
  id: number;
  name: string;
  slug: string;
  company_type?: CompanyType;
  sponsors: Array<number>;
  sponsor_slugs: Array<string>;
  street_line1?: string;
  street_line2?: string;
  state?: string;
  city?: number;
  city_info?: City;
  zipcode?: string;

  home_page?: string;

  shipping_geocode: number;
  shipping_geocode_info?: Geocode;
  shipping_geocode_response: number;
  shipping_geocode_response_info?: GeocodeBrokeredResponse;

  hirlcompanyclient?: HIRLCompanyClient;
  hirl_marketing_roles?: number[];
  hirl_marketing_roles_info?: HirlMarketingRoleInfo[];
  hirlcompanyclient_set?: HIRLCompanyClient[];

  active_customer_hirl_builder_agreements_count?: number;

  gcp_fee_calculation_type?: GCPFeeCalculationType;
  gcp_certificate_expiration_date?: string;
  gcp_full_year_expiration_last_notified_at?: string;
}

export enum GCPFeeCalculationType {
  top_shelf = 'top_shelf',
  annual = 'annual',
  multi_year = 'multi_year',
}

export const GCPFeeCalculationTypeLabelMapping: Record<
  GCPFeeCalculationType,
  string
> = {
  [GCPFeeCalculationType.top_shelf]: 'Top-Shelf Listing',
  [GCPFeeCalculationType.annual]: 'Annual',
  [GCPFeeCalculationType.multi_year]: 'Multi-Year Listing Contract',
};

export enum InspectionGradeType {
  letter = 1,
  numeric = 2,
}

export const InspectionGradeTypeLabelMapping: Record<
  InspectionGradeType,
  string
> = {
  [InspectionGradeType.letter]: 'Letter grades',
  [InspectionGradeType.numeric]: 'Numeric grades',
};

export enum CompanyType {
  builder = 'builder',
  rater = 'rater',
  developer = 'developer',
  architect = 'architect',
  communityOwner = 'communityowner',
  eep = 'eep',
  hvac = 'hvac',
  provider = 'provider',
  qa = 'qa',
  utility = 'utility',
  manufacturer = 'manufacturer',
  general = 'general',
}

export const CompanyTypeLabelMapping: Record<CompanyType, string> = {
  [CompanyType.builder]: 'Builder/Client',
  [CompanyType.architect]: 'Architect',
  [CompanyType.communityOwner]: 'Community Owner',
  [CompanyType.developer]: 'Developer',
  [CompanyType.rater]: 'Rater',
  [CompanyType.provider]: 'Certification Organization/Rating Provider',
  [CompanyType.eep]: 'Program Sponsor',
  [CompanyType.qa]: 'QA',
  [CompanyType.utility]: 'Utility',
  [CompanyType.general]: 'General',
  [CompanyType.manufacturer]: 'Product Manufacturer',
  [CompanyType.hvac]: 'HVAC Contractor',
};

export class Company {
  id?: number;
  name?: string;
  company_type?: CompanyType;

  slug?: string;
  street_line1?: string;
  street_line2?: string;
  zipcode?: string;
  city?: number;
  city_info?: City;
  geocode_response?: number;
  geocode_response_info?: GeocodeBrokeredResponse;
  latitude?: number;
  longitude?: number;
  confirmed_address?: boolean;
  office_phone?: string;
  home_page?: string;
  description?: string;
  default_email?: string;
  is_active?: boolean;
  is_public?: boolean;
  is_customer?: boolean;
  is_eep_sponsor?: boolean;
  countries: number[];
  hirl_marketing_roles?: number[];
  hirl_marketing_roles_info?: HirlMarketingRoleInfo[];
  logo?: string;
  auto_add_direct_relationships?: boolean;
  display_raw_addresses?: boolean;
  intersection?: string;
  inspection_grade_type?: string;
  geocode?: number;
  shipping_geocode?: number;
  shipping_geocode_info?: Geocode;
  shipping_geocode_response?: number;
  shipping_geocode_response_info?: GeocodeBrokeredResponse;
  resnet?: number;

  provider_id?: string;
  auto_submit_to_registry?: boolean;

  worker_compensation_insurance?: boolean;

  electricity_provider?: boolean;
  gas_provider?: boolean;
  water_provider?: boolean;

  hquito_accredited?: boolean;

  hirlcompanyclient_set?: HIRLCompanyClient[];

  eto_account?: number;
  eto_account_info?: ETOAccountInfo;

  total_users?: number;
  total_company_admin_users?: number;
  active_customer_hirl_builder_agreements_count?: number;
  active_coi_document_count?: number;
  sponsor_slugs?: string[];

  gcp_fee_calculation_type?: GCPFeeCalculationType;
  gcp_certificate_expiration_date?: string;
}

export class CompanyFlatList {
  id?: number;
  name?: string;
  company_type?: CompanyType;
  address?: string;
  city_name?: string;
  state?: string;
  zipcode?: string;
  office_phone?: string;
  eto_account?: string;
  total_users?: string;
  total_company_admin_users?: string;
  hirlcompanyclient_id?: string;
  gcp_certificate_expiration_date?: string;
}

export class CompanyAutocompleteList {
  id?: number;
  name?: string;
  company_type?: CompanyType;
  address?: string;
  state?: string;
  city_name?: string;
  zipcode?: string;
  office_phone?: string;
  total_users?: string;
  total_company_admin_users?: string;
  active_customer_hirl_builder_agreements_count?: number;
  active_coi_document_count?: number;
  gcp_full_year_expiration_last_notified_at?: string;
  gcp_certificate_expiration_date?: string;

  hirlcompanyclient_set?: HIRLCompanyClient[];
}

export class CompanyAggregatedCountyByState {
  state: string;
  state_name: string;
  counties: number;
  selected_counties: number;
}

export class CompanyUpdateCountiesByState {
  state: string;
  counties?: number[];
}

export class CopyResourcesToOtherCompany {
  company_from: number;
  companies_to: number[];
  copy_client_agreement: boolean;
  copy_coi: boolean;
  move_client_agreement: boolean;
  move_coi: boolean;
}

export class CopiedResourcesToOtherCompanyResponse {
  client_agreements: number[];
  cois: number[];
}

export class CustomerHIRLGCPFullYearExpirationNotification {
  company: number;
}
