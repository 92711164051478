import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  DistributionSystemBackendDict,
  createDistributionSystemForm,
  FIELD_LABELS,
  DistributionSystemTypeLabels,
  DistributionSystemTestTypeLabels,
  HydronicDistributionTypeLabels,
  TotalLeakageTestConditionLabels,
  DistributionSystemLeakageUnitLabels,
} from '@/data/simulation/models/mechanicals/DistributionSystem';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectDistributionSystemViewSet } from '@/modules/simulation/state/distribution-system/selectors';
import { filter, first, takeUntil } from 'rxjs/operators';
import {
  removeDistributionSystem,
  updateDistributionSystem,
} from '@/modules/simulation/state/distribution-system/actions';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import {
  EquipmentTypeLabels,
  getEquipmentType,
} from '@/data/simulation/models/mechanicals/MechanicalEquipment';
import { cloneDistributionSystem } from '@/modules/simulation/state/simulation/actions';

@Component({
  selector: 'app-distribution-system',
  templateUrl: './distribution-system.component.html',
  styleUrls: ['./distribution-system.component.scss'],
})
export class DistributionSystemComponent implements OnDestroy, OnInit {
  @Input() entityId: number;
  fieldLabels = FIELD_LABELS;

  entity: DistributionSystemBackendDict = null;
  loading = false;

  private componentDestroyed$ = new Subject();
  typeViewSubscription$ = null;
  public exteriorLocationLabelMapping = {};
  public interiorLocationLabelMapping = {};

  public distributionSystemTypeName: string;
  public distributionSystemTypeErrorCount = 0;

  public distributionSystemFormGroup: FormGroup;
  distributionSystemTypeLabels = DistributionSystemTypeLabels;
  distributionSystemTestTypeLabels = DistributionSystemTestTypeLabels;
  hydronicDistributionTypeLabels = HydronicDistributionTypeLabels;
  totalLeakageTestConditionLables = TotalLeakageTestConditionLabels;
  distributionSystemLeakageUnit = DistributionSystemLeakageUnitLabels;

  constructor(public dialog: MatDialog, public store: Store) {}

  ngOnInit(): void {
    this.store
      .select(selectDistributionSystemViewSet(this.entityId))
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(val => val !== null)
      )
      .subscribe(({ data, loading }) => {
        this.entity = data;
        this.loading = loading;
        if (loading) return;

        if (this.distributionSystemFormGroup) {
          this.distributionSystemFormGroup.patchValue(data);
          return;
        }
        this.distributionSystemFormGroup = createDistributionSystemForm(data);
      });
  }

  removeDistributionSystem() {
    let distributionSystemName = this.entity.name;
    if (distributionSystemName === null || distributionSystemName === '') {
      distributionSystemName = 'Untitled';
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '50%',
      data: {
        title: `Do you want to delete "${distributionSystemName}" distribution sytem ?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (result) {
          this.store.dispatch(
            removeDistributionSystem({ distributionSystem: this.entity })
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  onSave(
    distributionSystemChanges: Partial<DistributionSystemBackendDict>
  ): void {
    this.store.dispatch(
      updateDistributionSystem({
        id: this.entityId,
        distributionSystemChanges: distributionSystemChanges,
      })
    );
  }

  excludeNonHeaters(entity) {
    return !(entity['heating_percent_served'] > 0);
  }

  excludeNonCooler(entity) {
    return !(entity['cooling_percent_served'] > 0);
  }

  optionaLabel(entity) {
    const name = entity.name;
    const equipmentType = EquipmentTypeLabels[getEquipmentType(entity)];

    return `${equipmentType}(${name} - #${entity.id})`;
  }

  clone() {
    this.store.dispatch(cloneDistributionSystem({ id: this.entityId }));
  }
}
