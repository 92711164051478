import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Geocode } from '@/data/geocoder/models';
import { ConfirmedGeocodeResponse } from '@/modules/geocoder/components/confirm-geocode-response-dialog/confirm-geocode-response';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  first,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import {
  CompanyRequestParams,
  CompanyService,
} from '@/data/company/services/company-base.service';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { Company } from '@/data/company/models';
import { EMPTY, forkJoin, Observable, of, Subject } from 'rxjs';
import { toggleLoading } from '@/state/actions/app.actions';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { Relationship } from '@/data/relationship/models';
import { ServerResponse } from '@/core/schemes/server-response';
import { getInfoUser } from '@/state/selectors/info.selector';
import { User } from '@/data/core/models/user';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';

export class CompanyChangeDialogData {
  company?: Company;
  commit = true;
  retrieve = true;
}

@Component({
  selector: 'app-company-change-dialog',
  templateUrl: './company-change-dialog.component.html',
  styleUrls: ['./company-change-dialog.component.scss'],
})
export class CompanyChangeDialogComponent implements OnInit, OnDestroy {
  public initialized: boolean;
  public isEditForm = false;
  public entity?: Company;
  public commit = true;
  public retrieve = true;
  public form: FormGroup;
  // only for Create mode
  public checkedForExistingCompany = false;
  public newCompanyName = '';
  public existingCompanyControl = new FormControl();
  public existingCompanies$: Observable<ServerResponse<Company>>;

  private componentDestroyed$ = new Subject();
  private currentUser: User;

  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<CompanyChangeDialogComponent>,
    private fb: FormBuilder,
    private companyService: CompanyService,
    private dialog: MatDialog,
    private uiHelperService: UIHelperService,
    @Inject(MAT_DIALOG_DATA) public data: CompanyChangeDialogData
  ) {
    if (data?.company) {
      this.entity = Object.assign({}, data?.company);
    }
    this.commit = data?.commit || true;

    if (this.entity?.id) {
      this.isEditForm = true;
      this.checkedForExistingCompany = true;
    }

    if (!this.entity?.company_type) {
      throw Error('Provide entity.company_type for this dialog');
    }
  }

  ngOnInit() {
    const sources = {
      currentUser: this.store.select(getInfoUser).pipe(first()),
      company: of(this.entity),
    };

    this.store.dispatch(toggleLoading({ payload: true }));

    if (this.isEditForm && this.retrieve) {
      sources.company = this.companyService
        .retrieve(this.entity.id, { ignoreStore: true })
        .pipe(takeUntil(this.componentDestroyed$), first());
    }

    forkJoin(sources)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: ({ currentUser, company }) => {
          this.store.dispatch(toggleLoading({ payload: false }));

          this.currentUser = currentUser;
          this.entity = company;
          this.setupForm();
          this.initialized = true;
          if (this.isEditForm) {
            this.hydrateForm();
          }
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  private setupForm() {
    this.form = this.fb.group({
      name: [null, [Validators.required]],
      address: [null, [Validators.required]],
      office_phone: [null, Validators.required],
      default_email: ['', [Validators.required, Validators.email]],
    });

    if (!this.isEditForm) {
      this.existingCompanies$ = this.existingCompanyControl.valueChanges.pipe(
        takeUntil(this.componentDestroyed$),
        startWith(''),
        debounceTime(100),
        distinctUntilChanged(),
        tap(value => (this.newCompanyName = value)),
        tap(_ => this.store.dispatch(toggleLoading({ payload: true }))),
        switchMap(value =>
          this.companyService
            .list(
              Object.assign(new CompanyRequestParams(), {
                search: value,
                page_size: 50,
                company_type: this.entity.company_type,
              })
            )
            .pipe(catchError(err => EMPTY))
        ),
        tap(_ => this.store.dispatch(toggleLoading({ payload: false })))
      );
    } else {
      if (
        this.currentUser.company_info?.sponsor_slugs?.includes(
          CustomerHIRLSettings.companySlug
        )
      ) {
        this.form.get('name').disable();
      }
    }
  }

  hydrateForm() {
    this.form.patchValue(Object.assign({}, this.entity), {
      emitEvent: false,
      onlySelf: true,
    });
    // Company address do not have geocode field. We create it manually
    const geocode = new Geocode();
    geocode.raw_street_line1 = this.entity.street_line1;
    geocode.raw_street_line2 = this.entity.street_line2;
    geocode.raw_city = this.entity.city;
    geocode.raw_city_info = this.entity.city_info;
    geocode.raw_zipcode = this.entity.zipcode;

    this.form.patchValue({
      address: new ConfirmedGeocodeResponse({
        geocode: geocode,
        geocodeBrokeredResponse: null,
      }),
    });
  }

  save($event: MouseEvent) {
    $event.preventDefault();
    if (this.isEditForm) {
      this.edit();
    } else {
      this.create();
    }
  }

  private create() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    if (!this.commit) {
      this.dialogRef.close(this.entity);
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const data = new Company();

    data.name = this.form.value.name;
    data.company_type = this.entity.company_type;
    data.office_phone = this.form.value.office_phone;
    data.default_email = this.form.value.default_email;

    data.street_line1 = this.form.value.address.geocode.raw_street_line1;
    data.street_line2 = this.form.value.address.geocode.raw_street_line2;
    data.city = this.form.value.address.geocode.raw_city;
    data.zipcode = this.form.value.address.geocode.raw_zipcode;
    data.confirmed_address = false;

    if (this.form.value.address.geocodeBrokeredResponse) {
      data.geocode_response =
        this.form.value.address.geocodeBrokeredResponse.id;
      data.confirmed_address = true;
    } else {
      data.geocode_response = null;
      data.confirmed_address = false;
    }
    this.companyService
      .create(data)
      .pipe(first())
      .subscribe({
        next: company => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close(company);
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  private edit() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    if (!this.commit) {
      this.dialogRef.close(this.entity);
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const data = new Company();

    data.name = this.form.value.name;
    data.office_phone = this.form.value.office_phone;
    data.default_email = this.form.value.default_email;

    data.street_line1 = this.form.value.address.geocode.raw_street_line1;
    data.street_line2 = this.form.value.address.geocode.raw_street_line2;
    data.city = this.form.value.address.geocode.raw_city;
    data.zipcode = this.form.value.address.geocode.raw_zipcode;
    data.confirmed_address = false;

    if (this.form.value.address.geocodeBrokeredResponse) {
      data.geocode_response =
        this.form.value.address.geocodeBrokeredResponse.id;
      data.confirmed_address = true;
    } else {
      data.geocode_response = null;
      data.confirmed_address = false;
    }

    this.companyService
      .update(this.entity.id, data)
      .pipe(first())
      .subscribe({
        next: company => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close(company);
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  onCompanySelected($event: MatAutocompleteSelectedEvent) {
    if (typeof $event.option.value === 'string') {
      this.checkedForExistingCompany = true;
      this.form.patchValue({ name: this.newCompanyName });
    } else {
      const selectedCompany = $event.option.value;

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '400px',
        data: {
          title: 'Company with this name already exists',
          content: `Company with name <b>${selectedCompany.name}</b> already exists. We will create an association with existing company instead`,
        },
      });

      dialogRef
        .afterClosed()
        .pipe(first())
        .subscribe(result => {
          if (!result) {
            return;
          }
          this.store.dispatch(toggleLoading({ payload: true }));
          const relationship = new Relationship();
          relationship.company = this.currentUser.company_info.id;
          relationship.is_attached = true;
          relationship.is_viewable = true;
          relationship.is_owned = true;
          relationship.is_reportable = true;

          this.companyService
            .relationshipsCreate(selectedCompany.id, relationship)
            .pipe(first())
            .subscribe(
              _ => {
                this.store.dispatch(toggleLoading({ payload: false }));
                this.dialogRef.close(selectedCompany);
              },
              error => this.uiHelperService.handleUserRequestError(error)
            );
        });
    }
  }

  displayExistingCompanyAutocomplete(company: Company) {
    if (company) {
      return `${company.name}`;
    }
  }
}
