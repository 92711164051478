import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  ProductImage,
  ProductImageType,
  ProductImageTypeLabelMapping,
} from '@/data/product/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin, of, Subject } from 'rxjs';
import { User } from '@/data/core/models/user';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, switchMap, takeUntil } from 'rxjs/operators';
import { toggleLoading } from '@/state/actions/app.actions';
import { ProductImageService } from '@/data/product/services/product-image.service';
import {
  CustomerDocument,
  CustomerDocumentInfo,
} from '@/data/filehandling/models';
import { CustomerDocumentService } from '@/data/filehandling/services/customer-document.service';
import { ProductService } from '@/data/product/services/product.service';
import { UserService } from '@/data/core/services/user.service';

export interface ProductImageChangeDialogData {
  product_image: ProductImage;
  commit?: boolean;
}

@Component({
  selector: 'app-product-image-change-dialog',
  templateUrl: './product-image-change-dialog.component.html',
  styleUrls: ['./product-image-change-dialog.component.scss'],
})
export class ProductImageChangeDialogComponent implements OnInit, OnDestroy {
  protected readonly ProductImageType = ProductImageType;
  protected readonly ProductImageTypeLabelMapping =
    ProductImageTypeLabelMapping;

  public initialized: boolean;
  public isEditForm = false;
  public entity?: ProductImage;
  public commit = true;
  public retrieve = true;
  public form: FormGroup;

  private componentDestroyed$ = new Subject();
  private currentUser: User;

  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<ProductImageChangeDialogComponent>,
    private fb: FormBuilder,
    private userService: UserService,
    private productService: ProductService,
    private productImageService: ProductImageService,
    private customerDocumentService: CustomerDocumentService,
    private dialog: MatDialog,
    private uiHelperService: UIHelperService,
    @Inject(MAT_DIALOG_DATA) public data: ProductImageChangeDialogData
  ) {
    if (data?.product_image) {
      this.entity = Object.assign({}, data?.product_image);
    }
    this.commit = data?.commit || true;
    if (this.entity?.id) {
      this.isEditForm = true;
    }
  }

  ngOnInit() {
    const sources = {
      currentUser: this.store.select(getInfoUser).pipe(first()),
      productImage: of(this.entity),
    };

    this.store.dispatch(toggleLoading({ payload: true }));

    if (this.isEditForm && this.retrieve) {
      sources.productImage = this.productImageService
        .retrieve(this.entity.id, { ignoreStore: true })
        .pipe(takeUntil(this.componentDestroyed$), first());
    }

    forkJoin(sources)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: ({ currentUser, productImage }) => {
          this.store.dispatch(toggleLoading({ payload: false }));

          this.currentUser = currentUser;
          this.entity = productImage;
          this.setupForm();
          this.initialized = true;
          if (this.isEditForm) {
            this.hydrateForm();
          }
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  private setupForm() {
    this.form = this.fb.group({
      document: [null, [Validators.required]],
      image_type: [null, [Validators.required]],
      order: [1, [Validators.required, Validators.min(1)]],
    });
  }

  hydrateForm() {
    this.form.patchValue(Object.assign({}, this.entity), {
      emitEvent: false,
      onlySelf: true,
    });
  }

  save($event: MouseEvent) {
    $event.preventDefault();
    if (this.isEditForm) {
      this.edit();
    } else {
      this.create();
    }
  }

  private create() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    if (!this.commit) {
      this.dialogRef.close(this.entity);
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const data: CustomerDocument = new CustomerDocument();
    data.login_required = false;
    data.is_public = true;

    this.userService
      .documentsCreate(this.currentUser.id, data)
      .pipe(
        first(),
        // update file fields
        switchMap((customerDocument: CustomerDocument) => {
          const formData = new FormData();
          formData.append('document', this.form.value.document);
          return this.customerDocumentService.update(
            customerDocument.id,
            formData
          );
        })
      )
      .pipe(
        first(),
        switchMap((customerDocument: CustomerDocument) => {
          const productImageData = new ProductImage();
          productImageData.image_type = this.form.value.image_type;
          productImageData.order = this.form.value.order;
          productImageData.customer_document = customerDocument?.id;
          return this.productService.product_imagesCreate(
            this.entity.product,
            productImageData
          );
        })
      )
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: (productImage: ProductImage) => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close(productImage);
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  private edit() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    if (!this.commit) {
      this.dialogRef.close(this.entity);
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const data: CustomerDocument = new CustomerDocument();
    data.login_required = false;
    data.is_public = true;

    of(this.entity)
      .pipe(
        first(),
        switchMap(productImage => {
          if (this.form.value.document?.name) {
            return this.userService
              .documentsCreate(this.currentUser.id, data)
              .pipe(
                first(),
                // update file fields
                switchMap((customerDocument: CustomerDocument) => {
                  const formData = new FormData();
                  formData.append('document', this.form.value.document);
                  return this.customerDocumentService.update(
                    customerDocument.id,
                    formData
                  );
                })
              );
          }

          return of(productImage.customer_document_info);
        })
      )

      .pipe(
        first(),
        switchMap(
          (customerDocument: CustomerDocument | CustomerDocumentInfo) => {
            const productImageData = new ProductImage();
            productImageData.image_type = this.form.value.image_type;
            productImageData.order = this.form.value.order;
            productImageData.customer_document = customerDocument?.id;
            return this.productImageService.update(
              this.entity.id,
              productImageData
            );
          }
        )
      )
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: (productImage: ProductImage) => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close(productImage);
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }
}
