import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-top-nav-bar',
  templateUrl: './top-nav-bar.component.html',
  styleUrls: ['./top-nav-bar.component.scss'],
})
export class TopNavBarComponent {
  @Input() name: string;
  @Output() menuClick = new EventEmitter();
  isExpanded = true;

  toggleMenu() {
    this.isExpanded = !this.isExpanded;
    this.menuClick.emit();
  }
}
