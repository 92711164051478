<div class="card position-relative" appFocusTarget entityName="rimJoist" entityId="{{entityId}}">
    <app-loading-overlay *ngIf="loading"></app-loading-overlay>
    <div class="card-body pb-0">
        <app-form formName="rimJoistFormGroup" [formGroup]="rimJoistFormGroup" (save)="onSave($event)">
            <div>
                <app-input formControlName="name" [label]="fieldLabels.name"></app-input>
            </div>
            <div>
                <app-select formControlName="interior_location" [label]="fieldLabels.interior_location"
                    [items]="interiorLocationLabelMapping"></app-select>
                <app-select formControlName="exterior_location" [label]="fieldLabels.exterior_location"
                    [items]="exteriorLocationLabelMapping"></app-select>
            </div>
            <div>
                <app-input formControlName="area" [label]="fieldLabels.area" unit="{{areaLabels[entity?.area_units]}}" type="expression"></app-input>
            </div>
            <div>
                <app-foreign-model-field
                    [name]="rimJoistTypeName"
                    [errorCount]="rimJoistTypeErrorCount"
                    (add)="addRimJoistType()"
                    (edit)="editRimJoistType()"
                    (remove)="removeRimJoistType()"
                    label="Rim joist type">
                </app-foreign-model-field>
            </div>
        </app-form>
    </div>
    <div class="card-footer p-0 text-right">
        <app-model-action-buttons [entityId]="entityId" modelName="rimJoist" (clone)="clone()" (delete)="removeRimJoist()">
        </app-model-action-buttons>
    </div>
</div>
