import { getFloorplan } from '@/state/selectors/floorplan/floorplan.selector';
import { EEPAndHomeInfo } from '@/data/home/models/eep_program_home_status';
import { FloorPlanService } from '@/data/floorplan/services/floorplan.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ListRequestParams } from '@/core/schemes/request-params';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { first, takeUntil, filter } from 'rxjs/operators';
import { FormGroup, FormBuilder } from '@angular/forms';
import { EEPProgramHomeStatusStateLabelMapping } from '@/data/home/models/eep_program_home_status';
@Component({
  selector: 'app-floorplan-homes-tab',
  templateUrl: './floorplan-homes-tab.component.html',
  styleUrls: ['./floorplan-homes-tab.component.scss'],
})
export class FloorplanHomesTabComponent implements OnInit, OnDestroy {
  initialized = false;
  public floorPlanId: number;

  public eepHomeInfo: EEPAndHomeInfo[];
  public count: number;
  public isLoading = true;
  public storedParams: ListRequestParams = new ListRequestParams(1, '', '');
  public componentDestroyed$ = new Subject();
  public filterForm: FormGroup;
  public eepProgramHomeStatusStateLabelMapping =
    EEPProgramHomeStatusStateLabelMapping;

  constructor(
    public store: Store<AppState>,
    public dataService: FloorPlanService,
    public formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.store
      .select(getFloorplan)
      .pipe(
        filter(val => val !== null),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(floorPlan => {
        this.floorPlanId = floorPlan.id;
        this.list();
      });
    this.setupFilterForm();
  }

  setupFilterForm() {
    this.filterForm = this.formBuilder.group({
      search: [null],
    });

    this.filterForm.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(value => {
        this.storedParams = Object.assign(this.storedParams, value);
        this.list();
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  list() {
    this.isLoading = true;

    this.dataService
      .home_statuses(this.floorPlanId, this.storedParams)
      .pipe(first())
      .subscribe(data => {
        this.eepHomeInfo = data.results;
        this.count = data.count;
        this.isLoading = false;
        this.initialized = true;
      });
  }

  onSort({ sorts }) {
    this.storedParams.ordering = `${sorts[0].dir === 'desc' ? '-' : ''}${
      sorts[0].prop
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  onFooterPage(event) {
    this.storedParams.page = event.page;
    this.list();
  }
  getState(obj) {
    if (obj.certification_date) {
      return 'certified';
    } else {
      return obj.state;
    }
  }
}
