import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DIALOG_WIDTH } from '@/modules/simulation/constants';
import {
  WindowTypeChangeDialogComponent,
  WindowTypeChangeDialogData,
} from '../window-type-change-dialog/window-type-change-dialog.component';
import {
  WindowBackendDict,
  createWindowForm,
  FIELD_LABELS,
  WindowPerformanceClassLabels,
} from '@/data/simulation/models/enclosure/Window';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectWindowViewSet } from '@/modules/simulation/state/window/selectors';
import { filter, first, takeUntil } from 'rxjs/operators';
import { selectWindowTypeNameAndError } from '@/modules/simulation/state/window-type/selectors';
import {
  removeWindow,
  updateWindow,
} from '@/modules/simulation/state/window/actions';
import { CREATE_MODE, EDIT_MODE } from '@/core/constants';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { removeWindowType } from '@/modules/simulation/state/window-type/actions';
import {
  AreaUnitLabels,
  LengthUnitLabels,
  OrientationLabels,
  OrientationToAzimuth,
} from '@/data/simulation/enumerations';
import { cloneWindow } from '@/modules/simulation/state/simulation/actions';

@Component({
  selector: 'app-window',
  templateUrl: './window.component.html',
  styleUrls: ['./window.component.scss'],
})
export class WindowComponent implements OnDestroy, OnInit {
  @Input() entityId: number;
  fieldLabels = FIELD_LABELS;

  lengthLabels = LengthUnitLabels;
  areaLabels = AreaUnitLabels;

  entity: WindowBackendDict = null;
  loading = false;

  private componentDestroyed$ = new Subject();

  public windowTypeName: string;
  public windowTypeErrorCount = 0;
  public orientationLabels = OrientationLabels;

  public windowFormGroup: FormGroup;
  public wallControl = new FormControl(null, Validators.required);
  wallLabels = {
    above_grade_wall: 'Above Grade Wall',
    foundation_wall: 'Foundation Wall',
  };
  performanceClassLabels = WindowPerformanceClassLabels;

  constructor(public dialog: MatDialog, public store: Store) {}

  updateTypeDetails(typeId: number) {
    if (!typeId) {
      this.windowTypeName = null;
      this.windowTypeErrorCount = 1;
      return;
    }

    this.store
      .select(selectWindowTypeNameAndError(typeId))
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(({ name, errorCount }) => {
        this.windowTypeName = name;
        this.windowTypeErrorCount = errorCount;
      });
  }

  updateWallSelectorFormGroup(isFoundationWall: boolean) {
    this.wallControl.setValue(
      isFoundationWall ? 'foundation_wall' : 'above_grade_wall',
      { emitEvent: false }
    );
  }

  ngOnInit(): void {
    this.wallControl.valueChanges.subscribe(value => {
      this.windowFormGroup.patchValue({
        above_grade_wall: null,
        foundation_wall: null,
      });
    });

    this.store
      .select(selectWindowViewSet(this.entityId))
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(val => val !== null)
      )
      .subscribe(({ data, loading }) => {
        this.entity = data;
        this.loading = loading;
        if (loading) return;

        if (!this.windowFormGroup) {
          this.windowFormGroup = createWindowForm(data);
          this.updateWallSelectorFormGroup(data.foundation_wall !== null);
          this.windowFormGroup.controls.above_grade_wall.valueChanges.subscribe(
            value => {
              if (value === null) return;
              this.updateWallSelectorFormGroup(false);
            }
          );

          this.windowFormGroup.controls.foundation_wall.valueChanges.subscribe(
            value => {
              if (value === null) return;
              this.updateWallSelectorFormGroup(true);
            }
          );

          this.windowFormGroup.controls.orientation.valueChanges.subscribe(
            value => {
              if (value === null) return;
              this.windowFormGroup.controls.azimuth.patchValue(
                OrientationToAzimuth[value]
              );
            }
          );
        } else {
          this.windowFormGroup.patchValue(data);
          this.updateWallSelectorFormGroup(data.foundation_wall !== null);
        }

        this.updateTypeDetails(data.type);
      });
  }

  openEditWindowTypeChangeDialog(data: WindowTypeChangeDialogData) {
    return this.dialog.open(WindowTypeChangeDialogComponent, {
      width: DIALOG_WIDTH,
      disableClose: true,
      data,
    });
  }

  removeWindow() {
    let windowName = this.entity.name;
    if (windowName === null || windowName === '') {
      windowName = 'Untitled';
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '50%',
      data: {
        title: `Do you want to delete "${windowName}" window ?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (result) {
          this.store.dispatch(removeWindow({ window: this.entity }));
        }
      });
  }

  cloneWindow() {
    this.store.dispatch(cloneWindow({ id: this.entityId }));
  }

  editWindowType() {
    const updatedWindowType = this.openEditWindowTypeChangeDialog({
      commit: true,
      mode: EDIT_MODE,
      entityId: this.entity.type,
    });

    updatedWindowType.afterClosed().subscribe(() => {
      this.updateTypeDetails(this.entity.type);
    });
  }

  addWindowType() {
    this.onSave(this.windowFormGroup.value);
    const newWindowDialog = this.openEditWindowTypeChangeDialog({
      commit: true,
      mode: CREATE_MODE,
      parentId: this.entityId,
    });

    newWindowDialog.afterClosed().subscribe(windowType => {
      if (!windowType) {
        return;
      }

      this.windowFormGroup.patchValue({ type: windowType.id });
      this.updateTypeDetails(windowType.id);
    });
  }

  removeWindowType() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '50%',
      data: {
        title: `Do you want to delete "${this.windowTypeName}" window type ?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (result) {
          this.store.dispatch(
            removeWindowType({
              windowId: this.entityId,
              windowTypeId: this.entity.type,
            })
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  onSave(windowChanges: Partial<WindowBackendDict>): void {
    this.store.dispatch(
      updateWindow({
        id: this.entityId,
        windowChanges: windowChanges,
      })
    );
  }
}
