import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import * as AboveGradeWallActions from './actions';
import * as AboveGradeWallTypeActions from '../above-grade-wall-type/actions';
import * as SimulationActions from '../simulation/actions';
import * as SharedActions from '../shared/shared.actions';
import { AboveGradeWallService } from '@/data/simulation/services/above-grade-wall.service';
import { AboveGradeWallTypeService } from '@/data/simulation/services/above-grade-wall-type.service';
import { AboveGradeWallTypeBackendDict } from '@/data/simulation/models/enclosure/AboveGradeWallType';
import { AboveGradeWallBackendDict } from '@/data/simulation/models/enclosure/AboveGradeWall';
import { ModelGraphService } from '../../services/model-graph.service';
import { AboveGradeWallValidator } from '../../validators/above-grade-wall.validator';
import { StateModelName } from '../../state.registry';

@Injectable()
export class AboveGradeWallEffects {
  loadDetailedAboveGradeWalls$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AboveGradeWallActions.loadDetailedAboveGradeWalls),
      mergeMap(action => {
        const detailedWallTypes: AboveGradeWallTypeBackendDict[] = [];
        const aboveGradeWalls: AboveGradeWallBackendDict[] = [];
        action.detailedAboveGradeWalls.forEach(detailedWall => {
          const { type_info: detailedWallType, ...wall } = detailedWall;
          if (detailedWallType) {
            detailedWallTypes.push(detailedWallType);
          }
          this.modelGraphService.attachModel(
            'aboveGradeWall',
            detailedWall.id,
            'aboveGradeWallType',
            [detailedWall.type]
          );
          aboveGradeWalls.push(wall);
        });

        const errors = AboveGradeWallValidator.validate(
          action.detailedAboveGradeWalls
        );

        return of(
          AboveGradeWallActions.loadAboveGradeWallsSuccess({
            aboveGradeWalls: aboveGradeWalls,
            errors: errors,
          }),
          AboveGradeWallTypeActions.loadDetailedAboveGradeWallTypes({
            wallTypes: detailedWallTypes,
          })
        );
      })
    );
  });

  updateAboveGradeWall$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AboveGradeWallActions.updateAboveGradeWall),
      mergeMap(action =>
        this.abovegradewallService.update(action.aboveGradeWallChanges).pipe(
          mergeMap(updatedWall => {
            const errors = AboveGradeWallValidator.validate([updatedWall]);
            return of(
              AboveGradeWallActions.updateAboveGradeWallSuccess({
                aboveGradeWall: updatedWall,
                errors: errors[updatedWall.id],
              }),
              SharedActions.updateCrossModelErrors({
                modelName: StateModelName.aboveGradeWall,
                changedFields: Object.keys(action.aboveGradeWallChanges),
              })
            );
          }),
          catchError(error =>
            of(
              AboveGradeWallActions.updateAboveGradeWallFailure({
                id: action.aboveGradeWallChanges.id,
              }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        )
      )
    );
  });

  removeAboveGradeWall$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AboveGradeWallActions.removeAboveGradeWall),
      mergeMap(action =>
        this.abovegradewallService.delete(action.aboveGradeWall.id).pipe(
          mergeMap(() =>
            of(
              SimulationActions.removeItemFromList({
                fieldName: 'above_grade_walls',
                id: action.aboveGradeWall.id,
              }),
              AboveGradeWallActions.removeAboveGradeWallSuccess({
                id: action.aboveGradeWall.id,
              })
            )
          ),
          catchError(error =>
            of(
              AboveGradeWallActions.removeAboveGradeWallFailure({
                id: action.aboveGradeWall.id,
              }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        )
      )
    );
  });

  removeAboveGradeWallType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AboveGradeWallTypeActions.removeAboveGradeWallType),
      mergeMap(action =>
        this.abovegradewallTypeService.delete(action.wallTypeId).pipe(
          mergeMap(() => [
            AboveGradeWallActions.setAboveGradeWallType({
              wallId: action.wallId,
              wallTypeId: null,
            }),
            AboveGradeWallTypeActions.removeAboveGradeWallTypeSuccess({
              id: action.wallTypeId,
            }),
          ]),
          catchError(error => of(SharedActions.reportAPIFailure({ error })))
        )
      )
    );
  });

  addAboveGradeWallType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AboveGradeWallTypeActions.addAboveGradeWallType),
      mergeMap(action =>
        this.abovegradewallTypeService
          .create(action.wallId, action.wallType)
          .pipe(
            mergeMap(wallType =>
              of(
                AboveGradeWallTypeActions.loadDetailedAboveGradeWallTypes({
                  wallTypes: [wallType],
                }),
                AboveGradeWallActions.setAboveGradeWallType({
                  wallId: action.wallId,
                  wallTypeId: wallType.id,
                })
              )
            ),
            catchError(error => of(SharedActions.reportAPIFailure({ error })))
          )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private modelGraphService: ModelGraphService,
    private abovegradewallService: AboveGradeWallService,
    private abovegradewallTypeService: AboveGradeWallTypeService
  ) {}
}
