import { createReducer, on } from '@ngrx/store';
import * as WindowActions from './actions';
import { WindowBackendDict } from '@/data/simulation/models/enclosure/Window';
import { BaseState, sharedInitialState } from '../shared/base.state';
import { addErrorCount, addStateErrorCount } from '../helper';

export type WindowState = BaseState<WindowBackendDict>;

export const initialState: WindowState = sharedInitialState;

export const reducer = createReducer(
  initialState,
  on(WindowActions.loadWindowsSuccess, (currentState, payload) => {
    const entities = payload.windows.reduce(
      (acc, window) => ({
        ...acc,
        [window.id]: window,
      }),
      {}
    );

    const loadingStatus = payload.windows.reduce(
      (acc, window) => ({ ...acc, [window.id]: false }),
      {}
    );

    return {
      entities: { ...currentState.entities, ...entities },
      loading: { ...currentState.loading, ...loadingStatus },
      errors: {
        ...currentState.errors,
        ...addStateErrorCount(payload.errors),
      },
    };
  }),

  on(WindowActions.removeWindowSuccess, (currentState, { id }) => {
    const { [id]: removed, ...entities } = currentState.entities;
    const { [id]: removedLoading, ...loading } = currentState.loading;
    const { [id]: removedErrors, ...errors } = currentState.errors;

    return {
      entities,
      loading,
      errors,
    };
  }),

  on(WindowActions.updateWindow, (state, { windowChanges }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [windowChanges.id]: true,
      },
    };
  }),

  on(WindowActions.removeWindow, (state, { window }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [window.id]: true,
      },
    };
  }),

  on(
    WindowActions.updateWindowFailure,
    WindowActions.removeWindowFailure,
    (state, { id }) => {
      return {
        ...state,
        loading: {
          ...state.loading,
          [id]: false,
        },
      };
    }
  ),

  on(WindowActions.updateWindowErrors, (state, { id, errors }) => {
    return {
      ...state,
      errors: {
        ...state.errors,
        [id]: errors,
      },
    };
  }),

  on(WindowActions.updateWindowSuccess, (state, { window, errors }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        [window.id]: window,
      },
      loading: {
        ...state.loading,
        [window.id]: false,
      },
      errors: {
        ...state.errors,
        [window.id]: addErrorCount(errors),
      },
    };
  }),

  on(WindowActions.setWindowType, (state, { windowId, windowTypeId }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        [windowId]: {
          ...state.entities[windowId],
          type: windowTypeId,
        },
      },
    };
  })
);
