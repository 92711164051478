import { SimulationConfigService } from '@/data/simulation/services/simulation-config.service';
import { toggleLoading } from '@/state/actions/app.actions';
import { getInfoUser } from '@/state/selectors/info.selector';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { loadSimulationConfig } from '../../state/simulation-config/actions';

@Component({
  selector: 'app-company-simulation-config',
  templateUrl: './company-simulation-config.component.html',
  styleUrls: ['./company-simulation-config.component.scss'],
})
export class CompanySimulationConfigComponent implements OnInit {
  activeTab = 'report';
  configId = null;
  initialized = false;

  constructor(
    public simulationConfigService: SimulationConfigService,
    public store: Store
  ) {}

  ngOnInit(): void {
    this.store.dispatch(toggleLoading({ payload: true }));
    this.store
      .select(getInfoUser)
      .pipe(first())
      .subscribe(userInfo => {
        this.simulationConfigService
          .getCompanySimulationConfig(userInfo.company)
          .subscribe(
            config => {
              this.initialized = true;
              this.configId = config[0].id;
              this.store.dispatch(
                loadSimulationConfig({ simulationConfig: config[0] })
              );
              this.store.dispatch(toggleLoading({ payload: false }));
            },
            error => {
              // Handle any errors from either of the requests
              console.error('Error in one of the requests:', error);
            }
          );
      });
  }
}
