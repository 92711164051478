<div class="container-fluid" *ngIf="initialized">
  <div class="row">
    <div class="col-12">
      <div class="row mb-2">
        <div class="col-6">
          <h3>
            <span *ngIf="!entitiesIsLoading">{{ entitiesCount | number }}</span>
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" *ngIf="entitiesIsLoading"></fa-icon>
            Product Groups
          </h3>
        </div>
        <div class="col-6 text-right">
          <ng-container *ngIf="this.currentUser?.is_superuser || this.currentUser.company_info?.slug === CustomerHIRLSettings.companySlug">
            <button mat-raised-button color="accent" (click)="downloadReport($event)" class="mr-2">
              Export
            </button>
            <button mat-raised-button color="accent" (click)="openRenewalReminderDialog($event)" class="mr-2">
              Send Renewal Notification
            </button>
            <ng-container *ngIf="!this.selectedEntities?.length || entitiesIsLoading">
              <button mat-raised-button color="accent" class="mr-2" [disabled]="true" matTooltip="Select at least one Product Group">
                Download Certificate
              </button>
            </ng-container>
            <ng-container *ngIf="this.selectedEntities?.length">
              <button mat-raised-button color="accent" (click)="certificateDownload($event)" class="mr-2">
                Download Certificate
              </button>
            </ng-container>
            <ng-container *ngIf="!this.selectedEntities?.length || entitiesIsLoading">
              <button mat-raised-button color="accent" class="mr-2" [disabled]="true" matTooltip="Select at least one Product Group">Certify</button>
            </ng-container>
            <ng-container *ngIf="this.selectedEntities?.length">
              <button mat-raised-button color="accent" (click)="onPublish($event)" class="mr-2" [matTooltip]="'Certify selected Product Group(s)'">Certify</button>
            </ng-container>
          </ng-container>
          <app-permission-mat-button [permissionResponse]="createPermissionResponse" (click)="create($event);">
            Create New
          </app-permission-mat-button>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <hr>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <div class="row">
            <div class="col-12">
              <form [formGroup]="filterFromGroup" class="card">
                <h5 class="card-header">
                  Filters
                  <a href="#" matTooltip="Preview applied filters" (click)="showAppliedFiltersDialog($event);">
                    <fa-icon [icon]="['fas', 'filter']"></fa-icon>
                  </a>
                  <a href="#" class="small float-right" (click)="resetFilters($event);">Reset filters</a>
                </h5>
                <div class="card-body filter-card-body">
                  <mat-accordion class="filter-accordion" multi>
                    <mat-expansion-panel [expanded]="true">
                      <div class="row">
                        <div class="col-12">
                          <mat-form-field class="w-100">
                            <mat-label>Search</mat-label>
                            <app-search-input formControlName="search"
                                              placeholder="Type to search something"></app-search-input>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <mat-form-field class="w-100">
                            <mat-label>Product Manufacturer</mat-label>
                            <app-generic-model-chips formControlName="manufacturer_info"
                                                     modelClassName="company"
                                                     [filterParams]="{company_type: CompanyType.manufacturer, product_related: productRelated, ordering: 'name'}"
                                                     [optionDisplay]="companyFilterOptionDisplay" [panelWidth]="640"
                                                     [initialValueIds]="storedParams.manufacturer"></app-generic-model-chips>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <mat-form-field class="w-100">
                            <mat-label>Product Status</mat-label>
                            <mat-select formControlName="status" [multiple]="true">
                              <mat-option
                                *ngFor="
                                    let item of ProductStatusLabelMapping
                                      | defaultOrderKeyValue
                                  "
                                [value]="item.key"
                              >
                                {{ item.value.label }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <mat-form-field class="w-100">
                            <mat-label>Categories</mat-label>
                            <app-generic-model-chips formControlName="category_info"
                                                     modelClassName="category"
                                                     hasEmptyChoice="true"
                                                     emptyChoiceLabel="Unassigned"
                                                     [emptyChoiceValue]="'unassigned'"
                                                     [filterParams]="{product_related: productRelated, ordering: 'name'}"
                                                     [panelWidth]="640"
                                                     [initialValueIds]="storedParams.categories"></app-generic-model-chips>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <mat-form-field class="w-100">
                            <mat-label>Brand</mat-label>
                            <app-generic-model-chips formControlName="company_brands_info"
                                                     modelClassName="company_brand"
                                                     hasEmptyChoice="true"
                                                     emptyChoiceLabel="Unassigned"
                                                     [emptyChoiceValue]="'unassigned'"
                                                     [filterParams]="{product_related: productRelated, ordering: 'name'}"
                                                     [panelWidth]="640"
                                                     [initialValueIds]="storedParams.company_brands"></app-generic-model-chips>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <mat-form-field class="w-100">
                            <mat-label>Certificate Expiration</mat-label>
                            <app-date-range-mat-select
                              formControlName="hirl_certificate_expiration_date__range_info"
                              [dateRanges]="hirlCertificateExpirationDateRanges"
                            ></app-date-range-mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-9">
          <div class="row">
            <div class="col-12">
              <ng-container *ngIf="selectAllEntities">Selected <b>{{ entitiesCount }}</b> Product Groups</ng-container>
              <ng-container *ngIf="!selectAllEntities && selectedEntities?.length">Selected <b>{{ selectedEntities.length }}</b> Product Groups</ng-container>
            </div>
          </div>
          <div class="row" *ngIf="entitiesIsLoading">
            <div class="col-12">
              <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
            </div>
          </div>
          <table mat-table
                 [dataSource]="entities"
                 matSort
                 [matSortActive]="storedParams.toTableSort().active"
                 [matSortDirection]="storedParams.toTableSort().direction"
                 (matSortChange)="onSort($event)"
                 class="w-100"
          >

            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox matTooltip="Select All Product Groups" [(ngModel)]="selectAllEntities" (change)="onSelectAll()" [disabled]="entitiesIsLoading"></mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox (change)="onEntitySelect($event, row)"
                              [checked]="isEntityChecked(row)"
                              [disabled]="entitiesIsLoading"
                ></mat-checkbox>
              </td>
            </ng-container>

            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Product Group Name</th>
              <td mat-cell *matCellDef="let row">
                <app-product-display [product]="row" displayType="onlyNameAsLink"></app-product-display>
              </td>
            </ng-container>

            <ng-container matColumnDef="manufacturer__name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Product Manufacturer</th>
              <td mat-cell *matCellDef="let row">
                <app-company-display [company]="row.manufacturer_info" displayType="onlyNameAsLink"></app-company-display>
              </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>Web Status</th>
              <td mat-cell *matCellDef="let row">
                <span class="badge ml-2 {{ ProductStatusLabelMapping[row.status]?.badgeClass }}">{{ ProductStatusLabelMapping[row.status]?.label }}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="thumbnail">
              <th mat-header-cell *matHeaderCellDef>Image(s)</th>
              <td mat-cell *matCellDef="let row">
                <img src="{{ row.thumbnail_info?.customer_document_info?.document || 'https://placehold.co/64x64?text=No+Thumbnail' }}" width="64" height="64">
              </td>
            </ng-container>

            <ng-container matColumnDef="hirl_certificate_number">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Certificate Number</th>
              <td mat-cell *matCellDef="let row">
                {{ row.hirl_certificate_number }}
              </td>
            </ng-container>

            <ng-container matColumnDef="hirl_certificate_expiration_date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Certificate Expiration Date</th>
              <td mat-cell *matCellDef="let row">
                {{ row.hirl_certificate_expiration_date | date: "MM/dd/yyyy" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="paid_non_expired_initial_fee">
              <th mat-header-cell *matHeaderCellDef>Application Status</th>
              <td mat-cell *matCellDef="let row">
                <ng-container *ngIf="!row.payment_request_initial_fee?.id">Not created</ng-container>
                <app-payment-request-display [paymentRequest]="row.payment_request_initial_fee" displayType="nameAsLinkAndStatus" *ngIf="row.payment_request_initial_fee?.id"></app-payment-request-display>
                <div>
                  <app-invoice-display [invoice]="row?.payment_request_initial_fee?.invoice_info" displayType="nameAsLinkAndStatus" *ngIf="row?.payment_request_initial_fee?.invoice_info"></app-invoice-display>
                </div>
                <ng-container *ngIf="row.paid_non_expired_initial_fee?.id && (row.payment_request_initial_fee?.id !== row.paid_non_expired_initial_fee?.id)">
                  <hr>
                  <app-payment-request-display [paymentRequest]="row.paid_non_expired_initial_fee" displayType="nameAsLinkAndStatus" *ngIf="row.paid_non_expired_initial_fee?.id"></app-payment-request-display>
                  <div>
                    <app-invoice-display [invoice]="row?.paid_non_expired_initial_fee?.invoice_info" displayType="nameAsLinkAndStatus" *ngIf="row?.paid_non_expired_initial_fee?.invoice_info"></app-invoice-display>
                  </div>
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="paid_non_expired_payment_request">
              <th mat-header-cell *matHeaderCellDef>Product Fee</th>
              <td mat-cell *matCellDef="let row">
                <ng-container *ngIf="!row.product_fee_payment_request?.id">Not created</ng-container>
                <app-payment-request-display [paymentRequest]="row.product_fee_payment_request" displayType="nameAsLinkAndStatus" *ngIf="row.product_fee_payment_request?.id"></app-payment-request-display>
                <div>
                  <app-invoice-display [invoice]="row?.product_fee_payment_request?.invoice_info" displayType="nameAsLinkAndStatus" *ngIf="row?.product_fee_payment_request?.invoice_info"></app-invoice-display>
                </div>
                <ng-container *ngIf="row.paid_non_expired_payment_request?.id && (row.product_fee_payment_request?.id !== row.paid_non_expired_payment_request?.id)">
                  <hr>
                  <app-payment-request-display [paymentRequest]="row.paid_non_expired_payment_request" displayType="nameAsLinkAndStatus" *ngIf="row.paid_non_expired_payment_request?.id"></app-payment-request-display>
                  <div>
                    <app-invoice-display [invoice]="row?.paid_non_expired_payment_request?.invoice_info" displayType="nameAsLinkAndStatus" *ngIf="row?.paid_non_expired_payment_request?.invoice_info"></app-invoice-display>
                  </div>
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="countersigned_agreement">
              <th mat-header-cell *matHeaderCellDef>PM Agreement</th>
              <td mat-cell *matCellDef="let row">
                <ng-container *ngIf="!row.product_agreement?.id">Not created</ng-container>
                <app-hirl-product-agreement-display [hirlProductAgreement]="row.product_agreement" displayType="nameAsLinkAndStatus" *ngIf="row.product_agreement?.id"></app-hirl-product-agreement-display>
                <ng-container *ngIf="row.countersigned_agreement?.id && (row.product_agreement?.id !== row.countersigned_agreement?.id)">
                  <hr>
                  <app-hirl-product-agreement-display [hirlProductAgreement]="row.countersigned_agreement" displayType="nameAsLinkAndStatus" *ngIf="row.countersigned_agreement?.id"></app-hirl-product-agreement-display>
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="coi">
              <th mat-header-cell *matHeaderCellDef>COI</th>
              <td mat-cell *matCellDef="let row">
                <ng-container *ngIf="!row.manufacturer_general_liability_coi?.id">Not created</ng-container>
                <app-coi-document-display [coiDocument]="row.manufacturer_general_liability_coi" displayType="nameAsLinkAndDates" *ngIf="row.manufacturer_general_liability_coi?.id"></app-coi-document-display>
                <ng-container *ngIf="row.manufacturer_general_liability_coi?.id && (row.manufacturer_general_liability_coi?.id !== row.not_expired_manufacturer_general_liability_coi?.id)">
                  <hr>
                  <app-coi-document-display [coiDocument]="row.not_expired_manufacturer_general_liability_coi" displayType="nameAsLinkAndDates" *ngIf="row.not_expired_manufacturer_general_liability_coi?.id"></app-coi-document-display>
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>Actions</th>
              <td mat-cell *matCellDef="let row">
                <ng-container *ngIf="row.status === ProductStatus.active">
                  <a href="{{ row.customer_hirl_gcp_certificate_link }}" target="_blank" matTooltip="Open Certificate in new tab" class="mr-2">
                    <fa-icon [icon]="['fas', 'external-link-alt']"></fa-icon>
                  </a>
                  <app-copy-to-clipboard-button [value]="row.customer_hirl_gcp_certificate_link" matTooltip="Copy link">
                    <fa-icon [icon]="['fas', 'link']"></fa-icon>
                  </app-copy-to-clipboard-button>
                </ng-container>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <div class="d-flex flex-row">
            <div class="align-self-center">
            </div>
            <div class="d-flex flex-fill justify-content-end">
              <mat-paginator [length]="entitiesCount"
                             [pageIndex]="$any(storedParams.page) - 1"
                             [pageSize]="storedParams.page_size"
                             (page)="onPaginateChange($event)"
                             aria-label="Select page">
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
