import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  HIRLRPCUpdaterProjectTypeLabelMapping,
  HIRLRPCUpdaterRequest,
  HIRLRPCUpdaterRequestState,
  HIRLRPCUpdaterRequestStateLabelMapping,
  HIRLRPCUpdaterScoringPathLabelMapping,
} from '@/data/rpc/models';
import { User } from '@/data/core/models/user';
import { toggleLoading } from '@/state/actions/app.actions';
import { forkJoin, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, takeUntil } from 'rxjs/operators';
import { AppState } from '@/state/reducers';
import { HIRLRPCUpdaterRequestService } from '@/data/rpc/services/hirl-rpc-updater-request.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationService } from '@/shared/services/navigation-service.service';
import { UIHelperService } from '@/shared/services/ui-helper.service';

@Component({
  selector: 'app-hirl-rpc-updater-request-detail',
  templateUrl: './hirl-rpc-updater-request-detail.component.html',
  styleUrls: ['./hirl-rpc-updater-request-detail.component.scss'],
})
export class HirlRpcUpdaterRequestDetailComponent implements OnInit, OnDestroy {
  public initialized = false;
  public isRefreshing = false;
  public currentUser: User;

  public hirlRPCUpdaterRequestState = HIRLRPCUpdaterRequestState;
  public hirlRPCUpdaterRequest: HIRLRPCUpdaterRequest;

  public rpcUpdaterProjectTypeLabelMapping =
    HIRLRPCUpdaterProjectTypeLabelMapping;
  public rpcUpdaterScoringPathLabelMapping =
    HIRLRPCUpdaterScoringPathLabelMapping;

  public hirlRPCUpdaterRequestStateLabelMapping =
    HIRLRPCUpdaterRequestStateLabelMapping;
  private componentDestroyed$ = new Subject();
  private refreshIntervalId$: any;

  constructor(
    public store: Store<AppState>,
    public hirlRPCUpdaterRequestService: HIRLRPCUpdaterRequestService,
    public router: Router,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private navigation: NavigationService,
    private uiHelperService: UIHelperService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(params => {
        this.store.dispatch(toggleLoading({ payload: true }));

        forkJoin({
          hirlRPCUpdaterRequest: this.hirlRPCUpdaterRequestService.retrieve(
            params.hirlRPCUpdaterId
          ),
          currentUser: this.store.select(getInfoUser).pipe(first()),
        }).subscribe(
          ({ hirlRPCUpdaterRequest, currentUser }) => {
            this.currentUser = currentUser;
            this.hirlRPCUpdaterRequest = hirlRPCUpdaterRequest;

            this.initialized = true;
            this.store.dispatch(toggleLoading({ payload: false }));

            if (this.refreshIntervalId$) {
              clearInterval(this.refreshIntervalId$);
            }

            this.refreshIntervalId$ = setInterval(() => {
              this.isRefreshing = true;

              this.hirlRPCUpdaterRequestService
                .retrieve(params.hirlRPCUpdaterId)
                .pipe(takeUntil(this.componentDestroyed$), first())
                .subscribe(updatedHirlRPCUpdaterRequest => {
                  this.hirlRPCUpdaterRequest = updatedHirlRPCUpdaterRequest;
                  this.isRefreshing = false;
                });
            }, 15000);
          },
          error => this.uiHelperService.handleMainRequestError(error)
        );
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();

    if (this.refreshIntervalId$) {
      clearInterval(this.refreshIntervalId$);
    }
  }
}
