import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { forkJoin, Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute } from '@angular/router';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { filter, first, map, mergeMap, takeUntil } from 'rxjs/operators';
import {
  HIRLProject,
  HIRLProjectRegistration,
  HIRLProjectRegistrationType,
} from '@/data/customer-hirl/models';
import {
  HIRLProjectRequestParams,
  HIRLProjectService,
} from '@/data/customer-hirl/services/hirl-projects.service';
import { HIRLProjectRegistrationService } from '@/data/customer-hirl/services/hirl-project-registration.service';
import {
  getHIRLProjectRegistration,
  getHIRLProjectRegistrationProjects,
  getHIRLProjectRegistrationProjectsCount,
} from '@/state/selectors/customer-hirl/hirl-project-registration.selector';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { HIRLProjectPermissionService } from '@/modules/customer-hirl/services/hirl-project-permission-service';
import {
  QaStatusRequestParams,
  QaStatusService,
} from '@/data/qa/services/qa-status.service';
import { EEPProgramRequestParams } from '@/data/eep-program/services/eep-program.service';
import { EEPProgramHomeStatusState } from '@/data/home/models/eep_program_home_status';
import { EEPProgramHomeStatusService } from '@/data/home/services/eep_program_home_status.service';

@Component({
  selector: 'app-hirl-project-list-tab',
  templateUrl: './hirl-project-list-tab.component.html',
  styleUrls: ['./hirl-project-list-tab.component.scss'],
})
export class HirlProjectListTabComponent implements OnInit, OnDestroy {
  @ViewChild('tableTopScroll') tableTopScroll: ElementRef;

  public isLoading = false;
  public hirlProjectRegistration: HIRLProjectRegistration;
  public projects: HIRLProject[] = [];
  public projectsCount: number;
  public permissions: {
    [key: string]: {
      canEdit: Observable<ObjectPermissionResponse>;
      canDelete: Observable<ObjectPermissionResponse>;
    };
  } = {};

  public qaCount = 0;
  public certifiedProjectsCount = 0;

  public storedParams: HIRLProjectRequestParams = new HIRLProjectRequestParams(
    1,
    '',
    '-created_at',
    5
  );

  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private activatedRoute: ActivatedRoute,
    private hirlProjectService: HIRLProjectService,
    private hirlProjectRegistrationService: HIRLProjectRegistrationService,
    private hirlProjectPermissionService: HIRLProjectPermissionService,
    private qaStatusService: QaStatusService,
    private eepProgramHomeStatusService: EEPProgramHomeStatusService,
    private uiHelper: UIHelperService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.store
      .select(getHIRLProjectRegistration)
      .pipe(takeUntil(this.componentDestroyed$))
      .pipe(
        filter(val => val !== null),
        mergeMap(registration => {
          return forkJoin({
            qaCount: this.qaStatusService
              .list(
                Object.assign(new QaStatusRequestParams(), {
                  home_status__customer_hirl_project__registration:
                    registration.id,
                })
              )
              .pipe(
                first(),
                map(response => response.count)
              ),
            certifiedProjectsCount: this.eepProgramHomeStatusService
              .list(
                Object.assign(new EEPProgramRequestParams(), {
                  customer_hirl_project__registration: registration.id,
                  state: EEPProgramHomeStatusState.complete,
                })
              )
              .pipe(
                first(),
                map(response => response.count)
              ),
          }).pipe(
            map(({ qaCount, certifiedProjectsCount }) => {
              return { registration, qaCount, certifiedProjectsCount };
            })
          );
        })
      )
      .subscribe(({ registration, qaCount, certifiedProjectsCount }) => {
        this.hirlProjectRegistration = registration;
        this.qaCount = qaCount;
        this.certifiedProjectsCount = certifiedProjectsCount;

        this.hirlProjectRegistration = registration;
        // change default order for LD projects
        if (
          this.hirlProjectRegistration.project_type ===
          HIRLProjectRegistrationType.landDevelopment
        ) {
          this.storedParams.ordering = 'land_development_phase_number';
        }
        this.list(false);
      });

    this.store
      .select(getHIRLProjectRegistrationProjects)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(projects => {
        this.projects = projects;
      });

    this.store
      .select(getHIRLProjectRegistrationProjectsCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(count => {
        this.projectsCount = count;
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  list(scrollInto = true) {
    this.isLoading = true;
    if (scrollInto) {
      this.tableTopScroll?.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
    this.hirlProjectRegistrationService
      .hirl_projects(this.hirlProjectRegistration.id, this.storedParams)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(_ => {
        this.isLoading = false;
        this.projects.forEach(row => {
          this.permissions[row.id] = {
            canEdit: this.hirlProjectPermissionService.canEdit(
              row,
              this.hirlProjectRegistration,
              this.qaCount,
              this.certifiedProjectsCount
            ),
            canDelete: this.hirlProjectPermissionService.canDelete(
              row,
              this.hirlProjectRegistration
            ),
          };
        });
      });
  }

  onSort({ sorts }) {
    this.storedParams.ordering = `${sorts[0].dir === 'desc' ? '-' : ''}${
      sorts[0].prop
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  onChangePage(event) {
    this.storedParams.page = event.page;
    this.list();
  }
}
