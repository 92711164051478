import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BaseDataService } from '@/core/services/base-data-service';
import { Observable } from 'rxjs';
import { WaterHeaterBackendDict } from '../models/mechanicals/WaterHeater';
import { ListRequestParams } from '@/core/schemes/request-params';
import { ServerResponse } from '@/core/schemes/server-response';

@Injectable({ providedIn: 'root' })
export class WaterHeaterService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/water_heater/`;

  list(
    params: ListRequestParams
  ): Observable<ServerResponse<WaterHeaterBackendDict>> {
    return this.http.get<ServerResponse<WaterHeaterBackendDict>>(
      `${this.baseUrl}`,
      {
        params: this.rollParams(params),
      }
    );
  }

  update(
    waterHeater: Partial<WaterHeaterBackendDict>
  ): Observable<WaterHeaterBackendDict> {
    return this.http.patch<WaterHeaterBackendDict>(
      `${this.baseUrl}${waterHeater.id}/`,
      waterHeater
    );
  }
}
