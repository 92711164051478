import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@/shared/shared.module';
import { AddressToGeocodeResponseDialogComponent } from '@/modules/geocoder/components/address-to-geocode-response-dialog/address-to-geocode-response-dialog.component';
import { ConfirmGeocodeResponseDialogComponent } from '@/modules/geocoder/components/confirm-geocode-response-dialog/confirm-geocode-response-dialog.component';
import { HomeAddressToGeocodeResponseDialogComponent } from './components/home-address-to-geocode-response-dialog/home-address-to-geocode-response-dialog.component';
import { AddressTypeComponent } from './components/address-type/address-type.component';

@NgModule({
  declarations: [
    AddressToGeocodeResponseDialogComponent,
    ConfirmGeocodeResponseDialogComponent,
    HomeAddressToGeocodeResponseDialogComponent,
    AddressTypeComponent,
  ],
  imports: [CommonModule, SharedModule],
  exports: [AddressTypeComponent],
})
export class GeocoderModule {}
