import {
  createConditionalValidator,
  ModelFormControl,
  ModelFormGroup,
} from '../ModelForm';
import { Validators } from '@angular/forms';
import { FieldLabels } from '../base';
import {
  FuelType,
  HeatingCoolingCapacityUnit,
  HeatingEfficiencyUnit,
  HeatingEfficiencyUnitLabels,
  MechanicalMotorType,
} from '@/data/simulation/enumerations';
import {
  belowWarningRangeValidator,
  aboveWarningRangeValidator,
  enumValidator,
} from '@/modules/simulation/validators/helper';
import { validateAutoSizeCapacity } from './AirSourceHeatPump';

// Labels
export const FIELD_LABELS: FieldLabels<HeaterBackendDict> = {
  name: 'Name',
  system_type: 'System Type',
  fuel: 'Fuel',
  auto_size_capacity: {
    label: 'Auto Size Capacity',
    description:
      'Auto sizing can be used to autosize the equipment for research purposes or to run tests.',
  },
  capacity: 'Capacity',
  capacity_unit: 'Capacity Unit',
  efficiency: 'Efficiency',
  efficiency_unit: 'Efficiency Unit',
  motor_type: 'Motor Type',
  auxiliary_electric_defaults: 'Auxiliary Electric Defaults',
  auxiliary_electric_capacity: 'Auxiliary Electric Capacity',
  auxiliary_electric_unit: 'Auxiliary Electric Unit',
  fan_power: 'Fan Power',
  air_flow_defect_ratio: 'Air Flow defect ratio',
  ahri_reference_number: 'AHRI Reference Number',
};

export enum HeatingSystemType {
  FURNACE = 'furnace',
  WALL_FURNACE = 'wall_furnace',
  FLOOR_FURNACE = 'floor_furnace',
  BOILER = 'boiler',
  STOVE = 'stove',
  SPACE_HEATING = 'space_heating',
  ELECTRIC_RESISTANCE = 'resistance',
  FIREPLACE = 'fireplace',
}

export const HeatingSystemTypeLabels: Record<HeatingSystemType, string> = {
  [HeatingSystemType.FURNACE]: 'Furnace',
  [HeatingSystemType.BOILER]: 'Boiler',
  [HeatingSystemType.ELECTRIC_RESISTANCE]: 'Electric Resistance',
  [HeatingSystemType.WALL_FURNACE]: 'Wall Furnace',
  [HeatingSystemType.FLOOR_FURNACE]: 'Floor Furnace',
  [HeatingSystemType.SPACE_HEATING]: 'Space Heating',
  [HeatingSystemType.FIREPLACE]: 'Fireplace',
  [HeatingSystemType.STOVE]: 'Stove',
};

export enum AuxEnergyUnit {
  KWH_YEAR = 'kwh_yr',
  EAE = 'eae',
  WATT = 'watt',
}

export const AuxEnergyUnitLabels: Record<AuxEnergyUnit, string> = {
  [AuxEnergyUnit.KWH_YEAR]: 'kWh/yr',
  [AuxEnergyUnit.EAE]: 'Eae',
  [AuxEnergyUnit.WATT]: 'Watt',
};

export interface HeaterBackendDict {
  id: number;
  name: string;
  system_type: HeatingSystemType;
  fuel: FuelType;
  auto_size_capacity: boolean;
  capacity: number;
  capacity_unit: HeatingCoolingCapacityUnit;
  efficiency: number;
  efficiency_unit: HeatingEfficiencyUnit;
  motor_type: MechanicalMotorType;
  auxiliary_electric_defaults: boolean;
  auxiliary_electric_capacity: AuxEnergyUnit;
  auxiliary_electric_unit: string;
  fan_power: number;
  air_flow_defect_ratio: number;
  ahri_reference_number: string;
}

export function createHeaterForm(data: HeaterBackendDict): ModelFormGroup {
  const validateOnlyIfAutoSizeDisabled = createConditionalValidator(
    parentControls => !parentControls.auto_size_capacity.value,
    ['auto_size_capacity']
  );

  return new ModelFormGroup(
    {
      id: new ModelFormControl(data.id),
      name: new ModelFormControl(data.name, [
        Validators.required,
        Validators.maxLength(128),
      ]),
      system_type: new ModelFormControl(
        data.system_type,
        enumValidator(HeatingSystemType)
      ),
      fuel: new ModelFormControl(data.fuel, enumValidator(FuelType)),
      auto_size_capacity: new ModelFormControl(data.auto_size_capacity),
      capacity: new ModelFormControl(
        data.capacity,
        ...validateOnlyIfAutoSizeDisabled([
          Validators.required,
          Validators.min(0),
          Validators.max(1000),
          aboveWarningRangeValidator(995),
          belowWarningRangeValidator(10),
        ])
      ),
      capacity_unit: new ModelFormControl(
        data.capacity_unit,
        ...validateOnlyIfAutoSizeDisabled([
          enumValidator(HeatingCoolingCapacityUnit),
        ])
      ),
      efficiency: new ModelFormControl(data.efficiency, [
        Validators.required,
        Validators.min(0),
        Validators.max(100),
      ]),
      efficiency_unit: new ModelFormControl(
        data.efficiency_unit,
        enumValidator(HeatingEfficiencyUnit)
      ),
      motor_type: new ModelFormControl(
        data.motor_type,
        enumValidator(MechanicalMotorType, true)
      ),
      auxiliary_electric_defaults: new ModelFormControl(
        data.auxiliary_electric_defaults
      ),
      auxiliary_electric_capacity: new ModelFormControl(
        data.auxiliary_electric_capacity,
        [Validators.nullValidator, Validators.min(0)]
      ),
      auxiliary_electric_unit: new ModelFormControl(
        data.auxiliary_electric_unit,
        enumValidator(AuxEnergyUnit)
      ),
      fan_power: new ModelFormControl(data.fan_power, [
        Validators.nullValidator,
        Validators.min(0),
      ]),
      air_flow_defect_ratio: new ModelFormControl(data.air_flow_defect_ratio, [
        Validators.nullValidator,
        Validators.min(-0.9),
        Validators.max(9.0),
      ]),
      ahri_reference_number: new ModelFormControl(data.ahri_reference_number),
    },
    {
      validators: [validateEfficiency, validateAutoSizeCapacity],
    }
  );
}

function validateEfficiency(controls: ModelFormGroup) {
  const efficiencyUnit = controls.get('efficiency_unit').value;
  const efficiency = controls.get('efficiency').value;

  // Threshold similar to the backend logic
  const EFFICIENCY_RANGES = {
    [HeatingEfficiencyUnit.AFUE]: [50, 70],
    [HeatingEfficiencyUnit.PCT_EFF]: [0.5, 0.7],
    [HeatingEfficiencyUnit.COP]: [0.7, 1.0],
  };

  const threshold = EFFICIENCY_RANGES[efficiencyUnit];
  if (!threshold) {
    return {
      invalidEfficiencyUnit: {
        efficiencyUnit:
          HeatingEfficiencyUnitLabels[efficiencyUnit] || efficiencyUnit,
      },
    };
  }

  // Error if efficiency is below the range
  const [errorIfBelow, warnIfBelow] = threshold;
  if (efficiency < errorIfBelow) {
    return {
      efficiencyBelowRange: {
        efficiency: efficiency,
        efficiencyUnit: efficiencyUnit,
        range: errorIfBelow,
      },
    };
  }
  // Warn if efficiency is below the range
  if (efficiency < warnIfBelow) {
    return {
      efficiencyLowWarning: {
        efficiency: efficiency,
        efficiencyUnit: efficiencyUnit,
      },
    };
  }
}
