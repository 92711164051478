import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, Subject, Subscription } from 'rxjs';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { MatDialog } from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import * as _ from 'lodash';
import { filter, takeUntil } from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Invoice, InvoiceItemGroup } from '@/data/invoicing/models';
import {
  InvoiceItemGroupRequestParams,
  InvoiceItemGroupService,
} from '@/data/invoicing/services/invoice-item-group.service';
import { InvoiceService } from '@/data/invoicing/services/invoice.service';
import {
  getInvoice,
  getInvoiceInvoiceItemGroups,
  getInvoiceInvoiceItemGroupsCount,
} from '@/state/selectors/invoicing/invoice.selector';
import { InvoiceType } from '@/data/invoicing/models/invoice';

@Component({
  selector: 'app-hirl-invoice-invoice-item-group-tab',
  templateUrl: './hirl-invoice-invoice-item-group-tab.component.html',
  styleUrls: ['./hirl-invoice-invoice-item-group-tab.component.scss'],
})
export class HirlInvoiceInvoiceItemGroupTabComponent
  implements OnInit, OnDestroy
{
  public invoice: Invoice;
  public displayedColumns = [];
  public filterFromGroup: FormGroup;
  public isLoading = false;
  public rows: InvoiceItemGroup[] = [];

  public permissions: {
    [key: string]: {
      canEdit: Observable<ObjectPermissionResponse>;
      canDelete: Observable<ObjectPermissionResponse>;
    };
  } = {};
  public rowsCount: number;

  public defaultParams: InvoiceItemGroupRequestParams =
    new InvoiceItemGroupRequestParams(1, '', 'id');

  public storedParams: InvoiceItemGroupRequestParams = Object.assign(
    new InvoiceItemGroupRequestParams(),
    this.defaultParams
  );

  private listSubscription$: Subscription;
  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private injector: Injector,
    private dialog: MatDialog,
    private invoiceService: InvoiceService,
    private invoiceItemGroupService: InvoiceItemGroupService,
    private fb: FormBuilder,
    private uiHelperService: UIHelperService
  ) {}

  ngOnInit(): void {
    this.storedParams = _.cloneDeep(this.defaultParams);

    this.store
      .select(getInvoice)
      .pipe(
        filter(val => val !== null),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((entity: Invoice) => {
        this.invoice = entity;

        if (this.invoice.invoice_type === InvoiceType.hirlProject) {
          this.displayedColumns = [
            'project_id',
            'address',
            'eep_program',
            'h_number',
            'customer_hirl_project_type',
            'total',
            'created_at',
          ];
        } else if (this.invoice.invoice_type === InvoiceType.hirlGCP) {
          this.displayedColumns = [
            'hirl_gcp_name',
            'hirl_gcp_description',
            'hirl_gcp_total',
            'created_at',
          ];
        } else if (this.invoice.invoice_type === InvoiceType.hirlGCPTopShelf) {
          this.displayedColumns = [
            'hirl_gcp_name',
            'hirl_gcp_description',
            'hirl_gcp_total',
            'created_at',
          ];
        } else {
          this.displayedColumns = [
            'hirl_gcp_name',
            'hirl_gcp_total',
            'created_at',
          ];
        }
        this.list();
      });

    this.store
      .select(getInvoiceInvoiceItemGroups)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((invoiceItemGroups: InvoiceItemGroup[]) => {
        this.rows = invoiceItemGroups;
      });

    this.store
      .select(getInvoiceInvoiceItemGroupsCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(invoiceItemGroupsCount => {
        this.rowsCount = invoiceItemGroupsCount;
      });

    this.setupFilterForm();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  setupFilterForm() {
    this.filterFromGroup = this.fb.group({
      search: [null],
    });

    this.filterFromGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        const params = _.merge({}, this.defaultParams, values);
        delete params.ordering;

        this.storedParams = _.merge(this.storedParams, params);
        this.list();
      });
  }

  list() {
    this.isLoading = true;

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }

    this.listSubscription$ = this.invoiceService
      .invoice_item_groups(this.invoice.id, this.storedParams)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.rows = data.results;
        this.rowsCount = data.count;
        this.isLoading = false;
      });
  }

  onPaginateChange($event: PageEvent) {
    this.uiHelperService.getUISettings().rowsPerPage = $event.pageSize;
    this.storedParams.page_size = $event.pageSize;
    this.storedParams.page = $event.pageIndex + 1;
    this.list();
  }

  onSortChange($event: Sort) {
    this.storedParams.ordering = `${$event.direction === 'desc' ? '-' : ''}${
      $event.active
    }`;
    this.storedParams.page = 1;
    this.list();
  }
}
