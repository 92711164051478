<div class="row">
  <div class="col-3">
    <div class="row">
      <div class="col-12">
        <h4>
            <span *ngIf="!isLoading">{{ rowsCount | number }} Subdivisions</span>
            <fa-icon
              [icon]="['fas', 'spinner']"
              [spin]="true"
              *ngIf="isLoading"
            ></fa-icon>
        </h4>
      </div>
    </div>
        
    <div class="row">
      <div class="col-12">
        <form [formGroup]="filterFromGroup" class="card">
          <h5 class="card-header">Filters</h5>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Search</mat-label>
                  <app-search-input formControlName="search" placeholder="Type to search something"></app-search-input>
                </mat-form-field>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="col-9">
    <div class="row mb-2">
      <div class="col-12 text-right">
        <button mat-raised-button color="accent" (click)="onAddNew($event)">Add New</button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="row" *ngIf="isLoading">
          <div class="col-12">
            <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
          </div>
        </div>
        <table mat-table
               [dataSource]="rows"
               class="table"
               matSort
               [matSortActive]="storedParams.toTableSort().active"
               [matSortDirection]="storedParams.toTableSort().direction"
               (matSortChange)="onSortChange($event)">

          <ng-container matColumnDef="subdivision">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Subdivision</th>
            <td mat-cell *matCellDef="let row">{{ row.name }}</td>
          </ng-container>

          <ng-container matColumnDef="builder">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Builder</th>
            <td mat-cell *matCellDef="let row">{{ row.builder_org_info.name }}</td>
          </ng-container>

          <ng-container matColumnDef="crossroads">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Crossroads</th>
            <td mat-cell *matCellDef="let row">{{ row.cross_roads }}</td>
          </ng-container>

          <ng-container matColumnDef="coordinates">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Coordinates</th>
            <td mat-cell *matCellDef="let row"></td>
          </ng-container>

          <ng-container matColumnDef="number_of_homes">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Number of homes</th>
            <td mat-cell *matCellDef="let row"></td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [length]="rowsCount"
                       [pageIndex]="$any(storedParams.page) - 1"
                       [pageSize]="storedParams.page_size"
                       (page)="onPaginateChange($event)"
                       aria-label="Select page">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>

