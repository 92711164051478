import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export class HistoryDialogConfig {
  objectId: string | number;
  modelClassName: string;

  constructor({
    objectId,
    modelClassName,
  }: {
    objectId?: string | number;
    modelClassName?: string;
  }) {
    this.objectId = objectId;
    this.modelClassName = modelClassName;
  }
}

@Component({
  selector: 'app-history-dialog',
  templateUrl: './history-dialog.component.html',
  styleUrls: ['./history-dialog.component.scss'],
})
export class HistoryDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<HistoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public config: HistoryDialogConfig
  ) {}

  ngOnInit(): void {}
}
