import {
  Component,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { User } from '@/data/core/models/user';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { objectValidator } from '@/core/validators';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { EMPTY, forkJoin, Subject, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientAgreementService } from '@/data/customer-hirl/services/client-agreement.service';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '@/data/core/services/user.service';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import {
  CompanyRequestParams,
  CompanyService,
} from '@/data/company/services/company-base.service';
import { Company, CopyResourcesToOtherCompany } from '@/data/company/models';
import { getInfoUser } from '@/state/selectors/info.selector';
import { toggleLoading } from '@/state/actions/app.actions';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ClientAgreementCompanyTypeDataMap } from '@/modules/customer-hirl/constants';
import { CopiedResourcesToOtherCompanyResponse } from '@/data/company/models/company';

@Component({
  selector: 'app-hirl-copy-ca',
  templateUrl: './hirl-copy-ca.component.html',
  styleUrls: ['./hirl-copy-ca.component.scss'],
})
export class HirlCopyCaComponent implements OnInit, OnDestroy {
  @ViewChild('stepper') stepper;
  @ViewChild('companyFromTrigger', { static: false })
  companyFromAutocompleteTrigger: MatAutocompleteTrigger;

  public initialized = false;
  public currentUser: User;
  public companyFromFormGroup: UntypedFormGroup;
  public companyToFormGroup: UntypedFormGroup;
  public optionsFormGroup: UntypedFormGroup;

  public companyFromIsLoading = true;
  public componentCompanyTypeDataMap = ClientAgreementCompanyTypeDataMap;

  public filteredCompaniesFrom: Company[];
  public filteredCompaniesTo: Company[][] = [];

  public companyFromValueChanges: Subscription;
  public companyToValueChanges: Subscription[] = [];
  public companyToIsLoading: boolean[] = [];
  public copiedResourcesToOtherCompanyResponse: CopiedResourcesToOtherCompanyResponse;

  private componentDestroyed$ = new Subject();

  constructor(
    public fb: UntypedFormBuilder,
    public store: Store<AppState>,
    public injector: Injector,
    public router: Router,
    public clientAgreementService: ClientAgreementService,
    public dialog: MatDialog,
    public activatedRoute: ActivatedRoute,
    public companyService: CompanyService,
    public userService: UserService,
    public uiHelperService: UIHelperService
  ) {}

  ngOnInit(): void {
    const sources: { [k: string]: any } = {
      currentUser: this.store.select(getInfoUser).pipe(first()),
    };

    forkJoin(sources).subscribe(({ currentUser }) => {
      this.currentUser = currentUser;

      this.store.dispatch(toggleLoading({ payload: false }));
      this.initialized = true;
    });

    this.setupCompanyFromFormGroupGroup();
    this.setupCompanyToFormGroup();
    this.setupOptionsFormGroupGroup();

    this.addCompanyTo();
  }

  public ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  public setupCompanyFromFormGroupGroup() {
    this.companyFromFormGroup = this.fb.group({
      company_type_from: [null, [Validators.required]],
      company_from: ['', [Validators.required, objectValidator()]],
    });

    // from autocomplete
    const companyFromPipe = this.companyFromFormGroup
      .get('company_from')
      .valueChanges.pipe(
        takeUntil(this.componentDestroyed$),
        startWith(''),
        filter(_ => this.companyFromFormGroup.get('company_type_from').value),
        debounceTime(100),
        distinctUntilChanged(),
        tap(_ => (this.companyFromIsLoading = true)),
        switchMap(value =>
          this.companyService
            .list(
              Object.assign(new CompanyRequestParams(), {
                search: value,
                page_size: 300,
                company_type:
                  this.companyFromFormGroup.get('company_type_from').value,
              })
            )
            .pipe(catchError(err => EMPTY))
        ),
        map(response => (this.filteredCompaniesFrom = response.results)),
        tap(_ => (this.companyFromIsLoading = false))
      );

    this.companyFromFormGroup
      .get('company_type_from')
      .valueChanges.pipe(
        takeUntil(this.componentDestroyed$),
        distinctUntilChanged(),
        tap(_ => this.companyFromValueChanges?.unsubscribe()),
        tap(_ => (this.filteredCompaniesFrom = [])),
        tap(_ =>
          this.companyFromFormGroup
            .get('company_from')
            .patchValue('', { onlySelf: true, emitEvent: true })
        ),
        tap(_ => (this.companyFromValueChanges = companyFromPipe.subscribe()))
      )
      .subscribe();
  }

  public setupCompanyToFormGroup() {
    this.companyToFormGroup = this.fb.group({
      companies_to: this.fb.array([]),
    });
  }

  public setupOptionsFormGroupGroup() {
    this.optionsFormGroup = this.fb.group({
      copy_client_agreement: [false],
      copy_coi: [false],
      move_client_agreement: [false],
      move_coi: [false],
    });
  }

  public addCompanyTo(): void {
    const control = this.companyToFormGroup.controls
      .companies_to as UntypedFormArray;
    const index = control.length;
    this.filteredCompaniesTo.push([]);
    this.companyToValueChanges.push(null);
    this.companyToIsLoading.push(true);

    const group = this.fb.group({
      company_type_to: [null, [Validators.required]],
      company_to: ['', [Validators.required, objectValidator()]],
    });

    // from autocomplete
    const companyFromPipe = group.get('company_to').valueChanges.pipe(
      takeUntil(this.componentDestroyed$),
      startWith(''),
      filter(_ => group.get('company_type_to').value),
      debounceTime(100),
      distinctUntilChanged(),
      tap(_ => (this.companyToIsLoading[index] = true)),
      switchMap(value =>
        this.companyService
          .list(
            Object.assign(new CompanyRequestParams(), {
              search: value,
              page_size: 300,
              company_type: group.get('company_type_to').value,
            })
          )
          .pipe(catchError(err => EMPTY))
      ),
      map(response => (this.filteredCompaniesTo[index] = response.results)),
      tap(_ => (this.companyToIsLoading[index] = false))
    );

    group
      .get('company_type_to')
      .valueChanges.pipe(
        takeUntil(this.componentDestroyed$),
        distinctUntilChanged(),
        tap(_ => this.companyToValueChanges[index]?.unsubscribe()),
        tap(_ => (this.filteredCompaniesTo[index] = [])),
        tap(_ =>
          group
            .get('company_to')
            .patchValue('', { onlySelf: true, emitEvent: true })
        ),
        tap(
          _ => (this.companyToValueChanges[index] = companyFromPipe.subscribe())
        )
      )
      .subscribe();

    control.push(group);
  }

  public handleRemoveCompanyTo(event, index: number): void {
    event.preventDefault();
    const control = this.companyToFormGroup.controls
      .companies_to as UntypedFormArray;
    control.removeAt(index);
  }

  getCompanyToControls() {
    return (this.companyToFormGroup.get('companies_to') as UntypedFormArray)
      .controls;
  }

  displayCompanyMenu(company) {
    if (company) {
      return `${company.name}`;
    }
  }

  onClearFromCompany(
    $event: MouseEvent,
    companyFromTrigger: MatAutocompleteTrigger
  ) {
    $event.stopPropagation();
    this.companyFromFormGroup.get('company_from').patchValue(null);
    companyFromTrigger.openPanel();
  }

  onClearToCompany(
    $event: MouseEvent,
    companyToTrigger: MatAutocompleteTrigger
  ) {
    $event.stopPropagation();
    // this.formGroup.get('builder_organization_to').patchValue(null);
    companyToTrigger.openPanel();
  }

  onCopyAction($event: MouseEvent) {
    $event.stopPropagation();
    this.companyFromFormGroup.markAllAsTouched();
    this.companyToFormGroup.markAllAsTouched();
    this.optionsFormGroup.markAllAsTouched();

    if (
      this.companyFromFormGroup.invalid ||
      this.companyToFormGroup.invalid ||
      this.optionsFormGroup.invalid
    ) {
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const formData = new CopyResourcesToOtherCompany();
    formData.company_from = this.companyFromFormGroup.value.company_from.id;
    formData.companies_to = [];
    for (const companyToData of this.companyToFormGroup.controls.companies_to
      .value) {
      formData.companies_to.push(companyToData.company_to.id);
    }
    formData.copy_client_agreement =
      this.optionsFormGroup.value.copy_client_agreement;
    formData.copy_coi = this.optionsFormGroup.value.copy_coi;
    formData.move_client_agreement =
      this.optionsFormGroup.value.move_client_agreement;
    formData.move_coi = this.optionsFormGroup.value.move_coi;

    this.companyService
      .copy_resources_to_other_company(formData)
      .pipe(first())
      .subscribe(
        copiedResourcesToOtherCompanyResponse => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.stepper.selectedIndex = this.stepper.selectedIndex + 1;
          this.copiedResourcesToOtherCompanyResponse =
            copiedResourcesToOtherCompanyResponse;
        },
        error => this.uiHelperService.handleUserRequestError(error)
      );
  }

  reset() {
    this.copiedResourcesToOtherCompanyResponse = null;
    this.stepper.reset();
  }
}
