import { createReducer, on } from '@ngrx/store';
import * as SeasonalRateActions from './actions';
import { SeasonalRateBackendDict } from '@/data/simulation/models/utility/SeasonalRate';
import { BaseState, sharedInitialState } from '../shared/base.state';
import { addErrorCount, addStateErrorCount } from '../helper';

export type SeasonalRateState = BaseState<SeasonalRateBackendDict>;

export const initialState: SeasonalRateState = sharedInitialState;

export const reducer = createReducer(
  initialState,
  on(SeasonalRateActions.loadSeasonalRatesSuccess, (currentState, payload) => {
    const entities = payload.seasonalRates.reduce(
      (acc, seasonalrate) => ({ ...acc, [seasonalrate.id]: seasonalrate }),
      {}
    );

    const loadingStatus = payload.seasonalRates.reduce(
      (acc, seasonalrate) => ({ ...acc, [seasonalrate.id]: false }),
      {}
    );

    return {
      entities: { ...currentState.entities, ...entities },
      loading: { ...currentState.loading, ...loadingStatus },
      errors: { ...currentState.errors, ...addStateErrorCount(payload.errors) },
    };
  }),

  on(SeasonalRateActions.removeSeasonalRateSuccess, (currentState, { id }) => {
    const { [id]: removed, ...entities } = currentState.entities;
    const { [id]: removedLoading, ...loading } = currentState.loading;
    const { [id]: removedErrors, ...errors } = currentState.errors;

    return {
      entities,
      loading,
      errors,
    };
  }),

  on(
    SeasonalRateActions.updateSeasonalRate,
    (state, { seasonalRateChanges }) => {
      return {
        ...state,
        loading: {
          ...state.loading,
          [seasonalRateChanges.id]: true,
        },
      };
    }
  ),

  on(SeasonalRateActions.removeSeasonalRate, (state, { seasonalRate }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [seasonalRate.id]: true,
      },
    };
  }),

  on(
    SeasonalRateActions.updateSeasonalRateFailure,
    SeasonalRateActions.removeSeasonalRateFailure,
    (state, { id }) => {
      return {
        ...state,
        loading: {
          ...state.loading,
          [id]: false,
        },
      };
    }
  ),

  on(SeasonalRateActions.updateSeasonalRateErrors, (state, { id, errors }) => {
    return {
      ...state,
      errors: {
        ...state.errors,
        [id]: errors,
      },
    };
  }),

  on(
    SeasonalRateActions.updateSeasonalRateSuccess,
    (state, { seasonalRate, errors }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          [seasonalRate.id]: seasonalRate,
        },
        loading: {
          ...state.loading,
          [seasonalRate.id]: false,
        },
        errors: {
          ...state.errors,
          [seasonalRate.id]: addErrorCount(errors),
        },
      };
    }
  ),

  on(SeasonalRateActions.removeBlockRateSuccess, (state, { id }) => {
    return state;
  })
);
