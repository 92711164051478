<form class="app-form input-parent" [formGroup]="formGroup">
    <button *appRequiresEditPermission (click)="enableEditMode($event)" [ngStyle]="{ width: isInEditMode ? '0' : '100%' }" tabindex="0" class="wrapper-container">
        <i class="material-icons md-24" *ngIf="!isInEditMode">edit</i>
    </button>
    <div class="form-fields">
        <ng-content></ng-content>
    </div>
</form>
<div class="form-buttons-container" *ngIf="dedicatedSaveButton">
    <ng-container *ngIf="isInEditMode">
        <button type="button" class="cancel" (click)="cancelChanges($event)">
            Cancel
        </button>
        <button type="button" (click)="saveChanges($event)">Save</button>
    </ng-container>
</div>
