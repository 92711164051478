import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BaseDataService } from '@/core/services/base-data-service';
import { Observable } from 'rxjs';
import { DoorBackendDict } from '../models/enclosure/Door';

@Injectable({ providedIn: 'root' })
export class DoorService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/door/`;

  update(
    doorTypeChanges: Partial<DoorBackendDict>
  ): Observable<DoorBackendDict> {
    return this.http.patch<DoorBackendDict>(
      `${this.baseUrl}${doorTypeChanges.id}/`,
      doorTypeChanges
    );
  }

  delete(id: number) {
    return this.http.delete(`${this.baseUrl}${id}/`);
  }
}
