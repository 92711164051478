import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from '@/data/core/models/user';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { forkJoin, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { Router } from '@angular/router';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { toggleLoading } from '@/state/actions/app.actions';
import {
  defaultIfEmpty,
  first,
  map,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { getInfoUser } from '@/state/selectors/info.selector';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { ApexRequestCreate, ApexRequestType } from '@/data/home/models';
import { ApexRequestService } from '@/data/home/services/apex_request.service';
import { CustomerDocumentService } from '@/data/filehandling/services/customer-document.service';
import { CustomerDocument } from '@/data/filehandling/models';
import { forEach } from 'lodash';
import { CompanyService } from '@/data/company/services/company-base.service';
import { AnalysisWeatherType, UDRHModel } from '@/data/simulation/models';
import {
  ChangeUdrhDialogComponent,
  ChangeUDRHModelDialogData,
} from '@/modules/simulation/components/change-udrh-dialog/change-udrh-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-apex-request-utility-create',
  templateUrl: './apex-request-utility-create.component.html',
  styleUrls: ['./apex-request-utility-create.component.scss'],
})
export class ApexRequestUtilityCreateComponent implements OnInit, OnDestroy {
  public initialized = false;

  public files: File[] = [];
  public udrhFiles: File[] = [];
  public currentUser: User;
  public formGroup: FormGroup;

  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private dialog: MatDialog,
    private customerDocumentService: CustomerDocumentService,
    private companyService: CompanyService,
    private apexRequestService: ApexRequestService,
    private fb: UntypedFormBuilder,
    private uiHelperService: UIHelperService
  ) {}

  ngOnInit(): void {
    forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
    })
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(({ currentUser }) => {
        this.currentUser = currentUser;

        this.setupForm();
      });

    this.store.dispatch(toggleLoading({ payload: true }));
  }

  onViewReady() {
    this.initialized = true;
    this.store.dispatch(toggleLoading({ payload: false }));
  }

  public setupForm() {
    this.formGroup = this.fb.group({
      suites: [[], [Validators.required, Validators.minLength(1)]],
      weather_type: [AnalysisWeatherType.TMY3],
    });
  }

  onSubmit($event: MouseEvent) {
    $event.preventDefault();
    this.formGroup.markAllAsTouched();

    if (this.formGroup.invalid || !this.files.length) {
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const apexRequest: ApexRequestCreate = new ApexRequestCreate();

    apexRequest.simulation_config = {
      analysis_types: this.formGroup.value.suites.map(suite => {
        return {
          suite,
        };
      }),
      weather_type: this.formGroup.value.weather_type,
      qa_flags: [],
      company: this.currentUser.company,
    };

    apexRequest.request_type = ApexRequestType.simulate;

    const customerDocumentsChain$ = [];

    forEach(this.files, f => {
      const customerDocumentData = new CustomerDocument();

      const customerDocumentCreate$ = this.companyService
        .documentsCreate(this.currentUser.company, customerDocumentData)
        .pipe(
          first(),
          // update file fields
          switchMap(customerDocument => {
            const formData = new FormData();
            formData.append('document', f);
            return this.customerDocumentService.update(
              customerDocument.id,
              formData
            );
          })
        )
        .pipe(takeUntil(this.componentDestroyed$), first());

      customerDocumentsChain$.push(customerDocumentCreate$);
    });

    forkJoin(customerDocumentsChain$)
      .pipe(
        takeUntil(this.componentDestroyed$),
        defaultIfEmpty(null),
        map(customerDocuments =>
          customerDocuments.map(customerDocument => customerDocument.id)
        ),
        switchMap(customerDocumentsIds => {
          apexRequest.documents = customerDocumentsIds;

          return this.apexRequestService
            .create(apexRequest)
            .pipe(first(), takeUntil(this.componentDestroyed$));
        })
      )
      .subscribe({
        next: apexRequest => {
          this.store.dispatch(toggleLoading({ payload: false }));
          const _ = this.router.navigate([
            '/',
            'home',
            'apex',
            'request',
            'utility-detail',
            apexRequest.id,
          ]);
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  onSelect($event: NgxDropzoneChangeEvent) {
    this.files.push(...$event.addedFiles);
  }

  onRemove(f: File) {
    this.files.splice(this.files.indexOf(f), 1);
  }

  createUDRH($event: MouseEvent) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(ChangeUdrhDialogComponent, {
      width: '45%',
      disableClose: true,
      data: {} as ChangeUDRHModelDialogData,
    });

    dialogRef.afterClosed().subscribe((result?: UDRHModel) => {
      if (!result) {
        return;
      }

      this.formGroup.get('udrh_model').patchValue(result);
      this.uiHelperService.openSnackBar('Record Successfully Added');
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
