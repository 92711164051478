import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BaseDataService } from '@/core/services/base-data-service';
import { Observable } from 'rxjs';
import { RoofBackendDict } from '../models/enclosure/Roof';

@Injectable({ providedIn: 'root' })
export class RoofService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/roof/`;

  update(RoofType: Partial<RoofBackendDict>): Observable<RoofBackendDict> {
    return this.http.put<RoofBackendDict>(
      `${this.baseUrl}${RoofType.id}/`,
      RoofType
    );
  }

  delete(id: number) {
    return this.http.delete(`${this.baseUrl}${id}/`);
  }
}
