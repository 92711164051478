export class ContactCardPhone {
  id?: number;
  phone: string;
  description?: string;
}

export class ContactCardEmail {
  id?: number;
  email: string;
  description?: string;
}

export class ContactCard {
  id?: number;
  name?: string;
  title?: string;
  first_name?: string;
  last_name?: string;
  user?: number;
  company?: number;
  phones: ContactCardPhone[];
  emails: ContactCardEmail[];
  protected?: boolean;
}

export class ContactCardReportDownload {
  export_filters: { [key: string]: string };
}
