import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BaseDataService } from '@/core/services/base-data-service';
import { Observable } from 'rxjs';
import { DistributionSystemBackendDict } from '../models/mechanicals/DistributionSystem';

@Injectable({ providedIn: 'root' })
export class DistributionSystemService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/distribution_system/`;

  update(
    distributionSystemType: Partial<DistributionSystemBackendDict>
  ): Observable<DistributionSystemBackendDict> {
    return this.http.patch<DistributionSystemBackendDict>(
      `${this.baseUrl}${distributionSystemType.id}/`,
      distributionSystemType
    );
  }

  delete(id: number) {
    return this.http.delete(`${this.baseUrl}${id}`);
  }
}
