<div *ngIf="initialized; else loading;">
    <ng-container *ngTemplateOutlet="infiltrationView; context: {data: infiltration}"></ng-container>
</div>

<ng-template #infiltrationView let-infiltration="data">
    <div class="row mt-2">
        <ng-container>
            <div class="col-6 mt-2">
                <div class="card">
                    <div class="card-content">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item header"
                                style="background-color:rgba(0, 0, 0, 0.03); align-items: center;">
                                <b>
                                    {{ infiltration.measurement_type_display }} at
                                    {{ infiltration.infiltration_value | number: '1.2-2' }}
                                    {{ infiltration.infiltration_unit_display }}
                                </b>
                            </li>
                            <li class="list-group-item">
                                <b>Infiltration:</b>
                                {{ infiltration.infiltration_value | number: '1.2-2' }}
                                {{ infiltration.infiltration_unit_display }}
                            </li>
                            <li class="list-group-item">
                                <b>Verification Type:</b>
                                {{ infiltration.verification_type_display
                                ? infiltration.verification_type_display
                                : 'N/A'
                                }}
                            </li>
                            <li class="list-group-item">
                                <b>Tested Infiltration:</b>
                                {{ infiltration.tested_infiltration_value
                                ? infiltration.tested_infiltration_value
                                : 'N/A'
                                }}
                                {{ infiltration.tested_infiltration_unit_display }}
                            </li>
                            <li class="list-group-item">
                                <b>Envelope Tightness:</b>
                                {{ infiltration.envelope_tightness }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>

<ng-template #loading>
    <div class="row">
        <div class="col-12">
            <div class="d-flex justify-content-center" style="height: 320px;">
                <mat-spinner [diameter]="50"></mat-spinner>
            </div>
        </div>
    </div>
</ng-template>