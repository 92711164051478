import { Action, createReducer, on } from '@ngrx/store';
import { Simulation } from '@/data/simulation/models';
import {
  loadSimulation,
  resetSimulation,
} from '@/state/actions/floorplan/simulation.actions';

export const simulationStateKey = 'floorplan-simulation';

export interface SimulationState {
  detail: Simulation;
}

export const simulationInitialState: SimulationState = {
  detail: null,
};

const simulationReducer = createReducer(
  simulationInitialState,
  on(resetSimulation, (state, action) => ({ ...simulationInitialState })),
  on(loadSimulation, (state, { payload }) => ({ ...state, detail: payload }))
);

export function reducer(state: SimulationState | undefined, action: Action) {
  return simulationReducer(state, action);
}
