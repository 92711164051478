import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { loadImpersonations } from '@/state/actions/info.actions';
import { of, Observable } from 'rxjs';
import { debounceTime, first, skip, switchMap } from 'rxjs/operators';

import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

import {
  getInfo,
  getInfoUser,
  getImpersonations,
} from '@/state/selectors/info.selector';
import { User } from '@/data/core/models/user';
import { AuthenticationService } from '@/core/services/authentication.service';
import { ImpersonationUser } from '@/data/core/models/impersonation_user';
import { ServerResponse } from '@/core/schemes/server-response';
import { UserService } from '@/data/core/services/user.service';
import { DomService } from '@/core/services/dom.service';
import { Router } from '@angular/router';
import { MatAutocomplete } from '@angular/material/autocomplete';

@Component({
  selector: 'app-nav-impersonate',
  templateUrl: './impersonate.component.html',
  styleUrls: ['./impersonate.component.scss'],
})
export class NavImpersonateComponent implements OnInit {
  public isLoading = false;
  public isCollapsed = true;
  public txtQuery: string;
  @ViewChild('auto', { read: MatAutocomplete })
  matAutocomplete: MatAutocomplete;
  @ViewChild('impersonateDropdown', { read: ElementRef, static: false })
  impersonateDropdown: ElementRef;
  public filteredImpersonations$: Observable<ServerResponse<User[]>>;
  public authData$: Observable<ImpersonationUser>;
  public userData$: Observable<User>;
  public previousImpersonations$: Observable<User[]>;

  public impersonateForm: UntypedFormGroup;
  public outsideClickSubscription$;

  constructor(
    public store: Store<AppState>,
    public fb: UntypedFormBuilder,
    public router: Router,
    public userService: UserService,
    public domService: DomService,
    public authenticationService: AuthenticationService
  ) {
    this.authData$ = this.store.select(getInfo);
    this.userData$ = this.store.select(getInfoUser);
    this.previousImpersonations$ = this.store.select(getImpersonations);

    this.store.dispatch(
      loadImpersonations({
        payload: JSON.parse(localStorage.getItem('impersonationList')) || [],
      })
    );
  }

  ngOnInit() {
    this.prepareImpersonationForm();

    this.filteredImpersonations$ = this.impersonateForm
      .get('txtQuery')
      .valueChanges.pipe(
        debounceTime(300),
        switchMap(value =>
          !!value && typeof value === 'string'
            ? this.userService.impersonation_list(value)
            : of(value)
        )
      );
  }

  prepareImpersonationForm() {
    this.impersonateForm = this.fb.group({
      txtQuery: [''],
    });
  }

  displayImpersonationMenuFn(user: User) {
    if (user) {
      return user.first_name + ' ' + user.last_name;
    }
  }

  documentClickListener(target: any): void {
    if (!this.impersonateDropdown.nativeElement.contains(target)) {
      this.close();
    }
  }

  handleOpen() {
    if (this.isCollapsed) {
      this.open();
    } else {
      this.close();
    }
  }

  open() {
    this.outsideClickSubscription$?.unsubscribe();
    this.isCollapsed = false;
    this.outsideClickSubscription$ = this.domService.documentClickedTarget
      .pipe(skip(1))
      .subscribe(target => this.documentClickListener(target));
  }

  close() {
    this.isCollapsed = true;
    this.impersonateForm.get('txtQuery').patchValue('');
    this.outsideClickSubscription$?.unsubscribe();
  }

  public engageImpersonate() {
    const { id } = this.impersonateForm.get('txtQuery').value;

    this.fulfillImpersonate(id);
  }

  fulfillImpersonate(id: string) {
    this.userService
      .toggleImpersonate(true, id)
      .subscribe(res => this.impersonateForm.get('txtQuery').setValue(null));
  }

  public handlePreviousClick(id: string) {
    this.fulfillImpersonate(id);
  }

  public shouldDisable() {
    return (
      !this.impersonateForm.get('txtQuery').value ||
      typeof this.impersonateForm.get('txtQuery').value === 'string'
    );
  }

  public endImpersonate() {
    this.userService.toggleImpersonate(false).subscribe();
  }

  onLogout(event) {
    event.preventDefault();

    this.authenticationService
      .logout()
      .pipe(first())
      .subscribe(_ => {
        this.router.navigate(['/']);
      });
  }

  impersonateAutocompleteOpened() {
    this.outsideClickSubscription$?.unsubscribe();
  }

  impersonateAutocompleteClosed() {
    this.outsideClickSubscription$ = this.domService.documentClickedTarget
      .pipe(skip(1))
      .subscribe(target => this.documentClickListener(target));
  }
}
