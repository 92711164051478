import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainDashboardPageComponent } from './pages/main-dashboard-page/main-dashboard-page.component';
import { SharedModule } from '@/shared/shared.module';
import { CustomerHirlHomeStatusMetricTableComponent } from './components/customer-hirl-home-status-metric-table/customer-hirl-home-status-metric-table.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NewsColumnComponent } from './components/news-column/news-column.component';
import { CustomerHirlRegistrationMetricTabComponent } from './components/customer-hirl-registration-metric-tab/customer-hirl-registration-metric-tab.component';
import { CustomerHirlHomeStatusCertificationMetricChartByProjectsComponent } from './components/customer-hirl-home-status-certification-metric-chart-by-projects/customer-hirl-home-status-certification-metric-chart-by-projects.component';
import { CustomerHirlHomeStatusCertificationMetricChartByUnitsComponent } from './components/customer-hirl-home-status-certification-metric-chart-by-units/customer-hirl-home-status-certification-metric-chart-by-units.component';
import { CustomerHirlRegistrationsPerMonthMetricsChartByProjectsComponent } from './components/customer-hirl-registrations-per-month-metrics-chart-by-projects/customer-hirl-registrations-per-month-metrics-chart-by-projects.component';
import { CustomerHirlRegistrationsPerMonthMetricsChartByUnitsComponent } from './components/customer-hirl-registrations-per-month-metrics-chart-by-units/customer-hirl-registrations-per-month-metrics-chart-by-units.component';
import { CustomerHirlProjectCycleTimeMetricsChartComponent } from './components/customer-hirl-project-cycle-time-metrics-chart/customer-hirl-project-cycle-time-metrics-chart.component';

@NgModule({
  declarations: [
    MainDashboardPageComponent,
    CustomerHirlHomeStatusMetricTableComponent,
    NewsColumnComponent,
    CustomerHirlRegistrationMetricTabComponent,
    CustomerHirlHomeStatusCertificationMetricChartByProjectsComponent,
    CustomerHirlHomeStatusCertificationMetricChartByUnitsComponent,
    CustomerHirlRegistrationsPerMonthMetricsChartByProjectsComponent,
    CustomerHirlRegistrationsPerMonthMetricsChartByUnitsComponent,
    CustomerHirlProjectCycleTimeMetricsChartComponent,
  ],
  imports: [CommonModule, SharedModule, NgxChartsModule],
})
export class MiscModule {}
