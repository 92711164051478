import {
  EditorNavigationService,
  SubTab,
} from '@/modules/simulation/services/editor-navigation.service';
import { Component, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-systems-tab',
  templateUrl: './systems-tab.component.html',
  styleUrls: ['./systems-tab.component.scss'],
})
export class SystemsTabComponent {
  @Input() waterSystemId: any;
  @Input() naturalVentilationId: number;

  componentDestroyed$ = new Subject();
  activeSubTabLink: SubTab;
  subTabs = SubTab;

  constructor(public editorNavigationService: EditorNavigationService) {}

  ngOnInit() {
    this.editorNavigationService
      .getActiveSubTabLinkObservable()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(subTab => {
        this.activeSubTabLink = subTab;
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
