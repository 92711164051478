<div class="container-fluid main-page" *ngIf="initialized">
  <div class="row p-0">
    <app-top-nav-bar
      [name]="simulation.name"
      (menuClick)="sideNav.toggleDrawer()"
      class="w-100">
    </app-top-nav-bar>
    <div class="col-12 p-0">
      <app-side-nav-bar #sideNav>
        <div class="main-content">
          <ng-container [ngSwitch]="activeTabLink">
            <ng-container *ngSwitchCase="tabs.GENERAL_INFO">
              <app-general-info-tab></app-general-info-tab>
            </ng-container>
            <ng-container *ngSwitchCase="tabs.ENVELOPE">
              <app-envelope-tab></app-envelope-tab>
            </ng-container>
            <ng-container *ngSwitchCase="tabs.FENESTRATION">
              <app-fenestration-tab></app-fenestration-tab>
            </ng-container>
            <ng-container *ngSwitchCase="tabs.MECHANICALS">
              <app-mechanicals-tab></app-mechanicals-tab>
            </ng-container>
            <ng-container *ngSwitchCase="tabs.APPLIANCES">
              <app-appliances-tab></app-appliances-tab>
            </ng-container>
            <ng-container *ngSwitchCase="tabs.SYSTEMS">
              <app-systems-tab [waterSystemId]="simulation.water_system" [naturalVentilationId]="simulation.natural_ventilation"></app-systems-tab>
            </ng-container>
            <ng-container *ngSwitchCase="tabs.GENERATORS">
              <app-generators-tab></app-generators-tab>
            </ng-container>
            <ng-container *ngSwitchCase="tabs.REPORTS">
              <app-reports-tab [simulationId]="simulation.id"></app-reports-tab>
            </ng-container>
            <ng-container *ngSwitchCase="tabs.AXIS">
              <app-axis-tab></app-axis-tab>
            </ng-container>
          </ng-container>
        </div>
      </app-side-nav-bar>
    </div>
  </div>
</div>
