import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { EEPProgramHomeStatusRequestParams } from '@/data/home/services/eep_program_home_status.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationService } from '@/shared/services/navigation-service.service';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { User } from '@/data/core/models/user';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { first, takeUntil } from 'rxjs/operators';
import { getInfoUser } from '@/state/selectors/info.selector';
import {
  HIRLRPCUpdaterRequestParams,
  HIRLRPCUpdaterRequestService,
} from '@/data/rpc/services/hirl-rpc-updater-request.service';
import { HIRLRPCUpdaterRequest } from '@/data/rpc/models';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { HIRLRPCUpdaterRequestServicePermissionService } from '@/modules/rpc/services/hirl-rpc-updater-request-permission.service';

@Component({
  selector: 'app-hirl-rpc-updater-request-list',
  templateUrl: './hirl-rpc-updater-request-list.component.html',
  styleUrls: ['./hirl-rpc-updater-request-list.component.scss'],
})
export class HirlRpcUpdaterRequestListComponent implements OnInit, OnDestroy {
  constructor(
    public store: Store<AppState>,
    public hirlRPCUpdaterRequestService: HIRLRPCUpdaterRequestService,
    public hirlRPCUpdaterRequestServicePermissionService: HIRLRPCUpdaterRequestServicePermissionService,
    public router: Router,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private navigation: NavigationService,
    private uiHelperService: UIHelperService
  ) {
    const uiSettings = this.uiHelperService.getUISettings();

    this.defaultParams = new EEPProgramHomeStatusRequestParams(
      1,
      '',
      '-id',
      uiSettings.rowsPerPage
    );
  }

  public initialized = false;
  public currentUser: User;
  public filterFromGroup: FormGroup;

  public defaultParams: HIRLRPCUpdaterRequestParams;
  public storedParams: HIRLRPCUpdaterRequestParams;

  public isLoading = true;
  public rows: HIRLRPCUpdaterRequest[] = [];
  public rowsCount = 0;

  private listSubscription$: Subscription;
  private componentDestroyed$ = new Subject();
  private refreshIntervalId$: any;

  public createPermissionResponse: Observable<ObjectPermissionResponse>;

  @ViewChild(DatatableComponent) ngxDatatable: DatatableComponent;

  ngOnInit() {
    this.storedParams = Object.assign(
      new HIRLRPCUpdaterRequestParams(),
      this.defaultParams
    );

    this.createPermissionResponse =
      this.hirlRPCUpdaterRequestServicePermissionService.canCreate();

    forkJoin({
      queryParams: this.activatedRoute.queryParams.pipe(first()),
      currentUser: this.store.select(getInfoUser).pipe(first()),
    }).subscribe(({ queryParams, currentUser }) => {
      this.initialized = true;
      this.currentUser = currentUser;
      this.storedParams.assignQueryParams(queryParams);
      this.setupFilterForm();
      this.hydrateForm();
      this.list();

      this.refreshIntervalId$ = setInterval(() => {
        this.list();
      }, 15000);
    });
  }

  setupFilterForm() {
    this.filterFromGroup = this.fb.group({
      search: [null],
      certification_date__gte: [null],
      certification_date__lte: [null],
    });

    this.filterFromGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        const params = Object.assign(this.defaultParams, values);
        delete params.ordering;

        this.storedParams = Object.assign(this.storedParams, params);
        this.list();
      });
  }

  hydrateForm() {
    this.filterFromGroup.patchValue(this.storedParams, {
      emitEvent: false,
      onlySelf: true,
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();

    if (this.refreshIntervalId$) {
      clearInterval(this.refreshIntervalId$);
    }
  }

  list() {
    this.isLoading = true;

    // populate params to query string
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.storedParams.toQueryParams(),
      replaceUrl: true,
    });

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }

    this.listSubscription$ = this.hirlRPCUpdaterRequestService
      .list(this.storedParams)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(data => {
        this.rows = data.results;
        this.rowsCount = data.count;
        this.isLoading = false;
      });
  }

  getId(row) {
    return row.id;
  }

  onSort({ sorts }) {
    this.storedParams.ordering = `${sorts[0].dir === 'desc' ? '-' : ''}${
      sorts[0].prop
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  onFooterPage(event) {
    this.storedParams.page = event.page;
    this.list();
  }

  resetFilters($event: MouseEvent) {
    $event.preventDefault();
    this.filterFromGroup.reset();
  }

  onRowsPerPageChanged(rowsPerPage: number) {
    this.storedParams.page_size = rowsPerPage;
    this.list();
  }
}
