import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ServerResponse } from '@/core/schemes/server-response';
import { BaseDataService } from '@/core/services/base-data-service';
import { ListRequestParams } from '@/core/schemes/request-params';
import { Observable } from 'rxjs';
import { Metro } from '@/data/geographic/models';

export class MetroRequestParams extends ListRequestParams {
  home_status_related?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class MetroService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/metros/`;

  list(params?: MetroRequestParams) {
    return this.http.get<ServerResponse<Metro>>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }
  retrieve(metroId: string): Observable<Metro> {
    return this.http.get<Metro>(`${this.baseUrl}${metroId}`);
  }
}
