import { createAction, props } from '@ngrx/store';
import { Invoice, InvoiceItemGroup } from '@/data/invoicing/models';
import { Annotation } from '@/data/annotation/models';

export const resetInvoice = createAction('[Invoice] Reset');

export const loadInvoice = createAction(
  '[Invoice] Detail',
  props<{ payload: Invoice }>()
);

// InvoiceItemGroups

export const loadInvoiceInvoiceItemGroupsAction = createAction(
  '[Invoice InvoiceInvoiceItemGroups] InvoiceItemGroups',
  props<{ payload: InvoiceItemGroup[] }>()
);

export const loadInvoiceInvoiceItemGroupsCountAction = createAction(
  '[Invoice InvoiceInvoiceItemGroups] InvoiceItemGroups Count',
  props<{ payload: number }>()
);

export const deleteInvoiceInvoiceItemGroupAction = createAction(
  '[Invoice InvoiceInvoiceItemGroups] Delete InvoiceItemGroup',
  props<{ payload: InvoiceItemGroup }>()
);

// Notes

export const loadInvoiceNotes = createAction(
  '[Invoice Notes] Notes',
  props<{ payload: Annotation[] }>()
);

export const loadInvoiceNotesCount = createAction(
  '[Invoice Notes] Notes Count',
  props<{ payload: number }>()
);

export const updateInvoiceNote = createAction(
  '[Invoice Notes] Update Note',
  props<{ payload: Annotation }>()
);

export const deleteInvoiceNote = createAction(
  '[Invoice Notes] Delete Note',
  props<{ payload: Annotation }>()
);
