<div class="d-flex">
    <button mat-button color="accent" mat-raised-button (click)="handleRunSimulation()"
        [disabled]="alreadyRunningSimulation || !editingAllowed">
        <div class="button-content">
            <span>{{ (alreadyRunningSimulation ? 'Running ' : 'Run ') + 'Simulation' }}</span>
            <mat-spinner [diameter]="15" *ngIf="alreadyRunningSimulation"></mat-spinner>
        </div>
    </button>
    <button [disabled]="alreadyRunningSimulation || !editingAllowed" class="menu-button" mat-button color="accent" mat-raised-button
        (click)="openOverlay($event)">
        <mat-icon class="w-0">expand_more</mat-icon>
    </button>
</div>

<ng-template #overlayTemplate>
    <app-analysis-type-config [configId]="configId" class="overlay"></app-analysis-type-config>
</ng-template>
