import {
  AreaUnitLabels,
  FloorCoveringLabels,
  InsulationGradeLabelMapping,
  LengthUnitLabels,
} from '@/data/simulation/enumerations';
import {
  SlabTypeBackendDict,
  createSlabTypeForm,
  FIELD_LABELS,
} from '@/data/simulation/models/enclosure/SlabType';
import { updateSlabType } from '@/modules/simulation/state/slab-type/actions';
import { selectSlabViewSet } from '@/modules/simulation/state/slab-type/selectors';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-slab-type',
  templateUrl: './slab-type.component.html',
  styleUrls: ['./slab-type.component.scss'],
})
export class SlabTypeComponent implements OnInit {
  @Input() entityId: number;
  fieldLabels = FIELD_LABELS;

  lengthLabels = LengthUnitLabels;
  areaLabels = AreaUnitLabels;

  private componentDestroyed$ = new Subject();

  loading = false;
  entity: SlabTypeBackendDict = null;
  slabTypeFormGroup: FormGroup;
  insulationGradeLabelMapping = InsulationGradeLabelMapping;
  floorCoveringLabels = FloorCoveringLabels;

  constructor(public store: Store) {}

  ngOnInit() {
    this.store
      .select(selectSlabViewSet(this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(({ data, loading }) => {
        this.loading = loading;
        if (loading) return;

        if (this.slabTypeFormGroup) {
          this.slabTypeFormGroup.patchValue(data);
        } else {
          this.slabTypeFormGroup = createSlabTypeForm(data);
        }
        this.entity = data;
      });
  }

  saveSlabType(changedValues: Partial<SlabTypeBackendDict>) {
    this.store.dispatch(
      updateSlabType({
        slabTypeChanges: changedValues,
      })
    );
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
