import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '@/data/core/models/user';
import { forkJoin, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, takeUntil } from 'rxjs/operators';
import { toggleLoading } from '@/state/actions/app.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { CompanyService } from '@/data/company/services/company-base.service';
import {
  Company,
  CompanyInfo,
  GCPFeeCalculationTypeLabelMapping,
} from '@/data/company/models';

export interface HirlSetupInvoicePreferencesDialogData {
  company: Company | CompanyInfo;
}

@Component({
  selector: 'app-hirl-setup-invoice-preferences-dialog',
  templateUrl: './hirl-setup-invoice-preferences-dialog.component.html',
  styleUrls: ['./hirl-setup-invoice-preferences-dialog.component.scss'],
})
export class HirlSetupInvoicePreferencesDialogComponent
  implements OnInit, OnDestroy
{
  protected readonly GCPFeeCalculationTypeLabelMapping =
    GCPFeeCalculationTypeLabelMapping;

  public initialized: boolean;
  public customerHIRLSettings = CustomerHIRLSettings;

  public form: FormGroup;
  public company: Company;
  public currentUser: User;

  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<HirlSetupInvoicePreferencesDialogComponent>,
    private fb: FormBuilder,
    private companyService: CompanyService,
    private dialog: MatDialog,
    private uiHelperService: UIHelperService,
    @Inject(MAT_DIALOG_DATA) public data: HirlSetupInvoicePreferencesDialogData
  ) {}
  ngOnInit() {
    const sources = {
      currentUser: this.store.select(getInfoUser).pipe(first()),
      company: this.companyService
        .retrieve(this.data.company.id, { ignoreStore: true })
        .pipe(takeUntil(this.componentDestroyed$), first()),
    };

    this.store.dispatch(toggleLoading({ payload: true }));

    forkJoin(sources)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: ({
          currentUser,
          company,
        }: {
          currentUser: User;
          company: Company;
        }) => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.currentUser = currentUser;
          this.company = company;

          this.setupForm();
          this.initialized = true;
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  private setupForm() {
    this.form = this.fb.group({
      gcp_fee_calculation_type: [null, [Validators.required]],
    });
  }

  hydrateForm() {
    this.form.patchValue(Object.assign({}, this.company), {
      emitEvent: false,
      onlySelf: true,
    });
  }

  public save($event: MouseEvent) {
    $event.preventDefault();

    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const data: Company = new Company();

    data.gcp_fee_calculation_type = this.form.value.gcp_fee_calculation_type;

    this.companyService
      .update(this.company.id, data)
      .pipe(first())
      .subscribe({
        next: (_: Company) => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close();
        },
        error: (error: HttpErrorResponse) =>
          this.uiHelperService.handleUserRequestError(error),
      });
  }
}
