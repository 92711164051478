import { createReducer, on } from '@ngrx/store';
import * as RimJoistActions from './actions';
import { RimJoistBackendDict } from '@/data/simulation/models/enclosure/RimJoist';
import { BaseState, sharedInitialState } from '../shared/base.state';
import { addErrorCount, addStateErrorCount } from '../helper';

export type RimJoistState = BaseState<RimJoistBackendDict>;

export const initialState: RimJoistState = sharedInitialState;

export const reducer = createReducer(
  initialState,
  on(RimJoistActions.loadRimJoistsSuccess, (currentState, payload) => {
    const entities = payload.rimJoists.reduce(
      (acc, rimJoist) => ({ ...acc, [rimJoist.id]: rimJoist }),
      {}
    );
    const loadingStatus = payload.rimJoists.reduce(
      (acc, rimJoist) => ({ ...acc, [rimJoist.id]: false }),
      {}
    );

    return {
      entities: { ...currentState.entities, ...entities },
      loading: { ...currentState.loading, ...loadingStatus },
      errors: { ...currentState.errors, ...addStateErrorCount(payload.errors) },
    };
  }),

  on(RimJoistActions.removeRimJoistSuccess, (currentState, { id }) => {
    const { [id]: removed, ...entities } = currentState.entities;
    const { [id]: removedLoading, ...loading } = currentState.loading;
    const { [id]: removedErrors, ...errors } = currentState.errors;

    return {
      entities,
      loading,
      errors,
    };
  }),

  on(RimJoistActions.updateRimJoist, (state, { id }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [id]: true,
      },
    };
  }),

  on(RimJoistActions.removeRimJoist, (state, { rimJoist }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [rimJoist.id]: true,
      },
    };
  }),

  on(
    RimJoistActions.updateRimJoistFailure,
    RimJoistActions.removeRimJoistFailure,
    (state, { id }) => {
      return {
        ...state,
        loading: {
          ...state.loading,
          [id]: false,
        },
      };
    }
  ),

  on(RimJoistActions.updateRimJoistErrors, (state, { id, errors }) => {
    return {
      ...state,
      errors: {
        ...state.errors,
        [id]: errors,
      },
    };
  }),

  on(RimJoistActions.updateRimJoistSuccess, (state, { rimJoist, errors }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        [rimJoist.id]: rimJoist,
      },
      loading: {
        ...state.loading,
        [rimJoist.id]: false,
      },
      errors: {
        ...state.errors,
        [rimJoist.id]: addErrorCount(errors),
      },
    };
  }),

  on(
    RimJoistActions.setRimJoistType,
    (state, { rimJoistId, rimJoistTypeId }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          [rimJoistId]: {
            ...state.entities[rimJoistId],
            type: rimJoistTypeId,
          },
        },
      };
    }
  )
);
