import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hirl-project-registration-entity-responsible-for-payment',
  templateUrl:
    './hirl-project-registration-entity-responsible-for-payment.component.html',
  styleUrls: [
    './hirl-project-registration-entity-responsible-for-payment.component.scss',
  ],
})
export class HirlProjectRegistrationEntityResponsibleForPaymentComponent
  implements OnInit
{
  ngOnInit(): void {}
}
