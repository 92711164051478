import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BaseDataService } from '@/core/services/base-data-service';
import { Observable } from 'rxjs';
import { ThermostatBackendDict } from '../models/mechanicals/Thermostat';
import { ServerResponse } from '@/core/schemes/server-response';
import { ListRequestParams } from '@/core/schemes/request-params';

@Injectable({ providedIn: 'root' })
export class ThermostatService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/thermostat/`;

  list(
    params: ListRequestParams
  ): Observable<ServerResponse<ThermostatBackendDict>> {
    return this.http.get<ServerResponse<ThermostatBackendDict>>(
      `${this.baseUrl}`,
      {
        params: this.rollParams(params),
      }
    );
  }

  update(
    entity: Partial<ThermostatBackendDict>
  ): Observable<ThermostatBackendDict> {
    return this.http.patch<ThermostatBackendDict>(
      `${this.baseUrl}${entity.id}/`,
      entity
    );
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}${id}`);
  }
}
