import { createSelector } from '@ngrx/store';
import { SimulationState, UsableMechnicalEquipmentState } from '../reducers';
import { selectSimulationFeature } from '../simulation.selectors';
import {
  EquipmentType,
  MechanicalEquipmentBackendDict,
} from '@/data/simulation/models/mechanicals/MechanicalEquipment';

export const EquipmentFieldNameToStateName: Record<
  EquipmentType,
  keyof UsableMechnicalEquipmentState
> = {
  [EquipmentType.AIR_CONDITIONER]: 'airConditioner',
  [EquipmentType.AIR_SOURCE_HEAT_PUMP]: 'airSourceHeatPump',
  [EquipmentType.DEHUMIDIFIER]: 'dehumidifier',
  [EquipmentType.GROUND_SOURCE_HEAT_PUMP]: 'groundSourceHeatPump',
  [EquipmentType.HEATER]: 'heater',
  [EquipmentType.WATER_HEATER]: 'waterHeater',
};

export const selectMechanicalEquipments = createSelector(
  selectSimulationFeature,
  (state: SimulationState) => state.mechanicalEquipment.entities
);
export const selectMechanicalEquipment = (id: number) =>
  createSelector(selectMechanicalEquipments, entities => entities[id]);
const selectMechanicalEquipmentLoadingStatus = (id: number) =>
  createSelector(selectSimulationFeature, (state: SimulationState) => {
    return state.mechanicalEquipment.loading[id];
  });

export const selectMechanicalEquipmentViewSet = (id: number) =>
  createSelector(
    selectMechanicalEquipment(id),
    selectMechanicalEquipmentLoadingStatus(id),
    (mechanicalEquipment, loadingStatus) => ({
      data: mechanicalEquipment,
      loading: loadingStatus,
    })
  );

export const selectMechanicalEquipmentName = (id: number) =>
  createSelector(
    selectMechanicalEquipment(id),
    (mechanicalEquipment: MechanicalEquipmentBackendDict) =>
      mechanicalEquipment.name
  );

export const selectAttachedMechanicalEquipment = (
  equipmentType: EquipmentType
) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) =>
      state[EquipmentFieldNameToStateName[equipmentType]]
  );

export const selectAtahcedEquipmentNameAndError = (
  equipmentType: EquipmentType,
  equipmentId: number
) =>
  createSelector(
    selectAttachedMechanicalEquipment(equipmentType),
    attachedEquipments => ({
      name: attachedEquipments.entities[equipmentId].name,
      errorCount: attachedEquipments.errors[equipmentId].errorCount,
    })
  );

export const selectAttachedMechanicalEquipmentTypeDetails = (id: number) =>
  createSelector(selectSimulationFeature, (state: SimulationState) => {
    const entity = state.mechanicalEquipment.entities[id];
    if (entity.heater !== null) {
      return { name: EquipmentType.HEATER, id: entity.heater };
    } else if (entity.air_conditioner !== null) {
      return {
        name: EquipmentType.AIR_CONDITIONER,
        id: entity.air_conditioner,
      };
    } else if (entity.air_source_heat_pump !== null) {
      return {
        name: EquipmentType.AIR_SOURCE_HEAT_PUMP,
        id: entity.air_source_heat_pump,
      };
    } else if (entity.dehumidifier !== null) {
      return { name: EquipmentType.DEHUMIDIFIER, id: entity.dehumidifier };
    } else if (entity.ground_source_heat_pump !== null) {
      return {
        name: EquipmentType.GROUND_SOURCE_HEAT_PUMP,
        id: entity.ground_source_heat_pump,
      };
    } else if (entity.water_heater !== null) {
      return { name: EquipmentType.WATER_HEATER, id: entity.water_heater };
    }
  });

export const selectMechanicalEquipmentErrorCount = (id: number) =>
  createSelector(selectSimulationFeature, (state: SimulationState) => ({
    errorCount: state.mechanicalEquipment.errors[id].errorCount,
    warningCount: state.mechanicalEquipment.errors[id].warningCount,
  }));

export const selectMechanicalEquipmentErrorViewSet = (id: number) =>
  createSelector(
    selectSimulationFeature,
    selectMechanicalEquipmentErrorCount(id),
    selectMechanicalEquipmentName(id),
    selectAttachedMechanicalEquipmentTypeDetails(id),
    (state, errorCount, name, attachedEquipmentDetails) => ({
      name,
      attachedEquipmentDetails,
      attachedEquipmentErrorCount: {
        errorCount:
          state[EquipmentFieldNameToStateName[attachedEquipmentDetails.name]]
            .errors[attachedEquipmentDetails.id].errorCount,
        warningCount:
          state[EquipmentFieldNameToStateName[attachedEquipmentDetails.name]]
            .errors[attachedEquipmentDetails.id].warningCount,
      },
      mechanicalEquipmentError: errorCount,
    })
  );
