import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { first, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getInfoUser } from '@/state/selectors/info.selector';
import { AppState } from '@/state/reducers';
import { Annotation } from '@/data/annotation/models';
import { AnnotationService } from '@/data/annotation/services/annotation.service';

@Injectable({
  providedIn: 'root',
})
export class AnnotationPermissionService {
  constructor(
    public store: Store<AppState>,
    public annotationService: AnnotationService
  ) {}
  canEdit(
    annotation: Annotation | number
  ): Observable<ObjectPermissionResponse> {
    let annotation$: Observable<Annotation>;
    if (typeof annotation === 'number') {
      annotation$ = this.annotationService.retrieve(annotation, {
        ignoreStore: true,
      });
    } else {
      annotation$ = of(annotation);
    }
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: annotation$,
    }).pipe(
      map(({ currentUser, entity }) => {
        if (currentUser.is_superuser) {
          return new ObjectPermissionResponse(true);
        }
        if (currentUser.company === entity.user_info.company) {
          return new ObjectPermissionResponse(true);
        }
        return new ObjectPermissionResponse(
          false,
          'You do not have permissions to edit this note'
        );
      })
    );
  }

  canDelete(
    annotation: Annotation | number
  ): Observable<ObjectPermissionResponse> {
    return this.canEdit(annotation);
  }
}
