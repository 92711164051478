<div class="row h-100">
  <div class="col-12">
    <mat-sidenav-container class="h-100" [hasBackdrop]="false">
      <mat-sidenav
        #selectedSideNav
        mode="side"
        [opened]="!!selectedInvoiceItemGroups.length"
        position="end"
        fixedInViewport="true"
        [fixedTopGap]="50"
      >
        <div class="container padding-top-bottom">
          <div class="row mt-2">
            <div class="col-12">
              <h3>Selected Projects</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <ul class="list-group list-group-flush">
                <li
                  class="list-group-item"
                  *ngFor="let invoiceItemGroup of selectedInvoiceItemGroups"
                >
                  <a
                    href="/home/{{ invoiceItemGroup.home_id }}"
                    target="_blank"
                    matTooltip="View Home"
                  >
                    {{ invoiceItemGroup.hirl_project_address }}
                  </a>
                  - {{ invoiceItemGroup.total | currency : "USD" }}
                </li>
              </ul>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <hr />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              Total:
              {{
                selectedInvoiceItemGroups
                  | sumArrayOfObjectsPipe : "total"
                  | currency : "USD"
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button
                class="btn btn-primary btn-block"
                [disabled]="!selectedInvoiceItemGroups.length"
                (click)="createInvoice(selectedInvoiceItemGroups)"
              >
                Create Invoice
              </button>
            </div>
          </div>
        </div>
      </mat-sidenav>
      <mat-sidenav-content>
        <div class="container">
          <div class="row">
            <div class="col-6">
              <h3>
                <span *ngIf="!invoiceItemGroupsIsLoading">{{
                  invoiceItemGroupsCount | number
                }}</span>
                <fa-icon
                  [icon]="['fas', 'spinner']"
                  [spin]="true"
                  *ngIf="invoiceItemGroupsIsLoading"
                ></fa-icon>
                NGBS Invoice Management
              </h3>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <hr />
            </div>
          </div>

          <div class="row">
            <div class="col-3">
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <form [formGroup]="filterFromGroup" class="card">
                      <h5 class="card-header">
                        Filters
                        <a href="#" class="small" (click)="resetFilters($event)"
                          >Reset filters</a
                        >
                      </h5>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-12">
                            <mat-form-field appearance="outline" class="w-100">
                              <mat-label>Search</mat-label>
                              <app-search-input
                                formControlName="search"
                                placeholder="Type to search something"
                              ></app-search-input>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <mat-form-field appearance="outline" class="w-100">
                              <mat-label>Client</mat-label>
                              <app-generic-model-autocomplete
                                modelClassName="company"
                                formControlName="customer_hirl_client_company_info"
                                [panelWidth]="640"
                                [initialValueId]="
                                  storedParams.customer_hirl_client_company
                                "
                                [filterParams]="{
                                  company_type: CompanyType.builder
                                }"
                              ></app-generic-model-autocomplete>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <mat-form-field appearance="outline" class="w-100">
                              <mat-label>Category</mat-label>
                              <mat-select formControlName="category">
                                <mat-option [value]="''"> Any </mat-option>
                                <mat-option
                                  *ngFor="
                                    let item of invoiceItemGroupCategoryLabelMapping
                                      | defaultOrderKeyValue
                                  "
                                  [value]="item.key"
                                >
                                  {{ item.value }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <mat-form-field appearance="outline" class="w-100">
                              <mat-label>Project Status</mat-label>
                              <mat-select formControlName="home_status__state">
                                <mat-option [value]="''"> Any </mat-option>
                                <mat-option
                                  *ngFor="
                                    let item of eepProgramHomeStatusStateLabelMapping
                                      | keyvalue : returnZero
                                  "
                                  [value]="item.key"
                                >
                                  {{ item.value }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <mat-form-field appearance="outline" class="w-100">
                              <mat-label>Legacy Invoice Status</mat-label>
                              <mat-select
                                formControlName="customer_hirl_project_have_legacy_notified_certification"
                              >
                                <mat-option
                                  *ngFor="
                                    let item of projectHaveLegacyNotifiedCertificationLabelMapping
                                      | keyvalue
                                  "
                                  [value]="item.key"
                                >
                                  {{ item.value }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <mat-form-field appearance="outline" class="w-100">
                              <mat-label>Balance</mat-label>
                              <mat-select
                                formControlName="current_balance_filter"
                              >
                                <mat-option
                                  *ngFor="
                                    let item of currentBalanceFilterLabelMapping
                                      | keyvalue
                                  "
                                  [value]="item.key"
                                >
                                  {{ item.key }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <mat-form-field appearance="outline" class="w-100">
                              <mat-label>Project Type</mat-label>
                              <mat-select
                                formControlName="home_status__customer_hirl_project__registration__project_type"
                              >
                                <mat-option [value]="''"> Any </mat-option>
                                <mat-option
                                  *ngFor="
                                    let item of HIRLProjectRegistrationTypeLabelMapping
                                      | keyvalue
                                  "
                                  [value]="item.key"
                                >
                                  {{ item.value }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-12">
                            <mat-form-field appearance="outline" class="w-100">
                              <mat-label>CA Status</mat-label>
                              <mat-select
                                formControlName="customer_hirl_erfp_ca_status"
                              >
                                <mat-option [value]="''"> Any </mat-option>
                                <mat-option [value]="'do_not_have_ca'"
                                  >Do not have CA</mat-option
                                >
                                <mat-option
                                  *ngFor="
                                    let item of clientAgreementStateLabelMapping
                                      | keyvalue
                                  "
                                  [value]="item.key"
                                >
                                  {{ item.value }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-12">
                            <mat-form-field appearance="outline" class="w-100">
                              <mat-label>Created At Start</mat-label>
                              <input
                                matInput
                                [matDatepicker]="datepicker_created_at__gte"
                                formControlName="created_at__gte"
                              />
                              <mat-datepicker-toggle
                                matSuffix
                                (click)="
                                  filterFromGroup
                                    .get('created_at__gte')
                                    .setValue(null)
                                "
                              >
                                <mat-icon matDatepickerToggleIcon
                                  >clear</mat-icon
                                >
                              </mat-datepicker-toggle>
                              <mat-datepicker-toggle
                                matSuffix
                                [for]="datepicker_created_at__gte"
                              ></mat-datepicker-toggle>
                              <mat-datepicker
                                #datepicker_created_at__gte
                              ></mat-datepicker>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <mat-form-field appearance="outline" class="w-100">
                              <mat-label>Created At End</mat-label>
                              <input
                                matInput
                                [matDatepicker]="datepicker_created_at__lte"
                                formControlName="created_at__lte"
                              />
                              <mat-datepicker-toggle
                                matSuffix
                                (click)="
                                  filterFromGroup
                                    .get('created_at__lte')
                                    .setValue(null)
                                "
                              >
                                <mat-icon matDatepickerToggleIcon
                                  >clear</mat-icon
                                >
                              </mat-datepicker-toggle>
                              <mat-datepicker-toggle
                                matSuffix
                                [for]="datepicker_created_at__lte"
                              ></mat-datepicker-toggle>
                              <mat-datepicker
                                #datepicker_created_at__lte
                              ></mat-datepicker>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-9">
              <div class="row" *ngIf="invoiceItemGroupsIsLoading">
                <div class="col-12">
                  <mat-progress-bar
                    mode="indeterminate"
                    color="accent"
                  ></mat-progress-bar>
                </div>
              </div>
              <table
                mat-table
                [dataSource]="invoiceItemGroups"
                class="table"
                matSort
                [matSortActive]="storedParams.toTableSort().active"
                [matSortDirection]="storedParams.toTableSort().direction"
                (matSortChange)="onSortChange($event)"
              >
                <ng-container matColumnDef="select">
                  <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="onSelectAll($event)"></mat-checkbox>
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <mat-checkbox
                      (change)="onSelect($event, row)"
                      [checked]="selectedInvoiceItemGroups.includes(row)"
                    ></mat-checkbox>
                  </td>
                </ng-container>

                                <ng-container matColumnDef="project_id">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Project ID & Subdivision/MF Development</th>
                                    <td mat-cell *matCellDef="let row">
                                        <div style="display: flex; flex-direction: column;">
                                            <a [routerLink]="['/', 'hi', 'project_registrations', row.hirl_project_registration_id]" matTooltip="View Building">
                                              {{ row.hirl_project_id }}
                                            </a>
                                            <a [routerLink]="['/', row.subdivision_url]" matTooltip="View Subdivision/MF Development">
                                              {{ row.subdivision_name }}
                                            </a>
                                        </div>
                                    </td>
                                </ng-container>

                <ng-container matColumnDef="address">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Address
                  </th>
                  <td mat-cell *matCellDef="let row" [style.width.px]="220">
                    <a
                      href="/home/{{ row.home_id }}"
                      target="_blank"
                      matTooltip="View Home"
                    >
                      {{ row.hirl_project_address }}
                    </a>
                  </td>
                </ng-container>

                <ng-container matColumnDef="total">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Total
                  </th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.total | currency : "USD" }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="client">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Client
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <app-company-display
                      [company]="row?.client"
                      [displayType]="'onlyNameAsLink'"
                    ></app-company-display>
                  </td>
                </ng-container>

                <ng-container matColumnDef="program">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Program
                  </th>
                  <td mat-cell *matCellDef="let row" [style.width.px]="140">
                    <a
                      [routerLink]="['/', 'eep_program', row.eep_program_id]"
                      >{{ row.eep_program_name }}</a
                    >
                  </td>
                </ng-container>

                <ng-container matColumnDef="created">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Created
                  </th>
                  <td mat-cell *matCellDef="let row" [style.width.px]="140">
                    {{ row.created_at | date : "MM/dd/yyyy" }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="ca_status">
                  <th mat-header-cell *matHeaderCellDef>CA Status</th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.client_ca_status }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="project_status">
                  <th mat-header-cell *matHeaderCellDef>Project Status</th>
                  <td mat-cell *matCellDef="let row" [style.width.px]="160">
                    {{
                      eepProgramHomeStatusStateLabelMapping[
                        row.home_status_state
                      ]
                    }}
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <ng-container
                  matColumnDef="rowShading"
                  class="row-shading"
                ></ng-container>
                <tr
                  mat-row
                  *matRowDef="
                    let row;
                    columns: displayedColumns;
                    let even = even;
                    let odd = odd
                  "
                  [ngClass]="{ 'row-shading': odd }"
                ></tr>
              </table>
              <div class="d-flex flex-row">
                <div class="align-self-center">
                  <button
                    mat-raised-button
                    (click)="exportToCSV($event)"
                    [disabled]="invoiceItemGroupsIsLoading"
                    class="mr-2"
                    matTooltip="Export Data to CSV file"
                  >
                    CSV
                  </button>
                  <button
                    mat-raised-button
                    (click)="exportToExcel($event)"
                    [disabled]="invoiceItemGroupsIsLoading"
                    matTooltip="Export Data to Excel file"
                  >
                    Excel
                  </button>
                </div>
                <div class="d-flex flex-fill justify-content-end">
                  <mat-paginator
                    [length]="invoiceItemGroupsCount"
                    [pageIndex]="$any(storedParams.page) - 1"
                    [pageSize]="storedParams.page_size"
                    (page)="onPaginateChange($event)"
                    aria-label="Select page"
                  >
                  </mat-paginator>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>
