import { createSelector } from '@ngrx/store';
import { SimulationState } from '../reducers';
import { selectSimulationFeature } from '../simulation.selectors';

export const selectSeasonalRate = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.seasonalRate.entities[id]
  );
const selectSeasonalRateLoadingStatus = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.seasonalRate.loading[id]
  );

export const selectSeasonalRateViewSet = (id: number) =>
  createSelector(
    selectSeasonalRate(id),
    selectSeasonalRateLoadingStatus(id),
    (seasonalRate, loadingStatus) => ({
      data: seasonalRate,
      loading: loadingStatus,
    })
  );

export const selectSeasonalRates = (ids: number[]) =>
  createSelector(selectSimulationFeature, (state: SimulationState) =>
    ids.map(id => state.seasonalRate.entities[id])
  );
