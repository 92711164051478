<div class="d-flex flex-row align-items-center">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12 text-center">
                <span class="display-1 d-block">Access Denied</span>
                <div class="mb-4 lead">You do not have permissions to view this page.</div>
            </div>
        </div>
    </div>
</div>
