import { UserInfo } from '@/data/core/models/user';

export enum AccreditationName {
  NGBS2020 = 'ngbs-2020',
  NGBS2015 = 'ngbs-2015',
  NGBS2012 = 'ngbs-2012',
  NGBS2008 = 'ngbs-2008',
  NGBSMasterVerifier = 'master-verifier',
  NGBSWRIVerifier = 'ngbs-wri-verifier',
  NGBSGreenFieldRep = 'ngbs-green-field-rep',
  NGBSLandDevelopmentFieldRep = 'ngbs-land-development-field-rep',
  CongruentCertifiedRater = 'congruent_certified_rater',
  CongruentCertifiedModeler = 'congruent_certified_modeler',
  CongruentCertifiedInspector = 'congruent_certified_inspector',
  CongruentCertifiedTrainer = 'congruent_certified_trainer',
  CongruentCertifiedQualityAssessor = 'congruent_certified_quality_assessor',
}

export const AccreditationNameLabelMapping: Record<AccreditationName, string> =
  {
    [AccreditationName.NGBS2020]: 'NGBS 2020',
    [AccreditationName.NGBS2015]: 'NGBS 2015',
    [AccreditationName.NGBS2012]: 'NGBS 2012',
    [AccreditationName.NGBS2008]: 'NGBS 2008',
    [AccreditationName.NGBSMasterVerifier]: 'NGBS Master Verifier',
    [AccreditationName.NGBSWRIVerifier]: 'NGBS WRI Verifier',
    [AccreditationName.NGBSGreenFieldRep]: 'NGBS Green Field Rep',
    [AccreditationName.NGBSLandDevelopmentFieldRep]:
      'NGBS Land Development Field Rep',
    [AccreditationName.CongruentCertifiedRater]: 'Congruent Certified Rater',
    [AccreditationName.CongruentCertifiedModeler]:
      'Congruent Certified Modeler',
    [AccreditationName.CongruentCertifiedInspector]:
      'Congruent Certified Inspector',
    [AccreditationName.CongruentCertifiedTrainer]:
      'Congruent Certified Trainer',
    [AccreditationName.CongruentCertifiedQualityAssessor]:
      'Congruent Certified Quality Assessor',
  };

export enum AccreditationRole {
  modeler = 1,
  verifier = 2,
  senior = 3,
  rfi = 4,
  qa = 5,
  greenFieldRep = 6,
}

export const AccreditationRoleLabelMapping: Record<AccreditationRole, string> =
  {
    [AccreditationRole.modeler]: 'Energy Modeler',
    [AccreditationRole.verifier]: 'Verifier',
    [AccreditationRole.senior]: 'Rater/Verifier',
    [AccreditationRole.rfi]: 'Rating Field Inspector',
    [AccreditationRole.qa]: 'QA Designee',
    [AccreditationRole.greenFieldRep]: 'Green Field Rep',
  };

export enum AccreditationState {
  Inactive = 1,
  Active = 2,
  ProbationNew = 3,
  ProbationDisciplinary = 4,
  SuspendedAdministrative = 5,
  SuspendedDisciplinary = 6,
  TerminatedAdministrative = 7,
  TerminatedDisciplinary = 8,
}

export enum AccreditationCycle {
  Annual = 1,
  Every2Years = 2,
  Every3Years = 3,
  Every4Years = 4,
}

export const AccreditationCycleLabelMapping: Record<
  AccreditationCycle,
  string
> = {
  [AccreditationCycle.Annual]: 'Annual',
  [AccreditationCycle.Every2Years]: 'Every 2 years',
  [AccreditationCycle.Every3Years]: 'Every 3 years',
  [AccreditationCycle.Every4Years]: 'Every 4 years',
};

export const AccreditationStateLabelMapping: Record<
  AccreditationState,
  string
> = {
  [AccreditationState.Inactive]: 'Inactive',
  [AccreditationState.Active]: 'Active',
  [AccreditationState.ProbationNew]: 'Probation – New',
  [AccreditationState.ProbationDisciplinary]: 'Probation – Disciplinary',
  [AccreditationState.SuspendedAdministrative]: 'Suspended – Administrative',
  [AccreditationState.SuspendedDisciplinary]: 'Suspended – Disciplinary',
  [AccreditationState.TerminatedAdministrative]: 'Terminated – Administrative',
  [AccreditationState.TerminatedDisciplinary]:
    'Terminated – Disciplinary (Revoked)',
};

export class Accreditation {
  id?: number;
  trainee?: number;
  trainee_info?: UserInfo;
  name?: AccreditationName;
  accreditation_id?: string;
  role?: number;
  state?: number;
  state_changed_at?: string;
  approver?: number;
  approver_info?: UserInfo;
  accreditation_cycle?: number;
  date_initial?: string;
  date_last?: string;
  manual_expiration_date?: string;

  expiration_date?: string;
  is_expired?: boolean;

  notes?: string;

  updated_at?: string;
  created_at?: string;
}

export class AccreditationInfo {
  id?: number;
  name?: string;
}
