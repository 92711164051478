import {
  Component,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { first, takeUntil } from 'rxjs/operators';

import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { MatTabChangeEvent } from '@angular/material/tabs';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { Observable, Subject } from 'rxjs';
import { CommunityService } from '@/data/community/services/community.service';
import { Community } from '@/data/community/models';
import { Relationship } from '@/data/relationship/models';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { toggleLoading } from '@/state/actions/app.actions';
import { Geocode } from '@/data/geocoder/models';
import { ConfirmedGeocodeResponse } from '@/modules/geocoder/components/confirm-geocode-response-dialog/confirm-geocode-response';
import { MatDialog } from '@angular/material/dialog';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { resetCommunity } from '@/state/actions/community/community.actions';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { CommunityPermissionService } from '@/modules/community/services/community-permission-service';
import { componentCompanyTypeDataMap } from '@/modules/company/constants';
import {
  CommunityChangeDialogData,
  CommunityChangeDialogComponent,
} from '../../components/community-change-dialog/community-change-dialog.component';
@Component({
  selector: 'app-community-detail-page',
  templateUrl: './community-detail-page.component.html',
  styleUrls: ['./community-detail-page.component.scss'],
})
export class CommunityDetailPageComponent implements OnInit, OnDestroy {
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) mapInfoWindow: MapInfoWindow;
  initialized = false;

  selectedIndex = 0;
  community: Community;

  public editPermissionResponse: Observable<ObjectPermissionResponse>;
  public deletePermissionResponse: Observable<ObjectPermissionResponse>;

  // utility community type specific attrs
  electricityProvider: boolean;
  gasProvider: boolean;
  waterProvider: boolean;

  public relationshipRows: Relationship[] = [];
  componentCompanyTypeDataMap = componentCompanyTypeDataMap;
  relationshipCount = 0;

  confirmedGeocodeResponse: ConfirmedGeocodeResponse;
  showAdditionalLocationStatistics = false;

  public mapCenter: google.maps.LatLngLiteral;
  public mapOptions: google.maps.MapOptions = {
    disableDoubleClickZoom: true,
  };
  public mapMarkers = [];
  public mapInfoContent = '';

  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    public injector: Injector,
    public dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private communityService: CommunityService,
    private communityPermissionService: CommunityPermissionService,
    public uiHelperService: UIHelperService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(params => {
        if (params.tab) {
          this.selectedIndex = Number(params.tab) || 0;
        }
      });

    this.activatedRoute.params
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(params => {
        this.store.dispatch(toggleLoading({ payload: true }));
        this.communityService
          .retrieve(params.communityId)
          .pipe(takeUntil(this.componentDestroyed$), first())
          .subscribe(
            community => {
              this.community = community;
              this.communitySetup();
              this.store.dispatch(toggleLoading({ payload: false }));
              this.initialized = true;
            },
            error => this.uiHelperService.handleMainRequestError(error)
          );
        this.communityService
          .relationships_list(params.communityId)
          .pipe(takeUntil(this.componentDestroyed$), first())
          .subscribe(data => {
            this.relationshipRows = data.results;
            this.relationshipCount = data.count;
          });
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
    this.store.dispatch(resetCommunity());
  }

  communitySetup() {
    const geocode = new Geocode();
    geocode.raw_city = this.community.city;
    geocode.raw_city_info = this.community.city_info;
    this.confirmedGeocodeResponse = new ConfirmedGeocodeResponse({
      geocode: geocode,
      geocodeBrokeredResponse: this.community.geocode_response_info,
    });

    // map
    if (
      this.community.city_info?.latitude &&
      this.community.city_info?.longitude
    ) {
      this.mapCenter = {
        lat: this.community.city_info?.latitude,
        lng: this.community.city_info?.longitude,
      };
      this.mapMarkers.push({
        position: this.mapCenter,
        title: 'Geocoded Address',
        info: this.confirmedGeocodeResponse.displayAddress,
      });
    } else {
      this.mapCenter = null;
      this.mapMarkers = [];
    }

    this.editPermissionResponse = this.communityPermissionService.canEdit(
      this.community
    );
    this.deletePermissionResponse = this.communityPermissionService.canDelete(
      this.community
    );
  }

  onTabChange(event: MatTabChangeEvent) {
    window.dispatchEvent(new Event('resize'));
    const url = this.router
      .createUrlTree([], {
        relativeTo: this.activatedRoute,
        queryParams: { tab: event.index },
      })
      .toString();
    this.location.replaceState(url);
  }
  onToggleAdditionalLocationStatistics($event: MouseEvent) {
    $event.preventDefault();
    this.showAdditionalLocationStatistics =
      !this.showAdditionalLocationStatistics;
  }

  mapOpenInfo(marker: MapMarker, content) {
    this.mapInfoContent = content;
    this.mapInfoWindow.open(marker);
  }

  onDelete($event: MouseEvent) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '50%',
      data: {
        title: 'Do you want to delete this community ?',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (!result) {
          return;
        }
        this.store.dispatch(toggleLoading({ payload: true }));
        this.communityService
          .delete(this.community.id)
          .pipe(first())
          .subscribe(
            _ => {
              this.store.dispatch(toggleLoading({ payload: false }));
              this.router.navigate(['/', 'community']);
            },
            error => this.uiHelperService.handleUserRequestError(error)
          );
      });
  }

  onEdit($event: MouseEvent) {
    $event.preventDefault();

    const dialogRef = this.dialog.open(CommunityChangeDialogComponent, {
      width: '55%',
      disableClose: true,
      autoFocus: false,
      data: {
        community: this.community,
      } as CommunityChangeDialogData,
    });

    dialogRef.afterClosed().subscribe((result?: Community) => {
      if (!result) {
        return;
      }
      this.uiHelperService.openSnackBar('Record Successfully Updated');
    });
  }
}
