<ng-container [ngSwitch]="display">
    <ng-container *ngSwitchCase="displayType.default">
      {{ this.confirmedGeocodeResponse.displayAddress }}
    </ng-container>
    <ng-container *ngSwitchCase="displayType.geocodingIndicator">
      {{ this.confirmedGeocodeResponse.displayAddress }}
      <span *ngIf="this.confirmedGeocodeResponse.displayAddress && confirmedGeocodeResponse.geocodeBrokeredResponse" matTooltip="Confirmed address">◦</span>
      <span *ngIf="this.confirmedGeocodeResponse.displayAddress && !confirmedGeocodeResponse.geocodeBrokeredResponse" matTooltip="Manually overridden address">▵</span>
    </ng-container>
  <ng-container *ngSwitchCase="displayType.contactAddress">
    {{ this.confirmedGeocodeResponse.displayAddress }}
  </ng-container>
</ng-container>
