import {
  WindowBackendDict,
  DetailedWindowBackendDict,
} from '@/data/simulation/models/enclosure/Window';
import { createAction, props } from '@ngrx/store';
import { ModelErrors, StateErrors } from '../shared/base.state';

export const loadDetailedWindows = createAction(
  '[Simulation API] Load Detailed Windows',
  props<{ detailedWindows: DetailedWindowBackendDict[] }>()
);

export const loadWindowsSuccess = createAction(
  '[Window API] Load Windows Success',
  props<{
    windows: WindowBackendDict[];
    errors: StateErrors<WindowBackendDict>;
  }>()
);

export const updateWindow = createAction(
  '[Window UI] Update Window',
  props<{ id: number; windowChanges: Partial<WindowBackendDict> }>()
);

export const updateWindowSuccess = createAction(
  '[Window API] Update Window Success',
  props<{
    window: WindowBackendDict;
    errors: ModelErrors<WindowBackendDict>;
  }>()
);

export const updateWindowFailure = createAction(
  '[Window API] Update Window Failure',
  props<{ id: number }>()
);

export const removeWindow = createAction(
  '[Window UI] Remove Window',
  props<{ window: WindowBackendDict }>()
);

export const removeWindowSuccess = createAction(
  '[Window API] Remove Window Success',
  props<{ id: number }>()
);

export const removeWindowFailure = createAction(
  '[Window API] Remove Window Failure',
  props<{ id: number }>()
);

export const updateWindowErrors = createAction(
  '[Window UI] Update Window Errors',
  props<{ id: number; errors: any }>()
);

export const setWindowType = createAction(
  '[WindowType API] Set WindowType',
  props<{ windowId: number; windowTypeId: number | null }>()
);

export const setWindowTypeSuccess = createAction(
  '[WindowType API] Set WindowType null',
  props<{ windowId: number; windowTypeId: number | null }>()
);
