import { ModelFormControl, ModelFormGroup } from './ModelForm';
import { Validators } from '@angular/forms';
import { FieldLabels } from './base';
import { enumValidator } from '@/modules/simulation/validators/helper';
import { UserInfo } from '@/data/core/models/user';

export const FIELD_LABELS: FieldLabels<ProjectBackendDict> = {
  model_name: 'Model Name',
  building_status: 'Building Status',
  builder_name: 'Builder Name',
  builder: 'Builder',
  provider_identification: 'Provider Identification',
  provider_organization: 'Provider Organization',
  rater_organization_name: 'Rater Organization Name',
  rater_organization: 'Rater Organization',
  rater_of_record: 'Rater Of Record',
  permit_identification: 'Permit Identification',
  permit_date: 'Permit Date',
  final_inspection_date: 'Final Inspection Date',
  construction_year: 'Construction Year',
  rating_type: 'Rating Type',
  sampleset_identification: 'Sampleset Identification',
  resnet_registry_identification: 'RESNET Registry Identification',
  resnet_registry_datetime: 'RESNET Registry Datetime',
  eto_reference_home_type: 'ETO Reference Home Type',

  builder_info: 'Builder Info',
  rater_organization_info: 'Rater Organization Info',
  provider_organization_info: 'Provider Organization Info',
  rater_of_record_info: 'Rater Of Record Info',
};

enum RatingType {
  CONFIRMED = 'confirmed',
  SAMPLE_TEST = 'sample_test',
  SAMPLED = 'sampled',
  PROJECTED = 'projected',
  POST_IMPROVEMENT = 'post_improvement',
  THRESHOLD = 'threshold',
  UNKNOWN = 'unknown',
}

export const RatingTypeLabels = {
  [RatingType.CONFIRMED]: 'Confirmed',
  [RatingType.SAMPLE_TEST]: 'Sampled (Test)',
  [RatingType.SAMPLED]: 'Sampled',
  [RatingType.PROJECTED]: 'Projected',
  [RatingType.POST_IMPROVEMENT]: 'Post Improvement',
  [RatingType.THRESHOLD]: 'Threshold',
  [RatingType.UNKNOWN]: 'Not identified',
};

export enum BuildingStatus {
  NEW_CONSTRUCTION = 'new',
  EXISTING = 'existing',
}

export const BuildingStatusLabels = {
  [BuildingStatus.NEW_CONSTRUCTION]: 'New Construction',
  [BuildingStatus.EXISTING]: 'Existing Construction',
};

export interface ProjectBackendDict {
  id: number;
  model_name: string;
  building_status: BuildingStatus;
  builder_name: string;
  builder: number;
  builder_info?: { name: string };
  rater_organization_name: string;
  rater_organization: number;
  rater_organization_info?: { name: string };
  rater_of_record: number;
  rater_of_record_info: UserInfo;
  provider_identification: string;
  provider_organization: number;
  provider_organization_info?: { name: string };
  permit_identification: string;
  permit_date: Date;
  final_inspection_date: Date;
  construction_year: number;
  rating_type: string;
  sampleset_identification: string;
  resnet_registry_identification: string;
  resnet_registry_datetime: Date;
  eto_reference_home_type: string;
}

export function createProjectForm(data: ProjectBackendDict): ModelFormGroup {
  return new ModelFormGroup(
    {
      id: new ModelFormControl(data.id),
      model_name: new ModelFormControl(data.model_name),
      building_status: new ModelFormControl(
        data.building_status,
        enumValidator(BuildingStatus)
      ),
      builder_name: new ModelFormControl(data.builder_name),
      builder: new ModelFormControl(data.builder),
      rater_organization_name: new ModelFormControl(
        data.rater_organization_name
      ),
      rater_organization: new ModelFormControl(data.rater_organization),
      rater_of_record: new ModelFormControl(data.rater_of_record),
      provider_identification: new ModelFormControl(
        data.provider_identification
      ),
      provider_organization: new ModelFormControl(data.provider_organization),
      permit_identification: new ModelFormControl(data.permit_identification),
      permit_date: new ModelFormControl(data.permit_date),
      final_inspection_date: new ModelFormControl(data.final_inspection_date),
      construction_year: new ModelFormControl(data.construction_year, [
        Validators.min(1900),
        Validators.max(new Date().getFullYear()),
      ]),
      rating_type: new ModelFormControl(
        data.rating_type,
        enumValidator(RatingType)
      ),
      sampleset_identification: new ModelFormControl(
        data.sampleset_identification
      ),
      resnet_registry_identification: new ModelFormControl(
        data.resnet_registry_identification
      ),
      resnet_registry_datetime: new ModelFormControl(
        data.resnet_registry_datetime
      ),
      eto_reference_home_type: new ModelFormControl(
        data.eto_reference_home_type
      ),
    },
    {
      validators: (form: ModelFormGroup) => {
        const builder = form.get('builder').value;
        const builderName = form.get('builder_name').value;
        if (!builder && builderName) {
          form
            .get('builder')
            .setErrors({ noCompanyFound: { name: builderName } });
        }

        const raterOrganization = form.get('rater_organization').value;
        const raterOrganizationName = form.get('rater_organization_name').value;
        if (!raterOrganization && raterOrganizationName) {
          form
            .get('rater_organization')
            .setErrors({ noCompanyFound: { name: raterOrganizationName } });
        }

        const providerOrganization = form.get('provider_organization').value;
        const providerOrganizationName = form.get(
          'provider_identification'
        ).value;
        if (!providerOrganization && providerOrganizationName) {
          form
            .get('provider_organization')
            .setErrors({ noCompanyFound: { name: providerOrganizationName } });
        }
        return null;
      },
    }
  );
}
