<h2 mat-dialog-title>
  <ng-container *ngIf="!isEditForm">
    Create New <b>User</b>
  </ng-container>
  <ng-container *ngIf="isEditForm">
    Edit <b>User</b>
  </ng-container>
</h2>
<mat-dialog-content *ngIf="initialized">
  <mat-vertical-stepper [linear]="false" [selectedIndex]="selectedIndex" #stepper>
    <mat-step [stepControl]="mainInformationFormGroup">
      <form [formGroup]="mainInformationFormGroup">
        <ng-template matStepLabel>Main Information</ng-template>
        <div class="row mt-3">
          <div class="col-6">
            <mat-form-field class="w-100 mb-2">
              <mat-label>First Name</mat-label>
              <input matInput type="text" placeholder="First Name"
                     formControlName="first_name">
              <mat-error *ngIf="mainInformationFormGroup.controls['first_name'].hasError('required')">
                First name is required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field class="w-100 mb-2">
              <mat-label>Last Name</mat-label>
              <input matInput type="text" placeholder="Last Name"
                     formControlName="last_name">
              <mat-error *ngIf="mainInformationFormGroup.controls['last_name'].hasError('required')">
                Last Name is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <mat-form-field class="w-100 mb-2">
              <mat-label>Title</mat-label>
              <input matInput type="text" placeholder="Title"
                     formControlName="title">
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field class="w-100 mb-2">
              <mat-label>Department</mat-label>
              <input matInput type="text" placeholder="Department"
                     formControlName="department">
            </mat-form-field>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-12 text-center">
            <button matStepperNext mat-raised-button color="accent">Next</button>
          </div>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="contactFormGroup">
      <form [formGroup]="contactFormGroup">
        <ng-template matStepLabel>Contacts</ng-template>
        <div class="row mt-3">
          <div class="col-6">
            <mat-form-field class="w-100 mb-2">
              <mat-label>Email Address</mat-label>
              <input matInput placeholder="Email Address" name="email" formControlName="email">
              <mat-error *ngIf="contactFormGroup.controls['email'].hasError('required')">
                Email Address is required
              </mat-error>
              <mat-error *ngIf="contactFormGroup.controls['email'].hasError('email')">
                Email Address is invalid
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field class="w-100 mb-2">
              <mat-label>Work Phone</mat-label>
              <input matInput placeholder="Work Phone" name="work_phone" formControlName="work_phone"
                     mask="000-000-0000" [showMaskTyped]="true">
              <mat-error *ngIf="contactFormGroup.controls['work_phone'].hasError('required')">
                Phone Number is required
              </mat-error>
              <mat-error *ngIf="contactFormGroup.controls['work_phone'].hasError('pattern')">
                Phone Number is invalid
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
          </div>
          <div class="col-6">
            <mat-form-field class="w-100 mb-2">
              <mat-label>Cell Phone</mat-label>
              <input matInput placeholder="Cell Phone" name="cell_phone" formControlName="cell_phone"
                     mask="000-000-0000" [showMaskTyped]="true">
              <mat-error *ngIf="contactFormGroup.controls['cell_phone'].hasError('required')">
                Phone Number is required
              </mat-error>
              <mat-error *ngIf="contactFormGroup.controls['cell_phone'].hasError('pattern')">
                Phone Number is invalid
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <mat-form-field class="w-100 mb-2">
              <mat-label>Mailing address</mat-label>
              <app-address-input placeholder="Mailing address"
                                 formControlName="mailing_address_geocode"
                                 [show-clear-btn]="true"></app-address-input>
              <mat-hint>
                If not provided than Company Mailing address will use instead
              </mat-hint>
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field class="w-100 mb-2">
              <mat-label>Shipping address</mat-label>
              <app-address-input placeholder="Shipping address"
                                 formControlName="shipping_address_geocode"
                                 [show-clear-btn]="true"></app-address-input>
              <mat-hint>
                If not provided than Mailing address will use instead
              </mat-hint>
            </mat-form-field>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-12 text-center">
            <button matStepperNext mat-raised-button color="accent">Next</button>
          </div>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="raterFormGroup">
      <form [formGroup]="raterFormGroup">
        <ng-template matStepLabel>Rater Information</ng-template>
        <div class="row mt-3">
          <div class="col-6">
            <mat-form-field class="w-100 mb-2">
              <mat-label>Rater Roles</mat-label>
              <app-generic-model-chips placeholder="Add New Role..." formControlName="rater_roles"
                                       modelClassName="rater_role"
                                       [initialValueIds]="entity.rater_roles"></app-generic-model-chips>
            </mat-form-field>
            <mat-form-field class="w-100 mb-2">
              <mat-label>Signature</mat-label>
              <app-file-input formControlName="signature_image" class="w-100 mb-2"></app-file-input>
              <mat-hint *ngIf="entity.signature_image">
                Current file: <a href="{{ entity.signature_image }}"
                                 target="_blank">{{ entity.signature_image.split('/').pop() }}</a>
              </mat-hint>
            </mat-form-field>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-12 text-center">
            <button matStepperNext mat-raised-button color="accent">Next</button>
          </div>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="ngbsFormGroup">
      <form [formGroup]="ngbsFormGroup">
        <ng-template matStepLabel>NGBS Information</ng-template>
        <div class="row mt-3">
          <div class="col-6">
            <mat-form-field class="w-100 mb-2">
              <mat-label>Is QA Designee</mat-label>
              <mat-select formControlName="is_qa_designee">
                <mat-option [value]="true">Yes</mat-option>
                <mat-option [value]="false">No</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-12 text-center">
            <button matStepperNext mat-raised-button color="accent">Next</button>
          </div>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="providerFormGroup">
      <form [formGroup]="providerFormGroup">
        <ng-template matStepLabel>Provider Information</ng-template>
        <div class="row mt-3">
          <div class="col-6">
            <mat-form-field class="w-100 mb-2">
              <mat-label>RESNET Username</mat-label>
              <input matInput type="text" placeholder="RESNET Username"
                     formControlName="resnet_username">
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field class="w-100 mb-2">
              <mat-label>RESNET Password</mat-label>
              <input matInput type="text" placeholder="RESNET Password"
                     formControlName="resnet_password">
            </mat-form-field>
          </div>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="hesFormGroup">
      <form [formGroup]="hesFormGroup">
        <ng-template matStepLabel>HES</ng-template>
        <div class="row mt-3">
          <div class="col-6">
            <mat-form-field class="w-100 mb-2">
              <mat-label>Username</mat-label>
              <input matInput type="text" placeholder="Username"
                     formControlName="username">
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field class="w-100 mb-2">
              <mat-label>Password</mat-label>
              <input matInput type="text" placeholder="Password"
                     formControlName="password">
            </mat-form-field>
          </div>
        </div>
      </form>
    </mat-step>
  </mat-vertical-stepper>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button color="accent" mat-raised-button (click)="save($event)">Save</button>
  <button mat-button [mat-dialog-close]="null" color="primary" mat-raised-button>Cancel</button>
</mat-dialog-actions>
