import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { first, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getInfoUser } from '@/state/selectors/info.selector';
import { AppState } from '@/state/reducers';
import { RemRateUserService } from '@/data/remrate/services/remrateuser.service';
import { RemRateUser } from '@/data/remrate/models';
import { Company } from '@/data/company/models';
import { CompanyService } from '@/data/company/services/company-base.service';

@Injectable({
  providedIn: 'root',
})
export class RemRateUserPermissionService {
  constructor(
    private store: Store<AppState>,
    private companyService: CompanyService,
    private remRateUserService: RemRateUserService
  ) {}

  canCreate(company: Company | number): Observable<ObjectPermissionResponse> {
    let company$: Observable<Company>;
    if (typeof company === 'number') {
      company$ = this.companyService.retrieve(company, { ignoreStore: true });
    } else {
      company$ = of(company);
    }
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      company: company$,
    }).pipe(
      map(({ currentUser, company }) => {
        if (currentUser.is_superuser) {
          return new ObjectPermissionResponse(true);
        }

        if (currentUser.company === company.id) {
          return new ObjectPermissionResponse(true);
        }
        return new ObjectPermissionResponse(
          false,
          'You do not have permissions to edit this record'
        );
      })
    );
  }

  canEdit(
    remRateUser: RemRateUser | number
  ): Observable<ObjectPermissionResponse> {
    let remRateUser$: Observable<RemRateUser>;
    if (typeof remRateUser === 'number') {
      remRateUser$ = this.remRateUserService.retrieve(remRateUser, {
        ignoreStore: true,
      });
    } else {
      remRateUser$ = of(remRateUser);
    }
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: remRateUser$,
    }).pipe(
      map(({ currentUser, entity }) => {
        if (currentUser.is_superuser) {
          return new ObjectPermissionResponse(true);
        }

        if (currentUser.company === entity.company) {
          return new ObjectPermissionResponse(true);
        }
        return new ObjectPermissionResponse(
          false,
          'You do not have permissions to edit this record'
        );
      })
    );
  }

  canDelete(
    remRateUser: RemRateUser | number
  ): Observable<ObjectPermissionResponse> {
    return this.canEdit(remRateUser);
  }
}
