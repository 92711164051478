<div class="container-lg" *ngIf="initialized">
  <div class="row">
    <div class="col-12">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#" [routerLink]="['/', ]">Home</a>
          </li>
          <li class="breadcrumb-item"><a href="#" [routerLink]="['/', 'community']">Communities</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            <app-community-display [community]="community"></app-community-display>
          </li>
        </ol>
      </nav>
    </div>
  </div>
  <div class="row">
    <div class="col-9">
      <div class="d-flex-column mb-2">
        <div class="d-flex flex-row">
          <div class="flex-fill align-self-end">
            <h3 class="mb-0">
              Community:
              <app-community-display [community]="community"></app-community-display>
            </h3>
          </div>
          <div class="text-right">
            <app-history-mat-button [objectId]="community.id" [modelClassName]="'community'" class="mr-1">
              History
            </app-history-mat-button>
            <app-permission-mat-button [permissionResponse]="deletePermissionResponse" (action)="onDelete($event)" class="mr-1" [hideOnFalse]="true">
              Delete
            </app-permission-mat-button>
            <app-permission-mat-button [permissionResponse]="editPermissionResponse" (action)="onEdit($event)">
              Edit
            </app-permission-mat-button>
          </div>
        </div>
        <mat-divider class="mt-1 mb-2"></mat-divider>
        <div class="d-flex flex-row">
          <div class="d-flex-column flex-fill">
            <div class="d-flex flex-row">
              <div class="community-attr-name">City:</div>
              <div class="flex-fill">{{ community.city_info?.name }} </div>
            </div>
            <div class="d-flex flex-row">
              <div class="community-attr-name">Country:</div>
              <div class="flex-fill">{{ community.city_info?.country_info?.name }}</div>
            </div>
            <div class="d-flex flex-row">
              <div class="community-attr-name">Metro:</div>
              <div class="flex-fill">{{ community.metro_info?.name }}</div>
              <span *ngIf="!community.city_info?.county_info?.state">-</span>
            </div>
            <div class="d-flex flex-row">
              <div class="community-attr-name">State:</div>
              <div class="flex-fill">{{ community.city_info?.county_info?.state }}</div>
              <span *ngIf="!community.city_info?.county_info?.state">-</span>
            </div>
            <div class="d-flex flex-row">
              <div class="community-attr-name">Crossroads:</div>
              <span class="flex-fill" *ngIf="community.cross_roads !== ''">{{ community.cross_roads }}</span>
              <span *ngIf="community.cross_roads === ''">-</span>
            </div>
            <div class="d-flex flex-row">
              <div class="community-attr-name">Multi-family:</div>
              <span class="flex-fill" *ngIf="community.is_multi_family !== false">{{ community.is_multi_family }}</span>
              <span *ngIf="!community.is_multi_family">-</span>
            </div>
            <div class="mt-2"></div>
            <div class="d-flex flex-row">
              <div class="community-attr-name">AXIS ID:</div>
              <div class="flex-fill">{{ community.id }}</div>
            </div>
            <div class="mt-2"></div>
            <div class="d-flex flex-row">
              <div class="community-attr-name">Website:</div>
              <div class="flex-fill">
                <a href="{{ community.website }}" target="_blank" *ngIf="community.website">
                  {{ community.website }}
                </a>
                <span *ngIf="!community.website">-</span>
              </div>
            </div>
          </div>
          <div class="d-flex-column" style="max-width: 480px; min-width: 450px;">
              <div class="card w-100 h-100" *ngIf="community.city_info?.latitude && community.city_info?.longitude; else mapAddressNotValid;">
                <div class="card-content w-100 h-100">
                  <google-map
                      height="350px"
                      width="100%"
                      [zoom]="16"
                      [center]="mapCenter"
                      [options]="mapOptions"
                  >
                    <map-marker
                        #markerElem="mapMarker"
                        *ngFor="let marker of mapMarkers"
                        [position]="marker.position"
                        [label]="marker.label"
                        [title]="marker.title"
                        [options]="marker.options"
                        (mapClick)="mapOpenInfo(markerElem, marker.info)"
                    >
                    </map-marker>
                    <map-info-window>{{ mapInfoContent }}</map-info-window>
                  </google-map>
                </div>
              </div>
              <ng-template #mapAddressNotValid>
                <div class="d-flex flex-column flex-fill justify-content-center not-confirmed-address-container">
                  <div class="p-3 text-muted">
                    Axis was unable to confirm and map this address. Please verify the accuracy of the address.
                    If the address entered is correct, it may be too new for our mapping engines to find it.
                    <fa-icon [icon]="['fas', 'map-marker']"></fa-icon>
                  </div>
                </div>
              </ng-template>
          </div>
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="well">
        <h4>Associated Companies</h4>
        <dl id="relations">
          <ng-container *ngFor="let relationshipItem of relationshipRows">
            <dt>{{ componentCompanyTypeDataMap[relationshipItem?.company_info?.company_type].displayName }}</dt>
            <dd>
              <app-company-display displayType="onlyNameAsLink" [company]="relationshipItem?.company_info"></app-company-display>
              <span class="icons">
                <i class="fa fa-trash" data-toggle="tooltip" title="Delete an association for this company"></i>
              </span>
            </dd>
          </ng-container>
        </dl>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <mat-tab-group color="accent" style="min-height: 450px;" disableRipple="true" animationDuration="0ms"
                     dynamicHeight (selectedTabChange)="onTabChange($event)" [selectedIndex]="selectedIndex">
        <mat-tab label="Subdivisions">          
          <div class="mt-2 mb-5">
            <app-community-subdivision-tab></app-community-subdivision-tab>
          </div>
        </mat-tab>
        <mat-tab label="Companies">
          <div class="mt-2 mb-5">
            <!-- <app-company-counties-tab></app-company-counties-tab> -->
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
