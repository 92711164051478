<div class="container-fluid">
    <form [formGroup]="formGroup">
        <div class="row">
            <div class="col-6">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Representing Countries</mat-label>
                    <app-generic-model-chips placeholder="Add New Country..." formControlName="countries" modelClassName="country" [initialValueIds]="company.countries"></app-generic-model-chips>
                    <mat-error *ngIf="formGroup.get('countries').hasError('required')">
                        This field is required
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>
    <div class="row">
        <div class="col-12">
            <p>
                <b>Representing US Counties:</b>
            </p>
            <p>
                <mat-hint>
                    <span class="text-danger">*</span>
                    Select ALL counties in state by clicking on checkbox or click on arrow to select an individual county/counties
                </mat-hint>
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <mat-accordion *ngIf="initialized else loading">
                <mat-expansion-panel *ngFor="let item of aggregatedCountiesByState" (opened)="loadCounties(item)">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-checkbox [checked]="item.selected_counties == item.counties"
                                          [indeterminate]="item.selected_counties > 0 && item.selected_counties != item.counties"
                                          (click)="$event.stopPropagation();"
                                          (keydown)="$event.stopPropagation()"
                                          (change)="stateSelected($event, item)"
                                          class="mr-1"
                            >
                            </mat-checkbox>
                            <span style="margin-top: -3px;">
                                {{ item.state_name }} ({{ item.selected_counties }}/{{ item.counties }})
                            </span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                        <mat-selection-list *ngIf="!countiesSectionIsLoading else countiesSectionLoading" [(ngModel)]="selectedCounties" [compareWith]="countiesCompareFunction" (selectionChange)="countySelected($event)">
                            <mat-list-option *ngFor="let item of counties" [value]="item">
                                {{ item.name }}
                            </mat-list-option>
                        </mat-selection-list>
                        <ng-template #countiesSectionLoading>
                            <div class="row">
                                <div class="col-12">
                                    <div class="d-flex justify-content-center" style="height: 320px;">
                                        <mat-spinner [diameter]="50"></mat-spinner>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-template>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>

    <ng-template #loading>
        <div class="row">
            <div class="col-12">
                <div class="d-flex justify-content-center" style="height: 320px;">
                    <mat-spinner [diameter]="50"></mat-spinner>
                </div>
            </div>
        </div>
    </ng-template>
</div>
