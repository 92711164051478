import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '@/data/core/models/user';
import {
  HIRLProjectAddMF,
  HIRLProjectCommercialSpaceTypeLabelMapping,
  HIRLProjectRegistration,
} from '@/data/customer-hirl/models';
import { forkJoin, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { HIRLProjectRegistrationService } from '@/data/customer-hirl/services/hirl-project-registration.service';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { HIRLProjectService } from '@/data/customer-hirl/services/hirl-projects.service';
import { NavigationService } from '@/shared/services/navigation-service.service';
import { toggleLoading } from '@/state/actions/app.actions';
import { first, takeUntil } from 'rxjs/operators';
import { getInfoUser } from '@/state/selectors/info.selector';
import { hirlProjectAddressExistsValidator } from '@/modules/customer-hirl/field-validators';
import { CreateMFHIRLProject } from '@/data/customer-hirl/models/hirl-project-registration';

@Component({
  selector: 'app-hirl-project-add-stand-alone-commercial-space',
  templateUrl: './hirl-project-add-stand-alone-commercial-space.component.html',
  styleUrls: ['./hirl-project-add-stand-alone-commercial-space.component.scss'],
})
export class HirlProjectAddStandAloneCommercialSpaceComponent
  implements OnInit, OnDestroy
{
  protected readonly HIRLProjectCommercialSpaceTypeLabelMapping =
    HIRLProjectCommercialSpaceTypeLabelMapping;
  public initialized = false;

  public formGroup: FormGroup;

  public currentUser: User;
  public hirlProjectRegistration: HIRLProjectRegistration;

  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private hirlProjectRegistrationService: HIRLProjectRegistrationService,
    private uiHelperService: UIHelperService,
    private hirlProjectService: HIRLProjectService,
    private navigation: NavigationService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(toggleLoading({ payload: true }));

    this.activatedRoute.params
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(params => {
        forkJoin({
          hirlProjectRegistration: this.hirlProjectRegistrationService.retrieve(
            params.projectRegistrationId
          ),
          currentUser: this.store.select(getInfoUser).pipe(first()),
        }).subscribe(
          ({ hirlProjectRegistration, currentUser }) => {
            this.currentUser = currentUser;
            this.hirlProjectRegistration = hirlProjectRegistration;

            this.initialized = true;
            this.store.dispatch(toggleLoading({ payload: false }));
          },
          error => this.uiHelperService.handleMainRequestError(error)
        );
      });

    this.setupFormGroup();
  }

  public ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  public setupFormGroup() {
    this.formGroup = this.fb.group({
      address: [
        null,
        Validators.required,
        [
          hirlProjectAddressExistsValidator(
            this.hirlProjectService,
            null,
            false
          ),
        ],
      ],
      commercial_space_type: [null, Validators.required],
      total_commercial_space: [null, Validators.required],
    });
  }

  handleCreate($event: MouseEvent) {
    $event.preventDefault();

    const formData = new HIRLProjectAddMF();
    const projectFormData = new CreateMFHIRLProject();
    projectFormData.lot_number = this.formGroup.value.address.lotNumber;
    projectFormData.home_address_geocode =
      this.formGroup.value.address.geocode.id;
    projectFormData.home_address_geocode_response = null;

    if (this.formGroup.value.address.geocodeBrokeredResponse) {
      projectFormData.home_address_geocode_response =
        this.formGroup.value.address.geocodeBrokeredResponse.id;
    }

    projectFormData.story_count = 1;
    projectFormData.number_of_units = 1;
    projectFormData.green_energy_badges = [];

    projectFormData.is_include_commercial_space = true;
    projectFormData.commercial_space_type =
      this.formGroup.value.commercial_space_type;
    projectFormData.total_commercial_space =
      this.formGroup.value.total_commercial_space;

    formData.projects = [projectFormData];

    this.store.dispatch(toggleLoading({ payload: true }));
    this.hirlProjectRegistrationService
      .hirl_projects_create_multi_family(
        this.hirlProjectRegistration.id,
        formData
      )
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(
        _ => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.router.navigate([
            `/hi/project_registrations/`,
            this.hirlProjectRegistration.id,
          ]);
        },
        error => this.uiHelperService.handleUserRequestError(error)
      );
  }
}
