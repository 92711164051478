import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { first, map, mergeMap, takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { getInfoUser } from '@/state/selectors/info.selector';
import { CustomerHirlCOIDashboard, User } from '@/data/core/models/user';
import {
  IExcelJson,
  UIHelperService,
} from '@/shared/services/ui-helper.service';
import * as _ from 'lodash';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import {
  HIRLCOIDashboardUserRequestParams,
  UserRequestParams,
  UserService,
} from '@/data/core/services/user.service';
import { formatDate } from '@angular/common';
import { toggleLoading } from '@/state/actions/app.actions';

@Component({
  selector: 'app-hirl-coi-dashboard',
  templateUrl: './hirl-coi-dashboard.component.html',
  styleUrls: ['./hirl-coi-dashboard.component.scss'],
})
export class HirlCoiDashboardComponent implements OnInit, OnDestroy {
  public displayedColumns: string[] = [
    'first_name',
    'company__name',
    'general_liability',
    'professional_liability',
    'automobile_liability',
    'umbrella_liability',
    'workers_compensation',
  ];

  public initialized = false;
  public rows: CustomerHirlCOIDashboard[];
  public rowsCount: number;

  public isLoading = true;

  public currentUser: User;
  public filterFromGroup: FormGroup;

  public defaultParams: HIRLCOIDashboardUserRequestParams =
    new HIRLCOIDashboardUserRequestParams(1, '', '-id');
  public storedParams: UserRequestParams = Object.assign(
    new HIRLCOIDashboardUserRequestParams(),
    this.defaultParams
  );

  private listSubscription$: Subscription;
  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private userService: UserService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private uiHelperService: UIHelperService
  ) {}

  ngOnInit() {
    this.setupFilterForm();

    this.activatedRoute.params
      .pipe(
        takeUntil(this.componentDestroyed$),
        mergeMap(params => {
          this.rows = [];
          this.rowsCount = 0;

          return forkJoin({
            queryParams: this.activatedRoute.queryParams.pipe(first()),
            currentUser: this.store.select(getInfoUser).pipe(first()),
          }).pipe(
            map(({ queryParams, currentUser }, index) => {
              return { params, queryParams, currentUser };
            })
          );
        })
      )
      .subscribe(({ params, queryParams, currentUser }) => {
        this.storedParams.page_size =
          this.uiHelperService.getUISettings().rowsPerPage;
        this.storedParams.assignQueryParams(queryParams);
        this.currentUser = currentUser;

        this.initialized = true;

        this.hydrateForm();
        this.list();
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
  setupFilterForm() {
    this.filterFromGroup = this.fb.group({
      search: [null],
      coi_requirements_met: [''],
    });

    this.filterFromGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        const params = _.merge({}, this.defaultParams, values);
        delete params.page_size;
        delete params.ordering;

        if (params.company_info) {
          params.company = params.company_info.id;
        } else {
          params.company = null;
        }
        delete params.company_info;

        this.storedParams = Object.assign(this.storedParams, params);
        this.list();
      });
  }

  list() {
    this.isLoading = true;

    // populate params to query string
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.storedParams.toQueryParams(),
      replaceUrl: true,
    });

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }

    this.listSubscription$ = this.userService
      .customer_hirl_coi_dashboard_list(this.storedParams)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(data => {
        this.rows = data.results;
        this.rowsCount = data.count;

        this.isLoading = false;
      });
  }

  hydrateForm() {
    this.filterFromGroup.patchValue(this.storedParams, {
      emitEvent: false,
      onlySelf: true,
    });
  }

  resetFilters($event: MouseEvent) {
    $event.preventDefault();
    this.filterFromGroup.reset();
  }

  onPaginateChange($event: PageEvent) {
    this.uiHelperService.getUISettings().rowsPerPage = $event.pageSize;
    this.storedParams.page_size = $event.pageSize;
    this.storedParams.page = $event.pageIndex + 1;
    this.list();
  }

  onSortChange($event: Sort) {
    this.storedParams.ordering = `${$event.direction === 'desc' ? '-' : ''}${
      $event.active
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  exportToCSV($event: MouseEvent) {
    $event.preventDefault();
    const data = [];

    this.rows.forEach(row => {
      let general_liability = '';
      let professional_liability = '';
      let automobile_liability = '';
      let umbrella_liability = '';
      let workers_compensation = '';

      if (row.general_liability) {
        general_liability = formatDate(
          row.general_liability,
          'MM/dd/yyyy',
          'en-US'
        );
      }
      if (row.professional_liability) {
        professional_liability = formatDate(
          row.professional_liability,
          'MM/dd/yyyy',
          'en-US'
        );
      }

      if (row.automobile_liability) {
        automobile_liability = formatDate(
          row.automobile_liability,
          'MM/dd/yyyy',
          'en-US'
        );
      }
      if (row.umbrella_liability) {
        umbrella_liability = formatDate(
          row.umbrella_liability,
          'MM/dd/yyyy',
          'en-US'
        );
      }
      if (row.workers_compensation) {
        workers_compensation = formatDate(
          row.workers_compensation,
          'MM/dd/yyyy',
          'en-US'
        );
      }

      data.push({
        'Verifier Name': `${row.first_name} ${row.last_name}`,
        'Company Name': `${row.company_info?.name}`,
        'General Liability': general_liability,
        'Professional Liability': professional_liability,
        Auto: automobile_liability,
        Umbrella: umbrella_liability,
        'Workers Comp': workers_compensation,
      });
    });
    this.uiHelperService.exportToCsv(data, 'cois', [
      'Verifier Name',
      'Company Name',
      'General Liability',
      'Professional Liability',
      'Auto',
      'Umbrella',
      'Workers Comp',
    ]);
  }

  exportToExcel($event: MouseEvent) {
    $event.preventDefault();
    const excelData: Array<IExcelJson> = [];
    const udt: IExcelJson = {
      data: [
        {
          A: 'Verifier Name',
          B: 'Company Name',
          C: 'General Liability',
          D: 'Professional Liability',
          E: 'Auto',
          F: 'Umbrella',
          G: 'Workers Comp',
        }, // table header
      ],
      skipHeader: true,
    };
    this.rows.forEach(row => {
      let general_liability = '';
      let professional_liability = '';
      let automobile_liability = '';
      let umbrella_liability = '';
      let workers_compensation = '';

      if (row.general_liability) {
        general_liability = formatDate(
          row.general_liability,
          'MM/dd/yyyy',
          'en-US'
        );
      }
      if (row.professional_liability) {
        professional_liability = formatDate(
          row.professional_liability,
          'MM/dd/yyyy',
          'en-US'
        );
      }

      if (row.automobile_liability) {
        automobile_liability = formatDate(
          row.automobile_liability,
          'MM/dd/yyyy',
          'en-US'
        );
      }
      if (row.umbrella_liability) {
        umbrella_liability = formatDate(
          row.umbrella_liability,
          'MM/dd/yyyy',
          'en-US'
        );
      }
      if (row.workers_compensation) {
        workers_compensation = formatDate(
          row.workers_compensation,
          'MM/dd/yyyy',
          'en-US'
        );
      }

      udt.data.push({
        A: `${row.first_name} ${row.last_name}`,
        B: `${row.company_info?.name}`,
        C: general_liability,
        D: professional_liability,
        E: automobile_liability,
        F: umbrella_liability,
        G: workers_compensation,
      });
    });
    excelData.push(udt);

    this.uiHelperService.exportJsonToExcel(excelData, 'cois');
  }

  exportAll($event: MouseEvent) {
    $event.preventDefault();
    this.store.dispatch(toggleLoading({ payload: true }));
    const newTab = window.open('', '_blank');
    this.userService
      .customer_hirl_coi_dashboard_report(this.storedParams)
      .subscribe(
        asynchronousProcessedDocument => {
          newTab.location.href = `/file-operation/document/${asynchronousProcessedDocument.id}`;
          newTab.focus();
          this.store.dispatch(toggleLoading({ payload: false }));
        },
        error => {
          newTab.close();
          this.uiHelperService.handleUserRequestError(error);
        }
      );
  }
}
