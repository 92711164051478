<div class="container-lg">
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-6">
          <h4>
            <span *ngIf="!isLoading">{{ rowsCount | number }}</span>
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" *ngIf="isLoading"></fa-icon>
            UDRH Models
          </h4>
        </div>
        <div class="col-6 text-right">
          <button mat-raised-button [color]="'accent'" (click)="createFromFile($event);">
            Create New
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <hr>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <div class="row">
            <div class="col-12">
              <form [formGroup]="filterFromGroup" class="card">
                <h5 class="card-header">
                  Filters
                  <a href="#" class="small" (click)="resetFilters($event);">Reset filters</a>
                </h5>
                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <mat-form-field class="w-100">
                        <mat-label>Search</mat-label>
                        <app-search-input formControlName="search" placeholder="Type to search something"></app-search-input>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-9">
          <div class="row" *ngIf="isLoading">
            <div class="col-12">
              <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
            </div>
          </div>
          <table mat-table
                 [dataSource]="rows"
                 class="table"
                 matSort
                 [matSortActive]="storedParams.toTableSort().active"
                 [matSortDirection]="storedParams.toTableSort().direction"
                 (matSortChange)="onSortChange($event)">

            <ng-container matColumnDef="short_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
              <td mat-cell *matCellDef="let row">
                {{ row.short_name }}
<!--                <a [routerLink]="['/', 'home', 'apex', 'request', 'utility-detail', row.id]" matTooltip="View UDRH Model">-->
<!--                  {{ row.short_name }}-->
<!--                </a>-->
              </td>
            </ng-container>

            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef>Description</th>
              <td mat-cell *matCellDef="let row">
                {{ row.description }}
              </td>
            </ng-container>

            <ng-container matColumnDef="reference_analysis">
              <th mat-header-cell *matHeaderCellDef>Reference analysis</th>
              <td mat-cell *matCellDef="let row">
                {{ row.reference_analysis }}
              </td>
            </ng-container>

            <ng-container matColumnDef="design_analysis">
              <th mat-header-cell *matHeaderCellDef>Design analysis</th>
              <td mat-cell *matCellDef="let row">
                {{ row.design_analysis }}
              </td>
            </ng-container>


            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>Actions</th>
              <td mat-cell *matCellDef="let row">
<!--                <a [routerLink]="['/', 'home', 'apex', 'request', 'utility-detail', row.id]" target="_blank" class="mr-2">-->
<!--                  <fa-icon [icon]="['fas', 'external-link-alt']" matTooltip="View UDRH Model in new window"></fa-icon>-->
<!--                </a>-->

<!--                <app-permission-mat-button [permissionResponse]="permissions[row.id]['canDelete']" displayType="asLink" (action)="delete($event, row);" class="mr-2">-->
<!--                  <fa-icon [icon]="['fas', 'trash']" matTooltip="Delete"></fa-icon>-->
<!--                </app-permission-mat-button>-->
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>

          <div class="d-flex flex-row">
            <div class="align-self-center">

            </div>
            <div class="d-flex flex-fill justify-content-end">
              <mat-paginator [length]="rowsCount"
                             [pageIndex]="$any(storedParams.page) - 1"
                             [pageSize]="storedParams.page_size"
                             (page)="onPaginateChange($event)"
                             aria-label="Select page">
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
