import { Component, Input, OnInit } from '@angular/core';
import {
  HIRLProject,
  HIRLProjectRegistrationType,
  LandDevelopmentProjectType,
} from '@/data/customer-hirl/models';

@Component({
  selector: 'app-hirl-project-type',
  templateUrl: './hirl-project-type.component.html',
  styleUrls: ['./hirl-project-type.component.scss'],
})
export class HIRLProjectTypeComponent implements OnInit {
  @Input() project: HIRLProject;

  public projectRegistrationType = HIRLProjectRegistrationType;
  public landDevelopmentProjectType = LandDevelopmentProjectType;

  ngOnInit(): void {}
}
