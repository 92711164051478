<div class="dropdown">
  <button (click)="onCollapse($event)" class="bell-container">
    <fa-icon [icon]="['fas', 'spinner']" [spin]="true" class="nav-icon" *ngIf="!initialized || isLoading"></fa-icon>
    <fa-icon [icon]="['fas', 'bell']" class="nav-icon" *ngIf="initialized && !isLoading"></fa-icon>
    <ng-container>
      <span class="label message-count" *ngIf="entities.length === 0">{{ entitiesCount }}</span>
      <span class="label message-count active" *ngIf="entities.length > 0">{{ entitiesCount }}+</span>
    </ng-container>
  </button>

  <div class="dropdown-menu dropdown-menu-right"
       [ngClass]="{'show': !isCollapsed}"
       #messageDropdown>

    <ng-container *ngIf="initialized">
      <div class="row">
        <div class="col-6">
          <div class="pl-3 pt-2">
            <button mat-button *ngIf="entities.length > 0" (click)="onMarkAllAsRead($event)">Mark All as Read</button>
          </div>
        </div>
        <div class="col-6 text-right">
          <div class="pr-3 pt-2">
            <a mat-button [routerLink]="['/', 'messaging']">View All</a>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="isLoading">
        <div class="col-12">
          <mat-progress-bar mode="query"></mat-progress-bar>
        </div>
      </div>
      <div class="container message-list-container">
        <div class="row text-center" *ngIf="entities.length == 0">
          <div class="col-12">
            No unread messages
          </div>
        </div>
        <ng-container *ngFor="let entity of entities">
          <div class="row modern-message-container"
               [ngStyle]="{'background': MessageLevelLabelMapping[entity.level]?.backgroundColor, 'color': MessageLevelLabelMapping[entity.level]?.color}">
            <div class="col-2 message-icon-col">
              <fa-icon [icon]="['fas', MessageLevelLabelMapping[entity.level]?.icon]"
                       *ngIf="MessageLevelLabelMapping[entity.level]?.icon" size="2x"></fa-icon>
            </div>
            <div class="col-8 message-body-col">
              <h5 class="title">{{ entity.title }}</h5>
              <div [innerHTML]="entity.content"></div>
              <div class="row">
                <div class="col-12">
                  <div class="small text-muted">
                    <span> {{ entity.date_created | date: 'MM/dd/yyy HH:MM' }}</span>
                    <span> • </span>
                    <span> {{ MessageLevelLabelMapping[entity.level]?.label }}</span>
                    <span> • </span>
                    <span class="badge badge-secondary">{{ entity.category }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-2 message-actions-col">
              <div>
                <a (click)="onRead($event, entity)" [matTooltip]="'Mark as Read'" class="action">
                  <fa-icon [icon]="['fas', 'check']"></fa-icon>
                </a>
              </div>
              <div>
                <a [routerLink]="entity.url" target="_blank" [matTooltip]="'Go to page'" *ngIf="entity.url" class="action">
                  <fa-icon [icon]="['fas', 'external-link-alt']"></fa-icon>
                </a>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="!initialized">
      <div class="row">
        <div class="col-12">
          <div class="p-5 text-center">
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" size="5x"></fa-icon>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
