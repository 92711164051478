import { createSelector } from '@ngrx/store';
import { SimulationState } from '../reducers';
import { selectSimulationFeature } from '../simulation.selectors';
import { FoundationWallBackendDict } from '@/data/simulation/models/enclosure/FoundationWall';

export const selectFoundationWall = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.foundationWall.entities[id]
  );
const selectFoundationWallLoadingStatus = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.foundationWall.loading[id]
  );

export const selectFoundationWallTypeId = (id: number) =>
  createSelector(
    selectFoundationWall(id),
    foundationWall => foundationWall.type
  );

const selectFoundationWallErrors = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.foundationWall.errors[id]
  );

export const selectFoundationWallViewSet = (id: number) =>
  createSelector(
    selectFoundationWall(id),
    selectFoundationWallLoadingStatus(id),
    (foundationWall, loadingStatus) => ({
      data: foundationWall,
      loading: loadingStatus,
    })
  );

export const selectFoundationWallName = (id: number) =>
  createSelector(
    selectFoundationWall(id),
    (foundationWall: FoundationWallBackendDict) => foundationWall.name
  );

export const selectFoundationWallNestedErrorCount = (id: number) =>
  createSelector(
    selectSimulationFeature,
    selectFoundationWallErrors(id),
    selectFoundationWallTypeId(id),
    (state, wallErrors, wallTypeId) => {
      const wallTypeErrorCount = {
        errorCount: 0,
        warningCount: 0,
      };
      if (wallTypeId !== null) {
        const wallTypeErrors = state.foundationWallType.errors[wallTypeId];
        wallTypeErrorCount.errorCount = wallTypeErrors.errorCount;
        wallTypeErrorCount.warningCount = wallTypeErrors.warningCount;
      }
      return {
        foundationWall: {
          errorCount: wallErrors.errorCount,
          warningCount: wallErrors.warningCount,
        },
        foundationWallType: wallTypeErrorCount,
      };
    }
  );

export const selectFoundationWallErrorViewSet = (id: number) =>
  createSelector(
    selectFoundationWallNestedErrorCount(id),
    selectFoundationWallTypeId(id),
    selectFoundationWallName(id),
    (nestedModelsErrorCount, wallTypeId, name) => ({
      nestedModelsErrorCount,
      wallTypeId,
      name,
    })
  );
