import { resetSimulation } from '@/state/actions/floorplan/simulation.actions';
import { filter, takeUntil } from 'rxjs/operators';
import { getSimulation } from '@/state/selectors/floorplan/simulation.selector';
import { Simulation } from '@/data/simulation/models';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-enclosure-skylights',
  templateUrl: './enclosure-skylights.component.html',
  styleUrls: ['./enclosure-skylights.component.scss'],
})
export class EnclosureSkylightsComponent implements OnInit {
  public initialized = false;
  public invalidResponse = false;
  public simulation: Simulation;
  public count: number;
  public skylights: object[];

  private componentDestroyed$ = new Subject();

  constructor(public store: Store<AppState>) {}

  ngOnInit(): void {
    this.store
      .select(getSimulation)
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(val => val != null)
      )
      .subscribe(
        simulation => {
          this.skylights = simulation.skylights_info;
          this.count = this.skylights ? this.skylights.length : 0;
          this.initialized = true;
        },
        error => {
          this.invalidResponse = true;
          this.initialized = true;
        }
      );
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
    this.store.dispatch(resetSimulation());
  }
}
