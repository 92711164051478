import { createSelector } from '@ngrx/store';
import { SimulationState } from '../reducers';
import { selectSimulationFeature } from '../simulation.selectors';

export const selectRoofType = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.roofType.entities[id]
  );

const selectRoofTypeLoadingStatus = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.roofType.loading[id]
  );

export const selectRoofTypeViewSet = (id: number) =>
  createSelector(
    selectRoofType(id),
    selectRoofTypeLoadingStatus(id),
    (roofType, loadingStatus) => ({
      data: roofType,
      loading: loadingStatus,
    })
  );

const selectRoofTypeName = (id: number) =>
  createSelector(selectRoofType(id), roofType => roofType.name);

const selectRoofTypeErrorCount = (id: number) =>
  createSelector(selectSimulationFeature, (state: SimulationState) => {
    return state.roofType.errors[id].errorCount;
  });

export const selectRoofTypeNameAndError = (id: number) =>
  createSelector(
    selectRoofTypeName(id),
    selectRoofTypeErrorCount(id),
    (name, errorCount) => {
      return {
        name,
        errorCount,
      };
    }
  );
