import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { first, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getInfoUser } from '@/state/selectors/info.selector';
import { AppState } from '@/state/reducers';
import {
  PaymentRequest,
  PaymentRequestState,
} from '@/data/customer-hirl/models';
import { PaymentRequestService } from '@/data/customer-hirl/services/payment-request.service';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';
import { BasePermissionService } from '@/core/services/base-permission-service';

@Injectable({
  providedIn: 'root',
})
export class PaymentRequestPermissionService extends BasePermissionService {
  constructor(
    public store: Store<AppState>,
    private paymentRequestService: PaymentRequestService
  ) {
    super(store);
    this.serviceCls = this.paymentRequestService;
  }

  canCreate(): Observable<ObjectPermissionResponse> {
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
    }).pipe(
      map(({ currentUser }) => {
        return new ObjectPermissionResponse(true);
      })
    );
  }

  canEdit(
    paymentRequest: PaymentRequest | number
  ): Observable<ObjectPermissionResponse> {
    const paymentRequest$: Observable<PaymentRequest> =
      this.resolveEntity<PaymentRequest>(paymentRequest);
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: paymentRequest$,
    }).pipe(
      map(({ currentUser, entity }) => {
        return new ObjectPermissionResponse(false);
      })
    );
  }

  canDelete(
    paymentRequest: PaymentRequest | number
  ): Observable<ObjectPermissionResponse> {
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
    }).pipe(
      map(({ currentUser }) => {
        return new ObjectPermissionResponse(true);
      })
    );
  }

  canApprove(
    paymentRequest: PaymentRequest | number
  ): Observable<ObjectPermissionResponse> {
    const paymentRequest$: Observable<PaymentRequest> =
      this.resolveEntity<PaymentRequest>(paymentRequest);

    return forkJoin({
      entity: paymentRequest$,
      currentUser: this.store.select(getInfoUser).pipe(first()),
    }).pipe(
      map(({ currentUser, entity }) => {
        if (
          currentUser.company_info?.slug === CustomerHIRLSettings.companySlug
        ) {
          if (entity.state === PaymentRequestState.new) {
            return new ObjectPermissionResponse(true);
          }
        }
        return new ObjectPermissionResponse(false);
      })
    );
  }

  canReject(
    paymentRequest: PaymentRequest | number
  ): Observable<ObjectPermissionResponse> {
    const paymentRequest$: Observable<PaymentRequest> =
      this.resolveEntity<PaymentRequest>(paymentRequest);

    return forkJoin({
      entity: paymentRequest$,
      currentUser: this.store.select(getInfoUser).pipe(first()),
    }).pipe(
      map(({ currentUser, entity }) => {
        if (
          currentUser.company_info?.slug === CustomerHIRLSettings.companySlug
        ) {
          if (entity.state === PaymentRequestState.new) {
            return new ObjectPermissionResponse(true);
          }
        }
        return new ObjectPermissionResponse(false);
      })
    );
  }
}
