import { Injectable } from '@angular/core';
import { ListRequestParams } from '@/core/schemes/request-params';
import { environment } from '../../../../environments/environment';
import { BaseDataService } from '@/core/services/base-data-service';
import { Observable } from 'rxjs';
import { DuctBackendDict } from '../models/mechanicals/Duct';

export class DuctParams extends ListRequestParams {}

@Injectable({ providedIn: 'root' })
export class DuctService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/duct/`;
  public parentUrl = `${environment.AXIS_API_V3_ROOT}/distribution_system/`;

  create(
    distributionSystemId: number,
    data: Partial<DuctBackendDict>
  ): Observable<DuctBackendDict> {
    return this.http.post<DuctBackendDict>(
      `${this.parentUrl}${distributionSystemId}/ducts/`,
      data
    );
  }

  update(ductChanges: Partial<DuctBackendDict>): Observable<DuctBackendDict> {
    return this.http.patch<DuctBackendDict>(
      `${this.baseUrl}${ductChanges.id}/`,
      ductChanges
    );
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}${id}/`);
  }
}
