import { ListRequestParams } from '@/core/schemes/request-params';
import { Injectable } from '@angular/core';
import {
  BaseDataService,
  IDataServiceMethodOptions,
} from '@/core/services/base-data-service';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { PracticeItem } from '@/data/customer-hirl/models';
import { ServerResponse } from '@/core/schemes/server-response';

export class PracticeItemRequestParams extends ListRequestParams {
  practice?: number;
}

@Injectable({
  providedIn: 'root',
})
export class PracticeItemService extends BaseDataService {
  baseUrl = `${environment.AXIS_API_V3_ROOT}/practice_items/`;

  list(params?: PracticeItemRequestParams) {
    return this.http.get<ServerResponse<PracticeItem>>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }

  retrieve(
    practiceItemId: number,
    options?: IDataServiceMethodOptions
  ): Observable<PracticeItem> {
    return this.http.get<PracticeItem>(`${this.baseUrl}${practiceItemId}`, {});
  }

  update(practiceItem: PracticeItem, practiceItemId: number) {
    return this.http.patch<PracticeItem>(
      `${this.baseUrl}${practiceItemId}/`,
      practiceItem
    );
  }

  delete(practiceItemId: number) {
    return this.http.delete(`${this.baseUrl}${practiceItemId}`);
  }
}
