import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from '@/data/core/models/user';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  CustomerHIRLBulkCertificateEEPProgramHomeStatusDownload,
  CustomerHIRLBulkCertificateEEPProgramHomeStatusList,
} from '@/data/home/models/eep_program_home_status';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationService } from '@/shared/services/navigation-service.service';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { first, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { getInfoUser } from '@/state/selectors/info.selector';
import {
  EEPProgramHomeStatusRequestParams,
  EEPProgramHomeStatusService,
} from '@/data/home/services/eep_program_home_status.service';
import {
  CompanyAccessRequestParams,
  CompanyAccessService,
} from '@/data/company/services/company-access.service';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { UniqueSet } from '@/core/utils';
import { ServerResponse } from '@/core/schemes/server-response';
import { HttpErrorResponse } from '@angular/common/http';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { OkDialogComponent } from '@/shared/components/ok-dialog/ok-dialog.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { toggleLoading } from '@/state/actions/app.actions';
import { CustomerHIRLProductList } from '@/data/product/models';
import { AsynchronousProcessedDocument } from '@/data/filehandling/models';
import { AsynchronousProcessedDocumentStatusDialogComponent } from '@/modules/filehandling/components/asynchronous-processed-document-status-dialog/asynchronous-processed-document-status-dialog.component';

@Component({
  selector: 'app-hirl-bulk-certificate-download',
  templateUrl: './hirl-bulk-certificate-download.component.html',
  styleUrls: ['./hirl-bulk-certificate-download.component.scss'],
})
export class HirlBulkCertificateDownloadComponent implements OnInit, OnDestroy {
  public selectAllEntities = false;

  public displayedColumns = [];

  public initialized = false;

  public createPermissionResponse: Observable<ObjectPermissionResponse>;

  public filterFromGroup: FormGroup;

  public currentUser: User;

  public entities: CustomerHIRLBulkCertificateEEPProgramHomeStatusList[];
  public entitiesCount: number;
  public entitiesIsLoading = true;

  public selectedEntities: UniqueSet<CustomerHIRLBulkCertificateEEPProgramHomeStatusList> =
    new UniqueSet<CustomerHIRLBulkCertificateEEPProgramHomeStatusList>(
      (item: CustomerHIRLBulkCertificateEEPProgramHomeStatusList) => item.id
    );

  public defaultParams: EEPProgramHomeStatusRequestParams =
    new EEPProgramHomeStatusRequestParams(1, '', '-created_date', 25);
  public storedParams: EEPProgramHomeStatusRequestParams =
    new EEPProgramHomeStatusRequestParams(1, '', '-created_date', 25);

  private listSubscription$: Subscription;
  private componentDestroyed$ = new Subject();

  constructor(
    public store: Store<AppState>,
    public eepProgramHomeStatusService: EEPProgramHomeStatusService,
    public router: Router,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private companyAccessService: CompanyAccessService,
    private activatedRoute: ActivatedRoute,
    private navigation: NavigationService,
    private uiHelperService: UIHelperService
  ) {
    const uiSettings = this.uiHelperService.getUISettings();

    this.defaultParams = new EEPProgramHomeStatusRequestParams(
      1,
      '',
      '-id',
      uiSettings.rowsPerPage
    );
  }

  ngOnInit() {
    this.storedParams = Object.assign(
      new EEPProgramHomeStatusRequestParams(),
      this.defaultParams
    );

    forkJoin({
      queryParams: this.activatedRoute.queryParams.pipe(first()),
      currentUser: this.store.select(getInfoUser).pipe(first()),
    })
      .pipe(
        takeUntil(this.componentDestroyed$),
        switchMap(({ queryParams, currentUser }) => {
          const params = new CompanyAccessRequestParams();
          params.company = currentUser.company;
          return this.companyAccessService.list(params).pipe(
            first(),
            map(companyAccessesResponse => {
              const companyAccesses = companyAccessesResponse.results;
              let companyAccess = null;
              if (companyAccesses.length) {
                companyAccess = companyAccesses[0];
              }
              return {
                queryParams: queryParams,
                currentUser: currentUser,
                companyAccess: companyAccess,
              };
            })
          );
        })
      )
      .subscribe(({ queryParams, currentUser, companyAccess }) => {
        this.currentUser = currentUser;
        this.storedParams.assignQueryParams(queryParams);
        if (currentUser.is_superuser) {
          this.storedParams.company_access = null;
        } else {
          if (!this.storedParams.company_access) {
            this.storedParams.company_access = [companyAccess.id];
          }
        }

        this.displayedColumns = [
          'select',
          'customer_hirl_project__id',
          'home__subdivision__name',
          'home__street_line1',
          'eep_program__name',
          'certification_date',
          'actions',
        ];

        this.initialized = true;
        this.setupFilterForm();
        this.hydrateForm();
        this.list();
      });
  }

  setupFilterForm() {
    this.filterFromGroup = this.fb.group({
      search: [null],
      certification_date__gte: [null],
      certification_date__lte: [null],
      company_access_info: [null],
    });

    this.filterFromGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        const params = Object.assign(this.defaultParams, values);
        delete params.ordering;

        if (params.company_access_info) {
          params.company_access = params.company_access_info.map(
            companyAccess => companyAccess.id
          );
        } else {
          params.company_access = null;
        }
        delete params.company_access_info;

        this.storedParams = Object.assign(this.storedParams, params);
        this.list();
      });
  }

  hydrateForm() {
    this.filterFromGroup.patchValue(this.storedParams, {
      emitEvent: false,
      onlySelf: true,
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  list() {
    this.entitiesIsLoading = true;

    // populate params to query string
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.storedParams.toQueryParams(),
      replaceUrl: true,
    });

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }

    this.listSubscription$ = this.eepProgramHomeStatusService
      .customer_hirl_bulk_certificate_list(this.storedParams)
      .pipe(takeUntil(this.componentDestroyed$), take(1))
      .subscribe({
        next: (
          data: ServerResponse<CustomerHIRLBulkCertificateEEPProgramHomeStatusList>
        ) => {
          this.entities = data.results;
          this.entitiesCount = data.count;
          this.entitiesIsLoading = false;
          if (this.selectAllEntities) {
            this.entities.forEach(
              (row: CustomerHIRLBulkCertificateEEPProgramHomeStatusList) => {
                this.selectedEntities.add(row);
              }
            );
          }
        },
        error: (error: HttpErrorResponse) => {
          if (error.status === 400) {
            // to avoid cycle loop, reload list only if invalid param in query detected
            let invalidQueryParam = false;
            for (const key of Object.keys(error.error)) {
              if (this.storedParams.hasOwnProperty(key)) {
                invalidQueryParam = true;
                delete this.storedParams[key];
              }
            }

            if (invalidQueryParam) {
              this.list();
            }
          }
        },
      });
  }

  onSort($event: Sort) {
    this.storedParams.ordering = `${$event.direction === 'desc' ? '-' : ''}${
      $event.active
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  onPaginateChange($event: PageEvent) {
    this.uiHelperService.getUISettings().rowsPerPage = $event.pageSize;
    this.storedParams.page_size = $event.pageSize;
    this.storedParams.page = $event.pageIndex + 1;
    this.list();
  }

  resetFilters($event: MouseEvent) {
    $event.preventDefault();
    this.filterFromGroup.reset();
  }

  showAppliedFiltersDialog($event: MouseEvent) {
    $event.preventDefault();

    let content = '';
    for (const [key, value] of Object.entries(this.getAppliedFilters())) {
      content += `<b>${key}</b>: ${value}<br>`;
    }

    this.dialog.open(OkDialogComponent, {
      data: {
        title: 'Current Applied Filters',
        content: content,
      },
    });
  }

  getAppliedFilters(): {
    [key: string]: string;
  } {
    return this.storedParams.toAppliedFilters({
      overwritePropertyValues: {},
      overwritePropertyLabels: {},
    });
  }

  onSelectAll() {
    if (this.selectAllEntities) {
      this.entities.forEach(
        (row: CustomerHIRLBulkCertificateEEPProgramHomeStatusList) => {
          this.selectedEntities.add(row);
        }
      );
    } else {
      this.selectedEntities.clear();
    }
  }

  onEntitySelect(
    event: MatCheckboxChange,
    row: CustomerHIRLBulkCertificateEEPProgramHomeStatusList
  ): void {
    if (event.checked) {
      this.selectedEntities.add(row);
    } else {
      this.selectedEntities.delete(row);
    }

    this.selectAllEntities =
      this.selectedEntities.length &&
      this.selectedEntities.length === this.entitiesCount;
  }

  isEntityChecked(
    row: CustomerHIRLBulkCertificateEEPProgramHomeStatusList
  ): boolean {
    return this.selectedEntities.has(row);
  }

  certificateDownload($event: MouseEvent) {
    $event.preventDefault();

    this.store.dispatch(toggleLoading({ payload: true }));

    const certificateDownloadData =
      new CustomerHIRLBulkCertificateEEPProgramHomeStatusDownload();
    certificateDownloadData.home_statuses = this.selectedEntities
      ?.toArray()
      .map((product: CustomerHIRLProductList) => product.id);
    certificateDownloadData.select_all = this.selectAllEntities;

    this.eepProgramHomeStatusService
      .customer_hirl_certificate_multiple_download(
        certificateDownloadData,
        this.storedParams
      )
      .pipe(first())
      .subscribe(
        (asynchronousProcessedDocument: AsynchronousProcessedDocument) => {
          this.store.dispatch(toggleLoading({ payload: false }));

          const asynchronousProcessedDocumentDialogRef = this.dialog.open(
            AsynchronousProcessedDocumentStatusDialogComponent,
            {
              width: '50%',
              data: {
                entity: asynchronousProcessedDocument,
                retrieve: true,
              },
            }
          );
          asynchronousProcessedDocumentDialogRef.afterClosed().subscribe();
        },
        error => this.uiHelperService.handleUserRequestError(error)
      );
  }

  //   $event.preventDefault();
  //   this.store.dispatch(toggleLoading({ payload: true }));
  //
  //   const selectedIds = this.selectedRows.map(homeStatus => homeStatus.id);
  //   const params = new EEPProgramHomeStatusRequestParams();
  //   params.id = selectedIds.toString();
  //   const newTab = window.open('', '_blank');
  //   this.eepProgramHomeStatusService
  //     .bulk_certificate_download(params.toQueryParams())
  //     .pipe(takeUntil(this.componentDestroyed$), first())
  //     .subscribe(
  //       asynchronousProcessedDocument => {
  //         newTab.location.href = `/file-operation/document/${asynchronousProcessedDocument.id}`;
  //         newTab.focus();
  //         window.location.reload();
  //       },
  //       error => {
  //         // Close the blank tab if we encountered an error
  //         newTab.close();
  //         this.uiHelperService.handleUserRequestError(error);
  //       }
  //     );
  // }
}
