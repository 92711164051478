import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { CompanyBrand, Product, ProductType } from '@/data/product/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin, of, Subject } from 'rxjs';
import { User } from '@/data/core/models/user';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, takeUntil } from 'rxjs/operators';
import { toggleLoading } from '@/state/actions/app.actions';
import { ProductService } from '@/data/product/services/product.service';
import { HttpErrorResponse } from '@angular/common/http';
import {
  CompanyBrandChangeDialogComponent,
  CompanyBrandChangeDialogData,
} from '@/modules/product/components/company-brand-change-dialog/company-brand-change-dialog.component';
import { conditionalValidator } from '@/core/validators';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';
import { AppFieldValidatorsService } from '@/core/services/field-validators.service';

export interface HIRLProductChangeDialogData {
  product: Product;
  commit?: boolean;
}

@Component({
  selector: 'app-hirl-product-change-dialog',
  templateUrl: './hirl-product-change-dialog.component.html',
  styleUrls: ['./hirl-product-change-dialog.component.scss'],
})
export class HIRLProductChangeDialogComponent implements OnInit, OnDestroy {
  protected readonly CustomerHIRLSettings = CustomerHIRLSettings;

  public initialized: boolean;
  public currentUser: User;

  public currentUserIsCustomerOrSuper = false;

  public isEditForm = false;
  public entity?: Product;
  public commit = true;
  public retrieve = true;
  public form: FormGroup;
  public canSeeField = {};

  public descriptionMaxLength = 500;
  public descriptionRemainingCharacters = this.descriptionMaxLength;

  quillModules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block', { align: [] }],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ], // outdent/indent

      [
        { header: [1, 2, 3, 4, 5, 6, false] },
        { color: [] },
        { background: [] },
      ], // dropdown with defaults from theme
    ],
  };

  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<HIRLProductChangeDialogComponent>,
    private fb: FormBuilder,
    private productService: ProductService,
    private dialog: MatDialog,
    private appFieldValidatorsService: AppFieldValidatorsService,
    private uiHelperService: UIHelperService,
    @Inject(MAT_DIALOG_DATA) public data: HIRLProductChangeDialogData
  ) {
    if (data?.product) {
      this.entity = Object.assign({}, data?.product);
    }
    this.commit = data?.commit || true;
    if (this.entity?.id) {
      this.isEditForm = true;
    }
  }
  ngOnInit() {
    const sources = {
      currentUser: this.store.select(getInfoUser).pipe(first()),
      product: of(this.entity),
    };

    this.store.dispatch(toggleLoading({ payload: true }));

    if (this.isEditForm && this.retrieve) {
      sources.product = this.productService
        .retrieve(this.entity.id, { ignoreStore: true })
        .pipe(takeUntil(this.componentDestroyed$), first());
    }

    forkJoin(sources)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: ({ currentUser, product }) => {
          this.store.dispatch(toggleLoading({ payload: false }));

          this.currentUser = currentUser;
          this.currentUserIsCustomerOrSuper =
            this.currentUser.company_info?.slug ===
              CustomerHIRLSettings.companySlug || this.currentUser.is_superuser;
          this.entity = product;
          this.setupForm();
          this.initialized = true;
          this.hydrateForm();
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  private setupForm() {
    this.form = this.fb.group({
      title: [null, [Validators.required]],
      description: [''],
      company_brands_info: [null],
      categories_info: [null],
      manufacturer_info: [
        null,
        [
          conditionalValidator(
            () =>
              !this.isEditForm &&
              (this.currentUser.company_info?.slug ===
                CustomerHIRLSettings.companySlug ||
                this.currentUser.is_superuser),
            Validators.required
          ),
        ],
      ],
      website_url: ['', this.appFieldValidatorsService.websiteURLValidator()],
      specifications_url: [
        '',
        this.appFieldValidatorsService.websiteURLValidator(),
      ],
      is_featured: [false],
    });

    if (this.isEditForm) {
      this.canSeeField = {
        title:
          this.currentUserIsCustomerOrSuper ||
          !this.entity.paid_non_expired_payment_request,
        company_brands_info:
          this.currentUserIsCustomerOrSuper ||
          !this.entity.paid_non_expired_payment_request,
        categories_info:
          this.currentUserIsCustomerOrSuper ||
          !this.entity.paid_non_expired_payment_request,
        manufacturer_info: false,
        is_featured: this.currentUserIsCustomerOrSuper,
      };
    } else {
      this.canSeeField = {
        title: true,
        company_brands_info: true,
        categories_info: true,
        manufacturer_info: this.currentUserIsCustomerOrSuper,
        is_featured: this.currentUserIsCustomerOrSuper,
      };
    }
  }

  hydrateForm() {
    if (this.isEditForm) {
      this.form.patchValue(Object.assign({}, this.entity), {
        emitEvent: false,
        onlySelf: true,
      });
      this.form.get('categories_info').patchValue(this.entity.categories[0], {
        emitEvent: false,
        onlySelf: true,
      });
    } else {
      let manufacturer = null;
      if (
        this.currentUser.company_info?.slug !==
          CustomerHIRLSettings.companySlug &&
        !this.currentUser.is_superuser
      ) {
        manufacturer = this.currentUser.company_info;
      }
      this.form.get('manufacturer_info').patchValue(manufacturer);
    }
  }

  save($event: MouseEvent) {
    $event.preventDefault();
    if (this.isEditForm) {
      this.edit();
    } else {
      this.create();
    }
  }

  private create() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    const data: Product = new Product();

    data.title = this.form.value.title;
    data.manufacturer = this.form.value.manufacturer_info?.id;
    data.product_type = ProductType.hirlProduct;

    data.description = this.form.value.description;
    data.company_brands = this.form.value.company_brands_info?.map(
      (companyBrand: CompanyBrand) => companyBrand.id
    );
    if (this.form.value.categories_info) {
      data.categories = [this.form.value.categories_info];
    }
    data.website_url = this.form.value.website_url;
    data.specifications_url = this.form.value.specifications_url;

    if (this.currentUserIsCustomerOrSuper) {
      data.is_featured = this.form.value.is_featured;
    }

    if (!this.commit) {
      this.dialogRef.close(this.entity);
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    this.productService
      .create(data)
      .pipe(first())
      .subscribe({
        next: (entity: Product) => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close(entity);
        },
        error: (error: HttpErrorResponse) =>
          this.uiHelperService.handleUserRequestError(error),
      });
  }

  private edit() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    if (!this.commit) {
      this.dialogRef.close(this.entity);
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const data = new Product();

    data.title = this.form.value.title;
    data.description = this.form.value.description;
    data.company_brands = this.form.value.company_brands_info?.map(
      (companyBrand: CompanyBrand) => companyBrand.id
    );

    if (this.form.value.categories_info) {
      data.categories = [this.form.value.categories_info];
    }
    data.website_url = this.form.value.website_url;
    data.specifications_url = this.form.value.specifications_url;

    if (this.currentUserIsCustomerOrSuper) {
      data.is_featured = this.form.value.is_featured;
    }

    this.productService
      .update(this.entity.id, data)
      .pipe(first())
      .subscribe({
        next: (entity: Product) => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close(entity);
        },
        error: (error: HttpErrorResponse) =>
          this.uiHelperService.handleUserRequestError(error),
      });
  }

  createCompanyBrand($event: MouseEvent) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(CompanyBrandChangeDialogComponent, {
      width: '45%',
      disableClose: true,
      data: {} as CompanyBrandChangeDialogData,
    });

    dialogRef.afterClosed().subscribe((result?: CompanyBrand) => {
      if (!result) {
        return;
      }

      const companyBrands = this.form.get('company_brands_info').value || [];
      companyBrands.push(result);
      this.form.get('company_brands_info').patchValue(companyBrands);
      this.uiHelperService.openSnackBar('Record Successfully Added');
    });
  }

  stripHtmlTags(html: string): string {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  }

  onDescriptionChanged($event: any) {
    const plainText = this.stripHtmlTags($event.html).trim();
    this.descriptionRemainingCharacters =
      this.descriptionMaxLength - plainText.length;
    if (plainText.length > this.descriptionMaxLength) {
      // Truncate the content if it exceeds the limit
      const control = this.form.get('description');
      control?.setValue(plainText.substring(0, this.descriptionMaxLength), {
        emitEvent: false,
      });
    }
  }
}
