import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { getInfoUser } from '@/state/selectors/info.selector';
import { filter, map } from 'rxjs/operators';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';

@Injectable({ providedIn: 'root' })
export class CustomerHIRLInvoiceItemListGuard implements CanActivate {
  constructor(public router: Router, public store: Store<AppState>) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.select(getInfoUser).pipe(
      filter(currentUser => !!currentUser),
      map(currentUser => {
        if (currentUser.company_info) {
          if (
            currentUser.company_info &&
            currentUser.company_info.slug === CustomerHIRLSettings.companySlug
          ) {
            return true;
          }
          if (
            currentUser.company_info.sponsor_slugs.includes(
              CustomerHIRLSettings.companySlug
            ) &&
            currentUser.is_company_admin
          ) {
            return true;
          }
        }
        this.router.navigate(['403'], {
          queryParams: { returnUrl: state.url.split('?')[0] },
        });
        return false;
      })
    );
  }
}
