import {
  GroundSourceHeatPumpBackendDict,
  createGroundSourceHeatPumpForm,
} from '@/data/simulation/models/mechanicals/GroundSourceHeatPump';
import { BaseValidator } from './base.validator';
import { ModelErrors, StateErrors } from '../state/shared/base.state';

export class GroundSourceHeatPumpValidator extends BaseValidator {
  static validateForm(
    groundSourceHeatPump: GroundSourceHeatPumpBackendDict
  ): ModelErrors<GroundSourceHeatPumpBackendDict> {
    const groundSourceHeatPumpForm =
      createGroundSourceHeatPumpForm(groundSourceHeatPump);
    return groundSourceHeatPumpForm.getFieldAndModelErros();
  }

  static validate(
    groundSourceHeatPumps: GroundSourceHeatPumpBackendDict[]
  ): StateErrors<GroundSourceHeatPumpBackendDict> {
    const errors = {};
    groundSourceHeatPumps.forEach(groundSourceHeatPump => {
      errors[groundSourceHeatPump.id] = this.validateForm(groundSourceHeatPump);
    });
    return errors;
  }
}
