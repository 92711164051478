<ng-container [ngSwitch]="displayType">
    <ng-container *ngSwitchCase="HomeDisplayComponentType.addressAsLink">
      <a [routerLink]="['/', 'home', home?.id]">
        {{ home?.address }}
      </a>
    </ng-container>
    <ng-container *ngSwitchCase="HomeDisplayComponentType.address">
      {{ home?.address }}
    </ng-container>
</ng-container>
