import {
  RimJoistTypeBackendDict,
  createRimJoistTypeForm,
  FIELD_LABELS,
} from '@/data/simulation/models/enclosure/RimJoistType';
import { updateRimJoistType } from '@/modules/simulation/state/rim-joist-type/actions';
import { selectRimJoistViewSet } from '@/modules/simulation/state/rim-joist-type/selectors';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  AreaUnitLabels,
  LengthUnitLabels,
} from '@/data/simulation/enumerations';

@Component({
  selector: 'app-rim-joist-type',
  templateUrl: './rim-joist-type.component.html',
  styleUrls: ['./rim-joist-type.component.scss'],
})
export class RimJoistTypeComponent implements OnDestroy, OnInit {
  @Input() entityId: number;
  fieldLabels = FIELD_LABELS;

  lengthLabels = LengthUnitLabels;
  areaLabels = AreaUnitLabels;

  entity: RimJoistTypeBackendDict = null;
  rimJoistTypeFormGroup: FormGroup;

  componentDestroyed$ = new Subject();
  loading = false;

  constructor(public store: Store) {}

  ngOnInit() {
    this.store
      .select(selectRimJoistViewSet(this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(({ data, loading }) => {
        this.loading = loading;
        if (loading) return;

        if (this.rimJoistTypeFormGroup) {
          this.rimJoistTypeFormGroup.patchValue(data);
        } else {
          this.rimJoistTypeFormGroup = createRimJoistTypeForm(data);
        }
        this.entity = data;
      });
  }

  onSave(rimJoistTypeChanges) {
    this.store.dispatch(
      updateRimJoistType({
        id: this.entityId,
        rimJoistTypeChanges,
      })
    );
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
