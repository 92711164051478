import {
  EditorNavigationService,
  SubTab,
} from '@/modules/simulation/services/editor-navigation.service';

import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-appliances-tab',
  templateUrl: './appliances-tab.component.html',
  styleUrls: ['./appliances-tab.component.scss'],
})
export class AppliancesTabComponent {
  lightsId: number;
  componentDestroyed$ = new Subject();
  activeSubTabLink: SubTab;
  subTabs = SubTab;
  appliancesId: number;

  constructor(
    public store: Store,
    public editorNavigationService: EditorNavigationService
  ) {}

  ngOnInit(): void {
    this.editorNavigationService
      .getActiveSubTabLinkObservable()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(subTab => {
        this.activeSubTabLink = subTab;
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
