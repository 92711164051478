import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ServerResponse } from '@/core/schemes/server-response';
import { County } from '@/data/geographic/models/county';
import { BaseDataService } from '@/core/services/base-data-service';
import { ListRequestParams } from '@/core/schemes/request-params';
import { Observable } from 'rxjs';

export class CountyRequestParams extends ListRequestParams {
  state?: string;
  home_status_related?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CountyService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/counties/`;

  list(params?: CountyRequestParams) {
    return this.http.get<ServerResponse<County>>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }
  retrieve(countyId: string): Observable<County> {
    return this.http.get<County>(`${this.baseUrl}${countyId}`);
  }
}
