/**
 * EEP Program Info model representation
 */
export class EEPProgramInfo {
  id: number;
  name: string;
  slug: string;
}

/**
 * EEP Program model representation
 */
export class EEPProgram {
  id: number;
  name: string;
  slug: string;
}
