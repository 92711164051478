import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from '@/data/core/models/user';
import { FormBuilder, FormGroup } from '@angular/forms';
import { forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { ApexRequestRequestParams } from '@/data/home/services/apex_request.service';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { first, map, mergeMap, takeUntil } from 'rxjs/operators';
import { getInfoUser } from '@/state/selectors/info.selector';
import * as _ from 'lodash';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { UDRHModel } from '@/data/simulation/models';
import {
  UDRHModelRequestParams,
  UDRHModelService,
} from '@/data/simulation/services/udrh-model.service';
import {
  ChangeUdrhDialogComponent,
  ChangeUDRHModelDialogData,
} from '@/modules/simulation/components/change-udrh-dialog/change-udrh-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-udrh-list',
  templateUrl: './udrh-list.component.html',
  styleUrls: ['./udrh-list.component.scss'],
})
export class UdrhListComponent implements OnInit, OnDestroy {
  public displayedColumns: string[] = [
    'short_name',
    'description',
    'reference_analysis',
    'design_analysis',
    'actions',
  ];

  public rows: UDRHModel[];
  public rowsCount: number;

  public isLoading = true;

  public currentUser: User;
  public filterFromGroup: FormGroup;

  public createPermissionResponse: Observable<ObjectPermissionResponse>;
  public permissions: {
    [key: string]: {
      canEdit: Observable<ObjectPermissionResponse>;
      canDelete: Observable<ObjectPermissionResponse>;
    };
  } = {};

  public defaultParams: UDRHModelRequestParams = new UDRHModelRequestParams(
    1,
    '',
    '-id'
  );
  public storedParams: UDRHModelRequestParams = Object.assign(
    new ApexRequestRequestParams(),
    this.defaultParams
  );

  private listSubscription$: Subscription;
  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private dialog: MatDialog,
    private router: Router,
    private udrhModelService: UDRHModelService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private uiHelperService: UIHelperService
  ) {}

  ngOnInit() {
    this.setupFilterForm();

    this.activatedRoute.params
      .pipe(
        takeUntil(this.componentDestroyed$),
        mergeMap(params => {
          this.rows = [];
          this.rowsCount = 0;

          return forkJoin({
            queryParams: this.activatedRoute.queryParams.pipe(first()),
            currentUser: this.store.select(getInfoUser).pipe(first()),
          }).pipe(
            map(({ queryParams, currentUser }, index) => {
              return { params, queryParams, currentUser };
            })
          );
        })
      )
      .subscribe(({ params, queryParams, currentUser }) => {
        this.storedParams.page_size =
          this.uiHelperService.getUISettings().rowsPerPage;
        this.storedParams.assignQueryParams(queryParams);
        this.currentUser = currentUser;

        this.hydrateForm();
        this.list();
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  setupFilterForm() {
    this.filterFromGroup = this.fb.group({
      search: [null],
    });

    this.filterFromGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        const params = _.merge({}, this.defaultParams, values);
        delete params.page_size;
        delete params.ordering;

        this.storedParams = _.merge(this.storedParams, params);
        this.list();
      });
  }

  list() {
    this.isLoading = true;

    // populate params to query string
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.storedParams.toQueryParams(),
      replaceUrl: true,
    });

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }

    this.listSubscription$ = this.udrhModelService
      .list(this.storedParams)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(data => {
        this.rows = data.results;
        this.rowsCount = data.count;
        this.isLoading = false;

        this.rows.forEach(row => {
          this.permissions[row.id] = {
            canEdit: of(new ObjectPermissionResponse(false)),
            canDelete: of(new ObjectPermissionResponse(false)),
          };
        });
      });
  }

  hydrateForm() {
    this.filterFromGroup.patchValue(this.storedParams, {
      emitEvent: false,
      onlySelf: true,
    });
  }

  resetFilters($event: MouseEvent) {
    $event.preventDefault();
    this.filterFromGroup.reset();
  }

  onPaginateChange($event: PageEvent) {
    this.uiHelperService.getUISettings().rowsPerPage = $event.pageSize;
    this.storedParams.page_size = $event.pageSize;
    this.storedParams.page = $event.pageIndex + 1;
    this.list();
  }

  onSortChange($event: Sort) {
    this.storedParams.ordering = `${$event.direction === 'desc' ? '-' : ''}${
      $event.active
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  delete($event: MouseEvent, row) {}

  createFromFile($event: MouseEvent) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(ChangeUdrhDialogComponent, {
      width: '45%',
      disableClose: true,
      data: {} as ChangeUDRHModelDialogData,
    });

    dialogRef.afterClosed().subscribe((result?: UDRHModel) => {
      if (!result) {
        return;
      }
      this.list();
      this.uiHelperService.openSnackBar('Record Successfully Added');
    });
  }
}
