import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import * as NaturalVentilationActions from './actions';
import * as SharedActions from '../shared/shared.actions';
import { NaturalVentilationService } from '@/data/simulation/services/natural-ventilation.service';
import { NaturalVentilationValidator } from '../../validators/natural-ventilation.validator';

@Injectable()
export class NaturalVentilationEffects {
  loadDetailedNaturalVentilations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NaturalVentilationActions.loadNaturalVentilations),
      mergeMap(action => {
        const errors = NaturalVentilationValidator.validate(
          action.naturalVentilations
        );

        return of(
          NaturalVentilationActions.loadNaturalVentilationsSuccess({
            naturalVentilations: action.naturalVentilations,
            errors: errors,
          })
        );
      })
    );
  });

  updateNaturalVentilation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NaturalVentilationActions.updateNaturalVentilation),
      mergeMap(action =>
        this.naturalVentilationService
          .update(action.naturalVentilationChanges)
          .pipe(
            mergeMap(updatedNaturalVentilation => {
              const errors = NaturalVentilationValidator.validate([
                updatedNaturalVentilation,
              ]);
              return of(
                NaturalVentilationActions.updateNaturalVentilationSuccess({
                  naturalVentilation: updatedNaturalVentilation,
                  errors: errors[updatedNaturalVentilation.id],
                })
              );
            }),
            catchError(error =>
              of(
                NaturalVentilationActions.updateNaturalVentilationFailure({
                  id: action.naturalVentilationChanges.id,
                }),
                SharedActions.reportAPIFailure({ error })
              )
            )
          )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private naturalVentilationService: NaturalVentilationService
  ) {}
}
