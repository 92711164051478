import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import * as RoofTypeActions from './actions';
import * as SharedActions from '../shared/shared.actions';
import { RoofTypeService } from '@/data/simulation/services/roof-type.service';
import { ModelGraphService } from '../../services/model-graph.service';
import { RoofTypeValidator } from '../../validators/roof-type.validator';

@Injectable()
export class RoofTypeEffects {
  loadDetailedRoofTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RoofTypeActions.loadDetailedRoofTypes),
      mergeMap(action => {
        const errors = RoofTypeValidator.validate(action.roofTypes);
        return of(
          RoofTypeActions.loadRoofTypesSuccess({
            roofTypes: action.roofTypes,
            errors,
          })
        );
      })
    );
  });

  updateRoofType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RoofTypeActions.updateRoofType),
      mergeMap(action =>
        this.roofTypeService.update(action.id, action.roofTypeChanges).pipe(
          mergeMap(updatedRoofType => {
            const errors = RoofTypeValidator.validate([updatedRoofType]);
            return of(
              RoofTypeActions.loadRoofTypesSuccess({
                roofTypes: [updatedRoofType],
                errors,
              })
            );
          }),
          catchError(error =>
            of(
              RoofTypeActions.updateRoofTypeFailure({
                id: action.roofTypeChanges.id,
              }),
              SharedActions.reportAPIFailure({ error })
            )
          )
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private modelGraphService: ModelGraphService,
    private roofTypeService: RoofTypeService
  ) {}
}
