import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServerResponse } from '@/core/schemes/server-response';
import { HIRLGreenEnergyBadge } from '@/data/customer-hirl/models';
import { environment } from '../../../../environments/environment';
import { BaseDataService } from '@/core/services/base-data-service';
import { ListRequestParams } from '@/core/schemes/request-params';

export class HIRLGreenEnergyBadgeRequestParams extends ListRequestParams {
  eep_program?: number;
}

@Injectable({
  providedIn: 'root',
})
export class HIRLGreenEnergyBadgeService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/hirl_green_energy_badges/`;

  list(
    params?: HIRLGreenEnergyBadgeRequestParams
  ): Observable<ServerResponse<HIRLGreenEnergyBadge>> {
    return this.http.get<ServerResponse<HIRLGreenEnergyBadge>>(
      `${this.baseUrl}`,
      {
        params: this.rollParams(params),
      }
    );
  }

  retrieve(badgeId: string): Observable<HIRLGreenEnergyBadge> {
    return this.http.get<HIRLGreenEnergyBadge>(`${this.baseUrl}${badgeId}`);
  }
}
