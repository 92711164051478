import { SimulationBackendDict } from '@/data/simulation/models/simulation';
import { createAction, props } from '@ngrx/store';
import { StateErrors } from '../shared/base.state';
import { ValidationResult } from '../../validators/base.validator';

export const loadSimulation = createAction(
  '[Simulation] Load Simulation',
  props<{ id: number }>()
);

export const loadSimulationSuccess = createAction(
  '[Simulation] Load Simulation Success',
  props<{
    simulation: SimulationBackendDict;
    errors: StateErrors<SimulationBackendDict>;
  }>()
);

export const removeItemFromList = createAction(
  '[Simulation] Remove item from Simulation',
  props<{
    fieldName: keyof SimulationBackendDict;
    id: number;
  }>()
);

export const addItemToList = createAction(
  '[Simulation] Add item to Simulation',
  props<{
    fieldName: keyof SimulationBackendDict;
    id: number;
  }>()
);

export const loadSimulationFailure = createAction(
  '[Simulation] Load Simulation Failure',
  props<{ error: any }>()
);

export const updateSimulation = createAction(
  '[Simulation] Update Simulation',
  props<{ changes: Partial<SimulationBackendDict>; id: number }>()
);

export const updateSimulationFailure = createAction(
  '[Simulation] Update Simulation Failure',
  props<{ id: number }>()
);

export const updateCrossModelErrors = createAction(
  '[Simulation] Update Cross Model Errors',
  props<{ errors: ValidationResult[] }>()
);

// Above grade  walls related actions.
export const addNewAboveGradeWall = createAction(
  '[AboveGradeWalls UI] Add new AboveGradeWall to simulation'
);

export const cloneAboveGradeWall = createAction(
  '[AboveGradeWalls UI] Clone AboveGradeWall',
  props<{ id: number }>()
);

// Foundation walls related actions.
export const addNewFoundationWall = createAction(
  '[FoundationWalls UI] Add new FoundationWall to simulation'
);

export const cloneFoundationWall = createAction(
  '[FoundationWalls UI] Clone FoundationWall',
  props<{ id: number }>()
);

// Frame floors related actions.
export const addNewFrameFloor = createAction(
  '[FrameFloors UI] Add new FrameFloor to simulation'
);

export const cloneFrameFloor = createAction(
  '[FrameFloors UI] Clone FrameFloor',
  props<{ id: number }>()
);

// Roofs related actions.
export const addNewRoof = createAction('[Roofs UI] Add new Roof to simulation');

export const cloneRoof = createAction(
  '[Roofs UI] Clone Roof',
  props<{ id: number }>()
);

// RimJoists related actions.
export const addNewRimJoist = createAction(
  '[RimJoists UI] Add new RimJoist to simulation'
);

export const cloneRimJoist = createAction(
  '[RimJoists UI] Clone RimJoist',
  props<{ id: number }>()
);

// Slabs related actions.
export const addNewSlab = createAction('[Slabs UI] Add new Slab to simulation');

export const cloneSlab = createAction(
  '[Slabs UI] Clone Slab',
  props<{ id: number }>()
);

// Skylights related actions.
export const addNewSkylight = createAction(
  '[Skylights UI] Add new Skylight to simulation'
);

export const cloneSkylight = createAction(
  '[Skylights UI] Clone Skylight',
  props<{ id: number }>()
);

// Doors related actions.
export const cloneDoor = createAction(
  '[Doors UI] Clone Door',
  props<{ id: number }>()
);

export const addNewDoor = createAction('[Doors UI] Add new Door to simulation');

// Windows related actions.
export const cloneWindow = createAction(
  '[Windows UI] Clone Window',
  props<{ id: number }>()
);

export const addNewWindow = createAction(
  '[Windows UI] Add new Window to simulation'
);

// Equipment related actions.
export const addNewEquipment = createAction(
  '[Equipment UI] Add new Equipment to simulation',
  props<{ detailedMechanicalEquipment: any }>()
);

// DistributionSystems related actions.
export const addNewDistributionSystem = createAction(
  '[DistributionSystems UI] Add new DistributionSystem to simulation'
);

export const cloneDistributionSystem = createAction(
  '[DistributionSystems UI] Clone DistributionSystem',
  props<{ id: number }>()
);

// Photovoltaics related actions.
export const addNewPhotovoltaic = createAction(
  '[Photovoltaics UI] Add new Photovoltaic to simulation'
);

export const clonePhotovoltaic = createAction(
  '[Photovoltaics UI] Clone Photovoltaic',
  props<{ id: number }>()
);

// Add utility rate related actions.
export const addUtilityRate = createAction(
  '[UtilityRates UI] Add new UtilityRate to simulation',
  props<{ entity: any }>()
);

// Add thermostat related actions.
export const addThermostat = createAction(
  '[Thermostats UI] Add new Thermostat to simulation'
);

export const addMechanicalVentilation = createAction(
  '[MechanicalVentilation UI] Add new MechanicalVentilation to simulation'
);

export const cloneMechanicalVentilation = createAction(
  '[MechanicalVentilation UI] Clone MechanicalVentilation',
  props<{ id: number }>()
);
