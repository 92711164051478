<h2 mat-dialog-title class="d-flex justify-content-between align-items-center">
  Processing document
  <button mat-icon-button aria-label="Close dialog" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</h2>
<mat-dialog-content>
  <div class="row">
    <div class="col-12">
      <b>Current Status: </b>
      <span [style]="{color: taskStateColors[entity.state]}">{{ entity.state || "PENDING" }}</span>
    </div>
  </div>

  <ng-container *ngIf="initialized">
    <div class="row" *ngIf="entity.download">
      <div class="col-12">
        <b>Download: </b>
        <a href="{{ entity.document }}" *ngIf="entity.document">
          Document
        </a>
      </div>
    </div>
    <ng-container *ngIf="!isCompleted">
      <div class="row">
        <div class="col-12">
          <hr>
        </div>
      </div>
      <ng-container *ngIf="!processingProgressData && !writingProgressData">
        <div class="row">
          <div class="col-12">
            <b>Processing</b>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-progress-bar mode="query"></mat-progress-bar>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="processingProgressData">
        <div class="row">
          <div class="col-12">
            <b>Processing:</b>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-progress-bar [mode]="processingProgressData.mode" [value]="processingProgressData.value"></mat-progress-bar>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="writingProgressData">
        <div class="row">
          <div class="col-12">
            <b>Writing:</b>
          </div>
        </div>
        <div class="row" *ngIf="writingProgressData">
          <div class="col-12">
            <mat-progress-bar [mode]="writingProgressData.mode" [value]="writingProgressData.value"></mat-progress-bar>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <a mat-button href="{{ entity.document }}" color="accent" mat-raised-button *ngIf="entity.document && isCompleted">
    Download
  </a>
  <a mat-button [routerLink]="['/', 'file-operation', 'document', entity.id]" target="_blank" color="primary" mat-raised-button cdkFocusInitial [mat-dialog-close]="null">
    Open In New Window <fa-icon [icon]="['fas', 'external-link-alt']"></fa-icon>
  </a>
</mat-dialog-actions>


