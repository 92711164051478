import {
  DehumidifierBackendDict,
  createDehumidifierForm,
} from '@/data/simulation/models/mechanicals/Dehumidifier';
import { BaseValidator } from './base.validator';
import { ModelErrors, StateErrors } from '../state/shared/base.state';

export class DehumidifierValidator extends BaseValidator {
  static validateForm(
    dehumidifier: DehumidifierBackendDict
  ): ModelErrors<DehumidifierBackendDict> {
    const dehumidifierForm = createDehumidifierForm(dehumidifier);
    const errors = {};
    Object.keys(dehumidifier).forEach(key => {
      if (!dehumidifierForm.get(key)) return;
      errors[key] = dehumidifierForm.get(key).errors;
    });
    return {
      field: errors,
      model: dehumidifierForm.errors || {},
    };
  }

  static validate(
    dehumidifiers: DehumidifierBackendDict[]
  ): StateErrors<DehumidifierBackendDict> {
    const errors = {};
    dehumidifiers.forEach(dehumidifier => {
      errors[dehumidifier.id] = this.validateForm(dehumidifier);
    });
    return errors;
  }
}
