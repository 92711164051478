<div mat-dialog-title class="d-flex justify-content-between align-items-center">
    <h2>{{ editorIsVisible ? 'Frame floor type' : 'Select the frame floor' }}</h2>
    <button mat-icon-button aria-label="close dialog" mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <div *ngIf="!editorIsVisible">
        <form [formGroup]="typeSelectForm">
            <mat-form-field class="w-100">
                <mat-label>Type</mat-label>
                <app-generic-model-autocomplete modelClassName="frame_floor_type" formControlName="type"
                    [panelWidth]="380" [filterParams]="{library_element: true}">
                </app-generic-model-autocomplete>
            </mat-form-field>
        </form>
    </div>

    <div *ngIf="editorIsVisible">
        <app-frame-floor-type [entityId]="floorTypeId"></app-frame-floor-type>
    </div>
</mat-dialog-content>
