import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { filter, first, takeUntil } from 'rxjs/operators';
import { toggleLoading } from '@/state/actions/app.actions';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { HttpErrorResponse } from '@angular/common/http';
import { PaymentRequestService } from '@/data/customer-hirl/services/payment-request.service';
import { PaymentRequestPermissionService } from '@/modules/customer-hirl/services/payment-request-permission.service';
import {
  PaymentRequest,
  PaymentRequestProduct,
  PaymentRequestStateLabelMapping,
  PaymentRequestType,
  PaymentRequestTypeLabelMapping,
} from '@/data/customer-hirl/models';
import {
  getHIRLPaymentRequest,
  getHIRLPaymentRequestProductsCount,
} from '@/state/selectors/customer-hirl/payment-request';
import {
  PaymentRequestChangeDialogComponent,
  PaymentRequestChangeDialogData,
} from '@/modules/customer-hirl/components/payment-request-change-dialog/payment-request-change-dialog.component';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { Product } from '@/data/product/models';
import { NavigationService } from '@/shared/services/navigation-service.service';
import { resetPaymentRequest } from '@/state/actions/customer-hirl/payment-request';

@Component({
  selector: 'app-payment-request-detail',
  templateUrl: './payment-request-detail.component.html',
  styleUrls: ['./payment-request-detail.component.scss'],
})
export class PaymentRequestDetailComponent implements OnInit, OnDestroy {
  protected readonly PaymentRequest = PaymentRequest;
  protected readonly PaymentRequestType = PaymentRequestType;
  protected readonly PaymentRequestStateLabelMapping =
    PaymentRequestStateLabelMapping;
  protected readonly PaymentRequestTypeLabelMapping =
    PaymentRequestTypeLabelMapping;

  public displayedColumns = ['thumbnail', 'title', 'practice_items_count'];

  initialized = false;

  selectedIndex = 0;

  entity: PaymentRequest;

  public editPermissionResponse: Observable<ObjectPermissionResponse>;
  public approvePermissionResponse: Observable<ObjectPermissionResponse>;
  public rejectPermissionResponse: Observable<ObjectPermissionResponse>;
  public deletePermissionResponse: Observable<ObjectPermissionResponse>;

  public paymentRequestProductsCount = -1;

  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    public injector: Injector,
    public dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private paymentRequestService: PaymentRequestService,
    private paymentRequestPermissionService: PaymentRequestPermissionService,
    public uiHelperService: UIHelperService,
    private navigationService: NavigationService
  ) {
    this.store
      .select(getHIRLPaymentRequest)
      .pipe(
        filter(val => val !== null),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((entity: PaymentRequest) => {
        this.entity = entity;
        this.entitySetup();
      });

    this.store
      .select(getHIRLPaymentRequestProductsCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((paymentRequestProductsCount: number) => {
        this.paymentRequestProductsCount = paymentRequestProductsCount;
      });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(params => {
        if (params.tab) {
          this.selectedIndex = Number(params.tab) || 0;
        }
      });

    this.activatedRoute.params
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(params => {
        this.loadEntity(params.paymentRequestId);
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
    this.store.dispatch(resetPaymentRequest());
  }

  loadEntity(paymentRequestId: number) {
    this.store.dispatch(toggleLoading({ payload: true }));
    this.paymentRequestService
      .retrieve(paymentRequestId)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe({
        next: (entity: PaymentRequest) => {
          this.entity = entity;
          this.entitySetup();
          this.store.dispatch(toggleLoading({ payload: false }));
          this.initialized = true;
        },
        error: (error: HttpErrorResponse) =>
          this.uiHelperService.handleMainRequestError(error),
      });
  }

  entitySetup() {
    this.editPermissionResponse = this.paymentRequestPermissionService.canEdit(
      this.entity
    );
    this.approvePermissionResponse =
      this.paymentRequestPermissionService.canApprove(this.entity);
    this.rejectPermissionResponse =
      this.paymentRequestPermissionService.canReject(this.entity);
    this.deletePermissionResponse =
      this.paymentRequestPermissionService.canDelete(this.entity);
  }

  onTabChange(event: MatTabChangeEvent) {
    const url = this.router
      .createUrlTree([], {
        relativeTo: this.activatedRoute,
        queryParams: { tab: event.index },
      })
      .toString();
    this.location.replaceState(url);
  }

  onEdit($event: MouseEvent) {
    $event.preventDefault();

    const dialogRef = this.dialog.open(PaymentRequestChangeDialogComponent, {
      width: '55%',
      disableClose: true,
      autoFocus: false,
      data: {
        paymentRequest: this.entity,
      } as PaymentRequestChangeDialogData,
    });

    dialogRef.afterClosed().subscribe((entity?: PaymentRequest) => {
      if (!entity) {
        return;
      }
      this.uiHelperService.openSnackBar('Record Successfully Updated');
    });
  }

  onApprove($event: MouseEvent) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Do you want to Approve this Payment Request?',
      },
    });
    dialogRef.afterClosed().subscribe((entity?: PaymentRequest) => {
      if (!entity) {
        return;
      }

      this.store.dispatch(toggleLoading({ payload: true }));
      this.paymentRequestService
        .approve(this.entity.id)
        .pipe(takeUntil(this.componentDestroyed$), first())
        .subscribe({
          next: (_: PaymentRequest) => {
            this.store.dispatch(toggleLoading({ payload: false }));
            this.uiHelperService.openSnackBar(
              'Payment Request successfully approved'
            );
          },
          error: (error: HttpErrorResponse) =>
            this.uiHelperService.handleUserRequestError(error),
        });
    });
  }

  onReject($event: MouseEvent) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Do you want to reject this Payment Request?',
      },
    });
    dialogRef.afterClosed().subscribe((entity?: Product) => {
      if (!entity) {
        return;
      }

      this.store.dispatch(toggleLoading({ payload: true }));
      this.paymentRequestService
        .reject(this.entity.id)
        .pipe(takeUntil(this.componentDestroyed$), first())
        .subscribe({
          next: (_: Product) => {
            this.store.dispatch(toggleLoading({ payload: false }));
            this.uiHelperService.openSnackBar(
              'Payment Request successfully rejected'
            );
          },
          error: (error: HttpErrorResponse) =>
            this.uiHelperService.handleUserRequestError(error),
        });
    });
  }

  onChangePaymentRequestProduct(paymentRequestProduct?: PaymentRequestProduct) {
    this.loadEntity(this.entity.id);
  }
}
