import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { User } from '@/data/core/models/user';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TaskRequestParams } from '@/data/scheduling/services/task.service';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationService } from '@/shared/services/navigation-service.service';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { first, takeUntil, tap } from 'rxjs/operators';
import { getInfoUser } from '@/state/selectors/info.selector';
import { ServerResponse } from '@/core/schemes/server-response';
import { ChangeCompany, CompanyAccess } from '@/data/company/models';
import {
  CompanyAccessRequestParams,
  CompanyAccessService,
} from '@/data/company/services/company-access.service';
import { CompanyService } from '@/data/company/services/company-base.service';
import { toggleLoading } from '@/state/actions/app.actions';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-company-change-dialog',
  templateUrl: './user-change-company-dialog.component.html',
  styleUrls: ['./user-change-company-dialog.component.scss'],
})
export class UserChangeCompanyDialogComponent implements OnInit, OnDestroy {
  public initialized = false;
  public currentUser: User;
  public filterFromGroup: UntypedFormGroup;

  public displayedColumns = ['company__name', 'roles__name', 'actions'];

  public defaultParams: CompanyAccessRequestParams =
    new CompanyAccessRequestParams(1, '', '-id', 25);
  public storedParams: CompanyAccessRequestParams;

  public isLoading = true;
  public rows: CompanyAccess[] = [];
  public rowsCount = 0;

  private listSubscription$: Subscription;
  private componentDestroyed$ = new Subject();

  constructor(
    private dialogRef: MatDialogRef<UserChangeCompanyDialogComponent>,
    public store: Store<AppState>,
    public companyAccessService: CompanyAccessService,
    public companyService: CompanyService,
    public router: Router,
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private navigation: NavigationService,
    private uiHelperService: UIHelperService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
    }).subscribe(({ currentUser }) => {
      this.defaultParams.user = currentUser.id;

      this.storedParams = Object.assign(
        new TaskRequestParams(),
        this.defaultParams
      );

      this.initialized = true;
      this.currentUser = currentUser;
      this.setupFilterForm();
      this.hydrateForm();
      this.list();
    });
  }

  setupFilterForm() {
    this.filterFromGroup = this.fb.group({
      search: [null],
      approval_state: [],
      status: [],
      date__lte: [],
      date__gte: [],
    });

    this.filterFromGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        const params = Object.assign(this.defaultParams, values);
        delete params.ordering;

        this.storedParams = Object.assign(this.storedParams, params);
        this.list();
      });
  }

  hydrateForm() {
    this.filterFromGroup.patchValue(this.storedParams, {
      emitEvent: false,
      onlySelf: true,
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }
  }

  listSubscription(
    params?: TaskRequestParams
  ): Observable<ServerResponse<CompanyAccess>> {
    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }

    return this.companyAccessService.list(this.storedParams).pipe(
      takeUntil(this.componentDestroyed$),
      first(),
      tap(response => {
        this.rows = response.results;
        this.rowsCount = response.count;
      })
    );
  }

  list() {
    this.isLoading = true;

    this.listSubscription$ = this.listSubscription().subscribe(_ => {
      this.isLoading = false;
    });
  }

  getId(row) {
    return row.id;
  }

  onSort({ sorts }) {
    this.storedParams.ordering = `${sorts[0].dir === 'desc' ? '-' : ''}${
      sorts[0].prop
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  onFooterPage(event) {
    this.storedParams.page = event.page;
    this.list();
  }

  changeCompany($event: MouseEvent, companyAccess: CompanyAccess) {
    $event.preventDefault();

    this.store.dispatch(toggleLoading({ payload: true }));
    this.companyService
      .change_company({
        company_access: companyAccess.id,
      } as ChangeCompany)
      .pipe(
        takeUntil(this.componentDestroyed$),
        first(),
        tap(res => window.location.reload())
      )
      .subscribe({
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  onPaginateChange($event: PageEvent) {
    this.uiHelperService.getUISettings().rowsPerPage = $event.pageSize;
    this.storedParams.page_size = $event.pageSize;
    this.storedParams.page = $event.pageIndex + 1;
    this.list();
  }

  onSortChange($event: Sort) {
    this.storedParams.ordering = `${$event.direction === 'desc' ? '-' : ''}${
      $event.active
    }`;
    this.storedParams.page = 1;
    this.list();
  }
}
