<h2 mat-dialog-title>
    Create Floorplan using Simulation
</h2>
<mat-dialog-content>
    <form [formGroup]="form" class="mb-2" *ngIf="form">
        <div class="row">
            <div class="col-12">
                <mat-form-field class="w-100">
                    <mat-label>Name</mat-label>
                    <input matInput placeholder="Name" formControlName="name">
                    <mat-error *ngIf="form.get('name').hasError('required')">
                        This field is required
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <mat-form-field class="w-100">
                    <mat-label>Rating Type</mat-label>
                    <mat-select formControlName="rating_type">
                        <mat-option *ngFor="let item of RatingTypeLabels | defaultOrderKeyValue"
                            [value]="item.key">
                            {{ item.value }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.get('rating_type').hasError('required')">
                        This field is required
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-button color="accent" mat-raised-button (click)="save($event)">Save</button>
    <button mat-button [mat-dialog-close]="null" color="primary" mat-raised-button>Cancel</button>
</mat-dialog-actions>
