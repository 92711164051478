import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { MatDialog } from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { filter, first, takeUntil } from 'rxjs/operators';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import {
  Accreditation,
  AccreditationCycleLabelMapping,
  AccreditationNameLabelMapping,
  AccreditationRole,
  AccreditationRoleLabelMapping,
  AccreditationState,
  AccreditationStateLabelMapping,
} from '@/data/user-management/models';
import {
  AccreditationRequestParams,
  AccreditationService,
} from '@/data/user-management/services/accreditation.service';
import { UserService } from '@/data/core/services/user.service';
import {
  getUser,
  getUserAccreditations,
  getUserAccreditationsCount,
} from '@/state/selectors/user/user.selector';
import { User } from '@/data/core/models/user';
import { AccreditationPermissionService } from '@/modules/user-management/services/accreditation-permission.service';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { ServerResponse } from '@/core/schemes/server-response';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { toggleLoading } from '@/state/actions/app.actions';
import { HttpErrorResponse } from '@angular/common/http';

import {
  AccreditationChangeDialogComponent,
  AccreditationChangeDialogData,
} from '@/modules/user-management/components/accreditation-change-dialog/accreditation-change-dialog.component';

@Component({
  selector: 'app-accreditations-tab',
  templateUrl: './accreditations-tab.component.html',
  styleUrls: ['./accreditations-tab.component.scss'],
})
export class AccreditationsTabComponent implements OnInit, OnDestroy {
  protected readonly AccreditationStateLabelMapping =
    AccreditationStateLabelMapping;
  protected readonly AccreditationRoleLabelMapping =
    AccreditationRoleLabelMapping;
  protected readonly AccreditationNameLabelMapping =
    AccreditationNameLabelMapping;
  protected readonly AccreditationCycleLabelMapping =
    AccreditationCycleLabelMapping;

  public displayedColumns = [
    'name',
    'accreditation_id',
    'state',
    'role',
    'date_initial',
    'expiration_date',
    'accreditation_cycle',
    'notes',
    'actions',
  ];
  public filterFormGroup: FormGroup;
  public isLoading = false;
  public rows: Accreditation[] = [];
  public rowsCount: number;

  public user: User;
  public currentUser: User;

  public defaultParams: AccreditationRequestParams =
    new AccreditationRequestParams(1, '', '-created_date', 25);
  public storedParams: AccreditationRequestParams =
    new AccreditationRequestParams(1, '', '-created_date', 25);

  public overwritePropertyValues = {};
  public overwritePropertyLabels: { [key: string]: string } = {
    state: 'Status(es)',
    role: 'Role(s)',
  };
  public excludedProperties: string[];

  public createPermissionResponse: Observable<ObjectPermissionResponse>;
  public permissions: {
    [key: string]: {
      canEdit: Observable<ObjectPermissionResponse>;
      canDelete: Observable<ObjectPermissionResponse>;
      canGenerateCertificate: Observable<ObjectPermissionResponse>;
    };
  } = {};

  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private dialog: MatDialog,
    private userService: UserService,
    private accreditationService: AccreditationService,
    private accreditationPermissionService: AccreditationPermissionService,
    private fb: FormBuilder,
    private uiHelperService: UIHelperService
  ) {}

  ngOnInit(): void {
    this.store
      .select(getUser)
      .pipe(
        filter(val => val !== null),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((entity: User) => {
        this.user = entity;
        this.createPermissionResponse =
          this.accreditationPermissionService.canCreate();
        this.list();
      });

    this.store
      .select(getUserAccreditations)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((accreditations: Accreditation[]) => {
        this.rows = accreditations;
      });

    this.store
      .select(getUserAccreditationsCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(count => {
        this.rowsCount = count;
      });

    this.setupFilterForm();
    this.list();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  setupFilterForm() {
    this.filterFormGroup = this.fb.group({
      search: [null],
      state: [null],
      role: [null],
    });
    this.filterFormGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        const params = Object.assign(this.defaultParams, values);

        this.storedParams.assignFilterFormValues({
          formValues: params,
          excludedProperties: ['ordering'],
        });

        this.overwritePropertyValues = {
          state: this.filterFormGroup.value.state?.map(
            (state: AccreditationState) => AccreditationStateLabelMapping[state]
          ),
          role: this.filterFormGroup.value.role?.map(
            (role: AccreditationRole) => AccreditationRoleLabelMapping[role]
          ),
        };

        this.list();
      });
  }

  list() {
    this.isLoading = true;
    this.userService
      .accreditations(this.user.id, this.storedParams)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data: ServerResponse<Accreditation>) => {
        this.rows = data.results;
        this.rowsCount = data.count;
        this.rows.forEach(row => {
          this.permissions[row.id] = {
            canEdit: this.accreditationPermissionService.canEdit(row),
            canDelete: this.accreditationPermissionService.canDelete(row),
            canGenerateCertificate:
              this.accreditationPermissionService.canGenerateCertificate(row),
          };
        });
        this.isLoading = false;
      });
  }

  onSortChange($event: Sort) {
    this.storedParams.ordering = `${$event.direction === 'desc' ? '-' : ''}${
      $event.active
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  onPaginateChange($event: PageEvent) {
    this.uiHelperService.getUISettings().rowsPerPage = $event.pageSize;
    this.storedParams.page_size = $event.pageSize;
    this.storedParams.page = $event.pageIndex + 1;
    this.list();
  }

  create($event: MouseEvent) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(AccreditationChangeDialogComponent, {
      width: '55%',
      disableClose: true,
      autoFocus: false,
      data: {
        entity: {
          trainee: this.user.id,
        },
      } as AccreditationChangeDialogData,
    });
    dialogRef.afterClosed().subscribe((result?: Accreditation) => {
      if (!result) return;
      this.list();
      this.uiHelperService.openSnackBar(`Accreditation successfully created`);
    });
  }

  edit($event: MouseEvent, accreditation: Accreditation) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(AccreditationChangeDialogComponent, {
      width: '55%',
      disableClose: true,
      autoFocus: false,
      data: {
        entity: accreditation,
      } as AccreditationChangeDialogData,
    });
    dialogRef.afterClosed().subscribe((result?: Accreditation) => {
      if (!result) return;
      this.list();
      this.uiHelperService.openSnackBar(`Accreditation successfully updated`);
    });
  }

  generateCertificate($event: MouseEvent, accreditation: Accreditation) {
    $event.preventDefault();
    this.store.dispatch(toggleLoading({ payload: true }));
    this.accreditationService
      .customer_hirl_certificate(accreditation.id)
      .pipe(first(), takeUntil(this.componentDestroyed$))
      .subscribe(
        data => {
          this.store.dispatch(toggleLoading({ payload: false }));
          const downloadURL = window.URL.createObjectURL(data);
          const link = document.createElement('a');
          link.href = downloadURL;
          link.download = `certificate_${accreditation.id}.pdf`;
          link.click();
        },
        error => this.uiHelperService.handleUserRequestError(error)
      );
  }

  delete($event: MouseEvent, accreditation: Accreditation) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '45%',
      data: {
        title: 'Delete Accreditation?',
        content: `Do you want to delete accreditation: ${accreditation.name}`,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) return;
      this.store.dispatch(toggleLoading({ payload: true }));
      this.accreditationService
        .delete(accreditation.id)
        .pipe(first())
        .subscribe({
          next: () => {
            this.list();
            this.store.dispatch(toggleLoading({ payload: false }));
            this.uiHelperService.openSnackBar(
              `Accreditation successfully deleted`
            );
          },
          error: (error: HttpErrorResponse) =>
            this.uiHelperService.handleUserRequestError(error),
        });
    });
  }
}
