import { Component } from '@angular/core';
import { BaseAppliancesDirective } from '../base-appliances.directive';
import {
  AppliancesBackendDict,
  DryerEfficiencyUnitLabels,
  FIELD_LABELS,
  createClothesDryerForm,
} from '@/data/simulation/models/Appliances';
import { FormGroup } from '@angular/forms';
import { FuelTypeLabels } from '@/data/simulation/enumerations';

@Component({
  selector: 'app-clothes-dryer',
  templateUrl: './clothes-dryer.component.html',
  styleUrls: ['./clothes-dryer.component.scss'],
})
export class ClothesDryerComponent extends BaseAppliancesDirective {
  createForm: (data: AppliancesBackendDict) => FormGroup<any> =
    createClothesDryerForm;
  fuelTypeLabels = FuelTypeLabels;
  fieldLabels = FIELD_LABELS;
  dryerEfficiencyUnitLabels = DryerEfficiencyUnitLabels;
}
