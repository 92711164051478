import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BaseDataService } from '@/core/services/base-data-service';
import { Observable } from 'rxjs';
import { DehumidifierBackendDict } from '../models/mechanicals/Dehumidifier';
import { ListRequestParams } from '@/core/schemes/request-params';
import { ServerResponse } from '@/core/schemes/server-response';

@Injectable({ providedIn: 'root' })
export class DehumidifierService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/dehumidifier/`;

  list(
    params: ListRequestParams
  ): Observable<ServerResponse<DehumidifierBackendDict>> {
    return this.http.get<ServerResponse<DehumidifierBackendDict>>(
      `${this.baseUrl}`,
      {
        params: this.rollParams(params),
      }
    );
  }

  update(
    dehumidifier: Partial<DehumidifierBackendDict>
  ): Observable<DehumidifierBackendDict> {
    return this.http.patch<DehumidifierBackendDict>(
      `${this.baseUrl}${dehumidifier.id}/`,
      dehumidifier
    );
  }
}
