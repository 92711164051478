<h2 mat-dialog-title>
    Change Company
</h2>
<ng-container *ngIf="initialized else loading">
    <mat-dialog-content>
        <div class="container">
            <div class="row">
                <div class="col-6">
                    <form [formGroup]="filterFromGroup">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Search</mat-label>
                            <app-search-input formControlName="search"
                                              placeholder="Type to search something"></app-search-input>
                        </mat-form-field>
                    </form>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="row" *ngIf="isLoading">
                        <div class="col-12">
                            <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
                        </div>
                    </div>
                    <table mat-table
                           [dataSource]="rows"
                           class="table"
                           matSort
                           [matSortActive]="storedParams.toTableSort().active"
                           [matSortDirection]="storedParams.toTableSort().direction"
                           (matSortChange)="onSortChange($event)">

                        <ng-container matColumnDef="company__name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Company</th>
                            <td mat-cell *matCellDef="let row">
                                <app-company-display [company]="row.company_info" displayType="nameAndTypeAsLink"></app-company-display>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="roles__name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Roles</th>
                            <td mat-cell *matCellDef="let row">
                                <ng-container *ngIf="row.roles_info.length; else noRoles;">
                                <span
                                    *ngFor="let role of row.roles_info; let isLast=last">{{ role.name }}{{isLast ? '' : ', '}}</span>
                                </ng-container>
                                <ng-template #noRoles>
                                    <span>General User</span>
                                </ng-template>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef>Actions</th>
                            <td mat-cell *matCellDef="let row">
                                <button mat-raised-button color="accent" href="#" (click)="changeCompany($event, row);">
                                    Change
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>

                    <mat-paginator [length]="rowsCount"
                                   [pageIndex]="$any(storedParams.page) - 1"
                                   [pageSize]="storedParams.page_size"
                                   (page)="onPaginateChange($event)"
                                   aria-label="Select page">
                    </mat-paginator>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-raised-button color="accent" [mat-dialog-close]="false">
            Close
        </button>
    </mat-dialog-actions>
</ng-container>
<ng-template #loading>
    <mat-dialog-content>
        <div class="row">
            <div class="col-12">
                <div class="d-flex justify-content-center" style="height: 320px;">
                    <mat-spinner [diameter]="100"></mat-spinner>
                </div>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>
