<div class="card position-relative" appFocusTarget entityName="lights" [entityId]="entityId">
    <app-loading-overlay *ngIf="loading"></app-loading-overlay>
    <div class="card-header">
        <span>Lights</span>
    </div>
    <div class="card-body pb-0">
        <app-form formName="lightsForm" [formGroup]="form" (save)="onSave($event)">
            <div class="row mb-2">
                <div class="col-2"></div>
                <div class="col-3">
                    <label for="Interior">Interior</label> (%)
                </div>
                <div class="col-3">
                    <label for="Exterior">Exterior</label> (%)
                </div>
                <div class="col-3">
                    <label for="Garage">Garage</label> (%)
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label for="">CFL:</label>
                </div>
                <div class="col-3">
                    <app-input formControlName="interior_cfl_percent" type="number"></app-input>
                </div>
                <div class="col-3">
                    <app-input formControlName="exterior_cfl_percent" type="number"></app-input>
                </div>
                <div class="col-3">
                    <app-input formControlName="garage_cfl_percent" type="number"></app-input>
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label for="">Florescent:</label>
                </div>
                <div class="col-3">
                    <app-input formControlName="interior_florescent_percent" type="number"></app-input>
                </div>
                <div class="col-3">
                    <app-input formControlName="exterior_florescent_percent" type="number"></app-input>
                </div>
                <div class="col-3">
                    <app-input formControlName="garage_florescent_percent" type="number"></app-input>
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label for="">LED:</label>
                </div>
                <div class="col-3">
                    <app-input formControlName="interior_led_percent" type="number"></app-input>
                </div>
                <div class="col-3">
                    <app-input formControlName="exterior_led_percent" type="number"></app-input>
                </div>
                <div class="col-3">
                    <app-input formControlName="garage_led_percent" type="number"></app-input>
                </div>
            </div>
            <br>
            <div class="row total-row">
                <div class="col-2 light-color">Incandescent</div>
                <div class="col-3" [ngClass]="lightsCoverge.interior > 100 ? 'red-color' : 'light-color' ">
                    {{ 100 - lightsCoverge.interior }}
                </div>
                <div class="col-3" [ngClass]="lightsCoverge.exterior > 100 ? 'red-color' : 'light-color' ">
                    {{ 100 - lightsCoverge.exterior }}
                </div>
                <div class="col-3" [ngClass]="lightsCoverge.garage > 100 ? 'red-color' : 'light-color' ">
                    {{ 100 - lightsCoverge.garage }}
                </div>
            </div>
        </app-form>
    </div>
</div>
