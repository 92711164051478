import { createReducer, on } from '@ngrx/store';
import * as LightsActions from './actions';
import { LightsBackendDict } from '@/data/simulation/models/lights/Lights';
import { BaseState } from '../shared/base.state';
import { addStateErrorCount } from '../helper';

export type LightsState = BaseState<LightsBackendDict>;

export const initialState: LightsState = {
  entities: {},
  loading: {},
  errors: {},
};

export const reducer = createReducer(
  initialState,
  on(LightsActions.loadLightsSuccess, (state, { lights, errors }) => ({
    entities: {
      ...state.entities,
      [lights.id]: lights,
    },
    loading: {
      ...state.loading,
      [lights.id]: false,
    },
    errors: {
      ...state.errors,
      ...addStateErrorCount(errors),
    },
  })),

  on(LightsActions.updateLights, (state, { lightsChanges }) => ({
    ...state,
    loading: {
      ...state.loading,
      [lightsChanges.id]: true,
    },
  })),

  on(LightsActions.updateLightsFailure, (state, { id }) => ({
    ...state,
    loading: {
      ...state.loading,
      [id]: false,
    },
  }))
);
