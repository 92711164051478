<div class="container-lg" *ngIf="initialized">
  <div class="row">
    <div class="col-12">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#" [routerLink]="['/', ]">Home</a></li>
          <li class="breadcrumb-item">
            <a href="#" [routerLink]="['/', 'hi', 'product', 'agreements', 'list']">NGBS Manufacturer Agreements</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Manufacturer Agreement for
            <app-company-display [company]="productAgreement.company_info"></app-company-display>
          </li>
        </ol>
      </nav>
    </div>
  </div>

  <ng-container *ngIf="currentUser.company_info?.slug === CustomerHIRLSettings.companySlug || currentUser.is_superuser; else userStateAlert">
    <ng-container [ngSwitch]="productAgreement.state">
      <ng-container *ngSwitchCase="HIRLProductAgreementState.new">
        <div class="row">
          <div class="col-12">
            <i>
              Please review the enrollment information provided and contact the client directly to
              resolve any issues. Once reviewed, approve the enrollment by clicking "Approve
              client
              Enrollment".
            </i>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-12 text-center">
            <button mat-raised-button color="accent" (click)="approve($event)">
              Approve Product Manufacturer Enrollment
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="HIRLProductAgreementState.approved">
        <div class="row">
          <div class="col-12">
            <i>
              If the legal agreement has been
              signed by
              the client, initiate counter-signing of the legal agreement by clicking "Route
              Agreement
              for Counter-Signing".
            </i>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-12 text-center">
            <app-permission-mat-button [permissionResponse]="counterSignPermissionResponse" (action)="counterSign($event)">
              Route Agreement for Counter-Signing
            </app-permission-mat-button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="HIRLProductAgreementState.verified">
        <div class="row">
          <div class="col-12">
            <i>
              Legal agreement has been routed internally for counter-signature. Please update the
              agreement and certificate of insurance start and expiration dates based on the
              documents
              provided by the client.
            </i>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="HIRLProductAgreementState.countersigned">
        <div class="row">
          <div class="col-12">
            <i>
              Client enrollment has been completed. Legal agreement has been signed by both parties.
            </i>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-template #userStateAlert>
    <ng-container [ngSwitch]="productAgreement.state">
      <ng-container *ngSwitchCase="HIRLProductAgreementState.new">
        <div class="row">
          <div class="col-12">
            <i>
              Your enrollment has been forwarded to NGBS Program Administration Staff for review
              and
              approval. The signing of the legal agreement
              is
              completed using DocuSign. Please check your email inbox for correspondence regarding
              the
              legal agreement.
            </i>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="HIRLProductAgreementState.approved">
        <div class="row">
          <div class="col-12">
            <i>
              Your enrollment has been forwarded to NGBS Program Administration Staff for review
              and
              approval. Note that enrollment will only be completed once both signed legal
              agreement. The signing of the legal agreement
              is
              completed using DocuSign. Please check your email inbox for correspondence regarding
              the
              legal agreement. The certificate of insurance may be submitted to NGBS from this
              page
              (see instructions below).
            </i>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="HIRLProductAgreementState.verified">
        <div class="row">
          <div class="col-12">
            <i>
              The NGBS Program Administration Staff has approved your enrollment. The legal
              agreement
              is being counter-signed and a copy of the fully executed legal agreement will be
              emailed
              to you and will also be available to you on the "Documents" tab below.
            </i>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="HIRLProductAgreementState.countersigned">
        <div class="row">
          <div class="col-12">
            <i>
              Your enrollment has been completed. Copies of fully executed legal agreement are available on the
              "Documents" tab below. If
              you have
              any questions regarding your enrollment status, please contact NGBS <a
              href="mailto:greenproducts@homeinnovation.com">greenproducts@homeinnovation.com</a>.
            </i>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-template>

  <div class="row mb-2 mt-2">
    <div class="col-12">
      <mat-divider></mat-divider>
    </div>
  </div>

  <div class="row">
    <div class="col-12 text-right">
<!--      <app-permission-mat-button matTooltip="Force State" class="mr-2" [permissionResponse]="updateDocusignStatusPermissionResponse" [hideOnFalse]="true" (action)="forceState($event)">-->
<!--        Force State-->
<!--      </app-permission-mat-button>-->
      <app-permission-mat-button matTooltip="Update Docusign Status" class="mr-2" [permissionResponse]="updateDocusignStatusPermissionResponse" [hideOnFalse]="true" (action)="updateDocusignStatus($event)">
        Update Status
      </app-permission-mat-button>
      <app-permission-mat-button matTooltip="Re-send email for current step recipient" class="mr-2" [permissionResponse]="reSendEmailPermissionResponse" [hideOnFalse]="true" (action)="reSendEmail($event)">
        Re-send email
      </app-permission-mat-button>
      <app-history-mat-button [objectId]="productAgreement.id" modelClassName="hirl_product_agreement" class="mr-2">
        History
      </app-history-mat-button>
      <app-permission-mat-button matTooltip="Completely delete this agreement and all related information about it" class="mr-2" [permissionResponse]="deletePermissionResponse" [hideOnFalse]="true" (action)="delete($event)">
        Delete
      </app-permission-mat-button>
      <app-permission-mat-button [permissionResponse]="editPermissionResponse" [hideOnFalse]="true" (action)="edit($event)">
        Edit
      </app-permission-mat-button>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-12">
      <mat-divider></mat-divider>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-6">
      <div class="d-flex-column flex-fill">
        <div class="d-flex flex-row">
          <div class="ca-attr-name">
            Company Name:
          </div>
          <div class="flex-fill">
            <app-company-display [company]="productAgreement.company_info" displayType="onlyNameAsLink"></app-company-display>
          </div>
        </div>
        <div class="d-flex flex-row" *ngIf="productAgreement.signed_agreement && (productAgreement.state === HIRLProductAgreementState.countersigned || productAgreement.state === HIRLProductAgreementState.expired)">
          <div class="ca-attr-name">
            Signed Agreement:
          </div>
          <div class="flex-fill">
            <app-customer-document-display [customerDocument]="productAgreement.signed_agreement_info" displayType="filenameAsLinkAndSharedLink"></app-customer-document-display>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="d-flex-column flex-fill">
        <div class="d-flex flex-row">
          <div class="ca-attr-name">
            Enrollment Status:
          </div>
          <div class="flex-fill">
            {{ HIRLProductAgreementStateLabelMapping[productAgreement.state]?.label }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-2 mb-2">
    <div class="col-12">
      <mat-divider></mat-divider>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="d-flex-column flex-fill">
        <h4>Agreement: </h4>
        <div class="d-flex flex-row">
          <div class="ca-attr-name">
            Start Date:
          </div>
          <div class="flex-fill">
            {{ productAgreement.start_date | date: 'MM/dd/yyyy' }}
          </div>
        </div>
        <div class="d-flex flex-row">
          <div class="ca-attr-name">
            Expiration Date:
          </div>
          <div class="flex-fill">
            {{ productAgreement.expiration_date | date: 'MM/dd/yyyy' }}
          </div>
        </div>
        <div class="d-flex flex-row">
          <div class="ca-attr-name">
            Version:
          </div>
          <div class="flex-fill">
            {{ productAgreement.version_to_sign }}
          </div>
        </div>
        <div class="d-flex flex-row">
          <div class="ca-attr-name">
            Created By:
          </div>
          <div class="flex-fill">
            <app-user-display [user]="productAgreement.created_by_info" displayType="onlyNameAsLink"></app-user-display>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
<!--      <h4>Certificate Of Insurance:</h4>-->
<!--      <app-hirl-client-agreement-coi-list></app-hirl-client-agreement-coi-list>-->
    </div>
  </div>

  <mat-tab-group color="accent" style="min-height: 450px;" disableRipple="true" animationDuration="0ms"
                 dynamicHeight (selectedTabChange)="onTabChange($event)" [selectedIndex]="selectedIndex" class="mt-4">
    <mat-tab>
      <ng-template mat-tab-label>
        Contact Information
      </ng-template>
      <div class="mt-2 mb-5">
        <div class="row">
          <div class="col-12">
            <h3 class="contact-info-main-title">Mailing Information:</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="d-flex-column flex-fill">
              <h4 class="contact-info-sub-title">Mailing Address:</h4>
              <div class="d-flex flex-row">
                <div class="d-flex flex-row flex-fill">
                  <app-address-display [geocode]="productAgreement.mailing_geocode_info"
                                       [geocodeBrokeredResponse]="productAgreement.mailing_geocode_response_info"></app-address-display>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3 mb-3">
          <div class="col-12">
            <mat-divider></mat-divider>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <h3 class="contact-info-main-title">Contact Information:</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="d-flex-column flex-fill">
              <h4 class="contact-info-sub-title">Primary Contact:</h4>
              <div class="d-flex flex-row">
                <div class="ca-attr-name">
                  First Name:
                </div>
                <div class="flex-fill">
                  {{ productAgreement.primary_contact_first_name || '-' }}
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="ca-attr-name">
                  Last Name:
                </div>
                <div class="flex-fill">
                  {{ productAgreement.primary_contact_last_name || '-' }}
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="ca-attr-name">
                  Title:
                </div>
                <div class="flex-fill">
                  {{ productAgreement.primary_contact_title || '-' }}
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="ca-attr-name">
                  Email Address:
                </div>
                <div class="flex-fill">
                  {{ productAgreement.primary_contact_email_address || '-' }}
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="ca-attr-name">
                  Phone Number:
                </div>
                <div class="flex-fill">
                  {{ productAgreement.primary_contact_phone_number || '-' }}
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="ca-attr-name">
                  Cell Number:
                </div>
                <div class="flex-fill">
                  {{ productAgreement.primary_contact_cell_number || '-' }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        Documents
        <fa-icon [icon]="['fas', 'spinner']" [spin]="true" class="ml-1"
                 *ngIf="productAgreementDocumentsCount === -1"></fa-icon>
        <span class="badge badge-primary ml-1" *ngIf="productAgreementDocumentsCount !== -1">
                            {{ productAgreementDocumentsCount }}
                        </span>
      </ng-template>
      <div class="mt-2 mb-5">
        <app-customer-document-tab [modelClassName]="'hirl_product_agreement'" [objectId]="productAgreement.id" [canCreate]="true"></app-customer-document-tab>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        Notes
        <fa-icon [icon]="['fas', 'spinner']" [spin]="true" class="ml-1"
                 *ngIf="productAgreementNotesCount === -1"></fa-icon>
        <span class="badge badge-primary ml-1" *ngIf="productAgreementNotesCount !== -1">
                            {{ productAgreementNotesCount }}
                        </span>
      </ng-template>
      <div class="mt-2 mb-5">
        <app-annotation-tab [annotationTypeSlug]="'note'" [modelClassName]="'hirl_product_agreement'" [objectId]="productAgreement.id"></app-annotation-tab>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        Docusign Information
      </ng-template>
      <div class="mt-2 mb-5">
        <div class="row">
          <div class="col-12">
            <div class="d-flex flex-row">
              <div class="ca-attr-name">
                Envelope ID:
              </div>
              <div class="flex-fill">
                {{ productAgreement.docusign_data?.envelope_id || '-' }}
              </div>
            </div>
            <div class="d-flex flex-row">
              <div class="ca-attr-name">
                Status:
              </div>
              <div class="flex-fill">
                {{ productAgreement.docusign_data?.status || '-' }}
              </div>
            </div>
            <div class="d-flex flex-row">
              <div class="ca-attr-name">
                Status Message:
              </div>
              <div class="flex-fill">
                {{ productAgreement.docusign_data?.status_message || '-' }}
              </div>
            </div>
            <div class="d-flex flex-row">
              <div class="ca-attr-name">
                Recipient Email:
              </div>
              <div class="flex-fill">
                {{ productAgreement.docusign_data?.recipient_email || '-' }}
              </div>
            </div>
            <div class="d-flex flex-row">
              <div class="ca-attr-name">
                Delivered Time:
              </div>
              <div class="flex-fill">
                {{ productAgreement.docusign_data?.delivered_time | date: 'MM/dd/yyyy HH:MM' || '-' }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>

  <div class="row">
    <div class="col-12">
      <h4>Preview of Manufacturer Agreement (electronic signature will be required via DocuSign):</h4>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <iframe style="width: 100%; height: 25rem"
              src="assets/pages/hirl-product-agreement-detail/product_embedded_agreement_v1.pdf"></iframe>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      By clicking "Submit for Review and Approval by Home Innovation," you are acknowledging that
      your company agrees to the legal terms and conditions as stated above. Once you click,
      a legal agreement will be emailed to the Primary Contact specified above for signature.
      The legal agreement must be signed, dated, and returned in order
      for Home Innovation to initiate review of the submitted product(s).
      If you do not agree to the terms or if you have any questions prior to proceeding,
      you may contact the NGBS Green team at
      <a href="mailto:greenproducts@homeinnovation.com">greenproducts@homeinnovation.com</a>.
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <a href="assets/pages/hirl-product-agreement-detail/product_embedded_agreement_v1.pdf">Click here to download the
        sample PDF.</a>
    </div>
  </div>
</div>
