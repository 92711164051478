import { EEPProgramHomeStatusInfo } from '@/data/home/models';
import { CompanyInfo } from '@/data/company/models';
import { ProductInfo } from '@/data/product/models';
import { InvoiceItemInfo } from '@/data/invoicing/models/invoice';

export enum InvoiceItemGroupCategory {
  appealsFees = 'appeals_fees',
}

export const InvoiceItemGroupCategoryLabelMapping: Record<
  InvoiceItemGroupCategory,
  any
> = {
  [InvoiceItemGroupCategory.appealsFees]: 'Appeals Fee',
};

export class InvoiceItemGroup {
  id?: string;
  invoice: number;
  invoice_info: number;
  home_status: number;
  home_status_info: EEPProgramHomeStatusInfo;

  product: number;
  product_info?: ProductInfo;

  items_info?: InvoiceItemInfo[];
  category?: InvoiceItemGroupCategory;
  total: number;
  updated_at: string;
  created_at: string;
}

export class CustomerHIRLInvoiceManagementList {
  id?: string;
  home_status_state?: string;
  hirl_project_address?: string;
  home_id?: string;
  hirl_project_id?: string;
  hirl_project_registration_id?: string;
  eep_program_id?: string;
  eep_program_name?: string;
  client_ca_status?: string;
  client?: CompanyInfo;
  total?: number;
  created_at: string;
  subdivision_name?: string;
  subdivision_url?: string;
}
