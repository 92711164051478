import {
  AuxEnergyUnitLabels,
  CoolingEfficiencyUnitLabels,
  FuelTypeLabels,
  HeatingCoolingCapacityUnitLabels,
  MechanicalMotorTypeLabels,
  TemperatureUnitLabels,
} from '@/data/simulation/enumerations';
import {
  GroundSourceHeatPumpBackendDict,
  GroundSourceHeatPumpHeatingEfficiencyUnitLabels,
  createGroundSourceHeatPumpForm,
  FIELD_LABELS,
} from '@/data/simulation/models/mechanicals/GroundSourceHeatPump';
import { updateGroundSourceHeatPump } from '@/modules/simulation/state/ground-source-heat-pump/actions';
import { selectGroundSourceHeatPumpViewSet } from '@/modules/simulation/state/ground-source-heat-pump/selectors';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-ground-source-heat-pump',
  templateUrl: './ground-source-heat-pump.component.html',
  styleUrls: ['./ground-source-heat-pump.component.scss'],
})
export class GroundSourceHeatPumpComponent implements OnInit {
  @Input() entityId: number;
  fieldLabels = FIELD_LABELS;

  private componentDestroyed$ = new Subject();

  loading = false;
  entity: GroundSourceHeatPumpBackendDict = null;
  groundSourceHeatPumpFormGroup: FormGroup;

  heatingEfficiencyUnitLabels = GroundSourceHeatPumpHeatingEfficiencyUnitLabels;
  coolingEfficiencyUnitLabels = CoolingEfficiencyUnitLabels;
  heatingCoolingCapacityUnitLabels = HeatingCoolingCapacityUnitLabels;
  mechanicalMotorTypeLabels = MechanicalMotorTypeLabels;
  temperatureUnitLabels = TemperatureUnitLabels;
  fanPowerUnitLabels = AuxEnergyUnitLabels;
  pumpEnergyUnitLabels = AuxEnergyUnitLabels;

  constructor(public store: Store) {}

  ngOnInit() {
    this.store
      .select(selectGroundSourceHeatPumpViewSet(this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(({ data, loading }) => {
        this.entity = data;
        this.loading = loading;
        if (loading) return;

        if (this.groundSourceHeatPumpFormGroup) {
          this.groundSourceHeatPumpFormGroup.patchValue(data);
        } else {
          this.groundSourceHeatPumpFormGroup =
            createGroundSourceHeatPumpForm(data);
        }
      });
  }

  onSave(changedValues: Partial<GroundSourceHeatPumpBackendDict>) {
    this.store.dispatch(
      updateGroundSourceHeatPump({
        groundSourceHeatPumpChanges: changedValues,
      })
    );
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  protected readonly fuelTypeLabels = FuelTypeLabels;
}
