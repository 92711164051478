import { createSelector } from '@ngrx/store';
import { SimulationState } from '../reducers';
import { selectSimulationFeature } from '../simulation.selectors';
import { FrameFloorBackendDict } from '@/data/simulation/models/enclosure/FrameFloor';

export const selectFrameFloor = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.frameFloor.entities[id]
  );
const selectFrameFloorLoadingStatus = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.frameFloor.loading[id]
  );

export const selectFrameFloorTypeId = (id: number) =>
  createSelector(selectFrameFloor(id), frameFloor => frameFloor.type);

export const selectFrameFloorViewSet = (id: number) =>
  createSelector(
    selectFrameFloor(id),
    selectFrameFloorLoadingStatus(id),
    (frameFloor, loadingStatus) => ({
      data: frameFloor,
      loading: loadingStatus,
    })
  );

export const selectFrameFloorName = (id: number) =>
  createSelector(
    selectFrameFloor(id),
    (frameFloor: FrameFloorBackendDict) => frameFloor.name
  );

export const selectFrameFloorChildModels = (id: number) =>
  createSelector(selectSimulationFeature, state => {
    const frameFloor = state.frameFloor.entities[id];
    return {
      frameFloorType: frameFloor.type,
    };
  });

export const selectFrameFloorNestedErrorCount = (id: number) =>
  createSelector(
    selectSimulationFeature,
    selectFrameFloorChildModels(id),
    (state, childModels) => {
      const floorTypeErrorCount = {
        errorCount: 0,
        warningCount: 0,
      };
      if (childModels.frameFloorType !== null) {
        floorTypeErrorCount.errorCount =
          state.frameFloorType.errors[childModels.frameFloorType].errorCount;
        floorTypeErrorCount.warningCount =
          state.frameFloorType.errors[childModels.frameFloorType].warningCount;
      }

      return {
        frameFloor: {
          errorCount: state.frameFloor.errors[id].errorCount,
          warningCount: state.frameFloor.errors[id].warningCount,
        },
        frameFloorType: floorTypeErrorCount,
      };
    }
  );

export const selectFrameFloorErrorViewSet = (id: number) =>
  createSelector(
    selectFrameFloorNestedErrorCount(id),
    selectFrameFloorTypeId(id),
    selectFrameFloorName(id),
    (nestedModelsErrorCount, frameFloorTypeId, name) => {
      return {
        nestedModelsErrorCount,
        frameFloorTypeId,
        name,
      };
    }
  );
