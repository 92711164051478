import {
  RimJoistBackendDict,
  DetailedRimJoistBackendDict,
} from '@/data/simulation/models/enclosure/RimJoist';
import { createAction, props } from '@ngrx/store';
import { ModelErrors, StateErrors } from '../shared/base.state';

export const loadDetailedRimJoists = createAction(
  '[Simulation API] Load Detailed RimJoists',
  props<{ detailedRimJoists: DetailedRimJoistBackendDict[] }>()
);

export const loadRimJoistsSuccess = createAction(
  '[RimJoist API] Load RimJoists Success',
  props<{
    rimJoists: RimJoistBackendDict[];
    errors: StateErrors<RimJoistBackendDict>;
  }>()
);

export const updateRimJoist = createAction(
  '[RimJoist UI] Update RimJoist',
  props<{ id: number; rimJoistChanges: Partial<RimJoistBackendDict> }>()
);

export const updateRimJoistSuccess = createAction(
  '[RimJoist API] Update RimJoist Success',
  props<{
    rimJoist: RimJoistBackendDict;
    errors: ModelErrors<RimJoistBackendDict>;
  }>()
);

export const updateRimJoistFailure = createAction(
  '[RimJoist API] Update RimJoist Failure',
  props<{ id: number }>()
);

export const removeRimJoist = createAction(
  '[RimJoist UI] Remove RimJoist',
  props<{ rimJoist: RimJoistBackendDict }>()
);

export const removeRimJoistSuccess = createAction(
  '[RimJoist API] Remove RimJoist Success',
  props<{ id: number }>()
);

export const removeRimJoistFailure = createAction(
  '[RimJoist API] Remove RimJoist Failure',
  props<{ id: number }>()
);

export const updateRimJoistErrors = createAction(
  '[RimJoist UI] Update RimJoist Errors',
  props<{ id: number; errors: any }>()
);

export const setRimJoistType = createAction(
  '[RimJoistType API] Set RimJoistType',
  props<{ rimJoistId: number; rimJoistTypeId: number | null }>()
);

export const setRimJoistTypeSuccess = createAction(
  '[RimJoistType API] Set RimJoistType null',
  props<{ rimJoistId: number; rimJoistTypeId: number | null }>()
);
