import { Component, Input, OnInit } from '@angular/core';
import {
  COIDocument,
  COIDocumentInsuranceTypeMapping,
} from '@/data/customer-hirl/models';

export enum CoiDocumentDisplayComponentType {
  onlyName = 'onlyName',
  onlyNameAsLink = 'onlyNameAsLink',
  nameAsLinkAndDates = 'nameAsLinkAndDates',
}

@Component({
  selector: 'app-coi-document-display',
  templateUrl: './coi-document-display.component.html',
  styleUrls: ['./coi-document-display.component.scss'],
})
export class CoiDocumentDisplayComponent implements OnInit {
  @Input() displayType:
    | CoiDocumentDisplayComponentType
    | keyof typeof CoiDocumentDisplayComponentType =
    CoiDocumentDisplayComponentType.onlyName;
  @Input() coiDocument?: COIDocument;

  public CoiDocumentDisplayComponentType = CoiDocumentDisplayComponentType;

  ngOnInit(): void {}

  protected readonly COIDocumentInsuranceTypeMapping =
    COIDocumentInsuranceTypeMapping;
}
