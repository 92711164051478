import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { selectErrorViewSet } from '../../state/simulation.selectors';
import { ErrorMessageService } from '../../services/error-message.service';
import { StateNameToFieldLabels } from '../../state.registry';
import { LabelWithDescription } from '@/data/simulation/models/base';
import { FLAGS } from '../../errors-template';

@Component({
  selector: 'app-model-errors-tree',
  templateUrl: './model-errors-tree.component.html',
  styleUrls: ['./model-errors-tree.component.scss'],
})
export class ModelErrorsTreeComponent implements OnInit {
  @Input() entityId: number;
  @Input() entityName: string;

  componentDestroyed$ = new Subject();
  fieldErrors = {};
  fieldWarnings = {};
  modelErrors: string[][];
  modelWarnings: string[][];
  errorCount = 0;
  labels: { [key: string]: string } = {};
  flags = FLAGS;

  flagColor = {
    os_eri_strict: '#f3722c',
    home_energy_score: '#90be6d',
    resnet: '#277da1',
  };

  constructor(
    public store: Store,
    public errorMessageService: ErrorMessageService
  ) {}

  ngOnInit(): void {
    for (const item of Object.entries(
      StateNameToFieldLabels[this.entityName]
    )) {
      const k = item[0];
      const v = item[1];
      if (typeof v === 'object') {
        const value = v as LabelWithDescription;
        this.labels[k] = value.label;
      } else if (typeof v === 'string') {
        this.labels[k] = v;
      }
    }
    this.store
      .select(selectErrorViewSet(this.entityName, this.entityId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(({ errors, flags }) => {
        if (!errors) return;

        for (const [fieldName, fieldError] of Object.entries(errors.field)) {
          if (!fieldError) {
            delete this.fieldErrors[fieldName];
            delete this.fieldWarnings[fieldName];
            continue;
          }
          this.fieldErrors[fieldName] = [];
          this.fieldWarnings[fieldName] = [];
          for (const [errorId, data] of Object.entries(fieldError)) {
            const applicableFlag = FLAGS[errorId] ?? null;
            if (applicableFlag && !flags.includes(applicableFlag)) {
              continue;
            }

            if (this.errorMessageService.isWarning(errorId)) {
              this.fieldWarnings[fieldName].push([
                this.flagColor[this.flags[errorId]],
                this.errorMessageService.getMessage(errorId, data),
              ]);
              continue;
            }

            this.fieldErrors[fieldName].push([
              this.flagColor[this.flags[errorId]],
              this.errorMessageService.getMessage(errorId, data),
            ]);
          }
        }

        this.modelErrors = [];
        this.modelWarnings = [];
        for (const [errorId, data] of Object.entries(errors.model)) {
          const applicableFlag = FLAGS[errorId] ?? null;
          if (applicableFlag && !flags.includes(applicableFlag)) {
            continue;
          }

          if (this.errorMessageService.isWarning(errorId)) {
            this.modelWarnings.push([
              this.flagColor[this.flags[errorId]],
              this.errorMessageService.getMessage(errorId, data),
            ]);
            continue;
          }

          this.modelErrors.push([
            this.flagColor[this.flags[errorId]],
            this.errorMessageService.getMessage(errorId, data),
          ]);
        }

        this.errorCount = errors.errorCount;
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
