import { createReducer, on } from '@ngrx/store';
import * as FoundationWallTypeActions from './actions';
import { FoundationWallTypeBackendDict } from '@/data/simulation/models/enclosure/FoundationWallType';
import { BaseState } from '../shared/base.state';
import { addStateErrorCount } from '../helper';

export type FoundationWallTypeState = BaseState<FoundationWallTypeBackendDict>;

export const initialState: FoundationWallTypeState = {
  entities: {},
  loading: {},
  errors: {},
};

export const reducer = createReducer(
  initialState,
  on(
    FoundationWallTypeActions.loadFoundationWallTypesSuccess,
    (state, { wallTypes, errors }) => {
      const entities = wallTypes.reduce(
        (acc, wallType) => ({ ...acc, [wallType.id]: wallType }),
        {}
      );
      const loading = wallTypes.reduce(
        (acc, wallType) => ({ ...acc, [wallType.id]: false }),
        {}
      );
      return {
        entities: { ...state.entities, ...entities },
        loading: { ...state.loading, ...loading },
        errors: { ...state.errors, ...addStateErrorCount(errors) },
      };
    }
  ),

  on(
    FoundationWallTypeActions.updateFoundationWallType,
    (state, { wallTypeChanges }) => ({
      ...state,
      loading: { ...state.loading, [wallTypeChanges.id]: true },
    })
  ),

  on(
    FoundationWallTypeActions.updateFoundationWallTypeFailure,
    (state, { id }) => ({
      ...state,
      loading: { ...state.loading, [id]: false },
    })
  ),

  on(
    FoundationWallTypeActions.removeFoundationWallTypeSuccess,
    (state, { id }) => {
      const { [id]: removed, ...entities } = state.entities;
      const { [id]: removedLoading, ...loading } = state.loading;
      const { [id]: removedErrors, ...errors } = state.errors;
      return { entities, loading, errors };
    }
  )
);
