import {
  ProjectBackendDict,
  createProjectForm,
} from '@/data/simulation/models/Project';
import { BaseValidator } from './base.validator';
import { ModelErrors, StateErrors } from '../state/shared/base.state';

export class ProjectValidator extends BaseValidator {
  static validateForm(
    project: ProjectBackendDict
  ): ModelErrors<ProjectBackendDict> {
    const form = createProjectForm(project);
    return form.getFieldAndModelErros();
  }

  static validate(
    projects: ProjectBackendDict[]
  ): StateErrors<ProjectBackendDict> {
    const errors = {};
    projects.forEach(project => {
      errors[project.id] = this.validateForm(project);
    });
    return errors;
  }
}
