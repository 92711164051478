import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ETOAccount } from '@/data/customer-eto/models';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { first, map, mergeMap, takeUntil } from 'rxjs/operators';
import { ETOAccountService } from '@/data/customer-eto/services/eto-account.service';
import { toggleLoading } from '@/state/actions/app.actions';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { CompanyService } from '@/data/company/services/company-base.service';

export class EtoAccountChangeDialogData {
  etoAccount?: ETOAccount;
  commit = true;
  retrieve = true;
}

@Component({
  selector: 'app-eto-account-change-dialog',
  templateUrl: './eto-account-change-dialog.component.html',
  styleUrls: ['./eto-account-change-dialog.component.scss'],
})
export class EtoAccountChangeDialogComponent implements OnInit, OnDestroy {
  public isEditForm = false;
  public entity?: ETOAccount;
  public commit = true;
  public retrieve = true;
  public form: FormGroup;

  private componentDestroyed$ = new Subject();
  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<EtoAccountChangeDialogComponent>,
    private fb: FormBuilder,
    private companyService: CompanyService,
    private etoAccountService: ETOAccountService,
    private dialog: MatDialog,
    private uiHelperService: UIHelperService,
    @Inject(MAT_DIALOG_DATA) public data: EtoAccountChangeDialogData
  ) {
    if (data?.etoAccount) {
      this.entity = Object.assign({}, data?.etoAccount);
    }
    this.commit = data?.commit || true;
    this.retrieve = data?.retrieve || true;

    if (this.entity?.id) {
      this.isEditForm = true;
    }

    this.form = this.fb.group({
      account_number: [''],
    });
  }

  ngOnInit() {
    if (this.isEditForm) {
      if (this.retrieve) {
        this.store.dispatch(toggleLoading({ payload: true }));
        this.etoAccountService
          .retrieve(this.entity.id)
          .pipe(takeUntil(this.componentDestroyed$), first())
          .subscribe({
            next: adHocInvoiceLine => {
              this.store.dispatch(toggleLoading({ payload: false }));
              this.entity = adHocInvoiceLine;
              this.hydrateForm();
            },
            error: error => this.uiHelperService.handleUserRequestError(error),
          });
      } else {
        this.hydrateForm();
      }
    }
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  hydrateForm() {
    this.form.get('account_number')?.patchValue(this.entity?.account_number, {
      emitEvent: false,
      onlySelf: true,
    });
  }

  save($event: MouseEvent) {
    $event.preventDefault();
    if (this.isEditForm) {
      this.edit();
    } else {
      this.create();
    }
  }

  private create() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    if (!this.commit) {
      this.dialogRef.close(this.entity);
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const data = {
      company: this.entity.company,
      account_number: this.form.value.account_number,
    } as ETOAccount;

    this.etoAccountService
      .create(data)
      .pipe(
        // reload company, because ETOAccount has One-To-One relation
        mergeMap(etoAccount => {
          return this.companyService.retrieve(this.entity.company).pipe(
            map(company => {
              return { etoAccount, company };
            })
          );
        }),
        first()
      )
      .subscribe({
        next: ({ etoAccount, company }) => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close(etoAccount);
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  private edit() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    if (!this.commit) {
      this.dialogRef.close(this.entity);
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const data = {
      company: this.entity.company,
      account_number: this.form.value.account_number,
    } as ETOAccount;

    this.etoAccountService
      .update(this.entity.id, data)
      .pipe(
        mergeMap(etoAccount => {
          return this.companyService.retrieve(this.entity.company).pipe(
            map(company => {
              return { etoAccount, company };
            })
          );
        }),
        first()
      )
      .subscribe({
        next: ({ etoAccount, company }) => {
          this.store.dispatch(toggleLoading({ payload: false }));
          this.dialogRef.close(etoAccount);
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }
}
