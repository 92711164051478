import {
  EditorNavigationService,
  SubTab,
} from '@/modules/simulation/services/editor-navigation.service';
import { selectInfiltrationId } from '@/modules/simulation/state/simulation/selectors';
import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-envelope-tab',
  templateUrl: './envelope-tab.component.html',
  styleUrls: ['./envelope-tab.component.scss'],
})
export class EnvelopeTabComponent {
  componentDestroyed$ = new Subject();
  activeSubTabLink: SubTab;
  subTabs = SubTab;
  infiltrationId: number;

  constructor(
    public editorNavigationService: EditorNavigationService,
    public store: Store
  ) {}

  ngOnInit() {
    this.store
      .select(selectInfiltrationId())
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(id => (this.infiltrationId = id));

    this.editorNavigationService
      .getActiveSubTabLinkObservable()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(subTab => {
        this.activeSubTabLink = subTab;
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
