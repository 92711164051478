import {
  DuctBackendDict,
  DuctType,
} from '@/data/simulation/models/mechanicals/Duct';
import { selectDucts } from '@/modules/simulation/state/duct/selectors';
import { addDuct, removeDuct } from '@/modules/simulation/state/duct/actions';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { Location } from '@/data/simulation/enumerations';
import { selectDistributionSystemDucts } from '../../state/distribution-system/selectors';

@Component({
  selector: 'app-ducts',
  templateUrl: './ducts.component.html',
  styleUrls: ['./ducts.component.scss'],
})
export class DuctsComponent implements OnInit, OnDestroy {
  @Input() parentId: number;

  entityIds: number[];
  retunDucts: DuctBackendDict[] = [];
  supplyDucts: DuctBackendDict[] = [];
  componentDestroyed$ = new Subject();
  retunDuctIds: number[];
  supplyDuctIds: number[];
  ductTypes = DuctType;
  supplyLoading = false;
  returnLoading = false;

  constructor(public store: Store) {}

  updatePropertiesWithStore() {
    this.store
      .select(selectDucts(this.entityIds))
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(ducts => {
        this.supplyLoading = false;
        this.returnLoading = false;
        this.retunDucts = ducts.filter(duct => duct.type === DuctType.RETURN);
        this.supplyDucts = ducts.filter(duct => duct.type === DuctType.SUPPLY);
        this.retunDuctIds = this.retunDucts.map(duct => duct.id);
        this.supplyDuctIds = this.supplyDucts.map(duct => duct.id);
      });
  }

  ngOnInit() {
    this.store
      .select(selectDistributionSystemDucts(this.parentId))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(ducts => {
        this.entityIds = ducts;
        this.updatePropertiesWithStore();
      });
  }

  addNewDuct(ductType: DuctType) {
    if (ductType === DuctType.RETURN) {
      this.returnLoading = true;
    } else {
      this.supplyLoading = true;
    }
    this.store.dispatch(
      addDuct({
        duct: {
          type: ductType,
          area_percent: 0,
          location: Location.CONDITIONED_SPACE,
        },
        distributionSystemId: this.parentId,
      })
    );
  }

  removeDuct(ductId: number, ductType: DuctType) {
    if (ductType === DuctType.RETURN) {
      this.returnLoading = true;
    } else {
      this.supplyLoading = true;
    }
    this.store.dispatch(
      removeDuct({ ductId, distributionSystemId: this.parentId })
    );
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
