<h2 mat-dialog-title>
    {{ title }}
</h2>

<mat-dialog-content>
    <ngx-datatable class="material" [rows]="rows" columnMode="force" headerHeight="40" [rowHeight]="'auto'"
        [footerHeight]="50" [externalSorting]="true" (sort)="onSort($event)"
        [sorts]="[{prop: storedParams.ordering.replace('-', ''), dir: (storedParams.ordering.startsWith('-')) ? 'desc' : 'asc'}]"
        [loadingIndicator]="isLoading" #historyTable>
        <ngx-datatable-column name="Type" [sortable]="false" [width]="90">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ historyTypeLabelMapping[row.history_type] }}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="User" [sortable]="false" width="160">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <span *ngIf="!row.history_user">Administrator</span>
                <span *ngIf="row.history_user">
                    {{ row.history_user_info?.first_name }} {{ row.history_user_info?.last_name }}
                </span>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Fields" [sortable]="false">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <ul>
                    <li *ngFor="let change of row.changes">
                        {{ change.field_name }}
                    </li>
                </ul>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Previous Values" [sortable]="false">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <ul>
                    <li *ngFor="let change of row.changes">
                        {{ change.old || '-' }}
                    </li>
                </ul>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Current Values" [sortable]="false">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <ul>
                    <li *ngFor="let change of row.changes">
                        {{ change.new || '-' }}
                    </li>
                </ul>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Date" [sortable]="false" width="100">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ row.history_datetime | date: 'M/d/yy, h:mm' }}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-footer>
            <ng-template ngx-datatable-footer-template>
                <div class="col-12 text-center">
                    <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                        [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
                        [pagerNextIcon]="'datatable-icon-skip'" [page]="storedParams.page" [size]="20" [count]="count"
                        (change)="onFooterPage($event)">
                    </datatable-pager>
                </div>
            </ng-template>
        </ngx-datatable-footer>
    </ngx-datatable>
</mat-dialog-content>