import { withinRValueRange } from '@/modules/simulation/validators/helper';
import {
  createConditionalValidator,
  ModelFormControl,
  ModelFormGroup,
} from '../ModelForm';
import { Validators } from '@angular/forms';
import { LengthUnit } from '../../enumerations';
import { FieldLabels } from '../base';

// Labels
export const FIELD_LABELS: FieldLabels<RimJoistTypeBackendDict> = {
  name: 'Name',
  frame_spacing: 'Frame Spacing',
  cavity_insulation_r_value: 'Cavity Insulation R-Value',
  cavity_insulation_thickness: 'Cavity Insulation Thickness',
  cavity_insulation_grade: 'Cavity Insulation Grade',
  continuous_insulation_r_value: 'Continuous Insulation R-Value',
  units: 'Units',
  system_u_value: 'System U-Value',
  assembly_u_value: 'Assembly U-Value',
  user_defined_assembly_u_value: 'Contains Assembly Details',
};

export interface RimJoistTypeBackendDict {
  id: number;
  name: string;
  frame_spacing: number;
  cavity_insulation_r_value: number;
  cavity_insulation_thickness: number;
  cavity_insulation_grade: number;
  continuous_insulation_r_value: number;
  units: LengthUnit.FT;
  system_u_value: number;
  assembly_u_value: number;
  user_defined_assembly_u_value: boolean;
}

export const DEFAULT_RIM_JOIST_TYPE: RimJoistTypeBackendDict = {
  id: null,
  name: '',
  frame_spacing: null,
  cavity_insulation_r_value: null,
  cavity_insulation_thickness: null,
  cavity_insulation_grade: null,
  continuous_insulation_r_value: null,
  units: LengthUnit.FT,
  system_u_value: null,
  assembly_u_value: null,
  user_defined_assembly_u_value: false,
};

// Create createRimJoistTypeForm function
export function createRimJoistTypeForm(
  rimJoistType: RimJoistTypeBackendDict
): ModelFormGroup {
  const validateOnlyIfGeneratedUValue = createConditionalValidator(
    parentControls => !parentControls.user_defined_assembly_u_value.value,
    ['user_defined_assembly_u_value']
  );
  return new ModelFormGroup(
    {
      id: new ModelFormControl(rimJoistType.id),
      name: new ModelFormControl(rimJoistType.name, Validators.required),
      frame_spacing: new ModelFormControl(
        rimJoistType.frame_spacing,
        ...validateOnlyIfGeneratedUValue([
          Validators.required,
          Validators.min(0),
        ])
      ),
      cavity_insulation_r_value: new ModelFormControl(
        rimJoistType.cavity_insulation_r_value,
        ...validateOnlyIfGeneratedUValue([
          Validators.required,
          withinRValueRange(),
        ])
      ),
      cavity_insulation_thickness: new ModelFormControl(
        rimJoistType.cavity_insulation_thickness,
        ...validateOnlyIfGeneratedUValue([Validators.min(0)])
      ),
      cavity_insulation_grade: new ModelFormControl(
        rimJoistType.cavity_insulation_grade,
        ...validateOnlyIfGeneratedUValue([
          Validators.required,
          Validators.min(1),
          Validators.max(3),
        ])
      ),
      continuous_insulation_r_value: new ModelFormControl(
        rimJoistType.continuous_insulation_r_value,
        ...validateOnlyIfGeneratedUValue([Validators.required])
      ),
      system_u_value: new ModelFormControl(
        rimJoistType.system_u_value,
        ...validateOnlyIfGeneratedUValue([])
      ),
      assembly_u_value: new ModelFormControl(
        rimJoistType.assembly_u_value,
        ...validateOnlyIfGeneratedUValue([
          Validators.required,
          withinRValueRange(),
        ])
      ),
      user_defined_assembly_u_value: new ModelFormControl(
        rimJoistType.user_defined_assembly_u_value
      ),
    },
    {
      validators: [validateUvalue],
    }
  );
}

function validateUvalue(controls: ModelFormGroup) {
  const assemblyUValue = controls.get('assembly_u_value').value;
  const systemUValue = controls.get('system_u_value').value;

  if (assemblyUValue === null || assemblyUValue <= 0.01) {
    if (systemUValue === null || systemUValue === 0) {
      return { invaliRimJoistUValue: true };
    }
  }
}
