import { Component, OnInit } from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { User } from '@/data/core/models/user';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, takeUntil, map, mergeMap } from 'rxjs/operators';
import {
  IExcelJson,
  UIHelperService,
} from '@/shared/services/ui-helper.service';
import {
  CommunityRequestParams,
  CommunityService,
} from '@/data/community/services/community.service';
import {
  Community,
  CommunityFlatList,
} from '@/data/community/models/community';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { ConfirmDialogComponent } from '@/shared/components/confirm-dialog/confirm-dialog.component';
import { toggleLoading } from '@/state/actions/app.actions';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommunityPermissionService } from '@/modules/community/services/community-permission-service';
import { CommunityChangeDialogComponent } from '@/modules/community/components/community-change-dialog/community-change-dialog.component';
import * as _ from 'lodash';

@Component({
  selector: 'app-community-list-page',
  templateUrl: './community-list-page.component.html',
  styleUrls: ['./community-list-page.component.scss'],
})
export class CommunityListComponent implements OnInit {
  readonly headerHeight = 40;
  public displayedColumns = [];

  public rows: CommunityFlatList[] = [];
  public rowsCount: number;

  public isLoading = true;
  public currentUser: User;

  public searchTerm$: Subject<string> = new Subject<string>();

  public communityDisplayNamePlural: string;
  public filterFromGroup: FormGroup;
  public createPermissionResponse: Observable<ObjectPermissionResponse>;

  public defaultParams: CommunityRequestParams = new CommunityRequestParams(
    1,
    '',
    '-id'
  );
  public storedParams: CommunityRequestParams;
  public ColumnMode = ColumnMode;

  public permissions: {
    [key: string]: {
      canRemoveAssociation: Observable<ObjectPermissionResponse>;
    };
  } = {};

  private listSubscription$: Subscription;
  private componentDestroyed$ = new Subject();

  constructor(
    public store: Store<AppState>,
    public communityService: CommunityService,
    public router: Router,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private communityPermissionService: CommunityPermissionService,
    private uiHelperService: UIHelperService
  ) {}

  ngOnInit() {
    this.setupFilterForm();
    this.activatedRoute.params
      .pipe(
        takeUntil(this.componentDestroyed$),
        mergeMap(params => {
          this.rows = [];
          this.rowsCount = 0;

          return forkJoin({
            queryParams: this.activatedRoute.queryParams.pipe(first()),
            currentUser: this.store.select(getInfoUser).pipe(first()),
          }).pipe(
            map(({ queryParams, currentUser }, index) => {
              return { params, queryParams, currentUser };
            })
          );
        })
      )
      .subscribe(({ params, queryParams, currentUser }) => {
        this.storedParams = _.cloneDeep(this.defaultParams);
        this.storedParams.page_size =
          this.uiHelperService.getUISettings().rowsPerPage;
        this.storedParams.assignQueryParams(queryParams);
        this.currentUser = currentUser;
        this.createPermissionResponse =
          this.communityPermissionService.canCreate();
        if (this.currentUser.is_superuser) {
          this.displayedColumns = ['name', 'city__name', 'cross_roads'];
        } else {
          this.displayedColumns = [
            'name',
            'city__name',
            'cross_roads',
            'associations',
          ];
        }
        this.hydrateForm();
        this.list();
      });
  }

  hydrateForm() {
    this.filterFromGroup.patchValue(this.storedParams, {
      emitEvent: false,
      onlySelf: true,
    });

    this.filterFromGroup.patchValue(
      {
        city__country__abbr_info: this.storedParams.city__country,
      },
      { emitEvent: false, onlySelf: true }
    );

    this.filterFromGroup.patchValue(
      {
        city__county__state_info: this.storedParams.city__county__state,
      },
      { emitEvent: false, onlySelf: true }
    );
  }

  create($event: MouseEvent) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(CommunityChangeDialogComponent, {
      width: '55%',
      disableClose: true,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result?: Community) => {
      if (!result) {
        return;
      }
      this.list();
      this.uiHelperService.openSnackBar(
        `Community ${result.name} Successfully Created`
      );
    });
  }

  setupFilterForm() {
    this.filterFromGroup = this.fb.group({
      search: [null],
      city__country_info: [null],
      city__county__state_info: [null],
    });

    this.filterFromGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        const params = _.merge({}, this.defaultParams, values);
        delete params.page_size;
        delete params.ordering;
        delete this.storedParams.city__county__state;
        delete this.storedParams.city__country;

        if (params.city__country_info) {
          params.city__country = params.city__country_info.map(
            country => country.id
          );
        } else {
          params.city__country = null;
        }
        delete params.city__country_info;

        if (params.city__county__state_info) {
          params.city__county__state = params.city__county__state_info;
        } else {
          params.city__county__state = null;
        }
        delete params.city__county__state_info;

        this.storedParams = _.merge(this.storedParams, params);
        this.list();
      });
  }

  list() {
    this.isLoading = true;

    // populate params to query string
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.storedParams.toQueryParams(),
      replaceUrl: true,
    });

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }

    this.communityService
      .flat_list(this.storedParams)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(data => {
        this.rows = data.results;
        this.rowsCount = data.count;
        this.isLoading = false;
        this.rows.forEach(row => {
          this.permissions[row.id] = {
            canRemoveAssociation:
              this.communityPermissionService.canRemoveAssociation(row.id),
          };
        });
      });
  }

  onRemoveAssociation($event: MouseEvent, coummity: Community) {
    $event.preventDefault();

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '45%',
      data: {
        title: 'Remove Association ?',
        content: `Do you want to delete association with: ${coummity.name} organization ?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (!result) {
          return;
        }
        this.store.dispatch(toggleLoading({ payload: true }));
        this.communityService
          .relationships_remove(coummity.id)
          .pipe(first())
          .subscribe(() => {
            this.list();
            this.store.dispatch(toggleLoading({ payload: false }));
          });
      });
  }

  resetFilters($event: MouseEvent) {
    $event.preventDefault();
    this.filterFromGroup.reset();
  }

  onPaginateChange($event: PageEvent) {
    this.uiHelperService.getUISettings().rowsPerPage = $event.pageSize;
    this.storedParams.page_size = $event.pageSize;
    this.storedParams.page = $event.pageIndex + 1;
    this.list();
  }

  onSortChange($event: Sort) {
    this.storedParams.ordering = `${$event.direction === 'desc' ? '-' : ''}${
      $event.active
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  onFooterPage(event) {
    this.storedParams.page = event.page;
    this.list();
  }

  exportToExcel($event: MouseEvent) {
    $event.preventDefault();
    const excelData: Array<IExcelJson> = [];
    const udt: IExcelJson = {
      data: [
        {
          A: 'AXIS ID',
          B: 'Name',
          C: 'City(Metro)',
          D: 'Crossroads',
        }, // table header
      ],
      skipHeader: true,
    };
    this.rows.forEach(community => {
      udt.data.push({
        A: community.id,
        B: community.name,
        C: community.city_metro,
        D: community.cross_roads,
      });
    });
    excelData.push(udt);

    this.uiHelperService.exportJsonToExcel(excelData, 'communities');
  }

  exportToCSV($event: MouseEvent) {
    $event.preventDefault();
    const data = [];
    this.rows.forEach(community => {
      data.push({
        'AXIS ID': community.id,
        Name: community.name,
        'City (Metro)': community.city_metro,
        Crossroads: community.cross_roads,
      });
    });
    this.uiHelperService.exportToCsv(data, 'communities', [
      'AXIS ID',
      'Name',
      'City (Metro)',
      'Crossroads',
    ]);
  }
}
