import { SimulationBackendDict } from '@/data/simulation/models/simulation';
import { SimulationService } from '@/data/simulation/services/simulation.service';
import { getInfoUser } from '@/state/selectors/info.selector';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SimulationRightsService {
  public editingAllowed = false;

  public canEdit = new BehaviorSubject<boolean>(false);

  constructor(
    private store: Store,
    private simulationService: SimulationService
  ) {}

  init(simulation: SimulationBackendDict) {
    const homeStauses$ = this.simulationService.getObject(
      simulation.id,
      'home_project_status'
    );
    const userInfo$ = this.store.select(getInfoUser).pipe(first());

    forkJoin({
      userInfo: userInfo$,
      homeStauses: homeStauses$,
    })
      .pipe(first())
      .subscribe(({ userInfo, homeStauses }) => {
        if (userInfo.is_superuser) {
          this.canEdit.next(true);
          return;
        }

        if (userInfo.company == simulation.company) {
          this.canEdit.next(true);
          return;
        }

        if (
          !homeStauses['results'].find(status => status['state'] === 'complete')
        ) {
          this.canEdit.next(true);
          return;
        }
      });
  }

  setEditingAllowed(canEdit: boolean) {
    this.editingAllowed = canEdit;
    this.canEdit.next(canEdit);
  }
}
