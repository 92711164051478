import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import {
  HIRLProject,
  HIRLProjectCommercialSpaceTypeLabelMapping,
  HIRLProjectRegistrationAppealsLabelMapping,
  HIRLProjectRegistrationStateLabelMapping,
  HIRLProjectRegistrationType,
  HIRLProjectRegistrationTypeLabelMapping,
} from '@/data/customer-hirl/models';
import { User } from '@/data/core/models/user';
import {
  HIRLProjectRequestParams,
  HIRLProjectService,
} from '@/data/customer-hirl/services/hirl-projects.service';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UserRequestParams } from '@/data/core/services/user.service';
import { CompanyType } from '@/data/company/models';
import {
  IExcelJson,
  UIHelperService,
} from '@/shared/services/ui-helper.service';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { ConfirmedGeocodeResponse } from '@/modules/geocoder/components/confirm-geocode-response-dialog/confirm-geocode-response';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-customer-hirl-projects-list',
  templateUrl: './hirl-project-list.component.html',
  styleUrls: ['./hirl-project-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HIRLProjectListComponent implements OnInit, OnDestroy {
  protected readonly HIRLProjectRegistrationType = HIRLProjectRegistrationType;

  public displayedColumns = [];
  public typeLabelMapping = HIRLProjectRegistrationTypeLabelMapping;
  public appealsLabelMapping = HIRLProjectRegistrationAppealsLabelMapping;
  public stateLabelMapping = HIRLProjectRegistrationStateLabelMapping;
  public projectCommercialSpaceType =
    HIRLProjectCommercialSpaceTypeLabelMapping;
  public customerHIRLSettings = CustomerHIRLSettings;

  public filterFromGroup: UntypedFormGroup;

  public currentUser: User;
  public projects: HIRLProject[];
  public projectsCount: number;
  public projectsIsLoading = true;

  public registrationUserControlRequestParams: UserRequestParams;

  public defaultParams: HIRLProjectRequestParams = new HIRLProjectRequestParams(
    1,
    '',
    '-id',
    25
  );
  public storedParams: HIRLProjectRequestParams = Object.assign(
    new HIRLProjectRequestParams(),
    this.defaultParams
  );

  private listSubscription$: Subscription;
  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private hirlProjectService: HIRLProjectService,
    private router: Router,
    private dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private uiHelperService: UIHelperService
  ) {
    this.registrationUserControlRequestParams = new UserRequestParams();
    this.registrationUserControlRequestParams.company__company_type = [
      CompanyType.rater,
    ];
  }

  ngOnInit() {
    forkJoin({
      queryParams: this.activatedRoute.queryParams.pipe(first()),
      currentUser: this.store.select(getInfoUser).pipe(first()),
    }).subscribe(({ queryParams, currentUser }) => {
      this.storedParams.assignQueryParams(queryParams);
      this.currentUser = currentUser;

      this.displayedColumns = [
        'id',
        'name',
        'address',
        'program',
        'type',
        'status',
        'registeredBy',
      ];

      this.setupFilterForm();
      this.hydrateForm();
      this.list();
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  setupFilterForm() {
    this.filterFromGroup = this.fb.group({
      search: [null],
      registration__project_type: [null],
      registration__state: [null],
      appeals_project: [null],
      is_accessory_structure: [null],
      is_accessory_dwelling_unit: [null],
      commercial_space_type: [null],
      registration__is_build_to_rent: [null],
      registration__registration_user_info: [null],
      created_at__gte: [null],
      created_at__lte: [null],
    });

    this.filterFromGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        const params = Object.assign(this.defaultParams, values);
        delete params.ordering;

        if (params.registration__registration_user_info) {
          params.registration__registration_user =
            params.registration__registration_user_info.id;
        } else {
          params.registration__registration_user = null;
        }
        delete params.registration__registration_user_info;

        this.storedParams = Object.assign(this.storedParams, params);
        this.list();
      });
  }

  hydrateForm() {
    this.filterFromGroup.patchValue(this.storedParams, {
      emitEvent: false,
      onlySelf: true,
    });
  }

  list() {
    this.projectsIsLoading = true;

    // populate params to query string
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.storedParams.toQueryParams(),
      replaceUrl: true,
    });

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }

    this.listSubscription$ = this.hirlProjectService
      .list(this.storedParams)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.projects = data.results;
        this.projectsCount = data.count;
        this.projectsIsLoading = false;
      });
  }

  onSort($event: Sort) {
    this.storedParams.ordering = `${$event.direction === 'desc' ? '-' : ''}${
      $event.active
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  onPaginateChange($event: PageEvent) {
    this.uiHelperService.getUISettings().rowsPerPage = $event.pageSize;
    this.storedParams.page_size = $event.pageSize;
    this.storedParams.page = $event.pageIndex + 1;
    this.list();
  }

  onFooterPage(event) {
    this.storedParams.page = event.page;
    this.list();
  }

  resetFilters($event: MouseEvent) {
    $event.preventDefault();
    this.filterFromGroup.reset();
  }

  exportToExcel($event: MouseEvent) {
    $event.preventDefault();
    const excelData: Array<IExcelJson> = [];
    const udt: IExcelJson = {
      data: [
        {
          A: 'Project ID',
          B: 'Name',
          C: 'Address',
          D: 'Program',
          E: 'Builder/Client Company',
          F: 'Verification Company',
          G: 'Registered By',
          H: 'Registration Date',
          I: 'Story Count',
          J: 'Unit Count',
        }, // table header
      ],
      skipHeader: false,
    };
    this.projects.forEach(project => {
      const confirmedGeocodeResponse = new ConfirmedGeocodeResponse({
        geocode: project.home_address_geocode_info,
        geocodeBrokeredResponse: project.home_address_geocode_response_info,
      });
      udt.data.push({
        A: project.id,
        B: project.registration_info.project_name,
        C: confirmedGeocodeResponse.displayAddress,
        D: project.registration_info.eep_program_info.name,
        E: project.registration_info.builder_organization_info.name,
        F: project.registration_info.registration_user_info.company_info.name,
        G:
          project.registration_info.registration_user_info.first_name +
          ' ' +
          project.registration_info.registration_user_info.last_name,
        H: `${formatDate(
          project.registration_info.created_at,
          'yyyy-MM-dd',
          'en-US'
        )}`,
        I: project.story_count,
        J: project.number_of_units,
      });
    });
    excelData.push(udt);

    this.uiHelperService.exportJsonToExcel(excelData, 'projects');
  }

  exportToCSV($event: MouseEvent) {
    $event.preventDefault();
    const data = [];
    this.projects.forEach(project => {
      const confirmedGeocodeResponse = new ConfirmedGeocodeResponse({
        geocode: project.home_address_geocode_info,
        geocodeBrokeredResponse: project.home_address_geocode_response_info,
      });
      data.push({
        'Project ID': project.id,
        Name: project.registration_info.project_name,
        Address: confirmedGeocodeResponse.displayAddress,
        Program: project.registration_info.eep_program_info.name,
        'Builder/Client Company':
          project.registration_info.builder_organization_info.name,
        'Verification Company':
          project.registration_info.registration_user_info.company_info.name,
        'Registered By':
          project.registration_info.registration_user_info.first_name +
          ' ' +
          project.registration_info.registration_user_info.last_name,
        'Registration Date': `${formatDate(
          project.registration_info.created_at,
          'yyyy-MM-dd',
          'en-US'
        )}`,
        'Story Count': project.story_count,
        'Unit Count': project.number_of_units,
      });
    });
    this.uiHelperService.exportToCsv(data, 'projects', [
      'Project ID',
      'Name',
      'Address',
      'Program',
      'Builder/Client Company',
      'Verification Company',
      'Registered By',
      'Registration Date',
      'Story Count',
      'Unit Count',
    ]);
  }
}
