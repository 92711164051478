import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { first, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getInfoUser } from '@/state/selectors/info.selector';
import { AppState } from '@/state/reducers';
import { HIRLProductAgreementService } from '@/data/customer-hirl/services/product-agreement.service';
import { HIRLProductAgreement } from '@/data/customer-hirl/models';
import { HIRLProductAgreementState } from '@/data/customer-hirl/models/product-agreement';
import { CustomerHIRLSettings } from '@/modules/customer-hirl/constants';
import { CompanyType } from '@/data/company/models';

@Injectable({
  providedIn: 'root',
})
export class HIRLProductAgreementPermissionService {
  constructor(
    public store: Store<AppState>,
    public hirlProductAgreementService: HIRLProductAgreementService
  ) {}

  canCreate(): Observable<ObjectPermissionResponse> {
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
    }).pipe(
      map(({ currentUser }) => {
        if (currentUser.is_superuser) {
          return new ObjectPermissionResponse(true);
        }
        if (
          currentUser.company_info?.slug === CustomerHIRLSettings.companySlug
        ) {
          return new ObjectPermissionResponse(true);
        }
        if (
          currentUser.company_info?.company_type === CompanyType.manufacturer
        ) {
          return new ObjectPermissionResponse(true);
        }
        return new ObjectPermissionResponse(
          false,
          'You do not have permission to perform this action'
        );
      })
    );
  }

  canEdit(
    hirlProductAgreement: number | HIRLProductAgreement
  ): Observable<ObjectPermissionResponse> {
    const hirlProductAgreement$ =
      this.getHIRLProductAgreement(hirlProductAgreement);
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: hirlProductAgreement$,
    }).pipe(
      map(({ currentUser, entity }) => {
        if (currentUser.is_superuser) {
          return new ObjectPermissionResponse(true);
        }
        if (
          currentUser.company_info?.slug === CustomerHIRLSettings.companySlug
        ) {
          return new ObjectPermissionResponse(true);
        }
        if (
          [
            HIRLProductAgreementState.expired,
            HIRLProductAgreementState.countersigned,
          ].includes(entity.state)
        ) {
          return new ObjectPermissionResponse(
            false,
            `Product Agreement already ${entity.state}`
          );
        }
        if (entity.company === currentUser.company) {
          return new ObjectPermissionResponse(true);
        }
        return new ObjectPermissionResponse(
          false,
          'You do not have permission to perform this action'
        );
      })
    );
  }

  canDelete(hirlProductAgreement: number | HIRLProductAgreement) {
    const hirlProductAgreement$ =
      this.getHIRLProductAgreement(hirlProductAgreement);
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: hirlProductAgreement$,
    }).pipe(
      map(({ currentUser, entity }) => {
        if (currentUser.is_superuser) {
          return new ObjectPermissionResponse(true);
        }
        if (
          currentUser.company_info?.slug === CustomerHIRLSettings.companySlug
        ) {
          return new ObjectPermissionResponse(true);
        }
        if (
          [
            HIRLProductAgreementState.expired,
            HIRLProductAgreementState.countersigned,
          ].includes(entity.state)
        ) {
          return new ObjectPermissionResponse(
            false,
            `Product Agreement already ${entity.state}`
          );
        }
        return new ObjectPermissionResponse(
          false,
          'You do not have permission to perform this action'
        );
      })
    );
  }

  canReSendEmail(hirlProductAgreement: number | HIRLProductAgreement) {
    const hirlProductAgreement$ =
      this.getHIRLProductAgreement(hirlProductAgreement);
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: hirlProductAgreement$,
    }).pipe(
      map(({ currentUser, entity }) => {
        if (
          [
            HIRLProductAgreementState.expired,
            HIRLProductAgreementState.countersigned,
          ].includes(entity.state)
        ) {
          return new ObjectPermissionResponse(
            false,
            `Product Agreement already ${entity.state}`
          );
        }
        if (currentUser.is_superuser) {
          return new ObjectPermissionResponse(true);
        }
        if (
          currentUser.company_info?.slug === CustomerHIRLSettings.companySlug
        ) {
          return new ObjectPermissionResponse(true);
        }
        return new ObjectPermissionResponse(false);
      })
    );
  }

  canCounterSign(
    hirlProductAgreement: number | HIRLProductAgreement
  ): Observable<ObjectPermissionResponse> {
    const hirlProductAgreement$ =
      this.getHIRLProductAgreement(hirlProductAgreement);
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: hirlProductAgreement$,
    }).pipe(
      map(({ currentUser, entity }) => {
        if (entity.state != HIRLProductAgreementState.approved) {
          return new ObjectPermissionResponse(
            false,
            'Client Agreement is not in Approved state'
          );
        }
        if (!entity.start_date) {
          return new ObjectPermissionResponse(
            false,
            'Agreement start date not set'
          );
        }
        if (!entity.expiration_date) {
          return new ObjectPermissionResponse(
            false,
            'Agreement expiration date not set'
          );
        }
        return new ObjectPermissionResponse(true);
      })
    );
  }

  canUpdateDocusignStatus(
    hirlProductAgreement: number | HIRLProductAgreement
  ): Observable<ObjectPermissionResponse> {
    const hirlProductAgreement$ =
      this.getHIRLProductAgreement(hirlProductAgreement);
    return forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
      entity: hirlProductAgreement$,
    }).pipe(
      map(({ currentUser, entity }) => {
        if (
          currentUser.is_superuser ||
          currentUser.company_info?.slug === CustomerHIRLSettings.companySlug
        ) {
          return new ObjectPermissionResponse(true);
        }
        return new ObjectPermissionResponse(false);
      })
    );
  }

  private getHIRLProductAgreement(
    hirlProductAgreement: number | HIRLProductAgreement
  ): Observable<HIRLProductAgreement> {
    let hirlProductAgreement$: Observable<HIRLProductAgreement>;
    if (typeof hirlProductAgreement === 'number') {
      hirlProductAgreement$ = this.hirlProductAgreementService.retrieve(
        hirlProductAgreement,
        {
          ignoreStore: true,
        }
      );
    } else {
      hirlProductAgreement$ = of(hirlProductAgreement);
    }
    return hirlProductAgreement$;
  }
}
