import { RouterModule, Routes } from '@angular/router';
import { ContentLayoutComponent } from '@/layout/content-layout/content-layout.component';
import { CustomerHIRLRouting } from '@/modules/customer-hirl/customer-hirl.routing';
import { LegacyRedirectGuard } from '@/core/guards/redirector.guard';
import { environment } from '../environments/environment';
import { InternalRouting } from '@/modules/internal/internal.routing';
import { PageNotFoundComponent } from '@/shared/pages/page-not-found/page-not-found.component';
import { CommunityRouting } from '@/modules/community/community.routing';
import { AccessDeniedComponent } from '@/shared/pages/access-denied/access-denied.component';
import { AuthRouting } from '@/modules/auth/auth.routing';
import { CompanyRouting } from '@/modules/company/company.routing';
import { UserRouting } from '@/modules/user/user.routing';
import { FlatpageRouting } from '@/modules/flatpage/flatpage.routing';
import { MiscRouting } from '@/modules/misc/misc.routing';
import { UserManagementRouting } from '@/modules/user-management/user-management.routing';
import { FloorPlanRouting } from '@/modules/floorplan/floorplan.routing';
import { NgbsFindVerifierComponent } from '@/modules/customer-hirl/pages/ngbs-find-verifier/ngbs-find-verifier.component';
import { NgbsFindGreenVerifierComponent } from './modules/customer-hirl/pages/ngbs-find-green-verifier/ngbs-find-green-verifier.component';
import { SchedulingRouting } from '@/modules/scheduling/scheduling.routing';
import { CustomerETORouting } from './modules/customer-eto/customer-eto.routing';
import { SimulationRouting } from './modules/simulation/simulation.routing';
import { EEPProgramCertificateLookupResultComponent } from '@/modules/home/pages/eep-program-certificate-lookup-result/eep-program-certificate-lookup-result.component';
import { HomeRouting } from '@/modules/home/home.routing';
import { ProductRouting } from '@/modules/product/product.routing';

let routes: Routes;

let children: any[] = [
  ...AuthRouting,
  ...MiscRouting,
  ...UserManagementRouting,
  ...CustomerHIRLRouting,
  ...FlatpageRouting,
  ...FloorPlanRouting,
  ...CompanyRouting,
  ...CustomerETORouting,
  ...SchedulingRouting,
  ...CommunityRouting,
  ...HomeRouting,
  ...InternalRouting,
  ...SimulationRouting,
  ...ProductRouting,
  ...UserRouting,
  {
    path: '403',
    component: AccessDeniedComponent,
  },
  {
    path: '404',
    component: PageNotFoundComponent,
  },
];

if (environment.APP_INSIDE_DJANGO) {
  routes = [
    // non layout pages first
    {
      path: 'find_verifier',
      component: NgbsFindVerifierComponent,
    },
    {
      path: 'find_green_verifier',
      component: NgbsFindGreenVerifierComponent,
    },
    {
      path: 'certification',
      children: [
        {
          path: 'lookup',
          component: EEPProgramCertificateLookupResultComponent,
        },
      ],
    },
    {
      path: '',
      component: LegacyRedirectGuard,
      canActivate: [LegacyRedirectGuard],
      pathMatch: 'full',
    },
    {
      path: '',
      component: ContentLayoutComponent,
      children,
    },
    {
      path: '**',
      component: LegacyRedirectGuard,
      canActivate: [LegacyRedirectGuard],
    },
  ];
} else {
  // dev routes
  children = children.concat([]);

  routes = [
    {
      path: 'find_verifier',
      component: NgbsFindVerifierComponent,
    },
    {
      path: 'find_green_verifier',
      component: NgbsFindGreenVerifierComponent,
    },
    {
      path: 'certification',
      children: [
        {
          path: 'lookup',
          component: EEPProgramCertificateLookupResultComponent,
        },
      ],
    },
    {
      path: '',
      component: ContentLayoutComponent,
      children,
    },
    {
      path: 'app',
      component: ContentLayoutComponent,
      children,
    },
    {
      path: '**',
      component: LegacyRedirectGuard,
      canActivate: [LegacyRedirectGuard],
    },
  ];
}

export const AppRoutingModule = RouterModule.forRoot(routes, {
  scrollPositionRestoration: 'enabled',
  relativeLinkResolution: 'legacy',
});
