import { Component, OnDestroy, OnInit } from '@angular/core';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { User } from '@/data/core/models/user';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, map, mergeMap, takeUntil } from 'rxjs/operators';
import { toggleLoading } from '@/state/actions/app.actions';
import {
  IExcelJson,
  UIHelperService,
} from '@/shared/services/ui-helper.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  UserRequestParams,
  UserService,
} from '@/data/core/services/user.service';
import { ObjectPermissionResponse } from '@/core/schemes/object-permission-repsponse';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import * as _ from 'lodash';
import {
  CompanyTypeLabelMapping,
  HirlMarketingRoleInfo,
} from '@/data/company/models';

@Component({
  selector: 'app-user-list-page',
  templateUrl: './user-list-page.component.html',
  styleUrls: ['./user-list-page.component.scss'],
})
export class MailingListPageComponent implements OnInit, OnDestroy {
  public displayedColumns = [];

  public rows: User[];
  public rowsCount: number;

  public isLoading = true;

  public currentUser: User;
  public filterFromGroup: FormGroup;

  public createPermissionResponse: Observable<ObjectPermissionResponse>;

  public defaultParams: UserRequestParams = new UserRequestParams(1, '', '-id');
  public storedParams: UserRequestParams;

  private listSubscription$: Subscription;
  private componentDestroyed$ = new Subject();

  public typeLabelMapping = CompanyTypeLabelMapping;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private userService: UserService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private uiHelperService: UIHelperService
  ) {}

  ngOnInit() {
    this.setupFilterForm();

    this.activatedRoute.params
      .pipe(
        takeUntil(this.componentDestroyed$),
        mergeMap(params => {
          this.rows = [];
          this.rowsCount = 0;

          return forkJoin({
            queryParams: this.activatedRoute.queryParams.pipe(first()),
            currentUser: this.store.select(getInfoUser).pipe(first()),
          }).pipe(
            map(({ queryParams, currentUser }, index) => {
              return { params, queryParams, currentUser };
            })
          );
        })
      )
      .subscribe(({ params, queryParams, currentUser }) => {
        this.storedParams = _.cloneDeep(this.defaultParams);
        this.storedParams.page_size =
          this.uiHelperService.getUISettings().rowsPerPage;
        this.currentUser = currentUser;

        this.displayedColumns = [
          'first_name',
          'email',
          'phone_number',
          'company__name',
          'marketing_role',
        ];
        this.hydrateForm();
        this.list();
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }
  }

  setupFilterForm() {
    this.filterFromGroup = this.fb.group({
      search: [null],
      company__hirl_marketing_roles__slug: [null],
    });

    this.filterFromGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(values => {
        const params = _.merge({}, this.defaultParams, values);
        delete params.page_size;
        delete params.ordering;
        delete this.storedParams.company__hirl_marketing_roles__slug;

        this.storedParams = _.merge(this.storedParams, params);
        this.list();
      });
  }

  getMarketingRoleNames(
    roles: HirlMarketingRoleInfo[] | undefined | null
  ): string {
    if (!roles || roles.length === 0) {
      return ' ';
    }

    return roles.map(role => role.name).join(', ');
  }

  list() {
    this.isLoading = true;

    // populate params to query string
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.storedParams.toQueryParams(),
      replaceUrl: true,
    });

    if (this.listSubscription$) {
      this.listSubscription$.unsubscribe();
    }

    this.listSubscription$ = this.userService
      .list(this.storedParams)
      .pipe(takeUntil(this.componentDestroyed$), first())
      .subscribe(data => {
        this.rows = data.results;
        this.rowsCount = data.count;
        this.isLoading = false;
      });
  }

  hydrateForm() {
    this.filterFromGroup.patchValue(this.storedParams, {
      emitEvent: false,
      onlySelf: true,
    });
  }

  resetFilters($event: MouseEvent) {
    $event.preventDefault();
    this.filterFromGroup.reset();
  }

  onPaginateChange($event: PageEvent) {
    this.uiHelperService.getUISettings().rowsPerPage = $event.pageSize;
    this.storedParams.page_size = $event.pageSize;
    this.storedParams.page = $event.pageIndex + 1;
    this.list();
  }

  onSortChange($event: Sort) {
    this.storedParams.ordering = `${$event.direction === 'desc' ? '-' : ''}${
      $event.active
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  exportToExcel($event: MouseEvent) {
    $event.preventDefault();
    const excelData: Array<IExcelJson> = [];
    const udt: IExcelJson = {
      data: [
        {
          A: 'Name',
          B: 'Email',
          C: 'Phone Number',
          D: 'Company Name',
          E: 'Marketing Role',
        }, // table header
      ],
      skipHeader: true,
    };
    this.rows.forEach(user => {
      udt.data.push({
        A: user.first_name + ' ' + user.last_name,
        B: user.email,
        C: user.work_phone,
        D: user.company_info.name,
        E: this.getMarketingRoleNames(
          user.company_info.hirl_marketing_roles_info
        ),
      });
    });
    excelData.push(udt);

    this.uiHelperService.exportJsonToExcel(excelData, 'users');
  }

  exportToCSV($event: MouseEvent) {
    $event.preventDefault();
    const data = [];
    this.rows.forEach(user => {
      data.push({
        Name: user.first_name + ' ' + user.last_name,
        Email: user.email,
        'Phone Number': user.work_phone,
        'Company Name': user.company_info.name,
        'Marketing Role': this.getMarketingRoleNames(
          user.company_info.hirl_marketing_roles_info
        ),
      });
    });
    this.uiHelperService.exportToCsv(data, 'users', [
      'Name',
      'Email',
      'Phone Number',
      'Company Name',
      'Marketing Role',
    ]);
  }

  exportMailingList($event: MouseEvent) {
    this.store.dispatch(toggleLoading({ payload: true }));
    const newTab = window.open('', '_blank');
    this.userService
      .mailing_list_export(this.storedParams)
      .pipe(first())
      .subscribe(
        asynchronousProcessedDocument => {
          newTab.location.href = `/file-operation/document/${asynchronousProcessedDocument.id}`;
          newTab.focus();
        },
        error => {
          // Close the blank tab if we encountered an error
          newTab.close();
          this.uiHelperService.handleUserRequestError(error);
        }
      );
  }
}
