<span [ngSwitch]="displayType"[ngClass]="{'font-italic': !user?.is_active}">
    <span *ngSwitchCase="userDisplayComponentType.onlyName">{{ user?.first_name }} {{ user?.last_name }}</span>
    <a [routerLink]="['/', 'users', user?.id]" *ngSwitchCase="userDisplayComponentType.onlyNameAsLink">
        <ng-container *ngIf="user?.first_name || user?.last_name">
          {{ user?.first_name }} {{ user?.last_name }}
        </ng-container>
        <ng-container *ngIf="user && !user?.first_name && !user?.last_name">
          Undefined
        </ng-container>
        <span *ngIf="user && !user.is_active" class="text-muted">(Not Active)</span>
    </a>
</span>
