import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Company, CompanyType } from '@/data/company/models';
import { Subject } from 'rxjs';

export class CoiHelpDialogData {
  company: Company;
}

@Component({
  selector: 'app-coi-help-dialog',
  templateUrl: './coi-help-dialog.component.html',
  styleUrls: ['./coi-help-dialog.component.scss'],
})
export class CoiHelpDialogComponent implements OnInit, OnDestroy {
  protected readonly CompanyType = CompanyType;

  company: Company;
  private componentDestroyed$ = new Subject();

  constructor(@Inject(MAT_DIALOG_DATA) public data: CoiHelpDialogData) {
    this.company = data.company;
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
