import {
  WaterSystemBackendDict,
  createWaterSystemForm,
} from '@/data/simulation/models/systems/WaterSystem';
import { BaseValidator } from './base.validator';
import { ModelErrors, StateErrors } from '../state/shared/base.state';

export class WaterSystemValidator extends BaseValidator {
  static validateForm(
    waterSystem: WaterSystemBackendDict
  ): ModelErrors<WaterSystemBackendDict> {
    const form = createWaterSystemForm(waterSystem);
    return form.getFieldAndModelErros();
  }

  static validate(
    waterSystems: WaterSystemBackendDict[]
  ): StateErrors<WaterSystemBackendDict> {
    const errors = {};
    waterSystems.forEach(waterSystem => {
      errors[waterSystem.id] = this.validateForm(waterSystem);
    });
    return errors;
  }
}
