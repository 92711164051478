import { createSelector } from '@ngrx/store';
import { SimulationState } from '../reducers';
import { selectSimulationFeature } from '../simulation.selectors';

export const selectDoorType = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.doorType.entities[id]
  );

const selectDoorTypeLoadingStatus = (id: number) =>
  createSelector(
    selectSimulationFeature,
    (state: SimulationState) => state.doorType.loading[id]
  );

export const selectDoorTypeViewSet = (id: number) =>
  createSelector(
    selectDoorType(id),
    selectDoorTypeLoadingStatus(id),
    (doorType, loadingStatus) => ({
      data: doorType,
      loading: loadingStatus,
    })
  );

const selectDoorTypeName = (id: number) =>
  createSelector(selectDoorType(id), doorType => doorType.name);

const selectDoorTypeErrorCount = (id: number) =>
  createSelector(selectSimulationFeature, (state: SimulationState) => {
    return state.doorType.errors[id].errorCount;
  });

export const selectDoorTypeNameAndError = (id: number) =>
  createSelector(
    selectDoorTypeName(id),
    selectDoorTypeErrorCount(id),
    (name, errorCount) => {
      return {
        name,
        errorCount,
      };
    }
  );
