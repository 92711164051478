import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from '@/data/core/models/user';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { Router } from '@angular/router';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { toggleLoading } from '@/state/actions/app.actions';
import {
  defaultIfEmpty,
  first,
  map,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { getInfoUser } from '@/state/selectors/info.selector';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import {
  ApexRequestCreate,
  ApexRequestEnergyModelTypeLabelMapping,
  ApexRequestType,
} from '@/data/home/models';
import { ApexRequestService } from '@/data/home/services/apex_request.service';
import { CustomerDocumentService } from '@/data/filehandling/services/customer-document.service';
import { CustomerDocument } from '@/data/filehandling/models';
import { forEach } from 'lodash';
import { CompanyService } from '@/data/company/services/company-base.service';
import { CompanyFlatList } from '@/data/company/models';
import { conditionalValidator } from '@/core/validators';

@Component({
  selector: 'app-apex-request-create',
  templateUrl: './apex-request-create.component.html',
  styleUrls: ['./apex-request-create.component.scss'],
})
export class ApexRequestCreateComponent implements OnInit, OnDestroy {
  protected readonly ApexRequestType = ApexRequestType;
  protected readonly ApexRequestTypeLabelMapping: Record<string, string> = {
    [ApexRequestType.simulate]: 'Run APEX',
    [ApexRequestType.simulate_and_create_fp]:
      'Run APEX & Create AXIS Floorplan',
    [ApexRequestType.simulate_and_create_home]:
      'Run APEX & Create AXIS Floorplan and Home',
  };
  protected readonly ApexRequestEnergyModelTypeLabelMapping =
    ApexRequestEnergyModelTypeLabelMapping;

  initialized = false;

  files: File[] = [];
  currentUser: User;
  public formGroup: FormGroup;

  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private customerDocumentService: CustomerDocumentService,
    private companyService: CompanyService,
    private apexRequestService: ApexRequestService,
    private fb: FormBuilder,
    private uiHelperService: UIHelperService
  ) {}

  ngOnInit(): void {
    forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
    })
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(({ currentUser }) => {
        this.currentUser = currentUser;

        this.setupForm();
        this.initialized = true;
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  public setupForm() {
    this.formGroup = this.fb.group({
      builder_organization_info: [null],
      subdivision_info: [null],
      request_type: [ApexRequestType.simulate, Validators.required],
      energy_model_type: [
        null,
        conditionalValidator(() => {
          return (
            this.formGroup.get('request_type').value ===
            ApexRequestType.simulate_and_create_home
          );
        }, Validators.required),
      ],
    });

    this.formGroup
      .get('request_type')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe(value => {
        if (value === ApexRequestType.simulate) {
          this.formGroup.patchValue({
            builder_organization_info: null,
            subdivision_info: null,
            energy_model_type: null,
          });
        } else if (value === ApexRequestType.simulate_and_create_fp) {
          this.formGroup.patchValue({
            subdivision_info: null,
          });
        }
      });
  }

  onSubmit($event: MouseEvent) {
    $event.preventDefault();
    this.formGroup.markAllAsTouched();
    if (this.formGroup.invalid || !this.files.length) {
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const apexRequest: ApexRequestCreate = new ApexRequestCreate();

    apexRequest.builder_organization =
      this.formGroup.value.builder_organization_info?.id;
    apexRequest.subdivision = this.formGroup.value.subdivision_info?.id;
    apexRequest.request_type = this.formGroup.value.request_type;
    apexRequest.energy_model_type = this.formGroup.value.energy_model_type;

    const customerDocumentsChain$ = [];

    forEach(this.files, f => {
      const customerDocumentData = new CustomerDocument();

      const customerDocumentCreate$ = this.companyService
        .documentsCreate(this.currentUser.company, customerDocumentData)
        .pipe(
          first(),
          // update file fields
          switchMap(customerDocument => {
            const formData = new FormData();
            formData.append('document', f);
            return this.customerDocumentService.update(
              customerDocument.id,
              formData
            );
          })
        )
        .pipe(takeUntil(this.componentDestroyed$), first());

      customerDocumentsChain$.push(customerDocumentCreate$);
    });

    forkJoin(customerDocumentsChain$)
      .pipe(
        takeUntil(this.componentDestroyed$),
        defaultIfEmpty(null),
        map(customerDocuments =>
          customerDocuments.map(customerDocument => customerDocument.id)
        ),
        switchMap(customerDocumentsIds => {
          apexRequest.documents = customerDocumentsIds;

          return this.apexRequestService
            .create(apexRequest)
            .pipe(first(), takeUntil(this.componentDestroyed$));
        })
      )
      .subscribe({
        next: apexRequest => {
          this.store.dispatch(toggleLoading({ payload: false }));
          const _ = this.router.navigate([
            '/',
            'home',
            'apex',
            'request',
            'detail',
            apexRequest.id,
          ]);
        },
        error: error => this.uiHelperService.handleUserRequestError(error),
      });
  }

  onSelect($event: NgxDropzoneChangeEvent) {
    this.files.push(...$event.addedFiles);
  }

  onRemove(f: File) {
    this.files.splice(this.files.indexOf(f), 1);
  }

  builderCompanyDisplay(company: CompanyFlatList): string {
    if (company) {
      let displayName = `${company.name}`;
      if (company.city_name && company.state) {
        displayName += ` (${company.city_name}, ${company.state})`;
      }
      return displayName;
    }
    return '';
  }
}
