import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BaseDataService } from '@/core/services/base-data-service';
import { Observable } from 'rxjs';
import { HeaterBackendDict } from '../models/mechanicals/Heater';
import { ListRequestParams } from '@/core/schemes/request-params';
import { ServerResponse } from '@/core/schemes/server-response';

@Injectable({ providedIn: 'root' })
export class HeaterService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/heater/`;

  list(
    params: ListRequestParams
  ): Observable<ServerResponse<HeaterBackendDict>> {
    return this.http.get<ServerResponse<HeaterBackendDict>>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }

  update(heater: Partial<HeaterBackendDict>): Observable<HeaterBackendDict> {
    return this.http.patch<HeaterBackendDict>(
      `${this.baseUrl}${heater.id}/`,
      heater
    );
  }
}
