<app-loading-overlay *ngIf="loading"></app-loading-overlay>
<app-form formName="waterHeaterFormGroup" [formGroup]="waterHeaterFormGroup" (save)="onSave($event)">
    <div class="row">
        <div class="col-12">
            <app-input formControlName="name" [label]="fieldLabels.name"></app-input>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <app-select formControlName="style" [label]="fieldLabels.style" [items]="waterHeaterStyleLabels"></app-select>
        </div>
        <div class="col-5">
            <app-select formControlName="fuel" [label]="fieldLabels.fuel" [items]="fuelTypeLabels"></app-select>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <app-input formControlName="tank_size" type="number" [label]="fieldLabels.tank_size"></app-input>
        </div>
        <div class="col-4">
            <app-select formControlName="tank_units" [label]="fieldLabels.tank_units" [items]="waterHeaterLiquidVolumeLabels"></app-select>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <app-input formControlName="efficiency" type="number" [label]="fieldLabels.efficiency"></app-input>
        </div>
        <div class="col-3">
            <app-select formControlName="efficiency_unit" [label]="fieldLabels.efficiency_unit" [items]="hotWaterEfficiencyUnitLabels"></app-select>
        </div>
        <div class="col-4">
            <app-input formControlName="recovery_efficiency" type="number" [label]="fieldLabels.recovery_efficiency"></app-input>
        </div>
    </div>

<!--    <div class="row">-->
<!--        <div class="col-4">-->
<!--            <app-input formControlName="capacity" type="number" [label]="fieldLabels.capacity" [unit]="entity?.capacity_unit"></app-input>-->
<!--        </div>-->
<!--        <div class="col-3">-->
<!--            <app-select formControlName="capacity_unit" [label]="fieldLabels.capacity_unit" [items]="hotWaterCapacityUnitLabels"></app-select>-->
<!--        </div>-->
<!--        <div class="col-3">-->
<!--            <app-input formControlName="first_hour_rating" type="number" [label]="fieldLabels.first_hour_rating"></app-input>-->
<!--        </div>-->
<!--    </div>-->

    <div class="row">
        <div class="col-4">
            <app-input formControlName="extra_tank_insulation_r_value" type="expression" [label]="fieldLabels.extra_tank_insulation_r_value"></app-input>
        </div>
        <div class="col-4">
            <app-input formControlName="ahri_reference_number" type="number" [label]="fieldLabels.ahri_reference_number"></app-input>
        </div>
    </div>
</app-form>
