import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BaseDataService } from '@/core/services/base-data-service';
import { Observable } from 'rxjs';
import { InfiltrationBackendDict } from '../models/enclosure/Infiltration';

@Injectable({ providedIn: 'root' })
export class InfiltrationService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/infiltration/`;

  update(
    infiltrationTypeChanges: Partial<InfiltrationBackendDict>
  ): Observable<InfiltrationBackendDict> {
    return this.http.patch<InfiltrationBackendDict>(
      `${this.baseUrl}${infiltrationTypeChanges.id}/`,
      infiltrationTypeChanges
    );
  }
}
