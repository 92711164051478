import { Injectable } from '@angular/core';
import { ListRequestParams } from '@/core/schemes/request-params';
import { environment } from '../../../../environments/environment';
import { BaseDataService } from '@/core/services/base-data-service';
import { Observable } from 'rxjs';
import { BlockRateBackendDict } from '../models/utility/BlockRate';

export class BlockRateParams extends ListRequestParams {}

@Injectable({ providedIn: 'root' })
export class BlockRateService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/block_rate/`;
  public parentUrl = `${environment.AXIS_API_V3_ROOT}/simulation/`;

  create(
    simulationId: number,
    data: Partial<BlockRateBackendDict>
  ): Observable<BlockRateBackendDict> {
    return this.http.post<BlockRateBackendDict>(
      `${this.parentUrl}${simulationId}/block_rates/`,
      data
    );
  }

  update(
    blockRateChanges: Partial<BlockRateBackendDict>
  ): Observable<BlockRateBackendDict> {
    return this.http.patch<BlockRateBackendDict>(
      `${this.baseUrl}${blockRateChanges.id}/`,
      blockRateChanges
    );
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}${id}/`);
  }
}
