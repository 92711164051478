import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  getCompany,
  getCompanySponsoringPreferences,
  getCompanySponsoringPreferencesCount,
} from '@/state/selectors/company/company.selector';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  takeUntil,
} from 'rxjs/operators';
import { AppState } from '@/state/reducers';
import {
  CompanyService,
  SponsoringPreferencesRequestParams,
} from '@/data/company/services/company-base.service';
import { Company, SponsoringPreferences } from '@/data/company/models';

@Component({
  selector: 'app-company-sponsoring-list-tab',
  templateUrl: './company-sponsoring-list-tab.component.html',
  styleUrls: ['./company-sponsoring-list-tab.component.scss'],
})
export class CompanySponsoringListTabComponent implements OnInit, OnDestroy {
  company: Company;

  isLoading = false;
  rows: SponsoringPreferences[] = [];
  rowsCount: number;
  searchTerm$: Subject<string> = new Subject<string>();
  storedParams: SponsoringPreferencesRequestParams =
    new SponsoringPreferencesRequestParams(1, '', '-id');

  private componentDestroyed$ = new Subject();

  constructor(
    public store: Store<AppState>,
    public companyService: CompanyService
  ) {}

  ngOnInit(): void {
    this.store
      .select(getCompany)
      .pipe(
        filter(val => val !== null),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(company => {
        this.company = company;
        this.list();
      });

    this.store
      .select(getCompanySponsoringPreferences)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(sponsoringPreferences => {
        this.rows = sponsoringPreferences;
      });

    this.store
      .select(getCompanySponsoringPreferencesCount)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(count => {
        this.rowsCount = count;
      });

    this.searchTerm$
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe(searchTerm => {
        this.storedParams.search = searchTerm;
        this.storedParams.page = 1;
        this.list();
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  search(searchTerm: string) {
    this.searchTerm$.next(searchTerm);
  }

  list() {
    this.isLoading = true;
    this.companyService
      .sponsoring(this.company.id, this.storedParams)
      .pipe(first())
      .subscribe(_ => {
        this.isLoading = false;
      });
  }

  onSort({ sorts }) {
    this.storedParams.ordering = `${sorts[0].dir === 'desc' ? '-' : ''}${
      sorts[0].prop
    }`;
    this.storedParams.page = 1;
    this.list();
  }

  onChangePage(event) {
    this.storedParams.page = event.page;
    this.list();
  }

  onSearch($event: KeyboardEvent) {
    this.search(($event.target as HTMLInputElement).value);
  }

  onClearSearch($event: MouseEvent) {
    this.storedParams.search = '';
    this.search('');
  }
}
