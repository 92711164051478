import { Injectable } from '@angular/core';
import { ServerResponse } from '@/core/schemes/server-response';
import { environment } from '../../../../environments/environment';
import { EEPProgram } from '@/data/eep-program/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseDataService } from '@/core/services/base-data-service';
import { ListRequestParams } from '@/core/schemes/request-params';

export class EEPProgramRequestParams extends ListRequestParams {
  is_multi_family?: boolean;
  owner?: number;
  owner__slug?: string;
  is_qa_program?: boolean;
  includes_older_versions?: boolean;

  home_status_related?: boolean;

  set: string;
}

@Injectable({
  providedIn: 'root',
})
export class EEPProgramService extends BaseDataService {
  public baseUrl = `${environment.AXIS_API_V3_ROOT}/eep_programs/`;

  list(params?: EEPProgramRequestParams) {
    return this.http.get<ServerResponse<EEPProgram>>(`${this.baseUrl}`, {
      params: this.rollParams(params),
    });
  }

  retrieve(eepProgramId: number): Observable<EEPProgram> {
    return this.http.get<EEPProgram>(`${this.baseUrl}${eepProgramId}`);
  }

  hirl_project_programs(
    params?: EEPProgramRequestParams
  ): Observable<EEPProgram[]> {
    return this.http.get<EEPProgram[]>(
      `${this.baseUrl}hirl_project_programs/`,
      {
        params: this.rollParams(params),
      }
    );
  }

  hirl_program_have_accreditation(
    eepProgramId: number,
    userId: number
  ): Observable<boolean> {
    return this.http
      .get<any>(
        `${this.baseUrl}${eepProgramId}/hirl_program_have_accreditation/${userId}/`,
        {}
      )
      .pipe(
        map(loaded => {
          return loaded.exists;
        })
      );
  }
}
