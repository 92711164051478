import { DoorTypeBackendDict } from '@/data/simulation/models/enclosure/DoorType';
import { createAction, props } from '@ngrx/store';
import { StateErrors } from '../shared/base.state';

export const loadDetailedDoorTypes = createAction(
  '[DoorType] Load Detailed DoorTypes',
  props<{ doorTypes: DoorTypeBackendDict[] }>()
);

export const loadDoorTypesSuccess = createAction(
  '[DoorType] Load DoorTypes Success',
  props<{
    doorTypes: DoorTypeBackendDict[];
    errors: StateErrors<DoorTypeBackendDict>;
  }>()
);

export const addDoorType = createAction(
  '[DoorType UI] Add DoorType',
  props<{ doorId: number; doorType: DoorTypeBackendDict }>()
);

export const removeDoorType = createAction(
  '[Door UI] Remove DoorType',
  props<{ doorId: number; doorTypeId: number }>()
);

export const removeDoorTypeSuccess = createAction(
  '[DoorType API] Remove DoorType Success',
  props<{ id: number }>()
);

export const updateDoorType = createAction(
  '[DoorType UI] Update DoorType',
  props<{ doorTypeChanges: Partial<DoorTypeBackendDict> }>()
);

export const updateDoorTypeFailure = createAction(
  '[DoorType API] Update DoorType Failure',
  props<{ id: number }>()
);
