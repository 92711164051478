import { UserInfo } from '@/data/core/models/user';
import { TrainingStatus } from '@/data/user-management/models/training-status';

export enum TrainingType {
  voluntary = 1,
  mandatory = 2,
}

export const TrainingTypeLabelMapping: Record<TrainingType, string> = {
  [TrainingType.voluntary]: 'Voluntary',
  [TrainingType.mandatory]: 'Mandatory',
};

export enum AttendanceType {
  inPerson = 1,
  remote = 2,
  absent = 3,
}

export const AttendanceTypeLabelMapping: Record<AttendanceType, string> = {
  [AttendanceType.inPerson]: 'In-person',
  [AttendanceType.remote]: 'Remote',
  [AttendanceType.absent]: 'ABSENT',
};

export class Training {
  id?: number;

  trainee?: number;
  trainee_info?: UserInfo;

  name?: string;
  address?: string;
  training_date?: string;
  training_type?: TrainingType;
  attendance_type?: AttendanceType;

  statuses?: number[];
  statuses_info?: TrainingStatus[];
  certificate?: string;
  credit_hours?: number;
  notes?: string;

  updated_at?: string;
  created_at?: string;
}
