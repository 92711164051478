import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from '@/data/core/models/user';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  UserRequestParams,
  UserService,
} from '@/data/core/services/user.service';
import { forkJoin, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UIHelperService } from '@/shared/services/ui-helper.service';
import { getInfoUser } from '@/state/selectors/info.selector';
import { first, takeUntil } from 'rxjs/operators';
import { toggleLoading } from '@/state/actions/app.actions';
import { HIRLRPCUpdaterRequestService } from '@/data/rpc/services/hirl-rpc-updater-request.service';
import {
  HIRLRPCUpdaterProjectTypeLabelMapping,
  HIRLRPCUpdaterScoringPathLabelMapping,
} from '@/data/rpc/models';

@Component({
  selector: 'app-hirl-rpc-updater-request-create',
  templateUrl: './hirl-rpc-updater-request-create.component.html',
  styleUrls: ['./hirl-rpc-updater-request-create.component.scss'],
})
export class HirlRpcUpdaterRequestCreateComponent implements OnInit, OnDestroy {
  initialized = false;

  public currentUser: User;
  public formGroup: UntypedFormGroup;

  public userRequestParams: UserRequestParams;
  public rpcUpdaterProjectTypeLabelMapping =
    HIRLRPCUpdaterProjectTypeLabelMapping;
  public rpcUpdaterScoringPathLabelMapping =
    HIRLRPCUpdaterScoringPathLabelMapping;

  private componentDestroyed$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private hirlRPCUpdaterRequestService: HIRLRPCUpdaterRequestService,
    private router: Router,
    private dialog: MatDialog,
    private userService: UserService,
    private fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private uiHelperService: UIHelperService
  ) {
    this.userRequestParams = new UserRequestParams();
  }

  ngOnInit(): void {
    forkJoin({
      currentUser: this.store.select(getInfoUser).pipe(first()),
    }).subscribe(({ currentUser }) => {
      this.currentUser = currentUser;

      this.setupForm();

      this.userRequestParams.company = this.currentUser.company;
      this.initialized = true;
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  public setupForm() {
    this.formGroup = this.fb.group({
      scoring_path: [null, Validators.required],
      report_file: [null, Validators.required],
      project_type: [null, Validators.required],
    });
  }

  onSubmit($event: MouseEvent) {
    $event.preventDefault();
    this.formGroup.markAllAsTouched();
    if (this.formGroup.invalid) {
      return;
    }

    this.store.dispatch(toggleLoading({ payload: true }));

    const formData = new FormData();
    formData.append('project_type', this.formGroup.value.project_type);
    formData.append('scoring_path', this.formGroup.value.scoring_path);
    formData.append('report_file', this.formGroup.value.report_file);

    this.hirlRPCUpdaterRequestService
      .create(formData)
      .pipe(first(), takeUntil(this.componentDestroyed$))
      .subscribe(
        hirlRPCUpdaterRequest => {
          this.store.dispatch(toggleLoading({ payload: false }));
          const _ = this.router.navigate([
            '/',
            'hi',
            'hirl_rpc_updater_request_list',
            'detail',
            hirlRPCUpdaterRequest.id,
          ]);
        },
        error => this.uiHelperService.handleUserRequestError(error)
      );
  }
}
