<div class="container" *ngIf="initialized">
  <div class="row">
    <div class="col-12">
      <h3>
        NGBS Verification Report Upload
      </h3>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <hr>
    </div>
  </div>
  <div class="row mb-2" *ngIf="missingCOITypes.length">
    <div class="col-12">
      <mat-error>
        <i>
          {{ currentUser.company_info.name }} does not have active Certificate of Insurance for the following
          types: {{ missingCOITypes.join(', ') }}.
          Please review insurance requirements <a
          [routerLink]="['/', 'company', currentUser.company_info?.company_type, 'detail', currentUser.company_info?.id]"
          [queryParams]="{ tab: 5 }">here</a>.
        </i>
      </mat-error>
    </div>
  </div>
  <form [formGroup]="formGroup">
    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Verification Report Type</mat-label>
          <mat-select formControlName="report_type" required>
            <mat-option [value]="'rough'">
              Rough Verification Report
            </mat-option>
            <mat-option [value]="'final'">
              Final Verification Report
            </mat-option>
            <mat-option [value]="'final'">
              Letter of Approval Report
            </mat-option>
            <mat-option [value]="'final'">
              Certification Report
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="formGroup.controls['report_type'].hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Verifier Name</mat-label>
          <app-generic-model-autocomplete
            formControlName="verifier_info"
            modelClassName="user"
            [filterParams]="userRequestParams"
            [optionDisplay]="verifierOptionDisplay"
            [optionDisabled]="verifierOptionDisabled"
            required
          ></app-generic-model-autocomplete>
          <mat-error *ngIf="formGroup.get('verifier_info').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Sampling Documentation</mat-label>
          <mat-select
            formControlName="sampling_documentation"
          >
            <mat-option
              *ngFor="
                                    let item of ScoringExtractionSamplingDocumentationTypeMapping
                                      | defaultOrderKeyValue
                                  "
              [value]="item.key"
            >
              {{ item.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Verification Report File</mat-label>
          <app-file-input formControlName="report_file" required></app-file-input>
          <mat-error *ngIf="formGroup.get('report_file').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
  <div class="row">
    <div class="col-12">
      <p>
        <b><i>
          Before submitting a Verification Report, please review and/or modify any NGBS Green+
          Badges selected for this project to ensure accurate billing.
        </i></b>
      </p>
      <p>
        By submitting this NGBS Verification Report I affirm that the selected verifier
        has personally verified by inspection and/or document review the green practices for
        which they have awarded points during this inspection on this building following the
        guidance in the Verifier Agreement and Verifier’s Resource Guide.
      </p>
      <p>
        The Verifier further affirms that his/her company is not supplying product, participating in
        the physical construction of this project, or affiliated in any other way that would
        compromise the independence of the verification services provided.
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-center">
      <button mat-raised-button color="accent" (click)="onSubmit($event)">
        Submit
      </button>
    </div>
  </div>
</div>
