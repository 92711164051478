<h2 mat-dialog-title>
  Add New Simulation
</h2>
<mat-dialog-content *ngIf="initialized">
  <form [formGroup]="form" class="mb-2">
    <div class="row">
      <div class="col-12">
        <div>
          <mat-form-field class="w-100">
            <mat-label>Source Type</mat-label>
            <mat-select aria-label="Select Simulation Source Type" formControlName="source_type">
              <mat-option *ngFor="let sourceType of sourceTypeLabelMapping | defaultOrderKeyValue"
                          [value]="sourceType.key">{{ sourceType.value }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="form.get('source_type').value === SimulationSourceType.ekotrope">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Source Date</mat-label>
          <input matInput type="text" placeholder="Source Date" formControlName="source_date" [matDatepicker]="sourceDatePicker">
          <mat-datepicker-toggle matSuffix [for]="sourceDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #sourceDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="row" *ngIf="form.get('source_type').value && form.get('source_type').value === SimulationSourceType.axis">
      <div class="col-12">
        <mat-form-field class="mb-2 w-100">
          <mat-label>Simulation Name</mat-label>
          <input matInput type="text" placeholder="Simulation Name" formControlName="name">
          <mat-error *ngIf="form.get('name').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row" *ngIf="form.get('source_type').value && form.get('source_type').value != SimulationSourceType.axis">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Document</mat-label>
          <app-file-input formControlName="document" [accept]="'.blg, .xml, .rem, .hpxml'"></app-file-input>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>
<div class="row">
  <div class="col-12">
    <hr>
  </div>
</div>
<mat-dialog-actions align="center">
  <button mat-button color="accent" mat-raised-button (click)="save($event)">Save</button>
  <button mat-button [mat-dialog-close]="null" color="primary" mat-raised-button>Cancel</button>
</mat-dialog-actions>
